import { Component, EventEmitter, Output } from '@angular/core';
import { SmacsFieldComponentConfig } from '../../../../../../forms/smacs-forms-models';
import { SmacsFieldAbstractDirective } from '../../../../../../forms/smacs-field-abstract.directive';
import { UniqueIdService } from '../../../../../../shared/services/unique-id.service';
import { SmacsFormStateService } from '../../../../../../forms/smacs-form-state.service';
import { SmacsSelectOption, SmacsSelectValue } from '../../../../../../forms/fields/select/smacs-select.component';
import { isArray, isEqual } from 'lodash';
import { NgModel } from '@angular/forms';
import { SmacsSelectService } from '../../../../../../forms/fields/select/smacs-select.service';

export interface SmacsRadioButtonSelect<T> {
  value: string;
  label: string;
  labelParam?: any;
  options: SmacsSelectOption[];
  isMultiSelect: boolean;
}

export class SmacsGroupSettingsLocationConfig<T> extends SmacsFieldComponentConfig {
  constructor(public buttons: SmacsRadioButtonSelect<T>[]) {
    super();
  }
}

export interface GroupSettingsLocationFieldEntity {
  siteIds: number[];
  clusterId: number;
}

export enum GroupSettingsLocationOptionType {
  CLUSTER = 'CLUSTER',
  SITES = 'SITES',
}

export interface GroupSettingsLocationFieldData {
  option: GroupSettingsLocationOptionType;
  siteIds: SmacsSelectOption[];
  clusterId: SmacsSelectOption;
}

@Component({
  selector: 'smacs-group-settings-location',
  templateUrl: './dial-plan-management-group-settings-location.component.html',
  providers: [
    { provide: SmacsFieldAbstractDirective, useExisting: CiscoDialPlanManagementGroupSettingsLocationComponent },
  ],
})
export class CiscoDialPlanManagementGroupSettingsLocationComponent<T> extends SmacsFieldAbstractDirective<
  GroupSettingsLocationFieldEntity,
  GroupSettingsLocationFieldData,
  SmacsGroupSettingsLocationConfig<T>
> {
  radioSelect = [] as SmacsRadioButtonSelect<T>[];
  uniqueRadioId: string;
  isLoading: boolean;
  initialValues: SmacsSelectValue[];
  bindValue: string;
  searchedTerm = '';

  @Output() searchHasChanged = new EventEmitter<string>();
  @Output() selectWasCleared = new EventEmitter<void>();
  @Output() selectBlur = new EventEmitter<void>();

  constructor(protected smacsFormStateService: SmacsFormStateService, private uniqueIdService: UniqueIdService) {
    super(smacsFormStateService);
    this.uniqueRadioId = uniqueIdService.getUniqueId();
  }

  applyComponentConfig = (config: SmacsGroupSettingsLocationConfig<T>) => {
    this.radioSelect = config.buttons;
  };

  toFieldData = (entity: GroupSettingsLocationFieldEntity): GroupSettingsLocationFieldData => {
    const clusterObj = this.radioSelect.find((b) => b.value === GroupSettingsLocationOptionType.CLUSTER);
    const clusterLabel = entity.clusterId
      ? clusterObj.options.find((o: SmacsSelectOption) => o.value === entity.clusterId).label
      : '';

    const siteObj = this.radioSelect.find((b) => b.value === GroupSettingsLocationOptionType.SITES);
    const siteLabels = entity.siteIds.map((id: number) => {
      const optionMatch = siteObj.options.find((o: SmacsSelectOption) => o.value === id);
      return {
        label: optionMatch.label,
        value: id,
        group: optionMatch.group,
      };
    });

    return {
      option: entity.clusterId ? GroupSettingsLocationOptionType.CLUSTER : GroupSettingsLocationOptionType.SITES,
      siteIds: [...siteLabels],
      clusterId: entity.clusterId
        ? {
            value: entity.clusterId,
            label: clusterLabel,
          }
        : null,
    };
  };

  toEntity = (fieldData: GroupSettingsLocationFieldData): GroupSettingsLocationFieldEntity => {
    return {
      siteIds: fieldData.siteIds.map((opt: SmacsSelectOption) => opt.value),
      clusterId: fieldData.clusterId ? fieldData.clusterId.value : null,
    };
  };

  onRadioChange($event: GroupSettingsLocationOptionType, ngModelDir: NgModel) {
    this.updateStateAndSelf(
      {
        option: $event,
        clusterId: null,
        siteIds: [],
      },
      ngModelDir
    );
  }

  onSelectChange($event: any, ngModelDir: NgModel) {
    this.updateStateAndSelf(
      {
        option: isArray($event) ? GroupSettingsLocationOptionType.SITES : GroupSettingsLocationOptionType.CLUSTER,
        clusterId: isArray($event) ? null : $event,
        siteIds: isArray($event) ? $event : [],
      },
      ngModelDir
    );
  }

  addInitialEntityToOptions<SV extends SmacsSelectValue | string>(options: string[] | SmacsSelectValue[], entity: any) {
    // we have to use a generic type because typescript doesn't like it when you push elements to a union-typed array.
    let addToInitialValues = false;
    if (!this.initialValues) {
      this.initialValues = [];
      addToInitialValues = true;
    }

    if (!entity) {
      return;
    }

    // Multi Select?
    if (Array.isArray(entity)) {
      (entity as SV[]).forEach((value) => {
        if (
          (this.bindValue &&
            !options.some((o) => isEqual(SmacsSelectService.lookupNestedValue(o, this.bindValue), value))) ||
          (!this.bindValue && !options.some((o) => isEqual(o, value)))
        ) {
          (options as SV[]).unshift(value);
          if (addToInitialValues) {
            this.initialValues.push(value);
          }
        }
      });
    } else {
      if (
        (this.bindValue &&
          !options.some((o) => isEqual(SmacsSelectService.lookupNestedValue(o, this.bindValue), entity))) ||
        (!this.bindValue && !options.some((o) => isEqual(o, entity)))
      ) {
        (options as SV[]).unshift(entity as SV);
        if (addToInitialValues) {
          this.initialValues.push(entity);
        }
      }
    }
  }

  triggerLoading = () => false;

  handleSearchInput($event: { term: string; items: any[] }) {
    this.searchedTerm = $event.term;
    this.searchHasChanged.emit(this.searchedTerm);
  }

  handleBlur() {
    this.selectBlur.emit();
  }
}
