import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { Global360ViewContext, ServiceTypes } from '../../../shared/contexts/global-360-view.context';
import {
  CiscoHelpdeskOptions,
  EmailUserOperationLevel,
  Global360View,
  ServiceProvisioningLevel,
  TileConfig,
} from '../../../shared/models/generated/smacsModels';
import { HelpdeskOptionsContext } from '../../shared/contexts/helpdesk-options.context';
import { Subscription } from 'rxjs';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { TileConfigContext } from '../../shared/contexts/tile-config.context';

@Component({
  selector: 'smacs-operations-menu',
  templateUrl: './operations-menu.component.html',
  styleUrls: ['./operations-menu.component.scss'],
})
export class OperationsMenuComponent implements OnInit, OnDestroy {
  @Output() reassignClicked = new EventEmitter<void>();
  @Output() makePublicClicked = new EventEmitter<void>();
  @Output() pinResetClicked = new EventEmitter<void>();
  @Output() changeSiteClicked = new EventEmitter<void>();
  @Output() sendEmailClicked = new EventEmitter<EmailUserOperationLevel>();

  smacsIcons = SmacsIcons;
  global360View: Global360View;
  helpdeskOptions: CiscoHelpdeskOptions;
  isDeskphoneEnabledOnAnyClusters: boolean;

  private _allCurrentServerIds: number[];

  private _subs = new Subscription();

  constructor(
    private helpdeskOptionsContext: HelpdeskOptionsContext,
    private global360ViewContext: Global360ViewContext,
    private _currentClusterContext: CurrentClusterContext,
    private _tileConfigContext: TileConfigContext
  ) {}

  ngOnInit() {
    const global360ViewSub = this.global360ViewContext.state$.subscribe((state: Global360View) => {
      this.global360View = state;
    });
    this._subs.add(global360ViewSub);

    const helpdeskOptionsSub = this.helpdeskOptionsContext.state$.subscribe((helpdeskOptions) => {
      if (this.helpdeskOptionsContext.isCiscoHelpdeskOptions(helpdeskOptions)) {
        this.helpdeskOptions = helpdeskOptions;
      }
    });
    this._subs.add(helpdeskOptionsSub);

    const currentClusterSub = this._currentClusterContext.state$.subscribe((currentCluster) => {
      this._allCurrentServerIds = this._currentClusterContext.getCurrentClusterServerIds(currentCluster);
    });
    this._subs.add(currentClusterSub);

    const tileConfigContextSub = this._tileConfigContext.state$.subscribe((tileConfig: TileConfig) => {
      this.isDeskphoneEnabledOnAnyClusters = tileConfig.deskphone !== ServiceProvisioningLevel.HIDE;
    });
    this._subs.add(tileConfigContextSub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  onReassignClick() {
    if (this.canReassign()) {
      this.reassignClicked.emit();
    }
  }

  onMakePublicClick() {
    if (this.canMakePublic()) {
      this.makePublicClicked.emit();
    }
  }

  onPinResetClick() {
    if (this.hasPinToReset()) {
      this.pinResetClicked.emit();
    }
  }

  onChangeSiteClick() {
    if (this.hasDevice()) {
      this.changeSiteClicked.emit();
    }
  }

  onSendEmailClick() {
    this.sendEmailClicked.emit(this.helpdeskOptions.sendEmailUserOperation);
  }

  canReassign(): boolean {
    return this.hasAnyCiscoServiceInstances();
  }

  getReassignToolTip(): string {
    if (!this.canReassign()) {
      return 'tkey;operations.menu.reassign.disabled_tooltip';
    }
  }

  canMakePublic(): boolean {
    return this.hasDeskphone();
  }

  getMakePublicToolTip(): string {
    if (!this.canMakePublic()) {
      return 'tkey;operations.menu.make_public.disabled_tooltip';
    }
  }

  hasPinToReset(): boolean {
    return this.hasExtensionMobility() || this.hasVoicemail();
  }

  getPinResetTooltip(): string {
    if (!this.hasPinToReset()) {
      return 'tkey;pages.pinreset.nopin.message';
    }
  }

  isChangeSiteEnabled(): boolean {
    return this.helpdeskOptions?.changeSiteEnabled;
  }

  isSendEmailEnabled(): boolean {
    return this.helpdeskOptions?.sendEmailUserOperation !== EmailUserOperationLevel.HIDE;
  }

  getChangeSiteToolTip(): string {
    if (!this.hasDevice()) {
      return 'tkey;operations.menu.change_site.disabled_tooltip';
    }
  }

  hasDevice(): boolean {
    return this.global360ViewContext.hasAnyDeviceInstances(this.global360View, this._allCurrentServerIds);
  }

  hasAnyCiscoServiceInstances(): boolean {
    return this.global360ViewContext.hasAnyServiceInstances(this.global360View, this._allCurrentServerIds);
  }

  hasDeskphone(): boolean {
    return this.global360ViewContext.hasInstanceOf(
      ServiceTypes.DESKPHONE,
      this.global360View,
      this._allCurrentServerIds
    );
  }

  hasVoicemail(): boolean {
    return this.global360ViewContext.hasInstanceOf(
      ServiceTypes.VOICEMAIL,
      this.global360View,
      this._allCurrentServerIds
    );
  }

  hasExtensionMobility(): boolean {
    return this.global360ViewContext.hasInstanceOf(
      ServiceTypes.EXTENSION_MOBILITY,
      this.global360View,
      this._allCurrentServerIds
    );
  }
}
