import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { CustomPowershellReport } from '../../../../shared/models/generated/smacsModels';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../../../button/button.component';
import { CustomPowershellReportContext } from '../../../../shared/contexts/custom-powershell-report.context';
import { EMPTY, Observable, Subscription, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { catchError, mergeMap, take, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsCodeAreaConfig, SmacsCodeAreaMode } from '../../../../forms/fields/code-area/smacs-code-area.component';
import { isEqual } from 'lodash';
import { DatatableColumn, DatatableRow } from '../../../datatable/datatable.component';

interface CustomPowershellReportDatatableRow extends DatatableRow {
  [key: string]: unknown;
}

export interface CustomPowershellReportForm {
  pwshQuery: string;
  reportName: string;
}

@Component({
  selector: 'app-custom-powershell-report-form',
  templateUrl: './custom-powershell-report-form.component.html',
  styleUrls: ['../../../reporting.scss'],
})
export class CustomPowershellReportFormComponent
  extends SmacsFormAbstractDirective<CustomPowershellReport, CustomPowershellReportForm>
  implements OnInit, OnDestroy
{
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  tableDataAutomation = 'custom-powershell-report-datatable';
  isLoading = true;
  report: CustomPowershellReport;
  isQueryRunning = false;
  hasQueryRun = false;
  queryResults: any[] = [];
  columns: DatatableColumn<CustomPowershellReportDatatableRow>[];
  tableData: CustomPowershellReportDatatableRow[];

  formConfig: SmacsFormConfig = {
    fields: {
      reportName: {
        label: 'tkey;reporting.custom_reports.custom_report_list.report_name',
        dataAutomation: 'custom-powershell-report-name',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        required: true,
        validation: [
          {
            validator: (val: string) => {
              if (!this.report) {
                return this._allReportNames.includes(val.trim().toLowerCase())
                  ? SmacsFormsValidationState.INVALID
                  : SmacsFormsValidationState.VALID;
              } else {
                return this.report.reportName.trim().toLowerCase() === val.trim().toLowerCase() ||
                  !this._allReportNames.includes(val.trim().toLowerCase())
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              }
            },
            message: 'tkey;reporting.microsoft.custom_powershell_reports.form.report_name.error',
          },
        ],
      },
      pwshQuery: {
        label: 'tkey;reporting.microsoft.custom_powershell_reports.form.pwsh_query.label',
        dataAutomation: 'custom-powershell-report-query',
        componentConfig: new SmacsCodeAreaConfig({ mode: SmacsCodeAreaMode.POWERSHELL }),
        required: true,
        validation: [
          {
            validator: () =>
              !this._isInvalidQuery ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: () => this._invalidQueryMessage,
          },
        ],
      },
    },
  };

  private _allReportNames: string[] = [];
  private _isInvalidQuery = false;
  private _invalidQueryMessage = '';
  private _submitEvent: 'SUBMIT' | 'QUERY' | 'EXPORT';
  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _breadcrumbsService: BreadcrumbsService,
    private _bottomNavService: BottomNavService,
    private _customPowershellReportContext: CustomPowershellReportContext,
    private _translateService: TranslateService,
    private _smacsModalService: SmacsModalService,
    private _toastService: ToastService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _activatedRoute: ActivatedRoute
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    const reportId = parseInt(this._activatedRoute.snapshot.params.id) || null;

    this._customPowershellReportContext.state$
      .pipe(take(1))
      .subscribe((customPowershellReports: CustomPowershellReport[]) => {
        this._allReportNames = customPowershellReports.map((report: CustomPowershellReport) =>
          report.reportName.toLowerCase()
        );
        if (reportId) {
          this.report = customPowershellReports.find((report: CustomPowershellReport) => report.id === reportId);
        }

        this._initFormData();
        this._initBreadcrumb();
        this._initBottomNav();
        this.isLoading = false;
      });

    const formUpdateSub = this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<CustomPowershellReportForm>) => {
      if (data.valid === SmacsFormsValidationState.VALID && this.isFormSubmitted) {
        this._bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: !this.isFormValid(),
          })
        );
      }
      if (data?.old?.pwshQuery !== data?.new?.pwshQuery) {
        this.hasQueryRun = false;
      }
      if (data.old && !isEqual(data.old, data.new)) {
        this._isInvalidQuery = false;
        this.fieldChannels['pwshQuery'].validateSource.next();
      }
    });
    this._subscriptions.add(formUpdateSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this._breadcrumbsService.clearBreadcrumbs();
  }

  toFormData = (entity: CustomPowershellReport): CustomPowershellReportForm => {
    return {
      pwshQuery: entity.pwshQuery,
      reportName: entity.reportName,
    };
  };

  toEntity = (formData: CustomPowershellReportForm): CustomPowershellReport => {
    return {
      id: this.report ? this.report.id : null,
      lastUpdated: this.report ? this.report.lastUpdated : null,
      reportName: formData.reportName,
      pwshQuery: formData.pwshQuery,
    };
  };

  onTestQueryClick() {
    this._submitEvent = 'QUERY';
    this._validateAndSubmitSource.next(true);
    this._testQuery();
  }

  private _testQuery(): Observable<void> {
    this.queryResults = [];
    this.isQueryRunning = true;
    this.hasQueryRun = false;
    return this._customPowershellReportContext.test(this.entity.pwshQuery).pipe(
      tap((response) => {
        this.queryResults = response.results;
        this.hasQueryRun = true;
        this._initTable();
        this.isQueryRunning = false;
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.TABLE,
          'tkey;reporting.microsoft.custom_powershell_reports.form.toast.query.success.title',
          'tkey;reporting.microsoft.custom_powershell_reports.form.toast.query.success.message'
        );
        this._resetButtonStates();
      }),
      catchError((response) => {
        this.isQueryRunning = false;
        this._handleQueryErrorResponse(response);
        return throwError(() => response);
      })
    );
  }

  private _saveReport(): Observable<any> {
    const request = this.report
      ? this._customPowershellReportContext.update({
          ...this.entity,
          id: this.report.id,
        })
      : this._customPowershellReportContext.create(this.entity);
    return request.pipe(
      tap((data) => {
        this.report = {
          ...this.entity,
          id: data,
        };
        this._toastService.pushSaveToast(
          'tkey;reporting.microsoft.custom_powershell_reports.title',
          this.entity.reportName,
          SmacsIcons.TABLE
        );
      }),
      catchError((response) => {
        this.isQueryRunning = false;
        this._handleQueryErrorResponse(response);
        return throwError(() => response);
      })
    );
  }

  protected submit() {
    this._bottomNavService.setBottomNavValidationError(false);
    if (this._submitEvent === 'QUERY') {
      this._submitEvent = null;
      this._setButtonState('custom-powershell-report-test', true, true);
      this._setButtonState('custom-powershell-report-export', false, true);
      this._setButtonState('custom-powershell-report-cancel', false, true);
      this._setButtonState('custom-powershell-report-delete', false, true);
      this._setButtonState('custom-powershell-report-save', false, true);
      return this._testQuery().pipe(
        tap(() => {
          setTimeout(() => {
            if (document.getElementById('custom-powershell-report-results-header')) {
              document.getElementById('custom-powershell-report-results-header').scrollIntoView({ behavior: 'smooth' });
            }
          });
        }),
        catchError(() => {
          this._bottomNavService.setBottomNavValidationError(true);
          return EMPTY;
        })
      );
    }
    if (this._submitEvent === 'SUBMIT') {
      this._submitEvent = null;
      this._setButtonState('custom-powershell-report-test', false, true);
      this._setButtonState('custom-powershell-report-export', false, true);
      this._setButtonState('custom-powershell-report-cancel', false, true);
      this._setButtonState('custom-powershell-report-delete', false, true);
      this._setButtonState('custom-powershell-report-save', true, true);

      if (!this.hasQueryRun && (!this.report || this._hasQueryChanged())) {
        return this._showRunQueryConfirmationModal().pipe(
          tap((response) => {
            this._resetButtonStates();
            if (response) {
              this._returnToList();
            }
          }),
          catchError(() => {
            this._bottomNavService.setBottomNavValidationError(true);
            return EMPTY;
          })
        );
      } else {
        return this._saveReport().pipe(
          tap(() => {
            this._resetButtonStates();
            this._returnToList();
          }),
          catchError(() => {
            this._bottomNavService.setBottomNavValidationError(true);
            return EMPTY;
          })
        );
      }
    }
    if (this._submitEvent === 'EXPORT') {
      this._submitEvent = null;
      if (!this.report || this._hasQueryChanged()) {
        const options = {
          windowClass: 'custom-report-run-and-save-report-modal',
          modalViewProperties: {
            title: this._translateService.instant(
              'tkey;reporting.microsoft.custom_powershell_reports.form.modal.title'
            ),
            promptBody: this._translateService.instant(
              'tkey;reporting.microsoft.custom_powershell_reports.form.modal.export.message'
            ),
            icon: SmacsIcons.DOWNLOAD,
            displayCloseButton: true,
            buttons: [
              {
                label: 'tkey;dialogs.button.cancel',
                buttonClass: ButtonStyles.DEFAULT,
                dataAutomation: 'confirmation-modal-cancel-button',
              },
              {
                label: 'tkey;reporting.microsoft.custom_powershell_reports.form.modal.export.button',
                buttonClass: ButtonStyles.PRIMARY,
                dataAutomation: 'confirmation-modal-confirm-button',
                cb: () => {
                  return this._testQuery()
                    .pipe(mergeMap(() => this._saveReport()))
                    .pipe(
                      mergeMap(() => {
                        return this._exportReport();
                      })
                    )
                    .pipe(
                      tap(() => {
                        this._initBottomNav();
                        this.smacsFormStateService.setIsFormDirty(false);
                      })
                    )
                    .pipe(
                      catchError((response) => {
                        return throwError(() => response);
                      })
                    );
                },
              },
            ],
          },
        };
        return this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
      } else {
        return this._exportReport();
      }
    }
  }

  private _showRunQueryConfirmationModal() {
    return new Observable((subscriber) => {
      const options = {
        windowClass: 'custom-powershell-report-confirmation-modal',
        modalViewProperties: {
          title: this._translateService.instant('tkey;reporting.microsoft.custom_powershell_reports.form.modal.title'),
          promptBody: this._translateService.instant(
            'tkey;reporting.microsoft.custom_powershell_reports.form.modal.query.message'
          ),
          icon: SmacsIcons.TABLE,
          displayCloseButton: true,
          buttons: [
            {
              label: 'tkey;dialogs.button.cancel',
              buttonClass: ButtonStyles.DEFAULT,
              dataAutomation: 'confirmation-modal-cancel-button',
            },
            {
              label: 'tkey;reporting.microsoft.custom_powershell_reports.form.modal.query.button',
              buttonClass: ButtonStyles.PRIMARY,
              dataAutomation: 'confirmation-modal-confirm-button',
              cb: () => {
                return this._testQuery()
                  .pipe(
                    mergeMap(() => {
                      return this._saveReport();
                    })
                  )
                  .pipe(
                    tap(() => {
                      subscriber.next(true);
                      subscriber.complete();
                    }),
                    catchError((response) => {
                      subscriber.error(response);
                      subscriber.complete();
                      return throwError(() => response);
                    })
                  );
              },
            },
          ],
        },
      };
      this._smacsModalService
        .openPromptModal(() => options.modalViewProperties, options)
        .subscribe((response) => {
          if (response === false) {
            subscriber.next(false);
            subscriber.complete();
          }
        });
    });
  }

  private _initTable() {
    if (this.queryResults.length > 0) {
      this.columns = Object.keys(this.queryResults[0]).map(
        (queryKey) =>
          ({
            name: queryKey,
            label: queryKey,
          } as DatatableColumn<CustomPowershellReportDatatableRow>)
      );
      this.tableData = this.queryResults;
    }
  }

  private _hasQueryChanged(): boolean {
    return this.report && this.report.pwshQuery !== this.entity.pwshQuery;
  }

  private _initFormData() {
    if (this.report) {
      this.entitySource.next(this.report);
    }
  }

  private _initBreadcrumb() {
    const breadcrumbLabel =
      this.report?.reportName || 'tkey;reporting.microsoft.custom_powershell_reports.form.breadcrumb.new_report.label';
    this._breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;reporting.microsoft.custom_powershell_reports.title',
        url: '/reporting/microsoft/custom-powershell-reports',
        routerLink: true,
      },
      { label: breadcrumbLabel },
    ]);
  }

  private _initBottomNav() {
    const bottomNavButtons = [
      {
        id: 'custom-powershell-report-test',
        dataAutomation: 'custom-powershell-report-test',
        label: 'tkey;reporting.microsoft.custom_powershell_reports.bottom_nav.query',
        icon: SmacsIcons.RUN,
        buttonClass: ButtonStyles.INFO,
        cb: () => {
          this._submitEvent = 'QUERY';
          this._validateAndSubmitSource.next(true);
        },
      },
      {
        id: 'custom-powershell-report-export',
        dataAutomation: 'custom-powershell-report-export',
        label: 'tkey;reporting.microsoft.custom_powershell_reports.bottom_nav.export',
        icon: SmacsIcons.EXPORT,
        buttonClass: ButtonStyles.INFO,
        cb: () => {
          this._submitEvent = 'EXPORT';
          this._validateAndSubmitSource.next(true);
        },
      },
      {
        id: 'custom-powershell-report-cancel',
        dataAutomation: 'custom-powershell-report-cancel',
        label: 'tkey;global.button.cancel.text',
        buttonClass: ButtonStyles.DEFAULT,
        cb: () => this._returnToList(),
      },
      {
        id: 'custom-powershell-report-save',
        dataAutomation: 'custom-powershell-report-save',
        label: 'tkey;global.button.save.text',
        icon: SmacsIcons.OK,
        buttonClass: ButtonStyles.PRIMARY,
        cb: () => {
          this._submitEvent = 'SUBMIT';
          this._validateAndSubmitSource.next(true);
        },
      },
    ];

    if (this.report?.id) {
      const deleteButton = {
        id: 'custom-powershell-report-delete',
        dataAutomation: 'custom-powershell-report-delete',
        label: 'tkey;global.button.delete.text',
        icon: SmacsIcons.DELETE,
        buttonClass: ButtonStyles.DANGER,
        cb: () => this._promptDeleteReport(),
      };
      bottomNavButtons.splice(3, 0, deleteButton);
    }

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _exportReport(): Observable<void> {
    this._setButtonState('custom-powershell-report-test', false, true);
    this._setButtonState('custom-powershell-report-export', true, true);
    this._setButtonState('custom-powershell-report-cancel', false, true);
    this._setButtonState('custom-powershell-report-delete', false, true);
    this._setButtonState('custom-powershell-report-save', false, true);
    return this._customPowershellReportContext.export(this.report.id).pipe(
      tap(() => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.EXPORT,
          'tkey;reporting.microsoft.custom_powershell_reports.toast.export.title',
          'tkey;reporting.microsoft.custom_powershell_reports.toast.export.message'
        );
        this._resetButtonStates();
      }),
      catchError((err) => {
        this._resetButtonStates();
        throw err;
      })
    );
  }

  private _promptDeleteReport() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        title: this._translateService.instant('tkey;reporting.microsoft.custom_powershell_reports.modal.delete.title'),
        promptBody: this._translateService.instant(
          'tkey;reporting.microsoft.custom_powershell_reports.modal.delete.message',
          {
            reportName: this.report.reportName,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteReport(),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteReport(): Observable<void> {
    return this._customPowershellReportContext.delete(this.report.id).pipe(
      tap(() => {
        this._toastService.pushDeleteToast(
          'tkey;reporting.microsoft.custom_powershell_reports.title',
          this.report.reportName
        );
        this._returnToList();
      })
    );
  }

  private _returnToList() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  private _resetButtonStates() {
    this._setButtonState('custom-powershell-report-test', false, false);
    this._setButtonState('custom-powershell-report-export', false, false);
    this._setButtonState('custom-powershell-report-cancel', false, false);
    this._setButtonState('custom-powershell-report-delete', false, false);
    this._setButtonState('custom-powershell-report-save', false, false);
  }

  private _setButtonState(buttonId: string, pending: boolean, disabled: boolean, tooltip?: string) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: {
          pending: pending,
          buttonDisableState: { disabled: disabled, tooltipKey: tooltip || '' },
        },
      })
    );
  }

  private _handleQueryErrorResponse(error: any) {
    this._isInvalidQuery = true;
    this._invalidQueryMessage = error.error.description;
    this.fieldChannels['pwshQuery'].validateSource.next();
    this._toastService.push(
      ToastTypes.ERROR,
      SmacsIcons.TABLE,
      'tkey;reporting.microsoft.custom_powershell_reports.form.toast.query.error.title',
      'tkey;reporting.microsoft.custom_powershell_reports.form.toast.query.error.message'
    );
    this._resetButtonStates();
  }
}
