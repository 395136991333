import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { DateAgoPipe } from '../../../../shared/pipes/date-ago.pipe';
import { catchError, forkJoin, Observable, of, switchMap, throwError } from 'rxjs';
import { ButtonStyles } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { finalize, tap } from 'rxjs/operators';
import { BandwidthE911Dlr, JobState, JobStatus } from '../../../../shared/models/generated/smacsModels';
import { DlrMisconfigurationsReportContext } from './services/dlr-misconfigurations-report.context';
import { DlrMisconfigurationsPollingService } from './services/dlr-misconfigurations-polling.service';
import { DlrMisconfigurationsDatatableComponent } from './dlr-misconfigurations-datatable.component';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { BandwidthE911DlrsResource } from '../../../../helpdesk/shared/resources/bandwidth-e911-dlrs.resource';
import { BottomNavButton } from '../../../../shared/bottom-nav/bottom-nav.component';
import { TelephoneNumberFilter } from '../../../../shared/filters/telephone-number.filter';

@Component({
  selector: 'smacs-dlr-misconfigurations',
  templateUrl: './dlr-misconfigurations.component.html',
  styleUrls: ['../../../admin-page.scss'],
})
export class DlrMisconfigurationsComponent implements OnInit, OnDestroy {
  @ViewChild(DlrMisconfigurationsDatatableComponent) datatableComponent: DlrMisconfigurationsDatatableComponent;
  isLoading = true;
  lastUpdated: string;
  private _isCreateMissingInProgress = false;
  private _refreshButton: BottomNavButton = {
    id: 'billing-refresh',
    buttonClass: ButtonStyles.DEFAULT,
    icon: SmacsIcons.REFRESH,
    label: 'tkey;admin.billing.dlr_misconfigurations.refresh.label',
    dataAutomation: 'billing-refresh-button',
    cb: () => this._onRefreshClicked(),
    state: {
      buttonDisableState: {
        disabled: true,
      },
    },
  };
  private _createMissingButton: BottomNavButton = {
    id: 'create-missing',
    buttonClass: ButtonStyles.PRIMARY,
    icon: SmacsIcons.FIX_IT,
    label: 'tkey;admin.billing.dlr_misconfigurations.create_missing_button.label',
    dataAutomation: 'create-missing-button',
    cb: () => this._onCreateMissingButtonClicked(),
    state: {
      buttonDisableState: {
        disabled: true,
        tooltipKey: 'tkey;admin.billing.dlr_misconfigurations.create_missing_button.disabled_tooltip',
      },
      tooltipVisible: true,
    },
  };

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private bottomNavService: BottomNavService,
    private dlrMisconfigurationsReportContext: DlrMisconfigurationsReportContext,
    private dlrMisconfigurationsPollingService: DlrMisconfigurationsPollingService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private dateAgoPipe: DateAgoPipe,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _bandwidthE911DlrsResource: BandwidthE911DlrsResource,
    private _telephoneNumberFilter: TelephoneNumberFilter
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;admin.billing.dlr_misconfigurations.title',
      },
    ]);

    this._initBottomNav();
    this._waitForIdle(true)
      .pipe(
        switchMap(() => {
          if (!this.lastUpdated) {
            this._setButtonPending('billing-refresh', true);
            return this._refreshReport();
          } else {
            this._updateBottomNavTimestamp();
            return this.dlrMisconfigurationsReportContext.initReport();
          }
        })
      )
      .subscribe(() => {
        this.isLoading = false;
        this._setButtonDisabled('billing-refresh', false);
        this._setButtonPending('billing-refresh', false);
        this._setButtonDisabled('billing-export', false);
      });
  }

  ngOnDestroy() {
    this.dlrMisconfigurationsPollingService.stopPolling();
  }

  handleRowSelected(): void {
    if (!!this.datatableComponent?.tableRows) {
      const rowsSelected = this.datatableComponent.tableRows.filter((result) => result.isSelectedInTable);
      this.bottomNavService.dispatch(
        new BottomNavUpdateButtonsList([
          this._refreshButton,
          {
            ...this._createMissingButton,
            state: {
              buttonDisableState: {
                disabled: !rowsSelected.length,
                tooltipKey: !rowsSelected.length
                  ? 'tkey;admin.billing.dlr_misconfigurations.create_missing_button.disabled_tooltip'
                  : '',
              },
              tooltipVisible: !rowsSelected.length,
            },
            label:
              rowsSelected.length >= 1
                ? rowsSelected.length === 1
                  ? 'tkey;admin.billing.dlr_misconfigurations.create_missing_button_single.label'
                  : 'tkey;admin.billing.dlr_misconfigurations.create_missing_button_multiple.label'
                : 'tkey;admin.billing.dlr_misconfigurations.create_missing_button.label',
            labelParam: rowsSelected.length,
          },
        ])
      );
    }
  }

  private _initBottomNav() {
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList([this._refreshButton, this._createMissingButton]));
  }

  private _onRefreshClicked(): void {
    this.isLoading = true;
    this._setButtonPending('billing-refresh', true);
    this._setButtonDisabled('billing-export', true);
    this._refreshReport().subscribe(() => {
      this._setButtonDisabled('billing-export', false);
      this._setButtonPending('billing-refresh', false);
      this.toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.REFRESH,
        'tkey;admin.billing.dlr_misconfigurations.refresh.toast.title',
        'tkey;admin.billing.dlr_misconfigurations.refresh.toast.message'
      );
      this.isLoading = false;
    });
  }

  private _refreshReport(): Observable<void> {
    return this.dlrMisconfigurationsReportContext.refreshReport().pipe(
      switchMap(() => this._waitForIdle()),
      tap(() => this._updateBottomNavTimestamp()),
      switchMap(() => this.dlrMisconfigurationsReportContext.initReport())
    );
  }

  private _waitForIdle(setRefreshButtonToPending = false): Observable<void> {
    this.dlrMisconfigurationsPollingService.startPolling();
    // we have to create a new observable because stopPolling clears the state and breaks the emission chain
    return new Observable<void>((subscriber) => {
      this.dlrMisconfigurationsPollingService.state$.subscribe({
        next: (jobStatus) => {
          if (this._isJobIdle(jobStatus)) {
            this.lastUpdated = jobStatus.lastRunTime;
            this.dlrMisconfigurationsPollingService.stopPolling();
            subscriber.next();
            subscriber.complete();
          } else if (setRefreshButtonToPending) {
            setRefreshButtonToPending = false;
            this._setButtonPending('billing-refresh', true);
          }
        },
        error: (err) => {
          subscriber.error(err);
          subscriber.complete();
        },
      });
    });
  }

  private _isJobIdle(status: JobStatus): boolean {
    if (status.jobState === JobState.RUNNING || status.jobState === JobState.QUEUED) {
      return false;
    } else if (status.error) {
      this.dlrMisconfigurationsPollingService.stopPolling();
      this._setButtonDisabled('billing-refresh', false);
      throw new Error(status.error);
    } else {
      return true;
    }
  }

  private _updateBottomNavTimestamp(): void {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
        helpText: `
              <i class="${SmacsIcons.CLOCK}"></i>
              ${this.translateService.instant(
                'tkey;admin.billing.dlr_misconfigurations.refresh.previous_time'
              )} <strong>${this.dateAgoPipe.transform(this.lastUpdated)}</strong>
            `,
      })
    );
  }

  private _setButtonPending(buttonId: string, isPending: boolean): void {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: {
          pending: isPending,
        },
      })
    );
  }

  private _setButtonDisabled(buttonId: string, isDisabled: boolean): void {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: { buttonDisableState: { disabled: isDisabled, tooltipKey: '' } },
      })
    );
  }

  private _onCreateMissingButtonClicked(): void {
    const options = {
      windowClass: 'create-missing-modal',
      modalViewProperties: {
        title: 'tkey;admin.billing.dlr_misconfigurations.create_missing.modal.title',
        icon: SmacsIcons.FIX_IT,
        iconClass: 'text-info',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center',
        promptBody: this._translateService.instant(
          'tkey;admin.billing.dlr_misconfigurations.create_missing.modal.body',
          {
            num: this.datatableComponent.tableRows.filter((row) => row.isSelectedInTable).length,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;admin.billing.dlr_misconfigurations.create_missing.modal.confirm_button',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'confirmation-modal-confirm-button',
            isPending: this._isCreateMissingInProgress,
            cb: () => this._onCreateMissingConfirmClicked(),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _onCreateMissingConfirmClicked(): Observable<readonly unknown[]> {
    this._isCreateMissingInProgress = true;
    const createMissingObs$ = this.datatableComponent.tableRows
      .filter((row) => row.isSelectedInTable)
      .map((filteredRow) => {
        const bandwidthE911Dlr: BandwidthE911Dlr = {
          id: filteredRow.dlrEndpointId,
          geolocation: filteredRow.geolocation,
          preferredLanguage: 'en',
          callbackNumber: filteredRow.callbackNumber,
          callerName: filteredRow.callerName,
        };
        return this._bandwidthE911DlrsResource.post(bandwidthE911Dlr).pipe(
          tap(() => {
            this.toastService.push(
              ToastTypes.SUCCESS,
              SmacsIcons.FIX_IT,
              'tkey;admin.billing.dlr_misconfigurations.create_missing.toast.title',
              this._translateService.instant('tkey;admin.billing.dlr_misconfigurations.create_missing.toast.message', {
                callerName: filteredRow.callerName,
                callbackNumber: this._telephoneNumberFilter.transform(filteredRow.callbackNumber),
              })
            );
          }),
          catchError((error) => {
            if (error.status === 422) {
              this.toastService.push(
                ToastTypes.ERROR,
                SmacsIcons.WARNING,
                'tkey;admin.billing.dlr_misconfigurations.create_missing_error.title',
                this._translateService.instant(
                  'tkey;admin.billing.dlr_misconfigurations.create_missing_error.message',
                  {
                    dlrId: filteredRow.dlrEndpointId,
                    errorMessage: error.error?.description,
                  }
                )
              );
              return of(null);
            } else {
              return throwError(() => error);
            }
          })
        );
      });
    return forkJoin(createMissingObs$).pipe(
      finalize(() => {
        this._resetCreateMissingButton();
        this._onRefreshClicked();
      })
    );
  }

  private _resetCreateMissingButton() {
    this._isCreateMissingInProgress = false;
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        this._refreshButton,
        {
          ...this._createMissingButton,
          state: {
            buttonDisableState: {
              disabled: true,
              tooltipKey: 'tkey;admin.billing.dlr_misconfigurations.create_missing_button.disabled_tooltip',
            },
            tooltipVisible: true,
          },
          label: 'tkey;admin.billing.dlr_misconfigurations.create_missing_button.label',
        },
      ])
    );
  }
}
