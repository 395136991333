<div [attr.data-automation]="config.dataAutomation">
  <h5>
    <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
    <span [innerHTML]="getLabel() | translate"></span>
  </h5>
  <div class="align-items-center d-flex group-item-text-wrapper">
    <p class="general-settings-text__content" [innerHTML]="config.options?.content | translate"></p>
    <div class="general-settings-text__input">
      <div
        class="mb-3 row"
        [hidden]="state.hidden"
        [attr.data-automation]="config.dataAutomation + '-input'"
        [ngClass]="{
          'has-feedback': showValidation || isFormSubmitted,
          'smacs-forms-error': validationState === ValStates.INVALID,
          'smacs-forms-warning': validationState === ValStates.PENDING || validationState === ValStates.WARNING,
          'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
        }"
      >
        <div class="col">
          <div
            class="input-group"
            [ngClass]="{ 'input-group-lg': inputSize === inputSizes.LG, 'input-group-sm': inputSize === inputSizes.SM }"
          >
            <span *ngIf="inputAddOn && inputAddOn?.prependedContent" class="input-group-text">
              {{ inputAddOn?.prependedContent | translate }}
            </span>
            <input
              class="form-control"
              [ngClass]="{ 'smacs-text-pending': validationState === ValStates.PENDING }"
              [disabled]="state.disabled"
              [type]="inputType"
              [attr.id]="config.dataAutomation + '-input'"
              #ngModelDir="ngModel"
              (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
              [(ngModel)]="fieldData"
              [placeholder]="placeholder"
              data-automation="smacs-text-input"
            />
            <span *ngIf="inputAddOn && inputAddOn?.appendedContent" class="input-group-text">
              {{ inputAddOn?.appendedContent | translate }}
            </span>

            <span
              class="{{ smacsIcons.SAVING }} form-control-feedback"
              [hidden]="!(validationState === ValStates.PENDING && showAsyncValidation)"
              data-automation="smacs-text-async-pending"
            ></span>

            <span
              class="{{ smacsIcons.REMOVE }} form-control-feedback"
              [hidden]="!(validationState === ValStates.INVALID && showAsyncValidation)"
              data-automation="smacs-text-async-invalid"
            ></span>

            <span
              class="{{ smacsIcons.OK }} form-control-feedback"
              [hidden]="!(validationState === ValStates.VALID && showAsyncValidation)"
              data-automation="smacs-text-async-valid"
            ></span>
          </div>

          <div class="smacs-forms-feedback-area">
            <div
              class="smacs-forms-validation-message"
              data-automation="smacs-forms-validation-message"
              [hidden]="!showValidation && !isFormSubmitted"
            >
              {{ validationMessage?.content | translate : validationMessage?.params }}
            </div>
            <div class="form-text small" *ngIf="isAutogenerated" data-automation="smacs-forms-autogeneration-message">
              {{ config.autogeneration?.generatedMsg | translate }}
            </div>
            <span class="small form-text text-muted" *ngIf="config.helpText">
              {{ getHelpText() | translate }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
