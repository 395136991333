<div class="admin-ldap-sync smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div [hidden]="isLoading" class="col-md-10 offset-md-1">
    <app-breadcrumbs></app-breadcrumbs>

    <div class="card border-primary mb-4">
      <div class="card-header bg-primary text-white">
        {{ 'tkey;admin.ldap_sync.automatic_sync.panel.title' | translate }}
      </div>
      <div class="card-body">
        <div class="card bg-light mb-3">
          <div class="card-body" [innerHTML]="'tkey;admin.ldap_sync.automatic_sync.description' | translate"></div>
        </div>

        <smacs-automatic-sync-form [ldapSyncConfig]="ldapSyncConfig"></smacs-automatic-sync-form>
      </div>
    </div>

    <div class="card border-primary mb-4">
      <div class="card-header bg-primary text-white">
        {{ 'tkey;admin.ldap_sync.manual_sync.panel.title' | translate }}
      </div>
      <div class="card-body">
        <div class="card bg-light mb-3">
          <div class="card-body">{{ 'tkey;admin.ldap_sync.manual_sync.description' | translate }}</div>
        </div>

        <table class="table table-striped table-hover">
          <thead>
            <tr class="table-secondary">
              <th>{{ 'tkey;admin.ldap_sync.manual_sync.table_header.cluster.text' | translate }}</th>
              <th>{{ 'tkey;admin.ldap_sync.manual_sync.table_header.cucm_server.text' | translate }}</th>
              <th>{{ 'tkey;admin.ldap_sync.manual_sync.table_header.unity_servers.text' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let cluster of ldapJobStatus?.clusters; trackBy: trackByClusterId"
              data-automation="ldap-sync-cluster-row"
            >
              <td data-automation="ldap-sync-cluster-row-cluster-name">{{ cluster.clusterName }}</td>
              <td data-automation="ldap-sync-cluster-row-cucm-name">
                <i
                  class="icon-server-status fa {{ cluster.cucmServer | ldapSyncStatusFilter }}"
                  data-automation="cucm-status"
                  [hidden]="!isSyncing && !syncError"
                  [disableTooltip]="cluster.cucmServer.status !== ldapSyncStatus.ERROR"
                  [ngbTooltip]="
                    'tkey;admin.ldap_sync.manual_sync.server.error.tooltip'
                      | translate : { errorMessage: cluster.cucmServer.errorMessage }
                  "
                ></i>
                {{ cluster.cucmServer.name }}
              </td>
              <td>
                <span
                  *ngFor="let unityServer of cluster.unityServers; trackBy: trackByUnityServerId"
                  data-automation="ldap-sync-cluster-row-unity-name"
                >
                  <span>
                    <i
                      class="icon-server-status fa {{ unityServer | ldapSyncStatusFilter }}"
                      data-automation="unity-status"
                      [hidden]="!isSyncing && !syncError"
                      [disableTooltip]="unityServer.status !== ldapSyncStatus.ERROR"
                      [ngbTooltip]="
                        'tkey;admin.ldap_sync.manual_sync.server.error.tooltip'
                          | translate : { errorMessage: unityServer.errorMessage }
                      "
                    ></i>
                  </span>
                  {{ unityServer.name }}&nbsp;
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="d-flex flex-row-reverse">
          <smacs-button
            [dataAutomation]="'sync-all-btn'"
            [icon]="smacsIcons.OK"
            [iconPending]="smacsIcons.SAVING"
            [isPending]="isSyncing"
            [label]="
              isSyncing
                ? 'tkey;admin.ldap_sync.manual_sync.in_progress.button'
                : 'tkey;admin.ldap_sync.manual_sync.sync_all.button'
            "
            (clicked)="onSyncAllClick()"
          ></smacs-button>
        </div>
      </div>
    </div>
  </div>
</div>
