import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  CurrentUser,
  GlobalProperties,
  GraphDetails,
  MicrosoftGraphRegistrationDetails,
  Role,
} from '../../shared/models/generated/smacsModels';
import { combineLatest, Observable, ReplaySubject, Subscriber, Subscription } from 'rxjs';
import { GlobalPropertiesContext } from '../../shared/contexts/global-properties.context';
import { AuthenticationContext } from '../../shared/contexts/authentication.context';

@Injectable()
export class MsGraphConfigContext implements OnDestroy {
  _stateSource = new ReplaySubject<MicrosoftGraphRegistrationDetails>(1);
  state$ = this._stateSource.asObservable();

  private _baseUrl = '/services/microsoft/admin/ms-graph';
  private _subscriptions = new Subscription();

  constructor(
    private _http: HttpClient,
    private _globalPropertiesContext: GlobalPropertiesContext,
    private _authenticationContext: AuthenticationContext
  ) {
    const sub = combineLatest([this._globalPropertiesContext.state$, this._authenticationContext.state$]).subscribe(
      ([globalProperties, currentUser]: [GlobalProperties, CurrentUser]) => {
        if (globalProperties.hostedEnabled && this._authenticationContext.userIsAtLeast(currentUser, Role.S8_ADMIN)) {
          this._init().subscribe();
        }
      }
    );

    this._subscriptions.add(sub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  postValidate(msGraphRegistrationDetails: MicrosoftGraphRegistrationDetails): Observable<GraphDetails> {
    return this._http.post<GraphDetails>(`${this._baseUrl}/validation`, msGraphRegistrationDetails);
  }

  put(msGraphRegistrationDetails: MicrosoftGraphRegistrationDetails): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._http.put<void>(this._baseUrl, msGraphRegistrationDetails).subscribe(() => {
        this._init().subscribe(() => {
          subscriber.next(null);
          subscriber.complete();
        });
      });
    });
  }

  delete(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._http.delete<void>(this._baseUrl).subscribe(() => {
        this._init().subscribe(() => {
          subscriber.next(null);
          subscriber.complete();
        });
      });
    });
  }

  refresh(): Observable<void> {
    return this._init();
  }

  details(): Observable<GraphDetails> {
    return this._http.get<GraphDetails>(`${this._baseUrl}/token-details`);
  }

  private _init(): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      this._http
        .get<MicrosoftGraphRegistrationDetails>(this._baseUrl)
        .subscribe((data: MicrosoftGraphRegistrationDetails) => {
          this._stateSource.next(data);
          subscriber.next();
          subscriber.complete();
        });
    });
  }
}
