import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  SiteBasedHelpdeskUserPermission,
  SiteBasedHelpdeskUserPermissions,
} from '../../shared/models/generated/smacsModels';

@Injectable()
export class SiteMacManagementPermissionsResource {
  private _baseUrl = `/services/admin/site-mac-management/permissions`;
  constructor(private $http: HttpClient) {}

  get(): Observable<SiteBasedHelpdeskUserPermissions> {
    return this.$http.get<SiteBasedHelpdeskUserPermissions>(this._baseUrl);
  }

  put(body: SiteBasedHelpdeskUserPermission): Observable<void> {
    return this.$http.put<void>(`${this._baseUrl}/${body.userId}`, body);
  }
}
