import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MicrosoftTeamsUserResult } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class MicrosoftTeamsUserSearchResource {
  constructor(private http: HttpClient) {}

  search(query: string, enterpriseVoiceEnabled?: boolean, limit = 250): Observable<MicrosoftTeamsUserResult[]> {
    let params = `q=${encodeURIComponent(query)}`;
    if (limit != null) {
      params += `&limit=${limit}`;
    }
    if (enterpriseVoiceEnabled != null) {
      params += `&enterpriseVoiceEnabled=${enterpriseVoiceEnabled}`;
    }
    return this.http.get<MicrosoftTeamsUserResult[]>(`/services/microsoft/macs/teams-users/search?${params}`);
  }
}
