import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ButtonStyles } from '../../../../button/button.component';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import {
  CiscoDialPlanFieldConfig,
  DialPlanGroupEntry,
  DialPlanGroupEntryType,
  UsageType,
} from '../../../../shared/models/generated/smacsModels';
import { Observable, of } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import {
  ExtensionSelectorSize,
  SmacsExtensionSelectorConfig,
} from '../../../shared/extension-selector/extension-selector.component';
import { DialPlanInventoriesResource } from '../../../../shared/resources/dial-plan-inventories.resource';
import { map } from 'rxjs/operators';

export interface DnDidSelectorForm {
  dialPlanGroupId: number;
  extension: string;
  pattern: string;
}

export interface DnDidSelectorFormEntity {
  dialPlanGroupId: number;
  directoryNumber: {
    extension: string;
  };
  translationPattern: {
    pattern: string;
  };
}

@Component({
  selector: 'smacs-directory-number-dn-did-selector-form',
  templateUrl: './directory-number-dn-did-selector-form.component.html',
  styleUrls: ['./directory-number-dn-did-selector-form.component.scss'],
})
export class SmacsDirectoryNumberDnDidSelectorFormComponent
  extends SmacsFormAbstractDirective<DnDidSelectorFormEntity, DnDidSelectorForm>
  implements OnInit, OnChanges
{
  @Input() dialPlanGroups: CiscoDialPlanFieldConfig[] = [];
  @Input() initialDialPlanGroupId: number;
  @Input() initialExtension: string;
  @Input() isZpmSyncWarningPresent: boolean;

  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;

  showInactive = true;
  showRecentlyDeleted = true;
  showUnavailable = false;

  formConfig: SmacsFormConfig;

  selectedDialPlanGroup: CiscoDialPlanFieldConfig;
  selectedGroupDnOptions: DialPlanGroupEntry[] = [];
  selectedGroupDidOptions: DialPlanGroupEntry[] = [];
  selectorSize = ExtensionSelectorSize.SMALL;

  isSubmitted = false;
  isSearching = false;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private dialPlanInventoriesResource: DialPlanInventoriesResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._initFormConfig();

    if (this.initialDialPlanGroupId) {
      this.selectedDialPlanGroup = this.dialPlanGroups.find(
        (dialPlanGroup) => dialPlanGroup.id === this.initialDialPlanGroupId
      );
      if (this.selectedDialPlanGroup) {
        this.onDialPlanGroupChange(false);
      }
    }

    this._validateAndSubmitSource.subscribe(() => {
      this.isSubmitted = true;
    });
  }

  toFormData = (entity: DnDidSelectorFormEntity): DnDidSelectorForm => {
    return {
      dialPlanGroupId: entity?.dialPlanGroupId,
      extension: entity?.directoryNumber?.extension,
      pattern: entity?.translationPattern?.pattern,
    };
  };

  toEntity = (formData: DnDidSelectorForm): DnDidSelectorFormEntity => {
    return {
      dialPlanGroupId: this.selectedDialPlanGroup?.id,
      directoryNumber: {
        extension: formData.extension,
      },
      translationPattern: {
        pattern: formData.pattern,
      },
    };
  };

  isFormValid(): boolean {
    return !!this.selectedDialPlanGroup;
  }

  revert(oldValue: DnDidSelectorFormEntity) {
    this.entitySource.next(oldValue);
  }

  onDialPlanGroupChange(setFormDirty = true) {
    this.isSearching = true;
    this.entitySource.next({
      ...this.entity,
      dialPlanGroupId: this.selectedDialPlanGroup ? this.selectedDialPlanGroup.id : null,
    });
    this.applyComponentConfig();
    this.smacsFormStateService.setIsFormDirty(setFormDirty);
  }

  applyConfigs() {
    this.selectorSize = this.selectedGroupDidOptions.length ? ExtensionSelectorSize.SMALL : ExtensionSelectorSize.LARGE;

    const dnComponent = this.fieldComponents.find((field) => field.fieldId === 'extension');
    dnComponent.applyComponentConfig(
      new SmacsExtensionSelectorConfig({
        options: this.selectedGroupDnOptions,
        showRecentlyDeleted: this.showRecentlyDeleted,
        showUnavailable: this.showUnavailable,
        size: this.selectorSize,
        showInactive: this.showInactive,
      })
    );

    const didComponent = this.fieldComponents.find((field) => field.fieldId === 'pattern');
    didComponent.applyComponentConfig(
      new SmacsExtensionSelectorConfig({
        options: this.selectedGroupDidOptions,
        showRecentlyDeleted: this.showRecentlyDeleted,
        showUnavailable: this.showUnavailable,
        size: this.selectorSize,
        showInactive: this.showInactive,
      })
    );
  }

  applyComponentConfig() {
    this._getDialPlanInventory(this.selectedDialPlanGroup.id).subscribe(() => {
      this.applyConfigs();
      this.isSearching = false;
    });
  }

  protected submit() {
    return of(null);
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        dialPlanGroupId: {
          label: '',
          dataAutomation: 'dn-did-selector-group',
          hidden: () => true,
        },
        extension: {
          label: 'tkey;dndidselection.dn',
          dataAutomation: 'dn-extension-selector',
          required: true,
          componentConfig: new SmacsExtensionSelectorConfig({
            options: this.selectedGroupDnOptions,
            showRecentlyDeleted: this.showRecentlyDeleted,
            showUnavailable: this.showUnavailable,
            size: this.selectorSize,
            showInactive: this.showInactive,
          }),
        },
        pattern: {
          label: 'tkey;dndidselection.did',
          dataAutomation: 'did-extension-selector',
          required: false,
          componentConfig: new SmacsExtensionSelectorConfig({
            options: this.selectedGroupDidOptions,
            showRecentlyDeleted: this.showRecentlyDeleted,
            showUnavailable: this.showUnavailable,
            size: this.selectorSize,
            showInactive: this.showInactive,
          }),
        },
      },
    };
  }

  private _getDialPlanInventory(groupId: number): Observable<void> {
    return this.dialPlanInventoriesResource.get(groupId).pipe(
      map((inventory) => {
        this.selectedGroupDnOptions = inventory.dialPlanEntries;

        this.selectedGroupDidOptions = inventory.translationPatternEntries.map<DialPlanGroupEntry>((tpEntry) => {
          return {
            ...tpEntry,
            pbxType: DialPlanGroupEntryType.CUCM,
            usageTypes: [UsageType.NOT_APPLICABLE],
          };
        });
      })
    );
  }
}
