import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalViewProperties } from '../modal-view-properties';
import { DirectoryNumberRef, EndUser, EndUserRef, EndUserResult } from '../../shared/models/generated/smacsModels';
import { EndUserResource } from '../../shared/resources/end-user.resource';
import { Observable, Subscription } from 'rxjs';
import { ModalBodyClass } from '../modal-body';
import { SmacsFormConfig } from '../../forms/smacs-forms-models';
import { PromptModalComponent } from '../prompt-modal/prompt-modal.component';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { SmacsSelectConfig } from '../../forms/fields/select/smacs-select.component';

export interface PrimaryExtensionPickerModalViewProperties extends ModalViewProperties {
  user: EndUserResult;
  extensions: DirectoryNumberRef[];
}

export interface PrimaryExtensionPickerModalComponentReturnValue {
  endUserRef: EndUserRef;
  dnRef: DirectoryNumberRef;
}

@Component({
  selector: 'smacs-primary-extension-picker-modal',
  templateUrl: './primary-extension-picker-modal.component.html',
})
export class PrimaryExtensionPickerModalComponent extends ModalBodyClass implements OnDestroy, OnInit {
  protected formConfig: SmacsFormConfig;
  modalViewProperties: PrimaryExtensionPickerModalViewProperties;
  selectedExtension: string;

  private _subscriptions = new Subscription();

  constructor(
    private endUserResource: EndUserResource,
    private modalComponent: PromptModalComponent<any>,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    const passedExtensions = this.modalComponent.modalViewProperties.extensions;
    const extensions = passedExtensions.map((extension: any) => {
      return extension.extension;
    });
    this.formConfig = {
      fields: {
        primaryExtensionPicker: {
          dataAutomation: 'smacs-select-input',
          componentConfig: new SmacsSelectConfig({
            options: extensions,
            bindValue: 'extension',
            clearWithInput: true,
            hideClear: true,
          }),
          required: true,
        },
      },
      options: {
        columnClasses: {
          label: 'd-none',
          input: 'col-12',
        },
      },
    } as SmacsFormConfig;
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  submit(): Observable<any> {
    this.selectedExtension = this.formData.primaryExtensionPicker;
    return new Observable((subscriber) => {
      this.endUserResource
        .get(
          this.modalComponent.modalViewProperties.user.ref.id,
          this.modalComponent.modalViewProperties.user.ref.serverId
        )
        .subscribe((endUser: EndUser) => {
          const selectedDnRef = this.modalComponent.modalViewProperties.extensions.find(
            (extension: DirectoryNumberRef) => extension.extension === this.selectedExtension
          );
          endUser = { ...endUser, primaryExtension: selectedDnRef };
          this.endUserResource
            .put(endUser, this.modalComponent.modalViewProperties.user.ref.serverId)
            .subscribe((endUserRef: EndUserRef) => {
              subscriber.next({
                endUserRef: endUserRef,
                dnRef: selectedDnRef,
              });
              subscriber.complete();
            });
        });
    });
  }
}
