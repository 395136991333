import { Component, OnInit } from '@angular/core';
import { ClusterResult, SiteResult } from '../../shared/models/generated/smacsModels';
import { CurrentClusterContext } from '../../shared/contexts/current-cluster.context';
import { PromptModalComponent } from '../prompt-modal/prompt-modal.component';
import { ModalBodyClass } from '../modal-body';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { Observable, of } from 'rxjs';
import { SmacsFormConfig } from '../../forms/smacs-forms-models';
import { SmacsSelectConfig } from '../../forms/fields/select/smacs-select.component';
import { sortBy } from 'lodash';
import { ClusterSite } from '../../helpdesk/shared/contexts/site.context';

export interface SiteResultOption {
  label: string;
  value: string;
  group: string;
}

@Component({
  selector: 'smacs-site-picker-modal',
  templateUrl: './site-picker-modal.component.html',
})
export class SitePickerModalComponent extends ModalBodyClass implements OnInit {
  protected formConfig: SmacsFormConfig;
  selectedSite: SiteResult;
  sites: SiteResult[];
  clusterMapping: ClusterSite[];
  isUserPartiallyLdapActive: boolean;
  otherClusters: ClusterResult[];

  constructor(
    private currentClusterContext: CurrentClusterContext,
    private modalComponent: PromptModalComponent<any>,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.sites = this.modalComponent.modalViewProperties.allowedSites;
    this.isUserPartiallyLdapActive = this.modalComponent.modalViewProperties.isUserPartiallyLdapActive;
    this.otherClusters = this.modalComponent.modalViewProperties.otherClusters;
    this.clusterMapping = this.modalComponent.modalViewProperties?.clusterMapping;
    const allowedSites = this.clusterMapping.map((clusterSite) => {
      return {
        label: clusterSite.site,
        value: clusterSite.id,
        group: clusterSite.cluster,
      } as SiteResultOption;
    });

    this.formConfig = {
      fields: {
        sitePickerSelect: {
          dataAutomation: 'smacs-select-input',
          componentConfig: new SmacsSelectConfig({
            options: sortBy(allowedSites),
            bindValue: 'label',
            clearWithInput: true,
            hideClear: true,
          }),
          required: true,
        },
      },
      options: {
        columnClasses: {
          label: 'd-none',
          input: 'col-12',
        },
      },
    } as SmacsFormConfig;
  }

  getClusters(): string {
    return this.otherClusters.map((cluster) => cluster.name).join(',  ');
  }

  submit(): Observable<SiteResult> {
    const selectedSiteName = this.formData.sitePickerSelect;
    this.selectedSite = this.sites.find((site) => {
      return site.name === selectedSiteName;
    });

    this.currentClusterContext.setCurrentClusterFromSite(this.selectedSite.id);
    return of(this.selectedSite);
  }
}
