import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { get } from 'lodash';
import { ButtonSizes, ButtonStyles } from '../../../../../../button/button.component';
import { SmacsIcons } from '../../../../../../shared/models/smacs-icons.enum';
import { LdapUserResult, ProvisioningSchedule } from '../../../../../../shared/models/generated/smacsModels';
import {
  DatatableColumn,
  DatatableRow,
  DatatableRowSelectedEvent,
} from '../../../../../../reporting/datatable/datatable.component';

interface IgnoredUsersRow extends DatatableRow {
  userId: string;
}

interface IgnoredUser {
  userId: string;
}

@Component({
  selector: 'app-admin-zero-touch-provisioning-edit-job-ignored-users',
  templateUrl: './provisioning-edit-job-ignored-users.component.html',
  styleUrls: ['./provisioning-edit-job-ignored-users.component.scss'],
})
export class ProvisioningEditJobIgnoredUsersComponent implements OnChanges {
  @Input() job: ProvisioningSchedule;
  @Input() ldapUserResults: LdapUserResult[];
  @Input() ignoredUsers: LdapUserResult[];

  @Output() ignoredUsersUpdate = new EventEmitter<LdapUserResult[]>();

  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  usernameFilter = '';

  dataTableCols: DatatableColumn<IgnoredUsersRow>[] = [
    {
      name: 'userId',
      label: 'tkey;pages.zero_touch.provisioning.edit_job.ignored_users.username',
    },
  ];

  dataTableRows: IgnoredUsersRow[] = [];
  selectedIgnoredUsers: IgnoredUser[] = [];
  tableDataAutomation = 'ignored-users-datatable';

  ngOnChanges(changes: SimpleChanges) {
    const newLdapSearchResults = get(changes, ['ldapUserResults', 'currentValue']);
    const newIgnoredUsers = get(changes, ['ignoredUsers', 'currentValue']);

    this.ignoredUsers = newIgnoredUsers || this.ignoredUsers;
    this.ldapUserResults = newLdapSearchResults || this.ldapUserResults;
    if (this.ldapUserResults) {
      this.dataTableRows = this.ldapUserResults;
      this.dataTableRows.forEach((row) => {
        row.isTableRowSelectable = true;
        row.isSelectedInTable = !!this.ignoredUsers.find((user) => user.userId === row.userId);
      });
    }
  }

  onRowSelected($event: DatatableRowSelectedEvent<IgnoredUsersRow>) {
    if ($event === 'none') {
      this.ignoredUsersUpdate.emit([]);
    } else if ($event === 'all') {
      this.selectedIgnoredUsers = this.dataTableRows.map((result) => ({
        userId: result.userId,
      }));
      this.ignoredUsersUpdate.emit(this.selectedIgnoredUsers);
    } else {
      this.selectedIgnoredUsers = this.dataTableRows
        .filter((result) => result.isSelectedInTable)
        .map((result) => ({
          userId: result.userId,
        }));
      this.ignoredUsersUpdate.emit(this.selectedIgnoredUsers);
    }
  }
}
