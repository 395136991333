<form [autocomplete]="'off'">
  <div
    class="mb-3 row"
    [hidden]="(!state.hasOwnProperty('hidden') || state.hidden) && (!forceShow || state.defaultValue === null)"
    [attr.data-automation]="config.dataAutomation"
    [ngClass]="{
      'has-feedback':
        showValidation || isFormSubmitted || isLoading || triggerLoading() || misconfigurationFeedbackMessage,
      'smacs-forms-error': validationState === ValStates.INVALID,
      'smacs-forms-warning':
        validationState === ValStates.PENDING ||
        validationState === ValStates.WARNING ||
        misconfigurationFeedbackMessage ||
        isLoading ||
        triggerLoading() ||
        (triggerLoadingIcon && triggerLoadingIcon()),
      'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
    }"
    (click)="onClick()"
  >
    <label
      *ngIf="getLabel()"
      [ngClass]="'smacs-forms-label col-form-label ' + this.state.columnClasses?.label"
      [attr.for]="config.dataAutomation + '-input'"
      [attr.data-automation]="'smacs-select-label'"
    >
      <ng-template #tipContent><span [innerHtml]="config.labelToolTipText | translate"></span></ng-template>
      <div [ngbTooltip]="tipContent" [disableTooltip]="!config.labelToolTipText" class="fit-content d-inline-block">
        <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
        <span [innerHtml]="getLabel() | translate"></span>
        <i *ngIf="config.labelToolTipIconClass" class="{{ config.labelToolTipIconClass }} ms-1"></i>
      </div>
    </label>

    <div [class]="state.columnClasses?.input">
      <div
        class="input-group"
        [ngClass]="{ 'smacs-select--hidden-arrow': hideDropdownArrow }"
        [ngbTooltip]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"
        [disableTooltip]="!getDisabledState() && !config.inputToolTipText"
        [container]="'body'"
      >
        <span
          class="{{ smacsIcons.SEARCH }} form-control-feedback form-control-feedback--left"
          [hidden]="!isAsync"
          data-automation="smacs-text-async-search-icon"
        ></span>
        <ng-select
          #select
          style="width: inherit"
          [labelForId]="config.dataAutomation + '-input'"
          [attr.data-automation]="'smacs-select-input'"
          [items]="options$ | async"
          [(ngModel)]="fieldData"
          [ngModelOptions]="{ standalone: true }"
          #ngModelDir="ngModel"
          (ngModelChange)="onChange($event, ngModelDir)"
          [clearable]="isAsync || (isClearable() && !hideClear)"
          (clear)="onClear()"
          [bindLabel]="'label'"
          [bindValue]="bindValue"
          [multiple]="isMultiSelect"
          [loading]="(isAsync && isLoading) || triggerLoading()"
          [typeahead]="isAsync ? optionInputSource : null"
          [minTermLength]="minSearchLength"
          [disabled]="getDisabledState()"
          [groupBy]="'group'"
          [placeholder]="placeholder | translate"
          [ngClass]="{ 'ng-multi-select-async': isAsync && isMultiSelect, 'ng-select-async': isAsync }"
          (search)="handleSearchInput($event)"
          (blur)="handleBlur()"
          (close)="handleSelectClose(select, ngModelDir)"
          (change)="handleChange()"
          (focus)="handleClicked()"
          notFoundText="{{ 'tkey;smacs_select.not_found_text' | translate }}"
          loadingText="{{ 'tkey;smacs_select.loading_text' | translate }}"
          clearAllText="{{ 'tkey;smacs_select.clear_all_text' | translate }}"
          typeToSearchText="{{ 'tkey;smacs_select.search_text' | translate }}"
        >
          <ng-template ng-typetosearch-tmp>
            <div class="d-none"></div>
          </ng-template>
          <ng-template ng-label-tmp let-item="item" let-clear="clear">
            <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
            <span class="ng-value-label" [innerHTML]="item.label || item"></span>
          </ng-template>
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <ng-template #tipContent>
              <span>{{ item.disabledTooltip | translate }}</span>
            </ng-template>
            <span
              [ngbTooltip]="tipContent"
              [container]="'body'"
              [disableTooltip]="!item.disabled"
              [placement]="item.tooltipPlacement ? item.tooltipPlacement : 'auto'"
              class="d-flex flex-row justify-content-start align-items-center"
            >
              <span *ngIf="item.displayIcon || item.photoBase64" style="width: 2.5rem">
                <img
                  *ngIf="item.photoBase64"
                  [src]="'data:image/png;base64,' + item.photoBase64"
                  class="round-image-navbar"
                  [ngClass]="{ disabled: item.disabled }"
                />
                <i *ngIf="item.displayIcon" class="fa fa-2x icon-user-circle round-image-navbar-icon"></i>
              </span>
              <span
                class="ng-option-label"
                [ngClass]="{ 'fw-bold': item.appendValue }"
                [innerHTML]="item.label || item"
              ></span>
              <span *ngIf="item.appendValue">
                <span class="text-separator">|</span>
                {{ item.value }}
              </span>
            </span>
          </ng-template>
        </ng-select>
        <span
          class="{{ smacsIcons.WARNING }} form-control-feedback"
          [hidden]="
            !(
              (showAsyncValidation && validationState === ValStates.WARNING) ||
              (validationState === ValStates.VALID && misconfigurationFeedbackMessage)
            )
          "
          data-automation="smacs-text-warning-icon"
        ></span>

        <span
          class="{{ smacsIcons.SAVING }} form-control-feedback"
          [hidden]="
            !(validationState === ValStates.PENDING && showAsyncValidation) &&
            !(triggerLoadingIcon && triggerLoadingIcon())
          "
          data-automation="smacs-text-async-pending"
        ></span>

        <span
          class="{{ smacsIcons.REMOVE }} form-control-feedback"
          [hidden]="!(validationState === ValStates.INVALID && showAsyncValidation)"
          data-automation="smacs-text-async-invalid"
        ></span>

        <span
          class="{{ smacsIcons.OK }} form-control-feedback"
          [hidden]="!(validationState === ValStates.VALID && showAsyncValidation && !misconfigurationFeedbackMessage)"
          data-automation="smacs-text-async-valid"
        ></span>
      </div>

      <div class="smacs-forms-feedback-area">
        <div class="form-text small" *ngIf="isAutogenerated" data-automation="smacs-forms-autogeneration-message">
          {{ config.autogeneration?.generatedMsg | translate }}
        </div>

        <div
          class="smacs-forms-validation-message"
          [hidden]="!showValidation && !isFormSubmitted"
          [ngClass]="{ 'd-inline': config.autogeneration?.inline && (showValidation || isFormSubmitted) }"
          data-automation="smacs-forms-validation-message"
          [innerHTML]="validationMessage?.content | translate : validationMessage?.params"
        ></div>

        <div
          class="smacs-forms-validation-message text-warning"
          [ngClass]="{ 'd-inline': config.autogeneration?.inline && !validationMessage?.content }"
          data-automation="smacs-forms-warning-message"
          [hidden]="validationMessage?.content || !misconfigurationFeedbackMessage"
          [innerHTML]="misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params"
        ></div>

        <smacs-button
          *ngIf="showAutoGenerationLink"
          [dataAutomation]="'smacs-forms-autogeneration-button'"
          [hidden]="isAutogenerateLinkHidden()"
          (clicked)="autogenerateValue()"
          [buttonStyle]="buttonStyles.LINK"
          [label]="config.autogeneration?.linkLabel"
          [labelCssClass]="'small strong'"
        ></smacs-button>
      </div>

      <span class="small form-text text-muted" *ngIf="config.helpText" [innerHTML]="getHelpText() | translate"></span>
      <ng-content></ng-content>
    </div>
  </div>
</form>
