import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Microsoft360View } from '../../models/generated/smacsModels';
import { PollingAbstractService } from '../abstract/polling.abstract.service';

@Injectable()
export class Microsoft360ViewPollingService extends PollingAbstractService<Microsoft360View> {
  static MAX_POLLS = 20;

  baseUrl = '/services/microsoft/views/360-view';

  protected _pollingTimeInMilliseconds = 30000;

  constructor(protected httpClient: HttpClient, protected ngZone: NgZone) {
    super(httpClient, ngZone);
  }

  setBaseUrl(upn: string) {
    this.baseUrl = `/services/microsoft/views/360-view/${upn}`;
  }
}
