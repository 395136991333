import { Injectable } from '@angular/core';
import { SamlMetadataContextAbstract } from '../../shared/contexts/abstract/saml-metadata.context.abstract';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FileDownloadService } from '../../shared/services/file-download.service';

@Injectable()
export class SamlIdpMetadataContext extends SamlMetadataContextAbstract {
  protected _timestampUrl = '/services/system/admin/saml-sso/idp-metadata/timestamp';
  protected _metaDataUrl = '/services/system/admin/saml-sso/idp-metadata';

  constructor($http: HttpClient, private fileDownloadService: FileDownloadService) {
    super($http);
    this.refresh().subscribe();
  }

  getMetadataXML = (): Observable<void> => {
    return this.fileDownloadService.downloadFile(this._metaDataUrl).pipe(tap(() => this.getTimeStamp()));
  };
}
