<app-top-nav></app-top-nav>

<div class="d-flex cdr-dump-wrapper">
  <div class="container-fluid d-flex flex-column">
    <div class="row flex-grow-1">
      <div class="col-md-8 offset-md-2">
        <app-breadcrumbs></app-breadcrumbs>
        <div class="card">
          <div class="card-header">{{ 'tkey;cdr_dump.card.title' | translate }}</div>
          <div class="card-body">
            <smacs-text [fieldId]="'startDate'"></smacs-text>
            <smacs-text [fieldId]="'endDate'"></smacs-text>
            <smacs-text [fieldId]="'callRecords'"></smacs-text>
            <smacs-text [fieldId]="'unusedPercentage'"></smacs-text>
          </div>
        </div>
      </div>
    </div>
    <app-bottom-nav></app-bottom-nav>
  </div>
</div>
