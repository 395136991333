import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class SearchAbstractResource {
  protected constructor(protected http: HttpClient) {}

  protected search<T>(url: string, params: any): Observable<T> {
    const query = this._mapParamsToQueryUriComponent(params);
    return this.http.get<T>(`${url}?${query}`);
  }

  private _mapParamsToQueryUriComponent(params: any): string {
    const queries = Object.keys(params)
      .map((param) => (params[param] ? `${param}=${encodeURIComponent(params[param])}` : ''))
      .filter((param) => !!param);

    if (!queries.length) {
      throw new Error('You must provide at least one query to perform a search.');
    }

    return queries.join('&');
  }
}
