import { Injectable, NgZone } from '@angular/core';
import { PollingAbstractService } from '../../../../../shared/services/abstract/polling.abstract.service';
import { JobStatus } from '../../../../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DlrMisconfigurationsPollingService extends PollingAbstractService<JobStatus> {
  baseUrl = '/services/microsoft/billing/dlr-misconfigurations-reports/status';
  protected _pollingTimeInMilliseconds = 2000;

  constructor(protected http: HttpClient, protected ngZone: NgZone) {
    super(http, ngZone);
  }
}
