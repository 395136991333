import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SmacsIcons } from '../../models/smacs-icons.enum';
import { SmacsFieldConfig, SmacsFormConfig, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { SmacsFieldAbstractDirective } from '../../../forms/smacs-field-abstract.directive';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import {
  SmacsNavsetFormNavComponent,
  SmacsNavsetItemNavConfig,
} from './smacs-navset-form-nav/smacs-navset-form-nav.component';
import { of, Subscription } from 'rxjs';
import { SmacsNavsetItemResults } from '../smacs-navset.component';

export interface SmacsNavsetItemNavItem {
  fields: { [key: string]: string };
  hasError: boolean;
  showValidation: boolean;
  defaultLabel: string;
  labelId: string;
  badgeId: string;
  id: string;
  index: number;
}

export interface SmacsNavsetFormItemConfig {
  [key: string]: {
    label: string;
    fields: SmacsFieldConfig[];
    badgeFieldId?: string;
    labelFieldId?: string;
    title?: string;
    defaultValue?: any;
  };
}

export interface SmacsNavsetDropdownItemConfig {
  id: string;
  label: string;
}

export interface SmacsNavsetFormConfig {
  itemNav: SmacsNavsetItemNavItem[];
}

type SmacsNavsetAnimationState = 'animating' | 'rendered';

@Component({
  selector: 'smacs-navset-form',
  templateUrl: './smacs-navset-form.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsNavsetFormComponent }],
})
export class SmacsNavsetFormComponent
  extends SmacsFormAbstractDirective<SmacsNavsetFormConfig, SmacsNavsetFormConfig>
  implements OnInit
{
  @Input() dropdownLabel: string;
  @Input() dropdownItems: SmacsNavsetDropdownItemConfig[];
  @Input() emptyTemplateMessage: string;
  @Input() tooltipMessage: string;
  @Input() allowDuplicate: boolean;
  @Input() navsetItemsConfig: SmacsNavsetFormItemConfig;
  @Input() upstreamEntity: SmacsNavsetItemResults;
  @Input() isFormSubmitted: boolean;
  @Input() allowedDropdownItemNames: string[];
  @Input() isOneClickAddDropdown: boolean;
  @ViewChild(SmacsNavsetFormNavComponent) formNav: SmacsNavsetFormNavComponent;

  icons = SmacsIcons;
  searchFilter = '';
  navItemAnimationState: SmacsNavsetAnimationState = 'rendered';
  formConfig: SmacsFormConfig;
  subscriptions = new Subscription();

  ngOnInit() {
    this._initializeFormConfig();
  }

  protected submit() {
    return of(null);
  }

  private _initializeFormConfig = () => {
    // If we're refreshing the component config (as done in PcceAgentSettingsFormComponent) then disable the animation
    // so that we don't see every navset item animate at once
    if (this.formNav) {
      this.formNav.disableAnimation = true;
    }
    this.formConfig = {
      fields: {
        itemNav: {
          componentConfig: new SmacsNavsetItemNavConfig({
            dropdownLabel: this.dropdownLabel,
            dropdownTooltip: this.tooltipMessage,
            items: this.navsetItemsConfig,
            dropdownItems: this.dropdownItems,
            allowDuplicates: this.allowDuplicate,
            emptyTemplateMessage: this.emptyTemplateMessage,
            isOneClickAddDropdown: this.isOneClickAddDropdown,
          }),
          validation: [
            {
              validator: (items: SmacsNavsetItemNavItem[]) => {
                // This component is only valid if not a single item has an error
                return items.some((fieldItem: SmacsNavsetItemNavItem) => fieldItem.hasError)
                  ? SmacsFormsValidationState.INVALID
                  : SmacsFormsValidationState.VALID;
              },
            },
          ],
        },
      },
    } as SmacsFormConfig;

    if (!this.formNav) {
      this.entitySource.next({
        itemNav: [],
      });
    }
  };
}
