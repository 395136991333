<div class="modal-header">
  <button data-automation="preview-close-button" type="button" class="btn-close" (click)="onCloseClick()"></button>
</div>

<div class="container-fluid modal-body text-center">
  <div class="modal-body--inner">
    <img
      data-automation="preview-image"
      [ngClass]="{ 'company-logo-image': modalProperties.type === 'LOGO' }"
      [src]="'data:image/png;base64,' + modalProperties.image"
    />
  </div>
</div>

<div class="modal-footer"></div>
