<div class="smacs-report-content">
  <smacs-text fieldId="reportName"></smacs-text>
  <smacs-select fieldId="cucmServer"></smacs-select>
  <smacs-code-area fieldId="sqlQuery"></smacs-code-area>
  <div class="row">
    <div class="col-lg-9 offset-lg-3">
      <span>{{ 'tkey;reporting.custom_reports.sql_field.helptext_1' | translate }}</span>
      <button
        type="button"
        class="btn btn-link ps-1 pe-1 test-query-link text-decoration-none"
        (click)="testQueryClicked()"
        data-automation="cucm-report-test-query-link-button"
      >
        {{ 'tkey;reporting.custom_reports.sql_field.helptext_2' | translate }}
      </button>
      <span>{{ 'tkey;reporting.custom_reports.sql_field.helptext_3' | translate }}</span>
    </div>
  </div>

  <legend id="query-results-header" *ngIf="!isTableLoading && hasQueryRun">
    {{ 'tkey;menu.report.cucm_report_builder.query_results.title' | translate }}
  </legend>

  <app-loading-block *ngIf="isTestQueryClicked && (isTableLoading || !hasQueryRun)"></app-loading-block>

  <ng-container *ngIf="!isTableLoading && hasQueryRun">
    <smacs-datatable
      [dataAutomation]="tableDataAutomation"
      [minTableHeight]="'20rem'"
      [noResultsAlert]="'tkey;menu.report.cucm_report_builder.no_results'"
      [columns]="columns"
      [data]="tableData"
    ></smacs-datatable>
  </ng-container>
</div>
