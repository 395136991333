<div class="smacs-admin-page-content">
  <app-loading-block *ngIf="isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" *ngIf="!isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.email_templates.edit_view.description_header' | translate" class="mb-2"></div>
        <div [innerHTML]="'tkey;admin.email_templates.edit_view.description_text' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <smacs-email-template-edit-form
      [allEmailTemplates]="emailTemplates"
      [entity]="emailTemplate"
    ></smacs-email-template-edit-form>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
