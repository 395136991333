<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 pb-2 smacs-datatable-content">
    <app-breadcrumbs>
      <app-breadcrumb-card-item [aboutCardId]="49"></app-breadcrumb-card-item>
    </app-breadcrumbs>
    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <ng-container *ngIf="!isLoading">
      <smacs-datatable
        [dataAutomation]="'port-in-number-status-datatable'"
        [columns]="datatableCols"
        [data]="datatableRows"
        [mainFilterPlaceholder]="
          'tkey;admin.order_numbers.port_in_number_status.datatable.filter_placeholder' | translate
        "
        [maxLength]="150"
        [isFirstColumnSticky]="true"
        [showEditColumn]="true"
        [editTooltip]="''"
        [editIcon]="smacsIcons.NEXT"
        [editButtonStyle]="buttonStyles.OUTLINE_PRIMARY"
        [editButtonSize]="buttonSizes.SMALL"
        [editButtonCssClasses]="'vertical-align-baseline'"
        [editButtonLabel]="'tkey;admin.order_numbers.port_in_number_status.datatable.view.label'"
        [tableFontClass]="'table-font-sans-serif'"
        [setColumnWidths]="false"
        [isStriped]="false"
        [tableSize]="'table-md'"
        [noResultsAlert]="
          !datatableRows.length
            ? ('tkey;admin.order_numbers.port_in_number_status.datatable.no_results' | translate)
            : ''
        "
        [noResultsAlertClass]="!datatableRows.length ? 'alert-light' : ''"
        [persistFilters]="true"
        [matchesSecondaryDataFn]="matchesSecondaryDataFn"
      >
        <smacs-datatable-date-filter
          [label]="'tkey;admin.order_numbers.port_in_number_status.datatable.foc_date.label'"
          [matchFn]="isDateMatchFn"
          [dataAutomation]="'foc-date-filter'"
          [tableId]="'port-in-number-status-datatable'"
        ></smacs-datatable-date-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;admin.order_numbers.port_in_number_status.datatable.sbc_configuration.label'"
          [items]="sbcConfigurationOptions"
          [matchFn]="isSbcConfigurationMatchFn"
          [labelCssClass]="'text-capitalize'"
          [dataAutomation]="'sbc-configuration-filter'"
          [tableId]="'port-in-number-status-datatable'"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;admin.order_numbers.port_in_number_status.datatable.order_status.label'"
          [items]="orderStatusOptions"
          [matchFn]="isOrderStatusMatchFn"
          [labelCssClass]="'text-capitalize'"
          [dataAutomation]="'order-status-filter'"
          [tableId]="'port-in-number-status-datatable'"
        ></smacs-datatable-multi-select-filter>
      </smacs-datatable>
    </ng-container>
  </div>
</div>
