import { Component, Input, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../../../../forms/smacs-form-state.service';
import { of } from 'rxjs';
import { SmacsFormConfig } from '../../../../../../../forms/smacs-forms-models';
import {
  BandwidthEmergencyCallingSettingsConfig,
  TeamsPhoneNumberAssignment,
} from '../../../../../../../shared/models/generated/smacsModels';
import { SmacsSelectConfig } from '../../../../../../../forms/fields/select/smacs-select.component';

export interface BandwidthDynamicFormData {
  dynamicGeolocations: string;
  dynamicGeolocationCallerName: string;
  dynamicGeolocationCallbackNumber: string;
  dynamicGeolocationPreferredLanguage: string;
  dynamicGeolocationEndpointId: string;
}

@Component({
  selector: 'smacs-bandwidth-emergency-calling-form',
  templateUrl: './bandwidth-emergency-calling-form.component.html',
})
export class BandwidthEmergencyCallingFormComponent
  extends SmacsFormAbstractDirective<BandwidthDynamicFormData>
  implements OnInit
{
  @Input() bandwidthFieldConfig: BandwidthEmergencyCallingSettingsConfig;
  @Input() bandwidthEntity: BandwidthDynamicFormData;
  @Input() teamsLine: TeamsPhoneNumberAssignment;
  formConfig: SmacsFormConfig;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._initFormConfig();
    if (this.teamsLine) {
      this.entitySource.next(this.bandwidthEntity);
    } else {
      this.entitySource.next(this.getEntityFromFieldConfigDefaults());
    }
  }

  protected submit() {
    return of(null);
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        dynamicGeolocations: {
          label: 'tkey;shared.model.microsoft_teams_calling.e911_location.label',
          componentConfig: new SmacsSelectConfig({
            options: this.bandwidthFieldConfig ? this.bandwidthFieldConfig.dynamicGeolocations.possibleOptions : [],
          }),
          dataAutomation: 'bandwidth-e911-location-information',
          required: () => true,
          hidden: () => !this.bandwidthFieldConfig.dynamicGeolocations.show,
          defaultValue: () => this.bandwidthFieldConfig.dynamicGeolocations.defaultOption,
          disabled: () => this.isSubmitting,
        },
        dynamicGeolocationCallerName: {
          label: 'tkey;shared.model.microsoft_teams_calling.e911_callerName.label',
          dataAutomation: 'bandwidth-e911-caller-name',
          required: () => true,
          hidden: () => !this.bandwidthFieldConfig.dynamicGeolocationCallerName.show,
          defaultValue: () => this.bandwidthFieldConfig.dynamicGeolocationCallerName.value,
          disabled: () => this.isSubmitting,
        },
        dynamicGeolocationCallbackNumber: {
          label: 'tkey;shared.model.microsoft_teams_calling.e911_callbackNumber.label',
          dataAutomation: 'bandwidth-e911-callback-number',
          required: () => true,
          hidden: () => !this.bandwidthFieldConfig.dynamicGeolocationCallbackNumber.show,
          defaultValue: () => this.bandwidthFieldConfig.dynamicGeolocationCallbackNumber.value,
          disabled: () => this.isSubmitting,
        },
        dynamicGeolocationPreferredLanguage: {
          label: 'tkey;shared.model.microsoft_teams_calling.e911_preferredLanguage.label',
          dataAutomation: 'bandwidth-e911-preferred-language',
          componentConfig: new SmacsSelectConfig({
            options: this.bandwidthFieldConfig
              ? this.bandwidthFieldConfig.dynamicGeolocationPreferredLanguage.possibleOptions
              : [],
          }),
          required: () => true,
          hidden: () => !this.bandwidthFieldConfig.dynamicGeolocationPreferredLanguage.show,
          defaultValue: () => this.bandwidthFieldConfig.dynamicGeolocationPreferredLanguage.defaultOption,
          disabled: () => this.isSubmitting,
        },
        dynamicGeolocationEndpointId: {
          label: 'tkey;shared.model.microsoft_teams_calling.e911_endpointId.label',
          dataAutomation: 'bandwidth-e911-endpoint-id',
          required: () => true,
          hidden: () => !this.bandwidthFieldConfig.dynamicGeolocationEndpointId.show,
          defaultValue: () => this.bandwidthFieldConfig.dynamicGeolocationEndpointId.value,
          disabled: () => this.isSubmitting,
        },
      },
    } as SmacsFormConfig;
  }

  private getEntityFromFieldConfigDefaults(): BandwidthDynamicFormData {
    return {
      dynamicGeolocations: this.bandwidthFieldConfig.dynamicGeolocations.defaultOption,
      dynamicGeolocationCallbackNumber: this.bandwidthFieldConfig.dynamicGeolocationCallbackNumber.value,
      dynamicGeolocationCallerName: this.bandwidthFieldConfig.dynamicGeolocationCallerName.value,
      dynamicGeolocationPreferredLanguage: this.bandwidthFieldConfig.dynamicGeolocationPreferredLanguage.defaultOption,
      dynamicGeolocationEndpointId: this.bandwidthFieldConfig.dynamicGeolocationEndpointId.value,
    };
  }
}
