import { Component, Input, OnInit } from '@angular/core';
import {
  ClusterResult,
  LdapConfiguration,
  LdapUserId,
  SiteResult,
  SiteSummary,
} from '../../../../../shared/models/generated/smacsModels';
import { of, switchMap } from 'rxjs';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { HtmlInputType, SmacsTextConfig } from '../../../../../forms/fields/text/smacs-text.component';
import { SmacsFormsValidationState } from '../../../../../forms/smacs-forms-models';
import { TranslateService } from '@ngx-translate/core';
import { LdapResource } from '../../../../../shared/resources/ldap.resource';
import { cloneDeep, isEqual } from 'lodash';

export interface SiteConfigFormEntity {
  name: string;
  ldapAttribute: string;
}

@Component({
  selector: 'ziro-site-configuration-form',
  templateUrl: './site-configuration-form.component.html',
})
export class SiteConfigurationFormComponent extends SmacsFormAbstractDirective<SiteConfigFormEntity> implements OnInit {
  @Input() ldapConfig: LdapConfiguration;
  @Input() siteSummary: SiteSummary;
  @Input() siteId: string;

  private _initialEntity: SiteConfigFormEntity;
  isDirty = false;

  formConfig = {
    fields: {
      name: {
        label: 'tkey;site_management.site.section.field.name.text',
        dataAutomation: 'site-name',
        required: true,
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
        }),
        validation: [
          {
            validator: (val: string) => {
              if (!val) {
                this._siteNameValidationMsg = '';
                return SmacsFormsValidationState.VALID;
              }

              const match = this.siteSummary.clusters.find((clusterResult: ClusterResult) => {
                return clusterResult.sites.find((siteResult: SiteResult) => {
                  if (
                    siteResult.name.trim().toLowerCase() === val.trim().toLowerCase() &&
                    parseInt(this.siteId) !== siteResult.id
                  ) {
                    this._siteNameValidationMsg = this._translateService.instant(
                      'tkey;admin.site_management.site.name_unique.text',
                      {
                        siteName: siteResult.name,
                        clusterName: clusterResult.name,
                      }
                    );
                    return siteResult;
                  }
                });
              });

              if (match) {
                return SmacsFormsValidationState.INVALID;
              }

              return SmacsFormsValidationState.VALID;
            },
            message: () => this._siteNameValidationMsg,
          },
        ],
      },
      ldapAttribute: {
        label: () =>
          this._translateService.instant('tkey;admin.site_management.site.ldap_site_name.label', {
            attributeName: this.ldapConfig?.siteAttributeName,
          }),
        dataAutomation: 'ldap-attribute',
        required: false,
        hidden: () => !this.ldapConfig?.siteAttributeName,
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
        }),
        validation: [
          {
            validator: (val: string) => {
              if (!val) {
                this._ldapAttributeUniqueMsg = '';
                return SmacsFormsValidationState.VALID;
              }

              const match = this.siteSummary.clusters.find((clusterResult: ClusterResult) => {
                return clusterResult.sites.find((siteResult: SiteResult) => {
                  if (
                    siteResult.ldapSiteName?.trim().toLowerCase() === val.trim().toLowerCase() &&
                    parseInt(this.siteId) !== siteResult.id
                  ) {
                    this._ldapAttributeUniqueMsg = this._translateService.instant(
                      'tkey;admin.site_management.site.ldap_not_unique',
                      {
                        siteName: siteResult.name,
                      }
                    );
                    return siteResult;
                  }
                });
              });

              if (match) {
                return SmacsFormsValidationState.INVALID;
              }

              return SmacsFormsValidationState.VALID;
            },
            message: () => this._ldapAttributeUniqueMsg,
          },
          {
            validator: (val: string) => {
              if (!val) {
                this._ldapAttributeMatchesMsg = '';
                return SmacsFormsValidationState.VALID;
              } else {
                return this._ldapResource.findUsersByAttribute(this.ldapConfig?.siteAttributeName, val, 1000).pipe(
                  switchMap((matches: LdapUserId[]) => {
                    if (matches.length) {
                      if (matches.length < 1000) {
                        this._ldapAttributeMatchesMsg = this._translateService.instant(
                          'tkey;admin.site_management.site.ldap_site_name.success.text',
                          {
                            count: matches.length,
                            attributeName: this.ldapConfig?.siteAttributeName,
                            attributeValue: val,
                          }
                        );
                      } else {
                        this._ldapAttributeMatchesMsg = this._translateService.instant(
                          'tkey;admin.site_management.site.ldap_site_name.success.thousand_plus.text',
                          {
                            attributeName: this.ldapConfig?.siteAttributeName,
                            attributeValue: val,
                          }
                        );
                      }

                      return of(SmacsFormsValidationState.VALID);
                    } else {
                      this._ldapAttributeMatchesMsg = this._translateService.instant(
                        'tkey;admin.site_management.site.ldap_site_name.warning.helpblock',
                        {
                          attributeName: this.ldapConfig?.siteAttributeName,
                          attributeValue: val,
                        }
                      );
                      return of(SmacsFormsValidationState.WARNING);
                    }
                  })
                );
              }
            },
            message: () => this._ldapAttributeMatchesMsg,
            successMessage: () => this._ldapAttributeMatchesMsg,
          },
        ],
      },
    },
  };

  private _siteNameValidationMsg = '';
  private _ldapAttributeUniqueMsg = '';
  private _ldapAttributeMatchesMsg = '';

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _translateService: TranslateService,
    private _ldapResource: LdapResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initialEntity = cloneDeep(this.entity);
  }

  toEntity = (formData: SiteConfigFormEntity): SiteConfigFormEntity => {
    this.isDirty = !isEqual(this._initialEntity, formData);
    return formData;
  };

  protected submit() {
    return of(null);
  }
}
