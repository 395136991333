<a
  [attr.target]="isAdminRoute() ? '_self' : '_blank'"
  [attr.href]="redirectRoute()"
  class="btn"
  [ngClass]="state === State.ERROR ? 'btn-danger' : 'btn-warning'"
  *ngIf="showSystemStatusAlert()"
  [attr.data-automation]="'system-status-alert'"
>
  <strong>
    <i class="{{ smacsIcons.WARNING }} me-2"></i>
    {{
      (state === State.ERROR
        ? 'tkey;system_status.top_nav.error_message'
        : 'tkey;system_status.top_nav.warning_message'
      ) | translate
    }}
  </strong>
</a>
