<div class="datatable-filter">
  <div class="dropdown" ngbDropdown [attr.data-automation]="dataAutomation" (openChange)="openChange($event)">
    <button
      [id]="dataAutomation"
      type="button"
      class="btn dropdown-toggle"
      [class]="!!value || neverUsed ? 'btn-primary' : 'btn-default'"
      [disabled]="isViewLoading"
      data-automation="datatable-slider-filter-dropdown-toggle-button"
      ngbDropdownToggle
    >
      <span class="font-regular">{{ label | translate }}{{ ': ' }}</span>
      <span class="font-black" data-automation="datatable-slider-filter-value">
        {{ getFilterLabel() | translate : { days: value } }}
      </span>
    </button>
    <button
      *ngIf="!!value"
      type="button"
      class="btn btn-primary clear-margin"
      data-automation="datatable-clear-slider-filter-button"
      (click)="clearInput()"
    >
      <i class="icon-clear"></i>
    </button>
    <div ngbDropdownMenu class="dropdown-menu">
      <div class="datatable-container">
        <div
          class="form-check form-switch form-switch-right"
          [attr.data-automation]="'datatable-slider-never-used-select'"
        >
          <label
            class="form-check-label"
            [for]="'datatable-slider-never-used-select'"
            [innerHTML]="'tkey;reporting.datatable.filter.slider.never.used' | translate"
          ></label>
          <input
            type="checkbox"
            class="form-check-input"
            [id]="'datatable-slider-never-used-select'"
            [(ngModel)]="neverUsed"
            (ngModelChange)="updateSlider()"
            [disabled]="isViewLoading"
          />
        </div>
        <div
          class="mt-2"
          [hidden]="neverUsed"
          [ngClass]="disableDropDownOptions ? 'custom-initial-background custom-slider' : 'custom-slider'"
        >
          <span
            class="slider-label"
            [innerHTML]="'tkey;reporting.datatable.filter.slider.select.label' | translate"
          ></span>
          <div class="d-flex mt-2">
            <div class="minmax-slider-value">{{ floorValueLabel ? (floorValueLabel | translate) : floorValue }}</div>
            <ngx-slider [(value)]="sliderValue" [options]="options" (userChange)="onChange($event)"></ngx-slider>
            <div class="minmax-slider-value">{{ ceilValue }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
