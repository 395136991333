<div class="modal-content">
  <div class="modal-header modal-header--bordered">
    <h1 class="mb-0" data-automation="detailed-modal-title">
      <a id="release-notes-link" [href]="url" target="_blank">
      <strong class="text-primary">{{ 'tkey;release_notes.modal.title' | translate }}  <i class="{{smacsIcons.EXTERNAL_LINK}} release-notes-icon"></i> </strong>
      </a> | {{ title }}
    </h1>
    <button
      type="button"
      class="btn-close"
      (click)="onCloseClick()"
      [attr.data-automation]="'release-notes-modal-close-button'"
    ></button>
  </div>

  <div
    class="modal-body modal-body--dynamic modal-body--dynamic__scrollable p-5 pt-4"
    data-automation="prompt-modal-body"
  >
    <div data-automation="release-notes-modal-release-message" [innerHTML]="html"></div>
  </div>
</div>
