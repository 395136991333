import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DubberSettings, Role } from '../models/generated/smacsModels';
import { concatMap, map } from 'rxjs/operators';
import { AuthenticationContext } from './authentication.context';

@Injectable()
export class DubberSettingsContext {
  _stateSource = new ReplaySubject<DubberSettings>(1);
  state$ = this._stateSource.asObservable();

  private _baseUrl = '/services/microsoft/admin/dubber-settings';

  constructor(private _http: HttpClient, private _authenticationContext: AuthenticationContext) {
    this._authenticationContext.state$.subscribe((currentUser) => {
      if (currentUser?.role === Role.ZIRO_SUPPORT) {
        // only call API if it's needed (user is s8support).
        this._init().subscribe();
      } else {
        this._stateSource.next(null);
      }
    });
  }

  get(): Observable<DubberSettings> {
    return this._http.get<DubberSettings>(this._baseUrl);
  }

  put(settings: DubberSettings): Observable<void> {
    return this._http.put<void>(this._baseUrl, settings).pipe(concatMap(() => this._init()));
  }

  delete(): Observable<void> {
    return this._http.delete<void>(this._baseUrl).pipe(concatMap(() => this._init()));
  }

  testConnection(settings: DubberSettings): Observable<void> {
    return this._http.post<void>(`${this._baseUrl}/connections`, settings);
  }

  refresh(): Observable<void> {
    return this._init();
  }

  isDubberConfigured(settings: DubberSettings): boolean {
    return settings && !!settings.username && !!settings.url;
  }

  private _init(): Observable<void> {
    return this._get().pipe(map((data: DubberSettings) => this._stateSource.next(data)));
  }

  private _get(): Observable<DubberSettings> {
    return this._http.get<DubberSettings>(this._baseUrl);
  }
}
