<div class="d-flex flex-column h-100">
  <app-top-nav></app-top-nav>
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="container-fluid h-100" [hidden]="isLoading">
    <div class="row h-100">
      <smacs-user-detail-card class="col-lg-2 col-md-3 p-0"></smacs-user-detail-card>
      <div class="col-lg-10 col-md-9 user-detail-pane">
        <div class="user-detail-pane__outlet">
          <app-breadcrumbs *ngIf="!hideBreadcrumb"></app-breadcrumbs>
          <div>
            <router-outlet></router-outlet>
          </div>
        </div>
        <app-bottom-nav class="user-detail-pane__bottom-nav"></app-bottom-nav>
      </div>
    </div>
  </div>
</div>
