<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.system_health_status_notifications.description' | translate"></div>
        <div [innerHTML]="'tkey;system_status.zpc.description' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <div
      *ngIf="!hasEmailSettings()"
      class="smacs-page-alert alert border-warning w-100 mt-1"
      data-automation="system-health-settings-notifications-no-email-settings-alert"
    >
      <i class="icon-warning-triangle text-warning fa-4x"></i>
      <div class="ms-3" [innerHTML]="'tkey;admin.system_health_status_notifications.warning' | translate"></div>
    </div>

    <div [hidden]="!hasEmailSettings()" class="list-group list-group-striped mb-3">
      <div class="list-group-item position-relative">
        <smacs-general-settings-checkbox [fieldId]="'automaticDisableOfEndpoints'"></smacs-general-settings-checkbox>
      </div>
      <div class="list-group-item position-relative">
        <smacs-general-settings-text [fieldId]="'healthCheckIntervalInMinutes'"></smacs-general-settings-text>
      </div>
    </div>

    <div class="card" [hidden]="!hasEmailSettings()">
      <div class="card-header">{{ 'tkey;admin.system_health_status_notifications.title' | translate }}</div>
      <div class="card-body">
        <smacs-multi-checkbox [fieldId]="'notificationsTrigger'"></smacs-multi-checkbox>
        <smacs-text [fieldId]="'failureThresholdForWarnings'"></smacs-text>
        <smacs-text [fieldId]="'repeatNotificationWarnings'"></smacs-text>
        <smacs-text [fieldId]="'failureThresholdForErrors'"></smacs-text>
        <smacs-text [fieldId]="'repeatNotificationErrors'"></smacs-text>
        <smacs-multi-text [fieldId]="'to'"></smacs-multi-text>
      </div>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
