import { isNil } from 'lodash';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { HtmlInputAddOn, SmacsFieldComponentConfig, SmacsFormsValidationState } from '../../smacs-forms-models';
import { Observable, Subscription } from 'rxjs';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import * as moment from 'moment';
import { ButtonStyles } from '../../../button/button.component';
import { TranslateService } from '@ngx-translate/core';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { VariableEditorModalComponent } from '../../../modals/edit-custom-input-text-modal/variable-editor-modal.component';
import { VariableEditorType } from '../../../modals/edit-custom-input-text-modal/variable-editor.service';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';

type SmacsTextValue = string;

export enum HtmlInputType {
  TEXT = 'text',
  PASSWORD = 'password',
  NUMBER = 'number',
  TIME = 'time',
  DATE = 'date',
}

export enum InputSize {
  LG = 'LG',
  SM = 'SM',
}

export class SmacsTextConfig extends SmacsFieldComponentConfig {
  constructor(
    public config: {
      htmlInputType: HtmlInputType;
      placeholder?: string;
      htmlInputAddOn?: HtmlInputAddOn;
      inputSize?: InputSize;
      datalist$?: Observable<any>;
      min?: number;
      max?: number;
      readonly?: boolean;
      isCodePlaceholder?: boolean;
      disabled?: boolean;
      step?: number;
      starsPlaceholder?: string;
      displayEditor?: boolean;
      editorType?: VariableEditorType;
      tabIndex?: number;
      isWhiteWhileDisabled?: boolean;
      disablePointerEvents?: boolean;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-text',
  templateUrl: './smacs-text.component.html',
  styleUrls: ['./smacs-text.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsTextComponent }],
})
export class SmacsTextComponent
  extends SmacsFieldAbstractDirective<SmacsTextValue, SmacsTextValue, SmacsTextConfig>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() inlineLabel = true;
  @Input() disabled = false;
  @Input() dynamicPreviewValue: string;
  smacsIcons = SmacsIcons;

  htmlInputType = HtmlInputType;
  placeholder = '';
  inputType = HtmlInputType.TEXT;
  inputAddOn = null as HtmlInputAddOn;
  inputSize = '';
  inputSizes = InputSize;
  datalist$: Observable<any>;
  localTime = '';
  min: number;
  max: number;
  step: number;
  readOnly = false;
  starsPlaceholder = '••••••••';
  showMaskedInput = false;
  maskedValue = '';
  disabledMasking = false;
  buttonStyles = ButtonStyles;
  isChrome: boolean;
  displayEditor = false;
  editorType: VariableEditorType = VariableEditorType.MICROSOFT;
  tabIndex: number;
  isWhiteWhileDisabled = false;
  cannotCopy = false;

  private _subs = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _translateService: TranslateService,
    private _smacsModalService: SmacsModalService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.isChrome = !!(window as any)['chrome'];
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      this.applyState({ ...this.state, disabled: changes.disabled.currentValue });
      if (changes.disabled.currentValue === true) {
        this.shouldDisableTooltip = false;
      } else {
        this.showMaskedInput = false;
      }
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subs.unsubscribe();
  }

  applyComponentConfig = ({ config }: SmacsTextConfig) => {
    this.inputType = isNil(config.htmlInputType) ? this.inputType : config.htmlInputType;
    this.placeholder = isNil(config.placeholder) ? this.placeholder : config.placeholder;
    this.inputAddOn = isNil(config.htmlInputAddOn) ? this.inputAddOn : config.htmlInputAddOn;
    this.inputSize = isNil(config.inputSize) ? this.inputSize : config.inputSize;
    this.datalist$ = isNil(config.datalist$) ? this.datalist$ : config.datalist$;
    this.min = isNil(config.min) ? this.min : config.min;
    this.max = isNil(config.max) ? this.max : config.max;
    this.readOnly = isNil(config.readonly) ? this.readOnly : config.readonly;
    this.disabled = isNil(config.disabled) ? this.disabled : config.disabled;
    this.state.disabled = this.disabled;
    this.step = isNil(config.step) ? 0 : config.step;
    this.starsPlaceholder = isNil(config.starsPlaceholder) ? this.starsPlaceholder : config.starsPlaceholder;
    this.displayEditor = isNil(config.displayEditor) ? this.displayEditor : config.displayEditor;
    this.editorType = isNil(config.editorType) ? this.editorType : config.editorType;
    this.tabIndex = isNil(config.tabIndex) ? this.tabIndex : config.tabIndex;
    this.isWhiteWhileDisabled = isNil(config.isWhiteWhileDisabled)
      ? this.isWhiteWhileDisabled
      : config.isWhiteWhileDisabled;
    this.cannotCopy = isNil(config.disablePointerEvents) ? this.cannotCopy : config.disablePointerEvents;

    if (this.inputType === HtmlInputType.PASSWORD) {
      const updateSub = this.smacsFormsUpdate$.subscribe((update: any) => {
        if (this.disabled) {
          this.showMaskedInput = true;
        } else if (update.old && update.old !== update.new) {
          this.showMaskedInput = false;
          this.disabledMasking = true;
        } else if (this.isExisting && !this.disabledMasking) {
          this.showMaskedInput = true;
        }
      });
      this._subs.add(updateSub);
    }

    if (this.inputType === this.htmlInputType.TIME) {
      const updateSub = this.smacsFormsUpdate$.subscribe((update: any) => {
        this._setLocalTime(update.new);
      });
      this._subs.add(updateSub);
    }
  };

  insertText(text: string) {
    const inputElement = document.getElementById(this.config.id + '-input') as HTMLInputElement;
    const startPos = inputElement.selectionStart;
    const endPos = inputElement.selectionEnd;
    const before = inputElement.value.substring(0, startPos);
    const after = inputElement.value.substring(endPos);
    const newValue = before + text + after;

    inputElement.value = newValue;
    inputElement.focus();
    inputElement.selectionStart = startPos + text.length;
    inputElement.selectionEnd = startPos + text.length;

    this.updateSelf(newValue);
  }

  getHelpText() {
    if (!this.config.helpText) {
      return '';
    } else if (typeof this.config.helpText === 'string') {
      return this.config.helpText;
    } else {
      return this.config.helpText();
    }
  }

  getLabelColumnClass(): string {
    if (this.inlineLabel) {
      return this.state.columnClasses?.label;
    }
    return '';
  }

  getInputColumnClass(): string {
    if (this.config.label) {
      return this.state.columnClasses?.input;
    }
    return '';
  }

  hideDefaultValueAutogenerationFnFactory(
    defaultVal: SmacsTextValue
  ): (val: SmacsTextValue) => SmacsFormsValidationState {
    return (val) =>
      !defaultVal || defaultVal === val ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
  }

  misconfigurationWarningMessage = () => ({
    content: 'tkey;smacs_text.misconfiguration_feedback',
    params: { defaultValue: this.state.defaultValue },
  });

  isMisConfigurationFeedbackDisplayed(): boolean {
    return this.misconfigurationFeedbackMessage && !this.state.hidden ? false : !!this.misconfigurationFeedbackMessage;
  }

  getFixItLinkLabel(): string {
    if (this.misconfigurationFeedbackMessage && !this.state.hidden) {
      return this._translateService.instant('tkey;smacs_text.misconfiguration_feedback.state.show', {
        defaultValue: this.state.defaultValue,
      });
    }

    return this.config.autogeneration?.linkLabel;
  }

  openEditorModal() {
    const modalInstance = this._smacsModalService.openCustomModal(
      VariableEditorModalComponent,
      {
        value: this.entity,
        type: this.editorType,
        dynamicPreviewValue: this.dynamicPreviewValue,
      },
      'lg'
    );
    modalInstance.subscribe((newValue: any) => {
      this.updateSelf(newValue.value);
    });
  }

  private _setLocalTime(utc: string): void {
    const localMoment = moment.utc(utc, 'HH:mm').local();
    this.localTime = localMoment.isValid() ? localMoment.format('LT') : '';
  }
}
