<div
  class="smacs-about-card"
  [ngClass]="{ 'smacs-about-card--expanded': isExpanded }"
  data-automation="smacs-about-card"
>
  <div [@aboutCardBodyAnimation]="state">
    <div class="smacs-about-card__body my-2" data-automation="smacs-about-card-body" *ngIf="isExpanded">
      <div class="smacs-about-card__body__arrow" *ngIf="showArrow"></div>
      <div class="card">
        <div class="card-body h-100 overflow-hidden">
          <div class="smacs-about-card__body__content">
            <ng-content></ng-content>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
