import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ZiroSupportSettings } from '../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ZiroSupportSettingsResource {
  private _baseUrl = '/services/microsoft/admin/ziro-support-settings';

  constructor(private _http: HttpClient) {}

  get(): Observable<ZiroSupportSettings> {
    return this._http.get<ZiroSupportSettings>(this._baseUrl);
  }

  put(settings: ZiroSupportSettings): Observable<void> {
    return this._http.put<void>(this._baseUrl, settings);
  }
}
