import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { cloneDeep } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SnrDestination, SnrDestinationResult } from '../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import {
  SmacsNavsetComponent,
  SmacsNavsetConfig,
  SmacsNavsetItemConfig,
} from '../../../../shared/smacs-navset/smacs-navset.component';
import { HtmlInputType, InputSize, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SnrProfileResource } from '../../../../self-serve/resources/snr-profile.resource';

interface SnrDestinationForm {
  destinations: SnrDestination[];
  snrNavset: {
    destinations: SnrDestination[];
  };
}

@Component({
  selector: 'smacs-snr-destinations-form',
  templateUrl: './snr-destinations-form.component.html',
})
export class SnrDestinationsFormComponent
  extends SmacsFormAbstractDirective<SnrDestination[], SnrDestinationForm>
  implements OnInit
{
  @ViewChild(SmacsNavsetComponent) snrNavset: SmacsNavsetComponent;
  @Input() cucmServerId: number;
  @Input() initialSnrDestinations: SnrDestination[];
  protected formConfig: SmacsFormConfig;
  private _destinationInUseMessage = '';
  private _validators = {
    destinationsRegexValidator: (destNumInput: string) =>
      !destNumInput || /^.+@.+$/.test(destNumInput) || /^[0-9*#+]{1,24}$/i.test(destNumInput)
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID,
    destinationsSyncValidator: (destNumInput: string, id: string) => {
      let matchCurrentSnr;

      if (this.formData.snrNavset.destinations) {
        matchCurrentSnr = this.formData.snrNavset.destinations.find(
          (destination: SnrDestination) => destination.destinationNumber === destNumInput && destination.id !== id
        );
      }

      if (matchCurrentSnr) {
        this._destinationInUseMessage = this.translateService.instant('tkey;snr.snrdestination.destination.in_use', {
          destinationName: matchCurrentSnr.destinationName,
        });
        return SmacsFormsValidationState.INVALID;
      }
      return SmacsFormsValidationState.VALID;
    },
    destinationAsyncValidator: (destNumInput: string): Observable<SmacsFormsValidationState> => {
      return this.snrProfileResource.searchSnrDestinations(destNumInput, this.cucmServerId).pipe(
        map((destinations: SnrDestinationResult[]) => {
          const initialIds = this.initialSnrDestinations.map((dest) => dest.id);

          const matchOtherUsers = destinations.find(
            (destination: SnrDestinationResult) =>
              destination.ref.destination === destNumInput && !initialIds.includes(destination.ref.id)
          );

          if (matchOtherUsers) {
            this._destinationInUseMessage = this.translateService.instant(
              'tkey;snr.snrdestination.destination.in_use',
              {
                destinationName: matchOtherUsers.ref.name,
              }
            );
            return SmacsFormsValidationState.INVALID;
          }

          return SmacsFormsValidationState.VALID;
        })
      );
    },
  };

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private translateService: TranslateService,
    private snrProfileResource: SnrProfileResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._initFormConfig();
  }

  toFormData = (entity: SnrDestination[]): SnrDestinationForm => {
    const entityClone = cloneDeep(entity);
    const formData: any = {};

    if (entityClone.length) {
      formData.destinations = [];
    }
    entityClone.forEach((destination) => {
      formData.destinations.push({
        ...destination,
      });
    });

    return {
      destinations: entityClone,
      snrNavset: formData,
    };
  };

  toEntity = (formData: SnrDestinationForm): SnrDestination[] => {
    const formDataClone = cloneDeep(formData);
    const initialDestinations = formDataClone.destinations;
    const navsetDestinations = formDataClone.snrNavset.destinations;

    let finalDestinations: SnrDestination[] = [];
    // Previous destinations existed
    if (initialDestinations?.length && navsetDestinations?.length) {
      const deletedDestinations = initialDestinations.filter((destination) => {
        return !navsetDestinations.find((navsetDestination) => navsetDestination.id === destination.id);
      });
      deletedDestinations.forEach((deletedDestination) => (deletedDestination.destinationNumber = null));
      finalDestinations = [...deletedDestinations, ...navsetDestinations];
    }
    // Previous destinations existed, but all have been deleted now
    if (initialDestinations?.length && !navsetDestinations?.length) {
      const allFormerDestinationsRemoved = initialDestinations.map((destination) => {
        destination.destinationNumber = '';
        return destination;
      });
      finalDestinations = [...allFormerDestinationsRemoved];
    }
    // No previous destination existed, just adding new ones
    if (!initialDestinations?.length && navsetDestinations?.length) {
      finalDestinations = navsetDestinations;
    }

    return finalDestinations;
  };

  submit() {
    return this.parentSubmit$;
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        snrNavset: {
          componentConfig: new SmacsNavsetConfig({
            dropdownLabel: 'tkey;snr.snrdestination.add_button.label',
            dropdownTooltip: 'tkey;snr.snrdestination.add_button.tooltip',
            emptyMessage: 'tkey;snr.snrdestination.no_destinations',
            navsetItemsConfig: this._getDestinationsConfig(),
            allowDuplicates: true,
            isOneClickAddDropdown: true,
          }),
        },
      },
    };
  }

  private _getDestinationsConfig(): SmacsNavsetItemConfig {
    const destinationsNavsetFields: SmacsNavsetItemConfig = {
      destinations: {
        label: 'New Destination',
        labelFieldId: 'destinationName',
        defaultValue: () => ({
          destinationName: '',
          destinationNumber: '',
          delayBeforeRingingInSeconds: 4,
          answerTooSoonTimerInSeconds: 1.5,
          answerTooLateTimerInSeconds: 0,
          id: Date.now().toString(),
        }),
        fields: [
          {
            label: 'tkey;snr.snrdestination.name.label',
            dataAutomation: 'destinationName',
            required: true,
            componentConfig: new SmacsTextConfig({
              htmlInputType: HtmlInputType.TEXT,
              inputSize: InputSize.SM,
            }),
            validation: [
              {
                validator: (val: string) => {
                  return val.length > 50 ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
                },
                message: this.translateService.instant('tkey;validators.global.error.maxlength', { maxlength: 50 }),
              },
            ],
          },
          {
            label: 'tkey;snr.snrdestination.destinationnumber.label',
            dataAutomation: 'destinationNumber',
            required: true,
            componentConfig: new SmacsTextConfig({
              htmlInputType: HtmlInputType.TEXT,
              inputSize: InputSize.SM,
            }),
            validation: [
              {
                validator: this._validators.destinationsRegexValidator,
                message: 'tkey;snr.snrdestination.destination.invalid_destination',
              },
              {
                validator: this._validators.destinationsSyncValidator,
                message: () => this._destinationInUseMessage,
                injectValuesFromFields: ['id'],
              },
              {
                validator: this._validators.destinationAsyncValidator,
                message: () => this._destinationInUseMessage,
              },
            ],
          },
          {
            label: 'tkey;snr.snrdestination.delayring.label',
            dataAutomation: 'delayBeforeRingingInSeconds',
            required: true,
            componentConfig: new SmacsTextConfig({
              htmlInputType: HtmlInputType.NUMBER,
              inputSize: InputSize.SM,
              min: 0,
              max: 30,
            }),
            validation: [
              {
                validator: (val: string) => {
                  return Number(val) > 30 || Number(val) < 0
                    ? SmacsFormsValidationState.INVALID
                    : SmacsFormsValidationState.VALID;
                },
                message: 'tkey;snr.snrdestination.destination.range.text',
              },
            ],
          },
          {
            label: 'answerTooSoonTimerInSeconds',
            dataAutomation: 'answerTooSoonTimerInSeconds',
            componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
            hidden: () => true,
          },
          {
            label: 'answerTooLateTimerInSeconds',
            dataAutomation: 'answerTooLateTimerInSeconds',
            componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
            hidden: () => true,
          },
          {
            label: 'id',
            dataAutomation: 'id',
            componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
            hidden: () => true,
          },
        ],
      },
    };

    return { ...destinationsNavsetFields };
  }
}
