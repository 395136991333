import { Injectable } from '@angular/core';
import { Email, EmailTemplatePreviewFieldConfig } from '../../../shared/models/generated/smacsModels';
import { Nvp } from '../../../shared/models/nvp';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuditHeaderService } from '../../../shared/services/audit-header.service';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { EmailPreviewDetailedModalComponent } from '../../cisco/send-email/email-preview-detailed-modal/email-preview-detailed-modal.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';

@Injectable()
export class EmailService {
  constructor(
    private http: HttpClient,
    private auditHeaderService: AuditHeaderService,
    private smacsModalService: SmacsModalService,
    private toastService: ToastService
  ) {}

  sendEmail(email: Email, audits?: Nvp[]): Observable<void> {
    return this.http.post<void>(
      '/services/system/helpdesk/e-mails',
      email,
      this.auditHeaderService.getHttpOptions(audits)
    );
  }

  openEmailPreviewModal(
    enduserUsername: string,
    emailTemplatePreview?: EmailTemplatePreviewFieldConfig,
    cucmServerId?: number
  ) {
    this.smacsModalService
      .openDetailedModal(null, {
        bodyClass: EmailPreviewDetailedModalComponent,
        size: 'lg',
        modalViewProperties: {
          title: 'tkey;helpdesk.send_email.modal.title',
          submitButtonLabel: 'tkey;helpdesk.send_email.modal.submit.label',
          submitButtonIcon: SmacsIcons.SEND_EMAIL,
          enduserUsername: enduserUsername,
          emailTemplatePreview: emailTemplatePreview,
          cucmServerId: cucmServerId,
        },
      })
      .subscribe(() => {
        this.toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.SEND_EMAIL,
          'tkey;helpdesk.send_email.toast.title',
          'tkey;helpdesk.send_email.toast.message'
        );
      });
  }
}
