import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import {
  UccxAgent,
  UccxAgentRef,
  UccxAgentSkill,
  UccxFieldConfig,
} from '../../../../shared/models/generated/smacsModels';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { Subscription, throwError } from 'rxjs';
import { HtmlInputType, InputSize, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  SmacsCheckboxConfig,
} from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { TranslateService } from '@ngx-translate/core';
import {
  BottomNavClearButtonsList,
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { SmacsNavsetConfig, SmacsNavsetItemConfig } from '../../../../shared/smacs-navset/smacs-navset.component';
import { UccxAgentResource } from '../../../shared/resources/uccx-agent.resource';
import { catchError, tap } from 'rxjs/operators';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../../shared/services/toast.service';
import { cloneDeep } from 'lodash';
import { UserDetailUiContext } from '../../../shared/contexts/user-detail-ui.context';
import { Global360ViewContext } from '../../../../shared/contexts/global-360-view.context';

export interface UccxAgentFormData extends UccxAgent {
  uccxNavset: any;
}

@Component({
  selector: 'ziro-uccx-agent-form',
  templateUrl: './uccx-agent-form.component.html',
})
export class UccxAgentFormComponent
  extends SmacsFormAbstractDirective<UccxAgent, UccxAgentFormData>
  implements OnInit, OnDestroy
{
  smacsIcons = SmacsIcons;
  @Input() uccxAgentRef: UccxAgentRef;
  @Input() uccxServer: string;
  @Input() fieldConfig: UccxFieldConfig;
  @Input() resourceGroups: string[];

  isLoading = false;
  isSubmitted = false;
  allSkills: string[] = [];

  formConfig: SmacsFormConfig;

  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private translateService: TranslateService,
    private bottomNavService: BottomNavService,
    private route: ActivatedRoute,
    private router: Router,
    private uccxAgentResource: UccxAgentResource,
    private toastService: ToastService,
    private userDetailUiContext: UserDetailUiContext,
    private global360ViewContext: Global360ViewContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._initUccxData();
    this._initFormConfig();
    this._initBottomNav();

    const formUpdateSub = this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<UccxAgent>) => {
      if (data.valid === SmacsFormsValidationState.VALID && this.isFormSubmitted) {
        this.bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: !this.isFormValid(),
          })
        );
      }
    });

    this._subscriptions.add(formUpdateSub);

    Object.keys(this.fieldChannels).forEach((key) => {
      this.fieldChannels[key].validateSource.next();
    });

    const validateAndSubmitSub = this._validateAndSubmitSource.subscribe(() => {
      this.isSubmitted = true;
    });
    this._subscriptions.add(validateAndSubmitSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
    this.bottomNavService.dispatch(new BottomNavClearButtonsList());
  }

  toFormData = (entity: UccxAgent): UccxAgentFormData => {
    const entityClone = cloneDeep(entity);

    const skills: any = {};

    entityClone.skills.forEach((skill) => {
      const { name, competenceLevel } = skill;
      const formattedSkill: any = {};
      formattedSkill['competenceLevel'] = competenceLevel;
      skills[name] = [formattedSkill];
    });

    return {
      ...entityClone,
      uccxNavset: {
        ...skills,
      },
    };
  };

  toEntity = (formData: UccxAgentFormData): UccxAgent => {
    const formDataClone = cloneDeep(formData);
    const skills: UccxAgentSkill[] = [];

    if (formDataClone.uccxNavset) {
      Object.keys(formDataClone['uccxNavset']).forEach((key) => {
        skills.push({ name: key, competenceLevel: parseInt(formDataClone['uccxNavset'][key][0].competenceLevel) });
      });
    }

    delete formDataClone['uccxNavset'];

    return {
      ...formDataClone,
      skills: skills,
    };
  };

  protected submit() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
      })
    );

    this._setPending(true);
    const apiRequest = this.uccxAgentResource.put(this.entity, this.uccxAgentRef.serverId, this.entity.id);

    return apiRequest.pipe(
      tap((response: UccxAgentRef) => {
        const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
        const agentIndex = global360.uccxAgents.findIndex((agent) => agent.serverId === response.serverId);
        global360.uccxAgents[agentIndex] = response;
        this.global360ViewContext._stateSource.next(global360);
        this.toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.OK,
          'tkey;shared.toast.save.success.title',
          'tkey;uccxagent.toast.success'
        );
        this.router.navigateByUrl(`/user/${encodeURIComponent(this.route.snapshot.params.username)}`);
      }),
      catchError((error) => {
        this.isSubmitting = false;
        this._validateAndSubmitSource.next(false);
        this._setPending(false);
        return throwError(() => error);
      })
    );
  }

  private _initUccxData() {
    this.allSkills = this.fieldConfig.skills.sort();
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        resourceGroup: {
          label: 'tkey;uccxagent.details.resource_group.label',
          dataAutomation: 'uccx-resource-group',
          componentConfig: new SmacsSelectConfig({ options: this.resourceGroups }),
          hidden: () => !this.fieldConfig.resourceGroup.show,
          required: () => this.fieldConfig.resourceGroup.required,
          defaultValue: () => this.fieldConfig.resourceGroup.defaultValue,
        },
        team: {
          label: 'tkey;uccxagent.details.team.label',
          dataAutomation: 'uccx-team',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.team.possibleOptions }),
          hidden: () => !this.fieldConfig.team.show,
          required: () => this.fieldConfig.team.required,
          defaultValue: () => this.fieldConfig.team.defaultValue,
        },
        alias: {
          label: 'tkey;uccxagent.details.alias.label',
          dataAutomation: 'uccx-alias',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          hidden: () => !this.fieldConfig.alias.show,
          required: () => this.fieldConfig.alias.required,
          defaultValue: () => this.fieldConfig.alias.defaultValue,
          validation: [
            {
              validator: (val) =>
                /^([a-z0-9]{0,2}|[a-z0-9][a-z0-9_.-]+[a-z0-9])$/i.test(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;validators.global.error.pattern',
            },
            {
              validator: (val: string) =>
                val.length <= 31 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
              message: this.translateService.instant('tkey;validators.global.error.maxlength', { maxlength: 31 }),
            },
          ],
        },
        automaticAvailable: {
          label: 'tkey;uccxagent.details.automatic_available.label',
          dataAutomation: 'uccx-automatic-available',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
          hidden: () => !this.fieldConfig.automaticAvailable.show,
        },
        uccxNavset: {
          componentConfig: new SmacsNavsetConfig({
            dropdownLabel: 'tkey;uccxagent.details.skills.add_skill.text',
            dropdownTooltip: 'tkey;uccxagent.details.skills.no_more_skills.text',
            emptyMessage: 'tkey;uccxagent.details.skills.none.text',
            navsetItemsConfig: this._getSkillsConfig(),
            allowDuplicates: false,
          }),
        },
      },
    } as SmacsFormConfig;
  }

  private _getSkillsConfig = (): SmacsNavsetItemConfig => {
    const skillNavsetFields: SmacsNavsetItemConfig = {};
    this.allSkills.forEach((skill: string) => {
      skillNavsetFields[skill] = {
        label: skill,
        badgeFieldId: 'competenceLevel',
        fields: [
          {
            label: 'tkey;uccxagent.details.skills.competence_level.label',
            dataAutomation: 'competenceLevel',
            required: true,
            componentConfig: new SmacsTextConfig({
              htmlInputType: HtmlInputType.NUMBER,
              inputSize: InputSize.SM,
              min: 1,
              max: 10,
            }),
            validation: [
              {
                validator: (val: string) => {
                  return Number(val) >= 1 && Number(val) <= 10
                    ? SmacsFormsValidationState.VALID
                    : SmacsFormsValidationState.INVALID;
                },
                message: 'tkey;uccxagent.details.skills.competence_level.invalid',
              },
            ],
          },
        ],
      };
    });

    return {
      ...skillNavsetFields,
    };
  };

  private _initBottomNav = () => {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'agentCancel',
          dataAutomation: 'agent-cancel',
          label: 'tkey;global.button.cancel.text',
          buttonClass: ButtonStyles.DEFAULT,
          cb: () => this._goToUserHome(),
        },
        {
          id: 'agentSave',
          dataAutomation: 'agent-save',
          label: 'tkey;global.button.save.text',
          icon: this.smacsIcons.OK,
          buttonClass: ButtonStyles.PRIMARY,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });
    this._subscriptions.add(formSubmittedSub);
  };

  private _goToUserHome() {
    this.router.navigate(['../../'], { relativeTo: this.route });
  }

  private _setPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'agentSave',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'agentCancel',
        state: {
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }
}
