import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TeamsPhoneNumberAssignmentFieldConfig } from '../../../shared/models/generated/smacsModels';
import { Observable } from 'rxjs';

@Injectable()
export class MicrosoftPhoneNumberAssignmentsFieldConfigResource {
  private _baseUrl = '/services/microsoft/field-configs/teams-phone-number-assignments';

  constructor(private http: HttpClient) {}

  get(lineUri: string): Observable<TeamsPhoneNumberAssignmentFieldConfig> {
    return this.http.get<TeamsPhoneNumberAssignmentFieldConfig>(`${this._baseUrl}/${encodeURIComponent(lineUri)}`);
  }
}
