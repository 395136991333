<smacs-fieldset-header [legend]="'tkey;admin.site_management.bulk_change.select_field'">
  <smacs-select fieldId="siteName"></smacs-select>
  <smacs-select fieldId="serviceName"></smacs-select>
  <smacs-select fieldId="fieldName"></smacs-select>
</smacs-fieldset-header>
<div *ngIf="entity && entity.fieldName">
  <legend>{{ 'tkey;admin.site_management.bulk_change.field.preview' | translate }}</legend>
  <table class="table table-striped table-condensed" data-automation="preview-table">
    <tbody>
      <tr class="group-row" *ngFor="let fieldData of selectedFieldData | keyvalue : originalOrder">
        <td scope="row" data-automation="preview-table-field" class="w-25">
          <strong>{{ fieldData.key }}</strong>
        </td>
        <td data-automation="preview-table-value">{{ fieldData.value }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div [hidden]="!(selectedFieldData && entity && entity.fieldName)">
  <div class="row ms-0 me-1 border-bottom">
    <div class="d-inline ps-0 pe-1 w-auto">
      <h4>
        {{ 'tkey;admin.site_management.bulk_change.copy.field.settings.header.label' | translate }}
      </h4>
    </div>
    <hr />
  </div>

  <div class="row">
    <div class="col-sm-12">
      <smacs-radio [fieldId]="'copyDestination'"></smacs-radio>
    </div>
  </div>

  <smacs-select [fieldId]="'selectedSites'"></smacs-select>
</div>
