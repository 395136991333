import { Injectable } from '@angular/core';
import {
  ExtensionMobility,
  Phone,
  PhoneButtonTemplate,
  PhoneButtonTemplateRef,
  PhoneButtonTemplateResult,
  PhoneButtonTemplateSearch,
  PostPhoneButtonTemplate,
} from '../models/generated/smacsModels';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { SearchAbstractResource } from '../../self-serve/resources/search/search.abstract.resource';
import { AuditHeaderService } from './audit-header.service';
import { HttpOptions } from '../resources/abstract/model.resource.abstract';
import { Nvp } from '../models/nvp';

@Injectable()
export class PhoneButtonTemplateService extends SearchAbstractResource {
  constructor(protected http: HttpClient, private auditHeaderService: AuditHeaderService) {
    super(http);
  }

  findOrCreateTemplate(phone: Phone | ExtensionMobility, cucmServerId: number): Observable<PhoneButtonTemplateRef> {
    const buttonFeatures = phone.buttons.map((button) => button.type);

    const searchQuery = {
      model: phone.model,
      protocol: phone.protocol,
      features: buttonFeatures,
      cucmServerId: cucmServerId,
    } as PhoneButtonTemplateSearch;
    return this._findTemplates(searchQuery).pipe(
      switchMap((results) => {
        if (results?.length > 0) {
          return of(results[0].ref);
        } else {
          const postBody = {
            model: phone.model,
            protocol: phone.protocol,
            features: buttonFeatures,
          } as PostPhoneButtonTemplate;
          return this._createTemplate(postBody, cucmServerId, [{ name: 'name', value: phone.name }]);
        }
      })
    );
  }

  findTemplatesByModelAndProtocol(
    model: string,
    protocol: string,
    cucmServerId: number
  ): Observable<PhoneButtonTemplateResult[]> {
    const searchQuery = {
      model: model,
      protocol: protocol,
      'cucm-server-id': cucmServerId,
    };
    return this._searchTemplates(searchQuery);
  }

  getTemplate(id: string, cucmServerId: number): Observable<PhoneButtonTemplate> {
    return this._getTemplate(id, cucmServerId);
  }

  deleteTemplate(id: string, cucmServerId: number): Observable<void> {
    return this._deleteTemplate(id, cucmServerId);
  }

  private _getTemplate(id: string, cucmServerId: number): Observable<PhoneButtonTemplate> {
    return this.http.get<PhoneButtonTemplate>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(
        cucmServerId
      )}/phone-button-templates/${encodeURIComponent(id)}`
    );
  }

  private _createTemplate(
    body: PostPhoneButtonTemplate,
    cucmServerId: number,
    auditTags: Nvp[]
  ): Observable<PhoneButtonTemplateRef> {
    const httpHeaders: HttpOptions = { headers: this.auditHeaderService.buildHeader(auditTags) };

    return this.http.post<PhoneButtonTemplateRef>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(cucmServerId)}/phone-button-templates`,
      body,
      httpHeaders
    );
  }

  private _deleteTemplate(id: string, cucmServerId: number): Observable<void> {
    return this.http.delete<void>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(
        cucmServerId
      )}/phone-button-templates/${encodeURIComponent(id)}`
    );
  }

  private _findTemplates(body: PhoneButtonTemplateSearch): Observable<PhoneButtonTemplateResult[]> {
    return this.http.post<PhoneButtonTemplateResult[]>('/services/cisco/macs/phone-button-templates/search', body);
  }

  private _searchTemplates(params: {
    q?: string;
    name?: string;
    model?: string;
    protocol?: string;
    'cucm-server-id'?: number;
  }): Observable<PhoneButtonTemplateResult[]> {
    return this.search('/services/cisco/macs/phone-button-templates/search', params);
  }
}
