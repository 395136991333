import { Injectable } from '@angular/core';
import { MicrosoftBulkJobStatus, UserPhoto } from '../../../../shared/models/generated/smacsModels';
import { TranslateService } from '@ngx-translate/core';
import { EntityTableContentRow } from '../../../../shared/entity-table/entity-table.models';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';

@Injectable()
export abstract class ZpmBulkProvisioningEntityTableContainerAbstractComponent {
  isLoadingTable = true;
  bulkJobStatus: MicrosoftBulkJobStatus;
  tableRows: EntityTableContentRow[] = [];

  protected constructor(protected _translateService: TranslateService) {}

  getTableRows(upns: string[], userPhotos: UserPhoto[]) {
    return upns.map((upn: string) => {
      const photo = userPhotos.find((userPhoto: UserPhoto) => userPhoto.userPrincipalName === upn);
      const status = this._getStatusForRow(upn);

      return {
        content: {
          displayName: photo ? photo.displayName : '',
          upn: upn,
          processingStatus: status,
        },
        html: {
          icon: photo
            ? photo.photoBase64
              ? `
                <img src="data:image/png;base64,${photo.photoBase64}" 
                    alt="${photo.displayName}" 
                    class="user-profile-picture entity-table-thumbnail">
            `
              : '<i class="icon-user-circle font-size-32"></i>'
            : '<i class="icon-saving"></i>',
          displayName: photo
            ? photo.displayName ||
              `<span class="font-italic">${this._translateService.instant(
                'tkey;automate.zpm.bulk_provisioning.previous_job.table.user_not_found.text'
              )}</span>`
            : '',
          processingStatus: this._getBadgeForRow(status),
        },
      };
    });
  }

  private _getBadgeForRow(status: string): string {
    switch (status) {
      case 'Success': {
        return `
          <span class="alert alert-inline alert-info">
              <i class="${SmacsIcons.CHECK_CIRCLE} text-success"></i> ${status}
          </span>
        `;
      }
      case 'Failed': {
        return `
          <span class="alert alert-inline alert-info">
              <i class="${SmacsIcons.UNAVAILABLE} text-danger"></i> ${status}
          </span>
        `;
      }
      case 'Queued': {
        return `
          <span class="alert alert-inline alert-info">
              <i class="${SmacsIcons.SAVING} text-warning"></i> ${status}
          </span>
        `;
      }
      case 'In Progress': {
        return `
          <span class="alert alert-inline alert-info">
              <i class="${SmacsIcons.SAVING} text-warning"></i> ${status}
          </span>
        `;
      }
      default: {
        return '';
      }
    }
  }

  private _getStatusForRow(upn: string) {
    if (this.bulkJobStatus.jobDetailedStatus.completedUsers.some((user) => user.userPrincipalName === upn)) {
      return this._translateService.instant('tkey;automate.zpm.bulk_provisioning.previous_job.table.status.success');
    } else if (this.bulkJobStatus.jobDetailedStatus.failedUsers.some((user) => user.userPrincipalName === upn)) {
      return this._translateService.instant('tkey;automate.zpm.bulk_provisioning.previous_job.table.status.failed');
    } else if (this.bulkJobStatus.jobDetailedStatus.remainingUsers.some((user) => user.userPrincipalName === upn)) {
      return this._translateService.instant('tkey;automate.zpm.bulk_provisioning.previous_job.table.status.queued');
    } else if (this.bulkJobStatus.jobDetailedStatus.inProgressUser.some((user) => user.userPrincipalName === upn)) {
      return this._translateService.instant(
        'tkey;automate.zpm.bulk_provisioning.previous_job.table.status.in_progress'
      );
    }

    return '';
  }
}
