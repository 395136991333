<app-loading-block id="loading-primary-extension" [hidden]="!isLoading"></app-loading-block>
<div *ngIf="!isLoading" class="col-12 pb-5">
  <smacs-directory-number-dn-did-selector-form
    (smacsFormsUpdate$)="onFormUpdate($event, 'DN_DID_SELECTOR_FORM')"
    [dialPlanGroups]="dialPlanGroups"
    [initialDialPlanGroupId]="initialDialPlanGroupId"
    [initialExtension]="initialDnRef?.extension"
    [entity]="dnDidSelectorFormEntity"
    [isZpmSyncWarningPresent]="isZpmSyncWarningPresent"
  ></smacs-directory-number-dn-did-selector-form>

  <app-loading-block
    *ngIf="
      dnDidSelectorFormEntity.dialPlanGroupId &&
      dnDidSelectorFormEntity.directoryNumber.extension &&
      !isFieldConfigsLoaded
    "
    data-automation="directory-number-forms-loading-block"
  ></app-loading-block>
  <div
    data-automation="directory-number-forms"
    *ngIf="
      dnDidSelectorFormEntity.dialPlanGroupId &&
      dnDidSelectorFormEntity.directoryNumber.extension &&
      isFieldConfigsLoaded
    "
  >
    <div *ngIf="isLdapUser() && isEndUserPrimary">
      <smacs-directory-number-ldap-details-form
        [fieldConfig]="ldapFieldConfig"
        [entity]="dnLdapDetailsFormEntity"
        (smacsFormsUpdate$)="onFormUpdate($event, 'LDAP_DETAILS_FORM')"
      ></smacs-directory-number-ldap-details-form>
    </div>

    <smacs-directory-number-details-form
      [fieldConfig]="dnDetailsFieldConfig"
      [entity]="dnDetailsFormEntity"
      (smacsFormsUpdate$)="onFormUpdate($event, 'DN_DETAILS_FORM')"
    ></smacs-directory-number-details-form>

    <div *ngIf="dnDidSelectorFormEntity.translationPattern.pattern">
      <smacs-directory-number-did-details
        [fieldConfig]="didDetailsFieldConfig"
        [entity]="didDetailsFormEntity"
        (smacsFormsUpdate$)="onFormUpdate($event, 'DN_DID_DETAILS_FORM')"
      ></smacs-directory-number-did-details>
    </div>

    <smacs-directory-number-ils-details-form
      [entity]="dnIlsDetailsFormEntity"
      [fieldConfigs]="dnIlsDetailsFieldConfig"
      (smacsFormsUpdate$)="onFormUpdate($event, 'DN_ILS_DETAILS_FORM')"
    ></smacs-directory-number-ils-details-form>

    <div *ngIf="!isPublicPhone && hasUccxSettings">
      <smacs-directory-number-agent-extension-form
        [entity]="dnAgentExtFormEntity"
        (smacsFormsUpdate$)="onFormUpdate($event, 'DN_AGENT_EXT_FORM')"
        [dnExtension]="dnDidSelectorFormEntity.directoryNumber.extension"
      ></smacs-directory-number-agent-extension-form>
    </div>

    <smacs-directory-number-call-forward-form
      [fieldConfig]="dnCallForwardFieldConfig"
      [entity]="dnCallForwardFormEntity"
      (smacsFormsUpdate$)="onFormUpdate($event, 'DN_CALL_FORWARD_FORM')"
      [hasVoicemail]="dnHasVoicemail()"
      [siteId]="siteId"
      [siteSummary]="siteSummary"
    ></smacs-directory-number-call-forward-form>
  </div>
</div>
