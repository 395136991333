import { ChangeDetectorRef, Component, ContentChild, Input } from '@angular/core';
import { ButtonStyles, ButtonTypes } from '../../button/button.component';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { SmacsFieldAbstractDirective } from '../../forms/smacs-field-abstract.directive';
import {
  HtmlInputAddOn,
  SmacsFieldComponentConfig,
  SmacsFormsMessage,
  SmacsFormsValidationState,
} from '../../forms/smacs-forms-models';
import { AcceptedFileExtensions } from '../../forms/fields/file-upload/smacs-file-upload.component';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { isNil } from 'lodash';
import { EntityTable, EntityTableContentRow } from '../entity-table/entity-table.models';
import { SmacsSubmitButtonComponent } from '../../forms/submit-button/smacs-submit-button.component';

export interface ZiroFile<METADATA = unknown> {
  id: string;
  file: File;
  metadata?: METADATA;
}

export enum UploaderType {
  STAGED = 'STAGED',
  TABLE = 'TABLE',
}

export type MetadataFn<T> = (file: File) => T;

export class SmacsFileUploaderConfig<METADATA> extends SmacsFieldComponentConfig {
  constructor(
    public config: {
      uploaderType: UploaderType;
      acceptedFileExtensions?: AcceptedFileExtensions;
      maxSize?: number; // in kB
      metadataFn?: MetadataFn<METADATA>; // use this to get some metadata and display it when the file is selected
      maxFileNameLength?: number;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: FileUploaderComponent }],
})
export class FileUploaderComponent<METADATA> extends SmacsFieldAbstractDirective<
  ZiroFile<METADATA>[],
  ZiroFile<METADATA>[],
  SmacsFileUploaderConfig<METADATA>
> {
  @ContentChild(SmacsSubmitButtonComponent) submitButton: SmacsSubmitButtonComponent;
  @Input() tableRows: EntityTableContentRow[] = [];
  @Input() showTable = false;

  @Input() set table(table: EntityTable) {
    this._table = table;
  }

  get table(): EntityTable {
    return this._table;
  }

  _table: EntityTable;
  files: ZiroFile<METADATA>[] = [];
  isFileOver: boolean;
  htmlInputAddOn: HtmlInputAddOn;
  formattedSize: string;
  invalidFileIds: string[] = [];
  uploaderType: UploaderType;
  formattedAcceptedFields: string;
  acceptedFileExtensions: AcceptedFileExtensions;
  maxFileNameLength: number;

  private _maxSizeInBytes = 0;
  private metadataFn: MetadataFn<METADATA>;

  protected readonly ButtonTypes = ButtonTypes;
  protected readonly ButtonStyles = ButtonStyles;
  protected readonly SmacsIcons = SmacsIcons;

  private validators = {
    isWithinSizeConstraint: {
      validator: (files: ZiroFile[]): SmacsFormsValidationState => {
        let smacsValidationState: SmacsFormsValidationState;

        files?.forEach(({ file, id }) => {
          const isValid = !file || file?.size <= this._maxSizeInBytes;
          smacsValidationState = isValid ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
          if (!isValid && !this.invalidFileIds.includes(id)) {
            this.invalidFileIds.push(id);
          }
        });

        if (!!this.invalidFileIds.length) {
          smacsValidationState = SmacsFormsValidationState.INVALID;
        }

        return smacsValidationState;
      },
      message: (): SmacsFormsMessage => {
        return {
          content: 'tkey;shared.fileselect.validators.max_size',
          params: {
            size: this._formatFileSize(this._maxSizeInBytes),
          },
        };
      },
    },
    isAcceptedFileType: {
      validator: (files: ZiroFile[]): SmacsFormsValidationState => {
        let smacsValidationState: SmacsFormsValidationState;

        files?.forEach(({ file, id }) => {
          const fileType = file.name.substring(file.name.lastIndexOf('.'));
          const isValid = this.acceptedFileExtensions.acceptedExtensions
            .split(',')
            .some((type) => type.trim() === fileType);
          smacsValidationState = isValid ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
          if (!isValid && !this.invalidFileIds.includes(id)) {
            this.invalidFileIds.push(id);
          }
        });

        if (!!this.invalidFileIds.length) {
          smacsValidationState = SmacsFormsValidationState.INVALID;
        }

        return smacsValidationState;
      },
      message: (): SmacsFormsMessage => {
        return {
          content: 'tkey;shared.fileselect.validators.strict_file_types',
          params: { types: this.acceptedFileExtensions.acceptedExtensions.replace(/\s*,\s*/g, '/') },
        };
      },
    },
  };

  constructor(protected smacsFormStateService: SmacsFormStateService, private changeDetectorRef: ChangeDetectorRef) {
    super(smacsFormStateService);
  }

  applyComponentConfig = ({ config }: SmacsFileUploaderConfig<METADATA>) => {
    // remove component-specific validators if they are already present, to preserve the proper order and prevent dupes
    this.config.validation = (this.config.validation || []).filter((validator) => {
      return validator !== this.validators.isWithinSizeConstraint && validator !== this.validators.isAcceptedFileType;
    });

    this.acceptedFileExtensions = isNil(config.acceptedFileExtensions)
      ? this.acceptedFileExtensions
      : config.acceptedFileExtensions;
    this._maxSizeInBytes = isNil(config.maxSize) ? this._maxSizeInBytes : config.maxSize * 1024;
    this.formattedSize = this._formatFileSize(this._maxSizeInBytes);

    this.formattedAcceptedFields = config.acceptedFileExtensions.acceptedExtensions.replaceAll(',', ' /');

    if (this._maxSizeInBytes) {
      this.config.validation = [this.validators.isWithinSizeConstraint, ...this.config.validation];
    }
    if (this.acceptedFileExtensions && !this.acceptedFileExtensions.allowOthers) {
      this.config.validation = [this.validators.isAcceptedFileType, ...this.config.validation];
    }

    this.uploaderType = config.uploaderType;
    this.metadataFn = config.metadataFn;
    this.maxFileNameLength = config.maxFileNameLength;
  };

  handleFiles(fileList: FileList) {
    if (!fileList.length || this.state.disabled) return;

    this.deleteInvalidFiles();

    const file = Array.from(fileList).at(-1);
    const extensionRegex = /(?:\.([^.]+))?$/;
    const extensionType = extensionRegex.exec(file.name)[1];
    const renamedFile = new File(
      [file],
      !this.maxFileNameLength || file.name.length <= 50
        ? file.name
        : `${file.name.substring(0, this.maxFileNameLength - 4)}.${extensionType}`,
      {
        type: file.type,
      }
    );
    const newFile: ZiroFile<METADATA> = { id: Date.now().toString(), file: renamedFile };

    if (this.metadataFn) {
      newFile.metadata = this.metadataFn(file);
    }

    if (this.uploaderType === UploaderType.TABLE) {
      this.files = [];
    }
    this.files = [...this.files, newFile];

    this.changeDetectorRef.detectChanges();
    this.updateSelf(this.files);
  }

  onFileOver($event: boolean) {
    this.isFileOver = $event;
  }

  getStagedFileSubtext(file: ZiroFile): string {
    const fileSize = this._formatFileSize(file.file?.size);
    if (file.metadata) {
      return file.metadata + ' | ' + fileSize;
    }
    return fileSize;
  }

  onDeleteFile(id: string) {
    if (this.invalidFileIds.includes(id)) {
      const check = this.invalidFileIds.findIndex((identifier) => identifier === id);
      this.invalidFileIds.splice(check, 1);
    }
    this.files.splice(
      this.files.findIndex((f) => f.id === id),
      1
    );

    this.changeDetectorRef.detectChanges();
    this.updateSelf(this.files);
  }

  private deleteInvalidFiles() {
    this.invalidFileIds.forEach((invalidFile) => {
      this.onDeleteFile(invalidFile);
    });
  }

  private _formatFileSize(bytes: number): string {
    const kb = 1024;
    const mb = Math.pow(kb, 2);
    const gb = Math.pow(kb, 3);

    if (bytes < kb) return `${bytes} B`;
    if (bytes < mb) return `${this._roundToOneDecimalAsUnit(bytes, kb)} kB`;
    if (bytes < gb) return `${this._roundToOneDecimalAsUnit(bytes, mb)} MB`;
    return `${this._roundToOneDecimalAsUnit(bytes, gb)} GB`;
  }

  private _roundToOneDecimalAsUnit(bytes: number, unit: number): number {
    return Math.floor((bytes * 10) / unit) / 10;
  }

  toEntity = (fieldData: ZiroFile<METADATA>[]) => fieldData;

  toFieldData = (entity: ZiroFile<METADATA>[]) => entity;
  protected readonly UploaderType = UploaderType;
}
