import { Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../forms/fields/select/smacs-select.component';
import { PhoneButtonTemplateRef } from '../../../../shared/models/generated/smacsModels';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { filter, first } from 'rxjs/operators';
import { PhoneUiContext } from '../../../../shared/phone-buttons/contexts/phone-ui.context';
import { ExtMobilityUiContext } from '../../../../shared/phone-buttons/contexts/ext-mobility-ui.context';

interface PhoneButtonTemplateFormData {
  template: SmacsSelectOption;
}

@Component({
  selector: 'smacs-phone-button-template-form',
  templateUrl: './phone-button-template-form.component.html',
})
export class PhoneButtonTemplateFormComponent
  extends SmacsFormAbstractDirective<PhoneButtonTemplateRef, PhoneButtonTemplateFormData>
  implements OnInit, OnChanges
{
  @Input() possibleOptions: PhoneButtonTemplateRef[];

  formConfig: SmacsFormConfig;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    @Optional() private phoneUiContext: PhoneUiContext,
    @Optional() private extensionMobilityUiContext: ExtMobilityUiContext
  ) {
    super(smacsFormStateService);
  }

  private static _mapPossibleOptions(options: PhoneButtonTemplateRef[]): SmacsSelectOption[] {
    return options.map((opt) => ({ label: opt.name, value: opt }));
  }

  protected submit() {
    return (this.phoneUiContext || this.extensionMobilityUiContext).isSaving$.pipe(
      filter((isSaving) => !isSaving),
      first()
    );
  }

  ngOnInit() {
    const options = PhoneButtonTemplateFormComponent._mapPossibleOptions(this.possibleOptions);
    this.formConfig = {
      fields: {
        template: {
          label: 'tkey;pages.details.phoneSettings.phone_button_template.label',
          dataAutomation: 'phone-button-template-select',
          componentConfig: new SmacsSelectConfig({ options }),
          required: true,
        },
      },
    };
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.possibleOptions && !changes.possibleOptions.firstChange) {
      const templateConfig = new SmacsSelectConfig({
        options: PhoneButtonTemplateFormComponent._mapPossibleOptions(changes.possibleOptions.currentValue),
      });
      const templateComponent = this.fieldComponents.find((field) => field.fieldId === 'template');
      templateComponent.fieldData = this.toFormData(this.entity).template;
      templateComponent.applyComponentConfig(templateConfig);
    }
  }

  toEntity = (formData: PhoneButtonTemplateFormData): PhoneButtonTemplateRef => {
    return formData?.template?.value;
  };

  toFormData = (entity: PhoneButtonTemplateRef): PhoneButtonTemplateFormData => {
    return {
      template: {
        label: entity?.name,
        value: entity,
      },
    };
  };
}
