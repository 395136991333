import { Component, Input, OnInit } from '@angular/core';
import { cloneDeep } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SnrFieldConfig, SnrProfile, SnrProfileResult } from '../../../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SearchResource } from '../../../../shared/resources/search.resource';

interface SnrProfileFormData {
  profileName: string;
  profileDescription: string;
  reroutingCallingSearchSpace: string;
  callingSearchSpace: string;
  aarCallingSearchSpace: string;
  userMohAudioSource: string;
  networkMohAudioSource: string;
  privacy: string;
}

@Component({
  selector: 'smacs-snr-profile-form',
  templateUrl: './snr-profile-form.component.html',
})
export class SnrProfileFormComponent
  extends SmacsFormAbstractDirective<SnrProfile, SnrProfileFormData>
  implements OnInit
{
  @Input() fieldConfig: SnrFieldConfig;
  @Input() cucmServerId: number;

  formConfig: SmacsFormConfig;
  nameAsyncValidatorMessage = '';
  showAsyncValidatorSuccessMessage = false;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private translateService: TranslateService,
    private searchResource: SearchResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._initFormConfig();
  }

  toFormData = (entity: SnrProfile): SnrProfileFormData => {
    const entityCopy = cloneDeep(entity);
    const formData = {
      profileName: entityCopy.name,
      profileDescription: entityCopy.description,
      reroutingCallingSearchSpace: entityCopy.reRoutingCss,
      callingSearchSpace: entityCopy.css,
      aarCallingSearchSpace: entityCopy.aarCss,
      userMohAudioSource: entityCopy.userMohSourceName,
      networkMohAudioSource: entityCopy.networkMohSourceName,
      privacy: entityCopy.privacy,
    };

    return formData;
  };

  toEntity = (formData: SnrProfileFormData): SnrProfile => {
    const formDataCopy = cloneDeep(formData);
    const newEntity = {
      ...this.entity,
      name: formDataCopy.profileName,
      description: formDataCopy.profileDescription,
      reRoutingCss: formDataCopy.reroutingCallingSearchSpace,
      css: formDataCopy.callingSearchSpace,
      aarCss: formDataCopy.aarCallingSearchSpace,
      userMohSourceName: formDataCopy.userMohAudioSource,
      networkMohSourceName: formDataCopy.networkMohAudioSource,
      privacy: formDataCopy.privacy,
    };

    return newEntity;
  };

  submit() {
    return this.parentSubmit$;
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        profileName: {
          label: 'tkey;snr.snrdestination.name.label',
          dataAutomation: 'snr-profile-name',
          hidden: () => !this.fieldConfig.profileName.show,
          required: () => this.fieldConfig.profileName.required,
          defaultValue: () => this.fieldConfig.profileName.defaultValue,
          validation: [
            {
              validator: (val) =>
                /^[a-zA-Z 0-9._-]+$/i.test(val) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
              message: 'tkey;validators.global.error.pattern',
            },
            {
              validator: (val: string) => {
                return val.length > 50 ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
              },
              message: this.translateService.instant('tkey;validators.global.error.maxlength', { maxlength: 50 }),
            },
            {
              validator: (val: string): Observable<SmacsFormsValidationState> => {
                this.showAsyncValidatorSuccessMessage = false;
                return this.searchResource.searchSnrProfiles(val, 'name', this.cucmServerId).pipe(
                  map((results: SnrProfileResult[]) => {
                    const conflictingResult = results.find(
                      (result: SnrProfileResult) => result.ref.id !== this.entity.id
                    );
                    if (!conflictingResult) {
                      return SmacsFormsValidationState.VALID;
                    } else {
                      if (!conflictingResult.owner) {
                        this.nameAsyncValidatorMessage = this.translateService.instant(
                          'tkey;shared.device.phone.name.warning.overwrite.pub.helpblock',
                          { description: conflictingResult.ref.description }
                        );
                        return SmacsFormsValidationState.WARNING;
                      }
                      this.nameAsyncValidatorMessage = this.translateService.instant(
                        'tkey;shared.device.phone.name.error.unique.helpblock',
                        { description: conflictingResult.ref.description }
                      );
                      return SmacsFormsValidationState.INVALID;
                    }
                  })
                );
              },
              message: () => this.nameAsyncValidatorMessage,
              successMessage: () => (this.showAsyncValidatorSuccessMessage ? this.nameAsyncValidatorMessage : ''),
            },
          ],
        },
        profileDescription: {
          label: 'tkey;snr.snrprofile.description.label',
          dataAutomation: 'snr-profile-description',
          hidden: () => !this.fieldConfig.profileDescription.show,
          required: () => this.fieldConfig.profileDescription.required,
          defaultValue: () => this.fieldConfig.profileDescription.defaultValue,
          validation: [
            {
              validator: (val: string) => {
                return val.length > 128 ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
              },
              message: this.translateService.instant('tkey;validators.global.error.maxlength', { maxlength: 128 }),
            },
          ],
        },
        callingSearchSpace: {
          label: 'tkey;snr.snrprofile.css.label',
          dataAutomation: 'snr-profile-css',
          hidden: () => !this.fieldConfig.callingSearchSpace.show,
          required: () => this.fieldConfig.callingSearchSpace.required,
          defaultValue: () => this.fieldConfig.callingSearchSpace.defaultValue,
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.callingSearchSpace.possibleOptions }),
        },
        aarCallingSearchSpace: {
          label: 'tkey;snr.snrprofile.aarcss.label',
          dataAutomation: 'snr-profile-aar-css',
          hidden: () => !this.fieldConfig.aarCallingSearchSpace.show,
          required: () => this.fieldConfig.aarCallingSearchSpace.required,
          defaultValue: () => this.fieldConfig.aarCallingSearchSpace.defaultValue,
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.aarCallingSearchSpace.possibleOptions }),
        },
        reroutingCallingSearchSpace: {
          label: 'tkey;snr.snrprofile.reroutingcss.label',
          dataAutomation: 'snr-profile-rerouting-css',
          hidden: () => !this.fieldConfig.reroutingCallingSearchSpace.show,
          required: () => this.fieldConfig.reroutingCallingSearchSpace.required,
          defaultValue: () => this.fieldConfig.reroutingCallingSearchSpace.defaultValue,
          componentConfig: new SmacsSelectConfig({
            options: this.fieldConfig.reroutingCallingSearchSpace.possibleOptions,
          }),
        },
        userMohAudioSource: {
          label: 'tkey;snr.snrprofile.mohaudiouser.label',
          dataAutomation: 'snr-profile-mohaudiouser',
          hidden: () => !this.fieldConfig.userMohAudioSource.show,
          required: () => this.fieldConfig.userMohAudioSource.required,
          defaultValue: () => this.fieldConfig.userMohAudioSource.defaultValue,
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.userMohAudioSource.possibleOptions }),
        },
        networkMohAudioSource: {
          label: 'tkey;snr.snrprofile.mohaudionetwork.label',
          dataAutomation: 'snr-profile-mohaudionetwork',
          hidden: () => !this.fieldConfig.networkMohAudioSource.show,
          required: () => this.fieldConfig.networkMohAudioSource.required,
          defaultValue: () => this.fieldConfig.networkMohAudioSource.defaultValue,
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.networkMohAudioSource.possibleOptions }),
        },
        privacy: {
          label: 'tkey;snr.snrprofile.privacy.label',
          dataAutomation: 'snr-profile-privacy',
          hidden: () => !this.fieldConfig.privacy.show,
          required: () => this.fieldConfig.privacy.required,
          defaultValue: () => this.fieldConfig.privacy.defaultValue,
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.privacy.possibleOptions }),
        },
      },
    };
  }
}
