import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { AuditHeaderService } from '../../shared/services/audit-header.service';
import { SmacsModalService } from '../../shared/services/smacs-modal.service';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../button/button.component';
import { Nvp } from '../../shared/models/nvp';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class VoicemailPinResetResource {
  constructor(
    private http: HttpClient,
    private auditHeaderService: AuditHeaderService,
    private smacsModalService: SmacsModalService
  ) {}

  // resolve with 200 when pin reset is successful (response.data = VoicemailPinResetStatusJson)
  // reject with 422 when pin reset is not successful (response.data = VoicemailPinResetStatusJson)
  resetPin(
    serverId: number,
    voicemailId: string,
    pin: string,
    resetPinOnNextLogin: boolean,
    auditTags: Nvp[]
  ): Observable<any> {
    const auditTagHeader = this.auditHeaderService.buildHeader(auditTags);
    return this.http
      .post(
        `/services/cisco/macs/unity-servers/${serverId}/voicemails/${encodeURIComponent(voicemailId)}/pin-reset`,
        {
          pin,
          resetOnNextLogin: resetPinOnNextLogin,
        },
        {
          headers: auditTagHeader,
        }
      )
      .pipe(
        catchError((rejected) => {
          if (rejected.status === 422 && rejected.error.reasonCode === 'FORBIDDEN_DN_MODIFICATION') {
            const options = {
              modalViewProperties: {
                title: 'tkey;forbidden.user.title',
                promptBody: rejected.error.description,
                icon: SmacsIcons.FORBIDDEN,
                iconClass: 'text-danger',
                displayCloseButton: true,
                buttons: [
                  {
                    label: 'tkey;dialogs.button.ok',
                    buttonClass: ButtonStyles.INFO,
                    dataAutomation: 'confirm-navigation-cancel',
                  },
                ],
              },
            };
            this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
          }
          return throwError(() => rejected);
        })
      );
  }
}
