import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ReplaySubject } from 'rxjs';
import { GlobalPropertiesContext } from './global-properties.context';

export interface CurrentDateContextState {
  utcTimestamp: Moment;
  year: number;
}

@Injectable()
export class CurrentDateContext {
  private _stateSource = new ReplaySubject<CurrentDateContextState>(1);
  state$ = this._stateSource.asObservable();

  constructor(protected globalPropertiesContext: GlobalPropertiesContext) {
    this._init();
  }

  toLocal = (dateTime: string) => {
    return moment.utc(dateTime).local();
  };

  formatDateTime = (date: Moment) => {
    return date.format('MMM DD YYYY, hh:mm:ss a');
  };

  private _init() {
    this.globalPropertiesContext.state$.subscribe((state) => {
      const utcTimestamp = moment.utc(state.buildTimestamp);
      this._stateSource.next({
        utcTimestamp: utcTimestamp,
        year: utcTimestamp.year(),
      });
    });
  }
}
