import { Injectable } from '@angular/core';
import { filter, pick, sortBy } from 'lodash';
import {
  AbstractCiscoRef,
  EndUserResult,
  Global360View,
  PhoneRef,
  UccxAgentRef,
} from '../models/generated/smacsModels';
import { ServiceTypeMetadataService } from '../services/service-type-metadata.service';
import { ServiceTypeDeterminationService } from '../services/service-type-determination.service';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { Global360ViewResource } from '../resources/global-360-view.resource';

export enum ServiceTypes {
  PRIMARY_EXTENSION = 'Primary Extension',
  UCCX_AGENT = 'UCCX Agent',
  VOICEMAIL = 'Voicemail',
  SNR = 'Single Number Reach',
  DESKPHONE = 'Desk Phone',
  EXTENSION_MOBILITY = 'Extension Mobility',
  IM_PRESENCE = 'IM Presence',
  SOFTPHONE = 'IM Softphone',
  CIPC = 'CIPC',
  ANDROID = 'Android',
  IPHONE = 'IPhone',
  TABLET = 'Tablet',
  CTI_PORT = 'CTI Port',
  CTI_REMOTE_DEVICE = 'CTI Remote Device',
  SPARK_REMOTE_DEVICE = 'Cisco Spark Remote Device',
}

@Injectable()
export class Global360ViewContext {
  _stateSource = new ReplaySubject<Global360View>(1);
  state$ = this._stateSource.asObservable();
  _isFetchingSource = new BehaviorSubject<boolean>(false);
  isFetching$ = this._isFetchingSource.asObservable();
  allServiceTypes = Object.values(ServiceTypes);
  deviceServices = Object.values(
    pick(ServiceTypes, ['SNR', 'DESKPHONE', 'SOFTPHONE', 'CIPC', 'ANDROID', 'IPHONE', 'TABLET'])
  );

  constructor(
    private serviceTypeDeterminationService: ServiceTypeDeterminationService,
    private serviceTypeMetadataService: ServiceTypeMetadataService,
    private global360ViewResource: Global360ViewResource
  ) {}

  init(username: string) {
    this._isFetchingSource.next(true);
    this.getGlobal360View(username).subscribe((global360View: Global360View) => {
      this._stateSource.next(global360View);
      this._isFetchingSource.next(false);
    });
  }

  hasInstanceOf(serviceType: ServiceTypes, global360View: Global360View, serverIds: number[]): boolean {
    return this._getServiceInstances(serviceType, global360View, serverIds).length > 0;
  }

  hasAnyDeviceInstances(global360View: Global360View, serverIds: number[]): boolean {
    return this.deviceServices.some((s) => this.hasInstanceOf(s, global360View, serverIds));
  }

  hasAnyServiceInstances(global360View: Global360View, serverIds: number[]): boolean {
    return this.allServiceTypes.some((s) => this.hasInstanceOf(s, global360View, serverIds));
  }

  /**
   * Returns an array of service instances for the specified serviceType. If no service is present, an empty array
   * is returned.
   */
  private _getServiceInstances(serviceType: ServiceTypes, global360View: Global360View, serverIds: number[]) {
    if (!global360View) {
      return [];
    }

    let serviceInstances: AbstractCiscoRef[];

    if (serviceType === ServiceTypes.PRIMARY_EXTENSION) {
      serviceInstances = global360View.primaryExtensions;
    }
    if (serviceType === ServiceTypes.VOICEMAIL) {
      serviceInstances = global360View.voicemails;
    }
    if (serviceType === ServiceTypes.UCCX_AGENT) {
      serviceInstances = global360View.uccxAgents;
    }
    if (serviceType === ServiceTypes.SNR) {
      serviceInstances = global360View.snrProfiles;
    }
    if (serviceType === ServiceTypes.EXTENSION_MOBILITY) {
      serviceInstances = sortBy(global360View.extensionMobilities, 'name');
    }
    if (this.serviceTypeMetadataService.getMetadataFor(serviceType).isPhone) {
      serviceInstances = sortBy(this._findPhonesForServiceType(serviceType, global360View), 'name');
    }
    // this is special - since there is no "im&p" instance, we return the end users.
    if (serviceType === ServiceTypes.IM_PRESENCE) {
      serviceInstances = global360View.endUsers
        .filter((endUser) => endUser.imPresenceEnabled)
        .map((endUser) => endUser.ref);
    }

    if (!serviceInstances) {
      throw new Error('unhandled:' + serviceType);
    }
    return serviceInstances.filter((ref) => serverIds.includes(ref.serverId));
  }

  private _findPhonesForServiceType(serviceType: ServiceTypes, global360View: Global360View): PhoneRef[] {
    if (serviceType === ServiceTypes.DESKPHONE) {
      return this._findDeskphones(global360View);
    }
    const model = this.serviceTypeMetadataService.getMetadataFor(serviceType).model;
    if (!model) {
      throw new Error(`expected serviceType to have a phone model: ${serviceType}`);
    }
    return filter(global360View.phones, { model });
  }

  private _findDeskphones(global360View: Global360View): PhoneRef[] {
    return filter(global360View.phones, (phone: PhoneRef) => {
      return this.serviceTypeDeterminationService.isModelOfType('deskphone', phone.model);
    });
  }

  getCurrentEnduser(global360View: Global360View, serverId: number) {
    return global360View.endUsers.find((endUser: EndUserResult) => {
      return endUser.ref.serverId === serverId;
    });
  }

  getCurrentUccxAgent(global360View: Global360View, serverId: number) {
    return global360View.uccxAgents.find((uccxAgent: UccxAgentRef) => {
      return uccxAgent.serverId === serverId;
    });
  }

  setImPresenceEnabled(state: Global360View, serverId: number, imPresenceEnabled: boolean) {
    const endUsersUpdated = state.endUsers.map((endUser: EndUserResult) => {
      if (endUser.ref.serverId === serverId) {
        return {
          ...endUser,
          imPresenceEnabled: imPresenceEnabled,
        };
      }

      return endUser;
    });
    this._stateSource.next({
      ...state,
      endUsers: endUsersUpdated,
    });
  }

  getGlobal360View(username: string): Observable<Global360View> {
    return this.global360ViewResource.get(username);
  }
}
