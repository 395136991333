import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { EmailSettings, Role, TestEmailSettings } from '../models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { AuthenticationContext } from './authentication.context';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EmailSettingsContext {
  _stateSource = new ReplaySubject<EmailSettings>(1);
  state$ = this._stateSource.asObservable();

  constructor(private http: HttpClient, private authenticationContext: AuthenticationContext) {
    this.authenticationContext.state$.pipe(take(1)).subscribe((currentUser) => {
      const isAdmin = this.authenticationContext.userIsAtLeast(currentUser, Role.S8_ADMIN);
      if (isAdmin) {
        this._init();
      }
    });
  }

  put(settings: EmailSettings): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      this.http.put<void>('/services/system/admin/e-mail/settings', settings).subscribe(() => {
        this._stateSource.next(settings);
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  test(settings: TestEmailSettings): Observable<any> {
    return this.http.post<any>('/services/system/admin/e-mail/settings/test', settings);
  }

  delete(): Observable<void> {
    return this.http.delete<void>('/services/system/admin/e-mail/settings');
  }

  private _init() {
    this._get().subscribe((data: EmailSettings) => {
      this._stateSource.next(data);
    });
  }

  private _get(): Observable<EmailSettings> {
    return this.http.get<EmailSettings>('/services/system/admin/e-mail/settings');
  }
}
