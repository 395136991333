import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DatabaseImportRequest } from '../../shared/models/generated/smacsModels';

export interface DatabaseImportResponse {
  ok: boolean;
  statusCode: boolean;
  statusMessage: number;
  genericErrorMessage: string;
}

@Injectable()
abstract class ImportAbstractResource {
  baseUrl: string;

  constructor(protected http: HttpClient) {}

  post(request: DatabaseImportRequest | File) {
    let requestBody;

    if (request instanceof File) {
      requestBody = new FormData();
      requestBody.append('upload', request, request.name);
      return this.http.post(this.baseUrl + '/file', requestBody) as Observable<DatabaseImportResponse>;
    } else {
      requestBody = request;
      return this.http.post(this.baseUrl, requestBody) as Observable<DatabaseImportResponse>;
    }
  }
}

@Injectable()
export class ConfigurationDatabaseResource extends ImportAbstractResource {
  baseUrl = '/services/system/admin/config-db';

  constructor(protected http: HttpClient) {
    super(http);
  }
}

@Injectable()
export class AuditDatabaseResource extends ImportAbstractResource {
  baseUrl = '/services/system/admin/audit-db';

  constructor(protected http: HttpClient) {
    super(http);
  }
}

@Injectable()
export class CallRecordsDatabaseResource extends ImportAbstractResource {
  baseUrl = '/services/cisco/reporting/cdr-db';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
