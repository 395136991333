import { Component, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import {
  SmacsFieldComponentConfig,
  SmacsFieldConfig,
  SmacsFormsUpdate,
  SmacsFormsValidationState,
} from '../../forms/smacs-forms-models';
import { SmacsFieldAbstractDirective } from '../../forms/smacs-field-abstract.directive';
import { isNil } from 'lodash';
import {
  SmacsNavsetDropdownItemConfig,
  SmacsNavsetFormComponent,
  SmacsNavsetFormConfig,
  SmacsNavsetItemNavItem,
} from './smacs-navset-form/smacs-navset-form.component';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { Subscription } from 'rxjs';
import { SmacsNavsetItemNavConfig } from './smacs-navset-form/smacs-navset-form-nav/smacs-navset-form-nav.component';

export interface SmacsNavsetItemField extends SmacsFieldConfig<SmacsFieldComponentConfig> {
  title?: string;
}

export interface SmacsNavsetItemResults {
  [key: string]: any[];
}

type NavsetAction = 'added' | 'removed' | 'update' | 'none';

export interface SmacsNavsetItemConfig {
  [key: string]: {
    label: string;
    fields: SmacsNavsetItemField[];
    badgeFieldId?: string;
    labelFieldId?: string;
    title?: string;
    defaultValue?: any;
  };
}

export class SmacsNavsetConfig extends SmacsFieldComponentConfig {
  constructor(
    public config: {
      dropdownLabel: string;
      dropdownTooltip: string;
      emptyMessage: string;
      navsetItemsConfig: SmacsNavsetItemConfig;
      allowDuplicates: boolean;
      isRefreshChild?: boolean;
      isOneClickAddDropdown?: boolean;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-navset',
  templateUrl: './smacs-navset.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsNavsetComponent }],
})
export class SmacsNavsetComponent
  extends SmacsFieldAbstractDirective<SmacsNavsetItemResults, SmacsNavsetItemResults, SmacsNavsetConfig>
  implements OnDestroy
{
  @Output() itemWasRemoved = new EventEmitter();
  @Output() itemWasAdded = new EventEmitter();
  @ViewChild(SmacsNavsetFormComponent) form: SmacsNavsetFormComponent;
  dropdownLabel = '';
  tooltipMessage = '';
  emptyTemplateMessage = '';
  navsetItemsConfig: SmacsNavsetItemConfig;
  allowDuplicate = false;
  dropdownItems: SmacsNavsetDropdownItemConfig[] = [];
  isReady = false;
  allowedDropdownItems: string[] = [];
  lastActionTaken: NavsetAction = 'none';
  isRefreshChild = false;
  isOneClickAddDropdown = false;

  private _subscriptions = new Subscription();

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  applyComponentConfig = ({ config }: SmacsNavsetConfig) => {
    this.isReady = false;
    this.dropdownLabel = config.dropdownLabel || this.dropdownLabel;
    this.tooltipMessage = config.dropdownTooltip || this.tooltipMessage;
    this.emptyTemplateMessage = config.emptyMessage || this.emptyTemplateMessage;
    this.navsetItemsConfig = config.navsetItemsConfig;
    this.dropdownItems = this._setDropdownLabels(config.navsetItemsConfig);
    this.allowDuplicate = isNil(config.allowDuplicates) ? this.allowDuplicate : config.allowDuplicates;
    this.isOneClickAddDropdown = config.isOneClickAddDropdown || this.isOneClickAddDropdown;
    // The following two subscriptions were needed for both upstream entity and the add/remove emitters to be accurate
    // Otherwise the entity passed along to SmacsNavsetFormComponent and then SmacsNavsetItemNavConfig risks to be inaccurate
    // and for the add/remove emitters to be emitted
    const entityUpdate = this.entity$.subscribe((e) => {
      if (Object.keys(e).length) {
        this.entity = e;
      }
    });

    // The following code is added to make sure that we're emitting after updating the parent
    const selfUpdate = this.selfUpdate$.subscribe((e) => {
      if (this.lastActionTaken === 'removed') {
        this.updateParent();
        this.itemWasRemoved.emit();
      } else if (this.lastActionTaken === 'added') {
        this.updateParent();
        this.itemWasAdded.emit();
      }
      this.lastActionTaken = 'none';
    });

    this._subscriptions.add(entityUpdate);
    this._subscriptions.add(selfUpdate);

    // If we're reapplying the component config (in this case, SmacsNavsetFormComponent would have been initialized)
    // make sure to disable the animations so that all the items aren't animating at the same time.
    if (this.form) {
      this.form.formNav.disableAnimation = true;
    }

    // This component is only valid if SmacsNavsetFormComponent is valid
    this.config.validation = [
      {
        validator: () => {
          if (this.form) {
            return this.form.validationState;
          }
          return SmacsFormsValidationState.VALID;
        },
      },
    ];

    if (config.isRefreshChild) {
      this.form?.formNav.applyComponentConfig(
        new SmacsNavsetItemNavConfig({
          dropdownLabel: this.dropdownLabel,
          dropdownTooltip: this.tooltipMessage,
          items: this.navsetItemsConfig as any,
          dropdownItems: this.dropdownItems,
          allowDuplicates: this.allowDuplicate,
          emptyTemplateMessage: this.emptyTemplateMessage,
        })
      );
    }

    this.isReady = true;
  };

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  // Converts the itemConfiguration into an array of objects for the dropdown used in SmacsNavsetFormNav
  private _setDropdownLabels = (itemConfig: SmacsNavsetItemConfig) => {
    const keys = Object.keys(itemConfig);
    const objArrForDropdown = keys.map((key: string) => ({ label: itemConfig[key].label, id: key }));

    return keys.map((key: string) => ({ label: itemConfig[key].label, id: key }));
  };

  // Convert the NavsetConfiguration before updating
  private _convertItemNavToItemResults = (navsetFormData: SmacsNavsetFormConfig): SmacsNavsetItemResults => {
    const data: SmacsNavsetItemResults = {};
    navsetFormData.itemNav.forEach((item: SmacsNavsetItemNavItem) => {
      data[item.id] = data[item.id] || [];
      data[item.id].push(item.fields);
    });
    return data;
  };

  // Excludes dropdown items whose id is not included in the string array.
  changeAllowedDropdownItems(dropdownItemNames: string[]) {
    this.allowedDropdownItems = dropdownItemNames;
  }

  handleUpdate($event: SmacsFormsUpdate<SmacsNavsetFormConfig>) {
    // With the nested forms, items and dynamic forms, occasionally there is an update sent here without any data during initialization
    // To prevent possibly overriding an existing entity, this value checks for this scenario.
    // Additionally, with the nested SmacsForms, there is the possibly we will emit an empty update, which will cause
    // the parent to think there was a change. To prevent situations where we might detect an unnecessary change, the
    // second and third conditions to isThisAFalsePositive was added.
    const isThisAFalsePositive =
      (this.entity && !$event.old) ||
      ($event?.old?.itemNav.length === 0 && $event?.new?.itemNav.length === 0) ||
      (!$event.old && $event?.new?.itemNav.length === 0);

    if ($event.new && !isThisAFalsePositive) {
      const entity = this.entity || {};
      const newData = this._convertItemNavToItemResults($event.new);
      const oldKeys = Object.keys(entity);
      const newKeys = Object.keys(newData);

      const isThereAnyDifference =
        oldKeys.length !== newKeys.length ||
        this.validationState !== $event.valid ||
        newKeys.some((key) => {
          return (
            newData[key].length !== entity[key].length ||
            newData[key].some((newKeyData, index) => {
              const newKeyDataKeys = Object.keys(newKeyData);
              const oldKeyData = entity[key][index] || {};
              return newKeyDataKeys.some((newKeyDataKey: string) => {
                return newKeyData[newKeyDataKey] !== oldKeyData[newKeyDataKey];
              });
            })
          );
        });

      // In order to avoid any unnecessary updateParent calls, this code block is used to attempt to discern if there
      // are differences between the existing data and the update.
      if (isThereAnyDifference) {
        const didWeAddAnItem =
          newKeys.length > oldKeys.length ||
          Object.entries(newData).some((value) => {
            const id = value[0];
            const entries = value[1];
            const entry = this.entity[id] || [];
            return entries.length > entry.length;
          });
        const didWeRemoveAnItem =
          newKeys.length < oldKeys.length ||
          Object.entries(newData).some((value) => {
            const id = value[0];
            const entries = value[1];
            const entry = this.entity[id] || [];
            return entries.length < entry.length;
          });
        this.validationState = $event.valid;
        this.updateSelf(newData);
        this.updateParent();
        if (didWeAddAnItem) {
          this.lastActionTaken = 'added';
        } else if (didWeRemoveAnItem) {
          this.lastActionTaken = 'removed';
        } else {
          this.lastActionTaken = 'update';
        }
      }
    }
  }
}
