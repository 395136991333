import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HealthStatus, Server } from '../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { delayWhen, map, shareReplay } from 'rxjs/operators';
import { ModelContextAbstract } from '../../shared/contexts/abstract/model.context.abstract';

@Injectable()
export class ServersContext extends ModelContextAbstract<Server> {
  _baseUrl = '/services/cisco/admin/servers';

  _getAll: () => Observable<Server[]>;
  _put: (id: number, body: Server) => Observable<Server>;
  _post: (body: Server) => Observable<Server>;
  _delete: (id: number) => Observable<void>;

  _stateSource = new ReplaySubject<Server[]>(1); // Public so stages can update the state
  state$ = this._stateSource.asObservable();

  constructor(protected $http: HttpClient) {
    super($http);

    this.getAll().subscribe();
  }

  getAll(): Observable<void> {
    return this._getAll().pipe(map((servers) => this._stateSource.next(servers)));
  }

  post(server: Server): Observable<Server> {
    return this._post(server).pipe(delayWhen(() => this.getAll()));
  }

  put(server: Server): Observable<Server> {
    return this._put(server.id, server).pipe(delayWhen(() => this.getAll()));
  }

  delete(serverId: number): Observable<void> {
    return this._delete(serverId).pipe(delayWhen(() => this.getAll()));
  }

  testConnection(server: Server): Observable<HealthStatus> {
    return this.$http.post<HealthStatus>(`${this._baseUrl}/connection`, server).pipe(shareReplay());
  }
}
