import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthenticationContext } from '../../../shared/contexts/authentication.context';
import { CallLogQueryEditorData } from '../../../shared/expression-editor/call-logs-query-editor.component';
import { CallLogsQueryParams, CallLogsResource } from '../resources/call-logs.resource';
import { CallLog, CurrentUser } from '../../../shared/models/generated/smacsModels';
import { DatatableColumn, DatatableComponent, DatatableRow } from '../../datatable/datatable.component';
import { SecondsToHumanReadablePipe } from '../services/seconds-to-human-readable.pipe';
import moment from 'moment';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { ButtonStyles } from '../../../button/button.component';
import { Subscription } from 'rxjs';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../shared/services/toast.service';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';
import { DatatableFilterService } from '../../datatable/datatable-filter.service';

interface CallLogsRow extends DatatableRow {
  originator?: string;
  destination?: string;
  duration?: string;
  startDate?: string;
  endCause?: string;
}

const MAX_CALLER_COLUMN_WIDTH = 40;
const APPROXIMATE_ICON_CHARACTER_WIDTH = 4;

@Component({
  selector: 'smacs-call-logs',
  templateUrl: './call-logs.component.html',
  styleUrls: ['./call-logs.component.scss', '../../reporting.scss'],
})
export class CallLogsComponent implements OnInit, OnDestroy {
  callLogsQueryEditorData: CallLogQueryEditorData;
  callLogs: CallLog[] = [];
  data: CallLogsRow[] = [{}];
  query: CallLogsQueryParams;
  isReady = false;
  endCauses: string[] = [];
  tableDataAutomation = 'call-logs-datatable';
  private _subscriptions = new Subscription();
  @ViewChild(DatatableComponent) datatable: DatatableComponent<CallLogsRow>;

  constructor(
    private authenticationContext: AuthenticationContext,
    private callLogsResource: CallLogsResource,
    private secondsToHumanReadablePipe: SecondsToHumanReadablePipe,
    private breadcrumbsService: BreadcrumbsService,
    private bottomNavService: BottomNavService,
    private toastService: ToastService,
    private telephoneNumberFilter: TelephoneNumberFilter,
    private datatableFilterService: DatatableFilterService
  ) {}

  columns = [
    {
      name: 'originator',
      label: 'tkey;reporting.call_logs.datatable.columns.originator',
      columnCharacterWidth: MAX_CALLER_COLUMN_WIDTH,
      sortFn: (a: string, b: string) => {
        return a ? a.localeCompare(b) : -1;
      },
    },
    {
      name: 'destination',
      label: 'tkey;reporting.call_logs.datatable.columns.destination',
      columnCharacterWidth: MAX_CALLER_COLUMN_WIDTH,
      sortFn: (a: string, b: string) => {
        return a ? a.localeCompare(b) : -1;
      },
    },
    {
      name: 'startDate',
      label: 'tkey;reporting.call_logs.datatable.columns.startDate',
      columnCharacterWidth: 25,
      sortFn: (a: string, b: string) => {
        if (a === b) {
          return 0;
        } else if (!a) {
          return -1;
        } else if (!b) {
          return 1;
        } else {
          return moment(b).diff(a);
        }
      },
      ignoreFilter: true,
    },
    {
      name: 'duration',
      label: 'tkey;reporting.call_logs.datatable.columns.duration',
      columnCharacterWidth: 10,
      sortFn: (a: string, b: string) => {
        const durationA = Number(a);
        const durationB = Number(b);
        if (durationA === durationB) {
          return 0;
        } else if (!a || durationB > durationA) {
          return -1;
        } else if (!b || durationA > durationB) {
          return 1;
        }
      },
      pipe: this.secondsToHumanReadablePipe,
      ignoreFilter: true,
    },
    {
      name: 'endCause',
      label: 'tkey;reporting.call_logs.datatable.columns.endCause',
      columnCharacterWidth: 48,
      sortFn: (a: string, b: string) => {
        return a ? a.localeCompare(b) : -1;
      },
      ignoreFilter: true,
    },
  ] as DatatableColumn<CallLogsRow>[];

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;reporting.call_logs.title' }]);
    const authenticationObservable = this.authenticationContext.state$.subscribe((user: CurrentUser) => {
      if (!this.isReady) {
        this.isReady = true;
        const to = new Date();
        const from = new Date();
        from.setDate(from.getDate() - 7);
        this.callLogsQueryEditorData = {
          callPlacedOrReceived: 'BOTH',
          extensionUsernameOrDeviceName: {
            selection: 'username',
            value: user.userId,
          },
          from,
          to,
        };
        this._initialBottomNav();
      }
    });
    this._subscriptions.add(authenticationObservable);
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }

  handleQueryUpdate($event: CallLogQueryEditorData) {
    if (this.datatable) {
      this.datatable.clearFilters();
    }
    this.callLogsQueryEditorData = $event;
    const query: CallLogsQueryParams = {
      direction: this.callLogsQueryEditorData.callPlacedOrReceived,
      startDate: this._getDateInProperFormat(new Date($event.from.toUTCString())),
      endDate: this._getDateInProperFormat(new Date($event.to.toUTCString())),
    };

    if (this.callLogsQueryEditorData.extensionUsernameOrDeviceName.selection === 'extension') {
      query.number = this.callLogsQueryEditorData.extensionUsernameOrDeviceName.value;
    } else if (this.callLogsQueryEditorData.extensionUsernameOrDeviceName.selection === 'username') {
      query.username = this.callLogsQueryEditorData.extensionUsernameOrDeviceName.value;
    } else if (this.callLogsQueryEditorData.extensionUsernameOrDeviceName.selection === 'device') {
      query.deviceName = this.callLogsQueryEditorData.extensionUsernameOrDeviceName.value;
    }

    this.query = query;
    this._forceDatatableLoading(true);

    this.callLogsResource.get(query).subscribe((callLogs: CallLog[]) => {
      this.callLogs = callLogs;
      this.endCauses = Array.from(new Set(callLogs.map(this._getCallEndCause)));
      this._setExportDisabled(!this.callLogs.length);
      this._forceDatatableLoading(false);
      this.data = callLogs.map((callLog: CallLog) => {
        const endCause = this._getCallEndCause(callLog);
        const origIcon = this._getIcon(callLog.origDeviceOwner, callLog.origDeviceName);
        const destIcon = this._getIcon(callLog.destDeviceOwner, callLog.destDeviceName);
        callLog.origDeviceOwner = this._getName(callLog.origDeviceOwner, callLog.origDeviceName);
        callLog.destDeviceOwner = this._getName(callLog.destDeviceOwner, callLog.destDeviceName);
        const callLogDate = new Date(callLog.dateTimeOrigination);
        const dateOptions = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hourCycle: 'h23',
        } as Intl.DateTimeFormatOptions;
        const startDate = Intl.DateTimeFormat('default', dateOptions).format(callLogDate).replace(',', '');
        return {
          originator: `<div class="caller-row">
                          <span class="caller-row__icon">
                            <i class="${origIcon}"></i>
                          </span>
                          <div class="caller-row__content">
                            ${this._getOwnerTag(callLog.origDeviceOwner)}
                            ${this._getDeviceTag(callLog.origDeviceName, callLog.callingPartyNumber)}
                           </div>
                          </div>`,
          destination: `<div class="caller-row">
                          <span class="caller-row__icon">
                            <i class="${destIcon}"></i>
                          </span>
                          <div class="caller-row__content">
                             ${this._getOwnerTag(callLog.destDeviceOwner)}
                             ${this._getDeviceTag(callLog.destDeviceName, callLog.originalCalledPartyNumber)}
                           </div>
                          </div>`,
          duration: callLog.durationInSeconds.toString(),
          startDate,
          endCause,
          hardcodedTooltips: {
            originator: `<p>${callLog.origDeviceOwner}</p><p class="mb-0">${
              callLog.origDeviceName
            }</p><p class="mb-0"><em>(via ${this._getNumberInE164Format(callLog.callingPartyNumber)})</em></p>`,
            destination: `<p>${callLog.destDeviceOwner}</p><p class="mb-0">${
              callLog.destDeviceName
            }</p><p class="mb-0"><em>(via ${this._getNumberInE164Format(callLog.originalCalledPartyNumber)})</em></p>`,
          },
          hardcodedDisableTooltip: {
            originator:
              this._getLongestEntryLength(
                callLog.origDeviceOwner,
                `${callLog.origDeviceName} (via ${this._getNumberInE164Format(callLog.callingPartyNumber)})`
              ) <
              MAX_CALLER_COLUMN_WIDTH - APPROXIMATE_ICON_CHARACTER_WIDTH,
            destination:
              this._getLongestEntryLength(
                callLog.destDeviceOwner,
                `${callLog.destDeviceName} (via ${this._getNumberInE164Format(callLog.originalCalledPartyNumber)})`
              ) <
              MAX_CALLER_COLUMN_WIDTH - APPROXIMATE_ICON_CHARACTER_WIDTH,
          },
        };
      });
    });
  }

  doesRowCatchEndCauseFilter = (filter: string[], row: CallLogsRow): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.endCause);

  private _forceDatatableLoading(isLoading: boolean) {
    if (this.datatable) {
      this.datatable.isViewLoading = isLoading;
    }
  }

  private _getOwnerTag = (deviceOwner: string): string => {
    return `<p class="caller-row__content__text">${deviceOwner}</p>`;
  };

  private _getDeviceTag = (deviceName: string, number: string): string => {
    return `<p class="caller-row__content__text caller-row__content__text--faded">
              <span>${deviceName}</span>
              <em>(via ${this._getNumberInE164Format(number)})</em></p>`;
  };

  private _getLongestEntryLength = (deviceOwner: string, deviceName: string): number => {
    if (deviceOwner.length > deviceName.length) {
      return deviceOwner.length;
    }
    return deviceName.length;
  };

  private _getCallEndCause = (callLog: CallLog): string => {
    if (callLog?.destCause.code === '16') {
      return `${callLog.destDeviceOwner || 'Destination'} hung up`;
    } else if (callLog?.origCause?.code === '16') {
      return `${callLog.origDeviceOwner || 'Originator'} hung up`;
    }

    return callLog.destCause?.description || '';
  };

  private _getIcon = (deviceOwner: string, deviceName: string): string => {
    const phonePrefixes = ['SEP', 'CSF', 'TCT', 'BOT', 'TAB'];
    if (!deviceOwner && phonePrefixes.includes(deviceName.slice(0, 3))) {
      return SmacsIcons.DESKPHONE;
    }
    return SmacsIcons.USER;
  };

  private _getName = (deviceOwner: string, deviceName: string): string => {
    const phonePrefixes = ['SEP', 'CSF', 'TCT', 'BOT', 'TAB'];
    if (!deviceOwner) {
      return phonePrefixes.includes(deviceName.slice(0, 3)) ? 'Public Phone' : 'External Contact';
    }
    return deviceOwner;
  };

  private _getDateInProperFormat = (date: Date): string => {
    const month = date.getMonth() + 1;
    const day = date.getDate();
    return `${date.getFullYear()}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  };

  private _getNumberInE164Format = (number: string): string => {
    return this.telephoneNumberFilter.transform(number);
  };

  private _initialBottomNav() {
    const bottomNavButtons: BottomNavButton[] = [];

    bottomNavButtons.push({
      id: 'export-call-logs-button',
      dataAutomation: 'call-logs-bottom-nav',
      label: 'tkey;reporting.call_logs.button',
      icon: SmacsIcons.EXPORT,
      buttonClass: ButtonStyles.PRIMARY,
      state: {
        buttonDisableState: {
          disabled: false,
          tooltipKey: '',
        },
        tooltipVisible: true,
      },
      cb: this.exportCallLogs,
    });

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private exportCallLogs = () => {
    this._setExportPending(true);
    this.callLogsResource.getFile(this.query).subscribe(() => {
      this._setExportPending(false);
      this.toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.EXPORT,
        'tkey;reporting.xlsx_export.downloaded_toast.title',
        'tkey;reporting.xlsx_export.downloaded_toast.message'
      );
    });
  };

  private _setExportDisabled(disabled: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'export-call-logs-button',
        state: {
          pending: false,
          buttonDisableState: { disabled, tooltipKey: 'tkey;reporting.call_logs.button.tooltip' },
        },
      })
    );
  }

  private _setExportPending(isPending: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'export-call-logs-button',
        state: {
          pending: isPending,
          buttonDisableState: { disabled: isPending, tooltipKey: '' },
          tooltipVisible: true,
        },
      })
    );
  }
}
