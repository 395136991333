import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { MicrosoftDialPlanGroup, Type, UserGroupPermissions } from '../../../shared/models/generated/smacsModels';
import { UiUserPermissionTypes, UserPermissionsContext } from '../../contexts/user-permissions.context';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { UserPermissionsModalComponent } from './user-permissions-modal.component';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../shared/entity-table/entity-table.models';
import { UserPermissionsRolePipe } from './user-permissions-role.pipe';
import { MsDialPlanGroupsContext } from '../../contexts/ms-dial-plan-groups.context';
import { intersection, isEmpty } from 'lodash';

@Component({
  selector: 'ziro-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [UserPermissionsRolePipe],
})
export class UserPermissionsComponent implements OnInit, OnDestroy {
  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  isLoading = true;
  roles = [UiUserPermissionTypes.ADMINISTRATOR, UiUserPermissionTypes.HELPDESK] as string[];
  userPermissions: UserGroupPermissions[] = [];
  filteredUserPermissions: UserGroupPermissions[] = [];

  private _dialPlanGroups: MicrosoftDialPlanGroup[] = [];
  private _subscriptions = new Subscription();

  constructor(
    private _breadcrumbsService: BreadcrumbsService,
    private _microsoftUserPermissionsContext: UserPermissionsContext,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _userPermissionsRolePipe: UserPermissionsRolePipe,
    private _msDialPlanGroupsContext: MsDialPlanGroupsContext
  ) {}

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.system.user_permissions.title' }]);

    const subscription = combineLatest([
      this._microsoftUserPermissionsContext.state$,
      this._msDialPlanGroupsContext.state$,
    ]).subscribe(([userGroupPermissions, microsoftDialPlanGroup]) => {
      this.userPermissions = userGroupPermissions;
      this.filteredUserPermissions = this.userPermissions;
      this._dialPlanGroups = microsoftDialPlanGroup;
      this._createTable();
      this._generateTableRows();
      this.isLoading = false;
    });
    this._subscriptions.add(subscription);
  }

  ngOnDestroy() {
    this._breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }

  onEditClicked(permission: UserGroupPermissions) {
    const title =
      permission.type === Type.GROUP
        ? this._translateService.instant('tkey;admin.system.user_permissions.modify_permissions_group.label', {
            upn: permission.name,
          })
        : this._translateService.instant('tkey;admin.system.user_permissions.modify_permissions_user.label', {
            upn: permission.name,
          });

    const options = {
      size: 'lg',
      bodyClass: UserPermissionsModalComponent,
      modalViewProperties: {
        title: title,
        userPermission: permission,
        dialPlanGroups: this._dialPlanGroups,
        modalBodyClass: 'overflow-visible',
      },
    };
    this._smacsModalService.openDetailedModal(() => options.modalViewProperties, options);
  }

  onDeleteClicked(userPermission: UserGroupPermissions) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        promptBody: this._translateService.instant('tkey;admin.system.user_permissions.delete.modal.message', {
          upn: userPermission.name,
        }),
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteGroup(userPermission);
            },
          },
        ],
      },
    };
    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteGroup(userPermission: UserGroupPermissions): Observable<void> {
    return this._microsoftUserPermissionsContext.delete(userPermission);
  }

  private _createTable() {
    this.table = {
      columns: [
        {
          columnId: 'type',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;admin.system.user_permissions.type.label',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: ['Group', 'User'],
            filterFn: (rowsToFilter: EntityTableContentRow[], filterValue: string) => {
              return rowsToFilter.filter(
                (row: EntityTableContentRow) => filterValue.toLowerCase() === row.content['type'].toLowerCase()
              );
            },
          },
        },
        {
          columnId: 'userPrincipalName',
          cssColumnSize: 'col-sm-4',
          label: 'tkey;admin.system.user_permissions.user_principal_name.label',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'role',
          cssColumnSize: 'col-sm-3',
          label: 'tkey;admin.system.user_permissions.roles.label',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: this.roles,
          },
        },
        {
          columnId: 'permittedDialPlanGroups',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;admin.system.user_permissions.permitted_dialplan_groups.label',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: this._dialPlanGroups.map((group: MicrosoftDialPlanGroup) => {
              return group.name;
            }),
            isMultiSelect: true,
            filterFn: (rowsToFilter: EntityTableContentRow[], filterValues: string[]) => {
              if (isEmpty(filterValues)) {
                return rowsToFilter;
              }

              return rowsToFilter.filter((row: EntityTableContentRow) => {
                const permittedDialPlanGroups: string[] = row.content['permittedDialPlanGroups'];
                if (intersection(permittedDialPlanGroups, filterValues).length > 0) {
                  return row;
                }
              });
            },
          },
        },
      ],
      resultsMessage: 'tkey;admin.system.user_permissions.table.no_results.message',
      hasActions: true,
    };
  }

  private _generateTableRows() {
    this.tableRows = this.filteredUserPermissions.map((permission: UserGroupPermissions) => {
      return {
        content: {
          type: permission.type,
          userPrincipalName: permission.name,
          role: this._userPermissionsRolePipe.transform(permission.role),
          permittedDialPlanGroups: permission.permittedDialPlanGroups,
        },
        html: {
          type: `<span class="text-capitalize">${permission.type.toLowerCase()}</span>`,
        },
        badges: {
          permittedDialPlanGroups: {
            maxLength: 30,
            values: permission.permittedDialPlanGroups,
          },
        },
        actions: [
          {
            buttonStyle: ButtonStyles.PRIMARY,
            dataAutomation: 'user-permissions-row-edit',
            icon: SmacsIcons.EDIT,
            onClick: () => this.onEditClicked(permission),
          },
          {
            buttonStyle: ButtonStyles.DANGER,
            dataAutomation: 'user-permissions-row-delete',
            icon: SmacsIcons.DELETE,
            onClick: () => this.onDeleteClicked(permission),
          },
        ],
      };
    });
  }
}
