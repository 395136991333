import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Microsoft365UserLicenses } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class Microsoft365LicensesResource {
  constructor(protected http: HttpClient) {}

  put(microsoft365UserLicenses: Microsoft365UserLicenses): Observable<void> {
    return this.http.put<void>(
      `/services/microsoft/macs/365-licenses/${microsoft365UserLicenses.userPrincipalName}`,
      microsoft365UserLicenses
    );
  }
}
