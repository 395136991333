import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { ButtonModule } from '../button/button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AuditLogsComponent } from './audit/audit-logs/audit-logs.component';
import { SmacsAuditTypeTagPipe } from './audit/audit-logs/audit-type-tag.pipe';
import { AuditViewJsonModalComponent } from './audit/audit-logs/audit-json-view-modal.component';
import { CalendarModule } from 'primeng/calendar';
import { CiscoGeneralSettingsComponent } from './system/cisco-general-settings/cisco-general-settings.component';
import { GeneralSettingsCheckboxComponent } from './fields/general-settings/checkbox/general-settings-checkbox.component';
import { GeneralSettingsTextComponent } from './fields/general-settings/text/general-settings-text.component';
import { AdminComponent } from './admin.component';
import { AuditErrorViewModalComponent } from './audit/audit-logs/audit-error-view-modal.component';
import { SiteBasedHelpdeskUserPermissionsManagementComponent } from './security-and-permissions/site-based-helpdesk-user-permissions-management/site-based-helpdesk-user-permissions-management.component';
import { CustomizeUiComponent } from './system/customize-ui/customize-ui.component';
import { ImportConfigurationComponent } from './disaster-recovery/import-configuration/import-configuration.component';
import { AdminLicenseComponent } from './system/license/admin-license.component';
import { ImportAuditsComponent } from './disaster-recovery/import-audits/import-audits.component';
import { AdminEditPermissionsModalComponent } from './security-and-permissions/site-based-helpdesk-user-permissions-management/edit-permissions-modal.component';
import { BackupsComponent } from './disaster-recovery/backups/backups.component';
import { DialPlanExceptionManagementEditModule } from './cisco/dial-plan-exception-management/dial-plan-exception-management-edit/dial-plan-exception-management-edit.module';
import { DialPlanExceptionManagementComponent } from './cisco/dial-plan-exception-management/dial-plan-exception-management.component';
import { CertificateManagementComponent } from './system/certificate-management/certificate-management.component';
import { ImportCertificateComponent } from './system/certificate-management/import-certificate/import-certificate.component';
import { GenerateCsrComponent } from './system/certificate-management/generate-csr/generate-csr.component';
import { GenerateCsrFormComponent } from './system/certificate-management/generate-csr/generate-csr-form.component';
import { DefaultPhoneButtonTemplatesComponent } from './cisco/default-phone-button-templates/default-phone-button-templates.component';
import { AdminServersComponent } from './cisco/server-management/servers.component';
import { AdminAddEditServerModalComponent } from './cisco/server-management/add-edit-server-modal.component';
import { SmacsFormsModule } from '../forms/smacs-forms.module';
import { GraphManagementComponent } from './microsoft/graph-management/graph-management.component';
import { AdminLdapManagementComponent } from './system/ldap-management/admin-ldap-management.component';
import { AdminLdapManagementSettingsLdapServerUrlComponent } from './system/ldap-management/settings/ldap-server-url/admin-ldap-management-settings-ldap-server-url.component';
import { AdminLdapManagementSettingsLdapServerUrlFormComponent } from './system/ldap-management/settings/ldap-server-url/admin-ldap-management-settings-ldap-server-url-form.component';
import { AdminLdapManagementSettingsComponent } from './system/ldap-management/settings/admin-ldap-management-settings.component';
import { AdminLdapManagementPermissionsComponent } from './system/ldap-management/permissions/admin-ldap-management-permissions.component';
import { SamlComponent } from './security-and-permissions/saml/saml.component';
import { SamlExportButtonComponent } from './security-and-permissions/saml/saml-export-button/saml-export-button.component';
import { SamlImportButtonComponent } from './security-and-permissions/saml/saml-import-button/saml-import-button.component';
import { CdrDumpFileImportComponent } from './cisco/cdr-dump-file-import/cdr-dump-file-import.component';
import { ApplicationLogsComponent } from './system/application-logs/application-logs.component';
import { CustomizeUiPreviewModalComponent } from './system/customize-ui/customize-ui-preview-modal.component';
import { ServersContext } from './contexts/servers.context';
import { ApplicationLogsResource } from './resources/application-logs-resource.resource';
import { CertificateResource } from './resources/certificate.resource';
import { DefaultPhoneButtonTemplateResource } from './resources/default-phone-button-template.resource';
import { DialPlanExceptionResource } from './resources/dial-plan-exception.resource';
import { SiteBasedHelpdeskManagementResource } from './resources/site-based-helpdesk-management-resource.service';
import {
  AuditDatabaseResource,
  CallRecordsDatabaseResource,
  ConfigurationDatabaseResource,
} from './resources/import.abstract.resource';
import { MsTeamsCachedOptionsResource } from './resources/ms-teams-cached-options.resource';
import { SamlSsoConfigContext } from './contexts/saml-sso-config.context';
import { BulkEditPermissionsModalComponent } from './security-and-permissions/site-based-helpdesk-user-permissions-management/bulk-edit-permissions-modal/bulk-edit-permissions-modal.component';
import { AdminEmailConfigurationComponent } from './system/email-configuration/admin-email-configuration.component';
import { AdminEmailConfigurationModalComponent } from './system/email-configuration/admin-email-configuration-modal.component';
import { DeviceUtilizationReportSettingsComponent } from './cisco/device-utilization-report-settings/device-utilization-report-settings.component';
import { SystemHealthSettingsNotificationsComponent } from './system-health/system-health-settings-notifications/system-health-settings-notifications.component';
import { DialPlanManagementComponent } from './microsoft/dial-plan-management/dial-plan-management.component';
import { MsDialPlanGroupManagementComponent } from './microsoft/dial-plan-management/dial-plan-group-management/ms-dial-plan-group-management.component';
import { DialPlanGroupListComponent } from './microsoft/dial-plan-management/dial-plan-group-list/dial-plan-group-list.component';
import { MsDialPlanGroupManagementFormComponent } from './microsoft/dial-plan-management/dial-plan-group-management/ms-dial-plan-group-management-form/ms-dial-plan-group-management-form.component';
import { MsDialPlanGroupsContext } from './contexts/ms-dial-plan-groups.context';
import { HighAvailabilityManagementComponent } from './high-availability-management/high-availability-management.component';
import { LicenseModuleRowComponent } from './system/license/license-module-row/license-module-row.component';
import { CiscoDialPlanManagementListComponent } from './cisco/dial-plan-mangement/dial-plan-mangement-list/dial-plan-management-list.component';
import { CiscoDialPlanManagementEditComponent } from './cisco/dial-plan-mangement/dial-plan-mangement-edit/dial-plan-management-edit.component';
import { CiscoDialPlanManagementGroupSettingsFormComponent } from './cisco/dial-plan-mangement/dial-plan-mangement-edit/group-settings/dial-plan-management-group-settings-form.component';
import { CiscoDialPlanManagementWriteToLdapFormComponent } from './cisco/dial-plan-mangement/dial-plan-mangement-edit/write-to-ldap/dial-plan-management-write-to-ldap-form.component';
import { CiscoDialPlanManagementDidRangesFormComponent } from './cisco/dial-plan-mangement/dial-plan-mangement-edit/did-ranges/dial-plan-management-did-ranges-form.component';
import { CiscoDialPlanManagementDnRangesFormComponent } from './cisco/dial-plan-mangement/dial-plan-mangement-edit/dn-ranges/dial-plan-management-dn-ranges-form.component';
import { CiscoDialPlanManagementGroupSettingsLocationComponent } from './cisco/dial-plan-mangement/dial-plan-mangement-edit/group-settings/dial-pan-management-group-settings-location/dial-plan-management-group-settings-location.component';
import { MicrosoftDialPlanExceptionManagementListComponent } from './microsoft/dial-plan-exception-management/dial-plan-exception-management-list/dial-plan-exception-management-list.component';
import { MicrosoftDialPlanExceptionManagementEditComponent } from './microsoft/dial-plan-exception-management/dial-plan-exception-management-edit/dial-plan-exception-management-edit.component';
import { MicrosoftDialPlanExceptionManagementEditFormComponent } from './microsoft/dial-plan-exception-management/dial-plan-exception-management-edit/dial-plan-exception-management-edit-form/dial-plan-exception-management-edit-form.component';
import { MsDialPlanExceptionGroupsContext } from './contexts/ms-dial-plan-exception-groups.context';
import { UserPermissionsComponent } from './security-and-permissions/user-permissions/user-permissions.component';
import { UserPermissionsModalComponent } from './security-and-permissions/user-permissions/user-permissions-modal.component';
import { UserPermissionsContext } from './contexts/user-permissions.context';
import { UserPermissionsSearchFormComponent } from './security-and-permissions/user-permissions/user-permissions-search-form.component';
import { UserPermissionsRolePipe } from './security-and-permissions/user-permissions/user-permissions-role.pipe';
import { MsGraphConfigContext } from './contexts/ms-graph-config.context';
import { BandwidthManagementComponent } from './microsoft/ziro-support-settings/bandwidth-management/bandwidth-management.component';
import { MicrosoftLicenseManagementComponent } from './microsoft/license-management/license-management.component';
import { MicrosoftLicenseManagementFormComponent } from './microsoft/license-management/license-management-form.component';
import { CiscoGeneralSettingsResource } from './resources/cisco-general-settings.resource';
import { AuditSettingsComponent } from './audit/audit-settings/audit-settings.component';
import { AuditSettingsContext } from './contexts/audit-settings.context';
import { TrimWithEllipsisFilter } from '../shared/filters/trim-with-ellipsis.filter';
import { SortDialPlansPipe } from './cisco/dial-plan-mangement/dial-plan-mangement-list/sort-dial-plans.pipe';
import { Oauth2ResourceServerManagementComponent } from './security-and-permissions/oauth2-resource-server-settings/oauth2-resource-server-management.component';
import { Oauth2ResourceServerSettingsResource } from './resources/oauth2-resource-server-settings.resource';
import { MicrosoftGeneralSettingsComponent } from './microsoft/general-settings/microsoft-general-settings.component';
import { ImportCallRecordsComponent } from './disaster-recovery/import-call-records/import-call-records.component';
import { BandwidthCachedOptionsContext } from './contexts/bandwidth-cached-options.context';
import { MsZeroTouchProvisioningContext } from './contexts/ms-zero-touch-provisioning.context';
import { WebexAuthenticationComponent } from './cisco/webex-authentication/webex-authentication.component';
import { WebexAuthenticationFormComponent } from './cisco/webex-authentication/webex-authentication-form/webex-authentication-form.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { WebexCallingSettingsContext } from './contexts/webex-calling-settings.context';
import { WebexCallingStatusContext } from './contexts/webex-calling-status.context';
import { SecurityGroupManagementComponent } from './microsoft/security-group-management/security-group-management.component';
import { SecurityGroupManagementFormComponent } from './microsoft/security-group-management/security-group-management-form.component';
import { MicrosoftSecurityGroupSettingsResource } from '../shared/resources/microsoft-security-group-settings.resource';
import { EmailTemplatesContext } from './contexts/email-templates.context';
import { EmailTemplatesEntityTableComponent } from './cisco/email-templates/email-templates-entity-table/email-templates-entity-table.component';
import { EmailTemplateEditFormComponent } from './cisco/email-templates/email-template-edit/email-template-edit-form/email-template-edit-form.component';
import { EmailTemplateEditComponent } from './cisco/email-templates/email-template-edit/email-template-edit.component';
import { DubberAccountManagementComponent } from './microsoft/ziro-support-settings/dubber-account-management/dubber-account-management.component';
import { LoadingBlockModule } from '../shared/loading-block/loading-block.module';
import { TopNavModule } from '../shared/top-nav/top-nav.module';
import { BreadcrumbsModule } from '../shared/breadcrumbs/breadcrumbs.module';
import { EntityTableModule } from '../shared/entity-table/entity-table.module';
import { SideNavigationModule } from '../shared/side-navigation/side-navigation.module';
import { BottomNavModule } from '../shared/bottom-nav/bottom-nav.module';
import { BillingModule } from './billing/billing.module';
import { PortInOrdersComponent } from './order-numbers/port-in-orders/port-in-orders.component';
import { DatatableModule } from '../reporting/datatable/datatable.module';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { SiteManagementModule } from './cisco/site-management/site-management.module';
import { OrderViewAndEditComponent } from './order-numbers/order-view-and-edit/order-view-and-edit.component';
import { PortInDraftsUploadModalComponent } from './order-numbers/order-view-and-edit/port-in-drafts-upload-modal.component';
import { FileUploaderModule } from '../shared/file-uploader/file-uploader.module';
import { OrderViewAndEditContainerComponent } from './order-numbers/order-view-and-edit/order-view-and-edit-container.component';
import { CreateDraftComponent } from './order-numbers/create-draft/create-draft.component';
import { NumberPortabilitiesContext } from './order-numbers/check-number-portability/number-portabilities.context';
import { PortInDraftsResource } from './order-numbers/port-in-orders/port-in-drafts.resource';
import { CheckNumberPortabilityFormComponent } from './order-numbers/check-number-portability/check-number-portability-form.component';
import { CheckNumberPortabilityComponent } from './order-numbers/check-number-portability/check-number-portability.component';
import { ZiroSupportSettingsComponent } from './microsoft/ziro-support-settings/ziro-general-support-settings/ziro-support-settings.component';
import { ZiroSupportSettingsResource } from './resources/ziro-support-settings.resource';
import { OrderErrorComponent } from './order-numbers/order-view-and-edit/order-error/order-error.component';
import { OrderNoteComponent } from './order-numbers/order-view-and-edit/order-note/order-note.component';
import { PipesModule } from '../shared/pipes/pipes.module';
import { GenerateLoaModalComponent } from './order-numbers/order-view-and-edit/generate-loa-modal/generate-loa-modal.component';
import { PortInDraftSignedLoasResource } from './resources/port-in-draft-signed-loas.resource';
import { AdAttributesComponent } from './microsoft/dial-plan-management/dial-plan-group-management/ms-dial-plan-group-management-form/ad-attributes-form/ad-attributes.component';
import { AdAttributeChildFormComponent } from './microsoft/dial-plan-management/dial-plan-group-management/ms-dial-plan-group-management-form/ad-attributes-form/ad-attribute-child-form/ad-attribute-child-form.component';
import { PortInNumberStatusComponent } from './order-numbers/port-in-number-status/port-in-number-status.component';
import { GenerateKeyComponent } from './proxy-server-management/generate-key/generate-key.component';
import { ProxyFeatureSettingsComponent } from './proxy-server-management/proxy-feature-settings/proxy-feature-settings.component';
import { ProxyKeysContext } from './contexts/proxy-keys.context';
import { ConnectionSettingsComponent } from './proxy-server-management/connection-settings/connection-settings.component';
import { ProxyConnectionSettingsContext } from './contexts/proxy-connection-settings.context';
import { MsProxyFeatureSettingsFormComponent } from './proxy-server-management/proxy-feature-settings/ms-proxy-feature-settings-form/ms-proxy-feature-settings-form.component';
import { CiscoProxyFeatureSettingsFormComponent } from './proxy-server-management/proxy-feature-settings/cisco-proxy-feature-settings-form/cisco-proxy-feature-settings-form.component';
import { CiscoProxyFeatureSettingsResource } from './resources/cisco-proxy-feature-settings.resource';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    CalendarModule,
    DialPlanExceptionManagementEditModule,
    NgSelectModule,
    SmacsFormsModule,
    ButtonModule,
    ClipboardModule,
    LoadingBlockModule,
    TopNavModule,
    BreadcrumbsModule,
    EntityTableModule,
    SideNavigationModule,
    BottomNavModule,
    BillingModule,
    DatatableModule,
    CodemirrorModule,
    SiteManagementModule,
    FileUploaderModule,
    PipesModule,
  ],
  declarations: [
    SmacsAuditTypeTagPipe,
    AdminComponent,
    CiscoGeneralSettingsComponent,
    GeneralSettingsCheckboxComponent,
    GeneralSettingsTextComponent,
    CustomizeUiComponent,
    CustomizeUiPreviewModalComponent,
    AuditLogsComponent,
    AuditSettingsComponent,
    AuditViewJsonModalComponent,
    AuditErrorViewModalComponent,
    AdminLicenseComponent,
    ImportConfigurationComponent,
    ImportAuditsComponent,
    ImportCallRecordsComponent,
    SiteBasedHelpdeskUserPermissionsManagementComponent,
    AdminEditPermissionsModalComponent,
    CertificateManagementComponent,
    ImportCertificateComponent,
    GenerateCsrComponent,
    GenerateCsrFormComponent,
    DialPlanExceptionManagementComponent,
    BackupsComponent,
    DefaultPhoneButtonTemplatesComponent,
    AdminServersComponent,
    GraphManagementComponent,
    AdminAddEditServerModalComponent,
    DefaultPhoneButtonTemplatesComponent,
    AdminLdapManagementComponent,
    AdminLdapManagementSettingsComponent,
    AdminLdapManagementPermissionsComponent,
    AdminLdapManagementSettingsLdapServerUrlComponent,
    AdminLdapManagementSettingsLdapServerUrlFormComponent,
    DeviceUtilizationReportSettingsComponent,
    SamlComponent,
    SamlExportButtonComponent,
    SamlImportButtonComponent,
    CdrDumpFileImportComponent,
    ApplicationLogsComponent,
    BulkEditPermissionsModalComponent,
    AdminEmailConfigurationComponent,
    AdminEmailConfigurationModalComponent,
    SystemHealthSettingsNotificationsComponent,
    DialPlanManagementComponent,
    MsDialPlanGroupManagementComponent,
    DialPlanGroupListComponent,
    MsDialPlanGroupManagementFormComponent,
    HighAvailabilityManagementComponent,
    LicenseModuleRowComponent,
    CiscoDialPlanManagementListComponent,
    CiscoDialPlanManagementEditComponent,
    CiscoDialPlanManagementGroupSettingsFormComponent,
    CiscoDialPlanManagementGroupSettingsLocationComponent,
    CiscoDialPlanManagementDnRangesFormComponent,
    CiscoDialPlanManagementWriteToLdapFormComponent,
    CiscoDialPlanManagementDidRangesFormComponent,
    EmailTemplatesEntityTableComponent,
    EmailTemplateEditComponent,
    EmailTemplateEditFormComponent,
    MicrosoftDialPlanExceptionManagementListComponent,
    MicrosoftDialPlanExceptionManagementEditComponent,
    MicrosoftDialPlanExceptionManagementEditFormComponent,
    MicrosoftLicenseManagementComponent,
    MicrosoftLicenseManagementFormComponent,
    UserPermissionsComponent,
    UserPermissionsSearchFormComponent,
    UserPermissionsModalComponent,
    UserPermissionsRolePipe,
    BandwidthManagementComponent,
    DubberAccountManagementComponent,
    TrimWithEllipsisFilter,
    SortDialPlansPipe,
    Oauth2ResourceServerManagementComponent,
    MicrosoftGeneralSettingsComponent,
    WebexAuthenticationComponent,
    WebexAuthenticationFormComponent,
    SecurityGroupManagementComponent,
    SecurityGroupManagementFormComponent,
    PortInOrdersComponent,
    OrderViewAndEditComponent,
    OrderViewAndEditContainerComponent,
    PortInDraftsUploadModalComponent,
    PortInNumberStatusComponent,
    GenerateKeyComponent,
    ProxyFeatureSettingsComponent,
    MsProxyFeatureSettingsFormComponent,
    CiscoProxyFeatureSettingsFormComponent,
    ConnectionSettingsComponent,
    CheckNumberPortabilityComponent,
    CheckNumberPortabilityFormComponent,
    CreateDraftComponent,
    ZiroSupportSettingsComponent,
    OrderErrorComponent,
    OrderNoteComponent,
    GenerateLoaModalComponent,
    AdAttributesComponent,
    AdAttributeChildFormComponent,
  ],
  exports: [
    AdminComponent,
    CiscoGeneralSettingsComponent,
    GeneralSettingsCheckboxComponent,
    GeneralSettingsTextComponent,
    CustomizeUiComponent,
    CustomizeUiPreviewModalComponent,
    AuditViewJsonModalComponent,
    AuditErrorViewModalComponent,
    AuditLogsComponent,
    AuditSettingsComponent,
    AdminLicenseComponent,
    ImportConfigurationComponent,
    ImportAuditsComponent,
    ImportCallRecordsComponent,
    DialPlanExceptionManagementComponent,
    SiteBasedHelpdeskUserPermissionsManagementComponent,
    AdminEditPermissionsModalComponent,
    CertificateManagementComponent,
    ImportCertificateComponent,
    GenerateCsrComponent,
    BackupsComponent,
    DefaultPhoneButtonTemplatesComponent,
    AdminLdapManagementComponent,
    AdminLdapManagementSettingsComponent,
    AdminLdapManagementPermissionsComponent,
    AdminLdapManagementSettingsLdapServerUrlComponent,
    AdminLdapManagementSettingsLdapServerUrlFormComponent,
    DeviceUtilizationReportSettingsComponent,
    AdminServersComponent,
    GraphManagementComponent,
    AdminAddEditServerModalComponent,
    DefaultPhoneButtonTemplatesComponent,
    SamlComponent,
    CdrDumpFileImportComponent,
    ApplicationLogsComponent,
    BulkEditPermissionsModalComponent,
    AdminEmailConfigurationComponent,
    AdminEmailConfigurationModalComponent,
    SystemHealthSettingsNotificationsComponent,
    DialPlanManagementComponent,
    MsDialPlanGroupManagementComponent,
    DialPlanGroupListComponent,
    MsDialPlanGroupManagementFormComponent,
    HighAvailabilityManagementComponent,
    CiscoDialPlanManagementListComponent,
    CiscoDialPlanManagementEditComponent,
    CiscoDialPlanManagementGroupSettingsFormComponent,
    CiscoDialPlanManagementGroupSettingsLocationComponent,
    CiscoDialPlanManagementDnRangesFormComponent,
    CiscoDialPlanManagementWriteToLdapFormComponent,
    CiscoDialPlanManagementDidRangesFormComponent,
    EmailTemplatesEntityTableComponent,
    EmailTemplateEditComponent,
    EmailTemplateEditFormComponent,
    MicrosoftDialPlanExceptionManagementListComponent,
    MicrosoftDialPlanExceptionManagementEditComponent,
    MicrosoftDialPlanExceptionManagementEditFormComponent,
    MicrosoftLicenseManagementComponent,
    MicrosoftLicenseManagementFormComponent,
    UserPermissionsComponent,
    UserPermissionsSearchFormComponent,
    UserPermissionsModalComponent,
    BandwidthManagementComponent,
    DubberAccountManagementComponent,
    Oauth2ResourceServerManagementComponent,
    MicrosoftGeneralSettingsComponent,
    WebexAuthenticationComponent,
    SecurityGroupManagementComponent,
    PortInOrdersComponent,
    OrderViewAndEditComponent,
    OrderViewAndEditContainerComponent,
    PortInDraftsUploadModalComponent,
    PortInNumberStatusComponent,
    GenerateKeyComponent,
    ProxyFeatureSettingsComponent,
    ConnectionSettingsComponent,
    CheckNumberPortabilityComponent,
    CreateDraftComponent,
    ZiroSupportSettingsComponent,
    TrimWithEllipsisFilter,
  ],
  providers: [
    ServersContext,
    SamlSsoConfigContext,
    ApplicationLogsResource,
    CertificateResource,
    Oauth2ResourceServerSettingsResource,
    DefaultPhoneButtonTemplateResource,
    DialPlanExceptionResource,
    SiteBasedHelpdeskManagementResource,
    ConfigurationDatabaseResource,
    AuditDatabaseResource,
    CallRecordsDatabaseResource,
    AuditSettingsContext,
    MsTeamsCachedOptionsResource,
    BandwidthCachedOptionsContext,
    MsDialPlanGroupsContext,
    MsDialPlanExceptionGroupsContext,
    UserPermissionsContext,
    MsGraphConfigContext,
    CiscoGeneralSettingsResource,
    MsZeroTouchProvisioningContext,
    WebexCallingSettingsContext,
    WebexCallingStatusContext,
    MicrosoftSecurityGroupSettingsResource,
    EmailTemplatesContext,
    ZiroSupportSettingsResource,
    NumberPortabilitiesContext,
    PortInDraftsResource,
    PortInDraftSignedLoasResource,
    TrimWithEllipsisFilter,
    ProxyKeysContext,
    ProxyConnectionSettingsContext,
    CiscoProxyFeatureSettingsResource,
  ],
})
export class AdminModule {}
