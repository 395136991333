import { flatten, forOwn, mapValues } from 'lodash';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AssignExtensionChoice, BulkProvisioningFormData, BulkProvisioningTab } from '../bulk-provisioning.component';
import { CiscoZeroTouchProvisioningFieldConfig, SiteResult } from '../../../../shared/models/generated/smacsModels';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import { ZeroTouchProvisioningResource } from '../../../../shared/resources/zpc-zero-touch.abstract.resource';
import { SafeUrl } from '@angular/platform-browser';
import { of, Subscription } from 'rxjs';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import {
  SmacsSelectComponent,
  SmacsSelectConfig,
  SmacsSelectOption,
} from '../../../../forms/fields/select/smacs-select.component';
import { SmacsMultiCheckboxConfig } from '../../../../forms/fields/multi-checkbox/smacs-multi-checkbox.component';
import { SmacsRadioConfig } from '../../../../forms/fields/radio/smacs-radio.component';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig, SmacsFormsUpdate } from '../../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';

@Component({
  selector: 'smacs-bulk-provisioning-job-general',
  templateUrl: './bulk-provisioning-job-general.component.html',
  styleUrls: ['./bulk-provisioning-job-general.component.scss'],
})
export class BulkProvisioningJobGeneralComponent
  extends SmacsFormAbstractDirective<BulkProvisioningFormData>
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Input() tabSelectionEmitter: EventEmitter<BulkProvisioningTab>;
  @Input() csvTemplateUrl: SafeUrl;

  sites: SiteResult[];
  isSubmitted = false;
  provisioningFieldConfigs: CiscoZeroTouchProvisioningFieldConfig;
  smacsIcons = SmacsIcons;
  formConfig: SmacsFormConfig;

  private _subs = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private siteSummaryContext: SiteSummaryContext,
    private zeroTouchProvisioningResource: ZeroTouchProvisioningResource,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);

    const siteSummarySub = this.siteSummaryContext.state$.subscribe((siteSummary) => {
      this.sites = flatten(siteSummary.clusters.map((c) => c.sites));
      const siteOptions = this.sites.map<SmacsSelectOption>((siteResult) => {
        return {
          label: siteResult.name,
          value: String(siteResult.id),
        };
      });

      this.formConfig = {
        fields: {
          site: {
            label: 'tkey;pages.zero_touch.provisioning.filter.site',
            dataAutomation: 'bulk-provisioning-general-site-select',
            required: true,
            helpText: 'tkey;zero_touch.bulk_provisioning.general_settings.help_text.site',
            componentConfig: new SmacsSelectConfig({
              options: siteOptions,
              bindValue: 'value',
            }),
          },
          services: {
            label: 'tkey;pages.zero_touch.provisioning.filter.services',
            dataAutomation: 'bulk-provisioning-general-services-multi-checkbox',
            required: true,
            helpText: 'tkey;zero_touch.bulk_provisioning.general_settings.help_text.services',
            componentConfig: new SmacsMultiCheckboxConfig({
              multiCheckboxOptionConfig: [
                {
                  label: 'tkey;pages.zero_touch.provisioning.edit.form.primary_extension',
                  disabled: this._isPrimaryExtensionServiceDisabled,
                  disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.dn.disabled.tooltip',
                },
                {
                  label: 'tkey;pages.zero_touch.provisioning.edit.form.agent_extension',
                  hidden: () => !this.provisioningFieldConfigs?.agentExtension,
                },
                {
                  label: 'tkey;shared.service.voicemail.text',
                  disabled: () => !this.provisioningFieldConfigs?.voicemail,
                  disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
                },
                {
                  label: 'tkey;shared.model.deskphone.text',
                  disabled: () => !this.provisioningFieldConfigs?.deskphone,
                  disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
                },
                {
                  label: 'tkey;shared.service.snr.text',
                  disabled: () => this._isSingleNumberReachServiceDisabled() || !this.provisioningFieldConfigs?.snr,
                  disabledTooltip: () =>
                    this._isSingleNumberReachServiceDisabled()
                      ? 'tkey;pages.zero_touch.provisioning.services.snr.disabled.tooltip'
                      : 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
                },
                {
                  label: 'tkey;shared.service.teams_snr.text',
                  disabled: () => !this.provisioningFieldConfigs?.snrDestinationForMicrosoftTeams,
                  disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
                  tooltip: () =>
                    this.provisioningFieldConfigs?.snrDestinationForMicrosoftTeams
                      ? 'tkey;shared.service.teams_snr.tooltip'
                      : '',
                  // TODO: Un hide this checkbox when all the pieces of the epic is complete. See https://github.com/Stack8/smacs/issues/11648
                  hidden: () => true,
                },
                {
                  label: 'tkey;pages.zero_touch.provisioning.edit.form.extension_mobility',
                  disabled: () => !this.provisioningFieldConfigs?.mobility,
                  disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
                },
                {
                  label: 'tkey;pages.zero_touch.provisioning.edit.form.cipc',
                  disabled: () => !this.provisioningFieldConfigs?.cipc,
                  disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
                },
                {
                  label: 'tkey;pages.zero_touch.provisioning.edit.form.im_softphone',
                  disabled: () => !this.provisioningFieldConfigs?.imSoftPhone,
                  disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
                },
                {
                  label: 'tkey;pages.zero_touch.provisioning.edit.form.iphone',
                  disabled: () => !this.provisioningFieldConfigs?.iphone,
                  disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
                },
                {
                  label: 'tkey;pages.zero_touch.provisioning.edit.form.android',
                  disabled: () => !this.provisioningFieldConfigs?.android,
                  disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
                },
                {
                  label: 'tkey;pages.zero_touch.provisioning.edit.form.tablet',
                  disabled: () => !this.provisioningFieldConfigs?.tablet,
                  disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
                },
                {
                  label: 'tkey;pages.zero_touch_provisioning_edit.form.imp',
                  disabled: () => !this.provisioningFieldConfigs?.imp,
                  disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
                },
              ],
            }),
          },
          assignExtension: {
            label: 'tkey;zero_touch.bulk_provisioning.general_settings.assign_extension.label',
            dataAutomation: 'bulk-provisioning-general-assign-extension',
            required: true,
            hidden: () => !this.formData?.services?.primaryExtension,
            valExcluded: () => !this.formData?.services?.primaryExtension,
            componentConfig: new SmacsRadioConfig<any>({
              buttons: [
                {
                  value: AssignExtensionChoice.AUTOMATIC,
                  label: 'tkey;zero_touch.bulk_provisioning.general_settings.assign_extension.automatic',
                },
                {
                  value: AssignExtensionChoice.CSV,
                  label: 'tkey;zero_touch.bulk_provisioning.general_settings.assign_extension.csv',
                },
              ],
              inline: true,
            }),
          },

          dnGroups: {
            label: 'tkey;pages.zero_touch.provisioning.filter.dial_plan_group',
            dataAutomation: 'bulk-provisioning-general-dial-plan-groups-select',
            required: true,
            helpText: 'tkey;zero_touch.bulk_provisioning.general_settings.help_text.dialplan_groups',
            hidden: () => this.formData?.assignExtension !== AssignExtensionChoice.AUTOMATIC,
            valExcluded: () => this.formData?.assignExtension !== AssignExtensionChoice.AUTOMATIC,
            componentConfig: new SmacsSelectConfig({
              isMultiSelect: true,
              bindValue: 'value',
            }),
          },
          auditTag: {
            label: 'tkey;zero_touch.bulk_provisioning.general_settings.audit_tag.label',
            dataAutomation: 'bulk-provisioning-general-audit-tag-input',
            required: true,
            helpText: 'tkey;zero_touch.bulk_provisioning.general_settings.help_text.audit_tag',
            componentConfig: new SmacsTextConfig({
              htmlInputType: HtmlInputType.TEXT,
              placeholder: 'tkey;zero_touch.bulk_provisioning.general_settings.placeholder.audit_tag',
            }),
          },
        },
      };
    });
    this._subs.add(siteSummarySub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  ngOnInit() {
    this.entitySource.next({
      services: {
        primaryExtension: false,
        agentExtension: false,
        voicemail: false,
        deskphone: false,
        snr: false,
        snrDestinationForMicrosoftTeams: false,
        mobility: false,
        cipc: false,
        imSoftphone: false,
        iphone: false,
        android: false,
        tablet: false,
        imp: false,
      },
    } as BulkProvisioningFormData);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.smacsFormsUpdate$.subscribe((update) => {
      if (!update.new) {
        return;
      }
      if (!update.new.services.primaryExtension && this._isPrimaryExtensionServiceDisabled()) {
        const servicesComponent = this.fieldComponents.find((field) => field.fieldId === 'services');
        servicesComponent.updateSelf({
          ...this.formData.services,
          primaryExtension: true,
        });
      }

      if (!update.new.services.snr && this._isSingleNumberReachServiceDisabled()) {
        this.entitySource.next({
          ...this.formData,
          services: {
            ...this.formData.services,
            snr: true,
          },
        });
      }
    });

    const siteFieldComponent = this.fieldComponents.find((field) => field.fieldId === 'site') as SmacsSelectComponent;
    siteFieldComponent.smacsFormsUpdate$.subscribe((siteUpdate: SmacsFormsUpdate<string>) => {
      if (!siteUpdate.new) {
        return;
      }
      this.provisioningFieldConfigs = null;

      const servicesComponent = this.fieldComponents.find((field) => field.fieldId === 'services');
      servicesComponent.updateSelf(mapValues(this.formData.services, () => false));
      servicesComponent.isDirty = false;

      this.zeroTouchProvisioningResource
        .getCiscoZeroTouchProvisioningFieldConfigs(Number(siteUpdate.new))
        .subscribe((settings) => {
          this.provisioningFieldConfigs = settings;

          const dnGroupOptions = [] as SmacsSelectOption[];
          forOwn(this.provisioningFieldConfigs.dialPlanGroups, (label, value) => dnGroupOptions.push({ label, value }));
          const dnGroupsComponent = this.fieldComponents.find((field) => field.fieldId === 'dnGroups');
          dnGroupsComponent.applyComponentConfig(
            new SmacsSelectConfig({
              options: dnGroupOptions,
              isMultiSelect: true,
              bindValue: 'value',
            })
          );
        });
    });
  }

  goToTable() {
    this.isSubmitted = true;
    this._validateAndSubmitSource.next(true);
  }

  protected submit() {
    if (this.isFormValid()) {
      this.tabSelectionEmitter.emit(BulkProvisioningTab.TABLE);
    }

    return of(null);
  }

  private _isSingleNumberReachServiceDisabled = (): boolean => {
    const services = this.formData.services;
    return services.snrDestinationForMicrosoftTeams;
  };

  private _isPrimaryExtensionServiceDisabled = (): boolean => {
    const services = this.formData.services;
    return (
      services.agentExtension ||
      services.deskphone ||
      services.mobility ||
      services.snr ||
      services.voicemail ||
      services.imSoftphone ||
      services.iphone ||
      services.android ||
      services.tablet ||
      services.snrDestinationForMicrosoftTeams
    );
  };
}
