import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { combineLatest, of, Subscription } from 'rxjs';
import { PinResetService } from '../../../shared/services/pin-reset.service';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import { CiscoHelpdeskOptions, EndUserResult, VoicemailRef } from '../../../shared/models/generated/smacsModels';
import { HelpdeskOptionsContext } from '../../shared/contexts/helpdesk-options.context';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { PinResetComponent, PinResetOptions } from '../pin-reset/pin-reset.component';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { HttpErrorResponse } from '@angular/common/http';
import { isEqual } from 'lodash';

interface UserPinReset {
  voicemailPinReset: string;
  extensionMobPinReset: string;
}

@Component({
  selector: 'smacs-user-pin-reset',
  templateUrl: './user-pin-reset.component.html',
})
export class UserPinResetComponent extends SmacsFormAbstractDirective<UserPinReset> implements OnInit, OnDestroy {
  @ViewChild('vmPin') vmPinResetComponent: PinResetComponent;
  @ViewChild('emPin') emPinResetComponent: PinResetComponent;

  isVmPresent: boolean;
  isEmPresent: boolean;
  isLoading = true;
  hasVmError = false;
  hasEmError = false;
  voicemailMessage = '';
  extensionMobilityMessage = '';

  formConfig = {
    fields: {
      voicemailPinReset: {
        dataAutomation: 'user-voicemail-pin-reset',
        label: 'tkey;pages.pinreset.voicemail',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.PASSWORD,
          placeholder: 'tkey;pages.reset.pin.placeholder',
        }),
        validation: [
          {
            validator: (val: string) => {
              return !val || (!!val && /(^[0-9]+$)/.test(val))
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID;
            },
            message: 'tkey;validators.global.error.pattern',
          },
          {
            validator: () => (this.hasVmError ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID),
            message: () => this.voicemailMessage,
          },
        ],
      },
      extensionMobPinReset: {
        dataAutomation: 'user-extension-mobility-pin-reset',
        label: 'tkey;pages.pinreset.extensionmobility',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.PASSWORD,
          placeholder: 'tkey;pages.reset.pin.placeholder',
        }),
        validation: [
          {
            validator: (val: string) =>
              !val || (!!val && /(^[0-9]+$)/.test(val))
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: 'tkey;validators.global.error.pattern',
          },
          {
            validator: () => (this.hasEmError ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID),
            message: () => this.extensionMobilityMessage,
          },
        ],
      },
    },
  } as SmacsFormConfig;
  private _smacsIcons = SmacsIcons;
  private _cucmServerId: number;
  private _endUserResult: EndUserResult;
  private _resetPinOnNextLogin = false;
  private _voicemailRef: VoicemailRef;
  private _subscriptions = new Subscription();

  constructor(
    private _currentClusterContext: CurrentClusterContext,
    private _global360ViewContext: Global360ViewContext,
    private _userDetailUiContext: UserDetailUiContext,
    private _pinResetService: PinResetService,
    private _helpdeskOptionsContext: HelpdeskOptionsContext,
    private _toastService: ToastService,
    private userDetailUiContext: UserDetailUiContext,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    // We need UserDetailUiContext to wait until the cluster is picked from the modal.
    const subs = combineLatest([
      this._global360ViewContext.state$,
      this._currentClusterContext.state$,
      this._helpdeskOptionsContext.state$,
      this.userDetailUiContext.state$,
    ]).subscribe(([global360View, currentClusterContext, ciscoHelpdeskOptions, userDetailUi]) => {
      this._cucmServerId = currentClusterContext.cucmServerId;
      this._endUserResult = this._global360ViewContext.getCurrentEnduser(global360View, this._cucmServerId);
      this._resetPinOnNextLogin = (ciscoHelpdeskOptions as CiscoHelpdeskOptions).resetPinOnNextLoginEnabled;
      this.isVmPresent = !!global360View.voicemails && global360View.voicemails.length > 0;
      this.isEmPresent = !!global360View.extensionMobilities && global360View.extensionMobilities.length > 0;

      if (this.isVmPresent) {
        this._voicemailRef = this._userDetailUiContext.getCurrentVoicemail();
      }
      this.isLoading = false;
    });
    this._subscriptions.add(subs);

    this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<UserPinReset>) => {
      if (
        data.old?.voicemailPinReset &&
        !isEqual(data.new.voicemailPinReset, data.old.voicemailPinReset) &&
        this.hasVmError
      ) {
        this.hasVmError = false;
      }
      if (
        data.old?.extensionMobPinReset &&
        !isEqual(data.new.extensionMobPinReset, data.old.extensionMobPinReset) &&
        this.hasEmError
      ) {
        this.hasEmError = false;
      }
    });
  }

  onSubmit(event: string) {
    switch (event) {
      case PinResetOptions.END_USER: {
        this._resetEndUserPin();
        break;
      }
      case PinResetOptions.VOICEMAIL: {
        this._resetVoicemailPin();
        break;
      }
    }
  }

  submit() {
    return of(null);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  private _resetVoicemailPin() {
    this._pinResetService
      .resetVoicemailPin(this._voicemailRef, this.entity.voicemailPinReset, this._resetPinOnNextLogin)
      .subscribe({
        next: () => {
          this._toastService.push(
            ToastTypes.SUCCESS,
            this._smacsIcons.REFRESH,
            'tkey;pages.pinreset.success.title',
            'tkey;pages.pinreset.voicemail.success.message'
          );
          this._resetVoicemailPinInput();
        },
        error: (error) => {
          if (error.status === 422) {
            this.hasVmError = true;
            this.voicemailMessage = this._pinResetService.translateVoicemailPinResetErrorResponse(error);
            this.fieldChannels['voicemailPinReset'].validateSource.next();
          } else {
            throw new HttpErrorResponse(error);
          }
          this._resetVoicemailPinInput();
        },
      });
  }

  private _resetEndUserPin() {
    this._pinResetService
      .resetEndUserPin(
        this._cucmServerId.toString(),
        this._endUserResult,
        this.entity.extensionMobPinReset,
        this._resetPinOnNextLogin
      )
      .subscribe({
        next: () => {
          this._toastService.push(
            ToastTypes.SUCCESS,
            this._smacsIcons.REFRESH,
            'tkey;pages.pinreset.success.title',
            'tkey;pages.pinreset.extension_mobility.success.message'
          );
          this._resetEndUserPinInput();
        },
        error: (error) => {
          if (error.status === 422 && error.reasonCode !== 'FORBIDDEN_DN_MODIFICATION') {
            this.hasEmError = true;
            this.extensionMobilityMessage = this._pinResetService.translateEndUserPinResetErrorStatus(error);
            this.fieldChannels['extensionMobPinReset'].validateSource.next();
          } else {
            throw new HttpErrorResponse(error);
          }
          this._resetEndUserPinInput();
        },
      });
  }

  private _resetVoicemailPinInput() {
    this.vmPinResetComponent.isPending = false;
    this.entitySource.next({
      ...this.entity,
      voicemailPinReset: '',
    });
    this.smacsFormStateService.setIsFormDirty(false);
  }

  private _resetEndUserPinInput() {
    this.emPinResetComponent.isPending = false;
    this.entitySource.next({
      ...this.entity,
      extensionMobPinReset: '',
    });
    this.smacsFormStateService.setIsFormDirty(false);
  }
}
