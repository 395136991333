import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelfServe360View, SelfServeUiContext } from '../contexts/self-serve-ui.context';
import { Observable, of, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash';
import { EndUserResult, ExtensionMobilityRef, VoicemailRef } from '../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { SmacsFormAbstractDirective } from '../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../forms/smacs-forms-models';
import { HtmlInputType, SmacsTextConfig } from '../../forms/fields/text/smacs-text.component';

interface FormData {
  pin: string;
  pinConfirm: string;
}

enum ValidationMsg {
  NotNumeric = 'tkey;validators.global.numeric.error',
  DoNotMatch = 'tkey;selfserve.pin.do.not.match.text',
}

const validators = {
  pinIsNumeric: (val: string) =>
    !!val && /(^[0-9]+$)/.test(val) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
  doPinsMatch: (val: string, val2: string) =>
    !val || (!!val && !!val2 && val === val2) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
};

@Component({
  templateUrl: './self-serve-pin-reset.abstract.component.html',
})
export class SelfServePinResetAbstractComponent
  extends SmacsFormAbstractDirective<FormData>
  implements OnInit, OnDestroy
{
  isLoading = true;
  isPending = false;
  headerTranslationKey: string;
  headingDataAutomation: string;
  smacsIcons = SmacsIcons;
  user: EndUserResult;
  selfServe360View = {} as SelfServe360View;
  voicemailRef: VoicemailRef;
  extensionMobilityRef: ExtensionMobilityRef;
  formConfig = {
    fields: {
      pin: {
        label: 'tkey;selfserve.pin.placeholder',
        dataAutomation: 'self-serve-pin-reset-input',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
        required: true,
        validation: [
          {
            validator: (val: string) => validators.pinIsNumeric(val),
            message: ValidationMsg.NotNumeric,
            injectValuesFromFields: ['pinConfirm'],
          },
        ],
      },
      pinConfirm: {
        label: 'tkey;selfserve.pin.confirm.placeholder',
        dataAutomation: 'self-serve-pin-reset-confirm-input',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
        required: true,
        validation: [
          {
            validator: (val: string, val2: string) => validators.doPinsMatch(val, val2),
            message: ValidationMsg.DoNotMatch,
            injectValuesFromFields: ['pin'],
          },
        ],
      },
    },
  } as SmacsFormConfig;

  private _subscriptions = new Subscription();

  constructor(
    private selfServeUiContext: SelfServeUiContext,
    private route: ActivatedRoute,
    private router: Router,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initSelfServe();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  resetPin = (): Observable<any> => {
    return of(Promise.resolve());
  };

  onPinResetSuccess = (): void => {};

  resetPinClick(): Observable<void> {
    return new Observable((subscriber) => {
      this.isPending = true;
      this.resetPin().subscribe({
        next: () => {
          this.onPinResetSuccess();
          subscriber.next(null);
          subscriber.complete();
        },
        error: (error) => {
          this.isPending = false;
          this.isSubmitting = false;
          this.isFormSubmitted = false;
          this.smacsFormStateService.setIsFormDirty(false);
          subscriber.error(error);
          subscriber.complete();
        },
      });
    });
  }

  onPinResetSuccessBase = () => {
    this.isPending = false;
    this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(this.user.ref.username)}`);
  };

  private _initSelfServe = () => {
    if (!this.selfServeUiContext.selfServe360View) {
      const username = get(this.route, 'snapshot.params.username');
      this.selfServeUiContext.initUserDetails(username);
    }

    const vmId = get(this.route, 'snapshot.params.voicemailId');
    const emId = get(this.route, 'snapshot.params.extensionMobilityId');
    const subscription = this.selfServeUiContext.selfServe360View$.subscribe((selfServe360View: SelfServe360View) => {
      if (selfServe360View) {
        this.selfServe360View = selfServe360View;

        if (vmId) {
          this.voicemailRef = this.selfServe360View.voicemails.find((vm: VoicemailRef) => vm.id === vmId);
          this.user = selfServe360View.endUsers[0];
        } else if (emId) {
          this.extensionMobilityRef = this.selfServe360View.extensionMobilities.find(
            (em: ExtensionMobilityRef) => em.id === emId
          );
          this.user = selfServe360View.endUsers.find(
            (endUser: EndUserResult) => endUser.ref.serverId === this.extensionMobilityRef.serverId
          );
        }

        if (!this.voicemailRef && !this.extensionMobilityRef) {
          const username = get(this.route, 'snapshot.params.username');
          this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(username)}`);
          return;
        }

        this.isLoading = false;
      }
    });
    this._subscriptions.add(subscription);
  };

  protected submit(): Observable<void> {
    return this.resetPinClick();
  }
}
