import { Pipe, PipeTransform } from '@angular/core';
import { DefaultValueService } from '../services/default-value.service';

@Pipe({ name: 'customInputPreviewFilter' })
export class CustomInputPreviewFilter implements PipeTransform {
  constructor(private defaultValueService: DefaultValueService) {}

  transform(value: string): string {
    return this.defaultValueService.generatePreview(value);
  }
}
