import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JobStatus } from '../models/generated/smacsModels';
import { PollingAbstractService } from '../services/abstract/polling.abstract.service';

@Injectable()
export class BulkProvisioningPollingContext extends PollingAbstractService<JobStatus> {
  baseUrl = '/services/cisco/automate-jobs/cisco-bulk-end-user-provisionings/status';

  constructor(protected httpClient: HttpClient, protected ngZone: NgZone) {
    super(httpClient, ngZone);
  }
}
