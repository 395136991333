import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SmacsButtonComponent } from './button.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbModule, AngularSvgIconModule],
  declarations: [SmacsButtonComponent],
  exports: [SmacsButtonComponent],
})
export class ButtonModule {}
