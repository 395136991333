import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { SharedCallingRoutingPolicy } from '../../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class SharedCallingPoliciesContext {
  private _baseUrl = '/services/microsoft/views/shared-calling-routing-policies';
  private _stateSource = new ReplaySubject<SharedCallingRoutingPolicy[]>(1);
  state$ = this._stateSource.asObservable();

  constructor(private _http: HttpClient) {
    this.refresh().subscribe();
  }

  refresh(): Observable<void> {
    return this._get().pipe(map((policies) => this._stateSource.next(policies)));
  }

  private _get(): Observable<SharedCallingRoutingPolicy[]> {
    return this._http.get<SharedCallingRoutingPolicy[]>(this._baseUrl);
  }
}
