import { EnabledType, Site } from '../../../../../shared/models/generated/smacsModels';

const blankSite: Site = {
  id: '',
  name: '',
  ldapSiteName: '',
  serviceSettings: [
    {
      name: 'Android',
      oneClickEnabled: true,
      customInputTexts: [
        {
          name: 'name',
          value: 'BOT{{toUpperCase username}}',
          show: false,
          required: false,
        },
        {
          name: 'description',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'cisco_support_field',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'mobility_identity_name',
          value: '',
          show: true,
          required: true,
        },
        {
          name: 'stop_ringing_phone_delay',
          value: '19',
          show: false,
          required: true,
        },
        {
          name: 'Emergency Numbers',
          value: '999,911,112,113',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'rerouting_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'common_device_configuration',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'softkey_template',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'common_phone_profile',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: true,
        },
        {
          name: 'calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'aar_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'user_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'network_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'location',
          defaultOption: 'Hub_None',
          possibleOptions: ['Hub_None'],
          show: false,
          required: true,
        },
        {
          name: 'user_locale',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'privacy',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'device_mobility_mode',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line_for_vm',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'built_in_bridge',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'sip_profile_name',
          defaultOption: 'Standard SIP Profile',
          possibleOptions: ['Standard SIP Profile'],
          show: false,
          required: true,
        },
        {
          name: 'subscribe_css',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'media_resource_group_list',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
      ],
      selects: [
        {
          name: 'devicepool',
          value: '',
        },
        {
          name: 'preferred_security_profile_mode',
          value: '',
        },
      ],
      customMultiSelects: [
        {
          name: 'associated_end_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
        {
          name: 'associated_app_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
      ],
      multiSelects: [],
      customCheckboxes: [],
      checkboxes: [
        {
          name: 'mobility_identity_management',
          value: false,
        },
      ],
      enabled: EnabledType.DISABLED,
    },
    {
      name: 'CIPC',
      oneClickEnabled: true,
      customInputTexts: [
        {
          name: 'name',
          value: '',
          show: false,
          required: true,
        },
        {
          name: 'description',
          value: '',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'common_device_configuration',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'softkey_template',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'common_phone_profile',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: true,
        },
        {
          name: 'calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'aar_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'user_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'network_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'location',
          defaultOption: 'Hub_None',
          possibleOptions: ['Hub_None'],
          show: false,
          required: true,
        },
        {
          name: 'user_locale',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'privacy',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'device_mobility_mode',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line_for_vm',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'built_in_bridge',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'sip_profile_name',
          defaultOption: 'Standard SIP Profile',
          possibleOptions: ['Standard SIP Profile'],
          show: false,
          required: true,
        },
        {
          name: 'subscribe_css',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'media_resource_group_list',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
      ],
      selects: [
        {
          name: 'preferred_protocol',
          value: '',
        },
        {
          name: 'devicepool',
          value: '',
        },
        {
          name: 'preferred_security_profile_mode',
          value: '',
        },
      ],
      customMultiSelects: [
        {
          name: 'associated_end_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
        {
          name: 'associated_app_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
      ],
      multiSelects: [
        {
          name: 'phone_service',
          possibleOptions: [],
        },
      ],
      customCheckboxes: [
        {
          name: 'disable_speaker_phone',
          value: false,
          show: false,
        },
      ],
      checkboxes: [],
      enabled: EnabledType.DISABLED,
    },
    {
      name: 'Desk Phone',
      oneClickEnabled: false,
      customInputTexts: [
        {
          name: 'user_phone_description',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'public_phone_description',
          value: '',
          show: true,
          required: false,
        },
        {
          name: 'ext_data_location_auth_server',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'ext_data_location_secure_auth_url',
          value: '',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'phone_model',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: true,
        },
        {
          name: 'feature_control_policy',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'rerouting_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'common_device_configuration',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'softkey_template',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'common_phone_profile',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: true,
        },
        {
          name: 'calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'aar_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'user_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'network_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'location',
          defaultOption: 'Hub_None',
          possibleOptions: ['Hub_None'],
          show: false,
          required: true,
        },
        {
          name: 'user_locale',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'privacy',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'device_mobility_mode',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line_for_vm',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'built_in_bridge',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'sip_profile_name',
          defaultOption: 'Standard SIP Profile',
          possibleOptions: ['Standard SIP Profile'],
          show: false,
          required: true,
        },
        {
          name: 'subscribe_css',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'media_resource_group_list',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
      ],
      selects: [
        {
          name: 'preferred_protocol',
          value: '',
        },
        {
          name: 'devicepool',
          value: '',
        },
        {
          name: 'preferred_security_profile_mode',
          value: '',
        },
      ],
      customMultiSelects: [
        {
          name: 'associated_end_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
        {
          name: 'associated_app_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
      ],
      multiSelects: [
        {
          name: 'phone_service',
          possibleOptions: [],
        },
      ],
      customCheckboxes: [
        {
          name: 'disable_speaker_phone',
          value: false,
          show: false,
        },
      ],
      checkboxes: [],
      enabled: EnabledType.DISABLED,
    },
    {
      name: 'Directory Number',
      oneClickEnabled: false,
      customInputTexts: [
        {
          name: 'user_primary_extension_description',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'user_primary_extension_alerting_name',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'call_forward_no_answer_ring_duration',
          value: '',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'call_pickup_group',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'call_forward_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'call_forward_secondary_calling_search_space_for_forward_all',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
      ],
      selects: [
        {
          name: 'voicemail_profile',
          value: '',
        },
        {
          name: 'no_voicemail_profile',
          value: '',
        },
      ],
      customMultiSelects: [],
      multiSelects: [],
      customCheckboxes: [],
      checkboxes: [],
      enabled: EnabledType.NOT_APPLICABLE,
    },
    {
      name: 'End User',
      oneClickEnabled: false,
      customInputTexts: [
        {
          name: 'directory_uri',
          value: '',
          show: false,
          required: false,
        },
      ],
      customSelects: [],
      selects: [
        {
          name: 'uc_service_profile',
          value: '',
        },
        {
          name: 'uc_service_profile_without_voicemail',
          value: '',
        },
        {
          name: 'subscribe_css',
          value: '',
        },
      ],
      customMultiSelects: [],
      multiSelects: [
        {
          name: 'end_user_group',
          possibleOptions: [],
        },
      ],
      customCheckboxes: [],
      checkboxes: [],
      enabled: EnabledType.NOT_APPLICABLE,
    },
    {
      name: 'Extension Mobility',
      oneClickEnabled: true,
      customInputTexts: [
        {
          name: 'name',
          value: '',
          show: false,
          required: true,
        },
        {
          name: 'description',
          value: '',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'phone_model',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: true,
        },
        {
          name: 'user_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'user_locale',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'softkey_template',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'privacy',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line_for_vm',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'feature_control_policy',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'cross_cluster_css',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
      ],
      selects: [
        {
          name: 'preferred_protocol',
          value: '',
        },
      ],
      customMultiSelects: [
        {
          name: 'associated_end_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
        {
          name: 'associated_end_users_cti',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
        {
          name: 'associated_app_users_cti',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
      ],
      multiSelects: [
        {
          name: 'phone_service',
          possibleOptions: [],
        },
      ],
      customCheckboxes: [],
      checkboxes: [],
      enabled: EnabledType.DISABLED,
    },
    {
      name: 'IM Presence',
      oneClickEnabled: true,
      customInputTexts: [],
      customSelects: [],
      selects: [],
      customMultiSelects: [],
      multiSelects: [],
      customCheckboxes: [],
      checkboxes: [],
      enabled: EnabledType.DISABLED,
    },
    {
      name: 'IM Softphone',
      oneClickEnabled: true,
      customInputTexts: [
        {
          name: 'name',
          value: '',
          show: false,
          required: true,
        },
        {
          name: 'description',
          value: '',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'rerouting_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'common_device_configuration',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'common_phone_profile',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: true,
        },
        {
          name: 'calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'aar_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'user_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'network_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'location',
          defaultOption: 'Hub_None',
          possibleOptions: ['Hub_None'],
          show: false,
          required: true,
        },
        {
          name: 'user_locale',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'privacy',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'device_mobility_mode',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line_for_vm',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'built_in_bridge',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'sip_profile_name',
          defaultOption: 'Standard SIP Profile',
          possibleOptions: ['Standard SIP Profile'],
          show: false,
          required: true,
        },
        {
          name: 'subscribe_css',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'media_resource_group_list',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
      ],
      selects: [
        {
          name: 'devicepool',
          value: '',
        },
        {
          name: 'preferred_security_profile_mode',
          value: '',
        },
      ],
      customMultiSelects: [
        {
          name: 'associated_end_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
        {
          name: 'associated_app_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
      ],
      multiSelects: [],
      customCheckboxes: [],
      checkboxes: [],
      enabled: EnabledType.DISABLED,
    },
    {
      name: 'IPhone',
      oneClickEnabled: true,
      customInputTexts: [
        {
          name: 'name',
          value: 'TCT{{toUpperCase username}}',
          show: false,
          required: true,
        },
        {
          name: 'description',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'cisco_support_field',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'mobility_identity_name',
          value: '',
          show: true,
          required: true,
        },
        {
          name: 'stop_ringing_phone_delay',
          value: '19',
          show: false,
          required: true,
        },
        {
          name: 'Emergency Numbers',
          value: '999,911,112,113',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'rerouting_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'common_device_configuration',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'softkey_template',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'common_phone_profile',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: true,
        },
        {
          name: 'calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'aar_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'user_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'network_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'location',
          defaultOption: 'Hub_None',
          possibleOptions: ['Hub_None'],
          show: false,
          required: true,
        },
        {
          name: 'user_locale',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'privacy',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'device_mobility_mode',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line_for_vm',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'built_in_bridge',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'sip_profile_name',
          defaultOption: 'Standard SIP Profile',
          possibleOptions: ['Standard SIP Profile'],
          show: false,
          required: true,
        },
        {
          name: 'subscribe_css',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'media_resource_group_list',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
      ],
      selects: [
        {
          name: 'devicepool',
          value: '',
        },
        {
          name: 'preferred_security_profile_mode',
          value: '',
        },
      ],
      customMultiSelects: [
        {
          name: 'associated_end_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
        {
          name: 'associated_app_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
      ],
      multiSelects: [],
      customCheckboxes: [],
      checkboxes: [
        {
          name: 'mobility_identity_management',
          value: false,
        },
      ],
      enabled: EnabledType.DISABLED,
    },
    {
      name: 'Line Features',
      oneClickEnabled: false,
      customInputTexts: [
        {
          name: 'user_primary_extension_display_caller_id',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'user_primary_extension_ascii_display_caller_id',
          value: '',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'idle_ring_setting',
          defaultOption: 'Use System Default',
          possibleOptions: ['Use System Default'],
          show: false,
          required: true,
        },
        {
          name: 'active_ring_setting',
          defaultOption: 'Use System Default',
          possibleOptions: ['Use System Default'],
          show: false,
          required: true,
        },
        {
          name: 'call_recording_option',
          defaultOption: 'Call Recording Disabled',
          possibleOptions: ['Call Recording Disabled'],
          show: false,
          required: true,
        },
        {
          name: 'call_recording_profile',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'call_recording_media_source',
          defaultOption: 'Gateway Preferred',
          possibleOptions: ['Gateway Preferred'],
          show: false,
          required: true,
        },
      ],
      selects: [],
      customMultiSelects: [],
      multiSelects: [],
      customCheckboxes: [],
      checkboxes: [],
      enabled: EnabledType.NOT_APPLICABLE,
    },
    {
      name: 'Single Number Reach',
      oneClickEnabled: true,
      customInputTexts: [
        {
          name: 'profile_name',
          value: '',
          show: false,
          required: true,
        },
        {
          name: 'profile_description',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'destination_name',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'destination_number',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'delay_before_ringing_in_seconds',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'answer_too_soon_timer_in_seconds',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'answer_too_late_timer_in_seconds',
          value: '',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'user_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'network_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'privacy',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'aar_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'rerouting_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
      ],
      selects: [
        {
          name: 'devicepool',
          value: '',
        },
      ],
      customMultiSelects: [],
      multiSelects: [],
      customCheckboxes: [],
      checkboxes: [
        {
          name: 'snr_destination',
          value: false,
        },
      ],
      enabled: EnabledType.DISABLED,
    },
    {
      name: 'Tablet',
      oneClickEnabled: true,
      customInputTexts: [
        {
          name: 'name',
          value: 'TAB{{toUpperCase username}}',
          show: false,
          required: true,
        },
        {
          name: 'description',
          value: '',
          show: false,
          required: false,
        },
        {
          name: 'cisco_support_field',
          value: '',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'rerouting_calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'common_device_configuration',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'softkey_template',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'common_phone_profile',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: true,
        },
        {
          name: 'calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'user_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'network_moh_audio_source',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'location',
          defaultOption: 'Hub_None',
          possibleOptions: ['Hub_None'],
          show: false,
          required: true,
        },
        {
          name: 'user_locale',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'privacy',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'device_mobility_mode',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'always_use_prime_line_for_vm',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'built_in_bridge',
          defaultOption: 'Default',
          possibleOptions: ['Default'],
          show: false,
          required: true,
        },
        {
          name: 'sip_profile_name',
          defaultOption: 'Standard SIP Profile',
          possibleOptions: ['Standard SIP Profile'],
          show: false,
          required: true,
        },
        {
          name: 'subscribe_css',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
        {
          name: 'media_resource_group_list',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
      ],
      selects: [
        {
          name: 'devicepool',
          value: '',
        },
        {
          name: 'preferred_security_profile_mode',
          value: '',
        },
      ],
      customMultiSelects: [
        {
          name: 'associated_end_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
        {
          name: 'associated_app_users',
          possibleOptions: [],
          defaultOptions: [],
          show: false,
        },
      ],
      multiSelects: [],
      customCheckboxes: [],
      checkboxes: [],
      enabled: EnabledType.DISABLED,
    },
    {
      name: 'Translation Pattern',
      oneClickEnabled: false,
      customInputTexts: [
        {
          name: 'user_primary_extension_description',
          value: '',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'calling_search_space',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: false,
        },
      ],
      selects: [],
      customMultiSelects: [],
      multiSelects: [],
      customCheckboxes: [],
      checkboxes: [],
      enabled: EnabledType.NOT_APPLICABLE,
    },
    {
      name: 'Voicemail',
      oneClickEnabled: true,
      customInputTexts: [
        {
          name: 'display_name',
          value: '{{ firstName }} {{ lastName }}',
          show: false,
          required: false,
        },
        {
          name: 'smtp_notification_device',
          value: '',
          show: false,
          required: false,
        },
      ],
      customSelects: [
        {
          name: 'voicemail_template',
          defaultOption: '',
          possibleOptions: [],
          show: false,
          required: true,
        },
      ],
      selects: [
        {
          name: 'unity_server',
          value: '',
        },
      ],
      customMultiSelects: [],
      multiSelects: [],
      customCheckboxes: [],
      checkboxes: [],
      enabled: EnabledType.DISABLED,
    },
  ],
};

export default blankSite;
