import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WebexEndUser } from '../models/generated/smacsModels';
import { Nvp } from '../models/nvp';
import { AuditHeaderService } from '../services/audit-header.service';

@Injectable()
export class WebexUserResource {
  protected _baseUrl = '/services/cisco/macs/webex-users/';

  constructor(private _auditHeaderService: AuditHeaderService, protected http: HttpClient) {}

  get(email: string): Observable<WebexEndUser> {
    return this.http.get<WebexEndUser>(this._baseUrl + `${encodeURIComponent(email)}` + '/calling');
  }

  post(webexUser: WebexEndUser, auditTags?: Nvp[]): Observable<void> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.post<void>(
      this._baseUrl + `${encodeURIComponent(webexUser.email)}` + '/calling',
      webexUser,
      options
    );
  }

  put(webexUser: WebexEndUser, email: string, auditTags?: Nvp[]): Observable<void> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.put<void>(this._baseUrl + `${encodeURIComponent(email)}` + '/calling', webexUser, options);
  }

  delete(email: string, auditTags?: Nvp[]): Observable<void> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.delete<void>(this._baseUrl + `${encodeURIComponent(email)}` + '/calling', options);
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this._auditHeaderService.buildHeader(auditTags) } : {};
  }
}
