export const serviceFieldLabelMapping: Record<string, string> = {
  aar_calling_search_space: 'tkey;site_management.site.section.field.aar_calling_search_space.text',
  active_ring_setting: 'tkey;site_management.site.section.field.active_ring_setting.text',
  user_primary_extension_alerting_name: 'tkey;site_management.site.section.field.alerting_name.text',
  always_use_prime_line: 'tkey;site_management.site.section.field.always_use_prime_line.text',
  always_use_prime_line_for_vm: 'tkey;site_management.site.section.field.always_use_prime_line_for_vm.text',
  associated_app_users: 'tkey;site_management.site.section.field.associated_app_users.text',
  associated_app_users_cti: 'tkey;site_management.site.section.field.associated_app_users_cti.text',
  associated_end_users: 'tkey;site_management.site.section.field.associated_end_users.text',
  associated_end_users_cti: 'tkey;site_management.site.section.field.associated_end_users_cti.text',
  built_in_bridge: 'tkey;site_management.site.section.field.built_in_bridge.text',
  call_forward_calling_search_space: 'tkey;site_management.site.section.field.call_forward.calling_search_space.text',
  call_pickup_group: 'tkey;site_management.site.section.field.call_pickup_group.text',
  call_recording_media_source: 'tkey;site_management.site.section.field.call_recording_media_source.text',
  call_recording_option: 'tkey;site_management.site.section.field.call_recording_option.text',
  call_recording_profile: 'tkey;site_management.site.section.field.call_recording_profile.text',
  calling_search_space: 'tkey;site_management.site.section.field.calling_search_space.text',
  cisco_support_field: 'tkey;site_management.site.section.field.cisco_support_field.text',
  common_device_configuration: 'tkey;site_management.site.section.field.common_device_configuration.text',
  common_phone_profile: 'tkey;site_management.site.section.field.common_phone_profile.text',
  cross_cluster_css: 'tkey;site_management.site.section.field.cross_cluster_css.text',
  description: 'tkey;site_management.site.section.field.description.text',
  destination_name: 'tkey;site_management.site.section.field.destination_name.text',
  delay_before_ring: 'tkey;snr.snrdestination.delayring.label',
  destination_name_for_microsoft_teams: 'tkey;site_management.site.section.snr.teams_destination_name.label',
  destination_number_for_microsoft_teams: 'tkey;site_management.site.section.snr.teams_destination_number.label',
  snr_destination_for_microsoft_teams: 'tkey;site_management.site.section.snr.teams_snr_destination.label',
  devicepool: 'tkey;site_management.site.section.device_pool.label',
  device_mobility_mode: 'tkey;site_management.site.section.field.device_mobility_mode.text',
  directory_uri: 'tkey;site_management.site.section.field.directory_uri.text',
  disable_speaker_phone: 'tkey;site_management.site.section.field.disable_speaker_phone.text',
  display_name: 'tkey;site_management.site.section.field.display_name.text',
  'Emergency Numbers': 'tkey;site_management.site.section.field.emergency_numbers',
  end_user_group: 'tkey;site_management.site.section.end_user_group.label',
  ext_data_location_auth_server: 'tkey;site_management.site.section.field.ext_data_location_auth_server.text',
  ext_data_location_secure_auth_url: 'tkey;site_management.site.section.field.ext_data_location_secure_auth_url.text',
  external_mask: 'tkey;site_management.site.section.field.external_phone_number_mask.text',
  feature_control_policy: 'tkey;site_management.site.section.field.feature_control_policy.text',
  idle_ring_setting: 'tkey;site_management.site.section.field.idle_ring_setting.text',
  line_text_label: 'tkey;site_management.site.section.field.line_text_label.text',
  location: 'tkey;site_management.site.section.field.location.text',
  media_resource_group_list: 'tkey;site_management.site.section.field.media_resource_group_list.text',
  mobility_identity_name: 'tkey;site_management.site.section.field.mobility_identity.text',
  mobility_identity_management: 'tkey;site_management.site.section.field.mobility_identity_management.text',
  mobility_identity_destination: 'tkey;site_management.site.section.field.mobility_identity_destination.text',
  name: 'tkey;site_management.site.section.field.name.text',
  network_moh_audio_source: 'tkey;site_management.site.section.field.network_moh_audio_source.text',
  call_forward_no_answer_ring_duration: 'tkey;site_management.site.section.field.no_answer_ring_duration.text',
  no_voicemail_profile: 'tkey;site_management.site.section.field.no_voicemail_profile.text',
  phone_service: 'tkey;site_management.site.section.phone_services.label',
  phone_model: 'tkey;site_management.site.section.field.phone_model.text',
  preferred_protocol: 'tkey;site_management.site.section.field.preferred_protocol.label',
  preferred_security_profile_mode: 'tkey;site_management.site.section.field.preferred_security_profile_mode.text',
  privacy: 'tkey;site_management.site.section.field.privacy.text',
  profile_description: 'tkey;site_management.site.section.field.profile_description.text',
  profile_name: 'tkey;site_management.site.section.field.profile_name.text',
  public_phone_description: 'tkey;site_management.site.section.field.public_phone_description.text',
  rerouting_calling_search_space: 'tkey;site_management.site.section.field.rerouting_calling_search_space.text',
  route_partition: 'tkey;site_management.site.section.field.route_partition.text',
  call_forward_secondary_calling_search_space_for_forward_all:
    'tkey;site_management.site.section.field.secondary_calling_search_space_for_forward_all.text',
  sip_profile_name: 'tkey;site_management.site.section.field.sip_profile_name.text',
  smtp_notification_device: 'tkey;site_management.site.section.field.smtp_notification_device.text',
  softkey_template: 'tkey;site_management.site.section.field.softkey_template.text',
  stop_ringing_phone_delay: 'tkey;site_management.site.section.field.stop_ringing_phone_delay',
  subscribe_css: 'tkey;site_management.site.section.field.subscribe_css.text',
  uc_service_profile: 'tkey;site_management.site.section.end_user.uc_service_profile.text',
  uc_service_profile_without_voicemail:
    'tkey;site_management.site.section.end_user.uc_service_profile_without_voicemail.text',
  unity_server: 'tkey;site_management.site.section.field.unity_server.label',
  user_locale: 'tkey;site_management.site.section.field.user_locale.text',
  user_moh_audio_source: 'tkey;site_management.site.section.field.user_moh_audio_source.text',
  user_phone_description: 'tkey;site_management.site.section.field.user_phone_description.text',
  user_primary_extension_ascii_display_caller_id:
    'tkey;site_management.site.section.field.user_primary_extension_ascii_display_caller_id.text',
  user_primary_extension_description: 'tkey;site_management.site.section.field.user_primary_extension_description.text',
  user_primary_extension_display_caller_id:
    'tkey;site_management.site.section.field.user_primary_extension_display_caller_id.text',
  voicemail_profile: 'tkey;site_management.site.section.field.voicemail_profile.text',
  voicemail_template: 'tkey;site_management.site.section.field.voicemail_template.text',
};

export function getServiceFieldNameTkey(condition: string): string {
  return serviceFieldLabelMapping[condition] || condition;
}

export const serviceSettingNameMapping: Record<string, string> = {
  Android: 'tkey;site_management.site.section.android.title',
  CIPC: 'tkey;site_management.site.section.cipc.title',
  'Desk Phone': 'tkey;site_management.site.section.desk_phone.title',
  'Directory Number': 'tkey;site_management.site.section.directory_number.title',
  'End User': 'tkey;site_management.site.section.end_user.title',
  'Extension Mobility': 'tkey;site_management.site.section.extension_mobility.title',
  'IM Presence': 'tkey;site_management.site.section.im_presence.title',
  'IM Softphone': 'tkey;site_management.site.section.im_softphone.title',
  IPhone: 'tkey;site_management.site.section.iphone.title',
  'Line Features': 'tkey;site_management.site.section.line_features.title',
  'Single Number Reach': 'tkey;site_management.site.section.single_number_reach.title',
  Tablet: 'tkey;site_management.site.section.tablet.title',
  'Translation Pattern': 'tkey;site_management.site.section.translation_pattern.title',
  Voicemail: 'tkey;site_management.site.section.voicemail.title',
};

export function getServiceNameTkey(condition: string): string {
  return serviceSettingNameMapping[condition] || condition;
}
