<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div [hidden]="isLoading">
  <div class="card mb-4">
    <div class="card-header">{{ 'tkey;admin.webex_authentication.application_info.header' | translate }}</div>
    <div class="card-body">
      <smacs-text fieldId="clientId" [disabled]="clientIdExists"></smacs-text>
      <smacs-text #clientSecret fieldId="clientSecret" [disabled]="clientIdExists"></smacs-text>
      <smacs-text fieldId="redirectUri">
        <smacs-button
          [dataAutomation]="'copy-button'"
          [buttonStyle]="buttonStyles.DEFAULT"
          [label]="'tkey;admin.webex_authentication.copy_button'"
          [cssClass]="'btn-input-copy'"
          [cdkCopyToClipboard]="callingSettings?.redirectUri"
          [ngbTooltip]="'tkey;admin.webex_authentication.copy_button_clicked' | translate"
          [disableTooltip]="disableCopiedUriTooltip"
          [icon]="smacsIcons.FILE"
          (clicked)="onCopyUriClick()"
        ></smacs-button>
      </smacs-text>
    </div>
  </div>

  <div class="card">
    <div class="card-header">{{ 'tkey;admin.webex_authentication.service_account_info.header' | translate }}</div>
    <div class="card-body">
      <div *ngIf="!clientIdExists">
        <p>{{ 'tkey;admin.webex_authentication.no_client_or_secret' | translate }}</p>
      </div>
      <div *ngIf="clientIdExists" [ngSwitch]="callingStatus?.status">
        <div *ngSwitchCase="WebexCallingStatusEnum.OK">
          <p
            data-automation="service-acct-text"
            class="mb-0"
            [innerHTML]="
              'tkey;admin.webex_authentication.connected_text'
                | translate
                  : {
                      currentUserId: callingStatus?.currentUserId,
                      accessTokenExpirationDate: formattedExpirationDate
                    }
            "
          ></p>
          <a
            data-automation="refresh-tokens"
            *ngIf="!isRefreshing"
            class="strong refresh"
            (click)="onRefreshTokensClick()"
          >
            <i [attr.class]="smacsIcons.REFRESH"></i>
            {{ 'tkey;admin.webex_authentication.refresh_tokens_link' | translate }}
          </a>

          <a *ngIf="isRefreshing" class="strong text-warning disabled refresh-disabled">
            <i [attr.class]="smacsIcons.SAVING"></i>
            {{ 'tkey;admin.webex_authentication.refresh_tokens_link.refreshing' | translate }}
          </a>
        </div>

        <div *ngSwitchCase="WebexCallingStatusEnum.ERROR">
          <p data-automation="service-acct-text">{{ 'tkey;admin.webex_authentication.error' | translate }}</p>
          <div class="d-flex justify-content-center">
            <smacs-button
              [dataAutomation]="'webex-login-button'"
              [label]="'tkey;admin.webex_authentication.login_button_text'"
              [buttonStyle]="buttonStyles.PRIMARY"
              (clicked)="redirectToWebex()"
            ></smacs-button>
          </div>
        </div>

        <div *ngSwitchDefault>
          <p data-automation="service-acct-text">{{ 'tkey;admin.webex_authentication.not_configured' | translate }}</p>
          <div class="d-flex justify-content-center">
            <smacs-button
              [dataAutomation]="'webex-login-button'"
              [label]="'tkey;admin.webex_authentication.login_button_text'"
              [buttonStyle]="buttonStyles.PRIMARY"
              (clicked)="redirectToWebex()"
            ></smacs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
