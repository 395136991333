import { Injectable } from '@angular/core';

@Injectable()
export class AsciiService {
  /**
   * @param {string} value
   */
  stripAccents(value: string) {
    return value && value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
