<div
  [hidden]="state.hidden && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
  class="form-check form-group row"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted,
    'form-switch': checkboxType === htmlCheckboxType.SWITCH,
    'switch-sm': checkboxType === htmlCheckboxType.SWITCH && size === htmlSwitchSize.SM,
    'switch-lg': checkboxType === htmlCheckboxType.SWITCH && size === htmlSwitchSize.LG,
    'smacs-forms-feedback': showValidation,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage,
    'mb-3': textSize !== labelTextSize.LG
  }"
>
  <ng-container *ngIf="checkboxType === htmlCheckboxType.CHECKBOX">
    <label
      [class]="'smacs-forms-label form-check-label col-form-label ' + state.columnClasses?.label"
      [attr.data-automation]="'smacs-checkbox-label'"
      [attr.for]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
      (mouseover)="t.open()"
      (mouseleave)="t.close()"
    >
      {{ getLabel() | translate }}
    </label>
    <div [class]="'checkbox-wrapper ' + state.columnClasses?.input">
      <div
        [disableTooltip]="!state.disabled"
        [ngbTooltip]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"
        [container]="'body'"
        class="me-auto"
        #t="ngbTooltip"
      >
        <input
          type="checkbox"
          class="form-check-input"
          [ngClass]="{ 'indeterminate-input': indeterminate }"
          [attr.id]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
          [attr.data-automation]="'smacs-checkbox-input'"
          [(ngModel)]="fieldData"
          #ngModelDir="ngModel"
          (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
          [disabled]="state.disabled"
        />
      </div>

      <div class="smacs-forms-feedback-area">
        <div class="form-text small" *ngIf="isAutogenerated" data-automation="smacs-forms-autogeneration-message">
          {{ config.autogeneration?.generatedMsg | translate }}
        </div>

        <div
          class="smacs-forms-validation-message"
          data-automation="smacs-forms-validation-message"
          [hidden]="!showValidation && !isFormSubmitted"
        >
          {{ validationMessage?.content | translate : validationMessage?.params }}
        </div>

        <div
          class="smacs-forms-validation-message text-warning"
          [ngClass]="{ 'd-inline': config.autogeneration?.inline }"
          data-automation="smacs-forms-warning-message"
          [hidden]="!misconfigurationFeedbackMessage"
        >
          {{ misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params }}
        </div>

        <smacs-button
          [dataAutomation]="'smacs-forms-autogeneration-button'"
          [hidden]="isAutogenerateLinkHidden()"
          (clicked)="autogenerateValue()"
          [buttonStyle]="buttonStyles.LINK"
          [label]="config.autogeneration?.linkLabel"
          [labelCssClass]="'small strong'"
        ></smacs-button>
      </div>
      <div *ngIf="config.helpText" class="custom-control__help-text form-text text-muted small">
        {{ getHelpText() | translate }}
      </div>
    </div>
  </ng-container>

  <div *ngIf="checkboxType === htmlCheckboxType.LEFT_ALIGNED_CHECKBOX">
    <input
      type="checkbox"
      class="form-check-input"
      [ngClass]="{ 'indeterminate-input': indeterminate, 'large-size': textSize === labelTextSize.LG }"
      [attr.id]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
      [attr.data-automation]="'smacs-checkbox-input'"
      [(ngModel)]="fieldData"
      #ngModelDir="ngModel"
      (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
      [disabled]="state.disabled"
    />
    <ng-template #disabledToolTipContent>
      <span [innerHTML]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"></span>
    </ng-template>
    <div class="checkbox-wrapper--left-aligned">
      <label
        class="form-check-label col-form-label"
        [ngClass]="{
          'not-allowed': isAlwaysRequired,
          'fs-5 pb-0 pt-0 font-light': textSize === labelTextSize.LG,
        }"
        [attr.data-automation]="'smacs-checkbox-label'"
        [attr.for]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
        [ngbTooltip]="disabledToolTipContent"
        [disableTooltip]="!state.disabled || !getDisabledTooltip()"
      >
        <ng-template #labelTip>
          <span [innerHTML]="config.labelToolTipText | translate"></span>
        </ng-template>
        <span [ngbTooltip]="config.labelToolTipText ? labelTip : null">
          <span [innerHTML]="getLabel() | translate"></span>
          <i *ngIf="config.labelToolTipIconClass" class="{{ config.labelToolTipIconClass }} ms-1"></i>
        </span>
      </label>
      <div class="smacs-forms-feedback-area position-static">
        <span
          *ngIf="config.helpText"
          class="form-text text-muted small"
          [ngStyle]="{ 'margin-left': textSize === labelTextSize.LG ? '-20px' : '0px' }"
          [innerHTML]="getHelpText() | translate"
        ></span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="checkboxType === htmlCheckboxType.SWITCH">
    <label
      *ngIf="!isMenuToggle"
      [class]="'smacs-forms-label form-check-label col-form-label position-relative ' + state.columnClasses?.label"
      [attr.data-automation]="'smacs-checkbox-label'"
      [attr.for]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
    >
      <span [ngbTooltip]="config.labelToolTipText | translate">
        <span [innerHTML]="getLabel() | translate"></span>
        <i *ngIf="config.labelToolTipIconClass" class="{{ config.labelToolTipIconClass }} ms-1 tooltip-icon"></i>
      </span>
    </label>
    <div [class]="'checkbox-wrapper ' + state.columnClasses?.input">
      <div
        [ngbTooltip]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"
        [disableTooltip]="!state.disabled"
        [container]="'body'"
        class="me-auto"
      >
        <input
          type="checkbox"
          class="form-check-input"
          [attr.id]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
          [attr.data-automation]="'smacs-checkbox-input'"
          [(ngModel)]="fieldData"
          #ngModelDir="ngModel"
          (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
          [disabled]="state.disabled"
        />
      </div>
      <div class="smacs-forms-feedback-area pt-1">
        <div class="form-text small" *ngIf="isAutogenerated" data-automation="smacs-forms-autogeneration-message">
          {{ config.autogeneration?.generatedMsg | translate }}
        </div>

        <div
          class="smacs-forms-validation-message"
          data-automation="smacs-forms-validation-message"
          [hidden]="!showValidation && !isFormSubmitted"
        >
          {{ validationMessage?.content | translate : validationMessage?.params }}
        </div>

        <div
          class="smacs-forms-validation-message text-warning"
          [ngClass]="{ 'd-inline': config.autogeneration?.inline }"
          data-automation="smacs-forms-warning-message"
          [hidden]="!misconfigurationFeedbackMessage"
        >
          {{ misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params }}
        </div>

        <smacs-button
          [dataAutomation]="'smacs-forms-autogeneration-button'"
          [hidden]="isAutogenerateLinkHidden()"
          (clicked)="autogenerateValue()"
          [buttonStyle]="buttonStyles.LINK"
          [label]="config.autogeneration?.linkLabel"
          [labelCssClass]="'small strong'"
        ></smacs-button>

        <span *ngIf="config.helpText" class="form-text text-muted small" [innerHTML]="getHelpText() | translate"></span>
      </div>
    </div>
  </ng-container>
</div>
