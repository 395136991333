import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ButtonStyles } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { Observable, of, Subscription } from 'rxjs';
import { EndUserSearchResource } from '../../../../shared/resources/end-user-search.resource';
import { EntityTable, EntityTableContentRow } from '../../../../shared/entity-table/entity-table.models';
import { EndUserRef, EndUserResult } from '../../../../shared/models/generated/smacsModels';
import { ActivatedRoute, Router } from '@angular/router';
import { sortBy } from 'lodash';

export enum ReassignTypes {
  PUBLIC_TO_USER = 'PUBLIC_TO_USER',
  USER_TO_USER = 'USER_TO_USER',
}

export interface ReassignSearch {
  searchTerm: string;
}

@Component({
  selector: 'smacs-reassign-user-search',
  templateUrl: './reassign-user-search.component.html',
})
export class ReassignUserSearchComponent
  extends SmacsFormAbstractDirective<ReassignSearch>
  implements OnInit, OnDestroy
{
  @Input() reassignType: ReassignTypes;
  @Input() serverId: number;
  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];
  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;
  isLoading = true;

  formConfig = {
    fields: {
      searchTerm: {
        dataAutomation: 'reassign-search-input',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'tkey;global.search.placeholder.text',
        }),
      },
    },
  } as SmacsFormConfig;

  protected readonly ReassignTypes = ReassignTypes;
  private _userSearchResults: EndUserResult[];
  private _subscriptions = new Subscription();
  private username: string;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _endUserSearchResource: EndUserSearchResource,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    const routeSub = this._route.queryParams.subscribe((params) => {
      this.username = this._route.snapshot.params.username;
      if (!this.formData.searchTerm && params.search) {
        this.entitySource.next({ searchTerm: params.search });
        this.formData.searchTerm = params.search;
        this.searchForUser();
        this.isSubmitting = false;
      } else if (params.search) {
        this.searchForUser();
      }
      this.isLoading = false;
    });
    this._subscriptions.add(routeSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onSearchClicked() {
    this._router
      .navigate([], {
        queryParams: {
          search: this.formData.searchTerm,
        },
      })
      .then(() => this.searchForUser());
  }

  searchForUser() {
    this.isSubmitting = true;
    this._endUserSearchResource.searchByQ(this.formData.searchTerm, this.serverId, 100).subscribe((results) => {
      this._userSearchResults = sortBy(results, (userResult) => userResult.ref.username.toLowerCase());
      this.isSubmitting = false;
      this._createTable();
      this._generateTableRows();
    });
  }

  routeToReassignUser(endUserRef: EndUserRef) {
    this.smacsFormStateService.setIsFormDirty(false);
    this._router.navigate([`${endUserRef.username}`], { relativeTo: this._route, queryParams: {} });
  }

  onCancelClicked() {
    this._router.navigate(['../'], { relativeTo: this._route, replaceUrl: false });
  }

  onEnterSearchPressed() {
    if (this.formData.searchTerm?.length > 1 && !this.isSubmitting) {
      this.searchForUser();
    }
  }

  protected submit(): Observable<any> {
    return of(null);
  }

  private _createTable() {
    this.table = {
      columns: [
        {
          columnId: 'displayName',
          cssColumnSize: 'col-sm-6',
          label: 'tkey;pages.details.transfer.to.user.search.display.name',
        },
        {
          columnId: 'userId',
          cssColumnSize: 'col-sm-5',
          label: 'tkey;pages.details.transfer.to.user.search.userid',
        },
      ],
      resultsMessage: 'tkey;operations.menu.reassign.search.no_results',
      hasActions: true,
    };
  }

  private _generateTableRows() {
    this.tableRows = this._userSearchResults.map(
      (endUserResult): EntityTableContentRow => ({
        content: {
          displayName: `${endUserResult.ref.firstName} ${endUserResult.ref.lastName}`,
          userId: endUserResult.ref.username,
        },
        actions: [
          {
            buttonStyle: ButtonStyles.PRIMARY,
            dataAutomation: 'reassign-user-select',
            icon: SmacsIcons.NEXT,
            onClick: () => this.routeToReassignUser(endUserResult.ref),
            isDisabled: this.username === endUserResult.ref.username,
            tooltip:
              this.username === endUserResult.ref.username
                ? 'tkey;pages.details.transfer.to.user.has.services.text'
                : null,
          },
        ],
      })
    );
  }
}
