import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MicrosoftDialPlanExceptionGroup, RangeType } from '../../../../shared/models/generated/smacsModels';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { ButtonStyles } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../shared/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { Observable, Subscriber, Subscription, zip } from 'rxjs';
import { MicrosoftDialPlanExceptionManagementEditFormComponent } from './dial-plan-exception-management-edit-form/dial-plan-exception-management-edit-form.component';
import { SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { isEqual } from 'lodash';
import { MsDialPlanExceptionGroupsContext } from '../../../contexts/ms-dial-plan-exception-groups.context';

@Component({
  selector: 'app-microsoft-dial-plan-exception-management-edit',
  templateUrl: './dial-plan-exception-management-edit.component.html',
  styleUrls: ['../../../admin-page.scss'],
})
export class MicrosoftDialPlanExceptionManagementEditComponent implements OnInit, OnDestroy {
  @ViewChild(MicrosoftDialPlanExceptionManagementEditFormComponent)
  childFormComponent: MicrosoftDialPlanExceptionManagementEditFormComponent;

  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;
  isLoading = true;
  exceptionGroup = {
    name: '',
    exceptionRanges: [{ start: '', end: '' }],
    rangeType: RangeType.DID,
  } as MicrosoftDialPlanExceptionGroup;
  exceptionGroups: MicrosoftDialPlanExceptionGroup[];
  groupId: number;
  private _isValidFromDynamicValidator = SmacsFormsValidationState.VALID;
  private _subscriptions = new Subscription();

  constructor(
    private _breadcrumbsService: BreadcrumbsService,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _toastService: ToastService,
    private _activatedRoute: ActivatedRoute,
    private _bottomNavService: BottomNavService,
    private _router: Router,
    private _msDialPlanExceptionGroupsContext: MsDialPlanExceptionGroupsContext
  ) {}

  ngOnInit() {
    this.groupId = parseInt(this._activatedRoute.snapshot.params.id) || null;
    this._setBreadcrumb('');

    const msDialplanExceptionSub = zip(
      this._msDialPlanExceptionGroupsContext.state$,
      this._msDialPlanExceptionGroupsContext.getDynamicNonTeamsDialPlaneExceptionGroups()
    ).subscribe((data) => {
      const groups = data.flat();
      this.exceptionGroups = groups;
      if (this.groupId) {
        this.exceptionGroup = groups.find((g: MicrosoftDialPlanExceptionGroup) => Number(g.id) === this.groupId);
      }
      this._setBreadcrumb(this.exceptionGroup?.name);
      this._initBottomNav();
      this.isLoading = false;
    });
    this._subscriptions.add(msDialplanExceptionSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
      })
    );
  }

  onFormUpdate(data: SmacsFormsUpdate<MicrosoftDialPlanExceptionGroup>) {
    if (!isEqual(data.new, data.old)) {
      this.exceptionGroup = data.new;

      if (!isEqual(data.new.name, data.old?.name)) {
        this._setBreadcrumb(data.new.name);
      }
    }
  }

  onValidationFromDynamicValidator(validationState: SmacsFormsValidationState) {
    this._isValidFromDynamicValidator = validationState;
  }

  private _initBottomNav() {
    const buttons = [
      {
        id: 'bottom-nav-cancel-button',
        dataAutomation: 'bottom-nav-cancel-button',
        label: 'tkey;global.button.cancel.text',
        buttonClass: this.buttonStyles.DEFAULT,
        cb: () => {
          this._router.navigate(['/admin/microsoft/dial-plan-exception-management']);
        },
      },
      {
        id: 'bottom-nav-save-button',
        dataAutomation: 'bottom-nav-save-button',
        label: 'tkey;global.button.save.text',
        icon: this.smacsIcons.OK,
        buttonClass: this.buttonStyles.PRIMARY,
        cb: () => this._onSaveClick(),
      },
    ];

    if (this.exceptionGroup?.id) {
      buttons.splice(1, 0, {
        id: 'bottom-nav-delete-button',
        dataAutomation: 'bottom-nav-delete-button',
        label: 'tkey;global.button.delete.text',
        icon: SmacsIcons.DELETE,
        buttonClass: ButtonStyles.DANGER,
        cb: () => {
          this._deleteClicked();
        },
      });
    }

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList([...buttons]));
  }

  private _setBreadcrumb(label: string) {
    this._breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;admin.ms_dial_plan_exception.title',
        url: '/admin/microsoft/dial-plan-exception-management',
        routerLink: true,
      },
      { label: label },
    ]);
  }

  private _canSubmitForms(): boolean {
    if (this.childFormComponent) {
      this.childFormComponent._validateAndSubmitSource.next(true);
      if (this.childFormComponent.extensionRangesDisplayForm) {
        this.childFormComponent.extensionRangesDisplayForm._validateAndSubmitSource.next(true);
        return true;
      }
    }
    return false;
  }

  private _isChildFormsValid(): boolean {
    const isChildFormValid = !!this.childFormComponent.isFormValid();
    const isChildFormRangeValid =
      !!this.childFormComponent.extensionRangesDisplayForm.isFormValid() &&
      this._isValidFromDynamicValidator === SmacsFormsValidationState.VALID;
    return isChildFormValid && isChildFormRangeValid;
  }

  private _onSaveClick() {
    this._bottomNavService.setBottomNavValidationError(false);

    const canSubmitForms = this._canSubmitForms();
    const isChildFormsValid = this._isChildFormsValid();

    if (!canSubmitForms || !isChildFormsValid) {
      this._bottomNavService.setBottomNavValidationError(true);
      return;
    }

    this._bottomNavService.setButtonPendingState('bottom-nav-save-button', true);
    this._bottomNavService.setButtonDisabledState('bottom-nav-cancel-button', true);
    this._bottomNavService.setButtonDisabledState('bottom-nav-delete-button', true);

    if (this.exceptionGroup.id) {
      this._msDialPlanExceptionGroupsContext.put(this.exceptionGroup).subscribe(() => this._afterSave());
    } else {
      this._msDialPlanExceptionGroupsContext.post(this.exceptionGroup).subscribe(() => this._afterSave());
    }
  }

  private _afterSave() {
    this._toastService.pushSaveToast(
      'tkey;admin.ms_dial_plan_exception.toast.title',
      this.exceptionGroup.name,
      this.smacsIcons.DIAL_PLAN_GROUP
    );
    this._router.navigate(['/admin/microsoft/dial-plan-exception-management']);
  }

  private _deleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        title: this._translateService.instant('tkey;admin.msdialplan.management.delete_modal.title'),
        promptBody: this._translateService.instant('tkey;admin.ms_dial_plan_exception.delete.message', {
          name: this.exceptionGroup.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteGroup(this.exceptionGroup);
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteGroup(group: MicrosoftDialPlanExceptionGroup): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._msDialPlanExceptionGroupsContext.delete(Number(group.id), group.name).subscribe(() => {
        this._router.navigate(['/admin/microsoft/dial-plan-exception-management']);
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }
}
