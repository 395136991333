<ng-template #multiEditTileDropdown>
  <div class="custom-popover-body p-1">
    <ng-content></ng-content>
    <div *ngIf="showAddButton" class="text-center">
      <i
        (mouseenter)="multiEditAddHover = true"
        (mouseleave)="multiEditAddHover = false"
        data-automation="add-deskphone-button"
        class="add-deskphone-button fa fa-plus-circle fa-2x animated rubberBand fast clickable-cursor"
        (click)="onMultiEditAddClicked()"
        [ngClass]="{ 'animated rubberBand fast text-primary': multiEditAddHover }"
      ></i>
    </div>
  </div>
</ng-template>

<div
  class="tile tile-info"
  data-automation="smacs-multi-tile"
  [ngbPopover]="multiEditTileDropdown"
  [disablePopover]="!isEnabled"
  [ngbTooltip]="tooltip | translate"
  [popoverClass]="'overflow-visible'"
  [autoClose]="'outside'"
  placement="bottom"
  (mouseenter)="isHoverTile = true"
  (mouseleave)="isHoverTile = false"
>
  <div class="tile-description" [ngClass]="{ muted: !isEnabled }">
    <div class="text-center">
      <p [ngClass]="'svg-icon-class'">
        <svg-icon [name]="icon" [svgStyle]="{ 'width.px': 40 }"></svg-icon>
      </p>
      <strong data-automation="service-tile-name" class="d-block">
        {{ serviceName | translate }}
      </strong>
      <div *ngIf="description" class="badge bg-light text-dark">
        {{ description | translate }}
      </div>
      <div>
        <i *ngIf="isHoverTile && isEnabled" class="fa fa-chevron-down fa-2x"></i>
      </div>
    </div>
  </div>
</div>
