import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { SystemHealthStatus } from '../models/generated/smacsModels';

@Injectable()
export class SystemStatusResource {
  constructor(private http: HttpClient) {}

  get(): Observable<SystemHealthStatus> {
    return this.http.get<SystemHealthStatus>('/services/system/info/system-health-status');
  }

  refresh(): Observable<void> {
    return this.http.post<void>('/services/system/info/system-health-status/refresh', {}).pipe(shareReplay());
  }
}
