import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Microsoft360View } from '../models/generated/smacsModels';

@Injectable()
export class Microsoft360ViewResource {
  constructor(protected http: HttpClient) {}

  get(userPrincipalName: string): Observable<Microsoft360View> {
    return this.http.get<Microsoft360View>(`/services/microsoft/views/360-view/${userPrincipalName}`);
  }
}
