import { Component, OnDestroy, OnInit } from '@angular/core';
import { LdapResource } from '../../../shared/resources/ldap.resource';
import { LdapConfiguration, LdapPermissionGroup, UiOptions } from '../../../shared/models/generated/smacsModels';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { CustomUiOptionsContext } from '../../../shared/contexts/custom-ui-options.context';

@Component({
  selector: 'smacs-admin-ldap-management',
  templateUrl: './admin-ldap-management.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class AdminLdapManagementComponent implements OnInit, OnDestroy {
  isLoading = true;
  ldapPermissionGroup: LdapPermissionGroup;
  ldapConfiguration: LdapConfiguration;
  isSaved = new Subject<void>();
  isProxyServerEnabled = false;

  private _subscriptions = new Subscription();

  constructor(
    private ldapResource: LdapResource,
    private breadcrumbsService: BreadcrumbsService,
    private _customUiOptionsContext: CustomUiOptionsContext
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.ldap.title' }]);
    const uiOptionsSub = this._customUiOptionsContext.state$.subscribe((uiOptions: UiOptions) => {
      this.isProxyServerEnabled = uiOptions.proxyServerOnlyModeEnabled;
    });
    this._subscriptions.add(uiOptionsSub);
    forkJoin([this.ldapResource.getLdapConfiguration(), this.ldapResource.getLdapPermissionGroup()]).subscribe(
      (data: [LdapConfiguration, LdapPermissionGroup]) => {
        this.ldapConfiguration = {
          ...data[0],
          readTimeout: data[0].readTimeout / 1000,
          connectTimeout: data[0].connectTimeout / 1000,
        };
        // If loaded with no configurations for server, default to LDAPS port 636
        if (this.ldapConfiguration.urls.length === 0) {
          this.ldapConfiguration = {
            ...this.ldapConfiguration,
            urls: ['ldaps://:636'],
          };
        }
        this.ldapPermissionGroup = data[1];
        this.isLoading = false;
      }
    );
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }
}
