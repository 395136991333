<app-loading-block [hidden]="!isLoading"></app-loading-block>
<smacs-microsoft-teams-calling-dial-plan-selector-form
  *ngIf="!isLoading"
  [microsoftDialPlanGroups]="microsoftDialPlanGroups"
  [upn]="upn"
  [teamsLine]="teamsLine"
  [voicePolicies]="voicePolicies"
  [isZpcSyncWarningPresent]="isZpcSyncWarningPresent"
  [isResourceAccount]="isResourceAccount"
  [ms360View]="ms360View"
  [sharedCallingPolicies]="sharedCallingPolicies"
></smacs-microsoft-teams-calling-dial-plan-selector-form>
