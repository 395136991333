import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { AuditSettings } from '../../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { AuditSettingsContext } from '../../contexts/audit-settings.context';

@Component({
  selector: 'smacs-audit-settings',
  templateUrl: './audit-settings.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class AuditSettingsComponent extends SmacsFormAbstractDirective<AuditSettings> implements OnInit, OnDestroy {
  isLoading = true;
  smacsIcons = SmacsIcons;

  formConfig = {
    fields: {
      daysToKeepAuditRecords: {
        required: true,
        dataAutomation: 'days-to-keep-audit-records',
        label: 'tkey;admin.system.cisco_general_settings.audit_days_to_keep.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        validation: [
          {
            validator: (val: number) =>
              val >= 1 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: 'tkey;admin.audits.logs.audit_days_to_keep.positive.error.text',
          },
        ],
        options: {
          content: 'tkey;admin.system.cisco_general_settings.audit_days_to_keep.content',
        },
      },
    },
  };

  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private breadcrumbsService: BreadcrumbsService,
    private bottomNavService: BottomNavService,
    private toastService: ToastService,
    private auditSettingsContext: AuditSettingsContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.audits.settings.title' }]);

    const auditSettingsSub = this.auditSettingsContext.state$.subscribe((settings) => {
      this.entitySource.next(settings);
      this.isLoading = false;
    });
    this._subscriptions.add(auditSettingsSub);

    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'saveAuditSettings',
          dataAutomation: 'save-audit-settings-button',
          label: 'tkey;admin.ui.save',
          buttonClass: ButtonStyles.PRIMARY,
          state: {
            pending: false,
            buttonDisableState: {
              disabled: false,
              tooltipKey: '',
            },
          },
          icon: this.smacsIcons.OK,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );

    const validationAlertSub = combineLatest([this.smacsFormsUpdate$, this._validateAndSubmitSource]).subscribe(() => {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });
    this._subscriptions.add(validationAlertSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }

  submit(): Observable<void> {
    return this._saveAuditSettings();
  }

  private _saveAuditSettings(): Observable<void> {
    this._setButtonPending(true);

    return this.auditSettingsContext.update(this.entity).pipe(
      tap(() => {
        this._setButtonPending(false);

        this.toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.AUDITS,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.audits.settings.toast.message'
        );
      })
    );
  }

  private _setButtonPending(isPending: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'saveAuditSettings',
        state: {
          pending: isPending,
        },
      })
    );
  }
}
