<div
  class="mb-3 row"
  [hidden]="state.hidden && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
>
  <label
    [attr.for]="config.dataAutomation + '-input'"
    [ngClass]="{
      'has-feedback': showValidation || isFormSubmitted,
      'smacs-forms-error': validationState === ValStates.INVALID,
      'smacs-forms-warning':
        validationState === ValStates.PENDING ||
        validationState === ValStates.WARNING ||
        misconfigurationFeedbackMessage,
      'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
    }"
    [class]="'smacs-forms-label col-form-label ' + state.columnClasses?.label"
    data-automation="smacs-multi-text-label"
  >
    <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
    <span>{{ getLabel() | translate }}</span>
  </label>

  <div [class]="state.columnClasses?.input">
    <div
      *ngFor="let inputVal of shadowValue; let i = index; trackBy: trackData"
      [attr.data-automation]="'smacs-multi-text-input'"
      class="mb-1"
    >
      <div
        class="input-wrapper"
        [ngClass]="{
          'has-feedback': showValidation || isFormSubmitted,
          'smacs-forms-error': (showValidation || isFormSubmitted) && validationTrackingArray[i] === ValStates.INVALID
        }"
      >
        <div class="input-group">
          <input
            [ngClass]="{ 'smacs-text-pending': validationState === ValStates.PENDING }"
            [disabled]="state.disabled"
            [attr.id]="config.dataAutomation + '-input'"
            #ngModelDir="ngModel"
            (ngModelChange)="transformData($event, i, ngModelDir)"
            [(ngModel)]="shadowValue[i]"
            [placeholder]="placeholder | translate"
            class="form-control"
            type="text"
          />

          <smacs-button
            *ngIf="shadowValue.length > 1"
            [cssClass]="'form-control input-btn-end'"
            [dataAutomation]="'multi-text-input-remove-item-button'"
            (clicked)="removeTextItem(i, ngModelDir)"
            [buttonStyle]="buttonStyles.DEFAULT"
            [icon]="smacsIcons.MINUS"
          ></smacs-button>

          <smacs-button
            *ngIf="i === shadowValue.length - 1"
            [cssClass]="'form-control input-btn-end'"
            [isDisabled]="!inputVal"
            [dataAutomation]="'multi-text-input-add-item-button'"
            (clicked)="addNewTextItem()"
            [buttonStyle]="buttonStyles.DEFAULT"
            [icon]="smacsIcons.PLUS"
          ></smacs-button>
        </div>

        <div class="form-text small" *ngIf="isAutogenerated" data-automation="smacs-forms-autogeneration-message">
          {{ config.autogeneration?.generatedMsg | translate }}
        </div>

        <div
          class="smacs-forms-validation-message"
          data-automation="smacs-forms-validation-message"
          [hidden]="(!showValidation && !isFormSubmitted) || validationTrackingArray[i] === ValStates.VALID"
        >
          {{ validationMessage?.content | translate : validationMessage?.params }}
        </div>

        <smacs-button
          [dataAutomation]="'smacs-forms-autogeneration-button'"
          [hidden]="isAutogenerateLinkHidden()"
          (clicked)="autogenerateValue()"
          [buttonStyle]="buttonStyles.LINK"
          [label]="config.autogeneration?.linkLabel"
          [labelCssClass]="'small strong'"
        ></smacs-button>
      </div>
    </div>
    <span *ngIf="getHelpText()" class="small form-text text-muted" [innerHtml]="getHelpText() | translate"></span>
  </div>
</div>
