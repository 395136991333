import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultDirectoryNumberRequest, DirectoryNumber } from '../models/generated/smacsModels';
import { Observable } from 'rxjs';

@Injectable()
export class DefaultDirectoryNumberResource {
  constructor(private http: HttpClient) {}

  post(request: DefaultDirectoryNumberRequest): Observable<DirectoryNumber> {
    return this.http.post<DirectoryNumber>('/services/cisco/defaults/directory-number', {
      ...request,
    });
  }
}
