import { Component, EventEmitter, Output } from '@angular/core';
import { SmacsTextComponent } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFieldAbstractDirective } from '../../../forms/smacs-field-abstract.directive';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';

export enum PinResetOptions {
  VOICEMAIL = 'voicemailPinReset',
  END_USER = 'extensionMobPinReset',
}

@Component({
  selector: 'smacs-pin-reset',
  templateUrl: './pin-reset.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: PinResetComponent }],
})
export class PinResetComponent extends SmacsTextComponent {
  @Output() resetClicked = new EventEmitter<string>();
  isPending = false;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    protected translateService: TranslateService,
    protected smacsModalService: SmacsModalService
  ) {
    super(smacsFormStateService, translateService, smacsModalService);
  }

  submit(id: string) {
    this.isPending = true;
    this.resetClicked.emit(id);
  }
}
