import { Observable, Subject } from 'rxjs';

// SmacsForms Validation State: reflects the results of the validators associated with a form's fields
// When combining states from child fields, PENDING overrides VALID, and INVALID overrides PENDING and VALID, e.g.,
// VALID field + INVALID field + PENDING field => INVALID form
// VALID field + VALID field + PENDING field => PENDING form
// VALID field + VALID field + VALID field => VALID form
export enum SmacsFormsValidationState {
  VALID, // Field or form is VALID
  INVALID, // Field or form is INVALID. Invalid fields cause their nearest parent to also be invalid.
  PENDING, // Field state waiting on asynchronous task. Pending fields cause their nearest parent to be pending.
  WARNING,
}

export interface SmacsFieldValidationResult {
  isValid: boolean;
  message: string;
}

export type SmacsFormsValidationResult = SmacsFormsValidationState | SmacsFieldValidationResult;

export function isSmacsFieldValidationResult(
  smacsFormsValidationResult: SmacsFormsValidationResult
): smacsFormsValidationResult is SmacsFieldValidationResult {
  const smacsFieldValidationResult = smacsFormsValidationResult as SmacsFieldValidationResult;
  return smacsFieldValidationResult.isValid != null;
}

// SmacsForms Validator: A function that checks form data (usually from the field but can access form data if needed)
// and returns a boolean (sync validation) or Observable that emits a boolean (async validation)
type SmacsFormsValidator = (
  any: any,
  ...args: any
) => SmacsFormsValidationResult | Observable<SmacsFormsValidationResult>;

// SmacsForms Warning Validator: Works exactly like a regular validator, except it affects the presence of
// warning messages that have no effect on the validation state (e.g. misconfiguration feedback)
type SmacsFormsWarningValidator = (any: any, ...args: any) => SmacsFormsValidationState;

// SmacsField Component Configuration: Component-specific configuration for a SMACS custom field
// N.B. Some custom field components are fixed and require no configuration
export abstract class SmacsFieldComponentConfig {}

// SmacsForm Update: E represents the "real" type of data that this form is modifying
// e.g. GlobalConfiguration, EndUserSummary, etc.
// Referred to as here as the "entity", but need not actually correspond with a true DOM/database entity
export interface SmacsFormsUpdate<E> {
  // New: The current state of the entity data
  // i.e. if the form were to be saved right now, this is the object that will be pushed to the server
  new: E;
  // Old: The original state of the entity data when the form was initialized
  old: E;
  // Valid: set to VALID if all of the form's fields are valid, INVALID otherwise, and PENDING if not yet known
  // This gets updated upon any data change in any of the fields
  valid: SmacsFormsValidationState;
}

// SmacsField Channels: The form uses these subjects to keep each field up to date with the latest
// Form-provided value and configuration
export interface SmacsFieldChannels {
  [fieldId: string]: {
    entitySource: Subject<any>;
    componentConfigSource: Subject<SmacsFieldComponentConfig>;
    stateSource: Subject<SmacsFieldState>;
    validateSource: Subject<void>;
    isExistingSource: Subject<boolean>;
    smacsFormUpdate$: Observable<SmacsFormsUpdate<any>>;
  };
}

export interface SmacsFormsMessage {
  // Content: The text of the error/warning message that is displayed upon a validator failing
  // This can be either plain text or a Handlebars expression that uses the params
  content: string;
  // Params: Used to create the final text if content was a handlebars expression
  params: { [key: string]: string | number };
}

export type SmacsMessageOrFunction = string | SmacsFormsMessage | (() => string | SmacsFormsMessage);

export interface SmacsFormsValidationConfigItem {
  // Validator: The validation function that, depending on its return value and context (field or form),
  // generate a certain error or pending message
  validator: SmacsFormsValidator | SmacsFormsWarningValidator;
  // Message: The error or warning text that is displayed if and when this validator fails
  message?: SmacsMessageOrFunction;
  // Success Message: If defined, displays this message when valid
  successMessage?: SmacsMessageOrFunction;
  // Inject Values from Fields: makes values from these fields available as arguments in the validator method,
  // and triggers this validator when this field is changed
  injectValuesFromFields?: string[];
}

// SmacsForms Validation Config: A list of validation configuration items, its order determining which has priority
export type SmacsFormsValidationConfig = SmacsFormsValidationConfigItem[];

// SmacsField Config: The configuration given to the fields on init
export interface SmacsFieldConfig<C extends SmacsFieldComponentConfig = null> {
  // DataAutomation: The vale of the data-automation attribute of the field's top internal wrapper element
  // N.B. Must be *unique to the application* as input associations and blackbox testing rely on it
  dataAutomation?: string;
  // Used with DataAutomation when you want to specify a unique identifier
  id?: string;
  // Label: The text displayed to the user for this field, usually a translation key
  label?: string | (() => string);
  // The text displayed in the label's tooltip, usually a translation key
  labelToolTipText?: string;
  // CSS class for the icon that will appear next to the label, the tooltip will appear on hover of this if given
  labelToolTipIconClass?: string;
  inputToolTipText?: string;
  // ComponentConfig: the config specific to a custom field type in SMACS, e.g. MultiTextInput
  // Some simpler custom fields need no special configuration
  componentConfig?: C;
  // Validation: An array of validator configurations (see SmacsFormsValidation)
  // N.B. Each validator will run in the order given
  validation?: SmacsFormsValidationConfig;
  misconfigurationFeedback?: SmacsFormsValidationConfig;
  // Autogeneration: Produces a link that sets this field to a value based on a callback
  autogeneration?: SmacsFormsAutogenerationConfig;
  // The default value of the field. This is a string for selects, a boolean for checkboxes, etc.
  // If this is defined, autogeneration and warning validation will be added to the field.
  defaultValue?: () => any;
  // Runs on every validation cycle. Returns true if the field should be required.
  // Can be permanently set to true or false by passing a boolean instead
  // Defaults to being optional if not defined
  // In  most custom fields, it also adds a red asterisk to the field's label
  required?: boolean | (() => boolean);
  // Runs on every validation cycle. Returns true if the field should be hidden.
  // Defaults to always being shown if not defined
  hidden?: () => boolean;
  // Runs on every validation cycle. Returns true if the field should be disabled.
  // Defaults to never being disabled if not defined
  disabled?: () => boolean;
  // Displays on the input if it is disabled.
  disabledTooltip?: SmacsFormsMessage | string | (() => SmacsFormsMessage);
  // Runs on every validation cycle. Returns true if the field's validation state should be ignored.
  // Defaults to always determining its parent form's validation if not defined
  valExcluded?: () => boolean;
  // Debounce time (in milliseconds) for the validation (sync and async together)
  debounceTime?: number;
  // Help text displayed below input
  helpText?: string | (() => string);
  // Additional options
  options?: { content?: string };
  // If field change marks the form as dirty via SmacsFormStateService
  canModifyFormState?: boolean;
}

export class SmacsFormsAutogenerationConfig {
  // The label for the autogeneration link
  linkLabel: string;
  // The method used to generate the new value once the link is clicked
  generateValue: (val: any, ...otherVals: any) => any;
  // The translation key to use for the info text after the autogeneration link is clicked
  generatedMsg?: string;
  // Determines whether to show the autogeneration feature
  hidden: (val?: any, ...otherVals: any) => boolean;
  // Inject Values from Fields: makes values from these fields available as arguments in the generateValue method
  injectValuesFromFields?: string[];
  // Inline the autogeneration link
  inline?: boolean;
}

export interface SmacsFieldConfigs {
  // Field Id: Form Data, Messages and Validation flags will be linked to the field with this ID
  // i.e. these form the property names on the Form Data type
  // N.B. Must be *unique to the form*, but not necessarily unique in the application or even on the page
  [fieldId: string]: SmacsFieldConfig<SmacsFieldComponentConfig>;
}

export interface SmacsFieldConfigListItem<C extends SmacsFieldComponentConfig = null> extends SmacsFieldConfig<C> {
  // See Field ID of SmacsFieldConfigs
  fieldId: string;
}

export interface SmacsFormConfig {
  // SmacsFormConfig - Fields: Mapping of Field IDs to configurations for the fields under the control of this form
  fields: SmacsFieldConfigs;
  options?: {
    columnClasses?: SmacsFormColumnClasses;
  };
}

export interface SmacsFormsValidators {
  // All the field's validators are VALID, or INVALID otherwise, and PENDING if not known
  [fieldId: string]: SmacsFormsValidator;
}

export interface SmacsFormsWarningValidators {
  // All the field's validators are VALID, or INVALID otherwise, and PENDING if not known
  [fieldId: string]: SmacsFormsWarningValidator;
}

export interface SmacsFormData {
  // Form Data: The form the data takes when it is being modified by this form
  // By default, this interface is identical to that of E, but it doesn't need to be
  // i.e. For when the fields of a form don't correspond 1:1 to the entity it's modifying
  [fieldId: string]: any;
}

// Validation Flags: These internal flags provide a cache of the latest validation status of fields
export interface SmacsFormsValidationFlags {
  // Validation Flags: These flags are recomputed when the associated field is changed or during forced validation
  [fieldId: string]: SmacsFormsValidationState;
}

// SmacsFieldState: Field-specific states managed and referred to by the parent form
export class SmacsFieldState {
  defaultValue: any = null;
  hidden = false;
  disabled = false;
  required = false;
  valExcluded = false;
  helpText = '';
  columnClasses: SmacsFormColumnClasses;
  canModifyFormState = true;
}

// SmacsFieldState Cache: A mapping of fields' IDs to their current field state
export interface SmacsFieldStateCache {
  [fieldId: string]: SmacsFieldState;
}

export interface SmacsFormColumnClasses {
  label: string;
  input: string;
}

export interface HtmlInputAddOn {
  prependedContent?: string;
  appendedContent?: string;
  htmlPill?: string;
}
