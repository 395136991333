import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PcceAgentsFieldConfig } from '../../../../shared/models/generated/smacsModels';
import { PcceAgentFieldConfigRequest } from '../../../cisco/pcce-agent-settings/pcce-agent-settings.component';

@Injectable()
export class PcceAgentFieldConfigResource {
  constructor(private http: HttpClient) {}

  getFieldConfigs(req: PcceAgentFieldConfigRequest): Observable<PcceAgentsFieldConfig> {
    return this.http.get<PcceAgentsFieldConfig>(
      `/services/cisco/field-configs/pcce-agents?pcce-server-id=${req.pcceServerId}&department=${
        req.department
      }&username=${encodeURIComponent(req.username)}`
    );
  }
}
