import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { Nvp } from '../../models/nvp';

export interface ResourceOptions {
  headers?: HttpHeaders;
  auditTags?: Nvp[];
  clusterId?: number | string;
}

export class HttpOptions {
  headers: HttpHeaders;

  constructor({ clusterId, auditTags, headers }: ResourceOptions) {
    this.headers = headers || new HttpHeaders();
  }
}

export abstract class ModelResourceAbstract<T> {
  protected _baseUrl: string;

  protected constructor(protected http: HttpClient) {}

  protected get = (id: string | number, options = {} as ResourceOptions): Observable<T> => {
    return this.http
      .get<T>(`${this._baseUrl}/${encodeURIComponent(String(id))}`, new HttpOptions(options))
      .pipe(shareReplay());
  };

  protected getAll = (options = {} as ResourceOptions): Observable<T[]> => {
    return this.http.get<T[]>(this._baseUrl, new HttpOptions(options)).pipe(shareReplay());
  };

  protected post = (body: T, options = {} as ResourceOptions): Observable<T> => {
    return this.http.post<T>(this._baseUrl, body, new HttpOptions(options)).pipe(shareReplay());
  };

  protected put = (id: string | number, body: T, options = {} as ResourceOptions): Observable<T> => {
    return this.http
      .put<T>(`${this._baseUrl}/${encodeURIComponent(String(id))}`, body, new HttpOptions(options))
      .pipe(shareReplay());
  };

  protected delete = (id: string | number, options = {} as ResourceOptions): Observable<void> => {
    return this.http
      .delete<void>(`${this._baseUrl}/${encodeURIComponent(String(id))}`, new HttpOptions(options))
      .pipe(shareReplay());
  };
}
