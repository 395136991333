import { Component, Input } from '@angular/core';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { animate, query, sequence, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-about-card',
  templateUrl: './about-card.component.html',
  styleUrls: ['./about-card.component.scss'],
  animations: [
    trigger('aboutCardBodyAnimation', [
      transition('out => in', [
        sequence([
          query('.smacs-about-card__body', [
            style({
              maxHeight: '50rem',
              transform: 'scale(1)',
            }),
            animate(
              '0.3s ease-out',
              style({
                maxHeight: '0rem',
                transform: 'scale(0.1)',
              })
            ),
          ]),
        ]),
      ]),
      transition('in => out', [
        sequence([
          query('.smacs-about-card__body', [
            style({
              maxHeight: '0rem',
              transform: 'scale(0.1)',
            }),
            animate(
              '0.3s ease-out',
              style({
                maxHeight: '50rem',
                transform: 'scale(1)',
              })
            ),
          ]),
        ]),
      ]),
    ]),
  ],
})
export class AboutCardComponent {
  smacsIcons = SmacsIcons;

  @Input() isExpanded = false;
  @Input() showArrow = true;

  toggleCard() {
    this.isExpanded = !this.isExpanded;
  }

  get state() {
    return this.isExpanded ? 'out' : 'in';
  }
}
