import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SnrFieldConfig, SnrFieldConfigRequest } from '../../../../shared/models/generated/smacsModels';

@Injectable()
export class SnrFieldConfigResource {
  constructor(private http: HttpClient) {}

  getFieldConfigs(request: SnrFieldConfigRequest): Observable<SnrFieldConfig> {
    return this.http.post<SnrFieldConfig>(`/services/cisco/field-configs/snrs`, request);
  }
}
