import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading-block',
  templateUrl: './loading-block.component.html',
  styleUrls: ['./loading-block.component.scss'],
})
export class LoadingBlockComponent {
  @Input() hidden: boolean;
  @Input() cssClass = {
    margin: '200px auto',
  };
}
