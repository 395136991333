import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import {
  EndUser,
  Global360View,
  LineButton,
  Phone,
  Voicemail,
  VoicemailAlternateExtension,
  VoicemailFieldConfig,
  VoicemailRef,
} from '../../../shared/models/generated/smacsModels';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';
import { combineLatest, Subscription } from 'rxjs';
import { EndUserResource } from '../../../shared/resources/end-user.resource';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { VoicemailFormComponent, VoicemailFormEntity } from './form/voicemail-form.component';
import { ButtonStyles } from '../../../button/button.component';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { PhoneResource } from '../../../shared/resources/phone.resource';
import { VoicemailResource } from '../../../shared/resources/voicemail.resource';
import { VoicemailAlternateExtensionsResource } from '../../../shared/resources/voicemail-alternate-extensions.resource';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { VoicemailFieldsConfigResource } from '../../../shared/resources/voicemail-fields-config.resource';
import { take } from 'rxjs/operators';
import { VoicemailSmtpNotificationsDeviceResource } from '../../../shared/resources/voicemail-smtp-notifications-device.resource';

@Component({
  selector: 'smacs-voicemail',
  templateUrl: './voicemail.component.html',
})
export class VoicemailComponent implements OnInit, OnDestroy {
  @ViewChild(VoicemailFormComponent) voicemailForm: VoicemailFormComponent;
  @Input() modalProperties?: any;
  @Input() username?: string;
  @Input() siteId: number;
  @Output() voicemailSaved = new EventEmitter<boolean | string>();
  @Output() voicemailDeleted = new EventEmitter<boolean | string>();
  @Output() voicemailRefUpdated = new EventEmitter<VoicemailRef>();
  @Output() showLoading = new EventEmitter<boolean>();
  extension: string;
  phone: Phone;
  endUser: EndUser;
  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;
  originalValue = {
    alias: '',
    alternateExtensions: [],
    displayName: '',
    firstName: '',
    lastName: '',
    voicemailTemplate: '',
    smtpNotificationDevice: '',
  } as VoicemailFormEntity;

  unityServerId: number;
  serverId: number;
  isExistingVoicemail: boolean;
  voicemail: Voicemail;
  alternateExtensions: VoicemailAlternateExtension[] = [];
  voicemailId: string;
  phoneId: string;
  line: string;
  isLoading = true;
  voicemailFieldConfig: VoicemailFieldConfig;
  isNewVoicemailOnSharedLine: boolean;
  isExistingVoicemailOnSharedLine: boolean;
  isNewVoicemailOnPrimaryExtension: boolean;
  isExistingVoicemailOnPrimaryExtension: boolean;
  isPending = false;
  isDeleting = false;
  voicemailRef: VoicemailRef;
  isPublicPhone = false;
  isExistingVoicemailOnPublicPhone: boolean;
  global360View: Global360View;

  private _smtpNotificationDeviceValue: string;
  private _subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private endUserResource: EndUserResource,
    private global360ViewContext: Global360ViewContext,
    private currentClusterContext: CurrentClusterContext,
    private voicemailFieldsConfigResource: VoicemailFieldsConfigResource,
    private siteSummaryContext: SiteSummaryContext,
    private phoneResource: PhoneResource,
    private voicemailResource: VoicemailResource,
    private voicemailAlternateExtensionsResource: VoicemailAlternateExtensionsResource,
    private voicemailSmtpNotificationsDeviceResource: VoicemailSmtpNotificationsDeviceResource
  ) {}

  ngOnInit() {
    if (this.modalProperties) {
      this.username = this.modalProperties.username;
      this.isPublicPhone = this.modalProperties.phone.owner === null;
    } else if (!this.username) {
      this.username = get(this.route, 'snapshot.params.username');
    }
    this.voicemailId = this.modalProperties
      ? this.modalProperties.voicemailId
      : get(this.route, 'snapshot.params.voicemailId');
    this.phoneId = get(this.route, 'snapshot.params.phoneId');

    this.isNewVoicemailOnPrimaryExtension = !!(this.username && !this.phoneId && !this.voicemailId);
    this.isExistingVoicemailOnPrimaryExtension = !!(this.username && !this.phoneId && this.voicemailId);
    this.isNewVoicemailOnSharedLine = !!(!this.username && this.phoneId && !this.voicemailId);
    this.isExistingVoicemailOnSharedLine = !!(!this.username && this.phoneId && this.voicemailId);
    this.isExistingVoicemailOnPublicPhone = this.isPublicPhone && !!this.voicemailId;

    this.isExistingVoicemail = !!this.voicemailId;
    if (this.username) {
      this.global360ViewContext.init(this.username);
      this.global360ViewContext.state$.pipe(take(1)).subscribe((global360View) => {
        this.global360View = global360View;
      });
    }

    const loadingSub = combineLatest([this.currentClusterContext.state$, this.siteSummaryContext.state$]).subscribe(
      ([currentCluster, siteSummary]) => {
        this.unityServerId = this.siteSummaryContext.getSiteFromId(siteSummary, Number(this.siteId)).unityServerId;
        this.serverId = currentCluster.cucmServerId;
        if (this.modalProperties) {
          this.extension = this.modalProperties.button.dn.extension;
        }
        if (this.isNewVoicemailOnPrimaryExtension || this.isExistingVoicemailOnPrimaryExtension) {
          this._getFieldConfigsFromPrimaryExtension();
        } else if (this.isNewVoicemailOnSharedLine || this.isExistingVoicemailOnSharedLine) {
          this.getFieldConfigsFromSharedLine();
        } else if (this.isPublicPhone) {
          if (this.isExistingVoicemailOnPublicPhone) {
            this._getVoicemail();
          } else {
            this._setVoicemailFieldConfigs();
          }
        } else if (this.voicemailId) {
          this._getVoicemail();
        }
      }
    );

    this._subscriptions.add(loadingSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onDeleteComplete($event: boolean | string) {
    this.voicemailDeleted.emit($event);
  }

  onSaveComplete(event: string | boolean) {
    this.voicemailSaved.emit(event);
  }

  onVoicemailRefUpdated(event: VoicemailRef) {
    this.voicemailRefUpdated.emit(event);
    this.voicemailRef = event;
  }

  private _setVoicemailFieldConfigs() {
    this.voicemailFieldsConfigResource
      .post({
        extension: this.modalProperties ? this.modalProperties.dnRef.extension : this.extension,
        siteId: this.siteId,
      })
      .subscribe((voicemailFieldConfig) => {
        this.voicemailFieldConfig = voicemailFieldConfig;
        this.originalValue.voicemailTemplate = voicemailFieldConfig.voicemailTemplate.defaultValue;
        if (
          this.modalProperties &&
          voicemailFieldConfig.smtpNotificationDevice.defaultValue &&
          !this.originalValue.smtpNotificationDevice
        ) {
          this._smtpNotificationDeviceValue = voicemailFieldConfig.smtpNotificationDevice.defaultValue;
        }

        if (this.isExistingVoicemail || this.isExistingVoicemailOnPublicPhone) {
          this._setEntitySourceFromVoicemail();
        } else {
          this._setEntitySourceFromFieldConfigsDefault();
        }

        if (this.endUser && !this.modalProperties) {
          const filteredAlternateExtensions = this.alternateExtensions
            .map((alternateExtensions: VoicemailAlternateExtension) => alternateExtensions.extension)
            .filter((alternateExt) => {
              return alternateExt !== this.endUser.mailId && alternateExt !== this.endUser.primaryExtension.extension;
            });
          this.originalValue.alternateExtensions = [...filteredAlternateExtensions];
        }

        this.isLoading = false;
        this.showLoading.emit(this.isLoading);
      });
  }

  private _getFieldConfigsFromPrimaryExtension() {
    const global360ViewUpdate = this.global360ViewContext.state$.pipe(take(1)).subscribe((global360View) => {
      const global360User = this.global360ViewContext.getCurrentEnduser(global360View, this.serverId);
      this.endUserResource.get(global360User.ref.id, this.serverId).subscribe((endUser: EndUser) => {
        this.endUser = endUser;
        this.extension = this.endUser.primaryExtension.extension;

        if (this.isExistingVoicemailOnPrimaryExtension) {
          this._getVoicemail();
        } else {
          this._setVoicemailFieldConfigs();
        }
      });
    });
    this._subscriptions.add(global360ViewUpdate);
  }

  private getFieldConfigsFromSharedLine() {
    this.phoneResource.get(this.phoneId, this.serverId.toString()).subscribe((phone: Phone) => {
      this.phone = phone;

      const lineButton = phone.buttons.find((button: LineButton) => {
        return button && button.dn && button.dn.id === this.line;
      }) as LineButton;

      this.extension = lineButton?.dn.extension;

      if (this.isExistingVoicemailOnSharedLine) {
        this._getVoicemail();
      } else {
        this._setVoicemailFieldConfigs();
      }
    });
  }

  private _getVoicemail = () => {
    this.voicemailResource.get(this.unityServerId, this.voicemailId).subscribe((voicemail: Voicemail) => {
      this.voicemail = voicemail;
      this.voicemailId = voicemail.id;
      if (!this.modalProperties) {
        combineLatest([
          this.voicemailAlternateExtensionsResource.getAll(this.unityServerId, this.voicemailId),
          this.voicemailSmtpNotificationsDeviceResource.get(this.unityServerId, this.voicemailId),
        ]).subscribe(([voicemailAlternateExtensions, voicemailSmtpNotificationsDevice]) => {
          this.alternateExtensions = [...voicemailAlternateExtensions];
          this._smtpNotificationDeviceValue = voicemailSmtpNotificationsDevice.email;
          this._setVoicemailFieldConfigs();
        });
      } else {
        this._setVoicemailFieldConfigs();
      }
    });
  };

  private _setEntitySourceFromVoicemail = () => {
    this.originalValue.lastName = this.voicemail.lastName;
    this.originalValue.firstName = this.voicemail.firstName;
    this.originalValue.alias = this.voicemail.alias;
    this.originalValue.displayName = this.voicemail.displayName;
    this.originalValue.smtpNotificationDevice = this._smtpNotificationDeviceValue;
    this.alternateExtensions.forEach((extension) => {
      this.originalValue.alternateExtensions.push(extension.extension);
    });
  };

  private _setEntitySourceFromFieldConfigsDefault = () => {
    this.originalValue.lastName = this.voicemailFieldConfig.lastName.defaultValue;
    this.originalValue.firstName = this.voicemailFieldConfig.firstName.defaultValue;
    this.originalValue.alias = this.voicemailFieldConfig.alias.defaultValue;
    this.originalValue.displayName = this.voicemailFieldConfig.displayName.defaultValue;
    this.originalValue.smtpNotificationDevice = this.voicemailFieldConfig.smtpNotificationDevice.defaultValue;
  };
}
