import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subscriber, Subscription } from 'rxjs';
import { SiteContext } from '../../../shared/contexts/site.context';
import { PhoneUiContext } from '../../../../shared/phone-buttons/contexts/phone-ui.context';
import {
  ClusterResult,
  DevicePoolResult,
  Phone,
  SiteResult,
  SiteSummary,
} from '../../../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../../button/button.component';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../forms/fields/select/smacs-select.component';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import { take } from 'rxjs/operators';
import { SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { TranslateService } from '@ngx-translate/core';
import { PhoneResource } from '../../../../shared/resources/phone.resource';
import { ToastService } from '../../../../shared/services/toast.service';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { Nvp } from '../../../../shared/models/nvp';

interface PublicPhoneChangeSiteEntity {
  site: number;
}

@Component({
  selector: 'smacs-public-phone-change-site',
  templateUrl: './public-phone-change-site.component.html',
  styleUrls: ['../public-phone.component.scss', './public-phone-change-site.component.scss'],
})
export class PublicPhoneChangeSiteComponent
  extends SmacsFormAbstractDirective<PublicPhoneChangeSiteEntity>
  implements OnInit, AfterViewInit, OnDestroy
{
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  phoneId: string;
  isValidCluster = false;
  formConfig = {
    fields: {
      site: {
        label: 'tkey;change_site.public_phone.search.label',
        dataAutomation: 'public-phone-change-site-form-site',
        componentConfig: new SmacsSelectConfig(),
        validation: [
          {
            validator: (val: number) => {
              const cluster = this._getClusterForSite(val);
              this.isValidCluster = !!cluster && cluster.id === this._initialPhoneCluster.id;
              if (!val || cluster.id !== this._initialPhoneCluster.id) {
                return SmacsFormsValidationState.INVALID;
              }

              return SmacsFormsValidationState.VALID;
            },
            message: () => this._getValidationMessage(),
          },
        ],
      },
    },
    options: {
      columnClasses: {
        label: 'col-12 font-light',
        input: 'col-12',
      },
    },
  };

  private _phone: Phone;
  private _initialPhoneSite: SiteResult;
  private _initialPhoneCluster: ClusterResult;
  private _siteSummary: SiteSummary;
  private _subscriptions = new Subscription();
  private _initTimeout: number;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteContext: SiteContext,
    private _phoneUiContext: PhoneUiContext,
    private _siteSummaryContext: SiteSummaryContext,
    private _translateService: TranslateService,
    private _phoneResource: PhoneResource,
    private _toastService: ToastService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    const sub = combineLatest([
      this._siteSummaryContext.state$,
      this._phoneUiContext.phoneState$.pipe(take(1)),
    ]).subscribe(([siteSummary, phone]) => {
      this._siteSummary = siteSummary;
      this._phone = phone;
      this._siteContext.setSiteForPublicPhone(this._siteSummary, this._phone);
      this.phoneId = this._phone.id;
    });
    this._subscriptions.add(sub);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this._initTimeout = window.setTimeout(() => {
      this._siteContext.state$.pipe(take(1)).subscribe((site) => {
        this._initialPhoneSite = site;
        this._initialPhoneCluster = this._getClusterForSite(Number(site.id));
        this.entitySource.next({
          site: null,
        });
        this._setFormOptions();
      });
    });
  }

  ngOnDestroy() {
    window.clearTimeout(this._initTimeout);
    this._subscriptions.unsubscribe();
  }

  onCancelClicked() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  protected submit() {
    if (this.entity.site) {
      const newDevicePool = this._getSiteDeskphoneDevicePool(this.entity.site);
      const site = this._getSiteFromId(this.entity.site);

      const updatedPhone = {
        ...this._phone,
        devicePool: newDevicePool,
      };
      const auditTags = [{ name: 'change site', value: `${this._initialPhoneSite.name} > ${site.name}` }] as Nvp[];

      return new Observable((subscriber: Subscriber<void>) => {
        this._phoneResource
          .put(updatedPhone, this._initialPhoneCluster.cucmServerId.toString(), auditTags)
          .subscribe(() => {
            this._phoneUiContext.set(updatedPhone);
            this._siteContext.setSiteForPublicPhone(this._siteSummary, updatedPhone);
            this._toastService.push(
              ToastTypes.SUCCESS,
              this.smacsIcons.SITE,
              'tkey;change.site.toast.title',
              'tkey;change.site.toast.message',
              { site: site.name }
            );
            this._router.navigate(['../'], { relativeTo: this._route });
            subscriber.next();
            subscriber.complete();
          });
      });
    }

    return of(null);
  }

  private _getClusterForSite(siteId: number): ClusterResult {
    return this._siteSummary.clusters.find((clusterResult: ClusterResult) => {
      const site = clusterResult.sites.find((siteResult: SiteResult) => siteResult.id === siteId);

      if (site) {
        return clusterResult;
      }
    });
  }

  private _getSiteFromId(siteId: number): SiteResult {
    let sites: SiteResult[] = [];
    this._siteSummary.clusters.forEach((clusterResult: ClusterResult) => {
      sites = sites.concat(clusterResult.sites);
    });

    return sites.find((siteResult: SiteResult) => siteResult.id === siteId);
  }

  private _getSiteDeskphoneDevicePool(siteId: number): string {
    const site = this._getSiteFromId(siteId);
    let newDevicePool = '';
    if (site) {
      const devicePool = site.devicePools.find(
        (devicePoolResult: DevicePoolResult) => devicePoolResult.serviceName === 'Desk Phone'
      );
      if (devicePool) {
        newDevicePool = devicePool.devicePool;
      }
    }

    return newDevicePool;
  }

  private _setFormOptions() {
    const sites: SmacsSelectOption[] = [];

    this._siteSummary.clusters.forEach((cluster: ClusterResult) => {
      cluster.sites
        .filter((site: SiteResult) => {
          const deskphoneDevicePool = site.devicePools.find((devicePool: DevicePoolResult) => {
            if (devicePool.serviceName === 'Desk Phone') {
              return devicePool;
            }
          });

          return site.id !== Number(this._initialPhoneSite.id) && site.hasPermission && deskphoneDevicePool;
        })
        .forEach((site: SiteResult) => {
          sites.push({
            label: site.name,
            value: site.id,
            group: cluster.name,
          });
        });
    });

    const dialPlanGroupSelect = this.fieldComponents.find((field) => field.fieldId === 'site');
    dialPlanGroupSelect.applyComponentConfig(
      new SmacsSelectConfig({
        options: sites,
        bindValue: 'value',
      })
    );
  }

  private _getValidationMessage(): string {
    return this._translateService.instant('tkey;change_site.public_phone.form.site.error.text', {
      clusterName: this._initialPhoneCluster.name,
    });
  }
}
