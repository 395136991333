import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum VariableEditorType {
  CISCO = 'CISCO',
  MICROSOFT = 'MICROSOFT',
  WRITE_TO_AD = 'WRITE_TO_AD',
}

export interface VariableEditorOption {
  label: string;
  value: string;
}

export interface VariableEditorFunctionProps {
  label: string;
  value: string;
  disabled: boolean;
}

@Injectable()
export class VariableEditorService {
  constructor(private translateService: TranslateService) {}

  getOptions(editorType: VariableEditorType): VariableEditorOption[] {
    if (editorType === VariableEditorType.MICROSOFT) {
      return [
        {
          label: this.translateService.instant('tkey;admin.msdialplan.management.edit_custom_input_value.did.text'),
          value: 'did',
        },
      ];
    } else if (editorType === VariableEditorType.WRITE_TO_AD) {
      return [
        {
          label: this.translateService.instant('tkey;admin.msdialplan.management.edit_custom_input_value.did.text'),
          value: 'did',
        },
        {
          label: this.translateService.instant(
            'tkey;admin.msdialplan.management.edit_custom_input_value.extension.text'
          ),
          value: 'extension',
        },
      ];
    } else {
      return [
        {
          label: this.translateService.instant('tkey;site_management.site.edit_custom_input_value.username.text'),
          value: 'username',
        },
        {
          label: this.translateService.instant('tkey;site_management.site.edit_custom_input_value.first_name.text'),
          value: 'firstName',
        },
        {
          label: this.translateService.instant('tkey;site_management.site.edit_custom_input_value.last_name.text'),
          value: 'lastName',
        },
        {
          label: this.translateService.instant(
            'tkey;site_management.site.edit_custom_input_value.primary_extension.text'
          ),
          value: 'primaryExtension',
        },
        {
          label: this.translateService.instant('tkey;site_management.site.edit_custom_input_value.mail_id.text'),
          value: 'mailId',
        },
        {
          label: this.translateService.instant(
            'tkey;site_management.site.edit_custom_input_value.user_principal_name.text'
          ),
          value: 'ldap_userPrincipalName',
        },
      ];
    }
  }

  getFunctions(editorType: VariableEditorType): VariableEditorFunctionProps[] {
    if (editorType === VariableEditorType.MICROSOFT || editorType === VariableEditorType.WRITE_TO_AD) {
      return [
        {
          label: this.translateService.instant(
            'tkey;site_management.site.edit_custom_input_value.first_four_characters.text'
          ),
          value: 'first 4',
          disabled: false,
        },
        {
          label: this.translateService.instant(
            'tkey;site_management.site.edit_custom_input_value.last_four_characters.text'
          ),
          value: 'last 4',
          disabled: false,
        },
        {
          label: this.translateService.instant(
            'tkey;shared.custom_input_text.expression_editor.apply_international.label'
          ),
          value: 'toInternationalNumberFormat',
          disabled: false,
        },
      ];
    } else {
      return [
        {
          label: this.translateService.instant('tkey;site_management.site.edit_custom_input_value.to_upper.text'),
          value: 'toUpperCase',
          disabled: false,
        },
        {
          label: this.translateService.instant('tkey;site_management.site.edit_custom_input_value.to_lower.text'),
          value: 'toLowerCase',
          disabled: false,
        },
        {
          label: this.translateService.instant('tkey;site_management.site.edit_custom_input_value.strip_domain.text'),
          value: 'stripDomain',
          disabled: false,
        },
        {
          label: this.translateService.instant(
            'tkey;site_management.site.edit_custom_input_value.first_four_characters.text'
          ),
          value: 'first 4',
          disabled: false,
        },
        {
          label: this.translateService.instant(
            'tkey;site_management.site.edit_custom_input_value.last_four_characters.text'
          ),
          value: 'last 4',
          disabled: false,
        },
        {
          label: this.translateService.instant('tkey;site_management.site.edit_custom_input_value.ascii_only.text'),
          value: 'ascii',
          disabled: false,
        },
      ];
    }
  }
}
