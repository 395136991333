import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { WebexCallingSettings } from '../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class WebexCallingSettingsContext {
  private _stateSource = new ReplaySubject<WebexCallingSettings>(1);
  state$ = this._stateSource.asObservable();

  constructor(private _http: HttpClient) {
    this._init().subscribe();
  }

  refreshWebexCallingSettings(): Observable<void> {
    return this._init();
  }

  updateWebexCallingSettings(callingSettings: WebexCallingSettings): Observable<void> {
    return this._http.put<void>('/services/cisco/admin/webex-calling', callingSettings);
  }

  deleteWebexCallingSettings(): Observable<void> {
    return this._http.delete<void>('/services/cisco/admin/webex-calling');
  }

  private _init(): Observable<void> {
    return new Observable((subscriber) => {
      return this._http
        .get<WebexCallingSettings>('/services/cisco/admin/webex-calling')
        .subscribe((callingSettings) => {
          this._stateSource.next(callingSettings);
          subscriber.next();
          subscriber.complete();
        });
    });
  }
}
