<div class="position-relative d-none d-md-block animated slideInLeft faster bg-info smacs-left-nav" *ngIf="!!site">
  <div class="sidebar">
    <!-- Avatar (some LDAP users have no photo so we need to evaluate the photo itself not the user type) -->
    <div class="no-margin text-center" *ngIf="getBase64Photo()">
      <!-- Images get loaded before ng-if hides them, so we need to do this -->
      <a [href]="getUser360ViewRoute()">
        <img class="avatar-pic" [src]="getImageSrc()" />
      </a>
    </div>

    <!-- Contact Card -->
    <div class="contact-card border-rad-all-2">
      <a *ngIf="!getBase64Photo()" [href]="getUser360ViewRoute()" class="text-white">
        <i [class]="smacsIcons.USER_CIRCLE" class="fa-3x"></i>
      </a>

      <br />

      <span id="username" data-automation="user-detail-card-username" class="font-bold small">
        <a data-automation="user-detail-card-username-link" [href]="getUser360ViewRoute()">
          {{ getUsername() }}
        </a>
      </span>

      <h5 data-automation="user-detail-card-name" class="no-margin">
        <span class="font-light">
          {{ getFullName() }}
        </span>
      </h5>

      <hr />

      <span [hidden]="!getEmail()">
        <a data-automation="user-detail-card-email" href="mailto:{{ getEmail() }}">
          {{ getEmail() }}
        </a>
        <br />
      </span>

      <smacs-dn-detail-summary-info
        [translationPatternRef]="translationPatterns"
        [directoryNumberRef]="directoryNumber"
      ></smacs-dn-detail-summary-info>

      <!--Site-->
      <div *ngIf="areClustersConfigured" data-automation="user-detail-card-site-section">
        <span [class]="smacsIcons.SITE" class="me-1"></span>

        <span data-automation="user-detail-card-site-name" id="site-name">{{ site?.name }}</span>

        <br />
      </div>

      <!--LDAP-->
      <span [hidden]="getLdapType() === 'LOCAL'" data-automation="user-detail-card-ldap-status" id="ldap-status">
        <div>
          <span class="badge bg-success" [hidden]="getLdapType() !== 'LDAP_ACTIVE'">
            <i [class]="smacsIcons.USER"></i>
            <span class="ms-1">{{ 'tkey;userdetail.nav.user_type.active_ldap.text' | translate }}</span>
          </span>
          <span class="badge bg-warning" [hidden]="getLdapType() !== 'LDAP_INACTIVE'">
            <i [class]="smacsIcons.USER"></i>
            <span>{{ 'tkey;userdetail.nav.user_type.inactive_ldap.text' | translate }}</span>
          </span>
        </div>
        <span *ngFor="let item of global360View.ldapUser?.additionalAttributes | keyvalue; let i = index">
          <span
            [ngbTooltip]="item.key"
            [tooltipClass]="'tooltip--white'"
            [container]="'body'"
            *ngIf="!!item.value"
            data-automation="ldap-additional-attribute"
            [ngClass]="'ldap-additional-attribute-' + i"
          >
            {{ item.value }}
          </span>
          <br *ngIf="!!item.value" />
        </span>
      </span>

      <span
        [hidden]="getLdapType() !== 'LOCAL'"
        class="badge bg-secondary"
        data-automation="user-detail-card-ldap-non-status"
        id="non-ldap-status"
      >
        <span>{{ 'tkey;userdetail.nav.user_type.local.text' | translate }}</span>
      </span>
    </div>

    <smacs-operations-menu
      *ngIf="areClustersConfigured"
      (pinResetClicked)="onPinResetClick()"
      (makePublicClicked)="onMakePublicClick()"
      (changeSiteClicked)="onChangeSiteClick()"
      (reassignClicked)="onReassignClick()"
      (sendEmailClicked)="onSendEmailClick($event)"
    ></smacs-operations-menu>
  </div>
</div>
