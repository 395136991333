<app-self-serve-top-bar></app-self-serve-top-bar>

<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div *ngIf="!isLoading" data-automation="replace-deskphone-component">
  <div *ngIf="canReplaceDeskphone && !buildFailed">
    <div class="row col-12 overflow-hidden">
      <div
        [attr.data-automation]="'phone-buttons-template'"
        class="position-relative phone-buttons-container"
        [ngClass]="{
          'custom-col-lg-6': currentStep === 1 && animationCurrentStep === 1,
          'col-lg-6': !(currentStep === 1 && animationCurrentStep === 1)
        }"
      >
        <!-- Display Phone buttons on step 1 -->

        <div
          [@stepAnimation]="currentStep"
          *ngIf="currentStep === 1 && animationCurrentStep === 1"
          [ngClass]="{ 'phone-buttons--loaded': !isLoading }"
        >
          <smacs-phone-buttons [userMode]="userMode" [serverId]="cucmServerId.toString()"></smacs-phone-buttons>
        </div>

        <!-- Display animations starting from step 2 -->
        <app-multi-step-animation
          *ngIf="currentStep > 1"
          [currentStep]="animationCurrentStep"
          [steps]="lottieAnimationSteps"
          [animationStart$]="animationReplaySource.asObservable()"
        ></app-multi-step-animation>
      </div>
      <div
        class="phone-step-container d-flex align-items-center justify-content-center position-relative"
        [ngClass]="{
          'custom-col-lg-6': currentStep === 1 && animationCurrentStep === 1,
          'col-lg-6': !(currentStep === 1 && animationCurrentStep === 1)
        }"
      >
        <div
          *ngIf="currentStep === 1"
          [@stepAnimation]
          [ngClass]="{ 'phone-step-container__step--active': currentStep === 1 }"
          class="row w-100 phone-step-container__step"
          data-automation="replace-deskphone-step-1"
        >
          <div class="col-12 overflow-hidden">
            <div class="row">
              <div class="col-12 align-content-center d-flex flex-wrap">
                <div>
                  <h2 class="w-100 text-start">
                    {{ 'tkey;selfserve.user.replace_deskphone.step_one.title' | translate }}
                  </h2>
                  <p
                    class="text-start d-inline-block"
                    [innerHTML]="
                      'tkey;selfserve.user.replace_deskphone.step_one.body'
                        | translate : { name: currentUserRef.firstName }
                    "
                  ></p>
                  <div class="text-end">
                    <smacs-button
                      [dataAutomation]="'replacee-deskphone-cancel-step-1-button'"
                      (click)="onCancelClick()"
                      [buttonSize]="buttonSizes.LARGE"
                      [buttonStyle]="buttonStyles.DEFAULT"
                      [label]="'tkey;global.button.cancel.text'"
                      class="me-1"
                    ></smacs-button>

                    <smacs-button
                      [dataAutomation]="'replace-deskphone-begin-button'"
                      (click)="onBeginClick()"
                      [buttonSize]="buttonSizes.LARGE"
                      [buttonStyle]="buttonStyles.PRIMARY"
                      [label]="'tkey;selfserve.user.replace_deskphone.step_one.button.title'"
                    ></smacs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="currentStep === 2"
          [@stepAnimation]="currentStep === 2"
          [ngClass]="{ 'phone-step-container__step--active': currentStep === 2 }"
          class="row phone-step-container__step"
          data-automation="replace-deskphone-step-2"
        >
          <div class="col-12">
            <div class="row">
              <div class="col-12 align-content-center d-flex flex-wrap">
                <div>
                  <h2 class="w-100 text-start">
                    {{ 'tkey;selfserve.user.replace_deskphone.step_two.title' | translate }}
                  </h2>
                  <h5 class="w-100 text-start">
                    {{ 'tkey;selfserve.user.replace_deskphone.step_two.body' | translate }}
                  </h5>
                  <ul class="mt-3 w-100 text-start">
                    <li>
                      {{ 'tkey;selfserve.user.replace_deskphone.step_two.body.two' | translate }}
                    </li>
                    <li>
                      {{ 'tkey;selfserve.user.replace_deskphone.step_two.body.three' | translate }}
                    </li>
                  </ul>
                  <div class="text-end">
                    <smacs-button
                      [dataAutomation]="'replace-deskphone-cancel-step-2-button'"
                      (click)="onCancelClick()"
                      [buttonSize]="buttonSizes.LARGE"
                      [buttonStyle]="buttonStyles.DEFAULT"
                      [label]="'tkey;global.button.cancel.text'"
                      class="me-1"
                    ></smacs-button>

                    <smacs-button
                      [dataAutomation]="'replace-deskphone-next-button'"
                      (click)="onNextClick()"
                      [buttonSize]="buttonSizes.LARGE"
                      [buttonStyle]="buttonStyles.PRIMARY"
                      [label]="'tkey;global.button.next.text'"
                    ></smacs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="currentStep === 4"
          [@stepAnimation]="currentStep === 4"
          [ngClass]="{ 'phone-step-container__step--active': currentStep === 4 }"
          class="row phone-step-container__step"
          data-automation="replace-deskphone-step-4"
        >
          <div class="col-12 align-content-center d-flex flex-wrap overflow-hidden">
            <h2 class="w-100 text-start">{{ 'tkey;selfserve.user.activate_deskphone.step_four.body' | translate }}</h2>
            <h5 class="w-100 text-start">
              {{ 'tkey;selfserve.user.activate_deskphone.step_four.body.two' | translate }}
            </h5>
            <p
              class="w-100 text-start mt-3"
              [innerHtml]="'tkey;selfserve.user.replace_deskphone.step_four.body.three' | translate"
            ></p>
          </div>
        </div>
        <div
          *ngIf="currentStep === 5"
          [@stepAnimation]="currentStep === 5"
          [ngClass]="{ 'phone-step-container__step--active': currentStep === 5 }"
          class="row phone-step-container__step"
          data-automation="replace-deskphone-step-5"
        >
          <div class="col-12">
            <div class="row">
              <div class="col-12 align-content-center d-flex flex-wrap">
                <div>
                  <h2
                    class="w-100 text-start"
                    [innerHTML]="'tkey;selfserve.user.replace_deskphone.step_five.body' | translate"
                  ></h2>
                  <p class="w-100 text-start">
                    {{ 'tkey;selfserve.user.replace_deskphone.step_five.body.two' | translate }}
                  </p>
                  <div class="text-end">
                    <a
                      [routerLink]="['/self-serve/user/', username]"
                      class="btn btn-primary btn-lg"
                      data-automation="replace-deskphone-return-button"
                    >
                      {{ 'tkey;selfserve.user.replace_deskphone.return_button.title' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          [hidden]="currentStep !== 3"
          [ngClass]="{ 'phone-step-container__step--active': currentStep === 3 }"
          class="row phone-step-container__step fadeIn animated animation-delay-half-second"
          data-automation="activate-phone-step-3"
        >
          <div class="col-12">
            <div class="row">
              <div class="col-12 align-content-center d-flex flex-wrap">
                <div>
                  <h2 class="w-100 text-start">
                    {{ 'tkey;selfserve.user.replace_deskphone.step_three.title' | translate }}
                  </h2>
                  <h5
                    class="w-100 text-start"
                    [innerHTML]="'tkey;selfserve.user.replace_deskphone.step_three.body' | translate"
                  ></h5>

                  <smacs-text fieldId="extension"></smacs-text>

                  <div class="text-end">
                    <smacs-button
                      [dataAutomation]="'replace-deskphone-cancel-step-3-button'"
                      (click)="onCancelClick()"
                      [buttonSize]="buttonSizes.LARGE"
                      [buttonStyle]="buttonStyles.DEFAULT"
                      [label]="'tkey;global.button.cancel.text'"
                      class="me-1"
                    ></smacs-button>

                    <smacs-submit-button
                      [isPending]="isSubmitting || isPhoneRegistering"
                      [isDisabled]="isSubmitting || !isFormValid()"
                      [label]="'tkey;selfserve.user.replace_deskphone.save'"
                      [labelPending]="'tkey;selfserve.user.replace_deskphone.saving'"
                      [dataAutomation]="'replace-deskphone-button'"
                      [iconPending]="smacsIcons.SAVING"
                      [formSubmitSubject]="_validateAndSubmitSource"
                      [buttonSize]="buttonSizes.LARGE"
                    ></smacs-submit-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!canReplaceDeskphone || buildFailed" class="d-flex justify-content-center">
    <div class="text-center my-5" data-automation="misconfiguration-alert">
      <h2>
        <i *ngIf="expansionModuleDetected" class="{{ smacsIcons.EXCLAMATION_CIRCLE }} text-warning me-2"></i>
        <i *ngIf="buildFailed" class="{{ smacsIcons.WARNING }} text-warning me-2"></i>
        {{ getWarningTextTitle() }}
      </h2>
      <hr />
      <p [innerHTML]="getWarningTextDescription()"></p>
      <smacs-button
        *ngIf="expansionModuleDetected || buildFailed"
        [dataAutomation]="'expansion-module-prompt-cancel-button'"
        (click)="onCancelClick()"
        [buttonSize]="buttonSizes.LARGE"
        [buttonStyle]="buttonStyles.PRIMARY"
        [label]="'tkey;selfserve.user.replace_deskphone.return_button.title'"
        class="me-1"
      ></smacs-button>
    </div>
  </div>
</div>
