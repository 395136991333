import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideNavigationComponent } from './side-navigation.component';
import { SideNavigationSearchComponent } from './side-navigation-search/side-navigation-search.component';
import { SideNavigationSectionComponent } from './side-navigation-section/side-navigation-section.component';
import { SideNavigationSectionLinkComponent } from './side-navigation-section/side-navigation-section-link/side-navigation-section-link.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    SideNavigationComponent,
    SideNavigationSearchComponent,
    SideNavigationSectionComponent,
    SideNavigationSectionLinkComponent,
  ],
  exports: [
    SideNavigationComponent,
    SideNavigationSearchComponent,
    SideNavigationSectionComponent,
    SideNavigationSectionLinkComponent,
  ],
  imports: [CommonModule, TranslateModule, NgbTooltip, RouterLink, FormsModule],
})
export class SideNavigationModule {}
