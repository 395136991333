<div class="h-100 d-flex flex-column container" data-automation="bulk-provisioning-upload-csv">
  <div class="row mb-3">
    <div class="col-12 d-flex justify-content-end">
      <a
        class="btn btn-primary"
        [href]="csvTemplateUrl"
        [download]="'zpc_bulk_provisioning_job_template.csv'"
        data-automation="bulk-provisioning-upload-csv-download-template-link"
      >
        <i [attr.class]="smacsIcons.DOWNLOAD + ' me-1'"></i>
        <strong>{{ 'tkey;zero_touch.bulk_provisioning.upload_csv.download_template' | translate }}</strong>
      </a>
    </div>
  </div>

  <smacs-file-upload fieldId="csv"></smacs-file-upload>

  <div class="row">
    <div class="col-12">
      <div
        *ngIf="hasError"
        class="alert alert-danger w-100"
        data-automation="bulk-provisioning-upload-csv-invalid-file-alert"
      >
        <span>{{ 'tkey;zero_touch.bulk_provisioning.upload_csv.error' | translate }}</span>
      </div>
    </div>
  </div>

  <div class="row h-100">
    <div class="col-12 h-100">
      <app-loading-block [hidden]="!isLoadingFile"></app-loading-block>

      <table
        *ngIf="fileContents.length > 0"
        class="table table-hover border scrollable-table"
        data-automation="bulk-provisioning-upload-csv-table"
      >
        <thead>
          <tr class="table-info">
            <th class="col-3">
              {{ 'tkey;zero_touch.bulk_provisioning.upload_csv.username' | translate }}
            </th>
            <th class="col-3">
              {{ 'tkey;zero_touch.bulk_provisioning.upload_csv.primary_extension' | translate }}
            </th>
            <th class="col-3">
              {{ 'tkey;zero_touch.bulk_provisioning.upload_csv.deskphone_model' | translate }}
            </th>
            <th class="col-3">
              {{ 'tkey;zero_touch.bulk_provisioning.upload_csv.mac_address' | translate }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            *ngFor="let fileRow of fileContents"
            [ngClass]="
              fileRow.usernameError ||
              fileRow.primaryExtensionError ||
              fileRow.deskphoneError ||
              fileRow.macAddressError
                ? 'table-danger'
                : ''
            "
          >
            <td class="col-3">
              <div class="input-group">
                <span class="d-block">{{ fileRow.username }}</span>
                <span class="d-block invalid-feedback">{{ fileRow.usernameError | translate }}</span>
              </div>
            </td>
            <td class="col-3">
              <div class="input-group">
                <span class="d-block">{{ fileRow.primaryExtension }}</span>
                <span class="d-block invalid-feedback">{{ fileRow.primaryExtensionError | translate }}</span>
              </div>
            </td>
            <td class="col-3">
              <div class="input-group">
                <span class="d-block">{{ fileRow.deskphone }}</span>
                <span class="d-block invalid-feedback">{{ fileRow.deskphoneError | translate }}</span>
              </div>
            </td>
            <td class="col-3">
              <div class="input-group">
                <span class="d-block">{{ fileRow.macAddress }}</span>
                <span class="d-block invalid-feedback">{{ fileRow.macAddressError | translate }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row my-3">
    <div class="col-12">
      <div class="float-end">
        <button
          type="button"
          class="btn btn-outline-info me-1"
          (click)="goToGeneralView()"
          data-automation="bulk-provisioning-upload-csv-back-button"
        >
          <span>{{ 'tkey;selfserve.back.button' | translate }}</span>
        </button>
        <smacs-submit-button
          [isPending]="isSubmitting"
          [isDisabled]="hasError || isSubmitting || fileContents.length === 0"
          [label]="'tkey;pages.zero_touch.job.run_now'"
          [dataAutomation]="'bulk-provisioning-upload-csv-run-now-button'"
          [icon]="smacsIcons.NONE"
          [iconPending]="smacsIcons.SAVING"
          [formSubmitSubject]="_validateAndSubmitSource"
        ></smacs-submit-button>
      </div>
    </div>
  </div>
</div>
