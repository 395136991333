import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../../button/button.component';
import {
  EntityTable,
  EntityTableBadges,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../../shared/entity-table/entity-table.models';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { Router } from '@angular/router';
import {
  JobState,
  MicrosoftBulkJobDraft,
  MicrosoftBulkJobStatus,
  MicrosoftBulkJobUserDraft,
  MicrosoftDialPlanGroup,
} from '../../../../shared/models/generated/smacsModels';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ToastService } from '../../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { MicrosoftBulkProvisioningDraftsContext } from '../../../../shared/contexts/microsoft-bulk-provisioning-drafts.context';
import { MsDialPlanGroupsContext } from '../../../../admin/contexts/ms-dial-plan-groups.context';
import { MicrosoftBulkProvisioningPollingContext } from '../../../../shared/contexts/microsoft-bulk-provisioning-polling.context';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { TelephoneNumberFilter } from '../../../../shared/filters/telephone-number.filter';

@Component({
  selector: 'microsoft-bulk-provisioning-drafts-list',
  templateUrl: './bulk-provisioning-drafts-list.component.html',
  styleUrls: ['../../../../admin/admin-page.scss'],
  providers: [MicrosoftBulkProvisioningPollingContext],
})
export class MicrosoftBulkProvisioningDraftsListComponent implements OnInit, OnDestroy {
  SmacsIcons = SmacsIcons;
  isLoading = true;
  isRunning = false;
  table: EntityTable = {
    columns: [
      {
        columnId: 'jobName',
        cssColumnSize: 'col-sm-3',
        label: 'tkey;automate.zpm.bulk_provisioning.drafts.table.job_name',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'dialPlanGroup',
        cssColumnSize: 'col-sm-3',
        label: 'tkey;automate.zpm.bulk_provisioning.drafts.table.dial_plan_group',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'users',
        cssColumnSize: 'col-sm-5',
        label: 'tkey;automate.zpm.bulk_provisioning.drafts.table.users',
        filter: {
          type: EntityTableFilterTypes.TEXT,
          filterFn: (rows, filterValue) => {
            if (!filterValue) {
              return rows;
            }

            return rows.filter((row: EntityTableContentRow) => {
              const badges: EntityTableBadges = row.badges['users'];
              const valueMatch = badges.values.some((val: string) =>
                val.toLowerCase().includes(filterValue.toLowerCase())
              );
              const tooltipMatch = badges.tooltips.some((val: string) =>
                val.toLowerCase().includes(filterValue.toLowerCase())
              );
              return valueMatch || tooltipMatch;
            });
          },
        },
      },
    ],
    hasActions: true,
    cssClearButtonColumnSize: 'col-sm-1',
  };
  tableRows: EntityTableContentRow[] = [];
  bulkJobStatus: MicrosoftBulkJobStatus;

  private _subscriptions = new Subscription();

  constructor(
    private _bottomNavService: BottomNavService,
    private _breadcrumbsService: BreadcrumbsService,
    private _router: Router,
    private _microsoftBulkProvisioningDraftsContext: MicrosoftBulkProvisioningDraftsContext,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    private _smacsModalService: SmacsModalService,
    private _msDialPlanGroupsContext: MsDialPlanGroupsContext,
    private _microsoftBulkProvisioningPollingContext: MicrosoftBulkProvisioningPollingContext,
    private _telephoneNumberFilter: TelephoneNumberFilter
  ) {}

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;automate.zpm.bulk_provisioning.drafts.title' }]);
    this._initBottomNav();
    this._initContexts();
    this._initPolling();
  }

  ngOnDestroy() {
    this._breadcrumbsService.clearBreadcrumbs();
    this._microsoftBulkProvisioningPollingContext.stopPolling();
    this._subscriptions.unsubscribe();
  }

  private _initContexts() {
    const subscription = combineLatest([
      this._microsoftBulkProvisioningDraftsContext.state$,
      this._msDialPlanGroupsContext.state$,
    ]).subscribe(([drafts, dialPlanGroups]: [MicrosoftBulkJobDraft[], MicrosoftDialPlanGroup[]]) => {
      this.tableRows = drafts.map((draft: MicrosoftBulkJobDraft) => {
        const userBadges: EntityTableBadges = {
          values: draft.users.map((user: MicrosoftBulkJobUserDraft) => user.userPrincipalName),
          tooltips: draft.users.map((user: MicrosoftBulkJobUserDraft) => {
            if (user.phoneNumberAssignment.lineUri && user.phoneNumberAssignment.lineUriExtension) {
              const formattedLineUri = this._telephoneNumberFilter.transform(user.phoneNumberAssignment.lineUri);

              return `${formattedLineUri};ext=${user.phoneNumberAssignment.lineUriExtension}`;
            } else if (user.phoneNumberAssignment.lineUri) {
              const formattedLineUri = this._telephoneNumberFilter.transform(user.phoneNumberAssignment.lineUri);

              return `${formattedLineUri}`;
            }

            return '';
          }),
          displayToolTip: true,
        };
        const dpg: MicrosoftDialPlanGroup = dialPlanGroups.find(
          (dialPlanGroup: MicrosoftDialPlanGroup) => dialPlanGroup.id === draft.dialPlanGroupId
        );

        return {
          content: {
            jobId: draft.bulkJobId,
            jobName: draft.bulkJobName,
            dialPlanGroup: dpg?.name,
            users: draft.users.map((user: MicrosoftBulkJobUserDraft) => user.userPrincipalName),
          },
          badges: {
            users: userBadges,
          },
          actions: [
            {
              buttonStyle: ButtonStyles.PRIMARY,
              dataAutomation: 'ms-bulk-drafts-list-edit',
              icon: SmacsIcons.EDIT,
              onClick: () => {
                this._router.navigate([`/automate/microsoft/bulk-provisioning/${draft.bulkJobId}`]);
              },
            },
            {
              buttonStyle: ButtonStyles.DANGER,
              dataAutomation: 'ms-bulk-drafts-list-delete',
              icon: SmacsIcons.DELETE,
              onClick: () => this._deleteClicked(draft.bulkJobId.toString(), draft.bulkJobName),
            },
          ],
        };
      });

      this.isLoading = false;
    });
    this._subscriptions.add(subscription);
  }

  private _initBottomNav() {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'ms-bulk-drafts-list-add-button',
          dataAutomation: 'ms-bulk-drafts-list-add-button',
          label: 'tkey;automate.zpm.bulk_provisioning.drafts.create',
          icon: SmacsIcons.ADD,
          buttonClass: ButtonStyles.PRIMARY,
          cb: () => {
            this._router.navigate(['/automate/microsoft/bulk-provisioning/new']);
          },
        },
      ])
    );
  }

  private _deleteClicked(draftId: string, draftName: string) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        promptBody: this._translateService.instant('tkey;automate.zpm.bulk_provisioning.drafts.modal.delete.body', {
          groupName: draftName,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteDraft(draftId, draftName);
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteDraft(draftId: string, draftName: string): Observable<void> {
    return this._microsoftBulkProvisioningDraftsContext.delete(draftId).pipe(
      tap(() => {
        this._toastService.pushDeleteToast('tkey;automate.zpm.bulk_provisioning.drafts.toast', draftName);
      })
    );
  }

  private _initPolling() {
    this._microsoftBulkProvisioningPollingContext.state$.subscribe((state: MicrosoftBulkJobStatus) => {
      this.bulkJobStatus = state;

      if (this.isRunning && this.bulkJobStatus.jobStatus.jobState === JobState.IDLE) {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.AUTOMATION,
          'tkey;pages.zero_touch.job.completed',
          'tkey;pages.zero_touch.bulk_provisioning.title'
        );
      }

      this.isRunning = state.jobStatus.jobState === JobState.RUNNING || state.jobStatus.jobState === JobState.QUEUED;

      this.tableRows = this.tableRows.map((row: EntityTableContentRow) => {
        if (
          row.content['jobName'] === state.jobDetailedStatus?.jobName &&
          (this.isRunning || state.jobStatus.jobState === JobState.QUEUED)
        ) {
          return {
            ...row,
            cssClass: 'table-warning',
            actions: [
              {
                buttonStyle: ButtonStyles.PRIMARY,
                dataAutomation: 'ms-bulk-drafts-list-edit',
                icon: SmacsIcons.EDIT,
                isDisabled: true,
                tooltip: 'tkey;automate.zpm.bulk_provisioning.drafts.in_progress.disabled.tooltip',
                tooltipDisabled: false,
                onClick: () => {
                  this._router.navigate([`/automate/microsoft/bulk-provisioning/${row.content['jobId']}`]);
                },
              },
              {
                buttonStyle: ButtonStyles.DANGER,
                dataAutomation: 'ms-bulk-drafts-list-delete',
                icon: SmacsIcons.DELETE,
                isDisabled: true,
                tooltip: 'tkey;automate.zpm.bulk_provisioning.drafts.in_progress.disabled.tooltip',
                tooltipDisabled: false,
                onClick: () => this._deleteClicked(row.content['jobId'], row.content['jobName']),
              },
              {
                buttonStyle: ButtonStyles.NONE,
                dataAutomation: 'ms-bulk-drafts-list-running',
                icon: SmacsIcons.SAVING,
                onClick: () => {},
              },
            ],
          };
        }

        return {
          ...row,
          cssClass: '',
          actions: [
            {
              buttonStyle: ButtonStyles.PRIMARY,
              dataAutomation: 'ms-bulk-drafts-list-edit',
              icon: SmacsIcons.EDIT,
              isDisabled: false,
              tooltip: '',
              tooltipDisabled: true,
              onClick: () => {
                this._router.navigate([`/automate/microsoft/bulk-provisioning/${row.content['jobId']}`]);
              },
            },
            {
              buttonStyle: ButtonStyles.DANGER,
              dataAutomation: 'ms-bulk-drafts-list-delete',
              icon: SmacsIcons.DELETE,
              isDisabled: false,
              tooltip: '',
              tooltipDisabled: true,
              onClick: () => this._deleteClicked(row.content['jobId'], row.content['jobName']),
            },
          ],
        };
      });
    });
    this._microsoftBulkProvisioningPollingContext.startPolling();
  }

  protected readonly jobState = JobState;
}
