<div
  class="mb-3 row form-group"
  [hidden]="
    ((!state.hasOwnProperty('hidden') || state.hidden) && (!forceShow || state.defaultValue === null)) ||
    getHiddenState()
  "
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted || misconfigurationFeedbackMessage,
    'smacs-forms-error': (showValidation || isFormSubmitted) && validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING ||
      validationState === ValStates.WARNING ||
      (misconfigurationFeedbackMessage && state.hidden),
    'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
  }"
>
  <div class="col-3 d-flex align-items-center justify-content-end text-end">
    <label
      [attr.for]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
      data-automation="custom-input-text-label"
    >
      <ng-template #tipContent><span [innerHtml]="config.labelToolTipText | translate"></span></ng-template>
      <div class="d-inline-block">
        <div [ngbTooltip]="tipContent" [disableTooltip]="!config.labelToolTipText" class="fit-content d-inline-block">
          <span *ngIf="entity?.required && !entity?.show" class="text-danger smacs-forms-asterisk">*</span>
          <span [innerHtml]="getLabel() | translate"></span>
          <i *ngIf="config.labelToolTipIconClass" class="{{ config.labelToolTipIconClass }} ms-1"></i>
        </div>
      </div>
    </label>
  </div>

  <div class="col-9">
    <div class="d-flex">
      <div class="input-group" [ngbTooltip]="tooltipContent" [disableTooltip]="shouldDisableTooltip">
        <ng-template #tooltipContent>
          <span [innerHTML]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"></span>
        </ng-template>
        <input
          class="pe-4 border-rad-all-2 form-control"
          [ngClass]="{
              'border-danger': (showValidation || isFormSubmitted) && validationState === ValStates.INVALID,
              'border-warning': validationState === ValStates.WARNING ||
              (misconfigurationFeedbackMessage && state.hidden),
            }"
          [disabled]="state.disabled"
          [type]="htmlInputType.TEXT"
          [attr.id]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
          data-automation="custom-input-text"
          (ngModelChange)="handleTextChange($event)"
          [(ngModel)]="textValue"
          [ngModelOptions]="{ standalone: true }"
        />
        <button
          *ngIf="!!editorType"
          type="button"
          class="btn btn-default btn-expression-editor ms-0"
          data-automation="expression-editor"
          (click)="openModal()"
          [disabled]="state.disabled"
        >
          <i [attr.class]="smacsIcons.EDIT"></i>
          {{ 'tkey;admin.site_management.custom_input_text_modal.mandatory_functions.button' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="smacs-forms-feedback-area col-9 offset-3">
    <div
      *ngIf="(showValidation && !!validationMessage?.content) || isFormSubmitted"
      class="smacs-forms-validation-message"
      data-automation="smacs-forms-validation-message"
      [ngClass]="{ 'd-inline-block me-1': validationMessage && (showValidation || isFormSubmitted) }"
      [innerHTML]="validationMessage?.content | translate : validationMessage?.params"
    ></div>
  </div>
  <div class="col-lg-9 offset-lg-3" [ngClass]="{ 'pb-2': !hideCheckboxes }">
    <div class="pt-2 pb-2 text-80">
      <span class="me-1">
        <i [attr.class]="smacsIcons.PREVIEW + ' d-inline-block vertical-align-middle'"></i>
        <span>{{ 'tkey;site_management.site.section.preview.label' | translate }}</span>
      </span>
      <span *ngIf="!!previewText" data-automation="form-preview">{{ previewText }}</span>
    </div>
    <div class="d-flex align-items-center">
      <div class="d-flex align-items-center" data-automation="required-checkbox">
        <input
          type="checkbox"
          class="form-check-input mt-0 mb-1 rounded-0"
          [attr.id]="config.dataAutomation + '-required'"
          [(ngModel)]="isRequiredChecked"
          (ngModelChange)="onRequiredChange($event)"
          [disabled]="isAlwaysRequired || state.disabled"
          name="requiredCheck"
          [hidden]="hideCheckboxes"
        />
        <div>
          <label
            class="form-check-label col-form-label text-start pt-0"
            [ngClass]="{ 'not-allowed disabled': isAlwaysRequired }"
            [attr.for]="config.dataAutomation + '-required'"
            [hidden]="hideCheckboxes"
          >
            <span class="p-0 ms-2" [innerHTML]="requiredLabel | translate"></span>
          </label>
        </div>
      </div>
      <div class="d-flex align-items-center" data-automation="show-checkbox">
        <input
          type="checkbox"
          class="form-check-input mt-0 mb-1 ms-3 rounded-0"
          [attr.id]="config.dataAutomation + '-show'"
          [(ngModel)]="isShowChecked"
          (ngModelChange)="onShowChange($event)"
          name="showCheck"
          [ngModelOptions]="{ standalone: true }"
          [hidden]="hideCheckboxes"
        />
        <div>
          <label
            class="form-check-label col-form-label text-start pt-0"
            [attr.for]="config.dataAutomation + '-show'"
            [hidden]="hideCheckboxes"
          >
            <span class="p-0 ms-2" [innerHTML]="showLabel | translate"></span>
          </label>
        </div>
      </div>
    </div>
    <div
      *ngIf="getHelpText()"
      class="small form-text text-muted"
      [innerHtml]="getHelpText() | translate"
      [attr.data-automation]="config.dataAutomation + 'helpText'"
    ></div>
  </div>
</div>
