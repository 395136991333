import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[datatableFilterAbstract]',
})
export abstract class DatatableFilterAbstractDirective<T> {
  /** The label to display in the template. */
  @Input() label: string;

  /**
   * A function which returns whether a given row matches the filter value.
   * In other words, this returns true if the row should be displayed.
   * @param value The current value of this filter.
   * @param tableRow The row to check.
   * @return boolean
   * */
  @Input() matchFn: (value: T, tableRow: any) => boolean;
  @Input({ required: true }) dataAutomation: `${string}-filter`;
  @Input({ required: true }) tableId: string;

  @Output() isInitialized = new EventEmitter<void>();

  value: T;
  isViewLoading = false;
  abstract clear: () => void;

  filterUpdateSource = new Subject<void>();
  filterUpdate$ = this.filterUpdateSource.asObservable();

  constructor() {
    this.filterUpdate$.subscribe(() => {
      const sessionFilterParams = sessionStorage.getItem('filterParams');
      let allFilterParams;

      if (!!sessionFilterParams) {
        allFilterParams = JSON.parse(sessionFilterParams);
        if (!!allFilterParams[this.tableId]) {
          const updatedFilterParams = { ...allFilterParams[this.tableId], [this.dataAutomation]: this.value };
          sessionStorage.setItem(
            'filterParams',
            JSON.stringify({ ...allFilterParams, [this.tableId]: updatedFilterParams })
          );
        } else {
          const newFilterParams = { [this.dataAutomation]: this.value };
          sessionStorage.setItem(
            'filterParams',
            JSON.stringify({ ...allFilterParams, [this.tableId]: newFilterParams })
          );
        }
      } else {
        const newFilterParams = { [this.dataAutomation]: this.value };
        sessionStorage.setItem('filterParams', JSON.stringify({ [this.tableId]: newFilterParams }));
      }
    });
  }

  /** The function that the datatable listens to. Returns true if the row should be displayed. */
  matches = (tableRow: any) => {
    return this.matchFn(this.value, tableRow);
  };
}
