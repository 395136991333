<div class="d-inline-block position-relative">
  <ng-container *ngIf="keyword.type === keywords.CALLS_PLACED_OR_RECEIVED">
    <ng-select
      [attr.data-automation]="'expression-keyword-direction'"
      [placeholder]="keyword.type | translate"
      [(ngModel)]="keyword.keywordValue.value"
      [items]="keyword.choices"
      [clearable]="false"
      [searchable]="false"
      (ngModelChange)="handleModelChange()"
      bindLabel="selection"
      bindValue="value"
      class="expression-keyword__dropdown"
    ></ng-select>
  </ng-container>
  <ng-container *ngIf="keyword.type === keywords.EXTENSION_USERNAME_OR_DEVICENAME">
    <span
      class="expression-keyword"
      popoverClass="keyword-popover"
      data-automation="expression-keyword-input-toggle"
      [ngbPopover]="inputtemplate"
      [autoClose]="'outside'"
      [placement]="'bottom'"
      [ngClass]="{
        'expression-keyword--error': keyword.hasError
      }"
      (hidden)="handlePopoverClose()"
    >
      {{ inputTemplatePlaceholder | translate }}
    </span>
  </ng-container>
  <ng-container *ngIf="keyword.type === keywords.FROM_DATE">
    <div class="p-field">
      <ng-container *ngIf="keyword.keywordValue?.value">
        <label
          data-automation="expression-keyword-from-label"
          class="expression-keyword mb-0"
          [attr.for]="'fromDate'"
          [ngClass]="{ 'expression-keyword--error': keyword.hasError }"
        >
          {{ keyword.keywordValue?.value | date : 'MM/dd/yy' }}
        </label>
      </ng-container>
      <ng-container *ngIf="!keyword.keywordValue?.value">
        <label
          data-automation="expression-keyword-from-label"
          class="expression-keyword expression-keyword--empty mb-0"
          [attr.for]="'fromDate'"
        >
          {{ keyword.type | translate }}
        </label>
      </ng-container>
      <p-calendar
        [attr.data-automation]="'expression-keyword-from'"
        [(ngModel)]="keyword.keywordValue.value"
        (ngModelChange)="handleModelChange()"
        inputId="fromDate"
        [showTime]="false"
        panelStyleClass="keyword-datepicker"
        inputStyleClass="keyword-datepicker-input"
        [@.disabled]="true"
      ></p-calendar>
    </div>
  </ng-container>
  <ng-container *ngIf="keyword.type === keywords.TO_DATE">
    <div class="p-field">
      <ng-container *ngIf="keyword.keywordValue?.value">
        <label
          data-automation="expression-keyword-to-label"
          class="expression-keyword mb-0"
          [attr.for]="'toDate'"
          [ngClass]="{ 'expression-keyword--error': keyword.hasError }"
        >
          {{ keyword.keywordValue?.value | date : 'MM/dd/yy' }}
        </label>
      </ng-container>
      <ng-container *ngIf="!keyword.keywordValue?.value">
        <label
          data-automation="expression-keyword-to-label"
          class="expression-keyword expression-keyword--empty mb-0"
          [attr.for]="'toDate'"
        >
          {{ keyword.type | translate }}
        </label>
      </ng-container>
      <p-calendar
        [attr.data-automation]="'expression-keyword-to'"
        [(ngModel)]="keyword.keywordValue.value"
        (ngModelChange)="handleModelChange()"
        [showTime]="false"
        panelStyleClass="keyword-datepicker"
        inputStyleClass="keyword-datepicker-input"
        [inputId]="'toDate'"
        [@.disabled]="true"
      ></p-calendar>
    </div>
  </ng-container>
  <ng-template #inputtemplate>
    <div class="p-2">
      <label class="form-label" [attr.for]="'input-dropdown'">
        {{ 'tkey;reporting.call_logs.keyword.input.selection.label' | translate }}
      </label>
      <ng-select
        [attr.data-automation]="'input-selection'"
        [placeholder]="'Extension/username/device name'"
        [items]="keyword.choices"
        [clearable]="false"
        [searchable]="false"
        [labelForId]="'input-dropdown'"
        [(ngModel)]="keyword.keywordValue.selection"
        (ngModelChange)="handleSelectionChange()"
        bindLabel="selection"
        bindValue="value"
        class="input-dropdown"
      ></ng-select>
      <div class="mt-3" *ngIf="keyword.keywordValue.selection">
        <div>
          <label class="form-label" [attr.for]="'textfield-input'">
            {{
              'tkey;reporting.call_logs.keyword.input.value.label'
                | translate
                  : {
                      searchValue:
                        keyword.keywordValue.selection === 'extension'
                          ? 'an extension'
                          : 'a ' + keyword.keywordValue.selection
                    }
            }}
          </label>
        </div>
        <div>
          <ng-container *ngIf="keyword.keywordValue.selection === 'extension'">
            <smacs-expression-editor-keyword-extension-search
              [entity]="
                keyword.keywordValue?.value
                  ? { extensionUsernameOrDeviceName: { value: keyword.keywordValue?.value } }
                  : null
              "
              (smacsFormsUpdate$)="handleInputChange($event)"
              class="keyword-search"
            ></smacs-expression-editor-keyword-extension-search>
          </ng-container>
          <ng-container *ngIf="keyword.keywordValue.selection === 'username'">
            <smacs-expression-editor-keyword-username-search
              [entity]="
                keyword.keywordValue?.value
                  ? { extensionUsernameOrDeviceName: { value: keyword.keywordValue?.value } }
                  : null
              "
              (smacsFormsUpdate$)="handleInputChange($event)"
              class="keyword-search"
            ></smacs-expression-editor-keyword-username-search>
          </ng-container>
          <ng-container *ngIf="keyword.keywordValue.selection === 'device'">
            <smacs-expression-editor-keyword-device-search
              [entity]="
                keyword.keywordValue?.value
                  ? { extensionUsernameOrDeviceName: { value: keyword.keywordValue?.value } }
                  : null
              "
              (smacsFormsUpdate$)="handleInputChange($event)"
              class="keyword-search"
            ></smacs-expression-editor-keyword-device-search>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-template>
</div>
