import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';
import { DirectoryNumberRef, TranslationPatternRef } from '../../../shared/models/generated/smacsModels';
import { DnDetailService } from '../../shared/services/dn-detail.service';

@Component({
  selector: 'smacs-dn-detail-summary-info',
  templateUrl: './dn-detail-summary-info.component.html',
})
export class SmacsDnDetailSummaryInfoComponent implements OnInit, OnChanges {
  @Input() translationPatternRef: TranslationPatternRef[];
  @Input() directoryNumberRef: DirectoryNumberRef;

  did: string;
  didLink: string;

  constructor(private dnDetailService: DnDetailService) {}

  ngOnInit() {
    this._throwErrorIfThereAreMultipleDids();
    this._setDid();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.translationPatternRef &&
      !isEqual(changes.translationPatternRef.currentValue, changes.translationPatternRef.previousValue)
    ) {
      this._setDid();
    }
  }

  extensionLink(): string {
    return this.directoryNumberRef ? `tel:${this.directoryNumberRef.extension}` : '';
  }

  private _setDid() {
    const did = this.dnDetailService.getDid(this.translationPatternRef, false);

    if (did) {
      this.did = did.pattern;
      this.didLink = 'tel:' + did.pattern;
    } else {
      this.did = null;
      this.didLink = null;
    }
  }

  private _throwErrorIfThereAreMultipleDids() {
    this.dnDetailService.getDid(this.translationPatternRef, true);
  }
}
