import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BottomNavService } from '../../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ZeroTouchDeprovisioningResource } from '../../../../shared/resources/zpc-zero-touch.abstract.resource';
import {
  ViewZeroTouchJob,
  ZpcJobsViewAbstractDirective,
} from '../../../shared/jobs-view/zpc-jobs-view-abstract.directive';
import { ToastService } from '../../../../shared/services/toast.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { DeprovisioningSchedule, JobStatus } from '../../../../shared/models/generated/smacsModels';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { CiscoDeprovisioningStatusesPollingService } from '../../../../shared/services/cisco/cisco-deprovisioning-statuses-polling.service';
import { isEqual } from 'lodash';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../../shared/entity-table/entity-table.models';

@Component({
  selector: 'app-admin-zero-touch-deprovisioning-jobs-view',
  templateUrl: './deprovisioning-jobs-view.component.html',
  providers: [CiscoDeprovisioningStatusesPollingService],
})
export class DeprovisioningJobsViewComponent extends ZpcJobsViewAbstractDirective implements OnInit, OnDestroy {
  statusUpdatedSource = new BehaviorSubject([]);
  isLoading = true;
  requestedJobIds = [] as number[];
  smacsIcons = SmacsIcons;
  deprovisioningJobs: ViewZeroTouchJob[];
  filteredResult: ViewZeroTouchJob[] = [];
  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];

  private _subscriptions = new Subscription();

  constructor(
    protected bottomNavService: BottomNavService,
    protected breadcrumbsService: BreadcrumbsService,
    protected zeroTouchDeprovisioningResource: ZeroTouchDeprovisioningResource,
    protected router: Router,
    protected route: ActivatedRoute,
    protected smacsModalService: SmacsModalService,
    protected translateService: TranslateService,
    protected toastService: ToastService,
    private zeroTouchDeprovisioningPollingContext: CiscoDeprovisioningStatusesPollingService
  ) {
    super(
      zeroTouchDeprovisioningResource,
      breadcrumbsService,
      bottomNavService,
      toastService,
      route,
      router,
      translateService,
      smacsModalService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;pages.zero_touch.deprovisioning.title' }]);

    this.zeroTouchDeprovisioningPollingContext.state$.subscribe((state: JobStatus[]) =>
      this.statusUpdatedSource.next(state)
    );
    this.zeroTouchDeprovisioningPollingContext.startPolling();

    const refreshJobsSub = this._refreshJobs().subscribe();
    this._subscriptions.add(refreshJobsSub);
    this._buildForm();
  }

  ngOnDestroy() {
    this.zeroTouchDeprovisioningPollingContext.stopPolling();
    this._subscriptions.unsubscribe();
  }

  runJob(job: ViewZeroTouchJob) {
    if (!job.pending) {
      job.pending = true;
      this._mapJobsToTableRows();
      this.automationResource
        .runJob(job.id)
        .pipe(
          tap(() => {
            this.requestedJobIds.push(job.id);
          }),
          catchError((response) => {
            job.pending = false;
            this._mapJobsToTableRows();
            return throwError(() => response);
          })
        )
        .subscribe();
    }
  }

  private _buildForm() {
    this.zeroTouchJobs$.subscribe((filteredJobs: ViewZeroTouchJob[]) => {
      if (!isEqual(filteredJobs, this.deprovisioningJobs)) {
        this.deprovisioningJobs = filteredJobs;
        this.filteredResult = filteredJobs;
      }
      this.table = {
        columns: [
          {
            columnId: 'jobName',
            cssColumnSize: 'col-sm-6',
            label: 'tkey;pages.zero_touch.job_name',
            filter: {
              type: EntityTableFilterTypes.TEXT,
            },
          },
          {
            columnId: 'schedule',
            cssColumnSize: 'col-sm-3',
            label: 'tkey;pages.zero_touch.schedule',
          },
        ],
        cssClearButtonColumnSize: 'col-sm-3',
        resultsMessage: 'tkey;pages.zero_touch.deprovisioning.no_results',
        hasActions: true,
      };
      this._mapJobsToTableRows();
    });
  }

  private _mapJobsToTableRows() {
    this.tableRows = this.deprovisioningJobs.map((job) => {
      let schedule;
      if (job.dailyJobTime) {
        schedule = this.translateService.instant('tkey;pages.zero_touch.schedule.daily', { time: job.dailyJobTime });
      } else if (job.periodicJobMinutes) {
        schedule = this.translateService.instant('tkey;pages.zero_touch.schedule.periodic', {
          minutes: job.periodicJobMinutes,
        });
      }
      return {
        content: {
          jobName: job.name,
          schedule: schedule,
        },
        cssClass: job.pending ? 'table-warning' : '',
        actions: [
          {
            buttonStyle: ButtonStyles.DANGER,
            dataAutomation: 'zero-touch-deprovisioning-job-delete',
            icon: SmacsIcons.DELETE,
            tooltip: 'tkey;pages.zero_touch.job.run_now.tooltip',
            tooltipDisabled: !job.pending,
            isDisabled: job.pending,
            onClick: () => this.deleteJob(job),
          },
          {
            buttonStyle: ButtonStyles.INFO,
            dataAutomation: 'zero-touch-deprovisioning-job-run-now',
            icon: SmacsIcons.RUN,
            label: 'tkey;pages.zero_touch.job.run_now',
            tooltip: job.pending ? 'tkey;pages.zero_touch.job.run_now.running' : 'tkey;pages.zero_touch.job.run_now',
            tooltipDisabled: !job.pending,
            isDisabled: job.pending,
            isPending: job.pending,
            iconPending: SmacsIcons.SAVING,
            onClick: () => this.runJob(job),
          },
          {
            buttonStyle: ButtonStyles.PRIMARY,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/edit-job/${job.id}`,
            dataAutomation: 'zero-touch-deprovisioning-job-edit',
            icon: SmacsIcons.NEXT,
            tooltip: 'tkey;pages.zero_touch.job.run_now.tooltip',
            tooltipDisabled: !job.pending,
            isDisabled: job.pending,
            onClick: () => this.editJob(job),
          },
        ],
        actionsColumnSize: 'col-sm-2',
      };
    });
  }

  protected _getAllJobs = (): Observable<ViewZeroTouchJob[]> => {
    return this.zeroTouchDeprovisioningResource.getAll().pipe(
      map((jobs: DeprovisioningSchedule[]) => {
        return jobs.map((j: DeprovisioningSchedule) => {
          return {
            id: j.id,
            name: j.name,
            periodicJobMinutes: j.periodicJobMinutes,
            dailyJobTime: j.dailyJobTime,
            pending: false,
          } as ViewZeroTouchJob;
        });
      })
    );
  };
}
