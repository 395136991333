import { Component, Input, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import {
  SmacsFieldComponentConfig,
  SmacsFieldConfig,
  SmacsFieldConfigs,
  SmacsFormConfig,
} from '../../../../../forms/smacs-forms-models';
import { SmacsTextConfig } from '../../../../../forms/fields/text/smacs-text.component';
import { SmacsRadioConfig } from '../../../../../forms/fields/radio/smacs-radio.component';
import { SmacsCheckboxConfig } from '../../../../../forms/fields/checkbox/smacs-checkbox.component';
import { SmacsNavsetItemNavItem } from '../../smacs-navset-form.component';
import { of } from 'rxjs';
import { SmacsNavsetItemConfig } from '../../../smacs-navset.component';

@Component({
  selector: 'smacs-navset-form-nav-items',
  templateUrl: './smacs-navset-form-nav-items.component.html',
  styleUrls: ['./smacs-navset-form-nav-items.component.scss'],
})
export class SmacsNavsetFormNavItemsComponent
  extends SmacsFormAbstractDirective<SmacsFieldComponentConfig, SmacsFieldComponentConfig>
  implements OnInit
{
  @Input() formConfig: SmacsFormConfig;
  @Input() item: SmacsNavsetItemNavItem;
  isHidden = false;
  fieldNames: string[] = [];

  ngOnInit(): void {
    this.fieldNames = Object.keys(this.formConfig.fields);
    this.entitySource.next({
      ...this.item.fields,
    });
  }

  protected submit() {
    return of(null);
  }

  // Determine if the field is SmacsTextComponent
  isFieldSmacsText(field: SmacsFieldConfig<any>): field is SmacsFieldConfig<SmacsTextConfig> {
    return field.componentConfig instanceof SmacsTextConfig;
  }

  // Determine if the field is SmacsRadioComponent
  isFieldSmacsRadio(field: SmacsFieldConfig<any>): field is SmacsFieldConfig<SmacsRadioConfig<any>> {
    return field.componentConfig instanceof SmacsRadioConfig;
  }

  // Determine if the field is SmacsCheckboxComponent
  isFieldCheckbox(field: SmacsFieldConfig<any>): field is SmacsFieldConfig<SmacsCheckboxConfig> {
    return field.componentConfig instanceof SmacsCheckboxConfig;
  }

  getTitle(field: SmacsFieldConfigs[string]): string {
    return (field as SmacsNavsetItemConfig[string]).title;
  }

  getLabel(label: string | (() => string)): string {
    return typeof label === 'string' ? label : label();
  }

  validate() {
    this._validateAndSubmitSource.next(false);
  }
}
