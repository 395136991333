import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, Subscriber } from 'rxjs';
import {
  ClusterResult,
  DevicePoolResult,
  EndUserResult,
  Global360View,
  Phone,
  PhoneRef,
  SiteResult,
  SiteSearchResult,
  SiteSummary,
} from '../../../shared/models/generated/smacsModels';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../button/button.component';
import { SitePickerModalComponent } from '../../../modals/site-picker-modal/site-picker-modal.component';
import { Router } from '@angular/router';
import { uniq } from 'lodash';

export interface ClusterSite {
  site: string;
  id: string;
  cluster: string;
}

interface DeviceSummary {
  name: string;
  devicePool: string;
}

@Injectable()
export class SiteContext {
  _stateSource = new ReplaySubject<SiteResult>(1);
  state$ = this._stateSource.asObservable();

  private _global360View: Global360View;

  private static _isSameEndUser(previousState: Global360View, currentState: Global360View) {
    return previousState.endUsers[0].ref.id === currentState.endUsers[0].ref.id;
  }

  private initialSite: SiteResult;

  constructor(
    private currentClusterContext: CurrentClusterContext,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private router: Router
  ) {}

  setSiteForUser(global360View: Global360View, siteSummary: SiteSummary, newSite?: SiteResult) {
    if (newSite) {
      this._global360View = global360View;
      this._stateSource.next(newSite);
      // Prompt user for site when they haven't already selected one, or it's a different 360 view
    } else if (!this._global360View || !SiteContext._isSameEndUser(this._global360View, global360View)) {
      this._getSiteForEndUser(global360View, siteSummary).subscribe((site) => {
        if (site) {
          this._global360View = global360View;
          this._stateSource.next(site);
        }
      });
    }
  }

  setSiteForUserOnCluster(
    global360View: Global360View,
    cluster: ClusterResult,
    initialSite: SiteResult,
    siteSummary: SiteSummary
  ) {
    this.initialSite = initialSite;

    this._getSiteForEndUserOnCluster(global360View, cluster, siteSummary).subscribe((site) => {
      if (site) {
        this._global360View = global360View;
        this._stateSource.next(site);
      }
    });
  }

  setSiteForPublicPhone(siteSummary: SiteSummary, phone?: Phone) {
    this.currentClusterContext.state$.pipe(first()).subscribe((state) => {
      const cucmServerId = state.cucmServerId;
      const allowedSites = this.currentClusterContext
        .getAllowedSitesOnClusters(siteSummary.clusters)
        .filter((siteResult: SiteResult) => {
          const hasDeskphone = siteResult.devicePools.find(
            (devicePoolResult: DevicePoolResult) => devicePoolResult.serviceName === 'Desk Phone'
          );
          if (!!hasDeskphone) {
            return siteResult;
          }
        });

      if (phone) {
        // It is possible that CUCM servers maybe shared
        const filterClusters = siteSummary.clusters.filter(
          (clusterResult) => clusterResult.cucmServerId === cucmServerId
        );
        const siteResults: SiteResult[] = filterClusters.flatMap((cluster) => {
          return cluster.sites.filter((site) => {
            return site.devicePools.find((dpResult) => {
              return dpResult.serviceName === 'Desk Phone' && dpResult.devicePool === phone.devicePool;
            });
          });
        });

        const forbiddenSite = siteResults.find((siteResult) => {
          return !allowedSites.some((allowedSite) => allowedSite.id === siteResult.id);
        });

        if (forbiddenSite) {
          this._showForbiddenModal(forbiddenSite.name);
        } else if (siteResults.length === 1) {
          const publicPhoneSite = siteResults[0];
          this._stateSource.next(publicPhoneSite);
        } else if (siteResults.length > 1) {
          this._promptUserToPickSite('public-phone', siteResults, this._global360View, siteSummary).subscribe(
            (site) => {
              this._stateSource.next(site);
            }
          );
        } else {
          const options = {
            modalViewProperties: {
              icon: SmacsIcons.CONFIG,
              iconClass: 'text-danger',
              title: 'tkey;unsupported.site.not.found.title',
              promptBody:
                this.translateService.instant('tkey;unsupported.site.not.found.public_phone.text', {
                  phoneName: phone.name,
                  devicePool: phone.devicePool,
                }) + this.translateService.instant('tkey;unsupported.site.not.found.contact'),
              displayCloseButton: false,
              buttons: [
                {
                  label: 'tkey;dialogs.button.ok',
                  buttonClass: ButtonStyles.PRIMARY,
                  dataAutomation: 'unconfigured-device-pool-modal-ok-button',
                  isSubmitButton: false,
                },
              ],
            },
          };
          this.smacsModalService
            .openPromptModal(() => options.modalViewProperties, options)
            .subscribe(() => {
              this.router.navigateByUrl('/');
            });
        }
      } else {
        this._promptUserToPickSite('public-phone', allowedSites, this._global360View, siteSummary).subscribe((site) => {
          this._stateSource.next(site);
        });
      }
    });
  }

  /**
   * This context should be a component-scoped context. Until we fix that, this method will allow us to clear
   * out the state when needed.
   */
  clear() {
    this._stateSource = new ReplaySubject<SiteResult>(1);
    this.state$ = this._stateSource.asObservable();
  }

  private _getSiteForEndUserOnCluster(
    global360View: Global360View,
    cluster: ClusterResult,
    siteSummary: SiteSummary
  ): Observable<SiteResult> {
    const endUserServerIds = global360View.endUsers.map((endUserResult: EndUserResult) => endUserResult.ref.serverId);
    const allSites = this.currentClusterContext.getAllowedSitesOnClustersForUser(
      siteSummary.clusters,
      endUserServerIds
    );
    let endUserSites = cluster.sites.filter((sr: SiteResult) => allSites.find((s: SiteResult) => s.id === sr.id));
    const endUserSite = this._global360View.sites.find((sr) => sr.clusterId === cluster.id);
    if (endUserSite) {
      endUserSites = endUserSites.filter((sr: SiteResult) => sr.id === endUserSite.id);
    }

    if (this._hasPhonesOrSnr(global360View) && !this._hasPrimaryExtension(global360View)) {
      return this._showCannotFindSiteModal(global360View);
    } else if (this._hasImpOrExtMob(global360View) && !this._hasPrimaryExtension(global360View)) {
      const siteIds = this._getSiteIdsForImPresenceAndExtensionMobility(global360View, siteSummary);
      return this._promptUserToPickSite(
        'existing-user',
        allSites.filter((site) => siteIds.includes(site.id)) || allSites,
        global360View,
        siteSummary
      );
    } else if (
      !endUserSites.some((siteResult) =>
        global360View.sites.some((global360Site) => global360Site.id === siteResult.id)
      )
    ) {
      return this._promptUserToPickSite('new-user', endUserSites, global360View, siteSummary);
    } else if (endUserSites.length === 1) {
      this.currentClusterContext.setCurrentClusterFromSite(endUserSites[0].id);
      this._throwErrorIfSiteIsNotPermitted(endUserSites[0]);
      return of(endUserSites[0]);
    }
  }

  private _getSiteForEndUser(global360View: Global360View, siteSummary: SiteSummary): Observable<SiteResult> {
    const endUserServerIds = global360View.endUsers.map((endUserResult: EndUserResult) => endUserResult.ref.serverId);
    const allSites = this.currentClusterContext.getAllowedSitesOnClustersForUser(
      siteSummary.clusters,
      endUserServerIds
    );
    const endUserSites = global360View.sites
      .map((sr: SiteSearchResult) => {
        const matchingSite = allSites.find((s: SiteResult) => s.id === sr.id);
        if (matchingSite) {
          return matchingSite;
        }
      })
      .filter((s: SiteResult) => !!s);
    // No clusters are configured but we still want to display the 360 view
    if (siteSummary.clusters.length === 0) {
      const blankSite: SiteResult = {
        devicePools: [],
        hasPermission: false,
        id: -1,
        ldapSiteName: '',
        name: '',
        unityServerDescription: '',
        unityServerId: 0,
      };
      return of(blankSite);
    }
    if (endUserSites.length === 0) {
      if (global360View.sites.length) {
        this._showForbiddenModal(global360View.sites[0]['name']);
        return of(null);
      } else if (this._hasPhonesOrSnr(global360View)) {
        return this._showCannotFindSiteModal(global360View);
      } else if (this._hasImpOrExtMob(global360View)) {
        const siteIds = this._getSiteIdsForImPresenceAndExtensionMobility(global360View, siteSummary);
        return this._promptUserToPickSite(
          'existing-user',
          allSites.filter((site) => siteIds.includes(site.id)) || allSites,
          global360View,
          siteSummary
        );
      } else if (this._hasPrimaryExtension(global360View)) {
        const clusters = siteSummary.clusters;
        const sites = this._listSitesOfMatchedCluster(clusters, global360View);
        return this._promptUserToPickSite('existing-user', sites, global360View, siteSummary);
      } else if (!allSites.length) {
        const message = this.translateService.instant('tkey;forbidden.user.access.denied', {
          name: global360View.endUsers[0].ref.username,
        });
        const options = {
          modalViewProperties: {
            title: 'tkey;forbidden.user.title',
            promptBody: message,
            icon: SmacsIcons.FORBIDDEN,
            iconClass: 'text-danger',
            displayCloseButton: false,
            buttons: [
              {
                label: 'tkey;dialogs.button.ok',
                buttonClass: ButtonStyles.INFO,
                dataAutomation: 'forbidden-dismiss-button',
              },
            ],
          },
        };
        this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
        return of(null);
      } else {
        return this._promptUserToPickSite('new-user', allSites, global360View, siteSummary);
      }
    }

    if (endUserSites.length === 1) {
      this.currentClusterContext.setCurrentClusterFromSite(endUserSites[0].id);
      this._throwErrorIfSiteIsNotPermitted(endUserSites[0]);
      return of(endUserSites[0]);
    }

    if (endUserSites.length > 1) {
      return this._promptUserToPickSite('multiple-site-user', endUserSites, global360View, siteSummary);
    }
  }

  private _listSitesOfMatchedCluster(clusters: ClusterResult[], global360View: Global360View): SiteResult[] {
    const sites: SiteResult[] = [];
    const serverId = global360View.primaryExtensions[0].serverId;
    const clusterResults = clusters.filter((cluster: ClusterResult) => {
      return cluster.cucmServerId === serverId;
    });
    clusterResults.forEach((cluster: ClusterResult) => {
      cluster.sites.forEach((siteResult: SiteResult) => {
        sites.push(siteResult);
      });
    });
    return sites;
  }

  private _isUserPartiallyLdapActive(global360View: Global360View, siteSummary: SiteSummary): boolean {
    if (global360View) {
      return (
        !!global360View.ldapUser &&
        global360View.endUsers.filter((endUser) => endUser.type === 'LDAP_ACTIVE').length <
          uniq(siteSummary.clusters.map((cluster) => cluster.cucmServerId)).length
      );
    }
  }

  private _getOtherClusters(allowedSites: SiteResult[], siteSummary: SiteSummary): ClusterResult[] {
    const clustersAllowed = this._getClusterForSites(allowedSites, siteSummary).map(
      (clusterSite: ClusterSite) => clusterSite.cluster
    );
    return siteSummary.clusters.filter((cluster: ClusterResult) => {
      return !clustersAllowed.includes(cluster.name);
    });
  }

  private _showSitePickerModal = (
    type: 'new-user' | 'existing-user' | 'multiple-site-user' | 'public-phone',
    allowedSites: SiteResult[],
    global360View: Global360View,
    siteSummary: SiteSummary
  ): Observable<SiteResult> => {
    const options = {
      modalViewProperties: {
        icon: type === 'new-user' ? SmacsIcons.ADD_USER : SmacsIcons.SITE,
        iconClass: 'text-info',
        title: this._getHeader(type),
        promptBody: this._getSubheader(type),
        displayCloseButton: true,
        type: type,
        allowedSites: allowedSites,
        otherClusters: this._getOtherClusters(allowedSites, siteSummary),
        isUserPartiallyLdapActive: this._isUserPartiallyLdapActive(global360View, siteSummary),
        clusterMapping: this._getClusterForSites(allowedSites, siteSummary),
        buttons: [
          {
            label: 'tkey;shared.site.site_picker.select.button',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'site-picker-modal-submit',
            isSubmitButton: true,
          },
        ],
      },
      bodyClass: SitePickerModalComponent,
    };

    return this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  };

  private _getHeader(type: 'new-user' | 'existing-user' | 'multiple-site-user' | 'public-phone'): string {
    switch (type) {
      case 'new-user':
        return 'tkey;shared.site.site_picker.new_user.header';
      case 'existing-user':
        return `<strong>${this.translateService.instant('tkey;shared.site.cannot_find_site.header')}</strong>`;
      case 'multiple-site-user':
        return `<h3 class="text-warning">${this.translateService.instant(
          'tkey;shared.site.site_picker.multiple_site_user.header'
        )}</h3>`;
      case 'public-phone':
        return 'tkey;shared.site.site_picker.public_phone.header';
    }
  }

  private _getSubheader(type: 'new-user' | 'existing-user' | 'multiple-site-user' | 'public-phone'): string {
    const translate = this.translateService.instant('tkey;shared.site.site_picker.select.text');
    const selectSite = `<h5 class="text-center mt-4 mb-0">${translate}</h5>`;
    switch (type) {
      case 'new-user':
        return this.translateService.instant('tkey;shared.site.site_picker.new_user.subheader') + selectSite;
      case 'existing-user':
        return this.translateService.instant('tkey;shared.site.site_picker.existing_user.subheader') + selectSite;
      case 'multiple-site-user':
        return this.translateService.instant('tkey;shared.site.site_picker.multiple_site_user.subheader') + selectSite;
      case 'public-phone':
        return this.translateService.instant('tkey;shared.site.site_picker.public_phone.subheader') + selectSite;
    }
  }

  private _throwErrorIfSiteIsNotPermitted = (site: SiteResult) => {
    if (!site.hasPermission) {
      this._showForbiddenModal(site.name);
    }
  };

  private _getClusterForSites(sites: SiteResult[], siteSummary: SiteSummary): ClusterSite[] {
    const matchedSites: ClusterSite[] = [];
    const clusters = siteSummary.clusters;
    clusters.forEach((cluster: ClusterResult) => {
      sites.forEach((siteResult: SiteResult) => {
        const match = cluster.sites.some((siteSummary: SiteResult) => siteSummary.name === siteResult.name);
        if (match) {
          matchedSites.push({
            site: siteResult.name,
            id: siteResult.id.toString(),
            cluster: cluster.name,
          });
        }
      });
    });
    return matchedSites;
  }

  private _promptUserToPickSite = (
    type: 'new-user' | 'existing-user' | 'multiple-site-user' | 'public-phone',
    sites: SiteResult[],
    global360View: Global360View,
    siteSummary: SiteSummary
  ): Observable<SiteResult> => {
    return new Observable<SiteResult>((subscriber) => {
      this._showSitePickerModal(type, sites, global360View, siteSummary).subscribe((selectedSite) => {
        if (selectedSite) {
          subscriber.next(selectedSite);
          subscriber.complete();
        } else if (!!this.initialSite) {
          subscriber.next(this.initialSite);
          subscriber.complete();
        } else {
          window.location.href = window.location.origin;
          subscriber.complete();
        }
      });
    });
  };

  private _showForbiddenModal(name: string) {
    const message = this.translateService.instant('tkey;forbidden.site.access.denied', { name });

    const options = {
      animation: true,
      backdropClass: 'static',
      keyboard: false,
      modalViewProperties: {
        title: 'tkey;forbidden.user.title',
        promptBody: message,
        icon: SmacsIcons.FORBIDDEN,
        iconClass: 'text-danger',
        displayCloseButton: false,
        buttons: [
          {
            label: 'tkey;dialogs.button.ok',
            buttonClass: ButtonStyles.INFO,
            dataAutomation: 'forbidden-dismiss-button',
          },
        ],
      },
    };
    this.smacsModalService
      .openPromptModal(() => options.modalViewProperties, options)
      .subscribe(() => {
        this.router.navigateByUrl('/');
      });
  }

  private _showCannotFindSiteModal = (global360View: Global360View): Observable<any> => {
    let devices: DeviceSummary[] = [];
    let deviceMsg = '';
    if (global360View.phones) {
      devices = devices.concat(global360View.phones);
    } else if (global360View.snrProfiles) {
      devices = devices.concat(global360View.snrProfiles);
    }

    const endUserName = global360View.endUsers[0].ref.username;
    devices.forEach((device) => {
      deviceMsg += device.name + ' - ' + device.devicePool;
    });
    const promptBodyMsg =
      this.translateService.instant('tkey;shared.site.cannot_find_site.body_1') +
      ' ' +
      `<strong>${endUserName}</strong>` +
      ' ' +
      this.translateService.instant('tkey;shared.site.cannot_find_site.body_2') +
      ' ' +
      `<strong>${deviceMsg}</strong>` +
      `<br/><strong>${this.translateService.instant('tkey;shared.site.cannot_find_site.footer')}</strong>`;
    return new Observable<any>((subscriber: Subscriber<any>) => {
      const options = {
        modalViewProperties: {
          icon: SmacsIcons.SITE,
          iconClass: 'text-info',
          title: `<strong>${this.translateService.instant('tkey;shared.site.cannot_find_site.header')}</strong>`,
          promptBody: promptBodyMsg,
          displayCloseButton: true,
          buttons: [
            {
              label: 'tkey;dialogs.button.ok',
              buttonClass: ButtonStyles.PRIMARY,
              dataAutomation: 'confirmation-modal-confirm-button',
            },
          ],
        },
      };

      this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
      subscriber.next();
      subscriber.complete();
    });
  };

  private _hasPhonesOrSnr = (global360View: Global360View): boolean => {
    return !!(
      global360View.snrProfiles.length ||
      (global360View.phones.length &&
        global360View.phones.filter((phone: PhoneRef) => this._isSupported(phone.model)).length)
    );
  };

  private _hasPrimaryExtension = (global360View: Global360View): boolean => {
    return !!global360View.primaryExtensions.length;
  };

  private _hasImpOrExtMob = (global360View: Global360View): boolean => {
    return !!(
      global360View.endUsers.some((endUser) => endUser.imPresenceEnabled) || global360View.extensionMobilities.length
    );
  };

  private _isSupported = (model: string): boolean => {
    return !['CTI Port', 'CTI Remote Device', 'Cisco Spark Remote Device'].includes(model);
  };

  private _getSiteIdsForImPresenceAndExtensionMobility = (global360View: Global360View, siteSummary: SiteSummary) => {
    const imPresenceServerIds = global360View.endUsers
      .filter((endUser) => endUser.imPresenceEnabled)
      .map((endUser) => {
        return endUser.ref.serverId;
      });
    const extensionMobilityServerIds = global360View.extensionMobilities.map((extensionMobility) => {
      return extensionMobility.serverId;
    });
    const siteIds = siteSummary.clusters
      .filter((cluster) => {
        return (
          imPresenceServerIds.includes(cluster.cucmServerId) ||
          extensionMobilityServerIds.includes(cluster.cucmServerId)
        );
      })
      .map((cluster) => cluster.sites.map((site) => site.id));
    return [...siteIds[0]];
  };
}
