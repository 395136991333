import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuditSettings } from '../../shared/models/generated/smacsModels';
import { Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AuditSettingsContext {
  private _stateSource = new ReplaySubject<AuditSettings>(1);
  state$ = this._stateSource.asObservable();

  constructor(private http: HttpClient) {
    this.refresh().subscribe();
  }

  refresh(): Observable<void> {
    return this._get().pipe(
      map((auditSettings) => {
        this._stateSource.next(auditSettings);
      })
    );
  }

  update(auditSettings: AuditSettings): Observable<void> {
    auditSettings.daysToKeepAuditRecords = Number(auditSettings.daysToKeepAuditRecords);
    return this._put(auditSettings).pipe(
      tap(() => {
        this._stateSource.next(auditSettings);
      })
    );
  }

  private _get(): Observable<AuditSettings> {
    return this.http.get<AuditSettings>('/services/system/admin/audits/settings');
  }

  private _put(auditSettings: AuditSettings): Observable<void> {
    return this.http.put<void>('/services/system/admin/audits/settings', auditSettings);
  }
}
