import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BandwidthPortInOrderRef,
  DocumentType,
  PortInDraftDocument,
  PortInOrderDraft,
  PortInOrderDraftRef,
} from '../../../shared/models/generated/smacsModels';
import { FileDownloadService } from '../../../shared/services/file-download.service';

@Injectable()
export class PortInDraftsResource {
  private _url = '/services/microsoft/orders/port-in-drafts';

  constructor(private _http: HttpClient, private _fileDownloadService: FileDownloadService) {}

  get(): Observable<PortInOrderDraftRef[]> {
    return this._http.get<PortInOrderDraftRef[]>(this._url);
  }

  put(draft: PortInOrderDraft): Observable<void> {
    return this._http.put<void>(`${this._url}/${draft.draftId}`, { ...draft });
  }

  post(draft: PortInOrderDraft): Observable<PortInOrderDraftRef> {
    return this._http.post<PortInOrderDraftRef>(this._url, draft);
  }

  getById(draftId: number): Observable<PortInOrderDraft> {
    return this._http.get<PortInOrderDraft>(`${this._url}/${draftId}`);
  }

  deleteDraft(draftId: number): Observable<void> {
    return this._http.delete<void>(`${this._url}/${draftId}`);
  }

  getDocuments(draftId: number): Observable<PortInDraftDocument[]> {
    return this._http.get<PortInDraftDocument[]>(`${this._url}/${draftId}/documents`);
  }

  submitDraft(draftId: number): Observable<BandwidthPortInOrderRef> {
    return this._http.post<BandwidthPortInOrderRef>(`${this._url}/${draftId}/submissions`, {
      notificationEmails: [],
    });
  }

  uploadDocument(draftId: number, documentType: DocumentType, file: File) {
    const formData = new FormData();
    formData.append('upload', file);

    return this._http.post(`${this._url}/${draftId}/documents?documentType=${documentType}`, formData);
  }

  downloadDocument(draftId: number, documentId: number) {
    return this._fileDownloadService.downloadFile(`${this._url}/${draftId}/documents/${documentId}`);
  }

  deleteDocument(draftId: number, documentId: number): Observable<void> {
    return this._http.delete<void>(`${this._url}/${draftId}/documents/${documentId}`);
  }
}
