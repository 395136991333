import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig } from '../../../forms/smacs-forms-models';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { LineAppearanceManagementReportResource } from '../resources/line-appearance-management-report.resource';
import {
  ApplicableFields,
  ClusterResult,
  DirectoryNumberRef,
  DirectoryNumberResult,
  ExtensionMobility,
  ExtensionMobilityLineReport,
  ExtensionMobilityRef,
  LineAppearanceReport,
  LineFeature,
  Phone,
  PhoneRef,
  PhonesLineReport,
  Privilege,
  SiteSummary,
} from '../../../shared/models/generated/smacsModels';
import { DatatableColumn, DatatableComponent, DatatableRow } from '../../datatable/datatable.component';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import {
  BottomNavClearButtonsList,
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin, Observable, of, OperatorFunction, Subscriber, Subscription } from 'rxjs';
import { PhoneResource } from '../../../shared/resources/phone.resource';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { SearchDirectoryNumberResource } from '../../../self-serve/resources/search/search-directory-number.resource';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';
import { PhoneButtonsService } from '../../../shared/phone-buttons/shared/phone-buttons.service';
import { AuthenticationContext } from '../../../shared/contexts/authentication.context';
import { ExtensionMobilityService } from '../../../shared/services/extension-mobility.service';
import { Router } from '@angular/router';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import KeyDownEvent = JQuery.KeyDownEvent;

type LineReport = PhonesLineReport | ExtensionMobilityLineReport;

interface DeviceDetails {
  primaryText: string;
  secondaryText: string;
  id: string;
  serverId: number;
}

type DeviceLineReport = LineReport & {
  deviceDetails: DeviceDetails;
};

interface LineAppearanceManagementReportRow extends DatatableRow {
  device: string;
  deviceSubtext: string;
  deviceId: string;
  deviceServerId: string;
  label: string;
  labelSubtext: string;
  externalCallerId: string;
  externalCallerIdSubtext: string;
  externalCallerIdNumber: string;
  externalCallerIdNumberSubtext: string;
  ringIdle: string;
  ringIdleSubtext: string;
  ringActive: string;
  ringActiveSubtext: string;
  maxNumberOfCalls: number | string;
  maxNumberOfCallsSubtext: number | string;
  callRecordingOption: string;
  callRecordingOptionSubtext: string;
  callRecordingProfile: string;
  callRecordingProfileSubtext: string;
  callRecordingMediaSource: string;
  callRecordingMediaSourceSubtext: string;
  monitoringCssName: string;
  monitoringCssNameSubtext: string;
  busyTrigger: number | string;
  busyTriggerSubtext: number | string;
  model: string;
  disabledEditMsg: string;
  isEditDisabled: boolean;
}

function isPhonesLineReport(lineReport: LineReport): lineReport is PhonesLineReport {
  return !!(lineReport as PhonesLineReport).phoneResultJson;
}

function isExtensionMobilityLineReport(lineReport: LineReport): lineReport is ExtensionMobilityLineReport {
  return !!(lineReport as ExtensionMobilityLineReport).extensionMobilityResultJson;
}

type ApplicableSettings = keyof ApplicableFields & keyof LineFeature & keyof LineAppearanceManagementReportRow;

@Component({
  selector: 'ziro-line-appearance-management-report',
  templateUrl: './line-appearance-management-report.component.html',
  styleUrls: ['../../reporting.scss', './line-appearance-management-report.component.scss'],
})
export class LineAppearanceManagementReportComponent extends SmacsFormAbstractDirective<LineFeature> implements OnInit {
  @ViewChild(DatatableComponent) datatableChildComponent: DatatableComponent<LineAppearanceManagementReportRow>;
  @ViewChild('typeaheadInput') typeaheadInput: ElementRef;

  formConfig = {
    fields: {
      label: {
        label: 'tkey;menu.report.line_appearance_management_report.table.label',
        dataAutomation: 'line-appearance-management-report-line',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: this._getCommonSettingValue('label'),
          readonly: true,
        }),
      },
      ringIdle: {
        label: 'tkey;menu.report.line_appearance_management_report.table.ring.idle',
        dataAutomation: 'line-appearance-management-report-ringIdle',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: this._getCommonSettingValue('ringIdle'),
          readonly: true,
        }),
      },
      callRecordingOption: {
        label: 'tkey;menu.report.line_appearance_management_report.table.callrecording.option',
        dataAutomation: 'line-appearance-management-report-callRedordingOption',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: this._getCommonSettingValue('callRecordingOption'),
          readonly: true,
        }),
      },
      externalCallerId: {
        label: 'tkey;menu.report.line_appearance_management_report.table.callerid.external',
        dataAutomation: 'line-appearance-management-report-callerName',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: this._getCommonSettingValue('externalCallerId'),
          readonly: true,
        }),
      },
      ringActive: {
        label: 'tkey;menu.report.line_appearance_management_report.table.ring.active',
        dataAutomation: 'line-appearance-management-report-ring',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: this._getCommonSettingValue('ringActive'),
          readonly: true,
        }),
      },
      callRecordingProfile: {
        label: 'tkey;menu.report.line_appearance_management_report.table.callrecording.profile',
        dataAutomation: 'line-appearance-management-report-callRecordingProfile',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: this._getCommonSettingValue('callRecordingProfile'),
          readonly: true,
        }),
      },
      externalCallerIdNumber: {
        label: 'tkey;menu.report.line_appearance_management_report.table.external_phone_number_mask.label',
        dataAutomation: 'line-appearance-management-report-callerId',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: this._getCommonSettingValue('externalCallerIdNumber'),
          readonly: true,
        }),
      },
      maxNumberOfCalls: {
        label: 'tkey;menu.report.line_appearance_management_report.table.max.number.calls',
        dataAutomation: 'line-appearance-management-report-maxCalls',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: this._getCommonSettingValue('maxNumberOfCalls'),
          readonly: true,
        }),
      },
      callRecordingMediaSource: {
        label: 'tkey;menu.report.line_appearance_management_report.table.callrecording.media_source.label',
        dataAutomation: 'line-appearance-management-report-callRecordingMediaSource',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: this._getCommonSettingValue('callRecordingMediaSource'),
          readonly: true,
        }),
      },
      busyTrigger: {
        label: 'tkey;menu.report.line_appearance_management_report.table.busy.trigger',
        dataAutomation: 'line-appearance-management-report-busyTrigger',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: this._getCommonSettingValue('busyTrigger'),
          readonly: true,
        }),
      },
      monitoringCssName: {
        label: 'tkey;menu.report.line_appearance_management_report.table.monitoring_css.label',
        dataAutomation: 'line-appearance-management-report-monitoringCallingSearchSpace',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: this._getCommonSettingValue('monitoringCssName'),
          readonly: true,
        }),
      },
    },
    options: {
      columnClasses: {
        label: 'col-12 col-md-12 d-block text-start',
        input: 'col-12 col-md-12 d-block mt-n2',
      },
    },
  } as SmacsFormConfig;

  settings: LineAppearanceReport;
  isLoading = true;
  isReadOnly: boolean;
  tableRows = [] as LineAppearanceManagementReportRow[];
  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;

  bottomNavFixLineButton = {
    id: 'line-appearance-report-fix-btn',
    dataAutomation: 'line-appearance-report-fix-btn',
    label: 'tkey;menu.report.line_appearance_management_report.fix_it.label',
    buttonClass: this.buttonStyles.PRIMARY,
    labelParam: 1,
    icon: this.smacsIcons.FIX_IT,
    state: {
      pending: false,
      buttonDisableState: {
        disabled: true,
        tooltipKey: 'tkey;reporting.line_appearance_report.fix.disabled',
      },
      tooltipVisible: true,
    },
    cb: () => {
      this._onFixRowClicked();
    },
  };

  bottomNavRefreshButton = {
    id: 'line-appearance-report-refresh-button',
    dataAutomation: 'line-appearance-report-refresh-button',
    label: 'tkey;reporting.bulk_line_appearance_management.report.refresh_button_label',
    icon: this.smacsIcons.REFRESH,
    buttonClass: ButtonStyles.DEFAULT,
    state: {
      buttonDisableState: {
        disabled: false,
        tooltipKey: '',
      },
      tooltipVisible: true,
    },
    cb: () => {
      this._onRefreshClicked();
    },
  };

  applicableSettings: ApplicableSettings[] = [
    'busyTrigger',
    'callRecordingMediaSource',
    'callRecordingOption',
    'callRecordingProfile',
    'externalCallerId',
    'externalCallerIdNumber',
    'label',
    'maxNumberOfCalls',
    'monitoringCssName',
    'ringActive',
    'ringIdle',
  ];

  columns = [
    {
      name: 'device',
      label: 'tkey;menu.report.line_appearance_management_report.table.device.owner',
    },
    {
      name: 'label',
      label: 'tkey;menu.report.line_appearance_management_report.table.label',
      columnCharacterWidth: 25,
    },
    {
      name: 'externalCallerId',
      label: 'tkey;menu.report.line_appearance_management_report.table.callerid.external',
      columnCharacterWidth: 25,
    },
    {
      name: 'externalCallerIdNumber',
      label: 'tkey;menu.report.line_appearance_management_report.table.external_phone_number_mask.label',
      columnCharacterWidth: 16,
    },
    {
      name: 'ringIdle',
      label: 'tkey;menu.report.line_appearance_management_report.table.ring.idle',
      columnCharacterWidth: 18,
    },
    {
      name: 'ringActive',
      label: 'tkey;menu.report.line_appearance_management_report.table.ring.active',
      columnCharacterWidth: 18,
    },
    {
      name: 'maxNumberOfCalls',
      label: 'tkey;menu.report.line_appearance_management_report.table.max.number.calls',
    },
    {
      name: 'busyTrigger',
      label: 'tkey;menu.report.line_appearance_management_report.table.busy.trigger',
    },
    {
      name: 'callRecordingOption',
      label: 'tkey;menu.report.line_appearance_management_report.table.callrecording.option',
    },
    {
      name: 'callRecordingProfile',
      label: 'tkey;menu.report.line_appearance_management_report.table.callrecording.profile',
    },
    {
      name: 'callRecordingMediaSource',
      label: 'tkey;menu.report.line_appearance_management_report.table.callrecording.media_source.label',
    },
    {
      name: 'monitoringCssName',
      label: 'tkey;menu.report.line_appearance_management_report.table.monitoring_css.label',
    },
  ] as DatatableColumn<LineAppearanceManagementReportRow>[];

  public model: any;
  searchText = '';
  isSearching = false;
  isShowTable = false;
  tableDataAutomation = 'line-appearance-management-report-datatable';

  private _searchResults: DirectoryNumberResult[] = [];
  private _typeaheadSearchResults: DirectoryNumberResult[] | string[] = [];

  buttonSizes = ButtonSizes;
  isEmptySearchResult = false;
  clusters: ClusterResult[];
  extension: string;
  isNoResultsFound = false;
  isLinePreviewed = false;

  private _cucmServerId: number;
  private _directoryNumber: string;
  private _extensionDescription: string;
  private _subs = new Subscription();

  /**
   * iPhone, Android, Tablet maxNumberOfCalls and busyTrigger fields are not fixable
   */
  private static _isPropertyFixable(lineReportSetting: LineReport, cell: string): boolean {
    return !(
      isPhonesLineReport(lineReportSetting) &&
      LineAppearanceManagementReportComponent._isSkippableModel(lineReportSetting.phoneResultJson.ref.model) &&
      LineAppearanceManagementReportComponent._isSkippableSetting(cell)
    );
  }

  private static _isSkippableModel(model: string): boolean {
    const modelsToSkip = ['Cisco Dual Mode for iPhone', 'Cisco Dual Mode for Android', 'Cisco Jabber for Tablet'];

    return modelsToSkip.includes(model);
  }

  private static _isSkippableSetting(setting: string): boolean {
    const settingsToSkip = ['maxNumberOfCalls', 'busyTrigger'];

    return settingsToSkip.includes(setting);
  }

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private lineAppearanceManagementReportResource: LineAppearanceManagementReportResource,
    private bottomNavService: BottomNavService,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private phoneResource: PhoneResource,
    private extensionMobilityService: ExtensionMobilityService,
    private phoneButtonsService: PhoneButtonsService,
    private toastService: ToastService,
    protected smacsFormStateService: SmacsFormStateService,
    private searchDirectoryNumberResource: SearchDirectoryNumberResource,
    private siteSummaryContext: SiteSummaryContext,
    private telephoneNumberFilter: TelephoneNumberFilter,
    private authenticationContext: AuthenticationContext,
    private router: Router,
    private currentClusterContext: CurrentClusterContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;reporting.bulk_line_appearance_management.title' }]);

    const siteSummaryObservable = this.siteSummaryContext.state$.pipe(
      map((siteSummary: SiteSummary) => {
        this.clusters = siteSummary.clusters;
      })
    );

    const authenticationObservable = this.authenticationContext.state$.pipe(
      map((user) => {
        this.isReadOnly = !user.privileges.includes(Privilege.CONTROL_WRITE);
      })
    );

    this._subs.add(
      combineLatest([siteSummaryObservable, authenticationObservable]).subscribe(() => {
        this.isLoading = false;
      })
    );
  }

  private _getLineAppearanceManagementReport(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      const phoneDeviceLineReports = this.settings.phonesLineReportJsons
        .filter((report) => !!report.lineFeatureJson)
        .map<DeviceLineReport>((phoneLineReport) => {
          const phoneResult = phoneLineReport.phoneResultJson;
          const primaryText = phoneResult.owner
            ? phoneResult.owner.firstName + '    ' + phoneResult.owner.lastName
            : `${this.translateService.instant('tkey;reporting.bulk_line_appearance_management.public_phone')}`;
          return {
            ...phoneLineReport,
            deviceDetails: {
              primaryText: primaryText,
              secondaryText: phoneResult.ref.name,
              id: phoneResult.ref.id,
              serverId: phoneResult.ref.serverId,
            },
          };
        });

      const extensionMobilityDeviceLineReports = this.settings.extensionMobilityLineReportJsons
        .filter((report) => !!report.lineFeatureJson)
        .map<DeviceLineReport>((extensionMobilityLineReport) => {
          const extensionMobilityResult = extensionMobilityLineReport.extensionMobilityResultJson;
          const primaryText = extensionMobilityResult.ref.description || `<span>&lt;empty&gt;</span>`;
          return {
            ...extensionMobilityLineReport,
            deviceDetails: {
              primaryText: primaryText,
              secondaryText: extensionMobilityResult.ref.name,
              id: extensionMobilityResult.ref.id,
              serverId: extensionMobilityResult.ref.serverId,
            },
          };
        });

      const deviceLineReports = [...phoneDeviceLineReports, ...extensionMobilityDeviceLineReports];

      this.tableRows = deviceLineReports.map((lineReportSetting) => {
        let model = '';
        let disabledEditMsg = '';
        let isEditDisabled = false;
        let editLink = '';

        if (isPhonesLineReport(lineReportSetting)) {
          model = lineReportSetting.phoneResultJson.ref.model;
          isEditDisabled = !lineReportSetting.phoneResultJson?.owner && this._isNotDeskPhone(model);
          disabledEditMsg = isEditDisabled
            ? this.translateService.instant(
                'tkey;reporting.bulk_line_appearance_management.report.edit_device.disabled.tooltip.no_owner'
              )
            : '';
          const device = this.settings.phonesLineReportJsons.find((phonesLineReport) => {
            return phonesLineReport.phoneResultJson.ref.id === lineReportSetting.phoneResultJson.ref.id;
          });
          editLink = this._getEditDeviceLink(device);
        }

        if (isExtensionMobilityLineReport(lineReportSetting)) {
          model = lineReportSetting.extensionMobilityResultJson.ref.model;
          isEditDisabled = true;
          disabledEditMsg = isEditDisabled
            ? this.translateService.instant(
                'tkey;reporting.bulk_line_appearance_management.report.edit_device.disabled.tooltip.no_owner'
              )
            : '';
        }

        const tableRow = {
          device: lineReportSetting.deviceDetails.primaryText,
          deviceSubtext: lineReportSetting.deviceDetails.secondaryText,
          deviceId: lineReportSetting.deviceDetails.id,
          deviceServerId: String(lineReportSetting.deviceDetails.serverId),
          label: this._getCellValue(lineReportSetting, 'label'),
          labelSubtext: this._getCellSubtext(lineReportSetting, 'label', this.settings.commonSettings.label),
          externalCallerId: this._getCellValue(lineReportSetting, 'externalCallerId'),
          externalCallerIdSubtext: this._getCellSubtext(
            lineReportSetting,
            'externalCallerId',
            this.settings.commonSettings.externalCallerId
          ),
          externalCallerIdNumber: this._getCellValue(lineReportSetting, 'externalCallerIdNumber'),
          externalCallerIdNumberSubtext: this._getCellSubtext(
            lineReportSetting,
            'externalCallerIdNumber',
            this.settings.commonSettings.externalCallerIdNumber
          ),
          ringIdle: this._getCellValue(lineReportSetting, 'ringIdle'),
          ringIdleSubtext: this._getCellSubtext(lineReportSetting, 'ringIdle', this.settings.commonSettings.ringIdle),
          ringActive: this._getCellValue(lineReportSetting, 'ringActive'),
          ringActiveSubtext: this._getCellSubtext(
            lineReportSetting,
            'ringActive',
            this.settings.commonSettings.ringActive
          ),
          maxNumberOfCalls: this._getCellValue(lineReportSetting, 'maxNumberOfCalls'),
          maxNumberOfCallsSubtext: this._getCellSubtext(
            lineReportSetting,
            'maxNumberOfCalls',
            this.settings.commonSettings.maxNumberOfCalls
          ),
          callRecordingOption: this._getCellValue(lineReportSetting, 'callRecordingOption'),
          callRecordingOptionSubtext: this._getCellSubtext(
            lineReportSetting,
            'callRecordingOption',
            this.settings.commonSettings.callRecordingOption
          ),
          callRecordingProfile: this._getCellValue(lineReportSetting, 'callRecordingProfile'),
          callRecordingProfileSubtext: this._getCellSubtext(
            lineReportSetting,
            'callRecordingProfile',
            this.settings.commonSettings.callRecordingProfile
          ),
          callRecordingMediaSource: this._getCellValue(lineReportSetting, 'callRecordingMediaSource'),
          callRecordingMediaSourceSubtext: this._getCellSubtext(
            lineReportSetting,
            'callRecordingMediaSource',
            this.settings.commonSettings.callRecordingMediaSource
          ),
          monitoringCssName: this._getCellValue(lineReportSetting, 'monitoringCssName'),
          monitoringCssNameSubtext: this._getCellSubtext(
            lineReportSetting,
            'monitoringCssName',
            this.settings.commonSettings.monitoringCssName
          ),
          busyTrigger: this._getCellValue(lineReportSetting, 'busyTrigger'),
          busyTriggerSubtext: this._getCellSubtext(
            lineReportSetting,
            'busyTrigger',
            this.settings.commonSettings.busyTrigger
          ),
          hardcodedTooltips: {
            label: this._getCellTooltip(lineReportSetting, 'label'),
            externalCallerId: this._getCellTooltip(lineReportSetting, 'externalCallerId'),
            externalCallerIdNumber: this._getCellTooltip(lineReportSetting, 'externalCallerIdNumber'),
            ringIdle: this._getCellTooltip(lineReportSetting, 'ringIdle'),
            ringActive: this._getCellTooltip(lineReportSetting, 'ringActive'),
            maxNumberOfCalls: this._getCellTooltip(lineReportSetting, 'maxNumberOfCalls'),
            callRecordingOption: this._getCellTooltip(lineReportSetting, 'callRecordingOption'),
            callRecordingProfile: this._getCellTooltip(lineReportSetting, 'callRecordingProfile'),
            callRecordingMediaSource: this._getCellTooltip(lineReportSetting, 'callRecordingMediaSource'),
            monitoringCssName: this._getCellTooltip(lineReportSetting, 'monitoringCssName'),
            busyTrigger: this._getCellTooltip(lineReportSetting, 'busyTrigger'),
          },
          model: model,
          disabledEditMsg: disabledEditMsg,
          isEditDisabled: isEditDisabled,
          editButtonHref: editLink,
        } as LineAppearanceManagementReportRow;

        return {
          ...tableRow,
          cssClass: this._isInvalidRow(tableRow) ? 'table-warning' : '',
          isTableRowSelectable: true,
          tableRowSelectDisabledTooltip: this._getDisabledToolTip(tableRow),
          isTableRowSelectDisabled: !this._isInvalidRow(tableRow),
        };
      });
      this.isShowTable = true;
      this.isLoading = false;

      this.typeaheadInput.nativeElement.removeAttribute('disabled');
      setTimeout(() => {
        this.datatableChildComponent?.filterTableData();
      });

      subscriber.next();
      subscriber.complete();
    });
  }

  private _isInvalidRow(row: LineAppearanceManagementReportRow, param?: boolean): boolean {
    const emptyValue = `<span>&lt;empty&gt;</span>`;
    this.applicableSettings.forEach((setting) => {
      // this check is added to keep <empty> in empty strings after filtering the table.
      if (param) {
        if (row[setting] === '') {
          row[setting] = emptyValue;
        }
        if (this.settings.commonSettings[setting] === '') {
          (this.settings.commonSettings[setting] as string) = emptyValue;
        }
      } else if (row[setting] === emptyValue) {
        row[setting] = '';
      }
    });

    return this.applicableSettings.some((setting) => {
      if (
        LineAppearanceManagementReportComponent._isSkippableModel(row.model) &&
        LineAppearanceManagementReportComponent._isSkippableSetting(setting)
      ) {
        return false;
      } else {
        return row[setting] !== this.settings.commonSettings[setting] && !row[setting]?.toString().includes('N/A');
      }
    });
  }

  private _getDisabledToolTip(tableRow: LineAppearanceManagementReportRow): string {
    if (!this._isInvalidRow(tableRow)) {
      return this.translateService.instant('tkey;reporting.line_appearance_report.row.disabled.tooltip');
    }
  }

  private _getCellTooltip(lineReportSetting: LineReport, cellName: ApplicableSettings): string {
    return this._getCellValue(lineReportSetting, cellName)?.toString().includes('N/A')
      ? this.translateService.instant('tkey;menu.report.line_appearance_management_report.disabled.tooltip')
      : null;
  }

  private _getCellValue(lineReportSetting: LineReport, cellName: ApplicableSettings): string | number {
    if (lineReportSetting.applicableFieldsJson[cellName]) {
      if (lineReportSetting.lineFeatureJson[cellName] !== '') {
        return lineReportSetting.lineFeatureJson[cellName];
      }
      if (lineReportSetting.lineFeatureJson[cellName] === '') {
        return `<span>&lt;empty&gt;</span>`;
      }
    } else {
      return `<span class="muted ps-2">N/A</span>`;
    }
  }

  onSelectRow(): void {
    const rowsSelected = this.tableRows.filter((row: LineAppearanceManagementReportRow) => row.isSelectedInTable);
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          ...this.bottomNavRefreshButton,
        },
        {
          ...this.bottomNavFixLineButton,
          state: {
            buttonDisableState: {
              disabled: !rowsSelected.length,
              tooltipKey: !rowsSelected.length ? 'tkey;reporting.line_appearance_report.fix.disabled' : '',
            },
            tooltipVisible: !rowsSelected.length,
          },
          label:
            rowsSelected.length >= 1
              ? rowsSelected.length === 1
                ? 'tkey;menu.report.line_appearance_management_report.fix_it.count.one.label'
                : 'tkey;menu.report.line_appearance_management_report.fix_it.count.label'
              : 'tkey;menu.report.line_appearance_management_report.fix_it.label',
          labelParam: rowsSelected.length,
        },
      ])
    );
  }

  showOnlyMisconfiguredFilter = (filter: boolean, row: LineAppearanceManagementReportRow): boolean => {
    if (!filter) {
      return true;
    }
    return this._isInvalidRow(row, true);
  };

  protected submit() {
    return of(null);
  }

  private _getCellMisconfigMessage(commonSettingValue: number | string): string {
    return `<span class="text-warning strong text-truncate d-block w-100">
              <i class="icon-warning-triangle me-1"></i> ${this.translateService.instant(
                'tkey;reporting.line_appearance_report.expected.text'
              )} ${commonSettingValue !== '' ? commonSettingValue : 'Empty Value'}
            </span>`;
  }

  private _getCellSubtext(
    lineReportSetting: LineReport,
    cell: ApplicableSettings,
    commonSettingValue: number | string
  ): string {
    if (LineAppearanceManagementReportComponent._isPropertyFixable(lineReportSetting, cell)) {
      return lineReportSetting.lineFeatureJson[cell] !== commonSettingValue &&
        lineReportSetting.applicableFieldsJson[cell]
        ? this._getCellMisconfigMessage(commonSettingValue)
        : '';
    }

    return '';
  }

  private _getCommonSettingValue(commonSetting: ApplicableSettings): string {
    return this.settings?.commonSettings[commonSetting] !== ''
      ? String(this.settings?.commonSettings[commonSetting])
      : '<empty>';
  }

  private _initBottomNav(): void {
    const bottomNavButtons: BottomNavButton[] = [];

    if (!this.isReadOnly) {
      bottomNavButtons.push(this.bottomNavRefreshButton);
      bottomNavButtons.push(this.bottomNavFixLineButton);
    }

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _onFixRowClicked(): void {
    const resultsToFix = this.tableRows.filter((row: LineAppearanceManagementReportRow) => row.isSelectedInTable);
    const options = {
      windowClass: 'line-appearance-report-fix-it-modal',
      modalViewProperties: {
        icon: SmacsIcons.FIX_IT,
        iconClass: 'text-primary',
        title: this.translateService.instant('tkey;menu.report.line_appearance_management_report.modal.title'),
        promptBody: this.translateService.instant('tkey;menu.report.line_appearance_management_report.modal.message', {
          count: resultsToFix.length,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;reporting.line_appearance_report.fix',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._fixSelectedLine(resultsToFix),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _fixSelectedLine(resultsToFix: LineAppearanceManagementReportRow[]): Observable<void> {
    return new Observable((subscriber) => {
      const requests = resultsToFix.map((row: LineAppearanceManagementReportRow) => {
        return this._updateDevice(row);
      });

      forkJoin(requests).subscribe(() => {
        this.lineAppearanceManagementReportResource
          .get(this._cucmServerId, this._directoryNumber)
          .subscribe((result) => {
            this.settings = result;
            this._getLineAppearanceManagementReport().subscribe(() => {
              this.toastService.push(
                ToastTypes.SUCCESS,
                SmacsIcons.FIX_IT,
                'tkey;shared.toast.save.success.title',
                this.translateService.instant('tkey;reporting.line_appearance_report.success.toast.message')
              );
              this.onSelectRow();
              subscriber.next();
              subscriber.complete();
            });
          });
      });
    });
  }

  private _updateDevice(row: LineAppearanceManagementReportRow): Observable<PhoneRef | ExtensionMobilityRef> {
    const isExtensionMobilityDevice = this.settings.extensionMobilityLineReportJsons.some(
      (extensionMobilityLineReport) => extensionMobilityLineReport.extensionMobilityResultJson.ref.id === row.deviceId
    );
    if (isExtensionMobilityDevice) {
      return this.extensionMobilityService.get(row.deviceId, row.deviceServerId).pipe(
        switchMap((extensionMobility) => {
          this._updateDeviceLines(extensionMobility);
          return this.extensionMobilityService.put(row.deviceId, extensionMobility, row.deviceServerId);
        })
      );
    } else {
      return this.phoneResource.get(row.deviceId, row.deviceServerId).pipe(
        switchMap((phone) => {
          this._updateDeviceLines(phone);
          return this.phoneResource.put(phone, row.deviceServerId);
        })
      );
    }
  }

  private _updateDeviceLines(device: Phone | ExtensionMobility) {
    device.buttons.forEach((button) => {
      if (
        this.phoneButtonsService.isLineButton(button) &&
        button.lineFeature !== null &&
        button.dn.extension === this.extension
      ) {
        this.applicableSettings.forEach((setting) => {
          if (this.settings.commonSettings[setting] === '<span>&lt;empty&gt;</span>') {
            (this.settings.commonSettings[setting] as string) = '';
          }
          if (
            !(
              LineAppearanceManagementReportComponent._isSkippableModel(device.model) &&
              LineAppearanceManagementReportComponent._isSkippableSetting(setting)
            )
          ) {
            (button.lineFeature[setting] as any) = this.settings.commonSettings[setting];
          }
        });
      }
    });
  }

  private _onRefreshClicked(): void {
    this.isLoading = true;
    this._setRefreshButtonPending(true);
    this._getLineReport(true);
  }

  private _setRefreshButtonPending(isPending: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'line-appearance-report-refresh-button',
        state: {
          pending: isPending,
          buttonDisableState: { disabled: isPending, tooltipKey: '' },
        },
      })
    );
  }

  filterTypeaheadInput($event: KeyDownEvent) {
    if (this.isLinePreviewed && $event.key === 'Backspace') {
      return false;
    } else if (this.isLinePreviewed && $event.key !== 'Enter') {
      this.clearInput(false);
    }
  }

  onFocus($event: Event): void {
    this.typeaheadInput.nativeElement.setSelectionRange(0, 0);
    if (!this.isLinePreviewed) {
      $event.stopPropagation();
      setTimeout(() => {
        const inputEvent: Event = new Event('input');
        $event.target.dispatchEvent(inputEvent);
      }, 0);
    }
  }

  search: OperatorFunction<string, any> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(250),
      distinctUntilChanged(),
      switchMap((term) => this._search(term).pipe())
    );

  private _search(query: string): Observable<DirectoryNumberResult[] | string[]> {
    this.searchText = query;
    this.isNoResultsFound = false;
    const emptyResult = [
      {
        callable: false,
        numberOfAssociatedDevices: 0,
        ref: { extension: '' } as DirectoryNumberRef,
        routePartition: '',
      },
    ] as DirectoryNumberResult[];

    return new Observable((subscriber) => {
      if (!query || query.length < 2) {
        subscriber.next([]);
        subscriber.complete();
      } else {
        this.isSearching = true;
        const dropdownMenu = document.querySelector('.dropdown-menu') as HTMLElement;
        if (dropdownMenu !== null) {
          dropdownMenu.style.border = '1px solid #ff681d';
        }
        this.searchDirectoryNumberResource.get({ q: query, limit: 10 }).subscribe((directoryNumberResult) => {
          this._searchResults = directoryNumberResult;
          this._typeaheadSearchResults = directoryNumberResult.filter(
            (result: DirectoryNumberResult) => result.numberOfAssociatedDevices !== 0
          );
          const filteredResults = this._typeaheadSearchResults.length > 0 ? this._typeaheadSearchResults : emptyResult;

          subscriber.next(filteredResults);
          if (filteredResults === emptyResult) {
            this.isNoResultsFound = true;
            this.updateNoResultCss();
            const htmlElems = document.getElementsByTagName('ngb-typeahead-window');
            const elements = [].slice.call(htmlElems);
            elements[0]?.removeAttribute('hidden');
          }
          subscriber.complete();

          this.isSearching = false;
          if (dropdownMenu !== null) {
            dropdownMenu.style.border = '';
          }
        });
      }
    });
  }

  updateNoResultCss() {
    const elements = document.getElementsByClassName('dropdown-item');
    elements[0]?.setAttribute('disabled', '');
  }

  clearInput(isClearTable: boolean) {
    this.model = '';
    this.searchText = '';
    this.isLinePreviewed = false;
    if (isClearTable) {
      this.extension = null;
      this.isShowTable = false;
      this.bottomNavService.dispatch(new BottomNavClearButtonsList());
    }
  }

  showTable() {
    this.isEmptySearchResult = this._searchResults.length === 0;
  }

  getCucmServerDescriptionFromId(serverId: string | number): string {
    const clusterResult = this.clusters.find((cluster) => String(cluster.cucmServerId) === String(serverId));
    return clusterResult && clusterResult.cucmServerDescription;
  }

  scroll($event: any) {
    const element = $event?.currentTarget?.nextElementSibling?.getElementsByClassName('active')[0];
    element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  handleResultClick($event: MouseEvent, numberOfAssociatedDevices: number): void {
    if (numberOfAssociatedDevices === 1) {
      $event.stopPropagation();
      $event.preventDefault();
    }
  }

  onPreviewClick(result: DirectoryNumberResult): void {
    if (!this.isNoResultsFound && result.numberOfAssociatedDevices !== 1) {
      this.isLinePreviewed = true;
      this._initBottomNav();
      this.isLoading = true;
      this.typeaheadInput.nativeElement.setAttribute('disabled', '');
      this.extension = result.ref.extension;
      this._cucmServerId = result.ref.serverId;
      this._directoryNumber = result.ref.id;
      this._extensionDescription = result.ref.description;
      this._getLineReport(false);
    }
  }

  formatter = () =>
    this.searchText === '' || this.isNoResultsFound ? (this.model = this.searchText) : this._getInputDescription();

  private _getLineReport(showToast: boolean) {
    this.lineAppearanceManagementReportResource
      .get(this._cucmServerId, this._directoryNumber)
      .subscribe((lineAppearanceReport: LineAppearanceReport) => {
        this.settings = lineAppearanceReport;
        this.entitySource.next({
          busyTrigger: this.settings.commonSettings.busyTrigger,
          callRecordingMediaSource: this.settings.commonSettings.callRecordingMediaSource,
          callRecordingOption: this.settings.commonSettings.callRecordingOption,
          externalCallerId: this.settings.commonSettings.externalCallerId,
          externalCallerIdNumber: this.settings.commonSettings.externalCallerIdNumber,
          label: this.settings.commonSettings.label,
          maxNumberOfCalls: this.settings.commonSettings.maxNumberOfCalls,
          monitoringCssName: this.settings.commonSettings.monitoringCssName,
          ringActive: this.settings.commonSettings.ringActive,
          callRecordingProfile: this.settings.commonSettings.callRecordingProfile,
          ringIdle: this.settings.commonSettings.ringIdle,
        } as LineFeature);

        this._getLineAppearanceManagementReport().subscribe(() => {
          this.applicableSettings.forEach((setting) => {
            const labelComponent = this.fieldComponents.find((field) => field.fieldId === setting);
            labelComponent.applyComponentConfig(
              new SmacsTextConfig({
                htmlInputType: HtmlInputType.TEXT,
                placeholder: this._getCommonSettingValue(setting),
                readonly: true,
              })
            );
          });
        });
        if (showToast) {
          this.toastService.push(
            ToastTypes.SUCCESS,
            this.smacsIcons.REFRESH,
            'tkey;reporting.bulk_line_appearance_management.report.refresh.toast.title',
            'tkey;reporting.bulk_line_appearance_management.report.refresh.toast.message'
          );
        }
      });
  }

  private _getInputDescription(): string {
    if (this.extension) {
      const extension = this.translateService.instant(
        'tkey;menu.report.line_appearance_management_report.typeahead.description.extension',
        { extension: this.telephoneNumberFilter.transform(this.extension) }
      );
      const extensionDescription = this._extensionDescription
        ? ` | ${this.translateService.instant(
            'tkey;menu.report.line_appearance_management_report.typeahead.description.extension_description',
            { extensionDescription: this._extensionDescription }
          )}`
        : ``;
      const serverDescription = this.translateService.instant(
        'tkey;menu.report.line_appearance_management_report.typeahead.description.server_description',
        { serverDescription: this.getCucmServerDescriptionFromId(this._cucmServerId) }
      );
      return `${extension}${extensionDescription} | ${serverDescription}`;
    }
    return '';
  }

  _getEditDeviceLink(device: PhonesLineReport): string {
    if (device) {
      this.currentClusterContext.setCurrentClusterFromCucmServer(device.phoneResultJson.ref.serverId);
      const isDeskPhone = !this._isNotDeskPhone(device.phoneResultJson.ref.model);
      if (!device.phoneResultJson?.owner && isDeskPhone) {
        // public phone
        return `${window.location.origin}/app2/#/public-phone/${device.phoneResultJson.ref.name}`;
      } else if (device.phoneResultJson?.owner && isDeskPhone) {
        // user deskphone
        const baseRoute = `${window.location.origin}/app2/#`;
        const userRoute = `/user/${encodeURIComponent(device.phoneResultJson.owner.username)}`;
        const phoneRoute = `/deskphone/${device.phoneResultJson.ref.id}`;
        return baseRoute + userRoute + phoneRoute;
      } else if (device.phoneResultJson?.owner) {
        // user softphone
        const appUrl = `${window.location.origin}/app2/#/user/${device.phoneResultJson.owner.username}`;
        const phoneSubPath = this._getPhoneSubPath(device);
        return `${appUrl}/${phoneSubPath}/${device.phoneResultJson.ref.id}`;
      }
    }
  }

  private _isNotDeskPhone(phoneModel: string): boolean {
    const models = [
      'Cisco IP Communicator',
      'Cisco Unified Client Services Framework',
      'Cisco Dual Mode for iPhone',
      'Cisco Dual Mode for Android',
      'Cisco Jabber for Tablet',
      'Remote Destination Profile',
      'CTI Port',
      'CTI Remote Device',
      'Cisco Spark Remote Device',
    ];
    return models.includes(phoneModel);
  }

  private _getPhoneSubPath(phone: PhonesLineReport): string {
    switch (phone.phoneResultJson.ref.model) {
      case 'Cisco IP Communicator': {
        return 'cipc';
      }
      case 'Cisco Unified Client Services Framework': {
        return 'im-softphone';
      }
      case 'Cisco Dual Mode for iPhone': {
        return 'iphone';
      }
      case 'Cisco Dual Mode for Android': {
        return 'android';
      }
      case 'Cisco Jabber for Tablet': {
        return 'tablet';
      }
      default: {
        return 'deskphone';
      }
    }
  }
}
