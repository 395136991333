import { NgModule } from '@angular/core';
import { SiteBulkFieldCopyComponent } from './sites/site-bulk-field-copy/site-bulk-field-copy.component';
import { SiteBulkFieldCopyFormComponent } from './sites/site-bulk-field-copy/site-bulk-field-copy-form/site-bulk-field-copy-form.component';
import { ClustersListComponent } from './clusters/clusters-list.component';
import { SitesResource } from './services/sites.resource';
import { ClustersResource } from './services/clusters.resource';
import { SharedModule } from '../../../shared/shared.module';
import { EntityTableModule } from '../../../shared/entity-table/entity-table.module';
import { SmacsFormsModule } from '../../../forms/smacs-forms.module';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbsModule } from '../../../shared/breadcrumbs/breadcrumbs.module';
import { BottomNavModule } from '../../../shared/bottom-nav/bottom-nav.module';
import { LoadingBlockModule } from '../../../shared/loading-block/loading-block.module';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClusterFormComponent } from './clusters/cluster/cluster-form/cluster-form.component';
import { SitesListComponent } from './sites/sites-list.component';
import { SiteManagementComponent } from './site-management.component';
import { SiteComponent } from './sites/site/site.component';
import { ClusterComponent } from './clusters/cluster/cluster.component';
import { SiteManagementServiceComponent } from './site-management-service/site-management-service.component';
import { SiteManagementServiceFormComponent } from './site-management-service/site-management-service-form/site-management-service-form.component';
import { SiteConfigurationFormComponent } from './sites/site/site-configuration-form.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../../../button/button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SideNavigationModule } from '../../../shared/side-navigation/side-navigation.module';
import { SiteSideNavComponent } from './site-side-nav/site-side-nav.component';
import { CheckboxButtonModule } from '../../../shared/checkbox-button/checkbox-button.module';

@NgModule({
  imports: [
    BreadcrumbsModule,
    BottomNavModule,
    LoadingBlockModule,
    TranslateModule,
    CommonModule,
    RouterModule,
    EntityTableModule,
    SharedModule,
    EntityTableModule,
    SmacsFormsModule,
    TranslateModule,
    ButtonModule,
    ReactiveFormsModule,
    NgbTooltip,
    FormsModule,
    SideNavigationModule,
    CheckboxButtonModule,
  ],
  declarations: [
    SiteManagementComponent,
    ClustersListComponent,
    ClusterComponent,
    ClusterFormComponent,
    SitesListComponent,
    SiteComponent,
    SiteConfigurationFormComponent,
    SiteBulkFieldCopyComponent,
    SiteBulkFieldCopyFormComponent,
    SiteManagementServiceComponent,
    SiteManagementServiceFormComponent,
    SiteSideNavComponent,
  ],
  exports: [
    SiteManagementComponent,
    ClustersListComponent,
    ClusterComponent,
    ClusterFormComponent,
    SitesListComponent,
    SiteComponent,
    SiteConfigurationFormComponent,
    SiteBulkFieldCopyComponent,
    SiteBulkFieldCopyFormComponent,
    SiteManagementServiceComponent,
    SiteManagementServiceFormComponent,
    SiteSideNavComponent,
  ],
  providers: [SitesResource, ClustersResource],
})
export class SiteManagementModule {}
