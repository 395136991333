import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BandwidthEmergencyCallingSettingsConfig } from '../../../../shared/models/generated/smacsModels';

@Injectable()
export class BandwidthEmergencyCallingFieldConfigResource {
  constructor(protected http: HttpClient) {}

  get(upn: string, lineUri: string): Observable<BandwidthEmergencyCallingSettingsConfig> {
    const encodedUpn = encodeURIComponent(upn);
    const encodedLineUri = encodeURIComponent(lineUri);
    return this.http.get<BandwidthEmergencyCallingSettingsConfig>(
      `/services/microsoft/field-configs/bandwidth-emergency-calling-settings?upn=${encodedUpn}&lineUri=${encodedLineUri}`
    );
  }
}
