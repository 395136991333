import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { cloneDeep, concat, differenceWith, find, intersection, isEmpty, isEqual, uniq } from 'lodash';
import { SiteBasedHelpdeskManagementResource } from '../../resources/site-based-helpdesk-management-resource.service';
import {
  ClusterResult,
  SiteBasedHelpdeskUserPermission,
  SiteBasedHelpdeskUserPermissions,
  SiteResult,
  SiteSummary,
} from '../../../shared/models/generated/smacsModels';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { SbhPermissionsFilterService } from './sbh-permissions-filter.service';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../shared/services/toast.service';
import { Subscription } from 'rxjs';
import { ButtonSizes, ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { AdminEditPermissionsModalComponent } from './edit-permissions-modal.component';
import { BulkEditPermissionsModalComponent } from './bulk-edit-permissions-modal/bulk-edit-permissions-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { LdapResource } from '../../../shared/resources/ldap.resource';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFieldTypes,
  EntityTableFiltersValue,
  EntityTableFilterTypes,
  EntityTableOnFieldChange,
} from '../../../shared/entity-table/entity-table.models';
import { TrimWithEllipsisFilter } from '../../../shared/filters/trim-with-ellipsis.filter';
import { FilterSelectOption } from '../../../shared/filter/filter-select/filter-select.component';

export interface FilterOptionSite {
  site: string;
  cluster: string;
}

interface SiteBasedHelpdeskUserPermissionResult extends SiteBasedHelpdeskUserPermission {
  isSelected: boolean;
}

export interface SiteClusterIntersectionFilter {
  name: string;
  siteNames: string[];
  clusterNames: string[];
  readAccessToReportingEnabled: boolean;
}

export interface SbhPermissionsTableFilterCriteria {
  name: string;
  siteNames: string[];
  clusterNames: string[];
  readAccess: SbhPermissionsReportingOptions;
}

export enum SbhPermissionsReportingOptions {
  ENABLED = 'Enabled',
  DISABLED = 'Disabled',
}

@Component({
  selector: 'ziro-site-based-helpdesk-user-permissions-management',
  templateUrl: './site-based-helpdesk-user-permissions-management.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [SbhPermissionsFilterService, TrimWithEllipsisFilter],
})
export class SiteBasedHelpdeskUserPermissionsManagementComponent implements OnInit, OnDestroy {
  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  isLoading = true;
  isSaving = false;
  selectAll = false;
  isUpdateDisplayed = false;
  authorizedSitesFilterOpts = [] as FilterOptionSite[];
  filteredResultPermissions: SiteBasedHelpdeskUserPermissionResult[];

  private _tempFilteredPermissions: SiteBasedHelpdeskUserPermissionResult[];
  private _originalPermissionsResults: SiteBasedHelpdeskUserPermissionResult[];
  private _originalPermissions: SiteBasedHelpdeskUserPermission[];
  private _permissions: SiteBasedHelpdeskUserPermission[];
  private _filteredPermissions: SiteBasedHelpdeskUserPermission[];
  private _clusters = [] as ClusterResult[];
  private _siteSummary: SiteSummary;
  private _subscriptions = new Subscription();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private siteBasedHelpdeskManagementResource: SiteBasedHelpdeskManagementResource,
    private ldapResource: LdapResource,
    private siteSummaryContext: SiteSummaryContext,
    private filterService: SbhPermissionsFilterService,
    private smacsModalService: SmacsModalService,
    private toastService: ToastService,
    private bottomNavService: BottomNavService,
    private breadcrumbsService: BreadcrumbsService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.sitemacman.header' }]);

    const siteSummarySub = this.siteSummaryContext.state$.subscribe((siteSummary) => {
      this._siteSummary = siteSummary;
      this._getClusters();
      this._getResults();
    });
    this._subscriptions.add(siteSummarySub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  getModalTitle(permissionsToUpdate: SiteBasedHelpdeskUserPermission[]) {
    return `
      <span>${this.translateService.instant('tkey;admin.sitemacman.modal.title')}</span>
      <small class="x-small px-2">|</small>
      <small class="x-small">${this.translateService.instant('tkey;admin.sitemacman.modal.bulk.edit.selected.users', {
        count: permissionsToUpdate.length,
      })}</small>
   `;
  }

  onUpdatePermissionsClicked(): void {
    const permissionsToUpdate: SiteBasedHelpdeskUserPermission[] = differenceWith(
      this.filteredResultPermissions,
      this._originalPermissionsResults,
      isEqual
    );

    const options = {
      modalViewProperties: {
        title: this.getModalTitle(permissionsToUpdate),
        permissionList: permissionsToUpdate,
        authorizedSitesFilterOpts: this.authorizedSitesFilterOpts,
        clusters: this._clusters,
        submitButtonLabel: this.translateService.instant('tkey;admin.sitemacman.update_permission', {
          value: permissionsToUpdate.length,
        }),
      },
      bodyClass: BulkEditPermissionsModalComponent,
    };

    this.smacsModalService
      .openDetailedModal(() => options.modalViewProperties, options)
      .subscribe((siteBasedHelpdeskUserPermission: SiteBasedHelpdeskUserPermission[]) => {
        if (siteBasedHelpdeskUserPermission && siteBasedHelpdeskUserPermission.length > 0) {
          siteBasedHelpdeskUserPermission.forEach((sitePermission) => {
            this._permissions = this._permissions.map((aPermission: SiteBasedHelpdeskUserPermission) => {
              if (aPermission.userId === sitePermission.userId) {
                return sitePermission;
              }
              return aPermission;
            });
          });
          this.filteredResultPermissions = this._permissions.map((permissionObj: SiteBasedHelpdeskUserPermission) => {
            return {
              ...permissionObj,
              isSelected: false,
            };
          });
          this._tempFilteredPermissions = [...this.filteredResultPermissions];
          this._filterResults();
          this._updateFilteredRows();
          this._generateTableRows();
          this.toastService.push(
            ToastTypes.SUCCESS,
            this.smacsIcons.CONFIG,
            'tkey;shared.toast.save.success.title',
            'tkey;admin.sitemacman.toast.save.message'
          );
          this._originalPermissionsResults = this._permissions.map((result: SiteBasedHelpdeskUserPermission) => {
            return {
              ...result,
              isSelected: false,
            };
          });
          this.selectAll = false;
          this.isUpdateDisplayed = false;
        } else {
          this.isUpdateDisplayed = true;
        }
      });
  }

  /**
   *  update the selected permissions
   */
  updatePermissionSelection(permission: SiteBasedHelpdeskUserPermissionResult): void {
    this._tempFilteredPermissions.find(
      (filteredPermission: SiteBasedHelpdeskUserPermissionResult) => permission.userId === filteredPermission.userId
    ).isSelected = permission.isSelected;
    this._checkIfAllSelected();
    this._displayUpdatePermission();
  }

  selectAllClicked(updatedRows: EntityTableContentRow[]): void {
    const filterRows: SiteBasedHelpdeskUserPermission[] = [];
    updatedRows.map((tRow) => {
      filterRows.push(this._filteredPermissions.find((permission) => permission.userId === tRow.content['userId']));
    });
    this.filteredResultPermissions = filterRows.map((result: SiteBasedHelpdeskUserPermissionResult) => {
      return {
        ...result,
        isSelected: this.selectAll,
      };
    });
    this._tempFilteredPermissions = filterRows.map((result: SiteBasedHelpdeskUserPermissionResult) => {
      return {
        ...result,
        isSelected: this.selectAll,
      };
    });
    this._checkIfAllSelected();
    this._displayUpdatePermission();
  }

  onFieldChange(event: EntityTableOnFieldChange) {
    if (event.columnId === 'isSelected') {
      const permission = event.content as SiteBasedHelpdeskUserPermissionResult;
      permission.isSelected = event.newValue as boolean;
      this.updatePermissionSelection(event.content as SiteBasedHelpdeskUserPermissionResult);
    }
  }

  onFilterChange(filterValues: EntityTableFiltersValue) {
    this.selectAll = filterValues['isSelected'];
  }

  onRowsUpdate(rowsUpdate: EntityTableContentRow[]) {
    if (this.selectAll) {
      this.selectAllClicked(rowsUpdate);
    } else {
      this.isUpdateDisplayed = false;
    }
  }

  private _openEditModal(permission: SiteBasedHelpdeskUserPermission) {
    if (!permission) {
      return;
    }
    const options = {
      modalViewProperties: {
        title: 'tkey;admin.sitemacman.modal.title',
        authorizedSitesFilterOpts: this.authorizedSitesFilterOpts,
        clusters: this._clusters,
        permission,
      },
      bodyClass: AdminEditPermissionsModalComponent,
    };

    this.smacsModalService
      .openDetailedModal(() => options.modalViewProperties, options)
      .subscribe((siteBasedHelpdeskUserPermission: SiteBasedHelpdeskUserPermission) => {
        if (siteBasedHelpdeskUserPermission) {
          this._permissions = this._permissions.map((aPermission: SiteBasedHelpdeskUserPermission) => {
            if (aPermission.userId === siteBasedHelpdeskUserPermission.userId) {
              return siteBasedHelpdeskUserPermission;
            }
            return aPermission;
          });
          this.filteredResultPermissions = this._permissions.map((permissionObj: SiteBasedHelpdeskUserPermission) => {
            return {
              ...permissionObj,
              isSelected: this._tempFilteredPermissions.find(
                (filteredPermission: SiteBasedHelpdeskUserPermissionResult) =>
                  permission.userId === filteredPermission.userId
              ).isSelected,
            };
          });
          // on single modal update, also update the original permissions to reflect the selected permission rows
          this._originalPermissionsResults = this._originalPermissionsResults.map((originalPermission) =>
            siteBasedHelpdeskUserPermission.userId === originalPermission.userId
              ? ({ ...siteBasedHelpdeskUserPermission, isSelected: false } as SiteBasedHelpdeskUserPermissionResult)
              : originalPermission
          );
          this._filterResults();
          this._updateFilteredRows();
          this._generateTableRows();
          this.toastService.push(
            ToastTypes.SUCCESS,
            this.smacsIcons.CONFIG,
            'tkey;shared.toast.save.success.title',
            'tkey;admin.sitemacman.toast.save.message'
          );
        }
      });
  }

  private _filterResults() {
    const userId = '';
    const authorizedSites = [] as FilterOptionSite[];
    const readAccessFormFilter = {} as SbhPermissionsReportingOptions;

    const filterCriteria = {
      name: userId,
      siteNames: authorizedSites
        ? authorizedSites.filter((val: FilterOptionSite) => !!val.site).map((val: FilterOptionSite) => val.site)
        : [],
      clusterNames: authorizedSites
        ? authorizedSites.filter((val: FilterOptionSite) => !val.site).map((val: FilterOptionSite) => val.cluster)
        : [],
      readAccess: readAccessFormFilter,
    } as SbhPermissionsTableFilterCriteria;

    this._filteredPermissions = this._permissions.filter((permission: SiteBasedHelpdeskUserPermission) => {
      const rowToBeFiltered = {
        name: permission.userId,
        siteNames: permission.authorizedSites,
        clusterNames: permission.authorizedClusters,
        readAccessToReportingEnabled: permission.readAccessToControlEnabled,
      } as SiteClusterIntersectionFilter;

      return this.filterService.siteClusterFilter(filterCriteria, rowToBeFiltered, this._siteSummary);
    });
  }

  private _getClusters() {
    const options = [] as FilterOptionSite[];

    this._clusters = this._siteSummary.clusters;
    this._clusters.forEach((cluster: ClusterResult) => {
      cluster.sites.forEach((site: SiteResult) => {
        options.push({
          site: site.name,
          cluster: cluster.name,
        });
      });
    });

    this.authorizedSitesFilterOpts = [...options];
  }

  private _getResults() {
    this.isLoading = true;
    this.ldapResource.getLdapPermissionGroup().subscribe((ldapPermissionGroup) => {
      if (ldapPermissionGroup.siteBasedHelpdeskGroup !== '') {
        this.siteBasedHelpdeskManagementResource.get().subscribe((data: SiteBasedHelpdeskUserPermissions) => {
          this._permissions = data.permissions;
          this._originalPermissions = cloneDeep(this._permissions);
          this._originalPermissionsResults = this._originalPermissions.map(
            (result: SiteBasedHelpdeskUserPermission) => {
              return {
                ...result,
                isSelected: false,
              };
            }
          );
          this._filterResults();
          this.filteredResultPermissions = this._filteredPermissions.map(
            (permission: SiteBasedHelpdeskUserPermission) => {
              return {
                ...permission,
                isSelected: false,
              };
            }
          );
          this._tempFilteredPermissions = [...this.filteredResultPermissions];

          this._createTable();
          this._generateTableRows();
          this.isLoading = false;
        });
      } else {
        this.isLoading = false;
      }
    });
  }

  /**
   * display the update button when more than one row is selected
   */
  private _displayUpdatePermission(): void {
    const permissionsToUpdate: SiteBasedHelpdeskUserPermissionResult[] = differenceWith(
      this._tempFilteredPermissions,
      this._originalPermissionsResults,
      isEqual
    );
    if (permissionsToUpdate.length > 1) {
      this.bottomNavService.dispatch(
        new BottomNavUpdateButtonsList([
          {
            id: 'saveSiteBasedAdministrators',
            dataAutomation: 'saveSiteBasedAdministrators',
            label: 'tkey;admin.sitemacman.update_permission',
            type: ButtonTypes.CUSTOM,
            buttonClass: this.buttonStyles.PRIMARY,
            labelParam: permissionsToUpdate.length,
            state: {
              pending: false,
              buttonDisableState: { disabled: false, tooltipKey: '' },
            },
          },
        ])
      );
      this.isUpdateDisplayed = true;
    } else {
      this.isUpdateDisplayed = false;
    }
  }

  private _updateFilteredRows(): void {
    this.filteredResultPermissions = this._filteredPermissions.map((permission: SiteBasedHelpdeskUserPermission) => {
      const result = find(
        this._tempFilteredPermissions,
        (filteredPermission: SiteBasedHelpdeskUserPermissionResult) => {
          if (permission.userId === filteredPermission.userId) {
            return filteredPermission.isSelected;
          }
        }
      );
      return {
        ...permission,
        isSelected: result ? result.isSelected : false,
      };
    });
    this._tempFilteredPermissions = [...this.filteredResultPermissions];
    this._checkIfAllSelected();
    this._displayUpdatePermission();
  }

  private _checkIfAllSelected(): void {
    if (this.filteredResultPermissions.length > 0) {
      this.selectAll = this.filteredResultPermissions.every((filteredPermission) => filteredPermission.isSelected);
    }
  }

  private _createTable(): void {
    this.table = {
      columns: [
        {
          columnId: 'userId',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;admin.sitemacman.userIdHeader',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'authorizedSitesAndClusters',
          cssColumnSize: 'col-sm-5',
          label: 'tkey;admin.sitemacman.authorizedSitesHeader',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: this._formatSelectOptions(),
            isGroupSelectable: true,
            isMultiSelect: true,
            filterFn: (rowsToFilter: EntityTableContentRow[], filterValues: FilterSelectOption[]) => {
              if (isEmpty(filterValues)) {
                return rowsToFilter;
              }
              const selectedSites = filterValues
                .filter((filter: FilterSelectOption) => filter.value && filter.groupBy)
                .map((filter: FilterSelectOption) => filter.value);
              const selectedClusters = uniq(
                concat(
                  filterValues
                    .filter((filter: FilterSelectOption) => !filter.value && filter.groupBy)
                    .map((filter: FilterSelectOption) => filter.groupBy),
                  selectedSites.map((selectedSite: string) =>
                    this.siteSummaryContext.getClusterNameForSiteName(this._siteSummary, selectedSite)
                  )
                )
              );

              return rowsToFilter.filter((row: EntityTableContentRow) => {
                const rowSitesClusters: string[] = row.content['authorizedSitesAndClusters'];
                if (
                  intersection(rowSitesClusters, selectedSites).length > 0 ||
                  intersection(rowSitesClusters, selectedClusters).length > 0
                ) {
                  return row;
                }
              });
            },
          },
        },
        {
          columnId: 'control',
          cssColumnSize: 'col-sm-3',
          label: 'tkey;admin.sitemacman.control.header',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: ['Enabled', 'Disabled'],
            filterFn: (rowsToFilter: EntityTableContentRow[], filterValue: string) => {
              return rowsToFilter.filter((row: EntityTableContentRow) => {
                if (filterValue === 'Disabled' && !row.content['control']) {
                  return row;
                }
                if (filterValue === 'Enabled' && row.content['control']) {
                  return row;
                }
              });
            },
          },
        },
        {
          columnId: 'isSelected',
          cssColumnSize: 'col-sm-1',
          label: 'tkey;admin.sitemacman.select_all',
          filter: {
            type: EntityTableFilterTypes.CHECKBOX,
          },
        },
      ],
      hasActions: true,
    };
  }

  private _generateTableRows(): void {
    this.tableRows = this.filteredResultPermissions.map((permissionResult: SiteBasedHelpdeskUserPermissionResult) => {
      const authSitesAndClusters = permissionResult.authorizedSites.concat(permissionResult.authorizedClusters);
      return {
        cssClass: permissionResult.disabled ? 'table-warning' : '',
        content: {
          userId: permissionResult.userId,
          authorizedSitesAndClusters: authSitesAndClusters,
          control: permissionResult.readAccessToControlEnabled,
          isSelected: permissionResult.isSelected,
        },
        html: {
          userId: permissionResult.disabled
            ? `<span class="badge bg-warning">${this.translateService.instant(
                'tkey;admin.sitemacman.disabled.badge'
              )}</span><span> ${permissionResult.userId}</span>`
            : permissionResult.userId,
          authorizedSitesAndClusters:
            this._sitesSelected(permissionResult).length < 1
              ? `<div class="text-warning"><i class="fa fa-warning me-1"></i><span>${this.translateService.instant(
                  'tkey;admin.sitemacman.noSiteSelectedWarning'
                )}</span></div>`
              : '',
        },
        fields: {
          isSelected: {
            type: EntityTableFieldTypes.CHECKBOX,
            value: permissionResult.isSelected,
          },
        },
        badges: {
          authorizedSitesAndClusters: {
            maxLength: 30,
            values: authSitesAndClusters,
          },
          control: {
            values: permissionResult.readAccessToControlEnabled
              ? [this.translateService.instant('tkey;admin.sitemacman.control.enabled')]
              : [this.translateService.instant('tkey;admin.sitemacman.control.disabled')],
            badgeIcon: [
              permissionResult.readAccessToControlEnabled ? this.smacsIcons.CHECK_CIRCLE : this.smacsIcons.FORBIDDEN,
            ],
            badgeCssClass: [permissionResult.readAccessToControlEnabled ? 'bg-success' : 'bg-danger'],
          },
        },
        actions: [
          {
            buttonStyle: ButtonStyles.PRIMARY,
            buttonSize: ButtonSizes.SMALL,
            dataAutomation: 'site-based-helpdesk-user-edit',
            icon: SmacsIcons.EDIT,
            onClick: () => this._openEditModal(permissionResult),
          },
        ],
      };
    });
  }

  private _sitesSelected(permission: SiteBasedHelpdeskUserPermissionResult): string[] {
    return permission.authorizedSites.concat(permission.authorizedClusters);
  }

  private _formatSelectOptions(): FilterSelectOption[] {
    return this.authorizedSitesFilterOpts.map((authorisedSite) => {
      return {
        label: authorisedSite.site,
        value: authorisedSite.site,
        groupBy: authorisedSite.cluster,
      };
    });
  }
}
