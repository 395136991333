import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ButtonSizes, ButtonStyles } from '../../button/button.component';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { ModalBodyClass } from '../modal-body';
import { Observable, of, throwError } from 'rxjs';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { catchError } from 'rxjs/operators';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { ZiroPromptModalViewProperties } from '../prompt-modal/prompt-modal.component';

export type ModalSize = 'md' | 'lg' | 'xl' | 'xxl';

export class HandledDetailedModalErrorAlert {
  errorTemplate: string;

  constructor(errorTemplate: string) {
    this.errorTemplate = errorTemplate;
  }
}

export interface ZiroModalDetailedModalOptions extends NgbModalOptions {
  modalViewProperties?: ZiroDetailedModalViewProperties;
  bodyClass?: any;
}

export interface ZiroDetailedModalViewProperties extends ZiroPromptModalViewProperties {
  [key: string]: any;
}

@Component({
  selector: 'smacs-detailed-modal',
  templateUrl: './detailed-modal.component.html',
})
export class DetailedModalComponent<T extends ModalBodyClass> implements AfterViewInit {
  @ViewChild('modalBody', { read: ViewContainerRef }) modalBody: ViewContainerRef;

  modalViewProperties: any;
  buttonSizes = ButtonSizes;
  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;
  size: ModalSize;
  showDeleteButton?: boolean;
  submitFn: (...args: any[]) => Observable<any>;
  title: string;
  bodyComponent: ComponentRef<T>;
  isPending = false;
  bodyClass: Type<T>;
  isSubmitted = false;
  isErrorTemplatePresent = false;
  isLoading = true;
  isSaveDisabled = true;
  errorMessage = '';

  constructor(
    private activeModal: NgbActiveModal,
    private changeDetectorRef: ChangeDetectorRef,
    protected smacsFormStateService: SmacsFormStateService
  ) {}

  ngAfterViewInit() {
    if (this.modalViewProperties.setLoading) {
      this.smacsFormStateService.isFormLoading.subscribe((isFormLoading) => {
        this.isLoading = isFormLoading;
      });
    } else {
      this.isLoading = false;
    }

    if (this.modalViewProperties.setIsSaveDisabled) {
      this.smacsFormStateService.isSaveDisabled.subscribe((isSaveDisabled: boolean) => {
        this.isSaveDisabled = isSaveDisabled;
      });
    } else {
      this.isSaveDisabled = false;
    }

    if (this.bodyClass) {
      this.bodyComponent = this.modalBody.createComponent(this.bodyClass);
      this.bodyComponent.instance.submitFn = this.submitFn;

      setTimeout(() => {
        this.isErrorTemplatePresent = !!this.bodyComponent?.instance?.errorTemplate;
      }, 0);

      this.bodyComponent.instance.smacsFormsUpdate$.subscribe(() => {
        if (this.errorMessage) {
          this.errorMessage = '';
        }
      });
      this.changeDetectorRef.detectChanges();
    }
  }

  onSubmit() {
    if (this.isSaveDisabled) {
      return;
    }
    this.isSubmitted = true;
    if (!!this.bodyClass && this.bodyComponent.instance.isFormValid()) {
      this.isPending = true;
      this.bodyComponent.instance
        .submit()
        .pipe(
          catchError((err, caught) => {
            this.errorMessage = err?.errorTemplate;
            this.isPending = false;
            this.bodyComponent.instance._validateAndSubmitSource.next(false);
            if (!err?.errorTemplate) {
              return throwError(() => err);
            }
            return caught;
          })
        )
        .subscribe((result) => {
          this.isPending = false;
          this.activeModal.close(result);
        });
    } else {
      this.bodyComponent.instance._validateAndSubmitSource.next(true);
    }
  }

  onCancelClick() {
    if (this.bodyComponent.instance?.cancel) {
      this.bodyComponent.instance?.cancel();
    } else if (!this.bodyComponent?.instance?.isSubmitting) {
      this.activeModal.close(null);
    }
  }

  onDeleteClick() {
    this.activeModal?.close(null);
    this.bodyComponent.instance.delete().subscribe();
  }

  protected submit() {
    return of(null);
  }

  protected delete() {
    return of(null);
  }

  getSaveButtonLabel(): string {
    return this.modalViewProperties.submitButtonLabel
      ? this.modalViewProperties.submitButtonLabel
      : 'tkey;global.button.save.text';
  }

  getSaveButtonIcon(): SmacsIcons {
    return this.modalViewProperties.submitButtonIcon ? this.modalViewProperties.submitButtonIcon : SmacsIcons.OK;
  }

  resetButtonState() {
    this.isSaveDisabled = false;
    this.isPending = false;
    this.isSubmitted = false;
  }
}
