import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PhoneButtonsComponent } from './phone-buttons.component';
import { PhoneButtonsStandardComponent } from './layout/phone-buttons-standard.component';
import { PhoneButtonsDragNDropButtonComponent } from './drag-n-drop-button/phone-buttons-drag-n-drop-button.component';

import { TranslateModule } from '@ngx-translate/core';
import { ReplaceSpaces } from '../filters/replace-spaces.filter';
import { PhoneButtonEditFormModule } from './edit-form/phone-button-edit-form.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CounterPipe } from '../pipes/counter.pipe';
import { SharedModule } from '../shared.module';
import { ButtonModule } from '../../button/button.module';
import { SiteContext } from '../../helpdesk/shared/contexts/site.context';
import { LoadingBlockModule } from '../loading-block/loading-block.module';
import { PhoneButtonsLineComponent } from './edit-form/phone-buttons-shared/phone-buttons-line.component';
import { PhoneButtonsIntercomComponent } from './edit-form/phone-buttons-shared/phone-buttons-intercom.component';
import { PhoneButtonsSpeedDialComponent } from './edit-form/phone-buttons-shared/phone-buttons-speed-dial.component';
import { PhoneButtonsBlfComponent } from './edit-form/phone-buttons-shared/phone-buttons-blf';
import { PhoneButtonsLineHelpdeskComponent } from './edit-form/helpdesk/phone-buttons-line-helpdesk.component';
import { PhoneButtonsServiceUrlHelpdeskComponent } from './edit-form/helpdesk/phone-buttons-service-url-helpdesk.component';
import { PhoneButtonEditModalComponent } from './edit-modal/phone-button-edit-modal.component';
import { PhoneButtonsLineExtensionSearchComponent } from './edit-form/helpdesk/phone-buttons-line-extension-search/phone-buttons-line-extension-search.component';
import { PhoneButtonsLineHelpdeskFormComponent } from './edit-form/helpdesk/phone-buttons-line-helpdesk-form/phone-buttons-line-helpdesk-form.component';
import { PhoneButtonsEditFormComponent } from './edit-form/phone-buttons-edit-form.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    PhoneButtonEditFormModule,
    NgbModule,
    NgSelectModule,
    SharedModule,
    ButtonModule,
    LoadingBlockModule,
  ],
  declarations: [
    ReplaceSpaces,
    PhoneButtonsComponent,
    PhoneButtonsDragNDropButtonComponent,
    PhoneButtonsStandardComponent,
    CounterPipe,
  ],
  exports: [
    PhoneButtonsComponent,
    PhoneButtonsLineComponent,
    PhoneButtonsIntercomComponent,
    PhoneButtonsSpeedDialComponent,
    PhoneButtonsBlfComponent,
    PhoneButtonsLineHelpdeskComponent,
    PhoneButtonsServiceUrlHelpdeskComponent,
    PhoneButtonEditModalComponent,
    PhoneButtonsLineExtensionSearchComponent,
    PhoneButtonsLineHelpdeskFormComponent,
    PhoneButtonsEditFormComponent,
  ],
  providers: [SiteContext],
})
export class PhoneButtonsModule {}
