import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  DefaultLdapUserSiteAttribute,
  LdapUserDialPlanAttributes,
  LdapUserSiteAttribute,
} from '../../../shared/models/generated/smacsModels';
import { Nvp } from '../../../shared/models/nvp';
import { AuditHeaderService } from '../../../shared/services/audit-header.service';

@Injectable()
export class LdapUserResource {
  constructor(private _http: HttpClient, private _auditHeaderService: AuditHeaderService) {}

  get(username: string): Observable<LdapUserDialPlanAttributes> {
    return this._http.get<LdapUserDialPlanAttributes>(
      `/services/cisco/macs/ldap-user-dial-plan-attributes/${encodeURIComponent(username)}`
    );
  }

  put(body: LdapUserDialPlanAttributes, audits?: Nvp[]): Observable<void> {
    const httpOptions = this._buildHttpOptions(audits);
    return this._http.put<void>(
      `/services/cisco/macs/ldap-user-dial-plan-attributes/${encodeURIComponent(body.username)}`,
      body,
      httpOptions
    );
  }

  getLdapUserSiteAttributesDefaults(siteId: number): Observable<DefaultLdapUserSiteAttribute> {
    return this._http.get<DefaultLdapUserSiteAttribute>(`/services/cisco/defaults/ldap-user-site-attributes/${siteId}`);
  }

  putLdapUserSiteAttributesDefaults(body: Omit<LdapUserSiteAttribute, 'id'>, audits?: Nvp[]): Observable<void> {
    const httpOptions = this._buildHttpOptions(audits);
    return this._http.put<void>(
      `/services/cisco/macs/ldap-user-site-attributes/${body.userPrincipalName}`,
      body,
      httpOptions
    );
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this._auditHeaderService.buildHeader(auditTags) } : {};
  }
}
