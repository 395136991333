import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { PhoneResource } from '../../../../shared/resources/phone.resource';
import { DeviceRegistrationStatus, PhoneStatus } from '../../../../shared/models/generated/smacsModels';
import { TranslateService } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { DeviceRegistrationStatusPollingService } from '../../../../shared/services/cisco/device-registration-status-polling.service';

@Component({
  selector: 'smacs-service-tile-edit',
  templateUrl: './service-tile-edit.component.html',
  styleUrls: ['./service-tile-edit.component.scss'],
  providers: [DeviceRegistrationStatusPollingService],
})
export class SmacsServiceTileEditComponent implements OnInit, OnDestroy {
  @Input() isEnabled = true;
  @Input() tooltip: string;
  @Input() icon: string;
  @Input() serviceName: string;
  @Input() description: string;
  @Input() isDeskPhone = false;
  @Input() isDevice = false;
  @Input() isStatusCheckInProgress = false;
  @Input() hasMultipleDeskPhones = false;
  @Input() deleteTooltip: string;
  @Input() deviceToolip: string;
  @Input() deviceIconMarkup: string;
  @Input() isDeleteEnabled = true;
  @Input() isDeleteShown = true;
  @Input() isExchangeEnabled = true;
  @Input() isExchangeShown = true;
  @Input() phone: { serverId: number; name: string };

  @Output() editTileClicked = new EventEmitter();
  @Output() editDeskPhoneClicked = new EventEmitter();
  @Output() deskPhoneAddClicked = new EventEmitter();
  @Output() deskPhoneCopyClicked = new EventEmitter();
  @Output() deleteClicked = new EventEmitter();
  @Output() exchangeClicked = new EventEmitter();

  @ViewChild('tileTooltip') tileTooltip: NgbTooltip;

  ATTEMPTS_COUNT = 6;

  isHoverTile = false;
  isHoverAddDeskphone = false;
  isHoverCopyDeskphone = false;
  isHoverDelete = false;
  isHoverExchange = false;
  isHoverRefresh = false;

  phoneStatus: DeviceRegistrationStatus;
  phoneStatusAttemptCount = 0;
  phoneStatusTimeout: number;
  phoneStatusInterval: number;
  phoneStatusAttemptTime: number;
  phoneStatusTooltip = '';
  smacsIcons = SmacsIcons;
  deviceRegistrationStatus = DeviceRegistrationStatus;

  constructor(
    private phoneResource: PhoneResource,
    private translateService: TranslateService,
    protected ngZone: NgZone
  ) {}

  ngOnInit() {
    if (this.phone) {
      this.ngZone.runOutsideAngular(() => {
        this.getPhoneStatus();
      });
    }
  }

  ngOnDestroy() {
    window.clearTimeout(this.phoneStatusTimeout);
    window.clearInterval(this.phoneStatusInterval);
  }

  onEditTileClicked() {
    this.editTileClicked.emit();
  }

  onDeskPhoneAddClicked() {
    this.deskPhoneAddClicked.emit();
  }

  onDeskPhoneCopyClicked() {
    this.deskPhoneCopyClicked.emit();
  }

  onDeleteClicked() {
    if (this.isDeleteEnabled) {
      this.deleteClicked.emit();
    }
  }

  onExchangeClicked() {
    if (this.isExchangeEnabled) {
      this.exchangeClicked.emit();
    }
  }

  getPhoneStatus() {
    window.clearTimeout(this.phoneStatusTimeout);
    const retryCyclesMs = [5000, 15000, 30000, 30000, 60000, 60000];
    this.phoneStatusTooltip = this.translateService.instant('tkey;userdetail.desk_phone.status.retry.tooltip');
    this.phoneResource.getPhoneStatus(this.phone.name, this.phone.serverId).subscribe((response: PhoneStatus) => {
      this.phoneStatusAttemptCount++;

      this.ngZone.run(() => (this.phoneStatus = response.status));

      if (
        (response.status === DeviceRegistrationStatus.UNREGISTERED ||
          response.status === DeviceRegistrationStatus.NOT_FOUND) &&
        this.phoneStatusAttemptCount <= 6
      ) {
        this.phoneStatusAttemptTime = Date.now() + retryCyclesMs[this.phoneStatusAttemptCount - 1];
        this.phoneStatusTimeout = window.setTimeout(() => {
          this.getPhoneStatus();
        }, retryCyclesMs[this.phoneStatusAttemptCount - 1]);
      } else {
        this.phoneStatusTooltip =
          response.status === DeviceRegistrationStatus.NOT_FOUND ||
          response.status === DeviceRegistrationStatus.UNREGISTERED
            ? this.translateService.instant('tkey;userdetail.desk_phone.status.not.found.tooltip')
            : '';
      }
    });
  }

  getTooltip(): string {
    return this.phoneStatusTooltip || this.tooltip;
  }
}
