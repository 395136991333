<div class="card card-well top-buffer animated fadeIn faster" data-automation="transfer-public-component">
  <div class="card-header">
    <legend class="bottom-buffer mt-0 pb-2">
      <span class="fa-stack fa-lg text-info">
        <i class="icon-circle fa-stack-2x"></i>
        <i [attr.class]="smacsIcons.DESKPHONE + ' me-1 fa-stack-1x fa-inverse'"></i>
      </span>

      <span>{{ 'tkey;transfer.menu.to.public' | translate }}</span>
    </legend>

    <app-loading-block [hidden]="!isLoading"></app-loading-block>
    <div *ngIf="!isLoading">
      <div *ngIf="showVoicemailCard" class="pb-2">
        <h3>
          {{ 'tkey;pages.details.transfer.keep.voicemail.prompt' | translate }}
        </h3>
        <p>
          <strong>{{ 'tkey;pages.details.transfer.keep.voicemail.subheader.note.text' | translate }}</strong>
          {{ 'tkey;pages.details.transfer.keep.voicemail.subheader.text' | translate }}
        </p>
      </div>
      <div *ngIf="!showVoicemailCard" class="pb-2">
        <p>
          {{ 'tkey;transfer.userToPublic.confirmation.text' | translate }}
        </p>
        <p>
          <strong>{{ 'tkey;transfer.confirmation.continue.text' | translate }}</strong>
        </p>
      </div>

      <div class="text-end pb-2 border-top pt-3">
        <smacs-button
          [dataAutomation]="'transfer-public-cancel-button'"
          [buttonStyle]="buttonStyles.DEFAULT"
          [label]="'tkey;dialogs.button.cancel'"
          [cssClass]="'me-1'"
          [isDisabled]="isSubmitting"
          (clicked)="onCancelClicked()"
        ></smacs-button>
        <smacs-button
          *ngIf="showVoicemailCard"
          [dataAutomation]="'delete-voicemail-button'"
          [iconPending]="smacsIcons.SAVING"
          [buttonStyle]="buttonStyles.DANGER"
          [cssClass]="'me-1'"
          [label]="'tkey;pages.details.transfer.keep.voicemail.delete.button'"
          [isDisabled]="isSubmitting"
          (clicked)="onVoicemailButtonClicked(false)"
        ></smacs-button>
        <smacs-button
          *ngIf="showVoicemailCard"
          [dataAutomation]="'keep-voicemail-button'"
          [iconPending]="smacsIcons.SAVING"
          [buttonStyle]="buttonStyles.PRIMARY"
          [cssClass]="'me-1'"
          [label]="'tkey;pages.details.transfer.keep.voicemail.keep.button'"
          [isDisabled]="isSubmitting"
          (clicked)="onVoicemailButtonClicked(true)"
        ></smacs-button>
        <smacs-button
          *ngIf="!showVoicemailCard"
          [isPending]="isSubmitting"
          [dataAutomation]="'transfer-public-reassign-button'"
          [iconPending]="smacsIcons.SAVING"
          [buttonStyle]="buttonStyles.PRIMARY"
          [label]="'tkey;transfer.transferBtn.text'"
          [isDisabled]="isSubmitting"
          (clicked)="onReassignClicked()"
        ></smacs-button>
      </div>
    </div>
  </div>
</div>
