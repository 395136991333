<div class="card smacs-search-result-card mb-2">
  <div class="card-header d-flex align-items-center">
    <div class="flex-fill">
      <i [attr.class]="icon + ' me-1'" *ngIf="!isSvgIcon()"></i>
      <svg-icon
        *ngIf="isSvgIcon()"
        [name]="icon"
        [svgStyle]="{ 'width.rem': 1, 'margin-bottom.px': 2 }"
        [ngClass]="'me-1'"
      ></svg-icon>
      <span [innerHtml]="description" data-automation="search-result-description"></span>
    </div>
    <div class="d-flex">
      <span
        *ngIf="isSyncInProgress()"
        data-automation="search-result-warning-badge"
        class="badge bg-warning me-1 d-flex align-items-center"
      >
        <i [attr.class]="smacsIcons.WARNING + ' me-1'"></i>
        <span [innerHTML]="'tkey;search.end_user_result.ldap_sync_in_progress' | translate"></span>
      </span>

      <ng-template #tooltipContent><span [innerHTML]="edit?.tooltip | translate"></span></ng-template>
      <div
        *ngIf="edit?.link"
        class="me-1"
        [class.disabled]="edit?.isDisabled"
        [ngbTooltip]="tooltipContent"
        [disableTooltip]="!edit?.tooltip"
        data-automation="search-result-row-edit"
      >
        <a
          [routerLink]="edit.link"
          data-automation="search-result-row-edit-link"
          [attr.class]="'btn ' + buttonSizes.XSMALL + ' ' + buttonStyles.PRIMARY"
          [ngClass]="{ disableClick: edit.isDisabled, disabled: edit.isDisabled }"
        >
          <i [attr.class]="smacsIcons.EDIT"></i>
        </a>
      </div>
      <div
        *ngIf="pinReset?.link"
        class="me-1"
        [class.disabled]="pinReset?.isDisabled"
        [ngbTooltip]="pinReset?.tooltip | translate"
        [disableTooltip]="!pinReset?.tooltip"
      >
        <a
          [routerLink]="pinReset.link"
          data-automation="search-result-row-pin-reset-link"
          [attr.class]="'btn ' + buttonSizes.XSMALL + ' ' + buttonStyles.PRIMARY"
          [ngClass]="{ disableClick: pinReset.isDisabled, disabled: pinReset.isDisabled }"
        >
          <i [attr.class]="smacsIcons.REFRESH"></i>
        </a>
      </div>
      <smacs-button
        *ngIf="delete?.isDeletable"
        [dataAutomation]="'search-result-row-delete-button'"
        (clicked)="onDeleteClicked()"
        [buttonStyle]="buttonStyles.DANGER"
        [buttonSize]="buttonSizes.XSMALL"
        [icon]="smacsIcons.DELETE"
        [isDisabled]="delete?.isDisabled"
        [ngbTooltip]="delete.tooltip | translate"
      ></smacs-button>
    </div>
  </div>
</div>
