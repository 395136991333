import { Component, Input, ViewChild } from '@angular/core';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import {
  SmacsFieldComponentConfig,
  SmacsFormsUpdate,
  SmacsFormsValidationState,
} from '../../../forms/smacs-forms-models';
import { SmacsFieldAbstractDirective } from '../../../forms/smacs-field-abstract.directive';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual } from 'lodash';
import { SelectConfigEntity } from '../legacy-smacs-select-config/legacy-smacs-select-config.component';
import { SelectConfigFormComponent } from '../legacy-smacs-select-config/select-config-form/select-config-form.component';

export enum ReadWriteConfigType {
  LICENSE = 'LICENSE',
  SECURITY_GROUP = 'SECURITY_GROUP',
}

export class SmacsReadWriteSelectFieldConfig extends SmacsFieldComponentConfig {
  constructor(
    public config: {
      availableOptions: string[];
    }
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-read-write-select-config',
  templateUrl: './smacs-read-write-select-config.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsReadWriteSelectConfigComponent }],
})
export class SmacsReadWriteSelectConfigComponent extends SmacsFieldAbstractDirective<
  SelectConfigEntity,
  SelectConfigEntity,
  SmacsReadWriteSelectFieldConfig
> {
  @Input() type: ReadWriteConfigType;

  availableOptions: string[];
  isLoading = true;

  @ViewChild(SelectConfigFormComponent) childFormComponent: SelectConfigFormComponent;

  constructor(protected smacsFormStateService: SmacsFormStateService, private translateService: TranslateService) {
    super(smacsFormStateService);

    this.translateService.get(this.translateService.currentLang).subscribe(() => {
      this.isLoading = false;
    });
  }

  applyComponentConfig = ({ config }: SmacsReadWriteSelectFieldConfig) => {
    this.availableOptions = config.availableOptions;
  };

  onFormUpdate($event: SmacsFormsUpdate<SelectConfigEntity>) {
    if (!isEqual($event.new, $event.old)) {
      this.entity = $event.new;
      this.updateSelf(this.entity, true, $event.old);
      this.updateParent(cloneDeep($event.old));
    } else if (this.childFormComponent) {
      // This forces validation state to parent form
      if (
        (this.childFormComponent.isFormValid() && this.validationState !== SmacsFormsValidationState.VALID) ||
        (!this.childFormComponent.isFormValid() && this.validationState !== SmacsFormsValidationState.INVALID)
      ) {
        this.validationState = this.childFormComponent
          ? this.childFormComponent.isFormValid()
            ? SmacsFormsValidationState.VALID
            : SmacsFormsValidationState.INVALID
          : SmacsFormsValidationState.VALID;

        this.updateParent();
      }
    }
  }
}
