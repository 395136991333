import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { LicenseInfo } from '../models/generated/smacsModels';
import { map } from 'rxjs/operators';

@Injectable()
export class LicenseContext {
  // Public so stages can update state
  _licenseStateSource = new ReplaySubject<LicenseInfo>(1);
  licenseState$ = this._licenseStateSource.asObservable();

  constructor(private http: HttpClient) {
    this.refreshLicenseInfo().subscribe();
  }

  refreshLicenseInfo(): Observable<void> {
    return this.http.get<LicenseInfo>('/services/cisco/admin/licenses/info').pipe(
      map((data: LicenseInfo) => {
        this._licenseStateSource.next(data);
      })
    );
  }
}
