import { Component } from '@angular/core';
import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import { WeekDay } from '@angular/common';
import { SmacsFieldComponentConfig } from '../../smacs-forms-models';
import { NgModel } from '@angular/forms';

export class DateComponentConfig extends SmacsFieldComponentConfig {
  constructor(
    public config: {
      disabledDays?: WeekDay[];
      allowToday?: boolean;
      allowBeforeToday?: boolean;
      allowAfterToday?: boolean;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'ziro-date',
  templateUrl: 'date.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: DateComponent }],
})
export class DateComponent extends SmacsFieldAbstractDirective<string, Date, DateComponentConfig> {
  disabledDays: WeekDay[];
  minDate: Date;
  maxDate: Date;
  disabledDates: Date[];

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  applyComponentConfig = ({ config }: DateComponentConfig) => {
    this.disabledDays = config.disabledDays ?? [];

    this._determineMinAndMaxDates(config.allowToday, config.allowBeforeToday, config.allowAfterToday);
  };

  updateStateAndSelf(newFieldData: Date, ngModelDir: NgModel) {
    if (newFieldData) {
      newFieldData.setUTCHours(12, 0, 0, 0);
    }
    super.updateStateAndSelf(newFieldData, ngModelDir);
  }

  private _determineMinAndMaxDates(allowToday = true, allowBeforeToday = true, allowAfterToday = true) {
    const today = new Date();
    this.minDate = allowBeforeToday ? undefined : today;
    this.maxDate = allowAfterToday ? undefined : today;
    this.disabledDates = allowToday ? undefined : [today];
  }

  toEntity = (fieldData: Date): string => {
    return fieldData ? fieldData.toISOString() : null;
  };

  toFieldData = (entity: string): Date => {
    return entity ? new Date(entity) : null;
  };
}
