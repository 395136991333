<div class="card mb-3" [attr.data-automation]="entity.id + '-delegate-card'">
  <div class="card-header d-flex justify-content-between align-items-center">
    <span class="d-flex flex-row align-items-center">
      <img *ngIf="photoBase64" [src]="'data:image/png;base64,' + photoBase64" class="round-image-table" />
      <i *ngIf="!photoBase64" class="fa fa-2x icon-user-circle round-image-table-icon"></i>
      <h5 class="card-title ms-2 mb-0">{{ displayName }}</h5>
    </span>
    <button class="btn p-0" (click)="removeDelegateClicked()" [attr.data-automation]="'remove-delegate-button'">
      <i class="fa fa-2x fa-times"></i>
    </button>
  </div>
  <div class="card-body">
    <smacs-checkbox [fieldId]="'makeCallsEnabled'"></smacs-checkbox>
    <smacs-checkbox [fieldId]="'receiveCallsEnabled'"></smacs-checkbox>
    <smacs-checkbox [fieldId]="'manageSettingsEnabled'"></smacs-checkbox>
  </div>
</div>
