import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SmacsFormConfig, SmacsFormsMessage, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import {
  EndUser,
  Global360View,
  Voicemail,
  VoicemailAlternateExtension,
  VoicemailFieldConfig,
  VoicemailRef
} from '../../../../shared/models/generated/smacsModels';
import { VoicemailSearchResource } from '../../../../shared/resources/voicemail-search.resource';
import { forkJoin, Observable, of, Subscriber, Subscription, throwError, zip } from 'rxjs';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { catchError, delayWhen, map, tap } from 'rxjs/operators';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { VoicemailResource } from '../../../../shared/resources/voicemail.resource';
import { DefaultEndUserResource } from '../../../../shared/resources/default-end-user.resource';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import {
  VoicemailAlternateExtensionsResource
} from '../../../../shared/resources/voicemail-alternate-extensions.resource';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  VoicemailSmtpNotificationsDeviceResource
} from '../../../../shared/resources/voicemail-smtp-notifications-device.resource';
import { PhoneButtonsService } from '../../../../shared/phone-buttons/shared/phone-buttons.service';
import { VoicemailService, VoicemailServiceParams } from '../../../../shared/services/voicemail.service';
import { cloneDeep } from 'lodash';

export interface VoicemailFormEntity {
  alias: string;
  voicemailTemplate: string;
  firstName: string;
  lastName: string;
  displayName: string;
  alternateExtensions: string[];
  smtpNotificationDevice: string;
}

@Component({
  selector: 'smacs-voicemail-form',
  templateUrl: './voicemail-form.component.html',
  providers: [VoicemailService],
})
export class VoicemailFormComponent
  extends SmacsFormAbstractDirective<VoicemailFormEntity, VoicemailFormEntity>
  implements OnInit, OnDestroy, OnChanges
{
  @Input() modalProperties?: any;
  @Input() fieldConfig: VoicemailFieldConfig;
  @Input() endUser: EndUser;
  @Input() isExistingVoicemail: boolean;
  @Input() isExistingVoicemailOnPublicPhone: boolean;
  @Input() unityServerId: number;
  @Input() isPending = false;
  @Input() serverId: number;
  @Input() siteId: number;
  @Input() extension: string;
  @Input() voicemail: Voicemail;
  @Input() originalValue: VoicemailFormEntity;
  @Input() username: string;
  @Input() alternateExtensions: VoicemailAlternateExtension[] = [];
  @Input() global360View: Global360View;

  @Output() voicemailDeleted = new EventEmitter<boolean | string>();
  @Output() voicemailSaved = new EventEmitter<boolean | string>();
  @Output() voicemailRefUpdated = new EventEmitter<VoicemailRef>();

  ALTERNATE_EXTENSION_MAX_LENGTH_NUMBER = 40;
  DISPLAY_NAME_MAX_LENGTH_NUMBER = 64;
  fieldGroups = {
    textInputs: ['alias', 'firstName', 'lastName', 'displayName'],
  };
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  voicemailId: string;
  isDeleting = false;
  isSaving = false;
  voicemailRef: VoicemailRef;

  protected formConfig: SmacsFormConfig;
  private _validators = {
    aliasPatternValidator: (value: string) =>
      !value || /^[^\s()\[\]\\,<>:;]{1,64}$/.test(value)
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID,
    alternateExtensionsPatternValidator: (values: string[]) =>
      values.every((value: string) =>
        /(^[+*#0-9]*$)|(^(sip:)?[-._0-9A-Za-z]+@(([0-9]+\.[0-9]+\.[0-9]+\.[0-9]+)|([0-9A-Za-z][-0-9A-Za-z]*(\.[-0-9A-Za-z]+)*\.[A-Za-z](([-0-9A-Za-z]*[0-9A-Za-z])|([0-9A-Za-z]*))))$)/.test(
          value
        )
      )
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID,
    voiceMailAliasValidator: (value: string) => {
      if (!value) {
        return SmacsFormsValidationState.VALID;
      }
      return this.isVoiceMailAliasValid(value);
    },

    alternateExtensionValueValidator: (values: string[]) =>
      values.every(
        (value: string) =>
          !(this.endUser && (value === this.endUser.primaryExtension.extension || value === this.endUser.mailId))
      )
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID,
    displayNameLengthValidator: (value: string) =>
      value.length <= this.DISPLAY_NAME_MAX_LENGTH_NUMBER
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID,
    alternateExtensionsLengthValidator: (values: string[]) =>
      values.every((value: string) => value.length <= this.ALTERNATE_EXTENSION_MAX_LENGTH_NUMBER)
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID,

    smtpNotificationDevicePatternValidator: (value: string) =>
      !value ||
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      )
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID,
  };
  private _duplicateExtension: string;
  private _duplicateAlias: string;
  private _subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private voicemailSearchResource: VoicemailSearchResource,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private voicemailResource: VoicemailResource,
    private defaultEndUserResource: DefaultEndUserResource,
    private voicemailAlternateExtensionsResource: VoicemailAlternateExtensionsResource,
    private bottomNavService: BottomNavService,
    protected smacsFormStateService: SmacsFormStateService,
    private router: Router,
    private voicemailSmtpNotificationsDeviceResource: VoicemailSmtpNotificationsDeviceResource,
    private phoneButtonsService: PhoneButtonsService,
    private voicemailService: VoicemailService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initVoiceMailFormConfig();
    if (!this.modalProperties) {
      this._initBottomNav();
    }
    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this._setPending(true);
      this.isErrorPresent.subscribe((hasError: boolean) => {
        if (hasError) {
          this._setPending(false);
        }
        this.bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: hasError,
          })
        );
      });
    });
    this._subs.add(formSubmittedSub);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.isPending) {
      const isPending = changes.isPending.currentValue as boolean;
      this._validateAndSubmitSource.next(isPending);
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subs.unsubscribe();
  }

  isVoiceMailAliasValid(alias: string): Observable<SmacsFormsValidationState> {
    return this.voicemailSearchResource.searchByAlias(alias, this.unityServerId).pipe(
      map((results) =>
        results.every((user) => {
          if (
            alias !== user.ref.alias ||
            (this.isExistingVoicemail && this.voicemail.alias === user.ref.alias) ||
            (this.isExistingVoicemailOnPublicPhone && this.voicemail.alias === user.ref.alias)
          ) {
            return true;
          } else {
            this._duplicateAlias = user.ref.alias;
            this._duplicateExtension = user.ref.extension;
            return false;
          }
        })
          ? SmacsFormsValidationState.VALID
          : SmacsFormsValidationState.INVALID
      )
    );
  }

  handleDeleteClick(): void {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: encodeURIComponent('text-danger'),
        promptBody: this.translateService.instant('tkey;search.voicemail.delete.warning.text', {
          alias: this.originalValue.alias,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onDeleteConfirm(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  handleSaveClick(): Observable<VoicemailRef> {
    this.isPending = true;
    this._setPending(this.isPending);
    return this._updateVoicemail().pipe(
      delayWhen(() =>
        forkJoin([this._addOrDeleteAlternateExtensionsIfNeeded(), this._saveSmtpNotificationDeviceIfNeeded()])
      ),
      delayWhen(() =>
        forkJoin([this._callDefaultDirectoryNumberResourceIfNeeded(), this._callDefaultUserResourceIfNeeded()])
      ),
      tap(() => {
        this.originalValue = { ...this.formData };
        this.isExistingVoicemail = true;
        this.isPending = false;
        this.smacsFormStateService.setIsFormDirty(false);
        if (!this.modalProperties) {
          this.voicemailSaved.emit(true);
        } else {
          this.voicemailSaved.emit(this.formData.alias);
        }
        this._showSuccessSaveToast();
      }),
      catchError((response) => {
        this.isSaving = false;
        this.isPending = false;
        this._setPending(this.isPending);
        return throwError(() => response);
      })
    );
  }

  submit() {
    return this.handleSaveClick();
  }

  private _initVoiceMailFormConfig() {
    this.formConfig = {
      fields: {
        alias: {
          fieldId: 'alias',
          label: 'tkey;shared.voicemail.alias.label',
          dataAutomation: 'voicemail-alias',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          validation: [
            {
              validator: this._validators.aliasPatternValidator,
              message: 'tkey;validators.global.error.pattern',
            },
            {
              validator: this._validators.voiceMailAliasValidator,
              message: (): SmacsFormsMessage => {
                return {
                  content: 'tkey;addvoicemail.alias.validation.error.helpblock',
                  params: {
                    alias: this._duplicateAlias,
                    dn: this._duplicateExtension,
                  },
                };
              },
            },
          ],
          hidden: () => !this.fieldConfig.alias.show,
          required: () => this.fieldConfig.alias.required,
          defaultValue: () => this.fieldConfig.alias.defaultValue,
        },
        firstName: {
          fieldId: 'firstName',
          label: 'tkey;shared.voicemail.firstname.label',
          dataAutomation: 'voicemail-first-name',
          hidden: () => !this.fieldConfig.firstName.show,
          required: () => this.fieldConfig.firstName.required,
          defaultValue: () => this.fieldConfig.firstName.defaultValue,
        },
        lastName: {
          fieldId: 'lastName',
          label: 'tkey;shared.voicemail.lastname.label',
          dataAutomation: 'voicemail-last-name',
          hidden: () => !this.fieldConfig.lastName.show,
          required: () => this.fieldConfig.lastName.required,
          defaultValue: () => this.fieldConfig.lastName.defaultValue,
        },
        voicemailTemplate: {
          fieldId: 'voicemailTemplate',
          label: 'tkey;site_management.site.section.field.voicemail_template.text',
          dataAutomation: 'voicemail-template',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.voicemailTemplate.possibleOptions }),

          hidden: () => this.isExistingVoicemail || !this.fieldConfig.voicemailTemplate.show,
          required: () => this.fieldConfig.voicemailTemplate.required,
          defaultValue: () => this.fieldConfig.voicemailTemplate.defaultValue,
        },
        displayName: {
          fieldId: 'displayName',
          label: 'tkey;shared.voicemail.displayname.label',
          dataAutomation: 'voicemail-display-Name',
          validation: [
            {
              validator: this._validators.displayNameLengthValidator,
              message: (): SmacsFormsMessage => {
                return {
                  content: 'tkey;validators.global.error.maxlength',
                  params: {
                    maxlength: this.DISPLAY_NAME_MAX_LENGTH_NUMBER,
                  },
                };
              },
            },
          ],
          hidden: () => !this.fieldConfig.displayName.show,
          required: () => this.fieldConfig.displayName.required,
          defaultValue: () => this.fieldConfig.displayName.defaultValue,
        },
        alternateExtensions: {
          fieldId: 'alternateExtensions',
          label: 'tkey;shared.voicemail.alternate_extensions.label',
          dataAutomation: 'voicemail-alternate-extensions',
          validation: [
            {
              validator: this._validators.alternateExtensionsPatternValidator,
              message: 'tkey;validators.global.error.pattern',
            },
            {
              validator: this._validators.alternateExtensionValueValidator,
              message: 'tkey;shared.voicemail.alternate_extensions.primary_extension_validation_error',
            },
            {
              validator: this._validators.alternateExtensionsLengthValidator,
              message: (): SmacsFormsMessage => {
                return {
                  content: 'tkey;validators.global.error.maxlength',
                  params: {
                    maxlength: this.ALTERNATE_EXTENSION_MAX_LENGTH_NUMBER,
                  },
                };
              },
            },
          ],
          hidden: () =>
            this.modalProperties ||
            !this.fieldConfig.alternateExtensions.show ||
            this._isVoicemailExtensionDifferentFromUserPrimaryExtension(),
          required: () => this.fieldConfig.alternateExtensions.required,
          defaultValue: () =>
            this.fieldConfig.alternateExtensions.defaultValues?.length
              ? this.fieldConfig.alternateExtensions.defaultValues
              : null,
          valExcluded: () => !this.fieldConfig.alternateExtensions.show,
        },
        smtpNotificationDevice: {
          fieldId: 'smtpNotificationDevice',
          label: 'tkey;site_management.site.section.field.smtp_notification_device.text',
          dataAutomation: 'voicemail-smtp-notification-device',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          validation: [
            {
              validator: this._validators.smtpNotificationDevicePatternValidator,
              message: 'tkey;validators.global.email.invalid.error',
            },
          ],
          hidden: () => (this.modalProperties ? true : !this.fieldConfig.smtpNotificationDevice.show),
          required: () => this.fieldConfig.smtpNotificationDevice.required,
          defaultValue: () => this.fieldConfig.smtpNotificationDevice.defaultValue,
          valExcluded: () => !this.fieldConfig.smtpNotificationDevice.show,
        },
      },
    } as SmacsFormConfig;
  }

  private _initBottomNav() {
    const bottomNavButtons = [];
    bottomNavButtons.push({
      id: 'cancel',
      dataAutomation: 'user-voicemail-cancel',
      label: 'tkey;global.button.cancel.text',
      buttonClass: ButtonStyles.DEFAULT,
      cb: () => this._goToUserHome(),
    });
    if (this.isExistingVoicemail) {
      bottomNavButtons.push({
        id: 'delete',
        dataAutomation: 'user-voicemail-delete',
        label: 'tkey;global.button.delete.text',
        icon: this.smacsIcons.DELETE,
        buttonClass: ButtonStyles.DANGER,
        state: {
          pending: false,
          buttonDisableState: { disabled: !this.isExistingVoicemail, tooltipKey: '' },
        },
        cb: () => this.handleDeleteClick(),
      });
    }
    bottomNavButtons.push({
      id: 'save',
      dataAutomation: 'user-voicemail-save',
      label: 'tkey;global.button.save.text',
      icon: this.smacsIcons.OK,
      buttonClass: ButtonStyles.PRIMARY,
      state: {
        pending: false,
        buttonDisableState: { disabled: this.isPending, tooltipKey: '' },
      },
      type: ButtonTypes.SUBMIT,
      submitSubject: this._validateAndSubmitSource,
    });
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _goToUserHome() {
    this.router.navigate(this.voicemail ? ['../../'] : ['../'], {
      relativeTo: this.route,
    });
  }

  private _updateVoicemail = (): Observable<VoicemailRef> => {
    return new Observable<VoicemailRef>((subscriber) => {
      if (this._wasAnyChangeDoneToVoicemail() || !this.isExistingVoicemail || !this.isExistingVoicemailOnPublicPhone) {
        if (this.isExistingVoicemail || this.isExistingVoicemailOnPublicPhone) {
          return this._updateExistingVoicemail().subscribe((voicemailRef) => {
            this.voicemailRefUpdated.emit(voicemailRef);
            subscriber.next(voicemailRef);
            subscriber.complete();
          });
        } else {
          return this._createNewVoicemail().subscribe((voicemailRef) => {
            this.voicemailRefUpdated.emit(voicemailRef);
            subscriber.next(voicemailRef);
            subscriber.complete();
          });
        }
      } else {
        subscriber.next();
        subscriber.complete();
      }
    });
  };

  private _addOrDeleteAlternateExtensionsIfNeeded = (): Observable<void> => {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      this._modifyChangesInAlternateExtensionsIfNeeded().subscribe(() => {
        let alternateExtensionsToAdd: string[] = [];
        let alternateExtensionsToRemove: string[] = [];

        if (this.originalValue.alternateExtensions.length > this.formData.alternateExtensions.length) {
          alternateExtensionsToRemove = this.originalValue.alternateExtensions.slice(
            this.formData.alternateExtensions.length
          );
        } else if (this.formData.alternateExtensions.length > this.originalValue.alternateExtensions.length) {
          alternateExtensionsToAdd = this.formData.alternateExtensions.slice(
            this.originalValue.alternateExtensions.length
          );
        }

        if (alternateExtensionsToAdd.length) {
          this._addAlternateExtensions(alternateExtensionsToAdd).subscribe({
            next: () => {
              subscriber.next();
              subscriber.complete();
            },
            error: (response) => {
              subscriber.error(response);
              subscriber.complete();
            },
          });
        } else if (alternateExtensionsToRemove.length) {
          this._deleteAlternateExtensions(alternateExtensionsToRemove).subscribe(() => {
            subscriber.next();
            subscriber.complete();
          });
        } else {
          subscriber.next();
          subscriber.complete();
        }
      });
    });
  };

  private _saveSmtpNotificationDeviceIfNeeded(): Observable<void> {
    if (!this.endUser) {
      // public phones and secondary lines do not have smtp notification device
      return of(null);
    }
    return new Observable<void>((subscriber: Subscriber<void>) => {
      if (
        !this.isExistingVoicemail ||
        this.originalValue.smtpNotificationDevice !== this.formData.smtpNotificationDevice
      ) {
        this.voicemailSmtpNotificationsDeviceResource
          .put(
            this.unityServerId,
            this.voicemail.id,
            {
              email: this.formData.smtpNotificationDevice,
            },
            [{ name: 'Alias', value: this.formData.alias }]
          )
          .subscribe(() => {
            subscriber.next();
            subscriber.complete();
          });
      } else {
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  private _modifyChangesInAlternateExtensionsIfNeeded = (): Observable<void> => {
    const originalArrayLength = this.originalValue.alternateExtensions.length;
    const alternateExtensionsToPotentiallyModify = this.formData.alternateExtensions.slice(0, originalArrayLength);
    const alternateExtensionsToUpdate: VoicemailAlternateExtension[] = [];

    for (let index = 0; index < alternateExtensionsToPotentiallyModify.length; index++) {
      if (this.originalValue.alternateExtensions[index] !== this.formData.alternateExtensions[index]) {
        const alternateExtensionToUpdate = cloneDeep(
          this.alternateExtensions.find((altExt) => altExt.extension === this.originalValue.alternateExtensions[index])
        );
        alternateExtensionToUpdate.extension = this.formData.alternateExtensions[index];
        alternateExtensionsToUpdate.push(alternateExtensionToUpdate);
      }
    }

    return new Observable<void>((subscriber: Subscriber<void>) => {
      if (alternateExtensionsToUpdate.length) {
        zip(
          ...alternateExtensionsToUpdate.map((alternateExtension: VoicemailAlternateExtension) => {
            return this.voicemailAlternateExtensionsResource.put(
              this.unityServerId,
              this.voicemail.id,
              alternateExtension.extension,
              alternateExtension.id
            );
          })
        ).subscribe(() => {
          subscriber.next();
          subscriber.complete();
        });
      } else {
        subscriber.next();
        subscriber.complete();
      }
    });
  };

  private _addAlternateExtensions = (alternateExtensionsToAdd: string[]): Observable<void> => {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      zip(
        ...alternateExtensionsToAdd.map((extension: string) => {
          return this.voicemailAlternateExtensionsResource.post(this.unityServerId, this.voicemail.id, extension);
        })
      ).subscribe({
        next: (addedAlternateExtensions: VoicemailAlternateExtension[]) => {
          addedAlternateExtensions.forEach((addedExtension: VoicemailAlternateExtension) => {
            this.alternateExtensions.push(addedExtension);
          });
          this.originalValue.alternateExtensions = [
            ...this.alternateExtensions.map((newExtension: VoicemailAlternateExtension) => newExtension.extension),
          ];
          this._updateAlternateExtensionsEntitySource();
          subscriber.next();
          subscriber.complete();
        },
        error: (response) => {
          subscriber.error(response);
          subscriber.complete();
        },
      });
    });
  };

  private _deleteAlternateExtensions = (alternateExtensionsToDelete: string[]): Observable<void> => {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      zip(
        ...alternateExtensionsToDelete.map((extension: string) => {
          const extensionToRemove = this.alternateExtensions.find(
            (alternateExtension: VoicemailAlternateExtension) => alternateExtension.extension === extension
          );
          return this.voicemailAlternateExtensionsResource.delete(
            this.unityServerId,
            this.voicemail.id,
            extensionToRemove.id
          );
        })
      ).subscribe(() => {
        this.alternateExtensions = this.alternateExtensions.filter(
          (alternateExtension: VoicemailAlternateExtension) => {
            return !alternateExtensionsToDelete.includes(alternateExtension.extension);
          }
        );
        this._updateAlternateExtensionsEntitySource();
        subscriber.next();
        subscriber.complete();
      });
    });
  };

  private _updateAlternateExtensionsEntitySource = () => {
    if (this.endUser) {
      const filteredAlternateExtensions = this.alternateExtensions
        .map((alternateExtensions: VoicemailAlternateExtension) => alternateExtensions.extension)
        .filter((alternateExt: string) => {
          return alternateExt !== this.endUser.mailId && alternateExt !== this.endUser.primaryExtension.extension;
        });
      this.originalValue.alternateExtensions = [...filteredAlternateExtensions];
    }
  };

  private _callDefaultDirectoryNumberResourceIfNeeded = (): Observable<void> => {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      if (!this.isExistingVoicemail) {
        this.voicemailService
          .setForwardToVoicemail(
            false,
            this.endUser?.username,
            this.modalProperties ? this.modalProperties.dnRef : this.endUser.primaryExtension,
            this.siteId
          )
          .subscribe(() => {
            subscriber.next();
            subscriber.complete();
          });
      } else {
        subscriber.next();
        subscriber.complete();
      }
    });
  };

  private _callDefaultUserResourceIfNeeded = (): Observable<void> => {
    return new Observable<void>((subscription: Subscriber<void>) => {
      if (
        (this.endUser && this.modalProperties?.lineButtonSummary.isPrimaryExtension) ||
        (this.endUser && !this.modalProperties)
      ) {
        this.defaultEndUserResource
          .post({
            username: this.username,
            siteId: this.siteId.toString(),
            hasVoicemail: true,
            hasSnr: true,
            hasExtensionMobility: true,
          })
          .subscribe((defaultEndUser) => {
            this.voicemailService
              .setHomeCluster(this.global360View.endUsers, this.endUser.id, defaultEndUser.ucServiceProfile)
              .subscribe(() => {
                subscription.next();
                subscription.complete();
              });
          });
      } else {
        subscription.next();
        subscription.complete();
      }
    });
  };

  private _updateExistingVoicemail = (): Observable<VoicemailRef> => {
    this.voicemail.alias = this.formData.alias;
    this.voicemail.displayName = this.formData.displayName;
    this.voicemail.firstName = this.formData.firstName;
    this.voicemail.lastName = this.formData.lastName;
    return this.voicemailResource.put(this.unityServerId, this.voicemail.id, this.voicemail);
  };

  private _createNewVoicemail = (): Observable<VoicemailRef> => {
    return this.voicemailResource
      .post(
        this.unityServerId,
        {
          id: null,
          alias: this.formData.alias,
          firstName: this.formData.firstName,
          lastName: this.formData.lastName,
          displayName: this.formData.displayName,
          extension: this.extension,
          template: this.formData.voicemailTemplate,
        },
        this.endUser ? [{ name: 'username', value: this.endUser.username }] : null
      )
      .pipe(
        tap((voicemailRef) => {
          this.voicemailRef = voicemailRef;
          this.voicemailRefUpdated.emit(voicemailRef);
        }),
        delayWhen((voicemailRef) => this._getVoicemailAndSetEntitySource(voicemailRef))
      );
  };

  private _getVoicemailAndSetEntitySource(voicemailRef: VoicemailRef): Observable<Voicemail> {
    return this.voicemailResource.get(voicemailRef.serverId, voicemailRef.id).pipe(
      tap((voicemail) => {
        this.voicemail = { ...voicemail };
        this.voicemailId = voicemail.id;
        if (this.modalProperties) {
          this.modalProperties.lineButtonSummary = {
            ...this.modalProperties.lineButtonSummary,
            voicemail: this.voicemail,
          };
        }
        this.phoneButtonsService.isVoicemailDeleted.next(false);
        this._setEntitySourceFromVoicemail();
      })
    );
  }

  private _wasAnyChangeDoneToVoicemail = (): boolean => {
    return (
      this.originalValue.lastName !== this.formData.lastName ||
      this.originalValue.firstName !== this.formData.firstName ||
      this.originalValue.alias !== this.formData.alias ||
      this.originalValue.displayName !== this.formData.displayName ||
      this.originalValue.smtpNotificationDevice !== this.formData.smtpNotificationDevice ||
      this.originalValue.alternateExtensions !== this.formData.alternateExtensions
    );
  };

  private _setEntitySourceFromVoicemail = () => {
    this.originalValue.lastName = this.voicemail.lastName;
    this.originalValue.firstName = this.voicemail.firstName;
    this.originalValue.alias = this.voicemail.alias;
    this.originalValue.displayName = this.voicemail.displayName;
  };

  private _showSuccessSaveToast() {
    this.toastService.push(
      ToastTypes.SUCCESS,
      this.smacsIcons.VOICEMAIL,
      'tkey;shared.toast.save.success.title',
      'tkey;userdetail.voicemail.save.toast.success.text',
      { alias: this.formData.alias }
    );
  }

  private _onDeleteConfirm(): Observable<boolean> {
    this.isDeleting = true;
    this._setPending(this.isDeleting);
    return new Observable((subscriber) => {
      const serviceParams: VoicemailServiceParams = {
        endUserResult: !!this.global360View
          ? this.global360View.endUsers.find((endUserResult) => endUserResult.ref.id === this.endUser?.id)
          : undefined,
        endUsers: !!this.global360View ? this.global360View.endUsers : undefined,
        unityServerId: this.unityServerId,
        siteId: this.siteId,
        dnRef: !!this.endUser ? this.endUser.primaryExtension : undefined,
        hasSnr: true,
        hasExtensionMobility: true,
      };

      this.voicemailService
        .deprovisionVoicemail(
          this.voicemail.id,
          serviceParams,
          !!this.endUser && this.endUser.primaryExtension?.extension === this.voicemail.extension
        )
        .subscribe({
          next: () => {
            this._onSuccessfulVoicemailDelete();
            subscriber.next(true);
          },
          error: () => {
            this.isDeleting = false;
            this._setPending(this.isDeleting);
            subscriber.next(false);
          },
        });
    });
  }

  private _showSuccessDeleteToast() {
    this.toastService.push(
      ToastTypes.INFO,
      `${this.smacsIcons.DELETE} text-danger`,
      'tkey;search.voicemail.delete.success.text',
      'tkey;userdetail.voicemail.save.toast.success.text',
      { alias: this.formData.alias }
    );
  }

  private _setPending(state: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'cancel',
        state: {
          buttonDisableState: { disabled: state || this.isDeleting, tooltipKey: '' },
        },
      })
    );
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'save',
        state: {
          pending: this.isDeleting ? false : state,
          buttonDisableState: { disabled: state, tooltipKey: '' },
        },
      })
    );
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'delete',
        state: {
          pending: this.isDeleting,
          buttonDisableState: { disabled: this.isDeleting || state, tooltipKey: '' },
        },
      })
    );
  }

  private _isVoicemailExtensionDifferentFromUserPrimaryExtension(): boolean {
    // this is a dumb hack to hide alternate extensions when the voicemail extension doesn't match the primary extension.
    // see #12781
    return (
      this.voicemail &&
      this.endUser?.primaryExtension &&
      this.voicemail.extension !== this.endUser.primaryExtension.extension
    );
  }

  private _onSuccessfulVoicemailDelete() {
    this._showSuccessDeleteToast();
    this.voicemailDeleted.emit(true);
    this.voicemailDeleted.emit(this.voicemail.id);
    this.phoneButtonsService.isVoicemailDeleted.next(true);
    if (this.modalProperties) {
      this.modalProperties.lineButtonSummary = {
        ...this.modalProperties.lineButtonSummary,
        voicemail: null,
      };
    }
    this.phoneButtonsService.lineButtonSummary.next(this.modalProperties?.lineButtonSummary);
    this.smacsFormStateService.setIsFormDirty(false);
  }
}
