import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SmacsUserDetailCardComponent } from './cisco/user-detail-card/user-detail-card.component';
import { SharedModule } from '../shared/shared.module';
import { SmacsDnDetailSummaryInfoComponent } from './cisco/dn-detail-summary-info/dn-detail-summary-info.component';
import { RouterModule } from '@angular/router';
import { OperationsMenuComponent } from './cisco/operations-menu/operations-menu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SmacsFormsModule } from '../forms/smacs-forms.module';
import { UserDetailUiContext } from './shared/contexts/user-detail-ui.context';
import { SmacsCallForwardComponent } from './cisco/call-forward/call-forward.component';
import { PcceAgentSettingsComponent } from './cisco/pcce-agent-settings/pcce-agent-settings.component';
import { PcceAgentSettingsFormComponent } from './cisco/pcce-agent-settings/form/pcce-agent-settings-form.component';
import { VoicemailFormComponent } from './cisco/voicemail/form/voicemail-form.component';
import { VoicemailSearchResource } from '../shared/resources/voicemail-search.resource';
import { Microsoft365InfoLicensesComponent } from './microsoft/microsoft-view-licenses/microsoft365-info-licenses/microsoft365-info-licenses.component';
import { DirectoryNumberCallForwardFormComponent } from './cisco/directory-number/directory-number-call-forward-form/directory-number-call-forward-form.component';
import { DirectoryNumberDidDetailsComponent } from './cisco/directory-number/did-details/directory-number-did-details.component';
import { SmacsDirectoryNumberDetailsFormComponent } from './cisco/directory-number/directory-number-details-form/directory-number-details-form.component';
import { SmacsDirectoryNumberLdapDetailsFormComponent } from './cisco/directory-number/directory-number-ldap-details-form/directory-number-ldap-details-form.component';
import { SmacsDirectoryNumberDnDidSelectorFormComponent } from './cisco/directory-number/dn-did-selector-form/directory-number-dn-did-selector-form.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectoryNumberIlsDetailsFormComponent } from './cisco/directory-number/directory-number-ils-details-form/directory-number-ils-details-form.component';
import { VoicemailComponent } from './cisco/voicemail/voicemail.component';
import { DnDetailService } from './shared/services/dn-detail.service';
import { PcceAgentResource } from './shared/resources/pcce-agent.resource';
import { DialPlanGroupContext } from './shared/contexts/dial-plan-group.context';
import { DirectoryNumberAgentExtensionFormComponent } from './cisco/directory-number/directory-number-agent-extension-form/directory-number-agent-extension-form.component';
import { PhoneButtonsModule } from '../shared/phone-buttons/phone-buttons.module';
import { SmacsDirectoryNumberFormComponent } from './cisco/directory-number/directory-number-form.component';
import { DefaultsResource } from './shared/resources/defaults.resource';
import { LdapUserResource } from './shared/resources/ldap-user.resource';
import { TranslationPatternResource } from './shared/resources/translation-pattern.resource';
import { DefaultEndUserResource } from '../shared/resources/default-end-user.resource';
import { VoicemailResource } from '../shared/resources/voicemail.resource';
import { VoicemailAlternateExtensionsResource } from '../shared/resources/voicemail-alternate-extensions.resource';
import { DefaultDirectoryNumberResource } from '../shared/resources/default-directory-number.resource';
import { DialPlanInventoriesResource } from '../shared/resources/dial-plan-inventories.resource';
import { VoicemailFieldsConfigResource } from '../shared/resources/voicemail-fields-config.resource';
import { SmacsServiceTileComponent } from './shared/service-tile/service-tile.component';
import { SmacsServiceTileAddComponent } from './shared/service-tile/service-tile-add/service-tile-add.component';
import { SmacsServiceTileEditComponent } from './shared/service-tile/service-tile-edit/service-tile-edit.component';
import { SmacsServiceTileDeleteComponent } from './shared/service-tile/service-tile-delete/service-tile-delete.component';
import { SmacsServiceTileEditMultiComponent } from './shared/service-tile/service-tile-edit-multi/service-tile-edit-multi.component';
import { Microsoft365LicensesResource } from './shared/resources/microsoft-365-licenses.resource';
import { PhoneFieldsFormComponent } from './cisco/phone/phone-fields-form/phone-fields-form.component';
import { SmacsUserDetailHomeComponent } from './cisco/user-detail-home/user-detail-home.component';
import { SmacsAssociatedPublicPhonesComponent } from './cisco/user-detail-home/associated-public-phones/associated-public-phones.component';
import { ImPresenceResource } from './shared/resources/im-presence.resource';
import { Microsoft365UserResource } from './shared/resources/microsoft-365-user.resource';
import { SmacsUserDetailHomeServiceTileComponent } from './cisco/user-detail-home/user-detail-home-service-tile/user-detail-home-service-tile.component';
import { PhoneStatusFilter } from './shared/filters/phone-status.filter';
import { PhoneButtonTemplateFormComponent } from './cisco/phone/phone-button-template-form/phone-button-template-form.component';
import { PhoneComponent } from './cisco/phone/phone.component';
import { PrimaryExtensionFieldConfigResource } from './shared/resources/field-config/primary-extension-field-config.resource';
import { PhoneFieldConfigResource } from './shared/resources/field-config/phone-field-config.resource';
import { UserDetailComponent } from './cisco/user-detail/user-detail.component';
import { UserDetailPhoneComponent } from './cisco/user-detail/user-detail-phone/user-detail-phone.component';
import { PhoneFxComponent } from './cisco/phone/phone-fx/phone-fx.component';
import { ButtonModule } from '../button/button.module';
import { SmacsSearchComponent } from './search/search.component';
import { SmacsSearchResultSectionComponent } from './search/search-result-section/search-result-section.component';
import { MsTeamsAudioConferencingFormComponent } from './microsoft/teams-audio-conferencing/form/teams-audio-conferencing-form.component';
import { MicrosoftTeamsAudioConferencingResource } from './shared/resources/microsoft-teams-audio-conferencing.resource';

import { TelephoneNumberFilter } from '../shared/filters/telephone-number.filter';
import { UserDetailVoicemailComponent } from './cisco/user-detail/user-detail-voicemail/user-detail-voicemail.component';
import { VoicemailSmtpNotificationsDeviceResource } from '../shared/resources/voicemail-smtp-notifications-device.resource';
import { Microsoft360DetailCardComponent } from './microsoft/microsoft360-view/microsoft360-detail-card/microsoft360-detail-card.component';
import { Microsoft360DetailHomeComponent } from './microsoft/microsoft360-view/microsoft360-detail-home/microsoft360-detail-home.component';
import { Microsoft360DetailComponent } from './microsoft/microsoft360-view/microsoft360-detail/microsoft360-detail.component';
import { Microsoft360ViewResource } from '../shared/resources/microsoft-360-view.resource';
import { MicrosoftViewLicensesComponent } from './microsoft/microsoft-view-licenses/microsoft-view-licenses.component';
import { TeamsAudioConferencingComponent } from './microsoft/teams-audio-conferencing/teams-audio-conferencing.component';
import { AddUserComponent } from './cisco/add-user/add-user.component';
import { MsTeamsCallingFormComponent } from './microsoft/teams-calling-container/teams-calling/microsoft-teams-calling-dial-plan-selector-form/form/ms-teams-calling-form.component';
import { MicrosoftTeamsCallingComponent } from './microsoft/teams-calling-container/teams-calling/microsoft-teams-calling.component';
import { SearchFieldConfigResource } from './shared/resources/field-config/search-field-config.resource';
import { Microsoft365LicensesContext } from '../shared/contexts/microsoft-365-licenses.context';
import { MicrosoftDialPlansResource } from './shared/resources/ms-dial-plans.resource';
import { SmacsMicrosoftTeamsCallingDialPlanSelectorFormComponent } from './microsoft/teams-calling-container/teams-calling/microsoft-teams-calling-dial-plan-selector-form/microsoft-teams-calling-dial-plan-selector-form.component';
import { ReportingModule } from '../reporting/reporting.module';
import { PcceAgentFieldConfigResource } from './shared/resources/field-config/pcce-agent-field-config.resource';
import { DistributionListComponent } from './cisco/distribution-list/distribution-list.component';
import { AddMemberToDistributionListComponent } from './cisco/distribution-list/add-member-to-distribution-list.component';
import { SmacsDirectoryNumberDetailedModalComponent } from './directory-number-detailed-modal/directory-number-detailed-modal.component';
import { VoicemailDetailedModalComponent } from './voicemail-detailed-modal/voicemail-detailed-modal.component';
import { UccxAgentComponent } from './cisco/uccx-agent/uccx-agent.component';
import { UccxAgentFormComponent } from './cisco/uccx-agent/uccx-agent-form/uccx-agent-form.component';
import { UccxAgentResource } from './shared/resources/uccx-agent.resource';
import { UccxAgentFieldConfigResource } from './shared/resources/field-config/uccx-agent-field-config.resource';
import { Global360ViewContext } from '../shared/contexts/global-360-view.context';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { PublicPhoneComponent } from './cisco/public-phone/public-phone.component';
import { PublicPhoneContactCardComponent } from './cisco/public-phone/public-phone-contact-card/public-phone-contact-card.component';
import { PublicPhoneOperationsMenuComponent } from './cisco/public-phone/public-phone-operations-menu/public-phone-operations-menu.component';
import { PublicPhoneChangeSiteComponent } from './cisco/public-phone/public-phone-change-site/public-phone-change-site.component';
import { PublicPhoneContainerComponent } from './cisco/public-phone/public-phone-container/public-phone-container.component';
import { BandwidthEmergencyCallingFormComponent } from './microsoft/teams-calling-container/teams-calling/microsoft-teams-calling-dial-plan-selector-form/form/bandwidth-e911-dlrs-form/bandwidth-emergency-calling-form.component';
import { MicrosoftLicensesConfigsResource } from './shared/resources/microsoft-licenses-configs.resource';
import { MicrosoftLicensesContext } from '../shared/contexts/microsoft-licenses.context';
import { HelpdeskOptionsContext } from './shared/contexts/helpdesk-options.context';
import { SnrComponent } from './cisco/snr/snr.component';
import { SnrProfileFormComponent } from './cisco/snr/snr-profile-form/snr-profile-form.component';
import { SnrFieldConfigResource } from './shared/resources/field-config/snr-field-config.resource';
import { PhoneButtonEditFormModule } from '../shared/phone-buttons/edit-form/phone-button-edit-form.module';
import { SnrDestinationsFormComponent } from './cisco/snr/snr-destinations-form/snr-destinations-form.component';
import { ExtensionMobilityComponent } from './cisco/extension-mobility/extension-mobility.component';
import { ModelProtocolFieldConfigResource } from './shared/resources/field-config/model-protocol-field-config.resource';
import { UserPinResetComponent } from './cisco/user-pin-reset/user-pin-reset.component';
import { PinResetComponent } from './cisco/pin-reset/pin-reset.component';
import { PublicPhonePinResetComponent } from './cisco/public-phone/public-phone-pin-reset/public-phone-pin-reset.component';
import { SearchResultPinResetComponent } from './search/search-result-pin-reset/search-result-pin-reset.component';
import { PhoneMobilityIdentityComponent } from './cisco/phone/phone-mobility-identity/phone-mobility-identity.component';
import { ChangeSiteComponent } from './cisco/change-site/change-site.component';
import { ChangeSiteFormComponent } from './cisco/change-site/form/change-site-form.component';
import { MakePublicComponent } from './cisco/make-public/make-public.component';
import { WebexCallingComponent } from './cisco/webex-calling/webex-calling.component';
import { WebexCallingFieldConfigResource } from './shared/resources/field-config/webex-calling-field-config.resource';
import { WebexCallingDialPlanInventoriesResource } from './shared/resources/webex-calling-dial-plan-inventories.resource';
import { WebexUserResource } from '../shared/resources/webex-user.resource';
import { ZiroDidSelectorFormComponent } from './cisco/webex-calling/did-selector-form/did-selector-form.component';
import { ZiroExtensionDetailsFormComponent } from './cisco/webex-calling/extension-details-form/extension-details-form.component';
import { CipcProtocolFieldConfigResource } from './shared/resources/field-config/cipc-protocol-field-config.resource';
import { SecurityGroupMembershipsComponent } from './microsoft/security-group-memberships/security-group-memberships.component';
import { MicrosoftSecurityGroupsMembershipContext } from '../shared/contexts/microsoft-security-groups-membership.context';
import { SecurityGroupMembershipsFormComponent } from './microsoft/security-group-memberships/security-group-memberships-form/security-group-memberships-form.component';
import { Microsoft365SecurityGroupsContext } from '../shared/contexts/microsoft-365-security-groups.context';
import { PublicPhoneReassignComponent } from './cisco/public-phone/public-phone-reassign/public-phone-reassign.component';
import { ReassignUserSearchComponent } from './cisco/reassign-user/reassign-user-search/reassign-user-search.component';
import { ReassignUserExecuteComponent } from './cisco/reassign-user/reassign-user-execute/reassign-user-execute.component';
import { SendEmailComponent } from './cisco/send-email/send-email.component';
import { EmailPreviewDetailedModalComponent } from './cisco/send-email/email-preview-detailed-modal/email-preview-detailed-modal.component';
import { EmailService } from './shared/services/email.service';
import { EmailTemplatePreviewsFieldConfigResource } from './shared/resources/field-config/email-template-previews-field-config.resource';
import { DubberComplianceRecordingFormComponent } from './microsoft/dubber-compliance-recording/dubber-compliance-recording-form/dubber-compliance-recording-form.component';
import { DubberComplianceRecordingComponent } from './microsoft/dubber-compliance-recording/dubber-compliance-recording.component';
import { BandwidthEmergencyCallingFieldConfigResource } from './shared/resources/field-config/bandwidth-emergency-calling-field-config.resource';
import { BandwidthE911DlrsResource } from './shared/resources/bandwidth-e911-dlrs.resource';
import { TileConfigContext } from './shared/contexts/tile-config.context';
import { TopNavModule } from '../shared/top-nav/top-nav.module';
import { LoadingBlockModule } from '../shared/loading-block/loading-block.module';
import { BreadcrumbsModule } from '../shared/breadcrumbs/breadcrumbs.module';
import { CiscoSearchResultComponent } from '../shared/cisco-search-result/cisco-search-result.component';
import { PaginationModule } from '../shared/pagination/pagination.module';
import { EntityTableModule } from '../shared/entity-table/entity-table.module';
import { BottomNavModule } from '../shared/bottom-nav/bottom-nav.module';
import { MicrosoftDialPlanInventoriesResource } from './shared/resources/microsoft-dial-plan-inventories.resource';
import { ExtensionSelectorComponent } from './shared/extension-selector/extension-selector.component';
import { CiscoDialPlanFieldConfigResource } from '../shared/resources/cisco-dial-plan-field-config.resource';
import { PhoneServicesFormComponent } from './cisco/phone/phone-services-form/phone-services-form.component';
import { PhoneModelProtocolFormComponent } from './cisco/phone/phone-model-protocol-form/phone-model-protocol-form.component';
import { ReassignUserComponent } from './cisco/reassign-user/reassign-user.component';
import { TeamsCallingContainerComponent } from './microsoft/teams-calling-container/teams-calling-container.component';
import { DelegateManagementComponent } from './microsoft/teams-calling-container/delegate-management/delegate-management.component';
import { MicrosoftTeamsUserSearchResource } from './shared/resources/microsoft-teams-user-search.resource';
import { CallingDelegateCardFormComponent } from './microsoft/teams-calling-container/delegate-management/calling-delegate-card-form/calling-delegate-card-form.component';
import { MicrosoftSearchResultComponent } from './search/microsoft-search-result/microsoft-search-result.component';
import { HelpdeskAdAttributesComponent } from './microsoft/teams-calling-container/teams-calling/microsoft-teams-calling-dial-plan-selector-form/form/helpdesk-ad-attributes/helpdesk-ad-attributes.component';
import { MicrosoftOnPremAdResource } from './shared/resources/microsoft-on-prem-ad.resource';
import { HelpdeskAdAttributesFormComponent } from './microsoft/teams-calling-container/teams-calling/microsoft-teams-calling-dial-plan-selector-form/form/helpdesk-ad-attributes/helpdesk-ad-attributes-form/helpdesk-ad-attributes-form.component';
import { DraasSharedExtensionsResource } from './shared/resources/draas-shared-extensions.resource';
import { NumberLocationValidationResource } from './shared/resources/number-location-validation.resource';
import { TeamsCallQueuesFormComponent } from './microsoft/teams-calling-container/teams-calling/microsoft-teams-calling-dial-plan-selector-form/form/teams-call-queues/teams-call-queues-form.component';
import { MicrosoftPhoneNumberAssignmentsFieldConfigResource } from './shared/resources/microsoft-phone-number-assignments-field-config.resource';
import { MicrosoftVoicePoliciesFieldConfigResource } from './shared/resources/microsoft-voice-policies-field-config.resource';
import { MicrosoftVoicePoliciesResource } from './shared/resources/microsoft-voice-policies.resource';
import { MicrosoftPhoneNumberAssignmentsResource } from './shared/resources/microsoft-phone-number-assignments.resource';
import { MicrosoftTeamsAudioConferencingFieldConfigResource } from './shared/resources/microsoft-teams-audio-conferencing-field-config.resource';
import { AdminModule } from '../admin/admin.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SmacsFormsModule,
    NgSelectModule,
    PhoneButtonsModule,
    ButtonModule,
    ReportingModule,
    AngularSvgIconModule,
    PhoneButtonEditFormModule,
    TopNavModule,
    LoadingBlockModule,
    BreadcrumbsModule,
    PaginationModule,
    EntityTableModule,
    BottomNavModule,
    AdminModule,
    NgxSkeletonLoaderModule,
  ],
  declarations: [
    SmacsUserDetailCardComponent,
    SmacsDnDetailSummaryInfoComponent,
    OperationsMenuComponent,
    ExtensionSelectorComponent,
    SmacsCallForwardComponent,
    PcceAgentSettingsComponent,
    PcceAgentSettingsFormComponent,
    VoicemailFormComponent,
    Microsoft365InfoLicensesComponent,
    DirectoryNumberDidDetailsComponent,
    SmacsDirectoryNumberDetailsFormComponent,
    DirectoryNumberCallForwardFormComponent,
    SmacsDirectoryNumberLdapDetailsFormComponent,
    DirectoryNumberAgentExtensionFormComponent,
    SmacsDirectoryNumberDnDidSelectorFormComponent,
    DirectoryNumberIlsDetailsFormComponent,
    SmacsDirectoryNumberFormComponent,
    VoicemailComponent,
    SmacsServiceTileComponent,
    SmacsServiceTileAddComponent,
    SmacsServiceTileEditComponent,
    SmacsServiceTileDeleteComponent,
    SmacsServiceTileEditMultiComponent,
    PhoneFieldsFormComponent,
    PhoneServicesFormComponent,
    PhoneModelProtocolFormComponent,
    SmacsUserDetailHomeComponent,
    SmacsUserDetailHomeServiceTileComponent,
    SmacsAssociatedPublicPhonesComponent,
    PhoneStatusFilter,
    PhoneButtonTemplateFormComponent,
    PhoneComponent,
    UserDetailComponent,
    UserDetailPhoneComponent,
    PhoneFxComponent,
    SmacsSearchComponent,
    SmacsSearchResultSectionComponent,
    MsTeamsAudioConferencingFormComponent,
    UserDetailVoicemailComponent,
    Microsoft360DetailCardComponent,
    Microsoft360DetailHomeComponent,
    Microsoft360DetailComponent,
    MicrosoftViewLicensesComponent,
    TeamsAudioConferencingComponent,
    AddUserComponent,
    MsTeamsCallingFormComponent,
    MicrosoftTeamsCallingComponent,
    TeamsCallQueuesFormComponent,
    SmacsMicrosoftTeamsCallingDialPlanSelectorFormComponent,
    DistributionListComponent,
    AddMemberToDistributionListComponent,
    SmacsDirectoryNumberDetailedModalComponent,
    VoicemailDetailedModalComponent,
    UccxAgentComponent,
    UccxAgentFormComponent,
    PublicPhoneComponent,
    PublicPhoneContainerComponent,
    PublicPhoneContactCardComponent,
    PublicPhoneOperationsMenuComponent,
    PublicPhoneChangeSiteComponent,
    BandwidthEmergencyCallingFormComponent,
    ExtensionMobilityComponent,
    SnrComponent,
    SnrProfileFormComponent,
    SnrDestinationsFormComponent,
    UserPinResetComponent,
    PinResetComponent,
    PublicPhonePinResetComponent,
    SearchResultPinResetComponent,
    PhoneMobilityIdentityComponent,
    ChangeSiteComponent,
    ChangeSiteFormComponent,
    MakePublicComponent,
    WebexCallingComponent,
    ZiroDidSelectorFormComponent,
    ZiroExtensionDetailsFormComponent,
    SecurityGroupMembershipsComponent,
    SecurityGroupMembershipsFormComponent,
    PublicPhoneReassignComponent,
    ReassignUserSearchComponent,
    ReassignUserExecuteComponent,
    SendEmailComponent,
    EmailPreviewDetailedModalComponent,
    DubberComplianceRecordingComponent,
    DubberComplianceRecordingFormComponent,
    CiscoSearchResultComponent,
    MicrosoftSearchResultComponent,
    ReassignUserComponent,
    TeamsCallingContainerComponent,
    DelegateManagementComponent,
    CallingDelegateCardFormComponent,
    HelpdeskAdAttributesComponent,
    HelpdeskAdAttributesFormComponent,
  ],
  exports: [
    SmacsUserDetailCardComponent,
    SmacsDnDetailSummaryInfoComponent,
    OperationsMenuComponent,
    ExtensionSelectorComponent,
    SmacsCallForwardComponent,
    PcceAgentSettingsComponent,
    PcceAgentSettingsFormComponent,
    VoicemailFormComponent,
    Microsoft365InfoLicensesComponent,
    DirectoryNumberDidDetailsComponent,
    SmacsDirectoryNumberDetailsFormComponent,
    DirectoryNumberCallForwardFormComponent,
    SmacsDirectoryNumberLdapDetailsFormComponent,
    DirectoryNumberAgentExtensionFormComponent,
    SmacsDirectoryNumberDnDidSelectorFormComponent,
    DirectoryNumberIlsDetailsFormComponent,
    SmacsDirectoryNumberFormComponent,
    VoicemailComponent,
    PhoneFieldsFormComponent,
    PhoneServicesFormComponent,
    PhoneModelProtocolFormComponent,
    SmacsServiceTileComponent,
    SmacsServiceTileAddComponent,
    SmacsServiceTileEditComponent,
    SmacsServiceTileDeleteComponent,
    SmacsServiceTileEditMultiComponent,
    SmacsUserDetailHomeComponent,
    UserDetailComponent,
    SmacsUserDetailHomeServiceTileComponent,
    SmacsAssociatedPublicPhonesComponent,
    SmacsSearchComponent,
    SmacsSearchResultSectionComponent,
    MsTeamsAudioConferencingFormComponent,
    PhoneComponent,
    UserDetailVoicemailComponent,
    Microsoft360DetailCardComponent,
    Microsoft360DetailHomeComponent,
    Microsoft360DetailComponent,
    MicrosoftViewLicensesComponent,
    TeamsAudioConferencingComponent,
    AddUserComponent,
    MsTeamsCallingFormComponent,
    MicrosoftTeamsCallingComponent,
    TeamsCallQueuesFormComponent,
    SmacsMicrosoftTeamsCallingDialPlanSelectorFormComponent,
    DistributionListComponent,
    AddMemberToDistributionListComponent,
    SmacsDirectoryNumberDetailedModalComponent,
    UccxAgentComponent,
    UccxAgentFormComponent,
    PublicPhoneComponent,
    PublicPhoneContainerComponent,
    PublicPhoneChangeSiteComponent,
    UserDetailPhoneComponent,
    ExtensionMobilityComponent,
    SnrComponent,
    SnrProfileFormComponent,
    SnrDestinationsFormComponent,
    UserPinResetComponent,
    PublicPhonePinResetComponent,
    SearchResultPinResetComponent,
    ChangeSiteComponent,
    ChangeSiteFormComponent,
    MakePublicComponent,
    ZiroDidSelectorFormComponent,
    ZiroExtensionDetailsFormComponent,
    WebexCallingComponent,
    SecurityGroupMembershipsComponent,
    PublicPhoneReassignComponent,
    ReassignUserSearchComponent,
    ReassignUserExecuteComponent,
    SendEmailComponent,
    EmailPreviewDetailedModalComponent,
    DubberComplianceRecordingComponent,
    DubberComplianceRecordingFormComponent,
    CiscoSearchResultComponent,
    MicrosoftSearchResultComponent,
    ReassignUserComponent,
    TeamsCallingContainerComponent,
    DelegateManagementComponent,
  ],
  providers: [
    UserDetailUiContext,
    DnDetailService,
    PcceAgentResource,
    DialPlanGroupContext,
    MicrosoftLicensesContext,
    VoicemailSearchResource,
    DefaultsResource,
    DefaultEndUserResource,
    DefaultDirectoryNumberResource,
    ModelProtocolFieldConfigResource,
    PrimaryExtensionFieldConfigResource,
    PhoneFieldConfigResource,
    LdapUserResource,
    TranslationPatternResource,
    VoicemailResource,
    VoicemailAlternateExtensionsResource,
    VoicemailSmtpNotificationsDeviceResource,
    DialPlanInventoriesResource,
    CiscoDialPlanFieldConfigResource,
    VoicemailFieldsConfigResource,
    Microsoft365LicensesResource,
    ImPresenceResource,
    Microsoft365UserResource,
    MicrosoftTeamsUserSearchResource,
    MicrosoftTeamsAudioConferencingResource,
    MicrosoftTeamsAudioConferencingFieldConfigResource,
    TelephoneNumberFilter,
    MicrosoftDialPlansResource,
    Microsoft360ViewResource,
    Microsoft365LicensesContext,
    SearchFieldConfigResource,
    SmacsDirectoryNumberDetailedModalComponent,
    PcceAgentFieldConfigResource,
    UccxAgentResource,
    UccxAgentFieldConfigResource,
    SnrFieldConfigResource,
    Global360ViewContext,
    BandwidthEmergencyCallingFieldConfigResource,
    BandwidthE911DlrsResource,
    MicrosoftLicensesConfigsResource,
    HelpdeskOptionsContext,
    WebexCallingFieldConfigResource,
    WebexCallingDialPlanInventoriesResource,
    WebexUserResource,
    CipcProtocolFieldConfigResource,
    MicrosoftSecurityGroupsMembershipContext,
    Microsoft365SecurityGroupsContext,
    EmailService,
    EmailTemplatePreviewsFieldConfigResource,
    TileConfigContext,
    MicrosoftDialPlanInventoriesResource,
    MicrosoftOnPremAdResource,
    DraasSharedExtensionsResource,
    NumberLocationValidationResource,
    MicrosoftPhoneNumberAssignmentsResource,
    MicrosoftPhoneNumberAssignmentsFieldConfigResource,
    MicrosoftVoicePoliciesResource,
    MicrosoftVoicePoliciesFieldConfigResource,
  ],
})
export class HelpdeskModule {}
