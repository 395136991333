import { Component, OnDestroy, OnInit } from '@angular/core';
import { DirectoryNumberResource } from '../resources/directory-number.resource';
import { ToastService } from '../../shared/services/toast.service';
import { ValidatorsService } from '../../shared/validators.service';
import { DnContainer, SelfServe360View, SelfServeUiContext } from '../contexts/self-serve-ui.context';
import { get } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { DirectoryNumber, DirectoryNumberRef, EndUserResult } from '../../shared/models/generated/smacsModels';
import { Observable, Subscription, take } from 'rxjs';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../button/button.component';
import { SmacsFormAbstractDirective } from '../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../forms/smacs-forms-models';
import { TranslateService } from '@ngx-translate/core';
import { skip } from 'rxjs/operators';
import { Global360ViewContext } from '../../shared/contexts/global-360-view.context';

interface SelfServeCallForward {
  callForward: string;
}

@Component({
  selector: 'app-self-serve-call-forward-edit',
  templateUrl: './self-serve-call-forward-edit.component.html',
})
export class SelfServeCallForwardEditComponent
  extends SmacsFormAbstractDirective<SelfServeCallForward>
  implements OnInit, OnDestroy
{
  isLoading = true;
  directoryNumber: DirectoryNumber;
  user: EndUserResult;
  selfServe360View = {} as SelfServe360View;
  directoryNumberRef: DirectoryNumberRef;
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;

  private _subscriptions = new Subscription();

  formConfig = {
    fields: {
      callForward: {
        label: 'tkey;selfserve.user.call_forward.label',
        dataAutomation: 'self-serve-call-forward-input',
        validation: [
          {
            validator: (value: string) => {
              return ValidatorsService.callForwardFormatValidationFn(value)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID;
            },
            message: this.translateService.instant('tkey;validators.global.error.pattern'),
          },
        ],
      },
    },
  } as SmacsFormConfig;

  constructor(
    private directoryNumberResource: DirectoryNumberResource,
    private toastService: ToastService,
    private selfServeUiContext: SelfServeUiContext,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    protected smacsFormStateService: SmacsFormStateService,
    private global360ViewContext: Global360ViewContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initSelfServe();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  getDirectoryNumber = () => {
    const dnSub = this.directoryNumberResource
      .get(this.directoryNumberRef.id, this.directoryNumberRef.serverId.toString())
      .subscribe((data) => {
        this.directoryNumber = data;
        this.entitySource.next({
          callForward: data.forwardAll.destination,
        });
        this.isLoading = false;
      });
    this._subscriptions.add(dnSub);
  };

  private _onFormSubmit(): Observable<void> {
    return new Observable((subscriber) => {
      this.directoryNumber.forwardAll.destination = this.entity.callForward;
      this.directoryNumber.forwardAll.forwardToVoicemail = false;

      this.directoryNumberResource
        .put(this.directoryNumberRef.id, this.directoryNumber, this.directoryNumberRef.serverId.toString())
        .subscribe({
          next: () => {
            this.global360ViewContext.init(this.user.ref.username);
            this.selfServeUiContext.selfServe360View$
              .pipe(skip(1))
              .pipe(take(1))
              .subscribe(() => {
                this.toastService.pushSaveToast(
                  'tkey;selfserve.user.call_forward.label',
                  this.directoryNumber.forwardAll.destination,
                  this.smacsIcons.DESKPHONE
                );
                this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(this.user.ref.username)}`);

                subscriber.next(null);
                subscriber.complete();
              });
          },
          error: () => {
            subscriber.next(null);
            subscriber.complete();
          },
        });
    });
  }

  private _initSelfServe = () => {
    if (!this.selfServeUiContext.selfServe360View) {
      const username = get(this.route, 'snapshot.params.username');
      this.selfServeUiContext.initUserDetails(username);
    }

    const subscription = this.selfServeUiContext.selfServe360View$
      .pipe(take(1))
      .subscribe((selfServe360View: SelfServe360View) => {
        if (selfServe360View) {
          const dnId = get(this.route, 'snapshot.params.dnId');

          this.user = selfServe360View.endUsers[0];
          this.selfServe360View = selfServe360View;
          const dnContainer = selfServe360View.dnContainers.find(
            (dnc: DnContainer) => dnc.directoryNumberRef.id === dnId
          );

          if (!dnContainer) {
            const username = get(this.route, 'snapshot.params.username');
            this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(username)}`);
            return;
          }

          this.directoryNumberRef = dnContainer.directoryNumberRef;
          this.getDirectoryNumber();
        }
      });
    this._subscriptions.add(subscription);
  };

  protected submit(): Observable<void> {
    return this._onFormSubmit();
  }
}
