<div class="admin-servers smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1 pb-2" [hidden]="isLoading">
    <app-breadcrumbs></app-breadcrumbs>
    <div
      class="alert alert-danger"
      *ngIf="getBadServers().length > 0"
      data-automation="server-misconfiguration-error"
      [innerHTML]="
        'tkey;admin.servers.invalid_configuration.label' | translate : { invalidServers: getBadServers().length }
      "
    ></div>

    <app-entity-table [table]="entityTable" [tableRows]="tableRows"></app-entity-table>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
