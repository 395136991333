<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div *ngIf="!isLoading">
  <div class="container-fluid self-serve">
    <div class="row">
      <div class="col-sm-10 self-serve-main-container">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
