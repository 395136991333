import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../../shared/entity-table/entity-table.models';
import { FilterSelectOption } from '../../../../shared/filter/filter-select/filter-select.component';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { MicrosoftDialPlansResource } from '../../../../helpdesk/shared/resources/ms-dial-plans.resource';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { JobState, JobStatus, MicrosoftProvisioningJob } from '../../../../shared/models/generated/smacsModels';
import { ActivatedRoute, Router } from '@angular/router';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { BottomNavService } from '../../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../../shared/services/toast.service';
import {
  MicrosoftZeroTouchJobView,
  ZpmJobsViewAbstractDirective,
} from '../../../shared/jobs-view/zpm-jobs-view-abstract.directive';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { MsZeroTouchProvisioningContext } from '../../../../admin/contexts/ms-zero-touch-provisioning.context';
import { MicrosoftProvisioningStatusesPollingService } from '../../../../shared/services/microsoft/microsoft-provisioning-statuses-polling.service';
import { isEqual } from 'lodash';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';

export interface MsProvisioningJobView extends MicrosoftZeroTouchJobView {
  dialPlanGroupIds: number[];
}

@Component({
  selector: 'ziro-ms-provisioning-jobs-list',
  templateUrl: './ziro-ms-provisioning-jobs-list.component.html',
  providers: [MicrosoftProvisioningStatusesPollingService],
})
export class ZiroMsProvisioningJobsListComponent extends ZpmJobsViewAbstractDirective implements OnInit, OnDestroy {
  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];
  dialPlanGroupNames: string[] = [];
  dialPlanGroupFilterOpts: any[] = [];
  isLoading = true;
  microsoftProvisioningJobs: MicrosoftZeroTouchJobView[];

  private _subscriptions = new Subscription();

  constructor(
    protected msZeroTouchProvisioningContext: MsZeroTouchProvisioningContext,
    protected router: Router,
    protected smacsModalService: SmacsModalService,
    protected translateService: TranslateService,
    protected bottomNavService: BottomNavService,
    protected route: ActivatedRoute,
    protected breadcrumbsService: BreadcrumbsService,
    protected toastService: ToastService,
    protected microsoftDialPlansResource: MicrosoftDialPlansResource,
    private _microsoftProvisioningStatusesPollingService: MicrosoftProvisioningStatusesPollingService
  ) {
    super(
      msZeroTouchProvisioningContext,
      breadcrumbsService,
      bottomNavService,
      toastService,
      route,
      router,
      translateService,
      smacsModalService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;pages.zero_touch.provisioning.title' }]);
    this.getDialPlanGroups();
    this._listenForJobsAndStatuses();
  }

  ngOnDestroy() {
    this._microsoftProvisioningStatusesPollingService.stopPolling();
  }

  private getDialPlanGroups() {
    const dialPlanGroupContextSub = this.microsoftDialPlansResource.getAllDialPlanGroups().subscribe((response) => {
      this.dialPlanGroupFilterOpts = response.map((dialPlanGroup) => {
        this.dialPlanGroupNames.push(dialPlanGroup.name);
        return {
          label: dialPlanGroup.name,
          value: dialPlanGroup.id,
        };
      });
      this.refreshJobs().subscribe();
    });
    this._subscriptions.add(dialPlanGroupContextSub);
  }

  private _jobStatuses: JobStatus[] = [];

  private _listenForJobsAndStatuses() {
    this._microsoftProvisioningStatusesPollingService.startPolling();
    const sub = combineLatest([
      this.latestJobs$,
      this._microsoftProvisioningStatusesPollingService.state$.pipe(
        distinctUntilChanged((prev, curr) => isEqual(prev, curr))
      ),
    ]).subscribe({
      next: ([jobs, statuses]) => {
        this.microsoftProvisioningJobs = jobs.map((job: MicrosoftZeroTouchJobView) => {
          const status = statuses.find((s: JobStatus) => s.jobId === job.id);
          const previousStatus = this._jobStatuses.find((s: JobStatus) => s.jobId === job.id);

          if (previousStatus && previousStatus.jobState === JobState.RUNNING && status.jobState === JobState.IDLE) {
            this._showJobRunSuccessToast(job);
          }

          return {
            ...job,
            pending: status?.jobState === JobState.RUNNING,
          };
        });

        this._jobStatuses = statuses;
        this._createTable();
      },
    });
    this._subscriptions.add(sub);
  }

  private _showJobRunSuccessToast(job: MicrosoftZeroTouchJobView) {
    this.toastService.push(
      ToastTypes.SUCCESS,
      this.smacsIcons.AUTOMATION,
      'tkey;pages.zero_touch.job.completed',
      job.name
    );
  }

  private _createTable() {
    this.table = {
      columns: [
        {
          columnId: 'jobName',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;pages.zero_touch.job_name',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'searchFilter',
          cssColumnSize: 'col-sm-4',
          label: 'tkey;pages.zero_touch.microsoft.provisioning.list.job.graph.filter.header',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'dialPlanGroup',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;pages.zero_touch.microsoft.provisioning.list.job.dial_plan_group.header',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: this.dialPlanGroupFilterOpts,
            filterFn: (rowsToFilter: EntityTableContentRow[], filterValue: FilterSelectOption, columnKey: string) => {
              return rowsToFilter.filter((row) => {
                const dialPlanGroups: string[] = row.content[columnKey];
                return dialPlanGroups.some((group) =>
                  group.toLowerCase().includes(filterValue.label.trim().toLowerCase())
                );
              });
            },
          },
        },
        {
          columnId: 'schedule',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;pages.zero_touch.schedule',
        },
      ],
      cssClearButtonColumnSize: 'col-sm-2',
      hasActions: true,
    } as EntityTable;
    this._generateTableRows();
    this.isLoading = false;
  }

  private _generateTableRows() {
    this.tableRows = this.microsoftProvisioningJobs.map((job: MicrosoftZeroTouchJobView) => {
      let schedule;
      if (job.schedule) {
        if (job.schedule.dailyJobTime) {
          schedule = this.translateService.instant('tkey;pages.zero_touch.schedule.daily', {
            time: job.schedule.dailyJobTime,
          });
        } else if (job.schedule.periodicJobMinutes) {
          schedule = this.translateService.instant('tkey;pages.zero_touch.schedule.periodic', {
            minutes: job.schedule.periodicJobMinutes,
          });
        }
      }
      const dialPlanNames = job.options.teamsCalling
        ? this._mapIdToName(job.options.teamsCalling.dialPlanGroupIds)
        : null;
      return {
        content: {
          jobName: job.name,
          searchFilter: job.searchFilter,
          dialPlanGroup: dialPlanNames,
          schedule: schedule,
        },
        html: {
          searchFilter: `<code> ${job.searchFilter} </code>`,
        },
        cssClass: job.pending ? 'table-warning' : '',
        badges: {
          dialPlanGroup: {
            maxLength: 30,
            values: dialPlanNames,
          },
        },
        actions: [
          {
            buttonStyle: ButtonStyles.DANGER,
            dataAutomation: 'zero-touch-provisioning-result-row-delete',
            icon: SmacsIcons.DELETE,
            tooltip: 'tkey;pages.zero_touch.job.run_now.tooltip',
            tooltipDisabled: !job.pending,
            isDisabled: job.pending,
            onClick: () => this.deleteJob(job),
          },
          {
            buttonStyle: ButtonStyles.INFO,
            dataAutomation: 'zero-touch-provisioning-result-row-run-now',
            icon: SmacsIcons.RUN,
            label: job.pending ? 'tkey;pages.zero_touch.job.run_now.running' : 'tkey;pages.zero_touch.job.run_now',
            tooltip: job.pending ? 'tkey;pages.zero_touch.job.run_now.running' : 'tkey;pages.zero_touch.job.run_now',
            tooltipDisabled: !job.pending,
            isDisabled: job.pending,
            isPending: job.pending,
            iconPending: SmacsIcons.SAVING,
            onClick: () => {
              this.runJob(job);
            },
          },
          {
            buttonStyle: ButtonStyles.PRIMARY,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/edit-job/${job.id}`,
            dataAutomation: 'zero-touch-provisioning-result-row-edit',
            icon: SmacsIcons.NEXT,
            tooltip: 'tkey;pages.zero_touch.job.run_now.tooltip',
            tooltipDisabled: !job.pending,
            isDisabled: job.pending,
            onClick: () => this.editJob(job),
          },
        ],
        actionsColumnSize: 'col-sm-2',
      };
    });
  }

  private _mapIdToName(ids: number[]): string[] {
    const dialPlanNames: string[] = [];
    ids.map((id) => {
      this.dialPlanGroupFilterOpts
        .filter((option) => option.value === id)
        .map((option) => dialPlanNames.push(option.label));
    });
    return dialPlanNames;
  }

  protected getAllJobs = (): Observable<MsProvisioningJobView[]> => {
    return this.msZeroTouchProvisioningContext.getAll().pipe(
      map((provisioningJobs: MicrosoftProvisioningJob[]) => {
        return provisioningJobs.map((job: MicrosoftProvisioningJob): MsProvisioningJobView => {
          return {
            id: job.id,
            name: job.name,
            searchFilter: job.searchFilter,
            schedule: job.schedule,
            options: job.options,
            dialPlanGroupIds: job.options.teamsCalling?.dialPlanGroupIds,
          } as MsProvisioningJobView;
        });
      })
    );
  };

  runJob(job: MicrosoftZeroTouchJobView) {
    if (!job.pending) {
      job.pending = true;
      this._generateTableRows();
      this.msZeroTouchProvisioningContext.runJob(job.id).subscribe();
    }
  }
}
