import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ButtonTemplateOverride,
  PhoneButtonTemplateInventory as PhoneButtonTemplateInventoryItem,
} from '../../shared/models/generated/smacsModels';
import { shareReplay } from 'rxjs/operators';

@Injectable()
export class DefaultPhoneButtonTemplateResource {
  constructor(private http: HttpClient) {}

  getPhoneButtonTemplateInventory(): Observable<PhoneButtonTemplateInventoryItem[]> {
    return this.http
      .get<PhoneButtonTemplateInventoryItem[]>('/services/cisco/reporting/phone-button-template-inventories')
      .pipe(shareReplay());
  }

  getDefaultPhoneButtonTemplateOverrides(): Observable<ButtonTemplateOverride[]> {
    return this.http
      .get<ButtonTemplateOverride[]>('/services/cisco/admin/default-templates-override')
      .pipe(shareReplay());
  }

  saveDefaultPhoneButtonTemplateOverrides(body: any): Observable<ButtonTemplateOverride[]> {
    return this.http
      .put<ButtonTemplateOverride[]>('/services/cisco/admin/default-templates-override', body)
      .pipe(shareReplay());
  }
}
