<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 pb-2 smacs-report-content">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div
          [innerHTML]="
            (isReadOnly
              ? 'tkey;reporting.global_directory.description.read_only'
              : 'tkey;reporting.global_directory.description'
            ) | translate
          "
        ></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div
      *ngIf="isDisplayAlert"
      class="card border-primary text-center mb-3"
      data-automation="global-directory-report-build-alert"
    >
      <div class="card-body strong">
        <i class="{{ smacsIcons.SAVING }} pe-1"></i>
        <span>{{ 'tkey;reporting.global_directory.building' | translate }}</span>
      </div>
    </div>

    <div
      *ngIf="timeStamp && (timeStamp.includes('day') || timeStamp.includes('month') || timeStamp.includes('year'))"
      data-automation="global-directory-report-stale-data-alert"
      class="alert alert-warning"
    >
      <div [innerHTML]="'tkey;reporting.stale_data.alert' | translate : { timeStamp: timeStamp }"></div>
      <button
        data-automation="refresh-global-directory-data"
        [disabled]="isLoading"
        [ngClass]="isLoading ? 'warning text-warning' : ''"
        class="btn btn-link"
        (click)="refreshGlobalDirectoryData()"
      >
        <span *ngIf="!isLoading">
          <i class="icon-refresh"></i>
          {{ 'tkey;reporting.refresh_data.link' | translate }}
        </span>
        <span *ngIf="isLoading">
          <i class="icon-saving"></i>
          {{ 'tkey;reporting.refreshing_data.text' | translate }}
        </span>
      </button>
    </div>

    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <ng-container *ngIf="!isLoading && !isDisplayAlert">
      <smacs-datatable
        [columns]="columns"
        [data]="results"
        [showSelectColumn]="!isReadOnly"
        [mainFilterPlaceholder]="
          ('tkey;reporting.datatable.search_placeholder' | translate) +
          ('tkey;reporting.global_directory.ldap_user_id.label' | translate) +
          ', ' +
          ('tkey;reporting.global_directory.cucm_extension.label' | translate) +
          ', ' +
          ('tkey;reporting.global_directory.ldap_extension.label' | translate) +
          ', ' +
          ('tkey;reporting.global_directory.ldap_did.label' | translate)
        "
        [noResultsAlert]="getNoResultsAlert()"
        [noResultsAlertClass]="getNoResultsAlertClass()"
        (rowSelected)="onRowSelected()"
        [dataAutomation]="tableDataAutomation"
        (isViewStillLoading)="isViewLoading($event)"
      >
        <smacs-datatable-switch-filter
          [label]="'tkey;reporting.global_directory.hide' | translate"
          [matchFn]="showOnlyMisconfiguredFilter"
          [dataAutomation]="'show-only-misconfig-switch-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-switch-filter>
      </smacs-datatable>
    </ng-container>
  </div>
</div>

<app-bottom-nav data-automation="reports-global-directory-bottom-nav"></app-bottom-nav>
