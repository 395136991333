import { Component, Input, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { HtmlInputType, SmacsTextConfig } from '../../../../../forms/fields/text/smacs-text.component';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { of } from 'rxjs';
import { SmacsSelectOption } from '../../../../../forms/fields/select/smacs-select.component';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../../forms/smacs-forms-models';
import {
  ClusterResult,
  DialPlanManagementGroup,
  SiteResult,
  SiteSummary,
} from '../../../../../shared/models/generated/smacsModels';
import { uniq } from 'lodash';
import {
  GroupSettingsLocationFieldData,
  GroupSettingsLocationFieldEntity,
  GroupSettingsLocationOptionType,
  SmacsGroupSettingsLocationConfig,
} from './dial-pan-management-group-settings-location/dial-plan-management-group-settings-location.component';
import { ClusterOption, SiteOption } from '../dial-plan-management-edit.component';

export interface CiscoGroupSettingsFormEntity {
  name: string;
  siteIds: number[];
  clusterId: number;
}

export interface CiscoGroupSettingsFormData {
  name: string;
  location: GroupSettingsLocationFieldEntity;
}

@Component({
  selector: 'app-cisco-dial-plan-management-group-settings-form',
  templateUrl: './dial-plan-management-group-settings-form.component.html',
  providers: [],
})
export class CiscoDialPlanManagementGroupSettingsFormComponent
  extends SmacsFormAbstractDirective<CiscoGroupSettingsFormEntity, CiscoGroupSettingsFormData>
  implements OnInit
{
  @Input() clusterOptions: ClusterOption[] = [];
  @Input() siteOptions: SiteOption[] = [];
  @Input() groupId: string;
  @Input() dialPlanGroups: DialPlanManagementGroup[] = [];
  @Input() siteSummary: SiteSummary;

  formConfig: SmacsFormConfig;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.formConfig = {
      fields: {
        name: {
          label: 'tkey;dialplanmanagement.admin.group.name',
          dataAutomation: 'group-settings-group-name',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: true,
          validation: [
            {
              validator: (val: string) => {
                const filteredGroups = this.groupId
                  ? this.dialPlanGroups.filter((group: DialPlanManagementGroup) => group.id !== this.groupId)
                  : this.dialPlanGroups;
                const hasMatch = filteredGroups.find(
                  (group: DialPlanManagementGroup) => group.name.toLowerCase() === val.trim().toLowerCase()
                );
                return hasMatch ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
              },
              message: 'tkey;dialplanmanagement.admin.group.name.alreadyInUse',
            },
          ],
        },
        location: {
          dataAutomation: 'group-settings-location',
          required: false,
          componentConfig: new SmacsGroupSettingsLocationConfig([
            {
              value: GroupSettingsLocationOptionType.SITES,
              label: 'tkey;dialplanmanagement.admin.group.sites',
              options: [...this.siteOptions],
              isMultiSelect: true,
            },
            {
              value: GroupSettingsLocationOptionType.CLUSTER,
              label: 'tkey;dialplanmanagement.admin.group.clusters.label',
              options: [...this.clusterOptions],
              isMultiSelect: false,
            },
          ]),
          validation: [
            {
              validator: (val: GroupSettingsLocationFieldData) => {
                if (val.option === GroupSettingsLocationOptionType.SITES) {
                  const clusterIds = val.siteIds.map((o: SmacsSelectOption) => {
                    return this.siteSummary.clusters.find((cluster: ClusterResult) => {
                      return cluster.sites.find((site: SiteResult) => site.id === o.value);
                    }).id;
                  });
                  return uniq(clusterIds).length > 1
                    ? SmacsFormsValidationState.INVALID
                    : SmacsFormsValidationState.VALID;
                }

                return SmacsFormsValidationState.VALID;
              },
              message: 'tkey;dialplanmanagement.admin.group.site_cluster.validation.message',
            },
            {
              validator: (val: GroupSettingsLocationFieldData) => {
                return this.isFormSubmitted && !val.siteIds.length && !val.clusterId
                  ? SmacsFormsValidationState.INVALID
                  : SmacsFormsValidationState.VALID;
              },
              message: 'tkey;validators.global.required.error',
            },
          ],
        },
      },
    };
  }

  toFormData = (entity: CiscoGroupSettingsFormEntity): CiscoGroupSettingsFormData => {
    return {
      name: entity.name,
      location: {
        clusterId: entity.clusterId,
        siteIds: entity.siteIds,
      },
    };
  };

  toEntity = (formData: CiscoGroupSettingsFormData): CiscoGroupSettingsFormEntity => {
    return {
      name: formData.name,
      siteIds: formData.location.siteIds,
      clusterId: formData.location.clusterId,
    };
  };

  submit() {
    return of(null);
  }
}
