import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[smacsAutoSelect]',
})
export class AutoSelectDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('focus') onFocus() {
    // Queue the select method asynchronously or it doesn't work
    setTimeout(() => {
      this.elementRef.nativeElement.select();
    });
  }
}
