import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { DistributionListsResource } from '../../../shared/resources/distribution-lists.resource';
import { get, sortBy } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { VoicemailSearchResource } from '../../../shared/resources/voicemail-search.resource';
import {
  DistributionList,
  DistributionListMember,
  GlobalVoicemailResult,
} from '../../../shared/models/generated/smacsModels';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { Observable, Subscription } from 'rxjs';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig } from '../../../forms/smacs-forms-models';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../shared/entity-table/entity-table.models';

interface SearchUserResult extends GlobalVoicemailResult {
  userExists: boolean;
}

interface DistributionListFormEntity {
  search: string;
}

@Component({
  selector: 'smacs-add-member-to-distribution-list',
  templateUrl: './add-member-to-distribution-list.component.html',
})
export class AddMemberToDistributionListComponent
  extends SmacsFormAbstractDirective<DistributionListFormEntity>
  implements OnInit, OnDestroy
{
  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  globalVoiceSearchResults: SearchUserResult[] = [];
  filteredGlobalVoiceSearchResults: SearchUserResult[] = [];
  userToAdd: SearchUserResult;
  distributionList: DistributionList;
  confirmAdd = false;
  isAddingUser = false;
  formConfig = {
    fields: {
      search: {
        dataAutomation: 'search-user-to-add-to-distribution-list',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'tkey;pages.distributionlist.add.search.title',
        }),
        required: true,
      },
    },
  } as SmacsFormConfig;

  private _serverId: string;
  private _distributionListId: number;
  private _voicemailId: string;
  private _request: { id: string };
  private _subscription = new Subscription();
  private _listMembers: DistributionListMember[];

  private static _filterByDisplayName(listMember: GlobalVoicemailResult, displayName: string): boolean {
    const filterDisplayName = displayName;
    return filterDisplayName ? listMember.displayName.toLowerCase().includes(filterDisplayName.toLowerCase()) : true;
  }

  private static _filterByAlias(listMember: GlobalVoicemailResult, alias: string): boolean {
    const filterAlias = alias;
    return filterAlias ? listMember.alias.toLowerCase().includes(filterAlias.toLowerCase()) : true;
  }

  private static _filterByExtension(listMember: GlobalVoicemailResult, extension: string): boolean {
    const filterExtension = extension;
    return filterExtension ? listMember.extension.toLowerCase().includes(filterExtension.toLowerCase()) : true;
  }

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private breadcrumbsService: BreadcrumbsService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private distributionListsResource: DistributionListsResource,
    private voicemailSearchResource: VoicemailSearchResource,
    private router: Router
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._serverId = get(this.route, 'snapshot.params.serverid');
    this._distributionListId = get(this.route, 'snapshot.params.distributionlistid');
    this._getDistributionListInfo();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  backToDistributionList() {
    const url = `server/${this._serverId}/distribution-list/${this._distributionListId}`;
    this.router.navigateByUrl(url);
  }

  onEnterSubmit() {
    this._validateAndSubmitSource.next(true);
  }

  private _getDistributionListInfo() {
    this.distributionListsResource
      .getDistributionList(this._serverId, this._distributionListId)
      .subscribe((distributionList) => {
        this.distributionList = distributionList;
        this._listMembers = distributionList.members;
        this._setBreadcrumbs();
      });
  }

  private _userExistsInList(searchUserResults: GlobalVoicemailResult[]) {
    this.globalVoiceSearchResults = searchUserResults.map((result: GlobalVoicemailResult) => {
      const userExists = this._listMembers.find(
        (member: DistributionListMember) => result.alias === member.alias && result.displayName === member.displayName
      );
      return { ...result, userExists: !!userExists };
    });
  }

  goToConfirmAddPage(value: boolean, member?: SearchUserResult) {
    this.confirmAdd = value;
    if (value && !member.userExists) {
      this.userToAdd = member;
      this._voicemailId = member.id;
      this._request = {
        id: member.id,
      };
    }
  }

  addUserToList() {
    this.isAddingUser = true;
    this.distributionListsResource
      .addMemberToDistributionList(this._serverId, this._distributionListId, this._request)
      .subscribe(() => {
        this.toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.DISTRIBUTION_LIST,
          'tkey;shared.toast.save.success.title',
          'tkey;pages.distributionlist.add.member.success',
          { member: this.userToAdd.displayName }
        );
        this.isAddingUser = false;
        this.backToDistributionList();
      });
  }

  protected submit() {
    this.globalVoiceSearchResults = [];
    this.filteredGlobalVoiceSearchResults = [];
    return new Observable((subscriber) => {
      this.voicemailSearchResource
        .searchGloballyByQ(this.formData.search, Number(this._serverId))
        .subscribe((globalVoiceMailResult) => {
          if (globalVoiceMailResult.length === 0) {
            this.globalVoiceSearchResults = [];
            this.filteredGlobalVoiceSearchResults = [];
          } else {
            this._userExistsInList(globalVoiceMailResult);
            this._filterResults();
            this._createTable();
            this._generateTableRows();
          }
          subscriber.next();
          subscriber.complete();
        });
    });
  }

  private _setBreadcrumbs() {
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;search.distribution.lists.section.header',
        url: `/app2/#/server/${this._serverId}/distribution-list/${this._distributionListId}`,
      },
      { label: this.distributionList.displayName },
    ]);
  }

  private _filterResults() {
    const filteredResult = this.globalVoiceSearchResults.filter((members) => {
      return (
        AddMemberToDistributionListComponent._filterByDisplayName(members, '') &&
        AddMemberToDistributionListComponent._filterByAlias(members, '') &&
        AddMemberToDistributionListComponent._filterByExtension(members, '')
      );
    });
    this.filteredGlobalVoiceSearchResults = sortBy(filteredResult, (data) => {
      return [data.displayName, data.extension];
    });
  }

  private _createTable(): void {
    this.table = {
      columns: [
        {
          columnId: 'displayName',
          cssColumnSize: 'col-sm-4',
          label: 'tkey;pages.distributionlist.display_name',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'alias',
          cssColumnSize: 'col-sm-4',
          label: 'tkey;pages.distributionlist.alias',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'extension',
          cssColumnSize: 'col-sm-3',
          label: 'tkey;pages.distributionlist.extension',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
      ],
      hasActions: true,
    };
  }

  private _generateTableRows(): void {
    this.tableRows = this.filteredGlobalVoiceSearchResults.map((searchUserResult) => {
      return {
        content: {
          displayName: searchUserResult.displayName,
          alias: searchUserResult.alias,
          extension: searchUserResult.extension,
        },
        actions: [
          {
            buttonStyle: ButtonStyles.PRIMARY,
            dataAutomation: 'add-member-to-distribution-list',
            icon: SmacsIcons.NEXT,
            onClick: () => this.goToConfirmAddPage(true, searchUserResult),
            isDisabled: searchUserResult.userExists,
            tooltip: 'tkey;pages.distributionlist.members.tooltip',
            tooltipDisabled: !searchUserResult.userExists,
          },
        ],
      };
    });
  }
}
