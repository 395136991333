import { Component, OnDestroy, OnInit } from '@angular/core';
import { SystemStatusContext } from '../shared/contexts/system-status.context';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { CurrentDateContext } from '../shared/contexts/current-date.context';
import { LoginRequest, Role, State, StatusCategory, SystemHealthStatus } from '../shared/models/generated/smacsModels';
import { AuthenticationContext } from '../shared/contexts/authentication.context';
import { SmacsIcons } from '../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../button/button.component';
import { SmacsFormAbstractDirective } from '../forms/smacs-form-abstract.directive';
import { HtmlInputType, SmacsTextConfig } from '../forms/fields/text/smacs-text.component';
import { SmacsFormConfig } from '../forms/smacs-forms-models';
import { SiteSummaryContext } from '../shared/contexts/site-summary.context';
import { catchError, finalize, map } from 'rxjs/operators';
import { SmacsFormStateService } from '../forms/smacs-form-state.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { GlobalPropertiesContext } from '../shared/contexts/global-properties.context';
import { CustomUiOptionsContext } from '../shared/contexts/custom-ui-options.context';
import { HttpErrorResponse } from '@angular/common/http';

interface LoginErrorAlert {
  header: string;
  message: string | { content: string; params: { [key: string]: string } };
}

interface LoginInfoAlert {
  branch: string;
  environment: string;
}

enum LoginURLs {
  ZPC_DEV = 'zpc-develop.labs.goziro.com',
  ZPC_RC = 'zpc-rc.labs.goziro.com',
  ZPC_MASTER = 'zpc-master.labs.goziro.com',
  ZPM_DEV = 'zpm-develop.app.goziro.com',
  ZPM_RC = 'zpm-rc.app.goziro.com',
  ZPM_MASTER = 'zpm-master.app.goziro.com',
}

enum SsoAuthFailureTypes {
  MICROSOFT_NO_SERVICE_ACCOUNTS_CONFIGURED = 'MICROSOFT_NO_SERVICE_ACCOUNTS_CONFIGURED',
  MICROSOFT_USER_HAS_NO_PERMISSIONS = 'MICROSOFT_USER_HAS_NO_PERMISSIONS',
  UNHANDLED_FAILURE = 'UNHANDLED_FAILURE',
}

const errorMessages = [
  {
    serverError: 'Authentication failed',
    errorMessage: {
      header: 'tkey;global.login.fail.header',
      message: 'tkey;global.login.fail.message',
    },
  },
  {
    serverError: 'No sites or MS Graph have been configured',
    errorMessage: {
      header: 'tkey;pages.login.site.denied.header',
      message: 'tkey;pages.login.incomplete_config.text',
    },
  },
  {
    serverError: 'Site Based Helpdesk user does not have access to any sites',
    errorMessage: {
      header: 'tkey;pages.login.site.denied.header',
      message: 'tkey;pages.login.site.denied.message',
    },
  },
  {
    serverError: 'Self-serve user has no phone services',
    errorMessage: {
      header: 'tkey;pages.login.site.denied.header',
      message: 'tkey;pages.login.no_phone_services.text',
    },
  },
  {
    serverError: 'Site Based Helpdesk user is disabled',
    errorMessage: {
      header: 'tkey;pages.login.site.denied.header',
      message: 'tkey;pages.login.site.disabled_user.message',
    },
  },
];
const inactiveSideError = 'This side is inactive';

const systemStatusErrorMessage = {
  header: 'tkey;pages.login.error.header',
  message: 'tkey;pages.login.error.message',
} as LoginErrorAlert;
const systemStatusWarningMessage = {
  header: 'tkey;pages.login.warning.header',
  message: 'tkey;pages.login.warning.message',
} as LoginErrorAlert;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent extends SmacsFormAbstractDirective<LoginRequest> implements OnInit, OnDestroy {
  window = window;
  isLoading = true;
  year: number;
  loginLogo: string | SafeResourceUrl;
  loginStyles: string;
  isSsoEnabled: boolean;
  isSsoBypassed = false;
  systemHealthStatus: SystemHealthStatus;
  attemptedLogin = false;
  errorAlert: LoginErrorAlert;
  infoAlert: LoginInfoAlert;
  infoAlertLogo: string;
  outdatedBrowserError = false;
  isLoggingIn = false;
  smacsIcons = SmacsIcons;
  buttonSizes = ButtonSizes;
  buttonStyles = ButtonStyles;
  formConfig = {
    fields: {
      username: {
        label: 'tkey;pages.login.username.placeholder',
        placeholder: '',
        dataAutomation: 'login-username',
        required: true,
      },
      password: {
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
        label: 'tkey;pages.login.password.placeholder',
        placeholder: '',
        dataAutomation: 'login-password',
        required: true,
      },
    },
  } as SmacsFormConfig;

  private _isHostedEnabled: boolean;
  private _subs = new Subscription();
  private defaultBackgroundColor = '#202020';

  private static _hasAsyncAwaitSupport(): boolean {
    try {
      // eslint:disable-next-line:no-eval
      eval('async () => {}');
    } catch (e) {
      return true;
    }
    return false;
  }

  constructor(
    private systemStatusContext: SystemStatusContext,
    private router: Router,
    private currentDateContext: CurrentDateContext,
    private authenticationContext: AuthenticationContext,
    private siteSummaryContext: SiteSummaryContext,
    private domSanitizer: DomSanitizer,
    private _activatedRoute: ActivatedRoute,
    private globalPropertiesContext: GlobalPropertiesContext,
    private customUiOptionsContext: CustomUiOptionsContext,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    const globalProperties$ = this.globalPropertiesContext.state$.pipe(
      map((globalProperties) => {
        this._isHostedEnabled = globalProperties.hostedEnabled;
        this.errorAlert = this.getSsoErrorMessage();
        this.infoAlertLogo = this._isHostedEnabled ? 'icon-microsoft-logo' : 'icon-cisco-logo';
      })
    );

    this.infoAlert = this.getLoginInfoMessage();

    const customUiOptions$ = this.customUiOptionsContext.state$.pipe(
      map((uiOptions) => {
        this.loginLogo = uiOptions.customLogo
          ? this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64,${uiOptions.customLogo}`)
          : '/static/img/ziro-login-logo-sm.png';
        this.loginStyles = `background-color: ${uiOptions.customBackgroundColor || this.defaultBackgroundColor};`;
        this.loginStyles += uiOptions.customBackground
          ? `background-image: url("data:image/png;base64,${uiOptions.customBackground}");`
          : '';
        this.isSsoEnabled = uiOptions.ssoEnabled;
      })
    );

    const systemStatus$ = this.systemStatusContext.state$.pipe(
      map((systemStatus) => {
        this.systemHealthStatus = systemStatus;
        this._setErrorMessage();
      })
    );

    const currentDate$ = this.currentDateContext.state$.pipe(
      map((currentDate) => {
        this.year = currentDate.year;
      })
    );

    const subscription = combineLatest([customUiOptions$, systemStatus$, currentDate$, globalProperties$]).subscribe(
      () => {
        this.isLoading = false;
      }
    );
    this._subs.add(subscription);

    this.outdatedBrowserError = LoginComponent._hasAsyncAwaitSupport();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subs.unsubscribe();
  }

  onEnterSubmit() {
    this._validateAndSubmitSource.next(true);
  }

  showSsoLogin(): boolean {
    return !this.isDarkSide() && !this.isSsoBypassed && this.isSsoEnabled;
  }

  isDarkSide(): boolean {
    const trafficStatusState = this.systemHealthStatus?.healthStatuses.find(
      (status) => status.category === StatusCategory.TRAFFIC
    )?.cause;
    if (!!trafficStatusState) {
      return trafficStatusState === inactiveSideError;
    } else {
      return false;
    }
  }

  systemStatusError(): boolean {
    return this.systemHealthStatus?.globalState === State.ERROR;
  }

  showSmacsLogin(): void {
    this.isSsoBypassed = true;
  }

  handleSsoLogin(): void {
    window.location.href = '/saml2/authenticate/relying-party';
  }

  getLoginInfoMessage(): LoginInfoAlert {
    const baseUrl = window.location.hostname;

    switch (baseUrl.toLowerCase()) {
      case LoginURLs.ZPC_DEV: {
        return {
          branch: 'tkey;pages.login.info_message.branch_develop.text',
          environment: 'tkey;pages.login.info_message.environment_cisco.text',
        };
      }
      case LoginURLs.ZPC_RC: {
        return {
          branch: 'tkey;pages.login.info_message.branch_rc.text',
          environment: 'tkey;pages.login.info_message.environment_cisco.text',
        };
      }
      case LoginURLs.ZPC_MASTER: {
        return {
          branch: 'tkey;pages.login.info_message.branch_master.text',
          environment: 'tkey;pages.login.info_message.environment_cisco.text',
        };
      }
      case LoginURLs.ZPM_DEV: {
        return {
          branch: 'tkey;pages.login.info_message.branch_develop.text',
          environment: 'tkey;pages.login.info_message.environment_microsoft.text',
        };
      }
      case LoginURLs.ZPM_RC: {
        return {
          branch: 'tkey;pages.login.info_message.branch_rc.text',
          environment: 'tkey;pages.login.info_message.environment_microsoft.text',
        };
      }
      case LoginURLs.ZPM_MASTER: {
        return {
          branch: 'tkey;pages.login.info_message.branch_master.text',
          environment: 'tkey;pages.login.info_message.environment_microsoft.text',
        };
      }
    }
  }

  /**
   * When SSO auth fails we will be redirected to the login page with a query param. Display error message for param
   */
  getSsoErrorMessage(): LoginErrorAlert {
    const errorQueryParam = decodeURI(this._activatedRoute.snapshot.queryParamMap.get('error')) as SsoAuthFailureTypes;
    const userIdentifierQueryParam = this._activatedRoute.snapshot.queryParamMap.get('userIdentifier');

    switch (errorQueryParam) {
      case SsoAuthFailureTypes.MICROSOFT_NO_SERVICE_ACCOUNTS_CONFIGURED: {
        return {
          header: 'tkey;pages.login.error.title.ms_no_service_accounts',
          message: 'tkey;pages.login.error.message.ms_no_service_accounts',
        };
      }
      case SsoAuthFailureTypes.MICROSOFT_USER_HAS_NO_PERMISSIONS: {
        return {
          header: 'tkey;pages.login.error.title.ms_no_permissions',
          message: {
            content: 'tkey;pages.login.error.message.ms_no_permissions',
            params: {
              user: userIdentifierQueryParam,
              platform: this._isHostedEnabled ? 'ZPM' : 'ZPC',
            },
          },
        };
      }
      case SsoAuthFailureTypes.UNHANDLED_FAILURE: {
        return {
          header: 'tkey;pages.login.error.title.unhandled_failure',
          message: 'tkey;pages.login.error.message.unhandled_failure',
        };
      }
      default: {
        return null;
      }
    }
  }

  protected submit() {
    return this._handleLogin();
  }

  private _handleLogin(): Observable<void> {
    this.isLoggingIn = true;
    this.attemptedLogin = false;
    this.errorAlert = null;

    return this.authenticationContext
      .login({
        username: this.formData.username,
        password: this.formData.password,
      })
      .pipe(
        map((response: string) => {
          this._handleLoginSuccess(response);
        }),
        catchError((response) => {
          return this._handleLoginError(response).pipe(
            finalize(() => {
              this.isLoggingIn = false;
              this._validateAndSubmitSource.next(false);
            })
          );
        })
      );
  }

  private _handleLoginSuccess(resp: string): void {
    this.smacsFormStateService.setIsFormDirty(false);
    const systemStatusErrorResp = 'System status error detected';
    const notConfiguredErrorResp = 'No sites or MS Graph have been configured';
    const adminRestrictedResponses = [systemStatusErrorResp, notConfiguredErrorResp];

    if (adminRestrictedResponses.includes(resp)) {
      console.info(`Status ${resp}, redirecting to admin page`);
      if (resp === systemStatusErrorResp) {
        this.router.navigateByUrl(`admin/system-health-status`);
      } else if (resp === notConfiguredErrorResp) {
        if (this._isHostedEnabled) {
          this.router.navigateByUrl(`admin/microsoft/graph-management`);
        } else {
          window.location.href = window.location.origin + '/app2/#/admin/site-management';
        }
      }
    } else {
      if (this._isHostedEnabled) {
        this._redirectUserHome();
      } else {
        this.siteSummaryContext.refreshSiteSummary().subscribe(() => {
          this._redirectUserHome();
        });
      }
    }
  }

  private _redirectUserHome(): void {
    const currentUser = this.authenticationContext.getCurrentUser();

    if (this.authenticationContext.userIsAtLeast(currentUser, Role.S8_SITE_BASED_HELPDESK)) {
      // Redirect to SMACS home
      if (this.systemHealthStatus.globalState === State.ERROR) {
        this.router.navigateByUrl(`system-health-status`);
      } else {
        this.router.navigateByUrl(`/`);
      }
    } else if (currentUser.role === Role.S8_SELF_SERVE_USER) {
      this.router.navigateByUrl(`self-serve/user/${this.formData.username}`);
    } else {
      window.location.reload();
    }
  }

  private _handleLoginError(httpErrorResponse: HttpErrorResponse): Observable<void> {
    const errorMessage = errorMessages.find((message) => message.serverError === httpErrorResponse.error.description);
    this.attemptedLogin = true;
    if (errorMessage) {
      this.errorAlert = errorMessage.errorMessage;
    } else if (this.systemStatusError()) {
      this._setErrorMessage();
    } else {
      // if there was no health status error before, check again to see if one occurred since the last time we polled.
      return this.systemStatusContext.getSystemStatus().pipe(
        map((healthStatus) => {
          if (healthStatus.globalState !== State.ERROR) {
            // if there is still no health status error, then this is some unhandled error.
            throw httpErrorResponse;
          }
        })
      );
    }
    return of(null);
  }

  private _setErrorMessage(): void {
    if (this.getSsoErrorMessage()) {
      return;
    }

    if (this.systemHealthStatus.globalState === State.ERROR) {
      this.errorAlert = systemStatusErrorMessage;
    } else if (this.systemHealthStatus.globalState === State.WARNING) {
      this.errorAlert = systemStatusWarningMessage;
    } else if (this.errorAlert === systemStatusErrorMessage || this.errorAlert === systemStatusWarningMessage) {
      // reset to clean state
      this.errorAlert = null;
    }
  }
}
