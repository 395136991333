import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { SmacsFieldComponentConfig } from '../../../forms/smacs-forms-models';
import { SmacsFieldAbstractDirective } from '../../../forms/smacs-field-abstract.directive';
import { cloneDeep, isNil } from 'lodash';
import { concat, forkJoin, Observable, of, Subject, Subscription, timer } from 'rxjs';
import { ButtonStyles } from '../../../button/button.component';
import { TranslateService } from '@ngx-translate/core';
import { debounce, first, map, switchMap, tap } from 'rxjs/operators';
import { SmacsSelectOption, SmacsSelectValue } from '../../../forms/fields/select/smacs-select.component';
import { CustomMultiSelect } from '../../models/generated/smacsModels';
import { SmacsIcons } from '../../models/smacs-icons.enum';

export type ZiroAsyncOptionsFn = (string: string) => Observable<SmacsSelectOption[]>;

export class ZiroAsyncMultiSelectConfig extends SmacsFieldComponentConfig {
  constructor(
    public config?: {
      options?: string[];
      asyncOptionsFn?: ZiroAsyncOptionsFn;
      placeholder?: string;
      triggerLoading?: () => boolean;
      clearWithInput?: boolean;
      minSearchLength?: number;
      showAutoGenerationLink?: boolean;
      hideSelected?: boolean;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'ziro-async-multi-select-config',
  templateUrl: './ziro-async-multi-select-config.component.html',
  styleUrls: ['ziro-async-multi-select-config.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: ZiroAsyncMultiSelectConfigComponent }],
})
export class ZiroAsyncMultiSelectConfigComponent
  extends SmacsFieldAbstractDirective<CustomMultiSelect, CustomMultiSelect, ZiroAsyncMultiSelectConfig>
  implements OnInit, OnDestroy
{
  isLoading: boolean;
  options: string[] | SmacsSelectOption[] = [];
  asyncOptionsFn: ZiroAsyncOptionsFn;
  options$: Observable<string[] | SmacsSelectOption[]>;
  optionInputSource = new Subject<string>();
  placeholder = '';
  clearWithInput = false;
  searchedTerm = '';
  hideDropdownArrow = true;
  hideClear = false;
  hideSelected = false;
  minSearchLength = 2;
  buttonStyles = ButtonStyles;
  showAutoGenerationLink = false;
  selected: boolean;
  isFirstLoad = true;
  isShowChecked = false;
  showLabel = 'tkey;site_management.site.section.show.label';
  smacsIcons = SmacsIcons;

  private _subscriptions = new Subscription();

  constructor(protected smacsFormStateService: SmacsFormStateService, private translateService: TranslateService) {
    super(smacsFormStateService);
  }

  triggerLoading = () => false;

  applyComponentConfig = ({ config }: ZiroAsyncMultiSelectConfig) => {
    this.options = config.options;
    this.placeholder = config?.placeholder;
    this.asyncOptionsFn = config?.asyncOptionsFn;
    this.isLoading = false;
    this.options$ = this.getOptionsAsync();
    this.triggerLoading = config?.triggerLoading || this.triggerLoading;
    this.clearWithInput = config?.clearWithInput || this.clearWithInput;
    this.minSearchLength = isNil(config?.minSearchLength) ? this.minSearchLength : config.minSearchLength;
    this.showAutoGenerationLink = Boolean(config?.showAutoGenerationLink);
    this.hideSelected = isNil(config?.hideSelected) ? this.hideSelected : config.hideSelected;
  };

  ngOnInit() {
    this.selfUpdate$.subscribe((update) => {
      this.isShowChecked = update.show;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  onClear = () => {
    this.updateSelf({ ...this.entity, defaultOptions: [] });
    this.options = [];
  };

  onShowChange(newValue: boolean) {
    this.updateSelf({
      ...this.entity,
      show: newValue,
    });
  }

  getOptionsAsync = (): Observable<string[] | SmacsSelectOption[]> => {
    return concat(
      this.optionInputSource.pipe(
        debounce(() => timer(500)),
        switchMap((searchTerm: string) => {
          if (!searchTerm) {
            return of([]);
          } else {
            this.isLoading = true;
            this.hideDropdownArrow = false;
            return this.asyncOptionsFn(searchTerm).pipe(
              first(),
              switchMap((results) => {
                return of(results);
              })
            );
          }
        }),
        tap(() => (this.isLoading = false))
      )
    );
  };

  handleSearchInput($event: { term: string; items: any[] }) {
    this.searchedTerm = $event.term;

    this.isLoading = this.searchedTerm.length >= this.minSearchLength;
    if (!this.searchedTerm) {
      // need this to clear the options because when this.searchedTerm.length >= this.minSearchLength,
      // typeahead doesn't get triggered so the stale options remain.
      this.optionInputSource.next('');
    }
  }

  handleBlur() {
    if (!this.searchedTerm.length) {
      this.optionInputSource.next('');
    }
    this.hideDropdownArrow = true;
  }

  onChange($event: SmacsSelectValue) {
    this.hideDropdownArrow = true;
    const newValues: string[] = ($event as SmacsSelectOption[]).map((option) => option.value);
    this.updateSelf({ ...this.entity, defaultOptions: newValues });
  }

  toFieldData = (entity: CustomMultiSelect): CustomMultiSelect => {
    return cloneDeep(entity);
  };

  toEntity = (fieldData: CustomMultiSelect): CustomMultiSelect => {
    return cloneDeep(fieldData);
  };

  handleSelectClose() {
    this.smacsFormStateService.setIsFormDirty(true);
    this.selected = false;
  }

  handleChange() {
    this.selected = true;
  }

  getDisabledState(): boolean {
    if (this.config.hasOwnProperty('disabled')) {
      if (typeof this.config.disabled === 'boolean') {
        return this.config.disabled;
      } else if (typeof this.config.disabled === 'function') {
        return this.config.disabled();
      }
    }

    return this.state.disabled;
  }

  autogenerateValue() {
    if (this.config && this.config.autogeneration && this.config.autogeneration.generateValue) {
      const newVal: CustomMultiSelect = this.evaluateWithForm(
        this.config.autogeneration.generateValue,
        this.fieldData,
        this.config.autogeneration.injectValuesFromFields
      );

      this.updateSelf(newVal);
      this.options = newVal.defaultOptions;
      this._onReceiveNewEntity(newVal);
      this.isAutogenerated = true;
    }
  }

  protected _onReceiveNewEntity(newEntity: CustomMultiSelect) {
    super._onReceiveNewEntity(newEntity);
    if (newEntity.defaultOptions.length && this.isFirstLoad) {
      this.isFirstLoad = false;
      this._addInitialEntityToOptions(newEntity);
    }
  }

  private _addInitialEntityToOptions(entity: CustomMultiSelect) {
    const usersToSearch = entity.defaultOptions.map((user) => {
      return this.asyncOptionsFn(user).pipe(
        debounce(() => timer(500)),
        map((userSelectOption) => {
          return userSelectOption.length ? userSelectOption[0] : { label: user, value: user };
        })
      );
    });
    const userSearchSub = forkJoin(usersToSearch).subscribe((options) => {
      this.options = [...options];
    });
    this._subscriptions.add(userSearchSub);
  }
}
