import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { CurrentUser, Role } from '../models/generated/smacsModels';
import { AuthenticationContext } from '../contexts/authentication.context';
import { take } from 'rxjs/operators';
import { Observable, Subscriber } from 'rxjs';

@Injectable()
export class LoginGuardService {
  constructor(private authenticationContext: AuthenticationContext, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      return this.authenticationContext.state$.pipe(take(1)).subscribe((currentUser: CurrentUser) => {
        this._redirectToLogin(currentUser, state).subscribe((resolveGuard: boolean) => {
          subscriber.next(resolveGuard);
          subscriber.complete();
        });
      });
    });
  }

  private _redirectToLogin = (user: CurrentUser, state: RouterStateSnapshot): Observable<boolean> => {
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      const authenticated = !(!user || !user.role);

      if (!authenticated && state.url.includes('/login')) {
        subscriber.next(true);
        subscriber.complete();
      } else {
        this._redirectUserHome(user);
        subscriber.next(false);
        subscriber.complete();
      }
    });
  };

  private _redirectUserHome = (user: CurrentUser) => {
    switch (user.role) {
      case Role.S8_SELF_SERVE_USER:
        this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(user.userId)}`);
        break;
      case Role.S8_SITE_BASED_HELPDESK:
      case Role.S8_HELPDESK:
      case Role.S8_GLOBAL_HELPDESK:
      case Role.S8_ADMIN:
      case Role.ZIRO_SUPPORT:
        this.router.navigateByUrl('home');
        break;
    }
  };
}

export const loginRouteGuardFn: CanActivateFn = (route, state) => inject(LoginGuardService).canActivate(route, state);
