import { Injectable } from '@angular/core';
// import { TranslateService } from '@ngx-translate/core';
// import { SmacsModalService } from '../../shared/services/smacs-modal.service';
// import { ToastService } from '../../shared/services/toast.service';
// import { SnrProfileResource } from '../../shared/resources/snr-profile.resource';
// import { AsciiService } from '../../shared/services/ascii.service';
// import { DefaultValueService } from '../../shared/services/default-value.service';

@Injectable()
export class SnrService {
  // constructor(
  //   private translate: TranslateService,
  //   private smacsModalService: SmacsModalService,
  //   private toastService: ToastService,
  //   private defaultValueService: DefaultValueService,
  //   private customInputService,
  //   private snrProfileResource: SnrProfileResource,
  //   private asciiService: AsciiService
  // ) {}
  // confirmAndDeleteSnr = (id: string, name: string, auditTags: AuditTag[]) => {
  //   // TODO: this needs some love...
  //   return this.smacsModalService.showCustomModal(
  //     require('webapp/app/shared/html/delete-button/yes-no-modal.html'),
  //     'YesNoModalController',
  //     function () {
  //       return {
  //         modalTitle: function () {
  //           return {key: 'tkey;snr.snrprofile.delete.modal.header'};
  //         },
  //         confirmationMsg: function () {
  //           return {
  //             key: 'tkey;snr.snrprofile.delete.modal.text',
  //             tokens: {name: name}
  //           };
  //         },
  //         yesBtnMsg: 'tkey;dialogs.button.cancel',
  //         noBtnMsg: 'tkey;dialogs.button.delete',
  //         modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
  //         modalBodyIconClass: 'fa fa-trash-o fa-5x',
  //         onYesClickFn: function () {
  //           return self.deleteSnrWithToast(id, name, auditTags);
  //         }
  //       };
  //     },
  //     'nav-delete-button-modal'
  //   ).then(
  //     function (response) {
  //       if (!response) {
  //         return $q.reject();
  //       }
  //     }
  //   );
  // };
  /**
   * @param {string} id
   * @param {string} name
   * @param {AuditTag[]} auditTags
   */
  // self.deleteSnrWithToast = function (id, name, auditTags) {
  //   return snrProfileResource.deleteSnr(id, auditTags)
  //     .then(
  //       function () {
  //         toastService.pushDeleteToast($translate.instant('tkey;shared.service.snr.text'), name);
  //       }
  //     );
  // };
  // /**
  //  * @param {DefaultValueUser} [user]
  //  * @return {string}
  //  */
  // self.generateDefaultSnrProfileName = function (user) {
  //   return phoneService.generateDefaultPhoneName('Remote Destination Profile', user);
  // };
  //
  // /**
  //  * @param {DefaultValueUser} user
  //  * @return {string}
  //  */
  // self.generateDefaultSnrDescription = function (user) {
  //   return defaultValueService.generateValue(customInputService.getSnrProfileDescriptionSetting().value, user);
  // };
  /**
   * @param {DefaultValueUser} [user]
   * @return {string}
   */
  // generateDefaultSnrDestinationName = (user) => {
  //   return this.asciiService.stripAccents(
  //     this.defaultValueService.generateValue(this.customInputService.getSnrDestinationNameSetting().value, user)
  //   );
  // };
}
