import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'smacs-service-tile-edit-multi',
  templateUrl: './service-tile-edit-multi.component.html',
  styleUrls: ['./service-tile-edit-multi.component.scss'],
})
export class SmacsServiceTileEditMultiComponent {
  @Input() isEnabled = true;
  @Input() serviceName: string;
  @Input() description: string;
  @Input() icon: string;
  @Input() tooltip: string;
  @Input() showAddButton = false;
  @Output() multiEditAddClicked = new EventEmitter();
  multiEditAddHover = false;
  isHoverTile = false;

  onMultiEditAddClicked() {
    this.multiEditAddClicked.emit();
  }
}
