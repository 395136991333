import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import {
  CallingType,
  MicrosoftCachedOptions,
  MicrosoftDialPlanGroup,
  MicrosoftDialPlanRange,
  PstnConnectivityType,
  Role,
} from '../../../../shared/models/generated/smacsModels';
import { ActivatedRoute, Router } from '@angular/router';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../shared/services/toast.service';
import { MsDialPlanGroupsContext } from '../../../contexts/ms-dial-plan-groups.context';
import { Observable, Subscription } from 'rxjs';
import { RangeFilterService } from '../../../services/range-filter.service';
import {
  EntityTable,
  EntityTableBadges,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../../shared/entity-table/entity-table.models';
import { TelephoneNumberFilter } from '../../../../shared/filters/telephone-number.filter';
import { tap } from 'rxjs/operators';
import { AuthenticationContext } from '../../../../shared/contexts/authentication.context';
import { cloneDeep } from 'lodash';
import { MsTeamsCachedOptionsResource } from '../../../resources/ms-teams-cached-options.resource';

@Component({
  selector: 'microsoft-dial-plan-group-list',
  templateUrl: './dial-plan-group-list.component.html',
  providers: [RangeFilterService],
})
export class DialPlanGroupListComponent implements OnInit, OnDestroy {
  isLoading = true;

  bottomNavButtons = [
    {
      id: 'msDialPlanGroupsAdd',
      dataAutomation: 'ms-dialplan-groups-add-button',
      label: 'tkey;dialplanmanagement.admin.add_group',
      icon: SmacsIcons.ADD,
      buttonClass: ButtonStyles.PRIMARY,
      cb: () => {
        this._onAddGroupClicked();
      },
    },
  ];

  table: EntityTable = {
    columns: [
      {
        columnId: 'id',
        cssColumnSize: 'col-sm-1',
        label: 'tkey;global.table.id.text',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'name',
        cssColumnSize: 'col-sm-3',
        label: 'tkey;dialplanmanagement.admin.group.name',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'numberRanges',
        cssColumnSize: 'col-sm-4',
        label: 'tkey;admin.msdialplan.management.group_list.number_ranges',
        filter: {
          type: EntityTableFilterTypes.TEXT,
          filterFn: (rows, filterValue) =>
            rows.filter((row: EntityTableContentRow) => {
              const numberRanges = cloneDeep(row.content.numberRanges);
              const badges: EntityTableBadges = row.badges['numberRanges'];
              const containsLetters = /[a-zA-Z]/.test(filterValue) || !numberRanges.length;
              if (containsLetters) {
                numberRanges.push({ start: badges.values[0], end: badges.values[0] });
              }
              return containsLetters
                ? this._rangeFilterService.hasMatchingLetters(numberRanges, filterValue)
                : this._rangeFilterService.hasMatchingRanges(numberRanges, filterValue);
            }),
        },
      },
      {
        columnId: 'pstnType',
        cssColumnSize: 'col-sm-2',
        label: 'tkey;admin.msdialplan.management.pstn_connectivity_type.label',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
    ],
    resultsMessage: 'tkey;admin.msdialplan.management.group_list.no_results',
    hasActions: true,
    cssClearButtonColumnSize: 'col-sm-2',
  };
  tableRows: EntityTableContentRow[] = [];

  private _subs = new Subscription();
  private _msCachedOptions: MicrosoftCachedOptions;

  constructor(
    private _bottomNavService: BottomNavService,
    private _breadcrumbsService: BreadcrumbsService,
    private _msDialPlanContext: MsDialPlanGroupsContext,
    private _router: Router,
    private _route: ActivatedRoute,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _toastService: ToastService,
    private _rangeFilterService: RangeFilterService,
    private _telephoneNumberFilter: TelephoneNumberFilter,
    private _authenticationContext: AuthenticationContext,
    private _msTeamCachedOptionsResource: MsTeamsCachedOptionsResource
  ) {}

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;dialplanmanagement.title' }]);
    this._msTeamCachedOptionsResource.get().subscribe((msCachedOptions) => {
      this._msCachedOptions = msCachedOptions;
      this._buildTableRows();
      this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));
    });
  }

  ngOnDestroy() {
    this._breadcrumbsService.clearBreadcrumbs();
    this._subs.unsubscribe();
  }

  private _buildTableRows() {
    const sub = this._msDialPlanContext.state$.subscribe((msDialPlanGroups: MicrosoftDialPlanGroup[]) => {
      this.tableRows = msDialPlanGroups.map((dialPlan) => {
        const numberRangesBadges: EntityTableBadges = {
          values: [],
          badgeCssClass:
            dialPlan.callingType === CallingType.EXTENSION || dialPlan.callingType === CallingType.SHARED_CALLING
              ? ['bg-info']
              : undefined,
          badgeIcon: [],
        };

        dialPlan.dialPlanRangesJson
          .sort((a: MicrosoftDialPlanRange, b: MicrosoftDialPlanRange) => parseInt(a.start) - parseInt(b.start))
          .forEach((range: MicrosoftDialPlanRange) => {
            if (range.start === range.end) {
              numberRangesBadges.values.push(this._telephoneNumberFilter.transform(range.start));
              numberRangesBadges.badgeIcon.push('');
            } else {
              numberRangesBadges.values.push(this._getE164FormattedRange(range));
              numberRangesBadges.badgeIcon.push('');
            }
          });
        if (dialPlan.callingType === CallingType.EXTENSION) {
          numberRangesBadges.values.unshift(
            ' ' +
              this._translateService.instant('tkey;admin.msdialplan.management.calling_type.main_number.badge.text') +
              `${this._telephoneNumberFilter.transform(dialPlan.mainNumber)}`
          );
          numberRangesBadges.badgeIcon.unshift(SmacsIcons.HASH);
        }
        if (dialPlan.callingType === CallingType.SHARED_CALLING) {
          const sharedCallingLineUri = this._msCachedOptions.sharedCallingRoutingPolicies.find(
            (policy) => policy.policyName === dialPlan.sharedCallingRoutingPolicy
          )?.resourceAccountLineUri;
          numberRangesBadges.values.unshift(
            ' ' +
              this._translateService.instant(
                'tkey;admin.msdialplan.management.calling_type.shared_calling.badge.text'
              ) +
              `${this._telephoneNumberFilter.transform(sharedCallingLineUri)}`
          );
          numberRangesBadges.badgeIcon.unshift(SmacsIcons.HASH);
        }

        const isDeleteDisabled =
          this._authenticationContext.getCurrentUser().role !== Role.ZIRO_SUPPORT &&
          (dialPlan.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS_BYOC ||
            dialPlan.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS);
        const isCopyDisabled =
          this._authenticationContext.getCurrentUser().role !== Role.ZIRO_SUPPORT &&
          (dialPlan.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS_BYOC ||
            dialPlan.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS);

        return {
          content: {
            id: dialPlan.id,
            name: dialPlan.name,
            numberRanges: dialPlan.dialPlanRangesJson,
            pstnType: !!dialPlan.carrierName
              ? this._getFormattedPstnConnectivity(dialPlan) + ' - ' + dialPlan.carrierName
              : this._getFormattedPstnConnectivity(dialPlan),
          },
          badges: {
            numberRanges: numberRangesBadges,
          },
          actions: [
            {
              buttonStyle: ButtonStyles.DEFAULT,
              buttonType: ButtonTypes.LINK,
              buttonLinkHref: window.location.href + `/${dialPlan.id}/copy`,
              dataAutomation: 'microsoft-dial-plan-group-copy',
              icon: SmacsIcons.COPY,
              onClick: () => this._router.navigate([dialPlan.id, 'copy'], { relativeTo: this._route }),
              isDisabled: isCopyDisabled,
              tooltip: 'tkey;admin.msdialplan.management.button.copy.disabled.tooltip',
              tooltipDisabled: !isCopyDisabled,
            },
            {
              buttonStyle: ButtonStyles.PRIMARY,
              buttonType: ButtonTypes.LINK,
              buttonLinkHref: window.location.href + `/${dialPlan.id}`,
              dataAutomation: 'microsoft-dial-plan-group-edit',
              icon: SmacsIcons.EDIT,
              onClick: () => this._router.navigate([dialPlan.id], { relativeTo: this._route }),
            },
            {
              buttonStyle: ButtonStyles.DANGER,
              dataAutomation: 'microsoft-dial-plan-group-delete',
              icon: SmacsIcons.DELETE,
              onClick: () => this._deleteClicked(dialPlan),
              isDisabled: isDeleteDisabled,
              tooltip: 'tkey;admin.msdialplan.management.group_list.delete_disabled.tooltip',
              tooltipDisabled: !isDeleteDisabled,
            },
          ],
        };
      });
      this.isLoading = false;
    });
    this._subs.add(sub);
  }

  private _deleteClicked(msDialPlanGroup: MicrosoftDialPlanGroup) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        title: this._translateService.instant('tkey;admin.msdialplan.management.delete_modal.title'),
        promptBody: this._translateService.instant('tkey;admin.msdialplan.management.delete_modal.description', {
          groupName: msDialPlanGroup.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteGroup(msDialPlanGroup);
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _onAddGroupClicked() {
    this._router.navigate(['new-group'], { relativeTo: this._route });
  }

  private _getE164FormattedRange(range: MicrosoftDialPlanRange): string {
    return this._rangeFilterService.getE164FormattedRange(range);
  }

  private _deleteGroup(msDialPlanGroup: MicrosoftDialPlanGroup): Observable<void> {
    return this._msDialPlanContext.delete(msDialPlanGroup.id).pipe(
      tap(() => {
        this._toastService.pushDeleteToast('tkey;dialplanmanagement.save.successful.toast', msDialPlanGroup.name);
      })
    );
  }

  private _getFormattedPstnConnectivity(dialPlan: MicrosoftDialPlanGroup): string {
    switch (dialPlan.pstnConnectivityType) {
      case PstnConnectivityType.DIRECT_ROUTING:
        return this._translateService.instant(
          'tkey;admin.msdialplan.management.pstn_connectivity_type.direct_routing.label'
        );
      case PstnConnectivityType.OPERATOR_CONNECT:
        return this._translateService.instant(
          'tkey;admin.msdialplan.management.pstn_connectivity_type.operator_connect.label'
        );
      case PstnConnectivityType.ZIRO_DRAAS_BYOC:
        return this._translateService.instant(
          'tkey;admin.msdialplan.management.pstn_connectivity_type.ziro_draas_byoc.label'
        );
      case PstnConnectivityType.ZIRO_DRAAS:
        return this._translateService.instant(
          'tkey;admin.msdialplan.management.pstn_connectivity_type.ziro_draas.label'
        );
      case PstnConnectivityType.MICROSOFT_CALLING_PLANS:
        return this._translateService.instant(
          'tkey;admin.msdialplan.management.pstn_connectivity_type.ms_calling_plans.label'
        );
      default:
        return '';
    }
  }
}
