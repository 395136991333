import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Pipe({
  name: 'daysAgo',
})
export class DaysAgoPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(date: string): string {
    if (!date) {
      return this.translateService.instant('tkey;time.never');
    } else {
      const daysAgo = moment().diff(date, 'days');
      if (daysAgo === 0) {
        return this.translateService.instant('tkey;time.today');
      }
      return daysAgo === 1
        ? this.translateService.instant('tkey;reporting.device_utilization.last_used.singular')
        : this.translateService.instant('tkey;reporting.device_utilization.last_used.plural', {
            days: daysAgo,
          });
    }
  }
}
