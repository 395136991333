<app-top-nav></app-top-nav>
<div class="col-md-8 offset-md-2 mt-2">
  <h1>
    <span class="fa-stack fa-md text-info">
      <i class="icon-circle fa-stack-2x"></i>
      <i class="icon-refresh fa-stack-1x fa-inverse"></i>
    </span>
    <span>{{ 'tkey;pages.pinreset.title' | translate }}</span>
  </h1>

  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="row mt-2" [hidden]="isLoading">
    <legend data-automation="pin-reset-user-display-name">
      {{ voicemail?.displayName }}
    </legend>

    <smacs-pin-reset #vmPin [fieldId]="'vmPinReset'" (resetClicked)="onSubmit()"></smacs-pin-reset>
  </div>
</div>
