import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EndUserProvisioningOptions, JobState, JobStatus } from '../../shared/models/generated/smacsModels';
import { AuditHeaderService } from '../../shared/services/audit-header.service';
import { map } from 'rxjs/operators';
import { Nvp } from '../../shared/models/nvp';

@Injectable()
export class BulkEndUserProvisioningResource {
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  post(body: EndUserProvisioningOptions[], auditTag?: Nvp): Observable<void> {
    if (auditTag) {
      const auditHeader = this.auditHeaderService.buildHeader([auditTag]);
      return this.http.post<void>(`/services/cisco/automate-jobs/cisco-bulk-end-user-provisionings`, body, {
        headers: auditHeader,
      });
    }
    return this.http.post<void>(`/services/cisco/automate-jobs/cisco-bulk-end-user-provisionings`, body);
  }

  getState(): Observable<JobState> {
    return this.http
      .get<JobStatus>(`/services/cisco/automate-jobs/cisco-bulk-end-user-provisionings/status`)
      .pipe(map((jobStatus) => jobStatus.jobState));
  }
}
