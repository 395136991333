<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.ms_dial_plan_exception.edit.about_card' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div *ngIf="!isLoading">
      <app-microsoft-dial-plan-management-exception-edit-form
        [isNewGroup]="!groupId"
        [entity]="exceptionGroup"
        [groupId]="groupId"
        [exceptionGroups]="exceptionGroups"
        (smacsFormsUpdate$)="onFormUpdate($event)"
        (isValidFromDynamicValidator)="onValidationFromDynamicValidator($event)"
      ></app-microsoft-dial-plan-management-exception-edit-form>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
