<div
  class="mb-3 row"
  [hidden]="state.hidden && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage,
    'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
  }"
>
  <label
    [class]="'smacs-forms-label col-form-label ' + state.columnClasses?.label"
    [attr.for]="config.dataAutomation + '-input'"
    [attr.data-automation]="'smacs-select-label'"
  >
    <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
    <span>{{ getLabel() | translate }}</span>
  </label>

  <div [class]="state.columnClasses?.input">
    <div class="input-group">
      <input
        type="text"
        class="color-picker-input"
        [attr.data-automation]="'smacs-color-picker-input'"
        [disabled]="state.disabled"
        [(colorPicker)]="fieldData"
        [style.background]="fieldData"
        (colorPickerChange)="updateSelf($event)"
        [cpPosition]="'top'"
        [cpOutputFormat]="'hex'"
      />

      <span
        class="{{ smacsIcons.SAVING }} form-control-feedback"
        [hidden]="!(validationState === ValStates.PENDING && showAsyncValidation)"
        data-automation="smacs-text-async-pending"
      ></span>

      <span
        class="{{ smacsIcons.REMOVE }} form-control-feedback"
        [hidden]="!(validationState === ValStates.INVALID && showAsyncValidation)"
        data-automation="smacs-text-async-invalid"
      ></span>

      <span
        class="{{ smacsIcons.OK }} form-control-feedback"
        [hidden]="!(validationState === ValStates.VALID && showAsyncValidation)"
        data-automation="smacs-text-async-valid"
      ></span>
    </div>

    <div class="smacs-forms-feedback-area">
      <div
        class="smacs-forms-validation-message"
        data-automation="smacs-forms-validation-message"
        [hidden]="!showValidation && !isFormSubmitted"
      >
        {{ validationMessage?.content | translate : validationMessage?.params }}
      </div>

      <div
        class="smacs-forms-validation-message text-warning"
        [ngClass]="{ 'd-inline': config.autogeneration?.inline }"
        data-automation="smacs-forms-warning-message"
        [hidden]="!misconfigurationFeedbackMessage"
      >
        {{ misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params }}
      </div>
    </div>
  </div>
</div>
