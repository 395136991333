import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';
import { getServiceNameTkey } from '../site-management-service/serviceNameMappings';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ButtonSizes } from '../../../../button/button.component';
import { CommonFieldsStateByService } from '../sites/site/common-fields-ui.context';

export interface SiteSideNavSection {
  dataAutomation: string;
  label: string;
  isActive: boolean;
  links: SiteSideNavLink[];
  key: string;
}

export interface SiteSideNavLink {
  dataAutomation: string;
  label: string;
  sectionName?: string;
  isCommon?: boolean;
}

export interface SiteSideNavSectionEntity {
  oneClick: boolean;
  enabled: boolean;
}

@Component({
  selector: 'smacs-site-side-nav',
  templateUrl: './site-side-nav.component.html',
  styleUrls: ['./site-side-nav.component.scss'],
})
export class SiteSideNavComponent implements OnChanges {
  SmacsIcons = SmacsIcons;
  ButtonSizes = ButtonSizes;

  @Input() siteName = '';
  @Input() sideNavLinks: { [key: string]: SiteSideNavLink[] };
  @Input() sectionEntities: { [key: string]: SiteSideNavSectionEntity };
  @Input() commonFields: CommonFieldsStateByService;
  @Output() commonFieldsToggled = new EventEmitter<string>();

  @Input()
  get activeSection(): string {
    return this._activeSection;
  }
  set activeSection(activeSection: string) {
    this._activeSection = `${activeSection.replace(/\s/g, '').trim().toLowerCase()}-service-setting`;
    if (activeSection) {
      let elementIndex = 0;
      const elements = document.querySelectorAll('.site-form .service-setting-container');
      for (let i = 0; i < elements.length; i++) {
        const element = elements[i];
        const elementDataAutomation = element.getAttribute('data-automation');
        if (this.activeSection === elementDataAutomation) {
          elementIndex = i;
        }
      }
      this._scrollSideNav(elementIndex);
    }
  }
  private _activeSection: string;

  links: SiteSideNavSection[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.sideNavLinks &&
      changes.sideNavLinks.currentValue &&
      Object.keys(changes.sideNavLinks.currentValue).length &&
      !isEqual(changes?.sideNavLinks.previousValue, changes?.sideNavLinks.currentValue)
    ) {
      this._buildNav(changes.sideNavLinks.currentValue);
    }
  }

  isSectionEnabled(key: string, isEnabled: boolean) {
    const alwaysOnSections = ['Directory Number', 'Line Features', 'End User', 'Translation Pattern'];
    return alwaysOnSections.includes(key) ? true : isEnabled;
  }

  scrollToElement(dataAutomation: string) {
    document.querySelectorAll(`[data-automation="${dataAutomation}"]`)[0].scrollIntoView({ behavior: 'smooth' });
  }

  onCommonFieldsToggle(key: string) {
    this.commonFieldsToggled.emit(key);
  }

  private _scrollSideNav(index: number) {
    const cardHeaderHeight = 38;
    const navItemHeight = 46;
    const sideNav = document.getElementById('site-side-nav');
    if (index === this.links.length - 1) {
      setTimeout(() => {
        const bottomOfCard = document.querySelector('.card').getBoundingClientRect().bottom;
        document.querySelector('#site-side-nav').scrollTo({ top: bottomOfCard, behavior: 'smooth' });
      });
    } else {
      sideNav.scrollTop = cardHeaderHeight + navItemHeight * index;
    }
  }

  private _buildNav(sideNavLinks: { [key: string]: SiteSideNavLink[] }) {
    this.links = Object.keys(sideNavLinks).map((key: string) => {
      const fields = sideNavLinks[key];
      return {
        dataAutomation: `${key.replace(/\s/g, '').trim().toLowerCase()}-service-setting`,
        label: getServiceNameTkey(key),
        key: key,
        isActive: false,
        links: fields.map((field: SiteSideNavLink) => {
          return {
            label: field.label,
            dataAutomation: field.dataAutomation,
            isCommon: field.isCommon,
            sectionName: field.sectionName,
          };
        }),
      };
    });
  }

  protected readonly Object = Object;
}
