import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { DatatableFilterAbstractDirective } from '../datatable-filter-abstract.directive';
import { SmacsFilterInputComponent } from '../../../../shared/filter/filter-input/filter-input.component';
import { TranslateService } from '@ngx-translate/core';
import { difference, isEqual } from 'lodash';
import ChangeEvent = JQuery.ChangeEvent;

@Component({
  selector: 'smacs-datatable-multi-select-filter',
  templateUrl: './datatable-multi-select-filter.component.html',
  styleUrls: ['./datatable-multi-select-filter.component.scss', '../datatable-filter-abstract.directive.scss'],
  providers: [{ provide: DatatableFilterAbstractDirective, useExisting: DatatableMultiSelectFilterComponent }],
})
export class DatatableMultiSelectFilterComponent
  extends DatatableFilterAbstractDirective<string[]>
  implements OnChanges, OnInit
{
  @ViewChild(SmacsFilterInputComponent) smacsFilterInputComponent: SmacsFilterInputComponent;
  @Input() items: string[];
  @Input() labelCssClass = '';
  @Input() initialSelectedItems: string[];

  displayValue: string;
  searchFilter = '';
  selectedItems: string[] = [];

  constructor(private translateService: TranslateService, private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    if (!!this.initialSelectedItems) {
      this.selectedItems = this.initialSelectedItems;
      this.setValue();
    }

    this.isInitialized.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!isEqual(changes.items.previousValue, changes.items.currentValue)) {
      this.setValue();
      this.changeDetectorRef.detectChanges();
    }
  }

  clear = () => this._clearValues();

  onChanged(newValue: string) {
    this.searchFilter = newValue;
  }

  getFilteredSearchResults(): string[] {
    if (this.searchFilter !== '' && this.searchFilter !== null) {
      const modifiedFilterResults = difference(this.items, this.selectedItems).filter((item: string) =>
        item.toLowerCase().includes(this.searchFilter.toLowerCase())
      );
      return modifiedFilterResults.concat(this.selectedItems);
    }
    return this.items;
  }

  setValue() {
    if (this.selectedItems.length > 1) {
      this.displayValue =
        this.selectedItems.length + this.translateService.instant('tkey;reporting.datatable.filter.some.selected');
    } else {
      this.displayValue = this.selectedItems[0];
    }
    this.value = this.selectedItems;
    this.filterUpdateSource.next();
    this.changeDetectorRef.detectChanges();
  }

  onFilterChange(value: any, event: ChangeEvent) {
    if (!event.target.checked) {
      this.selectedItems = this.selectedItems.filter((val) => val !== value);
    } else {
      if (Array.isArray(value)) {
        this.selectedItems = value;
      } else {
        this.selectedItems.push(value);
      }
    }
    this.setValue();
  }

  filterOnlyOneItem(item: any) {
    this.selectedItems = [item];
    this.setValue();
  }

  private _clearValues() {
    this.value = [];
    this.displayValue = null;
    this.filterUpdateSource.next();
    this.smacsFilterInputComponent.onClear();
    this.selectedItems = [];
  }
}
