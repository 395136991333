<div
  class="mb-3"
  [hidden]="state.hidden && (!forceShow || !state.defaultValue)"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted,
    'smacs-forms-feedback': showValidation,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage
  }"
>
  <div
    *ngFor="let button of radioSelect"
    [attr.data-automation]="'smacs-radio-option-' + button.value"
    class="row mb-1"
  >
    <label
      [class]="'smacs-forms-label col-form-label ' + state.columnClasses?.label"
      [attr.data-automation]="config.dataAutomation + '-label'"
      [attr.for]="config.dataAutomation + '-' + button.value + '-' + uniqueRadioId"
    >
      <span [innerHtml]="button.label | translate : { param: button.labelParam }"></span>
    </label>
    <div [class]="'align-items-center d-flex ' + state.columnClasses?.input">
      <div class="form-check my-auto">
        <input
          class="form-check-input"
          type="radio"
          [attr.id]="config.dataAutomation + '-' + button.value + '-' + uniqueRadioId"
          #ngModelDir="ngModel"
          (ngModelChange)="onRadioChange($event, ngModelDir)"
          [(ngModel)]="fieldData.option"
          [name]="config.dataAutomation + '-' + uniqueRadioId"
          [value]="button.value"
          [attr.data-automation]="'smacs-radio-option-input-' + button.value"
        />
      </div>
      <div class="ms-1 flex-fill">
        <ng-select
          [labelForId]="config.dataAutomation + '-input'"
          [attr.data-automation]="'smacs-select-input'"
          [items]="button.options"
          [(ngModel)]="button.isMultiSelect ? fieldData.siteIds : fieldData.clusterId"
          [ngModelOptions]="{ standalone: true }"
          #ngModelDir="ngModel"
          (ngModelChange)="onSelectChange($event, ngModelDir)"
          [clearable]="false"
          [bindLabel]="'label'"
          [bindValue]="bindValue"
          [multiple]="button.isMultiSelect"
          [loading]="isLoading || triggerLoading()"
          [typeahead]="null"
          [minTermLength]="2"
          [disabled]="state.disabled"
          [groupBy]="'group'"
          (search)="handleSearchInput($event)"
          (blur)="handleBlur()"
          notFoundText="{{ 'tkey;smacs_select.not_found_text' | translate }}"
          loadingText="{{ 'tkey;smacs_select.loading_text' | translate }}"
          clearAllText="{{ 'tkey;smacs_select.clear_all_text' | translate }}"
          typeToSearchText="{{ 'tkey;smacs_select.search_text' | translate }}"
        >
          <ng-template ng-typetosearch-tmp>
            <div class="d-none"></div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-9 offset-lg-3">
      <div class="smacs-forms-feedback-area">
        <div
          class="smacs-forms-validation-message"
          data-automation="smacs-forms-validation-message"
          [hidden]="!showValidation && !isFormSubmitted"
        >
          {{ validationMessage?.content | translate : validationMessage?.params }}
        </div>

        <div
          class="smacs-forms-validation-message text-warning"
          [ngClass]="{ 'd-inline': config.autogeneration?.inline }"
          data-automation="smacs-forms-warning-message"
          [hidden]="!misconfigurationFeedbackMessage"
        >
          {{ misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params }}
        </div>
      </div>
    </div>
  </div>
</div>
