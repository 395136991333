<div class="container-fluid pb-5 adjusted-height">
  <legend data-automation="microsoft-security-groups-header-legend">
    {{ 'tkey;microsoft_360.view.security.groups.membership.header' | translate }}
  </legend>
  <div class="row h-100">
    <div class="col-md-7 order-2 order-md-1 h-100 overflow-auto">
      <smacs-multi-checkbox #securityGroupsCheckboxes [fieldId]="'securityGroups'"></smacs-multi-checkbox>
    </div>
    <div class="col-md-5 order-1 order-md-2 azure-icon">
      <em [ngClass]="smacsIcons.AZURE"></em>
    </div>
  </div>
</div>
