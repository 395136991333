import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../button/button.component';
import {
  ClusterResult,
  DirectoryNumberResult,
  DistributionListResult,
  ExtensionMobilityResult,
  PhoneResult,
  SiteResult,
  SiteSummary,
  SnrProfileResult,
  VoicemailResult,
} from '../models/generated/smacsModels';
import { CurrentClusterContext } from '../contexts/current-cluster.context';
import { DevicePoolSites, UiEndUserResult } from '../../helpdesk/search/search.component';
import { Router } from '@angular/router';
import { SiteSummaryContext } from '../contexts/site-summary.context';
import { Subscription } from 'rxjs';

interface SearchResultLink {
  link: string;
  tooltip?: string;
  isDisabled?: boolean;
}

interface SearchResultDeleteButton {
  isDeletable: boolean;
  isDisabled?: boolean;
  tooltip?: string;
}

@Component({
  selector: 'ziro-cisco-search-result',
  templateUrl: './cisco-search-result.component.html',
  styleUrls: ['cisco-search-result.component.scss'],
})
export class CiscoSearchResultComponent implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;

  @Input() icon: SmacsIcons;
  @Input() description: string;
  @Input() edit: SearchResultLink;
  @Input() pinReset: SearchResultLink;
  @Input() result:
    | DirectoryNumberResult
    | DistributionListResult
    | UiEndUserResult
    | PhoneResult
    | SnrProfileResult
    | VoicemailResult
    | ExtensionMobilityResult;
  @Input() delete: SearchResultDeleteButton;
  @Input() devicePoolSites: DevicePoolSites;

  @Output() deleteClicked = new EventEmitter<
    | DirectoryNumberResult
    | DistributionListResult
    | UiEndUserResult
    | PhoneResult
    | SnrProfileResult
    | VoicemailResult
    | ExtensionMobilityResult
  >();

  private _subscriptions = new Subscription();

  constructor(
    private currentClusterContext: CurrentClusterContext,
    private _router: Router,
    private siteSummaryContext: SiteSummaryContext
  ) {}

  ngOnInit() {
    if (this.result.hasOwnProperty('microsoft365UserRef')) {
      return;
    }

    const siteSummarySub = this.siteSummaryContext.state$.subscribe((siteSummary: SiteSummary) => {
      this._init(siteSummary);
    });
    this._subscriptions.add(siteSummarySub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onDeleteClicked(): void {
    this.deleteClicked.emit(this.result);
  }

  isSvgIcon(): boolean {
    return this.icon.includes('tablet') || this.icon.includes('distribution');
  }

  isSyncInProgress(): boolean {
    if (this.result.hasOwnProperty('isSyncInProgress')) {
      const result = this.result as UiEndUserResult;
      return result.isSyncInProgress;
    }

    return false;
  }

  private _init(siteSummary: SiteSummary) {
    const ciscoResult = this.result as
      | DirectoryNumberResult
      | DistributionListResult
      | UiEndUserResult
      | PhoneResult
      | SnrProfileResult
      | VoicemailResult
      | ExtensionMobilityResult;

    if (ciscoResult.ref?.url.includes('voicemails') || ciscoResult.ref?.url.includes('extension-mobility-profiles')) {
      // VMs don't have a devicepool like phones/snrs so we must look at every site that is on the same unity server
      const sites: SiteResult[] = [];
      siteSummary.clusters.forEach((cluster: ClusterResult) => {
        if (ciscoResult.ref?.url.includes('extension-mobility-profiles')) {
          if (cluster.cucmServerId === ciscoResult.ref.serverId) {
            cluster.sites.forEach((site) => {
              sites.push(site);
            });
          }
        } else {
          cluster.sites.forEach((siteResult: SiteResult) => {
            if (siteResult.unityServerId === ciscoResult.ref.serverId) {
              sites.push(siteResult);
            }
          });
        }
      });

      const hasPermission = sites.some((siteResult: SiteResult) => siteResult.hasPermission);

      if (!hasPermission) {
        if (ciscoResult.ref?.url.includes('voicemails')) {
          this.pinReset = {
            ...this.pinReset,
            tooltip: 'tkey;search.devices.no_permissions_tooltip',
            isDisabled: true,
          };
        }

        this.delete = {
          isDeletable: true,
          isDisabled: true,
          tooltip: 'tkey;search.devices.no_permissions_tooltip',
        };
      }
    } else if (ciscoResult.ref?.url.includes('phones') || ciscoResult.ref?.url.includes('snr-profiles')) {
      const result = this.result as PhoneResult | SnrProfileResult;
      const possibleSites = this.devicePoolSites[result.ref.serverId];
      const clusterResults = siteSummary.clusters.filter(
        (cluster: ClusterResult) => cluster.cucmServerId === ciscoResult.ref.serverId
      );

      const siteSearchResult = possibleSites?.find((ssr: SiteResult) => {
        return clusterResults.find((cr: ClusterResult) => {
          return cr.sites.find((sr: SiteResult) => sr.id === ssr.id);
        });
      });

      if (!siteSearchResult) {
        this.edit = {
          ...this.edit,
          tooltip: 'tkey;search.devices.unconfigured_device_pool_tooltip',
          isDisabled: true,
        };
      } else {
        const cluster = clusterResults.find((cr: ClusterResult) => {
          return cr.sites.find((sr: SiteResult) => sr.id === siteSearchResult.id);
        });
        const site = cluster.sites.find((sr: SiteResult) => sr.id === siteSearchResult.id);
        if (site && !site.hasPermission) {
          this.edit = {
            ...this.edit,
            tooltip: 'tkey;search.devices.no_permissions_tooltip',
            isDisabled: true,
          };

          this.delete = {
            isDeletable: true,
            isDisabled: true,
            tooltip: 'tkey;search.devices.no_permissions_tooltip',
          };
        }
      }
    }
  }
}
