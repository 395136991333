<ng-select
  class="smacs-filter-select flex-fill"
  [ngClass]="{ 'smacs-filter-select-with-icons': showOptionsIcons }"
  [attr.data-automation]="dataAutomation"
  [appendTo]="appendTo"
  [bindLabel]="bindLabel"
  [bindValue]="bindValue"
  [clearable]="isClearable"
  [groupBy]="groupBy"
  [hideSelected]="hideSelected"
  [items]="options$ | async"
  [multiple]="isMultiSelect"
  [ngModelOptions]="{ standalone: true }"
  [placeholder]="placeholder"
  [selectableGroup]="isGroupSelectable"
  [typeahead]="!!asyncOptionsFn ? optionInputSource : null"
  [disabled]="isDisabled"
  [(ngModel)]="filterValue"
  (change)="filterOnChange()"
>
  <ng-template *ngIf="showOptionsIcons" ng-label-tmp ng-option-tmp let-item="item">
    <span [container]="'body'" [ngbTooltip]="item?.iconHtmlTooltip" class="filter-select-option-tooltip-container">
      <span [innerHTML]="item?.iconHtml"></span>
      <span [innerHTML]="item.label"></span>
    </span>
  </ng-template>
</ng-select>
