<app-self-serve-top-bar></app-self-serve-top-bar>

<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div [hidden]="isLoading">
  <app-self-serve-user-details-back
    [link]="'/self-serve/user/' + user?.ref.username"
  ></app-self-serve-user-details-back>

  <app-self-serve-user-details-header
    [heading]="'tkey;self_serve.call_forward.header' | translate"
    [headingDataAutomation]="'self-server-user-details-call-forward-header'"
  ></app-self-serve-user-details-header>

  <smacs-text [fieldId]="'callForward'"></smacs-text>

  <div class="col-sm-12 text-end">
    <smacs-submit-button
      [isPending]="isSubmitting"
      [isDisabled]="isSubmitting"
      [cssClass]="'me-2'"
      [dataAutomation]="'self-serve-call-forward-edit-submit-button'"
      [label]="'tkey;selfserve.user.call_forward.button.label'"
      [iconPending]="smacsIcons.SAVING"
      [formSubmitSubject]="_validateAndSubmitSource"
    ></smacs-submit-button>
  </div>
</div>
