import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { MicrosoftDialPlanExceptionGroup } from '../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { ToastService } from '../../shared/services/toast.service';

@Injectable()
export class MsDialPlanExceptionGroupsContext {
  _stateSource = new ReplaySubject<MicrosoftDialPlanExceptionGroup[]>(1);
  state$ = this._stateSource.asObservable();

  constructor(private _http: HttpClient, private _toastService: ToastService) {
    this._getAll().subscribe();
  }

  getDynamicNonTeamsDialPlaneExceptionGroups(): Observable<MicrosoftDialPlanExceptionGroup[]> {
    return this._http.get<MicrosoftDialPlanExceptionGroup[]>(
      '/services/microsoft/admin/dynamic-non-teams-dial-plan-exception-groups'
    );
  }

  post(body: MicrosoftDialPlanExceptionGroup): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._http.post<void>(`/services/microsoft/admin/dial-plan-exception-groups`, body).subscribe(() => {
        this._getAll().subscribe(() => {
          subscriber.next(null);
          subscriber.complete();
        });
      });
    });
  }

  put(body: MicrosoftDialPlanExceptionGroup): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._http.put<void>(`/services/microsoft/admin/dial-plan-exception-groups/${body.id}`, body).subscribe(() => {
        this._getAll().subscribe(() => {
          subscriber.next(null);
          subscriber.complete();
        });
      });
    });
  }

  delete(id: number, name: string): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._http.delete<void>(`/services/microsoft/admin/dial-plan-exception-groups/${id}`).subscribe(() => {
        this._getAll().subscribe(() => {
          this._toastService.pushDeleteToast('tkey;admin.ms_dial_plan_exception.toast.title', name);
          subscriber.next(null);
          subscriber.complete();
        });
      });
    });
  }

  private _getAll(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._http
        .get<MicrosoftDialPlanExceptionGroup[]>('/services/microsoft/admin/dial-plan-exception-groups')
        .subscribe((data: MicrosoftDialPlanExceptionGroup[]) => {
          this._stateSource.next(data);
          subscriber.next(null);
          subscriber.complete();
        });
    });
  }
}
