import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

import { ToastMessageParams, ToastServiceAbstract, ToastTypes } from './abstract/toast.service.abstract';
import { forOwn, values } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { delay } from 'rxjs/operators';

export interface ToastParams {
  toast_type: string;
  icon: string;
  title_tkey: string;
  msg_tkey: string;
  msg_params: string;
  icon_class: string;
}

@Injectable()
export class ToastService extends ToastServiceAbstract {
  constructor(
    protected toastrService: ToastrService,
    protected translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    super(translateService);
    this.route.queryParams
      .pipe(delay(250))
      .subscribe((params: ToastParams) => this.buildToastFromParams(params, this.route));
  }

  push = (
    type: ToastTypes,
    icon: string,
    title: string,
    message: string,
    messageParams: ToastMessageParams = {},
    timeout?: number,
    iconClass?: string
  ) => {
    if (!icon.startsWith('icon')) {
      throw new Error(`toast icon ${icon} must be defined in the styleguide`);
    }

    if (!timeout) {
      timeout = type === ToastTypes.ERROR ? this.defaultErrorTimeout : this.defaultTimeout;
    }

    const overrides = {
      enableHtml: true,
      closeButton: true,
      timeOut: timeout,
      extendedTimeOut: timeout,
      toastClass: 'toast toast-custom-icon show',
    };

    const escapedMessageParams: ToastMessageParams = {};
    forOwn(messageParams, (val, key) => {
      escapedMessageParams[key] = this._escapeHtml(val);
    });

    const htmlBody = this._createToastHtmlBody(icon, title, message, escapedMessageParams, iconClass);

    switch (type) {
      case ToastTypes.INFO: {
        this.toastrService.info(htmlBody, '', overrides);
        break;
      }
      case ToastTypes.WARNING: {
        this.toastrService.warning(htmlBody, '', overrides);
        break;
      }
      case ToastTypes.ERROR: {
        this.toastrService.error(htmlBody, '', overrides);
        break;
      }
      case ToastTypes.SUCCESS: {
        this.toastrService.success(htmlBody, '', overrides);
        break;
      }
      default: {
        throw new Error(`toast type must be one of ${values(ToastTypes).toString()} but was ${type}`);
      }
    }
  };

  buildToastFromParams(params: ToastParams, activatedRoute: ActivatedRoute) {
    const toastType = (params.toast_type ? decodeURIComponent(params.toast_type) : null) as ToastTypes;
    const icon = params.icon ? decodeURIComponent(params.icon) : null;
    const title = decodeURIComponent(params.title_tkey);
    const message = decodeURIComponent(params.msg_tkey);
    const iconClass = params.icon_class ? decodeURIComponent(params.icon_class) : null;

    if (!toastType || !icon || !title || !message) {
      return;
    }

    const toastTypeKey = this._getEnumKeyByEnumValue(ToastTypes, toastType);

    if (params.msg_params) {
      const msgParamsDecoded = decodeURIComponent(params.msg_params);
      const msgParamsParsed = JSON.parse(msgParamsDecoded);

      this.push(ToastTypes[toastTypeKey], icon, title, message, msgParamsParsed, null, iconClass);
    } else {
      this.push(ToastTypes[toastTypeKey], icon, title, message, null, null, iconClass);
    }

    this._clearQueryParams(activatedRoute);
  }

  private _escapeHtml(value: string): string {
    const escapedCharactersMap: { [key: string]: string } = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#039;',
    };

    return value.replace(/[&<>"']/g, function (key: string) {
      return escapedCharactersMap[key];
    });
  }

  private _clearQueryParams(activatedRoute: ActivatedRoute) {
    this.router.navigate([], {
      relativeTo: activatedRoute,
      queryParams: {
        toast_type: null,
        icon: null,
        title_tkey: null,
        msg_tkey: null,
        msg_params: null,
      },
      replaceUrl: true,
    });
  }
}
