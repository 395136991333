<app-top-nav [isHomePage]="true"></app-top-nav>
<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div [hidden]="isLoading" class="container-fluid pt-5">
  <div class="col-md-8 offset-md-2 col-lg-6 offset-lg-3">
    <div class="text-center pt-5">
      <div class="mb-5 pt-5">
        <img src="/static/img/ziro-platform.png" alt="ZIRO" class="home-page-logo" />
      </div>
      <div
        class="input-group input-group-has-icon"
        [ngClass]="{ 'has-feedback has-error': !!zpmSearchErrorMessage && !hideSearchErrorMessage }"
      >
        <input
          type="text"
          class="form-control input-group-icon-input form-control-lg border-rad-tl-2 border-rad-bl-2"
          [placeholder]="searchPlaceholder | translate"
          (keyup.enter)="searchByString()"
          [(ngModel)]="searchText"
          (ngModelChange)="updateSearchError()"
          data-automation="home-search-input"
        />
        <button class="btn btn-primary border-rad-tr-2 border-rad-br-2" type="button" (click)="searchByString()">
          <i [attr.class]="smacsIcons.SEARCH"></i>
        </button>
      </div>
      <div
        *ngIf="zpmSearchErrorMessage && !hideSearchErrorMessage"
        class="small has-error text-start"
        data-automation="zpm-search-error-message"
      >
        {{ zpmSearchErrorMessage | translate }}
      </div>
      <div class="top-buffer" *ngIf="isCucmEnabled && provisioningEnabled && !proxyServer">
        <a
          *ngIf="isAddUserEnabled && isAdminOrGlobalHelpdesk"
          [routerLink]="'/user-add'"
          data-automation="add-user-button"
          class="btn btn-outline-info"
        >
          <i [attr.class]="smacsIcons.USER"></i>
          {{ 'tkey;home.add.user.button' | translate }}
        </a>
        &nbsp;
        <a
          [routerLink]="'/public-phone'"
          [hidden]="!isDeskphoneEnabledOnAnyClusters"
          class="btn btn-outline-info"
          data-automation="add-public-phone-button"
        >
          <i [attr.class]="smacsIcons.DESKPHONE"></i>
          {{ 'tkey;home.add.phone.button' | translate }}
        </a>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="text-center muted small pt-4">
            <div>
              <strong>
                <span>{{ 'tkey;home.version.text' | translate }}</span>
              </strong>

              <span data-automation="build-version">
                {{ buildVersion }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row top-buffer">
        <div class="col-md-12 text-center">
          <a data-automation="system-status-link" [routerLink]="'/system-health-status'" target="_blank">
            {{ 'tkey;system_status.title' | translate }}
          </a>
        </div>
        <div class="col-12">
          <div class="text-center muted small pt-1">
            <div *ngIf="isHosted">
              <a id="home-hosted-link" href="{{releaseNotesUrl}}" target="_blank">
                {{ 'tkey;footer.release_notes' | translate }} <i class="{{smacsIcons.EXTERNAL_LINK}} release-notes-icon"></i>
              </a>
            </div>
            <a *ngIf="isAdminOrHelpdesk" id="home-support-link" [routerLink]="'/support'" target="_blank">
              {{ 'tkey;footer.support' | translate }}
            </a>
            <p>
              <span
                [innerHtml]="'tkey;footer.copyright' | translate : { year: year }"
                data-automation="copyright-stamp"
              ></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
