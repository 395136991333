import { SvgIconRegistryService } from 'angular-svg-icon';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { GlobalPropertiesContext } from './global-properties.context';
import { GlobalProperties } from '../models/generated/smacsModels';
import zpcSvgJson from 'ziro-static/zpc-svg.json';
import zpmSvgJson from 'ziro-static/zpm-svg.json';
import miscSvgJson from 'ziro-static/svg.json';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class SvgLoaderContext {
  private _stateSource = new BehaviorSubject<boolean>(null);
  state$ = this._stateSource.asObservable();

  private _isHostedEnabledSource = new ReplaySubject<boolean>(1);
  private _isHostedEnabledState$ = this._isHostedEnabledSource.asObservable();
  private _isInitialized = false;

  constructor(
    private _svgIconRegistryService: SvgIconRegistryService,
    private _globalPropertiesContext: GlobalPropertiesContext
  ) {}

  init() {
    this._globalPropertiesContext.state$
      .pipe(takeUntil(this._isHostedEnabledState$))
      .subscribe((globalProperties: GlobalProperties) => {
        if (!this._isInitialized) {
          this._isInitialized = true;
          this._isHostedEnabledSource.next(globalProperties.hostedEnabled);
        }
      });
  }

  loadSvgs(loadAll = false): Observable<void> {
    return this._isHostedEnabledState$.pipe(
      switchMap((hostedEnabled: boolean) => {
        return this.state$.pipe(
          switchMap((state: boolean) => {
            if (!state) {
              const svgs = loadAll
                ? zpmSvgJson['iconImageFiles'].concat(zpcSvgJson['iconImageFiles'])
                : hostedEnabled
                ? zpmSvgJson['iconImageFiles']
                : zpcSvgJson['iconImageFiles'];
              const requests = svgs.map((svg: { iconName: string; iconPath: string }) => {
                return this._loadSvg(svg.iconPath, svg.iconName);
              });
              return forkJoin(requests).pipe(
                switchMap(() => {
                  this._stateSource.next(true);
                  return of(null);
                })
              );
            } else {
              return of(null);
            }
          })
        );
      })
    );
  }

  loadMiscSvgs() {
    const requests = miscSvgJson['iconImageFiles'].map((svg: { iconName: string; iconPath: string }) => {
      return this._loadSvg(svg.iconPath, svg.iconName);
    });
    return forkJoin(requests);
  }

  private _loadSvg(url: string, name: string) {
    return this._svgIconRegistryService.loadSvg(url, name);
  }
}
