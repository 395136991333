import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { State, StatusCategory } from '../../../shared/models/generated/smacsModels';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { Observable, Subscription, tap, timer } from 'rxjs';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { SystemStatusContext } from '../../../shared/contexts/system-status.context';
import { ProxyKeysContext } from '../../contexts/proxy-keys.context';
import { delayWhen, map } from 'rxjs/operators';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';

interface GenerateKeyFormData {
  secret: string;
}

@Component({
  selector: 'ziro-generate-key',
  templateUrl: './generate-key.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class GenerateKeyComponent extends SmacsFormAbstractDirective<GenerateKeyFormData> implements OnInit, OnDestroy {
  isLoading = true;
  smacsIcons = SmacsIcons;
  disableCopiedUriTooltip = true;
  proxyKey: string;
  healthStatusAlertCss: 'alert-warning' | 'alert-info' | 'alert-success';
  healthStatusAlertText: string;

  formConfig = {
    fields: {
      secret: {
        label: 'tkey;admin.proxy_server_management.generate_key.secret.label',
        helpText: 'tkey;admin.proxy_server_management.generate_key.secret.helptext',
        dataAutomation: 'generate-key-secret',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          isWhiteWhileDisabled: true,
          disablePointerEvents: true,
        }),
        disabled: () => true,
        disabledTooltip: () => {
          if (!this.entity?.secret) {
            return {
              content: 'tkey;admin.proxy_server_management.generate_key.secret.disabled.tooltip',
              params: {},
            };
          }
        },
      },
    },
  };

  private _subs = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _bottomNavService: BottomNavService,
    private _toastService: ToastService,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _breadcrumbsService: BreadcrumbsService,
    private _systemStatusContext: SystemStatusContext,
    private _proxyKeysContext: ProxyKeysContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._breadcrumbsService.updateBreadcrumbs([
      { label: 'tkey;admin.proxy_server_management.title' },
      { label: 'tkey;admin.proxy_server_management.generate_key.title' },
    ]);

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() =>
      this._bottomNavService.setBottomNavValidationError(!this.isFormValid())
    );
    this._subs.add(formSubmittedSub);

    const healthStatusSub = this._systemStatusContext.state$.subscribe((healthStatus) => {
      const proxyServerHealthStatus = healthStatus.healthStatuses.find(
        (status) => status.category === StatusCategory.PROXY_SERVER
      );
      if (!proxyServerHealthStatus) {
        this.healthStatusAlertCss = undefined;
        this.healthStatusAlertText = undefined;
      } else if (proxyServerHealthStatus.state === State.OK) {
        this.healthStatusAlertCss = 'alert-success';
        this.healthStatusAlertText = 'tkey;admin.proxy_server_management.generate_key.health_status_alert.success';
      } else if (proxyServerHealthStatus.state === State.WARNING) {
        if (!!this.proxyKey) {
          this.healthStatusAlertCss = 'alert-info';
          this.healthStatusAlertText = 'tkey;admin.proxy_server_management.generate_key.health_status_alert.info';
        } else {
          this.healthStatusAlertCss = 'alert-warning';
          this.healthStatusAlertText = 'tkey;admin.proxy_server_management.generate_key.health_status_alert.warning';
        }
      }
    });
    this._subs.add(healthStatusSub);

    const proxyKeySub = this._proxyKeysContext.state$.subscribe(
      (settings) => {
        if (!this.proxyKey) {
          this._setEntity(settings.keyFirstThree ? `${settings.keyFirstThree}•••••••••••••••••••••••••••••••••` : '');
        }
        this._initBottomNav(!!settings.keyFirstThree);
        this._setBottomNavTimestamp(settings.generatedDate);
        this.isLoading = false;
      }
    );

    this._subs.add(proxyKeySub);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subs.unsubscribe();
  }

  onCopyUriClick(): void {
    this.disableCopiedUriTooltip = false;
    timer(1000).subscribe(() => {
      this.disableCopiedUriTooltip = true;
    });
  }

  protected submit(): Observable<void> {
    return this.entity?.secret ? this._regenerateSecret() : this._generateSecret();
  }

  private _generateSecret(): Observable<void> {
    return this._proxyKeysContext.post().pipe(
      map((response) => {
        this.proxyKey = response.proxyKey;
        this._setEntity(response.proxyKey);
        this._allowPointerEvents();
      }),
      delayWhen(() => this._systemStatusContext.getSystemStatus()),
      tap(() => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.KEY,
          'tkey;admin.proxy_server_management.generate_key.success_toast.title',
          'tkey;admin.proxy_server_management.generate_key.success_toast.message'
        );
      })
    );
  }

  private _allowPointerEvents() {
    this.fieldChannels.secret.componentConfigSource.next(
      new SmacsTextConfig({
        htmlInputType: HtmlInputType.TEXT,
        isWhiteWhileDisabled: true,
        disablePointerEvents: false,
      })
    );
  }

  private _initBottomNav(isExisting: boolean): void {
    const buttons: BottomNavButton[] = [];
    if (isExisting) {
      buttons.push({
        id: 'proxy-server-management-delete-key-button',
        label: 'tkey;admin.proxy_server_management.generate_key.delete_button.label',
        buttonClass: ButtonStyles.DANGER,
        icon: SmacsIcons.DELETE,
        dataAutomation: 'proxy-server-management-delete-key-button',
        cb: () => this._onDeleteClick(),
      });
    }
    buttons.push({
      id: 'proxy-server-management-generate-key-button',
      label: isExisting
        ? 'tkey;admin.proxy_server_management.generate_key.regenerate_button.label'
        : 'tkey;admin.proxy_server_management.generate_key.generate_button.label',
      buttonClass: ButtonStyles.PRIMARY,
      icon: SmacsIcons.KEY,
      submitSubject: this._validateAndSubmitSource,
      dataAutomation: 'proxy-server-management-generate-key-button',
      type: ButtonTypes.SUBMIT,
    });
    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttons));
  }

  private _setBottomNavTimestamp(generatedAtDate: string): void {
    let helpText: string;
    if (generatedAtDate) {
      helpText = `
              <i class="${SmacsIcons.CLOCK}"></i>
                ${this._translateService.instant('tkey;admin.proxy_server_management.generate_key.generated_date', {
                  date: generatedAtDate,
                })}
              `;
    } else {
      helpText = '';
    }

    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        helpText: helpText,
        hasValidationError: false,
      })
    );
  }

  private _onDeleteClick(): void {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        title: this._translateService.instant('tkey;admin.proxy_server_management.generate_key.delete_button.label'),
        promptBody: this._translateService.instant('tkey;admin.proxy_server_management.generate_key.delete_modal.body'),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteSettings(),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteSettings(): Observable<void> {
    return this._proxyKeysContext.delete().pipe(
      delayWhen(() => this._systemStatusContext.getSystemStatus()),
      map(() => {
        this.entitySource.next({ secret: null });
        this.proxyKey = null;
        this._toastService.push(
          ToastTypes.INFO,
          `${SmacsIcons.DELETE} text-danger`,
          'tkey;admin.proxy_server_management.generate_key.delete_toast.title',
          'tkey;admin.proxy_server_management.generate_key.delete_toast.message'
        );
        this._changeDetectorRef.detectChanges();
      })
    );
  }

  private _regenerateSecret(): Observable<void> {
    const options = {
      windowClass: 'regenerate-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.REFRESH,
        iconClass: 'text-primary',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center',
        title: this._translateService.instant(
          'tkey;admin.proxy_server_management.generate_key.regenerate_button.label'
        ),
        promptBody: this._translateService.instant(
          'tkey;admin.proxy_server_management.generate_key.regenerate_modal.body'
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;admin.proxy_server_management.generate_key.regenerate_button.label',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._regenerateSettings(),
          },
        ],
      },
    };

    return this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _regenerateSettings(): Observable<void> {
    return this._proxyKeysContext.delete().pipe(delayWhen(() => this._generateSecret()));
  }

  private _setEntity(key: string): void {
    this.entitySource.next({ secret: key ?? '' });
  }
}
