import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { Microsoft360ViewContext } from '../../../../shared/contexts/microsoft-360-view.context';
import { AccountType, Microsoft360View } from '../../../../shared/models/generated/smacsModels';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

interface Ms360ViewBadge {
  badgeText?: string;
  outerSpanClass?: string;
  innerSpanClass?: string;
  iconClass?: SmacsIcons | string;
  tooltip?: string;
  tooltipClass?: string;
  tooltipPlacement?: string;
}

@Component({
  selector: 'smacs-microsoft360-detail-card',
  templateUrl: './microsoft360-detail-card.component.html',
  styleUrls: ['./microsoft360-detail-card.component.scss'],
})
export class Microsoft360DetailCardComponent implements OnInit {
  @Output() viewLoaded = new EventEmitter<boolean>();
  smacsIcons = SmacsIcons;
  microsoft360View: Microsoft360View;
  userPhoto: string;
  isResourceAccount: boolean;
  badge: Ms360ViewBadge;
  accountType: AccountType;
  isAccountLocked: boolean;
  upn: string;

  constructor(
    private route: ActivatedRoute,
    private microsoft360ViewContext: Microsoft360ViewContext,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.upn = this.route.snapshot.paramMap.get('upn');
    this.microsoft360ViewContext.state$.subscribe((ms360View: Microsoft360View) => {
      this.microsoft360View = ms360View;
      this.accountType = this.microsoft360View.accountType;
      this.isResourceAccount = this.accountType === AccountType.RESOURCE_ACCOUNT;
      this.isAccountLocked = !ms360View.microsoft365UserLicenses.accountEnabled;

      this.userPhoto = ms360View.photoBase64 ? `data:image/png;base64,${ms360View.photoBase64}` : null;
      if (this.microsoft360View?.microsoft365UserLicenses?.userPrincipalName === this.upn) {
        this.viewLoaded.emit(true);
      }

      this.badge = this.getBadge();
    });
  }

  private getBadge(): Ms360ViewBadge {
    let badge: Ms360ViewBadge;

    if (this.accountType === AccountType.RESOURCE_ACCOUNT) {
      badge = {
        badgeText: 'tkey;microsoft_360.view.contact.card.user.badge.resource.account',
        outerSpanClass: 'badge bg-secondary',
        tooltip: 'tkey;microsoft_360.view.contact.card.user.badge.resource.account.tooltip',
        tooltipClass: 'tooltip--white badge-tooltip',
        tooltipPlacement: 'top',
      };
    } else if (this.isAccountLocked) {
      badge = {
        badgeText: 'tkey;microsoft_360.view.contact.card.user.badge.account_locked',
        outerSpanClass: 'badge bg-danger',
        innerSpanClass: 'ms-1',
        iconClass: this.smacsIcons.EXCLAMATION_CIRCLE,
        tooltip: 'tkey;microsoft_360.view.contact.card.user.badge.account_locked.tooltip',
        tooltipClass: 'tooltip--white badge-tooltip',
        tooltipPlacement: 'top',
      };
    } else if (!!this.microsoft360View.onPremiseUserPrincipalName) {
      badge = {
        badgeText: this.translateService.instant('tkey;microsoft_360.view.contact.card.user.badge.on_premise_upn'),
        outerSpanClass: 'badge bg-secondary',
        innerSpanClass: 'ms-1',
        iconClass: 'fa-brands fa-windows',
      };
      if (this.upn !== this.microsoft360View.onPremiseUserPrincipalName) {
        badge = {
          ...badge,
          tooltip: this.translateService.instant(
            'tkey;microsoft_360.view.contact.card.user.badge.on_premise_upn.tooltip',
            {
              onPremUpn: this.microsoft360View.onPremiseUserPrincipalName,
            }
          ),
          tooltipClass: 'tooltip--white badge-tooltip',
          tooltipPlacement: 'top',
        };
      }
    }
    return badge;
  }
}
