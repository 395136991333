import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { forkJoin, Observable, of, Subscription, switchMap } from 'rxjs';
import { PortInDraftsResource } from '../port-in-orders/port-in-drafts.resource';
import { ModalBodyClass } from '../../../modals/modal-body';
import { SmacsFormConfig, SmacsFormsUpdate } from '../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { DetailedModalComponent } from '../../../modals/detailed-modal/detailed-modal.component';
import { SmacsSelectConfig } from '../../../forms/fields/select/smacs-select.component';
import { DocumentType, PortInOrderDraft } from '../../../shared/models/generated/smacsModels';
import { SmacsFileUploaderConfig, UploaderType, ZiroFile } from '../../../shared/file-uploader/file-uploader.component';
import { ModalViewProperties } from '../../../modals/modal-view-properties';

interface PortInDraftFileUploadEntity {
  documentType: string;
  file: ZiroFile<DocumentType>[];
}

@Component({
  selector: 'ziro-port-in-drafts-document-upload-modal',
  templateUrl: './port-in-drafts-upload-modal.component.html',
  providers: [PortInDraftsResource],
})
export class PortInDraftsUploadModalComponent extends ModalBodyClass implements OnInit, OnDestroy {
  @ViewChild('errorTemplate') errorTemplate: TemplateRef<any>;

  formConfig: SmacsFormConfig;
  modalViewProperties: ModalViewProperties;

  private _draft: PortInOrderDraft;
  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _modalComponent: DetailedModalComponent<any>,
    private _portInDraftsResource: PortInDraftsResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._draft = this._modalComponent.modalViewProperties.draft;
    this.modalViewProperties = this._modalComponent.modalViewProperties;

    this._initFormConfig();
    this.entitySource.next({
      documentType: null,
    });

    const sub = this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<PortInDraftFileUploadEntity>) => {
      if (data.new.documentType && data.new.documentType !== data.old?.documentType) {
        switch (data.new.documentType) {
          case 'LOA':
          case 'INVOICE': {
            this._rebuildFileUploader(data.new.documentType, '.pdf');
            break;
          }
          case 'CSR': {
            this._rebuildFileUploader(data.new.documentType, '.pdf,.doc,.docx,.xls,.xlsx');
            break;
          }
          case 'OTHER':
          default: {
            this._rebuildFileUploader(data.new.documentType, '');
            break;
          }
        }
      }
    });
    this._subscriptions.add(sub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  submit(): Observable<boolean> {
    const requests: Observable<any>[] = [];
    const entity: PortInDraftFileUploadEntity = this.entity;
    entity.file.forEach((file) => {
      requests.push(this._portInDraftsResource.uploadDocument(this._draft.draftId, file.metadata, file.file));
    });

    return forkJoin(requests).pipe(
      switchMap(() => {
        return of(true);
      })
    );
  }

  private _rebuildFileUploader(documentType: string, validFileTypes: string) {
    this.fieldComponents.forEach((item) => {
      if (item.fieldId === 'file') {
        item.applyComponentConfig(
          new SmacsFileUploaderConfig({
            acceptedFileExtensions: {
              acceptedExtensions: validFileTypes,
              allowOthers: validFileTypes === '',
            },
            maxSize: 3072,
            uploaderType: UploaderType.STAGED,
            maxFileNameLength: 50,
            metadataFn: () => documentType,
          })
        );
      }
    });
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        documentType: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.documents.modal.document_type.label',
          dataAutomation: 'port-in-draft-modal-document-type',
          componentConfig: new SmacsSelectConfig({
            options: [DocumentType.CSR, DocumentType.LOA, DocumentType.INVOICE, DocumentType.OTHER],
          }),
          required: true,
        },
        file: {
          required: true,
          dataAutomation: 'port-in-draft-modal-file',
          componentConfig: new SmacsFileUploaderConfig({
            acceptedFileExtensions: { acceptedExtensions: '', allowOthers: false },
            maxSize: 3072,
            uploaderType: UploaderType.STAGED,
            maxFileNameLength: 50,
          }),
          disabled: () => {
            return !this.formData.documentType;
          },
          disabledTooltip: 'tkey;admin.order_numbers.port_in_drafts_edit.documents.modal.document.tooltip',
        },
      },
    };
  }
}
