import { Injectable, Injector } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { dashboardActions } from '../actions/dashboard.actions';
import { DashboardService } from '../../services/dashboard.service';
import { CustomUiOptionsContext } from '../../../../shared/contexts/custom-ui-options.context';
import { of } from 'rxjs';
import { UiOptions } from '../../../../shared/models/generated/smacsModels';

@Injectable()
export class OptimizationTileEffect {
  loadOptimizationtile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.loadOptimizationTileAction),
      mergeMap(() =>
        this.dashboardService
          .getOptimizationTileValues()
          .pipe(map((result) => dashboardActions.loadOptimizationTileSuccessAction(result)))
      )
    )
  );
  constructor(private actions$: Actions, private dashboardService: DashboardService) {}
}

@Injectable()
export class OrphanedTileEffect {
  loadOrphanedtile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.loadOrphanedTileAction),
      mergeMap(() =>
        this.dashboardService
          .getOrphanedTileValues()
          .pipe(map((result) => dashboardActions.loadOrphanedTileSuccessAction(result)))
      )
    )
  );
  constructor(private actions$: Actions, private dashboardService: DashboardService) {}
}

@Injectable()
export class UnusedTileEffect {
  loadUnusedtile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(dashboardActions.loadUnusedTileAction),
      switchMap(() =>
        this.injector
          .get(CustomUiOptionsContext)
          .state$.pipe(switchMap((uiOptions: UiOptions) => of(uiOptions.reportingEnabled)))
      ),
      switchMap((isReportsEnabled) => this.dashboardService.getUnusedTileValues(isReportsEnabled)),
      map((result) => dashboardActions.loadUnusedTileSuccessAction(result))
    )
  );
  constructor(private actions$: Actions, private dashboardService: DashboardService, private injector: Injector) {}
}
