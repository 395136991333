import { Injectable } from '@angular/core';
import { SmacsFormsValidationState } from '../../smacs-forms-models';
import { ValidatorsService } from '../../../shared/validators.service';
import { e164ValidatorRegex } from '../../../shared/validators-regex';
import {
  CallingType,
  ClusterResult,
  DialPlanManagementGroup,
  DnDidRange,
  SiteResult,
  SiteSummary,
} from '../../../shared/models/generated/smacsModels';
import { ExtensionRange, SmacsRangeGroup } from './smacs-extension-range-models';
import { range } from 'lodash';
import { CANADIAN_AREA_CODES_2024 } from '../../../shared/models/canadian-area-codes';
import { TranslateService } from '@ngx-translate/core';

export interface ExtensionValidationResult {
  state: SmacsFormsValidationState;
  message?: string;
}

export interface ExtensionRangeValidationResult {
  start: ExtensionValidationResult;
  end: ExtensionValidationResult;
}

export interface ExtensionRangeValidationArguments<T> {
  value: T;
  maxLength: number;
  minLength: number;
  ciscoDialPlanGroups: DialPlanManagementGroup[];
  rangeType: 'DN' | 'DID';
  siteSummary: SiteSummary;
  clusterId: number;
  groups: SmacsRangeGroup[];
  zpmCallingType: boolean;
  ranges: DnDidRange[];
  existingPortInDraftNumbers: string[];
}

type ExtensionValidator = (args: ExtensionRangeValidationArguments<string>) => ExtensionValidationResult;
type RangeValidator = (args: ExtensionRangeValidationArguments<ExtensionRange>) => ExtensionRangeValidationResult;

@Injectable()
export class SmacsExtensionRangesValidationUiContext {
  private _activeExtensionValidators: ExtensionValidator[] = [];
  private _activeRangeValidators: RangeValidator[] = [];
  private _possibleExtensionValidators = {
    requiredValidator: (args: ExtensionRangeValidationArguments<string>) => {
      if (!args.value) {
        return { state: SmacsFormsValidationState.INVALID, message: 'tkey;validators.global.required.error' };
      }
      return { state: SmacsFormsValidationState.VALID };
    },
    patternValidator: (args: ExtensionRangeValidationArguments<string>) => {
      if (!/^\+?\d+$/.test(args.value)) {
        return { state: SmacsFormsValidationState.INVALID, message: 'tkey;rangeDirective.range.unsupportedpattern' };
      }
      return { state: SmacsFormsValidationState.VALID };
    },
    e164Validator: (args: ExtensionRangeValidationArguments<string>) => {
      const formattedVal = ValidatorsService.removeFormattingFromSingleNumber(args.value);

      if (!e164ValidatorRegex.test(formattedVal)) {
        return { state: SmacsFormsValidationState.INVALID, message: 'tkey;validators.global.e164.invalid.error' };
      }
      return { state: SmacsFormsValidationState.VALID };
    },
    maxLengthValidator: (args: ExtensionRangeValidationArguments<string>) => {
      const max = args.value.includes('+') ? args.maxLength + 1 : args.maxLength;

      if (args.value && args.value.length > max) {
        return {
          state: SmacsFormsValidationState.INVALID,
          message: this.translateService.instant('tkey;global.validation.max_length', { maxLength: args.maxLength }),
        };
      }

      return { state: SmacsFormsValidationState.VALID };
    },
    minLengthValidator: (args: ExtensionRangeValidationArguments<string>) => {
      const min = args.value.includes('+') ? args.minLength + 1 : args.minLength;

      if (args.value && args.value.length < min) {
        return {
          state: SmacsFormsValidationState.INVALID,
          message: this.translateService.instant('tkey;global.validation.min_length', { minLength: args.minLength }),
        };
      }

      return { state: SmacsFormsValidationState.VALID };
    },
    extensionTypePlusSignValidator: (args: ExtensionRangeValidationArguments<string>) => {
      if (args.value.includes('+') || args.value.length < 3 || args.value.length > 15) {
        return {
          state: SmacsFormsValidationState.INVALID,
          message: 'tkey;admin.msdialplan.management.single_extension_range_no_plus_and_length.validation.message',
        };
      }
      return { state: SmacsFormsValidationState.VALID };
    },
    noLeadingZeroes: (args: ExtensionRangeValidationArguments<string>) => {
      if (args.value && args.value.startsWith('0')) {
        return {
          state: SmacsFormsValidationState.INVALID,
          message: 'tkey;validators.global.no_leading_zero.invalid.error',
        };
      }
      return { state: SmacsFormsValidationState.VALID };
    },
    canadianNumbersRestricted: (args: ExtensionRangeValidationArguments<string>) => {
      if (!!args.value) {
        let value = args.value;
        if (args.value.startsWith('+1')) value = args.value.slice(2);
        if (args.value.startsWith('1')) value = args.value.slice(1);
        const strippedInputValue = value.trim().replace(/[()\-\s]/g, '');

        if (CANADIAN_AREA_CODES_2024.some((areaCode) => strippedInputValue.startsWith(areaCode))) {
          return {
            state: SmacsFormsValidationState.INVALID,
            message: 'tkey;admin.order_numbers.check_number_portability.canadian_numbers_restricted.error',
          };
        }
        return { state: SmacsFormsValidationState.VALID };
      }
    },
  };
  private _possibleRangeValidators = {
    isStartAndEndSamePlusStatus: (args: ExtensionRangeValidationArguments<ExtensionRange>) => {
      if (
        SmacsExtensionRangesValidationUiContext._isComparable(args.value.start, args.value.end) &&
        args.value.start.startsWith('+') !== args.value.end.startsWith('+')
      ) {
        return {
          start: { state: SmacsFormsValidationState.INVALID, message: 'tkey;rangeDirective.range.notsametype' },
          end: { state: SmacsFormsValidationState.INVALID, message: '' },
        };
      }
      return {
        start: { state: SmacsFormsValidationState.VALID },
        end: { state: SmacsFormsValidationState.VALID },
      };
    },
    isStartBeforeEnd: (args: ExtensionRangeValidationArguments<ExtensionRange>) => {
      if (
        SmacsExtensionRangesValidationUiContext._isComparable(args.value.start, args.value.end) &&
        Number(args.value.start) > Number(args.value.end)
      ) {
        return {
          start: { state: SmacsFormsValidationState.INVALID, message: 'tkey;rangeDirective.range.start.before.end' },
          end: { state: SmacsFormsValidationState.INVALID, message: '' },
        };
      }
      return {
        start: { state: SmacsFormsValidationState.VALID },
        end: { state: SmacsFormsValidationState.VALID },
      };
    },
    sameLengthValidator: (args: ExtensionRangeValidationArguments<ExtensionRange>) => {
      if (
        SmacsExtensionRangesValidationUiContext._isComparable(args.value.start, args.value.end) &&
        args.value.start.length !== args.value.end.length
      ) {
        return {
          start: {
            state: SmacsFormsValidationState.INVALID,
            message: 'tkey;dialplanmanagement.admin.group.range.length',
          },
          end: { state: SmacsFormsValidationState.INVALID, message: '' },
        };
      }
      return {
        start: { state: SmacsFormsValidationState.VALID },
        end: { state: SmacsFormsValidationState.VALID },
      };
    },
    maxDiffValidator: (args: ExtensionRangeValidationArguments<ExtensionRange>) => {
      const diff = parseInt(args.value.end) - parseInt(args.value.start);
      if (diff > 99999) {
        return {
          start: {
            state: SmacsFormsValidationState.INVALID,
            message: 'tkey;dialplanmanagement.admin.group.range.toolarge',
          },
          end: { state: SmacsFormsValidationState.INVALID, message: '' },
        };
      }
      return {
        start: { state: SmacsFormsValidationState.VALID },
        end: { state: SmacsFormsValidationState.VALID },
      };
    },
    overlappingRangesInOtherCiscoDialPlanGroups: (args: ExtensionRangeValidationArguments<ExtensionRange>) => {
      const overlappingGroupsDiffCluster: string[] = [];
      const overlappingGroupsSameCluster: string[] = [];

      args.ciscoDialPlanGroups.forEach((group: DialPlanManagementGroup) => {
        const ranges =
          args.rangeType === 'DN'
            ? group.directoryNumberRangesSection.dnRanges
            : group.translationPatternRangesSection.translationPatternRanges;
        ranges.forEach((otherRange: DnDidRange) => {
          if (SmacsExtensionRangesValidationUiContext._skipRangeValidation(args.value, otherRange)) {
            return;
          }

          if (
            SmacsExtensionRangesValidationUiContext._isRangeConflict(
              SmacsExtensionRangesValidationUiContext._getNumericValue(args.value.start),
              SmacsExtensionRangesValidationUiContext._getNumericValue(args.value.end),
              SmacsExtensionRangesValidationUiContext._getNumericValue(otherRange.start),
              SmacsExtensionRangesValidationUiContext._getNumericValue(otherRange.end)
            )
          ) {
            let conflictingClusterId;

            if (group.siteIds.length) {
              const matchingCluster = args.siteSummary?.clusters.find((cluster: ClusterResult) =>
                cluster.sites.find((site: SiteResult) => site.id === group.siteIds[0])
              );
              conflictingClusterId = matchingCluster ? matchingCluster.id : null;
            } else {
              conflictingClusterId = group.clusterId;
            }

            if (conflictingClusterId === args.clusterId) {
              overlappingGroupsSameCluster.push(group.name);
            } else {
              overlappingGroupsDiffCluster.push(group.name);
            }
          }
        });
      });

      if (overlappingGroupsSameCluster.length) {
        let errorMessage = this.translateService.instant('tkey;dialplanmanagement.admin.group.conflict');
        overlappingGroupsSameCluster.forEach((name) => {
          errorMessage += `<br><strong>${name}</strong>`;
        });
        return {
          start: { state: SmacsFormsValidationState.INVALID, message: errorMessage },
          end: { state: SmacsFormsValidationState.INVALID, message: '' },
        };
      } else if (overlappingGroupsDiffCluster.length) {
        let errorMessage = this.translateService.instant('tkey;dialplanmanagement.admin.group.conflict.warning');
        overlappingGroupsDiffCluster.forEach((name) => {
          errorMessage += `<br><strong>${name}</strong>`;
        });
        return {
          start: { state: SmacsFormsValidationState.WARNING, message: errorMessage },
          end: { state: SmacsFormsValidationState.WARNING, message: '' },
        };
      } else {
        return {
          start: { state: SmacsFormsValidationState.VALID },
          end: { state: SmacsFormsValidationState.VALID },
        };
      }
    },
    /**
     * Check if any ranges overlap with ranges that exist in others groups
     */
    overlappingRangesInOtherGroups: (args: ExtensionRangeValidationArguments<ExtensionRange>) => {
      if (!args.value.start || !args.value.end) {
        return {
          start: { state: SmacsFormsValidationState.VALID },
          end: { state: SmacsFormsValidationState.VALID },
        };
      }
      const overlappingGroups: string[] = [];
      args.groups.forEach((group: SmacsRangeGroup) => {
        if (group.rangeType === CallingType.DID) {
          const ranges = group.ranges;
          ranges.forEach((otherRange: DnDidRange) => {
            if (SmacsExtensionRangesValidationUiContext._skipRangeValidation(args.value, otherRange)) {
              return;
            }

            if (
              SmacsExtensionRangesValidationUiContext._isRangeConflict(
                SmacsExtensionRangesValidationUiContext._getNumericValue(args.value.start),
                SmacsExtensionRangesValidationUiContext._getNumericValue(args.value.end),
                SmacsExtensionRangesValidationUiContext._getNumericValue(otherRange.start),
                SmacsExtensionRangesValidationUiContext._getNumericValue(otherRange.end)
              )
            ) {
              overlappingGroups.push(group.name);
            }
          });
        } else if (group.rangeType === CallingType.EXTENSION) {
          if (
            SmacsExtensionRangesValidationUiContext._isRangeConflict(
              SmacsExtensionRangesValidationUiContext._getNumericValue(args.value.start),
              SmacsExtensionRangesValidationUiContext._getNumericValue(args.value.end),
              SmacsExtensionRangesValidationUiContext._getNumericValue(group.mainNumber),
              SmacsExtensionRangesValidationUiContext._getNumericValue(group.mainNumber)
            )
          ) {
            overlappingGroups.push(group.name);
          }
        }
      });
      if (!overlappingGroups.length) {
        return {
          start: { state: SmacsFormsValidationState.VALID },
          end: { state: SmacsFormsValidationState.VALID },
        };
      }
      let errorMessage = this.translateService.instant(
        'tkey;dialplanmanagement.admin.group.dnranges.group_conflict.error_message'
      );
      overlappingGroups.forEach((name) => {
        errorMessage += `<br><strong>${name}</strong>`;
      });
      return {
        start: { state: SmacsFormsValidationState.INVALID, message: errorMessage },
        end: { state: SmacsFormsValidationState.INVALID, message: '' },
      };
    },
    /**
     * Check if any ranges overlap with ranges inside the group
     * The range will always overlap with itself, so INVALID only when more than one dupe
     */
    overlappingRanges: (args: ExtensionRangeValidationArguments<ExtensionRange>) => {
      if (!args.value.start || !args.value.end) {
        return {
          start: { state: SmacsFormsValidationState.VALID },
          end: { state: SmacsFormsValidationState.VALID },
        };
      }

      const currentRange = {
        start: args.zpmCallingType
          ? ValidatorsService.removeFormattingFromSingleNumber(args.value.start)
          : args.value.start,
        end: args.zpmCallingType ? ValidatorsService.removeFormattingFromSingleNumber(args.value.end) : args.value.end,
      };
      const overlappingRanges = args.ranges.filter((otherRange: DnDidRange) => {
        const formattedRange = {
          start: args.zpmCallingType
            ? ValidatorsService.removeFormattingFromSingleNumber(otherRange.start)
            : otherRange.start,
          end: args.zpmCallingType
            ? ValidatorsService.removeFormattingFromSingleNumber(otherRange.end)
            : otherRange.end,
        };

        if (SmacsExtensionRangesValidationUiContext._skipRangeValidation(currentRange, formattedRange)) {
          return;
        }

        if (
          SmacsExtensionRangesValidationUiContext._isRangeConflict(
            SmacsExtensionRangesValidationUiContext._getNumericValue(currentRange.start),
            SmacsExtensionRangesValidationUiContext._getNumericValue(currentRange.end),
            SmacsExtensionRangesValidationUiContext._getNumericValue(formattedRange.start),
            SmacsExtensionRangesValidationUiContext._getNumericValue(formattedRange.end)
          )
        ) {
          return otherRange;
        }
      });

      if (overlappingRanges.length > 1) {
        return {
          start: {
            state: SmacsFormsValidationState.INVALID,
            message: 'tkey;dialplanmanagement.admin.group.dnranges.overlapping.error_message',
          },
          end: { state: SmacsFormsValidationState.INVALID, message: '' },
        };
      }
      return {
        start: { state: SmacsFormsValidationState.VALID },
        end: { state: SmacsFormsValidationState.VALID },
      };
    },
    overlappingRangesInExistingPortInDraft: (args: ExtensionRangeValidationArguments<ExtensionRange>) => {
      let inputValue = args.value.start;
      let injectedValue = args.value.end;
      const [unformattedInputValue, unformattedInjectedValue] = [args.value.start, args.value.end];
      let strippedInputValue: string;
      let strippedInjectedValue: string;
      if (!args.existingPortInDraftNumbers) {
        return {
          start: { state: SmacsFormsValidationState.VALID },
          end: { state: SmacsFormsValidationState.VALID },
        };
      }

      if (!!inputValue) {
        inputValue = inputValue.startsWith('+1') ? inputValue.slice(2) : inputValue;
        strippedInputValue = inputValue.trim().replace(/[()\-\s]/g, '');
      }

      if (!!injectedValue) {
        injectedValue = injectedValue.startsWith('+1') ? injectedValue.slice(2) : injectedValue;
        strippedInjectedValue = injectedValue.trim().replace(/[()\-\s]/g, '');
      }

      if (!!inputValue && !injectedValue && args.existingPortInDraftNumbers.includes(strippedInputValue)) {
        let errorMessage = this.translateService.instant(
          'tkey;admin.order_numbers.check_number_portability.number_in_existing_draft.error'
        );
        errorMessage += `<br><strong>${unformattedInputValue}</strong>`;
        return {
          start: { state: SmacsFormsValidationState.INVALID, message: errorMessage },
          end: { state: SmacsFormsValidationState.INVALID, message: '' },
        };
      }

      if (!!injectedValue && !inputValue && args.existingPortInDraftNumbers.includes(strippedInjectedValue)) {
        let errorMessage = this.translateService.instant(
          'tkey;admin.order_numbers.check_number_portability.number_in_existing_draft.error'
        );
        errorMessage += `<br><strong>${unformattedInputValue}</strong>`;
        return {
          start: { state: SmacsFormsValidationState.INVALID, message: errorMessage },
          end: { state: SmacsFormsValidationState.INVALID, message: '' },
        };
      }

      if (!!inputValue && !!injectedValue) {
        let isValid: boolean;
        const [lowerRange, upperRange] = [strippedInputValue, strippedInjectedValue].sort();
        const [lowerRangeNum, upperRangeNum] = [parseInt(lowerRange), parseInt(upperRange)];

        const BATCH_SIZE = 100_000;
        let tempUpperRange = lowerRangeNum + BATCH_SIZE;
        let tempLowerRange = lowerRangeNum;

        isValid =
          !SmacsExtensionRangesValidationUiContext._isComparable(lowerRange, upperRange) ||
          Number(lowerRange) <= Number(upperRange);

        while (upperRangeNum > tempUpperRange) {
          if (!isValid) break;
          const nums = range(tempLowerRange, tempUpperRange + 1, 1);
          isValid = !nums.some((num) => args.existingPortInDraftNumbers.includes(num.toString()));
          tempUpperRange += BATCH_SIZE;
          tempLowerRange = tempUpperRange;
        }

        if (upperRangeNum <= tempUpperRange) {
          const nums = range(tempLowerRange, upperRangeNum + 1, 1);
          isValid = !nums.some((num) => args.existingPortInDraftNumbers.includes(num.toString()));
        }

        if (!isValid) {
          let errorMessage = this.translateService.instant(
            'tkey;admin.order_numbers.check_number_portability.number_in_existing_draft.error'
          );
          if (strippedInputValue > strippedInjectedValue) {
            errorMessage += `<br><strong>${unformattedInjectedValue}-${unformattedInputValue}</strong>`;
          } else {
            errorMessage += `<br><strong>${unformattedInputValue}-${unformattedInjectedValue}</strong>`;
          }
          return {
            start: { state: SmacsFormsValidationState.INVALID, message: errorMessage },
            end: { state: SmacsFormsValidationState.INVALID, message: '' },
          };
        }
        return {
          start: { state: SmacsFormsValidationState.VALID },
          end: { state: SmacsFormsValidationState.VALID },
        };
      }
    },
  };

  constructor(private translateService: TranslateService) {}

  addValidator(
    name: keyof typeof this._possibleExtensionValidators | keyof typeof this._possibleRangeValidators
  ): void {
    if (this._isKeyofExtensionValidator(name)) {
      const extensionValidator = this._possibleExtensionValidators[name];
      this._activeExtensionValidators.push(extensionValidator);
    } else if (this._isKeyofRangeValidator(name)) {
      const rangeValidator = this._possibleRangeValidators[name];
      this._activeRangeValidators.push(rangeValidator);
    } else {
      throw new Error(`Could not find validator with name [${name}]`);
    }
  }

  reset(): void {
    this._activeExtensionValidators = [];
    this._activeRangeValidators = [];
  }

  validate(args: ExtensionRangeValidationArguments<ExtensionRange>): ExtensionRangeValidationResult {
    const startValidationState = this._validateExtension({ ...args, value: args.value.start });
    const endValidationState = this._validateExtension({ ...args, value: args.value.end });
    if (
      startValidationState.state === SmacsFormsValidationState.INVALID ||
      endValidationState.state === SmacsFormsValidationState.INVALID
    ) {
      return { start: startValidationState, end: endValidationState };
    }

    return this._validateRange(args);
  }

  private _validateExtension(args: ExtensionRangeValidationArguments<string>): ExtensionValidationResult {
    let warningState: ExtensionValidationResult;
    for (const validator of this._activeExtensionValidators) {
      const result = validator(args);
      if (result.state === SmacsFormsValidationState.INVALID) {
        return result;
      }
      if (result.state === SmacsFormsValidationState.WARNING) {
        warningState = result;
      }
    }
    return warningState || { state: SmacsFormsValidationState.VALID };
  }

  private _validateRange(args: ExtensionRangeValidationArguments<ExtensionRange>): ExtensionRangeValidationResult {
    let warningState: ExtensionRangeValidationResult;
    for (const validator of this._activeRangeValidators) {
      const result = validator(args);
      if (result.start.state === SmacsFormsValidationState.INVALID) {
        return result;
      }
      if (result.start.state === SmacsFormsValidationState.WARNING) {
        warningState = result;
      }
    }
    const validState: ExtensionValidationResult = { state: SmacsFormsValidationState.VALID };
    return warningState || { start: validState, end: validState };
  }

  private _isKeyofExtensionValidator(
    validatorName: string
  ): validatorName is keyof typeof this._possibleExtensionValidators {
    return !!this._possibleExtensionValidators[validatorName as keyof typeof this._possibleExtensionValidators];
  }
  private _isKeyofRangeValidator(validatorName: string): validatorName is keyof typeof this._possibleRangeValidators {
    return !!this._possibleRangeValidators[validatorName as keyof typeof this._possibleRangeValidators];
  }

  private static _isComparable = (start: string, end: string): boolean =>
    !(start === '' || end === '' || isNaN(Number(start)) || isNaN(Number(end)));

  private static _getNumericValue(value: string): number {
    // note that this automatically strips out any leading zeros, so "000123" return number 123.
    return Number(value.replace(/\D/g, ''));
  }

  private static _isRangeConflict(
    sourceStart: number,
    sourceEnd: number,
    targetStart: number,
    targetEnd: number
  ): boolean {
    return sourceStart <= targetEnd && targetStart <= sourceEnd;
  }

  private static _isOnlyOneRangePlusValue(sourceRange: DnDidRange, targetRange: DnDidRange): boolean {
    const sourceIsPlusValue = sourceRange.start.startsWith('+') || sourceRange.end.startsWith('+');
    const targetIsPlusValue = targetRange.start.startsWith('+') || targetRange.end.startsWith('+');
    return sourceIsPlusValue !== targetIsPlusValue;
  }

  private static _skipRangeValidation(sourceRange: DnDidRange, targetRange: DnDidRange): boolean {
    if (
      !targetRange.start ||
      !targetRange.end ||
      SmacsExtensionRangesValidationUiContext._isOnlyOneRangePlusValue(sourceRange, targetRange) ||
      sourceRange.start.length !== targetRange.start.length
    ) {
      return true;
    }
    return false;
  }
}
