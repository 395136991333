import { Injectable } from '@angular/core';
import { uniq } from 'lodash';
import { DeviceEnum, deviceEnumToName } from '../models/all-devices';
import { DevicePoolResult, SiteResult } from '../models/generated/smacsModels';

@Injectable()
export class SiteDeductionService {
  deduceSite = (devicePool: string, sites?: SiteResult[], deviceType?: DeviceEnum): SiteResult[] => {
    return sites.filter((site: SiteResult) => {
      return this._buildUniqueDevicePoolListFromSite(site, deviceType).includes(devicePool);
    });
  };

  private _buildUniqueDevicePoolListFromSite = (site: SiteResult, deviceType?: DeviceEnum): string[] => {
    return uniq(
      site.devicePools
        .filter(
          (d: DevicePoolResult) => !(deviceType || deviceType === 0) || d.serviceName === deviceEnumToName[deviceType]
        )
        .map((d: DevicePoolResult) => d.devicePool)
    );
  };
}
