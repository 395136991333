import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuditHeaderService } from '../../shared/services/audit-header.service';
import { Nvp } from '../../shared/models/nvp';
import { Observable } from 'rxjs';

@Injectable()
export class EndUserPinResetResource {
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  // resolve 200 when pin reset is successful (response.data = EndUserPinResetStatusJson)
  // reject 422 when pin reset is not successful (response.data = EndUserPinResetStatusJson)
  resetPin(
    cucmServerId: string,
    endUserId: string,
    pin: string,
    resetPinOnNextLogin = false,
    auditTags: Nvp[] = []
  ): Observable<void> {
    const auditTagHeader = this.auditHeaderService.buildHeader(auditTags);
    return this.http.post<void>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(cucmServerId)}/end-users/${encodeURIComponent(
        endUserId
      )}/pin-reset`,
      {
        pin,
        resetOnNextLogin: resetPinOnNextLogin,
      },
      {
        headers: auditTagHeader,
      }
    );
  }
}
