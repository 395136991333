import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuditReportQueries, AuditReportQuery } from '../../shared/models/generated/smacsModels';

@Injectable()
export class AuditReportQueryResource {
  protected _baseUrl = `/services/system/admin/audit-queries`;

  constructor(private http: HttpClient) {}

  getAllAuditQueries(): Observable<AuditReportQueries> {
    return this.http.get<AuditReportQueries>(this._baseUrl);
  }

  saveAuditQuery(auditQueryJson: AuditReportQuery): Observable<AuditReportQueries> {
    if (auditQueryJson.id) {
      return this.http.put<AuditReportQueries>(`${this._baseUrl}/${auditQueryJson.id}`, auditQueryJson);
    } else {
      return this.http.post<AuditReportQueries>(this._baseUrl, auditQueryJson);
    }
  }

  deleteAuditQuery(auditQueryJson: AuditReportQuery): Observable<void> {
    return this.http.delete<void>(`${this._baseUrl}/${auditQueryJson.id}`);
  }

  validateAuditQuery(auditQueryJson: AuditReportQuery): Observable<AuditReportQueries> {
    return this.http.post<AuditReportQueries>(`${this._baseUrl}/validate`, auditQueryJson);
  }
}
