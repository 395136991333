<span
  class="badge me-1 d-inline-flex gap-1 mw-100"
  [ngClass]="getCssClass()"
  [ngbTooltip]="tooltip"
  [disableTooltip]="!isTooltipDisplayed || !tooltip"
  [container]="'body'"
>
  <i *ngIf="!!badgeIcon" [ngClass]="badgeIcon"></i>
  <span style="text-overflow: ellipsis; overflow: hidden">{{ displayValue }}</span>
</span>
