import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { WebexDialPlanInventory } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class WebexCallingDialPlanInventoriesResource {
  constructor(private http: HttpClient) {}

  get(locationId: string): Observable<WebexDialPlanInventory> {
    return this.http.get<WebexDialPlanInventory>(
      `/services/cisco/views/webex-calling-dial-plan-inventories/v2/${locationId}`
    );
  }
}
