import { Routes } from '@angular/router';
import { ProvisioningJobsViewComponent } from '../../../../components/src/lib/automate/cisco/provisioning/jobs-view/provisioning-jobs-view.component';
import { smacsFormConfirmRouteChangeGuardFn } from '../../../../components/src/lib/forms/smacs-form-confirm-route-change.service';
import { routeGuardFn } from '../../../../components/src/lib/shared/services/route-guard.service';
import { ProvisioningEditJobComponent } from '../../../../components/src/lib/automate/cisco/provisioning/edit-job/provisioning-edit-job.component';
import { DeprovisioningJobsViewComponent } from '../../../../components/src/lib/automate/cisco/deprovisioning/jobs-view/deprovisioning-jobs-view.component';
import { DeprovisioningEditJobComponent } from '../../../../components/src/lib/automate/cisco/deprovisioning/edit-job/deprovisioning-edit-job.component';
import { ZiroMsProvisioningJobsListComponent } from '../../../../components/src/lib/automate/microsoft/provisioning/jobs-list/ziro-ms-provisioning-jobs-list.component';
import { ZiroMsProvisioningEditJobComponent } from '../../../../components/src/lib/automate/microsoft/provisioning/edit-job/ziro-ms-provisioning-edit-job.component';
import { LdapSyncComponent } from '../../../../components/src/lib/automate/cisco/ldap-sync/ldap-sync.component';
import { ZeroTouchPageComponent } from '../../../../components/src/lib/automate/shared/page/zero-touch-page.component';
import { BulkProvisioningComponent } from '../../../../components/src/lib/automate/cisco/bulk-provisioning/bulk-provisioning.component';
import { MicrosoftBulkProvisioningContainerComponent } from '../../../../components/src/lib/automate/microsoft/bulk-provisioning/microsoft-bulk-provisioning-container.component';
import { MicrosoftBulkProvisioningComponent } from '../../../../components/src/lib/automate/microsoft/bulk-provisioning/microsoft-bulk-provisioning.component';
import { ZpmBulkProvisioningPreviousJobComponent } from '../../../../components/src/lib/automate/microsoft/bulk-provisioning/previous-job/zpm-bulk-provisioning-previous-job.component';
import { AboutRestComponent } from '../../../../components/src/lib/automate/about-rest/about-rest.component';
import { SamlComponent } from '../../../../components/src/lib/admin/security-and-permissions/saml/saml.component';
import { ZeroTouchComponent } from '../../../../components/src/lib/automate/zero-touch.component';
import { WebexAuthenticationComponent } from '../../../../components/src/lib/admin/cisco/webex-authentication/webex-authentication.component';
import { CiscoGeneralSettingsComponent } from '../../../../components/src/lib/admin/system/cisco-general-settings/cisco-general-settings.component';
import { MicrosoftGeneralSettingsComponent } from '../../../../components/src/lib/admin/microsoft/general-settings/microsoft-general-settings.component';
import { DeviceUtilizationReportSettingsComponent } from '../../../../components/src/lib/admin/cisco/device-utilization-report-settings/device-utilization-report-settings.component';
import { AuditLogsComponent } from '../../../../components/src/lib/admin/audit/audit-logs/audit-logs.component';
import { AuditSettingsComponent } from '../../../../components/src/lib/admin/audit/audit-settings/audit-settings.component';
import { DialPlanExceptionManagementComponent } from '../../../../components/src/lib/admin/cisco/dial-plan-exception-management/dial-plan-exception-management.component';
import { AdminLdapManagementComponent } from '../../../../components/src/lib/admin/system/ldap-management/admin-ldap-management.component';
import { CustomizeUiComponent } from '../../../../components/src/lib/admin/system/customize-ui/customize-ui.component';
import { CdrDumpFileImportComponent } from '../../../../components/src/lib/admin/cisco/cdr-dump-file-import/cdr-dump-file-import.component';
import { AdminLicenseComponent } from '../../../../components/src/lib/admin/system/license/admin-license.component';
import { BackupsComponent } from '../../../../components/src/lib/admin/disaster-recovery/backups/backups.component';
import { ImportConfigurationComponent } from '../../../../components/src/lib/admin/disaster-recovery/import-configuration/import-configuration.component';
import { ImportAuditsComponent } from '../../../../components/src/lib/admin/disaster-recovery/import-audits/import-audits.component';
import { ImportCallRecordsComponent } from '../../../../components/src/lib/admin/disaster-recovery/import-call-records/import-call-records.component';
import { CertificateManagementComponent } from '../../../../components/src/lib/admin/system/certificate-management/certificate-management.component';
import { AdminServersComponent } from '../../../../components/src/lib/admin/cisco/server-management/servers.component';
import { BillingProductsSummaryViewComponent } from '../../../../components/src/lib/admin/billing/billing-products/summary-view/billing-products-summary-view.component';
import { BillingProductsDetailedViewComponent } from '../../../../components/src/lib/admin/billing/billing-products/detailed-view/billing-products-detailed-view.component';
import { GraphManagementComponent } from '../../../../components/src/lib/admin/microsoft/graph-management/graph-management.component';
import { DefaultPhoneButtonTemplatesComponent } from '../../../../components/src/lib/admin/cisco/default-phone-button-templates/default-phone-button-templates.component';
import { ApplicationLogsComponent } from '../../../../components/src/lib/admin/system/application-logs/application-logs.component';
import { SiteManagementComponent } from '../../../../components/src/lib/admin/cisco/site-management/site-management.component';
import { ClustersListComponent } from '../../../../components/src/lib/admin/cisco/site-management/clusters/clusters-list.component';
import { ClusterComponent } from '../../../../components/src/lib/admin/cisco/site-management/clusters/cluster/cluster.component';
import { SitesListComponent } from '../../../../components/src/lib/admin/cisco/site-management/sites/sites-list.component';
import { SiteBulkFieldCopyComponent } from '../../../../components/src/lib/admin/cisco/site-management/sites/site-bulk-field-copy/site-bulk-field-copy.component';
import { SiteComponent } from '../../../../components/src/lib/admin/cisco/site-management/sites/site/site.component';
import { AdminEmailConfigurationComponent } from '../../../../components/src/lib/admin/system/email-configuration/admin-email-configuration.component';
import { SupportComponent } from '../../../../components/src/lib/shared/support/support.component';
import { SystemHealthSettingsNotificationsComponent } from '../../../../components/src/lib/admin/system-health/system-health-settings-notifications/system-health-settings-notifications.component';
import { DialPlanManagementComponent } from '../../../../components/src/lib/admin/microsoft/dial-plan-management/dial-plan-management.component';
import { DialPlanGroupListComponent } from '../../../../components/src/lib/admin/microsoft/dial-plan-management/dial-plan-group-list/dial-plan-group-list.component';
import { MsDialPlanGroupManagementComponent } from '../../../../components/src/lib/admin/microsoft/dial-plan-management/dial-plan-group-management/ms-dial-plan-group-management.component';
import { HighAvailabilityManagementComponent } from '../../../../components/src/lib/admin/high-availability-management/high-availability-management.component';
import { MicrosoftDialPlanExceptionManagementListComponent } from '../../../../components/src/lib/admin/microsoft/dial-plan-exception-management/dial-plan-exception-management-list/dial-plan-exception-management-list.component';
import { MicrosoftDialPlanExceptionManagementEditComponent } from '../../../../components/src/lib/admin/microsoft/dial-plan-exception-management/dial-plan-exception-management-edit/dial-plan-exception-management-edit.component';
import { MicrosoftLicenseManagementComponent } from '../../../../components/src/lib/admin/microsoft/license-management/license-management.component';
import { SecurityGroupManagementComponent } from '../../../../components/src/lib/admin/microsoft/security-group-management/security-group-management.component';
import { BandwidthManagementComponent } from '../../../../components/src/lib/admin/microsoft/ziro-support-settings/bandwidth-management/bandwidth-management.component';
import { ZiroSupportSettingsComponent } from '../../../../components/src/lib/admin/microsoft/ziro-support-settings/ziro-general-support-settings/ziro-support-settings.component';
import { DlrMisconfigurationsComponent } from '../../../../components/src/lib/admin/microsoft/ziro-support-settings/dlr-misconfigurations/dlr-misconfigurations.component';
import { SbcNumberConfigurationsComponent } from '../../../../components/src/lib/admin/microsoft/ziro-support-settings/sbc-number-configurations/sbc-number-configurations.component';
import { DubberAccountManagementComponent } from '../../../../components/src/lib/admin/microsoft/ziro-support-settings/dubber-account-management/dubber-account-management.component';
import { CiscoDialPlanManagementListComponent } from '../../../../components/src/lib/admin/cisco/dial-plan-mangement/dial-plan-mangement-list/dial-plan-management-list.component';
import { CiscoDialPlanManagementEditComponent } from '../../../../components/src/lib/admin/cisco/dial-plan-mangement/dial-plan-mangement-edit/dial-plan-management-edit.component';
import { EmailTemplatesEntityTableComponent } from '../../../../components/src/lib/admin/cisco/email-templates/email-templates-entity-table/email-templates-entity-table.component';
import { EmailTemplateEditComponent } from '../../../../components/src/lib/admin/cisco/email-templates/email-template-edit/email-template-edit.component';
import { SystemStatusComponent } from '../../../../components/src/lib/system-status/system-status.component';
import { UserPermissionsComponent } from '../../../../components/src/lib/admin/security-and-permissions/user-permissions/user-permissions.component';
import { Oauth2ResourceServerManagementComponent } from '../../../../components/src/lib/admin/security-and-permissions/oauth2-resource-server-settings/oauth2-resource-server-management.component';
import { SiteBasedHelpdeskUserPermissionsManagementComponent } from '../../../../components/src/lib/admin/security-and-permissions/site-based-helpdesk-user-permissions-management/site-based-helpdesk-user-permissions-management.component';
import { CheckNumberPortabilityComponent } from '../../../../components/src/lib/admin/order-numbers/check-number-portability/check-number-portability.component';
import { CreateDraftComponent } from '../../../../components/src/lib/admin/order-numbers/create-draft/create-draft.component';
import { OrderViewAndEditContainerComponent } from '../../../../components/src/lib/admin/order-numbers/order-view-and-edit/order-view-and-edit-container.component';
import { PortInOrdersComponent } from '../../../../components/src/lib/admin/order-numbers/port-in-orders/port-in-orders.component';
import { PortInNumberStatusComponent } from '../../../../components/src/lib/admin/order-numbers/port-in-number-status/port-in-number-status.component';
import { GenerateKeyComponent } from '../../../../components/src/lib/admin/proxy-server-management/generate-key/generate-key.component';
import { ConnectionSettingsComponent } from '../../../../components/src/lib/admin/proxy-server-management/connection-settings/connection-settings.component';
import { ProxyFeatureSettingsComponent } from '../../../../components/src/lib/admin/proxy-server-management/proxy-feature-settings/proxy-feature-settings.component';
import { MicrosoftBulkProvisioningDraftsListComponent } from '../../../../components/src/lib/automate/microsoft/bulk-provisioning/drafts/bulk-provisioning-drafts-list.component';
import { MicrosoftBulkProvisioningInProgressComponent } from '../../../../components/src/lib/automate/microsoft/bulk-provisioning/in-progress/bulk-provisioning-in-progress.component';

const adminZeroTouchCiscoProvisioningChildrenConfig = [
  {
    path: '',
    component: ProvisioningJobsViewComponent,
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.provisioning.title',
    },
  },
  {
    path: 'edit-job',
    component: ProvisioningEditJobComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.provisioning.title',
    },
  },
  {
    path: 'edit-job/:jobId',
    component: ProvisioningEditJobComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.provisioning.title',
    },
  },
];

const adminZeroTouchDeprovisioningChildrenConfig = [
  {
    path: '',
    component: DeprovisioningJobsViewComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.deprovisioning.title',
    },
  },
  {
    path: 'edit-job',
    component: DeprovisioningEditJobComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.deprovisioning.title',
    },
  },
  {
    path: 'edit-job/:jobId',
    component: DeprovisioningEditJobComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.deprovisioning.title',
    },
  },
];

const adminZeroTouchMsProvisioningChildrenConfig = [
  {
    path: '',
    component: ZiroMsProvisioningJobsListComponent,
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.microsoft.provisioning.title',
    },
  },
  {
    path: 'edit-job',
    component: ZiroMsProvisioningEditJobComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.microsoft.provisioning.title',
    },
  },
  {
    path: 'edit-job/:jobId',
    component: ZiroMsProvisioningEditJobComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.microsoft.provisioning.title',
    },
  },
];

const adminZeroTouchChildrenConfig: Routes = [
  { path: '', redirectTo: 'ldap-sync', pathMatch: 'full' },
  {
    path: 'ldap-sync',
    component: LdapSyncComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.ldap_sync.title',
    },
  },
  {
    path: 'provisioning',
    component: ZeroTouchPageComponent,
    canActivate: [routeGuardFn],
    children: adminZeroTouchCiscoProvisioningChildrenConfig,
    data: {
      title: 'tkey;pages.zero_touch.provisioning.title',
    },
  },
  {
    path: 'deprovisioning',
    component: ZeroTouchPageComponent,
    canActivate: [routeGuardFn],
    children: adminZeroTouchDeprovisioningChildrenConfig,
    data: {
      title: 'tkey;pages.zero_touch.deprovisioning.title',
    },
  },
  {
    path: 'bulk-provisioning',
    component: BulkProvisioningComponent,
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.bulk_provisioning.title',
    },
  },
  {
    path: 'microsoft/bulk-provisioning',
    component: MicrosoftBulkProvisioningContainerComponent,
    children: [
      {
        path: '',
        component: MicrosoftBulkProvisioningDraftsListComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;automate.zpm.bulk_provisioning.drafts.title',
        },
      },
      {
        path: 'in-progress',
        component: MicrosoftBulkProvisioningInProgressComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;automate.zpm.bulk_provisioning.drafts.title',
        },
      },
      {
        path: 'previous-job',
        component: ZpmBulkProvisioningPreviousJobComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;automate.zpm.bulk_provisioning.previous_job.title',
        },
      },
      {
        path: 'new',
        component: MicrosoftBulkProvisioningComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;automate.zpm.bulk_provisioning.title',
        },
      },
      {
        path: ':jobId',
        component: MicrosoftBulkProvisioningComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;automate.zpm.bulk_provisioning.title',
        },
      },
    ],
  },
  {
    path: 'about-rest',
    component: AboutRestComponent,
    data: {
      title: 'tkey;pages.zero_touch.about_rest.title',
    },
  },
  // Dummy Route so the REST API link shows the 'No Zero-Touch License Warning' when Zero-Touch License is not present:
  {
    path: 'open-api',
    component: AboutRestComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.rest.title',
    },
  },
  {
    path: 'saml-sso',
    component: SamlComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;menu.admin.saml',
    },
  },
  {
    path: 'microsoft/zero-touch-provisioning',
    component: ZeroTouchPageComponent,
    children: adminZeroTouchMsProvisioningChildrenConfig,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.zero_touch.microsoft.provisioning.title',
    },
  },
];

export const adminZeroTouchRouteConfig = {
  path: 'automate',
  component: ZeroTouchComponent,
  canActivate: [routeGuardFn],
  canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
  children: adminZeroTouchChildrenConfig,
};

export const adminRouteChildrenConfig: Routes = [
  { path: '', redirectTo: 'audits/logs', pathMatch: 'full' },
  {
    path: 'global-config',
    redirectTo: 'system/cisco-general-settings',
  },
  {
    path: 'webex-authentication',
    component: WebexAuthenticationComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.webex_authentication.title',
    },
  },
  {
    path: 'system/cisco-general-settings',
    component: CiscoGeneralSettingsComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.system.cisco_general_settings.title',
    },
  },
  {
    path: 'system/microsoft-general-settings',
    component: MicrosoftGeneralSettingsComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.microsoft.general_settings.title',
    },
  },
  {
    path: 'device-utilization-report-settings',
    component: DeviceUtilizationReportSettingsComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.device.utilization.settings.header',
    },
  },
  { path: 'audit-search', redirectTo: 'audits/logs' },
  {
    path: 'audits/logs',
    component: AuditLogsComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.audits.logs.title',
    },
  },
  {
    path: 'audits/settings',
    component: AuditSettingsComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.audits.settings.title',
    },
  },
  {
    path: 'dn-exception-management',
    component: DialPlanExceptionManagementComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;dnexception.admin.title',
    },
  },
  {
    path: 'ldap-management',
    component: AdminLdapManagementComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.ldap.title',
    },
  },
  { path: 'ldap', redirectTo: 'ldap-management', pathMatch: 'full' },
  {
    path: 'ui',
    component: CustomizeUiComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;menu.admin.ui',
    },
  },
  {
    path: 'manual-cdr-import',
    component: CdrDumpFileImportComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;menu.admin.manual_cdr_import',
    },
  },
  {
    path: 'license',
    component: AdminLicenseComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;menu.admin.license',
    },
  },
  {
    path: 'backup',
    component: BackupsComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;admin.backup.title',
    },
  },
  {
    path: 'db-import',
    component: ImportConfigurationComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.admin.import.title',
    },
  },
  {
    path: 'audit-import',
    component: ImportAuditsComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.admin.audit.import.title',
    },
  },
  {
    path: 'cdr-import',
    component: ImportCallRecordsComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.admin.cdr.import.title',
    },
  },
  {
    path: 'site-mac-management',
    redirectTo: 'system/site-based-helpdesk-user-permissions-management',
    pathMatch: 'full',
  },
  {
    path: 'certificate-management',
    component: CertificateManagementComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.certificate_management.title',
    },
  },
  {
    path: 'servers',
    component: AdminServersComponent,
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.servers.config.title',
    },
  },
  {
    path: 'billing',
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;admin.billing.title',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'ziro-support-settings/bandwidth-dlr-misconfigurations',
      },
      {
        path: 'bandwidth-dlr-misconfigurations',
        redirectTo: '/admin/ziro-support-settings/bandwidth-dlr-misconfigurations',
      },
      {
        path: 'sbc-number-configurations',
        redirectTo: '/admin/ziro-support-settings/sbc-number-configurations',
      },
      {
        path: 'your-products',
        pathMatch: 'full',
        redirectTo: 'subscriptions',
      },
      {
        path: 'subscriptions',
        component: BillingProductsSummaryViewComponent,
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;admin.billing.products.title',
        },
      },
      {
        path: 'subscriptions/detailed-usage/:productId',
        component: BillingProductsDetailedViewComponent,
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;admin.billing.products.title',
        },
      },
    ],
  },
  {
    path: 'microsoft/graph-management',
    component: GraphManagementComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.msgraph.management.config.title',
    },
  },
  {
    path: 'microsoft/bandwidth-management',
    redirectTo: '/admin/ziro-support-settings/bandwidth-management',
  },
  {
    path: 'microsoft/dubber-account-management',
    redirectTo: '/admin/ziro-support-settings/dubber-account-management',
  },
  {
    path: 'phone-button-templates',
    component: DefaultPhoneButtonTemplatesComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.phone_button_templates.title',
    },
  },
  {
    path: 'application-logs',
    component: ApplicationLogsComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;menu.admin.application_logs',
    },
  },
  {
    path: 'site-management',
    canActivate: [routeGuardFn],
    component: SiteManagementComponent,
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'clusters' },
      {
        path: 'clusters',
        canActivate: [routeGuardFn],
        children: [
          {
            path: '',
            pathMatch: 'full',
            canActivate: [routeGuardFn],
            component: ClustersListComponent,
            data: {
              title: 'tkey;admin.site_management.title',
            },
          },
          {
            path: 'new',
            canActivate: [routeGuardFn],
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
            component: ClusterComponent,
            data: {
              title: 'tkey;admin.site_management.title',
            },
          },
          {
            path: ':clusterId',
            canActivate: [routeGuardFn],
            children: [
              {
                path: '',
                pathMatch: 'full',
                canActivate: [routeGuardFn],
                canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
                component: ClusterComponent,
                data: {
                  title: 'tkey;admin.site_management.title',
                },
              },
              {
                path: 'sites',
                canActivate: [routeGuardFn],
                children: [
                  {
                    path: '',
                    pathMatch: 'full',
                    canActivate: [routeGuardFn],
                    component: SitesListComponent,
                    data: {
                      title: 'tkey;admin.site_management.title',
                    },
                  },
                  {
                    path: 'bulk-copy',
                    canActivate: [routeGuardFn],
                    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
                    component: SiteBulkFieldCopyComponent,
                    data: {
                      title: 'tkey;admin.site_management.title',
                    },
                  },
                  {
                    path: 'new',
                    canActivate: [routeGuardFn],
                    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
                    component: SiteComponent,
                    data: {
                      title: 'tkey;admin.site_management.title',
                    },
                  },
                  {
                    path: ':siteId',
                    canActivate: [routeGuardFn],
                    children: [
                      {
                        path: '',
                        pathMatch: 'full',
                        canActivate: [routeGuardFn],
                        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
                        component: SiteComponent,
                        data: {
                          title: 'tkey;admin.site_management.title',
                        },
                      },
                    ],
                  },
                  {
                    path: ':siteId/copy',
                    canActivate: [routeGuardFn],
                    children: [
                      {
                        path: '',
                        pathMatch: 'full',
                        canActivate: [routeGuardFn],
                        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
                        component: SiteComponent,
                        data: {
                          title: 'tkey;admin.site_management.title',
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'email-configuration',
    component: AdminEmailConfigurationComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;admin.email_configuration.title',
    },
  },
  {
    path: 'support',
    component: SupportComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;support.title',
    },
  },
  {
    path: 'system-health-status-notifications',
    redirectTo: 'system-health-settings-and-notifications',
  },
  {
    path: 'system-health-settings-and-notifications',
    component: SystemHealthSettingsNotificationsComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;admin.system_health_status_notifications.title',
    },
  },
  {
    path: 'microsoft/dial-plan-management',
    component: DialPlanManagementComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    children: [
      {
        path: '',
        component: DialPlanGroupListComponent,
        data: {
          title: 'tkey;admin.msdialplan.management.title',
        },
      },
      {
        path: 'new-group',
        component: MsDialPlanGroupManagementComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.msdialplan.management.group_add.title',
        },
      },
      {
        path: ':groupId',
        component: MsDialPlanGroupManagementComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.msdialplan.management.group_edit.title',
        },
      },
      {
        path: ':groupId/copy',
        component: MsDialPlanGroupManagementComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.msdialplan.management.group_copy.title',
        },
      },
    ],
  },
  {
    path: 'high-availability-management',
    component: HighAvailabilityManagementComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;pages.admin.high.availability.management.title',
    },
  },
  // Microsoft
  {
    path: 'microsoft/dial-plan-exception-management',
    canActivate: [routeGuardFn],
    children: [
      {
        path: '',
        component: MicrosoftDialPlanExceptionManagementListComponent,
        data: {
          title: 'tkey;admin.ms_dial_plan_exception.title',
        },
      },
      {
        path: 'dial-plan-exception-group',
        component: MicrosoftDialPlanExceptionManagementEditComponent,
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;admin.ms_dial_plan_exception.title',
        },
      },
      {
        path: 'dial-plan-exception-group/:id',
        component: MicrosoftDialPlanExceptionManagementEditComponent,
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;admin.ms_dial_plan_exception.title',
        },
      },
    ],
  },
  {
    path: 'microsoft/license-management',
    component: MicrosoftLicenseManagementComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;admin.microsoft.license_management.title',
    },
  },
  {
    path: 'microsoft/security-group-management',
    component: SecurityGroupManagementComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;admin.microsoft.security_group_management.title',
    },
  },
  {
    path: 'microsoft/general-ziro-support-settings',
    redirectTo: '/admin/ziro-support-settings/general-ziro-support-settings',
  },
  {
    path: 'ziro-support-settings',
    canActivate: [routeGuardFn],
    children: [
      {
        path: 'application-logs',
        component: ApplicationLogsComponent,
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;menu.admin.application_logs',
        },
      },
      {
        path: 'general-ziro-support-settings',
        component: ZiroSupportSettingsComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.microsoft.general_ziro_support_settings.title',
        },
      },
      {
        path: 'bandwidth-management',
        component: BandwidthManagementComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.bandwidth_account_settings.title',
        },
      },
      {
        path: 'bandwidth-dlr-misconfigurations',
        canActivate: [routeGuardFn],
        component: DlrMisconfigurationsComponent,
        data: {
          title: 'tkey;admin.billing.dlr_misconfigurations.title',
        },
      },
      {
        path: 'sbc-number-configurations',
        canActivate: [routeGuardFn],
        component: SbcNumberConfigurationsComponent,
        data: {
          title: 'tkey;admin.billing.sbc_numbers_config.title',
        },
      },
      {
        path: 'dubber-account-management',
        component: DubberAccountManagementComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.microsoft.dubber_management.title',
        },
      },
      {
        path: 'backup',
        component: BackupsComponent,
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;admin.backup.title',
        },
      },
      {
        path: 'db-import',
        component: ImportConfigurationComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;pages.admin.import.title',
        },
      },
      {
        path: 'audit-import',
        component: ImportAuditsComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;pages.admin.audit.import.title',
        },
      },
    ],
  },

  // Cisco
  {
    path: 'cisco/dial-plan-management',
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    children: [
      {
        path: '',
        component: CiscoDialPlanManagementListComponent,
        data: {
          title: 'tkey;dialplanmanagement.title',
        },
      },
      {
        path: 'dial-plan-group',
        component: CiscoDialPlanManagementEditComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;dialplanmanagement.title',
        },
      },
      {
        path: 'dial-plan-group/:id',
        component: CiscoDialPlanManagementEditComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;dialplanmanagement.title',
        },
      },
      {
        path: 'dial-plan-group/:id/copy',
        component: CiscoDialPlanManagementEditComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;dialplanmanagement.title',
        },
      },
    ],
  },
  {
    path: 'cisco/email-templates',
    children: [
      {
        path: '',
        component: EmailTemplatesEntityTableComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.email_templates.title',
        },
      },
      {
        path: 'template',
        component: EmailTemplateEditComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.email_templates.title',
        },
      },
      {
        path: 'template/:id',
        component: EmailTemplateEditComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.email_templates.title',
        },
      },
      {
        path: 'template/:id/copy',
        component: EmailTemplateEditComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.email_templates.title',
        },
      },
    ],
  },
  {
    path: 'system-health-status',
    component: SystemStatusComponent,
    data: {
      title: 'tkey;system_status.title',
    },
  },
  // Security & Permissions
  {
    path: 'saml-sso',
    redirectTo: 'security-and-permissions/saml-sso',
  },
  {
    path: 'security-and-permissions/saml-sso',
    component: SamlComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;menu.admin.saml',
    },
  },
  {
    path: 'system/user-permissions',
    redirectTo: 'security-and-permissions/user-permissions',
  },
  {
    path: 'security-and-permissions/user-permissions',
    component: UserPermissionsComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.system.user_permissions.title',
    },
  },
  {
    path: 'system/oauth2-resource-server-management',
    redirectTo: 'security-and-permissions/oauth2-resource-server-management',
  },
  {
    path: 'security-and-permissions/oauth2-resource-server-management',
    component: Oauth2ResourceServerManagementComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.system.oauth2_resource_server_management.title',
    },
  },
  {
    path: 'system/site-based-helpdesk-user-permissions-management',
    redirectTo: 'security-and-permissions/site-based-helpdesk-user-permissions',
  },
  {
    path: 'security-and-permissions/site-based-helpdesk-user-permissions',
    component: SiteBasedHelpdeskUserPermissionsManagementComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    data: {
      title: 'tkey;admin.sitemacman.title',
    },
  },

  // Order Numbers
  {
    path: 'order-numbers',
    children: [
      { path: 'port-in-drafts', redirectTo: 'port-in-orders', pathMatch: 'full' },
      {
        path: 'port-in-drafts/check-number-portability',
        component: CheckNumberPortabilityComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.order_numbers.check_number_portability.title',
          isModifiedExtensionRangeForm: true,
        },
      },
      {
        path: 'port-in-drafts/create-drafts',
        component: CreateDraftComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.order_numbers.create_orders.title',
        },
      },
      {
        path: 'port-in-drafts/:id',
        component: OrderViewAndEditContainerComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.order_numbers.port_in_drafts.title',
        },
      },
      {
        path: 'port-in-orders',
        component: PortInOrdersComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.order_numbers.port_in_orders.title',
        },
      },
      {
        path: 'port-in-orders/:orderId',
        component: OrderViewAndEditContainerComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.order_numbers.port_in_orders.title',
        },
      },
      {
        path: 'port-in-number-status',
        component: PortInNumberStatusComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.order_numbers.port_in_number_status.title',
        },
      },
    ],
  },

  // Proxy Server Management
  {
    path: 'proxy-server-management',
    children: [
      {
        path: 'generate-key',
        component: GenerateKeyComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.proxy_server_management.generate_key.title',
        },
      },
      {
        path: 'zpm-connection-settings',
        component: ConnectionSettingsComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.proxy_server_management.zpm_connection_settings.title',
        },
      },
      {
        path: 'proxy-feature-settings',
        component: ProxyFeatureSettingsComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;admin.proxy_server_management.proxy_feature_settings.title',
        },
      },
    ],
  },
];
