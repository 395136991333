import { Injectable } from '@angular/core';
import {
  BlfButton,
  Button,
  CallParkBlfButton,
  IntercomButton,
  LineButton,
  LineFeatureFieldConfig,
  NoneButton,
  ServiceUrlButton,
  SpeedDialButton,
  TranslationPatternRef,
} from '../../models/generated/smacsModels';
import { Subject } from 'rxjs';
import { LineButtonPeripheralSummary } from '../layout/phone-buttons-standard.component';

export interface PhoneButtonSummary {
  type: string;
  label: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class PhoneButtonsService {
  /** This subject is used to emit voicemail deletion **/
  isVoicemailDeleted = new Subject<boolean>();

  /** This subject is used to emit LineButtonPeripheralSummary on voicemail creation/deletion **/
  lineButtonSummary = new Subject<LineButtonPeripheralSummary>();

  /** This subject is used to emit TranslationPatternRef on modifying DN **/
  updateTpRef = new Subject<TranslationPatternRef>();

  isNoneButton(button: Button): button is NoneButton {
    return button.type === 'None';
  }

  isLineButton(button: Button): button is LineButton {
    return button.type === 'Line';
  }

  isSpeedDialButton(button: Button): button is SpeedDialButton {
    return button.type === 'Speed Dial';
  }

  isBlfButton(button: Button): button is BlfButton {
    return button.type === 'Speed Dial BLF';
  }

  isCallParkBLFButton(button: Button): button is CallParkBlfButton {
    return button.type === 'Call Park BLF';
  }

  isIntercomButton(button: Button): button is IntercomButton {
    return button.type === 'Intercom';
  }

  isServiceUrlButton(button: Button): button is ServiceUrlButton {
    return button.type === 'Service URL';
  }

  mapButtonToButtonSummary(button: Button): PhoneButtonSummary {
    if (this.isLineButton(button)) {
      return {
        type: button.type,
        label: button.lineFeature?.label || '',
        value: button.dn?.extension || '',
      };
    } else if (this.isSpeedDialButton(button)) {
      return {
        type: button.type,
        label: button.label || '',
        value: button.value || '',
      };
    } else if (this.isBlfButton(button)) {
      return {
        type: button.type,
        label: button.label || '',
        value: button.destination || button.dn?.extension || '',
      };
    } else if (this.isServiceUrlButton(button)) {
      return {
        type: button.type,
        label: button.label || '',
        value: button.subscriptionName || '',
      };
    } else if (this.isIntercomButton(button)) {
      return {
        type: button.type,
        value: button.intercom?.extension || '',
        label: '',
      };
    } else if (this.isCallParkBLFButton(button)) {
      return {
        type: button.type,
        label: button.label || '',
        value: button.dn?.extension || '',
      };
    } else {
      return {
        type: button.type,
        label: '',
        value: '',
      };
    }
  }

  newButtonOfType(buttonType: string): Button {
    switch (buttonType) {
      case 'Line':
        return {
          type: buttonType,
          lineFeature: null,
          dn: null,
        } as LineButton;
      case 'Speed Dial':
        return {
          type: buttonType,
          label: '',
          value: '',
        } as SpeedDialButton;
      case 'Speed Dial BLF':
        return {
          type: buttonType,
          dn: null,
          callPickup: false,
          label: '',
          destination: '',
        } as BlfButton;
      case 'Intercom':
        return {
          type: buttonType,
          intercom: null,
        } as IntercomButton;
      case 'Service URL':
        return {
          type: buttonType,
          label: '',
          subscriptionName: '',
        } as ServiceUrlButton;
      case 'Call Park BLF':
        return {
          type: buttonType,
          dn: null,
          label: '',
        } as CallParkBlfButton;
      default:
        return {
          type: buttonType,
        } as Button;
    }
  }

  isValidLineButton(
    button: LineButton,
    isUccxAgent: boolean,
    fieldConfig: LineFeatureFieldConfig,
    otherButtons: Button[]
  ): boolean {
    const lineFeature = button.lineFeature;
    return (
      this._allRequiredFieldsArePresent(button, fieldConfig) &&
      this.validLabelLength(lineFeature.label) &&
      this.validLineLabelPattern(lineFeature.label) &&
      this.validLabelLength(lineFeature.externalCallerId) &&
      this.validSpeedDialLabelPattern(lineFeature.externalCallerId) &&
      this.validLabelLength(lineFeature.internalCallerId) &&
      this.validInternalCallerIdPattern(lineFeature.internalCallerId) &&
      this.validNumberMaskPattern(lineFeature.externalCallerIdNumber) &&
      this.validMaxNumberOfCallsMaximum(lineFeature.maxNumberOfCalls, fieldConfig?.maxNumberOfCalls?.possibleOptions) &&
      this.validMaxNumberOfCallsGreaterThanBusyTrigger(lineFeature.maxNumberOfCalls, lineFeature.busyTrigger) &&
      this.validMaxNumberOfCallsAgent(lineFeature.maxNumberOfCalls, isUccxAgent) &&
      this.validWholeNumber(lineFeature.maxNumberOfCalls) &&
      this.validBusyTriggerAgent(lineFeature.busyTrigger, isUccxAgent) &&
      this.validWholeNumber(lineFeature.busyTrigger) &&
      this.validUniqueLineExtension(button.dn?.extension, otherButtons)
    );
  }

  isValidNonLineButton(button: Button): boolean {
    if (this.isSpeedDialButton(button)) {
      return (
        this.validSpeedDialExtensionLength(button.value) &&
        this.validSpeedDialExtensionPattern(button.value) &&
        this.validLabelLength(button.label) &&
        this.validSpeedDialLabelPattern(button.label)
      );
    } else if (this.isBlfButton(button)) {
      return (
        this.validSpeedDialExtensionLength(button.destination) &&
        this.validSpeedDialExtensionPattern(button.destination) &&
        this.validLabelLength(button.label) &&
        this.validSpeedDialLabelPattern(button.label)
      );
    } else {
      return true;
    }
  }

  validSpeedDialExtensionPattern(val: string): boolean {
    return val.includes('@') ? /^.*$/.test(val) : /^[PpFfCc0-9#*+,]*$/.test(val);
  }

  validSpeedDialExtensionLength(val: string): boolean {
    return val.includes('@') ? val.length <= 254 : val.length <= 50;
  }

  validLabelLength(val: string): boolean {
    return val.length <= 30;
  }

  validSpeedDialLabelPattern(val: string): boolean {
    return /^[a-zA-Z0-9 !#$'()*+,./:;=?@\\^_`-]*$/.test(val);
  }

  validLineLabelPattern(val: string): boolean {
    return !val.includes('"');
  }

  validInternalCallerIdPattern(val: string): boolean {
    return /^[^"|<>{}\[\]&%]*$/.test(val);
  }

  validNumberMaskPattern(val: string): boolean {
    return /^[\d+*#X]*$/.test(val);
  }

  validWholeNumber(val: number): boolean {
    return val > 0;
  }

  validMaxNumberOfCallsMaximum(val: number, possibleOptions: string[]): boolean {
    return !possibleOptions || Number(possibleOptions[possibleOptions.length - 1]) >= val;
  }

  validMaxNumberOfCallsGreaterThanBusyTrigger(
    maxNumberOfCalls: number | string,
    busyTrigger: number | string
  ): boolean {
    return Number(maxNumberOfCalls) >= Number(busyTrigger);
  }

  validMaxNumberOfCallsAgent(val: number, isUccxAgent: boolean): boolean {
    return !isUccxAgent || Number(val) === 2;
  }

  validBusyTriggerAgent(val: number, isUccxAgent: boolean): boolean {
    return !isUccxAgent || Number(val) === 1;
  }

  validUniqueLineExtension(val: string, otherButtons: Button[]): boolean {
    return (
      !val || otherButtons.every((button) => !this.isLineButton(button) || !button.dn || button.dn.extension !== val)
    );
  }

  isValidLineOneButton(
    button: LineButton,
    isUccxAgent: boolean,
    fieldConfig: LineFeatureFieldConfig,
    otherButtons: Button[]
  ): boolean {
    if (button.lineFeature) {
      return !!button.dn && this.isValidLineButton(button, isUccxAgent, fieldConfig, otherButtons);
    }
    return !!button.dn && !!button.lineFeature;
  }

  private _allRequiredFieldsArePresent(button: LineButton, fieldConfig: LineFeatureFieldConfig): boolean {
    return Object.entries(fieldConfig).every(([key, value]) => {
      return (
        !value.required || (value.required && !value.show) || button.lineFeature[key as keyof LineFeatureFieldConfig]
      );
    });
  }
}
