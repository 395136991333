<smacs-phone-buttons-edit-form
  #form
  [userMode]="DragDropMode.SELF_SERVE"
  [entity]="buttonToEdit.button"
  [parentSubmit$]="submit$"
  (smacsFormsUpdate$)="onFormUpdate($event)"
></smacs-phone-buttons-edit-form>

<ng-template #errorTemplate>
  <span *ngIf="isValid === SmacsFormsValidationState.INVALID">
    {{ 'tkey;validators.global.required.missing.error' | translate }}
  </span>
</ng-template>
