import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ZpcProxySettings, ZpmProxySettings } from '../../../shared/models/generated/smacsModels';
import { MicrosoftProxyFeatureSettingsContext } from '../../contexts/microsoft-proxy-feature-settings.context';
import { GlobalPropertiesContext } from '../../../shared/contexts/global-properties.context';
import { CiscoProxyFeatureSettingsResource } from '../../resources/cisco-proxy-feature-settings.resource';
import { Subscription } from 'rxjs';

export interface ZpmProxyFeatureSettingsEntity extends ZpmProxySettings {
  cucmPhoneNumberAssignmentMatchType: DidMatchType;
}

export interface ZpcProxyFeatureSettingsEntity extends ZpcProxySettings {
  teamsPhoneNumberAssignmentMatchType: DidMatchType;
}

export enum DidMatchType {
  END_MATCH = 'END_MATCH',
  EXACT_MATCH = 'EXACT_MATCH',
}

@Component({
  selector: 'smacs-proxy-feature-settings',
  templateUrl: './proxy-feature-settings.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class ProxyFeatureSettingsComponent implements OnInit, OnDestroy {
  isLoading = true;
  zpmProxyFeatureSettingsEntity: ZpmProxyFeatureSettingsEntity;
  zpcProxyFeatureSettingsEntity: ZpcProxyFeatureSettingsEntity;
  smacsIcons = SmacsIcons;
  isHostedEnabled = false;

  private _subs = new Subscription();

  constructor(
    private _breadcrumbsService: BreadcrumbsService,
    private _msProxyFeatureSettingsContext: MicrosoftProxyFeatureSettingsContext,
    private globalPropertiesContext: GlobalPropertiesContext,
    private _ciscoProxyFeatureSettingsResource: CiscoProxyFeatureSettingsResource
  ) {}

  ngOnInit(): void {
    const globalContextSub = this.globalPropertiesContext.state$.subscribe((globalProperties) => {
      this.isHostedEnabled = globalProperties.hostedEnabled;
      this.isHostedEnabled ? this._initZpmFormData() : this._initZpcFormData();
    });

    this._breadcrumbsService.updateBreadcrumbs([
      { label: 'tkey;admin.proxy_server_management.title' },
      { label: 'tkey;admin.proxy_server_management.proxy_feature_settings.title' },
    ]);
    this._subs.add(globalContextSub);
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private _initZpmFormData(): void {
    const sub = this._msProxyFeatureSettingsContext.state$.subscribe((settings: ZpmProxySettings) => {
      this.zpmProxyFeatureSettingsEntity = {
        onPremAdWriteDownEnabled: settings.onPremAdWriteDownEnabled,
        cucmPhoneNumberAssignmentCheckEnabled: settings.cucmPhoneNumberAssignmentCheckEnabled,
        cucmPhoneNumberAssignmentMatchType:
          settings.cucmPhoneNumberAssignmentLastDigitsMatch > 0 ? DidMatchType.END_MATCH : DidMatchType.EXACT_MATCH,
        cucmPhoneNumberAssignmentLastDigitsMatch:
          settings.cucmPhoneNumberAssignmentLastDigitsMatch > 0
            ? settings.cucmPhoneNumberAssignmentLastDigitsMatch
            : undefined,
      };

      this.isLoading = false;
    });

    this._subs.add(sub);
  }

  private _initZpcFormData(): void {
    this._ciscoProxyFeatureSettingsResource.get().subscribe((settings: ZpcProxySettings) => {
      this.zpcProxyFeatureSettingsEntity = {
        teamsPhoneNumberAssignmentCheckEnabled: settings.teamsPhoneNumberAssignmentCheckEnabled,
        teamsPhoneNumberAssignmentMatchType:
          settings.teamsPhoneNumberAssignmentLastDigitsMatch > 0 ? DidMatchType.END_MATCH : DidMatchType.EXACT_MATCH,
        teamsPhoneNumberAssignmentLastDigitsMatch:
          settings.teamsPhoneNumberAssignmentLastDigitsMatch > 0
            ? settings.teamsPhoneNumberAssignmentLastDigitsMatch
            : undefined,
      };

      this.isLoading = false;
    });
  }
}
