<div class="smacs-admin-page-content">
  <div data-automation="device-utilization-content" class="col-md-10 offset-md-1 smacs-report-content">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div
          [innerHtml]="
            (isAdmin
              ? 'tkey;reporting.device_utilization.description'
              : 'tkey;reporting.device_utilization.description.site_based'
            ) | translate : { nextRefreshed: getRefresh(nextRunTime), lastRefreshed: getRefresh(lastRunTime) }
          "
        ></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div
      *ngIf="isRefreshing"
      class="card border-primary text-center mb-3"
      data-automation="utilization-refreshing-card"
    >
      <div class="card-body strong">
        <i class="{{ smacsIcons.SAVING }} pe-1"></i>
        <span>{{ 'tkey;reporting.device_utilization.refresh.in_progress' | translate }}</span>
      </div>
    </div>

    <app-loading-block [hidden]="!isSearching || isRefreshing"></app-loading-block>

    <div
      *ngIf="!isSearching && !isRefreshing && data.length === 0 && !showCustomAlert"
      class="mt-1 smacs-page-alert alert border-warning"
      data-automation="device-utilization-no-cdrs-warning"
    >
      <i class="icon-warning-triangle text-warning fa-4x"></i>
      <div class="ms-3" [innerHTML]="'tkey;reporting.device_utilization.no_cdr.message' | translate"></div>
    </div>

    <ng-container *ngIf="!isSearching && !isRefreshing && (data.length > 0 || !!showCustomAlert)">
      <smacs-datatable
        [columns]="columns"
        [data]="data"
        [isFirstColumnSticky]="true"
        [mainFilterPlaceholder]="'tkey;reporting.device_utilization.search_placeholder' | translate"
        [dataAutomation]="tableDataAutomation"
        [noResultsAlertClass]="'table-danger'"
        (alertClickableClicked)="onRefreshClicked()"
        [showCustomAlert]="showCustomAlert"
      >
        <smacs-datatable-switch-filter
          [label]="'tkey;menu.report.show_new_devices'"
          [matchFn]="isNewDeviceMatch"
          [dataAutomation]="'device-utilization-new-device-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-switch-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;reporting.device_utilization.model.header'"
          [items]="deviceModelOptions"
          [matchFn]="isDeviceModelMatch"
          [dataAutomation]="'device-utilization-model-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;reporting.device_utilization.pool.header'"
          [items]="devicePoolOptions"
          [matchFn]="isDevicePoolMatch"
          [dataAutomation]="'device-utilization-device-pool-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;reporting.device_utilization.registration_status.header'"
          [items]="registrationStatusOptions"
          [matchFn]="isRegistrationStatusMatch"
          [dataAutomation]="'device-utilization-registration-status-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-slider-filter
          [label]="'tkey;reporting.device_utilization.last_used.header'"
          [matchFn]="isLastUsedMatch"
          [floorValue]="0"
          [floorValueLabel]="'tkey;time.today'"
          [ceilValue]="callRetentionDays || 0"
          [dataAutomation]="'device-utilization-last-used-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-slider-filter>
        <div class="text-center" alert-content>
          {{ 'tkey;reporting.device_utilization.job_status.error.table_message_pre' | translate }}
          <button data-automation="custom-alert-button" class="btn btn-link strong" (click)="onRefreshClicked()">
            {{ 'tkey;reporting.device_utilization.job_status.error.table_message_clickable' | translate }}
          </button>
          {{ 'tkey;reporting.device_utilization.job_status.error.table_message_post' | translate }}
        </div>
      </smacs-datatable>
    </ng-container>
  </div>
</div>

<app-bottom-nav data-automation="device-utilization-bottom-nav"></app-bottom-nav>
