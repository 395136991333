import { Pipe, PipeTransform } from '@angular/core';
import { DeviceRegistrationStatus } from '../../../shared/models/generated/smacsModels';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'phoneStatusFilter' })
export class PhoneStatusFilter implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: string): string {
    switch (value) {
      case DeviceRegistrationStatus.UNREGISTERED: {
        return this.translateService.instant('tkey;userdetail.desk_phone.status.unregistered.label');
      }
      case DeviceRegistrationStatus.NOT_FOUND: {
        return this.translateService.instant('tkey;userdetail.desk_phone.status.not_found.label');
      }
      default: {
        return '';
      }
    }
  }
}
