import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MicrosoftTeamsCallQueuesFieldConfig, MicrosoftTeamsUserCallQueues } from '../models/generated/smacsModels';

@Injectable()
export class TeamsCallQueueResource {
  constructor(protected http: HttpClient) {}

  getQueueMemberships(): Observable<MicrosoftTeamsCallQueuesFieldConfig> {
    return this.http.get<MicrosoftTeamsCallQueuesFieldConfig>('/services/microsoft/field-configs/queue-memberships');
  }

  getUserQueueMemberships(upn: string): Observable<MicrosoftTeamsUserCallQueues> {
    return this.http.get<MicrosoftTeamsUserCallQueues>(`/services/microsoft/macs/queue-memberships/${upn}`);
  }

  saveUserQueueMemberships(upn: string, queues: MicrosoftTeamsUserCallQueues): Observable<void> {
    return this.http.put<void>(`/services/microsoft/macs/queue-memberships/${upn}`, queues);
  }
}
