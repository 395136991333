<div *ngIf="!isLoading" class="row bottom-buffer top-buffer">
  <div class="col-md-6">
    <a data-automation="company-logo" class="align-middle" [routerLink]="getLogoUrl()">
      <img
        *ngIf="customUiLogo"
        alt="Logo"
        [src]="customUiLogo"
        data-automation="self-serve-logo"
        class="company-logo-image"
      />
      <img class="company-logo-image" *ngIf="!customUiLogo" [src]="'/static/img/ziro-login-logo-sm.png'" alt="Logo" />
    </a>
  </div>

  <div class="col-md-6 text-end">
    <div ngbDropdown class="d-inline-block user-actions-dropdown" data-automation="user-actions-dropdown">
      <button
        type="button"
        class="btn btn-default btn-lg user-actions-dropdown--button"
        id="ssUserDropdown"
        data-automation="user-actions-dropdown-expand"
        [ngClass]="{ 'button-ldap-picture': getUserIconBase64() }"
        ngbDropdownToggle
      >
        <i *ngIf="!getUserIconBase64()" [class]="smacsIcons.USER_CIRCLE"></i>
        <img *ngIf="getUserIconBase64()" class="avatar-pic" [src]="getUserIconSrc()" [alt]="usersFullName" />
        {{ usersFullName }}
      </button>
      <div ngbDropdownMenu aria-labelledby="ssUserDropdown">
        <button class="btn" (click)="logoutUser()" data-automation="user-actions-dropdown-logout" ngbDropdownItem>
          <i [class]="smacsIcons.SIGN_OUT"></i>
          <span>{{ 'tkey;self_serve.sign_out' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>
