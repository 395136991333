import { Component, Input } from '@angular/core';
import { Microsoft365UserRef } from '../../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonTypes } from '../../../button/button.component';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';

export interface MicrosoftUserUiResult {
  ref: Microsoft365UserRef;
  lineUri: string;
  lineUriExtension: string;
  photo: string;
}

@Component({
  selector: 'ziro-microsoft-search-result',
  templateUrl: './microsoft-search-result.component.html',
  styleUrls: ['./microsoft-search-result.component.scss'],
})
export class MicrosoftSearchResultComponent {
  @Input() result: Microsoft365UserRef;
  @Input() lineUri: string;
  @Input() lineUriExtension: string;
  @Input() photo: string;
  @Input() isGuestAccount: boolean;
  @Input() isLoadingLineUri = true;
  protected readonly ButtonTypes = ButtonTypes;
  protected readonly ButtonSizes = ButtonSizes;
  protected readonly charLimitDN = 30;
  protected readonly charLimitUPN = 35;

  smacsIcons = SmacsIcons;

  constructor(private telephoneNumberFilter: TelephoneNumberFilter) {}

  formatLineUri(lineUri: string, extension: string): string {
    if (lineUri?.length) {
      if(extension) {
        return this.telephoneNumberFilter.transform(lineUri) + " ext." + extension;
      }
      return this.telephoneNumberFilter.transform(lineUri);
    }
    return '';
  }
}
