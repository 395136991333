import { SmacsLink } from '../shared/models/smacs-link';

export const zeroTouchLinks = [
  {
    url: 'ldap-sync',
    label: 'tkey;admin.ldap_sync.title',
    automation: 'zero-touch-ldap-sync',
    isRouterLink: true,
  },
  {
    url: 'deprovisioning',
    label: 'tkey;pages.zero_touch.deprovisioning.title',
    automation: 'zero-touch-deprovisioning',
    isRouterLink: true,
  },
  {
    url: 'provisioning',
    label: 'tkey;pages.zero_touch.provisioning.title',
    automation: 'zero-touch-provisioning',
    isRouterLink: true,
  },
  {
    url: 'bulk-provisioning',
    label: 'tkey;pages.zero_touch.bulk_provisioning.title',
    automation: 'zero-touch-bulk-provisioning',
    isRouterLink: true,
  },
  {
    url: 'microsoft/zero-touch-provisioning',
    label: 'tkey;pages.zero_touch.microsoft.provisioning.link.title',
    automation: 'zero-touch-microsoft-provisioning',
    isRouterLink: true,
  },
  {
    url: 'microsoft/bulk-provisioning',
    label: 'tkey;pages.zero_touch.bulk_provisioning.title',
    automation: 'zero-touch-microsoft-bulk-provisioning',
    isRouterLink: true,
  },
  {
    url: '/open-api/#/microsoft',
    label: 'tkey;pages.zero_touch.rest.microsoft.title',
    isRouterLink: false,
    automation: 'microsoft-rest-api',
    newTab: true,
  },
  {
    url: '/open-api/#/cisco',
    label: 'tkey;pages.zero_touch.rest.cisco.title',
    isRouterLink: false,
    automation: 'cisco-rest-api',
    newTab: true,
  },
  {
    url: '/open-api/#/system',
    label: 'tkey;pages.zero_touch.rest.system.title',
    isRouterLink: false,
    automation: 'system-rest-api',
    newTab: true,
  },
  {
    url: 'about-rest',
    label: 'tkey;pages.zero_touch.about_rest.title',
    automation: 'about-rest',
    isRouterLink: true,
  },
] as SmacsLink[];
