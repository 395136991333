<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div class="container-fluid col-md-10 col-md-offset-1 text-center pt-5" *ngIf="!isLoading">
  <div class="card technical-support-header">
    <div class="card-body">
      <h1>{{ 'tkey;support.header' | translate }}</h1>
    </div>
  </div>

  <div class="technical-support-assistance">
    <h1 class="display-3">{{ 'tkey;support.assistance.text' | translate }}</h1>
  </div>

  <div class="top-buffer" *ngIf="!customizedText">
    <h3 data-automation="contact-us">{{ 'tkey;support.contact.text' | translate }}</h3>
    <div>
      <h4>
        <strong>{{ 'tkey;support.email.label' | translate }}</strong>
        :
        <a href="mailto:softwaresupport@goziro.com" data-automation="support-email-link">softwaresupport&#64;goziro.com</a>
      </h4>
      <h4>
        <strong>{{ 'tkey;support.phonenumber.label' | translate }}</strong>
        :
        <a href="tel://+1-844-940-4110" data-automation="support-phone-number-link">+1-844-940-4110</a>
      </h4>
    </div>
  </div>

  <div class="top-buffer" *ngIf="customizedText">
    <span data-automation="custom-support-text" [innerHTML]="customizedText"></span>
  </div>

  <hr />
</div>
