import { Component, OnDestroy } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'microsoft-dial-plan-management',
  templateUrl: './dial-plan-management.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class DialPlanManagementComponent implements OnDestroy {
  constructor(private breadcrumbsService: BreadcrumbsService) {}

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
  }
}
