import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { CertificateResource } from '../../../resources/certificate.resource';
import { from, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { Csr, SignedCertificate, WildcardCertificate } from '../../../../shared/models/generated/smacsModels';
import { ToastService } from '../../../../shared/services/toast.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../../../button/button.component';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsRadioConfig } from '../../../../forms/fields/radio/smacs-radio.component';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { isEqual } from 'lodash';
import { catchError, tap } from 'rxjs/operators';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ImportCertSuccessModalComponent } from '../../../../modals/import-cert-success-modal/import-cert-success-modal.component';

export enum CertificateType {
  SIGNED = 'Signed',
  WILDCARD = 'Wildcard',
}

export interface CertificateFieldData {
  certificateType: CertificateType;
  certificateFile: any;
  keyFile: any;
  fqdn: string;
}

const validators = {
  isValidUrl: (val: string) =>
    /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/.test(val)
      ? SmacsFormsValidationState.VALID
      : SmacsFormsValidationState.INVALID,
};

@Component({
  selector: 'app-admin-import-certificate',
  templateUrl: './import-certificate.component.html',
  styleUrls: ['./import-certificate.component.scss'],
})
export class ImportCertificateComponent
  extends SmacsFormAbstractDirective<CertificateFieldData>
  implements OnInit, OnChanges, OnDestroy
{
  @Output() selectGenerateCsrTab = new EventEmitter<void>();
  @Output() deleteCsr = new Subject<void>();

  @Input() displayCsr: Subject<boolean>;
  @Input() csrTimestamp: string;
  @Input() existingCsr: Csr;

  isSubmitted = false;
  isSaving = false;
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;

  fieldGroups = {
    fileUploads: ['certificateFile', 'keyFile'],
  };

  formConfig = {
    fields: {
      certificateType: {
        label: 'tkey;certificate_management.import_certificate.radio_button.label',
        dataAutomation: 'radio-stage-select',
        componentConfig: new SmacsRadioConfig<any>({
          buttons: [
            {
              value: CertificateType.SIGNED,
              label: 'tkey;certificate_management.import_certificate.radio_button.signed_cert',
            },
            {
              value: CertificateType.WILDCARD,
              label: 'tkey;certificate_management.import_certificate.radio_button.wildcard_cert',
            },
          ],
          inline: true,
        }),
      },
      certificateFile: {
        label: 'tkey;certificate_management.import_certificate.form.certificate_label',
        dataAutomation: 'admin-import-certificate-upload-certificate-file',
        disabledTooltip: 'tkey;certificate_management.import_certificate.form.file_input.tooltip',
        required: () => this.formData.certificateType != null,
        disabled: () => this.disableFileInput(),
      },
      keyFile: {
        label: 'tkey;certificate_management.import_certificate.form.key_label',
        dataAutomation: 'admin-import-certificate-upload-key-file',
        required: () => this.formData.certificateType === CertificateType.WILDCARD,
        hidden: () => this.showWildcardCertificateForm(),
      },
      fqdn: {
        label: 'tkey;certificate_management.import_certificate.form.fqdn_label',
        dataAutomation: 'admin-import-certificate-fqdn',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        labelToolTipText: 'tkey;certificate_management.import_certificate.form.fqdn_label.tooltip',
        required: () => true,
        hidden: () => this.formData.certificateType === CertificateType.SIGNED,
        valExcluded: () => this.formData.certificateType === CertificateType.SIGNED,
        validation: [
          {
            validator: validators.isValidUrl,
            message: 'tkey;certificate_management.import_certificate.form.fqdn.error.text',
          },
        ],

        labelToolTipIconClass: this.smacsIcons.INFO,
      },
    },
  } as SmacsFormConfig;

  private _subs = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _certificateResource: CertificateResource,
    private _toastService: ToastService,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this.entitySource.next({
      certificateType: null,
      certificateFile: null,
      keyFile: null,
      fqdn: '',
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subs.unsubscribe();
  }

  showWildcardCertificateForm(): boolean {
    return this.formData.certificateType !== CertificateType.WILDCARD;
  }

  showSignedForm(): boolean {
    return this.formData.certificateType !== CertificateType.SIGNED;
  }

  hideForm(): boolean {
    return this.formData.certificateType == null;
  }

  switchTabs() {
    this.selectGenerateCsrTab.emit();
  }

  showCertAndSwitchTabs() {
    this.displayCsr.next(true);
    this.selectGenerateCsrTab.emit();
  }

  disableFileInput() {
    return !this.existingCsr && this.formData.certificateType === CertificateType.SIGNED;
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (!isEqual(changes.existingCsr.currentValue, changes.existingCsr.previousValue)) {
      const certFileState = this.fieldStates['certificateFile'];
      const certFileChannel = this.fieldChannels['certificateFile'];
      if (certFileChannel) {
        // If we've already initialized this form, make sure to un-disable the file input once the CSR is generated.
        certFileChannel.stateSource.next({ ...certFileState, disabled: this.disableFileInput() });
      }
    }
  }

  private _readFile(file: File): Observable<string> {
    return from(file.text());
  }

  private _submitCertificate(certificate: string, key?: string): Observable<void> {
    const signedCertificate = { certificate: certificate } as SignedCertificate;
    const wildcardCertificate = {
      certificate: certificate,
      fqdn: this.formData.fqdn,
      key: key,
    } as WildcardCertificate;
    const observable =
      this.formData.certificateType === CertificateType.SIGNED
        ? this._certificateResource.putSignedCertificate(signedCertificate)
        : this._certificateResource.putWildCardCertificate(wildcardCertificate);

    const options = {
      imgPath: '/static/img/cert-restart-web-server.png',
      modalViewProperties: {
        title: this._translateService.instant('tkey;admin.certificate_management.import.modal_success.title'),
        promptBody: this._translateService.instant('tkey;admin.certificate_management.import.modal_success.prompt'),
      },
    };

    return observable.pipe(
      tap(() => {
        this._smacsModalService.openCustomModal(ImportCertSuccessModalComponent, options, 'lg');
        this.isSaving = false;
        this.isSubmitted = false;
        this.entitySource.next({
          certificateType: null,
          certificateFile: null,
          keyFile: null,
          fqdn: '',
        });
        this.deleteCsr.next();
      }),
      catchError((error) => {
        if (error.status === 422) {
          this.isSaving = false;
          this.isSubmitted = false;
          this._toastService.push(
            ToastTypes.ERROR,
            this.smacsIcons.CERTIFICATE,
            'Unprocessable Entity',
            error.error.description
          );
          return of(null);
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  protected submit() {
    this.isSubmitted = true;
    this.isSaving = true;
    return new Observable((sub) => {
      this._readFile(this.formData.certificateFile).subscribe((certificate) => {
        if (this.formData.keyFile) {
          this._readFile(this.formData.keyFile).subscribe((key) => {
            this._submitCertificate(certificate.toString(), key.toString()).subscribe(() => {
              sub.next();
              sub.complete();
            });
          });
        } else {
          this._submitCertificate(certificate.toString()).subscribe(() => {
            sub.next();
            sub.complete();
          });
        }
      });
    });
  }
}
