<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div [hidden]="isLoading" class="col-md-10 offset-md-1">
    <app-breadcrumbs></app-breadcrumbs>

    <legend>{{ 'tkey;admin.ui.support.header' | translate }}</legend>
    <smacs-textarea [fieldId]="'supportTextHtml'"></smacs-textarea>

    <div class="mb-4">
      <legend>{{ 'tkey;admin.ui.header' | translate }}</legend>

      <div class="row">
        <div class="col-md-6">
          <div [innerHTML]="'tkey;admin.ui.form.description' | translate"></div>
        </div>
        <div class="col-md-6">
          <img src="/static/img/smacs_customize_ui_screenshot.png" class="img-fluid img-thumbnail" />
        </div>
      </div>
    </div>

    <div class="d-flex">
      <div class="flex-fill">
        <smacs-file-upload [fieldId]="'logo'"></smacs-file-upload>
      </div>
      <div class="d-flex input-action-buttons">
        <smacs-button
          [dataAutomation]="'customize-ui-logo-preview-btn'"
          (clicked)="onPreviewClick(customizeUiPhotoTypes.LOGO)"
          [buttonStyle]="buttonStyles.DEFAULT"
          [buttonSize]="buttonSizes.NORMAL"
          [icon]="smacsIcons.PREVIEW"
          [isDisabled]="!uiProperties || !uiProperties.logo"
        ></smacs-button>
        <smacs-button
          class="delete-btn"
          [dataAutomation]="'customize-ui-logo-delete-btn'"
          (clicked)="onDeleteClick(customizeUiPhotoTypes.LOGO)"
          [buttonStyle]="buttonStyles.DANGER"
          [buttonSize]="buttonSizes.NORMAL"
          [icon]="smacsIcons.DELETE"
          [isDisabled]="!uiProperties || !uiProperties.logo"
        ></smacs-button>
      </div>
    </div>

    <div class="d-flex">
      <div class="flex-fill">
        <smacs-file-upload [fieldId]="'backgroundImage'"></smacs-file-upload>
      </div>
      <div class="d-flex input-action-buttons">
        <smacs-button
          [dataAutomation]="'customize-ui-background-image-preview-btn'"
          (clicked)="onPreviewClick(customizeUiPhotoTypes.BACKGROUND_IMAGE)"
          [buttonStyle]="buttonStyles.DEFAULT"
          [buttonSize]="buttonSizes.NORMAL"
          [icon]="smacsIcons.PREVIEW"
          [isDisabled]="!uiProperties || !uiProperties.backgroundImage"
        ></smacs-button>
        <smacs-button
          class="delete-btn"
          [dataAutomation]="'customize-ui-background-image-delete-btn'"
          (clicked)="onDeleteClick(customizeUiPhotoTypes.BACKGROUND_IMAGE)"
          [buttonStyle]="buttonStyles.DANGER"
          [buttonSize]="buttonSizes.NORMAL"
          [icon]="smacsIcons.DELETE"
          [isDisabled]="!uiProperties || !uiProperties.backgroundImage"
        ></smacs-button>
      </div>
    </div>

    <div class="d-flex">
      <div class="flex-fill">
        <smacs-color-picker [fieldId]="'backgroundColor'"></smacs-color-picker>
      </div>
      <div class="spacer"></div>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
