<div class="mt-3" data-automation="certificate-management-import-certificate">
  <div class="alert alert-danger" [hidden]="!this.disableFileInput()">
    <div>
      <strong>{{ 'tkey;certificate_management.import_certificate.alert_danger.title' | translate }}</strong>
    </div>
    <span>
      {{ 'tkey;certificate_management.import_certificate.alert_danger.body_1' | translate }}
      <button
        type="button"
        class="btn btn-link"
        (click)="switchTabs()"
        data-automation="admin-import-certificate-no-csr-button"
      >
        {{ 'tkey;certificate_management.import_certificate.alert_danger.body_2' | translate }}
      </button>
      {{ 'tkey;certificate_management.import_certificate.alert_danger.body_3' | translate }}
    </span>
  </div>

  <div class="alert alert-info" [hidden]="showSignedForm() || !csrTimestamp">
    <div>
      <strong>{{ 'tkey;certificate_management.import_certificate.alert_info.title' | translate }}</strong>
    </div>
    <span>
      {{ 'tkey;certificate_management.import_certificate.alert_info.body_1' | translate }}
      <button
        type="button"
        class="btn btn-link"
        (click)="showCertAndSwitchTabs()"
        data-automation="admin-import-certificate-view-csr-button"
      >
        {{ 'tkey;certificate_management.import_certificate.alert_info.body_2' | translate }}
      </button>
      {{ 'tkey;certificate_management.import_certificate.alert_info.body_3' | translate }}
      {{ csrTimestamp }}.
    </span>
  </div>

  <div class="my-3 row">
    <div class="col-sm-12">
      <smacs-radio [fieldId]="'certificateType'" class="radioComponent"></smacs-radio>
    </div>
  </div>

  <div class="mb-3 row" [hidden]="!formData?.certificateType">
    <div class="col-sm-12">
      <smacs-file-upload
        *ngFor="let fieldId of fieldGroups.fileUploads"
        [fieldId]="fieldId"
        class="fileUpload"
      ></smacs-file-upload>
      <smacs-text [fieldId]="'fqdn'"></smacs-text>
    </div>
  </div>

  <div class="mb-3 row" [hidden]="hideForm()">
    <div class="offset-sm-2 col-sm-10 text-end">
      <div
        class="tooltip-wrapper tooltip-wrapper-disabled"
        [ngbTooltip]="'tkey;certificate_management.import_certificate.alert_danger.title' | translate"
        [disableTooltip]="showSignedForm() || !!csrTimestamp"
      >
        <smacs-submit-button
          [isPending]="isSubmitting"
          [isDisabled]="isSubmitting || !isFormValid()"
          [label]="'tkey;certificate_management.import_certificate.form.submit_button'"
          [dataAutomation]="'admin-import-certificate-import-button'"
          [iconPending]="smacsIcons.SAVING"
          [icon]="smacsIcons.IMPORT"
          [formSubmitSubject]="_validateAndSubmitSource"
        ></smacs-submit-button>
      </div>
    </div>
  </div>
</div>
