import { SmacsIcons } from '../shared/models/smacs-icons.enum';
import { NavSection } from '../shared/side-navigation/side-navigation.model';

export const zpcAdminLinks: NavSection[] = [
  {
    icon: SmacsIcons.HEALTH,
    dataAutomation: 'system-health-section-header',
    title: 'tkey;admin.menu.section.system_health',
    links: [
      {
        link: '/admin/system-health-status',
        text: 'tkey;system_status.title',
        dataAutomation: 'menu-system-health-status-link',
      },
      {
        dataAutomation: 'menu-system-health-settings-notifications-link',
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired
            ? 'tkey;admin.menu.license_expired.tooltip'
            : 'tkey;admin.email_feature.disabled.tooltip';
        },
        isDisabled: (flags) => {
          return flags.isLicenseExpired || !flags.hasEmailSettings;
        },
        link: '/admin/system-health-settings-and-notifications',
        text: 'tkey;admin.system_health_status_notifications.title',
      },
    ],
  },
  {
    icon: SmacsIcons.SERVER,
    dataAutomation: 'proxy-server-management-section-header',
    title: 'tkey;admin.proxy_server_management.title',
    links: [
      {
        link: '/admin/proxy-server-management/zpm-connection-settings',
        text: 'tkey;admin.proxy_server_management.zpm_connection_settings.title',
        dataAutomation: 'menu-proxy-server-connection-settings-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/proxy-server-management/proxy-feature-settings',
        text: 'tkey;admin.proxy_server_management.proxy_feature_settings.title',
        dataAutomation: 'menu-proxy-feature-settings-link',
        isDisabled: (flags) => !flags.isZpcProxyKeyConfigured,
        disabledTooltip: (flags) => {
          return flags.isZpcProxyKeyConfigured ? '' : 'tkey;admin.menu.proxy_key_unconfigured.tooltip.zpc';
        },
      },
    ],
  },
  {
    icon: SmacsIcons.SLIDERS,
    title: 'tkey;admin.menu.section.system',
    dataAutomation: 'system-section-header',
    links: [
      {
        link: '/admin/system/cisco-general-settings',
        text: 'tkey;admin.system.cisco_general_settings.title',
        dataAutomation: 'menu-cisco-general-settings-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/license',
        text: 'tkey;menu.admin.license',
        dataAutomation: 'menu-license-link',
      },
      {
        link: '/admin/ldap-management',
        text: 'tkey;admin.ldap.title',
        dataAutomation: 'menu-ldap-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/certificate-management',
        text: 'tkey;admin.certificate_management.title',
        dataAutomation: 'menu-certificate-management-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/ui',
        text: 'tkey;admin.ui.title',
        dataAutomation: 'menu-ui-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/application-logs',
        text: 'tkey;menu.admin.application_logs',
        dataAutomation: 'application-logs-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/email-configuration',
        text: 'tkey;admin.email_configuration.title',
        dataAutomation: 'email-configuration-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
    ],
  },
  {
    icon: SmacsIcons.SHIELD,
    dataAutomation: 'security-and-permissions',
    title: 'tkey;admin.menu.section.security_and_permissions',
    links: [
      {
        link: '/admin/security-and-permissions/saml-sso',
        text: 'tkey;admin.saml.title',
        dataAutomation: 'menu-saml-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/security-and-permissions/site-based-helpdesk-user-permissions',
        text: 'tkey;admin.sitemacman.header',
        dataAutomation: 'site-based-helpdesk-link',
        isDisabled: (flags) => flags.isLicenseExpired || flags.proxyServer,
        disabledTooltip: (flags) =>
          flags.isLicenseExpired
            ? 'tkey;admin.menu.license_expired.tooltip'
            : flags.proxyServer
            ? 'tkey;admin.menu.proxy_server.tooltip'
            : '',
      },
      {
        link: '/admin/security-and-permissions/oauth2-resource-server-management',
        text: 'tkey;admin.system.oauth2_resource_server_management.title',
        dataAutomation: 'menu-oauth2-resource-server-management-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
        isVisible: (flags) => {
          return flags.isAutomationModuleEnabled;
        },
      },
    ],
  },
  {
    icon: SmacsIcons.DESKPHONE,
    title: 'tkey;admin.menu.section.cisco',
    dataAutomation: 'cisco-management-section-header',
    isVisible: (flags) => {
      return !flags.isHostedEnabled;
    },
    links: [
      {
        link: '/admin/servers',
        text: 'tkey;admin.servers.config.title',
        dataAutomation: 'menu-servers-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/site-management',
        text: 'tkey;admin.site_management.title',
        dataAutomation: 'site-management-link',
        isDisabled: (flags) => flags.isLicenseExpired || flags.endpointDisabled || flags.proxyServer,
        disabledTooltip: (flags) =>
          flags.isLicenseExpired || flags.endpointDisabled
            ? 'tkey;admin.servers.disable.tooltip'
            : flags.proxyServer
            ? 'tkey;admin.menu.proxy_server.tooltip'
            : '',
      },
      {
        link: '/admin/cisco/dial-plan-management',
        text: 'tkey;dialplanmanagement.title',
        dataAutomation: 'menu-cisco-dial-plan-management-link',
        isDisabled: (flags) => flags.isLicenseExpired || flags.endpointDisabled || flags.proxyServer,
        disabledTooltip: (flags) =>
          flags.isLicenseExpired || flags.endpointDisabled
            ? 'tkey;admin.servers.disable.tooltip'
            : flags.proxyServer
            ? 'tkey;admin.menu.proxy_server.tooltip'
            : '',
      },
      {
        link: '/admin/dn-exception-management',
        text: 'tkey;dnexception.admin.title',
        dataAutomation: 'dn-exception-groups-link',
        isDisabled: (flags) => flags.isLicenseExpired || flags.proxyServer,
        disabledTooltip: (flags) =>
          flags.isLicenseExpired
            ? 'tkey;admin.menu.license_expired.tooltip'
            : flags.proxyServer
            ? 'tkey;admin.menu.proxy_server.tooltip'
            : '',
      },
      {
        link: '/admin/webex-authentication',
        text: 'tkey;admin.webex_authentication.title',
        dataAutomation: 'webex-authentication-link',
        isDisabled: (flags) => flags.isLicenseExpired || flags.proxyServer,
        disabledTooltip: (flags) =>
          flags.isLicenseExpired
            ? 'tkey;admin.menu.license_expired.tooltip'
            : flags.proxyServer
            ? 'tkey;admin.menu.proxy_server.tooltip'
            : '',
      },
      {
        link: '/admin/phone-button-templates',
        text: 'tkey;admin.phone_button_templates.title',
        dataAutomation: 'menu-phone-button-templates-link',
        isDisabled: (flags) => flags.isLicenseExpired || flags.endpointDisabled || flags.proxyServer,
        disabledTooltip: (flags) =>
          flags.isLicenseExpired || flags.endpointDisabled
            ? 'tkey;admin.servers.disable.tooltip'
            : flags.proxyServer
            ? 'tkey;admin.menu.proxy_server.tooltip'
            : '',
      },
      {
        link: '/admin/cisco/email-templates',
        text: 'tkey;admin.email_templates.title',
        dataAutomation: 'menu-email-templates-link',
        isDisabled: (flags) => flags.isLicenseExpired || !flags.hasEmailSettings || flags.proxyServer,
        disabledTooltip: (flags) =>
          flags.isLicenseExpired
            ? 'tkey;admin.menu.license_expired.tooltip'
            : !flags.hasEmailSettings
            ? 'tkey;admin.email_feature.disabled.tooltip'
            : flags.proxyServer
            ? 'tkey;admin.menu.proxy_server.tooltip'
            : '',
      },
      {
        link: '/admin/manual-cdr-import',
        text: 'tkey;menu.admin.manual_cdr_import',
        dataAutomation: 'menu-manual-cdr-import-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
        isVisible: (flags) => {
          return flags.isReportingModuleEnabled;
        },
      },
      {
        link: '/admin/device-utilization-report-settings',
        text: 'tkey;admin.device.utilization.settings.header',
        dataAutomation: 'device-utilization-settings-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
        isVisible: (flags) => {
          return flags.isReportingModuleEnabled;
        },
      },
    ],
  },
  {
    icon: SmacsIcons.DISASTER_RECOVERY,
    title: 'tkey;admin.menu.section.disaster_recovery',
    dataAutomation: 'disaster-recovery-section-header',
    links: [
      {
        link: '/admin/backup',
        text: 'tkey;admin.backup.title',
        dataAutomation: 'menu-backups-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/db-import',
        text: 'tkey;pages.admin.import.title',
        dataAutomation: 'menu-config-import-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/audit-import',
        text: 'tkey;pages.admin.audit.import.title',
        dataAutomation: 'menu-audit-import-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/cdr-import',
        text: 'tkey;pages.admin.cdr.import.title',
        dataAutomation: 'menu-cdr-import-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/high-availability-management',
        text: 'tkey;pages.admin.high.availability.management.title',
        dataAutomation: 'menu-high-availability-management-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
        isVisible: (flags) => flags.isHighAvailabilityEnabled,
      },
    ],
  },
  {
    icon: SmacsIcons.AUDITS,
    title: 'tkey;admin.menu.section.audits',
    dataAutomation: 'audits-section-header',
    links: [
      {
        link: '/admin/audits/logs',
        text: 'tkey;admin.audits.logs.title',
        dataAutomation: 'menu-audit-logs-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/audits/settings',
        text: 'tkey;admin.audits.settings.title',
        dataAutomation: 'menu-audit-settings-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
    ],
  },
];
