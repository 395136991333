<div class="modal-height">
  <smacs-directory-number-form
    [modalProperties]="modalProperties"
    (isLoadingDone)="isLoadingDone()"
    (isCancelClicked)="onCancelClicked($event)"
  ></smacs-directory-number-form>
</div>

<ng-template #errorTemplate>
  <span *ngIf="!areFormsValid">{{ 'tkey;validators.global.required.missing.error' | translate }}</span>
</ng-template>
