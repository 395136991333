import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { ButtonStyles } from '../../button/button.component';

@Component({
  selector: 'smacs-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class SmacsPaginationComponent {
  @Input() page = 1;
  @Input() pageSize = 10;
  @Input() totalResults = 0;
  @Input() dataAutomation = '';
  @Input() short = false;
  @Input() isViewLoading = false;
  @Input() disabled = false;

  @Output() pageChange = new EventEmitter<number>();
  @Output() pageChanged = new EventEmitter<void>();

  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;

  getFirstElementOfPage(): number {
    if (this.totalResults > 0) {
      return 1 + this.pageSize * (this.page - 1);
    }

    return 0;
  }

  getLastElementOfPage(): number {
    if (this.isOnLastPage()) {
      return this.totalResults;
    }
    return this.pageSize + (this.page - 1) * this.pageSize;
  }

  isOnFirstPage(): boolean {
    return this.page === 1;
  }

  isOnLastPage(): boolean {
    return this.totalResults <= this.page * this.pageSize;
  }

  incrementPage() {
    if (this.isOnLastPage()) {
      return;
    }

    this.page = this.page + 1;
    this.pageChange.emit(this.page);
    this.pageChanged.emit();
  }

  decrementPage() {
    if (this.isOnFirstPage()) {
      return;
    }

    this.page = this.page - 1;
    this.pageChange.emit(this.page);
    this.pageChanged.emit();
  }

  isPageLoading() {
    return this.isViewLoading;
  }
}
