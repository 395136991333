import { Injectable, NgZone } from '@angular/core';
import { PollingAbstractService } from '../../../shared/services/abstract/polling.abstract.service';
import { HttpClient } from '@angular/common/http';
import { JobStatus } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class PolicyMisconfigurationPollingContext extends PollingAbstractService<JobStatus> {
  baseUrl = '/services/microsoft/reporting/policy-misconfiguration/status';

  constructor(protected httpClient: HttpClient, protected ngZone: NgZone) {
    super(httpClient, ngZone);
  }
}
