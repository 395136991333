<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 d-flex flex-column h-100">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.billing.dlr_misconfigurations.about' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <app-loading-block *ngIf="isLoading"></app-loading-block>

    <div class="flex-grow-1" *ngIf="!isLoading">
      <smacs-dlr-misconfigurations-datatable
        (rowWasSelected)="handleRowSelected()"
      ></smacs-dlr-misconfigurations-datatable>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
