import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { Component, Input } from '@angular/core';
import { SmacsFieldComponentConfig } from '../../smacs-forms-models';
import { isNil } from 'lodash';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import jQuery from 'jquery';
import { ButtonStyles } from '../../../button/button.component';

export enum HtmlCheckboxType {
  CHECKBOX = 'CHECKBOX',
  SWITCH = 'SWITCH',
  LEFT_ALIGNED_CHECKBOX = 'LEFT_ALIGNED_CHECKBOX',
}

export enum HtmlSwitchSize {
  SM = 'SM',
  LG = 'LG',
}

export enum LabelTextSize {
  SM = 'SM',
  LG = 'LG',
}

export class SmacsCheckboxConfig extends SmacsFieldComponentConfig {
  constructor(
    public config?: {
      checkboxType?: HtmlCheckboxType;
      size?: HtmlSwitchSize;
      indeterminate?: boolean;
      isMenuToggle?: boolean;
      textSize?: LabelTextSize;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-checkbox',
  templateUrl: './smacs-checkbox.component.html',
  styleUrls: ['./smacs-checkbox.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsCheckboxComponent }],
})
export class SmacsCheckboxComponent extends SmacsFieldAbstractDirective<boolean, boolean, SmacsCheckboxConfig> {
  @Input() isAgentExtension: boolean;
  @Input() isAlwaysRequired: boolean;
  htmlCheckboxType = HtmlCheckboxType;
  htmlSwitchSize = HtmlSwitchSize;
  labelTextSize = LabelTextSize;
  checkboxType = HtmlCheckboxType.CHECKBOX;
  size = HtmlSwitchSize.SM;
  indeterminate = false;
  buttonStyles = ButtonStyles;
  isMenuToggle = false;
  textSize = LabelTextSize.SM;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  applyComponentConfig = ({ config }: SmacsCheckboxConfig) => {
    this.checkboxType = isNil(config?.checkboxType) ? this.checkboxType : config.checkboxType;
    this.size = isNil(config?.size) ? this.size : config.size;
    this.isMenuToggle = config?.isMenuToggle;
    this.indeterminate = isNil(config?.indeterminate) ? this.indeterminate : config.indeterminate;
    this.textSize = isNil(config?.textSize) ? this.textSize : config.textSize;

    if (this.indeterminate) {
      jQuery(function () {
        jQuery('input.indeterminate-input').prop('indeterminate', true);
      });
    }
  };

  misconfigurationWarningMessage = () => 'tkey;smacs_forms.misconfiguration_feedback';

  // As stupid as this seems, it addresses an issue with the checkbox being initialized with a null value.
  toFieldData = (entity: boolean): boolean => !!entity;
}
