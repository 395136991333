import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OrphanedTile } from '../../../state-management/models/report-dashboard.model';
import { Store } from '@ngrx/store';
import { DashboardState } from '../../dashboard.state';
import { dashboardActions } from '../../../state-management/actions/dashboard.actions';
import { initialOrphanedtile } from '../../../state-management/reducers/orphaned-tile.reducer';

@Component({
  selector: 'app-dashboard-orphaned-devices-tile',
  templateUrl: './dashboard-orphaned-devices-tile.component.html',
})
export class DashboardOrphanedDevicesTileComponent implements OnInit {
  orphanedTileData$: Observable<OrphanedTile>;

  constructor(private store: Store<DashboardState>) {
    this.orphanedTileData$ = this.store.select('reportDashboardOrphanedTile');
  }

  ngOnInit() {
    /**
     * When the component is first initialized, an action is dispatched to load the tile data.
     * An effect will be listening to this action and will fetch the tile data.
     * The reducer will update the state of the tile and send it back to the component.
     */
    this.store.dispatch(dashboardActions.loadOrphanedTileAction(initialOrphanedtile));
  }
}
