import { Component, OnDestroy, OnInit } from '@angular/core';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CucmQueriesResource } from '../../resources/cucm-queries.resource';
import { CustomCucmReport, Privilege } from '../../../../shared/models/generated/smacsModels';
import { combineLatest, Observable, Subscriber, Subscription } from 'rxjs';
import { ToastService } from '../../../../shared/services/toast.service';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { BottomNavButton } from '../../../../shared/bottom-nav/bottom-nav.component';
import { first, map } from 'rxjs/operators';
import { AuthenticationContext } from '../../../../shared/contexts/authentication.context';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../../shared/entity-table/entity-table.models';
import { DateTimeFormat } from '../../../../shared/filters/date-time-format.filter';

@Component({
  selector: 'app-custom-reports-list',
  templateUrl: './custom-reports-list.component.html',
  styleUrls: ['../../../reporting.scss'],
  providers: [DateTimeFormat],
})
export class CustomReportsListComponent implements OnInit, OnDestroy {
  cucmServers: { id: number; description: string }[] = [];
  serverIdLabelMap: { [key: number]: string } = {};
  isLoading = true;
  isReadOnly: boolean;
  exportingReports = new Set<number>();
  reports: CustomCucmReport[] = [];
  filteredReports: CustomCucmReport[] = [];
  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];

  private _subs = new Subscription();

  constructor(
    private _bottomNavService: BottomNavService,
    private _breadcrumbsService: BreadcrumbsService,
    private _cucmQueriesResource: CucmQueriesResource,
    private siteSummaryContext: SiteSummaryContext,
    private _router: Router,
    private _route: ActivatedRoute,
    private _toastService: ToastService,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private authenticationContext: AuthenticationContext,
    private _dateTimeFormat: DateTimeFormat
  ) {}

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;reporting.custom_reports.title' }]);

    const reportObservable = this._refreshTable();

    const authenticationObservable = this.authenticationContext.state$.pipe(
      map((user) => {
        this.isReadOnly = !user.privileges.includes(Privilege.CONTROL_WRITE);
      })
    );

    this._subs.add(
      combineLatest([reportObservable, authenticationObservable]).subscribe(() => {
        this._initBottomNav();
        this.isLoading = false;
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private _refreshTable(): Observable<void> {
    return combineLatest([this.siteSummaryContext.state$, this._cucmQueriesResource.getAllReports()]).pipe(
      first(),
      map(([siteSummary, reports]) => {
        this.serverIdLabelMap = siteSummary.clusters.reduce<{ [id: number]: string }>((serverMap, cluster) => {
          if (!serverMap[cluster.cucmServerId]) {
            serverMap[cluster.cucmServerId] = cluster.cucmServerDescription;
          }
          return serverMap;
        }, {});
        this.cucmServers = Object.entries(this.serverIdLabelMap).map(([key, value]) => {
          return { id: Number(key), description: value };
        });

        this.reports = reports;
        this.filteredReports = reports;
        this._buildTable();
      })
    );
  }

  private _buildTable() {
    const servers = this.cucmServers.map((server) => server.description);
    this.table = {
      columns: [
        {
          columnId: 'reportName',
          cssColumnSize: 'col-sm-4',
          label: 'tkey;reporting.custom_reports.custom_report_list.report_name',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'serverId',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;reporting.custom_reports.custom_report_list.cucm_server',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: servers,
          },
        },
        {
          columnId: 'lastUpdated',
          cssColumnSize: 'col-sm-3',
          label: 'tkey;reporting.custom_reports.custom_report_list.last_updated',
        },
      ],
      cssClearButtonColumnSize: 'col-sm-3',
      resultsMessage: this.isReadOnly
        ? 'tkey;reporting.custom_reports.custom_report_list.no_results.read_only'
        : 'tkey;reporting.custom_reports.custom_report_list.no_results',
      hasActions: true,
    };
    this._createTableRow();
  }

  deleteClicked(report: CustomCucmReport) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        title: this._translateService.instant('tkey;reporting.custom_reports.custom_report_list.delete.modal.title'),
        promptBody: this._translateService.instant(
          'tkey;reporting.custom_reports.custom_report_list.delete.modal.message',
          {
            reportName: report.reportName,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteReport(report),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  exportClicked(report: CustomCucmReport) {
    this.exportingReports.add(report.id);
    this._createTableRow();
    this._cucmQueriesResource.getExportToExcel(report.id).subscribe(() => {
      this._toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.EXPORT,
        'tkey;reporting.xlsx_export.downloaded_toast.title',
        'tkey;reporting.xlsx_export.downloaded_toast.message'
      );
      this.exportingReports.delete(report.id);
      this._createTableRow();
    });
  }

  editClicked(report: CustomCucmReport) {
    const id = report.id;
    this._router.navigate([id], { relativeTo: this._route });
  }

  private _deleteReport(report: CustomCucmReport): Observable<void> {
    this.isLoading = true;
    return new Observable((subscriber: Subscriber<void>) => {
      this._cucmQueriesResource.deleteReport(report.id).subscribe(() => {
        this._toastService.pushDeleteToast(
          'tkey;reporting.custom_reports.custom_report_list.toast.title',
          report.reportName
        );
        this._refreshTable().subscribe(() => (this.isLoading = false));
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }

  private _onAddReportClicked() {
    this._router.navigate(['new-report'], { relativeTo: this._route });
  }

  private _initBottomNav() {
    const bottomNavButtons: BottomNavButton[] = [];

    if (!this.isReadOnly) {
      bottomNavButtons.push({
        id: 'customReportsAddReport',
        dataAutomation: 'custom-reports-add-report-button',
        label: 'tkey;reporting.custom_reports.add_report.label',
        icon: SmacsIcons.ADD,
        buttonClass: ButtonStyles.PRIMARY,
        cb: () => {
          this._onAddReportClicked();
        },
      });
    }

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _createTableRow() {
    this.tableRows = this.filteredReports.map((report) => {
      return {
        content: {
          reportName: report.reportName,
          serverId: this.serverIdLabelMap[report.serverId],
          lastUpdated: this._dateTimeFormat.transform(report.lastUpdated),
        },
        actions: [
          {
            buttonStyle: ButtonStyles.DANGER,
            dataAutomation: 'report-delete',
            icon: SmacsIcons.DELETE,
            isHidden: this.isReadOnly,
            isDisabled: this.exportingReports.has(report.id),
            tooltip: this.exportingReports.has(report.id)
              ? 'tkey;reporting.xlsx_edit_delete.button.disabled.tooltip'
              : '',
            onClick: () => this.deleteClicked(report),
          },
          {
            buttonStyle: ButtonStyles.INFO,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/${report.id}`,
            dataAutomation: 'report-edit',
            icon: SmacsIcons.EDIT,
            isHidden: this.isReadOnly,
            isDisabled: this.exportingReports.has(report.id),
            tooltip: this.exportingReports.has(report.id)
              ? 'tkey;reporting.xlsx_edit_delete.button.disabled.tooltip'
              : '',
            onClick: () => this.editClicked(report),
          },
          {
            buttonStyle: ButtonStyles.PRIMARY,
            dataAutomation: 'report-export',
            icon: SmacsIcons.EXPORT,
            isDisabled: this.exportingReports.has(report.id),
            label: 'tkey;reporting.xlsx_export.button',
            isPending: this.exportingReports.has(report.id),
            iconPending: SmacsIcons.SAVING,
            onClick: () => this.exportClicked(report),
          },
        ],
        actionsColumnSize: 'col-sm-3',
      };
    });
  }
}
