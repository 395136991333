<div *ngIf="this.dataTableRows.length > 0" data-automation="ignored-users-content" class="smacs-report-content">
  <smacs-datatable
    [columns]="dataTableCols"
    [data]="dataTableRows"
    [showSelectColumn]="true"
    [mainFilterPlaceholder]="
      ('tkey;reporting.datatable.search_placeholder' | translate) +
      ('tkey;pages.zero_touch.provisioning.edit_job.ignored_users.username' | translate)
    "
    (rowSelected)="onRowSelected($event)"
    [dataAutomation]="tableDataAutomation"
  ></smacs-datatable>
</div>
