import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { BottomNavButton } from '../../../../shared/bottom-nav/bottom-nav.component';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EMPTY, Observable, Subscriber, Subscription, throwError } from 'rxjs';
import { RouteHelpersService } from '../../../../shared/services/route-helpers.service';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { ZeroTouchDeprovisioningResource } from '../../../../shared/resources/zpc-zero-touch.abstract.resource';
import { find, isMatch } from 'lodash';
import { ZeroTouchSchedulingService } from '../../../services/zero-touch-scheduling.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { DeprovisioningSchedule, JobState, JobStatus } from '../../../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { SmacsRadioConfig } from '../../../../forms/fields/radio/smacs-radio.component';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SCHEDULE_FREQUENCY } from '../../../../shared/models/zero-touch';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { CiscoDeprovisioningStatusPollingService } from '../../../../shared/services/cisco/cisco-deprovisioning-status-polling.service';

interface DeprovisioningScheduleFormData extends DeprovisioningSchedule {
  runAutomatically?: boolean;
  scheduleFrequency?: SCHEDULE_FREQUENCY;
}

@Component({
  selector: 'app-admin-zero-touch-deprovisioning-edit-job',
  templateUrl: './deprovisioning-edit-job.component.html',
  providers: [CiscoDeprovisioningStatusPollingService],
})
export class DeprovisioningEditJobComponent
  extends SmacsFormAbstractDirective<DeprovisioningScheduleFormData>
  implements OnDestroy, OnInit
{
  isLoading = true;
  jobId: number;
  isRunning: boolean;
  smacsIcons = SmacsIcons;
  nextRunTimesMessage = '';
  bottomNavButtons = [
    {
      id: 'runJobNow',
      label: 'tkey;pages.zero_touch.job.run_now',
      dataAutomation: 'admin-zero-touch-run-job-button',
      buttonClass: ButtonStyles.INFO,
      tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip.disabled',
      state: {
        pending: false,
        buttonDisableState: { disabled: true, tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip.disabled' },
        tooltipVisible: true,
      },
      icon: this.smacsIcons.RUN,
      cb: () => {
        this._runJobNow();
      },
    },
    {
      id: 'cancelAddJob',
      label: 'tkey;global.button.cancel.text',
      dataAutomation: 'admin-zero-touch-cancel-job-add-button',
      buttonClass: ButtonStyles.OUTLINE_DEFAULT,
      tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip',
      state: {
        pending: false,
        buttonDisableState: { disabled: false },
        tooltipVisible: false,
      },
      cb: () => {
        this.routeHelpersService.goToParent(this.route, 'jobId');
      },
    },
    {
      id: 'saveJob',
      label: 'tkey;global.button.save.text',
      buttonClass: ButtonStyles.PRIMARY,
      dataAutomation: 'admin-zero-touch-save-job-button',
      state: {
        pending: false,
        buttonDisableState: { disabled: true, tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip' },
        tooltipVisible: false,
      },
      icon: this.smacsIcons.OK,
      type: ButtonTypes.SUBMIT,
      submitSubject: this._validateAndSubmitSource,
    },
  ] as BottomNavButton[];
  validators = {
    isNameUnique: (val: string): Observable<SmacsFormsValidationState> =>
      new Observable<SmacsFormsValidationState>((subscriber: Subscriber<SmacsFormsValidationState>) => {
        this.zeroTouchDeprovisioningResource.getAll().subscribe((existingJobs: DeprovisioningSchedule[]) => {
          const match = find(
            existingJobs,
            (job: DeprovisioningSchedule) => this.jobId !== job.id && job.name.toLowerCase() === val.toLowerCase()
          );

          subscriber.next(match ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID);
          subscriber.complete();
        });
      }),
  };

  formConfig = {
    fields: {
      name: {
        label: 'tkey;pages.zero_touch.job_name',
        dataAutomation: 'deprovisioning-job-name',
        required: true,
        validation: [
          {
            validator: this.validators.isNameUnique,
            message: 'tkey;pages.zero_touch.name.unique',
          },
        ],
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        helpText: 'tkey;pages.zero_touch.provisioning.edit_job.help_text.job_name',
      },
      makeDeskphonesPublicEnabled: {
        label: 'tkey;pages.zero_touch.deprovisioning.job.label.make_deskphones_public',
        dataAutomation: 'zero-touch-make-public',
        helpText: 'tkey;pages.zero_touch.deprovisioning.job.help_text.make_deskphones_public',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH }),
      },
      keepVoicemailEnabled: {
        label: 'tkey;pages.zero_touch.deprovisioning.job.label.keep_voicemail',
        dataAutomation: 'zero-touch-keep-voicemail',
        helpText: 'tkey;pages.zero_touch.deprovisioning.job.help_text.keep_voicemail',
        hidden: () => !this.formData.makeDeskphonesPublicEnabled,
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH }),
      },
      runAutomatically: {
        label: 'tkey;pages.zero_touch.edit.form.run_automatically',
        dataAutomation: 'zero-touch-run-automatically',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH }),
      },
      scheduleFrequency: {
        label: 'tkey;pages.zero_touch.schedule',
        dataAutomation: 'zero-touch-schedule-frequency',
        required: () => this.formData.runAutomatically,
        hidden: () => !this.formData.runAutomatically,
        componentConfig: new SmacsRadioConfig({
          buttons: [
            {
              value: SCHEDULE_FREQUENCY.PERIODICALLY,
              label: 'tkey;pages.zero_touch.edit.form.periodically',
            },
            {
              value: SCHEDULE_FREQUENCY.DAILY,
              label: 'tkey;pages.zero_touch.edit.form.daily',
            },
          ],
          inline: true,
        }),
      },
      periodicJobMinutes: {
        label: 'tkey;pages.zero_touch.edit.form.every',
        dataAutomation: 'zero-touch-schedule-interval',
        required: () =>
          this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.PERIODICALLY && this.formData.runAutomatically,
        hidden: () =>
          this.formData.scheduleFrequency !== SCHEDULE_FREQUENCY.PERIODICALLY || !this.formData.runAutomatically,
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        helpText: () => this.nextRunTimesMessage,
      },
      dailyJobTime: {
        label: 'tkey;pages.zero_touch.edit.form.daily_at_time',
        dataAutomation: 'zero-touch-schedule-time',
        required: () => this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.DAILY && this.formData.runAutomatically,
        hidden: () => this.formData.scheduleFrequency !== SCHEDULE_FREQUENCY.DAILY || !this.formData.runAutomatically,
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TIME }),
      },
    },
  } as SmacsFormConfig;

  isSubmitting = false;

  job: DeprovisioningSchedule;

  private _subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private zeroTouchDeprovisioningResource: ZeroTouchDeprovisioningResource,
    private bottomNavService: BottomNavService,
    private breadcrumbsService: BreadcrumbsService,
    private toastService: ToastService,
    private routeHelpersService: RouteHelpersService,
    private zeroTouchSchedulingService: ZeroTouchSchedulingService,
    protected smacsFormStateService: SmacsFormStateService,
    private ciscoDeprovisioningStatusPollingService: CiscoDeprovisioningStatusPollingService,
    private _translateService: TranslateService,
    private _smacsModalService: SmacsModalService,
    private _router: Router
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.jobId = parseInt(this.route.snapshot.params.jobId) || null;

    if (this.jobId) {
      this.bottomNavButtons.splice(2, 0, {
        id: 'bottom-nav-delete-button',
        dataAutomation: 'bottom-nav-delete-button',
        label: 'tkey;global.button.delete.text',
        icon: SmacsIcons.DELETE,
        buttonClass: ButtonStyles.DANGER,
        cb: () => {
          this._onDeleteClicked();
        },
      });
    }

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;pages.zero_touch.deprovisioning.title' }]);

    const formUpdateSub = this.smacsFormsUpdate$.subscribe(
      (update: SmacsFormsUpdate<DeprovisioningScheduleFormData>) => {
        if (update?.new) {
          this._setRunNowState();
          if (
            update.new.periodicJobMinutes &&
            (!update.old || update.new.periodicJobMinutes !== update.old?.periodicJobMinutes)
          ) {
            this._setRunTimeMessage(update.new.periodicJobMinutes.toString());
          }
        }
      }
    );
    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this.isSubmitting = true;
      this._setButtonState('saveJob', true, true);
      this._setButtonState('runJobNow', true, false);
      this._setButtonState('cancelAddJob', true, false);
      const errorPresentSub = this.isErrorPresent.subscribe((hasError: boolean) => {
        if (hasError) {
          this._setButtonState('saveJob', false, false);
          this._setButtonState('runJobNow', false, false);
          this._setButtonState('cancelAddJob', false, false);
        }
        this.bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: hasError,
          })
        );
      });
      this._subs.add(errorPresentSub);
    });
    this._subs.add(formSubmittedSub);
    this._subs.add(formUpdateSub);

    this._getJob().subscribe(() => {
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subs.unsubscribe();
    this.ciscoDeprovisioningStatusPollingService.stopPolling();
  }

  protected submit() {
    return this._saveDeprovisioningJob();
  }

  private _getJob(): Observable<void> {
    return new Observable<void>((subscriber) => {
      if (this.jobId) {
        this.zeroTouchDeprovisioningResource.get(this.jobId).subscribe((job: DeprovisioningSchedule) => {
          this.job = job;
          this.breadcrumbsService.updateBreadcrumbs([
            { label: 'tkey;pages.zero_touch.deprovisioning.title', url: `/app2/#/automate/deprovisioning` },
            { label: job.name },
          ]);
          this.entitySource.next({
            id: job.id,
            name: job.name,
            dailyJobTime: job.dailyJobTime,
            periodicJobMinutes: job.periodicJobMinutes,
            makeDeskphonesPublicEnabled: job.makeDeskphonesPublicEnabled,
            keepVoicemailEnabled: job.keepVoicemailEnabled,
            runAutomatically:
              this._getScheduleFrequency(job) === SCHEDULE_FREQUENCY.PERIODICALLY ||
              this._getScheduleFrequency(job) === SCHEDULE_FREQUENCY.DAILY,
            scheduleFrequency: this._getScheduleFrequency(job),
          });
          this._setButtonState('saveJob', false, false);
          this._setButtonState('runJobNow', false, false);
          this._startPollingForStatuses();
          if (job.periodicJobMinutes) {
            this._setRunTimeMessage(job.periodicJobMinutes.toString());
          }
          subscriber.next();
          subscriber.complete();
        });
      } else {
        this.entitySource.next({
          dailyJobTime: null,
          keepVoicemailEnabled: false,
          makeDeskphonesPublicEnabled: false,
          name: '',
          periodicJobMinutes: null,
        } as DeprovisioningScheduleFormData);
        this.breadcrumbsService.updateBreadcrumbs([
          { label: 'tkey;pages.zero_touch.deprovisioning.title', url: `/app2/#/automate/deprovisioning` },
          { label: 'tkey;pages.zero_touch.job.edit.breadcrumb.new_job' },
        ]);
        this._setButtonState('saveJob', false, false);
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  private _getScheduleFrequency(job: DeprovisioningSchedule): SCHEDULE_FREQUENCY {
    if (job.dailyJobTime) {
      return SCHEDULE_FREQUENCY.DAILY;
    } else if (job.periodicJobMinutes) {
      return SCHEDULE_FREQUENCY.PERIODICALLY;
    }

    return null;
  }

  private _runJobNow() {
    if (this.jobId) {
      this.isRunning = true;
      this._setButtonState('saveJob', true, false);
      this._setButtonState('runJobNow', true, true);
      this._setButtonState('cancelAddJob', true, false);

      this.zeroTouchDeprovisioningResource
        .runJob(this.jobId)
        .pipe(
          catchError((response) => {
            this.isRunning = false;
            this._setButtonState('saveJob', false, false);
            this._setButtonState('runJobNow', false, false);
            this._setButtonState('cancelAddJob', false, false);
            return throwError(() => response);
          })
        )
        .subscribe();
    }
  }

  private _saveDeprovisioningJob(): Observable<DeprovisioningSchedule> {
    const isScheduledDaily = this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.DAILY;
    const isScheduledPeriodically = this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.PERIODICALLY;

    const job: DeprovisioningSchedule = {
      id: this.jobId,
      dailyJobTime: isScheduledDaily && this.formData.runAutomatically ? this.formData.dailyJobTime : null,
      keepVoicemailEnabled: this.formData.makeDeskphonesPublicEnabled ? this.formData.keepVoicemailEnabled : false,
      makeDeskphonesPublicEnabled: this.formData.makeDeskphonesPublicEnabled,
      name: this.formData.name,
      periodicJobMinutes:
        isScheduledPeriodically && this.formData.runAutomatically ? this.formData.periodicJobMinutes : null,
    };
    const saveJob$ = this.jobId
      ? this.zeroTouchDeprovisioningResource.put(this.jobId, { id: this.jobId, ...job })
      : this.zeroTouchDeprovisioningResource.post(job);

    return saveJob$.pipe(
      tap(() => {
        this.isSubmitting = false;

        this.toastService.pushSaveToast(
          'tkey;pages.zero_touch.deprovisioning.title',
          this.formData.name,
          this.smacsIcons.ARCHIVE
        );
        this.routeHelpersService.goToParent(this.route, 'jobId');
      }),
      catchError(() => {
        this.isSubmitting = false;

        this.toastService.push(
          ToastTypes.ERROR,
          this.smacsIcons.ARCHIVE,
          'tkey;pages.zero_touch.deprovisioning.save.error',
          'tkey;global.error.contact_admin'
        );
        this._setButtonState('saveJob', false, false);
        this._setButtonState('cancelAddJob', false, false);
        return throwError(() => EMPTY);
      })
    );
  }

  private _setButtonState(buttonId: string, disabled: boolean, pending: boolean, tooltipVisible = false) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: {
          pending: pending,
          buttonDisableState: { disabled: disabled, tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip.disabled' },
          tooltipVisible: tooltipVisible,
        },
      })
    );
  }

  private _setRunTimeMessage(periodicJobMinutes: string) {
    if (!periodicJobMinutes) {
      this.nextRunTimesMessage = '';
    } else {
      this.nextRunTimesMessage = this.zeroTouchSchedulingService.getNextRunTimeMessage(periodicJobMinutes.toString());
    }
  }

  private _startPollingForStatuses() {
    if (this.jobId) {
      this.ciscoDeprovisioningStatusPollingService.setBaseUrl(this.jobId.toString());
      this.ciscoDeprovisioningStatusPollingService.startPolling();
      this.ciscoDeprovisioningStatusPollingService.state$.subscribe((status: JobStatus) => {
        if (status.jobState === JobState.RUNNING || status.jobState === JobState.QUEUED) {
          this._setButtonState('saveJob', true, false);
          this._setRunNowState(true);
          this._setButtonState('cancelAddJob', true, false);
        } else {
          this._setButtonState('saveJob', this.isSubmitting, this.isSubmitting);
          this._setRunNowState();
          this._setButtonState('cancelAddJob', this.isSubmitting, false);

          if (this.isRunning) {
            this.isRunning = false;
            this.toastService.push(
              ToastTypes.SUCCESS,
              this.smacsIcons.AUTOMATION,
              'tkey;pages.zero_touch.job.completed',
              this.formData.name
            );
          }
        }
      });
    }
  }

  private _setRunNowState(isRunning = false) {
    if (!isRunning) {
      if (this.job && this.formData) {
        const formData = this._formDataToEntity();
        const jobCopy = { ...this.job };
        delete jobCopy.id;

        if (!isMatch(jobCopy, formData)) {
          this._setButtonState('runJobNow', true, false, true);
        } else {
          this._setButtonState('runJobNow', false, false);
        }
      }
    } else {
      this._setButtonState('runJobNow', true, true);
    }
  }

  private _formDataToEntity(): DeprovisioningSchedule {
    return {
      dailyJobTime: this.formData.dailyJobTime,
      keepVoicemailEnabled: this.formData.keepVoicemailEnabled,
      makeDeskphonesPublicEnabled: this.formData.makeDeskphonesPublicEnabled,
      name: this.formData.name,
      periodicJobMinutes: this.formData.periodicJobMinutes,
    } as DeprovisioningSchedule;
  }

  private _onDeleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        promptBody: this._translateService.instant('tkey;pages.zero_touch.modal_delete.body', {
          name: this.entity.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteGroup();
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteGroup(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this.zeroTouchDeprovisioningResource.delete(this.jobId).subscribe(() => {
        this.toastService.pushDeleteToast('tkey;shared.toast.delete.success.title', this.entity.name);
        this._router.navigate(['/automate/deprovisioning']);
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }
}
