import { Injectable } from '@angular/core';
import { SearchFieldConfigResource } from '../../helpdesk/shared/resources/field-config/search-field-config.resource';
import { Observable, ReplaySubject } from 'rxjs';
import { SearchFieldConfig } from '../models/generated/smacsModels';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SearchFieldConfigContext {
  _stateSource = new ReplaySubject<SearchFieldConfig>(1);
  state$ = this._stateSource.asObservable();

  constructor(private searchFieldConfigResource: SearchFieldConfigResource) {
    this.refresh().subscribe();
  }

  refresh(): Observable<void> {
    return this.searchFieldConfigResource.get().pipe(
      map((fieldConfig) => {
        return this._stateSource.next(fieldConfig);
      })
    );
  }
}
