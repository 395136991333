import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SmacsTextComponent } from './fields/text/smacs-text.component';
import { SmacsRadioComponent } from './fields/radio/smacs-radio.component';
import { SmacsCheckboxComponent } from './fields/checkbox/smacs-checkbox.component';
import { SmacsSelectComponent } from './fields/select/smacs-select.component';
import { SmacsMultiTextComponent } from './fields/multi-text/smacs-multi-text.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SmacsFileUploadComponent } from './fields/file-upload/smacs-file-upload.component';
import { SmacsMultiCheckboxComponent } from './fields/multi-checkbox/smacs-multi-checkbox.component';
import { SmacsExtensionRangesComponent } from './fields/extension-ranges/smacs-extension-ranges.component';
import { SmacsSingleExtensionRangeFormComponent } from './fields/extension-ranges/single-extension-range/smacs-single-extension-range-form.component';
import { SmacsExtensionRangesFormComponent } from './fields/extension-ranges/smacs-extension-ranges-form.component';
import { SmacsTextareaComponent } from './fields/textarea/smacs-textarea.component';
import { SmacsColorPickerComponent } from './fields/color-picker/smacs-color-picker.component';
import { SmacsFieldsetHeaderComponent } from './fieldset-header/smacs-fieldset-header.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { SmacsFormStateService } from './smacs-form-state.service';
import { SmacsSubmitButtonComponent } from './submit-button/smacs-submit-button.component';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { SmacsCodeAreaComponent } from './fields/code-area/smacs-code-area.component';
import { ButtonModule } from '../button/button.module';
import { SmacsSingleExtensionRangeContainerComponent } from './fields/extension-ranges/single-extension-range/smacs-single-extension-range-container.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ExtensionRangesDisplayFormComponent } from './fields/extension-ranges/extension-ranges-display-form/extension-ranges-display-form.component';
import { SmacsFilterDateComponent } from '../shared/filter/filter-date/smacs-filter-date.component';
import { SmacsFilterSelectComponent } from '../shared/filter/filter-select/filter-select.component';
import { SmacsFilterCheckboxComponent } from '../shared/filter/filter-checkbox/filter-checkbox.component';
import { SmacsNavsetFormComponent } from '../shared/smacs-navset/smacs-navset-form/smacs-navset-form.component';
import { SmacsNavsetFormNavComponent } from '../shared/smacs-navset/smacs-navset-form/smacs-navset-form-nav/smacs-navset-form-nav.component';
import { SmacsNavsetFormNavItemsComponent } from '../shared/smacs-navset/smacs-navset-form/smacs-navset-form-nav/smacs-navset-form-nav-items/smacs-navset-form-nav-items.component';
import { SmacsNavsetComponent } from '../shared/smacs-navset/smacs-navset.component';
import { SmacsFilterInputComponent } from '../shared/filter/filter-input/filter-input.component';
import { SmacsCheckboxConfigComponent } from '../shared/custom-configs/smacs-checkbox-config/smacs-checkbox-config.component';
import { CheckboxConfigFormComponent } from '../shared/custom-configs/smacs-checkbox-config/checkbox-config-form/checkbox-config-form.component';
import { SmacsViewMembersComponent } from '../shared/view-members/view-members.component';
import { legacySmacsTextConfigComponent } from '../shared/custom-configs/legacy-smacs-text-config/legacy-smacs-text-config.component';
import { TextConfigFormComponent } from '../shared/custom-configs/legacy-smacs-text-config/text-config-form/text-config-form.component';
import { LegacySmacsSelectConfigComponent } from '../shared/custom-configs/legacy-smacs-select-config/legacy-smacs-select-config.component';
import { SelectConfigFormComponent } from '../shared/custom-configs/legacy-smacs-select-config/select-config-form/select-config-form.component';
import { SmacsReadWriteSelectConfigComponent } from '../shared/custom-configs/smacs-read-write-select-config/smacs-read-write-select-config.component';
import { ReadWriteSelectConfigFormComponent } from '../shared/custom-configs/smacs-read-write-select-config/read-write-select-config-form/read-write-select-config-form.component';
import { ReadWriteSelectConfigFormTextComponent } from '../shared/custom-configs/smacs-read-write-select-config/read-write-select-config-form/read-write-select-config-form-text.component';
import { ZiroAsyncMultiSelectConfigComponent } from '../shared/custom-configs/smacs-multi-select-config/ziro-async-multi-select-config.component';
import { MultiSelectConfigFormComponent } from '../shared/custom-configs/smacs-multi-select-config/multi-select-config-form/multi-select-config-form.component';
import { CollapsibleCardComponent } from '../shared/collapsible-card/collapsible-card.component';
import { TelephoneNumberFilter } from '../shared/filters/telephone-number.filter';
import { SmacsCheckboxButtonFieldComponent } from './fields/checkbox-button/checkbox-button-field.component';
import { CheckboxButtonModule } from '../shared/checkbox-button/checkbox-button.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DateComponent } from './fields/date/date.component';
import { ZiroCustomInputTextComponent } from '../shared/custom-configs/custom-input-text/ziro-custom-input-text.component';
import { ZiroCustomSelectComponent } from '../shared/custom-configs/custom-select/ziro-custom-select.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    CalendarModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    ColorPickerModule,
    CodemirrorModule,
    ButtonModule,
    ScrollingModule,
    CheckboxButtonModule,
    AngularSvgIconModule.forRoot(),
  ],
  declarations: [
    SmacsTextComponent,
    SmacsRadioComponent,
    SmacsCheckboxComponent,
    SmacsSelectComponent,
    SmacsMultiTextComponent,
    SmacsFileUploadComponent,
    SmacsMultiCheckboxComponent,
    SmacsExtensionRangesComponent,
    SmacsSingleExtensionRangeFormComponent,
    SmacsExtensionRangesFormComponent,
    SmacsSingleExtensionRangeContainerComponent,
    SmacsTextareaComponent,
    SmacsColorPickerComponent,
    SmacsFieldsetHeaderComponent,
    SmacsSubmitButtonComponent,
    SmacsCodeAreaComponent,
    ExtensionRangesDisplayFormComponent,
    SmacsFilterDateComponent,
    SmacsFilterSelectComponent,
    SmacsFilterCheckboxComponent,
    SmacsFilterInputComponent,
    SmacsNavsetFormComponent,
    SmacsNavsetFormNavComponent,
    SmacsNavsetFormNavItemsComponent,
    SmacsNavsetComponent,
    SmacsCheckboxConfigComponent,
    CheckboxConfigFormComponent,
    SmacsViewMembersComponent,
    legacySmacsTextConfigComponent,
    TextConfigFormComponent,
    LegacySmacsSelectConfigComponent,
    SelectConfigFormComponent,
    SmacsReadWriteSelectConfigComponent,
    ReadWriteSelectConfigFormComponent,
    ReadWriteSelectConfigFormTextComponent,
    ZiroAsyncMultiSelectConfigComponent,
    MultiSelectConfigFormComponent,
    CollapsibleCardComponent,
    TelephoneNumberFilter,
    SmacsCheckboxButtonFieldComponent,
    DateComponent,
    ZiroCustomInputTextComponent,
    ZiroCustomSelectComponent,
  ],
  exports: [
    SmacsTextComponent,
    SmacsRadioComponent,
    SmacsCheckboxComponent,
    SmacsSelectComponent,
    SmacsMultiTextComponent,
    SmacsFileUploadComponent,
    SmacsMultiTextComponent,
    SmacsMultiCheckboxComponent,
    SmacsExtensionRangesComponent,
    SmacsTextareaComponent,
    SmacsColorPickerComponent,
    SmacsFieldsetHeaderComponent,
    SmacsSubmitButtonComponent,
    SmacsCodeAreaComponent,
    ExtensionRangesDisplayFormComponent,
    SmacsFilterDateComponent,
    SmacsFilterSelectComponent,
    SmacsFilterCheckboxComponent,
    SmacsFilterInputComponent,
    SmacsNavsetFormComponent,
    SmacsNavsetFormNavComponent,
    SmacsNavsetFormNavItemsComponent,
    SmacsNavsetComponent,
    SmacsCheckboxConfigComponent,
    CheckboxConfigFormComponent,
    SmacsViewMembersComponent,
    legacySmacsTextConfigComponent,
    TextConfigFormComponent,
    LegacySmacsSelectConfigComponent,
    SmacsReadWriteSelectConfigComponent,
    ZiroAsyncMultiSelectConfigComponent,
    CollapsibleCardComponent,
    TelephoneNumberFilter,
    SmacsCheckboxButtonFieldComponent,
    DateComponent,
    ZiroCustomInputTextComponent,
    ZiroCustomSelectComponent,
  ],
  providers: [SmacsFormStateService],
})
export class SmacsFormsModule {}
