<legend>{{ 'tkey;userdetail.home.associated_public_phones.header' | translate }}</legend>
<p>{{ 'tkey;shared.device.phone.no_owner.description_text' | translate }}</p>

<ul>
  <li
    *ngFor="let phone of associatedPublicPhones; let i = index"
    [attr.data-is-deskphone]="isDeskphone(phone)"
    [attr.data-automation]="'associated-phone'"
  >
    <span *ngIf="isDeskphone(phone)">
      <a [routerLink]="'/public-phone/' + phone.name" class="me-1">
        <strong data-automation="associated-phone-name">{{ phone.name }}</strong>
      </a>
      <span data-automation="associated-phone-description">{{ phone.description }}</span>
    </span>
    <span *ngIf="!isDeskphone(phone)">
      <strong data-automation="associated-phone-name" class="me-1">{{ phone.name }}</strong>
      <span data-automation="associated-phone-description">{{ phone.description }}</span>
      <span *ngIf="!phone.name.includes('CTI')" class="smacs-forms-validation-message text-warning d-inline">
        - {{ 'tkey;shared.device.phone.no_owner' | translate }}
      </span>
      <smacs-button
        *ngIf="!phone.name.includes('CTI')"
        [buttonStyle]="buttonStyles.LINK"
        [dataAutomation]="'associated-devices-fix-it'"
        [label]="
          phone.isUpdating
            ? 'tkey;shared.device.phone.updating_owner'
            : 'tkey;shared.html.customizable_fields.text_input.fix_it.label'
        "
        [cssClass]="'ms-1'"
        [isDisabled]="phone.isUpdating"
        (clicked)="onFixItClick(i)"
      ></smacs-button>
      <span *ngIf="phone.isUpdating" class="icon-saving icon-warning multi-select-loading ms-1"></span>
    </span>
  </li>
</ul>
