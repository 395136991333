import { Component, OnInit } from '@angular/core';
import { ServersContext } from '../../../../admin/contexts/servers.context';
import { CustomCucmReport, Server } from '../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { CucmQueriesResource } from '../../resources/cucm-queries.resource';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-cucm-report-builder',
  templateUrl: './cucm-report-builder.component.html',
  styleUrls: ['../../../reporting.scss'],
})
export class CucmReportBuilderComponent implements OnInit {
  isLoading = false;
  servers: Server[] = [];
  formConfig: SmacsFormConfig;
  isNewReport: boolean;
  reportId: string;
  breadCrumbLabel: string;
  report: CustomCucmReport;
  allReports: CustomCucmReport[];

  constructor(
    private _serversContext: ServersContext,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _breadcrumbsService: BreadcrumbsService,
    private _cucmQueriesResource: CucmQueriesResource,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.isNewReport = this._router.url.endsWith('/new-report');
    this.breadCrumbLabel = 'tkey;reporting.custom_reports.new_report.title';
    this.isLoading = true;

    forkJoin([this._cucmQueriesResource.getAllReports(), this._serversContext._getAll()]).subscribe(
      ([reports, servers]) => {
        this.allReports = reports;
        this.servers = servers.filter((s) => s.serverType === 'CUCM');
        if (!this.isNewReport) {
          this._activatedRoute.paramMap.subscribe((params) => {
            this.reportId = params.get('reportId');
            this.report = this.allReports.find((r) => r.id === Number(this.reportId));
            if (!this.report) {
              this._router.navigate([`../new-report`], { relativeTo: this._route });
              return;
            }
            this.breadCrumbLabel = this.report.reportName;
          });
        }
        this._setBreadcrumbs();
        this.isLoading = false;
      }
    );
  }

  private _setBreadcrumbs() {
    this._breadcrumbsService.updateBreadcrumbs([
      { label: 'tkey;reporting.custom_reports.title' },
      { label: this.breadCrumbLabel },
    ]);
  }
}
