import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { AuthenticationContext } from '../../../shared/contexts/authentication.context';
import { CurrentUser, Privilege } from '../../../shared/models/generated/smacsModels';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-custom-reports',
  templateUrl: './custom-reports.component.html',
  styleUrls: ['../../reporting.scss'],
})
export class CustomReportsComponent implements OnInit, OnDestroy {
  isReadOnly = false;

  constructor(private breadcrumbsService: BreadcrumbsService, private authenticationContext: AuthenticationContext) {}

  ngOnInit() {
    this.authenticationContext.state$.pipe(take(1)).subscribe((user: CurrentUser) => {
      this.isReadOnly = !user.privileges.includes(Privilege.CONTROL_WRITE);
    });
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
  }
}
