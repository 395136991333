<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div *ngIf="!isLoading" class="col-12 pb-5">
  <ziro-did-selector-form
    [dialPlanGroups]="dialPlanFieldConfig"
    [entity]="didSelectorFormData"
    [webexFieldConfig]="webexFieldConfig"
    [extensionDetailsEntity]="extensionDetailsEntity"
    [parentSubmit$]="parentSubmit$"
    (smacsFormsUpdate$)="onFormUpdate($event, 'DID_SELECTOR_FORM')"
  ></ziro-did-selector-form>

  <ziro-extension-details-form
    [webexFieldConfig]="webexFieldConfig"
    [entity]="extensionDetailsEntity"
    [didSelectorFormData]="didSelectorFormData"
    [parentSubmit$]="parentSubmit$"
    (smacsFormsUpdate$)="onFormUpdate($event, 'EXTENSION_DETAILS_FORM')"
  ></ziro-extension-details-form>
</div>
