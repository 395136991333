import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
} from '@angular/core';
import {
  DnDidSelectorFormEntity,
  SmacsDirectoryNumberDnDidSelectorFormComponent,
} from './dn-did-selector-form/directory-number-dn-did-selector-form.component';
import { SmacsFormsUpdate } from '../../../forms/smacs-forms-models';
import * as _ from 'lodash';
import { cloneDeep, get, isEqual } from 'lodash';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import { combineLatest, forkJoin, Observable, of, Subject, Subscriber, Subscription } from 'rxjs';
import {
  CallForward,
  CiscoDialPlanFieldConfig,
  ClusterResult,
  DefaultEndUserRequest,
  DefaultLdapUser,
  DirectoryNumber,
  DirectoryNumberFieldConfig,
  DirectoryNumberRef,
  DirectoryNumberResult,
  DnDetailSummary,
  EndUser,
  EndUserResult,
  Global360View,
  LdapUser,
  LdapUserDialPlanDetailsFieldConfig,
  LineButton,
  SiteSummary,
  State,
  StatusCategory,
  TranslationPattern,
  TranslationPatternFieldConfig,
  TranslationPatternRef,
  UccxAgentResult,
  Voicemail,
  VoicemailRef,
  VoicemailResult,
} from '../../../shared/models/generated/smacsModels';
import {
  BottomNavClearButtonsList,
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import {
  DnLdapDetailsFormEntity,
  SmacsDirectoryNumberLdapDetailsFormComponent,
} from './directory-number-ldap-details-form/directory-number-ldap-details-form.component';
import { VoicemailSearchResource } from '../../../shared/resources/voicemail-search.resource';
import { DefaultsResource } from '../../shared/resources/defaults.resource';
import {
  DnDetailsFieldConfig,
  DnDetailsFormEntity,
  SmacsDirectoryNumberDetailsFormComponent,
} from './directory-number-details-form/directory-number-details-form.component';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { PrimaryExtensionFieldConfigResource } from '../../shared/resources/field-config/primary-extension-field-config.resource';
import { DirectoryNumberResource } from '../../../self-serve/resources/directory-number.resource';
import { LdapUserResource } from '../../shared/resources/ldap-user.resource';
import { DirectoryNumberDidDetailsComponent } from './did-details/directory-number-did-details.component';
import {
  DirectoryNumberIlsDetailsFormComponent,
  IlsDetailsEntity,
  IlsDetailsFieldConfigs,
} from './directory-number-ils-details-form/directory-number-ils-details-form.component';
import {
  DirectoryNumberAgentExtensionFormComponent,
  DnAgentExtensionForm,
} from './directory-number-agent-extension-form/directory-number-agent-extension-form.component';
import { UcMetadataCacheContext } from '../../../shared/contexts/uc-metadata-cache.context';
import {
  DirectoryNumberCallForwardFormComponent,
  DnCallForwardFieldConfig,
  DnCallForwardForm,
} from './directory-number-call-forward-form/directory-number-call-forward-form.component';
import { SearchDirectoryNumberResource } from '../../../self-serve/resources/search/search-directory-number.resource';
import { TranslationPatternResource } from '../../shared/resources/translation-pattern.resource';
import { EndUserResource } from '../../../shared/resources/end-user.resource';
import { ActivatedRoute, Router } from '@angular/router';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import { PhoneResource } from '../../../shared/resources/phone.resource';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { PhoneButtonTypes } from '../../../shared/models/phone-button';
import { DnDetailSummaryResource } from '../../../shared/resources/dn-detail-summary.resource';
import { SiteContext } from '../../shared/contexts/site.context';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { TranslateService } from '@ngx-translate/core';
import { VoicemailResource } from '../../../shared/resources/voicemail.resource';
import { ButtonStyles } from '../../../button/button.component';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';
import { UccxAgentResource } from '../../shared/resources/uccx-agent.resource';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { VoicemailService, VoicemailServiceParams } from '../../../shared/services/voicemail.service';
import { DialPlanInventoriesResource } from '../../../shared/resources/dial-plan-inventories.resource';
import { CiscoDialPlanFieldConfigResource } from '../../../shared/resources/cisco-dial-plan-field-config.resource';
import { SystemStatusContext } from '../../../shared/contexts/system-status.context';
import { HttpErrorResponse } from '@angular/common/http';
import { Nvp } from '../../../shared/models/nvp';

export interface EndUserUpdateResult {
  endUser: EndUser;
  updateOtherEndUsers: boolean;
}

@Component({
  selector: 'smacs-directory-number-form',
  templateUrl: './directory-number-form.component.html',
})
export class SmacsDirectoryNumberFormComponent implements OnInit, OnDestroy {
  @ViewChild(SmacsDirectoryNumberDnDidSelectorFormComponent)
  dnDidSelectorForm: SmacsDirectoryNumberDnDidSelectorFormComponent;
  @ViewChild(SmacsDirectoryNumberLdapDetailsFormComponent)
  directoryNumberLdapDetailsForm: SmacsDirectoryNumberLdapDetailsFormComponent;
  @ViewChild(SmacsDirectoryNumberDetailsFormComponent) dnDetailsForm: SmacsDirectoryNumberDetailsFormComponent;
  @ViewChild(DirectoryNumberDidDetailsComponent) dnDidDetailsForm: DirectoryNumberDidDetailsComponent;
  @ViewChild(DirectoryNumberIlsDetailsFormComponent) dnIlsDetailsForm: DirectoryNumberIlsDetailsFormComponent;
  @ViewChild(DirectoryNumberAgentExtensionFormComponent) dnAgentExtForm: DirectoryNumberAgentExtensionFormComponent;
  @ViewChild(DirectoryNumberCallForwardFormComponent) dnCallForwardForm: DirectoryNumberCallForwardFormComponent;

  @Input() modalProperties?: any;

  @Output() isLoadingDone = new EventEmitter<void>();
  @Output() isCancelClicked = new EventEmitter<boolean>();

  isExisting: boolean;
  isEndUserPrimary: boolean;
  initialDirectoryNumber: DirectoryNumber;
  initialDnRef: DirectoryNumberRef;
  initialTranslationPattern: TranslationPattern;
  initialTranslationPatternRef: TranslationPatternRef;
  initialVoicemail: Voicemail;
  initialVoicemailRef: VoicemailRef;
  ldapUser: LdapUser;
  user360View: Global360View;
  siteId: number;
  siteSummary: SiteSummary;
  dnRef: DirectoryNumberRef;
  tpRef: TranslationPatternRef;
  endUserUpdate: EndUserUpdateResult;
  userDetailUiContext: UserDetailUiContext;
  smacsIcons = SmacsIcons;
  dialPlanGroups: CiscoDialPlanFieldConfig[];
  initialDialPlanGroupId: number;
  isLoading = true;
  isSaving = false;
  isFieldConfigsLoaded = false;
  isZpmSyncWarningPresent = false;
  voicemailResults: VoicemailResult[] = [];
  voicemailForExtension: VoicemailResult[] = [];
  isPublicPhone = false;
  hasUccxSettings = false;
  initialLdapValues: DefaultLdapUser;
  endUser: EndUser;
  extensionSelected = false;
  dnDetailsFieldConfig: DnDetailsFieldConfig;
  ldapFieldConfig: LdapUserDialPlanDetailsFieldConfig;
  dnFieldConfig: DirectoryNumberFieldConfig;
  didDetailsFieldConfig: TranslationPatternFieldConfig;
  dnIlsDetailsFieldConfig: IlsDetailsFieldConfigs;
  dnCallForwardFieldConfig: DnCallForwardFieldConfig;
  isFirstTranslationPatternUpdate: boolean;
  username: any;
  auditTags: Nvp[];

  dnDidSelectorFormEntity: DnDidSelectorFormEntity = {
    dialPlanGroupId: null,
    directoryNumber: {
      extension: '',
    },
    translationPattern: {
      pattern: '',
    },
  };

  dnLdapDetailsFormEntity: DnLdapDetailsFormEntity = {
    e164Number: '',
    extension: '',
  };

  dnDetailsFormEntity: DnDetailsFormEntity = {
    description: '',
    urgentPriority: false,
    classOfService: '',
    alertingName: '',
    routePartition: '',
    callPickupGroupName: '',
    voicemailProfile: '',
    lineGroups: [],
    autoAnswer: '',
  };

  didDetailsFormEntity = {
    description: '',
    routePartition: '',
    classOfService: '',
  };

  dnIlsDetailsFormEntity: IlsDetailsEntity = {
    enterpriseAlternateNumber: {
      numberMask: '',
      urgent: false,
      addToLocalRoutePartition: false,
      routePartition: '',
      advertiseGloballyViaIls: false,
    },
    e164AlternateNumber: {
      numberMask: '',
      urgent: false,
      addToLocalRoutePartition: false,
      routePartition: '',
      advertiseGloballyViaIls: false,
    },
    pstnFailover: '',
  };

  dnAgentExtFormEntity: DnAgentExtensionForm = {
    agentExtension: '',
  };

  dnCallForwardFormEntity: DnCallForwardForm = {
    secondaryClassOfServiceForForwardAll: '',
    noAnswerRingDuration: '',
    forwardAll: {
      classOfService: '',
      destination: '',
      forwardToVoicemail: false,
    },
    forwardBusyInternal: {
      classOfService: '',
      destination: '',
      forwardToVoicemail: false,
    },
    forwardBusyExternal: {
      classOfService: '',
      destination: '',
      forwardToVoicemail: false,
    },
    forwardNoAnswerInternal: {
      classOfService: '',
      destination: '',
      forwardToVoicemail: false,
    },
    forwardNoAnswerExternal: {
      classOfService: '',
      destination: '',
      forwardToVoicemail: false,
    },
    forwardNoCoverageInternal: {
      classOfService: '',
      destination: '',
      forwardToVoicemail: false,
    },
    forwardNoCoverageExternal: {
      classOfService: '',
      destination: '',
      forwardToVoicemail: false,
    },
    forwardUnregisteredInternal: {
      classOfService: '',
      destination: '',
      forwardToVoicemail: false,
    },
    forwardUnregisteredExternal: {
      classOfService: '',
      destination: '',
      forwardToVoicemail: false,
    },
    forwardOnCtiFailure: {
      classOfService: '',
      destination: '',
      forwardToVoicemail: false,
    },
  };

  private _subscriptions = new Subscription();
  private _currentCluster: ClusterResult;
  private _isSaveCompleteSource = new Subject<boolean>();
  private _isLdapUpdated = false;
  isSaveComplete$ = this._isSaveCompleteSource.asObservable();

  constructor(
    private dialPlanInventoriesResource: DialPlanInventoriesResource,
    private ciscoDialPlanFieldConfigResource: CiscoDialPlanFieldConfigResource,
    private bottomNavService: BottomNavService,
    private voicemailSearchResource: VoicemailSearchResource,
    private primaryExtensionFieldConfigResource: PrimaryExtensionFieldConfigResource,
    private directoryNumberResource: DirectoryNumberResource,
    private ldapUserResource: LdapUserResource,
    private ucMetadataCacheContext: UcMetadataCacheContext,
    private searchDirectoryNumberResource: SearchDirectoryNumberResource,
    private translationPatternResource: TranslationPatternResource,
    private voicemailResource: VoicemailResource,
    private defaultsResource: DefaultsResource,
    private endUserResource: EndUserResource,
    private route: ActivatedRoute,
    private global360ViewContext: Global360ViewContext,
    private phoneResource: PhoneResource,
    private currentClusterContext: CurrentClusterContext,
    private dnDetailSummaryResource: DnDetailSummaryResource,
    @Optional() private siteContext: SiteContext,
    private siteSummaryContext: SiteSummaryContext,
    private toastService: ToastService,
    private translateService: TranslateService,
    private router: Router,
    private smacsModalService: SmacsModalService,
    private telephoneNumberFilter: TelephoneNumberFilter,
    private _uccxAgentResource: UccxAgentResource,
    private injector: Injector,
    private smacsFormStateService: SmacsFormStateService,
    private voicemailService: VoicemailService,
    private systemStatusContext: SystemStatusContext
  ) {}

  ngOnInit() {
    this.userDetailUiContext = this.modalProperties?.userDetailUiContext
      ? this.modalProperties.userDetailUiContext
      : this.injector.get(UserDetailUiContext);

    const systemStatusSub = this.systemStatusContext.state$.subscribe((systemStatus) => {
      const syncStatus = systemStatus.healthStatuses.find((status) => status.category === StatusCategory.PROXY_SERVER);
      this.isZpmSyncWarningPresent = syncStatus && syncStatus.state !== State.OK;
    });
    this._subscriptions.add(systemStatusSub);

    const combineSub = combineLatest([this.ucMetadataCacheContext.state$, this.currentClusterContext.state$]).subscribe(
      ([ucMetadataCache, currentCluster]) => {
        this._currentCluster = currentCluster;
        this.hasUccxSettings = !!Object.keys(ucMetadataCache.uccxMetadataCache).length;

        this.username = this.modalProperties
          ? this.modalProperties.username
          : get(this.route, 'snapshot.params.username');
        const dnId = this.modalProperties ? this.modalProperties.dnRef?.id : get(this.route, 'snapshot.params.dnId');
        const lineNum = this.modalProperties
          ? this.modalProperties.lineNum
          : get(this.route, 'snapshot.params.lineNum');
        this.isPublicPhone = this.modalProperties?.lineNum != null;

        if (dnId) {
          if (this.username) {
            this.isEndUserPrimary = this.modalProperties ? this.modalProperties.isEndUserPrimary : true;
            this.isExisting = true;
            this._initUserDn();
          } else if (lineNum != null) {
            this.isEndUserPrimary = false;
            this.isExisting = true;
            this._initSecondaryLine();
          }
        } else if (this.username) {
          this.isEndUserPrimary = this.modalProperties ? this.modalProperties.isEndUserPrimary : true;
          this.isExisting = false;
          this._initUserDn();
        } else if (lineNum != null) {
          this.isEndUserPrimary = false;
          this.isExisting = false;
          this._initSecondaryLine();
        }
      }
    );

    this._subscriptions.add(combineSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    if (!this.modalProperties) {
      this.bottomNavService.dispatch(new BottomNavClearButtonsList());
    }
  }

  onFormUpdate(
    $event: SmacsFormsUpdate<
      | DnDidSelectorFormEntity
      | DnLdapDetailsFormEntity
      | DnDetailsFormEntity
      | TranslationPattern
      | IlsDetailsEntity
      | DnAgentExtensionForm
      | DnCallForwardForm
    >,
    form: string
  ) {
    if ($event && !isEqual($event.new, $event.old)) {
      this.updateBottomNavErrorState(false);
      switch (form) {
        case 'DN_DID_SELECTOR_FORM': {
          const newState = { ...$event.new } as DnDidSelectorFormEntity;
          const oldState = { ...$event.old } as DnDidSelectorFormEntity;

          if (
            newState.dialPlanGroupId &&
            oldState.dialPlanGroupId &&
            newState.dialPlanGroupId !== oldState.dialPlanGroupId
          ) {
            this.dnDidSelectorFormEntity = {
              ...newState,
              directoryNumber: {
                extension: '',
              },
              translationPattern: {
                pattern: '',
              },
            };
          } else {
            this.dnDidSelectorFormEntity = { ...newState };

            if (newState.directoryNumber?.extension) {
              this.isFieldConfigsLoaded = false;
              this.searchVoicemail(newState.directoryNumber.extension).subscribe((voicemails) => {
                this.voicemailForExtension = voicemails;
                this._getFieldConfigs().subscribe(() => {
                  const oldState = $event.old as DnDidSelectorFormEntity;
                  this.isFirstTranslationPatternUpdate =
                    oldState.translationPattern.pattern === '' && newState.translationPattern.pattern !== '';
                  if (
                    !this.initialDirectoryNumber ||
                    this.initialDirectoryNumber.extension !== newState.directoryNumber?.extension ||
                    this.isFirstTranslationPatternUpdate
                  ) {
                    this._setDefaultsOnExtensionUpdate(!this.initialDirectoryNumber);
                  }
                  this.isFieldConfigsLoaded = true;
                });
              });
              this.extensionSelected = true;
              if (!this.initialDirectoryNumber) {
                this.dnDetailsFormEntity.description = ($event.new as any)['description'];
                this.dnDetailsFormEntity.alertingName = ($event.new as any)['alertingName'];
              }
            }
          }
          break;
        }
        case 'LDAP_DETAILS_FORM': {
          this.dnLdapDetailsFormEntity = { ...$event.new } as DnLdapDetailsFormEntity;
          break;
        }
        case 'DN_DETAILS_FORM': {
          this.dnDetailsFormEntity = { ...$event.new } as DnDetailsFormEntity;
          break;
        }
        case 'DN_DID_DETAILS_FORM': {
          this.didDetailsFormEntity = { ...$event.new } as TranslationPattern;
          break;
        }
        case 'DN_ILS_DETAILS_FORM': {
          this.dnIlsDetailsFormEntity = { ...$event.new } as IlsDetailsEntity;
          break;
        }
        case 'DN_AGENT_EXT_FORM': {
          this.dnAgentExtFormEntity = { ...$event.new } as DnAgentExtensionForm;
          break;
        }
        case 'DN_CALL_FORWARD_FORM': {
          this.dnCallForwardFormEntity = { ...$event.new } as DnCallForwardForm;
          break;
        }
        default: {
          break;
        }
      }
    }
    this._initBottomNav();
  }

  isLdapUser(): boolean {
    const ldapUser = this.userDetailUiContext.getLdapUser();
    return !!ldapUser;
  }

  checkDnForExistingVoicemail() {
    if (this.initialDirectoryNumber?.extension !== this.dnDidSelectorFormEntity.directoryNumber.extension) {
      this.searchVoicemail(this.dnDidSelectorFormEntity.directoryNumber.extension).subscribe(
        (results: VoicemailResult[]) => {
          if (results.length) {
            const options = {
              windowClass: 'delete-button-modal',
              modalViewProperties: {
                icon: SmacsIcons.DELETE_OUTLINE,
                iconClass: 'text-danger',
                title: this.translateService.instant('tkey;dnvoicemail.delete.modal.header'),
                promptBody: this.translateService.instant('tkey;dnvoicemail.delete.modal.body', {
                  displayName: `${results[0].firstName} ${results[0].lastName}`,
                  exisitingVmExtension: results[0].ref.extension,
                }),
                displayCloseButton: true,
                buttons: [
                  {
                    label: 'tkey;dialogs.button.cancel',
                    buttonClass: ButtonStyles.DEFAULT,
                    dataAutomation: 'confirmation-modal-cancel-button',
                    cb: () => {
                      this.isSaving = false;
                      this.setFormsSubmittingState(false);
                      this.updateBottomNavErrorState(false);
                      this.isCancelClicked.emit(true);
                      return of(null);
                    },
                  },
                  {
                    label: 'tkey;dialogs.button.delete',
                    buttonClass: ButtonStyles.DANGER,
                    dataAutomation: 'confirmation-modal-confirm-button',
                    cb: () => {
                      this._handleSave();
                      return of(null);
                    },
                  },
                ],
              },
            };
            this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
          } else {
            this._handleSave();
          }
        }
      );
    } else {
      this._handleSave();
    }
  }

  private _initUserDn() {
    this.user360View = this.modalProperties
      ? this.modalProperties.global360View
      : this.userDetailUiContext.getGlobal360View();

    if (!this.user360View && this.isExisting) {
      this.global360ViewContext.init(this.username);
    } else {
      this.global360ViewContext._stateSource.next(this.user360View);
    }

    const initDnSub = combineLatest([
      this.userDetailUiContext.state$.pipe(take(1)),
      this.siteSummaryContext.state$,
    ]).subscribe(([userDetail, siteSummary]) => {
      this.siteId = this.userDetailUiContext.getCurrentSite().id;
      this.siteSummary = siteSummary;

      const endUserResult = this.userDetailUiContext.getCurrentEnduser();

      this.endUserResource.get(endUserResult.ref.id, endUserResult.ref.serverId).subscribe((data: EndUser) => {
        this.endUser = data;
        this.initialDnRef = this.modalProperties
          ? this.modalProperties.dnRef
          : this.userDetailUiContext.getCurrentPrimaryExtension();

        this.initialTranslationPatternRef = this.modalProperties
          ? this.modalProperties.translationPattern
          : this.userDetailUiContext.getCurrentTranslationPattern();
        this.initialVoicemailRef = this.userDetailUiContext.getCurrentVoicemailFromExtension(
          this.initialDnRef?.extension
        );
        this.voicemailResults = this.userDetailUiContext.getVoicemailResults().map((result) => {
          return {
            firstName: endUserResult.ref.firstName,
            lastName: endUserResult.ref.lastName,
            ref: result,
          };
        });
        this.ldapUser = this.userDetailUiContext.getLdapUser();

        if (this.isExisting) {
          const reqs = [];

          reqs.push(this.directoryNumberResource.get(this.initialDnRef.id, this.initialDnRef.serverId.toString()));
          reqs.push(
            this.initialTranslationPatternRef
              ? this.translationPatternResource.get(
                  this.initialTranslationPatternRef.id,
                  this.initialTranslationPatternRef.serverId
                )
              : this._returnNull()
          );
          reqs.push(
            this.initialVoicemailRef
              ? this.voicemailResource.get(this.initialVoicemailRef.serverId, this.initialVoicemailRef.id)
              : this._returnNull()
          );

          forkJoin(reqs).subscribe((response: [DirectoryNumber, TranslationPattern, Voicemail]) => {
            this.initialDirectoryNumber = response[0];
            this.initialTranslationPattern = response[1];
            this.initialVoicemail = response[2];
            this._getSiteDialPlanInventories();
          });
        } else {
          this._getSiteDialPlanInventories();
        }
      });
    });
    this._subscriptions.add(initDnSub);
  }

  private _initSecondaryLine() {
    const sub = this.currentClusterContext.state$
      .pipe(
        switchMap((results) => {
          const lineNum = this.modalProperties
            ? this.modalProperties.lineNum
            : get(this.route, 'snapshot.params.lineNum');
          const phoneId = get(this.route, 'snapshot.params.phoneId');
          const phone = this.modalProperties?.phone;

          if (phoneId) {
            // this code is unreachable in prod... it's just to satisfy the workbench. we should have a chore to remove this
            this._handleSecondaryLineOnWorkbench(phoneId, results.cucmServerId.toString(), lineNum);
            return of(null);
          }

          const button = phone.buttons[lineNum];

          if (phone.owner) {
            this.global360ViewContext.init(phone.owner.username);
            return forkJoin([
              this.global360ViewContext.state$,
              this.userDetailUiContext.state$,
              this.siteSummaryContext.state$,
            ]).pipe(
              switchMap((state) => {
                this.user360View = state[0];
                this.siteSummary = state[2];
                this.siteId = this.userDetailUiContext.getCurrentSite().id;
                this.ldapUser = this.userDetailUiContext.getLdapUser();
                const endUserResult = this.userDetailUiContext.getCurrentEnduser();
                return this.endUserResource.get(endUserResult.ref.id, endUserResult.ref.serverId).pipe(
                  switchMap((data) => {
                    this.endUser = data;
                    if (button.type === PhoneButtonTypes.line) {
                      this._initSecondaryDn(button as LineButton);
                    }
                    return of(null);
                  })
                );
              })
            );
          } else {
            return this.siteSummaryContext.state$.pipe(
              switchMap((siteSummary) => {
                this.siteSummary = siteSummary;
                this._initSecondaryDn(button as LineButton);
                return of(null);
              })
            );
          }
        })
      )
      .subscribe();
    this._subscriptions.add(sub);
  }

  private _handleSecondaryLineOnWorkbench(phoneId: string, cucmServerId: string, lineNum: number) {
    this.phoneResource.get(phoneId, cucmServerId).subscribe((phone) => {
      const button = phone.buttons[lineNum];

      if (phone.owner) {
        this.global360ViewContext.init(phone.owner.username);
        this.global360ViewContext.state$.pipe(take(1)).subscribe((state: Global360View) => {
          this.user360View = state;
          const subs = combineLatest([
            this.userDetailUiContext.state$.pipe(take(1)),
            this.siteSummaryContext.state$,
          ]).subscribe(([userDetail, siteSummary]) => {
            this.siteId = this.userDetailUiContext.getCurrentSite().id;
            this.siteSummary = siteSummary;
            this.ldapUser = this.userDetailUiContext.getLdapUser();
            const endUserResult = this.userDetailUiContext.getCurrentEnduser();

            this.endUserResource.get(endUserResult.ref.id, endUserResult.ref.serverId).subscribe((data: EndUser) => {
              this.endUser = data;
              if (button.type === PhoneButtonTypes.line) {
                this._initSecondaryDn(button as LineButton);
              }
            });
          });
          this._subscriptions.add(subs);
        });
      } else {
        this._initSecondaryDn(button as LineButton);
      }
    });
  }

  private _initSecondaryDn(lineButton: LineButton) {
    const dnId = this.modalProperties ? this.modalProperties.dnRef?.id : get(this.route, 'snapshot.params.dnId');
    if (!dnId) {
      if (!this.modalProperties) {
        this.siteContext.state$.pipe(take(1)).subscribe((site) => {
          this.siteId = site.id;
          this._getSiteDialPlanInventories();
        });
      } else {
        this.siteId = this.modalProperties.siteId;
        this._getSiteDialPlanInventories();
      }
    } else {
      const siteIdObs = this.modalProperties
        ? of(this.modalProperties.siteId)
        : this.siteContext.state$.pipe(
            take(1),
            map((siteResult) => siteResult.id)
          );
      forkJoin([
        this.directoryNumberResource.get(lineButton.dn.id, lineButton.dn.serverId.toString()),
        this.dnDetailSummaryResource.get(lineButton.dn.id, lineButton.dn.serverId.toString()),
        siteIdObs,
      ]).subscribe((data: [DirectoryNumber, DnDetailSummary, number]) => {
        this.initialDirectoryNumber = data[0];
        const dnDetailSummary = data[1];
        this.siteId = data[2];
        this.initialTranslationPatternRef = dnDetailSummary.translationPatterns[0];
        this.initialVoicemailRef = dnDetailSummary.voicemail;
        this.initialDnRef = { ...lineButton.dn };

        const reqs = [];
        reqs.push(
          this.initialTranslationPatternRef
            ? this.translationPatternResource.get(
                this.initialTranslationPatternRef.id,
                this.initialTranslationPatternRef.serverId
              )
            : this._returnNull()
        );
        reqs.push(
          this.initialVoicemailRef
            ? this.voicemailResource.get(this.initialVoicemailRef.serverId, this.initialVoicemailRef.id)
            : this._returnNull()
        );

        forkJoin(reqs).subscribe((response: [TranslationPattern, Voicemail]) => {
          this.initialTranslationPattern = response[0];
          this.initialVoicemail = response[1];
          this._getSiteDialPlanInventories();
        });
      });
    }
  }

  dnHasVoicemail(): boolean {
    return (
      this.voicemailForExtension.length > 0 &&
      this.initialDirectoryNumber?.extension === this.dnDidSelectorFormEntity.directoryNumber.extension
    );
  }

  setFormsSubmittingState(state: boolean) {
    this.dnDidSelectorForm._validateAndSubmitSource.next(state);
    if (this.directoryNumberLdapDetailsForm) {
      this.directoryNumberLdapDetailsForm._validateAndSubmitSource.next(state);
    }
    if (this.dnDetailsForm) {
      this.dnDetailsForm._validateAndSubmitSource.next(state);
    }
    if (this.dnDidDetailsForm) {
      this.dnDidDetailsForm._validateAndSubmitSource.next(state);
    }
    if (this.dnIlsDetailsForm) {
      this.dnIlsDetailsForm._validateAndSubmitSource.next(state);
    }
    if (this.dnAgentExtForm) {
      this.dnAgentExtForm._validateAndSubmitSource.next(state);
    }
    if (this.dnCallForwardForm) {
      this.dnCallForwardForm._validateAndSubmitSource.next(state);
    }
  }

  /**
   * Set field config defaults for child forms when Extension is updated for a new DN.
   * @private
   */
  private _setDefaultsOnExtensionUpdate(updateDnForms: boolean) {
    if (updateDnForms) {
      this.dnDetailsFormEntity = {
        description: this.dnFieldConfig.description.defaultValue,
        urgentPriority: this.dnFieldConfig.urgentPriority.defaultValue,
        classOfService: this.dnFieldConfig.classOfService.defaultValue,
        alertingName: this.dnFieldConfig.alertingName.defaultValue,
        routePartition: this.dnFieldConfig.routePartition.defaultValue,
        callPickupGroupName: this.dnFieldConfig.callPickUpGroupName.defaultValue,
        voicemailProfile: this.dnFieldConfig.voicemailProfile.defaultValue,
        lineGroups: this.dnFieldConfig.lineGroups.defaultValues,
        autoAnswer: this.dnFieldConfig.autoAnswer.defaultValue,
      };
      this.dnIlsDetailsFormEntity = {
        enterpriseAlternateNumber: {
          numberMask: this.dnFieldConfig.enterpriseAlternateNumber.numberMask.defaultValue,
          urgent: this.dnFieldConfig.enterpriseAlternateNumber.urgent.defaultValue,
          addToLocalRoutePartition: this.dnFieldConfig.enterpriseAlternateNumber.addToLocalRoutePartition.defaultValue,
          routePartition: this.dnFieldConfig.enterpriseAlternateNumber.routePartition.defaultValue,
          advertiseGloballyViaIls: this.dnFieldConfig.enterpriseAlternateNumber.advertiseGloballyViaIls.defaultValue,
        },
        e164AlternateNumber: {
          numberMask: this.dnFieldConfig.e164AlternateNumber.numberMask.defaultValue,
          urgent: this.dnFieldConfig.e164AlternateNumber.urgent.defaultValue,
          addToLocalRoutePartition: this.dnFieldConfig.e164AlternateNumber.addToLocalRoutePartition.defaultValue,
          routePartition: this.dnFieldConfig.e164AlternateNumber.routePartition.defaultValue,
          advertiseGloballyViaIls: this.dnFieldConfig.e164AlternateNumber.advertiseGloballyViaIls.defaultValue,
        },
        pstnFailover: this.dnFieldConfig.pstnFailover.defaultValue,
      };

      if (!this.isExisting && this.isLdapUser() && this.isEndUserPrimary) {
        this.dnLdapDetailsFormEntity = {
          e164Number: this.ldapFieldConfig.e164Number.defaultValue,
          extension: this.ldapFieldConfig.extension.defaultValue,
        };
      }

      if (this.hasUccxSettings && !this.isPublicPhone) {
        this.dnAgentExtFormEntity.agentExtension = this.endUser.ipccExtension
          ? this.endUser.ipccExtension.extension
          : null;
      }

      const defaultCallForward: CallForward = {
        classOfService: this.dnFieldConfig.forwardAllCss.defaultValue,
        destination: '',
        forwardToVoicemail: false,
      };

      this.dnCallForwardFormEntity = {
        forwardAll: { ...defaultCallForward },
        forwardBusyExternal: { ...defaultCallForward },
        forwardBusyInternal: { ...defaultCallForward },
        forwardNoAnswerExternal: { ...defaultCallForward },
        forwardNoAnswerInternal: { ...defaultCallForward },
        forwardNoCoverageExternal: { ...defaultCallForward },
        forwardNoCoverageInternal: { ...defaultCallForward },
        forwardUnregisteredExternal: { ...defaultCallForward },
        forwardUnregisteredInternal: { ...defaultCallForward },
        forwardOnCtiFailure: { ...defaultCallForward },
        noAnswerRingDuration: this.dnFieldConfig.noAnswerRingDuration.defaultValue,
        secondaryClassOfServiceForForwardAll: this.dnFieldConfig.secondaryClassOfServiceForForwardAll.defaultValue,
      };
    }
    if (this.isFirstTranslationPatternUpdate) {
      this.didDetailsFormEntity = {
        description: this.didDetailsFieldConfig.description.defaultValue,
        routePartition: this.didDetailsFieldConfig.routePartition.defaultValue,
        classOfService: this.didDetailsFieldConfig.classOfService.defaultValue,
      };
    } else {
      this.didDetailsFormEntity = {
        description: this.didDetailsFormEntity.description,
        routePartition: this.didDetailsFormEntity.routePartition,
        classOfService: this.didDetailsFormEntity.classOfService,
      };
    }
  }

  private _setInitialFormValues() {
    this.dnDidSelectorFormEntity = {
      directoryNumber: {
        extension: this.initialDirectoryNumber.extension,
      },
      translationPattern: {
        pattern: this.initialTranslationPattern?.pattern || '',
      },
    } as DnDidSelectorFormEntity;

    this.dnCallForwardFormEntity = {
      forwardAll: { ...this.initialDirectoryNumber?.forwardAll },
      secondaryClassOfServiceForForwardAll: this.initialDirectoryNumber.secondaryClassOfServiceForForwardAll,
      noAnswerRingDuration: this.initialDirectoryNumber.noAnswerRingDuration,
      forwardBusyExternal: { ...this.initialDirectoryNumber?.forwardBusyExternal },
      forwardBusyInternal: { ...this.initialDirectoryNumber?.forwardBusyInternal },
      forwardNoAnswerExternal: { ...this.initialDirectoryNumber?.forwardNoAnswerExternal },
      forwardNoAnswerInternal: { ...this.initialDirectoryNumber?.forwardNoAnswerInternal },
      forwardNoCoverageExternal: { ...this.initialDirectoryNumber?.forwardNoCoverageExternal },
      forwardNoCoverageInternal: { ...this.initialDirectoryNumber?.forwardNoCoverageInternal },
      forwardOnCtiFailure: { ...this.initialDirectoryNumber?.forwardOnCtiFailure },
      forwardUnregisteredExternal: { ...this.initialDirectoryNumber?.forwardUnregisteredExternal },
      forwardUnregisteredInternal: { ...this.initialDirectoryNumber?.forwardUnregisteredInternal },
    };

    this.dnDetailsFormEntity = {
      description: this.initialDirectoryNumber.description,
      urgentPriority: this.initialDirectoryNumber.urgentPriority,
      classOfService: this.initialDirectoryNumber.classOfService,
      alertingName: this.initialDirectoryNumber.alertingName,
      routePartition: this.initialDirectoryNumber.routePartition,
      callPickupGroupName: this.initialDirectoryNumber.callPickUpGroupName,
      voicemailProfile: this.initialDirectoryNumber.voicemailProfile,
      lineGroups: this.initialDirectoryNumber.lineGroups,
      autoAnswer: this.initialDirectoryNumber.autoAnswer,
    };
    this.dnIlsDetailsFormEntity = {
      enterpriseAlternateNumber: this.initialDirectoryNumber.enterpriseAlternateNumber,
      e164AlternateNumber: this.initialDirectoryNumber.e164AlternateNumber,
      pstnFailover: this.initialDirectoryNumber.pstnFailover,
    };

    if (this.initialTranslationPattern) {
      this.didDetailsFormEntity = {
        description: this.initialTranslationPattern.description,
        routePartition: this.initialTranslationPattern.routePartition,
        classOfService: this.initialTranslationPattern.classOfService,
      };
    }

    if (this.isLdapUser() && this.isEndUserPrimary) {
      this.initialLdapValues = {
        e164Number: this.ldapUser.e164Number,
        extension: this.ldapUser.extension,
      };
      this.dnLdapDetailsFormEntity = {
        e164Number: this.ldapUser.e164Number,
        extension: this.ldapUser.extension,
      };
    }

    if (this.hasUccxSettings && !this.isPublicPhone) {
      this.dnAgentExtFormEntity.agentExtension = this.endUser.ipccExtension
        ? this.endUser.ipccExtension.extension
        : null;
    }
  }

  private _setBottomNavButtonState(id: string, pending: boolean, buttonDisableState: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: id,
        state: {
          pending: pending,
          buttonDisableState: {
            disabled: buttonDisableState,
          },
        },
      })
    );
  }

  private _handleSave() {
    if (this.areAllFormsValid()) {
      this.setFormsSubmittingState(true);
      this._setBottomNavButtonState('dn-form-save-button', true, true);
      this._setBottomNavButtonState('dn-form-cancel-button', false, true);
      this._setBottomNavButtonState('dn-form-delete-button', false, true);
      this.saveForms();
    } else {
      this.isSaving = false;
      this.setFormsSubmittingState(true);
      this.updateBottomNavErrorState(true);
    }
  }

  private _onSaveClick() {
    if (this.isSaving) {
      return;
    }
    this.isSaving = true;
    this.updateBottomNavErrorState(false);

    if (this.areAllFormsValid()) {
      this.setFormsSubmittingState(true);
      this.bottomNavService.dispatch(
        new BottomNavUpdateButtonState({
          id: 'dn-form-save-button',
          state: {
            pending: true,
            buttonDisableState: {
              disabled: true,
            },
          },
        })
      );
      this.bottomNavService.dispatch(
        new BottomNavUpdateButtonState({
          id: 'dn-form-cancel-button',
          state: {
            buttonDisableState: { disabled: true, tooltipKey: '' },
          },
        })
      );
      this.bottomNavService.dispatch(
        new BottomNavUpdateButtonState({
          id: 'dn-form-delete-button',
          state: {
            buttonDisableState: { disabled: true, tooltipKey: '' },
          },
        })
      );

      this.checkDnForExistingVoicemail();
    } else {
      this.isSaving = false;
      this.setFormsSubmittingState(true);
      this.updateBottomNavErrorState(true);
    }
  }

  updateBottomNavErrorState(newState: boolean): void {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: newState,
      })
    );
  }

  saveForms() {
    this.handleDnSave().subscribe((dnRef: DirectoryNumberRef) => {
      this.dnRef = dnRef;
      forkJoin([this.handleVmSave(dnRef), this.handleLdapSave(), this.handleTpSave()]).subscribe((data) => {
        const tpRef = data[2];
        this.tpRef = tpRef;

        this.handleCurrentEndUserUpdate(dnRef).subscribe((endUserUpdate: EndUserUpdateResult) => {
          this.endUserUpdate = endUserUpdate;
          if (endUserUpdate.updateOtherEndUsers) {
            this.handleNonCurrentEndUserUpdate().subscribe(() => {
              this.onSaveComplete(dnRef, tpRef);
            });
          } else {
            this.onSaveComplete(dnRef, tpRef);
          }
        });
      });
    });
  }

  onSaveComplete(dnRef: DirectoryNumberRef, tpRef: TranslationPatternRef) {
    this.isSaving = false;
    this.setFormsSubmittingState(false);

    if (!this.modalProperties) {
      // update global 360 view _stateSource to reflect any DN modifications on 360 view tile.
      const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
      const dnIndex = global360.primaryExtensions.findIndex(
        (primaryExtension: DirectoryNumberRef) => primaryExtension.id === dnRef.id
      );
      if (dnIndex > -1) {
        global360.primaryExtensions[dnIndex] = dnRef;
      } else {
        global360.primaryExtensions.push(dnRef);
      }

      // If we have an agent to go along with this DN push to ipcc extensions as well
      const ipccIndex = global360.ipccExtensions.findIndex(
        (extension: DirectoryNumberRef) => extension.id === dnRef.id
      );
      if (this.dnAgentExtFormEntity.agentExtension) {
        if (ipccIndex > -1) {
          global360.ipccExtensions[ipccIndex] = dnRef;
        } else {
          global360.ipccExtensions.push(dnRef);
        }
      } else if (!this.dnAgentExtFormEntity.agentExtension && ipccIndex > -1) {
        global360.ipccExtensions.splice(ipccIndex, 1);
      }

      // Like above update global 360 view _stateSource to reflect any Translation Pattern modifications on 360 view tile.
      if (tpRef) {
        const tpIndex = global360.translationPatterns.findIndex(
          (tpRef360: TranslationPatternRef) => tpRef360.id === tpRef.id
        );
        if (tpIndex > -1) {
          global360.translationPatterns[tpIndex] = tpRef;
        } else {
          global360.translationPatterns.push(tpRef);
        }
      } else if (this.initialTranslationPatternRef) {
        const filteredTranslationPatterns = global360.translationPatterns.filter(
          (tpRef360: TranslationPatternRef) => tpRef360.id !== this.initialTranslationPatternRef.id
        );
        global360.translationPatterns = [...filteredTranslationPatterns];
      }

      // update ldap attributes if changed
      if (
        this.isLdapUser() &&
        this.isEndUserPrimary &&
        !isEqual(this.initialLdapValues, this.dnLdapDetailsFormEntity)
      ) {
        global360.ldapUser.e164Number = this.dnLdapDetailsFormEntity.e164Number;
        global360.ldapUser.extension = this.dnLdapDetailsFormEntity.extension;
        // and additional attributes if there were any that were tied to the initial e164 or ext value
        const initialLdapAdditionalAttribs = global360.ldapUser.additionalAttributes;
        for (const attribKey in initialLdapAdditionalAttribs) {
          if (this.initialLdapValues?.e164Number.includes(initialLdapAdditionalAttribs[attribKey])) {
            global360.ldapUser.additionalAttributes[attribKey] = this.dnLdapDetailsFormEntity?.e164Number;
          } else if (this.initialLdapValues?.extension.includes(initialLdapAdditionalAttribs[attribKey])) {
            global360.ldapUser.additionalAttributes[attribKey] = this.dnLdapDetailsFormEntity?.extension;
          }
        }
      }
      // This change happens automatically in the backend so we just need to mirror it here
      global360.endUsers = global360.endUsers.map((endUserResult) =>
        endUserResult.ref.serverId !== this.userDetailUiContext.getCurrentEnduser().ref.serverId &&
        this.isEndUserPrimary
          ? { ...endUserResult, imPresenceEnabled: false }
          : endUserResult
      );
      this.global360ViewContext._stateSource.next(global360);

      this._update360View(dnRef, this.dnAgentExtFormEntity.agentExtension && ipccIndex === -1).subscribe();
    }

    this._isSaveCompleteSource.next(true);
  }

  private _update360View(dnRef: DirectoryNumberRef, initAgent: boolean): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      const username = this.modalProperties
        ? this.modalProperties.username
        : get(this.route, 'snapshot.params.username');

      if (initAgent) {
        this._uccxAgentResource.search(username).subscribe((data: UccxAgentResult[]) => {
          const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
          global360.uccxAgents = data.map((agentResult: UccxAgentResult) => agentResult.ref);
          this.global360ViewContext._stateSource.next(global360);
          this.redirectToHome(username, dnRef);
          if (!this.modalProperties) {
            this._showSaveToast();
          }
        });
      } else {
        this.redirectToHome(username, dnRef);
        if (!this.modalProperties) {
          this._showSaveToast();
        }
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  private _showSaveToast() {
    this.toastService.push(
      ToastTypes.SUCCESS,
      this.smacsIcons.PRIMARY_EXT,
      'tkey;shared.toast.save.success.title',
      `${this.translateService.instant(
        'tkey;shared.service.primary.extension.text'
      )} - ${this.telephoneNumberFilter.transform(this.dnDidSelectorFormEntity.directoryNumber.extension)}`
    );
  }

  redirectToHome(username: string, dnRef: DirectoryNumberRef) {
    if (!this.isEndUserPrimary && !this.isExisting) {
      const phoneId = this.modalProperties ? this.modalProperties.phone.id : get(this.route, 'snapshot.params.phoneId');
      const lineNum = get(this.route, 'snapshot.params.lineNum');
      const route = `/phone/${phoneId}/line/${lineNum}/directory-number/${dnRef.id}`;
      if (!this.modalProperties) {
        this.router.navigateByUrl(route);
      }
    } else {
      const route = `/user/${encodeURIComponent(username)}`;
      if (!this.modalProperties) {
        this.router.navigateByUrl(route);
      }
    }
  }

  handleCurrentEndUserUpdate(dnRef: DirectoryNumberRef): Observable<EndUserUpdateResult> {
    if (this.isPublicPhone) {
      return of({ endUser: null, updateOtherEndUsers: false } as EndUserUpdateResult);
    }
    return new Observable((subscriber: Subscriber<any>) => {
      const serverId = this.userDetailUiContext.getCurrentEnduser().ref.serverId;
      this.isEndUserPrimary = this.modalProperties ? this.modalProperties.isEndUserPrimary : this.isEndUserPrimary;
      if (this.isEndUserPrimary) {
        const req = {
          hasExtensionMobility: this.userDetailUiContext.hasExtensionMobility(),
          hasSnr: this.userDetailUiContext.hasSnr(),
          hasVoicemail: !!(
            this.isExisting &&
            this.dnDidSelectorFormEntity.directoryNumber.extension === this.initialDirectoryNumber?.extension &&
            this.voicemailResults.length
          ),
          siteId: this.siteId.toString(),
          username: this.endUser ? this.endUser.username : null,
        } as DefaultEndUserRequest;
        this.defaultsResource.endUserDefaults(req).subscribe((endUser: EndUser) => {
          const existingIpccExtensionRef = this.userDetailUiContext.getCurrentIpccExtension();
          endUser.primaryExtension = {
            id: dnRef.id,
          } as DirectoryNumberRef;
          endUser.enableHomeCluster = true;
          endUser.extensionMobilityCrossCluster = true;
          if (!!existingIpccExtensionRef && dnRef.extension !== existingIpccExtensionRef.extension) {
            endUser.ipccExtension = existingIpccExtensionRef;
          } else {
            endUser.ipccExtension = {
              id: this.dnAgentExtFormEntity.agentExtension ? dnRef.id : null,
            } as DirectoryNumberRef;
          }
          this.endUserResource.put(endUser, serverId).subscribe(() => {
            subscriber.next({ endUser: endUser, updateOtherEndUsers: true } as EndUserUpdateResult);
            subscriber.complete();
          });
        });
      } else {
        const uccxAgents = this.userDetailUiContext.getUccxAgents().length;
        if (
          (!!this.endUser.ipccExtension && this.endUser.ipccExtension.id === dnRef.id) ||
          (uccxAgents === 0 && this.dnAgentExtFormEntity.agentExtension) ||
          (!this.endUser.ipccExtension && uccxAgents >= 1)
        ) {
          this.endUser = {
            ...this.endUser,
            ipccExtension: {
              id: this.dnAgentExtFormEntity.agentExtension ? dnRef.id : null,
            } as DirectoryNumberRef,
          };
          this.endUserResource.put(this.endUser, serverId).subscribe(() => {
            subscriber.next({ endUser: this.endUser, updateOtherEndUsers: false } as EndUserUpdateResult);
            subscriber.complete();
          });
        } else {
          subscriber.next({ endUser: this.endUser, updateOtherEndUsers: false } as EndUserUpdateResult);
          subscriber.complete();
        }
      }
    });
  }

  handleNonCurrentEndUserUpdate(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      const otherEndUsers = this.user360View.endUsers.filter((e: EndUserResult) => {
        return e.ref.id !== this.userDetailUiContext.getCurrentEnduser().ref.id;
      });
      if (otherEndUsers.length) {
        const userUpdateRequests = otherEndUsers.map((e: EndUserResult) => {
          return this.endUserResource.get(e.ref.id, e.ref.serverId).pipe(
            switchMap((user) => {
              if (user.enableHomeCluster) {
                user.enableHomeCluster = false;
                return this.endUserResource.put(user, e.ref.serverId);
              }
              return of(e.ref);
            })
          );
        });
        forkJoin(userUpdateRequests).subscribe(() => {
          subscriber.next();
          subscriber.complete();
        });
      } else {
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  public handleDnSave(): Observable<DirectoryNumberRef> {
    return new Observable<DirectoryNumberRef>((subscriber: Subscriber<DirectoryNumberRef>) => {
      this._checkForInactiveDn().subscribe(() => {
        const serverId = this._currentCluster.cucmServerId;

        const updatedDirectoryNumber = {
          alertingName: this.dnDetailsFormEntity.alertingName,
          autoAnswer: this.dnDetailsFormEntity.autoAnswer,
          callPickUpGroupName: this.dnDetailsFormEntity.callPickupGroupName,
          classOfService: this.dnDetailsFormEntity.classOfService,
          description: this.dnDetailsFormEntity.description,
          e164AlternateNumber: this.dnIlsDetailsFormEntity.e164AlternateNumber,
          enterpriseAlternateNumber: this.dnIlsDetailsFormEntity.enterpriseAlternateNumber,
          extension: this.dnDidSelectorFormEntity.directoryNumber.extension,
          ...this.dnCallForwardFormEntity,
          lineGroups: this.dnDetailsFormEntity.lineGroups,
          noAnswerRingDuration: this.dnCallForwardFormEntity.noAnswerRingDuration,
          pstnFailover: this.dnIlsDetailsFormEntity.pstnFailover,
          routePartition: this.dnDetailsFormEntity.routePartition,
          secondaryClassOfServiceForForwardAll: this.dnCallForwardFormEntity.secondaryClassOfServiceForForwardAll,
          urgentPriority: this.dnDetailsFormEntity.urgentPriority,
          voicemailProfile: this.dnDetailsFormEntity.voicemailProfile,
        } as DirectoryNumber;

        const auditTags = this.isPublicPhone ? null : [{ name: 'username', value: this.endUser.username }];

        const createDirectoryNumber = () => {
          this.directoryNumberResource.post(updatedDirectoryNumber, serverId.toString(), auditTags).subscribe({
            next: (ref: DirectoryNumberRef) => {
              if ('error' in ref) {
                this.isSaving = false;
                this.setFormsSubmittingState(false);
                this.bottomNavService.dispatch(
                  new BottomNavUpdateButtonState({
                    id: 'dn-form-save-button',
                    state: {
                      pending: false,
                      buttonDisableState: {
                        disabled: false,
                      },
                    },
                  })
                );
                this.bottomNavService.dispatch(
                  new BottomNavUpdateButtonState({
                    id: 'dn-form-cancel-button',
                    state: {
                      buttonDisableState: { disabled: false, tooltipKey: '' },
                    },
                  })
                );
                if (this.isExisting) {
                  this.bottomNavService.dispatch(
                    new BottomNavUpdateButtonState({
                      id: 'dn-form-delete-button',
                      state: {
                        buttonDisableState: { disabled: false, tooltipKey: '' },
                      },
                    })
                  );
                }
                subscriber.complete();
              } else {
                this.userDetailUiContext.state$.subscribe();
                subscriber.next(ref as DirectoryNumberRef);
                subscriber.complete();
              }
            },
            error: (error: HttpErrorResponse) => {
              this.isSaving = false;
              this._setBottomNavButtonState('dn-form-save-button', false, false);
              this._setBottomNavButtonState('dn-form-cancel-button', false, false);
              this._setBottomNavButtonState('dn-form-delete-button', false, false);

              throw error;
            },
          });
        };

        if (this.isExisting) {
          updatedDirectoryNumber.id = this.initialDirectoryNumber.id;
          this.directoryNumberResource
            .put(
              this.initialDirectoryNumber.id,
              updatedDirectoryNumber,
              this.initialDnRef.serverId.toString(),
              auditTags
            )
            .subscribe({
              next: (ref: DirectoryNumberRef) => {
                subscriber.next(ref);
                subscriber.complete();
              },
              error: (error: HttpErrorResponse) => {
                this.dnDidSelectorForm.revert({
                  ...this.dnDidSelectorFormEntity,
                  directoryNumber: {
                    extension: this.initialDirectoryNumber.extension,
                  },
                  translationPattern: {
                    pattern: this.initialTranslationPattern?.pattern || '',
                  },
                });

                this.isSaving = false;
                this._setBottomNavButtonState('dn-form-save-button', false, false);
                this._setBottomNavButtonState('dn-form-cancel-button', false, false);
                this._setBottomNavButtonState('dn-form-delete-button', false, false);

                throw error;
              },
            });
        } else if (this.isPublicPhone) {
          this.searchDirectoryNumberResource
            .get({ extension: updatedDirectoryNumber.extension })
            .subscribe((result: DirectoryNumberResult[]) => {
              if (result.length) {
                this.directoryNumberResource.delete(result[0].ref.id, serverId).subscribe(() => {
                  createDirectoryNumber();
                });
              } else {
                createDirectoryNumber();
              }
            });
        } else {
          createDirectoryNumber();
        }
      });
    });
  }

  private _checkForInactiveDn(): Observable<void> {
    if (
      this.initialDirectoryNumber &&
      this.initialDirectoryNumber.extension === this.dnDidSelectorFormEntity.directoryNumber.extension
    ) {
      return of(null);
    }
    return this.searchDirectoryNumberResource
      .get({
        extension: this.dnDidSelectorFormEntity.directoryNumber.extension,
        'cucm-server-id': this._currentCluster.cucmServerId,
      })
      .pipe(
        switchMap((results) => {
          return results.length
            ? this.directoryNumberResource.delete(results[0].ref.id, results[0].ref.serverId)
            : of(null);
        })
      );
  }

  handleVmSave(dnRef?: DirectoryNumberRef): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      if (!this.isExisting) {
        this.searchVoicemail(this.dnDidSelectorFormEntity.directoryNumber.extension).subscribe(
          (results: VoicemailResult[]) => {
            if (!results.length) {
              subscriber.next();
              subscriber.complete();
            } else {
              const result = results[0];
              this._deleteVm(result.ref.id, result.ref.serverId, dnRef).subscribe(() => {
                subscriber.next();
                subscriber.complete();
              });
            }
          }
        );
      } else {
        if (this.dnDidSelectorFormEntity.directoryNumber.extension === this.initialDirectoryNumber.extension) {
          subscriber.next();
          subscriber.complete();
        } else {
          this.searchVoicemail(this.dnDidSelectorFormEntity.directoryNumber.extension).subscribe(
            (results: VoicemailResult[]) => {
              if (!results.length) {
                if (this.initialVoicemail && !this.modalProperties) {
                  this.initialVoicemail.extension = this.dnDidSelectorFormEntity.directoryNumber.extension;
                  this._putVm(this.initialVoicemail).subscribe(() => {
                    subscriber.next();
                    subscriber.complete();
                  });
                  subscriber.next();
                  subscriber.complete();
                } else {
                  subscriber.next();
                  subscriber.complete();
                }
              } else {
                const result = results[0];
                this._deleteVm(result.ref.id, result.ref.serverId).subscribe(() => {
                  if (this.initialVoicemail) {
                    this.initialVoicemail.extension = this.dnDidSelectorFormEntity.directoryNumber.extension;
                    this._putVm(this.initialVoicemail).subscribe(() => {
                      subscriber.next();
                      subscriber.complete();
                    });
                  } else {
                    subscriber.next();
                    subscriber.complete();
                  }
                });
              }
            }
          );
        }
      }
    });
  }

  private _deleteVm(vmId: string, serverId: number, dnRef?: DirectoryNumberRef): Observable<void> {
    const serviceParams: VoicemailServiceParams = {
      endUserResult: this.userDetailUiContext.getCurrentEnduser(),
      endUsers: this.userDetailUiContext.getEndUsers(),
      unityServerId: serverId,
      siteId: Number(this.userDetailUiContext.getCurrentSite().id),
      dnRef: this.initialDnRef || dnRef,
      hasSnr: !!this.userDetailUiContext.hasSnr(),
      hasExtensionMobility: !!this.userDetailUiContext.hasExtensionMobility(),
    };
    return this.voicemailService.deprovisionVoicemail(vmId, serviceParams, this.isEndUserPrimary);
  }

  private _putVm(vm: Voicemail): Observable<VoicemailRef> {
    const serverId = this.user360View
      ? this.userDetailUiContext.getCurrentVoicemail().serverId
      : this.initialVoicemailRef.serverId;
    return this.voicemailResource.put(serverId, this.initialVoicemail.id, {
      ...vm,
      extension: this.dnDidSelectorFormEntity.directoryNumber.extension,
    });
  }

  handleLdapSave(): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      if (
        this.isLdapUser() &&
        this.isEndUserPrimary &&
        !isEqual(this.initialLdapValues, this.dnLdapDetailsFormEntity)
      ) {
        this._isLdapUpdated = true;
        this.ldapUserResource
          .put({
            username: this.userDetailUiContext.getLdapUser().username,
            did: this.dnLdapDetailsFormEntity.e164Number,
            extension: this.dnLdapDetailsFormEntity.extension,
          })
          .pipe(
            // For new DNs we can only get updated LDAP info from a 360 view that has been processed by the API
            switchMap(() =>
              !this.isExisting ? this.global360ViewContext.getGlobal360View(this.endUser.username) : of(null)
            ),
            tap((g360UpdatedLdap) => {
              const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
              if (global360?.ldapUser && !!g360UpdatedLdap) {
                global360.ldapUser = g360UpdatedLdap.ldapUser;
                this.global360ViewContext._stateSource.next(global360);
              }
            })
          )
          .subscribe(() => {
            subscriber.next();
            subscriber.complete();
          });
      } else {
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  handleTpSave(): Observable<TranslationPatternRef> {
    return new Observable<TranslationPatternRef>((subscriber: Subscriber<TranslationPatternRef>) => {
      const serverId = this._currentCluster.cucmServerId;

      if (!this.initialTranslationPattern && this.dnDidSelectorFormEntity.translationPattern.pattern) {
        this.translationPatternResource
          .post(serverId, {
            classOfService: this.didDetailsFormEntity.classOfService,
            description: this.didDetailsFormEntity.description,
            destination: this.dnDidSelectorFormEntity.directoryNumber.extension,
            pattern: this.dnDidSelectorFormEntity.translationPattern.pattern,
            routePartition: this.didDetailsFormEntity.routePartition,
          } as TranslationPattern)
          .subscribe((data: TranslationPatternRef) => {
            subscriber.next(data);
            subscriber.complete();
          });
      } else if (this.initialTranslationPattern && !this.dnDidSelectorFormEntity.translationPattern.pattern) {
        this.translationPatternResource.delete(this.initialTranslationPattern.id, serverId).subscribe(() => {
          subscriber.next(null);
          subscriber.complete();
        });
      } else if (
        this.initialTranslationPattern &&
        !_.isEqual(this.initialTranslationPattern, this.dnDidSelectorFormEntity.translationPattern)
      ) {
        this.translationPatternResource
          .put(this.initialTranslationPattern.id, serverId, {
            id: this.initialTranslationPattern.id,
            classOfService: this.didDetailsFormEntity.classOfService,
            description: this.didDetailsFormEntity.description,
            destination: this.dnDidSelectorFormEntity.directoryNumber.extension,
            pattern: this.dnDidSelectorFormEntity.translationPattern.pattern,
            routePartition: this.didDetailsFormEntity.routePartition,
          })
          .subscribe((tpRef) => {
            subscriber.next(tpRef);
            subscriber.complete();
          });
      } else {
        subscriber.next(null);
        subscriber.complete();
      }
    });
  }

  areAllFormsValid(): boolean {
    if (!this.dnDidSelectorForm?.isFormValid()) {
      return false;
    }

    if (this.directoryNumberLdapDetailsForm) {
      if (!this.directoryNumberLdapDetailsForm?.isFormValid()) {
        return false;
      }
    }

    if (this.dnAgentExtForm && !this.dnAgentExtForm?.isFormValid()) {
      return false;
    }

    if (
      !this.dnDetailsForm?.isFormValid() ||
      !this.dnIlsDetailsForm?.isFormValid() ||
      !this.dnCallForwardForm?.isFormValid() ||
      !this.dnCallForwardForm?.getAreChildrenValid()
    ) {
      return false;
    }

    if (this.dnDidDetailsForm) {
      if (!this.dnDidDetailsForm?.isFormValid()) {
        return false;
      }
    }
    return true;
  }

  private _getSiteDialPlanInventories() {
    this._initBottomNav();
    this.ciscoDialPlanFieldConfigResource.get(this.siteId).subscribe((data) => {
      this.dialPlanGroups = data;

      if (this.isExisting) {
        this.dialPlanInventoriesResource
          .search(this.initialDnRef.serverId, this.initialDnRef.extension)
          .subscribe((dialPlan) => {
            this.initialDialPlanGroupId = dialPlan[0]?.id;
            this._setInitialFormValues();
            this.isLoading = false;
            this.isLoadingDone.emit();
          });
      } else {
        this.isLoading = false;
        this.isLoadingDone.emit();
      }
    });
  }

  private _onDeleteClick() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        title: this.translateService.instant('tkey;userdetail.primary_extension.delete.confirmation.modal.header'),
        promptBody: this.translateService.instant(
          this.initialVoicemail
            ? 'tkey;userdetail.primary_extension.voicemail.delete.confirmation.modal.text'
            : 'tkey;userdetail.primary_extension.delete.confirmation.modal.text',
          {
            extension: this.initialDnRef.extension,
            voicemail: this.initialVoicemail?.alias,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onConfirmDelete(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _onConfirmDelete(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      if (this.initialVoicemail) {
        this._deleteVm(this.initialVoicemailRef.id, this.initialVoicemailRef.serverId).subscribe({
          next: () => {
            this.toastService.pushDeleteToast('tkey;shared.service.voicemail.text', this.initialVoicemailRef.alias);
            this._deleteDN().subscribe(() => {
              subscriber.next();
              subscriber.complete();
            });
          },
          error: () => {
            subscriber.error();
            subscriber.complete();
          },
        });
      } else {
        this._deleteDN().subscribe(() => {
          subscriber.next();
          subscriber.complete();
        });
      }
    });
  }

  private _deleteDN(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this.directoryNumberResource.delete(this.initialDnRef.id, this.initialDnRef.serverId).subscribe(() => {
        const reqs = [];
        const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());

        // Clear primary extension
        global360.primaryExtensions = global360.primaryExtensions.filter((dnRef: DirectoryNumberRef) => {
          if (dnRef.id !== this.initialDirectoryNumber.id) {
            return dnRef;
          }
        });

        // Clear IPCC extension
        global360.ipccExtensions = global360.ipccExtensions.filter((dnRef: DirectoryNumberRef) => {
          if (dnRef.id !== this.initialDirectoryNumber.id) {
            return dnRef;
          }
        });

        if (this.isLdapUser() && this.isEndUserPrimary) {
          reqs.push(
            this.ldapUserResource.put({
              username: this.userDetailUiContext.getLdapUser().username,
              did: '',
              extension: '',
            })
          );
        }

        if (this.initialTranslationPatternRef) {
          global360.translationPatterns = global360.translationPatterns.filter(
            (didRef: TranslationPatternRef) => didRef.id !== this.initialTranslationPatternRef.id
          );

          reqs.push(
            this.translationPatternResource.delete(
              this.initialTranslationPatternRef.id,
              this.initialTranslationPatternRef.serverId
            )
          );
        }

        this.global360ViewContext._stateSource.next(global360);

        if (reqs.length) {
          forkJoin(reqs).subscribe(() => {
            this._postDelete();
            subscriber.next();
            subscriber.complete();
          });
        } else {
          this._postDelete();
        }
      });
    });
  }

  private _hasServices(): boolean {
    return (
      !this.isPublicPhone &&
      (this.userDetailUiContext.hasSnr() ||
        this.userDetailUiContext.hasExtensionMobility() ||
        this.userDetailUiContext.hasPhones())
    );
  }

  private _postDelete() {
    this.setFormsSubmittingState(false);
    this.smacsFormStateService.setIsFormDirty(false);

    this.toastService.pushDeleteToast(
      'tkey;shared.service.primary.extension.text',
      this.dnDidSelectorFormEntity.directoryNumber.extension
    );

    const username = this.modalProperties ? this.modalProperties.username : get(this.route, 'snapshot.params.username');
    const route = `/user/${username}`;
    this.router.navigateByUrl(route);
  }

  private _initBottomNav() {
    if (this.isSaving || this.modalProperties) {
      return;
    }
    const bottomNavButtons = [];

    bottomNavButtons.push({
      id: 'dn-form-cancel-button',
      dataAutomation: 'dn-form-cancel-button',
      label: 'tkey;dialogs.button.cancel',
      buttonClass: ButtonStyles.DEFAULT,
      cb: () => this._goToUserHome(),
    });

    if (this.isExisting) {
      bottomNavButtons.push({
        id: 'dn-form-delete-button',
        dataAutomation: 'dn-form-delete-button',
        label: 'tkey;dialogs.button.delete',
        icon: this.smacsIcons.DELETE,
        buttonClass: ButtonStyles.DANGER,
        state: {
          pending: false,
          buttonDisableState: {
            disabled: this._hasServices() || !this.isExisting || this._disableButtonOnLoading(),
            tooltipKey: 'tkey;userdetail.primary_extension.delete.tooltip',
          },
          tooltipVisible: this._hasServices(),
        },
        cb: () => this._onDeleteClick(),
      });
    }

    bottomNavButtons.push({
      id: 'dn-form-save-button',
      dataAutomation: 'dn-form-save-button',
      label: 'tkey;global.button.save.text',
      icon: this.smacsIcons.OK,
      buttonClass: ButtonStyles.PRIMARY,
      state: {
        pending: false,
        buttonDisableState: {
          disabled: this._disableButtonOnLoading(),
        },
      },
      cb: () => this._onSaveClick(),
    });
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _disableButtonOnLoading(): boolean {
    return (
      this.isLoading ||
      (this.dnDidSelectorFormEntity.dialPlanGroupId &&
        this.dnDidSelectorFormEntity.directoryNumber.extension &&
        !this.isFieldConfigsLoaded)
    );
  }

  public searchVoicemail(extension: string): Observable<VoicemailResult[]> {
    return new Observable((subscriber: Subscriber<VoicemailResult[]>) => {
      this.voicemailSearchResource.searchByExtension(extension).subscribe((data: VoicemailResult[]) => {
        subscriber.next(data);
        subscriber.complete();
      });
    });
  }

  private _goToUserHome() {
    this.router.navigateByUrl(`/user/${encodeURIComponent(this.route.snapshot.params.username)}`);
  }

  /**
   * Get request parameters from respective form entities when DN exists.
   * @private
   */
  private _getFieldConfigs() {
    return new Observable((subscriber: Subscriber<void>) => {
      const dnReqBody = {
        dialPlanGroupId: this.dnDidSelectorFormEntity.dialPlanGroupId
          ? this.dnDidSelectorFormEntity.dialPlanGroupId
          : this.initialDialPlanGroupId,
        extension: this.dnDidSelectorFormEntity.directoryNumber.extension
          ? this.dnDidSelectorFormEntity.directoryNumber.extension
          : this.userDetailUiContext.getCurrentPrimaryExtension()?.extension,
        siteId: this.siteId,
        username: this.isEndUserPrimary ? this.userDetailUiContext.getCurrentEnduser().ref.username : null,
        withVoicemail: this.dnHasVoicemail(),
      };
      const tpReqBody = {
        dialPlanGroupId: this.dnDidSelectorFormEntity.dialPlanGroupId
          ? this.dnDidSelectorFormEntity.dialPlanGroupId
          : this.initialDialPlanGroupId,
        extension: this.dnDidSelectorFormEntity.directoryNumber.extension
          ? this.dnDidSelectorFormEntity.directoryNumber.extension
          : this.userDetailUiContext.getCurrentPrimaryExtension()?.extension,
        siteId: this.siteId,
        username: this.isEndUserPrimary ? this.userDetailUiContext.getCurrentEnduser().ref.username : null,
      };

      if (dnReqBody.dialPlanGroupId) {
        const fieldConfigRequests: Observable<any>[] = [
          this.primaryExtensionFieldConfigResource.getDnFieldConfig(dnReqBody),
          this.primaryExtensionFieldConfigResource.getTranslationPatternFieldConfig(tpReqBody),
        ];
        if (this.isLdapUser() && this.isEndUserPrimary) {
          const ldapReqBody = {
            dialPlanGroupId: this.dnDidSelectorFormEntity.dialPlanGroupId
              ? this.dnDidSelectorFormEntity.dialPlanGroupId
              : this.initialDialPlanGroupId,
            extension: this.dnDidSelectorFormEntity.directoryNumber.extension
              ? this.dnDidSelectorFormEntity.directoryNumber.extension
              : this.userDetailUiContext.getCurrentPrimaryExtension()?.extension,
          };
          fieldConfigRequests.push(
            this.primaryExtensionFieldConfigResource.getLdapUserDialPlanFieldConfig(ldapReqBody)
          );
        }
        forkJoin(fieldConfigRequests).subscribe(
          (data: [DirectoryNumberFieldConfig, TranslationPatternFieldConfig, LdapUserDialPlanDetailsFieldConfig]) => {
            this.dnFieldConfig = data[0];
            this.didDetailsFieldConfig = data[1];
            this.ldapFieldConfig = data[2];
            this.dnDetailsFieldConfig = {
              description: this.dnFieldConfig.description,
              urgentPriority: this.dnFieldConfig.urgentPriority,
              classOfService: this.dnFieldConfig.classOfService,
              alertingName: this.dnFieldConfig.alertingName,
              routePartition: this.dnFieldConfig.routePartition,
              callPickupGroupName: this.dnFieldConfig.callPickUpGroupName,
              voicemailProfile: this.dnFieldConfig.voicemailProfile,
              lineGroups: this.dnFieldConfig.lineGroups,
              autoAnswer: this.dnFieldConfig.autoAnswer,
            };
            this.dnIlsDetailsFieldConfig = {
              e164AlternateNumber: this.dnFieldConfig.e164AlternateNumber,
              enterpriseAlternateNumber: this.dnFieldConfig.enterpriseAlternateNumber,
              pstnFailover: this.dnFieldConfig.pstnFailover,
            };
            this.dnCallForwardFieldConfig = {
              forwardAllCss: this.dnFieldConfig.forwardAllCss,
              secondaryClassOfServiceForForwardAll: this.dnFieldConfig.secondaryClassOfServiceForForwardAll,
              noAnswerRingDuration: this.dnFieldConfig.noAnswerRingDuration,
            };

            this.isFieldConfigsLoaded = true;
            subscriber.next();
            subscriber.complete();
          }
        );
      } else {
        this.isLoading = false;
        this.isLoadingDone.emit();
      }
    });
  }

  private _returnNull(): Observable<null> {
    return new Observable((subscriber) => {
      subscriber.next(null);
      subscriber.complete();
    });
  }
}
