import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Microsoft365LicenseSettings } from '../models/generated/smacsModels';

@Injectable()
export class Microsoft365LicenseSettingsResource {
  constructor(protected http: HttpClient) {}

  get(): Observable<Microsoft365LicenseSettings> {
    return this.http.get<Microsoft365LicenseSettings>('/services/microsoft/admin/365-license-settings');
  }

  put(data: Microsoft365LicenseSettings): Observable<void> {
    return this.http.put<void>('/services/microsoft/admin/365-license-settings', data);
  }
}
