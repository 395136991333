import { isEqual, map as lodashMap, reduce } from 'lodash';

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { BottomNavService } from '../../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import { ZeroTouchProvisioningResource } from '../../../../shared/resources/zpc-zero-touch.abstract.resource';
import {
  ViewZeroTouchJob,
  ZpcJobsViewAbstractDirective,
} from '../../../shared/jobs-view/zpc-jobs-view-abstract.directive';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from '../../../../shared/services/toast.service';
import {
  ClusterResult,
  DialPlanManagementGroup,
  JobStatus,
  ProvisioningSchedule,
  SiteResult,
  SiteSummary,
  ZeroTouchProvisioningOptions,
} from '../../../../shared/models/generated/smacsModels';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { CiscoProvisioningStatusesPollingService } from '../../../../shared/services/cisco/cisco-provisioning-statuses-polling.service';
import { FilterSelectOption } from '../../../../shared/filter/filter-select/filter-select.component';
import { DialPlanGroupContext } from '../../../../helpdesk/shared/contexts/dial-plan-group.context';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../../shared/entity-table/entity-table.models';
import { DialPlanGroupIdToNameFilter } from '../../../../shared/filters/dial-plan-group-id-to-name.filter';

const serviceNameToLabel = {
  dn: 'tkey;pages.zero_touch.provisioning.edit.form.primary_extension',
  agentExtension: 'tkey;pages.zero_touch.provisioning.edit.form.agent_extension',
  voicemail: 'tkey;pages.zero_touch_provisioning_edit.form.voicemail',
  imSoftPhone: 'tkey;pages.zero_touch.provisioning.edit.form.im_softphone',
  iphone: 'tkey;pages.zero_touch.provisioning.edit.form.iphone',
  android: 'tkey;pages.zero_touch.provisioning.edit.form.android',
  tablet: 'tkey;pages.zero_touch.provisioning.edit.form.tablet',
  cipc: 'tkey;pages.zero_touch.provisioning.edit.form.cipc',
  mobility: 'tkey;pages.zero_touch.provisioning.edit.form.extension_mobility',
  snrProfile: 'tkey;pages.zero_touch.provisioning.edit.form.single_number_reach',
  imp: 'tkey;pages.zero_touch_provisioning_edit.form.imp',
  snrDestinationForMicrosoftTeams: 'tkey;shared.service.teams_snr.service.text',
} as const;

export interface ViewProvisioningJob extends ViewZeroTouchJob {
  ldapFilter: string;
  siteId: number;
  siteName: string;
  dialPlanGroupIds: number[];
  services: (keyof typeof serviceNameToLabel)[];
}

@Component({
  selector: 'app-admin-zero-touch-provisioning-jobs-view',
  templateUrl: './provisioning-jobs-view.component.html',
  providers: [CiscoProvisioningStatusesPollingService, DialPlanGroupIdToNameFilter],
})
export class ProvisioningJobsViewComponent extends ZpcJobsViewAbstractDirective implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;

  isLoading = true;
  statusUpdatedSource = new BehaviorSubject([]);
  dialPlanGroups = [] as DialPlanManagementGroup[];
  provisioningJobs: ViewProvisioningJob[];
  filteredResult: ViewProvisioningJob[] = [];
  requestedJobIds = [] as number[];

  siteFilterOpts: FilterSelectOption[] = [];
  dialPlanGroupFilterOpts: any[] = [];
  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];

  private _subscriptions = new Subscription();
  private _siteSummary: SiteSummary;

  constructor(
    protected zeroTouchProvisioningResource: ZeroTouchProvisioningResource,
    protected router: Router,
    protected smacsModalService: SmacsModalService,
    protected translateService: TranslateService,
    protected bottomNavService: BottomNavService,
    protected route: ActivatedRoute,
    protected breadcrumbsService: BreadcrumbsService,
    protected siteSummaryContext: SiteSummaryContext,
    protected toastService: ToastService,
    private ciscoProvisioningStatusesPollingService: CiscoProvisioningStatusesPollingService,
    private dialPlanGroupContext: DialPlanGroupContext,
    private dialPlanGroupIdToNamePipe: DialPlanGroupIdToNameFilter
  ) {
    super(
      zeroTouchProvisioningResource,
      breadcrumbsService,
      bottomNavService,
      toastService,
      route,
      router,
      translateService,
      smacsModalService
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;pages.zero_touch.provisioning.title' }]);

    this.ciscoProvisioningStatusesPollingService.startPolling();
    this.ciscoProvisioningStatusesPollingService.state$.subscribe((statuses: JobStatus[]) => {
      this.statusUpdatedSource.next(statuses);
    });

    const contextSub = combineLatest([this.dialPlanGroupContext.state$, this.siteSummaryContext.state$]).subscribe(
      ([dialPlanState, siteSummaryState]) => {
        this.dialPlanGroups = dialPlanState;
        this.dialPlanGroupFilterOpts = lodashMap(dialPlanState, (dialPlanGroup) => {
          return {
            label: dialPlanGroup.name,
            value: dialPlanGroup.id,
          };
        });

        this._siteSummary = siteSummaryState;
        this.getSites(siteSummaryState);
        const refreshJobsSub = this._refreshJobs().subscribe();
        this._subscriptions.add(refreshJobsSub);
        this._buildTable();
      }
    );
    this._subscriptions.add(contextSub);
    this._buildForm();
  }

  ngOnDestroy() {
    this.ciscoProvisioningStatusesPollingService.stopPolling();
    this._subscriptions.unsubscribe();
  }

  _getServicesFromJob({ options }: { options: ZeroTouchProvisioningOptions }): string[] {
    return reduce(
      options,
      (acc, enabled: boolean, name: string) => (enabled === true ? [...acc, name] : acc),
      []
    ).sort();
  }

  getLabelFromServiceName(serviceName: keyof typeof serviceNameToLabel): string {
    return serviceNameToLabel[serviceName];
  }

  runJob(job: ViewZeroTouchJob) {
    if (!job.pending) {
      job.pending = true;
      this._mapJobsToTableRows();
      this.requestedJobIds.push(job.id);
      this.automationResource.runJob(job.id).subscribe();
    }
  }

  protected _getAllJobs = (): Observable<ViewProvisioningJob[]> => {
    return this.zeroTouchProvisioningResource.getAll().pipe(
      map((jobs: ProvisioningSchedule[]) => {
        return jobs.map((j: ProvisioningSchedule): ViewProvisioningJob => {
          return {
            id: j.id,
            name: j.name,
            periodicJobMinutes: j.periodicJobMinutes,
            dailyJobTime: j.dailyJobTime,
            ldapFilter: j.ldapFilter,
            siteId: j.options.siteId,
            siteName: this.siteSummaryContext.getSiteFromId(this._siteSummary, j.options.siteId).name,
            dialPlanGroupIds: j.options.dialPlanGroupIds,
            services: this._getServicesFromJob(j),
          } as ViewProvisioningJob;
        });
      })
    );
  };

  /**
   * Get sites, and format them for primeNg dropdown
   */
  private getSites(siteSummary: SiteSummary) {
    this.siteFilterOpts = [];

    siteSummary.clusters.forEach((cluster: ClusterResult) => {
      cluster.sites.forEach((site: SiteResult) => {
        this.siteFilterOpts.push({
          label: site.name,
          value: site.id,
          groupBy: cluster.name,
        });
      });
    });
  }

  private _buildForm() {
    this.zeroTouchJobs$.subscribe((filteredJobs: ViewProvisioningJob[]) => {
      if (!isEqual(filteredJobs, this.provisioningJobs)) {
        this.provisioningJobs = filteredJobs;
        this.filteredResult = filteredJobs;
        this._mapJobsToTableRows();
      }
    });
  }

  private _mapJobsToTableRows() {
    this.tableRows = this.provisioningJobs.map((job) => {
      let schedule;
      if (job.dailyJobTime) {
        schedule = this.translateService.instant('tkey;pages.zero_touch.schedule.daily', { time: job.dailyJobTime });
      } else if (job.periodicJobMinutes) {
        schedule = this.translateService.instant('tkey;pages.zero_touch.schedule.periodic', {
          minutes: job.periodicJobMinutes,
        });
      }
      const services = job.services.map((service) => {
        return this.getLabelFromServiceName(service);
      });
      const dialPlanId = this.dialPlanGroupIdToNamePipe.transform(job.dialPlanGroupIds, this.dialPlanGroups);
      return {
        content: {
          jobName: job.name,
          ldapFilter: job.ldapFilter,
          site: job.siteName,
          dialPlanGroup: dialPlanId,
          schedule: schedule,
          services: services.map((service) => {
            return this.translateService.instant(service);
          }),
        },
        html: {
          ldapFilter: `<code> ${job.ldapFilter} </code>`,
        },
        cssClass: job.pending ? 'table-warning' : '',
        badges: {
          services: {
            values: services.map((service) => {
              return this.translateService.instant(service);
            }),
          },
        },
        actions: [
          {
            buttonStyle: ButtonStyles.DANGER,
            dataAutomation: 'zero-touch-provisioning-result-row-delete',
            icon: SmacsIcons.DELETE,
            tooltip: 'tkey;pages.zero_touch.job.run_now.tooltip',
            tooltipDisabled: !job.pending,
            isDisabled: job.pending,
            onClick: () => this.deleteJob(job),
          },
          {
            buttonStyle: ButtonStyles.INFO,
            dataAutomation: 'zero-touch-provisioning-result-row-run-now',

            icon: SmacsIcons.RUN,
            label: job.pending ? 'tkey;pages.zero_touch.job.run_now.running' : 'tkey;pages.zero_touch.job.run_now',
            tooltip: job.pending ? 'tkey;pages.zero_touch.job.run_now.running' : 'tkey;pages.zero_touch.job.run_now',
            tooltipDisabled: !job.pending,
            isDisabled: job.pending,
            isPending: job.pending,
            iconPending: SmacsIcons.SAVING,
            onClick: () => {
              this.runJob(job);
            },
          },
          {
            buttonStyle: ButtonStyles.PRIMARY,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/edit-job/${job.id}`,
            dataAutomation: 'zero-touch-provisioning-result-row-edit',
            icon: SmacsIcons.NEXT,
            tooltip: 'tkey;pages.zero_touch.job.run_now.tooltip',
            tooltipDisabled: !job.pending,
            isDisabled: job.pending,
            onClick: () => this.editJob(job),
          },
        ],
        actionsColumnSize: 'col-sm-2',
      };
    });
  }

  private _buildTable() {
    this.table = {
      columns: [
        {
          columnId: 'jobName',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;pages.zero_touch.job_name',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'ldapFilter',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;pages.zero_touch.provisioning.filter.ldap_filter',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'site',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;pages.zero_touch.provisioning.filter.site',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: this.siteFilterOpts,
            filterFn: (rowsToFilter: EntityTableContentRow[], filterValue: FilterSelectOption, columnKey: string) => {
              return rowsToFilter.filter((row) => {
                return row.content[columnKey].trim().toLowerCase() === filterValue.label.trim().toLowerCase();
              });
            },
          },
        },
        {
          columnId: 'dialPlanGroup',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;pages.zero_touch.provisioning.filter.dial_plan_group',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: this.dialPlanGroupFilterOpts,
            filterFn: (rowsToFilter: EntityTableContentRow[], filterValue: FilterSelectOption, columnKey: string) => {
              return rowsToFilter.filter((row) => {
                return row.content[columnKey].trim().toLowerCase().includes(filterValue.label.trim().toLowerCase());
              });
            },
          },
        },
        {
          columnId: 'schedule',
          cssColumnSize: 'col-sm-1',
          label: 'tkey;pages.zero_touch.schedule',
        },
        {
          columnId: 'services',
          cssColumnSize: 'col-sm-1',
          label: 'tkey;pages.zero_touch.provisioning.filter.services',
        },
      ],
      cssClearButtonColumnSize: 'col-sm-2',
      resultsMessage: 'tkey;pages.zero_touch.provisioning.no_results',
      hasActions: true,
    };
  }
}
