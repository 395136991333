import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OAuth2ResourceServerSettings } from '../../shared/models/generated/smacsModels';

@Injectable()
export class Oauth2ResourceServerSettingsResource {
  constructor(private http: HttpClient) {}

  get(): Observable<OAuth2ResourceServerSettings> {
    return this.http.get<OAuth2ResourceServerSettings>('/services/system/admin/oauth2-resource-server-settings');
  }

  put(oAuth2ResourceServerSettings: OAuth2ResourceServerSettings): Observable<void> {
    return this.http.put<void>('/services/system/admin/oauth2-resource-server-settings', oAuth2ResourceServerSettings);
  }

  delete(): Observable<void> {
    return this.http.delete<void>('/services/system/admin/oauth2-resource-server-settings');
  }

  validate(oAuth2ResourceServerSettings: OAuth2ResourceServerSettings): Observable<void> {
    return this.http.post<void>(
      '/services/system/admin/oauth2-resource-server-settings/validation',
      oAuth2ResourceServerSettings
    );
  }
}
