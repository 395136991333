<app-loading-block [hidden]="!isLoading || modalProperties"></app-loading-block>
<div *ngIf="!isLoading" data-automation="smacs-voicemail-wrapper">
  <smacs-voicemail-form
    (voicemailDeleted)="onDeleteComplete($event)"
    (voicemailSaved)="onSaveComplete($event)"
    (voicemailRefUpdated)="onVoicemailRefUpdated($event)"
    [fieldConfig]="voicemailFieldConfig"
    [endUser]="endUser"
    [isExistingVoicemail]="isExistingVoicemail"
    [isExistingVoicemailOnPublicPhone]="isExistingVoicemailOnPublicPhone"
    [unityServerId]="unityServerId"
    [entity]="originalValue"
    [isPending]="isPending"
    [serverId]="serverId"
    [siteId]="siteId"
    [extension]="modalProperties ? this.modalProperties?.button?.dn?.extension : extension"
    [voicemail]="voicemail"
    [originalValue]="originalValue"
    [username]="username"
    [alternateExtensions]="alternateExtensions"
    [modalProperties]="modalProperties"
    [global360View]="global360View"
  ></smacs-voicemail-form>
</div>
