import { Injectable, Injector } from '@angular/core';
import {
  AppUserRef,
  AppUserResult,
  ClusterResult,
  DirectoryNumberRef,
  EndUserRef,
  EndUserResult,
  ExtensionMobility,
  ExtensionMobilityFieldConfig,
  Global360View,
  LineButton,
  LineFeatureFieldConfig,
  ModelProtocolFieldConfig,
  PhoneFieldConfig,
} from '../../models/generated/smacsModels';
import { combineLatest, forkJoin, Observable, of, ReplaySubject, Subscriber, switchMap } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SelfServe360View } from '../../../self-serve/contexts/self-serve-ui.context';
import { CurrentClusterContext } from '../../contexts/current-cluster.context';
import { PhoneFieldConfigResource } from '../../../helpdesk/shared/resources/field-config/phone-field-config.resource';
import { SiteContext } from '../../../helpdesk/shared/contexts/site.context';
import { AppUserSearchResource } from '../../resources/app-user-search.resource';
import { EndUserSearchResource } from '../../resources/end-user-search.resource';
import { Global360ViewContext } from '../../contexts/global-360-view.context';
import { LineFeatureConfigResource } from '../../resources/line-feature-config.resource';
import { first, map, take } from 'rxjs/operators';
import { PhoneType } from '../../models/service-type';
import { ModelProtocolFieldConfigResource } from '../../../helpdesk/shared/resources/field-config/model-protocol-field-config.resource';
import { SearchPhoneResource } from '../../resources/search-phone.resource';
import { ExtensionMobilityProfilesResource } from '../../resources/extension-mobility-profiles.resource';
import { AbstractUiContext } from './abstract-ui.context';

@Injectable()
export class ExtMobilityUiContext extends AbstractUiContext<ExtensionMobility> {
  private _phoneSource = new ReplaySubject<ExtensionMobility>(1);
  phoneState$ = this._phoneSource.asObservable();

  private _isBuildPhoneCalled = new ReplaySubject<boolean>(1);
  isBuildPhoneCalled$ = this._isBuildPhoneCalled.asObservable();

  private _phoneFieldConfigSource = new ReplaySubject<ExtensionMobilityFieldConfig>(1);
  phoneFieldConfigState$ = this._phoneFieldConfigSource.asObservable();

  phoneUiContextModel: string;
  phoneUiContextProtocol: string;
  phoneFieldConfig: ExtensionMobilityFieldConfig;
  builtPhone: ExtensionMobility;

  constructor(
    protected lineFeatureConfigResource: LineFeatureConfigResource,
    private injector: Injector,
    private extensionMobilityProfilesResource: ExtensionMobilityProfilesResource,
    private searchPhoneResource: SearchPhoneResource,
    private phoneFieldConfigResource: PhoneFieldConfigResource,
    private currentClusterContext: CurrentClusterContext,
    private siteContext: SiteContext,
    private endUserSearchResource: EndUserSearchResource,
    private appUserSearchResource: AppUserSearchResource,
    private modelProtocolFieldConfigResource: ModelProtocolFieldConfigResource,
    protected route: ActivatedRoute
  ) {
    super(lineFeatureConfigResource);
    const username = this.route.snapshot?.params?.username as string;
    const phoneId = this.route.snapshot?.params?.phoneId as string;
    const phoneName = this.route.snapshot?.params?.phoneName as string;

    this.siteContext.state$.pipe(first()).subscribe((site) => {
      this.modelProtocolFieldConfigResource
        .post(PhoneType.EXTENSION_MOBILITY, { siteId: Number(site.id) })
        .subscribe((response: ModelProtocolFieldConfig) => {
          this._modelProtocolFieldConfigSource.next(response);

          const model = response.models.defaultValue ? response.models.defaultValue : '';
          const protocol = model ? response.modelSpecificProtocols[model].defaultValue : '';

          if (!protocol && !phoneId && !phoneName) {
            this._phoneSource.next({
              model: response.models.defaultValue,
              buttons: [],
              serviceSubscriptions: [],
              name: '',
            } as ExtensionMobility);
            this._phoneFieldConfigSource.next(null);
            this._isBuildPhoneCalled.next(false);
          } else if (!phoneId && !phoneName) {
            this.phoneUiContextProtocol = protocol;
            this.phoneUiContextModel = model;

            this.getFieldConfig(site.id.toString(), model, protocol, true).subscribe((phoneFieldConfig) => {
              this.phoneFieldConfig = phoneFieldConfig;
              const contextStates = [
                this.phoneFieldConfigState$,
                this.currentClusterContext.state$,
              ] as Observable<any>[];
              if (username) {
                contextStates.push(injector.get(Global360ViewContext).state$);
              }
              combineLatest(contextStates).pipe(first()).subscribe();
              this._buildNewPhone(
                username,
                Number(site.id),
                this.phoneFieldConfigState$,
                this.injector,
                model,
                protocol
              ).subscribe((newPhone) => {
                this._isBuildPhoneCalled.next(true);
                this.set(newPhone);
              });
            });
          }
        });
    });

    if (phoneId) {
      const request = this.currentClusterContext.state$ as Observable<SelfServe360View | ClusterResult>;
      request.pipe(first()).subscribe((result) => {
        const cucmServerId = (result as ClusterResult).cucmServerId;
        if (!!cucmServerId) {
          this._get(phoneId, cucmServerId.toString()).subscribe((phone) => {
            this.set(phone);
          });
        }
      });
    } else if (phoneName) {
      this.searchPhoneResource.get({ name: phoneName }).subscribe((results) => {
        if (results.length > 1) {
          const serverIds = results.map((r) => r.ref.serverId).join(', ');
          throw new Error(
            `Could not find a unique phone with name [${phoneName}]. Results were found on servers with IDs: ${serverIds}`
          );
        } else if (results.length === 0) {
          throw new Error(`Could not find phone with name [${phoneName}]`);
        }
        const result = results[0];
        forkJoin([
          this._get(result.ref.id, result.ref.serverId.toString()),
          this.currentClusterContext.state$.pipe(first()),
        ]).subscribe(([phone]) => {
          this.currentClusterContext.setCurrentClusterFromCucmServer(result.ref.serverId);
          this.set(phone);
        });
      });
    }
  }

  private _buildNewPhone(
    username: string,
    siteId: number,
    phoneFieldConfigState$: Observable<PhoneFieldConfig | ExtensionMobilityFieldConfig>,
    injector: Injector,
    model: string,
    protocol: string
  ): Observable<ExtensionMobility> {
    return new Observable<ExtensionMobility>((subscriber) => {
      const contextStates = [phoneFieldConfigState$, this.currentClusterContext.state$] as Observable<any>[];
      if (username) {
        contextStates.push(injector.get(Global360ViewContext).state$);
      }
      combineLatest(contextStates)
        .pipe(first())
        .subscribe((contextResults) => {
          const extMobilityFieldConfig = contextResults[0] as ExtensionMobilityFieldConfig;
          const currentCluster = contextResults[1] as ClusterResult;
          const global360View = contextResults[2] as Global360View;

          const cucmServerId = currentCluster.cucmServerId;
          const endUser = global360View
            ? global360View.endUsers.find((user: EndUserResult) => user.ref.serverId === cucmServerId)
            : null;
          const primaryExtension = global360View
            ? global360View.primaryExtensions.find(
                (extension: DirectoryNumberRef) => extension.serverId === cucmServerId
              )
            : null;

          // Get Associated End Users
          const associatedEndUserObs = extMobilityFieldConfig.associatedEndUsers.defaultValues
            .filter((user: EndUserRef) => user?.username !== username)
            .map((user: EndUserRef) =>
              this.endUserSearchResource
                .searchByUsername(user.username)
                .pipe(
                  map((endUserResults: EndUserResult[]) =>
                    endUserResults.find((endUserResult: EndUserResult) => endUserResult.ref.serverId === cucmServerId)
                  )
                )
            );

          // Get Associated End Users via CTI
          const ctiAssociatedEndUserObs = extMobilityFieldConfig.associatedEndUsersCti.defaultValues
            .filter((user) => user?.username !== username)
            .map((user) =>
              this.endUserSearchResource
                .searchByUsername(user.username)
                .pipe(
                  map((endUserResults: EndUserResult[]) =>
                    endUserResults.find((endUserResult: EndUserResult) => endUserResult.ref.serverId === cucmServerId)
                  )
                )
            );

          // Get Associated App Users via CTI
          const ctiAssociatedAppUserObs = extMobilityFieldConfig.associatedAppUsersCti.defaultValues.map((user) =>
            this.appUserSearchResource.searchByUsername(user.username, cucmServerId).pipe(map((res) => res[0]))
          );
          const miscPhoneFeatureObservables = [
            associatedEndUserObs.length ? combineLatest(associatedEndUserObs) : of([]),
            ctiAssociatedEndUserObs.length ? combineLatest(ctiAssociatedEndUserObs) : of([]),
            ctiAssociatedAppUserObs.length ? combineLatest(ctiAssociatedAppUserObs) : of([]),
          ] as Observable<any>[];

          if (username) {
            const lineOneFeatureObs = this.lineFeatureConfigResource.post({
              model: model,
              protocol: protocol,
              deviceOwner: username,
              extension: primaryExtension.extension,
              siteId: siteId,
            });
            miscPhoneFeatureObservables.push(lineOneFeatureObs);
          }

          combineLatest(miscPhoneFeatureObservables)
            .pipe(first())
            .subscribe((miscResults) => {
              const associatedEndUserResults = miscResults[0] as EndUserResult[];
              const ctiAssociatedEndUserResults = miscResults[1] as EndUserResult[];
              const ctiAssociatedAppUserResults = miscResults[2] as AppUserResult[];
              const lineFeatureConfig = miscResults[3] as LineFeatureFieldConfig;

              if (
                endUser &&
                !associatedEndUserResults.some((result: EndUserResult) => result.ref.id === endUser.ref.id)
              ) {
                associatedEndUserResults.unshift(endUser);
              }

              if (
                endUser &&
                !ctiAssociatedEndUserResults.some((result: EndUserResult) => result.ref.id === endUser.ref.id)
              ) {
                ctiAssociatedEndUserResults.unshift(endUser);
              }

              const buttons = username
                ? [
                    {
                      type: 'Line',
                      dn: primaryExtension,
                      lineFeature: {
                        associatedEndUsers: endUser ? [endUser.ref] : [],
                        busyTrigger: Number(lineFeatureConfig.busyTrigger.defaultValue),
                        callRecordingMediaSource: lineFeatureConfig.callRecordingMediaSource.defaultValue,
                        callRecordingOption: lineFeatureConfig.callRecordingOption.defaultValue,
                        callRecordingProfile: lineFeatureConfig.callRecordingProfile.defaultValue,
                        externalCallerId: lineFeatureConfig.externalCallerId.defaultValue,
                        externalCallerIdNumber: lineFeatureConfig.externalCallerIdNumber.defaultValue,
                        internalCallerId: lineFeatureConfig.internalCallerId.defaultValue,
                        label: lineFeatureConfig.label.defaultValue,
                        maxNumberOfCalls: Number(lineFeatureConfig.maxNumberOfCalls.defaultValue),
                        monitoringCssName: lineFeatureConfig.monitoringCssName.defaultValue,
                        ringActive: lineFeatureConfig.ringActive.defaultValue,
                        ringIdle: lineFeatureConfig.ringIdle.defaultValue,
                      },
                    } as LineButton,
                  ]
                : [
                    {
                      type: 'Line',
                      dn: null,
                      lineFeature: null,
                    },
                  ];

              const phone: ExtensionMobility = {
                id: undefined,
                alwaysUsePrimeLine: extMobilityFieldConfig.alwaysUsePrimeLine.defaultValue,
                alwaysUsePrimeLineForVoiceMessage:
                  extMobilityFieldConfig.alwaysUsePrimeLineForVoiceMessage.defaultValue,
                associatedEndUsers: associatedEndUserResults.filter((user) => !!user).map((user) => user.ref),
                buttons: buttons,
                description: extMobilityFieldConfig.description.defaultValue,
                featureControlPolicyName: extMobilityFieldConfig.featureControlPolicy?.defaultValue ?? '',
                model: model,
                name: extMobilityFieldConfig.name.defaultValue,
                phoneButtonTemplate: undefined, // phone component handles this on model/protocol change
                privacy: extMobilityFieldConfig.privacy.defaultValue,
                protocol: '', // phone-fields form takes care of this
                serviceSubscriptions: [],
                softkeyTemplate: extMobilityFieldConfig.softkeyTemplate.defaultValue,
                crossClusterCss: extMobilityFieldConfig.crossClusterCss.defaultValue,
                userLocale: extMobilityFieldConfig.userLocale?.defaultValue ?? '',
                userMohSourceName: extMobilityFieldConfig.userMohAudioSource.defaultValue,
                ctiAssociatedAppUsers: ctiAssociatedAppUserResults.filter((user) => !!user).map((user) => user.ref),
                ctiAssociatedEndUsers: ctiAssociatedEndUserResults.filter((user) => !!user).map((user) => user.ref),
              };
              this.builtPhone = phone;
              subscriber.next(phone);
              subscriber.complete();
            });
        });
    });
  }

  setupPhone(
    username: string,
    siteId: number,
    model: string,
    protocol: string,
    phoneId: string,
    updateLineFeature: any,
    isFormUpdated: boolean
  ): Observable<ExtensionMobility> {
    return new Observable((subscriber: Subscriber<ExtensionMobility>) => {
      if (isFormUpdated || phoneId) {
        const contextStates = [this.phoneFieldConfigState$, this.currentClusterContext.state$] as Observable<any>[];
        if (username) {
          contextStates.push(this.injector.get(Global360ViewContext).state$);
        }
        combineLatest(contextStates)
          .pipe(take(1))
          .subscribe((states) => {
            const extMobilityFieldConfig = states[0] as ExtensionMobilityFieldConfig;
            const currentCluster = states[1] as ClusterResult;
            const global360View = states[2] as Global360View;

            const cucmServerId = currentCluster.cucmServerId;
            const endUser = global360View
              ? global360View.endUsers.find((user: EndUserResult) => user.ref.serverId === cucmServerId)
              : null;
            const primaryExtension = global360View
              ? global360View.primaryExtensions.find(
                  (extension: DirectoryNumberRef) => extension.serverId === cucmServerId
                )
              : null;

            const associatedEndUsersObs = extMobilityFieldConfig.associatedEndUsers.defaultValues
              .filter((user: EndUserRef) => user.username !== username)
              .map((user: EndUserRef) =>
                this.endUserSearchResource
                  .searchByUsername(user.username)
                  .pipe(
                    map((endUserResults: EndUserResult[]) =>
                      endUserResults.find((endUserResult: EndUserResult) => endUserResult.ref.serverId === cucmServerId)
                    )
                  )
              );
            const associatedEndUsersCtiObs = extMobilityFieldConfig.associatedEndUsersCti.defaultValues
              .filter((user: EndUserRef) => user.username !== username)
              .map((user: EndUserRef) =>
                this.endUserSearchResource
                  .searchByUsername(user.username)
                  .pipe(
                    map((endUserResults: EndUserResult[]) =>
                      endUserResults.find((endUserResult: EndUserResult) => endUserResult.ref.serverId === cucmServerId)
                    )
                  )
              );
            const associatedAppUsersCtiObs = extMobilityFieldConfig.associatedAppUsersCti.defaultValues.map(
              (user: AppUserRef) =>
                this.appUserSearchResource
                  .searchByUsername(user.username, cucmServerId)
                  .pipe(map((res: AppUserResult[]) => res[0]))
            );

            const miscPhoneFeatureObservables = [
              associatedEndUsersObs.length ? combineLatest(associatedEndUsersObs) : of([]),
              associatedEndUsersCtiObs.length ? combineLatest(associatedEndUsersCtiObs) : of([]),
              associatedAppUsersCtiObs.length ? combineLatest(associatedAppUsersCtiObs) : of([]),
            ] as Observable<any>[];

            if (username) {
              const lineOneFeatureObs = this.lineFeatureConfigResource.post({
                model: model,
                protocol: protocol,
                deviceOwner: username,
                extension: primaryExtension.extension,
                siteId: siteId,
              });
              miscPhoneFeatureObservables.push(lineOneFeatureObs);
            }

            this.phoneState$.pipe(take(1)).subscribe((state: ExtensionMobility) => {
              this._getMiscPhoneFeatures(
                username,
                siteId,
                model,
                protocol,
                extMobilityFieldConfig,
                cucmServerId,
                primaryExtension.extension
              ).subscribe(
                ({
                  associatedEndUserResults,
                  ctiAssociatedEndUserResults,
                  ctiAssociatedAppUserResults,
                  lineFeatureConfig,
                }) => {
                  if (endUser && !associatedEndUserResults.some((result) => result.ref.id === endUser.ref.id)) {
                    associatedEndUserResults.unshift(endUser);
                  }

                  if (endUser && !ctiAssociatedEndUserResults.some((result) => result.ref.id === endUser.ref.id)) {
                    ctiAssociatedEndUserResults.unshift(endUser);
                  }

                  // Only wipe buttons when no dn/line-feature currently exist son first line

                  // if no line-feature / config exists do this
                  let buttons;
                  const buttonOne = state.buttons[0] as LineButton;
                  if (!username) {
                    buttons = [{ type: 'Line', dn: null, lineFeature: null }];
                  } else if (!buttonOne?.dn || !buttonOne.lineFeature) {
                    // New phone, give line some values
                    buttons = [
                      {
                        type: 'Line',
                        dn: primaryExtension,
                        lineFeature: {
                          associatedEndUsers: endUser ? [endUser.ref] : [],
                          busyTrigger: Number(lineFeatureConfig.busyTrigger.defaultValue),
                          callRecordingMediaSource: lineFeatureConfig.callRecordingMediaSource.defaultValue,
                          callRecordingOption: lineFeatureConfig.callRecordingOption.defaultValue,
                          callRecordingProfile: lineFeatureConfig.callRecordingProfile.defaultValue,
                          externalCallerId: lineFeatureConfig.externalCallerId.defaultValue,
                          externalCallerIdNumber: lineFeatureConfig.externalCallerIdNumber.defaultValue,
                          internalCallerId: lineFeatureConfig.internalCallerId.defaultValue,
                          label: lineFeatureConfig.label.defaultValue,
                          maxNumberOfCalls: Number(lineFeatureConfig.maxNumberOfCalls.defaultValue),
                          monitoringCssName: lineFeatureConfig.monitoringCssName.defaultValue,
                          ringActive: lineFeatureConfig.ringActive.defaultValue,
                          ringIdle: lineFeatureConfig.ringIdle.defaultValue,
                        },
                      },
                    ];
                  } else {
                    if (updateLineFeature) {
                      if (updateLineFeature.label) {
                        buttonOne.lineFeature.label = lineFeatureConfig.label.defaultValue;
                      } else {
                        buttonOne.lineFeature.label = lineFeatureConfig.label.defaultValue;
                      }
                      if (updateLineFeature.ringActive) {
                        buttonOne.lineFeature.ringActive = lineFeatureConfig.ringActive.defaultValue;
                      } else {
                        buttonOne.lineFeature.ringActive = lineFeatureConfig.ringActive.defaultValue;
                      }
                      if (updateLineFeature.ringIdle) {
                        buttonOne.lineFeature.ringIdle = lineFeatureConfig.ringIdle.defaultValue;
                      } else {
                        buttonOne.lineFeature.ringIdle = lineFeatureConfig.ringIdle.defaultValue;
                      }
                      if (updateLineFeature.callRecordingMediaSource) {
                        buttonOne.lineFeature.callRecordingMediaSource =
                          lineFeatureConfig.callRecordingMediaSource.defaultValue;
                      } else {
                        buttonOne.lineFeature.callRecordingMediaSource =
                          lineFeatureConfig.callRecordingMediaSource.defaultValue;
                      }
                      if (updateLineFeature.callRecordingOption) {
                        buttonOne.lineFeature.callRecordingOption = lineFeatureConfig.callRecordingOption.defaultValue;
                      } else {
                        buttonOne.lineFeature.callRecordingOption = lineFeatureConfig.callRecordingOption.defaultValue;
                      }
                      if (updateLineFeature.callRecordingProfile) {
                        buttonOne.lineFeature.callRecordingProfile =
                          lineFeatureConfig.callRecordingProfile.defaultValue;
                      } else {
                        buttonOne.lineFeature.callRecordingProfile =
                          lineFeatureConfig.callRecordingProfile.defaultValue;
                      }
                    }
                    // Editing existing phone, don't clear line
                    buttons = [buttonOne];
                  }

                  const extensionMobility: ExtensionMobility = {
                    id: undefined,
                    alwaysUsePrimeLine: extMobilityFieldConfig.alwaysUsePrimeLine.defaultValue,
                    alwaysUsePrimeLineForVoiceMessage:
                      extMobilityFieldConfig.alwaysUsePrimeLineForVoiceMessage.defaultValue,
                    associatedEndUsers: associatedEndUserResults.filter((user) => !!user).map((user) => user.ref),
                    buttons: buttons,
                    description: extMobilityFieldConfig.description.defaultValue,
                    featureControlPolicyName: extMobilityFieldConfig.featureControlPolicy?.defaultValue ?? '',
                    userLocale: extMobilityFieldConfig.userLocale?.defaultValue ?? '',
                    model: model,
                    name: extMobilityFieldConfig.name.defaultValue,
                    phoneButtonTemplate: undefined, // phone component handles this on model/protocol change
                    privacy: extMobilityFieldConfig.privacy.defaultValue,
                    serviceSubscriptions: [],
                    softkeyTemplate: extMobilityFieldConfig.softkeyTemplate.defaultValue,
                    userMohSourceName: extMobilityFieldConfig.userMohAudioSource.defaultValue,
                    crossClusterCss: extMobilityFieldConfig.crossClusterCss.defaultValue,
                    ctiAssociatedAppUsers: ctiAssociatedAppUserResults.filter((user) => !!user).map((user) => user.ref),
                    ctiAssociatedEndUsers: ctiAssociatedEndUserResults.filter((user) => !!user).map((user) => user.ref),
                    protocol: protocol,
                  };

                  subscriber.next(extensionMobility);
                  subscriber.complete();
                }
              );
            });
          });
      } else {
        if (this.builtPhone) {
          this.builtPhone.protocol = this.phoneUiContextProtocol;
        }
        subscriber.next(this.builtPhone);
        subscriber.complete();
      }
    });
  }

  set(phone: ExtensionMobility) {
    this._phoneSource.next(phone);
  }

  getFieldConfig(
    siteId: string,
    model: string,
    protocol: string,
    isInitialRun?: boolean
  ): Observable<ExtensionMobilityFieldConfig> {
    return new Observable((sub) => {
      const username = this.route.snapshot?.params?.username as string;

      if (this.phoneUiContextProtocol !== protocol || this.phoneUiContextModel !== model || isInitialRun) {
        this.phoneFieldConfigResource
          .get(PhoneType.EXTENSION_MOBILITY, {
            username: username,
            siteId: siteId,
            model: model,
            protocol: protocol,
          })
          .subscribe((fieldConfig: ExtensionMobilityFieldConfig) => {
            this._phoneFieldConfigSource.next(fieldConfig);
            sub.next(fieldConfig);
            sub.complete();
          });
      } else {
        this._phoneFieldConfigSource.next(this.phoneFieldConfig);
        sub.next(this.phoneFieldConfig);
        sub.complete();
      }
    });
  }

  private _getMiscPhoneFeatures(
    username: string,
    siteId: number,
    model: string,
    protocol: string,
    extMobilityFieldConfig: ExtensionMobilityFieldConfig,
    cucmServerId: number,
    extension: string
  ): Observable<{
    associatedEndUserResults: EndUserResult[];
    ctiAssociatedEndUserResults: EndUserResult[];
    ctiAssociatedAppUserResults: AppUserResult[];
    lineFeatureConfig: LineFeatureFieldConfig;
  }> {
    const associatedEndUsersObs = extMobilityFieldConfig.associatedEndUsers.defaultValues
      .filter((user: EndUserRef) => user.username !== username)
      .map((user: EndUserRef) =>
        this.endUserSearchResource
          .searchByUsername(user.username)
          .pipe(
            map((endUserResults: EndUserResult[]) =>
              endUserResults.find((endUserResult: EndUserResult) => endUserResult.ref.serverId === cucmServerId)
            )
          )
      );
    const associatedEndUsersCtiObs = extMobilityFieldConfig.associatedEndUsersCti.defaultValues
      .filter((user: EndUserRef) => user.username !== username)
      .map((user: EndUserRef) =>
        this.endUserSearchResource
          .searchByUsername(user.username)
          .pipe(
            map((endUserResults: EndUserResult[]) =>
              endUserResults.find((endUserResult: EndUserResult) => endUserResult.ref.serverId === cucmServerId)
            )
          )
      );
    const associatedAppUsersCtiObs = extMobilityFieldConfig.associatedAppUsersCti.defaultValues.map(
      (user: AppUserRef) =>
        this.appUserSearchResource
          .searchByUsername(user.username, cucmServerId)
          .pipe(map((res: AppUserResult[]) => res[0]))
    );

    const miscPhoneFeatureObservables = [
      associatedEndUsersObs.length ? combineLatest(associatedEndUsersObs) : of([]),
      associatedEndUsersCtiObs.length ? combineLatest(associatedEndUsersCtiObs) : of([]),
      associatedAppUsersCtiObs.length ? combineLatest(associatedAppUsersCtiObs) : of([]),
    ] as Observable<any>[];

    if (username) {
      const lineOneFeatureObs = this.lineFeatureConfigResource.post({
        model: model,
        protocol: protocol,
        deviceOwner: username,
        extension: extension,
        siteId: siteId,
      });
      miscPhoneFeatureObservables.push(lineOneFeatureObs);
    }

    return combineLatest(miscPhoneFeatureObservables)
      .pipe(first())
      .pipe(
        switchMap((miscResults) => {
          const associatedEndUserResults = miscResults[0] as EndUserResult[];
          const ctiAssociatedEndUserResults = miscResults[1] as EndUserResult[];
          const ctiAssociatedAppUserResults = miscResults[2] as AppUserResult[];
          const lineFeatureConfig = miscResults[3] as LineFeatureFieldConfig;

          return of({
            associatedEndUserResults: associatedEndUserResults,
            ctiAssociatedEndUserResults: ctiAssociatedEndUserResults,
            ctiAssociatedAppUserResults: ctiAssociatedAppUserResults,
            lineFeatureConfig: lineFeatureConfig,
          });
        })
      );
  }

  private _get(id: string, cucmServerId: string): Observable<ExtensionMobility> {
    return this.extensionMobilityProfilesResource.get(id, cucmServerId);
  }
}
