<div class="side-navigation">
  <nav class="side-navigation__menu d-flex flex-column" [ngClass]="{ 'side-navigation__menu--minimized': isMinimized }">
    <div>
      <div class="side-navigation__menu__header">
        <div class="side-navigation__menu__toggle">
          <button (click)="toggleMenu()" class="btn side-navigation__menu__toggle__button">
            <span
              class="fa fa-chevron-right side-navigation__menu__toggle__button__icon"
              [ngClass]="{ 'side-navigation__menu__toggle__button__icon--flipped': !isMinimized }"
            ></span>
          </button>
        </div>
        <div
          class="align-items-end justify-content-between side-navigation__menu__title__wrapper"
          [ngClass]="{ hidden: isMinimized }"
        >
          <h3 class="side-navigation__menu__title">{{ title | translate }}</h3>
        </div>
      </div>
      <side-navigation-search
        [searchFilter]="searchFilter"
        [minimized]="isMinimized"
        (searchFilterHasUpdated)="onSearchFilterUpdate($event)"
      ></side-navigation-search>
    </div>
    <ul *ngIf="!searchFilter" class="side-navigation__menu__list" [@inOutAnimation]>
      <side-navigation-section
        *ngFor="let section of filteredNavSections"
        [icon]="section.icon"
        [sectionTitle]="section.title"
        [isLink]="section.isLink"
        [link]="section.link"
        [dataAutomation]="section.dataAutomation"
        [searchFilter]="searchFilter"
        [isDisabled]="section?.isDisabled ? section.isDisabled(navProps) : false"
        [disabledTooltip]="section?.disabledTooltip ? section.disabledTooltip(navProps) : undefined"
        [isVisible]="section?.isVisible ? section.isVisible(navProps) : true"
        [childLinks]="section?.links"
        [navProps]="navProps"
        [isMinimized]="isMinimized"
        (clickEvent)="handleClickEvent($event)"
        (toggleMinimized)="expandSideNav()"
      ></side-navigation-section>
    </ul>

    <ul *ngIf="searchFilter && !isMinimized" class="side-navigation__menu__list" [@inOutAnimation]>
      <span class="side-navigation__menu__description">{{ 'tkey;admin.side_nav.search_heading' | translate }}</span>

      <side-navigation-section
        *ngFor="let section of filteredNavSections"
        [icon]="section.icon"
        [sectionTitle]="section.title"
        [isLink]="section.isLink"
        [link]="section.link"
        [dataAutomation]="section.dataAutomation"
        [searchFilter]="searchFilter"
        [isDisabled]="section?.isDisabled ? section.isDisabled(navProps) : false"
        [disabledTooltip]="section?.disabledTooltip ? section.disabledTooltip(navProps) : undefined"
        [isVisible]="section?.isVisible ? section.isVisible(navProps) : true"
        [childLinks]="section?.links"
        [navProps]="navProps"
        [isMinimized]="isMinimized"
        (clickEvent)="handleClickEvent($event)"
        (toggleMinimized)="expandSideNav()"
      ></side-navigation-section>

      <div *ngIf="filteredNavSections.length === 0" class="side-navigation__menu__description py-3 text-center fw-bold">
        {{ 'tkey;admin.side_nav.no_results' | translate }}
      </div>
    </ul>
  </nav>
</div>
