import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  HealthStatus,
  LdapConfiguration,
  LdapConnection,
  LdapGroups,
  LdapMembers,
  LdapPermissionGroup,
  LdapPublicConfiguration,
  LdapUserId,
  LdapUserResult,
} from '../models/generated/smacsModels';

@Injectable()
export class LdapResource {
  constructor(private http: HttpClient) {}

  getLdapConfiguration(): Observable<LdapConfiguration> {
    return this.http.get<LdapConfiguration>('/services/cisco/admin/ldap-configuration');
  }

  getLdapConfigurationSettings(): Observable<LdapPublicConfiguration> {
    return this.http.get<LdapPublicConfiguration>('/services/cisco/admin/ldap-configuration/settings');
  }

  putLdapConfiguration(ldapConnectionConfig: LdapConfiguration): Observable<LdapConfiguration> {
    return this.http.put<LdapConfiguration>('/services/cisco/admin/ldap-configuration', ldapConnectionConfig);
  }

  testLdapConnectionConfiguration(ldapConnection: LdapConnection): Observable<HealthStatus> {
    return this.http.post<HealthStatus>('/services/cisco/admin/ldap-configuration/connections', ldapConnection);
  }

  getLdapPermissionGroup(): Observable<LdapPermissionGroup> {
    return this.http.get<LdapPermissionGroup>('/services/cisco/admin/ldap-permission-groups');
  }

  putLdapPermissionGroup(ldapGroupConfig: LdapPermissionGroup): Observable<void> {
    return this.http.put<void>('/services/cisco/admin/ldap-permission-groups', ldapGroupConfig);
  }

  searchLdapGroupMembers(group: string, max: number): Observable<LdapMembers> {
    return this.http.get<LdapMembers>(
      `/services/cisco/macs/ldap-groups/members?group=${encodeURIComponent(group)}&max=${encodeURIComponent(
        max.toString()
      )}`
    );
  }

  findUsersByAttribute(attributeName: string, attributeValue: string, max: number): Observable<LdapUserId[]> {
    return this.http.get<LdapUserId[]>(
      `/services/cisco/macs/ldap-users/search/attribute?attributeName=${encodeURIComponent(
        attributeName
      )}&attributeValue=${encodeURIComponent(attributeValue)}&max=${encodeURIComponent(max.toString())}`
    );
  }

  searchUsers(filter: string, max?: number): Observable<LdapUserResult[]> {
    return this.http.get<LdapUserResult[]>(
      `/services/cisco/macs/ldap-users/search/filter?filter=${encodeURIComponent(filter)}` +
        (max === undefined ? '' : `&max=${encodeURIComponent(max.toString())}`)
    );
  }

  searchLdapGroups(group: string, max: number): Observable<LdapGroups> {
    return this.http.get<LdapGroups>(
      `/services/cisco/macs/ldap-groups?query=${encodeURIComponent(group)}&max=${encodeURIComponent(max.toString())}`
    );
  }
}
