<div class="pcce-form-container container-fluid">
  <smacs-fieldset-header [legend]="'tkey;pages.details.agentSettings.header'">
    <smacs-text fieldId="userName"></smacs-text>
    <smacs-checkbox fieldId="ssoEnabled"></smacs-checkbox>
    <smacs-text fieldId="password"></smacs-text>
    <smacs-text fieldId="confirmPassword"></smacs-text>
    <smacs-text fieldId="description"></smacs-text>
    <smacs-select fieldId="department"></smacs-select>
    <smacs-select fieldId="agentTeam"></smacs-select>
    <smacs-select fieldId="agentDeskSettings"></smacs-select>
    <smacs-select fieldId="skillGroups"></smacs-select>
  </smacs-fieldset-header>

  <legend>{{ 'tkey;pcce.attributes.title' | translate }}</legend>

  <smacs-navset data-automation="attributes-navset" fieldId="pcceNavset"></smacs-navset>
</div>
