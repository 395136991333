import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ServiceTileType } from '../../cisco/user-detail-home/service-tile-type.model';

@Component({
  selector: 'smacs-service-tile',
  templateUrl: './service-tile.component.html',
})
export class SmacsServiceTileComponent {
  serviceTileType = ServiceTileType;

  @Input() tileType: ServiceTileType;

  // Common Inputs for tiles
  @Input() serviceName: string;
  @Input() serviceType: string;
  @Input() serviceState: string;
  @Input() icon: string;
  @Input() isEnabled = true;
  @Input() isDisplayed = true;
  @Input() tooltip: string;
  @Input() description: string;
  @Input() dataAutomation: string;

  // Add Tile Inputs + Outputs
  @Input() isAdding = false;
  @Input() isInProgress?: boolean;
  @Input() isInProgressBadgeText = 'tkey;userdetail.microsoft_365.licensing_in_progress.badge.text';
  @Output() addTileClicked = new EventEmitter();

  // Delete Tile-specific Inputs + Outputs
  @Input() isDeleting = false;
  @Input() isDeleted = false;
  @Output() deleteTileConfirmButtonClicked = new EventEmitter();
  @Output() deleteTileCancelButtonClicked = new EventEmitter();

  // Edit Tile-specific Inputs
  @Input() isDeskPhone = false;
  @Input() isDevice = false;
  @Input() isStatusCheckInProgress = false;
  @Input() hasMultipleDeskPhones = false;
  @Input() deleteTooltip: string;
  @Input() deviceToolip: string;
  @Input() deviceIconMarkup: string;
  @Input() isDeleteEnabled = true;
  @Input() isDeleteShown = true;
  @Input() isExchangeEnabled = true;
  @Input() isExchangeShown = false;
  @Input() phone: { serverId: number; name: string };
  @Output() editTileClicked = new EventEmitter();
  @Output() editTileDeleteClicked = new EventEmitter();
  @Output() editTileExchangeClicked = new EventEmitter();
  @Output() editDeskPhoneClicked = new EventEmitter();
  @Output() editDeskPhoneAddClicked = new EventEmitter();
  @Output() editDeskPhoneCopyClicked = new EventEmitter();

  // Multi Edit Tiles Inputs
  @Input() multiEditTiles: { serviceName: string; description: string; icon: string }[];
  @Output() multiEditAddClicked = new EventEmitter();

  onAddTileClicked() {
    this.addTileClicked.emit();
  }

  onDeleteTileConfirmButtonClicked() {
    this.deleteTileConfirmButtonClicked.emit();
  }

  onDeleteTileCancelButtonClicked() {
    this.deleteTileCancelButtonClicked.emit();
  }

  onEditTileClicked() {
    this.editTileClicked.emit();
  }

  onEditTileDeleteClicked() {
    this.editTileDeleteClicked.emit();
  }

  onEditTileExchangeClicked() {
    this.editTileExchangeClicked.emit();
  }

  onEditDeskPhoneClicked() {
    this.editDeskPhoneClicked.emit();
  }

  onEditTileDeskPhoneAddClicked() {
    this.editDeskPhoneAddClicked.emit();
  }

  onEditTileDeskPhoneCopyClicked() {
    this.editDeskPhoneCopyClicked.emit();
  }

  onMultiEditAddClicked() {
    this.multiEditAddClicked.emit();
  }
}
