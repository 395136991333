import { Component } from '@angular/core';
import { LineButton } from '../../../models/generated/smacsModels';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { PhoneButtonsService } from '../../shared/phone-buttons.service';

enum LineButtonLabelTitle {
  LABEL_TITLE = 'tkey;pages.details.phoneSettings.button.speedDial.label',
  NUMBER_TITLE = 'tkey;pages.details.phoneSettings.button.speedDial.number',
}

interface SelfServeLineFormData {
  extension: string;
  label: string;
}

@Component({
  selector: 'smacs-phone-buttons-line',
  templateUrl: './phone-buttons-line.component.html',
})
export class PhoneButtonsLineComponent extends SmacsFormAbstractDirective<LineButton, SelfServeLineFormData> {
  fieldGroups = {
    textInputs: ['extension', 'label'],
  };

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private phoneButtonsService: PhoneButtonsService
  ) {
    super(smacsFormStateService);
  }

  formConfig = {
    fields: {
      extension: {
        disabled: () => true,
        disabledTooltip: 'tkey;shared.device.phone.phone_buttons.config_pane.disabled_extension',
        label: LineButtonLabelTitle.NUMBER_TITLE,
        dataAutomation: 'phone-button-line-extension',
        required: false,
      },
      label: {
        label: LineButtonLabelTitle.LABEL_TITLE,
        dataAutomation: 'phone-button-line-label',
        required: false,
        validation: [
          {
            validator: (val: string) =>
              this.phoneButtonsService.validLineLabelPattern(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: 'tkey;validators.global.error.pattern',
          },
          {
            validator: (val: string) =>
              this.phoneButtonsService.validLabelLength(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: {
              content: 'tkey;validators.global.error.maxlength',
              params: {
                maxlength: 30,
              },
            },
          },
        ],
      },
    },
    options: {
      columnClasses: {
        label: 'col-3 text-lg-end',
        input: 'col-9',
      },
    },
  } as SmacsFormConfig;

  submit() {
    return this.parentSubmit$;
  }

  toEntity = (formData: SelfServeLineFormData): LineButton => {
    return { ...this.entity, lineFeature: { ...this.entity.lineFeature, label: formData.label } };
  };

  toFormData = (entity: LineButton): SelfServeLineFormData => {
    return {
      extension: entity?.dn?.extension ? entity.dn.extension : '',
      label: entity?.lineFeature?.label ? entity.lineFeature.label : '',
    };
  };
}
