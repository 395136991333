import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsFileUploadConfig } from '../../../forms/fields/file-upload/smacs-file-upload.component';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../shared/services/toast.service';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { CdrDumpFileImportResource } from '../../resources/cdr-dump-file-import.resource';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { parse } from 'papaparse';
import { EMPTY, Observable, Subscriber, Subscription, throwError } from 'rxjs';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { JobState, JobStatus } from '../../../shared/models/generated/smacsModels';
import { CdrDumpFileImportPollingContext } from '../../contexts/cdr-dump-file-import-polling.context';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { catchError, tap } from 'rxjs/operators';

export interface ManualCdrImport {
  cdrTextFile: File;
}

interface CdrFile {
  cdrRecordType: string;
}

@Component({
  selector: 'smacs-admin-manual-cdr-import',
  templateUrl: './cdr-dump-file-import.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [CdrDumpFileImportResource, CdrDumpFileImportPollingContext],
})
export class CdrDumpFileImportComponent
  extends SmacsFormAbstractDirective<ManualCdrImport>
  implements OnInit, OnDestroy
{
  isLoading = true;
  bottomNavId = 'manualCdrImportButton';
  bottomNavButtons: BottomNavButton;
  bottomNavImportLabel = 'tkey;admin.manual_cdr_import.button.import';
  bottomNavUpdatingLabel = 'tkey;admin.manual_cdr_import.button.in_progress';
  previousJobState: JobState;
  currentJobState: JobState;
  jobIsCurrentlyRunning = false;
  isSuccess: boolean;
  smacsIcons = SmacsIcons;

  formConfig = {
    fields: {
      cdrTextFile: {
        required: true,
        label: 'tkey;admin.manual_cdr_import.input.label',
        dataAutomation: 'manual-cdr-import-file-field',
        componentConfig: new SmacsFileUploadConfig({
          acceptedFileExtensions: { acceptedExtensions: '.txt' },
          maxSize: 3145728,
        }),
        validation: [
          {
            validator: (data) => this._validateCdrFile(data),
            message: 'tkey;admin.manual_cdr_import.csv.error',
          },
        ],
        debounceTime: 0,
      },
    },
  } as SmacsFormConfig;

  private _subscriptions = new Subscription();

  constructor(
    private bottomNavService: BottomNavService,
    private toastService: ToastService,
    private manualImportCdrResource: CdrDumpFileImportResource,
    protected smacsFormStateService: SmacsFormStateService,
    private manualCdrImportPollingContext: CdrDumpFileImportPollingContext,
    private breadcrumbsService: BreadcrumbsService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.manual_cdr_import.title' }]);
    this.bottomNavButtons = {
      id: this.bottomNavId,
      label: this.bottomNavImportLabel,
      icon: SmacsIcons.IMPORT,
      buttonClass: ButtonStyles.PRIMARY,
      dataAutomation: 'bottom-nav-import-button',
      submitSubject: this._validateAndSubmitSource,
      type: ButtonTypes.SUBMIT,
      state: {
        buttonDisableState: {
          disabled: false,
          pending: false,
          tooltipKey: '',
        },
      },
    } as BottomNavButton;

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList([this.bottomNavButtons]));
    this._listenToPolling();
    const validateAndSubmitSub = this._validateAndSubmitSource.subscribe(() => {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });
    this._subscriptions.add(validateAndSubmitSub);
    this.isLoading = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.breadcrumbsService.clearBreadcrumbs();
    this.manualCdrImportPollingContext.stopPolling();
    this._subscriptions.unsubscribe();
  }

  protected submit() {
    return this._postCdrImportTextFileAsBinary();
  }

  private _listenToPolling() {
    this.manualCdrImportPollingContext.startPolling();
    this.manualCdrImportPollingContext.state$.subscribe(this._handlePolling);
  }

  private _handlePolling = (jobStatus: JobStatus) => {
    this.previousJobState = this.currentJobState || jobStatus.jobState;
    this.currentJobState = jobStatus.jobState;
    this.isSuccess = this.currentJobState === JobState.IDLE && this.previousJobState !== JobState.IDLE;
    if (this.isSuccess) {
      this.jobIsCurrentlyRunning = false;
      this.smacsFormStateService.setIsFormDirty(false);
      this._updateBottomNavButtonAsValid();
      this.previousJobState = JobState.IDLE;
      this.currentJobState = JobState.IDLE;
      this.manualCdrImportPollingContext.stopPolling();
      this.toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.IMPORT,
        'tkey;admin.manual_cdr_import.toast.success.title',
        'tkey;admin.manual_cdr_import.toast.success.description'
      );
    }
    if (
      jobStatus.error !== null &&
      (this.currentJobState === JobState.IDLE || this.previousJobState === JobState.RUNNING) &&
      this.previousJobState !== JobState.IDLE
    ) {
      this.jobIsCurrentlyRunning = false;
      this.smacsFormStateService.setIsFormDirty(false);
      this._updateBottomNavButtonAsValid();
      this.manualCdrImportPollingContext.stopPolling();
      this.toastService.push(
        ToastTypes.ERROR,
        SmacsIcons.PHONE_WITH_LIST,
        'tkey;admin.manual_cdr_import.failure.toast.title',
        'tkey;admin.manual_cdr_import.failure.toast.message'
      );
    }

    if (this.currentJobState === JobState.RUNNING) {
      this.jobIsCurrentlyRunning = true;
      this._updateBottomNavButtonAsPending();
    }
  };

  private _updateBottomNavButtonAsValid() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          ...this.bottomNavButtons,
          state: { buttonDisableState: { disabled: false, tooltipKey: '' }, pending: false },
          label: this.bottomNavImportLabel,
        },
      ])
    );
  }

  private _updateBottomNavButtonAsPending() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          ...this.bottomNavButtons,
          state: { buttonDisableState: { disabled: true, tooltipKey: '' }, pending: true },
          label: this.bottomNavUpdatingLabel,
        },
      ])
    );
  }

  private _postCdrImportTextFileAsBinary(): Observable<void> {
    this._updateBottomNavButtonAsPending();
    this.jobIsCurrentlyRunning = true;

    return this.manualImportCdrResource.post(this.formData.cdrTextFile).pipe(
      tap(() => {
        this.currentJobState = JobState.RUNNING;
        this._listenToPolling();
      }),
      catchError(() => {
        this.jobIsCurrentlyRunning = false;
        this._updateBottomNavButtonAsValid();
        this.toastService.push(
          ToastTypes.ERROR,
          SmacsIcons.IMPORT,
          'tkey;admin.manual_cdr_import.toast.invalid.title',
          'tkey;admin.manual_cdr_import.toast.invalid.description'
        );
        return throwError(() => EMPTY);
      })
    );
  }

  private _parseFile(result: any): Observable<SmacsFormsValidationState> {
    return new Observable((subscriber: Subscriber<SmacsFormsValidationState>) => {
      parse<CdrFile>(result, {
        header: true,
        skipEmptyLines: true,
        step: (row, parser) => {
          const data = row.data as unknown as CdrFile;
          const cdrRecordType = data.cdrRecordType;

          if (parseInt(cdrRecordType) !== 1) {
            parser.abort();
          }
        },
        complete: (results) => {
          const valid = !results.errors.length && !results.meta.aborted;
          const state = valid ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
          subscriber.next(state);
          subscriber.complete();
        },
      });
    });
  }

  private _validateCdrFile(file: File): Observable<SmacsFormsValidationState> {
    const chunkSize = 100000; // bytes = 0.1mb
    const offset = 0;

    return new Observable((subscriber: Subscriber<SmacsFormsValidationState>) => {
      const r = new FileReader();
      const blob = file.slice(offset, chunkSize + offset);
      r.onload = (e) => {
        if (e.target.error == null) {
          this._parseFile(e.target.result).subscribe((state: SmacsFormsValidationState) => {
            subscriber.next(state);
            subscriber.complete();
          });
        } else {
          subscriber.next(SmacsFormsValidationState.INVALID);
          subscriber.complete();
        }
      };
      r.readAsText(blob);
    });
  }
}
