import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { DelegateManagementResource } from '../../../shared/resources/delegate-management.resource';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AssignedCallingDelegate,
  CallingDelegate,
  Microsoft365UserResult,
  MicrosoftTeamsCallingSettings,
  MicrosoftTeamsUserResult,
  UnansweredDelayInSeconds,
  UnansweredTargetType,
  UserPhoto,
} from '../../../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { forkJoin, Observable, of, Subscription, switchMap, tap, throwError } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { Microsoft365UserResource, UserPhotoSearchParams } from '../../../shared/resources/microsoft-365-user.resource';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../forms/fields/select/smacs-select.component';
import { MicrosoftTeamsUserSearchResource } from '../../../shared/resources/microsoft-teams-user-search.resource';
import { BottomNavButton } from '../../../../shared/bottom-nav/bottom-nav.component';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { CallingDelegateCardFormComponent } from './calling-delegate-card-form/calling-delegate-card-form.component';
import { TeamsCallingHandlingAndForwardingResource } from '../../../shared/resources/teams-calling-handling-and-forwarding.resource';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../../shared/services/toast.service';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { catchError, map } from 'rxjs/operators';
import { isEqual } from 'lodash';

interface DelegateManagementFormData {
  searchTerm: string | SmacsSelectOption;
}

export interface SelectedDelegate {
  assignedCallingDelegate: AssignedCallingDelegate;
  userPhoto: UserPhoto;
}

@Component({
  selector: 'ziro-delegate-management',
  templateUrl: './delegate-management.component.html',
  providers: [DelegateManagementResource, TeamsCallingHandlingAndForwardingResource],
})
export class DelegateManagementComponent
  extends SmacsFormAbstractDirective<DelegateManagementFormData>
  implements OnInit, OnDestroy
{
  @ViewChildren(CallingDelegateCardFormComponent) childForms: QueryList<CallingDelegateCardFormComponent>;
  isLoading = true;

  userDisplayName: string;
  formConfig: SmacsFormConfig;
  teamsUserResults: MicrosoftTeamsUserResult[];
  userPhotos: UserPhoto[] = [];
  selectedDelegates: SelectedDelegate[] = [];
  private _upn: string;
  private _initialDelegate: CallingDelegate;
  private _subscriptions = new Subscription();
  constructor(
    private _delegateManagementResource: DelegateManagementResource,
    private _route: ActivatedRoute,
    private _microsoft365UserResource: Microsoft365UserResource,
    private _microsoftTeamsUserSearchResource: MicrosoftTeamsUserSearchResource,
    private _bottomNavService: BottomNavService,
    private _router: Router,
    private _teamsCallingAndForwardingResource: TeamsCallingHandlingAndForwardingResource,
    private _toastService: ToastService,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._upn = this._route.snapshot.paramMap.get('upn');
    this._initFormConfig();
    this._initUser().subscribe(() => {
      this._initBottomNav();
      this.isLoading = false;
    });

    const updateSub = this.smacsFormsUpdate$.subscribe((update) => {
      const selectedOption = update.new.searchTerm as SmacsSelectOption;
      if (!!selectedOption) {
        const selectedOptionUpn = selectedOption.value as string;
        const displayName = selectedOption.label;
        this._addSelectedDelegate(selectedOptionUpn, displayName);
        this.entitySource.next({ searchTerm: undefined });
      }
    });
    this._subscriptions.add(updateSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  delegateRemoveClicked(delegateId: string) {
    this.smacsFormStateService.setIsFormDirty(true);
    this.selectedDelegates = this.selectedDelegates.filter(
      (delegate) => delegate.assignedCallingDelegate.id !== delegateId
    );
  }

  protected submit(): Observable<void> {
    this._bottomNavService.setButtonPendingState('delegate-management-save', true);
    return this._saveCallingDelegate()
      .pipe(switchMap(() => this._updateTeamsCallingSettings(this.childForms.map((child) => child.entity))))
      .pipe(
        tap(() => {
          this._initBottomNav();
          this._toastService.push(
            ToastTypes.SUCCESS,
            SmacsIcons.DELEGATE,
            'tkey;microsoft_360.view.teams.delegate_management.save.toast.title',
            'tkey;microsoft_360.view.teams.delegate_management.save.toast.message'
          );
          this._router.navigate(['../../'], { relativeTo: this._route });
        }),
        catchError((err) => {
          this._initBottomNav();
          return throwError(() => err);
        })
      );
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        searchTerm: {
          label: 'tkey;microsoft_360.view.delegate_management.add_delegate.label',
          dataAutomation: 'async-user-search',
          componentConfig: new SmacsSelectConfig({
            options: [],
            isMultiSelect: false,
            asyncOptionsFn: (searchTerm) => this._getUserAsync(searchTerm),
            showAutoGenerationLink: true,
            placeholder: 'tkey;microsoft_360.view.delegate_management.add_delegate.placeholder',
            minSearchLength: 3,
            clearWithInput: true,
          }),
        },
      },
      options: {
        columnClasses: {
          label: 'ms-4 ps-4 w-auto',
          input: 'w-75',
        },
      },
    };
  }

  private _setCurrentUserData(): Observable<Microsoft365UserResult[]> {
    return this._microsoft365UserResource.search(this._upn).pipe(
      tap((results) => {
        this.userDisplayName = results[0].microsoft365UserRef.displayName;
      })
    );
  }

  private _setCallingDelegate(): Observable<UserPhoto[]> {
    return this._delegateManagementResource.getCallingDelegate(this._upn).pipe(
      switchMap((delegate: CallingDelegate) => {
        this._initialDelegate = delegate;
        const userPhotoSearchParams: UserPhotoSearchParams = {
          photoSize: '48x48',
          userPrincipalNames: delegate.assignedCallingDelegates.map((assignedDelegate) => assignedDelegate.id),
        };
        return userPhotoSearchParams.userPrincipalNames.length
          ? this._microsoft365UserResource.userPhotoSearch(userPhotoSearchParams)
          : of(null);
      }),
      tap((userPhotos: UserPhoto[]) => {
        if (this._initialDelegate.assignedCallingDelegates.length) {
          this.userPhotos = this.userPhotos.concat(userPhotos);
          this.selectedDelegates = this._initialDelegate.assignedCallingDelegates.map((assignedDelegate) => {
            return {
              assignedCallingDelegate: assignedDelegate,
              userPhoto: userPhotos.find((userPhoto) => userPhoto.userPrincipalName === assignedDelegate.id),
            };
          });
        }
      })
    );
  }

  private _initUser(): Observable<[Microsoft365UserResult[], UserPhoto[]]> {
    return forkJoin([this._setCurrentUserData(), this._setCallingDelegate()]);
  }

  private _getUserAsync(searchTerm: string): Observable<SmacsSelectOption[]> {
    return this._microsoftTeamsUserSearchResource.search(searchTerm).pipe(
      switchMap((results: MicrosoftTeamsUserResult[]) => {
        this.teamsUserResults = results.filter(
          (result) =>
            !this.selectedDelegates.some(
              (delegate) => delegate.assignedCallingDelegate.id === result.userPrincipalName
            ) && result.userPrincipalName !== this._upn
        );
        return results.length
          ? this._microsoft365UserResource.userPhotoSearch({
              photoSize: '48x48',
              userPrincipalNames: results.map((result) => result.userPrincipalName),
            })
          : of([]);
      }),
      map((userPhotos: UserPhoto[]) => {
        this.userPhotos = userPhotos;
        return this.teamsUserResults.map((result) => {
          const userPhoto =
            userPhotos.find((userPhoto) => userPhoto.userPrincipalName === result.userPrincipalName)?.photoBase64 || '';
          return {
            label: result.ref.displayName,
            value: result.userPrincipalName,
            photoBase64: userPhoto,
            displayIcon: !userPhoto,
            disabled: !result.enterpriseVoiceEnabled,
            appendValue: true,
            disabledTooltip: !result.enterpriseVoiceEnabled
              ? 'tkey;microsoft_360.view.delegate_management.add_delegate.disabled_tooltip'
              : '',
            tooltipPlacement: 'start',
          };
        });
      })
    );
  }

  private _addSelectedDelegate(upn: string, displayName: string) {
    const selectedDelegate: SelectedDelegate = {
      assignedCallingDelegate: {
        id: upn,
        makeCallsEnabled: true,
        receiveCallsEnabled: true,
        manageSettingsEnabled: false,
      },
      userPhoto: {
        userPrincipalName: upn,
        displayName,
        photoBase64: this.userPhotos.find((userPhoto) => userPhoto.userPrincipalName === upn)?.photoBase64 || '',
      },
    };
    this.selectedDelegates.push(selectedDelegate);
  }

  private _onDeleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        title: this._translateService.instant('tkey;microsoft_360.view.teams.delegate_management.delete.modal.title'),
        promptBody: this._translateService.instant(
          'tkey;microsoft_360.view.teams.delegate_management.delete.modal.message'
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteDelegates(),
          },
        ],
      },
    };
    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteDelegates(): Observable<void> {
    const callingDelegate: CallingDelegate = {
      userPrincipalName: this._upn,
      assignedCallingDelegates: [],
    };
    return this._delegateManagementResource.putCallingDelegate(callingDelegate).pipe(
      switchMap(() => this._updateTeamsCallingSettings([])),
      tap(() => {
        this._toastService.pushDeleteToast(
          'tkey;microsoft_360.view.teams.delegate_management.delete.toast.success.message',
          this._upn
        );
        this._router.navigate(['../../'], { relativeTo: this._route });
      })
    );
  }

  private _initBottomNav() {
    const buttonsList: BottomNavButton[] = [
      {
        id: 'delegate-management-cancel',
        dataAutomation: 'delegate-management-cancel',
        label: 'tkey;global.button.cancel.text',
        buttonClass: ButtonStyles.DEFAULT,
        cb: () => {
          this._router.navigate(['../../'], { relativeTo: this._route });
        },
      },
      {
        type: ButtonTypes.SUBMIT,
        id: 'delegate-management-save',
        dataAutomation: 'delegate-management-save',
        label: 'tkey;microsoft_360.view.teams.delegate_management.bottom_nav.save_button.text',
        icon: SmacsIcons.OK,
        buttonClass: ButtonStyles.PRIMARY,
        submitSubject: this._validateAndSubmitSource,
      },
    ];

    if (this._initialDelegate.assignedCallingDelegates.length) {
      buttonsList.splice(1, 0, {
        id: 'delegate-management',
        dataAutomation: 'delegate-management-delete',
        label: 'tkey;global.button.delete.text',
        buttonClass: ButtonStyles.DANGER,
        cb: () => this._onDeleteClicked(),
      });
    }
    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttonsList));
  }

  private _saveCallingDelegate(): Observable<void> {
    const callingDelegate: CallingDelegate = {
      userPrincipalName: this._upn,
      assignedCallingDelegates: this.childForms.map((assignedDelegate) => assignedDelegate.entity),
    };
    this._initialDelegate = callingDelegate;
    return this._delegateManagementResource.putCallingDelegate(callingDelegate);
  }

  private _updateTeamsCallingSettings(assignedDelegates: AssignedCallingDelegate[]): Observable<void> {
    return this._teamsCallingAndForwardingResource.getTeamsCallingHandlingForwarding(this._upn).pipe(
      switchMap((currentSettings) => {
        const voicemailTypeOrCurrent: UnansweredTargetType =
          currentSettings.unansweredTargetType === UnansweredTargetType.DELEGATES
            ? UnansweredTargetType.VOICEMAIL
            : currentSettings.unansweredTargetType;

        const updatedSettings: MicrosoftTeamsCallingSettings = {
          ...currentSettings,
          unansweredTargetType: assignedDelegates.some((assignedDelegate) => assignedDelegate.receiveCallsEnabled)
            ? UnansweredTargetType.DELEGATES
            : voicemailTypeOrCurrent,
        };

        const voicemailDelayOrDefault: UnansweredDelayInSeconds =
          updatedSettings.unansweredTargetType === UnansweredTargetType.VOICEMAIL
            ? UnansweredDelayInSeconds.SECONDS_20
            : currentSettings.unansweredDelayInSeconds;

        // If user is saving at least one delegate with receiveCallsEnabled, we want to reduce unansweredDelay to 10
        // if not, 20 seconds is standard for VOICEMAIL target type, otherwise no change
        updatedSettings.unansweredDelayInSeconds =
          updatedSettings.unansweredTargetType === UnansweredTargetType.DELEGATES
            ? UnansweredDelayInSeconds.SECONDS_10
            : voicemailDelayOrDefault;

        // User had SINGLE_TARGET type and it did not change so their target should not change(would throw error)
        updatedSettings.unansweredTarget =
          updatedSettings.unansweredTargetType === UnansweredTargetType.SINGLE_TARGET
            ? currentSettings.unansweredTarget
            : '';

        return !isEqual(currentSettings, updatedSettings)
          ? this._teamsCallingAndForwardingResource.putTeamsCallingHandlingForwarding(updatedSettings)
          : of(null);
      })
    );
  }
}
