<app-top-nav></app-top-nav>
<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div *ngIf="!isLoading" data-automation="smacs-search" class="container-fluid">
  <div class="col-md-8 offset-md-2 py-3">
    <h2 class="my-3">
      <span class="fa-stack text-info me-2">
        <i [attr.class]="smacsIcons.CIRCLE + ' fa-stack-2x'"></i>
        <i [attr.class]="smacsIcons.SEARCH + ' fa-stack-1x fa-inverse'"></i>
      </span>
      <span class="vertical-align-middle">{{ 'tkey;search.header' | translate }}</span>
    </h2>

    <h5 *ngIf="query?.length > 2" data-automation="search-result-summary" class="mb-4">
      <span *ngIf="microsoftEndUserResults?.length <= 0">
        {{
          (totalResults === 1 ? 'tkey;search.summary.one_found.text' : 'tkey;search.summary.text')
            | translate : { results: totalResults }
        }}
      </span>
      <span *ngIf="microsoftEndUserResults?.length === 250">
        {{ 'tkey;search.summary.top.text' | translate }}
      </span>
    </h5>

    <div *ngIf="!query || query.length < 3" class="alert alert-danger" data-automation="no-query-warning">
      <strong>{{ 'tkey;search.query.too.small.text' | translate }}</strong>
    </div>
    <div *ngIf="query?.length > 2">
      <!-- CUCM Users -->
      <smacs-search-result-section
        *ngIf="cucmEndUserResults?.length > 0"
        data-automation="search-result-section-cucm-users"
        [heading]="'tkey;search.users.section.header'"
        [resultsCount]="cucmEndUserResults?.length"
      >
        <ziro-cisco-search-result
          *ngFor="let result of cucmEndUserResults"
          [result]="result"
          [icon]="smacsIcons.USER"
          [description]="getCucmEndUserDescription(result)"
          [edit]="{
            link: '/user/' + result.ref.username,
            tooltip: getCucmEndUserTooltip(result),
            isDisabled: usernameHasSpaces(result) || result.isDisabled
          }"
        ></ziro-cisco-search-result>
      </smacs-search-result-section>

      <!-- Microsoft Users -->
      <div
        *ngIf="microsoftEndUserResults?.length > 0"
        class="microsoft-search-table mt-4"
        data-automation="search-result-section-microsoft-users"
      >
        <!-- Column Headers -->
        <div class="microsoft-search-table__header border-bottom btn border-0 text-start">
          <div class="header__display-name">
            <strong>{{ 'tkey;search.ms_users.results.displayname.header' | translate }}</strong>
          </div>
          <div class="header__upn">
            <strong>{{ 'tkey;search.ms_users.results.upn.header' | translate }}</strong>
          </div>
          <div class="microsoft-search-header__assigned-number">
            <strong>{{ 'tkey;search.ms_users.results.assigned_number.header' | translate }}</strong>
          </div>
          <div class="microsoft-search-header__edit-btn"></div>
        </div>
        <ziro-microsoft-search-result
          data-automation="microsoft-search-result"
          *ngFor="let result of microsoftEndUserResults; let last = last; let first = first"
          class="border-top btn btn-default border-0"
          (click)="handleResultClick(result.ref.id.includes('#EXT#'), result.ref.id)"
          [ngStyle]="{
            cursor: result.ref.id.includes('#EXT#') ? 'not-allowed' : 'pointer'
          }"
          [ngClass]="{ 'border-bottom': last }"
          [result]="result.ref"
          [lineUri]="result.lineUri"
          [lineUriExtension]="result.lineUriExtension"
          [isLoadingLineUri]="isLoadingLineUri"
          [photo]="result.photo"
          [isGuestAccount]="result.ref.id.includes('#EXT#')"
        ></ziro-microsoft-search-result>
      </div>

      <!-- Phones -->
      <smacs-search-result-section
        *ngIf="phoneResults?.length > 0"
        data-automation="search-result-section-phones"
        [heading]="'tkey;search.phones.section.header'"
        [resultsCount]="phoneResults?.length"
      >
        <ziro-cisco-search-result
          *ngFor="let result of phoneResults"
          [result]="result"
          [icon]="getPhoneIcon(result)"
          [description]="getPhoneDescription(result)"
          [edit]="{
            link: getPhoneEditLink(result),
            isDisabled: getPhoneEditLinkState(result).isDisabled,
            tooltip: getPhoneEditLinkState(result).disabledTooltip
          }"
          [delete]="{
            isDeletable: true,
            isDisabled: false
          }"
          [devicePoolSites]="devicePoolSites"
          (deleteClicked)="onPhoneDeleteClicked($event)"
        ></ziro-cisco-search-result>
      </smacs-search-result-section>

      <!-- Directory Numbers -->
      <smacs-search-result-section
        *ngIf="directoryNumberResults?.length > 0"
        data-automation="search-result-section-dns"
        [heading]="'tkey;search.directory.numbers.section.header'"
        [resultsCount]="directoryNumberResults?.length"
      >
        <ziro-cisco-search-result
          *ngFor="let result of directoryNumberResults"
          [result]="result"
          [icon]="smacsIcons.HASH"
          [description]="getDnDescription(result)"
          [delete]="{
            isDeletable: true
          }"
          (deleteClicked)="onDnDeleteClicked($event)"
        ></ziro-cisco-search-result>
      </smacs-search-result-section>

      <!-- Voicemails -->
      <smacs-search-result-section
        *ngIf="voicemailResults?.length > 0"
        data-automation="search-result-section-voicemail"
        [heading]="'tkey;search.voicemails.section.header'"
        [resultsCount]="voicemailResults?.length"
      >
        <ziro-cisco-search-result
          *ngFor="let result of voicemailResults"
          [result]="result"
          [icon]="smacsIcons.VOICEMAIL"
          [description]="getVoicemailDescription(result)"
          [pinReset]="{
            link: getPinResetLink(result),
            tooltip: 'tkey;search.pin.reset.button'
          }"
          [delete]="{
            isDeletable: true
          }"
          (deleteClicked)="onVoicemailDeleteClicked($event)"
        ></ziro-cisco-search-result>
      </smacs-search-result-section>

      <!-- Distribution Lists -->
      <smacs-search-result-section
        *ngIf="distributionListResults?.length > 0"
        data-automation="search-result-section-distribution-lists"
        [heading]="'tkey;search.distribution.lists.section.header'"
        [resultsCount]="distributionListResults?.length"
      >
        <ziro-cisco-search-result
          *ngFor="let result of distributionListResults"
          [result]="result"
          [icon]="smacsIcons.DISTRIBUTION_LIST_SEARCH"
          [description]="getDistributionListDescription(result)"
          [edit]="{
            link: '/server/' + result.ref.serverId + '/distribution-list/' + result.ref.id
          }"
        ></ziro-cisco-search-result>
      </smacs-search-result-section>

      <!-- SNR Profiles -->
      <smacs-search-result-section
        *ngIf="snrResults?.length > 0"
        data-automation="search-result-section-snr-profiles"
        [heading]="'tkey;search.snr.section.header'"
        [resultsCount]="snrResults?.length"
      >
        <ziro-cisco-search-result
          *ngFor="let result of snrResults"
          [result]="result"
          [icon]="smacsIcons.SNR"
          [description]="getSnrDescription(result)"
          [edit]="{
            link: '/user/' + result.owner?.username + '/snr/' + result.ref.id,
            isDisabled: getPhoneEditLinkState(result).isDisabled,
            tooltip: getPhoneEditLinkState(result).disabledTooltip
          }"
          [delete]="{
            isDeletable: true,
            isDisabled: !result.owner
          }"
          [devicePoolSites]="devicePoolSites"
          (deleteClicked)="onSnrDeleteClicked($event)"
        ></ziro-cisco-search-result>
      </smacs-search-result-section>

      <!-- Extension Mobility -->
      <smacs-search-result-section
        *ngIf="extensionMobilityProfiles?.length > 0"
        data-automation="search-result-section-extension-mobility"
        [heading]="'tkey;search.extension_mobility_profiles.section.header'"
        [resultsCount]="extensionMobilityProfiles?.length"
      >
        <ziro-cisco-search-result
          *ngFor="let result of extensionMobilityProfiles"
          [result]="result"
          [icon]="smacsIcons.EXTENSION_MOBILITY_SEARCH"
          [description]="getExtensionMobilityProfileDescription(result)"
          [delete]="{
            isDeletable: true
          }"
          (deleteClicked)="onExtensionMobilityProfileDeleteClicked($event)"
        ></ziro-cisco-search-result>
      </smacs-search-result-section>
    </div>
  </div>
</div>
