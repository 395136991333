import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UserDetailUiContext } from '../../../shared/contexts/user-detail-ui.context';
import { BottomNavClearButtonsList, BottomNavService } from '../../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { cloneDeep, get } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { VoicemailComponent } from '../../voicemail/voicemail.component';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { Global360ViewContext } from '../../../../shared/contexts/global-360-view.context';
import { SiteContext } from '../../../shared/contexts/site.context';
import { combineLatest, Subscription } from 'rxjs';

@Component({
  selector: 'smacs-user-detail-voicemail',
  templateUrl: './user-detail-voicemail.component.html',
})
export class UserDetailVoicemailComponent implements OnInit, OnDestroy {
  @Output() voicemailDelete = new EventEmitter<void>();
  @ViewChild(VoicemailComponent) voicemailComponent: VoicemailComponent;

  siteId: number;
  isLoading = true;
  username: string;
  voicemailId: string;
  _icons = SmacsIcons;

  private _subscriptions = new Subscription();

  constructor(
    private userDetailUiContext: UserDetailUiContext,
    private siteContext: SiteContext,
    private bottomNavService: BottomNavService,
    private translateService: TranslateService,
    private breadcrumbsService: BreadcrumbsService,
    private route: ActivatedRoute,
    private global360ViewContext: Global360ViewContext,
    private router: Router
  ) {}

  ngOnInit() {
    this.voicemailId = get(this.route, 'snapshot.params.voicemailId');
    const contextSub = combineLatest([this.siteContext.state$, this.userDetailUiContext.state$]).subscribe(([site]) => {
      this.siteId = Number(site.id);
      this.username = this.userDetailUiContext.getCurrentEnduser().ref.username;
      this.isLoading = false;
    });
    this._subscriptions.add(contextSub);
  }

  ngOnDestroy() {
    this.bottomNavService.dispatch(new BottomNavClearButtonsList());
    this._subscriptions.unsubscribe();
  }

  onVoicemailSave(): void {
    this.router.navigate(this.voicemailId ? ['../../'] : ['../'], { relativeTo: this.route });
    const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
    const voicemailRef = this.voicemailComponent.voicemailRef;
    global360.voicemails.push(voicemailRef);
    this.global360ViewContext._stateSource.next(global360);
  }

  onVoicemailDelete(): void {
    const global360View = cloneDeep(this.userDetailUiContext.getGlobal360View());
    global360View.voicemails.splice(0, 1);
    this.global360ViewContext._stateSource.next(global360View);
    this.router.navigate(['../../'], { relativeTo: this.route });
  }
}
