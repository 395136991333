<div class="card mb-4">
  <div class="card-header d-flex justify-content-between align-items-center">
    <span class="p-0 m-0">
      {{ 'tkey;admin.site_management.site.site_configuration.label' | translate }}
    </span>
  </div>
  <div class="card-body pb-0">
    <smacs-text [fieldId]="'name'"></smacs-text>
    <smacs-text [fieldId]="'ldapAttribute'"></smacs-text>
  </div>
</div>
