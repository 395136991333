import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { MenuItem } from 'primeng/api';

@Injectable()
export class BreadcrumbsService {
  private _breadcrumbsSource = new ReplaySubject<MenuItem[]>(1);
  breadcrumbs$ = this._breadcrumbsSource.asObservable();

  updateBreadcrumbs = (breadcrumbs: MenuItem[]) => {
    this._breadcrumbsSource.next(breadcrumbs);
  };

  clearBreadcrumbs = () => {
    this._breadcrumbsSource.next(null);
  };
}
