<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 mb-3">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.order_numbers.create_orders.about.description' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <app-entity-table
      *ngIf="table && !isLoading"
      [table]="table"
      [tableRows]="tableRows"
      (headerSelectAllChange)="handleSelectAllChange($event)"
      (fieldChange)="handleTableFieldChange()"
      data-automation="create-draft-entity-table"
      [hidden]="!isTableDisplayed"
    ></app-entity-table>

    <div
      class="alert alert-danger d-flex justify-content-between"
      *ngIf="showNoPortsAlert"
      data-automation="'noPortsAlert'"
    >
      <span>
        <i [attr.class]="smacsIcons.WARNING"></i>
        <strong data-automation="no-ports-alert-text">
          {{ 'tkey;admin.order_numbers.create_orders.no_numbers_warning.text' | translate }}
        </strong>
      </span>
    </div>
    <div class="card mb-4" data-automation="site-info" [hidden]="showNoPortsAlert">
      <div class="card-header d-flex justify-content-between align-items-center bg-info text-white fs-5 p-3">
        <span class="p-0 m-0">
          <i [attr.class]="smacsIcons.SITE"></i>
          {{ 'tkey;admin.order_numbers.create_orders.create_draft.site_form.header.label' | translate }}
        </span>
      </div>
      <div class="card-body pb-0">
        <smacs-text [fieldId]="'customerOrderId'"></smacs-text>
        <ziro-date [fieldId]="'desiredFocDate'"></ziro-date>
        <smacs-text [fieldId]="'loaAuthorizingPerson'"></smacs-text>
        <smacs-text [fieldId]="'businessName'"></smacs-text>
        <smacs-text [fieldId]="'houseNumber'"></smacs-text>
        <smacs-text [fieldId]="'streetName'"></smacs-text>
        <smacs-text [fieldId]="'addressLine2'"></smacs-text>
        <smacs-text [fieldId]="'city'"></smacs-text>
        <smacs-select [fieldId]="'stateCode'"></smacs-select>
        <smacs-text [fieldId]="'country'"></smacs-text>
        <smacs-text [fieldId]="'zip'"></smacs-text>
        <smacs-text [fieldId]="'billingTelephoneNumber'"></smacs-text>
        <smacs-text [fieldId]="'accountNumber'"></smacs-text>
        <smacs-text [fieldId]="'pinNumber'"></smacs-text>
        <smacs-checkbox [fieldId]="'partialPort'"></smacs-checkbox>
        <smacs-text [fieldId]="'newBillingTelephoneNumber'"></smacs-text>
      </div>
    </div>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
