import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { PortInDraftsResource } from '../port-in-orders/port-in-drafts.resource';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import {
  BandwidthPortInDocuments,
  BandwidthPortInOrder,
  BandwidthPortInOrderNoteAndHistory,
  CurrentUser,
  DocumentType,
  Errors,
  NumberStatus,
  PortInDraftDocument,
  PortInOrderDraft,
  PrePortedNumberStatus,
} from '../../../shared/models/generated/smacsModels';
import { forkJoin, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { DatatableColumn, DatatableRow } from '../../../reporting/datatable/datatable.component';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFieldTypes,
  EntityTableOnFieldChange,
} from '../../../shared/entity-table/entity-table.models';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { catchError, filter, map, tap } from 'rxjs/operators';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { PortInDraftsUploadModalComponent } from './port-in-drafts-upload-modal.component';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../forms/fields/select/smacs-select.component';
import { SmacsCheckboxFieldConfig } from '../../../shared/custom-configs/smacs-checkbox-config/smacs-checkbox-config.component';
import { provinces, states } from './state-province-options';
import { cloneDeep, isEqual } from 'lodash';
import { ZiroPromptModalViewProperties } from '../../../modals/prompt-modal/prompt-modal.component';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { PortInOrdersResource } from '../port-in-orders/port-in-orders.resource';
import moment from 'moment';
import { getProcessingStatusHtml } from '../port-in-orders/processing-status-helper';
import { DateComponentConfig } from '../../../forms/fields/date/date.component';
import { WeekDay } from '@angular/common';
import { ZpmUserPhotoContext } from '../../../shared/contexts/zpm-user-photo.context';
import { MenuItem } from 'primeng/api';
import { GenerateLoaModalComponent } from './generate-loa-modal/generate-loa-modal.component';

interface PortInDraftEditDatatableRow extends DatatableRow {
  number: string;
  upn: string;
  dialPlanGroup: string;
  isConfiguredOnSbc: string;
}

interface PortInOrderDetailsView extends PortInOrderDraft {
  processingStatus: string;
  actualFocDate: string;
  orderCreatedDate: string;
  lastModifiedDate: string;
  lastModifiedBy: string;
  losingCarrierIsWireless: string;
  requestedFocDate: string;
  errors: Errors;
}

export const groupedProvinces = provinces.map((option: SmacsSelectOption) => {
  return { ...option, group: 'Canada' };
});
export const groupedStates = states.map((option: SmacsSelectOption) => {
  return { ...option, group: 'United States' };
});

@Component({
  selector: 'ziro-order-view-and-edit',
  templateUrl: './order-view-and-edit.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [PortInDraftsResource, PortInOrdersResource, ZpmUserPhotoContext],
})
export class OrderViewAndEditComponent
  extends SmacsFormAbstractDirective<PortInOrderDraft | PortInOrderDetailsView>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() companyId: string;
  @Input() isUserS8Support: boolean;
  @Input() currentUser: CurrentUser;

  isDraftOrder = false;
  loaIsPresent = false;
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  isLoading = true;
  formConfig: SmacsFormConfig;
  phoneNumbersTableRows = [] as PortInDraftEditDatatableRow[];
  phoneNumbersTableCols: DatatableColumn<PortInDraftEditDatatableRow>[] = [
    {
      name: 'number',
      label: 'tkey;admin.order_numbers.order_view_and_edit.phone_numbers.table.phone_number.label',
    },
    {
      name: 'upn',
      label: 'tkey;admin.order_numbers.order_view_and_edit.phone_numbers.table.user.label',
    },
    {
      name: 'dialPlanGroup',
      label: 'tkey;admin.order_numbers.order_view_and_edit.phone_numbers.table.dial_plan.label',
    },
    {
      name: 'isConfiguredOnSbc',
      label: 'tkey;admin.order_numbers.order_view_and_edit.phone_numbers.table.sbc_config.label',
    },
  ];

  documentsTable: EntityTable = {
    columns: [
      {
        columnId: 'documentName',
        cssColumnSize: 'col-sm-7',
        label: 'tkey;admin.order_numbers.port_in_drafts_edit.documents.table.document_name.label',
      },
      {
        columnId: 'documentType',
        cssColumnSize: 'col-sm-4',
        label: 'tkey;admin.order_numbers.port_in_drafts_edit.documents.table.document_type.label',
      },
    ],
    hasActions: true,
  };
  documentsTableRows: EntityTableContentRow[] = [];
  userFilterOptions = ['Assigned', 'Not Assigned'];
  dialPlanGroupFilterOptions: string[] = [];
  sbcConfiguredFilterOptions = ['Configured on SBC', 'Not Configured on SBC'];
  errors: Errors;
  notes: BandwidthPortInOrderNoteAndHistory[] = [];
  initialDraft: PortInOrderDraft;
  tableDataAutomation = 'port-in-drafts-phone-numbers-datatable';
  generateLoaButtonDisabledTooltip = '';

  private _selectedDraftOrOrder: PortInOrderDetailsView;
  private _processingStatusHtml: string;
  private _processingStatus: string;
  private _zipValidationParams = '';
  private _draftOrderId: number;
  private _orderId: string;
  private _documents: PortInDraftDocument[] | BandwidthPortInDocuments[] = [];
  private _saveButtonSubject = new Subject<boolean>();
  private _submitButtonSubject = new Subject<boolean>();
  private _isSaveClicked = false;
  private _isSubmitClicked = false;
  private _subscriptions = new Subscription();
  private prePortedNumberStatus: PrePortedNumberStatus;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _breadcrumbsService: BreadcrumbsService,
    private _portInDraftsResource: PortInDraftsResource,
    private _router: Router,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    private _telephoneNumberFilter: TelephoneNumberFilter,
    private _bottomNavService: BottomNavService,
    private _smacsModalService: SmacsModalService,
    private _toastService: ToastService,
    private _portInOrdersResource: PortInOrdersResource,
    private userPhotoContext: ZpmUserPhotoContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.isDraftOrder = !!this._route.snapshot.params['id'];
    if (this.isDraftOrder) {
      this._draftOrderId = this._route.snapshot.params['id'];
    } else {
      this._orderId = this._route.snapshot.params['orderId'];
    }

    this._initForm();
    this._initialize();

    const smacsFormsUpdateSub = this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<PortInOrderDraft>) => {
      this._setSaveButtonDisabledState();
      this._setSubmitButtonDisabledState();
      this._setGenerateLoaButtonDisabledState();

      if (data.new && data.old && !isEqual(data.new.stateCode, data.old?.stateCode)) {
        const provinceValues = provinces.map((option: SmacsSelectOption) => option.value);

        if (provinceValues.includes(data.new.stateCode)) {
          this.entitySource.next({
            ...this.formData,
            country: 'Canada',
          });
        } else {
          this.entitySource.next({
            ...this.formData,
            country: 'United States',
          });
        }
      }
    });
    this._subscriptions.add(smacsFormsUpdateSub);

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() =>
      this._bottomNavService.setBottomNavValidationError(!this.isFormValid())
    );
    this._subscriptions.add(formSubmittedSub);

    this._saveButtonSubject.subscribe((clicked) => {
      this._isSaveClicked = true;
      this._validateAndSubmitSource.next(clicked);
    });
    this._submitButtonSubject.subscribe((clicked) => {
      this._isSubmitClicked = true;
      this._validateAndSubmitSource.next(clicked);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (this.isLoading === false && changes.isUserS8Support?.currentValue !== changes.isUserS8Support?.previousValue) {
      // this is for the workbench
      this._initBottomNav();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  matchesSecondaryDataFn = (searchValue: string, row: PortInDraftEditDatatableRow): boolean => {
    if (!searchValue) return true;
    const formattedSearchValue = this.numericizePhoneNumber(searchValue);
    const formattedRowNumber = this.numericizePhoneNumber(row.number);
    return formattedRowNumber.includes(formattedSearchValue);
  };

  onUpnFilterChange(filterByValues: string[], row: PortInDraftEditDatatableRow): boolean {
    return (
      !filterByValues.length ||
      (filterByValues.includes('Assigned') && !row.upn.includes('Not Assigned')) ||
      (filterByValues.includes('Not Assigned') && row.upn.includes('Not Assigned'))
    );
  }

  onDialPlanFilterChange(filterByValues: string[], row: PortInDraftEditDatatableRow): boolean {
    const element = document.createElement('div');
    element.innerHTML = row.dialPlanGroup;
    const text = element.textContent.trim() || element.innerText.trim() || '';
    return !filterByValues.length || filterByValues.includes(String(text));
  }

  onSbcConfiguredFilterChange(filterByValues: string[], row: PortInDraftEditDatatableRow): boolean {
    const element = document.createElement('div');
    element.innerHTML = row.isConfiguredOnSbc;
    const text = element.textContent.trim() || element.innerText.trim() || '';
    return !filterByValues.length || filterByValues.includes(String(text));
  }

  onFieldChange(entityTableOnFieldChange: EntityTableOnFieldChange) {
    if (this.isDraftOrder) {
      this._portInDraftsResource
        .downloadDocument(this._draftOrderId || Number(this._orderId), entityTableOnFieldChange.content['documentId'])
        .subscribe(() => {
          this._toastService.push(
            ToastTypes.SUCCESS,
            SmacsIcons.DOWNLOAD,
            'tkey;global.toast.download.title',
            'tkey;admin.order_numbers.port_in_drafts_edit.documents.export.message',
            { documentName: entityTableOnFieldChange.content['documentName'] }
          );
        });
    } else {
      this._portInOrdersResource
        .downloadDocument(this._orderId, entityTableOnFieldChange.content['documentId'])
        .subscribe(() => {
          this._toastService.push(
            ToastTypes.SUCCESS,
            this.smacsIcons.DOWNLOAD,
            'tkey;admin.order_numbers.port_in_orders_details.file.toast.title',
            this._translateService.instant('tkey;admin.order_numbers.port_in_orders_details.file.toast.content', {
              fileName: entityTableOnFieldChange.content['documentName'],
            })
          );
        });
    }
  }

  onGenerateLoaClick() {
    const options = {
      size: 'xl',
      bodyClass: GenerateLoaModalComponent,
      modalViewProperties: {
        title: 'tkey;admin.order_numbers.order_view_and_edit.documents.generate_loa.modal.title',
        message: 'tkey;admin.order_numbers.order_view_and_edit.documents.generate_loa.modal.body',
        submitButtonLabel: 'tkey;admin.order_numbers.order_view_and_edit.documents.generate_loa.modal.submit.label',
        modalBodyClass: 'modal-body--full-height',
        icon: this.smacsIcons.PDF,
        draftId: this._draftOrderId,
        authorizingPersonDisplayName: this.formData.loaAuthorizingPerson,
        currentUserDisplayName: this.currentUser.displayName,
      },
    };
    this._smacsModalService
      .openDetailedModal(() => options.modalViewProperties, options)
      .pipe(
        filter((data) => !!data),
        switchMap(() => this._getDraftOrOrderDocuments())
      )
      .subscribe(() => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.PDF,
          'tkey;admin.order_numbers.order_view_and_edit.documents.generate_loa.modal.toast.title',
          'tkey;admin.order_numbers.order_view_and_edit.documents.generate_loa.modal.toast.message'
        );
      });
  }

  onNewDocumentClick() {
    const options = {
      size: 'lg',
      bodyClass: PortInDraftsUploadModalComponent,
      modalViewProperties: {
        title: 'tkey;admin.order_numbers.port_in_drafts_edit.documents.modal.title',
        message: 'tkey;admin.order_numbers.port_in_drafts_edit.documents.modal.message',
        draft: this._selectedDraftOrOrder,
        modalBodyClass: '',
        submitButtonLabel: 'tkey;admin.order_numbers.port_in_drafts_edit.documents.modal.upload.label',
        icon: this.smacsIcons.ADD_DOCUMENT,
      },
    };
    this._smacsModalService
      .openDetailedModal(() => options.modalViewProperties, options)
      .subscribe({
        next: (data) => {
          if (data) {
            this._getDraftOrOrderDocuments().subscribe(() => {
              this._toastService.push(
                ToastTypes.SUCCESS,
                SmacsIcons.IMPORT,
                'tkey;admin.order_numbers.port_in_drafts_edit.documents.modal.toast.title',
                'tkey;admin.order_numbers.port_in_drafts_edit.documents.modal.toast.message'
              );
            });
          }
        },
      });
  }

  protected submit(): Observable<void> {
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
      })
    );

    this._setCancelAndDeleteButtonDisabledState(true);

    const updatedDraft = {
      ...this.entity,
      customerOrderId: `${this.companyId} ${this.entity.customerOrderId}`,
      newBillingTelephoneNumber: this.entity.partialPort ? this.entity.newBillingTelephoneNumber : null,
    };

    if (this._isSaveClicked) {
      this._isSaveClicked = false;
      this._setSaveOrSubmitPendingState('save');
      return this._saveDraft(updatedDraft);
    } else if (this._isSubmitClicked) {
      this._isSubmitClicked = false;
      this._setSaveOrSubmitPendingState('submit');
      return this._submitDraft(updatedDraft);
    }
  }

  private numericizePhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }
    if (phoneNumber.startsWith('1')) {
      phoneNumber = phoneNumber.slice(1, phoneNumber.length);
    }
    return phoneNumber
      .replaceAll('+1', '')
      .replaceAll('+', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .replaceAll(' ', '')
      .trim();
  }

  private _saveDraft(draft: PortInOrderDraft): Observable<void> {
    return this._portInDraftsResource.put(draft).pipe(
      tap(() => {
        this.smacsFormStateService.setIsFormDirty(false);
        this._setSaveOrSubmitPendingState(false);
        this._setSaveButtonDisabledState();
        this._setSubmitButtonDisabledState();
        this._setGenerateLoaButtonDisabledState();
        this._setCancelAndDeleteButtonDisabledState(false);
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.HASH,
          'tkey;admin.order_numbers.port_in_drafts_edit.save_successful.title',
          'tkey;admin.order_numbers.port_in_drafts_edit.save_successful.message'
        );
      }),
      catchError((err) => {
        this._setSaveOrSubmitPendingState(false);
        this._setCancelAndDeleteButtonDisabledState(false);
        throw err;
      })
    );
  }

  private _submitDraft(draft: PortInOrderDraft): Observable<void> {
    const hasInvoiceOrCsr = this._documents.some(
      (doc) => doc.documentType === DocumentType.CSR || doc.documentType === DocumentType.INVOICE
    );
    if (!hasInvoiceOrCsr) {
      return this._openCsrOrInvoiceMissingModal().pipe(
        switchMap((confirmed) => {
          if (confirmed) {
            return this._submitToBandwidth(draft);
          } else {
            this._setSaveOrSubmitPendingState(false);
            this._setCancelAndDeleteButtonDisabledState(false);
            return of(null);
          }
        })
      );
    } else {
      return this._submitToBandwidth(draft);
    }
  }

  private _submitToBandwidth(draft: PortInOrderDraft): Observable<void> {
    return this._portInDraftsResource.submitDraft(draft.draftId).pipe(
      map(() => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.HASH,
          'tkey;admin.order_numbers.port_in_drafts_edit.submission_successful.title',
          'tkey;admin.order_numbers.port_in_drafts_edit.submission_successful.message'
        );
        this._router.navigate(['../..', 'port-in-orders'], { relativeTo: this._route });
      }),
      catchError((err) => {
        this._setSaveOrSubmitPendingState(false);
        this._setCancelAndDeleteButtonDisabledState(false);
        throw err;
      })
    );
  }

  private _openCsrOrInvoiceMissingModal(): Observable<boolean> {
    const modalOptions: ZiroPromptModalViewProperties = {
      title: 'tkey;admin.order_numbers.port_in_drafts_edit.no_documents_modal.title',
      promptBody: 'tkey;admin.order_numbers.port_in_drafts_edit.no_documents_modal.message',
      icon: SmacsIcons.WARNING,
      iconClass: 'text-warning',
      displayCloseButton: true,
      buttons: [
        {
          dataAutomation: 'confirmation-modal-cancel-button',
          label: 'tkey;global.button.cancel.text',
          buttonClass: ButtonStyles.DEFAULT,
        },
        {
          dataAutomation: 'confirmation-modal-confirm-button',
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.no_documents_modal.continue',
          buttonIcon: SmacsIcons.OK,
          buttonClass: ButtonStyles.PRIMARY,
          cb: () => {
            return of(true);
          },
        },
      ],
    };

    return this._smacsModalService.openPromptModal(() => modalOptions, { modalViewProperties: modalOptions });
  }

  private _initForm() {
    this.formConfig = {
      fields: {
        customerOrderId: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.customer_order_id.label',
          dataAutomation: 'customerOrderId',
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.TEXT,
            htmlInputAddOn: { appendedContent: null, prependedContent: this.isDraftOrder ? this.companyId : '' },
            readonly: !this.isDraftOrder,
          }),
          required: this.isDraftOrder,
          helpText: this.isDraftOrder
            ? 'tkey;admin.order_numbers.port_in_drafts_edit.form.customer_order_id.helptext'
            : 'tkey;admin.order_numbers.view_orders.customer_order_id.helptext',
          validation: [
            {
              validator: (val: string) => {
                return !val || val.length <= 40 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '40',
                },
              },
            },
          ],
        },
        loaAuthorizingPerson: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.authorizing_person.label',
          dataAutomation: 'loaAuthorizingPerson',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          required: this.isDraftOrder,
          helpText: this.isDraftOrder
            ? 'tkey;admin.order_numbers.port_in_drafts_edit.form.authorizing_person.helptext'
            : 'tkey;admin.order_numbers.view_orders.authorizing_person.helptext',
          validation: [
            {
              validator: (val: string) => {
                return !val || val.length <= 15 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '15',
                },
              },
            },
          ],
        },
        businessName: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.business_name.label',
          dataAutomation: 'businessName',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          required: this.isDraftOrder,
          validation: [
            {
              validator: (val: string) => {
                return !val || val.length <= 50 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '50',
                },
              },
            },
          ],
        },
        houseNumber: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.street_number.label',
          dataAutomation: 'houseNumber',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          required: this.isDraftOrder,
        },
        streetName: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.street_name.label',
          dataAutomation: 'streetName',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          required: this.isDraftOrder,
        },
        addressLine2: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.address_line_2.label',
          dataAutomation: 'addressLine2',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          required: false,
        },
        city: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.city.label',
          dataAutomation: 'city',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          required: this.isDraftOrder,
        },
        stateCode: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.state.label',
          dataAutomation: 'stateCode',
          componentConfig: this.isDraftOrder
            ? new SmacsSelectConfig({
                options: [...groupedStates, ...groupedProvinces],
                bindValue: 'value',
              })
            : new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: true }),
          required: this.isDraftOrder,
          disabled: () => !this.isDraftOrder,
        },
        country: {
          label: '',
          dataAutomation: 'country',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          hidden: () => true,
        },
        zip: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.zip.label',
          dataAutomation: 'zip',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          required: this.isDraftOrder,
          validation: this.isDraftOrder
            ? [
                {
                  validator: (val: string, country: string) => {
                    if (country === 'Canada') {
                      const postalRegex = /^([A-Za-z][0-9][A-Za-z])[\s-]?([0-9][A-Za-z][0-9])$/;
                      this._zipValidationParams = 'Postal Code';
                      return postalRegex.test(val)
                        ? SmacsFormsValidationState.VALID
                        : SmacsFormsValidationState.INVALID;
                    } else {
                      const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
                      this._zipValidationParams = 'ZIP';
                      return zipRegex.test(val) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
                    }
                  },
                  message: () => {
                    return {
                      content: 'tkey;admin.order_numbers.port_in_drafts_edit.form.zip.validation.message',
                      params: {
                        type: this._zipValidationParams,
                      },
                    };
                  },
                  injectValuesFromFields: ['country'],
                },
              ]
            : [],
        },
        billingTelephoneNumber: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.billing_telephone_number.label',
          dataAutomation: 'billingTelephoneNumber',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          required: this.isDraftOrder,
          helpText: this.isDraftOrder
            ? 'tkey;admin.order_numbers.port_in_drafts_edit.form.billing_telephone_number.helptext'
            : 'tkey;admin.order_numbers.view_orders.billing_telephone_number.helptext',
          validation: [
            {
              validator: (val: string) => {
                return !val || (val.startsWith('+1') && val.length === 12)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: 'tkey;validators.global.e164.invalid.error',
            },
          ],
        },
        accountNumber: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.account_number.label',
          dataAutomation: 'account-number',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          validation: [
            {
              validator: (val: string) => {
                return !val || val.length <= 15 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '15',
                },
              },
            },
          ],
        },
        pinNumber: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.account_pin.label',
          dataAutomation: 'account-pin',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          validation: [
            {
              validator: (val: string) => {
                return !val || val.length <= 20 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '20',
                },
              },
            },
          ],
        },
        partialPort: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.partial_port.label',
          dataAutomation: 'partialPort',
          componentConfig: new SmacsCheckboxFieldConfig(),
          helpText: this.isDraftOrder
            ? 'tkey;admin.order_numbers.port_in_drafts_edit.form.partial_port.helptext'
            : 'tkey;admin.order_numbers.view_orders.partial_port.helptext',
          disabled: () => !this.isDraftOrder,
        },
        newBillingTelephoneNumber: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.replacement_billing_telephone_number.label',
          dataAutomation: 'newBillingTelephoneNumber',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: !this.isDraftOrder }),
          required: false,
          helpText: this.isDraftOrder
            ? 'tkey;admin.order_numbers.port_in_drafts_edit.form.replacement_billing_telephone_number.helptext'
            : 'tkey;admin.order_numbers.view_orders.replacement_billing_telephone_number.helptext',
          hidden: () => !this.formData?.partialPort,
          validation: [
            {
              validator: (val: string) => {
                return !val || val.toLowerCase() === 'not provided' || (val.startsWith('+1') && val.length === 12)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: 'tkey;validators.global.e164.invalid.error',
            },
          ],
        },
        rateCenterName: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.rate_center_name.label',
          dataAutomation: 'rateCenterName',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: true }),
        },
        losingCarrierName: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.losing_carrier_name.label',
          dataAutomation: 'losingCarrierName',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: true }),
        },
      },
    };
    if (this.isDraftOrder) {
      this.formConfig.fields['desiredFocDate'] = {
        label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.desired_foc_date.label',
        dataAutomation: 'desiredFocDate',
        componentConfig: new DateComponentConfig({
          disabledDays: [WeekDay.Saturday, WeekDay.Sunday],
          allowToday: false,
          allowBeforeToday: false,
        }),
        required: true,
        helpText: 'tkey;admin.order_numbers.port_in_drafts_edit.form.desired_foc_date.helptext',
      };
    }
    if (!this.isDraftOrder) {
      this.formConfig.fields['processingStatus'] = {
        label: 'tkey;admin.order_numbers.port_in_orders.datatable.processing_status.label',
        dataAutomation: 'processingStatus',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: true }),
      };
      this.formConfig.fields['actualFocDate'] = {
        label: 'tkey;admin.order_numbers.port_in_orders.datatable.actual_foc_date.label',
        dataAutomation: 'actualFocDate',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: true }),
        helpText: 'tkey;admin.order_numbers.port_in_orders.datatable.actual_foc_date.helptext',
      };
      this.formConfig.fields['orderCreatedDate'] = {
        label: 'tkey;admin.order_numbers.port_in_orders.datatable.order_created_date.label',
        dataAutomation: 'orderCreatedDate',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: true }),
      };
      this.formConfig.fields['lastModifiedDate'] = {
        label: 'tkey;admin.order_numbers.port_in_orders.datatable.last_modified_date.label',
        dataAutomation: 'lastModifiedDate',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: true }),
      };
      this.formConfig.fields['lastModifiedBy'] = {
        label: 'tkey;admin.order_numbers.port_in_orders.datatable.last_modified_by.label',
        dataAutomation: 'lastModifiedBy',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: true }),
      };
      this.formConfig.fields['losingCarrierIsWireless'] = {
        label: 'tkey;admin.order_numbers.port_in_orders.datatable.losing_carrier_is_wireless.label',
        dataAutomation: 'losingCarrierIsWireless',
        componentConfig: new SmacsCheckboxFieldConfig(),
        helpText: 'tkey;admin.order_numbers.port_in_orders.datatable.losing_carrier_is_wireless.helptext',
        disabled: () => true,
      };
      this.formConfig.fields['requestedFocDate'] = {
        label: 'tkey;admin.order_numbers.view_orders.desired_foc_date.label',
        dataAutomation: 'requestedFocDate',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, readonly: true }),
        helpText: 'tkey;admin.order_numbers.view_orders.desired_foc_date.helptext',
      };
    }
  }

  private _initialize() {
    forkJoin([this.isDraftOrder ? this._getDraft() : this._getOrder(), this._getDraftOrOrderDocuments()]).subscribe(
      () => {
        this._initBreadcrumbs();
        this._initBottomNav();
        this.isLoading = false;
      }
    );
  }

  private _getDraft(): Observable<PrePortedNumberStatus> {
    return this._portInDraftsResource.getById(this._draftOrderId || Number(this._orderId)).pipe(
      switchMap((portInDraft: PortInOrderDetailsView) => {
        this._selectedDraftOrOrder = portInDraft;
        this.initialDraft = cloneDeep(portInDraft);
        this.entitySource.next({
          ...portInDraft,
          customerOrderId: portInDraft.customerOrderId.replace(`${this.companyId} `, ''),
        });
        return this._mapPhoneNumberTableRows();
      })
    );
  }

  private _getOrder() {
    return this._portInOrdersResource.getOrder(this._orderId).pipe(
      switchMap((order: BandwidthPortInOrder) => {
        const notProvidedText = this._translateService.instant(
          'tkey;admin.order_numbers.port_in_orders.datatable.not_provided.text'
        );
        this._processingStatus = order.processingStatus;
        this._selectedDraftOrOrder = {
          addressLine2: order.subscriber?.serviceAddress.addressLine2 || notProvidedText,
          billingTelephoneNumber: order.billingTelephoneNumber,
          businessName: order.subscriber?.businessName,
          city: order.subscriber?.serviceAddress.city,
          country: order.subscriber?.serviceAddress.country,
          customerOrderId: order.customerOrderId || notProvidedText,
          desiredFocDate: null,
          draftId: Number(order.id),
          houseNumber: order.subscriber?.serviceAddress.houseNumber,
          loaAuthorizingPerson: order.loaAuthorizingPerson,
          losingCarrierName: order.losingCarrierName || notProvidedText,
          newBillingTelephoneNumber: order.newBillingTelephoneNumber || notProvidedText,
          partialPort: order.partialPort === 'true',
          phoneNumbers: order.listOfPhoneNumbers,
          rateCenterName: order.vendorName || notProvidedText,
          stateCode: order.subscriber?.serviceAddress.stateCode,
          streetName: order.subscriber?.serviceAddress.streetName,
          zip: order.subscriber?.serviceAddress.zip,
          // Port-in order specific fields
          processingStatus: getProcessingStatusHtml(order.processingStatus),
          actualFocDate: order.actualFocDate
            ? moment(order.actualFocDate).format('YYYY-MM-DD HH:mm:ss')
            : this._translateService.instant(
                'tkey;admin.order_numbers.port_in_orders.datatable.actual_foc_date.placeholder'
              ),
          orderCreatedDate: order.orderCreateDate
            ? moment(order.orderCreateDate).format('YYYY-MM-DD HH:mm:ss')
            : notProvidedText,
          lastModifiedDate: order.lastModifiedDate
            ? moment(order.lastModifiedDate).format('YYYY-MM-DD HH:mm:ss')
            : notProvidedText,

          lastModifiedBy: order.lastModifiedBy || notProvidedText,
          losingCarrierIsWireless: order.losingCarrierIsWireless,
          requestedFocDate: order.requestedFocDate
            ? moment(order.requestedFocDate).format('YYYY-MM-DD HH:mm:ss')
            : notProvidedText,
          errors: order.errors,
          accountNumber: order.accountNumber || notProvidedText,
          pinNumber: order.pinNumber || notProvidedText,
          isTollFree: false,
        };
        if (order.errors) {
          this.errors = order.errors;
        }
        this.entitySource.next(this._selectedDraftOrOrder);
        if ('processingStatus' in this._selectedDraftOrOrder) {
          this._processingStatusHtml = this._selectedDraftOrOrder.processingStatus;
        }

        return forkJoin([this._mapPhoneNumberTableRows(), this._getAndSetNotes(order.id)]);
      }),
      map(() => {
        this._setProcessingStatusComponent();
      })
    );
  }

  private _getAndSetNotes(orderId: string): Observable<BandwidthPortInOrderNoteAndHistory[]> {
    return this._portInOrdersResource.getNotes(orderId).pipe(
      tap((notes: BandwidthPortInOrderNoteAndHistory[]) => {
        this.notes = notes.reverse();
      })
    );
  }

  private _setProcessingStatusComponent() {
    this.fieldComponents
      .find((field) => field.fieldId === 'processingStatus')
      .applyComponentConfig(
        new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          readonly: true,
          htmlInputAddOn: {
            appendedContent: '',
            prependedContent: '',
            htmlPill: this._processingStatusHtml,
          },
        })
      );
  }

  private _getDraftOrOrderDocuments(): Observable<PortInDraftDocument[] | BandwidthPortInDocuments[]> {
    const getDocs$: Observable<PortInDraftDocument[] | BandwidthPortInDocuments[]> = this.isDraftOrder
      ? this._portInDraftsResource.getDocuments(this._draftOrderId || Number(this._orderId))
      : this._portInOrdersResource.getOrderDocuments(this._orderId);
    return getDocs$.pipe(
      tap((data: PortInDraftDocument[] | BandwidthPortInDocuments[]) => {
        this._documents = data;
        this.loaIsPresent = data.some((doc) => doc.documentType === DocumentType.LOA);
        this._setSaveButtonDisabledState();
        this._setSubmitButtonDisabledState();
        this._mapDocumentTableRows();
      })
    );
  }

  private _mapPhoneNumberTableRows() {
    return this._portInOrdersResource
      .getOrderAndDraftNumberInventories(this.isDraftOrder ? this._draftOrderId.toString() : this._orderId)
      .pipe(
        tap((prePortedNumberStatus: PrePortedNumberStatus) => {
          this.prePortedNumberStatus = prePortedNumberStatus;
          const upns = prePortedNumberStatus.numbers
            .map((numInventory) => numInventory.userPrincipalName)
            .filter((upn) => !!upn);

          this.userPhotoContext.initUserPhotos(upns);

          this.phoneNumbersTableRows = prePortedNumberStatus.numbers.map((numberInventory: NumberStatus) => {
            let upnMarkup: string;
            if (numberInventory.userPrincipalName) {
              upnMarkup = `
            <div class="d-inline-flex align-items-center">
             <div class="me-1 thumbnail-container">
               <i class="icon-user-circle font-size-32"></i>
             </div>
             <span>${numberInventory.userPrincipalName}</span>
            </div>
        `;
            } else {
              upnMarkup = `
           <em class="d-block">${this._translateService.instant(
             'tkey;admin.order_numbers.view_and_edit.not_assigned'
           )}</em>`;
            }

            this.dialPlanGroupFilterOptions = Array.from(
              new Set(
                prePortedNumberStatus.numbers
                  .filter((numberInventory) => !!numberInventory.dialPlanGroup)
                  .map((numberInventory) => numberInventory.dialPlanGroup.name)
                  .concat('Not Assigned')
              )
            );

            return {
              number: `<strong>${this._telephoneNumberFilter.transform(numberInventory.number)}</strong>`,
              upn: upnMarkup,
              dialPlanGroup: numberInventory.dialPlanGroup
                ? numberInventory.dialPlanGroup.name
                : '<em>Not Assigned</em>',
              isConfiguredOnSbc: numberInventory.isConfiguredOnSbc
                ? `<span class="alert alert-inline alert-info"><i class="${this.smacsIcons.CHECK_CIRCLE} me-1 text-success"></i>Configured on SBC</span>`
                : `<span class="alert alert-inline alert-info"><i class="${this.smacsIcons.EXCLAMATION_CIRCLE} me-1 text-danger"></i>Not Configured on SBC</span>`,
            };
          });

          const userPhotoCtxSub = this.userPhotoContext.state$.subscribe(() => {
            const userPhotos = this.userPhotoContext.getUserPhotos();

            this.phoneNumbersTableRows = this.phoneNumbersTableRows.map((row) => {
              const userPhoto = userPhotos.find((photo) => row.upn.includes(photo.userPrincipalName));
              if (userPhoto?.photoBase64) {
                return {
                  ...row,
                  upn: row.upn.replace(
                    `<i class="icon-user-circle font-size-32"></i>`,
                    `<img src="data:image/png;base64,${userPhoto.photoBase64}"
                alt="${userPhoto.displayName}"
                class="user-profile-picture h-100 w-100">`
                  ),
                };
              }
              return row;
            });
          });
          this._subscriptions.add(userPhotoCtxSub);
        })
      );
  }

  private _mapDocumentTableRows() {
    this.documentsTableRows = this._documents.map((document: PortInDraftDocument | BandwidthPortInDocuments) => {
      const documentId = this.isDraftOrder
        ? (document as PortInDraftDocument).documentId
        : (document as BandwidthPortInDocuments).fileName;

      return {
        fields: {
          documentName: {
            type: EntityTableFieldTypes.BUTTON,
            buttonStyle: ButtonStyles.LINK,
            label: document.documentName,
          },
        },
        content: {
          documentId: documentId,
          documentName: document.documentName,
          documentType: document.documentType,
        },
        cssClass: 'animated fast flipInX',
        actions: this.isDraftOrder
          ? [
              {
                label: 'tkey;global.button.delete.text',
                buttonStyle: ButtonStyles.OUTLINE_DANGER,
                dataAutomation: 'draft-document-delete',
                icon: SmacsIcons.DELETE,
                onClick: () => this._deleteDocumentClicked(document as PortInDraftDocument),
              },
            ]
          : [],
      };
    });
  }

  private _deleteDocumentClicked(document: PortInDraftDocument) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        title: this._translateService.instant(
          'tkey;admin.order_numbers.port_in_drafts_edit.documents.table.modal.title'
        ),
        promptBody: this._translateService.instant(
          'tkey;admin.order_numbers.port_in_drafts_edit.documents.table.modal.content',
          {
            documentName: document.documentName,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteDocument(document);
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteDocument(
    document: PortInDraftDocument
  ): Observable<PortInDraftDocument[] | BandwidthPortInDocuments[]> {
    return this._portInDraftsResource
      .deleteDocument(this._selectedDraftOrOrder.draftId, document.documentId)
      .pipe(
        switchMap(() => {
          return this._getDraftOrOrderDocuments();
        })
      )
      .pipe(
        tap(() => {
          this._toastService.pushDeleteToast(
            'tkey;admin.order_numbers.port_in_drafts_edit.documents.table.delete.type',
            document.documentName
          );
        })
      );
  }

  private _initBreadcrumbs() {
    const crumb: MenuItem = {
      label: 'tkey;admin.order_numbers.port_in_orders.title',
      url: `/admin/order-numbers/port-in-orders`,
      routerLink: true,
    };
    this._breadcrumbsService.updateBreadcrumbs([crumb, { label: this._selectedDraftOrOrder.customerOrderId }]);
  }

  private _initBottomNav() {
    const buttons: BottomNavButton[] = [];
    buttons.push({
      id: 'back-button',
      dataAutomation: 'back-button',
      label: this.isDraftOrder ? 'tkey;global.button.cancel.text' : 'tkey;global.button.back.text',
      buttonClass: ButtonStyles.DEFAULT,
      icon: this.isDraftOrder ? SmacsIcons.NONE : SmacsIcons.BACK,
      cb: () => {
        this._router.navigate(
          window.location.hash.includes('?redirect=port-in-number-status')
            ? ['../..', 'port-in-number-status']
            : ['../..', 'port-in-orders'],
          { relativeTo: this._route }
        );
      },
    });

    if (this.isDraftOrder) {
      buttons.push({
        id: 'delete-draft',
        dataAutomation: 'delete-draft',
        label: 'tkey;global.button.delete.text',
        buttonClass: ButtonStyles.DANGER,
        icon: SmacsIcons.DELETE,
        cb: () => {
          this._confirmDraftDeletion();
        },
      });
      buttons.push({
        id: 'save-draft',
        dataAutomation: 'save-draft',
        label: 'tkey;global.button.save.text',
        buttonClass: ButtonStyles.PRIMARY,
        icon: SmacsIcons.OK,
        type: ButtonTypes.SUBMIT,
        submitSubject: this._saveButtonSubject,
      });
      buttons.push({
        id: 'submit-draft',
        dataAutomation: 'submit-draft',
        label: 'tkey;admin.order_numbers.port_in_drafts_edit.submit_draft.button.support.text',
        buttonClass: ButtonStyles.PRIMARY,
        icon: SmacsIcons.OK,
        type: ButtonTypes.SUBMIT,
        submitSubject: this._submitButtonSubject,
      });
    }

    const isOrderInProgress = !['COMPLETE', 'CANCELLED', 'REQUESTED_CANCEL', 'EXCEPTION'].includes(
      this._processingStatus
    );

    if (!this.isDraftOrder && isOrderInProgress) {
      buttons.push({
        id: 'bottom-nav-cancel-button',
        label: 'tkey;admin.order_numbers.port_in_orders.cancel_order.button',
        buttonClass: ButtonStyles.DANGER,
        icon: SmacsIcons.REMOVE,
        dataAutomation: 'bottom-nav-cancel-button',
        cb: () => {
          this._openCancellationModal();
        },
      });
    }

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttons));

    this._setSaveButtonDisabledState();
    this._setSubmitButtonDisabledState();
  }

  private _confirmDraftDeletion() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        title: this._translateService.instant('tkey;admin.order_numbers.port_in_drafts_edit.delete_modal.title'),
        promptBody: this._translateService.instant(
          'tkey;admin.order_numbers.port_in_drafts_edit.delete_modal.message',
          {
            orderId: this.entity.customerOrderId,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteDraft();
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteDraft(): Observable<void> {
    return this._portInDraftsResource.deleteDraft(this.entity.draftId).pipe(
      map(() => {
        this.smacsFormStateService.setIsFormDirty(false);
        this._toastService.pushDeleteToast(
          'tkey;admin.order_numbers.port_in_drafts.title',
          this.entity.customerOrderId
        );
        this._router.navigate(['/admin/order-numbers/port-in-orders']);
      })
    );
  }

  private _setSaveOrSubmitPendingState(pendingButton: 'save' | 'submit' | false) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'save-draft',
        state: {
          pending: pendingButton === 'save',
          buttonDisableState: { disabled: !!pendingButton, tooltipKey: '' },
          tooltipVisible: false,
        },
      })
    );
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'submit-draft',
        state: {
          pending: pendingButton === 'submit',
          buttonDisableState: { disabled: !!pendingButton, tooltipKey: '' },
          tooltipVisible: false,
        },
      })
    );
  }

  private _setSaveButtonDisabledState() {
    const hasLoa = this._documents.some((doc) => doc.documentType === DocumentType.LOA);
    const isFormDirty = this.smacsFormStateService.getIsFormDirty();
    const isDisabled = hasLoa && isFormDirty;

    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'save-draft',
        state: {
          buttonDisableState: {
            disabled: isDisabled,
            tooltipKey: isDisabled
              ? 'tkey;admin.order_numbers.port_in_drafts_edit.save_draft.button.disabled.tooltip'
              : '',
          },
          tooltipVisible: isDisabled,
        },
      })
    );
  }

  private _setSubmitButtonDisabledState() {
    const hasLoa = this._documents.some((doc) => doc.documentType === DocumentType.LOA);
    const hasInvoiceOrCsr = this._documents.some(
      (doc) => doc.documentType === DocumentType.INVOICE || doc.documentType === DocumentType.CSR
    );
    const isFormDirty = this.smacsFormStateService.getIsFormDirty();

    let tooltip = '';
    if (isFormDirty) {
      tooltip = 'tkey;admin.order_numbers.port_in_drafts_edit.submit_draft.button.disabled.tooltip.unsaved_changes';
    } else if (this.isUserS8Support && !hasLoa) {
      tooltip =
        'tkey;admin.order_numbers.port_in_drafts_edit.submit_draft.button.disabled.tooltip.missing_documents.s8support';
    } else if (!this.isUserS8Support && (!hasLoa || !hasInvoiceOrCsr)) {
      tooltip = 'tkey;admin.order_numbers.port_in_drafts_edit.submit_draft.button.disabled.tooltip.missing_documents';
    }

    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'submit-draft',
        state: {
          buttonDisableState: {
            disabled: !!tooltip,
            tooltipKey: tooltip,
          },
          tooltipVisible: !!tooltip,
        },
      })
    );
  }

  private _setGenerateLoaButtonDisabledState() {
    if (this.entity?.isTollFree) {
      this.generateLoaButtonDisabledTooltip =
        'tkey;admin.order_numbers.order_view_and_edit.documents.generate_loa.button.disabled_tooltip.tollfree';
    } else if (this.smacsFormStateService.getIsFormDirty()) {
      this.generateLoaButtonDisabledTooltip =
        'tkey;admin.order_numbers.order_view_and_edit.documents.generate_loa.button.disabled_tooltip.dirty';
    } else {
      this.generateLoaButtonDisabledTooltip = '';
    }
  }

  private _setCancelAndDeleteButtonDisabledState(isDisabled: boolean) {
    const isOrderInProgress = !['COMPLETE', 'CANCELLED', 'REQUESTED_CANCEL', 'EXCEPTION'].includes(
      this._processingStatus
    );
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'back-button',
        state: {
          buttonDisableState: { disabled: isDisabled, tooltipKey: '' },
        },
      })
    );
    if (this.isDraftOrder) {
      this._bottomNavService.dispatch(
        new BottomNavUpdateButtonState({
          id: 'delete-draft',
          state: {
            buttonDisableState: { disabled: isDisabled, tooltipKey: '' },
          },
        })
      );
    } else if (isOrderInProgress) {
      this._bottomNavService.dispatch(
        new BottomNavUpdateButtonState({
          id: 'bottom-nav-cancel-button',
          state: {
            buttonDisableState: { disabled: isDisabled, tooltipKey: '' },
          },
        })
      );
    }
  }

  private _cancelOrder(orderId: string): Observable<void> {
    return this._portInOrdersResource.cancelOrder(orderId).pipe(
      tap(() => {
        this._toastService.push(
          ToastTypes.INFO,
          SmacsIcons.HASH,
          'tkey;admin.order_numbers.port_in_orders.cancel_order.toast.title',
          'tkey;admin.order_numbers.port_in_orders.cancel_order.toast.message'
        );
        this._router.navigate(['../..', 'port-in-orders'], { relativeTo: this._route });
      })
    );
  }

  private _openCancellationModal() {
    const options: ZiroPromptModalViewProperties = {
      promptBody: this._translateService.instant('tkey;admin.order_numbers.port_in_orders.cancel_order.modal.body', {
        orderId: this._selectedDraftOrOrder.customerOrderId,
      }),
      title: 'tkey;admin.order_numbers.port_in_orders.cancel_order.modal.title',
      icon: SmacsIcons.REMOVE,
      iconClass: 'text-danger',
      displayCloseButton: true,
      buttons: [
        {
          label: 'tkey;global.button.back.text',
          buttonClass: ButtonStyles.DEFAULT,
          dataAutomation: 'confirmation-modal-cancel-button',
        },
        {
          label: 'tkey;admin.order_numbers.port_in_orders.cancel_order.button',
          buttonIcon: SmacsIcons.REMOVE,
          buttonClass: ButtonStyles.DANGER,
          dataAutomation: 'confirmation-modal-confirm-button',
          cb: () => {
            return this._cancelOrder(this._orderId);
          },
        },
      ],
    };
    this._smacsModalService.openPromptModal(() => options, { modalViewProperties: options });
  }
}
