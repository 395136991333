import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuditService, SystemAudits } from '../../services/audit.service';
import moment from 'moment';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../shared/services/toast.service';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { combineLatest, Observable } from 'rxjs';
import { ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { Audit, AuditSearchResult } from '../../../shared/models/generated/smacsModels';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { AuditViewJsonModalComponent } from './audit-json-view-modal.component';
import { AuditErrorViewModalComponent } from './audit-error-view-modal.component';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFieldTypes,
  EntityTableFiltersValue,
  EntityTableFilterTypes,
  EntityTableOnFieldChange,
} from '../../../shared/entity-table/entity-table.models';
import { AuditTypeCssClass, SmacsAuditTypeTagPipe } from './audit-type-tag.pipe';
import { DateTimeFormat } from '../../../shared/filters/date-time-format.filter';
import { map } from 'rxjs/operators';

@Component({
  selector: 'smacs-audit-logs',
  templateUrl: './audit-logs.component.html',
  styleUrls: ['../../admin-page.scss', './audit-logs.component.scss'],
  providers: [AuditService, SmacsAuditTypeTagPipe, DateTimeFormat],
})
export class AuditLogsComponent implements OnInit, OnDestroy {
  private readonly exportButtonId: string = 'export-audit-button';

  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];
  isLoading: boolean;
  totalResults: number;
  bottomNavButtons = [
    {
      id: this.exportButtonId,
      dataAutomation: 'export-audit-btn',
      label: 'tkey;admin.audits.logs.xlsx_export.button',
      buttonClass: ButtonStyles.PRIMARY,
      icon: SmacsIcons.EXPORT,
      state: {
        pending: false,
        buttonDisableState: { disabled: false, tooltipKey: '' },
      },
      cb: () => {
        this.onGetFileClicked();
      },
    },
  ];

  private _page = 1;
  private _pageSize = 10;

  constructor(
    private auditService: AuditService,
    private smacsModalService: SmacsModalService,
    private bottomNavService: BottomNavService,
    private toastService: ToastService,
    private breadcrumbsService: BreadcrumbsService,
    private smacsAuditTypeTagPipe: SmacsAuditTypeTagPipe,
    private dateTimeFormat: DateTimeFormat
  ) {}

  ngOnInit(): void {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.audits.logs.title' }]);
    this.isLoading = true;

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));
    this.doSearch().subscribe();
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
  }

  onShowErrorClicked(audit: Audit): void {
    if (audit.errorMessage) {
      this.smacsModalService.openCustomModal(AuditErrorViewModalComponent, { audit: audit }, 'xl');
    }
  }

  onShowJsonClicked(audit: Audit): void {
    if (!audit || !this.isUserAbleToViewJson(audit)) {
      return;
    }

    this.smacsModalService.openCustomModal(AuditViewJsonModalComponent, { audit: audit }, 'xl');
  }

  isUserAbleToViewJson(audit: Audit): string {
    return audit.beforeJson || audit.afterJson;
  }

  onGetFileClicked() {
    this._setPending(true);
    this.auditService.exportAudits().subscribe(() => {
      this._setPending(false);
      this.toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.DOWNLOAD,
        'tkey;admin.audits.logs.xlsx_export.downloading_toast.title',
        'tkey;admin.audits.logs.xlsx_export.downloading_toast.message'
      );
    });
  }

  onFilterChange = (event: EntityTableFiltersValue): Observable<EntityTableContentRow[]> => {
    const searchCriteria = {
      startTime: !!event.timestamp ? moment(event.timestamp[0]).hours(0).minutes(0).seconds(0).toISOString() : '',
      endTime: !!event.timestamp
        ? event.timestamp[1]
          ? moment(event.timestamp[1]).hours(23).minutes(59).seconds(59).toDate().toISOString()
          : ''
        : '',
      performedBy: event.performedBy,
      category: event.category,
      type: event.type,
      tag: event.tags,
      serverName: event.server,
      response: event.response,
      page: this._page,
      pageSize: this._pageSize,
    };

    return this.auditService.searchAudits(searchCriteria).pipe(
      map((response: AuditSearchResult) => {
        this.totalResults = response.totalResults;
        this.table.pagination.totalResults = this.totalResults;
        return this._generateTableRows(response.audits);
      })
    );
  };

  onErrorClick(event: EntityTableOnFieldChange) {
    this.onShowErrorClicked(event.contentValue as Audit);
  }

  doSearch(pageNumber = 0, filterValues: EntityTableFiltersValue = null): Observable<EntityTableContentRow[]> {
    const sTime = this._getDefaultStartTime();
    const eTime = this._getDefaultEndTime();
    const searchCriteria = {
      startTime: !!filterValues ? filterValues.timestamp[0].toISOString() : sTime.toISOString(),
      endTime: !!filterValues
        ? filterValues.timestamp[1]
          ? filterValues.timestamp[1].toISOString()
          : ''
        : eTime.toISOString(),
      performedBy: !!filterValues ? filterValues['performedBy'] : '',
      category: !!filterValues ? filterValues['category'] : null,
      type: !!filterValues ? filterValues['type'] : '',
      tag: !!filterValues ? filterValues['tags'] : null,
      serverName: !!filterValues ? filterValues['server'] : '',
      response: !!filterValues ? filterValues['response'] : null,
      page: !!pageNumber ? pageNumber : this._page,
      pageSize: this._pageSize,
    };

    return combineLatest([this.auditService.searchAudits(searchCriteria), this.auditService.state$]).pipe(
      map(([response, systemAudit]) => {
        this.isLoading = true;
        const rows = this._generateTableRows(response.audits);
        this.totalResults = response.totalResults;
        if (pageNumber < 1) {
          this._createTable(systemAudit);
        }
        this.table.pagination.totalResults = this.totalResults;
        if (!filterValues) {
          this.tableRows = rows;
        }
        this.isLoading = false;
        return rows;
      })
    );
  }

  private _createTable(systemAudits: SystemAudits) {
    this.table = {
      columns: [
        {
          columnId: 'timestamp',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;admin.audits.logs.timestamp',
          filter: {
            type: EntityTableFilterTypes.DATE,
            defaultDate: [this._getDefaultStartTime(), this._getDefaultEndTime()],
            preventDateClear: true,
            onChanged: this.onFilterChange.bind(this),
          },
          asyncSearch: true,
        },
        {
          columnId: 'performedBy',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;admin.audits.logs.performedBy',
          filter: {
            type: EntityTableFilterTypes.TEXT,
            onChanged: this.onFilterChange.bind(this),
          },
          asyncSearch: true,
          cssClass: 'text-wrap text-break',
        },
        {
          columnId: 'category',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;admin.audits.logs.category',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: systemAudits.categories,
            onChanged: this.onFilterChange.bind(this),
          },
          asyncSearch: true,
        },
        {
          columnId: 'type',
          cssColumnSize: 'col-sm-1',
          label: 'tkey;admin.audits.logs.type',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: systemAudits.types,
            onChanged: this.onFilterChange.bind(this),
          },
          asyncSearch: true,
        },
        {
          columnId: 'tags',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;admin.audits.logs.tags',
          filter: {
            type: EntityTableFilterTypes.TEXT,
            onChanged: this.onFilterChange.bind(this),
          },
          asyncSearch: true,
        },
        {
          columnId: 'server',
          cssColumnSize: 'col-sm-1',
          label: 'tkey;admin.audits.logs.server',
          filter: {
            type: EntityTableFilterTypes.TEXT,
            onChanged: this.onFilterChange.bind(this),
          },
          asyncSearch: true,
        },
        {
          columnId: 'response',
          cssColumnSize: 'col-sm-1',
          label: 'tkey;admin.audits.logs.response',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: systemAudits.response,
            onChanged: this.onFilterChange.bind(this),
          },
          asyncSearch: true,
        },
      ],
      pagination: {
        totalResults: this.totalResults,
        pageSize: this._pageSize,
        onChanged: this.doSearch.bind(this),
      },
      hasActions: true,
    };
  }

  private _generateTableRows(searchResults: Audit[]): EntityTableContentRow[] {
    return searchResults.map((result: Audit) => {
      const hasError = result.response !== 'OK';
      const content = {
        content: {
          timestamp: result.timestamp,
          performedBy: result.userId,
          category: result.category,
          type: result.type,
          tags: result.tags,
          server: result.server,
          response: result.response,
        },
        html: {
          timestamp: `<strong>${this.dateTimeFormat.transform(result.timestamp)}</strong>`,
        },
        badges: {
          type: {
            maxLength: 30,
            values: [result.type],
            badgeCssClass: [this._getTypeBadge(result.type as keyof AuditTypeCssClass)],
          },
          tags: {
            maxLength: 3,
            values: result.tags ? Object.values(result.tags) : [],
            tooltips: result.tags ? Object.keys(result.tags) : [],
            displayToolTip: true,
          },
        },
        actions: [
          {
            buttonStyle: ButtonStyles.PRIMARY,
            dataAutomation: 'view-audit-json',
            icon: SmacsIcons.NEXT,
            onClick: () => this.onShowJsonClicked(result),
          },
        ],
        cssClass: hasError ? 'table-danger' : '',
      };
      if (hasError) {
        return {
          ...content,
          fields: {
            response: {
              type: EntityTableFieldTypes.BUTTON,
              buttonStyle: ButtonStyles.DANGER,
              label: result.response,
              contentValue: result,
            },
          },
        };
      }
      return content;
    }) as EntityTableContentRow[];
  }

  private _setPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: this.exportButtonId,
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  private _getDefaultStartTime(): Date {
    return moment().hours(0).minutes(0).seconds(0).subtract(1, 'weeks').toDate();
  }

  private _getDefaultEndTime(): Date {
    return moment().hours(23).minutes(59).seconds(59).toDate();
  }

  private _getTypeBadge(type: keyof AuditTypeCssClass): string {
    return this.smacsAuditTypeTagPipe.transform(type);
  }
}
