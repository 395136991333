import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

interface LdapUserDialPlanAttributesPayload {
  username: string;
  extension: string;
  did: string;
}

@Injectable({
  providedIn: 'root',
})
export class LdapUserDialPlanAttributesResource {
  constructor(private http: HttpClient) {}

  put(request: LdapUserDialPlanAttributesPayload): Observable<void> {
    return this.http.put<void>(`/services/cisco/macs/ldap-user-dial-plan-attributes/${request.username}`, {
      ...request,
    });
  }
}
