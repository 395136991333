<div class="datatable-filter">
  <div
    class="form-check form-switch form-switch-right"
    [attr.data-automation]="dataAutomation"
    [ngbTooltip]="tooltip | translate"
    [disableTooltip]="!tooltip"
  >
    <label class="form-check-label" [for]="dataAutomation" [innerHTML]="label | translate"></label>
    <input
      type="checkbox"
      class="form-check-input"
      [id]="dataAutomation"
      [(ngModel)]="value"
      (ngModelChange)="filterUpdateSource.next()"
      [disabled]="isViewLoading"
    />
  </div>
</div>
