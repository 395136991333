<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="breadcrumbDescription | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div *ngIf="!isLoading">
      <ul
        ngbNav
        #nav="ngbNav"
        [(activeId)]="active"
        (navChange)="onNavChange($event)"
        class="nav-tabs"
        data-automation="dial-plan-management-nav-tabs"
      >
        <li [ngbNavItem]="tabs.GROUP_SETTINGS" [destroyOnHide]="false">
          <a ngbNavLink class="btn nav-link">
            <span
              *ngIf="isGroupSettingsFormInvalid"
              [class]="smacsIcons.EXCLAMATION + ' fa animated infinite tada text-danger me-1'"
            ></span>
            <span>{{ 'tkey;dialplanmanagement.admin.group.navlink' | translate }}</span>
          </a>
          <ng-template ngbNavContent>
            <app-cisco-dial-plan-management-group-settings-form
              [clusterOptions]="clusterOptions"
              [siteOptions]="siteOptions"
              [entity]="groupSettingsFormEntity"
              [groupId]="dialPlanGroup.id"
              [dialPlanGroups]="dialPlanGroups"
              [siteSummary]="siteSummary"
              (smacsFormsUpdate$)="onGroupSettingsFormUpdate($event)"
            ></app-cisco-dial-plan-management-group-settings-form>
          </ng-template>
        </li>
        <li
          [ngbNavItem]="tabs.DN_RANGES"
          [destroyOnHide]="false"
          [disabled]="!isGroupSettingsPopulated"
          [disableTooltip]="isGroupSettingsPopulated"
          [ngbTooltip]="'tkey;dialplanmanagement.admin.group.disabled_panes' | translate"
        >
          <a ngbNavLink class="btn nav-link">
            <span
              *ngIf="isDnRangesFormInvalid"
              [class]="smacsIcons.EXCLAMATION + ' fa animated infinite tada text-danger me-1'"
            ></span>
            <span>{{ 'tkey;dialplanmanagement.admin.group.dnranges.navlink' | translate }}</span>
          </a>
          <ng-template ngbNavContent>
            <app-cisco-dial-plan-management-dn-ranges-form
              *ngIf="isFormVisible"
              [entity]="dnRangesFormEntity"
              [cucmMetadata]="cucmMetadata"
              [groupId]="dialPlanGroup.id"
              [clusterId]="clusterId"
              [dialPlanGroups]="dialPlanGroups"
              [siteSummary]="siteSummary"
              (smacsFormsUpdate$)="onDnRangesFormUpdate($event)"
            ></app-cisco-dial-plan-management-dn-ranges-form>
          </ng-template>
        </li>
        <li
          [ngbNavItem]="tabs.WRITE_TO_LDAP"
          [destroyOnHide]="false"
          [disabled]="!isGroupSettingsPopulated"
          [disableTooltip]="isGroupSettingsPopulated"
          [ngbTooltip]="'tkey;dialplanmanagement.admin.group.disabled_panes' | translate"
        >
          <a ngbNavLink class="btn nav-link">
            <span
              *ngIf="isWriteToLdapFormInvalid"
              [class]="smacsIcons.EXCLAMATION + ' fa animated infinite tada text-danger me-1'"
            ></span>
            <span>{{ 'tkey;dialplanmanagement.admin.group.writeldap.navlink' | translate }}</span>
          </a>
          <ng-template ngbNavContent>
            <app-cisco-dial-plan-management-write-to-ldap-form
              *ngIf="isFormVisible"
              [entity]="writeToLdapFormEntity"
              [ldapConfiguration]="ldapConfiguration"
              (smacsFormsUpdate$)="onWriteToLdapFormUpdate($event)"
            ></app-cisco-dial-plan-management-write-to-ldap-form>
          </ng-template>
        </li>
        <li
          [ngbNavItem]="tabs.DID_RANGES"
          [destroyOnHide]="false"
          [disabled]="!isGroupSettingsPopulated"
          [disableTooltip]="isGroupSettingsPopulated"
          [ngbTooltip]="'tkey;dialplanmanagement.admin.group.disabled_panes' | translate"
        >
          <a ngbNavLink class="btn nav-link">
            <span
              *ngIf="isDidRangesFormInvalid"
              [class]="smacsIcons.EXCLAMATION + ' fa animated infinite tada text-danger me-1'"
            ></span>
            <span>{{ 'tkey;dialplanmanagement.admin.group.didranges.navlink' | translate }}</span>
          </a>
          <ng-template ngbNavContent>
            <app-cisco-dial-plan-management-did-ranges-form
              *ngIf="isFormVisible"
              [entity]="didRangesFormEntity"
              [cucmMetadata]="cucmMetadata"
              [groupId]="dialPlanGroup.id"
              [clusterId]="clusterId"
              [dialPlanGroups]="dialPlanGroups"
              [siteSummary]="siteSummary"
              (smacsFormsUpdate$)="onDidRangesFormUpdate($event)"
            ></app-cisco-dial-plan-management-did-ranges-form>
          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
