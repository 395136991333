import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DialPlanGroupContext } from '../../../../helpdesk/shared/contexts/dial-plan-group.context';
import {
  ClusterResult,
  CucmMetadata,
  CustomCheckbox,
  CustomInputText,
  CustomMultiSelectFieldConfig,
  CustomSelect,
  DialPlanManagementGroup,
  LdapPublicConfiguration,
  SelectFieldConfig,
  SiteResult,
  SiteSummary,
  TextFieldConfig,
  UcMetadataCache,
} from '../../../../shared/models/generated/smacsModels';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { ButtonSizes, ButtonStyles } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import { isEqual } from 'lodash';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../shared/services/toast.service';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap/nav/nav';
import {
  CiscoDialPlanManagementWriteToLdapFormComponent,
  CiscoWriteToLdapFormEntity,
} from './write-to-ldap/dial-plan-management-write-to-ldap-form.component';
import { SmacsFormsUpdate } from '../../../../forms/smacs-forms-models';
import {
  CiscoDialPlanManagementGroupSettingsFormComponent,
  CiscoGroupSettingsFormEntity,
} from './group-settings/dial-plan-management-group-settings-form.component';
import {
  CiscoDialPlanManagementDidRangesFormComponent,
  CiscoDidRangesFormEntity,
} from './did-ranges/dial-plan-management-did-ranges-form.component';
import { UcMetadataCacheContext } from '../../../../shared/contexts/uc-metadata-cache.context';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import {
  CiscoDialPlanManagementDnRangesFormComponent,
  CiscoDnRangesFormEntity,
} from './dn-ranges/dial-plan-management-dn-ranges-form.component';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { LdapResource } from '../../../../shared/resources/ldap.resource';
import { BottomNavButton } from '../../../../shared/bottom-nav/bottom-nav.component';
import { tap } from 'rxjs/operators';

enum Tabs {
  GROUP_SETTINGS = 'GROUP_SETTINGS',
  DN_RANGES = 'DN_RANGES',
  WRITE_TO_LDAP = 'WRITE_TO_LDAP',
  DID_RANGES = 'DID_RANGES',
}

export interface SelectFieldConfigUi extends SelectFieldConfig<string> {
  name: string;
  defaultValues?: string[];
}

export interface CustomMultiSelectFieldUi extends CustomMultiSelectFieldConfig {
  name: string;
}

export interface TextFieldConfigUi extends TextFieldConfig {
  name: string;
}

export interface SiteOption {
  label: string;
  value: number;
  group: string;
}

export interface ClusterOption {
  label: string;
  value: number;
}

@Component({
  selector: 'app-cisco-dial-plan-management-edit',
  templateUrl: './dial-plan-management-edit.component.html',
  styleUrls: ['../../../admin-page.scss', './dial-plan-management-edit.component.scss'],
  providers: [LdapResource],
})
export class CiscoDialPlanManagementEditComponent implements OnInit, OnDestroy {
  @ViewChild(CiscoDialPlanManagementGroupSettingsFormComponent)
  groupSettingsFormComponent: CiscoDialPlanManagementGroupSettingsFormComponent;

  @ViewChild(CiscoDialPlanManagementDnRangesFormComponent)
  dnRangesFormComponent: CiscoDialPlanManagementDnRangesFormComponent;

  @ViewChild(CiscoDialPlanManagementWriteToLdapFormComponent)
  writeToLdapFormComponent: CiscoDialPlanManagementWriteToLdapFormComponent;

  @ViewChild(CiscoDialPlanManagementDidRangesFormComponent)
  didRangesFormComponent: CiscoDialPlanManagementDidRangesFormComponent;

  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  tabs = Tabs;
  isLoading = true;
  active = this.tabs.GROUP_SETTINGS;
  breadcrumbDescription = 'tkey;dial_plan_management.edit.group_settings.description';

  siteSummary: SiteSummary;
  dialPlanGroup: DialPlanManagementGroup;
  dialPlanGroups: DialPlanManagementGroup[] = [];
  cucmMetadata: CucmMetadata;
  ldapConfiguration: LdapPublicConfiguration;

  isFormVisible = false;
  isGroupSettingsPopulated = false;
  clusterId: number;

  groupSettingsFormEntity: CiscoGroupSettingsFormEntity;
  isGroupSettingsFormInvalid = false;
  clusterOptions: ClusterOption[] = [];
  siteOptions: SiteOption[] = [];

  dnRangesFormEntity: CiscoDnRangesFormEntity;
  isDnRangesFormInvalid = false;

  writeToLdapFormEntity: CiscoWriteToLdapFormEntity;
  isWriteToLdapFormInvalid = false;

  didRangesFormEntity: CiscoDidRangesFormEntity;
  isDidRangesFormInvalid = false;

  private _isCopy: boolean;
  private _ucMetadataCache: UcMetadataCache;
  private _groupId: string;
  private _subscriptions = new Subscription();

  constructor(
    private _dialPlanGroupContext: DialPlanGroupContext,
    private _breadcrumbsService: BreadcrumbsService,
    private _siteSummaryContext: SiteSummaryContext,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _toastService: ToastService,
    private _activatedRoute: ActivatedRoute,
    private _ucMetadataCacheContext: UcMetadataCacheContext,
    private _bottomNavService: BottomNavService,
    private _router: Router,
    private _smacsFormStateService: SmacsFormStateService,
    private _ldapResource: LdapResource,
    private _routeReuseStrategy: RouteReuseStrategy
  ) {}

  ngOnInit() {
    this._routeReuseStrategy.shouldReuseRoute = () => false;
    this._groupId = this._activatedRoute.snapshot.params.id || null;
    this._isCopy = this._router.url.includes('copy');
    this._setBreadcrumbLabel('');
    this._initBottomNav();
    this._initContexts();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onNavChange($event: NgbNavChangeEvent) {
    this._setBreadcrumbDescription($event.nextId);
  }

  /**
   * Update local entity to be passed to API
   * Updates breadcrumb on name change
   * Disables/enables other tabs when Cluster or Site selected or not
   * Toggle visibility of DID Range form to force update of config options
   */
  onGroupSettingsFormUpdate(data: SmacsFormsUpdate<CiscoGroupSettingsFormEntity>) {
    if (!isEqual(data.new, data.old)) {
      this.groupSettingsFormEntity = data.new;

      if (!isEqual(data.new.name, data.old?.name)) {
        this._setBreadcrumbLabel(data.new.name);
      }

      if (!isEqual(data.new.clusterId, data.old?.clusterId) || !isEqual(data.new.siteIds, data.old?.siteIds)) {
        this.isFormVisible = false;

        setTimeout(() => {
          this._setUcMetadataCacheCtxDeps();
          this.isGroupSettingsPopulated = !!(data.new.clusterId || data.new.siteIds.length);
        }, 10);
      }
    }
  }

  onDnRangesFormUpdate(data: SmacsFormsUpdate<CiscoDnRangesFormEntity>) {
    if (!isEqual(data.new, data.old)) {
      this.dnRangesFormEntity = data.new;
    }
  }

  onWriteToLdapFormUpdate(data: SmacsFormsUpdate<CiscoWriteToLdapFormEntity>) {
    if (!isEqual(data.new, data.old)) {
      this.writeToLdapFormEntity = data.new;
    }
  }

  onDidRangesFormUpdate(data: SmacsFormsUpdate<CiscoDidRangesFormEntity>) {
    if (!isEqual(data.new, data.old)) {
      this.didRangesFormEntity = data.new;
    }
  }

  private _setBreadcrumbLabel(label: string) {
    this._breadcrumbsService.updateBreadcrumbs([
      { label: 'tkey;dialplanmanagement.title', url: '/admin/cisco/dial-plan-management', routerLink: true },
      { label: label },
    ]);
  }

  private _setBreadcrumbDescription(tab: Tabs) {
    switch (tab) {
      case Tabs.GROUP_SETTINGS: {
        this.breadcrumbDescription = 'tkey;dial_plan_management.edit.group_settings.description';
        break;
      }
      case Tabs.DN_RANGES: {
        this.breadcrumbDescription = 'tkey;dial_plan_management.edit.dn_ranges.description';
        break;
      }
      case Tabs.WRITE_TO_LDAP: {
        this.breadcrumbDescription = 'tkey;dial_plan_management.edit.write_to_ldap.description';
        break;
      }
      case Tabs.DID_RANGES: {
        this.breadcrumbDescription = 'tkey;dial_plan_management.edit.did_ranges.description';
        break;
      }
      default: {
        this.breadcrumbDescription = '';
        break;
      }
    }
  }

  private _deleteGroup(): Observable<void> {
    return this._dialPlanGroupContext.delete(this._groupId).pipe(
      tap(() => {
        this._toastService.pushDeleteToast('tkey;shared.toast.delete.success.title', this.groupSettingsFormEntity.name);
        this._router.navigate(['/admin/cisco/dial-plan-management']);
      })
    );
  }

  private _initBottomNav() {
    const buttons: BottomNavButton[] = [];

    buttons.push(
      {
        id: 'bottom-nav-cancel-button',
        label: 'tkey;global.button.cancel.text',
        buttonClass: this.buttonStyles.DEFAULT,
        dataAutomation: 'bottom-nav-cancel-button',
        cb: () => {
          this._router.navigate(['/admin/cisco/dial-plan-management']);
        },
      },
      {
        id: 'bottom-nav-save-button',
        label: 'tkey;global.button.save.text',
        icon: this.smacsIcons.OK,
        buttonClass: this.buttonStyles.PRIMARY,
        dataAutomation: 'bottom-nav-save-button',
        cb: () => this._onSaveClick(),
      }
    );

    if (this._groupId && !this._isCopy) {
      buttons.splice(0, 0, {
        id: 'bottom-nav-copy-button',
        label: 'tkey;dialplanmanagement.admin.group.copy',
        buttonClass: this.buttonStyles.INFO,
        icon: this.smacsIcons.COPY,
        dataAutomation: 'bottom-nav-copy-button',
        cb: () => {
          this._router.navigate([`/admin/cisco/dial-plan-management/dial-plan-group/${this._groupId}/copy`]);
        },
      });

      buttons.splice(2, 0, {
        id: 'bottom-nav-delete-button',
        dataAutomation: 'bottom-nav-delete-button',
        label: 'tkey;global.button.delete.text',
        icon: SmacsIcons.DELETE,
        buttonClass: ButtonStyles.DANGER,
        cb: () => {
          this._onDeleteClicked();
        },
      });
    }

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList([...buttons]));
  }

  private _onDeleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        promptBody: this._translateService.instant('tkey;global.sure.delete.dialplanmanagement.group', {
          name: this.groupSettingsFormEntity.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteGroup();
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _initContexts() {
    const subs = combineLatest([
      this._siteSummaryContext.state$,
      this._dialPlanGroupContext.state$,
      this._ucMetadataCacheContext.state$,
      this._ldapResource.getLdapConfigurationSettings(),
    ]).subscribe((states: [SiteSummary, DialPlanManagementGroup[], UcMetadataCache, LdapPublicConfiguration]) => {
      this._setSiteSummaryCtxDeps(states[0]);
      this._setDialPlanCtxDeps(states[1]);

      this._ucMetadataCache = states[2];
      this._setUcMetadataCacheCtxDeps();

      this.ldapConfiguration = states[3];

      this.isLoading = false;
    });
    this._subscriptions.add(subs);
  }

  /**
   * Set options for Cluster + Site selects on Group Settings tab
   */
  private _setSiteSummaryCtxDeps(state: SiteSummary) {
    this.siteSummary = state;
    this.clusterOptions = this.siteSummary.clusters.map((cluster: ClusterResult) => {
      return {
        value: cluster.id,
        label: cluster.name,
      };
    });

    this.siteSummary.clusters.forEach((cluster: ClusterResult) => {
      cluster.sites.forEach((site: SiteResult) => {
        this.siteOptions.push({
          label: site.name,
          value: site.id,
          group: cluster.name,
        });
      });
    });
  }

  /**
   * Set dialplan group initial state. Either an empty object for add, pre-populated from the API for edit, and a
   * modified object from the API for copy
   */
  private _setDialPlanCtxDeps(state: DialPlanManagementGroup[]) {
    const groupId = this._activatedRoute.snapshot.params.id || null;
    this.dialPlanGroups = state;

    if (groupId) {
      this.dialPlanGroup = this.dialPlanGroups.find((group: DialPlanManagementGroup) => group.id === groupId);
      if (this._isCopy) {
        this.dialPlanGroup = {
          ...this.dialPlanGroup,
          id: null,
          name: `${this.dialPlanGroup.name} (Copy)`,
          directoryNumberRangesSection: {
            ...this.dialPlanGroup.directoryNumberRangesSection,
            dnRanges: [{ start: '', end: '' }],
          },
          translationPatternRangesSection: {
            ...this.dialPlanGroup.translationPatternRangesSection,
            translationPatternRanges: [],
          },
        };
      }

      this.isGroupSettingsPopulated = true;
      this._setBreadcrumbLabel(this.dialPlanGroup.name);
    } else {
      this.dialPlanGroup = {
        clusterId: null,
        directoryNumberRangesSection: {
          customInputs: [
            {
              name: 'external_phone_number_mask',
              value: '',
              show: false,
              required: false,
            },
            {
              name: 'public_external_phone_number_mask',
              value: '',
              show: false,
              required: false,
            },
            {
              name: 'line_text_label',
              value: '',
              show: false,
              required: false,
            },
          ],
          customSelects: [
            {
              name: 'route_partition',
              defaultOption: '',
              possibleOptions: [],
              show: false,
              required: false,
            },
            {
              name: 'Monitoring Css',
              defaultOption: '',
              possibleOptions: [],
              show: false,
              required: false,
            },
            {
              name: 'auto_answer',
              defaultOption: 'Auto Answer Off',
              possibleOptions: ['Auto Answer Off'],
              show: false,
              required: false,
            },
          ],
          customCheckboxes: [
            {
              name: 'urgent_priority',
              value: false,
              show: false,
            },
          ],
          customMultiSelects: [
            {
              name: 'optional_line_groups',
              possibleOptions: [],
              defaultOptions: [],
              show: false,
            },
          ],
          checkboxes: [
            {
              name: 'advertise_distinct_mask_for_public_lines',
              value: false,
            },
          ],
          dnRanges: [{ start: '', end: '' }],
        },
        e164AlternateNumberSection: {
          customSelects: [
            {
              name: 'route_partition',
              defaultOption: '',
              possibleOptions: [],
              show: false,
              required: false,
            },
          ],
          customInputs: [
            {
              name: 'number_mask',
              value: '',
              show: false,
              required: false,
            },
          ],
          customCheckboxes: [
            {
              name: 'add_to_local_route_partition',
              value: false,
              show: false,
            },
            {
              name: 'is_urgent',
              value: false,
              show: false,
            },
            {
              name: 'advertise_globally',
              value: false,
              show: false,
            },
          ],
        },
        enterpriseAlternateNumberSection: {
          customSelects: [
            {
              name: 'route_partition',
              defaultOption: '',
              possibleOptions: [],
              show: false,
              required: false,
            },
          ],
          customInputs: [
            {
              name: 'number_mask',
              value: '',
              show: false,
              required: false,
            },
          ],
          customCheckboxes: [
            {
              name: 'add_to_local_route_partition',
              value: false,
              show: false,
            },
            {
              name: 'is_urgent',
              value: false,
              show: false,
            },
            {
              name: 'advertise_globally',
              value: false,
              show: false,
            },
          ],
        },
        id: null,
        name: '',
        pstnFailoverSection: {
          customSelects: [
            {
              name: 'pstn_failover',
              defaultOption: '',
              possibleOptions: [],
              show: false,
              required: false,
            },
          ],
        },
        siteIds: [],
        translationPatternRangesSection: {
          customSelects: [
            {
              name: 'route_partition',
              defaultOption: '',
              possibleOptions: [],
              required: false,
              show: false,
            },
          ],
          translationPatternRanges: [],
        },
        writeLdapSection: {
          customInputs: [
            {
              name: 'e164_ldap',
              required: false,
              show: false,
              value: '',
            },
            {
              name: 'extension_ldap',
              required: false,
              show: false,
              value: '',
            },
          ],
        },
      } as DialPlanManagementGroup;
    }

    this.groupSettingsFormEntity = {
      name: this.dialPlanGroup.name,
      clusterId: this.dialPlanGroup.clusterId,
      siteIds: this.dialPlanGroup.siteIds,
    };
    this.dnRangesFormEntity = {
      // DN Ranges
      ranges: this.dialPlanGroup.directoryNumberRangesSection.dnRanges,
      // Line Appearance Settings
      advertise_distinct_mask_for_public_lines: this.dialPlanGroup.directoryNumberRangesSection.checkboxes[0],
      external_phone_number_mask: this.dialPlanGroup.directoryNumberRangesSection.customInputs.find(
        (c: CustomInputText) => c.name === 'external_phone_number_mask'
      ),
      public_external_phone_number_mask: this.dialPlanGroup.directoryNumberRangesSection.customInputs.find(
        (c: CustomInputText) => c.name === 'public_external_phone_number_mask'
      ),
      line_text_label: this.dialPlanGroup.directoryNumberRangesSection.customInputs.find(
        (c: CustomInputText) => c.name === 'line_text_label'
      ),
      // Directory Number Settings
      route_partition: this.dialPlanGroup.directoryNumberRangesSection.customSelects.find(
        (c: CustomSelect) => c.name === 'route_partition'
      ),
      urgent_priority: this.dialPlanGroup.directoryNumberRangesSection.customCheckboxes[0],
      optional_line_group_membership: this.dialPlanGroup.directoryNumberRangesSection.customMultiSelects[0],
      auto_answer: this.dialPlanGroup.directoryNumberRangesSection.customSelects.find(
        (c: CustomSelect) => c.name === 'auto_answer'
      ),
      monitoring_calling_search_space: this.dialPlanGroup.directoryNumberRangesSection.customSelects.find(
        (c: CustomSelect) => c.name === 'Monitoring Css'
      ),
      // Enterprise Alternate Number
      ent_alt_num_number_mask: this.dialPlanGroup.enterpriseAlternateNumberSection.customInputs[0],
      ent_alt_num_advertise_globally_via_ils: this.dialPlanGroup.enterpriseAlternateNumberSection.customCheckboxes.find(
        (c: CustomCheckbox) => c.name === 'advertise_globally'
      ),
      ent_alt_num_is_urgent: this.dialPlanGroup.enterpriseAlternateNumberSection.customCheckboxes.find(
        (c: CustomCheckbox) => c.name === 'is_urgent'
      ),
      ent_alt_num_add_to_local_route_partition:
        this.dialPlanGroup.enterpriseAlternateNumberSection.customCheckboxes.find(
          (c: CustomCheckbox) => c.name === 'add_to_local_route_partition'
        ),
      ent_alt_num_route_partition: this.dialPlanGroup.enterpriseAlternateNumberSection.customSelects[0],
      // E.164 Alternate
      e164_alt_num_number_mask: this.dialPlanGroup.e164AlternateNumberSection.customInputs[0],
      e164_alt_num_advertise_globally_via_ils: this.dialPlanGroup.e164AlternateNumberSection.customCheckboxes.find(
        (c: CustomCheckbox) => c.name === 'advertise_globally'
      ),
      e164_alt_num_is_urgent: this.dialPlanGroup.e164AlternateNumberSection.customCheckboxes.find(
        (c: CustomCheckbox) => c.name === 'is_urgent'
      ),
      e164_alt_num_add_to_local_route_partition: this.dialPlanGroup.e164AlternateNumberSection.customCheckboxes.find(
        (c: CustomCheckbox) => c.name === 'add_to_local_route_partition'
      ),
      e164_alt_num_route_partition: this.dialPlanGroup.e164AlternateNumberSection.customSelects[0],
      // PSTN Failover
      pstn_failover: this.dialPlanGroup.pstnFailoverSection.customSelects[0],
    };
    this.writeToLdapFormEntity = {
      e164_ldap: this.dialPlanGroup.writeLdapSection.customInputs.find((c: CustomInputText) => c.name === 'e164_ldap'),
      extension_ldap: this.dialPlanGroup.writeLdapSection.customInputs.find(
        (c: CustomInputText) => c.name === 'extension_ldap'
      ),
    };
    this.didRangesFormEntity = {
      route_partition: this.dialPlanGroup.translationPatternRangesSection.customSelects[0],
      ranges: this.dialPlanGroup.translationPatternRangesSection.translationPatternRanges,
    };
  }

  /**
   * Set the available options for the Route Partition field config in DID Ranges
   */
  private _setUcMetadataCacheCtxDeps() {
    if (this.groupSettingsFormEntity.clusterId) {
      const matchingCluster = this.siteSummary.clusters.find(
        (cluster: ClusterResult) => cluster.id === this.groupSettingsFormEntity.clusterId
      );
      this.clusterId = matchingCluster.id;
      this.cucmMetadata = this._ucMetadataCache.cucmMetadataCache[matchingCluster.cucmServerId];
      this.isFormVisible = true;
    } else if (this.groupSettingsFormEntity.siteIds.length) {
      const matchingCluster = this.siteSummary.clusters.find((cluster: ClusterResult) =>
        cluster.sites.find((site: SiteResult) => site.id === this.groupSettingsFormEntity.siteIds[0])
      );
      this.clusterId = matchingCluster.id;
      this.cucmMetadata = this._ucMetadataCache.cucmMetadataCache[matchingCluster.cucmServerId];
      this.isFormVisible = true;
    }
  }

  // TODO error icon in tab of form with error validation
  private _onSaveClick() {
    this._bottomNavService.setBottomNavValidationError(false);
    const canSubmitForm = this._canSubmitForms();
    const canSetNavErrors = this._setNavErrors();

    if (!canSubmitForm || !canSetNavErrors) {
      this._bottomNavService.setBottomNavValidationError(true);
      return;
    }

    this._bottomNavService.setBottomNavValidationError(false);
    this._bottomNavService.setButtonPendingState('bottom-nav-save-button', true);
    this._bottomNavService.setButtonDisabledState('bottom-nav-copy-button', true);
    this._bottomNavService.setButtonDisabledState('bottom-nav-cancel-button', true);

    this.dialPlanGroup = {
      ...this.dialPlanGroup,
      // Group Settings
      clusterId: this.groupSettingsFormEntity.clusterId,
      name: this.groupSettingsFormEntity.name,
      siteIds: [...this.groupSettingsFormEntity.siteIds],
      // DN Ranges
      directoryNumberRangesSection: {
        customInputs: [
          this.dnRangesFormEntity.external_phone_number_mask,
          this.dnRangesFormEntity.public_external_phone_number_mask,
          this.dnRangesFormEntity.line_text_label,
        ],
        customSelects: [
          this.dnRangesFormEntity.route_partition,
          this.dnRangesFormEntity.monitoring_calling_search_space,
          this.dnRangesFormEntity.auto_answer,
        ],
        customCheckboxes: [this.dnRangesFormEntity.urgent_priority],
        customMultiSelects: [this.dnRangesFormEntity.optional_line_group_membership],
        checkboxes: [this.dnRangesFormEntity.advertise_distinct_mask_for_public_lines],
        dnRanges: [...this.dnRangesFormEntity.ranges],
      },
      e164AlternateNumberSection: {
        customSelects: [this.dnRangesFormEntity.e164_alt_num_route_partition],
        customInputs: [this.dnRangesFormEntity.e164_alt_num_number_mask],
        customCheckboxes: [
          this.dnRangesFormEntity.e164_alt_num_add_to_local_route_partition,
          this.dnRangesFormEntity.e164_alt_num_is_urgent,
          this.dnRangesFormEntity.e164_alt_num_advertise_globally_via_ils,
        ],
      },
      enterpriseAlternateNumberSection: {
        customSelects: [this.dnRangesFormEntity.ent_alt_num_route_partition],
        customInputs: [this.dnRangesFormEntity.ent_alt_num_number_mask],
        customCheckboxes: [
          this.dnRangesFormEntity.ent_alt_num_add_to_local_route_partition,
          this.dnRangesFormEntity.ent_alt_num_is_urgent,
          this.dnRangesFormEntity.ent_alt_num_advertise_globally_via_ils,
        ],
      },
      pstnFailoverSection: {
        customSelects: [this.dnRangesFormEntity.pstn_failover],
      },
      // Write to LDAP
      writeLdapSection: {
        customInputs: [this.writeToLdapFormEntity.e164_ldap, this.writeToLdapFormEntity.extension_ldap],
      },
      // Translation Pattern Ranges
      translationPatternRangesSection: {
        customSelects: [this.didRangesFormEntity.route_partition],
        translationPatternRanges: [...this.didRangesFormEntity.ranges],
      },
    } as DialPlanManagementGroup;

    if (this.dialPlanGroup.id) {
      this._dialPlanGroupContext
        .put(this.dialPlanGroup.id, this.dialPlanGroup)
        .subscribe(() => this._onSaveSuccess(this.dialPlanGroup.name));
    } else {
      this._dialPlanGroupContext.post(this.dialPlanGroup).subscribe(() => this._onSaveSuccess(this.dialPlanGroup.name));
    }
  }

  private _onSaveSuccess(groupName: string) {
    this._dialPlanGroupContext.init().subscribe(() => {
      this._smacsFormStateService.setIsFormDirty(false);
      this._toastService.pushSaveToast(
        'tkey;dialplanmanagement.save.successful.toast',
        groupName,
        this.smacsIcons.DIAL_PLAN_GROUP
      );
      this._router.navigate(['/admin/cisco/dial-plan-management']);
    });
  }

  /**
   * If no site/cluster has been selected the other forms won't be in the DOM, so we must manually force validation
   */
  private _canSubmitForms(): boolean {
    this.groupSettingsFormComponent._validateAndSubmitSource.next(true);
    this.groupSettingsFormComponent.validateAllFields();

    if (this.dnRangesFormComponent) {
      this.dnRangesFormComponent._validateAndSubmitSource.next(true);
      if (this.dnRangesFormComponent.extensionRangesDisplayForm) {
        this.dnRangesFormComponent.extensionRangesDisplayForm._validateAndSubmitSource.next(true);
      } else {
        return false;
      }
    } else {
      return false;
    }

    if (this.writeToLdapFormComponent) {
      this.writeToLdapFormComponent._validateAndSubmitSource.next(true);
    } else {
      return false;
    }

    if (this.didRangesFormComponent) {
      this.didRangesFormComponent._validateAndSubmitSource.next(true);
      if (this.didRangesFormComponent.extensionRangesDisplayForm) {
        this.didRangesFormComponent.extensionRangesDisplayForm._validateAndSubmitSource.next(true);
      } else {
        return false;
      }
    } else {
      return false;
    }

    return true;
  }

  /**
   * Show/hide the exclamation errors icon
   */
  private _setNavErrors(): boolean {
    this.isGroupSettingsFormInvalid = !this.groupSettingsFormComponent.isFormValid();
    this.isDnRangesFormInvalid =
      (this.dnRangesFormComponent && !this.dnRangesFormComponent.isFormValid()) ||
      (this.dnRangesFormComponent &&
        this.dnRangesFormComponent.extensionRangesDisplayForm &&
        !this.dnRangesFormComponent.extensionRangesDisplayForm.isFormValid());
    this.isWriteToLdapFormInvalid = this.writeToLdapFormComponent && !this.writeToLdapFormComponent.isFormValid();
    this.isDidRangesFormInvalid =
      (this.didRangesFormComponent && !this.didRangesFormComponent.isFormValid()) ||
      (this.didRangesFormComponent &&
        this.didRangesFormComponent.extensionRangesDisplayForm &&
        !this.didRangesFormComponent.extensionRangesDisplayForm.isFormValid());

    if (
      !this.groupSettingsFormComponent.isFormValid() ||
      !this.dnRangesFormComponent?.isFormValid() ||
      !this.writeToLdapFormComponent?.isFormValid() ||
      !this.didRangesFormComponent?.isFormValid() ||
      !this.dnRangesFormComponent?.extensionRangesDisplayForm?.isFormValid() ||
      !this.didRangesFormComponent?.extensionRangesDisplayForm?.isFormValid()
    ) {
      this._bottomNavService.setBottomNavValidationError(true);
      return false;
    }

    return true;
  }
}
