import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class CdrDumpFileImportResource {
  private _baseUrl = '/services/cisco/reporting/cucm-cdrs/imports/dump-files';

  constructor(private http: HttpClient) {}

  post(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('upload', file);
    return this.http.post<void>(this._baseUrl, formData);
  }
}
