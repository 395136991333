import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, ReplaySubject, Subscriber, Subscription, switchMap, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { find, forOwn, isEqual, isMatch } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { BottomNavButton } from '../../../../shared/bottom-nav/bottom-nav.component';
import { LdapResource } from '../../../../shared/resources/ldap.resource';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { RouteHelpersService } from '../../../../shared/services/route-helpers.service';
import { ZeroTouchProvisioningResource } from '../../../../shared/resources/zpc-zero-touch.abstract.resource';
import { ToastService } from '../../../../shared/services/toast.service';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import {
  CiscoZeroTouchProvisioningFieldConfig,
  ClusterResult,
  EmailSettings,
  JobState,
  JobStatus,
  LdapUserId,
  LdapUserResult,
  ProvisioningSchedule,
  SiteResult,
  SiteSummary,
} from '../../../../shared/models/generated/smacsModels';
import { SCHEDULE_FREQUENCY } from '../../../../shared/models/zero-touch';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../forms/fields/select/smacs-select.component';
import { SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsMultiCheckboxConfig } from '../../../../forms/fields/multi-checkbox/smacs-multi-checkbox.component';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { SmacsRadioConfig } from '../../../../forms/fields/radio/smacs-radio.component';
import { ZeroTouchSchedulingService } from '../../../services/zero-touch-scheduling.service';
import { ViewMembersState } from '../../../../shared/view-members/view-members.component';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { ButtonStyles } from '../../../../button/button.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SmacsMultiTextConfig } from '../../../../forms/fields/multi-text/smacs-multi-text.component';
import { EmailSettingsContext } from '../../../../shared/contexts/email-settings.context';
import { CollapsibleCardSwitchValue } from '../../../../shared/collapsible-card/collapsible-card.component';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { CiscoProvisioningStatusPollingService } from '../../../../shared/services/cisco/cisco-provisioning-status-polling.service';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';

interface SiteServices {
  agentExtension: boolean;
  android: boolean;
  cipc: boolean;
  dn: boolean;
  imSoftPhone: boolean;
  imp: boolean;
  iphone: boolean;
  mobility: boolean;
  snr: boolean;
  tablet: boolean;
  voicemail: boolean;
  snrDestinationForMicrosoftTeams: boolean;
}

interface ProvisioningScheduleFormData {
  name: string;
  ldapFilter: string;
  site?: number;
  dialPlanGroups?: number[];
  services: SiteServices;
  dailyJobTime: string;
  periodicJobMinutes: number;
  allOutcomeRecipients: string[] | null;
  successRecipients: string[] | null;
  failureRecipients: string[] | null;
  runAutomatically?: boolean;
  scheduleFrequency?: any;
}

enum JobEditViews {
  GENERAL_SETTINGS,
  IGNORED_USERS,
}

@Component({
  selector: 'app-admin-zero-touch-provisioning-edit-job',
  templateUrl: './provisioning-edit-job.component.html',
  styleUrls: ['./provisioning-edit-job.component.scss'],
  providers: [CiscoProvisioningStatusPollingService],
})
export class ProvisioningEditJobComponent
  extends SmacsFormAbstractDirective<ProvisioningScheduleFormData>
  implements OnInit, OnDestroy
{
  private _validators = {
    toEmailValidator: (values: string[]): SmacsFormsValidationState => {
      return values.every((value: string) => {
        return (
          !value ||
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        );
      })
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID;
    },
  };

  ignoredUsers = [] as LdapUserResult[];
  initialIgnoredUsers: LdapUserResult[];
  JobEditViews = JobEditViews;
  jobEditView = JobEditViews.GENERAL_SETTINGS;
  ldapUserResults: LdapUserResult[];
  ignoredUsersFormIsDirty = false;
  generalSettingsFormIsDirty = false;
  jobId: number;
  isLoading = true;
  smacsIcons = SmacsIcons;
  sites: SiteResult[];
  isRunning = false;
  nextRunTimesMessage = '';
  ciscoZeroTouchProvisioningFieldConfig: CiscoZeroTouchProvisioningFieldConfig;
  ldapFilterState = ViewMembersState.IDLE;
  ldapFilterMatches: string[] = [];
  ldapFilterViewMembersDisabled = false;
  job = {} as ProvisioningSchedule;
  isSaving = false;
  isEmailSectionEnabled = true;
  allowRunNowWithEmailDisabled = false;
  emailSectionToggle = false;
  emailSectionShouldBeOpenOnLoad = false;
  bottomNavButtons = [
    {
      id: 'runNowZeroTouchProvisioningForm',
      dataAutomation: 'runNowZeroTouchProvisioningForm',
      label: 'tkey;pages.zero_touch.job.run_now',
      buttonClass: ButtonStyles.INFO,
      tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip.disabled',
      state: {
        pending: false,
        buttonDisableState: { disabled: true, tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip.disabled' },
        tooltipVisible: true,
      },
      icon: this.smacsIcons.RUN,
      cb: () => this._runNow(),
    },
    {
      id: 'cancelZeroTouchProvisioningForm',
      dataAutomation: 'cancelZeroTouchProvisioningForm',
      label: 'tkey;global.button.cancel.text',
      buttonClass: ButtonStyles.OUTLINE_DEFAULT,
      tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip',
      state: {
        pending: false,
        buttonDisableState: { disabled: false },
        tooltipVisible: false,
      },
      cb: () => {
        this.routeHelpersService.goToParent(this.route, 'jobId');
      },
    },
    {
      id: 'saveZeroTouchProvisioningForm',
      dataAutomation: 'saveZeroTouchProvisioningForm',
      label: 'tkey;admin.ui.save',
      icon: this.smacsIcons.OK,
      buttonClass: ButtonStyles.PRIMARY,
      state: {
        pending: false,
        buttonDisableState: { disabled: true, tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip' },
        tooltipVisible: true,
      },
      cb: () => {
        if (this.jobEditView === JobEditViews.GENERAL_SETTINGS) {
          this._submitForm();
        } else {
          this._saveIgnoredUsers();
        }
      },
    },
  ] as BottomNavButton[];
  validators = {
    isUnique: (val: string, comparisonKey: 'name' | 'ldapFilter'): Observable<SmacsFormsValidationState> => {
      return new Observable<SmacsFormsValidationState>((subscriber: Subscriber<SmacsFormsValidationState>) => {
        this.zeroTouchProvisioningResource.getAll().subscribe((existingJobs: ProvisioningSchedule[]) => {
          const match = find(
            existingJobs,
            (job: ProvisioningSchedule) =>
              this.jobId !== job.id && job[comparisonKey].toLowerCase() === val.toLowerCase()
          );

          subscriber.next(match ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID);
          subscriber.complete();
        });
      });
    },
    isNameUnique: (val: string): Observable<SmacsFormsValidationState> => this.validators.isUnique(val, 'name'),
    isLdapFilterUnique: (val: string): Observable<SmacsFormsValidationState> => {
      return new Observable<SmacsFormsValidationState>((subscriber: Subscriber<SmacsFormsValidationState>) => {
        this.validators.isUnique(val, 'ldapFilter').subscribe((isUnique: SmacsFormsValidationState) => {
          if (isUnique !== SmacsFormsValidationState.VALID) {
            this.ldapFilterState = ViewMembersState.IDLE;
          }

          subscriber.next(isUnique);
          subscriber.complete();
        });
      });
    },
    isLdapFilterValid: (val: string): Observable<SmacsFormsValidationState> => this._isLdapFilterValid(val),
  };
  formConfig = {
    fields: {
      name: {
        label: 'tkey;pages.zero_touch.job_name',
        dataAutomation: 'provisioning-job-name',
        required: true,
        validation: [
          {
            validator: this.validators.isNameUnique,
            message: 'tkey;pages.zero_touch.name.unique',
          },
        ],
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        helpText: 'tkey;pages.zero_touch.provisioning.edit_job.help_text.job_name',
      },
      ldapFilter: {
        label: 'tkey;pages.zero_touch.provisioning.filter.ldap_filter',
        dataAutomation: 'provisioning-ldap-filter',
        required: true,
        validation: [
          {
            validator: this.validators.isLdapFilterUnique,
            message: 'tkey;pages.zero_touch.ldap_filter.unique',
          },
          {
            validator: this.validators.isLdapFilterValid,
            message: 'tkey;pages.zero_touch.provisioning.ldap_filter.invalid_format',
          },
        ],
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        helpText: 'tkey;pages.zero_touch.provisioning.edit_job.help_text.ldap_filter',
      },
      site: {
        label: 'tkey;pages.zero_touch.provisioning.filter.site',
        dataAutomation: 'provisioning-site',
        required: true,
        componentConfig: new SmacsSelectConfig({
          options: [],
          bindValue: 'value',
        }),
        helpText: 'tkey;pages.zero_touch.provisioning.edit_job.help_text.site',
      },
      dialPlanGroups: {
        label: 'tkey;pages.zero_touch.provisioning.filter.dial_plan_group',
        dataAutomation: 'provisioning-dial-plan-group',
        required: true,
        componentConfig: new SmacsSelectConfig({
          options: [],
          isMultiSelect: true,
          bindValue: 'value',
        }),
        helpText: 'tkey;pages.zero_touch.provisioning.edit_job.help_text.dialplan_groups',
        hidden: () => !this.formData?.site,
      },
      services: {
        label: 'tkey;pages.zero_touch.provisioning.filter.services',
        dataAutomation: 'bulk-provisioning-general-services-multi-checkbox',
        required: true,
        helpText: 'tkey;pages.zero_touch.provisioning.edit_job.help_text.services',
        hidden: () => {
          return !this.formData?.site;
        },
        componentConfig: new SmacsMultiCheckboxConfig({
          multiCheckboxOptionConfig: [
            {
              label: 'tkey;pages.zero_touch.provisioning.edit.form.primary_extension',
              disabled: () => {
                return this._isPrimaryExtensionServiceDisabled();
              },
              disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.dn.disabled.tooltip',
            },
            {
              label: 'tkey;pages.zero_touch.provisioning.edit.form.agent_extension',
              hidden: () => {
                return !this.ciscoZeroTouchProvisioningFieldConfig?.agentExtension;
              },
            },
            {
              label: 'tkey;shared.service.voicemail.text',
              disabled: () => {
                return !this.ciscoZeroTouchProvisioningFieldConfig?.voicemail;
              },
              disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
            },
            {
              label: 'tkey;shared.service.snr.text',
              disabled: () => {
                return this._isSingleNumberReachServiceDisabled() || !this.ciscoZeroTouchProvisioningFieldConfig?.snr;
              },
              disabledTooltip: () =>
                this._isSingleNumberReachServiceDisabled()
                  ? 'tkey;pages.zero_touch.provisioning.services.snr.disabled.tooltip'
                  : 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
            },
            {
              label: 'tkey;shared.service.teams_snr.text',
              disabled: () => !this.ciscoZeroTouchProvisioningFieldConfig?.snrDestinationForMicrosoftTeams,
              disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
              tooltip: () =>
                this.ciscoZeroTouchProvisioningFieldConfig?.snrDestinationForMicrosoftTeams
                  ? 'tkey;shared.service.teams_snr.tooltip'
                  : '',
              // TODO: Un hide this checkbox when all the pieces of the epic is complete. See https://github.com/Stack8/smacs/issues/11648
              hidden: () => true,
            },
            {
              label: 'tkey;pages.zero_touch.provisioning.edit.form.extension_mobility',
              disabled: () => {
                return !this.ciscoZeroTouchProvisioningFieldConfig?.mobility;
              },

              disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
            },
            {
              label: 'tkey;pages.zero_touch.provisioning.edit.form.cipc',
              disabled: () => {
                return !this.ciscoZeroTouchProvisioningFieldConfig?.cipc;
              },
              disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
            },
            {
              label: 'tkey;pages.zero_touch.provisioning.edit.form.im_softphone',
              disabled: () => {
                return !this.ciscoZeroTouchProvisioningFieldConfig?.imSoftPhone;
              },
              disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
            },
            {
              label: 'tkey;pages.zero_touch.provisioning.edit.form.iphone',
              disabled: () => {
                return !this.ciscoZeroTouchProvisioningFieldConfig?.iphone;
              },
              disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
            },
            {
              label: 'tkey;pages.zero_touch.provisioning.edit.form.android',
              disabled: () => {
                return !this.ciscoZeroTouchProvisioningFieldConfig?.android;
              },
              disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
            },
            {
              label: 'tkey;pages.zero_touch.provisioning.edit.form.tablet',
              disabled: () => {
                return !this.ciscoZeroTouchProvisioningFieldConfig?.tablet;
              },
              disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
            },
            {
              label: 'tkey;pages.zero_touch_provisioning_edit.form.imp',
              disabled: () => {
                return !this.ciscoZeroTouchProvisioningFieldConfig?.imp;
              },
              disabledTooltip: () => 'tkey;pages.zero_touch.provisioning.services.disabled.tooltip',
            },
          ],
        }),
      },
      runAutomatically: {
        label: 'tkey;pages.zero_touch.edit.form.run_automatically',
        dataAutomation: 'zero-touch-run-automatically',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH }),
        hidden: () => !this.formData?.site,
      },
      scheduleFrequency: {
        label: 'tkey;pages.zero_touch.schedule',
        dataAutomation: 'zero-touch-schedule-frequency',
        required: () => this.formData.runAutomatically,
        hidden: () => !this.formData.runAutomatically,
        componentConfig: new SmacsRadioConfig({
          buttons: [
            {
              value: SCHEDULE_FREQUENCY.PERIODICALLY,
              label: 'tkey;pages.zero_touch.edit.form.periodically',
            },
            {
              value: SCHEDULE_FREQUENCY.DAILY,
              label: 'tkey;pages.zero_touch.edit.form.daily',
            },
          ],
          inline: true,
        }),
      },
      periodicJobMinutes: {
        label: 'tkey;pages.zero_touch.edit.form.every',
        dataAutomation: 'zero-touch-schedule-interval',
        required: () =>
          this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.PERIODICALLY && this.formData.runAutomatically,
        hidden: () =>
          this.formData.scheduleFrequency !== SCHEDULE_FREQUENCY.PERIODICALLY || !this.formData.runAutomatically,
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        helpText: () => this.nextRunTimesMessage,
        validation: [
          {
            validator: (val: number) => {
              if (
                !this.formData.runAutomatically ||
                this.formData.scheduleFrequency !== SCHEDULE_FREQUENCY.PERIODICALLY
              ) {
                return SmacsFormsValidationState.VALID;
              }

              return val >= 1 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
            },
            message: this.translateService.instant('tkey;validators.global.error.min', {
              minValue: 1,
            }),
          },
        ],
      },
      dailyJobTime: {
        label: 'tkey;pages.zero_touch.edit.form.daily_at_time',
        dataAutomation: 'zero-touch-schedule-time',
        required: () => this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.DAILY && this.formData.runAutomatically,
        hidden: () => this.formData.scheduleFrequency !== SCHEDULE_FREQUENCY.DAILY || !this.formData.runAutomatically,
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TIME }),
      },
      allOutcomeRecipients: {
        label: 'tkey;pages.zero_touch.edit.form.email.all_outcomes.label',
        dataAutomation: 'zero-touch-edit-all-outcomes',
        componentConfig: new SmacsMultiTextConfig({
          placeholder: 'tkey;pages.zero_touch.provisioning.edit_job.email.smacs_administrators.placeholder',
        }),
        helpText: () => 'tkey;pages.zero_touch.edit.form.email.all_outcomes.helpText',
        required: () => false,
        validation: [
          {
            validator: this._validators.toEmailValidator,
            message: 'tkey;validators.global.email.invalid.error',
          },
        ],
      },
      successRecipients: {
        label: 'tkey;pages.zero_touch.edit.form.email.only_successful_outcomes.label',

        dataAutomation: 'zero-touch-edit-successful-outcomes',
        componentConfig: new SmacsMultiTextConfig({
          placeholder: 'tkey;pages.zero_touch.provisioning.edit_job.email.call_center_supervisors.placeholder',
        }),
        helpText: () => 'tkey;pages.zero_touch.edit.form.email.only_successful_outcomes.helpText',
        required: false,
        validation: [
          {
            validator: this._validators.toEmailValidator,
            message: 'tkey;validators.global.email.invalid.error',
          },
        ],
      },
      failureRecipients: {
        label: 'tkey;pages.zero_touch.edit.form.email.only_unsuccessful_outcomes.label',
        dataAutomation: 'zero-touch-edit-unsuccessful-outcomes',
        componentConfig: new SmacsMultiTextConfig({
          placeholder: 'tkey;pages.zero_touch.provisioning.edit_job.email.uc_helpdesk.placeholder',
        }),
        helpText: () => 'tkey;pages.zero_touch.edit.form.email.only_unsuccessful_outcomes.helpText',
        validation: [
          {
            validator: this._validators.toEmailValidator,
            message: 'tkey;validators.global.email.invalid.error',
          },
        ],
        required: () => false,
      },
    },
  };

  private _formInitializedSource = new ReplaySubject<void>(1);
  private _subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private zeroTouchProvisioningResource: ZeroTouchProvisioningResource,
    private siteSummaryContext: SiteSummaryContext,
    private ldapResource: LdapResource,
    private bottomNavService: BottomNavService,
    private breadcrumbsService: BreadcrumbsService,
    private routeHelpersService: RouteHelpersService,
    private toastService: ToastService,
    private zeroTouchSchedulingService: ZeroTouchSchedulingService,
    protected smacsFormStateService: SmacsFormStateService,
    private translateService: TranslateService,
    private ciscoProvisioningStatusPollingService: CiscoProvisioningStatusPollingService,
    private emailSettingsContext: EmailSettingsContext,
    private _smacsModalService: SmacsModalService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.jobId = parseInt(this.route.snapshot.params.jobId) || null;

    if (this.jobId) {
      this.bottomNavButtons.splice(2, 0, {
        id: 'bottom-nav-delete-button',
        dataAutomation: 'bottom-nav-delete-button',
        label: 'tkey;global.button.delete.text',
        icon: SmacsIcons.DELETE,
        buttonClass: ButtonStyles.DANGER,
        cb: () => {
          this._onDeleteClicked();
        },
      });
    }

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));

    const emailSettingsSub = this.emailSettingsContext.state$.subscribe((state: EmailSettings) => {
      this.isEmailSectionEnabled = !!state.host && !!state.from;
    });

    const formUpdateSub = this.smacsFormsUpdate$.subscribe((update: SmacsFormsUpdate<ProvisioningScheduleFormData>) => {
      if (update?.new) {
        this._formInitializedSource.next();
        this._setIsDirty();
        if (update.new.site && update.new.site !== update.old?.site) {
          this._setDialPlanGroupsAndServicesForSite(update.new.site);
        }

        if (update.new.periodicJobMinutes !== update.old?.periodicJobMinutes) {
          this._setRunTimeMessage(update.new.periodicJobMinutes);
        }

        if (!isMatch(update.new, update.old) && this._isPrimaryExtensionServiceDisabled() && !update.new.services.dn) {
          this.entitySource.next({
            ...this.formData,
            services: {
              ...this.formData.services,
              dn: true,
            },
          });
        }
        if (
          !isMatch(update.new, update.old) &&
          this._isSingleNumberReachServiceDisabled() &&
          !update.new.services.snr
        ) {
          this.entitySource.next({
            ...this.formData,
            services: {
              ...this.formData.services,
              snr: true,
            },
          });
        }
      }
    });
    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this.isErrorPresent.subscribe((hasError) => {
        if (hasError) {
          this._resetBottomNavButtonsState();
        }

        this.bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: hasError,
          })
        );
      });
    });

    this._subs.add(formSubmittedSub);
    this._subs.add(formUpdateSub);
    this._subs.add(emailSettingsSub);

    // The form has to be initialized before we can call applyComponentConfig or our new data will be replaced by
    // what we are initially passing to formConfig
    this._getSites()
      .pipe(
        switchMap(() => this._getJob()),
        switchMap(() => this._formInitializedSource.asObservable())
      )
      .subscribe(() => {
        this._formInitializedSource.complete();
        this._setSiteOptions();
        if (this.jobId) {
          this._setDialPlanGroupsAndServicesForSite(this.job.options.siteId);
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subs.unsubscribe();
    this.ciscoProvisioningStatusPollingService.stopPolling();
  }

  switchEditView($event: MouseEvent, newJobEditView: JobEditViews) {
    $event.preventDefault();

    if (this.jobEditView === newJobEditView || this.generalSettingsFormIsDirty || !this.jobId) {
      return;
    }

    this.jobEditView = newJobEditView;

    if (newJobEditView === JobEditViews.IGNORED_USERS) {
      this.isLoading = true;
      this._searchLdapUsers(this.formData.ldapFilter || this.job.ldapFilter).subscribe(
        (ldapUserResults: LdapUserResult[]) => {
          this.isLoading = false;
          this.ldapUserResults = ldapUserResults;
        }
      );

      this._refreshIgnoredUsersList();
    }
  }

  onIgnoredUsersUpdate($event: LdapUserResult[]) {
    this.ignoredUsers = $event;

    const ignoredUsersNames = this.ignoredUsers.map((u) => u.userId);
    const initialIgnoredUsersNames = this.initialIgnoredUsers.map((u) => u.userId);
    this.ignoredUsersFormIsDirty = !isEqual(ignoredUsersNames, initialIgnoredUsersNames);
  }

  cardSwitchToggled(switchData: CollapsibleCardSwitchValue): void {
    if (switchData.switchId === 'emailNotifications') {
      this.emailSectionToggle = switchData.isChecked;
    }
  }

  private _isLdapFilterValid(ldapFilter: string): Observable<SmacsFormsValidationState> {
    this.ldapFilterState = ViewMembersState.SEARCHING;
    return new Observable<SmacsFormsValidationState>((subscriber: Subscriber<SmacsFormsValidationState>) => {
      if (!ldapFilter) {
        this.ldapFilterMatches = [];
        this.ldapFilterState = ViewMembersState.IDLE;
        this.ldapFilterViewMembersDisabled = false;
        subscriber.next(SmacsFormsValidationState.VALID);
        subscriber.complete();
      } else {
        this._searchLdapUsers(ldapFilter).subscribe({
          next: (ldapUserResults: LdapUserResult[]) => {
            this.ldapFilterMatches = ldapUserResults.map((ldapResult: LdapUserResult) => {
              return ldapResult.userId;
            });
            this.ldapFilterState = ViewMembersState.IDLE;
            this.ldapFilterViewMembersDisabled = false;
            subscriber.next(SmacsFormsValidationState.VALID);
            subscriber.complete();
          },
          error: (e) => {
            this.ldapFilterMatches = [];
            if (
              e.error.description.includes('Unbalanced parenthesis;') ||
              e.error.description.includes(`Missing 'equals';`) ||
              e.error.description.includes(`Exception on LDAP search: invalid LDAP search filter`)
            ) {
              this.ldapFilterState = ViewMembersState.IDLE;
              this.ldapFilterMatches = [];
              this.ldapFilterViewMembersDisabled = true;
              subscriber.next(SmacsFormsValidationState.INVALID);
              subscriber.complete();
            }
          },
        });
      }
    });
  }

  private _setIsDirty(isRunning = false) {
    this.generalSettingsFormIsDirty = this.ldapFilterState === ViewMembersState.SEARCHING;

    if (this.job && this.formData) {
      const formData = this._formDataToEntity();
      const jobCopy = { ...this.job };
      delete jobCopy.id;

      if (!this.emailSectionToggle) {
        jobCopy.emailRecipients = formData.emailRecipients;
      }

      if (!isMatch(jobCopy, formData)) {
        this.generalSettingsFormIsDirty = true;
      }

      if (isRunning) {
        this._setButtonState('runNowZeroTouchProvisioningForm', true, true, false);
      } else if (this.generalSettingsFormIsDirty && !this.allowRunNowWithEmailDisabled) {
        this._setButtonState('runNowZeroTouchProvisioningForm', true, false, true);
      } else {
        this._setButtonState('runNowZeroTouchProvisioningForm', false, false, false);
      }
    }
  }

  private _getSites(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      const siteSummarySub = this.siteSummaryContext.state$.subscribe((summary: SiteSummary) => {
        let sites: SiteResult[] = [];

        summary.clusters.forEach((cluster: ClusterResult) => {
          sites = sites.concat(cluster.sites);
        });

        this.sites = [...sites];
        subscriber.next();
        subscriber.complete();
      });
      this._subs.add(siteSummarySub);
    });
  }

  private _setButtonState(buttonId: string, disabled: boolean, pending: boolean, tooltipVisible: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: {
          pending: pending,
          buttonDisableState: { disabled: disabled, tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip.disabled' },
          tooltipVisible: tooltipVisible,
        },
      })
    );
  }

  private _getJob(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      if (this.jobId) {
        this.zeroTouchProvisioningResource.get(this.jobId).subscribe((job: ProvisioningSchedule) => {
          this.job = job;
          this.breadcrumbsService.updateBreadcrumbs([
            { label: 'tkey;pages.zero_touch.provisioning.title', url: '/app2/#/automate/provisioning' },
            { label: job.name },
          ]);
          this.entitySource.next({
            name: job.name,
            ldapFilter: job.ldapFilter,
            site: job.options.siteId,
            dialPlanGroups: job.options.dialPlanGroupIds,
            services: {
              dn: job.options.dn,
              agentExtension: job.options.agentExtension,
              voicemail: job.options.voicemail,
              snr: job.options.snrProfile,
              snrDestinationForMicrosoftTeams: job.options.snrDestinationForMicrosoftTeams,
              mobility: job.options.mobility,
              cipc: job.options.cipc,
              imSoftPhone: job.options.imSoftPhone,
              iphone: job.options.iphone,
              android: job.options.android,
              tablet: job.options.tablet,
              imp: job.options.imp,
            },
            dailyJobTime: job.dailyJobTime,
            periodicJobMinutes: job.periodicJobMinutes,
            runAutomatically:
              this._getScheduleFrequency(job) === SCHEDULE_FREQUENCY.PERIODICALLY ||
              this._getScheduleFrequency(job) === SCHEDULE_FREQUENCY.DAILY,
            scheduleFrequency: this._getScheduleFrequency(job),
            allOutcomeRecipients: job.emailRecipients?.allOutcomeRecipients || [],
            failureRecipients: job.emailRecipients?.failureRecipients || [],
            successRecipients: job.emailRecipients?.successRecipients || [],
          } as ProvisioningScheduleFormData);
          this._startPollingForStatuses();
          this._refreshIgnoredUsersList();
          if (this.job.periodicJobMinutes) {
            this._setRunTimeMessage(this.job.periodicJobMinutes);
          }

          this.emailSectionShouldBeOpenOnLoad =
            !!this.job.emailRecipients?.successRecipients.length ||
            !!this.job.emailRecipients?.failureRecipients.length ||
            !!this.job.emailRecipients?.allOutcomeRecipients.length;
          if (this.emailSectionShouldBeOpenOnLoad) {
            this.cardSwitchToggled({ switchId: 'emailNotifications', isChecked: true });
          }
          this._setButtonState('saveZeroTouchProvisioningForm', false, false, false);
          this._setButtonState('runNowZeroTouchProvisioningForm', false, false, false);
          this.isLoading = false;
          subscriber.next();
          subscriber.complete();
        });
      } else {
        this.bottomNavService.dispatch(
          new BottomNavUpdateButtonState({
            id: 'runNowZeroTouchProvisioningForm',
            state: {
              buttonDisableState: { disabled: true, tooltipKey: '' },
              tooltipVisible: true,
            },
          })
        );
        this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));
        this.breadcrumbsService.updateBreadcrumbs([
          { label: 'tkey;pages.zero_touch.provisioning.title', url: '/app2/#/automate/provisioning' },
          { label: 'tkey;pages.zero_touch.job.edit.breadcrumb.new_job' },
        ]);
        this.entitySource.next({
          name: '',
          ldapFilter: '',
          site: null,
          dialPlanGroups: [],
          services: {
            dn: false,
            agentExtension: false,
            voicemail: false,
            snr: false,
            snrDestinationForMicrosoftTeams: false,
            mobility: false,
            cipc: false,
            imSoftPhone: false,
            iphone: false,
            android: false,
            tablet: false,
            imp: false,
          },

          dailyJobTime: null,
          periodicJobMinutes: null,
          runAutomatically: null,
          scheduleFrequency: null,
          allOutcomeRecipients: [],
          failureRecipients: [],
          successRecipients: [],
        } as ProvisioningScheduleFormData);

        this._setButtonState('saveZeroTouchProvisioningForm', false, false, false);
        this._setButtonState('runNowZeroTouchProvisioningForm', true, false, true);

        this.isLoading = false;
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  private _getScheduleFrequency(job: ProvisioningSchedule): SCHEDULE_FREQUENCY {
    if (job.dailyJobTime) {
      return SCHEDULE_FREQUENCY.DAILY;
    } else if (job.periodicJobMinutes) {
      return SCHEDULE_FREQUENCY.PERIODICALLY;
    }

    return null;
  }

  private _refreshIgnoredUsersList() {
    if (this.jobId) {
      this.zeroTouchProvisioningResource.getIgnoredUsers(this.jobId).subscribe((ldapUserIds: LdapUserId[]) => {
        this.ignoredUsers = ldapUserIds;
        this.initialIgnoredUsers = ldapUserIds;
      });
    }
  }

  private _searchLdapUsers(filter: string): Observable<LdapUserResult[]> {
    return this.ldapResource.searchUsers(filter, 0);
  }

  private _setDialPlanGroupsAndServicesForSite(siteId: number) {
    this.zeroTouchProvisioningResource
      .getCiscoZeroTouchProvisioningFieldConfigs(siteId)
      .subscribe((ciscoZeroTouchProvisioningFieldConfig: CiscoZeroTouchProvisioningFieldConfig) => {
        this.ciscoZeroTouchProvisioningFieldConfig = ciscoZeroTouchProvisioningFieldConfig;
        // Check that services that are enabled are valid
        const updatedServices = {} as SiteServices;
        forOwn(this.formData.services, (val: boolean, key: keyof SiteServices) => {
          if (!ciscoZeroTouchProvisioningFieldConfig[key] && val) {
            updatedServices[key] = false;
          } else {
            updatedServices[key] = val;
          }
        });

        // Setup dial plan groups select options, and check that selected dial plan group is still valid
        const dialPlanGroupSelectOptions: SmacsSelectOption[] = [];
        forOwn(ciscoZeroTouchProvisioningFieldConfig.dialPlanGroups, (val: string, key: string) => {
          dialPlanGroupSelectOptions.push({
            label: val,
            value: parseInt(key),
          });
        });

        const dialPlanGroupSelect = this.fieldComponents.find((field) => field.fieldId === 'dialPlanGroups');
        dialPlanGroupSelect.applyComponentConfig(
          new SmacsSelectConfig({
            options: dialPlanGroupSelectOptions,
            isMultiSelect: true,
            bindValue: 'value',
          })
        );
        const dialPlanGroupOptionIds = dialPlanGroupSelectOptions.map((smacsSelectOption: SmacsSelectOption) => {
          return smacsSelectOption.value;
        });
        const validDialPlanGroups = this.formData.dialPlanGroups.filter((smacsSelectOption: number) => {
          if (dialPlanGroupOptionIds.includes(smacsSelectOption)) {
            return smacsSelectOption;
          }
        });
        if (
          validDialPlanGroups.length !== this.formData.dialPlanGroups.length ||
          !isEqual(updatedServices, this.formData.services)
        ) {
          this.entitySource.next({
            ...this.formData,
            dialPlanGroups: validDialPlanGroups,
            services: updatedServices,
          } as ProvisioningScheduleFormData);
        }
      });
  }

  private _setSiteOptions() {
    const siteSelectOptions = this.sites.map((s: SiteResult) => {
      return {
        label: s.name,
        value: s.id,
      };
    }) as SmacsSelectOption[];
    const siteSelect = this.fieldComponents.find((field) => field.fieldId === 'site');
    siteSelect.applyComponentConfig(new SmacsSelectConfig({ options: siteSelectOptions, bindValue: 'value' }));
  }

  private _isPrimaryExtensionServiceDisabled = (): boolean => {
    const services = this.formData.services;
    return (
      services.agentExtension ||
      services.mobility ||
      services.snr ||
      services.voicemail ||
      services.imSoftPhone ||
      services.iphone ||
      services.android ||
      services.tablet ||
      services.snrDestinationForMicrosoftTeams
    );
  };

  private _isSingleNumberReachServiceDisabled = (): boolean => {
    const services = this.formData.services;
    return services.snrDestinationForMicrosoftTeams;
  };

  private _setRunTimeMessage(periodicJobMinutes: number) {
    if (!periodicJobMinutes || periodicJobMinutes < 1) {
      this.nextRunTimesMessage = '';
    } else {
      this.nextRunTimesMessage = this.zeroTouchSchedulingService.getNextRunTimeMessage(periodicJobMinutes.toString());
    }
  }

  protected submit() {
    return this._saveJob();
  }

  private _submitForm() {
    this.isSaving = true;
    this._setButtonState('saveZeroTouchProvisioningForm', true, true, false);
    this._setButtonState('runNowZeroTouchProvisioningForm', true, false, false);
    this._setButtonState('cancelZeroTouchProvisioningForm', true, false, false);
    this._validateAndSubmitSource.next(true);
  }

  private _saveJob(): Observable<ProvisioningSchedule> {
    const isScheduledDaily = this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.DAILY;
    const isScheduledPeriodically = this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.PERIODICALLY;
    const job = {
      ...this._formDataToEntity(),
      dailyJobTime: isScheduledDaily && this.formData.runAutomatically ? this.formData.dailyJobTime : null,
      periodicJobMinutes:
        isScheduledPeriodically && this.formData.runAutomatically ? this.formData.periodicJobMinutes : null,
      emailRecipients:
        this.isEmailSectionEnabled && this.emailSectionToggle
          ? {
              allOutcomeRecipients: this.formData.allOutcomeRecipients,
              failureRecipients: this.formData.failureRecipients,
              successRecipients: this.formData.successRecipients,
            }
          : null,
    };
    const saveJob$ = this.jobId
      ? this.zeroTouchProvisioningResource.put(this.jobId, { id: this.jobId, ...job })
      : this.zeroTouchProvisioningResource.post(job);

    return saveJob$.pipe(
      tap(() => {
        this.toastService.pushSaveToast('tkey;pages.zero_touch.provisioning.title', job.name, this.smacsIcons.ARCHIVE);
        this.isSaving = false;
        this._resetBottomNavButtonsState();
        this.job = { id: this.jobId, ...job };
        this.allowRunNowWithEmailDisabled = !this.isEmailSectionEnabled || !this.emailSectionToggle;
        this._setIsDirty();

        if (!this.jobId) {
          this.routeHelpersService.goToParent(this.route, 'jobId');
        }
      }),
      catchError((response: HttpErrorResponse) => {
        this._resetBottomNavButtonsState();
        return throwError(() => response);
      })
    );
  }

  private _runNow() {
    if (this.jobId) {
      this.isRunning = true;
      this._setButtonState('saveZeroTouchProvisioningForm', true, false, true);
      this._setButtonState('runNowZeroTouchProvisioningForm', true, true, false);
      this._setButtonState('cancelZeroTouchProvisioningForm', true, false, true);

      this.zeroTouchProvisioningResource.runJob(this.jobId).subscribe({
        next: () => {
          this._refreshIgnoredUsersList();
        },
        error: (e) => {
          console.warn(e);
        },
      });
    }
  }

  private _startPollingForStatuses() {
    if (this.jobId) {
      this.ciscoProvisioningStatusPollingService.setBaseUrl(this.jobId.toString());
      this.ciscoProvisioningStatusPollingService.startPolling();
      this.ciscoProvisioningStatusPollingService.state$.subscribe((status: JobStatus) => {
        if ((status.jobState === JobState.RUNNING || status.jobState === JobState.QUEUED) && !this.isSaving) {
          this._setButtonState('saveZeroTouchProvisioningForm', true, false, true);
          this._setIsDirty(true);
          this._setButtonState('cancelZeroTouchProvisioningForm', true, false, true);
        } else if (!this.isSaving) {
          this._setButtonState('saveZeroTouchProvisioningForm', false, false, false);
          this._setIsDirty();
          this._setButtonState('cancelZeroTouchProvisioningForm', false, false, false);

          if (this.isRunning) {
            this.isRunning = false;
            this.toastService.push(
              ToastTypes.SUCCESS,
              this.smacsIcons.AUTOMATION,
              'tkey;pages.zero_touch.job.completed',
              this.job.name
            );
          }
        }
      });
    }
  }

  private _formDataToEntity(): ProvisioningSchedule {
    return {
      dailyJobTime: this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.DAILY ? this.formData.dailyJobTime : null,
      ldapFilter: this.formData.ldapFilter,
      name: this.formData.name,
      periodicJobMinutes:
        this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.PERIODICALLY ? this.formData.periodicJobMinutes : null,
      options: {
        agentExtension: this.formData.services ? this.formData.services.agentExtension : false,
        android: this.formData.services ? this.formData.services.android : false,
        cipc: this.formData.services ? this.formData.services.cipc : false,
        dialPlanGroupIds: this.formData.dialPlanGroups ? this.formData.dialPlanGroups : [],
        dn: this.formData.services ? this.formData.services.dn : false,
        imSoftPhone: this.formData.services ? this.formData.services.imSoftPhone : false,
        imp: this.formData.services ? this.formData.services.imp : false,
        iphone: this.formData.services ? this.formData.services.iphone : false,
        mobility: this.formData.services ? this.formData.services.mobility : false,
        siteId: this.formData.site ? this.formData.site : null,
        snrProfile: this.formData.services ? this.formData.services.snr : false,
        tablet: this.formData.services ? this.formData.services.tablet : false,
        voicemail: this.formData.services ? this.formData.services.voicemail : false,
        snrDestinationForMicrosoftTeams: this.formData.services
          ? this.formData.services.snrDestinationForMicrosoftTeams
          : false,
      },
      emailRecipients: {
        allOutcomeRecipients: this.formData.allOutcomeRecipients,
        failureRecipients: this.formData.failureRecipients,
        successRecipients: this.formData.successRecipients,
      },
    } as ProvisioningSchedule;
  }

  private _saveIgnoredUsers() {
    this.isSaving = true;
    this._setButtonState('saveZeroTouchProvisioningForm', true, true, false);
    this._setButtonState('runNowZeroTouchProvisioningForm', true, false, false);
    this._setButtonState('cancelZeroTouchProvisioningForm', true, false, false);

    this.zeroTouchProvisioningResource.saveIgnoredUsers(this.jobId, this.ignoredUsers).subscribe(() => {
      this._resetBottomNavButtonsState();
      this.isSaving = false;

      this.initialIgnoredUsers = this.ignoredUsers;
      this.ignoredUsersFormIsDirty = false;

      this.toastService.pushSaveToast(
        'tkey;zero_touch.provisioning.edit_job.ignored_users',
        this.job.name,
        this.smacsIcons.ARCHIVE
      );
    });
  }

  private _resetBottomNavButtonsState() {
    this._setButtonState('saveZeroTouchProvisioningForm', false, false, false);
    this._setButtonState('runNowZeroTouchProvisioningForm', false, false, false);
    this._setButtonState('cancelZeroTouchProvisioningForm', false, false, false);
  }

  private _onDeleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        promptBody: this.translateService.instant('tkey;pages.zero_touch.modal_delete.body', {
          name: this.entity.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteGroup();
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteGroup(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this.zeroTouchProvisioningResource.delete(this.jobId).subscribe(() => {
        this.toastService.pushDeleteToast('tkey;shared.toast.delete.success.title', this.entity.name);
        this.router.navigate(['/automate/provisioning']);
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }
}
