import { Injectable } from '@angular/core';
import { RangeType } from './models/generated/smacsModels';

@Injectable()
export class ValidatorsService {
  static isSipUri(value: string) {
    return /^.+@.+$/.test(value);
  }

  static isPhoneNumber(value: string) {
    return /^[0-9*#+]{1,24}$/i.test(value);
  }

  static destinationFormatValidationFn(value: string) {
    return !value || (!!value && (ValidatorsService.isSipUri(value) || ValidatorsService.isPhoneNumber(value)));
  }

  static callForwardFormatValidationFn(value: string) {
    return /^[0-9*#X+]*$/.test(value);
  }

  static removeFormattingFromSingleNumber(val: string, toRemove = [' ', '-', '(', ')']) {
    toRemove.forEach((item: string) => {
      val = val.replaceAll(item, '');
    });

    return val;
  }

  static removeFormattingFromBulkRanges(val: string, rangeType?: RangeType) {
    const cleanedVal = ValidatorsService.removeFormattingFromSingleNumber(val, [' ', '(', ')']);
    const ranges = cleanedVal.split(/[,\n\r]+/);
    const formattedRanges = ranges.map((rangeVal: string) => {
      const numbers =
        !!rangeType && rangeType === RangeType.EXTENSION
          ? rangeVal.replace('-', '!!!!!').split('!!!!!')
          : rangeVal.replace('-+', '!!!!!+').split('!!!!!');
      const mappedNumbers = numbers.map((number: string) =>
        ValidatorsService.removeFormattingFromSingleNumber(number, ['-'])
      );
      return mappedNumbers.length > 1 ? mappedNumbers.join('-') : mappedNumbers[0];
    });

    return formattedRanges.join(',');
  }
}
