<div class="smacs-admin-page-content" *ngIf="isReady">
  <div class="col-md-10 offset-md-1 smacs-report-content">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;reporting.call_logs.description' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <smacs-call-logs-query-editor
      [config]="callLogsQueryEditorData"
      (callLogQueryResultUpdated)="handleQueryUpdate($event)"
    ></smacs-call-logs-query-editor>
    <smacs-datatable
      [columns]="columns"
      [data]="data"
      [mainFilterPlaceholder]="'tkey;reporting.call_logs.datatable.search' | translate"
      [dataAutomation]="tableDataAutomation"
    >
      <smacs-datatable-multi-select-filter
        [label]="'tkey;reporting.call_logs.datatable.columns.endCause'"
        [items]="endCauses"
        [matchFn]="doesRowCatchEndCauseFilter"
        [dataAutomation]="'call-logs-end-cause-filter'"
        [tableId]="tableDataAutomation"
      ></smacs-datatable-multi-select-filter>
    </smacs-datatable>
  </div>
</div>
<app-bottom-nav data-automation="call-logs-bottom-nav"></app-bottom-nav>
<app-loading-block [hidden]="isReady"></app-loading-block>
