import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { concatMap, map, mergeMap, tap } from 'rxjs/operators';
import { CustomPowershellReport } from '../models/generated/smacsModels';
import { FileDownloadService } from '../services/file-download.service';

@Injectable()
export class CustomPowershellReportContext {
  private _stateSource = new ReplaySubject<CustomPowershellReport[]>(1);
  state$ = this._stateSource.asObservable();

  private _baseUrl = '/services/microsoft/reporting/custom-powershell-reports';

  constructor(private _http: HttpClient, private _fileDownloadService: FileDownloadService) {
    this._init().subscribe();
  }

  create(report: CustomPowershellReport): Observable<number> {
    return this._http.post<number>(this._baseUrl, report).pipe(
      mergeMap((reportId: number) => {
        return this._init().pipe(map(() => reportId));
      }),
      map((reportId) => reportId)
    );
  }

  update(report: CustomPowershellReport): Observable<number> {
    return this._http.put<number>(`${this._baseUrl}/${report.id}`, report).pipe(
      mergeMap(() => this._init()),
      map(() => report.id)
    );
  }

  delete(reportId: number): Observable<void> {
    return this._http.delete<void>(`${this._baseUrl}/${reportId}`).pipe(concatMap(() => this._init()));
  }

  test(query: string): Observable<any> {
    return this._http.post<void>(`${this._baseUrl}/live-queries`, { query });
  }

  export(reportId: number): Observable<void> {
    return this._fileDownloadService.downloadFile(`${this._baseUrl}/${reportId}/exports`);
  }

  private _init(): Observable<void> {
    return this._get().pipe(
      tap((data: CustomPowershellReport[]) => this._stateSource.next(data)),
      map(() => null)
    );
  }

  private _get(): Observable<CustomPowershellReport[]> {
    return this._http.get<CustomPowershellReport[]>(this._baseUrl);
  }
}
