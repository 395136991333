import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmacsToastComponent } from './toast.component';

@NgModule({
  declarations: [SmacsToastComponent],
  exports: [SmacsToastComponent],
  imports: [CommonModule],
})
export class ZiroToastModule {}
