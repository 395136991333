<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.phone_button_templates.description' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <fieldset *ngIf="table" [disabled]="isSaving" data-automation="default-phone-button-template-entity-table">
      <app-entity-table
        [table]="table"
        [tableRows]="tableRows"
        (fieldChange)="onTemplateChange($event)"
      ></app-entity-table>
    </fieldset>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
