<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <p
          [innerHtml]="
            isHostedEnabled
              ? ('tkey;admin.license.description.header_zpm' | translate)
              : ('tkey;admin.license.description.header_zpc' | translate)
          "
        ></p>
        <p
          [innerHtml]="
            isHostedEnabled
              ? ('tkey;admin.license.description.text.hosted_true' | translate)
              : ('tkey;admin.license.description.text.hosted_false' | translate)
          "
          class="mb-0"
        ></p>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div
      [ngClass]="{
        'alert-warning': licenseHealthStatus.state === state.WARNING,
        'alert-danger': licenseHealthStatus.state === state.ERROR
      }"
      class="alert"
      data-automation="license-alert"
      *ngIf="licenseStatusMessage.title"
    >
      <h4>{{ licenseStatusMessage.title | translate }}</h4>
      <div [innerHTML]="licenseStatusMessage.content | translate"></div>
    </div>

    <div class="col-sm-12 p-0">
      <div *ngIf="!isLoading && licenseStatusCause !== 'No license installed'">
        <table class="table mb-4 table-striped" data-automation="license-details">
          <tbody>
            <tr
              [ngClass]="{
                'bg-info': licenseHealthStatus?.state === state.OK,
                'bg-danger': licenseHealthStatus?.state === state.ERROR,
                'bg-warning': licenseHealthStatus?.state === state.WARNING
              }"
            >
              <th colspan="2" class="text-white">{{ 'tkey;admin.license.module.license_details.text' | translate }}</th>
            </tr>
            <tr>
              <td>
                <strong>{{ 'tkey;admin.license.company.text' | translate }}</strong>
              </td>
              <td class="text-end" data-automation="license-company">
                <code>{{ licenseInfo?.company }}</code>
              </td>
            </tr>
            <tr>
              <td>
                <strong>{{ 'tkey;admin.license.total_licenses.text' | translate }}</strong>
              </td>
              <td class="text-end" data-automation="license-total">
                <code>{{ licenseInfo?.totalLicenses }}</code>
              </td>
            </tr>
            <tr
              data-automation="license-used-wrapper"
              [class.table-warning]="licenseStatusCause === 'Exceeded number of licenses'"
            >
              <td>
                <strong>{{ 'tkey;admin.license.used_licenses.text' | translate }}</strong>
              </td>
              <td class="text-end" data-automation="license-used">
                <code>{{ licenseInfo?.totalUsedLicenses }}</code>
              </td>
            </tr>
            <tr
              data-automation="license-expiry-date-wrapper"
              [class.table-danger]="licenseStatusCause === 'License is expired'"
            >
              <td>
                <strong>{{ 'tkey;admin.license.expiry_date.text' | translate }}</strong>
              </td>
              <td class="text-end" data-automation="license-expiry-date">
                <code *ngIf="licenseInfo?.expiryDate">{{ licenseInfo?.expiryDate }}</code>
                <code *ngIf="!licenseInfo?.expiryDate">
                  {{ 'tkey;admin.license.expiry_date.perpetual.text' | translate }}
                </code>
              </td>
            </tr>
          </tbody>
        </table>

        <table *ngIf="!isHostedEnabled" class="table mb-4 table-striped" data-automation="license-ziro-modules">
          <tbody>
            <tr class="bg-info">
              <th colspan="2" class="text-white">{{ 'tkey;admin.license.module.text' | translate }}</th>
            </tr>
            <tr
              smacs-license-module-row
              data-automation="license-smacs-enabled"
              [moduleEnabled]="licenseInfo?.modules.provisioning"
              [moduleName]="'tkey;admin.license.module.manage.text'"
            ></tr>
            <tr
              smacs-license-module-row
              data-automation="license-zero-touch-enabled"
              [moduleEnabled]="licenseInfo?.modules.automation"
              [moduleName]="'tkey;admin.license.module.automate.text'"
            ></tr>
            <tr
              smacs-license-module-row
              data-automation="license-reports-enabled"
              [moduleEnabled]="licenseInfo?.modules.reporting"
              [moduleName]="'tkey;admin.license.module.control.text'"
            ></tr>
            <tr
              smacs-license-module-row
              data-automation="license-high-availability-enabled"
              [moduleEnabled]="licenseInfo?.modules.highAvailability"
              [moduleName]="'tkey;admin.license.module.high_availability.text'"
            ></tr>
            <tr
              smacs-license-module-row
              data-automation="license-migrate-to-teams-enabled"
              [moduleEnabled]="licenseInfo?.modules.proxyServerOnlyMode"
              [moduleName]="'tkey;admin.license.module.proxy_server.text'"
            ></tr>
          </tbody>
        </table>

        <table
          *ngIf="!isHostedEnabled && licenseInfo?.consumedCucmLicenseCountBreakdown?.length >= 2"
          class="table mb-4 table-striped"
          data-automation="detailed-license-usage"
        >
          <tbody>
            <tr class="bg-info">
              <th colspan="2" class="text-white">{{ 'tkey;admin.license.detailed_license_usage.text' | translate }}</th>
            </tr>
            <tr
              data-automation="license-detail-cisco-row"
              *ngFor="let ciscoUsage of cucmLicenseUsage; trackBy: trackByName"
            >
              <td data-automation="license-detail-cisco-row-name">
                <strong>{{ ciscoUsage.cucmDescription }}</strong>
              </td>
              <td data-automation="license-detail-cisco-row-count" class="text-end">
                <strong>{{ ciscoUsage.totalLicenses }}</strong>
              </td>
            </tr>
          </tbody>
        </table>

        <br />
      </div>
      <smacs-file-upload [fieldId]="'licenseFileUpload'"></smacs-file-upload>
    </div>
  </div>
</div>

<app-bottom-nav data-automation="license-import-button"></app-bottom-nav>
