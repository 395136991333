<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.microsoft.license_management.about' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div *ngIf="!isLoading">
      <app-microsoft-license-management-form
        [entity]="formEntity"
        [usageLocationOptions]="usageLocationOptions"
        [licensesOptions]="licensesOptions"
        [ms365Licenses]="ms365Licenses"
      ></app-microsoft-license-management-form>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
