<smacs-select [fieldId]="'type'"></smacs-select>

<smacs-phone-buttons-speed-dial
  #form
  *ngIf="phoneButtonsService.isSpeedDialButton(entity)"
  [entity]="entity"
  [parentSubmit$]="parentSubmit$"
  (smacsFormsUpdate$)="handleButtonChange($event)"
></smacs-phone-buttons-speed-dial>
<smacs-phone-buttons-blf
  #form
  *ngIf="phoneButtonsService.isBlfButton(entity)"
  [entity]="entity"
  [parentSubmit$]="parentSubmit$"
  (smacsFormsUpdate$)="handleButtonChange($event)"
></smacs-phone-buttons-blf>
<smacs-phone-buttons-service-url-helpdesk
  #form
  *ngIf="phoneButtonsService.isServiceUrlButton(entity) && userMode === DragDropMode.HELPDESK"
  [entity]="entity"
  [parentSubmit$]="parentSubmit$"
  (smacsFormsUpdate$)="handleButtonChange($event)"
  [phone]="phone"
></smacs-phone-buttons-service-url-helpdesk>
<smacs-phone-buttons-intercom
  #form
  *ngIf="phoneButtonsService.isIntercomButton(entity) && userMode === DragDropMode.HELPDESK"
  [entity]="entity"
  [parentSubmit$]="parentSubmit$"
  (smacsFormsUpdate$)="handleButtonChange($event)"
></smacs-phone-buttons-intercom>
<ng-container *ngIf="phoneButtonsService.isLineButton(entity)">
  <smacs-phone-buttons-line-helpdesk
    #form
    *ngIf="userMode === DragDropMode.HELPDESK"
    [lineFeature]="$any(entity).lineFeature"
    [dnRef]="$any(entity).dn"
    [parentSubmit$]="parentSubmit$"
    [fieldConfig]="lineFieldConfig"
    [phone]="phone"
    [siteId]="siteId"
    [lineButtonSummary]="lineButtonSummary"
    [buttonIndex]="buttonIndex"
    [global360View]="global360View"
    (lineButtonWasUpdated)="handleButtonChange($event)"
    (lineButtonSummaryWasUpdated)="handleVoicemailChange($event)"
    (dnUpdated)="handleDnChange($event, $any(entity).dn)"
  ></smacs-phone-buttons-line-helpdesk>
  <smacs-phone-buttons-line
    #form
    *ngIf="userMode === DragDropMode.SELF_SERVE"
    [entity]="entity"
    [parentSubmit$]="parentSubmit$"
    (smacsFormsUpdate$)="handleButtonChange($event)"
  ></smacs-phone-buttons-line>
</ng-container>
