import { Component, Input } from '@angular/core';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../../shared/entity-table/entity-table.models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ziro-zpm-bulk-provisioning-status-entity-table',
  templateUrl: './zpm-bulk-provisioning-status-entity-table.component.html',
})
export class ZpmBulkProvisioningStatusEntityTableComponent {
  @Input() tableRows: EntityTableContentRow[] = [];
  @Input() isViewLoading = true;

  table: EntityTable = {
    columns: [
      {
        columnId: 'icon',
        cssColumnSize: 'col-sm-1',
        cssClass: 'justify-content-center',
        label: '',
      },
      {
        columnId: 'displayName',
        cssColumnSize: 'col-sm-4',
        label: 'tkey;automate.zpm.bulk_provisioning.previous_job.table.display_name',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'upn',
        cssColumnSize: 'col-sm-4',
        label: 'tkey;automate.zpm.bulk_provisioning.previous_job.table.upn',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'processingStatus',
        cssColumnSize: 'col-sm-2',
        label: 'tkey;automate.zpm.bulk_provisioning.previous_job.table.processing_status',
        filter: {
          type: EntityTableFilterTypes.SELECT,
          options: [
            this._translateService.instant('tkey;automate.zpm.bulk_provisioning.previous_job.table.status.in_progress'),
            this._translateService.instant('tkey;automate.zpm.bulk_provisioning.previous_job.table.status.queued'),
            this._translateService.instant('tkey;automate.zpm.bulk_provisioning.previous_job.table.status.success'),
            this._translateService.instant('tkey;automate.zpm.bulk_provisioning.previous_job.table.status.failed'),
          ],
        },
      },
    ],
    hasActions: true,
  };

  constructor(private _translateService: TranslateService) {}
}
