<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="pb-2">
    <div [hidden]="isLoading" class="col-md-10 offset-md-1 animated fadeIn">
      <app-breadcrumbs>
        <app-breadcrumb-card-item>
          <div [innerHTML]="'tkey;admin.saml.description' | translate"></div>
        </app-breadcrumb-card-item>
      </app-breadcrumbs>

      <div *ngIf="!isHostedEnabled" data-automation="saml-alert-text" class="alert alert-info">
        <span [innerHTML]="'tkey;admin.saml.alert.text' | translate"></span>
      </div>
      <div class="mb-3 list-group">
        <div class="list-group-item">
          <h5>{{ 'tkey;admin.saml.sp.title' | translate }}</h5>
          <div class="row">
            <div class="col-lg-7">
              <p [innerHTML]="'tkey;admin.saml.sp.description' | translate"></p>
            </div>
            <div class="col-lg-5">
              <div class="align-items-center d-md-flex justify-content-end d-block">
                <p *ngIf="samlSpMetaDataTimestamp.timestamp && samlSpDate" class="animated fadeIn m-0 p-1 pe-3">
                  <i class="fa fa-clock-o"></i>
                  <span class="ms-1" data-automation="saml-sp-timestamp">
                    {{ 'tkey;admin.saml.timestamp.label.exported' | translate }}
                    <span class="fw-bold">{{ samlSpDate | date }}</span>
                  </span>
                </p>
                <div class="d-flex flex-column justify-content-between">
                  <app-saml-export-btn
                    [label]="'tkey;admin.saml.button.sp.export'"
                    [dataAutomation]="'saml-sp-export-btn'"
                    [icon]="smacsIcons.EXPORT"
                    [event]="samlSpGetMetadataXML"
                  ></app-saml-export-btn>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="list-group-item bg-light">
          <h5>{{ 'tkey;admin.saml.idp.title' | translate }}</h5>
          <div class="row">
            <div class="col-lg-7">
              <p [innerHTML]="'tkey;admin.saml.idp.description' | translate"></p>
            </div>
            <div class="col-lg-5">
              <div class="align-items-center d-md-flex justify-content-end d-block">
                <p *ngIf="samlIdpMetaDataTimestamp.timestamp && samlIdpDate" class="animated fadeIn m-0 p-1 pe-3">
                  <i class="fa fa-clock-o"></i>
                  <span class="ms-1" data-automation="saml-idp-timestamp">
                    {{ 'tkey;admin.saml.timestamp.label.imported' | translate }}
                    <span class="fw-bold">{{ samlIdpDate | date }}</span>
                  </span>
                </p>
                <div class="d-flex flex-column justify-content-between">
                  <app-saml-import-btn
                    class="mb-1"
                    [label]="'tkey;admin.saml.button.idp.import'"
                    [dataAutomation]="'saml-idp-import-btn'"
                    [uploadEvent]="samlIdpUpload"
                    [fileExtension]="'.xml'"
                    [icon]="smacsIcons.IMPORT"
                    [iconPending]="smacsIcons.SAVING"
                    (successfulImport)="handleSuccessfulFileImport()"
                    (invalidTypeError)="handleInvalidImportTypeError()"
                  ></app-saml-import-btn>
                  <app-saml-export-btn
                    [label]="'tkey;admin.saml.button.idp.export'"
                    [dataAutomation]="'saml-idp-export-btn'"
                    [buttonStyle]="buttonStyles.DEFAULT"
                    [icon]="smacsIcons.EXPORT"
                    [isDisabled]="!samlIdpMetaDataTimestamp.timestamp"
                    [ngbTooltip]="'tkey;admin.saml.idp.export.tooltip' | translate"
                    [disableTooltip]="!!samlIdpMetaDataTimestamp.timestamp"
                    [event]="!samlIdpMetaDataTimestamp.timestamp ? null : samlIdpGetMetadataXML"
                  ></app-saml-export-btn>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="list-group-item">
          <div class="row py-2">
            <div class="col-md-7">
              <h5>{{ 'tkey;admin.saml.sso.title' | translate }}</h5>
            </div>
            <div class="col-md-5">
              <div class="align-items-center d-md-flex justify-content-end d-block">
                <div
                  data-automation="sso-toggle-input"
                  class="form-check form-switch switch-lg"
                  [placement]="'top'"
                  [disableTooltip]="
                    !!(
                      samlConfig.ssoEnabled ||
                      (samlSpMetaDataTimestamp.timestamp && samlIdpMetaDataTimestamp.timestamp)
                    )
                  "
                  [ngbTooltip]="'tkey;admin.saml.sso.tooltip' | translate"
                >
                  <input
                    [(ngModel)]="samlConfig.ssoEnabled"
                    (change)="onEnabledToggled()"
                    [disabled]="
                      !(
                        samlConfig.ssoEnabled ||
                        (samlSpMetaDataTimestamp.timestamp && samlIdpMetaDataTimestamp.timestamp)
                      )
                    "
                    data-automation="smacs-checkbox-input"
                    type="checkbox"
                    class="form-check-input"
                    id="enhancedLineModeToggle"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
