<div
  class="w-100"
  [hidden]="state.hidden && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'active-color': isFileOver,
    'disabled-color': state.disabled,
    'has-feedback': showValidation || isFormSubmitted,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage,
    'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
  }"
>
  <div
    class="position-relative tooltip-wrapper d-block mb-3"
    smacsFileUploader
    (fileOver)="onFileOver($event)"
    (fileDropped)="handleFiles($event)"
    (click)="fileInput.click()"
    [ngbTooltip]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"
    [container]="'body'"
    [disableTooltip]="!state.disabled"
  >
    <span
      *ngIf="state.required && getLabel()"
      class="text-danger smacs-forms-label col-form-label required-label smacs-forms-asterisk"
    >
      *
      <span class="uploader-label">
        {{ getLabel() | translate }}
      </span>
    </span>

    <div
      *ngIf="state.disabled"
      class="disabled-background tooltip-wrapper"
      [ngbTooltip]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"
      [container]="'body'"
      [disableTooltip]="!state.disabled"
    ></div>

    <svg viewBox="0 0 300 100" preserveAspectRatio="none">
      <path
        d="M0,0 300,0 300,100 0,100z"
        vector-effect="non-scaling-stroke"
        [ngClass]="{
          'disabled-stroke': state.disabled,
          'has-feedback': showValidation || isFormSubmitted,
          'feedback-stroke': showValidation || isFormSubmitted,
          'error-stroke': validationState === ValStates.INVALID,
          'warning-stroke':
            validationState === ValStates.PENDING ||
            validationState === ValStates.WARNING ||
            misconfigurationFeedbackMessage,
          'success-stroke': validationState === ValStates.VALID && showAsyncValidation,
          'active-stroke': isFileOver
        }"
      />
    </svg>

    <div class="w-100 p-4 d-flex flex-column justify-content-center align-items-center">
      <i
        [attr.data-automation]="'icon-saving'"
        class="fa fa-4x icon-saving d-block"
        *ngIf="validationState === ValStates.PENDING"
      ></i>
      <i class="fa fa-4x icon-import d-block text-primary" *ngIf="validationState !== ValStates.PENDING"></i>
      <div class="text-center">
        <div [innerHTML]="'tkey;forms.file.uploader.title' | translate"></div>
        <input
          data-automation="file-uploader-input"
          type="file"
          multiple
          id="uploader"
          (change)="handleFiles($event.target.files)"
          [accept]="acceptedFileExtensions?.acceptedExtensions || null"
          #fileInput
          [disabled]="state.disabled"
        />
        <button
          type="button"
          class="btn btn-primary file-btn"
          [attr.data-automation]="'choose-file-btn'"
          [ngClass]="{
            'disabled-button': state.disabled
          }"
        >
          {{ 'tkey;forms.file.uploader.button' | translate }}
        </button>
        <div class="pt-2 d-flex justify-content-center gap-2">
          <span *ngIf="!!formattedSize">{{ 'tkey;forms.file.uploader.max_size' | translate }}{{ formattedSize }}</span>
          <span *ngIf="!!formattedAcceptedFields">
            {{ 'tkey;forms.file.uploader.accepted_files' | translate }}{{ formattedAcceptedFields }}
          </span>
        </div>

        <div class="form-feedback pt-2">
          <span
            class="{{ smacsIcons.SAVING }}"
            [hidden]="!(validationState === ValStates.PENDING && showAsyncValidation)"
            data-automation="smacs-text-async-pending"
          ></span>

          <span
            class="{{ smacsIcons.REMOVE }}"
            [hidden]="!(validationState === ValStates.INVALID && showAsyncValidation)"
            data-automation="smacs-text-async-invalid"
          ></span>

          <span
            class="{{ smacsIcons.OK }} "
            [hidden]="!(validationState === ValStates.VALID && showAsyncValidation)"
            data-automation="smacs-text-async-valid"
          ></span>
        </div>

        <div class="smacs-forms-feedback-area">
          <div
            class="smacs-forms-validation-message"
            data-automation="smacs-forms-validation-message"
            [hidden]="!showValidation && !isFormSubmitted"
          >
            {{ validationMessage?.content | translate : validationMessage?.params }}
          </div>

          <div
            class="smacs-forms-validation-message text-warning"
            data-automation="smacs-forms-warning-message"
            [hidden]="!misconfigurationFeedbackMessage"
          >
            {{ misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params }}
          </div>

          <span class="small form-text text-muted" *ngIf="config.helpText">
            {{ getHelpText() | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="uploaderType === UploaderType.STAGED"
    class="w-100 d-flex flex-column gap-2"
    [ngClass]="{ 'pb-3': !!files.length }"
  >
    <div
      class="file position-relative px-3 py-2 d-flex gap-4 align-items-center"
      *ngFor="let file of files; let index = index"
      [ngClass]="{ 'border-danger': invalidFileIds.includes(file.id), 'text-body': !invalidFileIds.includes(file.id) }"
      [attr.data-automation]="'file-' + index"
    >
      <i class="fa fa-2x icon-file d-block"></i>
      <div class="d-flex align-items-center me-auto">
        <p class="mb-0">
          <span data-automation="file-name">{{ file.file.name }}</span>
          <span class="font-sml-grey d-block" data-automation="file-subtext">{{ getStagedFileSubtext(file) }}</span>
        </p>
      </div>
      <smacs-button
        [dataAutomation]="'delete-btn'"
        [isDisabled]="state.disabled"
        [cssClass]="'btn-sm ms-1'"
        [icon]="SmacsIcons.DELETE"
        [buttonStyle]="ButtonStyles.DANGER"
        [type]="ButtonTypes.BUTTON"
        (clicked)="onDeleteFile(file.id)"
      ></smacs-button>
    </div>
  </div>

  <div [attr.data-automation]="config.dataAutomation + '-entity-table'">
    <app-entity-table
      *ngIf="(uploaderType === UploaderType.TABLE && !!tableRows.length) || showTable"
      [table]="table"
      [tableRows]="tableRows"
      [verticalAlignContent]="true"
    ></app-entity-table>
  </div>
</div>
