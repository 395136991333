import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  MicrosoftDialPlanFieldConfig,
  MicrosoftDialPlanGroupSearchResult,
} from '../../../shared/models/generated/smacsModels';

@Injectable()
export class MicrosoftDialPlansResource {
  constructor(private http: HttpClient) {}

  getAllDialPlanGroups(): Observable<MicrosoftDialPlanFieldConfig[]> {
    return this.http.get<MicrosoftDialPlanFieldConfig[]>(`/services/microsoft/field-configs/dial-plans`);
  }

  getDialPlanInventoriesByLineUri(e164LineUri: string): Observable<MicrosoftDialPlanGroupSearchResult[]> {
    return this.http.get<MicrosoftDialPlanGroupSearchResult[]>(
      `/services/microsoft/views/dial-plan-inventories/search?lineUri=${encodeURIComponent(e164LineUri)}`
    );
  }
}
