import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSizes, ButtonStyles } from '../../button/button.component';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { NgModel } from '@angular/forms';

@Component({
  selector: 'ziro-checkbox-button',
  templateUrl: './checkbox-button.component.html',
  styleUrls: ['checkbox-button.component.scss'],
})
export class CheckboxButtonComponent {
  @Input() label = '';
  @Input() icon = SmacsIcons.NONE;
  @Input() buttonSize = ButtonSizes.NORMAL;
  @Input() value = false;
  @Input() tooltip = '';
  @Input() dataAutomation = '';
  @Input() disabled: boolean;
  @Input() noTextWrap = false;

  @Output() changed = new EventEmitter<boolean>();

  buttonStyle = ButtonStyles.DEFAULT;

  onCheck($event: boolean, ngModelDir: NgModel) {
    ngModelDir.control.markAsTouched();
    this.changed.next($event);
  }
}
