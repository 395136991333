<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div class="smacs-content" *ngIf="!isLoading">
  <div class="mx-4 flex-grow-1">
    <div
      class="offset-md-1 col-md-10 smacs-page-alert alert border-warning"
      *ngIf="pageDisabled"
      data-automation="smacs-page-disabled-alert"
    >
      <i class="{{ smacsIcons.WARNING }} text-warning fa-4x"></i>
      <div class="ms-3" [innerHTML]="'tkey;userdetail.home.services.disabled_service.label' | translate"></div>
    </div>

    <smacs-pcce-agent-settings-form
      *ngIf="!pageDisabled"
      [entity]="pcceAgent"
      [pcceAgentRef]="pcceAgentRef"
      [pcceServer]="pcceServer"
      [isNewAgent]="isNewAgent"
      [isSsoAgent]="isSsoAgent"
      [fieldConfig]="fieldConfig"
    ></smacs-pcce-agent-settings-form>
  </div>
</div>
