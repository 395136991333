import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { EMPTY, Observable, of, skipWhile, Subscription, throwError } from 'rxjs';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import {
  Microsoft360View,
  MicrosoftSecurityGroupFieldConfigOption,
  MicrosoftSecurityGroupMembership,
  MicrosoftSecurityGroupOption,
  MicrosoftSecurityGroupsFieldConfig,
} from '../../../../shared/models/generated/smacsModels';
import {
  MultiCheckboxOptionConfig,
  SmacsMultiCheckboxComponent,
  SmacsMultiCheckboxConfig,
} from '../../../../forms/fields/multi-checkbox/smacs-multi-checkbox.component';
import { Microsoft360ViewContext } from '../../../../shared/contexts/microsoft-360-view.context';
import { TranslateService } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { Microsoft365SecurityGroupsContext } from '../../../../shared/contexts/microsoft-365-security-groups.context';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../../shared/services/toast.service';
import { Microsoft360ViewPollingService } from '../../../../shared/services/microsoft/microsoft-360-view-polling.service';

export interface SecurityGroupEntity {
  securityGroups: {
    [key: string]: boolean;
  };
}

@Component({
  selector: 'ziro-security-group-memberships-form',
  templateUrl: './security-group-memberships-form.component.html',
  styleUrls: ['./security-group-memberships-form.component.scss'],
  providers: [Microsoft360ViewPollingService],
})
export class SecurityGroupMembershipsFormComponent
  extends SmacsFormAbstractDirective<SecurityGroupEntity>
  implements OnInit, OnDestroy
{
  @Input() fieldConfig: MicrosoftSecurityGroupsFieldConfig;
  @Input() microsoft360View: Microsoft360View;
  @Input() inventory: MicrosoftSecurityGroupFieldConfigOption[];
  @Input() initialEntity: SecurityGroupEntity;

  @ViewChild('securityGroupsCheckboxes') groupsCheckboxes: SmacsMultiCheckboxComponent<SecurityGroupEntity>;
  userSecurityGroups: MicrosoftSecurityGroupOption[] = [];
  formConfig: SmacsFormConfig;
  smacsIcons = SmacsIcons;

  private userPrincipalName: string;
  private _groupLabels: MultiCheckboxOptionConfig[];
  private _subscriptions = new Subscription();

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _bottomNavService: BottomNavService,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    private _microsoft360ViewContext: Microsoft360ViewContext,
    private _microsoft360ViewPollingService: Microsoft360ViewPollingService,
    private _microsoft365SecurityGroupsContext: Microsoft365SecurityGroupsContext,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this.userPrincipalName = this.microsoft360View.microsoft365UserLicenses.userPrincipalName;
    this.userSecurityGroups = this.microsoft360View.securityGroupMemberships;
    this._setCheckboxes();
    this._initBottomNav();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
    this._microsoft360ViewPollingService.stopPolling();
  }

  protected submit() {
    return this._onSaveClick();
  }

  private _onSaveClick(): Observable<void> {
    this._setPending(true);

    return this._updateUserSecurityGroups().pipe(
      switchMap((updatedSecurityGroupMembership) => {
        if (!updatedSecurityGroupMembership) {
          return of(null);
        }

        this._microsoft360ViewPollingService.setBaseUrl(this.userPrincipalName);
        this._microsoft360ViewPollingService.startPolling(Microsoft360ViewPollingService.MAX_POLLS);
        // There is a delay between updating the security groups and them actually appearing on the user
        return this._microsoft360ViewPollingService.state$.pipe(
          skipWhile((state: Microsoft360View) => {
            // use IDs because some fields may be different i.e. null vs empty string description
            const polledSecurityGroupIds = state.securityGroupMemberships
              .map((group: MicrosoftSecurityGroupOption) => group.id)
              .sort();
            const updatedSecurityGroupIds = updatedSecurityGroupMembership.securityGroupMemberships
              .map((group) => group.id)
              .sort();
            return !isEqual(polledSecurityGroupIds, updatedSecurityGroupIds);
          })
        );
      }),
      map((new360View) => {
        this._microsoft360ViewPollingService.stopPolling();
        if (new360View) {
          this._microsoft360ViewContext.setMicrosoft360View(new360View);
        }
        this.smacsFormStateService.setIsFormDirty(false);
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.SECURITY_GROUPS,
          'tkey;shared.toast.save.success.title',
          this._translateService.instant('tkey;shared.toast.save.success.message', {
            type: this._translateService.instant('tkey;shared.model.microsoft_365.text'),
            name: this.userPrincipalName,
          })
        );
        this._router.navigate(['../'], { relativeTo: this._route });
      }),
      catchError((response) => {
        this._setPending(false);
        if (response.status === 422 && response.error.reasonCode === 'CHANGE_NOT_PERMITTED') {
          this._toastService.push(
            ToastTypes.ERROR,
            `${SmacsIcons.SECURITY_GROUPS} text-danger`,
            'tkey;microsoft_360.view.security.groups.membership.toast.error.title',
            response.error.description
          );
          return throwError(() => EMPTY);
        }
        return throwError(() => response);
      })
    );
  }

  private _initBottomNav() {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'ms-security-groups-cancel',
          dataAutomation: 'ms-security-groups-cancel',
          label: 'tkey;global.button.cancel.text',
          buttonClass: ButtonStyles.DEFAULT,
          cb: () => {
            this._router.navigate(['../'], { relativeTo: this._route });
          },
        },
        {
          id: 'ms-security-groups-save',
          dataAutomation: 'ms-security-groups-save',
          label: 'tkey;global.button.save.text',
          buttonClass: ButtonStyles.PRIMARY,
          state: {
            pending: false,
            buttonDisableState: {
              disabled: false,
              tooltipKey: '',
            },
          },
          icon: SmacsIcons.OK,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );
  }

  private _setCheckboxes() {
    this._updateCheckboxLabels();
    const securityGroups: { [key: string]: boolean } = {};

    this._groupLabels.forEach((securityGroup: MultiCheckboxOptionConfig) => {
      securityGroups[securityGroup.label] = this.userSecurityGroups.some(
        (group: MicrosoftSecurityGroupOption) => group.displayName === securityGroup.label
      );
    });

    this.entitySource.next({
      securityGroups: { ...securityGroups },
    });
  }

  private _updateCheckboxLabels = () => {
    const securityGroups = this.inventory.map((item: MicrosoftSecurityGroupOption) => {
      const label = item.displayName;
      let description = item.description || ' ';
      if (!label) {
        description += this._translateService.instant('tkey;selfserve.microsoft365.checkbox.notfound');
      }

      const isItemInitiallySelected = this.initialEntity.securityGroups[item.displayName];

      return {
        label,
        additionalContent: description,
        alignLeft: true,
        disabled: () =>
          this._isGroupReadOnly(item) || (isItemInitiallySelected && this._isGroupAssociatedWithTeamsCalling(item)),
        disabledTooltip: () => this._getDisabledTooltip(item),
      } as MultiCheckboxOptionConfig;
    });

    this._groupLabels = securityGroups;

    this.formConfig = {
      fields: {
        securityGroups: {
          dataAutomation: 'microsoft-security-groups-checkbox',
          componentConfig: new SmacsMultiCheckboxConfig({ multiCheckboxOptionConfig: securityGroups }),
        },
      },
      options: {
        columnClasses: {
          label: 'col-lg-3',
          input: 'col-lg-9',
        },
      },
    };
  };

  private _isGroupReadOnly(group: MicrosoftSecurityGroupOption): boolean {
    return this.fieldConfig.readOnlySecurityGroupList.some((readOnlyGroup) => readOnlyGroup.id === group.id);
  }

  private _isGroupAssociatedWithTeamsCalling(group: MicrosoftSecurityGroupOption): boolean {
    const fieldConfigOption = this.fieldConfig.writeSecurityGroupList.find(
      (secGroupFieldConfig) => secGroupFieldConfig.id === group.id
    );
    const isSecurityGroupAssociatedWithMcoevServicePlan = fieldConfigOption.licenses.some((license) =>
      license.associatedServicePlans.some((plan) => plan.startsWith('MCOEV') && !plan.startsWith('MCOEV_VIRTUALUSER'))
    );
    const isTeamsLinePresentOnUser = !!this.microsoft360View.teamsPhoneNumberAssignment?.lineUri;
    return isSecurityGroupAssociatedWithMcoevServicePlan && isTeamsLinePresentOnUser;
  }

  private _getDisabledTooltip(group: MicrosoftSecurityGroupOption): string {
    if (this._isGroupReadOnly(group)) {
      return 'tkey;microsoft_360.view.security.groups.membership.checkbox.disabled';
    }
    if (this._isGroupAssociatedWithTeamsCalling(group)) {
      return 'tkey;microsoft_360.view.security.groups.membership.checkbox.teams_calling_group.disabled';
    }
    return '';
  }

  private _updateUserSecurityGroups(): Observable<MicrosoftSecurityGroupMembership> {
    if (isEqual(this.initialEntity, this.formData)) {
      return new Observable((subscriber) => {
        subscriber.next(null);
        subscriber.complete();
      });
    } else {
      return this._microsoft365SecurityGroupsContext.get(this.userPrincipalName).pipe(
        mergeMap((userSecurityGroups) => {
          const newSecurityGroupsForUser = this.inventory
            .filter((item) => this.formData.securityGroups[item.displayName])
            .map((option: MicrosoftSecurityGroupFieldConfigOption) => {
              const group: MicrosoftSecurityGroupOption = {
                id: option.id,
                displayName: option.displayName,
                description: option.description,
              };
              return group;
            });
          const groupIdsInForm = this.fieldConfig.writeSecurityGroupList
            .concat(this.fieldConfig.readOnlySecurityGroupList)
            .map((group) => group.id);
          const securityGroupLicensesToAdd = this.microsoft360View.securityGroupMemberships.filter(
            (group: MicrosoftSecurityGroupOption) => !groupIdsInForm.includes(group.id)
          );
          const update = { ...userSecurityGroups };
          update.securityGroupMemberships = [...newSecurityGroupsForUser, ...securityGroupLicensesToAdd];

          return this._microsoft365SecurityGroupsContext
            .updateUserGroups(this.userPrincipalName, update)
            .pipe(map(() => update));
        })
      );
    }
  }

  private _setPending(setting: boolean) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'ms-security-groups-save',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }
}
