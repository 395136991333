import { invert } from 'lodash';

export const enum DeviceEnum {
  PRIMARY_EXTENSION,
  VOICEMAIL,
  SNR,
  DESKPHONE,
  EXTENSION_MOBILITY,
  IM_PRESENCE,
  SOFTPHONE,
  CIPC,
  ANDROID,
  IPHONE,
  TABLET,
  CTI_PORT,
  CTI_REMOTE_DEVICE,
}

export const deviceEnumToName = {
  [DeviceEnum.PRIMARY_EXTENSION]: 'Primary Extension',
  [DeviceEnum.VOICEMAIL]: 'Voicemail',
  [DeviceEnum.SNR]: 'Single Number Reach',
  [DeviceEnum.DESKPHONE]: 'Desk Phone',
  [DeviceEnum.EXTENSION_MOBILITY]: 'Extension Mobility',
  [DeviceEnum.IM_PRESENCE]: 'IM Presence',
  [DeviceEnum.SOFTPHONE]: 'IM Softphone',
  [DeviceEnum.CIPC]: 'CIPC',
  [DeviceEnum.ANDROID]: 'Android',
  [DeviceEnum.IPHONE]: 'IPhone',
  [DeviceEnum.TABLET]: 'Tablet',
  [DeviceEnum.CTI_PORT]: 'CTI Port',
  [DeviceEnum.CTI_REMOTE_DEVICE]: 'CTI Remote Device',
};

export const deviceNameToEnum = invert(deviceEnumToName);
