import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  CallingType,
  DialPlanGroupEntry,
  DialPlanGroupEntryStatus,
  Microsoft365UserResult,
  MicrosoftBulkJobDraft,
  MicrosoftDialPlanFieldConfig,
  MicrosoftProvisioningUserOptions,
  PrePortedNumberStatus,
  PrePortedOrderRef,
  PstnConnectivityType,
  SbcConfigurationReport,
  SbcConfigurationType,
  SelectFieldConfig,
  SharedCallingRoutingPolicy,
  TeamsVoicePoliciesFieldConfig,
  UpnValidation,
  UpnValidationRequest,
  UpnValidationStatusEnum,
  UserPhoto,
} from '../../../../shared/models/generated/smacsModels';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of, Subscriber, Subscription, switchMap } from 'rxjs';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../forms/fields/select/smacs-select.component';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { MicrosoftBulkProvisioningFormData } from '../microsoft-bulk-provisioning.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { MicrosoftDialPlanInventoriesResource } from '../../../../helpdesk/shared/resources/microsoft-dial-plan-inventories.resource';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { forOwn, startCase } from 'lodash';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { parse, ParseStepResult, unparse } from 'papaparse';
import { TelephoneNumberFilter } from '../../../../shared/filters/telephone-number.filter';
import { TranslateService } from '@ngx-translate/core';
import {
  EntityTable,
  EntityTableColumn,
  EntityTableContentRow,
  EntityTableFieldTypes,
  EntityTableFilterTypes,
  EntityTableOnFieldChange,
  EntityTableSelectField,
} from '../../../../shared/entity-table/entity-table.models';
import {
  SmacsFileUploaderConfig,
  UploaderType,
  ZiroFile,
} from '../../../../shared/file-uploader/file-uploader.component';
import { BottomNavService } from '../../../../shared/bottom-nav/bottom-nav.service';
import { ZpmUserPhotoContext } from '../../../../shared/contexts/zpm-user-photo.context';
import { ButtonStyles } from '../../../../button/button.component';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Microsoft365UserResource } from '../../../../helpdesk/shared/resources/microsoft-365-user.resource';
import { ValidatorsService } from '../../../../shared/validators.service';
import { EntityTableComponent } from '../../../../shared/entity-table/entity-table.component';
import { e164StrictPlusStartValidatorRegex } from '../../../../shared/validators-regex';
import { ZpmBulkProvisioningResource } from '../../../resources/zpm-bulk-provisioning.resource';
import { SbcNumberConfigurationsContext } from '../../../../admin/microsoft/ziro-support-settings/sbc-number-configurations/sbc-number-configurations-context.service';
import { OrderNumberStatusResource } from '../../../../admin/order-numbers/port-in-number-status/order-number-status.resource';
import { FilterSelectOption } from '../../../../shared/filter/filter-select/filter-select.component';
import { getStringFromProcessingStatus } from '../../../../admin/order-numbers/port-in-orders/processing-status-helper';
import { MicrosoftVoicePoliciesFieldConfigResource } from '../../../../helpdesk/shared/resources/microsoft-voice-policies-field-config.resource';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';

interface MicrosoftBulkProvisioningCsvFile {
  'User Principal Name': string;
  Number: string;
}

interface ZpmBulkFieldConfig {
  key: string;
  config: SelectFieldConfig<string> | SelectFieldConfig<SharedCallingRoutingPolicy>;
}

interface ExistingNumbersInPortOrder {
  [number: string]: PrePortedOrderRef;
}

interface ParsedCsvFileData {
  rows: EntityTableContentRow[];
  upnValidationRequest: UpnValidationRequest;
}

@Component({
  selector: 'smacs-microsoft-bulk-provisioning-form',
  templateUrl: './microsoft-bulk-provisioning-form.component.html',
  styleUrls: ['./microsoft-bulk-provisioning-form.component.scss'],
  providers: [
    MicrosoftDialPlanInventoriesResource,
    MicrosoftVoicePoliciesFieldConfigResource,
    OrderNumberStatusResource,
  ],
})
export class MicrosoftBulkProvisioningFormComponent
  extends SmacsFormAbstractDirective<MicrosoftBulkProvisioningFormData>
  implements OnInit, OnDestroy, AfterViewInit, OnChanges
{
  @Input() dialPlanFieldConfigs: MicrosoftDialPlanFieldConfig[];
  @Input() tableRows: EntityTableContentRow[] = [];
  @Input() drafts: MicrosoftBulkJobDraft[] = [];

  @ViewChild(EntityTableComponent) entityTableComponent: EntityTableComponent;

  CallingType = CallingType;

  csvTemplateUrl = this._csvTemplateUrl();
  smacsIcons = SmacsIcons;
  isLoading = true;
  isLoadingFieldData = false;
  hasCsvNumbers = false;
  formConfig: SmacsFormConfig;
  table: EntityTable;
  userPhotos: UserPhoto[] = [];
  selectedDialPlan: MicrosoftDialPlanFieldConfig;

  private _voicePoliciesFieldConfig: TeamsVoicePoliciesFieldConfig;
  private _subscriptions = new Subscription();
  private _fieldConfigCols: EntityTableColumn[] = [];
  private _prePortedNumberStatuses$ = new BehaviorSubject<PrePortedNumberStatus[] | null>(null);
  private _prePortedNumberStatuses: PrePortedNumberStatus[];
  private _upnValidations: UpnValidation[] = [];
  private _sbcConfigReport: SbcConfigurationReport;
  private _visibleFieldConfigs: ZpmBulkFieldConfig[] = [];
  private _dialPlanNumbers: DialPlanGroupEntry[] = [];
  private _file: ZiroFile;
  private _isParsingFile = false;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _microsoftDialPlanInventoriesResource: MicrosoftDialPlanInventoriesResource,
    private _microsoftVoicePoliciesFieldConfigResource: MicrosoftVoicePoliciesFieldConfigResource,
    private _sanitizer: DomSanitizer,
    private _telephoneNumberFilter: TelephoneNumberFilter,
    private _translateService: TranslateService,
    private _bottomNavService: BottomNavService,
    private _zpmUserPhotoContext: ZpmUserPhotoContext,
    private _smacsModalService: SmacsModalService,
    private _microsoft365UserResource: Microsoft365UserResource,
    private _portOrderStatusResource: OrderNumberStatusResource,
    private _breadcrumbsService: BreadcrumbsService,
    private _zpmBulkProvisioningResource: ZpmBulkProvisioningResource,
    private _sbcNumberConfigsContext: SbcNumberConfigurationsContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._initFormConfig();
    this._portOrderStatusResource.get().subscribe((prePortedNumberStatuses: PrePortedNumberStatus[]) => {
      this._prePortedNumberStatuses$.next(prePortedNumberStatuses);
    });

    if (this.entity.draftJobId) {
      this.selectedDialPlan = this.dialPlanFieldConfigs.find(
        (fieldConfig: MicrosoftDialPlanFieldConfig) =>
          fieldConfig.id.toString() === this.entity.dialPlanGroup.toString()
      );
      this._getDialPlanInventory(this.selectedDialPlan.id).subscribe();
    }

    const formUpdateSub = this.smacsFormsUpdate$.subscribe(
      (changes: SmacsFormsUpdate<MicrosoftBulkProvisioningFormData>) => {
        // new update breadcrumb
        if (changes.old?.bulkJobName !== changes.new?.bulkJobName) {
          this._breadcrumbsService.updateBreadcrumbs([
            {
              label: 'tkey;automate.zpm.bulk_provisioning.breadcrumb',
              url: '/automate/microsoft/bulk-provisioning',
              routerLink: true,
            },
            { label: changes.new?.bulkJobName },
          ]);
        }

        if (
          changes.new?.dialPlanGroup &&
          this.selectedDialPlan?.id.toString() !== changes.new?.dialPlanGroup.toString()
        ) {
          if (this.selectedDialPlan && this._file) {
            this._confirmDialPlanChange(this.selectedDialPlan.id, parseInt(changes.new?.dialPlanGroup));
          } else {
            this._bottomNavService.setButtonDisabledState('bulk-run-now', false);
            this._getDialPlanInventory(changes.new?.dialPlanGroup).subscribe();
            this.selectedDialPlan = this.dialPlanFieldConfigs.find(
              (fieldConfig: MicrosoftDialPlanFieldConfig) =>
                fieldConfig.id.toString() === changes.new.dialPlanGroup.toString()
            );
          }
        }

        if (
          (changes.new?.file && !changes.old?.file) ||
          (changes.new?.file && changes.old?.file && changes.new?.file[0].id !== changes.old?.file[0].id)
        ) {
          const files = changes.new.file as any;
          this._file = files[0] as ZiroFile;
          this._parseFileAndUpdateTable();
        }

        if (changes.new?.addUpn && changes.new?.addUpn !== changes.old?.addUpn) {
          this._addUpnToTable(changes.new.addUpn);
        }

        // Disable run now when form is dirty
        if (this.smacsFormStateService.getIsFormDirty()) {
          this._bottomNavService.setButtonDisabledState(
            'bulk-run-now',
            true,
            'tkey;automate.zpm.bulk_provisioning.bottom_nav.run_now.disabled.tooltip'
          );
        }

        if (changes.new?.bulkJobName && changes.new.bulkJobName.length) {
          this._validateFile().subscribe((fileValState: SmacsFormsValidationState) => {
            if (fileValState === SmacsFormsValidationState.VALID) {
              this._bottomNavService.setBottomNavValidationError(false);
            }
          });
        }
      }
    );
    this._subscriptions.add(formUpdateSub);
    const userPhotoCtxSub = this._zpmUserPhotoContext.state$.pipe(distinctUntilChanged()).subscribe(() => {
      this.userPhotos = this._zpmUserPhotoContext.getUserPhotos();
      if (this.table) {
        const validatedRows = this._validateRows(this.tableRows);
        const tableRows = this._generateRows(validatedRows, this.userPhotos);
        this.tableRows = tableRows
          .map((row: EntityTableContentRow) => {
            return {
              ...row,
              fields: {
                ...row.fields,
                upn: {
                  ...row.fields.upn,
                  validation: {
                    ...row.fields.upn.validation,
                    hasError: !!this._validateUpn(row.content.upn, this.tableRows),
                    message: this._validateUpn(row.content.upn, this.tableRows),
                  },
                },
              },
            };
          })
          .sort((a, b) => {
            const errorA = this._getRowValidationHierarchy(a);
            const errorB = this._getRowValidationHierarchy(b);
            return errorB - errorA;
          });
      }
    });
    this._subscriptions.add(userPhotoCtxSub);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  isFormDirty(): boolean {
    return this.smacsFormStateService.getIsFormDirty();
  }

  setIsFormDirty(isDirty: boolean): void {
    this.smacsFormStateService.setIsFormDirty(isDirty);
  }

  getDraftName(): string {
    return this.entity.bulkJobName;
  }

  getDraft(): MicrosoftBulkJobDraft {
    return {
      bulkJobId: this.entity.draftJobId || undefined,
      bulkJobName: this.entity.bulkJobName,
      dialPlanGroupId: this.entity.dialPlanGroup,
      users: this.tableRows
        .sort((a: EntityTableContentRow, b: EntityTableContentRow) => {
          const aNumberContentValue = this._getNumberColRowContentValue(a);
          const bNumberContentValue = this._getNumberColRowContentValue(b);
          return aNumberContentValue
            ? bNumberContentValue
              ? aNumberContentValue.localeCompare(bNumberContentValue)
              : -1
            : 1;
        })
        .map((row: EntityTableContentRow) => {
          const numberColRowContentValue = this._getNumberColRowContentValue(row);

          return {
            phoneNumberAssignment: {
              emergencyLocation: '',
              lineUri:
                this.selectedDialPlan.callingType !== CallingType.EXTENSION
                  ? numberColRowContentValue
                    ? ValidatorsService.removeFormattingFromSingleNumber(numberColRowContentValue)
                    : null
                  : this.selectedDialPlan.mainNumber,
              lineUriExtension:
                this.selectedDialPlan.callingType === CallingType.EXTENSION ? numberColRowContentValue : null,
            },
            userPrincipalName: row.content.upn,
            voicePolicies: {
              callingLineIdentityName: this._getPolicyValue('callingLineIdentity', row),
              callingPolicyName: this._getPolicyValue('callingPolicy', row),
              emergencyCallRoutingPolicyName: this._getPolicyValue('emergencyCallRoutingPolicy', row),
              emergencyCallingPolicyName: this._getPolicyValue('emergencyCallingPolicy', row),
              ipPhonePolicyName: this._getPolicyValue('ipPhonePolicy', row),
              onlineVoiceRoutingPolicyName: this._getPolicyValue('onlineVoiceRoutingPolicy', row),
              onlineVoicemailPolicyName: this._getPolicyValue('onlineVoicemailPolicy', row),
              sharedCallRoutingPolicyName: 'Global',
              teamsCallHoldPolicyName: this._getPolicyValue('teamsCallHoldPolicy', row),
              teamsCallParkPolicyName: this._getPolicyValue('teamsCallParkPolicy', row),
              tenantDialPlanName: this._getPolicyValue('tenantDialPlan', row),
            },
          };
        }),
    };
  }

  getProvisioningOptions(): MicrosoftProvisioningUserOptions[] {
    return this.tableRows
      .sort((a: EntityTableContentRow, b: EntityTableContentRow) => {
        const aNumberContentValue = this._getNumberColRowContentValue(a);
        const bNumberContentValue = this._getNumberColRowContentValue(b);
        return aNumberContentValue
          ? bNumberContentValue
            ? aNumberContentValue.localeCompare(bNumberContentValue)
            : -1
          : 1;
      })
      .map((row: EntityTableContentRow) => {
        const numberColRowContentValue = this._getNumberColRowContentValue(row);
        return {
          id: undefined,
          userPrincipalName: row.content.upn,
          teamsCalling: {
            dialPlanGroupIds: numberColRowContentValue ? null : [parseInt(this.entity.dialPlanGroup)],
            lineUri:
              this.selectedDialPlan.callingType !== CallingType.EXTENSION
                ? numberColRowContentValue
                  ? ValidatorsService.removeFormattingFromSingleNumber(numberColRowContentValue)
                  : null
                : this.selectedDialPlan.mainNumber,
            lineUriExtension:
              this.selectedDialPlan.callingType === CallingType.EXTENSION ? numberColRowContentValue : null,
            teamsVoicePolicies: {
              userPrincipalName: row.content.upn,
              callingLineIdentity: this._getPolicyValue('callingLineIdentity', row),
              callingPolicy: this._getPolicyValue('callingPolicy', row),
              emergencyCallRoutingPolicy: this._getPolicyValue('emergencyCallRoutingPolicy', row),
              emergencyCallingPolicy: this._getPolicyValue('emergencyCallingPolicy', row),
              ipPhonePolicy: this._getPolicyValue('ipPhonePolicy', row),
              onlineVoiceRoutingPolicy: this._getPolicyValue('onlineVoiceRoutingPolicy', row),
              onlineVoicemailPolicy: this._getPolicyValue('onlineVoicemailPolicy', row),
              teamsCallHoldPolicy: this._getPolicyValue('teamsCallHoldPolicy', row),
              teamsCallParkPolicy: this._getPolicyValue('teamsCallParkPolicy', row),
              tenantDialPlan: this._getPolicyValue('tenantDialPlan', row),
              sharedCallRoutingPolicy: {
                policyName: 'Global',
                resourceAccountDisplayName: '',
                resourceAccountLineUri: '',
                resourceAccountUpn: '',
              },
            },
          },
          audioConferenceNumber: null,
        };
      });
  }

  onFieldChange(event: EntityTableOnFieldChange): void {
    this._bottomNavService.setButtonDisabledState(
      'bulk-run-now',
      true,
      'tkey;automate.zpm.bulk_provisioning.bottom_nav.run_now.disabled.tooltip'
    );

    const oldValue = this.tableRows[event.index].content[event.columnId];
    this.tableRows = this.tableRows.map((tableRow: EntityTableContentRow) => {
      if (event.rowId !== tableRow.rowId) {
        return tableRow;
      }

      return {
        ...tableRow,
        content: {
          ...tableRow.content,
          [event.columnId]: event.newValue,
        },
        fields: {
          ...tableRow.fields,
          [event.columnId]: {
            ...tableRow.fields.number,
            value:
              event.columnId === 'number'
                ? {
                    label: event.newValue,
                    value: event.newValue,
                  }
                : event.newValue,
          } as EntityTableSelectField,
        },
      };
    });
    if (event.columnId === 'upn') {
      const upnValidationRequest: UpnValidationRequest = {
        userPrincipalNames: [String(event.newValue)],
      };
      this._zpmBulkProvisioningResource.bulkUpnValidation(upnValidationRequest).subscribe((upnValidation) => {
        this._upnValidations = this._upnValidations
          .filter((val) => val.userPrincipalName !== oldValue)
          .concat(upnValidation);
        this._updateTable(this.tableRows);
      });
    } else {
      this._updateTable(this.tableRows);
    }
  }

  protected submit(): Observable<void> {
    return of(null);
  }

  private _getPolicyValue(policyKey: keyof TeamsVoicePoliciesFieldConfig, row: EntityTableContentRow): string {
    const config = this._voicePoliciesFieldConfig[policyKey];
    if (config.show && config.possibleOptions.length > 1) {
      return row.content[policyKey];
    } else if (config.defaultValue && typeof config.defaultValue === 'string') {
      return config.defaultValue;
    }
    return '';
  }

  private _csvTemplateUrl(): SafeUrl {
    const csvData = unparse({
      fields: ['User Principal Name', 'Number'],
      data: [],
    });
    return this._sanitizer.bypassSecurityTrustUrl(`data:text/plain;charset=utf8,${encodeURIComponent(csvData)}`);
  }

  private _getDialPlanInventory(id: number | string): Observable<void> {
    this.isLoadingFieldData = true;
    const isDraasType = this.dialPlanFieldConfigs.some(
      (fieldConfig) =>
        fieldConfig.id === Number(id) &&
        (fieldConfig.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS ||
          fieldConfig.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS_BYOC)
    );

    return forkJoin([
      this._microsoftDialPlanInventoriesResource.get(parseInt(id.toString())),
      this._microsoftVoicePoliciesFieldConfigResource.get(Number(id), false),
      isDraasType ? this._sbcNumberConfigsContext.get() : of(null),
    ]).pipe(
      map(
        ([dialPlanGroupEntry, policiesFieldConfig, sbcConfigurationReport]: [
          DialPlanGroupEntry[],
          TeamsVoicePoliciesFieldConfig,
          SbcConfigurationReport | null
        ]) => {
          this._dialPlanNumbers = dialPlanGroupEntry && dialPlanGroupEntry.length ? dialPlanGroupEntry : [];
          this._voicePoliciesFieldConfig = policiesFieldConfig;
          this._sbcConfigReport = sbcConfigurationReport || null;
          this._setFieldStates();
          this._generateTable();

          if (this.entity.draftJobId || !this._isParsingFile) {
            this._updateTable(this.tableRows);
          }
          this.isLoadingFieldData = false;
          if (this._isParsingFile) {
            this._parseFileAndUpdateTable();
          }
          return null;
        }
      )
    );
  }

  private _initFormConfig(): void {
    const dialPlanGroupsSelect = this.dialPlanFieldConfigs.map<SmacsSelectOption>(
      (dialPlanGroup: MicrosoftDialPlanFieldConfig) => {
        return {
          label: dialPlanGroup.name,
          value: String(dialPlanGroup.id),
          disabled:
            dialPlanGroup.pstnConnectivityType === PstnConnectivityType.OPERATOR_CONNECT ||
            dialPlanGroup.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS ||
            dialPlanGroup.callingType === CallingType.SHARED_CALLING,
          disabledTooltip: this._getDialPlanSelectOptionTooltip(dialPlanGroup),
        };
      }
    );

    this.formConfig = {
      fields: {
        bulkJobName: {
          label: 'tkey;automate.zpm.bulk_provisioning.user_list.bulk_job_name.label',
          dataAutomation: 'zpm-bulk-provisioning-bulk-job-name',
          required: () => true,
          helpText: 'tkey;automate.zpm.bulk_provisioning.user_list.bulk_job_name.helptext',
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.TEXT,
            placeholder: 'tkey;automate.zpm.bulk_provisioning.user_list.bulk_job_name.placeholder',
          }),
          validation: [
            {
              validator: (val: string) => {
                if (!val) {
                  return SmacsFormsValidationState.VALID;
                }

                if (this.entity.draftJobId) {
                  return this.drafts.some((draft: MicrosoftBulkJobDraft) => {
                    return (
                      val.trim().toLowerCase() === draft.bulkJobName.toLowerCase() &&
                      this.entity.draftJobId !== draft.bulkJobId
                    );
                  })
                    ? SmacsFormsValidationState.INVALID
                    : SmacsFormsValidationState.VALID;
                }

                return this.drafts.some(
                  (draft: MicrosoftBulkJobDraft) => val.trim().toLowerCase() === draft.bulkJobName.toLowerCase()
                )
                  ? SmacsFormsValidationState.INVALID
                  : SmacsFormsValidationState.VALID;
              },
              message: 'tkey;automate.zpm.bulk_provisioning.user_list.bulk_job_name.validation.unique',
            },
          ],
        },
        dialPlanGroup: {
          label: 'tkey;automate.zpm.bulk_provisioning.teams_calling_settings.dial_plan_group.label',
          dataAutomation: 'microsoft-bulk-provisioning-general-dial-plan-groups-select',
          required: () => true,
          helpText: 'tkey;automate.zpm.bulk_provisioning.teams_calling_settings.dial_plan_group.helptext',
          componentConfig: new SmacsSelectConfig({
            options: dialPlanGroupsSelect,
            isMultiSelect: false,
            bindValue: 'value',
          }),
        },
        addUpn: {
          dataAutomation: 'zpm-bulk-provisioning-add-upn',
          componentConfig: new SmacsSelectConfig({
            isMultiSelect: false,
            asyncOptionsFn: this._searchUsers.bind(this),
            minSearchLength: 3,
            placeholder: 'tkey;automate.zpm.bulk_provisioning.user_list.add_user.helptext',
          }),
          required: false,
          disabled: () => !this.formData.dialPlanGroup,
          disabledTooltip: 'tkey;automate.zpm.bulk_provisioning.upn.disabled.tooltip',
        },
        file: {
          required: true,
          dataAutomation: 'zpm-bulk-provisioning-file',
          componentConfig: new SmacsFileUploaderConfig({
            acceptedFileExtensions: { acceptedExtensions: '.csv', allowOthers: false },
            maxSize: 1024,
            uploaderType: UploaderType.TABLE,
          }),
          disabled: () => !this.formData.dialPlanGroup,
          disabledTooltip: 'tkey;automate.zpm.bulk_provisioning.file.disabled.tooltip',
          validation: [
            {
              validator: () => this._validateFile(),
              message: 'tkey;automate.zpm.bulk_provisioning.user_list.file.error',
            },
          ],
        },
      },
    };
  }

  private _searchUsers(query: string): Observable<string[]> {
    if (!query || query.length < 3) {
      return of([]);
    }

    return this._microsoft365UserResource.search(query).pipe(
      map((data: Microsoft365UserResult[]) => {
        return data.map((user: Microsoft365UserResult) => {
          return user.microsoft365UserRef.id;
        });
      })
    );
  }

  private _setHasCsvNumbers(validatedRows: EntityTableContentRow[]): void {
    this.hasCsvNumbers = validatedRows.some((row: EntityTableContentRow) => row.content.phoneNumber);
    this._setFieldStates();
  }

  private _generateTable(): void {
    this._fieldConfigCols = [];
    this._visibleFieldConfigs = [];

    forOwn(this._voicePoliciesFieldConfig, (val, key: keyof TeamsVoicePoliciesFieldConfig) => {
      if (key === 'sharedCallingRoutingPolicy') {
        return;
      }

      const typedConfig = val as SelectFieldConfig<string>;

      if (val.show) {
        if (!typedConfig.possibleOptions || typedConfig.possibleOptions.length > 1) {
          this._visibleFieldConfigs.push({
            key: key,
            config: val,
          });
          this._fieldConfigCols.push({
            columnId: key,
            cssColumnSize: 'col-sm-3',
            label: startCase(key),
          });
        }
      }
    });

    const upnColumnSize = !this._fieldConfigCols.length
      ? 'col-sm-5'
      : this._fieldConfigCols.length === 1
      ? 'col-sm-4'
      : 'col-sm-3';
    const table: EntityTable = {
      columns: [
        {
          columnId: 'icon',
          cssColumnSize: 'icon-col',
          cssClass: 'justify-content-center',
          label: '',
        },
        {
          columnId: 'upn',
          cssColumnSize: upnColumnSize,
          label: 'tkey;automate.zpm.bulk_provisioning.teams_audio_conf_settings.table.upn.label',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'number',
          cssColumnSize: !this._fieldConfigCols.length ? 'col-sm-5' : 'col-sm-3',
          label:
            this.selectedDialPlan.callingType !== CallingType.EXTENSION
              ? 'tkey;automate.zpm.bulk_provisioning.teams_audio_conf_settings.table.phone_number.label'
              : 'tkey;automate.zpm.bulk_provisioning.teams_audio_conf_settings.table.extension.label',
          filter: {
            type: EntityTableFilterTypes.TEXT,
            filterFn: (rows, filterValue) => {
              return rows.filter((row) => {
                const formattedFilterVal = this._trimNumber(filterValue);
                const formattedCellVal = this._trimNumber(row.content['number']);

                return !!formattedCellVal.includes(formattedFilterVal);
              });
            },
          },
        },
      ],
      hasActions: true,
      resultsMessage: 'tkey;automate.zpm.bulk_provisioning.teams_audio_conf_settings.table.no_results_message',
    };
    table.columns = [...table.columns, ...this._fieldConfigCols];
    this.table = table;
  }

  private _parseFileAndUpdateTable(): void {
    this._isParsingFile = true;
    if (!this.isLoadingFieldData) {
      this._parseFile()
        .pipe(
          switchMap((parsedFileData: ParsedCsvFileData) =>
            combineLatest([
              parsedFileData?.upnValidationRequest.userPrincipalNames.length
                ? this._bulkValidateUpns(parsedFileData.upnValidationRequest)
                : of([
                    {
                      optionalAssignedLineUri: '',
                      upnValidationStatus: UpnValidationStatusEnum.UPN_NOT_FOUND,
                      userPrincipalName: '',
                    },
                  ]),
              this._prePortedNumberStatuses$,
              of(parsedFileData.rows),
            ])
          )
        )
        .subscribe(
          ([bulkUpnValidations, prePortedNumberStatuses, csvRows]: [
            UpnValidation[],
            PrePortedNumberStatus[] | null,
            EntityTableContentRow[]
          ]) => {
            this._prePortedNumberStatuses = prePortedNumberStatuses || [];
            this._upnValidations = bulkUpnValidations || [];
            this._updateTable(csvRows);
            this._isParsingFile = false;
            this.isLoadingFieldData = false;
            setTimeout(() => {
              this._scrollToTable();
            }, 10);
          }
        );
    }
  }

  private _checkNumbersExistingInPortOrders(options: string[]): ExistingNumbersInPortOrder {
    const numbersToCheck = options.filter((option) => !!option).map((option) => option);
    return numbersToCheck.reduce((acc: ExistingNumbersInPortOrder, curNum: string) => {
      if (acc[curNum]) return acc;

      const existingOrderInfo = this._prePortedNumberStatuses?.find((prePortedNumberStatus) =>
        prePortedNumberStatus.numbers?.find((numberStatus) => numberStatus.number === curNum)
      )?.order;

      if (existingOrderInfo) {
        acc = { ...acc, [curNum]: existingOrderInfo };
      }
      return acc;
    }, {});
  }

  private _getNumberColRowContentValue(row: EntityTableContentRow): string {
    return typeof row.content['number'] === 'string' ? row.content['number'] : row.content['number']?.value;
  }

  private _updateTable(entityTableContentRows: EntityTableContentRow[]): void {
    // entityTableContentRow may come from tableRows or csvRows
    this.tableRows = [];

    const tableRows: EntityTableContentRow[] = entityTableContentRows.map(
      (row: EntityTableContentRow, index: number) => {
        const dialPlanOptions: string[] = this._dialPlanNumbers.map((entry: DialPlanGroupEntry) => entry.value);

        const numberColRowContentValue = this._getNumberColRowContentValue(row);

        const existingNumbersInPortOrders: ExistingNumbersInPortOrder = this._checkNumbersExistingInPortOrders([
          numberColRowContentValue,
          ...dialPlanOptions,
        ]);

        const existingNumberPortOrderRef: PrePortedOrderRef = existingNumbersInPortOrders[numberColRowContentValue];

        const numberCellEmptyOption: FilterSelectOption = {
          label: '',
          value: '',
        };

        const numberCellCurrentOption: FilterSelectOption = this._getCurrentNumberValue(
          existingNumbersInPortOrders,
          numberColRowContentValue,
          row
        );

        let numberCellSelectOptions: FilterSelectOption[] = [numberCellEmptyOption];

        if (numberCellCurrentOption) {
          numberCellSelectOptions.push(numberCellCurrentOption);
        }

        const dpNums: FilterSelectOption[] = this._dialPlanNumbers
          .filter(
            (num) => num?.value !== String(numberCellCurrentOption) && num?.value !== numberCellCurrentOption?.value
          )
          .map((filteredNum) => {
            return existingNumbersInPortOrders[filteredNum.value]
              ? {
                  label: filteredNum.value,
                  value: filteredNum.value,
                  iconHtml: `<i class="icon ${this.smacsIcons.TOOLTIP} me-1"></i>`,
                }
              : {
                  label: filteredNum.value,
                  value: filteredNum.value,
                };
          });
        numberCellSelectOptions = numberCellSelectOptions.concat(dpNums);

        if (!(row.fields?.number as EntityTableSelectField)?.options) {
          numberCellSelectOptions = numberCellSelectOptions
            .concat(
              dialPlanOptions.map((option) =>
                existingNumbersInPortOrders[option]
                  ? {
                      label: option,
                      value: option,
                      iconHtml: `<i class="icon ${this.smacsIcons.TOOLTIP} me-1"></i>`,
                    }
                  : {
                      label: option,
                      value: option,
                    }
              )
            )
            .reduce((acc, cur) => {
              if (acc.every((selectOption: FilterSelectOption) => selectOption.value !== cur.value)) {
                acc.push(cur);
              }
              return acc;
            }, []);
        }

        const staticFields: { [key: string]: any } = {};
        const upnValidationMessage = this._validateUpn(row.content['upn'], entityTableContentRows);

        staticFields['upn'] = {
          type: EntityTableFieldTypes.SELECT,
          value: row.content['upn'],
          asyncOptionsFn: this._searchUsers.bind(this),
          rowId: 'upn',
          isHidden: false,
          onToggle: (): void => null,
          validation: {
            hasError: () => {
              return !!upnValidationMessage;
            },
            message: upnValidationMessage,
          },
        };

        const numberValidationMessage = this._validateNumber(
          numberColRowContentValue,
          entityTableContentRows,
          row.content['upn'],
          index
        );

        staticFields['number'] = {
          type: EntityTableFieldTypes.SELECT,
          value: numberCellCurrentOption,
          options: numberCellSelectOptions,
          rowId: 'number',
          isHidden: false,
          onToggle: (): void => null,
          validation: {
            hasError: !!numberValidationMessage,
            message: numberValidationMessage,
          },
          showOptionsIcons: true,
          bindValue: 'value',
        };

        const fields: { [key: string]: any } = {};
        const fieldsContent: { [key: string]: any } = {};

        this._visibleFieldConfigs.forEach((config: ZpmBulkFieldConfig) => {
          const configUntyped: any = config.config;
          const policyValueFromDraft = row.content['voicePolicies']
            ? row.content['voicePolicies'][config.key + 'Name']
            : undefined;

          if (configUntyped['possibleOptions'] && config.key !== 'emergencyLocation') {
            const typedConfig = config.config as SelectFieldConfig<string>;
            const fieldValidationMessage = this._validatePolicy(config.key, typedConfig.defaultValue);
            fields[config.key] = {
              type: EntityTableFieldTypes.SELECT,
              value: row.content[config.key] || policyValueFromDraft || typedConfig.defaultValue,
              options: typedConfig.possibleOptions,
              rowId: config.key,
              isHidden: false,
              onToggle: (): void => null,
              validation: {
                validator: (value: string) => {
                  return this._validatePolicy(config.key, value);
                },
                hasError: Boolean(fieldValidationMessage),
                message: fieldValidationMessage,
              },
            };
            fieldsContent[config.key] = row.content[config.key] || typedConfig.defaultValue;
          }
        });

        return {
          content: {
            ...row.content,
            ...fieldsContent,
          },
          html: {
            number: row.content.number
              ? this._telephoneNumberFilter.transform(row.content.number)
              : `<i class="icon ${this.smacsIcons.FIX_IT}"></i> ${this._translateService.instant(
                  'tkey;automate.zpm.bulk_provisioning.auto_assigned.label'
                )}`,
          },
          fields: {
            ...staticFields,
            ...fields,
          },
          actions: [
            {
              buttonStyle: ButtonStyles.DANGER,
              dataAutomation: 'bulk-provisioning-user-delete',
              icon: SmacsIcons.DELETE,
              onClick: () => this._onDeleteClicked(row.content.upn, index),
            },
          ],
          tooltip: {
            number: {
              content: existingNumberPortOrderRef
                ? 'tkey;automate.zpm.bulk_provisioning.existing_number_in_port_order.tooltip'
                : '',
              params: {
                status:
                  existingNumberPortOrderRef?.status &&
                  getStringFromProcessingStatus(existingNumberPortOrderRef?.status),
                orderId: existingNumberPortOrderRef?.id,
              },
            },
          },
          rowId: row.rowId || crypto.randomUUID(),
        };
      }
    );
    this.tableRows = this._generateRows(tableRows, this.userPhotos).sort((a, b) => {
      const errorA = this._getRowValidationHierarchy(a);
      const errorB = this._getRowValidationHierarchy(b);
      return errorB - errorA;
    });
    this._setHasCsvNumbers(this.tableRows);
    this._getUserPhotos();
    this.fieldChannels['file'].validateSource.next();
  }

  private _getCurrentNumberValue(
    existingNumbersInPortOrders: ExistingNumbersInPortOrder,
    numberColRowContentValue: string,
    row: EntityTableContentRow
  ): FilterSelectOption {
    const isNumberInPortOrders = existingNumbersInPortOrders[numberColRowContentValue];
    const filterSelectCurrentValue = row.fields?.number?.value as FilterSelectOption;
    if (!!isNumberInPortOrders) {
      return {
        ...(row.fields.number.value as FilterSelectOption),
        iconHtml: `<i class="icon ${this.smacsIcons.TOOLTIP} me-1"></i>`,
      };
    } else if (!!filterSelectCurrentValue) {
      filterSelectCurrentValue.value = this._formatCsvNumberToTableData(filterSelectCurrentValue.value);
      filterSelectCurrentValue.label = this._formatCsvNumberToTableData(filterSelectCurrentValue.label);
      return filterSelectCurrentValue;
    } else {
      return {
        label: this._formatCsvNumberToTableData(numberColRowContentValue),
        value: this._formatCsvNumberToTableData(numberColRowContentValue),
      } as FilterSelectOption;
    }
  }

  private _validateFile(): Observable<SmacsFormsValidationState> {
    if (this.tableRows.length) {
      const tableRows = this._validateRows(this.tableRows);
      if (this._isContentValid(tableRows)) {
        this._bottomNavService.setBottomNavValidationError(false);
        return of(SmacsFormsValidationState.VALID);
      }
      return of(SmacsFormsValidationState.INVALID);
    } else {
      return of(SmacsFormsValidationState.VALID);
    }
  }

  private _bulkValidateUpns(upnValidationRequest: UpnValidationRequest): Observable<UpnValidation[]> {
    return this._zpmBulkProvisioningResource.bulkUpnValidation(upnValidationRequest);
  }

  private _parseFile(file?: ZiroFile): Observable<ParsedCsvFileData> {
    this.isLoadingFieldData = true;
    const parseFileData: ParsedCsvFileData = {
      rows: [],
      upnValidationRequest: {
        userPrincipalNames: [],
      },
    };

    return new Observable((subscriber: Subscriber<ParsedCsvFileData>) => {
      parse<MicrosoftBulkProvisioningCsvFile>(file ? file?.file : this._file.file, {
        header: true,
        skipEmptyLines: true,
        complete: () => {
          subscriber.next(parseFileData);
          subscriber.complete();
        },
        step: (row: ParseStepResult<MicrosoftBulkProvisioningCsvFile>): void => {
          const data: MicrosoftBulkProvisioningCsvFile = row.data as unknown as MicrosoftBulkProvisioningCsvFile;
          const upn = data['User Principal Name']?.trim();
          const number = data['Number']?.trim();

          if (upn || number) {
            parseFileData.rows.push({
              content: {
                upn: upn,
                number: number ? this._formatCsvNumberToTableData(number) : '',
              },
              fields: {
                number: {
                  type: EntityTableFieldTypes.SELECT,
                  value: {
                    value: number ? this._formatCsvNumberToTableData(number) : '',
                    label: number ? this._formatCsvNumberToTableData(number) : '',
                  },
                },
              },
            });
            if (upn) {
              parseFileData.upnValidationRequest.userPrincipalNames.push(upn);
            }
          }
        },
      });
    });
  }

  private _onDeleteClicked(upn: string, index: number): void {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        promptBody: this._translateService.instant(
          'tkey;automate.zpm.bulk_provisioning.user_list.modal.delete.message',
          { upn }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'prompt-modal-cancel',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'prompt-modal-confirm',
            cb: () => {
              this.tableRows = this.tableRows
                .filter((row: EntityTableContentRow, rowIndex: number) => rowIndex !== index)
                .map((row: EntityTableContentRow, i: number) => {
                  return {
                    ...row,
                    actions: [
                      {
                        buttonStyle: ButtonStyles.DANGER,
                        dataAutomation: 'bulk-provisioning-user-delete',
                        icon: SmacsIcons.DELETE,
                        onClick: () => this._onDeleteClicked(row.content.upn, i),
                      },
                    ],
                  };
                });
              this.fieldChannels['file'].validateSource.next();

              return of(null);
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _addUpnToTable(newUpn: string): void {
    const row = {
      content: {
        upn: newUpn,
        number: '',
      },
    };
    this.tableRows = [row, ...this.tableRows];
    this._updateTable(this.tableRows);

    this.entitySource.next({
      ...this.entity,
      addUpn: '',
    });
  }

  private _getUserPhotos(): void {
    const upns = this.tableRows
      .map((row: EntityTableContentRow) => row.content.upn)
      .filter((upn: string) => !!upn && !upn.trim().includes(' '));
    this._zpmUserPhotoContext.initUserPhotos(upns);
  }

  private _getRowValidationHierarchy(row: EntityTableContentRow): number {
    for (const key in row.fields) {
      if (row.fields[key]?.validation?.hasError) {
        return 1;
      }
    }
    return 0;
  }

  private _isContentValid(rows: EntityTableContentRow[]): boolean {
    return rows.length && !rows.some((row: EntityTableContentRow) => row.content.upnError || row.content.numberError);
  }

  private _generateRows(rows: EntityTableContentRow[], userPhotos: UserPhoto[]): EntityTableContentRow[] {
    return rows.map((row: EntityTableContentRow) => {
      const photo = userPhotos.find((userPhoto: UserPhoto) => userPhoto.userPrincipalName === row.content.upn);

      if (photo && photo.photoBase64) {
        row.html.icon = `
           <img src="data:image/png;base64,${photo.photoBase64}"
                alt="${photo.displayName}"
                class="user-profile-picture entity-table-thumbnail">
        `;
      } else if (photo && !photo.photoBase64) {
        row.html.icon = `
           <i class="icon-user-circle font-size-32"></i>
        `;
      } else {
        row.html.icon = `<i class="icon-saving"></i>`;
      }

      if (photo && photo.displayName) {
        row.html.upn = `
           <a href="/app2/#/360-view/microsoft/${photo.userPrincipalName}" target="_blank">
               ${photo.userPrincipalName}
           </a>
        `;
      } else {
        row.html.upn = `
           <span>${row.content.upn}</span>
        `;
      }

      if (row.content.upnError) {
        row.html.upn += ` 
           <span class="text-danger d-block smacs-forms-validation-message">${this._translateService.instant(
             row.content.upnError
           )}</span>
        `;
      }

      if (row.content.numberError) {
        row.html.number = `
          ${this._telephoneNumberFilter.transform(row.content.number)}
          <span class="text-danger d-block smacs-forms-validation-message">${this._translateService.instant(
            row.content.numberError
          )}</span>
        `;
      }

      return {
        ...row,
      };
    });
  }

  private _validateRows(rows: EntityTableContentRow[]): EntityTableContentRow[] {
    return rows.map((row: EntityTableContentRow) => this._validateRow(row, rows));
  }

  private _validateUpn(upn: string, rows: EntityTableContentRow[]): string {
    const upnValidation = this._upnValidations?.find((validation) => validation.userPrincipalName === upn);

    if (!upn) {
      return 'tkey;automate.zpm.bulk_provisioning.user_list.upn.validation.message';
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        upn
      )
    ) {
      return 'tkey;automate.zpm.bulk_provisioning.user_list.upn.pattern.validation.message';
    } else if (rows.filter((r: EntityTableContentRow) => r.content.upn === upn).length > 1) {
      return 'tkey;automate.zpm.bulk_provisioning.user_list.upn.uniqueness.validation.message';
    } else if (!!upnValidation && upnValidation.upnValidationStatus === UpnValidationStatusEnum.UPN_NOT_FOUND) {
      return 'tkey;automate.zpm.bulk_provisioning.table.upn_not_found.text';
    } else if (
      !!upnValidation &&
      upnValidation.upnValidationStatus === UpnValidationStatusEnum.LINEURI_ALREADY_ASSIGNED
    ) {
      return this._translateService.instant('tkey;automate.zpm.bulk_provisioning.table.line_uri_assigned.text', {
        upn,
      });
    } else if (!!upnValidation && upnValidation.upnValidationStatus === UpnValidationStatusEnum.VOICE_LICENSE_MISSING) {
      return this._translateService.instant('tkey;automate.zpm.bulk_provisioning.table.voice_license_missing.text', {
        upn,
      });
    }

    return '';
  }

  private _validatePolicy(policyName: string, currentDefaultValue: string): string {
    const key = policyName as keyof TeamsVoicePoliciesFieldConfig;
    const field = this._voicePoliciesFieldConfig[key];
    const selectField: SelectFieldConfig<string> = field as SelectFieldConfig<string>;
    return selectField.possibleOptions.includes(currentDefaultValue)
      ? ''
      : this._translateService.instant('tkey;automate.zpm.bulk_provisioning.table.policy_invalid.text', {
          policyName: selectField.defaultValue,
          dialPlanGroupName: this.selectedDialPlan.name,
        });
  }

  private _validateNumber(number: string, rows: EntityTableContentRow[], upn: string, index?: number): string {
    const formattedPhoneNumber = this._telephoneNumberFilter.transform(number);
    const e164Number = this._trimNumber(formattedPhoneNumber);
    const sbcConfig = this._sbcConfigReport?.sbcNumberConfigurations.find((config) => config.number === e164Number);
    if (this.selectedDialPlan.callingType !== CallingType.EXTENSION) {
      if (number && !e164StrictPlusStartValidatorRegex.test(e164Number)) {
        return 'tkey;automate.zpm.bulk_provisioning.user_list.phoneNumber.pattern.validation.message';
      } else if (
        number &&
        rows.filter((r: EntityTableContentRow) => r.content.number['value'] === number || r.content.number === number)
          .length > 1
      ) {
        if (
          rows.filter((r: EntityTableContentRow) => r.content.number['value'] === number || r.content.number === number)
            .length === 2
        ) {
          const conflictingUpn = rows.find(
            (r, idx) => (r.content.number['value'] === number || r.content.number === number) && index !== idx
          )?.content['upn'];

          return this._translateService.instant(
            'tkey;automate.zpm.bulk_provisioning.user_list.phoneNumber.uniqueness.validation.message',
            {
              conflictingName: conflictingUpn,
            }
          );
        } else if (
          rows.filter((r: EntityTableContentRow) => r.content.number['value'] === number || r.content.number === number)
            .length > 2
        ) {
          return this._translateService.instant(
            'tkey;automate.zpm.bulk_provisioning.user_list.phoneNumber.uniqueness_multiple.validation.message',
            { numberType: 'number' }
          );
        }
      } else if (
        number &&
        !this._dialPlanNumbers.some(
          (entry: DialPlanGroupEntry) => formattedPhoneNumber === this._telephoneNumberFilter.transform(entry.value)
        )
      ) {
        return this._translateService.instant(
          'tkey;automate.zpm.bulk_provisioning.user_list.phoneNumber.exist.validation.message',
          { dpgName: this.selectedDialPlan.name }
        );
      } else if (
        number &&
        this._dialPlanNumbers.find(
          (entry: DialPlanGroupEntry) => formattedPhoneNumber === this._telephoneNumberFilter.transform(entry.value)
        )?.assignmentStatus === DialPlanGroupEntryStatus.ASSIGNED
      ) {
        const conflictingAssignedDialPlanGroupEntry = this._dialPlanNumbers.find(
          (entry: DialPlanGroupEntry) => formattedPhoneNumber === this._telephoneNumberFilter.transform(entry.value)
        );
        return this._translateService.instant(
          'tkey;automate.zpm.bulk_provisioning.user_list.phoneNumber.already_assigned.validation.message',
          { upn: conflictingAssignedDialPlanGroupEntry.description }
        );
      } else if (
        number &&
        this._dialPlanNumbers.find(
          (entry: DialPlanGroupEntry) => formattedPhoneNumber === this._telephoneNumberFilter.transform(entry.value)
        )?.assignmentStatus === DialPlanGroupEntryStatus.RESTRICTED
      ) {
        const restrictedDialPlanGroupEntry = this._dialPlanNumbers.find(
          (entry: DialPlanGroupEntry) => formattedPhoneNumber === this._telephoneNumberFilter.transform(entry.value)
        );
        return this._translateService.instant(
          'tkey;automate.zpm.bulk_provisioning.user_list.phoneNumber.restricted_group.validation.message',
          { dpgName: restrictedDialPlanGroupEntry.exceptionGroupName }
        );
      } else if (
        (sbcConfig?.numberConfigurationType === SbcConfigurationType.BYOC_MISSING_FROM_SBC ||
          sbcConfig?.numberConfigurationType === SbcConfigurationType.DRAAS_MISSING_FROM_SBC) &&
        (this.selectedDialPlan.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS ||
          this.selectedDialPlan.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS_BYOC)
      ) {
        return 'tkey;automate.zpm.bulk_provisioning.table.sbc_not_configured.text';
      } else if (sbcConfig?.numberConfigurationType === SbcConfigurationType.SBC_NUMBER_MISSING_CARRIER) {
        return 'tkey;automate.zpm.bulk_provisioning.table.missing_carrier.text';
      }

      // Extension validation
    } else if (this.selectedDialPlan.callingType === CallingType.EXTENSION) {
      if (
        number &&
        rows.filter((r: EntityTableContentRow) => r.content.number['value'] === number || r.content.number === number)
          .length > 1
      ) {
        if (
          rows.filter((r: EntityTableContentRow) => r.content.number['value'] === number || r.content.number === number)
            .length === 2
        ) {
          const conflictingUpn = rows.find(
            (r) => (r.content.number['value'] === number || r.content.number === number) && r.content['upn'] !== upn
          )?.content['upn'];

          return this._translateService.instant(
            'tkey;automate.zpm.bulk_provisioning.user_list.extension.uniqueness.validation.message',
            {
              conflictingName: conflictingUpn,
            }
          );
        } else if (
          rows.filter((r: EntityTableContentRow) => r.content.number['value'] === number || r.content.number === number)
            .length > 2
        ) {
          return this._translateService.instant(
            'tkey;automate.zpm.bulk_provisioning.user_list.phoneNumber.uniqueness_multiple.validation.message',
            { numberType: 'extension' }
          );
        }
      } else if (
        number &&
        !this._dialPlanNumbers.some(
          (entry: DialPlanGroupEntry) => formattedPhoneNumber === this._telephoneNumberFilter.transform(entry.value)
        )
      ) {
        return 'tkey;automate.zpm.bulk_provisioning.user_list.extension.exist.validation.message';
      }
    }

    return '';
  }

  private _validateRow(row: EntityTableContentRow, rows: EntityTableContentRow[]): EntityTableContentRow {
    const numberColRowContentValue = this._getNumberColRowContentValue(row);
    row.content.upnError = this._validateUpn(row.content.upn, rows);
    row.content.numberError = this._validateNumber(numberColRowContentValue, rows, row.content['upn']);
    return row;
  }

  private _getDialPlanSelectOptionTooltip(dialPlanGroup: MicrosoftDialPlanFieldConfig): string {
    if (dialPlanGroup.pstnConnectivityType === PstnConnectivityType.OPERATOR_CONNECT) {
      return 'tkey;automate.zpm.bulk_provisioning.teams_calling_settings.dial_plan_group.operator_connect.disabled.tooltip';
    } else if (dialPlanGroup.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS) {
      return 'tkey;automate.zpm.bulk_provisioning.teams_calling_settings.dial_plan_group.microsoft_calling.disabled.tooltip';
    } else if (dialPlanGroup.callingType === CallingType.SHARED_CALLING) {
      return 'tkey;automate.zpm.bulk_provisioning.teams_calling_settings.dial_plan_group.shared_calling.disabled.tooltip';
    } else {
      return '';
    }
  }

  private _confirmDialPlanChange(oldDialPlanGroupId: number, dialPlanGroupId: number): void {
    const options = {
      windowClass: 'zpm-bulk-dpg-change-modal',
      modalViewProperties: {
        promptBody: this._translateService.instant(
          'tkey;automate.zpm.bulk_provisioning.form.dial_plan_group.change_modal'
        ),
        icon: SmacsIcons.WARNING,
        iconClass: 'text-warning',
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
            cb: () => this._resetDialPlan(oldDialPlanGroupId),
          },
          {
            label: 'tkey;dialogs.button.confirm',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._changeDialPlan(dialPlanGroupId),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _resetDialPlan(dialPlanGroupId: number): Observable<void> {
    this.entitySource.next({
      ...this.entity,
      dialPlanGroup: dialPlanGroupId.toString(),
    });
    return of(null);
  }

  private _changeDialPlan(dialPlanGroupId: number): Observable<void> {
    this.selectedDialPlan = this.dialPlanFieldConfigs.find(
      (fieldConfig: MicrosoftDialPlanFieldConfig) => fieldConfig.id.toString() === dialPlanGroupId.toString()
    );

    return this._getDialPlanInventory(dialPlanGroupId);
  }

  private _scrollToTable(): void {
    const elements = document.querySelectorAll(`[data-automation="zpm-bulk-provisioning-preview-entity-table"]`);

    if (elements.length) {
      elements[0].scrollIntoView({ behavior: 'smooth' });
    }
  }

  private _trimNumber(number: string | FilterSelectOption): string {
    if (typeof number === 'string') {
      return number
        .replaceAll(' ', '')
        .replaceAll('-', '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll(',', '')
        .replaceAll('/', '')
        .replaceAll('\\', '');
    }
    return number.value
      .replaceAll(' ', '')
      .replaceAll('-', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll(',', '')
      .replaceAll('/', '')
      .replaceAll('\\', '');
  }

  private _formatCsvNumberToTableData(number: string) {
    if (this.selectedDialPlan.callingType === CallingType.DID && number.length > 6) {
      const trimmedNumber = this._trimNumber(number);
      const lastDigitsNum = trimmedNumber.substring(trimmedNumber.length - 7);
      const matchInSelectedDialPlan = this._dialPlanNumbers.find((dpNum) => {
        const lastDigitsDp = dpNum.value.substring(dpNum.value.length - 7);
        return lastDigitsNum === lastDigitsDp;
      });
      return matchInSelectedDialPlan ? matchInSelectedDialPlan.value : this._trimNumber(number);
    } else {
      return this._trimNumber(number);
    }
  }
}
