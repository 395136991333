import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModelProtocolFieldConfig } from '../../../../shared/models/generated/smacsModels';
import { Observable } from 'rxjs';
import { PhoneType } from '../../../../shared/models/service-type';

@Injectable()
export class ModelProtocolFieldConfigResource {
  constructor(private http: HttpClient) {}

  post(deviceType: PhoneType, body: { siteId: number }): Observable<ModelProtocolFieldConfig> {
    switch (deviceType) {
      case PhoneType.DESKPHONE:
        return this.http.post<ModelProtocolFieldConfig>(`/services/cisco/field-configs/deskphone-model-protocol`, body);
      case PhoneType.EXTENSION_MOBILITY:
        return this.http.post<ModelProtocolFieldConfig>(
          `/services/cisco/field-configs/extension-mobility-model-protocols`,
          body
        );
    }
  }
}
