import { Component, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { of, Subscription } from 'rxjs';
import {
  Checkbox,
  CucmMetadata,
  CustomCheckbox,
  CustomInputText,
  CustomMultiSelect,
  CustomSelect,
  DialPlanManagementGroup,
  DnDidRange,
  SiteSummary,
} from '../../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../../forms/smacs-forms-models';
import { TranslateService } from '@ngx-translate/core';
import {
  LegacySmacsSelectConfigComponent,
  SmacsSelectFieldConfig,
} from '../../../../../shared/custom-configs/legacy-smacs-select-config/legacy-smacs-select-config.component';
import { cloneDeep, isEqual, sortBy } from 'lodash';
import {
  CustomMultiSelectFieldUi,
  SelectFieldConfigUi,
  TextFieldConfigUi,
} from '../dial-plan-management-edit.component';
import { SmacsMultiCheckboxConfig } from '../../../../../forms/fields/multi-checkbox/smacs-multi-checkbox.component';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../../../forms/fields/checkbox/smacs-checkbox.component';
import { SmacsIcons } from '../../../../../shared/models/smacs-icons.enum';
import { SmacsTextFieldConfig } from '../../../../../shared/custom-configs/legacy-smacs-text-config/legacy-smacs-text-config.component';
import { RangeService } from '../../../../../shared/services/range.service';
import { ExtensionRangesOptionalValidators } from '../../../../../forms/fields/extension-ranges/smacs-extension-range-models';
import {
  ExtensionRangesDisplayFormComponent,
  RangeDisplayFormModel,
} from '../../../../../forms/fields/extension-ranges/extension-ranges-display-form/extension-ranges-display-form.component';
import { SmacsTextareaConfig } from '../../../../../forms/fields/textarea/smacs-textarea.component';

interface CustomCheckboxUi {
  enabled: boolean;
  show: boolean;
}

export interface CiscoDnRangesFormEntity {
  // DN Ranges
  ranges: DnDidRange[];
  // Line Appearance Settings
  advertise_distinct_mask_for_public_lines: Checkbox;
  external_phone_number_mask: CustomInputText;
  public_external_phone_number_mask: CustomInputText;
  line_text_label: CustomInputText;
  // Directory Number Settings
  route_partition: CustomSelect;
  urgent_priority: CustomCheckbox;
  optional_line_group_membership: CustomMultiSelect;
  auto_answer: CustomSelect;
  monitoring_calling_search_space: CustomSelect;
  // Enterprise Alternate Number
  ent_alt_num_number_mask: CustomInputText;
  ent_alt_num_advertise_globally_via_ils: CustomCheckbox;
  ent_alt_num_is_urgent: CustomCheckbox;
  ent_alt_num_add_to_local_route_partition: CustomCheckbox;
  ent_alt_num_route_partition: CustomSelect;
  // E.164 Alternate Number
  e164_alt_num_number_mask: CustomInputText;
  e164_alt_num_advertise_globally_via_ils: CustomCheckbox;
  e164_alt_num_is_urgent: CustomCheckbox;
  e164_alt_num_add_to_local_route_partition: CustomCheckbox;
  e164_alt_num_route_partition: CustomSelect;
  // PSTN Failover
  pstn_failover: CustomSelect;
}

export interface CiscoDnRangesFormData {
  // DN Ranges
  ranges: DnDidRange[];
  bulkRanges: '';
  // Line Appearance Settings
  advertise_distinct_mask_for_public_lines: boolean;
  external_phone_number_mask: TextFieldConfigUi;
  public_external_phone_number_mask: TextFieldConfigUi;
  line_text_label: TextFieldConfigUi;
  // Other Directory Number Settings
  route_partition: SelectFieldConfigUi;
  urgent_priority: CustomCheckboxUi;
  optional_line_group_membership?: CustomMultiSelectFieldUi;
  auto_answer: SelectFieldConfigUi;
  monitoring_calling_search_space: SelectFieldConfigUi;
  // Enterprise Alternate Number
  ent_alt_num_number_mask: TextFieldConfigUi;
  ent_alt_num_advertise_globally_via_ils: CustomCheckboxUi;
  ent_alt_num_is_urgent: CustomCheckboxUi;
  ent_alt_num_add_to_local_route_partition: CustomCheckboxUi;
  ent_alt_num_route_partition: SelectFieldConfigUi;
  // E.164 Alternate Number
  e164_alt_num_number_mask: TextFieldConfigUi;
  e164_alt_num_advertise_globally_via_ils: CustomCheckboxUi;
  e164_alt_num_is_urgent: CustomCheckboxUi;
  e164_alt_num_add_to_local_route_partition: CustomCheckboxUi;
  e164_alt_num_route_partition: SelectFieldConfigUi;
  // PSTN Failover
  pstn_failover: SelectFieldConfigUi;
}

@Component({
  selector: 'app-cisco-dial-plan-management-dn-ranges-form',
  templateUrl: './dial-plan-management-dn-ranges-form.component.html',
  styleUrls: ['./dial-plan-management-dn-ranges-form.component.scss'],
})
export class CiscoDialPlanManagementDnRangesFormComponent
  extends SmacsFormAbstractDirective<CiscoDnRangesFormEntity, CiscoDnRangesFormData>
  implements OnInit, OnDestroy
{
  @Input() groupId: string;
  @Input() clusterId: number;
  @Input() dialPlanGroups: DialPlanManagementGroup[] = [];
  @Input() cucmMetadata: CucmMetadata;
  @Input() siteSummary: SiteSummary;

  @ViewChildren(LegacySmacsSelectConfigComponent) childSelectConfigs: QueryList<LegacySmacsSelectConfigComponent>;
  @ViewChild(ExtensionRangesDisplayFormComponent) extensionRangesDisplayForm: ExtensionRangesDisplayFormComponent;

  smacsIcons = SmacsIcons;
  validationStates = SmacsFormsValidationState;
  formConfig: SmacsFormConfig;

  isEntAltSectionVisible = false;
  isE164AltSectionVisible = false;
  isPstnFailoverSectionVisible = false;

  displayRangeFormEntity: RangeDisplayFormModel;
  displayRangeForm = false;
  extensionRangesDisplayFormValidationState: SmacsFormsValidationState;
  displayRangeFormOptionalValidators: ExtensionRangesOptionalValidators;

  private _subscriptions = new Subscription();

  private static _isTextConfigPopulated(config: CustomInputText) {
    return !!(config.required || config.show || config.value);
  }

  private static _isSelectConfigPopulated(config: CustomSelect): boolean {
    return !!(config.possibleOptions.length || config.defaultOption || config.required || config.show);
  }

  private static _isCheckboxConfigPopulated(config: CustomCheckbox): boolean {
    return !!(config.value || config.show);
  }

  private static _getInitialPstnOptions(hasEnterprise: boolean, hasE164: boolean) {
    const options = [];
    if (hasEnterprise) {
      options.push('Enterprise Number');
    }

    if (hasE164) {
      options.push('+E.164 Number');
    }

    return options;
  }

  private static _getEntAltSectionPopulated(entity: CiscoDnRangesFormEntity): boolean {
    return (
      CiscoDialPlanManagementDnRangesFormComponent._isTextConfigPopulated(
        entity.ent_alt_num_number_mask as CustomInputText
      ) ||
      CiscoDialPlanManagementDnRangesFormComponent._isCheckboxConfigPopulated(
        entity.ent_alt_num_advertise_globally_via_ils as CustomCheckbox
      ) ||
      CiscoDialPlanManagementDnRangesFormComponent._isCheckboxConfigPopulated(
        entity.ent_alt_num_is_urgent as CustomCheckbox
      ) ||
      CiscoDialPlanManagementDnRangesFormComponent._isCheckboxConfigPopulated(
        entity.ent_alt_num_add_to_local_route_partition as CustomCheckbox
      ) ||
      CiscoDialPlanManagementDnRangesFormComponent._isSelectConfigPopulated(
        entity.ent_alt_num_route_partition as CustomSelect
      )
    );
  }

  private static _getE164AltSectionPopulated(entity: CiscoDnRangesFormEntity): boolean {
    return (
      CiscoDialPlanManagementDnRangesFormComponent._isTextConfigPopulated(
        entity.e164_alt_num_number_mask as CustomInputText
      ) ||
      CiscoDialPlanManagementDnRangesFormComponent._isCheckboxConfigPopulated(
        entity.e164_alt_num_advertise_globally_via_ils as CustomCheckbox
      ) ||
      CiscoDialPlanManagementDnRangesFormComponent._isCheckboxConfigPopulated(
        entity.e164_alt_num_is_urgent as CustomCheckbox
      ) ||
      CiscoDialPlanManagementDnRangesFormComponent._isSelectConfigPopulated(
        entity.e164_alt_num_route_partition as CustomSelect
      ) ||
      CiscoDialPlanManagementDnRangesFormComponent._isCheckboxConfigPopulated(
        entity.e164_alt_num_add_to_local_route_partition as CustomCheckbox
      )
    );
  }

  private static _getPstnFailoverSectionPopulated(entity: CiscoDnRangesFormEntity): boolean {
    return CiscoDialPlanManagementDnRangesFormComponent._isSelectConfigPopulated(entity.pstn_failover as CustomSelect);
  }

  private static _isLocalRoutePartitionsEnabled(formData: CiscoDnRangesFormData): boolean {
    const entNumberMask = formData.ent_alt_num_number_mask
      ? (formData.ent_alt_num_number_mask as TextFieldConfigUi)
      : null;
    const entAdvertiseGlobally = formData.ent_alt_num_advertise_globally_via_ils
      ? (formData.ent_alt_num_advertise_globally_via_ils as CustomCheckboxUi)
      : null;
    const entIsUrgent = formData.ent_alt_num_is_urgent ? (formData.ent_alt_num_is_urgent as CustomCheckboxUi) : null;
    const entAddToLocalRoutePartition = formData.ent_alt_num_add_to_local_route_partition
      ? (formData.ent_alt_num_add_to_local_route_partition as CustomCheckboxUi)
      : null;

    const e164NumberMask = formData.e164_alt_num_number_mask
      ? (formData.e164_alt_num_number_mask as TextFieldConfigUi)
      : null;
    const e164AdvertiseGlobally = formData.e164_alt_num_advertise_globally_via_ils
      ? (formData.e164_alt_num_advertise_globally_via_ils as CustomCheckboxUi)
      : null;
    const e164IsUrgent = formData.e164_alt_num_is_urgent ? (formData.e164_alt_num_is_urgent as CustomCheckboxUi) : null;
    const e164AddToLocalRoutePartition = formData.e164_alt_num_add_to_local_route_partition
      ? (formData.e164_alt_num_add_to_local_route_partition as CustomCheckboxUi)
      : null;

    return (
      // Enterprise
      (entNumberMask && (!!entNumberMask.defaultValue || entNumberMask.required || entNumberMask.show)) ||
      (entAdvertiseGlobally && (entAdvertiseGlobally.show || entAdvertiseGlobally.enabled)) ||
      (entIsUrgent && (entIsUrgent.show || entIsUrgent.enabled)) ||
      (entAddToLocalRoutePartition && (entAddToLocalRoutePartition.show || entAddToLocalRoutePartition.enabled)) ||
      // E.164
      (e164NumberMask && (!!e164NumberMask.defaultValue || e164NumberMask.required || e164NumberMask.show)) ||
      (e164AdvertiseGlobally && (e164AdvertiseGlobally.show || e164AdvertiseGlobally.enabled)) ||
      (e164IsUrgent && (e164IsUrgent.show || e164IsUrgent.enabled)) ||
      (e164AddToLocalRoutePartition && (e164AddToLocalRoutePartition.show || e164AddToLocalRoutePartition.enabled))
    );
  }

  private static _isE164LocalRoutePartitionEnabledOrShowing(entity: CiscoDnRangesFormEntity): boolean {
    return (
      entity.e164_alt_num_add_to_local_route_partition.value || entity.e164_alt_num_add_to_local_route_partition.show
    );
  }

  private static _isEANLocalRoutePartitionEnabledOrShowing(entity: CiscoDnRangesFormEntity): boolean {
    return (
      entity.ent_alt_num_add_to_local_route_partition.value || entity.ent_alt_num_add_to_local_route_partition.show
    );
  }

  constructor(protected smacsFormStateService: SmacsFormStateService, private _translateService: TranslateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    const entAltSectionPopulated = CiscoDialPlanManagementDnRangesFormComponent._getEntAltSectionPopulated(
      this.entity as CiscoDnRangesFormEntity
    );
    const e164AltSectionPopulated = CiscoDialPlanManagementDnRangesFormComponent._getE164AltSectionPopulated(
      this.entity as CiscoDnRangesFormEntity
    );
    const pstnFailoverOptions = CiscoDialPlanManagementDnRangesFormComponent._getInitialPstnOptions(
      entAltSectionPopulated,
      e164AltSectionPopulated
    );

    this.isEntAltSectionVisible = entAltSectionPopulated;
    this.isE164AltSectionVisible = e164AltSectionPopulated;
    this.isPstnFailoverSectionVisible = CiscoDialPlanManagementDnRangesFormComponent._getPstnFailoverSectionPopulated(
      this.entity as CiscoDnRangesFormEntity
    );
    this.formConfig = {
      fields: {
        bulkRanges: {
          label: 'tkey;dialplanmanagement.admin.group.generate.dnranges',
          helpText: () => 'tkey;dialplanmanagement.admin.group.generate.dnranges.helpblock',
          dataAutomation: 'dn-ranges-bulk-ranges',
          componentConfig: new SmacsTextareaConfig({
            placeholder: this._translateService.instant('tkey;admin.msdialplan.management.bulk_generate.placeholder'),
          }),
          validation: [
            {
              validator: (val: string) => {
                if (!val) {
                  return SmacsFormsValidationState.VALID;
                }

                const split = val.split(',');
                const hasInvalid = split.some((v: string) => {
                  return !/^\+?[0-9]+(?:-\+?[0-9]+)*$/.test(v.trim());
                });

                return hasInvalid ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
              },
              message: 'tkey;rangeDirective.range.unsupportedpattern',
            },
          ],
        },
        // Line Appearance Settings
        advertise_distinct_mask_for_public_lines: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.external_phone_mask.advertise_public_line_mask.label',
          dataAutomation: 'dn-ranges-advertise-distinct-mask-for-public-lines',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH }),
          helpText:
            'tkey;dialplanmanagement.admin.group.dnranges.external_phone_mask.advertise_public_line_mask.help_text',
          labelToolTipText:
            'tkey;dialplanmanagement.admin.group.dnranges.external_phone_mask.advertise_public_line_mask.tooltip',
          labelToolTipIconClass: this.smacsIcons.INFO,
        },
        external_phone_number_mask: {
          label: () => {
            return this.formData.advertise_distinct_mask_for_public_lines
              ? 'tkey;dialplanmanagement.admin.group.dnranges.external_phone_mask.user_lines.label'
              : 'tkey;site_management.site.section.field.external_phone_number_mask.text';
          },
          dataAutomation: 'dn-ranges-external-phone-number-mask',
          componentConfig: new SmacsTextFieldConfig({ displayEditor: false }),
          helpText: () =>
            this.formData.advertise_distinct_mask_for_public_lines
              ? 'tkey;dialplanmanagement.admin.group.dnranges.external_phone_mask.user_lines.help_text'
              : '',
        },
        public_external_phone_number_mask: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.external_phone_mask.public_lines.label',
          dataAutomation: 'dn-ranges-public-external-phone-number-mask',
          componentConfig: new SmacsTextFieldConfig({ displayEditor: false }),
          helpText: () => 'tkey;dialplanmanagement.admin.group.dnranges.external_phone_mask.public_lines.help_text',
          hidden: () => !this.formData.advertise_distinct_mask_for_public_lines,
        },
        line_text_label: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.line_text_label.label',
          dataAutomation: 'dn-ranges-line-text-label',
          componentConfig: new SmacsTextFieldConfig({ displayEditor: true }),
        },
        // Directory Number Settings
        route_partition: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.route_partition.label',
          dataAutomation: 'dn-ranges-route-partition',
          componentConfig: new SmacsSelectFieldConfig(false, sortBy(this.cucmMetadata.routePartitions)),
        },
        urgent_priority: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.urgent_priority',
          dataAutomation: 'dn-ranges-urgent-priority',
          componentConfig: new SmacsMultiCheckboxConfig({
            multiCheckboxOptionConfig: [
              {
                label: 'tkey;site_management.site.section.checkbox.enable',
              },
              {
                label: 'tkey;site_management.site.section.show.label',
              },
            ],
          }),
        },
        optional_line_group_membership: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.line_groups.label',
          dataAutomation: 'dn-ranges-optional-line-group-membership',
          componentConfig: new SmacsSelectFieldConfig(true, sortBy(this.cucmMetadata.lineGroups), false),
        },
        auto_answer: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.auto_answer.label',
          dataAutomation: 'dn-ranges-auto-answer',
          componentConfig: new SmacsSelectFieldConfig(false, sortBy(this.cucmMetadata.autoAnswers), true, true),
        },
        monitoring_calling_search_space: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.monitoring_css.label',
          dataAutomation: 'dn-ranges-monitoring-calling-search-space',
          componentConfig: new SmacsSelectFieldConfig(false, sortBy(this.cucmMetadata.callingSearchSpaces)),
        },
        // Enterprise Alternate Number
        ent_alt_num_number_mask: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.number_mask.label',
          dataAutomation: 'dn-ranges-ent-alt-num-external-phone-number-mask',
          componentConfig: new SmacsTextFieldConfig({ displayEditor: true }),
        },
        ent_alt_num_advertise_globally_via_ils: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.advertise_globally.label',
          dataAutomation: 'dn-ranges-ent-alt-num-advertise-globally-via-ils',
          componentConfig: new SmacsMultiCheckboxConfig({
            multiCheckboxOptionConfig: [
              {
                label: 'tkey;site_management.site.section.checkbox.enable',
              },
              {
                label: 'tkey;site_management.site.section.show.label',
              },
            ],
          }),
        },
        ent_alt_num_is_urgent: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.is_urgent.label',
          dataAutomation: 'dn-ranges-ent-alt-num-is-urgent',
          componentConfig: new SmacsMultiCheckboxConfig({
            multiCheckboxOptionConfig: [
              {
                label: 'tkey;site_management.site.section.checkbox.enable',
              },
              {
                label: 'tkey;site_management.site.section.show.label',
              },
            ],
          }),
        },
        ent_alt_num_add_to_local_route_partition: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.add_route_partition.label.label',
          dataAutomation: 'dn-ranges-ent-alt-num-add-to-local-route-partition',
          componentConfig: new SmacsMultiCheckboxConfig({
            multiCheckboxOptionConfig: [
              {
                label: 'tkey;site_management.site.section.checkbox.enable',
              },
              {
                label: 'tkey;site_management.site.section.show.label',
              },
            ],
          }),
        },
        ent_alt_num_route_partition: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.route_partition.label',
          dataAutomation: 'dn-ranges-ent-alt-num-route-partition',
          componentConfig: new SmacsSelectFieldConfig(false, sortBy(this.cucmMetadata.routePartitions)),
          disabled: () => {
            if (this.formData.ent_alt_num_add_to_local_route_partition) {
              const fd = this.formData.ent_alt_num_add_to_local_route_partition as CustomCheckboxUi;
              return !fd.enabled && !fd.show;
            }

            return false;
          },
          disabledTooltip: 'tkey;dialplanmanagement.admin.group.dnranges.route_partition.disabled.tooltip',
        },
        // E.164 Alternate Number
        e164_alt_num_number_mask: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.number_mask.label',
          dataAutomation: 'dn-ranges-e164-alt-num-external-phone-number-mask',
          componentConfig: new SmacsTextFieldConfig({ displayEditor: true }),
        },
        e164_alt_num_advertise_globally_via_ils: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.advertise_globally.label',
          dataAutomation: 'dn-ranges-e164-alt-num-advertise-globally-via-ils',
          componentConfig: new SmacsMultiCheckboxConfig({
            multiCheckboxOptionConfig: [
              {
                label: 'tkey;site_management.site.section.checkbox.enable',
              },
              {
                label: 'tkey;site_management.site.section.show.label',
              },
            ],
          }),
        },
        e164_alt_num_is_urgent: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.is_urgent.label',
          dataAutomation: 'dn-ranges-e164-alt-num-is-urgent',
          componentConfig: new SmacsMultiCheckboxConfig({
            multiCheckboxOptionConfig: [
              {
                label: 'tkey;site_management.site.section.checkbox.enable',
              },
              {
                label: 'tkey;site_management.site.section.show.label',
              },
            ],
          }),
        },
        e164_alt_num_add_to_local_route_partition: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.add_route_partition.label.label',
          dataAutomation: 'dn-ranges-e164-alt-num-add-to-local-route-partition',
          componentConfig: new SmacsMultiCheckboxConfig({
            multiCheckboxOptionConfig: [
              {
                label: 'tkey;site_management.site.section.checkbox.enable',
              },
              {
                label: 'tkey;site_management.site.section.show.label',
              },
            ],
          }),
        },
        e164_alt_num_route_partition: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.route_partition.label',
          dataAutomation: 'dn-ranges-e164-alt-num-route-partition',
          componentConfig: new SmacsSelectFieldConfig(false, sortBy(this.cucmMetadata.routePartitions)),
          disabled: () => {
            if (this.formData.e164_alt_num_add_to_local_route_partition) {
              const fd = this.formData.e164_alt_num_add_to_local_route_partition as CustomCheckboxUi;
              return !fd.enabled && !fd.show;
            }

            return false;
          },
          disabledTooltip: 'tkey;dialplanmanagement.admin.group.dnranges.route_partition.disabled.tooltip',
        },
        // PSTN Failover
        pstn_failover: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.pstn_failover.advertised_number.label',
          dataAutomation: 'dn-ranges-pstn-failover',
          componentConfig: new SmacsSelectFieldConfig(false, [...pstnFailoverOptions]),
          disabled: () =>
            !CiscoDialPlanManagementDnRangesFormComponent._isLocalRoutePartitionsEnabled(
              this.formData as CiscoDnRangesFormData
            ),
          disabledTooltip:
            'tkey;dialplanmanagement.admin.group.dnranges.pstn_failover.advertised_number.disabled_tooltip',
        },
      },
    };

    this.displayRangeFormOptionalValidators = {
      overlappingRanges: true,
      strictE164Validation: false,
      hasSameLengthValidation: true,
      maxDiffValidation: 99999,
      minLengthValidation: 1,
      maxLengthValidation: 24,
      overlappingRangesInOtherCiscoDialPlanGroups: {
        clusterId: this.clusterId,
        dialPlanGroups: !this.groupId
          ? this.dialPlanGroups
          : this.dialPlanGroups.filter((group: DialPlanManagementGroup) => group.id !== this.groupId),
        range: 'DN',
      },
    };

    this.displayRangeFormEntity = { ranges: cloneDeep(this.entity.ranges) };
    this.displayRangeForm = true;
    this._watchForChanges();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  generateBulkRangesClicked() {
    if (!this.formData['bulkRanges'].trim()) {
      return;
    }

    this.displayRangeForm = false;

    // Remove new lines, spaces
    const generatedRanges = RangeService.generateRangesFromArray(
      this.formData['bulkRanges'].replace(/\n|\r/g, '').replace(/ /g, '').split(',')
    );
    const currentRanges = cloneDeep(this.entity.ranges).filter((r: DnDidRange) => r.start && r.end);
    const updatedRanges = [...currentRanges, ...generatedRanges];
    const newEntity = {
      ...this.entity,
      ranges: updatedRanges,
    };

    this.formData['bulkRanges'] = '';
    this.entitySource.next(cloneDeep(newEntity));
    this.displayRangeFormEntity = { ranges: newEntity.ranges };

    setTimeout(() => {
      this.displayRangeForm = true;
    });
  }

  submit() {
    this.setDisplayFormValidationState();
    return of(null);
  }

  toFormData = (entity: CiscoDnRangesFormEntity): CiscoDnRangesFormData => {
    return {
      // DN Ranges
      ranges: entity.ranges,
      bulkRanges: this.formData['bulkRanges'] || '',
      // Line Appearance Settings
      advertise_distinct_mask_for_public_lines: entity.advertise_distinct_mask_for_public_lines?.value,
      external_phone_number_mask: {
        name: entity.external_phone_number_mask?.name,
        defaultValue: entity.external_phone_number_mask?.value,
        required: entity.external_phone_number_mask?.required,
        show: entity.external_phone_number_mask?.show,
      },
      public_external_phone_number_mask: {
        name: entity.public_external_phone_number_mask?.name,
        defaultValue: entity.public_external_phone_number_mask?.value,
        required: entity.public_external_phone_number_mask?.required,
        show: entity.public_external_phone_number_mask?.show,
      },
      line_text_label: {
        name: entity.line_text_label?.name,
        defaultValue: entity.line_text_label?.value,
        required: entity.line_text_label?.required,
        show: entity.line_text_label?.show,
      },
      // Directory Number Settings
      route_partition: {
        name: entity.route_partition?.name,
        defaultValue: entity.route_partition?.defaultOption,
        defaultValues: entity.route_partition?.defaultOption ? [entity.route_partition?.defaultOption] : [],
        possibleOptions: entity.route_partition?.possibleOptions,
        required: entity.route_partition?.required,
        show: entity.route_partition?.show,
      },
      urgent_priority: {
        enabled: entity.urgent_priority.value,
        show: entity.urgent_priority.show,
      },
      optional_line_group_membership: {
        name: entity.optional_line_group_membership?.name,
        defaultValue: entity.optional_line_group_membership?.defaultOptions[0],
        defaultValues: [...entity.optional_line_group_membership?.defaultOptions],
        possibleOptions: entity.optional_line_group_membership?.possibleOptions,
        show: entity.optional_line_group_membership?.show,
        required: false,
      },
      auto_answer: {
        name: entity.auto_answer?.name,
        defaultValue: entity.auto_answer?.defaultOption,
        defaultValues: entity.auto_answer?.defaultOption ? [entity.auto_answer?.defaultOption] : [],
        possibleOptions: entity.auto_answer?.possibleOptions,
        required: true,
        show: entity.auto_answer?.show,
      },
      monitoring_calling_search_space: {
        name: entity.monitoring_calling_search_space?.name,
        defaultValue: entity.monitoring_calling_search_space?.defaultOption,
        defaultValues: entity.monitoring_calling_search_space?.defaultOption
          ? [entity.monitoring_calling_search_space?.defaultOption]
          : [],
        possibleOptions: entity.monitoring_calling_search_space?.possibleOptions,
        required: entity.monitoring_calling_search_space?.required,
        show: entity.monitoring_calling_search_space?.show,
      },
      // Enterprise Alternate Number
      ent_alt_num_number_mask: {
        name: entity.ent_alt_num_number_mask?.name,
        defaultValue: entity.ent_alt_num_number_mask?.value,
        required: entity.ent_alt_num_number_mask?.required,
        show: entity.ent_alt_num_number_mask?.show,
      },
      ent_alt_num_advertise_globally_via_ils: {
        enabled: entity.ent_alt_num_advertise_globally_via_ils?.value,
        show: entity.ent_alt_num_advertise_globally_via_ils?.show,
      },
      ent_alt_num_is_urgent: {
        enabled: entity.ent_alt_num_is_urgent?.value,
        show: entity.ent_alt_num_is_urgent?.show,
      },
      ent_alt_num_add_to_local_route_partition: {
        enabled: entity.ent_alt_num_add_to_local_route_partition?.value,
        show: entity.ent_alt_num_add_to_local_route_partition?.show,
      },
      ent_alt_num_route_partition: {
        name: entity.ent_alt_num_route_partition?.name,
        defaultValue: CiscoDialPlanManagementDnRangesFormComponent._isEANLocalRoutePartitionEnabledOrShowing(entity)
          ? entity.ent_alt_num_route_partition?.defaultOption
          : '',
        defaultValues: entity.ent_alt_num_route_partition?.defaultOption
          ? [entity.ent_alt_num_route_partition?.defaultOption]
          : [],
        possibleOptions: CiscoDialPlanManagementDnRangesFormComponent._isEANLocalRoutePartitionEnabledOrShowing(entity)
          ? entity.ent_alt_num_route_partition?.possibleOptions
          : [],
        required: CiscoDialPlanManagementDnRangesFormComponent._isEANLocalRoutePartitionEnabledOrShowing(entity)
          ? entity.ent_alt_num_route_partition?.required
          : false,
        show: CiscoDialPlanManagementDnRangesFormComponent._isEANLocalRoutePartitionEnabledOrShowing(entity)
          ? entity.ent_alt_num_route_partition?.show
          : false,
      },
      // E.164 Alternate Number
      e164_alt_num_number_mask: {
        name: entity.e164_alt_num_number_mask?.name,
        defaultValue: entity.e164_alt_num_number_mask?.value,
        required: entity.e164_alt_num_number_mask?.required,
        show: entity.e164_alt_num_number_mask?.show,
      },
      e164_alt_num_advertise_globally_via_ils: {
        enabled: entity.e164_alt_num_advertise_globally_via_ils?.value,
        show: entity.e164_alt_num_advertise_globally_via_ils?.show,
      },
      e164_alt_num_is_urgent: {
        enabled: entity.e164_alt_num_is_urgent?.value,
        show: entity.e164_alt_num_is_urgent?.show,
      },
      e164_alt_num_add_to_local_route_partition: {
        enabled: entity.e164_alt_num_add_to_local_route_partition?.value,
        show: entity.e164_alt_num_add_to_local_route_partition?.show,
      },
      e164_alt_num_route_partition: {
        name: entity.e164_alt_num_route_partition?.name,
        defaultValue: CiscoDialPlanManagementDnRangesFormComponent._isE164LocalRoutePartitionEnabledOrShowing(entity)
          ? entity.e164_alt_num_route_partition?.defaultOption
          : '',
        defaultValues: entity.e164_alt_num_route_partition?.defaultOption
          ? [entity.e164_alt_num_route_partition?.defaultOption]
          : [],
        possibleOptions: CiscoDialPlanManagementDnRangesFormComponent._isE164LocalRoutePartitionEnabledOrShowing(entity)
          ? entity.e164_alt_num_route_partition?.possibleOptions
          : [],
        required: CiscoDialPlanManagementDnRangesFormComponent._isE164LocalRoutePartitionEnabledOrShowing(entity)
          ? entity.e164_alt_num_route_partition?.required
          : false,
        show: CiscoDialPlanManagementDnRangesFormComponent._isE164LocalRoutePartitionEnabledOrShowing(entity)
          ? entity.e164_alt_num_route_partition?.show
          : false,
      },
      // PSTN Failover
      pstn_failover: {
        name: entity.pstn_failover?.name,
        defaultValue:
          CiscoDialPlanManagementDnRangesFormComponent._getE164AltSectionPopulated(entity) ||
          CiscoDialPlanManagementDnRangesFormComponent._getEntAltSectionPopulated(entity)
            ? entity.pstn_failover?.defaultOption
            : '',
        defaultValues: entity.pstn_failover?.defaultOption ? [entity.pstn_failover?.defaultOption] : [],
        possibleOptions:
          CiscoDialPlanManagementDnRangesFormComponent._getE164AltSectionPopulated(entity) ||
          CiscoDialPlanManagementDnRangesFormComponent._getEntAltSectionPopulated(entity)
            ? entity.pstn_failover?.possibleOptions
            : [],
        required:
          CiscoDialPlanManagementDnRangesFormComponent._getE164AltSectionPopulated(entity) ||
          CiscoDialPlanManagementDnRangesFormComponent._getEntAltSectionPopulated(entity)
            ? entity.pstn_failover?.required
            : false,
        show:
          CiscoDialPlanManagementDnRangesFormComponent._getE164AltSectionPopulated(entity) ||
          CiscoDialPlanManagementDnRangesFormComponent._getEntAltSectionPopulated(entity)
            ? entity.pstn_failover?.show
            : false,
      },
    } as CiscoDnRangesFormData;
  };

  toEntity = (formData: CiscoDnRangesFormData): CiscoDnRangesFormEntity => {
    return {
      // DN Ranges
      ranges: formData.ranges,
      // Line Appearance Settings
      advertise_distinct_mask_for_public_lines: {
        value: formData.advertise_distinct_mask_for_public_lines,
        name: 'advertise_distinct_mask_for_public_lines',
      },
      external_phone_number_mask: {
        name: formData.external_phone_number_mask.name,
        required: formData.external_phone_number_mask.required,
        show: formData.external_phone_number_mask.show,
        value: formData.external_phone_number_mask.defaultValue,
      },
      public_external_phone_number_mask: {
        name: formData.public_external_phone_number_mask.name,
        required: formData.public_external_phone_number_mask.required,
        show: formData.public_external_phone_number_mask.show,
        value: formData.public_external_phone_number_mask.defaultValue,
      },
      line_text_label: {
        name: formData.line_text_label.name,
        required: formData.line_text_label.required,
        show: formData.line_text_label.show,
        value: formData.line_text_label.defaultValue,
      },
      // Directory Number Settings
      route_partition: {
        name: formData.route_partition.name,
        defaultOption: formData.route_partition.defaultValue,
        possibleOptions: formData.route_partition.possibleOptions,
        required: formData.route_partition.required,
        show: formData.route_partition.show,
      },
      urgent_priority: {
        name: 'urgent_priority',
        value: formData.urgent_priority.enabled,
        show: formData.urgent_priority.show,
      },
      optional_line_group_membership: {
        name: formData.optional_line_group_membership.name,
        defaultOptions: [...formData.optional_line_group_membership.defaultValues],
        possibleOptions: formData.optional_line_group_membership.possibleOptions,
        show: formData.optional_line_group_membership.show,
      },
      auto_answer: {
        name: formData.auto_answer.name,
        defaultOption: formData.auto_answer.defaultValue,
        possibleOptions: formData.auto_answer.possibleOptions,
        required: true,
        show: formData.auto_answer.show,
      },
      monitoring_calling_search_space: {
        name: formData.monitoring_calling_search_space.name,
        defaultOption: formData.monitoring_calling_search_space.defaultValue,
        possibleOptions: formData.monitoring_calling_search_space.possibleOptions,
        required: formData.monitoring_calling_search_space.required,
        show: formData.monitoring_calling_search_space.show,
      },
      // Enterprise Alternate Number
      ent_alt_num_number_mask: {
        name: formData.ent_alt_num_number_mask.name,
        required: formData.ent_alt_num_number_mask.required,
        show: formData.ent_alt_num_number_mask.show,
        value: formData.ent_alt_num_number_mask.defaultValue,
      },
      ent_alt_num_advertise_globally_via_ils: {
        name: 'advertise_globally',
        value: formData.ent_alt_num_advertise_globally_via_ils.enabled,
        show: formData.ent_alt_num_advertise_globally_via_ils.show,
      },
      ent_alt_num_is_urgent: {
        name: 'is_urgent',
        value: formData.ent_alt_num_is_urgent.enabled,
        show: formData.ent_alt_num_is_urgent.show,
      },
      ent_alt_num_add_to_local_route_partition: {
        name: 'add_to_local_route_partition',
        value: formData.ent_alt_num_add_to_local_route_partition.enabled,
        show: formData.ent_alt_num_add_to_local_route_partition.show,
      },
      ent_alt_num_route_partition: {
        name: formData.ent_alt_num_route_partition.name,
        defaultOption: formData.ent_alt_num_route_partition.defaultValue,
        possibleOptions: formData.ent_alt_num_route_partition.possibleOptions,
        required: formData.ent_alt_num_route_partition.required,
        show: formData.ent_alt_num_route_partition.show,
      },
      // E.164 Alternate Number
      e164_alt_num_number_mask: {
        name: formData.e164_alt_num_number_mask.name,
        required: formData.e164_alt_num_number_mask.required,
        show: formData.e164_alt_num_number_mask.show,
        value: formData.e164_alt_num_number_mask.defaultValue,
      },
      e164_alt_num_advertise_globally_via_ils: {
        name: 'advertise_globally',
        value: formData.e164_alt_num_advertise_globally_via_ils.enabled,
        show: formData.e164_alt_num_advertise_globally_via_ils.show,
      },
      e164_alt_num_is_urgent: {
        name: 'is_urgent',
        value: formData.e164_alt_num_is_urgent.enabled,
        show: formData.e164_alt_num_is_urgent.show,
      },
      e164_alt_num_add_to_local_route_partition: {
        name: 'add_to_local_route_partition',
        value: formData.e164_alt_num_add_to_local_route_partition.enabled,
        show: formData.e164_alt_num_add_to_local_route_partition.show,
      },
      e164_alt_num_route_partition: {
        name: formData.e164_alt_num_route_partition.name,
        defaultOption: formData.e164_alt_num_route_partition.defaultValue,
        possibleOptions: formData.e164_alt_num_route_partition.possibleOptions,
        required: formData.e164_alt_num_route_partition.required,
        show: formData.e164_alt_num_route_partition.show,
      },
      // PSTN Failover
      pstn_failover: {
        name: formData.pstn_failover.name,
        defaultOption: formData.pstn_failover.defaultValue,
        possibleOptions: formData.pstn_failover.possibleOptions,
        required: formData.pstn_failover.required,
        show: formData.pstn_failover.show,
      },
    } as CiscoDnRangesFormEntity;
  };

  setDisplayFormValidationState() {
    this.extensionRangesDisplayFormValidationState =
      this.isFormSubmitted || this.extensionRangesDisplayForm.extensionRanges.showValidation
        ? this.extensionRangesDisplayForm.validationState
        : this.validationStates.VALID;
  }

  onRangeFormUpdate(data: SmacsFormsUpdate<RangeDisplayFormModel>) {
    // Always update validation state, don't always update entity. Async val will fire this multiple times so entity
    // won't necessarily change onFormUpdate
    if (isEqual(this.entity.ranges, data.new.ranges)) {
      this.setDisplayFormValidationState();
    } else {
      const newEntity = {
        ...cloneDeep(this.entity),
        ranges: data.new.ranges,
      };

      this.entitySource.next(newEntity);

      this.setDisplayFormValidationState();
    }
  }

  private _watchForChanges() {
    /**
     * When Enterprise or E164 alternate sections update the options available for PSTN Failover should refresh
     */
    const sub = this.smacsFormsUpdate$.subscribe((changes: SmacsFormsUpdate<CiscoDnRangesFormEntity>) => {
      const entNumberMaskChanged = !isEqual(changes.new.ent_alt_num_number_mask, changes.old.ent_alt_num_number_mask);
      const entAdvertiseGloballyChanged = !isEqual(
        changes.new.ent_alt_num_advertise_globally_via_ils,
        changes.old.ent_alt_num_advertise_globally_via_ils
      );
      const entIsUrgentChanged = !isEqual(changes.new.ent_alt_num_is_urgent, changes.old.ent_alt_num_is_urgent);
      const entAddToLocalRoutePartitionChanged = !isEqual(
        changes.new.ent_alt_num_add_to_local_route_partition,
        changes.old.ent_alt_num_add_to_local_route_partition
      );
      const entAltChanged =
        entNumberMaskChanged || entAdvertiseGloballyChanged || entIsUrgentChanged || entAddToLocalRoutePartitionChanged;

      const e164NumberMaskChanged = !isEqual(
        changes.new.e164_alt_num_number_mask,
        changes.old.e164_alt_num_number_mask
      );
      const e164AdvertiseGloballyChanged = !isEqual(
        changes.new.e164_alt_num_advertise_globally_via_ils,
        changes.old.e164_alt_num_advertise_globally_via_ils
      );
      const e164IsUrgentChanged = !isEqual(changes.new.e164_alt_num_is_urgent, changes.old.e164_alt_num_is_urgent);
      const e164AddToLocalRoutePartitionChanged = !isEqual(
        changes.new.e164_alt_num_add_to_local_route_partition,
        changes.old.e164_alt_num_add_to_local_route_partition
      );
      const e164AltChanged =
        e164NumberMaskChanged ||
        e164AdvertiseGloballyChanged ||
        e164IsUrgentChanged ||
        e164AddToLocalRoutePartitionChanged;

      if (entAltChanged || e164AltChanged) {
        const hasEnterprise = CiscoDialPlanManagementDnRangesFormComponent._getEntAltSectionPopulated(changes.new);
        const hasE164 = CiscoDialPlanManagementDnRangesFormComponent._getE164AltSectionPopulated(changes.new);

        this._refreshPstnFailover(hasEnterprise, hasE164);
      }
    });
    this._subscriptions.add(sub);
  }

  private _refreshPstnFailover(hasEnterprise: boolean, hasE164: boolean) {
    const options = CiscoDialPlanManagementDnRangesFormComponent._getInitialPstnOptions(hasEnterprise, hasE164);
    const field = this.fieldComponents.find((f) => f.fieldId === 'pstn_failover');
    field.applyComponentConfig(new SmacsSelectFieldConfig(false, [...options]));
    setTimeout(() => {
      const config = this.childSelectConfigs.find(
        (c: LegacySmacsSelectConfigComponent) => c.fieldId === 'pstn_failover'
      );
      if (config) {
        config.childFormComponent.validateAllFields();
      }
    }, 100);
  }

  private _getAutoAnswerOptions(): string[] {
    if (this.groupId) {
      const dnRanges = this.dialPlanGroups.find((group) => group.id === this.groupId).directoryNumberRangesSection;
      return dnRanges.customSelects.find((select) => select.name === 'auto_answer').possibleOptions;
    }
    return this.entity.auto_answer.possibleOptions;
  }
}
