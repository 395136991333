export const getStringFromProcessingStatus = (processingStatus: string) => {
  switch (processingStatus) {
    case 'COMPLETE': {
      return 'Completed';
    }
    case 'CANCELLED': {
      return 'Cancelled';
    }
    case 'SUBMITTED': {
      return 'Submitted';
    }
    case 'REQUESTED_SUPP': {
      return 'Support Requested';
    }
    case 'REQUESTED_CANCEL': {
      return 'Cancellation Requested';
    }
    case 'FOC': {
      return 'FOC Date Confirmed';
    }
    case 'DRAFT':
    case 'draft': {
      return 'Draft';
    }
    case 'EXCEPTION': {
      return 'Exception';
    }
    default: {
      return processingStatus.replaceAll('_', ' ').toLowerCase();
    }
  }
};

export const getProcessingStatusHtml = (processingStatus: string): string => {
  return `<span class="alert alert-inline alert-info text-capitalize"> ${getProcessingStatusIcon(
    processingStatus
  )} ${getStringFromProcessingStatus(processingStatus)}</span>`;
};

const getProcessingStatusIcon = (processingStatus: string) => {
  switch (processingStatus) {
    case 'COMPLETE': {
      return '<i class="icon-check-circle-alt text-success"></i>';
    }
    case 'CANCELLED': {
      return '<i class="icon-unavailable text-danger"></i>';
    }
    case 'SUBMITTED': {
      return '<i class="icon-send-email text-primary"></i>';
    }
    case 'REQUESTED_SUPP': {
      return '<i class="icon-search-agent text-warning"></i>';
    }
    case 'REQUESTED_CANCEL': {
      return '<i class="icon-search-agent text-danger"></i>';
    }
    case 'FOC': {
      return '<i class="icon-calendar-check-outline text-primary"></i>';
    }
    case 'EXCEPTION': {
      return '<i class="icon-warning-triangle text-danger"></i>';
    }
    case 'DRAFT': {
      return '<i class="icon-draft text-warning"></i>';
    }
    default: {
      return '';
    }
  }
};
