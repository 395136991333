import { Injectable } from '@angular/core';
// @ts-ignore
import Handlebars from 'handlebars/dist/cjs/handlebars';
import moment from 'moment';
import { TelephoneNumberFilter } from '../filters/telephone-number.filter';

export interface DefaultValueUser {
  username: string;
  firstName: string;
  lastName: string;
  primaryExtension: string;
}

export interface EditorUser {
  username: string;
  firstName: string;
  lastName: string;
  primaryExtension: string;
  mailId: string;
  did: string;
  userPrincipalName: string;
  extension: string;
  internationalDid: string;
}

const EXAMPLE_PREVIEW_USER: EditorUser = {
  username: 'jdoe',
  firstName: 'John',
  lastName: 'Doe',
  primaryExtension: '+15149401480',
  mailId: 'jdoe@stack8.com',
  did: '+15149401480',
  userPrincipalName: 'userPrincipalName',
  extension: '801550',
  internationalDid: '+913312345678',
};

@Injectable()
export class DefaultValueService {
  /**
   * Map of (template string) -> (template), so that we can quickly apply a template to an input. NOT doing this
   * has severe negative performance implications because the template string gets compiled multiple times per
   * digest cycle and it's an expensive operation.
   */
  templateCache = {};

  dynamicDefaultValue: string;

  private static _replaceLdapString(value: string): string {
    return value.replace(/ldap_/g, '');
  }

  constructor(private _telephoneNumberFilter: TelephoneNumberFilter) {
    Handlebars.registerHelper({
      toLowerCase: function (input: string) {
        return input.toLowerCase();
      },
      toUpperCase: function (input: string) {
        return input.toUpperCase();
      },
      firstLetterUpperCase: function (input: string) {
        if (input && input.length >= 1) {
          return input[0].toUpperCase();
        }
        return input;
      },
      firstLetterLowerCase: function (input: string) {
        if (input && input.length >= 1) {
          return input[0].toLowerCase();
        }
        return input;
      },
      firstLetter: function (input: string) {
        if (input && input.length >= 1) {
          return input[0];
        }
        return input;
      },
      timestamp: function () {
        return moment().format('YYYY-MM-DD');
      },
      first: function (x: number, input: string) {
        return input.slice(0, x);
      },
      last: function (x: number, input: string) {
        return input.slice(-x);
      },
      stripDomain: function (input: string) {
        if (input.indexOf('@') > 0) {
          input = input.slice(0, input.indexOf('@'));
        }
        return input;
      },
      ascii: function (input: string) {
        return input.replace(/[^\x00-\x7F]/g, '');
      },
      toInternationalNumberFormat: (input: string) => {
        return this._telephoneNumberFilter.transform(this.dynamicDefaultValue ?? EXAMPLE_PREVIEW_USER.internationalDid);
      },
    });
  }

  generatePreview = (formatString: string, dynamicDefaultValue?: string) => {
    if (dynamicDefaultValue) {
      this.dynamicDefaultValue = dynamicDefaultValue;
      EXAMPLE_PREVIEW_USER.did = this.dynamicDefaultValue;
    }
    if (!formatString) {
      return '';
    }
    if (formatString.includes('ldap_')) {
      const match = formatString.match(/ldap_\w+/g);
      if (match) {
        match.forEach((ldapString) => {
          const ldapAttribute = DefaultValueService._replaceLdapString(ldapString) as keyof EditorUser;
          EXAMPLE_PREVIEW_USER[ldapAttribute] = ldapAttribute;
        });
        formatString = DefaultValueService._replaceLdapString(formatString);
      }
    }
    return this.applyMoustacheTemplate(formatString, EXAMPLE_PREVIEW_USER);
  };

  applyMoustacheTemplate = (input: string, options: DefaultValueUser): string => {
    try {
      const template = Handlebars.compile(input, { strict: true, noEscape: true });
      return template(options);
    } catch (e) {
      console.warn('exception rendering moustache template', e);
      return input;
    }
  };

  updatePreviewLabel(label: string): string {
    const context = EXAMPLE_PREVIEW_USER;
    switch (label) {
      case 'Username':
        return label + `<p class="text-muted d-inline"> (ex: ${context.username}) </p>`;
      case 'First Name':
        return label + `<p class="text-muted d-inline"> (ex: ${context.firstName}) </p>`;
      case 'Last Name':
        return label + `<p class="text-muted d-inline"> (ex: ${context.lastName}) </p>`;
      case 'Primary Extension':
        return label + `<p class="text-muted d-inline"> (ex: ${context.primaryExtension}) </p>`;
      case 'Mail ID':
        return label + `<p class="text-muted d-inline"> (ex: ${context.mailId}) </p>`;
      case 'DID':
        return label + `<p class="text-muted d-inline"> (ex: ${context.did}) </p>`;
      case 'userPrincipalName':
        return label + `<p class="text-muted d-inline"> (from LDAP)</p>`;
      case 'Extension':
        return label + `<p class="text-muted d-inline"> (ex: ${context.extension}) </p>`;
      default:
        return label;
    }
  }
}
