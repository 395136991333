import { cloneDeep, difference, flatMap, forOwn, isEqual, map, uniq, values } from 'lodash';
import {
  AfterViewInit,
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';

import {
  SmacsFieldChannels,
  SmacsFieldComponentConfig,
  SmacsFieldConfig,
  SmacsFieldConfigListItem,
  SmacsFieldConfigs,
  SmacsFieldState,
  SmacsFieldStateCache,
  SmacsFormConfig,
  SmacsFormData,
  SmacsFormsUpdate,
  SmacsFormsValidationConfigItem,
  SmacsFormsValidationFlags,
  SmacsFormsValidationState,
} from './smacs-forms-models';
import { EMPTY, Observable, ReplaySubject, Subject, Subscriber } from 'rxjs';
import { SmacsFieldAbstractDirective } from './smacs-field-abstract.directive';
import { delay } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { SmacsFormStateService } from './smacs-form-state.service';
import { HandledDetailedModalErrorAlert } from '../modals/detailed-modal/detailed-modal.component';

/**
 * The SMACS Form framework is meant to be used in conjunction with one or more fields to simplify and standardize
 * form validation. The most basic example of a form would look something like this:
 *
 * ---
 *
 * ```
 * @Component({
 *   selector: 'smacs-example-form',
 *   template: `
 *     <smacs-text-input *ngFor="let field of fieldIds" [fieldId]="field">
 *     </smacs-text-input>
 *   `
 * })
 * export class SmacsExampleForm extends SmacsFormAbstractDirective<string[]> {
 *   fieldIds = [ 'exampleText1', 'exampleText2' ];
 *
 *   formConfig = {
 *     fields: {
 *       exampleText1: {},
 *       exampleText2: {}
 *     }
 *   } as SmacsFormConfig;
 * }
 * ```
 *
 * ---
 *
 * For a more realistic example, see `smacs-forms.stage.component` in the workbench.
 *
 * E -- The type of the entity, i.e. what you expect to receive from / send to the API.
 *
 * F -- The type of the formData, i.e. the model used internally by the form.
 *
 * @see SmacsFieldAbstractDirective
 */
@Directive({
  selector: '[smacsFormAbstract]',
})
export abstract class SmacsFormAbstractDirective<E, F extends SmacsFormData = E>
  implements OnChanges, OnDestroy, AfterViewInit
{
  /** The field components which exist in the DOM. See the Angular docs for ViewChildren and QueryList for more info.
   * @see ViewChildren
   * @see QueryList */
  @ViewChildren(SmacsFieldAbstractDirective) fieldComponents: QueryList<
    SmacsFieldAbstractDirective<any, any, SmacsFieldComponentConfig>
  >;

  /** Force validation on all fields. */
  @Input() forceValidation$: Observable<void>;
  /** The entity is what is sent to and from the parent component. If you need to update this from within the form,
   * be sure to use {@link entitySource}; do not update this object directly. */
  @Input() entity: E;

  @Input() parentSubmit$: Observable<any>;

  @Input() isErrorThrown = true;

  /** This subject is used to notify the form's parent when there has been a change to the entity.
   * @see SmacsFormsUpdate */
  smacsFormsUpdateSource = new Subject<SmacsFormsUpdate<E>>();
  /** The Observable connected to {@link smacsFormsUpdateSource}. */
  @Output() smacsFormsUpdate$ = this.smacsFormsUpdateSource.asObservable();

  /** This subject is used to validate if form is valid and submit form **/
  _validateAndSubmitSource = new Subject<boolean>();

  /** Set to true on first form submit regardless of form validation state. Passed along to field component
   * where it forces validation to be shown */
  isFormSubmitted = false;

  /** Set to true on submission of valid form, should be toggled back to false if necessary in the extending form.
   * Used to disable elements while request is in progress in the extending form **/
  isSubmitting = false;

  /** Fired on click of form submit and when form is valid */
  private _formSubmitSource = new Subject<void>();
  formSubmit$ = this._formSubmitSource.asObservable();

  /** The object intended to be used for a form's internal operations.
   * Must be transformed to/from {@link entity} when communicating to its parent component. */
  formData = {} as F;
  /** Stores the latest validation state on all of the fields. */
  validationFlags = {} as SmacsFormsValidationFlags;
  /** Stores the 'state' of each of the fields, i.e. the hidden/disabled/required/excluded status. */
  fieldStates = {} as SmacsFieldStateCache;
  /** The channels through which one may push updates from the form to the fields. */
  fieldChannels = {} as SmacsFieldChannels;

  ValidationState = SmacsFormsValidationState;
  /** The validation state of the form. It is INVALID if any fields are INVALID, and VALID if all fields are VALID. */
  validationState: SmacsFormsValidationState;

  /** It is required to update the shadow values internally by the form. **/
  updateShadowValue$ = new Subject<any>();

  /** Fields can be grouped for convenience of binding using their IDs (useful for *ngFor).
   * Note: This class member isn't used within the abstract form class, it is simply here for convenience. */
  fieldGroups: { [groupName: string]: string[] };

  /** If the form was populated using existing values. */
  isExisting = false;

  /** Fired when validation is run against field on submit*/
  isErrorPresent = new Subject<boolean>();

  /** Use this to directly in your html template to hide stuff until the async "afterViewInit" initialization is done.
   *  See {@link _init}. */
  isInitialized = false;

  /** Used to determine if form is inside modal to prevent route change confirmation after form in modal is submitted */
  isModal = false;

  /** Use this subject to update the {@link entity}. Typically you will push an update to this Subject if you are
   * initializing this form with a value passed down from the parent. */
  protected entitySource = new ReplaySubject<E>(1);
  /** It is required for implementing classes to provide a form configuration using this member. Each key in *fields*
   * should correspond to a *fieldId* provided to a field component in the HTML template. If this condition is not met,
   * an Error will be thrown at runtime. */
  protected abstract formConfig: SmacsFormConfig;
  private _oldFormConfig: SmacsFormConfig;

  /** Used mainly as a holder for the fields' configs. */
  private _fieldConfigList: SmacsFieldConfigListItem[];

  /** Used to prevent errors on forms taking time to load with child forms when navigating away **/
  private _viewInitTimeout: number;

  constructor(protected smacsFormStateService: SmacsFormStateService) {}

  /** Called when a new value is pushed to {@link entitySource}. */
  private _onReceiveNewEntity = (newEntity: E) => {
    this.formData = this.toFormData(newEntity);
    this._updateFieldValues();
  };

  /** This needs to be implemented in each form component that extends this class. This method is where the submit form
   * request is sent (i.e. POST or PUT). Whether the call returns success or error, the form needs to be set back
   * to a usable state in this method (e.g. enable any disabled buttons, etc).
   * **/
  protected abstract submit(): Observable<any>;

  /** We implement {@link AfterViewInit} here to ensure that all of our ViewChildren in {@link fieldComponents}
   * have finished initializing themselves before we attempt to modify them. */
  ngAfterViewInit() {
    this._viewInitTimeout = window.setTimeout(this._init, 0); // Prevents (invalid in this case) "Expression Changed" warning
  }

  /** If you override this, be sure to include `super.ngOnChanges(changes)` as the first line in your overriding method
   * to ensure that changes to the {@link entity} made by the parent component are registered properly. */
  ngOnChanges(changes: SimpleChanges) {
    const newEntity = changes.entity && changes.entity.currentValue;
    if (newEntity) {
      this.entitySource.next(newEntity);
    }
  }

  ngOnDestroy() {
    window.clearTimeout(this._viewInitTimeout);
    Object.values(this.fieldChannels).forEach((channelList) => {
      channelList.entitySource.complete();
      channelList.componentConfigSource.complete();
      channelList.validateSource.complete();
      channelList.stateSource.complete();
      channelList.isExistingSource.complete();
    });
  }

  isFormValid() {
    return this.validationState === SmacsFormsValidationState.VALID;
  }

  /**
   * Sets isExisting property on form, and on all form field components
   * @param isExisting
   */
  setIsExisting(isExisting: boolean) {
    this.isExisting = isExisting;
    Object.values(this.fieldChannels).forEach((channelList) => {
      channelList.isExistingSource.next(this.isExisting);
    });
  }

  validateAllFields() {
    Object.keys(this.fieldChannels).forEach((key: string) => {
      this.fieldChannels[key].validateSource.next();
    });
  }

  /** On _validateAndSubmitSource, check if the form contains any async field validation running,
   * if so, wait till validation is complete to proceed with submit.
   */
  protected checkForPendingField(): Observable<boolean> {
    let asyncFieldPresent = false;
    let hasValidatedAllFields = false;
    return new Observable((subscriber: Subscriber<boolean>) => {
      this.fieldComponents.forEach((fieldComponent, index) => {
        if (fieldComponent.showAsyncValidation && fieldComponent.isValidationInProgress) {
          asyncFieldPresent = true;
          fieldComponent.isAsyncValidationRunning.subscribe((isValidating) => {
            if (!isValidating && hasValidatedAllFields && this.isFormSubmitted) {
              this._setValidationState();
              setTimeout(() => {
                this.isErrorPresent.next(!this.isFormValid());
                subscriber.next(this.isFormValid());
                subscriber.complete();
              });
            }
          });
        } else if (this.fieldComponents.length === index + 1 && !asyncFieldPresent) {
          setTimeout(() => {
            this.isErrorPresent.next(!this.isFormValid());
            subscriber.next(this.isFormValid());
            subscriber.complete();
          });
        }
        hasValidatedAllFields = this.fieldComponents.length === index + 1;
      });
    });
  }

  protected _init = () => {
    this._subscribeToDynamicFields();
    this._initFieldConfigList();
    this._initFields();
    this._setFieldStates();

    this.entitySource.subscribe(this._onReceiveNewEntity);
    this._validateAndSubmitSource.subscribe((submit: boolean) => {
      if (submit) {
        this._setIsFormSubmitted(true);
        this.checkForPendingField().subscribe((valid) => {
          if (valid) {
            this._formSubmitSource.next();
            this._disableFields();
            this.isSubmitting = submit;
            this._trimEntityStringValues();
            this.submit().subscribe({
              next: () => {
                this.isSubmitting = false;
                this._setFieldStates();
                this.smacsFormStateService.setIsFormDirty(false);
              },
              error: (response) => {
                this.isSubmitting = false;
                this._setFieldStates();
                if (response !== EMPTY && !(response instanceof HandledDetailedModalErrorAlert) && this.isErrorThrown) {
                  throw new HttpErrorResponse(response);
                }
              },
            });
          } else {
            this.scrollToValidationError();
            this.isSubmitting = false;
          }
        });
      } else {
        // In cases where this subscription is fired twice in quick succession, the setTimeout ensures that the fields
        // are reset to their normal states properly. This is important for when we quickly emit true then false.
        setTimeout(() => {
          this._setFieldStates();
          this.isSubmitting = submit;
        }, 0);
      }
    });

    this.isInitialized = true;
  };

  /** Subscribes to the fieldComponents query list changes, so that we may easily initialize new components. */
  private _subscribeToDynamicFields = () => {
    this._oldFormConfig = cloneDeep(this.formConfig);
    this.fieldComponents?.changes.subscribe((fields: SmacsFieldAbstractDirective<any>[]) => {
      this._initializeDynamicFields(fields);
    });
  };

  /** If one to many fields are added after the form has been initialized, this handles the creation of those fields. */
  private _initializeDynamicFields(fields: SmacsFieldAbstractDirective<any>[]) {
    if (isEqual(this._oldFormConfig, this.formConfig)) {
      // If the form config hasn't been updated, it's likely that this is just a field property being changed.
      return;
    }

    const oldFieldIds = Object.keys(this._oldFormConfig.fields);
    const newFieldIds = Object.keys(this.formConfig.fields);

    if (newFieldIds.length !== fields.length) {
      // This happens if we're making multiple modifications... just wait until we have the correct number of fields.
      return;
    }

    this._initFieldConfigList();

    const addedFieldIds = difference<string>(newFieldIds, oldFieldIds);
    addedFieldIds.forEach((fId) => {
      const addedField = fields.find((field) => field.fieldId === fId);
      this.initFieldComponent(addedField);
      this._updateFieldComponentConfig(fId);
    });

    const removedFieldIds = difference<string>(oldFieldIds, newFieldIds);
    removedFieldIds.forEach((fId) => {
      delete this.validationFlags[fId];
    });

    this.fieldComponents.forEach((fieldComponent) => this.crossWithOtherFields(fieldComponent.fieldId));
    this._setValidationState();

    this._oldFormConfig = cloneDeep(this.formConfig);
    // Update the entity so that our new fields get their intended values.
    this.entitySource.next(this.toEntity(this.formData));
  }

  private _initFieldConfigList = () => {
    this._fieldConfigList = map<SmacsFieldConfigs, SmacsFieldConfigListItem>(
      this.formConfig.fields,
      (config: SmacsFieldConfig, fieldId: string) => ({ fieldId, ...config } as SmacsFieldConfigListItem)
    );
  };

  private _initFields = () => {
    this.fieldComponents.forEach((fieldComponent) => this.initFieldComponent(fieldComponent));
    // wait until all fields are initialized before trying to cross
    this.fieldComponents.forEach((fieldComponent) => this.crossWithOtherFields(fieldComponent.fieldId));

    this._updateFieldComponentConfigs();
  };

  private _throwDevError = (msg: string) => {
    throw new Error('SmacsForms - ' + msg);
  };

  private _checkEachFieldHasAConfig = () => {
    this.fieldComponents.forEach((component) => {
      if (component.fieldId && !this._fieldConfigList.map((f) => f.fieldId).includes(component.fieldId)) {
        this._throwDevError(`No configuration found for field component. Component ID = [ ${component.fieldId} ]`);
      }
    });
  };

  private _checkEachConfigHasAField = () => {
    this._fieldConfigList.forEach((config) => {
      if (config.fieldId && !this.fieldComponents.map((f) => f.fieldId).includes(config.fieldId)) {
        this._throwDevError(
          `No field component found for configuration. Field Configuration ID = [ ${config.fieldId} ]`
        );
      }
    });
  };

  private _checkFormConfig = () => {
    this._checkEachFieldHasAConfig();
    this._checkEachConfigHasAField();
  };

  /** Initialize the field component by providing it with the Observables needed to receive updates from this form.
   * If you are calling this method after adding a new field, be sure to wait for {@link fieldComponents} to pick up
   * the new DOM element first. */
  protected initFieldComponent<X, Y, C extends SmacsFieldComponentConfig>(
    fieldComponent: SmacsFieldAbstractDirective<X, Y, C>
  ) {
    const fieldEntitySource = new Subject<X>();
    const fieldComponentConfigSource = new Subject<C>();
    const fieldValidateSource = new Subject<void>();
    const fieldStateSource = new Subject<SmacsFieldState>();
    const isExistingSource = new Subject<boolean>();

    this._checkFormConfig();

    this.fieldChannels[fieldComponent.fieldId] = {
      entitySource: fieldEntitySource,
      componentConfigSource: fieldComponentConfigSource,
      validateSource: fieldValidateSource,
      stateSource: fieldStateSource,
      isExistingSource: isExistingSource,

      smacsFormUpdate$: fieldComponent.smacsFormsUpdate$,
    };

    Object.assign(fieldComponent, {
      entity$: fieldEntitySource.asObservable(),
      componentConfig$: fieldComponentConfigSource.asObservable(),
      validate$: fieldValidateSource.asObservable(),
      state$: fieldStateSource.asObservable(),
      forceValidation$: this.forceValidation$,
      isExisting$: isExistingSource.asObservable(),

      config: this.formConfig.fields[fieldComponent.fieldId] as SmacsFieldConfig<C>,
      evaluateWithForm: this._evaluateWithForm.bind(this),
      updateShadowValue$: this.updateShadowValue$.asObservable(),
      isFormSubmitted: this.isFormSubmitted,
    } as Partial<SmacsFieldAbstractDirective<X, Y, C>>);

    fieldComponent.smacsFormsUpdate$.subscribe((update: SmacsFormsUpdate<any>) =>
      this._onFieldUpdate(update, fieldComponent.fieldId)
    );

    fieldComponent.init();

    // Ensure fields all have correct value for isExisting, this may have been set before the fields were initialized
    if (this.isExisting) {
      this.setIsExisting(this.isExisting);
    }
  }

  /** Initializes field cross-validation. If there is a field whose validation depends on the value of another field,
   * ensure that the former is re-validated whenever we update the value of the latter. */
  protected crossWithOtherFields(fieldId: string) {
    const crossableValidators: SmacsFormsValidationConfigItem[] = (
      this.formConfig.fields[fieldId].validation || []
    ).filter((v) => v.injectValuesFromFields);
    if (!crossableValidators.length) {
      return;
    }

    // In this context, I use 'suzerain' to mean a field that triggers validation of this field.
    // If you can think of a better name, please change it.
    const suzerains: string[] = flatMap(crossableValidators, (v) => v.injectValuesFromFields);
    const uniqueSuzerains = uniq(suzerains);
    uniqueSuzerains.forEach((suzerain) => {
      const suzerainFieldChannel = this.fieldChannels[suzerain];
      suzerainFieldChannel.smacsFormUpdate$.pipe(delay(0)).subscribe((update) => {
        if (!isEqual(update.new, update.old)) {
          this.fieldChannels[fieldId].validateSource.next();
        }
      });
    });
  }

  /** On form submit, check for validation errors and set @isFormSubmitted to true.**/
  protected _setIsFormSubmitted(isSubmitted: boolean) {
    this.isFormSubmitted = isSubmitted;
    this.fieldComponents?.forEach((item) => {
      item.isDirty = isSubmitted;
      item.showValidation = isSubmitted;
      item.isFormSubmitted = isSubmitted;
    });
  }

  protected _setFieldStates = () => {
    this._fieldConfigList?.map((fc: SmacsFieldConfigListItem) => fc.fieldId).forEach(this._setFieldState);
    this._setValidationState();
  };

  /** This method is used by the fields to perform validation and autogeneration. */
  private _evaluateWithForm<T>(
    cb: (fieldVal: T, ...injected: any[]) => any,
    fieldVal: T,
    injectValuesFromFields: (keyof F)[] = []
  ) {
    return cb(fieldVal, ...injectValuesFromFields.map((id) => this.formData[id]));
  }

  /** Called when there is an update to the {@link entity}. Updates the fields with their piece of the new entity. */
  private _updateFieldValues = () => {
    this._fieldConfigList.forEach((field: SmacsFieldConfigListItem) => {
      const oldFieldData = this.fieldComponents.find((component) => component.fieldId === field.fieldId)?.entity;
      const newFieldData = this.formData[field.fieldId];
      // Only update when the field has changed to prevent async validation triggers on same values.
      if (!isEqual(oldFieldData, newFieldData)) {
        this.fieldChannels[field.fieldId].entitySource.next(newFieldData);
      }
    });
    this._updateParent(this.formData);
  };

  private _updateFieldComponentConfig = (fieldId: string) => {
    this.fieldChannels[fieldId].componentConfigSource.next(this.formConfig.fields[fieldId].componentConfig);
  };

  private _updateFieldComponentConfigs = () => {
    this._fieldConfigList
      .filter((c) => c.componentConfig)
      .forEach((field: SmacsFieldConfigListItem) => this._updateFieldComponentConfig(field.fieldId));
  };

  /** Called whenever a field is updated. Changes the form's state based on the update. */
  private _onFieldUpdate = (update: SmacsFormsUpdate<any>, fieldId: string) => {
    if (isEqual(update.new, update.old) && isEqual(update.valid, this.validationFlags[fieldId])) {
      return;
    }

    this.fieldComponents.forEach((field) => {
      if (field.config.disabled && field.getDisabledTooltip()) {
        field.shouldDisableTooltip = !field.config.disabled();
      }
    });

    this.formData = {
      ...this.formData,
      [fieldId]: update.new,
    };
    this.validationFlags[fieldId] = this._toSmacsFormsValidationState(update.valid);

    this._setFieldStates();
    this._updateParent(this.formData);
  };

  /** Pushes a new value to the form's parent component. */
  private _updateParent(newFormData: F) {
    const oldEntity = this.entity;
    this.entity = this.toEntity(newFormData);

    this.smacsFormsUpdateSource.next({
      new: this.entity,
      old: oldEntity,
      valid: this.validationState,
    });
  }

  /** Updates the form's validation state based on the validation state of its fields.
   * If any field is INVALID, the form is INVALID. If any field is PENDING, the form is PENDING.
   * Otherwise, the form is considered VALID.
   * @param flagsObj The object containing the validation states of the fields.
   * @param ignoredFields The form will ignore the validation state of the provided field IDs. */
  private _validateFlagGroup(flagsObj: { [fieldId: string]: SmacsFormsValidationState }, ignoredFields?: string[]) {
    let _flagsObj = flagsObj;

    if (Array.isArray(ignoredFields)) {
      _flagsObj = { ...flagsObj };
      ignoredFields.forEach((id) => (_flagsObj[id] = SmacsFormsValidationState.VALID));
    }

    const flags = values(_flagsObj);

    return flags.some((f) => f === SmacsFormsValidationState.INVALID)
      ? SmacsFormsValidationState.INVALID
      : flags.some((f) => f === SmacsFormsValidationState.PENDING)
      ? SmacsFormsValidationState.PENDING
      : SmacsFormsValidationState.VALID;
  }

  private _toSmacsFormsValidationState = (validity: SmacsFormsValidationState | boolean): SmacsFormsValidationState => {
    return typeof validity === 'boolean'
      ? validity === true
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID
      : validity;
  };

  protected _setValidationState = () => {
    const ignoredFields = [] as string[];

    forOwn(this.fieldStates, (state: SmacsFieldState, fieldId: string) => {
      if (state.valExcluded) {
        ignoredFields.push(fieldId);
      }
    });

    this.validationState = this._validateFlagGroup(this.validationFlags, ignoredFields);
  };

  private _setFieldState = (fieldId: string) => {
    const config = this.formConfig.fields[fieldId];

    const newState = (this.fieldStates[fieldId] = {
      defaultValue: config.defaultValue === undefined ? null : config.defaultValue(),
      hidden: config.hidden === undefined ? false : config.hidden(),
      disabled: config.disabled === undefined ? false : config.disabled(),
      valExcluded: config.valExcluded === undefined ? false : config.valExcluded(),
      required:
        config.required === undefined
          ? false
          : typeof config.required === 'boolean'
          ? config.required
          : config.required(),
      helpText:
        config.helpText === undefined ? '' : typeof config.helpText === 'string' ? config.helpText : config.helpText(),
      columnClasses: {
        label: this.formConfig?.options?.columnClasses?.label || 'col-lg-3 text-lg-end',
        input: this.formConfig?.options?.columnClasses?.input || 'col-lg-9',
      },
      canModifyFormState: !this.isModal,
    });

    this.fieldChannels[fieldId].stateSource.next(newState);
  };

  private _disableFields = () => {
    this._fieldConfigList
      .map((fc) => fc.fieldId)
      .forEach((fieldId) => {
        this.fieldStates[fieldId].disabled = true;
        this.fieldChannels[fieldId].stateSource.next(this.fieldStates[fieldId]);
      });
  };

  private _trimEntityStringValues() {
    if (this.entity) {
      const oldEntity = cloneDeep(this.entity);
      if (typeof oldEntity === 'object') {
        Object.keys(this.entity).forEach((key: string) => {
          const keyValue: E[keyof E] = this.entity[key as keyof typeof this.entity];
          const valueType = typeof keyValue;
          if (valueType === 'string') {
            const stringValue: string = keyValue as string;
            this.entity = {
              ...this.entity,
              [key]: stringValue.trim(),
            };
          }
        });
      } else {
        const entity = this.entity as string;
        this.entity = entity.trim() as E;
      }

      this.formData = this.toFormData(this.entity);
      this.smacsFormsUpdateSource.next({
        new: this.entity,
        old: oldEntity,
        valid: this.validationState,
      });
    }
  }

  protected scrollToValidationError() {
    const htmlElems: HTMLCollection = document.getElementsByClassName('smacs-forms-error');
    const elems = [].slice.call(htmlElems).filter((elem: HTMLElement) => {
      const isCard = elem.closest('#collapsibleCardBody') as HTMLElement;
      if (!isCard || isCard.offsetHeight) {
        return elem;
      }
    });

    const modal = document.getElementById('detailed-modal');
    if (elems.length > 0) {
      const sideNavContent = <HTMLDivElement>document.querySelector('.side-navigation__content');
      if (sideNavContent) {
        const smacsFormsError = <HTMLDivElement>htmlElems[0];
        sideNavContent.scrollTop = smacsFormsError.offsetTop - sideNavContent.offsetTop;
      } else if (modal) {
        modal.closest('smacs-detailed-modal')?.getElementsByClassName('smacs-forms-error')[0]?.scrollIntoView();
      } else {
        elems[0].scrollIntoView();
      }
    }
  }

  /** This must be implemented if the class's generic params (E & F) are not the same. Transforms the form's internal
   * state into the type that the form's parent component expects. Typically this is called after every field update.
   * @see toFormData */
  protected toEntity = (formData: F): E => cloneDeep<any>(formData) as E;

  /** This must be implemented if the class's generic params (E & F) are not the same. Transforms the entity passed down
   * from the parent into a type that is usable by the form. Typically this is called whenever the entity is updated.
   * @see toEntity */
  protected toFormData = (entity: E): F => cloneDeep<any>(entity) as F;
}
