import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UnusedTile } from '../../../state-management/models/report-dashboard.model';
import { Store } from '@ngrx/store';
import { DashboardState } from '../../dashboard.state';
import { initialUnusedTile } from '../../../state-management/reducers/unused-tile.reducer';
import { dashboardActions } from '../../../state-management/actions/dashboard.actions';
import { CustomUiOptionsContext } from '../../../../../shared/contexts/custom-ui-options.context';

@Component({
  selector: 'app-dashboard-unused-devices-tile',
  templateUrl: './dashboard-unused-devices-tile.component.html',
})
export class DashboardUnusedDevicesTileComponent implements OnInit, OnDestroy {
  unusedTileData$: Observable<UnusedTile>;
  private _subscriptions = new Subscription();

  constructor(private store: Store<DashboardState>, private _customUiOptionsContext: CustomUiOptionsContext) {
    this.unusedTileData$ = this.store.select('reportDashboardUnusedTile');
  }

  ngOnInit() {
    const uiOptionsSub = this._customUiOptionsContext.state$.subscribe(() => {
      /**
       * When the component is first initialized, an action is dispatched to load the tile data.
       * An effect will be listening to this action and will fetch the tile data.
       * The reducer will update the state of the tile and send it back to the component.
       */
      this.store.dispatch(dashboardActions.loadUnusedTileAction(initialUnusedTile));
    });
    this._subscriptions.add(uiOptionsSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
