import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TranslationPatternRef } from '../../../shared/models/generated/smacsModels';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../button/button.component';

@Injectable()
export class DnDetailService {
  constructor(private translateService: TranslateService, private smacsModalService: SmacsModalService) {}

  getDid(translationPatternRef: TranslationPatternRef[], throwErrorModal: boolean): TranslationPatternRef {
    if (translationPatternRef) {
      const dids = translationPatternRef.map((translationPattern) => translationPattern.pattern).join(',');

      const translationPatterns = translationPatternRef.filter((translationPattern) => {
        return (
          translationPattern.pattern.length >= 10 &&
          !translationPattern.pattern.startsWith('9.') &&
          translationPattern.pattern.match(/^[+0-9]/)
        );
      });

      if (translationPatterns.length) {
        if (translationPatterns.length === 1) {
          return translationPatterns[0];
        } else if (throwErrorModal) {
          const options = {
            modalViewProperties: {
              title: 'tkey;unsupported.user.title',
              iconStackedClasses: {
                topIcon: SmacsIcons.UNAVAILABLE,
                topIconCssClass: 'text-danger',
                bottomIcon: SmacsIcons.USER,
              },
              promptBody: this.translateService.instant(
                'tkey;userdetail.primary_extension.multiple_dids_not_supported',
                {
                  dids,
                  dn: translationPatterns[0].destination,
                }
              ),
              buttons: [
                {
                  label: 'tkey;dialogs.button.ok',
                  buttonClass: ButtonStyles.INFO,
                  dataAutomation: 'confirmation-modal-cancel-button',
                },
              ],
            },
          };

          this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
        }
      }
    }

    return null;
  }
}
