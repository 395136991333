<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1">
    <app-breadcrumbs>
      <app-breadcrumb-card-item [aboutCardId]="59" [hidden]="!isHostedEnabled"></app-breadcrumb-card-item>
      <app-breadcrumb-card-item
        *ngIf="!isHostedEnabled"
        [innerHTML]="'tkey;admin.proxy_server_management.proxy_feature_settings.about' | translate"
      ></app-breadcrumb-card-item>
    </app-breadcrumbs>
    <app-loading-block *ngIf="isLoading"></app-loading-block>
    <smacs-ms-proxy-feature-settings-form
      *ngIf="!isLoading && isHostedEnabled"
      [entity]="zpmProxyFeatureSettingsEntity"
    ></smacs-ms-proxy-feature-settings-form>
    <smacs-cisco-proxy-feature-settings-form
      *ngIf="!isLoading && !isHostedEnabled"
      [entity]="zpcProxyFeatureSettingsEntity"
    ></smacs-cisco-proxy-feature-settings-form>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
