import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { HealthStatus, LdapConfiguration, State } from '../../../../shared/models/generated/smacsModels';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { LdapResource } from '../../../../shared/resources/ldap.resource';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ToastService } from '../../../../shared/services/toast.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { AdminLdapManagementSettingsLdapServerUrlComponent } from './ldap-server-url/admin-ldap-management-settings-ldap-server-url.component';
import { cloneDeep } from 'lodash';

enum ServerStatusCauses {
  CONNECTION = 'Connection Issue',
  AUTHENTICATION = 'Incorrect Credentials',
}

@Component({
  selector: 'smacs-admin-ldap-management-settings',
  templateUrl: './admin-ldap-management-settings.component.html',
})
export class AdminLdapManagementSettingsComponent
  extends SmacsFormAbstractDirective<LdapConfiguration>
  implements OnInit, OnDestroy
{
  @Input() ldapConfiguration: LdapConfiguration;
  @Input() isSaved: Subject<string>;
  @Input() isProxyServerEnabled: boolean;

  @ViewChild(AdminLdapManagementSettingsLdapServerUrlComponent)
  childComponent: AdminLdapManagementSettingsLdapServerUrlComponent;

  window = window;
  isPending = false;
  smacsIcons = SmacsIcons;

  formConfig = {
    fields: {
      urls: {
        dataAutomation: 'admin-ldap-management-ldap-server-url-list',
      },
      username: {
        label: 'tkey;admin.ldap.settings.account.username.label',
        helpText: 'tkey;admin.ldap.settings.account.username.help',
        dataAutomation: 'ldap-management-username-input',
        required: true,
      },
      password: {
        label: 'tkey;admin.ldap.settings.account.password.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
        dataAutomation: 'ldap-management-password-input',
        required: () => this._isFirstTimeSetup(),
      },
      userBaseDn: {
        label: 'tkey;admin.ldap.settings.configuration.userbasedn.label',
        helpText: 'tkey;admin.ldap.settings.general.user_base_dn.help',
        dataAutomation: 'ldap-management-user-base-dn-input',
        required: true,
      },
      userIdAttributeName: {
        label: 'tkey;admin.ldap.settings.configuration.userIdAttributeName.label',
        helpText: 'tkey;admin.ldap.settings.general.user_id.help',
        dataAutomation: 'ldap-management-user-id-attribute-name-input',
        required: true,
        validation: [
          {
            validator: (val: string) =>
              !val || val.toLowerCase() === 'samaccountname'
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.WARNING,
            message: this.translateService.instant(
              'tkey;admin.ldap.settings.configuration.userIdAttributeName.feedback.message'
            ),
          },
        ],
      },
      userObjectClass: {
        label: 'tkey;admin.ldap.settings.configuration.userObjectClass.label',
        helpText: 'tkey;admin.ldap.settings.configuration.userObjectClass.help',
        dataAutomation: 'ldap-management-user-object-class-input',
        required: true,
      },
      groupBaseDn: {
        label: 'tkey;admin.ldap.settings.configuration.groupbasedn.label',
        helpText: 'tkey;admin.ldap.settings.general.group_base_dn.help',
        dataAutomation: 'ldap-management-group-base-dn-input',
        required: true,
      },
      groupAttributeName: {
        label: 'tkey;admin.ldap.settings.configuration.group_attribute_name.label',
        helpText: 'tkey;admin.ldap.settings.general.groups.help',
        dataAutomation: 'ldap-management-group-attribute-name-input',
        required: true,
      },
      groupObjectClass: {
        label: 'tkey;admin.ldap.settings.configuration.groupObjectClass.label',
        helpText: 'tkey;admin.ldap.settings.configuration.groupObjectClass.help',
        dataAutomation: 'ldap-management-group-object-class-input',
        required: true,
      },
      connectTimeout: {
        label: 'tkey;admin.ldap.settings.configuration.connect_timeout.label',
        helpText: 'tkey;admin.ldap.settings.general.connection_timeout.help',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        dataAutomation: 'ldap-management-connection-timeout-input',
        required: true,
        validation: [
          {
            validator: (val: number) =>
              val >= 1 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: this.translateService.instant('tkey;admin.ldap.invalid.connect_timeout'),
          },
          {
            validator: (val: number) =>
              val <= 12 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: this.translateService.instant('tkey;admin.ldap.invalid.connect_timeout'),
          },
        ],
      },
      readTimeout: {
        label: 'tkey;admin.ldap.settings.configuration.read_timeout.label',
        helpText: 'tkey;admin.ldap.settings.general.read_timeout.help',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        dataAutomation: 'ldap-management-read-timeout-input',
        required: true,
        validation: [
          {
            validator: (val: number) =>
              val >= 1 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: this.translateService.instant('tkey;admin.ldap.invalid.read_timeout'),
          },
          {
            validator: (val: number) =>
              val <= 60 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: this.translateService.instant('tkey;admin.ldap.invalid.read_timeout'),
          },
        ],
      },
      siteAttributeName: {
        label: 'tkey;admin.ldap.settings.site.title',
        helpText: 'tkey;admin.ldap.settings.site.help',
        dataAutomation: 'ldap-management-site-attribute-name-input',
      },
      writeToSiteEnabled: {
        label: 'tkey;admin.ldap.settings.writeToSiteEnabled.title',
        helpText: 'tkey;admin.ldap.settings.writeToSiteEnabled.helptext',
        dataAutomation: 'ldap-management-write-to-site-enabled-input',
        disabled: () => !this.formData.siteAttributeName,
        disabledTooltip: 'tkey;admin.ldap.settings.writeToSiteEnabled.disabled_tooltip',
      },
      extensionAttributeName: {
        label: 'tkey;admin.ldap.settings.configuration.extension_attribute_name.label',
        helpText: 'tkey;admin.ldap.settings.dial_plan.help',
        dataAutomation: 'ldap-management-extension-attribute-name-input',
      },
      e164NumberAttributeName: {
        label: 'tkey;admin.ldap.settings.configuration.e164_number_attribute_name.label',
        helpText: 'tkey;admin.ldap.settings.dial_plan.help',
        dataAutomation: 'ldap-management-e164-number-attribute-name-input',
      },
      photoAttributeName: {
        label: 'tkey;admin.ldap.settings.configuration.photo_attribute_name.label',
        helpText: 'tkey;admin.ldap.settings.additional.photo.help',
        dataAutomation: 'ldap-management-photo-attribute-name-input',
      },
      additionalAttributeNames: {
        label: 'tkey;admin.ldap.settings.configuration.new_additional_attribute_name.label',
        dataAutomation: 'ldap-management-additional-attributes-input',
      },
    },
  } as SmacsFormConfig;

  constructor(
    private ldapResource: LdapResource,
    private toastService: ToastService,
    private translateService: TranslateService,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.setIsExisting(!!this.ldapConfiguration.username);
    this._setDefaultValues();
    this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<LdapConfiguration>) => {
      if (!data.new.siteAttributeName && data.new.writeToSiteEnabled) {
        const entityCopy = cloneDeep(data.new);
        entityCopy.writeToSiteEnabled = false;
        this.entitySource.next(entityCopy);
      }
    });
  }

  onRemoveServer(index: number) {
    const newLdap = {
      ...this.formData,
      urls: this.formData.urls.filter((item: string, i: number) => index !== i),
    };

    this.entitySource.next({
      ...newLdap,
    });
  }

  protected submit() {
    return this._testConnections();
  }

  private _testConnections(): Observable<void> {
    return new Observable((subscriber) => {
      const invalidForm = this.childComponent.childForms.find((form) => !form.isFormValid());

      if (invalidForm) {
        this.scrollToValidationError();
        subscriber.next(null);
        subscriber.complete();
      } else {
        const ldapConnectionRequests = this.formData.urls.map((url: string) => {
          const ldapConnection = {
            url: url,
            username: this.formData.username.trim(),
            password: this.formData.password,
            userBaseDn: this.formData.userBaseDn,
            groupBaseDn: this.formData.groupBaseDn,
            connectTimeout: this.formData.connectTimeout * 1000,
            readTimeout: this.formData.readTimeout * 1000,
          };

          return this.ldapResource.testLdapConnectionConfiguration(ldapConnection);
        });

        forkJoin(ldapConnectionRequests).subscribe((serverStatuses: HealthStatus[]) => {
          const badServerStatuses = serverStatuses.filter(
            (serverStatus: HealthStatus) => serverStatus.state !== State.OK
          );

          if (!badServerStatuses.length) {
            this._saveLdapConfiguration().subscribe(() => {
              this.isSaved.next(null);
              this.formConfig.fields['password'].required = false;
              this.toastService.push(
                ToastTypes.SUCCESS,
                this.smacsIcons.LDAP,
                'tkey;shared.toast.save.success.title',
                'tkey;admin.ldap.settings.saved'
              );

              subscriber.next(null);
              subscriber.complete();
            });
          } else {
            const badServerStatus = badServerStatuses[0];

            if (badServerStatus.cause === ServerStatusCauses.CONNECTION) {
              this.toastService.push(
                ToastTypes.ERROR,
                this.smacsIcons.LDAP,
                'tkey;shared.toast.save.fail.title',
                'tkey;admin.ldap.connection.error',
                { url: badServerStatus.description }
              );
            } else if (badServerStatus.cause === ServerStatusCauses.AUTHENTICATION) {
              this.toastService.push(
                ToastTypes.ERROR,
                this.smacsIcons.LDAP,
                'tkey;shared.toast.save.fail.title',
                'tkey;admin.ldap.authentication.error'
              );
            }

            subscriber.next(null);
            subscriber.complete();
          }
        });
      }
    });
  }

  private _saveLdapConfiguration() {
    const ldapConfiguration = {
      ...this.formData,
      username: this.formData.username.trim(),
      userBaseDn: this.formData.userBaseDn.trim(),
      userIdAttributeName: this.formData.userIdAttributeName.trim(),
      groupAttributeName: this.formData.groupAttributeName.trim(),
      groupBaseDn: this.formData.groupBaseDn.trim(),
      readTimeout: this.formData.readTimeout * 1000,
      connectTimeout: this.formData.connectTimeout * 1000,
      siteAttributeName: !!this.formData.siteAttributeName ? this.formData.siteAttributeName.trim() : '',
      extensionAttributeName: !!this.formData.extensionAttributeName ? this.formData.extensionAttributeName : '',
      e164NumberAttributeName: !!this.formData.e164NumberAttributeName ? this.formData.e164NumberAttributeName : '',
    } as LdapConfiguration;
    return this.ldapResource.putLdapConfiguration(ldapConfiguration);
  }

  private _setDefaultValues() {
    this.entitySource.next({
      ...this.ldapConfiguration,
    });
  }

  private _isFirstTimeSetup() {
    return !this.ldapConfiguration.username || this.ldapConfiguration.username.length === 0;
  }
}
