import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { DistributionList, DistributionListMember } from '../../../shared/models/generated/smacsModels';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash';
import { DistributionListsResource } from '../../../shared/resources/distribution-lists.resource';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../shared/services/toast.service';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../shared/entity-table/entity-table.models';

@Component({
  selector: 'smacs-distribution-list',
  templateUrl: './distribution-list.component.html',
  styleUrls: ['./distribution-list-component.scss'],
})
export class DistributionListComponent implements OnInit, OnDestroy {
  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];
  distributionListMember: DistributionListMember[] = [];
  smacsIcons = SmacsIcons;
  isLoading = true;

  private _serverId: string;
  private _distributionListId: number;
  private _subscription = new Subscription();
  private _distributionList: DistributionList;

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private bottomNavService: BottomNavService,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private distributionListsResource: DistributionListsResource,
    protected router: Router
  ) {}

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  ngOnInit(): void {
    this._serverId = get(this.route, 'snapshot.params.serverid');
    this._distributionListId = get(this.route, 'snapshot.params.distributionlistid');
    this._getDistributionListMembers();
    this._initBottomNav();
  }

  deleteClicked(member: DistributionListMember) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        promptBody: this.translateService.instant('tkey;global.sure.delete.distributionlist.member', {
          user: member,
          list: this._distributionList,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._postDeleteMember(member);
            },
          },
        ],
      },
    };

    this.smacsModalService
      .openPromptModal(() => options.modalViewProperties, options)
      .subscribe(() => this._refreshList());
  }

  onButtonClicked() {
    const url = `server/${this._serverId}/distribution-list/${this._distributionListId}/add`;
    this.router.navigateByUrl(url);
  }

  private _postDeleteMember(memberToDelete: DistributionListMember): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.distributionListsResource
        .removeMemberFromDistributionList(this._serverId, this._distributionListId, memberToDelete.id)
        .subscribe(() => {
          this.toastService.pushDeleteToast('tkey;pages.distributionlist.member', memberToDelete.displayName);
          subscriber.next();
          subscriber.complete();
        });
    });
  }

  private _initBottomNav(): void {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'add-distribution-list-member-button',
          label: 'tkey;pages.distributionlist.add.member',
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'add-distribution-list-member-button',
          state: {
            pending: false,
            buttonDisableState: {
              disabled: false,
              tooltipKey: '',
            },
          },
          icon: this.smacsIcons.ADD,
          type: ButtonTypes.BUTTON,
          cb: () => this.onButtonClicked(),
        },
      ])
    );
  }

  private _setBreadcrumbs() {
    this.breadcrumbsService.updateBreadcrumbs([
      { label: 'tkey;search.distribution.lists.section.header' },
      { label: this._distributionList.displayName },
    ]);
  }

  private _refreshList() {
    this.distributionListsResource
      .getDistributionList(this._serverId, this._distributionListId)
      .subscribe((distributionList) => {
        this._loadMembers(distributionList);
      });
  }

  private _getDistributionListMembers() {
    this.isLoading = true;
    this.distributionListsResource
      .getDistributionList(this._serverId, this._distributionListId)
      .subscribe((distributionList) => {
        this._loadMembers(distributionList);
        this._createTable();
        this._generateTableRows();
        this._setBreadcrumbs();
        this.isLoading = false;
      });
  }

  private _loadMembers(distributionList: DistributionList): void {
    this._distributionList = distributionList;
    this.distributionListMember = distributionList.members;
  }

  private _createTable(): void {
    this.table = {
      columns: [
        {
          columnId: 'displayName',
          cssColumnSize: 'col-sm-6',
          label: 'tkey;pages.distributionlist.display_name',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'alias',
          cssColumnSize: 'col-sm-5',
          label: 'tkey;pages.distributionlist.alias',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
      ],
      hasActions: true,
    };
  }

  private _generateTableRows(): void {
    this.tableRows = this.distributionListMember.map((member) => {
      return {
        content: {
          displayName: member.displayName,
          alias: member.alias,
        },
        actions: [
          {
            buttonStyle: ButtonStyles.DANGER,
            dataAutomation: 'member-delete',
            icon: SmacsIcons.DELETE,
            onClick: () => this.deleteClicked(member),
          },
        ],
      };
    });
  }
}
