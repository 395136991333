import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Microsoft360ViewContext } from '../../../../shared/contexts/microsoft-360-view.context';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AccountType,
  CallingType,
  CurrentUser,
  LicensingState,
  Microsoft360View,
  Microsoft365LicenseCount,
  Microsoft365LicensesFieldConfig,
  Microsoft365UserLicenses,
  Microsoft365UserLicenseStatus,
  MicrosoftSecurityGroupsFieldConfig,
  MicrosoftTeamsDevice,
  MicrosoftTeamsDevices,
  PstnConnectivityType,
  Role,
  State,
  StatusCategory,
  TileStatus,
  ZpmTileConfig,
} from '../../../../shared/models/generated/smacsModels';
import { Microsoft360ViewPollingService } from '../../../../shared/services/microsoft/microsoft-360-view-polling.service';
import { combineLatest, forkJoin, Observable, of, Subscription, switchMap } from 'rxjs';
import { MicrosoftTileMappingService } from '../microsoft-tile-mapping.service';
import { ServiceTileType } from '../../../cisco/user-detail-home/service-tile-type.model';
import { cloneDeep, isEqual } from 'lodash';
import { ToastService } from '../../../../shared/services/toast.service';
import { AuthenticationContext } from '../../../../shared/contexts/authentication.context';
import { BottomNavClearButtonsList, BottomNavService } from '../../../../shared/bottom-nav/bottom-nav.service';
import { Microsoft365LicensesResource } from '../../../shared/resources/microsoft-365-licenses.resource';
import { MicrosoftTile, MicrosoftTileState } from '../tiles.model';
import { MicrosoftDialPlansResource } from '../../../shared/resources/ms-dial-plans.resource';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { TranslateService } from '@ngx-translate/core';
import { MicrosoftLicensesContext } from '../../../../shared/contexts/microsoft-licenses.context';
import { Nvp } from '../../../../shared/models/nvp';
import { MicrosoftTileConfigContext } from '../../../shared/contexts/microsoft-tile-config.context';
import { Microsoft365SecurityGroupsContext } from '../../../../shared/contexts/microsoft-365-security-groups.context';
import { MicrosoftSecurityGroupsMembershipContext } from '../../../../shared/contexts/microsoft-security-groups-membership.context';
import { DubberFieldConfigResource } from '../../../../shared/resources/dubber-field-config.resource';
import { BandwidthEmergencyCallingFieldConfigResource } from '../../../shared/resources/field-config/bandwidth-emergency-calling-field-config.resource';
import { BandwidthE911DlrsResource } from '../../../shared/resources/bandwidth-e911-dlrs.resource';
import { DubberService } from '../../../shared/services/dubber.service';
import { ButtonStyles } from '../../../../button/button.component';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { DeviceService } from '../../../shared/services/device.service';
import { SystemStatusContext } from '../../../../shared/contexts/system-status.context';
import { MicrosoftOnPremAdResource } from '../../../shared/resources/microsoft-on-prem-ad.resource';
import { catchError, map } from 'rxjs/operators';
import { DraasSharedExtensionsResource } from '../../../shared/resources/draas-shared-extensions.resource';
import { ZiroModalPromptModalOptions } from '../../../../modals/prompt-modal/prompt-modal.component';
import { MicrosoftVoicePoliciesResource } from '../../../shared/resources/microsoft-voice-policies.resource';
import { MicrosoftPhoneNumberAssignmentsResource } from '../../../shared/resources/microsoft-phone-number-assignments.resource';

interface Device extends MicrosoftTeamsDevices {
  tooltip: string;
  iconMarkup: string;
}

interface TileStates {
  ms365: MicrosoftTileState;
  msTeamsCalling: MicrosoftTileState;
  msTeamsAudioConferencing: MicrosoftTileState;
  dubber: MicrosoftTileState;
  msSecurityGroupMembership: MicrosoftTileState;
}

@Component({
  selector: 'smacs-microsoft360-detail-home',
  templateUrl: './microsoft360-detail-home.component.html',
  providers: [
    MicrosoftTileMappingService,
    Microsoft360ViewPollingService,
    DubberFieldConfigResource,
    DubberService,
    DeviceService,
  ],
})
export class Microsoft360DetailHomeComponent implements OnInit, OnDestroy {
  TileStatus = TileStatus;
  ServiceTileType = ServiceTileType;
  SmacsIcons = SmacsIcons;
  isLoading = true;
  userPrincipalName: string;
  isTeamsLicensingInProgress: boolean;
  isAudioLicensingInProgress: boolean;
  is365LicensesSyncInProgress: boolean;
  tile365LicensesSyncBadgeText = '';
  tileStates: TileStates = {
    ms365: null,
    msTeamsCalling: null,
    msTeamsAudioConferencing: null,
    dubber: null,
    msSecurityGroupMembership: null,
  };
  tileConfig: ZpmTileConfig;
  microsoft360View: Microsoft360View;
  isLicensesConfigured = false;
  readWriteLicenses: Microsoft365LicenseCount[] = [];
  isSecurityGroupsConfigured = false;
  devices: Device[] = [];

  private _currentUser: CurrentUser;
  private _subscriptions = new Subscription();
  private _securityGroupFieldConfig: MicrosoftSecurityGroupsFieldConfig;
  private _microsoftLicensesFieldConfig: Microsoft365LicensesFieldConfig;
  private initialDevices: MicrosoftTeamsDevices[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private microsoft360ViewContext: Microsoft360ViewContext,
    private authenticationContext: AuthenticationContext,
    private microsoftLicenseContext: MicrosoftLicensesContext,
    private microsoftTileMappingService: MicrosoftTileMappingService,
    private microsoft360ViewPollingService: Microsoft360ViewPollingService,
    private microsoft365LicensesResource: Microsoft365LicensesResource,
    private microsoftPhoneNumberAssignmentsResource: MicrosoftPhoneNumberAssignmentsResource,
    private microsoftVoicePoliciesResource: MicrosoftVoicePoliciesResource,
    private toastService: ToastService,
    private changeDetectorRef: ChangeDetectorRef,
    private bottomNavService: BottomNavService,
    private bandwidthE911DlrsResource: BandwidthE911DlrsResource,
    private bandwidthEmergencyCallingFieldConfigResource: BandwidthEmergencyCallingFieldConfigResource,
    private microsoftDialPlansResource: MicrosoftDialPlansResource,
    private translateService: TranslateService,
    private microsoftTileConfigContext: MicrosoftTileConfigContext,
    private _microsoft365SecurityGroupsContext: Microsoft365SecurityGroupsContext,
    private _securityGroupsMembershipContext: MicrosoftSecurityGroupsMembershipContext,
    private _dubberService: DubberService,
    private _smacsModalService: SmacsModalService,
    private deviceService: DeviceService,
    private _systemStatusContext: SystemStatusContext,
    private _microsoftOnPremAdResource: MicrosoftOnPremAdResource,
    private draasSharedExtensionsResource: DraasSharedExtensionsResource
  ) {}

  ngOnInit(): void {
    this.userPrincipalName = this.route.snapshot.paramMap.get('upn');
    const authSub = this.authenticationContext.state$.subscribe((currentUser) => (this._currentUser = currentUser));

    const msSub = this.microsoft360ViewContext.state$
      .pipe(
        switchMap((microsoft360View) => {
          this.microsoft360View = microsoft360View;

          if (this.microsoft360View.accountType === AccountType.SFB_ON_PREM_USER) {
            const options: ZiroModalPromptModalOptions = {
              windowClass: 'unsupported-account-type-modal',
              modalViewProperties: {
                title: this.translateService.instant('tkey;microsoft_360.view.unsupported_account_type_modal.title'),
                promptBody: this.translateService.instant(
                  'tkey;microsoft_360.view.unsupported_account_type_modal.body'
                ),
                icon: SmacsIcons.WARNING,
                buttons: [
                  {
                    label: 'tkey;microsoft_360.view.return_home.modal.button',
                    buttonClass: ButtonStyles.PRIMARY,
                    dataAutomation: 'modal-confirm-button',
                    cb: () => of(this.router.navigate(['/'])),
                  },
                ],
              },
            };
            return this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
          } else if (this.microsoft360View.accountType === AccountType.UPN_NOT_IN_TEAMS) {
            const options: ZiroModalPromptModalOptions = {
              windowClass: 'account-not-in-teams-modal',
              modalViewProperties: {
                title: this.translateService.instant('tkey;microsoft_360.view.upn_not_in_teams_modal.title'),
                promptBody: this.translateService.instant('tkey;microsoft_360.view.upn_not_in_teams_modal.body', {
                  upn: this.userPrincipalName,
                }),
                icon: SmacsIcons.WARNING,
                buttons: [
                  {
                    label: 'tkey;microsoft_360.view.return_home.modal.button',
                    buttonClass: ButtonStyles.PRIMARY,
                    dataAutomation: 'modal-confirm-button',
                    cb: () => of(this.router.navigate(['/'])),
                  },
                ],
              },
            };
            return this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
          } else {
            return combineLatest([
              this.microsoftLicenseContext.state$,
              this.microsoftTileConfigContext.state$,
              this._securityGroupsMembershipContext.state$,
            ]).pipe(
              switchMap(([microsoftLicensesFieldConfig, microsoftTileConfig, securityGroupsFieldConfig]) => {
                this.initialDevices = microsoft360View.teamsDevices;

                this.isLicensesConfigured =
                  !!microsoftLicensesFieldConfig.writeLicenseList.length ||
                  !!microsoftLicensesFieldConfig.readOnlyLicenseList.length;
                this.readWriteLicenses = microsoftLicensesFieldConfig.writeLicenseList;
                this._microsoftLicensesFieldConfig = microsoftLicensesFieldConfig;

                this.isSecurityGroupsConfigured =
                  !!securityGroupsFieldConfig.readOnlySecurityGroupList.length ||
                  !!securityGroupsFieldConfig.writeSecurityGroupList.length;
                this._securityGroupFieldConfig = securityGroupsFieldConfig;
                this.tileConfig = microsoftTileConfig;

                this._checkForLicenseStatus(microsoft360View);
                this._initTileStates(microsoft360View);

                if (
                  this.isAudioLicensingInProgress ||
                  this.isTeamsLicensingInProgress ||
                  this.is365LicensesSyncInProgress
                ) {
                  /** Poll every minute until licensed */
                  this.microsoft360ViewPollingService.setBaseUrl(this.userPrincipalName);
                  this.microsoft360ViewPollingService.startPolling(Microsoft360ViewPollingService.MAX_POLLS);
                  this.microsoft360ViewPollingService.state$.subscribe((ms360View: Microsoft360View) => {
                    if (!isEqual(ms360View, this.microsoft360View)) {
                      this.microsoft360View = ms360View;
                      /** set the new 360 view received from polling in context */
                      this.microsoft360ViewContext.setMicrosoft360View(ms360View);
                      this._checkForLicenseStatus(ms360View);
                    }
                  });
                }

                const devicesStatusObservables = this.initialDevices.map((device) =>
                  this.deviceService.getDeviceStatus(device.id)
                );
                return devicesStatusObservables.length &&
                  this.authenticationContext.userIsAtLeast(this._currentUser, Role.S8_HELPDESK)
                  ? forkJoin(devicesStatusObservables)
                  : of([] as MicrosoftTeamsDevice[]);
              })
            );
          }
        })
      )
      .subscribe((deviceStatuses: boolean | MicrosoftTeamsDevice[]) => {
        if (typeof deviceStatuses !== 'boolean' && deviceStatuses?.length) {
          this.devices = this.initialDevices.map((device) => {
            const deviceStatus = deviceStatuses.find((deviceStatus) => deviceStatus.id === device.id);
            return {
              ...device,
              tooltip: this.getDeviceStatusTooltip(deviceStatus.healthStatus),
              iconMarkup: this.getDeviceStatusMarkup(deviceStatus.healthStatus),
            };
          });
        }
        this.isLoading = false;
      });

    this._subscriptions.add(authSub);
    this._subscriptions.add(msSub);
    this.bottomNavService.dispatch(new BottomNavClearButtonsList());
  }

  ngOnDestroy() {
    this.microsoft360ViewPollingService.stopPolling();
    this._subscriptions.unsubscribe();
  }

  getDeviceStatusTooltip(healthStatus: string): string {
    switch (healthStatus) {
      case 'healthy':
        return 'tkey;microsoft_360.view.teams.device.tooltip.healthy';
      case 'nonUrgent':
        return 'tkey;microsoft_360.view.teams.device.tooltip.non_urgent';
      case 'critical':
        return 'tkey;microsoft_360.view.teams.device.tooltip.critical';
      case 'offline':
        return 'tkey;microsoft_360.view.teams.device.tooltip.offline';
      default:
        return 'tkey;microsoft_360.view.teams.device.tooltip.unknown';
    }
  }

  getDeviceStatusMarkup(healthStatus: string): string {
    switch (healthStatus) {
      case 'healthy':
        return `<span class="fa-stack status-icon"><i class="icon-circle fa-stack-2x icon-success"></i><i class="icon-ok fa-stack-1x"></i></span>`;
      case 'nonUrgent':
        return `<i class="icon-warning-triangle icon-warning"></i>`;
      case 'offline':
        return `<span class="fa-stack status-icon"><i class="fa fa-phone fa-stack-1x"></i><i class="fa fa-ban fa-stack-2x icon-warning"></i></span>`;
      default:
        return `<i class="icon-exclamation-circle icon-danger"></i>`;
    }
  }

  getDeleteMS365LicenseTileTooltip(): string {
    if (!this.authenticationContext.userIsAtLeast(this._currentUser, Role.S8_ADMIN)) {
      return 'tkey;selfserve.microsoft365.checkbox.disabled.read_only';
    } else if (this.isAudioLicensingInProgress || this.isTeamsLicensingInProgress) {
      return 'tkey;microsoft_360.view.delete.disabled.tooltip.licensing.in.progress';
    }
    return this.microsoft360View?.teamsPhoneNumberAssignment !== null
      ? 'tkey;microsoft_360.view.delete.disabled.tooltip'
      : '';
  }

  /**
   * Enable delete on MS License tile for following cases:
   * - User is an admin
   * - Teams Line is null
   * - Either licensing is not in progress
   */
  enableDeleteMS365LicenseTile(): boolean {
    return (
      this.authenticationContext.userIsAtLeast(this._currentUser, Role.S8_ADMIN) &&
      (!this.microsoft360View?.teamsPhoneNumberAssignment || false) &&
      !(this.isTeamsLicensingInProgress || this.isAudioLicensingInProgress)
    );
  }

  onAddTileClicked(tileState: MicrosoftTileState): void {
    switch (tileState.serviceType) {
      case MicrosoftTile.MS365: {
        tileState.isEnabled ? this.router.navigate(['licenses'], { relativeTo: this.route }) : null;
        break;
      }
      case MicrosoftTile.MICROSOFT_TEAMS_CALLING: {
        tileState.isEnabled ? this.router.navigate(['teams-calling'], { relativeTo: this.route }) : null;
        break;
      }
      case MicrosoftTile.MICROSOFT_TEAMS_AUDIO_CONFERENCING: {
        tileState.isEnabled ? this.router.navigate(['audio-conference'], { relativeTo: this.route }) : null;
        break;
      }
      case MicrosoftTile.SECURITY_GROUP_MEMBERSHIP: {
        tileState.isEnabled ? this.router.navigate(['security-group-membership'], { relativeTo: this.route }) : null;
        break;
      }
      case MicrosoftTile.DUBBER: {
        tileState.isEnabled ? this.router.navigate(['dubber-compliance-recording'], { relativeTo: this.route }) : null;
        break;
      }
      default: {
        break;
      }
    }
  }

  onEditTileClicked(tileState: MicrosoftTileState) {
    if (!tileState.isEnabled) {
      return;
    }

    switch (tileState.serviceType) {
      case MicrosoftTile.MS365: {
        this.router.navigate(['licenses'], { relativeTo: this.route });
        break;
      }
      case MicrosoftTile.MICROSOFT_TEAMS_CALLING: {
        this.router.navigate(['teams-calling'], { relativeTo: this.route });
        break;
      }
      case MicrosoftTile.MICROSOFT_TEAMS_AUDIO_CONFERENCING: {
        this.router.navigate(['audio-conference'], { relativeTo: this.route });
        break;
      }
      case MicrosoftTile.SECURITY_GROUP_MEMBERSHIP: {
        tileState.isEnabled ? this.router.navigate(['security-group-membership'], { relativeTo: this.route }) : null;
        break;
      }
      case MicrosoftTile.DUBBER: {
        this.router.navigate(['dubber-compliance-recording'], { relativeTo: this.route });
        break;
      }
      default: {
        break;
      }
    }
  }

  onEditTileDeleteClicked(tileState: MicrosoftTileState) {
    switch (tileState.serviceType) {
      case MicrosoftTile.MICROSOFT_TEAMS_AUDIO_CONFERENCING: {
        break;
      }
      default: {
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: {
            ...this.tileStates[tileState.serviceType],
            tileType: ServiceTileType.DELETE,
          },
        };
        break;
      }
    }
  }

  onDeleteTileConfirmButtonClicked(tileState: MicrosoftTileState) {
    switch (tileState.serviceType) {
      case MicrosoftTile.MS365: {
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: {
            ...this.tileStates[tileState.serviceType],
            isDeleting: true,
          },
        };
        const microsoft365UserLicenses = this.microsoft360View.microsoft365UserLicenses;
        const licenseToUpdate = microsoft365UserLicenses.licenses.filter(
          (license) =>
            !this.readWriteLicenses.some(
              (readWriteLicense) => readWriteLicense.microsoft365LicenseJson.skuId === license.skuId
            )
        );
        const updatedMs365UserLicenses: Microsoft365UserLicenses = {
          ...microsoft365UserLicenses,
          licenses: licenseToUpdate,
        };

        const microsoft360ViewUpdated = cloneDeep(this.microsoft360View);
        microsoft360ViewUpdated.microsoft365UserLicenses = updatedMs365UserLicenses;

        this.microsoft365LicensesResource.put(updatedMs365UserLicenses).subscribe(() => {
          microsoft360ViewUpdated.licensingStatus.teamsPhoneStatus = LicensingState.UNLICENSED;
          microsoft360ViewUpdated.licensingStatus.teamsAudioConferencingStatus = LicensingState.UNLICENSED;
          this._removeServiceFromMs360View(tileState.serviceType, microsoft360ViewUpdated);
          this._showDeleteToastForTile(tileState);
        });
        break;
      }
      case MicrosoftTile.MICROSOFT_TEAMS_CALLING: {
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: {
            ...this.tileStates[tileState.serviceType],
            isDeleting: true,
          },
        };

        this.microsoftDialPlansResource
          .getAllDialPlanGroups()
          .pipe(
            switchMap((dialPlans) => {
              if (!dialPlans.length) {
                return of(null);
              }
              // find the dial plan field config applicable to this line
              if (this.microsoft360View.teamsPhoneNumberAssignment) {
                return this.microsoftDialPlansResource
                  .getDialPlanInventoriesByLineUri(this.microsoft360View.teamsPhoneNumberAssignment.lineUri)
                  .pipe(
                    map((dialPlansByUri) =>
                      dialPlans.find((msDialPlanFieldConfig) => msDialPlanFieldConfig.id === dialPlansByUri[0].id)
                    )
                  );
              } else {
                return of(
                  dialPlans.find(
                    (msDialPlanFieldConfig) =>
                      msDialPlanFieldConfig.mainNumber ===
                      this.microsoft360View.teamsVoicePolicies.sharedCallRoutingPolicy.resourceAccountLineUri
                  )
                );
              }
            }),
            switchMap((activeFieldConfig) => {
              if (!activeFieldConfig) {
                return of(null);
              }
              const deletionObs: Observable<void>[] = [of(null)];

              if (activeFieldConfig.onPremAdWriteDownEnabled) {
                deletionObs.push(this.deleteOnPremAdAttributes(activeFieldConfig.id));
              }

              if (
                activeFieldConfig.callingType === CallingType.EXTENSION &&
                [PstnConnectivityType.ZIRO_DRAAS, PstnConnectivityType.ZIRO_DRAAS_BYOC].includes(
                  activeFieldConfig.pstnConnectivityType
                )
              ) {
                // delete ziro sbc number configuration if it exists
                const lineUri = this.microsoft360View.teamsPhoneNumberAssignment.lineUri;
                const lineUriExtension = this.microsoft360View.teamsPhoneNumberAssignment.lineUriExtension;
                deletionObs.push(
                  this.draasSharedExtensionsResource.getExtension(lineUri, lineUriExtension).pipe(
                    switchMap(() => {
                      return this.draasSharedExtensionsResource.deleteExtension(lineUri, lineUriExtension);
                    }),
                    catchError((err) => {
                      if (err.status === 404) {
                        return of(null);
                      }
                      throw err;
                    })
                  )
                );
              }

              if (activeFieldConfig.emergencyCallingSettingsConfigured) {
                // GET Bandwidth field-config and then DELETE
                deletionObs.push(
                  this.bandwidthEmergencyCallingFieldConfigResource
                    .get(this.userPrincipalName, this.microsoft360View.teamsPhoneNumberAssignment.lineUri)
                    .pipe(
                      switchMap((bandwidthFieldConfig) => {
                        return this.bandwidthE911DlrsResource.delete(
                          bandwidthFieldConfig.dynamicGeolocationEndpointId.value
                        );
                      }),
                      catchError(() => {
                        // Do not throw an error here, our API returns a 500 if bandwidth settings do not exist
                        // so we just have to swallow all errors.
                        return of(null);
                      })
                    )
                );
              }

              return forkJoin(deletionObs);
            }),
            switchMap(() => {
              const auditTags: Nvp[] = [
                {
                  name: 'displayName',
                  value: this.microsoft360View.microsoft365UserLicenses.displayName,
                },
              ];
              return forkJoin([
                this.microsoft360View.teamsPhoneNumberAssignment
                  ? this.microsoftPhoneNumberAssignmentsResource.delete(this.userPrincipalName, auditTags)
                  : of(null),
                this.microsoftVoicePoliciesResource.delete(
                  this.userPrincipalName,
                  this.microsoft360View.accountType === AccountType.RESOURCE_ACCOUNT
                ),
              ]);
            })
          )
          .subscribe({
            next: () => {
              const microsoft360ViewUpdated = this.microsoft360View;
              microsoft360ViewUpdated.teamsPhoneNumberAssignment = null;
              this._removeServiceFromMs360View(tileState.serviceType, microsoft360ViewUpdated);
              this._showDeleteToastForTile(tileState);
            },
            error: (response) => {
              if (
                response.status === 422 &&
                response.error.reasonCode === 'INSUFFICIENT_PERMISSION_TO_VIEW_OR_UPDATE_DIAL_PLAN_GROUP'
              ) {
                const description = response.error.description.match(/\[(.*?)\]/);
                const options = {
                  windowClass: 'delete-button-modal',
                  modalViewProperties: {
                    icon: SmacsIcons.FORBIDDEN,
                    iconClass: 'text-danger',
                    modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
                    title: this.translateService.instant(
                      'tkey;microsoft_360.view.teams.calling.permissions.modal.title'
                    ),
                    promptBody: this.translateService.instant(
                      'tkey;microsoft_360.view.teams.calling.permissions.modal.text',
                      { group: description[1] }
                    ),
                    displayCloseButton: false,
                    buttons: [
                      {
                        label: 'tkey;dialogs.button.ok',
                        buttonClass: ButtonStyles.INFO,
                        dataAutomation: 'confirmation-modal-confirm-button',
                        cb: () => {
                          this.tileStates = {
                            ...this.tileStates,
                            [tileState.serviceType]: {
                              ...this.tileStates[tileState.serviceType],
                              tileType: ServiceTileType.EDIT,
                              isDeleting: false,
                            },
                          };
                          return of(null);
                        },
                      },
                    ],
                  },
                };

                this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
              } else {
                throw response;
              }
            },
          });
        break;
      }
      case MicrosoftTile.SECURITY_GROUP_MEMBERSHIP: {
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: {
            ...this.tileStates[tileState.serviceType],
            isDeleting: true,
          },
        };

        const securityGroupMemberships = this.microsoft360View.securityGroupMemberships;
        const groupsToKeep = securityGroupMemberships.filter((securityGroup) =>
          this._securityGroupFieldConfig.readOnlySecurityGroupList.some((readOnly) => readOnly.id === securityGroup.id)
        );
        this._microsoft365SecurityGroupsContext.get(this.userPrincipalName).subscribe((userGroupMemberships) => {
          userGroupMemberships.securityGroupMemberships = groupsToKeep.length ? groupsToKeep : [];
          const microsoft360ViewUpdated = this.microsoft360View;
          microsoft360ViewUpdated.securityGroupMemberships = groupsToKeep;

          this._microsoft365SecurityGroupsContext
            .updateUserGroups(this.userPrincipalName, userGroupMemberships)
            .subscribe(() => {
              if (!groupsToKeep.length) {
                this._removeServiceFromMs360View(tileState.serviceType, microsoft360ViewUpdated);
              } else {
                this.microsoft360ViewContext._stateSource.next(microsoft360ViewUpdated);
                this._initTileStates(microsoft360ViewUpdated);
              }
              this._showDeleteToastForTile(tileState);
            });
        });
        break;
      }
      case MicrosoftTile.DUBBER: {
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: {
            ...this.tileStates[tileState.serviceType],
            isDeleting: true,
          },
        };

        const auditTags: Nvp[] = [
          {
            name: 'upn',
            value: this.userPrincipalName,
          },
        ];
        this._dubberService
          .deleteDubberUser(
            this.microsoft360View.dubberUser.region,
            this.microsoft360View.dubberUser.id,
            this.userPrincipalName,
            auditTags
          )
          .subscribe(() => {
            const updated360View: Microsoft360View = {
              ...this.microsoft360View,
              dubberUser: null,
            };
            this.microsoft360ViewContext._stateSource.next(updated360View);
            this._showDeleteToastForTile(tileState);
          });
        break;
      }
      default: {
        break;
      }
    }
  }

  onDeleteTileCancelButtonClicked(tileState: MicrosoftTileState) {
    switch (tileState.serviceType) {
      case MicrosoftTile.MICROSOFT_TEAMS_AUDIO_CONFERENCING: {
        break;
      }
      default: {
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: {
            ...this.tileStates[tileState.serviceType],
            tileType: ServiceTileType.EDIT,
          },
        };
        break;
      }
    }
  }

  _initTileStates(microsoft360View: Microsoft360View): void {
    this.tileStates = {
      ms365: this.microsoftTileMappingService.mapServiceToTile(
        MicrosoftTile.MS365,
        this.tileConfig,
        microsoft360View,
        this.isLicensesConfigured,
        this.is365LicensesSyncInProgress,
        this._microsoftLicensesFieldConfig
      ),
      msTeamsCalling: this.microsoftTileMappingService.mapServiceToTile(
        MicrosoftTile.MICROSOFT_TEAMS_CALLING,
        this.tileConfig,
        microsoft360View
      ),
      msTeamsAudioConferencing: this.microsoftTileMappingService.mapServiceToTile(
        MicrosoftTile.MICROSOFT_TEAMS_AUDIO_CONFERENCING,
        this.tileConfig,
        microsoft360View
      ),
      dubber: this.microsoftTileMappingService.mapServiceToTile(
        MicrosoftTile.DUBBER,
        this.tileConfig,
        microsoft360View
      ),
      msSecurityGroupMembership: this.microsoftTileMappingService.mapServiceToTile(
        MicrosoftTile.SECURITY_GROUP_MEMBERSHIP,
        this.tileConfig,
        microsoft360View,
        this.isSecurityGroupsConfigured,
        null,
        this._securityGroupFieldConfig
      ),
    };
    this.changeDetectorRef.detectChanges();
  }

  private _checkForLicenseStatus(ms360View: Microsoft360View): void {
    this.isTeamsLicensingInProgress =
      ms360View.licensingStatus.teamsPhoneStatus === LicensingState.LICENSING_IN_PROGRESS;
    this.isAudioLicensingInProgress =
      ms360View.licensingStatus.teamsAudioConferencingStatus === LicensingState.LICENSING_IN_PROGRESS;
    if (
      ms360View.licensingStatus.groupLicenseAssignmentStatus === Microsoft365UserLicenseStatus.LICENSING_IN_PROGRESS
    ) {
      this.is365LicensesSyncInProgress = true;
      this.tile365LicensesSyncBadgeText = 'tkey;userdetail.microsoft_365.licensing_in_progress.badge.text';
    } else if (
      ms360View.licensingStatus.groupLicenseAssignmentStatus === Microsoft365UserLicenseStatus.UNLICENSING_IN_PROGRESS
    ) {
      this.is365LicensesSyncInProgress = true;
      this.tile365LicensesSyncBadgeText = 'tkey;userdetail.microsoft_365.unlicensing_in_progress.badge.text';
    } else {
      this.is365LicensesSyncInProgress = false;
      this.tile365LicensesSyncBadgeText = '';
    }

    const isAnyLicenseInProgress =
      this.isTeamsLicensingInProgress || this.isAudioLicensingInProgress || this.is365LicensesSyncInProgress;
    if (!isAnyLicenseInProgress) {
      this.microsoft360ViewPollingService.stopPolling();
    }
  }

  private _removeServiceFromMs360View(serviceType: MicrosoftTile, ref: Microsoft360View = null) {
    const microsoft360View = cloneDeep(ref);
    switch (serviceType) {
      case MicrosoftTile.MS365: {
        microsoft360View.teamsAudioConferencing = null;
        break;
      }
      case MicrosoftTile.MICROSOFT_TEAMS_CALLING: {
        microsoft360View.teamsPhoneNumberAssignment = null;
        microsoft360View.teamsVoicePolicies.sharedCallRoutingPolicy = {
          policyName: 'Global',
          resourceAccountUpn: '',
          resourceAccountLineUri: '',
          resourceAccountDisplayName: '',
        };
        break;
      }
      default:
        break;
    }
    this.microsoft360ViewContext._stateSource.next(microsoft360View);
    this._initTileStates(microsoft360View);
  }

  private _showDeleteToastForTile(tileState: MicrosoftTileState) {
    switch (tileState.serviceType) {
      case MicrosoftTile.MS365: {
        this.toastService.pushDeleteToast('tkey;shared.model.microsoft_365.text', tileState.description);
        break;
      }
      case MicrosoftTile.MICROSOFT_TEAMS_CALLING: {
        this.toastService.push(
          ToastTypes.INFO,
          `${SmacsIcons.DELETE} text-danger`,
          'tkey;shared.toast.delete.success.title',
          `${this.translateService.instant('tkey;microsoft_360.view.teams.calling.toast.text')} - <strong>${
            this.userPrincipalName
          } | ${tileState.description}
            </strong>`
        );
        break;
      }
      case MicrosoftTile.SECURITY_GROUP_MEMBERSHIP: {
        this.toastService.pushDeleteToast('tkey;microsoft_360.view.security.groups.tile.title', tileState.description);
        break;
      }
      case MicrosoftTile.DUBBER: {
        this.toastService.pushDeleteToast(
          'tkey;microsoft_360.view.dubber.delete.toast.message',
          this.userPrincipalName
        );
        break;
      }
      default:
        break;
    }
  }

  private deleteOnPremAdAttributes(dialPlanId: number): Observable<void> {
    return this._systemStatusContext.getSystemStatus().pipe(
      switchMap((systemStatus) => {
        const syncStatus = systemStatus.healthStatuses.find(
          (status) => status.category === StatusCategory.PROXY_SERVER
        );
        const isZpcSyncWarningPresent = syncStatus && syncStatus.state !== State.OK;
        if (!isZpcSyncWarningPresent && !!this.microsoft360View.onPremiseUserPrincipalName) {
          return this._microsoftOnPremAdResource.getAdAttributes(
            this.microsoft360View.onPremiseUserPrincipalName,
            dialPlanId
          );
        } else {
          return of(null);
        }
      }),
      switchMap((adAttributes) => {
        if (!!adAttributes) {
          return this._microsoftOnPremAdResource.putAdAttributes(
            this.microsoft360View.onPremiseUserPrincipalName,
            dialPlanId,
            {
              ...adAttributes,
              adWriteDownListJson: adAttributes.adWriteDownListJson.map((attr) => {
                return {
                  attributeName: attr.attributeName,
                  attributeValue: '',
                };
              }),
            }
          );
        } else {
          return of(null);
        }
      })
    );
  }
}
