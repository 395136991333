import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SmacsIcons } from '../models/smacs-icons.enum';

export interface CollapsibleCardSwitchValue {
  switchId: string;
  isChecked: boolean;
}

@Component({
  selector: 'smacs-collapsible-card',
  templateUrl: './collapsible-card.component.html',
  styleUrls: ['./collapsible-card.component.scss'],
})
export class CollapsibleCardComponent implements AfterViewInit, OnChanges {
  @Output() toggleSwitch: EventEmitter<CollapsibleCardSwitchValue> = new EventEmitter<CollapsibleCardSwitchValue>();
  @Input() label: string;
  @Input() isDisabled = false;
  @Input() disabledTooltip: string;
  @Input() shouldBeOpen = false;
  @Input() dataAutomation = '';
  @Input() isSwitchHidden = false;
  @Input() isDisabledAndChecked = false;
  @Input() showChevron = false;
  @Input() cssClass = '';
  @Input() icon: SmacsIcons;
  @Input() svgIcon: SmacsIcons;
  @Input() autoScrollOnOpen = true;
  @Input() hasBetaTag = false;
  @Input() betaTagTooltip = '';
  @Input() isCollapsible = true;
  @Input() set switchId(id: string) {
    this._switchId = id.replaceAll(' ', '_');
  }
  get switchId(): string {
    return this._switchId;
  }
  private _switchId: string;

  isCardSwitchChecked = false;
  smacsIcons = SmacsIcons;

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.isCardSwitchChecked = (this.shouldBeOpen && !this.isDisabled) || this.isDisabledAndChecked;
    this._setCardBodyState();
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.shouldBeOpen && changes.shouldBeOpen.currentValue !== changes.shouldBeOpen.previousValue) {
      this.isCardSwitchChecked = changes.shouldBeOpen.currentValue;
      this._setCardBodyState();
      this.changeDetectorRef.detectChanges();
    }
  }

  emitSwitchValue() {
    this.isCardSwitchChecked = !this.isCardSwitchChecked;
    const switchValue = {
      switchId: this.switchId,
      isChecked: this.isCardSwitchChecked,
    };
    this.toggleSwitch.emit(switchValue);
    this._setCardBodyState();
    if (switchValue.isChecked && this.autoScrollOnOpen) {
      setTimeout(
        () => document.querySelector(`.${this.switchId}`).scrollIntoView({ behavior: 'smooth', block: 'end' }),
        300
      );
    }
  }

  private _setCardBodyState() {
    if (this.isCardSwitchChecked) {
      this._expandCardBody();
    } else {
      this._collapseCardBody();
    }
  }

  private _expandCardBody() {
    const cardBody = document.querySelector(`.${this.switchId}`) as HTMLDivElement;
    if (cardBody) {
      cardBody.style.height = '100%';
      cardBody.style.maxHeight = 'unset';
      cardBody.style.overflow = 'visible';
    }
  }

  private _collapseCardBody() {
    const cardBody = document.querySelector(`.${this.switchId}`) as HTMLDivElement;
    if (cardBody) {
      cardBody.style.maxHeight = '0px';
      cardBody.style.overflow = 'hidden';
    }
  }
}
