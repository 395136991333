import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { combineLatest, of, Subscription } from 'rxjs';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { PinResetService } from '../../../../shared/services/pin-reset.service';
import { PinResetComponent } from '../../pin-reset/pin-reset.component';
import { ToastService } from '../../../../shared/services/toast.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { PhoneUiContext } from '../../../../shared/phone-buttons/contexts/phone-ui.context';
import { HelpdeskOptionsContext } from '../../../shared/contexts/helpdesk-options.context';
import { CiscoHelpdeskOptions, LineButton, Phone } from '../../../../shared/models/generated/smacsModels';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SiteContext } from '../../../shared/contexts/site.context';
import { DnDetailSummaryResource } from '../../../../shared/resources/dn-detail-summary.resource';
import { switchMap } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';

export interface PinReset {
  vmPinReset: string;
}

@Component({
  selector: 'smacs-public-phone-pin-reset',
  templateUrl: './public-phone-pin-reset.component.html',
})
export class PublicPhonePinResetComponent extends SmacsFormAbstractDirective<PinReset> implements OnInit, OnDestroy {
  @ViewChild('vmPin') vmPinResetComponent: PinResetComponent;

  isLoading = true;
  errorMessage = '';
  formConfig = {
    fields: {
      vmPinReset: {
        dataAutomation: 'public-phone-voicemail-pin-reset',
        label: 'tkey;pages.pinreset.voicemail',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.PASSWORD,
          placeholder: 'tkey;pages.reset.pin.placeholder',
        }),
        validation: [
          {
            validator: (val: string) => {
              return !val || (!!val && /(^[0-9]+$)/.test(val))
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID;
            },
            message: 'tkey;validators.global.error.pattern',
          },
          {
            validator: () => (this._hasError ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID),
            message: () => this.errorMessage,
          },
        ],
      },
    },
  } as SmacsFormConfig;
  private _phone: Phone;
  private _smacsIcons = SmacsIcons;
  private _hasError = false;
  private _resetPinOnNextLogin = false;
  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _route: ActivatedRoute,
    private _pinResetService: PinResetService,
    private _toastService: ToastService,
    private _phoneUiContext: PhoneUiContext,
    private _siteContext: SiteContext,
    private _helpdeskOptionsContext: HelpdeskOptionsContext,
    private _dnDetailSummaryResource: DnDetailSummaryResource,
    private _siteSummaryContext: SiteSummaryContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    const subscription = combineLatest([
      this._phoneUiContext.phoneState$,
      this._helpdeskOptionsContext.state$,
      this._siteSummaryContext.state$,
    ]).subscribe(([phone, helpdeskOptions, siteSummary]) => {
      this._phone = phone;
      this._siteContext.setSiteForPublicPhone(siteSummary, this._phone);
      this._resetPinOnNextLogin = (helpdeskOptions as CiscoHelpdeskOptions).resetPinOnNextLoginEnabled;
      this.isLoading = false;
    });
    this._subscriptions.add(subscription);

    this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<PinReset>) => {
      if (data.old?.vmPinReset && !isEqual(data.new.vmPinReset, data.old.vmPinReset) && this._hasError) {
        this._hasError = false;
      }
    });
  }

  submit() {
    return of(null);
  }

  onSubmit() {
    this._resetVoicemailPin();
  }

  private _getLineButton() {
    if (this._phone.buttons.length > 0 && this._phone.buttons[0].type === 'Line') {
      return this._phone.buttons[0];
    }
  }

  private _resetVoicemailPin() {
    const lineButton = this._getLineButton();
    if (lineButton) {
      const serverId = (lineButton as LineButton).dn.serverId.toString();
      const dnId = (lineButton as LineButton).dn.id;
      this._dnDetailSummaryResource
        .get(dnId, serverId)
        .pipe(
          switchMap((dnDetailSummary) => {
            return this._pinResetService.resetVoicemailPin(
              dnDetailSummary.voicemail,
              this.entity.vmPinReset,
              this._resetPinOnNextLogin
            );
          })
        )
        .subscribe({
          next: () => {
            this._toastService.push(
              ToastTypes.SUCCESS,
              this._smacsIcons.REFRESH,
              'tkey;pages.pinreset.success.title',
              'tkey;pages.pinreset.voicemail.success.message'
            );
            this._resetVoicemailPinInput();
          },
          error: (error) => {
            if (error.status === 422) {
              this._hasError = true;
              this.errorMessage = this._pinResetService.translateVoicemailPinResetErrorResponse(error);
              this.fieldChannels['vmPinReset'].validateSource.next();
            } else {
              throw new HttpErrorResponse(error);
            }
            this._resetVoicemailPinInput();
          },
        });
    }
  }

  private _resetVoicemailPinInput() {
    this.vmPinResetComponent.isPending = false;
    this.entitySource.next({
      vmPinReset: '',
    });
    this.smacsFormStateService.setIsFormDirty(false);
  }
}
