import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { Timestamp } from '../../models/generated/smacsModels';

export class SamlMetadataContextAbstract {
  protected _timestampUrl: string;
  protected _metaDataUrl: string;
  _stateSource = new ReplaySubject<Timestamp>(1);
  state$ = this._stateSource.asObservable();

  constructor(public http: HttpClient) {}

  refresh(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.getTimeStamp();
      subscriber.next();
      subscriber.complete();
    });
  }

  uploadMetadata = (formData: FormData): Observable<void> => {
    return new Observable<void>((subscriber) => {
      this.http
        .post(this._metaDataUrl, formData)
        .pipe(shareReplay())
        .subscribe(() => {
          this.getTimeStamp();
          subscriber.next();
          subscriber.complete();
        });
    });
  };

  getTimeStamp = () => {
    this.http.get<Timestamp>(this._timestampUrl).subscribe({
      next: (timestamp: Timestamp) => {
        this._stateSource.next(timestamp);
      },
      error: () => {
        this._stateSource.next({ timestamp: '' });
      },
    });
  };
}
