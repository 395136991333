<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 d-flex flex-column h-100">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.billing.products.about' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <app-loading-block *ngIf="isLoading"></app-loading-block>

    <div class="flex-grow-1" *ngIf="!isLoading">
      <app-entity-table
        [table]="table"
        [tableRows]="tableRows"
        [theadRowCssClass]="'bg-info text-white'"
        [showCustomAlert]="showCustomAlert"
        data-automation="billing-summary-entity-table"
        (initialTextInputValue)="onInitialTextInputValue($event)"
      >
        <div class="text-center" alert-content>
          {{ 'tkey;admin.billing.products.detailed_usage.datatable.error_1' | translate }}
          <button class="btn btn-link strong" (click)="onRefreshClicked()">
            {{ 'tkey;admin.billing.products.detailed_usage.datatable.error_2' | translate }}
          </button>
          {{ 'tkey;admin.billing.products.detailed_usage.datatable.error_3' | translate }}
        </div>
      </app-entity-table>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
