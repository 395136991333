<div class="audit-view-wrapper smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.audits.logs.description' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <fieldset *ngIf="table" data-automation="admin-audit-logs-entity-table">
      <app-entity-table
        [table]="table"
        [tableRows]="tableRows"
        [totalResult]="totalResults"
        (fieldChange)="onErrorClick($event)"
        (filterValuesUpdate)="onFilterChange($event)"
      ></app-entity-table>
    </fieldset>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
