import { Component, OnDestroy, OnInit } from '@angular/core';
import { zpcAdminLinks } from './zpc-admin-links';
import { zpmAdminLinks } from './zpm-admin-links';
import { SystemStatusContext } from '../shared/contexts/system-status.context';
import {
  CurrentUser,
  EmailSettings,
  HealthStatus,
  MicrosoftGraphRegistrationDetails,
  PstnConnectivityType,
  Role,
  State,
  StatusCategory,
  SystemHealthStatus,
} from '../shared/models/generated/smacsModels';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmailSettingsContext } from '../shared/contexts/email-settings.context';
import { SmacsIcons } from '../shared/models/smacs-icons.enum';
import { AuthenticationContext } from '../shared/contexts/authentication.context';
import { MsGraphConfigContext } from './contexts/ms-graph-config.context';
import { GlobalPropertiesContext } from '../shared/contexts/global-properties.context';
import { NavProps, NavSection } from '../shared/side-navigation/side-navigation.model';
import { HelpdeskOptionsContext } from '../helpdesk/shared/contexts/helpdesk-options.context';
import { MsDialPlanGroupsContext } from './contexts/ms-dial-plan-groups.context';
import { CustomUiOptionsContext } from '../shared/contexts/custom-ui-options.context';
import { BandwidthSettingsContext } from '../shared/contexts/bandwidth-settings.context';
import { ProxyKeysContext } from './contexts/proxy-keys.context';
import { ProxyConnectionSettingsContext } from './contexts/proxy-connection-settings.context';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;
  adminLinks: NavSection[] = zpcAdminLinks;
  pageDisabled = false;
  pageDisabledMessage = '';
  showAdminMenu = false;

  navProps: NavProps = {
    isLicenseExpired: false,
    isMsGraphConfigured: false,
    hasEmailSettings: false,
    isHostedEnabled: false,
    isUserS8Support: false,
    isReportingModuleEnabled: false,
    isAutomationModuleEnabled: false,
    isHighAvailabilityEnabled: false,
    endpointDisabled: false,
    portingEnabled: false,
    isMsDialPlanConfigured: false,
    proxyServer: false,
    isZpmProxyKeyConfigured: false,
    isZpcProxyKeyConfigured: false,
  };

  private _subs = new Subscription();

  constructor(
    private systemStatusContext: SystemStatusContext,
    private router: Router,
    private emailSettingsContext: EmailSettingsContext,
    private authenticationContext: AuthenticationContext,
    private globalPropertiesContext: GlobalPropertiesContext,
    private msGraphConfigCtx: MsGraphConfigContext,
    private msDialPlanGroupContext: MsDialPlanGroupsContext,
    private bandwidthSettingsContext: BandwidthSettingsContext,
    private _helpdeskOptionsContext: HelpdeskOptionsContext,
    private _uiOptionsContext: CustomUiOptionsContext,
    private _zpmProxyKeysContext: ProxyKeysContext,
    private _zpcProxyKeysContext: ProxyConnectionSettingsContext
  ) {}

  ngOnInit(): void {
    const globalPropertiesSub = this.globalPropertiesContext.state$.subscribe((globalProperties) => {
      this.navProps.isHostedEnabled = globalProperties.hostedEnabled;
    });

    const systemStatusSub = this.systemStatusContext.state$.subscribe((systemStatus: SystemHealthStatus) => {
      this.navProps.endpointDisabled = systemStatus.endpointDisabled;

      systemStatus.healthStatuses?.forEach((status: HealthStatus) => {
        if (status.category === StatusCategory.LICENSES) {
          this.navProps.isLicenseExpired = status.state === State.ERROR && status.cause === 'License is expired';
        }
      });
      this.adminLinks = this.navProps.isHostedEnabled ? zpmAdminLinks : zpcAdminLinks;
    });

    const routerSub = this.router.events.subscribe(() => {
      this.pageDisabled = false;
    });

    const emailSettingsSub = this.emailSettingsContext.state$.subscribe((state: EmailSettings) => {
      this.navProps.hasEmailSettings = !!state.host && !!state.from;
    });

    this.authenticationContext.state$.subscribe((currentUser: CurrentUser) => {
      this.showAdminMenu = this.authenticationContext.userIsAtLeast(currentUser, Role.S8_ADMIN);
      this.navProps.isUserS8Support = currentUser.role === Role.ZIRO_SUPPORT;
    });

    const uiOptionSub = this._uiOptionsContext.state$.subscribe((uiOptions) => {
      this.navProps.isReportingModuleEnabled = uiOptions.reportingEnabled;
      this.navProps.isAutomationModuleEnabled = uiOptions.automationEnabled;
      this.navProps.isHighAvailabilityEnabled = uiOptions.highAvailabilityEnabled;
      this.navProps.proxyServer = uiOptions.proxyServerOnlyModeEnabled && !uiOptions.provisioningEnabled;
    });

    const msGraphConfigCtxSub = this.msGraphConfigCtx.state$.subscribe((state: MicrosoftGraphRegistrationDetails) => {
      this.navProps.isMsGraphConfigured = !!state.tenant && !!state.clientId;
    });

    const helpdeskOptionsSub = this._helpdeskOptionsContext.state$.subscribe((helpdeskOptions) => {
      this.navProps.portingEnabled =
        this._helpdeskOptionsContext.isMicrosoftHelpdeskOptions(helpdeskOptions) && helpdeskOptions.portingEnabled;
    });

    const msDialPlanGroupsCtxSub = this.msDialPlanGroupContext.state$.subscribe((state) => {
      this.navProps.isMsDialPlanConfigured = state.length > 0;
      this.navProps.isZiroDraasDialPlanConfigured = !!state.find(
        (group) =>
          group.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS ||
          group.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS_BYOC
      );
    });

    const bandwidthSettingsSub = this.bandwidthSettingsContext.state$.subscribe((state) => {
      this.navProps.isBandwidthConfigured = this.bandwidthSettingsContext.isBandwidthConfigured(state);
    });

    const zpmProxyKeysSub = this._zpmProxyKeysContext.state$.subscribe((proxyKey) => {
      this.navProps.isZpmProxyKeyConfigured = !!proxyKey.keyFirstThree;
    });

    const zpcProxyKeysSub = this._zpcProxyKeysContext.state$.subscribe((proxyKey) => {
      this.navProps.isZpcProxyKeyConfigured = proxyKey.host !== null;
    });

    this._subs.add(emailSettingsSub);
    this._subs.add(systemStatusSub);
    this._subs.add(routerSub);
    this._subs.add(globalPropertiesSub);
    this._subs.add(msGraphConfigCtxSub);
    this._subs.add(helpdeskOptionsSub);
    this._subs.add(uiOptionSub);
    this._subs.add(msDialPlanGroupsCtxSub);
    this._subs.add(bandwidthSettingsSub);
    this._subs.add(zpmProxyKeysSub);
    this._subs.add(zpcProxyKeysSub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
