import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import {
  DirectoryNumber,
  DirectoryNumberRef,
  EndUser,
  EndUserRef,
  EndUserResult,
  PostVoicemail,
  SmtpAddress,
  VoicemailRef
} from '../models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { AuditHeaderService } from './audit-header.service';
import { Nvp } from '../models/nvp';
import { catchError, delayWhen, switchMap } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash';
import { VoicemailSmtpNotificationsDeviceResource } from '../resources/voicemail-smtp-notifications-device.resource';
import { DirectoryNumberResource } from '../../self-serve/resources/directory-number.resource';
import { EndUserResource } from '../resources/end-user.resource';
import { DefaultDirectoryNumberResource } from '../resources/default-directory-number.resource';
import { VoicemailResource } from '../resources/voicemail.resource';
import { DialPlanInventoriesResource } from '../resources/dial-plan-inventories.resource';

export interface VoicemailServiceParams {
  endUserResult: EndUserResult;
  unityServerId: number;
  siteId: number;
  hasSnr: boolean;
  hasExtensionMobility: boolean;
  dnRef?: DirectoryNumberRef;
  endUsers?: EndUserResult[];
}

@Injectable()
export class VoicemailService {
  constructor(
    private http: HttpClient,
    private auditHeaderService: AuditHeaderService,
    private voicemailSmtpNotificationsDeviceResource: VoicemailSmtpNotificationsDeviceResource,
    private directoryNumberResource: DirectoryNumberResource,
    private defaultDirectoryNumberResource: DefaultDirectoryNumberResource,
    private endUserResource: EndUserResource,
    private dialPlanInventoriesResource: DialPlanInventoriesResource,
    private voicemailResource: VoicemailResource
  ) {}

  /**
   * When a VM is provisioned or deleted update the forwardToVoicemail boolean on the DN
   */
  setForwardToVoicemail(
    isDeprovisioningVoicemail: boolean,
    endUserUsername: string | null,
    dn: DirectoryNumberRef,
    siteId: number,
    isMakePublic?: boolean
  ): Observable<void> {
    return new Observable<void>((subscriber) => {
      forkJoin([
        this.dialPlanInventoriesResource.search(dn.serverId, dn.extension),
        this.directoryNumberResource.get(dn.id, dn.serverId.toString()),
      ]).subscribe(([dialPlanSearchResults, existingDn]) => {
        // if there is no dial plan group for this DN, do not try to call the default resource.
        // we do still want to update the call forwarding rules though.
        const defaultDirectoryNumberObs = dialPlanSearchResults[0]
          ? this.defaultDirectoryNumberResource.post({
              siteId: siteId.toString(),
              endUserUsername,
              dialPlanGroupId: dialPlanSearchResults[0].id.toString(),
              withVoicemail: !isDeprovisioningVoicemail,
              extension: existingDn.extension,
            })
          : of(existingDn);

        defaultDirectoryNumberObs.subscribe((defaultDn: DirectoryNumber) => {
          const currentDirectoryNumber = cloneDeep(existingDn);

          existingDn.voicemailProfile = defaultDn.voicemailProfile;

          if (!isDeprovisioningVoicemail) {
            // Forward busy
            existingDn.forwardBusyExternal.forwardToVoicemail = true;
            existingDn.forwardBusyInternal.forwardToVoicemail = currentDirectoryNumber.forwardBusyInternal.forwardToVoicemail === currentDirectoryNumber.forwardBusyExternal.forwardToVoicemail ? true : currentDirectoryNumber.forwardBusyInternal.forwardToVoicemail;

            // Forward no answer
            existingDn.forwardNoAnswerExternal.forwardToVoicemail = true;
            existingDn.forwardNoAnswerInternal.forwardToVoicemail = currentDirectoryNumber.forwardNoAnswerInternal.forwardToVoicemail === currentDirectoryNumber.forwardNoAnswerExternal.forwardToVoicemail ? true : currentDirectoryNumber.forwardNoAnswerInternal.forwardToVoicemail;

            // Forward no coverage
            existingDn.forwardNoCoverageExternal.forwardToVoicemail = currentDirectoryNumber.forwardNoCoverageExternal.forwardToVoicemail === currentDirectoryNumber.forwardNoAnswerExternal.forwardToVoicemail ? true : currentDirectoryNumber.forwardNoCoverageExternal.forwardToVoicemail;
            existingDn.forwardNoCoverageInternal.forwardToVoicemail = currentDirectoryNumber.forwardNoCoverageInternal.forwardToVoicemail === currentDirectoryNumber.forwardNoAnswerExternal.forwardToVoicemail ? true : currentDirectoryNumber.forwardNoCoverageInternal.forwardToVoicemail;

            // Forward Unregistered
            existingDn.forwardUnregisteredExternal.forwardToVoicemail = currentDirectoryNumber.forwardUnregisteredExternal.forwardToVoicemail === currentDirectoryNumber.forwardNoAnswerExternal.forwardToVoicemail ? true :  currentDirectoryNumber.forwardUnregisteredExternal.forwardToVoicemail;
            existingDn.forwardUnregisteredInternal.forwardToVoicemail = currentDirectoryNumber.forwardUnregisteredInternal.forwardToVoicemail === currentDirectoryNumber.forwardNoAnswerExternal.forwardToVoicemail ? true : currentDirectoryNumber.forwardUnregisteredInternal.forwardToVoicemail;

            // Forward cti failure
            existingDn.forwardOnCtiFailure.forwardToVoicemail = currentDirectoryNumber.forwardOnCtiFailure.forwardToVoicemail === currentDirectoryNumber.forwardNoAnswerExternal.forwardToVoicemail ? true : currentDirectoryNumber.forwardOnCtiFailure.forwardToVoicemail;

          } else {
            existingDn.forwardBusyInternal.forwardToVoicemail = false;
            existingDn.forwardBusyExternal.forwardToVoicemail = false;

            existingDn.forwardNoAnswerExternal.forwardToVoicemail = false;
            existingDn.forwardNoAnswerInternal.forwardToVoicemail = false;

            existingDn.forwardNoCoverageExternal.forwardToVoicemail = false;
            existingDn.forwardNoCoverageInternal.forwardToVoicemail = false;

            existingDn.forwardUnregisteredExternal.forwardToVoicemail = false;
            existingDn.forwardUnregisteredInternal.forwardToVoicemail = false;

            existingDn.forwardOnCtiFailure.forwardToVoicemail = false;
          }

          if (isEqual(currentDirectoryNumber, existingDn)) {
            subscriber.next();
            subscriber.complete();
            return;
          }

          const auditTags = isMakePublic
            ? [{ name: 'transfer', value: `${endUserUsername} > public phone` }]
            : [{ name: 'username', value: endUserUsername }];
          this.directoryNumberResource
            .put(dn.id, existingDn, dn.serverId.toString(), endUserUsername ? auditTags : null)
            .subscribe(() => {
              subscriber.next();
              subscriber.complete();
            });
        });
      });
    });
  }

  /**
   * Set enableHomeCluster to false for any end user not on the current cluster, and to true for the end user on the
   * current cluster
   */
  setHomeCluster(
    endUserResults: EndUserResult[],
    currentEndUserId: string,
    defaultUcServiceProfile?: string
  ): Observable<EndUserRef[] | void[]> {
    const getRequests: Observable<EndUserRef>[] = endUserResults.map((endUserResultMapped: EndUserResult) => {
      return this.endUserResource.get(endUserResultMapped.ref.id, endUserResultMapped.ref.serverId).pipe(
        switchMap((endUser) => {
          if (endUser.id === currentEndUserId && defaultUcServiceProfile) {
            endUser.ucServiceProfile = defaultUcServiceProfile;
            endUser.enableHomeCluster = true;
            return this.endUserResource.put(endUser, endUserResultMapped.ref.serverId);
          } else if (endUser.id !== currentEndUserId && endUser.enableHomeCluster) {
            endUser.enableHomeCluster = false;
            return this.endUserResource.put(endUser, endUserResultMapped.ref.serverId);
          } else {
            return of(null);
          }
        })
      );
    });

    return forkJoin(getRequests);
  }

  setSmtpNotificationsDeviceDefaults(
    voicemailRef: VoicemailRef,
    endUserUsername: string,
    unityServerId: number,
    siteId: number
  ): Observable<SmtpAddress | void> {
    return this.voicemailSmtpNotificationsDeviceResource
      .getDefaultValue({
        endUserUsername,
        siteId: siteId.toString(),
      })
      .pipe(
        switchMap((smtpDefaultValue) => {
          if (smtpDefaultValue.email !== '') {
            return this.voicemailSmtpNotificationsDeviceResource.put(
              unityServerId,
              voicemailRef.id,
              { email: smtpDefaultValue.email },
              [{ name: 'Alias', value: voicemailRef.alias }]
            );
          } else {
            return of(null);
          }
        })
      );
  }

  provisionVoicemailWithDefaults(serviceParams: VoicemailServiceParams): Observable<VoicemailRef> {
    let defaultUcServiceProfile: string;
    return forkJoin([
      this.getVoicemailDefault(serviceParams.endUserResult.ref.username, serviceParams.siteId),
      this._getEndUserDefault(serviceParams, true),
    ]).pipe(
      switchMap((data) => {
        const vmDefault = data[0];
        defaultUcServiceProfile = data[1].ucServiceProfile;
        const auditTags: Nvp[] = [{ name: 'username', value: serviceParams.endUserResult.ref.username }];
        return this.http
          .post(
            `/services/cisco/macs/unity-servers/${serviceParams.unityServerId}/voicemails`,
            vmDefault,
            auditTags[0].value ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {}
          )
          .pipe(
            catchError((err) => {
              return throwError(() => err);
            })
          );
      }),
      delayWhen((ref: VoicemailRef) => {
        return forkJoin([
          this.setSmtpNotificationsDeviceDefaults(
            ref,
            serviceParams.endUserResult.ref.username,
            serviceParams.unityServerId,
            serviceParams.siteId
          ),
          this.setForwardToVoicemail(
            false,
            serviceParams.endUserResult.ref.username,
            serviceParams.dnRef,
            serviceParams.siteId
          ),
          this.setHomeCluster(serviceParams.endUsers, serviceParams.endUserResult.ref.id, defaultUcServiceProfile),
        ]);
      })
    );
  }

  deprovisionVoicemail(
    voicemailId: string,
    serviceParams: VoicemailServiceParams,
    isPrimary: boolean
  ): Observable<any> {
    const auditTags: Nvp[] = serviceParams.endUserResult
      ? [{ name: 'username', value: serviceParams.endUserResult.ref.username }]
      : null;

    // If this is a public phone what needs to happen at this point for primary or non-primary extensions?
    if (!isPrimary) {
      return this.voicemailResource.delete(serviceParams.unityServerId, voicemailId, auditTags).pipe(
        switchMap(() => {
          if (serviceParams.dnRef) {
            return this.setForwardToVoicemail(
              true,
              serviceParams.endUserResult?.ref.username || null,
              serviceParams.dnRef,
              serviceParams.siteId
            );
          }

          return of(null);
        })
      );
    } else {
      return this.voicemailResource.delete(serviceParams.unityServerId, voicemailId, auditTags).pipe(
        switchMap(() => {
          return this._getEndUserDefault(serviceParams, false).pipe(
            switchMap((endUser: EndUser) => {
              return forkJoin([
                this.setForwardToVoicemail(
                  true,
                  serviceParams.endUserResult.ref.username,
                  serviceParams.dnRef,
                  serviceParams.siteId
                ),
                this.setHomeCluster(
                  serviceParams.endUsers,
                  serviceParams.endUserResult.ref.id,
                  endUser.ucServiceProfile
                ),
              ]);
            })
          );
        })
      );
    }
  }

  simpleDeprovision(vmId: string, unityServerId: number, auditTags: Nvp[]) {
    return this.voicemailResource.delete(unityServerId, vmId, auditTags);
  }

  getVoicemailDefault(endUserUsername: string, siteId: number): Observable<PostVoicemail> {
    return this.http.post<PostVoicemail>('/services/cisco/defaults/voicemail', {
      siteId,
      endUserUsername,
    });
  }

  private _getEndUserDefault(serviceParams: VoicemailServiceParams, isSaving: boolean): Observable<EndUser> {
    return this.http.post<EndUser>('/services/cisco/defaults/end-user', {
      username: serviceParams.endUserResult.ref.username,
      siteId: serviceParams.siteId.toString(),
      hasVoicemail: isSaving,
      hasSnr: serviceParams.hasSnr,
      hasExtensionMobility: serviceParams.hasExtensionMobility,
    });
  }
}
