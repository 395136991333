<div
  class="position-relative d-none d-md-block animated slideInLeft faster bg-info smacs-left-nav mt-0 ms-detail-card-container"
>
  <div class="sidebar">
    <!-- Contact Card -->
    <div
      class="contact-card position-relative border-rad-all-2"
      [ngClass]="{ 'mt-5 pt-4': userPhoto }"
      *ngIf="microsoft360View?.microsoft365UserLicenses"
    >
      <span
        *ngIf="isResourceAccount"
        class="custom-icon d-inline-flex justify-content-center align-items-center bg-white rounded-circle"
        [ngbTooltip]="'tkey;microsoft_360.view.contact.card.user.resource.account.icon' | translate"
        [tooltipClass]="'tooltip--white'"
        [placement]="'top'"
      >
        <a
          [href]="'/app2/#/360-view/microsoft/' + microsoft360View?.microsoft365UserLicenses.userPrincipalName"
          class="text-black d-inline-block mt-2 custom-icon__link"
          data-automation="microsoft-detail-card-phone-list"
        >
          <i [class]="smacsIcons.PHONE_WITH_LIST" class="fa-2x"></i>
        </a>
      </span>
      <a
        *ngIf="!userPhoto && !isResourceAccount"
        [href]="'/app2/#/360-view/microsoft/' + microsoft360View?.microsoft365UserLicenses.userPrincipalName"
        class="text-white"
        data-automation="microsoft-detail-card-user-icon"
      >
        <i [class]="smacsIcons.USER_CIRCLE" class="fa-3x"></i>
      </a>
      <a
        *ngIf="userPhoto && !isResourceAccount"
        [href]="'/app2/#/360-view/microsoft/' + microsoft360View?.microsoft365UserLicenses.userPrincipalName"
        data-automation="microsoft-detail-card-user-photo"
        class="avatar-pic-wrapper"
      >
        <img
          class="avatar-pic"
          [src]="userPhoto"
          [ngbTooltip]="'tkey;microsoft_360.view.contact.card.user.photo.tooltip' | translate"
          [tooltipClass]="'tooltip--white'"
          [placement]="'top'"
        />
      </a>
      <br />

      <h5
        data-automation="microsoft-detail-card-name"
        class="no-margin"
        [ngbTooltip]="'tkey;microsoft_360.view.contact.card.user.display.name.tooltip' | translate"
        [tooltipClass]="'tooltip--white'"
        [placement]="'top'"
      >
        <span class="font-light">{{ microsoft360View?.microsoft365UserLicenses.displayName }}</span>
      </h5>

      <!--BADGE-->
      <div class="mt-2" data-automation="user-detail-card-account-status">
        <span data-automation="account-badge" [hidden]="!badge?.badgeText" [class]="badge?.outerSpanClass">
          <i *ngIf="badge?.iconClass" [class]="badge?.iconClass"></i>
          <span
            [class]="badge?.innerSpanClass"
            [ngbTooltip]="badge?.tooltip | translate"
            [tooltipClass]="badge?.tooltipClass"
            [placement]="badge?.tooltipPlacement"
          >
            {{ badge?.badgeText | translate }}
          </span>
        </span>
      </div>

      <hr />

      <div
        [ngbTooltip]="'tkey;microsoft_360.view.contact.card.user.principal.name.tooltip' | translate"
        [tooltipClass]="'tooltip--white'"
        [placement]="'top'"
      >
        <a
          [href]="'mailto:' + microsoft360View?.microsoft365UserLicenses.userPrincipalName"
          data-automation="microsoft-detail-card-upn"
        >
          {{ microsoft360View?.microsoft365UserLicenses.userPrincipalName }}
        </a>
      </div>
      <div
        data-automation="microsoft-detail-card-usage-location"
        [ngbTooltip]="'tkey;microsoft_360.view.contact.card.usage.location.tooltip' | translate"
        [tooltipClass]="'tooltip--white'"
        [placement]="'top'"
      >
        {{ microsoft360View?.microsoft365UserLicenses?.usageLocation }}
      </div>
      <div data-automation="microsoft-detail-extension-info">
        <a
          *ngIf="microsoft360View?.teamsPhoneNumberAssignment?.lineUri"
          [href]="'tel://' + microsoft360View?.teamsPhoneNumberAssignment?.lineUri"
          data-automation="microsoft-detail-card-e164"
          [ngbTooltip]="'tkey;microsoft_360.view.contact.card.number.tooltip' | translate"
          [tooltipClass]="'tooltip--white'"
          [placement]="'top'"
          class="number-padding"
        >
          {{ microsoft360View?.teamsPhoneNumberAssignment?.lineUri | telephoneNumberFilter }}
        </a>
        <span
          *ngIf="
            microsoft360View?.teamsPhoneNumberAssignment?.lineUriExtension &&
            microsoft360View?.teamsPhoneNumberAssignment?.lineUri
          "
        >
          ext.
        </span>
        <a
          *ngIf="microsoft360View?.teamsPhoneNumberAssignment?.lineUriExtension"
          [href]="'tel://' + microsoft360View?.teamsPhoneNumberAssignment?.lineUriExtension"
          data-automation="microsoft-detail-card-extension"
          [ngbTooltip]="'tkey;microsoft_360.view.contact.card.extension.tooltip' | translate"
          [tooltipClass]="'tooltip--white'"
          [placement]="'top'"
          class="extension-padding"
        >
          {{ microsoft360View?.teamsPhoneNumberAssignment?.lineUriExtension }}
        </a>
        <a
          *ngIf="microsoft360View?.teamsVoicePolicies?.sharedCallRoutingPolicy?.resourceAccountLineUri"
          [href]="'tel://' + microsoft360View?.teamsVoicePolicies?.sharedCallRoutingPolicy?.resourceAccountLineUri"
          data-automation="microsoft-detail-card-e164"
          [ngbTooltip]="'tkey;microsoft_360.view.contact.card.number.shared_calling.tooltip' | translate"
          [tooltipClass]="'tooltip--white'"
          [placement]="'top'"
          class="number-padding"
        >
          {{
            microsoft360View.teamsVoicePolicies.sharedCallRoutingPolicy.resourceAccountLineUri | telephoneNumberFilter
          }}
        </a>
      </div>
    </div>
  </div>
</div>
