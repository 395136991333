import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuditHeaderService } from '../../../shared/services/audit-header.service';
import { Nvp } from '../../../shared/models/nvp';

@Injectable()
export class ImPresenceResource {
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  post(endUserId: string, serverId: number, auditTags?: Nvp[]): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.post<void>(
      `/services/cisco/macs/cucm-servers/${serverId}/end-users/${endUserId}/im-presence`,
      null,
      httpOptions
    );
  }

  delete(endUserId: string, cucmServerId: string, auditTags?: Nvp[]): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.delete<void>(
      `/services/cisco/macs/cucm-servers/${cucmServerId}/end-users/${encodeURIComponent(endUserId)}/im-presence`,
      httpOptions
    );
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }
}
