import { ServiceTileType } from '../../cisco/user-detail-home/service-tile-type.model';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ServiceProvisioningLevel } from '../../../shared/models/generated/smacsModels';

export enum MicrosoftTile {
  MS365 = 'ms365',
  MICROSOFT_TEAMS_CALLING = 'msTeamsCalling',
  MICROSOFT_TEAMS_AUDIO_CONFERENCING = 'msTeamsAudioConferencing',
  DUBBER = 'dubber',
  SECURITY_GROUP_MEMBERSHIP = 'msSecurityGroupMembership',
}

export interface MicrosoftTileState {
  dataAutomation: string;
  serviceType: MicrosoftTile; // matches Tiles enum values, used for dynamic look up
  tileType: ServiceTileType;
  serviceName: string;
  icon: SmacsIcons;
  description: string;
  isAdding: boolean;
  isDeleting: boolean;
  isDisplayed: boolean;
  isEnabled: boolean;
  provisioningLevel: ServiceProvisioningLevel;
  service: any;
  tooltip?: string;
  isExchangeEnabled?: boolean;
  isExchangeShown?: boolean;
}
