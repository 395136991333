<smacs-phone-buttons-line-extension-search
  #form
  [entity]="extensionSearchEntity"
  [isPending]="isLineFeaturePending"
  [phone]="phone"
  [buttonIndex]="buttonIndex"
  [parentSubmit$]="parentSubmit$"
  [dnRef]="dnRef"
  [lineButtonSummary]="lineButtonSummary"
  [global360View]="global360View"
  [isSingleButton]="isSingleButton"
  (directoryNumberCreated)="onDnCreated($event)"
  (smacsFormsUpdate$)="onExtensionChanged($event)"
  (voicemailUpdated)="onVoicemailUpdate($event)"
  (reconsiderLineFeatureSettings)="fetchLineFeature($event)"
></smacs-phone-buttons-line-extension-search>

<smacs-phone-buttons-line-helpdesk-form
  #form
  *ngIf="lineFeature"
  [entity]="lineFeature"
  [extension]="dnRef.extension"
  [fieldConfig]="fieldConfig"
  [isUccxAgentLine]="lineButtonSummary.isUccxAgent"
  [parentSubmit$]="parentSubmit$"
  (smacsFormsUpdate$)="onLineFeatureUpdate($event)"
></smacs-phone-buttons-line-helpdesk-form>
