import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JobStatus, MicrosoftDialPlanInventorySummary } from '../../../shared/models/generated/smacsModels';
import { FileDownloadService } from '../../../shared/services/file-download.service';

@Injectable()
export class MicrosoftDialPlanInventoryReportResource {
  constructor(private $http: HttpClient, private fileDownloadService: FileDownloadService) {}

  get(): Observable<MicrosoftDialPlanInventorySummary[]> {
    return this.$http.get<MicrosoftDialPlanInventorySummary[]>(`/services/microsoft/reporting/dial-plan-inventory`);
  }

  refresh(): Observable<void> {
    return this.$http.post<void>(`/services/microsoft/reporting/dial-plan-inventory/refresh`, null);
  }

  status(): Observable<JobStatus> {
    return this.$http.get<JobStatus>(`/services/microsoft/reporting/dial-plan-inventory/status`);
  }

  export(): Observable<void> {
    return this.fileDownloadService.downloadFile(`/services/microsoft/reporting/dial-plan-inventory/export`);
  }
}
