<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div class="container-fluid">
  <div class="col-12 pb-5" *ngIf="!isLoading">
    <div class="mx-4 flex-grow-1">
      <smacs-snr-destinations-form
        [entity]="snrDestinations"
        [cucmServerId]="cucmServerId"
        [initialSnrDestinations]="snrDestinations"
        (smacsFormsUpdate$)="onDestinationsValidationStateChange($event)"
        [parentSubmit$]="parentSubmit$"
        [isErrorThrown]="_isSnrDestinationsUpdated && isErrorThrown"
      ></smacs-snr-destinations-form>

      <smacs-snr-profile-form
        [fieldConfig]="snrFieldConfig"
        [entity]="snrProfile"
        (smacsFormsUpdate$)="onProfileValidationStateChange($event)"
        [parentSubmit$]="parentSubmit$"
        [isErrorThrown]="_isSnrProfileUpdated && isErrorThrown"
        [cucmServerId]="cucmServerId"
      ></smacs-snr-profile-form>

      <div>
        <legend>{{ 'tkey;snr.snrprofile.lines.label' | translate }}</legend>
        <div class="mb-3 row">
          <label
            class="col-form-label col-lg-3 text-lg-end smacs-forms-label"
            for="extension"
            data-automation="smacs-text-label"
          >
            <span>{{ 'tkey;snr.snrprofile.extension.label' | translate }}</span>
          </label>
          <form class="col-lg-9">
            <div
              class="input-group h-100"
              [ngbTooltip]="'tkey;snr.snrprofile.lines.extensiondisabled.label' | translate"
            >
              <input
                class="form-control extension-input"
                id="extension"
                type="text"
                [disabled]="true"
                placeholder=" {{ this.primaryExtension.extension | telephoneNumberFilter }}"
              />
            </div>
          </form>
        </div>
      </div>

      <smacs-phone-buttons-line-helpdesk-form
        [fieldConfig]="lineFeatureFieldConfig"
        [entity]="snrProfileLine"
        (smacsFormsUpdate$)="onProfileLineValidationStateChange($event)"
        [parentSubmit$]="parentSubmit$"
        [isErrorThrown]="false"
      ></smacs-phone-buttons-line-helpdesk-form>
    </div>
  </div>
</div>
