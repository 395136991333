import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReleaseNotesModalComponent } from './release-notes-modal.component';
import { ModalsModule } from '../../modals/modals.module';
import { ReleaseNotesService } from './release-notes.service';

@NgModule({
  declarations: [ReleaseNotesModalComponent],
  exports: [ReleaseNotesModalComponent],
  imports: [CommonModule, ButtonModule, TranslateModule, ModalsModule],
  providers: [ReleaseNotesService],
})
export class ReleaseNotesModule {}
