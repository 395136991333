<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1">
    <app-breadcrumbs></app-breadcrumbs>

    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <div [hidden]="isLoading">
      <div class="card border-warning mb-3">
        <div class="card-body">
          <table>
            <tr>
              <td rowspan="2">
                <i class="fa fa-warning text-warning fa-2x"></i>
              </td>
              <td>
                <span>
                  {{ warningTextPreamble | translate }}
                </span>

                <br />

                <span class="strong text-warning">
                  {{ warningText | translate }}
                </span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div [hidden]="importFileType === importFileTypes.CDR">
        <div class="row">
          <div class="col-sm-12">
            <smacs-radio [fieldId]="'importFrom'"></smacs-radio>
          </div>
        </div>

        <hr />
      </div>

      <div class="row">
        <div class="col-sm-offset-2 col-sm-10">
          <smacs-text [fieldId]="'contextUrl'"></smacs-text>
          <smacs-text [fieldId]="'username'"></smacs-text>
          <smacs-text [fieldId]="'password'"></smacs-text>
          <smacs-file-upload [fieldId]="'importFromFile'"></smacs-file-upload>
        </div>
      </div>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
