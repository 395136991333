import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LineFeatureFieldConfig, LineFeatureFieldConfigRequest } from '../models/generated/smacsModels';

@Injectable({
  providedIn: 'root',
})
export class LineFeatureConfigResource {
  protected _baseUrl = '/services/cisco/field-configs/line-features';

  constructor(protected http: HttpClient) {}

  post(body: LineFeatureFieldConfigRequest): Observable<LineFeatureFieldConfig> {
    return this.http.post<LineFeatureFieldConfig>(this._baseUrl, body);
  }
}
