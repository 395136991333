import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, forkJoin, Observable, Subscription } from 'rxjs';
import { map, skip } from 'rxjs/operators';
import { get, uniq } from 'lodash';
import {
  CucmLicenseOptimizationContext,
  LicenseOptimizationReport,
  LicenseTierReportDevice,
} from '../../contexts/cucm-license-optimization.context';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { ButtonSizes, ButtonStyles } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { PhoneResource } from '../../../../shared/resources/phone.resource';
import { ToastService } from '../../../../shared/services/toast.service';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import moment from 'moment';
import { TimeAgoPipe } from '../../services/time-ago.pipe';
import { DatatableColumn, DatatableRow } from '../../../datatable/datatable.component';
import { Title } from '@angular/platform-browser';
import { AuthenticationContext } from '../../../../shared/contexts/authentication.context';
import { BottomNavButton } from '../../../../shared/bottom-nav/bottom-nav.component';
import { ControlSettings, CurrentUser, Privilege } from '../../../../shared/models/generated/smacsModels';
import { DatatableFilterService } from '../../../datatable/datatable-filter.service';
import { GarbageCanPipe } from '../../services/garbage-can.pipe';
import { DeviceSettingsResource } from '../../../../admin/resources/device-settings.resource';

interface LicenseTierReportDeviceRow extends DatatableRow {
  id: string;
  serverId: number;
  deviceName: string;
  ownerId: string;
  deviceModelName: string;
  lastUsed: string;
  recentlyDeleted: boolean;
}

@Component({
  selector: 'app-cucm-license-optimization-candidates',
  templateUrl: './cucm-license-optimization-candidates.component.html',
  styleUrls: ['../../../reporting.scss'],
})
export class CucmLicenseOptimizationCandidatesComponent implements OnInit, OnDestroy {
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  licenseTier = decodeURIComponent(get(this.route, 'snapshot.params.licenseTier'));
  data: LicenseTierReportDeviceRow[] = [];
  isLoading = true;
  isReadOnly: boolean;
  deviceModelOptions = [] as string[];
  cdrRetentionInDays: number;
  newDeviceMaxAgeInDays: number;
  tableDataAutomation = 'cucm-license-optimization-candidates-datatable';
  bottomNavExportButton: BottomNavButton = {
    id: 'exportLicenseOptimizerReport',
    dataAutomation: 'export-license-optimizer-report-button',
    label: 'tkey;reporting.xlsx_export.button',
    icon: this.smacsIcons.EXPORT,
    buttonClass: this.buttonStyles.PRIMARY,
    cb: () => {
      this.onGetFileClicked();
    },
  };
  columns = [
    {
      name: 'deviceName',
      label: 'tkey;reporting.cucm_license_optimizer.candidates.device_name',
    },
    {
      name: 'ownerId',
      label: 'tkey;reporting.cucm_license_optimizer.candidates.owner',
    },
    {
      name: 'deviceModelName',
      label: 'tkey;reporting.cucm_license_optimizer.candidates.device_model',
    },
    {
      name: 'lastUsed',
      label: 'tkey;reporting.cucm_license_optimizer.candidates.last_used',
      sortFn: (a: string, b: string) => {
        if (a === b) {
          return 0;
        } else if (!a) {
          return -1;
        } else if (!b) {
          return 1;
        } else {
          return moment(b).diff(a);
        }
      },
      pipe: this.timeAgoPipe,
    },
  ] as DatatableColumn<LicenseTierReportDeviceRow>[];

  private _subs = new Subscription();

  constructor(
    private licenseOptimizationContext: CucmLicenseOptimizationContext,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private bottomNavService: BottomNavService,
    private smacsModalService: SmacsModalService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private datatableFilterService: DatatableFilterService,
    private phoneResource: PhoneResource,
    private timeAgoPipe: TimeAgoPipe,
    private titleService: Title,
    private authenticationContext: AuthenticationContext,
    private garbageCanPipe: GarbageCanPipe,
    private adminDeviceSettingsResource: DeviceSettingsResource
  ) {}

  ngOnInit() {
    if (this.licenseTier !== undefined) {
      this.breadcrumbsService.updateBreadcrumbs([
        { label: 'tkey;reporting.cucm_license_optimizer.overview.title' },
        {
          label: 'tkey;reporting.cucm_license_optimizer.candidates.breadcrumb.title',
        },
      ]);
    }

    this._setDefaultDeviceUtilizationValues();

    const reportObservable = this.licenseOptimizationContext.report$.pipe(
      map((report: LicenseOptimizationReport) => {
        const reportTier = report[this.licenseTier];
        if (!reportTier || reportTier.optimalTotal >= reportTier.currentTotal) {
          this._navigateToOverview();
          return false;
        } else {
          this.deviceModelOptions = uniq(
            reportTier.devices.map((device: LicenseTierReportDevice) => device.deviceModelName)
          );
          this.data = reportTier.devices.map(
            (device: LicenseTierReportDevice) =>
              ({
                id: device.id,
                serverId: device.serverId,
                deviceName: device.recentlyDeleted
                  ? this.garbageCanPipe.transform(device.deviceName)
                  : device.deviceName,
                ownerId: device.ownerId,
                deviceModelName: device.deviceModelName,
                lastUsed: device.lastUsed || '',
                isTableRowSelectable: device.isDeletionCandidate,
                isTableRowSelectDisabled: device.recentlyDeleted,
                tableRowSelectDisabledTooltip:
                  'tkey;reporting.cucm_license_optimizer.candidates.recently_deleted.tooltip',
                hardcodedTooltips: {
                  deviceName: this.translateService.instant(
                    'tkey;reporting.cucm_license_optimizer.candidates.recently_deleted.tooltip'
                  ),
                },
                hardcodedDisableTooltip: {
                  deviceName: !device.recentlyDeleted,
                },
                recentlyDeleted: device.recentlyDeleted,
              } as LicenseTierReportDeviceRow)
          );
          this.titleService.setTitle(
            this.translateService.instant('tkey;reporting.cucm_license_optimizer.candidates.breadcrumb.smacs.title') +
              this.translateService.instant('tkey;reporting.cucm_license_optimizer.candidates.breadcrumb.title', {
                labelParam: this.licenseTier,
              })
          );
          return true;
        }
      })
    );

    const authenticationObservable = this.authenticationContext.state$.pipe(
      map((user: CurrentUser) => {
        this.isReadOnly = !user.privileges.includes(Privilege.CONTROL_WRITE);
      })
    );

    this._subs.add(
      combineLatest([reportObservable, authenticationObservable]).subscribe(([isRoutable]) => {
        if (isRoutable) {
          this.isLoading = false;
          this.updateBottomNav();
        }
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  isDeviceModelMatch = (filter: string[], row: LicenseTierReportDeviceRow): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.deviceModelName);

  private _delete() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        promptBody: this.translateService.instant('tkey;reporting.cucm_license_optimizer.candidates.delete.confirm', {
          devices: this.data.filter((row: LicenseTierReportDeviceRow) => row.isSelectedInTable).length,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteSelectedDevices(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteSelectedDevices(): Observable<void> {
    const selectedDevices = this.data.filter((device) => device.isSelectedInTable);
    const deleteDevice = selectedDevices.map((device) => {
      // the phone resource can delete any type of device???(?!?!)
      return this.phoneResource.delete(device.id, String(device.serverId));
    });
    return new Observable((subscriber) => {
      forkJoin(deleteDevice).subscribe(() => {
        this.licenseOptimizationContext.report$.pipe(skip(1)).subscribe(() => {
          this.toastService.push(
            ToastTypes.SUCCESS,
            SmacsIcons.REPORTS,
            'tkey;reporting.cucm_license_optimizer.delete.success.title',
            'tkey;reporting.cucm_license_optimizer.delete.success.message',
            {
              num: String(selectedDevices.length),
            }
          );
          this._navigateToOverview();
        });
        this.licenseOptimizationContext.refresh();
        subscriber.next();
      });
    });
  }

  private _navigateToOverview() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  private onGetFileClicked() {
    this._setExportPending(true);
    this.licenseOptimizationContext.downloadFile().subscribe(() => {
      this._setExportPending(false);
      this.toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.EXPORT,
        'tkey;reporting.xlsx_export.downloaded_toast.title',
        'tkey;reporting.xlsx_export.downloaded_toast.message'
      );
    });
  }

  private _setExportPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'exportLicenseOptimizerReport',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
          tooltipVisible: true,
        },
      })
    );
  }

  updateBottomNav() {
    const bottomNavButtons: BottomNavButton[] = [this.bottomNavExportButton];

    if (!this.isReadOnly) {
      const rowsSelected = this.data.filter((row: LicenseTierReportDeviceRow) => row.isSelectedInTable);
      bottomNavButtons.push({
        id: 'licenseOptimizationDelete',
        label:
          rowsSelected.length >= 1
            ? rowsSelected.length === 1
              ? 'tkey;reporting.cucm_license_optimizer.candidates.delete.count.one.label'
              : 'tkey;reporting.cucm_license_optimizer.candidates.delete.count.label'
            : 'tkey;reporting.cucm_license_optimizer.candidates.delete.label',
        labelParam: rowsSelected.length,
        dataAutomation: 'cucm-license-optimization-candidates-delete-button',
        buttonClass: ButtonStyles.DANGER,
        icon: this.smacsIcons.DELETE,
        state: {
          buttonDisableState: {
            disabled: !rowsSelected.length,
            tooltipKey: !rowsSelected.length
              ? 'tkey;reporting.cucm_license_optimizer.candidates.delete.disabled.tooltip'
              : '',
          },
          tooltipVisible: !rowsSelected.length,
        },
        cb: () => this._delete(),
      });
    }

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _setDefaultDeviceUtilizationValues = () => {
    this.adminDeviceSettingsResource.getAdminDeviceSettings().subscribe((settings: ControlSettings) => {
      this.cdrRetentionInDays = settings.cdrRetentionInDays;
      this.newDeviceMaxAgeInDays = settings.newDeviceMaxAgeInDays;
    });
  };
}
