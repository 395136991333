import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProxyKeyResponse, ProxyKeysSettings } from '../../shared/models/generated/smacsModels';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalPropertiesContext } from '../../shared/contexts/global-properties.context';

@Injectable()
export class ProxyKeysContext implements OnDestroy {
  private _stateSource = new ReplaySubject<ProxyKeysSettings>(1);
  state$ = this._stateSource.asObservable();

  private _baseUrl = '/services/microsoft/admin/proxy-keys';
  private _subscriptions = new Subscription();

  constructor(private http: HttpClient, private globalPropertiesContext: GlobalPropertiesContext) {
    const sub = this.globalPropertiesContext.state$.subscribe((globalProperties) => {
      if (globalProperties.hostedEnabled) {
        this._init();
      }
    });

    this._subscriptions.add(sub);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _init() {
    this._get().subscribe((proxyKeySettings) => this._stateSource.next(proxyKeySettings));
  }

  private _get(): Observable<ProxyKeysSettings> {
    return this.http.get<ProxyKeysSettings>(this._baseUrl);
  }

  post(): Observable<ProxyKeyResponse> {
    return this.http.post<ProxyKeyResponse>(this._baseUrl, null).pipe(tap(() => this.refresh()));
  }

  delete(): Observable<void> {
    return this.http.delete<void>(this._baseUrl).pipe(
      tap(() =>
        this._stateSource.next({
          generatedDate: '',
          keyFirstThree: '',
        })
      )
    );
  }

  refresh(): void {
    this._init();
  }
}
