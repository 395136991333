import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDownloadService } from '../../shared/services/file-download.service';

@Injectable()
export class ApplicationLogsResource {
  private _allExportLogs = '/services/system/admin/export-logs';
  private _todayExportLogs = '/services/system/admin/export-logs/today';

  constructor(public fileDownloadService: FileDownloadService) {}

  getAllLogs = (): Observable<void> => {
    return this.fileDownloadService.downloadFile(this._allExportLogs);
  };

  getTodaysExportLogs = (): Observable<void> => {
    return this.fileDownloadService.downloadFile(this._todayExportLogs);
  };
}
