<app-loading-block *ngIf="isLoading"></app-loading-block>

<div *ngIf="!isLoading" data-automation="site-management">
  <div class="site-column-container">
    <div id="site-side-nav" class="site-side-nav">
      <smacs-site-side-nav
        [sectionEntities]="sectionEntities"
        [activeSection]="activeSection"
        [siteName]="site?.name"
        [sideNavLinks]="sideNavLinks"
        [commonFields]="commonFieldsState"
        (commonFieldsToggled)="handleCommonFieldsToggled($event)"
      ></smacs-site-side-nav>
    </div>
    <div class="site-form">
      <app-breadcrumbs></app-breadcrumbs>
      <div>
        <div
          *ngIf="isCommonFieldsBannerVisible"
          class="alert alert-info d-flex justify-content-between"
          [attr.data-automation]="'banner'"
        >
          <strong data-automation="banner-text">
            {{ 'tkey;site_management.site.common_fields_applied_banner.text' | translate }}
          </strong>
          <button type="button" class="border-0" data-automation="banner-close" (click)="closeAlert()">
            <span>&times;</span>
          </button>
        </div>
        <ziro-site-configuration-form
          [entity]="siteConfigEntity"
          [ldapConfig]="ldapConfig"
          [siteSummary]="siteSummary"
          [siteId]="site.id"
          (smacsFormsUpdate$)="onFormUpdate($event, 'site-configuration-form')"
        ></ziro-site-configuration-form>
        <!-- Desk Phone -->
        <ziro-site-management-service
          data-entity-key="Desk Phone"
          [serviceSetting]="entities['Desk Phone']"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'Desk Phone')"
          [commonFieldsState]="commonFieldsState['Desk Phone']"
        ></ziro-site-management-service>
        <!-- Extension Mobility -->
        <ziro-site-management-service
          data-entity-key="Extension Mobility"
          [serviceSetting]="entities['Extension Mobility']"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          [serviceSettingToCopy]="copiableServiceSettings.deskphone"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'Extension Mobility')"
          [commonFieldsState]="commonFieldsState['Extension Mobility']"
        ></ziro-site-management-service>
        <!-- IM Softphone -->
        <ziro-site-management-service
          data-entity-key="IM Softphone"
          [serviceSetting]="entities['IM Softphone']"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          [serviceSettingToCopy]="copiableServiceSettings.deskphone"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'IM Softphone')"
          [commonFieldsState]="commonFieldsState['IM Softphone']"
        ></ziro-site-management-service>
        <!-- iPhone -->
        <ziro-site-management-service
          data-entity-key="IPhone"
          [serviceSetting]="entities.IPhone"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          [serviceSettingToCopy]="copiableServiceSettings.imSoftphone ?? copiableServiceSettings.deskphone"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'IPhone')"
          [commonFieldsState]="commonFieldsState['IPhone']"
        ></ziro-site-management-service>
        <!-- Android -->
        <ziro-site-management-service
          data-entity-key="Android"
          [serviceSetting]="entities.Android"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          [serviceSettingToCopy]="
            copiableServiceSettings.iPhone ?? copiableServiceSettings.imSoftphone ?? copiableServiceSettings.deskphone
          "
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'Android')"
          [commonFieldsState]="commonFieldsState['Android']"
        ></ziro-site-management-service>
        <!-- Tablet -->
        <ziro-site-management-service
          data-entity-key="Tablet"
          [serviceSetting]="entities.Tablet"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          [serviceSettingToCopy]="
            copiableServiceSettings.iPhone ?? copiableServiceSettings.imSoftphone ?? copiableServiceSettings.deskphone
          "
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'Tablet')"
          [commonFieldsState]="commonFieldsState['Tablet']"
        ></ziro-site-management-service>
        <!-- CIPC -->
        <ziro-site-management-service
          data-entity-key="CIPC"
          [serviceSetting]="entities.CIPC"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          [serviceSettingToCopy]="copiableServiceSettings.deskphone"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'CIPC')"
          [commonFieldsState]="commonFieldsState['CIPC']"
        ></ziro-site-management-service>
        <!-- Directory Number -->
        <ziro-site-management-service
          data-entity-key="Directory Number"
          [serviceSetting]="entities['Directory Number']"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'Directory Number')"
          [commonFieldsState]="commonFieldsState['Directory Number']"
        ></ziro-site-management-service>
        <!-- Lines Features -->
        <ziro-site-management-service
          data-entity-key="Line Features"
          [serviceSetting]="entities['Line Features']"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'Line Features')"
          [commonFieldsState]="commonFieldsState['Line Features']"
        ></ziro-site-management-service>
        <!-- Voicemail -->
        <ziro-site-management-service
          data-entity-key="Voicemail"
          [serviceSetting]="entities.Voicemail"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="unityMetadata"
          [unityServerOptions]="unityServerOptions"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'Voicemail')"
          [commonFieldsState]="commonFieldsState['Voicemail']"
        ></ziro-site-management-service>
        <!-- IM&P -->
        <ziro-site-management-service
          data-entity-key="IM Presence"
          [serviceSetting]="entities['IM Presence']"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'IM Presence')"
          [commonFieldsState]="commonFieldsState['IM Presence']"
        ></ziro-site-management-service>
        <!-- End User -->
        <ziro-site-management-service
          data-entity-key="End User"
          [serviceSetting]="entities['End User']"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'End User')"
          [commonFieldsState]="commonFieldsState['End User']"
        ></ziro-site-management-service>
        <!-- SNR -->
        <ziro-site-management-service
          data-entity-key="Single Number Reach"
          [serviceSetting]="entities['Single Number Reach']"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          [serviceSettingToCopy]="copiableServiceSettings.deskphone"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'Single Number Reach')"
          [commonFieldsState]="commonFieldsState['Single Number Reach']"
        ></ziro-site-management-service>
        <!-- Translation Pattern -->
        <ziro-site-management-service
          data-entity-key="Translation Pattern"
          [serviceSetting]="entities['Translation Pattern']"
          [cucmMetadata]="cucmMetadata"
          [clusterResult]="clusterResult"
          [siteName]="site.name"
          [unityMetadata]="null"
          (smacsFormsUpdate$)="onFormUpdate($event)"
          (sideNavLinksUpdate$)="updateSideNavLinks($event, 'Translation Pattern')"
          [commonFieldsState]="commonFieldsState['Translation Pattern']"
          [commonFieldScrollSpacing]="true"
        ></ziro-site-management-service>
      </div>
    </div>
  </div>
</div>
