import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cluster } from '../../../../shared/models/generated/smacsModels';

@Injectable()
export class ClustersResource {
  constructor(private http: HttpClient) {}

  get(clusterId: number): Observable<Cluster> {
    return this.http.get<Cluster>(`/services/cisco/admin/clusters/${clusterId}`);
  }

  put(cluster: Cluster): Observable<void> {
    return this.http.put<void>(`/services/cisco/admin/clusters/${cluster.id}`, cluster);
  }

  post(cluster: Cluster): Observable<number> {
    return this.http.post<number>(`/services/cisco/admin/clusters`, cluster);
  }

  delete(clusterId: number): Observable<void> {
    return this.http.delete<void>(`/services/cisco/admin/clusters/${clusterId}`);
  }
}
