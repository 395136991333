import { Injectable, QueryList } from '@angular/core';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { delay, first, map } from 'rxjs/operators';
import { SmacsFieldComponentConfig, SmacsFormsValidationState } from './smacs-forms-models';
import { SmacsNavsetFormNavComponent } from '../shared/smacs-navset/smacs-navset-form/smacs-navset-form-nav/smacs-navset-form-nav.component';
import { SmacsFieldAbstractDirective } from './smacs-field-abstract.directive';

interface PhoneValidationStates {
  modelProtocolValidationState: SmacsFormsValidationState;
  phoneFieldsValidationState: SmacsFormsValidationState;
  phoneServiceSubscriptionsValidationState: SmacsFormsValidationState;
  phoneButtonValidationState: SmacsFormsValidationState;
  mobilityIdentityValidationState: SmacsFormsValidationState;
}

interface SnrValidationStates {
  destinationsValidationState: SmacsFormsValidationState;
  snrProfileValidationState: SmacsFormsValidationState;
  profileLineValidationState: SmacsFormsValidationState;
}

interface SingleValidationState {
  singleValidation: SmacsFormsValidationState;
}

export interface CheckPendingFieldParams {
  snrDestinationFields?: SmacsNavsetFormNavComponent;
  fieldComponents?: QueryList<SmacsFieldAbstractDirective<any, any, SmacsFieldComponentConfig>>;
  validationStates: PhoneValidationStates | SnrValidationStates | SingleValidationState;
}

@Injectable()
export class SmacsFormStateService {
  private _isFormDirty = false;

  isFormLoading = new Subject<boolean>();
  isSaveDisabled = new Subject<boolean>();

  setIsFormDirty(isDirty: boolean) {
    this._isFormDirty = isDirty;
  }

  getIsFormDirty(): boolean {
    return this._isFormDirty;
  }

  /**
   * Check if the async field enters the 'pending' state.
   * Return validationStates of fields after the async validation is complete.
   */

  checkForPendingFields(params: CheckPendingFieldParams): Observable<boolean> {
    const asyncFields = this._getAsyncFields(params);
    const asyncValidationSources = asyncFields.length
      ? asyncFields.map((asyncField) =>
          asyncField.isAsyncValidationRunning.pipe(first((isValidating) => !isValidating))
        )
      : [of(null)];

    return forkJoin(asyncValidationSources).pipe(
      map(() => {
        if ('phoneFieldsValidationState' in params.validationStates) {
          params.validationStates.phoneFieldsValidationState = this._getValidation(params);
        }
        if ('destinationsValidationState' in params.validationStates) {
          params.validationStates.destinationsValidationState = this._getNestedFieldValidation(params);
          params.validationStates.snrProfileValidationState = this._getValidation(params);
        }
        if ('singleValidation' in params.validationStates) {
          params.validationStates.singleValidation = this._getValidation(params);
        }

        return Object.values(params.validationStates).every((val) => val === SmacsFormsValidationState.VALID);
      }),
      delay(0)
    );
  }

  private _getAsyncFields(
    params: CheckPendingFieldParams
  ): SmacsFieldAbstractDirective<any, any, SmacsFieldComponentConfig>[] {
    if (!!params.fieldComponents) {
      // Method input was a query list containing field components
      return params.fieldComponents.filter(
        (fieldComponent) => fieldComponent.showAsyncValidation && fieldComponent.isValidationInProgress
      );
    } else {
      // nested Navset fields
      return params.snrDestinationFields.formNavItems
        .map((navItem) =>
          navItem.fieldComponents.filter(
            (fieldComponent) => fieldComponent.showAsyncValidation && fieldComponent.isValidationInProgress
          )
        )
        .reduce((acc, curr) => acc.concat(curr), []);
    }
  }

  private _getValidation(params: CheckPendingFieldParams): SmacsFormsValidationState {
    const isAnyFieldInvalid = params.fieldComponents.some(
      (value) => value.validationState === SmacsFormsValidationState.INVALID
    );
    return isAnyFieldInvalid ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
  }

  private _getNestedFieldValidation(params: CheckPendingFieldParams): SmacsFormsValidationState {
    const isAnyFieldInvalid =
      params.snrDestinationFields.formNavItems.some((navItem) => {
        return navItem.fieldComponents.some(
          (fieldComponent) => fieldComponent.validationState === SmacsFormsValidationState.INVALID
        );
      }) || params.snrDestinationFields.fieldData.some((navItem) => navItem.hasError);
    return isAnyFieldInvalid ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
  }
}
