import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class FileDownloadService {
  constructor(private http: HttpClient) {}

  downloadFile(fileUrl: string, method: 'get' | 'post' = 'get', queryParams?: object): Observable<void> {
    const url = this._buildUrl(fileUrl, queryParams);

    return this.http.request(method, url, { responseType: 'blob', observe: 'response' }).pipe(
      map((response) => {
        this._downloadFile(response);
      })
    );
  }

  private _buildUrl(fileUrl: string, queryParams?: object): string {
    let url = fileUrl;
    if (queryParams) {
      Object.entries(queryParams).forEach(([key, value], index, array) => {
        url += index === 0 ? '?' : '&';
        url += `${key}=${value}`;
      });
    }
    return url;
  }

  private _downloadFile(response: HttpResponse<Blob | string>) {
    const blob: Blob | string = response.body;
    const url = window.URL.createObjectURL(blob as Blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = this._getFilename(response);

    document.body.appendChild(a);
    a.click();

    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  private _getFilename(fileResponse: HttpResponse<Blob | string>): string {
    return fileResponse.headers
      .get('content-disposition')
      .split(';')
      .map((prop) => prop.trim())
      .find((prop) => prop.startsWith('filename'))
      .replace('filename=', '');
  }
}
