import { Component, Input, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { LdapUserDialPlanDetailsFieldConfig } from '../../../../shared/models/generated/smacsModels';
import { of } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';

export interface DnLdapDetailsFormEntity {
  e164Number: string;
  extension: string;
}

@Component({
  selector: 'smacs-directory-number-ldap-details-form',
  templateUrl: './directory-number-ldap-details-form.component.html',
})
export class SmacsDirectoryNumberLdapDetailsFormComponent
  extends SmacsFormAbstractDirective<DnLdapDetailsFormEntity, DnLdapDetailsFormEntity>
  implements OnInit
{
  @Input() fieldConfig: LdapUserDialPlanDetailsFieldConfig;

  formConfig: SmacsFormConfig;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._initFormConfig();
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        e164Number: {
          label: 'tkey;shared.dnWizard.dnDetails.ldapDetails.e_number.label',
          dataAutomation: 'dn-ldap-details-e164-number',
          hidden: () => !this.fieldConfig.e164Number.show,
          required: () => this.fieldConfig.e164Number.required,
          defaultValue: () => this.fieldConfig.e164Number.defaultValue,
        },
        extension: {
          label: 'tkey;shared.dnWizard.dnDetails.ldapDetails.extension.label',
          dataAutomation: 'dn-ldap-details-extension',
          hidden: () => !this.fieldConfig.extension.show,
          required: () => this.fieldConfig.extension.required,
          defaultValue: () => this.fieldConfig.extension.defaultValue,
        },
      },
    };
  }

  protected submit() {
    return of(null);
  }
}
