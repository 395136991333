import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OrphanedDevicesResource } from '../resources/orphaned-devices.resource';
import { OrphanedDeviceList, UnusedDevicesReport } from '../../../shared/models/generated/smacsModels';
import { OptimizationTile, OrphanedTile, UnusedTile } from '../state-management/models/report-dashboard.model';
import { DeviceUtilizationResource } from '../resources/device-utilization-resource';
import { CucmLicenseUsageResource } from '../resources/cucm-license-usage.resource';
import { TileStatusService } from './tile-status.service';

@Injectable()
export class DashboardService {
  constructor(
    protected $http: HttpClient,
    private orphanedDevicesResource: OrphanedDevicesResource,
    private deviceUtilizationResource: DeviceUtilizationResource,
    private cucmLicenseUsageResource: CucmLicenseUsageResource,
    private updateTileStatusService: TileStatusService
  ) {}
  getOrphanedTileValues(): Observable<OrphanedTile> {
    return new Observable((subscription) => {
      this.orphanedDevicesResource.find().subscribe((response: OrphanedDeviceList) => {
        const orphanedDevices = response.results;
        const ophanedStatus = { orphanedDeviceCount: orphanedDevices.length, tileStatus: {} } as OrphanedTile;
        subscription.next({
          tileStatus: this.updateTileStatusService.updateOrphanedDevicesTileStatus(ophanedStatus),
          orphanedDeviceCount: orphanedDevices.length,
          isLoading: false,
        });

        subscription.complete();
      });
    });
  }

  getUnusedTileValues(isReportsEnabled: boolean): Observable<UnusedTile> {
    return new Observable((subscription) => {
      this.deviceUtilizationResource.getUnusedDevicesReport().subscribe((response: UnusedDevicesReport) => {
        const unusedTileStatus = {
          numCalls: response.numCalls,
          unusedCount: response.numUnusedDevices,
          percentUnused: response.totalNumDevices ? (response.numUnusedDevices / response.totalNumDevices) * 100 : 0,
        } as UnusedTile;
        subscription.next({
          isLoading: false,
          tileStatus: this.updateTileStatusService.updateUnusedTileStatus(unusedTileStatus, isReportsEnabled),
          numCalls: response.numCalls,
          unusedCount: response.numUnusedDevices,
          percentUnused: response.totalNumDevices ? (response.numUnusedDevices / response.totalNumDevices) * 100 : 0,
        });
        subscription.complete();
      });
    });
  }

  getOptimizationTileValues(): Observable<OptimizationTile> {
    return new Observable((subscription) => {
      this.cucmLicenseUsageResource.getCucmLicenseUsage().subscribe((response) => {
        const totalLicenseCount = response.publicDevices.length + response.users.length;
        const optimizedPublicDeviceCount = response.publicDevices.filter(
          (device) => !device.isDeletionCandidate
        ).length;
        const optimizedUserCount = response.users.filter(
          (user) => user.cucmCurrentLicense === user.cucmOptimalLicense
        ).length;

        this.deviceUtilizationResource.getUnusedDevicesReport().subscribe((unusedDeviceReport: UnusedDevicesReport) => {
          const numCalls = unusedDeviceReport.numCalls;
          const optimizationTileStatus = {
            totalLicenseCount: totalLicenseCount,
            optimizationPercent:
              totalLicenseCount === 0
                ? 0
                : Math.round(((optimizedUserCount + optimizedPublicDeviceCount) / totalLicenseCount) * 100),
            numCalls: numCalls,
            tileStatus: {},
          } as OptimizationTile;
          subscription.next({
            isLoading: false,
            numCalls: numCalls,
            totalLicenseCount: totalLicenseCount,
            optimizationPercent: optimizationTileStatus.optimizationPercent,
            tileStatus: this.updateTileStatusService.updateLicenceDashboardTileStatus(optimizationTileStatus),
          });
          subscription.complete();
        });
      });
    });
  }
}
