import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../shared/breadcrumbs/breadcrumbs.service';
import { GlobalPropertiesContext } from '../../shared/contexts/global-properties.context';
import { Subscription } from 'rxjs';

export interface ApiSection {
  title: string;
  content: string;
}
@Component({
  selector: 'smacs-about-rest',
  templateUrl: './about-rest.component.html',
  styleUrls: ['../../admin/admin-page.scss'],
})
export class AboutRestComponent implements OnInit, OnDestroy {
  window = window;
  ciscoSection: ApiSection[] = [
    {
      title: 'tkey;api.page.section.admin.title',
      content: 'tkey;api.page.cisco.section.admin.content',
    },
    {
      title: 'tkey;api.page.section.macs.title',
      content: 'tkey;api.page.section.cisco.macs.content',
    },
    {
      title: 'tkey;api.page.section.field.configs.title',
      content: 'tkey;api.page.section.cisco.field.configs.content',
    },
    {
      title: 'tkey;api.page.section.automate_itsm.title',
      content: 'tkey;api.page.section.cisco.automate_itsm.content',
    },
    {
      title: 'tkey;api.page.section.automate_job.title',
      content: 'tkey;api.page.section.cisco.automate_job.title.content',
    },
    {
      title: 'tkey;api.page.section.defaults.title',
      content: 'tkey;api.page.section.cisco.defaults.content',
    },
    {
      title: 'tkey;api.page.section.views.title',
      content: 'tkey;api.page.section.cisco.views.content',
    },
    {
      title: 'tkey;api.page.section.report.title',
      content: 'tkey;api.page.section.cisco.report.content',
    },
  ];
  systemSection: ApiSection[] = [
    {
      title: 'tkey;api.page.section.admin.title',
      content: 'tkey;api.page.system.section.admin.content',
    },
    {
      title: 'tkey;api.page.section.field.configs.title',
      content: 'tkey;api.page.section.system.field.configs.content',
    },
    {
      title: 'tkey;api.page.section.info.title',
      content: 'tkey;api.page.section.system.info.content',
    },
    {
      title: 'tkey;api.page.section.security.title',
      content: 'tkey;api.page.section.security.content',
    },
  ];
  microsoftSection: ApiSection[] = [
    {
      title: 'tkey;api.page.section.admin.title',
      content: 'tkey;api.page.section.microsoft.admin.content',
    },
    {
      title: 'tkey;api.page.section.macs.title',
      content: 'tkey;api.page.section.microsoft.macs.content',
    },
    {
      title: 'tkey;api.page.section.field.configs.title',
      content: 'tkey;api.page.section.microsoft.field.configs.content',
    },
    {
      title: 'tkey;api.page.section.automate_itsm.title',
      content: 'tkey;api.page.section.microsoft.automate_itsm.content',
    },
    {
      title: 'tkey;api.page.section.automate_job.title',
      content: 'tkey;api.page.section.microsoft.automate_job.content',
    },
    {
      title: 'tkey;api.page.section.views.title',
      content: 'tkey;api.page.section.microsoft.views.content',
    },
  ];
  isHostedEnabled = false;

  private _subscription = new Subscription();

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private globalPropertiesContext: GlobalPropertiesContext
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;pages.zero_touch.about_rest.title' }]);

    const globalPropertiesSub = this.globalPropertiesContext.state$.subscribe((globalProperties) => {
      this.isHostedEnabled = globalProperties.hostedEnabled;
    });
    this._subscription.add(globalPropertiesSub);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
}
