<div
  class="mb-3 row"
  [hidden]="state.hidden"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage,
    'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
  }"
>
  <label
    class="smacs-forms-label col-form-label col-3 text-end"
    [attr.data-automation]="config.dataAutomation + '-label'"
  >
    <span *ngIf="state.required" class="text-danger smacs-horizontal-form-asterisk">*</span>
    <span>{{ getLabel() | translate }}</span>
  </label>
  <div class="col-9">
    <div
      class="form-check"
      [ngClass]="{ 'has-warning': showWarningMessage() }"
      [attr.data-automation]="'smacs-radio-option-voicemail'"
    >
      <input
        class="form-check-input"
        type="radio"
        [attr.id]="config.dataAutomation + '-' + 'voicemail' + '-' + uniqueRadioId"
        [name]="config.dataAutomation"
        [value]="'voicemail'"
        [(ngModel)]="temporaryValue.forwardToVoicemail"
        (ngModelChange)="transformData($event, 'voicemailOption', ngModelDir)"
        #ngModelDir="ngModel"
        [attr.data-automation]="'smacs-radio-option-input-voicemail'"
      />
      <label
        class="form-check-label"
        [attr.for]="config.dataAutomation + '-' + 'voicemail' + '-' + uniqueRadioId"
        [attr.data-automation]="'smacs-radio-option-label-voicemail'"
      >
        <i class="icon-search-voicemail"></i>
        {{ 'tkey;shared.dnWizard.dnDetails.dnVoicemail.voicemail.label' | translate }}
      </label>
    </div>
    <div
      class="form-check"
      [ngClass]="{ 'has-warning': showWarningMessage() }"
      [attr.data-automation]="'smacs-radio-option-destination'"
    >
      <input
        class="form-check-input"
        type="radio"
        [attr.id]="config.dataAutomation + '-' + 'destination' + '-' + uniqueRadioId"
        [name]="config.dataAutomation"
        [value]="'destination'"
        #ngModelDir="ngModel"
        (ngModelChange)="transformData($event, 'destinationOption', ngModelDir)"
        [(ngModel)]="temporaryValue.forwardToVoicemail"
        [attr.data-automation]="'smacs-radio-option-input-destination'"
      />
      <input
        type="text"
        [name]="config.dataAutomation + '-' + uniqueRadioId"
        class="text-field form-control"
        [ngClass]="{ 'alert-danger': showErrorMessage() }"
        [attr.id]="config.dataAutomation + '-input'"
        [placeholder]="'tkey;shared.device.phone.call_forward.placeholder' | translate"
        [attr.data-automation]="'smacs-text-input'"
        [value]="temporaryValue.destination"
        [disabled]="temporaryValue.forwardToVoicemail !== 'destination'"
        [(ngModel)]="temporaryValue.destination"
        (ngModelChange)="transformData($event, 'destinationInput', ngModelDir)"
        #ngModelDir="ngModel"
      />
    </div>
    <div class="smacs-forms-feedback-area">
      <div
        class="smacs-forms-validation-message text-danger"
        data-automation="smacs-forms-validation-message"
        [hidden]="validationState !== ValStates.INVALID"
      >
        {{ 'tkey;validators.global.required.error' | translate }}
      </div>
      <div
        class="smacs-forms-validation-message text-danger"
        data-automation="smacs-forms-error-message"
        [hidden]="!showErrorMessage()"
      >
        {{ 'tkey;validators.global.error.pattern' | translate }}
      </div>

      <div
        class="smacs-forms-validation-message text-warning"
        [ngClass]="{ 'd-inline': config.autogeneration?.inline }"
        data-automation="smacs-forms-warning-message"
        [hidden]="!showWarningMessage()"
      >
        {{ 'tkey;shared.section.primarydn.cf.voicemail.warning' | translate }}
      </div>
    </div>
  </div>
</div>
