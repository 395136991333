import { SmacsIcons } from '../../models/smacs-icons.enum';
import { TranslateService } from '@ngx-translate/core';

export interface ToastMessageParams {
  [key: string]: string;
}

export enum ToastTypes {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
  SUCCESS = 'success',
}

export abstract class ToastServiceAbstract {
  defaultTimeout = 5000;
  defaultErrorTimeout = 20000;
  smacsIcons = SmacsIcons;

  protected constructor(protected translateService: TranslateService) {}

  push: (
    type: ToastTypes,
    icon: string,
    title: string,
    message: string,
    messageParams: ToastMessageParams,
    timeout?: number
  ) => void;

  pushDeleteToast(serviceType: string, name: string): void {
    this.push(
      ToastTypes.INFO,
      `${this.smacsIcons.DELETE} text-danger`,
      'tkey;shared.toast.delete.success.title',
      'tkey;shared.toast.delete.success.message',
      {
        type: this.translateService.instant(serviceType),
        name: this.translateService.instant(name),
      }
    );
  }

  pushSaveToast(serviceType: string, name: string, icon: string): void {
    this.push(
      ToastTypes.SUCCESS,
      icon,
      'tkey;shared.toast.save.success.title',
      'tkey;shared.toast.save.success.message',
      {
        type: this.translateService.instant(serviceType),
        name: name ? this.translateService.instant(name) : this.translateService.instant(serviceType),
      }
    );
  }

  protected _createToastHtmlBody(
    icon: string,
    title: string,
    message: string,
    messageParams: ToastMessageParams = {},
    iconClass: string
  ): string {
    const translatedTitle = this.translateService.instant(title, messageParams);
    const translatedMessage = this.translateService.instant(message, messageParams);

    return `
      <div class="smacs-toast">
        <div class="smacs-toast-icon-container">
          <i class="icon-circle smacs-toast-icon-bg"></i>
          <i class="${icon} ${iconClass} toast-icon smacs-toast-icon"></i>
        </div>

        <div class="smacs-toast-text">
          <h4 class="smacs-toast-title">${translatedTitle}</h4>
          <div class="smacs-toast-body">${translatedMessage}</div>
        </div>
      </div>
    `;
  }

  protected _getEnumKeyByEnumValue<T>(enumObj: T, enumValue: T[keyof T]): keyof T {
    const enumKeys = Object.keys(enumObj) as (keyof T)[];
    const keys = enumKeys.filter((k) => enumObj[k] === enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
}
