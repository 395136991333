import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GroupedRows, NumberPortabilitiesContext } from '../check-number-portability/number-portabilities.context';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ButtonSizes, ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import {
  MicrosoftHelpdeskOptions,
  PortInOrderDraft,
  PortInOrderDraftRef,
  PortType,
} from '../../../shared/models/generated/smacsModels';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { forkJoin, Observable, Subscription, switchMap, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';
import { cloneDeep } from 'lodash';
import {
  EntityTable,
  EntityTableCheckboxField,
  EntityTableContentRow,
  EntityTableFieldTypes,
} from '../../../shared/entity-table/entity-table.models';
import { PortabilityRow } from '../check-number-portability/check-number-portability.component';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { SmacsSelectConfig } from '../../../forms/fields/select/smacs-select.component';
import { SmacsCheckboxFieldConfig } from '../../../shared/custom-configs/smacs-checkbox-config/smacs-checkbox-config.component';
import { DateComponentConfig } from '../../../forms/fields/date/date.component';
import { WeekDay } from '@angular/common';
import { groupedProvinces, groupedStates } from '../order-view-and-edit/order-view-and-edit.component';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { HelpdeskOptionsContext } from '../../../helpdesk/shared/contexts/helpdesk-options.context';
import { PortInDraftsResource } from '../port-in-orders/port-in-drafts.resource';
import { ToastService } from '../../../shared/services/toast.service';
import { catchError, tap } from 'rxjs/operators';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { EntityTableComponent } from '../../../shared/entity-table/entity-table.component';
import { PortInOrdersResource } from '../port-in-orders/port-in-orders.resource';
import { provinces } from '../order-view-and-edit/state-province-options';

@Component({
  selector: 'ziro-create-draft',
  templateUrl: './create-draft.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [PortInOrdersResource],
})
export class CreateDraftComponent extends SmacsFormAbstractDirective<PortInOrderDraft> implements OnInit, OnDestroy {
  @ViewChild(EntityTableComponent) entityTableComponent: EntityTableComponent;
  buttonSizes = ButtonSizes;
  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;
  isLoading = true;
  showNoPortsAlert = false;
  isTableDisplayed = false;
  formConfig: SmacsFormConfig;
  table: EntityTable = {
    columns: [
      {
        columnId: 'isSelected',
        cssColumnSize: 'col-sm-1',
        label: '',
        headerSelectAll: true,
      },
      {
        columnId: 'losingCarrier',
        cssColumnSize: 'col-sm-3',
        label: 'tkey;admin.order_numbers.create_orders.datatable.losing_carrier.label',
      },
      {
        columnId: 'numberRanges',
        cssColumnSize: 'col-sm-8',
        label: 'tkey;admin.order_numbers.create_orders.datatable.numbers.label',
      },
    ],
    resultsMessage: 'tkey;admin.order_numbers.create_orders.datatable.no_draftable_numbers',
    hasActions: false,
  };
  tableRows: EntityTableContentRow[] = [];
  private _zipValidationParams = '';
  private _companyId = '';
  private _groupedRows: GroupedRows;
  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _numberPortabilityContext: NumberPortabilitiesContext,
    private _bottomNavService: BottomNavService,
    private _breadcrumbsService: BreadcrumbsService,
    private _translateService: TranslateService,
    private _router: Router,
    private _phoneNumberFilter: TelephoneNumberFilter,
    private _helpdeskOptionsContext: HelpdeskOptionsContext,
    private _toastService: ToastService,
    private _portInOrdersResource: PortInOrdersResource,
    private _portInDraftsResource: PortInDraftsResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;admin.order_numbers.port_in_orders.title',
        url: '/app2/#/admin/order-numbers/port-in-orders',
      },
      {
        label: 'tkey;admin.order_numbers.check_number_portability.title',
        url: '/app2/#/admin/order-numbers/port-in-drafts/check-number-portability',
      },
      { label: 'tkey;admin.order_numbers.create_orders.title' },
    ]);

    const numberPortabilitySub = this._numberPortabilityContext.groupedRowsState$.subscribe(
      (groupedRows: GroupedRows) => {
        this._groupedRows = groupedRows;
        const formattedNumberRows = this._formatNumbers(cloneDeep(groupedRows));
        this.tableRows = this._getTableRows(formattedNumberRows);
        this.showNoPortsAlert = this._getNumberOfPossiblePorts() === 0 || Object.keys(groupedRows).length === 0;
        this.isTableDisplayed = Object.keys(groupedRows).length !== 0;
        this._initForm();
        this._refreshBottomNav();
        this.isLoading = false;
      }
    );
    const helpdeskOptionsSub = this._helpdeskOptionsContext.state$.subscribe((options: MicrosoftHelpdeskOptions) => {
      this._companyId = options.companyId;
    });
    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this._refreshBottomNav(true);
      if (!this.isFormValid()) {
        this._bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: !this.isFormValid(),
          })
        );
        this._refreshBottomNav();
      }
    });
    const formUpdateSub = this.smacsFormsUpdate$.subscribe(() => {
      if (this.isFormValid()) {
        this._bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: !this.isFormValid(),
          })
        );
      }
    });
    this._subscriptions.add(numberPortabilitySub);
    this._subscriptions.add(helpdeskOptionsSub);
    this._subscriptions.add(formSubmittedSub);
    this._subscriptions.add(formUpdateSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  submit(): Observable<PortInOrderDraftRef[]> {
    return this._createDrafts();
  }

  handleSelectAllChange($event: boolean) {
    this.tableRows = this.tableRows.map((row) => {
      return {
        ...row,
        fields: {
          ...row.fields,
          isSelected: {
            type: EntityTableFieldTypes.CHECKBOX,
            value: !(row.fields.isSelected as EntityTableCheckboxField).disabled && $event,
            disabled: (row.fields.isSelected as EntityTableCheckboxField).disabled,
          },
        },
      };
    });
    this._refreshBottomNav();
  }

  handleTableFieldChange() {
    this._refreshBottomNav();
  }

  private _formatNumbers(groupedRows: GroupedRows): GroupedRows {
    for (const groupedRow in groupedRows) {
      groupedRows[groupedRow].numbers = groupedRows[groupedRow].numbers.map((number) =>
        this._phoneNumberFilter.transform(number.startsWith('+1') ? number : `+1 ${number}`)
      );
    }
    return groupedRows;
  }

  private _getTableRows(formattedNumberRows: GroupedRows): EntityTableContentRow[] {
    return Object.values(formattedNumberRows).map((row: PortabilityRow) => {
      const disabledTooltip = !row.rateCenterSupported
        ? this._translateService.instant(
            'tkey;admin.order_numbers.create_orders.datatable.rate_center_not_supported.tooltip',
            { value: row.rateCenter }
          )
        : !row.losingCarrierSupported
        ? this._translateService.instant(
            'tkey;admin.order_numbers.create_orders.datatable.carrier_not_supported.tooltip',
            { value: row.losingCarrier }
          )
        : '';

      let losingCarrierHtml: string = null;
      if (row.portType === PortType.MIXED || row.csrRetrievalSupported) {
        losingCarrierHtml = row.losingCarrier;
        if (row.portType === PortType.MIXED) {
          losingCarrierHtml += ' <i class="icon-warning-triangle text-warning"></i>';
        }
        if (row.csrRetrievalSupported) {
          losingCarrierHtml += `<br><span class="badge bg-success">${this._translateService.instant(
            'tkey;admin.order_numbers.create_orders.datatable.csr_retrieval_supported'
          )}</span>`;
        }
      }

      return {
        content: {
          losingCarrier: row.losingCarrier,
          numberRanges: row.numbers.join(', '),
        },
        fields: {
          isSelected: {
            type: EntityTableFieldTypes.CHECKBOX,
            value: row.losingCarrierSupported && row.rateCenterSupported,
            disabled: !row.losingCarrierSupported || !row.rateCenterSupported,
          },
        },
        cssClass: !row.losingCarrierSupported || !row.rateCenterSupported ? 'disabled' : '',
        tooltip: {
          isSelected: {
            content: disabledTooltip,
            isDisabled: false,
          },
          losingCarrier: {
            content: 'tkey;admin.order_numbers.create_orders.portability_warning.mixed_port_type',
            isDisabled: row.portType !== PortType.MIXED,
          },
        },
        badges: {
          numberRanges: {
            values: [...row.numbers],
          },
        },
        html: {
          losingCarrier: losingCarrierHtml,
        },
      };
    });
  }

  private _refreshBottomNav(pending = false) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'cancel-button',
          dataAutomation: 'cancel-button',
          label: 'tkey;global.button.cancel.text',
          buttonClass: ButtonStyles.DEFAULT,
          icon: SmacsIcons.NONE,
          cb: () => {
            this._router.navigateByUrl('/admin/order-numbers/port-in-drafts/check-number-portability');
          },
        },
        {
          id: 'create-drafts-button',
          dataAutomation: 'create-drafts-button',
          label: this._translateService.instant('tkey;admin.order_numbers.create_orders.create_drafts_button.text', {
            value: this._getNumberOfSelectedPorts().toString(),
          }),
          buttonClass: ButtonStyles.PRIMARY,
          state: {
            pending: pending,
            tooltipVisible: this._getNumberOfSelectedPorts() === 0,
            buttonDisableState: {
              disabled: this._getNumberOfSelectedPorts() === 0,
              tooltipKey: 'tkey;admin.order_numbers.create_orders.create_draft.button_disabled.tooltip',
            },
          },
          icon: SmacsIcons.OK,
          submitSubject: this._validateAndSubmitSource,
          type: ButtonTypes.SUBMIT,
        },
      ])
    );
  }

  private _createDrafts(): Observable<PortInOrderDraftRef[]> {
    return forkJoin([this._portInDraftsResource.get(), this._portInOrdersResource.get()]).pipe(
      switchMap(([drafts, orders]) => {
        let orderIdNumber = 1;
        const filteredItems = [...drafts, ...orders].filter(
          (item) =>
            item.customerOrderId &&
            item.customerOrderId.toLowerCase().includes(this.entity.customerOrderId.toLowerCase())
        );
        if (filteredItems.length) {
          const mappedNums = filteredItems.map((order) => {
            return order.customerOrderId.split(this.entity.customerOrderId).pop();
          });
          if (mappedNums.length) {
            const intArray = mappedNums.map((id) => parseInt(id, 10)).filter((num) => !isNaN(num));
            if (intArray.length) {
              orderIdNumber = Math.max(...intArray) + 1;
            }
          }
        }

        const allDraftObs$: Observable<PortInOrderDraftRef>[] = Object.values(this._groupedRows)
          .filter((row) => row.rateCenterSupported && row.losingCarrierSupported)
          .map((groupedRow: PortabilityRow) => {
            const customerOrderId = `${this._companyId} ${this.entity.customerOrderId} ${orderIdNumber}`;
            const draft: PortInOrderDraft = {
              customerOrderId,
              isTollFree: groupedRow.portType === PortType.MANUALTOLLFREE,
              losingCarrierName: groupedRow.losingCarrier,
              phoneNumbers: groupedRow.numbers,
              rateCenterName: groupedRow.rateCenter,
              desiredFocDate: this.formData.desiredFocDate ?? '',
              accountNumber: this.formData.accountNumber ?? '',
              addressLine2: this.formData.addressLine2 ?? '',
              businessName: this.formData.businessName ?? '',
              city: this.formData.city ?? '',
              country: provinces.map((prov) => prov.value).includes(this.formData.stateCode)
                ? 'Canada'
                : 'United States',
              draftId: null,
              houseNumber: this.formData.houseNumber ?? '',
              loaAuthorizingPerson: this.formData.loaAuthorizingPerson ?? '',
              billingTelephoneNumber: this.formData.billingTelephoneNumber
                ? '+1'.concat(this.formData.billingTelephoneNumber)
                : '',
              newBillingTelephoneNumber: this.formData.newBillingTelephoneNumber
                ? this.formData.newBillingTelephoneNumber !== ''
                  ? '+1'.concat(this.formData.newBillingTelephoneNumber)
                  : ''
                : '',
              partialPort: this.formData.partialPort || false,
              pinNumber: this.formData.pinNumber ?? '',
              stateCode: this.formData.stateCode ?? '',
              streetName: this.formData.streetName ?? '',
              zip: this.formData.zip,
            };
            orderIdNumber++;
            return this._portInDraftsResource.post(draft).pipe(
              tap(() => {
                this._toastService.push(
                  ToastTypes.SUCCESS,
                  SmacsIcons.OK,
                  'tkey;admin.order_numbers.port_in_drafts.title',
                  this._translateService.instant('tkey;admin.order_numbers.create_orders.create_draft.success', {
                    orderId: customerOrderId,
                  })
                );
              })
            );
          });
        return forkJoin([...allDraftObs$]).pipe(
          catchError((err) => {
            this._refreshBottomNav();
            return throwError(() => err);
          }),
          tap(() => this._router.navigateByUrl('/admin/order-numbers/port-in-orders'))
        );
      })
    );
  }

  private _initForm() {
    this.formConfig = {
      fields: {
        customerOrderId: {
          label: 'tkey;admin.order_numbers.create_orders.create_draft.site_form.site_field.label',
          dataAutomation: 'siteName',
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.TEXT,
          }),
          required: true,
          helpText: 'tkey;admin.order_numbers.create_orders.create_draft.site_form.site_field.helptext',
          validation: [
            {
              validator: (val: string) => {
                return !val || val.length <= 30 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '30',
                },
              },
            },
          ],
        },
        loaAuthorizingPerson: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.authorizing_person.label',
          dataAutomation: 'loaAuthorizingPerson',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: true,
          helpText: 'tkey;admin.order_numbers.create_orders.create_draft.site_form.loa_field.helptext',
          validation: [
            {
              validator: (val: string) => {
                return !val || val.length <= 15 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '15',
                },
              },
            },
          ],
        },
        businessName: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.business_name.label',
          dataAutomation: 'businessName',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: true,
          validation: [
            {
              validator: (val: string) => {
                return !val || val.length <= 50 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '50',
                },
              },
            },
          ],
        },
        houseNumber: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.street_number.label',
          dataAutomation: 'houseNumber',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: true,
        },
        streetName: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.street_name.label',
          dataAutomation: 'streetName',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: true,
        },
        addressLine2: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.address_line_2.label',
          dataAutomation: 'addressLine2',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        },
        city: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.city.label',
          dataAutomation: 'city',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: true,
        },
        stateCode: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.state.label',
          dataAutomation: 'stateCode',
          componentConfig: new SmacsSelectConfig({
            options: [...groupedStates, ...groupedProvinces],
            bindValue: 'value',
          }),
          required: true,
        },
        country: {
          label: '',
          dataAutomation: 'country',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          hidden: () => true,
        },
        zip: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.zip.label',
          dataAutomation: 'zip',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: true,
          validation: [
            {
              validator: (val: string, stateCode: string) => {
                if (provinces.map((prov) => prov.value).includes(stateCode)) {
                  const postalRegex = /^([A-Za-z][0-9][A-Za-z])[\s-]?([0-9][A-Za-z][0-9])$/;
                  this._zipValidationParams = 'Postal Code';
                  return postalRegex.test(val) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
                } else {
                  const zipRegex = /(^\d{5}$)|(^\d{5}-\d{4}$)/;
                  this._zipValidationParams = 'ZIP';
                  return zipRegex.test(val) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
                }
              },
              message: () => {
                return {
                  content: 'tkey;admin.order_numbers.port_in_drafts_edit.form.zip.validation.message',
                  params: {
                    type: this._zipValidationParams,
                  },
                };
              },
              injectValuesFromFields: ['stateCode'],
            },
          ],
        },
        billingTelephoneNumber: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.billing_telephone_number.label',
          dataAutomation: 'billingTelephoneNumber',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          helpText: 'tkey;admin.order_numbers.port_in_drafts_edit.form.billing_telephone_number.helptext',
          required: true,
          validation: [
            {
              validator: (val: string) => {
                return !val || val.length === 10 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '10',
                },
              },
            },
          ],
        },
        accountNumber: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.account_number.label',
          dataAutomation: 'account-number',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          validation: [
            {
              validator: (val: string) => {
                return !val || val.length <= 15 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '15',
                },
              },
            },
          ],
        },
        pinNumber: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.account_pin.label',
          dataAutomation: 'account-pin',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          validation: [
            {
              validator: (val: string) => {
                return !val || val.length <= 20 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '20',
                },
              },
            },
          ],
        },
        partialPort: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.partial_port.label',
          dataAutomation: 'partialPort',
          componentConfig: new SmacsCheckboxFieldConfig(),
          helpText: 'tkey;admin.order_numbers.port_in_drafts_edit.form.partial_port.helptext',
        },
        newBillingTelephoneNumber: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.replacement_billing_telephone_number.label',
          dataAutomation: 'newBillingTelephoneNumber',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          helpText: 'tkey;admin.order_numbers.port_in_drafts_edit.form.replacement_billing_telephone_number.helptext',
          hidden: () => !this.formData?.partialPort,
          validation: [
            {
              validator: (val: string) => {
                return !val || (val.startsWith('+1') && val.length === 12)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: 'tkey;validators.global.e164.invalid.error',
            },
          ],
        },
        desiredFocDate: {
          label: 'tkey;admin.order_numbers.port_in_drafts_edit.form.desired_foc_date.label',
          dataAutomation: 'desiredFocDate',
          componentConfig: new DateComponentConfig({
            disabledDays: [WeekDay.Saturday, WeekDay.Sunday],
            allowToday: false,
            allowBeforeToday: false,
          }),
          required: true,
          helpText: 'tkey;admin.order_numbers.port_in_drafts_edit.form.desired_foc_date.helptext',
        },
      },
    };
  }

  private _getNumberOfSelectedPorts(): number {
    return this.tableRows.reduce((acc, curr) => {
      if ((curr.fields.isSelected as EntityTableCheckboxField).value) {
        acc++;
      }
      return acc;
    }, 0);
  }

  private _getNumberOfPossiblePorts(): number {
    return this.tableRows.reduce((acc, curr) => {
      if (curr.cssClass !== 'disabled') {
        acc++;
      }
      return acc;
    }, 0);
  }
}
