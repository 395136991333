import { Component, OnDestroy, OnInit } from '@angular/core';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import { ClustersResource } from '../services/clusters.resource';
import { Subscription, switchMap } from 'rxjs';
import { EntityTable, EntityTableContentRow } from '../../../../shared/entity-table/entity-table.models';
import { Cluster, SiteSummary } from '../../../../shared/models/generated/smacsModels';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { ZiroModalPromptModalOptions } from '../../../../modals/prompt-modal/prompt-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';

@Component({
  selector: 'smacs-clusters-list',
  templateUrl: './clusters-list.component.html',
})
export class ClustersListComponent implements OnInit, OnDestroy {
  table: EntityTable = {
    columns: [
      {
        columnId: 'name',
        cssColumnSize: 'col-sm-5',
        label: 'tkey;admin.site_management.cluster_sites.cluster_name',
      },
      {
        columnId: 'servers',
        cssColumnSize: 'col-sm-5',
        label: 'tkey;admin.site_management.cluster_sites.servers',
      },
    ],
    resultsMessage: 'tkey;admin.site_management.cluster_sites.no_results',
    cssClearButtonColumnSize: 'col-sm-2',
    hasActions: true,
  };
  tableRows: EntityTableContentRow[] = [];

  isLoading = true;

  private _subscription = new Subscription();

  constructor(
    private siteSummaryContext: SiteSummaryContext,
    private clustersResource: ClustersResource,
    private breadcrumbsService: BreadcrumbsService,
    private bottomNavService: BottomNavService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private smacsModalService: SmacsModalService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.site_management.cluster_sites.header' }]);

    this._initBottomNav();

    const siteSummarySub = this.siteSummaryContext.state$.subscribe((siteSummary) => {
      this._buildTableRows(siteSummary);
      this.isLoading = false;
    });
    this._subscription.add(siteSummarySub);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private _buildTableRows(siteSummary: SiteSummary) {
    this.tableRows = siteSummary.clusters.map((cluster) => {
      const servers: string[] = [cluster.cucmServerDescription];
      if (cluster.impServerId) {
        servers.push(cluster.impServerDescription);
      }
      if (cluster.pcceServerId) {
        servers.push(cluster.pcceServerDescription);
      }

      return {
        content: {
          name: cluster.name,
          servers: servers,
        },
        badges: {
          servers: {
            values: servers,
          },
        },
        actionsColumnSize: 'col-sm-2',
        actions: [
          {
            buttonStyle: ButtonStyles.PRIMARY,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/${cluster.id}/sites`,
            icon: SmacsIcons.NEXT,
            dataAutomation: 'clusters-list-sites-button',
            onClick: () => {
              this.router.navigate([cluster.id, 'sites'], { relativeTo: this.route });
            },
          },
          {
            buttonStyle: ButtonStyles.DANGER,
            icon: SmacsIcons.DELETE,
            dataAutomation: 'clusters-list-delete-button',
            onClick: () => {
              this._openDeleteClusterModal(cluster);
            },
          },
          {
            buttonStyle: ButtonStyles.DEFAULT,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/${cluster.id}`,
            icon: SmacsIcons.EDIT,
            dataAutomation: 'clusters-list-edit-button',
            onClick: () => {
              this.router.navigate([cluster.id], { relativeTo: this.route });
            },
          },
        ],
      };
    });
  }

  private _initBottomNav() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'add-cluster',
          label: 'tkey;admin.site_management.add_cluster.button',
          icon: SmacsIcons.ADD,
          cb: () => {
            this.router.navigate(['new'], { relativeTo: this.route });
          },
          dataAutomation: 'clusters-list-add-cluster-button',
          buttonClass: ButtonStyles.PRIMARY,
        },
      ])
    );
  }

  private _openDeleteClusterModal(cluster: Cluster) {
    const options: ZiroModalPromptModalOptions = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        promptBody: this.translateService.instant(
          'tkey;admin.site_management.cluster_sites.cluster_confirmation_delete.text',
          {
            cluster: cluster,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'prompt-modal-cancel',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'prompt-modal-confirm',
            cb: () =>
              this.clustersResource.delete(cluster.id).pipe(
                switchMap(() => this.siteSummaryContext.refreshSiteSummary()),
                tap(() => {
                  this.toastService.push(
                    ToastTypes.INFO,
                    SmacsIcons.DELETE,
                    'tkey;admin.clusters.delete.toast.title',
                    'tkey;admin.clusters.delete.toast.message',
                    { clusterName: cluster.name },
                    null,
                    'text-danger'
                  );
                })
              ),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }
}
