import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { JobStatus, MicrosoftProvisioningJob } from '../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { ModelContextAbstract } from '../../shared/contexts/abstract/model.context.abstract';

@Injectable()
export class MsZeroTouchProvisioningContext extends ModelContextAbstract<MicrosoftProvisioningJob> {
  protected _baseUrl = '/services/microsoft/automate-jobs/zero-touch-provisionings';

  _get: (jobId: number) => Observable<MicrosoftProvisioningJob>;
  _post: (job: MicrosoftProvisioningJob) => Observable<MicrosoftProvisioningJob>;
  _put: (jobId: number, job: MicrosoftProvisioningJob) => Observable<MicrosoftProvisioningJob>;
  _delete: (jobId: number) => Observable<void>;

  private _stateSource = new ReplaySubject<MicrosoftProvisioningJob[]>(1);
  state$ = this._stateSource.asObservable();

  constructor(protected $http: HttpClient) {
    super($http);
    this.init().subscribe();
  }

  runJob = (jobId: number): Observable<JobStatus> => {
    return this.http.post<JobStatus>(`${this._baseUrl}/${jobId}/run`, null);
  };

  init(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._getAll().subscribe((data: MicrosoftProvisioningJob[]) => {
        this._stateSource.next(data);
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }

  getAll(): Observable<MicrosoftProvisioningJob[]> {
    return new Observable((subscriber: Subscriber<MicrosoftProvisioningJob[]>) => {
      this._getAll().subscribe((data: MicrosoftProvisioningJob[]) => {
        this._stateSource.next(data);
        subscriber.next(data);
        subscriber.complete();
      });
    });
  }

  post(job: MicrosoftProvisioningJob): Observable<MicrosoftProvisioningJob> {
    return new Observable((subscriber: Subscriber<MicrosoftProvisioningJob>) => {
      this._post(job).subscribe((data) => {
        this.getAll();
        subscriber.next(data);
      });
    });
  }

  put(job: MicrosoftProvisioningJob): Observable<MicrosoftProvisioningJob> {
    return new Observable((subscriber: Subscriber<MicrosoftProvisioningJob>) => {
      this._put(job.id, job).subscribe((data: MicrosoftProvisioningJob) => {
        this.getAll();
        subscriber.next(data);
      });
    });
  }

  delete(jobId: number): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._delete(jobId).subscribe(() => {
        this.getAll();
        subscriber.next();
      });
    });
  }

  get(jobId: number): Observable<MicrosoftProvisioningJob> {
    return new Observable((subscriber: Subscriber<MicrosoftProvisioningJob>) => {
      this._get(jobId).subscribe((data: MicrosoftProvisioningJob) => {
        subscriber.next(data);
        subscriber.complete();
      });
    });
  }
}
