<div class="card card-well top-buffer animated fadeIn faster" data-automation="send-email-entity-table">
  <div class="card-header">
    <legend class="bottom-buffer mt-0 pb-2">
      <span class="fa-stack fa-lg text-info">
        <i class="icon-circle fa-stack-2x"></i>
        <i [class]="SmacsIcons.SEND_EMAIL + ' me-1 fa-stack-1x fa-inverse'"></i>
      </span>

      <span>{{ 'tkey;helpdesk.send_email.table.title' | translate }}</span>
    </legend>
    <p>{{ 'tkey;helpdesk.send_email.table.helptext' | translate }}</p>
    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <div class="send-email__table-card" *ngIf="!isLoading">
      <app-entity-table [table]="entityTable" [tableRows]="tableRows"></app-entity-table>
    </div>

    <div class="text-end pb-2 border-top pt-3">
      <smacs-button
        [buttonStyle]="ButtonStyles.DEFAULT"
        [buttonSize]="ButtonSizes.NORMAL"
        [label]="'tkey;global.button.cancel.text'"
        [dataAutomation]="'send-email-cancel-button'"
        (clicked)="goHome()"
      ></smacs-button>
    </div>
  </div>
</div>
