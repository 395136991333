<smacs-reassign-user-search
  *ngIf="!isLoading && isUserSelect"
  [reassignType]="ReassignTypes.PUBLIC_TO_USER"
  [serverId]="serverId"
></smacs-reassign-user-search>
<smacs-reassign-user-execute
  *ngIf="!isLoading && !isUserSelect"
  [reassignType]="ReassignTypes.PUBLIC_TO_USER"
  [isVoicemailPresentOnLineOne]="isVoicemailPresentOnLineOne"
  [publicPhoneVoicemailRef]="publicPhoneVoicemailRef"
  [serverId]="serverId"
  [siteId]="siteId"
  (reassignClicked)="onReassignClicked($event)"
></smacs-reassign-user-execute>
