import './environments/environment';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import * as Lottie from 'lottie-web';

if ((window as any).environment.production) {
  enableProdMode();
}

/**
 * Polyfill for bug in Chrome 80 (https://github.com/Stack8/smacs/issues/5128)
 * https://stackoverflow.com/questions/60109837/angular-7-cannot-find-control-with-name-formcontrolname-in-angular-reactive-fo
 */
(function () {
  const arrayReduce = Array.prototype.reduce;
  let callback;
  Object.defineProperty(Array.prototype, 'reduce', {
    // eslint-disable-next-line @typescript-eslint/ban-types
    value: function (cb: Function, ...args: any) {
      callback = cb;
      return arrayReduce.call(this, callback, ...args);
    },
  });
})();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

if (window.navigator.webdriver) {
  freezeAnimations();
}

function freezeAnimations() {
  (Lottie.default as any).freeze();
  document.getElementsByTagName('body')[0].classList.add('disable-all-animations');
}
