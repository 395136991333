import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ButtonSizes, ButtonStyles } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';

@Component({
  selector: 'smacs-service-tile-delete',
  templateUrl: './service-tile-delete.component.html',
})
export class SmacsServiceTileDeleteComponent {
  @Input() serviceName: string;
  @Input() description: string;
  @Input() isDeleting = false;

  @Output() confirmButtonClicked = new EventEmitter();
  @Output() cancelButtonClicked = new EventEmitter();

  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;

  onCancelClicked() {
    this.cancelButtonClicked.emit();
  }

  onConfirmClicked() {
    this.confirmButtonClicked.emit();
  }
}
