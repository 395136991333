import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultEndUserRequest, EndUser } from '../models/generated/smacsModels';
import { Observable } from 'rxjs';

@Injectable()
export class DefaultEndUserResource {
  constructor(private http: HttpClient) {}

  post(request: DefaultEndUserRequest): Observable<EndUser> {
    return this.http.post<EndUser>('/services/cisco/defaults/end-user', {
      ...request,
    });
  }
}
