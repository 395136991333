<div class="card card-well top-buffer animated fadeIn faster">
  <div class="card-header">
    <legend class="bottom-buffer mt-0 pb-2">
      <span class="fa-stack fa-lg text-info">
        <i class="icon-circle fa-stack-2x"></i>
        <i [attr.class]="smacsIcons.SITE + ' me-1 fa-stack-1x fa-inverse'"></i>
      </span>

      <span>{{ 'tkey;operations.menu.change_site.text' | translate }}</span>
    </legend>

    <smacs-select [fieldId]="'site'"></smacs-select>

    <div *ngIf="isValidCluster" [attr.data-automation]="'warning-container'">
      <hr />

      <p [innerHTML]="'tkey;change_site.public_phone.warning.device_pool.text' | translate"></p>

      <div class="alert alert-warning">
        <i [attr.class]="smacsIcons.WARNING + ' me-1'"></i>
        <strong>{{ 'tkey;change_site.public_phone.warning.post_change.text' | translate }}</strong>
      </div>

      <p>
        <strong>{{ 'tkey;change_site.public_phone.warning.continue.text' | translate }}</strong>
      </p>

      <hr />
    </div>
    <div class="text-end">
      <div *ngIf="!isSubmitting && isFormSubmitted && validationState === ValidationState.INVALID">
        <div class="alert smacs-error-notification alert-danger animated shake text-start" role="alert">
          <i class="fa fa-exclamation fa-lg"></i>

          <span class="strong">
            {{ 'tkey;validators.global.required.missing.error' | translate }}
          </span>
        </div>
      </div>

      <smacs-button
        [dataAutomation]="'public-phone-change-site-cancel-button'"
        [buttonStyle]="buttonStyles.DEFAULT"
        [label]="'tkey;dialogs.button.cancel'"
        [cssClass]="'me-1'"
        [isDisabled]="isSubmitting"
        (clicked)="onCancelClicked()"
      ></smacs-button>
      <smacs-submit-button
        [isPending]="isSubmitting"
        [dataAutomation]="'public-phone-change-site-button'"
        [iconPending]="smacsIcons.SAVING"
        [buttonStyle]="buttonStyles.PRIMARY"
        [label]="'tkey;change.site.change_site'"
        [formSubmitSubject]="_validateAndSubmitSource"
      ></smacs-submit-button>
    </div>
  </div>
</div>
