import { Pipe, PipeTransform } from '@angular/core';
import { DeviceType } from '../../../shared/models/generated/smacsModels';
import { TranslateService } from '@ngx-translate/core';

@Pipe({ name: 'publicDevice' })
export class PublicDevicePipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(owner: string, deviceType: string): string {
    return owner || (deviceType === DeviceType.DESK_PHONE ? this.translate.instant('tkey;pages.phone.title') : '');
  }
}
