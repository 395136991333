export const ServiceType = {
  PRIMARY_EXTENSION: 'Primary Extension',
  UCCX_AGENT: 'UCCX Agent',
  PCCE_AGENT: 'PCCE Agent',
  VOICEMAIL: 'Voicemail',
  SNR: 'Single Number Reach',
  DESKPHONE: 'Desk Phone',
  EXTENSION_MOBILITY: 'Extension Mobility',
  IM_PRESENCE: 'IM Presence',
  SOFTPHONE: 'IM Softphone',
  CIPC: 'CIPC',
  ANDROID: 'Android',
  IPHONE: 'IPhone',
  TABLET: 'Tablet',
  CTI_PORT: 'CTI Port',
  CTI_REMOTE_DEVICE: 'CTI Remote Device',
  SPARK_REMOTE_DEVICE: 'Cisco Spark Remote Device',
  MICROSOFT_365: 'Microsoft 365',
  MICROSOFT_TEAMS_CALLING: 'Microsoft Teams Calling',
  MICROSOFT_TEAMS_AUDIO_CONFERENCING: 'Microsoft Teams Audio Conferencing',
} as const;

/** The type of phone, i.e. Deskphone, Android, etc. This is to be expanded eventually.
 *  This is different from ServiceType in that the strings map to the routes of the different phones. */
export enum PhoneType {
  DESKPHONE = 'Deskphone',
  ANDROID = 'Android',
  IPHONE = 'IPhone',
  EXTENSION_MOBILITY = 'Extension Mobility',
  TABLET = 'Tablet',
  IM_SOFTPHONE = 'IM Softphone',
  CIPC = 'CIPC',
}
