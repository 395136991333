<div class="card" [hidden]="isHidden">
  <div class="card-header">
    <p class="mb-0 header-height" data-automation="navset-header">
      {{ this.item.fields[this.item.labelId] || this.item.defaultLabel }}
    </p>
  </div>
  <div class="card-body" [attr.data-automation]="'navset-item-template-' + item.id">
    <div
      *ngFor="let name of fieldNames"
      [attr.data-automation]="(getLabel(formConfig.fields[name].label) | translate) + '-smacs-field'"
    >
      <div data-automation="navset-field">
        <ng-container *ngIf="!!getTitle(formConfig.fields[name])">
          <smacs-fieldset-header [legend]="getTitle(formConfig.fields[name])">
            <smacs-text *ngIf="isFieldSmacsText(formConfig.fields[name])" [fieldId]="name"></smacs-text>
            <smacs-radio *ngIf="isFieldSmacsRadio(formConfig.fields[name])" [fieldId]="name"></smacs-radio>
            <smacs-checkbox *ngIf="isFieldCheckbox(formConfig.fields[name])" [fieldId]="name"></smacs-checkbox>
          </smacs-fieldset-header>
        </ng-container>
        <ng-container *ngIf="!getTitle(formConfig.fields[name])">
          <smacs-text *ngIf="isFieldSmacsText(formConfig.fields[name])" [fieldId]="name"></smacs-text>
          <smacs-radio *ngIf="isFieldSmacsRadio(formConfig.fields[name])" [fieldId]="name"></smacs-radio>
          <smacs-checkbox *ngIf="isFieldCheckbox(formConfig.fields[name])" [fieldId]="name"></smacs-checkbox>
        </ng-container>
      </div>
    </div>
  </div>
</div>
