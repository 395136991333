import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ButtonState, IndexedPhoneButton, PhoneButton, PhoneButtonTypes } from '../../models/phone-button';

import { get } from 'lodash';
import { SmacsIcons } from '../../models/smacs-icons.enum';
import { DragDropMode } from '../drag-drop-mode.enum';
import { LineButtonPeripheralSummary } from '../layout/phone-buttons-standard.component';
import { PhoneButtonsService } from '../shared/phone-buttons.service';
import { TelephoneNumberFilter } from '../../filters/telephone-number.filter';
import { LineButton } from '../../models/generated/smacsModels';

@Component({
  selector: 'app-phone-buttons-dnd-button',
  templateUrl: './phone-buttons-drag-n-drop-button.component.html',
  styleUrls: ['./phone-buttons-drag-n-drop-button.component.scss'],
})
export class PhoneButtonsDragNDropButtonComponent implements OnInit, OnChanges {
  @Input() isPending: boolean;
  @Input() button: PhoneButton;
  @Input() buttonIdx: number;
  @Input() userMode: DragDropMode;
  @Input() buttonState: ButtonState = new ButtonState();
  @Input() inverse = false;
  @Input() dnSummary: LineButtonPeripheralSummary;
  @Output() deletedPhoneButton = new EventEmitter<IndexedPhoneButton>();

  smacsIcons = SmacsIcons;
  DragDropMode = DragDropMode;
  tooltipMessage: string;
  clearTooltipMessage: string;
  showVoicemailIcon = false;
  showUccxIcon = false;

  constructor(
    private phoneButtonsService: PhoneButtonsService,
    private telephoneNumberFilter: TelephoneNumberFilter,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.buttonIdx === 0 && !this.isHelpdeskMode()) {
      this.tooltipMessage = 'tkey;shared.device.phone.phone_buttons.button_tab.tooltip.line_one';
    } else if (this.buttonState.uneditable && this.userMode === DragDropMode.SELF_SERVE) {
      this.tooltipMessage = 'tkey;shared.device.phone.phone_buttons.button_tab.tooltip.uneditable';
    } else if (this.buttonState.unmovable) {
      this.tooltipMessage = 'tkey;shared.device.phone.phone_buttons.button_tab.tooltip.unmovable';
    } else if (this.buttonIdx === 0) {
      this.clearTooltipMessage =
        'tkey;shared.device.phone.phone_buttons.button_tab.tooltip.helpdesk_mode.line_one.clear.button';
    } else {
      this.tooltipMessage = null;
    }
    this.isEmpty(this.button);
    this.phoneButtonsService.lineButtonSummary.subscribe((lineButtonSummary) => {
      if (this.button.type === PhoneButtonTypes.line) {
        const lineDn = (this.button as LineButton).dn;
        if (!!lineDn && !!lineButtonSummary && lineDn.id === lineButtonSummary.id) {
          this.showUccxIcon = lineButtonSummary.isUccxAgent;
          this.changeDetectorRef.detectChanges();
        }
      }
    });
    this.showUccxIcon = this.isUccxAgent(this.button);

    this.phoneButtonsService.updateTpRef.subscribe((tpRef) => {
      if (this.button.type === PhoneButtonTypes.line && this.dnSummary) {
        const lineDn = (this.button as LineButton).dn;
        if (!!lineDn && lineDn.extension === tpRef.destination) {
          this.dnSummary.didTranslationPattern = tpRef;
        }
      }
    });
  }

  isButtonLabelLong(button: PhoneButton): boolean {
    return this.getButtonTypeLabel(button).length > 2;
  }

  isEmpty(button: PhoneButton): boolean {
    const phoneButtonSummary = this.phoneButtonsService.mapButtonToButtonSummary(button);
    return !phoneButtonSummary.value && !phoneButtonSummary.label;
  }

  getNonLineButtonValue(button: PhoneButton) {
    if (this.phoneButtonsService.isSpeedDialButton(button)) {
      return button.value;
    } else if (this.phoneButtonsService.isBlfButton(button)) {
      return button.destination;
    } else if (this.phoneButtonsService.isServiceUrlButton(button)) {
      return button.subscriptionName;
    } else if (this.phoneButtonsService.isIntercomButton(button)) {
      return button.intercom?.extension;
    }
  }

  getButtonLabel(button: PhoneButton) {
    return get(button, 'label') || get(button, 'lineFeature.label');
  }

  getButtonValue(button: PhoneButton) {
    return get(button, 'value');
  }

  getButtonDestination(button: PhoneButton) {
    return get(button, 'destination');
  }

  getButtonExtension(button: PhoneButton) {
    return this.telephoneNumberFilter.transform(get(button, 'dn.extension'));
  }

  getButtonType(button: PhoneButton): string {
    return get(button, 'type');
  }

  getButtonTypeLabel(button: PhoneButton): string {
    return button.type
      .split(' ')
      .map((item) => item.toUpperCase().substring(0, 1))
      .join('');
  }

  openDeleteConfirmation() {
    if (!this.clearTooltipMessage) {
      this.buttonState.deleteToggled = true;
    }
  }

  closeDeleteConfirmation() {
    this.buttonState.deleteToggled = false;
  }

  deleteButton() {
    this.button = this.phoneButtonsService.newButtonOfType(this.button.type);
    this.deletedPhoneButton.emit({ index: this.buttonIdx, button: this.button });
    this.showVoicemailIcon = false;
    this.showUccxIcon = false;
  }

  isSelfServeLine(button: PhoneButton): boolean {
    return this.phoneButtonsService.isLineButton(button) && this.userMode === DragDropMode.SELF_SERVE;
  }

  isHelpdeskMode(): boolean {
    return this.userMode === DragDropMode.HELPDESK;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.dnSummary?.currentValue?.voicemail === null) {
      this.showVoicemailIcon = false;
    }
  }

  isVoicemailExists(button: PhoneButton): boolean {
    if (this.phoneButtonsService.isLineButton(button) && this.isHelpdeskMode()) {
      return !!button.dn && !!this.dnSummary?.voicemail;
    }
    return false;
  }

  translationPattern(button: PhoneButton): string {
    if (this.phoneButtonsService.isLineButton(button) && button.dn && this.isHelpdeskMode() && this.dnSummary) {
      return this.telephoneNumberFilter.transform(this.dnSummary.didTranslationPattern?.pattern);
    }
  }

  displayButtonLabel(button: PhoneButton): string {
    if (Object.values(PhoneButtonTypes).some((val) => val === button.type)) {
      return !this.isEmpty(button) ? this.getButtonLabel(button) : this.getButtonType(button);
    }
    return this.getButtonType(button);
  }

  isPrimaryExtension(button: PhoneButton): boolean {
    if (this.phoneButtonsService.isLineButton(button) && this.isHelpdeskMode()) {
      return !!button.dn && !!this.dnSummary?.isPrimaryExtension;
    }
    return false;
  }

  isUccxAgent(button: PhoneButton): boolean {
    if (this.phoneButtonsService.isLineButton(button) && this.isHelpdeskMode()) {
      return !!button.dn && !!this.dnSummary?.isUccxAgent;
    }
    return false;
  }
}
