import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-animated-image',
  templateUrl: './animated-image.component.html',
  styleUrls: ['./animated-image.component.scss'],
})
export class AnimatedImageComponent implements OnInit {
  @Input() lottiParams: AnimationOptions;
  @Input() animationStart$ = new Observable<void>();
  private _anim: any;

  ngOnInit(): void {
    this.animationStart$.subscribe(() => {
      this._anim.play();
    });
  }

  handleAnimation(anim: any) {
    this._anim = anim;
  }
}
