<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.microsoft.security_group_management.about' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div *ngIf="!isLoading">
      <ziro-security-group-management-form
        [securityOptions]="securityOptions"
        [securityGroups]="securityGroups"
        [entity]="formEntity"
      ></ziro-security-group-management-form>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
