<div class="admin-site-based-admins smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <span [innerHTML]="'tkey;admin.site_based_admins.description' | translate"></span>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <fieldset *ngIf="table" [disabled]="isSaving" data-automation="sbh-user-permissions-entity-table">
      <app-entity-table
        [table]="table"
        [tableRows]="tableRows"
        (fieldChange)="onFieldChange($event)"
        (filterChange)="onFilterChange($event)"
        (filterValuesUpdate)="onRowsUpdate($event)"
      ></app-entity-table>
    </fieldset>
  </div>
</div>

<app-bottom-nav [hidden]="!isUpdateDisplayed" (buttonClicked)="onUpdatePermissionsClicked()"></app-bottom-nav>
