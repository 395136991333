<div>
  <legend>{{ 'tkey;dialplanmanagement.admin.group.didranges.navlink' | translate }}</legend>

  <smacs-select-config fieldId="route_partition"></smacs-select-config>
  <div class="row">
    <div class="col-6">
      <label
        [ngClass]="{
          'smacs-forms-error': extensionRangesDisplayFormValidationState === validationStates.INVALID,
          'smacs-forms-warning': extensionRangesDisplayFormValidationState === validationStates.WARNING
        }"
        data-automation="extension-ranges-label"
        class="smacs-forms-label col-form-label"
      >
        <span>
          {{ 'tkey;dialplanmanagement.admin.group.didranges' | translate }}
        </span>
      </label>
      <ziro-extension-ranges-display-form
        data-automation="extension-ranges-display-form"
        *ngIf="displayRangeForm"
        [entity]="displayRangeFormEntity"
        [groupId]="groupId"
        [clusterId]="clusterId"
        [dialPlanGroups]="dialPlanGroups"
        [siteSummary]="siteSummary"
        [optionalValidators]="displayRangeFormOptionalValidators"
        [isRequired]="false"
        (smacsFormsUpdate$)="onRangeFormUpdate($event)"
      ></ziro-extension-ranges-display-form>
    </div>
    <div class="col-6">
      <smacs-textarea fieldId="bulkRanges"></smacs-textarea>
      <smacs-button
        [dataAutomation]="'did-ranges-bulk-ranges-button'"
        [label]="'tkey;dialplanmanagement.admin.group.generate.button'"
        (clicked)="generateBulkRangesClicked()"
      ></smacs-button>
    </div>
  </div>
</div>
