<app-loading-block [hidden]="!isLoading"></app-loading-block>
<app-breadcrumbs></app-breadcrumbs>
<div *ngIf="!isLoading">
  <div class="card bg-light mb-3">
    <div class="card-body">
      <p [innerHTML]="'tkey;admin.site_management.bulk_change.instructions' | translate"></p>
    </div>
  </div>
  <div class="pb-2">
    <app-site-bulk-field-copy-form
      [clusterId]="clusterId"
      [sites]="sites"
      [currentClusterName]="currentClusterName"
    ></app-site-bulk-field-copy-form>
  </div>
</div>
