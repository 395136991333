import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EndUserRef,
  ExtensionMobilityRef,
  Global360View,
  PhoneRef,
} from '../../../../shared/models/generated/smacsModels';
import { UserDetailUiContext } from '../../../shared/contexts/user-detail-ui.context';
import { first, skip } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { PhoneUiContext } from '../../../../shared/phone-buttons/contexts/phone-ui.context';
import { Global360ViewContext } from '../../../../shared/contexts/global-360-view.context';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { BottomNavClearButtonsList, BottomNavService } from '../../../../shared/bottom-nav/bottom-nav.service';
import { combineLatest, Subscription } from 'rxjs';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ToastService } from '../../../../shared/services/toast.service';
import { PhoneType } from '../../../../shared/models/service-type';
import { DeviceService } from '../../../../shared/phone-buttons/device.service';

@Component({
  selector: 'smacs-user-detail-phone',
  templateUrl: './user-detail-phone.component.html',
  providers: [PhoneUiContext, DeviceService],
})
export class UserDetailPhoneComponent implements OnInit, OnDestroy {
  isLoading = true;
  endUserRef: EndUserRef;
  global360View: Global360View;
  isCopy: boolean;
  phoneType: PhoneType;
  PhoneType = PhoneType;

  private _subscription = new Subscription();

  constructor(
    private userDetailUiContext: UserDetailUiContext,
    private global360ViewContext: Global360ViewContext,
    private route: ActivatedRoute,
    private router: Router,
    private smacsFormStateService: SmacsFormStateService,
    private bottomNavService: BottomNavService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.isCopy = this.router.routerState.snapshot.url.includes('copy');
    this.phoneType = DeviceService.getPhoneTypeFromRoute(this.route.snapshot);
    const userDetailSubs = combineLatest([this.global360ViewContext.state$, this.userDetailUiContext.state$]).subscribe(
      ([global360View]) => {
        this.global360View = global360View;
        this.endUserRef = this.userDetailUiContext.getCurrentEnduser().ref;
        this.isLoading = false;
      }
    );
    this._subscription.add(userDetailSubs);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  onSave(ref: PhoneRef | ExtensionMobilityRef) {
    this.global360ViewContext.init(this.endUserRef.username);
    this.global360ViewContext.state$.pipe(skip(1), first()).subscribe(() => {
      this.toastService.pushSaveToast(this.phoneType, ref?.name, this._getSmacsIconsFromPhoneType(this.phoneType));
      this.goHome();
    });
  }

  goHome() {
    if (!this.smacsFormStateService.getIsFormDirty()) {
      this.bottomNavService.dispatch(new BottomNavClearButtonsList());
    }
    const isNew = !this.route.snapshot.params.phoneId;
    this.router.navigate(this.isCopy ? ['../../../'] : isNew ? ['../'] : ['../..'], { relativeTo: this.route });
  }

  private _getSmacsIconsFromPhoneType(phoneType: PhoneType): SmacsIcons {
    switch (phoneType) {
      case PhoneType.DESKPHONE: {
        return SmacsIcons.DESKPHONE;
      }
      case PhoneType.EXTENSION_MOBILITY: {
        return SmacsIcons.EXTENSION_MOBILITY;
      }
      case PhoneType.ANDROID: {
        return SmacsIcons.ANDROID;
      }
      case PhoneType.IPHONE: {
        return SmacsIcons.IPHONE;
      }
      case PhoneType.TABLET: {
        return SmacsIcons.TABLET;
      }
      case PhoneType.IM_SOFTPHONE: {
        return SmacsIcons.SOFTPHONE;
      }
      case PhoneType.CIPC: {
        return SmacsIcons.CIPC;
      }
      default: {
        return SmacsIcons.DESKPHONE;
      }
    }
  }
}
