import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles } from '../../../../../button/button.component';
import { SmacsIcons } from '../../../../../shared/models/smacs-icons.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import {
  AdWriteDownAttribute,
  BandwidthCachedOptions,
  CallingType,
  CustomInputText,
  CustomSelect,
  DnDidRange,
  EmergencyLocation,
  EnabledType,
  HealthStatus,
  MicrosoftCachedOptions,
  MicrosoftDialPlanGroup,
  MicrosoftDialPlanRange,
  PstnConnectivityType,
  Role,
  SharedCallingRoutingPolicy,
  State,
  StatusCategory,
} from '../../../../../shared/models/generated/smacsModels';
import {
  SmacsFormConfig,
  SmacsFormsAutogenerationConfig,
  SmacsFormsUpdate,
  SmacsFormsValidationConfig,
  SmacsFormsValidationState,
} from '../../../../../forms/smacs-forms-models';
import { HtmlInputType, SmacsTextConfig } from '../../../../../forms/fields/text/smacs-text.component';
import {
  ExtensionRangesOptionalValidators,
  SmacsRangeGroup,
} from '../../../../../forms/fields/extension-ranges/smacs-extension-range-models';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../../shared/services/toast.service';
import { SmacsModalService } from '../../../../../shared/services/smacs-modal.service';
import { BottomNavButton } from '../../../../../shared/bottom-nav/bottom-nav.component';
import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  ReplaySubject,
  Subscription,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { MsDialPlanGroupsContext } from '../../../../contexts/ms-dial-plan-groups.context';
import { legacySmacsTextConfigComponent } from '../../../../../shared/custom-configs/legacy-smacs-text-config/legacy-smacs-text-config.component';
import { RangeService } from '../../../../../shared/services/range.service';
import { cloneDeep, forOwn, isEqual, sortBy } from 'lodash';
import {
  ExtensionRangesDisplayFormComponent,
  RangeDisplayFormModel,
} from '../../../../../forms/fields/extension-ranges/extension-ranges-display-form/extension-ranges-display-form.component';
import { CollapsibleCardSwitchValue } from '../../../../../shared/collapsible-card/collapsible-card.component';
import { SmacsTextareaConfig } from '../../../../../forms/fields/textarea/smacs-textarea.component';
import { ToastTypes } from '../../../../../shared/services/abstract/toast.service.abstract';
import { SmacsRadioConfig } from '../../../../../forms/fields/radio/smacs-radio.component';
import { e164BulkNoPlusRegex, e164BulkWithPlusRegex, e164ValidatorRegex } from '../../../../../shared/validators-regex';
import { AuthenticationContext } from '../../../../../shared/contexts/authentication.context';
import { ValidatorsService } from '../../../../../shared/validators.service';
import { SystemStatusContext } from '../../../../../shared/contexts/system-status.context';
import { AdAttributesComponent } from './ad-attributes-form/ad-attributes.component';
import {
  SmacsSelectComponent,
  SmacsSelectConfig,
  SmacsSelectOption,
} from '../../../../../forms/fields/select/smacs-select.component';
import { ZiroCustomSelectConfig } from '../../../../../shared/custom-configs/custom-select/ziro-custom-select.component';
import { ZiroCustomInputTextConfig } from '../../../../../shared/custom-configs/custom-input-text/ziro-custom-input-text.component';
import { VariableEditorType } from '../../../../../modals/edit-custom-input-text-modal/variable-editor.service';
import { MsTeamsCachedOptionsResource } from '../../../../resources/ms-teams-cached-options.resource';

export interface MsDialplanGroupFormData {
  id: string;
  groupName: string;
  pstnConnectivityType: PstnConnectivityType;
  carrierName: string;
  callingType: CallingType;
  mainNumber: string;
  numberRanges: MicrosoftDialPlanRange[];
  bulkRanges: string;
  lineUri: CustomInputText;
  calling_policy: CustomSelect;
  teams_call_hold_policy: CustomSelect;
  teams_call_park_policy: CustomSelect;
  calling_line_identity: CustomSelect;
  tenant_dial_plan: CustomSelect;
  emergency_calling_policy: CustomSelect;
  emergency_call_routing_policy: CustomSelect;
  online_voice_routing_policy: CustomSelect;
  online_voicemail_policy: CustomSelect;
  ip_phone_policy: CustomSelect;
  emergency_location: CustomSelect;
  e911Geolocation: CustomSelect;
  billingLocation: string;
  adWriteDownList: AdWriteDownAttribute[];
  enforcedNumbersUsageLocation: string;
  sharedCallingRoutingPolicy: string;
}

@Component({
  selector: 'microsoft-dial-plan-group-management-form',
  templateUrl: './ms-dial-plan-group-management-form.component.html',
})
export class MsDialPlanGroupManagementFormComponent
  extends SmacsFormAbstractDirective<MicrosoftDialPlanGroup, MsDialplanGroupFormData>
  implements OnInit, OnDestroy
{
  @Input() allMsDialplanGroups: MicrosoftDialPlanGroup[];
  @Input() msCachedOptions: MicrosoftCachedOptions;
  @Input() bandwidthCachedOptions: BandwidthCachedOptions;
  @Input() usageLocations: string[];
  @Input() isUserS8Support: boolean;
  @Input() isBandwidthConfigured: boolean;
  @Input() isGccHigh: boolean;
  @Input() onPremAdWriteDownEnabled: boolean;

  @ViewChild(legacySmacsTextConfigComponent) textConfigChildComponent: legacySmacsTextConfigComponent;
  @ViewChild(ExtensionRangesDisplayFormComponent) extensionRangesDisplayForm: ExtensionRangesDisplayFormComponent;
  @ViewChild(AdAttributesComponent) adAttributesComponent: AdAttributesComponent;
  @ViewChild('sharedCallingRoutingPolicy') sharedCallingRoutingPolicy: SmacsSelectComponent;

  formConfig: SmacsFormConfig;
  fieldIds = [
    'calling_policy',
    'teams_call_hold_policy',
    'teams_call_park_policy',
    'calling_line_identity',
    'tenant_dial_plan',
    'emergency_calling_policy',
    'emergency_call_routing_policy',
    'online_voice_routing_policy',
    'online_voicemail_policy',
    'ip_phone_policy',
    'emergency_location',
  ];
  displayRangeFormEntity: RangeDisplayFormModel;
  displayRangeForm = false;
  extensionRangesDisplayFormValidationState: SmacsFormsValidationState;
  displayRangeFormOptionalValidators: ExtensionRangesOptionalValidators;
  validationStates = SmacsFormsValidationState;
  isBandwidthGroup: boolean;
  isDisabledForAdmin = false;
  isZpcSyncPresent = false;
  isZpcSyncInWarningState = false;
  isAdAttributesToggleChecked = false;
  isSyncStatusEstablished = false;
  initialAdAttributesEntity: AdWriteDownAttribute[] = [];
  SmacsIcons = SmacsIcons;
  firstNumberInRange = '';

  private _subs = new Subscription();
  private _allMsDialplanGroupNames: string[];
  private _isNewGroup: boolean;
  private _mainNumberOverlappingGroupName: string;
  private didRangeOverlap: MicrosoftDialPlanGroup;
  private isNonZiroCallingProvider: boolean;
  private _pstnTypesSupportingEnforcedUsageLocation = [
    PstnConnectivityType.DIRECT_ROUTING,
    PstnConnectivityType.ZIRO_DRAAS,
    PstnConnectivityType.ZIRO_DRAAS_BYOC,
  ];
  private _sharedCallingPolicySource = new ReplaySubject<SmacsSelectOption[]>(1);
  private _isLoadingSharedCallingPolicies = false;
  private _initialEntity: MicrosoftDialPlanGroup;

  constructor(
    private _bottomNavService: BottomNavService,
    private _router: Router,
    private _route: ActivatedRoute,
    protected smacsFormStateService: SmacsFormStateService,
    private _translateService: TranslateService,
    private _msDialPlanContext: MsDialPlanGroupsContext,
    private _toastService: ToastService,
    private _smacsModalService: SmacsModalService,
    private _authenticationContext: AuthenticationContext,
    private _systemStatusContext: SystemStatusContext,
    private _msTeamsCachedOptionsResource: MsTeamsCachedOptionsResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._isNewGroup = this.entity.id == null;
    this._initialEntity = this.entity;
    this.isBandwidthGroup = this.entity.bandwidthEmergencyCallingSettings != null;
    this._allMsDialplanGroupNames = this.allMsDialplanGroups.map((dpg) => dpg.name.trim().toLowerCase());
    this.isDisabledForAdmin =
      this._authenticationContext.getCurrentUser().role !== Role.ZIRO_SUPPORT &&
      (this.entity?.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS ||
        this.entity?.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS_BYOC);
    this._sharedCallingPolicySource.next(
      this._mapSharedCallingPoliciesToSelectOptions(this.msCachedOptions.sharedCallingRoutingPolicies)
    );
    this._initBottomNav();
    this._initFormConfig();

    const systemStatusSub = this._systemStatusContext.state$.pipe().subscribe((systemStatus) => {
      const syncStatus: HealthStatus = systemStatus.healthStatuses.find(
        (status) => status.category === StatusCategory.PROXY_SERVER
      );
      this.isZpcSyncPresent = !!syncStatus;
      this.isZpcSyncInWarningState = !!syncStatus && syncStatus.state === State.WARNING;
      if (this.entity.adWriteDownList?.length > 0) {
        this.isAdAttributesToggleChecked = true;
        this.initialAdAttributesEntity = this.entity.adWriteDownList;
      }
      this.isSyncStatusEstablished = true;
    });
    this._subs.add(systemStatusSub);

    const formUpdateSub = this.smacsFormsUpdate$.subscribe((changes: SmacsFormsUpdate<MicrosoftDialPlanGroup>) => {
      if (changes.new.dialPlanRangesJson.length && changes.new.dialPlanRangesJson[0].start) {
        this.firstNumberInRange = changes.new.dialPlanRangesJson[0].start;
      }
      this.displayRangeFormOptionalValidators = {
        ...this.displayRangeFormOptionalValidators,
        strictE164Validation: changes.new.callingType === CallingType.DID,
        isExtensionRangeType: changes.new.callingType === CallingType.EXTENSION,
      };
      if (
        (this.isUserS8Support &&
          changes.new.bandwidthEmergencyCallingSettings &&
          changes.new.callingType === CallingType.EXTENSION) ||
        (this.isUserS8Support &&
          changes.new.bandwidthEmergencyCallingSettings &&
          changes.new.pstnConnectivityType === PstnConnectivityType.OPERATOR_CONNECT)
      ) {
        this.onBandwidthRangeSelected({ isChecked: false, switchId: null });
      }

      this.isNonZiroCallingProvider =
        changes.new.pstnConnectivityType === PstnConnectivityType.OPERATOR_CONNECT ||
        changes.new.pstnConnectivityType === PstnConnectivityType.DIRECT_ROUTING ||
        changes.new.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS_BYOC ||
        changes.new.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS;

      if (this.isNonZiroCallingProvider) {
        this.fieldChannels['billingLocation'].entitySource.next('');
      } else if (changes.new.pstnConnectivityType !== changes.old.pstnConnectivityType) {
        this.fieldChannels['billingLocation'].entitySource.next(this._initialEntity.billingLocation);
      }

      if (
        changes.new.callingType !== changes.old.callingType &&
        changes.new.callingType === CallingType.SHARED_CALLING
      ) {
        this.fieldChannels['sharedCallingRoutingPolicy'].entitySource.next('');
        const blankSelectField: CustomSelect = {
          name: '',
          defaultOption: '',
          possibleOptions: [],
          required: false,
          show: false,
        };
        this.fieldChannels['emergency_location'].entitySource.next({ ...blankSelectField, name: 'emergency_location' });
        this.fieldChannels['online_voice_routing_policy'].entitySource.next({
          ...blankSelectField,
          name: 'online_voice_routing_policy',
          defaultOption: 'Global',
          possibleOptions: ['Global'],
          required: true,
        });
      }

      if (
        changes.old.callingType === CallingType.SHARED_CALLING &&
        changes.new.callingType !== CallingType.SHARED_CALLING &&
        !Object.keys(this.extensionRangesDisplayForm.extensionRanges.childFormComponent.formData).length
      ) {
        this.extensionRangesDisplayForm.extensionRanges.childFormComponent.addItem(true);
      }

      if (
        changes.new.pstnConnectivityType !== changes.old.pstnConnectivityType ||
        changes.new.callingType !== changes.old.callingType
      ) {
        if (changes.new.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS) {
          const newConfigAlwaysRequired = new ZiroCustomSelectConfig({
            availableOptions: sortBy(this.msCachedOptions?.emergencyLocations.map((opt) => opt.description)),
            isAlwaysRequired: changes.new.callingType !== CallingType.SHARED_CALLING,
            displayValues: this._getCustomSelectDisplayValues('emergency_location'),
            displayLabel: 'tkey;admin.msdialplan.management.field.emergency_location.label',
          });
          this.fieldChannels['emergency_location'].componentConfigSource.next(newConfigAlwaysRequired);
          const emergencyLocationFieldData = this.formData.emergency_location;
          this.fieldChannels['emergency_location'].entitySource.next({
            ...emergencyLocationFieldData,
            required: changes.new.callingType !== CallingType.SHARED_CALLING,
          });
        } else if (changes.old.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS) {
          const newConfig = new ZiroCustomSelectConfig({
            availableOptions: sortBy(this.msCachedOptions?.emergencyLocations.map((opt) => opt.description)),
            isAlwaysRequired: false,
            displayValues: this._getCustomSelectDisplayValues('emergency_location'),
            displayLabel: 'tkey;admin.msdialplan.management.field.emergency_location.label',
          });
          this.fieldChannels['emergency_location'].componentConfigSource.next(newConfig);
        }
        const emergencyLocationFieldData = this.formData.emergency_location;
        if (changes.new.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS) {
          if (!emergencyLocationFieldData.required) {
            this.fieldChannels['emergency_location'].entitySource.next({
              ...emergencyLocationFieldData,
              required: changes.new.callingType !== CallingType.SHARED_CALLING,
            });
          }
        } else if (changes.new.callingType === CallingType.SHARED_CALLING || this.isGccHigh) {
          if (emergencyLocationFieldData.required) {
            this.fieldChannels['emergency_location'].entitySource.next({
              ...emergencyLocationFieldData,
              required: false,
            });
          }
        }
      }
    });
    const bottomNavErrorStateSub = combineLatest([this._validateAndSubmitSource, this.smacsFormsUpdate$]).subscribe(
      () => {
        this._updateBottomNavErrorState();
      }
    );
    this._subs.add(formUpdateSub);
    this._subs.add(bottomNavErrorStateSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
      })
    );
    this._subs.unsubscribe();
  }

  generateBulkRangesClicked() {
    if (!this.formData['bulkRanges'].trim()) {
      return;
    }

    this.displayRangeForm = false;

    // Remove new lines, spaces
    const cleanVal = ValidatorsService.removeFormattingFromBulkRanges(this.formData['bulkRanges']);
    const generatedRanges = RangeService.generateRangesFromArray(
      cleanVal.replace(/\n|\r/g, '').replace(/ /g, '').split(',')
    );
    const currentRanges = cloneDeep(this.entity.dialPlanRangesJson).filter((r: DnDidRange) => r.start && r.end);
    const updatedRanges = [...currentRanges, ...generatedRanges];
    const newEntity = {
      ...this.entity,
      dialPlanRangesJson: updatedRanges,
    };

    this.formData['bulkRanges'] = '';
    this.entitySource.next(cloneDeep(newEntity));
    this.displayRangeFormEntity = { ranges: newEntity.dialPlanRangesJson };

    setTimeout(() => {
      this.displayRangeForm = true;
    });
  }

  setDisplayFormValidationState() {
    this.extensionRangesDisplayFormValidationState = this.extensionRangesDisplayForm.extensionRanges.showValidation
      ? this.extensionRangesDisplayForm.validationState
      : this.validationStates.VALID;
  }

  onRangeFormUpdate(data: SmacsFormsUpdate<RangeDisplayFormModel>) {
    // Always update validation state, don't always update entity. Async val will fire this multiple times so entity
    // won't necessarily change onFormUpdate
    if (isEqual(this.entity.dialPlanRangesJson, data.new.ranges)) {
      this.setDisplayFormValidationState();
    } else {
      const newEntity = {
        ...cloneDeep(this.entity),
        dialPlanRangesJson: data.new.ranges,
      };

      this.entitySource.next(newEntity);

      this.setDisplayFormValidationState();
    }
  }

  onBandwidthRangeSelected(event: CollapsibleCardSwitchValue) {
    this.isBandwidthGroup = event.isChecked;
    // nullify the bandwidth settings in the entity
    this.entitySource.next(this.toEntity(this.formData));
  }

  getBandwidthCallingSettingsTooltip(): string {
    if (this.entity.pstnConnectivityType === PstnConnectivityType.OPERATOR_CONNECT) {
      return 'tkey;admin.msdialplan.management.field.bandwidth_range.disabled_operator_connect.tooltip';
    } else if (this.entity.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS) {
      return 'tkey;admin.msdialplan.management.field.bandwidth_range.disabled_microsoft_calling_plan.tooltip';
    } else if (this.entity.callingType === CallingType.EXTENSION) {
      return 'tkey;admin.msdialplan.management.field.bandwidth_range.disabled_type_extension.tooltip';
    } else if (this.entity.callingType === CallingType.SHARED_CALLING) {
      return 'tkey;admin.msdialplan.management.field.bandwidth_range.disabled_type_shared_calling.tooltip';
    }

    return '';
  }

  onAdAttributesToggled(event: CollapsibleCardSwitchValue) {
    this.isAdAttributesToggleChecked = event.isChecked;
    if (!this.isAdAttributesToggleChecked) {
      this.entitySource.next({
        ...cloneDeep(this.entity),
        adWriteDownList: null,
      });
    } else {
      this.entitySource.next({
        ...cloneDeep(this.entity),
        adWriteDownList: this.adAttributesComponent.getAllChildValues(),
      });
    }
  }

  handleAttributeUpdate() {
    if (this.isFormValid() && this.adAttributesComponent.areAllItemsValid()) {
      this._bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: false,
        })
      );
    }
    this.entitySource.next({
      ...cloneDeep(this.entity),
      adWriteDownList: this.adAttributesComponent.getAllChildValues(),
    });
  }

  reloadSharedCallingPolicyOptions(): void {
    this._isLoadingSharedCallingPolicies = true;
    this._msTeamsCachedOptionsResource
      .refreshTeamsCachedOptions('sharedCallingRoutingPolicies')
      .pipe(
        switchMap(() => this._msTeamsCachedOptionsResource.get()),
        map((cachedOptions): SmacsSelectOption[] => {
          return this._mapSharedCallingPoliciesToSelectOptions(cachedOptions.sharedCallingRoutingPolicies);
        })
      )
      .subscribe((options) => {
        this._sharedCallingPolicySource.next(options);
        this.sharedCallingRoutingPolicy.optionInputSource.next('');
        this._isLoadingSharedCallingPolicies = false;
      });
  }

  private _updateBottomNavErrorState() {
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: !this.isFormValid() || !this._areChildFormsValid(),
      })
    );
  }

  private static _getNumericValue(value: string): number {
    // note that this automatically strips out any leading zeros, so "000123" return number 123.
    return Number(value.replace(/\D/g, ''));
  }

  private static _intersects(value: number, rangeStart: number, rangeEnd: number): boolean {
    return value >= rangeStart && value <= rangeEnd;
  }

  private static _isRangeConflict(
    sourceStart: number,
    sourceEnd: number,
    targetStart: number,
    targetEnd: number
  ): boolean {
    return (
      MsDialPlanGroupManagementFormComponent._intersects(sourceStart, targetStart, targetEnd) ||
      MsDialPlanGroupManagementFormComponent._intersects(sourceEnd, targetStart, targetEnd) ||
      MsDialPlanGroupManagementFormComponent._intersects(targetStart, sourceStart, sourceEnd) ||
      MsDialPlanGroupManagementFormComponent._intersects(targetEnd, sourceStart, sourceEnd)
    );
  }

  private static _skipRangeValidation(sourceRange: DnDidRange, targetRange: DnDidRange): boolean {
    if (
      !targetRange.start ||
      !targetRange.end ||
      MsDialPlanGroupManagementFormComponent._isOnlyOneRangePlusValue(sourceRange, targetRange) ||
      sourceRange.start.length !== targetRange.start.length
    ) {
      return true;
    }

    return false;
  }

  private static _isOnlyOneRangePlusValue(sourceRange: DnDidRange, targetRange: DnDidRange): boolean {
    const sourceIsPlusValue = sourceRange.start.startsWith('+') || sourceRange.end.startsWith('+');
    const targetIsPlusValue = targetRange.start.startsWith('+') || targetRange.end.startsWith('+');
    return sourceIsPlusValue !== targetIsPlusValue;
  }

  private _postLoadOptions(attribute: keyof MicrosoftCachedOptions): Observable<string[]> {
    return this._msTeamsCachedOptionsResource.refreshTeamsCachedOptions(attribute).pipe(
      switchMap(() => {
        return this._msTeamsCachedOptionsResource.get().pipe(
          map((data: MicrosoftCachedOptions) => {
            let options: string[] = [];

            forOwn(data, (value, key) => {
              if (key === attribute) {
                switch (key) {
                  case 'emergencyLocations': {
                    const typedVal = value as EmergencyLocation[];
                    options = typedVal.map((value) => value.description);
                    break;
                  }
                  case 'sharedCallingRoutingPolicies':
                  case 'audioConferencingDefaultServiceNumber': {
                    break;
                  }
                  default: {
                    options = value as string[];
                    break;
                  }
                }
                return false;
              }
            });

            return options;
          })
        );
      })
    );
  }

  private _getCachedSharedCallingPolicyOptions(searchTerm: string): Observable<SmacsSelectOption[]> {
    return this._sharedCallingPolicySource
      .asObservable()
      .pipe(map((options) => options.filter((opt) => opt.label?.includes(searchTerm))));
  }

  private _mapSharedCallingPoliciesToSelectOptions(
    sharedCallingRoutingPolicies: SharedCallingRoutingPolicy[]
  ): SmacsSelectOption[] {
    return sharedCallingRoutingPolicies
      .filter((policy) => policy.policyName !== 'Global')
      .map((policy) => {
        let label = `<strong>${policy.policyName}</strong>`;
        if (policy.resourceAccountLineUri) {
          label = label.concat(` | ${policy.resourceAccountLineUri}`);
        }
        if (policy.resourceAccountDisplayName) {
          label = label.concat(` | ${policy.resourceAccountDisplayName}`);
        }
        return {
          label,
          value: policy.policyName,
        };
      });
  }

  private _initFormConfig() {
    const hasZiroDrass = this.allMsDialplanGroups.filter((dpg: MicrosoftDialPlanGroup) => {
      return dpg.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS;
    });
    const hasZiroDrassByoc = this.allMsDialplanGroups.filter((dpg: MicrosoftDialPlanGroup) => {
      return dpg.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS_BYOC;
    });

    this.formConfig = {
      fields: {
        groupName: {
          label: 'tkey;dialplanmanagement.admin.group.name',
          dataAutomation: 'ms-dialpan-group-name-input',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: true,
          validation: [
            {
              validator: (val: string) => {
                if (this._isNewGroup) {
                  return this._allMsDialplanGroupNames.includes(val.trim().toLowerCase())
                    ? SmacsFormsValidationState.INVALID
                    : SmacsFormsValidationState.VALID;
                } else {
                  const groupMatch = this.allMsDialplanGroups.find(
                    (group) => group.name.trim().toLowerCase() === val.trim().toLowerCase()
                  );
                  return !groupMatch ||
                    groupMatch.id === this.entity.id ||
                    !this._allMsDialplanGroupNames.includes(val.trim().toLowerCase())
                    ? SmacsFormsValidationState.VALID
                    : SmacsFormsValidationState.INVALID;
                }
              },
              message: 'tkey;dialplanmanagement.admin.group.name.alreadyInUse',
            },
          ],
        },
        pstnConnectivityType: {
          label: 'tkey;admin.msdialplan.management.pstn_connectivity_type.label',
          dataAutomation: 'pstn-connectivity-type-input',
          required: true,
          disabled: () => this.isDisabledForAdmin,
          disabledTooltip: 'tkey;admin.msdialplan.management.calling_type.tooltip',
          componentConfig: new SmacsRadioConfig({
            buttons: [
              {
                value: PstnConnectivityType.ZIRO_DRAAS,
                label: 'tkey;admin.msdialplan.management.pstn_connectivity_type.ziro_draas.label',
                helpText: 'tkey;admin.msdialplan.management.pstn_type.ziro_draas.helptext',
                hidden: !hasZiroDrass.length && this._authenticationContext.getCurrentUser().role !== Role.ZIRO_SUPPORT,
                disabled: () => this._authenticationContext.getCurrentUser().role !== Role.ZIRO_SUPPORT,
              },
              {
                value: PstnConnectivityType.ZIRO_DRAAS_BYOC,
                label: 'tkey;admin.msdialplan.management.pstn_connectivity_type.ziro_draas_byoc.label',
                helpText: 'tkey;admin.msdialplan.management.pstn_type.ziro_draas_byoc.helptext',
                hidden:
                  !hasZiroDrassByoc.length && this._authenticationContext.getCurrentUser().role !== Role.ZIRO_SUPPORT,
                disabled: () => this._authenticationContext.getCurrentUser().role !== Role.ZIRO_SUPPORT,
              },
              {
                value: PstnConnectivityType.DIRECT_ROUTING,
                label: 'tkey;admin.msdialplan.management.pstn_connectivity_type.direct_routing.label',
                helpText: 'tkey;admin.msdialplan.management.pstn_type.direct_routing.helptext',
              },
              {
                value: PstnConnectivityType.OPERATOR_CONNECT,
                label: 'tkey;admin.msdialplan.management.pstn_connectivity_type.operator_connect.label',
                helpText: 'tkey;admin.msdialplan.management.pstn_type.operator_connect.helptext',
              },
              {
                value: PstnConnectivityType.MICROSOFT_CALLING_PLANS,
                label: 'tkey;admin.msdialplan.management.pstn_connectivity_type.ms_calling_plans.label',
                helpText: 'tkey;admin.msdialplan.management.pstn_connectivity_type.ms_calling_plans.helptext',
              },
            ],
            inline: false,
            isDisplayVertical: true,
          }),
        },
        carrierName: {
          label: 'tkey;admin.msdialplan.management.carrier_name.label',
          dataAutomation: 'carrier-name-input',
          required: false,
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          hidden: () => {
            return (
              this.formData.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS ||
              this.formData.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS
            );
          },
          helpText: 'tkey;admin.msdialplan.management.carrier_name.helptext',
        },
        callingType: {
          label: 'tkey;admin.msdialplan.management.calling_type.label',
          dataAutomation: 'ms-dialplan-range-type-input',
          required: true,
          componentConfig: new SmacsRadioConfig({
            buttons: [
              {
                value: CallingType.DID,
                label: 'tkey;admin.msdialplan.management.calling_type.option_1.label',
                helpText: 'tkey;admin.msdialplan.management.calling_type.option_1.helptext',
                disabled: () => {
                  return this.isDisabledForAdmin;
                },
                disabledTooltip: () => {
                  return 'tkey;admin.msdialplan.management.group.disabled.tooltip';
                },
              },
              {
                value: CallingType.EXTENSION,
                label: 'tkey;admin.msdialplan.management.calling_type.option_2.label',
                helpText: 'tkey;admin.msdialplan.management.calling_type.option_2.helptext',
                disabled: () => {
                  return (
                    this.isDisabledForAdmin ||
                    this.formData.pstnConnectivityType === PstnConnectivityType.OPERATOR_CONNECT ||
                    this.formData.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS
                  );
                },
                disabledTooltip: () => {
                  return this.isDisabledForAdmin
                    ? 'tkey;admin.msdialplan.management.group.disabled.tooltip'
                    : this.formData.pstnConnectivityType === PstnConnectivityType.OPERATOR_CONNECT
                    ? 'tkey;admin.msdialplan.management.calling_type.option_2.label.tooltip'
                    : 'tkey;admin.msdialplan.management.calling_type.option_2.label.tooltip_2';
                },
              },
              {
                value: CallingType.SHARED_CALLING,
                label: 'tkey;admin.msdialplan.management.calling_type.option_3.label',
                helpText: 'tkey;admin.msdialplan.management.calling_type.option_3.helptext',
                disabled: () => this.isDisabledForAdmin,
                disabledTooltip: () => {
                  return 'tkey;admin.msdialplan.management.group.disabled.tooltip';
                },
              },
            ],
            inline: false,
            isDisplayVertical: true,
          }),
        },
        sharedCallingRoutingPolicy: {
          label: 'tkey;admin.msdialplan.management.shared_calling_routing_policy.label',
          required: true,
          dataAutomation: 'ms-dialplan-shared-calling-routing-policy-select',
          componentConfig: new SmacsSelectConfig({
            options: [
              ...this._mapSharedCallingPoliciesToSelectOptions(this.msCachedOptions.sharedCallingRoutingPolicies),
            ],
            asyncOptionsFn: (searchTerm) => this._getCachedSharedCallingPolicyOptions(searchTerm),
            bindValue: 'value',
            minSearchLength: 0,
            triggerLoadingIcon: () => this._isLoadingSharedCallingPolicies,
          }),
          hidden: () => this.formData.callingType !== CallingType.SHARED_CALLING,
          valExcluded: () => this.formData.callingType !== CallingType.SHARED_CALLING,
        },
        enforcedNumbersUsageLocation: {
          label: 'tkey;admin.msdialplan.management.enforce_number_usage_location.label',
          helpText: 'tkey;admin.msdialplan.management.enforce_number_usage_location.helptext',
          dataAutomation: 'ms-dialplan-enforce-number-usage-location-select',
          componentConfig: new SmacsSelectConfig({ options: this.usageLocations }),
          required: false,
          hidden: () => !this._pstnTypesSupportingEnforcedUsageLocation.includes(this.formData.pstnConnectivityType),
        },
        mainNumber: {
          label: 'tkey;admin.msdialplan.management.main_number.label',
          dataAutomation: 'ms-dialpan-main-number-input',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: () => this.formData.callingType === CallingType.EXTENSION,
          hidden: () => this.formData.callingType !== CallingType.EXTENSION,
          validation: [
            {
              validator: (val: string) => {
                if (this.formData.callingType !== CallingType.EXTENSION) {
                  return SmacsFormsValidationState.VALID;
                }

                const formattedVal = ValidatorsService.removeFormattingFromSingleNumber(val);

                return e164ValidatorRegex.test(formattedVal)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: 'tkey;validators.global.e164.invalid.error',
            },
            {
              validator: (val: string) => {
                if (this.formData.callingType !== CallingType.EXTENSION) {
                  return SmacsFormsValidationState.VALID;
                }

                const isExtensionMatch = this.allMsDialplanGroups.find((dpg: MicrosoftDialPlanGroup) => {
                  if (
                    dpg.callingType === CallingType.EXTENSION &&
                    dpg.id !== this.entity.id &&
                    !MsDialPlanGroupManagementFormComponent._skipRangeValidation(
                      { start: val, end: val },
                      { start: dpg.mainNumber, end: dpg.mainNumber }
                    ) &&
                    MsDialPlanGroupManagementFormComponent._isRangeConflict(
                      MsDialPlanGroupManagementFormComponent._getNumericValue(val),
                      MsDialPlanGroupManagementFormComponent._getNumericValue(val),
                      MsDialPlanGroupManagementFormComponent._getNumericValue(dpg.mainNumber),
                      MsDialPlanGroupManagementFormComponent._getNumericValue(dpg.mainNumber)
                    )
                  ) {
                    return dpg;
                  }
                });

                if (isExtensionMatch) {
                  this._mainNumberOverlappingGroupName = isExtensionMatch.name;
                  return SmacsFormsValidationState.INVALID;
                }

                const isDidMatch = this.allMsDialplanGroups.find((dpg: MicrosoftDialPlanGroup) => {
                  if (dpg.callingType === CallingType.DID) {
                    const overlap = dpg.dialPlanRangesJson.find((range: MicrosoftDialPlanRange) => {
                      if (
                        !MsDialPlanGroupManagementFormComponent._skipRangeValidation({ start: val, end: val }, range) &&
                        MsDialPlanGroupManagementFormComponent._isRangeConflict(
                          MsDialPlanGroupManagementFormComponent._getNumericValue(val),
                          MsDialPlanGroupManagementFormComponent._getNumericValue(val),
                          MsDialPlanGroupManagementFormComponent._getNumericValue(range.start),
                          MsDialPlanGroupManagementFormComponent._getNumericValue(range.end)
                        )
                      ) {
                        return range;
                      }
                    });

                    if (overlap) {
                      return dpg;
                    }
                  }
                });

                if (isDidMatch) {
                  this._mainNumberOverlappingGroupName = null;
                  this.didRangeOverlap = isDidMatch;
                  return SmacsFormsValidationState.INVALID;
                }

                return SmacsFormsValidationState.VALID;
              },
              message: () => {
                if (this._mainNumberOverlappingGroupName) {
                  return {
                    content: 'tkey;dialplanmanagement.admin.group.mainnumber.overlapping.error_message',
                    params: {
                      overlappingGroupName: this._mainNumberOverlappingGroupName,
                    },
                  };
                } else {
                  return (
                    this._translateService.instant('tkey;dialplanmanagement.admin.group.conflict') +
                    `<br><strong>${this.didRangeOverlap.name}</strong>`
                  );
                }
              },
            },
          ],
          disabled: () => this.isDisabledForAdmin,
          disabledTooltip: 'tkey;admin.msdialplan.management.group.disabled.tooltip',
        },
        bulkRanges: {
          label: 'tkey;admin.msdialplan.management.field.bulk_number_ranges.label',
          dataAutomation: 'ms-dialpan-group-number-ranges-bulk-input',
          componentConfig: new SmacsTextareaConfig({
            placeholder: this._translateService.instant('tkey;admin.msdialplan.management.bulk_generate.placeholder'),
          }),
          helpText: () => 'tkey;admin.msdialplan.management.bulk_generate.helptext',
          validation: [
            {
              validator: (val: string, callingType: string) => {
                if (!val) {
                  return SmacsFormsValidationState.VALID;
                }
                const bulkFieldRegex = callingType === CallingType.DID ? e164BulkWithPlusRegex : e164BulkNoPlusRegex;
                const cleanVal = ValidatorsService.removeFormattingFromBulkRanges(val);

                return bulkFieldRegex.test(cleanVal)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: () =>
                this.formData.callingType === CallingType.DID
                  ? 'tkey;validators.global.e164.invalid.error'
                  : 'tkey;validators.global.extension.invalid.error',
              injectValuesFromFields: ['callingType'],
            },
          ],
          disabled: () => this.isDisabledForAdmin,
          disabledTooltip: 'tkey;admin.msdialplan.management.group.disabled.tooltip',
        },
        calling_policy: {
          label: 'tkey;admin.msdialplan.management.field.calling_policy.label',
          dataAutomation: 'ms-dialplan-group-calling-policy',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this._getCustomSelectAvailableOptions('calling_policy'),
            isAlwaysRequired: true,
            displayValues: this._getCustomSelectDisplayValues('calling_policy'),
            displayLabel: 'tkey;admin.msdialplan.management.field.calling_policy.label',
            postLoadedOptions: this._postLoadOptions.bind(this, 'callingPolicies'),
          }),
          validation: this._getCustomSelectValidation('calling_policy'),
          autogeneration: this._getCustomSelectAutogeneration('calling_policy'),
        },
        teams_call_hold_policy: {
          label: 'tkey;admin.msdialplan.management.field.teams_call_hold_policy.label',
          dataAutomation: 'ms-dialplan-group-teams-call-hold-policy',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this._getCustomSelectAvailableOptions('teams_call_hold_policy'),
            isAlwaysRequired: true,
            displayValues: this._getCustomSelectDisplayValues('teams_call_hold_policy'),
            displayLabel: 'tkey;admin.msdialplan.management.field.teams_call_hold_policy.label',
            postLoadedOptions: this._postLoadOptions.bind(this, 'teamsCallHoldPolicies'),
          }),
          validation: this._getCustomSelectValidation('teams_call_hold_policy'),
          autogeneration: this._getCustomSelectAutogeneration('teams_call_hold_policy'),
        },
        teams_call_park_policy: {
          label: 'tkey;admin.msdialplan.management.field.teams_call_park_policy.label',
          dataAutomation: 'ms-dialplan-group-teams-call-park-policy',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this._getCustomSelectAvailableOptions('teams_call_park_policy'),
            isAlwaysRequired: true,
            displayValues: this._getCustomSelectDisplayValues('teams_call_park_policy'),
            displayLabel: 'tkey;admin.msdialplan.management.field.teams_call_park_policy.label',
            postLoadedOptions: this._postLoadOptions.bind(this, 'teamsCallParkPolicies'),
          }),
          validation: this._getCustomSelectValidation('teams_call_park_policy'),
          autogeneration: this._getCustomSelectAutogeneration('teams_call_park_policy'),
        },
        calling_line_identity: {
          label: 'tkey;admin.msdialplan.management.field.calling_line_identity.label',
          dataAutomation: 'ms-dialplan-group-calling-line-identity',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this._getCustomSelectAvailableOptions('calling_line_identity'),
            isAlwaysRequired: true,
            displayValues: this._getCustomSelectDisplayValues('calling_line_identity'),
            displayLabel: 'tkey;admin.msdialplan.management.field.calling_line_identity.label',
            postLoadedOptions: this._postLoadOptions.bind(this, 'callingLineIdentities'),
          }),
          validation: this._getCustomSelectValidation('calling_line_identity'),
          autogeneration: this._getCustomSelectAutogeneration('calling_line_identity'),
        },
        tenant_dial_plan: {
          label: 'tkey;admin.msdialplan.management.field.tenant_dial_plan.label',
          dataAutomation: 'ms-dialplan-group-tenant-dialplan',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this._getCustomSelectAvailableOptions('tenant_dial_plan'),
            isAlwaysRequired: true,
            displayValues: this._getCustomSelectDisplayValues('tenant_dial_plan'),
            displayLabel: 'tkey;admin.msdialplan.management.field.tenant_dial_plan.label',
            postLoadedOptions: this._postLoadOptions.bind(this, 'tenantDialPlans'),
          }),
          validation: this._getCustomSelectValidation('tenant_dial_plan'),
          autogeneration: this._getCustomSelectAutogeneration('tenant_dial_plan'),
        },
        emergency_calling_policy: {
          label: 'tkey;admin.msdialplan.management.field.emergency_calling_policy.label',
          dataAutomation: 'ms-dialplan-group-emergency-calling-policy',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this._getCustomSelectAvailableOptions('emergency_calling_policy'),
            isAlwaysRequired: true,
            displayValues: this._getCustomSelectDisplayValues('emergency_calling_policy'),
            displayLabel: 'tkey;admin.msdialplan.management.field.emergency_calling_policy.label',
            postLoadedOptions: this._postLoadOptions.bind(this, 'emergencyPolicies'),
          }),
          validation: this._getCustomSelectValidation('emergency_calling_policy'),
          autogeneration: this._getCustomSelectAutogeneration('emergency_calling_policy'),
        },
        emergency_call_routing_policy: {
          label: 'tkey;admin.msdialplan.management.field.emergency_call_routing_policy.label',
          dataAutomation: 'ms-dialplan-group-emergency-call-routing-policy',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this._getCustomSelectAvailableOptions('emergency_call_routing_policy'),
            isAlwaysRequired: true,
            displayValues: this._getCustomSelectDisplayValues('emergency_call_routing_policy'),
            displayLabel: 'tkey;admin.msdialplan.management.field.emergency_call_routing_policy.label',
            postLoadedOptions: this._postLoadOptions.bind(this, 'emergencyRoutingPolicies'),
          }),
          validation: this._getCustomSelectValidation('emergency_call_routing_policy'),
          autogeneration: this._getCustomSelectAutogeneration('emergency_call_routing_policy'),
        },
        online_voice_routing_policy: {
          label: 'tkey;admin.msdialplan.management.field.voice_routing_policy.label',
          dataAutomation: 'ms-dialplan-group-voice-routing-policy',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this._getCustomSelectAvailableOptions('online_voice_routing_policy'),
            isAlwaysRequired: true,
            displayValues: this._getCustomSelectDisplayValues('online_voice_routing_policy'),
            displayLabel: 'tkey;admin.msdialplan.management.field.voice_routing_policy.label',
            postLoadedOptions: this._postLoadOptions.bind(this, 'voiceRoutingPolicies'),
          }),
          validation: this._getCustomSelectValidation('online_voice_routing_policy'),
          autogeneration: this._getCustomSelectAutogeneration('online_voice_routing_policy'),
          disabled: () => this.formData.callingType === CallingType.SHARED_CALLING,
          disabledTooltip: 'tkey;admin.msdialplan.management.voice_routing_policy.disabled_tooltip',
        },
        online_voicemail_policy: {
          label: 'tkey;admin.msdialplan.management.field.online_voicemail_policy.label',
          dataAutomation: 'ms-dialplan-group-online-voicemail-policy',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this._getCustomSelectAvailableOptions('online_voicemail_policy'),
            isAlwaysRequired: true,
            displayValues: this._getCustomSelectDisplayValues('online_voicemail_policy'),
            displayLabel: 'tkey;admin.msdialplan.management.field.online_voicemail_policy.label',
            postLoadedOptions: this._postLoadOptions.bind(this, 'onlineVoicemailPolicies'),
          }),
          validation: this._getCustomSelectValidation('online_voicemail_policy'),
          autogeneration: this._getCustomSelectAutogeneration('online_voicemail_policy'),
        },
        ip_phone_policy: {
          label: 'tkey;admin.msdialplan.management.field.ip_phone_policy.label',
          dataAutomation: 'ms-dialplan-group-ip-phone-policy',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this._getCustomSelectAvailableOptions('ip_phone_policy'),
            isAlwaysRequired: true,
            displayValues: this._getCustomSelectDisplayValues('ip_phone_policy'),
            displayLabel: 'tkey;admin.msdialplan.management.field.ip_phone_policy.label',
            postLoadedOptions: this._postLoadOptions.bind(this, 'ipPhonePolicies'),
          }),
          validation: this._getCustomSelectValidation('ip_phone_policy'),
          autogeneration: this._getCustomSelectAutogeneration('ip_phone_policy'),
        },
        emergency_location: {
          label: 'tkey;admin.msdialplan.management.field.emergency_location.label',
          dataAutomation: 'ms-dialplan-group-emergency-location',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this._getCustomSelectAvailableOptions('emergency_location'),
            isAlwaysRequired: this.entity.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS,
            displayValues: this._getCustomSelectDisplayValues('emergency_location'),
            displayLabel: 'tkey;admin.msdialplan.management.field.emergency_location.label',
            postLoadedOptions: this._postLoadOptions.bind(this, 'emergencyLocations'),
          }),
          validation: this._getCustomSelectValidation('emergency_location'),
          autogeneration: this._getCustomSelectAutogeneration('emergency_location'),
          hidden: () => this.isGccHigh,
          disabled: () => this.formData.callingType === CallingType.SHARED_CALLING,
          disabledTooltip: 'tkey;admin.msdialplan.management.emergency_location.disabled_tooltip',
          valExcluded: () => this.formData.callingType === CallingType.SHARED_CALLING,
        },
        lineUri: {
          label: 'tkey;shared.model.microsoft_teams.on_prem.line.uri.extension',
          dataAutomation: 'ms-dialpan-group-on-prem-line-uri-extension',
          componentConfig: new ZiroCustomInputTextConfig({
            editorType: VariableEditorType.MICROSOFT,
            isAlwaysRequired: false,
            hideCheckboxes: false,
          }),
          validation: [
            {
              validator: (val: CustomInputText) => {
                if (
                  this.formData.callingType === CallingType.EXTENSION ||
                  this.formData.callingType === CallingType.SHARED_CALLING
                ) {
                  return SmacsFormsValidationState.VALID;
                } else {
                  return val.required && !val.value.length && !val.show
                    ? SmacsFormsValidationState.INVALID
                    : SmacsFormsValidationState.VALID;
                }
              },
              message: 'tkey;custom_input_text.value.error',
            },
          ],
          helpText: () => 'tkey;admin.msdialplan.management.field.on_prem_line_uri.helptext',
          hidden: () =>
            this.formData.callingType === CallingType.EXTENSION ||
            this.formData.callingType === CallingType.SHARED_CALLING,
          valExcluded: () =>
            this.formData.callingType === CallingType.EXTENSION ||
            this.formData.callingType === CallingType.SHARED_CALLING,
        },
        billingLocation: {
          id: 'ms-dialplan-group-billing-location',
          label: 'tkey;admin.msdialplan.management.field.billing_location.label',
          dataAutomation: 'ms-dialplan-group-billing-location',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          hidden: () => !this.isUserS8Support,
          valExcluded: () => !this.isUserS8Support,
          required: () => this.formData.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS,
          disabled: () => this.isUserS8Support && this.isNonZiroCallingProvider,
          disabledTooltip: 'tkey;admin.msdialplan.management.field.billing_location.label.disabled',
        },
        e911Geolocation: {
          label: 'tkey;admin.msdialplan.management.field.e911_geolocation.label',
          dataAutomation: 'ms-dialplan-group-e911-geolocation',
          componentConfig: new ZiroCustomSelectConfig({
            availableOptions: this.isBandwidthConfigured
              ? this._getCustomSelectAvailableOptions('e911Geolocation')
              : [],
            isAlwaysRequired: true,
            displayValues: this.isBandwidthConfigured
              ? this.entity.bandwidthEmergencyCallingSettings?.customSelects[0].defaultOption
                ? [this.entity.bandwidthEmergencyCallingSettings.customSelects[0].defaultOption].concat(
                    this.entity.bandwidthEmergencyCallingSettings.customSelects[0].possibleOptions
                  )
                : this.entity.bandwidthEmergencyCallingSettings?.customSelects[0]?.possibleOptions
              : [],
            displayLabel: 'tkey;admin.msdialplan.management.field.e911_geolocation.label',
          }),
          validation: this._getCustomSelectValidation('e911Geolocation'),
          helpText: 'tkey;admin.msdialplan.management.field.e911_geolocation.helptext',
          valExcluded: () => !this.isBandwidthGroup || !this.isUserS8Support || !this.isBandwidthConfigured,
          autogeneration: this._getCustomSelectAutogeneration('e911Geolocation'),
        },
      },
    };

    this.displayRangeFormOptionalValidators = {
      hasSameLengthValidation: true,
      overlappingRanges: true,
      strictE164Validation: true,
      overlappingRangesInOtherGroups: {
        groups: this._isNewGroup
          ? this._mapMsGroupsToGeneric(this.allMsDialplanGroups)
          : this._mapMsGroupsToGeneric(
              this.allMsDialplanGroups.filter((group: MicrosoftDialPlanGroup) => group.id !== this.entity.id)
            ),
      },
      noLeadingZeroes: true,
    };
    this.displayRangeFormEntity = { ranges: cloneDeep(this.entity.dialPlanRangesJson) };
    this.displayRangeForm = true;
  }

  private _initBottomNav = () => {
    const isCopyDisabled =
      !this.isUserS8Support &&
      (this.entity.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS_BYOC ||
        this.entity.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS);

    const buttons: BottomNavButton[] = [
      {
        id: 'msDialplanGroupCopyButton',
        dataAutomation: 'ms-dialplan-group-copy-button',
        label: 'tkey;admin.msdialplan.management.button.copy.label',
        icon: SmacsIcons.COPY,
        buttonClass: ButtonStyles.INFO,
        state: {
          pending: false,
          buttonDisableState: {
            disabled: isCopyDisabled,
            tooltipKey: 'tkey;admin.msdialplan.management.button.copy.disabled.tooltip',
          },
          tooltipVisible: isCopyDisabled,
        },
        cb: () => {
          this._router.navigate([`/admin/microsoft/dial-plan-management/${this.entity.id}/copy`]);
        },
      },
      {
        id: 'msDialplanGroupCancelButton',
        dataAutomation: 'ms-dialplan-group-cancel-button',
        label: 'tkey;global.button.cancel.text',
        buttonClass: ButtonStyles.DEFAULT,
        cb: () => {
          this._router.navigate(['/admin/microsoft/dial-plan-management']);
        },
      },
      {
        id: 'msDialplanGroupDeleteButton',
        dataAutomation: 'ms-dialplan-group-delete-button',
        label: 'tkey;global.button.delete.text',
        icon: SmacsIcons.DELETE,
        buttonClass: ButtonStyles.DANGER,
        cb: () => {
          this._deleteClicked();
        },
        state: {
          buttonDisableState: {
            disabled: this.isDisabledForAdmin,
            tooltipKey: 'tkey;admin.msdialplan.management.group.bottom_nav.delete_disabled.tooltip',
          },
          tooltipVisible: this.isDisabledForAdmin,
        },
      },
      {
        id: 'msDialplanGroupSaveButton',
        dataAutomation: 'ms-dialplan-group-save-button',
        label: 'tkey;global.button.save.text',
        icon: SmacsIcons.OK,
        buttonClass: ButtonStyles.PRIMARY,
        cb: () => {
          this._validateAndSubmitSource.next(true);
        },
      },
    ];
    if (!this.entity.id) {
      buttons.splice(0, 1);
    }
    if (this._isNewGroup) {
      buttons.splice(1, 1);
    }
    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttons));
  };

  protected submit(): Observable<void> {
    this.setDisplayFormValidationState();
    if (!!this.adAttributesComponent) {
      this.entitySource.next({
        ...cloneDeep(this.entity),
        adWriteDownList: this.adAttributesComponent.getAllChildValues(),
      });
    }
    const canSubmitForm = this._canSubmitForms();
    const areChildFormsValid = this._areChildFormsValid();

    if (!canSubmitForm || !areChildFormsValid) {
      this._bottomNavService.setBottomNavValidationError(true);
      return of(null);
    }

    this._setPending('msDialplanGroupSaveButton', true);
    this._setDisabled('msDialplanGroupDeleteButton', true);
    this._setDisabled('msDialplanGroupCancelButton', true);

    const saveData: MicrosoftDialPlanGroup = {
      ...this.entity,
      carrierName:
        this.entity.pstnConnectivityType === PstnConnectivityType.ZIRO_DRAAS ||
        this.entity.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS
          ? ''
          : this.entity.carrierName,
      mainNumber:
        this.entity.callingType === CallingType.EXTENSION
          ? ValidatorsService.removeFormattingFromSingleNumber(this.entity.mainNumber)
          : '',
      enforcedNumbersUsageLocation: this._pstnTypesSupportingEnforcedUsageLocation.includes(
        this.entity.pstnConnectivityType
      )
        ? this.entity.enforcedNumbersUsageLocation
        : '',
      customInputs:
        this.entity.callingType === CallingType.EXTENSION
          ? this.entity.customInputs.map((input: CustomInputText) => {
              if (input.name === 'on_prem_line_uri_extension') {
                return {
                  ...input,
                  required: false,
                  show: false,
                  value: '',
                };
              }

              return input;
            })
          : this.entity.customInputs,
      dialPlanRangesJson: this.entity.dialPlanRangesJson.map((range: MicrosoftDialPlanRange) => {
        return {
          start: ValidatorsService.removeFormattingFromSingleNumber(range.start),
          end: ValidatorsService.removeFormattingFromSingleNumber(range.end),
        };
      }),
      sharedCallingRoutingPolicy:
        this.entity.callingType === CallingType.SHARED_CALLING ? this.entity.sharedCallingRoutingPolicy : 'Global',
    };

    return this._msDialPlanContext.save(saveData).pipe(
      tap(() => {
        this._setPending('msDialplanGroupSaveButton', false);
        this._setDisabled('msDialplanGroupDeleteButton', false);
        this._setDisabled('msDialplanGroupCancelButton', false);
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.TABLE,
          'tkey;selfserve.microsoft365.toast.success.title',
          'tkey;admin.msdialplan.management.toast.message',
          { groupName: saveData.name }
        );
        this._router.navigate(['/admin/microsoft/dial-plan-management']);
      }),
      catchError((error) => {
        this._setPending('msDialplanGroupSaveButton', false);
        this._setDisabled('msDialplanGroupDeleteButton', false);
        this._setDisabled('msDialplanGroupCancelButton', false);
        return throwError(() => error);
      })
    );
  }

  private _deleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        title: this._translateService.instant('tkey;admin.msdialplan.management.delete_modal.title'),
        promptBody: this._translateService.instant('tkey;admin.msdialplan.management.delete_modal.description', {
          groupName: this.entity.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteGroup();
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteGroup(): Observable<void> {
    return this._msDialPlanContext.delete(this.entity.id).pipe(
      map(() => {
        this.smacsFormStateService.setIsFormDirty(false);
        this._toastService.pushDeleteToast('tkey;dialplanmanagement.save.successful.toast', this.entity.name);
        this._router.navigate(['../'], { relativeTo: this._route });
      })
    );
  }

  toFormData = (entity: MicrosoftDialPlanGroup): MsDialplanGroupFormData => {
    let e911Geolocation: CustomSelect;
    const bandwidthDialPlanSettings = entity.bandwidthEmergencyCallingSettings?.customSelects || [];
    const e911GeolocationSetting = bandwidthDialPlanSettings[0];

    if (!e911GeolocationSetting && !!this.formData?.e911Geolocation) {
      // Keep the formData e911 geolocation setting if it has been erased from the entity.
      // This prevents the custom-select from disappearing.
      e911Geolocation = this.formData.e911Geolocation;
    } else {
      e911Geolocation = e911GeolocationSetting;
    }

    const lineUri = entity.customInputs.find((input) => input.name === 'on_prem_line_uri_extension');
    return {
      id: entity.id,
      groupName: entity.name,
      pstnConnectivityType: entity.pstnConnectivityType,
      carrierName: entity.carrierName,
      callingType: entity.callingType,
      mainNumber: entity.mainNumber,
      numberRanges: entity.dialPlanRangesJson,
      bulkRanges: this.formData.bulkRanges || '',
      lineUri: lineUri,
      calling_policy: entity.customSelects.find((cs) => cs.name === 'calling_policy'),
      teams_call_hold_policy: entity.customSelects.find((cs) => cs.name === 'teams_call_hold_policy'),
      teams_call_park_policy: entity.customSelects.find((cs) => cs.name === 'teams_call_park_policy'),
      calling_line_identity: entity.customSelects.find((cs) => cs.name === 'calling_line_identity'),
      tenant_dial_plan: entity.customSelects.find((cs) => cs.name === 'tenant_dial_plan'),
      emergency_calling_policy: entity.customSelects.find((cs) => cs.name === 'emergency_calling_policy'),
      emergency_call_routing_policy: entity.customSelects.find((cs) => cs.name === 'emergency_call_routing_policy'),
      online_voice_routing_policy: entity.customSelects.find((cs) => cs.name === 'online_voice_routing_policy'),
      online_voicemail_policy: entity.customSelects.find((cs) => cs.name === 'online_voicemail_policy'),
      ip_phone_policy: entity.customSelects.find((cs) => cs.name === 'ip_phone_policy'),
      emergency_location: this._getFieldDataForEmergencyLocation(
        entity.customSelects.find((cs) => cs.name === 'emergency_location')
      ),
      billingLocation: entity.billingLocation,
      e911Geolocation: e911Geolocation,
      adWriteDownList: this.isAdAttributesToggleChecked ? entity.adWriteDownList : null,
      enforcedNumbersUsageLocation: entity.enforcedNumbersUsageLocation,
      sharedCallingRoutingPolicy: entity.sharedCallingRoutingPolicy,
    };
  };

  toEntity = (form: MsDialplanGroupFormData): MicrosoftDialPlanGroup => {
    return {
      customInputs: [form.lineUri],
      customSelects: [
        {
          name: 'calling_policy',
          defaultOption: form.calling_policy.defaultOption,
          possibleOptions: form.calling_policy.possibleOptions,
          required: form.calling_policy.required,
          show: form.calling_policy.show,
        },
        {
          name: 'teams_call_hold_policy',
          defaultOption: form.teams_call_hold_policy.defaultOption,
          possibleOptions: form.teams_call_hold_policy.possibleOptions,
          required: form.teams_call_hold_policy.required,
          show: form.teams_call_hold_policy.show,
        },
        {
          name: 'teams_call_park_policy',
          defaultOption: form.teams_call_park_policy.defaultOption,
          possibleOptions: form.teams_call_park_policy.possibleOptions,
          required: form.teams_call_park_policy.required,
          show: form.teams_call_park_policy.show,
        },
        {
          name: 'calling_line_identity',
          defaultOption: form.calling_line_identity.defaultOption,
          possibleOptions: form.calling_line_identity.possibleOptions,
          required: form.calling_line_identity.required,
          show: form.calling_line_identity.show,
        },
        {
          name: 'tenant_dial_plan',
          defaultOption: form.tenant_dial_plan.defaultOption,
          possibleOptions: form.tenant_dial_plan.possibleOptions,
          required: form.tenant_dial_plan.required,
          show: form.tenant_dial_plan.show,
        },
        {
          name: 'emergency_calling_policy',
          defaultOption: form.emergency_calling_policy.defaultOption,
          possibleOptions: form.emergency_calling_policy.possibleOptions,
          required: form.emergency_calling_policy.required,
          show: form.emergency_calling_policy.show,
        },
        {
          name: 'emergency_call_routing_policy',
          defaultOption: form.emergency_call_routing_policy.defaultOption,
          possibleOptions: form.emergency_call_routing_policy.possibleOptions,
          required: form.emergency_call_routing_policy.required,
          show: form.emergency_call_routing_policy.show,
        },
        {
          name: 'online_voice_routing_policy',
          defaultOption: form.online_voice_routing_policy.defaultOption,
          possibleOptions: form.online_voice_routing_policy.possibleOptions,
          required: form.online_voice_routing_policy.required,
          show: form.online_voice_routing_policy.show,
        },
        {
          name: 'online_voicemail_policy',
          defaultOption: form.online_voicemail_policy.defaultOption,
          possibleOptions: form.online_voicemail_policy.possibleOptions,
          required: form.online_voicemail_policy.required,
          show: form.online_voicemail_policy.show,
        },
        {
          name: 'ip_phone_policy',
          defaultOption: form.ip_phone_policy.defaultOption,
          possibleOptions: form.ip_phone_policy.possibleOptions,
          required: form.ip_phone_policy.required,
          show: form.ip_phone_policy.show,
        },
        {
          name: 'emergency_location',
          defaultOption: form.emergency_location.defaultOption
            ? this._findEmergencyLocationInCacheByDescription(form.emergency_location.defaultOption).id
            : '',
          possibleOptions: form.emergency_location.possibleOptions.map(
            (opt) => this._findEmergencyLocationInCacheByDescription(opt).id
          ),
          required: form.emergency_location.required,
          show: form.emergency_location.show,
        },
      ],
      billingLocation: form?.billingLocation || '',
      dialPlanRangesJson:
        form.callingType !== CallingType.SHARED_CALLING ? form?.numberRanges : ([] as MicrosoftDialPlanRange[]),
      id: form?.id,
      name: form?.groupName,
      callingType: form?.callingType,
      mainNumber: form?.mainNumber,
      pstnConnectivityType: form?.pstnConnectivityType,
      carrierName: form?.carrierName || '',
      bandwidthEmergencyCallingSettings: this.isBandwidthGroup
        ? {
            customSelects: [
              {
                name: 'e911Geolocations',
                defaultOption: form.e911Geolocation?.defaultOption || '',
                possibleOptions: form.e911Geolocation?.possibleOptions || [],
                required: form.e911Geolocation?.required || true,
                show: form.e911Geolocation?.show || false,
              },
            ],
          }
        : null,
      adWriteDownList: this.isAdAttributesToggleChecked ? form.adWriteDownList : null,
      enforcedNumbersUsageLocation: form.enforcedNumbersUsageLocation,
      sharedCallingRoutingPolicy: form.sharedCallingRoutingPolicy,
    };
  };

  private _getFieldDataForEmergencyLocation(customSelectEntity: CustomSelect): CustomSelect {
    const defaultValue = this._findEmergencyLocationInCacheById(customSelectEntity.defaultOption)?.description ?? '';
    return {
      name: customSelectEntity.name,
      defaultOption: defaultValue,
      possibleOptions: customSelectEntity.possibleOptions.map(
        (opt) => this._findEmergencyLocationInCacheById(opt).description
      ),
      required: customSelectEntity.required,
      show: customSelectEntity.show,
    };
  }

  private _findEmergencyLocationInCacheById(emergencyLocationId: string): EmergencyLocation {
    if (!emergencyLocationId) {
      return null;
    }
    // default to just displaying the id, this can happen if a configured option was deleted in TAC.
    return (
      this.msCachedOptions.emergencyLocations.find((cachedOpt) => cachedOpt.id === emergencyLocationId) ?? {
        id: emergencyLocationId,
        description: emergencyLocationId,
      }
    );
  }

  private _findEmergencyLocationInCacheByDescription(emergencyLocationDescription: string): EmergencyLocation {
    if (!emergencyLocationDescription) {
      return null;
    }
    let emergencyLocation = this.msCachedOptions.emergencyLocations.find(
      (cachedOpt) => cachedOpt.description === emergencyLocationDescription
    );
    if (!emergencyLocation) {
      // the description is set to the id if the emergency location doesn't exist in Teams.
      emergencyLocation = this._findEmergencyLocationInCacheById(emergencyLocationDescription);
    }
    return emergencyLocation;
  }

  private _setPending(buttonId: string, setting: boolean) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  private _setDisabled(buttonId: string, setting: boolean) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: {
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  private _canSubmitForms(): boolean {
    if (this.adAttributesComponent) {
      this.adAttributesComponent.validateAllChildren();
    }
    if (this.extensionRangesDisplayForm) {
      this.extensionRangesDisplayForm._validateAndSubmitSource.next(true);
    } else {
      return false;
    }

    return true;
  }

  private _areChildFormsValid(): boolean {
    let validity = true;
    if (
      (this.extensionRangesDisplayForm &&
        this.formData.callingType !== CallingType.SHARED_CALLING &&
        !this.extensionRangesDisplayForm.isFormValid()) ||
      (this.isAdAttributesToggleChecked && this.adAttributesComponent && !this.adAttributesComponent.areAllItemsValid())
    ) {
      validity = false;
    }
    return validity;
  }

  private _mapMsGroupsToGeneric(dialPlanGroup: MicrosoftDialPlanGroup[]): SmacsRangeGroup[] {
    return dialPlanGroup.map((g: MicrosoftDialPlanGroup) => {
      return {
        id: Number(g.id),
        name: g.name,
        ranges: g.dialPlanRangesJson,
        pstnConnectivityType: g.pstnConnectivityType,
        rangeType: g.callingType,
        mainNumber: g.mainNumber,
      } as SmacsRangeGroup;
    });
  }

  private _getCustomSelectDisplayValues(fieldId: string): string[] {
    return this.entity.customSelects
      .filter((select) => select.name === fieldId)
      .map((customSelectFiltered: CustomSelect) => {
        return customSelectFiltered.defaultOption
          ? [
              customSelectFiltered.defaultOption,
              ...customSelectFiltered.possibleOptions.filter((option) => option !== customSelectFiltered.defaultOption),
            ]
          : customSelectFiltered.possibleOptions;
      })
      .flat();
  }

  private _getCustomSelectValidation(fieldId: string): SmacsFormsValidationConfig {
    return [
      {
        validator: (val: CustomSelect) => {
          if (val && val.required && !val.defaultOption) {
            return SmacsFormsValidationState.INVALID;
          }
          return SmacsFormsValidationState.VALID;
        },
        message: 'tkey;custom_select.required.all_defaults_required.error',
      },
      {
        validator: (val: CustomSelect) => {
          return val.possibleOptions.every((value) => this._getCustomSelectAvailableOptions(fieldId).includes(value))
            ? SmacsFormsValidationState.VALID
            : SmacsFormsValidationState.INVALID;
        },
        message: () => {
          const possibleOptions = this._getCustomSelectPossibleOptions(fieldId);
          const invalidOptions = possibleOptions.filter(
            (value) => !sortBy(this.msCachedOptions?.emergencyLocations.map((opt) => opt.description)).includes(value)
          );
          const translation = this._translateService.instant('tkey;select.invalid_options.label');
          return `${translation} ${this._arrayToString(invalidOptions)}`;
        },
      },
      {
        validator: (val: CustomSelect) => {
          if (!val.show && val.possibleOptions.length > 1) {
            return SmacsFormsValidationState.WARNING;
          }
          return SmacsFormsValidationState.VALID;
        },
        message: 'tkey;custom_select.show.multiple_values.warning',
      },
    ];
  }

  private _getCustomSelectAutogeneration(fieldId: string): SmacsFormsAutogenerationConfig {
    return {
      linkLabel: 'tkey;shared.html.customizable_fields.text_input.fix_it.label',
      generateValue: (fieldValue) => {
        return fieldValue;
      },
      hidden: (val) => {
        const possibleOptions = (val as CustomSelect)?.possibleOptions;
        const availableOptions = this._getCustomSelectAvailableOptions(fieldId);
        return possibleOptions?.every((option) => availableOptions?.includes(option));
      },
      inline: true,
    };
  }

  private _arrayToString(values: string[]): string {
    return values ? values.join(', ') : '';
  }

  private _getCustomSelectPossibleOptions(fieldId: string): string[] {
    switch (fieldId) {
      case 'calling_policy':
        return this.formData.calling_policy.possibleOptions;
      case 'teams_call_hold_policy':
        return this.formData.teams_call_hold_policy.possibleOptions;
      case 'teams_call_park_policy':
        return this.formData.teams_call_park_policy.possibleOptions;
      case 'calling_line_identity':
        return this.formData.calling_line_identity.possibleOptions;
      case 'tenant_dial_plan':
        return this.formData.tenant_dial_plan.possibleOptions;
      case 'emergency_calling_policy':
        return this.formData.emergency_calling_policy.possibleOptions;
      case 'emergency_call_routing_policy':
        return this.formData.emergency_call_routing_policy.possibleOptions;
      case 'online_voice_routing_policy':
        return this.formData.online_voice_routing_policy.possibleOptions;
      case 'online_voicemail_policy':
        return this.formData.online_voicemail_policy.possibleOptions;
      case 'ip_phone_policy':
        return this.formData.ip_phone_policy.possibleOptions;
      case 'emergency_location':
        return this.formData.emergency_location.possibleOptions;
      default:
        return [];
    }
  }

  private _getCustomSelectAvailableOptions(fieldId: string): string[] {
    switch (fieldId) {
      case 'calling_policy':
        return sortBy(this.msCachedOptions?.callingPolicies);
      case 'teams_call_hold_policy':
        return sortBy(this.msCachedOptions?.teamsCallHoldPolicies);
      case 'teams_call_park_policy':
        return sortBy(this.msCachedOptions?.teamsCallParkPolicies);
      case 'calling_line_identity':
        return sortBy(this.msCachedOptions?.callingLineIdentities);
      case 'tenant_dial_plan':
        return sortBy(this.msCachedOptions?.tenantDialPlans);
      case 'emergency_calling_policy':
        return sortBy(this.msCachedOptions?.emergencyPolicies);
      case 'emergency_call_routing_policy':
        return sortBy(this.msCachedOptions?.emergencyRoutingPolicies);
      case 'online_voice_routing_policy':
        return sortBy(this.msCachedOptions?.voiceRoutingPolicies);
      case 'online_voicemail_policy':
        return sortBy(this.msCachedOptions?.onlineVoicemailPolicies);
      case 'ip_phone_policy':
        return sortBy(this.msCachedOptions?.ipPhonePolicies);
      case 'emergency_location':
        return sortBy(this.msCachedOptions?.emergencyLocations.map((opt) => opt.description));
      case 'e911Geolocation':
        return sortBy(this.bandwidthCachedOptions?.e911Geolocations);
      default:
        return [];
    }
  }

  protected readonly PstnConnectivityType = PstnConnectivityType;
  protected readonly CallingType = CallingType;
  protected readonly smacsIcons = SmacsIcons;
  protected readonly EnabledType = EnabledType;
}
