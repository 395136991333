import { Component, Input, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { TranslationPatternFieldConfig } from '../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { of } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { TranslateService } from '@ngx-translate/core';

export interface TranslationPatternFormData {
  description: string;
  routePartition: string;
  classOfService: string;
}
@Component({
  selector: 'smacs-directory-number-did-details',
  templateUrl: './directory-number-did-details.component.html',
})
export class DirectoryNumberDidDetailsComponent
  extends SmacsFormAbstractDirective<TranslationPatternFormData>
  implements OnInit
{
  @Input() fieldConfig: TranslationPatternFieldConfig;
  formConfig: SmacsFormConfig;

  constructor(protected smacsFormStateService: SmacsFormStateService, private _translateService: TranslateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.formConfig = {
      fields: {
        description: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.didDescription.label',
          dataAutomation: 'dn-did-details-description-input',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: this.fieldConfig.description.required,
          hidden: () => !this.fieldConfig.description.show,
          defaultValue: () => this.fieldConfig.description.defaultValue,
          validation: [
            {
              validator: (val: string) => {
                return val.length > 50 ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
              },
              message: this._translateService.instant('tkey;validators.global.error.maxlength', { maxlength: 50 }),
            },
          ],
        },
        classOfService: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.did_css.label',
          dataAutomation: 'dn-did-details-css-input',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.classOfService.possibleOptions }),
          required: this.fieldConfig.classOfService.required,
          hidden: () => !this.fieldConfig.classOfService.show,
          defaultValue: () => this.fieldConfig.classOfService.defaultValue,
        },
        routePartition: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.did_route_partition.label',
          dataAutomation: 'dn-did-details-route-partition-input',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.routePartition.possibleOptions }),
          required: this.fieldConfig.routePartition.required,
          hidden: () => !this.fieldConfig.routePartition.show,
          defaultValue: () => this.fieldConfig.routePartition.defaultValue,
        },
      },
    };
  }

  protected submit() {
    return of(null);
  }
}
