import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SharedCallingExtension, ZiroDraasExtensions } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class DraasSharedExtensionsResource {
  constructor(private http: HttpClient) {}

  getAllExtensions(e164: string): Observable<ZiroDraasExtensions> {
    return this.http.get<ZiroDraasExtensions>(
      `/services/microsoft/macs/ziro-draas-numbers/${encodeURIComponent(e164)}`
    );
  }

  getExtension(e164: string, extension: string): Observable<SharedCallingExtension> {
    return this.http.get<SharedCallingExtension>(
      `/services/microsoft/macs/ziro-draas-numbers/${encodeURIComponent(e164)}/shared-extensions/${encodeURIComponent(
        extension
      )}`
    );
  }

  postExtension(e164: string, request: { sharedCallingExtension: string }): Observable<void> {
    return this.http.post<void>(
      `/services/microsoft/macs/ziro-draas-numbers/${encodeURIComponent(e164)}/shared-extensions`,
      request
    );
  }

  deleteExtension(e164: string, extension: string): Observable<void> {
    return this.http.delete<void>(
      `/services/microsoft/macs/ziro-draas-numbers/${encodeURIComponent(e164)}/shared-extensions/${encodeURIComponent(
        extension
      )}`
    );
  }
}
