import { Injectable } from '@angular/core';
import { TelephoneNumberFilter } from '../../shared/filters/telephone-number.filter';
import { DnDidRange } from '../../shared/models/generated/smacsModels';

@Injectable()
export class RangeFilterService {
  public static getNonFormattedRange(range: { start: string; end: string }): string {
    return `${range.start} - ${range.end}`;
  }

  constructor(private _telephoneNumberFilter: TelephoneNumberFilter) {}

  getE164FormattedRange(range: { start: string; end: string }): string {
    return `${this._telephoneNumberFilter.transform(range.start)} - ${this._telephoneNumberFilter.transform(
      range.end
    )}`;
  }

  hasMatchingRanges(ranges: DnDidRange[], filter: string): boolean {
    return ranges.some((range: DnDidRange) => {
      const numericRangeStart = Number(range.start.replace('+', ''));
      const numericRangeEnd = Number(range.end.replace('+', ''));
      const numericRangeFilter = filter?.replace(/\D/g, '');

      return (
        // Matches both the range strings and numbers that fall inside one of the ranges in the group:
        this.getE164FormattedRange(range).includes(filter) ||
        RangeFilterService.getNonFormattedRange(range).includes(filter) ||
        (isFinite(Number(numericRangeFilter)) &&
          Number(numericRangeStart) <= Number(numericRangeFilter) &&
          Number(numericRangeFilter) <= Number(numericRangeEnd))
      );
    });
  }

  hasMatchingLetters(ranges: DnDidRange[], filter: string): boolean {
    return ranges.some((range: DnDidRange) => range.start.toLowerCase().includes(filter.toLowerCase()));
  }
}
