import { ChangeDetectorRef, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { ActivationStart, Event, NavigationEnd, Router } from '@angular/router';
import { get } from 'lodash';
import { GlobalPropertiesContext } from '../../../components/src/lib/shared/contexts/global-properties.context';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  isLoading = true;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private router: Router,
    private title: Title,
    private _globalPropertiesContext: GlobalPropertiesContext
  ) {
    this._globalPropertiesContext.refreshGlobalProperties().subscribe(() => {
      this.isLoading = false;
    });
    this.translate.addLangs(['en', 'fr']);
    this._addTitleListener();
  }

  private _addTitleListener() {
    this.router.events.subscribe((event: Event) => {
      this._setLanguage(event);
    });
  }

  private _setLanguage(event: Event) {
    if (event instanceof ActivationStart) {
      const dataTitle = get(event, ['snapshot', 'data', 'title']);

      if (
        !this.translate.currentLang ||
        (this._translateCurrentRoute() && !this._currentLanguageIsCorrect(this._getDefaultLangCodeFromBrowser()))
      ) {
        const lang = this._translateCurrentRoute() ? this._getDefaultLangCodeFromBrowser() : 'en';
        this.translate.use(lang);
      }
      this._getTitle(dataTitle);
    } else if (event instanceof NavigationEnd) {
      if (!this._translateCurrentRoute() && !this._currentLanguageIsCorrect('en')) {
        // We should not be translating on the route we landed on. Defaulting to English.
        this.translate.use('en');
      }
    }
  }

  private _getDefaultLangCodeFromBrowser(): string {
    const browserLangCode = this.translate.getBrowserLang();

    if (this.translate.getLangs().some((lang) => lang === browserLangCode)) {
      return browserLangCode;
    } else {
      return 'en';
    }
  }

  private _getTitle(dataTitle: string) {
    if (dataTitle) {
      this.translate.get(dataTitle).subscribe((title: string) => {
        this.title.setTitle(`ZIRO - ${title}`);
      });
    } else {
      this.title.setTitle(`ZIRO`);
    }
  }

  private _currentLanguageIsCorrect(expectedLanguage: string): boolean {
    return expectedLanguage === this.translate.currentLang;
  }

  private _translateCurrentRoute(): boolean {
    const hash = window.location.hash;
    const translatedRoutes = ['self-serve', 'login', 'system-health-status'];

    return translatedRoutes.some((route: string) => {
      if (hash.includes(route)) {
        return route;
      }
    });
  }
}
