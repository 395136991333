<div class="smacs-admin-page-content">
  <app-loading-block *ngIf="isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1 pb-2" *ngIf="!isLoading && !pageDisabled">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.webex_authentication.about_card' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <ziro-webex-authentication-form
      #authForm
      [entity]="callingSettings"
      [clientIdExists]="clientIdExists"
      [callingSettings]="callingSettings"
    ></ziro-webex-authentication-form>
  </div>

  <div
    class="offset-md-1 col-md-10 smacs-page-alert alert border-warning"
    *ngIf="pageDisabled"
    data-automation="smacs-page-disabled-alert"
  >
    <i class="{{ smacsIcons.WARNING }} text-warning fa-4x"></i>
    <div class="ms-3" [innerHTML]="pageDisabledMessage | translate"></div>
  </div>
</div>

<app-bottom-nav *ngIf="!pageDisabled"></app-bottom-nav>
