import { Component, OnInit } from '@angular/core';
import { SmacsFieldAbstractDirective } from '../../../forms/smacs-field-abstract.directive';
import { HtmlInputType } from '../../../forms/fields/text/smacs-text.component';
import { CustomInputText } from '../../models/generated/smacsModels';
import { SmacsFieldComponentConfig } from '../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { VariableEditorModalComponent } from '../../../modals/edit-custom-input-text-modal/variable-editor-modal.component';
import { VariableEditorType } from '../../../modals/edit-custom-input-text-modal/variable-editor.service';
import { SmacsModalService } from '../../services/smacs-modal.service';
import { SmacsIcons } from '../../models/smacs-icons.enum';
import { DefaultValueService } from '../../services/default-value.service';

export class ZiroCustomInputTextConfig extends SmacsFieldComponentConfig {
  constructor(
    public config: {
      editorType: VariableEditorType;
      isAlwaysRequired: boolean;
      hideCheckboxes: boolean;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'ziro-custom-input-text',
  templateUrl: './ziro-custom-input-text.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: ZiroCustomInputTextComponent }],
})
export class ZiroCustomInputTextComponent
  extends SmacsFieldAbstractDirective<CustomInputText, CustomInputText, ZiroCustomInputTextConfig>
  implements OnInit
{
  htmlInputType = HtmlInputType;
  smacsIcons = SmacsIcons;
  editorType: VariableEditorType;
  textValue = '';
  previewText: string;

  isRequiredChecked = false;
  isShowChecked = false;
  isAlwaysRequired = false;
  isFirstLoad = true;
  requiredLabel: string;
  showLabel = 'tkey;site_management.site.section.show.label';
  hideCheckboxes = false;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _smacsModalService: SmacsModalService,
    private _defaultValueService: DefaultValueService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.selfUpdate$.subscribe((update) => {
      this.textValue = update.value;
      this.previewText = this._defaultValueService.generatePreview(update.value);
      this.isRequiredChecked = this.isAlwaysRequired || update.required;
      this.isShowChecked = update.show;
      if (update.required !== this.isRequiredChecked) {
        this.updateSelf({
          ...update,
          required: this.isRequiredChecked,
        });
      }
    });
  }

  applyComponentConfig = ({ config }: ZiroCustomInputTextConfig) => {
    this.isAlwaysRequired = config.isAlwaysRequired;
    this.editorType = config.editorType;
    this.requiredLabel = this.isAlwaysRequired
      ? 'tkey;site_management.site.section.required.always.label'
      : 'tkey;site_management.site.section.required.label';
    this.hideCheckboxes = config.hideCheckboxes;
  };

  handleTextChange(newValue: string) {
    this.updateSelf({
      ...this.entity,
      value: newValue,
    });
  }

  onRequiredChange(newValue: boolean) {
    this.updateSelf({ ...this.entity, required: newValue });
  }

  onShowChange(newValue: boolean) {
    this.updateSelf({
      ...this.entity,
      show: newValue,
    });
  }

  openModal() {
    const modalInstance = this._smacsModalService.openCustomModal(
      VariableEditorModalComponent,
      {
        value: this.entity.value,
        type: this.editorType,
      },
      'lg'
    );
    modalInstance.subscribe((newValue: any) => {
      this.updateSelf({
        ...this.entity,
        value: newValue.value,
      });
    });
  }

  getHiddenState(): boolean {
    if (this.config.hasOwnProperty('hidden')) {
      if (typeof this.config.hidden === 'boolean') {
        return this.state.hidden;
      } else if (typeof this.config.hidden === 'function') {
        return this.config.hidden();
      }
    }
  }

  protected toEntity = (formData: CustomInputText): CustomInputText => {
    if (this.isFirstLoad) {
      this.previewText = this._defaultValueService.generatePreview(formData.value);
      this.isFirstLoad = false;
    }
    return { ...formData, required: this.isAlwaysRequired || formData.required };
  };
}
