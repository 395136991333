export enum SmacsIcons {
  NONE = '',
  ABOUT = 'icon-about',
  ADD = 'icon-add',
  ADD_DOCUMENT = 'icon-add-document',
  ADDRESS_CARD = 'icon-address-card',
  ADD_USER = 'icon-add-user',
  ANDROID = 'icon-search-android',
  ANDROID_OUTLINE = 'icon-outline-android',
  ARCHIVE = 'icon-archive',
  AUDITS = 'icon-audits',
  AUTOMATION = 'icon-automation',
  AUTOMATION_ALT = 'icon-automation-alt',
  AZURE = 'icon-azure',
  BACK = 'icon-back',
  BANDWIDTH = 'icon-bandwidth',
  BELL = 'icon-bell',
  BILLING = 'icon-billing',
  BUILDING = 'icon-building',
  BULLHORN = 'icon-bullhorn',
  CALENDAR = 'icon-calendar',
  CALENDAR_CHECK_OUTLINE = 'icon-calendar-check-outline',
  CARD_ADD = 'icon-card-add',
  CERTIFICATE = 'icon-certificate',
  CHECK_CIRCLE = 'icon-check-circle',
  CHECK_CIRCLE_ALT = 'icon-check-circle-alt',
  CHECKED_SQUARE = 'icon-checked-square',
  CIPC = 'icon-search-cipc',
  CIPC_OUTLINE = 'icon-outline-cipc',
  CIRCLE = 'icon-circle',
  CLEAR = 'icon-clear',
  CLOCK = 'icon-clock',
  CLUSTER = 'icon-cluster',
  CODE_BRANCH = 'icon-code-branch',
  CONFIG = 'icon-config',
  COPY = 'icon-copy',
  DELEGATE = 'icon-delegate',
  DELETE = 'icon-delete',
  DELETE_OUTLINE = 'icon-delete-outline',
  DESKPHONE = 'icon-search-deskphone',
  DESKPHONE_OUTLINE = 'icon-outline-deskphone',
  DIAL_PLAN_GROUP = 'icon-table',
  DISASTER_RECOVERY = 'icon-disaster-recovery',
  DISTRIBUTION_LIST = 'icon-distribution-list',
  DISTRIBUTION_LIST_SEARCH = 'icon-search-distribution-list',
  DOCUMENTS = 'icon-documents',
  DOWN = 'icon-down',
  DOWNLOAD = 'icon-download',
  DRAFT = 'icon-draft',
  DUBBER = 'icon-outline-dubber',
  DUBBER_ICON = 'icon-dubber',
  EDIT = 'icon-edit',
  ENVIRONMENT = 'icon-environment',
  EYE = 'icon-eye',
  EXCHANGE = 'icon-exchange',
  EXCLAMATION = 'icon-exclamation',
  EXCLAMATION_CIRCLE = 'icon-exclamation-circle',
  EXPORT = 'icon-export',
  EXTENSION_MOBILITY = 'icon-search-extension-mobility',
  EXTENSION_MOBILITY_SEARCH = 'icon-extension-mobility',
  EXTENSION_MOBILITY_OUTLINE = 'icon-outline-extension-mobility',
  EXTERNAL_LINK = 'icon-external-link',
  FILE = 'icon-file',
  FILTER = 'icon-filter',
  FIX_IT = 'icon-fix-it',
  FORBIDDEN = 'icon-forbidden',
  GROUP = 'icon-group',
  GET_DATA_SOLID = 'icon-custom-solid-get-data',
  GET_DATA_OUTLINE = 'icon-custom-outline-get-data',
  HASH = 'icon-search-hash',
  HAND = 'icon-hand',
  HEALTH = 'icon-health',
  HISTORY = 'icon-history',
  IMP = 'icon-search-imp',
  IMP_OUTLINE = 'icon-outline-imp',
  IMPORT = 'icon-import',
  INACTIVE = 'icon-inactive',
  INBOX = 'icon-inbox',
  INDETERMINATE_SQUARE = 'icon-indeterminate-square',
  INFO = 'icon-info-circle',
  IPHONE = 'icon-search-iphone',
  IPHONE_OUTLINE = 'icon-outline-iphone',
  KEY = 'icon-key',
  LANGUAGE = 'icon-language',
  LDAP = 'icon-ldap',
  LIGHT_BULB = 'icon-light-bulb',
  LOADING = 'icon-loading',
  LOCK = 'icon-lock',
  MEETINGS = 'icon-outline-meetings',
  MINUS = 'icon-minus',
  MICROSOFT_365 = 'icon-search-microsoft-365',
  MICROSOFT_365_OUTLINE = 'icon-outline-microsoft-365',
  MICROSOFT_TEAMS_CALLING = 'icon-search-teams-calling',
  MONITOR = 'icon-monitor',
  NEXT = 'icon-next',
  NOTES = 'icon-notes',
  OK = 'icon-ok',
  ONE_CLICK = 'icon-one-click',
  PDF = 'icon-pdf',
  PHONE_ALT = 'icon-phone-alt',
  PHONE_BUTTONS = 'icon-phone-buttons',
  PHONE_DIAL = 'icon-phone-dial',
  PHONE_OTHER = 'icon-search-phone-other',
  PHONE_OTHER_OUTLINE = 'icon-outline-phone-other',
  PHONE_WITH_LIST = 'icon-phone-with-list',
  PLUS = 'icon-plus',
  PLUS_CIRCLE = 'icon-plus-circle',
  PLUS_SQUARE = 'icon-plus-square',
  POWERSHELL = 'icon-powershell',
  PREVIEW = 'icon-preview',
  PRIMARY_EXT = 'icon-search-primary-extension',
  PRIMARY_EXT_OUTLINE = 'icon-outline-primary-extension',
  PREVIOUS = 'icon-previous',
  REASSIGN = 'icon-reassign',
  REFRESH = 'icon-refresh',
  REMOVE = 'icon-remove',
  REPORTS = 'icon-reports',
  RUN = 'icon-run',
  SAVING = 'icon-saving',
  SEARCH = 'icon-search',
  SECURITY_GROUPS = 'icon-security-groups',
  SECURITY_GROUPS_OUTLINE = 'icon-outline-security-groups',
  SEND_EMAIL = 'icon-send-email',
  SERVER = 'icon-server',
  SHIELD = 'icon-shield',
  SLIDERS = 'icon-sliders',
  SNR = 'icon-search-snr',
  SNR_OUTLINE = 'icon-outline-snr',
  SIGN_IN = 'icon-sign-in',
  SIGN_OUT = 'icon-sign-out',
  SITE = 'icon-site',
  SOFTPHONE = 'icon-search-softphone',
  SOFTPHONE_OUTLINE = 'icon-outline-softphone',
  SORT_DOWN = 'icon-sort-down',
  SORT_UP = 'icon-sort-up',
  STAR = 'icon-star',
  TABLE = 'icon-table',
  TABLET = 'icon-tablet',
  TABLET_SEARCH = 'icon-search-tablet',
  TABLET_OUTLINE = 'icon-outline-tablet',
  TOOLTIP = 'icon-tooltip',
  UCCX_AGENT = 'icon-search-agent',
  UCCX_AGENT_OUTLINE = 'icon-outline-agent',
  UNAVAILABLE = 'icon-unavailable',
  UNCHECKED_SQUARE = 'icon-unchecked-square',
  UNDO = 'icon-undo',
  UP = 'icon-up',
  USER = 'icon-user',
  USER_CIRCLE = 'icon-user-circle',
  VOICEMAIL = 'icon-search-voicemail',
  VOICEMAIL_OUTLINE = 'icon-outline-voicemail',
  WARNING = 'icon-warning-triangle',
  WEBEX_CALLING = 'icon-webex-calling',
  WEBEX_CALLING_OUTLINE = 'icon-custom-outline-webex-calling',
  WIFI = 'icon-wifi',
  WINDOWS_CLOSE = 'icon-window-close',
  WRENCH = 'icon-wrench',
  ZIRO = 'icon-ziro-sm',
}
