import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import {
  SmacsFormConfig,
  SmacsFormsValidationConfigItem,
  SmacsFormsValidationState,
} from '../../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { of, Subscription } from 'rxjs';
import { HtmlInputType, SmacsTextComponent, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { SmacsIcons } from '../../../models/smacs-icons.enum';
import { SmacsModalService } from '../../../services/smacs-modal.service';
import { DefaultValueService } from '../../../services/default-value.service';
import { TranslateService } from '@ngx-translate/core';
import { TextFieldConfig } from '../../../models/generated/smacsModels';
import { omit } from 'lodash';
import { UniqueIdService } from '../../../services/unique-id.service';
import { VariableEditorModalComponent } from '../../../../modals/edit-custom-input-text-modal/variable-editor-modal.component';
import { VariableEditorType } from '../../../../modals/edit-custom-input-text-modal/variable-editor.service';

export interface TextConfigEntity extends TextFieldConfig {
  preview: string;
}

@Component({
  selector: 'smacs-text-config-form',
  templateUrl: './text-config-form.component.html',
  styleUrls: ['./text-config-form.component.scss'],
})
export class TextConfigFormComponent
  extends SmacsFormAbstractDirective<TextFieldConfig, TextConfigEntity>
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('defaultValue') defaultValueComponent: SmacsTextComponent;

  @Input() displayEditor = false;
  @Input() displayLabel: string;
  @Input() oneClickEnabled: boolean;
  @Input() dataAutomation: string;
  @Input() helpText: string;
  @Input() isMicrosoftDialPlan: boolean;
  @Input() disabled = false;
  @Input() labelToolTipIconClass: string;
  @Input() labelToolTipText: string;
  @Input() extraValidation: SmacsFormsValidationConfigItem[];

  formConfig: SmacsFormConfig;
  smacsIcons = SmacsIcons;

  private _subs = new Subscription();
  private _validators = {
    requiredHiddenValidator(value: string, required: boolean, show: boolean): SmacsFormsValidationState {
      return !show && !value && required ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
    },
    oneClickRequiredValidator(value: string, required: boolean, show: boolean, oneClickEnabled: boolean) {
      return oneClickEnabled && show && required && !value
        ? SmacsFormsValidationState.INVALID
        : SmacsFormsValidationState.VALID;
    },
  };

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private smacsModalService: SmacsModalService,
    private defaultValueService: DefaultValueService,
    private translateService: TranslateService,
    private uniqueIdService: UniqueIdService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._initFormConfig();
    const sub = this.smacsFormsUpdate$.subscribe(() => {
      if (this.defaultValueComponent && this.defaultValueComponent.validationState) {
        this.defaultValueComponent.isDirty = true;
        this.defaultValueComponent.showValidation = true;
      }
    });
    this._subs.add(sub);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.oneClickEnabled) {
      this._validateAllFields();
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  openModal() {
    const modalInstance = this.smacsModalService.openCustomModal(
      VariableEditorModalComponent,
      {
        value: this.entity.defaultValue,
        type: this.isMicrosoftDialPlan ? VariableEditorType.MICROSOFT : VariableEditorType.CISCO,
      },
      'lg'
    );
    modalInstance.subscribe((newValue: any) => {
      const value = newValue.value;
      this.entitySource.next({
        ...this.entity,
        defaultValue: value,
      });

      this._validateAllFields();
    });
  }

  protected toEntity = (formData: TextConfigEntity): TextFieldConfig => {
    return {
      ...omit(formData, ['preview']),
    };
  };

  protected toFormData = (entity: TextFieldConfig): TextConfigEntity => {
    return {
      ...entity,
      preview: this.defaultValueService.generatePreview(entity.defaultValue),
    };
  };

  protected submit() {
    return of(null);
  }

  getDisabledState(): boolean {
    return this.disabled;
  }

  private _getLabel(): string {
    if (
      (this.formData.required && this.oneClickEnabled && !this.formData.defaultValue) ||
      (this.formData.required && !this.formData.show)
    ) {
      return `<span class="text-danger smacs-forms-asterisk">*</span> ${this.translateService.instant(
        this.displayLabel
      )}`;
    }

    return this.displayLabel;
  }

  private _initFormConfig() {
    const id = this.uniqueIdService.getUniqueId();

    this.formConfig = {
      fields: {
        defaultValue: {
          label: () => this._getLabel(),
          labelToolTipText: this.labelToolTipText,
          labelToolTipIconClass: this.labelToolTipIconClass,
          id: `text-config-input-value-${id}`,
          dataAutomation: `text-config-input-value`,
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: () => false,
          validation: [
            {
              validator: (value: string, show: boolean, required: boolean) => {
                return this._validators.requiredHiddenValidator(value, required, show);
              },
              message: () => 'tkey;custom_input_text.value.error',
              injectValuesFromFields: ['show', 'required'],
            },
            {
              validator: (value: string, show: boolean, required: boolean) => {
                return this._validators.oneClickRequiredValidator(value, required, show, this.oneClickEnabled);
              },
              message: () => 'tkey;site_management.site.section.one_click_provisioning_blocked.error',
              injectValuesFromFields: ['show', 'required'],
            },
          ],
          disabled: () => this.getDisabledState(),
        },
        required: {
          id: `text-config-required-${id}`,
          dataAutomation: `text-config-required`,
          label: 'tkey;site_management.site.section.required.label',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX }),
          disabled: () => this.getDisabledState(),
        },
        show: {
          id: `text-config-show-${id}`,
          dataAutomation: `text-config-show`,
          label: 'tkey;site_management.site.section.show.label',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX }),
          disabled: () => this.getDisabledState(),
        },
      },
    };
    if (!!this.extraValidation) {
      this.formConfig.fields['defaultValue'].validation.push(...this.extraValidation);
    }
  }

  private _validateAllFields() {
    Object.keys(this.fieldChannels).forEach((key: string) => {
      this.fieldChannels[key].validateSource.next();
    });
  }
}
