<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1">
    <app-breadcrumbs>
      <app-breadcrumb-card-item
        [innerHTML]="'tkey;admin.proxy_server_management.zpm_connection_settings.about' | translate"
      ></app-breadcrumb-card-item>
    </app-breadcrumbs>
    <div class="card mt-3">
      <div class="card-body">
        <smacs-text [fieldId]="'host'"></smacs-text>
        <smacs-text [fieldId]="'secret'"></smacs-text>
      </div>
    </div>
    <div
      *ngIf="healthStatusAlertCss"
      class="mt-3 alert"
      [ngClass]="healthStatusAlertCss"
      [innerHTML]="healthStatusAlertText | translate"
    ></div>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
