import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  BandwidthPortInDocuments,
  BandwidthPortInOrder,
  BandwidthPortInOrderNoteAndHistory,
  BandwidthPortInOrderRef,
  PrePortedNumberStatus,
} from '../../../shared/models/generated/smacsModels';
import { FileDownloadService } from '../../../shared/services/file-download.service';

@Injectable()
export class PortInOrdersResource {
  private _url = '/services/microsoft/orders/bandwidth-port-in-orders';

  constructor(private _http: HttpClient, private _fileDownloadService: FileDownloadService) {}

  get(): Observable<BandwidthPortInOrderRef[]> {
    return this._http.get<BandwidthPortInOrderRef[]>(this._url);
  }

  getOrder(orderId: string): Observable<BandwidthPortInOrder> {
    return this._http.get<BandwidthPortInOrder>(`${this._url}/${orderId}`);
  }

  getOrderDocuments(orderId: string): Observable<BandwidthPortInDocuments[]> {
    return this._http.get<BandwidthPortInDocuments[]>(`${this._url}/${orderId}/documents`);
  }

  cancelOrder(orderId: string): Observable<void> {
    return this._http.delete<void>(`${this._url}/${orderId}`);
  }

  downloadDocument(orderId: string, documentId: string): Observable<void> {
    return this._fileDownloadService.downloadFile(`${this._url}/${orderId}/documents/${documentId}`);
  }

  getOrderAndDraftNumberInventories(draftOrOrderId: string): Observable<PrePortedNumberStatus> {
    return this._http.get<PrePortedNumberStatus>(`/services/microsoft/orders/number-statuses/${draftOrOrderId}`);
  }

  getNotes(orderId: string): Observable<BandwidthPortInOrderNoteAndHistory[]> {
    return this._http.get<BandwidthPortInOrderNoteAndHistory[]>(`${this._url}/${orderId}/notes`);
  }
}
