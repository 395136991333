import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DistributionListResult, SiteSearchResult, SnrProfileResult } from '../models/generated/smacsModels';

@Injectable()
export class SearchResource {
  limit = 100;

  constructor(private http: HttpClient) {}

  searchSnrProfiles(
    query: string,
    searchParam: 'q' | 'name' = 'q',
    cucmServerId?: number
  ): Observable<SnrProfileResult[]> {
    let queryParams = `${searchParam}=${encodeURIComponent(query)}`;

    if (cucmServerId) {
      queryParams += `&cucmServerId=${encodeURIComponent(cucmServerId.toString())}`;
    }

    return this.http.get<SnrProfileResult[]>(
      `/services/cisco/macs/snr-profiles/search?${queryParams}&limit=${this.limit}`
    );
  }

  searchDistributionLists(query: string, cucmServerId?: number): Observable<DistributionListResult[]> {
    let queryParams = `q=${encodeURIComponent(query)}`;

    if (cucmServerId) {
      queryParams += `&cucmServerId=${encodeURIComponent(cucmServerId.toString())}`;
    }

    return this.http.get<DistributionListResult[]>(
      `/services/cisco/macs/distribution-lists/search?${queryParams}&limit=${this.limit}`
    );
  }

  findByDevicePool(devicePool: string): Observable<SiteSearchResult[]> {
    return this.http.get<SiteSearchResult[]>(
      `/services/cisco/admin/sites/search?devicePool=${encodeURIComponent(devicePool)}`
    );
  }
}
