<div class="row" [hidden]="state.hidden && (!forceShow || !state.defaultValue)">
  <div [attr.data-automation]="'navset-list-' + navItemAnimationState" class="col-12 col-md-3">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-pills" orientation="vertical">
      <li
        *ngFor="let item of fieldData; let index = index"
        [ngbNavItem]="index"
        [ngClass]="{ active: index === active, 'nav-item--custom': true, 'nav-item--active': index === active }"
        data-automation="navset-item"
        [attr.data-index]="index"
        (@addNavItem.start)="handleNavsetItemAnimationStateChange('animating')"
        (@addNavItem.done)="handleNavsetItemAnimationStateChange('rendered')"
        [@addNavItem]
      >
        <div
          [attr.data-automation]="'navset-item-link-' + index"
          ngbNavLink
          class="btn nav-item--custom__link"
          (click)="changeActiveTab(index)"
        >
          <div
            [attr.data-automation]="'navset-item-content-' + index"
            class="d-flex align-items-center nav-item--custom__link__content"
            [ngClass]="{ 'nav-item--custom__link__content--has-error': item.hasError }"
          >
            <div
              *ngIf="item.hasError && item.showValidation"
              data-automation="navset-item-error"
              class="ms-auto me-1 text-danger"
            >
              <i [ngClass]="[icons.EXCLAMATION, 'fa', 'fa-lg', 'animated', 'tada', 'infinite']"></i>
            </div>
            <div
              *ngIf="shouldShowBadge(item.fields[item.badgeId])"
              data-automation="navset-item-badge"
              class="badge bg-secondary me-1"
            >
              {{ item.fields[item.badgeId] }}
            </div>
            <div
              data-automation="navset-item-text"
              class="text-nowrap overflow-hidden text-truncate nav-item--custom__link__label"
              [ngClass]="{
                'nav-item--custom__link__label--regular': item.labelId ? item.fields[item.labelId] : item.defaultLabel
              }"
            >
              <span
                [ngClass]="{
                  'nav-item--custom__link__label--italic': isOneClickAddDropdown && !item.fields.name
                }"
              >
                {{ (item.labelId ? item.fields[item.labelId] : item.defaultLabel) || this.dropdownItems[0].label }}
              </span>
              <span class="nav-item--custom__link__label--bold">
                {{ item.fields?.destinationNumber }}
              </span>
            </div>
          </div>
          <div
            data-automation="navset-item-remove"
            class="nav-item--custom__link__close"
            (click)="close($event, index)"
          >
            <i [ngClass]="icons.REMOVE"></i>
          </div>
        </div>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    <div class="row mb-2">
      <div class="col">
        <div *ngIf="!isOneClickAddDropdown" ngbDropdown data-automation="dropdown" class="dropdown--custom w-100">
          <button
            ngbDropdownToggle
            [ngbTooltip]="tooltipMessage | translate"
            [disableTooltip]="!!dropdownItems.length || !tooltipMessage"
            data-automation="dropdown-button"
            class="dropdown--custom__button btn btn-outline-default w-100 text-start px-4 py-2"
          >
            {{ dropdownLabel | translate }}
            <i [attr.class]="icons.ADD"></i>
          </button>
          <div ngbDropdownMenu class="p-3 w-100">
            <smacs-filter-input
              (filterChanged)="onChanged($event)"
              [value]="searchFilter"
              [placeholder]="'tkey;navset.placeholder'"
              [dataAutomation]="'dropdown-search-input'"
            ></smacs-filter-input>

            <ul data-automation="dropdown-list" class="m-0 p-0">
              <li
                ngbDropdownItem
                *ngFor="let item of getFilteredList()"
                (click)="add($event, item)"
                data-automation="dropdown-list-item"
                class="nowrap overflow-hidden text-truncate dropdown--custom__list__item"
              >
                {{ item.label }}
              </li>
            </ul>
          </div>
        </div>

        <div
          *ngIf="isOneClickAddDropdown"
          [ngbTooltip]="'tkey;snr.snrdestination.add_button.tooltip' | translate"
          [disableTooltip]="this.fieldData.length !== 4"
          data-automation="dropdown"
          class="dropdown--custom w-100"
        >
          <button
            data-automation="dropdown-button"
            class="dropdown--custom__button btn btn-outline-default w-100 text-start px-4 py-2"
            (click)="add($event, dropdownItems[0])"
            [disabled]="this.fieldData.length === 4"
          >
            {{ dropdownLabel | translate }}
            <i [attr.class]="icons.ADD"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-md-9 position-relative overflow-hidden">
    <div [@messageAnimation] *ngIf="!fieldData?.length && emptyTemplateMessage">
      <div class="alert alert-info" data-automation="navset-empty-template-message">
        <i [attr.class]="icons.INFO"></i>
        <span class="ms-2">{{ emptyTemplateMessage | translate }}</span>
      </div>
    </div>
    <div [@inOutAnimation]="fieldData[index]?.id" *ngFor="let config of fieldConfiguration; let index = index">
      <smacs-navset-form-nav-items
        [@inOutState]="active === index ? 'show' : 'hide'"
        (@inOutState.start)="handleAnimationStart(index, active === index ? 'show' : 'hide')"
        (@inOutState.done)="handleAnimationEnd(index, active === index ? 'show' : 'hide')"
        [attr.data-automation]="active === index ? 'navset-content' : 'navset-content-hidden'"
        [formConfig]="config"
        [item]="fieldData[index]"
        (smacsFormsUpdate$)="handleItemUpdate($event, index)"
      ></smacs-navset-form-nav-items>
    </div>
  </div>
</div>
