<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div *ngIf="isZpcSyncWarningPresent">
  <legend data-automation="fieldset-header-legend">
    {{ 'tkey;shared.model.microsoft_teams_calling.ad_attributes.header' | translate }}
  </legend>
  <div class="alert alert-warning d-flex justify-content-between">
    <span>
      <i [attr.class]="smacsIcons.WARNING"></i>
      <strong data-automation="ad-attribute-warning-banner-text">
        {{ 'tkey;shared.model.microsoft_teams_calling.ad_attributes.warning_banner.text' | translate }}
      </strong>
    </span>
  </div>
</div>
<div *ngIf="isAnyAttributeVisible">
  <div *ngIf="!isLoading && !isZpcSyncWarningPresent">
    <ziro-helpdesk-ad-attributes-form
      (smacsFormsUpdate$)="onChildFormUpdate($event)"
      [formConfig]="formConfig"
      [attributeFields]="attributeFields"
      [initialEntity]="initialEntity"
    ></ziro-helpdesk-ad-attributes-form>
  </div>
</div>
