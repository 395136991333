import { Component, OnChanges, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { TranslateService } from '@ngx-translate/core';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { of } from 'rxjs';
import { isEqual } from 'lodash';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';

interface GenerateCSRPayload {
  country: string;
  state: string;
  locality: string;
  organization: string;
  organizationalUnit: string;
  commonName: string;
  subjectAlternativeNames: string[];
}

const validators = {
  isCountryCorrectLength: (val: string) =>
    val.length === 2 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
  isAlpha: (val: string) =>
    /^[a-zA-Z]+$/.test(val) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
};

enum ValidationMsg {
  INCORRECT_LENGTH = 'tkey;validators.global.error.length',
  INVALID_VALUE = 'tkey;validators.global.alpha.error',
}

@Component({
  selector: 'app-admin-generate-csr-form',
  templateUrl: './generate-csr-form.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class GenerateCsrFormComponent
  extends SmacsFormAbstractDirective<GenerateCSRPayload>
  implements OnInit, OnChanges, OnDestroy
{
  smacsIcons = SmacsIcons;

  fieldGroups = {
    textInputs: ['country', 'state', 'locality', 'organization', 'organizationalUnit', 'commonName'],
    multiTextInputs: ['subjectAlternativeNames'],
  };

  formConfig = {
    fields: {
      country: {
        label: 'tkey;certificate_management.generate_csr.distinguished_names.country.label',
        dataAutomation: 'admin-generate-csr-country',
        required: true,
        validation: [
          {
            validator: validators.isCountryCorrectLength,
            message: this.translateService.instant(ValidationMsg.INCORRECT_LENGTH, {
              maxlength: 2,
            }),
          },
          {
            validator: validators.isAlpha,
            message: this.translateService.instant(ValidationMsg.INVALID_VALUE),
          },
        ],
      },
      state: {
        label: 'tkey;certificate_management.generate_csr.distinguished_names.state.label',
        dataAutomation: 'admin-generate-csr-state',
        required: true,
      },
      locality: {
        label: 'tkey;certificate_management.generate_csr.distinguished_names.locality.label',
        dataAutomation: 'admin-generate-csr-locality',
        required: true,
      },
      organization: {
        label: 'tkey;certificate_management.generate_csr.distinguished_names.organization.label',
        dataAutomation: 'admin-generate-csr-organization',
        required: true,
      },
      organizationalUnit: {
        label: 'tkey;certificate_management.generate_csr.distinguished_names.organizational_unit.label',
        dataAutomation: 'admin-generate-csr-organizational-unit',
        required: true,
      },
      commonName: {
        label: 'tkey;certificate_management.generate_csr.distinguished_names.common_name.label',
        dataAutomation: 'admin-generate-csr-common-name',
        required: true,
      },
      subjectAlternativeNames: {
        label: 'tkey;certificate_management.generate_csr.distinguished_names.subject_alt_name.label',
        dataAutomation: 'admin-generate-csr-subject-alternative-names',
        autogeneration: {
          linkLabel: 'tkey;certificate_management.generate_csr.distinguished_names.subject_alt_name.autogenerate',
          generateValue: (val: any, ...otherVals: any) => otherVals,
          generatedMsg: 'tkey;certificate_management.generate_csr.distinguished_names.subject_alt_name.autogenerated',
          hidden: () => !this.checkSanEmpty(),
          injectValuesFromFields: ['commonName'],
        },
        required: true,
      },
    },
  } as SmacsFormConfig;

  constructor(private translateService: TranslateService, protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.entitySource.next({
      country: '',
      state: '',
      locality: '',
      organization: '',
      organizationalUnit: '',
      commonName: undefined,
      subjectAlternativeNames: [],
    });

    this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<GenerateCSRPayload>) => {
      if (
        (!isEqual(data.new.commonName, data.old.commonName) && data.new.subjectAlternativeNames.length === 0) ||
        (!isEqual(data.new.commonName, data.old.commonName) &&
          data.new.subjectAlternativeNames.length === 1 &&
          data.new.subjectAlternativeNames[0] === data.old.commonName)
      ) {
        this.formData.subjectAlternativeNames = [data.new.commonName];
        this.updateShadowValue$.next(this.formData.subjectAlternativeNames);
      }
    });
  }

  checkSanEmpty(): boolean {
    return !!(this.formData.subjectAlternativeNames.every((i: string) => i === '') && this.formData.commonName);
  }

  protected submit() {
    return of(null);
  }
}
