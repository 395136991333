import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Nvp } from '../models/nvp';

@Injectable()
export class AuditHeaderService {
  buildHeader(auditTags: Nvp[]): HttpHeaders {
    const auditTagString = auditTags.map((tag: Nvp) => `${tag.name}=${tag.value}`).join(';');
    return new HttpHeaders({ 'set-audits': auditTagString });
  }

  getHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.buildHeader(auditTags) } : {};
  }
}
