<div
  [attr.data-automation]="config.dataAutomation"
  class="mb-3 row form-group"
  [ngClass]="{ 'has-feedback': showValidation, 'smacs-forms-error': validationState === ValStates.INVALID }"
>
  <label [class]="'col-form-label col-3 smacs-forms-label text-lg-end'" [attr.for]="config.dataAutomation + '-input'">
    <span [innerHTML]="getLabel() | translate"></span>
  </label>
  <div class="align-items-center col-3" data-automation="pin-reset-input">
    <div [attr.data-automation]="config.dataAutomation + '-input'">
      <div class="col">
        <div class="d-flex">
          <div class="input-group">
            <input
              class="form-control"
              [disabled]="isPending"
              [type]="inputType"
              [attr.id]="config.dataAutomation + '-input'"
              #ngModelDir="ngModel"
              (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
              [(ngModel)]="fieldData"
              [placeholder]="placeholder | translate"
              data-automation="smacs-text-input"
              [autocomplete]="'new-password'"
            />
            <smacs-button
              [dataAutomation]="'pin-reset-confirm-button'"
              (clicked)="submit(fieldId)"
              [cssClass]="'input-btn-end'"
              [buttonStyle]="buttonStyles.PRIMARY"
              [isPending]="isPending"
              [iconPending]="smacsIcons.SAVING"
              [icon]="smacsIcons.REFRESH"
              [isDisabled]="!fieldData || validationState === ValStates.INVALID || isPending"
            ></smacs-button>
          </div>
        </div>
        <div class="smacs-forms-feedback-area">
          <div
            class="smacs-forms-validation-message"
            data-automation="smacs-forms-validation-message"
            [hidden]="!showValidation"
          >
            {{ validationMessage?.content | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
