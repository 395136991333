<div class="animated flipInY fast">
  <div class="animated infinite vibrate thumbnail tile tile-danger text-center">
    <div class="tile-description">
      <i class="{{ smacsIcons.DELETE_OUTLINE }}">
        <span class="font-bold">
          {{ 'tkey;dialogs.button.delete' | translate }}
        </span>
      </i>
      <div data-automation="service-tile-name">{{ serviceName | translate }}</div>
      <div data-automation="service-tile-description">{{ description | translate }}</div>
      <fieldset [disabled]="isDeleting" class="mt-2">
        <smacs-button
          [cssClass]="'me-1'"
          [label]="'tkey;dialogs.button.cancel'"
          [buttonStyle]="buttonStyles.DEFAULT"
          [buttonSize]="buttonSizes.SMALL"
          [isDisabled]="isDeleting"
          (clicked)="onCancelClicked()"
          [attr.data-automation]="'delete-cancel'"
        ></smacs-button>
        <smacs-button
          [label]="'tkey;dialogs.button.delete'"
          [buttonStyle]="buttonStyles.DANGER"
          [buttonSize]="buttonSizes.SMALL"
          [isPending]="isDeleting"
          [iconPending]="smacsIcons.SAVING"
          [isDisabled]="isDeleting"
          (clicked)="onConfirmClicked()"
          [attr.data-automation]="'delete-confirm'"
        ></smacs-button>
      </fieldset>
    </div>
  </div>
</div>
