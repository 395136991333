import { Component, OnDestroy, OnInit } from '@angular/core';
import { BackupResource } from '../../resources/backup.resource';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { combineLatest, Subscription } from 'rxjs';
import { GlobalPropertiesContext } from '../../../shared/contexts/global-properties.context';

@Component({
  selector: 'app-admin-backup',
  templateUrl: './backups.component.html',
  styleUrls: ['./backups.component.scss', '../../admin-page.scss'],
  providers: [BackupResource],
})
export class BackupsComponent implements OnInit, OnDestroy {
  currentBackupUrl = `${window.location.origin}/services/system/admin/backups/current`;
  isLoading = true;
  isHostedEnabled: boolean;
  dailyBackupFileNames: string[];
  weeklyBackupFileNames: string[];
  smacsIcons = SmacsIcons;
  private _subscriptions = new Subscription();

  constructor(
    private backupResource: BackupResource,
    private breadcrumbsService: BreadcrumbsService,
    private globalPropertiesContext: GlobalPropertiesContext
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.backup.title' }]);

    const sub = combineLatest([
      this.globalPropertiesContext.state$,
      this.backupResource.getDailyBackups(),
      this.backupResource.getWeeklyBackups(),
    ]).subscribe(([globalProperties, dailyBackup, weeklyBackup]) => {
      this.isHostedEnabled = globalProperties.hostedEnabled;
      this.dailyBackupFileNames = dailyBackup;
      this.weeklyBackupFileNames = weeklyBackup;
      this.isLoading = false;
    });

    this._subscriptions.add(sub);
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }
}
