<smacs-fieldset-header [legend]="'tkey;shared.dnWizard.dnDetails.extensionDetails.header'">
  <smacs-text [fieldId]="'description'"></smacs-text>
  <smacs-checkbox [fieldId]="'urgentPriority'"></smacs-checkbox>
  <smacs-select [fieldId]="'classOfService'"></smacs-select>
  <smacs-text [fieldId]="'alertingName'"></smacs-text>
  <smacs-select [fieldId]="'routePartition'"></smacs-select>
  <smacs-select [fieldId]="'callPickupGroupName'"></smacs-select>
  <smacs-select [fieldId]="'voicemailProfile'"></smacs-select>
  <smacs-select [fieldId]="'lineGroups'"></smacs-select>
  <smacs-select [fieldId]="'autoAnswer'"></smacs-select>
</smacs-fieldset-header>
