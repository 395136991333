import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { AnimationItem } from 'lottie-web';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'ziro-self-serve-card',
  templateUrl: './self-serve-card.component.html',
  styleUrls: ['./self-serve-card.component.scss'],
})
export class SelfServeCardComponent implements OnInit, OnDestroy {
  @Input() dataAutomation: string;
  @Input() routerLink: string;
  @Input() header: string;
  @Input() description: string;
  @Input() displayBadgePrimary = false;
  @Input() displayBadgeSecondary = false;
  @Input() badgeSecondaryCssClass = 'bg-secondary';
  @Input() lottieFile: string;

  smacsIcons = SmacsIcons;
  animation: AnimationItem;
  isLottieReady = false;
  animationOptions = {
    speed: 1,
    renderer: 'svg',
    loop: true,
    autoplay: false,
  } as AnimationOptions;

  private _subscriptions = new Subscription();

  constructor() {}

  ngOnInit() {
    this.animationOptions = {
      ...this.animationOptions,
      animationData: this.lottieFile,
    } as AnimationOptions;
    this.isLottieReady = true;
  }

  ngOnDestroy = () => {
    this._subscriptions.unsubscribe();
  };

  onAnimationCreated(event: AnimationItem) {
    this.animation = event;
  }

  onMouseOver() {
    this.animation?.play();
  }

  onMouseLeave() {
    this.animation?.stop();
  }
}
