import { Component, Input, Output } from '@angular/core';
import { ExtensionRange } from '../smacs-extension-range-models';
import { Subject } from 'rxjs';
import { SmacsFormsUpdate } from '../../../smacs-forms-models';
import { isEqual } from 'lodash';
import { ExtensionRangeValidationResult } from '../smacs-extension-ranges-validation.ui-context';

/**
 * Using a non smacs-forms component in order to play nicely with virtual scroll
 */
@Component({
  selector: 'smacs-single-extension-range-container',
  templateUrl: './smacs-single-extension-range-container.component.html',
})
export class SmacsSingleExtensionRangeContainerComponent {
  @Input() entity: ExtensionRange;
  @Input() index: number;
  @Input() externalValidation: ExtensionRangeValidationResult;
  @Input() isFormSubmitted: boolean;
  @Input() ranges: ExtensionRange[];
  @Input() isDisabled = false;

  @Output() singleExtensionRangeChange = new Subject<any>();

  updateSelf($event: SmacsFormsUpdate<ExtensionRange>) {
    if (!isEqual($event.new, $event.old)) {
      this.singleExtensionRangeChange.next({
        index: this.index,
        $event: $event,
      });
    }
  }
}
