import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../../forms/smacs-forms-models';
import { LineFeature, LineFeatureFieldConfig } from '../../../../models/generated/smacsModels';
import { SmacsSelectConfig } from '../../../../../forms/fields/select/smacs-select.component';
import { HtmlInputType, SmacsTextConfig } from '../../../../../forms/fields/text/smacs-text.component';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { PhoneButtonsService } from '../../../shared/phone-buttons.service';

@Component({
  selector: 'smacs-phone-buttons-line-helpdesk-form',
  templateUrl: './phone-buttons-line-helpdesk-form.component.html',
})
export class PhoneButtonsLineHelpdeskFormComponent
  extends SmacsFormAbstractDirective<LineFeature>
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() fieldConfig: LineFeatureFieldConfig;
  @Input() extension: string;
  @Input() isUccxAgentLine: boolean;

  isLoading = true;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private phoneButtonsService: PhoneButtonsService,
    private translateService: TranslateService
  ) {
    super(smacsFormStateService);
  }

  fieldGroups = {
    textInputs: ['label', 'internalCallerId', 'externalCallerId', 'externalCallerIdNumber'],
    ringInputs: ['ringIdle', 'ringActive'],
    callRecordingInputs: ['callRecordingOption', 'callRecordingProfile', 'callRecordingMediaSource'],
    numberInputs: ['maxNumberOfCalls', 'busyTrigger'],
  };
  formConfig: SmacsFormConfig;

  ngOnInit() {
    this.formConfig = {
      fields: {
        label: {
          label: 'tkey;pages.details.phoneSettings.button.line.label',
          defaultValue: () => this.fieldConfig.label.defaultValue,
          hidden: () => !this.fieldConfig.label.show,
          required: this.fieldConfig.label.required,
          valExcluded: () => !this.fieldConfig.label.show,
          dataAutomation: 'phone-button-line-label',
          validation: [
            {
              validator: (val: string) =>
                this.phoneButtonsService.validLabelLength(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: {
                content: 'tkey;validators.global.error.maxlength',
                params: {
                  maxlength: 30,
                },
              },
            },
            {
              validator: (val: string) =>
                this.phoneButtonsService.validLineLabelPattern(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;validators.global.error.pattern',
            },
          ],
        },
        externalCallerId: {
          label: 'tkey;pages.details.phoneSettings.button.line.callerid.external',
          dataAutomation: 'phone-button-line-caller-external',
          defaultValue: () => this.fieldConfig.externalCallerId.defaultValue,
          hidden: () => !this.fieldConfig.externalCallerId.show,
          required: this.fieldConfig.externalCallerId.required,
          valExcluded: () => !this.fieldConfig.externalCallerId.show,
          validation: [
            {
              validator: (val: string) =>
                this.phoneButtonsService.validLabelLength(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: {
                content: 'tkey;validators.global.error.maxlength',
                params: {
                  maxlength: 30,
                },
              },
            },
            {
              validator: (val: string) =>
                this.phoneButtonsService.validSpeedDialLabelPattern(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;validators.global.error.pattern',
            },
          ],
        },
        internalCallerId: {
          label: 'tkey;pages.details.phoneSettings.button.line.callerid.internal',
          dataAutomation: 'phone-button-line-caller-internal',
          defaultValue: () => this.fieldConfig.internalCallerId.defaultValue,
          hidden: () => !this.fieldConfig.internalCallerId.show,
          required: this.fieldConfig.internalCallerId.required,
          valExcluded: () => !this.fieldConfig.internalCallerId.show,
          validation: [
            {
              validator: (val: string) =>
                this.phoneButtonsService.validLabelLength(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: {
                content: 'tkey;validators.global.error.maxlength',
                params: {
                  maxlength: 30,
                },
              },
            },
            {
              validator: (val: string) =>
                this.phoneButtonsService.validInternalCallerIdPattern(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;validators.global.error.pattern',
            },
          ],
        },
        externalCallerIdNumber: {
          label: 'tkey;pages.details.phoneSettings.button.line.external_phone_number_mask.label',
          dataAutomation: 'phone-button-line-caller-id-numeric',
          hidden: () => !this.fieldConfig.externalCallerIdNumber.show,
          defaultValue: () => this.fieldConfig.externalCallerIdNumber.defaultValue,
          required: this.fieldConfig.externalCallerIdNumber.required,
          valExcluded: () => !this.fieldConfig.externalCallerIdNumber.show,
          validation: [
            {
              validator: (val: string) =>
                this.phoneButtonsService.validNumberMaskPattern(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;validators.global.error.pattern',
            },
          ],
        },
        ringIdle: {
          label: 'tkey;pages.details.phoneSettings.button.line.ring.idle',
          dataAutomation: 'phone-button-line-ring-idle',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.ringIdle.possibleOptions }),
          required: this.fieldConfig.ringIdle.required,
          defaultValue: () => this.fieldConfig.ringIdle.defaultValue,
          hidden: () => !this.fieldConfig.ringIdle.show,
        },
        ringActive: {
          label: 'tkey;pages.details.phoneSettings.button.line.ring.active',
          dataAutomation: 'phone-button-line-ring-active',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.ringActive.possibleOptions }),
          required: this.fieldConfig.ringActive.required,
          defaultValue: () => this.fieldConfig.ringActive.defaultValue,
          hidden: () => !this.fieldConfig.ringActive.show,
        },
        maxNumberOfCalls: {
          label: 'tkey;pages.details.phoneSettings.button.line.max.number.calls',
          dataAutomation: 'phone-button-line-max-calls',
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.NUMBER,
            min: 1,
          }),
          required: this.fieldConfig.maxNumberOfCalls.required,
          hidden: () => !this.fieldConfig.maxNumberOfCalls.show,
          validation: [
            {
              validator: (val: number) =>
                this.phoneButtonsService.validMaxNumberOfCallsMaximum(
                  val,
                  this.fieldConfig?.maxNumberOfCalls?.possibleOptions
                )
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: {
                content: 'tkey;validators.global.error.max',
                params: {
                  maxValue:
                    this.fieldConfig.maxNumberOfCalls.possibleOptions[
                      this.fieldConfig.maxNumberOfCalls.possibleOptions.length - 1
                    ],
                },
              },
            },
            {
              validator: (val: number) =>
                this.phoneButtonsService.validMaxNumberOfCallsAgent(val, this.isUccxAgentLine)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;validators.buttons.agent.max.calls',
            },
            {
              validator: (val: number) =>
                this.phoneButtonsService.validWholeNumber(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: {
                content: 'tkey;validators.global.error.min',
                params: {
                  minValue: 1,
                },
              },
            },
            {
              validator: (val: number, val2: number) =>
                this.phoneButtonsService.validMaxNumberOfCallsGreaterThanBusyTrigger(val, val2)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: {
                content: 'tkey;validators.global.error.min.fieldname',
                params: {
                  fieldName: this.translateService.instant('tkey;pages.details.phoneSettings.button.line.busy.trigger'),
                },
              },
              injectValuesFromFields: ['busyTrigger'],
            },
          ],
        },
        busyTrigger: {
          label: 'tkey;pages.details.phoneSettings.button.line.busy.trigger',
          dataAutomation: 'phone-button-line-busy-trigger',
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.NUMBER,
            min: 1,
          }),
          required: this.fieldConfig.busyTrigger.required,
          hidden: () => !this.fieldConfig.busyTrigger.show,
          validation: [
            {
              validator: (val: number) =>
                this.phoneButtonsService.validBusyTriggerAgent(val, this.isUccxAgentLine)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;validators.buttons.agent.busy.trigger',
            },
            {
              validator: (val: number) =>
                this.phoneButtonsService.validWholeNumber(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: {
                content: 'tkey;validators.global.error.min',
                params: {
                  minValue: 1,
                },
              },
            },
            {
              validator: (val: number, val2: number) =>
                this.phoneButtonsService.validMaxNumberOfCallsGreaterThanBusyTrigger(val2, val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: {
                content: 'tkey;validators.global.error.max.fieldname',
                params: {
                  fieldName: this.translateService.instant(
                    'tkey;pages.details.phoneSettings.button.line.max.number.calls'
                  ),
                },
              },
              injectValuesFromFields: ['maxNumberOfCalls'],
            },
          ],
        },
        callRecordingOption: {
          label: 'tkey;pages.details.phoneSettings.button.line.callrecording.option',
          dataAutomation: 'phone-button-line-call-recording-option',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.callRecordingOption.possibleOptions }),
          required: this.fieldConfig.callRecordingOption.required,
          hidden: () => !this.fieldConfig.callRecordingOption.show,
          defaultValue: () => this.fieldConfig.callRecordingOption.defaultValue,
        },
        callRecordingProfile: {
          label: 'tkey;pages.details.phoneSettings.button.line.callrecording.profile',
          dataAutomation: 'phone-button-line-call-recording-profile',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.callRecordingProfile.possibleOptions }),
          required: this.fieldConfig.callRecordingProfile.required,
          hidden: () => !this.fieldConfig.callRecordingProfile.show,
          defaultValue: () => this.fieldConfig.callRecordingProfile.defaultValue,
        },
        callRecordingMediaSource: {
          label: 'tkey;pages.details.phoneSettings.button.line.callrecording.media_source.label',
          dataAutomation: 'phone-button-line-call-recording-media-source',
          componentConfig: new SmacsSelectConfig({
            options: this.fieldConfig.callRecordingMediaSource.possibleOptions,
          }),
          required: this.fieldConfig.callRecordingMediaSource.required,
          hidden: () => !this.fieldConfig.callRecordingMediaSource.show,
          defaultValue: () => this.fieldConfig.callRecordingMediaSource.defaultValue,
        },
        monitoringCssName: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.monitoring_css.label',
          dataAutomation: 'phone-button-monitoring-css',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.monitoringCssName.possibleOptions }),
          required: this.fieldConfig.monitoringCssName.required,
          hidden: () => !this.fieldConfig.monitoringCssName.show,
          defaultValue: () => this.fieldConfig.monitoringCssName.defaultValue,
        },
      },
      options: {
        columnClasses: {
          label: 'col-3 text-lg-end',
          input: 'col-9 text-lg-start',
        },
      },
    };
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    setTimeout(() => {
      this.isLoading = false;
    }, 0);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.entity && !changes.entity.firstChange && !this.isFormValid()) {
      // Force validation if entity has been changed and the form is invalid.
      this._reevaluateFields();
    }
    super.ngOnChanges(changes);
  }

  submit() {
    return this.parentSubmit$;
  }

  private _reevaluateFields() {
    this.fieldComponents.forEach((fieldComponent) => {
      fieldComponent.showValidation = true;
    });
    Object.keys(this.fieldChannels).forEach((key: string) => {
      this.fieldChannels[key].validateSource.next();
    });
  }
}
