import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { clone } from 'lodash';
import * as jsondiffpatch from 'jsondiffpatch';
// @ts-ignore
import * as htmlFormatter from 'jsondiffpatch/formatters/html';
import { Audit } from '../../../shared/models/generated/smacsModels';
import { CustomModal } from '../../../shared/services/smacs-modal.service';

interface AuditViewJsonModalViewProperties {
  audit: Audit;
}

@Component({
  selector: 'app-audit-json-view-modal',
  templateUrl: './audit-json-view-modal.component.html',
  styleUrls: ['./audit-json-view-modal.component.scss'],
})
export class AuditViewJsonModalComponent implements CustomModal<AuditViewJsonModalViewProperties>, OnInit {
  modalProperties: AuditViewJsonModalViewProperties;
  audit: Audit;
  showAllFields = false;
  isUnifiedDiff = true;
  singleJsonView: string;
  unifiedDiff: string;
  splitLeft: string;
  splitRight: string;

  // This key is used to artificially created a diff, then remove it, so that the displayed UI is the same for diffs
  // and single-entries.
  BOGUS_KEY = 'zNotUsed_deleteMe';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.audit = this.modalProperties.audit;

    const beforeJson = this.audit.beforeJson;
    const afterJson = this.audit.afterJson;

    // Single audit view
    if (!beforeJson || !afterJson) {
      this.showAllFields = true;
      const left = JSON.parse(beforeJson || afterJson);
      const right = clone(left);
      right[this.BOGUS_KEY] = '';
      this.singleJsonView = this.generateHtml(left, right);
    } else {
      this.setupUnifiedDiffView();
    }

    this.setUnchangedView();
  }

  onOkClick(): void {
    this.activeModal.close(true);
  }

  toggleUnchanged(): void {
    this.setUnchangedView();
  }

  toggleViewType(): void {
    if (this.isUnifiedDiff) {
      this.splitLeft = null;
      this.splitRight = null;
      this.setupUnifiedDiffView();
    } else {
      this.unifiedDiff = null;
      this.setupSplitDiffView();
    }
  }

  private setUnchangedView(): void {
    if (this.showAllFields) {
      htmlFormatter.showUnchanged();
    } else {
      htmlFormatter.hideUnchanged();
    }
  }

  private setupUnifiedDiffView(): void {
    const left = JSON.parse(this.audit.beforeJson);
    const right = JSON.parse(this.audit.afterJson);

    this.unifiedDiff = this.generateHtml(left, right);
  }

  private setupSplitDiffView(): void {
    let left = JSON.parse(this.audit.beforeJson);
    let right = clone(left);

    right[this.BOGUS_KEY] = '';
    this.splitLeft = this.generateHtml(left, right);

    left = JSON.parse(this.audit.beforeJson);
    right = JSON.parse(this.audit.afterJson);
    this.splitRight = this.generateHtml(left, right);
  }

  private generateHtml(left: any, right: any): string {
    const delta = jsondiffpatch
      .create({
        objectHash: (obj: object, index: number): string => {
          if ('id' in obj) {
            return obj.id as string;
          } else if ('skuId' in obj) {
            return obj.skuId as string;
          } else {
            return '$$index:' + index;
          }
        },
      })
      .diff(left, right);

    if (delta) {
      return htmlFormatter
        .format(delta, left)
        .replace(
          `<li class="jsondiffpatch-added" data-key="zNotUsed_deleteMe"><div class="jsondiffpatch-property-name">${this.BOGUS_KEY}</div><div class="jsondiffpatch-value"><pre>&quot;&quot;</pre></div></li>`,
          ''
        );
    } else {
      return `<pre class="modal-body-display-no-changes">` + this.audit.beforeJson + `</pre>`;
    }
  }
}
