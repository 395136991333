import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { NumberPortabilityRequest, NumberPortabilityResponse } from '../../../shared/models/generated/smacsModels';
import { PortabilityRow } from './check-number-portability.component';

export interface GroupedRows {
  [key: string]: PortabilityRow;
}

@Injectable()
export class NumberPortabilitiesContext {
  groupedRowsStateSource = new BehaviorSubject<GroupedRows>({});
  groupedRowsState$ = this.groupedRowsStateSource.asObservable();

  private _url = '/services/microsoft/orders/number-portabilities';

  constructor(private _http: HttpClient) {}

  checkPortabilities(numbers: NumberPortabilityRequest): Observable<NumberPortabilityResponse> {
    return this._http.post<NumberPortabilityResponse>(this._url, numbers);
  }
}
