<app-self-serve-top-bar></app-self-serve-top-bar>

<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div [hidden]="isLoading">
  <app-self-serve-user-details-back
    [link]="'/self-serve/user/' + user?.ref.username"
  ></app-self-serve-user-details-back>

  <app-self-serve-user-details-header
    [heading]="headerTranslationKey | translate"
    [headingDataAutomation]="headingDataAutomation"
  ></app-self-serve-user-details-header>

  <smacs-text [fieldId]="'pin'"></smacs-text>
  <smacs-text [fieldId]="'pinConfirm'"></smacs-text>

  <div class="col-sm-12 text-end">
    <smacs-submit-button
      [isPending]="isSubmitting"
      [isDisabled]="isSubmitting"
      [cssClass]="'me-2'"
      [icon]="smacsIcons.REFRESH"
      [dataAutomation]="'self-serve-pin-reset-button'"
      [label]="'tkey;selfserve.user.reset.button'"
      [iconPending]="smacsIcons.SAVING"
      [formSubmitSubject]="_validateAndSubmitSource"
    ></smacs-submit-button>
  </div>
</div>
