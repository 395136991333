<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item [aboutCardId]="52"></app-breadcrumb-card-item>
    </app-breadcrumbs>
    <div class="card mt-3">
      <div class="card-body">
        <smacs-text [fieldId]="'secret'">
          <smacs-button
            *ngIf="proxyKey"
            [dataAutomation]="'generate-key-copy-button'"
            [buttonStyle]="null"
            [cssClass]="'btn-input-copy'"
            [cdkCopyToClipboard]="proxyKey"
            [isDisabled]="!entity?.secret"
            [ngbTooltip]="'tkey;admin.webex_authentication.copy_button_clicked' | translate"
            [disableTooltip]="disableCopiedUriTooltip"
            [icon]="smacsIcons.COPY"
            (clicked)="onCopyUriClick()"
          ></smacs-button>
        </smacs-text>
      </div>
    </div>
    <div
      *ngIf="healthStatusAlertCss"
      class="mt-3 alert"
      data-automation="generate-key-health-status"
      [ngClass]="healthStatusAlertCss"
      [innerHTML]="healthStatusAlertText | translate"
    ></div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
