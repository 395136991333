<div [attr.data-automation]="config.dataAutomation">
  <div class="row">
    <div class="col-12">
      <legend>{{ getLabel() | translate }}</legend>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <div
        *ngIf="isNoNumbersRemaining"
        data-automation="extension-selector-no-remaining-alert"
        class="alert alert-warning"
      >
        {{ 'tkey;dndidselection.nomoredns' | translate }}
      </div>
      <div
        *ngIf="filteredOptions.length === 0 && !isNoNumbersRemaining"
        data-automation="extension-selector-no-matches-alert"
        class="alert alert-warning"
      >
        {{ 'tkey;dnselection.no_matches' | translate }}
      </div>
      <div
        *ngIf="!!validationMessage?.content && showValidation"
        data-automation="extension-selector-validation"
        class="alert alert-danger"
        [innerHTML]="validationMessage.content | translate : validationMessage?.params"
      ></div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <smacs-filter-input
        (filterChanged)="onFilterChanged($event)"
        [placeholder]="'tkey;shared.extensionSelector.filter.placeholder'"
        [dataAutomation]="'extension-selector-filter-input'"
        [isDisabled]="isSubmitting"
      ></smacs-filter-input>
    </div>
  </div>

  <div class="row my-3 extension-row">
    <input [type]="'hidden'" [(ngModel)]="fieldData" [attr.data-automation]="config.dataAutomation + '-hidden-input'" />

    <ng-template #tipContent let-option="option">
      <span [innerHtml]="option?.tooltip ? (option.tooltip.message | translate : option.tooltip?.params) : ''"></span>
    </ng-template>

    <div
      *ngFor="let option of displayedFilteredOptions; let i = index"
      class="extension-cell"
      [ngClass]="{
        'col-md-4 col-lg-2 col-sm-4': size === ExtensionSelectorSize.LARGE,
        'col-md-6 col-lg-4 col-sm-4': size === ExtensionSelectorSize.SMALL
      }"
      [attr.data-automation]="'extension-selector-option'"
      [ngbTooltip]="tipContent"
      [tooltipContext]="{option}"
      [container]="'body'"
      [disableTooltip]="!option.tooltip"
    >
      <button
        type="button"
        [ngClass]="{
          'btn-dark animated bounceIn faster': option.value === fieldData,
          'btn-outline-default': option.value !== fieldData,
          'btn-outline-danger':
            option.assignmentStatus === DialPlanGroupEntryStatus.ASSIGNED ||
            option.assignmentStatus === DialPlanGroupEntryStatus.RESTRICTED ||
            option.assignmentStatus === DialPlanGroupEntryStatus.TEAMS_UNASSIGNED_NUMBER_ROUTING_RULE ||
            option.isDisabled
        }"
        [disabled]="
          state.disabled ||
          option.assignmentStatus === DialPlanGroupEntryStatus.ASSIGNED ||
          option.assignmentStatus === DialPlanGroupEntryStatus.RESTRICTED ||
          option.assignmentStatus === DialPlanGroupEntryStatus.TEAMS_UNASSIGNED_NUMBER_ROUTING_RULE ||
          option.isDisabled
        "
        (click)="onOptionSelected(option.value)"
        class="btn"
      >
        <span>
          <i *ngIf="option.deletionDate" [attr.class]="smacsIcons.HISTORY + ' me-1'"></i>
          <i
            *ngIf="option.assignmentStatus === DialPlanGroupEntryStatus.INACTIVE"
            [attr.class]="smacsIcons.INACTIVE + ' me-1'"
          ></i>
          <i
            *ngIf="
              option.assignmentStatus === DialPlanGroupEntryStatus.ASSIGNED ||
              option.assignmentStatus === DialPlanGroupEntryStatus.RESTRICTED ||
              option.assignmentStatus === DialPlanGroupEntryStatus.TEAMS_UNASSIGNED_NUMBER_ROUTING_RULE ||
              option.isDisabled
            "
            [attr.class]="smacsIcons.UNAVAILABLE + ' me-1'"
          ></i>
          <span data-automation="extension-selector-option-value">
            {{ option.value | telephoneNumberFilter }}
          </span>
        </span>
      </button>
    </div>
  </div>

  <smacs-pagination
    [(page)]="page"
    [pageSize]="PAGE_SIZE"
    [totalResults]="filteredOptions.length"
    [disabled]="state.disabled"
    [dataAutomation]="'smacs-extension-selector-pagination'"
    (pageChanged)="onPageChange()"
  ></smacs-pagination>
</div>
