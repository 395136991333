import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsSelectComponent, SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import {
  SmacsFieldComponentConfig,
  SmacsFormConfig,
  SmacsFormsValidationState,
} from '../../../../forms/smacs-forms-models';
import {
  CustomCheckboxFieldConfig,
  CustomMultiSelectFieldConfig,
  SelectFieldConfig,
  TextFieldConfig,
} from '../../../../shared/models/generated/smacsModels';
import { of, Subscription } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { TranslateService } from '@ngx-translate/core';

export interface DnDetailsFormEntity {
  description: string;
  urgentPriority: boolean;
  classOfService: string;
  alertingName: string;
  routePartition: string;
  callPickupGroupName: string;
  voicemailProfile: string;
  lineGroups: string[];
  autoAnswer: string;
}

export interface DnDetailsFieldConfig {
  description: TextFieldConfig;
  urgentPriority: CustomCheckboxFieldConfig;
  classOfService: SelectFieldConfig<string>;
  alertingName: TextFieldConfig;
  routePartition: SelectFieldConfig<string>;
  callPickupGroupName: SelectFieldConfig<string>;
  voicemailProfile: SelectFieldConfig<string>;
  lineGroups: CustomMultiSelectFieldConfig;
  autoAnswer: SelectFieldConfig<string>;
}

@Component({
  selector: 'smacs-directory-number-details-form',
  templateUrl: './directory-number-details-form.component.html',
})
export class SmacsDirectoryNumberDetailsFormComponent
  extends SmacsFormAbstractDirective<DnDetailsFormEntity>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() fieldConfig: DnDetailsFieldConfig;
  formConfig: SmacsFormConfig;
  private _subscribers = new Subscription();

  constructor(protected smacsFormStateService: SmacsFormStateService, private _translateService: TranslateService) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    const smacsFormsUpdate = this.smacsFormsUpdate$.subscribe(() => {
      if (this.fieldComponents.length) {
        this.reevaluateFields();
      }
    });

    this._subscribers.add(smacsFormsUpdate);

    this._initFormConfig();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.fieldConfig?.currentValue && this.fieldComponents?.length) {
      setTimeout(() => {
        this.reevaluateFields();
      });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscribers.unsubscribe();
  }

  protected submit() {
    return of(null);
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        description: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.extensionDescription.label',
          dataAutomation: 'dn-detail-description',
          hidden: () => !this.fieldConfig.description.show,
          required: () => this.fieldConfig.description.required,
          defaultValue: () => this.fieldConfig.description.defaultValue,
          validation: [
            {
              validator: (val: string) => {
                return val.length > 50 ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
              },
              message: this._translateService.instant('tkey;validators.global.error.maxlength', { maxlength: 50 }),
            },
          ],
        },
        urgentPriority: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.urgent_priority.label',
          dataAutomation: 'dn-detail-urgent-priority',
          hidden: () => !this.fieldConfig.urgentPriority.show,
          defaultValue: () => this.fieldConfig.urgentPriority.defaultValue,
        },
        classOfService: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.classOfService.label',
          dataAutomation: 'dn-detail-class-of-service',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.classOfService.possibleOptions }),
          hidden: () => !this.fieldConfig.classOfService.show,
          required: () => this.fieldConfig.classOfService.required,
          defaultValue: () => this.fieldConfig.classOfService.defaultValue,
        },
        alertingName: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.alertingName.label',
          dataAutomation: 'dn-detail-alerting-name',
          hidden: () => !this.fieldConfig.alertingName.show,
          required: () => this.fieldConfig.alertingName.required,
          defaultValue: () => this.fieldConfig.alertingName.defaultValue,
          validation: [
            {
              validator: (val) =>
                !val || val.length <= 50 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
              message: {
                content: 'tkey;global.validation.max_length',
                params: {
                  maxLength: '50',
                },
              },
            },
          ],
        },
        routePartition: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.route_partition.label',
          dataAutomation: 'dn-detail-route-partition',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.routePartition.possibleOptions }),
          hidden: () => !this.fieldConfig.routePartition.show,
          required: () => this.fieldConfig.routePartition.required,
          defaultValue: () => this.fieldConfig.routePartition.defaultValue,
        },
        callPickupGroupName: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.callPickupGroup.label',
          dataAutomation: 'dn-detail-call-pickup-group-name',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.callPickupGroupName.possibleOptions }),
          hidden: () => !this.fieldConfig.callPickupGroupName.show,
          required: () => this.fieldConfig.callPickupGroupName.required,
          defaultValue: () => this.fieldConfig.callPickupGroupName.defaultValue,
        },
        voicemailProfile: {
          label: 'tkey;shared.dnWizard.dnDetails.dnVoicemail.voicemailProfile.label',
          dataAutomation: 'dn-detail-voicemail-profile',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.voicemailProfile.possibleOptions }),
          hidden: () => !this.fieldConfig.voicemailProfile.show,
          required: () => this.fieldConfig.voicemailProfile.required,
          defaultValue: () => this.fieldConfig.voicemailProfile.defaultValue,
        },
        lineGroups: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.associated_line_groups.label',
          dataAutomation: 'dn-detail-line-groups',
          componentConfig: new SmacsSelectConfig({
            options: this.fieldConfig.lineGroups.possibleOptions,
            isMultiSelect: true,
          }),
          hidden: () => !this.fieldConfig.lineGroups.show,
          required: () => this.fieldConfig.lineGroups.required,
          defaultValue: () => this.fieldConfig.lineGroups.defaultValues,
        },
        autoAnswer: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.auto_answer.label',
          dataAutomation: 'dn-detail-auto-answer',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.autoAnswer.possibleOptions }),
          hidden: () => !this.fieldConfig.autoAnswer.show,
          required: () => this.fieldConfig.autoAnswer.required,
          defaultValue: () => this.fieldConfig.autoAnswer.defaultValue,
        },
      },
    };
  }

  reevaluateFields() {
    const fieldComponents = new Map(
      this.fieldComponents.map((fieldComponent) => [fieldComponent.fieldId, fieldComponent])
    );
    fieldComponents.forEach((fieldComponent: SmacsSelectComponent, fieldId: keyof DnDetailsFieldConfig) => {
      const config: SmacsFieldComponentConfig = fieldComponent.config.componentConfig;
      fieldComponent.forceShow = false;
      if (config instanceof SmacsSelectConfig) {
        if (fieldId === 'lineGroups') {
          fieldComponent.applyComponentConfig(
            new SmacsSelectConfig({
              options: this.fieldConfig.lineGroups.possibleOptions,
              isMultiSelect: true,
            })
          );
        } else {
          fieldComponent.applyComponentConfig(
            new SmacsSelectConfig({ options: (this.fieldConfig[fieldId] as SelectFieldConfig<string>).possibleOptions })
          );
        }
      }
      this.fieldChannels[fieldId].validateSource.next();
    });
  }
}
