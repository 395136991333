import { Pipe, PipeTransform } from '@angular/core';
import { UserPermissionsContext } from '../../contexts/user-permissions.context';
import { Role } from '../../../shared/models/generated/smacsModels';

@Pipe({ name: 'userPermissionsRolePipe' })
export class UserPermissionsRolePipe implements PipeTransform {
  transform(role: string): string {
    return UserPermissionsContext.getStringFromApiRole(role as Role);
  }
}
