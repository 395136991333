import { Component, EventEmitter, HostBinding, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import DragOverEvent = JQuery.DragOverEvent;
import DragLeaveEvent = JQuery.DragLeaveEvent;

@Component({
  selector: 'app-saml-import-btn',
  templateUrl: './saml-import-button.component.html',
  styleUrls: ['./saml-import-button.component.scss'],
})
export class SamlImportButtonComponent {
  smacsIcons = SmacsIcons;
  @ViewChild('hiddenInput') fileInput: HTMLElement;
  @HostBinding('style.opacity') private opacity = '1';
  @Input() uploadEvent: (formData: FormData) => Observable<any>;
  @Input() fileExtension = '';
  @Input() buttonStyle = 'btn-primary';
  @Input() dataAutomation = '';
  @Input() iconPending = this.smacsIcons.SAVING;
  @Input() icon = this.smacsIcons.DOWNLOAD;
  @Input() label = '';
  @Input() isDisabled = false;
  @Output() invalidTypeError = new EventEmitter<boolean>();
  @Output() successfulImport = new EventEmitter<boolean>();
  uploadedFile: File = null;
  isPending = false;

  @HostListener('dragover', ['$event']) onDragOver(event: DragOverEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.opacity = '0.8';
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragLeaveEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.opacity = '1';
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.opacity = '1';
    this.uploadFile(event.dataTransfer.files);
  }

  uploadFile(files: FileList) {
    const file = files[0] as File;
    if (file) {
      if (!this._verifyFileExtension(file)) {
        this.invalidTypeError.emit(true);
        this.uploadedFile = null;
        return;
      }
      this.isPending = true;
      const formData = new FormData();
      formData.append('upload', file, file.name);
      this.uploadEvent(formData).subscribe(() => {
        this.isPending = false;
        this.successfulImport.emit(true);
      });
    }
    this.uploadedFile = null;
  }

  private _verifyFileExtension(file: File): boolean {
    const fileExtension = file.name.slice(file.name.length - this.fileExtension.length);
    return fileExtension === this.fileExtension;
  }
}
