import { FilterSelectOption } from '../filter/filter-select/filter-select.component';
import { ButtonSizes, ButtonStyles, ButtonTypes } from '../../button/button.component';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { Observable } from 'rxjs';
import { SmacsSelectAsyncOptionsFn } from '../../forms/fields/select/smacs-select.component';

export interface EntityTable {
  columns: EntityTableColumn[];
  filteredResultsMessage?: string;
  resultsMessage?: string;
  pagination?: EntityTablePagination;
  // Clear button column sizes
  cssClearButtonColumnSize?: string;
  cssClearButtonColumnSizeFixed?: string;
  hasActions: boolean;
  summaryTable?: boolean;
  isFirstColumnSticky?: boolean;
}

export interface EntityTableColumn {
  // This must be unique, it is the key that ties that columns headers, filters, and rows together
  columnId: string;
  // Any CSS classes on the column, not including column size
  cssClass?: string;
  // Bootstrap column sizes
  cssColumnSize?: string;
  // Filter if one is required on the column
  filter?: EntityTableFilterCheckbox | EntityTableFilterDate | EntityTableFilterSelect | EntityTableFilterText;
  // Select All Filter should appear in place of a header label
  headerSelectAll?: boolean;
  // Text to display in cell
  label: string;
  // Filter based on async search via api calls
  asyncSearch?: boolean;
  // Fixed column size
  fixedColumnSize?: string;
}

export enum EntityTableFilterTypes {
  CHECKBOX = 'CHECKBOX',
  DATE = 'DATE',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
}

export interface EntityTableFilter {
  // If we need a custom filter outside the basic built in filters
  filterFn?: (
    rowsToFilter: EntityTableContentRow[],
    filterValue: FilterSelectOption | FilterSelectOption[] | string[] | string | Date[],
    columnKey: string
  ) => EntityTableContentRow[];
  // Might need it just for audits since we display API data
  onChanged?: ($event: any) => Observable<EntityTableContentRow[]>;
  type: EntityTableFilterTypes;
  // If any of the filters should simply update the parent, and not filter the table
  preventTableUpdate?: boolean;
}

export interface EntityTableFiltersValue {
  [columnId: string]: any;
}

export interface EntityTableFilterCheckbox extends EntityTableFilter {
  type: EntityTableFilterTypes.CHECKBOX;
  value?: boolean;
}

export interface EntityTableFilterDate extends EntityTableFilter {
  defaultDate: Date[];
  type: EntityTableFilterTypes.DATE;
  preventDateClear?: boolean;
}

export interface EntityTableFilterSelect extends EntityTableFilter {
  bindLabel?: string;
  bindValue?: string;
  isMultiSelect?: boolean;
  options: FilterSelectOption[] | string[] | any[];
  type: EntityTableFilterTypes.SELECT;
  isGroupSelectable?: boolean;
  onChanged?: ($event: any) => Observable<EntityTableContentRow[]>;
}

export interface EntityTableFilterText extends EntityTableFilter {
  type: EntityTableFilterTypes.TEXT;
  filterFn?: (rowsToFilter: EntityTableContentRow[], filterValue: string, columnKey: string) => EntityTableContentRow[];
}

export interface EntityTableContentRow {
  // Any buttons that are present in the last column
  actions?: EntityTableAction[];
  actionsColumnSize?: string;
  // Data to be displayed. What we filter on
  content: { [columnId: string]: any };
  cssClass?: string;
  validationCssClass?: string;
  // If we need any form elements in the table cell
  fields?: {
    [columnId: string]:
      | EntityTableSelectField
      | EntityTableButtonField
      | EntityTableCheckboxField
      | EntityTableTextField;
  };
  // If we need badges in the table cell
  badges?: { [columnId: string]: EntityTableBadges };
  // If we need any HTML in the table cell
  html?: { [columnId: string]: string };
  tooltip?: { [columnId: string]: EntityTableTooltip };
  // Prevent wrap and replace with ellipsis in cell
  preventWrap?: { [columnId: string]: boolean };
  rowId?: string;
}

export interface EntityTableAction {
  buttonStyle: ButtonStyles;
  dataAutomation: string;
  icon: SmacsIcons;
  onClick: (row: EntityTableContentRow) => any;
  buttonType?: ButtonTypes;
  buttonLinkHref?: string;
  label?: string;
  buttonSize?: ButtonSizes;
  isDisabled?: boolean;
  tooltip?: string;
  tooltipDisabled?: boolean;
  isHidden?: boolean;
  isPending?: boolean;
  iconPending?: SmacsIcons;
}

export enum EntityTableFieldTypes {
  BUTTON = 'BUTTON',
  CHECKBOX = 'CHECKBOX',
  SELECT = 'SELECT',
  TEXT = 'TEXT',
}

export interface EntityTableField {
  type: EntityTableFieldTypes;
  validation?: EntityTableFieldValidator;
  validator?: Observable<boolean>;
  bindValue?: string;
  tooltip?: string;
}

export interface EntityTableButtonField extends EntityTableField {
  buttonStyle?: ButtonStyles;
  icon?: SmacsIcons;
  label?: string;
  type: EntityTableFieldTypes.BUTTON;
  contentValue?: object;
  value?: string;
}

export interface EntityTableSelectField extends EntityTableField {
  options?: FilterSelectOption[] | string[] | any[];
  asyncOptionsFn?: SmacsSelectAsyncOptionsFn;
  type: EntityTableFieldTypes.SELECT;
  value?: string | FilterSelectOption;
  rowId?: string;
  isHidden?: boolean;
  showOptionsIcons?: boolean;
  icon?: SmacsIcons;
  onToggle?: (row: EntityTableTextField, i: number) => void;
  isDisabled?: boolean;
}

export interface EntityTableCheckboxField extends EntityTableField {
  type: EntityTableFieldTypes.CHECKBOX;
  value?: boolean;
  disabled?: boolean;
}

export interface EntityTableTextField extends EntityTableField {
  type: EntityTableFieldTypes.TEXT;
  value?: string;
  showPlaceholderMarkup?: boolean;
  placeholderMarkup?: string;
  rowId?: string;
  onEnter?: (row: EntityTableTextField, i: number) => void;
  onBlur?: (row: EntityTableTextField, i: number) => void;
}

export interface EntityTableBadges {
  values: string[];
  maxLength?: number;
  badgeIcon?: string[];
  badgeCssClass?: string[];
  tooltips?: string[];
  displayToolTip?: boolean;
}

export interface EntityTableFieldValidator {
  validator: (value: FilterSelectOption | string | any) => boolean;
  message: string;
  hasError?: boolean;
}

export interface EntityTableOnFieldChange {
  newValue: FilterSelectOption[] | FilterSelectOption | string[] | string | boolean;
  columnId: string;
  rowId: string;
  content: { [columnId: string]: any };
  contentValue: object;
  index?: number;
}

export interface EntityTableTooltip {
  content: string | string[];
  params?: { [key: string]: string | number };
  isDisabled?: boolean;
  isList?: boolean;
}

export interface EntityTablePagination {
  totalResults: number;
  pageSize?: number;
  onChanged?: ($event: any, filterValue: EntityTableFiltersValue) => Observable<EntityTableContentRow[]>;
}
