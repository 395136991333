<div class="public-phone-contact-card border-rad-all-2" *ngIf="siteName" data-automation="public-phone-contact-card">
  <div class="public-phone-contact-card__header">
    <span>{{ 'tkey;pages.phone.header' | translate }}</span>
    <span
      *ngIf="phoneRegistrationStatus"
      class="public-phone-contact-card__header__status-icon"
      data-automation="public-phone-contact-card-registration-status"
    >
      <i
        [ngClass]="{
          'public-phone-contact-card__header__status-icon--success':
            phoneRegistrationStatus === DeviceRegistrationStatus.REGISTERED,
          'public-phone-contact-card__header__status-icon--warning':
            phoneRegistrationStatus === DeviceRegistrationStatus.NOT_FOUND,
          'public-phone-contact-card__header__status-icon--danger':
            phoneRegistrationStatus === DeviceRegistrationStatus.UNREGISTERED
        }"
        [ngbTooltip]="humanReadableRegistrationStatus.get(phoneRegistrationStatus) | translate"
        data-automation="public-phone-contact-card-registration-status-icon"
      ></i>
    </span>
  </div>
  <div class="public-phone-contact-card__body">
    <div data-automation="public-phone-contact-card-description">
      <strong>{{ phoneDescription }}</strong>
    </div>
    <div data-automation="public-phone-contact-card-name">{{ phoneName }}</div>
    <div data-automation="public-phone-contact-card-site">
      <i [class]="SmacsIcons.SITE"></i>
      <span class="ms-1">{{ siteName }}</span>
    </div>
  </div>
</div>
