import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SmacsCdrDumpComponent } from './cdr-dump.component';
import { SharedModule } from '../shared/shared.module';
import { ButtonModule } from '../button/button.module';
import { FormsModule } from '@angular/forms';
import { SmacsFormsModule } from '../forms/smacs-forms.module';
import { TopNavModule } from '../shared/top-nav/top-nav.module';
import { BreadcrumbsModule } from '../shared/breadcrumbs/breadcrumbs.module';
import { BottomNavModule } from '../shared/bottom-nav/bottom-nav.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    SharedModule,
    ButtonModule,
    FormsModule,
    SmacsFormsModule,
    TopNavModule,
    BreadcrumbsModule,
    BottomNavModule,
  ],
  declarations: [SmacsCdrDumpComponent],
  exports: [SmacsCdrDumpComponent],
})
export class CdrDumpModule {}
