import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReassignTypes } from '../../reassign-user/reassign-user-search/reassign-user-search.component';
import { CurrentClusterContext } from '../../../../shared/contexts/current-cluster.context';
import { combineLatest, forkJoin, Observable, of, Subscription, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PhoneUiContext } from '../../../../shared/phone-buttons/contexts/phone-ui.context';
import {
  ClusterResult,
  DefaultDirectoryNumberRequest,
  DirectoryNumber,
  DirectoryNumberRef,
  EndUserRef,
  EndUserResult,
  Global360View,
  LdapUserDialPlanAttributes,
  LdapUserDialPlanDetailsFieldConfig,
  LdapUserDialPlanDetailsFieldConfigRequest,
  LineButton,
  Phone,
  PhoneRef,
  TranslationPatternRef,
  Voicemail,
  VoicemailRef,
  VoicemailResult,
} from '../../../../shared/models/generated/smacsModels';
import { Nvp } from '../../../../shared/models/nvp';
import { EndUserResource } from '../../../../shared/resources/end-user.resource';
import { ReassignUserExecuteComponent } from '../../reassign-user/reassign-user-execute/reassign-user-execute.component';
import { SearchPhoneResource } from '../../../../shared/resources/search-phone.resource';
import { PhoneResource } from '../../../../shared/resources/phone.resource';
import { DnDetailSummaryResource } from '../../../../shared/resources/dn-detail-summary.resource';
import { LdapUserResource } from '../../../shared/resources/ldap-user.resource';
import { PrimaryExtensionFieldConfigResource } from '../../../shared/resources/field-config/primary-extension-field-config.resource';
import { DirectoryNumberResource } from '../../../../self-serve/resources/directory-number.resource';
import { VoicemailService } from '../../../../shared/services/voicemail.service';
import { DefaultDirectoryNumberResource } from '../../../../shared/resources/default-directory-number.resource';
import { TranslationPatternResource } from '../../../shared/resources/translation-pattern.resource';
import { VoicemailResource } from '../../../../shared/resources/voicemail.resource';
import { VoicemailSearchResource } from '../../../../shared/resources/voicemail-search.resource';
import { DefaultDeskphoneResource } from '../../../../self-serve/resources/default-deskphone.resource';
import { cloneDeep } from 'lodash';
import { LineFeatureConfigResource } from '../../../../shared/resources/line-feature-config.resource';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ToastService } from '../../../../shared/services/toast.service';
import { DefaultEndUserResource } from '../../../../shared/resources/default-end-user.resource';
import { PhoneButtonsService } from '../../../../shared/phone-buttons/shared/phone-buttons.service';

@Component({
  selector: 'smacs-public-phone-reassign',
  templateUrl: './public-phone-reassign.component.html',
})
export class PublicPhoneReassignComponent implements OnInit, OnDestroy {
  @ViewChild(ReassignUserExecuteComponent) reassignUserExecuteComponent: ReassignUserExecuteComponent;
  isLoading = true;
  serverId: number;
  isUserSelect: boolean;
  isVoicemailPresentOnLineOne = false;
  publicPhoneVoicemailRef: VoicemailRef;
  siteId: number;
  protected readonly ReassignTypes = ReassignTypes;
  private _isKeepVoicemail: boolean;
  private _targetUserGlobal360: Global360View;
  private _targetEndUserResult: EndUserResult;
  private _phone: Phone;
  private _subscriptions = new Subscription();
  private _auditTags: Nvp[];
  private _phoneName: string;
  private _primaryExtensionRef: DirectoryNumberRef;
  private _dialplanGroupId: number;
  private _associatedTranslationPatterns: TranslationPatternRef[];

  constructor(
    private _currentClusterContext: CurrentClusterContext,
    private _route: ActivatedRoute,
    private _router: Router,
    private _phoneUiContext: PhoneUiContext,
    private _endUserResource: EndUserResource,
    private _searchPhoneResource: SearchPhoneResource,
    private _phoneResource: PhoneResource,
    private _dnDetailSummaryResource: DnDetailSummaryResource,
    private _ldapUserResource: LdapUserResource,
    private _primaryExtensionFieldConfigResource: PrimaryExtensionFieldConfigResource,
    private _directoryNumberResource: DirectoryNumberResource,
    private _voicemailService: VoicemailService,
    private _defaultDirectoryNumberResource: DefaultDirectoryNumberResource,
    private _translationPatternResource: TranslationPatternResource,
    private _voicemailResource: VoicemailResource,
    private _voicemailSearchResource: VoicemailSearchResource,
    private _defaultDeskphoneResource: DefaultDeskphoneResource,
    private _lineFeatureConfigResource: LineFeatureConfigResource,
    private _toastService: ToastService,
    private _defaultEndUserResource: DefaultEndUserResource,
    private _phoneButtonsService: PhoneButtonsService
  ) {}

  ngOnInit() {
    this.isUserSelect = !this._router.url.includes('/reassign/');
    this._phoneName = this._route.snapshot.params.phoneName;
    this.siteId = this._phoneUiContext.getSiteId();

    if (!this.isUserSelect) {
      const combineSub = combineLatest([
        this._phoneUiContext.isVoicemailPresentOnLineOneState$,
        this._currentClusterContext.state$,
        this._getPhoneByName(),
      ])
        .pipe(
          switchMap(([isVoicemailPresent, clusterResult, phone]) => {
            this.isVoicemailPresentOnLineOne = isVoicemailPresent;
            this.serverId = clusterResult.cucmServerId;
            this._primaryExtensionRef = (phone.buttons[0] as LineButton).dn;
            this._phone = phone;
            return this._dnDetailSummaryResource.get(
              this._primaryExtensionRef.id,
              this._primaryExtensionRef.serverId.toString()
            );
          })
        )
        .subscribe((dnDetails) => {
          this._dialplanGroupId = dnDetails.dialPlanGroupId;
          if (dnDetails.translationPatterns.length) {
            this._associatedTranslationPatterns = dnDetails.translationPatterns;
          }
          this.publicPhoneVoicemailRef = dnDetails.voicemail;
          this.isLoading = false;
        });
      this._subscriptions.add(combineSub);
    } else {
      const ctxSub = this._currentClusterContext.state$.subscribe((clusterResult: ClusterResult) => {
        this.serverId = clusterResult.cucmServerId;
        this.isLoading = false;
      });
      this._subscriptions.add(ctxSub);
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onReassignClicked(isKeepVoicemail: boolean) {
    this._isKeepVoicemail = isKeepVoicemail;
    this._targetUserGlobal360 = this.reassignUserExecuteComponent.global360View;
    this._targetEndUserResult = this._targetUserGlobal360.endUsers.find(
      (endUserResult) => endUserResult.ref.serverId === this.serverId
    );
    this._auditTags = [{ name: 'transfer', value: `public phone > ${this._targetEndUserResult.ref.username}` }];
    const reassignSub = forkJoin([
      this._handleTargetEndUser(),
      this._handleOtherEndUsers(),
      this._handleDirectoryNumber(),
      this._handleTranslationPatterns(),
    ])
      .pipe(switchMap(() => forkJoin([this._handlePhone(), this._handleVoicemail()])))
      .subscribe(() => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.DESKPHONE,
          'tkey;transfer.toast.title',
          'tkey;transfer.toast.message',
          { origin: this._phoneName, destination: this._targetEndUserResult.ref.username }
        );
        this._router.navigate([`/user/${this._targetEndUserResult.ref.username}/deskphone/${this._phone.id}`]);
      });
    this._subscriptions.add(reassignSub);
  }

  // Get the phone before everything else so that we have access to the user's new primary extension
  private _getPhoneByName(): Observable<Phone> {
    return this._searchPhoneResource.get({ name: this._phoneName }).pipe(
      switchMap((phoneResults) => {
        return this._phoneResource.get(phoneResults[0].ref.id, phoneResults[0].ref.serverId.toString());
      })
    );
  }

  private _handleTargetEndUser(): Observable<void> {
    return forkJoin([
      this._endUserResource.get(this._targetEndUserResult.ref.id, this.serverId),

      this._defaultEndUserResource.post({
        hasVoicemail: this._isKeepVoicemail,
        siteId: this.siteId.toString(),
        hasExtensionMobility: false,
        hasSnr: false,
        username: this._targetEndUserResult.ref.username,
      }),
    ]).pipe(
      switchMap((results) => {
        const targetEndUser = results[0];
        const targetEndUserDefaults = results[1];
        targetEndUser.enableHomeCluster = true;
        targetEndUser.extensionMobilityCrossCluster = true;
        targetEndUser.primaryExtension = this._primaryExtensionRef;
        targetEndUser.ucServiceProfile = targetEndUserDefaults.ucServiceProfile;

        return this._endUserResource.put(targetEndUser, this.serverId, this._auditTags).pipe(
          switchMap((endUserRef: EndUserRef) => {
            // After the primary extension is set on the user, if this is an LDAP user, also set the user's LDAP Dial Plan Attributes.
            if (this._targetEndUserResult.type === 'LDAP_ACTIVE' && this._dialplanGroupId) {
              const ldapUserDialPlanRequest: LdapUserDialPlanDetailsFieldConfigRequest = {
                dialPlanGroupId: this._dialplanGroupId,
                extension: this._primaryExtensionRef.extension,
              };
              return this._primaryExtensionFieldConfigResource
                .getLdapUserDialPlanFieldConfig(ldapUserDialPlanRequest)
                .pipe(
                  switchMap((ldapUserDialplanDetails: LdapUserDialPlanDetailsFieldConfig) => {
                    const ldapUserDialPlanAttributes = {
                      did: ldapUserDialplanDetails.e164Number.defaultValue,
                      extension: ldapUserDialplanDetails.extension.defaultValue,
                      username: endUserRef.username,
                    } as LdapUserDialPlanAttributes;
                    return this._ldapUserResource.put(ldapUserDialPlanAttributes, this._auditTags);
                  })
                );
            } else {
              return of(null);
            }
          })
        );
      })
    );
  }

  private _handleOtherEndUsers(): Observable<null> | Observable<EndUserRef>[] {
    const nonCurrentEndUsers = this._targetUserGlobal360.endUsers
      .filter((endUserResult) => endUserResult.ref.id !== this._targetEndUserResult.ref.id)
      .map((endUserResult) => {
        return this._endUserResource.get(endUserResult.ref.id, endUserResult.ref.serverId).pipe(
          switchMap((endUser) => {
            endUser.enableHomeCluster = false;
            return this._endUserResource.put(endUser, endUserResult.ref.serverId, this._auditTags);
          })
        );
      });
    if (nonCurrentEndUsers.length) {
      return nonCurrentEndUsers;
    } else {
      return of(null);
    }
  }

  private _handleDirectoryNumber(): Observable<DirectoryNumberRef> {
    // Get new defaults for the directory number and set Alerting Name and Description on the directory number
    // Handle voicemail related properties if necessary
    const extension =
      this._phone.buttons[0] &&
      this._phoneButtonsService.isLineButton(this._phone.buttons[0]) &&
      this._phone.buttons[0].dn?.extension;
    const dnDefaultsRequest: DefaultDirectoryNumberRequest = {
      dialPlanGroupId: this._dialplanGroupId.toString(),
      siteId: this.siteId.toString(),
      endUserUsername: this._targetEndUserResult.ref.username,
      withVoicemail: this._isKeepVoicemail,
      extension: extension || '',
    };
    return forkJoin([
      this._defaultDirectoryNumberResource.post(dnDefaultsRequest),
      this._directoryNumberResource.get(this._primaryExtensionRef.id, this._primaryExtensionRef.serverId.toString()),
    ]).pipe(
      switchMap((results) => {
        const defaultDn: DirectoryNumber = results[0];
        const directoryNumber: DirectoryNumber = results[1];
        directoryNumber.alertingName = defaultDn.alertingName;
        directoryNumber.description = defaultDn.description;
        if (!this._isKeepVoicemail) {
          directoryNumber.voicemailProfile = defaultDn.voicemailProfile;

          directoryNumber.forwardBusyInternal.forwardToVoicemail = this._isKeepVoicemail;
          directoryNumber.forwardBusyExternal.forwardToVoicemail = this._isKeepVoicemail;

          directoryNumber.forwardNoAnswerExternal.forwardToVoicemail = this._isKeepVoicemail;
          directoryNumber.forwardNoAnswerInternal.forwardToVoicemail = this._isKeepVoicemail;

          directoryNumber.forwardNoCoverageExternal.forwardToVoicemail = this._isKeepVoicemail;
          directoryNumber.forwardNoCoverageInternal.forwardToVoicemail = this._isKeepVoicemail;

          directoryNumber.forwardUnregisteredExternal.forwardToVoicemail = this._isKeepVoicemail;
          directoryNumber.forwardUnregisteredInternal.forwardToVoicemail = this._isKeepVoicemail;

          directoryNumber.forwardOnCtiFailure.forwardToVoicemail = this._isKeepVoicemail;
        }

        return this._directoryNumberResource.put(
          directoryNumber.id,
          directoryNumber,
          this.serverId.toString(),
          this._auditTags
        );
      })
    );
  }

  private _handleTranslationPatterns(): Observable<TranslationPatternRef[]> | Observable<null> {
    if (this._associatedTranslationPatterns?.length && !!this._dialplanGroupId) {
      const tpObservable$ = this._associatedTranslationPatterns.map((tpRef) =>
        forkJoin([
          this._primaryExtensionFieldConfigResource.getTranslationPatternFieldConfig({
            siteId: Number(this.siteId),
            extension: this._primaryExtensionRef.extension,
            username: this._targetEndUserResult.ref.username,
            dialPlanGroupId: this._dialplanGroupId,
          }),
          this._translationPatternResource.get(tpRef.id, tpRef.serverId),
        ]).pipe(
          switchMap((results) => {
            const tpFieldConfig = results[0];
            const translationPattern = results[1];
            translationPattern.description = tpFieldConfig.description.defaultValue;
            return this._translationPatternResource.put(tpRef.id, tpRef.serverId, translationPattern);
          })
        )
      );
      return forkJoin(tpObservable$);
    } else {
      return of(null);
    }
  }

  private _handleVoicemail(): Observable<void> | Observable<VoicemailRef> {
    if (this._isKeepVoicemail && this.isVoicemailPresentOnLineOne) {
      return forkJoin([
        this._voicemailService.getVoicemailDefault(this._targetEndUserResult.ref.username, Number(this.siteId)),
        this._voicemailSearchResource.searchByExtension(this._primaryExtensionRef.extension),
      ]).pipe(
        switchMap((results) => {
          const vmDefaults = results[0];
          const vmResult: VoicemailResult = results[1][0];
          const newVoicemailValue: Voicemail = {
            id: vmResult.ref.id,
            extension: vmResult.ref.extension,
            alias: vmDefaults.alias,
            firstName: vmDefaults.firstName,
            lastName: vmDefaults.lastName,
            displayName: vmDefaults.displayName,
          };
          return this._voicemailResource.put(vmResult.ref.serverId, vmResult.ref.id, newVoicemailValue);
        })
      );
    } else if (this.isVoicemailPresentOnLineOne) {
      return this._voicemailSearchResource.searchByExtension(this._primaryExtensionRef.extension).pipe(
        switchMap((vmResults) => {
          return this._voicemailResource.delete(vmResults[0].ref.serverId, vmResults[0].ref.id, this._auditTags);
        })
      );
    } else {
      return of(null);
    }
  }

  private _handlePhone(): Observable<PhoneRef> {
    return forkJoin([
      this._lineFeatureConfigResource.post({
        extension: this._primaryExtensionRef.extension,
        protocol: this._phone.protocol,
        siteId: Number(this.siteId),
        model: this._phone.model,
        deviceOwner: this._targetEndUserResult.ref.username,
      }),
      this._defaultDeskphoneResource.post({
        endUserUsername: this._targetEndUserResult.ref.username,
        phoneModel: this._phone.model,
        siteId: this.siteId.toString(),
        protocol: this._phone.protocol,
      }),
    ]).pipe(
      switchMap((results) => {
        const lineFeatureFieldConfig = results[0];
        const phoneDefaults = results[1];
        const updatedPhone = {
          ...cloneDeep(this._phone),
          description: phoneDefaults.description,
          owner: this._targetEndUserResult.ref,
          associatedEndUsers: this._phone.associatedEndUsers.concat(this._targetEndUserResult.ref),
          buttons: this._phone.buttons.map((button, index) => {
            if (button.type === 'Line') {
              const newButton = button as LineButton;
              if (newButton.lineFeature) {
                newButton.lineFeature.associatedEndUsers.push(this._targetEndUserResult.ref);
              }
              if (newButton.dn?.extension === this._primaryExtensionRef.extension) {
                newButton.lineFeature.label = lineFeatureFieldConfig.label.defaultValue;
                newButton.lineFeature.internalCallerId = lineFeatureFieldConfig.internalCallerId.defaultValue;
                newButton.lineFeature.externalCallerId = lineFeatureFieldConfig.externalCallerId.defaultValue;
              }
              if (index === 0) {
                const defaultLineOneButton = phoneDefaults.buttons[0] as LineButton;
                newButton.dn.description = defaultLineOneButton.dn?.description;
              }
              return newButton;
            } else {
              return button;
            }
          }),
        };
        return this._phoneResource.put(cloneDeep(updatedPhone), this.serverId.toString(), this._auditTags);
      })
    );
  }
}
