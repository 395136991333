import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { Subscription } from 'rxjs';
import { ToastService } from '../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { DateAgoPipe } from '../../../shared/pipes/date-ago.pipe';
import { PolicyMisconfigurationPollingContext } from './policy-misconfiguration-polling.context';
import { PolicyMisconfigurationResource, TeamsPoliciesUntyped } from './policy-misconfiguration.resource';
import {
  AccountType,
  JobState,
  JobStatus,
  Microsoft360View,
  MicrosoftPolicyConfiguration,
  MicrosoftPolicyMisconfigurationReport,
  TeamsVoicePolicies,
} from '../../../shared/models/generated/smacsModels';
import moment from 'moment/moment';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFieldTypes,
  EntityTableFilterTypes,
  EntityTableOnFieldChange,
  EntityTableSelectField,
} from '../../../shared/entity-table/entity-table.models';
import { forOwn } from 'lodash';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { MicrosoftVoicePoliciesResource } from '../../../helpdesk/shared/resources/microsoft-voice-policies.resource';
import { Microsoft360ViewResource } from '../../../shared/resources/microsoft-360-view.resource';

@Component({
  selector: 'microsoft-policy-misconfiguration-report',
  templateUrl: './policy-misconfiguration-report.component.html',
  styleUrls: ['../../reporting.scss'],
  providers: [
    PolicyMisconfigurationPollingContext,
    PolicyMisconfigurationResource,
    MicrosoftVoicePoliciesResource,
    Microsoft360ViewResource,
  ],
})
export class PolicyMisconfigurationReportComponent implements OnInit, OnDestroy {
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  isLoading = true;
  tableDataAutomation = 'policy-misconfiguration-datatable';

  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];

  private _pollingSubscription: Subscription;
  private _subscriptions = new Subscription();

  constructor(
    private _translateService: TranslateService,
    private _bottomNavService: BottomNavService,
    private _breadcrumbsService: BreadcrumbsService,
    private _toastService: ToastService,
    private _dateAgoPipe: DateAgoPipe,
    private _policyMisconfigurationPollingContext: PolicyMisconfigurationPollingContext,
    private _policyMisconfigurationResource: PolicyMisconfigurationResource,
    private _microsoftVoicePoliciesResource: MicrosoftVoicePoliciesResource,
    private _microsoft360ViewResource: Microsoft360ViewResource
  ) {}

  ngOnInit() {
    this._initBreadcrumbs();
    this._initPolling();
    this._listenToPolling();
  }

  ngOnDestroy() {
    this._pollingSubscription.unsubscribe();
    this._subscriptions.unsubscribe();
  }

  onFieldChange(event: EntityTableOnFieldChange): void {
    const upn = event.content['userPrincipalName'];

    this.tableRows = this.tableRows.map((row: EntityTableContentRow) => {
      if (row.content['userPrincipalName'] === upn) {
        const updatedFields: { [key: string]: EntityTableSelectField } = {};
        forOwn(row.fields, (field: EntityTableSelectField, key: string) => {
          updatedFields[key] = {
            ...field,
            isDisabled: true,
          };
        });

        return {
          ...row,
          fields: {
            ...updatedFields,
          },
        };
      }

      return row;
    });

    this._microsoft360ViewResource.get(upn).subscribe((microsoft360View: Microsoft360View) => {
      const isResourceAccount = microsoft360View.accountType === AccountType.RESOURCE_ACCOUNT;

      this._microsoftVoicePoliciesResource.get(upn, isResourceAccount).subscribe((data: TeamsVoicePolicies) => {
        const teamsVoicePolicies: TeamsPoliciesUntyped = {
          ...data,
        };
        teamsVoicePolicies[event.columnId] = event.newValue as string;

        this._microsoftVoicePoliciesResource.put(upn, teamsVoicePolicies, isResourceAccount).subscribe(() => {
          this.tableRows = this.tableRows.map((row: EntityTableContentRow) => {
            if (row.content['userPrincipalName'] === upn) {
              const updatedFields: { [key: string]: EntityTableSelectField } = {};
              forOwn(row.fields, (field: EntityTableSelectField, key: string) => {
                updatedFields[key] = {
                  ...field,
                  isDisabled: false,
                };
              });

              return {
                ...row,
                content: {
                  ...row.content,
                  [event.columnId]: event.newValue as string,
                },
                fields: {
                  ...updatedFields,
                  [event.columnId]: null,
                },
              };
            }

            return row;
          });

          this._toastService.push(
            ToastTypes.SUCCESS,
            SmacsIcons.OK,
            'tkey;shared.toast.save.success.title',
            'tkey;reporting.microsoft.policy_misconfig.toast.success'
          );
        });
      });
    });
  }

  private _initPolling() {
    if (!this._policyMisconfigurationPollingContext.getIsPolling()) {
      this._policyMisconfigurationPollingContext.startPolling();
    }
  }

  private _listenToPolling(isRefreshed = false) {
    this._pollingSubscription = this._policyMisconfigurationPollingContext.state$.subscribe((state: JobStatus) => {
      if (state.jobState === JobState.IDLE && state.lastRunTime === null) {
        this._refresh();
      } else if (state.jobState === JobState.IDLE && state.lastRunTime !== null) {
        const secondsAgo = moment().diff(state.lastRunTime, 'seconds');
        if (secondsAgo > 60) {
          this._initBottomNav(state);
          this._refresh();
        } else {
          this._initBottomNav(state);
          this._initTableRows();
          if (isRefreshed) {
            this._toastService.push(
              ToastTypes.SUCCESS,
              SmacsIcons.OK,
              'tkey;reporting.microsoft.policy_misconfig.toast.refresh.title',
              'tkey;reporting.microsoft.policy_misconfig.toast.refresh.content'
            );
          }
        }
      }
    });
  }

  private _initBottomNav(state: JobStatus) {
    const bottomNavButtons: BottomNavButton[] = [
      {
        id: 'policy-misconfiguration-refresh',
        dataAutomation: 'policy-misconfiguration-refresh',
        label: 'tkey;reporting.microsoft.policy_misconfig.bottom_nav.refresh',
        icon: this.smacsIcons.REFRESH,
        buttonClass: this.buttonStyles.DEFAULT,
        cb: () => {
          this._refresh(true);
        },
      },
    ];

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));

    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
        helpText: `
              <i class="${SmacsIcons.CLOCK}"></i>
              ${this._translateService.instant(
                'tkey;reporting.microsoft.policy_misconfig.bottom_nav.last_refreshed'
              )} <strong>${this._dateAgoPipe.transform(state.lastRunTime)}</strong>
            `,
      })
    );
  }

  private _initTable() {
    this.table = {
      columns: [
        {
          columnId: 'userPrincipalName',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.upn.label',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'lineUri',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.number.label',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'dialPlanName',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.dial_plan_group.label',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'onlineVoiceRoutingPolicy',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.voice_routing.label',
        },
        {
          columnId: 'tenantDialPlan',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.dial_plan.label',
        },
        {
          columnId: 'emergencyCallingPolicy',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.emergency_calling.label',
        },
        {
          columnId: 'emergencyCallRoutingPolicy',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.emergency_call_routing.label',
        },
        {
          columnId: 'callingPolicy',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.calling.label',
        },
        {
          columnId: 'ipPhonePolicy',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.ip_phone.label',
        },
        {
          columnId: 'teamsCallHoldPolicy',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.call_hold.label',
        },
        {
          columnId: 'teamsCallParkPolicy',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.call_park.label',
        },
        {
          columnId: 'onlineVoicemailPolicy',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.voicemail.label',
        },
        {
          columnId: 'callingLineIdentity',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.caller_id.label',
        },
        {
          columnId: 'sharedCallRoutingPolicy',
          fixedColumnSize: 'width: 250px;',
          label: 'tkey;reporting.microsoft.policy_misconfig.table.shared_calling.label',
        },
      ],
      hasActions: true,
      cssClearButtonColumnSizeFixed: 'width: 150px;',
      isFirstColumnSticky: true,
    };
  }

  private _initTableRows() {
    this._policyMisconfigurationPollingContext.stopPolling();
    this._pollingSubscription.unsubscribe();

    this._policyMisconfigurationResource.get().subscribe((data: MicrosoftPolicyMisconfigurationReport[]) => {
      const invalidRows: MicrosoftPolicyMisconfigurationReport[] = data.filter(
        (row: MicrosoftPolicyMisconfigurationReport) => {
          let isValid = true;

          forOwn(row, (value: string | MicrosoftPolicyConfiguration, key: string) => {
            if (value && typeof value === 'object') {
              const typedValue = value as MicrosoftPolicyConfiguration;
              if (!typedValue.isValid) {
                isValid = false;
                return false;
              }
            }
          });

          if (!isValid) {
            return row;
          }
        }
      );
      this._initTable();

      this.tableRows = invalidRows.map((row: MicrosoftPolicyMisconfigurationReport) => {
        const fields: { [key: string]: EntityTableSelectField } = {};

        forOwn(row, (value: string | MicrosoftPolicyConfiguration, key: string) => {
          if (value && typeof value === 'object') {
            const typedValue = value as MicrosoftPolicyConfiguration;
            if (!typedValue.isValid) {
              const possibleOptions: string[] = typedValue.possibleOptions.filter(
                (option: string) => option !== value.defaultPolicy
              );

              fields[key] = {
                type: EntityTableFieldTypes.SELECT,
                value: typedValue.currentPolicy,
                options: [
                  {
                    label: value.currentPolicy,
                    value: value.currentPolicy,
                  },
                  {
                    label: value.defaultPolicy,
                    value: value.defaultPolicy,
                    iconHtml: `<i class="icon ${this.smacsIcons.INFO} me-1"></i>`,
                    iconHtmlTooltip: this._translateService.instant(
                      'tkey;reporting.microsoft.policy_misconfig.table.select.default_value'
                    ),
                  },
                  ...possibleOptions.map((option) => {
                    return {
                      label: option,
                      value: option,
                    };
                  }),
                ],
                showOptionsIcons: true,
                bindValue: 'value',
                rowId: key,
                isHidden: false,
                onToggle: (): void => null,
                validation: {
                  validator: () => {
                    return false;
                  },
                  hasError: true,
                  message: this._translateService.instant(
                    'tkey;reporting.microsoft.policy_misconfig.table.policy_invalid.text'
                  ),
                },
                icon: SmacsIcons.WARNING,
                isDisabled: false,
              };
            }
          }
        });

        return {
          validationCssClass: 'table-warning',
          content: {
            userPrincipalName: row.upn,
            lineUri: row.lineUri,
            dialPlanName: row.dialPlanName.length > 30 ? row.dialPlanName.slice(0, 30) + '...' : row.dialPlanName,
            onlineVoiceRoutingPolicy: row.onlineVoiceRoutingPolicy?.currentPolicy,
            tenantDialPlan: row.tenantDialPlan?.currentPolicy,
            emergencyCallingPolicy: row.emergencyCallingPolicy?.currentPolicy,
            emergencyCallRoutingPolicy: row.emergencyCallRoutingPolicy?.currentPolicy,
            callingPolicy: row.callingPolicy?.currentPolicy,
            ipPhonePolicy: row.ipPhonePolicy?.currentPolicy,
            teamsCallHoldPolicy: row.teamsCallHoldPolicy?.currentPolicy,
            teamsCallParkPolicy: row.teamsCallParkPolicy?.currentPolicy,
            onlineVoicemailPolicy: row.onlineVoicemailPolicy?.currentPolicy,
            callingLineIdentity: row.callingLineIdentity?.currentPolicy,
            sharedCallRoutingPolicy: row.sharedCallRoutingPolicy?.currentPolicy,
          },
          tooltip: {
            dialPlanName: {
              content: row.dialPlanName.length > 30 ? row.dialPlanName : null,
            },
          },
          fields: fields,
        };
      });

      this.isLoading = false;
    });
  }

  private _refresh(refreshClicked = false) {
    this._policyMisconfigurationPollingContext.stopPolling();
    this._pollingSubscription.unsubscribe();
    this._bottomNavService.setButtonPendingState('policy-misconfiguration-refresh', true);
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
        helpText: null,
      })
    );

    this.isLoading = true;

    this._policyMisconfigurationResource.refresh().subscribe(() => {
      this._initPolling();
      this._listenToPolling(refreshClicked);
    });
  }

  private _initBreadcrumbs() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;reporting.microsoft.policy_misconfig.title' }]);
  }
}
