import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, switchMap } from 'rxjs';
import { WebexCallingStatus } from '../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class WebexCallingStatusContext {
  private _stateSource = new ReplaySubject<WebexCallingStatus>(1);
  state$ = this._stateSource.asObservable();

  constructor(private _http: HttpClient) {
    this._init().subscribe();
  }

  refreshWebexCallingStatusTokens(): Observable<void> {
    return this._http
      .post<WebexCallingStatus>('/services/cisco/admin/webex-calling/status/token-renewals', {})
      .pipe(switchMap(() => this._init()));
  }

  refreshWebexCallingStatus() {
    return this._init();
  }

  private _init(): Observable<void> {
    return new Observable((subscriber) => {
      return this._http
        .get<WebexCallingStatus>('/services/cisco/admin/webex-calling/status')
        .subscribe((callingStatus) => {
          this._stateSource.next(callingStatus);
          subscriber.next();
          subscriber.complete();
        });
    });
  }
}
