import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { GraphDetails, MicrosoftGeneralSettings } from '../../../shared/models/generated/smacsModels';
import { SmacsFieldComponentConfig, SmacsFieldConfig, SmacsFormConfig } from '../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  SmacsCheckboxConfig,
} from '../../../forms/fields/checkbox/smacs-checkbox.component';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { combineLatest, Observable, Subscriber, Subscription } from 'rxjs';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { MicrosoftGeneralSettingsResource } from '../../resources/microsoft-general-settings.resource';
import { MsGraphConfigContext } from '../../contexts/ms-graph-config.context';

@Component({
  selector: 'smacs-microsoft-general-settings',
  templateUrl: './microsoft-general-settings.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [MicrosoftGeneralSettingsResource],
})
export class MicrosoftGeneralSettingsComponent
  extends SmacsFormAbstractDirective<MicrosoftGeneralSettings>
  implements OnInit, OnDestroy
{
  isLoading = true;
  smacsIcons = SmacsIcons;
  bottomNavButtons = [
    {
      id: 'microsoft-general-settings-save-button',
      dataAutomation: 'microsoft-general-settings-save-button',
      label: 'tkey;admin.ui.save',
      buttonClass: ButtonStyles.PRIMARY,
      state: {
        pending: false,
        buttonDisableState: {
          disabled: false,
          tooltipKey: '',
        },
      },
      icon: this.smacsIcons.OK,
      type: ButtonTypes.SUBMIT,
      submitSubject: this._validateAndSubmitSource,
    },
  ] as BottomNavButton[];
  graphDetails: GraphDetails;
  formConfig = {
    fields: {
      zpcUrl: {
        dataAutomation: 'zpc-url',
        label: 'tkey;admin.microsoft.general_settings.form.zpc_url.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        options: {
          content: 'tkey;admin.microsoft.general_settings.form.zpc_url.content',
        },
      },
      showAssociatedDevicesOn360View: {
        dataAutomation: 'show-associated-devices',
        label: 'tkey;admin.microsoft.general_settings.form.show_associated_devices.label',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        options: {
          content: 'tkey;admin.microsoft.general_settings.form.show_associated_devices.content',
        },
        disabled: () => !this.graphDetails || !this.graphDetails.graphRoles.includes('TeamworkDevice.ReadWrite.All'),
        disabledTooltip: 'tkey;admin.microsoft.general_settings.form.show_associated_devices.disabled_tooltip',
      },
    },
  } as SmacsFormConfig;

  private _config = {} as MicrosoftGeneralSettings;
  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _toastService: ToastService,
    private _microsoftGeneralSettingsResource: MicrosoftGeneralSettingsResource,
    private _bottomNavService: BottomNavService,
    private _breadcrumbsService: BreadcrumbsService,
    private _msGraphConfigContext: MsGraphConfigContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.microsoft.general_settings.title' }]);
    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));

    combineLatest([this._microsoftGeneralSettingsResource.get(), this._msGraphConfigContext.details()]).subscribe(
      ([generalSettings, graphDetails]: [MicrosoftGeneralSettings, GraphDetails]) => {
        this._config = generalSettings;
        this.entitySource.next({
          ...this._config,
        });
        this.graphDetails = graphDetails;
        this.isLoading = false;
      }
    );

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() =>
      this._setBottomNavValidationError(!this.isFormValid())
    );
    this._subscriptions.add(formSubmittedSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
    this._breadcrumbsService.clearBreadcrumbs();
  }

  isCheckBox(config: SmacsFieldConfig<SmacsFieldComponentConfig>): boolean {
    return config.componentConfig instanceof SmacsCheckboxConfig;
  }

  isTextInput(config: SmacsFieldConfig<SmacsFieldComponentConfig>): boolean {
    return config.componentConfig instanceof SmacsTextConfig;
  }

  // Used to keep the order of the keys-value pairs as we iterate formConfig.fields using 'keyvalue' pipe
  returnZero = () => 0;

  protected submit() {
    return this._saveGeneralSettings();
  }

  private _saveGeneralSettings(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._setBottomNavValidationError(false);
      this._setBottomNavPending(true);

      this._microsoftGeneralSettingsResource.put(this.entity).subscribe(() => {
        this._setBottomNavPending(false);

        this._toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.CONFIG,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.microsoft.general_settings.toast.message'
        );

        subscriber.next(null);
        subscriber.complete();
      });
    });
  }

  private _setBottomNavValidationError(hasError: boolean) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: hasError,
      })
    );
  }

  private _setBottomNavPending(setting: boolean) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'microsoft-general-settings-save-button',
        state: {
          pending: setting,
        },
      })
    );
  }
}
