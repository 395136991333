import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { DialPlanExceptionResource } from '../../../resources/dial-plan-exception.resource';
import { DialPlanExceptionGroup, DnDidRange, DnExceptionRange } from '../../../../shared/models/generated/smacsModels';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsExtensionRangesComponentConfig } from '../../../../forms/fields/extension-ranges/smacs-extension-range-models';
import { SmacsTextareaConfig } from '../../../../forms/fields/textarea/smacs-textarea.component';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { SmacsExtensionRangesComponent } from '../../../../forms/fields/extension-ranges/smacs-extension-ranges.component';
import { uniq } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { ModalBodyClass } from '../../../../modals/modal-body';
import { DetailedModalComponent } from '../../../../modals/detailed-modal/detailed-modal.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';

const GROUP_NAME_MAX_LENGTH = 255;

export interface DialPlanExceptionForm {
  id: string;
  groupName: string;
  dnRanges: DnExceptionRange[];
  exceptionList: string;
  legalHold: boolean;
}

@Component({
  selector: 'app-dn-exception-edit',
  templateUrl: './dial-plan-exception-management-edit.component.html',
})
export class DialPlanExceptionManagementEditComponent extends ModalBodyClass implements OnInit, OnDestroy {
  allGroups: DialPlanExceptionGroup[];
  initialDnExceptionGroup = {} as DialPlanExceptionGroup;
  @Output() closeTrigger = new EventEmitter<DialPlanExceptionGroup>();

  @ViewChild(SmacsExtensionRangesComponent) childComponent: SmacsExtensionRangesComponent;
  @ViewChild('errorTemplate') errorTemplate: TemplateRef<any>;

  isSubmitted: boolean;
  allGroupNames: string[];
  ranges: DnExceptionRange[];
  isChildFormValid = true;
  formConfig: SmacsFormConfig;
  smacsIcons = SmacsIcons;

  private _subscription = new Subscription();

  private static _unpackNumberRangeUpdate(formData: DialPlanExceptionForm): DnDidRange[] {
    return formData.dnRanges ? formData.dnRanges : [];
  }

  constructor(
    private dialPlanExceptionResource: DialPlanExceptionResource,
    protected smacsFormStateService: SmacsFormStateService,
    private modalComponent: DetailedModalComponent<any>
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.formConfig = {
      fields: {
        groupName: {
          label: 'tkey;dialplanmanagement.admin.group.name',
          dataAutomation: 'dial-plan-exception-group-name',
          required: true,
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          validation: [
            {
              validator: (val: string) =>
                val && !this.allGroupNames.includes(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;dialplanmanagement.admin.group.name.alreadyInUse',
            },
            {
              validator: (val: string) =>
                val.length <= GROUP_NAME_MAX_LENGTH
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: {
                content: 'tkey;validators.global.error.maxlength',
                params: {
                  maxlength: GROUP_NAME_MAX_LENGTH,
                },
              },
            },
          ],
        },
        dnRanges: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges',
          dataAutomation: 'dial-plan-exception-group-ranges',
          componentConfig: new SmacsExtensionRangesComponentConfig({
            optionalValidators: {
              overlappingRanges: true,
            },
          }),
        },
        exceptionList: {
          label: 'tkey;dnexception.admin.group.dnlist',
          dataAutomation: 'dial-plan-exception-list',
          componentConfig: new SmacsTextareaConfig(),
          validation: [
            {
              validator: (val: string) => {
                return !val ||
                  val
                    .split(',')
                    .filter((dn) => !!dn.trim())
                    .every((dn) => !!dn.match(/^\s*\+?[0-9]*\s*$/))
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: 'tkey;dnexception.admin.group.dnlistformat',
            },
          ],
        },
        legalHold: {
          label: 'tkey;dnexception.admin.group.legal_hold',
          labelToolTipText: 'tkey;dnexception.admin.group.legal_hold.tooltip',
          dataAutomation: 'forbid-modifications',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX }),
          labelToolTipIconClass: this.smacsIcons.INFO,
        },
      },
    } as SmacsFormConfig;

    this.allGroupNames = this.modalComponent.modalViewProperties.allGroups.map(
      (dng: DialPlanExceptionGroup) => dng.name
    );
    this.entitySource.next({
      ...this.modalComponent.modalViewProperties.dnExceptionGroup,
    });

    const validateAndSubmitSourceSub = this._validateAndSubmitSource.subscribe((value) => {
      this.isSubmitted = true;
    });
    this._subscription.add(validateAndSubmitSourceSub);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  isAlertHidden(): boolean {
    this.isChildFormValid = this.childComponent?.childFormComponent?.isFormValid();
    return !this.isSubmitted || (this.isFormValid() && this.isChildFormValid);
  }

  toFormData = (entity: DialPlanExceptionGroup): DialPlanExceptionForm => {
    return {
      id: entity.id,
      groupName: entity.name,
      dnRanges: entity.exceptionRanges,
      exceptionList: entity.exceptionValues ? entity.exceptionValues.join(', ') : '',
      legalHold: entity.legalHold,
    } as DialPlanExceptionForm;
  };

  toEntity = (formData: DialPlanExceptionForm): DialPlanExceptionGroup => {
    return {
      id: formData.id?.toString() || null,
      name: formData.groupName || '',
      exceptionRanges: DialPlanExceptionManagementEditComponent._unpackNumberRangeUpdate(formData),
      exceptionValues: formData.exceptionList
        ? formData.exceptionList
            .split(',')
            .filter((dn) => !dn.trim().match(/^\+?$/))
            .map((dn) => dn.trim())
        : [],
      legalHold: formData.legalHold,
    } as DialPlanExceptionGroup;
  };

  submit(): Observable<any> {
    const modalObs$ = new Observable<any>((subscriber) => {
      if (!this.childComponent.childFormComponent.isFormValid()) {
        this.isSubmitted = true;
        this._validateAndSubmitSource.next(false);
      } else {
        const reqBody = {
          ...this.entity,
          exceptionValues: uniq(this.entity.exceptionValues),
        };

        (!this.entity.id
          ? this.dialPlanExceptionResource.post(reqBody)
          : this.dialPlanExceptionResource.put(this.entity.id, reqBody)
        ).subscribe(() => {
          subscriber.next(reqBody);
          subscriber.complete();
        });
      }
    });
    return modalObs$.pipe();
  }
}
