import { Injectable } from '@angular/core';
import { CurrentUser } from '../models/generated/smacsModels';
import { combineLatest, ReplaySubject } from 'rxjs';
import { AuthenticationContext } from './authentication.context';
import { GlobalPropertiesContext } from './global-properties.context';

class LocalStorageSettings {
  [username: string]: UserLocalStorageSettings;
}

export class UserLocalStorageSettings {
  currentClusterId: string;
}

@Injectable()
export class LocalStorageContext {
  private _localStorageSource = new ReplaySubject<UserLocalStorageSettings>(1);
  state$ = this._localStorageSource.asObservable();

  private _versionedLocalStorageSettingsKey: string;
  private _currentUser: CurrentUser;

  constructor(
    private globalPropertiesContext: GlobalPropertiesContext,
    private authenticationContext: AuthenticationContext
  ) {
    combineLatest([globalPropertiesContext.state$, this.authenticationContext.state$]).subscribe(
      ([globalProperties, currentUser]) => {
        this._currentUser = currentUser;

        this._versionedLocalStorageSettingsKey = `smacs-${globalProperties.version}-smacs_settings_cache`;

        if (this._currentUser) {
          const localStorageSettings = this._getLocalStorageSettings();
          let userLocalStorageSetting = localStorageSettings[this._currentUser.userId];

          if (!userLocalStorageSetting) {
            console.info(
              `No localStorage entry for user [${this._currentUser.userId}] found for version [${globalProperties.version}]. Creating now.`
            );
            userLocalStorageSetting = { currentClusterId: null };
            localStorageSettings[this._currentUser.userId] = userLocalStorageSetting;
            this._setLocalStorageSettings(localStorageSettings);
          }

          this._localStorageSource.next(userLocalStorageSetting);
        } else {
          // Nobody is logged in, so localStorage should not be accessible
          this._localStorageSource.next(null);
        }
      }
    );
  }

  updateUserSettings(userLocalStorageSettings: UserLocalStorageSettings) {
    if (!this._currentUser) {
      throw new Error('Cannot update localStorage settings while unauthenticated!');
    }

    const localStorageSettings = this._getLocalStorageSettings();
    localStorageSettings[this._currentUser.userId] = userLocalStorageSettings;
    this._setLocalStorageSettings(localStorageSettings);
  }

  private _getLocalStorageSettings(): LocalStorageSettings {
    const initialLocalStorage = localStorage.getItem(this._versionedLocalStorageSettingsKey);
    return JSON.parse(initialLocalStorage) || {};
  }

  private _setLocalStorageSettings(localStorageSettings: LocalStorageSettings) {
    localStorage.setItem(this._versionedLocalStorageSettingsKey, JSON.stringify(localStorageSettings));
  }
}
