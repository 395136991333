import { Injectable } from '@angular/core';
import { MicrosoftLicensesConfigsResource } from '../../helpdesk/shared/resources/microsoft-licenses-configs.resource';
import { Microsoft365LicensesFieldConfig } from '../models/generated/smacsModels';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable()
export class MicrosoftLicensesContext {
  // public so stages can access
  _stateSource = new ReplaySubject<Microsoft365LicensesFieldConfig>(1);
  state$ = this._stateSource.asObservable();

  constructor(private microsoftLicensesConfigsResource: MicrosoftLicensesConfigsResource) {
    this.refresh().subscribe();
  }

  refresh(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.microsoftLicensesConfigsResource
        .getLicensesConfig()
        .subscribe((licenses: Microsoft365LicensesFieldConfig) => {
          this._stateSource.next(licenses);
          subscriber.next(null);
          subscriber.complete();
        });
    });
  }
}
