import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { SmacsRadioConfig } from '../../../forms/fields/radio/smacs-radio.component';
import { ModalBodyClass } from '../../../modals/modal-body';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { SmacsFormConfig } from '../../../forms/smacs-forms-models';
import { DetailedModalComponent } from '../../../modals/detailed-modal/detailed-modal.component';
import { UserPermissionsContext } from '../../contexts/user-permissions.context';
import {
  MicrosoftDialPlanGroup,
  MicrosoftSecurityGroup,
  Role,
  Type,
  UserGroupPermissions,
} from '../../../shared/models/generated/smacsModels';
import { ViewMembersState } from '../../../shared/view-members/view-members.component';
import { MicrosoftSecurityGroupsSearchResource } from '../../resources/microsoft-security-groups-search.resource';
import { SmacsSelectConfig } from '../../../forms/fields/select/smacs-select.component';

@Component({
  selector: 'ziro-user-permissions-modal',
  templateUrl: './user-permissions-modal.component.html',
  providers: [MicrosoftSecurityGroupsSearchResource],
})
export class UserPermissionsModalComponent extends ModalBodyClass implements OnInit {
  @ViewChild('errorTemplate') errorTemplate: TemplateRef<any>;

  Type = Type;

  isExisting: boolean;
  formConfig: SmacsFormConfig;
  permissionType: Type;
  usersInGroup: string[] = [];
  viewMembersState: ViewMembersState = ViewMembersState.IDLE;
  userGroupPermissions: UserGroupPermissions;

  private _dialPlanGroups: MicrosoftDialPlanGroup[] = [];

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _modalComponent: DetailedModalComponent<any>,
    private _microsoftUserPermissionsContext: UserPermissionsContext,
    private _microsoftSecurityGroupsSearchResource: MicrosoftSecurityGroupsSearchResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.userGroupPermissions = this._modalComponent.modalViewProperties.userPermission;
    this._dialPlanGroups = this._modalComponent.modalViewProperties.dialPlanGroups;
    this._initFormConfig();
    this.permissionType = this.userGroupPermissions.type;
    this.isExisting = !!this.userGroupPermissions.role;
    this.entitySource.next({
      role: this.userGroupPermissions.role,
      permittedDialPlanGroups: this.userGroupPermissions.permittedDialPlanGroups,
    });

    if (this.permissionType === Type.GROUP) {
      this._getGroupMembers();
    }
  }

  submit(): Observable<any> {
    const requestBody = {
      name: this.userGroupPermissions.name,
      type: this.userGroupPermissions.type,
      role: this.entity.role,
      permittedDialPlanGroups:
        this.entity.role === Role.S8_ADMIN || this.entity.role === Role.ZIRO_SUPPORT
          ? []
          : this.entity.permittedDialPlanGroups,
    };

    return this.isExisting
      ? this._microsoftUserPermissionsContext.put(requestBody)
      : this._microsoftUserPermissionsContext.post(requestBody);
  }

  private _initFormConfig() {
    const options = this._dialPlanGroups.map((group: MicrosoftDialPlanGroup) => {
      return group.name;
    });
    this.formConfig = {
      fields: {
        role: {
          label: 'tkey;admin.system.user_permissions.assign_roles.label',
          dataAutomation: 'user-permissions-modal-role',
          componentConfig: new SmacsRadioConfig<Role>({
            buttons: [
              {
                value: Role.S8_ADMIN,
                label: 'tkey;admin.system.user_permissions.administrator.label',
                helpText: 'tkey;admin.system.user_permissions.administrator.helptext',
              },
              {
                value: Role.S8_HELPDESK,
                label: 'tkey;admin.system.user_permissions.helpdesk.label',
                helpText: 'tkey;admin.system.user_permissions.helpdesk.helptext',
              },
            ],
            inline: false,
          }),
          required: true,
        },
        permittedDialPlanGroups: {
          label: 'tkey;admin.system.user_permissions.limit_helpdesk.label',
          helpText: 'tkey;admin.system.user_permissions.limit_helpdesk.helptext',
          dataAutomation: 'user-permissions-modal-limit-helpdesk',
          componentConfig: new SmacsSelectConfig({
            options: [...options],
            isMultiSelect: true,
          }),
          disabled: () =>
            this.formData.role === Role.S8_ADMIN || this.formData.role === Role.ZIRO_SUPPORT || !options.length,
          disabledTooltip: () => {
            return {
              content:
                this.formData.role === Role.S8_ADMIN || this.formData.role === Role.ZIRO_SUPPORT
                  ? 'tkey;admin.system.user_permissions.limit_helpdesk.tooltip'
                  : 'tkey;admin.system.user_permissions.limit_helpdesk.tooltip.no_groups',
              params: {},
            };
          },
        },
      },
      options: {
        columnClasses: {
          label: 'pt-0 col-lg-3 text-lg-end',
          input: '',
        },
      },
    };
  }

  private _getGroupMembers() {
    this.viewMembersState = ViewMembersState.SEARCHING;
    this._microsoftSecurityGroupsSearchResource
      .get(this.userGroupPermissions.name)
      .subscribe((data: MicrosoftSecurityGroup) => {
        this.usersInGroup = data.members;
        this.viewMembersState = ViewMembersState.IDLE;
      });
  }
}
