import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EndUserResult, Phone, PhoneRef } from '../../../../shared/models/generated/smacsModels';
import { ButtonStyles } from '../../../../button/button.component';
import { PhoneResource } from '../../../../shared/resources/phone.resource';
import { KnownPhoneModels } from '../../../shared/contexts/user-detail-ui.context';

interface AssociatedPhoneState extends PhoneRef {
  isUpdating?: boolean;
}

@Component({
  selector: 'smacs-associated-public-phones',
  templateUrl: './associated-public-phones.component.html',
})
export class SmacsAssociatedPublicPhonesComponent {
  @Input() associatedPublicPhones: AssociatedPhoneState[];
  @Input() endUser: EndUserResult;

  @Output() deviceFixed = new EventEmitter();

  buttonStyles = ButtonStyles;

  constructor(private phoneResource: PhoneResource) {}

  isDeskphone(phone: AssociatedPhoneState): boolean {
    return !(
      phone.model === KnownPhoneModels.CIPC ||
      phone.model === KnownPhoneModels.IM_SOFTPHONE ||
      phone.model === KnownPhoneModels.IPHONE ||
      phone.model === KnownPhoneModels.ANDROID ||
      phone.model === KnownPhoneModels.TABLET ||
      phone.model === KnownPhoneModels.SNR ||
      phone.model === KnownPhoneModels.CTI_PORT ||
      phone.model === KnownPhoneModels.CTI_REMOTE_DEVICE ||
      phone.model === KnownPhoneModels.SPARK_REMOTE_DEVICE
    );
  }

  onFixItClick(i: number) {
    const ref = this.associatedPublicPhones[i];
    this.associatedPublicPhones[i].isUpdating = true;

    this.phoneResource.get(ref.id, ref.serverId.toString()).subscribe((phone: Phone) => {
      phone.owner = this.endUser.ref;
      this.phoneResource.put(phone, ref.serverId.toString()).subscribe(() => {
        this.deviceFixed.emit(phone);
      });
    });
  }
}
