import { Component, OnInit } from '@angular/core';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { DialPlanExceptionResource } from '../../resources/dial-plan-exception.resource';
import { TranslateService } from '@ngx-translate/core';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { DialPlanExceptionGroup } from '../../../shared/models/generated/smacsModels';
import { ButtonStyles } from '../../../button/button.component';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { Observable } from 'rxjs';
import { DialPlanExceptionManagementEditComponent } from './dial-plan-exception-management-edit/dial-plan-exception-management-edit.component';
import { RangeFilterService } from '../../services/range-filter.service';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../shared/entity-table/entity-table.models';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { ZiroModalPromptModalOptions } from '../../../modals/prompt-modal/prompt-modal.component';

interface UiDialPlanExceptionGroup extends DialPlanExceptionGroup {
  exceptions?: string[];
}

@Component({
  selector: 'app-admin-exception-management',
  templateUrl: './dial-plan-exception-management.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [RangeFilterService],
})
export class DialPlanExceptionManagementComponent implements OnInit {
  isLoading = true;
  entityTable: EntityTable = {
    columns: [
      {
        columnId: 'groupName',
        cssColumnSize: 'col-sm-4',
        label: 'tkey;dialplanmanagement.admin.group.name',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'exceptions',
        cssColumnSize: 'col-sm-7',
        label: 'tkey;dnexception.admin.group.range.exceptions',
        filter: {
          type: EntityTableFilterTypes.TEXT,
          filterFn: (rowsToFilter: EntityTableContentRow[], filterValue: string, columnKey: string) => {
            return rowsToFilter.filter((row: EntityTableContentRow) => {
              const exceptions = row.content[columnKey] as string[];
              const allRanges = exceptions.map((exception: string) => {
                const split = exception.split(' - ');
                if (split.length > 1) {
                  return {
                    start: split[0],
                    end: split[1],
                  };
                } else {
                  return {
                    start: split[0],
                    end: split[0],
                  };
                }
              });
              return this._rangeFilterService.hasMatchingRanges(allRanges, filterValue);
            });
          },
        },
      },
    ],
    hasActions: true,
  };
  tableRows: EntityTableContentRow[] = [];

  private _dnExceptionGroups: UiDialPlanExceptionGroup[] = [];

  constructor(
    private _smacsModalService: SmacsModalService,
    private _smacsFormsStateService: SmacsFormStateService,
    private _toastService: ToastService,
    private _dialPlanExceptionResource: DialPlanExceptionResource,
    private _translateService: TranslateService,
    private _bottomNavService: BottomNavService,
    private _breadcrumbsService: BreadcrumbsService,
    private _rangeFilterService: RangeFilterService
  ) {}

  ngOnInit() {
    this._loadDnExceptionGroups().subscribe();
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;dnexception.admin.title' }]);
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'addGroupButton',
          label: 'tkey;dialplanmanagement.admin.add_group',
          icon: SmacsIcons.PLUS_CIRCLE,
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'bottom-nav-add-group-button',
          cb: () => {
            this._showEdit({
              exceptionRanges: [],
              exceptionValues: [],
              legalHold: false,
              name: '',
            } as DialPlanExceptionGroup);
          },
          state: {
            pending: false,
            buttonDisableState: { disabled: false, tooltipKey: '' },
          },
        },
      ])
    );
  }

  private _onEditClicked(dnExceptionGroup: DialPlanExceptionGroup) {
    this._showEdit(dnExceptionGroup);
  }

  private _onDeleteClicked(dnExceptionGroup: DialPlanExceptionGroup) {
    const options = {
      modalViewProperties: {
        promptBody: this._translateService.instant('tkey;global.sure.delete.dnexception.group', {
          name: dnExceptionGroup.name,
        }),
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onConfirmDeleteModalYes(dnExceptionGroup),
          },
        ],
      },
    };
    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _groupNameComparator = (g1: DialPlanExceptionGroup, g2: DialPlanExceptionGroup) =>
    g1.name.localeCompare(g2.name);

  private _getExceptionValues = (dnExceptionGroup: DialPlanExceptionGroup): string[] => {
    const exceptionRanges = (dnExceptionGroup.exceptionRanges || []).map((range) => `${range.start} - ${range.end}`);
    const exceptionValues = dnExceptionGroup.exceptionValues || [];

    return exceptionRanges.concat(exceptionValues);
  };

  private _loadDnExceptionGroups(): Observable<any> {
    return new Observable((subscriber) => {
      this._dialPlanExceptionResource.getAllDnExceptionGroups().subscribe((groups) => {
        this._dnExceptionGroups = groups.sort(this._groupNameComparator).map((group: DialPlanExceptionGroup) => {
          return {
            ...group,
            exceptions: this._getExceptionValues(group),
          };
        });
        this._generateTable();
        this.isLoading = false;
        subscriber.next();
      });
    });
  }

  private _generateTable() {
    this.tableRows = this._dnExceptionGroups.map((group: UiDialPlanExceptionGroup) => {
      return {
        content: {
          groupName: group.name,
          exceptions: group.exceptions,
        },
        html: {
          exceptions: group.exceptions
            .map((range: string) => {
              return `<span class="badge bg-secondary me-1">${range}</span>`;
            })
            .join(''),
        },
        actions: [
          {
            buttonStyle: ButtonStyles.PRIMARY,
            dataAutomation: 'edit-exception-group-button',
            icon: SmacsIcons.EDIT,
            onClick: () => this._onEditClicked(group),
          },
          {
            buttonStyle: ButtonStyles.DANGER,
            dataAutomation: 'delete-exception-group-button',
            icon: SmacsIcons.DELETE,
            onClick: () => this._onDeleteClicked(group),
          },
        ],
      };
    });
  }

  private _showEdit(dnExceptionGroup: DialPlanExceptionGroup) {
    const options = {
      modalViewProperties: {
        title: 'tkey;dnexception.admin.group.edit',
        dnExceptionGroup: { ...dnExceptionGroup },
        allGroups: this._dnExceptionGroups.filter(
          (group: UiDialPlanExceptionGroup) => group.id !== dnExceptionGroup.id
        ),
      },
      bodyClass: DialPlanExceptionManagementEditComponent,
      size: 'lg',
    } as ZiroModalPromptModalOptions;
    this._smacsModalService
      .openDetailedModal(() => options.modalViewProperties, options)
      .subscribe((result) => {
        if (result) {
          this._loadDnExceptionGroups().subscribe(() => {
            this._smacsFormsStateService.setIsFormDirty(false);
            this._toastService.pushSaveToast('tkey;dnexceptiongroup.name', result.name, SmacsIcons.DIAL_PLAN_GROUP);
          });
        }
      });
  }

  private _onConfirmDeleteModalYes(dnExceptionGroup: DialPlanExceptionGroup): Observable<any> {
    return new Observable((subscriber) => {
      this._dialPlanExceptionResource.delete(dnExceptionGroup.id).subscribe(() => {
        this._loadDnExceptionGroups().subscribe(() => {
          this._toastService.pushDeleteToast('tkey;dnexceptiongroup.name', dnExceptionGroup.name);
          subscriber.next(true);
        });
      });
    });
  }
}
