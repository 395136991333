import { Injectable, Type } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PromptModalComponent, ZiroModalPromptModalOptions } from '../../modals/prompt-modal/prompt-modal.component';
import {
  DetailedModalComponent,
  ZiroModalDetailedModalOptions,
} from '../../modals/detailed-modal/detailed-modal.component';
import { from, Observable } from 'rxjs';

export interface CustomModal<T> {
  modalProperties: T;
}

@Injectable()
export class SmacsModalService {
  defaultModalOptions = {
    animation: false,
    size: 'md',
    backdrop: 'static',
    keyboard: false,
  } as NgbModalOptions;

  errorModalType = {
    NO_SITE: 'NO_SITE',
    NOT_SUPPORTED: 'NOT_SUPPORTED',
    FORBIDDEN: 'FORBIDDEN',
  };

  constructor(private modalService: NgbModal) {}

  openCustomModal<T>(modalClass: Type<CustomModal<T>>, modalProperties: T, size: string): Observable<any> {
    const ngbModalRef = this.modalService.open(modalClass, { ...this.defaultModalOptions, size });
    ngbModalRef.componentInstance.modalProperties = modalProperties;
    return from(ngbModalRef.result);
  }

  openPromptModal(viewPropsFn: any = null, options: ZiroModalPromptModalOptions): Observable<any> {
    const modalOptions: any = Object.assign({}, this.defaultModalOptions, options);
    modalOptions['controller'] = 'PromptModalComponent';

    if (viewPropsFn) {
      modalOptions['modalViewProperties'] = {
        ...viewPropsFn(),
      };
    }

    const modalInstance = this.modalService.open(PromptModalComponent, modalOptions);
    modalInstance.componentInstance.bodyClass = modalOptions['bodyClass'];
    modalInstance.componentInstance.bodyInputs = modalOptions['bodyInputs'];
    modalInstance.componentInstance.size = modalOptions['size'];
    modalInstance.componentInstance.modalViewProperties = modalOptions['modalViewProperties'];
    return new Observable((subscriber) => {
      modalInstance.closed.subscribe((result) => {
        subscriber.next(result);
      });
    });
  }

  openDetailedModal(viewPropsFn: any = null, options: ZiroModalDetailedModalOptions): Observable<any> {
    const modalOptions: any = Object.assign({}, this.defaultModalOptions, options);
    modalOptions['controller'] = 'DetailedModalComponent';

    if (viewPropsFn) {
      modalOptions['modalViewProperties'] = {
        ...viewPropsFn(),
      };
    }

    const modalInstance = this.modalService.open(DetailedModalComponent, modalOptions);
    modalInstance.componentInstance.bodyClass = modalOptions['bodyClass'];
    modalInstance.componentInstance.bodyInputs = modalOptions['bodyInputs'];
    modalInstance.componentInstance.size = modalOptions['size'];
    modalInstance.componentInstance.modalViewProperties = modalOptions['modalViewProperties'];
    return new Observable((subscriber) => {
      modalInstance.closed.subscribe((result) => {
        subscriber.next(result);
      });
    });
  }

  dismissOpenModals() {
    return this.modalService.dismissAll();
  }
}
