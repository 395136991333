import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { NavProps, NavSectionLink } from '../side-navigation.model';

@Component({
  selector: 'side-navigation-section',
  templateUrl: './side-navigation-section.component.html',
  styleUrls: ['./side-navigation-section.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0, maxHeight: '0%' }),
        animate('0.25s ease-in', style({ opacity: 1, maxHeight: '60%' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, maxHeight: '60%' }),
        animate('0.25s ease-out', style({ opacity: 0, maxHeight: '0%' })),
      ]),
    ]),
  ],
})
export class SideNavigationSectionComponent implements OnInit, AfterViewInit {
  @Input() icon: string;
  @Input() isMinimized = false;
  @Input() sectionTitle: string;
  @Input() dataAutomation: string;
  @Input() isLink = false;
  @Input() link = '';
  @Input() isDisabled?: boolean = false;
  @Input() disabledTooltip?: string;
  @Input() isVisible?: boolean = true;
  @Input() childLinks: NavSectionLink[];
  @Input() navProps: NavProps;
  @Input() searchFilter = '';

  @Output() clickEvent = new EventEmitter<NavSectionLink>();
  @Output() toggleMinimized = new EventEmitter<void>();

  active = false;
  isLinkActive = false;

  constructor(private changeDetection: ChangeDetectorRef, private _router: Router) {}

  ngOnInit() {
    this.isLinkActive = this.isLink && this._router.url.endsWith(this.link);
    this.active = this._childLinkActive(this.childLinks, this._router.url);

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLinkActive = this.isLink && this._router.url.endsWith(this.link);
        this.active = this._childLinkActive(this.childLinks, this._router.url);
      }
    });
  }

  ngAfterViewInit(): void {
    this.changeDetection.detectChanges();
  }

  handleSectionHeaderClick() {
    /* If the "section" isn't really a section but just a top level link we just navigate.
    e.g.  the Audits link in the Admin Side Nav */
    if (this.isLink && !this.isDisabled) {
      this._router.navigateByUrl(this.link);
    } else {
      // Else we just toggle the active state of this section
      this.active = !this.active;
    }

    // If we click one of the icons when the sidenav is minimized, we also need to expand the sidenav
    if (this.isMinimized) {
      this.toggleMinimized.emit();
    }
  }

  handleClickEvent(link: NavSectionLink) {
    this.clickEvent.emit(link);
  }

  private _childLinkActive(links: NavSectionLink[], route: string): boolean {
    return !!links?.find(
      (section: NavSectionLink) => route?.includes(section.link) || this._childLinkActive(section.links, route)
    );
  }
}
