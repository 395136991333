import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { DefaultValueService } from '../../shared/services/default-value.service';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../button/button.component';
import { CustomModal } from '../../shared/services/smacs-modal.service';
import {
  VariableEditorFunctionProps,
  VariableEditorOption,
  VariableEditorService,
  VariableEditorType,
} from './variable-editor.service';

interface VariableEditorModalViewProps {
  type: VariableEditorType;
  value?: string;
  dynamicPreviewValue?: string;
}

@Component({
  selector: 'app-edit-custom-input-text-modal',
  templateUrl: './variable-editor-modal.component.html',
  styleUrls: ['./variable-editor-modal.component.scss'],
})
export class VariableEditorModalComponent implements CustomModal<VariableEditorModalViewProps>, OnInit {
  modalProperties: VariableEditorModalViewProps;
  variableEditorInputForm: UntypedFormGroup;
  variableOptions: VariableEditorOption[] = [];
  functionOneOptions: VariableEditorFunctionProps[] = [];
  functionTwoOptions: VariableEditorFunctionProps[] = [];
  state = {
    isAddDisabled: true,
    isExpressionPreviewVisible: false,
    expressionPreview: '',
    isFunctionOneVisible: false,
    isFunctionTwoVisible: false,
    expressionResult: '',
  };
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  selectedVariable = {} as VariableEditorOption;
  functionOne = {} as VariableEditorFunctionProps;
  functionTwo = {} as VariableEditorFunctionProps;
  expressionPreview: string;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private translateService: TranslateService,
    private defaultValueService: DefaultValueService,
    private variableEditorService: VariableEditorService
  ) {}

  ngOnInit() {
    const defaultOptions = this.variableEditorService.getOptions(this.modalProperties.type);
    this.variableOptions = [
      {
        label: this.translateService.instant('tkey;site_management.site.edit_custom_input_value.choose_variable.text'),
      } as VariableEditorOption,
      ...cloneDeep(defaultOptions),
    ];
    const defaultFunctions: VariableEditorFunctionProps[] = this.variableEditorService.getFunctions(
      this.modalProperties.type
    );
    this.functionOneOptions = [
      {
        label: this.translateService.instant(
          'tkey;site_management.site.edit_custom_input_value.expression_one_apply.text'
        ),
      } as VariableEditorFunctionProps,
      ...cloneDeep(defaultFunctions),
    ];
    this.functionTwoOptions = [
      {
        label: this.translateService.instant(
          'tkey;site_management.site.edit_custom_input_value.expression_two_apply.text'
        ),
      } as VariableEditorFunctionProps,
      ...cloneDeep(defaultFunctions),
    ];

    this._buildInputForm();

    this.selectedVariable = this.variableOptions[0];
    this.functionOne = this.functionOneOptions[0];
    this.functionTwo = this.functionTwoOptions[0];
  }

  onConfirmClick() {
    const returnValue = {
      value: this.variableEditorInputForm.get('value').value,
      preview: this._expressionPreview(),
    };
    this.activeModal.close(returnValue);
  }

  onCancelClick() {
    this.activeModal.dismiss();
  }

  onAddClick() {
    if (this.variableEditorInputForm.get('value').value) {
      const currentValue = this.variableEditorInputForm.get('value').value;
      const stringLength = currentValue.length;
      const index = stringLength;
      const preInsertion = currentValue.substring(0, index);
      const insertion = this._getHandlebarsExpression();
      const postInsertion = currentValue.substring(index, stringLength);
      const value = preInsertion + insertion + postInsertion;
      this.variableEditorInputForm.patchValue({
        value: value,
      });
    } else {
      this.variableEditorInputForm.patchValue({
        value: this._getHandlebarsExpression(),
      });
    }
    this._resetState();
  }

  addPreviewVariableOptions(option: VariableEditorOption): string {
    return this.defaultValueService.updatePreviewLabel(option.label);
  }

  onChange() {
    if (!this.selectedVariable || !this.selectedVariable.value) {
      this._resetState();
    } else {
      this._resetFunctionOpts('funcOne');
      this._resetFunctionOpts('funcTwo');
      this.state.isAddDisabled = false;
      this.state.isExpressionPreviewVisible = true;
      this.state.isFunctionOneVisible = true;
    }
    this._getPreview();
  }

  onFunctionOneChange(event: Event) {
    if (!event) {
      this.functionOne = {} as VariableEditorFunctionProps;
      this.functionTwo = {} as VariableEditorFunctionProps;
      this.state.isFunctionTwoVisible = false;

      /** Enable them */
      this._resetFunctionOpts('funcOne');
      this._resetFunctionOpts('funcTwo');
    } else {
      /** Disable function selected in functionTwo */
      this.functionTwoOptions = this.functionTwoOptions.map((option) => {
        option['disabled'] = option.label === this.functionOne.label;
        return option;
      });

      this.state.isFunctionTwoVisible = true;
    }
    this._getPreview();
  }

  onFunctionTwoChange(event: Event) {
    if (!event) {
      /** Enable the cleared option of function two in function one. */
      this._resetFunctionOpts('funcOne');
      this.functionTwo = {} as VariableEditorFunctionProps;
    } else {
      /** Disable function selected in functionOne */
      this.functionOneOptions = this.functionOneOptions.map((option) => {
        option['disabled'] = option.label === this.functionTwo.label;
        return option;
      });
    }
    this._getPreview();
  }

  private _buildInputForm() {
    this.variableEditorInputForm = this.formBuilder.group({
      value: this.modalProperties.value || '',
    });
  }

  /**
   * Reset state to defaults
   */
  private _resetState() {
    this.selectedVariable = this.variableOptions[0];
    this.functionOne = {
      label: '',
      value: '',
      disabled: false,
    } as VariableEditorFunctionProps;
    this.functionTwo = {} as VariableEditorFunctionProps;
    this.state.isAddDisabled = true;
    this.state.isExpressionPreviewVisible = false;
    this.state.expressionPreview = '';
    this.state.isFunctionOneVisible = false;
    this.state.isFunctionTwoVisible = false;

    this._resetFunctionOpts('funcOne');
    this._resetFunctionOpts('funcTwo');
  }

  /**
   * Build handlebars expression based on selected options
   */
  private _getHandlebarsExpression() {
    const variable = this.selectedVariable.value;
    const functionOne = this.functionOne?.value;
    const functionTwo = this.functionTwo?.value;

    if (variable && functionOne && functionTwo) {
      return `{{ ${functionTwo} (${functionOne}  ${variable}) }}`;
    } else if (variable && functionOne) {
      return `{{ ${functionOne} ${variable} }}`;
    } else if (variable) {
      return `{{ ${variable} }}`;
    }

    return '';
  }

  /**
   * Get compiled handlebars statement
   */
  private _getPreview() {
    const handlebarsExpression = this._getHandlebarsExpression();
    this.state.expressionPreview = this.defaultValueService.generatePreview(handlebarsExpression);
    this.expressionPreview = cloneDeep(this.state.expressionPreview);
  }

  private _resetFunctionOpts(functionType: string) {
    if (
      this.selectedVariable.value === 'did' &&
      !this.functionOneOptions.some((f) => f.value === 'toInternationalNumberFormat')
    ) {
      this.functionOneOptions.push({
        label: this.translateService.instant(
          'tkey;shared.custom_input_text.expression_editor.apply_international.label'
        ),
        value: 'toInternationalNumberFormat',
        disabled: false,
      });
    }
    switch (functionType) {
      case 'funcOne': {
        this.functionOneOptions = this.functionOneOptions
          .filter((func) =>
            func.value === 'toInternationalNumberFormat' ? this.selectedVariable.value === 'did' : true
          )
          .map((option) => {
            option['disabled'] = false;
            return option;
          });
        break;
      }
      case 'funcTwo': {
        this.functionTwoOptions = this.functionTwoOptions
          .filter((func) => func.value !== 'toInternationalNumberFormat')
          .map((option) => {
            option['disabled'] = false;
            return option;
          });
        break;
      }
      default:
        break;
    }
  }

  private _expressionPreview(): string {
    return this.defaultValueService.generatePreview(this.variableEditorInputForm.get('value').value);
  }

  protected readonly ButtonSizes = ButtonSizes;
}
