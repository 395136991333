import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import {
  MultiSelectFieldConfig,
  PhoneServiceParameter,
  PhoneServiceSubscription,
} from '../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import {
  SmacsNavsetComponent,
  SmacsNavsetConfig,
  SmacsNavsetItemConfig,
} from '../../../../shared/smacs-navset/smacs-navset.component';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { isEqual } from 'lodash';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { PhoneUiContext } from '../../../../shared/phone-buttons/contexts/phone-ui.context';
import { filter, first } from 'rxjs/operators';

type ServiceSubscriptionParamData = { id: string; [param: string]: string };
type ServiceSubscriptionData = { [key: string]: ServiceSubscriptionParamData[] };

interface PhoneServicesFormData {
  serviceSubscriptions: ServiceSubscriptionData;
}

@Component({
  selector: 'smacs-phone-services-form',
  templateUrl: './phone-services-form.component.html',
})
export class PhoneServicesFormComponent
  extends SmacsFormAbstractDirective<PhoneServiceSubscription[], PhoneServicesFormData>
  implements OnInit, AfterViewInit
{
  @ViewChild(SmacsNavsetComponent) navsetComponent: SmacsNavsetComponent;

  @Input() serviceSubscriptionFieldConfig: MultiSelectFieldConfig<PhoneServiceSubscription>;

  formConfig: SmacsFormConfig;
  possibleOptions: PhoneServiceSubscription[];

  constructor(protected smacsFormStateService: SmacsFormStateService, private phoneUiContext: PhoneUiContext) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.formConfig = {
      fields: {
        serviceSubscriptions: {
          componentConfig: new SmacsNavsetConfig({
            dropdownLabel: 'tkey;shared.device.phone.service_subscriptions.add_subscriptions.navlink',
            dropdownTooltip: 'tkey;shared.device.phone.service_subscriptions.none.text',
            emptyMessage: 'tkey;shared.device.phone.service_subscriptions.none.text',
            navsetItemsConfig: this._initPhoneServiceConfig(),
            allowDuplicates: true,
          }),
        },
      },
    };
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.smacsFormsUpdate$.subscribe((update) => {
      if (!isEqual(update.new, update.old)) {
        this.navsetComponent.form?.formNav.formNavItems.forEach((navItemComponent) => {
          Object.keys(navItemComponent.fieldChannels).forEach((key) => {
            navItemComponent.fieldChannels[key].validateSource.next();
          });
        });
      }
    });
  }

  submit() {
    return this.phoneUiContext.isSaving$.pipe(
      filter((isSaving) => !isSaving),
      first()
    );
  }

  private _initPhoneServiceConfig(): SmacsNavsetItemConfig {
    const phoneServiceFormData: SmacsNavsetItemConfig = {};
    this.possibleOptions = this.serviceSubscriptionFieldConfig.possibleOptions ?? [];
    // Add all existing subscriptions to the possible options.
    this.entity?.forEach((existingSubscription) => {
      if (!this.possibleOptions.find((option) => option.phoneServiceId === existingSubscription.phoneServiceId)) {
        this.possibleOptions.push(existingSubscription);
      }
    });

    this.possibleOptions.forEach((serviceSubscription: PhoneServiceSubscription) => {
      const key = serviceSubscription.phoneServiceName;
      const phoneServiceDefaultValue: any = {};
      const labelFieldId = `${key}-label-field`;
      phoneServiceDefaultValue[labelFieldId] = key;
      phoneServiceFormData[key] = {
        label: key,
        labelFieldId,
        defaultValue: phoneServiceDefaultValue,
        fields: [
          {
            label: 'tkey;shared.device.phone.service_subscriptions.name.label',
            dataAutomation: labelFieldId,
            componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
            required: true,
            validation: [
              {
                validator: (val) => {
                  return val && val.length > 32 ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
                },
                message: {
                  content: 'tkey;validators.global.error.maxlength',
                  params: {
                    maxlength: 32,
                  },
                },
              },
              {
                validator: (val: string): SmacsFormsValidationState => {
                  const duplicates = this.entity?.filter(
                    (serviceSub: PhoneServiceSubscription) => serviceSub.phoneServiceSubscriptionName === val
                  );
                  return duplicates?.length > 1 ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
                },
                message:
                  'tkey;shared.device.phone.phone_service_subscriptions.edit_subscription.unique_name.error.helpblock',
              },
            ],
          },
        ],
      };

      serviceSubscription.parameters.forEach((parameter: PhoneServiceParameter) => {
        const parameterFieldId = `${parameter.name}-parameter-field`;
        phoneServiceDefaultValue[parameterFieldId] = parameter.defaultValue || '';
        const inputType = parameter.password ? HtmlInputType.PASSWORD : HtmlInputType.TEXT;
        phoneServiceFormData[key].fields.push({
          label: parameter.displayName,
          dataAutomation: parameterFieldId,
          componentConfig: new SmacsTextConfig({ htmlInputType: inputType }),
          required: parameter.required,
        });
      });
      if (phoneServiceFormData[key].fields[1]) {
        phoneServiceFormData[key].fields[1].title = 'tkey;shared.device.phone.service_subscriptions.parameters.label';
      }
    });
    return phoneServiceFormData;
  }

  toEntity = (formData: PhoneServicesFormData): PhoneServiceSubscription[] => {
    const serviceSubscriptions: PhoneServiceSubscription[] = [];
    if (formData?.serviceSubscriptions) {
      Object.entries(formData.serviceSubscriptions).forEach(([key, subscriptionData]) => {
        const phoneService = this.possibleOptions.find((serviceSub) => serviceSub.phoneServiceName === key);
        subscriptionData.forEach((entry) => {
          const parameters: PhoneServiceParameter[] = phoneService.parameters.map(
            (parameter: PhoneServiceParameter) => {
              return {
                displayName: parameter.displayName,
                defaultValue: entry[`${parameter.name}-parameter-field`],
                name: parameter.name,
                password: parameter.password,
                required: parameter.required,
              };
            }
          );

          serviceSubscriptions.push({
            phoneServiceId: phoneService.phoneServiceId,
            phoneServiceName: phoneService.phoneServiceName,
            phoneServiceSubscriptionName: entry[`${key}-label-field`],
            parameters,
          });
        });
      });
    }
    return serviceSubscriptions;
  };

  toFormData = (entity: PhoneServiceSubscription[]): PhoneServicesFormData => {
    const navsetData: ServiceSubscriptionData = {};
    entity?.forEach((subscription: PhoneServiceSubscription) => {
      const entry: ServiceSubscriptionParamData = { id: subscription.phoneServiceId };
      entry[`${subscription.phoneServiceName}-label-field`] = subscription.phoneServiceSubscriptionName;
      subscription.parameters.forEach((parameter: PhoneServiceParameter) => {
        entry[`${parameter.name}-parameter-field`] = parameter.defaultValue;
      });

      if (!navsetData[subscription.phoneServiceName]) {
        navsetData[subscription.phoneServiceName] = [];
      }

      navsetData[subscription.phoneServiceName].push({ ...entry });
    });
    return { serviceSubscriptions: navsetData };
  };
}
