import { Component, Input } from '@angular/core';

@Component({
  selector: 'smacs-search-result-section',
  templateUrl: './search-result-section.component.html',
  styleUrls: ['./search-result-section.component.scss'],
})
export class SmacsSearchResultSectionComponent {
  @Input() resultsCount: number;
  @Input() heading: string;
}
