import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MicrosoftTeamsDevice } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class DeviceService {
  constructor(private http: HttpClient) {}

  getDeviceStatus(id: string): Observable<MicrosoftTeamsDevice> {
    return this.http.get<MicrosoftTeamsDevice>(`/services/microsoft/macs/teams-devices/${id}`);
  }
}
