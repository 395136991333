<div
  class="modal-body modal-body--flex modal-body--inner"
  [ngClass]="{ 'modal-body--tall': modalComponent.modalViewProperties.isButtonConflictPresent }"
>
  <div
    *ngIf="modalComponent.modalViewProperties.changeType === 'phone model'"
    class="text-center"
    data-automation="confirmation-modal-message"
  >
    <p *ngIf="modalComponent.modalViewProperties.isButtonConflictPresent && !isCopyUdpButton">
      {{
        'tkey;phone.phone_button_layout_conflict_modal.main.phone_model'
          | translate : { change: modalComponent.modalViewProperties.proposedValue }
      }}
    </p>
    <p *ngIf="!modalComponent.modalViewProperties.isButtonConflictPresent && !isCopyUdpButton">
      {{
        'tkey;phone.phone_button_layout_conflict_modal.main.phone_model.without_preview'
          | translate : { change: modalComponent.modalViewProperties.proposedValue }
      }}
    </p>
    <p
      *ngIf="isCopyUdpButton"
      [innerHTML]="'tkey;phone.phone_button_layout_conflict_modal.main.phone_model.copy_from_udp' | translate"
    ></p>
  </div>
  <div *ngIf="isCopyUdpButton" class="text-center" data-automation="confirmation-modal-message-tip">
    <p [innerHTML]="'tkey;phone.phone_button_layout_conflict_modal.main.phone_model.copy_from_udp.tip' | translate"></p>
    <p
      [innerHTML]="'tkey;phone.phone_button_layout_conflict_modal.main.phone_model.copy_from_udp.warning' | translate"
      class="text-center text-warning"
      data-automation="phone-button-layout-conflict-modal-copy-udp-warning"
    ></p>
  </div>
  <p
    *ngIf="modalComponent.modalViewProperties.changeType === 'phone button template'"
    class="text-center"
    data-automation="confirmation-modal-message"
  >
    {{
      'tkey;phone.phone_button_layout_conflict_modal.main.phone_button_template'
        | translate : { change: modalComponent.modalViewProperties.proposedValue }
    }}
  </p>

  <p
    *ngIf="
      modalComponent.modalViewProperties.currentExpansionModules?.length === 0 &&
      modalComponent.modalViewProperties.isButtonConflictPresent &&
      !isCopyUdpButton
    "
    class="text-center text-warning"
    data-automation="phone-button-layout-conflict-modal-warning"
  >
    {{ 'tkey;phone.phone_button_layout_conflict_modal.warning.buttons' | translate }}
  </p>
  <p
    *ngIf="
      modalComponent.modalViewProperties.currentExpansionModules?.length > 0 &&
      !modalComponent.modalViewProperties.isButtonConflictPresent
    "
    class="text-center text-warning"
    data-automation="phone-button-layout-conflict-modal-warning"
  >
    {{
      'tkey;phone.phone_button_layout_conflict_modal.warning.expansion_modules'
        | translate : { expansionModules: modalComponent.modalViewProperties.currentExpansionModules?.join(', ') }
    }}
  </p>
  <p
    *ngIf="
      modalComponent.modalViewProperties.currentExpansionModules?.length > 0 &&
      modalComponent.modalViewProperties.isButtonConflictPresent
    "
    class="text-center text-warning"
    data-automation="phone-button-layout-conflict-modal-warning"
  >
    {{
      'tkey;phone.phone_button_layout_conflict_modal.warning.both'
        | translate : { expansionModules: modalComponent.modalViewProperties.currentExpansionModules?.join(', ') }
    }}
  </p>

  <div
    *ngIf="modalComponent.modalViewProperties.isButtonConflictPresent"
    class="overflow-auto"
    id="scrollableButtonRowTable"
  >
    <table class="table table--scrollable">
      <thead>
        <tr class="table--scrollable__header">
          <th class="table-col-1"></th>
          <th class="table-col-5" data-automation="phone-button-layout-conflict-modal-current-value">
            <strong>
              {{ modalComponent.modalViewProperties.currentValue }} ({{
                !isCopyUdpButton
                  ? ('tkey;phone.phone_button_layout_conflict_modal.current' | translate)
                  : ('tkey;phone.phone_button_layout_conflict_modal.current.extension_mobility' | translate)
              }})
            </strong>
          </th>
          <th class="table-col-1"></th>
          <th class="table-col-5" data-automation="phone-button-layout-conflict-modal-new-value">
            <strong>
              {{ modalComponent.modalViewProperties.proposedValue }} ({{
                !isCopyUdpButton
                  ? ('tkey;phone.phone_button_layout_conflict_modal.new' | translate)
                  : ('tkey;phone.phone_button_layout_conflict_modal.new.deskphone' | translate)
              }})
            </strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let row of buttonRows; let i = index"
          [ngClass]="{
            'table-warning': (row.current.value && !row.proposed.value) || (row.current.label && !row.proposed.label)
          }"
          data-automation="phone-button-layout-conflict-modal-button-row"
        >
          <td class="table-cell">
            <span *ngIf="row.current">{{ i + 1 }}</span>
          </td>
          <td class="table-cell">
            <span *ngIf="row.current">
              <span data-automation="phone-button-layout-conflict-modal-current-type">{{ row.current.type }}</span>
              <br />
              <span data-automation="phone-button-layout-conflict-modal-current-description">
                {{ row.current.value ? ' ' + row.current.value : ''
                }}{{ row.current.label ? ' - ' + row.current.label : '' }}
              </span>
            </span>
          </td>
          <td class="table-cell">
            <span *ngIf="row.proposed">{{ i + 1 }}</span>
          </td>
          <td class="table-cell">
            <span *ngIf="row.proposed">
              <span data-automation="phone-button-layout-conflict-modal-new-type">{{ row.proposed.type }}</span>
              <br />
              <span data-automation="phone-button-layout-conflict-modal-new-description">
                {{ row.proposed.value ? ' ' + row.proposed.value : ''
                }}{{ row.proposed.label ? ' - ' + row.proposed.label : '' }}
              </span>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
