<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" *ngIf="!isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div
          [innerHTML]="
            'tkey;automate.zpm.bulk_provisioning.previous_job.about_card'
              | translate
                : {
                    lastRunDate: getDateFromTimestamp(bulkJobStatus.jobStatus.lastRunTime),
                    lastRunTime: getTimeFromTimestamp(bulkJobStatus.jobStatus.lastRunTime),
                    auditTag: bulkJobStatus.jobDetailedStatus?.jobName,
                    completedUsers: bulkJobStatus.jobDetailedStatus?.completedUsers.length,
                    failedUsers: bulkJobStatus.jobDetailedStatus?.failedUsers.length
                  }
          "
        ></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <ziro-zpm-bulk-provisioning-status-entity-table
      [tableRows]="tableRows"
      [isViewLoading]="isLoadingTable"
    ></ziro-zpm-bulk-provisioning-status-entity-table>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
