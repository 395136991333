<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.bandwidth_account_settings.card.message' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div class="card mt-3">
      <div class="card-header">{{ 'tkey;admin.bandwidth_account_settings.card.label' | translate }}</div>
      <div class="card-body">
        <smacs-text [fieldId]="'username'"></smacs-text>
        <smacs-text [fieldId]="'password'"></smacs-text>
        <smacs-text [fieldId]="'dlrAccountId'"></smacs-text>
        <smacs-text [fieldId]="'tlsVoiceAccountId'"></smacs-text>
        <smacs-select [fieldId]="'tlsVoiceEnvironmentType'"></smacs-select>
      </div>
    </div>

    <div class="alert alert-info refresh-alert">
      {{ 'tkey;admin.bandwidth_account_settings.refresh_card.message' | translate }}
      <br />
      <a *ngIf="!isRefreshing" class="strong cache-refresh-link" (click)="refreshCache()" target="_blank">
        <i [attr.class]="smacsIcons.REFRESH"></i>
        {{ 'tkey;admin.bandwidth_account_settings.refresh.link.text' | translate }}
      </a>

      <a *ngIf="isRefreshing" class="strong text-warning disabled disabled-refresh-link" target="_blank">
        <i [attr.class]="smacsIcons.SAVING"></i>
        {{ 'tkey;admin.bandwidth_account_settings.refreshing.link.text' | translate }}
      </a>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
