import { Component, Input, ViewChild } from '@angular/core';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import {
  SmacsFieldComponentConfig,
  SmacsFormsUpdate,
  SmacsFormsValidationState,
} from '../../../forms/smacs-forms-models';
import { SmacsIcons } from '../../models/smacs-icons.enum';
import { SmacsFieldAbstractDirective } from '../../../forms/smacs-field-abstract.directive';
import { SelectFieldConfig } from '../../models/generated/smacsModels';
import { SelectConfigFormComponent } from './select-config-form/select-config-form.component';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep, isEqual } from 'lodash';

export interface SelectConfigTranslations {
  description?: string;
}

export interface SelectConfigEntity extends SelectFieldConfig<string> {
  defaultValues: string[]; // need an array to hold values for multi select
}

export class SmacsSelectFieldConfig extends SmacsFieldComponentConfig {
  constructor(
    public isMultiSelect: boolean,
    public availableOptions: string[],
    public isRequiredVisible: boolean = true,
    public isAlwaysRequired: boolean = false,
    public configTranslations?: SelectConfigTranslations
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-select-config',
  templateUrl: './legacy-smacs-select-config.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: LegacySmacsSelectConfigComponent }],
})
export class LegacySmacsSelectConfigComponent extends SmacsFieldAbstractDirective<
  SelectConfigEntity,
  SelectConfigEntity,
  SmacsSelectFieldConfig
> {
  smacsIcons = SmacsIcons;
  availableOptions: string[];
  isLoading = true;
  isRequiredVisible = true;
  isAlwaysRequired = false;
  translations: SelectConfigTranslations;

  @Input() isMultiSelect = false;

  @ViewChild(SelectConfigFormComponent) childFormComponent: SelectConfigFormComponent;

  constructor(protected smacsFormStateService: SmacsFormStateService, private translateService: TranslateService) {
    super(smacsFormStateService);

    this.translateService.get(this.translateService.currentLang).subscribe(() => {
      this.isLoading = false;
    });
  }

  applyComponentConfig = ({
    isMultiSelect,
    availableOptions,
    isRequiredVisible,
    isAlwaysRequired,
    configTranslations,
  }: SmacsSelectFieldConfig) => {
    this.isMultiSelect = isMultiSelect;
    this.availableOptions = availableOptions;
    this.isRequiredVisible = isRequiredVisible;
    this.isAlwaysRequired = isAlwaysRequired;
    this.translations = {
      description: configTranslations?.description || 'tkey;site_management.site.section.select.modal.description',
    };
  };

  onFormUpdate($event: SmacsFormsUpdate<SelectConfigEntity>) {
    if (!isEqual($event.new, $event.old)) {
      this.entity = $event.new;
      this.updateSelf(this.entity, true, $event.old);
      this.updateParent(cloneDeep($event.old));
    } else if (this.childFormComponent) {
      // This forces validation state to parent form
      if (
        (this.childFormComponent.isFormValid() && this.validationState !== SmacsFormsValidationState.VALID) ||
        (!this.childFormComponent.isFormValid() && this.validationState !== SmacsFormsValidationState.INVALID)
      ) {
        this.validationState = this.childFormComponent
          ? this.childFormComponent.isFormValid()
            ? SmacsFormsValidationState.VALID
            : SmacsFormsValidationState.INVALID
          : SmacsFormsValidationState.VALID;

        this.updateParent();
      }
    }
  }
}
