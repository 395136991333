import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastNoAnimationModule, ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './routing/routing.module';
import { ServiceLocator } from '../../../workbench/src/app/locator.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouteReuseStrategy } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SvgLoaderContext } from '../../../components/src/lib/shared/contexts/svg-loader.context';
import { SharedModule } from 'primeng/api';
import { SelfServeModule } from '../../../components/src/lib/self-serve/self-serve.module';
import { ReportingModule } from '../../../components/src/lib/reporting/reporting.module';
import { AdminModule } from '../../../components/src/lib/admin/admin.module';
import { ZeroTouchModule } from '../../../components/src/lib/automate/zero-touch.module';
import { SystemStatusModule } from '../../../components/src/lib/system-status/system-status.module';
import { SmacsToastComponent } from '../../../components/src/lib/shared/toast/toast.component';
import { HelpdeskModule } from '../../../components/src/lib/helpdesk/helpdesk.module';
import { LoginModule } from '../../../components/src/lib/login/login.module';
import { HomeModule } from '../../../components/src/lib/home/home.module';
import { CdrDumpModule } from '../../../components/src/lib/cdr-dump/cdr-dump.module';
import { ModalsModule } from '../../../components/src/lib/modals/modals.module';
import { DatatableModule } from '../../../components/src/lib/reporting/datatable/datatable.module';
import { PipesModule } from '../../../components/src/lib/shared/pipes/pipes.module';
import { ReleaseNotesModule } from '../../../components/src/lib/shared/release-notes/release-notes.module';
import { httpInterceptorProvider } from '../../../components/src/lib/shared/services/http-interceptor.service';
import { globalErrorProvider } from '../../../components/src/lib/shared/services/global-error-handler.service';
import { AppRouteReuseStrategy } from '../../../components/src/lib/route-reuse-strategy';
import { ActivateDeskphoneRouteGuardService } from '../../../components/src/lib/shared/services/activate-deskphone-route-guard.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/static/locale/', '.json');
}

export function initializeSvgIcons(svgLoaderService: SvgLoaderContext): () => Promise<void> {
  return () => {
    return new Promise((resolve) => {
      svgLoaderService.loadMiscSvgs().subscribe(() => resolve(null));
    });
  };
}

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgbModule,
    SelfServeModule,
    ReportingModule,
    AdminModule,
    ZeroTouchModule,
    SystemStatusModule,
    NgSelectModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // BrowserAnimationsModule,
    ToastrModule.forRoot({
      toastComponent: SmacsToastComponent,
    }),
    ToastNoAnimationModule.forRoot({
      toastComponent: SmacsToastComponent,
    }),
    HelpdeskModule,
    LoginModule,
    HomeModule,
    ModalsModule,
    CdrDumpModule,
    DatatableModule,
    PipesModule,
    AngularSvgIconModule.forRoot(),
    ReleaseNotesModule,
  ],
  declarations: [AppComponent],
  providers: [
    SvgLoaderContext,
    httpInterceptorProvider,
    globalErrorProvider,
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy,
    },
    ActivateDeskphoneRouteGuardService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeSvgIcons,
      multi: true,
      deps: [SvgLoaderContext],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
