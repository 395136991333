import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { PhoneStatus } from '../../models/generated/smacsModels';
import { PollingAbstractService } from '../abstract/polling.abstract.service';

@Injectable({
  providedIn: 'root',
})
export class DeviceRegistrationStatusPollingService extends PollingAbstractService<PhoneStatus> {
  baseUrl = '';

  protected _pollingTimeInMilliseconds = 15000;

  constructor(protected http: HttpClient, protected ngZone: NgZone) {
    super(http, ngZone);
  }

  setBaseUrl(name: string, cucmServerId: string | number) {
    this.baseUrl = `/services/cisco/macs/cucm-servers/${encodeURIComponent(
      String(cucmServerId)
    )}/phones/${encodeURIComponent(name)}/status`;
  }
}
