<div class="row">
  <div data-automation="connected-list-available" class="col-md-4">
    <h4 class="text-center">
      {{ availableTranslation | translate }}
    </h4>
    <div class="deselect-protect">
      <smacs-filter-input
        [placeholder]="'tkey;site_management.site.section.select.modal.filter_placeholder'"
        [dataAutomation]="'available-options-filter'"
        (filterChanged)="onAvailableFilterChanged($event)"
      ></smacs-filter-input>
    </div>

    <div
      dragula="{{ DRAGULA_GROUP_NAME }}"
      [(dragulaModel)]="availableOptions"
      data-automation="available-options-list"
      class="source connected-list mt-3"
    >
      <div
        *ngFor="let option of availableOptions"
        class="mb-1"
        [hidden]="!isMatchingFilter(option, availableOptionsFiltered)"
        (mousedown)="selectMultiple($event)"
      >
        <smacs-button
          [label]="option"
          [buttonStyle]="buttonStyles.DEFAULT"
          [buttonSize]="buttonSizes.LARGE"
          (dblclick)="onDblClick(option, listTypes.AVAILABLE_OPTIONS)"
          [isDragItem]="true"
          [isFullWidth]="true"
        ></smacs-button>
      </div>
    </div>
  </div>
  <div data-automation="connected-list-show" class="col-md-4">
    <h4 class="text-center">
      {{ showTranslation | translate }}
    </h4>
    <div class="deselect-protect">
      <smacs-filter-input
        [placeholder]="'tkey;site_management.site.section.select.modal.filter_placeholder'"
        [dataAutomation]="'configured-options-filter'"
        (filterChanged)="onConfiguredFilterChanged($event)"
      ></smacs-filter-input>
    </div>

    <div
      dragula="{{ DRAGULA_GROUP_NAME }}"
      [(dragulaModel)]="configuredOptions"
      data-automation="configured-options-list"
      class="target connected-list mt-3"
    >
      <div
        *ngFor="let option of configuredOptions"
        class="mb-1"
        [hidden]="!isMatchingFilter(option, configuredOptionsFiltered)"
        (mousedown)="selectMultiple($event)"
      >
        <smacs-button
          [label]="option"
          [buttonStyle]="buttonStyles.DEFAULT"
          [buttonSize]="buttonSizes.LARGE"
          (dblclick)="onDblClick(option, listTypes.CONFIGURED_OPTIONS)"
          [isDragItem]="true"
          [isFullWidth]="true"
        ></smacs-button>
      </div>
    </div>
  </div>
  <div data-automation="connected-list-default" class="col-md-4">
    <h4 class="text-center">
      {{ defaultTranslation | translate }}
    </h4>
    <div class="deselect-protect">
      <smacs-filter-input
        [placeholder]="'tkey;site_management.site.section.select.modal.filter_placeholder'"
        [dataAutomation]="'default-options-filter'"
        (filterChanged)="onDefaultFilterChanged($event)"
      ></smacs-filter-input>
    </div>

    <div
      dragula="{{ DRAGULA_GROUP_NAME }}"
      [(dragulaModel)]="defaultOptions"
      data-automation="default-options-list"
      class="target connected-list mt-3"
      [ngbTooltip]="'tkey;site_management.site.section.select.modal.tooltip.message' | translate"
      #tooltip="ngbTooltip"
      [autoClose]="false"
      [triggers]="'manual'"
    >
      <div
        *ngFor="let option of defaultOptions"
        class="mb-1"
        [hidden]="!isMatchingFilter(option, defaultOptionsFiltered)"
        (mousedown)="selectMultiple($event)"
      >
        <smacs-button
          [label]="option"
          [buttonStyle]="buttonStyles.DEFAULT"
          [buttonSize]="buttonSizes.LARGE"
          [isDragItem]="true"
          [isFullWidth]="true"
        ></smacs-button>
      </div>
    </div>
  </div>
  <div
    *ngIf="isLoadingOptions"
    data-automation="e2e-connected-lists-loading-options"
    style="width: 1px; height: 1px"
  ></div>
</div>
