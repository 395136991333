<app-breadcrumbs [labelParam]="licenseTier">
  <app-breadcrumb-card-item>
    <div
      *ngIf="!isReadOnly"
      [innerHtml]="
        'tkey;reporting.cucm_license_optimizer.candidates.description'
          | translate
            : {
                licenseType: licenseTier,
                callRetentionDays: cdrRetentionInDays,
                newDeviceMaxAge: newDeviceMaxAgeInDays
              }
      "
    ></div>

    <div
      *ngIf="isReadOnly"
      [innerHtml]="
        'tkey;reporting.cucm_license_optimizer.candidates.description.read_only'
          | translate
            : {
                licenseType: licenseTier,
                callRetentionDays: cdrRetentionInDays,
                newDeviceMaxAge: newDeviceMaxAgeInDays
              }
      "
    ></div>
  </app-breadcrumb-card-item>
</app-breadcrumbs>

<app-loading-block [hidden]="!isLoading"></app-loading-block>

<ng-container *ngIf="!isLoading && data.length > 0">
  <smacs-datatable
    [columns]="columns"
    [data]="data"
    [showSelectColumn]="!isReadOnly"
    [isFirstColumnSticky]="true"
    [mainFilterPlaceholder]="'tkey;reporting.cucm_license_optimizer.candidates.search_placeholder' | translate"
    (rowSelected)="updateBottomNav()"
    [dataAutomation]="tableDataAutomation"
  >
    <smacs-datatable-multi-select-filter
      [label]="'tkey;reporting.cucm_license_optimizer.candidates.device_model'"
      [items]="deviceModelOptions"
      [matchFn]="isDeviceModelMatch"
      [dataAutomation]="'cucm-license-optimization-candidates-model-filter'"
      [tableId]="tableDataAutomation"
    ></smacs-datatable-multi-select-filter>
  </smacs-datatable>
</ng-container>
