<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div *ngIf="!isLoading" data-automation="user-detail-home">
  <app-breadcrumbs>
    <div breadcrumbButton>
      <div *ngIf="isClusterDropdownVisible" ngbDropdown class="d-inline-block" data-automation="cluster-dropdown">
        <button
          type="button"
          class="btn btn-outline-dark btn-sm me-2"
          id="clusterDropdown"
          data-automation="dropdown-toggle"
          ngbDropdownToggle
        >
          <i class="{{ smacsIcons.ENVIRONMENT }}"></i>
          {{ 'tkey;userdetail.home.cluster_dropdown.cluster.label' | translate }}
          <strong>{{ currentClusterName }}</strong>
        </button>
        <div ngbDropdownMenu aria-labelledby="clusterDropdown">
          <button
            *ngFor="let cluster of clusterDropdownOptions; let i = index"
            [ngClass]="{ 'bg-dark text-light': cluster.name === currentClusterName }"
            class="btn-sm"
            [attr.data-automation]="'dropdown-item-' + i"
            ngbDropdownItem
            (click)="changeCluster(cluster)"
          >
            {{ cluster.name }}
          </button>
        </div>
      </div>
      <smacs-button
        [dataAutomation]="'user-detail-home-delete-all-button'"
        [label]="'tkey;userdetail.home.services.delete_all.button'"
        [icon]="smacsIcons.DELETE"
        [buttonSize]="buttonSizes.SMALL"
        [buttonStyle]="buttonStyles.OUTLINE_DANGER"
        [isDisabled]="!isDeleteAllEnabled()"
        [tooltip]="'tkey;userdetail.primary_extension.voicemail.delete.disabled_tooltip.no_items.text'"
        [isTooltipDisabled]="!isDeleteAllEnabled() ? false : true"
        (clicked)="onDeleteAllClick()"
      ></smacs-button>
    </div>
  </app-breadcrumbs>
  <div *ngIf="!hasSites" class="alert alert-warning text-center" data-automation="no-sites-warning-banner">
    <i [attr.class]="smacsIcons.WARNING + ' me-1'"></i>
    <strong>{{ 'tkey;userdetail.no_sites.banner.text' | translate }}</strong>
  </div>
  <div class="row pt-2">
    <!-- Primary Ext. --Single -->
    <div *ngIf="tileStates.primaryExtension.isDisplayed" class="col-sm-2">
      <smacs-service-tile
        [attr.data-automation]="tileStates.primaryExtension.dataAutomation"
        [dataAutomation]="tileStates.primaryExtension.dataAutomation"
        [serviceType]="tileStates.primaryExtension.serviceType"
        [tileType]="tileStates.primaryExtension.tileType"
        [serviceName]="tileStates.primaryExtension.serviceName"
        [icon]="tileStates.primaryExtension.icon"
        [description]="tileStates.primaryExtension.description"
        [isAdding]="tileStates.primaryExtension.isAdding"
        [isDeleting]="tileStates.primaryExtension.isDeleting"
        [isDeleted]="tileStates.primaryExtension.isDeleted"
        [isEnabled]="tileStates.primaryExtension.isEnabled"
        [deleteTooltip]="getPrimaryExtensionDeleteTooltip()"
        [isDeleteEnabled]="getPrimaryExtensionDeleteTooltip() ? false : true"
        [isExchangeEnabled]="tileStates.primaryExtension.isExchangeEnabled"
        [isExchangeShown]="tileStates.primaryExtension.isExchangeShown"
        [tooltip]="tileStates.primaryExtension.tooltip"
        (addTileClicked)="onAddTileClicked(tileStates.primaryExtension)"
        (editTileClicked)="onEditTileClicked(tileStates.primaryExtension)"
        (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.primaryExtension)"
        (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.primaryExtension)"
        (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.primaryExtension)"
        (editTileExchangeClicked)="onEditTileExchangeClicked()"
      ></smacs-service-tile>
    </div>
    <!-- UCCX Agent --Multi -->
    <div *ngIf="tileStates.uccxAgent.length" class="col-sm-2">
      <div *ngIf="tileStates.uccxAgent.length === 1">
        <smacs-service-tile
          *ngFor="let tileState of tileStates.uccxAgent; let i = index"
          [attr.data-automation]="tileState.dataAutomation"
          [dataAutomation]="tileState.dataAutomation"
          [serviceType]="tileState.serviceType"
          [tileType]="tileState.tileType"
          [serviceName]="tileState.serviceName"
          [icon]="tileState.icon"
          [description]="tileState.description"
          [isAdding]="tileState.isAdding"
          [isDeleting]="tileState.isDeleting"
          [isDeleted]="tileState.isDeleted"
          [isEnabled]="tileState.isEnabled"
          [tooltip]="tileState.tooltip"
          [deleteTooltip]="'tkey;userdetail.agent_details.delete.tooltip'"
          [isDeleteEnabled]="false"
          (addTileClicked)="onAddTileClicked(tileState, i)"
          (editTileClicked)="onEditTileClicked(tileState)"
          (editTileDeleteClicked)="onEditTileDeleteClicked(tileState, i)"
          (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileState, i)"
          (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileState, i)"
        ></smacs-service-tile>
      </div>

      <smacs-service-tile-edit-multi
        *ngIf="tileStates.uccxAgent.length > 1"
        [attr.data-automation]="'uccx-agent-multi-edit-tile'"
        [serviceName]="'tkey;shared.service.agent.text'"
        [description]="getMultiTileDescription('tkey;shared.service.agent.text', tileStates.uccxAgent.length)"
        [icon]="smacsIcons.UCCX_AGENT_OUTLINE"
        (multiEditAddClicked)="multiEditAddClicked = true"
      >
        <smacs-service-tile
          *ngFor="let tileState of tileStates.uccxAgent; let i = index"
          [attr.data-automation]="tileState.dataAutomation"
          [dataAutomation]="tileState.dataAutomation"
          [serviceType]="tileState.serviceType"
          [tileType]="tileState.tileType"
          [serviceName]="tileState.serviceName"
          [icon]="tileState.icon"
          [description]="tileState.description"
          [isAdding]="tileState.isAdding"
          [isDeleting]="tileState.isDeleting"
          [isEnabled]="tileState.isEnabled"
          [tooltip]="tileState.tooltip"
          [deleteTooltip]="'tkey;userdetail.agent_details.delete.tooltip'"
          [isDeleteEnabled]="false"
          (addTileClicked)="onAddTileClicked(tileState, i)"
          (editTileClicked)="onEditTileClicked(tileState)"
          (editTileDeleteClicked)="onEditTileDeleteClicked(tileState, i)"
          (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileState, i)"
          (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileState, i)"
        ></smacs-service-tile>
      </smacs-service-tile-edit-multi>
    </div>
    <!-- PCCE Agent --Single -->
    <div *ngIf="tileStates.pcceAgent.isDisplayed" class="col-sm-2">
      <smacs-service-tile
        [attr.data-automation]="tileStates.pcceAgent.dataAutomation"
        [dataAutomation]="tileStates.pcceAgent.dataAutomation"
        [serviceType]="tileStates.pcceAgent.serviceType"
        [tileType]="tileStates.pcceAgent.tileType"
        [serviceName]="tileStates.pcceAgent.serviceName"
        [icon]="tileStates.pcceAgent.icon"
        [description]="tileStates.pcceAgent.description"
        [isAdding]="tileStates.pcceAgent.isAdding"
        [isDeleting]="tileStates.pcceAgent.isDeleting"
        [isDeleted]="tileStates.pcceAgent.isDeleted"
        [isEnabled]="tileStates.pcceAgent.isEnabled"
        [isDeleteEnabled]="true"
        [tooltip]="tileStates.pcceAgent.tooltip"
        (addTileClicked)="onAddTileClicked(tileStates.pcceAgent)"
        (editTileClicked)="onEditTileClicked(tileStates.pcceAgent)"
        (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.pcceAgent)"
        (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.pcceAgent)"
        (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.pcceAgent)"
      ></smacs-service-tile>
    </div>
    <!-- VM --Single -->
    <div *ngIf="tileStates.voicemail?.isDisplayed" class="col-sm-2">
      <smacs-service-tile
        [attr.data-automation]="tileStates.voicemail.dataAutomation"
        [dataAutomation]="tileStates.voicemail.dataAutomation"
        [serviceType]="tileStates.voicemail.serviceType"
        [tileType]="tileStates.voicemail.tileType"
        [serviceName]="tileStates.voicemail.serviceName"
        [icon]="tileStates.voicemail.icon"
        [description]="tileStates.voicemail.description"
        [isAdding]="tileStates.voicemail.isAdding"
        [isDeleting]="tileStates.voicemail.isDeleting"
        [isDeleted]="tileStates.voicemail.isDeleted"
        [isEnabled]="tileStates.voicemail.isEnabled"
        [isDisplayed]="tileStates.voicemail.isDisplayed"
        [tooltip]="tileStates.voicemail.tooltip"
        (addTileClicked)="onAddTileClicked(tileStates.voicemail)"
        (editTileClicked)="onEditTileClicked(tileStates.voicemail)"
        (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.voicemail)"
        (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.voicemail)"
        (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.voicemail)"
      ></smacs-service-tile>
    </div>
    <!-- SNR --Single -->
    <div *ngIf="tileStates.snr?.isDisplayed" class="col-sm-2">
      <smacs-service-tile
        [attr.data-automation]="tileStates.snr.dataAutomation"
        [dataAutomation]="tileStates.snr.dataAutomation"
        [serviceType]="tileStates.snr.serviceType"
        [tileType]="tileStates.snr.tileType"
        [serviceName]="tileStates.snr.serviceName"
        [icon]="tileStates.snr.icon"
        [description]="tileStates.snr.description"
        [isAdding]="tileStates.snr.isAdding"
        [isDeleting]="tileStates.snr.isDeleting"
        [isDeleted]="tileStates.snr.isDeleted"
        [isEnabled]="tileStates.snr.isEnabled"
        [isDisplayed]="tileStates.snr.isDisplayed"
        [tooltip]="tileStates.snr.tooltip"
        (addTileClicked)="onAddTileClicked(tileStates.snr)"
        (editTileClicked)="onEditTileClicked(tileStates.snr)"
        (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.snr)"
        (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.snr)"
        (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.snr)"
      ></smacs-service-tile>
    </div>
    <!-- Deskphone --Multi -->
    <div *ngIf="displayServiceTiles(tileStates.deskphone)" class="col-sm-2">
      <smacs-user-detail-home-service-tile
        [tileStates]="tileStates.deskphone"
        (addTileClicked)="serviceTileAddClicked($event)"
        (editTileClicked)="serviceTileEditClicked($event)"
        (editTileDeleteClicked)="serviceTileDeleteClicked($event)"
        (deleteTileConfirmButtonClicked)="serviceTileDeleteConfirmClicked($event)"
        (deleteTileCancelButtonClicked)="serviceTileDeleteCancelClicked($event)"
        (copyDeskphoneButtonClicked)="serviceCopyClicked($event)"
      ></smacs-user-detail-home-service-tile>
    </div>
    <!-- Ext. Mobility --Single -->
    <div *ngIf="tileStates.extMobility?.isDisplayed" class="col-sm-2">
      <smacs-service-tile
        [attr.data-automation]="tileStates.extMobility.dataAutomation"
        [dataAutomation]="tileStates.extMobility.dataAutomation"
        [serviceType]="tileStates.extMobility.serviceType"
        [tileType]="tileStates.extMobility.tileType"
        [serviceName]="tileStates.extMobility.serviceName"
        [icon]="tileStates.extMobility.icon"
        [description]="tileStates.extMobility.description"
        [isAdding]="tileStates.extMobility.isAdding"
        [isDeleting]="tileStates.extMobility.isDeleting"
        [isDeleted]="tileStates.extMobility.isDeleted"
        [isEnabled]="tileStates.extMobility.isEnabled"
        [tooltip]="tileStates.extMobility.tooltip"
        (addTileClicked)="onAddTileClicked(tileStates.extMobility)"
        (editTileClicked)="onEditTileClicked(tileStates.extMobility)"
        (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.extMobility)"
        (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.extMobility)"
        (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.extMobility)"
      ></smacs-service-tile>
    </div>
    <!-- IM&P --Single -->
    <div *ngIf="tileStates.imPresence?.isDisplayed" class="col-sm-2">
      <smacs-service-tile
        [attr.data-automation]="tileStates.imPresence.dataAutomation"
        [dataAutomation]="tileStates.imPresence.dataAutomation"
        [serviceType]="tileStates.imPresence.serviceType"
        [tileType]="tileStates.imPresence.tileType"
        [serviceName]="tileStates.imPresence.serviceName"
        [icon]="tileStates.imPresence.icon"
        [description]="tileStates.imPresence.description"
        [isAdding]="tileStates.imPresence.isAdding"
        [isDeleting]="tileStates.imPresence.isDeleting"
        [isDeleted]="tileStates.imPresence.isDeleted"
        [isEnabled]="tileStates.imPresence.isEnabled"
        [tooltip]="tileStates.imPresence.tooltip"
        (addTileClicked)="onAddTileClicked(tileStates.imPresence)"
        (editTileClicked)="onEditTileClicked(tileStates.imPresence)"
        (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.imPresence)"
        (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.imPresence)"
        (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.imPresence)"
      ></smacs-service-tile>
    </div>
    <!-- IM Softphone --Multi -->
    <div *ngIf="displayServiceTiles(tileStates.imSoftphone)" class="col-sm-2">
      <smacs-user-detail-home-service-tile
        [tileStates]="tileStates.imSoftphone"
        (addTileClicked)="serviceTileAddClicked($event)"
        (editTileClicked)="serviceTileEditClicked($event)"
        (editTileDeleteClicked)="serviceTileDeleteClicked($event)"
        (deleteTileConfirmButtonClicked)="serviceTileDeleteConfirmClicked($event)"
        (deleteTileCancelButtonClicked)="serviceTileDeleteCancelClicked($event)"
      ></smacs-user-detail-home-service-tile>
    </div>
    <!-- CIPC --Multi -->
    <div *ngIf="displayServiceTiles(tileStates.cipc)" class="col-sm-2">
      <smacs-user-detail-home-service-tile
        [tileStates]="tileStates.cipc"
        (addTileClicked)="serviceTileAddClicked($event)"
        (editTileClicked)="serviceTileEditClicked($event)"
        (editTileDeleteClicked)="serviceTileDeleteClicked($event)"
        (deleteTileConfirmButtonClicked)="serviceTileDeleteConfirmClicked($event)"
        (deleteTileCancelButtonClicked)="serviceTileDeleteCancelClicked($event)"
      ></smacs-user-detail-home-service-tile>
    </div>
    <!-- Android --Multi -->
    <div *ngIf="displayServiceTiles(tileStates.android)" class="col-sm-2">
      <smacs-user-detail-home-service-tile
        [tileStates]="tileStates.android"
        (addTileClicked)="serviceTileAddClicked($event)"
        (editTileClicked)="serviceTileEditClicked($event)"
        (editTileDeleteClicked)="serviceTileDeleteClicked($event)"
        (deleteTileConfirmButtonClicked)="serviceTileDeleteConfirmClicked($event)"
        (deleteTileCancelButtonClicked)="serviceTileDeleteCancelClicked($event)"
      ></smacs-user-detail-home-service-tile>
    </div>
    <!-- iPhone --Multi -->
    <div *ngIf="displayServiceTiles(tileStates.iphone)" class="col-sm-2">
      <smacs-user-detail-home-service-tile
        [tileStates]="tileStates.iphone"
        (addTileClicked)="serviceTileAddClicked($event)"
        (editTileClicked)="serviceTileEditClicked($event)"
        (editTileDeleteClicked)="serviceTileDeleteClicked($event)"
        (deleteTileConfirmButtonClicked)="serviceTileDeleteConfirmClicked($event)"
        (deleteTileCancelButtonClicked)="serviceTileDeleteCancelClicked($event)"
      ></smacs-user-detail-home-service-tile>
    </div>
    <!-- Tablet --Multi -->
    <div *ngIf="displayServiceTiles(tileStates.tablet)" class="col-sm-2">
      <smacs-user-detail-home-service-tile
        [tileStates]="tileStates.tablet"
        (addTileClicked)="serviceTileAddClicked($event)"
        (editTileClicked)="serviceTileEditClicked($event)"
        (editTileDeleteClicked)="serviceTileDeleteClicked($event)"
        (deleteTileConfirmButtonClicked)="serviceTileDeleteConfirmClicked($event)"
        (deleteTileCancelButtonClicked)="serviceTileDeleteCancelClicked($event)"
      ></smacs-user-detail-home-service-tile>
    </div>
    <!-- CTI Port --Multi -->
    <div *ngIf="tileStates.ctiPort.length" class="col-sm-2">
      <smacs-user-detail-home-service-tile
        [tileStates]="tileStates.ctiPort"
        (addTileClicked)="serviceTileAddClicked($event)"
        (editTileClicked)="serviceTileEditClicked($event)"
        (editTileDeleteClicked)="serviceTileDeleteClicked($event)"
        (deleteTileConfirmButtonClicked)="serviceTileDeleteConfirmClicked($event)"
        (deleteTileCancelButtonClicked)="serviceTileDeleteCancelClicked($event)"
      ></smacs-user-detail-home-service-tile>
    </div>
    <!-- CTI Remote Device --Multi -->
    <div *ngIf="tileStates.ctiRemoteDevice.length" class="col-sm-2">
      <smacs-user-detail-home-service-tile
        [tileStates]="tileStates.ctiRemoteDevice"
        (addTileClicked)="serviceTileAddClicked($event)"
        (editTileClicked)="serviceTileEditClicked($event)"
        (editTileDeleteClicked)="serviceTileDeleteClicked($event)"
        (deleteTileConfirmButtonClicked)="serviceTileDeleteConfirmClicked($event)"
        (deleteTileCancelButtonClicked)="serviceTileDeleteCancelClicked($event)"
      ></smacs-user-detail-home-service-tile>
    </div>
    <!-- Spark Remote Device --Multi -->
    <div *ngIf="tileStates.sparkRemoteDevice.length" class="col-sm-2">
      <smacs-user-detail-home-service-tile
        [tileStates]="tileStates.sparkRemoteDevice"
        (addTileClicked)="serviceTileAddClicked($event)"
        (editTileClicked)="serviceTileEditClicked($event)"
        (editTileDeleteClicked)="serviceTileDeleteClicked($event)"
        (deleteTileConfirmButtonClicked)="serviceTileDeleteConfirmClicked($event)"
        (deleteTileCancelButtonClicked)="serviceTileDeleteCancelClicked($event)"
      ></smacs-user-detail-home-service-tile>
    </div>
    <!-- Webex Calling -- Single -->
    <div *ngIf="tileStates.webexCalling.isDisplayed" class="col-sm-2">
      <smacs-service-tile
        [attr.data-automation]="tileStates.webexCalling.dataAutomation"
        [dataAutomation]="tileStates.webexCalling.dataAutomation"
        [serviceType]="tileStates.webexCalling.serviceType"
        [tileType]="tileStates.webexCalling.tileType"
        [serviceName]="tileStates.webexCalling.serviceName"
        [icon]="tileStates.webexCalling.icon"
        [description]="tileStates.webexCalling.description"
        [isAdding]="tileStates.webexCalling.isAdding"
        [isDeleting]="tileStates.webexCalling.isDeleting"
        [isDeleted]="tileStates.webexCalling.isDeleted"
        [isEnabled]="tileStates.webexCalling.isEnabled"
        [isDeleteEnabled]="true"
        [isExchangeEnabled]="tileStates.webexCalling.isExchangeEnabled"
        [isExchangeShown]="tileStates.webexCalling.isExchangeShown"
        [tooltip]="tileStates.webexCalling.tooltip"
        (addTileClicked)="onAddTileClicked(tileStates.webexCalling)"
        (editTileClicked)="onEditTileClicked(tileStates.webexCalling)"
        (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.webexCalling)"
        (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.webexCalling)"
        (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.webexCalling)"
      ></smacs-service-tile>
    </div>
  </div>

  <div *ngIf="associatedPublicPhones.length" class="row">
    <div class="col-12">
      <smacs-associated-public-phones
        [associatedPublicPhones]="associatedPublicPhones"
        [endUser]="endUserResult"
        (deviceFixed)="onDeviceFixed($event)"
      ></smacs-associated-public-phones>
    </div>
  </div>
</div>
