import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { HtmlInputType, SmacsTextComponent, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { Observable, Subscription, switchMap, tap } from 'rxjs';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { catchError, delayWhen } from 'rxjs/operators';
import { State, StatusCategory, SyncSettings } from '../../../shared/models/generated/smacsModels';
import { ProxyConnectionSettingsContext } from '../../contexts/proxy-connection-settings.context';
import { SystemStatusContext } from '../../../shared/contexts/system-status.context';

@Component({
  selector: 'ziro-connection-settings',
  templateUrl: './connection-settings.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class ConnectionSettingsComponent extends SmacsFormAbstractDirective<SyncSettings> implements OnInit, OnDestroy {
  isLoading = true;
  healthStatusAlertCss: 'alert-warning' | 'alert-info' | 'alert-success';
  healthStatusAlertText: string;

  formConfig = {
    fields: {
      host: {
        fieldId: 'host',
        label: 'tkey;admin.proxy_server_management.zpm_connection_settings.host.label',
        helpText: 'tkey;admin.proxy_server_management.zpm_connection_settings.host.helptext',
        dataAutomation: 'proxy-server-connection-settings-host',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'tkey;admin.proxy_server_management.zpm_connection_settings.host.placeholder',
          htmlInputAddOn: {
            prependedContent: 'https://',
            appendedContent: '',
          },
        }),
        required: true,
        validation: [
          {
            validator: (val: string) => {
              if (!val) {
                return SmacsFormsValidationState.VALID;
              }

              if (!/^[a-zA-Z0-9.-]*$/.test(val)) {
                return SmacsFormsValidationState.INVALID;
              }

              return SmacsFormsValidationState.VALID;
            },
            message: 'tkey;admin.proxy_server_management.zpm_connection_settings.host.pattern',
          },
        ],
      },
      secret: {
        fieldId: 'secret',
        label: 'tkey;admin.proxy_server_management.zpm_connection_settings.secret.label',
        helpText: 'tkey;admin.proxy_server_management.zpm_connection_settings.secret.helptext',
        dataAutomation: 'proxy-server-connection-settings-secret',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.PASSWORD,
          starsPlaceholder: '••••••••••••••••••••••••••••••••••••',
        }),
        required: () => true,
      },
    },
  };

  private _subs = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _bottomNavService: BottomNavService,
    private _toastService: ToastService,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _breadcrumbsService: BreadcrumbsService,
    private _proxyConnectionSettingsContext: ProxyConnectionSettingsContext,
    private _systemStatusContext: SystemStatusContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._breadcrumbsService.updateBreadcrumbs([
      { label: 'tkey;admin.proxy_server_management.title' },
      { label: 'tkey;admin.proxy_server_management.zpm_connection_settings.title' },
    ]);

    const proxyConnectionSettingsSub = this._proxyConnectionSettingsContext.state$.subscribe((settings) => {
      this.entitySource.next(settings);
      this.setIsExisting(!!settings.host);
      this._setDeleteDisabledState(!this.isExisting);
      this.isLoading = false;
    });

    this._subs.add(proxyConnectionSettingsSub);

    const healthStatusSub = this._systemStatusContext.state$.subscribe((healthStatus) => {
      const proxyServerHealthStatus = healthStatus.healthStatuses.find(
        (status) => status.category === StatusCategory.PROXY_SERVER
      );
      if (!proxyServerHealthStatus) {
        this.healthStatusAlertCss = undefined;
        this.healthStatusAlertText = undefined;
      } else if (proxyServerHealthStatus.state === State.OK) {
        this.healthStatusAlertCss = 'alert-success';
        this.healthStatusAlertText = 'tkey;admin.proxy_server_management.generate_key.health_status_alert.success';
      } else if (proxyServerHealthStatus.state === State.WARNING) {
        this.healthStatusAlertCss = 'alert-warning';
        this.healthStatusAlertText = 'tkey;admin.proxy_server_management.generate_key.health_status_alert.warning';
      }
    });
    this._subs.add(healthStatusSub);

    this._initBottomNav();

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() =>
      this._bottomNavService.setBottomNavValidationError(!this.isFormValid())
    );
    this._subs.add(formSubmittedSub);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._breadcrumbsService.clearBreadcrumbs();
    this._subs.unsubscribe();
  }

  protected submit(): Observable<void> {
    return this._saveSettings();
  }

  private _saveSettings(): Observable<void> {
    this._setDeleteDisabledState(true);
    this._bottomNavService.setButtonPendingState('proxy-server-connection-settings-save-button', true);

    return this._proxyConnectionSettingsContext.testConnection(this.entity).pipe(
      switchMap(() => this._proxyConnectionSettingsContext.put(this.entity)),
      delayWhen(() => this._systemStatusContext.getSystemStatus()),
      tap(() => {
        this._setDeleteDisabledState(false);
        this._bottomNavService.setButtonPendingState('proxy-server-connection-settings-save-button', false);
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.SERVER,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.proxy_server_management.zpm_connection_settings.success_toast.message'
        );

        this.smacsFormStateService.setIsFormDirty(false);
        this.isFormSubmitted = false;
        this.fieldComponents.forEach((item: SmacsTextComponent) => {
          item.isDirty = false;
          item.showValidation = false;
          item.isFormSubmitted = this.isFormSubmitted;

          if (item.fieldId === 'secret') {
            item.showMaskedInput = true;
            item.applyComponentConfig(new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }));
          }
        });
      }),
      catchError((response) => {
        this._setDeleteDisabledState(false);
        this._bottomNavService.setButtonPendingState('proxy-server-connection-settings-save-button', false);
        this._bottomNavService.setBottomNavValidationError(false);
        throw response;
      })
    );
  }

  private _initBottomNav(): void {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'proxy-server-connection-settings-delete-button',
          label: 'tkey;dialogs.button.delete',
          buttonClass: ButtonStyles.DANGER,
          dataAutomation: 'proxy-server-connection-settings-delete-button',
          state: {
            pending: false,
            buttonDisableState: {
              disabled: false,
              tooltipKey: '',
            },
          },
          icon: SmacsIcons.DELETE,
          cb: () => this._onDeleteClick(),
        },
        {
          id: 'proxy-server-connection-settings-save-button',
          label: 'tkey;admin.ui.save',
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'proxy-server-connection-settings-save-button',
          state: {
            pending: false,
            buttonDisableState: { disabled: false, tooltipKey: '' },
          },
          icon: SmacsIcons.OK,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );
  }

  private _resetFormState(): void {
    this.setIsExisting(false);
    this.smacsFormStateService.setIsFormDirty(false);
    this.isFormSubmitted = false;
    this.fieldComponents.forEach((item: SmacsTextComponent) => {
      item.isDirty = false;
      item.showValidation = false;
      item.isFormSubmitted = this.isFormSubmitted;

      if (item.fieldId === 'secret') {
        item.showMaskedInput = false;
        item.applyComponentConfig(new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }));
      }
    });
    this._setDeleteDisabledState(true);
  }

  private _setDeleteDisabledState(state: boolean): void {
    this._bottomNavService.setButtonDisabledState('proxy-server-connection-settings-delete-button', state);
  }

  private _onDeleteClick(): void {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        title: this._translateService.instant(
          'tkey;admin.proxy_server_management.zpm_connection_settings.delete_modal.title'
        ),
        promptBody: this._translateService.instant(
          'tkey;admin.proxy_server_management.zpm_connection_settings.delete_modal.body'
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteSettings(),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteSettings(): Observable<void> {
    return this._proxyConnectionSettingsContext.delete().pipe(
      delayWhen(() => this._systemStatusContext.getSystemStatus()),
      tap(() => {
        this._resetFormState();
        this._toastService.push(
          ToastTypes.INFO,
          `${SmacsIcons.DELETE} text-danger`,
          'tkey;shared.toast.delete.success.title',
          'tkey;admin.proxy_server_management.zpm_connection_settings.delete_toast.message'
        );
      })
    );
  }
}
