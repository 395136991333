import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MicrosoftTeamsCallingSettings } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class TeamsCallingHandlingAndForwardingResource {
  constructor(private $http: HttpClient) {}

  getTeamsCallingHandlingForwarding(upn: string): Observable<MicrosoftTeamsCallingSettings> {
    return this.$http.get<MicrosoftTeamsCallingSettings>(
      `/services/microsoft/macs/teams-calling-handling-and-forwarding-settings/${upn}`
    );
  }

  putTeamsCallingHandlingForwarding(msTeamsCallingSettings: MicrosoftTeamsCallingSettings): Observable<void> {
    return this.$http.put<void>(
      `/services/microsoft/macs/teams-calling-handling-and-forwarding-settings/${msTeamsCallingSettings.userPrincipalName}`,
      msTeamsCallingSettings
    );
  }
}
