import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SafeHtml } from '@angular/platform-browser';
import { CustomModal } from '../services/smacs-modal.service';
import { SmacsIcons } from '../models/smacs-icons.enum';

export interface ReleaseNotesModalViewProperties {
  html: SafeHtml;
  title: string;
  slug: string;
}

@Component({
  selector: 'ziro-release-notes-modal',
  templateUrl: './release-notes-modal.component.html',
  styleUrls: ['./release-notes-modal.component.scss'],
})
export class ReleaseNotesModalComponent implements CustomModal<ReleaseNotesModalViewProperties>, OnInit {
  smacsIcons = SmacsIcons;
  modalProperties: ReleaseNotesModalViewProperties;
  html: SafeHtml;
  title: string;
  base_url = "https://learn.goziro.com/t/";
  url: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.html = this.modalProperties.html;
    this.title = this.modalProperties.title;
    this.url = this.base_url + this.modalProperties.slug;
  }

  onCloseClick(): void {
    this.activeModal.close(true);
  }
}
