import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../../button/button.component';
import { Global360View, VoicemailRef, VoicemailResult } from '../../../../shared/models/generated/smacsModels';
import { ReassignTypes } from '../reassign-user-search/reassign-user-search.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Global360ViewResource } from '../../../../shared/resources/global-360-view.resource';
import { VoicemailSearchResource } from '../../../../shared/resources/voicemail-search.resource';
import { CurrentClusterContext } from '../../../../shared/contexts/current-cluster.context';
import { Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'smacs-reassign-user-execute',
  templateUrl: './reassign-user-execute.component.html',
})
export class ReassignUserExecuteComponent implements OnInit {
  @Output() reassignClicked = new EventEmitter<boolean>();
  @Input() isVoicemailPresentOnLineOne: boolean;
  @Input() publicPhoneVoicemailRef: VoicemailRef;
  @Input() serverId: number;
  @Input() siteId: number;
  @Input() reassignType: ReassignTypes;

  isSubmitting = false;

  showVoicemailCard: boolean;
  cannotReassign: boolean;
  isLoading = true;
  global360View: Global360View;
  displayName: string;
  isKeepVoicemail = false;
  protected readonly ReassignTypes = ReassignTypes;
  protected readonly smacsIcons = SmacsIcons;
  protected readonly buttonStyles = ButtonStyles;

  private _username: string;
  private _phoneName: string;
  private _userToUserTarget: string;
  private _userToSearch: string;
  private selectedUserIsPublicPhoneVoicemailAlias = false;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _global360ViewResource: Global360ViewResource,
    private _voicemailSearchResource: VoicemailSearchResource,
    private _currentClusterContext: CurrentClusterContext
  ) {}

  ngOnInit() {
    const params = this._route.snapshot.params;
    this._username = params.username;
    this._userToUserTarget = params.targetUsername;
    this._phoneName = params.phoneName;
    this._userToSearch = this.reassignType === ReassignTypes.PUBLIC_TO_USER ? this._username : this._userToUserTarget;
    this.selectedUserIsPublicPhoneVoicemailAlias =
      this.reassignType === ReassignTypes.PUBLIC_TO_USER &&
      !!this.publicPhoneVoicemailRef &&
      this.publicPhoneVoicemailRef.alias === this._username;
    this._global360ViewResource
      .get(this._userToSearch)
      .pipe(
        switchMap((global360View: Global360View) => {
          this.global360View = global360View;
          this.displayName = `${global360View.endUsers[0].ref.firstName} ${global360View.endUsers[0].ref.lastName}`;
          this.showVoicemailCard = this.isVoicemailPresentOnLineOne;
          this.cannotReassign =
            this.global360View.primaryExtensions.some((dnRef) => dnRef.serverId === this.serverId) ||
            this.global360View.phones.some((phoneRef) => phoneRef.serverId === this.serverId) ||
            this.global360View.snrProfiles.some((snrRef) => snrRef.serverId === this.serverId) ||
            this.global360View.extensionMobilities.some((extMobRef) => extMobRef.serverId === this.serverId);
          if (this.selectedUserIsPublicPhoneVoicemailAlias || !this.isVoicemailPresentOnLineOne) {
            return of(null);
          } else {
            return this._determineConflictingVoicemail();
          }
        })
      )
      .subscribe((results: VoicemailResult[] | null) => {
        if (results?.length) {
          this.cannotReassign = true;
        }
        this.isLoading = false;
      });
  }

  onReassignClicked() {
    this.isSubmitting = true;
    this.reassignClicked.emit(this.isKeepVoicemail);
  }

  onGoBackClicked() {
    if (this.isVoicemailPresentOnLineOne && !this.showVoicemailCard) {
      this.showVoicemailCard = true;
    } else {
      if (this.reassignType === ReassignTypes.PUBLIC_TO_USER) {
        this._router.navigateByUrl(`/public-phone/${this._phoneName}/reassign?search=${this._username}`);
      } else {
        this._router.navigateByUrl(`/user/${this._username}/reassign?search=${this._userToUserTarget}`);
      }
    }
  }

  onVoicemailButtonClicked(isKeeping: boolean) {
    this.isKeepVoicemail = isKeeping;
    this.showVoicemailCard = false;
  }

  // Since it is possible for the target user to have a voicemail on a unity server that does not
  // show up in the global 360, we should search the unity server by alias before allowing the reassign
  private _determineConflictingVoicemail(): Observable<VoicemailResult[]> {
    return this._currentClusterContext.state$.pipe(
      switchMap((currentCluster) => {
        const unityServerId = this._currentClusterContext.getCurrentClusterUnityServerIdBySiteId(
          currentCluster,
          this.siteId
        );
        return this._voicemailSearchResource.searchByAlias(this._userToSearch, unityServerId);
      })
    );
  }
}
