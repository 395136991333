import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { CustomUiOptionsContext } from '../contexts/custom-ui-options.context';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit, OnDestroy {
  isLoading = true;
  customizedText: SafeHtml;

  private _subscription = new Subscription();

  constructor(private customUiOptionsContext: CustomUiOptionsContext, private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this.getUiProperties();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getUiProperties() {
    const uiOptionsSub = this.customUiOptionsContext.state$.subscribe((options) => {
      if (options.supportTextHtml) {
        this.customizedText = this.domSanitizer.bypassSecurityTrustHtml(options.supportTextHtml);
      }
      this.isLoading = false;
    });
    this._subscription.add(uiOptionsSub);
  }
}
