import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DeviceRegistrationStatus, Phone, PhoneRef, PhoneStatus } from '../models/generated/smacsModels';
import { Observable } from 'rxjs';
import { AuditHeaderService } from '../services/audit-header.service';
import { map } from 'rxjs/operators';
import { Nvp } from '../models/nvp';

export const humanReadableRegistrationStatus = new Map<DeviceRegistrationStatus, string>([
  [DeviceRegistrationStatus.NOT_FOUND, 'tkey;phone.registration_status.not_found'],
  [DeviceRegistrationStatus.REGISTERED, 'tkey;phone.registration_status.registered'],
  [DeviceRegistrationStatus.UNREGISTERED, 'tkey;phone.registration_status.unregistered'],
]);

@Injectable()
export class PhoneResource {
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  get(id: string, cucmServerId: string): Observable<Phone> {
    return this.http
      .get<Phone>(
        `/services/cisco/macs/cucm-servers/${encodeURIComponent(cucmServerId)}/phones/${encodeURIComponent(id)}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  put(phone: Phone, cucmServerId: string, auditTags?: Nvp[]): Observable<PhoneRef> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.put<PhoneRef>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(cucmServerId)}/phones/${encodeURIComponent(phone.id)}`,
      phone,
      options
    );
  }

  post(phone: Phone, cucmServerId: string, auditTags?: Nvp[]): Observable<PhoneRef> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.post<PhoneRef>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(cucmServerId)}/phones`,
      phone,
      options
    );
  }

  delete(id: string, cucmServerId: string, auditTags?: Nvp[]): Observable<void> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.delete<void>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(cucmServerId)}/phones/${encodeURIComponent(id)}`,
      options
    );
  }

  getPhoneStatus = (name: string, cucmServerId: string | number): Observable<PhoneStatus> => {
    return this.http.get<PhoneStatus>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(String(cucmServerId))}/phones/${encodeURIComponent(
        name
      )}/status`
    );
  };

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }
}
