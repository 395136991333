<div *ngIf="permissionType === Type.GROUP" class="row mb-3">
  <div class="col-lg-3 text-end">
    <label class="col-form-label">{{ 'tkey;admin.system.user_permissions.members.label' | translate }}</label>
  </div>
  <div class="col-lg-4">
    <smacs-view-members
      [members]="usersInGroup"
      [state]="viewMembersState"
      [disabled]="usersInGroup.length === 0"
    ></smacs-view-members>
  </div>
</div>

<smacs-radio [fieldId]="'role'"></smacs-radio>
<smacs-select [fieldId]="'permittedDialPlanGroups'"></smacs-select>

<ng-template #errorTemplate>
  <span *ngIf="isFormSubmitted && validationState === ValidationState.INVALID">
    {{ 'tkey;admin.system.user_permissions.form.required.error_message' | translate }}
  </span>
</ng-template>
