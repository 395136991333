import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { Observable, Subscription, throwError } from 'rxjs';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  SmacsCheckboxConfig,
} from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { SmacsRadioConfig } from '../../../../forms/fields/radio/smacs-radio.component';
import { HtmlInputType, InputSize, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { DidMatchType, ZpmProxyFeatureSettingsEntity } from '../proxy-feature-settings.component';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../../button/button.component';
import { catchError, tap } from 'rxjs/operators';
import { ZpmProxySettings } from '../../../../shared/models/generated/smacsModels';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { MicrosoftProxyFeatureSettingsContext } from '../../../contexts/microsoft-proxy-feature-settings.context';
import { ToastService } from '../../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'smacs-ms-proxy-feature-settings-form',
  templateUrl: './ms-proxy-feature-settings-form.component.html',
})
export class MsProxyFeatureSettingsFormComponent
  extends SmacsFormAbstractDirective<ZpmProxyFeatureSettingsEntity>
  implements OnInit, OnDestroy
{
  dialPlanSyncToggleEnabled: boolean;
  lastDigitsMatchEnabled: boolean;
  lastDigitsMatchCount: number;

  private _subs = new Subscription();
  protected readonly smacsIcons = SmacsIcons;

  formConfig: SmacsFormConfig = {
    fields: {
      onPremAdWriteDownEnabled: {
        dataAutomation: 'proxy-settings-ad-write-down',
        required: true,
        componentConfig: new SmacsCheckboxConfig({
          size: HtmlSwitchSize.LG,
          checkboxType: HtmlCheckboxType.SWITCH,
        }),
      },
      cucmPhoneNumberAssignmentCheckEnabled: {
        dataAutomation: 'proxy-settings-dial-plan-sync',
        required: true,
        componentConfig: new SmacsCheckboxConfig({
          size: HtmlSwitchSize.LG,
          checkboxType: HtmlCheckboxType.SWITCH,
        }),
      },
      cucmPhoneNumberAssignmentMatchType: {
        dataAutomation: 'did-match-type',
        required: true,
        componentConfig: new SmacsRadioConfig({
          isDisplayVertical: true,
          alignLeftNoLabel: true,
          buttons: [
            {
              value: DidMatchType.EXACT_MATCH,
              label:
                'tkey;admin.proxy_server_management.proxy_feature_settings.number_assignment_check.option.exact_match.zpm',
            },
            {
              value: DidMatchType.END_MATCH,
              label: this._getTranslatedEndMatchLabel(),
            },
          ],
          inline: true,
        }),
      },
      cucmPhoneNumberAssignmentLastDigitsMatch: {
        label:
          'tkey;admin.proxy_server_management.proxy_feature_settings.number_assignment_check.option.end_match.count',
        dataAutomation: 'last-matching-digits-count',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.NUMBER,
          inputSize: InputSize.SM,
        }),
        validation: [
          {
            validator: (value: string, cucmPhoneNumberAssignmentMatchType: DidMatchType) => {
              if (cucmPhoneNumberAssignmentMatchType === DidMatchType.EXACT_MATCH)
                return SmacsFormsValidationState.VALID;
              return Number(value) > 0 && Number(value) < 11
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID;
            },
            message: this._translateService.instant(
              'tkey;admin.proxy_server_management.proxy_feature_settings.number_assignment_check.option.end_match.count.invalid_message'
            ),
            injectValuesFromFields: ['cucmPhoneNumberAssignmentMatchType'],
          },
        ],
      },
    },
  };

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _bottomNavService: BottomNavService,
    private _proxyFeatureSettingsContext: MicrosoftProxyFeatureSettingsContext,
    private _toastService: ToastService,
    private _translateService: TranslateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    const smacsFormUpdateSub = this.smacsFormsUpdate$.subscribe(
      (update: SmacsFormsUpdate<ZpmProxyFeatureSettingsEntity>) => {
        this.dialPlanSyncToggleEnabled = update.new.cucmPhoneNumberAssignmentCheckEnabled;
        this.lastDigitsMatchEnabled = update.new.cucmPhoneNumberAssignmentMatchType === DidMatchType.END_MATCH;
        this.lastDigitsMatchCount =
          !!update.new.cucmPhoneNumberAssignmentLastDigitsMatch && this.lastDigitsMatchEnabled
            ? update.new.cucmPhoneNumberAssignmentLastDigitsMatch
            : 0;

        const cucmPhoneNumberAssignmentMatchType = this.fieldComponents.find(
          (field) => field.fieldId === 'cucmPhoneNumberAssignmentMatchType'
        );
        cucmPhoneNumberAssignmentMatchType.applyComponentConfig(
          new SmacsRadioConfig({
            ...this.formConfig.fields['cucmPhoneNumberAssignmentMatchType'].componentConfig,
            buttons: [
              {
                value: DidMatchType.EXACT_MATCH,
                label:
                  'tkey;admin.proxy_server_management.proxy_feature_settings.number_assignment_check.option.exact_match.zpm',
              },
              {
                value: DidMatchType.END_MATCH,
                label: this._getTranslatedEndMatchLabel(),
              },
            ],
          })
        );
        const cucmPhoneNumberAssignmentLastDigitsMatch = this.fieldComponents.find(
          (field) => field.fieldId === 'cucmPhoneNumberAssignmentLastDigitsMatch'
        );
        cucmPhoneNumberAssignmentLastDigitsMatch.applyState({
          ...cucmPhoneNumberAssignmentLastDigitsMatch.state,
          required: !!this.lastDigitsMatchEnabled,
        });
      }
    );
    this._subs.add(smacsFormUpdateSub);
    this._initBottomNav();
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
  }

  private _initBottomNav: () => void = () => {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'proxy-feature-settings-save',
          dataAutomation: 'proxy-feature-settings-save-button',
          label: 'tkey;global.button.save.text',
          icon: SmacsIcons.OK,
          buttonClass: ButtonStyles.PRIMARY,
          cb: () => {
            this._validateAndSubmitSource.next(true);
          },
        },
      ])
    );
  };

  protected submit(): Observable<ZpmProxySettings> {
    this._setPending(true);
    const apiData = this._toApiData();
    const apiRequest = this._proxyFeatureSettingsContext.put(apiData);

    return apiRequest.pipe(
      tap(() => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.SERVER,
          'tkey;shared.toast.save.success.title',
          'tkey;proxy_feature_settings.toast.success'
        );
        this._setPending(false);
      }),
      catchError((error) => {
        this.isSubmitting = false;
        this._validateAndSubmitSource.next(false);
        this._setPending(false);
        return throwError(() => error);
      })
    );
  }

  private _toApiData(): ZpmProxySettings {
    return {
      onPremAdWriteDownEnabled: this.entity.onPremAdWriteDownEnabled,
      cucmPhoneNumberAssignmentCheckEnabled: this.entity.cucmPhoneNumberAssignmentCheckEnabled,
      cucmPhoneNumberAssignmentLastDigitsMatch:
        this.entity.cucmPhoneNumberAssignmentMatchType === DidMatchType.EXACT_MATCH ||
        !this.entity.cucmPhoneNumberAssignmentCheckEnabled
          ? 0
          : this.entity.cucmPhoneNumberAssignmentLastDigitsMatch,
    };
  }

  private _setPending(setting: boolean): void {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'proxy-feature-settings-save',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  // this is done to keep the label alignment of the radio options on the left
  private _getTranslatedEndMatchLabel(): string {
    return this._translateService.instant(
      'tkey;admin.proxy_server_management.proxy_feature_settings.number_assignment_check.option.end_match.zpm',
      {
        lastDigitCount: this.lastDigitsMatchCount == 0 ? 'X' : this.lastDigitsMatchCount,
      }
    );
  }
}
