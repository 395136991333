import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Pipe({ name: 'telephoneNumberFilter' })
export class TelephoneNumberFilter implements PipeTransform {
  phoneUtil: PhoneNumberUtil;

  constructor() {
    this.phoneUtil = PhoneNumberUtil.getInstance();
  }

  transform(value: string): string {
    if (value) {
      try {
        if (value.startsWith('+') && value.length >= 10) {
          const phoneNumber = this.phoneUtil.parse(value);
          return this.phoneUtil.format(phoneNumber, PhoneNumberFormat.INTERNATIONAL);
        } else if (value.length >= 10) {
          return this.phoneUtil.formatInOriginalFormat(this.phoneUtil.parseAndKeepRawInput(value, 'CA'));
        }
        return value;
      } catch (e) {
        return value;
      }
    } else {
      return '';
    }
  }
}
