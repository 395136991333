<smacs-fieldset-header [legend]="'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.enterprise_alternate_number.label'">
  <smacs-text fieldId="enterpriseNumberMask"></smacs-text>
  <smacs-checkbox fieldId="enterpriseAddToLocalRoutePartition"></smacs-checkbox>
  <smacs-select fieldId="enterpriseRoutePartition"></smacs-select>
  <smacs-checkbox fieldId="enterpriseUrgent"></smacs-checkbox>
  <smacs-checkbox fieldId="enterpriseAdvertiseGloballyViaIls"></smacs-checkbox>
</smacs-fieldset-header>

<smacs-fieldset-header [legend]="'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.e164_alternate_number.label'">
  <smacs-text fieldId="e164NumberMask"></smacs-text>
  <smacs-checkbox fieldId="e164AddToLocalRoutePartition"></smacs-checkbox>
  <smacs-select fieldId="e164RoutePartition"></smacs-select>
  <smacs-checkbox fieldId="e164Urgent"></smacs-checkbox>
  <smacs-checkbox fieldId="e164AdvertiseGloballyViaIls"></smacs-checkbox>
</smacs-fieldset-header>

<smacs-fieldset-header [legend]="'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.pstn_failover.label'">
  <smacs-select fieldId="pstnFailover"></smacs-select>
</smacs-fieldset-header>
