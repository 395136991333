<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div [hidden]="isLoading">
  <app-breadcrumbs>
    <app-breadcrumb-card-item>
      <h4>{{ 'tkey;pages.zero_touch.microsoft.provisioning.jobs.list.about.card.header' | translate }}</h4>
      <hr />
      <div
        [innerHTML]="'tkey;pages.zero_touch.microsoft.provisioning.jobs.list.about.card.description' | translate"
      ></div>
    </app-breadcrumb-card-item>
  </app-breadcrumbs>
  <app-entity-table
    *ngIf="!isLoading"
    [table]="table"
    [tableRows]="tableRows"
    data-automation="ms-provisioning-job-entity-table"
  ></app-entity-table>
</div>
