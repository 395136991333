<div class="card card-well top-buffer animated fadeIn faster" data-automation="transfer-public-component">
  <div class="card-header">
    <legend class="bottom-buffer mt-0 pb-2">
      <span class="fa-stack fa-lg text-info">
        <i class="icon-circle fa-stack-2x"></i>
        <i [attr.class]="smacsIcons.REASSIGN + ' m-1 fa-stack-1x fa-inverse'"></i>
      </span>

      <span data-automation="reassign-header">
        {{
          reassignType === ReassignTypes.PUBLIC_TO_USER
            ? ('tkey;transfer.publicToUser.title' | translate)
            : ('tkey;operations.menu.reassign.text' | translate)
        }}
      </span>
    </legend>

    <div class="pb-2">
      <p data-automation="user-search-title">
        {{
          reassignType === ReassignTypes.PUBLIC_TO_USER
            ? ('tkey;transfer.publicToUser.userSearch.title' | translate)
            : ('tkey;transfer.userToUser.userSearch.title' | translate)
        }}
      </p>
      <div class="input-group d-flex" *ngIf="!isLoading" (keyup.enter)="onEnterSearchPressed()">
        <smacs-text [fieldId]="'searchTerm'" class="flex-grow-1"></smacs-text>
        <span class="input-group-append">
          <smacs-button
            #searchButton
            [dataAutomation]="'reassign-search-button'"
            [buttonStyle]="buttonStyles.PRIMARY"
            [isPending]="isSubmitting"
            [iconPending]="smacsIcons.SAVING"
            [icon]="smacsIcons.SEARCH"
            [isDisabled]="formData.searchTerm?.length < 2"
            [ngbTooltip]="
              formData.searchTerm?.length < 2 ? ('tkey;operations.menu.reassign.search_disabled' | translate) : ''
            "
            [isTooltipDisabled]="formData.searchTerm?.length > 1"
            (clicked)="onSearchClicked()"
          ></smacs-button>
        </span>
      </div>
    </div>
    <fieldset *ngIf="table" data-automation="reassign-user-search-entity-table">
      <app-entity-table [table]="table" [tableRows]="tableRows"></app-entity-table>
    </fieldset>
    <div class="text-end pb-2 border-top pt-3">
      <smacs-button
        [dataAutomation]="'reassign-cancel-button'"
        [buttonStyle]="buttonStyles.DEFAULT"
        [label]="'tkey;dialogs.button.cancel'"
        [cssClass]="'me-1'"
        [isDisabled]="isSubmitting"
        (clicked)="onCancelClicked()"
      ></smacs-button>
    </div>
  </div>
</div>
