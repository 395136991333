import { Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  BottomNavClearButtonsList,
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { combineLatest, of, Subscription, switchMap, tap, timer } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import {
  WebexCallingSettings,
  WebexCallingStatus,
  WebexCallingStatusEnum,
} from '../../../../shared/models/generated/smacsModels';
import { HtmlInputType, SmacsTextComponent, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../shared/services/toast.service';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { WebexCallingSettingsContext } from '../../../contexts/webex-calling-settings.context';
import { WebexCallingStatusContext } from '../../../contexts/webex-calling-status.context';
import moment from 'moment';

@Component({
  selector: 'ziro-webex-authentication-form',
  templateUrl: './webex-authentication-form.component.html',
  styleUrls: ['./webex-authentication-form.component.scss'],
})
export class WebexAuthenticationFormComponent
  extends SmacsFormAbstractDirective<WebexCallingSettings>
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('clientSecret') clientSecret: SmacsTextComponent;
  @ViewChild('tooltip') tooltip: NgbTooltip;
  @Input() clientIdExists: boolean;
  isRefreshing = false;
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  @Input() callingSettings: WebexCallingSettings;
  callingStatus: WebexCallingStatus;
  disableCopiedUriTooltip = true;
  WebexCallingStatusEnum = WebexCallingStatusEnum;
  formattedExpirationDate: string;
  isLoading = true;
  formConfig = {
    fields: {
      clientId: {
        label: 'tkey;admin.webex_authentication.client_id.label',
        dataAutomation: 'client-id',
        required: () => true,
        disabled: () => this.clientIdExists,
        disabledTooltip: 'tkey;admin.webex_authentication.client_already_provided.disabled_tooltip',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
      },
      clientSecret: {
        label: 'tkey;admin.webex_authentication.client_secret.label',
        dataAutomation: 'client-secret',
        required: () => !this.isExisting,
        disabled: () => this.clientIdExists,
        disabledTooltip: 'tkey;admin.webex_authentication.client_already_provided.disabled_tooltip',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
      },
      redirectUri: {
        label: 'tkey;admin.webex_authentication.redirect_uri.label',
        dataAutomation: 'redirect-uri',
        disabled: () => true,
        helpText: 'tkey;admin.webex_authentication.redirect_uri.help_text',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
      },
    },
  };
  private _subscriptions = new Subscription();
  private _validationState = SmacsFormsValidationState.VALID;
  private readonly SCOPE =
    'spark:people_read spark:people_write spark-admin:licenses_read spark-admin:people_read spark-admin:people_write spark-admin:telephony_config_read spark-admin:telephony_config_write spark-admin:devices_read spark-admin:devices_write';

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _bottomNavService: BottomNavService,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _toastService: ToastService,
    private _webexCallingSettingsContext: WebexCallingSettingsContext,
    private _webexCallingStatusContext: WebexCallingStatusContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    const callingStatusSub = this._webexCallingStatusContext.state$.subscribe((callingStatus: WebexCallingStatus) => {
      this.callingStatus = callingStatus;
      this._setBottomNav();
      this.formatExpirationDate(this.callingStatus.accessTokenExpirationDate);
      this.isLoading = false;
    });

    const formUpdateSub = this.smacsFormsUpdate$.subscribe((updates: SmacsFormsUpdate<WebexCallingSettings>) => {
      this._validationState = updates.valid;
    });

    this._subscriptions.add(callingStatusSub);
    this._subscriptions.add(formUpdateSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this._bottomNavService.dispatch(new BottomNavClearButtonsList());
  }

  onCopyUriClick() {
    this.disableCopiedUriTooltip = false;
    timer(1000).subscribe(() => {
      this.disableCopiedUriTooltip = true;
    });
  }

  redirectToWebex() {
    window.location.href = `https://webexapis.com/v1/authorize?client_id=${encodeURIComponent(
      this.callingSettings.clientId
    )}&response_type=code&redirect_uri=${encodeURIComponent(
      this.callingSettings.redirectUri
    )}&scope=${encodeURIComponent(this.SCOPE)}&state=set_state_here`;
  }

  onRefreshTokensClick() {
    this.isRefreshing = true;

    this._webexCallingStatusContext.refreshWebexCallingStatusTokens().subscribe(() => {
      this.isRefreshing = false;
      this._toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.OK,
        'tkey;shared.toast.refresh.success.title',
        'tkey;admin.webex_authentication.toast.token_refresh_success'
      );
    });
  }

  formatExpirationDate(dateString: string) {
    const dateUtc = new Date(dateString).toUTCString();
    this.formattedExpirationDate = moment(dateUtc).format('MMMM Do YYYY');
  }

  protected submit() {
    this._bottomNavService.dispatch(new BottomNavUpdateState({ hasValidationError: !this.isFormValid() }));
    this._setBottomNavButtonsState('save');
    return this._webexCallingSettingsContext.updateWebexCallingSettings(this.entity).pipe(
      switchMap(() => {
        return this._webexCallingSettingsContext.refreshWebexCallingSettings().pipe(
          switchMap(() => {
            this.setIsExisting(true);
            this._toastService.push(
              ToastTypes.SUCCESS,
              SmacsIcons.CONFIG,
              'tkey;shared.toast.save.success.title',
              'tkey;admin.webex_authentication.toast.save'
            );
            this.clientIdExists = true;
            this.callingSettings.clientId = this.formData.clientId;
            this._setBottomNav();
            return of(null);
          })
        );
      })
    );
  }

  private _setBottomNavButtonsState(type: 'save' | 'delete' | null) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'bottom-nav-delete-button',
        state: {
          pending: type === 'delete',
          buttonDisableState: { disabled: type !== null, tooltipKey: '' },
        },
      })
    );
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'bottom-nav-save-button',
        state: {
          pending: type === 'save',
          buttonDisableState: { disabled: type !== null, tooltipKey: '' },
        },
      })
    );
  }

  private _setBottomNav = () => {
    this.callingSettings.clientId
      ? this._bottomNavService.dispatch(
          new BottomNavUpdateButtonsList([
            {
              id: 'bottom-nav-delete-button',
              dataAutomation: 'bottom-nav-delete-button',
              label: 'tkey;global.button.delete.text',
              icon: this.smacsIcons.DELETE,
              buttonClass: ButtonStyles.DANGER,
              cb: () => this._onDeleteClicked(),
            },
            {
              id: 'bottom-nav-save-button',
              dataAutomation: 'bottom-nav-save-button',
              label: 'tkey;global.button.save.text',
              icon: this.smacsIcons.OK,
              buttonClass: ButtonStyles.PRIMARY,
              type: ButtonTypes.SUBMIT,
              submitSubject: this._validateAndSubmitSource,
              state: {
                pending: false,
                buttonDisableState: {
                  disabled: true,
                  tooltipKey: 'tkey;admin.webex_authentication.client_already_provided.disabled_tooltip',
                },
                tooltipVisible: true,
              },
            },
          ])
        )
      : this._bottomNavService.dispatch(
          new BottomNavUpdateButtonsList([
            {
              id: 'bottom-nav-save-button',
              dataAutomation: 'bottom-nav-save-button',
              label: 'tkey;global.button.save.text',
              icon: this.smacsIcons.OK,
              buttonClass: ButtonStyles.PRIMARY,
              type: ButtonTypes.SUBMIT,
              submitSubject: this._validateAndSubmitSource,
            },
          ])
        );

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this._bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });
    this._subscriptions.add(formSubmittedSub);
  };

  private _onDeleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        promptBody: this._translateService.instant('tkey;admin.webex_authentication.delete_modal'),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteWebexCalling();
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteWebexCalling() {
    this._setBottomNavButtonsState('delete');
    return this._webexCallingSettingsContext.deleteWebexCallingSettings().pipe(
      tap(() => {
        this.setIsExisting(false);
        this._setIsFormSubmitted(false);
        this.smacsFormStateService.setIsFormDirty(false);
        this.clientSecret.showMaskedInput = false;
        this._toastService.push(
          ToastTypes.INFO,
          `${SmacsIcons.DELETE} text-danger`,
          'tkey;shared.toast.delete.success.title',
          'tkey;admin.webex_authentication.toast.delete'
        );
        this.callingSettings.clientId = '';
        this.clientIdExists = false;
        this._setBottomNav();
      }),
      switchMap(() =>
        combineLatest([
          this._webexCallingSettingsContext.refreshWebexCallingSettings(),
          this._webexCallingStatusContext.refreshWebexCallingStatus(),
        ])
      )
    );
  }
}
