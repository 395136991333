import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from '../../../shared/services/toast.service';
import { SystemStatusContext } from '../../../shared/contexts/system-status.context';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { BottomNavService } from '../../../shared/bottom-nav/bottom-nav.service';
import { CallRecordsDatabaseResource, DatabaseImportResponse } from '../../resources/import.abstract.resource';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { TranslateService } from '@ngx-translate/core';
import { ImportAbstractDirective, ImportFileTypes, ImportTypes } from '../import.abstract.directive';
import { Observable } from 'rxjs';

@Component({
  selector: 'smacs-import-call-records',
  templateUrl: '../import.abstract.component.html',
  styleUrls: ['../../admin-page.scss', '../import.abstract.component.scss'],
})
export class ImportCallRecordsComponent extends ImportAbstractDirective implements OnInit, OnDestroy {
  warningText = 'tkey;pages.admin.cdr.import.warning.text';
  warningTextPreamble = 'tkey;pages.admin.cdr.import.preamble.text';

  constructor(
    protected toastService: ToastService,
    protected systemStatusContext: SystemStatusContext,
    protected smacsModalService: SmacsModalService,
    protected bottomNavService: BottomNavService,
    protected smacsFormStateService: SmacsFormStateService,
    protected breadcrumbsService: BreadcrumbsService,
    protected translateService: TranslateService,
    private callRecordsDatabaseResource: CallRecordsDatabaseResource
  ) {
    super(
      toastService,
      systemStatusContext,
      smacsModalService,
      bottomNavService,
      smacsFormStateService,
      translateService
    );
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;pages.admin.cdr.import.header' }]);
    this.importFileType = ImportFileTypes.CDR;
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
  }

  requestImport = (): Observable<DatabaseImportResponse> => {
    this.formData.importFrom = ImportTypes.FILE;
    const request = this.getImportRequest();

    return this.callRecordsDatabaseResource.post(request);
  };
}
