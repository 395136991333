import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultDeskPhoneRequest, Phone } from '../../shared/models/generated/smacsModels';
import { Observable } from 'rxjs';

@Injectable()
export class DefaultDeskphoneResource {
  constructor(private http: HttpClient) {}

  post(body: DefaultDeskPhoneRequest): Observable<Phone> {
    return this.http.post<Phone>('/services/cisco/defaults/phones/desk-phone', body);
  }
}
