import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  SiteBasedHelpdeskUserPermission,
  SiteBasedHelpdeskUserPermissions,
} from '../../shared/models/generated/smacsModels';
import { Observable } from 'rxjs';

@Injectable()
export class SiteBasedHelpdeskManagementResource {
  constructor(private $http: HttpClient) {}

  get(): Observable<SiteBasedHelpdeskUserPermissions> {
    return this.$http.get<SiteBasedHelpdeskUserPermissions>(
      '/services/cisco/admin/site-based-helpdesk-management/permissions'
    );
  }

  put(body: SiteBasedHelpdeskUserPermission): Observable<void> {
    return this.$http.put<void>(
      `/services/cisco/admin/site-based-helpdesk-management/permissions/${encodeURIComponent(body.userId)}`,
      body
    );
  }
}
