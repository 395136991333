import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { PhoneButtonsBlfComponent } from './phone-buttons-shared/phone-buttons-blf';
import { PhoneButtonsLineComponent } from './phone-buttons-shared/phone-buttons-line.component';
import { PhoneButtonsIntercomComponent } from './phone-buttons-shared/phone-buttons-intercom.component';
import { PhoneButtonsSpeedDialComponent } from './phone-buttons-shared/phone-buttons-speed-dial.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SmacsFormsModule } from '../../../forms/smacs-forms.module';
import { PhoneButtonsLineHelpdeskComponent } from './helpdesk/phone-buttons-line-helpdesk.component';
import { PhoneButtonsServiceUrlHelpdeskComponent } from './helpdesk/phone-buttons-service-url-helpdesk.component';
import { PhoneButtonEditModalComponent } from '../edit-modal/phone-button-edit-modal.component';
import { PhoneButtonsLineExtensionSearchComponent } from './helpdesk/phone-buttons-line-extension-search/phone-buttons-line-extension-search.component';
import { PhoneButtonsLineHelpdeskFormComponent } from './helpdesk/phone-buttons-line-helpdesk-form/phone-buttons-line-helpdesk-form.component';
import { PhoneButtonsEditFormComponent } from './phone-buttons-edit-form.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    SmacsFormsModule,
  ],
  declarations: [
    PhoneButtonsLineComponent,
    PhoneButtonsIntercomComponent,
    PhoneButtonsSpeedDialComponent,
    PhoneButtonsBlfComponent,
    PhoneButtonsLineHelpdeskComponent,
    PhoneButtonsServiceUrlHelpdeskComponent,
    PhoneButtonEditModalComponent,
    PhoneButtonsLineExtensionSearchComponent,
    PhoneButtonsLineHelpdeskFormComponent,
    PhoneButtonsEditFormComponent,
  ],
  exports: [
    PhoneButtonsLineComponent,
    PhoneButtonsIntercomComponent,
    PhoneButtonsSpeedDialComponent,
    PhoneButtonsBlfComponent,
    PhoneButtonsLineHelpdeskComponent,
    PhoneButtonsServiceUrlHelpdeskComponent,
    PhoneButtonEditModalComponent,
    PhoneButtonsLineExtensionSearchComponent,
    PhoneButtonsLineHelpdeskFormComponent,
    PhoneButtonsEditFormComponent,
  ],
})
export class PhoneButtonEditFormModule {}
