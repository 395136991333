<app-top-nav></app-top-nav>
<div class="smacs-distribution-list-page-content">
  <div class="col-md-10 offset-md-1 top-buffer">
    <app-breadcrumbs></app-breadcrumbs>
    <div class="card bg-light">
      <div class="card-body">
        <legend class="mt-0 pb-2">
          <span class="fa-stack fa-lg text-info">
            <i class="{{ smacsIcons.CIRCLE }} fa-stack-2x"></i>
            <i class="{{ smacsIcons.DISTRIBUTION_LIST }} fa-stack-1x fa-inverse"></i>
          </span>
          {{ 'tkey;pages.distributionlist.add.pagetitle' | translate }}
        </legend>
        <!-- Confirm to add an user from search result -->
        <div *ngIf="confirmAdd">
          <div
            [innerHTML]="
              'tkey;pages.distributionlist.add.confirm' | translate : { member: userToAdd, list: distributionList }
            "
          ></div>
          <div [innerHTML]="'tkey;pages.distributionlist.add.confirm.continue' | translate"></div>
          <hr />
          <div class="text-end">
            <smacs-button
              [dataAutomation]="'add-member-cancel-button'"
              [label]="'tkey;pages.distributionlist.add.cancel'"
              (clicked)="goToConfirmAddPage(false)"
              [buttonStyle]="buttonStyles.DEFAULT"
              [buttonSize]="buttonSizes.NORMAL"
              [cssClass]="'me-1'"
              [isDisabled]="isAddingUser"
            ></smacs-button>
            <smacs-button
              [dataAutomation]="'add-member-confirm-button'"
              [label]="'tkey;pages.distributionlist.add.member'"
              (clicked)="addUserToList()"
              [buttonStyle]="buttonStyles.PRIMARY"
              [buttonSize]="buttonSizes.NORMAL"
              [cssClass]="'ms-1'"
              [isPending]="isAddingUser"
              [iconPending]="smacsIcons.SAVING"
              [icon]="smacsIcons.ADD"
            ></smacs-button>
          </div>
        </div>
        <!-- Search user -->
        <div>
          <form (keyup.enter)="onEnterSubmit()" [hidden]="confirmAdd">
            <div class="input-group mb-3">
              <smacs-text [fieldId]="'search'" class="flex-fill"></smacs-text>
              <smacs-submit-button
                [buttonStyle]="buttonStyles.PRIMARY"
                [buttonSize]="buttonSizes.NORMAL"
                [icon]="smacsIcons.SEARCH"
                [dataAutomation]="'search-user'"
                [isDisabled]="isSubmitting"
                [isPending]="isSubmitting"
                [iconPending]="smacsIcons.SAVING"
                [formSubmitSubject]="_validateAndSubmitSource"
              ></smacs-submit-button>
            </div>

            <fieldset *ngIf="table" data-automation="add-to-distribution-list-entity-table">
              <app-entity-table [table]="table" [tableRows]="tableRows"></app-entity-table>
            </fieldset>
            <hr />
            <div class="text-end">
              <smacs-button
                [dataAutomation]="'back-to-distribution-list'"
                [label]="'tkey;pages.distributionlist.add.back'"
                (clicked)="backToDistributionList()"
                [buttonStyle]="buttonStyles.DEFAULT"
                [buttonSize]="buttonSizes.NORMAL"
              ></smacs-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
