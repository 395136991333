import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { adminRouteChildrenConfig, adminZeroTouchRouteConfig } from './route-configs';
import { HomeComponent } from '../../../../components/src/lib/home/home.component';
import { routeGuardFn } from '../../../../components/src/lib/shared/services/route-guard.service';
import { LoginComponent } from '../../../../components/src/lib/login/login.component';
import { loginRouteGuardFn } from '../../../../components/src/lib/shared/services/login-guard-service';
import { LogoutComponent } from '../../../../components/src/lib/shared/logout/logout.component';
import { SmacsSearchComponent } from '../../../../components/src/lib/helpdesk/search/search.component';
import {
  smacsFormConfirmRouteChangeGuardFn,
  SmacsFormConfirmRouteChangeService,
} from '../../../../components/src/lib/forms/smacs-form-confirm-route-change.service';
import { AdminComponent } from '../../../../components/src/lib/admin/admin.component';
import { SelfServeComponent } from '../../../../components/src/lib/self-serve/self-serve.component';
import { SelfServeUserDetailsComponent } from '../../../../components/src/lib/self-serve/self-serve-user-details/self-serve-user-details.component';
import { SelfServeDeskphoneEditComponent } from '../../../../components/src/lib/self-serve/self-serve-edit-deskphone/self-serve-deskphone-edit.component';
import { SelfServeVmPinResetComponent } from '../../../../components/src/lib/self-serve/self-serve-pin-reset/self-serve-vm-pin-reset/self-serve-vm-pin-reset.component';
import { SelfServeEmPinResetComponent } from '../../../../components/src/lib/self-serve/self-serve-pin-reset/self-serve-em-pin-reset/self-serve-em-pin-reset.component';
import { SelfServeCallForwardEditComponent } from '../../../../components/src/lib/self-serve/self-serve-call-forward-edit/self-serve-call-forward-edit.component';
import { SelfServeSnrEditComponent } from '../../../../components/src/lib/self-serve/self-serve-snr-edit/self-serve-snr-edit.component';
import { ActivateDeskphoneComponent } from '../../../../components/src/lib/self-serve/activate-deskphone/activate-deskphone.component';
import { SelfServeReplaceDeskphoneComponent } from '../../../../components/src/lib/self-serve/self-serve-replace-deskphone/self-serve-replace-deskphone.component';
import { deskphoneRouteGuardFn } from '../../../../components/src/lib/shared/services/activate-deskphone-route-guard.service';
import { ReportingComponent } from '../../../../components/src/lib/reporting/reporting.component';
import { MicrosoftDialPlanInventoryReportComponent } from '../../../../components/src/lib/reporting/microsoft/dial-plan-inventory-report/dial-plan-inventory-report.component';
import { CustomPowershellReportsComponent } from '../../../../components/src/lib/reporting/microsoft/custom-powershell-reports/custom-powershell-reports.component';
import { CustomPowershellReportFormComponent } from '../../../../components/src/lib/reporting/microsoft/custom-powershell-reports/custom-powershell-report-form/custom-powershell-report-form.component';
import { CallLogsComponent } from '../../../../components/src/lib/reporting/cisco/call-logs/call-logs.component';
import { DashboardComponent } from '../../../../components/src/lib/reporting/cisco/dashboard/dashboard.component';
import { OrphanedDevicesComponent } from '../../../../components/src/lib/reporting/cisco/orphaned-devices/orphaned-devices.component';
import { DnAvailabilityComponent } from '../../../../components/src/lib/reporting/cisco/dn-availability/dn-availability.component';
import { DeviceUtilizationComponent } from '../../../../components/src/lib/reporting/cisco/device-utilization/device-utilization.component';
import { LineAppearanceManagementReportComponent } from '../../../../components/src/lib/reporting/cisco/bulk-line-appearance-management/line-appearance-management-report.component';
import { CucmLicenseOptimizationComponent } from '../../../../components/src/lib/reporting/cisco/cucm-license-optimization/cucm-license-optimization.component';
import { CucmLicenseOptimizationOverviewComponent } from '../../../../components/src/lib/reporting/cisco/cucm-license-optimization/cucm-license-optimization-overview/cucm-license-optimization-overview.component';
import { CucmLicenseOptimizationCandidatesComponent } from '../../../../components/src/lib/reporting/cisco/cucm-license-optimization/cucm-license-optimization-candidates/cucm-license-optimization-candidates.component';
import { GlobalDirectoryComponent } from '../../../../components/src/lib/reporting/cisco/global-directory/global-directory.component';
import { UnusedPhoneButtonTemplatesComponent } from '../../../../components/src/lib/reporting/cisco/unused-phone-button-templates/unused-phone-button-templates.component';
import { CustomReportsComponent } from '../../../../components/src/lib/reporting/cisco/custom-reports/custom-reports.component';
import { CustomReportsListComponent } from '../../../../components/src/lib/reporting/cisco/custom-reports/custom-reports-list/custom-reports-list.component';
import { CucmReportBuilderComponent } from '../../../../components/src/lib/reporting/cisco/custom-reports/cucm-report-builder/cucm-report-builder.component';
import { SystemStatusComponent } from '../../../../components/src/lib/system-status/system-status.component';
import { UserDetailComponent } from '../../../../components/src/lib/helpdesk/cisco/user-detail/user-detail.component';
import { SmacsUserDetailHomeComponent } from '../../../../components/src/lib/helpdesk/cisco/user-detail-home/user-detail-home.component';
import { SnrComponent } from '../../../../components/src/lib/helpdesk/cisco/snr/snr.component';
import { UccxAgentComponent } from '../../../../components/src/lib/helpdesk/cisco/uccx-agent/uccx-agent.component';
import { PcceAgentSettingsComponent } from '../../../../components/src/lib/helpdesk/cisco/pcce-agent-settings/pcce-agent-settings.component';
import { SmacsDirectoryNumberFormComponent } from '../../../../components/src/lib/helpdesk/cisco/directory-number/directory-number-form.component';
import { UserDetailPhoneComponent } from '../../../../components/src/lib/helpdesk/cisco/user-detail/user-detail-phone/user-detail-phone.component';
import { UserDetailVoicemailComponent } from '../../../../components/src/lib/helpdesk/cisco/user-detail/user-detail-voicemail/user-detail-voicemail.component';
import { UserPinResetComponent } from '../../../../components/src/lib/helpdesk/cisco/user-pin-reset/user-pin-reset.component';
import { ChangeSiteComponent } from '../../../../components/src/lib/helpdesk/cisco/change-site/change-site.component';
import { MakePublicComponent } from '../../../../components/src/lib/helpdesk/cisco/make-public/make-public.component';
import { SendEmailComponent } from '../../../../components/src/lib/helpdesk/cisco/send-email/send-email.component';
import { WebexCallingComponent } from '../../../../components/src/lib/helpdesk/cisco/webex-calling/webex-calling.component';
import { ReassignUserComponent } from '../../../../components/src/lib/helpdesk/cisco/reassign-user/reassign-user.component';
import { PublicPhoneComponent } from '../../../../components/src/lib/helpdesk/cisco/public-phone/public-phone.component';
import { PublicPhoneContainerComponent } from '../../../../components/src/lib/helpdesk/cisco/public-phone/public-phone-container/public-phone-container.component';
import { PublicPhoneChangeSiteComponent } from '../../../../components/src/lib/helpdesk/cisco/public-phone/public-phone-change-site/public-phone-change-site.component';
import { PublicPhonePinResetComponent } from '../../../../components/src/lib/helpdesk/cisco/public-phone/public-phone-pin-reset/public-phone-pin-reset.component';
import { PublicPhoneReassignComponent } from '../../../../components/src/lib/helpdesk/cisco/public-phone/public-phone-reassign/public-phone-reassign.component';
import { SearchResultPinResetComponent } from '../../../../components/src/lib/helpdesk/search/search-result-pin-reset/search-result-pin-reset.component';
import { SmacsCdrDumpComponent } from '../../../../components/src/lib/cdr-dump/cdr-dump.component';
import { AddUserComponent } from '../../../../components/src/lib/helpdesk/cisco/add-user/add-user.component';
import { Microsoft360DetailComponent } from '../../../../components/src/lib/helpdesk/microsoft/microsoft360-view/microsoft360-detail/microsoft360-detail.component';
import { Microsoft360DetailHomeComponent } from '../../../../components/src/lib/helpdesk/microsoft/microsoft360-view/microsoft360-detail-home/microsoft360-detail-home.component';
import { MicrosoftViewLicensesComponent } from '../../../../components/src/lib/helpdesk/microsoft/microsoft-view-licenses/microsoft-view-licenses.component';
import { TeamsAudioConferencingComponent } from '../../../../components/src/lib/helpdesk/microsoft/teams-audio-conferencing/teams-audio-conferencing.component';
import { TeamsCallingContainerComponent } from '../../../../components/src/lib/helpdesk/microsoft/teams-calling-container/teams-calling-container.component';
import { MicrosoftTeamsCallingComponent } from '../../../../components/src/lib/helpdesk/microsoft/teams-calling-container/teams-calling/microsoft-teams-calling.component';
import { DelegateManagementComponent } from '../../../../components/src/lib/helpdesk/microsoft/teams-calling-container/delegate-management/delegate-management.component';
import { SecurityGroupMembershipsComponent } from '../../../../components/src/lib/helpdesk/microsoft/security-group-memberships/security-group-memberships.component';
import { DubberComplianceRecordingComponent } from '../../../../components/src/lib/helpdesk/microsoft/dubber-compliance-recording/dubber-compliance-recording.component';
import { SupportComponent } from '../../../../components/src/lib/shared/support/support.component';
import { DistributionListComponent } from '../../../../components/src/lib/helpdesk/cisco/distribution-list/distribution-list.component';
import { AddMemberToDistributionListComponent } from '../../../../components/src/lib/helpdesk/cisco/distribution-list/add-member-to-distribution-list.component';
import { PolicyMisconfigurationReportComponent } from '../../../../components/src/lib/reporting/microsoft/policy-misconfiguration-report/policy-misconfiguration-report.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [routeGuardFn],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;pages.home.title',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [loginRouteGuardFn],
    data: {
      title: 'tkey;pages.login.title',
    },
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [routeGuardFn],
  },
  {
    path: 'search',
    component: SmacsSearchComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    children: [
      {
        path: '?query',
        component: SmacsSearchComponent,
        canActivate: [routeGuardFn],
        pathMatch: 'full',
      },
    ],
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [routeGuardFn],
    children: adminRouteChildrenConfig,
  },
  { path: 'zero-touch', redirectTo: 'automate' },
  adminZeroTouchRouteConfig,
  {
    path: 'self-serve',
    canActivate: [routeGuardFn],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/',
      },
      {
        path: 'user',
        canActivate: [routeGuardFn],
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: '/',
          },
          {
            path: ':username',
            component: SelfServeComponent,
            canActivate: [routeGuardFn],
            children: [
              {
                path: '',
                component: SelfServeUserDetailsComponent,
                canActivate: [routeGuardFn],
                data: {
                  title: 'tkey;pages.title.selfserve',
                },
              },
              {
                path: 'phone/:phoneId',
                component: SelfServeDeskphoneEditComponent,
                canActivate: [routeGuardFn],
                data: {
                  title: 'tkey;pages.title.selfserve',
                },
              },
              {
                path: 'voicemail-pin-reset/:voicemailId',
                component: SelfServeVmPinResetComponent,
                canActivate: [routeGuardFn],
                data: {
                  title: 'tkey;pages.title.selfserve',
                },
              },
              {
                path: 'extension-mobility-pin-reset/:extensionMobilityId',
                component: SelfServeEmPinResetComponent,
                canActivate: [routeGuardFn],
                data: {
                  title: 'tkey;pages.title.selfserve',
                },
              },
              {
                path: 'call-forward/:dnId',
                component: SelfServeCallForwardEditComponent,
                canActivate: [routeGuardFn],
                data: {
                  title: 'tkey;pages.title.selfserve',
                },
              },
              {
                path: 'snr-destination/:destinationId',
                component: SelfServeSnrEditComponent,
                canActivate: [routeGuardFn],
                data: {
                  title: 'tkey;pages.title.selfserve',
                },
              },
              {
                path: 'snr-profile/:snrProfileId/snr-destination',
                component: SelfServeSnrEditComponent,
                canActivate: [routeGuardFn],
                data: {
                  title: 'tkey;pages.title.selfserve',
                },
              },
              {
                path: 'activate',
                component: ActivateDeskphoneComponent,
                canActivate: [routeGuardFn],
                canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
                data: {
                  title: 'tkey;pages.title.selfserve',
                },
              },
              {
                path: 'replace-deskphone/:phoneId',
                component: SelfServeReplaceDeskphoneComponent,
                canActivate: [routeGuardFn],
                data: {
                  title: 'tkey;pages.title.selfserve',
                },
              },
            ],
          },
        ],
      },
    ],
  },
  { path: 'activate', canActivate: [routeGuardFn, deskphoneRouteGuardFn], children: [] },
  { path: 'report', redirectTo: 'reporting' },
  { path: 'control', redirectTo: 'reporting' },
  {
    path: 'reporting',
    canActivate: [routeGuardFn],
    component: ReportingComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'microsoft',
        redirectTo: 'microsoft/dial-plan-inventory',
        pathMatch: 'full',
      },
      {
        path: 'microsoft/dial-plan-inventory',
        component: MicrosoftDialPlanInventoryReportComponent,
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;reporting.microsoft.dial_plan_inventory.title',
        },
      },
      {
        path: 'microsoft/custom-powershell-reports',
        canActivate: [routeGuardFn],
        children: [
          {
            path: '',
            component: CustomPowershellReportsComponent,
            data: {
              title: 'tkey;reporting.microsoft.custom_powershell_reports.title',
            },
          },
          {
            path: 'new-report',
            component: CustomPowershellReportFormComponent,
            data: {
              title: 'tkey;reporting.microsoft.custom_powershell_reports.title',
            },
          },
          {
            path: ':id',
            component: CustomPowershellReportFormComponent,
            data: {
              title: 'tkey;reporting.microsoft.custom_powershell_reports.title',
            },
          },
        ],
      },
      {
        path: 'microsoft/policy-misconfiguration-report',
        component: PolicyMisconfigurationReportComponent,
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;reporting.microsoft.policy_misconfig.title',
        },
      },
      {
        path: 'call-logs',
        component: CallLogsComponent,
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;reporting.call_logs.title',
        },
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;menu.report.dashboard',
        },
      },
      {
        path: 'orphaned-devices',
        component: OrphanedDevicesComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;menu.admin.admin.orphaned_devices',
        },
      },
      {
        path: 'dn-availability',
        component: DnAvailabilityComponent,
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;reporting.dn_availability.title',
        },
      },
      {
        path: 'device-utilization',
        component: DeviceUtilizationComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;reporting.device_utilization.title',
        },
      },
      {
        path: 'bulk-line-appearance-management',
        component: LineAppearanceManagementReportComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;reporting.bulk_line_appearance_management.title',
        },
      },
      {
        path: 'cucm-license-optimization',
        component: CucmLicenseOptimizationComponent,
        canActivate: [routeGuardFn],
        children: [
          {
            path: '',
            component: CucmLicenseOptimizationOverviewComponent,
            data: {
              title: 'tkey;reporting.cucm_license_optimizer.overview.title',
            },
          },
          {
            path: ':licenseTier',
            component: CucmLicenseOptimizationCandidatesComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
            data: {
              title: 'tkey;reporting.cucm_license_optimizer.candidates.title',
            },
          },
        ],
      },
      {
        path: 'global-directory',
        component: GlobalDirectoryComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;reporting.global_directory.title',
        },
      },
      {
        path: 'unused-phone-button-templates',
        component: UnusedPhoneButtonTemplatesComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;menu.report.unused_phone_button_template.title',
        },
      },
      {
        path: 'custom-reports',
        component: CustomReportsComponent,
        children: [
          {
            path: '',
            component: CustomReportsListComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
            data: {
              title: 'tkey;reporting.custom_reports.title',
            },
          },
          {
            path: 'new-report',
            component: CucmReportBuilderComponent,
            canActivate: [routeGuardFn],
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
            data: {
              title: 'tkey;reporting.custom_reports.new_report.title',
            },
          },
          {
            path: ':reportId',
            component: CucmReportBuilderComponent,
            canActivate: [routeGuardFn],
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
            data: {
              title: 'tkey;reporting.custom_reports.edit_report.title',
            },
          },
        ],
      },
    ],
  },
  { path: 'system-status', redirectTo: 'system-health-status', pathMatch: 'full' },
  {
    path: 'system-health-status',
    component: SystemStatusComponent,
    data: {
      title: 'tkey;system_status.title',
    },
  },
  {
    path: 'user/:username',
    component: UserDetailComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    children: [
      {
        path: '',
        component: SmacsUserDetailHomeComponent,
        data: {
          title: 'tkey;pages.details.title',
          breadcrumb: 'tkey;userdetail.breadcrumb.home.text',
        },
      },
      {
        path: 'snr/:profileId',
        component: SnrComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;snr.page.title',
        },
      },
      {
        path: 'snr',
        component: SnrComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;snr.page.title',
        },
      },
      {
        path: 'uccx-agent/:serverId',
        component: UccxAgentComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;pages.details.title',
          breadcrumb: 'tkey;shared.service.agent.text',
        },
      },
      {
        path: 'pcce-agent-settings',
        component: PcceAgentSettingsComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;pcce.agent_settings.title',
          breadcrumb: 'tkey;pcce.agent_settings.title',
        },
      },
      {
        path: 'primary-extension',
        pathMatch: 'full',
        component: SmacsDirectoryNumberFormComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;pages.details.title',
          breadcrumb: 'tkey;shared.service.primary.extension.text',
        },
      },
      {
        path: 'primary-extension/:dnId',
        pathMatch: 'full',
        component: SmacsDirectoryNumberFormComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;pages.details.title',
          breadcrumb: 'tkey;shared.service.primary.extension.text',
        },
      },
      {
        path: 'deskphone',
        pathMatch: 'full',
        component: UserDetailPhoneComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;shared.model.deskphone.text',
          breadcrumb: 'tkey;shared.model.deskphone.text',
        },
      },
      {
        path: 'deskphone/:phoneId',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;shared.model.deskphone.text',
          breadcrumb: 'tkey;shared.model.deskphone.text',
        },
      },
      {
        path: 'deskphone/:phoneId/copy',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;shared.model.deskphone.text',
          breadcrumb: 'tkey;shared.model.deskphone.text',
        },
      },
      {
        path: 'voicemail',
        component: UserDetailVoicemailComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'Voicemail',
          breadcrumb: 'tkey;userdetail.voicemail.title',
        },
      },
      {
        path: 'voicemail/:voicemailId',
        component: UserDetailVoicemailComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'Voicemail',
          breadcrumb: 'tkey;userdetail.voicemail.title',
        },
      },
      {
        path: 'iphone',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.iphone.page.title',
          breadcrumb: 'tkey;userdetail.iphone.page.title',
        },
      },
      {
        path: 'iphone/:phoneId',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.iphone.page.title',
          breadcrumb: 'tkey;userdetail.iphone.page.title',
        },
      },
      {
        path: 'android',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.android.page.title',
          breadcrumb: 'tkey;userdetail.android.page.title',
        },
      },
      {
        path: 'android/:phoneId',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.android.page.title',
          breadcrumb: 'tkey;userdetail.android.page.title',
        },
      },
      {
        path: 'extension-mobility',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.extension_mobility.page.title',
          breadcrumb: 'tkey;userdetail.extension_mobility.page.title',
        },
      },
      {
        path: 'extension-mobility/:phoneId',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.extension_mobility.page.title',
          breadcrumb: 'tkey;userdetail.extension_mobility.page.title',
        },
      },
      {
        path: 'pin-reset',
        component: UserPinResetComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;pages.pinreset.title',
          breadcrumb: 'tkey;pages.pinreset.title',
        },
      },
      {
        path: 'tablet',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.tablet.page.title',
          breadcrumb: 'tkey;userdetail.tablet.page.title',
        },
      },
      {
        path: 'tablet/:phoneId',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.tablet.page.title',
          breadcrumb: 'tkey;userdetail.tablet.page.title',
        },
      },
      {
        path: 'im-softphone',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.imSoftphone.page.title',
          breadcrumb: 'tkey;userdetail.imSoftphone.page.title',
        },
      },
      {
        path: 'im-softphone/:phoneId',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.imSoftphone.page.title',
          breadcrumb: 'tkey;userdetail.imSoftphone.page.title',
        },
      },
      {
        path: 'change-site',
        component: ChangeSiteComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;change.site.change_site',
        },
      },
      {
        path: 'make-public',
        component: MakePublicComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;transfer.menu.to.public',
        },
      },
      {
        path: 'send-email',
        component: SendEmailComponent,
        data: {
          title: 'tkey;helpdesk.send_email.table.title',
          breadcrumb: 'tkey;helpdesk.send_email.table.title',
        },
      },
      {
        path: 'webex-calling/:email',
        pathMatch: 'full',
        component: WebexCallingComponent,
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;pages.details.title',
          breadcrumb: 'tkey;shared.service.webex_calling.text',
        },
      },
      {
        path: 'cipc',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.cipc.page.title',
          breadcrumb: 'tkey;userdetail.cipc.page.title',
        },
      },
      {
        path: 'cipc/:phoneId',
        component: UserDetailPhoneComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;userdetail.cipc.page.title',
          breadcrumb: 'tkey;userdetail.cipc.page.title',
        },
      },
      {
        path: 'reassign',
        component: ReassignUserComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;pages.reassign.title',
          breadcrumb: 'tkey;pages.reassign.title',
        },
      },
      {
        path: 'reassign/:targetUsername',
        component: ReassignUserComponent,
        pathMatch: 'full',
        canActivate: [routeGuardFn],
        data: {
          title: 'tkey;pages.reassign.title',
        },
      },
    ],
  },
  {
    path: 'phone/:phoneId/line/:lineNum/directory-number',
    component: SmacsDirectoryNumberFormComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;pages.details.title',
    },
  },
  {
    path: 'phone/:phoneId/line/:lineNum/directory-number/:dnId',
    component: SmacsDirectoryNumberFormComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;pages.details.title',
    },
  },
  {
    path: 'public-phone',
    canActivate: [routeGuardFn],
    children: [
      {
        path: '',
        component: PublicPhoneComponent,
        data: {
          title: 'tkey;pages.phone.title',
        },
        children: [
          {
            path: '',
            component: PublicPhoneContainerComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
            data: {
              title: 'tkey;pages.phone.title',
            },
          },
        ],
      },
      {
        path: ':phoneName',
        component: PublicPhoneComponent,
        data: {
          title: 'tkey;pages.phone.title',
        },
        children: [
          {
            path: '',
            component: PublicPhoneContainerComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
            data: {
              title: 'tkey;pages.phone.title',
            },
          },
          {
            path: 'change-site',
            component: PublicPhoneChangeSiteComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
            data: {
              title: 'tkey;pages.phone.title',
              breadcrumb: 'tkey;change.site.change_site',
            },
          },
          {
            path: 'pin-reset',
            component: PublicPhonePinResetComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
            data: {
              title: 'tkey;pages.pinreset.title',
              breadcrumb: 'tkey;pages.pinreset.title',
            },
          },
          {
            path: 'copy',
            component: PublicPhoneContainerComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
            data: {
              title: 'tkey;pages.phone.title',
            },
          },
          {
            path: 'reassign',
            component: PublicPhoneReassignComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
          },
          {
            path: 'reassign/:username',
            component: PublicPhoneReassignComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
          },
          {
            path: 'reassign/:search',
            component: PublicPhoneReassignComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
          },
        ],
      },
    ],
  },
  {
    path: 'search',
    component: SmacsSearchComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;search.title',
    },
  },
  {
    path: 'unity-servers/:serverId/voicemails/:voicemailId/pin-reset',
    component: SearchResultPinResetComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;pages.pinreset.title',
    },
  },
  {
    path: 'cdr-dump',
    component: SmacsCdrDumpComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;cdr_dump.title',
    },
  },
  {
    path: 'user-add',
    component: AddUserComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    pathMatch: 'full',
    data: {
      title: 'tkey;pages.useradd.success.newUser',
    },
  },
  {
    path: '360-view/microsoft/:upn',
    component: Microsoft360DetailComponent,
    canActivate: [routeGuardFn],
    children: [
      {
        path: '',
        component: Microsoft360DetailHomeComponent,
        data: {
          title: 'tkey;microsoft_360.view.page.title',
          breadcrumb: 'tkey;microsoft_360.view.breadcrumb.home.text',
        },
      },
      {
        path: 'licenses',
        component: MicrosoftViewLicensesComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;microsoft_360.view.licenses.info.title',
          breadcrumb: 'tkey;microsoft_360.view.licenses.info.breadcrumb.text',
        },
      },
      {
        path: 'audio-conference',
        component: TeamsAudioConferencingComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;microsoft_360.view.teams.audio.conference.title',
          breadcrumb: 'tkey;microsoft_360.view.teams.audio.conference.breadcrumb.text',
        },
      },
      {
        path: 'teams-calling',
        component: TeamsCallingContainerComponent,
        data: {
          title: 'tkey;microsoft_360.view.teams.calling.title',
          breadcrumb: 'tkey;microsoft_360.view.teams.calling.title',
        },
        children: [
          {
            path: '',
            component: MicrosoftTeamsCallingComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
          },
          {
            path: 'delegate-management',
            component: DelegateManagementComponent,
            canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
            data: {
              title: 'tkey;microsoft_360.view.teams.delegate_management.title',
              breadcrumb: 'tkey;microsoft_360.view.teams.delegate_management.title',
            },
          },
        ],
      },
      {
        path: 'security-group-membership',
        component: SecurityGroupMembershipsComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;microsoft_360.view.security.groups.tile.title',
          breadcrumb: 'tkey;microsoft_360.view.security.groups.membership.breadcrumb.text',
        },
      },
      {
        path: 'dubber-compliance-recording',
        component: DubberComplianceRecordingComponent,
        canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
        data: {
          title: 'tkey;microsoft_360.view.dubber.tile.title',
          breadcrumb: 'tkey;microsoft_360.view.dubber.breadcrumb.text',
        },
      },
    ],
  },
  {
    path: 'support',
    component: SupportComponent,
    pathMatch: 'full',
  },
  {
    path: 'server/:serverid/distribution-list/:distributionlistid',
    component: DistributionListComponent,
    canActivate: [routeGuardFn],
    canDeactivate: [smacsFormConfirmRouteChangeGuardFn],
    pathMatch: 'full',
    data: {
      title: 'tkey;pages.distributionlist.pagetitle',
    },
  },
  {
    path: 'server/:serverid/distribution-list/:distributionlistid/add',
    component: AddMemberToDistributionListComponent,
    canActivate: [routeGuardFn],
    data: {
      title: 'tkey;pages.distributionlist.add.pagetitle',
      breadcrumb: 'tkey;pages.distributionlist.add.pagetitle',
    },
  },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
  ],
  providers: [SmacsFormConfirmRouteChangeService],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}
