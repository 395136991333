<smacs-datatable
  [data]="tableRows"
  [columns]="tableColumns"
  [mainFilterPlaceholder]="'tkey;admin.billing.dlr_misconfigurations.datatable.search.placeholder' | translate"
  [maxLength]="30"
  [isFirstColumnSticky]="false"
  [dataAutomation]="tableDataAutomation"
  [noResultsAlert]="noResultsAlert"
  [noResultsAlertClass]="noResultsAlert ? 'table-success' : null"
  [showSelectColumn]="true"
  (rowSelected)="onRowSelected()"
>
  <smacs-datatable-multi-select-filter
    [label]="'tkey;admin.billing.dlr_misconfigurations.datatable.misconfiguration_type'"
    [dataAutomation]="'misconfiguration-type-filter'"
    [items]="misconfigurationTypeOptions"
    [matchFn]="isMisconfigurationTypeMatch"
    [tableId]="tableDataAutomation"
  ></smacs-datatable-multi-select-filter>
</smacs-datatable>
