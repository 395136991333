import { Injectable } from '@angular/core';
import { Observable, of, ReplaySubject, switchMap } from 'rxjs';
import { ServiceProvisioningLevel, TileConfig } from '../../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TileConfigContext {
  private _baseUrl = '/services/cisco/field-configs/tile-configs';

  private _stateSource = new ReplaySubject<TileConfig>(1);
  state$ = this._stateSource.asObservable();

  constructor(private _http: HttpClient) {}

  getTileConfigs(siteId: number): Observable<void> {
    if (siteId === -1) {
      const tileConfigForNoSite: TileConfig = {
        android: ServiceProvisioningLevel.SHOW,
        cipc: ServiceProvisioningLevel.SHOW,
        deskphone: ServiceProvisioningLevel.SHOW,
        extensionMobility: ServiceProvisioningLevel.SHOW,
        imPresence: ServiceProvisioningLevel.SHOW,
        imSoftphone: ServiceProvisioningLevel.SHOW,
        iphone: ServiceProvisioningLevel.SHOW,
        pcceAgent: ServiceProvisioningLevel.SHOW,
        primaryExtension: ServiceProvisioningLevel.SHOW,
        snrProfile: ServiceProvisioningLevel.SHOW,
        snrDestination: ServiceProvisioningLevel.SHOW,
        tablet: ServiceProvisioningLevel.SHOW,
        voicemail: ServiceProvisioningLevel.SHOW,
        webexCalling: ServiceProvisioningLevel.SHOW,
      };
      this._stateSource.next(tileConfigForNoSite);
      return of(null);
    } else {
      return this._http.post<TileConfig>(this._baseUrl, { siteId }).pipe(
        switchMap((tileConfig) => {
          this._stateSource.next(tileConfig);
          return of(null);
        })
      );
    }
  }
}
