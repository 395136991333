import { Component, Input, OnInit } from '@angular/core';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { Phone, PhoneServiceSubscription, ServiceUrlButton } from '../../../models/generated/smacsModels';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';

@Component({
  selector: 'smacs-phone-buttons-service-url-helpdesk',
  templateUrl: './phone-buttons-service-url-helpdesk.component.html',
})
export class PhoneButtonsServiceUrlHelpdeskComponent
  extends SmacsFormAbstractDirective<ServiceUrlButton>
  implements OnInit
{
  @Input() phone: Phone;

  protected formConfig: SmacsFormConfig;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    const serviceSubscriptionNames = this.phone.serviceSubscriptions.map(
      (serviceSubscription: PhoneServiceSubscription) => serviceSubscription.phoneServiceSubscriptionName
    );

    this.formConfig = {
      fields: {
        subscriptionName: {
          label: 'tkey;shared.device.phone.phone_buttons.features.service_url_feature.service_subscription.label',
          dataAutomation: 'phone-button-service-url-subscription-name',
          componentConfig: new SmacsSelectConfig({ options: serviceSubscriptionNames }),
        },
        label: {
          label: 'tkey;shared.device.buttonLayout.features.serviceUrlFeature.label.label',
          dataAutomation: 'phone-button-service-url-label',
        },
      },
      options: {
        columnClasses: {
          label: 'col-3 text-lg-end',
          input: 'col-9',
        },
      },
    } as SmacsFormConfig;
  }

  submit() {
    return this.parentSubmit$;
  }
}
