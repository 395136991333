import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DeviceUtilization, JobStatus, UnusedDevicesReport } from '../../../shared/models/generated/smacsModels';
import { FileDownloadService } from '../../../shared/services/file-download.service';

@Injectable()
export class DeviceUtilizationResource {
  constructor(private http: HttpClient, private fileDownloadService: FileDownloadService) {}

  get(): Observable<DeviceUtilization[]> {
    return this.http.get<DeviceUtilization[]>(`/services/cisco/reporting/utilizations`);
  }

  refresh(): Observable<void> {
    return this.http.post<void>(`/services/cisco/reporting/utilizations/refresh`, null);
  }

  getRefreshStatus(): Observable<JobStatus> {
    return this.http.get<JobStatus>(`/services/cisco/reporting/utilizations/refresh/status`);
  }

  getUnusedDevicesReport(): Observable<UnusedDevicesReport> {
    return this.http.get<UnusedDevicesReport>(`/services/cisco/reporting/utilizations/unused-devices`);
  }

  export(): Observable<void> {
    return this.fileDownloadService.downloadFile('/services/cisco/reporting/utilizations/export');
  }
}
