import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { HtmlInputType, SmacsTextComponent, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { DubberSettings } from '../../../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { DubberSettingsContext } from '../../../../shared/contexts/dubber-settings.context';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';

@Component({
  selector: 'ziro-dubber-account-management',
  templateUrl: './dubber-account-management.component.html',
  styleUrls: ['../../../admin-page.scss'],
})
export class DubberAccountManagementComponent
  extends SmacsFormAbstractDirective<DubberSettings>
  implements OnInit, OnDestroy
{
  isLoading = true;
  smacsIcons = SmacsIcons;
  formConfig: SmacsFormConfig = {
    fields: {
      username: {
        label: 'tkey;admin.microsoft.dubber_management.username',
        dataAutomation: 'dubber-account-username',
        required: true,
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
      },
      password: {
        label: 'tkey;admin.microsoft.dubber_management.password',
        dataAutomation: 'dubber-account-password',
        required: () => !this.isExisting,
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
      },
      url: {
        label: 'tkey;admin.microsoft.dubber_management.url',
        dataAutomation: 'dubber-account-url',
        required: true,
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          htmlInputAddOn: { prependedContent: 'https://', appendedContent: '' },
        }),
      },
    },
  };

  private _subscriptions = new Subscription();

  private static _prefixUrl(url: string): string {
    return !url.startsWith('https://') ? 'https://'.concat(url) : url;
  }

  private static _formatUrl(url: string): string {
    return url?.startsWith('https://') ? url.replace(/^https?:\/\//, '') : url;
  }

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _bottomNavService: BottomNavService,
    private _toastService: ToastService,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _breadcrumbsService: BreadcrumbsService,
    private _dubberSettingsContext: DubberSettingsContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.microsoft.dubber_management.title' }]);
    this._initBottomNav();

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() =>
      this._bottomNavService.setBottomNavValidationError(!this.isFormValid())
    );
    this._subscriptions.add(formSubmittedSub);

    const ctxSub = this._dubberSettingsContext.state$.subscribe((settings: DubberSettings) => {
      if (settings !== null) {
        this.setIsExisting(this._dubberSettingsContext.isDubberConfigured(settings));
        this.entitySource.next({
          ...settings,
          url: DubberAccountManagementComponent._formatUrl(settings.url),
        });
        this._setDeleteDisabledState(!this.isExisting);
        this.isLoading = false;
      }
    });
    this._subscriptions.add(ctxSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  protected submit() {
    return this._onFormSubmit();
  }

  private _onFormSubmit(): Observable<void> {
    this._setSavePendingState(true);
    this._setDeleteDisabledState(true);
    this._setBottomNavValidationError(false);

    const formattedEntity = {
      ...this.entity,
      url: DubberAccountManagementComponent._prefixUrl(this.entity.url),
    };

    return this._dubberSettingsContext.testConnection(formattedEntity).pipe(
      switchMap(() => this._dubberSettingsContext.put(formattedEntity)),
      tap(() => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.DUBBER_ICON,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.microsoft.dubber_management.toast.save.message'
        );
        this._setSavePendingState(false);
        this._setDeleteDisabledState(false);
        this._resetFormSubmitted();
      }),
      catchError((response) => {
        this._setSavePendingState(false);
        this._setBottomNavValidationError(true);
        throw response;
      })
    );
  }

  private _initBottomNav() {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'dubber-management-delete-button',
          dataAutomation: 'dubber-management-delete-button',
          label: 'tkey;dialogs.button.delete',
          buttonClass: ButtonStyles.DANGER,
          state: {
            pending: false,
            buttonDisableState: {
              disabled: false,
              tooltipKey: '',
            },
          },
          icon: this.smacsIcons.DELETE,
          cb: () => this._onDeleteClick(),
        },
        {
          id: 'dubber-management-save-button',
          dataAutomation: 'dubber-management-save-button',
          label: 'tkey;admin.ui.save',
          buttonClass: ButtonStyles.PRIMARY,
          state: {
            pending: false,
            buttonDisableState: { disabled: false, tooltipKey: '' },
          },
          icon: this.smacsIcons.OK,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );
  }

  private _onDeleteClick() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        promptBody: this._translateService.instant('tkey;admin.microsoft.dubber_management.modal.delete.message'),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteDetails(),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteDetails(): Observable<void> {
    return this._dubberSettingsContext.delete().pipe(
      tap(() => {
        this._resetFormState();
        this._toastService.push(
          ToastTypes.INFO,
          `${this.smacsIcons.DELETE} text-danger`,
          'tkey;shared.toast.delete.success.title',
          'tkey;admin.microsoft.dubber_management.toast.delete.message'
        );
      })
    );
  }

  private _setSavePendingState(setting: boolean) {
    this._bottomNavService.setButtonPendingState('dubber-management-save-button', setting);
  }

  private _setDeleteDisabledState(state: boolean) {
    this._bottomNavService.setButtonDisabledState('dubber-management-delete-button', state);
  }

  private _setBottomNavValidationError(hasError: boolean) {
    this._bottomNavService.setBottomNavValidationError(hasError);
  }

  private _resetFormState() {
    this.setIsExisting(false);
    this.entitySource.next({
      username: '',
      password: '',
      url: '',
    });
    this._resetFormSubmitted();
    this._setDeleteDisabledState(true);
  }

  private _resetFormSubmitted() {
    this.smacsFormStateService.setIsFormDirty(false);
    this.isFormSubmitted = false;
    this.fieldComponents.forEach((item: SmacsTextComponent) => {
      item.isDirty = false;
      item.showValidation = false;
      item.isFormSubmitted = this.isFormSubmitted;

      if (item.fieldId === 'password') {
        item.showMaskedInput = false;
        item.applyComponentConfig(new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }));
      }
    });
  }
}
