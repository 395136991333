import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DirectoryReportUser, JobStatus } from '../models/generated/smacsModels';
import { FileDownloadService } from '../services/file-download.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalDirectoryResource {
  constructor(private http: HttpClient, private fileDownloadService: FileDownloadService) {}

  get(): Observable<DirectoryReportUser[]> {
    return this.http.get<DirectoryReportUser[]>('/services/cisco/reporting/global-directories');
  }

  getFile(): Observable<void> {
    return this.fileDownloadService.downloadFile('/services/cisco/reporting/global-directories/export');
  }

  getStatus(): Observable<JobStatus> {
    return this.http.get<JobStatus>('/services/cisco/reporting/global-directories/status');
  }

  refreshData(): Observable<void> {
    return this.http.post<void>('/services/cisco/reporting/global-directories/refresh', null);
  }
}
