<label
  [ngClass]="isPending ? 'btn-warning disabled' : buttonStyle"
  [attr.data-automation]="dataAutomation"
  class="btn m-0 d-block import-button"
  for="hiddenInput"
>
  <i [ngClass]="isPending ? iconPending : icon"></i>
  <span class="ms-2">{{ label | translate }}</span>
</label>
<input
  #hiddenInput
  [attr.data-automation]="dataAutomation + '-hidden-input'"
  [(ngModel)]="uploadedFile"
  [accept]="fileExtension"
  (change)="uploadFile($event.target['files'])"
  [disabled]="isDisabled || isPending"
  class="d-none"
  type="file"
  id="hiddenInput"
/>
