import { Component, Input, OnInit } from '@angular/core';
import { DatatableFilterAbstractDirective } from '../datatable-filter-abstract.directive';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { NEVER_USED_VALUE } from '../../datatable-filter.service';

@Component({
  selector: 'smacs-datatable-slider-filter',
  templateUrl: './datatable-slider-filter.component.html',
  styleUrls: ['./datatable-slider-filter.component.scss', '../datatable-filter-abstract.directive.scss'],
  providers: [{ provide: DatatableFilterAbstractDirective, useExisting: DatatableSliderFilterComponent }],
})
export class DatatableSliderFilterComponent extends DatatableFilterAbstractDirective<number> implements OnInit {
  @Input() ceilValue: number;
  @Input() floorValue = 0;
  @Input() floorValueLabel: string;
  sliderValue = 0;
  neverUsed: boolean;
  isDropDownOpen: boolean;
  disableDropDownOptions = true;
  options: Options;
  previousValue = 0;

  clear = () => {
    this.disableDropDownOptions = true;
    this.sliderValue = 0;
    this.neverUsed = false;
    this.value = null;
  };

  ngOnInit() {
    this.options = {
      floor: this.floorValue,
      ceil: this.ceilValue,
      step: this.ceilValue > 1461 ? this.ceilValue / 100 : null,
      showSelectionBar: true,
      keyboardSupport: true,
      animate: false,
    };
  }

  clearInput() {
    this.clear();
    this.filterUpdateSource.next();
  }

  updateSlider() {
    if (this.neverUsed) {
      this.previousValue = this.value;
      this.value = NEVER_USED_VALUE;
    } else {
      this.value = this.previousValue >= 0 ? this.previousValue : null;
      this.sliderValue = this.value ? this.value : 0;
    }
  }

  async onChange(changeContext: ChangeContext) {
    this.disableDropDownOptions = false;
    this.value = this.neverUsed ? NEVER_USED_VALUE : Number(changeContext.value);
  }

  getFilterLabel(): string {
    if (this.neverUsed) {
      return 'tkey;reporting.datatable.filter.slider.never';
    } else if (this.value === 0) {
      return 'tkey;time.today';
    } else if (this.value === 1) {
      return 'tkey;reporting.device_utilization.last_used.singular';
    } else if (!!this.value && (!this.neverUsed || this.isDropDownOpen)) {
      return 'tkey;reporting.datatable.filter.slider.number.of.days';
    }
    return 'tkey;reporting.datatable.filter.select.all';
  }

  openChange($event: boolean) {
    this.isDropDownOpen = $event;
    if (!this.isDropDownOpen) {
      this.filterUpdateSource.next();
    }
    if (this.ceilValue > 99) {
      const ngxSlider = document.querySelector('.ngx-slider-model-value') as HTMLSpanElement;
      ngxSlider.style.fontSize = '9px';
    }
  }
}
