import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../shared/services/toast.service';
import { ControlSettings } from '../../../shared/models/generated/smacsModels';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { tap } from 'rxjs/operators';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { DeviceSettingsResource } from '../../resources/device-settings.resource';
import { AuditSettingsContext } from '../../contexts/audit-settings.context';

@Component({
  selector: 'app-admin-device-utilization-report-settings',
  templateUrl: './device-utilization-report-settings.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class DeviceUtilizationReportSettingsComponent
  extends SmacsFormAbstractDirective<ControlSettings>
  implements OnInit, OnDestroy
{
  isLoading = true;
  smacsIcons = SmacsIcons;

  bottomNavButtons = [
    {
      id: 'saveDeviceUtilizationReportSettings',
      dataAutomation: 'save-device-utilization-report-settings',
      label: 'tkey;admin.ui.save',
      buttonClass: ButtonStyles.PRIMARY,
      state: {
        pending: false,
        buttonDisableState: {
          disabled: false,
          tooltipKey: '',
        },
      },
      icon: this.smacsIcons.OK,
      type: ButtonTypes.SUBMIT,
      submitSubject: this._validateAndSubmitSource,
    },
  ] as BottomNavButton[];

  CDR_FILE_PROCESSING_FREQUENCY_MIN_VALUE = 5;
  UTILIZATION_PROCESSING_FREQUENCY_MIN_VALUE = 60;
  FIELD_MIN_VALUE = 1;
  maxAuditAge: number;

  formConfig = {
    fields: {
      cdrFileProcessingFrequencyInMinutes: {
        dataAutomation: 'cucm-cdr-file-process-frequency',
        label: 'tkey;admin.device.utilization.settings.cucm_cdr_file_process_frequency.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        required: true,
        defaultValue: () => 30,
        options: {
          content: 'tkey;admin.device.utilization.settings.cucm_cdr_file_process_frequency.content',
        },
        validation: [
          {
            validator: (val: number) =>
              val >= this.FIELD_MIN_VALUE ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: this.translateService.instant('tkey;admin.audits.logs.audit_days_to_keep.positive.error.text'),
          },
          {
            validator: (val: number) =>
              val >= this.CDR_FILE_PROCESSING_FREQUENCY_MIN_VALUE
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: () =>
              this.translateService.instant('tkey;validators.global.error.min', {
                minValue: this.CDR_FILE_PROCESSING_FREQUENCY_MIN_VALUE,
              }),
          },
        ],
      },
      utilizationProcessingFrequencyInMinutes: {
        dataAutomation: 'device-utilization-refresh-frequency',
        label: 'tkey;admin.device.utilization.settings.device_utilization_refresh_frequency.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        required: true,
        options: {
          content: 'tkey;admin.device.utilization.settings.device_utilization_refresh_frequency.content',
        },
        defaultValue: () => 360,
        validation: [
          {
            validator: (val: number) =>
              val >= this.FIELD_MIN_VALUE ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: () =>
              this.translateService.instant('tkey;admin.audits.logs.audit_days_to_keep.positive.error.text'),
          },
          {
            validator: (val: number) =>
              val >= this.UTILIZATION_PROCESSING_FREQUENCY_MIN_VALUE
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: () =>
              this.translateService.instant('tkey;validators.global.error.min', {
                minValue: this.UTILIZATION_PROCESSING_FREQUENCY_MIN_VALUE,
              }),
          },
        ],
      },
      cdrRetentionInDays: {
        dataAutomation: 'cdr-call-retention',
        label: 'tkey;admin.device.utilization.settings.call_retention.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        required: true,
        options: {
          content: 'tkey;admin.device.utilization.settings.call_retention.content',
        },
        defaultValue: () => 90,
        validation: [
          {
            validator: (val: number) =>
              val >= this.FIELD_MIN_VALUE ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: () =>
              this.translateService.instant('tkey;admin.audits.logs.audit_days_to_keep.positive.error.text'),
          },
        ],
      },
      newDeviceMaxAgeInDays: {
        dataAutomation: 'new-device-max-age',
        label: 'tkey;admin.device.utilization.settings.new_device_max_age.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        required: true,
        options: {
          content: 'tkey;admin.device.utilization.settings.new_device_max_age.content',
        },
        defaultValue: () => 30,
        validation: [
          {
            validator: (val: number) =>
              val >= this.FIELD_MIN_VALUE ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: () =>
              this.translateService.instant('tkey;admin.audits.logs.audit_days_to_keep.positive.error.text'),
          },
          {
            validator: (val: number) =>
              val >= this.maxAuditAge ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID,
            message: () =>
              this.translateService.instant('tkey;admin.device.utilization.settings.new_device_max_age.validator', {
                maxAuditAge: this.maxAuditAge,
              }),
          },
        ],
      },
    },
  } as SmacsFormConfig;

  private _subscriptions = new Subscription();

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private toastService: ToastService,
    private adminDeviceSettingsResource: DeviceSettingsResource,
    private bottomNavService: BottomNavService,
    private translateService: TranslateService,
    private auditSettingsContext: AuditSettingsContext,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  returnZero = () => 0;

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.device.utilization.settings.header' }]);
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));

    const initSub = combineLatest([
      this.adminDeviceSettingsResource.getAdminDeviceSettings(),
      this.auditSettingsContext.state$,
    ]).subscribe(([reportSettings, auditSettings]) => {
      this.entitySource.next(reportSettings);
      this.maxAuditAge = auditSettings.daysToKeepAuditRecords;
      this.isLoading = false;
    });
    this._subscriptions.add(initSub);

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() =>
      this._setBottomNavValidationError(!this.isFormValid())
    );
    this._subscriptions.add(formSubmittedSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
    this.breadcrumbsService.clearBreadcrumbs();
  }

  protected submit() {
    return this._saveDeviceSettings();
  }

  private _setBottomNavValidationError(hasError: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: hasError,
      })
    );
  }

  private _saveDeviceSettings(): Observable<void> {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
      })
    );

    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'saveDeviceUtilizationReportSettings',
        state: {
          pending: true,
        },
      })
    );

    return this.adminDeviceSettingsResource.saveAdminDeviceSettings(this.formData).pipe(
      tap(() => {
        this.bottomNavService.dispatch(
          new BottomNavUpdateButtonState({
            id: 'saveDeviceUtilizationReportSettings',
            state: {
              pending: false,
            },
          })
        );
        this.toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.CONFIG,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.device.utilization.settings.toast.message'
        );
      })
    );
  }
}
