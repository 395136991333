import { ServiceTileType } from './service-tile-type.model';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ServiceProvisioningLevel } from '../../../shared/models/generated/smacsModels';

export enum CiscoTile {
  PRIMARY_EXT = 'primaryExtension',
  UCCX_AGENT = 'uccxAgent',
  PCCE_AGENT = 'pcceAgent',
  VOICEMAIL = 'voicemail',
  SNR = 'snr',
  DESKPHONE = 'deskphone',
  EXT_MOBILITY = 'extMobility',
  IMP = 'imPresence',
  IM_SOFTPHONE = 'imSoftphone',
  CIPC = 'cipc',
  ANDROID = 'android',
  IPHONE = 'iphone',
  TABLET = 'tablet',
  CTI_PORT = 'ctiPort',
  CTI_REMOTE_DEVICE = 'ctiRemoteDevice',
  SPARK_REMOTE_DEVICE = 'sparkRemoteDevice',
  WEBEX_CALLING = 'webexCalling',
}

export interface CiscoTileState {
  dataAutomation: string;
  serviceType: CiscoTile; // matches Tiles enum values, used for dynamic look up
  tileType: ServiceTileType;
  serviceName: string;
  icon: SmacsIcons;
  description: string;
  isAdding: boolean;
  isDeleting: boolean;
  isDisplayed: boolean;
  isEnabled: boolean;
  provisioningLevel: ServiceProvisioningLevel;
  service: any;
  tooltip?: string;
  isExchangeEnabled?: boolean;
  isExchangeShown?: boolean;
  isDeleted?: boolean;
}
