import { Directive, OnDestroy, OnInit } from '@angular/core';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { DetailedZiroCallingReportsContext } from './services/detailed-ziro-calling-reports.context';
import { JobState, JobStatus } from '../../../shared/models/generated/smacsModels';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { Observable, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { BillingProductsPollingService } from './services/billing-products-polling.service';
import { DateAgoPipe } from '../../../shared/pipes/date-ago.pipe';

export type ProductType =
  | 'zpm_subscription'
  | 'ziro_draas'
  | 'ziro_draas_extension'
  | 'ziro_spare_did'
  | 'ziro_draas_byoc'
  | 'ziro_pstn_connectivity_other'
  | 'ziro_draas_shared_calling_did';

@Directive({
  selector: '[smacsBillingProductsAbstract]',
})
export abstract class BillingProductsAbstractDirective implements OnInit, OnDestroy {
  isLoading = true;
  lastUpdated: string;

  constructor(
    protected breadcrumbsService: BreadcrumbsService,
    protected bottomNavService: BottomNavService,
    protected detailedZiroCallingReportsContext: DetailedZiroCallingReportsContext,
    protected billingProductsPollingService: BillingProductsPollingService,
    protected toastService: ToastService,
    protected translateService: TranslateService,
    protected dateAgoPipe: DateAgoPipe
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;admin.billing.products.title',
      },
    ]);

    this._initBottomNav();
    this._waitForIdle(true)
      .pipe(
        switchMap((jobStatus: JobStatus) => {
          if (jobStatus.error) {
            return of(null);
          }

          if (!this.lastUpdated) {
            this._setButtonPending('billing-refresh', true);
            return this._refreshReport();
          } else {
            this._updateBottomNavTimestamp();
            return this.detailedZiroCallingReportsContext.initReport();
          }
        })
      )
      .subscribe(() => {
        this.isLoading = false;
        this._setButtonDisabled('billing-refresh', false);
        this._setButtonPending('billing-refresh', false);
        this._setButtonDisabled('billing-export', false);
      });
  }

  ngOnDestroy() {
    this.billingProductsPollingService.stopPolling();
  }

  onRefreshClicked() {
    this.isLoading = true;
    this._setButtonPending('billing-refresh', true);
    this._setButtonDisabled('billing-export', true);
    this._refreshReport().subscribe(() => {
      this._setButtonDisabled('billing-export', false);
      this._setButtonPending('billing-refresh', false);
      this.toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.REFRESH,
        'tkey;admin.billing.products.refresh.toast.title',
        'tkey;admin.billing.products.refresh.toast.message'
      );
      this.isLoading = false;
    });
  }

  private _initBottomNav() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'billing-refresh',
          buttonClass: ButtonStyles.DEFAULT,
          icon: SmacsIcons.REFRESH,
          label: 'tkey;admin.billing.products.refresh.label',
          dataAutomation: 'billing-refresh-button',
          cb: () => this.onRefreshClicked(),
          state: {
            buttonDisableState: {
              disabled: true,
            },
          },
        },
        {
          id: 'billing-export',
          buttonClass: ButtonStyles.PRIMARY,
          icon: SmacsIcons.EXPORT,
          label: 'tkey;admin.billing.products.export.label',
          dataAutomation: 'billing-export-button',
          cb: () => this._onExportClicked(),
          state: {
            buttonDisableState: {
              disabled: true,
            },
          },
        },
      ])
    );
  }

  private _refreshReport(): Observable<void> {
    return this.detailedZiroCallingReportsContext.refreshReport().pipe(
      switchMap(() => this._waitForIdle()),
      tap(() => this._updateBottomNavTimestamp()),
      switchMap((jobStatus: JobStatus) => {
        if (this._isJobIdle(jobStatus)) {
          this.billingProductsPollingService.stopPolling();
        }

        if (jobStatus.error) {
          this.isLoading = false;
          this._setButtonDisabled('billing-refresh', false);
          this._setButtonPending('billing-refresh', false);
          this._setButtonDisabled('billing-export', false);
          return of();
        }
        return this.detailedZiroCallingReportsContext.initReport();
      })
    );
  }

  private _waitForIdle(setRefreshButtonToPending = false): Observable<JobStatus> {
    this.billingProductsPollingService.startPolling();
    // we have to create a new observable because stopPolling clears the state and breaks the emission chain
    return new Observable<JobStatus>((subscriber) => {
      this.billingProductsPollingService.state$.subscribe({
        next: (jobStatus: JobStatus) => {
          if (jobStatus.error) {
            this.lastUpdated = jobStatus.lastRunTime;
            subscriber.next(jobStatus);
            subscriber.complete();
          } else if (this._isJobIdle(jobStatus)) {
            this.lastUpdated = jobStatus.lastRunTime;
            subscriber.next(jobStatus);
            subscriber.complete();
          } else if (setRefreshButtonToPending) {
            setRefreshButtonToPending = false;
            this._setButtonPending('billing-refresh', true);
          }
        },
        error: (err) => {
          subscriber.error(err);
          subscriber.complete();
        },
      });
    });
  }

  private _isJobIdle(status: JobStatus): boolean {
    if (status.jobState === JobState.RUNNING || status.jobState === JobState.QUEUED) {
      return false;
    } else if (status.error) {
      return true;
    } else {
      return true;
    }
  }

  private _updateBottomNavTimestamp() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
        helpText: `
              <i class="${SmacsIcons.CLOCK}"></i>
              ${this.translateService.instant(
                'tkey;reporting.device_utilization.bottom_nav.helptext'
              )} <strong>${this.dateAgoPipe.transform(this.lastUpdated)}</strong>
            `,
      })
    );
  }

  private _onExportClicked() {
    this._setButtonPending('billing-export', true);
    this._setButtonDisabled('billing-refresh', true);
    this.detailedZiroCallingReportsContext.export().subscribe(() => {
      this._setButtonDisabled('billing-refresh', false);
      this._setButtonPending('billing-export', false);
      this.toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.EXPORT,
        'tkey;admin.billing.products.export.toast.title',
        'tkey;admin.billing.products.export.toast.message'
      );
    });
  }

  private _setButtonPending(buttonId: 'billing-refresh' | 'billing-export', isPending: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: {
          pending: isPending,
        },
      })
    );
  }

  private _setButtonDisabled(buttonId: 'billing-refresh' | 'billing-export', isDisabled: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: { buttonDisableState: { disabled: isDisabled, tooltipKey: '' } },
      })
    );
  }
}
