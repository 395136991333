<div
  class="microsoft-search-result__display-name d-flex align-items-center gap-1"
  data-automation="microsoft-search-result-display-name"
>
  <div class="result-image round-image-table" data-automation="microsoft-search-result-photo">
    <img *ngIf="photo" [src]="'data:image/png;base64,' + photo" data-automation="microsoft-search-result-image" />
    <i *ngIf="!photo" class="fa fa-2x icon-user-circle" data-automation="microsoft-search-result-icon"></i>
  </div>
  <span [disableTooltip]="result.displayName.length <= charLimitDN" [ngbTooltip]="result.displayName">
    <strong>{{ result.displayName | trimWithEllipsisFilter : charLimitDN }}</strong>
  </span>
</div>
<div
  class="microsoft-search-result__upn d-flex align-items-center text-truncate"
  data-automation="microsoft-search-result-upn"
>
  <span [disableTooltip]="result.id.length <= charLimitUPN" [ngbTooltip]="result.id">
    {{ result.id | trimWithEllipsisFilter : charLimitUPN }}
  </span>
</div>
<div
  class="microsoft-search-result__assigned-number d-flex align-items-center"
  data-automation="microsoft-search-result-assigned-number"
>
  <span *ngIf="!isLoadingLineUri; else skeletonLoader">{{ formatLineUri(lineUri, lineUriExtension) }}</span>
  <ng-template #skeletonLoader>
    <ngx-skeleton-loader
      class="skeleton-loader d-flex align-items-center"
      count="1"
      appearance="line"
    ></ngx-skeleton-loader>
  </ng-template>
</div>
<div class="microsoft-search-result__button d-flex align-items-center">
  <smacs-button
    [buttonSize]="ButtonSizes.SMALL"
    [dataAutomation]="'microsoft-search-result-button'"
    [icon]="smacsIcons.EDIT"
    [isDisabled]="isGuestAccount"
    [isTooltipDisabled]="!isGuestAccount"
    [tooltip]="'tkey;search.ms_users.guest.disabled.tooltip'"
  ></smacs-button>
</div>
