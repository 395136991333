import { Component, Input, OnInit } from '@angular/core';
import { SmacsFieldAbstractDirective } from '../../../forms/smacs-field-abstract.directive';
import { SmacsFieldComponentConfig } from '../../../forms/smacs-forms-models';
import {
  DialPlanGroupEntry,
  DialPlanGroupEntryStatus,
  DialPlanGroupEntryType,
} from '../../../shared/models/generated/smacsModels';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { TranslateService } from '@ngx-translate/core';
import { DateTimeService } from '../../../shared/services/date-time.service';

export class SmacsExtensionSelectorConfig extends SmacsFieldComponentConfig {
  constructor(
    public config: {
      options: DialPlanGroupEntry[];
      size: ExtensionSelectorSize;
      showRecentlyDeleted?: boolean;
      showUnavailable?: boolean;
      showInactive?: boolean;
    }
  ) {
    super();
  }
}

export enum ExtensionSelectorSize {
  SMALL = 'SMALL',
  LARGE = 'LARGE',
}

export interface ExtensionSelectorOption extends DialPlanGroupEntry {
  tooltip: ExtensionSelectorOptionTooltip;
  isDisabled?: boolean;
}

export interface ExtensionSelectorOptionTooltip {
  message: string;
  params: ExtensionSelectorOptionTooltipParams;
}

interface ExtensionSelectorOptionTooltipParams {
  environmentType?: string;
  extensionDescription?: string;
  deletionDate?: string;
  exceptionGroupName?: string;
  usageTypes?: string;
}

@Component({
  selector: 'smacs-extension-selector',
  templateUrl: './extension-selector.component.html',
  styleUrls: ['./extension-selector.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: ExtensionSelectorComponent }],
})
export class ExtensionSelectorComponent
  extends SmacsFieldAbstractDirective<string, string, SmacsExtensionSelectorConfig>
  implements OnInit
{
  @Input() isSubmitting: boolean;
  @Input() isHosted = false;

  PAGE_SIZE = 48;
  ENVIRONMENT_TYPE_TOOLTIP_MAP: Map<DialPlanGroupEntryType, string> = new Map([
    [
      DialPlanGroupEntryType.CUCM,
      this.translateService.instant('tkey;extension_selector.option.tooltip.environment_type.cucm'),
    ],
    [
      DialPlanGroupEntryType.TEAMS,
      this.translateService.instant('tkey;extension_selector.option.tooltip.environment_type.teams'),
    ],
    [
      DialPlanGroupEntryType.WEBEX,
      this.translateService.instant('tkey;extension_selector.option.tooltip.environment_type.webex'),
    ],
  ]);

  DialPlanGroupEntryStatus = DialPlanGroupEntryStatus;
  ExtensionSelectorSize = ExtensionSelectorSize;

  // from component config
  configOptions: DialPlanGroupEntry[];
  options: ExtensionSelectorOption[] = [];
  size: ExtensionSelectorSize;
  showRecentlyDeleted?: boolean;
  showUnavailable?: boolean;
  showInactive?: boolean;

  initialValue: string;
  filteredOptions: ExtensionSelectorOption[] = [];
  displayedFilteredOptions: ExtensionSelectorOption[] = [];
  isNoNumbersRemaining = false;
  page = 1;
  filter = '';

  protected requiredFeedbackMessage = 'tkey;dndidselection.dndid.error.select.extension.text';
  protected validationInProgressMessage = '';

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private translateService: TranslateService,
    private dateTimeService: DateTimeService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.requiredFeedbackMessage = this.isHosted
      ? 'tkey;dndidselection.dndid.error.select.number.text'
      : 'tkey;dndidselection.dndid.error.select.extension.text';
  }

  applyComponentConfig = ({ config }: SmacsExtensionSelectorConfig) => {
    this.size = config.size;
    this.showRecentlyDeleted = !!config.showRecentlyDeleted;
    this.showUnavailable = !!config.showUnavailable;
    this.showInactive = !!config.showInactive;

    this.initialValue = this.initialValue ?? this.fieldData;

    let isFiltered = false;
    if (config.options !== this.configOptions) {
      this.configOptions = config.options;
      this._setOptions(config.options);
      this.page = 1;

      if (this.initialValue) {
        const initialDialPlanEntry = this.options.find((option) => option.value === this.initialValue);
        if (initialDialPlanEntry) {
          initialDialPlanEntry.assignmentStatus = DialPlanGroupEntryStatus.UNASSIGNED;
          this._setFilteredOptions(this.filter);
          isFiltered = true;
          const optionNumber = this.filteredOptions.indexOf(initialDialPlanEntry) + 1;
          this.page = Math.ceil(optionNumber / this.PAGE_SIZE);
        }
      }
    }

    if (!isFiltered) {
      this._setFilteredOptions(this.filter);
    }

    this._setPaginatedFilteredOptions();
  };

  onFilterChanged($event: string) {
    this.filter = $event;
    this._setFilteredOptions(this.filter);
    this.page = 1;
    this._setPaginatedFilteredOptions();
  }

  onOptionSelected($event: string) {
    if (this.fieldData === $event) {
      this.updateSelf(null);
    } else {
      this.updateSelf($event);
    }
  }

  onPageChange() {
    this._setPaginatedFilteredOptions();
  }

  private _setOptions(dialPlanEntries: DialPlanGroupEntry[] | ExtensionSelectorOption[]) {
    this.options = dialPlanEntries.map((entry): ExtensionSelectorOption => {
      if ('tooltip' in entry) return entry;
      let tooltip: ExtensionSelectorOptionTooltip = null;
      switch (entry.assignmentStatus) {
        case DialPlanGroupEntryStatus.ASSIGNED:
          tooltip = {
            message: 'tkey;extension_selector.option.tooltip.assigned',
            params: {
              environmentType: this.ENVIRONMENT_TYPE_TOOLTIP_MAP.get(entry.pbxType),
              extensionDescription: entry.description,
            },
          };
          break;
        case DialPlanGroupEntryStatus.RESTRICTED:
          tooltip = {
            message: 'tkey;extension_selector.option.tooltip.restricted',
            params: {
              environmentType: this.ENVIRONMENT_TYPE_TOOLTIP_MAP.get(entry.pbxType),
              exceptionGroupName: entry.exceptionGroupName,
            },
          };
          break;
        case DialPlanGroupEntryStatus.RECENTLY_DELETED:
          tooltip = {
            message: 'tkey;extension_selector.option.tooltip.recently_deleted',
            params: {
              environmentType: this.ENVIRONMENT_TYPE_TOOLTIP_MAP.get(entry.pbxType),
              extensionDescription: entry.description,
              deletionDate: this.dateTimeService.parse(entry.deletionDate).format('MMM DD YYYY, hh:mm:ss a'),
            },
          };
          break;
        case DialPlanGroupEntryStatus.INACTIVE:
          tooltip = {
            message: entry.description,
            params: {
              environmentType: this.ENVIRONMENT_TYPE_TOOLTIP_MAP.get(entry.pbxType),
            },
          };
          break;
        case DialPlanGroupEntryStatus.TEAMS_UNASSIGNED_NUMBER_ROUTING_RULE:
          tooltip = {
            message: 'tkey;extension_selector.option.tooltip.teams_unassigned_number_routing_rule',
            params: {
              extensionDescription: entry.description,
            },
          };
          break;
      }
      return { ...entry, tooltip } as ExtensionSelectorOption;
    });

    this.isNoNumbersRemaining = this.options.every(
      (option) =>
        option.assignmentStatus === DialPlanGroupEntryStatus.ASSIGNED ||
        option.assignmentStatus === DialPlanGroupEntryStatus.RESTRICTED
    );
  }

  private _setFilteredOptions(filter: string) {
    const filterValue = filter?.trim()?.toLowerCase();
    const filterValueStripped = filterValue?.replace(/[^0-9A-Za-z]/g, '');
    this.filteredOptions = this.options
      .filter((dialPlanEntry) => {
        switch (dialPlanEntry.assignmentStatus) {
          case DialPlanGroupEntryStatus.RECENTLY_DELETED:
            return this.showRecentlyDeleted;
          case DialPlanGroupEntryStatus.INACTIVE:
            return this.showInactive;
          case DialPlanGroupEntryStatus.ASSIGNED:
          case DialPlanGroupEntryStatus.RESTRICTED:
          case DialPlanGroupEntryStatus.TEAMS_UNASSIGNED_NUMBER_ROUTING_RULE:
            return this.showUnavailable;
          case DialPlanGroupEntryStatus.UNASSIGNED:
          default:
            return true;
        }
      })
      .filter((dialPlanEntry) => {
        return (
          !filterValue ||
          dialPlanEntry.value.includes(filterValue) ||
          dialPlanEntry.value.includes(filterValueStripped) ||
          dialPlanEntry.description?.toLowerCase().includes(filterValue)
        );
      });
  }

  private _setPaginatedFilteredOptions() {
    this.displayedFilteredOptions = this.filteredOptions.slice(
      (this.page - 1) * this.PAGE_SIZE,
      this.page * this.PAGE_SIZE
    );
  }
}
