<div
  [hidden]="state.hidden && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
  class="form-group mb-3 row"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted,
    'smacs-forms-feedback': showValidation,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage,
  }"
>
  <ziro-checkbox-button
    [noTextWrap]="noTextWrap"
    [disabled]="disabled"
    [label]="getLabel()"
    [icon]="icon"
    [tooltip]="config.labelToolTipText || disabledTooltip"
    [buttonSize]="size"
    [dataAutomation]="config.dataAutomation"
    [value]="entity"
    (changed)="onChange($event)"
  ></ziro-checkbox-button>
</div>
