import { invert } from 'lodash';
import { Phone, PhoneSummary } from '../models/generated/smacsModels';
import { Injectable } from '@angular/core';

const wellKnownServiceModels = {
  cipc: 'Cisco IP Communicator',
  'im-softphone': 'Cisco Unified Client Services Framework',
  iphone: 'Cisco Dual Mode for iPhone',
  android: 'Cisco Dual Mode for Android',
  tablet: 'Cisco Jabber for Tablet',
  snr: 'Remote Destination Profile',
  'cti-port': 'CTI Port',
  'cti-remote-device': 'CTI Remote Device',
  deskphone: null as string,
};

const wellKnownServiceModelsInverted = invert(wellKnownServiceModels);

@Injectable()
export class ServiceTypeDeterminationService {
  public static getModelName(model: keyof typeof wellKnownServiceModels) {
    return wellKnownServiceModels[model];
  }

  public isModelOfType(refModel: string, phone: PhoneSummary | Phone): boolean;
  public isModelOfType(refModel: string, model: string): boolean;
  public isModelOfType(
    refModel: keyof typeof wellKnownServiceModels,
    phoneModel: string | PhoneSummary | Phone
  ): boolean {
    const model = typeof phoneModel === 'string' ? phoneModel : phoneModel.model;

    return refModel === 'deskphone'
      ? !wellKnownServiceModelsInverted[model]
      : wellKnownServiceModels[refModel] === model;
  }
}
