import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  TeamsPhoneNumberAssignment,
  TeamsPhoneNumberAssignmentRef,
} from '../../../shared/models/generated/smacsModels';
import { Nvp } from '../../../shared/models/nvp';
import { AuditHeaderService } from '../../../shared/services/audit-header.service';

@Injectable()
export class MicrosoftPhoneNumberAssignmentsResource {
  private _baseUrl = '/services/microsoft/macs/teams-phone-number-assignment';
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  get(upn: string): Observable<TeamsPhoneNumberAssignment> {
    return this.http.get<TeamsPhoneNumberAssignment>(`${this._baseUrl}/${encodeURIComponent(upn)}`);
  }

  post(upn: string, body: TeamsPhoneNumberAssignment): Observable<TeamsPhoneNumberAssignmentRef> {
    return this.http.post<TeamsPhoneNumberAssignmentRef>(`${this._baseUrl}/${encodeURIComponent(upn)}`, body);
  }

  put(upn: string, body: TeamsPhoneNumberAssignment): Observable<void> {
    return this.http.put<void>(`${this._baseUrl}/${encodeURIComponent(upn)}`, body);
  }

  delete(upn: string, auditTags?: Nvp[]): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.delete<void>(`${this._baseUrl}/${encodeURIComponent(upn)}`, httpOptions);
  }

  private _buildHttpOptions(auditTags: Nvp[]): { headers?: HttpHeaders } {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }
}
