import { Injectable } from '@angular/core';
import { DnDidRange } from '../models/generated/smacsModels';
import { groupBy, isEmpty, sortBy, uniq } from 'lodash';

@Injectable()
export class RangeService {
  static generateRangesFromArray(bulkRangeValue: string[]): DnDidRange[] {
    const groupByElements = groupBy(uniq(bulkRangeValue), function (value) {
      if (value.startsWith('+')) {
        return 'plusDns';
      } else {
        return 'nonPlusDns';
      }
    });

    const plusRanges = RangeService._generateRangesOnSimilarTypeValues(groupByElements.plusDns);
    const ranges = RangeService._generateRangesOnSimilarTypeValues(groupByElements.nonPlusDns);
    if (plusRanges.length > 0 && ranges.length > 0) {
      return plusRanges.concat(ranges);
    } else if (plusRanges.length > 0) {
      return plusRanges;
    } else if (ranges.length > 0) {
      return ranges;
    }
  }

  private static _generateRangesOnSimilarTypeValues(dnDidValues: string[]): DnDidRange[] {
    if (!isEmpty(dnDidValues)) {
      const dnDidRanges = [];
      let sortedDns = RangeService._sortValues(dnDidValues);

      sortedDns.forEach((value: string) => {
        if (value.indexOf('-') !== -1) {
          const start = value.split('-')[0];
          const end = value.split('-')[1];
          dnDidRanges.push(RangeService._getRangeObject(start, end));
        }
      });

      sortedDns = sortedDns.filter((value) => value.indexOf('-') === -1);

      if (sortedDns.length > 1) {
        let start = sortedDns[0];
        let end = sortedDns[0];
        for (let i = 1; i < sortedDns.length; i++) {
          if (RangeService._areConsecutiveValuesOfSameLength(sortedDns[i - 1], sortedDns[i])) {
            end = sortedDns[i];
          } else {
            dnDidRanges.push(RangeService._getRangeObject(start, end));

            start = sortedDns[i];
            end = sortedDns[i];
          }
        }
        dnDidRanges.push(RangeService._getRangeObject(start, end));
      } else if (sortedDns.length === 1) {
        const start = sortedDns[0];
        const end = sortedDns[0];
        dnDidRanges.push(RangeService._getRangeObject(start, end));
      }
      return dnDidRanges;
    } else {
      return [];
    }
  }

  private static _getRangeObject(start: string, end: string): DnDidRange {
    return {
      start: start,
      end: end,
    };
  }

  private static _areConsecutiveValuesOfSameLength(firstDn: string, secondDn: string): boolean {
    return parseInt(firstDn) + 1 === parseInt(secondDn) && firstDn.length === secondDn.length;
  }

  private static _sortValues(dnDidStrings: string[]): string[] {
    return sortBy(dnDidStrings, function (dn) {
      return parseInt(dn);
    });
  }
}
