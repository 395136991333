import { Injectable } from '@angular/core';
import { SearchAbstractResource } from './search.abstract.resource';
import { DialPlanManagementGroupSearchResult } from '../../../shared/models/generated/smacsModels';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export class SearchDialPlanQueryParams {
  q?: string;
  name?: string;
  extension?: string;
}

@Injectable()
export class SearchDialPlanResource extends SearchAbstractResource {
  constructor(protected http: HttpClient) {
    super(http);
  }

  get(params: SearchDialPlanQueryParams): Observable<DialPlanManagementGroupSearchResult[]> {
    return this.search<DialPlanManagementGroupSearchResult[]>('/services/cisco/admin/dial-plan/groups/search', params);
  }
}
