import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { EmailSettings, HealthStatusSettings } from '../../../shared/models/generated/smacsModels';
import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { EmailSettingsContext } from '../../../shared/contexts/email-settings.context';
import { SmacsMultiCheckboxConfig } from '../../../forms/fields/multi-checkbox/smacs-multi-checkbox.component';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { TranslateService } from '@ngx-translate/core';
import { SystemHealthStatusSettingsResource } from '../../resources/system-health-status-settings.resource';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { cloneDeep, isEqual } from 'lodash';
import { SmacsMultiTextConfig } from '../../../forms/fields/multi-text/smacs-multi-text.component';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../shared/services/toast.service';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { tap } from 'rxjs/operators';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  SmacsCheckboxConfig,
} from '../../../forms/fields/checkbox/smacs-checkbox.component';
import { BreadcrumbsComponent } from '../../../shared/breadcrumbs/breadcrumbs.component';

interface HealthSettingsNotificationsSettingsFormData {
  automaticDisableOfEndpoints: boolean;
  healthCheckIntervalInMinutes: number;
  notificationsTrigger: {
    healthStatusWarningDetected: boolean;
    healthStatusErrorDetected: boolean;
  };
  failureThresholdForWarnings: number;
  repeatNotificationWarnings: number;
  failureThresholdForErrors: number;
  repeatNotificationErrors: number;
  to: string[];
}

@Component({
  selector: 'smacs-admin-system-health-settings-notifications',
  templateUrl: './system-health-settings-notifications.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [SystemHealthStatusSettingsResource],
})
export class SystemHealthSettingsNotificationsComponent
  extends SmacsFormAbstractDirective<HealthSettingsNotificationsSettingsFormData>
  implements OnInit, OnDestroy
{
  @ViewChild(BreadcrumbsComponent) breadcrumbsComponent: BreadcrumbsComponent;

  smacsIcons = SmacsIcons;
  isLoading = true;
  formConfig: SmacsFormConfig;

  private _emailSettings: EmailSettings;
  private _subscriptions = new Subscription();
  private _validators = {
    toEmailValidator: (values: string[]): SmacsFormsValidationState => {
      return values.every((value: string) => {
        return (
          !value ||
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value
          )
        );
      })
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID;
    },
    minValueValidator: (value: number, isEnabled: boolean, minValue: number): SmacsFormsValidationState => {
      return isEnabled && !isNaN(value) && Number(value) < minValue
        ? SmacsFormsValidationState.INVALID
        : SmacsFormsValidationState.VALID;
    },
  };

  constructor(
    private bottomNavService: BottomNavService,
    private breadcrumbsService: BreadcrumbsService,
    private emailSettingsContext: EmailSettingsContext,
    private translateService: TranslateService,
    private systemHealthStatusNotificationsResource: SystemHealthStatusSettingsResource,
    protected smacsFormStateService: SmacsFormStateService,
    private toastService: ToastService
  ) {
    super(smacsFormStateService);
  }

  @HostListener('document:click', ['$event']) onAboutCardLinkClick($event: any) {
    if (!$event.target.matches('.breadcrumb-scroll-to-expand')) return;
    $event.preventDefault();
    this.breadcrumbsComponent.scrollToAndExpand();
  }

  ngOnInit() {
    this._initFormConfig();
    this._initBottomNav();
    this._initBreadcrumbs();

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() =>
      this._setBottomNavValidationError(!this.isFormValid())
    );
    this._subscriptions.add(formSubmittedSub);

    const contextSub = combineLatest([
      this.emailSettingsContext.state$,
      this.systemHealthStatusNotificationsResource.get(),
    ]).subscribe(([data, healthStatusNotification]) => {
      this._emailSettings = data;
      this._setBottomNavDisabled(!this._emailSettings.host || !this._emailSettings.from);
      this.entitySource.next(this._entityToFormData(healthStatusNotification));
      this.isLoading = false;
    });
    this._subscriptions.add(contextSub);

    const updateSub = this.smacsFormsUpdate$.subscribe(
      (update: SmacsFormsUpdate<HealthSettingsNotificationsSettingsFormData>) => {
        if (
          (update.new.notificationsTrigger.healthStatusWarningDetected === true &&
            update.old?.notificationsTrigger.healthStatusWarningDetected === false) ||
          (update.new.notificationsTrigger.healthStatusErrorDetected === true &&
            update.old?.notificationsTrigger.healthStatusErrorDetected === false)
        ) {
          let entityCopy = cloneDeep(update.new);

          if (!update.new.failureThresholdForWarnings) {
            entityCopy = {
              ...entityCopy,
              failureThresholdForWarnings: 3,
            };
          }

          if (update.new.repeatNotificationWarnings === null) {
            entityCopy = {
              ...entityCopy,
              repeatNotificationWarnings: 30,
            };
          }

          if (!update.new.failureThresholdForErrors) {
            entityCopy = {
              ...entityCopy,
              failureThresholdForErrors: 3,
            };
          }

          if (update.new.repeatNotificationErrors === null) {
            entityCopy = {
              ...entityCopy,
              repeatNotificationErrors: 30,
            };
          }

          if (!isEqual(update.new, entityCopy)) {
            this.entitySource.next(entityCopy);
          }
        }
      }
    );
    this._subscriptions.add(updateSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }

  hasEmailSettings(): boolean {
    return !!this._emailSettings?.host;
  }

  protected submit() {
    return this._onFormSubmit();
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        automaticDisableOfEndpoints: {
          label: 'tkey;admin.system_health_status_notifications.automatic_disable_of_endpoints.label',
          dataAutomation: 'system-health-settings-notifications-automatic-disable-of-endpoints',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
          options: {
            content: 'tkey;admin.system_health_status_notifications.automatic_disable_of_endpoints.helptext',
          },
        },
        healthCheckIntervalInMinutes: {
          label: 'tkey;admin.system_health_status_notifications.health_check_interval_in_minutes.label',
          dataAutomation: 'system-health-settings-notifications-health-check-interval-in-minutes',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
          required: () => true,
          validation: [
            {
              validator: (val: number) => {
                return val >= 3 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              },
              message: 'tkey;admin.system_health_status_notifications.health_check_interval_in_minutes.validation',
            },
          ],
          options: {
            content: 'tkey;admin.system_health_status_notifications.health_check_interval_in_minutes.helptext',
          },
        },
        notificationsTrigger: {
          label: 'tkey;admin.system_health_status_notifications.notifications_triggers.label',
          dataAutomation: 'system-health-settings-notifications-notifications-triggers',
          required: false,
          componentConfig: new SmacsMultiCheckboxConfig({
            multiCheckboxOptionConfig: [
              {
                label: 'tkey;admin.system_health_status_notifications.health_status_warning_detected.label',
                hidden: () => false,
                disabled: () => false,
              },
              {
                label: 'tkey;admin.system_health_status_notifications.health_status_error_detected.label',
                hidden: () => false,
                disabled: () => false,
              },
            ],
          }),
        },
        failureThresholdForWarnings: {
          label: 'tkey;admin.system_health_status_notifications.failure_threshold_warnings.label',
          dataAutomation: 'system-health-settings-notifications-failure-threshold-warnings',
          required: () => {
            return this.formData?.notificationsTrigger?.healthStatusWarningDetected;
          },
          hidden: () => {
            return !this.formData?.notificationsTrigger?.healthStatusWarningDetected;
          },
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.NUMBER,
            min: 1,
          }),
          helpText: () =>
            this.translateService.instant(
              'tkey;admin.system_health_status_notifications.failure_threshold_warnings.helptext',
              {
                value: this.formData.failureThresholdForWarnings * 3,
              }
            ),
          validation: [
            {
              validator: (value: number) => {
                return this._validators.minValueValidator(
                  value,
                  this.formData?.notificationsTrigger?.healthStatusWarningDetected,
                  1
                );
              },
              message: () =>
                this.translateService.instant('tkey;validators.global.error.min', {
                  minValue: 1,
                }),
            },
          ],
        },
        repeatNotificationWarnings: {
          label: 'tkey;admin.system_health_status_notifications.repeat_notifications_warnings.label',
          dataAutomation: 'system-health-settings-notifications-repeat-notifications-warnings',
          required: () => {
            return this.formData?.notificationsTrigger?.healthStatusWarningDetected;
          },
          hidden: () => {
            return !this.formData?.notificationsTrigger?.healthStatusWarningDetected;
          },
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.NUMBER,
            min: 0,
          }),
          helpText: () =>
            this.translateService.instant(
              'tkey;admin.system_health_status_notifications.repeat_notifications_warnings.helptext',
              {
                value: this.formData.repeatNotificationWarnings,
              }
            ),
          validation: [
            {
              validator: (value: number) => {
                return this._validators.minValueValidator(
                  value,
                  this.formData?.notificationsTrigger?.healthStatusWarningDetected,
                  0
                );
              },
              message: () =>
                this.translateService.instant('tkey;validators.global.error.min', {
                  minValue: 0,
                }),
            },
          ],
        },
        failureThresholdForErrors: {
          label: 'tkey;admin.system_health_status_notifications.features_threshold_errors.label',
          dataAutomation: 'system-health-settings-notifications-failure-threshold-errors',
          required: () => {
            return this.formData?.notificationsTrigger?.healthStatusErrorDetected;
          },
          hidden: () => {
            return !this.formData?.notificationsTrigger?.healthStatusErrorDetected;
          },
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.NUMBER,
            min: 1,
          }),
          helpText: () =>
            this.translateService.instant(
              'tkey;admin.system_health_status_notifications.features_threshold_errors.helptext',
              {
                value: this.formData.failureThresholdForErrors * 3,
              }
            ),
          validation: [
            {
              validator: (value: number) => {
                return this._validators.minValueValidator(
                  value,
                  this.formData?.notificationsTrigger?.healthStatusErrorDetected,
                  1
                );
              },
              message: () =>
                this.translateService.instant('tkey;validators.global.error.min', {
                  minValue: 1,
                }),
            },
          ],
        },
        repeatNotificationErrors: {
          label: 'tkey;admin.system_health_status_notifications.repeat_notifications_errors.label',
          dataAutomation: 'system-health-settings-notifications-repeat-notifications-errors',
          required: () => {
            return this.formData?.notificationsTrigger?.healthStatusErrorDetected;
          },
          hidden: () => {
            return !this.formData?.notificationsTrigger?.healthStatusErrorDetected;
          },
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.NUMBER,
            min: 0,
          }),
          helpText: () =>
            this.translateService.instant(
              'tkey;admin.system_health_status_notifications.repeat_notifications_errors.helptext',
              {
                value: this.formData.repeatNotificationErrors,
              }
            ),
          validation: [
            {
              validator: (value: number) => {
                return this._validators.minValueValidator(
                  value,
                  this.formData?.notificationsTrigger?.healthStatusErrorDetected,
                  0
                );
              },
              message: () =>
                this.translateService.instant('tkey;validators.global.error.min', {
                  minValue: 0,
                }),
            },
          ],
        },
        to: {
          label: 'tkey;admin.system_health_status_notifications.to.label',
          dataAutomation: 'system-health-settings-notifications-to',
          required: () => {
            return (
              this.formData?.notificationsTrigger?.healthStatusWarningDetected ||
              this.formData?.notificationsTrigger?.healthStatusErrorDetected
            );
          },
          componentConfig: new SmacsMultiTextConfig({ placeholder: 'ziroadministrators@yourcompany.com' }),
          helpText: () => 'tkey;admin.system_health_status_notifications.to.helptext',
          validation: [
            {
              validator: this._validators.toEmailValidator,
              message: 'tkey;validators.global.email.invalid.error',
            },
          ],
        },
      },
    };

    this.entitySource.next({
      automaticDisableOfEndpoints: false,
      notificationsTrigger: {
        healthStatusWarningDetected: false,
        healthStatusErrorDetected: false,
      },
      healthCheckIntervalInMinutes: 15,
      failureThresholdForWarnings: null,
      repeatNotificationWarnings: null,
      failureThresholdForErrors: null,
      repeatNotificationErrors: null,
      to: [],
    });
  }

  private _onFormSubmit(): Observable<void> {
    this._setBottomNavPending(true);
    this._setBottomNavValidationError(false);

    return this.systemHealthStatusNotificationsResource.put(this._formDataToEntity()).pipe(
      tap(() => {
        this._setBottomNavPending(false);

        this.toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.BELL,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.system_health_status_notifications.toast.success'
        );
      })
    );
  }

  private _entityToFormData(entity: HealthStatusSettings): HealthSettingsNotificationsSettingsFormData {
    return {
      automaticDisableOfEndpoints: entity.automaticDisableOfEndpoints,
      notificationsTrigger: {
        healthStatusWarningDetected: !!(
          entity.warningNotifications?.failureThreshold || entity.warningNotifications?.repeatNotificationInMinutes
        ),
        healthStatusErrorDetected: !!(
          entity.errorNotifications?.failureThreshold || entity.errorNotifications?.repeatNotificationInMinutes
        ),
      },
      healthCheckIntervalInMinutes: entity.healthCheckIntervalInMinutes,
      failureThresholdForWarnings: entity.warningNotifications?.failureThreshold,
      repeatNotificationWarnings: entity.warningNotifications?.repeatNotificationInMinutes,
      failureThresholdForErrors: entity.errorNotifications?.failureThreshold,
      repeatNotificationErrors: entity.errorNotifications?.repeatNotificationInMinutes,
      to: entity.emailRecipients && entity.emailRecipients[0] ? entity.emailRecipients : [],
    };
  }

  private _formDataToEntity(): HealthStatusSettings {
    return {
      automaticDisableOfEndpoints: this.formData.automaticDisableOfEndpoints,
      emailRecipients: this.formData.to,
      errorNotifications: this.formData.notificationsTrigger.healthStatusErrorDetected
        ? {
            failureThreshold: this.formData.failureThresholdForErrors,
            repeatNotificationInMinutes: this.formData.repeatNotificationErrors,
          }
        : null,
      healthCheckIntervalInMinutes: this.formData.healthCheckIntervalInMinutes,
      warningNotifications: this.formData.notificationsTrigger.healthStatusWarningDetected
        ? {
            failureThreshold: this.formData.failureThresholdForWarnings,
            repeatNotificationInMinutes: this.formData.repeatNotificationWarnings,
          }
        : null,
    };
  }

  private _setBottomNavValidationError(hasError: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: hasError,
      })
    );
  }

  private _setBottomNavPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'system-health-settings-notifications-save-button',
        state: {
          pending: setting,
          buttonDisableState: {
            disabled: setting,
            tooltipKey: '',
          },
        },
      })
    );
  }

  private _setBottomNavDisabled(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'system-health-settings-notifications-save-button',
        state: {
          pending: false,
          buttonDisableState: {
            disabled: setting,
            tooltipKey: '',
          },
        },
      })
    );
  }

  private _initBottomNav() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'system-health-settings-notifications-save-button',
          label: 'tkey;admin.ui.save',
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'system-health-settings-notifications-save-button',
          state: {
            pending: false,
            buttonDisableState: {
              disabled: false,
              tooltipKey: '',
            },
          },
          icon: this.smacsIcons.OK,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );
  }

  private _initBreadcrumbs() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.system_health_status_notifications.title' }]);
  }
}
