<div *ngIf="links.length" class="card card-default">
  <div class="card-header">
    <span class="font-light">{{ 'tkey;admin.site_management.site.site_configuration.label' | translate }}</span>
    -
    <strong class="text-info">{{ siteName }}</strong>
  </div>
  <ul class="nav nav-pills flex-column">
    <li
      *ngFor="let link of links; let i = index"
      class="nav-item nav-item-section"
      attr.data-automation="{{ link.dataAutomation }}-link"
      [ngClass]="{ active: link.dataAutomation === activeSection }"
    >
      <span class="nav-link nav-section-title" (click)="scrollToElement(link.dataAutomation)">
        <span data-automation="section-title">{{ link.label | translate }}</span>
        <span
          *ngIf="sectionEntities[link.key].enabled"
          class="fa fa-stack pull-right"
          data-automation="section-one-click-icon"
          [ngClass]="{
            'icon-default': !sectionEntities[link.key].oneClick,
            'icon-success': sectionEntities[link.key].oneClick
          }"
          [ngbTooltip]="
            sectionEntities[link.key].oneClick
              ? ('tkey;admin.site_management.one_click_provisioning_enabled.tooltip' | translate)
              : ('tkey;admin.site_management.one_click_provisioning_disabled.tooltip' | translate)
          "
          [container]="'body'"
        >
          <span class="icon-circle fa-stack-2x"></span>
          <i [attr.class]="SmacsIcons.ONE_CLICK + ' fa-inverse fa-stack-1x'"></i>
        </span>
      </span>

      <ul
        *ngIf="isSectionEnabled(link.key, sectionEntities[link.key].enabled)"
        attr.data-automation="{{ link.dataAutomation }}-sublinks"
        class="nav nav-pills flex-column"
      >
        <ziro-checkbox-button
          [dataAutomation]="link.key + '-show-button'"
          [hidden]="commonFields[link.key]?.commonFields.length === 0"
          [icon]="SmacsIcons.EYE"
          [label]="'Show Common Fields'"
          [buttonSize]="ButtonSizes.XSMALL"
          (changed)="onCommonFieldsToggle(link.key)"
          class="ms-2"
        ></ziro-checkbox-button>
        <li
          *ngFor="let sublink of link.links"
          class="nav-item position-relative nav-item-section-field"
          [hidden]="sublink.isCommon && !commonFields[link.key].areCommonFieldsVisible"
          [attr.data-automation]="sublink.dataAutomation + '-link-list-item'"
        >
          <i class="position-absolute icon-info" *ngIf="sublink.isCommon" [attr.class]="SmacsIcons.EYE"></i>
          <span
            class="nav-link py-0"
            [attr.data-automation]="sublink.dataAutomation + '-link'"
            (click)="scrollToElement(sublink.dataAutomation)"
          >
            {{ sublink.label | translate }}
          </span>
        </li>
      </ul>
    </li>
  </ul>
</div>
