import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'smacs-service-tile-add',
  templateUrl: './service-tile-add.component.html',
  styleUrls: ['./service-tile-add.component.scss'],
})
export class SmacsServiceTileAddComponent {
  @Input() isEnabled: boolean;
  @Input() tooltip: string;
  @Input() icon: string;
  @Input() serviceName: string;
  @Input() isAdding = false;
  @Input() isDeleted: boolean;
  @Input() isInProgress?: boolean;
  @Input() isInProgressBadgeText: string;

  @Output() addTileClicked = new EventEmitter();

  onTileClicked() {
    this.addTileClicked.emit();
  }
}
