import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';

import { MicrosoftOnPremAdResource } from '../../../../../../shared/resources/microsoft-on-prem-ad.resource';
import {
  AdAttributes,
  MicrosoftOnPremAdAttributesFieldConfig,
} from '../../../../../../../shared/models/generated/smacsModels';
import { SmacsFieldConfigs, SmacsFormConfig, SmacsFormsUpdate } from '../../../../../../../forms/smacs-forms-models';
import { HtmlInputType, SmacsTextConfig } from '../../../../../../../forms/fields/text/smacs-text.component';
import {
  HelpdeskAdAttributesEntity,
  HelpdeskAdAttributesFormComponent,
} from './helpdesk-ad-attributes-form/helpdesk-ad-attributes-form.component';
import { SmacsIcons } from '../../../../../../../shared/models/smacs-icons.enum';

@Component({
  selector: 'ziro-helpdesk-ad-attributes',
  templateUrl: './helpdesk-ad-attributes.component.html',
})
export class HelpdeskAdAttributesComponent implements OnInit {
  @ViewChild(HelpdeskAdAttributesFormComponent) adAttributeForm: HelpdeskAdAttributesFormComponent;
  @Output() adItemWasChanged = new EventEmitter<void>();
  @Input() isZpcSyncWarningPresent: boolean;
  @Input() onPremUpn: string;
  @Input() dialPlanId: number;
  @Input() mainNumber: string;
  // Depending on range type, selectedNumber could be DID or EXT
  @Input() selectedNumber: string;
  @Input() lineUriExtensionForDedicatedDid: string;

  initialEntity: HelpdeskAdAttributesEntity;
  isLoading = true;
  formConfig: SmacsFormConfig;
  attributeFields: string[] = [];
  smacsIcons = SmacsIcons;
  isAnyAttributeVisible = false;
  private _fieldConfig: MicrosoftOnPremAdAttributesFieldConfig;
  private _attributesId: string;
  private _existingAttributes: AdAttributes;

  constructor(private _microsoftOnPremAdResource: MicrosoftOnPremAdResource) {}

  ngOnInit(): void {
    this.init();
  }

  init(manuallyUpdatedExtensionValue?: string) {
    this.isLoading = true;
    if (!this.isZpcSyncWarningPresent) {
      forkJoin([
        this._microsoftOnPremAdResource.getOnPremAttributesFieldConfig(
          this.onPremUpn,
          this.mainNumber ? this.mainNumber : this.selectedNumber,
          !!this.lineUriExtensionForDedicatedDid
            ? manuallyUpdatedExtensionValue || this.lineUriExtensionForDedicatedDid
            : this.mainNumber
            ? this.selectedNumber
            : ''
        ),
        this._microsoftOnPremAdResource.getAdAttributes(this.onPremUpn, this.dialPlanId),
      ]).subscribe(([fieldConfig, existingAttributes]) => {
        if (!!existingAttributes) {
          this._fieldConfig = fieldConfig;
          this._existingAttributes = existingAttributes;
          this._attributesId = existingAttributes.userPrincipalName;
          this.attributeFields = Object.keys(fieldConfig.attributes);
          this.formConfig = {
            fields: this.attributeFields.reduce((acc: SmacsFieldConfigs, name) => {
              acc[name] = {
                label: name,
                required: fieldConfig.attributes[name].required,
                hidden: () => !fieldConfig.attributes[name].show,
                dataAutomation: name + '-input',
                defaultValue: () => fieldConfig.attributes[name].defaultValue,
                componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
              };
              return acc;
            }, {}),
          };
          // If all fields are hidden, initialEntity will be used to ensure we are saving the correct values
          this.initialEntity = this.attributeFields.reduce((acc: HelpdeskAdAttributesEntity, name) => {
            acc[name] = existingAttributes.adWriteDownListJson.some(
              (attr) => attr.attributeName === name && !!attr.attributeValue
            )
              ? existingAttributes.adWriteDownListJson.find((attrib) => attrib.attributeName === name)?.attributeValue
              : fieldConfig.attributes[name].defaultValue;
            return acc;
          }, {});
          this.isAnyAttributeVisible = Object.entries(fieldConfig.attributes).some(
            (attr) =>
              attr[1].show ||
              Object.entries(this.initialEntity).some(
                (entry) => entry[0] === attr[0] && entry[1] !== attr[1].defaultValue
              )
          );
        } else {
          this.isAnyAttributeVisible = false;
        }
        this.isLoading = false;
      });
    } else {
      this.isLoading = false;
    }
  }

  onChildFormUpdate(event: SmacsFormsUpdate<HelpdeskAdAttributesEntity>): void {
    if (event.new !== event.old) {
      this.adItemWasChanged.emit();
    }
  }

  isFormValid(): boolean {
    // Ad write down was configured but the field config request returned a 404
    if (!this._fieldConfig || !this.isAnyAttributeVisible) {
      return true;
    } else {
      return !!this.adAttributeForm && this.adAttributeForm?.isFormValid();
    }
  }

  submitAdAttributeForm(): Observable<void> {
    return !!this.adAttributeForm || !!this.initialEntity
      ? this._microsoftOnPremAdResource.putAdAttributes(
          this.onPremUpn,
          this.dialPlanId,
          this._getPayload(!!this.adAttributeForm ? this.adAttributeForm.entity : this.initialEntity, this.onPremUpn)
        )
      : of(null);
  }

  emptyAdAttributes(): Observable<void> {
    return !!this.adAttributeForm
      ? this._microsoftOnPremAdResource.putAdAttributes(this.onPremUpn, this.dialPlanId, {
          ...this._existingAttributes,
          userPrincipalName: this.onPremUpn,
          adWriteDownListJson: this._existingAttributes.adWriteDownListJson.map((attr) => {
            return {
              attributeName: attr.attributeName,
              attributeValue: '',
            };
          }),
        })
      : of(null);
  }

  private _getPayload(formData: HelpdeskAdAttributesEntity, userPrincipalName: string): AdAttributes {
    return {
      userPrincipalName,
      adWriteDownListJson: Object.entries(formData).map((entry) => {
        return {
          attributeName: entry[0],
          attributeValue: entry[1],
        };
      }),
    };
  }
}
