import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ZpcProxySettings } from '../../shared/models/generated/smacsModels';

@Injectable()
export class CiscoProxyFeatureSettingsResource {
  private _baseUrl = '/services/cisco/admin/proxy-feature-settings';
  constructor(private http: HttpClient) {}

  get(): Observable<ZpcProxySettings> {
    return this.http.get<ZpcProxySettings>(this._baseUrl);
  }

  put(body: ZpcProxySettings): Observable<ZpcProxySettings> {
    return this.http.put<ZpcProxySettings>(this._baseUrl, body);
  }
}
