<div class="smacs-admin-page-content">
  <div data-automation="bulk-line-appearance-management" class="col-md-10 offset-md-1 smacs-report-content">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div
          [innerHTML]="
            (isReadOnly
              ? 'tkey;reporting.bulk_line_appearance_management.report.description.read_only'
              : 'tkey;reporting.bulk_line_appearance_management.report.description'
            ) | translate
          "
        ></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <div class="bulk-line-appearance-management typeahead-width pb-4">
      <label
        class="typeahead-search input-group input-group-lg border-rad-all-2"
        [ngClass]="{
          'typeahead-search__pending': isSearching,
          'typeahead-margin': !isShowTable,
          'dropdown-menu__pending': isSearching
        }"
      >
        <span
          class="{{ smacsIcons.SEARCH }} fa-lg typeahead-search__icon"
          [ngClass]="{ 'icon-warning': isSearching }"
        ></span>
        <ng-template #resultTemplate let-r="result" let-t="term">
          <div
            *ngIf="r.ref.extension !== ''"
            class="flex-fill dropdown-item__content"
            [ngClass]="{
              'dropdown-item__content--disabled': r.numberOfAssociatedDevices === 1
            }"
            data-automation="search-result-row"
            (mouseenter)="tooltip?.open()"
            (mouseleave)="tooltip?.close()"
          >
            <span
              class="search-result-font"
              [class.disabled]="r.numberOfAssociatedDevices === 1"
              (click)="handleResultClick($event, r.numberOfAssociatedDevices)"
            >
              <span class="extension-class">
                <small class="muted d-block">
                  {{ 'tkey;reporting.bulk_line_appearance_management.table_header.extension' | translate }}
                </small>
                <span data-automation="search-result-row-extension">
                  <strong>{{ r.ref.extension | telephoneNumberFilter }}</strong>
                </span>
              </span>
              <span class="description-class">
                <small *ngIf="r.ref?.description !== ''" class="muted d-block">
                  {{ 'tkey;reporting.bulk_line_appearance_management.table_header.description' | translate }}
                </small>
                <span
                  [ngClass]="{ 'd-block text-truncate': r.ref?.description?.length > 27 }"
                  [ngbTooltip]="r.ref?.description"
                  [disableTooltip]="r.ref?.description?.length < 27"
                  [placement]="'top-left'"
                  container="body"
                  data-automation="search-result-row-description"
                >
                  {{ r.ref?.description }}
                </span>
              </span>
              <span class="associated-devices-class">
                <small class="muted d-block">
                  {{
                    'tkey;reporting.bulk_line_appearance_management.table_header.number_associated_devices' | translate
                  }}
                </small>
                <span
                  #tooltip="ngbTooltip"
                  triggers="hover"
                  [autoClose]="true"
                  [ngbTooltip]="
                    'tkey;menu.report.line_appearance_management_report.typeahead.disabled.tooltip' | translate
                  "
                  container="body"
                  [disableTooltip]="r.numberOfAssociatedDevices !== 1"
                  placement="bottom"
                  data-automation="search-result-row-number-of-associated-devices"
                >
                  {{ r.numberOfAssociatedDevices }}
                </span>
              </span>
              <span class="server-class">
                <small class="muted d-block">
                  {{ 'tkey;reporting.bulk_line_appearance_management.table_header.cucm' | translate }}
                </small>
                <span data-automation="search-result-row-cucm-server">
                  {{ getCucmServerDescriptionFromId(r.ref.serverId) }}
                </span>
              </span>
            </span>
          </div>
          <div class="ps-4">
            <span
              *ngIf="isNoResultsFound"
              class="search-result-font disabled"
              data-automation="search-no-result-row"
              style="color: black"
            >
              {{ 'tkey;global.search.no_results.text' | translate }}
            </span>
            <span
              *ngIf="isSearching && r.ref?.extension === ''"
              class="search-result-font disabled"
              data-automation="search-loading-row"
              style="color: black"
            >
              {{ 'tkey;menu.report.line_appearance_management_report.loading_text' | translate }}
            </span>
          </div>
        </ng-template>
        <input
          #typeaheadInput
          placeholder="{{ 'tkey;reporting.line_appearance.search.placeholder' | translate }}"
          (keyup.arrowDown)="scroll($event)"
          (keyup.arrowUp)="scroll($event)"
          data-automation="bulk-line-appearance-management-typeahead"
          class="typeahead-search__input form-control"
          [(ngModel)]="model"
          (selectItem)="onPreviewClick($event.item)"
          [focusFirst]="true"
          [resultTemplate]="resultTemplate"
          [inputFormatter]="formatter"
          [ngbTypeahead]="search"
          (focus)="onFocus($event)"
          (keydown)="filterTypeaheadInput($event)"
          [ngClass]="{
            'dropdown-menu__pending': isSearching,
            'typeahead-search__input__disabled': isLinePreviewed
          }"
        />
        <i *ngIf="isSearching" class="{{ smacsIcons.LOADING }} icon-warning fa-lg"></i>
        <span
          *ngIf="searchText !== '' && !isSearching"
          class="ng-clear-wrapper clear-hover"
          data-automation="clear-search-query"
        >
          <span class="ng-clear" aria-hidden="true" (click)="clearInput(true)">x</span>
        </span>
      </label>
    </div>
    <app-loading-block [hidden]="!isLoading"></app-loading-block>
    <div [hidden]="!isShowTable">
      <div [hidden]="isLoading" class="card">
        <div
          class="card-header"
          [innerHtml]="
            'tkey;menu.report.line_appearance_management_report.common.settings'
              | translate : { extension: extension | telephoneNumberFilter }
          "
        ></div>
        <div class="card-body">
          <div class="row">
            <div class="col-3">
              <smacs-text [fieldId]="'label'" [inlineLabel]="false"></smacs-text>
            </div>
            <div class="col-3">
              <smacs-text [fieldId]="'externalCallerId'" [inlineLabel]="false"></smacs-text>
            </div>
            <div class="col-3">
              <smacs-text [fieldId]="'externalCallerIdNumber'" [inlineLabel]="false"></smacs-text>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <smacs-text [fieldId]="'ringIdle'" [inlineLabel]="false"></smacs-text>
            </div>
            <div class="col-3">
              <smacs-text [fieldId]="'ringActive'" [inlineLabel]="false"></smacs-text>
            </div>
            <div class="col-3">
              <smacs-text [fieldId]="'maxNumberOfCalls'" [inlineLabel]="false"></smacs-text>
            </div>
            <div class="col-3">
              <smacs-text [fieldId]="'busyTrigger'" [inlineLabel]="false"></smacs-text>
            </div>
          </div>
          <div class="row">
            <div class="col-3">
              <smacs-text [fieldId]="'callRecordingOption'" [inlineLabel]="false"></smacs-text>
            </div>
            <div class="col-3">
              <smacs-text [fieldId]="'callRecordingProfile'" [inlineLabel]="false"></smacs-text>
            </div>
            <div class="col-3">
              <smacs-text [fieldId]="'callRecordingMediaSource'" [inlineLabel]="false"></smacs-text>
            </div>
            <div class="col-3">
              <smacs-text [fieldId]="'monitoringCssName'" [inlineLabel]="false"></smacs-text>
            </div>
          </div>
        </div>
      </div>
      <legend [hidden]="isLoading">
        <p
          [innerHtml]="
            'tkey;menu.report.line_appearance_management_report.legend'
              | translate : { extension: extension | telephoneNumberFilter }
          "
        ></p>
      </legend>
      <smacs-datatable
        *ngIf="isShowTable && !isLoading"
        [columns]="columns"
        [data]="tableRows"
        (rowSelected)="onSelectRow()"
        [showSelectColumn]="!isReadOnly"
        [isFirstColumnSticky]="true"
        [showEditColumn]="true"
        [editTooltip]="'tkey;reporting.bulk_line_appearance_management.report.edit_device.tooltip'"
        [mainFilterPlaceholder]="
          ('tkey;reporting.datatable.search_placeholder' | translate) +
          ('tkey;menu.report.line_appearance_management_report.table.filter.owner' | translate) +
          ', ' +
          ('tkey;menu.report.line_appearance_management_report.table.filter.device.name' | translate) +
          ', ' +
          ('tkey;menu.report.line_appearance_management_report.table.filter.line.setting' | translate) +
          '...'
        "
        [dataAutomation]="tableDataAutomation"
      >
        <smacs-datatable-switch-filter
          [label]="'tkey;menu.report.line_appearance_management_report.table.filter.misconfigured' | translate"
          [matchFn]="showOnlyMisconfiguredFilter"
          [dataAutomation]="'show-only-misconfigured-switch-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-switch-filter>
      </smacs-datatable>
    </div>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
