import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ZiroCallingSubscription } from '../../../../shared/models/generated/smacsModels';
import { ReplaySubject, switchMap } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class PurchasedZiroCallingSubscriptionsContext {
  _stateSource = new ReplaySubject<ZiroCallingSubscription[]>();
  state$ = this._stateSource.asObservable();
  constructor(private http: HttpClient) {
    this.init();
  }

  init() {
    this.getSubscriptions().subscribe();
  }

  getSubscriptions() {
    return this.http
      .get<ZiroCallingSubscription[]>('/services/microsoft/billing/purchased-ziro-calling-subscriptions')
      .pipe(map((subscriptions) => this._stateSource.next(subscriptions)));
  }

  updateSubscriptions(payload: ZiroCallingSubscription[]) {
    return this.http
      .put('/services/microsoft/billing/purchased-ziro-calling-subscriptions', payload)
      .pipe(switchMap(() => this.getSubscriptions()));
  }
}
