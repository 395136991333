import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ModelResourceAbstract } from '../../shared/resources/abstract/model.resource.abstract';
import { DialPlanExceptionGroup } from '../../shared/models/generated/smacsModels';

@Injectable()
export class DialPlanExceptionResource extends ModelResourceAbstract<DialPlanExceptionGroup> {
  protected _baseUrl = '/services/cisco/admin/dial-plan-exception-groups/groups';

  get: (id: number) => Observable<DialPlanExceptionGroup>;
  post: (dnExceptionGroup: DialPlanExceptionGroup) => Observable<DialPlanExceptionGroup>;
  put: (id: number, dnExceptionGroup: DialPlanExceptionGroup) => Observable<DialPlanExceptionGroup>;
  delete: (id: string) => Observable<void>;

  constructor(protected http: HttpClient) {
    super(http);
  }

  getAllDnExceptionGroups = (): Observable<DialPlanExceptionGroup[]> => {
    return this.http.get<DialPlanExceptionGroup[]>(this._baseUrl);
  };

  deleteDnExceptionGroup(id: number): Observable<void> {
    return this.http.delete<void>(`${this._baseUrl}/${id}`);
  }

  saveDnExceptionGroup(id: number, dnExceptionGroup: DialPlanExceptionGroup): Observable<DialPlanExceptionGroup> {
    return this.http.put<DialPlanExceptionGroup>(`${this._baseUrl}/${id}`, dnExceptionGroup);
  }

  createDnExceptionGroup(dnExceptionGroup: DialPlanExceptionGroup): Observable<DialPlanExceptionGroup> {
    return this.http.post<DialPlanExceptionGroup>(this._baseUrl, dnExceptionGroup);
  }
}
