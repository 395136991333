import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  SmacsCheckboxConfig,
} from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { of } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';

export interface DnAgentExtensionForm {
  agentExtension: string;
}

interface DnAgentExtensionFormData {
  agentExtension: boolean;
}

@Component({
  selector: 'smacs-directory-number-agent-extension-form',
  templateUrl: './directory-number-agent-extension-form.component.html',
})
export class DirectoryNumberAgentExtensionFormComponent
  extends SmacsFormAbstractDirective<DnAgentExtensionForm, DnAgentExtensionFormData>
  implements OnInit, OnChanges
{
  protected formConfig: SmacsFormConfig;
  @Input() entity: DnAgentExtensionForm;
  @Input() dnExtension: string;

  private _isToggleDisabled = false;
  paramExtension: string;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    if (this.entity.agentExtension && this.entity.agentExtension !== this.dnExtension) {
      this._isToggleDisabled = true;
      this.paramExtension = this.entity.agentExtension;
    }
    this._initFormConfig();
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        agentExtension: {
          label: 'Agent Extension',
          dataAutomation: 'dn-agent-extension-checkbox',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
          disabled: () => this._isToggleDisabled,
          disabledTooltip: {
            content: 'tkey;shared.dnWizard.dnDetails.uccx.toggle.disabled_message',
            params: { extension: this.paramExtension },
          },
        },
      },
    } as SmacsFormConfig;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.dnExtension &&
      !changes.dnExtension.firstChange &&
      changes.entity?.currentValue?.agentExtension === changes.dnExtension.previousValue
    ) {
      this.entity.agentExtension = changes.dnExtension.currentValue;
    }
    super.ngOnChanges(changes);
  }

  toFormData = (entity: DnAgentExtensionForm): DnAgentExtensionFormData => {
    if (!entity.agentExtension) {
      return { agentExtension: false };
    } else if (entity.agentExtension === this.dnExtension) {
      this._isToggleDisabled = false;
      return { agentExtension: true };
    } else if (entity.agentExtension !== this.dnExtension) {
      return { agentExtension: false };
    }
  };

  toEntity = (formData: DnAgentExtensionFormData): DnAgentExtensionForm => {
    if (formData.agentExtension === true) {
      return { agentExtension: this.dnExtension };
    } else {
      return { agentExtension: '' };
    }
  };

  protected submit() {
    return of(null);
  }
}
