import { BlfButton } from '../../../models/generated/smacsModels';
import { Component, Input } from '@angular/core';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { PhoneButtonsService } from '../../shared/phone-buttons.service';

enum PhoneButtonBLF {
  BLF_EXTENSION = 'tkey;pages.details.phoneSettings.button.blf.extension',
  LABEL = 'tkey;pages.details.phoneSettings.button.blf.label',
  CALL_PICKUP = 'tkey;pages.details.phoneSettings.button.blf.callpickup',
}

@Component({
  selector: 'smacs-phone-buttons-blf',
  templateUrl: './phone-buttons-blf.html',
  styleUrls: ['./phone-buttons-form.scss'],
})
export class PhoneButtonsBlfComponent extends SmacsFormAbstractDirective<BlfButton> {
  @Input() isPending = false;
  private _wasExtensionChanged = false;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private phoneButtonsService: PhoneButtonsService
  ) {
    super(smacsFormStateService);
  }

  formConfig = {
    fields: {
      destination: {
        label: PhoneButtonBLF.BLF_EXTENSION,
        dataAutomation: 'phone-button-speed-dial-blf-number',
        validation: [
          {
            validator: (val: string) =>
              this.phoneButtonsService.validSpeedDialExtensionLength(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: {
              content: 'tkey;validators.global.error.maxlength',
              params: {
                maxlength: 50,
              },
            },
          },
          {
            validator: (val: string) =>
              this.phoneButtonsService.validSpeedDialExtensionPattern(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: 'tkey;validators.global.error.invalid_number.pattern',
          },
        ],
      },
      label: {
        label: PhoneButtonBLF.LABEL,
        dataAutomation: 'phone-button-speed-dial-blf-label',
        validation: [
          {
            validator: (val: string) =>
              this.phoneButtonsService.validLabelLength(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: {
              content: 'tkey;validators.global.error.maxlength',
              params: {
                maxlength: 30,
              },
            },
          },
          {
            validator: (val: string) =>
              this.phoneButtonsService.validSpeedDialLabelPattern(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: 'tkey;validators.global.error.pattern',
          },
        ],
      },
      callPickup: {
        label: PhoneButtonBLF.CALL_PICKUP,
        dataAutomation: 'phone-button-speed-dial-blf-call-pickup',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.CHECKBOX }),
      },
    },
    options: {
      columnClasses: {
        label: 'col-3 text-lg-end',
        input: 'col-9',
      },
    },
  } as SmacsFormConfig;

  submit() {
    return this.parentSubmit$;
  }

  toFormData = (entity: BlfButton): BlfButton => {
    const newEntity = { ...entity };
    if (entity.dn && !entity.destination) {
      newEntity.destination = newEntity.dn.extension;
      delete newEntity.dn;
    }
    return newEntity;
  };

  toEntity = (formData: BlfButton): BlfButton => {
    const newFormData = { ...formData };
    if (this._wasExtensionChanged) {
      delete newFormData.dn;
    } else if (this.entity.dn) {
      newFormData.dn = { ...this.entity.dn };
      newFormData.destination = '';
    }
    return newFormData;
  };

  handleDestinationChange() {
    this._wasExtensionChanged = true;
  }
}
