import { Component, OnInit } from '@angular/core';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { IntercomButton } from '../../../models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';

interface IntercomButtonFormData {
  extension: string;
}

@Component({
  selector: 'smacs-phone-buttons-intercom',
  templateUrl: './phone-buttons-intercom.component.html',
})
export class PhoneButtonsIntercomComponent
  extends SmacsFormAbstractDirective<IntercomButton, IntercomButtonFormData>
  implements OnInit
{
  protected formConfig: SmacsFormConfig;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.formConfig = {
      fields: {
        extension: {
          label: 'tkey;pages.details.phoneSettings.button.intercom.extension',
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.TEXT,
            placeholder: this.entity.intercom?.extension,
          }),
          dataAutomation: 'phone-button-intercom-extension',
          disabled: () => true,
        },
      },
      options: {
        columnClasses: {
          label: 'col-3 text-lg-end',
          input: 'col-9',
        },
      },
    } as SmacsFormConfig;
  }

  submit() {
    return this.parentSubmit$;
  }

  toFormData = (entity: IntercomButton): IntercomButtonFormData => {
    return {
      extension: entity.intercom?.extension,
    };
  };

  toEntity = (formData: IntercomButtonFormData): IntercomButton => {
    // intercom is immutable
    return this.entity;
  };
}
