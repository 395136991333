import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { WebexCallingFieldConfigResource } from '../../shared/resources/field-config/webex-calling-field-config.resource';
import { WebexDialPlanFieldConfig, WebexEndUser, WebexFieldConfig } from '../../../shared/models/generated/smacsModels';
import { cloneDeep, get, isEqual } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { WebexUserResource } from '../../../shared/resources/webex-user.resource';
import { forkJoin, Observable, of } from 'rxjs';
import { DidSelectorFormData, ZiroDidSelectorFormComponent } from './did-selector-form/did-selector-form.component';
import {
  ExtensionDetailsEntity,
  ZiroExtensionDetailsFormComponent,
} from './extension-details-form/extension-details-form.component';
import { SmacsFormsUpdate } from '../../../forms/smacs-forms-models';
import { ButtonStyles } from '../../../button/button.component';
import {
  BottomNavClearButtonsList,
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ToastService } from '../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { map, shareReplay, tap } from 'rxjs/operators';

export const UCM_LICENSE_NAME = 'Unified Communication Manager (UCM)';

@Component({
  selector: 'ziro-webex-calling',
  templateUrl: './webex-calling.component.html',
})
export class WebexCallingComponent implements OnInit, OnDestroy {
  @ViewChild(ZiroDidSelectorFormComponent) didSelectorFormComponent: ZiroDidSelectorFormComponent;
  @ViewChild(ZiroExtensionDetailsFormComponent) extensionDetailsFormComponent: ZiroExtensionDetailsFormComponent;

  webexFieldConfig: WebexFieldConfig;
  didSelectorFormData: DidSelectorFormData;
  extensionDetailsEntity: ExtensionDetailsEntity;
  dialPlanFieldConfig: WebexDialPlanFieldConfig[];
  isLoading = true;
  parentSubmit$: Observable<void>;

  private _email: string;
  private _initialWebexEndUser: WebexEndUser;
  private _isExistingUser = true;
  private _smacsIcons = SmacsIcons;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    private _bottomNavService: BottomNavService,
    private _smacsModalService: SmacsModalService,
    private _webexUserResource: WebexUserResource,
    private _userDetailUiContext: UserDetailUiContext,
    private _global360ViewContext: Global360ViewContext,
    private _telephoneNumberFilter: TelephoneNumberFilter,
    private _fieldConfigResource: WebexCallingFieldConfigResource,
    private _smacsFormStateService: SmacsFormStateService
  ) {}

  ngOnInit() {
    this._email = get(this._route, 'snapshot.params.email');

    forkJoin([
      this._webexUserResource.get(this._email),
      this._fieldConfigResource.getDialPlanFieldConfigs(),
      this._fieldConfigResource.getFieldConfigs(this._email),
    ]).subscribe(([webexEndUser, webexDialPlanFieldConfig, webexFieldConfig]) => {
      this._initialWebexEndUser = webexEndUser;
      this._isExistingUser = !!webexEndUser.webexCallingLicense;

      this.dialPlanFieldConfig = webexDialPlanFieldConfig;
      this.webexFieldConfig = webexFieldConfig;

      this.didSelectorFormData = {
        webexCallingLicense: this._isExistingUser
          ? this._initialWebexEndUser.webexCallingLicense
          : this.webexFieldConfig.webexCallingLicenseJson.defaultValue,
        location: this._initialWebexEndUser.location,
        did: this._initialWebexEndUser.did,
      };
      this.extensionDetailsEntity = {
        extension: this._isExistingUser
          ? this._initialWebexEndUser.extension
          : this.webexFieldConfig.extension.defaultValue,
      };
      this._initBottomNav();
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this._bottomNavService.dispatch(new BottomNavClearButtonsList());
  }

  onFormUpdate($event: SmacsFormsUpdate<DidSelectorFormData | ExtensionDetailsEntity>, form: string) {
    if ($event && !isEqual($event.new, $event.old)) {
      switch (form) {
        case 'DID_SELECTOR_FORM':
          this.didSelectorFormData = { ...$event.new } as DidSelectorFormData;
          break;
        case 'EXTENSION_DETAILS_FORM':
          this.extensionDetailsEntity = { ...$event.new } as ExtensionDetailsEntity;
          break;
      }
    }
  }

  private _initBottomNav() {
    const bottomNavButtons = [];

    bottomNavButtons.push({
      id: 'webex-form-cancel-button',
      dataAutomation: 'webex-form-cancel-button',
      label: 'tkey;dialogs.button.cancel',
      buttonClass: ButtonStyles.DEFAULT,
      cb: () => this._goToUserHome(),
    });

    if (this._isExistingUser) {
      bottomNavButtons.push({
        id: 'webex-form-delete-button',
        dataAutomation: 'webex-form-delete-button',
        label: 'tkey;dialogs.button.delete',
        icon: this._smacsIcons.DELETE,
        buttonClass: ButtonStyles.DANGER,
        cb: () => this._onDelete(),
      });
    }

    bottomNavButtons.push({
      id: 'webex-form-save-button',
      dataAutomation: 'webex-form-save-button',
      label: 'tkey;global.button.save.text',
      icon: this._smacsIcons.OK,
      buttonClass: ButtonStyles.PRIMARY,
      cb: () => this._onSave(),
    });

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _goToUserHome() {
    this._router.navigateByUrl(`/user/${encodeURIComponent(this._route.snapshot.params.username)}`);
  }

  private _areFormsInvalid() {
    return !this.didSelectorFormComponent.isFormValid() || !this.extensionDetailsFormComponent?.isFormValid();
  }

  private _onSave() {
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
      })
    );
    if (this._areFormsInvalid()) {
      this.parentSubmit$ = of(null);
      this._setFormSubmitState(true);
      this._bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: true,
        })
      );
    } else {
      this._setPendingState('save');

      const entityCopy: WebexEndUser =
        this.didSelectorFormData?.webexCallingLicense === UCM_LICENSE_NAME
          ? {
              ...this._initialWebexEndUser,
              webexCallingLicense: this.didSelectorFormData.webexCallingLicense,
              location: '',
              did: '',
              extension: '',
            }
          : {
              ...this._initialWebexEndUser,
              webexCallingLicense: this.didSelectorFormData.webexCallingLicense,
              did: this.didSelectorFormData.did,
              location: this.didSelectorFormData.location,
              extension: this.extensionDetailsEntity.extension,
            };
      this.parentSubmit$ = (
        this._isExistingUser
          ? this._webexUserResource.put(entityCopy, this._email)
          : this._webexUserResource.post(entityCopy)
      ).pipe(
        map(() => null),
        shareReplay()
      );
      this._setFormSubmitState(true);

      this.parentSubmit$.subscribe(() => {
        this._setFormSubmitState(null);
        this._toastService.push(
          ToastTypes.SUCCESS,
          this._smacsIcons.WEBEX_CALLING,
          'tkey;shared.toast.save.success.title',
          `${this._translateService.instant('tkey;shared.service.webex_calling.text')} - ${
            this._telephoneNumberFilter.transform(entityCopy.did || entityCopy.extension) ||
            entityCopy.webexCallingLicense
          }`
        );
        const global360 = cloneDeep(this._userDetailUiContext.getGlobal360View());
        global360.webexEndUser = {
          ...global360.webexEndUser,
          webexCallingLicense: entityCopy.webexCallingLicense,
          location: entityCopy.location,
          did: entityCopy.did,
          extension: entityCopy.extension,
        };
        this._global360ViewContext._stateSource.next(global360);
        this._goToUserHome();
      });
    }
  }

  private _onDelete() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        title: this._translateService.instant('tkey;helpdesk.webex_calling.delete.confirmation.modal.header'),
        promptBody: this._translateService.instant('tkey;helpdesk.webex_calling.delete.confirmation.modal.text', {
          displayName: this._initialWebexEndUser.displayName,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onConfirmDelete(),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _onConfirmDelete(): Observable<void> {
    return this._webexUserResource.delete(this._email).pipe(
      tap(() => {
        this._toastService.pushDeleteToast(
          'tkey;shared.service.webex_calling.text',
          `${this._translateService.instant('tkey;shared.service.webex_calling.text')} - ${
            this._telephoneNumberFilter.transform(
              this._initialWebexEndUser.did || this._initialWebexEndUser.extension
            ) || this._initialWebexEndUser.webexCallingLicense
          }`
        );
        const global360 = cloneDeep(this._userDetailUiContext.getGlobal360View());
        global360.webexEndUser = {
          ...global360.webexEndUser,
          webexCallingLicense: null,
          location: null,
          did: null,
          extension: null,
        };
        this._global360ViewContext._stateSource.next(global360);
        this._setFormSubmitState(false);
        this._smacsFormStateService.setIsFormDirty(false);
        this._goToUserHome();
      })
    );
  }

  private _setFormSubmitState(state: boolean) {
    if (this.didSelectorFormComponent) {
      this.didSelectorFormComponent._validateAndSubmitSource.next(state);
    }
    if (this.extensionDetailsFormComponent) {
      this.extensionDetailsFormComponent._validateAndSubmitSource.next(state);
    }
  }

  private _setPendingState(type: 'save' | 'delete' | null) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'webex-form-save-button',
        state: {
          pending: type === 'save',
          buttonDisableState: { disabled: type !== null, tooltipKey: '' },
        },
      })
    );
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'webex-form-cancel-button',
        state: {
          buttonDisableState: { disabled: type !== null, tooltipKey: '' },
        },
      })
    );
    if (this._isExistingUser) {
      this._bottomNavService.dispatch(
        new BottomNavUpdateButtonState({
          id: 'webex-form-delete-button',
          state: {
            pending: type === 'delete',
            buttonDisableState: { disabled: type !== null, tooltipKey: '' },
          },
        })
      );
    }
  }
}
