import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DetailedZiroCallingReport } from '../../../../shared/models/generated/smacsModels';
import { Observable, ReplaySubject } from 'rxjs';
import { FileDownloadService } from '../../../../shared/services/file-download.service';
import { map } from 'rxjs/operators';

@Injectable()
export class DetailedZiroCallingReportsContext {
  private _stateSource = new ReplaySubject<DetailedZiroCallingReport[]>(1);
  state$ = this._stateSource.asObservable();

  constructor(private http: HttpClient, private fileDownloadService: FileDownloadService) {}

  initReport(): Observable<void> {
    return this.http.get<DetailedZiroCallingReport[]>('/services/microsoft/billing/detailed-ziro-calling-reports').pipe(
      map((report) => {
        this._stateSource.next(report);
      })
    );
  }

  refreshReport(): Observable<void> {
    return this.http.post<void>('/services/microsoft/billing/detailed-ziro-calling-reports/refresh', null);
  }

  export(): Observable<void> {
    return this.fileDownloadService.downloadFile('/services/microsoft/billing/detailed-ziro-calling-reports/export');
  }
}
