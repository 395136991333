<div
  class="mb-3 row"
  [hidden]="state.hidden && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage,
    'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
  }"
>
  <label
    *ngIf="config.label"
    [class]="'smacs-forms-label col-form-label ' + state.columnClasses?.label"
    [attr.for]="config.dataAutomation + '-input'"
    data-automation="smacs-code-area-label"
  >
    <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
    <span [disableTooltip]="!!config.labelToolTipIconClass" [ngbTooltip]="config.labelToolTipText | translate">
      {{ getLabel() | translate }}
    </span>
    <i
      *ngIf="config.labelToolTipIconClass"
      [ngbTooltip]="config.labelToolTipText | translate"
      class="{{ config.labelToolTipIconClass }} ms-1"
    ></i>
  </label>

  <div [class]="state.columnClasses?.input">
    <div class="ngx-codemirror-container">
      <ngx-codemirror
        [(ngModel)]="fieldData"
        [options]="{
          lineNumbers: true,
          theme: 'eclipse',
          mode: mode,
          readOnly: state.disabled
        }"
        [className]="state.disabled ? 'disabled' : ''"
        #ngModelDir="ngModel"
        (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
      ></ngx-codemirror>
    </div>

    <span
      class="{{ smacsIcons.WARNING }} form-control-feedback"
      [hidden]="
        !(
          (showAsyncValidation && validationState === ValStates.WARNING) ||
          (validationState === ValStates.VALID && misconfigurationFeedbackMessage)
        )
      "
      data-automation="smacs-text-warning-icon"
    ></span>

    <span
      class="{{ smacsIcons.SAVING }} form-control-feedback"
      [hidden]="!(validationState === ValStates.PENDING && showAsyncValidation)"
      data-automation="smacs-text-async-pending"
    ></span>

    <span
      class="{{ smacsIcons.REMOVE }} form-control-feedback"
      [hidden]="!(validationState === ValStates.INVALID && showAsyncValidation)"
      data-automation="smacs-text-async-invalid"
    ></span>

    <span
      class="{{ smacsIcons.OK }} form-control-feedback"
      [hidden]="!(validationState === ValStates.VALID && showAsyncValidation && !misconfigurationFeedbackMessage)"
      data-automation="smacs-text-async-valid"
    ></span>

    <div class="d-flex justify-content-between">
      <div class="smacs-forms-feedback-area">
        <div class="form-text small" *ngIf="isAutogenerated" data-automation="smacs-forms-autogeneration-message">
          {{ config.autogeneration?.generatedMsg | translate }}
        </div>

        <div
          class="smacs-forms-validation-message"
          data-automation="smacs-forms-validation-message"
          [hidden]="!showValidation && !isFormSubmitted"
          [innerHTML]="validationMessage?.content | translate : validationMessage?.params"
        ></div>

        <div
          class="smacs-forms-validation-message text-warning"
          [ngClass]="{ 'd-inline': config.autogeneration?.inline }"
          data-automation="smacs-forms-warning-message"
          [hidden]="!misconfigurationFeedbackMessage"
          [innerHTML]="misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params"
        ></div>

        <span *ngIf="getHelpText()" class="small form-text text-muted" [innerHtml]="getHelpText() | translate"></span>
      </div>

      <div class="flex-shrink-0"><ng-content></ng-content></div>
    </div>
  </div>
</div>
