<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div *ngIf="!isLoading">
  <app-breadcrumbs [labelParam]="licenseTier">
    <app-breadcrumb-card-item>
      <div
        *ngIf="unusedDevicesReport?.numCalls === 0"
        [innerHTML]="'tkey;reporting.cucm_license_optimizer.overview.description.export_disabled' | translate"
      ></div>
      <div *ngIf="unusedDevicesReport?.numCalls !== 0">
        <div
          [innerHTML]="'tkey;reporting.cucm_license_optimizer.overview.description.export_enabled' | translate"
        ></div>
        <p class="mb-0">
          <a [routerLink]="[]" (click)="onGetFileClicked()">
            {{ 'tkey;reporting.xlsx_export.button' | translate }}
          </a>
          <span
            [innerHTML]="
              'tkey;reporting.cucm_license_optimizer.overview.description.export_enabled.part_two' | translate
            "
          ></span>
        </p>
      </div>
    </app-breadcrumb-card-item>
  </app-breadcrumbs>

  <div
    *ngIf="unusedDevicesReport?.numCalls === 0"
    class="smacs-page-alert alert border-warning w-100 mt-1"
    data-automation="device-utilization-no-cdrs-warning"
  >
    <i class="icon-warning-triangle text-warning fa-4x"></i>
    <div class="ms-3" [innerHTML]="'tkey;reporting.cucm_license_optimizer.no_cdr.message' | translate"></div>
  </div>

  <app-entity-table
    *ngIf="unusedDevicesReport?.numCalls > 0"
    [table]="table"
    [tableRows]="tableRows"
    data-automation="license-optimization-entity-table"
  ></app-entity-table>
</div>
