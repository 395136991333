<p>{{ modalViewProperties.message | translate }}</p>

<smacs-select [fieldId]="'documentType'"></smacs-select>

<smacs-file-uploader [fieldId]="'file'"></smacs-file-uploader>

<ng-template #errorTemplate>
  <span *ngIf="isFormSubmitted && validationState === ValidationState.INVALID">
    {{ 'tkey;validators.global.required.missing.error' | translate }}
  </span>
</ng-template>
