import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsFormAbstractDirective } from '../../forms/smacs-form-abstract.directive';
import { HighAvailabilitySettings } from '../../shared/models/generated/smacsModels';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import {
  HtmlInputAddOn,
  SmacsFieldComponentConfig,
  SmacsFieldConfig,
  SmacsFormConfig,
  SmacsFormsValidationState,
} from '../../forms/smacs-forms-models';
import { Observable, Subscription, throwError } from 'rxjs';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  SmacsCheckboxConfig,
} from '../../forms/fields/checkbox/smacs-checkbox.component';
import { HtmlInputType, SmacsTextConfig } from '../../forms/fields/text/smacs-text.component';
import { HighAvailabilityResource } from '../resources/high-availability.resource';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../button/button.component';
import { BottomNavButton } from '../../shared/bottom-nav/bottom-nav.component';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { TranslateService } from '@ngx-translate/core';
import { ToastTypes } from '../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../shared/services/toast.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LicenseContext } from '../../shared/contexts/license.context';
import { isEqual } from 'lodash';
import { Router } from '@angular/router';

const validators = {
  isSyncValueValid(sync: number, haEnabled: boolean): SmacsFormsValidationState {
    if (sync >= 6 && sync <= 48) {
      return SmacsFormsValidationState.VALID;
    } else if (sync.toString() === '' && !haEnabled) {
      return SmacsFormsValidationState.VALID;
    } else {
      return SmacsFormsValidationState.INVALID;
    }
  },
};

@Component({
  selector: 'smacs-high-availability-management',
  templateUrl: './high-availability-management.component.html',
  styleUrls: ['../admin-page.scss', './high-availability-management.component.scss'],
  providers: [HighAvailabilityResource],
})
export class HighAvailabilityManagementComponent
  extends SmacsFormAbstractDirective<HighAvailabilitySettings>
  implements OnInit, OnDestroy
{
  constructor(
    private router: Router,
    private breadcrumbsService: BreadcrumbsService,
    private highAvailabilityResource: HighAvailabilityResource,
    private bottomNavService: BottomNavService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private licenseContext: LicenseContext,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }
  isLoading = true;
  formConfig = {
    fields: {
      haEnabled: {
        dataAutomation: 'enable-high-availability',
        label: 'tkey;pages.admin.high.availability.management.enable.high.availability.label',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
      },
      syncIntervalInHours: {
        dataAutomation: 'sync-interval-in-hours',
        label: 'tkey;pages.admin.high.availability.management.sync.interval.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        options: {
          content: 'tkey;pages.admin.high.availability.management.sync.interval.content',
        },
        required: true,
        validation: [
          {
            validator: (sync: number, haEnabled: boolean) => validators.isSyncValueValid(sync, haEnabled),
            message: 'tkey;pages.admin.high.availability.management.sync.interval.validation.message',
            injectValuesFromFields: ['haEnabled'],
          },
          {
            validator: (val: number) =>
              Number.isInteger(Number(val)) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: 'tkey;validators.global.error.invalid_number.pattern',
          },
        ],
      },
      url: {
        dataAutomation: 'secondary-machine-fqdn',
        label: 'tkey;pages.admin.high.availability.management.secondary.machine.fqdn.label',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          htmlInputAddOn: {
            prependedContent: 'https://',
          } as HtmlInputAddOn,
        }),
        options: {
          content: 'tkey;pages.admin.high.availability.management.secondary.machine.fqdn.content',
        },
        required: this.formData.haEnabled,
      },
    },
  } as SmacsFormConfig;
  smacsIcons = SmacsIcons;

  private _subscriptions = new Subscription();

  private static _prefixUrl(url: string): string {
    return !url.startsWith('https://') ? 'https://'.concat(url) : url;
  }

  private static _formatUrl(url: string): string {
    return url.startsWith('https://') ? url.replace(/^https?:\/\//, '') : url;
  }

  ngOnInit(): void {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;pages.admin.high.availability.management.header' }]);
    const licenseSub = this.licenseContext.licenseState$.subscribe((licenseInfo) => {
      if (!licenseInfo.modules.highAvailability) {
        this.router.navigateByUrl('/admin');
      } else {
        this.highAvailabilityResource.getHaSettings().subscribe((settings: HighAvailabilitySettings) => {
          this.entitySource.next({ ...settings, url: HighAvailabilityManagementComponent._formatUrl(settings.url) });
          this.formConfig.fields['url'].required = settings.haEnabled;
          this.setIsExisting(!!settings.url);
          this._setBottomNavButtons();
          this.isLoading = false;
        });
      }
    });
    this._subscriptions.add(licenseSub);

    const formUpdateSub = this.smacsFormsUpdate$.subscribe((data) => {
      if (!!data.old && !!data.new && data.new.haEnabled !== data.old.haEnabled) {
        this._resetFieldState('url', data.new.haEnabled);
      }
      if (!isEqual(data.old, data.new)) {
        this.setReplicateButtonDisabledWithTooltip();
      }
    });
    this._subscriptions.add(formUpdateSub);

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });
    this._subscriptions.add(formSubmittedSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }

  returnZero = () => 0;

  isCheckBox(config: SmacsFieldConfig<SmacsFieldComponentConfig>): boolean {
    return config.componentConfig instanceof SmacsCheckboxConfig;
  }

  isTextInput(config: SmacsFieldConfig<SmacsFieldComponentConfig>): boolean {
    return config.componentConfig instanceof SmacsTextConfig;
  }

  protected submit(): Observable<any> {
    return this._saveHaSettings();
  }

  private _setBottomNavButtons() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'replicateConfigurationNow',
          dataAutomation: 'replicate-ha-configuration-button',
          label: 'tkey;pages.admin.high.availability.management.replication.button.label',
          buttonClass: ButtonStyles.INFO,
          state: {
            pending: false,
            tooltipVisible: !this.entity?.haEnabled,
            buttonDisableState: {
              disabled: !this.entity?.haEnabled,
              tooltipKey: 'tkey;pages.admin.high.availability.management.disabled.replicate.button.tooltip',
            },
          },
          icon: this.smacsIcons.RUN,
          type: ButtonTypes.BUTTON,
          cb: () => this._replicateConfigurationButton(),
        },
        {
          id: 'saveHaSettings',
          dataAutomation: 'save-ha-settings-button',
          label: 'tkey;admin.ui.save',
          buttonClass: ButtonStyles.PRIMARY,
          state: {
            pending: false,
            buttonDisableState: {
              disabled: false,
              tooltipKey: '',
            },
          },
          icon: this.smacsIcons.OK,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ] as BottomNavButton[])
    );
  }
  // If HA is enabled perform validate POST followed by settings PUT, otherwise just settings PUT
  private _saveHaSettings() {
    this.bottomNavService.setButtonPendingState('saveHaSettings', true);
    this.bottomNavService.setButtonDisabledState('replicateConfigurationNow', true);

    if (this.entity?.haEnabled) {
      return this.highAvailabilityResource
        .postHaValidation(HighAvailabilityManagementComponent._prefixUrl(this.formData.url))
        .pipe(
          switchMap(() => this._saveSettingsPUT()),
          catchError((err) => {
            this._validateAndSubmitSource.next(false);
            this.bottomNavService.setButtonPendingState('saveHaSettings', false);
            return this._handleError(err);
          })
        );
    } else {
      this.setReplicateButtonDisabledWithTooltip();
      return this._saveSettingsPUT();
    }
  }

  private _saveSettingsPUT(): Observable<any> {
    const request = {
      haEnabled: this.formData.haEnabled,
      syncIntervalInHours: Number(this.formData.syncIntervalInHours),
      url: HighAvailabilityManagementComponent._prefixUrl(this.formData.url),
    } as HighAvailabilitySettings;

    return this.highAvailabilityResource.putHaSettings(request).pipe(
      tap(() => {
        if (this.entity?.haEnabled) {
          this.bottomNavService.setButtonDisabledState('replicateConfigurationNow', false);
        }
        this.toastService.pushSaveToast(
          'tkey;shared.toast.save.success.title',
          'tkey;pages.admin.high.availability.management.save.toast.message',
          SmacsIcons.DISASTER_RECOVERY
        );
        this.bottomNavService.setButtonPendingState('saveHaSettings', false);
      }),
      catchError((err) => {
        this._validateAndSubmitSource.next(false);
        this.bottomNavService.setButtonPendingState('saveHaSettings', false);
        if (this.entity?.haEnabled) {
          this.bottomNavService.setButtonDisabledState('replicateConfigurationNow', false);
        }
        return this._handleError(err);
      })
    );
  }

  // Replicate configuration now button disabled until HA settings are validated
  private _replicateConfigurationButton() {
    this.bottomNavService.setButtonDisabledState('saveHaSettings', true);
    this.bottomNavService.setButtonPendingState('replicateConfigurationNow', true);
    const requestUrl = HighAvailabilityManagementComponent._prefixUrl(this.formData.url);
    this.highAvailabilityResource
      .postReplication(requestUrl)
      .pipe(
        tap(() => {
          this.toastService.push(
            ToastTypes.SUCCESS,
            SmacsIcons.DISASTER_RECOVERY,
            'tkey;shared.toast.replication.success.title',
            'tkey;pages.admin.high.availability.management.replication.toast.message'
          );
          this.bottomNavService.setButtonPendingState('replicateConfigurationNow', false);
          this.bottomNavService.setButtonDisabledState('saveHaSettings', false);
        }),
        catchError((err) => {
          this.bottomNavService.setButtonDisabledState('saveHaSettings', false);
          this.bottomNavService.setButtonPendingState('replicateConfigurationNow', false);
          return this._handleError(err);
        })
      )
      .subscribe();
  }

  private setReplicateButtonDisabledWithTooltip() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'replicateConfigurationNow',
        state: {
          tooltipVisible: true,
          buttonDisableState: {
            disabled: true,
            tooltipKey: 'tkey;pages.admin.high.availability.management.disabled.replicate.button.tooltip',
          },
        },
      })
    );
  }

  private _resetFieldState(fieldId: string, isRequired: boolean) {
    this.formConfig.fields[fieldId].required = isRequired;
    const fieldState = this.fieldStates[fieldId];
    const fieldChannel = this.fieldChannels[fieldId];
    this.fieldChannels[fieldId].validateSource.next();
    fieldChannel.stateSource.next({ ...fieldState, required: isRequired });
  }

  private _handleError = (error: any) => {
    let errorTitle = '';
    let errorDescription = error ? error.error.description : '';
    if (error.status === 422 && error.error.reasonCode === 'URL_NOT_REACHABLE') {
      errorTitle = 'tkey;pages.admin.high.availability.management.error.toast.title.url.unreachable';
      errorDescription = 'tkey;pages.admin.high.availability.management.error.toast.message.url.unreachable';
      return this._displayErrorToast(errorTitle, errorDescription);
    } else if (error.status === 422 && error.error.reasonCode === 'INVALID_HA_CONFIGURATION') {
      errorTitle = 'tkey;pages.admin.high.availability.management.error.toast.title.invalid.configuration';
      errorDescription = 'tkey;pages.admin.high.availability.management.error.toast.message.invalid.configuration';
      return this._displayErrorToast(errorTitle, errorDescription);
    } else if (error.status === 422 && error.error.reasonCode === 'INCONSISTENT_LOCAL_ADMIN_ACCOUNTS') {
      errorTitle = 'tkey;pages.admin.high.availability.management.error.toast.title.inconsistent.credentials';
      errorDescription = 'tkey;pages.admin.high.availability.management.error.toast.message.inconsistent.credentials';
      return this._displayErrorToast(errorTitle, errorDescription);
    } else if (error.status === 422 && error.error.reasonCode === 'INCONSISTENT_VERSIONS') {
      errorTitle = 'tkey;pages.admin.high.availability.management.error.toast.title.inconsistent.versions';
      errorDescription = 'tkey;pages.admin.high.availability.management.error.toast.message.inconsistent.versions';
      return this._displayErrorToast(errorTitle, errorDescription);
    } else {
      return throwError(() => error);
    }
  };

  private _displayErrorToast(title: string, message: string): Observable<any> {
    return new Observable<void>((subscriber) => {
      this.toastService.push(ToastTypes.ERROR, this.smacsIcons.DISASTER_RECOVERY, title, message);
      subscriber.complete();
    });
  }
}
