<app-breadcrumbs>
  <app-breadcrumb-card-item>
    <div [innerHTML]="'tkey;menu.report.cucm_report_builder.description' | translate"></div>
  </app-breadcrumb-card-item>
</app-breadcrumbs>
<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div class="pb-2" *ngIf="!isLoading">
  <legend>
    {{ 'tkey;reporting.custom_reports.shared.header' | translate }}
  </legend>

  <app-cucm-report-builder-form
    [servers]="servers"
    [isNewReport]="isNewReport"
    [report]="report"
    [allReports]="allReports"
  ></app-cucm-report-builder-form>
</div>
