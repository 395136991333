import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VoicemailResource } from '../../../shared/resources/voicemail.resource';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { PinReset } from '../../cisco/public-phone/public-phone-pin-reset/public-phone-pin-reset.component';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { HelpdeskOptionsContext } from '../../shared/contexts/helpdesk-options.context';
import { CiscoHelpdeskOptions, Voicemail } from '../../../shared/models/generated/smacsModels';
import { of, Subscription } from 'rxjs';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { HttpErrorResponse } from '@angular/common/http';
import { PinResetService } from '../../../shared/services/pin-reset.service';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { PinResetComponent } from '../../cisco/pin-reset/pin-reset.component';
import { isEqual } from 'lodash';

@Component({
  selector: 'smacs-search-result-pin-reset',
  templateUrl: './search-result-pin-reset.component.html',
})
export class SearchResultPinResetComponent extends SmacsFormAbstractDirective<PinReset> implements OnInit {
  @ViewChild('vmPin') vmPinResetComponent: PinResetComponent;

  isLoading = true;
  voicemail: Voicemail;
  errorMessage = '';
  formConfig = {
    fields: {
      vmPinReset: {
        dataAutomation: 'voicemail-pin-reset',
        label: 'tkey;pages.pinreset.voicemail',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.PASSWORD,
          placeholder: 'tkey;pages.reset.pin.placeholder',
        }),
        validation: [
          {
            validator: (val: string) => {
              return !val || (!!val && /(^[0-9]+$)/.test(val))
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID;
            },
            message: 'tkey;validators.global.error.pattern',
          },
          {
            validator: () => (this._hasError ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID),
            message: () => this.errorMessage,
          },
        ],
      },
    },
  } as SmacsFormConfig;
  private _resetPinOnNextLogin = false;
  private _hasError = false;
  private _subscriptions = new Subscription();
  private _serverId: string;
  private _smacsIcons = SmacsIcons;

  constructor(
    private _route: ActivatedRoute,
    private _pinResetService: PinResetService,
    private _toastService: ToastService,
    private _voicemailResource: VoicemailResource,
    private _helpdeskOptionsContext: HelpdeskOptionsContext,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._serverId = this._route.snapshot.paramMap.get('serverId');
    const voicemailId = this._route.snapshot.paramMap.get('voicemailId');

    const helpdeskOptSub = this._helpdeskOptionsContext.state$.subscribe((helpdeskOptions) => {
      this._resetPinOnNextLogin = (helpdeskOptions as CiscoHelpdeskOptions).resetPinOnNextLoginEnabled;

      this._voicemailResource.get(Number(this._serverId), voicemailId).subscribe((voicemail) => {
        this.voicemail = voicemail;
        this.isLoading = false;
      });
    });
    this._subscriptions.add(helpdeskOptSub);

    this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<PinReset>) => {
      if (data.old?.vmPinReset && !isEqual(data.new.vmPinReset, data.old.vmPinReset) && this._hasError) {
        this._hasError = false;
      }
    });
  }

  submit() {
    return of(null);
  }

  onSubmit() {
    this._resetVoicemailPin();
  }

  private _resetVoicemailPin() {
    this._pinResetService
      .resetVoicemailWithServerId(
        Number(this._serverId),
        this.voicemail,
        this.entity.vmPinReset,
        this._resetPinOnNextLogin
      )
      .subscribe({
        next: () => {
          this._toastService.push(
            ToastTypes.SUCCESS,
            this._smacsIcons.REFRESH,
            'tkey;pages.pinreset.success.title',
            'tkey;pages.pinreset.voicemail.success.message'
          );
          this._resetVoicemailPinInput();
        },
        error: (error) => {
          if (error.status === 422) {
            this._hasError = true;
            this.errorMessage = this._pinResetService.translateVoicemailPinResetErrorResponse(error);
            this.fieldChannels['vmPinReset'].validateSource.next();
          } else {
            throw new HttpErrorResponse(error);
          }
          this._resetVoicemailPinInput();
        },
      });
  }

  private _resetVoicemailPinInput() {
    this.vmPinResetComponent.isPending = false;
    this.entitySource.next({
      vmPinReset: '',
    });
    this.smacsFormStateService.setIsFormDirty(false);
  }
}
