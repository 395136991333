import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BandwidthE911Dlr, BandwidthE911DlrRef } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class BandwidthE911DlrsResource {
  constructor(protected http: HttpClient) {}

  get(endpointId: string): Observable<BandwidthE911Dlr> {
    return this.http.get<BandwidthE911Dlr>(`/services/microsoft/macs/bandwidth-e911-dlrs/${endpointId}`);
  }

  post(body: BandwidthE911Dlr): Observable<BandwidthE911DlrRef> {
    return this.http.post<BandwidthE911DlrRef>(`/services/microsoft/macs/bandwidth-e911-dlrs`, body);
  }

  put(body: BandwidthE911Dlr): Observable<void> {
    return this.http.put<void>(`/services/microsoft/macs/bandwidth-e911-dlrs/${body.id}`, body);
  }

  delete(endpointId: string): Observable<void> {
    return this.http.delete<void>(`/services/microsoft/macs/bandwidth-e911-dlrs/${endpointId}`);
  }
}
