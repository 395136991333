import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import moment from 'moment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CertificateResource } from '../../../resources/certificate.resource';
import { Subject, Subscription } from 'rxjs';
import { Csr, DistinguishedNameProperties } from '../../../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { GenerateCsrFormComponent } from './generate-csr-form.component';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';

@Component({
  selector: 'app-admin-generate-csr',
  templateUrl: './generate-csr.component.html',
})
export class GenerateCsrComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() csrTimestamp: Subject<string>;
  @Input() displayCsr: Subject<boolean>;
  @Input() existingCsr: Csr;

  @Output() csrGenerated = new EventEmitter<Csr>();

  @ViewChild(GenerateCsrFormComponent) csrFormComponent: GenerateCsrFormComponent;

  distinguishedNameProperties = {} as DistinguishedNameProperties;
  isGeneratingCsr = false;
  csrForm: SmacsFormsUpdate<DistinguishedNameProperties>;
  formValidator = new EventEmitter<boolean>();

  fileUrl: SafeUrl;
  fileName: string;

  smacsIcons = SmacsIcons;

  shouldDisplayCsr = false;

  private _subscriptions = new Subscription();

  constructor(
    private certificateResource: CertificateResource,
    private sanitizer: DomSanitizer,
    private smacsFormStateService: SmacsFormStateService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.existingCsr && !changes.existingCsr.currentValue) {
      this.displayCsr.next(false);
    }
  }

  ngOnInit() {
    this.distinguishedNameProperties.subjectAlternativeNames = [];
    this.certificateResource.getCsrs().subscribe((csrs) => this.updateCsr(csrs));

    const sub = this.displayCsr.asObservable().subscribe((state: boolean) => {
      this.shouldDisplayCsr = state;
    });
    this._subscriptions.add(sub);
  }

  ngAfterViewInit(): void {
    this.csrFormComponent.formSubmit$.subscribe(() => {
      this.generateCsr();
    });
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  setDisplayCsr(display: boolean): void {
    this.displayCsr.next(display);
  }

  buildFileUrl(csr: string) {
    return this.sanitizer.bypassSecurityTrustUrl(`data:text/plain;charset=utf8,${encodeURIComponent(csr)}`);
  }

  buildFileName(timestamp: string) {
    return timestamp ? `smacs_${timestamp.replace(' ', '-')}.csr` : 'smacs.csr';
  }

  onFormUpdate($event: SmacsFormsUpdate<DistinguishedNameProperties>) {
    this.csrForm = $event;
  }

  generateCsr() {
    this.formValidator.emit(true);
    if (this.csrForm.valid === SmacsFormsValidationState.INVALID) {
      return;
    }
    this.isGeneratingCsr = true;
    this.certificateResource.postCsr(this.csrForm.new).subscribe((csrs) => {
      this.updateCsr(csrs);
      this.isGeneratingCsr = false;
      this.smacsFormStateService.setIsFormDirty(false);
    });
  }

  updateCsr(csrs: Csr[]) {
    const csr = csrs[0];
    this.existingCsr = csr;

    if (csr) {
      this.buildCsrTimestamp(csr.timestamp);
      this.fileUrl = this.buildFileUrl(csr.csr);
      this.fileName = this.buildFileName(csr.timestamp);
      this.displayCsr.next(true);
      this.isGeneratingCsr = false;
      this.csrGenerated.emit(csr);
    }
  }

  buildCsrTimestamp(rawTimestamp: string) {
    const dateUtc = moment.utc(rawTimestamp.replace('.', ':'));
    const timestamp = moment(dateUtc).local().format('YYYY-MM-DD HH:mm');
    this.csrTimestamp.next(timestamp);
  }
}
