import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AssignExtensionChoice,
  BulkProvisioningFormData,
  BulkProvisioningTab,
  SELECTABLE_SERVICES,
  SiteServices,
} from '../bulk-provisioning.component';
import { parse } from 'papaparse';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import {
  DeviceModelMetadata,
  EndUserProvisioningOptions,
  SiteSummary,
  UcMetadataCache,
} from '../../../../shared/models/generated/smacsModels';
import { BulkEndUserProvisioningResource } from '../../../resources/bulk-end-user-provisioning.resource';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { get, pick } from 'lodash';
import { SafeUrl } from '@angular/platform-browser';
import { ToastService } from '../../../../shared/services/toast.service';
import { EMPTY, Observable, Subscription } from 'rxjs';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFileUploadConfig } from '../../../../forms/fields/file-upload/smacs-file-upload.component';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { UcMetadataCacheContext } from '../../../../shared/contexts/uc-metadata-cache.context';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { Nvp } from '../../../../shared/models/nvp';

class BulkRow {
  username: string;
  usernameError?: string;
  primaryExtension: string;
  primaryExtensionError?: string;
  deskphone: string;
  deskphoneError?: string;
  macAddress: string;
  macAddressError?: string;
}

export interface BulkProvisioningCsvFile {
  Username: string;
  'Primary Extension': string;
  'Deskphone Model': string;
  'MAC Address': string;
}

@Component({
  selector: 'smacs-bulk-provisioning-upload-csv',
  templateUrl: './bulk-provisioning-upload-csv.component.html',
  styleUrls: ['./bulk-provisioning-upload-csv.component.scss'],
})
export class BulkProvisioningUploadCsvComponent
  extends SmacsFormAbstractDirective<{ file: File }>
  implements AfterViewInit, OnInit, OnChanges, OnDestroy
{
  @Input() tabSelectionEmitter: EventEmitter<BulkProvisioningTab>;
  @Input() bulkProvisioningConfig: BulkProvisioningFormData;
  @Input() csvTemplateUrl: SafeUrl;
  @Output() jobSubmitted = new EventEmitter<void>();

  isSubmitting = false;
  isLoadingFile = false;
  fileContents = [] as BulkRow[];
  hasError = false;
  clearFile = new EventEmitter<void>();
  smacsIcons = SmacsIcons;

  formConfig = {
    fields: {
      csv: {
        label: 'tkey;zero_touch.bulk_provisioning.upload_csv.csv_file.label',
        dataAutomation: 'bulk-provisioning-upload-csv-csv-file-input',
        required: true,
        componentConfig: new SmacsFileUploadConfig({
          acceptedFileExtensions: { acceptedExtensions: '.csv,.txt' },
        }),
      },
    },
  } as SmacsFormConfig;

  private _subs = new Subscription();
  private _siteSummary: SiteSummary;
  private _ucMetadataCache: UcMetadataCache;
  private _deviceModelMetadataMap: { [index: string]: DeviceModelMetadata };

  constructor(
    private siteSummaryContext: SiteSummaryContext,
    private bulkProvisioningResource: BulkEndUserProvisioningResource,
    private toastService: ToastService,
    private ucMetadataCacheContext: UcMetadataCacheContext,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    const tabSub = this.tabSelectionEmitter.subscribe((tab) => {
      if (tab === BulkProvisioningTab.TABLE) {
        this._setDeviceModelMetadataMap();
        this.fileContents.forEach(this._validateRow);
        this._setHasError();
      }
    });
    this._subs.add(tabSub);

    const ucMetadataContextSub = this.ucMetadataCacheContext.state$.subscribe((state: UcMetadataCache) => {
      this._ucMetadataCache = state;
    });
    this._subs.add(ucMetadataContextSub);

    const siteSummarySub = this.siteSummaryContext.state$.subscribe((siteSummary) => {
      this._siteSummary = siteSummary;
    });
    this._subs.add(siteSummarySub);
  }

  ngOnChanges(changes: SimpleChanges) {
    const newConfig = get(changes, ['bulkProvisioningConfig', 'currentValue']) as BulkProvisioningFormData;

    if (newConfig) {
      this.bulkProvisioningConfig = newConfig;
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    this.fieldComponents
      .find((field) => field.fieldId === 'csv')
      .smacsFormsUpdate$.subscribe((update: SmacsFormsUpdate<File>) => {
        this.hasError = false;
        this.fileContents.length = 0;

        if (update.valid === SmacsFormsValidationState.VALID) {
          this._updateTable(update.new);
        } else {
          this.isLoadingFile = update.valid === SmacsFormsValidationState.PENDING;
        }
      });
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private _updateTable(file: File) {
    if (file) {
      this._setDeviceModelMetadataMap();
      parse<BulkProvisioningCsvFile>(file, {
        header: true,
        skipEmptyLines: true,
        complete: () => {
          this.isLoadingFile = false;
          this._setHasError();
        },
        step: (row) => {
          const data = row.data as unknown as BulkProvisioningCsvFile;
          const rowContent = {
            username: data['Username']?.trim(),
            primaryExtension: data['Primary Extension']?.trim(),
            deskphone: data['Deskphone Model']?.trim(),
            macAddress: data['MAC Address']?.trim(),
          } as BulkRow;

          this._validateRow(rowContent);

          this.fileContents.push(rowContent);
        },
      });
    }
  }

  goToGeneralView() {
    this.clearFile.emit();
    this.tabSelectionEmitter.emit(BulkProvisioningTab.GENERAL);
  }

  protected submit() {
    return new Observable((subscriber) => {
      if (this.hasError || this.isSubmitting || this.fileContents.length === 0) {
        subscriber.error(EMPTY);
        subscriber.complete();
      }

      this.isSubmitting = true;
      const payload = this.fileContents.map((row) => {
        return {
          username: row.username,
          siteId: Number(this.bulkProvisioningConfig.site),
          dn: this.bulkProvisioningConfig.services.primaryExtension
            ? {
                dialPlanGroupIds: this.bulkProvisioningConfig.dnGroups?.length
                  ? this.bulkProvisioningConfig.dnGroups.map((group) => Number(group))
                  : null,
                extension: !this.bulkProvisioningConfig.dnGroups?.length ? row.primaryExtension : null,
              }
            : null,
          deskphone: this.bulkProvisioningConfig.services.deskphone
            ? {
                phoneModel: row.deskphone,
                macAddress: row.macAddress,
              }
            : null,
          snrProfile: this.bulkProvisioningConfig.services.snr,
          ...pick<SiteServices>(
            this.bulkProvisioningConfig.services,
            SELECTABLE_SERVICES.filter((service) => !['primaryExtension', 'deskphone', 'snr'].includes(service))
          ),
        };
      }) as EndUserProvisioningOptions[];

      let auditTag: Nvp;
      if (this.bulkProvisioningConfig.auditTag) {
        auditTag = { name: 'bulkJobTag', value: this.bulkProvisioningConfig.auditTag };
      }

      this.bulkProvisioningResource.post(payload, auditTag).subscribe(() => {
        this.isSubmitting = false;
        this.toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.AUTOMATION,
          'tkey;zero_touch.bulk_provisioning.upload_csv.success_toast.title',
          'tkey;zero_touch.bulk_provisioning.upload_csv.success_toast.message'
        );
        this.jobSubmitted.emit();
        this.tabSelectionEmitter.emit(BulkProvisioningTab.GENERAL);
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }

  private _setDeviceModelMetadataMap() {
    const cucmServerId = this.siteSummaryContext.findCucmServerIdForSite(
      this._siteSummary,
      Number(this.bulkProvisioningConfig.site)
    );
    this._deviceModelMetadataMap = this._ucMetadataCache.cucmDeviceModelMetadataCache[cucmServerId];
  }

  private _setHasError = () => {
    this.hasError = this.fileContents.some(
      (row) => !!(row.usernameError || row.primaryExtensionError || row.deskphoneError || row.macAddressError)
    );
  };

  private _validateRow = (row: BulkRow) => {
    delete row.usernameError;
    delete row.primaryExtensionError;
    delete row.deskphoneError;
    delete row.macAddressError;

    const ALPHA_NUMERIC_DOT_AND_DASH_DELIMITED_REGEX = /^(([A-Z0-9]+)([.-]))*([A-Z0-9]+)$/i;
    const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!row.username) {
      row.usernameError = 'tkey;zero_touch.bulk_provisioning.upload_csv.username_error.required';
    } else if (!ALPHA_NUMERIC_DOT_AND_DASH_DELIMITED_REGEX.test(row.username) && !EMAIL_REGEX.test(row.username)) {
      row.usernameError = 'tkey;zero_touch.bulk_provisioning.upload_csv.username_error.pattern';
    }

    if (
      this.bulkProvisioningConfig.services.primaryExtension &&
      this.bulkProvisioningConfig.assignExtension === AssignExtensionChoice.CSV
    ) {
      if (!row.primaryExtension) {
        row.primaryExtensionError = 'tkey;zero_touch.bulk_provisioning.upload_csv.primary_extension_error.required';
      } else if (!/^\+?[0-9]+$/g.test(row.primaryExtension)) {
        row.primaryExtensionError = 'tkey;zero_touch.bulk_provisioning.upload_csv.primary_extension_error.pattern';
      }
    }

    if (this.bulkProvisioningConfig.services.deskphone) {
      if (!row.deskphone) {
        row.deskphoneError = 'tkey;zero_touch.bulk_provisioning.upload_csv.deskphone_model_error.required';
      } else if (!this._deviceModelMetadataMap[row.deskphone]) {
        row.deskphoneError = 'tkey;zero_touch.bulk_provisioning.upload_csv.deskphone_model_error.does_not_exist';
      }

      if (!row.macAddress) {
        row.macAddressError = 'tkey;zero_touch.bulk_provisioning.upload_csv.mac_address_error.required';
      } else if (!/^[0-9a-fA-F]{12}$/g.test(row.macAddress)) {
        row.macAddressError = 'tkey;zero_touch.bulk_provisioning.upload_csv.mac_address_error.pattern';
      }
    }
  };
}
