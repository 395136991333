import { Observable } from 'rxjs';
import { Microsoft365LicensesFieldConfig } from '../../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class MicrosoftLicensesConfigsResource {
  constructor(private http: HttpClient) {}

  getLicensesConfig(): Observable<Microsoft365LicensesFieldConfig> {
    return this.http.get<Microsoft365LicensesFieldConfig>(`/services/microsoft/field-configs/365-licenses`);
  }
}
