import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import {
  CallingType,
  JobState,
  MicrosoftBulkJobDraft,
  MicrosoftBulkJobDraftRef,
  MicrosoftBulkJobStatus,
  MicrosoftBulkJobUserDraft,
  MicrosoftDialPlanFieldConfig,
} from '../../../shared/models/generated/smacsModels';
import { MicrosoftDialPlansResource } from '../../../helpdesk/shared/resources/ms-dial-plans.resource';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { MicrosoftBulkProvisioningPollingContext } from '../../../shared/contexts/microsoft-bulk-provisioning-polling.context';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../shared/services/toast.service';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles } from '../../../button/button.component';
import { MicrosoftBulkProvisioningFormComponent } from './form/microsoft-bulk-provisioning-form.component';
import { ZpmBulkProvisioningResource } from '../../resources/zpm-bulk-provisioning.resource';
import { TranslateService } from '@ngx-translate/core';
import { ZpmUserPhotoContext } from '../../../shared/contexts/zpm-user-photo.context';
import { ZpmBulkProvisioningEntityTableContainerAbstractComponent } from './status-table/zpm-bulk-provisioning-status-entity-table-abstract.component';
import { ZiroFile } from '../../../shared/file-uploader/file-uploader.component';
import { Nvp } from '../../../shared/models/nvp';
import { ActivatedRoute, Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { MicrosoftBulkProvisioningDraftsContext } from '../../../shared/contexts/microsoft-bulk-provisioning-drafts.context';
import { EntityTableContentRow } from '../../../shared/entity-table/entity-table.models';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { forOwn } from 'lodash';
import { SmacsFormsValidationState } from '../../../forms/smacs-forms-models';

export interface MicrosoftBulkProvisioningFormData {
  dialPlanGroup: string;
  addUpn: string;
  file: ZiroFile[];
  bulkJobName: string;
  draftJobId?: number;
}

@Component({
  selector: 'smacs-microsoft-bulk-provisioning',
  templateUrl: './microsoft-bulk-provisioning.component.html',
  styleUrls: ['../../../admin/admin-page.scss', './microsoft-bulk-provisioning.component.scss'],
  providers: [MicrosoftBulkProvisioningPollingContext, ZpmBulkProvisioningResource],
})
export class MicrosoftBulkProvisioningComponent
  extends ZpmBulkProvisioningEntityTableContainerAbstractComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MicrosoftBulkProvisioningFormComponent) formComponent: MicrosoftBulkProvisioningFormComponent;

  jobState = JobState;
  smacsIcons = SmacsIcons;
  isLoading = true;
  isRunning = false;
  isSaving = false;
  isComplete = false;
  microsoftDialPlanFieldConfig: MicrosoftDialPlanFieldConfig[];
  draftFormData: MicrosoftBulkProvisioningFormData;
  tableRows: EntityTableContentRow[] = [];
  drafts: MicrosoftBulkJobDraft[] = [];

  private _jobId: string;
  private _isNew = false;
  private _subscriptions = new Subscription();

  constructor(
    protected _translateService: TranslateService,
    private breadcrumbsService: BreadcrumbsService,
    private microsoftDialPlansResource: MicrosoftDialPlansResource,
    private microsoftBulkProvisioningPollingContext: MicrosoftBulkProvisioningPollingContext,
    private toastService: ToastService,
    private _bottomNavService: BottomNavService,
    private _zpmBulkProvisioningResource: ZpmBulkProvisioningResource,
    private _zpmUserPhotoContext: ZpmUserPhotoContext,
    private _route: ActivatedRoute,
    private _router: Router,
    private _microsoftBulkProvisioningDraftsContext: MicrosoftBulkProvisioningDraftsContext,
    private _smacsModalService: SmacsModalService
  ) {
    super(_translateService);
  }

  ngOnInit() {
    this._jobId = this._route.snapshot.params['jobId'];
    this._isNew = this._router.url.endsWith('new');

    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;automate.zpm.bulk_provisioning.breadcrumb',
        url: '/automate/microsoft/bulk-provisioning',
        routerLink: true,
      },
      { label: '' },
    ]);

    this._listenToPolling();

    if (this._jobId) {
      this._initExistingJob();
    } else {
      this._initNewJob();
    }
  }

  private _initExistingJob() {
    const sub = combineLatest([
      this.microsoftDialPlansResource.getAllDialPlanGroups(),
      this._microsoftBulkProvisioningDraftsContext.state$,
    ]).subscribe(([dialPlanGroupsConfig, drafts]: [MicrosoftDialPlanFieldConfig[], MicrosoftBulkJobDraft[]]) => {
      this.drafts = drafts;
      this.microsoftDialPlanFieldConfig = dialPlanGroupsConfig;

      if (!this.isLoading) {
        return;
      }

      const draft: MicrosoftBulkJobDraft = drafts.find(
        (d: MicrosoftBulkJobDraft) => d.bulkJobId.toString() === this._jobId.toString()
      );

      const csvString =
        'User Principal Name,Number\n' +
        draft?.users
          .map((user: MicrosoftBulkJobUserDraft) => {
            const number = user.phoneNumberAssignment.lineUriExtension
              ? user.phoneNumberAssignment.lineUriExtension
              : user.phoneNumberAssignment.lineUri
              ? user.phoneNumberAssignment.lineUri
              : '';

            return `${user.userPrincipalName},${number}`;
          })
          .join('\n');
      const blob = new Blob([csvString], { type: 'text/csv' });
      const file = new File([blob], `zpm_bulk_provisioning_job-${this._jobId}.csv`);

      const bulkZiroFile: ZiroFile = {
        id: `zpm_bulk_provisioning_job-${this._jobId}.csv`,
        file: file,
      };

      this.draftFormData = {
        dialPlanGroup: draft.dialPlanGroupId,
        addUpn: '',
        file: [bulkZiroFile],
        bulkJobName: draft.bulkJobName,
        draftJobId: draft.bulkJobId,
      };

      const dialPlan: MicrosoftDialPlanFieldConfig = dialPlanGroupsConfig.find(
        (dpg: MicrosoftDialPlanFieldConfig) => dpg.id.toString() === draft.dialPlanGroupId
      );

      this.tableRows = draft?.users.map((user: MicrosoftBulkJobUserDraft) => {
        const number =
          dialPlan.callingType === CallingType.EXTENSION
            ? user.phoneNumberAssignment.lineUriExtension || ''
            : user.phoneNumberAssignment.lineUri || '';
        return {
          content: {
            upn: user.userPrincipalName,
            number: number,
            voicePolicies: user.voicePolicies,
          },
          rowId: user.userPrincipalName || '' + `${Math.floor(Math.random() * 10000)}`,
        };
      });

      this.breadcrumbsService.updateBreadcrumbs([
        {
          label: 'tkey;automate.zpm.bulk_provisioning.breadcrumb',
          url: '/automate/microsoft/bulk-provisioning',
          routerLink: true,
        },
        { label: draft.bulkJobName },
      ]);
      this._initBottomNav();
      this.isLoading = false;
    });

    this._subscriptions.add(sub);
  }

  private _initNewJob() {
    const sub = combineLatest([
      this.microsoftDialPlansResource.getAllDialPlanGroups(),
      this._microsoftBulkProvisioningDraftsContext.state$,
    ]).subscribe(([dialPlanGroupsConfig, drafts]: [MicrosoftDialPlanFieldConfig[], MicrosoftBulkJobDraft[]]) => {
      if (!this._jobId) {
        this.drafts = drafts;
        this.microsoftDialPlanFieldConfig = dialPlanGroupsConfig;

        this.draftFormData = {
          dialPlanGroup: undefined,
          addUpn: '',
          file: undefined,
          bulkJobName: '',
        };
        this._initBottomNav();
        this.isLoading = false;
      }
    });

    this._subscriptions.add(sub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this.microsoftBulkProvisioningPollingContext.stopPolling();
  }

  private _listenToPolling() {
    this.microsoftBulkProvisioningPollingContext.state$.subscribe(this._handlePolling);
    this.microsoftBulkProvisioningPollingContext.startPolling();
  }

  private _handlePolling = (microsoftBulkJobStatus: MicrosoftBulkJobStatus) => {
    this.bulkJobStatus = microsoftBulkJobStatus;

    if (this.isRunning && this.bulkJobStatus.jobStatus.jobState === JobState.IDLE) {
      this.isComplete = true;
      this._bottomNavService.setButtonPendingState('bulk-run-now', false);
      this.toastService.push(
        ToastTypes.SUCCESS,
        this.smacsIcons.AUTOMATION,
        'tkey;pages.zero_touch.job.completed',
        'tkey;pages.zero_touch.bulk_provisioning.title'
      );
    }
    this.isRunning =
      this.bulkJobStatus.jobStatus.jobState === JobState.RUNNING ||
      this.bulkJobStatus.jobStatus.jobState === JobState.QUEUED;

    if (this.isRunning) {
      this._bottomNavService.setButtonDisabledState(
        'bulk-run-now',
        true,
        'tkey;automate.zpm.bulk_provisioning.drafts.in_progress.run_now.disabled.tooltip'
      );
    } else if (!this.formComponent?.isFormDirty() && !this.isSaving) {
      this._bottomNavService.setButtonDisabledState('bulk-run-now', false);
    }

    if (this.isRunning) {
      const upnsInStatus = [
        ...microsoftBulkJobStatus.jobDetailedStatus.inProgressUser.map((user) => user.userPrincipalName),
        ...microsoftBulkJobStatus.jobDetailedStatus.remainingUsers.map((user) => user.userPrincipalName),
        ...microsoftBulkJobStatus.jobDetailedStatus.completedUsers.map((user) => user.userPrincipalName),
        ...microsoftBulkJobStatus.jobDetailedStatus.failedUsers.map((user) => user.userPrincipalName),
      ];

      this._zpmUserPhotoContext.initUserPhotos(upnsInStatus);

      const userPhotos = this._zpmUserPhotoContext.getUserPhotos();
      this.tableRows = this.getTableRows(upnsInStatus, userPhotos);
      this.isLoadingTable = false;
    }
  };

  private _deleteClicked() {
    const draftName = this.formComponent.getDraftName();

    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        promptBody: this._translateService.instant('tkey;automate.zpm.bulk_provisioning.drafts.modal.delete.body', {
          groupName: draftName,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteDraft(this._jobId, draftName);
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteDraft(draftId: string, draftName: string): Observable<void> {
    return this._microsoftBulkProvisioningDraftsContext.delete(draftId).pipe(
      tap(() => {
        this.toastService.pushDeleteToast('tkey;automate.zpm.bulk_provisioning.drafts.toast', draftName);
        this._router.navigate(['/automate/microsoft/bulk-provisioning']);
      })
    );
  }

  private _isValidForSave(): boolean {
    const noValidationFields: string[] = ['addUpn', 'file'];
    let isValid = true;

    this.formComponent._validateAndSubmitSource.next(true);
    Object.keys(this.formComponent.fieldChannels).forEach((key: string) => {
      if (!noValidationFields.includes(key)) {
        this.formComponent.fieldChannels[key].validateSource.next();
      }
    });

    forOwn(this.formComponent.validationFlags, (val: number, key: string) => {
      if (!noValidationFields.includes(key) && val === SmacsFormsValidationState.INVALID) {
        isValid = false;
      }
    });

    return isValid;
  }

  private _save() {
    if (!this._isValidForSave()) {
      this._bottomNavService.setBottomNavValidationError(true);
      return;
    }

    this.isSaving = true;

    this._bottomNavService.setBottomNavValidationError(false);
    this._bottomNavService.setButtonPendingState('bulk-save', true);
    this._bottomNavService.setButtonDisabledState('bulk-run-now', true);
    this._bottomNavService.setButtonDisabledState('bulk-cancel', true);
    this._bottomNavService.setButtonDisabledState('bulk-delete', true);

    const payload = this.formComponent.getDraft();

    if (this._isNew) {
      this._microsoftBulkProvisioningDraftsContext.create(payload).subscribe((data: MicrosoftBulkJobDraftRef) => {
        this._jobId = data.id;
        this._postSave();
      });
    } else {
      this._microsoftBulkProvisioningDraftsContext
        .update({
          ...payload,
          bulkJobId: parseInt(this._jobId),
        })
        .subscribe(() => {
          this._postSave();
        });
    }
  }

  private _postSave() {
    this.formComponent.setIsFormDirty(false);
    this.toastService.push(
      ToastTypes.SUCCESS,
      this.smacsIcons.AUTOMATION,
      'tkey;shared.toast.save.success.title',
      `${this._translateService.instant('tkey;automate.zpm.bulk_provisioning.drafts.toast')} - <strong>${
        this.formComponent.entity.bulkJobName
      }</strong>`
    );

    if (this._isNew) {
      this._isNew = false;
      this._router.navigate([`/automate/microsoft/bulk-provisioning/${this._jobId}`]);
    } else {
      this._bottomNavService.setButtonPendingState('bulk-save', false);
      this._bottomNavService.setButtonDisabledState('bulk-run-now', false);
      this._bottomNavService.setButtonDisabledState('bulk-cancel', false);
      this._bottomNavService.setButtonDisabledState('bulk-delete', false);
    }

    this.isSaving = false;
  }

  private _runNow() {
    this.formComponent._validateAndSubmitSource.next(true);
    this.formComponent.validateAllFields();

    if (!this.formComponent.isFormValid()) {
      this._bottomNavService.setBottomNavValidationError(true);
      return false;
    }

    this.tableRows = [];
    this.isLoadingTable = true;
    this._bottomNavService.setBottomNavValidationError(false);
    this._bottomNavService.setButtonPendingState('bulk-run-now', true);
    this._bottomNavService.setButtonDisabledState('bulk-save', true);
    this._bottomNavService.setButtonDisabledState('bulk-cancel', true);
    this._bottomNavService.setButtonDisabledState('bulk-delete', true);

    const auditTag: Nvp = { name: 'jobName', value: this.formComponent.entity.bulkJobName };
    const payload = this.formComponent.getProvisioningOptions();

    this._zpmBulkProvisioningResource.post(payload, auditTag).subscribe(() => {
      this.toastService.push(
        ToastTypes.SUCCESS,
        this.smacsIcons.AUTOMATION,
        'tkey;automate.zpm.bulk_provisioning.success_toast.title',
        'tkey;automate.zpm.bulk_provisioning.success_toast.message'
      );
      this._router.navigate(['/automate/microsoft/bulk-provisioning']);
    });
  }

  private _initBottomNav() {
    let buttons: BottomNavButton[] = [
      {
        id: 'bulk-run-now',
        buttonClass: ButtonStyles.INFO,
        icon: SmacsIcons.RUN,
        label: 'tkey;automate.zpm.bulk_provisioning.run_now.label',
        dataAutomation: 'bulk-run-now',
        cb: () => this._runNow(),
        state: {
          buttonDisableState: {
            disabled: true,
            tooltipKey:
              this.bulkJobStatus?.jobStatus?.jobState !== JobState.IDLE
                ? 'tkey;automate.zpm.bulk_provisioning.drafts.in_progress.run_now.disabled.tooltip'
                : 'tkey;automate.zpm.bulk_provisioning.bottom_nav.run_now.loading.disabled.tooltip',
          },
          tooltipVisible: true,
        },
      },
      {
        id: 'bulk-cancel',
        buttonClass: ButtonStyles.DEFAULT,
        label: 'tkey;global.button.cancel.text',
        dataAutomation: 'bulk-cancel',
        cb: () => {
          this._router.navigate(['/automate/microsoft/bulk-provisioning']);
        },
      },
      {
        id: 'bulk-delete',
        buttonClass: ButtonStyles.DANGER,
        icon: SmacsIcons.DELETE,
        label: 'tkey;global.button.delete.text',
        dataAutomation: 'bulk-delete',
        cb: () => this._deleteClicked(),
      },
      {
        id: 'bulk-save',
        buttonClass: ButtonStyles.PRIMARY,
        icon: SmacsIcons.OK,
        label: 'tkey;global.button.save.text',
        dataAutomation: 'bulk-save',
        cb: () => this._save(),
      },
    ];

    if (this._isNew) {
      const existingOnlyButtons = ['bulk-run-now', 'bulk-delete'];
      buttons = buttons.filter((button: BottomNavButton) => !existingOnlyButtons.includes(button.id));
    }

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttons));
  }
}
