<footer *ngIf="!isLoading" [class]="cssClasses">
  <ng-content></ng-content>
  <div class="navbar-text">
    <div [innerHtml]="'tkey;company.footer' | translate : { year: year }" data-automation="footer-year"></div>

    <a id="login-support-link" [routerLink]="'/support'" target="_blank" data-automation="login-support-link">
      {{ 'tkey;footer.support' | translate }}
    </a>
  </div>
</footer>
