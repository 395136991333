import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { Component } from '@angular/core';
import { SmacsFieldComponentConfig, SmacsFormsValidationState } from '../../smacs-forms-models';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import { isNil } from 'lodash';

export interface MultiCheckboxOptionConfig {
  label: string;
  hidden?: () => boolean;
  disabled?: () => boolean;
  disabledTooltip?: () => string;
  tooltip?: () => string;
  additionalContent?: string;
  alignLeft?: boolean;
}

export class SmacsMultiCheckboxConfig extends SmacsFieldComponentConfig {
  constructor(public config: { multiCheckboxOptionConfig: MultiCheckboxOptionConfig[] }) {
    super();
  }
}

@Component({
  selector: 'smacs-multi-checkbox',
  templateUrl: './smacs-multi-checkbox.component.html',
  styleUrls: ['./smacs-multi-checkbox.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsMultiCheckboxComponent }],
})
export class SmacsMultiCheckboxComponent<T> extends SmacsFieldAbstractDirective<T, T, SmacsMultiCheckboxConfig> {
  objectKeys = Object.keys;
  optionConfigs: MultiCheckboxOptionConfig[];
  id = new Date().valueOf();

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  applyComponentConfig = ({ config }: SmacsMultiCheckboxConfig) => {
    this.optionConfigs = isNil(config.multiCheckboxOptionConfig)
      ? this.optionConfigs
      : config.multiCheckboxOptionConfig;
  };

  isDisabled(i: number) {
    return this.optionConfigs[i]?.disabled ? this.optionConfigs[i].disabled() : false;
  }

  isHidden(i: number) {
    return this.optionConfigs[i].hidden ? this.optionConfigs[i].hidden() : false;
  }

  hasTooltip(i: number): boolean {
    return this.getTooltip(i) && this.getTooltip(i) !== '' ? true : false;
  }

  getTooltip(i: number): string {
    return this.optionConfigs[i]?.disabledTooltip &&
      (!this.optionConfigs[i]?.tooltip || this.optionConfigs[i].tooltip() === '')
      ? this.optionConfigs[i]?.disabledTooltip()
      : this.optionConfigs[i]?.tooltip && this.optionConfigs[i].tooltip() !== ''
      ? this.optionConfigs[i]?.tooltip()
      : '';
  }

  exists(val: T): SmacsFormsValidationState {
    return !val || Object.values(val).includes(true)
      ? SmacsFormsValidationState.VALID
      : SmacsFormsValidationState.INVALID;
  }
}
