import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subscriber, Subscription } from 'rxjs';
import { ToastService } from '../../../shared/services/toast.service';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { DeviceUtilizationResource } from '../resources/device-utilization-resource';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { CurrentUser, DeviceUtilization, JobState, Role } from '../../../shared/models/generated/smacsModels';
import { map, switchMap, tap } from 'rxjs/operators';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { DatatableColumn, DatatableRow } from '../../datatable/datatable.component';
import { SecondsToHumanReadablePipe } from '../services/seconds-to-human-readable.pipe';
import { NewDevicePipe } from '../services/new-device.pipe';
import { PublicDevicePipe } from '../services/public-device.pipe';
import moment from 'moment';
import { DatatableFilterService } from '../../datatable/datatable-filter.service';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { DaysAgoPipe } from '../../../shared/pipes/days-ago.pipe';
import { AuthenticationContext } from '../../../shared/contexts/authentication.context';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { DateAgoPipe } from '../../../shared/pipes/date-ago.pipe';
import { DeviceSettingsResource } from '../../../admin/resources/device-settings.resource';

interface DeviceUtilizationRow extends DatatableRow {
  deviceName: string;
  ownerId: string;
  duration: number;
  modelName: string;
  devicePool: string;
  registrationStatus: string;
  lastUsed: string;
  recentlyCreated: boolean;
  // the transformed properties are used only for filtering
  lastUsedTransformed: string;
  durationTransformed: string;
}

@Component({
  selector: 'app-device-utilization',
  templateUrl: './device-utilization.component.html',
  styleUrls: ['../../reporting.scss'],
})
export class DeviceUtilizationComponent implements OnInit, OnDestroy {
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  isSearching = true;
  isRefreshing = false;
  isAdmin: boolean;
  timeout: number;
  callRetentionDays: number;
  tableDataAutomation = 'device-utilization-datatable';
  showCustomAlert = false;

  columns = [
    {
      name: 'deviceName',
      label: 'tkey;reporting.device_utilization.device_name.header',
      pipe: this.newDevicePipe,
      pipeArgs: ['recentlyCreated'],
    },
    {
      name: 'ownerId',
      label: 'tkey;reporting.device_utilization.owner.header',
      sortFn: (a: string, b: string) => {
        if (a === b) {
          return 0;
        } else if (b && (!a || a === 'Public Phone')) {
          return -1;
        } else if (a && (!b || b === 'Public Phone')) {
          return 1;
        } else {
          return a.localeCompare(b);
        }
      },
    },
    {
      name: 'duration',
      label: 'tkey;reporting.device_utilization.duration.header',
      pipe: this.secondsToHumanReadablePipe,
    },
    {
      name: 'modelName',
      label: 'tkey;reporting.device_utilization.model.header',
    },
    {
      name: 'devicePool',
      label: 'tkey;reporting.device_utilization.pool.header',
    },
    {
      name: 'registrationStatus',
      label: 'tkey;reporting.device_utilization.registration_status.header',
    },
    {
      name: 'lastUsed',
      label: 'tkey;reporting.device_utilization.last_used.header',
      sortFn: (a: string, b: string) => {
        if (a === b) {
          return 0;
        } else if (!a) {
          return -1;
        } else if (!b) {
          return 1;
        } else {
          return moment(b).diff(a);
        }
      },
      pipe: this.daysAgoPipe,
      columnCharacterWidth: 14,
    },
  ] as DatatableColumn<DeviceUtilizationRow>[];
  registrationStatusOptions = ['Registered', 'Unregistered', 'Not Found'];
  deviceModelOptions = [] as string[];
  devicePoolOptions = [] as string[];
  data = [] as DeviceUtilizationRow[];
  lastRunTime = '';
  nextRunTime = '';

  private _subs = new Subscription();

  constructor(
    private translateService: TranslateService,
    private toastService: ToastService,
    private deviceUtilizationResource: DeviceUtilizationResource,
    private bottomNavService: BottomNavService,
    private breadcrumbsService: BreadcrumbsService,
    private secondsToHumanReadablePipe: SecondsToHumanReadablePipe,
    private daysAgoPipe: DaysAgoPipe,
    private newDevicePipe: NewDevicePipe,
    private publicDevicePipe: PublicDevicePipe,
    private adminDeviceSettingsResource: DeviceSettingsResource,
    private datatableFilterService: DatatableFilterService,
    private smacsModalService: SmacsModalService,
    private authenticationContext: AuthenticationContext,
    private dateAgoPipe: DateAgoPipe
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;menu.report.device_utilization' }]);

    const reportObservable = this._waitForRefreshToFinish().pipe(switchMap(() => this.searchUtilizations()));

    const authenticationObservable = this.authenticationContext.state$.pipe(
      map((user: CurrentUser) => {
        this.isAdmin = this.authenticationContext.userIsAtLeast(user, Role.S8_ADMIN);
      })
    );

    const settingsObservable = this.adminDeviceSettingsResource.getAdminDeviceSettings().pipe(
      map((settings) => {
        this.callRetentionDays = settings.cdrRetentionInDays;
      })
    );

    this._subs.add(
      combineLatest([reportObservable, authenticationObservable, settingsObservable]).subscribe(() => {
        this.isSearching = false;
        this._initBottomNav();
        this._setBottomNavTimestamp();
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
    this.breadcrumbsService.clearBreadcrumbs();
    clearTimeout(this.timeout);
  }

  onGetFileClicked() {
    this._setExportPending(true);
    this.deviceUtilizationResource.export().subscribe(() => {
      this._setExportPending(false);
      this.toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.EXPORT,
        'tkey;reporting.xlsx_export.downloaded_toast.title',
        'tkey;reporting.xlsx_export.downloaded_toast.message'
      );
    });
  }

  // public so that the stage can use it
  searchUtilizations(): Observable<void> {
    this.isSearching = true;
    return this.deviceUtilizationResource.get().pipe(
      map((deviceUtilizationSearchResults) => {
        this.data = deviceUtilizationSearchResults.map((deviceUtilization: DeviceUtilization) => {
          if (deviceUtilization.modelName && this.deviceModelOptions.indexOf(deviceUtilization.modelName) === -1) {
            this.deviceModelOptions.push(deviceUtilization.modelName);
          }
          if (deviceUtilization.devicePool && this.devicePoolOptions.indexOf(deviceUtilization.devicePool) === -1) {
            this.devicePoolOptions.push(deviceUtilization.devicePool);
          }

          return {
            deviceName: deviceUtilization.deviceName,
            ownerId: this.publicDevicePipe.transform(deviceUtilization.ownerId, deviceUtilization.deviceType),
            duration: deviceUtilization.totalDuration,
            modelName: deviceUtilization.modelName || '',
            devicePool: deviceUtilization.devicePool,
            registrationStatus: deviceUtilization.registrationStatus,
            lastUsed: deviceUtilization.lastUsedDate || '',
            recentlyCreated: !!deviceUtilization.recentlyCreatedDate,
            lastUsedTransformed: this.daysAgoPipe.transform(deviceUtilization.lastUsedDate),
            durationTransformed: this.secondsToHumanReadablePipe.transform(deviceUtilization.totalDuration),
            hardcodedTooltips: {
              lastUsed: deviceUtilization.lastUsedDate || null,
            },
          };
        });
      })
    );
  }

  getRefresh(runTime: string): string {
    if (!runTime) {
      return '';
    }

    const refreshUtc = moment.utc(runTime).format('YYYY-MM-DD hh:mm A');
    const refreshLocal = moment.utc(runTime).local().format('YYYY-MM-DD hh:mm A');

    return `${refreshLocal} (${refreshUtc} UTC)`;
  }

  onRefreshClicked = () => {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        title: this.translateService.instant('tkey;reporting.device_utilization.modal.refresh.title'),
        promptBody: this.translateService.instant('tkey;reporting.device_utilization.modal.refresh.message', {
          nextRefreshed: this.getRefresh(this.nextRunTime),
        }),
        icon: SmacsIcons.REFRESH,
        iconClass: `text-primary`,
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;reporting.device_utilization.modal.refresh.title',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._refreshUtilizations(),
          },
        ],
      },
    };
    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  };

  private _refreshUtilizations(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.deviceUtilizationResource
        .refresh()
        .pipe(
          switchMap(() => this._waitForRefreshToFinish()),
          tap(() => this._setBottomNavTimestamp()),
          switchMap(() => this.searchUtilizations())
        )
        .subscribe(() => {
          this.isSearching = false;
          this.toastService.push(
            ToastTypes.SUCCESS,
            SmacsIcons.REFRESH,
            'tkey;reporting.device_utilization.bottom_nav.sync_cdr_files.toast.title',
            'tkey;reporting.device_utilization.bottom_nav.sync_cdr_files.toast.message'
          );
        });
      subscriber.next();
      subscriber.complete();
    });
  }

  private _waitForRefreshToFinish(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this._checkRefreshJobStatus(subscriber, true);
    });
  }

  private _checkRefreshJobStatus(subscriber: Subscriber<void>, isFirstCall = false) {
    this.deviceUtilizationResource.getRefreshStatus().subscribe((status) => {
      if (status.error !== null && status.jobState === JobState.IDLE) {
        this.toastService.push(
          ToastTypes.ERROR,
          SmacsIcons.REFRESH,
          'tkey;reporting.device_utilization.bottom_nav.sync_cdr_files.failure.toast.title',
          'tkey;reporting.device_utilization.bottom_nav.sync_cdr_files.failure.toast.message'
        );
        this.lastRunTime = status.lastRunTime;
        this.nextRunTime = status.nextRunTime;
        this.data = [];
        this.isRefreshing = false;
        this.isSearching = false;
        this.showCustomAlert = true;
        this._initBottomNav();
        this._setBottomNavTimestamp();
        this._setSyncCdrPending(false);
        subscriber.error();
        return;
      }
      this.showCustomAlert = false;
      if (status.jobState === JobState.IDLE) {
        this.lastRunTime = status.lastRunTime;
        this.nextRunTime = status.nextRunTime;
        this.isRefreshing = false;
        this._setSyncCdrPending(false);

        subscriber.next();
        subscriber.complete();
      } else {
        if (isFirstCall) {
          this.isRefreshing = true;
          this._setSyncCdrPending(true);
        }
        this.timeout = window.setTimeout(() => this._checkRefreshJobStatus(subscriber), 2000);
      }
    });
  }

  private _initBottomNav = () => {
    const bottomNavButtons: BottomNavButton[] = [
      {
        id: 'deviceUtilizationSyncCdrFiles',
        dataAutomation: 'device-utilization-sync-cdr-button',
        label: 'tkey;reporting.device_utilization.bottom_nav.sync_cdr_files',
        icon: this.smacsIcons.REFRESH,
        buttonClass: ButtonStyles.DEFAULT,
        cb: () => {
          this.onRefreshClicked();
        },
      },
      {
        id: 'exportUtilizationReport',
        dataAutomation: 'export-utilization-report',
        label: 'tkey;reporting.xlsx_export.button',
        icon: SmacsIcons.EXPORT,
        buttonClass: ButtonStyles.PRIMARY,
        state: {
          buttonDisableState: {
            disabled: this.isExportLinkDisabled(),
            tooltipKey: 'tkey;reporting.xlsx_export.button.disabled.tooltip',
          },
          tooltipVisible: true,
        },
        cb: () => {
          this.onGetFileClicked();
        },
      },
    ];

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  };

  private _setBottomNavTimestamp() {
    if (this.lastRunTime) {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: false,
          helpText: `
              <i class="${this.smacsIcons.CLOCK}"></i>
              ${this.translateService.instant(
                'tkey;reporting.device_utilization.bottom_nav.helptext'
              )} <strong>${this.dateAgoPipe.transform(this.lastRunTime)}</strong>
            `,
        })
      );
    }
  }

  private _setSyncCdrPending(isPending: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'deviceUtilizationSyncCdrFiles',
        state: {
          pending: isPending,
          buttonDisableState: { disabled: isPending, tooltipKey: '' },
        },
      })
    );
  }

  private _setExportPending(isPending: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'exportUtilizationReport',
        state: {
          pending: isPending,
          buttonDisableState: { disabled: isPending, tooltipKey: '' },
          tooltipVisible: true,
        },
      })
    );
  }

  isExportLinkDisabled() {
    return !this.data || this.data.length === 0;
  }

  isDeviceModelMatch = (filter: string[], row: DeviceUtilizationRow): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.modelName);
  isDevicePoolMatch = (filter: string[], row: DeviceUtilizationRow): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.devicePool);
  isRegistrationStatusMatch = (filter: string[], row: DeviceUtilizationRow): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.registrationStatus);
  isNewDeviceMatch = (filter: boolean, row: DeviceUtilizationRow): boolean => filter || !row.recentlyCreated;
  isLastUsedMatch = (filter: number, row: DeviceUtilizationRow): boolean =>
    this.datatableFilterService.filterTimePeriod(filter, row.lastUsedTransformed);
}
