<legend>
  <span>
    {{ 'tkey;operations.menu.title' | translate }}
  </span>
</legend>

<div class="list-group">
  <a
    id="operations-menu-transfer-user"
    data-automation="operations-menu-transfer-user"
    class="btn list-group-item"
    (click)="onReassignClick()"
    [ngClass]="{ disabled: !canReassign() }"
    [ngbTooltip]="getReassignToolTip() | translate"
  >
    <span [attr.class]="'icon ' + smacsIcons.REASSIGN"></span>
    <span class="operations-menu-text">
      {{ 'tkey;operations.menu.reassign.text' | translate }}
    </span>
  </a>

  <a
    id="operations-menu-transfer-public"
    data-automation="operations-menu-transfer-public"
    class="btn list-group-item"
    (click)="onMakePublicClick()"
    [hidden]="!isDeskphoneEnabledOnAnyClusters"
    [ngClass]="{ disabled: !canMakePublic() }"
    [ngbTooltip]="getMakePublicToolTip() | translate"
  >
    <span [attr.class]="'icon ' + smacsIcons.DESKPHONE"></span>
    <span class="operations-menu-text">
      {{ 'tkey;operations.menu.make_public.text' | translate }}
    </span>
  </a>

  <a
    id="operations-menu-pin-reset"
    data-automation="operations-menu-pin-reset"
    class="btn list-group-item"
    (click)="onPinResetClick()"
    [ngClass]="{ disabled: !hasPinToReset() }"
    [ngbTooltip]="getPinResetTooltip() | translate"
  >
    <span [attr.class]="'icon ' + smacsIcons.REFRESH"></span>
    <span class="operations-menu-text">
      {{ 'tkey;operations.menu.pinreset.text' | translate }}
    </span>
  </a>

  <a
    *ngIf="isChangeSiteEnabled()"
    id="operations-menu-change-site"
    data-automation="operations-menu-change-site"
    class="btn list-group-item"
    (click)="onChangeSiteClick()"
    [ngClass]="{ disabled: !hasDevice() }"
    [ngbTooltip]="getChangeSiteToolTip() | translate"
  >
    <span [attr.class]="'icon ' + smacsIcons.SITE"></span>
    <span class="operations-menu-text">
      {{ 'tkey;operations.menu.change_site.text' | translate }}
    </span>
  </a>

  <a
    *ngIf="isSendEmailEnabled()"
    id="operations-menu-send-email"
    data-automation="operations-menu-send-email"
    class="btn list-group-item"
    (click)="onSendEmailClick()"
  >
    <i [class]="smacsIcons.SEND_EMAIL"></i>
    <span class="operations-menu-text">
      {{ 'tkey;operations.menu.send_email.text' | translate }}
    </span>
  </a>
</div>
