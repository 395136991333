import { Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ToastTypes } from '../shared/services/abstract/toast.service.abstract';
import { SystemStatusContext } from '../shared/contexts/system-status.context';
import {
  CurrentUser,
  HealthStatus,
  Role,
  State,
  StatusCategory,
  SystemHealthStatus,
} from '../shared/models/generated/smacsModels';
import { ToastService } from '../shared/services/toast.service';
import { ButtonSizes, ButtonStyles } from '../button/button.component';
import { SmacsIcons } from '../shared/models/smacs-icons.enum';
import { AuthenticationContext } from '../shared/contexts/authentication.context';
import { Subscription } from 'rxjs';
import { BreadcrumbsService } from '../shared/breadcrumbs/breadcrumbs.service';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../shared/bottom-nav/bottom-nav.service';
import { TranslateService } from '@ngx-translate/core';
import { DateAgoPipe } from '../shared/pipes/date-ago.pipe';
import { GlobalPropertiesContext } from '../shared/contexts/global-properties.context';
import { AboutCardComponent } from '../shared/about-card/about-card.component';

interface EndpointStatus extends HealthStatus {
  errorCount: number;
  maxWidth: number;
  tooltip: string;
}

type StatusCategoryValues = `${StatusCategory}`;

interface ZiroHealthStatusCategoryTranslationKeys {
  zpcTKey: string;
  zpmTKey?: string | number;
}

@Component({
  selector: 'app-system-status',
  templateUrl: './system-status.component.html',
  styleUrls: ['../admin/admin-page.scss', './system-status.component.scss'],
})
export class SystemStatusComponent implements OnInit, OnDestroy {
  @ViewChildren(AboutCardComponent) aboutCards: QueryList<AboutCardComponent>;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  stateEnum = State;
  isLoading = true;
  isRefreshing = false;
  hostedEnabled: boolean;
  trafficStatus: HealthStatus;
  endpointsStatusExists: boolean;
  formattedStatuses = new Map<StatusCategoryValues, (HealthStatus | EndpointStatus)[]>();
  statusKeys: StatusCategoryValues[];
  aboutCardTranslationKeys = new Map<StatusCategoryValues, ZiroHealthStatusCategoryTranslationKeys>()
    .set(StatusCategory.ENDPOINTS, {
      zpcTKey: 'tkey;system_status.zpc.about_card.endpoints',
      zpmTKey: 'tkey;system_status.zpm.about_card.endpoints',
    })
    .set(StatusCategory.LICENSES, { zpcTKey: 'tkey;system_status.zpc.about_card.licenses' })
    .set(StatusCategory.TRAFFIC, { zpcTKey: 'tkey;system_status.zpc.about_card.traffic' })
    .set(StatusCategory.VIRTUAL_MACHINE, {
      zpcTKey: 'tkey;system_status.zpc.about_card.vm',
    })
    .set(StatusCategory.HIGH_AVAILABILITY_REPLICATION, {
      zpcTKey: 'tkey;system_status.zpc.about_card.highavailability',
    })
    .set(StatusCategory.PROXY_SERVER, {
      zpcTKey: 'tkey;system_status.zpc.about_card.sync',
      zpmTKey: 'tkey;system_status.zpm.about_card.sync',
    });

  protected readonly StatusCategory = StatusCategory;
  private _currentUser: CurrentUser;
  private _subscriptions = new Subscription();

  private static _getTooltipState(cause: string): { tooltip: string; maxWidth: number; errorCount: number } {
    let tooltipDescription = '';
    let maxWidth = 350;
    let erroredServers = [];
    if (cause?.length > 40) {
      // Workaround because Safari can't handle lookbehinds
      // See: https://caniuse.com/js-regexp-lookbehind
      const lookBehindPolyfill = /,(?=\])/;
      const parsedErrorServers = cause?.split('').reverse().join('').split(lookBehindPolyfill);
      erroredServers = parsedErrorServers.reverse().map((text: string) => {
        return text.split('').reverse().join('');
      });

      if (erroredServers.length > 1) {
        tooltipDescription = erroredServers.join(',\n');
        maxWidth = erroredServers[0].length * 5;
      }
    }

    return { tooltip: tooltipDescription, maxWidth: maxWidth, errorCount: erroredServers.length };
  }

  constructor(
    private authenticationContext: AuthenticationContext,
    private toastService: ToastService,
    private translateService: TranslateService,
    private systemStatusContext: SystemStatusContext,
    private breadcrumbsService: BreadcrumbsService,
    private bottomNavService: BottomNavService,
    private dateAgoPipe: DateAgoPipe,
    private globalPropertiesContext: GlobalPropertiesContext
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;system_status.title' }]);

    // Make sure translations have loaded or bottom nav helptext won't be translated on first load
    this.translateService.get(this.translateService.currentLang).subscribe(() => {
      this._initAuthenticationContext();
      this.initSystemStatusContext();
    });

    const globalPropertySub = this.globalPropertiesContext.state$.subscribe(
      (globalProperties) => (this.hostedEnabled = globalProperties.hostedEnabled)
    );
    this._subscriptions.add(globalPropertySub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this.breadcrumbsService.clearBreadcrumbs();
  }

  refresh() {
    this._setRefreshingForm(true);
    this._setPending(true);
    const resetServerStates = this.formattedStatuses.get('Endpoints')?.map((server) => (server.state = null));

    if (!!resetServerStates) {
      this.formattedStatuses.set('Endpoints', resetServerStates);
    }

    this.systemStatusContext.refreshSystemStatus().subscribe(() => {
      this._setRefreshingForm(false);
      this._setPending(false);
      this.toastService.push(
        ToastTypes.SUCCESS,
        this.smacsIcons.REFRESH,
        'tkey;system_status.toast.title',
        'tkey;system_status.toast.message'
      );
    });
  }

  getTableBorderClass(statuses: HealthStatus[] | EndpointStatus[]) {
    const doesAnyStatusHaveAnErrorStatus = this._doesAnyStatusHaveAnErrorStatus(statuses);
    const doesAnyStatusHaveAWarningStatus = this._doesAnyStatusHaveAWarningStatus(statuses);

    return {
      'border-danger': doesAnyStatusHaveAnErrorStatus,
      'border-warning': !doesAnyStatusHaveAnErrorStatus && doesAnyStatusHaveAWarningStatus,
      'border-info': !doesAnyStatusHaveAnErrorStatus && !doesAnyStatusHaveAWarningStatus,
    };
  }

  getTableHeaderClass(statuses: HealthStatus[] | EndpointStatus[]) {
    const doesAnyStatusHaveAnErrorStatus = this._doesAnyStatusHaveAnErrorStatus(statuses);
    const doesAnyStatusHaveAWarningStatus = this._doesAnyStatusHaveAWarningStatus(statuses);
    return {
      'bg-danger': doesAnyStatusHaveAnErrorStatus,
      'bg-warning': !doesAnyStatusHaveAnErrorStatus && doesAnyStatusHaveAWarningStatus,
      'bg-info': !doesAnyStatusHaveAnErrorStatus && !doesAnyStatusHaveAWarningStatus,
    };
  }
  handleExpansionClick(i: number) {
    this.aboutCards.get(i).toggleCard();
  }

  private _doesAnyStatusHaveAnErrorStatus = (statuses: HealthStatus[] | EndpointStatus[]) => {
    return statuses.some((status: HealthStatus | EndpointStatus) => status?.state === this.stateEnum.ERROR);
  };

  private _doesAnyStatusHaveAWarningStatus = (statuses: HealthStatus[] | EndpointStatus[]) => {
    return statuses.some((status: HealthStatus | EndpointStatus) => status?.state === this.stateEnum.WARNING);
  };

  private _initAuthenticationContext(): void {
    const _authenticateSub = this.authenticationContext.state$.subscribe((user: CurrentUser) => {
      this._currentUser = user;
      this._initBottomNav();
    });

    this._subscriptions.add(_authenticateSub);
  }

  private _setRefreshingForm(isRefreshing: boolean) {
    this.isRefreshing = isRefreshing;
  }

  private _setPending(isPending: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'system-health-status-refresh-button',
        state: {
          pending: isPending,
          buttonDisableState: { disabled: isPending, tooltipKey: '' },
        },
      })
    );
  }

  private _initBottomNav() {
    if (
      this._currentUser &&
      this._currentUser.userId &&
      this.authenticationContext.userIsAtLeast(this._currentUser, Role.S8_ADMIN)
    ) {
      this.bottomNavService.dispatch(
        new BottomNavUpdateButtonsList([
          {
            id: 'system-health-status-refresh-button',
            label: 'tkey;system_status.refresh.text',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'system-health-status-refresh-button',
            state: {
              pending: false,
              buttonDisableState: {
                disabled: false,
                tooltipKey: '',
              },
            },
            icon: this.smacsIcons.REFRESH,
            cb: () => this.refresh(),
          },
        ])
      );
    }
  }

  private initSystemStatusContext() {
    const systemStatusSub = this.systemStatusContext.state$.subscribe((systemHealthStatus: SystemHealthStatus) => {
      this.formattedStatuses = new Map<StatusCategoryValues, (HealthStatus | EndpointStatus)[]>();
      // Preserve insertion order so endpoints are displayed last
      let endpointStatuses: (EndpointStatus | HealthStatus)[] = [];
      systemHealthStatus.healthStatuses.forEach((status) => {
        if (status.category === StatusCategory.ENDPOINTS) {
          if (endpointStatuses.length) {
            const tooltipState = SystemStatusComponent._getTooltipState(status.cause);
            status = { ...status, ...tooltipState };
            endpointStatuses = [...endpointStatuses, status];
          } else {
            const tooltipState = SystemStatusComponent._getTooltipState(status.cause);
            status = { ...status, ...tooltipState };
            endpointStatuses = [status];
          }
        } else if (this.formattedStatuses.has(status.category)) {
          const existingStatus = this.formattedStatuses.get(status.category);
          this.formattedStatuses.set(status.category, [...existingStatus, status]);
        } else {
          this.formattedStatuses.set(status.category, [status]);
        }
      });

      if (endpointStatuses.length) {
        this.formattedStatuses.set('Endpoints', endpointStatuses);
      }

      this.endpointsStatusExists = this.formattedStatuses.has('Endpoints');
      this.statusKeys = Array.from(this.formattedStatuses.keys());
      this.trafficStatus = systemHealthStatus.healthStatuses.find(
        (status: HealthStatus) => status.category === StatusCategory.TRAFFIC
      );

      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: false,
          helpText: `
        <span class="me-2" data-automation="system-status-last-updated">
          <i class="fa fa-clock-o"></i>
            ${this.translateService.instant('tkey;system_status.refresh.timestamp.text')}
            <strong>${this.dateAgoPipe.transform(systemHealthStatus.generatedTime)}</strong>
      </span>`,
        })
      );
      this._setRefreshingForm(false);
      this.isLoading = false;
    });

    this._subscriptions.add(systemStatusSub);
  }

  protected readonly Number = Number;
  protected readonly isNaN = isNaN;
}
