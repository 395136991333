<app-breadcrumbs>
  <app-breadcrumb-card-item>
    <h4>{{ 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.about.card.header' | translate }}</h4>
    <hr />
    <div [innerHTML]="'tkey;pages.zero_touch.microsoft.provisioning.edit.job.about.card.description' | translate"></div>
  </app-breadcrumb-card-item>
</app-breadcrumbs>

<app-loading-block *ngIf="isLoading"></app-loading-block>

<div [hidden]="isLoading">
  <div class="card mt-3 h-auto" data-automation="ms-zero-touch-provisioning-edit-job-details">
    <div class="card-header">
      {{ 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.job_details.title' | translate }}
    </div>
    <div class="card-body">
      <div class="container-fluid">
        <smacs-text class="mt-1" [fieldId]="'name'"></smacs-text>
        <smacs-text class="mt-1" [fieldId]="'searchFilter'">
          <smacs-view-members
            class="w-25"
            [members]="userMatches"
            [state]="searchFilterState"
            [disabled]="isViewUsersDisabled"
          ></smacs-view-members>
        </smacs-text>
      </div>
    </div>
  </div>

  <div class="card mt-3 h-auto" data-automation="ms-zero-touch-provisioning-edit-teams-calling">
    <div class="card-header">
      {{ 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.teams_calling.title' | translate }}
    </div>
    <div class="card-body">
      <div class="container-fluid">
        <smacs-select [fieldId]="'dialPlanGroups'"></smacs-select>
      </div>
    </div>
  </div>

  <div class="mt-3">
    <smacs-collapsible-card
      (toggleSwitch)="onToggle($event)"
      [label]="'tkey;pages.zero_touch.microsoft.provisioning.edit.job.teams_audio_conf_settings.title'"
      [shouldBeOpen]="sectionToggleState.isAudioConfEnabled"
      [dataAutomation]="'teams-audio-conference-settings-card'"
      [switchId]="'audioConfToggle'"
    >
      <smacs-select [fieldId]="'audioConfNumber'"></smacs-select>
    </smacs-collapsible-card>
  </div>
  <div class="mt-3">
    <smacs-collapsible-card
      (toggleSwitch)="onToggle($event)"
      [label]="'tkey;pages.zero_touch.microsoft.provisioning.edit.job.schedule.title'"
      [shouldBeOpen]="sectionToggleState.isScheduleEnabled"
      [dataAutomation]="'schedule-settings-card'"
      [switchId]="'scheduleToggle'"
    >
      <smacs-radio [fieldId]="'scheduleFrequency'"></smacs-radio>
      <smacs-text [fieldId]="'periodicJobMinutes'"></smacs-text>
      <smacs-text [fieldId]="'dailyJobTime'"></smacs-text>
    </smacs-collapsible-card>
  </div>
</div>
