import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchAbstractResource } from './search.abstract.resource';
import { Observable } from 'rxjs';
import { DirectoryNumberResult } from '../../../shared/models/generated/smacsModels';

export class SearchDirectoryNumberQueryParams {
  q?: string;
  extension?: string;
  description?: string;
  'cucm-server-id'?: number;
  limit?: number;
}

@Injectable()
export class SearchDirectoryNumberResource extends SearchAbstractResource {
  constructor(protected http: HttpClient) {
    super(http);
  }

  get(params: SearchDirectoryNumberQueryParams, limit?: number): Observable<DirectoryNumberResult[]> {
    if (limit) {
      params.limit = limit;
    }
    return this.search<DirectoryNumberResult[]>('/services/cisco/macs/directory-numbers/search', params);
  }
}
