import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalPropertiesContext } from '../../../shared/contexts/global-properties.context';
import { Observable, of, ReplaySubject } from 'rxjs';
import {
  CiscoHelpdeskOptions,
  GlobalProperties,
  MicrosoftHelpdeskOptions,
} from '../../../shared/models/generated/smacsModels';
import { map, switchMap } from 'rxjs/operators';

export type HelpdeskOptions = CiscoHelpdeskOptions | MicrosoftHelpdeskOptions;

@Injectable()
export class HelpdeskOptionsContext {
  private _stateSource = new ReplaySubject<HelpdeskOptions>(1);
  state$ = this._stateSource.asObservable();

  private _isHosted: boolean;
  private _globalPropertiesState: Observable<GlobalProperties>;

  constructor(private http: HttpClient, private globalPropertiesContext: GlobalPropertiesContext) {
    this.refresh().subscribe();
  }

  refresh(): Observable<void> {
    this._isHosted = null;
    // wipe the local global properties state, to kill off any lingering subscriptions from the last refresh.
    this._globalPropertiesState = this.globalPropertiesContext.state$;

    return this._globalPropertiesState.pipe(
      switchMap((globalProperties) => {
        if (globalProperties.hostedEnabled !== this._isHosted) {
          this._isHosted = globalProperties.hostedEnabled;
          return this._isHosted ? this._getMicrosoftHelpdeskOptions() : this._getCiscoHelpdeskOptions();
        }
        return of(null);
      }),
      map((helpdeskOptions) => {
        if (helpdeskOptions) {
          this._stateSource.next(helpdeskOptions);
        }
      })
    );
  }

  isCiscoHelpdeskOptions(helpdeskOptions: HelpdeskOptions): helpdeskOptions is CiscoHelpdeskOptions {
    // specifically excluding null/undefined.
    return this._isHosted === false;
  }

  isMicrosoftHelpdeskOptions(helpdeskOptions: HelpdeskOptions): helpdeskOptions is MicrosoftHelpdeskOptions {
    return this._isHosted;
  }

  private _getCiscoHelpdeskOptions(): Observable<CiscoHelpdeskOptions> {
    return this.http.get<CiscoHelpdeskOptions>('/services/cisco/field-configs/helpdesk-options');
  }

  private _getMicrosoftHelpdeskOptions(): Observable<MicrosoftHelpdeskOptions> {
    return this.http.get<MicrosoftHelpdeskOptions>('/services/microsoft/field-configs/helpdesk-options');
  }
}
