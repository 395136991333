import { Pipe, PipeTransform } from '@angular/core';

export interface AuditTypeCssClass {
  Create: string;
  Update: string;
  Delete: string;
}

@Pipe({ name: 'smacsAuditTypeTag' })
export class SmacsAuditTypeTagPipe implements PipeTransform {
  typeToClass: AuditTypeCssClass = {
    Create: 'bg-success',
    Update: 'bg-warning',
    Delete: 'bg-danger',
  };

  transform(value: keyof AuditTypeCssClass): string {
    if (!value) {
      return '';
    }
    return this.typeToClass[value];
  }
}
