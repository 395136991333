import { SmacsModalService } from '../../shared/services/smacs-modal.service';
import { ToastTypes } from '../../shared/services/abstract/toast.service.abstract';
import { Directive, OnDestroy, OnInit } from '@angular/core';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../shared/bottom-nav/bottom-nav.service';
import { SystemStatusContext } from '../../shared/contexts/system-status.context';
import { ToastService } from '../../shared/services/toast.service';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { DatabaseImportRequest } from '../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../forms/smacs-form-abstract.directive';
import { HtmlInputType, SmacsTextConfig } from '../../forms/fields/text/smacs-text.component';
import { HtmlInputAddOn, SmacsFormConfig, SmacsFormsValidationState } from '../../forms/smacs-forms-models';
import { SmacsFileUploadConfig } from '../../forms/fields/file-upload/smacs-file-upload.component';
import { SmacsRadioConfig } from '../../forms/fields/radio/smacs-radio.component';
import { EMPTY, Observable, Subscription, throwError } from 'rxjs';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { ButtonStyles, ButtonTypes } from '../../button/button.component';
import { catchError, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

export interface AdminImportConfig {
  importFrom: ImportTypes;
  contextUrl: string;
  username: string;
  password: string;
  importFromFile: any;
}

export enum ImportTypes {
  INSTANCE,
  FILE,
}

export enum ImportFileTypes {
  CDR = 'CDR',
  AUDIT = 'AUDIT',
  CONFIG = 'CONFIG',
}

@Directive({
  selector: '[smacsAdminImportAbstract]',
})
export abstract class ImportAbstractDirective
  extends SmacsFormAbstractDirective<AdminImportConfig>
  implements OnInit, OnDestroy
{
  importFileTypes = ImportFileTypes;
  isLoading = true;
  warningText: string;
  warningTextPreamble: string;
  importFileType: ImportFileTypes;
  smacsIcons = SmacsIcons;
  importFromFileMessage = '';

  private validators = {
    importFromFileValidator: {
      validator: (val: File) => {
        this.importFromFileMessage = '';
        this.importFromFileMessage = this.translateService.instant('tkey;admin.import.error.message', {
          importFileType: this.importFileType.toLowerCase(),
        });
        return val?.name.toLowerCase().includes(this.importFileType.toLowerCase())
          ? SmacsFormsValidationState.VALID
          : SmacsFormsValidationState.INVALID;
      },
      message: () => this.importFromFileMessage,
    },
  };

  requestImport: () => Observable<any>;

  formConfig: SmacsFormConfig = {
    fields: {
      // Import From Radio
      importFrom: {
        label: 'tkey;pages.admin.audit.import.type.label',
        dataAutomation: 'smacs-import-abstract-import-from-input',
        disabled: () => this.isSubmitting,
        componentConfig: new SmacsRadioConfig<any>({
          buttons: [
            {
              value: ImportTypes.INSTANCE,
              label: 'tkey;pages.admin.audit.import.type.instance.label',
            },
            {
              value: ImportTypes.FILE,
              label: 'tkey;pages.admin.audit.import.type.file.label',
            },
          ],
          inline: true,
        }),
      },
      // Text Fields
      contextUrl: {
        label: 'tkey;pages.admin.import.address.label',
        dataAutomation: 'import-from-instance-url',
        disabled: () => this.isSubmitting,
        required: () => !this._isImportFromFile(),
        hidden: () => this._isImportFromFile(),
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          htmlInputAddOn: {
            prependedContent: 'https://',
          } as HtmlInputAddOn,
        }),
      },
      username: {
        label: 'tkey;pages.admin.import.username.label',
        disabled: () => this.isSubmitting,
        required: () => !this._isImportFromFile(),
        hidden: () => this._isImportFromFile(),
        dataAutomation: 'import-from-instance-username',
      },
      password: {
        label: 'tkey;pages.admin.import.password.label',
        disabled: () => this.isSubmitting,
        required: () => !this._isImportFromFile(),
        hidden: () => this._isImportFromFile(),
        dataAutomation: 'import-from-instance-password',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
      },
      // File Input
      importFromFile: {
        label: '',
        dataAutomation: 'import-from-file-input',
        disabled: () => this.isSubmitting,
        required: () => this._isImportFromFile(),
        hidden: () => !this._isImportFromFile(),
        componentConfig: new SmacsFileUploadConfig({ acceptedFileExtensions: { acceptedExtensions: '.sql' } }),
        validation: [],
      },
    },
  };

  private _subs = new Subscription();

  protected constructor(
    protected toastService: ToastService,
    protected systemStatusContext: SystemStatusContext,
    protected smacsModalService: SmacsModalService,
    protected bottomNavService: BottomNavService,
    protected smacsFormStateService: SmacsFormStateService,
    protected translateService: TranslateService
  ) {
    super(smacsFormStateService);
  }

  protected static formatUrl(initialUrl: string) {
    return initialUrl.match(/^https?:\/\//) ? initialUrl : `https://${initialUrl}`;
  }

  ngOnInit() {
    this.entitySource.next({
      ...this.formData,
      importFrom: ImportTypes.INSTANCE,
    });

    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'importConfigButton',
          label: 'tkey;pages.admin.import.bottom_nav.import.label',
          icon: this.smacsIcons.IMPORT,
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'bottom-nav-import-button',
          state: {
            pending: false,
            buttonDisableState: { disabled: false, tooltipKey: '' },
          },
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });
    this._subs.add(formSubmittedSub);

    this.formConfig.fields.importFromFile.validation.push(this.validators.importFromFileValidator);

    this.isLoading = false;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subs.unsubscribe();
  }

  getImportRequest(): DatabaseImportRequest {
    let request;

    if (this.formData.importFrom === ImportTypes.INSTANCE) {
      request = {
        contextUrl: ImportAbstractDirective.formatUrl(this.formData.contextUrl),
        username: this.formData.username,
        password: this.formData.password,
      };
    } else {
      request = this.formData.importFromFile;
    }

    return request;
  }

  protected submit() {
    return this._onImportClicked();
  }

  private _onImportClicked(): Observable<void> {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
      })
    );

    this._setPending(true);

    return this.requestImport().pipe(
      tap(() => {
        this._setPending(false);
        const options = {
          modalViewProperties: {
            title: this.translateService.instant('tkey;pages.admin.app.import.ok.tooltip'),
            icon: SmacsIcons.CHECK_CIRCLE,
            iconClass: 'text-success',
            displayCloseButton: true,
            buttons: [
              {
                label: 'tkey;dialogs.button.ok',
                buttonClass: ButtonStyles.PRIMARY,
                dataAutomation: 'confirmation-modal-ok-button',
              },
            ],
          },
        };

        this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
      }),
      catchError((response) => {
        this.isSubmitting = false;
        this._validateAndSubmitSource.next(false);
        this._setPending(false);
        const errorDescription = response ? response.error.description : '';

        if (errorDescription === 'Username or password is incorrect') {
          this.toastService.push(ToastTypes.ERROR, this.smacsIcons.DOWNLOAD, 'Unauthorized', errorDescription);
          return throwError(() => EMPTY);
        } else if (errorDescription === 'User does not have permission to export database') {
          this.toastService.push(ToastTypes.ERROR, this.smacsIcons.DOWNLOAD, 'Forbidden', errorDescription);
          return throwError(() => EMPTY);
        } else if (errorDescription.includes('Could not connect to host')) {
          this.toastService.push(ToastTypes.ERROR, this.smacsIcons.DOWNLOAD, 'Bad Gateway', errorDescription);
          return throwError(() => EMPTY);
        } else if (response === null) {
          this.bottomNavService.dispatch(
            new BottomNavUpdateState({
              hasValidationError: true,
            })
          );
          return throwError(() => EMPTY);
        } else {
          return throwError(() => response);
        }
      })
    );
  }

  private _setPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'importConfigButton',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  private _isImportFromFile() {
    return this.formData.importFrom === ImportTypes.FILE || this.importFileType === ImportFileTypes.CDR;
  }
}
