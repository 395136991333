import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UnusedDevicesReport } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class CucmCdrUnusedDevicesContext {
  _stateSource = new ReplaySubject<UnusedDevicesReport>(1); // Public so stages can update the state
  state$ = this._stateSource.asObservable();

  constructor(private $http: HttpClient) {
    this._init();
  }

  private _init() {
    this._getUnusedDevices().subscribe((unusedDevicesReport: UnusedDevicesReport) => {
      this._stateSource.next(unusedDevicesReport);
    });
  }

  private _getUnusedDevices = () =>
    this.$http.get<UnusedDevicesReport>('/services/cisco/reporting/utilizations/unused-devices');

  refreshUnusedDevices() {
    this._init();
  }
}
