<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 d-flex flex-column h-100">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.billing.products.about' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <app-loading-block *ngIf="isLoading"></app-loading-block>

    <div class="flex-grow-1" *ngIf="!isLoading">
      <smacs-datatable
        *ngIf="!isLoadingTable"
        [data]="data"
        [columns]="columns"
        [mainFilterPlaceholder]="'tkey;admin.billing.products.detailed_usage.datatable.search.placeholder' | translate"
        [maxLength]="40"
        [isFirstColumnSticky]="true"
        [dataAutomation]="tableDataAutomation"
        [noResultsAlert]="noResultsAlert"
        [noResultsAlertClass]="noResultsAlertClass"
        [showCustomAlert]="showCustomAlert"
      >
        <smacs-datatable-switch-filter
          #noDialPlanFilter
          [label]="'tkey;admin.billing.products.detailed_usage.datatable.no_dial_plan.filter'"
          [dataAutomation]="'billing-no-dial-plan-group-filter'"
          [tooltip]="'tkey;admin.billing.products.detailed_usage.datatable.no_dial_plan.filter.tooltip'"
          [matchFn]="isNoDialPlanGroupMatch"
          [tableId]="tableDataAutomation"
          (isInitialized)="isFilterInitialized('noDialPlanFilter')"
        ></smacs-datatable-switch-filter>
        <smacs-datatable-switch-filter
          #tollFreeFilter
          [label]="'tkey;admin.billing.products.detailed_usage.datatable.only_toll_free.filter'"
          [dataAutomation]="'billing-toll-free-filter'"
          [matchFn]="isTollFreeMatch"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-switch-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;admin.billing.products.detailed_usage.datatable.country.header'"
          [dataAutomation]="'billing-country-filter'"
          [items]="countryFilterOptions"
          [matchFn]="isCountryMatch"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;admin.billing.products.detailed_usage.datatable.carrier.header'"
          [dataAutomation]="'billing-carrier-filter'"
          [items]="carrierFilterOptions"
          [matchFn]="isCarrierMatch"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;admin.billing.products.detailed_usage.datatable.account_type.header'"
          [dataAutomation]="'billing-account-type-filter'"
          [items]="accountTypeFilterOptions"
          [matchFn]="isAccountTypeMatch"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;admin.billing.products.detailed_usage.datatable.pstn.header'"
          [dataAutomation]="'pstn-filter'"
          [items]="pstnFilterOptions"
          [matchFn]="isPstnMatch"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;admin.billing.products.detailed_usage.datatable.dial_plan_group_name.header'"
          [dataAutomation]="'billing-dial-plan-group-name-filter'"
          [items]="dialPlanGroupNameFilterOptions"
          [matchFn]="isDialPlanGroupMatch"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;admin.billing.products.detailed_usage.datatable.billing_location.header'"
          [dataAutomation]="'billing-billing-location-filter'"
          [items]="billingLocationFilterOptions"
          [matchFn]="isBillingLocationMatch"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          #billingProductFilter
          [label]="'tkey;admin.billing.products.detailed_usage.datatable.billing_product.header'"
          [dataAutomation]="'billing-billing-product-filter'"
          [items]="billingProductFilterOptions"
          [matchFn]="isBillingProductMatch"
          [tableId]="tableDataAutomation"
          (isInitialized)="isFilterInitialized('billingProductFilter')"
        ></smacs-datatable-multi-select-filter>
        <div class="text-center" alert-content>
          {{ 'tkey;admin.billing.products.detailed_usage.datatable.error_1' | translate }}
          <button class="btn btn-link strong" (click)="onRefreshClicked()">
            {{ 'tkey;admin.billing.products.detailed_usage.datatable.error_2' | translate }}
          </button>
          {{ 'tkey;admin.billing.products.detailed_usage.datatable.error_3' | translate }}
        </div>
      </smacs-datatable>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
