import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationContext } from '../../shared/contexts/authentication.context';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { combineLatest, Subscription } from 'rxjs';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { SelfServe360View, SelfServeUiContext } from '../contexts/self-serve-ui.context';
import { get } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { CustomUiOptionsContext } from '../../shared/contexts/custom-ui-options.context';

@Component({
  selector: 'app-self-serve-top-bar',
  templateUrl: './self-serve-top-bar.component.html',
  styleUrls: ['./self-serve-top-bar.component.scss'],
})
export class SelfServeTopBarComponent implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;
  customUiLogo: SafeResourceUrl;
  usersFullName = '';
  selfServe360View: SelfServe360View;
  isLoading = true;

  private _subscriptions = new Subscription();

  constructor(
    private authenticationContext: AuthenticationContext,
    private customUiOptionsContext: CustomUiOptionsContext,
    private domSanitizer: DomSanitizer,
    private _selfServeUiContext: SelfServeUiContext,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    const username = get(this._route, 'snapshot.params.username');

    const subscription = combineLatest([
      this._selfServeUiContext.selfServe360View$,
      this.customUiOptionsContext.state$,
    ]).subscribe(([selfServeState, uiOptions]) => {
      const user = selfServeState.endUsers[0];
      if (!username || user.ref.username === username) {
        this.customUiLogo = uiOptions.customLogo
          ? this.domSanitizer.bypassSecurityTrustResourceUrl(`data:image/png;base64, ${uiOptions.customLogo}`)
          : null;
        this.selfServe360View = selfServeState;
        this.usersFullName = `${selfServeState.endUsers[0].ref.firstName} ${selfServeState.endUsers[0].ref.lastName}`;
        this.isLoading = false;
      }
    });
    this._subscriptions.add(subscription);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  logoutUser(): void {
    this.authenticationContext.logout().subscribe();
  }

  getUserIconBase64(): string {
    if (this.selfServe360View?.ldapUser && this.selfServe360View.ldapUser.photoBase64) {
      return this.selfServe360View.ldapUser.photoBase64;
    }

    return '';
  }

  getUserIconSrc(): string {
    return `data:image/jpeg;base64,${this.getUserIconBase64()}`;
  }

  getLogoUrl(): string {
    const username = get(this._route, 'snapshot.params.username');

    if (username) {
      return `/self-serve/user/${encodeURIComponent(username)}`;
    } else {
      return '/self-serve';
    }
  }
}
