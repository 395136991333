import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DetailedModalComponent } from '../../modals/detailed-modal/detailed-modal.component';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { SmacsFormConfig } from '../../forms/smacs-forms-models';
import { ModalBodyClass } from '../../modals/modal-body';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { Observable, throwError } from 'rxjs';
import { Global360ViewContext } from '../../shared/contexts/global-360-view.context';
import { UserDetailUiContext } from '../shared/contexts/user-detail-ui.context';
import { Global360View, VoicemailRef } from '../../shared/models/generated/smacsModels';
import { VoicemailComponent } from '../cisco/voicemail/voicemail.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'smacs-voicemail-detailed-modal',
  templateUrl: './voicemail-detailed-modal.component.html',
  styleUrls: ['./voicemail-detailed-modal.component.scss'],
  providers: [Global360ViewContext, UserDetailUiContext],
})
export class VoicemailDetailedModalComponent extends ModalBodyClass implements OnInit {
  @ViewChild(VoicemailComponent) voicemail: VoicemailComponent;
  @ViewChild('errorTemplate') errorTemplate: TemplateRef<any>;
  areFormsValid = true;
  smacsIcons = SmacsIcons;
  modalProperties: any;
  voicemailRef: VoicemailRef;
  global360View: Global360View;
  isLoading = true;

  cssClass = {
    margin: '100px auto',
  };
  private _isVoicemailSaved = false;

  constructor(
    private modalComponent: DetailedModalComponent<any>,
    private ngbActiveModal: NgbActiveModal,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  formConfig = {
    fields: {},
  } as SmacsFormConfig;

  ngOnInit(): void {
    this.modalProperties = this.modalComponent.modalViewProperties;
  }

  isFormValid() {
    this.areFormsValid = this.voicemail.voicemailForm.isFormValid();
    return this.voicemail.voicemailForm.isFormValid();
  }

  onVoicemailRefUpdated(vmRef: VoicemailRef) {
    this.voicemailRef = vmRef;
    this._isVoicemailSaved = true;
  }

  submit(): Observable<VoicemailRef> {
    return this.voicemail.voicemailForm.handleSaveClick().pipe(
      catchError((error) => {
        if (this._isVoicemailSaved) {
          // If the voicemail save worked but one of the other calls failed, close the modal to take us back to a working state.
          this.ngbActiveModal.close(this.voicemailRef);
        }
        return throwError(() => error);
      })
    );
  }

  delete(): Observable<void> {
    const modalObs$ = new Observable<void>(() => {
      this.voicemail.voicemailForm.handleDeleteClick();
    });
    return modalObs$.pipe();
  }

  cancel() {
    this.ngbActiveModal.close({ isCancelClicked: true });
  }

  setLoading($event: boolean) {
    this.isLoading = $event;
    this.smacsFormStateService.isFormLoading.next(this.isLoading);
  }
}
