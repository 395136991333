<app-loading-block *ngIf="isLoading"></app-loading-block>
<ng-container *ngIf="!isLoading">
  <smacs-reassign-user-search
    *ngIf="!isTargetUserSelected"
    [reassignType]="ReassignTypes.USER_TO_USER"
    [serverId]="serverId"
  ></smacs-reassign-user-search>
  <smacs-reassign-user-execute
    *ngIf="isTargetUserSelected"
    [reassignType]="ReassignTypes.USER_TO_USER"
    [isVoicemailPresentOnLineOne]="isVoicemailPresentOnLineOne"
    [serverId]="serverId"
    [siteId]="siteId"
    (reassignClicked)="onReassignClicked($event)"
  ></smacs-reassign-user-execute>
</ng-container>
