import { SmacsFieldComponentConfig } from '../../smacs-forms-models';
import {
  CallingType,
  DialPlanManagementGroup,
  DnDidRange,
  PstnConnectivityType,
  SiteSummary,
} from '../../../shared/models/generated/smacsModels';

export class ExtensionRange {
  start: string;
  end: string;
}

export class ExtensionRanges {
  [rangeId: string]: ExtensionRange;
}

export class SmacsExtensionRangesComponentConfig extends SmacsFieldComponentConfig {
  constructor(public config: { optionalValidators: ExtensionRangesOptionalValidators; siteSummary?: SiteSummary }) {
    super();
  }
}

export class SmacsRangeGroup {
  id: number;
  name: string;
  ranges: DnDidRange[];
  rangeType: CallingType;
  pstnConnectivityType: PstnConnectivityType;
  mainNumber: string;
}

export class ExtensionRangesOptionalValidators {
  // Allow ranges to overlap on same group
  overlappingRanges?: boolean;
  // Forces strict formatting of ranges to E.164 format
  strictE164Validation?: boolean;
  // Forces range start and end to be same number of digits
  hasSameLengthValidation?: boolean;
  // Forces max diff between range start and end
  maxDiffValidation?: number;
  // Validate ranges overlapping on different groups
  // Generic to work with MS dial plan and exception groups (data must be mapped to interface)
  overlappingRangesInOtherGroups?: {
    groups: SmacsRangeGroup[];
  };
  // Validate ranges overlapping on different groups
  // Warning if overlapping on different cluster
  // Error if overlapping on same cluster
  overlappingRangesInOtherCiscoDialPlanGroups?: {
    clusterId: number;
    dialPlanGroups: DialPlanManagementGroup[];
    range: 'DN' | 'DID';
  };
  overlappingRangesInExistingPortInDraft?: {
    existingPortInDraftNumbers: string[];
  };
  // Min length validation without "+"
  minLengthValidation?: number;
  // Max length validation without "+"
  maxLengthValidation?: number;
  // If the range type is extension (For ZPM dial plan groups)
  isExtensionRangeType?: boolean;
  // Does not allow leading zeroes
  noLeadingZeroes?: boolean;
  // Bandwidth doesn't allow Canadian area codes at the moment
  canadianNumbersRestricted?: {
    restrictedAreaCodes: string[];
  };
}
