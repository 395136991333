<div
  *ngFor="let t of trackingList; let idx = index"
  [ngClass]="{ 'card card-body card--ldap-server': trackingList.length > 1 }"
>
  <button
    *ngIf="trackingList.length > 1"
    (click)="onRemoveServer(idx)"
    class="btn btn-outline-danger card--ldap-server__delete-button"
  >
    <i [class]="smacsIcons.DELETE"></i>
  </button>
  <smacs-admin-ldap-management-settings-ldap-server-url-form
    [entity]="fieldData[idx]"
    (smacsFormsUpdate$)="onFormUpdate($event, idx)"
    [forceValidation$]="forceValidation$"
    [attr.data-automation]="config.dataAutomation + '-' + idx"
    [isParentFormSubmitted]="isFormSubmitted"
  ></smacs-admin-ldap-management-settings-ldap-server-url-form>
</div>

<div class="d-flex justify-content-end">
  <smacs-button
    [cssClass]="'add-ldap-server'"
    [label]="'tkey;admin.ldap.settings.servers.add.label'"
    [icon]="smacsIcons.PLUS"
    [buttonStyle]="buttonStyles.INFO"
    (clicked)="addServer()"
    [attr.data-automation]="'add-ldap-server-button'"
  ></smacs-button>
</div>
