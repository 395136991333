<form [autocomplete]="'new-password'">
  <div
    class="mb-3 row"
    [hidden]="state.hidden && (!forceShow || !state.defaultValue)"
    [attr.data-automation]="config.dataAutomation"
    [ngClass]="{
      'has-feedback': showValidation || isFormSubmitted || misconfigurationFeedbackMessage,
      'smacs-forms-error': (showValidation || isFormSubmitted) && validationState === ValStates.INVALID,
      'smacs-forms-warning':
        validationState === ValStates.PENDING ||
        validationState === ValStates.WARNING ||
        (misconfigurationFeedbackMessage && state.hidden),
      'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
    }"
  >
    <label
      *ngIf="config.label"
      [class]="'col-form-label ' + getLabelColumnClass()"
      [ngClass]="{
        'smacs-forms-label': inlineLabel && !leftAligned,
        'col-12': !inlineLabel,
        'ms-3': leftAligned
      }"
      [attr.for]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
      data-automation="smacs-text-label"
    >
      <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
      <span
        [disableTooltip]="!!config.labelToolTipIconClass"
        [ngbTooltip]="config.labelToolTipText | translate"
        [innerHtml]="getLabel() | translate"
      ></span>
      <i
        *ngIf="config.labelToolTipIconClass"
        [ngbTooltip]="config.labelToolTipText | translate"
        class="{{ config.labelToolTipIconClass }} ms-1"
      ></i>
    </label>

    <div [class]="getInputColumnClass()" [ngClass]="{ 'col-12': !config.label || !inlineLabel }">
      <div class="d-flex">
        <ng-template #disabledToolTipContent>
          <span [innerHTML]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"></span>
        </ng-template>
        <div
          class="input-group"
          [ngClass]="{ 'input-group-lg': inputSize === inputSizes.LG, 'input-group-sm': inputSize === inputSizes.SM }"
          [ngbTooltip]="disabledToolTipContent"
          [disableTooltip]="!getDisabledTooltip()"
        >
          <span *ngIf="inputAddOn && inputAddOn?.prependedContent" class="input-group-text form-control">
            {{ inputAddOn?.prependedContent | translate }}
          </span>
          <div class="values-container" data-automation="values-container" (click)="onValueClick()">
            <span *ngFor="let val of getPossibleOptions()" class="value-container">
              <i [attr.class]="smacsIcons.EYE + ' me-1'"></i>
              <span class="value">{{ val }}</span>
            </span>
            <span *ngFor="let val of currentValue.defaultValues" class="value-container">
              <i [attr.class]="smacsIcons.EDIT + ' me-1'"></i>
              <span class="value">{{ val }}</span>
            </span>
          </div>
          <input
            class="hidden"
            [disabled]="state.disabled"
            [type]="inputType"
            [min]="min"
            [max]="max"
            [readonly]="readOnly"
            [attr.id]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
            #ngModelDir="ngModel"
            (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
            [(ngModel)]="showMaskedInput ? maskedValue : fieldData"
            [ngModelOptions]="{ standalone: true }"
            [placeholder]="showMaskedInput ? starsPlaceholder : (placeholder | translate)"
            [autocomplete]="inputType === htmlInputType.PASSWORD && isChrome ? 'new-password' : 'off'"
            data-automation="smacs-text-input"
          />

          <span *ngIf="inputAddOn && inputAddOn?.appendedContent" class="input-group-text form-control">
            {{ inputAddOn?.appendedContent | translate }}
          </span>

          <span
            class="{{ smacsIcons.WARNING }} form-control-feedback"
            [hidden]="
              !(
                (showAsyncValidation && validationState === ValStates.WARNING) ||
                (validationState === ValStates.VALID && misconfigurationFeedbackMessage && state.hidden)
              )
            "
            data-automation="smacs-text-warning-icon"
          ></span>

          <span
            class="{{ smacsIcons.SAVING }} form-control-feedback"
            [hidden]="!(validationState === ValStates.PENDING && showAsyncValidation)"
            data-automation="smacs-text-async-pending"
          ></span>

          <span
            class="{{ smacsIcons.REMOVE }} form-control-feedback"
            [hidden]="!(validationState === ValStates.INVALID && showAsyncValidation)"
            data-automation="smacs-text-async-invalid"
          ></span>

          <span
            class="{{ smacsIcons.OK }} form-control-feedback"
            [hidden]="!(validationState === ValStates.VALID && showAsyncValidation && !misconfigurationFeedbackMessage)"
            data-automation="smacs-text-async-valid"
          ></span>
        </div>

        <ng-content></ng-content>
      </div>

      <div class="smacs-forms-feedback-area">
        <div
          class="form-text small text-info"
          *ngIf="isAutogenerated"
          data-automation="smacs-forms-autogeneration-message"
        >
          {{ config.autogeneration?.generatedMsg | translate }}
        </div>

        <div
          *ngIf="showValidation || isFormSubmitted"
          class="smacs-forms-validation-message"
          data-automation="smacs-forms-validation-message"
          [ngClass]="{ 'd-inline-block me-1': validationMessage && (showValidation || isFormSubmitted) }"
          [innerHTML]="validationMessage?.content | translate : validationMessage?.params"
        ></div>
        <div
          *ngIf="isMisConfigurationFeedbackDisplayed()"
          class="smacs-forms-validation-message"
          [ngClass]="{ 'd-inline-block me-1': config.autogeneration?.inline }"
          data-automation="smacs-forms-warning-message"
          [innerHTML]="
            (validationMessage && (showValidation || isFormSubmitted)
              ? 'tkey;smacs_text.misconfiguration_additional_feedback'
              : 'tkey;smacs_text.misconfiguration_feedback'
            ) | translate : { defaultValue: this.state.defaultValue }
          "
        ></div>

        <smacs-button
          [dataAutomation]="'smacs-forms-autogeneration-button'"
          [hidden]="isAutogenerateLinkHidden() || validationState === ValStates.PENDING"
          (clicked)="autogenerateValue()"
          [buttonStyle]="buttonStyles.LINK"
          [label]="getFixItLinkLabel()"
          [labelCssClass]="misconfigurationFeedbackMessage && !state.hidden ? 'small' : 'small strong'"
        ></smacs-button>

        <span
          *ngIf="getHelpText()"
          class="small form-text text-muted mt-0"
          [innerHtml]="getHelpText() | translate"
        ></span>

        <span
          *ngIf="inputType === htmlInputType.TIME && localTime"
          data-automation="smacs-forms-local-time"
          class="small form-text text-muted"
        >
          {{ 'tkey;pages.zero_touch.edit.form.at_local_time' | translate }}
          <strong>{{ localTime }}</strong>
        </span>
      </div>
    </div>
  </div>
</form>
