import { Component, Input, ViewChild } from '@angular/core';
import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import { isNil } from 'lodash';
import {
  ExtensionRange,
  ExtensionRangesOptionalValidators,
  SmacsExtensionRangesComponentConfig,
} from './smacs-extension-range-models';
import { SmacsExtensionRangesFormComponent } from './smacs-extension-ranges-form.component';
import { SmacsFormsUpdate } from '../../smacs-forms-models';
import { CallingType, RangeType, SiteSummary } from '../../../shared/models/generated/smacsModels';

@Component({
  selector: 'smacs-extension-ranges',
  templateUrl: './smacs-extension-ranges.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsExtensionRangesComponent }],
})
export class SmacsExtensionRangesComponent extends SmacsFieldAbstractDirective<
  ExtensionRange[],
  ExtensionRange[],
  SmacsExtensionRangesComponentConfig
> {
  @ViewChild(SmacsExtensionRangesFormComponent) childFormComponent: SmacsExtensionRangesFormComponent;
  @Input() isZpm?: boolean;
  @Input() isDisabled = false;
  @Input() zpmCallingType: CallingType;
  @Input() exceptionRangeType: RangeType;

  optionalValidators = {} as ExtensionRangesOptionalValidators;
  upstreamEntity: ExtensionRange[];
  siteSummary: SiteSummary;
  showRequiredValidation = false;

  private _initialized = false;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  init() {
    this.config.validation = [
      {
        validator: () => {
          return this.childFormComponent.getFormValidationState();
        },
        message: '',
      },
    ];
    super.init();
  }

  applyComponentConfig = ({ config }: SmacsExtensionRangesComponentConfig) => {
    this.optionalValidators = isNil(config.optionalValidators) ? this.optionalValidators : config.optionalValidators;
    this.siteSummary = isNil(config.siteSummary) ? this.siteSummary : config.siteSummary;
  };

  _onReceiveNewEntity = (entity: ExtensionRange[]) => {
    this.upstreamEntity = entity;
    this.fieldData = entity;
  };

  onFormUpdate(update: SmacsFormsUpdate<ExtensionRange[]>) {
    // Only mark as dirty after the fields have been initialized
    this.updateSelf(update.new, this._initialized);
    this._initialized = true;
    if (this.showRequiredValidation) {
      this.showRequiredValidation = this.fieldData?.length === 0;
    }
  }

  hasFeedback() {
    return (
      this.isFormSubmitted ||
      !!this.childFormComponent?.fieldComponents?.some((field) => field.showValidation) ||
      this.showRequiredValidation
    );
  }

  /**
   * This will trigger the required validation for the field when all fields are removed
   */
  onRangeDelete() {
    this.updateSelf(this.fieldData);
    this.showRequiredValidation = this.fieldData?.length === 0;
  }
}
