import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SmacsFieldComponentConfig } from '../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { UniqueIdService } from '../../../shared/services/unique-id.service';
import { NgModel } from '@angular/forms';
import { take } from 'rxjs/operators';
import { SmacsFieldAbstractDirective } from '../../../forms/smacs-field-abstract.directive';

export interface CallForwardField {
  destination: string;
  forwardToVoicemail: boolean;
}

export class SmacsCallForwardConfig extends SmacsFieldComponentConfig {
  constructor(
    public config: {
      hasVoicemail?: boolean;
      autoForwardToVoicemail?: boolean;
      isUnityServerConfigured?: boolean;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-directory-number-call-forward',
  templateUrl: './call-forward.component.html',
  styleUrls: ['./call-forward.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsCallForwardComponent }],
})
export class SmacsCallForwardComponent
  extends SmacsFieldAbstractDirective<CallForwardField, CallForwardField, SmacsCallForwardConfig>
  implements OnInit, AfterViewChecked
{
  temporaryValue = { forwardToVoicemail: '', destination: '' };
  hasVoicemail: boolean;
  autoForwardToVoicemail: boolean;
  isUnityServerConfigured: boolean;
  uniqueRadioId: string;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private uniqueIdService: UniqueIdService,
    private changeDetection: ChangeDetectorRef
  ) {
    super(smacsFormStateService);
    this.uniqueRadioId = uniqueIdService.getUniqueId();
  }

  ngOnInit(): void {
    this.smacsFormsUpdate$.pipe(take(1)).subscribe((fieldState) => {
      this.temporaryValue.forwardToVoicemail = fieldState.new?.forwardToVoicemail ? 'voicemail' : 'destination';
      this.temporaryValue.destination = fieldState.new ? fieldState.new.destination : '';
    });
  }

  applyComponentConfig = ({ config }: SmacsCallForwardConfig) => {
    this.hasVoicemail = config.hasVoicemail;
    this.autoForwardToVoicemail = config.autoForwardToVoicemail;
    this.isUnityServerConfigured = config.isUnityServerConfigured;
    this.temporaryValue.forwardToVoicemail =
      config.hasVoicemail && config.autoForwardToVoicemail ? 'voicemail' : 'destination';
  };

  showWarningMessage(): boolean {
    return !this.hasVoicemail && this.isUnityServerConfigured && this.temporaryValue.forwardToVoicemail === 'voicemail';
  }

  showErrorMessage(): boolean {
    if (this.temporaryValue.forwardToVoicemail === 'voicemail') {
      return false;
    }

    const val = this.temporaryValue.destination;
    return val.includes('@') ? !/^.*$/.test(val) : !/^[PpFfCc0-9#*+,]*$/.test(val);
  }

  ngAfterViewChecked() {
    this.changeDetection.detectChanges();
  }

  transformData = ($event: string, updateField: string, ngModelDir: NgModel) => {
    if (updateField === 'destinationInput') {
      this.temporaryValue.destination = $event;
    } else if (updateField === 'destinationOption') {
      this.temporaryValue.forwardToVoicemail = $event;
    } else if (updateField === 'voicemailOption') {
      this.temporaryValue.forwardToVoicemail = $event;
    }
    const entity = this._toEntity();

    this.updateStateAndSelf(entity, ngModelDir);
  };

  private _toEntity(): CallForwardField {
    return {
      forwardToVoicemail: this.temporaryValue.forwardToVoicemail === 'voicemail',
      destination: this.temporaryValue.forwardToVoicemail === 'destination' ? this.temporaryValue.destination : '',
    };
  }
}
