<div class="public-phone-operations-menu" data-automation="public-phone-operations-menu">
  <div class="public-phone-operations-menu__list-item">
    <span class="public-phone-operations-menu__list-item__content">
      {{ 'tkey;operations.menu.public.title' | translate }}
    </span>
  </div>
  <div class="public-phone-operations-menu__list-item">
    <a
      class="btn btn-link public-phone-operations-menu__list-item__content"
      [routerLink]="'reassign'"
      data-automation="public-phone-operations-menu-reassign-button"
      [ngClass]="{ disabled: isUnsavedCopy }"
      [ngbTooltip]="'tkey;pages.phone.public_phone.reassign.disabled.tooltip' | translate"
      [disableTooltip]="!isUnsavedCopy"
    >
      <i [attr.class]="smacsIcons.REASSIGN + ' me-1'"></i>
      {{ 'tkey;operations.menu.reassign.text' | translate }}
    </a>
  </div>
  <div class="public-phone-operations-menu__list-item">
    <a
      class="btn btn-link public-phone-operations-menu__list-item__content"
      [routerLink]="isVoicemailPresentOnLineOne ? 'pin-reset' : null"
      [ngClass]="{ disabled: !isVoicemailPresentOnLineOne || isUnsavedCopy }"
      [ngbTooltip]="
        isUnsavedCopy
          ? ('tkey;pages.phone.public_phone.pinReset.disabled.tooltip' | translate)
          : ('tkey;operations.menu.public_phone.pinreset.disabled' | translate)
      "
      [disableTooltip]="isVoicemailPresentOnLineOne && !isUnsavedCopy"
      data-automation="public-phone-operations-menu-pin-reset-button"
    >
      <i [attr.class]="smacsIcons.REFRESH + ' me-1'"></i>
      {{ 'tkey;operations.menu.pinreset.text' | translate }}
    </a>
  </div>
  <div *ngIf="isChangeSiteEnabled" class="public-phone-operations-menu__list-item">
    <a
      class="btn btn-link public-phone-operations-menu__list-item__content"
      [routerLink]="'change-site'"
      data-automation="public-phone-operations-menu-change-site-button"
    >
      <i [attr.class]="smacsIcons.SITE + ' me-1'"></i>
      {{ 'tkey;change.site.change_site' | translate }}
    </a>
  </div>
  <div class="public-phone-operations-menu__list-item">
    <a
      class="btn btn-link public-phone-operations-menu__list-item__content"
      data-automation="public-phone-copy-button"
      (click)="goToCopyPublicPhone()"
      [ngClass]="{ disabled: !isCopyPhoneButtonEnabled }"
      [ngbTooltip]="'tkey;pages.phone.public_phone.copy.disabled.tooltip' | translate"
      [disableTooltip]="isCopyPhoneButtonEnabled"
    >
      <i [attr.class]="smacsIcons.COPY + ' me-1'"></i>
      Copy Phone
    </a>
  </div>
</div>
