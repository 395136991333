import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClusterResult, SiteBasedHelpdeskUserPermission } from '../../../shared/models/generated/smacsModels';
import { FilterOptionSite } from './site-based-helpdesk-user-permissions-management.component';
import { SmacsFormConfig } from '../../../forms/smacs-forms-models';
import { SmacsRadioConfig } from '../../../forms/fields/radio/smacs-radio.component';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../forms/fields/checkbox/smacs-checkbox.component';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { SiteClusterSelection } from './site-based-helpdesk.enum';
import { SiteBasedHelpdeskManagementResource } from '../../resources/site-based-helpdesk-management-resource.service';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { ModalBodyClass } from '../../../modals/modal-body';
import { DetailedModalComponent } from '../../../modals/detailed-modal/detailed-modal.component';
import ChangeEvent = JQuery.ChangeEvent;

interface ModalViewProperties {
  permission: SiteBasedHelpdeskUserPermission;
  authorizedSitesFilterOpts: FilterOptionSite[];
  clusters: ClusterResult[];
}

@Component({
  selector: 'app-edit-permissions-modal-component',
  templateUrl: './edit-permissions-modal.component.html',
  styleUrls: ['./edit-permissions-modal.component.scss'],
})
export class AdminEditPermissionsModalComponent extends ModalBodyClass implements OnInit {
  formConfig: SmacsFormConfig;
  modalViewProperties: ModalViewProperties;
  authorizedClusterVal = [] as string[];
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  authorization: string;
  selectedSites = [] as string[];
  selectedClusters = [] as string[];
  filteredAuthorizedSitesOpts = [] as FilterOptionSite[];
  filteredAuthorizedClusterOpts = [] as ClusterResult[];
  resultFilter = '';

  private _authorizedSitesOpts = [] as FilterOptionSite[];
  private _authorizedClusterOpts = [] as ClusterResult[];

  @Input() manualValidationTrigger: EventEmitter<boolean>;

  constructor(
    private modalComponent: DetailedModalComponent<any>,
    private activeModal: NgbActiveModal,
    private siteBasedHelpdeskManagementResource: SiteBasedHelpdeskManagementResource,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.modalViewProperties = this.modalComponent.modalViewProperties;
    this.filteredAuthorizedSitesOpts = this._authorizedSitesOpts = this.modalViewProperties.authorizedSitesFilterOpts;
    this.filteredAuthorizedClusterOpts = this._authorizedClusterOpts = this.modalViewProperties.clusters;

    this.authorizedClusterVal = this.modalViewProperties.permission.authorizedClusters;
    this.selectedClusters = [...this.authorizedClusterVal];
    this.selectedSites = [...this.modalViewProperties.permission.authorizedSites];

    this.formConfig = {
      fields: {
        accessLevel: {
          label: '',
          dataAutomation: 'site-mac-man-edit-access-level',
          componentConfig: new SmacsRadioConfig<any>({
            buttons: [
              {
                value: SiteClusterSelection.SITES,
                label: 'tkey;admin.sitemacman.modal.sites.label',
              },
              {
                value: SiteClusterSelection.CLUSTERS,
                label: 'tkey;admin.sitemacman.modal.clusters.label',
              },
            ],
            inline: true,
            hideLabel: true,
          }),
        },
        isAccessDisabled: {
          label: 'tkey;admin.sitemacman.modal.disable.access',
          dataAutomation: 'site-mac-man-edit-disable-access',
          helpText: 'tkey;admin.sitemacman.modal.disable.access.help.text',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX }),
        },
        readAccessToReportingEnabled: {
          label: 'tkey;admin.sitemacman.modal.read_only.label',
          dataAutomation: 'site-mac-man-read-access-control-enabled',
          helpText: 'tkey;admin.sitemacman.modal.read_only.helptext',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX }),
        },
      },
    };
    this.entitySource.next({
      accessLevel: this.authorizedClusterVal.length !== 0 ? SiteClusterSelection.CLUSTERS : SiteClusterSelection.SITES,
      isAccessDisabled: this.modalViewProperties.permission.disabled,
      readAccessToReportingEnabled: this.modalViewProperties.permission.readAccessToControlEnabled,
    });

    this.authorization =
      this.authorizedClusterVal.length !== 0 ? SiteClusterSelection.CLUSTERS : SiteClusterSelection.SITES;
    this.smacsFormsUpdate$.subscribe(() => {
      this.resultFilter = this.authorization !== this.formData.accessLevel ? '' : this.resultFilter;
      this.authorization =
        this.authorization !== this.formData.accessLevel ? this.formData.accessLevel : this.authorization;
      this._filterCustomResults();
    });
  }

  onClose() {
    this.activeModal.close(false);
  }

  updateSiteSelection(site: string, $event: ChangeEvent): void {
    if ($event.target.checked) {
      this.selectedSites.push(site);
    } else if (!$event.target.checked) {
      this.selectedSites = this.selectedSites.filter((selectedSite) => selectedSite !== site);
    }
  }

  updateClusterSelection(cluster: string, $event: ChangeEvent): void {
    if ($event.target.checked) {
      this.selectedClusters.push(cluster);
    } else if (!$event.target.checked) {
      this.selectedClusters = this.selectedClusters.filter((selectedCluster) => selectedCluster !== cluster);
    }
  }

  isClusterNameDisplayed(siteIndex: number, clusterName: string): boolean {
    if (siteIndex === 0) {
      return true;
    } else {
      if (siteIndex < this.filteredAuthorizedSitesOpts.length) {
        if (this.filteredAuthorizedSitesOpts[siteIndex - 1].cluster !== clusterName) {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  onChanged(newValue: string) {
    this.resultFilter = newValue;
    this._filterCustomResults();
  }

  submit() {
    return this._onSave();
  }

  private _onSave(): Observable<void> {
    const siteBasedHelpdeskUserPermission = {
      userId: this.modalViewProperties.permission.userId,
      disabled: this.formData.isAccessDisabled,
      authorizedSites: this.selectedSites,
      authorizedClusters: this.selectedClusters,
      readAccessToControlEnabled: this.formData.readAccessToReportingEnabled,
    } as SiteBasedHelpdeskUserPermission;

    if (this.authorization === SiteClusterSelection.SITES) {
      siteBasedHelpdeskUserPermission.authorizedClusters = [];
    } else if (this.authorization === SiteClusterSelection.CLUSTERS) {
      siteBasedHelpdeskUserPermission.authorizedSites = [];
    } else {
      siteBasedHelpdeskUserPermission.authorizedSites = [];
      siteBasedHelpdeskUserPermission.authorizedClusters = [];
    }

    return this.siteBasedHelpdeskManagementResource.put(siteBasedHelpdeskUserPermission).pipe(
      tap(() => {
        this.activeModal.close(siteBasedHelpdeskUserPermission);
      })
    );
  }

  private _filterCustomResults(): void {
    if (this.authorization === SiteClusterSelection.CLUSTERS) {
      this.filteredAuthorizedClusterOpts = this._authorizedClusterOpts.filter((clusterResult: ClusterResult) => {
        return clusterResult.name.toLowerCase().includes(this.resultFilter.toLowerCase());
      });
    } else {
      this.filteredAuthorizedSitesOpts = this._authorizedSitesOpts.filter((optionSite: FilterOptionSite) => {
        return (
          optionSite.site.toLowerCase().includes(this.resultFilter.toLowerCase()) ||
          optionSite.cluster.toLowerCase().includes(this.resultFilter.toLowerCase())
        );
      });
    }
  }
}
