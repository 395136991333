<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 pb-2">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <p class="mb-0" [innerHtml]="'tkey;reporting.dashboard.description' | translate"></p>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div class="tile-container">
      <div class="row">
        <div class="col-md-6 col-lg-4 col-xl-3">
          <app-dashboard-orphaned-devices-tile></app-dashboard-orphaned-devices-tile>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3">
          <app-dashboard-unused-devices-tile></app-dashboard-unused-devices-tile>
        </div>
        <div class="col-md-6 col-lg-4 col-xl-3">
          <app-dashboard-license-optimizer-tile></app-dashboard-license-optimizer-tile>
        </div>
      </div>
    </div>
  </div>
</div>
