<app-top-nav></app-top-nav>

<div class="smacs-content">
  <nav
    data-automation="base-container-side-nav"
    class="navmenu navmenu-default offcanvas-sm bg-light smacs-admin-menu"
    role="navigation"
  >
    <ul class="nav flex-column">
      <li
        [ngClass]="activeWindow(link.url) ? 'nav-item active' : 'nav-item'"
        *ngFor="let link of links"
        [attr.data-automation]="link.automation"
        routerLinkActive="active"
      >
        <ng-container *ngIf="!link.isHidden">
          <a
            *ngIf="link.isRouterLink && !disableLinks(link.url)"
            class="nav-link"
            [routerLink]="link.url"
            [attr.id]="link.automation"
            [attr.data-automation]="link.automation + '-link'"
          >
            <span>
              {{ link.label | translate }}
            </span>
            <span *ngIf="link.isBeta" class="badge bg-warning rounded-pill align-self-center ms-1">
              {{ 'tkey;reporting.call_logs.link.beta.badge' | translate }}
            </span>
          </a>

          <a
            *ngIf="link.isRouterLink && disableLinks(link.url)"
            class="custom-disable"
            [attr.id]="link.automation"
            [attr.data-automation]="link.automation + '-link'"
            [ngbTooltip]="getDisableTooltip(link.url) | translate"
            [disableTooltip]="!disableLinks(link.url)"
          >
            <span>
              {{ link.label | translate }}
            </span>
          </a>

          <a
            *ngIf="!link.isRouterLink"
            [attr.target]="link.newTab ? '_blank' : '_self'"
            [attr.href]="!disableLinks(link.url) ? link.url : null"
            [attr.id]="link.automation"
            [attr.data-automation]="link.automation + '-link'"
            [ngbTooltip]="getDisableTooltip() | translate"
            [disableTooltip]="!disableLinks(link.url)"
            [ngClass]="{
              'custom-disable': disableLinks(link.url),
              'nav-link': !disableLinks(link.url)
            }"
          >
            <span>
              {{ link.label | translate }}
            </span>
          </a>
        </ng-container>
      </li>
    </ul>
  </nav>

  <div class="container-fluid animated fadeIn overflow-hidden ps-0 pe-0">
    <div
      class="offset-md-1 col-md-10 smacs-page-alert alert border-warning"
      *ngIf="pageDisabled"
      data-automation="smacs-page-disabled-alert"
    >
      <i class="{{ smacsIcons.WARNING }} text-warning fa-4x"></i>
      <div class="ms-3" [innerHTML]="pageDisabledMessage | translate"></div>
    </div>

    <router-outlet *ngIf="!pageDisabled"></router-outlet>
  </div>
</div>
