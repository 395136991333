import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DatatableColumn, DatatableComponent, DatatableRow } from '../../../reporting/datatable/datatable.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import moment from 'moment/moment';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { DatatableFilterService } from '../../../reporting/datatable/datatable-filter.service';
import { TranslateService } from '@ngx-translate/core';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';
import { OrderNumberStatusResource } from './order-number-status.resource';
import { NumberStatus, PrePortedNumberStatus } from '../../../shared/models/generated/smacsModels';
import { getProcessingStatusHtml, getStringFromProcessingStatus } from '../port-in-orders/processing-status-helper';
import { uniqWith } from 'lodash';
import { numericizePhoneNumber } from '../phone-number-format.service';

interface PortInNumberStatusDatatableRow extends DatatableRow {
  phoneNumber: string;
  customerOrderId: string;
  orderStatus: string;
  focDate: string;
  sbcConfiguration: string;
  user: string;
  dialPlan: string;
}

@Component({
  selector: 'ziro-port-in-number-status',
  templateUrl: './port-in-number-status.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [OrderNumberStatusResource],
})
export class PortInNumberStatusComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent) datatableComponent: DatatableComponent<PortInNumberStatusDatatableRow>;

  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  isLoading = true;
  orderStatusOptions: string[] = [];
  sbcConfigurationOptions: string[] = [
    this._translateService.instant(
      'tkey;admin.order_numbers.port_in_number_status.datatable.sbc_configuration.configured'
    ),
    this._translateService.instant(
      'tkey;admin.order_numbers.port_in_number_status.datatable.sbc_configuration.not_configured'
    ),
  ];
  datatableCols: DatatableColumn<PortInNumberStatusDatatableRow>[] = [
    {
      name: 'phoneNumber',
      label: 'tkey;admin.order_numbers.port_in_number_status.datatable.phone_number.label',
    },
    {
      name: 'customerOrderId',
      label: 'tkey;admin.order_numbers.port_in_number_status.datatable.customer_order_id.label',
    },
    {
      name: 'orderStatus',
      label: 'tkey;admin.order_numbers.port_in_number_status.datatable.order_status.label',
      sortFn: (a: string, b: string) => {
        return this._getTextFromHtml(a).localeCompare(this._getTextFromHtml(b));
      },
    },
    {
      name: 'focDate',
      label: 'tkey;admin.order_numbers.port_in_number_status.datatable.foc_date.label',
      sortFn: (a: string, b: string) => {
        if (a === b) {
          return 0;
        } else if (!a) {
          return -1;
        } else if (!b) {
          return 1;
        } else {
          return moment(b.split('<')[0].trim()).diff(a.split('<')[0].trim());
        }
      },
    },
    {
      name: 'sbcConfiguration',
      label: 'tkey;admin.order_numbers.port_in_number_status.datatable.sbc_configuration.label',
    },
    {
      name: 'user',
      label: 'tkey;admin.order_numbers.port_in_number_status.datatable.user.label',
    },
    {
      name: 'dialPlan',
      label: 'tkey;admin.order_numbers.port_in_number_status.datatable.dial_plan.label',
    },
  ];
  datatableRows: PortInNumberStatusDatatableRow[] = [];

  private _subscriptions = new Subscription();

  constructor(
    private _breadcrumbsService: BreadcrumbsService,
    private _datatableFilterService: DatatableFilterService,
    private _translateService: TranslateService,
    private _telephoneNumberFilter: TelephoneNumberFilter,
    private _orderNumberStatusResource: OrderNumberStatusResource
  ) {}

  ngOnInit() {
    this._setBreadcrumb();
    this._getStatuses();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  isOrderStatusMatchFn = (filter: string[], row: PortInNumberStatusDatatableRow): boolean => {
    return this._datatableFilterService.filterMultiSelectValues(
      filter.map((f: string) => f.toLowerCase()),
      this._getTextFromHtml(row.orderStatus).toLowerCase()
    );
  };

  isSbcConfigurationMatchFn = (filter: string[], row: PortInNumberStatusDatatableRow): boolean => {
    return this._datatableFilterService.filterMultiSelectValues(
      filter.map((f: string) => this._translateService.instant(f).toLowerCase()),
      this._getTextFromHtml(row.sbcConfiguration).toLowerCase()
    );
  };

  isDateMatchFn = (filter: Date[], row: PortInNumberStatusDatatableRow): boolean => {
    if (!filter || !filter.length) {
      return true;
    }

    const rowDate = moment(row.focDate, 'MMMM D, YYYY');
    const startDate = moment(filter[0], 'MMMM D, YYYY');
    const endDate = moment(filter[1], 'MMMM D, YYYY');

    return (
      rowDate.toISOString() === startDate.toISOString() ||
      rowDate.toISOString() === endDate.toISOString() ||
      rowDate.isBetween(startDate, endDate)
    );
  };

  matchesSecondaryDataFn = (searchValue: string, row: PortInNumberStatusDatatableRow): boolean => {
    if (!searchValue) return true;
    const readableSearchVal = searchValue.trim().toLowerCase();
    const formattedSearchValue = numericizePhoneNumber(searchValue);
    const formattedRowNumber = numericizePhoneNumber(row.phoneNumber);

    const formattedRowDate = this._getTextFromHtml(row.focDate).split('(Requested)')[0];
    const readableRowDate = moment(formattedRowDate).format('MMM D').toLowerCase();

    const searchDate =
      moment(searchValue).format('YYYY') !== '2001'
        ? moment(searchValue).toISOString()
        : moment(searchValue).format('DD-MM');
    const rowDate =
      moment(searchValue).format('YYYY') !== '2001'
        ? moment(formattedRowDate).toISOString()
        : moment(formattedRowDate).format('DD-MM');

    return (
      (searchDate && rowDate && searchDate === rowDate) ||
      formattedRowNumber.includes(formattedSearchValue) ||
      readableSearchVal === readableRowDate
    );
  };

  private _setBreadcrumb() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.order_numbers.port_in_number_status.title' }]);
  }

  private _getStatuses() {
    this._orderNumberStatusResource.get().subscribe({
      next: (data: PrePortedNumberStatus[]) => {
        this.datatableRows = this._mapStatusesToRows(data);
        this.isLoading = false;
      },
    });
  }

  private _mapStatusesToRows(statuses: PrePortedNumberStatus[]): PortInNumberStatusDatatableRow[] {
    this.orderStatusOptions = uniqWith(
      statuses.map((status: PrePortedNumberStatus) => getStringFromProcessingStatus(status.order.status)),
      (a: string, b: string) => a.toLowerCase() === b.toLowerCase()
    ).sort((a: string, b: string) => a.localeCompare(b));

    const rows: PortInNumberStatusDatatableRow[] = statuses
      .map((status: PrePortedNumberStatus) => {
        return status.numbers.map((numberStatus: NumberStatus) => {
          const buttonHref = !isNaN(Number(status.order.id))
            ? `/app2/#/admin/order-numbers/port-in-drafts/${status.order.id}?redirect=port-in-number-status`
            : `/app2/#/admin/order-numbers/port-in-orders/${status.order.id}?redirect=port-in-number-status`;
          const focDateFormatted = status.order.actualFocDate
            ? moment(status.order.actualFocDate).format('MMMM D, YYYY')
            : moment(status.order.requestedFoc)
            ? `${moment(status.order.requestedFoc).format('MMMM D, YYYY')} <strong>(${this._translateService.instant(
                'tkey;admin.order_numbers.port_in_number_status.datatable.requested'
              )})</strong>`
            : '';

          return {
            phoneNumber: this._telephoneNumberFilter.transform(numberStatus.number),
            customerOrderId: status.order.name,
            orderStatus: getProcessingStatusHtml(status.order.status.toUpperCase()),
            focDate: focDateFormatted,
            sbcConfiguration: this._getSbcBadge(numberStatus.isConfiguredOnSbc),
            user: numberStatus.userPrincipalName || '<span class="muted ps-2">N/A</span>',
            dialPlan: numberStatus.dialPlanGroup?.name || '<span class="muted ps-2">N/A</span>',
            editButtonHref: buttonHref,
          };
        });
      })
      .flat();

    return rows.sort(this._sortByOrderStatus.bind(this));
  }

  private _sortByOrderStatus(a: PortInNumberStatusDatatableRow, b: PortInNumberStatusDatatableRow) {
    const sortOrder: string[] = [
      'FOC Date Confirmed',
      'Submitted',
      'Support Requested',
      'Cancellation Requested',
      'Exception',
      'Draft',
      'Completed',
      'Cancelled',
    ];
    return (
      sortOrder.indexOf(this._getTextFromHtml(a.orderStatus)) - sortOrder.indexOf(this._getTextFromHtml(b.orderStatus))
    );
  }

  private _getSbcBadge(isConfiguredOnSbc: boolean) {
    if (isConfiguredOnSbc) {
      return `
        <span class="badge d-inline-flex bg-success">
          <i class="icon-check-circle"></i>
          <span class="ms-1 overflow-hidden" style="text-overflow: ellipsis;">
            ${this._translateService.instant(
              'tkey;admin.order_numbers.port_in_number_status.datatable.sbc_configuration.configured'
            )}
          </span>
        </span>
      `;
    } else {
      return `
        <span class="badge d-inline-flex bg-danger">
          <i class="icon-forbidden"></i>
          <span class="ms-1 overflow-hidden" style="text-overflow: ellipsis;">
            ${this._translateService.instant(
              'tkey;admin.order_numbers.port_in_number_status.datatable.sbc_configuration.not_configured'
            )}
          </span>
        </span>
      `;
    }
  }

  private _getTextFromHtml(html: string) {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent.trim() || element.innerText.trim() || '';
  }
}
