import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { JobState, MicrosoftBulkJobStatus } from '../../../../shared/models/generated/smacsModels';
import { MicrosoftBulkProvisioningPollingContext } from '../../../../shared/contexts/microsoft-bulk-provisioning-polling.context';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { isEqual } from 'lodash';
import { ZpmBulkProvisioningEntityTableContainerAbstractComponent } from '../status-table/zpm-bulk-provisioning-status-entity-table-abstract.component';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles } from '../../../../button/button.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ZpmUserPhotoContext } from '../../../../shared/contexts/zpm-user-photo.context';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ziro-zpm-bulk-provisioning-previous-job',
  templateUrl: './zpm-bulk-provisioning-previous-job.component.html',
  styleUrls: ['../../../../admin/admin-page.scss'],
  providers: [MicrosoftBulkProvisioningPollingContext],
})
export class ZpmBulkProvisioningPreviousJobComponent
  extends ZpmBulkProvisioningEntityTableContainerAbstractComponent
  implements OnInit, OnDestroy
{
  smacsIcons = SmacsIcons;
  jobState = JobState;
  isLoading = true;

  private _subscriptions = new Subscription();

  constructor(
    protected _translateService: TranslateService,
    private _breadcrumbsService: BreadcrumbsService,
    private _microsoftBulkProvisioningPollingContext: MicrosoftBulkProvisioningPollingContext,
    private _bottomNavService: BottomNavService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _zpmUserPhotoContext: ZpmUserPhotoContext
  ) {
    super(_translateService);
  }

  ngOnInit() {
    this._listenToPolling();
    this._initBottomNav();
  }

  ngOnDestroy() {
    this._microsoftBulkProvisioningPollingContext.stopPolling();
    this._subscriptions.unsubscribe();
  }

  getDateFromTimestamp(timestamp: string) {
    return moment(timestamp).format('YYYY-MM-DD');
  }

  getTimeFromTimestamp(timestamp: string) {
    return moment(timestamp).format('h:mm A');
  }

  private _listenToPolling() {
    this._microsoftBulkProvisioningPollingContext.state$.subscribe(this._handlePolling);
    this._microsoftBulkProvisioningPollingContext.startPolling();
  }

  private _listenToContext() {
    const userPhotoCtxSub = this._zpmUserPhotoContext.state$.subscribe(() => {
      const upns = this._zpmUserPhotoContext.getUpns();
      const userPhotos = this._zpmUserPhotoContext.getUserPhotos();
      this.tableRows = this.getTableRows(upns, userPhotos);
    });
    this._subscriptions.add(userPhotoCtxSub);
  }

  private _handlePolling = (microsoftBulkJobStatus: MicrosoftBulkJobStatus) => {
    if (!isEqual(this.bulkJobStatus, microsoftBulkJobStatus)) {
      this.bulkJobStatus = microsoftBulkJobStatus;
      this._updateBreadcrumbs();
      this.isLoading = false;
      if (this.bulkJobStatus.jobDetailedStatus) {
        const upnsToGet = [
          ...this.bulkJobStatus.jobDetailedStatus.completedUsers.map((userDetails) => userDetails.userPrincipalName),
          ...this.bulkJobStatus.jobDetailedStatus.failedUsers.map((userDetails) => userDetails.userPrincipalName),
          ...this.bulkJobStatus.jobDetailedStatus.inProgressUser.map((userDetails) => userDetails.userPrincipalName),
          ...this.bulkJobStatus.jobDetailedStatus.remainingUsers.map((userDetails) => userDetails.userPrincipalName),
        ];

        this._zpmUserPhotoContext.initUserPhotos(upnsToGet);

        const userPhotos = this._zpmUserPhotoContext.getUserPhotos();
        this.tableRows = this.getTableRows(upnsToGet, userPhotos);
        this.isLoadingTable = false;
        this._listenToContext();
      }
    }
  };

  private _updateBreadcrumbs() {
    this._breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;automate.zpm.bulk_provisioning.breadcrumb',
        url: '/automate/microsoft/bulk-provisioning',
        routerLink: true,
      },
      {
        label: this._translateService.instant('tkey;automate.zpm.bulk_provisioning.previous_job.breadcrumb', {
          auditTag: this.bulkJobStatus.jobDetailedStatus?.jobName,
        }),
      },
    ]);
  }

  private _initBottomNav() {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'previous-job-back',
          dataAutomation: 'previous-job-back',
          buttonClass: ButtonStyles.DEFAULT,
          icon: SmacsIcons.BACK,
          label: 'tkey;automate.zpm.bulk_provisioning.drafts.in_progress.bottom_nav.cancel',
          cb: () => {
            this._router.navigate(['..'], { relativeTo: this._route });
          },
        },
      ])
    );
  }
}
