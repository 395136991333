import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../button/button.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../shared/services/toast.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import {
  ClusterResult,
  DirectoryNumber,
  DirectoryNumberRef,
  DnDetailSummary,
  Global360View,
  LdapUserDialPlanAttributes,
  LineButton,
  Phone,
  PhoneRef,
  TranslationPatternRef,
  VoicemailRef
} from '../../../shared/models/generated/smacsModels';
import { combineLatest, forkJoin, Observable, of, Subscription } from 'rxjs';
import { Nvp } from '../../../shared/models/nvp';
import { ExtensionMobilityProfilesResource } from '../../../shared/resources/extension-mobility-profiles.resource';
import { ImPresenceResource } from '../../shared/resources/im-presence.resource';
import { SnrProfileResource } from '../../../self-serve/resources/snr-profile.resource';
import { PhoneResource } from '../../../shared/resources/phone.resource';
import { DnDetailSummaryResource } from '../../../shared/resources/dn-detail-summary.resource';
import { switchMap } from 'rxjs/operators';
import { DirectoryNumberResource } from '../../../self-serve/resources/directory-number.resource';
import { VoicemailResource } from '../../../shared/resources/voicemail.resource';
import { TranslationPatternResource } from '../../shared/resources/translation-pattern.resource';
import { EndUserResource } from '../../../shared/resources/end-user.resource';
import {
  PrimaryExtensionFieldConfigResource
} from '../../shared/resources/field-config/primary-extension-field-config.resource';
import { LdapUserResource } from '../../shared/resources/ldap-user.resource';
import {
  PhoneFieldConfigRequestParams,
  PhoneFieldConfigResource
} from '../../shared/resources/field-config/phone-field-config.resource';
import { PhoneType } from '../../../shared/models/service-type';
import { VoicemailService } from '../../../shared/services/voicemail.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { TranslateService } from '@ngx-translate/core';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';

@Component({
  selector: 'ziro-make-public',
  templateUrl: './make-public.component.html',
})
export class MakePublicComponent implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  isLoading = true;
  isSubmitting = false;
  showVoicemailCard = false;
  isKeepVoicemail = true;

  private _global360View: Global360View;
  private _subscriptions = new Subscription();
  private _auditTags: Nvp[];
  private _username: string;
  private _currentPrimaryExtension: DirectoryNumberRef;
  private _nonPrimaryIpccExtensions: string[] = [];
  private _currentCluster: ClusterResult;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _toastService: ToastService,
    private _breadcrumbsService: BreadcrumbsService,
    private _userDetailUiContext: UserDetailUiContext,
    private _extensionMobilityProfilesResource: ExtensionMobilityProfilesResource,
    private _imPresenceResource: ImPresenceResource,
    private _snrProfileResource: SnrProfileResource,
    private _phoneResource: PhoneResource,
    private _dnDetailSummaryResource: DnDetailSummaryResource,
    private _directoryNumberResource: DirectoryNumberResource,
    private _voicemailResource: VoicemailResource,
    private _translationPatternResource: TranslationPatternResource,
    private _endUserResource: EndUserResource,
    private _primaryExtensionFieldConfigResource: PrimaryExtensionFieldConfigResource,
    private _ldapUserResource: LdapUserResource,
    private _phoneFieldConfigResource: PhoneFieldConfigResource,
    private _voicemailService: VoicemailService,
    private _translateService: TranslateService,
    private _currentClusterContext: CurrentClusterContext
  ) {}

  ngOnInit(): void {
    this._username = this._route.snapshot.params.username;
    this._breadcrumbsService.updateBreadcrumbs([
      { label: this._username, url: `/user/${this._username}`, routerLink: true },
      { label: 'tkey;transfer.menu.to.public' },
    ]);

    const combineSub = combineLatest([this._currentClusterContext.state$, this._userDetailUiContext.state$]).subscribe(
      ([currentCluster]) => {
        this._currentCluster = currentCluster;
        this._global360View = this._userDetailUiContext.getGlobal360View();
        this._currentPrimaryExtension = this._userDetailUiContext.getCurrentPrimaryExtension();

        if (
          this._global360View.voicemails.some((vmRef) => vmRef.extension === this._currentPrimaryExtension.extension)
        ) {
          this.showVoicemailCard = true;
        }
        this.isLoading = false;
      }
    );
    this._subscriptions.add(combineSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onCancelClicked() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  onVoicemailButtonClicked(isKeeping: boolean) {
    this.isKeepVoicemail = isKeeping;
    this.showVoicemailCard = false;
  }

  onReassignClicked() {
    this.isSubmitting = true;
    this._auditTags = [{ name: 'transfer', value: `${this._username} > public phone` }];
    const phonesToEdit = this._userDetailUiContext.getDeskphones();
    const phonesToTransfer: Observable<PhoneRef>[] = [];
    phonesToEdit.forEach((phoneRef) => phonesToTransfer.push(this._transferDeskphones(phoneRef)));

    forkJoin([
      this._handleNonDeskphones(),
      this._handleSnr(),
      this._handleImPresence(),
      this._handleUdp(),
      this._handleVoicemail(),
      this._handleIpcc(),
      this._handleEndUser(),
      forkJoin(phonesToTransfer),
      this._transferPrimaryExtension(),
    ]).subscribe(() => {
      phonesToEdit.forEach((phoneRef) => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.DESKPHONE,
          'tkey;transfer.userToPublic.toast.text',
          this._translateService.instant('tkey;transfer.userToPublic.phoneName.text', {
            phoneName: phoneRef.name,
          })
        );
      });
      this._router.navigate([`/public-phone/${phonesToEdit[0].name}`]);
    });
  }

  private _handleUdp(): Observable<void[]> {
    const extMobToDelete = this._global360View.extensionMobilities
      .filter((extMobRef) => extMobRef.serverId === this._currentCluster.cucmServerId)
      .map((extMobRef) => {
        return this._extensionMobilityProfilesResource.delete(
          extMobRef.id,
          extMobRef.serverId.toString(),
          this._auditTags
        );
      });
    return extMobToDelete.length ? forkJoin(extMobToDelete) : of(null);
  }

  private _handleImPresence(): Observable<void> {
    const currentEndUserResult = this._userDetailUiContext.getCurrentEnduser();
    if (currentEndUserResult.imPresenceEnabled) {
      return this._imPresenceResource.delete(
        currentEndUserResult.ref.id,
        currentEndUserResult.ref.serverId.toString(),
        this._auditTags
      );
    } else {
      return of(null);
    }
  }

  private _handleSnr(): Observable<void[]> {
    const snrToDelete = this._global360View.snrProfiles
      .filter((snrRef) => snrRef.serverId === this._currentCluster.cucmServerId)
      .map((snrRef) => {
        return this._snrProfileResource.deleteSnrProfile(snrRef.id, snrRef.serverId, this._auditTags);
      });
    return snrToDelete.length ? forkJoin(snrToDelete) : of(null);
  }

  private _handleNonDeskphones(): Observable<void[]> {
    const nonDeskphonesToDelete = this._global360View.phones
      .filter((phoneRef) => phoneRef.serverId === this._currentCluster.cucmServerId && !phoneRef.name.startsWith('SEP'))
      .map((phoneRef) => {
        return this._phoneResource.delete(phoneRef.id, phoneRef.serverId.toString(), this._auditTags);
      });
    return nonDeskphonesToDelete.length ? forkJoin(nonDeskphonesToDelete) : of(null);
  }

  private _handleIpcc(): Observable<void | void[]> {
    const currentIpccExtensions = this._global360View.ipccExtensions.filter(
      (dnRef) => dnRef.serverId === this._currentCluster.cucmServerId
    );
    // If the user has an IPCC extension that does not match the primary extension, delete the IPCC DN as
    // well as the associated VM / TP
    if (!currentIpccExtensions.length) {
      return of(null);
    }

    const ipccRef: DirectoryNumberRef = currentIpccExtensions[0];
    const isNonPrimaryIpcc = this._global360View.primaryExtensions.some((primaryRef) => primaryRef.id !== ipccRef.id);

    if (!isNonPrimaryIpcc) {
      return of(null);
    }

    // Declare ipcc extension at this point so that _transferDeskphone method knows to clear the line before PUT
    this._nonPrimaryIpccExtensions.push(ipccRef.extension);

    const deleteVoicemail = (voicemail: VoicemailRef) => {
      return this._voicemailResource.delete(voicemail.serverId, voicemail.id, this._auditTags);
    };
    const deleteTranslationPattern = (didRef: TranslationPatternRef) => {
      return this._translationPatternResource.delete(didRef.id, didRef.serverId);
    };
    const deleteDnAndRelatedServices = (dnDetails: DnDetailSummary) => {
      const itemsToDelete: Observable<void>[] = [];
      if (dnDetails.voicemail) {
        itemsToDelete.push(deleteVoicemail(dnDetails.voicemail));
      }
      if (dnDetails.translationPatterns.length) {
        itemsToDelete.push(...dnDetails.translationPatterns.map(deleteTranslationPattern));
      }
      itemsToDelete.push(this._directoryNumberResource.delete(ipccRef.id, ipccRef.serverId, this._auditTags));
      return forkJoin(itemsToDelete);
    };

    return this._dnDetailSummaryResource.get(ipccRef.id, ipccRef.serverId.toString()).pipe(
      switchMap((dnDetails) => {
        if (dnDetails.voicemail || dnDetails.translationPatterns.length) {
          return deleteDnAndRelatedServices(dnDetails);
        } else {
          return this._directoryNumberResource.delete(ipccRef.id, ipccRef.serverId.toString(), this._auditTags);
        }
      })
    );
  }

  private _handleVoicemail(): Observable<void> {
    const vmRef = this._userDetailUiContext.getCurrentVoicemail();
    return !this.isKeepVoicemail ? this._voicemailResource.delete(vmRef.serverId, vmRef.id, this._auditTags) : of(null);
  }

  private _handleEndUser(): Observable<void> {
    const primaryExtension = this._userDetailUiContext.getCurrentPrimaryExtension();
    return this._endUserResource
      .get(
        this._userDetailUiContext.getCurrentEnduser().ref.id,
        this._userDetailUiContext.getCurrentEnduser().ref.serverId
      )
      .pipe(
        switchMap((endUser) => {
          return this._endUserResource.put(
            { ...endUser, primaryExtension: null, ipccExtension: null },
            this._userDetailUiContext.getCurrentEnduser().ref.serverId,
            this._auditTags
          );
        })
      )
      .pipe(
        switchMap((endUserRef) => {
          // If this is an LDAP user, clear the user's LDAP Dial Plan Attributes.
          if (this._userDetailUiContext.getCurrentEnduser().type === 'LDAP_ACTIVE') {
            const ldapUserDialPlanAttributes = {
              did: '',
              extension: '',
              username: endUserRef.username,
            } as LdapUserDialPlanAttributes;
            return this._ldapUserResource.put(ldapUserDialPlanAttributes, this._auditTags);
          } else return of(null);
        })
      );
  }

  private _transferDeskphones(phoneRef: PhoneRef): Observable<PhoneRef> {
    return this._phoneResource.get(phoneRef.id, phoneRef.serverId.toString()).pipe(
      switchMap((phone: Phone) => {
        const newPhoneValue = phone;
        // Remove owner
        newPhoneValue.owner = null;
        // Call phone field config resource and set description to expected value
        const requestParams: PhoneFieldConfigRequestParams = {
          model: phone.model,
          protocol: phone.protocol,
          siteId: this._userDetailUiContext.getCurrentSite().id.toString(),
        };
        return this._phoneFieldConfigResource.get(PhoneType.DESKPHONE, requestParams).pipe(
          switchMap((phoneFieldConfig) => {
            newPhoneValue.description = phoneFieldConfig.description.defaultValue;
            // Remove the current user from the phone's associated end user array.
            newPhoneValue.associatedEndUsers = newPhoneValue.associatedEndUsers.filter(
              (endUserRef) => endUserRef.username !== this._username
            );
            // For every configured line, remove the current user from the line's associated end user array.
            newPhoneValue.buttons.forEach((button, idx) => {
              if (button.type === 'Line') {
                let newButton = button as LineButton;
                if (newButton.lineFeature?.associatedEndUsers?.length) {
                  newButton.lineFeature.associatedEndUsers = newButton.lineFeature?.associatedEndUsers.filter(
                    (endUserRef) => endUserRef.username !== this._username
                  );
                }
                // If one of the deskphone's lines is the user's primary extension, clear out the label, external
                // call ID, and internal call ID fields for that line.
                if (newButton.dn?.extension === this._currentPrimaryExtension.extension) {
                  newButton.lineFeature.label = '';
                  newButton.lineFeature.externalCallerId = '';
                  newButton.lineFeature.internalCallerId = '';
                  newButton.dn.description = '';
                }
                // clear ippc line manually here so that all calls can be made asynchronously
                if (this._nonPrimaryIpccExtensions.includes(newButton.dn?.extension)) {
                  newButton = { dn: null, lineFeature: null, type: 'Line' };
                }
                newPhoneValue.buttons[idx] = newButton;
              }
            });
            return this._phoneResource.put(newPhoneValue, phoneRef.serverId.toString(), this._auditTags);
          })
        );
      })
    );
  }

  private _transferPrimaryExtension(): Observable<void | DirectoryNumberRef> {
    return this._directoryNumberResource
      .get(this._currentPrimaryExtension.id, this._currentPrimaryExtension.serverId.toString())
      .pipe(
        switchMap((dn) => {
          const newDn: DirectoryNumber = {
            ...dn,
            description: '',
            alertingName: '',
          };
          const editDn$ = this._directoryNumberResource.put(
            newDn.id,
            newDn,
            this._currentPrimaryExtension.serverId.toString(),
            this._auditTags
          );
          if (!this.isKeepVoicemail) {
            return editDn$.pipe(
              switchMap((dnRef) =>
                this._voicemailService.setForwardToVoicemail(
                  true,
                  this._username,
                  dnRef,
                  Number(this._userDetailUiContext.getCurrentSite().id),
                  true
                )
              )
            );
          } else {
            return editDn$.pipe();
          }
        })
      );
  }
}
