import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HealthStatusSettings } from '../../shared/models/generated/smacsModels';
import { Observable } from 'rxjs';

@Injectable()
export class SystemHealthStatusSettingsResource {
  constructor(private http: HttpClient) {}

  get(): Observable<HealthStatusSettings> {
    return this.http.get<HealthStatusSettings>('/services/system/admin/health-status-settings');
  }

  put(settings: HealthStatusSettings): Observable<void> {
    return this.http.put<void>('/services/system/admin/health-status-settings', settings);
  }
}
