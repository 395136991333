import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { ClusterResult, DevicePoolResult, SiteResult, SiteSummary } from '../models/generated/smacsModels';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { AuthenticationContext } from './authentication.context';
import { GlobalPropertiesContext } from './global-properties.context';

@Injectable()
export class SiteSummaryContext {
  _stateSource = new ReplaySubject<SiteSummary>(1); // public so we can access it in the workbench
  state$ = this._stateSource.asObservable();

  constructor(
    private http: HttpClient,
    private authenticationContext: AuthenticationContext,
    private globalPropertiesContext: GlobalPropertiesContext
  ) {
    combineLatest([this.authenticationContext.state$, this.globalPropertiesContext.state$]).subscribe(
      ([currentUser, globalProperties]) => {
        if (!globalProperties.hostedEnabled && currentUser && currentUser.userId) {
          this.refreshSiteSummary().subscribe();
        }
      }
    );
  }

  refreshSiteSummary(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this._getSiteSummary().subscribe((data) => {
        this._stateSource.next(data);
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  getClusterNameForSiteName = (summary: SiteSummary, siteName: string): string => {
    let clusterName = null;

    summary.clusters.forEach((cluster) => {
      cluster.sites.forEach((site) => {
        if (site.name === siteName) {
          clusterName = cluster.name;
        }
      });
    });

    if (!clusterName) {
      throw new Error(`cluster not found for site: ${siteName}`);
    }

    return clusterName;
  };

  getClusterIdForSiteId = (summary: SiteSummary, siteId: number): number => {
    let clusterId = null;

    summary.clusters.forEach((cluster) => {
      cluster.sites.forEach((site) => {
        if (site.id === siteId) {
          clusterId = cluster.id;
        }
      });
    });

    return clusterId;
  };

  getSiteFromId = (summary: SiteSummary, siteId: number): SiteResult => {
    return this._sites(summary).find((site) => site.id === siteId);
  };

  findSiteByLdapAttribute = (summary: SiteSummary, attributeValue: string) => {
    return this._sites(summary).find((site) => site.ldapSiteName === attributeValue);
  };

  getAllowedClusters = (summary: SiteSummary): ClusterResult[] => {
    return summary.clusters.filter((cluster) => cluster.sites.some((site) => site.hasPermission));
  };

  findCucmServerIdForCluster = (summary: SiteSummary, clusterId: number): number => {
    return summary.clusters.find((cluster) => cluster.id === clusterId).cucmServerId;
  };

  findCucmServerIdForSite = (summary: SiteSummary, siteId: number): number => {
    return summary.clusters.find((cluster) => cluster.sites.some((site) => site.id === siteId)).cucmServerId;
  };

  getClusterById(summary: SiteSummary, clusterId: number): ClusterResult {
    return summary.clusters.find((cluster) => cluster.id === clusterId);
  }

  isDeskphoneEnabledOnAnyClusters(summary: SiteSummary): boolean {
    const cluster = summary.clusters.find((cluster: ClusterResult) => {
      const site = cluster.sites.find((site: SiteResult) => {
        const deskphoneDevicePool = site.devicePools.find((devicePool: DevicePoolResult) => {
          if (devicePool.serviceName === 'Desk Phone') {
            return devicePool;
          }
        });

        if (site.hasPermission && deskphoneDevicePool) {
          return site;
        }
      });

      if (site) {
        return site;
      }
    });

    return !!cluster;
  }

  private _sites(summary: SiteSummary): SiteResult[] {
    return _.flatten(summary.clusters.map((cluster) => cluster.sites));
  }

  private _getSiteSummary(): Observable<SiteSummary> {
    return this.http.get<SiteSummary>('/services/cisco/views/site-summaries').pipe(shareReplay());
  }
}
