import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavProps, NavSectionLink } from '../../side-navigation.model';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'side-navigation-section-link',
  templateUrl: './side-navigation-section-link.component.html',
  styleUrls: ['./side-navigation-section-link.component.scss'],
})
export class SideNavigationSectionLinkComponent implements OnInit {
  @Input() text: string;
  @Input() newTab = false;
  @Input() isRouterLink = true;
  @Input() link: string;
  @Input() dataAutomation: string;
  @Input() isDisabled?: boolean;
  @Input() isVisible?: boolean = true;
  @Input() disabledTooltip?: string;
  @Input() navProps: NavProps;
  @Input() childLinks: NavSectionLink[];
  @Input() queryParams: { [key: string]: any };

  @Output() clickEvent = new EventEmitter<string>();

  isActive = false;

  constructor(private _router: Router) {}

  ngOnInit() {
    this._setIsActive();

    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._setIsActive();
      }
    });
  }

  onClick() {
    this.clickEvent.emit();
  }

  handleClickEvent(link: NavSectionLink) {
    link.onClick();
  }

  private _setIsActive() {
    this.isActive = this.link && (this._router.url.endsWith(this.link) || this._router.url.includes(`${this.link}/`));
  }
}
