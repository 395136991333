import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { SmacsLink } from '../shared/models/smacs-link';
import { GlobalPropertiesContext } from '../shared/contexts/global-properties.context';
import { CustomUiOptionsContext } from '../shared/contexts/custom-ui-options.context';
import { MicrosoftDialPlanFieldConfig } from '../shared/models/generated/smacsModels';
import { MicrosoftDialPlansFieldConfigContext } from '../shared/contexts/microsoft-dial-plans-field-config.context';

const onPremLinks = [
  {
    url: 'dashboard',
    label: 'tkey;menu.report.dashboard',
    automation: 'menu-dashboard-link',
    isRouterLink: true,
  },
  {
    url: 'orphaned-devices',
    label: 'tkey;menu.admin.admin.orphaned_devices',
    automation: 'menu-orphaned-devices-link',
    isRouterLink: true,
  },
  {
    url: 'unused-phone-button-templates',
    label: 'tkey;menu.report.unused_phone_button_template.title',
    automation: 'menu-unused-phone-button-template-link',
    isRouterLink: true,
  },
  {
    url: 'dn-availability',
    label: 'tkey;menu.report.dn_availability',
    automation: 'menu-license-link',
    isRouterLink: true,
  },
  {
    url: 'device-utilization',
    label: 'tkey;menu.report.device_utilization',
    automation: 'menu-license-link',
    isRouterLink: true,
  },
  {
    url: 'cucm-license-optimization',
    label: 'tkey;reporting.cucm_license_optimizer.overview.title',
    automation: 'menu-cucm-license-optimization-link',
    isRouterLink: true,
  },
  {
    url: 'global-directory',
    label: 'tkey;reporting.global_directory.title',
    automation: 'menu-global-directory-link',
    isRouterLink: true,
  },
  {
    url: 'bulk-line-appearance-management',
    label: 'tkey;reporting.bulk_line_appearance_management.title',
    automation: 'menu-line-appearance-management-link',
    isRouterLink: true,
  },
  {
    url: 'call-logs',
    label: 'tkey;reporting.call_logs.title',
    automation: 'menu-call-logs-link',
    isRouterLink: true,
  },
  {
    url: 'custom-reports',
    label: 'tkey;reporting.custom_reports.title',
    automation: 'menu-custom-reports-link',
    isRouterLink: true,
  },
];
const cloudLinks: SmacsLink[] = [
  {
    url: 'microsoft/dial-plan-inventory',
    label: 'tkey;reporting.microsoft.dial_plan_inventory.title',
    automation: 'reporting-microsoft-dial-plan-inventory-link',
    isRouterLink: true,
  },
  {
    url: 'microsoft/custom-powershell-reports',
    label: 'tkey;reporting.microsoft.custom_powershell_reports.title',
    automation: 'reporting-microsoft-custom-powershell-reports-link',
    isRouterLink: true,
  },
  {
    url: 'microsoft/policy-misconfiguration-report',
    label: 'tkey;reporting.microsoft.policy_misconfig.title',
    automation: 'reporting-microsoft-policy-misconfiguration-report-link',
    isRouterLink: true,
  },
];

@Component({
  selector: 'app-reporting',
  templateUrl: './reporting.component.html',
})
export class ReportingComponent implements OnInit, OnDestroy {
  pageDisabled = false;
  pageDisabledMessage = 'tkey;reporting.page_disabled.unlicensed';
  reportLinks: SmacsLink[] = [];
  isDialPlansConfigured = false;

  private _isHosted = false;
  private _reportingEnabled = false;
  private _subs = new Subscription();

  constructor(
    private _router: Router,
    private _globalPropertiesContext: GlobalPropertiesContext,
    private _customUiOptionsContext: CustomUiOptionsContext,
    private _microsoftDialPlansFieldConfigContext: MicrosoftDialPlansFieldConfigContext
  ) {}

  ngOnInit() {
    const sub = combineLatest([this._globalPropertiesContext.state$, this._customUiOptionsContext.state$]).subscribe(
      ([globalProperties, uiOptions]) => {
        this._isHosted = globalProperties.hostedEnabled;
        this._setupLinks();
        this._checkForDialPlans();

        this._reportingEnabled = uiOptions.reportingEnabled;
        this._checkReportLicense();
      }
    );

    const routerSub = this._router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this._checkReportLicense();
      }
    });

    this._subs.add(routerSub);
    this._subs.add(sub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private _checkReportLicense() {
    this.pageDisabled =
      !this._reportingEnabled &&
      !this._router.url.endsWith('/orphaned-devices') &&
      !this._router.url.endsWith('/dashboard') &&
      !this._router.url.endsWith('/cucm-license-optimization') &&
      !this._router.url.endsWith('/unused-phone-button-templates');
  }

  private _setupLinks() {
    if (this._isHosted) {
      this.reportLinks = [...cloudLinks];
    } else {
      this.reportLinks = [...onPremLinks];
    }
  }

  private _checkForDialPlans() {
    if (this._isHosted) {
      const subscription = this._microsoftDialPlansFieldConfigContext.state$.subscribe(
        (fieldConfig: MicrosoftDialPlanFieldConfig[]) => {
          this.isDialPlansConfigured = !!fieldConfig.length;
        }
      );
      this._subs.add(subscription);
    }
  }
}
