import { Component, OnDestroy } from '@angular/core';
import { ImportAbstractDirective, ImportFileTypes } from '../import.abstract.directive';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { BottomNavService } from '../../../shared/bottom-nav/bottom-nav.service';
import { ConfigurationDatabaseResource } from '../../resources/import.abstract.resource';
import { SystemStatusContext } from '../../../shared/contexts/system-status.context';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { forkJoin, Observable, Subscriber, throwError } from 'rxjs';
import { MsGraphConfigContext } from '../../contexts/ms-graph-config.context';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-admin-import-db',
  templateUrl: '../import.abstract.component.html',
  styleUrls: ['../../admin-page.scss', '../import.abstract.component.scss'],
})
export class ImportConfigurationComponent extends ImportAbstractDirective implements OnDestroy {
  warningText = 'tkey;pages.admin.import.warning.text';
  warningTextPreamble = 'tkey;pages.admin.import.warning.preamble.text';

  constructor(
    protected toastService: ToastService,
    protected systemStatusContext: SystemStatusContext,
    protected smacsModalService: SmacsModalService,
    protected bottomNavService: BottomNavService,
    protected smacsFormStateService: SmacsFormStateService,
    private configurationDatabaseResource: ConfigurationDatabaseResource,
    private breadcrumbsService: BreadcrumbsService,
    private _msGraphConfigContext: MsGraphConfigContext,
    protected translateService: TranslateService
  ) {
    super(
      toastService,
      systemStatusContext,
      smacsModalService,
      bottomNavService,
      smacsFormStateService,
      translateService
    );
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;pages.admin.import.header' }]);
    this.importFileType = ImportFileTypes.CONFIG;
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
  }

  requestImport = (): Observable<void> => {
    const request = this.getImportRequest();

    if (request) {
      return new Observable((subscriber: Subscriber<void>) => {
        this.configurationDatabaseResource.post(request).subscribe(() => {
          forkJoin([this.systemStatusContext.refreshSystemStatus(), this._msGraphConfigContext.refresh()]).subscribe(
            () => {
              subscriber.next(null);
              subscriber.complete();
            }
          );
        });
      });
    } else {
      return throwError(null);
    }
  };
}
