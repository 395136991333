export class ZeroTouchJob {
  id?: number;
  name: string;
  dailyJobTime: string;
  periodicJobMinutes: number;
}

export enum SCHEDULE_FREQUENCY {
  PERIODICALLY,
  DAILY,
}

export class MicrosoftZeroTouchJob {
  id?: number;
  name: string;
  options: any;
  schedule: any;
  searchFilter: string;
}
