import { Component } from '@angular/core';
import { EmailSettings } from '../../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { Observable } from 'rxjs';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { EmailSettingsContext } from '../../../shared/contexts/email-settings.context';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { ModalBodyClass } from '../../../modals/modal-body';
import { PromptModalComponent } from '../../../modals/prompt-modal/prompt-modal.component';

interface ModalViewProperties {
  emailSettings: EmailSettings;
  emailAddress: string;
}

@Component({
  selector: 'app-admin-email-configuration-modal',
  templateUrl: './admin-email-configuration-modal.component.html',
})
export class AdminEmailConfigurationModalComponent extends ModalBodyClass {
  modalViewProperties: ModalViewProperties;
  smacsIcons = SmacsIcons;

  formConfig = {
    fields: {
      emailAddress: {
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'Ex. jsmith@stack8.com',
        }),
        dataAutomation: 'email-config-modal-email-input',
        required: true,
        validation: [
          {
            validator: (val: string) =>
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                val
              )
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: 'tkey;admin.email_configuration.modal.email.validator.message',
          },
        ],
      },
    },
  } as SmacsFormConfig;

  constructor(
    private emailSettingsContext: EmailSettingsContext,
    private toastService: ToastService,
    protected smacsFormStateService: SmacsFormStateService,
    private modalComponent: PromptModalComponent<any>
  ) {
    super(smacsFormStateService);
  }

  submit() {
    return this._onFormSubmit();
  }

  _onFormSubmit(): Observable<boolean> {
    const enteredEmailAddress = this.formData.emailAddress;
    return new Observable<boolean>((subscriber) => {
      this.emailSettingsContext
        .test({
          ...this.modalComponent.modalViewProperties.emailSettings,
          emailAddress: enteredEmailAddress,
        })
        .subscribe({
          next: () => {
            this.emailSettingsContext.put(this.modalComponent.modalViewProperties.emailSettings).subscribe(() => {
              this.toastService.push(
                ToastTypes.SUCCESS,
                this.smacsIcons.INBOX,
                'tkey;shared.toast.save.success.title',
                'tkey;admin.email_configuration.toast.success.message'
              );
              subscriber.next(true);
              subscriber.complete();
            });
          },
          error: () => {
            this.toastService.push(
              ToastTypes.ERROR,
              this.smacsIcons.INBOX,
              'tkey;admin.email_configuration.toast.error.title',
              'tkey;admin.email_configuration.toast.error.message'
            );
            subscriber.next(false);
            subscriber.complete();
          },
        });
    });
  }
}
