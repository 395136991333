<div
  class="mb-3 row smacs-multi-checkbox"
  [hidden]="state.hidden && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted,
    'smacs-forms-feedback': showValidation,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage
  }"
>
  <label
    *ngIf="config.label"
    [class]="'smacs-forms-label ' + state.columnClasses?.label"
    [attr.for]="config.dataAutomation + '-input'"
    data-automation="smacs-multi-checkbox-label"
  >
    <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
    {{ getLabel() | translate }}
  </label>
  <div [class]="config.label ? state.columnClasses?.input : 'col-md-12'">
    <div *ngIf="fieldData">
      <div
        *ngFor="let key of objectKeys(fieldData); let i = index"
        [hidden]="isHidden(i)"
        class="form-check"
        [attr.data-automation]="'smacs-multi-checkbox-option-' + key"
      >
        <ng-template #tipContent><span [innerHtml]="getTooltip(i) | translate"></span></ng-template>
        <input
          type="checkbox"
          class="form-check-input"
          [attr.id]="id + '-' + config.dataAutomation + '-input-' + key"
          [attr.data-automation]="'smacs-multi-checkbox-option-' + key + '-input'"
          [(ngModel)]="fieldData[key]"
          #ngModelDir="ngModel"
          (ngModelChange)="updateStateAndSelf(fieldData, ngModelDir)"
          [disabled]="isDisabled(i) || state.disabled"
          [ngbTooltip]="tipContent"
          [disableTooltip]="(!isDisabled(i) && !state.disabled) || !hasTooltip(i)"
          [autoClose]="false"
          [container]="'body'"
          #t="ngbTooltip"
        />
        <label
          class="smacs-forms-label form-check-label"
          [ngClass]="{ 'text-start': optionConfigs[i]?.alignLeft }"
          [attr.data-automation]="'smacs-multi-checkbox-option-' + key + '-label'"
          [attr.for]="id + '-' + config.dataAutomation + '-input-' + key"
          (mouseover)="t.open()"
          (mouseleave)="t.close()"
        >
          <div data-automation="checkbox-label">
            {{ optionConfigs[i]?.label | translate }}
          </div>
          <div
            class="additional-content"
            *ngIf="optionConfigs[i]?.additionalContent"
            [innerHTML]="optionConfigs[i]?.additionalContent | translate"
          ></div>
        </label>
      </div>
    </div>

    <div class="smacs-forms-feedback-area">
      <div
        class="smacs-forms-validation-message"
        data-automation="smacs-forms-validation-message"
        [hidden]="!showValidation && !isFormSubmitted"
      >
        {{ validationMessage?.content | translate : validationMessage?.params }}
      </div>

      <div
        class="smacs-forms-validation-message text-warning"
        [ngClass]="{ 'd-inline': config.autogeneration?.inline }"
        data-automation="smacs-forms-warning-message"
        [hidden]="!misconfigurationFeedbackMessage"
      >
        {{ misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params }}
      </div>

      <span *ngIf="config.helpText" [innerHTML]="getHelpText() | translate" class="small form-text text-muted"></span>
    </div>
  </div>
</div>
