import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class PortInDraftSignedLoasResource {
  constructor(private http: HttpClient) {}

  generateSignedDocument(draftId: number): Observable<void> {
    return this.http.post<void>(`/services/microsoft/orders/port-in-drafts/${draftId}/generated-signed-loas`, null);
  }
}
