import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { AdWriteDownAttribute } from '../../../../../../shared/models/generated/smacsModels';
import { AdAttributeChildFormComponent } from './ad-attribute-child-form/ad-attribute-child-form.component';
import { SmacsFormsUpdate } from '../../../../../../forms/smacs-forms-models';
import { SmacsIcons } from '../../../../../../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../../../../../button/button.component';

@Component({
  selector: 'ziro-ad-attributes',
  templateUrl: './ad-attributes.component.html',
})
export class AdAttributesComponent implements OnInit {
  @ViewChildren(AdAttributeChildFormComponent) adAttributeChildren: QueryList<AdAttributeChildFormComponent>;
  @Output() adItemWasChanged = new EventEmitter<void>();
  @Input() initialAdAttributes: AdWriteDownAttribute[];
  @Input() dynamicPreviewValue: string;
  adAttributes: AdWriteDownAttribute[] = [];
  allAttributeNames: string[];

  ngOnInit(): void {
    if (!this.initialAdAttributes || !this.initialAdAttributes.length) {
      this.adAttributes = [].concat({
        attributeName: '',
        attributeValue: '',
        show: false,
        required: true,
      });
    } else {
      this.adAttributes = this.initialAdAttributes;
    }
  }
  onChildFormUpdate(event: SmacsFormsUpdate<AdWriteDownAttribute>) {
    if (event.new !== event.old) {
      this.adItemWasChanged.emit();
      this.allAttributeNames = this.getAllChildValues().map((childValue) => childValue.attributeName);
    }
  }

  areAllItemsValid(): boolean {
    return !this.adAttributeChildren.some((child) => !child.isFormValid());
  }

  validateAllChildren() {
    this.adAttributeChildren.forEach((child) => child._validateAndSubmitSource.next(true));
  }

  handleClearClicked(index: number) {
    this.adAttributes = [...this.adAttributeChildren.map((child) => child.formData).filter((_, idx) => idx !== index)];
    this.allAttributeNames = this.getAllChildValues()
      .map((childValue) => childValue.attributeName)
      .filter((_, idx) => idx !== index);
    this.adItemWasChanged.emit();
  }

  handleAddItemClicked() {
    this.adAttributes = this.adAttributes.concat({
      attributeName: '',
      attributeValue: '',
      show: false,
      required: true,
    });
  }

  getAllChildValues(): AdWriteDownAttribute[] {
    return this.adAttributeChildren.map((child) => child.formData);
  }

  protected readonly smacsIcons = SmacsIcons;
  protected readonly buttonSizes = ButtonSizes;
  protected readonly buttonStyles = ButtonStyles;
}
