import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { find, get } from 'lodash';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { map, skip, take } from 'rxjs/operators';
import { SnrProfileResource } from '../resources/snr-profile.resource';
import { ValidatorsService } from '../../shared/validators.service';
import { ToastService } from '../../shared/services/toast.service';
import {
  EndUserResult,
  SnrDestination,
  SnrDestinationResult,
  SnrProfileRef,
} from '../../shared/models/generated/smacsModels';
import { SelfServe360View, SelfServeUiContext, SnrContainer } from '../contexts/self-serve-ui.context';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { SnrService } from '../services/snr.service';
import { ButtonSizes, ButtonStyles } from '../../button/button.component';
import { SmacsModalService } from '../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { Nvp } from '../../shared/models/nvp';
import { SmacsFormAbstractDirective } from '../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../forms/smacs-forms-models';
import { HtmlInputType, SmacsTextConfig } from '../../forms/fields/text/smacs-text.component';
import { Global360ViewContext } from '../../shared/contexts/global-360-view.context';

interface SnrEdit {
  destinationNumber: string;
  delay: number;
}

@Component({
  selector: 'app-self-serve-snr-edit',
  templateUrl: './self-serve-snr-edit.component.html',
  providers: [SnrService],
})
export class SelfServeSnrEditComponent extends SmacsFormAbstractDirective<SnrEdit> implements OnInit, OnDestroy {
  isLoading = true;
  isDeleting = false;
  matchingDestinationName = '';
  user: EndUserResult;
  selfServe360View = {} as SelfServe360View;
  snrProfileRef: SnrProfileRef;
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  snrDestination = {} as SnrDestination;
  valueOnInit = {} as SnrDestination;
  auditTags: Nvp[] = [];

  validators = {
    _validateDestinationNotTaken: (val: string): Observable<SmacsFormsValidationState> => {
      return this.snrProfileResource.searchDestination(val, this.snrProfileRef.serverId).pipe(
        map((data: SnrDestinationResult[]) => {
          if (!!val) {
            const matchingDestinationSummary = this._findExactMatch(data, val);
            if (
              matchingDestinationSummary &&
              matchingDestinationSummary['ref']['destination'] !== this.valueOnInit.destinationNumber
            ) {
              this.matchingDestinationName = matchingDestinationSummary['ref']['name'];
              return SmacsFormsValidationState.INVALID;
            }
            this.matchingDestinationName = '';
            return SmacsFormsValidationState.VALID;
          }
          return SmacsFormsValidationState.VALID;
        })
      );
    },
  };

  formConfig = {
    fields: {
      destinationNumber: {
        label: 'tkey;selfserve.user.single_number_reach.destination_number.label',
        dataAutomation: 'self-serve-snr-edit-destination-input',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'tkey;selfserve.user.single_number_reach.destination_number.placeholder',
        }),
        required: true,
        validation: [
          {
            validator: (val: string) =>
              ValidatorsService.destinationFormatValidationFn(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: 'tkey;snr.snrdestination.destination.invalid_destination',
          },
          {
            validator: (val: string) => !val || this.validators._validateDestinationNotTaken(val),
            message: () =>
              this._translateService.instant('tkey;snr.snrdestination.destination.in_use', {
                destinationName: this.matchingDestinationName,
              }),
          },
        ],
      },
      delay: {
        label: 'tkey;selfserve.user.single_number_reach.delay.label',
        dataAutomation: 'self-serve-snr-edit-delay-input',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.NUMBER,
          placeholder: 'tkey;selfserve.user.single_number_reach.delay.label',
        }),
        required: true,
        validation: [
          {
            validator: (val: number) =>
              val >= 0 && val <= 30 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: 'tkey;snr.snrdestination.destination.range.text',
          },
        ],
      },
    },
  } as SmacsFormConfig;

  private _subscriptions = new Subscription();

  constructor(
    private snrProfileResource: SnrProfileResource,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private router: Router,
    private selfServeUiContext: SelfServeUiContext,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    protected smacsFormStateService: SmacsFormStateService,
    private global360ViewContext: Global360ViewContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initSelfServe();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  private _onFormSubmit(): Observable<void> {
    return new Observable((subscriber) => {
      this.snrDestination.delayBeforeRingingInSeconds = this.entity.delay;
      this.snrDestination.destinationNumber = this.entity.destinationNumber;

      if (this.snrDestination.id) {
        this.snrProfileResource
          .updateDestination(this.snrProfileRef.serverId, this.snrProfileRef.id, this.snrDestination, this.auditTags)
          .subscribe(() => {
            this.global360ViewContext.init(this.user.ref.username);
            this.selfServeUiContext.selfServe360View$
              .pipe(skip(1))
              .pipe(take(1))
              .subscribe(() => {
                this.toastService.pushSaveToast(
                  'tkey;self_serve.snr.destination.title',
                  this.snrDestination.destinationNumber,
                  this.smacsIcons.SNR
                );
                this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(this.user.ref.username)}`);
                subscriber.next(null);
                subscriber.complete();
              });
          });
      } else {
        this.snrDestination.destinationName = this.entity.destinationNumber;
        delete this.snrDestination.id;

        this.snrProfileResource
          .createDestination(this.snrDestination, this.snrProfileRef.serverId, this.snrProfileRef.id, this.auditTags)
          .subscribe(() => {
            this.toastService.pushSaveToast(
              'tkey;self_serve.snr.destination.title',
              this.snrDestination.destinationNumber,
              this.smacsIcons.SNR
            );
            this.global360ViewContext.init(this.user.ref.username);
            this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(this.user.ref.username)}`);

            subscriber.next(null);
            subscriber.complete();
          });
      }
    });
  }

  onDeleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        promptBody: this._translateService.instant('tkey;snr.snrdestination.delete.modal.message', {
          destination: this.snrDestination.destinationNumber,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteSnrDestination(),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteSnrDestination(): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      this.isDeleting = true;

      this.snrProfileResource
        .deleteDestination(this.snrProfileRef.serverId, this.snrProfileRef.id, this.snrDestination, this.auditTags)
        .subscribe(() => {
          this.global360ViewContext.init(this.user.ref.username);
          this.selfServeUiContext.selfServe360View$
            .pipe(skip(1))
            .pipe(take(1))
            .subscribe(() => {
              this.toastService.pushDeleteToast(
                'tkey;self_serve.snr.destination.title',
                this.snrDestination.destinationNumber
              );
              this.isDeleting = false;
              this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(this.user.ref.username)}`);
              subscriber.next(null);
              subscriber.complete();
            });
        });
    });
  }

  private _findExactMatch = (searchResults: SnrDestinationResult[], destNum: string) => {
    return find(searchResults, (destinationSummary) => {
      return destinationSummary.ref.destination === destNum;
    });
  };

  private _initSelfServe = () => {
    if (!this.selfServeUiContext.selfServe360View) {
      const username = get(this.route, 'snapshot.params.username');
      this.selfServeUiContext.initUserDetails(username);
    }

    const subscription = this.selfServeUiContext.selfServe360View$
      .pipe(take(1))
      .subscribe((selfServe360View: SelfServe360View) => {
        if (selfServe360View) {
          const snrDestinationId = get(this.route, 'snapshot.params.destinationId');
          const snrProfileId = get(this.route, 'snapshot.params.snrProfileId');
          this.user = selfServe360View.endUsers[0];
          this.auditTags.push({
            name: 'username',
            value: selfServe360View.endUsers[0].ref.username,
          });
          this.selfServe360View = selfServe360View;
          let initialSnrDestination;

          if (snrDestinationId) {
            const snrContainer = selfServe360View.snrContainers.find((snrCont: SnrContainer) => {
              const snrDes = snrCont.snrDestination.find(
                (snrDestination: SnrDestination) => snrDestination.id === snrDestinationId
              );

              if (snrDes) {
                return true;
              }
            });

            if (!snrContainer) {
              this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(this.user.ref.username)}`);
              return;
            }

            initialSnrDestination = snrContainer.snrDestination.find(
              (snrDestination: SnrDestination) => snrDestination.id === snrDestinationId
            );
            this.snrProfileRef = snrContainer.snrProfileRef;
          } else if (snrProfileId) {
            const snrContainer = selfServe360View.snrContainers.find(
              (snrCont: SnrContainer) => snrCont.snrProfileRef.id === snrProfileId
            );
            initialSnrDestination = {
              id: '',
              answerTooLateTimerInSeconds: 0,
              answerTooSoonTimerInSeconds: 1.5,
              delayBeforeRingingInSeconds: 4,
              destinationNumber: '',
              destinationName: '',
            };

            this.snrProfileRef = snrContainer.snrProfileRef;
          } else {
            this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(this.user.ref.username)}`);
            return;
          }

          this.valueOnInit = initialSnrDestination;
          this.snrDestination = initialSnrDestination;
          this.entitySource.next({
            destinationNumber: initialSnrDestination.destinationNumber,
            delay: initialSnrDestination.delayBeforeRingingInSeconds,
          });
          this.isLoading = false;
        }
      });
    this._subscriptions.add(subscription);
  };

  protected submit(): Observable<void> {
    return this._onFormSubmit();
  }
}
