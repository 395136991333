<div class="row">
  <div class="col-12">
    <smacs-text fieldId="name"></smacs-text>
    <smacs-radio fieldId="rangeType"></smacs-radio>
  </div>
</div>
<div class="row">
  <div class="col-6">
    <label
      [ngClass]="{
        'smacs-forms-error': extensionRangesDisplayFormValidationState === validationStates.INVALID,
        'smacs-forms-warning': extensionRangesDisplayFormValidationState === validationStates.WARNING
      }"
      data-automation="extension-ranges-label"
      class="smacs-forms-label col-form-label"
    >
      <span class="text-danger smacs-forms-asterisk">*</span>
      <span [innerText]="'tkey;admin.ms_dial_plan_exception.edit.add_number_ranges.label' | translate"></span>
    </label>
    <ziro-extension-ranges-display-form
      data-automation="extension-ranges-display-form"
      *ngIf="displayRangeForm"
      [entity]="displayRangeFormEntity"
      [optionalValidators]="displayRangeFormOptionalValidators"
      [isRequired]="true"
      (smacsFormsUpdate$)="onRangeFormUpdate($event)"
      [isZpm]="true"
      [exceptionRangeType]="entity.rangeType"
      (isValidFromDynamicValidator)="isValidFromDynamicValidator.next($event)"
    ></ziro-extension-ranges-display-form>
  </div>
  <div class="col-6">
    <smacs-textarea fieldId="bulkRanges"></smacs-textarea>
    <smacs-button
      [dataAutomation]="'exception-group-bulk-ranges-generate-button'"
      [label]="'tkey;admin.ms_dial_plan_exception.edit.bulk_generate.button.label'"
      (clicked)="generateBulkRangesClicked()"
      [isDisabled]="this.validationFlags['bulkRanges'] === validationStates.INVALID"
      [tooltip]="'tkey;dialplanmanagement.admin.group.generate.button.disabled_tooltip' | translate"
      [isTooltipDisabled]="this.validationFlags['bulkRanges'] === validationStates.VALID"
    ></smacs-button>
  </div>
</div>
