import { Injectable } from '@angular/core';

@Injectable()
export class UniqueIdService {
  private _idCache = {} as { [uid: string]: boolean };

  private static _generateId(): string {
    return Math.random().toString().slice(2);
  }

  getUniqueId() {
    let generatedId: string;

    do {
      generatedId = UniqueIdService._generateId();
    } while (this._idCache[generatedId]);

    this._idCache[generatedId] = true;
    return generatedId;
  }
}
