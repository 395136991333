import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { major, minor } from 'semver';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { ReleaseNotesModalViewProperties } from './release-notes-modal.component';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ReleaseNotesService {
  private VERSION_LOCAL_STORAGE_KEY = 'version';
  private _baseUrl = 'https://learn.goziro.com';

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  getLocalStorageVersion(): string {
    return localStorage.getItem(this.VERSION_LOCAL_STORAGE_KEY);
  }

  isValidRelease(version: string): boolean {
    return /^[0-9]+\.[0-9]+\.[0-9]+$/.test(version);
  }

  isNewVersion(previousVersion: string, currentVersion: string): boolean {
    if (!previousVersion && !this.isValidRelease(currentVersion)) {
      return false;
    } else if (!previousVersion && this.isValidRelease(currentVersion)) {
      localStorage.setItem(this.VERSION_LOCAL_STORAGE_KEY, currentVersion);
      return false;
    }

    const previousMajorVersion = major(previousVersion);
    const previousMinorVersion = minor(previousVersion);
    const currentMajorVersion = major(currentVersion);
    const currentMinorVersion = minor(currentVersion);

    return (
      this.isValidRelease(currentVersion) &&
      (previousMajorVersion < currentMajorVersion || previousMinorVersion < currentMinorVersion)
    );
  }

  setVersion(version: string) {
    localStorage.setItem(this.VERSION_LOCAL_STORAGE_KEY, version);
  }

  getVersionReleaseNotes(version: string): Observable<ReleaseNotesModalViewProperties> {
    const currentMinorVersion = version.replace(/\.\d*$/, '');

    return this._getAllReleaseNotes().pipe(
      switchMap((releaseNotes: any) => {
        const releaseNote = releaseNotes.topic_list.topics.find((topic: any) => {
          // we can't just use 'includes' for the version because 9.1 would match 9.11, etc.
          const title: string = topic.title;
          const versionMatch = title.match(/\d*\.\d*/);
          return !!versionMatch && versionMatch[0] === currentMinorVersion;
        });

        return this._getDocumentation(releaseNote.id).pipe(
          map((releaseNoteHtml: SafeHtml) => {
            return {
              html: releaseNoteHtml,
              title: releaseNote.title,
              slug: releaseNote.slug,
            };
          }),
          catchError(() => {
            return of(null);
          })
        );
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  private _getAllReleaseNotes(): Observable<any> {
    return this.http.get(`${this._baseUrl}/c/5/l/latest.json`);
  }

  private _getDocumentation(id: number): Observable<SafeHtml> {
    return this.http.get(`${this._baseUrl}/t/${id}.json`).pipe(
      map((response: any) => {
        const rawHtml = response.post_stream.posts[0].cooked;
        return this.sanitizer.bypassSecurityTrustHtml(rawHtml);
      })
    );
  }
}
