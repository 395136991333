<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.system.user_permissions.about' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <ziro-user-permissions-search-form [existingUserPermissions]="userPermissions"></ziro-user-permissions-search-form>

    <fieldset *ngIf="table" data-automation="user-permissions-list">
      <app-entity-table [table]="table" [tableRows]="tableRows"></app-entity-table>
    </fieldset>
  </div>
</div>
