<div [attr.data-automation]="dataAutomation" class="checkbox-button">
  <div
    class="button-container d-inline-block"
    [ngbTooltip]="tooltip | translate"
    [disableTooltip]="!tooltip || !disabled"
    [container]="'body'"
  >
    <button type="button" class="btn {{ buttonStyle }} {{ buttonSize }}" [ngClass]="{ 'text-nowrap': noTextWrap }">
      <label [attr.for]="dataAutomation + '-input'">
        <span class="checkbox-wrapper me-1">
          <input
            [disabled]="disabled"
            type="checkbox"
            class="form-check-input"
            [attr.id]="dataAutomation + '-input'"
            [attr.data-automation]="'smacs-checkbox-button-input'"
            [(ngModel)]="value"
            #ngModelDir="ngModel"
            (ngModelChange)="onCheck($event, ngModelDir)"
          />
        </span>
        <i *ngIf="icon" class="me-1 {{ icon }}" data-automation="smacs-checkbox-button-icon"></i>
        <span *ngIf="label" data-automation="smacs-checkbox-button-label">{{ label | translate }}</span>
      </label>
    </button>
  </div>
</div>
