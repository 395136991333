import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import {
  CucmLicenseOptimizationContext,
  LicenseOptimizationReport,
} from '../../contexts/cucm-license-optimization.context';
import { combineLatest, Subscription } from 'rxjs';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { UiOptions, UnusedDevicesReport } from '../../../../shared/models/generated/smacsModels';
import { ToastService } from '../../../../shared/services/toast.service';
import { ButtonStyles } from '../../../../button/button.component';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { CucmCdrUnusedDevicesContext } from '../../contexts/cucm-cdr-unused-devices.context';
import { EntityTable, EntityTableContentRow } from '../../../../shared/entity-table/entity-table.models';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomUiOptionsContext } from '../../../../shared/contexts/custom-ui-options.context';
import { get } from 'lodash';

@Component({
  selector: 'ziro-cucm-license-optimization-overview',
  templateUrl: './cucm-license-optimization-overview.component.html',
  styleUrls: ['../../../reporting.scss'],
})
export class CucmLicenseOptimizationOverviewComponent implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;

  licenseTier = decodeURIComponent(get(this._route, 'snapshot.params.licenseTier'));
  licenseTiers = ['CUWL Standard', 'Enhanced Plus', 'Enhanced', 'Basic', 'Essential'];
  licenseOptimizationReport: LicenseOptimizationReport;
  unusedDevicesReport: UnusedDevicesReport;
  isLoading = true;
  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];

  bottomNavButtons = [
    {
      id: 'exportLicenseOptimizerReport',
      dataAutomation: 'export-license-optimizer-report-button',
      label: 'tkey;reporting.xlsx_export.button',
      icon: SmacsIcons.EXPORT,
      buttonClass: ButtonStyles.PRIMARY,
      cb: () => {
        this.onGetFileClicked();
      },
    },
  ];

  removeEventListener: () => void;

  private _reportingEnabled = false;
  private _subscriptions = new Subscription();

  constructor(
    private bottomNavService: BottomNavService,
    private breadcrumbsService: BreadcrumbsService,
    private cucmCdrUnusedDevicesContext: CucmCdrUnusedDevicesContext,
    private licenseOptimizationContext: CucmLicenseOptimizationContext,
    private toastService: ToastService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private _route: ActivatedRoute,
    private _router: Router,
    private _customUiOptionsContext: CustomUiOptionsContext
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;reporting.cucm_license_optimizer.overview.title' }]);

    const subscription = combineLatest([
      this.cucmCdrUnusedDevicesContext.state$,
      this.licenseOptimizationContext.report$,
    ]).subscribe(([unusedDevicesReport, report]: [UnusedDevicesReport, LicenseOptimizationReport]) => {
      this.unusedDevicesReport = unusedDevicesReport;
      this._updateExportDisabledStatus();
      this.licenseOptimizationReport = report;
      this._updateExportDisabledStatus();
      this._buildTable();
      this.isLoading = false;
    });

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));

    const uiOptionsSub = this._customUiOptionsContext.state$.subscribe((uiOptions: UiOptions) => {
      this._reportingEnabled = uiOptions.reportingEnabled;
      this._updateExportDisabledStatus();
    });

    this._subscriptions.add(subscription);
    this._subscriptions.add(uiOptionsSub);

    this.removeEventListener = this.renderer.listen(this.elementRef.nativeElement, 'click', (event) => {
      if (
        event.target instanceof HTMLAnchorElement &&
        event.target.getAttribute('class') &&
        event.target.getAttribute('class').includes('excel-export')
      ) {
        this.onAnchorClicked(event);
      }
    });
  }

  ngOnDestroy() {
    this.removeEventListener();
    this._subscriptions.unsubscribe();
  }

  private onAnchorClicked(event: Event) {
    event.preventDefault();
    this.onGetFileClicked();
  }

  onGetFileClicked() {
    if (this._reportingEnabled) {
      this._setExportPending(true);
      this.licenseOptimizationContext.downloadFile().subscribe(() => {
        this._setExportPending(false);
        this.toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.EXPORT,
          'tkey;reporting.xlsx_export.downloaded_toast.title',
          'tkey;reporting.xlsx_export.downloaded_toast.message'
        );
      });
    }
  }

  private _setExportPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'exportLicenseOptimizerReport',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
          tooltipVisible: true,
        },
      })
    );
  }

  private _updateExportDisabledStatus() {
    const isDisabled = this.isLoading || this.unusedDevicesReport?.numCalls === 0 || !this._reportingEnabled;
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'exportLicenseOptimizerReport',
        state: {
          pending: false,
          buttonDisableState: {
            disabled: isDisabled,
            tooltipKey: 'tkey;reporting.cucm_license_optimizer.export.button.disabled.tooltip',
          },
          tooltipVisible: isDisabled,
        },
      })
    );
  }

  private _buildTable() {
    this.table = {
      columns: [
        {
          columnId: 'type',
          cssColumnSize: 'col-sm-3',
          label: 'tkey;reporting.cucm_license_optimizer.overview.license_type',
        },
        {
          columnId: 'currentTotal',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;reporting.cucm_license_optimizer.overview.current_licenses',
        },
        {
          columnId: 'optimalTotal',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;reporting.cucm_license_optimizer.overview.optimal_licenses',
        },
        {
          columnId: 'optimizableDevices',
          cssColumnSize: 'col-sm-4',
          label: 'tkey;reporting.cucm_license_optimizer.overview.optimizable_devices',
        },
      ],
      resultsMessage: 'tkey;reporting.cucm_license_optimizer.no_cdr.message',
      hasActions: true,
    };

    this.tableRows = this.licenseTiers.map((type) => {
      return {
        content: {
          type: type,
          currentTotal: this.licenseOptimizationReport[type].currentTotal,
          optimalTotal: this.licenseOptimizationReport[type].optimalTotal,
          optimizableDevices: this.licenseOptimizationReport[type].devices.length,
        },
        cssClass: this.licenseOptimizationReport[type].devices.length > 0 ? 'table-warning' : '',
        actions: [
          {
            buttonStyle: ButtonStyles.PRIMARY,
            dataAutomation: 'candidates-button',
            icon: SmacsIcons.NEXT,
            isDisabled: this.licenseOptimizationReport[type].devices.length === 0,
            tooltip: 'tkey;reporting.cucm_license_optimizer.overview.candidates_button.tooltip',
            tooltipDisabled: this.licenseOptimizationReport[type].devices.length > 0,
            onClick: () => this._nextClicked(type),
          },
        ],
      };
    });
  }

  private _nextClicked(type: string) {
    const url = encodeURIComponent(type);
    this._router.navigate([url], { relativeTo: this._route });
  }
}
