import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { SupportComponent } from '../shared/support/support.component';
import { TranslateModule } from '@ngx-translate/core';
import { LoadingBlockModule } from '../shared/loading-block/loading-block.module';
import { TopNavModule } from '../shared/top-nav/top-nav.module';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, TranslateModule, LoadingBlockModule, TopNavModule, RouterLink, FormsModule],
  declarations: [HomeComponent, SupportComponent],
  exports: [HomeComponent, SupportComponent],
})
export class HomeModule {}
