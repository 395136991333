import { Injectable } from '@angular/core';
import { intersection, uniq } from 'lodash';
import { SiteSummary } from '../../../shared/models/generated/smacsModels';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import {
  SbhPermissionsReportingOptions,
  SbhPermissionsTableFilterCriteria,
  SiteClusterIntersectionFilter,
} from './site-based-helpdesk-user-permissions-management.component';

@Injectable()
export class SbhPermissionsFilterService {
  constructor(private siteSummaryContext: SiteSummaryContext) {}

  siteClusterFilter = (
    criteria: SbhPermissionsTableFilterCriteria,
    rowToBeFiltered: SiteClusterIntersectionFilter,
    siteSummary: SiteSummary
  ): boolean => {
    const { name, siteNames, clusterNames } = rowToBeFiltered;

    let rowNameMatches = true;
    let siteMatches = true;
    let clusterMatches = true;
    let readAccessFilterMatches = true;

    // username filter
    if (criteria.name) {
      rowNameMatches = name.toLowerCase().includes(criteria.name.toLowerCase());
    }

    // site filter
    if (criteria.siteNames.length > 0) {
      if (siteNames.length > 0) {
        siteMatches = intersection(siteNames, criteria.siteNames).length === criteria.siteNames.length;
      } else if (clusterNames.length > 0) {
        const clustersOfSiteFilter = uniq(
          criteria.siteNames.map((s) => this.siteSummaryContext.getClusterNameForSiteName(siteSummary, s))
        );

        siteMatches = intersection(clusterNames, clustersOfSiteFilter).length === clustersOfSiteFilter.length;
      } else {
        siteMatches = false;
      }
    }

    // cluster filter
    if (criteria.clusterNames.length > 0) {
      if (siteNames.length > 0) {
        const clustersOfSites = siteNames.map((site) =>
          this.siteSummaryContext.getClusterNameForSiteName(siteSummary, site)
        );
        clusterMatches = criteria.clusterNames.every((cluster) => clustersOfSites.includes(cluster));
      } else if (clusterNames.length > 0) {
        clusterMatches = intersection(clusterNames, criteria.clusterNames).length === criteria.clusterNames.length;
      } else {
        clusterMatches = false;
      }
    }

    // Read access filter
    if (
      criteria.readAccess === SbhPermissionsReportingOptions.ENABLED &&
      !rowToBeFiltered.readAccessToReportingEnabled
    ) {
      readAccessFilterMatches = false;
    } else if (
      criteria.readAccess === SbhPermissionsReportingOptions.DISABLED &&
      rowToBeFiltered.readAccessToReportingEnabled
    ) {
      readAccessFilterMatches = false;
    }

    return rowNameMatches && siteMatches && clusterMatches && readAccessFilterMatches;
  };
}
