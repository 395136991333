import { UnusedTile } from '../models/report-dashboard.model';
import { dashboardActions } from '../actions/dashboard.actions';
import { createReducer, on } from '@ngrx/store';

export const initialUnusedTile: UnusedTile = {
  numCalls: 0,
  unusedCount: 0,
  percentUnused: 0,
  tileStatus: {
    message: 'tkey;reporting.dashboard.unused_devices.message.valid',
    status: null,
    value: 0,
    reportUrl: '../device-utilization',
    dataAutomation: 'unused-devices-tile',
  },
  isLoading: true,
};

export const unusedTileReducer = createReducer(
  initialUnusedTile,
  on(dashboardActions.loadUnusedTileAction, (state) => ({
    ...state,
  })),
  on(dashboardActions.loadUnusedTileSuccessAction, (state, payload) => ({
    ...state,
    numCalls: payload.numCalls,
    unusedCount: payload.unusedCount,
    percentUnused: payload.percentUnused,
    tileStatus: payload.tileStatus,
    isLoading: payload.isLoading,
  }))
);
