import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Microsoft365LicensesView } from '../models/generated/smacsModels';

@Injectable()
export class Microsoft365LicensesViewResource {
  constructor(private http: HttpClient) {}

  get(): Observable<Microsoft365LicensesView> {
    return this.http.get<Microsoft365LicensesView>('/services/microsoft/views/365-licenses');
  }
}
