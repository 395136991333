import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NavProps, NavSection, NavSectionLink } from './side-navigation.model';

@Component({
  selector: 'side-navigation',
  templateUrl: './side-navigation.component.html',
  styleUrls: ['./side-navigation.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.25s 0.25s ease-in', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('0.25s ease-out', style({ opacity: 0 }))]),
    ]),
  ],
})
export class SideNavigationComponent implements OnInit {
  @Input() title: string;
  @Input() navSections: NavSection[];
  @Input() navProps: NavProps;
  @Output() clickEvent = new EventEmitter<NavSectionLink>();

  isMinimized = false;
  searchFilter = '';
  filteredNavSections: NavSection[];

  constructor(private router: Router, private _translateService: TranslateService) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this._resetFilterAndSections();
      }
    });

    this.filteredNavSections = this.navSections;
  }

  toggleMenu() {
    this.isMinimized = !this.isMinimized;
  }

  onSearchFilterUpdate(searchFilter: string) {
    const searchFilterLowercase = searchFilter.toLowerCase();
    this.searchFilter = searchFilter;
    if (searchFilter === '') {
      this._resetFilterAndSections();
    } else {
      this.filteredNavSections = [];
      const filteredNavSections: NavSection[] = [];
      this.navSections.forEach((section: NavSection) => {
        if (!!section.isVisible && !section.isVisible(this.navProps)) {
          return;
        }

        const sectionTitle = this._translateService.instant(section.title);
        // If section title matches return entire section
        if (sectionTitle.toLowerCase().includes(searchFilterLowercase)) {
          filteredNavSections.push(section);
        } else {
          const childMatches = this._filterChildLinks(section, searchFilterLowercase);
          const filteredMatches = this._filterHiddenLinks(childMatches);

          // If it's only a child match we must filter out any child links that don't match
          if (filteredMatches.length) {
            filteredNavSections.push({
              ...section,
              links: filteredMatches,
            });
          }
        }
      });

      this.filteredNavSections = filteredNavSections;
    }
  }

  handleClickEvent(link: NavSectionLink) {
    if (this.isMinimized) {
      this.isMinimized = false;
    }
    this.clickEvent.emit(link);
  }

  expandSideNav() {
    this.isMinimized = false;
  }

  private _filterHiddenLinks(links: NavSectionLink[]): NavSectionLink[] {
    return links
      ? links.filter((sectionLink: NavSectionLink) => !sectionLink.isVisible || sectionLink.isVisible(this.navProps))
      : [];
  }

  private _filterChildLinks(section: NavSection | NavSectionLink, searchFilter: string): NavSectionLink[] {
    return section.links?.filter((link: NavSectionLink) => {
      const linkTitle = this._translateService.instant(link.text);
      const filteredChildLinks = this._filterChildLinks(link, searchFilter);
      return linkTitle.toLowerCase().includes(searchFilter) || (filteredChildLinks && filteredChildLinks.length > 0);
    });
  }

  private _resetFilterAndSections() {
    this.searchFilter = '';
    this.filteredNavSections = [...this.navSections];
  }
}
