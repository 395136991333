import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PhoneResult } from '../../shared/models/generated/smacsModels';
import { SmacsFormsMessage, SmacsFormsValidationState } from '../../forms/smacs-forms-models';
import { SearchPhoneResource } from '../../shared/resources/search-phone.resource';

export enum SearchPhoneValidationMessage {
  NOT_FOUND = 'tkey;selfserve.user.activate_deskphone.extension.not_found',
  MULTIPLE_FOUND = 'tkey;selfserve.user.activate_deskphone.extension.multiple_found',
  OWNED_BY_SELF = 'tkey;selfserve.user.activate_deskphone.extension.owned_by_self',
  OWNED_BY_OTHER = 'tkey;selfserve.user.activate_deskphone.extension.owned_by_other',
  WRONG_CLUSTER = 'tkey;selfserve.user.activate_deskphone.extension.wrong_cluster',
  VALID = 'tkey;selfserve.user.activate_deskphone.extension.valid',
}

@Injectable()
export class PhoneValidationService {
  private _phoneResult: PhoneResult;
  private _validationMessage: string | SmacsFormsMessage;

  constructor(private searchPhoneResource: SearchPhoneResource) {}

  searchPhoneAndSetValidationMessage(
    val: string,
    username: string,
    userServerId: any
  ): Observable<SmacsFormsValidationState> {
    this._phoneResult = null;
    return this.searchPhoneResource.get({ extension: val }).pipe(
      map<PhoneResult[], SmacsFormsValidationState>((results: PhoneResult[]) => {
        if (results.length < 1) {
          this._validationMessage = SearchPhoneValidationMessage.NOT_FOUND;
          return SmacsFormsValidationState.INVALID;
        }
        if (results.length > 1) {
          this._validationMessage = SearchPhoneValidationMessage.MULTIPLE_FOUND;
          return SmacsFormsValidationState.INVALID;
        }
        const result = results[0];
        if (result.owner) {
          if (result.owner.username === username) {
            this._validationMessage = SearchPhoneValidationMessage.OWNED_BY_SELF;
            return SmacsFormsValidationState.INVALID;
          }
          this._validationMessage = {
            content: SearchPhoneValidationMessage.OWNED_BY_OTHER,
            params: { userId: result.owner.username },
          };
          return SmacsFormsValidationState.INVALID;
        }
        if (result.ref.serverId !== userServerId) {
          this._validationMessage = SearchPhoneValidationMessage.WRONG_CLUSTER;
          return SmacsFormsValidationState.INVALID;
        }
        this._phoneResult = result;
        this._validationMessage = SearchPhoneValidationMessage.VALID;
        return SmacsFormsValidationState.VALID;
      })
    );
  }

  getValidationMessage(): string | SmacsFormsMessage {
    return this._validationMessage;
  }

  getPhoneResult(): PhoneResult {
    return this._phoneResult;
  }
}
