import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PostVoicemail, Voicemail, VoicemailRef } from '../models/generated/smacsModels';
import { SmacsModalService } from '../services/smacs-modal.service';
import { catchError } from 'rxjs/operators';
import { AuditHeaderService } from '../services/audit-header.service';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { ButtonStyles } from '../../button/button.component';
import { Nvp } from '../models/nvp';

@Injectable()
export class VoicemailResource {
  constructor(
    private http: HttpClient,
    protected smacsModalService: SmacsModalService,
    private auditHeaderService: AuditHeaderService
  ) {}

  get(unityServerId: number, id: string): Observable<Voicemail> {
    return this.http
      .get<Voicemail>(`/services/cisco/macs/unity-servers/${unityServerId}/voicemails/${id}`)
      .pipe(catchError(this._handleVoicemailError));
  }

  put(unityServerId: number, id: string, voicemail: Voicemail, auditTags?: Nvp[]): Observable<VoicemailRef> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http
      .put<VoicemailRef>(`/services/cisco/macs/unity-servers/${unityServerId}/voicemails/${id}`, voicemail, httpOptions)
      .pipe(catchError(this._handleVoicemailError));
  }

  delete(unityServerId: number, id: string, auditTags?: Nvp[]): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http
      .delete<void>(`/services/cisco/macs/unity-servers/${unityServerId}/voicemails/${id}`, httpOptions)
      .pipe(catchError(this._handleVoicemailError));
  }

  post(unityServerId: number, voicemail: PostVoicemail, auditTags?: Nvp[]): Observable<VoicemailRef> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http
      .post<VoicemailRef>(
        `/services/cisco/macs/unity-servers/${unityServerId}/voicemails`,
        {
          ...voicemail,
        },
        httpOptions
      )
      .pipe(catchError(this._handleVoicemailError));
  }

  private _handleVoicemailError = (error: any) => {
    const errorDescription = error ? error.error?.description : '';
    if (error.status === 422 && error.error.reasonCode === 'FORBIDDEN_DN_MODIFICATION') {
      const options = {
        modalViewProperties: {
          title: 'tkey;forbidden.user.title',
          promptBody: errorDescription,
          icon: SmacsIcons.FORBIDDEN,
          iconClass: 'text-danger',
          displayCloseButton: true,
          buttons: [
            {
              label: 'tkey;dialogs.button.ok',
              buttonClass: ButtonStyles.INFO,
              dataAutomation: 'custom-error-modal-ok',
            },
          ],
        },
      };
      this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
    }
    return throwError(() => error);
  };

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }
}
