import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingBlockComponent } from './loading-block.component';

@NgModule({
  declarations: [LoadingBlockComponent],
  exports: [LoadingBlockComponent],
  imports: [CommonModule],
})
export class LoadingBlockModule {}
