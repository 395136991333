import { Injectable } from '@angular/core';
import { ModelContextAbstract } from '../../shared/contexts/abstract/model.context.abstract';
import { SamlSsoConfig } from '../../shared/models/generated/smacsModels';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class SamlSsoConfigContext extends ModelContextAbstract<SamlSsoConfig> {
  protected _baseUrl = '/services/system/admin/saml-sso/settings';

  _stateSource = new ReplaySubject<SamlSsoConfig>(1);
  state$ = this._stateSource.asObservable();

  constructor(protected http: HttpClient) {
    super(http);
    this.refresh().subscribe();
  }

  refresh(): Observable<void> {
    return this._get('').pipe(
      map((samlSsoConfig) => {
        this._stateSource.next(samlSsoConfig);
      })
    );
  }

  set(samlSsoConfig: SamlSsoConfig): Observable<void> {
    return this._put('', samlSsoConfig).pipe(
      map(() => {
        this._stateSource.next(samlSsoConfig);
      })
    );
  }
}
