import { Component, Input, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import {
  MicrosoftSecurityGroupOption,
  MicrosoftSecurityGroupRef,
  MicrosoftSecurityGroupResult,
} from '../../../shared/models/generated/smacsModels';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { MicrosoftSecurityGroupSettingsResource } from '../../../shared/resources/microsoft-security-group-settings.resource';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../forms/fields/select/smacs-select.component';
import { MicrosoftSecurityGroupsSearchResource } from '../../resources/microsoft-security-groups-search.resource';
import { finalize, map, tap } from 'rxjs/operators';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';

export interface MsSecurityGroupManagementEntity {
  readOnlySecurityGroupList: MicrosoftSecurityGroupOption[];
  writeSecurityGroupList: MicrosoftSecurityGroupOption[];
}

export interface MsSecurityGroupManagementFormData {
  readOnlySecurityGroupList: MsSecurityGroupManagementSelectFormData[];
  writeSecurityGroupList: MsSecurityGroupManagementSelectFormData[];
}

interface MsSecurityGroupManagementSelectFormData {
  label: string;
  value: MicrosoftSecurityGroupRef;
}

interface SecurityGroupAsyncSearchParams {
  assignedOnly: boolean;
  entityKey: keyof MsSecurityGroupManagementEntity;
}

@Component({
  selector: 'ziro-security-group-management-form',
  templateUrl: './security-group-management-form.component.html',
  providers: [MicrosoftSecurityGroupsSearchResource],
})
export class SecurityGroupManagementFormComponent
  extends SmacsFormAbstractDirective<MsSecurityGroupManagementEntity, MsSecurityGroupManagementFormData>
  implements OnInit
{
  @Input() securityOptions: string[];
  @Input() securityGroups: MicrosoftSecurityGroupOption[];

  formConfig: SmacsFormConfig;

  private _subscriptions = new Subscription();
  private _overlappingErrorMessage = '';
  private _validators = {
    noOverlaps: (
      writeSecurityGroupList: MsSecurityGroupManagementSelectFormData[],
      fieldValues: MsSecurityGroupManagementSelectFormData[]
    ): SmacsFormsValidationState => {
      const writeGroupIds = writeSecurityGroupList.map(
        (group: MsSecurityGroupManagementSelectFormData) => group.value.id
      );

      const overlappingGroups = fieldValues.filter((fieldValue: MsSecurityGroupManagementSelectFormData) =>
        writeGroupIds.includes(fieldValue.value.id)
      );
      this._overlappingErrorMessage = this._translateService.instant(
        'tkey;admin.microsoft.security_group_management.form.error.overlapping'
      );
      overlappingGroups.forEach((group: MsSecurityGroupManagementSelectFormData) => {
        this._overlappingErrorMessage += ` <strong>${group.label}</strong>,`;
      });
      this._overlappingErrorMessage = this._overlappingErrorMessage.substring(
        0,
        this._overlappingErrorMessage.length - 1
      );

      return overlappingGroups.length ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
    },
  };

  constructor(
    private _bottomNavService: BottomNavService,
    private _toastService: ToastService,
    private _microsoftSecurityGroupsSettingsResource: MicrosoftSecurityGroupSettingsResource,
    private _microsoftSecurityGroupsSearchResource: MicrosoftSecurityGroupsSearchResource,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._initFormConfig();
    this._initBottomNav();
    const bottomNavErrorStateSub = combineLatest([this._validateAndSubmitSource, this.smacsFormsUpdate$]).subscribe({
      next: () => {
        this._bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: !this.isFormValid(),
          })
        );
      },
    });
    this._subscriptions.add(bottomNavErrorStateSub);
  }

  toEntity = (form: MsSecurityGroupManagementFormData): MsSecurityGroupManagementEntity => {
    return {
      readOnlySecurityGroupList: form.readOnlySecurityGroupList.map(
        (group: MsSecurityGroupManagementSelectFormData) => {
          return {
            description: '',
            displayName: group.value.displayName,
            id: group.value.id,
          };
        }
      ),
      writeSecurityGroupList: form.writeSecurityGroupList.map((group: MsSecurityGroupManagementSelectFormData) => {
        return {
          description: '',
          displayName: group.value.displayName,
          id: group.value.id,
        };
      }),
    };
  };

  toFormData = (entity: MsSecurityGroupManagementEntity): MsSecurityGroupManagementFormData => {
    return {
      readOnlySecurityGroupList: entity.readOnlySecurityGroupList.map((group: MicrosoftSecurityGroupOption) => {
        return {
          label: group.displayName,
          value: {
            displayName: group.displayName,
            id: group.id,
            url: null,
          },
        };
      }),
      writeSecurityGroupList: entity.writeSecurityGroupList.map((group: MicrosoftSecurityGroupOption) => {
        return {
          label: group.displayName,
          value: {
            displayName: group.displayName,
            id: group.id,
            url: null,
          },
        };
      }),
    };
  };

  protected submit() {
    return this._onSubmit();
  }

  private _onSubmit(): Observable<void> {
    this._setButtonState(true);
    if (this.entity.readOnlySecurityGroupList.length === 0 && this.entity.writeSecurityGroupList.length === 0) {
      const options = {
        windowClass: 'delete-button-modal',
        modalViewProperties: {
          title: 'tkey;admin.microsoft.security_group_management.modal.confirmation.title',
          promptBody: 'tkey;admin.microsoft.security_group_management.modal.confirmation.description',
          icon: SmacsIcons.WARNING,
          iconClass: 'text-warning',
          displayCloseButton: true,
          buttons: [
            {
              label: 'tkey;dialogs.button.cancel',
              buttonClass: ButtonStyles.DEFAULT,
              dataAutomation: 'confirmation-modal-cancel-button',
            },
            {
              label: 'tkey;dialogs.button.ok',
              buttonClass: ButtonStyles.PRIMARY,
              dataAutomation: 'confirmation-modal-confirm-button',
              cb: () => of(true),
            },
          ],
        },
      };
      return new Observable((subscriber) => {
        this._smacsModalService
          .openPromptModal(() => options.modalViewProperties, options)
          .subscribe((result) => {
            if (result) {
              this._putSettings().subscribe(() => {
                subscriber.next(null);
                subscriber.complete();
              });
            } else {
              this._setButtonState(false);
              subscriber.next(null);
              subscriber.complete();
            }
          });
      });
    } else {
      return this._putSettings();
    }
  }

  private _putSettings(): Observable<any> {
    return this._microsoftSecurityGroupsSettingsResource.put(this.entity).pipe(
      tap(() => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.MICROSOFT_365,
          'tkey;admin.microsoft.security_group_management.title',
          'tkey;shared.toast.save.success.title'
        );
      }),
      finalize(() => this._setButtonState(false))
    );
  }

  private _searchGroups(params: SecurityGroupAsyncSearchParams, query: string): Observable<SmacsSelectOption[]> {
    const selectedOptionIds = this.entity[params.entityKey].map((option: MicrosoftSecurityGroupOption) => option.id);

    return this._microsoftSecurityGroupsSearchResource.search(query, params.assignedOnly).pipe(
      map((results: MicrosoftSecurityGroupResult[]) => {
        return results
          .filter((result: MicrosoftSecurityGroupResult) => {
            return !selectedOptionIds.includes(result.microsoftSecurityGroupRefJson.id);
          })
          .map((result: MicrosoftSecurityGroupResult) => {
            return {
              label: result.microsoftSecurityGroupRefJson.displayName,
              value: result.microsoftSecurityGroupRefJson,
            };
          });
      })
    );
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        readOnlySecurityGroupList: {
          label: 'tkey;admin.microsoft.security_group_management.read_groups.label',
          dataAutomation: 'ms-security-group-management-read-groups',
          componentConfig: new SmacsSelectConfig({
            isMultiSelect: true,
            asyncOptionsFn: this._searchGroups.bind(this, {
              assignedOnly: false,
              entityKey: 'readOnlySecurityGroupList',
            }),
            minSearchLength: 3,
            placeholder: 'tkey;admin.microsoft.security_group_management.read_groups.placeholder',
          }),
          helpText: 'tkey;admin.microsoft.security_group_management.read_groups.helptext',
          validation: [
            {
              validator: this._validators.noOverlaps,
              message: () => this._overlappingErrorMessage,
              injectValuesFromFields: ['writeSecurityGroupList'],
            },
          ],
        },
        writeSecurityGroupList: {
          label: 'tkey;admin.microsoft.security_group_management.write_groups.label',
          dataAutomation: 'ms-security-group-management-write-groups',
          componentConfig: new SmacsSelectConfig({
            isMultiSelect: true,
            asyncOptionsFn: this._searchGroups.bind(this, {
              assignedOnly: true,
              entityKey: 'writeSecurityGroupList',
            }),
            minSearchLength: 3,
            placeholder: 'tkey;admin.microsoft.security_group_management.write_groups.placeholder',
          }),
          helpText: 'tkey;admin.microsoft.security_group_management.write_groups.helptext',
          validation: [
            {
              validator: this._validators.noOverlaps,
              message: () => this._overlappingErrorMessage,
              injectValuesFromFields: ['readOnlySecurityGroupList'],
            },
          ],
        },
      },
    };
  }

  private _initBottomNav() {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'security-group-management-save',
          dataAutomation: 'security-group-management-save',
          label: 'tkey;global.button.save.text',
          icon: SmacsIcons.OK,
          buttonClass: ButtonStyles.PRIMARY,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );
  }

  private _setButtonState(setting: boolean) {
    this._bottomNavService.setButtonPendingState('security-group-management-save', setting);
  }
}
