import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { MicrosoftBulkJobDraft, MicrosoftBulkJobDraftRef } from '../models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { delayWhen, map } from 'rxjs/operators';

@Injectable()
export class MicrosoftBulkProvisioningDraftsContext {
  private _stateSource = new ReplaySubject<MicrosoftBulkJobDraft[]>(1);
  state$ = this._stateSource.asObservable();

  private _baseUrl = '/services/microsoft/automate-jobs/draft-bulk-user-provisionings';

  constructor(private _http: HttpClient) {
    this.init().subscribe();
  }

  init(): Observable<void> {
    return this._getDrafts().pipe(
      map((data: MicrosoftBulkJobDraft[]) => {
        this._stateSource.next(data);
        return null;
      })
    );
  }

  getById(id: string): Observable<MicrosoftBulkJobDraft> {
    return this._http.get<MicrosoftBulkJobDraft>(`${this._baseUrl}/${id}`);
  }

  create(payload: MicrosoftBulkJobDraft): Observable<MicrosoftBulkJobDraftRef> {
    return this._http.post<MicrosoftBulkJobDraftRef>(this._baseUrl, payload).pipe(delayWhen(() => this.init()));
  }

  update(payload: MicrosoftBulkJobDraft): Observable<void> {
    return this._http.put<void>(`${this._baseUrl}/${payload.bulkJobId}`, payload).pipe(delayWhen(() => this.init()));
  }

  delete(draftId: any): Observable<void> {
    return this._http.delete<void>(`${this._baseUrl}/${draftId}`).pipe(delayWhen(() => this.init()));
  }

  private _getDrafts(): Observable<MicrosoftBulkJobDraft[]> {
    return this._http.get<MicrosoftBulkJobDraft[]>(this._baseUrl);
  }
}
