import { Injectable } from '@angular/core';
import {
  OptimizationTile,
  OrphanedTile,
  UnregisteredTile,
  UnusedTile,
} from '../state-management/models/report-dashboard.model';
import { DashboardTile, DashboardTileStatus } from '../../../shared/dashboard-tile/dashboard-tile.component';

@Injectable()
export class TileStatusService {
  optimizationPercentThresholds = {
    Good: 95,
    Warning: 85,
  };
  unregisteredPercentThresholds = {
    Good: 1,
    Neutral: 5,
    Warning: 15,
  };
  unusedPercentThresholds = {
    Good: 0.5,
    Neutral: 2,
    Warning: 5,
  };

  updateLicenceDashboardTileStatus(optimizationTile: OptimizationTile): DashboardTile {
    const optimizationTileStatus: DashboardTile = {
      message: 'tkey;reporting.dashboard.optimization',
      status: null,
      value: 0,
      reportUrl: '../cucm-license-optimization',
      dataAutomation: 'license-optimizer-tile',
    };
    if (optimizationTile.numCalls === 0) {
      optimizationTileStatus.message = 'tkey;reporting.dashboard.optimization';
      optimizationTileStatus.status = null;
    } else if (optimizationTile.totalLicenseCount === 0) {
      optimizationTileStatus.status = DashboardTileStatus.NEUTRAL;
    } else if (optimizationTile.optimizationPercent >= this.optimizationPercentThresholds.Good) {
      optimizationTileStatus.status = DashboardTileStatus.GOOD;
    } else if (optimizationTile.optimizationPercent >= this.optimizationPercentThresholds.Warning) {
      optimizationTileStatus.status = DashboardTileStatus.WARNING;
    } else {
      optimizationTileStatus.status = DashboardTileStatus.CRITICAL;
    }
    optimizationTile.numCalls === 0
      ? (optimizationTileStatus.value = '+')
      : (optimizationTileStatus.value = optimizationTile.optimizationPercent + '%');
    return optimizationTileStatus;
  }

  updateOrphanedDevicesTileStatus(orpahanedTile: OrphanedTile): DashboardTile {
    const orphanedDevicesTileStatus: DashboardTile = {
      message: 'tkey;reporting.dashboard.orphaned_devices',
      status: null,
      value: 0,
      reportUrl: '../orphaned-devices',
      dataAutomation: 'orphaned-devices-tile',
    };

    if (orpahanedTile.orphanedDeviceCount === 0) {
      orphanedDevicesTileStatus.status = DashboardTileStatus.GOOD;
    } else if (orpahanedTile.orphanedDeviceCount <= 20) {
      orphanedDevicesTileStatus.status = DashboardTileStatus.WARNING;
    } else {
      orphanedDevicesTileStatus.status = DashboardTileStatus.CRITICAL;
    }
    orphanedDevicesTileStatus.value = orpahanedTile.orphanedDeviceCount;
    return orphanedDevicesTileStatus;
  }

  updateUnregisteredTileStatus(unregisteredTile: UnregisteredTile): DashboardTile {
    const unregisteredTileStatus: DashboardTile = {
      message: 'tkey;reporting.dashboard.unregistered_devices',
      status: null,
      value: 0,
      reportUrl: '../deskphone-inventory',
      dataAutomation: 'deskphone-inventory-tile',
    };
    if (unregisteredTile.percentUnregistered < this.unregisteredPercentThresholds.Good) {
      unregisteredTileStatus.status = DashboardTileStatus.GOOD;
    } else if (unregisteredTile.percentUnregistered < this.unregisteredPercentThresholds.Neutral) {
      unregisteredTileStatus.status = DashboardTileStatus.NEUTRAL;
    } else if (unregisteredTile.percentUnregistered < this.unregisteredPercentThresholds.Warning) {
      unregisteredTileStatus.status = DashboardTileStatus.WARNING;
    } else {
      unregisteredTileStatus.status = DashboardTileStatus.CRITICAL;
    }
    if (unregisteredTile.unregisteredCount === 1) {
      unregisteredTileStatus.message = 'tkey;reporting.dashboard.unregistered_device';
    }
    unregisteredTileStatus.value = unregisteredTile.unregisteredCount;
    return unregisteredTileStatus;
  }

  updateUnusedTileStatus(unusedTile: UnusedTile, isReportsEnabled: boolean): DashboardTile {
    const unusedTileStatus: DashboardTile = {
      message: 'tkey;reporting.dashboard.unused_devices.message.valid',
      status: null,
      value: 0,
      reportUrl: '../device-utilization',
      dataAutomation: 'unused-devices-tile',
    };
    if (
      unusedTile.unusedCount != null &&
      unusedTile.percentUnused != null &&
      (unusedTile.numCalls || unusedTile.numCalls === 0)
    ) {
      if (unusedTile.numCalls > 0 && isReportsEnabled) {
        if (unusedTile.percentUnused < this.unusedPercentThresholds.Good) {
          unusedTileStatus.status = DashboardTileStatus.GOOD;
        } else if (unusedTile.percentUnused < this.unusedPercentThresholds.Neutral) {
          unusedTileStatus.status = DashboardTileStatus.NEUTRAL;
        } else if (unusedTile.percentUnused < this.unusedPercentThresholds.Warning) {
          unusedTileStatus.status = DashboardTileStatus.WARNING;
        } else {
          unusedTileStatus.status = DashboardTileStatus.CRITICAL;
        }
        if (unusedTile.unusedCount === 1) {
          unusedTileStatus.message = 'tkey;reporting.dashboard.unused_device.message.valid';
        } else {
          unusedTileStatus.message = 'tkey;reporting.dashboard.unused_devices.message.valid';
        }
        unusedTileStatus.value = unusedTile.unusedCount;
      } else {
        unusedTileStatus.message = 'tkey;reporting.dashboard.unused_devices.message.invalid';
        unusedTileStatus.value = '+';
        unusedTileStatus.status = null;
      }
      return unusedTileStatus;
    }
    return unusedTileStatus;
  }
}
