<app-breadcrumbs>
  <app-breadcrumb-card-item>
    <h4>{{ 'tkey;pages.zero_touch.provisioning.description.header' | translate }}</h4>
    <hr />
    <div [innerHTML]="'tkey;pages.zero_touch.provisioning.edit_job.description.text' | translate"></div>
    <div [innerHTML]="'tkey;pages.zero_touch.provisioning.edit_job.description.footer' | translate"></div>
  </app-breadcrumb-card-item>
</app-breadcrumbs>

<app-loading-block [hidden]="!isLoading"></app-loading-block>

<ul class="nav nav-tabs mb-1" [hidden]="isLoading">
  <li
    class="nav-item"
    [ngbTooltip]="'tkey;pages.zero_touch.provisioning.edit.need_save' | translate"
    [disableTooltip]="!ignoredUsersFormIsDirty"
  >
    <a
      class="btn nav-link"
      href="#"
      [ngClass]="{ active: jobEditView === JobEditViews.GENERAL_SETTINGS, disabled: ignoredUsersFormIsDirty }"
      (click)="switchEditView($event, JobEditViews.GENERAL_SETTINGS)"
      data-automation="zero-touch-provisioning-general-settings-tab"
    >
      {{ 'tkey;zero_touch.provisioning.edit_job.general_settings' | translate }}
    </a>
  </li>

  <li
    class="nav-item"
    [ngbTooltip]="'tkey;pages.zero_touch.provisioning.edit.need_save' | translate"
    [disableTooltip]="!generalSettingsFormIsDirty && !!jobId"
    [attr.data-automation]="'zero-touch-provisioning-ignored-users-tab-wrapper'"
  >
    <a
      class="btn nav-link"
      href="#"
      [ngClass]="{
        active: jobEditView === JobEditViews.IGNORED_USERS,
        disabled: generalSettingsFormIsDirty || !jobId
      }"
      (click)="switchEditView($event, JobEditViews.IGNORED_USERS)"
      data-automation="zero-touch-provisioning-ignored-users-tab"
    >
      {{ 'tkey;zero_touch.provisioning.edit_job.ignored_users' | translate }}
    </a>
  </li>
</ul>

<div [hidden]="isLoading || jobEditView !== JobEditViews.GENERAL_SETTINGS" class="pt-1">
  <smacs-text class="mt-1" [fieldId]="'name'"></smacs-text>
  <smacs-text [fieldId]="'ldapFilter'">
    <smacs-view-members
      class="w-25"
      [members]="ldapFilterMatches"
      [state]="ldapFilterState"
      [disabled]="ldapFilterViewMembersDisabled"
    ></smacs-view-members>
  </smacs-text>
  <smacs-select [fieldId]="'site'"></smacs-select>
  <smacs-select [fieldId]="'dialPlanGroups'"></smacs-select>
  <smacs-multi-checkbox [fieldId]="'services'"></smacs-multi-checkbox>
  <smacs-checkbox [fieldId]="'runAutomatically'"></smacs-checkbox>
  <smacs-radio [fieldId]="'scheduleFrequency'"></smacs-radio>
  <smacs-text [fieldId]="'periodicJobMinutes'"></smacs-text>
  <smacs-text [fieldId]="'dailyJobTime'"></smacs-text>

  <smacs-collapsible-card
    [label]="'tkey;pages.zero_touch.edit.form.job_status_notification_emails.title'"
    [shouldBeOpen]="emailSectionShouldBeOpenOnLoad"
    [isDisabled]="!isEmailSectionEnabled"
    [disabledTooltip]="'tkey;pages.zero_touch.provision.edit_job.email.disabled.tooltip'"
    dataAutomation="zero-touch-email-notifications"
    switchId="emailNotifications"
    (toggleSwitch)="cardSwitchToggled($event)"
  >
    <smacs-multi-text [fieldId]="'allOutcomeRecipients'"></smacs-multi-text>
    <smacs-multi-text [fieldId]="'successRecipients'"></smacs-multi-text>
    <smacs-multi-text [fieldId]="'failureRecipients'"></smacs-multi-text>
  </smacs-collapsible-card>
</div>

<app-admin-zero-touch-provisioning-edit-job-ignored-users
  [job]="job"
  [ignoredUsers]="ignoredUsers"
  [ldapUserResults]="ldapUserResults"
  [hidden]="isLoading || jobEditView !== JobEditViews.IGNORED_USERS"
  (ignoredUsersUpdate)="onIgnoredUsersUpdate($event)"
></app-admin-zero-touch-provisioning-edit-job-ignored-users>
