import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { get } from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CucmLicenseOptimizationOverviewComponent } from './cucm-license-optimization-overview/cucm-license-optimization-overview.component';

@Component({
  selector: 'app-cucm-license-optimization',
  templateUrl: './cucm-license-optimization.component.html',
  styleUrls: ['../../reporting.scss'],
})
export class CucmLicenseOptimizationComponent implements OnInit, OnDestroy {
  @ViewChild(CucmLicenseOptimizationOverviewComponent)
  childComponent: CucmLicenseOptimizationOverviewComponent;
  licenseTier = decodeURIComponent(get(this.route, 'snapshot.params.licenseTier'));
  childComponentLicenseReport: CucmLicenseOptimizationOverviewComponent;
  private _subs = new Subscription();

  constructor(private breadcrumbsService: BreadcrumbsService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this._setLicenseTier();
    const routeChange = this.router.events.subscribe(this._setLicenseTier);
    this._subs.add(routeChange);
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
    this._subs.unsubscribe();
  }

  onRouterOutletActivate(event: CucmLicenseOptimizationOverviewComponent) {
    this.childComponentLicenseReport = event;
  }

  private _setLicenseTier = () => {
    const tier = this.route.firstChild?.snapshot?.params['licenseTier'];
    if (tier) {
      this.licenseTier = decodeURIComponent(tier);
    }
  };
}
