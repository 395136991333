<app-loading-block [hidden]="!isSecondaryLineInfoLoading"></app-loading-block>
<div
  *ngIf="phoneUI?.hasLayout && !isSecondaryLineInfoLoading && !isSingleButtonSoftphone"
  data-automation="phone-frame-container"
  class="phone-frame-container text-center"
>
  <ng-container *ngFor="let page of paginatedButtons; let pageNumber = index">
    <div
      class="phone-frame"
      [class]="phoneUI?.background ? 'phone-frame--' + phoneUI.background : ''"
      [ngClass]="{
        'phone-frame--shifted-left':
          currentlyOpenPopoverPosition?.page === pageNumber && currentlyOpenPopoverPosition?.side === 'right',
        'phone-frame--shifted-right':
          (!isSelfServeMode &&
            currentlyOpenPopoverPosition?.page === pageNumber &&
            currentlyOpenPopoverPosition?.side === 'left') ||
          (isModalOpen() && !isSelfServeMode)
      }"
      *ngIf="page.left.length || page.right.length"
      [attr.data-automation]="'buttons-page-' + (pageNumber + 1)"
    >
      <div class="phone-frame__content">
        <div class="wrapper">
          <div class="overlay" *ngIf="isLoadingLineInfo || isPending" [attr.data-automation]="'phone-buttons-pending'">
            <div class="spinner-wrapper">
              <i class="icon fa fa-spinner fa-pulse"></i>
            </div>
          </div>

          <div [ngClass]="phoneFrameButtonClasses">
            <ul class="phone-frame__content__buttons__left nav nav-pills flex-column">
              <ng-container *ngIf="page.left.length">
                <app-phone-buttons-dnd-button
                  *ngFor="let indexedButton of page.left"
                  #dndButton="ngbPopover"
                  [ngbPopover]="editFormPopover"
                  [triggers]="'manual'"
                  [placement]="'left'"
                  [autoClose]="false"
                  [popoverClass]="'phone-buttons-edit-popover-window phone-buttons-edit-popover-window--left'"
                  (click)="
                    openButtonEditForm($event, dndButton, indexedButton, buttonStates[indexedButton.index], {
                      side: 'left',
                      page: pageNumber
                    })
                  "
                  class="phone-frame__content__buttons__left__button"
                  [isPending]="isPending || isLoadingLineInfo"
                  [button]="indexedButton.button"
                  [buttonIdx]="indexedButton.index"
                  [buttonState]="buttonStates[indexedButton.index]"
                  (deletedPhoneButton)="deleteSingleButton($event)"
                  [userMode]="userMode"
                  [dnSummary]="indexedButton.linePeripheralSummary"
                ></app-phone-buttons-dnd-button>
              </ng-container>
              <ng-container *ngIf="!page.left.length">
                <li
                  *ngFor="let button of phoneUI.numberLeft | counter"
                  class="phone-frame__content__buttons__right__button"
                >
                  <div class="phone-frame__content__buttons__right__button__placeholder"></div>
                </li>
              </ng-container>
            </ul>

            <ul class="phone-frame__content__buttons__right nav nav-pills flex-column">
              <ng-container *ngIf="page.right.length">
                <app-phone-buttons-dnd-button
                  *ngFor="let indexedButton of page.right"
                  #dndButton="ngbPopover"
                  [ngbPopover]="editFormPopover"
                  [triggers]="'manual'"
                  [placement]="'right'"
                  [autoClose]="false"
                  [popoverClass]="'phone-buttons-edit-popover-window phone-buttons-edit-popover-window--right'"
                  (click)="
                    openButtonEditForm($event, dndButton, indexedButton, buttonStates[indexedButton.index], {
                      side: 'right',
                      page: pageNumber
                    })
                  "
                  class="phone-frame__content__buttons__right__button"
                  [isPending]="isPending || isLoadingLineInfo"
                  [button]="indexedButton.button"
                  [buttonIdx]="indexedButton.index"
                  [buttonState]="buttonStates[indexedButton.index]"
                  [inverse]="true"
                  (deletedPhoneButton)="deleteSingleButton($event)"
                  [userMode]="userMode"
                  [dnSummary]="indexedButton.linePeripheralSummary"
                ></app-phone-buttons-dnd-button>
              </ng-container>

              <ng-container *ngIf="!page.right.length">
                <li
                  *ngFor="let button of phoneUI.numberRight | counter"
                  class="phone-frame__content__buttons__right__button"
                >
                  <div class="phone-frame__content__buttons__right__button__placeholder"></div>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
      </div>
      <div *ngIf="phoneUI.numberPages > 1" class="phone-frame__page-label badge rounded-pill text-muted">
        Page {{ pageNumber + 1 }} of {{ phoneUI.numberPages }}
      </div>
    </div>
  </ng-container>
</div>

<div
  *ngIf="!phoneUI?.hasLayout && !isSecondaryLineInfoLoading && !isSingleButtonSoftphone"
  data-automation="phone-frame-container"
  class="phone-frame-container text-center"
>
  <div class="default-wrapper">
    <div class="overlay" *ngIf="isLoadingLineInfo || isPending">
      <div class="spinner-wrapper">
        <i class="icon fa fa-spinner fa-pulse"></i>
      </div>
    </div>

    <ul
      class="default-phone-button-layout"
      [ngClass]="{ 'default-phone-button-layout--shifted-right': !!currentlyOpenPopoverPosition }"
      [attr.data-automation]="'buttons-default-layout'"
    >
      <app-phone-buttons-dnd-button
        *ngFor="let indexedPhoneButton of buttons"
        [isPending]="isPending || isLoadingLineInfo"
        #dndButton="ngbPopover"
        [ngbPopover]="editFormPopover"
        [triggers]="'manual'"
        [placement]="'left'"
        [autoClose]="false"
        [popoverClass]="'phone-buttons-edit-popover-window phone-buttons-edit-popover-window--left'"
        (click)="
          openButtonEditForm($event, dndButton, indexedPhoneButton, buttonStates[indexedPhoneButton.index], {
            side: 'left',
            page: 0
          })
        "
        class="default-phone-button-layout__button"
        [button]="indexedPhoneButton.button"
        [buttonIdx]="indexedPhoneButton.index"
        [buttonState]="buttonStates[indexedPhoneButton.index]"
        (deletedPhoneButton)="deleteSingleButton($event)"
        [userMode]="userMode"
        [dnSummary]="indexedPhoneButton.linePeripheralSummary"
      ></app-phone-buttons-dnd-button>
    </ul>
  </div>
</div>

<ng-template #editFormPopover let-indexedButton="indexedButton" let-isFixedButton="isFixedButton">
  <div data-automation="phone-buttons-edit-modal-edit-form">
    <smacs-phone-buttons-edit-form
      [validateChildFormFields]="updateFieldValidation"
      [entity]="indexedButton.button"
      [isFixedButton]="isFixedButton"
      [lineButtonSummary]="indexedButton.linePeripheralSummary"
      [lineFieldConfig]="indexedButton.lineFeatureConfig"
      [phone]="phone"
      [buttonIndex]="indexedButton.index"
      [buttonTypeOptions]="availableButtonTypeOptions"
      [isAutomaticPhoneTemplateSelectionEnabled]="isAutomaticPhoneTemplateSelectionEnabled"
      [userMode]="userMode"
      [global360View]="global360View"
      [siteId]="siteId"
      (smacsFormsUpdate$)="updateSingleButtonFromPopover($event, indexedButton.index)"
      (linePeripheralSummaryUpdated)="handlePeripheralUpdateFromForm($event, indexedButton.index)"
      (lineButtonDnUpdated)="handleLineFieldConfigUpdate($event, indexedButton.index)"
      (click)="$event.stopPropagation()"
    ></smacs-phone-buttons-edit-form>
  </div>
</ng-template>

<!-- Single phone button -->
<smacs-phone-buttons-edit-form
  *ngIf="
    buttons &&
    !!buttons[0]?.linePeripheralSummary &&
    !!buttons[0]?.lineFeatureConfig &&
    buttons.length === 1 &&
    isSingleButtonSoftphone
  "
  [validateChildFormFields]="updateFieldValidation"
  [entity]="buttons[0].button"
  [isFixedButton]="true"
  [lineButtonSummary]="buttons[0].linePeripheralSummary"
  [lineFieldConfig]="buttons[0].lineFeatureConfig"
  [phone]="phone"
  [buttonIndex]="buttons[0].index"
  [buttonTypeOptions]="availableButtonTypeOptions"
  [isAutomaticPhoneTemplateSelectionEnabled]="isAutomaticPhoneTemplateSelectionEnabled"
  [userMode]="userMode"
  [global360View]="global360View"
  [siteId]="siteId"
  (smacsFormsUpdate$)="updateSingleButtonFromPopover($event, buttons[0].index)"
  (linePeripheralSummaryUpdated)="handlePeripheralUpdateFromForm($event, buttons[0].index)"
  (lineButtonDnUpdated)="handleLineFieldConfigUpdate($event, buttons[0].index)"
></smacs-phone-buttons-edit-form>
