<div
  class="mb-3 row"
  [hidden]="state.hidden && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage,
    'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
  }"
>
  <label
    *ngIf="config.label"
    [ngClass]="state.columnClasses?.label"
    class="smacs-forms-label col-form-label"
    [attr.for]="config.dataAutomation + '-file-input'"
  >
    <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
    <span [disableTooltip]="!!config.labelToolTipIconClass" [ngbTooltip]="config.labelToolTipText | translate">
      {{ getLabel() | translate }}
    </span>
    <i
      *ngIf="config.labelToolTipIconClass"
      [ngbTooltip]="config.labelToolTipText | translate"
      class="{{ config.labelToolTipIconClass }} ms-1"
    ></i>
  </label>

  <div class="match-input-lg" [class]="config.label ? state.columnClasses?.input : 'col-12'">
    <div
      class="tooltip-wrapper d-block h-100"
      [ngClass]="{ 'tooltip-wrapper-disabled': state.disabled }"
      [ngbTooltip]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"
      [disableTooltip]="!state.disabled"
    >
      <div class="smacs-file-upload h-100">
        <span *ngIf="htmlInputAddOn && htmlInputAddOn?.prependedContent" class="input-text-start form-control">
          {{ htmlInputAddOn?.prependedContent | translate }}
        </span>
        <div class="smacs-file-upload__file-selection-area">
          <div
            class="smacs-file-upload__form-control"
            [ngClass]="{
              'is-invalid': validationState === ValStates.INVALID && isDirty,
              'smacs-file-upload__form-control--disabled': state.disabled
            }"
          >
            <span class="form-control">{{ entity?.name }}</span>

            <span
              class="{{ smacsIcons.SAVING }} form-control-feedback"
              [hidden]="!(validationState === ValStates.PENDING && showAsyncValidation)"
              data-automation="smacs-text-async-pending"
            ></span>

            <span
              class="{{ smacsIcons.REMOVE }} form-control-feedback"
              [hidden]="!(validationState === ValStates.INVALID && showAsyncValidation)"
              data-automation="smacs-text-async-invalid"
            ></span>

            <span
              class="{{ smacsIcons.OK }} form-control-feedback"
              [hidden]="!(validationState === ValStates.VALID && showAsyncValidation)"
              data-automation="smacs-text-async-valid"
            ></span>
          </div>

          <button
            class="input-group-addon input-btn-end btn btn-default smacs-file-upload__button h-100"
            [disabled]="state.disabled"
          >
            <i class="{{ smacsIcons.FILE }} fileinput-new"></i>
            <span>
              {{
                (entity ? 'tkey;shared.fileselect.change.button' : 'tkey;shared.fileselect.select.button') | translate
              }}
            </span>
            <input
              type="file"
              class="smacs-file-upload__file-selection-area__input"
              [attr.id]="config.dataAutomation + '-file-input'"
              [attr.accept]="acceptedFileExtensions?.acceptedExtensions || null"
              (change)="onFileChange($event.target['files'], ngModelDir)"
              [disabled]="state.disabled"
              [(ngModel)]="_filePath"
              #ngModelDir="ngModel"
              data-automation="file-upload-input"
            />
          </button>
        </div>

        <smacs-button
          *ngIf="entity"
          class="d-flex"
          [dataAutomation]="'file-upload-clear-button'"
          [cssClass]="'input-group-addon input-btn-end h-100'"
          [label]="'tkey;shared.fileselect.remove.button'"
          [buttonStyle]="buttonStyles.DEFAULT"
          [isDisabled]="state.disabled"
          (clicked)="onRemoveClicked(ngModelDir)"
        ></smacs-button>
        <span
          *ngIf="htmlInputAddOn && htmlInputAddOn?.appendedContent"
          class="input-group-text input-text-end form-control"
        >
          {{ htmlInputAddOn?.appendedContent | translate }}
        </span>
      </div>
    </div>

    <div class="smacs-forms-feedback-area">
      <div
        class="smacs-forms-validation-message"
        data-automation="smacs-forms-validation-message"
        [hidden]="!showValidation && !isFormSubmitted"
        [innerHTML]="validationMessage?.content | translate : validationMessage?.params"
      ></div>

      <div
        class="smacs-forms-validation-message text-warning"
        data-automation="smacs-forms-warning-message"
        [hidden]="!misconfigurationFeedbackMessage"
      >
        {{ misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params }}
      </div>

      <span class="small form-text text-muted" *ngIf="config.helpText">
        {{ getHelpText() | translate }}
      </span>
    </div>
  </div>
</div>
