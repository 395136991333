import { Component, OnDestroy, OnInit } from '@angular/core';
import { get } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { SiteResult } from '../../../../../shared/models/generated/smacsModels';
import { Subscription } from 'rxjs';
import { BreadcrumbsService } from '../../../../../shared/breadcrumbs/breadcrumbs.service';
import { TranslateService } from '@ngx-translate/core';
import { SiteSummaryContext } from '../../../../../shared/contexts/site-summary.context';

@Component({
  selector: 'app-site-bulk-field-copy',
  templateUrl: './site-bulk-field-copy.component.html',
  styleUrls: ['./site-bulk-field-copy.component.scss'],
})
export class SiteBulkFieldCopyComponent implements OnInit, OnDestroy {
  clusterId: number;
  currentClusterName: string;
  isLoading = true;
  sites: SiteResult[];
  private _subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private translateService: TranslateService,
    private siteSummaryContext: SiteSummaryContext
  ) {}

  ngOnInit() {
    this.clusterId = get(this.route, 'snapshot.params.clusterId');
    const siteSummaryContextSub = this.siteSummaryContext.state$.subscribe((siteSummary) => {
      const cluster = siteSummary.clusters.find((clusterResult) => Number(clusterResult.id) === Number(this.clusterId));
      this.currentClusterName = cluster.name;
      this.sites = cluster.sites;
      this._updateBreadCrumb();
      this.isLoading = false;
    });
    this._subscriptions.add(siteSummaryContextSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  private _updateBreadCrumb() {
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;admin.site_management.cluster_sites.header',
        url: '/app2/#/admin/site-management/clusters',
      },
      {
        label: this.translateService.instant('tkey;admin.site_management.site.header', {
          labelParam: this.currentClusterName,
        }),
        url: `/app2/#/admin/site-management/clusters/${this.clusterId}/sites`,
      },
      {
        label: 'tkey;admin.site_management.bulk_change.button',
      },
    ]);
  }
}
