import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SyncSettings } from '../../shared/models/generated/smacsModels';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GlobalPropertiesContext } from '../../shared/contexts/global-properties.context';

@Injectable()
export class ProxyConnectionSettingsContext implements OnDestroy {
  private _stateSource = new ReplaySubject<SyncSettings>(1);
  state$ = this._stateSource.asObservable();

  private _baseUrl = '/services/cisco/admin/proxy-connection-settings';
  private _subscriptions = new Subscription();

  constructor(private http: HttpClient, private globalPropertiesContext: GlobalPropertiesContext) {
    const sub = this.globalPropertiesContext.state$.subscribe((globalProperties) => {
      if (!globalProperties.hostedEnabled) {
        this._init();
      }
    });

    this._subscriptions.add(sub);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _init() {
    this._get().subscribe((syncSettings) => this._stateSource.next(syncSettings));
  }

  private _get(): Observable<SyncSettings> {
    return this.http.get<SyncSettings>(this._baseUrl);
  }

  put(settings: SyncSettings): Observable<void> {
    return this.http.put<void>(this._baseUrl, settings).pipe(tap(() => this.refresh()));
  }

  delete(): Observable<void> {
    return this.http.delete<void>(this._baseUrl).pipe(
      tap(() =>
        this._stateSource.next({
          host: null,
          secret: null,
        })
      )
    );
  }

  testConnection(settings: SyncSettings): Observable<void> {
    return this.http.post<void>(`${this._baseUrl}/connections`, settings);
  }

  refresh(): void {
    this._init();
  }
}
