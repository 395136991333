import { Component, OnInit } from '@angular/core';
import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { SmacsFieldComponentConfig, SmacsFormsValidationState } from '../../smacs-forms-models';
import { take } from 'rxjs/operators';
import { NgModel } from '@angular/forms';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import { ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { isEqual, isNil } from 'lodash';

type SmacsMultiTextValue = string[];

export class SmacsMultiTextConfig extends SmacsFieldComponentConfig {
  constructor(public config?: { placeholder?: string }) {
    super();
  }
}

const validators = {
  isPopulated: (val: string[]): SmacsFormsValidationState => {
    return val.indexOf('') === -1 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
  },
};

@Component({
  selector: 'smacs-multi-text',
  templateUrl: './smacs-multi-text.component.html',
  styleUrls: ['./smacs-multi-text.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsMultiTextComponent }],
})
export class SmacsMultiTextComponent
  extends SmacsFieldAbstractDirective<SmacsMultiTextValue, SmacsMultiTextValue, SmacsMultiTextConfig>
  implements OnInit
{
  shadowValue: SmacsMultiTextValue;
  validationTrackingArray: SmacsFormsValidationState[] = [];
  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;
  placeholder = '';

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.smacsFormsUpdate$.pipe(take(1)).subscribe((fieldState) => {
      this.shadowValue = fieldState.new ? [...fieldState.new] : [];
      if (this.shadowValue.length === 0) {
        this.shadowValue.push('');
      }
    });

    // With this pattern there is a known limitation where only one unique validation message can be shown at a time.
    // If we ever need to fix this, we should consider reworking this component into the "form within a form" pattern.
    this.smacsFormsUpdate$.subscribe((fieldState) => {
      // This is needed when the form state is updated after the form has already been initialized
      if (!isEqual(this.shadowValue, fieldState.new) && fieldState.new.length) {
        this.shadowValue = [...fieldState.new];
      }

      if (fieldState.valid === SmacsFormsValidationState.INVALID) {
        const failingValidator = this.config.validation.find(
          (validator) =>
            this.evaluateWithForm(validator.validator, fieldState.new, validator.injectValuesFromFields) ===
            SmacsFormsValidationState.INVALID
        );
        this.validationTrackingArray = fieldState.new.map((textValue) =>
          this.evaluateWithForm(failingValidator.validator, [textValue], failingValidator.injectValuesFromFields)
        );
        // add a new value if we want to display error message on empty field input
        if (this.validationTrackingArray && this.validationTrackingArray.length <= 0) {
          this.validationTrackingArray.push(SmacsFormsValidationState.INVALID);
        }
      } else {
        this.validationTrackingArray = fieldState.new.map(() => SmacsFormsValidationState.VALID);
      }
    });

    this.init$.subscribe(() => {
      this.config.validation = [
        {
          validator: validators.isPopulated,
          message: 'tkey;validators.global.required.error',
        },
        ...(this.config.validation || []),
      ];
      this.updateShadowValue$.subscribe((val) => {
        this.shadowValue = [...val];
        this.updateSelf(this.shadowValue);
      });
    });
  }

  applyComponentConfig = ({ config }: SmacsMultiTextConfig) => {
    this.placeholder = isNil(config) ? this.placeholder : config.placeholder;
  };

  transformData = ($event: string, inputIndex: number, ngModelDir: NgModel) => {
    this.shadowValue = this.shadowValue.map((val: string, index: number) => {
      if (index === inputIndex) {
        return $event;
      }

      return val;
    });

    if (this.shadowValue.length === 1 && !$event) {
      this.updateStateAndSelf([], ngModelDir);
    } else {
      this.updateStateAndSelf([...this.shadowValue], ngModelDir);
    }
  };

  trackData = (index: number) => {
    return index;
  };

  removeTextItem = (index: number, ngModelDir: NgModel) => {
    const newFieldData = [...this.shadowValue];
    newFieldData.splice(index, 1);

    this.shadowValue = [...newFieldData];
    this.updateStateAndSelf(newFieldData, ngModelDir);
  };

  addNewTextItem = () => {
    const newFieldData = [...this.shadowValue, ''];

    this.shadowValue = [...newFieldData];
    this.updateSelf(newFieldData);
  };

  autogenerateValue = () => {
    if (this.config && this.config.autogeneration && this.config.autogeneration.generateValue) {
      const newVal = this.evaluateWithForm(
        this.config.autogeneration.generateValue,
        this.fieldData,
        this.config.autogeneration.injectValuesFromFields
      );

      this.shadowValue = [...newVal];

      this._onReceiveNewEntity(newVal);
      this.isAutogenerated = true;
    }
  };
}
