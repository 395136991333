import { Component, OnInit } from '@angular/core';

import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { ActivatedRoute } from '@angular/router';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { Global360View, UccxAgent, UccxAgentRef, UccxFieldConfig } from '../../../shared/models/generated/smacsModels';
import { combineLatest, Subscription, switchMap } from 'rxjs';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import { UcMetadataCacheContext } from '../../../shared/contexts/uc-metadata-cache.context';
import { UccxAgentResource } from '../../shared/resources/uccx-agent.resource';
import { UccxAgentFieldConfigResource } from '../../shared/resources/field-config/uccx-agent-field-config.resource';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';

@Component({
  selector: 'ziro-uccx-agent',
  templateUrl: './uccx-agent.component.html',
})
export class UccxAgentComponent implements OnInit {
  isLoading = true;
  username: string;
  serverId: string;
  smacsIcons = SmacsIcons;
  uccxAgentRef: UccxAgentRef;
  uccxAgent: UccxAgent;
  global360View: Global360View;
  fieldConfig: UccxFieldConfig;
  resourceGroups: string[];
  private _subscriptions = new Subscription();

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private route: ActivatedRoute,
    private global360ViewContext: Global360ViewContext,
    private ucMetadataCacheContext: UcMetadataCacheContext,
    private userDetailUiContext: UserDetailUiContext,
    private uccxAgentResource: UccxAgentResource,
    private uccxAgentFieldConfigResource: UccxAgentFieldConfigResource
  ) {}

  ngOnInit(): void {
    this.username = this.route.snapshot.paramMap.get('username');
    this.serverId = this.route.snapshot.paramMap.get('serverId');
    this.global360ViewContext.init(this.username);

    const subs = combineLatest([
      this.global360ViewContext.state$,
      this.ucMetadataCacheContext.state$,
      this.userDetailUiContext.state$,
    ])
      .pipe(
        switchMap(([global360View, ucMetadataCache, userDetailUiLoaded]) => {
          this._updateBreadCrumb();
          this.global360View = global360View;
          if (global360View.uccxAgents.length > 0) {
            this.uccxAgentRef = this.global360ViewContext.getCurrentUccxAgent(global360View, Number(this.serverId));

            this.resourceGroups = ucMetadataCache.uccxMetadataCache[this.uccxAgentRef.serverId].resourceGroups;

            return combineLatest([
              this.uccxAgentResource.get(this.uccxAgentRef.serverId.toString(), this.uccxAgentRef.id.toString()),
              this.uccxAgentFieldConfigResource.getFieldConfigs(this.uccxAgentRef.serverId),
            ]);
          }
        })
      )
      .subscribe(([uccxAgent, fieldConfig]) => {
        this.uccxAgent = uccxAgent;
        this.fieldConfig = fieldConfig;
        this.isLoading = false;
      });
    this._subscriptions.add(subs);
  }

  private _updateBreadCrumb() {
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: this.username,
        url: `/app2/#/user/${encodeURIComponent(this.username)}`,
      },
      {
        label: 'tkey;shared.service.agent.text',
      },
    ]);
  }
}
