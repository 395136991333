import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Microsoft365UserLicenses } from '../models/generated/smacsModels';

@Injectable()
export class Microsoft365LicensesContext {
  private _stateSource = new ReplaySubject<Microsoft365UserLicenses>(1);
  state$ = this._stateSource.asObservable();

  constructor(protected http: HttpClient) {}

  init(userPrincipalName: string) {
    this.http
      .get<Microsoft365UserLicenses>(`/services/microsoft/macs/365-licenses/${userPrincipalName}`)
      .subscribe((userLicenses: Microsoft365UserLicenses) => {
        this._stateSource.next(userLicenses);
      });
  }

  updateUserLicenses(userPrincipalName: string, microsoft365UserLicenses: Microsoft365UserLicenses): Observable<void> {
    return this.http
      .put<void>(`/services/microsoft/macs/365-licenses/${userPrincipalName}`, microsoft365UserLicenses)
      .pipe(
        tap(() => {
          this._stateSource.next(microsoft365UserLicenses);
        })
      );
  }
}
