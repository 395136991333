<div class="mb-3">
  <span class="me-3 font-regular">{{ 'tkey;admin.sitemacman.modal.userId.label' | translate }}</span>
  <span>{{ modalViewProperties.permission.userId }}</span>
</div>
<smacs-radio [fieldId]="'accessLevel'"></smacs-radio>
<smacs-filter-input
  (filterChanged)="onChanged($event)"
  [value]="resultFilter"
  [dataAutomation]="'site-based-helpdesk-filter'"
></smacs-filter-input>
<div class="custom-table" *ngIf="authorization === 'SITES'">
  <div *ngFor="let item of filteredAuthorizedSitesOpts; let i = index">
    <div class="col-12 text-muted custom-table__header" *ngIf="isClusterNameDisplayed(i, item.cluster)">
      {{ item.cluster }}
    </div>
    <div class="col-12 form-check custom-table__row">
      <input
        type="checkbox"
        name="'{{ item.cluster }}'"
        class="form-check-input"
        id="select-site-{{ item.site }}"
        [checked]="selectedSites.indexOf(item.site) > -1"
        (change)="updateSiteSelection(item.site, $event)"
      />
      <label for="select-site-{{ item.site }}" class="form-check-label">
        {{ item.site }}
      </label>
    </div>
  </div>
</div>
<div class="custom-table" *ngIf="authorization === 'CLUSTERS'">
  <div class="d-flex" *ngFor="let item of filteredAuthorizedClusterOpts">
    <div class="col-12 form-check custom-table__row">
      <input
        type="checkbox"
        class="form-check-input"
        id="select-cluster-{{ item.name }}"
        [checked]="selectedClusters.indexOf(item.name) > -1"
        (change)="updateClusterSelection(item.name, $event)"
      />
      <label for="select-cluster-{{ item.name }}" class="form-check-label">{{ item.name }}</label>
    </div>
  </div>
</div>
<div class="checkbox-container">
  <smacs-checkbox [fieldId]="'readAccessToReportingEnabled'"></smacs-checkbox>
  <smacs-checkbox [fieldId]="'isAccessDisabled'"></smacs-checkbox>
</div>
