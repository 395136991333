<div data-automation="about-rest-page" class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 pb-5">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;api.page.breadcrumb.description' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <legend>{{ 'tkey;api.page.section.auth_header.title' | translate }}</legend>
    <p>{{ 'tkey;api.page.section.auth_header.description' | translate }}</p>
    <div [innerHtml]="'tkey;api.page.section.auth_header.table.content' | translate"></div>
    <!-- REST TYPES -->
    <legend>{{ 'tkey;api.page.section.type.reason.code.title' | translate }}</legend>
    <div [innerHtml]="'tkey;api.page.request.type.table.body.content' | translate"></div>
    <!-- Reason Codes -->
    <legend>{{ 'tkey;api.page.section.other.reason.code.title' | translate }}</legend>
    <div [innerHtml]="'tkey;api.page.section.other.reason.code.table.content' | translate"></div>
    <!-- API Sections -->
    <div *ngIf="!isHostedEnabled">
      <legend>{{ 'tkey;api.page.section.cisco.title' | translate }}</legend>
      <p>{{ 'tkey;api.page.section.cisco.description' | translate }}</p>
      <table class="table table-bordered">
        <thead>
          <tr class="table-secondary d-flex">
            <th class="col-2">{{ 'tkey;api.page.column.section.title' | translate }}</th>
            <th class="col-10">{{ 'tkey;api.page.column.description.title' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr class="d-flex" *ngFor="let section of ciscoSection">
            <th class="col-2">
              {{ section.title | translate }}
            </th>
            <td class="col-10" [innerHTML]="section.content | translate"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div *ngIf="isHostedEnabled">
      <legend>{{ 'tkey;api.page.section.microsoft.title' | translate }}</legend>
      <p>{{ 'tkey;api.page.section.microsoft.description' | translate }}</p>
      <table class="table table-bordered">
        <thead>
          <tr class="table-secondary">
            <th>{{ 'tkey;api.page.column.section.title' | translate }}</th>
            <th>{{ 'tkey;api.page.column.description.title' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let section of microsoftSection">
            <th class="col-2">
              {{ section.title | translate }}
            </th>
            <td [innerHTML]="section.content | translate"></td>
          </tr>
        </tbody>
      </table>
    </div>
    <legend>{{ 'tkey;api.page.section.system.title' | translate }}</legend>
    <p>{{ 'tkey;api.page.section.system.description' | translate }}</p>
    <table class="table table-bordered">
      <thead>
        <tr class="table-secondary d-flex">
          <th class="col-2">{{ 'tkey;api.page.column.section.title' | translate }}</th>
          <th class="col-10">{{ 'tkey;api.page.column.description.title' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr class="d-flex" *ngFor="let section of systemSection">
          <th class="col-2">
            {{ section.title | translate }}
          </th>
          <td class="col-10" [innerHTML]="section.content | translate"></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
