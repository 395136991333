import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { cloneDeep, isEqual } from 'lodash';
import { MicrosoftDialPlanRange } from '../../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { Observable, of, Subscription } from 'rxjs';
import { SmacsTextareaConfig } from '../../../forms/fields/textarea/smacs-textarea.component';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { SmacsRadioConfig } from '../../../forms/fields/radio/smacs-radio.component';
import {
  ExtensionRangesDisplayFormComponent,
  RangeDisplayFormModel,
} from '../../../forms/fields/extension-ranges/extension-ranges-display-form/extension-ranges-display-form.component';
import { ExtensionRangesOptionalValidators } from '../../../forms/fields/extension-ranges/smacs-extension-range-models';
import { RangeService } from '../../../shared/services/range.service';
import { CANADIAN_AREA_CODES_2024 } from '../../../shared/models/canadian-area-codes';

enum EnterByType {
  TEXT = 'Text',
  RANGES = 'Ranges',
}

export interface CheckNumberPortabilityComponentPayload {
  enterByType: 'Text' | 'Ranges';
  bulkNumbers?: string;
  dialPlanRangesJson?: MicrosoftDialPlanRange[];
}

@Component({
  selector: 'ziro-check-number-portability-form',
  templateUrl: './check-number-portability-form.component.html',
})
export class CheckNumberPortabilityFormComponent
  extends SmacsFormAbstractDirective<CheckNumberPortabilityComponentPayload>
  implements OnInit, OnDestroy
{
  @Input() existingPortInDraftNumbers: string[];
  @ViewChild(ExtensionRangesDisplayFormComponent) extensionRangesDisplayForm: ExtensionRangesDisplayFormComponent;
  protected readonly SmacsFormsValidationState = SmacsFormsValidationState;
  rangesFormEntity: RangeDisplayFormModel;
  extensionRangesDisplayFormValidationState: SmacsFormsValidationState;
  rangesFormOptionalValidators: ExtensionRangesOptionalValidators;
  validationStates = SmacsFormsValidationState;
  subscriptions = new Subscription();
  formConfig: SmacsFormConfig;
  overlappingExistingPortInDraftMessage = '';

  constructor(protected smacsFormStateService: SmacsFormStateService, private translateService: TranslateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.rangesFormOptionalValidators = {
      overlappingRangesInExistingPortInDraft: {
        existingPortInDraftNumbers: this.existingPortInDraftNumbers,
      },
      canadianNumbersRestricted: {
        restrictedAreaCodes: CANADIAN_AREA_CODES_2024,
      },
      hasSameLengthValidation: true,
      overlappingRanges: true,
      strictE164Validation: false,
      noLeadingZeroes: true,
    };
    this.rangesFormEntity = { ranges: cloneDeep(this.entity.dialPlanRangesJson) };

    this.formConfig = {
      fields: {
        enterByType: {
          label: 'tkey;admin.order_numbers.check_number_portability.enter_by.label',
          dataAutomation: 'enter-by-type',
          componentConfig: new SmacsRadioConfig({
            buttons: [
              {
                value: EnterByType.TEXT,
                label: 'tkey;admin.order_numbers.check_number_portability.enter_by.option_1',
              },
              {
                value: EnterByType.RANGES,
                label: 'tkey;admin.order_numbers.check_number_portability.enter_by.option_2',
              },
            ],
            inline: true,
          }),
        },
        bulkNumbers: {
          label: 'tkey;admin.msdialplan.management.field.bulk_numbers.label',
          dataAutomation: 'check-number-portability-bulk-input',
          componentConfig: new SmacsTextareaConfig({
            placeholder: this.translateService.instant(
              'tkey;admin.msdialplan.management.bulk_generate.placeholder.number.portability'
            ),
          }),
          required: true,
          helpText: () => 'tkey;admin.msdialplan.management.bulk_generate.helptext.number.portability',
          validation: [
            {
              validator: (val: string) => {
                return val
                  .split(/[\n,]/)
                  .filter((num) => {
                    if (num && num.trim()) {
                      return num;
                    }
                  })
                  .every((num) => {
                    const formattedNum = num.replace(/[()\-\s]/g, '');
                    if (formattedNum.startsWith('+')) {
                      return formattedNum.match(/^\+\d{11}$/);
                    } else {
                      return formattedNum.match(/^\d{10}$/);
                    }
                  })
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: 'tkey;validators.global.e164_strict.invalid.error',
            },
            {
              validator: (val: string) => {
                let isValid = SmacsFormsValidationState.VALID;
                const unformattedNumbers = val.replace(/\n|\r/g, ',').split(',');
                const strippedNumbers = val
                  .replace(/\n|\r/g, ',')
                  .replace(/[()\-\s]/g, '')
                  .split(',')
                  .map((num) => (num.startsWith('+1') ? num.slice(2) : num));

                this.overlappingExistingPortInDraftMessage = this.translateService.instant(
                  'tkey;admin.order_numbers.check_number_portability.number_in_existing_draft.error'
                );

                strippedNumbers.forEach((num, i) => {
                  if (this.existingPortInDraftNumbers.includes(num)) {
                    isValid = SmacsFormsValidationState.INVALID;
                    this.overlappingExistingPortInDraftMessage += `<br><strong>${unformattedNumbers[i]}</strong>`;
                  }
                });

                return isValid;
              },
              message: () => this.overlappingExistingPortInDraftMessage,
            },
            {
              validator: (inputValue: string) => {
                let isValid = SmacsFormsValidationState.VALID;
                const strippedNumbers = inputValue
                  .replace(/\n|\r/g, ',')
                  .replace(/[()\-\s]/g, '')
                  .split(',')
                  .map((num) => {
                    if (num.startsWith('+1')) return num.slice(2);
                    if (num.startsWith('1')) return num.slice(1);
                    return num;
                  });

                if (!!inputValue) {
                  strippedNumbers.forEach((number) => {
                    if (isValid === SmacsFormsValidationState.INVALID) return;

                    const hasCanadianAreaCode = !!CANADIAN_AREA_CODES_2024.find((areaCode) => {
                      return number.startsWith(areaCode);
                    });

                    isValid = !hasCanadianAreaCode
                      ? SmacsFormsValidationState.VALID
                      : SmacsFormsValidationState.INVALID;
                  });
                }
                return isValid;
              },
              message: 'tkey;admin.order_numbers.check_number_portability.canadian_numbers_restricted.error',
            },
          ],
        },
      },
      options: {
        columnClasses: {
          label: 'text-lg-start col-lg-3',
          input: '',
        },
      },
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  generateBulkRanges() {
    const generatedRanges = RangeService.generateRangesFromArray(
      this.formData['bulkNumbers']
        .replace(/\n|\r/g, ',')
        .replace(/[()\-\s]/g, '')
        .split(',')
    );
    return generatedRanges;
  }

  setDisplayFormValidationState() {
    this.extensionRangesDisplayFormValidationState =
      this.isFormSubmitted || this.extensionRangesDisplayForm.extensionRanges.showValidation
        ? this.extensionRangesDisplayForm.validationState
        : this.validationStates.VALID;
  }

  onRangeFormUpdate(data: SmacsFormsUpdate<RangeDisplayFormModel>) {
    // Always update validation state, don't always update entity. Async val will fire this multiple times so entity
    // won't necessarily change onFormUpdate
    if (isEqual(this.entity.dialPlanRangesJson, data.new.ranges)) {
      this.setDisplayFormValidationState();
    } else {
      const newEntity = {
        ...cloneDeep(this.entity),
        dialPlanRangesJson: data.new.ranges,
      };

      this.entitySource.next(newEntity);

      this.setDisplayFormValidationState();
    }
  }

  submit(): Observable<null> {
    if (this.extensionRangesDisplayForm) {
      this.setDisplayFormValidationState();
    }
    return of(null);
  }
}
