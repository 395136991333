<div
  class="report-datatable"
  [attr.data-automation]="dataAutomation"
  [ngClass]="{ 'report-datatable--disable': isViewLoading }"
>
  <div class="report-datatable__filter-container" *ngIf="hasFilter">
    <smacs-filter-input
      class="w-100"
      (filterChanged)="onFilterChanged($event)"
      [value]="searchValue"
      [dataAutomation]="'datatable-search-input'"
      [placeholder]="mainFilterPlaceholder | translate"
      [isDisabled]="isViewLoading"
    ></smacs-filter-input>
    <div class="ms-2">
      <button
        type="button"
        class="btn btn-default"
        (click)="clearFilters()"
        [disabled]="isViewLoading"
        data-automation="datatable-clear-filters-button"
      >
        <i class="{{ SmacsIcons.CLEAR }} pe-1"></i>
        {{ 'tkey;reporting.datatable.clear_filters' | translate }}
      </button>
    </div>
  </div>
  <div class="report-datatable__filter-container flex-wrap">
    <ng-content></ng-content>
  </div>
  <div
    class="report-datatable__table-container"
    id="scrollingTable"
    [ngClass]="{ 'report-datatable__table-container-card': containerCard }"
    [ngStyle]="{ 'min-height': minTableHeight }"
    #scrollingTable
  >
    <table class="report-datatable__scrolling-table table" [ngClass]="tableSize">
      <thead>
        <tr>
          <th
            *ngIf="showSelectColumn"
            class="report-datatable__scrolling-table__header report-datatable__scrolling-table__fixed-column report-datatable__scrolling-table__fixed-header report-datatable__scrolling-table__select-all-header"
            [ngClass]="{
              'report-datatable__scrolling-table__fixed-column--shadowed': !isFirstColumnSticky && isTableScrolledRight
            }"
          >
            <div
              class="form-check report-datatable__scrolling-table__checkbox"
              data-automation="datatable-select-all-checkbox"
            >
              <input
                type="checkbox"
                class="form-check-input"
                id="selectAllCheckbox"
                [indeterminate]="someSelected"
                [(ngModel)]="allSelected"
                (ngModelChange)="selectAll()"
                [disabled]="isViewLoading"
              />
            </div>
          </th>
          <th
            *ngFor="let column of columns; let i = index; trackBy: trackColumnByName"
            class="report-datatable__scrolling-table__header"
            (click)="sort(column.name, columnSortDirections[column.name])"
            [ngClass]="{
              'report-datatable__scrolling-table__fixed-column': i === 0 && isFirstColumnSticky,
              'report-datatable__scrolling-table__fixed-header': i === 0 && isFirstColumnSticky,
              'report-datatable__scrolling-table__fixed-column--selectable-row--condensed':
                showSelectColumn && isFirstColumnSticky,
              'report-datatable__scrolling-table__fixed-column--shadowed':
                i === 0 && isFirstColumnSticky && isTableScrolledRight
            }"
            [attr.data-automation]="'datatable-header-' + column.name"
          >
            {{ column.label | translate }}
            <div class="ps-2 btn-group-vertical">
              <span
                data-automation="datatable-sort-icon-asc"
                class="{{ SmacsIcons.SORT_UP }} sort-up"
                [ngClass]="{
                  'text-primary': isActiveColumn(column.name) && sortState === DatatableSortDirection.ASC,
                  'sorting-disabled': isActiveColumn(column.name) && !(sortState === DatatableSortDirection.ASC)
                }"
              ></span>
              <span
                data-automation="datatable-sort-icon-desc"
                class="{{ SmacsIcons.SORT_DOWN }} sort-down"
                [ngClass]="{
                  'text-primary': isActiveColumn(column.name) && sortState === DatatableSortDirection.DESC,
                  'sorting-disabled': isActiveColumn(column.name) && !(sortState === DatatableSortDirection.DESC)
                }"
              ></span>
            </div>
          </th>
          <th
            *ngIf="!isViewLoading && showEditColumn"
            class="report-datatable__scrolling-table__header report-datatable__scrolling-table__fixed-header report-datatable__scrolling-table__fixed-column-right"
            [ngClass]="{
              'report-datatable__scrolling-table__fixed-column-right--shadowed': isTableScrolledLeft,
            }"
          ></th>
        </tr>
      </thead>
      <tbody data-automation="datatable-body">
        <tr
          *ngIf="(showNoResultWarningAboveSelected || filteredData.length === 0) && showCustomAlert"
          [class]="noResultsAlertClass || 'table-warning'"
          data-automation="datatable-no-results-alert"
        >
          <td [attr.colspan]="columns.length + 1" class="py-3 table-danger">
            <ng-content select="[alert-content]"></ng-content>
          </td>
        </tr>

        <tr
          *ngIf="(showNoResultWarningAboveSelected || filteredData.length === 0) && !showCustomAlert"
          [class]="noResultsAlertClass || 'table-warning'"
          data-automation="datatable-no-results-alert"
        >
          <td [attr.colspan]="columns.length + 1" class="py-3">
            <div *ngIf="showCustomAlert" class="text-center strong table-danger">
              <ng-content select="[alert-content]"></ng-content>
            </div>
            <div
              *ngIf="!showCustomAlert"
              class="text-center strong"
              [innerHTML]="noResultsAlert || 'tkey;reporting.datatable.no_results_found' | translate"
            ></div>
          </td>
        </tr>
        <tr
          *ngFor="let row of paginatedData; let i = index; trackBy: trackRowByDatatableIndex"
          class="report-datatable__scrolling-table__row"
          [attr.data-automation]="'datatable-row-' + i"
          [ngClass]="{
            'report-datatable__scrolling-table__row--striped': isStriped,
            'report-datatable__scrolling-table__row--striped--regular':
              isStriped && !isViewLoading && matchesFilters(row),
            'report-datatable__scrolling-table__row--striped--special':
              isStriped && !isViewLoading && !matchesFilters(row),
            'report-datatable__scrolling-table__row--loading': isViewLoading,
            'text-muted': row.isTableRowSelectDisabled
          }"
          [class]="row.cssClass"
        >
          <td
            *ngIf="showSelectColumn && row.isTableRowSelectable && !isViewLoading"
            class="report-datatable__scrolling-table__fixed-column"
            [ngClass]="{
              'report-datatable__scrolling-table__fixed-column--shadowed': !isFirstColumnSticky && isTableScrolledRight
            }"
          >
            <div
              class="form-check report-datatable__scrolling-table__checkbox"
              data-automation="datatable-select-checkbox"
              [ngbTooltip]="row.tableRowSelectDisabledTooltip | translate"
              [disableTooltip]="!row.isTableRowSelectDisabled"
              container="body"
            >
              <input
                type="checkbox"
                class="form-check-input"
                [attr.id]="'selectCheckbox' + i"
                [(ngModel)]="row.isSelectedInTable"
                (ngModelChange)="onRowSelected()"
                [disabled]="isViewLoading || row.isTableRowSelectDisabled"
              />
            </div>
          </td>
          <td
            *ngIf="showSelectColumn && !row.isTableRowSelectable"
            class="report-datatable__scrolling-table__fixed-column"
            [ngClass]="{
              'report-datatable__scrolling-table__fixed-column--shadowed': !isFirstColumnSticky && isTableScrolledRight
            }"
          ></td>
          <td
            *ngFor="let property of columns; let j = index; trackBy: trackColumnByName"
            [ngClass]="{
              'report-datatable__scrolling-table__fixed-column': j === 0 && isFirstColumnSticky,
              'report-datatable__scrolling-table__fixed-column--selectable-row--condensed':
                showSelectColumn && isFirstColumnSticky,
              'report-datatable__scrolling-table__fixed-column--shadowed':
                j === 0 && isFirstColumnSticky && isTableScrolledRight,
              'report-datatable__scrolling-table__fixed-column--warning':
                j === 0 && isFirstColumnSticky && isTableScrolledRight && row?.cssClass?.includes('table-warning'),
            }"
            [ngStyle]="{ 'vertical-align': !truncateCell ? 'top' : ''}"
            [attr.data-automation]="'datatable-cell-' + property.name"
          >
            <div
              [style.width.px]="setColumnWidths ? (property | datatableColumnWidth) : ''"
              [class]="property.cssClass"
            >
              <div [ngClass]="tableFontClass" class="table-entry" *ngIf="!isViewLoading">
                <ng-template #rowTooltip>
                  <div [innerHTML]="row.hardcodedTooltips[property.name]"></div>
                </ng-template>
                <span
                  [disableTooltip]="
                    property.disableTooltip ||
                    ((row[property.name] | stripHtml).length <= getCellMaxStringLength(property) &&
                      (!row.hardcodedTooltips || !row.hardcodedTooltips[property.name])) ||
                    (row.hardcodedDisableTooltip && row.hardcodedDisableTooltip[property.name]) ||
                    isViewLoading
                  "
                  [ngbTooltip]="
                    row.hardcodedTooltips && !!row.hardcodedTooltips[property.name]
                      ? rowTooltip
                      : (row[property.name] | stripHtml)
                  "
                  [placement]="'top-left'"
                  [tooltipClass]="'datatable-tooltip'"
                  container="body"
                  class="d-block"
                  [ngClass]="{
                    'text-truncate': property.name !== 'numbers',
                    'd-flex': !truncateCell,
                    'flex-wrap': !truncateCell,
                    'gap-1': !truncateCell,
                    'align-start': !truncateCell
                  }"
                  data-automation="datatable-cell-content"
                  [style.width.px]="setColumnWidths ? (property | datatableColumnWidth) : ''"
                  [innerHTML]="
                    property.pipe
                      ? (row[property.name] | dynamic : property.pipe : getPipeAgs(row, property.pipeArgs))
                      : row[property.name]
                  "
                ></span>
                <span
                  *ngIf="row[property.name + 'Subtext']"
                  id="{{ property.name }}Subtext"
                  class="d-block small table-entry text-truncate"
                  data-automation="datatable-cell-subtext"
                  [innerHTML]="row[property.name + 'Subtext']"
                  [disableTooltip]="
                    getSubtextValue(property.name).length <= getCellMaxStringLength(property) || isViewLoading
                  "
                  [style.width.px]="setColumnWidths ? (property | datatableColumnWidth) : ''"
                  [ngbTooltip]="getSubtextValue(property.name)"
                  [placement]="'top-left'"
                  [tooltipClass]="'datatable-tooltip'"
                  container="body"
                ></span>
              </div>
              <div *ngIf="isViewLoading">
                <ngx-skeleton-loader *ngIf="isViewLoading && i < 3" [animation]="'progress-dark'"></ngx-skeleton-loader>
              </div>
            </div>
          </td>
          <td
            *ngIf="!isViewLoading && showEditColumn"
            class="report-datatable__scrolling-table__fixed-column-right"
            [ngClass]="{
              'report-datatable__scrolling-table__fixed-column-right--shadowed': isTableScrolledLeft,
              'report-datatable__scrolling-table__fixed-column--warning':
                isTableScrolledLeft && row?.cssClass?.includes('table-warning'),
            }"
            [ngStyle]="{ 'vertical-align': !truncateCell ? 'top' : '' }"
          >
            <smacs-button
              *ngIf="!row.isEditButtonHidden"
              [cssClass]="'report-datatable__scrolling-table__checkbox ' + editButtonCssClasses"
              [dataAutomation]="'datatable-edit-button'"
              (click)="_handleEdit(row)"
              [isDisabled]="row.isEditDisabled"
              [tooltip]="row.disabledEditMsg | translate"
              [isTooltipDisabled]="!row.isEditDisabled"
              [ngbTooltip]="editTooltip | translate"
              [disableTooltip]="!editTooltip || row.isEditDisabled"
              [tooltipClass]="'datatable-tooltip'"
              [buttonStyle]="editButtonStyle"
              [buttonSize]="editButtonSize"
              [type]="editButtonType"
              [href]="row.editButtonHref"
              [icon]="editIcon"
              [iconAppended]="true"
              [label]="editButtonLabel | translate"
              container="body"
            ></smacs-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="report-datatable__pagination">
    <smacs-pagination
      [pageSize]="PAGE_SIZE"
      [(page)]="page"
      [totalResults]="filteredData.length"
      [short]="true"
      (pageChanged)="changePage()"
      [dataAutomation]="'datatable-pagination'"
      [isViewLoading]="isViewLoading"
    ></smacs-pagination>
  </div>
</div>
