<div class="datatable-filter">
  <div class="dropdown" ngbDropdown [autoClose]="'outside'" [attr.data-automation]="dataAutomation">
    <div>
      <button
        [id]="dataAutomation"
        type="button"
        class="btn dropdown-toggle"
        [class]="!!displayValue ? 'btn-primary' : 'btn-default'"
        [disabled]="isViewLoading"
        data-automation="datatable-multi-select-filter-dropdown-toggle-button"
        ngbDropdownToggle
      >
        <span class="font-regular">{{ label | translate }}{{ !!displayValue ? ': ' : '' }}</span>
        <span
          *ngIf="!!displayValue"
          [ngClass]="labelCssClass"
          class="font-black"
          data-automation="datatable-multi-select-filter-value"
        >
          {{ displayValue | translate }}
        </span>
      </button>
      <button
        type="button"
        *ngIf="!!displayValue"
        class="btn btn-primary clear-margin"
        data-automation="datatable-clear-filter-button"
        (click)="clear()"
      >
        <i class="icon-clear"></i>
      </button>
    </div>
    <div ngbDropdownMenu class="dropdown-menu">
      <div class="dropdown-menu__sticky-container">
        <div class="dropdown-item">
          <smacs-filter-input
            (filterChanged)="onChanged($event)"
            [placeholder]="'tkey;reporting.datatable.filter.search_placeholder'"
            [value]="searchFilter"
            [dataAutomation]="'dropdown-search-input'"
          ></smacs-filter-input>
        </div>
        <div class="dropdown-item dropdown-menu__actions">
          <button
            type="button"
            class="btn btn-link clear-all"
            (click)="clear()"
            [ngClass]="{ disabled: selectedItems.length === 0 }"
            data-automation="datatable-clear-all-button"
          >
            {{ 'tkey;reporting.datatable.clear_all' | translate }}
          </button>
        </div>
      </div>
      <div data-automation="dropdown-list" class="dropdown-menu__list m-0 p-0">
        <div
          *ngFor="let item of getFilteredSearchResults()"
          class="dropdown-item"
          [attr.data-automation]="'filter-dropdown-item'"
        >
          <div
            class="col-11 form-check"
            [attr.data-automation]="'datatable-multi-select-filter-dropdown-item-' + (item | translate)"
          >
            <input
              type="checkbox"
              class="form-check-input"
              id="{{ 'select-filter-item-' + dataAutomation + '-' + item }}"
              (change)="onFilterChange(item, $event)"
              [checked]="selectedItems.indexOf(item) > -1"
            />
            <label
              for="{{ 'select-filter-item-' + dataAutomation + '-' + item }}"
              class="form-check-label"
              [ngClass]="labelCssClass"
            >
              {{ item | translate }}
            </label>
            <button
              type="button"
              class="btn btn-link form-check-only ms-1 d-inline"
              (click)="filterOnlyOneItem(item)"
              data-automation="select-item-only"
            >
              Only
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
