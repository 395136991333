<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div class="h-100 d-flex flex-column">
  <div class="login-container flex-grow-1" data-automation="login-container" [style]="loginStyles">
    <div [hidden]="isLoading" class="login-container-inner">
      <!-- Display Alert if Server or License Issue detected -->
      <div
        *ngIf="errorAlert"
        [ngClass]="{
          'shake animated': attemptedLogin,
          'alert-danger': systemStatusError() || getSsoErrorMessage(),
          'alert-warning': !systemStatusError() && !getSsoErrorMessage()
        }"
        data-automation="login-warnings"
        class="alert"
      >
        <h4 data-automation="login-warning-header" [innerHtml]="errorAlert?.header | translate"></h4>
        <div
          data-automation="login-warning-message"
          [innerHtml]="errorAlert?.message?.content || errorAlert?.message | translate : errorAlert?.message?.params"
        ></div>
      </div>

      <div
        *ngIf="infoAlert"
        data-automation="login-branch-info"
        class="login-container-form__info-alert border-rad-all-2 d-flex justify-content-between"
      >
        <div class="d-flex flex-column justify-content-center">
          <div>
            <i [attr.class]="smacsIcons.CODE_BRANCH"></i>
            <span data-automation="login-info-branch" [innerHtml]="infoAlert?.branch | translate"></span>
          </div>
          <div class="mt-1">
            <i [attr.class]="smacsIcons.ENVIRONMENT"></i>
            <span data-automation="login-info-environment" [innerHtml]="infoAlert?.environment | translate"></span>
          </div>
        </div>
        <div class="align-items-center">
          <svg-icon [name]="infoAlertLogo"></svg-icon>
        </div>
      </div>

      <div class="login-container-form border-rad-all-2">
        <div>
          <img class="company-logo-image" data-automation="company-logo-image" [src]="loginLogo" alt="Logo" />
        </div>

        <div *ngIf="!outdatedBrowserError" class="top-buffer mb-0">
          <!-- SSO -->
          <div [hidden]="!showSsoLogin()" data-automation="login-sso-form">
            <smacs-button
              id="sso-login-button"
              [dataAutomation]="'sso-login-button'"
              [label]="'tkey;pages.login.submit'"
              (clicked)="handleSsoLogin()"
              [isDisabled]="isLoggingIn"
              [icon]="smacsIcons.LOCK"
              [iconPending]="smacsIcons.SAVING"
              [buttonSize]="buttonSizes.LARGE"
              [buttonStyle]="buttonStyles.PRIMARY"
              [isFullWidth]="true"
            ></smacs-button>
          </div>
          <!-- Local Auth -->
          <div [hidden]="showSsoLogin()" data-automation="login-local-auth-form">
            <div>
              <form (keyup.enter)="onEnterSubmit()">
                <smacs-text [fieldId]="'username'"></smacs-text>
                <smacs-text [fieldId]="'password'"></smacs-text>
              </form>

              <smacs-submit-button
                [isPending]="isLoggingIn"
                [isDisabled]="isSubmitting"
                [label]="'tkey;pages.login.submit'"
                [dataAutomation]="'login-submit'"
                [icon]="smacsIcons.LOCK"
                [iconPending]="smacsIcons.SAVING"
                [formSubmitSubject]="_validateAndSubmitSource"
                [buttonSize]="buttonSizes.LARGE"
                [buttonStyle]="buttonStyles.PRIMARY"
                [buttonStylePending]="buttonStyles.PRIMARY"
                [isFullWidth]="true"
              ></smacs-submit-button>
            </div>
          </div>
        </div>
        <div *ngIf="outdatedBrowserError" class="alert alert-danger">
          <h4>{{ 'tkey;global.login.browser_outdated.header' | translate }}</h4>
          <div [innerHtml]="'tkey;global.login.browser_outdated.message' | translate"></div>
        </div>
      </div>
    </div>
  </div>

  <app-footer>
    <div [hidden]="!showSsoLogin()" class="text-center mb-n2">
      <div class="d-inline-block">
        <smacs-button
          [cssClass]="'w-100 mt-2 mb-n2'"
          [buttonStyle]="buttonStyles.LINK"
          [dataAutomation]="'switch-login'"
          [label]="'tkey;pages.login.bypass.sso'"
          [icon]="smacsIcons.SIGN_IN"
          (clicked)="showSmacsLogin()"
        ></smacs-button>
      </div>
    </div>
  </app-footer>
</div>
