import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SystemStatusComponent } from './system-status.component';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ButtonModule } from '../button/button.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoadingBlockModule } from '../shared/loading-block/loading-block.module';
import { BreadcrumbsModule } from '../shared/breadcrumbs/breadcrumbs.module';
import { SmacsFormsModule } from '../forms/smacs-forms.module';
import { BottomNavModule } from '../shared/bottom-nav/bottom-nav.module';

@NgModule({
  declarations: [SystemStatusComponent],
  exports: [SystemStatusComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    ReactiveFormsModule,
    RouterModule,
    ButtonModule,
    NgbModule,
    LoadingBlockModule,
    BreadcrumbsModule,
    SmacsFormsModule,
    BottomNavModule,
  ],
})
export class SystemStatusModule {}
