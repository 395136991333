import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditDnExceptionGroupModalComponent } from './edit-dn-exception-group-modal/edit-dn-exception-group-modal.component';
import { OopsModalComponent } from './oops-modal/oops-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PhoneButtonEditFormModule } from '../shared/phone-buttons/edit-form/phone-button-edit-form.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { DialPlanExceptionManagementEditModule } from '../admin/cisco/dial-plan-exception-management/dial-plan-exception-management-edit/dial-plan-exception-management-edit.module';
import { CustomInputPreviewFilter } from '../shared/filters/custom-input-preview.filter';
import { VariableEditorModalComponent } from './edit-custom-input-text-modal/variable-editor-modal.component';
import { EditCustomSelectModalComponent } from './edit-custom-select-modal/edit-custom-select-modal.component';
import { ConnectedListComponent } from './edit-custom-select-modal/connected-list/connected-list.component';
import { DragulaModule } from 'ng2-dragula';
import { SitePickerModalComponent } from './site-picker-modal/site-picker-modal.component';
import { PrimaryExtensionPickerModalComponent } from './primary-extension-picker-modal/primary-extension-picker-modal.component';
import { DeletedAgentErrorModalComponent } from './deleted-angent-error-modal/deleted-agent-error-modal.component';
import { ChangePrimaryExtensionModalComponent } from './change-primary-extension-modal/change-primary-extension.modal.component';
import { ButtonModule } from '../button/button.module';
import { SharedModule } from '../shared/shared.module';
import { PromptModalComponent } from './prompt-modal/prompt-modal.component';
import { SmacsFormsModule } from '../forms/smacs-forms.module';
import { DetailedModalComponent } from './detailed-modal/detailed-modal.component';
import { OkButtonModalComponent } from './ok-button-modal/ok-button-modal.component';
import { ImportCertSuccessModalComponent } from './import-cert-success-modal/import-cert-success-modal.component';
import { VariableEditorService } from './edit-custom-input-text-modal/variable-editor.service';
import { PhoneButtonLayoutConflictModalComponent } from '../shared/modals/phone-button-layout-conflict-modal/phone-button-layout-conflict-modal.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgbModule,
    TranslateModule,
    PhoneButtonEditFormModule,
    DialPlanExceptionManagementEditModule,
    NgSelectModule,
    DragulaModule.forRoot(),
    ButtonModule,
    SharedModule,
    SmacsFormsModule,
  ],
  declarations: [
    EditDnExceptionGroupModalComponent,
    OopsModalComponent,
    VariableEditorModalComponent,
    CustomInputPreviewFilter,
    EditCustomSelectModalComponent,
    ConnectedListComponent,
    SitePickerModalComponent,
    PrimaryExtensionPickerModalComponent,
    DeletedAgentErrorModalComponent,
    ChangePrimaryExtensionModalComponent,
    PromptModalComponent,
    DetailedModalComponent,
    OkButtonModalComponent,
    ImportCertSuccessModalComponent,
    PhoneButtonLayoutConflictModalComponent,
  ],
  exports: [
    EditDnExceptionGroupModalComponent,
    OopsModalComponent,
    VariableEditorModalComponent,
    CustomInputPreviewFilter,
    EditCustomSelectModalComponent,
    ConnectedListComponent,
    SitePickerModalComponent,
    PrimaryExtensionPickerModalComponent,
    DeletedAgentErrorModalComponent,
    PromptModalComponent,
    DetailedModalComponent,
  ],
  providers: [VariableEditorService],
})
export class ModalsModule {}
