<div
  class="mb-3"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    row: inlineLabel,
    'has-feedback': showValidation || isFormSubmitted,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage,
    'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
  }"
>
  <ng-container *ngIf="inlineLabel">
    <label
      *ngIf="config.label"
      class="smacs-forms-label col-form-label col-3"
      [ngClass]="
        rightAlignedLabel
          ? 'text-lg-end smacs-forms-label col-form-label col-3'
          : 'smacs-forms-label col-form-label col-3'
      "
      [attr.for]="config.dataAutomation + '-textarea'"
      data-automation="smacs-text-label"
    >
      <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
      <span>
        {{ getLabel() | translate }}
      </span>
    </label>

    <div class="col-9">
      <textarea
        #ngModelDir="ngModel"
        (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
        [disabled]="state.disabled"
        [(ngModel)]="fieldData"
        [placeholder]="placeholder"
        [attr.id]="config.dataAutomation + '-textarea'"
        [ngClass]="nonresizable ? 'smacs-textarea smacs-textarea__non-resizable' : 'smacs-textarea'"
        data-automation="smacs-textarea"
        class="form-control"
      ></textarea>

      <span
        class="{{ smacsIcons.SAVING }} form-control-feedback"
        [hidden]="!(validationState === ValStates.PENDING && showAsyncValidation)"
        data-automation="smacs-text-async-pending"
      ></span>

      <span
        class="{{ smacsIcons.REMOVE }} form-control-feedback"
        [hidden]="!(validationState === ValStates.INVALID && showAsyncValidation)"
        data-automation="smacs-text-async-invalid"
      ></span>

      <span
        class="{{ smacsIcons.OK }} form-control-feedback"
        [hidden]="!(validationState === ValStates.VALID && showAsyncValidation)"
        data-automation="smacs-text-async-valid"
      ></span>
      <div>
        <div class="smacs-forms-feedback-area">
          <div
            class="smacs-forms-validation-message"
            data-automation="smacs-forms-validation-message"
            [hidden]="!showValidation && !isFormSubmitted"
          >
            {{ validationMessage?.content | translate : validationMessage?.params }}
          </div>

          <div
            class="smacs-forms-validation-message text-warning"
            [ngClass]="{ 'd-inline': config.autogeneration?.inline }"
            data-automation="smacs-forms-warning-message"
            [hidden]="!misconfigurationFeedbackMessage"
          >
            {{ misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params }}
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!inlineLabel">
    <div class="position-relative">
      <label
        *ngIf="config.label"
        class="smacs-forms-label col-form-label"
        [attr.for]="config.dataAutomation + '-textarea'"
        data-automation="smacs-text-label"
      >
        <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
        <span>
          {{ getLabel() | translate }}
        </span>
      </label>
      <span
        class="{{ smacsIcons.SAVING }} form-control-feedback"
        [hidden]="!(validationState === ValStates.PENDING && showAsyncValidation)"
        data-automation="smacs-text-async-pending"
      ></span>

      <span
        class="{{ smacsIcons.REMOVE }} form-control-feedback"
        [hidden]="!(validationState === ValStates.INVALID && showAsyncValidation)"
        data-automation="smacs-text-async-invalid"
      ></span>

      <span
        class="{{ smacsIcons.OK }} form-control-feedback"
        [hidden]="!(validationState === ValStates.VALID && showAsyncValidation)"
        data-automation="smacs-text-async-valid"
      ></span>
    </div>
    <div>
      <textarea
        #ngModelDir="ngModel"
        (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
        [disabled]="state.disabled"
        [(ngModel)]="fieldData"
        [placeholder]="placeholder"
        [attr.id]="config.dataAutomation + '-textarea'"
        [ngClass]="nonresizable ? 'smacs-textarea smacs-textarea__non-resizable' : 'smacs-textarea'"
        data-automation="smacs-textarea"
        class="form-control"
      ></textarea>
    </div>
    <div>
      <div class="smacs-forms-feedback-area">
        <div
          class="smacs-forms-validation-message"
          data-automation="smacs-forms-validation-message"
          [hidden]="!showValidation && !isFormSubmitted"
          [innerHTML]="validationMessage?.content | translate : validationMessage?.params"
        ></div>

        <div
          class="smacs-forms-validation-message text-warning"
          [ngClass]="{ 'd-inline': config.autogeneration?.inline }"
          data-automation="smacs-forms-warning-message"
          [hidden]="!misconfigurationFeedbackMessage"
        >
          {{ misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params }}
        </div>
      </div>

      <span *ngIf="getHelpText()" class="small form-text text-muted" [innerHtml]="getHelpText() | translate"></span>
    </div>
  </ng-container>
</div>
