import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { LdapSyncConfigResource } from '../../resources/ldap-sync-config.resource';
import {
  ClusterLdapSyncInfo,
  LdapSyncConfig,
  LdapSyncJobStatus,
  LdapSyncStatus,
  ServerLdapSyncInfo,
} from '../../../shared/models/generated/smacsModels';
import { LdapSyncResource } from '../../resources/ldap-sync.resource';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../shared/services/toast.service';
import { AutomaticSyncFormComponent } from './automatic-sync-form/automatic-sync-form.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { LdapSyncJobStatusPollingService } from '../../../shared/services/cisco/ldap-sync-job-status-polling.service';

@Component({
  selector: 'smacs-admin-zero-touch-ldap-sync',
  templateUrl: './ldap-sync.component.html',
  styleUrls: ['../../../admin/admin-page.scss'],
  providers: [LdapSyncJobStatusPollingService],
})
export class LdapSyncComponent implements OnInit, OnDestroy {
  @ViewChild(AutomaticSyncFormComponent) childForm: AutomaticSyncFormComponent;

  isLoading = true;
  isSyncing = false;
  syncError = false;
  ldapSyncConfig: LdapSyncConfig;
  ldapJobStatus: LdapSyncJobStatus;
  ldapSyncStatus = LdapSyncStatus;
  smacsIcons = SmacsIcons;

  private _subscriptions = new Subscription();

  constructor(
    private ldapSyncConfigResource: LdapSyncConfigResource,
    private ldapSyncResource: LdapSyncResource,
    private toastService: ToastService,
    private breadcrumbsService: BreadcrumbsService,
    private ldapSyncJobStatusPollingService: LdapSyncJobStatusPollingService
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.ldap_sync.title' }]);

    this.ldapSyncJobStatusPollingService.startPolling();
    this._getLdapSyncConfigResource().subscribe((data: LdapSyncConfig) => {
      this.ldapSyncConfig = data;
      this.handlePolling();
    });
  }

  ngOnDestroy() {
    this.ldapSyncJobStatusPollingService.stopPolling();
  }

  handlePolling() {
    const subscription = this.ldapSyncJobStatusPollingService.state$.subscribe((state: LdapSyncJobStatus) => {
      this.ldapJobStatus = state;

      if (this.ldapJobStatus.inProgress) {
        this.isSyncing = true;
      } else if (!this.ldapJobStatus.inProgress) {
        this.ldapSyncJobStatusPollingService.stopPolling();

        const isInvalid = this.ldapJobStatus.clusters.find((cluster: ClusterLdapSyncInfo) => {
          if (cluster.cucmServer.status === this.ldapSyncStatus.ERROR) {
            return true;
          }

          const invalidUnity = cluster.unityServers.find((server: ServerLdapSyncInfo) => server.status === 'ERROR');
          if (invalidUnity) {
            return true;
          }
        });

        if (isInvalid && this.isSyncing) {
          this.syncError = true;
          this.toastService.push(
            ToastTypes.ERROR,
            this.smacsIcons.REFRESH,
            'tkey;admin.ldap_sync.manual_sync.sync_all.toast.error.title',
            'tkey;admin.ldap_sync.manual_sync.sync_all.toast.error.message'
          );
        } else if (this.isSyncing) {
          this.toastService.push(
            ToastTypes.SUCCESS,
            this.smacsIcons.REFRESH,
            'tkey;admin.ldap_sync.manual_sync.sync_all.toast.success.title',
            'tkey;admin.ldap_sync.manual_sync.sync_all.toast.success.message'
          );
        }

        this.isSyncing = false;
      }

      this.isLoading = false;
    });

    this._subscriptions.add(subscription);
  }

  onSyncAllClick() {
    this.isSyncing = true;
    this.syncError = false;
    this.ldapSyncResource.post().subscribe(() => {
      this.ldapSyncJobStatusPollingService.startPolling();
      this.handlePolling();
    });
  }

  trackByClusterId = (index: number, cluster: ClusterLdapSyncInfo): number => {
    return cluster.clusterId;
  };

  trackByUnityServerId = (index: number, unityServer: ServerLdapSyncInfo): number => {
    return unityServer.id;
  };

  private _getLdapSyncConfigResource = (): Observable<LdapSyncConfig> => this.ldapSyncConfigResource.get();
}
