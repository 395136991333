import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { of } from 'rxjs';
import { SmacsFormConfig } from '../../../../../forms/smacs-forms-models';
import { AssignedCallingDelegate } from '../../../../../shared/models/generated/smacsModels';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  LabelTextSize,
  SmacsCheckboxConfig,
} from '../../../../../forms/fields/checkbox/smacs-checkbox.component';
import { SmacsIcons } from '../../../../../shared/models/smacs-icons.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ziro-calling-delegate-card-form',
  templateUrl: './calling-delegate-card-form.component.html',
  styleUrls: ['./calling-delegate-card-form.component.scss'],
})
export class CallingDelegateCardFormComponent
  extends SmacsFormAbstractDirective<AssignedCallingDelegate>
  implements OnInit
{
  @Output() delegateRemoved = new EventEmitter<string>();
  @Input() photoBase64: string;
  @Input() displayName: string;
  @Input() currentUser: string;
  formConfig: SmacsFormConfig;
  smacsIcons = SmacsIcons;

  constructor(smacsFormStateService: SmacsFormStateService, private _translateService: TranslateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initFormConfig();
  }
  removeDelegateClicked() {
    this.delegateRemoved.emit(this.entity.id);
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        makeCallsEnabled: {
          dataAutomation: `make-calls-checkbox-${this.entity.id}`,
          label: 'tkey;microsoft_360.view.teams.delegate_management.make_calls.label',
          componentConfig: new SmacsCheckboxConfig({
            checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX,
            size: HtmlSwitchSize.LG,
            textSize: LabelTextSize.LG,
          }),
          helpText: this._translateService.instant(
            'tkey;microsoft_360.view.teams.delegate_management.make_calls.helptext',
            {
              displayName: this.currentUser,
            }
          ),
        },
        receiveCallsEnabled: {
          dataAutomation: `receive-calls-checkbox-${this.entity.id}`,
          label: 'tkey;microsoft_360.view.teams.delegate_management.receive_calls.label',
          componentConfig: new SmacsCheckboxConfig({
            checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX,
            size: HtmlSwitchSize.LG,
            textSize: LabelTextSize.LG,
          }),
          helpText: this._translateService.instant(
            'tkey;microsoft_360.view.teams.delegate_management.receive_calls.helptext',
            {
              displayName: this.currentUser,
            }
          ),
        },
        manageSettingsEnabled: {
          dataAutomation: `manage-calls-checkbox-${this.entity.id}`,
          label: 'tkey;microsoft_360.view.teams.delegate_management.manage_settings.label',
          componentConfig: new SmacsCheckboxConfig({
            checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX,
            size: HtmlSwitchSize.LG,
            textSize: LabelTextSize.LG,
          }),
          helpText: this._translateService.instant(
            'tkey;microsoft_360.view.teams.delegate_management.manage_settings.helptext',
            {
              displayName: this.currentUser,
            }
          ),
        },
      },
    };
  }

  protected submit() {
    return of(null);
  }
}
