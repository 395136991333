import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DragulaModule } from 'ng2-dragula';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { CurrentClusterContext } from './contexts/current-cluster.context';
import { CurrentDateContext } from './contexts/current-date.context';
import { LicenseContext } from './contexts/license.context';
import { SiteSummaryContext } from './contexts/site-summary.context';
import { SystemStatusContext } from './contexts/system-status.context';
import { DnDetailSummaryResource } from './resources/dn-detail-summary.resource';
import { EndUserResource } from './resources/end-user.resource';
import { EndUserSearchResource } from './resources/end-user-search.resource';
import { AppUserSearchResource } from './resources/app-user-search.resource';
import { SearchPhoneResource } from './resources/search-phone.resource';
import { Global360ViewResource } from './resources/global-360-view.resource';
import { LdapResource } from './resources/ldap.resource';
import { PhoneResource } from './resources/phone.resource';
import { SystemStatusResource } from './resources/system-status.resource';
import { AsciiService } from './services/ascii.service';
import { AuditHeaderService } from './services/audit-header.service';
import { DateTimeService } from './services/date-time.service';
import { DefaultValueService } from './services/default-value.service';
import { RouteGuardService } from './services/route-guard.service';
import { RouteHelpersService } from './services/route-helpers.service';
import { ServiceTypeDeterminationService } from './services/service-type-determination.service';
import { ServiceTypeMetadataService } from './services/service-type-metadata.service';
import { SiteDeductionService } from './services/site-deduction.service';
import { SmacsModalService } from './services/smacs-modal.service';
import { ToastService } from './services/toast.service';
import { UniqueIdService } from './services/unique-id.service';
import { ValidatorsService } from './validators.service';
import { BottomNavService } from './bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from './breadcrumbs/breadcrumbs.service';
import { ServiceTypeIconFilter } from './filters/service-type-icon.filter';
import { ServiceTypeNameFilter } from './filters/service-type-name.filter';
import { PhoneButtonTemplateFieldConfigResource } from './resources/phone-button-template-field-config-resource.service';
import { PhoneButtonTemplateService } from './services/phone-button-template.service';
import { PhoneUiContext } from './phone-buttons/contexts/phone-ui.context';
import { ButtonModule } from '../button/button.module';
import { LoginGuardService } from './services/login-guard-service';
import { DeviceSettingsResource } from '../admin/resources/device-settings.resource';
import { DistributionListsResource } from './resources/distribution-lists.resource';
import { AuditReportQueryResource } from '../admin/resources/audit-report-query.resource';
import { DialPlanExceptionResource } from '../admin/resources/dial-plan-exception.resource';
import { SiteMacManagementPermissionsResource } from '../admin/resources/site-mac-management-permissions.resource';
import { SearchResource } from './resources/search.resource';
import { LocalStorageContext } from './contexts/local-storage.context';
import { ExtensionMobilityService } from './services/extension-mobility.service';
import { PortalContext } from './contexts/portal.context';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { GlobalPropertiesContext } from './contexts/global-properties.context';
import { SearchExtensionMobilityProfilesResource } from '../self-serve/resources/search/search-extension-mobility-profiles.resource';
import { CustomUiOptionsContext } from './contexts/custom-ui-options.context';
import { CustomizeUiResource } from './resources/customize-ui.resource';
import { ExtensionMobilityProfilesResource } from './resources/extension-mobility-profiles.resource';
import { ExtMobilityUiContext } from './phone-buttons/contexts/ext-mobility-ui.context';
import { FileDownloadService } from './services/file-download.service';
import { AuthenticationContext } from './contexts/authentication.context';
import { VoicemailService } from './services/voicemail.service';
import { BandwidthSettingsContext } from './contexts/bandwidth-settings.context';
import { MobilityIdentityResource } from './resources/mobility-identity.resource';
import { DubberSettingsContext } from './contexts/dubber-settings.context';
import { CustomPowershellReportContext } from './contexts/custom-powershell-report.context';
import { ZiroToastModule } from './toast/ziro-toast.module';
import { Microsoft365LicensesViewResource } from './resources/microsoft-365-licenses-view.resource';
import { MicrosoftDialPlansFieldConfigContext } from './contexts/microsoft-dial-plans-field-config.context';
import { MicrosoftBulkProvisioningDraftsContext } from './contexts/microsoft-bulk-provisioning-drafts.context';
import { MicrosoftProxyFeatureSettingsContext } from '../admin/contexts/microsoft-proxy-feature-settings.context';

// Note we need a separate function as it's required
// by the AOT compiler.
export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    BreadcrumbModule,
    DragulaModule.forRoot(),
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    ButtonModule,
    AngularSvgIconModule,
    ZiroToastModule,
  ],
  // Do not declare new components here. Declare them in either a new or existing module.
  declarations: [],
  exports: [],
  providers: [
    // Contexts
    AuthenticationContext,
    CurrentClusterContext,
    CurrentDateContext,
    LicenseContext,
    SiteSummaryContext,
    SystemStatusContext,
    PhoneUiContext,
    PortalContext,
    GlobalPropertiesContext,
    CustomUiOptionsContext,
    ExtMobilityUiContext,
    BandwidthSettingsContext,
    DubberSettingsContext,
    CustomPowershellReportContext,
    MicrosoftDialPlansFieldConfigContext,
    MicrosoftBulkProvisioningDraftsContext,

    // Resources
    DeviceSettingsResource,
    AuditReportQueryResource,
    CustomizeUiResource,
    DialPlanExceptionResource,
    DistributionListsResource,
    DnDetailSummaryResource,
    EndUserResource,
    EndUserSearchResource,
    AppUserSearchResource,
    SearchPhoneResource,
    SearchExtensionMobilityProfilesResource,
    SiteMacManagementPermissionsResource,
    Global360ViewResource,
    LdapResource,
    PhoneResource,
    SearchResource,
    SystemStatusResource,
    PhoneButtonTemplateFieldConfigResource,
    ExtensionMobilityProfilesResource,
    MobilityIdentityResource,
    Microsoft365LicensesViewResource,
    MicrosoftProxyFeatureSettingsContext,

    // Services
    AsciiService,
    AuditHeaderService,
    DateTimeService,
    DefaultValueService,
    LocalStorageContext,
    RouteGuardService,
    RouteHelpersService,
    ServiceTypeDeterminationService,
    ServiceTypeMetadataService,
    SiteDeductionService,
    SmacsModalService,
    ToastService,
    UniqueIdService,
    ValidatorsService,
    BottomNavService,
    BreadcrumbsService,
    PhoneButtonTemplateService,
    LoginGuardService,
    ExtensionMobilityService,
    FileDownloadService,
    VoicemailService,

    // Filters
    ServiceTypeIconFilter,
    ServiceTypeNameFilter,
  ],
})
export class SharedModule {}
