import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomModal } from '../../../shared/services/smacs-modal.service';

interface PreviewModalViewProperties {
  image: string;
  type: 'LOGO' | 'BACKGROUND_IMAGE';
}

@Component({
  selector: 'smacs-admin-customize-ui-preview-modal',
  templateUrl: './customize-ui-preview-modal.component.html',
  styleUrls: ['./customize-ui-preview-modal.component.scss'],
})
export class CustomizeUiPreviewModalComponent implements CustomModal<PreviewModalViewProperties> {
  modalProperties: PreviewModalViewProperties;

  constructor(private activeModal: NgbActiveModal) {}

  onCloseClick() {
    this.activeModal.close(false);
  }
}
