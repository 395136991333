import { Component } from '@angular/core';
import { SpeedDialButton } from '../../../models/generated/smacsModels';

import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { PhoneButtonsService } from '../../shared/phone-buttons.service';
import { SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';

enum SpeedDialLabelTitle {
  LABEL_TITLE = 'tkey;pages.details.phoneSettings.button.speedDial.label',
  NUMBER_TITLE = 'tkey;pages.details.phoneSettings.button.speedDial.number',
  TYPE_TITLE = 'tkey;pages.details.phoneSettings.button.type',
}

@Component({
  selector: 'smacs-phone-buttons-speed-dial',
  templateUrl: './phone-buttons-speed-dial.component.html',
  styleUrls: ['./phone-buttons-form.scss'],
})
export class PhoneButtonsSpeedDialComponent extends SmacsFormAbstractDirective<SpeedDialButton> {
  fieldGroups = {
    textInputs: ['value', 'label'],
  };

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private phoneButtonsService: PhoneButtonsService
  ) {
    super(smacsFormStateService);
  }

  formConfig = {
    fields: {
      value: {
        label: SpeedDialLabelTitle.NUMBER_TITLE,
        dataAutomation: 'phone-button-speed-dial-number',
        required: false,
        validation: [
          {
            validator: (val: string) =>
              this.phoneButtonsService.validSpeedDialExtensionLength(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: {
              content: 'tkey;validators.global.error.maxlength',
              params: {
                maxlength: 50,
              },
            },
          },
          {
            validator: (val: string) =>
              this.phoneButtonsService.validSpeedDialExtensionPattern(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: 'tkey;validators.global.error.invalid_number.pattern',
          },
        ],
      },
      label: {
        label: SpeedDialLabelTitle.LABEL_TITLE,
        dataAutomation: 'phone-button-speed-dial-label',
        required: false,
        validation: [
          {
            validator: (val: string) =>
              this.phoneButtonsService.validLabelLength(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: {
              content: 'tkey;validators.global.error.maxlength',
              params: {
                maxlength: 30,
              },
            },
          },
          {
            validator: (val: string) =>
              this.phoneButtonsService.validSpeedDialLabelPattern(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: 'tkey;validators.global.error.pattern',
          },
        ],
      },
    },
    options: {
      columnClasses: {
        label: 'col-3 text-lg-end',
        input: 'col-9',
      },
    },
  };

  submit() {
    return this.parentSubmit$;
  }
}
