<form [autocomplete]="'new-password'">
  <div
    class="row form-group"
    [hidden]="(!state.hasOwnProperty('hidden') || state.hidden) && (!forceShow || state.defaultValue === null)"
    [attr.data-automation]="config.dataAutomation"
    [ngClass]="{
      'has-feedback': showValidation || isFormSubmitted || misconfigurationFeedbackMessage,
      'smacs-forms-error': (showValidation || isFormSubmitted) && validationState === ValStates.INVALID,
      'smacs-forms-warning':
        validationState === ValStates.PENDING ||
        validationState === ValStates.WARNING ||
        (misconfigurationFeedbackMessage && state.hidden),
      'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation,
      'mb-3': !displayEditor
    }"
  >
    <label
      *ngIf="config.label"
      [class]="'col-form-label ' + getLabelColumnClass()"
      [ngClass]="{
        'smacs-forms-label': inlineLabel,
        'col-12 pb-0': !inlineLabel
      }"
      [attr.for]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
      data-automation="smacs-text-label"
    >
      <ng-template #tipContent><span [innerHtml]="config.labelToolTipText | translate"></span></ng-template>
      <div class="d-inline-block">
        <div [ngbTooltip]="tipContent" [disableTooltip]="!config.labelToolTipText" class="fit-content d-inline-block">
          <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
          <span [innerHtml]="getLabel() | translate"></span>
          <i *ngIf="config.labelToolTipIconClass" class="{{ config.labelToolTipIconClass }} ms-1"></i>
        </div>
      </div>
    </label>

    <div [class]="getInputColumnClass()" [ngClass]="{ 'col-12': !config.label || !inlineLabel }">
      <div class="d-flex">
        <div
          class="input-group"
          [ngClass]="{
            'input-group-lg': inputSize === inputSizes.LG,
            'input-group-sm': inputSize === inputSizes.SM,
            'custom-form-feedback': inputAddOn && inputAddOn?.appendedContent
          }"
          [ngbTooltip]="tooltipContent"
          [disableTooltip]="shouldDisableTooltip || !getDisabledTooltip()?.content"
          [placement]="'top'"
        >
          <ng-template #tooltipContent>
            <span [innerHTML]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"></span>
          </ng-template>
          <span *ngIf="inputAddOn && inputAddOn?.prependedContent" class="input-group-text input-text-start">
            {{ inputAddOn?.prependedContent | translate }}
          </span>
          <span *ngIf="inputAddOn && inputAddOn.htmlPill" [innerHTML]="inputAddOn.htmlPill" class="mt-1"></span>
          <input
            [hidden]="inputAddOn && inputAddOn?.htmlPill"
            class="pe-4 border-rad-all-2"
            [ngClass]="{
              'form-control': !readOnly,
              'smacs-text-pending': validationState === ValStates.PENDING,
              'form-control-plaintext': readOnly,
              'pt-2': readOnly,
              'form-control-white-disabled': isWhiteWhileDisabled,
              'border-end-0': !!content?.clientWidth,
              'pe-none': cannotCopy
            }"
            [disabled]="state.disabled"
            [type]="inputType"
            [min]="min"
            [max]="inputType === htmlInputType.DATE ? '2999-12-31' : max"
            [step]="step"
            [readonly]="readOnly"
            [attr.id]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
            #ngModelDir="ngModel"
            (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
            [(ngModel)]="showMaskedInput ? maskedValue : fieldData"
            [ngModelOptions]="{ standalone: true }"
            [placeholder]="showMaskedInput ? starsPlaceholder : (placeholder | translate)"
            [autocomplete]="inputType === htmlInputType.PASSWORD && isChrome ? 'new-password' : 'off'"
            [tabIndex]="tabIndex"
            data-automation="smacs-text-input"
          />
          <button
            *ngIf="displayEditor"
            type="button"
            class="btn btn-default btn-expression-editor ms-0"
            data-automation="expression-editor"
            (click)="openEditorModal()"
            [disabled]="state.disabled"
          >
            <i [attr.class]="smacsIcons.EDIT"></i>
            {{ 'tkey;admin.site_management.custom_input_text_modal.mandatory_functions.button' | translate }}
          </button>

          <span *ngIf="inputAddOn && inputAddOn?.appendedContent" class="input-group-text input-text-end">
            {{ inputAddOn?.appendedContent | translate }}
          </span>

          <span
            class="{{ smacsIcons.WARNING }} form-control-feedback"
            [hidden]="
              !(
                (showAsyncValidation && validationState === ValStates.WARNING) ||
                (validationState === ValStates.VALID && misconfigurationFeedbackMessage && state.hidden)
              )
            "
            data-automation="smacs-text-warning-icon"
          ></span>

          <span
            class="{{ smacsIcons.SAVING }} form-control-feedback"
            [hidden]="!(validationState === ValStates.PENDING && showAsyncValidation)"
            data-automation="smacs-text-async-pending"
          ></span>

          <span
            class="{{ smacsIcons.REMOVE }} form-control-feedback"
            [hidden]="!(validationState === ValStates.INVALID && showAsyncValidation)"
            data-automation="smacs-text-async-invalid"
          ></span>

          <span
            class="{{ smacsIcons.OK }} form-control-feedback"
            [hidden]="!(validationState === ValStates.VALID && showAsyncValidation && !misconfigurationFeedbackMessage)"
            data-automation="smacs-text-async-valid"
          ></span>
        </div>

        <div #content><ng-content></ng-content></div>
      </div>

      <div class="smacs-forms-feedback-area">
        <div
          class="form-text small text-info"
          *ngIf="isAutogenerated"
          data-automation="smacs-forms-autogeneration-message"
        >
          {{ config.autogeneration?.generatedMsg | translate }}
        </div>

        <div
          *ngIf="(showValidation && !!validationMessage?.content) || isFormSubmitted"
          class="smacs-forms-validation-message"
          data-automation="smacs-forms-validation-message"
          [ngClass]="{ 'd-inline-block me-1': validationMessage && (showValidation || isFormSubmitted) }"
          [innerHTML]="validationMessage?.content | translate : validationMessage?.params"
        ></div>
        <div
          *ngIf="isMisConfigurationFeedbackDisplayed()"
          class="smacs-forms-validation-message"
          [ngClass]="{ 'd-inline-block me-1': config.autogeneration?.inline }"
          data-automation="smacs-forms-warning-message"
          [innerHTML]="
            (validationMessage && (showValidation || isFormSubmitted)
              ? 'tkey;smacs_text.misconfiguration_additional_feedback'
              : 'tkey;smacs_text.misconfiguration_feedback'
            ) | translate : { defaultValue: this.state.defaultValue }
          "
        ></div>

        <smacs-button
          [dataAutomation]="'smacs-forms-autogeneration-button'"
          [hidden]="isAutogenerateLinkHidden() || validationState === ValStates.PENDING"
          (clicked)="autogenerateValue()"
          [buttonStyle]="buttonStyles.LINK"
          [label]="getFixItLinkLabel()"
          [labelCssClass]="misconfigurationFeedbackMessage && !state.hidden ? 'small' : 'small strong'"
        ></smacs-button>

        <div *ngIf="getHelpText()" class="small form-text text-muted" [innerHtml]="getHelpText() | translate"></div>

        <div
          *ngIf="inputType === htmlInputType.TIME && localTime"
          data-automation="smacs-forms-local-time"
          class="small form-text text-muted"
        >
          {{ 'tkey;pages.zero_touch.edit.form.at_local_time' | translate }}
          <strong>{{ localTime }}</strong>
        </div>
      </div>
    </div>
  </div>
</form>
