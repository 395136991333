import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BackupResource {
  constructor(private http: HttpClient) {}

  getDailyBackups = (): Observable<string[]> => this.http.get<string[]>('/services/system/admin/backups/daily');
  getWeeklyBackups = (): Observable<string[]> => this.http.get<string[]>('/services/system/admin/backups/weekly');
}
