<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div [hidden]="isLoading">
  <app-breadcrumbs>
    <app-breadcrumb-card-item>
      <h4>{{ 'tkey;pages.zero_touch.provisioning.description.header' | translate }}</h4>
      <hr />
      <div [innerHTML]="'tkey;pages.zero_touch.provisioning.description.text' | translate"></div>
    </app-breadcrumb-card-item>
  </app-breadcrumbs>

  <app-entity-table
    *ngIf="table"
    [table]="table"
    [tableRows]="tableRows"
    data-automation="provisioning-job-entity-table"
  ></app-entity-table>
</div>
