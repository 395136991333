import { AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { PhoneButtonTypes } from '../../models/phone-button';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import {
  Button,
  DirectoryNumberRef,
  Global360View,
  LineFeatureFieldConfig,
  Phone,
} from '../../models/generated/smacsModels';
import { PhoneButtonsService } from '../shared/phone-buttons.service';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../forms/fields/select/smacs-select.component';
import { DragDropMode } from '../drag-drop-mode.enum';
import { SmacsFormConfig, SmacsFormsUpdate } from '../../../forms/smacs-forms-models';
import { LineButtonPeripheralSummary } from '../layout/phone-buttons-standard.component';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { Subject } from 'rxjs';
import { PhoneButtonsLineHelpdeskComponent } from './helpdesk/phone-buttons-line-helpdesk.component';
import { ExtensionMobilityDnD } from '../phone-buttons.component';

interface ButtonTypeFormData {
  type: SmacsSelectOption;
}

@Component({
  selector: 'smacs-phone-buttons-edit-form',
  templateUrl: './phone-buttons-edit-form.component.html',
})
export class PhoneButtonsEditFormComponent
  extends SmacsFormAbstractDirective<Button, ButtonTypeFormData>
  implements OnInit, AfterViewInit, OnChanges
{
  @ViewChild('form') childForm: SmacsFormAbstractDirective<Button>;
  @ViewChild(PhoneButtonsLineHelpdeskComponent) lineHelpdeskForm: PhoneButtonsLineHelpdeskComponent;

  @Input() userMode: DragDropMode;

  // the below inputs are only relevant in DragDropMode.HELPDESK
  @Input() isFixedButton: boolean;
  @Input() siteId: number;
  @Input() lineButtonSummary: LineButtonPeripheralSummary;
  @Input() lineFieldConfig: LineFeatureFieldConfig;
  @Input() phone: Phone | ExtensionMobilityDnD;
  @Input() buttonIndex: number;
  @Input() isAutomaticPhoneTemplateSelectionEnabled: boolean;
  @Input() buttonTypeOptions: SmacsSelectOption[];
  // only sent in helpdesk mode
  @Input() global360View: Global360View;
  @Input() validateChildFormFields: boolean;
  @Output() linePeripheralSummaryUpdated = new Subject<LineButtonPeripheralSummary>();
  @Output() lineButtonDnUpdated = new Subject<any>();

  ButtonTypes = PhoneButtonTypes;
  DragDropMode = DragDropMode;

  formConfig: SmacsFormConfig;

  constructor(protected smacsFormStateService: SmacsFormStateService, public phoneButtonsService: PhoneButtonsService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    if (!this.buttonTypeOptions?.length) {
      this.buttonTypeOptions = [{ value: this.entity.type, label: this.entity.type }];
    } else if (!this.buttonTypeOptions.find((option) => option.value === this.entity.type)) {
      // Add the existing button type to buttonTypeOptions when not present in metadata cache.
      this.buttonTypeOptions.push({ value: this.entity.type, label: this.entity.type });
    }

    this.formConfig = {
      fields: {
        type: {
          label: 'tkey;pages.details.phoneSettings.button.type',
          dataAutomation: 'phone-button-types',
          componentConfig: new SmacsSelectConfig({ options: this.buttonTypeOptions.filter((opt) => !!opt.group) }),
          required: true,
          hidden: () => this.userMode === DragDropMode.SELF_SERVE,
          disabled: () => this.isFixedButton || !this.isAutomaticPhoneTemplateSelectionEnabled,
          disabledTooltip: this.isFixedButton
            ? 'tkey;shared.device.phone.phone_buttons.type.fixed.disabled_tooltip'
            : 'tkey;shared.device.phone.phone_buttons.type.disabled_tooltip',
        },
      },
      options: {
        columnClasses: {
          label: 'col-3 text-lg-end',
          input: 'col-9',
        },
      },
    };
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    setTimeout(() => {
      this.fieldChannels.type.smacsFormUpdate$.subscribe((update: SmacsFormsUpdate<SmacsSelectOption>) => {
        if (update.new?.value && update.old?.value && update.new.value !== update.old.value) {
          const newButton = this.phoneButtonsService.newButtonOfType(update.new.value);
          this.entitySource.next(newButton);
        }
      });
    }, 0);

    // When this form is submitted, trigger a submit on the child.
    this._validateAndSubmitSource.subscribe((submit) => this.childForm._validateAndSubmitSource.next(submit));
  }

  handleButtonChange<T extends Button>(update: SmacsFormsUpdate<T>) {
    this.validationState = update.valid;
    this.formData = this.toFormData(update.new);
    const oldEntity = this.entity;
    this.entity = this.toEntity(this.formData);

    this.smacsFormsUpdateSource.next({
      old: oldEntity,
      new: this.entity,
      valid: this.validationState,
    });
  }

  handleVoicemailChange(update: LineButtonPeripheralSummary) {
    this.linePeripheralSummaryUpdated.next(update);
  }

  handleDnChange($event: any, dnRef: DirectoryNumberRef) {
    this.lineButtonDnUpdated.next({ update: $event, dnRef: dnRef });
  }

  submit() {
    return this.parentSubmit$;
  }

  toFormData = (entity: Button): ButtonTypeFormData => {
    return {
      ...entity,
      type: this.buttonTypeOptions.find((option) => option.value === entity.type),
    };
  };

  toEntity = (formData: ButtonTypeFormData): Button => {
    return {
      ...formData,
      type: formData.type.value,
    };
  };

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (
      !!changes.validateChildFormFields &&
      !changes.validateChildFormFields.isFirstChange() &&
      changes.validateChildFormFields.previousValue &&
      this.lineHelpdeskForm.lineHelpdeskFormComponent?.fieldChannels
    ) {
      Object.keys(this.lineHelpdeskForm.lineHelpdeskFormComponent?.fieldChannels).forEach((key) => {
        this.lineHelpdeskForm.lineHelpdeskFormComponent.fieldChannels[key].validateSource.next();
      });
    }
  }
}
