import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PhoneType } from '../../../../shared/models/service-type';
import {
  ExtensionMobilityFieldConfig,
  ExtensionMobilityFieldConfigRequest,
  PhoneFieldConfig,
} from '../../../../shared/models/generated/smacsModels';

export interface PhoneFieldConfigRequestParams {
  model: string;
  protocol: string;
  siteId: string;
  username?: string;
}

@Injectable()
export class PhoneFieldConfigResource {
  constructor(private http: HttpClient) {}

  get(
    phoneType: PhoneType,
    body: PhoneFieldConfigRequestParams
  ): Observable<PhoneFieldConfig | ExtensionMobilityFieldConfig> {
    switch (phoneType) {
      case PhoneType.EXTENSION_MOBILITY:
        return this.getExtMobilityFieldConfig(body as ExtensionMobilityFieldConfigRequest);
      case PhoneType.DESKPHONE:
      case PhoneType.ANDROID:
      case PhoneType.IPHONE:
      case PhoneType.TABLET:
      case PhoneType.IM_SOFTPHONE:
      case PhoneType.CIPC:
        return this.getPhoneFieldConfig(body);
      default:
        throw new Error(`phoneType [${phoneType}] did not match a field-configs endpoint`);
    }
  }

  getPhoneFieldConfig(configRequest: PhoneFieldConfigRequestParams): Observable<PhoneFieldConfig> {
    return this.http.get<PhoneFieldConfig>(
      configRequest.username
        ? `/services/cisco/field-configs/phone?username=${configRequest.username}&siteId=${configRequest.siteId}&model=${configRequest.model}&protocol=${configRequest.protocol}`
        : `/services/cisco/field-configs/phone?siteId=${configRequest.siteId}&model=${configRequest.model}&protocol=${configRequest.protocol}`
    );
  }

  getExtMobilityFieldConfig(body: ExtensionMobilityFieldConfigRequest): Observable<ExtensionMobilityFieldConfig> {
    return this.http.post<ExtensionMobilityFieldConfig>(
      '/services/cisco/field-configs/extension-mobility-profiles',
      body
    );
  }
}
