import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { SystemStatusContext } from '../contexts/system-status.context';
import {
  CurrentUser,
  GlobalProperties,
  HealthStatus,
  Privilege,
  Role,
  State,
  StatusCategory,
  SystemHealthStatus,
} from '../models/generated/smacsModels';
import { AuthenticationContext } from '../contexts/authentication.context';
import { first, map } from 'rxjs/operators';
import { forkJoin, Observable } from 'rxjs';
import { GlobalPropertiesContext } from '../contexts/global-properties.context';

@Injectable()
export class RouteGuardService {
  constructor(
    private authenticationContext: AuthenticationContext,
    private systemStatusContext: SystemStatusContext,
    private globalPropertiesContext: GlobalPropertiesContext,
    private router: Router
  ) {}

  /**
   * Validate route permissions and user role across all three phases before allowing user to access route
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this._resolvePhaseI().pipe(map((results) => this._resolveRolePermissionPhase(state, ...results)));
  }

  /**
   * Get the currently authenticated user for the next phase
   * Get the build properties as well as it's not a protected endpoint, and doesn't require authentication
   */
  private _resolvePhaseI(): Observable<[CurrentUser, SystemHealthStatus, GlobalProperties]> {
    return forkJoin([
      this.authenticationContext.state$.pipe(first()),
      this.systemStatusContext.state$.pipe(first()),
      this.globalPropertiesContext.state$.pipe(first()),
    ]);
  }

  private _resolveRolePermissionPhase(
    state: RouterStateSnapshot,
    user: CurrentUser,
    systemHealthStatus: SystemHealthStatus,
    globalProperties: GlobalProperties
  ): boolean {
    const isAuthed = !!user.role;
    if (!isAuthed) {
      if (state.url !== '/') {
        window.sessionStorage.setItem('login_redirect_url', `/app2/#${state.url}`);
      }
      this.router.navigateByUrl('login');
      return false;
    } else {
      const redirectUrl = window.sessionStorage.getItem('login_redirect_url');
      if (redirectUrl) {
        // This happens after logging in with SSO.
        window.sessionStorage.removeItem('login_redirect_url');
        window.location.href = redirectUrl;
        return false;
      }
    }

    if (state.url === '/') {
      this.router.navigateByUrl('home');
      return false;
    }

    if (
      this._isUnauthenticatedRoutingToAuthenticatedRoute(user, state) ||
      this._isNonAdminRoutingToAdminRoute(user, state) ||
      this._isAccessingReportingModuleWithoutReadAccess(user, state) ||
      this._isSelfServeUserRoutingToNonSelfServeRoute(user, state) ||
      this._isSiteBasedUserRoutingToSelfServeRoute(user, state) ||
      this._isNonS8SupportRoutingToS8SupportRoute(user, state) ||
      this._isHostedAdminNavigatingToApplicationLogs(user, state, globalProperties)
    ) {
      this._redirectUserHome(user);
      return false;
    }

    systemHealthStatus.healthStatuses.forEach((status: HealthStatus) => {
      if (status.category === StatusCategory.LICENSES && status.state === State.ERROR) {
        const allowedRoutes = ['system-health-status', 'admin/license'];
        if (!allowedRoutes.some((allowedRoute) => state.url.includes(allowedRoute))) {
          this.router.navigateByUrl('admin/system-health-status');
          return false;
        }
      }
    });

    systemHealthStatus.healthStatuses.forEach((status: HealthStatus) => {
      if (
        status.category === StatusCategory.ENDPOINTS &&
        status.state === State.WARNING &&
        // LDAP servers should not be considered disabled and should not block access to the other modules, see issue #9895
        !status.description.includes('LDAP')
      ) {
        const blockedRoutes = [
          '/automate/ldap-sync',
          '/automate/deprovisioning',
          '/automate/provisioning',
          '/automate/bulk-provisioning',
          '/automate/microsoft/bulk-provisioning',
          '/automate/microsoft/zero-touch-provisioning',
          '/reporting',
          '/phone-button-templates',
        ];
        if (blockedRoutes.some((blockedRoute) => state.url.includes(blockedRoute))) {
          this.router.navigate([]);
          window.location.href = '/app2/#/admin/system-health-status';
          return false;
        }
      }
    });
    return true;
  }

  private _redirectUserHome(user: CurrentUser) {
    switch (user.role) {
      case Role.ZIRO_SUPPORT:
      case Role.S8_ADMIN:
        this.router.navigateByUrl('admin');
        break;
      case Role.S8_SELF_SERVE_USER:
        if (user.userId.includes('@')) {
          this.router.navigateByUrl(`self-serve/user/${user.userId}`);
        } else {
          this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(user.userId)}`);
        }
        break;
      case Role.S8_SITE_BASED_HELPDESK:
      case Role.S8_HELPDESK:
      case Role.S8_GLOBAL_HELPDESK:
      default:
        this.router.navigateByUrl('home');
        break;
    }
  }

  private _isUnauthenticatedRoutingToAuthenticatedRoute(user: CurrentUser, state: RouterStateSnapshot): boolean {
    const nonAuthenticatedRoutes = ['/system-health-status'];
    return !user.role && !nonAuthenticatedRoutes.some((route) => state.url.startsWith(route));
  }

  private _isAccessingReportingModuleWithoutReadAccess(user: CurrentUser, state: RouterStateSnapshot): boolean {
    return state.url.startsWith('/reporting') && !user.privileges.includes(Privilege.CONTROL_READ);
  }

  private _isSelfServeUserRoutingToNonSelfServeRoute(user: CurrentUser, state: RouterStateSnapshot): boolean {
    if (state.url.startsWith('/activate')) {
      return false;
    }
    if (user.userId.includes('@')) {
      return user.role === Role.S8_SELF_SERVE_USER && !state.url.startsWith(`/self-serve/user/${user.userId}`);
    } else {
      return (
        user.role === Role.S8_SELF_SERVE_USER &&
        !state.url.startsWith(`/self-serve/user/${encodeURIComponent(user.userId)}`)
      );
    }
  }

  private _isSiteBasedUserRoutingToSelfServeRoute(user: CurrentUser, state: RouterStateSnapshot): boolean {
    return user.role === Role.S8_SITE_BASED_HELPDESK && state.url.startsWith(`/self-serve`);
  }

  private _isNonAdminRoutingToAdminRoute(user: CurrentUser, state: RouterStateSnapshot): boolean {
    const adminRoutes = ['/admin', '/automate'];
    return (
      !this.authenticationContext.userIsAtLeast(user, Role.S8_ADMIN) &&
      adminRoutes.some((route: string) => state.url.startsWith(route))
    );
  }

  private _isNonS8SupportRoutingToS8SupportRoute(user: CurrentUser, state: RouterStateSnapshot): boolean {
    const s8supportRoutes = ['/admin/billing'];
    return user.role !== Role.ZIRO_SUPPORT && s8supportRoutes.some((route) => state.url.startsWith(route));
  }

  private _isHostedAdminNavigatingToApplicationLogs(
    user: CurrentUser,
    state: RouterStateSnapshot,
    globalProperties: GlobalProperties
  ): boolean {
    return globalProperties.hostedEnabled && user.role !== Role.ZIRO_SUPPORT && state.url.endsWith('application-logs');
  }
}

export const routeGuardFn: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
  inject(RouteGuardService).canActivate(route, state);
