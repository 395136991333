<div class="smacs-admin-page-content">
  <div class="pb-2">
    <div class="col-md-10 offset-md-1 animated fadeIn">
      <app-breadcrumbs>
        <app-breadcrumb-card-item>
          <div [innerHTML]="'tkey;pages.admin.application_logs.description' | translate"></div>
        </app-breadcrumb-card-item>
      </app-breadcrumbs>

      <ul class="list-group mb-3">
        <li class="list-group-item">
          <div class="d-md-flex align-items-center justify-content-between d-block">
            <div>
              <h5>{{ 'tkey;pages.admin.application_logs.todays.title' | translate }}</h5>
              <p>{{ 'tkey;pages.admin.application_logs.todays.description' | translate }}</p>
            </div>
            <smacs-button
              [dataAutomation]="'export-todays-logs-button'"
              (clicked)="getTodaysLogs()"
              [isDisabled]="todaysLogsIsDownloading"
              [isPending]="todaysLogsIsDownloading"
              [icon]="icons.DOWNLOAD"
              [iconPending]="icons.SAVING"
              [buttonStyle]="buttonStyle.PRIMARY"
              [label]="'tkey;pages.admin.application_logs.todays.button'"
            ></smacs-button>
          </div>
        </li>
        <li class="list-group-item bg-light">
          <div class="d-md-flex align-items-center justify-content-between d-block">
            <div>
              <h5>{{ 'tkey;pages.admin.application_logs.all.title' | translate }}</h5>
              <p>
                {{ 'tkey;pages.admin.application_logs.all.description' | translate }}
              </p>
            </div>
            <smacs-button
              [dataAutomation]="'export-all-logs-button'"
              (clicked)="getAllLogs()"
              [isDisabled]="allLogsIsDownloading"
              [isPending]="allLogsIsDownloading"
              [icon]="icons.DOWNLOAD"
              [iconPending]="icons.SAVING"
              [buttonStyle]="buttonStyle.PRIMARY"
              [label]="'tkey;pages.admin.application_logs.all.button'"
            ></smacs-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>
