<app-self-serve-top-bar></app-self-serve-top-bar>

<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div *ngIf="!isLoading" data-automation="activate-deskphone-component">
  <div *ngIf="canActivateDeskphone">
    <div class="row col-12 overflow-hidden">
      <div class="col-lg-6 position-relative">
        <app-multi-step-animation
          [currentStep]="animationCurrentStep"
          [steps]="lottieAnimationSteps"
          [animationStart$]="animationReplaySource.asObservable()"
        ></app-multi-step-animation>
      </div>
      <div class="phone-step-container col-lg-6 d-flex align-items-center justify-content-center position-relative">
        <div
          *ngIf="currentStep === 1"
          [@stepAnimation]="currentStep === 1"
          [ngClass]="{ 'phone-step-container__step--active': currentStep === 1 }"
          class="row w-100 phone-step-container__step"
          data-automation="activate-phone-step-1"
        >
          <div class="col-12 overflow-hidden">
            <div class="row">
              <div class="col-12 align-content-center d-flex flex-wrap">
                <div>
                  <h2 class="w-100 text-start">
                    {{ 'tkey;selfserve.user.activate_deskphone.step_one.title' | translate }}
                  </h2>
                  <p
                    class="text-start d-inline-block"
                    [innerHTML]="
                      'tkey;selfserve.user.activate_deskphone.step_one.body'
                        | translate : { name: currentUserRef.firstName }
                    "
                  ></p>
                  <div class="text-end">
                    <smacs-button
                      [dataAutomation]="'activate-deskphone-cancel-step-1-button'"
                      (click)="onCancelClick()"
                      [buttonSize]="buttonSizes.LARGE"
                      [buttonStyle]="buttonStyles.DEFAULT"
                      [label]="'tkey;global.button.cancel.text'"
                      class="me-1"
                    ></smacs-button>

                    <smacs-button
                      [dataAutomation]="'activate-deskphone-begin-button'"
                      (click)="onBeginClick()"
                      [buttonSize]="buttonSizes.LARGE"
                      [buttonStyle]="buttonStyles.PRIMARY"
                      [label]="'tkey;selfserve.user.activate_deskphone.step_one.button.title'"
                    ></smacs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="currentStep === 2"
          [@stepAnimation]="currentStep === 2"
          [ngClass]="{ 'phone-step-container__step--active': currentStep === 2 }"
          class="row phone-step-container__step"
          data-automation="activate-phone-step-2"
        >
          <div class="col-12">
            <div class="row">
              <div class="col-12 align-content-center d-flex flex-wrap">
                <div>
                  <h2 class="w-100 text-start">
                    {{ 'tkey;selfserve.user.activate_deskphone.step_two.title' | translate }}
                  </h2>
                  <h5 class="w-100 text-start">
                    {{ 'tkey;selfserve.user.activate_deskphone.step_two.body' | translate }}
                  </h5>
                  <ul class="mt-3 w-100 text-start">
                    <li>
                      {{ 'tkey;selfserve.user.activate_deskphone.step_two.body.two' | translate }}
                    </li>
                    <li>
                      {{ 'tkey;selfserve.user.activate_deskphone.step_two.body.three' | translate }}
                    </li>
                  </ul>
                  <div class="text-end">
                    <smacs-button
                      [dataAutomation]="'activate-deskphone-cancel-step-2-button'"
                      (click)="onCancelClick()"
                      [buttonSize]="buttonSizes.LARGE"
                      [buttonStyle]="buttonStyles.DEFAULT"
                      [label]="'tkey;global.button.cancel.text'"
                      class="me-1"
                    ></smacs-button>

                    <smacs-button
                      [dataAutomation]="'activate-deskphone-next-button'"
                      (click)="onNextClick()"
                      [buttonSize]="buttonSizes.LARGE"
                      [buttonStyle]="buttonStyles.PRIMARY"
                      [label]="'tkey;global.button.next.text'"
                    ></smacs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="currentStep === 4"
          [@stepAnimation]="currentStep === 4"
          [ngClass]="{ 'phone-step-container__step--active': currentStep === 4 }"
          class="row phone-step-container__step"
          data-automation="activate-phone-step-4"
        >
          <div class="col-12 align-content-center d-flex flex-wrap overflow-hidden">
            <h2 class="w-100 text-start">{{ 'tkey;selfserve.user.activate_deskphone.step_four.body' | translate }}</h2>
            <h5 class="w-100 text-start">
              {{ 'tkey;selfserve.user.activate_deskphone.step_four.body.two' | translate }}
            </h5>
            <p
              class="w-100 text-start mt-3"
              [innerHtml]="'tkey;selfserve.user.activate_deskphone.step_four.body.three' | translate"
            ></p>
          </div>
        </div>
        <div
          *ngIf="currentStep === 5"
          [@stepAnimation]="currentStep === 5"
          [ngClass]="{ 'phone-step-container__step--active': currentStep === 5 }"
          class="row phone-step-container__step"
          data-automation="activate-phone-step-5"
        >
          <div class="col-12">
            <div class="row">
              <div class="col-12 align-content-center d-flex flex-wrap">
                <div>
                  <h2 class="w-100 text-start">
                    {{ 'tkey;selfserve.user.activate_deskphone.step_five.body' | translate }}
                  </h2>
                  <p class="w-100 text-start">
                    {{ 'tkey;selfserve.user.activate_deskphone.step_five.body.two' | translate }}
                  </p>
                  <div class="text-end">
                    <a
                      [routerLink]="['/self-serve/user/', username]"
                      class="btn btn-primary btn-lg"
                      data-automation="activate-deskphone-return-button"
                    >
                      {{ 'tkey;selfserve.user.activate_deskphone.return_button.title' | translate }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          [hidden]="currentStep !== 3"
          [ngClass]="{ 'phone-step-container__step--active': currentStep === 3 }"
          class="row phone-step-container__step fadeIn animated animation-delay-half-second"
          data-automation="activate-phone-step-3"
        >
          <div class="col-12">
            <div class="row">
              <div class="col-12 align-content-center d-flex flex-wrap">
                <div>
                  <h2 class="w-100 text-start">
                    {{ 'tkey;selfserve.user.activate_deskphone.step_three.title' | translate }}
                  </h2>
                  <h5 class="w-100 text-start">
                    {{ 'tkey;selfserve.user.activate_deskphone.step_three.body' | translate }}
                  </h5>

                  <smacs-text fieldId="extension"></smacs-text>

                  <div class="text-end">
                    <smacs-button
                      [dataAutomation]="'activate-deskphone-cancel-step-3-button'"
                      (click)="onCancelClick()"
                      [buttonSize]="buttonSizes.LARGE"
                      [buttonStyle]="buttonStyles.DEFAULT"
                      [label]="'tkey;global.button.cancel.text'"
                      class="me-1"
                    ></smacs-button>

                    <smacs-submit-button
                      [isPending]="isSubmitting || isActivationInProgress"
                      [isDisabled]="isSubmitting || isActivationInProgress || !isFormValid()"
                      [label]="'tkey;selfserve.user.activate_deskphone.save'"
                      [labelPending]="'tkey;selfserve.user.activate_deskphone.saving'"
                      [dataAutomation]="'activate-deskphone-button'"
                      [iconPending]="smacsIcons.SAVING"
                      [formSubmitSubject]="_validateAndSubmitSource"
                      [buttonSize]="buttonSizes.LARGE"
                    ></smacs-submit-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!canActivateDeskphone" class="d-flex justify-content-center">
    <div class="text-center my-5" data-automation="misconfiguration-alert">
      <h2>
        <i class="{{ smacsIcons.WARNING }} text-warning me-2"></i>
        {{ 'tkey;selfserve.user.activate_deskphone.forbidden.header' | translate }}
      </h2>
      <hr />
      <p [innerHTML]="'tkey;selfserve.user.activate_deskphone.forbidden.text' | translate"></p>
    </div>
  </div>
</div>
