<div
  class="tile add-service-tile animated"
  data-automation="add-service-tile"
  [ngClass]="{
    muted: !isEnabled,
    'tile-warning infinite pulse': isAdding && !isInProgress,
    'tile-default': !isAdding && !isInProgress,
    'tile-in-progress': isInProgress,
    'flipInY fast': isDeleted && !isAdding
  }"
  [ngbTooltip]="tooltip | translate"
  (click)="onTileClicked()"
>
  <div class="text-center">
    <p [ngClass]="'svg-icon-class'">
      <svg-icon [name]="icon" [svgStyle]="{ 'width.px': 40 }"></svg-icon>
    </p>

    <div class="user-detail-tile-title mb-1">
      <span data-automation="service-tile-name">{{ serviceName | translate }}</span>
    </div>

    <i *ngIf="!isAdding && !isInProgress && isEnabled" class="fa fa-plus-circle fa-2x"></i>

    <i *ngIf="isAdding && !isInProgress" class="icon-saving fa-2x tile-add-spinner"></i>

    <div
      *ngIf="isInProgress"
      class="mx-auto d-flex align-items-center alert-inline alert alert-warning animated fadeIn"
    >
      <i class="icon-saving fa-1x tile-add-spinner me-2"></i>
      <div class="d-inline-flex align-items-center" data-automation="add-service-tile-in-progress">
        {{ isInProgressBadgeText | translate }}
      </div>
    </div>
  </div>
</div>
