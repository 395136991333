import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { find, isMatch } from 'lodash';
import { MsZeroTouchProvisioningContext } from '../../../../admin/contexts/ms-zero-touch-provisioning.context';
import { ButtonStyles } from '../../../../button/button.component';
import { SmacsRadioConfig } from '../../../../forms/fields/radio/smacs-radio.component';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../forms/fields/select/smacs-select.component';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { Microsoft365UserResource } from '../../../../helpdesk/shared/resources/microsoft-365-user.resource';
import { MicrosoftDialPlansResource } from '../../../../helpdesk/shared/resources/ms-dial-plans.resource';
import { BottomNavButton } from '../../../../shared/bottom-nav/bottom-nav.component';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { CollapsibleCardSwitchValue } from '../../../../shared/collapsible-card/collapsible-card.component';
import {
  JobSchedule,
  JobState,
  JobStatus,
  Microsoft365UserResult,
  MicrosoftDialPlanFieldConfig,
  MicrosoftProvisioningJob,
  MicrosoftProvisioningJobOptions,
  MicrosoftTeamsAudioConference,
  ZeroTouchAudioConference,
} from '../../../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SCHEDULE_FREQUENCY } from '../../../../shared/models/zero-touch';
import { ToastService } from '../../../../shared/services/toast.service';
import { ViewMembersState } from '../../../../shared/view-members/view-members.component';
import { combineLatest, Observable, ReplaySubject, Subscriber, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ZeroTouchSchedulingService } from '../../../services/zero-touch-scheduling.service';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { MicrosoftProvisioningStatusPollingService } from '../../../../shared/services/microsoft/microsoft-provisioning-status-polling.service';
import { MicrosoftTeamsAudioConferencingFieldConfigResource } from '../../../../helpdesk/shared/resources/microsoft-teams-audio-conferencing-field-config.resource';

const PERIODIC_JOB_MIN_DURATION = 60;

interface MsProvisioningJobFormData {
  name: string;
  searchFilter: string;
  dialPlanGroups?: number[];
  audioConfNumber?: string;
  periodicJobMinutes?: number;
  scheduleFrequency?: any;
  dailyJobTime: string;
}

enum SectionToggles {
  AUDIO_CONFERENCE = 'audioConfToggle',
  SCHEDULE = 'scheduleToggle',
}

interface ToggleState {
  isAudioConfEnabled: boolean;
  isScheduleEnabled: boolean;
}

@Component({
  selector: 'ziro-ms-provisioning-edit-job',
  templateUrl: './ziro-ms-provisioning-edit-job.component.html',
  styleUrls: ['./ziro-ms-provisioning-edit-job.component.scss'],
  providers: [MicrosoftProvisioningStatusPollingService],
})
export class ZiroMsProvisioningEditJobComponent
  extends SmacsFormAbstractDirective<MsProvisioningJobFormData>
  implements OnInit, OnDestroy
{
  jobId: number;
  formConfig: SmacsFormConfig;
  job: MicrosoftProvisioningJob;
  nextRunTimesMessage = '';
  searchFilterState = ViewMembersState.IDLE;
  userMatches: string[] = [];
  isViewUsersDisabled = false;
  isLoading = true;
  sectionToggleState: ToggleState = {
    isAudioConfEnabled: false,
    isScheduleEnabled: false,
  };
  isRunning = false;
  isSaving = false;
  isFormDirty = false;
  dialPlanFieldConfig: MicrosoftDialPlanFieldConfig[] = [];
  dialPlanGroupsSelect: SmacsSelectOption[] = [];
  microsoftTeamsAudioConference: MicrosoftTeamsAudioConference = null;
  validators = {
    isUnique: (val: string, comparisonKey: 'name' | 'searchFilter'): Observable<SmacsFormsValidationState> => {
      return new Observable<SmacsFormsValidationState>((subscriber: Subscriber<SmacsFormsValidationState>) => {
        this._msZeroTouchProvisioningContext.getAll().subscribe((existingJobs: MicrosoftProvisioningJob[]) => {
          const match = find(
            existingJobs,
            (job: MicrosoftProvisioningJob) =>
              this.jobId !== job.id && job[comparisonKey].toLowerCase() === val.toLowerCase()
          );

          subscriber.next(match ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID);
          subscriber.complete();
        });
      });
    },
    isNameUnique: (val: string): Observable<SmacsFormsValidationState> => this.validators.isUnique(val, 'name'),
    isSearchFilterUnique: (val: string): Observable<SmacsFormsValidationState> => {
      return new Observable<SmacsFormsValidationState>((subscriber: Subscriber<SmacsFormsValidationState>) => {
        this.validators.isUnique(val, 'searchFilter').subscribe((isUnique: SmacsFormsValidationState) => {
          if (isUnique !== SmacsFormsValidationState.VALID) {
            this.searchFilterState = ViewMembersState.IDLE;
          }

          subscriber.next(isUnique);
          subscriber.complete();
        });
      });
    },
    isSearchFilterValid: (val: string): Observable<SmacsFormsValidationState> => this._isSearchFilterValid(val),
  };
  smacsIcons = SmacsIcons;

  private _subscriptions = new Subscription();
  private _formInitializedSource = new ReplaySubject<void>(1);

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _msZeroTouchProvisioningContext: MsZeroTouchProvisioningContext,
    private _translateService: TranslateService,
    private _bottomNavService: BottomNavService,
    private _breadcrumbsService: BreadcrumbsService,
    private _toastService: ToastService,
    private _microsoft365UserResource: Microsoft365UserResource,
    private _microsoftTeamsAudioConferencingFieldConfigResource: MicrosoftTeamsAudioConferencingFieldConfigResource,
    private _microsoftDialPlansResource: MicrosoftDialPlansResource,
    private _zeroTouchSchedulingService: ZeroTouchSchedulingService,
    private _smacsModalService: SmacsModalService,
    protected smacsFormStateService: SmacsFormStateService,
    private _microsoftProvisioningStatusPollingService: MicrosoftProvisioningStatusPollingService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this.jobId = parseInt(this._route.snapshot.params.jobId) || null;
    this._pollForStatus();
    this._initFormConfig();
    const smacsFormSub = this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<MsProvisioningJobFormData>) => {
      if (data?.new) {
        this._formInitializedSource.next();
        this._setIsFormDirty();

        if (data.new.periodicJobMinutes !== data.old?.periodicJobMinutes) {
          this._setRunTimeMessage(data.new.periodicJobMinutes);
        }
      }
    });
    this._subscriptions.add(smacsFormSub);

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this.isErrorPresent.subscribe((hasError) => {
        this._setBottomNavValidationError(hasError);
      });
    });
    this._subscriptions.add(formSubmittedSub);

    this._initForm().subscribe(() => {
      this._getJob().subscribe(() => {
        this._formInitializedSource
          .asObservable()
          .pipe(take(1))
          .subscribe(() => {
            this._retrieveDialPlanGroups();
            this._revalidateFields();
          });
      });
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
    this._microsoftProvisioningStatusPollingService.stopPolling();
  }

  onToggle(event: CollapsibleCardSwitchValue) {
    switch (event.switchId) {
      case SectionToggles.AUDIO_CONFERENCE: {
        this.sectionToggleState.isAudioConfEnabled = event.isChecked;
        if (event.isChecked) {
          this._retrieveAudioConferenceNumber();
        }
        this.fieldChannels['audioConfNumber'].validateSource.next();
        break;
      }
      case SectionToggles.SCHEDULE: {
        this.sectionToggleState.isScheduleEnabled = event.isChecked;
        const scheduleField = this.fieldComponents.find((field) => field.fieldId === 'scheduleFrequency');
        scheduleField.state.hidden = !this.sectionToggleState.isScheduleEnabled;
        this.fieldChannels['scheduleFrequency'].validateSource.next();
        break;
      }
    }
  }

  protected submit() {
    return this._saveJob();
  }

  private _getJob(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      if (this.jobId) {
        // Existing Job
        this._msZeroTouchProvisioningContext.get(this.jobId).subscribe((provisioningJob: MicrosoftProvisioningJob) => {
          this.job = provisioningJob;

          this.sectionToggleState = {
            isAudioConfEnabled: !!provisioningJob.options.audioConferenceNumber,
            isScheduleEnabled: !!provisioningJob.schedule,
          };
          this._breadcrumbsService.updateBreadcrumbs([
            {
              label: 'tkey;pages.zero_touch.provisioning.title',
              url: '/app2/#/automate/microsoft/zero-touch-provisioning',
            },
            { label: provisioningJob.name },
          ]);

          this._retrieveAudioConferenceNumber();
          this.entitySource.next({
            name: provisioningJob.name,
            searchFilter: provisioningJob.searchFilter,
            audioConfNumber: !!provisioningJob.options.audioConferenceNumber
              ? provisioningJob.options.audioConferenceNumber.audioConferenceNumber
              : null,
            dialPlanGroups: provisioningJob.options.teamsCalling
              ? provisioningJob.options.teamsCalling.dialPlanGroupIds
              : null,
            scheduleFrequency: this.sectionToggleState.isScheduleEnabled
              ? this._getScheduleFrequency(provisioningJob)
              : null,
            dailyJobTime: this.sectionToggleState.isScheduleEnabled ? provisioningJob.schedule.dailyJobTime : '',
            periodicJobMinutes: this.sectionToggleState.isScheduleEnabled
              ? provisioningJob.schedule.periodicJobMinutes
              : PERIODIC_JOB_MIN_DURATION,
          } as MsProvisioningJobFormData);

          if (
            this.sectionToggleState.isScheduleEnabled &&
            this._getScheduleFrequency(this.job) === SCHEDULE_FREQUENCY.PERIODICALLY
          ) {
            this._setRunTimeMessage(this.job.schedule.periodicJobMinutes);
          }
        });
        this._initBottomNav();
        this.isLoading = false;
        subscriber.next();
        subscriber.complete();
      } else {
        // New Job
        this._breadcrumbsService.updateBreadcrumbs([
          {
            label: 'tkey;pages.zero_touch.provisioning.title',
            url: '/app2/#/automate/microsoft/zero-touch-provisioning',
          },
          { label: 'tkey;pages.zero_touch.job.edit.breadcrumb.new_job' },
        ]);

        this.sectionToggleState = {
          isAudioConfEnabled: false,
          isScheduleEnabled: false,
        };
        this.entitySource.next({
          name: '',
          searchFilter: '',
          dialPlanGroups: [],
          audioConfNumber: null,
          scheduleFrequency: null,
          dailyJobTime: null,
          periodicJobMinutes: PERIODIC_JOB_MIN_DURATION,
        });
        this._initBottomNav();
        this.isLoading = false;
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        name: {
          label: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.job_name.label',
          dataAutomation: 'provisioning-job-name',
          required: true,
          validation: [
            {
              validator: this.validators.isNameUnique,
              message: 'tkey;pages.zero_touch.name.unique',
            },
          ],
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          helpText: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.job_name.helpText',
        },
        searchFilter: {
          label: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.graph_filter.label',
          labelToolTipText: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.graph_filter.tooltip',
          labelToolTipIconClass: `icon ${this.smacsIcons.INFO}`,
          dataAutomation: 'provisioning-search-filter',
          required: true,
          validation: [
            {
              validator: this.validators.isSearchFilterUnique,
              message: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.graph_filter.unique.message',
            },
            {
              validator: this.validators.isSearchFilterValid,
              message: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.graph_filter.valid.message',
            },
          ],
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.TEXT,
            placeholder: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.graph_filter.placeholder',
            htmlInputAddOn: {
              prependedContent: 'users?$filter=',
              appendedContent: '&$count=true',
            },
          }),
          helpText: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.graph_filter.helptext',
        },
        dialPlanGroups: {
          label: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.teams_calling.dial_plan_group.label',
          dataAutomation: 'provisioning-dial-plan-group',
          required: () => true,
          componentConfig: new SmacsSelectConfig({
            options: this.dialPlanGroupsSelect,
            isMultiSelect: true,
            bindValue: 'value',
          }),
          helpText: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.teams_calling.dial_plan_group.helptext',
        },
        audioConfNumber: {
          label: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.teams_audio_conf_settings.number.label',
          dataAutomation: 'provisioning-audio-conf-number',
          required: () => true,
          componentConfig: new SmacsSelectConfig({ options: [], bindValue: 'value' }),
          valExcluded: () => !this.sectionToggleState.isAudioConfEnabled,
        },
        scheduleFrequency: {
          label: 'tkey;pages.zero_touch.schedule',
          dataAutomation: 'provisioning-schedule-frequency',
          required: () => true,
          componentConfig: new SmacsRadioConfig({
            buttons: [
              {
                value: SCHEDULE_FREQUENCY.PERIODICALLY,
                label: 'tkey;pages.zero_touch.edit.form.periodically',
              },
              {
                value: SCHEDULE_FREQUENCY.DAILY,
                label: 'tkey;pages.zero_touch.edit.form.daily',
              },
            ],
            inline: true,
          }),
          hidden: () => !this.sectionToggleState.isScheduleEnabled,
          valExcluded: () => !this.sectionToggleState.isScheduleEnabled,
        },
        periodicJobMinutes: {
          label: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.periodic.schedule.label',
          dataAutomation: 'provisioning-schedule-interval',
          required: () => this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.PERIODICALLY,
          hidden: () => this.formData.scheduleFrequency !== SCHEDULE_FREQUENCY.PERIODICALLY,
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
          helpText: () => this.nextRunTimesMessage,
          validation: [
            {
              validator: (val: number) => {
                if (
                  !this.sectionToggleState.isScheduleEnabled ||
                  this.formData.scheduleFrequency !== SCHEDULE_FREQUENCY.PERIODICALLY
                ) {
                  return SmacsFormsValidationState.VALID;
                }

                return val >= PERIODIC_JOB_MIN_DURATION
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: this._translateService.instant('tkey;validators.global.error.min', {
                minValue: PERIODIC_JOB_MIN_DURATION,
              }),
            },
          ],
        },
        dailyJobTime: {
          label: 'tkey;pages.zero_touch.microsoft.provisioning.edit.job.daily_at_run_time.label',
          dataAutomation: 'provisioning-schedule-time',
          required: () => this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.DAILY,
          hidden: () => this.formData.scheduleFrequency !== SCHEDULE_FREQUENCY.DAILY,
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TIME }),
        },
      },
    } as SmacsFormConfig;
  }

  private _initBottomNav() {
    const bottomNavButtons = [
      {
        id: 'runNowMsProvisioning',
        dataAutomation: 'ms-provisioning-run-now',
        label: 'tkey;pages.zero_touch.job.run_now',
        buttonClass: ButtonStyles.INFO,
        tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip.disabled',
        state: {
          pending: !!this.isRunning,
          buttonDisableState: {
            disabled: this.jobId ? !!this.isRunning : true,
            tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip.disabled',
          },
          tooltipVisible: !this.jobId,
        },
        icon: this.smacsIcons.RUN,
        cb: () => this._runJobNow(),
      },
      {
        id: 'cancelMsProvisioning',
        dataAutomation: 'ms-provisioning-cancel',
        label: 'tkey;global.button.cancel.text',
        buttonClass: ButtonStyles.OUTLINE_DEFAULT,
        tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip',
        state: {
          pending: false,
          buttonDisableState: { disabled: false },
          tooltipVisible: true,
        },
        cb: () => {
          this.jobId
            ? this._router.navigate(['../../'], { relativeTo: this._route })
            : this._router.navigate(['../'], { relativeTo: this._route });
        },
      },
      {
        id: 'saveMsProvisioning',
        dataAutomation: 'ms-provisioning-save',
        label: 'tkey;admin.ui.save',
        icon: this.smacsIcons.OK,
        buttonClass: ButtonStyles.PRIMARY,
        state: {
          pending: false,
          buttonDisableState: {
            disabled: !!this.isRunning,
            tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip',
          },
          tooltipVisible: !!this.isRunning,
        },
        cb: () => this._submitForm(),
      },
    ] as BottomNavButton[];

    if (this.jobId) {
      bottomNavButtons.splice(2, 0, {
        id: 'deleteMsProvisioning',
        dataAutomation: 'bottom-nav-delete-button',
        label: 'tkey;global.button.delete.text',
        icon: SmacsIcons.DELETE,
        buttonClass: ButtonStyles.DANGER,
        state: {
          pending: false,
          buttonDisableState: {
            disabled: !!this.isRunning,
            tooltipKey: 'tkey;pages.zero_touch.job.run_now.tooltip',
          },
          tooltipVisible: !!this.isRunning,
        },
        cb: () => {
          this._onDeleteClicked();
        },
      });
    }

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _setIsFormDirty() {
    if (this.job && this.formData) {
      const formData = this._toEntityData();
      const jobCopy = { ...this.job };
      delete jobCopy.id;

      if (!this.isFormDirty && !isMatch(jobCopy, formData)) {
        this.isFormDirty = true;
      }
      if (this.isFormDirty) {
        this._setButtonState('runNowMsProvisioning', true, false, true);
      }
    }
  }

  private _saveJob(): Observable<void> {
    const job = this._toEntityData();
    const saveJob$ = !!this.jobId
      ? this._msZeroTouchProvisioningContext.put({ id: this.jobId, ...job })
      : this._msZeroTouchProvisioningContext.post(job);

    return new Observable<void>((subscriber) => {
      saveJob$.subscribe({
        next: () => {
          this._toastService.pushSaveToast(
            'tkey;pages.zero_touch.provisioning.title',
            job.name,
            this.smacsIcons.ARCHIVE
          );
          this.isSaving = false;
          this._resetBottomNavButtonsState();
          this.job = { id: this.jobId, ...job };
          this._setIsFormDirty();

          if (!this.jobId) {
            this._router.navigate(['../'], { relativeTo: this._route });
          }
          subscriber.next();
          subscriber.complete();
        },
        error: (error) => {
          this._resetBottomNavButtonsState();
          subscriber.next();
          subscriber.complete();
          throw new HttpErrorResponse(error);
        },
      });
    });
  }

  private _searchTeamsCallingUsers(filter: string): Observable<Microsoft365UserResult[]> {
    return this._microsoft365UserResource.advancedSearch(filter);
  }

  private _initForm() {
    return new Observable((subscriber) => {
      const subs = combineLatest([
        this._microsoftDialPlansResource.getAllDialPlanGroups(),
        this._microsoftTeamsAudioConferencingFieldConfigResource.get(),
      ]).subscribe(([dialPlanFieldConfig, audioConf]) => {
        this.dialPlanFieldConfig = dialPlanFieldConfig;
        this.microsoftTeamsAudioConference = audioConf;
        subscriber.next();
        subscriber.complete();
      });
      this._subscriptions.add(subs);
    });
  }

  private _isSearchFilterValid(searchFilter: string): Observable<SmacsFormsValidationState> {
    this.searchFilterState = ViewMembersState.SEARCHING;
    return new Observable<SmacsFormsValidationState>((subscriber: Subscriber<SmacsFormsValidationState>) => {
      if (!searchFilter) {
        this.userMatches = [];
        this.searchFilterState = ViewMembersState.IDLE;
        this.isViewUsersDisabled = false;
        subscriber.next(SmacsFormsValidationState.VALID);
        subscriber.complete();
      } else {
        this._searchTeamsCallingUsers(searchFilter).subscribe({
          next: (userResults: Microsoft365UserResult[]) => {
            this.userMatches = userResults.map((userResult: Microsoft365UserResult) => {
              return userResult.microsoft365UserRef.id;
            });
            this.searchFilterState = ViewMembersState.IDLE;
            this.isViewUsersDisabled = false;
            subscriber.next(SmacsFormsValidationState.VALID);
            subscriber.complete();
          },
          error: (e) => {
            this.userMatches = [];
            if (e.error) {
              this.searchFilterState = ViewMembersState.IDLE;
              this.isViewUsersDisabled = true;
              subscriber.next(SmacsFormsValidationState.INVALID);
              subscriber.complete();
            }
          },
        });
      }
    });
  }

  private _retrieveAudioConferenceNumber() {
    const audioConfSelect = this.fieldComponents.find((field) => field.fieldId === 'audioConfNumber');
    audioConfSelect.applyComponentConfig(
      new SmacsSelectConfig({
        options: this.microsoftTeamsAudioConference.audioConferencingServiceNumbers.possibleOptions,
        bindValue: 'value',
      })
    );
  }

  private _retrieveDialPlanGroups() {
    this.dialPlanGroupsSelect = this.dialPlanFieldConfig.map<SmacsSelectOption>(
      (dialPlanGroup: MicrosoftDialPlanFieldConfig) => {
        return {
          label: dialPlanGroup.name,
          value: dialPlanGroup.id,
        };
      }
    );
    const dialPlanGroupSelect = this.fieldComponents.find((field) => field.fieldId === 'dialPlanGroups');
    dialPlanGroupSelect.applyComponentConfig(
      new SmacsSelectConfig({ options: this.dialPlanGroupsSelect, isMultiSelect: true, bindValue: 'value' })
    );
  }

  private _revalidateFields() {
    Object.keys(this.fieldChannels).forEach((key) => {
      this.fieldChannels[key].validateSource.next();
    });
  }

  private _runJobNow() {
    if (this.jobId) {
      this.isRunning = true;
      this._setButtonState('saveMsProvisioning', true, false, true);
      this._setButtonState('deleteMsProvisioning', true, false, true);
      this._setButtonState('runNowMsProvisioning', true, true, false);
      this._setButtonState('cancelMsProvisioning', true, false, true);

      this._msZeroTouchProvisioningContext.runJob(this.jobId).subscribe({
        error: (e) => {
          console.warn(e);
        },
      });
    }
  }

  private _onDeleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        promptBody: this._translateService.instant('tkey;pages.zero_touch.modal_delete.body'),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onConfirmDelete(this.jobId),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _onConfirmDelete(jobId: number): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._msZeroTouchProvisioningContext.delete(jobId).subscribe(() => {
        this.smacsFormStateService.setIsFormDirty(false);
        this._toastService.pushDeleteToast('tkey;shared.toast.delete.success.title', this.job.name);
        this._router.navigate(['../../'], { relativeTo: this._route });
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private _toEntityData(): MicrosoftProvisioningJob {
    let scheduleObj: JobSchedule = null;
    let audioConfNumberObj: ZeroTouchAudioConference = null;
    if (this.sectionToggleState.isScheduleEnabled) {
      scheduleObj = {
        dailyJobTime: this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.DAILY ? this.formData.dailyJobTime : '',
        periodicJobMinutes:
          this.formData.scheduleFrequency === SCHEDULE_FREQUENCY.PERIODICALLY ? this.formData.periodicJobMinutes : 0,
      };
    }
    if (this.sectionToggleState.isAudioConfEnabled) {
      audioConfNumberObj = {
        audioConferenceNumber: this.formData.audioConfNumber,
      };
    }
    return {
      name: this.formData.name,
      options: {
        audioConferenceNumber: audioConfNumberObj,
        teamsCalling: {
          dialPlanGroupIds: this.formData.dialPlanGroups,
          teamsVoicePolicies: null,
        },
      } as MicrosoftProvisioningJobOptions,
      schedule: scheduleObj,
      searchFilter: this.formData.searchFilter,
    } as MicrosoftProvisioningJob;
  }

  private _submitForm() {
    this.isSaving = true;
    this._setButtonState('runNowMsProvisioning', true, false, false);
    this._setButtonState('saveMsProvisioning', true, true, false);
    this._setButtonState('deleteMsProvisioning', true, false, false);
    this._setButtonState('cancelMsProvisioning', true, false, false);
    this._validateAndSubmitSource.next(true);
  }

  private _setButtonState(buttonId: string, disabled: boolean, pending: boolean, tooltipVisible: boolean) {
    const toolTipKey = this.isRunning
      ? 'tkey;pages.zero_touch.job.run_now.tooltip'
      : 'tkey;pages.zero_touch.job.run_now.tooltip.disabled';
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: {
          pending: pending,
          buttonDisableState: { disabled: disabled, tooltipKey: toolTipKey },
          tooltipVisible: tooltipVisible,
        },
      })
    );
  }

  private _setBottomNavValidationError(hasError: boolean) {
    if (hasError) {
      this._resetBottomNavButtonsState();
    }
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: hasError,
      })
    );
  }

  private _getScheduleFrequency(job: MicrosoftProvisioningJob): SCHEDULE_FREQUENCY {
    if (job.schedule.dailyJobTime) {
      return SCHEDULE_FREQUENCY.DAILY;
    } else if (job.schedule.periodicJobMinutes) {
      return SCHEDULE_FREQUENCY.PERIODICALLY;
    }
    return null;
  }

  private _resetBottomNavButtonsState() {
    this._setButtonState('runNowMsProvisioning', true, false, false);
    this._setButtonState('saveMsProvisioning', false, false, false);
    this._setButtonState('cancelMsProvisioning', false, false, false);
    if (this.jobId) {
      this._setButtonState('deleteMsProvisioning', false, false, false);
    }
  }

  private _setRunTimeMessage(periodicJobMinutes: number) {
    this.nextRunTimesMessage = this._zeroTouchSchedulingService.getNextRunTimeMessage(periodicJobMinutes.toString());
  }

  private _pollForStatus() {
    if (this.jobId) {
      this._microsoftProvisioningStatusPollingService.setBaseUrl(this.jobId.toString());
      this._microsoftProvisioningStatusPollingService.startPolling();
      this._microsoftProvisioningStatusPollingService.state$.subscribe((status: JobStatus) => {
        if ((status.jobState === JobState.RUNNING || status.jobState === JobState.QUEUED) && !this.isSaving) {
          this.isRunning = true;
          this._setButtonState('saveMsProvisioning', true, false, true);
          this._setButtonState('runNowMsProvisioning', true, true, false);
          this._setButtonState('cancelMsProvisioning', true, false, true);
        } else if (!this.isSaving) {
          this._setButtonState('saveMsProvisioning', false, false, false);
          this._setButtonState('runNowMsProvisioning', !!this.isFormDirty, false, !!this.isFormDirty);
          this._setButtonState('cancelMsProvisioning', false, false, false);

          if (this.isRunning) {
            this.isRunning = false;
            this._toastService.push(
              ToastTypes.SUCCESS,
              this.smacsIcons.AUTOMATION,
              'tkey;pages.zero_touch.job.completed',
              this.job.name
            );
          }
        }
      });
    }
  }
}
