import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { forkJoin, Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import {
  MicrosoftDialPlanRange,
  NumberPortabilityResponse,
  PortType,
} from '../../../shared/models/generated/smacsModels';
import { chunk, cloneDeep, isEqual, range, uniq } from 'lodash';
import { catchError, map } from 'rxjs/operators';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { GroupedRows, NumberPortabilitiesContext } from './number-portabilities.context';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsFormsUpdate } from '../../../forms/smacs-forms-models';
import { ActivatedRoute, Router } from '@angular/router';
import { PortInDraftsResource } from '../port-in-orders/port-in-drafts.resource';
import { HttpErrorResponse } from '@angular/common/http';
import {
  CheckNumberPortabilityComponentPayload,
  CheckNumberPortabilityFormComponent,
} from './check-number-portability-form.component';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { ZiroPromptModalViewProperties } from '../../../modals/prompt-modal/prompt-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';

export interface PortabilityRow {
  losingCarrier: string;
  losingCarrierSupported: boolean;
  csrRetrievalSupported: boolean;
  rateCenter: string;
  rateCenterSupported: boolean;
  portType: PortType;
  numbers: string[];
}

@Component({
  selector: 'ziro-check-number-portability',
  templateUrl: './check-number-portability.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class CheckNumberPortabilityComponent implements OnInit {
  @ViewChild(CheckNumberPortabilityFormComponent) formComponent: CheckNumberPortabilityFormComponent;

  existingPortInDraftNumbers: string[];
  isLoading = true;
  formEntity: CheckNumberPortabilityComponentPayload = {
    enterByType: 'Text',
    bulkNumbers: '',
    dialPlanRangesJson: [{ start: '', end: '' }],
  };
  private numbersToCheck: string[];
  private subscriptions = new Subscription();

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private bottomNavService: BottomNavService,
    private smacsFormStateService: SmacsFormStateService,
    private numberPortabilitiesContext: NumberPortabilitiesContext,
    private toastService: ToastService,
    private router: Router,
    private route: ActivatedRoute,
    private portInDraftResource: PortInDraftsResource,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private telephoneNumberFilter: TelephoneNumberFilter
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;admin.order_numbers.port_in_orders.title',
        url: `/admin/order-numbers/port-in-orders`,
        routerLink: true,
      },
      { label: 'tkey;admin.order_numbers.check_number_portability.title' },
    ]);
    this.initBottomNav();
    const portInSub = this.portInDraftResource.get().subscribe((draftRefs) => {
      this.existingPortInDraftNumbers = draftRefs.flatMap((ref) => ref.numbers);

      this.isLoading = false;
    });
    this.subscriptions.add(portInSub);
  }

  onFormUpdate(data: SmacsFormsUpdate<CheckNumberPortabilityComponentPayload>) {
    if (!isEqual(data.new, data.old)) {
      this.formEntity = data.new;
    }
  }

  private initBottomNav() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'check-number-portability-button',
          dataAutomation: 'check-number-portability-button',
          label: 'tkey;admin.order_numbers.check_number_portability.submit.label',
          buttonClass: ButtonStyles.PRIMARY,
          state: {
            pending: false,
            buttonDisableState: {
              disabled: false,
              tooltipKey: '',
            },
          },
          icon: SmacsIcons.NEXT,
          iconAppended: true,
          cb: () => this.checkNumberPortabilities(),
        },
      ])
    );
  }

  private checkNumberPortabilities() {
    this.bottomNavService.setBottomNavValidationError(false);
    this.bottomNavService.setButtonPendingState('check-number-portability-button', true);
    this.areChildFormsValid()
      .pipe(
        switchMap((valid) => {
          if (valid) {
            const ranges =
              this.formEntity.enterByType === 'Text'
                ? this.formComponent.generateBulkRanges()
                : this.formEntity.dialPlanRangesJson;

            const allNumbers = ranges
              .map((microsoftDialPlanRange: MicrosoftDialPlanRange) => {
                const numbers = range(
                  parseInt(microsoftDialPlanRange.start),
                  parseInt(microsoftDialPlanRange.end) + 1,
                  1
                );

                return numbers.map((number) => {
                  return number.toString().startsWith('1') ? `+${number}` : `+1${number}`;
                });
              })
              .flat(1);
            this.numbersToCheck = uniq(allNumbers);

            return this._sendNumberPortabilityRequest();
          } else {
            this.bottomNavService.setBottomNavValidationError(true);
            this.bottomNavService.setButtonPendingState('check-number-portability-button', false);
            return of(null);
          }
        })
      )
      .subscribe();
  }

  private _sendNumberPortabilityRequest(): Observable<void> {
    return this.numberPortabilitiesContext
      .checkPortabilities({
        phoneNumbersToPort: this.numbersToCheck,
      })
      .pipe(
        switchMap((response: NumberPortabilityResponse | HttpErrorResponse) => {
          if ('error' in response) {
            return;
          }

          if (response.portType !== PortType.MIXED) {
            return of([response]);
          }

          /**
           * If numbers return MIXED group the number by their losingCarrier, and retry the requests
           */
          const groupedRows = new Map<string, string[]>();
          response.numberPortabilities.forEach((numberPortability) => {
            const key = numberPortability.losingCarrierName;
            if (groupedRows.has(key)) {
              groupedRows.get(key).push(numberPortability.number);
            } else {
              groupedRows.set(key, [numberPortability.number]);
            }
          });

          // if there is only one losing carrier, just return this response, we won't be able to do any better.
          if (groupedRows.size === 1) {
            return of([response]);
          }

          const responses = Array.from(groupedRows.values()).map((numbers) =>
            this.numberPortabilitiesContext.checkPortabilities({ phoneNumbersToPort: numbers })
          );
          return forkJoin(responses);
        }),
        map((numberPortabilities) => {
          const groupedRows: GroupedRows = {};

          numberPortabilities.forEach((portabilityResponse) => {
            portabilityResponse.numberPortabilities.forEach((numberPortability) => {
              // If the rate center is supported, group it only by the losing carrier name.
              // Otherwise, group it by both the losing carrier name and the rate center name.
              if (numberPortability.rateCenterSupported) {
                const portabilityRow = groupedRows[numberPortability.losingCarrierName];

                if (!!portabilityRow) {
                  groupedRows[numberPortability.losingCarrierName] = {
                    ...portabilityRow,
                    numbers: [...portabilityRow.numbers, numberPortability.number],
                    rateCenter: portabilityRow.rateCenter.includes(numberPortability.rateCenterName)
                      ? portabilityRow.rateCenter
                      : `${portabilityRow.rateCenter}, ${numberPortability.rateCenterName}`,
                  };
                } else {
                  groupedRows[numberPortability.losingCarrierName] = {
                    portType: portabilityResponse.portType,
                    losingCarrier: numberPortability.losingCarrierName,
                    losingCarrierSupported: numberPortability.losingCarrierSupported,
                    csrRetrievalSupported: numberPortability.csrRetrievalSupported,
                    rateCenter: numberPortability.rateCenterName,
                    rateCenterSupported: numberPortability.rateCenterSupported,
                    numbers: [numberPortability.number],
                  };
                }
              } else {
                const portabilityRow =
                  groupedRows[numberPortability.losingCarrierName + ' ' + numberPortability.rateCenterName];

                if (!!portabilityRow) {
                  groupedRows[numberPortability.losingCarrierName + ' ' + numberPortability.rateCenterName] = {
                    ...portabilityRow,
                    numbers: [...portabilityRow.numbers, numberPortability.number],
                  };
                } else {
                  groupedRows[numberPortability.losingCarrierName + ' ' + numberPortability.rateCenterName] = {
                    portType: portabilityResponse.portType,
                    losingCarrier: numberPortability.losingCarrierName,
                    losingCarrierSupported: numberPortability.losingCarrierSupported,
                    csrRetrievalSupported: false,
                    rateCenter: numberPortability.rateCenterName,
                    rateCenterSupported: numberPortability.rateCenterSupported,
                    numbers: [numberPortability.number],
                  };
                }
              }
            });
          });

          this.numberPortabilitiesContext.groupedRowsStateSource.next(cloneDeep(groupedRows));
          this.setCompletedState();
        }),
        catchError((error) => {
          this.bottomNavService.setButtonPendingState('check-number-portability-button', false);
          if (error?.error?.reasonCode === 'NUMBERS_ALREADY_PORTING') {
            const badPhoneNumbers = error.error.description.split('[')[1].split(']')[0].split(', ');
            this.numbersToCheck = this.numbersToCheck.filter((number) => !badPhoneNumbers.includes(number));
            if (this.numbersToCheck.length) {
              this._openModalRecheckPortabilityWithoutErroneousNumbers(badPhoneNumbers);
              return of(null);
            }
          }
          return throwError(() => error);
        })
      );
  }

  private areChildFormsValid(): Observable<boolean> {
    if (!!this.formComponent.extensionRangesDisplayForm) {
      this.formComponent.extensionRangesDisplayForm._validateAndSubmitSource.next(true);
      this.formComponent.extensionRangesDisplayForm.validateAllFields();

      return this.smacsFormStateService
        .checkForPendingFields({
          fieldComponents: this.formComponent.extensionRangesDisplayForm.fieldComponents,
          validationStates: { singleValidation: this.formComponent?.extensionRangesDisplayFormValidationState },
        })
        .pipe(
          switchMap((asyncValid) => {
            const syncValid = this.formComponent.extensionRangesDisplayForm.isFormValid();
            return of(asyncValid && syncValid);
          })
        );
    } else {
      this.formComponent._validateAndSubmitSource.next(true);
      this.formComponent.validateAllFields();
      return of(this.formComponent.isFormValid());
    }
  }

  private setCompletedState() {
    this.bottomNavService.setButtonPendingState('check-number-portability-button', false);
    this.toastService.push(
      ToastTypes.SUCCESS,
      SmacsIcons.OK,
      'tkey;admin.order_numbers.check_number_portability.toast.success',
      'tkey;admin.order_numbers.check_number_portability.submit.label'
    );
    this.router.navigate(['..', 'create-drafts'], { relativeTo: this.route });
  }

  private _openModalRecheckPortabilityWithoutErroneousNumbers(phoneNumbers: string[]) {
    const formattedPhoneNumbers = phoneNumbers.map((number) => this.telephoneNumberFilter.transform(number));
    const rowedPhoneNumbers = chunk(formattedPhoneNumbers, 3)
      .map((row) => row.join(', '))
      .join(',<br>');

    const options: ZiroPromptModalViewProperties = {
      promptBody: this.translateService.instant(
        'tkey;admin.order_numbers.check_number_portability.error.numbers_already_porting.message',
        {
          phoneNumbers: rowedPhoneNumbers,
        }
      ),
      title: 'tkey;admin.order_numbers.check_number_portability.error.numbers_already_porting.title',
      icon: SmacsIcons.WARNING,
      iconClass: 'text-warning',
      displayCloseButton: true,
      buttons: [
        {
          label: 'tkey;global.button.cancel.text',
          buttonClass: ButtonStyles.DEFAULT,
          dataAutomation: 'confirmation-modal-cancel-button',
        },
        {
          label: 'tkey;admin.order_numbers.check_number_portability.error.numbers_already_porting.continue',
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'confirmation-modal-confirm-button',
          cb: () => {
            return this._sendNumberPortabilityRequest();
          },
        },
      ],
    };
    this.smacsModalService.openPromptModal(() => options, { modalViewProperties: options });
  }
}
