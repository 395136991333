<div class="smacs-admin-page-content" data-automation="smacs-high-availability-settings">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1 pb-2" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;pages.admin.high.availability.management.description' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div *ngFor="let config of formConfig.fields | keyvalue : returnZero; let i = index" class="list-group">
      <div class="list-group-item position-relative">
        <div *ngIf="isCheckBox(config.value)">
          <smacs-general-settings-checkbox [fieldId]="config.key"></smacs-general-settings-checkbox>
          <p
            [innerHTML]="
              'tkey;pages.admin.high.availability.management.enable.high.availability.content'
                | translate : { hours: formData.syncIntervalInHours }
            "
          ></p>
        </div>

        <div
          *ngIf="isTextInput(config.value)"
          class="align-items-center d-flex group-item-text-wrapper justify-content-between"
        >
          <div
            class="col-12 global-config-text-container"
            [attr.data-automation]="config.value.dataAutomation + '-field'"
          >
            <smacs-general-settings-text [fieldId]="config.key"></smacs-general-settings-text>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
