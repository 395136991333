import { Directive, OnInit } from '@angular/core';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../shared/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { ButtonStyles } from '../../../button/button.component';
import { JobSchedule, MicrosoftProvisioningJobOptions } from '../../../shared/models/generated/smacsModels';
import { MsZeroTouchProvisioningContext } from '../../../admin/contexts/ms-zero-touch-provisioning.context';

export interface MicrosoftZeroTouchJobView {
  id: number;
  name: string;
  searchFilter: string;
  schedule: JobSchedule;
  options: MicrosoftProvisioningJobOptions;
  pending?: boolean;
}

@Directive({
  selector: '[smacsZpmJobsViewAbstract]',
})
export abstract class ZpmJobsViewAbstractDirective implements OnInit {
  isLoading = true;
  pageLabel: string;
  smacsIcons = SmacsIcons;
  bottomNavButtons = [
    {
      id: 'zeroTouchAddJob',
      dataAutomation: 'admin-zero-touch-add-job-button',
      label: 'tkey;pages.zero_touch.add_job',
      buttonClass: ButtonStyles.PRIMARY,
      icon: this.smacsIcons.ADD,
      state: {
        pending: false,
        buttonDisableState: { disabled: false, tooltipKey: '' },
      },
      cb: () => this.editJob(),
    },
  ];

  protected getAllJobs: () => Observable<MicrosoftZeroTouchJobView[]>;
  private _latestJobsSource = new ReplaySubject<MicrosoftZeroTouchJobView[]>(1);
  protected latestJobs$ = this._latestJobsSource.asObservable();

  protected constructor(
    protected provisioningContext: MsZeroTouchProvisioningContext,
    protected breadcrumbsService: BreadcrumbsService,
    protected bottomNavService: BottomNavService,
    protected toastService: ToastService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected translateService: TranslateService,
    protected smacsModalService: SmacsModalService
  ) {}

  ngOnInit() {
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: this.pageLabel,
      },
    ]);
  }

  editJob(job?: MicrosoftZeroTouchJobView) {
    const routePath = ['edit-job'];
    if (job) {
      routePath.push(job.id + '');
    }
    this.router.navigate(routePath, { relativeTo: this.route });
  }

  deleteJob(job: MicrosoftZeroTouchJobView) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        promptBody: this.translateService.instant('tkey;pages.zero_touch.modal_delete.body'),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this.onConfirmDelete(job),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  refreshJobs(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.getAllJobs().subscribe((jobsList) => {
        this._latestJobsSource.next(jobsList);
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }

  onConfirmDelete(job: MicrosoftZeroTouchJobView): Observable<void> {
    this.isLoading = true;
    return new Observable((subscriber: Subscriber<void>) => {
      this.provisioningContext.delete(job.id).subscribe(() => {
        this.refreshJobs().subscribe(() => {
          this.isLoading = false;
          this.toastService.pushDeleteToast('tkey;shared.toast.delete.success.title', job.name);
          subscriber.next(null);
          subscriber.complete();
        });
      });
    });
  }
}
