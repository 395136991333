import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { difference } from 'lodash';
import { ButtonSizes, ButtonStyles } from '../../button/button.component';
import { CustomModal } from '../../shared/services/smacs-modal.service';
import { ConnectedListChangeEvent, ConnectedListComponent } from './connected-list/connected-list.component';
import { Observable } from 'rxjs';

export interface EditCustomSelectModalTranslations {
  description?: string;
  available?: string;
  show?: string;
  default?: string;
}

interface ViewProps {
  configuredOptions: string[];
  availableOptions: string[];
  defaultOptions: string[];
  modalHeaderKey: string;
  isMultiSelect: boolean;
  isPreviewVisible: boolean;
  translations: EditCustomSelectModalTranslations;
  postLoadOptionsFn?: () => Observable<string[]>;
}

@Component({
  selector: 'app-edit-custom-select-modal',
  templateUrl: './edit-custom-select-modal.component.html',
})
export class EditCustomSelectModalComponent implements CustomModal<ViewProps>, OnInit {
  @ViewChild(ConnectedListComponent) connectedListComponent: ConnectedListComponent;

  modalProperties: ViewProps;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  availableOptions: string[];
  configuredOptions: string[];
  defaultOptions: string[];
  previewOptions: string[];
  selectedDefault: string | string[];
  isLoadingOptions = false;

  constructor(private activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.configuredOptions = this.modalProperties.configuredOptions;
    this.availableOptions = difference(this.modalProperties.availableOptions, [
      ...this.modalProperties.configuredOptions,
      ...this.modalProperties.defaultOptions,
    ]);
    this.defaultOptions = this.modalProperties.defaultOptions;
    this.previewOptions = [...this.modalProperties.defaultOptions, ...this.modalProperties.configuredOptions];
    this.selectedDefault = this.modalProperties.isMultiSelect
      ? [...this.modalProperties.defaultOptions]
      : [...this.modalProperties.defaultOptions][0];

    if (this.modalProperties.postLoadOptionsFn && this.modalProperties.postLoadOptionsFn()) {
      this.isLoadingOptions = true;
      this.modalProperties.postLoadOptionsFn().subscribe((options: string[]) => {
        const newOptions = difference(
          [...options],
          [
            ...this.modalProperties.availableOptions,
            ...this.modalProperties.configuredOptions,
            ...this.modalProperties.defaultOptions,
          ]
        );

        if (newOptions.length) {
          this.availableOptions = [...this.availableOptions, ...newOptions];
          setTimeout(() => {
            this.connectedListComponent._filterAvailableOptions();
          });
        }
        this.isLoadingOptions = false;
      });
    }
  }

  onConfirmClick() {
    this.activeModal.close({
      defaultOptions: this.defaultOptions,
      configuredOptions: this.configuredOptions,
    });
  }

  onCancelClick() {
    this.activeModal.close(null);
  }

  onChange(data: ConnectedListChangeEvent) {
    this.availableOptions = data['availableOptions'];
    this.configuredOptions = data['configuredOptions'];
    this.defaultOptions = data['defaultOptions'];
    this.previewOptions = [...data['defaultOptions'], ...data['configuredOptions']];
    this.selectedDefault = this.modalProperties.isMultiSelect
      ? [...data['defaultOptions']]
      : [...data['defaultOptions']][0];
  }

  onDefaultSelectChange() {
    this.selectedDefault = this.modalProperties.isMultiSelect ? [...this.defaultOptions] : [...this.defaultOptions][0];
  }
}
