<div class="extension-ranges-list">
  <cdk-virtual-scroll-viewport itemSize="33" class="virtual-scroll-viewport" minBufferPx="400" maxBufferPx="1000">
    <div
      *cdkVirtualFor="let field of safeEntity; let i = index"
      class="extension-ranges-list__item"
      [attr.data-automation]="'extension-ranges-list-item-' + i"
    >
      <smacs-single-extension-range-container
        class="d-flex flex-fill"
        [entity]="field"
        [index]="i"
        [externalValidation]="fieldIds[i].externalValidationState"
        [ranges]="entity"
        [isDisabled]="isDisabled"
        [isFormSubmitted]="isFormSubmitted"
        (singleExtensionRangeChange)="onSingleRangeUpdate($event)"
      ></smacs-single-extension-range-container>
      <div class="extension-ranges-list__item__delete" *ngIf="!isModifiedExtensionRangeForm">
        <smacs-button
          [cssClass]="'extension-ranges-list__item__delete__button'"
          [dataAutomation]="'extension-range-delete-button'"
          (clicked)="deleteItem(i)"
          [buttonStyle]="buttonStyles.DANGER"
          [icon]="smacsIcons.DELETE"
          [isDisabled]="isDisabled"
        ></smacs-button>
      </div>
      <div class="modified-extension-ranges-buttons" *ngIf="isModifiedExtensionRangeForm">
        <smacs-button
          *ngIf="entity?.length > 1"
          [dataAutomation]="'extension-range-delete-button'"
          [buttonStyle]="buttonStyles.OUTLINE_SECONDARY"
          [buttonSize]="buttonSize.SMALL"
          [cssClass]="'circle-button'"
          (clicked)="deleteItem(i)"
          [icon]="smacsIcons.MINUS"
        ></smacs-button>
        <smacs-button
          *ngIf="!entity?.length || i === entity.length - 1"
          [dataAutomation]="'extension-range-add-button'"
          [buttonStyle]="buttonStyles.OUTLINE_SECONDARY"
          [buttonSize]="buttonSize.SMALL"
          [cssClass]="'circle-button'"
          (clicked)="addItem(true)"
          [isDisabled]="validationState === ValidationState.INVALID"
          [icon]="smacsIcons.PLUS"
        ></smacs-button>
      </div>
    </div>
  </cdk-virtual-scroll-viewport>
</div>

<div class="extension-ranges-add-button" *ngIf="!isModifiedExtensionRangeForm">
  <smacs-button
    [dataAutomation]="'extension-ranges-add-button'"
    (clicked)="addItem(true)"
    [buttonStyle]="buttonStyles.INFO"
    [isDisabled]="validationState === ValidationState.INVALID || isDisabled"
    [icon]="smacsIcons.PLUS"
    [buttonSize]="buttonSize.SMALL"
    [label]="
      !isZpm ? 'tkey;dialplanmanagement.admin.group.range.new' : 'tkey;admin.ms_dial_plan_management.group.range.new'
    "
  ></smacs-button>
</div>
