<div class="change-site">
  <div class="card card-well top-buffer animated fadeIn faster">
    <div class="card-header">
      <legend class="bottom-buffer mt-0 pb-2">
        <span class="fa-stack fa-lg text-info">
          <i class="icon-circle fa-stack-2x"></i>
          <i [attr.class]="smacsIcons.SITE + ' me-1 fa-stack-1x fa-inverse'"></i>
        </span>

        <span>{{ 'tkey;operations.menu.change_site.text' | translate }}</span>
      </legend>

      <app-loading-block [hidden]="!isLoading"></app-loading-block>

      <ziro-change-site-form
        *ngIf="!isLoading"
        [entity]="entity"
        [cluster]="cluster"
        [initialSite]="initialSite"
        [siteSummary]="siteSummary"
        [sites]="sites"
      ></ziro-change-site-form>
    </div>
  </div>
</div>
