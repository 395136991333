<app-breadcrumbs>
  <app-breadcrumb-card-item [aboutCardId]="58"></app-breadcrumb-card-item>
</app-breadcrumbs>

<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div class="pb-2" *ngIf="!isLoading">
  <microsoft-dial-plan-group-management-form
    [entity]="dialPlanGroup"
    [allMsDialplanGroups]="allDialPlanGroups"
    [msCachedOptions]="microsoftCachedOptions"
    [bandwidthCachedOptions]="bandwidthCachedOptions"
    [usageLocations]="usageLocations"
    [isUserS8Support]="isUserS8Support"
    [isBandwidthConfigured]="isBandwidthConfigured"
    [isGccHigh]="isGccHigh"
    [onPremAdWriteDownEnabled]="onPremAdWriteDownEnabled"
    (smacsFormsUpdate$)="onFormUpdate($event)"
  ></microsoft-dial-plan-group-management-form>
</div>
