import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HighAvailabilitySettings } from '../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';

@Injectable()
export class HighAvailabilityResource {
  smacsIcons = SmacsIcons;

  private _baseUrl = `/services/cisco/admin/high-availability`;

  constructor(private $http: HttpClient) {}

  getHaSettings(): Observable<HighAvailabilitySettings> {
    return this.$http.get<HighAvailabilitySettings>(this._baseUrl + `/settings`);
  }

  putHaSettings(settings: HighAvailabilitySettings): Observable<void> {
    return this.$http.put<void>(this._baseUrl + `/settings`, settings);
  }

  postReplication(requestUrl: string): Observable<void> {
    return this.$http.post<void>(this._baseUrl + `/replication`, { url: requestUrl });
  }

  postHaValidation(requestUrl: string): Observable<void> {
    return this.$http.post<void>(this._baseUrl + '/validation', { url: requestUrl });
  }
}
