import { PhoneModelUI } from '../phone-buttons/phone-buttons-mapping';
import {
  BlfButton,
  Button as JsonButton,
  CallParkBlfButton,
  IntercomButton,
  LineButton,
  LineFeatureFieldConfig,
  ServiceUrlButton,
  SpeedDialButton,
} from './generated/smacsModels';
import { LineButtonPeripheralSummary } from '../phone-buttons/layout/phone-buttons-standard.component';

export enum PhoneButtonTypes {
  line = 'Line',
  speed_dial = 'Speed Dial',
  blf = 'Speed Dial BLF',
  intercom = 'Intercom',
  service_url = 'Service URL',
  call_park_blf = 'Call Park BLF',
}

export type PhoneButton =
  | LineButton
  | SpeedDialButton
  | BlfButton
  | IntercomButton
  | ServiceUrlButton
  | JsonButton
  | CallParkBlfButton;

export class ButtonState {
  disabled = false;
  unmovable = false;
  candidate = false;
  dragTarget = false;
  dropTarget = false;
  justSwapped = false;
  deleteToggled = false;
  uneditable = false;
  fixed = false;
  selected = false;
  invalid = false;
}

export enum ButtonGroups {
  STANDARD,
  EXPANSION,
}

export interface ExpansionModule {
  expansionUI: PhoneModelUI;
  buttons: IndexedPhoneButton[];
}
export interface IndexedPhoneButton {
  button: PhoneButton;
  index: number;
  lineFeatureConfig?: LineFeatureFieldConfig;
  linePeripheralSummary?: LineButtonPeripheralSummary;
}
