<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div data-automation="send-email-detailed-modal" *ngIf="!isLoading">
  <div class="row">
    <label class="col-form-label col-3 text-lg-end">
      {{ 'tkey;helpdesk.send_email.modal.from.label' | translate }}
    </label>
    <span class="col-9 col-form-label opacity-50" data-automation="send-email-modal-from">{{ emailSenderName }}</span>
  </div>
  <div class="row">
    <label class="col-form-label col-3 text-lg-end">{{ 'tkey;helpdesk.send_email.modal.to.label' | translate }}</label>
    <span class="col-9 col-form-label opacity-50" data-automation="send-email-modal-to">{{ emailRecipients }}</span>
  </div>
  <div class="row">
    <label class="col-form-label col-3 text-lg-end">
      {{ 'tkey;helpdesk.send_email.modal.subject.label' | translate }}
    </label>
    <span
      class="col-9 col-form-label opacity-50"
      data-automation="send-email-modal-subject"
      [innerHTML]="emailSubject"
    ></span>
  </div>
  <div class="row">
    <label class="col-form-label col-3 text-lg-end">
      {{ 'tkey;helpdesk.send_email.modal.body.label' | translate }}
    </label>
    <span
      class="col-9 col-form-label opacity-50"
      data-automation="send-email-modal-body"
      [innerHTML]="emailBody"
    ></span>
  </div>
</div>
