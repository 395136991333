import { Injectable } from '@angular/core';
import { SiteSummaryContext } from './site-summary.context';
import { LocalStorageContext, UserLocalStorageSettings } from './local-storage.context';
import { AuthenticationContext } from './authentication.context';
import { take } from 'rxjs/operators';
import { ClusterResult, CurrentUser, Role, SiteResult, SiteSummary } from '../models/generated/smacsModels';
import { combineLatest, ReplaySubject } from 'rxjs';

@Injectable()
export class CurrentClusterContext {
  private _stateSource = new ReplaySubject<ClusterResult>(1);
  state$ = this._stateSource.asObservable();

  private _siteSummary: SiteSummary;
  private _localStorageSetting: UserLocalStorageSettings;

  constructor(
    private siteSummaryContext: SiteSummaryContext,
    private localStorageContext: LocalStorageContext,
    private authenticationContext: AuthenticationContext
  ) {
    combineLatest([this.siteSummaryContext.state$, this.localStorageContext.state$]).subscribe(
      ([siteSummary, localStorageSetting]) => {
        this._localStorageSetting = localStorageSetting;
        if (this._siteSummary !== siteSummary) {
          this._siteSummary = siteSummary;
          this.load();
        }
      }
    );
  }

  getCurrentClusterServerIds(currentCluster: ClusterResult): number[] {
    const serverIds = [currentCluster.cucmServerId];
    if (currentCluster.pcceServerId) {
      serverIds.push(currentCluster.pcceServerId);
    }
    serverIds.push(...currentCluster.sites.map((site) => site.unityServerId));
    return serverIds;
  }

  getCurrentClusterUnityServerIdBySiteId(currentCluster: ClusterResult, siteId: number): number {
    return currentCluster.sites.find((site) => site.id === siteId).unityServerId;
  }

  setCurrentClusterFromSite(siteId: number) {
    const clusterId = this.siteSummaryContext.getClusterIdForSiteId(this._siteSummary, siteId);
    if (this.authenticationContext.getCurrentUser()) {
      this._setSessionCluster(this.authenticationContext.getCurrentUser(), clusterId);
    }
  }

  setCurrentClusterFromCucmServer(serverId: number) {
    const cluster = this._siteSummary.clusters.find((c) => c.cucmServerId === serverId);
    this._setSessionCluster(this.authenticationContext.getCurrentUser(), cluster.id);
  }

  getAllowedSitesOnClusters(clusters: ClusterResult[]): SiteResult[] {
    const sites = [];

    for (let i = 0; i < clusters.length; i++) {
      const cluster = clusters[i];
      for (let j = 0; j < cluster.sites.length; j++) {
        const site = cluster.sites[j];
        if (site.hasPermission) {
          sites.push(site);
        }
      }
    }

    return sites;
  }

  /**
   * Filter out any cluster that the currently authenticated user does not have access to
   */
  getAllowedSitesOnClustersForUser(clusters: ClusterResult[], endUserServerIds: number[]): SiteResult[] {
    const sites = [];

    for (let i = 0; i < clusters.length; i++) {
      const cluster = clusters[i];

      if (endUserServerIds.includes(cluster.cucmServerId)) {
        for (let j = 0; j < cluster.sites.length; j++) {
          const site = cluster.sites[j];
          if (site.hasPermission) {
            sites.push(site);
          }
        }
      }
    }

    return sites;
  }

  private _setSessionClusterId = (clusterId: number) => {
    const currentCluster = this._siteSummary.clusters.find((cluster: ClusterResult) => cluster.id === clusterId);
    this._stateSource.next(currentCluster);

    if (Number(this._localStorageSetting.currentClusterId) !== clusterId) {
      this._localStorageSetting.currentClusterId = String(clusterId);
      this.localStorageContext.updateUserSettings(this._localStorageSetting);
      console.info('Setting current cluster to ' + currentCluster.name);
    }
  };

  private load = () => {
    this.authenticationContext.state$.pipe(take(1)).subscribe((user) => this._setSessionCluster(user));
  };

  private _setSessionCluster(user: CurrentUser, clusterId?: number) {
    if (!user.role) {
      return;
    }

    const currentClusterId = clusterId || Number(this._localStorageSetting.currentClusterId);
    const clusterExists = currentClusterId && this.configContainsCluster(currentClusterId);
    const userHasPermissionToSeeCluster =
      clusterExists && this.determineIfUserHasPermissionForCluster(currentClusterId);

    if (clusterExists && userHasPermissionToSeeCluster) {
      this._setSessionClusterId(currentClusterId);
    } else if (user.role !== Role.S8_SELF_SERVE_USER) {
      const allowedClusters = this.siteSummaryContext.getAllowedClusters(this._siteSummary);
      if (allowedClusters && allowedClusters.length) {
        this._setSessionClusterId(allowedClusters[0].id);
      } else {
        console.warn('No clusters found on database.');
        const emptyCluster: ClusterResult = {
          cucmServerDescription: '',
          cucmServerId: 0,
          id: -1,
          impServerDescription: '',
          impServerId: 0,
          name: '',
          pcceServerDescription: '',
          pcceServerId: 0,
          sites: [],
        };
        this._stateSource.next(emptyCluster);
      }
    }
  }

  private configContainsCluster = (currentClusterId: number) => {
    return this._siteSummary.clusters.filter((cluster) => cluster.id === currentClusterId).length === 1;
  };

  private determineIfUserHasPermissionForCluster = (clusterId: number) => {
    const clusters = this._siteSummary.clusters.filter((cluster: ClusterResult) => cluster.id === clusterId);
    return this.getAllowedSitesOnClusters(clusters).length > 0;
  };
}
