<div
  class="mb-3 row form-group"
  [hidden]="(!state.hasOwnProperty('hidden') || state.hidden) && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted || misconfigurationFeedbackMessage,
    'smacs-forms-error': (showValidation || isFormSubmitted) && validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING ||
      validationState === ValStates.WARNING ||
      (misconfigurationFeedbackMessage && state.hidden),
  }"
  [ngbTooltip]="tooltipContent"
  [disableTooltip]="!getDisabledState()"
  [container]="'body'"
>
  <label
    *ngIf="config.label"
    class="col-lg-3 justify-content-end d-flex pt-1 pe-none text-end"
    [attr.for]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
    data-automation="smacs-text-label"
  >
    <ng-template #tipContent><span [innerHtml]="config.labelToolTipText | translate"></span></ng-template>
    <span *ngIf="entity?.required" class="text-danger smacs-forms-asterisk">*</span>
    <div [ngbTooltip]="tipContent" [disableTooltip]="!config.labelToolTipText" class="fit-content d-inline-block">
      <span [innerHtml]="getLabel() | translate"></span>
      <i *ngIf="config.labelToolTipIconClass" class="{{ config.labelToolTipIconClass }} ms-1"></i>
    </div>
  </label>

  <div class="col-lg-9">
    <div class="input-group">
      <div class="col-md-12 position-relative">
        <div *ngIf="!getDisabledState()" class="input-overlay-wrapper row">
          <button
            type="button"
            data-automation="custom-select-form-input-overlay-button"
            (click)="openModal()"
            class="input-overlay-button"
          ></button>
        </div>
        <input
          class="w-100 input-padding form-control"
          [ngClass]="{
            'border-danger': (showValidation || isFormSubmitted) && validationState === ValStates.INVALID,
            'border-warning':
              validationState === ValStates.WARNING || (misconfigurationFeedbackMessage && state.hidden),
          }"
          [disabled]="getDisabledState()"
          [type]="htmlInputType.TEXT"
          [attr.id]="config.id ? config.id + '-input' : config.dataAutomation + '-input'"
          [(ngModel)]="displayValues"
          [ngModelOptions]="{ standalone: true }"
          data-automation="custom-select-text-input"
        />
      </div>
      <div
        *ngIf="(showValidation && !!validationMessage?.content) || isFormSubmitted"
        class="smacs-forms-validation-message"
        data-automation="smacs-forms-validation-message"
        [ngClass]="{ 'd-inline-block me-1': validationMessage && (showValidation || isFormSubmitted) }"
        [innerHTML]="validationMessage?.content | translate : validationMessage?.params"
      ></div>
      <smacs-button
        class="mt-1"
        [dataAutomation]="'smacs-forms-autogeneration-button'"
        [hidden]="isAutogenerateLinkHidden() || !showAutoGenerationLink"
        (clicked)="handleAutoGeneration()"
        [buttonStyle]="buttonStyles.LINK"
        [buttonSize]="buttonSizes.SMALL"
        [label]="'tkey;shared.html.customizable_fields.text_input.fix_it.label'"
        [labelCssClass]="'small strong'"
      ></smacs-button>
    </div>
  </div>
  <div class="col-lg-9 offset-lg-3 pb-4">
    <div class="select-config-extras">
      <div class="d-flex flex-column select-config-extras--helpless" [ngClass]="{ disabled: getDisabledState() }">
        <div class="pt-3 pb-1">
          <span
            [ngClass]="{
              'text-danger': (showValidation || isFormSubmitted) && validationState === ValStates.INVALID,
              'text-warning':
                validationState === ValStates.WARNING || (misconfigurationFeedbackMessage && state.hidden),
              disabled: getDisabledState()
            }"
            class="text-80"
          >
            {{ 'tkey;custom_select.default_value.label' | translate }}
          </span>
          <smacs-button
            [dataAutomation]="'custom-select-default-value-button'"
            [label]="entity?.defaultOption"
            [buttonStyle]="buttonStyles.LINK"
            [buttonSize]="buttonSizes.SMALL"
            [cssClass]="'text-80'"
            (clicked)="openModal()"
            [isDisabled]="getDisabledState()"
          ></smacs-button>
        </div>
        <div class="d-flex align-items-center">
          <div class="d-flex align-items-center" data-automation="required-checkbox">
            <input
              type="checkbox"
              class="form-check-input mt-0 mb-1 rounded-0"
              [attr.id]="config.dataAutomation + '-required'"
              [(ngModel)]="isRequiredChecked"
              (ngModelChange)="onRequiredChange($event)"
              [disabled]="isAlwaysRequired || getDisabledState()"
              name="requiredCheck"
            />
            <div>
              <label
                class="form-check-label col-form-label text-start pt-1"
                [ngClass]="{ 'not-allowed disabled': isAlwaysRequired }"
                [attr.for]="config.dataAutomation + '-required'"
              >
                <span class="p-0 ms-2" [innerHTML]="requiredLabel | translate"></span>
              </label>
            </div>
          </div>
          <div class="d-flex align-items-center" data-automation="show-checkbox">
            <input
              type="checkbox"
              class="form-check-input mt-0 mb-1 ms-3 rounded-0"
              [attr.id]="config.dataAutomation + '-show'"
              [(ngModel)]="isShowChecked"
              (ngModelChange)="onShowChange($event)"
              name="showCheck"
              [ngModelOptions]="{ standalone: true }"
              [disabled]="getDisabledState()"
            />
            <div>
              <label
                class="form-check-label col-form-label text-start pt-1"
                [attr.for]="config.dataAutomation + '-show'"
              >
                <span class="p-0 ms-2" [innerHTML]="showLabel | translate"></span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #tooltipContent><span [innerHTML]="config.disabledTooltip?.toString() | translate"></span></ng-template>
