<div class="container-fluid">
  <smacs-fieldset-header [legend]="'tkey;pages.details.agentSettings.header'">
    <smacs-select fieldId="resourceGroup"></smacs-select>
    <smacs-select fieldId="team"></smacs-select>
    <smacs-text fieldId="alias"></smacs-text>
    <smacs-checkbox fieldId="automaticAvailable"></smacs-checkbox>
  </smacs-fieldset-header>

  <smacs-fieldset-header [legend]="'tkey;pages.details.agentSettings.skills.header'">
    <smacs-navset data-automation="skills-navset" fieldId="uccxNavset"></smacs-navset>
  </smacs-fieldset-header>
</div>
