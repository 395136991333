import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { JobState, MicrosoftBulkJobStatus } from '../../../../shared/models/generated/smacsModels';
import { Subscription } from 'rxjs';
import { MicrosoftBulkProvisioningPollingContext } from '../../../../shared/contexts/microsoft-bulk-provisioning-polling.context';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../../shared/services/toast.service';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles } from '../../../../button/button.component';
import { MicrosoftBulkProvisioningFormComponent } from '../form/microsoft-bulk-provisioning-form.component';
import { ZpmBulkProvisioningResource } from '../../../resources/zpm-bulk-provisioning.resource';
import { TranslateService } from '@ngx-translate/core';
import { ZpmUserPhotoContext } from '../../../../shared/contexts/zpm-user-photo.context';
import { ZpmBulkProvisioningEntityTableContainerAbstractComponent } from '../status-table/zpm-bulk-provisioning-status-entity-table-abstract.component';
import { Router } from '@angular/router';
import { EntityTableContentRow } from '../../../../shared/entity-table/entity-table.models';

@Component({
  selector: 'smacs-microsoft-bulk-provisioning-in-progress',
  templateUrl: './bulk-provisioning-in-progress.component.html',
  styleUrls: ['../../../../admin/admin-page.scss'],
  providers: [MicrosoftBulkProvisioningPollingContext, ZpmBulkProvisioningResource],
})
export class MicrosoftBulkProvisioningInProgressComponent
  extends ZpmBulkProvisioningEntityTableContainerAbstractComponent
  implements OnInit, OnDestroy
{
  @ViewChild(MicrosoftBulkProvisioningFormComponent) formComponent: MicrosoftBulkProvisioningFormComponent;

  jobState = JobState;
  smacsIcons = SmacsIcons;
  isLoading = true;
  isRunning = false;
  isComplete = false;
  tableRows: EntityTableContentRow[] = [];

  private _subscriptions = new Subscription();

  constructor(
    protected _translateService: TranslateService,
    private _breadcrumbsService: BreadcrumbsService,
    private _microsoftBulkProvisioningPollingContext: MicrosoftBulkProvisioningPollingContext,
    private _toastService: ToastService,
    private _bottomNavService: BottomNavService,
    private _zpmUserPhotoContext: ZpmUserPhotoContext,
    private _router: Router
  ) {
    super(_translateService);
  }

  ngOnInit(): void {
    this._breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;automate.zpm.bulk_provisioning.breadcrumb',
        url: '/automate/microsoft/bulk-provisioning',
        routerLink: true,
      },
    ]);

    this._initBottomNav();
    this._listenToPolling();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
    this._microsoftBulkProvisioningPollingContext.stopPolling();
  }

  getTotalRows(): number {
    return [
      ...this.bulkJobStatus.jobDetailedStatus.completedUsers,
      ...this.bulkJobStatus.jobDetailedStatus.failedUsers,
      ...this.bulkJobStatus.jobDetailedStatus.inProgressUser,
      ...this.bulkJobStatus.jobDetailedStatus.remainingUsers,
    ].length;
  }

  getCompletedRows(): number {
    return [...this.bulkJobStatus.jobDetailedStatus.completedUsers, ...this.bulkJobStatus.jobDetailedStatus.failedUsers]
      .length;
  }

  getPercentComplete(): number {
    return (this.getCompletedRows() / this.getTotalRows()) * 100;
  }

  private _listenToPolling(): void {
    this._microsoftBulkProvisioningPollingContext.state$.subscribe(this._handlePolling);
    this._microsoftBulkProvisioningPollingContext.startPolling();
  }

  private _handlePolling = (microsoftBulkJobStatus: MicrosoftBulkJobStatus) => {
    this.bulkJobStatus = microsoftBulkJobStatus;

    this._breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;automate.zpm.bulk_provisioning.breadcrumb',
        url: '/automate/microsoft/bulk-provisioning',
        routerLink: true,
      },
      {
        label: `${microsoftBulkJobStatus.jobDetailedStatus.jobName} ${this._translateService.instant(
          'tkey;automate.zpm.bulk_provisioning.bulk_job.label'
        )}`,
      },
    ]);

    if (this.isRunning && this.bulkJobStatus.jobStatus.jobState === JobState.IDLE) {
      this.isComplete = true;
      this._toastService.push(
        ToastTypes.SUCCESS,
        this.smacsIcons.AUTOMATION,
        'tkey;pages.zero_touch.job.completed',
        'tkey;pages.zero_touch.bulk_provisioning.title'
      );
      this._bottomNavService.setButtonPendingState('bulk-in-progress-in-progress', false);
      this._router.navigate([`/automate/microsoft/bulk-provisioning`]);
    }
    this.isRunning = this.bulkJobStatus.jobStatus.jobState === JobState.RUNNING;

    if (this.isRunning) {
      this._bottomNavService.setButtonPendingState('bulk-in-progress-in-progress', true);
      const upnsInStatus = [
        ...microsoftBulkJobStatus.jobDetailedStatus.inProgressUser.map((user) => user.userPrincipalName),
        ...microsoftBulkJobStatus.jobDetailedStatus.remainingUsers.map((user) => user.userPrincipalName),
        ...microsoftBulkJobStatus.jobDetailedStatus.completedUsers.map((user) => user.userPrincipalName),
        ...microsoftBulkJobStatus.jobDetailedStatus.failedUsers.map((user) => user.userPrincipalName),
      ];

      this._zpmUserPhotoContext.initUserPhotos(upnsInStatus);

      const userPhotos = this._zpmUserPhotoContext.getUserPhotos();
      this.tableRows = this.getTableRows(upnsInStatus, userPhotos);
      this.isLoadingTable = false;
    }

    if (this.bulkJobStatus.jobStatus.jobState === JobState.IDLE) {
      this._router.navigate([`/automate/microsoft/bulk-provisioning`]);
    }

    this.isLoading = false;
  };

  private _initBottomNav(): void {
    const buttons = [
      {
        id: 'bulk-in-progress-return',
        buttonClass: ButtonStyles.DEFAULT,
        label: 'tkey;automate.zpm.bulk_provisioning.drafts.in_progress.bottom_nav.cancel',
        dataAutomation: 'bulk-cancel',
        icon: SmacsIcons.BACK,
        cb: () => {
          this._router.navigate(['/automate/microsoft/bulk-provisioning']);
        },
      },
      {
        id: 'bulk-in-progress-in-progress',
        buttonClass: ButtonStyles.DEFAULT,
        label: 'tkey;automate.zpm.bulk_provisioning.drafts.inprogress.bottom_nav.inprogress',
        dataAutomation: 'bulk-in-progress-in-progress',
        icon: SmacsIcons.SAVING,
        state: {
          pending: true,
        },
      },
    ];

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttons));
  }
}
