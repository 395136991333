import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EmailTemplate } from '../../../../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import {
  HtmlInputType,
  SmacsTextComponent,
  SmacsTextConfig,
} from '../../../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../../forms/smacs-forms-models';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../../../forms/fields/checkbox/smacs-checkbox.component';
import { Observable, Subscription } from 'rxjs';
import { SmacsMultiTextConfig } from '../../../../../forms/fields/multi-text/smacs-multi-text.component';
import {
  SmacsCodeAreaComponent,
  SmacsCodeAreaConfig,
  SmacsCodeAreaMode,
} from '../../../../../forms/fields/code-area/smacs-code-area.component';
import { EmailTemplatesContext } from '../../../../contexts/email-templates.context';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { catchError, map } from 'rxjs/operators';
import { DefaultValueService } from '../../../../../shared/services/default-value.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { VariableEditorModalComponent } from '../../../../../modals/edit-custom-input-text-modal/variable-editor-modal.component';
import { VariableEditorType } from '../../../../../modals/edit-custom-input-text-modal/variable-editor.service';
import { SmacsModalService } from '../../../../../shared/services/smacs-modal.service';
import { SmacsIcons } from '../../../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../../../button/button.component';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../../shared/bottom-nav/bottom-nav.service';
import { BottomNavButton } from '../../../../../shared/bottom-nav/bottom-nav.component';
import { Router } from '@angular/router';
import { ToastService } from '../../../../../shared/services/toast.service';

@Component({
  selector: 'smacs-email-template-edit-form',
  templateUrl: 'email-template-edit-form.component.html',
  styleUrls: ['./email-template-edit-form.component.scss'],
})
export class EmailTemplateEditFormComponent
  extends SmacsFormAbstractDirective<EmailTemplate>
  implements OnInit, OnDestroy
{
  @Input() allEmailTemplates: EmailTemplate[];

  SmacsIcons = SmacsIcons;
  ButtonStyles = ButtonStyles;

  subjectHtmlPreview: SafeHtml;
  bodyHtmlPreview: SafeHtml;

  formConfig: SmacsFormConfig = {
    fields: {
      emailTemplateName: {
        id: 'email-template-name',
        label: 'tkey;admin.email_templates.edit_view.name.label',
        dataAutomation: 'email-template-name-input',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        required: true,
        validation: [
          {
            validator: (val: string) => {
              return !!val &&
                this.allEmailTemplates.some(
                  (template) =>
                    template.id !== this.entity.id && template.emailTemplateName.toLowerCase() === val.toLowerCase()
                )
                ? SmacsFormsValidationState.INVALID
                : SmacsFormsValidationState.VALID;
            },
            message: 'tkey;admin.email_templates.edit_view.name.validation.message',
          },
        ],
      },
      senderName: {
        id: 'email-sender-name',
        label: 'tkey;admin.email_templates.edit_view.sender_name.label',
        dataAutomation: 'email-sender-name-input',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        helpText: 'tkey;admin.email_templates.edit_view.sender_name.helptext',
        required: true,
      },
      is360ViewEndUserRecipient: {
        id: 'is-360-view-end-user-recipient',
        label: 'tkey;admin.email_templates.edit_view.recipients.360_view_enduser.label',
        dataAutomation: 'email-template-is-360-view-enduser-recipient-checkbox',
        helpText: 'tkey;admin.email_templates.edit_view.recipients.360_view_enduser.helptext',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.CHECKBOX }),
      },
      otherRecipients: {
        id: 'other-recipients',
        label: 'tkey;admin.email_templates.edit_view.recipients.other.label',
        dataAutomation: 'email-template-other-recipients-multitext',
        helpText: 'tkey;admin.email_templates.edit_view.recipients.other.helptext',
        componentConfig: new SmacsMultiTextConfig(),
        validation: [
          {
            validator: (values: string[]): SmacsFormsValidationState => {
              return values.every((value: string) => {
                return (
                  !value ||
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                    value
                  )
                );
              })
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID;
            },
            message: 'tkey;validators.global.email.invalid.error',
          },
          {
            validator: (val: string[], is360ViewEndUserRecipient: boolean) => {
              return !is360ViewEndUserRecipient && val.every((v) => !v)
                ? SmacsFormsValidationState.INVALID
                : SmacsFormsValidationState.VALID;
            },
            message: 'tkey;admin.email_templates.edit_view.recipients.validation.message',
            injectValuesFromFields: ['is360ViewEndUserRecipient'],
          },
        ],
      },
      emailSubject: {
        id: 'email-subject',
        label: 'tkey;admin.email_templates.edit_view.subject.label',
        dataAutomation: 'email-template-subject-input',
        helpText: 'tkey;admin.email_templates.edit_view.subject.helptext',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        required: true,
      },
      emailBody: {
        id: 'email-body',
        label: 'tkey;admin.email_templates.edit_view.body.label',
        dataAutomation: 'email-template-body-codearea',
        helpText: 'tkey;admin.email_templates.edit_view.body.helptext',
        componentConfig: new SmacsCodeAreaConfig({ mode: SmacsCodeAreaMode.HTML }),
        required: true,
      },
    },
  };

  private _subscription = new Subscription();
  private _isCopy: boolean;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private emailTemplatesContext: EmailTemplatesContext,
    private defaultValueService: DefaultValueService,
    private domSanitizer: DomSanitizer,
    private smacsModalService: SmacsModalService,
    private bottomNavService: BottomNavService,
    private router: Router,
    private toastService: ToastService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._isCopy = this.router.url.includes('copy');
    this._initBottomNav(this.entity);

    const formUpdateSub = this.smacsFormsUpdate$.subscribe((update) => {
      const newSubject = update.new.emailSubject;
      if (newSubject != null) {
        this.subjectHtmlPreview = this._generatePreview(newSubject);
      }
      const newBody = update.new.emailBody;
      if (newBody != null) {
        this.bodyHtmlPreview = this._generatePreview(newBody);
      }

      if (this.isFormSubmitted) {
        this.bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: !this.isFormValid(),
          })
        );
      }
    });
    this._subscription.add(formUpdateSub);

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });
    this._subscription.add(formSubmittedSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  openVariableEditorModal(fieldId: 'emailSubject' | 'emailBody') {
    const modalInstance = this.smacsModalService.openCustomModal(
      VariableEditorModalComponent,
      {
        type: VariableEditorType.CISCO,
      },
      'lg'
    );
    modalInstance.subscribe((newValue: any) => {
      const value = newValue.value;
      const fieldComponent = this.fieldComponents.find((field) => field.fieldId === fieldId);
      if (fieldId === 'emailSubject') {
        (fieldComponent as SmacsTextComponent).insertText(value);
      } else {
        (fieldComponent as SmacsCodeAreaComponent).insertText(value);
      }
    });
  }

  protected submit(): Observable<void> {
    this._setButtonsPending(true);
    return this._saveEmailTemplate(this.entity).pipe(
      map(() => {
        this._onSaveComplete(this.entity);
      }),
      catchError((err) => {
        this._setButtonsPending(false);
        throw err;
      })
    );
  }

  private _generatePreview(unparsedText: string): SafeHtml {
    const htmlPreview = this.defaultValueService.generatePreview(unparsedText);
    return this.domSanitizer.bypassSecurityTrustHtml(htmlPreview);
  }

  private _saveEmailTemplate(emailTemplate: EmailTemplate): Observable<void | number> {
    if (emailTemplate.id && !this._isCopy) {
      return this.emailTemplatesContext.updateTemplate(emailTemplate.id, emailTemplate);
    } else {
      return this.emailTemplatesContext.createTemplate(emailTemplate);
    }
  }

  private _initBottomNav(emailTemplate: EmailTemplate) {
    const bottomNavButtons: BottomNavButton[] = [];
    bottomNavButtons.push({
      id: 'email-template-cancel',
      label: 'tkey;global.button.cancel.text',
      dataAutomation: 'email-template-edit-bottom-nav-cancel-button',
      buttonClass: ButtonStyles.DEFAULT,
      cb: () => this._onCancelClicked(),
    });

    if (emailTemplate.id && !this._isCopy) {
      bottomNavButtons.push({
        id: 'email-template-delete',
        label: 'tkey;global.button.delete.text',
        dataAutomation: 'email-template-edit-bottom-nav-delete-button',
        buttonClass: ButtonStyles.DANGER,
        icon: SmacsIcons.DELETE,
        cb: () => this._onDeleteClicked(),
      });
    }

    bottomNavButtons.push({
      id: 'email-template-save',
      label: 'tkey;global.button.save.text',
      dataAutomation: 'email-template-edit-bottom-nav-save-button',
      buttonClass: ButtonStyles.PRIMARY,
      icon: SmacsIcons.OK,
      cb: () => this._onSaveClicked(),
    });

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _onSaveClicked() {
    this._validateAndSubmitSource.next(true);
  }

  private _returnToTable() {
    this.router.navigateByUrl('/admin/cisco/email-templates');
  }

  private _onCancelClicked() {
    this._returnToTable();
  }

  private _onDeleteClicked() {
    this.emailTemplatesContext.deleteTemplate(this.entity).subscribe((wasDeleted) => {
      if (wasDeleted) {
        this._returnToTable();
      }
    });
  }

  private _onSaveComplete(emailTemplate: EmailTemplate) {
    this.toastService.pushSaveToast(
      'tkey;admin.email_templates.edit_view.toast.message.type',
      emailTemplate.emailTemplateName,
      SmacsIcons.SEND_EMAIL
    );
    this._returnToTable();
  }

  private _setButtonsPending(isPending: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'email-template-save',
        state: {
          pending: isPending,
          buttonDisableState: {
            disabled: isPending,
          },
        },
      })
    );
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'email-template-cancel',
        state: {
          buttonDisableState: {
            disabled: isPending,
          },
        },
      })
    );
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'email-template-delete',
        state: {
          buttonDisableState: {
            disabled: isPending,
          },
        },
      })
    );
  }
}
