<smacs-checkbox *ngFor="let fieldId of fieldGroups.checkboxInputs" [fieldId]="fieldId"></smacs-checkbox>

<smacs-text *ngFor="let fieldId of fieldGroups.textInputs" [fieldId]="fieldId"></smacs-text>

<div class="text-end">
  <smacs-submit-button
    [isPending]="isSubmitting"
    [isDisabled]="isSubmitting"
    [label]="'tkey;global.button.save.text'"
    [dataAutomation]="'automatic-sync-form-save-button'"
    [icon]="smacsIcons.OK"
    [iconPending]="smacsIcons.SAVING"
    [formSubmitSubject]="_validateAndSubmitSource"
  ></smacs-submit-button>
</div>
