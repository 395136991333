import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomModal } from '../../shared/services/smacs-modal.service';
import { ButtonSizes, ButtonStyles } from '../../button/button.component';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { ToastTypes } from '../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../shared/services/toast.service';

interface ImportCertSuccessModalViewProperties {
  imgPath?: string;
}

@Component({
  selector: 'smacs-import-cert-success-modal',
  templateUrl: './import-cert-success-modal.component.html',
})
export class ImportCertSuccessModalComponent implements CustomModal<ImportCertSuccessModalViewProperties> {
  modalProperties: ImportCertSuccessModalViewProperties;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;

  constructor(private activeModal: NgbActiveModal, private _toastService: ToastService) {}

  onButtonClick() {
    this.activeModal.close();
    this._toastService.push(
      ToastTypes.SUCCESS,
      this.smacsIcons.CERTIFICATE,
      'tkey;certificate_management.import_certificate.success_toast.title',
      'tkey;certificate_management.import_certificate.success_toast.message'
    );
  }
}
