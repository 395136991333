import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CipcProtocolFieldConfig } from '../../../../shared/models/generated/smacsModels';

@Injectable()
export class CipcProtocolFieldConfigResource {
  constructor(private http: HttpClient) {}

  getProtocolFieldConfig(siteId: number): Observable<CipcProtocolFieldConfig> {
    return this.http.get<CipcProtocolFieldConfig>(`/services/cisco/field-configs/cipc-protocol?siteId=${siteId}`);
  }
}
