import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { Microsoft360View } from '../models/generated/smacsModels';
import { Microsoft360ViewResource } from '../resources/microsoft-360-view.resource';
import { ActivatedRoute } from '@angular/router';
import { SvgLoaderContext } from './svg-loader.context';

@Injectable()
export class Microsoft360ViewContext {
  public _stateSource = new ReplaySubject<Microsoft360View>(1);
  public state$ = this._stateSource.asObservable();

  constructor(
    private microsoft360ViewResource: Microsoft360ViewResource,
    private route: ActivatedRoute,
    private svgLoaderContext: SvgLoaderContext
  ) {
    const userPrincipalName = this.route.snapshot.paramMap.get('upn');
    this.svgLoaderContext.init();
    this.svgLoaderContext.state$.subscribe((state: boolean) => {
      if (state) {
        this.refresh(userPrincipalName).subscribe();
      } else {
        this.svgLoaderContext.loadSvgs().subscribe();
      }
    });
  }

  refresh(userPrincipalName: string): Observable<Microsoft360View> {
    return new Observable<Microsoft360View>((subscriber: Subscriber<Microsoft360View>) => {
      this._getMicrosoft360View(userPrincipalName).subscribe({
        next: (microsoft360View: Microsoft360View) => {
          this._stateSource.next(microsoft360View);
          subscriber.next(microsoft360View);
          subscriber.complete();
        },
        error: (response) => {
          if (response.status === 404) {
            window.location.href = `${window.location.origin}/app2/#/home`;
          }

          subscriber.error(response);
          subscriber.complete();
        },
      });
    });
  }

  setMicrosoft360View(ms360View: Microsoft360View) {
    this._stateSource.next(ms360View);
  }

  private _getMicrosoft360View(userPrincipalName: string): Observable<Microsoft360View> {
    return this.microsoft360ViewResource.get(userPrincipalName);
  }
}
