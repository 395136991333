<smacs-select-config-form
  *ngIf="!!entity && !isLoading"
  [hidden]="state.hidden && (!forceShow || !state.defaultValue)"
  [entity]="entity"
  [displayLabel]="config.label"
  [isRequiredVisible]="isRequiredVisible"
  [dataAutomation]="config.dataAutomation"
  [isMultiSelect]="isMultiSelect"
  [isAlwaysRequired]="isAlwaysRequired"
  [availableOptions]="availableOptions"
  [disabled]="config.disabled ? config.disabled() : null"
  [disabledTooltip]="config.disabledTooltip ? config.disabledTooltip : null"
  [helpText]="getHelpText()"
  [translations]="translations"
  [labelToolTipIconClass]="config.labelToolTipIconClass"
  [labelToolTipText]="config.labelToolTipText"
  (smacsFormsUpdate$)="onFormUpdate($event)"
></smacs-select-config-form>
