<app-self-serve-top-bar></app-self-serve-top-bar>

<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div *ngIf="!isLoading">
  <app-self-serve-user-details-back
    [link]="'/self-serve/user/' + user?.ref.username"
  ></app-self-serve-user-details-back>

  <app-self-serve-user-details-header
    [heading]="'tkey;self_serve.phone.buttons.header' | translate"
    [headingDataAutomation]="'self-server-user-details-deskphone-header'"
  ></app-self-serve-user-details-header>

  <div class="col-md-10 offset-md-1">
    <div class="card mb-3">
      <div class="card-body">
        <h3 class="fw-bolder mb-2">
          {{ 'tkey;shared.device.phone.phone_buttons.instruction_card.header' | translate }}
        </h3>
        <div [innerHTML]="'tkey;shared.device.phone.phone_buttons.instruction_card.text' | translate"></div>
        <strong class="icon-info-circle">
          {{ 'tkey;shared.device.phone.phone_buttons.instruction_card.footer' | translate }}
        </strong>
      </div>
    </div>

    <smacs-phone-buttons
      [isPending]="isPending"
      [userMode]="userMode"
      [global360View]="selfServe360View"
      [serverId]="cucmServerId"
      [isEnhancedLineModeOn]="isDisplayEnhancedLineMode"
      [ngClass]="{ 'phone-buttons--loaded': !isLoading, 'pointer-none': isPending }"
    ></smacs-phone-buttons>
  </div>
</div>
