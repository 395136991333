import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { playerFactory, SharedModule } from '../shared/shared.module';

import { ActivateDeskphoneComponent } from './activate-deskphone/activate-deskphone.component';
import { SelfServePinResetAbstractComponent } from './self-serve-pin-reset/self-serve-pin-reset.abstract.component';
import { SelfServeDeskphoneEditComponent } from './self-serve-edit-deskphone/self-serve-deskphone-edit.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SmacsFormsModule } from '../forms/smacs-forms.module';
import { SelfServeUserDetailsHeaderComponent } from './self-serve-user-details/self-serve-user-details-header/self-serve-user-details-header.component';
import { RouterModule } from '@angular/router';
import { SelfServeUserDetailsBackComponent } from './self-serve-user-details/self-serve-user-details-back/self-serve-user-details-back.component';
import { SelfServeEmPinResetComponent } from './self-serve-pin-reset/self-serve-em-pin-reset/self-serve-em-pin-reset.component';
import { SelfServeUserDetailsComponent } from './self-serve-user-details/self-serve-user-details.component';
import { SelfServeTopBarComponent } from './self-serve-top-bar/self-serve-top-bar.component';
import { SelfServeVmPinResetComponent } from './self-serve-pin-reset/self-serve-vm-pin-reset/self-serve-vm-pin-reset.component';
import { SelfServeSnrEditComponent } from './self-serve-snr-edit/self-serve-snr-edit.component';
import { SelfServeCallForwardEditComponent } from './self-serve-call-forward-edit/self-serve-call-forward-edit.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule } from 'primeng/calendar';
import { DefaultDeskphoneResource } from './resources/default-deskphone.resource';
import { VoicemailPinResetResource } from './resources/voicemail-pin-reset.resource';
import { DirectoryNumberResource } from './resources/directory-number.resource';
import { EndUserPinResetResource } from './resources/end-user-pin-reset.resource';
import { SearchDialPlanResource } from './resources/search/search-dial-plan.resource';
import { SearchDirectoryNumberResource } from './resources/search/search-directory-number.resource';
import { SnrService } from './services/snr.service';
import { SnrProfileResource } from './resources/snr-profile.resource';
import { PhoneButtonsModule } from '../shared/phone-buttons/phone-buttons.module';
import { ButtonModule } from '../button/button.module';
import { SelfServeReplaceDeskphoneComponent } from './self-serve-replace-deskphone/self-serve-replace-deskphone.component';
import { PhoneValidationService } from './services/phone-validation.service';
import { SelfServeCardComponent } from './self-serve-card/self-serve-card.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LottieCacheModule, LottieModule } from 'ngx-lottie';
import { SelfServeComponent } from './self-serve.component';
import { PinResetService } from '../shared/services/pin-reset.service';
import { AnimatedImageComponent } from '../shared/animated-image/animated-image.component';
import { MultiStepAnimationComponent } from '../shared/multi-step-animation/multi-step-animation.component';
import { LoadingBlockModule } from '../shared/loading-block/loading-block.module';
import { FooterModule } from '../shared/footer/footer.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    SharedModule,
    NgbModule,
    NgSelectModule,
    SmacsFormsModule,
    RouterModule,
    BrowserAnimationsModule,
    CalendarModule,
    PhoneButtonsModule,
    ButtonModule,
    AngularSvgIconModule,
    LottieModule.forRoot({ player: playerFactory }),
    LottieCacheModule.forRoot(),
    LoadingBlockModule,
    FooterModule,
  ],
  declarations: [
    SelfServeComponent,
    ActivateDeskphoneComponent,
    SelfServeEmPinResetComponent,
    SelfServeVmPinResetComponent,
    SelfServeTopBarComponent,
    SelfServeUserDetailsComponent,
    SelfServeUserDetailsHeaderComponent,
    SelfServeUserDetailsBackComponent,
    SelfServeSnrEditComponent,
    SelfServeCallForwardEditComponent,
    SelfServePinResetAbstractComponent,
    SelfServeDeskphoneEditComponent,
    SelfServeReplaceDeskphoneComponent,
    SelfServeCardComponent,
    AnimatedImageComponent,
    MultiStepAnimationComponent,
  ],
  exports: [
    SelfServeComponent,
    ActivateDeskphoneComponent,
    SelfServeEmPinResetComponent,
    SelfServeVmPinResetComponent,
    SelfServeTopBarComponent,
    SelfServeUserDetailsComponent,
    SelfServeUserDetailsHeaderComponent,
    SelfServeUserDetailsBackComponent,
    SelfServeSnrEditComponent,
    SelfServeCallForwardEditComponent,
    SelfServeDeskphoneEditComponent,
    SelfServeReplaceDeskphoneComponent,
    SelfServeCardComponent,
    AnimatedImageComponent,
    MultiStepAnimationComponent,
  ],
  providers: [
    DefaultDeskphoneResource,
    PinResetService,
    VoicemailPinResetResource,
    DirectoryNumberResource,
    EndUserPinResetResource,
    SearchDialPlanResource,
    SearchDirectoryNumberResource,
    SnrService,
    SnrProfileResource,
    PhoneValidationService,
  ],
})
export class SelfServeModule {}
