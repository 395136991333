import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  AccountType,
  BandwidthE911Dlr,
  BandwidthEmergencyCallingSettingsConfig,
  CallingType,
  DialPlanGroupEntry,
  DialPlanGroupEntryStatus,
  LicensingState,
  Microsoft360View,
  MicrosoftDialPlanFieldConfig,
  MicrosoftDialPlanGroupSearchResult,
  PstnConnectivityType,
  SharedCallingExtension,
  SharedCallingRoutingPolicy,
  TeamsPhoneNumberAssignment,
  TeamsPhoneNumberAssignmentFieldConfig,
  TeamsVoicePolicies,
  TeamsVoicePoliciesFieldConfig,
  UsageType,
} from '../../../../../shared/models/generated/smacsModels';
import { EMPTY, forkJoin, Observable, of, Subscription, switchMap, timer } from 'rxjs';
import { ButtonStyles, ButtonTypes } from '../../../../../button/button.component';
import { SmacsIcons } from '../../../../../shared/models/smacs-icons.enum';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../../forms/smacs-forms-models';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { MicrosoftDialPlansResource } from '../../../../shared/resources/ms-dial-plans.resource';
import { BottomNavButton } from '../../../../../shared/bottom-nav/bottom-nav.component';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateState,
} from '../../../../../shared/bottom-nav/bottom-nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BandwidthDynamicFormData } from './form/bandwidth-e911-dlrs-form/bandwidth-emergency-calling-form.component';
import { cloneDeep, isEqual } from 'lodash';
import { catchError, delayWhen, filter, map, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { BandwidthEmergencyCallingFieldConfigResource } from '../../../../shared/resources/field-config/bandwidth-emergency-calling-field-config.resource';
import { BandwidthE911DlrsResource } from '../../../../shared/resources/bandwidth-e911-dlrs.resource';
import { MicrosoftDialPlanInventoriesResource } from '../../../../shared/resources/microsoft-dial-plan-inventories.resource';
import {
  ExtensionSelectorOption,
  ExtensionSelectorSize,
  SmacsExtensionSelectorConfig,
} from '../../../../shared/extension-selector/extension-selector.component';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../../../shared/services/smacs-modal.service';
import { DraasSharedExtensionsResource } from '../../../../shared/resources/draas-shared-extensions.resource';
import { NumberLocationValidationResource } from '../../../../shared/resources/number-location-validation.resource';
import { MicrosoftPhoneNumberAssignmentsFieldConfigResource } from '../../../../shared/resources/microsoft-phone-number-assignments-field-config.resource';
import { MicrosoftVoicePoliciesFieldConfigResource } from '../../../../shared/resources/microsoft-voice-policies-field-config.resource';
import { MicrosoftPhoneNumberAssignmentsResource } from '../../../../shared/resources/microsoft-phone-number-assignments.resource';

export interface DialPlanSelectorEntity {
  dialPlanGroupId: number;
  extension: string;
}

@Component({
  selector: 'smacs-microsoft-teams-calling-dial-plan-selector-form',
  templateUrl: './microsoft-teams-calling-dial-plan-selector-form.component.html',
})
export class SmacsMicrosoftTeamsCallingDialPlanSelectorFormComponent
  extends SmacsFormAbstractDirective<DialPlanSelectorEntity>
  implements OnInit, OnDestroy
{
  @Input() microsoftDialPlanGroups: MicrosoftDialPlanFieldConfig[];
  @Input() teamsLine: TeamsPhoneNumberAssignment;
  @Input() voicePolicies: TeamsVoicePolicies;
  @Input() upn: string;
  @Input() isZpcSyncWarningPresent: boolean;
  @Input() isResourceAccount: boolean;
  @Input() ms360View: Microsoft360View;
  @Input() sharedCallingPolicies: SharedCallingRoutingPolicy[];

  check: DialPlanGroupEntry;
  onPremUpn: string;
  initialZiroDraasSharedCallingExtension: SharedCallingExtension;
  isBandwidthConfiguredWithoutE911Dlr = false;
  phoneNumberAssignmentFieldConfig: TeamsPhoneNumberAssignmentFieldConfig;
  voicePoliciesFieldConfig: TeamsVoicePoliciesFieldConfig;
  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;
  CallingType = CallingType;

  showRecentlyDeleted = true;
  showUnavailable = false;
  isSearching: boolean;
  isDialPlanGroupNotFoundForExistingLine = false;
  hasExtensionError = false;
  isNumberLocationValidationInProgress = false;
  showValue = true;
  formConfig: SmacsFormConfig;
  isDisplayForm = false;
  isBandwidthRange = false;
  bandwidthEmergencyCallingFieldConfig: BandwidthEmergencyCallingSettingsConfig;
  bandwidthEntity: BandwidthDynamicFormData;
  initialBandwidthEndpointId: string;
  isExtensionChanged = false;
  isAdWriteDownConfigured = false;
  initialLineUri: string;

  lastDialPlanGroup: MicrosoftDialPlanFieldConfig;
  selectedDialPlanGroup: MicrosoftDialPlanFieldConfig;
  initialDialPlanGroup: MicrosoftDialPlanFieldConfig;
  dialPlanInventories: DialPlanGroupEntry[] = [];
  isSubmitted = false;
  licenseIncompatibleAlert: string = null;
  dialPlanGroupSharedCallingPolicy: SharedCallingRoutingPolicy;
  isDisplaySharedCallingAlert = false;
  isExistingSharedCalling = false;

  private _lastSelectedExtension = '';
  private _userUsageLocation = '';
  private _numberUsageLocation = '';
  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private microsoftDialPlanInventoriesResource: MicrosoftDialPlanInventoriesResource,
    private microsoftDialPlansResource: MicrosoftDialPlansResource,
    private microsoftPhoneNumberAssignmentFieldConfigResource: MicrosoftPhoneNumberAssignmentsFieldConfigResource,
    private microsoftVoicePoliciesFieldConfigResource: MicrosoftVoicePoliciesFieldConfigResource,
    private microsoftPhoneNumberAssignmentsResource: MicrosoftPhoneNumberAssignmentsResource,
    private bottomNavService: BottomNavService,
    private route: ActivatedRoute,
    private router: Router,
    private bandwidthEmergencyCallingFieldConfigResource: BandwidthEmergencyCallingFieldConfigResource,
    private bandwidthE911DlrsResource: BandwidthE911DlrsResource,
    private _translateService: TranslateService,
    private _smacsModalService: SmacsModalService,
    private draasSharedExtensionsResource: DraasSharedExtensionsResource,
    private numberLocationValidationResource: NumberLocationValidationResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this.onPremUpn = this.ms360View.onPremiseUserPrincipalName;
    this._initBottomNav();
    this.initialLineUri = cloneDeep(this.teamsLine?.lineUri);
    this.isDisplayForm = true;
    const existingSharedCallingVoicePolicyNumber =
      this.ms360View?.teamsVoicePolicies?.sharedCallRoutingPolicy?.resourceAccountLineUri;
    this.isExistingSharedCalling = !!existingSharedCallingVoicePolicyNumber;
    if (this.teamsLine !== null || existingSharedCallingVoicePolicyNumber) {
      this.showValue = false;
      const phoneNumberAssignment$ = existingSharedCallingVoicePolicyNumber
        ? of(null)
        : this.microsoftPhoneNumberAssignmentsResource.get(this.upn);
      phoneNumberAssignment$
        .pipe(
          delayWhen(() => this._waitUntilSelectedDialPlanIsSet()),
          filter(() => !!this.selectedDialPlanGroup),
          switchMap((phoneNumberAssignment) =>
            forkJoin([
              existingSharedCallingVoicePolicyNumber
                ? of(null)
                : this.microsoftPhoneNumberAssignmentFieldConfigResource.get(phoneNumberAssignment.lineUri),
              this.microsoftVoicePoliciesFieldConfigResource.get(this.selectedDialPlanGroup.id, this.isResourceAccount),
            ])
          ),
          tap(([phoneNumberAssignmentFieldConfig, voicePoliciesFieldConfig]) => {
            this.phoneNumberAssignmentFieldConfig = phoneNumberAssignmentFieldConfig;
            this.voicePoliciesFieldConfig = voicePoliciesFieldConfig;
          }),
          catchError((err) => {
            if (
              err.status === 422 &&
              err.error.reasonCode === 'INSUFFICIENT_PERMISSION_TO_VIEW_OR_UPDATE_DIAL_PLAN_GROUP'
            ) {
              this._handleNoPermissionForDialPlanGroup(err);
              return EMPTY;
            }
            throw err;
          })
        )
        .subscribe(() => {
          this.showValue = true;
        });
    }

    this.smacsFormsUpdate$.subscribe((changes) => {
      if (changes.old?.extension !== changes.new?.extension) {
        if (changes.new.extension) {
          this.showValue = false;
          const isExtensionType = this._isDialPlanExtensionType();
          const formattedExtension = isExtensionType
            ? this.selectedDialPlanGroup.mainNumber
            : changes.new.extension.split(';')[0];
          forkJoin([
            this.microsoftPhoneNumberAssignmentFieldConfigResource.get(formattedExtension),
            this.microsoftVoicePoliciesFieldConfigResource.get(this.selectedDialPlanGroup.id, this.isResourceAccount),
          ]).subscribe(([phoneNumberAssignmentFieldConfig, voicePoliciesFieldConfig]) => {
            if (this.teamsLine && isExtensionType) {
              this.teamsLine.lineUriExtension = changes.new.extension;
            } else if (
              this.teamsLine?.lineUriExtension &&
              !phoneNumberAssignmentFieldConfig.lineUriExtension.defaultValue
            ) {
              // If we are switching to a dial plan group which does not have the shorthand extension set, clear it
              this.teamsLine.lineUriExtension = '';
            } else if (
              this.teamsLine &&
              !this.teamsLine.lineUriExtension &&
              phoneNumberAssignmentFieldConfig.lineUriExtension.defaultValue
            ) {
              // If we are switching from a dial plan group which does not have the shorthand extension set, set it
              this.teamsLine.lineUriExtension = phoneNumberAssignmentFieldConfig.lineUriExtension.defaultValue;
            }

            this.phoneNumberAssignmentFieldConfig = phoneNumberAssignmentFieldConfig;
            this.voicePoliciesFieldConfig = voicePoliciesFieldConfig;

            if (this.isBandwidthRange) {
              this.isExtensionChanged = true;
              this.bandwidthEmergencyCallingFieldConfigResource
                .get(this.upn, this.entity.extension)
                .subscribe((bandwidthEmergencyCallingFieldConfig) => {
                  this.bandwidthEmergencyCallingFieldConfig = bandwidthEmergencyCallingFieldConfig;
                  if (!isEqual(this.lastDialPlanGroup, this.selectedDialPlanGroup)) {
                    this.bandwidthEntity = {
                      dynamicGeolocationCallerName:
                        bandwidthEmergencyCallingFieldConfig.dynamicGeolocationCallerName.value,
                      dynamicGeolocationPreferredLanguage:
                        bandwidthEmergencyCallingFieldConfig.dynamicGeolocationPreferredLanguage.defaultOption,
                      dynamicGeolocations: bandwidthEmergencyCallingFieldConfig.dynamicGeolocations.defaultOption,
                      dynamicGeolocationCallbackNumber:
                        bandwidthEmergencyCallingFieldConfig.dynamicGeolocationCallbackNumber.value,
                      dynamicGeolocationEndpointId:
                        bandwidthEmergencyCallingFieldConfig.dynamicGeolocationEndpointId.value,
                    };
                  } else {
                    this.bandwidthEntity = {
                      ...this.bandwidthEntity,
                      dynamicGeolocationCallbackNumber:
                        bandwidthEmergencyCallingFieldConfig.dynamicGeolocationCallbackNumber.value,
                      dynamicGeolocationEndpointId:
                        bandwidthEmergencyCallingFieldConfig.dynamicGeolocationEndpointId.value,
                    };
                  }
                  this.showValue = true;
                  if (!this.lastDialPlanGroup || this.lastDialPlanGroup.id !== this.selectedDialPlanGroup.id) {
                    this.lastDialPlanGroup = this.selectedDialPlanGroup;
                  }
                });
            } else {
              this.showValue = true;
              this.bandwidthEmergencyCallingFieldConfig = null;
            }
          });
        }
      }
    });

    if ((this.teamsLine || existingSharedCallingVoicePolicyNumber) && !!this.microsoftDialPlanGroups) {
      this.isSearching = true;
      const sharedCallingDialPlanGroup = this.microsoftDialPlanGroups.find(
        (group) => group.mainNumber === existingSharedCallingVoicePolicyNumber
      );

      const dialPlan$: Observable<MicrosoftDialPlanFieldConfig[] | MicrosoftDialPlanGroupSearchResult[]> =
        existingSharedCallingVoicePolicyNumber
          ? of(sharedCallingDialPlanGroup ? [sharedCallingDialPlanGroup] : [])
          : this.microsoftDialPlansResource.getDialPlanInventoriesByLineUri(this.teamsLine.lineUri);
      dialPlan$.subscribe((dialPlan) => {
        // check if bandwidth is enabled using the passed field config array from parent
        if (dialPlan.length) {
          const fieldConfig = this.microsoftDialPlanGroups.find(
            (msDialPlanFieldConfig: MicrosoftDialPlanFieldConfig) => msDialPlanFieldConfig.id === dialPlan[0].id
          );
          if (fieldConfig) {
            this.isBandwidthRange = fieldConfig.emergencyCallingSettingsConfigured;
          }
        }

        // additional setup to run after Bandwidth check
        const additionalSetup = () => {
          if (dialPlan.length === 0) {
            this.isDialPlanGroupNotFoundForExistingLine = true;
          } else {
            this.isDialPlanGroupNotFoundForExistingLine = false;
            this.selectedDialPlanGroup = this.microsoftDialPlanGroups.find(
              (msDialPlanFieldConfig: MicrosoftDialPlanFieldConfig) => msDialPlanFieldConfig.id === dialPlan[0].id
            );
            this.isDisplaySharedCallingAlert = this.selectedDialPlanGroup.callingType === CallingType.SHARED_CALLING;
            this.initialDialPlanGroup = { ...this.selectedDialPlanGroup };
            this.isAdWriteDownConfigured = this.selectedDialPlanGroup.onPremAdWriteDownEnabled;
            const isExtensionType = this._isDialPlanExtensionType();
            this.dialPlanGroupSharedCallingPolicy = this._determineSharedCallingPolicyForDialPlan(
              this.selectedDialPlanGroup
            );
            this.entity = {
              dialPlanGroupId: dialPlan[0].id,
              extension: isExtensionType
                ? this.teamsLine.lineUriExtension
                : this.selectedDialPlanGroup.callingType === CallingType.DID
                ? this.teamsLine.lineUri
                : '',
            };
            this.entitySource.next({ ...this.entity });
            this._lastSelectedExtension = this.entity.extension;

            let dialPlanInventoriesObs: Observable<void> = of(null);
            if (this.selectedDialPlanGroup.callingType !== CallingType.SHARED_CALLING) {
              dialPlanInventoriesObs = this.microsoftDialPlanInventoriesResource.get(dialPlan[0].id).pipe(
                map((dialPlanInventories) => {
                  this.dialPlanInventories = this.filterOperatorConnectMsCallingPlanUsageTypes(dialPlanInventories);
                }),
                catchError((err) => {
                  if (err.status === 422) {
                    return EMPTY;
                  } else {
                    throw err;
                  }
                })
              );
            }

            let ziroDraasSharedCallingObs: Observable<void> = of(null);
            if (this._isZiroDraasSharedMainNumberDialPlan(this.selectedDialPlanGroup)) {
              ziroDraasSharedCallingObs = this.draasSharedExtensionsResource
                .getExtension(this.teamsLine.lineUri, this.teamsLine.lineUriExtension)
                .pipe(
                  map((sharedCallingExtension) => {
                    this.initialZiroDraasSharedCallingExtension = sharedCallingExtension;
                  }),
                  catchError((err) => {
                    if (err.status === 404) {
                      // in this case the original extension was not configured on the sbc. ignore this.
                      return of(null);
                    }
                    throw err;
                  })
                );
            }

            return forkJoin([dialPlanInventoriesObs, ziroDraasSharedCallingObs])
              .pipe(switchMap(() => this._applyComponentConfigWithRetry()))
              .subscribe({
                next: () => {
                  this._initFormConfig();
                  this.isSearching = false;
                  this.isDisplayForm = true;
                },
              });
          }
        };

        // Get Bandwidth field config and then search using the provided EndpointId
        if (this.isBandwidthRange) {
          this.bandwidthEmergencyCallingFieldConfigResource
            .get(this.upn, this.teamsLine.lineUri)
            .subscribe((bandwidthEmergencyCallingFieldConfig) => {
              this.bandwidthEmergencyCallingFieldConfig = bandwidthEmergencyCallingFieldConfig;
              this.bandwidthE911DlrsResource
                .get(bandwidthEmergencyCallingFieldConfig.dynamicGeolocationEndpointId.value)
                .pipe(
                  catchError((err: HttpErrorResponse) => {
                    if (err.status === 404) {
                      const defaultEntity = {
                        callbackNumber: bandwidthEmergencyCallingFieldConfig.dynamicGeolocationCallbackNumber.value,
                        callerName: bandwidthEmergencyCallingFieldConfig.dynamicGeolocationCallerName.value,
                        geolocation: bandwidthEmergencyCallingFieldConfig.dynamicGeolocations.defaultOption,
                        preferredLanguage:
                          bandwidthEmergencyCallingFieldConfig.dynamicGeolocationPreferredLanguage.defaultOption,
                        id: bandwidthEmergencyCallingFieldConfig.dynamicGeolocationEndpointId.value,
                      };
                      this.isBandwidthConfiguredWithoutE911Dlr = true;
                      return of<BandwidthE911Dlr>(defaultEntity);
                    } else {
                      throw new Error(err.message);
                    }
                  })
                )
                .subscribe((bandwidthE911Dlr: BandwidthE911Dlr) => {
                  // this response is passed to the child as a form entity for validation
                  this.bandwidthEntity = {
                    dynamicGeolocations: bandwidthE911Dlr.geolocation,
                    dynamicGeolocationCallerName: bandwidthE911Dlr.callerName,
                    dynamicGeolocationCallbackNumber: bandwidthE911Dlr.callbackNumber,
                    dynamicGeolocationPreferredLanguage: bandwidthE911Dlr.preferredLanguage,
                    dynamicGeolocationEndpointId: bandwidthE911Dlr.id,
                  };
                  this.initialBandwidthEndpointId = bandwidthE911Dlr.id;
                  additionalSetup();
                });
            });
        } else {
          additionalSetup();
        }
      });
    }

    this._validateAndSubmitSource.subscribe(() => {
      this.isSubmitted = true;
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });

    this._initFormConfig();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  private _initBottomNav() {
    const buttonsList: BottomNavButton[] = [];
    buttonsList.push({
      id: 'dn-selector-cancel',
      dataAutomation: 'dn-selector-cancel',
      label: 'tkey;global.button.cancel.text',
      buttonClass: ButtonStyles.DEFAULT,
      cb: () => {
        this.router.navigate(['../'], { relativeTo: this.route });
      },
    });

    buttonsList.push({
      type: ButtonTypes.SUBMIT,
      id: 'dn-selector-save',
      dataAutomation: 'dn-selector-save',
      label: 'tkey;microsoft_360.view.teams.bottom_nav.save_button.text',
      icon: this.smacsIcons.OK,
      buttonClass: ButtonStyles.PRIMARY,
      submitSubject: this._validateAndSubmitSource,
    });
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttonsList));
  }

  onDialPlanGroupChange() {
    if (this.selectedDialPlanGroup) {
      this.dialPlanInventories = [];
      this.isSearching = true;
      this.isBandwidthRange = this.selectedDialPlanGroup.emergencyCallingSettingsConfigured;
      this.isAdWriteDownConfigured = this.selectedDialPlanGroup.onPremAdWriteDownEnabled;

      this._setLicenseIncompatibleTooltip();
      this.dialPlanGroupSharedCallingPolicy = this._determineSharedCallingPolicyForDialPlan(this.selectedDialPlanGroup);

      if (this.selectedDialPlanGroup.callingType === CallingType.SHARED_CALLING) {
        this.isDisplaySharedCallingAlert = false;
        this.microsoftVoicePoliciesFieldConfigResource
          .get(this.selectedDialPlanGroup.id, this.isResourceAccount)
          .subscribe((voicePoliciesFieldConfig) => {
            this.voicePoliciesFieldConfig = voicePoliciesFieldConfig;
            this.isDisplaySharedCallingAlert = true;
            this.isSearching = false;
          });
      } else if (!this.licenseIncompatibleAlert) {
        this.microsoftDialPlanInventoriesResource
          .get(this.selectedDialPlanGroup.id)
          .subscribe((dialPlanInventories) => {
            if (this.entity.extension) {
              this._lastSelectedExtension = this.entity.extension;
            }
            const extensionOnNewDialPlan =
              this._lastSelectedExtension &&
              dialPlanInventories.some((entry) => entry.value === this._lastSelectedExtension)
                ? this._lastSelectedExtension
                : '';
            this.entitySource.next({
              ...this.entity,
              extension: extensionOnNewDialPlan,
            });

            this.dialPlanInventories = this.filterOperatorConnectMsCallingPlanUsageTypes(dialPlanInventories);
            this.isSearching = false;
            this.applyComponentConfig();
          });
      } else {
        this.isSearching = false;
      }
    }
  }

  protected submit() {
    return of(null);
  }

  private _setLicenseIncompatibleTooltip(): void {
    if (
      this.ms360View.licensingStatus.teamsPhoneStatus === LicensingState.LICENSED &&
      this.selectedDialPlanGroup.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS &&
      this.selectedDialPlanGroup.callingType !== CallingType.SHARED_CALLING &&
      !this.isResourceAccount
    ) {
      this.licenseIncompatibleAlert = 'tkey;microsoft_360.view.teams.calling.permissions.incompatible_license';
    } else if (
      this.ms360View.licensingStatus.teamsPhoneStatus === LicensingState.LICENSED_WITH_MICROSOFT_CALLING_PLAN &&
      this.selectedDialPlanGroup.pstnConnectivityType !== PstnConnectivityType.MICROSOFT_CALLING_PLANS
    ) {
      this.licenseIncompatibleAlert =
        'tkey;microsoft_360.view.teams.calling.permissions.incompatible_microsoft_license';
    } else {
      this.licenseIncompatibleAlert = null;
    }
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        dialPlanGroupId: {
          label: '',
          dataAutomation: 'ms-selector-group',
          required: true,
          hidden: () => true,
        },
        extension: {
          label: 'Available Numbers',
          dataAutomation: 'ms-extension-selector',
          required: true,
          componentConfig: new SmacsExtensionSelectorConfig({
            options: this.dialPlanInventories,
            showRecentlyDeleted: this.showRecentlyDeleted,
            showUnavailable: this.showUnavailable,
            size: ExtensionSelectorSize.LARGE,
          }),
          validation: [
            {
              validator: (val) => {
                this.isNumberLocationValidationInProgress = true;
                const lineUri =
                  this.selectedDialPlanGroup?.callingType === CallingType.DID
                    ? val
                    : this.selectedDialPlanGroup?.mainNumber;
                return this.numberLocationValidationResource.get(lineUri, this.upn).pipe(
                  map((numberLocationValidation) => {
                    this._userUsageLocation = numberLocationValidation.userUsageLocation;
                    this._numberUsageLocation = numberLocationValidation.numberUsageLocation;
                    this.hasExtensionError = !numberLocationValidation.isValid;
                    this.isNumberLocationValidationInProgress = false;

                    return numberLocationValidation.isValid
                      ? SmacsFormsValidationState.VALID
                      : SmacsFormsValidationState.INVALID;
                  })
                );
              },
              message: () => {
                return {
                  content: 'tkey;microsoft_360.view.teams.extensionSelection.validation.number_usage_location',
                  params: {
                    upn: this.upn,
                    numberLocation: this._numberUsageLocation,
                    userLocation: this._userUsageLocation,
                    dpgName: this.selectedDialPlanGroup?.name,
                  },
                };
              },
            },
            {
              validator: () => {
                return this.selectedDialPlanGroup?.callingType === CallingType.SHARED_CALLING &&
                  !this.dialPlanGroupSharedCallingPolicy
                  ? SmacsFormsValidationState.INVALID
                  : SmacsFormsValidationState.VALID;
              },
            },
          ],
          debounceTime: 0,
        },
      },
    } as SmacsFormConfig;
  }

  applyComponentConfig() {
    const extension = this.fieldComponents.find((field) => field.fieldId === 'extension');
    extension.applyComponentConfig(
      new SmacsExtensionSelectorConfig({
        options: this.dialPlanInventories,
        showRecentlyDeleted: this.showRecentlyDeleted,
        showUnavailable: this.showUnavailable,
        size: ExtensionSelectorSize.LARGE,
      })
    );
  }

  private _isDialPlanExtensionType(): boolean {
    return this.selectedDialPlanGroup && this.selectedDialPlanGroup.callingType === CallingType.EXTENSION;
  }

  private filterOperatorConnectMsCallingPlanUsageTypes(
    dialPlanInventories: DialPlanGroupEntry[]
  ): DialPlanGroupEntry[] | ExtensionSelectorOption[] {
    if (!this.selectedDialPlanGroup) return dialPlanInventories;
    const isOperatorConnectOrMsCallingPlanNumbers =
      this.selectedDialPlanGroup.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS ||
      this.selectedDialPlanGroup.pstnConnectivityType === PstnConnectivityType.OPERATOR_CONNECT;
    return dialPlanInventories.map((entry) => {
      if (
        !isOperatorConnectOrMsCallingPlanNumbers ||
        entry.usageTypes.includes(this.ms360View.accountType as AccountType & UsageType) ||
        entry.usageTypes.includes(UsageType.NOT_APPLICABLE)
      )
        return entry;

      if (
        entry.assignmentStatus === DialPlanGroupEntryStatus.UNASSIGNED ||
        entry.assignmentStatus === DialPlanGroupEntryStatus.RECENTLY_DELETED
      ) {
        entry.usageTypes = entry.usageTypes.map((usageType) => {
          switch (usageType) {
            case UsageType.RESOURCE_ACCOUNT:
              return 'Resource Account' as UsageType;
            case UsageType.CONFERENCE_BRIDGE:
              return 'Conference Bridge' as UsageType;
            case UsageType.USER:
              return 'User Account' as UsageType;
            default:
              return usageType;
          }
        });

        if (entry.usageTypes.includes(UsageType.NUMBER_NOT_FOUND_IN_TEAMS)) {
          (entry as ExtensionSelectorOption).tooltip = {
            message:
              'tkey;microsoft_360.view.teams.extensionSelection.tooltip.usage_type_account_type_number_not_in_teams',
            params: {},
          };
        } else {
          (entry as ExtensionSelectorOption).tooltip = {
            message: 'tkey;microsoft_360.view.teams.extensionSelection.tooltip.usage_type_account_type_mismatch',
            params: {
              usageTypes: entry.usageTypes.join(' or '),
            },
          };
        }

        (entry as ExtensionSelectorOption).isDisabled = true;
      }
      return entry;
    });
  }

  private _isZiroDraasSharedMainNumberDialPlan(dialPlanFieldConfig: MicrosoftDialPlanFieldConfig): boolean {
    const ziroDraasPstnTypes = [PstnConnectivityType.ZIRO_DRAAS, PstnConnectivityType.ZIRO_DRAAS_BYOC];
    return (
      dialPlanFieldConfig?.callingType === CallingType.EXTENSION &&
      ziroDraasPstnTypes.includes(dialPlanFieldConfig?.pstnConnectivityType)
    );
  }

  private _handleNoPermissionForDialPlanGroup(error: any): void {
    const description = error.error.description.match(/\[(.*?)\]/);
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.FORBIDDEN,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        title: this._translateService.instant('tkey;microsoft_360.view.teams.calling.permissions.modal.title'),
        promptBody: this._translateService.instant('tkey;microsoft_360.view.teams.calling.permissions.modal.text', {
          group: description[1],
        }),
        displayCloseButton: false,
        buttons: [
          {
            label: 'tkey;dialogs.button.ok',
            buttonClass: ButtonStyles.INFO,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              this.router.navigate(['..'], { relativeTo: this.route });
              return of(null);
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _determineSharedCallingPolicyForDialPlan(dialPlan: MicrosoftDialPlanFieldConfig): SharedCallingRoutingPolicy {
    if (dialPlan.callingType !== CallingType.SHARED_CALLING || !dialPlan.mainNumber) {
      return null;
    }
    return this.sharedCallingPolicies.find((policy) => policy.resourceAccountLineUri === dialPlan.mainNumber);
  }

  // This is a temporary method. Please forgive its sinful nature.
  // To be fixed in https://github.com/Stack8/smacs/issues/18109
  private _waitUntilSelectedDialPlanIsSet(): Observable<void> {
    if (
      !this.selectedDialPlanGroup &&
      !this.isDialPlanGroupNotFoundForExistingLine &&
      this.microsoftDialPlanGroups.length > 0
    ) {
      return timer(200).pipe(switchMap(() => this._waitUntilSelectedDialPlanIsSet()));
    }
    return of(null);
  }

  // Another naughty method
  private _applyComponentConfigWithRetry(): Observable<void> {
    if (!this.fieldComponents) {
      return timer(200).pipe(switchMap(() => this._applyComponentConfigWithRetry()));
    }
    this.applyComponentConfig();
    return of(null);
  }
}
