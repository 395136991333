<smacs-button
  [dataAutomation]="dataAutomation"
  [isDisabled]="isPending || isDisabled"
  [isPending]="isPending"
  (click)="event ? onButtonClick() : null"
  [label]="label"
  [icon]="icon"
  [iconPending]="iconPending"
  [buttonStyle]="isPending ? pendingStyle : buttonStyle"
  [cssClass]="'d-block w-100'"
></smacs-button>
