import { Component, Input } from '@angular/core';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import {
  CallQueue,
  MicrosoftTeamsCallQueuesFieldConfig,
  MicrosoftTeamsUserCallQueues,
} from '../../../../../../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig } from '../../../../../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../../../../../forms/smacs-form-state.service';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../../../../forms/fields/select/smacs-select.component';
import { TeamsCallQueueResource } from '../../../../../../../shared/resources/teams-call-queue.resource';
import { map } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { SmacsIcons } from '../../../../../../../shared/models/smacs-icons.enum';
import { TranslateService } from '@ngx-translate/core';

interface CallQueueFormData {
  callQueues: SmacsSelectOption[];
}

@Component({
  selector: 'ziro-teams-call-queues-form',
  templateUrl: 'teams-call-queues-form.component.html',
  styleUrls: ['teams-call-queues-form.component.scss'],
  providers: [TeamsCallQueueResource],
})
export class TeamsCallQueuesFormComponent extends SmacsFormAbstractDirective<CallQueueFormData> {
  @Input() upn: string;

  smacsIcons = SmacsIcons;

  formConfig: SmacsFormConfig = {
    fields: {
      callQueues: {
        label: 'tkey;microsoft_360.view.teams.call_queues.call_queue_membership.label',
        dataAutomation: 'call-queues',
        required: false,
        componentConfig: new SmacsSelectConfig({
          placeholder: this.translateService.instant(
            'tkey;microsoft_360.view.teams.call_queues.call_queue_membership.placeholder'
          ),
          options: [],
          isMultiSelect: true,
        }),
      },
    },
  };
  isDataInitialized = false;
  isFetchingQueueData = false;

  private _initialOptions: SmacsSelectOption[] = [];
  private subs = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _teamsCallQueueResource: TeamsCallQueueResource,
    private translateService: TranslateService
  ) {
    super(smacsFormStateService);
  }

  saveQueues(): Observable<void> {
    if (!isEqual(this._initialOptions, this.entity.callQueues)) {
      const payload: MicrosoftTeamsUserCallQueues = {
        callQueueJsons: this.entity.callQueues.map((option: SmacsSelectOption) => {
          return {
            callQueueName: option.label,
            callQueueUuid: option.value,
          };
        }),
        userPrincipalName: this.upn,
      };

      return this._teamsCallQueueResource.saveUserQueueMemberships(this.upn, payload).pipe(
        map(() => {
          return null;
        })
      );
    }

    return of(null);
  }

  getCallQueues(): void {
    this.isFetchingQueueData = true;
    const callQueueSub = forkJoin([
      this._teamsCallQueueResource.getQueueMemberships(),
      this._teamsCallQueueResource.getUserQueueMemberships(this.upn),
    ]).subscribe(([fieldConfig, userCallQueues]) => {
      this._updateEntity(userCallQueues);
      this._updateFieldOptions(fieldConfig);
      this.isFetchingQueueData = false;
      this.isDataInitialized = true;
    });

    this.subs.add(callQueueSub);
  }

  protected submit(): Observable<void> {
    return of(null);
  }

  private _updateEntity(userCallQueues: MicrosoftTeamsUserCallQueues): void {
    const options: SmacsSelectOption[] = userCallQueues.callQueueJsons.map((option: CallQueue) => {
      return {
        label: option.callQueueName,
        value: option.callQueueUuid,
      };
    });

    this._initialOptions = [...options];

    this.entitySource.next({
      ...this.entity,
      callQueues: options,
    });
  }

  private _updateFieldOptions(fieldConfig: MicrosoftTeamsCallQueuesFieldConfig): void {
    const options = fieldConfig.callQueues.possibleOptions.map((option: CallQueue) => {
      return {
        label: option.callQueueName,
        value: option.callQueueUuid,
      };
    });

    const fieldNameComponent = this.fieldComponents.find((field) => field.fieldId === 'callQueues');
    fieldNameComponent.applyComponentConfig(
      new SmacsSelectConfig({
        placeholder: this.translateService.instant(
          'tkey;microsoft_360.view.teams.call_queues.call_queue_membership.placeholder'
        ),
        options: options,
        isMultiSelect: true,
      })
    );
  }
}
