import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { Role, UserGroupPermissions } from '../../shared/models/generated/smacsModels';
import { ToastService } from '../../shared/services/toast.service';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';

export enum UiUserPermissionTypes {
  ADMINISTRATOR = 'Administrator',
  HELPDESK = 'Helpdesk',
}

@Injectable()
export class UserPermissionsContext {
  _stateSource = new ReplaySubject<UserGroupPermissions[]>(1);
  state$ = this._stateSource.asObservable();

  private _baseUrl = `/services/microsoft/admin/user-and-group-permissions`;

  static getStringFromApiRole(role: Role): string {
    switch (role) {
      case Role.ZIRO_SUPPORT: {
        return 'Administrator';
      }
      case Role.S8_ADMIN: {
        return 'Administrator';
      }
      case Role.S8_HELPDESK: {
        return 'Helpdesk';
      }
      default: {
        return null;
      }
    }
  }

  constructor(private _httpClient: HttpClient, private _toastService: ToastService) {
    this._getAll().subscribe();
  }

  post(permission: UserGroupPermissions): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._httpClient.post<void>(`${this._baseUrl}`, permission).subscribe(() => {
        this._getAll().subscribe(() => {
          this._toastService.pushSaveToast('tkey;admin.system.user_permissions.title', permission.name, SmacsIcons.OK);
          subscriber.next(null);
          subscriber.complete();
        });
      });
    });
  }

  put(permission: UserGroupPermissions): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._httpClient.put<void>(`${this._baseUrl}/${permission.name}`, permission).subscribe(() => {
        this._getAll().subscribe(() => {
          this._toastService.pushSaveToast('tkey;admin.system.user_permissions.title', permission.name, SmacsIcons.OK);
          subscriber.next(null);
          subscriber.complete();
        });
      });
    });
  }

  delete(permission: UserGroupPermissions): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._httpClient.delete<void>(`${this._baseUrl}/${permission.name}`).subscribe(() => {
        this._getAll().subscribe(() => {
          this._toastService.pushDeleteToast('tkey;admin.system.user_permissions.title', permission.name);
          subscriber.next(null);
          subscriber.complete();
        });
      });
    });
  }

  private _getAll(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._httpClient.get<UserGroupPermissions[]>(this._baseUrl).subscribe((data: UserGroupPermissions[]) => {
        this._stateSource.next(data);
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }
}
