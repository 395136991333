import { Injectable } from '@angular/core';
import { ReadWriteConfigType } from '../../shared/custom-configs/smacs-read-write-select-config/smacs-read-write-select-config.component';

export interface ReadWriteTranslations {
  description: string;
  available: string;
  show: string;
  default: string;
}

@Injectable()
export class ReadWriteSelectConfigService {
  getTranslations(type: ReadWriteConfigType): ReadWriteTranslations {
    switch (type) {
      case ReadWriteConfigType.LICENSE: {
        return {
          description: 'tkey;admin.microsoft.license_management.licenses.modal.about',
          available: 'tkey;admin.microsoft.license_management.licenses.modal.inventory',
          show: 'tkey;admin.microsoft.license_management.licenses.modal.read_only',
          default: 'tkey;admin.microsoft.license_management.licenses.modal.write',
        };
      }
      case ReadWriteConfigType.SECURITY_GROUP: {
        return {
          description: 'tkey;admin.microsoft.security_group_management.security_group.modal.about',
          available: 'tkey;admin.microsoft.security_group_management.security_group.modal.inventory',
          show: 'tkey;admin.microsoft.security_group_management.security_group.modal.read_only',
          default: 'tkey;admin.microsoft.security_group_management.security_group.modal.write',
        };
      }
    }
  }
}
