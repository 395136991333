import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { PhoneUiContext } from '../../../shared/phone-buttons/contexts/phone-ui.context';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Button,
  EndUser,
  EndUserRef,
  ExtensionMobility,
  ExtensionMobilityFieldConfig,
  Global360View,
  MobilityIdentity,
  MobilityIdentityFieldConfig,
  Phone,
  PhoneButtonTemplateFieldConfig,
  PhoneButtonTemplateRef,
  PhoneButtonTemplateResult,
  PhoneFieldConfig,
  PhoneRef,
  PhoneServiceSubscription,
  PostPhoneButtonTemplate,
  TemplateButton,
} from '../../../shared/models/generated/smacsModels';
import { combineLatest, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { SiteContext } from '../../shared/contexts/site.context';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { catchError, filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  BottomNavClearButtonsList,
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { DragdropUiContext } from '../../../shared/phone-buttons/contexts/dragdrop-ui.context';
import { PhoneResource } from '../../../shared/resources/phone.resource';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { PhoneButtonTemplateService } from '../../../shared/services/phone-button-template.service';
import { cloneDeep, isEqual } from 'lodash';
import { ToastService } from '../../../shared/services/toast.service';
import { PhoneButtonTemplateFieldConfigResource } from '../../../shared/resources/phone-button-template-field-config-resource.service';
import { PhoneFieldsEntity, PhoneFieldsFormComponent } from './phone-fields-form/phone-fields-form.component';
import { SmacsFormsUpdate, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { ButtonStyles } from '../../../button/button.component';
import { PhoneButtonsService, PhoneButtonSummary } from '../../../shared/phone-buttons/shared/phone-buttons.service';
import { PhoneType } from '../../../shared/models/service-type';
import { PhoneButtonTemplateFormComponent } from './phone-button-template-form/phone-button-template-form.component';
import { PhoneButtonsComponent, UdpInfo } from '../../../shared/phone-buttons/phone-buttons.component';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import { HelpdeskOptionsContext } from '../../shared/contexts/helpdesk-options.context';
import { Nvp } from '../../../shared/models/nvp';
import { ExtensionMobilityProfilesResource } from '../../../shared/resources/extension-mobility-profiles.resource';
import { MobilityIdentityResource } from '../../../shared/resources/mobility-identity.resource';
import {
  MobilityIdentityFormdata,
  PhoneMobilityIdentityComponent,
} from './phone-mobility-identity/phone-mobility-identity.component';
import { phoneModels } from '../../../shared/phone-buttons/phone-buttons-mapping';
import { EndUserResource } from '../../../shared/resources/end-user.resource';
import {
  ModelProtocolFormData,
  PhoneModelProtocolFormComponent,
} from './phone-model-protocol-form/phone-model-protocol-form.component';
import { PhoneServicesFormComponent } from './phone-services-form/phone-services-form.component';
import { DeviceAbstractDirective } from '../device/device-abstract.directive';

@Component({
  selector: 'smacs-phone',
  templateUrl: './phone.component.html',
})
export class PhoneComponent
  extends DeviceAbstractDirective<Phone, PhoneFieldConfig>
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() isPublicPhone = false;

  @ViewChildren(PhoneModelProtocolFormComponent)
  phoneModelProtocolFormComponent: QueryList<PhoneModelProtocolFormComponent>;
  @ViewChildren(PhoneFieldsFormComponent) phoneFieldsFormComponent: QueryList<PhoneFieldsFormComponent>;
  @ViewChildren(PhoneServicesFormComponent) phoneServicesFormComponent: QueryList<PhoneServicesFormComponent>;
  @ViewChildren(PhoneButtonTemplateFormComponent)
  phoneButtonTemplateFormComponent: QueryList<PhoneButtonTemplateFormComponent>;
  @ViewChildren(PhoneButtonsComponent) phoneButtonsComponent: QueryList<PhoneButtonsComponent>;
  @ViewChildren(PhoneMobilityIdentityComponent)
  phoneMobilityIdentityComponent: QueryList<PhoneMobilityIdentityComponent>;

  // loading vars
  isLoadingPhoneFields = true;
  loadingSubscriptions = {
    phoneUiContext: true,
    phoneFieldConfig: true,
    helpdeskOptionsContext: true,
    modelProtocolFieldConfig: true,
  };

  // input for child components
  unifiedFxUrl: string;
  isFormStateInvalid = false;
  isManualUpdate = false;
  hasModelProtocolUpdateBeenRun = false;
  isCopyUdpButton = false;
  isErrorThrown = false;
  isFormUpdated = false;
  isSingleButtonPhone: boolean;
  isDeskphoneType: boolean;
  isMobilityIdentityType: boolean;
  isExistingMobilityIdentity: boolean;
  isExistingPhoneWithServiceSubscriptions: boolean;
  mobilityIdentityFieldConfig: MobilityIdentityFieldConfig;
  mobilityIdentityInitialEntity: MobilityIdentityFormdata;

  endUserRef: EndUserRef; // null if public phone
  global360View: Global360View; // null if public phone

  isCopy = false;

  // private members
  private _auditTags: Nvp[];
  private _mobilityIdentityAuditTags: Nvp[];
  private _conflictingDeskphone: PhoneRef;
  private _isSubmitted = false;
  private _subscription = new Subscription();
  private _initialPhoneName = '';
  private _isFirstLoad = true;
  private _lastSupportedServiceSubscriptions: PhoneServiceSubscription[];
  private _udpId: string;
  private _endUser: EndUser;
  private _unsubscribe = new Subject<void>();

  constructor(
    protected translateService: TranslateService,
    protected smacsModalService: SmacsModalService,
    protected phoneButtonTemplateService: PhoneButtonTemplateService,
    protected phoneButtonsService: PhoneButtonsService,
    protected phoneUiContext: PhoneUiContext,
    private changeDetectorRef: ChangeDetectorRef,
    private bottomNavService: BottomNavService,
    private route: ActivatedRoute,
    private router: Router,
    private siteContext: SiteContext,
    private siteSummaryContext: SiteSummaryContext,
    private dragdropUiContext: DragdropUiContext,
    private phoneResource: PhoneResource,
    private helpdeskOptionsContext: HelpdeskOptionsContext,
    private toastService: ToastService,
    private phoneButtonTemplateFieldConfigResource: PhoneButtonTemplateFieldConfigResource,
    private smacsFormStateService: SmacsFormStateService,
    private userDetailUiContext: UserDetailUiContext,
    private global360ViewContext: Global360ViewContext,
    private extensionMobilityProfilesResource: ExtensionMobilityProfilesResource,
    private mobilityIdentityResource: MobilityIdentityResource,
    private endUserResource: EndUserResource
  ) {
    super(translateService, smacsModalService, phoneButtonTemplateService, phoneButtonsService, phoneUiContext);
  }

  ngOnInit() {
    this.isCopy = this.router.routerState.snapshot.url.includes('copy');
    this.phoneType = this.phoneUiContext.getPhoneTypeFromRoute();
    // Devices that are not of the deskphone type have their model and protocol hard-coded
    this.isDeskphoneType = this._getIsDeskphoneType();
    this.isMobilityIdentityType = this.phoneType === PhoneType.ANDROID || this.phoneType === PhoneType.IPHONE;
    this._phoneId = this.route.snapshot?.params?.phoneId as string;

    this._subscription.add(
      this.phoneUiContext.isBuildPhoneCalled$.subscribe((isBuildPhoneCalled) => {
        if (!isBuildPhoneCalled) {
          this.isFormUpdated = true;
        }
      })
    );

    this._initUserContexts();
    this._initHelpdeskOptionsContext();
  }

  ngAfterViewInit() {
    // When we submit the forms, make sure they don't call the "submit" function unless ALL forms are valid.
    const phoneFieldChanges$ = this.phoneFieldsFormComponent.changes.subscribe(
      (formComponents: QueryList<PhoneFieldsFormComponent>) => {
        if (formComponents.first) {
          formComponents.first.isFormValid = this._formsAreValid.bind(this);
        }
      }
    );
    const pbtChanges$ = this.phoneButtonTemplateFormComponent.changes.subscribe(
      (formComponents: QueryList<PhoneButtonTemplateFormComponent>) => {
        if (formComponents.first) {
          formComponents.first.isFormValid = this._formsAreValid.bind(this);
        }
      }
    );
    const mobilityIdentityChanges$ = this.phoneMobilityIdentityComponent.changes.subscribe(
      (formComponents: QueryList<PhoneMobilityIdentityComponent>) => {
        if (formComponents.first) {
          formComponents.first.isFormValid = this._formsAreValid.bind(this);
        }
      }
    );
    this._subscription.add(phoneFieldChanges$);
    this._subscription.add(pbtChanges$);
    this._subscription.add(mobilityIdentityChanges$);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this.bottomNavService.dispatch(new BottomNavClearButtonsList());
  }

  updateConflictingPhone($event: PhoneRef) {
    this._conflictingDeskphone = $event;
  }

  onModelProtocolFormUpdate(modelProtocolUpdate: SmacsFormsUpdate<ModelProtocolFormData>) {
    this._validationStates.modelProtocolValidationState = modelProtocolUpdate.valid;
    this._updateBottomNavErrorState();

    const model = modelProtocolUpdate.new.model;
    const protocol = modelProtocolUpdate.new.protocol;

    if (!this.hasModelProtocolUpdateBeenRun && this.phone.id) {
      this.hasModelProtocolUpdateBeenRun = true;
      this.isLoadingPhoneFields = false;
    }

    if (
      model &&
      protocol &&
      (!isEqual(modelProtocolUpdate.new, modelProtocolUpdate.old) ||
        (!this.hasModelProtocolUpdateBeenRun && !this.phone.id))
    ) {
      this.hasModelProtocolUpdateBeenRun = true;
      this.loadingSubscriptions.phoneFieldConfig = true;

      this._onModelProtocolChange(model, modelProtocolUpdate.old?.model, protocol)
        .pipe(
          switchMap((confirmed) => {
            if (confirmed) {
              this.isManualUpdate = true;
              this.modelProtocolFormData = cloneDeep(modelProtocolUpdate.new);
              return this.phoneUiContext.getFieldConfig(this.siteId.toString(), model, protocol);
            } else {
              this.modelProtocolFormData = {
                ...this.modelProtocolFormData,
              };
              this.loadingSubscriptions.phoneFieldConfig = false;
              return of(null);
            }
          }),
          filter((fieldConfig) => !!fieldConfig),
          switchMap((fieldConfig) => {
            if (this.phoneType === PhoneType.CIPC) {
              this.isFormUpdated = true;
            }

            if (!fieldConfig.phoneServices && this.phone?.serviceSubscriptions) {
              this._lastSupportedServiceSubscriptions = cloneDeep(this.phone.serviceSubscriptions);
            }

            return this.phoneUiContext.setupPhone(
              this.endUserRef?.username,
              this.siteId,
              model,
              protocol,
              this._phoneId,
              this.isFormUpdated
            );
          }),
          switchMap((phone) => {
            if (!phone.owner && this.phone.buttons) {
              phone.buttons[0] = this.phone.buttons[0];
            }

            if (this.phoneFieldsEntity?.devicePool) {
              // The phone fields are initially all undefined while the phone is being set up.
              // If devicePool is configured then the phone has been set up and it's ready to copy.
              phone = {
                ...this.phoneFieldsEntity,
                ...phone,
              };
            }

            // If model or protocol were changed, we need to update the buttons and button template.
            if (!this.isAutomaticPhoneButtonTemplateMode) {
              return this.phoneButtonTemplateService
                .findTemplatesByModelAndProtocol(model, protocol, this.cucmServerId)
                .pipe(
                  map((results) => {
                    this.possiblePhoneButtonTemplates = results.map((res) => res.ref);
                    return phone;
                  })
                );
            } else {
              return of(phone);
            }
          })
        )
        .subscribe((phone) => {
          this._setPhoneState(protocol, model, phone);
        });
    }
  }

  onPhoneFieldsChange(phoneFieldsUpdate: SmacsFormsUpdate<PhoneFieldsEntity>) {
    this._validationStates.phoneFieldsValidationState = phoneFieldsUpdate.valid;
    this._updateBottomNavErrorState();

    if (!isEqual(phoneFieldsUpdate.new, phoneFieldsUpdate.old)) {
      this.phoneFieldsEntity = cloneDeep(phoneFieldsUpdate.new);
      this.phoneUiContext.set({ ...this.phone, ...this.modelProtocolFormData, ...this.phoneFieldsEntity });
    }
  }

  onMobilityIdentityFormChange(update: SmacsFormsUpdate<MobilityIdentityFormdata>) {
    if (!update.new.formSwitch) {
      this._validationStates.mobilityIdentityValidationState = SmacsFormsValidationState.VALID;
      this._updateBottomNavErrorState();
    } else {
      this._validationStates.mobilityIdentityValidationState = update.valid;
      this._updateBottomNavErrorState();
    }
  }

  onPhoneServiceSubscriptionsChange(update: SmacsFormsUpdate<PhoneServiceSubscription[]>) {
    if (!isEqual(update.new, update.old)) {
      if (update.old?.length > update.new.length) {
        const subscriptionsToBeRemoved = update.old.filter(
          (sub) => !update.new.some((service) => service.phoneServiceName === sub.phoneServiceName)
        );
        this._clearServiceUrlButton(subscriptionsToBeRemoved);
      }
      this.phoneUiContext.set({ ...this.phone, serviceSubscriptions: update.new });
    }
    this._validationStates.phoneServiceSubscriptionsValidationState = update.valid;
    this._updateBottomNavErrorState();
  }

  private _initUserContexts() {
    if (!this.isPublicPhone) {
      const userContextSubs = combineLatest([
        this.global360ViewContext.state$,
        this.userDetailUiContext.state$,
      ]).subscribe((states: [Global360View, number]) => {
        this.global360View = states[0];
        this.endUserRef = this.userDetailUiContext.getCurrentEnduser().ref;
        this._auditTags = this.endUserRef ? [{ name: 'owner', value: this.endUserRef.username }] : null;
      });
      this._subscription.add(userContextSubs);
    }

    this._subscription.add(this._initPhoneContexts().subscribe());
  }

  private _initHelpdeskOptionsContext() {
    if (this.phoneType === PhoneType.DESKPHONE || this.phoneType === PhoneType.CIPC) {
      const helpdeskOptionsSub = this.helpdeskOptionsContext.state$.subscribe((helpdeskOptions) => {
        if (this.helpdeskOptionsContext.isCiscoHelpdeskOptions(helpdeskOptions)) {
          this.isAutomaticPhoneButtonTemplateMode = helpdeskOptions.automaticPhoneTemplateSelectionEnabled;
          this.isDisplayEnhancedLineMode = helpdeskOptions.displayEnhancedLineMode;
          this.unifiedFxUrl = helpdeskOptions.unifiedFxUrl;
        }
        this.loadingSubscriptions.helpdeskOptionsContext = false;
        this._checkIsLoaded();
      });
      this._subscription.add(helpdeskOptionsSub);
    } else {
      this.loadingSubscriptions.helpdeskOptionsContext = false;
    }
  }

  private _setPhoneState(newProtocol: string, newModel: string, phone: Phone) {
    let newPhoneState = this._getPhoneLines(newProtocol, newModel, phone);
    if (this._phoneId && this._initialPhoneName === newPhoneState.name && !this.isCopyUdpButton) {
      newPhoneState = {
        ...newPhoneState,
        name: '',
      };
    }
    if (this.isCopyUdpButton) {
      newPhoneState = {
        ...newPhoneState,
        expansionModules: this.phone.expansionModules,
      };
    }

    if (this.phoneType === PhoneType.CIPC) {
      newPhoneState = {
        ...newPhoneState,
        name: phone.name,
      };
    }

    if (this._phoneId) {
      newPhoneState = {
        ...newPhoneState,
        id: this._phoneId,
      };
    }

    if (this._lastSupportedServiceSubscriptions) {
      newPhoneState = {
        ...newPhoneState,
        serviceSubscriptions: this._lastSupportedServiceSubscriptions,
      };
      this._lastSupportedServiceSubscriptions = null;
    }

    this.phoneFieldsEntity = this._mapToPhoneFieldsEntity(newPhoneState);
    this.phoneUiContext.set(cloneDeep(newPhoneState));
    if (this.isLoadingPhoneFields) {
      this.isLoadingPhoneFields = false;
    } else {
      this.phoneFieldsFormComponent.first.setSelectOptions(this.fieldConfig);
    }
    this.isManualUpdate = false;
    this.loadingSubscriptions.phoneFieldConfig = false;
    this.isFormUpdated = true;
  }

  onPhoneButtonTemplateChange($event: SmacsFormsUpdate<PhoneButtonTemplateRef>) {
    if (!$event.new || isEqual($event.new, $event.old)) {
      return;
    }
    this.isPhoneButtonTemplateChangePending = true;
    this._checkForConflictAndGetTemplate($event.new, $event.old).subscribe(() => {
      this.isPhoneButtonTemplateChangePending = false;
    });
  }

  onPhoneButtonValidationStateChange($event: SmacsFormsValidationState) {
    this._validationStates.phoneButtonValidationState = $event;
    this._updateBottomNavErrorState();
  }

  onCopyUdpButtons($event: UdpInfo) {
    const { udpModel, udpId } = $event;
    this.isCopyUdpButton = true;
    this._udpId = udpId;
    const deskphoneFieldState = { ...this.phoneFieldsEntity };
    this._updateBottomNavErrorState();

    const deskphoneModel = this.phone.model;

    this.loadingSubscriptions.phoneFieldConfig = true;
    this._onCopyUdpButtonsChange(udpModel, deskphoneModel)
      .pipe(
        tap((confirmed) => {
          if (confirmed) {
            this.isManualUpdate = true;
            this._setPhoneState(this.phone.protocol, deskphoneModel, this.phone);
            this.isFormUpdated = true;
          } else {
            // Rollback the phone fields form.
            if (this.phone.id) {
              this.phoneFieldsEntity = deskphoneFieldState;
            }
            this.loadingSubscriptions.phoneFieldConfig = false;
          }
        })
      )
      .subscribe();
  }

  private _handleFieldConfigs(): Observable<PhoneFieldConfig | ExtensionMobilityFieldConfig> {
    return this.phoneUiContext.phoneFieldConfigState$.pipe(
      tap((fieldConfig) => {
        this.fieldConfig = fieldConfig as PhoneFieldConfig;
      })
    );
  }

  // Since this method requires the fieldConfig, we'll run it after it is set
  private _handleMobilityIdentity(): Observable<[EndUser, MobilityIdentity[]]> {
    const endUserObs$ = this.isMobilityIdentityType
      ? this.endUserResource.get(this.endUserRef?.id, this.endUserRef?.serverId)
      : of(null);
    const mobilityIdentityObs$ =
      this.isMobilityIdentityType && this._phoneId
        ? this.mobilityIdentityResource.getAll(this.endUserRef.serverId.toString(), this._phoneId)
        : of(null);
    return forkJoin([endUserObs$, mobilityIdentityObs$]).pipe(
      tap(([endUser, mobilityIdentities]) => {
        this._endUser = endUser;
        this.isExistingMobilityIdentity = !!mobilityIdentities && !!mobilityIdentities.length;
        if (this.fieldConfig?.mobilityIdentityFieldConfigJson || this.isExistingMobilityIdentity) {
          this._setMobilityIdentityEntityAndFieldConfig(this.isExistingMobilityIdentity ? mobilityIdentities[0] : null);
        }
      })
    );
  }

  private _handlePhoneUiContextUpdates(): Observable<any> {
    return this.phoneUiContext.phoneState$.pipe(
      tap((phone) => {
        this._isChangeOnlyInButtons(phone);
        this.phoneButtonTemplateEntity = phone.phoneButtonTemplate;
        this.isSingleButtonPhone = phone.buttons?.length === 1;

        if (!phone.owner) {
          this._phoneId = phone.id;
        }
        if (this.isExistingPhoneWithServiceSubscriptions == null) {
          this.isExistingPhoneWithServiceSubscriptions = phone.serviceSubscriptions?.length > 0;
        }
        if (this._phoneId && !this._initialPhoneName) {
          this._initialPhoneName = phone.name;
        }
        if (phone.expansionModules !== this.phone.expansionModules) {
          this.phone.expansionModules = phone.expansionModules;
        }
        if (!this._originalPhoneButtonTemplateFeatures && phone.buttons) {
          this._originalPhoneButtonTemplateFeatures = {
            features: phone.buttons.map((button) => button.type),
            model: phone.model,
            protocol: phone.protocol,
          } as PostPhoneButtonTemplate;
        }
      }),
      switchMap((phone) => this._handleModelProtocol(phone))
    );
  }

  private _handleModelProtocol(phone: Phone): Observable<any> {
    if (!!this.phone.model && !!this.phone.protocol) {
      this.dragdropUiContext.setDeviceMetaDataCache(this.cucmServerId, phone);
      if (this.phone.id) {
        setTimeout(() => (this.phone.phoneButtonTemplate = phone.phoneButtonTemplate));
      }
      return forkJoin([
        this._handleGetFirstLoadFieldConfig(),
        this._handlePhoneButtonTemplateFieldConfig(phone),
        this._handleGetPhoneButtonTemplate(phone),
      ]);
    } else {
      return of(null);
    }
  }

  private _handlePhoneButtonTemplateFieldConfig(
    phone: Phone
  ): Observable<PhoneButtonTemplateFieldConfig> | Observable<null> {
    if (this.phoneButtonTemplateFieldConfig === undefined) {
      // Loading an existing phone, we need the phone button template for the phone buttons.
      // Assigning null kind of a silly thing to do but this is how we ensure that the api is only called once.
      this.phoneButtonTemplateFieldConfig = null;
      return this.phoneButtonTemplateFieldConfigResource
        .post({
          model: phone.model,
          protocol: phone.protocol,
          siteId: this.siteId,
        })
        .pipe(
          tap((phoneButtonTemplateFieldConfig) => {
            this.phoneButtonTemplateFieldConfig = phoneButtonTemplateFieldConfig;
          })
        );
    } else {
      return of(null);
    }
  }

  private _handleGetFirstLoadFieldConfig(): Observable<PhoneFieldConfig | ExtensionMobilityFieldConfig> {
    return this._isFirstLoad
      ? this.phoneUiContext.getFieldConfig(this.siteId.toString(), this.phone.model, this.phone.protocol).pipe(
          tap(() => {
            this._isFirstLoad = false;
          })
        )
      : of(null);
  }

  private _handleGetPhoneButtonTemplate(phone: Phone): Observable<PhoneButtonTemplateResult[]> | Observable<null> {
    if (!this.isAutomaticPhoneButtonTemplateMode && !this.possiblePhoneButtonTemplates) {
      // Loading an existing phone... we need the possible phone button template options.
      return this.phoneButtonTemplateService
        .findTemplatesByModelAndProtocol(phone.model, phone.protocol, this.cucmServerId)
        .pipe(
          tap((results) => {
            this.possiblePhoneButtonTemplates = results.map((res) => res.ref);
          })
        );
    } else {
      return of(null);
    }
  }

  private _initPhoneContexts(): Observable<any> {
    return combineLatest([this.siteContext.state$, this.siteSummaryContext.state$]).pipe(
      first(),
      tap(([site, siteSummary]) => {
        this.siteId = Number(site.id);
        this.cucmServerId = this.siteSummaryContext.findCucmServerIdForSite(siteSummary, this.siteId);
      }),
      switchMap(() => {
        return forkJoin([
          this._handleFieldConfigs()
            .pipe(switchMap(() => (this.isMobilityIdentityType ? this._handleMobilityIdentity() : of(null))))
            .pipe(
              tap(() => {
                if (!this.isManualUpdate) {
                  this.loadingSubscriptions.phoneFieldConfig = false;
                }
                this._checkIsLoaded();
              })
            ),
          this._handleModelProtocolFieldConfig().pipe(
            tap(() => {
              this.loadingSubscriptions.modelProtocolFieldConfig = false;
              this._checkIsLoaded();
            })
          ),
          this._handlePhoneUiContextUpdates().pipe(
            tap(() => {
              this.loadingSubscriptions.phoneUiContext = false;
              this._checkIsLoaded();
              // ensure the phone buttons get our changes
              this.changeDetectorRef.detectChanges();
            })
          ),
        ]);
      })
    );
  }

  private _confirmDeletion(): Observable<boolean> {
    return this.phoneResource.delete(this.phone.id, this.cucmServerId.toString(), this._auditTags).pipe(
      switchMap(() => {
        const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
        if (global360) {
          global360.phones = global360.phones.filter((phoneRef: PhoneRef) => phoneRef.id !== this.phone.id);
          this.global360ViewContext._stateSource.next(global360);
        }
        this.smacsFormStateService.setIsFormDirty(false);
        this.toastService.pushDeleteToast(this.phoneType, this._initialPhoneName);
        this.delete$.next();
        return of(true);
      })
    );
  }

  private _deletePhone() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        title: this.translateService.instant('tkey;userdetail.phone.delete.confirmation.modal.header'),
        promptBody: this.translateService.instant('tkey;userdetail.phone.delete.confirmation.modal.text', {
          phoneName: this._initialPhoneName,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._confirmDeletion(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _savePhone() {
    this._isSubmitted = true;
    this._setBottomNavButtonsState('save');
    this.setFormsPending('save');
    this.smacsFormStateService
      .checkForPendingFields({
        fieldComponents: this.phoneFieldsFormComponent.last.fieldComponents,
        validationStates: this._validationStates,
      })
      .pipe(
        switchMap((valid) => {
          if (!valid) {
            this._updateBottomNavErrorState();
            this._setBottomNavButtonsState(null);
            this.setFormsPending(null);
            this._validateChildForms();
            this.isFormStateInvalid = true;
            return of(null);
          } else {
            return this._checkForConflictingDeskphone().pipe(
              switchMap((confirmed) => {
                if (confirmed) {
                  if (
                    !!this.phoneButtonTemplateEntity &&
                    this.phoneButtonTemplateEntity !== this.phone.phoneButtonTemplate
                  ) {
                    this.phone.phoneButtonTemplate = this.phoneButtonTemplateEntity;
                  }
                  this._associateCurrentUserToLines();
                  return this._assignPhoneButtonTemplate().pipe(
                    switchMap(() => {
                      this.phoneUiContext.set(this.phone);
                      return this.phoneUiContext.phoneState$.pipe(
                        takeUntil(this._unsubscribe),
                        switchMap((phone: Phone) => {
                          if (
                            (this.phone.id &&
                              !this.isCopy &&
                              (!this.isMobilityIdentityType ||
                                (!this.isExistingMobilityIdentity && !this.mobilityIdentityFieldConfig))) ||
                            (this.phone.id && !this.isCopy && this.isMobilityIdentityType)
                          ) {
                            return this._updatePhone(phone);
                          } else {
                            return this._createPhone(phone);
                          }
                        })
                      );
                    }),
                    tap((ref: PhoneRef) => {
                      this._unsubscribe.next(null);
                      this.phone.id = ref.id;
                      this.phoneUiContext.set(this.phone);
                      if (!this.endUserRef) {
                        this.setFormsPending(null);
                      }
                      this.smacsFormStateService.setIsFormDirty(false);
                      this.save$.next(ref);
                    })
                  );
                } else {
                  this.setFormsPending(null);
                  return of(null);
                }
              }),
              catchError((err) => {
                this.setFormsPending(null);
                this._setErrorThrown();
                throw err;
              })
            );
          }
        })
      )
      .subscribe();
  }

  private _setErrorThrown() {
    this.isErrorThrown = true;
    this.changeDetectorRef.detectChanges();
  }

  private _formsAreValid(): boolean {
    return Object.values(this._validationStates).every((val) => val === SmacsFormsValidationState.VALID);
  }

  private _updateBottomNavErrorState() {
    if (this._isSubmitted) {
      this.bottomNavService.dispatch(new BottomNavUpdateState({ hasValidationError: !this._formsAreValid() }));
    }
  }

  private _setBottomNavButtonsState(type: 'save' | 'delete' | null) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'save-phone',
        state: {
          pending: type === 'save',
          buttonDisableState: { disabled: type !== null, tooltipKey: '' },
        },
      })
    );
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'cancel-phone',
        state: {
          buttonDisableState: { disabled: type !== null, tooltipKey: '' },
        },
      })
    );
    if (this.phone.id) {
      this.bottomNavService.dispatch(
        new BottomNavUpdateButtonState({
          id: 'delete-phone',
          state: {
            pending: type === 'delete',
            buttonDisableState: { disabled: type !== null, tooltipKey: '' },
          },
        })
      );
    }
  }

  private setFormsPending(type: 'save' | 'delete' | null) {
    this._setBottomNavButtonsState(type);

    if (type === 'save') {
      this.phoneUiContext.setSubmitting(true);
      this._validateChildForms();
    }
    if (!type) {
      this.phoneUiContext.setSubmitting(false);
    }
  }

  private _validateChildForms() {
    if (this.phoneModelProtocolFormComponent?.first) {
      this.phoneModelProtocolFormComponent.first._validateAndSubmitSource.next(true);
    }
    if (this.phoneFieldsFormComponent?.first) {
      this.phoneFieldsFormComponent.first._validateAndSubmitSource.next(true);
    }
    if (this.phoneServicesFormComponent?.first) {
      this.phoneServicesFormComponent.first._validateAndSubmitSource.next(true);
    }
    if (this.phoneButtonTemplateFormComponent?.first) {
      this.phoneButtonTemplateFormComponent.first._validateAndSubmitSource.next(true);
    }
    if (this.isMobilityIdentityType && this.phoneMobilityIdentityComponent.first?.isFormSwitchChecked) {
      this.phoneMobilityIdentityComponent.first._validateAndSubmitSource.next(true);
    }
  }

  private _checkForConflictingDeskphone(): Observable<boolean> {
    if (this._conflictingDeskphone) {
      if (this._conflictingDeskphone.description.startsWith('Auto')) {
        return this._deleteConflictingPhone().pipe(map(() => true));
      } else {
        return this._openConflictingDeskphoneModal();
      }
    } else {
      return of(true);
    }
  }

  private _openConflictingDeskphoneModal(): Observable<boolean> {
    const options = {
      modalViewProperties: {
        icon: SmacsIcons.WARNING,
        iconClass: `text-warning`,
        title: this.translateService.instant('tkey;shared.device.phone.mac_address.warning.overwrite.pub.dialog.title'),
        promptBody: this.translateService.instant(
          'tkey;shared.device.phone.mac_address.warning.overwrite.pub.dialog.body',
          { description: this._conflictingDeskphone.description }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;shared.device.phone.mac_address.warning.overwrite.pub.dialog.confirm',
            buttonClass: ButtonStyles.WARNING,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteConflictingPhone(),
          },
        ],
      },
    };

    return this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteConflictingPhone(): Observable<boolean> {
    return this.phoneResource.delete(this._conflictingDeskphone.id, this.cucmServerId.toString(), this._auditTags).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  private _createPhone(phone: Phone): Observable<PhoneRef> {
    const phonePost$: Observable<PhoneRef> = this.phoneResource.post(
      phone,
      this.cucmServerId.toString(),
      this._auditTags
    );
    this.phone.id = !this.isCopy ? this.phone.id : null;
    if (this.isMobilityIdentityType && this._isMobilityIdentityPost()) {
      const endUserUpdateObs$ = !this?._endUser?.enableMobility
        ? this.endUserResource.put({ ...this._endUser, enableMobility: true }, this.cucmServerId, this._auditTags)
        : of(null);
      this._mobilityIdentityAuditTags = this._auditTags.concat({ name: 'deviceName', value: phone.name });

      return phonePost$.pipe(
        switchMap((phoneRef: PhoneRef) => {
          return endUserUpdateObs$.pipe(
            switchMap(() => {
              return this.mobilityIdentityResource
                .post(
                  this.cucmServerId.toString(),
                  phoneRef.id,
                  this.phoneMobilityIdentityComponent.first.getMobilityIdentity(),
                  this._mobilityIdentityAuditTags
                )
                .pipe(switchMap(() => of(phoneRef)));
            })
          );
        })
      );
    }

    return phonePost$;
  }

  private _updatePhone(phone: Phone): Observable<PhoneRef> {
    const phonePut$ = this.phoneResource.put(phone, this.cucmServerId.toString(), this._auditTags);
    const endUserUpdateObs$ = !this?._endUser?.enableMobility
      ? this.endUserResource.put({ ...this._endUser, enableMobility: true }, this.cucmServerId, this._auditTags)
      : of(null);
    if (this.isMobilityIdentityType) {
      this._mobilityIdentityAuditTags = this._auditTags.concat({ name: 'deviceName', value: phone.name });
    }

    if (
      this.phone.id &&
      !this.isCopy &&
      (!this.isMobilityIdentityType || (!this.isExistingMobilityIdentity && !this.mobilityIdentityFieldConfig))
    ) {
      return phonePut$;
    } else if (this.phone.id && !this.isCopy && this.isMobilityIdentityType) {
      const mobilityIdentityDelete$ = this.mobilityIdentityResource.delete(
        this.cucmServerId.toString(),
        phone.id,
        this.mobilityIdentityInitialEntity.id,
        this._mobilityIdentityAuditTags
      );
      const mobilityIdentityPost$ = endUserUpdateObs$.pipe(
        switchMap(() => {
          return this.mobilityIdentityResource.post(
            this.cucmServerId.toString(),
            phone.id,
            this.phoneMobilityIdentityComponent.first.getMobilityIdentity(),
            this._mobilityIdentityAuditTags
          );
        })
      );
      const mobilityIdentityPut$ = this.mobilityIdentityResource.put(
        this.cucmServerId.toString(),
        phone.id,
        this.phoneMobilityIdentityComponent.first.getMobilityIdentity(),
        this._mobilityIdentityAuditTags
      );
      return phonePut$.pipe(
        switchMap((phoneRef) => {
          if (this._isMobilityIdentityDelete()) {
            return mobilityIdentityDelete$.pipe(switchMap(() => of(phoneRef)));
          } else if (this._isMobilityIdentityPost()) {
            return mobilityIdentityPost$.pipe(switchMap(() => of(phoneRef)));
          } else if (this._isMobilityIdentityPut()) {
            return mobilityIdentityPut$.pipe(switchMap(() => of(phoneRef)));
          } else {
            return of(phoneRef);
          }
        })
      );
    }

    return phonePut$;
  }

  private _onCopyUdpButtonsChange(udpModel: string, oldModel: string): Observable<boolean> {
    return this.extensionMobilityProfilesResource.get(this._udpId, this.cucmServerId.toString()).pipe(
      switchMap((udpResult: ExtensionMobility) => {
        const proposedDeskphoneButtons = this._overlayButtonsForCopyUdpButtons(
          udpResult.buttons,
          this.phone.buttons,
          this.phoneButtonTemplateFieldConfig
        ).filter((button) => button?.type !== undefined);

        const proposedDeskphoneButtonSummary = this.phone.buttons.map((button) =>
          this.phoneButtonsService.mapButtonToButtonSummary(button)
        );

        const currentUdpStandardButtonSummary = udpResult.buttons
          .map((button) => this.phoneButtonsService.mapButtonToButtonSummary(button))
          .slice(0, this.phoneButtonTemplateFieldConfig.buttons.length);

        const udpButtonSummary = udpResult.buttons.map((button) =>
          this.phoneButtonsService.mapButtonToButtonSummary(button)
        );

        const standardButtonLength = this.phoneButtonTemplateFieldConfig.buttons.reduce(
          (acc: number, cur: TemplateButton) => (cur.feature !== 'None' ? acc + 1 : acc),
          0
        );

        const deskphoneKemsButtonTotal = this.phone.expansionModules.reduce(
          (acc: number, cur: keyof typeof phoneModels) => {
            return acc + phoneModels[cur].numberStandard * phoneModels[cur].numberPages;
          },
          0
        );

        const proposedDeskphoneButtonLength = standardButtonLength + deskphoneKemsButtonTotal;

        const udpHasKems = this._checkUdpHasKems(udpButtonSummary, standardButtonLength);

        const buttonsWillBeLost = udpButtonSummary
          .slice(proposedDeskphoneButtonLength)
          .some((button) => button.type !== 'None');

        if (udpHasKems && buttonsWillBeLost) {
          const deleteExpansionModule = udpResult.buttons.length !== this.phone.buttons.length;
          return this._openPhoneButtonLayoutConflictModal(
            {
              changeType: 'phone model',
              currentValue: oldModel,
              proposedValue: udpModel,
              currentButtonLayout: currentUdpStandardButtonSummary,
              proposedButtonLayout: proposedDeskphoneButtonSummary,
              currentExpansionModules: this.phone.expansionModules,
              isButtonConflictPresent: false,
            },
            true
          ).pipe(
            switchMap((confirmed) => {
              if (confirmed) {
                const lostButtonIndexes = udpButtonSummary
                  .slice(proposedDeskphoneButtonLength)
                  .reduce((acc, cur, i) => {
                    if (cur.type !== 'None' && !!cur.label) {
                      acc = [...acc, i + proposedDeskphoneButtonLength];
                      return acc;
                    }
                    return acc;
                  }, []);

                lostButtonIndexes.forEach((i) => {
                  if (proposedDeskphoneButtons[i]) {
                    proposedDeskphoneButtons[i] = proposedDeskphoneButtonSummary[i];
                  }
                });

                this.phone.buttons = proposedDeskphoneButtons;
                this.phone.phoneButtonTemplate = this.phoneButtonTemplateFieldConfig.ref;
                this.phoneButtonTemplateEntity = this.phone.phoneButtonTemplate;
                if (deleteExpansionModule) {
                  this.phone.expansionModules = [];
                }
              }
              return of(confirmed);
            })
          );
        } else if (!udpHasKems) {
          this.phone.buttons = proposedDeskphoneButtons;
          if (this.phone.expansionModules?.length) {
            this.phone.expansionModules = [];
          }
          return of(true);
        } else {
          this.phone.buttons = proposedDeskphoneButtons;
          return of(true);
        }
      })
    );
  }

  private _checkUdpHasKems(udpButtonSummary: PhoneButtonSummary[], standardButtonLength: number): boolean {
    for (let i = standardButtonLength; i <= udpButtonSummary.length; i++) {
      if (!!udpButtonSummary[i]?.value || !!udpButtonSummary[i]?.label) {
        return true;
      }
    }
    return false;
  }

  private _onModelProtocolChange(newModel: string, oldModel: string, newProtocol: string): Observable<boolean> {
    return this.phoneButtonTemplateFieldConfigResource
      .post({
        model: newModel,
        protocol: newProtocol,
        siteId: this.siteId,
      })
      .pipe(
        switchMap((proposedPhoneButtonTemplateFieldConfig) => {
          const { proposedTemplateAvailableFeatures, proposedButtons, currentButtonSummary } =
            this._getProposedButtonSummary(proposedPhoneButtonTemplateFieldConfig);
          const isButtonConflictPresent = this._isButtonConflictPresent(
            currentButtonSummary,
            proposedPhoneButtonTemplateFieldConfig.buttons,
            proposedTemplateAvailableFeatures
          );

          if (isButtonConflictPresent || this.phone.expansionModules?.length > 0) {
            const proposedButtonSummary = proposedButtons.map((button) =>
              this.phoneButtonsService.mapButtonToButtonSummary(button)
            );
            return this._openPhoneButtonLayoutConflictModal(
              {
                changeType: 'phone model',
                currentValue: oldModel,
                proposedValue: newModel,
                currentButtonLayout: currentButtonSummary,
                proposedButtonLayout: proposedButtonSummary,
                currentExpansionModules: this.phone.expansionModules,
                isButtonConflictPresent: isButtonConflictPresent,
              },
              false
            ).pipe(
              switchMap((confirmed) => {
                if (confirmed) {
                  this.phone.buttons = proposedButtons;
                  this.phoneButtonTemplateFieldConfig = proposedPhoneButtonTemplateFieldConfig;
                  this.phone.phoneButtonTemplate = this.phoneButtonTemplateFieldConfig.ref;
                  this.phoneButtonTemplateEntity = this.phone.phoneButtonTemplate;
                  if (newModel !== oldModel) {
                    this.phone.expansionModules = [];
                  }
                }
                return of(confirmed);
              })
            );
          } else {
            this.phone.buttons = proposedButtons;
            this.phoneButtonTemplateFieldConfig = proposedPhoneButtonTemplateFieldConfig;
            this.phone.phoneButtonTemplate = this.phoneButtonTemplateFieldConfig.ref;
            this.phoneButtonTemplateEntity = this.phone.phoneButtonTemplate;
            return of(true);
          }
        })
      );
  }

  private _overlayButtonsForCopyUdpButtons(
    udpButtons: Button[],
    currentPhoneButtons: Button[],
    phoneButtonTemplateFieldConfig: PhoneButtonTemplateFieldConfig
  ): Button[] {
    const availablePhoneButtonTypes = phoneButtonTemplateFieldConfig.availableButtonFeatures;
    return currentPhoneButtons.map((phoneButton, i) => {
      const udpButtonAtIndex = udpButtons[i];
      const isPhoneButtonFixed =
        !this.isAutomaticPhoneButtonTemplateMode || phoneButtonTemplateFieldConfig.buttons[i]?.fixed;
      if (udpButtonAtIndex) {
        if (udpButtonAtIndex.type === phoneButton.type) {
          return udpButtonAtIndex;
        } else if (!availablePhoneButtonTypes.includes(udpButtonAtIndex.type) || isPhoneButtonFixed) {
          return phoneButton;
        } else {
          return udpButtonAtIndex;
        }
      }
    });
  }

  private _getProposedButtonSummary(proposedPhoneButtonTemplateFieldConfig: PhoneButtonTemplateFieldConfig) {
    const proposedTemplateAvailableFeatures = proposedPhoneButtonTemplateFieldConfig.availableButtonFeatures;
    const proposedButtons = this._overlayButtons(
      this.phone.buttons,
      proposedPhoneButtonTemplateFieldConfig.buttons,
      proposedTemplateAvailableFeatures
    );
    const currentButtonSummary = (this.phone.buttons || [])
      .map((button) => this.phoneButtonsService.mapButtonToButtonSummary(button))
      .slice(
        0,
        this.phoneButtonTemplateFieldConfig
          ? this.phoneButtonTemplateFieldConfig.buttons.length
          : proposedPhoneButtonTemplateFieldConfig.buttons.length
      );
    return {
      proposedTemplateAvailableFeatures,
      proposedButtons,
      currentButtonSummary,
    };
  }

  private _checkIsLoaded() {
    if (this.isLoading && Object.values(this.loadingSubscriptions).every((loadingSub) => !loadingSub)) {
      this.isLoading = false;

      const bottomNavButtons = [];
      bottomNavButtons.push({
        id: 'cancel-phone',
        dataAutomation: 'phone-cancel-button',
        label: 'Cancel',
        buttonClass: ButtonStyles.DEFAULT,
        cb: () => {
          this.cancel$.next();
        },
      });
      if (this._phoneId && !this.isCopy) {
        bottomNavButtons.push({
          id: 'delete-phone',
          dataAutomation: 'phone-delete-button',
          label: 'Delete',
          icon: SmacsIcons.DELETE,
          buttonClass: ButtonStyles.DANGER,
          cb: () => {
            this._deletePhone();
          },
        });
      }
      bottomNavButtons.push({
        id: 'save-phone',
        dataAutomation: 'phone-save-button',
        label: 'Save',
        icon: SmacsIcons.OK,
        buttonClass: ButtonStyles.PRIMARY,
        cb: () => {
          this._savePhone();
        },
      });
      this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
    }
  }

  /**
   * Update the phoneFieldsEntity only if phone fields have changed
   * @param newPhone
   * @private
   */
  private _isChangeOnlyInButtons(newPhone: Phone) {
    if (this.phone) {
      const phoneFieldsNew = this._mapToPhoneFieldsEntity(newPhone);
      const phoneFieldsOld = this._mapToPhoneFieldsEntity(this.phone);
      if (!isEqual(phoneFieldsNew, phoneFieldsOld)) {
        this.phoneFieldsEntity = this._mapToPhoneFieldsEntity(newPhone);
      }
    } else {
      this.phoneFieldsEntity = this._mapToPhoneFieldsEntity(newPhone);
    }
    this.phone = newPhone;
    this.modelProtocolFormData = {
      model: newPhone?.model,
      protocol: newPhone?.protocol,
    };
  }

  private _getIsDeskphoneType(): boolean {
    return this.phoneType === PhoneType.DESKPHONE;
  }

  /**
   * Set mobility identity entity and field config. If site is not configured for mobility identity management
   * but a mobility identity was found on the phone, then the field config should be hardcoded as
   * show/required: true with empty default values, otherwise build according to field configs and existing
   * mobility identity data
   * @param mobilityIdentity
   * @private
   */
  private _setMobilityIdentityEntityAndFieldConfig(mobilityIdentity: MobilityIdentity) {
    if (
      this.isMobilityIdentityType &&
      this.isExistingMobilityIdentity &&
      !this.fieldConfig.mobilityIdentityFieldConfigJson
    ) {
      this.mobilityIdentityFieldConfig = {
        name: {
          show: true,
          required: true,
          defaultValue: '',
        },
        destination: {
          show: true,
          required: true,
          defaultValue: '',
        },
        stopRingingPhoneDelayInSeconds: {
          show: true,
          required: true,
          defaultValue: undefined,
        },
        delayBeforeRingInSeconds: {
          show: true,
          required: true,
          defaultValue: undefined,
        },
      };
    } else {
      this.mobilityIdentityFieldConfig = this.fieldConfig.mobilityIdentityFieldConfigJson;
    }
    this.mobilityIdentityInitialEntity = {
      id: this.isExistingMobilityIdentity ? mobilityIdentity.id : null,
      formSwitch: this.isExistingMobilityIdentity,
      name: this.isExistingMobilityIdentity
        ? mobilityIdentity.name
        : this.mobilityIdentityFieldConfig.name.defaultValue,
      destinationNumber: this.isExistingMobilityIdentity
        ? mobilityIdentity.destinationNumber
        : this.mobilityIdentityFieldConfig.destination.defaultValue,
      delayBeforeRingingInSeconds: this.isExistingMobilityIdentity
        ? mobilityIdentity.delayBeforeRingingInSeconds
        : Number(this.mobilityIdentityFieldConfig.delayBeforeRingInSeconds.defaultValue),
      delayStopRingingInSeconds: this.isExistingMobilityIdentity
        ? mobilityIdentity.delayStopRingingInSeconds
        : Number(this.mobilityIdentityFieldConfig.stopRingingPhoneDelayInSeconds.defaultValue),
    };
  }

  private _isMobilityIdentityDelete(): boolean {
    return this.isExistingMobilityIdentity && !this.phoneMobilityIdentityComponent.first.isFormSwitchChecked;
  }

  private _isMobilityIdentityPut(): boolean {
    return (
      this.isExistingMobilityIdentity &&
      this.phoneMobilityIdentityComponent.first?.isFormSwitchChecked &&
      this.phoneMobilityIdentityComponent.first?.getIsFormDirty()
    );
  }

  private _isMobilityIdentityPost(): boolean {
    return !this.isExistingMobilityIdentity && this.phoneMobilityIdentityComponent.first?.isFormSwitchChecked;
  }
}
