import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DragulaService } from 'ng2-dragula';
import { Subscription } from 'rxjs';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { isEqual } from 'lodash';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import MouseDownEvent = JQuery.MouseDownEvent;

export enum ListTypes {
  AVAILABLE_OPTIONS,
  CONFIGURED_OPTIONS,
  DEFAULT_OPTIONS,
}

export interface ConnectedListChangeEvent {
  availableOptions: string[];
  configuredOptions: string[];
  defaultOptions: string[];
}

@Component({
  selector: 'app-connected-list',
  templateUrl: './connected-list.component.html',
  styleUrls: ['./connected-list.component.scss'],
})
export class ConnectedListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() configuredOptions: string[];
  @Input() availableOptions: string[];
  @Input() defaultOptions: string[];
  @Input() isMultiSelect: boolean;
  @Input() availableTranslation: string;
  @Input() showTranslation: string;
  @Input() defaultTranslation: string;
  @Input() isLoadingOptions = false;

  @Output() listChange = new EventEmitter<ConnectedListChangeEvent>();

  @ViewChild('tooltip') tooltip: NgbTooltip;

  listTypes = ListTypes;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;

  DRAGULA_GROUP_NAME = 'CONNECTED_LIST';

  availableOptionsFilter = '';
  configuredOptionsFilter = '';
  defaultOptionsFilter = '';

  availableOptionsFiltered: string[];
  configuredOptionsFiltered: string[];
  defaultOptionsFiltered: string[];

  selectedItems: HTMLButtonElement[] = [];
  isMultiDrag = false;
  multiSelectStartIndex: number | null = null;
  currentParentContainer: HTMLElement;
  isLastSelectCtrl = false;
  shiftSelectedBlock: HTMLButtonElement[] | null = null;

  dragItem: string;
  modifiedDragItem: string;
  valuesText: HTMLSpanElement;

  private _optionsCache: { configuredOptions: string[]; availableOptions: string[]; defaultOptions: string[] };
  private _subscriptions = new Subscription();

  @HostListener('document:click', ['$event']) clickOffDeselect($event: MouseEvent) {
    const eventTarget = $event.target as Element;
    const isClickInsideDeselectProtect =
      Array.from(document.querySelectorAll('.deselect-protect')).filter((element) => element.contains(eventTarget))
        .length > 0;

    if (
      this.currentParentContainer &&
      $event.target !== this.currentParentContainer &&
      !this.currentParentContainer.contains(eventTarget) &&
      !isClickInsideDeselectProtect &&
      !eventTarget.classList.contains('smacs-filter-clear') &&
      !eventTarget.classList.contains('ng-option') &&
      !eventTarget.classList.contains('ng-option-label')
    ) {
      this._clearSelectedItems();
    }
  }

  constructor(private dragulaService: DragulaService) {
    this._addSubscriptions();
  }

  ngOnInit() {
    this._filterAvailableOptions();
    this._filterConfiguredOptions();
    this._filterDefaultOptions();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      !isEqual(changes.configuredOptions?.currentValue, changes.configuredOptions?.previousValue) ||
      !isEqual(changes.defaultOptions?.currentValue, changes.defaultOptions?.previousValue)
    ) {
      this._filterConfiguredOptions();
      this._filterDefaultOptions();
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onDblClick(selectedOption: string, sourceList: ListTypes) {
    if (this.selectedItems.length) {
      this._clearSelectedItems();
    }
    const availableOptions =
      sourceList === this.listTypes.AVAILABLE_OPTIONS
        ? [...this.availableOptions].filter((option: string) => selectedOption !== option)
        : [...this.availableOptions, selectedOption];
    const configuredOptions =
      sourceList === this.listTypes.AVAILABLE_OPTIONS
        ? [...this.configuredOptions, selectedOption]
        : [...this.configuredOptions].filter((option: string) => selectedOption !== option);

    this.listChange.emit({
      availableOptions: availableOptions,
      configuredOptions: configuredOptions,
      defaultOptions: this.defaultOptions,
    });

    this.availableOptions = availableOptions;
    this.configuredOptions = configuredOptions;

    this._filterAvailableOptions();
    this._filterConfiguredOptions();
  }

  onAvailableFilterChanged($event: string) {
    this.availableOptionsFilter = $event ?? '';
    this._filterAvailableOptions();
  }

  onConfiguredFilterChanged($event: string) {
    this.configuredOptionsFilter = $event ?? '';
    this._filterConfiguredOptions();
  }

  onDefaultFilterChanged($event: string) {
    this.defaultOptionsFilter = $event ?? '';
    this._filterDefaultOptions();
  }

  isMatchingFilter(option: string, filteredOptions: string[]): boolean {
    return filteredOptions.includes(option);
  }

  selectMultiple(e: MouseDownEvent) {
    // define clickedButton so that if user clicks the span inside the button we still target the button
    const clickedButton = e.target instanceof HTMLSpanElement ? e.target.parentNode : e.target;
    const currentList = this._searchUpwardForCurrentListElement(clickedButton);
    const containingList = Array.from(currentList.getElementsByTagName('button'));

    // if items are selected in another container, deselect them
    if (document.querySelectorAll('.selected').length > currentList.querySelectorAll('.selected').length) {
      this._clearSelectedItems();
    }

    // the multiSelectStartIndex can be re-assigned by the shift or cmd/ctrl keys
    if (e.ctrlKey || e.metaKey) {
      if (clickedButton.classList.contains('selected')) {
        const targetIndex = this.selectedItems.indexOf(clickedButton);
        this.selectedItems.splice(targetIndex, 1);
        clickedButton.classList.remove('selected');
        if (!this.selectedItems.length) {
          this.multiSelectStartIndex = null;
          this.currentParentContainer = null;
        }
      } else if (!clickedButton.classList.contains('selected')) {
        this.multiSelectStartIndex = containingList.indexOf(clickedButton);
        clickedButton.classList.add('selected');
        this.isLastSelectCtrl = true;
        this.selectedItems.push(clickedButton);
        this.currentParentContainer = currentList;
      }
    } else if (e.shiftKey) {
      // selecting an item when multiSelectStartIndex is set should create a list of items to be selected
      if (this.multiSelectStartIndex !== null) {
        const selectionsToAdd: HTMLButtonElement[] = containingList.slice(
          this.multiSelectStartIndex > containingList.indexOf(clickedButton)
            ? containingList.indexOf(clickedButton)
            : this.multiSelectStartIndex,
          this.multiSelectStartIndex > containingList.indexOf(clickedButton)
            ? this.multiSelectStartIndex + 1
            : containingList.indexOf(clickedButton) + 1
        ) as HTMLButtonElement[];
        // If the last selection was made with ctrl, allow the creation of a selection block in addition to any other already
        // selected items.
        // Otherwise, just create a shift-selected block
        if (this.isLastSelectCtrl) {
          this.selectedItems = [
            ...this.selectedItems.filter((item: HTMLButtonElement) => !selectionsToAdd.includes(item)),
            ...selectionsToAdd,
          ];
        } else if (this.shiftSelectedBlock) {
          // If a shift-block is already selected then create a new one pivoting off of that start index.
          this.selectedItems = [
            ...this.selectedItems.filter((item: HTMLButtonElement) => {
              return !selectionsToAdd.includes(item) && !this.shiftSelectedBlock.includes(item);
            }),
            ...selectionsToAdd,
          ];
        } else {
          this.selectedItems = selectionsToAdd;
        }
        this.isLastSelectCtrl = false;
        // save the shift-selected items in case the user pivots after this selection
        this.shiftSelectedBlock = selectionsToAdd as HTMLButtonElement[];

        // clean up by removing and re-adding classes
        document
          .querySelectorAll('.selected')
          .forEach((button: HTMLButtonElement) => button.classList.remove('selected'));

        this.selectedItems.forEach((item: HTMLButtonElement) => {
          if (!item.classList.contains('selected')) {
            item.classList.add('selected');
          }
        });
      } else if (!clickedButton.classList.contains('selected') && !this.multiSelectStartIndex) {
        // making an initial selection with shift-click
        clickedButton.classList.add('selected');
        this.multiSelectStartIndex = containingList.indexOf(clickedButton);
        this.selectedItems.push(clickedButton);
        this.currentParentContainer = currentList;
      }
    }
  }

  private _searchUpwardForCurrentListElement(currentTarget: HTMLElement): HTMLElement {
    if (currentTarget.className.includes('connected-list')) {
      return currentTarget;
    }
    return this._searchUpwardForCurrentListElement(currentTarget.parentElement);
  }

  _filterAvailableOptions() {
    this.availableOptionsFiltered = this.availableOptions.filter((option: string) =>
      option.toLowerCase().includes(this.availableOptionsFilter.toLowerCase())
    );
  }

  private _filterConfiguredOptions() {
    this.configuredOptionsFiltered = this.configuredOptions.filter((option: string) =>
      option.toLowerCase().includes(this.configuredOptionsFilter.toLowerCase())
    );
  }

  private _filterDefaultOptions() {
    this.defaultOptionsFiltered = this.defaultOptions.filter((option: string) =>
      option.toLowerCase().includes(this.defaultOptionsFilter.toLowerCase())
    );
  }

  private _clearSelectedItems() {
    document.querySelectorAll('.selected').forEach((button: HTMLButtonElement) => button.classList.remove('selected'));
    this.selectedItems = [];
    this.multiSelectStartIndex = null;
    this.isLastSelectCtrl = false;
    this.shiftSelectedBlock = null;
  }

  // Dragula event subscriptions

  private _addSubscriptions() {
    const drag = this.dragulaService.drag(this.DRAGULA_GROUP_NAME).subscribe((item) => {
      // Since we are dragging a Smacs-button we have to access the button within
      const dragElement = item.el.getElementsByTagName('button')[0];
      this.dragItem = dragElement.innerText;

      this._optionsCache = {
        availableOptions: this.availableOptions,
        configuredOptions: this.configuredOptions,
        defaultOptions: this.defaultOptions,
      };

      // if item being dragged is not a selected item and there are selected items we should deselect all
      // selected items and proceed with a single drag

      if (this.selectedItems.length && this.selectedItems.indexOf(dragElement) === -1) {
        this._clearSelectedItems();
      }

      dragElement.classList.add('mirror-image-drag');

      if (this.selectedItems.length > 1) {
        // remove selected class, add in-transit-selected class to hide selected items being moved
        this.isMultiDrag = true;
        item.source.querySelectorAll('.selected').forEach((button: HTMLButtonElement) => {
          button.classList.remove('selected');
          if (button.innerText !== this.dragItem) {
            button.classList.add('in-transit-selected');
          }
        });
        // change the text of the item being dragged
        this.valuesText = document.createElement('span');
        this.valuesText.innerText = ` + ${this.selectedItems.length - 1} value${
          this.selectedItems.length === 2 ? '' : 's'
        }`;
        dragElement.appendChild(this.valuesText);
        this.modifiedDragItem = dragElement.innerText;
      }
    });
    this._subscriptions.add(drag);

    const shadow = this.dragulaService.shadow(this.DRAGULA_GROUP_NAME).subscribe(({ el }) => {
      const mirror = el.getElementsByTagName('button')[0];
      mirror.classList.add('mirror-image-destination');
    });
    this._subscriptions.add(shadow);

    const over = this.dragulaService.over(this.DRAGULA_GROUP_NAME).subscribe(({ container, el }) => {
      const dataAutomation = container.getAttribute('data-automation');
      const text = el?.textContent;

      if (
        dataAutomation === 'default-options-list' &&
        text &&
        !this.defaultOptions.includes(text) &&
        !this.isMultiSelect &&
        this.defaultOptions.length === 1
      ) {
        this.tooltip.open();
        document.querySelectorAll('smacs-button.gu-mirror')[0]?.classList.add('no-drop');
      }
    });
    this._subscriptions.add(over);

    const out = this.dragulaService.out(this.DRAGULA_GROUP_NAME).subscribe(({ container }) => {
      const dataAutomation = container.getAttribute('data-automation');
      if (dataAutomation === 'default-options-list') {
        this.tooltip.close();
        document.querySelectorAll('smacs-button.gu-mirror')[0]?.classList.remove('no-drop');
      }
    });
    this._subscriptions.add(out);

    const drop = this.dragulaService.drop(this.DRAGULA_GROUP_NAME).subscribe(({ name, target, source }) => {
      const targetDataAutomation = target.getAttribute('data-automation');
      const sourceDataAutomation = source.getAttribute('data-automation');

      // cancel drag and show tooltip if necessary
      if (!this.isMultiSelect && this.defaultOptions.length > 1) {
        this.dragulaService.find(name).drake.cancel(true);
        this.availableOptions = [...this._optionsCache.availableOptions];
        this.configuredOptions = [...this._optionsCache.configuredOptions];
        this.defaultOptions = [...this._optionsCache.defaultOptions];
      } else {
        // Multiple item drag and drop

        if (targetDataAutomation === 'available-options-list' && this.isMultiDrag) {
          // remove the selected item values from the source arrays
          if (sourceDataAutomation === 'configured-options-list') {
            this.configuredOptions = this.configuredOptions.filter(
              (listItem: string) =>
                !this.selectedItems.map((button: HTMLButtonElement) => button.innerText).includes(listItem)
            );
          } else if (sourceDataAutomation === 'default-options-list') {
            this.defaultOptions = this.defaultOptions.filter(
              (listItem: string) =>
                !this.selectedItems.map((button: HTMLButtonElement) => button.innerText).includes(listItem)
            );
          } else {
            // target === source
            this.availableOptions = this.availableOptions.filter(
              (listItem: string) =>
                !this.selectedItems.map((button: HTMLButtonElement) => button.innerText).includes(listItem)
            );
          }
          // reverse the selected items array and insert them behind the drag item
          this.selectedItems.reverse().forEach((selectedItem: HTMLButtonElement) => {
            if (selectedItem.innerText !== this.dragItem) {
              this.availableOptions.splice(this.availableOptions.indexOf(this.dragItem) + 1, 0, selectedItem.innerText);
            }
          });
          // remove the in-flight mirror image so that it doesn't appear in the list
          this.availableOptions = this.availableOptions.filter((option: string) => option !== this.modifiedDragItem);
        } else if (targetDataAutomation === 'configured-options-list' && this.isMultiDrag) {
          // same thing as above for the configured optiosn list/show values
          if (sourceDataAutomation === 'available-options-list') {
            this.availableOptions = this.availableOptions.filter(
              (listItem: string) =>
                !this.selectedItems.map((button: HTMLButtonElement) => button.innerText).includes(listItem)
            );
          } else if (sourceDataAutomation === 'default-options-list') {
            this.defaultOptions = this.defaultOptions.filter(
              (listItem: string) =>
                !this.selectedItems.map((button: HTMLButtonElement) => button.innerText).includes(listItem)
            );
          } else {
            this.configuredOptions = this.configuredOptions.filter(
              (listItem: string) =>
                !this.selectedItems.map((button: HTMLButtonElement) => button.innerText).includes(listItem)
            );
          }

          this.selectedItems.reverse().forEach((selectedItem: HTMLButtonElement) => {
            if (selectedItem.innerText !== this.dragItem || selectedItem.innerText !== this.modifiedDragItem) {
              this.configuredOptions.splice(
                this.configuredOptions.indexOf(this.dragItem) + 1,
                0,
                selectedItem.innerText
              );
            }
          });

          this.configuredOptions = this.configuredOptions.filter((option: string) => option !== this.modifiedDragItem);
        } else if (targetDataAutomation === 'default-options-list' && this.isMultiSelect && this.isMultiDrag) {
          if (sourceDataAutomation === 'available-options-list') {
            this.availableOptions = this.availableOptions.filter(
              (listItem: string) =>
                !this.selectedItems.map((button: HTMLButtonElement) => button.innerText).includes(listItem)
            );
          } else if (sourceDataAutomation === 'configured-options-list') {
            this.configuredOptions = this.configuredOptions.filter(
              (listItem: string) =>
                !this.selectedItems.map((button: HTMLButtonElement) => button.innerText).includes(listItem)
            );
          } else {
            this.defaultOptions = this.defaultOptions.filter(
              (listItem: string) =>
                !this.selectedItems.map((button: HTMLButtonElement) => button.innerText).includes(listItem)
            );
          }

          this.selectedItems.reverse().forEach((selectedItem: HTMLButtonElement) => {
            if (selectedItem.innerText !== this.dragItem) {
              this.defaultOptions.splice(this.defaultOptions.indexOf(this.dragItem) + 1, 0, selectedItem.innerText);
            }
          });

          this.defaultOptions = this.defaultOptions.filter((option: string) => option !== this.modifiedDragItem);
        } else if (
          targetDataAutomation === 'default-options-list' &&
          sourceDataAutomation === 'available-options-list' &&
          !this.isMultiSelect &&
          this.isMultiDrag
        ) {
          // If single select and multiple items are dragged from available to default, automatically drop everything
          // other than the dragged item into the 'show values' column
          this.availableOptions = this.availableOptions.filter(
            (listItem: string) =>
              !this.selectedItems.map((button: HTMLButtonElement) => button.innerText).includes(listItem)
          );
          this.selectedItems.forEach((item) => {
            this.configuredOptions.push(item.innerText);
          });
          this.configuredOptions = this.configuredOptions.filter(
            (listItem: string) => !listItem.includes(this.dragItem)
          );
        }

        this.listChange.emit({
          availableOptions: this.availableOptions,
          configuredOptions: this.configuredOptions,
          defaultOptions: this.defaultOptions,
        });

        this._optionsCache = {
          availableOptions: this.availableOptions,
          configuredOptions: this.configuredOptions,
          defaultOptions: this.defaultOptions,
        };

        this._filterAvailableOptions();
        this._filterConfiguredOptions();
        this._filterDefaultOptions();
      }
      this._clearSelectedItems();
    });
    this._subscriptions.add(drop);

    const dragend = this.dragulaService.dragend(this.DRAGULA_GROUP_NAME).subscribe(() => {
      // clean up classes and elements added
      if (this.isMultiDrag) {
        this.isMultiDrag = false;
        document.querySelectorAll('.in-transit-selected').forEach((button: HTMLButtonElement) => {
          button.classList.remove('in-transit-selected');
        });
        this._clearSelectedItems();
        this.valuesText.innerText = '';
        this.isMultiDrag = false;
      }
      const mirrorImageDrag = document.querySelector('.mirror-image-drag');
      if (mirrorImageDrag) {
        mirrorImageDrag.classList.remove('mirror-image-drag');
      }
      const destination = document.querySelector('.mirror-image-destination');
      if (destination) {
        destination.classList.remove('mirror-image-destination');
      }
    });
    this._subscriptions.add(dragend);
  }
}
