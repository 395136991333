<div data-automation="import-cert-success-modal">
  <div class="modal-header modal-header--bordered d-flex align-items-center justify-content-between">
    <h5 class="mb-0">
      {{ 'tkey;admin.certificate_management.import.modal_success.title' | translate }}
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="onButtonClick()"></button>
  </div>

  <div class="modal-body modal-body--dynamic">
    <p [innerHTML]="'tkey;admin.certificate_management.import.modal_success.prompt' | translate"></p>
    <div>
      <img [style.object-fit]="'cover'" [style.width]="'100%'" alt="deleted objects" [src]="modalProperties.imgPath" />
    </div>
  </div>

  <div class="modal-footer modal-footer--bordered">
    <div class="text-end">
      <smacs-button
        [label]="'tkey;admin.certificate_management.import.modal_success.button'"
        [buttonStyle]="buttonStyles.PRIMARY"
        [buttonSize]="buttonSizes.LARGE"
        (clicked)="onButtonClick()"
        [dataAutomation]="'cert-import-success-button'"
        [icon]="smacsIcons.OK"
      ></smacs-button>
    </div>
  </div>
</div>
