<div [ngClass]="{ 'table-responsive': tableResponsive, 'fixed-height': scrollableTable }">
  <table class="table table-hover" [ngClass]="{ 'align-middle': table.summaryTable }">
    <thead>
      <!-- Headers -->
      <tr data-automation="entity-table-headers" class="d-flex {{ theadRowCssClass }}">
        <th
          *ngFor="let col of table.columns; trackBy: trackByColumns; let i = index"
          [attr.data-automation]="'entity-table-header-' + col.columnId"
          [ngClass]="
            (col.cssColumnSize || '') +
            ' ' +
            (col.cssClass ? col.cssClass : '') +
            (table.isFirstColumnSticky && i === 0 ? ' sticky-column' : '')
          "
          [attr.style]="col.fixedColumnSize"
        >
          <span *ngIf="col.headerSelectAll">
            <smacs-filter-checkbox
              [isCentered]="col.headerSelectAll"
              [value]="headerSelectAllValue"
              [dataAutomation]="'entity-table-select-all-' + col.columnId"
              (filterCheckboxChanged)="onHeaderSelectAllChanged($event)"
              [isIndeterminate]="isIndeterminate"
              [selectAllValue]="headerSelectAllValue"
              [isSelectAll]="true"
            ></smacs-filter-checkbox>
          </span>
          <span>{{ col.label | translate }}</span>
        </th>
        <th
          *ngIf="getHasActions()"
          data-automation="entity-table-header-space"
          [ngClass]="
            table?.cssClearButtonColumnSize
              ? table.cssClearButtonColumnSize
              : table?.cssClearButtonColumnSizeFixed
              ? ''
              : 'col-sm-1'
          "
          [attr.style]="table?.cssClearButtonColumnSizeFixed"
        ></th>
      </tr>
      <!-- Filters -->
      <tr data-automation="entity-table-filters" *ngIf="hasFilters()" class="d-flex">
        <td
          *ngFor="let col of table.columns; trackBy: trackByColumns; last as isLast; let i = index"
          [attr.data-automation]="'entity-table-filter-cell-' + col.columnId"
          [ngClass]="(col.cssColumnSize || '') + (table.isFirstColumnSticky && i === 0 ? ' sticky-column' : '')"
          [attr.style]="col.fixedColumnSize"
        >
          <span *ngIf="col.filter">
            <smacs-filter-input
              *ngIf="col.filter.type === entityTableFilterTypes.TEXT"
              [dataAutomation]="'entity-table-filter-' + col.columnId"
              [asyncText]="col.asyncSearch"
              [clearAllFilter]="isClearAllFilter"
              (filterChanged)="onFilterChanged($event, col.columnId, col.filter.preventTableUpdate, isLast)"
            ></smacs-filter-input>
            <smacs-filter-select
              *ngIf="col.filter.type === entityTableFilterTypes.SELECT"
              [dataAutomation]="'entity-table-filter-' + col.columnId"
              [filterOptions]="col.filter.options"
              [bindValue]="col.filter.bindValue"
              [bindLabel]="col.filter.bindLabel || 'label'"
              [isMultiSelect]="col.filter.isMultiSelect"
              [isGroupSelectable]="col.filter.isGroupSelectable"
              [asyncText]="col.asyncSearch"
              [clearAllFilter]="isClearAllFilter"
              [appendTo]="selectAppendTo"
              (filterChanged)="onFilterChanged($event, col.columnId, col.filter.preventTableUpdate, isLast)"
            ></smacs-filter-select>
            <smacs-filter-date
              *ngIf="col.filter.type === entityTableFilterTypes.DATE"
              [dataAutomation]="'entity-table-filter-' + col.columnId"
              (selectedDate)="onFilterChanged($event, col.columnId, col.filter.preventTableUpdate)"
              [model]="col.filter.defaultDate"
            ></smacs-filter-date>
            <smacs-filter-checkbox
              *ngIf="col.filter.type === entityTableFilterTypes.CHECKBOX"
              [dataAutomation]="'entity-table-filter-' + col.columnId"
              (filterCheckboxChanged)="onFilterChanged($event, col.columnId, col.filter.preventTableUpdate)"
            ></smacs-filter-checkbox>
          </span>
          <div *ngIf="isLast && !getHasActions() && !!tableRows.length" class="w-100 text-end">
            <smacs-button
              [dataAutomation]="'entity-table-filter-clear-button'"
              [label]="'tkey;global.button.clear.text'"
              (clicked)="clearFilters()"
              [buttonStyle]="buttonStyles.DEFAULT"
              [buttonSize]="buttonSizes.SMALL"
              [icon]="smacsIcons.CLEAR"
            ></smacs-button>
          </div>
        </td>
        <td
          *ngIf="getHasActions()"
          [ngClass]="
            table?.cssClearButtonColumnSize
              ? table.cssClearButtonColumnSize
              : table?.cssClearButtonColumnSizeFixed
              ? ''
              : 'col-sm-1'
          "
          [attr.style]="table?.cssClearButtonColumnSizeFixed"
          class="text-end"
        >
          <smacs-button
            [dataAutomation]="'entity-table-filter-clear-button'"
            [label]="'tkey;global.button.clear.text'"
            (clicked)="clearFilters()"
            [buttonStyle]="buttonStyles.DEFAULT"
            [buttonSize]="buttonSizes.SMALL"
            [icon]="smacsIcons.CLEAR"
          ></smacs-button>
        </td>
      </tr>
    </thead>
    <tbody>
      <!-- Content -->
      <ng-container *ngIf="!isRowsLoading">
        <tr
          *ngFor="let row of filteredTableRows; let i = index; trackBy: trackByContent"
          [ngClass]="[
            row.cssClass ? row.cssClass : '',
            hasValidationError(row) ? (row.validationCssClass ? row.validationCssClass : 'table-danger') : ''
          ]"
          class="d-flex"
          data-automation="entity-table-content-row"
        >
          <td
            *ngFor="let col of table.columns; trackBy: trackByColumns; let cellIndex = index"
            [attr.data-automation]="'entity-table-content-' + col.columnId"
            [ngClass]="
              (col.cssColumnSize || '') +
              ' ' +
              (col.cssClass ? col.cssClass : '') +
              (table.summaryTable ? 'align-content-center' : '') +
              (table.isFirstColumnSticky && cellIndex === 0 ? ' sticky-column' : '')
            "
            [attr.class]="verticalAlignContent ? 'd-flex align-items-center' : ''"
            #tooltipTrigger="ngbTooltip"
            [ngbTooltip]="tooltipContent"
            [disableTooltip]="!row?.tooltip?.[col.columnId]?.content || row?.tooltip?.[col.columnId]?.isDisabled"
            [container]="'body'"
            [positionTarget]="positionTarget"
            [triggers]="'manual'"
            (mouseenter)="tooltipTrigger.open()"
            (mouseleave)="tooltipTrigger.close()"
            [attr.style]="col.fixedColumnSize"
          >
            <ng-template #tooltipContent>
              <div *ngIf="row.tooltip[col.columnId]?.isList" class="d-flex flex-column">
                <span *ngFor="let number of row.tooltip[col.columnId]?.content" class="text-nowrap mw-100">
                  {{ number }}
                </span>
              </div>
              <span
                [attr.data-automation]="'entity-table-tooltip'"
                [innerHTML]="row?.tooltip?.[col.columnId]?.content | translate : row?.tooltip?.[col.columnId]?.params"
              ></span>
            </ng-template>

            <span #positionTarget>
              <span
                *ngIf="row.html && row.html[col.columnId] && !(row.fields && row.fields[col.columnId])"
                [innerHTML]="row.html[col.columnId] | translate"
              ></span>

              <span
                *ngIf="
                  !(row.html && row.html[col.columnId]) &&
                  !(row.fields && row.fields[col.columnId]) &&
                  !(row.badges && row.badges[col.columnId])
                "
                [ngClass]="{ 'prevent-wrap': row.preventWrap && row.preventWrap[col.columnId] }"
              >
                {{ row.content[col.columnId] | translate }}
              </span>

              <span *ngIf="row.fields && row.fields[col.columnId]" class="entity-table-fields w-100">
                <smacs-button
                  *ngIf="row.fields[col.columnId].type === entityTableFieldTypes.BUTTON"
                  [label]="getButtonField(row.fields[col.columnId]).label"
                  [buttonStyle]="getButtonField(row.fields[col.columnId]).buttonStyle"
                  [dataAutomation]="'entity-table-content-button-' + col.columnId"
                  [icon]="getButtonField(row.fields[col.columnId])?.icon"
                  (clicked)="onFieldClicked(row, col.columnId, getButtonField(row.fields[col.columnId]).contentValue)"
                ></smacs-button>
                <div
                  *ngIf="row.fields[col.columnId].type === entityTableFieldTypes.CHECKBOX"
                  [attr.data-automation]="'entity-table-content-checkbox-' + col.columnId + '-' + i"
                  class="form-check"
                  [ngbTooltip]="!row.tooltip ? null : (row.tooltip[col.columnId]?.content | translate)"
                  [disableTooltip]="!row.tooltip ? null : row.tooltip[col.columnId]?.isDisabled"
                  [container]="'body'"
                  [ngClass]="{ 'd-flex justify-content-center': col.headerSelectAll }"
                >
                  <input
                    type="checkbox"
                    class="form-check-input"
                    [attr.id]="'entity-table-content-checkbox-' + col.columnId + '-' + i"
                    [(ngModel)]="getCheckboxField(row.fields[col.columnId]).value"
                    (ngModelChange)="onFieldChanged($event, row, col.columnId)"
                    [ngModelOptions]="{ standalone: true }"
                    [disabled]="row.fields[col.columnId]?.disabled"
                  />
                  <label
                    class="form-check-label"
                    [attr.for]="'entity-table-content-checkbox-' + col.columnId + '-' + i"
                  ></label>
                </div>
                <span
                  *ngIf="row.fields[col.columnId].type === entityTableFieldTypes.TEXT"
                  [attr.data-automation]="'entity-table-content-text-' + col.columnId + '-' + i"
                  class="d-flex align-items-center"
                >
                  <div
                    [attr.data-automation]="'text-input-placeholder'"
                    class="text-input-placeholder w-100 d-flex justify-content-between py-2 px-1"
                    [ngClass]="{
                      'text-input-placeholder--active': !row.fields[col.columnId].isDisabled,
                      'text-input-placeholder--disabled': row.fields[col.columnId].isDisabled
                    }"
                    *ngIf="row.fields[col.columnId].showPlaceholderMarkup"
                    (click)="toggleTextPlaceholderDisplay(row.fields[col.columnId])"
                  >
                    <span [innerHTML]="row.fields[col.columnId].placeholderMarkup"></span>
                    <i class="icon-edit pe-1"></i>
                  </div>
                  <input
                    [attr.data-automation]="'editable-cell-text-input'"
                    #textInput
                    smacsAutoSelect
                    *ngIf="!row.fields[col.columnId].showPlaceholderMarkup"
                    type="text"
                    class="text-input w-100 px-1"
                    [attr.id]="'entity-table-content-text-' + col.columnId + '-' + i"
                    [(ngModel)]="getTextField(row.fields[col.columnId]).value"
                    (ngModelChange)="onFieldChanged($event, row, col.columnId, {}, i)"
                    [ngModelOptions]="{ standalone: true }"
                    (blur)="row.fields[col.columnId].onBlur(row.fields[col.columnId], i)"
                    (keyup.enter)="row.fields[col.columnId].onEnter(row.fields[col.columnId], i)"
                  />
                  <label
                    class="form-check-label"
                    [attr.for]="'entity-table-content-checkbox-' + col.columnId + '-' + i"
                  ></label>
                </span>
                <div
                  *ngIf="row.fields[col.columnId].type === entityTableFieldTypes.SELECT"
                  class="entity-table-fields-select-container"
                  [ngClass]="{
                    'has-error': row.fields[col.columnId].validation && row.fields[col.columnId].validation.hasError
                  }"
                >
                  <i [ngClass]="'icon me-1 ' + getSelectField(row.fields[col.columnId]).icon"></i>
                  <smacs-filter-select
                    [hidden]="getSelectField(row.fields[col.columnId]).isHidden === true"
                    [dataAutomation]="'entity-table-content-select'"
                    [filterOptions]="getSelectField(row.fields[col.columnId]).options"
                    [asyncOptionsFn]="getSelectField(row.fields[col.columnId]).asyncOptionsFn"
                    [filterValue]="row.fields[col.columnId].value?.value ?? row.fields[col.columnId].value"
                    [isClearable]="false"
                    [appendTo]="selectAppendTo"
                    (filterChanged)="onFieldChanged($event, row, col.columnId, {}, i)"
                    [bindValue]="row.fields[col.columnId]?.bindValue || null"
                    [showOptionsIcons]="row.fields[col.columnId]?.showOptionsIcons"
                    [isDisabled]="row.fields[col.columnId]?.isDisabled"
                  ></smacs-filter-select>
                  <span
                    [hidden]="!getSelectField(row.fields[col.columnId]).isHidden"
                    class="entity-table-select-edit"
                    (click)="row.fields[col.columnId].onToggle(row.fields[col.columnId], i)"
                  >
                    <span
                      *ngIf="row.html && row.html[col.columnId]"
                      [innerHTML]="row.html[col.columnId] | translate"
                    ></span>
                    <span *ngIf="row.html && !row.html[col.columnId]">{{ row.content[col.columnId] }}</span>

                    <smacs-button
                      [buttonStyle]="buttonStyles.NONE"
                      [buttonSize]="buttonSizes.SMALL"
                      [dataAutomation]="'entity-table-select-filter-button'"
                      [icon]="smacsIcons.EDIT"
                      [cssClass]="'ms-1 my-0 py-0'"
                    ></smacs-button>
                  </span>
                </div>

                <span
                  *ngIf="row.fields[col.columnId].validation && row.fields[col.columnId].validation.hasError"
                  class="small has-error"
                  data-automation="entity-table-content-validation-message"
                  [innerHTML]="row.fields[col.columnId].validation.message | translate"
                ></span>
              </span>
              <span *ngIf="row.badges && row.badges[col.columnId]">
                <smacs-entity-table-badge
                  *ngFor="let badgeValue of row.badges[col.columnId].values; let idx = index"
                  [value]="badgeValue"
                  [maxLength]="row.badges[col.columnId].maxLength"
                  [badgeIcon]="!!row.badges[col.columnId].badgeIcon ? row.badges[col.columnId].badgeIcon[idx] : ''"
                  [badgeCssClass]="
                    !!row.badges[col.columnId].badgeCssClass ? row.badges[col.columnId].badgeCssClass[idx] : ''
                  "
                  [tooltip]="!!row.badges[col.columnId].tooltips ? row.badges[col.columnId].tooltips[idx] : ''"
                  [displayToolTip]="row.badges[col.columnId].displayToolTip"
                ></smacs-entity-table-badge>
              </span>
            </span>
          </td>
          <td
            *ngIf="getHasActions()"
            data-automation="entity-table-content-actions"
            [ngClass]="[
              table?.cssClearButtonColumnSize
                ? table.cssClearButtonColumnSize
                : table?.cssClearButtonColumnSizeFixed
                ? ''
                : 'col-sm-1',
              table.summaryTable ? 'align-items-center' : ''
            ]"
            [attr.class]="
              verticalAlignContent
                ? 'd-flex align-items-center justify-content-end entity-table-actions'
                : 'd-flex justify-content-end entity-table-actions'
            "
            [attr.style]="table?.cssClearButtonColumnSizeFixed"
          >
            <!-- Actions -->
            <div>
              <smacs-button
                *ngFor="let action of row.actions; trackBy: trackByColumns"
                [cssClass]="table.summaryTable ? 'btn-sm ms-1' : 'btn-xs ms-1'"
                [label]="action.label ? action.label : null"
                [buttonStyle]="action.buttonStyle"
                [buttonSize]="action.buttonSize"
                [dataAutomation]="action.dataAutomation"
                [icon]="action?.icon"
                [isDisabled]="action.isDisabled"
                [tooltip]="action.tooltip"
                [isTooltipDisabled]="action.tooltipDisabled"
                (clicked)="action.onClick(row)"
                [hidden]="action.isHidden"
                [isPending]="action.isPending"
                [iconPending]="action.iconPending"
                [type]="action?.buttonType"
                [href]="action?.buttonLinkHref"
              ></smacs-button>
            </div>
          </td>
        </tr>
      </ng-container>

      <!-- No results found -->
      <tr *ngIf="!tableRows.length && !isViewLoading" class="d-flex" data-automation="entity-table-no-results">
        <td
          *ngIf="!showCustomAlert"
          class="text-center col-12"
          [innerHTML]="
            table.resultsMessage
              ? (table.resultsMessage | translate)
              : ('tkey;global.search.no_results_alt.text' | translate)
          "
        ></td>
        <td *ngIf="showCustomAlert" class="text-center table-danger col-12">
          <ng-content select="[alert-content]"></ng-content>
        </td>
      </tr>

      <!-- No filtered results found -->
      <tr
        *ngIf="tableRows !== undefined && tableRows.length && !filteredTableRows?.length"
        class="d-flex"
        data-automation="entity-table-no-filtered-results"
      >
        <td
          class="text-center col-12"
          [innerHTML]="
            table.filteredResultsMessage
              ? (table.filteredResultsMessage | translate)
              : ('tkey;global.search.no_results.text' | translate)
          "
        ></td>
      </tr>
      <tr
        *ngIf="isViewLoading || isRowsLoading"
        class="d-flex align-items-center"
        [attr.data-automation]="'skeleton-loader'"
      >
        <td
          *ngFor="let col of table.columns; trackBy: trackByColumns"
          [ngClass]="col.cssColumnSize || ''"
          [attr.style]="col.fixedColumnSize"
        >
          <ngx-skeleton-loader [animation]="'progress-dark'"></ngx-skeleton-loader>
        </td>
        <td
          *ngIf="getHasActions()"
          [ngClass]="
            table?.cssClearButtonColumnSize
              ? table.cssClearButtonColumnSize
              : table?.cssClearButtonColumnSizeFixed
              ? ''
              : 'col-sm-1'
          "
          [attr.style]="table?.cssClearButtonColumnSizeFixed"
        >
          <ngx-skeleton-loader [animation]="'progress-dark'"></ngx-skeleton-loader>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<smacs-pagination
  *ngIf="table.pagination"
  [(page)]="page"
  [pageSize]="table.pagination.pageSize"
  [totalResults]="totalResults"
  [dataAutomation]="'entity-table-pagination'"
  (pageChanged)="onPageChange()"
></smacs-pagination>
