import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModalBodyClass } from '../../modals/modal-body';
import { Observable } from 'rxjs';
import { SmacsFormConfig } from '../../forms/smacs-forms-models';
import { DetailedModalComponent } from '../../modals/detailed-modal/detailed-modal.component';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { DirectoryNumberRef, EndUser, TranslationPatternRef } from '../../shared/models/generated/smacsModels';
import { ToastService } from '../../shared/services/toast.service';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { TranslateService } from '@ngx-translate/core';
import { SmacsDirectoryNumberFormComponent } from '../cisco/directory-number/directory-number-form.component';
import { catchError } from 'rxjs/operators';
import { SmacsModalService } from '../../shared/services/smacs-modal.service';
import { ToastTypes } from '../../shared/services/abstract/toast.service.abstract';

export interface DirectoryNumberUpdate {
  dnRef: DirectoryNumberRef;
  translationPatterns: TranslationPatternRef;
  endUser: EndUser;
}

@Component({
  selector: 'smacs-directory-number-detailed-modal',
  templateUrl: 'directory-number-detailed-modal.component.html',
  styleUrls: ['./directory-number-detailed-modal.component.scss'],
})
export class SmacsDirectoryNumberDetailedModalComponent extends ModalBodyClass implements OnInit {
  @ViewChild(SmacsDirectoryNumberFormComponent) directoryNumberForm: SmacsDirectoryNumberFormComponent;
  @ViewChild('errorTemplate') errorTemplate: TemplateRef<any>;
  areFormsValid = true;
  isSaving = false;
  smacsIcons = SmacsIcons;
  modalProperties: any;
  areFormsValidSet = false;

  constructor(
    private modalComponent: DetailedModalComponent<any>,
    protected smacsFormStateService: SmacsFormStateService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private smacsModalService: SmacsModalService
  ) {
    super(smacsFormStateService);
  }

  formConfig = {
    fields: {},
  } as SmacsFormConfig;

  ngOnInit() {
    this.modalProperties = this.modalComponent.modalViewProperties;
  }

  isFormValid() {
    this.areFormsValid = this.directoryNumberForm.areAllFormsValid();
    if (!this.areFormsValidSet) {
      this.directoryNumberForm.setFormsSubmittingState(true);
      this.areFormsValidSet = true;
    }
    return this.areFormsValid;
  }

  submit(): Observable<any> {
    this.smacsFormStateService.isSaveDisabled.next(true);
    const modalObs$ = new Observable<DirectoryNumberUpdate>((subscriber) => {
      this.directoryNumberForm.checkDnForExistingVoicemail();
      this.directoryNumberForm.isSaveComplete$.subscribe((isComplete) => {
        if (isComplete) {
          subscriber.next({
            dnRef: this.directoryNumberForm.dnRef,
            translationPatterns: this.directoryNumberForm.tpRef,
            endUser: this.directoryNumberForm.endUserUpdate.endUser,
          });
          subscriber.complete();
          this.toastService.push(
            ToastTypes.SUCCESS,
            this.smacsIcons.HASH,
            'tkey;shared.toast.save.success.title',
            `${this.translateService.instant('tkey;shared.service.extension.text')} -
                ${this.directoryNumberForm.dnDidSelectorFormEntity.directoryNumber.extension}`
          );
        }
      });
    });

    return modalObs$.pipe(
      catchError((err) => {
        this.smacsFormStateService.isSaveDisabled.next(false);
        throw err;
      })
    );
  }

  isLoadingDone() {
    this.smacsFormStateService.isSaveDisabled.next(false);
  }

  onCancelClicked(isClicked: boolean) {
    if (isClicked) {
      this.modalComponent.resetButtonState();
    }
  }
}
