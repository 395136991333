<app-loading-block [hidden]="!isSaving || modalProperties"></app-loading-block>
<div data-automation="smacs-voicemail-form" class="container-fluid" *ngIf="!isSaving || modalProperties">
  <smacs-text *ngFor="let fieldId of fieldGroups.textInputs" [fieldId]="fieldId"></smacs-text>
  <smacs-select [fieldId]="'voicemailTemplate'"></smacs-select>
  <smacs-multi-text [fieldId]="'alternateExtensions'"></smacs-multi-text>
  <smacs-text [fieldId]="'smtpNotificationDevice'"></smacs-text>
</div>
<div class="py-2 px-3 d-flex justify-content-end align-items-center">
  <div *ngIf="!username && !modalProperties">
    <smacs-button
      class="me-1"
      [dataAutomation]="'delete-voicemail-button'"
      (click)="handleDeleteClick()"
      [isDisabled]="isPending || !isExistingVoicemail"
      [iconPending]="smacsIcons.SAVING"
      [isPending]="isDeleting"
      [buttonStyle]="buttonStyles.DANGER"
      [label]="'tkey;dialogs.button.delete'"
    ></smacs-button>
    <smacs-submit-button
      [isPending]="isPending"
      [isDisabled]="!isFormValid() || isPending"
      [label]="'tkey;global.button.save.text'"
      [dataAutomation]="'save-voicemail-button'"
      [icon]="smacsIcons.OK"
      [iconPending]="smacsIcons.SAVING"
      [formSubmitSubject]="_validateAndSubmitSource"
    ></smacs-submit-button>
  </div>
</div>
