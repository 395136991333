<app-loading-block [hidden]="isDisplayForm"></app-loading-block>
<div [hidden]="!isDisplayForm" class="container-fluid">
  <div class="row">
    <div
      [innerHtml]="
        'tkey;extension_selector.dial_plan_group_not_found.message'
          | translate : { extension: teamsLine?.lineUri | telephoneNumberFilter }
      "
      *ngIf="
        isDialPlanGroupNotFoundForExistingLine &&
        !entity?.extension &&
        voicePolicies.sharedCallRoutingPolicy.policyName === 'Global'
      "
      class="alert alert-warning col-12"
    ></div>
    <div
      [innerHtml]="
        'tkey;microsoft_360.view.teams.extensionSelection.shared_calling.error.no_dpg'
          | translate : { policyName: voicePolicies.sharedCallRoutingPolicy.policyName }
      "
      *ngIf="isDialPlanGroupNotFoundForExistingLine && voicePolicies.sharedCallRoutingPolicy.policyName !== 'Global'"
      class="alert alert-warning col-12"
      data-automation="shared-calling-no-dial-plan-found-alert"
    ></div>
    <div
      [innerHtml]="'tkey;microsoft_360.view.teams.nodialplans.configured.alert' | translate"
      *ngIf="microsoftDialPlanGroups.length === 0"
      class="alert alert-warning col-12"
    ></div>

    <div class="col-6">
      <smacs-text [fieldId]="'dialPlanGroupId'"></smacs-text>
      <div [ngClass]="{ 'has-feedback smacs-forms-error': isSubmitted && !selectedDialPlanGroup }">
        <label class="mb-2">
          <span class="text-danger">*</span>
          {{ 'tkey;microsoft_360.view.teams.extensionSelection.dialplan.group' | translate }}
        </label>
        <ng-select
          id="dialPlanNameSelect"
          [labelForId]="'dialPlanNameSelect'"
          data-automation="ms-teams-calling-dial-plan-select"
          [items]="microsoftDialPlanGroups"
          bindLabel="name"
          [(ngModel)]="selectedDialPlanGroup"
          [clearable]="false"
          (ngModelChange)="onDialPlanGroupChange()"
        ></ng-select>
      </div>

      <div
        *ngIf="selectedDialPlanGroup?.callingType === CallingType.EXTENSION"
        data-automation="selected-group-main-number-alert"
        class="alert alert-info mt-3"
      >
        <h4 data-automation="selected-group-main-number-alert-number" class="mb-0">
          <strong>
            {{ 'tkey;microsoft_360.view.teams.extensionSelection.main_number.label' | translate }}
            {{ selectedDialPlanGroup?.mainNumber | telephoneNumberFilter }}
          </strong>
        </h4>
        <p class="mb-0">{{ 'tkey;microsoft_360.view.teams.extensionSelection.main_number.message' | translate }}</p>
      </div>

      <div
        *ngIf="selectedDialPlanGroup?.callingType === CallingType.SHARED_CALLING && isDisplaySharedCallingAlert"
        data-automation="selected-group-shared-calling-alert"
        class="alert mt-3"
        [ngClass]="{
          'alert-info': dialPlanGroupSharedCallingPolicy,
          'alert-danger': !dialPlanGroupSharedCallingPolicy
        }"
      >
        <h4
          *ngIf="selectedDialPlanGroup?.mainNumber"
          data-automation="selected-group-shared-calling-alert-number"
          class="mb-3"
        >
          <strong>
            {{ 'tkey;microsoft_360.view.teams.extensionSelection.shared_calling.label' | translate }}
            {{ selectedDialPlanGroup?.mainNumber | telephoneNumberFilter }}
          </strong>
        </h4>
        <p
          *ngIf="dialPlanGroupSharedCallingPolicy"
          class="mb-0"
          [innerHTML]="
            'tkey;microsoft_360.view.teams.extensionSelection.shared_calling.message'
              | translate
                : {
                    policyName: dialPlanGroupSharedCallingPolicy.policyName,
                    resourceAccountName: dialPlanGroupSharedCallingPolicy.resourceAccountDisplayName
                  }
          "
        ></p>
        <p
          *ngIf="!dialPlanGroupSharedCallingPolicy"
          class="mb-0"
          [innerHTML]="'tkey;microsoft_360.view.teams.extensionSelection.shared_calling.error.no_number' | translate"
        ></p>
      </div>
    </div>

    <div
      [hidden]="
        !selectedDialPlanGroup ||
        selectedDialPlanGroup?.callingType === CallingType.SHARED_CALLING ||
        licenseIncompatibleAlert
      "
      class="col-6 pt-4"
    >
      <div class="card">
        <div class="card-header">
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="ms-extension-show-unavailable"
              data-automation="ms-unavailable-input"
              [(ngModel)]="showUnavailable"
              (ngModelChange)="applyComponentConfig()"
              [disabled]="isSubmitting"
            />
            <label
              class="form-check-label"
              [attr.data-automation]="'ms-unavailable-input-label'"
              for="ms-extension-show-unavailable"
            >
              <i class="{{ smacsIcons.UNAVAILABLE }} text-danger"></i>
              {{ 'tkey;microsoft_360.view.teams.extensionSelection.show_unavailable.label' | translate }}
            </label>
          </div>
          <div class="form-check">
            <input
              type="checkbox"
              class="form-check-input"
              id="ms-extension-show-recently-deleted"
              data-automation="ms-extension-show-recently-deleted-input"
              [(ngModel)]="showRecentlyDeleted"
              (ngModelChange)="applyComponentConfig()"
              [disabled]="isSubmitting"
            />
            <label
              class="form-check-label"
              [attr.data-automation]="'ms-show-recently-deleted-input-label'"
              for="ms-extension-show-recently-deleted"
            >
              <i [attr.class]="smacsIcons.HISTORY"></i>
              {{ 'tkey;microsoft_360.view.teams.extensionSelection.show_revently_deleted.label' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-loading-block
    [hidden]="!isSearching || isDialPlanGroupNotFoundForExistingLine"
    data-automation="microsoft-teams-dial-plan-inventory-loading"
  ></app-loading-block>

  <div
    *ngIf="licenseIncompatibleAlert"
    data-automation="teams-calling-incompatible-license-alert"
    class="alert alert-danger mt-3"
  >
    {{ licenseIncompatibleAlert | translate }}
  </div>

  <div
    [hidden]="
      isSearching ||
      !selectedDialPlanGroup ||
      selectedDialPlanGroup?.callingType === CallingType.SHARED_CALLING ||
      licenseIncompatibleAlert
    "
  >
    <div class="row mt-2" *ngIf="isZpcSyncWarningPresent" data-automation="sync-warning-alert">
      <div class="col-12">
        <div class="alert alert-warning mb-0">
          <strong>{{ 'tkey;extension_selector.sync.warning.zpm' | translate }}</strong>
        </div>
      </div>
    </div>
    <div data-automation="teams-calling-extension-selector">
      <smacs-extension-selector class="col-12" [fieldId]="'extension'" [isHosted]="true"></smacs-extension-selector>
    </div>
  </div>
  <app-loading-block
    [hidden]="(showValue && !isNumberLocationValidationInProgress) || isDialPlanGroupNotFoundForExistingLine"
  ></app-loading-block>
  <smacs-ms-teams-calling-form
    *ngIf="
      !isSearching &&
      ((entity?.extension && this.selectedDialPlanGroup?.callingType !== CallingType.SHARED_CALLING) ||
        dialPlanGroupSharedCallingPolicy) &&
      showValue &&
      !licenseIncompatibleAlert &&
      !isNumberLocationValidationInProgress &&
      !hasExtensionError
    "
    [teamsLine]="teamsLine"
    [voicePolicies]="voicePolicies"
    [upn]="upn"
    [onPremUpn]="onPremUpn"
    [lineUri]="entity?.extension"
    [mainNumber]="selectedDialPlanGroup?.mainNumber"
    [phoneNumberAssignmentFieldConfig]="phoneNumberAssignmentFieldConfig"
    [voicePoliciesFieldConfig]="voicePoliciesFieldConfig"
    [bandwidthFieldConfig]="bandwidthEmergencyCallingFieldConfig"
    [bandwidthEntity]="bandwidthEntity"
    [initialBandwidthEndpointId]="initialBandwidthEndpointId"
    [isExtensionChanged]="isExtensionChanged"
    [dialPlanFieldConfig]="selectedDialPlanGroup"
    [isBandwidthConfiguredWithoutE911Dlr]="isBandwidthConfiguredWithoutE911Dlr"
    [isResourceAccount]="isResourceAccount"
    [isZpcSyncWarningPresent]="isZpcSyncWarningPresent"
    [isAdWriteDownConfigured]="isAdWriteDownConfigured"
    [initialZiroDraasSharedCallingExtension]="initialZiroDraasSharedCallingExtension"
    [initialLineUri]="initialLineUri"
    [initialDialPlanGroup]="initialDialPlanGroup"
    [sharedCallingPolicy]="dialPlanGroupSharedCallingPolicy"
    [isExistingSharedCalling]="isExistingSharedCalling"
  ></smacs-ms-teams-calling-form>
</div>
