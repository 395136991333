import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { DatatableColumn, DatatableRow } from '../../datatable/datatable.component';
import { MicrosoftDialPlanInventoryReportResource } from './dial-plan-inventory-report.resource';
import { JobState, JobStatus, MicrosoftDialPlanInventorySummary } from '../../../shared/models/generated/smacsModels';
import { filter } from 'rxjs/operators';
import { MicrosoftDialPlanInventoriesPollingService } from './dial-plan-inventory-report-polling.service';
import { DateAgoPipe } from '../../../shared/pipes/date-ago.pipe';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { AuthenticationContext } from '../../../shared/contexts/authentication.context';
import { isArray } from 'lodash';

interface MicrosoftDialPlanInventoryRow extends DatatableRow {
  groupName: string;
  percentAvailable: number;
  available: string;
}

enum InventoryThresholds {
  high = 10,
  low = 0,
}

interface InventoryModel {
  high: string;
  low: string;
  empty: string;
}

enum InventoryStatus {
  HIGH = 'tkey;reporting.microsoft.dial_plan_inventory.inventory_status.high',
  LOW = 'tkey;reporting.microsoft.dial_plan_inventory.inventory_status.low',
  EMPTY = 'tkey;reporting.microsoft.dial_plan_inventory.inventory_status.empty',
}

@Component({
  selector: 'app-microsoft-dial-plan-inventory-report',
  templateUrl: './dial-plan-inventory-report.component.html',
  styleUrls: ['../../reporting.scss'],
  providers: [MicrosoftDialPlanInventoryReportResource, MicrosoftDialPlanInventoriesPollingService],
})
export class MicrosoftDialPlanInventoryReportComponent implements OnInit, OnDestroy {
  private static _refreshButtonId = 'refreshReport';
  private static _exportButtonId = 'exportReport';

  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  isLoading = true;
  tableDataAutomation = 'dial-plan-inventory-datatable';
  datatableRows = [] as MicrosoftDialPlanInventoryRow[];
  availabilityFilterOptionsTexts: string[] = [];
  inventoryFilterOptions = {
    high: null,
    low: null,
    empty: null,
  } as InventoryModel;
  datatableCols: DatatableColumn<MicrosoftDialPlanInventoryRow>[] = [
    {
      name: 'groupName',
      label: 'tkey;reporting.microsoft.dial_plan_inventory.group_name.label',
    },
    {
      name: 'available',
      label: 'tkey;reporting.microsoft.dial_plan_inventory.available_numbers.label',
      sortFn: (a: string, b: string) => {
        // a and b are html <span>'s so need to extract the numbers:
        const numberA = Number(a.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '').split('%')[0]);
        const numberB = Number(b.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '').split('%')[0]);
        if (numberA < numberB) {
          return -1;
        } else if (numberA > numberB) {
          return 1;
        }
        return 0;
      },
    },
  ];

  private _subs = new Subscription();

  constructor(
    private translateService: TranslateService,
    private _bottomNavService: BottomNavService,
    private _breadcrumbsService: BreadcrumbsService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _toastService: ToastService,
    private _smacsModalService: SmacsModalService,
    private _microsoftDialPlanInventoryReportResource: MicrosoftDialPlanInventoryReportResource,
    private _microsoftDialPlanInventoriesPollingService: MicrosoftDialPlanInventoriesPollingService,
    private _dateAgoPipe: DateAgoPipe,
    private _authenticationContext: AuthenticationContext
  ) {}

  ngOnInit() {
    this._initFilterOptions();
    this._initBreadcrumbs();
    this._initBottomNav();
    this._getDialPlanInventories();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
    this._microsoftDialPlanInventoriesPollingService.stopPolling();
  }

  onRefreshClick() {
    this.isLoading = true;
    this._setPending(MicrosoftDialPlanInventoryReportComponent._refreshButtonId, true);
    this._setDisabled(MicrosoftDialPlanInventoryReportComponent._exportButtonId, true);
    this._refreshAndPollDialPlanInventories();
  }

  onExportClick() {
    this._setPending(MicrosoftDialPlanInventoryReportComponent._exportButtonId, true);
    this._setDisabled(MicrosoftDialPlanInventoryReportComponent._refreshButtonId, true);

    this._microsoftDialPlanInventoryReportResource.export().subscribe(() => {
      this._setPending(MicrosoftDialPlanInventoryReportComponent._exportButtonId, false);
      this._setDisabled(MicrosoftDialPlanInventoryReportComponent._refreshButtonId, false);
      this._toastService.push(
        ToastTypes.SUCCESS,
        this.smacsIcons.DOWNLOAD,
        'tkey;reporting.xlsx_export.downloaded_toast.title',
        'tkey;reporting.xlsx_export.downloaded_toast.message'
      );
    });
  }

  isAvailableNumbersMatch(filters: string[] | string, row: MicrosoftDialPlanInventoryRow): boolean {
    let isMatch = false;
    if (!filters.length) {
      isMatch = true;
    } else if (isArray(filters)) {
      if (row.percentAvailable <= InventoryThresholds.low) {
        isMatch = filters.some((val: string) =>
          this.translateService.instant(val).includes(this.translateService.instant(InventoryStatus.EMPTY))
        );
      } else if (row.percentAvailable > InventoryThresholds.low && row.percentAvailable < InventoryThresholds.high) {
        isMatch = filters.some((val: string) =>
          this.translateService.instant(val).includes(this.translateService.instant(InventoryStatus.LOW))
        );
      } else if (row.percentAvailable >= InventoryThresholds.high) {
        isMatch = filters.some((val: string) =>
          this.translateService.instant(val).includes(this.translateService.instant(InventoryStatus.HIGH))
        );
      }
    }
    return isMatch;
  }

  private _getDialPlanInventories() {
    this._microsoftDialPlanInventoryReportResource.status().subscribe((jobStatus: JobStatus) => {
      if (jobStatus.jobState === JobState.IDLE) {
        if (jobStatus.lastRunTime) {
          this._setBottomNavTimestamp(jobStatus.lastRunTime);
          this._microsoftDialPlanInventoryReportResource
            .get()
            .subscribe((data: MicrosoftDialPlanInventorySummary[]) => {
              this._initDatatable(data);
              this.isLoading = false;
            });
        } else {
          this._refreshAndPollDialPlanInventories();
        }
      } else {
        this._pollForIdleStatus().subscribe(() => {
          this._microsoftDialPlanInventoriesPollingService.stopPolling();
          this._microsoftDialPlanInventoryReportResource
            .get()
            .subscribe((data: MicrosoftDialPlanInventorySummary[]) => {
              this._initDatatable(data);
              this.isLoading = false;
            });
        });
      }
    });
  }

  private _refreshAndPollDialPlanInventories() {
    this._microsoftDialPlanInventoryReportResource.refresh().subscribe(() => {
      this._pollForIdleStatus().subscribe(() => {
        this._microsoftDialPlanInventoriesPollingService.stopPolling();
        this._microsoftDialPlanInventoryReportResource.get().subscribe((data: MicrosoftDialPlanInventorySummary[]) => {
          this._initDatatable(data);
          this._toastService.push(
            ToastTypes.SUCCESS,
            this.smacsIcons.REFRESH,
            'tkey;reporting.microsoft.dial_plan_inventory.toast.title',
            'tkey;reporting.microsoft.dial_plan_inventory.toast.message'
          );
          this._setPending(MicrosoftDialPlanInventoryReportComponent._refreshButtonId, false);
          this._setDisabled(MicrosoftDialPlanInventoryReportComponent._exportButtonId, false);
          this.isLoading = false;
        });
      });
    });
  }

  private _pollForIdleStatus(): Observable<JobStatus> {
    this._microsoftDialPlanInventoriesPollingService.startPolling();
    return this._microsoftDialPlanInventoriesPollingService.state$.pipe(
      filter((jobStatus: JobStatus) => {
        if (jobStatus.jobState === JobState.IDLE) {
          this._setBottomNavTimestamp(jobStatus.lastRunTime);
          return true;
        }
        return false;
      })
    );
  }

  private _initDatatable(data: MicrosoftDialPlanInventorySummary[]) {
    this.datatableRows = data.map((d: MicrosoftDialPlanInventorySummary) => this._resolveDialPlanGroupInventory(d));
  }

  private _resolveDialPlanGroupInventory(
    dialPlanGroupInventory: MicrosoftDialPlanInventorySummary
  ): MicrosoftDialPlanInventoryRow {
    const { groupName, availableNumbers, unavailableNumbers } = dialPlanGroupInventory;
    const total = availableNumbers + unavailableNumbers;
    const percentAvailable = total === 0 ? 0 : Math.round((availableNumbers / total) * 100);

    let rowCssClass = 'table-warning';
    if (percentAvailable <= InventoryThresholds.low) {
      rowCssClass = 'table-danger';
    } else if (percentAvailable >= InventoryThresholds.high) {
      rowCssClass = '';
    }

    return {
      groupName,
      available: this.translateService.instant('tkey;reporting.microsoft.dial_plan_inventory.available.text', {
        available: availableNumbers,
        total: total,
        percent: percentAvailable,
      }),
      percentAvailable: percentAvailable,
      cssClass: rowCssClass,
    } as MicrosoftDialPlanInventoryRow;
  }

  private _initFilterOptions() {
    this.inventoryFilterOptions = {
      high: this.translateService.instant(InventoryStatus.HIGH),
      low: this.translateService.instant(InventoryStatus.LOW),
      empty: this.translateService.instant(InventoryStatus.EMPTY),
    } as InventoryModel;
    this.availabilityFilterOptionsTexts = Object.values(this.inventoryFilterOptions);
  }

  private _initBreadcrumbs() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;reporting.microsoft.dial_plan_inventory.title' }]);
  }

  private _initBottomNav() {
    const bottomNavButtons: BottomNavButton[] = [
      {
        id: MicrosoftDialPlanInventoryReportComponent._refreshButtonId,
        dataAutomation: MicrosoftDialPlanInventoryReportComponent._refreshButtonId,
        label: 'tkey;reporting.microsoft.dial_plan_inventory.refresh_dial_plan_data',
        icon: this.smacsIcons.REFRESH,
        buttonClass: this.buttonStyles.DEFAULT,
        cb: () => {
          this.onRefreshClick();
        },
      },
      {
        id: MicrosoftDialPlanInventoryReportComponent._exportButtonId,
        dataAutomation: MicrosoftDialPlanInventoryReportComponent._exportButtonId,
        label: 'tkey;reporting.microsoft.dial_plan_inventory.export_to_excel',
        icon: this.smacsIcons.EXPORT,
        buttonClass: this.buttonStyles.PRIMARY,
        cb: () => {
          this.onExportClick();
        },
      },
    ];

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _setBottomNavTimestamp(timestamp: string) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
        helpText: `
              <i class="${this.smacsIcons.CLOCK}"></i>
              ${this.translateService.instant(
                'tkey;reporting.microsoft.dial_plan_inventory.last_updated'
              )} <strong>${this._dateAgoPipe.transform(timestamp)}</strong>
            `,
      })
    );
  }

  private _setPending(id: string, setting: boolean) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: id,
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  private _setDisabled(id: string, setting: boolean) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: id,
        state: {
          pending: false,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }
}
