import { Pipe, PipeTransform } from '@angular/core';
import { DatatableColumn } from './datatable.component';

@Pipe({
  name: 'datatableColumnWidth',
})
export class DatatableColumnWidthPipe implements PipeTransform {
  transform<T>(column: DatatableColumn<T>): number {
    // Calculate by using the ch css unit or looking at the width of a single character
    const robotoMonoAt14PixelsCharacterWidth = 7.8;
    // Add extra character width to account for ellipsis
    return column.columnCharacterWidth * robotoMonoAt14PixelsCharacterWidth + robotoMonoAt14PixelsCharacterWidth;
  }
}
