import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dynamic',
})
export class DynamicPipe implements PipeTransform {
  transform(value: any, pipe: PipeTransform, args: any[]): any {
    return args ? pipe.transform(value, ...args) : pipe.transform(value);
  }
}
