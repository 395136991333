import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { BulkEndUserProvisioningResource } from '../../resources/bulk-end-user-provisioning.resource';
import { JobState, JobStatus } from '../../../shared/models/generated/smacsModels';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { unparse } from 'papaparse';
import { ToastService } from '../../../shared/services/toast.service';
import { Subscription, zip } from 'rxjs';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsFormsUpdate } from '../../../forms/smacs-forms-models';
import { UcMetadataCacheContext } from '../../../shared/contexts/uc-metadata-cache.context';
import { BulkProvisioningPollingContext } from '../../../shared/contexts/bulk-provisioning-polling.context';

export enum BulkProvisioningTab {
  GENERAL,
  TABLE,
}

export enum AssignExtensionChoice {
  AUTOMATIC,
  CSV,
}

export interface SiteServices {
  primaryExtension: boolean;
  agentExtension: boolean;
  deskphone: boolean;
  mobility: boolean;
  snr: boolean;
  voicemail: boolean;
  imSoftphone: boolean;
  iphone: boolean;
  android: boolean;
  tablet: boolean;
  cipc: boolean;
  imp: boolean;
  snrDestinationForMicrosoftTeams: boolean;
}

export interface BulkProvisioningFormData {
  site: number;
  services: SiteServices;
  assignExtension: AssignExtensionChoice;
  dnGroups: number[];
  auditTag: string;
}

export const SELECTABLE_SERVICES = [
  'primaryExtension',
  'agentExtension',
  'deskphone',
  'voicemail',
  'snr',
  'mobility',
  'imSoftphone',
  'imp',
  'cipc',
  'iphone',
  'android',
  'tablet',
  'snrDestinationForMicrosoftTeams',
];

@Component({
  selector: 'smacs-bulk-provisioning',
  templateUrl: './bulk-provisioning.component.html',
  styleUrls: ['../../../admin/admin-page.scss', './bulk-provisioning.component.scss'],
  providers: [BulkProvisioningPollingContext],
})
export class BulkProvisioningComponent implements OnInit, OnDestroy {
  currentTab = BulkProvisioningTab.GENERAL;
  BulkProvisioningTab = BulkProvisioningTab;
  isLoading = true;
  isRunning = false;
  isComplete = false;
  tabSelectionEmitter = new EventEmitter<BulkProvisioningTab>();
  auditLink = `${window.location.origin}/app2/#/admin/audits/logs`;

  csvTemplateUrl = this._csvTemplateUrl();
  smacsIcons = SmacsIcons;

  bulkProvisioningConfig: BulkProvisioningFormData;

  private _stateEndpoint = '/services/cisco/automate-jobs/cisco-bulk-end-user-provisionings/status';
  private _subscriptions = new Subscription();
  constructor(
    private sanitizer: DomSanitizer,
    private siteSummaryContext: SiteSummaryContext,
    private bulkProvisioningResource: BulkEndUserProvisioningResource,
    private toastService: ToastService,
    private breadcrumbsService: BreadcrumbsService,
    private ucMetadataCacheContext: UcMetadataCacheContext,
    private bulkProvisioningPollingContext: BulkProvisioningPollingContext
  ) {
    this.tabSelectionEmitter.subscribe((newTab: BulkProvisioningTab) => {
      this.currentTab = newTab;
    });
  }

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;pages.zero_touch.bulk_provisioning.title',
      },
    ]);
    this._listenToPolling();

    // these are initialized here for the benefit of upload-csv
    const contextSubs = zip(this.ucMetadataCacheContext.state$, this.siteSummaryContext.state$).subscribe(
      () => (this.isLoading = false)
    );
    this._subscriptions.add(contextSubs);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this.bulkProvisioningPollingContext.stopPolling();
  }
  onJobRun() {
    this.isComplete = false;
    this.isRunning = true;
    if (!this.bulkProvisioningPollingContext.getIsPolling()) {
      this._listenToPolling();
    }
  }

  receiveJobSettingsUpdate($event: SmacsFormsUpdate<BulkProvisioningFormData>) {
    this.bulkProvisioningConfig = $event.new;

    if ($event.new?.site) {
      // we want to dismiss the "complete" banner if the user is configuring another job
      // this assumes that they will always configure the site first.
      this.isComplete = false;
    }
  }

  private _listenToPolling() {
    this.bulkProvisioningPollingContext.state$.subscribe(this._handlePolling);
    this.bulkProvisioningPollingContext.startPolling();
  }

  private _csvTemplateUrl(): SafeUrl {
    const csvData = unparse({
      fields: ['Username', 'Primary Extension', 'Deskphone Model', 'MAC Address'],
      data: [],
    });
    return this.sanitizer.bypassSecurityTrustUrl(`data:text/plain;charset=utf8,${encodeURIComponent(csvData)}`);
  }

  private _handlePolling = (jobStatus: JobStatus) => {
    if (this.isRunning && jobStatus?.jobState === JobState.IDLE) {
      this.isComplete = true;
      this.bulkProvisioningPollingContext.stopPolling();
      this.toastService.push(
        ToastTypes.SUCCESS,
        this.smacsIcons.AUTOMATION,
        'tkey;pages.zero_touch.job.completed',
        'tkey;pages.zero_touch.bulk_provisioning.title'
      );
    }
    this.isRunning = jobStatus?.jobState === JobState.RUNNING;
  };
}
