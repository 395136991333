<div data-automation="smacs-breadcrumbs" class="mb-4">
  <nav aria-label="breadcrumb" class="mt-4" xmlns="http://www.w3.org/1999/html">
    <ol class="breadcrumb border-rad-all-2">
      <li *ngFor="let breadcrumb of breadcrumbs; let last = last" class="breadcrumb-item" [ngClass]="{ active: last }">
        <a
          *ngIf="!last && (breadcrumb.url === undefined || (breadcrumb.routerLink && breadcrumb.url))"
          [routerLink]="breadcrumb.routerLink ? breadcrumb.url : '.'"
        >
          {{ breadcrumb.label | translate }}
        </a>
        <span *ngIf="last" [attr.data-automation]="'active-breadcrumb'">
          {{ breadcrumb.label | translate : { labelParam: labelParam } }}
        </span>
        <span
          *ngIf="last && beta"
          class="badge bg-warning rounded-pill align-self-center ms-1"
          [ngbTooltip]="betaTooltip | translate"
          [disableTooltip]="!betaTooltip"
          [attr.data-automation]="'beta-tag'"
        >
          <span>
            {{ 'tkey;shared.device.phone.phone_buttons.instruction_card.header.badge' | translate }}
          </span>
        </span>
        <a
          *ngIf="!breadcrumb.routerLink && breadcrumb.url !== undefined && !last"
          [attr.href]="window.location.origin + breadcrumb.url"
          target="_self"
        >
          {{ breadcrumb.label | translate : { labelParam: labelParam } }}
        </a>
      </li>
      <li class="breadcrumb-icon-button-item">
        <button
          class="breadcrumb-icon-button-item__icon-button"
          data-automation="smacs-breadcrumbs-about-card-toggle"
          (click)="handleExpansionClick()"
          [ngbTooltip]="tooltip | translate"
          [disableTooltip]="!tooltip"
          *ngIf="items.length"
        >
          <i
            class="breadcrumb-icon-button-item__icon-button__icon"
            [ngClass]="{ 'breadcrumb-icon-button-item__icon-button__icon--expanded': isExpansionToggled }"
          ></i>
        </button>
      </li>
      <ng-content select="[breadcrumbButton]"></ng-content>
    </ol>
  </nav>
  <app-about-card [isExpanded]="isExpansionToggled">
    <div>
      <ng-content></ng-content>
    </div>
  </app-about-card>
</div>
