import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CallLogQueryKeywords } from '../call-logs-query-editor.component';
import { animate, query, style, transition, trigger } from '@angular/animations';
import { SmacsFormsUpdate } from '../../../forms/smacs-forms-models';

export class CallLogsQueryEditorKeywordConfig {
  keywordValue: { selection: string; value: any };
  choices: { selection: string; value: any }[];
  dataAutomation: string;
  type: string;
  hasError?: boolean;
}

export interface ExpressionEditorKeywordSearchFormData {
  'keyword-search': { label: string; value: string };
}
export interface ExpressionEditorKeywordSearchEntity {
  extensionUsernameOrDeviceName: { selection: string; value: string };
}

@Component({
  selector: 'ziro-call-logs-query-editor-keyword',
  templateUrl: './call-logs-query-editor-keyword.component.html',
  styleUrls: ['./call-logs-query-editor-keyword.component.scss'],
  animations: [
    trigger('custom-ng-select', [
      transition(':enter', [
        query(
          '.ng-dropdown-panel',
          [
            style({ opacity: 0, transform: 'translateY(-100px)' }),
            animate('1s', style({ opacity: 1, transform: 'none' })),
          ],
          { optional: true }
        ),
      ]),
    ]),
  ],
})
export class CallLogsQueryEditorKeywordComponent {
  @Input() keyword: CallLogsQueryEditorKeywordConfig;
  @Output() keywordWasUpdated = new EventEmitter();
  keywords = CallLogQueryKeywords;
  isCallKeywordQueryDropdownOpen = false;
  hasTheInputChanged = false;

  handleModelChange() {
    this.keywordWasUpdated.emit();
  }

  handleInputChange($event: SmacsFormsUpdate<any>) {
    if (
      $event?.old?.extensionUsernameOrDeviceName?.value !== $event?.new?.extensionUsernameOrDeviceName?.value ||
      $event?.old?.extensionUsernameOrDeviceName?.selection !== $event?.new?.extensionUsernameOrDeviceName?.selection
    ) {
      this.hasTheInputChanged = true;
      this.keyword.keywordValue = $event.new.extensionUsernameOrDeviceName;
    }
  }

  handlePopoverClose() {
    if (this.hasTheInputChanged) {
      this.keywordWasUpdated.emit();
      this.hasTheInputChanged = false;
    }
  }

  handleSelectionChange() {
    (this.keyword as CallLogsQueryEditorKeywordConfig).keywordValue.value = '';
  }

  get inputTemplatePlaceholder(): string {
    const { selection: dropdownValue, value: inputValue } = (this.keyword as CallLogsQueryEditorKeywordConfig)
      .keywordValue;
    if (dropdownValue) {
      return inputValue ? `${dropdownValue} ${inputValue}` : dropdownValue;
    }

    return this.keyword.type;
  }
}
