<smacs-text-config-form
  *ngIf="!!entity && !isLoading"
  [hidden]="state.hidden && (!forceShow || !state.defaultValue)"
  [displayEditor]="displayEditor"
  [entity]="entity"
  [displayLabel]="getLabel()"
  [oneClickEnabled]="isOneClickEnabled"
  [dataAutomation]="config.dataAutomation"
  [helpText]="getHelpText()"
  [isMicrosoftDialPlan]="isMicrosoftDialPlan"
  [disabled]="config.disabled ? config.disabled() : null"
  [labelToolTipIconClass]="config.labelToolTipIconClass"
  [labelToolTipText]="config.labelToolTipText"
  [extraValidation]="extraValidation"
  (smacsFormsUpdate$)="onFormUpdate($event)"
></smacs-text-config-form>
