import { AfterContentChecked, Component, ContentChildren, Input, QueryList } from '@angular/core';
import { SmacsFieldAbstractDirective } from '../smacs-field-abstract.directive';
import { SmacsNavsetComponent } from '../../shared/smacs-navset/smacs-navset.component';
import { SmacsFormAbstractDirective } from '../smacs-form-abstract.directive';

@Component({
  selector: 'smacs-fieldset-header',
  templateUrl: './smacs-fieldset-header.component.html',
})
export class SmacsFieldsetHeaderComponent implements AfterContentChecked {
  // If we have to add more content children here at some point, we should consider using an interface.
  @ContentChildren(SmacsFieldAbstractDirective, { descendants: true })
  smacsFields!: QueryList<SmacsFieldAbstractDirective<any>>;
  @ContentChildren(SmacsNavsetComponent, { descendants: true })
  smacsNavsets!: QueryList<SmacsNavsetComponent>;
  // If a fieldset header contains a component as a direct child then Angular will have a hard time finding the fields
  // within. To get around this, provide SmacsFormAbstractDirective in the providers array of the component like we do
  // in PhoneModelProtocolForm
  @ContentChildren(SmacsFormAbstractDirective, { descendants: true })
  smacsForms!: QueryList<SmacsFormAbstractDirective<any>>;
  @Input()
  legend: string;

  hasVisibleFields = false;

  ngAfterContentChecked() {
    this.hasVisibleFields =
      this.smacsFields.some(
        (field) => field.forceShow || (field.state.hasOwnProperty('hidden') && !field.state?.hidden)
      ) ||
      this.smacsNavsets.length > 0 ||
      this.smacsForms?.some((smacsForm) =>
        smacsForm.fieldComponents?.some((field) => field.forceShow || !field.state.hidden)
      );
  }
}
