import { Injectable } from '@angular/core';
import {
  DirectoryNumberRef,
  EndUserResult,
  ExtensionMobilityRef,
  PcceAgentRef,
  PhoneRef,
  ServiceProvisioningLevel,
  SnrProfileRef,
  TileConfig,
  UccxAgentRef,
  UcMetadataCache,
  VoicemailRef,
  WebexEndUser,
} from '../../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { CiscoTile, CiscoTileState } from './tiles.model';
import { ServiceTileType } from './service-tile-type.model';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';

@Injectable()
export class TileMappingService {
  smacsIcons = SmacsIcons;

  private _defaultTileState: CiscoTileState = {
    description: '',
    isAdding: false,
    isDeleting: false,
    isDisplayed: false,
    isEnabled: false,
    provisioningLevel: null,
    service: null,
  } as CiscoTileState;

  constructor(private telephoneNumberFilter: TelephoneNumberFilter) {}

  /**
   * Maps refs, and tile config to TileState for service
   */
  mapServiceToTile(
    tile: CiscoTile,
    tileConfig: TileConfig,
    ref: any = null,
    dnRef: DirectoryNumberRef = null,
    hasSites: boolean
  ): CiscoTileState {
    switch (tile) {
      case CiscoTile.VOICEMAIL: {
        return this._mapVoicemailToTile(ref, dnRef, tileConfig, hasSites);
      }
      case CiscoTile.SNR: {
        return this._mapSnrToTile(ref, dnRef, tileConfig, hasSites);
      }
      case CiscoTile.EXT_MOBILITY: {
        return this._mapExtMobilityToTile(ref, dnRef, tileConfig, hasSites);
      }
      case CiscoTile.TABLET: {
        return this._mapTabletToTile(ref, dnRef, tileConfig, hasSites);
      }
      case CiscoTile.IM_SOFTPHONE: {
        return this._mapImSoftphoneToTile(ref, dnRef, tileConfig, hasSites);
      }
      case CiscoTile.CIPC: {
        return this._mapCipcToTile(ref, dnRef, tileConfig, hasSites);
      }
      case CiscoTile.ANDROID: {
        return this._mapAndroidToTile(ref, dnRef, tileConfig, hasSites);
      }
      case CiscoTile.IPHONE: {
        return this._mapIphoneToTile(ref, dnRef, tileConfig, hasSites);
      }
      case CiscoTile.DESKPHONE: {
        return this._mapDeskphoneToTile(ref, dnRef, tileConfig, hasSites);
      }
      case CiscoTile.CTI_PORT: {
        return this._mapCtiPortToTile(ref, hasSites);
      }
      case CiscoTile.CTI_REMOTE_DEVICE: {
        return this._mapCtiRemoteDeviceToTile(ref, hasSites);
      }
      case CiscoTile.SPARK_REMOTE_DEVICE: {
        return this._mapSparkRemoteDeviceToTile(ref, hasSites);
      }
      case CiscoTile.PCCE_AGENT: {
        return this._mapPcceAgentToTile(ref, dnRef, tileConfig, hasSites);
      }
      case CiscoTile.WEBEX_CALLING: {
        return this._mapWebexCallingToTile(ref, tileConfig, hasSites);
      }
      default: {
        break;
      }
    }
  }

  mapPrimaryExtensionToTile(
    dnRef: DirectoryNumberRef,
    tileConfigs: TileConfig,
    description: string,
    primaryExtensionCandidates: DirectoryNumberRef[],
    hasSites: boolean
  ): CiscoTileState {
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-primary-extension-tile',
      serviceName: 'tkey;shared.service.primary.extension.text',
      icon: this.smacsIcons.PRIMARY_EXT_OUTLINE,
      serviceType: CiscoTile.PRIMARY_EXT,
      service: dnRef ? dnRef : null,
      tileType: dnRef ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: description,
      provisioningLevel: tileConfigs.primaryExtension,
      isDisplayed: this._displayTile(dnRef, tileConfigs.primaryExtension, hasSites),
      isEnabled: hasSites ? tileConfigs.primaryExtension !== ServiceProvisioningLevel.HIDE : false,
      isExchangeEnabled: this._hasPrimaryExtensionCandidates(dnRef, primaryExtensionCandidates),
      isExchangeShown: this._hasPrimaryExtensionCandidates(dnRef, primaryExtensionCandidates),
      tooltip: !hasSites
        ? dnRef
          ? 'tkey;userdetail.no_sites.device_enabled.tooltip.text'
          : 'tkey;userdetail.no_sites.device_not_Found.tooltip.text'
        : '',
    };
  }

  mapImpToTile(endUser: EndUserResult, tileConfigs: TileConfig, hasSites: boolean): CiscoTileState {
    const tooltipWhenUserHasSites = endUser.imPresenceEnabled
      ? 'tkey;userdetail.home.services.unsupported_device.label'
      : null;
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-imp-tile',
      serviceName: 'tkey;shared.service.imp.text',
      icon: this.smacsIcons.IMP_OUTLINE,
      serviceType: CiscoTile.IMP,
      service: {
        url: `/services/cisco/macs/cucm-servers/${endUser.ref.serverId}/end-users/${endUser.ref.id}/im-presence`,
      },
      tileType: endUser.imPresenceEnabled ? ServiceTileType.EDIT : ServiceTileType.ADD,
      provisioningLevel: tileConfigs.imPresence,
      isEnabled: hasSites ? !endUser.imPresenceEnabled : false,
      isDisplayed: this._displayTile(null, tileConfigs.imPresence, hasSites),
      tooltip: hasSites
        ? tooltipWhenUserHasSites
        : endUser.imPresenceEnabled
        ? 'tkey;userdetail.no_sites.device_enabled.tooltip.text'
        : 'tkey;userdetail.no_sites.device_not_Found.tooltip.text',
    };
  }

  private _getUccxAgentTileDescription(
    ipccExtension: DirectoryNumberRef,
    ucMetadataCache: UcMetadataCache,
    ref: UccxAgentRef,
    uccxAgentRefs: UccxAgentRef[]
  ) {
    return `${
      uccxAgentRefs.length <= 1
        ? `${ref.id} | ${this.telephoneNumberFilter.transform(ipccExtension.extension)}`
        : ucMetadataCache.uccxMetadataCache[ref.serverId].description
    } `;
  }

  mapUccxAgentToTile(
    ref: UccxAgentRef,
    uccxAgentRefs: UccxAgentRef[],
    ucMetadataCache: UcMetadataCache,
    ipccExtension: DirectoryNumberRef,
    hasSites: boolean
  ): CiscoTileState {
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-uccx-agent-tile',
      icon: this.smacsIcons.UCCX_AGENT_OUTLINE,
      serviceType: CiscoTile.UCCX_AGENT,
      service: ref ? ref : null,
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      serviceName: uccxAgentRefs.length <= 1 ? 'tkey;shared.service.agent.text' : ref.id,
      description: this._getUccxAgentTileDescription(ipccExtension, ucMetadataCache, ref, uccxAgentRefs),
      provisioningLevel: uccxAgentRefs.length ? ServiceProvisioningLevel.SHOW : ServiceProvisioningLevel.HIDE,
      isDisplayed: !!uccxAgentRefs.length,
      isEnabled: hasSites ? !!uccxAgentRefs.length : false,
      tooltip: !hasSites
        ? ref
          ? 'tkey;userdetail.no_sites.device_enabled.tooltip.text'
          : 'tkey;userdetail.no_sites.device_not_Found.tooltip.text'
        : '',
    };
  }
  /**
   * Check if a user has any primary extension candidates that don't match their current primary extension
   */
  private _hasPrimaryExtensionCandidates(
    dnRef: DirectoryNumberRef,
    primaryExtensionCandidates: DirectoryNumberRef[]
  ): boolean {
    return primaryExtensionCandidates.some((ref: DirectoryNumberRef) => ref.id !== dnRef?.id);
  }

  private _mapPcceAgentToTile(
    refs: PcceAgentRef[],
    dnRef: DirectoryNumberRef,
    tileConfigs: TileConfig,
    hasSites: boolean
  ): CiscoTileState {
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-pcce-agent-tile',
      serviceName: 'tkey;shared.service.agent.text',
      icon: this.smacsIcons.UCCX_AGENT_OUTLINE,
      serviceType: CiscoTile.PCCE_AGENT,
      service: refs && refs.length ? refs[0] : null,
      tileType: refs && refs.length ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: refs && refs.length ? refs[0].username : '',
      provisioningLevel: tileConfigs.pcceAgent,
      isDisplayed: this._displayTile(null, tileConfigs.pcceAgent, hasSites),
      isEnabled: hasSites,
      tooltip: hasSites
        ? ''
        : refs && refs.length
        ? 'tkey;userdetail.no_sites.device_enabled.tooltip.text'
        : 'tkey;userdetail.no_sites.device_not_Found.tooltip.text',
    };
  }

  private _mapDeskphoneToTile(
    ref: PhoneRef,
    dnRef: DirectoryNumberRef,
    tileConfigs: TileConfig,
    hasSites: boolean
  ): CiscoTileState {
    const enabledStatus = dnRef ? tileConfigs.deskphone !== ServiceProvisioningLevel.HIDE : false;
    const tooltipWhenUserHasSites = dnRef ? null : 'tkey;userdetail.add.service_tile.missing_primary_extension.tooltip';
    return {
      dataAutomation: `user-360-deskphone-tile`,
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      service: ref ? ref : null,
      serviceName: ref ? ref.model : 'tkey;shared.model.deskphone.text',
      icon: this.smacsIcons.DESKPHONE_OUTLINE,
      description: ref ? ref.description : '',
      isAdding: false,
      isDeleting: false,
      isEnabled: !hasSites ? false : enabledStatus,
      // Deskphone is not quick addable
      provisioningLevel:
        tileConfigs.deskphone === ServiceProvisioningLevel.HIDE
          ? ServiceProvisioningLevel.HIDE
          : ServiceProvisioningLevel.SHOW,
      isDisplayed: this._displayTile(ref, tileConfigs.deskphone, hasSites),
      serviceType: CiscoTile.DESKPHONE,
      tooltip: hasSites
        ? tooltipWhenUserHasSites
        : ref
        ? 'tkey;userdetail.no_sites.device_enabled.tooltip.text'
        : 'tkey;userdetail.no_sites.device_not_Found.tooltip.text',
    };
  }

  private _mapCipcToTile(
    ref: PhoneRef,
    dnRef: DirectoryNumberRef,
    tileConfigs: TileConfig,
    hasSites: boolean
  ): CiscoTileState {
    const enabledStatus = dnRef ? tileConfigs.cipc !== ServiceProvisioningLevel.HIDE : false;
    return {
      ...this._defaultTileState,
      dataAutomation: `user-360-cipc-tile`,
      serviceName: 'tkey;shared.model.cipc.text',
      icon: this.smacsIcons.CIPC_OUTLINE,
      serviceType: CiscoTile.CIPC,
      service: ref ? ref : null,
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: ref ? ref.name : '',
      provisioningLevel: tileConfigs.cipc,
      isEnabled: !hasSites ? false : enabledStatus,
      isDisplayed: this._displayTile(ref, tileConfigs.cipc, hasSites),
      tooltip: hasSites
        ? this._getTileTooltip(dnRef, tileConfigs.cipc, true)
        : this._getTileTooltip(ref, tileConfigs.cipc, false),
    };
  }

  private _mapImSoftphoneToTile(
    ref: PhoneRef,
    dnRef: DirectoryNumberRef,
    tileConfigs: TileConfig,
    hasSites: boolean
  ): CiscoTileState {
    const enabledStatus = dnRef ? tileConfigs.imSoftphone !== ServiceProvisioningLevel.HIDE : false;
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-im-softphone-tile',
      serviceName: 'tkey;shared.model.imsoftphone.text',
      icon: this.smacsIcons.SOFTPHONE_OUTLINE,
      serviceType: CiscoTile.IM_SOFTPHONE,
      service: ref ? ref : null,
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: ref ? ref.name : '',
      provisioningLevel: tileConfigs.imSoftphone,
      isEnabled: !hasSites ? false : enabledStatus,
      isDisplayed: this._displayTile(ref, tileConfigs.imSoftphone, hasSites),
      tooltip: hasSites
        ? this._getTileTooltip(dnRef, tileConfigs.imSoftphone, true)
        : this._getTileTooltip(ref, tileConfigs.imSoftphone, false),
    };
  }

  private _mapIphoneToTile(
    ref: PhoneRef,
    dnRef: DirectoryNumberRef,
    tileConfigs: TileConfig,
    hasSites: boolean
  ): CiscoTileState {
    const enabledStatus = dnRef ? tileConfigs.iphone !== ServiceProvisioningLevel.HIDE : false;
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-iphone-tile',
      serviceName: 'tkey;shared.model.iphone.text',
      icon: this.smacsIcons.IPHONE_OUTLINE,
      serviceType: CiscoTile.IPHONE,
      service: ref ? ref : null,
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: ref ? ref.name : '',
      provisioningLevel: tileConfigs.iphone,
      isEnabled: !hasSites ? false : enabledStatus,
      isDisplayed: this._displayTile(ref, tileConfigs.iphone, hasSites),
      tooltip: hasSites
        ? this._getTileTooltip(dnRef, tileConfigs.iphone, true)
        : this._getTileTooltip(ref, tileConfigs.iphone, false),
    };
  }

  private _mapAndroidToTile(
    ref: PhoneRef,
    dnRef: DirectoryNumberRef,
    tileConfigs: TileConfig,
    hasSites: boolean
  ): CiscoTileState {
    const enabledStatus = dnRef ? tileConfigs.android !== ServiceProvisioningLevel.HIDE : false;
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-android-tile',
      serviceName: 'tkey;shared.model.android.text',
      icon: this.smacsIcons.ANDROID_OUTLINE,
      serviceType: CiscoTile.ANDROID,
      service: ref ? ref : null,
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: ref ? ref.name : '',
      provisioningLevel: tileConfigs.android,
      isEnabled: !hasSites ? false : enabledStatus,
      isDisplayed: this._displayTile(ref, tileConfigs.android, hasSites),
      tooltip: hasSites
        ? this._getTileTooltip(dnRef, tileConfigs.android, true)
        : this._getTileTooltip(ref, tileConfigs.android, false),
    };
  }

  private _mapTabletToTile(
    ref: PhoneRef,
    dnRef: DirectoryNumberRef,
    tileConfigs: TileConfig,
    hasSites: boolean
  ): CiscoTileState {
    const enabledStatus = dnRef ? tileConfigs.tablet !== ServiceProvisioningLevel.HIDE : false;
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-tablet-tile',
      serviceName: 'tkey;shared.model.tablet.text',
      icon: this.smacsIcons.TABLET_OUTLINE,
      serviceType: CiscoTile.TABLET,
      service: ref ? ref : null,
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: ref ? ref.name : '',
      provisioningLevel: tileConfigs.tablet,
      isEnabled: hasSites ? enabledStatus : false,
      isDisplayed: this._displayTile(ref, tileConfigs.tablet, hasSites),
      tooltip: hasSites
        ? this._getTileTooltip(dnRef, tileConfigs.tablet, true)
        : this._getTileTooltip(ref, tileConfigs.tablet, false),
    };
  }

  private _mapCtiPortToTile(ref: PhoneRef, hasSites: boolean): CiscoTileState {
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-cti-port-tile',
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      serviceName: 'tkey;shared.model.ctiport.text',
      icon: this.smacsIcons.PHONE_OTHER_OUTLINE,
      serviceType: CiscoTile.CTI_PORT,
      isEnabled: false,
      tooltip: hasSites
        ? 'tkey;userdetail.home.services.unsupported_device.label'
        : this._getTileTooltip(ref, ServiceProvisioningLevel.SHOW, false),
      service: ref ? ref : null,
      description: ref ? ref.name : '',
      isDisplayed: !!ref,
      provisioningLevel: ServiceProvisioningLevel.SHOW,
    };
  }

  private _mapCtiRemoteDeviceToTile(ref: PhoneRef, hasSites: boolean): CiscoTileState {
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-cti-remove-device-tile',
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      serviceName: 'tkey;shared.model.ctiremotedevice.text',
      icon: this.smacsIcons.PHONE_OTHER_OUTLINE,
      serviceType: CiscoTile.CTI_REMOTE_DEVICE,
      isEnabled: false,
      tooltip: hasSites
        ? 'tkey;userdetail.home.services.unsupported_device.label'
        : this._getTileTooltip(ref, ServiceProvisioningLevel.SHOW, false),
      service: ref ? ref : null,
      description: ref ? ref.name : '',
      isDisplayed: !!ref,
      provisioningLevel: ServiceProvisioningLevel.SHOW,
    };
  }

  private _mapSparkRemoteDeviceToTile(ref: PhoneRef, hasSites: boolean): CiscoTileState {
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-spark-remote-device-tile',
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      serviceName: 'tkey;shared.model.sparkremotedevice.text',
      icon: this.smacsIcons.PHONE_OTHER_OUTLINE,
      serviceType: CiscoTile.SPARK_REMOTE_DEVICE,
      isEnabled: false,
      tooltip: hasSites
        ? 'tkey;userdetail.home.services.unsupported_device.label'
        : this._getTileTooltip(ref, ServiceProvisioningLevel.SHOW, false),
      service: ref ? ref : null,
      description: ref ? ref.name : '',
      isDisplayed: !!ref,
      provisioningLevel: ServiceProvisioningLevel.SHOW,
    };
  }

  private _mapVoicemailToTile(
    ref: VoicemailRef,
    dnRef: DirectoryNumberRef,
    tileConfigs: TileConfig,
    hasSites: boolean
  ): CiscoTileState {
    const enabledStatus = dnRef ? tileConfigs.voicemail !== ServiceProvisioningLevel.HIDE : false;
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-voicemail-tile',
      serviceName: 'tkey;shared.service.voicemail.text',
      icon: this.smacsIcons.VOICEMAIL_OUTLINE,
      serviceType: CiscoTile.VOICEMAIL,
      service: ref ? ref : null,
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: ref ? ref.alias : '',
      provisioningLevel: tileConfigs.voicemail,
      isDisplayed: this._displayTile(ref, tileConfigs.voicemail, hasSites),
      isEnabled: hasSites ? enabledStatus : false,
      tooltip: hasSites
        ? this._getTileTooltip(dnRef, tileConfigs.voicemail, true)
        : this._getTileTooltip(ref, tileConfigs.voicemail, false),
    };
  }

  private _mapSnrToTile(
    ref: SnrProfileRef,
    dnRef: DirectoryNumberRef,
    tileConfigs: TileConfig,
    hasSites: boolean
  ): CiscoTileState {
    const enabledStatus = dnRef ? tileConfigs.snrProfile !== ServiceProvisioningLevel.HIDE : false;
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-snr-tile',
      serviceName: 'tkey;shared.service.snr.text',
      icon: this.smacsIcons.SNR_OUTLINE,
      serviceType: CiscoTile.SNR,
      service: ref ? ref : null,
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: ref ? ref.name : '',
      provisioningLevel: tileConfigs.snrProfile,
      isDisplayed: this._displayTile(ref, tileConfigs.snrProfile, hasSites),
      isEnabled: hasSites ? enabledStatus : false,
      tooltip: hasSites
        ? this._getTileTooltip(dnRef, tileConfigs.snrProfile, true)
        : this._getTileTooltip(ref, tileConfigs.snrProfile, false),
    };
  }

  private _mapExtMobilityToTile(
    ref: ExtensionMobilityRef,
    dnRef: DirectoryNumberRef,
    tileConfigs: TileConfig,
    hasSites: boolean
  ): CiscoTileState {
    const enabledStatus = dnRef ? tileConfigs.extensionMobility !== ServiceProvisioningLevel.HIDE : false;
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-ext-mobility-tile',
      serviceName: 'tkey;shared.service.extensionmobility.text',
      icon: this.smacsIcons.EXTENSION_MOBILITY_OUTLINE,
      serviceType: CiscoTile.EXT_MOBILITY,
      service: ref ? ref : null,
      tileType: ref ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: ref ? ref.name : '',
      provisioningLevel: tileConfigs.extensionMobility,
      isEnabled: hasSites ? enabledStatus : false,
      isDisplayed: this._displayTile(ref, tileConfigs.extensionMobility, hasSites),
      tooltip: hasSites
        ? this._getTileTooltip(dnRef, tileConfigs.extensionMobility, true)
        : this._getTileTooltip(ref, tileConfigs.extensionMobility, false),
    };
  }

  private _mapWebexCallingToTile(ref: WebexEndUser, tileConfigs: TileConfig, hasSites: boolean): CiscoTileState {
    const displayLogicWhenSitesExist = ref && ref.email && tileConfigs.webexCalling !== ServiceProvisioningLevel.HIDE;
    return {
      ...this._defaultTileState,
      dataAutomation: 'user-360-webex-calling-tile',
      serviceName: 'tkey;shared.service.webex_calling.text',
      icon: this.smacsIcons.WEBEX_CALLING_OUTLINE,
      serviceType: CiscoTile.WEBEX_CALLING,
      service: ref ? ref : null,
      tileType: ref?.webexCallingLicense ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: ref ? this.telephoneNumberFilter.transform(ref.did || ref.extension) || ref.webexCallingLicense : '',
      provisioningLevel: hasSites ? tileConfigs.webexCalling : ServiceProvisioningLevel.SHOW,
      isEnabled: hasSites ? tileConfigs.webexCalling !== ServiceProvisioningLevel.HIDE : false,
      isDisplayed: hasSites ? displayLogicWhenSitesExist : !!ref?.webexCallingLicense,
      tooltip: hasSites
        ? ''
        : !!ref?.webexCallingLicense
        ? 'tkey;userdetail.no_sites.device_enabled.tooltip.text'
        : 'tkey;userdetail.no_sites.device_not_Found.tooltip.text',
    };
  }

  /**
   * Determines if the service tile should be displayed
   * Display the tile if either are true:
   * 1. Service exists
   * 2. Provisioning Level is SHOW or ONE_CLICK
   * 3. No sites are returned by the 360 view - we want to display all services in this case
   * Otherwise hide the tile
   */
  private _displayTile(service: any, provisioningLevel: ServiceProvisioningLevel, hasSites: boolean): boolean {
    return !!(service || provisioningLevel !== ServiceProvisioningLevel.HIDE) || !hasSites;
  }
  /**
   * Returns the tooltip for any service that can be disabled in site management
   */
  private _getTileTooltip(
    ref: DirectoryNumberRef | PhoneRef | VoicemailRef | SnrProfileRef | ExtensionMobilityRef | boolean,
    provisioningLevel: ServiceProvisioningLevel,
    hasSites: boolean
  ): string {
    if (hasSites) {
      return !ref
        ? 'tkey;userdetail.add.service_tile.missing_primary_extension.tooltip'
        : provisioningLevel === ServiceProvisioningLevel.HIDE
        ? 'tkey;userdetail.home.services.disabled_service.label'
        : '';
    } else {
      return !ref
        ? 'tkey;userdetail.no_sites.device_not_Found.tooltip.text'
        : 'tkey;userdetail.no_sites.device_enabled.tooltip.text';
    }
  }
}
