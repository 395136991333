import { Component, OnDestroy, OnInit } from '@angular/core';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import {
  EnvironmentType,
  MicrosoftGraphRegistrationDetails,
  SystemHealthStatus,
} from '../../../shared/models/generated/smacsModels';
import { MsGraphConfigContext } from '../../contexts/ms-graph-config.context';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { HtmlInputType, SmacsTextComponent, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { EMPTY, Observable, Subscriber, Subscription, throwError } from 'rxjs';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsSelectConfig } from '../../../forms/fields/select/smacs-select.component';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SystemStatusContext } from '../../../shared/contexts/system-status.context';
import { MsTeamsCachedOptionsResource } from '../../resources/ms-teams-cached-options.resource';

@Component({
  selector: 'microsoft-graph-management',
  templateUrl: './graph-management.component.html',
  styleUrls: ['../../admin-page.scss', './graph-management.component.scss'],
})
export class GraphManagementComponent
  extends SmacsFormAbstractDirective<MicrosoftGraphRegistrationDetails>
  implements OnInit, OnDestroy
{
  isLoading = true;
  smacsIcons = SmacsIcons;

  formConfig = {
    fields: {
      tenant: {
        fieldId: 'tenant',
        label: 'tkey;admin.msgraph.management.config.label.tenant',
        dataAutomation: 'validate-tenant',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        required: true,
        validation: [
          {
            validator: (val) =>
              /^[^-]([a-z0-9\-]+\.)*[a-z0-9\-]+\.[a-z]{2,6}\b/.test(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: 'tkey;admin.msgraph.management.config.tenant.domain.error',
          },
        ],
      },
      clientId: {
        fieldId: 'clientId',
        label: 'tkey;admin.msgraph.management.config.label.client.id',
        dataAutomation: 'validate-client-id',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        required: true,
      },
      clientSecret: {
        fieldId: 'clientSecret',
        label: 'tkey;admin.msgraph.management.config.label.client.secret',
        dataAutomation: 'validate-client-secret',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
        required: () => !this.isExisting,
      },
      environmentType: {
        fieldId: 'environmentType',
        label: 'tkey;admin.msgraph.management.config.label.environment.type',
        dataAutomation: 'validate-environment-type',
        componentConfig: new SmacsSelectConfig({ options: Object.values(EnvironmentType) }),
        required: true,
      },
    },
  } as SmacsFormConfig;

  private _subs = new Subscription();
  isRefreshing: boolean;

  constructor(
    private bottomNavService: BottomNavService,
    private msGraphContext: MsGraphConfigContext,
    private toastService: ToastService,
    protected smacsFormStateService: SmacsFormStateService,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private breadcrumbsService: BreadcrumbsService,
    private systemStatusContext: SystemStatusContext,
    private msTeamsCachedOptionsResource: MsTeamsCachedOptionsResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.msgraph.management.config.title' }]);
    this.msGraphContext.state$.subscribe((msGraphRegistrationDetails: MicrosoftGraphRegistrationDetails) => {
      this.entitySource.next(msGraphRegistrationDetails);
      this.setIsExisting(!!msGraphRegistrationDetails.clientId);
      this._setDeleteDisabledState(!this.isExisting);
      this.isLoading = false;
    });

    this._initBottomNav();

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() =>
      this.bottomNavService.setBottomNavValidationError(!this.isFormValid())
    );
    this._subs.add(formSubmittedSub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  refreshCache() {
    this.isRefreshing = true;
    this._setDeleteDisabledState(true);
    this._setSaveDisabledState(true);
    const teamsCachedOptionsSub = this.msTeamsCachedOptionsResource
      .refreshTeamsCachedOptions()
      .pipe(
        tap(() => {
          this.toastService.push(
            ToastTypes.SUCCESS,
            this.smacsIcons.REFRESH,
            'tkey;admin.powershell_management.toast.refresh_cache.title',
            'tkey;admin.powershell_management.toast.refresh_cache.message'
          );
          this.isRefreshing = false;
          this._setDeleteDisabledState(false);
          this._setSaveDisabledState(false);
        }),
        catchError((response) => {
          this.isRefreshing = false;
          this._setDeleteDisabledState(false);
          this._setSaveDisabledState(false);
          return throwError(() => response);
        })
      )
      .subscribe();
    this._subs.add(teamsCachedOptionsSub);
  }

  protected submit() {
    return this._onValidateClicked();
  }

  private _setSavePendingState(setting: boolean) {
    this.bottomNavService.setButtonPendingState('validateMsRegistrationDetailsButton', setting);
  }

  private _onValidateClicked(): Observable<void | SystemHealthStatus> {
    this._setSavePendingState(true);
    this._setDeleteDisabledState(true);

    return this.msGraphContext.postValidate(this.formData).pipe(
      map((validationResponse) => {
        //TODO: this is temp code, remove this map in #17678
        const missingRequiredUseCases = validationResponse.useCases.filter(
          (useCase) => useCase.isRequired && !useCase.isEnabled
        );
        if (missingRequiredUseCases.length > 0) {
          throw new Error(
            `missing required useCases: ${missingRequiredUseCases.map((useCase) => useCase.name).join(', ')}`
          );
        }
      }),
      switchMap(() => this.msGraphContext.put(this.formData)),
      switchMap(() => this.systemStatusContext.refreshSystemStatus()),
      tap(() => {
        this.toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.MICROSOFT_365,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.msgraph.management.config.toast.success.message'
        );
        this._setSavePendingState(false);
        this._setDeleteDisabledState(false);
      }),
      catchError((err) => {
        //TODO: in #17678 uncomment this line and delete the temp code
        // if (!err || err.status !== 422) {
        //   throw err;
        // }
        // const errorDescription = err ? err.error.description || err.error[0].message : '';
        let errorDescription: string;
        if (err) {
          if (err.message && err.message.startsWith('missing required useCases')) {
            errorDescription = err.message;
          } else if (err.error && err.status === 422) {
            errorDescription = err.error.description || err.error[0].message;
          } else {
            throw err;
          }
        }
        // end temp code
        this.toastService.push(
          ToastTypes.ERROR,
          this.smacsIcons.MICROSOFT_365,
          'tkey;admin.msgraph.management.config.toast.error.validate.message',
          errorDescription
        );
        this._setSavePendingState(false);
        this._setDeleteDisabledState(false);
        return throwError(() => EMPTY);
      })
    );
  }

  private _initBottomNav() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'ms-graph-management-delete-button',
          label: 'tkey;dialogs.button.delete',
          buttonClass: ButtonStyles.DANGER,
          dataAutomation: 'ms-graph-management-delete-button',
          state: {
            pending: false,
            buttonDisableState: {
              disabled: false,
              tooltipKey: '',
            },
          },
          icon: this.smacsIcons.DELETE,
          cb: () => this._onDeleteClick(),
        },
        {
          id: 'validateMsRegistrationDetailsButton',
          label: 'tkey;admin.ui.save',
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'ms-graph-management-save-button',
          state: {
            pending: false,
            buttonDisableState: { disabled: false, tooltipKey: '' },
          },
          icon: this.smacsIcons.OK,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );
  }

  private _onDeleteClick() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        promptBody: this.translateService.instant('tkey;admin.msgraph.management.config.modal.delete.message'),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteAppRegistrationDetails(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _resetFormState() {
    this.setIsExisting(false);
    this.entitySource.next({
      clientId: '',
      clientSecret: '',
      tenant: '',
      environmentType: EnvironmentType.COMMERCIAL,
    });
    this.smacsFormStateService.setIsFormDirty(false);
    this.isFormSubmitted = false;
    this.fieldComponents.forEach((item: SmacsTextComponent) => {
      item.isDirty = false;
      item.showValidation = false;
      item.isFormSubmitted = this.isFormSubmitted;

      if (item.fieldId === 'clientSecret') {
        item.showMaskedInput = false;
        item.applyComponentConfig(new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }));
      }
    });
    this._setDeleteDisabledState(true);
  }

  private _setDeleteDisabledState(state: boolean) {
    this.bottomNavService.setButtonDisabledState('ms-graph-management-delete-button', state);
  }

  private _setSaveDisabledState(state: boolean) {
    this.bottomNavService.setButtonDisabledState('ms-graph-management-save-button', state);
  }

  private _deleteAppRegistrationDetails(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this.msGraphContext.delete().subscribe(() => {
        this._resetFormState();
        this.toastService.push(
          ToastTypes.INFO,
          `${this.smacsIcons.DELETE} text-danger`,
          'tkey;shared.toast.delete.success.title',
          `tkey;admin.msgraph.management.config.toast.delete.message`
        );
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }
}
