<div class="modal-height">
  <app-loading-block [cssClass]="cssClass" [hidden]="!isLoading"></app-loading-block>
  <smacs-voicemail
    (voicemailRefUpdated)="onVoicemailRefUpdated($event)"
    (showLoading)="setLoading($event)"
    [modalProperties]="modalProperties"
    [username]="modalProperties.username"
    [siteId]="modalProperties.siteId"
  ></smacs-voicemail>
</div>

<ng-template #errorTemplate>
  <span *ngIf="!areFormsValid">{{ 'tkey;validators.global.required.missing.error' | translate }}</span>
</ng-template>
