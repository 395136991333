<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1 pb-2" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.system.cisco_general_settings.description' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div class="mb-3 list-group list-group-striped">
      <div *ngFor="let config of formConfig.fields | keyvalue : returnZero" class="list-group-item position-relative">
        <smacs-general-settings-checkbox
          *ngIf="isCheckBox(config.value)"
          [fieldId]="config.key"
        ></smacs-general-settings-checkbox>

        <smacs-general-settings-text
          *ngIf="isTextInput(config.value)"
          [fieldId]="config.key"
        ></smacs-general-settings-text>
      </div>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
