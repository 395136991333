import { Component, OnDestroy, OnInit } from '@angular/core';
import { isArray, some, sortBy } from 'lodash';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { ServersContext } from '../../contexts/servers.context';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { TranslateService } from '@ngx-translate/core';
import { BottomNavButtonState } from '../../../shared/bottom-nav/bottom-nav.component';
import { SystemStatusContext } from '../../../shared/contexts/system-status.context';
import {
  ClusterResult,
  HealthStatus,
  Server,
  State,
  Status,
  StatusCategory,
  SystemHealthStatus,
} from '../../../shared/models/generated/smacsModels';
import { combineLatest, Observable, Subject, Subscriber, Subscription } from 'rxjs';
import { ToastService } from '../../../shared/services/toast.service';
import { ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { UcMetadataCacheContext } from '../../../shared/contexts/uc-metadata-cache.context';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { AdminAddEditServerModalComponent } from './add-edit-server-modal.component';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../shared/entity-table/entity-table.models';
import { ZiroModalDetailedModalOptions } from '../../../modals/detailed-modal/detailed-modal.component';

@Component({
  selector: 'app-admin-servers',
  templateUrl: './servers.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class AdminServersComponent implements OnInit, OnDestroy {
  private readonly _reloadButtonId: string = 'reload-config-btn';
  private readonly _addServerButtonId: string = 'admin-add-server';

  isLoading = true;
  serverStatuses: Map<string, Status>;
  licenseStatus: HealthStatus;
  serverStatusesNew: HealthStatus[] = [];
  bottomNavButtons = [
    {
      id: this._reloadButtonId,
      dataAutomation: 'reload-config-btn',
      label: 'tkey;menu.admin.reload',
      buttonClass: ButtonStyles.PRIMARY,
      icon: SmacsIcons.REFRESH,
      state: {
        pending: false,
        buttonDisableState: { disabled: false },
      },
      cb: () => {
        this._reloadConfig();
      },
    },
    {
      id: this._addServerButtonId,
      dataAutomation: 'admin-add-server',
      label: 'tkey;admin.servers.add.server.button',
      icon: SmacsIcons.ADD,
      tooltipKey: 'tkey;admin.servers.add.server.button.tooltip',
      buttonClass: ButtonStyles.PRIMARY,
      state: {
        pending: false,
        buttonDisableState: { disabled: true, tooltipKey: 'tkey;admin.servers.add.server.button.tooltip' },
        tooltipVisible: true,
      },
      cb: () => {
        this._openEditModal();
      },
    },
  ];
  entityTable: EntityTable = {
    columns: [
      {
        columnId: 'id',
        cssColumnSize: 'col-sm-1',
        label: 'tkey;admin.servers.id.label',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'description',
        cssColumnSize: 'col-sm-3',
        label: 'tkey;admin.servers.description.label',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'hostAddress',
        cssColumnSize: 'col-sm-3',
        label: 'tkey;admin.servers.host.address.label',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'serverType',
        cssColumnSize: 'col-sm-2',
        label: 'tkey;admin.servers.server.type.label',
        filter: {
          type: EntityTableFilterTypes.SELECT,
          options: ['CUCM', 'IM & Presence', 'Unity', 'UCCX', 'PCCE'],
        },
      },
      {
        columnId: 'status',
        cssColumnSize: 'col-sm-2',
        label: 'tkey;system_status.server.status',
        filter: {
          type: EntityTableFilterTypes.SELECT,
          options: [...(Object.values(State) as string[])],
          filterFn: (rowsToFilter: EntityTableContentRow[], filterValue: string, columnKey: string) => {
            return rowsToFilter.filter((row: EntityTableContentRow) => {
              const status = this.serverStatuses.has(row.content['hostAddress'])
                ? this.serverStatuses.get(row.content['hostAddress']).state
                : 'Ok';
              return filterValue ? status.toLocaleLowerCase() === filterValue.toLowerCase() : true;
            });
          },
        },
      },
    ],
    hasActions: true,
  };
  tableRows: EntityTableContentRow[] = [];

  private _isRefreshComplete = new Subject<boolean>();
  private _servers: Server[] = [];
  private _reloadingConfigurations = false;
  private _clusters: ClusterResult[] = [];
  private _isRefreshingContexts = false;
  private _subscription = new Subscription();
  private _isFirstRun = true;

  private static _getServerUrl(server: Server): string {
    const baseUrl = 'https://' + server.hostAddress;
    return server.serverType === 'PCCE' ? baseUrl + '/cceadmin' : baseUrl;
  }

  constructor(
    private _siteSummaryContext: SiteSummaryContext,
    private _smacsModalService: SmacsModalService,
    private _toastService: ToastService,
    private _serversContext: ServersContext,
    private _bottomNavService: BottomNavService,
    private _translateService: TranslateService,
    private _systemStatusContext: SystemStatusContext,
    private _ucMetadataCacheContext: UcMetadataCacheContext,
    private _breadcrumbsService: BreadcrumbsService
  ) {}

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.servers.config.title' }]);
    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));
    this.serverStatuses = new Map();
    const serverSiteSub = combineLatest([this._serversContext.state$, this._siteSummaryContext.state$]).subscribe(
      ({ 0: servers, 1: siteSummary }) => {
        this._servers = sortBy(servers, ['serverType', 'description']);
        this._clusters = siteSummary.clusters;
        this._generateTable();
        if (this._isFirstRun) {
          this._isFirstRun = false;
          this.isLoading = false;
        }
      }
    );
    this._subscription.add(serverSiteSub);

    const systemStatusContextSub = this._systemStatusContext.state$.subscribe((action: SystemHealthStatus) => {
      this._loadSystemAlertState(action);
      if (!this.isLoading) {
        this._generateTable();
      }
    });
    this._subscription.add(systemStatusContextSub);

    const refreshSub = this._isRefreshComplete.subscribe((isRefreshed) => {
      this.isLoading = true;
      if (isRefreshed) {
        this._generateTable();
        this.isLoading = false;
      }
    });
    this._subscription.add(refreshSub);
  }

  ngOnDestroy() {
    this._breadcrumbsService.clearBreadcrumbs();
    this._subscription.unsubscribe();
  }

  getBadServers(): Server[] {
    if (!this._hasServers() || this.serverStatuses.size === 0) {
      return [];
    }

    return this._servers.filter((server: Server) => {
      return this.serverStatuses.has(server.hostAddress)
        ? this.serverStatuses.get(server.hostAddress).state === State.ERROR
        : false;
    });
  }

  private _openEditModal(server?: Server) {
    if (!server) {
      server = {
        port: 8443,
        disabled: false,
      } as Server;
    }
    const options = {
      modalViewProperties: {
        title: 'tkey;admin.servers.modal.edit.title',
        server,
        servers: this._servers,
      },
      bodyClass: AdminAddEditServerModalComponent,
      size: 'lg',
    } as ZiroModalDetailedModalOptions;

    this._smacsModalService
      .openDetailedModal(() => options.modalViewProperties, options)
      .subscribe({
        next: (updatedServer: Server) => {
          if (updatedServer) {
            this.isLoading = true;
            this._refreshContexts(updatedServer).subscribe(() => {
              this._toastService.push(
                ToastTypes.SUCCESS,
                SmacsIcons.CONFIG,
                updatedServer.id ? 'tkey;admin.servers.update.success.text' : 'tkey;admin.servers.add.success.text',
                'tkey;admin.servers.add.success.text'
              );
              this._isRefreshingContexts = false;
              this._isRefreshComplete.next(true);
            });
          }
        },
        error: (error) => {
          console.error('error', error);
        },
      });
  }

  private _openDeleteModal(server: Server) {
    if (this._isServerUsedByCluster(server)) {
      return;
    }
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        promptBody: this._translateService.instant('tkey;admin.servers.delete.confirm.text', {
          description: server.description,
        }),
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onConfirmDelete(server),
          },
        ],
      },
    };
    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _isErrorPresentOnServer(server: Server): boolean {
    return this.serverStatuses.has(server.hostAddress)
      ? this.serverStatuses.get(server.hostAddress).state === State.ERROR
      : false;
  }

  private _isWarningPresentOnSever(server: Server): boolean {
    return (
      this.serverStatuses.has(server.hostAddress) && this.serverStatuses.get(server.hostAddress).state === State.WARNING
    );
  }

  private _isServerUsedByCluster(server: Server): boolean {
    if (server.serverType === 'CUCM') {
      return some(this._clusters, (cluster) => cluster.cucmServerId === server.id);
    } else if (server.serverType === 'IM & Presence') {
      return some(this._clusters, (cluster) => cluster.impServerId === server.id);
    } else if (server.serverType === 'PCCE') {
      return some(this._clusters, (cluster) => cluster.pcceServerId === server.id);
    } else if (server.serverType === 'Unity') {
      return some(this._clusters, (cluster) => {
        return some(cluster.sites, (site) => site.unityServerId === server.id);
      });
    }
    return false;
  }

  private _onConfirmDelete(server: Server): Observable<any> {
    return new Observable((subscriber) => {
      this._serversContext.delete(server.id).subscribe({
        next: () => {
          this._refreshContexts(server).subscribe(() => {
            this._toastService.pushDeleteToast('tkey;admin.servers.config.name', server.description);
            subscriber.next();
            this._isRefreshComplete.next(true);
          });
        },
        error: (error) => subscriber.error(error),
      });
    });
  }

  private _refreshContexts = (server: Server): Observable<void> => {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      this._isRefreshingContexts = true;
      this.isLoading = true;

      if (this._isErrorPresentOnServer(server)) {
        this._ucMetadataCacheContext.reloadUcMetadataCache().subscribe(() => {
          this._serversContext.getAll().subscribe();

          this._refresh().subscribe(() => {
            this.isLoading = false;
            subscriber.next();
            subscriber.complete();
          });
        });
      } else {
        this._refresh().subscribe(() => {
          this.isLoading = false;
          subscriber.next();
          subscriber.complete();
        });
      }
    });
  };

  private _refresh(): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      combineLatest([
        this._siteSummaryContext.refreshSiteSummary(),
        this._systemStatusContext.refreshSystemStatus(),
      ]).subscribe(() => {
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private _loadSystemAlertState(systemAlertState: SystemHealthStatus) {
    if (systemAlertState) {
      this.serverStatusesNew = [];
      systemAlertState.healthStatuses.forEach((status) => {
        if (status.category === StatusCategory.LICENSES) {
          this.licenseStatus = status;
        }

        if (status.category === StatusCategory.ENDPOINTS) {
          this.serverStatusesNew.push(status);
          this.serverStatuses.clear();
        }
      });
      this.serverStatusesNew.forEach((newStatus) => {
        const serviceStatus = {
          cause: newStatus.cause,
          state: newStatus.state,
        };
        const url = newStatus.description.substring(
          newStatus.description.lastIndexOf('[') + 1,
          newStatus.description.lastIndexOf(']')
        );
        this.serverStatuses.set(url, serviceStatus);
      });
      const isNoLicense =
        this.licenseStatus.state === State.WARNING && this.licenseStatus.cause === 'No license installed';

      this._bottomNavService.dispatch(
        new BottomNavUpdateButtonState({
          id: this._addServerButtonId,
          state: {
            buttonDisableState: { disabled: isNoLicense, tooltipKey: 'tkey;admin.servers.add.server.button.tooltip' },
            tooltipVisible: isNoLicense,
          },
        })
      );
    }
  }

  private _getServerStatus(server: Server): { status: string; html: string } {
    if (this._isWarningPresentOnSever(server)) {
      const status = this.serverStatuses.get(server.hostAddress)?.cause;
      return {
        status: status,
        html: `
          <span class="badge me-2 bg-warning" data-automation="misconfigured-server-error">
            ${status}
          </span>
        `,
      };
    } else if (this._isErrorPresentOnServer(server)) {
      const status = this.serverStatuses.get(server.hostAddress)?.cause;
      return {
        status: status,
        html: `
          <span class="badge me-2 bg-danger" data-automation="misconfigured-server-error">
            ${status}
          </span>
        `,
      };
    } else {
      const status = this.serverStatuses.get(server.hostAddress)?.state;
      return {
        status: status,
        html: `
          <span class="badge me-2 bg-success" data-automation="misconfigured-server-error">
            ${status ? status : ''}
          </span>
        `,
      };
    }
  }

  private _generateTable() {
    this.tableRows = this._servers.map((server: Server) => {
      const status = this._getServerStatus(server);

      return {
        cssClass: this._isWarningPresentOnSever(server)
          ? 'table-warning'
          : this._isErrorPresentOnServer(server)
          ? 'table-danger'
          : null,
        content: {
          id: server.id,
          description: server.description,
          hostAddress: server.hostAddress,
          serverType: server.serverType,
          status: status.status,
        },
        html: {
          hostAddress: `<a href="${AdminServersComponent._getServerUrl(server)}" target="_blank">${
            server.hostAddress
          }</a>`,
          status: status.html,
        },
        actions: [
          {
            buttonStyle: ButtonStyles.PRIMARY,
            dataAutomation: 'server-edit',
            icon: SmacsIcons.EDIT,
            onClick: () => this._openEditModal(server),
          },
          {
            buttonStyle: ButtonStyles.DANGER,
            dataAutomation: 'server-delete',
            icon: SmacsIcons.DELETE,
            onClick: () => this._openDeleteModal(server),
            isDisabled: server.disabled || this._isServerUsedByCluster(server),
            tooltip: server.disabled
              ? 'tkey;admin.servers.delete.disabled.tooltip.disabled_server'
              : 'tkey;admin.servers.delete.disabled.tooltip.in_use',
            tooltipDisabled: !(server.disabled || this._isServerUsedByCluster(server)),
          },
        ],
      };
    });

    console.warn(`Initial server state had ${this.getBadServers().length} invalid server configurations`);
  }

  /**
   * When we "fix" an invalid server configuration, the server-side GlobalSettings needs to load the new caches. Maybe
   * one day we can reload *just* the invalid servers.
   *
   * The `currentClusterContext` and `clusterSettingsContext` rely on data from the global settings (which just got
   * refreshed). To reload these, refresh the siteSummaryContext (which will in turn reload everything).
   */
  private _reloadConfig() {
    const handleCb = () => {
      this._siteSummaryContext.refreshSiteSummary().subscribe(() => {
        this._serversContext.getAll().subscribe();

        this._systemStatusContext.refreshSystemStatus().subscribe((systemHealthStatus) => {
          this._reloadingConfigurations = false;
          this._loadSystemAlertState(systemHealthStatus);
          this._updateButtonState(this._reloadButtonId, {
            pending: false,
            buttonDisableState: { disabled: false, tooltipKey: '' },
          });
          this._updateButtonState(this._addServerButtonId, {
            pending: false,
            buttonDisableState: {
              disabled:
                this.licenseStatus.state === State.WARNING && this.licenseStatus.cause === 'No license installed',
              tooltipKey: '',
            },
          });

          this._toastService.push(
            ToastTypes.SUCCESS,
            SmacsIcons.REFRESH,
            'tkey;pages.admin.configurationreloaded.toast.title',
            'tkey;pages.admin.configurationreloaded.toast.message'
          );
          this._isRefreshComplete.next(true);
        });
      });
    };

    if (!this._reloadingConfigurations) {
      this.isLoading = true;
      this._reloadingConfigurations = true;
      this._updateButtonState(this._reloadButtonId, {
        pending: true,
        buttonDisableState: { disabled: true },
      });
      this._updateButtonState(this._addServerButtonId, {
        pending: false,
        buttonDisableState: { disabled: true, tooltipKey: 'tkey;admin.servers.add.server.button.tooltip' },
      });

      this._ucMetadataCacheContext.reloadUcMetadataCache().subscribe(() => handleCb());
    }
  }

  private _updateButtonState(id: string, state: BottomNavButtonState) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: id,
        state: state,
      })
    );
  }

  private _hasServers = (): boolean => {
    return isArray(this._servers) && !!this._servers.length;
  };
}
