<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.certificate_management.about_card.content' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div class="card border-warning mb-3" *ngIf="isSsoEnabled" data-automation="certificate-management-sso-warning">
      <div class="card-body d-flex align-items-center">
        <td rowspan="2">
          <i class="fa fa-warning text-warning fa-2x"></i>
        </td>
        <td>
          <p class="strong text-warning">
            {{ 'tkey;admin.certificate_management.sso_warning.header' | translate }}
          </p>
          <span [innerHTML]="'tkey;admin.certificate_management.sso_warning.text' | translate"></span>
        </td>
      </div>
    </div>
    <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
      <li data-automation="generate-csr-tab" [ngbNavItem]="tabIds.GENERATE_CSR" [domId]="tabIds.GENERATE_CSR">
        <a ngbNavLink class="btn nav-link">{{ 'tkey;certificate_management.generate_csr.navlink' | translate }}</a>
        <ng-template ngbNavContent>
          <app-admin-generate-csr
            [csrTimestamp]="csrTimestamp"
            [displayCsr]="displayCsr"
            [existingCsr]="existingCsr"
            (csrGenerated)="csrGenerated($event)"
          ></app-admin-generate-csr>
        </ng-template>
      </li>
      <li
        data-automation="import-certificate-tab"
        [ngbNavItem]="tabIds.IMPORT_CERTIFICATE"
        [domId]="tabIds.IMPORT_CERTIFICATE"
      >
        <a ngbNavLink class="btn nav-link">
          {{ 'tkey;certificate_management.import_certificate.navlink' | translate }}
        </a>
        <ng-template ngbNavContent>
          <app-admin-import-certificate
            (selectGenerateCsrTab)="selectGenerateCsrTab()"
            [displayCsr]="displayCsr"
            [csrTimestamp]="csrTimestamp | async"
            [existingCsr]="existingCsr"
            (deleteCsr)="csrDeleted()"
          ></app-admin-import-certificate>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
