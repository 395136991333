<div>
  <legend>{{ 'tkey;dialplanmanagement.admin.group.dnranges.navlink' | translate }}</legend>

  <div class="row">
    <div class="col-6">
      <!-- Label outside to allow show/hide the form -->
      <label
        [ngClass]="{
          'smacs-forms-error': extensionRangesDisplayFormValidationState === validationStates.INVALID,
          'smacs-forms-warning': extensionRangesDisplayFormValidationState === validationStates.WARNING
        }"
        data-automation="extension-ranges-label"
        class="smacs-forms-label col-form-label"
      >
        <span class="text-danger smacs-forms-asterisk">*</span>
        <span>
          {{ 'tkey;dialplanmanagement.admin.group.dnranges' | translate }}
        </span>
      </label>
      <ziro-extension-ranges-display-form
        data-automation="extension-ranges-display-form"
        *ngIf="displayRangeForm"
        [entity]="displayRangeFormEntity"
        [groupId]="groupId"
        [clusterId]="clusterId"
        [dialPlanGroups]="dialPlanGroups"
        [siteSummary]="siteSummary"
        [optionalValidators]="displayRangeFormOptionalValidators"
        (smacsFormsUpdate$)="onRangeFormUpdate($event)"
      ></ziro-extension-ranges-display-form>
    </div>
    <div class="col-6">
      <smacs-textarea fieldId="bulkRanges"></smacs-textarea>
      <smacs-button
        [dataAutomation]="'dn-ranges-bulk-ranges-button'"
        [label]="'tkey;dialplanmanagement.admin.group.generate.button'"
        (clicked)="generateBulkRangesClicked()"
      ></smacs-button>
    </div>
  </div>
</div>

<div>
  <legend>{{ 'tkey;dialplanmanagement.admin.group.dnranges.line_appearance_settings.header' | translate }}</legend>
  <smacs-checkbox fieldId="advertise_distinct_mask_for_public_lines"></smacs-checkbox>
  <smacs-text-config fieldId="external_phone_number_mask" [isOneClickEnabled]="false"></smacs-text-config>
  <smacs-text-config fieldId="public_external_phone_number_mask" [isOneClickEnabled]="false"></smacs-text-config>
  <smacs-text-config fieldId="line_text_label" [isOneClickEnabled]="false"></smacs-text-config>
</div>

<div>
  <legend>{{ 'tkey;dialplanmanagement.admin.group.dnranges.dn.settings' | translate }}</legend>
  <smacs-select-config fieldId="route_partition"></smacs-select-config>
  <smacs-multi-checkbox fieldId="urgent_priority"></smacs-multi-checkbox>
  <smacs-select-config fieldId="optional_line_group_membership"></smacs-select-config>
  <smacs-select-config fieldId="auto_answer"></smacs-select-config>
  <smacs-select-config fieldId="monitoring_calling_search_space"></smacs-select-config>
</div>

<div>
  <legend
    (click)="isEntAltSectionVisible = !isEntAltSectionVisible"
    class="clickable-cursor"
    data-automation="dn-ranges-ent-alt-num-header"
  >
    <i [class]="isEntAltSectionVisible ? smacsIcons.INDETERMINATE_SQUARE : smacsIcons.PLUS_SQUARE"></i>
    {{ 'tkey;dialplanmanagement.admin.group.dnranges.enterprise_alternate_number.label' | translate }}
  </legend>

  <div [hidden]="!isEntAltSectionVisible">
    <smacs-text-config fieldId="ent_alt_num_number_mask" [isOneClickEnabled]="false"></smacs-text-config>
    <smacs-multi-checkbox fieldId="ent_alt_num_advertise_globally_via_ils"></smacs-multi-checkbox>
    <smacs-multi-checkbox fieldId="ent_alt_num_is_urgent"></smacs-multi-checkbox>
    <smacs-multi-checkbox fieldId="ent_alt_num_add_to_local_route_partition"></smacs-multi-checkbox>
    <smacs-select-config fieldId="ent_alt_num_route_partition"></smacs-select-config>
  </div>
</div>

<div>
  <legend
    (click)="isE164AltSectionVisible = !isE164AltSectionVisible"
    class="clickable-cursor"
    data-automation="dn-ranges-e164-alt-num-header"
  >
    <i [class]="isE164AltSectionVisible ? smacsIcons.INDETERMINATE_SQUARE : smacsIcons.PLUS_SQUARE"></i>
    {{ 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.label' | translate }}
  </legend>

  <div [hidden]="!isE164AltSectionVisible">
    <smacs-text-config fieldId="e164_alt_num_number_mask" [isOneClickEnabled]="false"></smacs-text-config>
    <smacs-multi-checkbox fieldId="e164_alt_num_advertise_globally_via_ils"></smacs-multi-checkbox>
    <smacs-multi-checkbox fieldId="e164_alt_num_is_urgent"></smacs-multi-checkbox>
    <smacs-multi-checkbox fieldId="e164_alt_num_add_to_local_route_partition"></smacs-multi-checkbox>
    <smacs-select-config fieldId="e164_alt_num_route_partition"></smacs-select-config>
  </div>
</div>

<div>
  <legend
    (click)="isPstnFailoverSectionVisible = !isPstnFailoverSectionVisible"
    class="clickable-cursor"
    data-automation="dn-ranges-pstn-failover-header"
  >
    <i [class]="isPstnFailoverSectionVisible ? smacsIcons.INDETERMINATE_SQUARE : smacsIcons.PLUS_SQUARE"></i>
    {{ 'tkey;dialplanmanagement.admin.group.dnranges.pstn_failover.label' | translate }}
  </legend>

  <div [hidden]="!isPstnFailoverSectionVisible">
    <smacs-select-config fieldId="pstn_failover"></smacs-select-config>
  </div>
</div>
