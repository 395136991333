import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PhoneType } from '../models/service-type';

@Injectable()
export class DeviceService {
  /** Recursively search for the phone type in the url. */
  public static getPhoneTypeFromRoute(routeSnapshot: ActivatedRouteSnapshot): PhoneType {
    if (!!routeSnapshot) {
      const urlSegments = routeSnapshot.url;
      if (urlSegments.some((url) => url.path === 'public-phone')) {
        return PhoneType.DESKPHONE;
      }
      const routePhoneType = urlSegments
        .map((url) => url.path.toUpperCase().replace('-', '_'))
        .find((url) => {
          return url in PhoneType;
        });
      if (routePhoneType) {
        return PhoneType[routePhoneType as keyof typeof PhoneType];
      }
      return this.getPhoneTypeFromRoute(routeSnapshot.parent);
    }
  }
}
