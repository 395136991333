import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuditHeaderService } from '../services/audit-header.service';
import { Observable } from 'rxjs';
import { ExtensionMobility, ExtensionMobilityRef } from '../models/generated/smacsModels';
import { map } from 'rxjs/operators';
import { Nvp } from '../models/nvp';

@Injectable()
export class ExtensionMobilityProfilesResource {
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  get(id: string, cucmServerId: string): Observable<ExtensionMobility> {
    return this.http
      .get<ExtensionMobility>(
        `/services/cisco/macs/cucm-servers/${encodeURIComponent(
          cucmServerId
        )}/extension-mobility-profiles/${encodeURIComponent(id)}`
      )
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  put(extensionMobility: ExtensionMobility, cucmServerId: string, auditTags?: Nvp[]): Observable<ExtensionMobilityRef> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.put<ExtensionMobilityRef>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(
        cucmServerId
      )}/extension-mobility-profiles/${encodeURIComponent(extensionMobility.id)}`,
      extensionMobility,
      options
    );
  }

  post(
    extensionMobility: ExtensionMobility,
    cucmServerId: string,
    auditTags?: Nvp[]
  ): Observable<ExtensionMobilityRef> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.post<ExtensionMobilityRef>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(cucmServerId)}/extension-mobility-profiles`,
      extensionMobility,
      options
    );
  }

  delete(id: string, cucmServerId: string, auditTags?: Nvp[]): Observable<void> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.delete<void>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(
        cucmServerId
      )}/extension-mobility-profiles/${encodeURIComponent(id)}`,
      options
    );
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }
}
