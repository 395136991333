<div
  class="button-container"
  [ngClass]="{ 'button-container--full-width': isFullWidth }"
  [ngbTooltip]="tooltip | translate"
  [disableTooltip]="!tooltip || isTooltipDisabled"
  [container]="'body'"
>
  <a
    *ngIf="type === ButtonTypes.LINK"
    [attr.type]="type"
    class="btn {{ getButtonClass() }} {{ getButtonSize() }} {{ getButtonDisabledClass() }}"
    [attr.href]="!isDisabled ? href : null"
    [attr.data-automation]="dataAutomation"
    [attr.id]="id"
    [ngClass]="{
      'delete-btn': icon === icons.DELETE,
      'hide-focus': focus && isDragItem,
      'drag-item': isDragItem,
      'match-input-lg': buttonSize === ButtonSizes.NORMAL,
      disabled: isDisabled
    }"
    (focus)="focus = true"
    (blur)="focus = false"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </a>

  <button
    *ngIf="type !== ButtonTypes.LINK"
    [attr.type]="type"
    class="btn {{ getButtonClass() }} {{ getButtonSize() }} {{ getButtonDisabledClass() }}"
    (click)="onClick()"
    [attr.data-automation]="dataAutomation"
    [attr.id]="id"
    [ngClass]="{
      'delete-btn': icon === icons.DELETE,
      'hide-focus': focus && isDragItem,
      'drag-item': isDragItem,
      'match-input-lg': buttonSize === ButtonSizes.NORMAL
    }"
    [disabled]="isDisabled || isPending"
    (focus)="focus = true"
    (blur)="focus = false"
  >
    <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
  </button>
</div>

<ng-template #buttonContent>
  <i
    *ngIf="(!iconAppended && icon) || (!iconAppended && isPending && iconPending)"
    [ngClass]="{ 'me-1': getLabel() }"
    class="{{ getIconClass() }}"
    data-automation="smacs-button-pending-icon"
  ></i>
  <svg-icon
    *ngIf="isCustomIcon && !iconAppended && !isPending"
    [ngClass]="{ 'me-1': getLabel() }"
    [name]="icon"
    [svgStyle]="{ 'width.em': 1, height: 'auto' }"
  ></svg-icon>
  <span *ngIf="getLabel()" [ngClass]="labelCssClass" [innerHTML]="getLabel() | translate"></span>
  <i
    *ngIf="(iconAppended && icon) || (iconAppended && isPending && iconPending)"
    [ngClass]="{ 'ms-1': getLabel() }"
    class="{{ getIconClass() }}"
    data-automation="smacs-button-pending-icon"
  ></i>
  <svg-icon
    *ngIf="isCustomIcon && iconAppended && !isPending"
    [ngClass]="{ 'ms-1': getLabel() }"
    [name]="icon"
    [svgStyle]="{ 'width.em': 1, height: 'auto' }"
  ></svg-icon>
</ng-template>
