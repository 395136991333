import * as moment from 'moment';
import { Injectable } from '@angular/core';

@Injectable()
export class ZeroTouchSchedulingService {
  getNextRunTimeMessage(periodicJobMinutes: string): string {
    if (!periodicJobMinutes || parseInt(periodicJobMinutes) < 1) {
      return '';
    }
    const now = moment.utc();
    const lastMidnight = moment.utc().startOf('day');

    const periodInMilli = parseInt(periodicJobMinutes) * 60000;
    const nowInMilli = now.valueOf();
    const lastMidnightInMilli = lastMidnight.valueOf();

    const timeElapsedSinceLastMidnightInMilli = nowInMilli - lastMidnightInMilli;
    const numOfCyclesSinceLastMidnight = Math.trunc(timeElapsedSinceLastMidnightInMilli / periodInMilli);
    const nextCycleStartTimeInMilli = (numOfCyclesSinceLastMidnight + 1) * periodInMilli + lastMidnightInMilli;
    const cycleAfterNextStartTimeInMilli = nextCycleStartTimeInMilli + periodInMilli;

    const formatString = 'YYYY-MM-DD hh:mm A';
    const localRunTime1 = moment.unix(nextCycleStartTimeInMilli / 1000);
    const localRunTime2 = moment.unix(cycleAfterNextStartTimeInMilli / 1000);

    const localRunTime1Formatted = localRunTime1.format(formatString);
    const localRunTime2Formatted = localRunTime2.format(formatString);
    const utcRunTime1Formatted = moment.utc(localRunTime1).format(formatString);
    const utcRunTime2Formatted = moment.utc(localRunTime2).format(formatString);

    return `The next two run times will be: <strong>${localRunTime1Formatted} (${utcRunTime1Formatted} UTC)</strong> and <strong>${localRunTime2Formatted} (${utcRunTime2Formatted} UTC)</strong>`;
  }
}
