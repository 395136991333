import { Component, Input, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { IlsAlternateNumberFieldConfig, SelectFieldConfig } from '../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsCheckboxConfig } from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { cloneDeep } from 'lodash';
import { of } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { TranslateService } from '@ngx-translate/core';

export interface IlsDetailsEntity {
  enterpriseAlternateNumber: {
    numberMask: string;
    urgent: boolean;
    addToLocalRoutePartition: boolean;
    routePartition: string;
    advertiseGloballyViaIls: boolean;
  };
  e164AlternateNumber: {
    numberMask: string;
    urgent: boolean;
    addToLocalRoutePartition: boolean;
    routePartition: string;
    advertiseGloballyViaIls: boolean;
  };
  pstnFailover: string;
}

export interface IlsDetailsFormData {
  enterpriseNumberMask: string;
  enterpriseUrgent: boolean;
  enterpriseAddToLocalRoutePartition: boolean;
  enterpriseRoutePartition: string;
  enterpriseAdvertiseGloballyViaIls: boolean;
  e164NumberMask: string;
  e164Urgent: boolean;
  e164AddToLocalRoutePartition: boolean;
  e164RoutePartition: string;
  e164AdvertiseGloballyViaIls: boolean;
  pstnFailover: string;
}

export interface IlsDetailsFieldConfigs {
  enterpriseAlternateNumber: IlsAlternateNumberFieldConfig;
  e164AlternateNumber: IlsAlternateNumberFieldConfig;
  pstnFailover: SelectFieldConfig<string>;
}
@Component({
  selector: 'smacs-directory-number-ils-details-form',
  templateUrl: './directory-number-ils-details-form.component.html',
})
export class DirectoryNumberIlsDetailsFormComponent
  extends SmacsFormAbstractDirective<IlsDetailsEntity, IlsDetailsFormData>
  implements OnInit
{
  @Input() fieldConfigs: IlsDetailsFieldConfigs;
  formConfig: SmacsFormConfig;

  constructor(protected smacsFormStateService: SmacsFormStateService, private _translateService: TranslateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initFormConfig();
    this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<IlsDetailsEntity>) => {
      const entityCopy = cloneDeep(data.new);
      const oldEnterPriseAddToPartition = data.old.enterpriseAlternateNumber.addToLocalRoutePartition;
      const newEnterPriseAddToPartition = data.new.enterpriseAlternateNumber.addToLocalRoutePartition;
      const oldE164AddToPartition = data.old.e164AlternateNumber.addToLocalRoutePartition;
      const newE164AddToPartition = data.new.e164AlternateNumber.addToLocalRoutePartition;
      if (oldEnterPriseAddToPartition !== newEnterPriseAddToPartition && !newEnterPriseAddToPartition) {
        entityCopy.enterpriseAlternateNumber.routePartition = '';
        this.entitySource.next(entityCopy);
      }
      if (oldE164AddToPartition !== newE164AddToPartition && !newE164AddToPartition) {
        entityCopy.e164AlternateNumber.routePartition = '';
        this.entitySource.next(entityCopy);
      }
      if (
        data.old.enterpriseAlternateNumber.numberMask !== data.new.enterpriseAlternateNumber.numberMask ||
        data.old.e164AlternateNumber.numberMask !== data.new.e164AlternateNumber.numberMask
      ) {
        this.fieldChannels['pstnFailover'].validateSource.next();
      }
    });
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        enterpriseNumberMask: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.number_mask.label',
          dataAutomation: 'dn-ils-details-enterpriseNumberMask',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          defaultValue: () => this.fieldConfigs.enterpriseAlternateNumber.numberMask.defaultValue,
          required: () => this.fieldConfigs.enterpriseAlternateNumber.numberMask.required,
          hidden: () => !this.fieldConfigs.enterpriseAlternateNumber.numberMask.show,
          validation: [
            {
              validator: (val: string) => {
                return !val || /^\+?[0-9X]+(?:-\+?[0-9X]+)*$/.test(val.trim())
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: this._translateService.instant(
                'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.number_mask.validation'
              ),
            },
          ],
        },
        enterpriseAddToLocalRoutePartition: {
          label:
            'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.e164_alternate_number.add_to_local_route_partition.label',
          dataAutomation: 'dn-ils-details-enterpriseAddToLocalRoutePartition',
          componentConfig: new SmacsCheckboxConfig(),
          defaultValue: () => this.fieldConfigs.enterpriseAlternateNumber.addToLocalRoutePartition.defaultValue,
          hidden: () => !this.fieldConfigs.enterpriseAlternateNumber.addToLocalRoutePartition.show,
        },
        enterpriseRoutePartition: {
          label:
            'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.e164_alternate_number.alternate_number_route_partition.label',
          dataAutomation: 'dn-ils-details-enterpriseRoutePartition',
          componentConfig: new SmacsSelectConfig({
            options: this.fieldConfigs.enterpriseAlternateNumber.routePartition.possibleOptions,
          }),
          defaultValue: () => this.fieldConfigs.enterpriseAlternateNumber.routePartition.defaultValue,
          required: () => this.fieldConfigs.enterpriseAlternateNumber.routePartition.required,
          hidden: () => !this.fieldConfigs.enterpriseAlternateNumber.routePartition.show,
          disabled: () => !this.formData.enterpriseAddToLocalRoutePartition,
        },
        enterpriseUrgent: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.e164_alternate_number.is_urgent.label',
          dataAutomation: 'dn-ils-details-enterpriseUrgent',
          componentConfig: new SmacsCheckboxConfig(),
          defaultValue: () => this.fieldConfigs.enterpriseAlternateNumber.urgent.defaultValue,
          hidden: () => !this.fieldConfigs.enterpriseAlternateNumber.urgent.show,
        },
        enterpriseAdvertiseGloballyViaIls: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.e164_alternate_number.advertise_global.label',
          dataAutomation: 'dn-ils-details-enterpriseAdvertiseGloballyViaIls',
          componentConfig: new SmacsCheckboxConfig(),
          defaultValue: () => this.fieldConfigs.enterpriseAlternateNumber.advertiseGloballyViaIls.defaultValue,
          hidden: () => !this.fieldConfigs.enterpriseAlternateNumber.advertiseGloballyViaIls.show,
        },
        e164NumberMask: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.number_mask.label',
          dataAutomation: 'dn-ils-details-e164NumberMask',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          defaultValue: () => this.fieldConfigs.e164AlternateNumber.numberMask.defaultValue,
          required: () => this.fieldConfigs.e164AlternateNumber.numberMask.required,
          hidden: () => !this.fieldConfigs.e164AlternateNumber.numberMask.show,
          validation: [
            {
              validator: (val: string) => {
                return !val || /^\+?[0-9X]+(?:-\+?[0-9X]+)*$/.test(val.trim())
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: this._translateService.instant(
                'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.number_mask.validation'
              ),
            },
          ],
        },
        e164AddToLocalRoutePartition: {
          label:
            'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.e164_alternate_number.add_to_local_route_partition.label',
          dataAutomation: 'dn-ils-details-e164AddToLocalRoutePartition',
          componentConfig: new SmacsCheckboxConfig(),
          defaultValue: () => this.fieldConfigs.e164AlternateNumber.addToLocalRoutePartition.defaultValue,
          hidden: () => !this.fieldConfigs.e164AlternateNumber.addToLocalRoutePartition.show,
        },
        e164RoutePartition: {
          label:
            'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.e164_alternate_number.alternate_number_route_partition.label',
          dataAutomation: 'dn-ils-details-e164RoutePartition',
          componentConfig: new SmacsSelectConfig({
            options: this.fieldConfigs.e164AlternateNumber.routePartition.possibleOptions,
          }),
          defaultValue: () => this.fieldConfigs.e164AlternateNumber.routePartition.defaultValue,
          required: () => this.fieldConfigs.e164AlternateNumber.routePartition.required,
          hidden: () => !this.fieldConfigs.e164AlternateNumber.routePartition.show,
          disabled: () => !this.formData.e164AddToLocalRoutePartition,
        },
        e164Urgent: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.e164_alternate_number.is_urgent.label',
          dataAutomation: 'dn-ils-details-e164Urgent',
          componentConfig: new SmacsCheckboxConfig(),
          defaultValue: () => this.fieldConfigs.e164AlternateNumber.urgent.defaultValue,
          hidden: () => !this.fieldConfigs.e164AlternateNumber.urgent.show,
        },
        e164AdvertiseGloballyViaIls: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.e164_alternate_number.advertise_global.label',
          dataAutomation: 'dn-ils-details-e164AdvertiseGloballyViaIls',
          componentConfig: new SmacsCheckboxConfig(),
          defaultValue: () => this.fieldConfigs.e164AlternateNumber.advertiseGloballyViaIls.defaultValue,
          hidden: () => !this.fieldConfigs.e164AlternateNumber.advertiseGloballyViaIls.show,
        },
        pstnFailover: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.pstn_failover.advertised_number.label',
          dataAutomation: 'dn-ils-details-pstnFailover',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfigs.pstnFailover.possibleOptions }),
          defaultValue: () => this.fieldConfigs.pstnFailover.defaultValue,
          required: () => this.fieldConfigs.pstnFailover.required,
          hidden: () => !this.fieldConfigs.pstnFailover.show,
        },
      },
    };
  }

  toFormData = (entity: IlsDetailsEntity): IlsDetailsFormData => {
    return {
      enterpriseNumberMask: entity.enterpriseAlternateNumber.numberMask,
      enterpriseUrgent: entity.enterpriseAlternateNumber.urgent,
      enterpriseAddToLocalRoutePartition: entity.enterpriseAlternateNumber.addToLocalRoutePartition,
      enterpriseRoutePartition: entity.enterpriseAlternateNumber.routePartition,
      enterpriseAdvertiseGloballyViaIls: entity.enterpriseAlternateNumber.advertiseGloballyViaIls,
      e164NumberMask: entity.e164AlternateNumber.numberMask,
      e164Urgent: entity.e164AlternateNumber.urgent,
      e164AddToLocalRoutePartition: entity.e164AlternateNumber.addToLocalRoutePartition,
      e164RoutePartition: entity.e164AlternateNumber.routePartition,
      e164AdvertiseGloballyViaIls: entity.e164AlternateNumber.advertiseGloballyViaIls,
      pstnFailover: entity.pstnFailover,
    };
  };

  toEntity = (formData: IlsDetailsFormData): IlsDetailsEntity => {
    return {
      enterpriseAlternateNumber: {
        numberMask: formData.enterpriseNumberMask,
        urgent: formData.enterpriseUrgent,
        addToLocalRoutePartition: formData.enterpriseAddToLocalRoutePartition,
        routePartition: formData.enterpriseRoutePartition,
        advertiseGloballyViaIls: formData.enterpriseAdvertiseGloballyViaIls,
      },
      e164AlternateNumber: {
        numberMask: formData.e164NumberMask,
        urgent: formData.e164Urgent,
        addToLocalRoutePartition: formData.e164AddToLocalRoutePartition,
        routePartition: formData.e164RoutePartition,
        advertiseGloballyViaIls: formData.e164AdvertiseGloballyViaIls,
      },
      pstnFailover: formData.pstnFailover,
    };
  };

  protected submit() {
    return of(null);
  }
}
