<div class="container mb-1">
  <div class="row col-6">
    <smacs-radio fieldId="enterByType"></smacs-radio>
  </div>

  <div class="row col-6" [hidden]="formData.enterByType !== 'Text'">
    <smacs-textarea fieldId="bulkNumbers"></smacs-textarea>
  </div>

  <div class="row col-6" *ngIf="formData.enterByType !== 'Text'">
    <label
      [ngClass]="{
        'smacs-forms-error': extensionRangesDisplayFormValidationState === validationStates.INVALID,
        'smacs-forms-warning': extensionRangesDisplayFormValidationState === validationStates.WARNING
      }"
      data-automation="extension-ranges-label"
      class="smacs-forms-label col-form-label"
    >
      <span class="text-danger smacs-forms-asterisk">*</span>
      <span>
        {{ 'tkey;admin.order_numbers.check_number_portability.ranges.label' | translate }}
      </span>
    </label>
    <ziro-extension-ranges-display-form
      data-automation="extension-ranges-display-form"
      [entity]="rangesFormEntity"
      [optionalValidators]="rangesFormOptionalValidators"
      [isRequired]="true"
      [isZpm]="true"
      (smacsFormsUpdate$)="onRangeFormUpdate($event)"
    ></ziro-extension-ranges-display-form>
  </div>
</div>
