import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { DialPlanManagementGroup } from '../../../shared/models/generated/smacsModels';
import { ModelContextAbstract } from '../../../shared/contexts/abstract/model.context.abstract';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()
export class DialPlanGroupContext extends ModelContextAbstract<DialPlanManagementGroup> {
  protected _baseUrl = '/services/cisco/admin/dial-plan/groups';

  _get: (id: number) => Observable<DialPlanManagementGroup>;
  _post: (group: DialPlanManagementGroup) => Observable<DialPlanManagementGroup>;
  _put: (id: string, group: DialPlanManagementGroup) => Observable<DialPlanManagementGroup>;
  _delete: (id: string) => Observable<void>;

  private _stateSource = new ReplaySubject<DialPlanManagementGroup[]>(1);
  state$ = this._stateSource.asObservable();

  constructor(protected $http: HttpClient) {
    super($http);

    this.init().subscribe();
  }

  init(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._getAll().subscribe((results: DialPlanManagementGroup[]) => {
        this._stateSource.next(results);
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }

  getAll() {
    this._getAll().subscribe((dialPlanGroups: DialPlanManagementGroup[]) => {
      this._stateSource.next(dialPlanGroups);
    });
  }

  post(dialPlanGroup: DialPlanManagementGroup): Observable<DialPlanManagementGroup> {
    return new Observable((subscriber: Subscriber<DialPlanManagementGroup>) => {
      this._post(dialPlanGroup).subscribe((data) => {
        this.getAll();
        subscriber.next(data);
      });
    });
  }

  put(dialPlanGroupId: string, dialPlanGroup: DialPlanManagementGroup): Observable<DialPlanManagementGroup> {
    return new Observable((subscriber: Subscriber<DialPlanManagementGroup>) => {
      this._put(dialPlanGroupId, dialPlanGroup).subscribe((data: DialPlanManagementGroup) => {
        this.getAll();
        subscriber.next(data);
      });
    });
  }

  delete(dialPlanGroupId: string): Observable<void> {
    return this._delete(dialPlanGroupId).pipe(
      tap(() => {
        this.getAll();
      })
    );
  }
}
