import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PhoneFieldConfigResource } from '../../shared/resources/field-config/phone-field-config.resource';
import {
  EndUserRef,
  EndUserResult,
  ExtensionMobility,
  ExtensionMobilityFieldConfig,
  ExtensionMobilityRef,
  Global360View,
  ModelProtocolFieldConfig,
  PhoneButtonTemplateRef,
  PhoneFieldConfig,
  PhoneServiceSubscription,
  PostPhoneButtonTemplate,
} from '../../../shared/models/generated/smacsModels';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { SiteContext } from '../../shared/contexts/site.context';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { catchError, filter, first, map, switchMap, tap } from 'rxjs/operators';
import {
  BottomNavClearButtonsList,
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { DragdropUiContext } from '../../../shared/phone-buttons/contexts/dragdrop-ui.context';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { PhoneButtonTemplateService } from '../../../shared/services/phone-button-template.service';
import { cloneDeep, isEqual, omit } from 'lodash';
import { ToastService } from '../../../shared/services/toast.service';
import { PhoneButtonTemplateFieldConfigResource } from '../../../shared/resources/phone-button-template-field-config-resource.service';
import { SmacsFormsUpdate, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { ButtonStyles } from '../../../button/button.component';
import { PhoneButtonsService } from '../../../shared/phone-buttons/shared/phone-buttons.service';
import { PhoneType, ServiceType } from '../../../shared/models/service-type';
import { PhoneButtonsComponent } from '../../../shared/phone-buttons/phone-buttons.component';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import { PhoneFieldsEntity, PhoneFieldsFormComponent } from '../phone/phone-fields-form/phone-fields-form.component';
import { PhoneButtonTemplateFormComponent } from '../phone/phone-button-template-form/phone-button-template-form.component';
import { ExtMobilityUiContext } from '../../../shared/phone-buttons/contexts/ext-mobility-ui.context';
import { ExtensionMobilityProfilesResource } from '../../../shared/resources/extension-mobility-profiles.resource';
import { EndUserResource } from '../../../shared/resources/end-user.resource';
import { Nvp } from '../../../shared/models/nvp';
import { HelpdeskOptions, HelpdeskOptionsContext } from '../../shared/contexts/helpdesk-options.context';
import { DeviceAbstractDirective } from '../device/device-abstract.directive';
import { ModelProtocolFormData } from '../phone/phone-model-protocol-form/phone-model-protocol-form.component';

@Component({
  selector: 'smacs-extension-mobility',
  templateUrl: './extension-mobility.component.html',
  providers: [ExtMobilityUiContext],
})
export class ExtensionMobilityComponent
  extends DeviceAbstractDirective<ExtensionMobility, ExtensionMobilityFieldConfig>
  implements OnInit, AfterViewInit, OnDestroy
{
  // i/o
  @Input() endUserRef: EndUserRef;
  @Input() global360View: Global360View;

  @ViewChildren(PhoneFieldsFormComponent) phoneFieldsFormComponent: QueryList<PhoneFieldsFormComponent>;
  @ViewChildren(PhoneButtonTemplateFormComponent)
  phoneButtonTemplateFormComponent: QueryList<PhoneButtonTemplateFormComponent>;
  @ViewChildren(PhoneButtonsComponent) phoneButtonsComponent: PhoneButtonsComponent;

  phoneType = PhoneType.EXTENSION_MOBILITY;

  isLoadingPhoneFields = true;
  loadingSubscriptions = {
    phoneUiContext: true,
    phoneFieldConfig: true,
    helpdeskOptionsContext: true,
    modelProtocolFieldConfig: true,
  };
  isExistingPhoneWithServiceSubscriptions: boolean;

  // input for child components
  phoneFieldConfig: PhoneFieldConfig | ExtensionMobilityFieldConfig;
  isFormStateInvalid = false;
  isManualUpdate = false;
  hasModelProtocolUpdateBeenRun = false;
  isFormUpdated = false;
  isInitialLoad = true;
  isErrorThrown = false;

  // private members
  private _auditTags: Nvp[];
  private _isSubmitted = false;
  private _subscription = new Subscription();
  private _lastSupportedServiceSubscriptions: PhoneServiceSubscription[];
  private _initialPhoneName = '';
  private _endUserResult: EndUserResult;
  private _selectedKem: string[];

  constructor(
    protected translateService: TranslateService,
    protected smacsModalService: SmacsModalService,
    protected phoneButtonTemplateService: PhoneButtonTemplateService,
    protected phoneButtonsService: PhoneButtonsService,
    protected extMobilityUiContext: ExtMobilityUiContext,
    private changeDetectorRef: ChangeDetectorRef,
    private bottomNavService: BottomNavService,
    private route: ActivatedRoute,
    private siteContext: SiteContext,
    private siteSummaryContext: SiteSummaryContext,
    private dragdropUiContext: DragdropUiContext,
    private extMobilityProfilesResource: ExtensionMobilityProfilesResource,
    private helpdeskOptionsContext: HelpdeskOptionsContext,
    private toastService: ToastService,
    private phoneButtonTemplateFieldConfigResource: PhoneButtonTemplateFieldConfigResource,
    private smacsFormStateService: SmacsFormStateService,
    private userDetailUiContext: UserDetailUiContext,
    private global360ViewContext: Global360ViewContext,
    private phoneFieldConfigResource: PhoneFieldConfigResource,
    private endUserResource: EndUserResource
  ) {
    super(translateService, smacsModalService, phoneButtonTemplateService, phoneButtonsService, extMobilityUiContext);
  }

  ngOnInit() {
    this._auditTags = this.endUserRef ? [{ name: 'username', value: this.endUserRef.username }] : null;
    this._phoneId = this.route.snapshot?.params?.phoneId as string;

    this._initSiteContexts();
    this._initHelpdeskOptionsContext();
  }

  ngAfterViewInit() {
    this.phoneFieldsFormComponent.changes.subscribe((formComponents: QueryList<PhoneFieldsFormComponent>) => {
      if (formComponents.first) {
        formComponents.first.isFormValid = this._formsAreValid.bind(this);
      }
    });
    this.phoneButtonTemplateFormComponent.changes.subscribe(
      (formComponents: QueryList<PhoneButtonTemplateFormComponent>) => {
        if (formComponents.first) {
          formComponents.first.isFormValid = this._formsAreValid.bind(this);
        }
      }
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
    this.bottomNavService.dispatch(new BottomNavClearButtonsList());
  }

  onModelProtocolFormUpdate(modelProtocolUpdate: SmacsFormsUpdate<ModelProtocolFormData>) {
    this._validationStates.modelProtocolValidationState = modelProtocolUpdate.valid;
    this._updateBottomNavErrorState();

    const model = modelProtocolUpdate.new.model;
    const protocol = modelProtocolUpdate.new.protocol;

    if (!this.hasModelProtocolUpdateBeenRun && this.phone.id) {
      this.hasModelProtocolUpdateBeenRun = true;
      this.isLoadingPhoneFields = false;
    }

    if (
      model &&
      protocol &&
      (!isEqual(modelProtocolUpdate.new, modelProtocolUpdate.old) ||
        (!this.hasModelProtocolUpdateBeenRun && !this.phone.id))
    ) {
      this.hasModelProtocolUpdateBeenRun = true;
      this.loadingSubscriptions.phoneFieldConfig = true;

      this._onModelProtocolChange(model, modelProtocolUpdate.old?.model, protocol)
        .pipe(
          switchMap((confirmed) => {
            if (confirmed) {
              this.isManualUpdate = true;
              this.extMobilityUiContext.setPhoneTemplate(null);
              this.modelProtocolFormData = cloneDeep(modelProtocolUpdate.new);
              return this.extMobilityUiContext.getFieldConfig(this.siteId.toString(), model, protocol);
            } else {
              this.modelProtocolFormData = {
                ...this.modelProtocolFormData,
              };
              this.loadingSubscriptions.phoneFieldConfig = false;
              return of(null);
            }
          }),
          filter((fieldConfig) => !!fieldConfig),
          switchMap((fieldConfig) => {
            if (!fieldConfig.phoneServices && this.phone?.serviceSubscriptions) {
              this._lastSupportedServiceSubscriptions = cloneDeep(this.phone.serviceSubscriptions);
            }

            this.isFormUpdated = true;
            return this.extMobilityUiContext.setupPhone(
              this.endUserRef?.username,
              this.siteId,
              model,
              protocol,
              this._phoneId,
              false,
              this.isFormUpdated
            );
          }),
          switchMap((phone) => {
            if (this.phone?.buttons && phone) {
              phone.buttons[0] = this.phone.buttons[0];
            }

            if (this.phoneFieldsEntity?.ctiAssociatedEndUsers) {
              // The phone fields are initially all undefined while the phone is being set up.
              // If ctiAssociatedEndUsers is configured then the phone has been set up and it's ready to copy.
              phone = {
                ...phone,
                ...this.phoneFieldsEntity,
              };
            }

            // If model or protocol were changed, we need to update the buttons and button template.
            if (!this.isAutomaticPhoneButtonTemplateMode) {
              return this.phoneButtonTemplateService
                .findTemplatesByModelAndProtocol(model, protocol, this.cucmServerId)
                .pipe(
                  map((results) => {
                    this.possiblePhoneButtonTemplates = results.map((res) => res.ref);
                    return phone;
                  })
                );
            } else {
              return of(phone);
            }
          })
        )
        .subscribe((phone) => {
          this._setPhoneState(protocol, model, phone);
        });
    }
  }

  onPhoneFieldsChange(phoneFieldsUpdate: SmacsFormsUpdate<PhoneFieldsEntity>) {
    this._validationStates.phoneFieldsValidationState = phoneFieldsUpdate.valid;
    this._updateBottomNavErrorState();

    if (!isEqual(phoneFieldsUpdate.new, phoneFieldsUpdate.old)) {
      this.phoneFieldsEntity = cloneDeep(phoneFieldsUpdate.new);
      this.extMobilityUiContext.set({ ...this.phone, ...this.modelProtocolFormData, ...this.phoneFieldsEntity });
    }
  }

  onPhoneButtonTemplateChange($event: SmacsFormsUpdate<PhoneButtonTemplateRef>) {
    if (!$event.new || isEqual($event.new, $event.old)) {
      return;
    }
    this.isPhoneButtonTemplateChangePending = true;
    this._checkForConflictAndGetTemplate($event.new, $event.old).subscribe(() => {
      this.isPhoneButtonTemplateChangePending = false;
    });
  }

  onPhoneButtonValidationStateChange($event: SmacsFormsValidationState) {
    this._validationStates.phoneButtonValidationState = $event;
    this._updateBottomNavErrorState();
  }

  onExpansionModuleSelected(module: string[]) {
    this._selectedKem = module;
  }

  onPhoneServiceSubscriptionsChange(update: SmacsFormsUpdate<PhoneServiceSubscription[]>) {
    if (!isEqual(update.new, update.old)) {
      if (update.old?.length > update.new.length) {
        const subscriptionsToBeRemoved = update.old.filter(
          (sub) => !update.new.some((service) => service.phoneServiceName === sub.phoneServiceName)
        );
        this._clearServiceUrlButton(subscriptionsToBeRemoved);
      }
      this.extMobilityUiContext.set({ ...this.phone, serviceSubscriptions: update.new });
    }
    this._validationStates.phoneServiceSubscriptionsValidationState = update.valid;
    this._updateBottomNavErrorState();
  }

  private _initSiteContexts() {
    const contextSubs = combineLatest([
      this.siteContext.state$.pipe(first()),
      this.siteSummaryContext.state$,
    ]).subscribe(([site, siteSummary]) => {
      this.siteId = Number(site.id);
      this.cucmServerId = this.siteSummaryContext.findCucmServerIdForSite(siteSummary, Number(site.id));
      this.extMobilityUiContext.phoneFieldConfigState$.subscribe((fieldConfig) => {
        this.fieldConfig = fieldConfig as ExtensionMobilityFieldConfig;
        if (!this.isManualUpdate) {
          this.loadingSubscriptions.phoneFieldConfig = false;
        }
        this._checkIsLoaded();
      });
      this._subscribeToModelProtocolFieldConfigState();
    });
    this._subscription.add(contextSubs);
  }

  private _subscribeToModelProtocolFieldConfigState() {
    this.extMobilityUiContext.modelProtocolFieldConfigState$
      .pipe(first())
      .subscribe((modelProtocolFieldConfig: ModelProtocolFieldConfig) => {
        this.modelProtocolFieldConfig = modelProtocolFieldConfig;
        this._subscribeToPhoneState();
      });
  }

  private _subscribeToPhoneState() {
    const phoneUiContextSub = this.extMobilityUiContext.phoneState$.subscribe((extMobility) => {
      this._phoneId = extMobility.id;
      this.phone = cloneDeep(extMobility);

      this.modelProtocolFormData = {
        model: extMobility?.model,
        protocol: extMobility?.protocol,
      };

      this.phoneFieldsEntity = this._mapToPhoneFieldsEntity(this.phone);

      if (this.isExistingPhoneWithServiceSubscriptions == null) {
        this.isExistingPhoneWithServiceSubscriptions = extMobility.serviceSubscriptions?.length > 0;
      }

      if (this.isInitialLoad && !!this._phoneId) {
        this.isInitialLoad = false;
        const phoneFieldReq = this.phoneFieldConfigResource.get(PhoneType.EXTENSION_MOBILITY, {
          username: this.endUserRef.username,
          siteId: this.userDetailUiContext.getCurrentSite().id.toString(),
          model: extMobility.model,
          protocol: extMobility.protocol,
        });

        phoneFieldReq.subscribe((phoneFieldConfig) => {
          this.phoneFieldConfig = phoneFieldConfig;
          this.loadingSubscriptions.modelProtocolFieldConfig = false;
          this._checkIsLoaded();
        });
      } else {
        this.loadingSubscriptions.modelProtocolFieldConfig = false;
        this._checkIsLoaded();
      }

      if (this._phoneId && !this._initialPhoneName) {
        this._initialPhoneName = extMobility.name;
      }
      this._isChangeOnlyInButtons(extMobility);
      this.phoneButtonTemplateEntity = extMobility.phoneButtonTemplate;
      if (!!this.phone.model && !!this.phone.protocol) {
        this.dragdropUiContext.setDeviceMetaDataCache(this.cucmServerId, extMobility);
        if (this.phone.id) {
          this.extMobilityUiContext
            .getFieldConfig(this.siteId.toString(), this.phone.model, this.phone.protocol)
            .subscribe(() => {
              this.phone.phoneButtonTemplate = extMobility.phoneButtonTemplate;
            });
        }

        if (this.phoneButtonTemplateFieldConfig === undefined) {
          // Loading an existing phone, we need the phone button template for the phone buttons.
          // Assigning null kind of silly thing to do but this is how we ensure that the api is only called once.
          this.phoneButtonTemplateFieldConfig = null;
          this.phoneButtonTemplateFieldConfigResource
            .post({
              model: extMobility.model,
              protocol: extMobility.protocol,
              siteId: this.siteId,
            })
            .subscribe((phoneButtonTemplateFieldConfig) => {
              this.phoneButtonTemplateFieldConfig = phoneButtonTemplateFieldConfig;
            });
        }
        if (!this.isAutomaticPhoneButtonTemplateMode && !this.possiblePhoneButtonTemplates) {
          // Loading an existing phone... we need the possible phone button template options.
          this.phoneButtonTemplateService
            .findTemplatesByModelAndProtocol(extMobility.model, extMobility.protocol, this.cucmServerId)
            .subscribe((results) => {
              this.possiblePhoneButtonTemplates = results.map((res) => res.ref);
            });
        }
      }

      if (!this._originalPhoneButtonTemplateFeatures) {
        this._originalPhoneButtonTemplateFeatures = {
          features: extMobility.buttons.map((button) => button.type),
          model: extMobility.model,
          protocol: extMobility.protocol,
        } as PostPhoneButtonTemplate;
      }
      this.loadingSubscriptions.phoneUiContext = false;
      this._checkIsLoaded();

      this.changeDetectorRef.detectChanges();
    });
    this._subscription.add(phoneUiContextSub);
  }

  private _initHelpdeskOptionsContext() {
    const helpdeskOptionsContextSub = this.helpdeskOptionsContext.state$.subscribe(
      (helpdeskOptions: HelpdeskOptions) => {
        if (this.helpdeskOptionsContext.isCiscoHelpdeskOptions(helpdeskOptions)) {
          this.isAutomaticPhoneButtonTemplateMode = helpdeskOptions.automaticPhoneTemplateSelectionEnabled;
          this.isDisplayEnhancedLineMode = helpdeskOptions.displayEnhancedLineMode;
        }

        this.loadingSubscriptions.helpdeskOptionsContext = false;
        this._checkIsLoaded();
      }
    );
    this._subscription.add(helpdeskOptionsContextSub);
  }

  private _confirmDeletion(): Observable<boolean> {
    return new Observable((subscriber) => {
      this.extMobilityProfilesResource
        .delete(this.phone.id, this.cucmServerId.toString(), this._auditTags)
        .subscribe(() => {
          const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
          if (global360) {
            global360.extensionMobilities = global360.extensionMobilities.filter(
              (extensionMobilityRef: ExtensionMobilityRef) => extensionMobilityRef.id !== this.phone.id
            );
            this.global360ViewContext._stateSource.next(global360);
          }
          this.smacsFormStateService.setIsFormDirty(false);
          this.toastService.pushDeleteToast(ServiceType.EXTENSION_MOBILITY, this._initialPhoneName);
          this.delete$.next();
          subscriber.next(true);
          subscriber.complete();
        });
    });
  }

  private _deletePhone() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        title: this.translateService.instant('tkey;userdetail.extension.mobility.delete.confirmation.modal.header'),
        promptBody: this.translateService.instant('tkey;userdetail.extension.mobility.delete.confirmation.modal.text', {
          phoneName: this._initialPhoneName,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._confirmDeletion(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _savePhone() {
    this._isSubmitted = true;

    this._setBottomNavButtonsState('save');
    this.smacsFormStateService
      .checkForPendingFields({
        fieldComponents: this.phoneFieldsFormComponent.last.fieldComponents,
        validationStates: this._validationStates,
      })
      .subscribe((valid) => {
        if (!valid) {
          this._updateBottomNavErrorState();
          this._setBottomNavButtonsState(null);
          this.phoneFieldsFormComponent.first._validateAndSubmitSource.next(true);
          if (this.phoneButtonTemplateFormComponent?.first) {
            this.phoneButtonTemplateFormComponent.first._validateAndSubmitSource.next(true);
          }
          this.isFormStateInvalid = true;
        } else {
          if (!!this.phoneButtonTemplateEntity && this.phoneButtonTemplateEntity !== this.phone.phoneButtonTemplate) {
            this.phone.phoneButtonTemplate = this.phoneButtonTemplateEntity;
          }
          this._associateCurrentUserToLines();
          this._assignPhoneButtonTemplate()
            .pipe(
              switchMap(() => {
                this.extMobilityUiContext.set(this.phone);
                return this._createOrUpdateExtMobility();
              }),
              tap((ref) => {
                this._setEnableCti().subscribe(() => {
                  if (!this.endUserRef) {
                    this.setFormsPending(null);
                  }
                  this.smacsFormStateService.setIsFormDirty(false);
                  this.save$.next(ref);
                });
              }),
              catchError((err) => {
                this.setFormsPending(null);
                this._setErrorThrown();
                throw err;
              })
            )
            .subscribe();

          window.setTimeout(() => this.setFormsPending('save'));
        }
      });
  }

  private _setErrorThrown() {
    this.isErrorThrown = true;
    this.changeDetectorRef.detectChanges();
  }

  /** After saving extension mobility, set enableCti to true if it is false **/
  private _setEnableCti(): Observable<EndUserRef | void[]> {
    this._endUserResult = { ...this.userDetailUiContext.getCurrentEnduser() };

    return this.endUserResource.get(this._endUserResult.ref.id, this._endUserResult.ref.serverId).pipe(
      switchMap((endUser) => {
        if (!endUser.enableCti) {
          endUser.enableCti = true;
          return this.endUserResource.put(endUser, this._endUserResult.ref.serverId);
        }
        return of(null);
      })
    );
  }

  private _formsAreValid(): boolean {
    return Object.values(this._validationStates).every((val) => val === SmacsFormsValidationState.VALID);
  }

  private _updateBottomNavErrorState() {
    if (this._isSubmitted) {
      this.bottomNavService.dispatch(new BottomNavUpdateState({ hasValidationError: !this._formsAreValid() }));
    }
  }

  private _setBottomNavButtonsState(type: 'save' | 'delete' | null) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'save-phone',
        state: {
          pending: type === 'save',
          buttonDisableState: { disabled: type !== null, tooltipKey: '' },
        },
      })
    );
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'cancel-phone',
        state: {
          buttonDisableState: { disabled: type !== null, tooltipKey: '' },
        },
      })
    );
    if (!!this.phone.id) {
      this.bottomNavService.dispatch(
        new BottomNavUpdateButtonState({
          id: 'delete-phone',
          state: {
            pending: type === 'delete',
            buttonDisableState: { disabled: type !== null, tooltipKey: '' },
          },
        })
      );
    }
  }

  private setFormsPending(type: 'save' | 'delete' | null) {
    this._setBottomNavButtonsState(type);

    if (type === 'save') {
      this.extMobilityUiContext.setSubmitting(true);
      this.phoneFieldsFormComponent.first._validateAndSubmitSource.next(true);
      if (this.phoneButtonTemplateFormComponent?.first) {
        this.phoneButtonTemplateFormComponent.first._validateAndSubmitSource.next(true);
      }
    }
    if (!type) {
      this.extMobilityUiContext.setSubmitting(false);
    }
  }

  private _createOrUpdateExtMobility(): Observable<ExtensionMobilityRef> {
    return this.extMobilityUiContext.phoneState$.pipe(
      switchMap((phone) => {
        const phoneCopy = cloneDeep(omit(phone, 'expansionModules'));
        if (phone.id) {
          return this.extMobilityProfilesResource.put(phoneCopy, this.cucmServerId.toString(), this._auditTags);
        } else {
          return this.extMobilityProfilesResource.post(phoneCopy, this.cucmServerId.toString(), this._auditTags);
        }
      })
    );
  }

  private _onModelProtocolChange(newModel: string, oldModel: string, newProtocol: string): Observable<boolean> {
    return new Observable<boolean>((subscriber) => {
      this.phoneButtonTemplateFieldConfigResource
        .post({
          model: newModel,
          protocol: newProtocol,
          siteId: this.siteId,
        })
        .subscribe((proposedPhoneButtonTemplateFieldConfig) => {
          this.phoneButtonTemplateFieldConfig = proposedPhoneButtonTemplateFieldConfig;
          const proposedTemplateAvailableFeatures = this.phoneButtonTemplateFieldConfig.availableButtonFeatures;
          const proposedButtons = this._overlayButtons(
            this.phone.buttons,
            proposedPhoneButtonTemplateFieldConfig.buttons,
            proposedTemplateAvailableFeatures
          );
          const currentButtonSummary = this.phone.buttons.map((button) =>
            this.phoneButtonsService.mapButtonToButtonSummary(button)
          );

          const isButtonConflictPresent = this._isButtonConflictPresent(
            currentButtonSummary,
            proposedPhoneButtonTemplateFieldConfig.buttons,
            proposedTemplateAvailableFeatures
          );
          if (
            isButtonConflictPresent ||
            currentButtonSummary.length > proposedPhoneButtonTemplateFieldConfig.buttons.length
          ) {
            const proposedButtonSummary = proposedButtons.map((button) =>
              this.phoneButtonsService.mapButtonToButtonSummary(button)
            );
            this._openPhoneButtonLayoutConflictModal({
              changeType: 'phone model',
              currentValue: oldModel,
              proposedValue: newModel,
              currentButtonLayout: currentButtonSummary,
              proposedButtonLayout: proposedButtonSummary,
              currentExpansionModules: this._selectedKem,
              isButtonConflictPresent: isButtonConflictPresent,
            }).subscribe((confirmed) => {
              if (confirmed) {
                this.phone.buttons = proposedButtons;
                this.phoneButtonTemplateFieldConfig = proposedPhoneButtonTemplateFieldConfig;
                this.phone.phoneButtonTemplate = this.phoneButtonTemplateFieldConfig.ref;
                this.phoneButtonTemplateEntity = this.phone.phoneButtonTemplate;
              }
              subscriber.next(confirmed);
              subscriber.complete();
            });
          } else {
            this.phone.buttons = proposedButtons;
            this.phoneButtonTemplateFieldConfig = proposedPhoneButtonTemplateFieldConfig;
            this.phone.phoneButtonTemplate = this.phoneButtonTemplateFieldConfig.ref;
            this.phoneButtonTemplateEntity = this.phone.phoneButtonTemplate;
            subscriber.next(true);
            subscriber.complete();
          }
        });
    });
  }

  private _setPhoneState(newProtocol: string, newModel: string, phone: ExtensionMobility) {
    let newPhoneState = this._getPhoneLines(newProtocol, newModel, phone);

    if (this._phoneId) {
      newPhoneState = {
        ...newPhoneState,
        id: this._phoneId,
      };
    }

    if (this._lastSupportedServiceSubscriptions) {
      newPhoneState = {
        ...newPhoneState,
        serviceSubscriptions: this._lastSupportedServiceSubscriptions,
      };
      this._lastSupportedServiceSubscriptions = null;
    }

    this.phoneFieldsEntity = this._mapToPhoneFieldsEntity(newPhoneState);
    this.extMobilityUiContext.set(cloneDeep(newPhoneState));
    if (this.isLoadingPhoneFields) {
      this.isLoadingPhoneFields = false;
    } else {
      this.phoneFieldsFormComponent.first.setSelectOptions(this.fieldConfig);
    }
    this.isManualUpdate = false;
    this.loadingSubscriptions.phoneFieldConfig = false;
    this.isFormUpdated = true;
  }

  private _checkIsLoaded() {
    if (this.isLoading && Object.values(this.loadingSubscriptions).every((loadingSub) => !loadingSub)) {
      this.isLoading = false;

      const bottomNavButtons = [];
      bottomNavButtons.push({
        id: 'cancel-phone',
        dataAutomation: 'phone-cancel-button',
        label: 'Cancel',
        buttonClass: ButtonStyles.DEFAULT,
        cb: () => {
          this.cancel$.next();
        },
      });
      if (this._phoneId) {
        bottomNavButtons.push({
          id: 'delete-phone',
          dataAutomation: 'phone-delete-button',
          label: 'Delete',
          icon: SmacsIcons.DELETE,
          buttonClass: ButtonStyles.DANGER,
          cb: () => {
            this._deletePhone();
          },
        });
      }
      bottomNavButtons.push({
        id: 'save-phone',
        dataAutomation: 'phone-save-button',
        label: 'Save',
        icon: SmacsIcons.OK,
        buttonClass: ButtonStyles.PRIMARY,
        cb: () => {
          this._savePhone();
        },
      });
      this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
    }
  }

  /**
   * Update the phoneFieldsEntity only if phone fields have changed
   * @param newExtMobility
   * @private
   */
  private _isChangeOnlyInButtons(newExtMobility: ExtensionMobility) {
    if (!!this.phone) {
      const phoneFieldsNew = this._mapToPhoneFieldsEntity(newExtMobility);
      const phoneFieldsOld = this._mapToPhoneFieldsEntity(this.phone);
      if (!isEqual(phoneFieldsNew, phoneFieldsOld)) {
        this.phone = newExtMobility;
        this.phoneFieldsEntity = this._mapToPhoneFieldsEntity(newExtMobility);
      } else if (!isEqual(newExtMobility.buttons, this.phone.buttons)) {
        this.phone.buttons = newExtMobility.buttons;
      }
    } else {
      this.phone = newExtMobility;
      this.phoneFieldsEntity = this._mapToPhoneFieldsEntity(newExtMobility);
    }
  }
}
