<div
  class="mb-3 row"
  [hidden]="(!state.hasOwnProperty('hidden') || state.hidden) && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted || misconfigurationFeedbackMessage,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage,
    'smacs-forms-success': validationState === ValStates.VALID && showAsyncValidation
  }"
>
  <div [class]="state.columnClasses?.label">
    <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
    <label [for]="config.dataAutomation" class="col-form-label" [innerHTML]="getLabel() | translate"></label>
  </div>
  <div [class]="state.columnClasses?.input">
    <p-calendar
      [id]="config.dataAutomation"
      [attr.data-automation]="config.dataAutomation"
      [(ngModel)]="fieldData"
      #ngModelDir="ngModel"
      (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
      [selectionMode]="'single'"
      [showIcon]="true"
      [icon]="'icon-calendar'"
      [selectOtherMonths]="true"
      [showTime]="false"
      [inputStyleClass]="'form-control'"
      [styleClass]="'smacs-primeng-datepicker'"
      [hourFormat]="'12'"
      [disabledDays]="disabledDays"
      [disabledDates]="disabledDates"
      [minDate]="minDate"
      [maxDate]="maxDate"
    ></p-calendar>
  </div>
</div>
