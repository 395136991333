<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1 top-buffer" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div
          [innerHTML]="
            !!trafficStatus && !hostedEnabled
              ? ('tkey;system_status.zpc.description' | translate)
              : ('tkey;system_status.zpm.description' | translate)
          "
        ></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div [hidden]="isLoading" class="status-container" *ngFor="let key of statusKeys; index as i">
      <div
        *ngIf="key === StatusCategory.ENDPOINTS && !!trafficStatus && trafficStatus?.state !== stateEnum.OK"
        class="alert alert-warning d-flex align-items-center justify-content-center p-4"
        data-automation="system-status-result-alert"
      >
        <i class="{{ smacsIcons.WARNING }} fa-2x"></i>
        <h5 class="ms-2 mb-0" [innerHTML]="'tkey;system_status.server.unavailable' | translate"></h5>
      </div>

      <table
        class="status-container__table table table-striped table-hover border-rad-table"
        [ngClass]="getTableBorderClass(formattedStatuses.get(key))"
        [attr.data-automation]="'system-status-' + key.toLowerCase().split(' ').join('-') + '-status-table'"
      >
        <tbody>
          <tr class="text-white">
            <th
              [ngClass]="getTableHeaderClass(formattedStatuses.get(key))"
              class="status-container__table__header text-white d-flex justify-content-between px-3"
            >
              <p class="d-flex align-items-center mb-0">{{ key }} Status</p>

              <button
                class="breadcrumb-icon-button-item__icon-button flex-grow-0"
                data-automation="smacs-breadcrumbs-about-card-toggle"
                (click)="handleExpansionClick(i)"
              >
                <i
                  class="breadcrumb-icon-button-item__icon-button__icon"
                  [ngClass]="{ 'breadcrumb-icon-button-item__icon-button__icon--expanded': aboutCard.isExpanded }"
                ></i>
              </button>
            </th>
          </tr>
          <app-about-card #aboutCard class="text-body flex-grow-1">
            <app-breadcrumb-card-item
              *ngIf="
                !!aboutCardTranslationKeys?.get(key)?.zpmTKey && !isNaN(aboutCardTranslationKeys?.get(key)?.zpmTKey)
              "
              [aboutCardId]="aboutCardTranslationKeys?.get(key)?.zpmTKey"
            ></app-breadcrumb-card-item>
            <div
              *ngIf="
                isNaN(Number(!!aboutCardTranslationKeys.get(key).zpcTKey)) ||
                isNaN(aboutCardTranslationKeys?.get(key)?.zpmTKey)
              "
              [innerHTML]="
                !!trafficStatus && !hostedEnabled
                  ? (aboutCardTranslationKeys.get(key).zpcTKey | translate)
                  : (aboutCardTranslationKeys?.get(key)?.zpmTKey | translate)
              "
            ></div>
          </app-about-card>
          <tr
            class="d-flex status-container__table__row"
            [attr.data-automation]="'system-status-result-' + key.toLowerCase().split(' ').join('-') + '-status-row'"
            *ngFor="let status of formattedStatuses.get(key)"
            [ngClass]="{
              'table-warning': status?.state === stateEnum.WARNING,
              'table-danger': status?.state === stateEnum.ERROR
            }"
          >
            <td
              class="col-sm-5 status-container__table__row__description"
              [attr.data-automation]="
                'system-status-result-' + key.toLowerCase().split(' ').join('-') + '-' + 'description'
              "
            >
              {{ status?.description }}
            </td>
            <td
              class="col-sm-7 text-end status-container__table__row__status"
              [attr.data-automation]="key.toLowerCase().split(' ').join('-') + '-' + 'status'"
            >
              <span
                *ngIf="!isRefreshing"
                class="badge me-2"
                [attr.data-automation]="
                  'system-status-result-' + key.toLowerCase().split(' ').join('-') + '-status-badge'
                "
                [style.max-width.px]="status.maxWidth"
                [ngClass]="{
                  'bg-warning': status?.state === stateEnum.WARNING,
                  'bg-danger': status?.state === stateEnum.ERROR,
                  'bg-success': status?.state === stateEnum.OK,
                  'status-container__table__row__error-message': !!status.tooltip
                }"
              >
                <div
                  [hidden]="!status.tooltip"
                  data-automation="system-status-result-endpoints-status-text"
                  [ngbTooltip]="status?.tooltip"
                  [tooltipClass]="'system-status-tooltip'"
                  [disableTooltip]="status?.errorCount === 2"
                  [ngClass]="{ 'text-truncate': status?.errorCount > 1 }"
                >
                  {{ status?.state === stateEnum.OK ? 'OK' : status?.cause }}
                </div>
                <span *ngIf="!status.tooltip">
                  {{ status?.state === stateEnum.OK ? 'OK' : status?.cause }}
                </span>
              </span>
              <i *ngIf="isRefreshing" class="icon-saving"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      *ngIf="!endpointsStatusExists && !!trafficStatus && trafficStatus?.state !== stateEnum.OK"
      class="alert alert-warning d-flex align-items-center justify-content-center p-4"
      data-automation="system-status-result-alert"
    >
      <i class="{{ smacsIcons.WARNING }} fa-2x"></i>
      <h5 class="ms-2 mb-0" [innerHTML]="'tkey;system_status.server.unavailable' | translate"></h5>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
