<div class="row">
  <div class="col-12">
    <smacs-select [fieldId]="'webexCallingLicense'"></smacs-select>
  </div>
</div>

<div [hidden]="!formData || !formData.webexCallingLicense || formData.webexCallingLicense === UCM_LICENSE_NAME">
  <div class="row">
    <div class="col-6">
      <div
        [ngClass]="{ 'has-feedback smacs-forms-error': isSubmitted && !selectedDialPlanGroup }"
        data-automation="webex-did-selector-dial-plan-group-wrapper"
      >
        <smacs-select [fieldId]="'location'"></smacs-select>
        <label for="dialPlanGroupSelect" class="mb-2">
          <span class="text-danger">*</span>
          {{ 'tkey;helpdesk.webex_calling.location_name.label.text' | translate }}
        </label>
        <ng-select
          id="dialPlanGroupSelect"
          [labelForId]="'dialPlanGroupSelect'"
          data-automation="webex-dial-plan-group-select"
          [items]="dialPlanGroups"
          bindLabel="groupName"
          [(ngModel)]="selectedDialPlanGroup"
          (ngModelChange)="onDialPlanGroupChange()"
          [disabled]="isSubmitting"
          [clearable]="false"
        ></ng-select>
      </div>
    </div>
    <div [hidden]="!selectedDialPlanGroup" data-automation="webex-did-filter-toggle-wrapper" class="col-6">
      <div class="card">
        <div class="card-header">
          <div class="form-check" data-automation="webex-did-show-recently-deleted">
            <input
              type="checkbox"
              class="form-check-input"
              id="did-show-recently-deleted"
              data-automation="webex-did-show-recently-deleted-input"
              [(ngModel)]="showRecentlyDeleted"
              (ngModelChange)="applyComponentConfig()"
              [disabled]="isSubmitting"
            />
            <label
              class="form-check-label"
              [attr.data-automation]="'did-show-recently-deleted-label'"
              for="did-show-recently-deleted"
            >
              <i [attr.class]="smacsIcons.HISTORY"></i>
              {{ 'tkey;dndidselection.dndid.show_recently_deleted.label' | translate }}
            </label>
          </div>
          <div class="form-check" data-automation="webex-did-show-unavailable">
            <input
              type="checkbox"
              class="form-check-input"
              id="did-show-unavailable"
              data-automation="webex-did-show-unavailable-input"
              [(ngModel)]="showUnavailable"
              (ngModelChange)="applyComponentConfig()"
              [disabled]="isSubmitting"
            />
            <label
              class="form-check-label"
              [attr.data-automation]="'did-show-unavailable-label'"
              for="did-show-unavailable"
            >
              <i [attr.class]="smacsIcons.UNAVAILABLE + ' text-danger'"></i>
              {{ 'tkey;dndidselection.dndid.show_unavailable.label' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-loading-block [hidden]="!(selectedDialPlanGroup && isSearching)"></app-loading-block>

  <div [hidden]="!selectedDialPlanGroup" class="row">
    <div [hidden]="!selectedDidOptions.length" class="col-12">
      <smacs-extension-selector [fieldId]="'did'" [isSubmitting]="isSubmitting"></smacs-extension-selector>
    </div>
  </div>
</div>
