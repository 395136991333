import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TeamsVoicePolicies } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class MicrosoftVoicePoliciesResource {
  private _baseUrl = '/services/microsoft/macs/teams-voice-policies';
  constructor(private http: HttpClient) {}

  get(upn: string, isResourceAccount: boolean): Observable<TeamsVoicePolicies> {
    return this.http.get<TeamsVoicePolicies>(
      `${this._baseUrl}/${encodeURIComponent(upn)}?isResourceAccount=${isResourceAccount}`
    );
  }

  put(upn: string, body: TeamsVoicePolicies, isResourceAccount: boolean): Observable<void> {
    return this.http.put<void>(
      `${this._baseUrl}/${encodeURIComponent(upn)}?isResourceAccount=${isResourceAccount}`,
      body
    );
  }

  delete(upn: string, isResourceAccount: boolean): Observable<void> {
    const blankBody: TeamsVoicePolicies = {
      userPrincipalName: upn,
      callingLineIdentity: 'Global',
      callingPolicy: 'Global',
      emergencyCallRoutingPolicy: 'Global',
      emergencyCallingPolicy: 'Global',
      ipPhonePolicy: 'Global',
      onlineVoiceRoutingPolicy: 'Global',
      onlineVoicemailPolicy: 'Global',
      teamsCallHoldPolicy: 'Global',
      teamsCallParkPolicy: 'Global',
      tenantDialPlan: 'Global',
      sharedCallRoutingPolicy: {
        policyName: 'Global',
        resourceAccountUpn: '',
        resourceAccountDisplayName: '',
        resourceAccountLineUri: '',
      },
    };
    return this.http.put<void>(
      `${this._baseUrl}/${encodeURIComponent(upn)}?isResourceAccount=${isResourceAccount}`,
      blankBody
    );
  }
}
