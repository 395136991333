import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { OAuth2ResourceServerSettings } from '../../../shared/models/generated/smacsModels';
import { SmacsFormConfig } from '../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { Observable, Subscription } from 'rxjs';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { Oauth2ResourceServerSettingsResource } from '../../resources/oauth2-resource-server-settings.resource';
import { finalize, map, switchMap } from 'rxjs/operators';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';

@Component({
  selector: 'smacs-oauth2-resource-server-management',
  templateUrl: './oauth2-resource-server-management.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class Oauth2ResourceServerManagementComponent
  extends SmacsFormAbstractDirective<OAuth2ResourceServerSettings>
  implements OnInit, OnDestroy
{
  isLoading = true;

  formConfig: SmacsFormConfig = {
    fields: {
      jwtIssuerUrl: {
        dataAutomation: 'oauth2-jwt-issuer-url',
        label: 'tkey;admin.system.oauth2_resource_server_management.jwt_issuer_url.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        required: true,
        options: {
          content: 'tkey;admin.system.oauth2_resource_server_management.jwt_issuer_url.content',
        },
      },
      audience: {
        dataAutomation: 'oauth2-audience',
        label: 'tkey;admin.system.oauth2_resource_server_management.audience.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        required: true,
        options: {
          content: 'tkey;admin.system.oauth2_resource_server_management.audience.content',
        },
      },
    },
  };
  fieldIds: string[] = ['jwtIssuerUrl', 'audience'];

  private _subscriptions = new Subscription();

  constructor(
    private toastService: ToastService,
    private smacsModalService: SmacsModalService,
    private bottomNavService: BottomNavService,
    protected smacsFormStateService: SmacsFormStateService,
    private breadcrumbsService: BreadcrumbsService,
    private oauth2ResourceServerSettingsResource: Oauth2ResourceServerSettingsResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.system.oauth2_resource_server_management.title' }]);

    this.oauth2ResourceServerSettingsResource.get().subscribe((oauth2ResourceServerSettings) => {
      this.entitySource.next(oauth2ResourceServerSettings);
      this.setIsExisting(!!oauth2ResourceServerSettings.jwtIssuerUrl);
      this._initBottomNav();
      this.isLoading = false;
    });

    const formUpdateSub = this.smacsFormsUpdate$.subscribe(() => {
      if (this.isFormSubmitted) {
        this._setBottomNavValidationError(!this.isFormValid());
      }
    });
    this._subscriptions.add(formUpdateSub);

    const formSubmitSub = this._validateAndSubmitSource.subscribe(() => {
      this._setBottomNavValidationError(!this.isFormValid());
    });
    this._subscriptions.add(formSubmitSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
    this.breadcrumbsService.clearBreadcrumbs();
  }

  protected submit() {
    return this._saveSettings();
  }

  private _saveSettings(): Observable<void> {
    this._setBottomNavValidationError(false);
    this._setBottomNavPending('save');

    return this.oauth2ResourceServerSettingsResource.validate(this.entity).pipe(
      switchMap(() => this.oauth2ResourceServerSettingsResource.put(this.entity)),
      map(() => {
        this.setIsExisting(true);
        this.toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.CONFIG,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.system.oauth2_resource_server_management.toast.save.message'
        );
      }),
      finalize(() => {
        this._setBottomNavPending('none');
      })
    );
  }

  private _openDeleteModal() {
    const options = {
      animation: false,
      modalViewProperties: {
        promptBody: 'tkey;admin.system.oauth2_resource_server_management.modal.delete.message',
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteSettings();
            },
          },
        ],
      },
    };
    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteSettings(): Observable<void> {
    this._setBottomNavPending('delete');
    return this.oauth2ResourceServerSettingsResource.delete().pipe(
      map(() => {
        this.setIsExisting(false);
        this._setBottomNavPending('none');
        this.entitySource.next({
          jwtIssuerUrl: '',
          audience: '',
        });
        this._setIsFormSubmitted(false);
        this.smacsFormStateService.setIsFormDirty(false);
        this.toastService.push(
          ToastTypes.INFO,
          `${SmacsIcons.DELETE} text-danger`,
          'tkey;shared.toast.delete.success.title',
          'tkey;admin.system.oauth2_resource_server_management.toast.delete.message'
        );
      })
    );
  }

  private _initBottomNav() {
    const bottomNavButtons: BottomNavButton[] = [
      {
        id: 'deleteOauth2ResourceServerSettings',
        dataAutomation: 'oauth2-resource-server-management-delete-button',
        label: 'tkey;global.button.delete.text',
        buttonClass: ButtonStyles.DANGER,
        state: {
          pending: false,
          buttonDisableState: {
            disabled: !this.isExisting,
            tooltipKey: '',
          },
        },
        icon: SmacsIcons.DELETE,
        cb: () => this._openDeleteModal(),
      },
      {
        id: 'saveOauth2ResourceServerSettings',
        dataAutomation: 'oauth2-resource-server-management-save-button',
        label: 'tkey;global.button.save.text',
        buttonClass: ButtonStyles.PRIMARY,
        state: {
          pending: false,
          buttonDisableState: {
            disabled: false,
            tooltipKey: '',
          },
        },
        icon: SmacsIcons.OK,
        type: ButtonTypes.SUBMIT,
        submitSubject: this._validateAndSubmitSource,
      },
    ];

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _setBottomNavValidationError(hasError: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: hasError,
      })
    );
  }

  private _setBottomNavPending(pendingButton: 'save' | 'delete' | 'none') {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'deleteOauth2ResourceServerSettings',
        state: {
          pending: pendingButton === 'delete',
          buttonDisableState: {
            disabled: !this.isExisting || pendingButton !== 'none',
          },
        },
      })
    );
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'saveOauth2ResourceServerSettings',
        state: {
          pending: pendingButton === 'save',
          buttonDisableState: {
            disabled: pendingButton !== 'none',
          },
        },
      })
    );
  }
}
