import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { PcceAgent, PcceAgentRef, PcceAgentsFieldConfig } from '../../../../shared/models/generated/smacsModels';
import {
  SmacsFormConfig,
  SmacsFormsMessage,
  SmacsFormsUpdate,
  SmacsFormsValidationState,
} from '../../../../forms/smacs-forms-models';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import {
  BottomNavClearButtonsList,
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { EMPTY, Subscription, throwError } from 'rxjs';
import * as _ from 'lodash';
import { cloneDeep, difference, without } from 'lodash';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  SmacsCheckboxConfig,
} from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import {
  SmacsNavsetComponent,
  SmacsNavsetConfig,
  SmacsNavsetItemConfig,
} from '../../../../shared/smacs-navset/smacs-navset.component';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { PcceAgentResource } from '../../../shared/resources/pcce-agent.resource';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../../shared/services/toast.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { UserDetailUiContext } from '../../../shared/contexts/user-detail-ui.context';
import { Global360ViewContext } from '../../../../shared/contexts/global-360-view.context';
import { PcceAgentFieldConfigResource } from '../../../shared/resources/field-config/pcce-agent-field-config.resource';
import { PcceAgentFieldConfigRequest } from '../pcce-agent-settings.component';
import { TranslateService } from '@ngx-translate/core';
import { DeletedAgentErrorModalComponent } from '../../../../modals/deleted-angent-error-modal/deleted-agent-error-modal.component';

export interface PcceAgentForm extends PcceAgent {
  [x: string]: any;
}

@Component({
  selector: 'smacs-pcce-agent-settings-form',
  templateUrl: './pcce-agent-settings-form.component.html',
  styleUrls: ['./pcce-agent-settings-form.component.scss'],
})
export class PcceAgentSettingsFormComponent
  extends SmacsFormAbstractDirective<PcceAgent, PcceAgentForm>
  implements OnInit, OnDestroy
{
  smacsIcons = SmacsIcons;
  @Input() pcceAgentRef: PcceAgentRef;
  @Input() pcceServer: string;
  @Input() isNewAgent: boolean;
  @Input() isSsoAgent: boolean;
  @Input() fieldConfig: PcceAgentsFieldConfig;

  isFormInit = false;
  teamOptions: string[] = [];
  deskSettingsOptions: string[] = [];
  skillGroupOptions: string[] = [];

  allBooleanAttributes: string[] = [];
  validBooleanAttributes: string[] = [];
  allProficiencyAttributes: string[] = [];
  validProficiencyAttributes: string[] = [];
  allValidAttributes: string[] = [];

  formConfig: SmacsFormConfig;

  isLoading = false;
  isSubmitted = false;
  minPasswordLength: number;
  maxPasswordLength: number;

  private _subscriptions = new Subscription();

  constructor(
    private bottomNavService: BottomNavService,
    private pcceAgentResource: PcceAgentResource,
    private route: ActivatedRoute,
    protected smacsFormStateService: SmacsFormStateService,
    private toastService: ToastService,
    private smacsModalService: SmacsModalService,
    private router: Router,
    private userDetailUiContext: UserDetailUiContext,
    private global360ViewContext: Global360ViewContext,
    private pcceAgentFieldConfigResource: PcceAgentFieldConfigResource,
    private translateService: TranslateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initPcceData();
    this._initFormConfig();
    this._initBottomNav();
    this.setIsExisting(!this.isNewAgent);

    // This gets called whenever any field is updated
    this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<PcceAgent>) => {
      const newDepartmentName = data.new.department;

      if (data.valid === SmacsFormsValidationState.VALID && this.isFormSubmitted) {
        this.bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: !this.isFormValid(),
          })
        );
      }

      // if the department changes, we need to update the possible values for the other fields.
      if (
        !_.isEqual(newDepartmentName, data.old.department) &&
        !(newDepartmentName === 'Global' && data.old.department === '')
      ) {
        this.isLoading = true;
        this.isFormInit = true; // Flag to prevent an infinite loop on this callback.
        const requestBody = {
          pcceServerId: Number(this.pcceServer) || this.pcceAgentRef.serverId,
          department: newDepartmentName === '' ? 'Global' : newDepartmentName,
          username: this.entity.userName,
        } as PcceAgentFieldConfigRequest;

        // Get possible options for settings when department changes
        this.pcceAgentFieldConfigResource.getFieldConfigs(requestBody).subscribe((pcceAgentsFieldConfig) => {
          this.teamOptions = pcceAgentsFieldConfig.teams.possibleOptions;
          this.deskSettingsOptions = pcceAgentsFieldConfig.deskSettings.possibleOptions;
          this.skillGroupOptions = pcceAgentsFieldConfig.skillGroups.possibleOptions;

          this.validBooleanAttributes = pcceAgentsFieldConfig.booleanAttributes;
          this.validProficiencyAttributes = pcceAgentsFieldConfig.proficiencyAttributes;
          const agentTeamComponent = this.fieldComponents.find((field) => field.fieldId === 'agentTeam');
          agentTeamComponent.applyComponentConfig(new SmacsSelectConfig({ options: cloneDeep(this.teamOptions) }));

          const deskSettingComponent = this.fieldComponents.find((field) => field.fieldId === 'agentDeskSettings');
          deskSettingComponent.applyComponentConfig(
            new SmacsSelectConfig({ options: cloneDeep(this.deskSettingsOptions) })
          );

          const skillGroupsComponent = this.fieldComponents.find((field) => field.fieldId === 'skillGroups');
          skillGroupsComponent.applyComponentConfig(
            new SmacsSelectConfig({
              options: cloneDeep(this.skillGroupOptions),
              isMultiSelect: true,
            })
          );

          const navsetComponent = this.fieldComponents.find(
            (field) => field.fieldId === 'pcceNavset'
          ) as SmacsNavsetComponent;
          navsetComponent.applyComponentConfig(
            new SmacsNavsetConfig({
              dropdownLabel: 'tkey;pcce.attributes.dropdown_label',
              dropdownTooltip: 'tkey;pcce.attributes.no_items',
              emptyMessage: 'tkey;pcce.attributes.empty',
              navsetItemsConfig: this._getAttributeConfig(),
              allowDuplicates: false,
            })
          );
          this.isLoading = false;
          navsetComponent.changeAllowedDropdownItems([
            ...this.validBooleanAttributes,
            ...this.validProficiencyAttributes,
          ]);
          // Run the field validation:
          Object.keys(this.fieldChannels).forEach((key) => {
            this.fieldChannels[key].validateSource.next();
          });
        });
      }

      // If the password field changes, ensure it matches the confirmPassword field if applicable:
      if (data.new.password !== data.old.password) {
        this.fieldChannels['confirmPassword'].validateSource.next();
      }
      if (data.old.ssoEnabled !== data.new.ssoEnabled) {
        this.bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: false,
          })
        );
      }
    });

    const validateAndSubmitSub = this._validateAndSubmitSource.subscribe(() => {
      this.isSubmitted = true;
    });
    this._subscriptions.add(validateAndSubmitSub);
  }

  // Gets the data and process it into the right format for it to be usable in terms of the field value dependencies.
  private _initPcceData() {
    // Set Boolean and Proficiency attributes
    this.allBooleanAttributes = this.fieldConfig.booleanAttributes;
    this.allProficiencyAttributes = this.fieldConfig.proficiencyAttributes;

    this.validBooleanAttributes = this.allBooleanAttributes;
    this.validProficiencyAttributes = this.allProficiencyAttributes;

    this.teamOptions = cloneDeep(this.fieldConfig.teams.possibleOptions);
    this.deskSettingsOptions = cloneDeep(this.fieldConfig.deskSettings.possibleOptions);
    this.skillGroupOptions = cloneDeep(this.fieldConfig.skillGroups.possibleOptions);
  }

  private _getAttributeConfig = () => {
    const booleanNavsetFields: SmacsNavsetItemConfig = {};
    const proficiencyAttributeFields: SmacsNavsetItemConfig = {};
    this.validBooleanAttributes.forEach((booleanAttribute) => {
      const id = `${booleanAttribute}`;
      booleanNavsetFields[booleanAttribute] = {
        label: booleanAttribute,
        badgeFieldId: id,
        fields: [
          {
            label: 'tkey;pcce.agent_settings.proficiency',
            dataAutomation: id,
            componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH }),
            validation: [
              {
                validator: () => {
                  return this.validBooleanAttributes.includes(booleanAttribute)
                    ? SmacsFormsValidationState.VALID
                    : SmacsFormsValidationState.INVALID;
                },
                message: 'tkey;pcce.agent_settings.option_unavailable',
              },
            ],
          },
        ],
      };
    });

    this.validProficiencyAttributes.forEach((proficiencyAttribute) => {
      const id = `${proficiencyAttribute}`;
      proficiencyAttributeFields[proficiencyAttribute] = {
        label: proficiencyAttribute,
        badgeFieldId: id,
        fields: [
          {
            label: 'tkey;pcce.agent_settings.proficiency',
            dataAutomation: id,
            componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
            required: true,
            validation: [
              {
                validator: () => {
                  return this.validProficiencyAttributes.includes(proficiencyAttribute)
                    ? SmacsFormsValidationState.VALID
                    : SmacsFormsValidationState.INVALID;
                },
                message: 'tkey;pcce.agent_settings.option_unavailable',
              },
              {
                validator: (val: string) => {
                  const number = Number(val);
                  return number < 1 || number > 10
                    ? SmacsFormsValidationState.INVALID
                    : SmacsFormsValidationState.VALID;
                },
                message: 'tkey;pcce.agent_settings.proficiency.range_error',
              },
            ],
          },
        ],
      };
    });
    return {
      ...booleanNavsetFields,
      ...proficiencyAttributeFields,
    };
  };

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        pcceNavset: {
          componentConfig: new SmacsNavsetConfig({
            dropdownLabel: 'tkey;pcce.attributes.dropdown_label',
            dropdownTooltip: 'tkey;pcce.attributes.no_items',
            emptyMessage: 'tkey;pcce.attributes.empty',
            navsetItemsConfig: this._getAttributeConfig(),
            allowDuplicates: false,
          }),
        },
        userName: {
          label: 'tkey;pcce.agent_settings.username',
          dataAutomation: 'pcce-agent-settings-username-input',
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.TEXT,
            readonly: true,
          }),
          hidden: () => !this.fieldConfig.username.show,
          required: () => this.fieldConfig.username.required,
        },
        ssoEnabled: {
          label: 'tkey;pcce.agent_settings.sso',
          dataAutomation: 'pcce-agent-settings-sso-enabled-toggle',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.SM }),
          hidden: () => !this.fieldConfig.enableSso.show,
        },
        password: {
          label:
            this.isNewAgent || this.isSsoAgent
              ? 'tkey;pcce.agent_settings.password.set'
              : 'tkey;pcce.agent_settings.password.change',
          dataAutomation: 'pcce-agent-settings-password-input',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
          // password only required when it's being set for the first time (new agents) or if switching an SSO agent to non-SSO
          required: () => (this.isNewAgent || this.isSsoAgent) && !this.formData.ssoEnabled,
          hidden: () => this.formData.ssoEnabled,
          validation: [
            {
              /* password length requirements come from PCCE, we only know them after sending a request that returns an
              error, in which case we then enable this password length validation. See _setPasswordLengthRequirement().
              */
              validator: (val: string) =>
                (!this.isNewAgent && !val) ||
                (this.maxPasswordLength === undefined && this.minPasswordLength === undefined) ||
                (val.length >= this.minPasswordLength && val.length <= this.maxPasswordLength)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,

              message: (): SmacsFormsMessage => {
                return {
                  content: 'tkey;pcce.agent_settings.password.length_error',
                  params: { minPasswordLength: this.minPasswordLength, maxPasswordLength: this.maxPasswordLength },
                };
              },
            },
          ],
          valExcluded: () => this.formData.ssoEnabled,
        },
        confirmPassword: {
          label: 'tkey;pcce.agent_settings.password.re_enter',
          dataAutomation: 'pcce-agent-settings-confirm-password-input',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
          required: () => (this.isNewAgent || this.isSsoAgent) && !this.formData.ssoEnabled,
          hidden: () => this.formData.ssoEnabled,
          validation: [
            {
              validator: (val: string) =>
                (!val && !this.formData.password) || val === this.formData.password
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;pcce.agent_settings.password.equality_error',
            },
          ],
          valExcluded: () => this.formData.ssoEnabled,
        },
        description: {
          label: 'tkey;pcce.agent_settings.description',
          dataAutomation: 'pcce-agent-settings-description-input',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          validation: [
            {
              validator: (val: string) =>
                val.length <= 255 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
              message: this.translateService.instant('tkey;validators.global.error.maxlength', {
                maxlength: 255,
              }),
            },
          ],
          hidden: () => !this.fieldConfig.description.show,
          required: () => this.fieldConfig.description.required,
        },
        department: {
          label: 'tkey;pcce.agent_settings.department',
          dataAutomation: 'pcce-agent-settings-department-input',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.departments.possibleOptions }),
          required: () => this.fieldConfig.departments.required,
          hidden: () => !this.fieldConfig.departments.show,
          defaultValue: () => this.fieldConfig.departments.defaultValue,
        },
        agentTeam: {
          label: 'tkey;pcce.agent_settings.team',
          dataAutomation: 'pcce-agent-settings-team-input',
          componentConfig: new SmacsSelectConfig({ options: this.teamOptions }),
          required: () => this.fieldConfig.teams.required,
          hidden: () => !this.fieldConfig.teams.show,
          validation: [
            {
              validator: (val: string) =>
                !val || this.teamOptions.includes(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;pcce.agent_settings.option_unavailable',
            },
          ],
          disabled: () => this.isLoading,
        },
        agentDeskSettings: {
          label: 'tkey;pcce.agent_settings.desk_settings',
          dataAutomation: 'pcce-agent-settings-desk-settings-input',
          componentConfig: new SmacsSelectConfig({ options: this.deskSettingsOptions }),
          required: () => this.fieldConfig.deskSettings.required,
          hidden: () => !this.fieldConfig.deskSettings.show,
          validation: [
            {
              validator: (val: string) =>
                !val || this.deskSettingsOptions.includes(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;pcce.agent_settings.option_unavailable',
            },
          ],
          disabled: () => this.isLoading,
        },
        skillGroups: {
          label: 'tkey;pcce.skill_groups.title',
          dataAutomation: 'pcce-agent-settings-skill-groups-input',
          componentConfig: new SmacsSelectConfig({ options: this.skillGroupOptions, isMultiSelect: true }),
          required: () => this.fieldConfig.skillGroups.required,
          hidden: () => !this.fieldConfig.skillGroups.show,
          validation: [
            {
              validator: (val) => {
                return difference(val, this.skillGroupOptions).length === 0
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: 'tkey;pcce.agent_settings.options_unavailable',
            },
          ],
          autogeneration: {
            linkLabel: 'tkey;shared.html.customizable_fields.text_input.fix_it.label',
            generateValue: (val) => without(val, ...difference(val, this.skillGroupOptions)),
            hidden: (val) => difference(val, this.skillGroupOptions).length === 0,
            inline: true,
          },
          disabled: () => this.isLoading,
        },
      },
    } as SmacsFormConfig;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
    this.bottomNavService.dispatch(new BottomNavClearButtonsList());
  }

  private _initBottomNav = () => {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'pcceAgentSettingsCancel',
          dataAutomation: 'pcce-agent-settings-cancel',
          label: 'tkey;global.button.cancel.text',
          buttonClass: ButtonStyles.DEFAULT,
          cb: () => this._goToUserHome(),
        },
        {
          id: 'pcceAgentSettingsSave',
          dataAutomation: 'pcce-agent-settings-save',
          label: 'tkey;global.button.save.text',
          icon: this.smacsIcons.OK,
          buttonClass: ButtonStyles.PRIMARY,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });
    this._subscriptions.add(formSubmittedSub);
  };

  protected submit() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
      })
    );
    delete (this.entity as any)['confirmPassword'];

    if (this.entity['ssoEnabled']) {
      this.entity.password = '';
    }

    this._setPending(true);
    const apiRequest = this.isNewAgent
      ? this.pcceAgentResource.post(this.pcceServer, this.entity)
      : this.pcceAgentResource.put(this.pcceAgentRef.id, this.pcceAgentRef.serverId.toString(), this.entity);
    return apiRequest.pipe(
      tap((response: PcceAgentRef) => {
        this.toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.OK,
          'tkey;shared.toast.save.success.title',
          'tkey;pcce.agent_settings.toast.success'
        );
        this.router.navigateByUrl(`/user/${encodeURIComponent(this.route.snapshot.params.username)}`);

        const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
        if (response !== null) {
          global360.pcceAgents.push(response);
        }
        this.global360ViewContext._stateSource.next(global360);
      }),
      catchError((error) => {
        this.isSubmitting = false;
        this._validateAndSubmitSource.next(false);
        this._setPending(false);
        if (error.status === 422 && error.error.reasonCode === 'INVALID_PCCE_AGENT_PASSWORD') {
          this._setPasswordLengthRequirement(error);
          /* If this PCCE agent was previously "deleted", it can't be re-created straight away.
           So show error modal with instructions on how to fully delete it:
          */
          return EMPTY;
        } else if (error.status === 422 && error.error.reasonCode === 'PCCE_OBJECT_NOT_FULLY_DELETED') {
          this.smacsModalService.openCustomModal(
            DeletedAgentErrorModalComponent,
            {
              username: this.entity.userName,
            },
            'lg'
          );
          return EMPTY;
        } else {
          return throwError(() => error);
        }
      })
    );
  }

  private _setPasswordLengthRequirement(error: any) {
    // We only know the password length requirements after we get an error related to that. Then we can do field validation.
    const lengthRange = error.error.description.match(/.*?(\d+) to (\d+)/);
    this.minPasswordLength = parseInt(lengthRange[1]);
    this.maxPasswordLength = parseInt(lengthRange[2]);
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: true,
      })
    );
    this.fieldChannels['password'].validateSource.next();
  }

  private _setPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'pcceAgentSettingsSave',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  toFormData = (entity: PcceAgent): PcceAgentForm => {
    return {
      ...entity,
      department: entity.department === '' ? 'Global' : entity.department,
      confirmPassword: (entity as any)['confirmPassword'] ? (entity as any)['confirmPassword'] : '',
      pcceNavset: {
        ...entity.booleanAgentAttributes,
        ...entity.proficiencyAgentAttributes,
      },
    };
  };

  toEntity = (formData: PcceAgentForm): PcceAgent => {
    const formDataClone = cloneDeep(formData);
    if (formDataClone.pcceNavset) {
      Object.keys(formData.pcceNavset).forEach((key: string) => {
        if (this.allBooleanAttributes.includes(key)) {
          formDataClone.booleanAgentAttributes = {
            ...formDataClone.booleanAgentAttributes,
            ...formDataClone.pcceNavset[key][0],
          };
        } else if (this.allProficiencyAttributes.includes(key)) {
          formDataClone.proficiencyAgentAttributes = {
            ...formDataClone.proficiencyAgentAttributes,
            ...formDataClone.pcceNavset[key][0],
          };
        }
      });

      delete formDataClone['pcceNavset'];
    }

    return {
      ...formDataClone,
      department: formDataClone.department,
    };
  };

  private _goToUserHome() {
    this.router.navigate(['../'], {
      relativeTo: this.route,
    });
  }
}
