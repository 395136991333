<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 pb-2 smacs-report-content">
    <app-breadcrumbs>
      <app-breadcrumb-card-item [aboutCardId]="64"></app-breadcrumb-card-item>
    </app-breadcrumbs>

    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <div *ngIf="!isLoading" data-automation="policy-misconfiguration-report-table" class="flex-grow-1">
      <app-entity-table
        [table]="table"
        [tableRows]="tableRows"
        [tableResponsive]="true"
        [scrollableTable]="true"
        [selectAppendTo]="'body'"
        (fieldChange)="onFieldChange($event)"
      ></app-entity-table>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
