import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SmacsModalService } from '../../shared/services/smacs-modal.service';
import { Observable, Subscriber } from 'rxjs';
import { DirectoryNumber, DirectoryNumberRef } from '../../shared/models/generated/smacsModels';
import { AuditHeaderService } from '../../shared/services/audit-header.service';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../button/button.component';
import { Nvp } from '../../shared/models/nvp';

@Injectable()
export class DirectoryNumberResource {
  constructor(
    private http: HttpClient,
    private smacsModalService: SmacsModalService,
    private auditHeaderService: AuditHeaderService
  ) {}

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }

  post(
    directoryNumber: DirectoryNumber,
    serverId: string,
    auditTags?: Nvp[]
  ): Observable<DirectoryNumberRef | HttpErrorResponse> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return new Observable<DirectoryNumberRef | HttpErrorResponse>((subscriber: Subscriber<DirectoryNumberRef>) => {
      this.http
        .post(
          `/services/cisco/macs/cucm-servers/${encodeURIComponent(serverId)}/directory-numbers`,
          directoryNumber,
          httpOptions
        )
        .subscribe({
          next: (ref: DirectoryNumberRef) => {
            subscriber.next(ref);
            subscriber.complete();
          },
          error: (error) => {
            if (error.status === 422 && error.error.reasonCode === 'FORBIDDEN_DN_MODIFICATION') {
              const options = {
                modalViewProperties: {
                  title: 'Forbidden',
                  promptBody: error.error.description,
                  icon: SmacsIcons.FORBIDDEN,
                  iconClass: 'text-danger',
                  displayCloseButton: true,
                  buttons: [
                    {
                      label: 'OK',
                      buttonClass: ButtonStyles.INFO,
                      dataAutomation: 'forbidden-dismiss-button',
                    },
                  ],
                },
              };
              this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
              subscriber.next(error);
            } else {
              subscriber.error(error);
            }
            subscriber.complete();
          },
        });
    });
  }

  get(id: string, serverId: string): Observable<DirectoryNumber> {
    return this.http.get<DirectoryNumber>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(serverId)}/directory-numbers/${encodeURIComponent(id)}`
    );
  }

  put(id: string, directoryNumber: DirectoryNumber, serverId: string, auditTags?: any): Observable<DirectoryNumberRef> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return new Observable<DirectoryNumberRef>((subscriber: Subscriber<DirectoryNumberRef>) => {
      this.http
        .put(
          `/services/cisco/macs/cucm-servers/${encodeURIComponent(serverId)}/directory-numbers/${encodeURIComponent(
            id
          )}`,
          directoryNumber,
          httpOptions
        )
        .subscribe({
          next: (ref: DirectoryNumberRef) => {
            subscriber.next(ref);
            subscriber.complete();
          },
          error: (error) => {
            if (error.status === 422 && error.error.reasonCode === 'FORBIDDEN_DN_MODIFICATION') {
              const options = {
                modalViewProperties: {
                  title: 'tkey;forbidden.user.title',
                  promptBody: error.error.description,
                  icon: SmacsIcons.FORBIDDEN,
                  iconClass: 'text-danger',
                  displayCloseButton: true,
                  buttons: [
                    {
                      label: 'tkey;dialogs.button.ok',
                      buttonClass: ButtonStyles.INFO,
                      dataAutomation: 'forbidden-dismiss-button',
                    },
                  ],
                },
              };
              this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
            }

            subscriber.error(error);
            subscriber.complete();
          },
        });
    });
  }

  delete(id: string, serverId: number | string, auditTags?: Nvp[]): Observable<void> {
    const options = this.auditHeaderService.getHttpOptions(auditTags);
    return new Observable<void>((subscriber: Subscriber<void>) => {
      this.http
        .delete<void>(
          `/services/cisco/macs/cucm-servers/${encodeURIComponent(
            String(serverId)
          )}/directory-numbers/${encodeURIComponent(id)}`,
          options
        )
        .subscribe({
          next: () => {
            subscriber.next();
            subscriber.complete();
          },
          error: (error) => {
            if (error.status === 422 && error.error.reasonCode === 'FORBIDDEN_DN_MODIFICATION') {
              const modalOptions = {
                modalViewProperties: {
                  title: 'Forbidden',
                  promptBody: error.error.description,
                  icon: SmacsIcons.FORBIDDEN,
                  iconClass: 'text-danger',
                  displayCloseButton: true,
                  buttons: [
                    {
                      label: 'OK',
                      buttonClass: ButtonStyles.INFO,
                      dataAutomation: 'forbidden-dismiss-button',
                    },
                  ],
                },
              };
              this.smacsModalService.openPromptModal(() => modalOptions.modalViewProperties, modalOptions);
            } else {
              // Display error toasts
              subscriber.error(error);
            }
            subscriber.complete();
          },
        });
    });
  }
}
