import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { DefaultPhoneButtonTemplateResource } from '../../../admin/resources/default-phone-button-template.resource';
import { PhoneButtonTemplateInventory, Privilege, SiteSummary } from '../../../shared/models/generated/smacsModels';
import { combineLatest, from, Observable, Subscription } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { PhoneButtonTemplateService } from '../../../shared/services/phone-button-template.service';
import { DatatableColumn, DatatableComponent, DatatableRow } from '../../datatable/datatable.component';
import { uniq } from 'lodash';
import { AuthenticationContext } from '../../../shared/contexts/authentication.context';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { DatatableFilterService } from '../../datatable/datatable-filter.service';

interface UnusedPbtRow extends DatatableRow {
  id: string;
  cucmId: number;
  templateName: string;
  model: string;
  protocol: string;
  type: string;
  cucmServer: string;
  deviceCount: number;
  standardTemplate: boolean;
  cucmDeviceDefault: boolean;
}

enum PhoneButtonTemplateTypes {
  ZIRO = 'ZIRO',
  STANDARD = 'Standard',
  DEVICE_DEFAULT = 'Device Default',
  INDIVIDUAL = 'Individual',
  CUSTOM = 'Custom',
}

@Component({
  selector: 'app-unused-phone-button-templates',
  templateUrl: './unused-phone-button-templates.component.html',
  styleUrls: ['../../reporting.scss'],
})
export class UnusedPhoneButtonTemplatesComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent) datatableChildComponent: DatatableComponent<UnusedPbtRow>;

  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  bottomNavButton: BottomNavButton;
  isLoading = true;
  isReadOnly = true;
  serversMap = {};
  unusedPbts = [] as UnusedPbtRow[];
  protocolFilterOptions = ['SIP', 'SCCP'] as string[];
  cucmServerFilterOptions = [] as string[];
  typeFilterOptions = Object.values(PhoneButtonTemplateTypes) as string[];
  tableDataAutomation = 'unused-phone-button-templates-datatable';
  columns = [
    {
      name: 'templateName',
      label: 'tkey;menu.report.unused_phone_button_template.template_name.label',
    },
    {
      name: 'model',
      label: 'tkey;menu.report.unused_phone_button_template.model_name.label',
    },
    {
      name: 'protocol',
      label: 'tkey;menu.report.unused_phone_button_template.protocol.label',
    },
    {
      name: 'type',
      label: 'tkey;menu.report.unused_phone_button_template.type.label',
    },
    {
      name: 'cucmServer',
      label: 'tkey;menu.report.unused_phone_button_template.cucm_server.label',
      columnCharacterWidth: 12,
    },
    {
      name: 'deviceCount',
      label: 'tkey;menu.report.unused_phone_button_template.count.label',
      cssClass: 'text-end ms-auto',
    },
  ] as DatatableColumn<UnusedPbtRow>[];

  private _subs = new Subscription();

  constructor(
    private bottomNavService: BottomNavService,
    private defaultPhoneButtonTemplateResource: DefaultPhoneButtonTemplateResource,
    private siteSummaryContext: SiteSummaryContext,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private phoneButtonTemplateService: PhoneButtonTemplateService,
    private toastService: ToastService,
    private breadcrumbsService: BreadcrumbsService,
    private datatableFilterService: DatatableFilterService,
    private authenticationContext: AuthenticationContext
  ) {}

  ngOnInit(): void {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;menu.report.unused_phone_button_template.title' }]);

    const reportObservable = this.siteSummaryContext.state$.pipe(
      map((siteSummaries) => this._reduceSiteSummaryToServerMap(siteSummaries)),
      switchMap((cucmServerMap) => this._getTemplates(cucmServerMap))
    );

    const authenticationObservable = this.authenticationContext.state$.pipe(
      map((user) => {
        this.isReadOnly = !user.privileges.includes(Privilege.CONTROL_WRITE);
      })
    );

    this._subs.add(
      combineLatest([reportObservable, authenticationObservable]).subscribe(() => {
        this._initBottomNav();
        this.isLoading = false;
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.unsubscribe();
    this.breadcrumbsService.clearBreadcrumbs();
  }

  setDeleteButtonState() {
    const rowsSelected = this.unusedPbts.filter((row: UnusedPbtRow) => row.isSelectedInTable);

    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          ...this.bottomNavButton,
          state: {
            buttonDisableState: {
              disabled: !rowsSelected.length,
              tooltipKey: !rowsSelected.length ? 'tkey;menu.report.unused_phone_button_template.delete.tooltip' : '',
            },
            tooltipVisible: !rowsSelected.length,
          },
          label:
            rowsSelected.length >= 1
              ? rowsSelected.length === 1
                ? 'tkey;menu.report.unused_phone_button_template.delete.count.one.label'
                : 'tkey;menu.report.unused_phone_button_template.delete.count.label'
              : 'tkey;menu.report.unused_phone_button_template.delete.label',
          labelParam: rowsSelected.length,
        },
      ])
    );
  }

  showAllFilter = (filter: boolean, row: UnusedPbtRow): boolean => {
    return !(!filter && (row.standardTemplate || row.cucmDeviceDefault || row.deviceCount > 0));
  };

  isProtocolMatch = (filter: string[], row: UnusedPbtRow): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.protocol);

  isCucmServerMatch = (filter: string[], row: UnusedPbtRow): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.cucmServer);

  isTypeMatch = (filter: string[], row: UnusedPbtRow): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.type);

  private _getDisabledToolTip(row: PhoneButtonTemplateInventory): string {
    if (row.standardTemplate) {
      return 'tkey;menu.report.unused_phone_button_template.standard_template.tooltip';
    } else if (row.cucmDeviceDefault) {
      return 'tkey;menu.report.unused_phone_button_template.cucm_default_template.tooltip';
    } else if (row.phoneRefs > 0) {
      return 'tkey;menu.report.unused_phone_button_template.non_standard_template.tooltip';
    }
    return null;
  }

  private _initBottomNav() {
    const bottomNavButtons: BottomNavButton[] = [];

    if (!this.isReadOnly) {
      this.bottomNavButton = {
        id: 'unused-pbt-delete-btn',
        dataAutomation: 'unused-pbt-delete-btn',
        label: 'tkey;menu.report.unused_phone_button_template.delete.label',
        buttonClass: this.buttonStyles.DANGER,
        labelParam: 0,
        icon: this.smacsIcons.DELETE,
        state: {
          pending: false,
          buttonDisableState: {
            disabled: true,
            tooltipKey: 'tkey;menu.report.unused_phone_button_template.delete.tooltip',
          },
          tooltipVisible: true,
        },
        cb: () => {
          this._onDeleteClicked();
        },
      };
      bottomNavButtons.push(this.bottomNavButton);
    }

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _onDeleteClicked(): void {
    const rowsSelected = this.unusedPbts.filter((row: UnusedPbtRow) => row.isSelectedInTable);
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        title: this.translateService.instant('tkey;menu.report.unused_phone_button_template.modal.title'),
        promptBody: this.translateService.instant('tkey;menu.report.unused_phone_button_template.modal.message', {
          count: rowsSelected.length,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onConfirmDelete(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _onConfirmDelete(): Observable<void> {
    return new Observable((subscriber) => {
      const selectedDevices = this.unusedPbts.filter((row: UnusedPbtRow) => row.isSelectedInTable);

      const devicesToDelete = selectedDevices
        .filter((row: UnusedPbtRow) => row.isSelectedInTable)
        .map((row: UnusedPbtRow) => {
          return this.phoneButtonTemplateService.deleteTemplate(row.id, row.cucmId);
        }) as Observable<void>[];

      from(devicesToDelete)
        .pipe(mergeMap((request: Observable<void>) => request, 3))
        .subscribe({
          complete: () => {
            const deletedIds = selectedDevices.map((row: UnusedPbtRow) => row.id);
            this.unusedPbts = this.unusedPbts.filter((row: UnusedPbtRow) => !deletedIds.includes(row.id));

            this.toastService.push(
              ToastTypes.INFO,
              `${this.smacsIcons.DELETE} text-danger`,
              'tkey;shared.toast.delete.success.title',
              'tkey;menu.report.unused_phone_button_template.toast.message'
            );

            this.bottomNavService.dispatch(
              new BottomNavUpdateButtonsList([
                {
                  ...this.bottomNavButton,
                  state: {
                    buttonDisableState: { disabled: true, tooltipKey: '' },
                    tooltipVisible: true,
                  },
                  label: 'tkey;menu.report.unused_phone_button_template.delete.label',
                },
              ])
            );

            setTimeout(() => {
              this.datatableChildComponent?.filterTableData();
            });

            subscriber.next();
            subscriber.complete();
          },
        });
    });
  }

  private _reduceSiteSummaryToServerMap(siteSummaries: SiteSummary): Map<number, string> {
    return siteSummaries.clusters.reduce<Map<number, string>>((serverMap, clusterResult) => {
      if (!serverMap.has(clusterResult.cucmServerId)) {
        serverMap.set(clusterResult.cucmServerId, clusterResult.cucmServerDescription);
      }
      return serverMap;
    }, new Map<number, string>());
  }

  private _getTemplates(cucmServerMap: Map<number, string>): Observable<void> {
    return this.defaultPhoneButtonTemplateResource.getPhoneButtonTemplateInventory().pipe(
      map((results: PhoneButtonTemplateInventory[]) => {
        this.unusedPbts = results.map((result: PhoneButtonTemplateInventory) => {
          return {
            id: result.id,
            cucmId: result.cucmId,
            templateName: result.name,
            model: result.model,
            protocol: result.protocol,
            type: this._getType(result),
            cucmServer: cucmServerMap.get(result.cucmId),
            deviceCount: result.phoneRefs,
            standardTemplate: result.standardTemplate,
            cucmDeviceDefault: result.cucmDeviceDefault,
            isTableRowSelectable: true,
            isTableRowSelectDisabled: result.standardTemplate || result.cucmDeviceDefault || result.phoneRefs > 0,
            tableRowSelectDisabledTooltip: this._getDisabledToolTip(result),
          };
        });

        this.cucmServerFilterOptions = uniq(
          this.unusedPbts.map((unusedPbtRow: UnusedPbtRow) => unusedPbtRow.cucmServer)
        );
      })
    );
  }

  private _getType(result: PhoneButtonTemplateInventory): PhoneButtonTemplateTypes {
    if (result.name.startsWith('SMACS') || result.name.startsWith('ZIRO')) {
      return PhoneButtonTemplateTypes.ZIRO;
    } else if (result.standardTemplate) {
      return PhoneButtonTemplateTypes.STANDARD;
    } else if (result.cucmDeviceDefault) {
      return PhoneButtonTemplateTypes.DEVICE_DEFAULT;
    } else if (result.name.includes('Individual')) {
      return PhoneButtonTemplateTypes.INDIVIDUAL;
    } else {
      return PhoneButtonTemplateTypes.CUSTOM;
    }
  }
}
