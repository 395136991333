import { Injectable } from '@angular/core';
import { ServiceType } from '../models/service-type';

@Injectable()
export class ServiceTypeIconFilter {
  icons: any = {};

  constructor() {
    this.icons[ServiceType.PRIMARY_EXTENSION] = 'icon-primary-extension';
    this.icons[ServiceType.UCCX_AGENT] = 'icon-agent';
    this.icons[ServiceType.VOICEMAIL] = 'icon-voicemail';
    this.icons[ServiceType.EXTENSION_MOBILITY] = 'icon-extension-mobility';
    this.icons[ServiceType.SNR] = 'icon-snr';
    this.icons[ServiceType.IM_PRESENCE] = 'icon-imp';
    this.icons[ServiceType.DESKPHONE] = 'icon-deskphone';
    this.icons[ServiceType.CTI_PORT] = 'icon-phone-other';
    this.icons[ServiceType.CTI_REMOTE_DEVICE] = 'icon-phone-other';
    this.icons[ServiceType.SPARK_REMOTE_DEVICE] = 'icon-phone-other';
    this.icons[ServiceType.ANDROID] = 'icon-android';
    this.icons[ServiceType.IPHONE] = 'icon-iphone';
    this.icons[ServiceType.SOFTPHONE] = 'icon-softphone';
    this.icons[ServiceType.TABLET] = 'icon-tablet';
    this.icons[ServiceType.CIPC] = 'icon-cipc';
    this.icons[ServiceType.MICROSOFT_365] = 'smacs-icons icon-microsoft-365';
    this.icons[ServiceType.MICROSOFT_TEAMS_CALLING] = 'smacs-icons icon-teams-calling';
  }

  transform(serviceType: string): string {
    const icon = this.icons[serviceType];
    if (!icon) {
      throw Error('No icon found for ' + serviceType);
    }
    return icon;
  }
}
