import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HealthStatus, Server, State } from '../../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsSelectConfig } from '../../../forms/fields/select/smacs-select.component';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { forkJoin, Observable, of, Subscriber, Subscription } from 'rxjs';
import { ServersContext } from '../../contexts/servers.context';
import { isEqual } from 'lodash';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../forms/fields/checkbox/smacs-checkbox.component';
import { switchMap, tap } from 'rxjs/operators';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { ModalBodyClass } from '../../../modals/modal-body';
import { DetailedModalComponent } from '../../../modals/detailed-modal/detailed-modal.component';
import { UcMetadataCacheContext } from '../../../shared/contexts/uc-metadata-cache.context';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { SearchFieldConfigContext } from '../../../shared/contexts/search-field-config.context';

@Component({
  selector: 'app-add-edit-server-modal-component',
  templateUrl: './add-edit-server-modal.component.html',
})
export class AdminAddEditServerModalComponent extends ModalBodyClass implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;
  buttonSizes = ButtonSizes;
  buttonStyles = ButtonStyles;

  hasConnectionError = false;
  hasCredentialsError = false;
  cachedFormData: Server;
  isSubmitted = false;
  connectionError: HealthStatus;

  validators = {
    isDescriptionUnique: (val: string): SmacsFormsValidationState =>
      this._isUnique('description', val) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
    isHostAddressUnique: (val: string): SmacsFormsValidationState =>
      this._isUnique('hostAddress', val) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
    isConnectionWorking: (): SmacsFormsValidationState =>
      this.hasConnectionError ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID,
    areCredentialsCorrect: (): SmacsFormsValidationState =>
      this.hasCredentialsError ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID,
  };
  formConfig = {
    fields: {
      description: {
        label: 'tkey;admin.servers.description.label',
        placeholder: '',
        dataAutomation: 'server-add-edit-description',
        required: true,
        validation: [
          {
            validator: this.validators.isDescriptionUnique,
            message: 'tkey;admin.servers.error.duplicate.description.text',
          },
        ],
      },
      hostAddress: {
        label: 'tkey;admin.servers.host.address.label',
        placeholder: '',
        dataAutomation: 'server-add-edit-host-address',
        required: true,
        validation: [
          {
            validator: this.validators.isHostAddressUnique,
            message: 'tkey;admin.servers.modal.new.duplicate_host_address',
          },
          {
            validator: this.validators.isConnectionWorking,
            message: '',
          },
        ],
      },
      port: {
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        label: 'tkey;admin.servers.port.label',
        placeholder: '',
        dataAutomation: 'server-add-edit-port',
        required: true,
        validation: [
          {
            validator: this.validators.isConnectionWorking,
            message: '',
          },
        ],
      },
      serverType: {
        label: 'tkey;admin.servers.server.type.label',
        placeholder: '',
        dataAutomation: 'server-add-edit-server-type',
        componentConfig: new SmacsSelectConfig({ options: ['CUCM', 'IM & Presence', 'Unity', 'UCCX', 'PCCE'] }),
        required: true,
        validation: [
          {
            validator: this.validators.isConnectionWorking,
            message: '',
          },
        ],
      },
      username: {
        label: 'tkey;admin.servers.username.label',
        placeholder: '',
        dataAutomation: 'server-add-edit-username',
        required: true,
        validation: [
          {
            validator: this.validators.areCredentialsCorrect,
            message: '',
          },
        ],
      },
      password: {
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
        label: 'tkey;admin.servers.password.label',
        placeholder: '',
        dataAutomation: 'server-add-edit-password',
        required: () =>
          this.modalComponent.modalViewProperties.server == null ||
          this.modalComponent.modalViewProperties.server.id == null,
        validation: [
          {
            validator: this.validators.areCredentialsCorrect,
            message: '',
          },
        ],
      },
      disabled: {
        dataAutomation: 'server-add-edit-disable',
        label: 'tkey;admin.servers.disable.label',
        helpText: 'tkey;admin.servers.disable.helptext',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH }),
      },
    },
  } as SmacsFormConfig;

  private _subs = new Subscription();

  constructor(
    private activeModal: NgbActiveModal,
    private serversContext: ServersContext,
    protected smacsFormStateService: SmacsFormStateService,
    private modalComponent: DetailedModalComponent<any>,
    private ucMetaDataCacheContext: UcMetadataCacheContext,
    private _siteSummaryContext: SiteSummaryContext,
    private _searchFieldConfigContext: SearchFieldConfigContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.setIsExisting(!!this.modalComponent.modalViewProperties.server?.username);
    this._setDefaultValue();

    const validateAndSubmitSourceSub = this._validateAndSubmitSource.subscribe(() => {
      this.isSubmitted = true;
    });
    this._subs.add(validateAndSubmitSourceSub);

    this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<Server>) => {
      this._updateErrorStateIfValueChange(data);
      this._updatePortBasedOnServerType(data);
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subs.unsubscribe();
  }

  submit(): Observable<HealthStatus | Server> {
    this.isSubmitted = true;
    this.hasConnectionError = false;
    this.hasCredentialsError = false;
    this.connectionError = null;
    this.cachedFormData = { ...this.formData };

    if (!this.entity.disabled) {
      return new Observable((subscriber: Subscriber<HealthStatus | Server>) => {
        this._testConnection().subscribe((data: HealthStatus) => {
          this.connectionError = data;
          if (this.connectionError.state === State.ERROR) {
            if (this.connectionError.cause === 'Connection Issue') {
              this.hasConnectionError = true;
              this.fieldChannels['hostAddress'].validateSource.next();
              this.fieldChannels['port'].validateSource.next();
              this.fieldChannels['serverType'].validateSource.next();
            } else if (this.connectionError.cause === 'Incorrect Credentials') {
              this.hasCredentialsError = true;
              this.fieldChannels['username'].validateSource.next();
              this.fieldChannels['password'].validateSource.next();
            }

            this._validateAndSubmitSource.next(false);
            subscriber.error();
            subscriber.complete();
          } else {
            this._saveServer().subscribe(() => {
              subscriber.next(data);
              subscriber.complete();
            });
          }
        });
      });
    } else {
      return this._saveServer();
    }
  }

  private _reloadContexts(): Observable<void[]> {
    return forkJoin([
      this.ucMetaDataCacheContext.reloadUcMetadataCache(),
      this._siteSummaryContext.refreshSiteSummary(),
      this._searchFieldConfigContext.refresh(),
    ]);
  }

  private _saveServer(): Observable<Server> {
    if (this.formData.id) {
      this.serversContext.put(this.formData).pipe(
        switchMap((data) => this._reloadContexts().pipe(switchMap(() => of(data)))),
        tap((data: Server) => {
          this.activeModal.close(data);
        })
      );

      return this.serversContext.put(this.formData).pipe(
        switchMap((data) => this._reloadContexts().pipe(switchMap(() => of(data)))),
        tap((data: Server) => {
          this.activeModal.close(data);
        })
      );
    } else {
      return this.serversContext.post(this.formData).pipe(
        switchMap((data) => this._reloadContexts().pipe(switchMap(() => of(data)))),
        tap((data: Server) => {
          this.activeModal.close(data);
        })
      );
    }
  }

  private _testConnection = (): Observable<HealthStatus> => {
    return this.serversContext.testConnection(this.formData);
  };

  private _setDefaultValue() {
    this.entitySource.next({
      ...this.modalComponent.modalViewProperties.server,
    });
  }

  private _isUnique(comparisonKey: keyof Server, currentValue: string): boolean {
    return (
      !this.entity ||
      !this.modalComponent.modalViewProperties.servers.find(
        (server: Server) =>
          String(server[comparisonKey]).toLowerCase() === currentValue.trim().toLowerCase() &&
          server.id !== this.entity.id
      )
    );
  }

  /**
   * Change the port based on the server type selected
   */
  private _updatePortBasedOnServerType(data: SmacsFormsUpdate<Server>) {
    if (data.new && data.old && !isEqual(data.new.serverType, data.old?.serverType)) {
      if (data.new.serverType === 'PCCE') {
        this.entitySource.next({
          ...this.formData,
          port: 443,
        });
      } else {
        this.entitySource.next({
          ...this.formData,
          port: 8443,
        });
      }
    }
  }

  /**
   * Clear the manually placed errors if changes have been made to the form
   */
  private _updateErrorStateIfValueChange(data: SmacsFormsUpdate<Server>) {
    if (!isEqual(data.new, data.old) && this.connectionError) {
      this.hasConnectionError = false;
      this.hasCredentialsError = false;
      Object.values(this.fieldChannels).forEach((field) => field.validateSource.next());
    }
  }
}
