<div [attr.data-automation]="dataAutomation">
  <div
    data-automation="filter-checkbox-input"
    class="form-check"
    [ngClass]="{ 'd-flex justify-content-center': isCentered }"
  >
    <input
      type="checkbox"
      class="form-check-input"
      [attr.id]="dataAutomation + '_input'"
      [(ngModel)]="value"
      (ngModelChange)="filterOnChange()"
      [ngModelOptions]="{ standalone: true }"
      [indeterminate]="isIndeterminate"
      [checked]="isIndeterminate ? false : isSelectAll ? selectAllValue : value"
    />
    <label class="form-check-label" [attr.for]="dataAutomation + '_input'"></label>
  </div>
</div>
