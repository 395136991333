import { ActivatedRoute, Router } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class RouteHelpersService {
  constructor(private router: Router) {}

  goToParent(route: ActivatedRoute, paramKey: string | number) {
    const path = route.snapshot.params[paramKey || 'id'] === undefined ? ['..'] : ['../..'];
    this.router.navigate(path, { relativeTo: route });
  }
}
