<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item [aboutCardId]="42"></app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div *ngIf="!isLoading" data-automation="microsoft-dial-plan-exception-management-entity-table">
      <app-entity-table [table]="table" [tableRows]="tableRows"></app-entity-table>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
