import { Component, Input } from '@angular/core';
import { SmacsButtonComponent } from '../../button/button.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'smacs-submit-button',
  templateUrl: '../../button/button.component.html',
  styleUrls: ['../../button/button.component.scss'],
})
export class SmacsSubmitButtonComponent extends SmacsButtonComponent {
  @Input() formSubmitSubject = new Subject<boolean>();

  onClick() {
    this.formSubmitSubject.next(true);
  }
}
