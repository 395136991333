import { Injectable } from '@angular/core';
import { GlobalVoicemailResult, VoicemailResult } from '../models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class VoicemailSearchResource {
  limit = 100;

  constructor(private http: HttpClient) {}

  searchByQ(query: string, unityServerId?: number): Observable<VoicemailResult[]> {
    let params = `q=${encodeURIComponent(query)}`;
    if (unityServerId) {
      params += `&unity-server-id=${unityServerId}`;
    }
    return this.http.get<VoicemailResult[]>(`/services/cisco/macs/voicemails/search?${params}&limit=${this.limit}`);
  }

  searchByExtension(extension: string, unityServerId?: number): Observable<VoicemailResult[]> {
    let params = `extension=${encodeURIComponent(extension)}`;
    if (unityServerId) {
      params += `&unity-server-id=${unityServerId}`;
    }
    return this.http.get<VoicemailResult[]>(`/services/cisco/macs/voicemails/search?${params}&limit=${this.limit}`);
  }

  searchByAlias(alias: string, unityServerId: number): Observable<VoicemailResult[]> {
    let params = `alias=${encodeURIComponent(alias)}`;
    if (unityServerId) {
      params += `&unity-server-id=${unityServerId}`;
    }
    return this.http.get<VoicemailResult[]>(`/services/cisco/macs/voicemails/search?${params}&limit=${this.limit}`);
  }

  searchGloballyByQ(query: string, unityServerId: number): Observable<GlobalVoicemailResult[]> {
    const params = `q=${encodeURIComponent(query)}`;
    return this.http.get<GlobalVoicemailResult[]>(
      `/services/cisco/macs/global-voicemails/search?${params}&server-id=${unityServerId}`
    );
  }

  searchGloballyByAlias(alias: string, unityServerId: number): Observable<GlobalVoicemailResult[]> {
    const params = `alias=${encodeURIComponent(alias)}`;
    return this.http.get<GlobalVoicemailResult[]>(
      `/services/cisco/macs/global-voicemails/search?${params}&server-id=${unityServerId}`
    );
  }
}
