<smacs-select [fieldId]="'site'"></smacs-select>

<div *ngIf="isValidCluster">
  <app-loading-block [hidden]="!isGettingDevicePools"></app-loading-block>

  <div *ngIf="!isGettingDevicePools" [attr.data-automation]="'warning-container'">
    <hr />

    <div *ngIf="servicesToUpdate.length" data-automation="change-site-service-to-update-container">
      <p
        *ngIf="!ldapAttribute || !ldapAttribute.site"
        [innerHTML]="'tkey;change.site.confirm_site_change.text_1' | translate"
        data-automation="change-site-service-to-update-container-text"
      ></p>
      <p
        *ngIf="ldapAttribute?.site"
        [innerHTML]="'tkey;change.site.confirm_site_change.text_1.writeToSiteEnabled' | translate"
        data-automation="change-site-service-to-update-container-text-ldap"
      ></p>
      <ul>
        <li *ngFor="let service of servicesToUpdate" data-automation="change-site-service-to-update">
          <strong>
            <i class="{{ service.icon }} me-1"></i>
            {{ service.type | translate }}
          </strong>
          {{ service.name }}
        </li>
      </ul>
    </div>

    <div
      *ngIf="servicesToDelete.length"
      data-automation="change-site-service-to-delete-alert"
      class="alert alert-danger"
    >
      <p
        data-automation="change-site-service-to-delete-alert-text-1"
        class="strong"
        [innerHTML]="'tkey;change.site.confirm_site_change.text_2' | translate"
      ></p>
      <ul>
        <li *ngFor="let service of servicesToDelete" data-automation="change-site-service-to-delete">
          <strong>
            <i class="{{ service.icon }} me-1"></i>
            {{ service.type | translate }}
          </strong>
          {{ service.name }}
        </li>
      </ul>
      <p
        data-automation="change-site-service-to-delete-alert-text-2"
        [innerHTML]="'tkey;change.site.confirm_site_change.text_3' | translate : { siteName: selectedSite.name }"
        class="mb-0"
      ></p>
    </div>

    <div data-automation="change-site-warning-alert" class="alert alert-warning">
      <i [attr.class]="smacsIcons.WARNING + ' me-1'"></i>
      <strong>{{ 'tkey;change.site.confirm_site_change.text_4' | translate }}</strong>
    </div>

    <p data-automation="change-site-confirmation-text">
      <strong>{{ 'tkey;change.site.confirm_site_change.footer' | translate }}</strong>
    </p>

    <hr />
  </div>
</div>

<div class="text-end">
  <div
    *ngIf="!isSubmitting && isFormSubmitted && validationState === ValidationState.INVALID"
    data-automation="ziro-error-alert"
  >
    <div class="alert ziro-error-alert alert-danger animated shake text-start" role="alert">
      <i class="fa fa-exclamation fa-lg"></i>
      <span class="strong">
        {{ 'tkey;validators.global.required.missing.error' | translate }}
      </span>
    </div>
  </div>

  <smacs-button
    [dataAutomation]="'change-site-cancel-button'"
    [buttonStyle]="buttonStyles.DEFAULT"
    [label]="'tkey;dialogs.button.cancel'"
    [cssClass]="'me-1'"
    [isDisabled]="isSubmitting"
    (clicked)="onCancelClicked()"
  ></smacs-button>
  <smacs-submit-button
    [isPending]="isSubmitting"
    [dataAutomation]="'change-site-button'"
    [iconPending]="smacsIcons.SAVING"
    [buttonStyle]="buttonStyles.PRIMARY"
    [label]="'tkey;change.site.change_site'"
    [formSubmitSubject]="_validateAndSubmitSource"
  ></smacs-submit-button>
</div>
