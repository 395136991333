import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UccxFieldConfig } from '../../../../shared/models/generated/smacsModels';

@Injectable()
export class UccxAgentFieldConfigResource {
  constructor(private http: HttpClient) {}

  getFieldConfigs(uccxServerId: number): Observable<UccxFieldConfig> {
    return this.http.get<UccxFieldConfig>(`/services/cisco/field-configs/uccx-agents?uccx-server-id=${uccxServerId}`);
  }
}
