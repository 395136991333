import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class LdapSyncResource {
  constructor(private _http: HttpClient) {}

  post(): Observable<void> {
    return this._http.post<void>('/services/cisco/automate-jobs/cisco-ldap-syncs/run', {});
  }
}
