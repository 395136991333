<div [attr.data-automation]="config.dataAutomation" class="mb-3">
  <label
    data-automation="extension-ranges-label"
    *ngIf="config.label"
    class="smacs-forms-label col-form-label"
    [attr.for]="'extension-ranges-list'"
    [ngClass]="{
      'has-feedback': hasFeedback(),
      'smacs-forms-error': validationState === ValStates.INVALID,
      'smacs-forms-warning': validationState === ValStates.WARNING
    }"
  >
    <span *ngIf="config.required" class="text-danger smacs-forms-asterisk">*</span>
    <span>
      {{ getLabel() | translate }}
    </span>
  </label>
  <smacs-extension-ranges-form
    (smacsFormsUpdate$)="onFormUpdate($event)"
    [optionalValidators]="optionalValidators"
    [upstreamEntity]="upstreamEntity"
    [isFormSubmitted]="isFormSubmitted"
    [siteSummary]="siteSummary"
    [forceValidation$]="forceValidation$"
    [isZpm]="isZpm"
    [isDisabled]="isDisabled"
    [zpmCallingType]="zpmCallingType"
    (rangeDelete)="onRangeDelete()"
  ></smacs-extension-ranges-form>

  <div
    [ngClass]="{
      'has-feedback': hasFeedback(),
      'smacs-forms-error': validationState === ValStates.INVALID
    }"
    class="smacs-forms-ranges-validation-message"
    [hidden]="!hasFeedback()"
    data-automation="smacs-forms-validation-message"
    [innerHTML]="validationMessage?.content | translate : validationMessage?.params"
  ></div>
</div>
