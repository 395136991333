import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MicrosoftSecurityGroupSettings } from '../models/generated/smacsModels';

@Injectable()
export class MicrosoftSecurityGroupSettingsResource {
  constructor(protected http: HttpClient) {}

  get(): Observable<MicrosoftSecurityGroupSettings> {
    return this.http.get<MicrosoftSecurityGroupSettings>('/services/microsoft/admin/security-group-settings');
  }

  put(data: MicrosoftSecurityGroupSettings): Observable<void> {
    return this.http.put<void>('/services/microsoft/admin/security-group-settings', data);
  }
}
