import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuditHeaderService } from '../services/audit-header.service';
import { MobilityIdentity, MobilityIdentityRef } from '../models/generated/smacsModels';
import { Observable } from 'rxjs';
import { Nvp } from '../models/nvp';

@Injectable()
export class MobilityIdentityResource {
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  getAll(serverId: string, phoneId: string): Observable<MobilityIdentity[]> {
    return this.http.get<MobilityIdentity[]>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(serverId)}/phones/${encodeURIComponent(
        phoneId
      )}/mobility-identities`
    );
  }

  put(serverId: string, phoneId: string, mobilityIdentity: MobilityIdentity, auditTags: Nvp[]): Observable<void> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.put<void>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(serverId)}/phones/${encodeURIComponent(
        phoneId
      )}/mobility-identities/${mobilityIdentity.id}`,
      mobilityIdentity,
      options
    );
  }

  post(
    serverId: string,
    phoneId: string,
    mobilityIdentity: MobilityIdentity,
    auditTags: Nvp[]
  ): Observable<MobilityIdentityRef> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.post<MobilityIdentityRef>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(serverId)}/phones/${encodeURIComponent(
        phoneId
      )}/mobility-identities`,
      mobilityIdentity,
      options
    );
  }

  delete(serverId: string, phoneId: string, mobilityIdentityId: string, auditTags: Nvp[]): Observable<void> {
    const options = this._buildHttpOptions(auditTags);
    return this.http.delete<void>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(serverId)}/phones/${encodeURIComponent(
        phoneId
      )}/mobility-identities/${mobilityIdentityId}`,
      options
    );
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return { headers: this.auditHeaderService.buildHeader(auditTags) };
  }
}
