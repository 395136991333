<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div *ngIf="!isLoading">
  <smacs-fieldset-header [legend]="'tkey;pages.details.phoneSettings.header' | translate">
    <smacs-phone-model-protocol-form
      [entity]="modelProtocolFormData"
      [fieldConfig]="modelProtocolFieldConfig"
      [phoneType]="phoneType"
      [isGeneratingPhone]="loadingSubscriptions.phoneFieldConfig || (!phone.ctiAssociatedEndUsers && !!phone.protocol)"
      (modelProtocolUpdate$)="onModelProtocolFormUpdate($event)"
    ></smacs-phone-model-protocol-form>
    <smacs-phone-fields-form
      *ngIf="fieldConfig && !isLoadingPhoneFields"
      [cucmServerId]="cucmServerId"
      [endUserRef]="endUserRef"
      [phoneType]="phoneType"
      [fieldConfig]="fieldConfig"
      [entity]="phoneFieldsEntity"
      [isGeneratingPhone]="loadingSubscriptions.phoneFieldConfig || !phone.ctiAssociatedEndUsers"
      (smacsFormsUpdate$)="onPhoneFieldsChange($event)"
      [attr.data-automation]="'phone-fields-form'"
      [isFormStateInvalid]="isFormStateInvalid"
      [isErrorThrown]="isErrorThrown"
    >
      <smacs-phone-button-template-form
        *ngIf="
          phone.model &&
          phone.protocol &&
          phone.buttons.length > 0 &&
          !loadingSubscriptions.phoneFieldConfig &&
          !isAutomaticPhoneButtonTemplateMode &&
          possiblePhoneButtonTemplates
        "
        [entity]="phoneButtonTemplateEntity"
        [possibleOptions]="possiblePhoneButtonTemplates"
        [isErrorThrown]="false"
        (smacsFormsUpdate$)="onPhoneButtonTemplateChange($event)"
      ></smacs-phone-button-template-form>
    </smacs-phone-fields-form>
  </smacs-fieldset-header>

  <smacs-phone-services-form
    *ngIf="fieldConfig?.phoneServices && (fieldConfig.phoneServices.show || isExistingPhoneWithServiceSubscriptions)"
    [hidden]="loadingSubscriptions.phoneFieldConfig || !phone.ctiAssociatedEndUsers"
    [serviceSubscriptionFieldConfig]="fieldConfig?.phoneServices"
    [entity]="phone.serviceSubscriptions"
    (smacsFormsUpdate$)="onPhoneServiceSubscriptionsChange($event)"
  ></smacs-phone-services-form>

  <app-loading-block
    *ngIf="loadingSubscriptions.phoneFieldConfig"
    data-automation="phone-buttons-loading-block"
  ></app-loading-block>

  <div
    *ngIf="phone.model && phone.protocol && phone.buttons.length > 0 && !loadingSubscriptions.phoneFieldConfig"
    data-automation="phone-buttons-container"
  >
    <smacs-phone-buttons
      *ngIf="phoneButtonTemplateFieldConfig"
      [userMode]="DragDropMode.HELPDESK"
      [global360View]="global360View"
      [siteId]="siteId"
      [serverId]="cucmServerId.toString()"
      [templateButtons]="phoneButtonTemplateFieldConfig.buttons"
      [isPending]="isPhoneButtonTemplateChangePending"
      [attr.data-automation]="'phone-buttons-form'"
      [fieldConfig]="phoneFieldConfig"
      [endUserRef]="endUserRef"
      [phoneType]="phoneType"
      [phoneButtonTemplateConfig]="phoneButtonTemplateFieldConfig"
      [isAutomaticPhoneButtonTemplateSelectionEnabled]="isAutomaticPhoneButtonTemplateMode"
      [isEnhancedLineModeOn]="isDisplayEnhancedLineMode"
      (validationStateSubject)="onPhoneButtonValidationStateChange($event)"
      (expansionModuleSelected)="onExpansionModuleSelected($event)"
    ></smacs-phone-buttons>
  </div>
</div>
