<div class="smacs-admin-page-content">
  <app-loading-block *ngIf="isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" *ngIf="!isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.email_templates.table_view.description' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div data-automation="email-templates-entity-table">
      <app-entity-table [table]="table" [tableRows]="tableRows"></app-entity-table>
    </div>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
