<smacs-service-tile-edit-multi
  *ngIf="tileStates.length > 1"
  [attr.data-automation]="tileStates[0].dataAutomation + '-multi-edit-tile'"
  [serviceName]="
    tileStates[0].serviceType === tiles.DESKPHONE ? 'tkey;shared.model.deskphone.text' : tileStates[0].serviceName
  "
  [description]="getMultiTileDescription(tileStates[0], tileStates.length)"
  [icon]="tileStates[0].icon"
>
  <smacs-service-tile
    *ngFor="let tileState of tileStates; let i = index"
    [isDeskPhone]="tileState.serviceType === tiles.DESKPHONE"
    [attr.data-automation]="tileState.dataAutomation"
    [dataAutomation]="tileState.dataAutomation"
    [serviceType]="tileState.serviceType"
    [tileType]="tileState.tileType"
    [serviceName]="tileState.serviceName"
    [icon]="tileState.icon"
    [description]="tileState.description"
    [isAdding]="tileState.isAdding"
    [isDeleting]="tileState.isDeleting"
    [isDeleted]="tileState.isDeleted"
    [isEnabled]="tileState.isEnabled"
    [isDisplayed]="tileState.isDisplayed"
    [tooltip]="tileState.tooltip"
    [phone]="
      tileState.serviceType === tiles.DESKPHONE && tileState.service
        ? { name: tileState.service['name'], serverId: tileState.service['serverId'] }
        : null
    "
    (editDeskPhoneAddClicked)="onAddTileClicked(tileState, i)"
    (multiEditAddClicked)="onAddTileClicked(tileState, i)"
    (addTileClicked)="onAddTileClicked(tileState, i)"
    (editTileClicked)="onEditTileClicked(tileState)"
    (editTileDeleteClicked)="onEditTileDeleteClicked(tileState, i)"
    (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileState, i)"
    (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileState, i)"
    (editDeskPhoneCopyClicked)="onCopyDeskphoneClicked(tileState, i)"
  ></smacs-service-tile>
</smacs-service-tile-edit-multi>
<div *ngIf="tileStates.length === 1">
  <smacs-service-tile
    *ngFor="let tileState of tileStates; let i = index"
    [isDeskPhone]="tileState.serviceType === tiles.DESKPHONE"
    [attr.data-automation]="tileState.dataAutomation"
    [dataAutomation]="tileState.dataAutomation"
    [serviceType]="tileState.serviceType"
    [tileType]="tileState.tileType"
    [serviceName]="tileState.serviceName"
    [icon]="tileState.icon"
    [description]="tileState.description"
    [isAdding]="tileState.isAdding"
    [isDeleting]="tileState.isDeleting"
    [isDeleted]="tileState.isDeleted"
    [isEnabled]="tileState.isEnabled"
    [isDisplayed]="tileState.isDisplayed"
    [tooltip]="tileState.tooltip"
    [phone]="
      tileState.serviceType === tiles.DESKPHONE && tileState.service
        ? { name: tileState.service['name'], serverId: tileState.service['serverId'] }
        : null
    "
    (editDeskPhoneAddClicked)="onAddTileClicked(tileState, i)"
    (multiEditAddClicked)="onAddTileClicked(tileState, i)"
    (addTileClicked)="onAddTileClicked(tileState, i)"
    (editTileClicked)="onEditTileClicked(tileState)"
    (editTileDeleteClicked)="onEditTileDeleteClicked(tileState, i)"
    (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileState, i)"
    (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileState, i)"
    (editDeskPhoneCopyClicked)="onCopyDeskphoneClicked(tileState, i)"
  ></smacs-service-tile>
</div>
