<smacs-fieldset-header [legend]="'tkey;admin.email_templates.edit_view.name.header'">
  <smacs-text [fieldId]="'emailTemplateName'"></smacs-text>
  <smacs-text [fieldId]="'senderName'"></smacs-text>
</smacs-fieldset-header>
<smacs-fieldset-header [legend]="'tkey;admin.email_templates.edit_view.recipients.header'">
  <smacs-checkbox [fieldId]="'is360ViewEndUserRecipient'"></smacs-checkbox>
  <smacs-multi-text [fieldId]="'otherRecipients'"></smacs-multi-text>
</smacs-fieldset-header>
<smacs-fieldset-header [legend]="'tkey;admin.email_templates.edit_view.email_contents.header'">
  <smacs-text [fieldId]="'emailSubject'">
    <smacs-button
      [label]="'tkey;site_management.site.edit_custom_input_value.title'"
      [icon]="SmacsIcons.ADD"
      [buttonStyle]="ButtonStyles.DEFAULT"
      [dataAutomation]="'email-subject-insert-variable-button'"
      (clicked)="openVariableEditorModal('emailSubject')"
    ></smacs-button>
  </smacs-text>

  <smacs-code-area [fieldId]="'emailBody'">
    <smacs-button
      [label]="'tkey;site_management.site.edit_custom_input_value.title'"
      [icon]="SmacsIcons.ADD"
      [buttonStyle]="ButtonStyles.DEFAULT"
      [cssClass]="'border-top-0 ms-2'"
      [dataAutomation]="'email-body-insert-variable-button'"
      (clicked)="openVariableEditorModal('emailBody')"
    ></smacs-button>
  </smacs-code-area>
</smacs-fieldset-header>

<legend>{{ 'tkey;admin.email_templates.edit_view.preview.header' | translate }}</legend>
<div class="row mb-2">
  <div class="smacs-forms-label text-lg-end col-form-label col-lg-3">
    <label>{{ 'tkey;admin.email_templates.edit_view.subject.label' | translate }}</label>
  </div>
  <div class="col-lg-9" data-automation="email-template-subject-preview">
    <div class="form-control font-light subject-preview" [innerHTML]="subjectHtmlPreview"></div>
  </div>
</div>
<div class="row mb-4">
  <div class="smacs-forms-label text-lg-end col-form-label col-lg-3">
    <label>{{ 'tkey;admin.email_templates.edit_view.body.label' | translate }}</label>
  </div>
  <div class="col-lg-9" data-automation="email-template-body-preview">
    <div class="form-control font-light body-preview" [innerHTML]="bodyHtmlPreview"></div>
  </div>
</div>
