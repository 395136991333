<app-top-nav></app-top-nav>

<div class="smacs-content">
  <side-navigation
    *ngIf="showAdminMenu"
    [title]="'tkey;admin.menu.title'"
    [navSections]="adminLinks"
    [navProps]="navProps"
  ></side-navigation>

  <div class="container-fluid overflow-hidden px-0">
    <div
      class="offset-md-1 col-md-10 smacs-page-alert alert border-warning"
      *ngIf="pageDisabled"
      data-automation="smacs-page-disabled-alert"
    >
      <i class="{{ smacsIcons.WARNING }} text-warning fa-4x"></i>
      <div class="ms-3" [innerHTML]="pageDisabledMessage | translate"></div>
    </div>
    <router-outlet *ngIf="!pageDisabled"></router-outlet>
  </div>
</div>
