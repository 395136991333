import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { SmacsRadioConfig } from '../../../../../forms/fields/radio/smacs-radio.component';
import {
  SmacsFieldComponentConfig,
  SmacsFormConfig,
  SmacsFormData,
  SmacsFormsValidationState,
} from '../../../../../forms/smacs-forms-models';
import { HtmlInputType, SmacsTextConfig } from '../../../../../forms/fields/text/smacs-text.component';
import { TranslateService } from '@ngx-translate/core';
import { SmacsIcons } from '../../../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../../../button/button.component';
import { of } from 'rxjs';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';

enum LdapProtocol {
  LDAP = 'ldap',
  LDAPS = 'ldaps',
}

interface AdminLdapManagementSettingsLdapServerUrlFormData extends SmacsFieldComponentConfig {
  hostAddress: string;
  protocol: LdapProtocol;
  port: string;
}

@Component({
  selector: 'smacs-admin-ldap-management-settings-ldap-server-url-form',
  templateUrl: './admin-ldap-management-settings-ldap-server-url-form.component.html',
})
export class AdminLdapManagementSettingsLdapServerUrlFormComponent
  extends SmacsFormAbstractDirective<string, AdminLdapManagementSettingsLdapServerUrlFormData>
  implements OnChanges
{
  @Input() isParentFormSubmitted: boolean;

  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  formConfig = {
    fields: {
      hostAddress: {
        label: 'tkey;admin.ldap.settings.servers.host.label',
        dataAutomation: 'admin-ldap-management-server-host-address',
        required: true,
      },
      protocol: {
        label: 'tkey;admin.ldap.settings.servers.protocol.label',
        componentConfig: new SmacsRadioConfig<string>({
          buttons: [
            {
              value: LdapProtocol.LDAP,
              label: 'LDAP',
            },
            {
              value: LdapProtocol.LDAPS,
              label: 'LDAPS',
            },
          ],
          inline: true,
        }),
        dataAutomation: 'admin-ldap-management-server-protocol',
        required: true,
      },
      port: {
        label: 'tkey;admin.ldap.settings.servers.port.label',
        dataAutomation: 'admin-ldap-management-server-port',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        required: true,
        validation: [
          {
            validator: (val: number) =>
              val >= 1 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: this.translateService.instant('tkey;validators.global.error.min', {
              minValue: 1,
            }),
          },
          {
            validator: (val: number) =>
              val <= 11999 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: this.translateService.instant('tkey;validators.global.error.max', {
              maxValue: 11999,
            }),
          },
        ],
      },
    },
  } as SmacsFormConfig;

  constructor(private translateService: TranslateService, protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  protected submit() {
    return of(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (
      changes.isParentFormSubmitted &&
      changes.isParentFormSubmitted.currentValue &&
      changes.isParentFormSubmitted.currentValue !== changes.isParentFormSubmitted.previousValue
    ) {
      this._validateAndSubmitSource.next(true);
    }
  }

  toFormData = (entity: string): AdminLdapManagementSettingsLdapServerUrlFormData => {
    const match = entity.match(/(ldaps?:\/\/)?(.*?):(.*)/);
    const [protocolStr, hostAddress, port] = (match || []).slice(1);
    const protocol = protocolStr ? (protocolStr === 'ldaps://' ? LdapProtocol.LDAPS : LdapProtocol.LDAP) : null;
    return {
      hostAddress,
      protocol,
      port,
    };
  };

  toEntity = (formData: SmacsFormData): string => {
    const protocolPrefix =
      formData.protocol === '' ? '' : formData.protocol === LdapProtocol.LDAPS ? 'ldaps://' : 'ldap://';
    if (formData.port !== '389' && formData.port !== '636') {
      return `${protocolPrefix}${formData.hostAddress.trim() || ''}:${formData.port}`;
    }
    return `${protocolPrefix}${formData.hostAddress.trim() || ''}:${
      formData.protocol === LdapProtocol.LDAPS ? '636' : '389'
    }`;
  };
}
