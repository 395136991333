<div class="public-phone">
  <app-top-nav></app-top-nav>
  <div *ngIf="!isLoading" class="row public-phone__window">
    <div class="col-lg-2 col-md-3">
      <smacs-public-phone-contact-card></smacs-public-phone-contact-card>
      <smacs-public-phone-operations-menu
        *ngIf="phoneId"
        [phoneId]="phoneId"
        [isCopyPhoneButtonEnabled]="isCopyPhoneButtonEnabled"
        [isUnsavedCopy]="isUnsavedCopy"
        class="mt-4"
      ></smacs-public-phone-operations-menu>
    </div>
    <div class="col-lg-10 col-md-9 public-phone__window__main" data-automation="public-phone">
      <app-breadcrumbs></app-breadcrumbs>
      <router-outlet></router-outlet>
    </div>
  </div>
  <app-loading-block *ngIf="isLoading"></app-loading-block>
  <div class="row">
    <app-bottom-nav class="w-100"></app-bottom-nav>
  </div>
</div>
