<div
  class="service-list-service-type"
  [attr.data-enabled]="isEnabled"
  [attr.data-automation]="'service-tile-wrapper'"
  [attr.data-service-type]="serviceType"
  [attr.data-service-state]="serviceState"
  [attr.data-tile-type]="tileType"
  [hidden]="!isDisplayed"
>
  <smacs-service-tile-add
    *ngIf="tileType === serviceTileType.ADD"
    [tooltip]="tooltip"
    [isEnabled]="isEnabled"
    [isInProgress]="isInProgress"
    [isInProgressBadgeText]="isInProgressBadgeText"
    [serviceName]="serviceName"
    [icon]="icon"
    [isAdding]="isAdding"
    [isDeleted]="isDeleted"
    (addTileClicked)="onAddTileClicked()"
    [attr.data-automation]="dataAutomation"
  ></smacs-service-tile-add>

  <smacs-service-tile-delete
    *ngIf="tileType === serviceTileType.DELETE"
    [serviceName]="serviceName"
    [description]="description"
    [isDeleting]="isDeleting"
    (confirmButtonClicked)="onDeleteTileConfirmButtonClicked()"
    (cancelButtonClicked)="onDeleteTileCancelButtonClicked()"
    [attr.data-automation]="dataAutomation"
  ></smacs-service-tile-delete>

  <smacs-service-tile-edit
    *ngIf="tileType === serviceTileType.EDIT"
    [isEnabled]="isEnabled"
    [serviceName]="serviceName"
    [icon]="icon"
    [description]="description"
    [isDeskPhone]="isDeskPhone"
    [isDevice]="isDevice"
    [isStatusCheckInProgress]="isStatusCheckInProgress"
    [hasMultipleDeskPhones]="hasMultipleDeskPhones"
    [isDeleteShown]="isDeleteShown"
    [deleteTooltip]="deleteTooltip"
    [deviceToolip]="deviceToolip"
    [deviceIconMarkup]="deviceIconMarkup"
    [tooltip]="tooltip"
    [isDeleteEnabled]="isDeleteEnabled"
    [isExchangeEnabled]="isExchangeEnabled"
    [isExchangeShown]="isExchangeShown"
    [phone]="phone"
    (editTileClicked)="onEditTileClicked()"
    (editDeskPhoneClicked)="onEditDeskPhoneClicked()"
    (deskPhoneAddClicked)="onEditTileDeskPhoneAddClicked()"
    (deskPhoneCopyClicked)="onEditTileDeskPhoneCopyClicked()"
    (deleteClicked)="onEditTileDeleteClicked()"
    (exchangeClicked)="onEditTileExchangeClicked()"
    [attr.data-automation]="dataAutomation"
  ></smacs-service-tile-edit>
</div>
