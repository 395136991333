import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateAgo',
  pure: true,
})
export class DateAgoPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      if (!value.includes('Z')) {
        value = value + 'Z';
      }
      const seconds = Math.floor((+new Date().getTime() - +new Date(value)) / 1000);
      if (seconds < 60) {
        // less than 60 seconds ago will show as 'Just now'
        return 'Just now';
      }
      return moment(value).fromNow();
    }
    return value;
  }
}
