import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  ButtonState,
  ExpansionModule,
  IndexedPhoneButton,
  PhoneButton,
  PhoneButtonTypes,
} from '../models/phone-button';
import { DragulaService, Group } from 'ng2-dragula';
import { Observable, Subject, Subscription } from 'rxjs';
import { phoneModels, PhoneModelUI } from './phone-buttons-mapping';
import {
  EndUserRef,
  ExtensionMobility,
  Global360View,
  LineButton,
  Phone,
  PhoneButtonTemplate,
  PhoneButtonTemplateFieldConfig,
  PhoneFieldConfig,
  TemplateButton,
} from '../models/generated/smacsModels';
import { DragDropMode } from './drag-drop-mode.enum';
import { PhoneUiContext } from './contexts/phone-ui.context';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { PhoneResource } from '../resources/phone.resource';
import { ToastService } from '../services/toast.service';
import { SmacsFormsValidationState } from '../../forms/smacs-forms-models';
import { cloneDeep, isEqual } from 'lodash';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { PhoneButtonTemplateService } from '../services/phone-button-template.service';
import { PhoneButtonsService } from './shared/phone-buttons.service';
import { ButtonStyles } from '../../button/button.component';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../services/smacs-modal.service';
import { PhoneType } from '../models/service-type';
import { ExtMobilityUiContext } from './contexts/ext-mobility-ui.context';
import { AbstractUiContext } from './contexts/abstract-ui.context';
import MouseDownEvent = JQuery.MouseDownEvent;

enum ExpansionModuleTabId {
  PRIMARY_BUTTONS = 'primary-buttons-tab',
  ADD_MODULE = 'add-expansion_module-tab',
}

const DRAGULA_GROUP_NAME = 'PHONE_BUTTONS';

const SELF_SERVE_MOVABLE_BUTTON_TYPES = [PhoneButtonTypes.speed_dial, PhoneButtonTypes.blf, PhoneButtonTypes.line];

export class DragStartUpdate {
  constructor(public dragIdx: number) {}
}

export class DragOverUpdate {
  constructor(public draggingIdx: number, public targetIdx: number) {}
}

export class DragDropUpdate {
  constructor(public srcIdx: number, public destIdx: number) {}
}

export type DragUpdate = DragStartUpdate | DragOverUpdate | DragDropUpdate;

export interface ExtensionMobilityDnD extends ExtensionMobility {
  expansionModules: string[];
}

export type UdpInfo = {
  udpModel: string;
  udpId: string;
};

@Component({
  selector: 'smacs-phone-buttons',
  templateUrl: './phone-buttons.component.html',
  styleUrls: ['./phone-buttons.component.scss'],
})
export class PhoneButtonsComponent implements OnInit, OnDestroy {
  @Input() userMode: DragDropMode;
  @Input() isPending: boolean;
  @Input() siteId: number;
  @Input() serverId: string;
  @Input() isEnhancedLineModeOn: boolean;
  @Input() isAutomaticPhoneButtonTemplateSelectionEnabled: boolean;
  @Input() templateButtons: TemplateButton[];
  @Input() fieldConfig: PhoneFieldConfig;
  @Input() phoneButtonTemplateConfig: PhoneButtonTemplateFieldConfig;
  @Input() global360View: Global360View;
  @Input() phoneType: PhoneType;
  @Input() endUserRef: EndUserRef;

  @Output() validationStateSubject = new Subject<SmacsFormsValidationState>();
  @Output() copyUdpButtonClicked = new EventEmitter<UdpInfo>();
  @Output() expansionModuleSelected = new Subject<string[]>();

  private _validationState: SmacsFormsValidationState;
  private _dragulaSubs = new Subscription();
  private _subscription = new Subscription();
  private _dragEventEmitters: EventEmitter<DragUpdate>;
  private _buttons: PhoneButton[];
  private _indexedButtons: IndexedPhoneButton[];

  phone: Phone | ExtensionMobilityDnD;
  standardButtons: IndexedPhoneButton[];
  expansionButtons: ExpansionModule[];
  phoneUI: PhoneModelUI;
  expansionsUI: PhoneModelUI[] = [];
  buttonStates: ButtonState[];
  isButtonSwapped = false;
  supportedKems: (keyof typeof phoneModels)[] | string[] = [];
  expansionTabId = ExpansionModuleTabId;
  expansionModuleTabs: string[] = [];
  active: string;
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  selectedModule: keyof typeof phoneModels | string = null;
  isKemCompatible: boolean;
  isAddingNewKem = false;
  isPhoneButtonTemplateStandard: boolean;
  context: AbstractUiContext;
  isKemTitleDisplayed = true;
  isSaving = false;

  isSingleButton = false;

  /** When this is fired, all popovers open in phone-buttons-standard components will be closed. */
  private _closePopoverSubject = new Subject<NgbPopover>();
  closePopover$ = this._closePopoverSubject.asObservable();

  constructor(
    private dragulaService: DragulaService,
    private hostElRef: ElementRef,
    private phoneResource: PhoneResource,
    private toastService: ToastService,
    private phoneButtonTemplateService: PhoneButtonTemplateService,
    private phoneButtonsService: PhoneButtonsService,
    private translateService: TranslateService,
    private smacsModalService: SmacsModalService,
    private smacsFormStateService: SmacsFormStateService,
    private injector: Injector,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    const dragStart$ = this.dragulaService.drag(DRAGULA_GROUP_NAME);
    const dragOver$ = this.dragulaService.over(DRAGULA_GROUP_NAME);
    const dragDropped$ = this.dragulaService.drop(DRAGULA_GROUP_NAME);
    let dragIdx: number;
    let dropIdx: number;

    this._dragulaSubs.add(
      dragStart$.subscribe(({ source }) => {
        const buttonData = this._getButtonData(source as HTMLElement);
        dragIdx = Number(buttonData['buttonIdx']);
        this._dragEventEmitters.emit(new DragStartUpdate(dragIdx));
      })
    );

    this._dragulaSubs.add(
      dragOver$.subscribe(({ container }) => {
        const buttonData = this._getButtonData(container as HTMLElement);
        dropIdx = Number(buttonData['buttonIdx']);
        this._dragEventEmitters.emit(new DragOverUpdate(dragIdx, dropIdx));
      })
    );

    this._dragulaSubs.add(
      dragDropped$.subscribe(() => {
        this._dragEventEmitters.emit(new DragDropUpdate(dragIdx, dropIdx));
      })
    );
  }

  /*
    The drag and drop functionality starts here.

       1. We create the drag and drop grouping via _createDragGroup, making all elements with the
          smacs-dnd-nav-item css class as draggable.
       2. _handleDragEvents handles what to do when the dragula events (created in constructor) are fired.

          _handleDragStart is what we do when we begin dragging an element
          _handleDragOver is what we do when we are hovering one draggable element over another
          _handleDragDrop is what we do once we drop the element
   */
  ngOnInit() {
    const drake = this._createDragGroup().drake;
    drake.on('drop', () => drake.cancel(true));
    this._dragEventEmitters = new EventEmitter<DragUpdate>();
    this._handleDragEvents();
    this.context =
      this.phoneType === PhoneType.EXTENSION_MOBILITY
        ? this.injector.get(ExtMobilityUiContext)
        : this.injector.get(PhoneUiContext);
    this.isSingleButton = this.templateButtons?.length === 1;

    const sub = this.context.phoneState$.subscribe((phone) => {
      if (this.userMode === DragDropMode.HELPDESK && !this.isSingleButton) {
        const extensionMobilitySupportedKem =
          this.phoneType === PhoneType.EXTENSION_MOBILITY
            ? phoneModels[phone.model as keyof typeof phoneModels].largestSupportedKem
            : null;
        this.isKemCompatible =
          (!!this.fieldConfig && this.fieldConfig.expansionModules?.length > 0) || !!extensionMobilitySupportedKem;
        this.supportedKems = this.isKemCompatible
          ? this.phoneType === PhoneType.EXTENSION_MOBILITY
            ? [extensionMobilitySupportedKem]
            : this.fieldConfig.expansionModules?.map((module) => module.model as keyof typeof phoneModels)
          : [];
        if (!this.selectedModule) {
          this.selectedModule = this._getModule();
        }
      }

      const isPhoneButtonTemplateChanged = !isEqual(this.phone?.phoneButtonTemplate, phone?.phoneButtonTemplate);
      const isServiceSubscriptionChanged = !isEqual(this.phone?.serviceSubscriptions, phone?.serviceSubscriptions);
      if (!this.phone || isPhoneButtonTemplateChanged) {
        if (isPhoneButtonTemplateChanged) {
          this.expansionModuleTabs = [];
        }

        this.phone =
          this.phoneType === PhoneType.DESKPHONE || this.phoneType === PhoneType.CIPC
            ? (phone as Phone)
            : (phone as ExtensionMobilityDnD);

        if (this.phoneType === PhoneType.EXTENSION_MOBILITY) {
          const pbt = this.context.getPhoneTemplate();
          if (!pbt) {
            // Get phone button template
            this.phoneButtonTemplateService
              .getTemplate(
                !!phone.id ? phone.phoneButtonTemplate.id : this.phoneButtonTemplateConfig.ref.id,
                Number(this.serverId)
              )
              .subscribe((data: PhoneButtonTemplate) => {
                this.isPhoneButtonTemplateStandard = data.standardTemplate;
                this.setUdpKem(phone.model);
                this._setUp(this.phone);
              });
          } else {
            this.isPhoneButtonTemplateStandard = pbt.standardTemplate;
            this.setUdpKem(phone.model);
            this._setUp(this.phone);
          }
          this.changeDetectorRef.detectChanges();
        } else {
          this._setUp(this.phone);
        }
      } else if (!isEqual(phone, this.phone)) {
        this.phone = this.phoneType === PhoneType.DESKPHONE ? (phone as Phone) : (phone as ExtensionMobilityDnD);
      }

      if (isServiceSubscriptionChanged) {
        this.phone.serviceSubscriptions = phone.serviceSubscriptions;
      }
      this.changeDetectorRef.detectChanges();
    });
    this._subscription.add(sub);

    const savingSub = this.context.isSaving$.subscribe((isSaving) => {
      this.isSaving = isSaving;
    });
    this._subscription.add(savingSub);

    this.isKemTitleDisplayed = this.phoneType !== PhoneType.EXTENSION_MOBILITY;
    this.active = this.expansionTabId.PRIMARY_BUTTONS;
    window.addEventListener('click', this._closePopovers);
  }

  ngOnDestroy() {
    this.dragulaService.destroy(DRAGULA_GROUP_NAME);
    this._dragulaSubs.unsubscribe();
    this._subscription.unsubscribe();
    window.removeEventListener('click', this._closePopovers);
  }

  /** When opening a popover from the phone-buttons-standard component, close any which might be open on other
   * phone-buttons-standard components. */
  onPopoverOpened(popover: NgbPopover) {
    this._closePopoverSubject.next(popover);
  }

  setUdpKem(phoneModel: string) {
    this.phone.expansionModules = [];
    this.expansionModuleTabs = [];
    if (!this.isPhoneButtonTemplateStandard) {
      const maxAmount = phoneModels[phoneModel as keyof typeof phoneModels].maxAmount;

      const kems = [];
      if (this.isKemCompatible) {
        for (let i = 0; i < maxAmount; i++) {
          kems.push(this.selectedModule);
          if (maxAmount && !this.isPhoneButtonTemplateStandard) {
            this.phone.expansionModules.push(this.selectedModule);
          }
        }
      }
      this.expansionModuleSelected.next(kems);
    }
  }

  addNewTab(module: keyof typeof phoneModels | string) {
    const existingKemsLength = this.expansionModuleTabs.length;
    this.selectedModule = module;
    this.expansionModuleTabs.push(this.selectedModule);
    const moduleIndex = this.expansionModuleTabs.length - 1;
    this.active = this.expansionTabId.ADD_MODULE.concat(String(moduleIndex));
    this.isAddingNewKem = true;
    this._createExpansionModuleOnTabClick(this.selectedModule as keyof typeof phoneModels);

    if (this.expansionModuleTabs.length > 1) {
      const getConfigurableButtonCount = this._getNumberOfConfigurableButtons(this.expansionsUI[moduleIndex]);
      const startingIndex = this._getCurrentlyConfiguredButtons(getConfigurableButtonCount, existingKemsLength);
      // Modify phone buttons only if the template is a standard one.
      if (this.isPhoneButtonTemplateStandard) {
        for (let i = startingIndex; i < startingIndex + getConfigurableButtonCount; i++) {
          this._buttons[i] = this._getNewButtonForKemTemplate();
        }
      }
      /**
       * Actions post adding a new KEM
       */
      this._setIndexedButtons();
      const kemButtons: IndexedPhoneButton[] = this._indexedButtons.slice(
        startingIndex,
        startingIndex + getConfigurableButtonCount
      );
      this.expansionButtons.push({ expansionUI: this.expansionsUI[moduleIndex], buttons: kemButtons });
      this._resetButtonStatesExcludingInvalid();
      this._updatePhone();
      this.isAddingNewKem = false;
    } else {
      this._getProposedButtons(moduleIndex).subscribe((proposedButtons: PhoneButton[]) => {
        this._setNewPhoneButtons(proposedButtons, moduleIndex);
        this._updatePhone();
        this.isAddingNewKem = false;
      });
    }
  }

  canDisplayKemDropdown(): boolean {
    return (
      this.userMode === DragDropMode.HELPDESK &&
      this.isAutomaticPhoneButtonTemplateSelectionEnabled &&
      this.phoneType !== PhoneType.EXTENSION_MOBILITY
    );
  }

  canAddMoreExpansionModule(): boolean {
    if (this.phoneType === PhoneType.EXTENSION_MOBILITY) {
      return false;
    }
    if (!this.isKemCompatible) {
      return false;
    }
    if (this.selectedModule) {
      const getMaxAllowed = this.fieldConfig.expansionModules.find(
        (expansionModule) => expansionModule.model === this.selectedModule
      ).maxAmount;
      return this.expansionModuleTabs.length < getMaxAllowed;
    }
    return true;
  }

  getAddTabDisabledTooltip(): string {
    let tooltipMsg = '';
    if (!this.isKemCompatible) {
      tooltipMsg = 'tkey;shared.device.phone.phone_buttons.model.kem.unsupported';
    } else if (!this.canAddMoreExpansionModule()) {
      tooltipMsg = 'tkey;shared.device.phone.phone_buttons.add.expansion.module.disable.tooltip';
    }
    return tooltipMsg;
  }

  onSecondaryLineUpdating(isUpdating: boolean) {
    setTimeout(() => {
      this.isPending = isUpdating;
    });
  }

  handleInvalidButtonState() {
    this._updateValidationState();
  }

  changeActiveTab(index: number) {
    this.active = this.expansionTabId.ADD_MODULE.concat(String(index));
  }

  onRemoveKem($event: MouseDownEvent, index: number) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        promptBody: this.translateService.instant(
          'tkey;shared.device.phone.phone_buttons.delete.kem.modal.prompt.message',
          { selectedModule: this.selectedModule, index: this.expansionModuleTabs.length }
        ),
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onConfirmCloseTab($event, index),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
    this._updateValidationState();
  }

  onClickCopyUdpButtons() {
    const udpModel = this.global360View.extensionMobilities[0].model;
    const udpId = this.global360View.extensionMobilities[0].id;
    this.copyUdpButtonClicked.next({ udpModel, udpId });
  }

  updateSingleButton = ($event: IndexedPhoneButton) => {
    this.isButtonSwapped = false;
    const buttonIdx = $event.index;
    this._buttons[buttonIdx] = $event.button;
    this.phone.buttons = [...this._buttons];
    this.context.set(this.phone);
    this._setIndexedButtons();
    this._updateValidationState();
  };

  deleteSingleButton = ($event: IndexedPhoneButton) => {
    const buttonIdx = $event.index;
    Object.assign(this._buttons[buttonIdx], $event.button);
    this.phone.buttons = [...this._buttons];
    this.context.set(this.phone);
    this._updateValidationState();

    if (this.userMode === DragDropMode.HELPDESK) {
      this.smacsFormStateService.setIsFormDirty(true);
    }
  };

  showCopyUdpButton(): boolean {
    // Hide button if self serve mode or a public phone
    if (this.userMode === DragDropMode.SELF_SERVE || !this.global360View) {
      return false;
    }
    const udpLength = this.global360View.extensionMobilities.length;
    const isDeskphone = this.phoneType === PhoneType.DESKPHONE;
    return udpLength && isDeskphone;
  }

  disabledCopyUdpButtonTooltip(): string {
    const isUdpOnMultiClusters = this.global360View.extensionMobilities.length > 1;
    return isUdpOnMultiClusters
      ? this.translateService.instant(
          'tkey;shared.device.phone.phone_buttons.primary.button.tab.copy_button.disabled_label.udp_on_multi_clusters'
        )
      : this.translateService.instant(
          'tkey;shared.device.phone.phone_buttons.primary.button.tab.copy_button.disabled_label.model_doesnt_match'
        );
  }

  isCopyUdpButtonEnabled(): boolean {
    const udpLength = this.global360View.extensionMobilities.length;
    const isModelSame = this.global360View.extensionMobilities[0].model === this.phone.model;
    return udpLength === 1 && isModelSame;
  }

  private _getNewButtonForKemTemplate(): PhoneButton {
    return this.phoneButtonsService.newButtonOfType(PhoneButtonTypes.speed_dial);
  }

  private _getCurrentlyConfiguredButtons(configurableCount: number, numberOfKems: number): number {
    const expansionButtonsLength = configurableCount * numberOfKems;
    return this.isEnhancedLineModeOn
      ? this.phoneUI.numberStandard + expansionButtonsLength
      : this.phoneUI.numberLeft > 0
      ? this.phoneUI.numberLeft + expansionButtonsLength
      : this.phoneUI.numberStandard + expansionButtonsLength;
  }

  private _onConfirmCloseTab($event: MouseDownEvent, index: number): Observable<void> {
    $event.stopPropagation();
    $event.preventDefault();
    return new Observable((subscriber) => {
      const getCountOfButtonsToDelete = this.expansionButtons[index].buttons.length;
      this._buttons = this.isPhoneButtonTemplateStandard
        ? this._buttons.slice(0, -getCountOfButtonsToDelete)
        : this._buttons;
      this.expansionModuleTabs.splice(index, 1);
      this.expansionButtons.splice(index, 1);
      this.expansionsUI.splice(index, 1);
      if (this.expansionModuleTabs.length === 0) {
        this.active = this.expansionTabId.PRIMARY_BUTTONS;
      } else {
        this.changeActiveTab(index - 1);
      }
      this.selectedModule =
        this.expansionModuleTabs.length === 0 && this.supportedKems.length > 1 ? null : this.selectedModule;
      this._updatePhone();

      subscriber.next();
      subscriber.complete();
    });
  }

  private _closePopovers = ($event?: any) => {
    if ($event && (typeof $event.target.className !== 'string' || !$event.target.className.includes('popover'))) {
      this._closePopoverSubject.next(null);
    }
  };

  private _setUp(phone: Phone | ExtensionMobility) {
    this._buttons = phone.buttons as PhoneButton[];
    this._setModelsUI();
    this._setButtons();
    if (this._buttons) {
      this.resetButtonStates();
    }
  }

  private buttonOrderUpdated() {
    this.context.set(this.phone);

    if (this.userMode === DragDropMode.HELPDESK) {
      this.smacsFormStateService.setIsFormDirty(true);
      this.isPending = false;
    } else {
      this.phoneResource.put(this.phone as Phone, this.serverId).subscribe(() => {
        this.isPending = false;
        this.toastService.pushSaveToast('Deskphone', this.phone.description, SmacsIcons.DESKPHONE);
      });
    }
  }

  private _setModelsUI() {
    this._setPhoneModel();
    this._setExpansionModels();
  }

  private _setButtons() {
    this._setIndexedButtons();
    this._setStandardButtons();
    this._setExpansionButtons();
  }

  private _setStandardButtons() {
    this.standardButtons = this.phoneUI
      ? this._indexedButtons.slice(0, this._getNumberOfConfigurableButtons(this.phoneUI))
      : this._indexedButtons;
  }

  private _isLeftColumnVisible(phoneUI: PhoneModelUI) {
    const isEnhancedLineModeEnabled = this.isEnhancedLineModeOn;
    return phoneUI.configurableLeft || (isEnhancedLineModeEnabled && phoneUI.enhancedLineModeLeft);
  }

  private _isRightColumnVisible(phoneUI: PhoneModelUI) {
    const isEnhancedLineModeEnabled = this.isEnhancedLineModeOn;
    return phoneUI.configurableRight || (isEnhancedLineModeEnabled && phoneUI.enhancedLineModeRight);
  }

  private _getNumberOfConfigurableButtons(model: PhoneModelUI) {
    if (model.numberLeft || model.numberRight) {
      const configurableLeftButtons = this._isLeftColumnVisible(model) ? model.numberLeft : 0;
      const configurableRightButtons = this._isRightColumnVisible(model) ? model.numberRight : 0;
      return model.numberPages * (configurableLeftButtons + configurableRightButtons);
    }
    return model.numberStandard;
  }

  private _setExpansionButtons() {
    if (this.phoneUI) {
      let startingIndex = this._getNumberOfConfigurableButtons(this.phoneUI);
      this.expansionButtons = this.expansionsUI.reduce((modules: ExpansionModule[], expansionUI) => {
        if (expansionUI) {
          const expansionButtonsLength = this._getNumberOfConfigurableButtons(expansionUI);
          const buttons: IndexedPhoneButton[] = this._indexedButtons.slice(
            startingIndex,
            startingIndex + expansionButtonsLength
          );
          startingIndex += expansionButtonsLength;
          modules.push({ expansionUI, buttons });
        }

        return modules;
      }, []);
    } else {
      this.expansionButtons = [];
    }
  }

  private _setPhoneModel() {
    const defaultPhoneUI = {
      numberStandard: this._buttons.length,
      numberPages: 1,
      numberLeft: 0,
      numberRight: 0,
      configurableLeft: false,
      configurableRight: false,
      enhancedLineModeLeft: false,
      enhancedLineModeRight: false,
      background: 'none',
      type: 'grid',
      hasLayout: false,
      largestSupportedKem: '',
    };

    let model = this.phone.model as keyof typeof phoneModels;
    if (model === 'Cisco IP Communicator') {
      model = 'Cisco 7965';
    }
    this.phoneUI = (this.phone ? phoneModels[model] : defaultPhoneUI) || defaultPhoneUI;
    // For CUCM versions < 12, IM Softphones should have a single button layout, but otherwise default to 8 buttons
    if (model === 'Cisco Unified Client Services Framework') {
      this.phoneUI.numberStandard = defaultPhoneUI.numberStandard;
    }
  }

  private _setExpansionModels() {
    const expansionModules = this.phone.expansionModules;
    const areAllExpansionModulesValid =
      expansionModules && expansionModules.every((module: keyof typeof phoneModels) => !!phoneModels[module]);
    if (!areAllExpansionModulesValid) {
      const startingIndex = this._getNumberOfConfigurableButtons(this.phoneUI);
      const defaultPhoneUI = {
        numberStandard: this._buttons.slice(startingIndex).length,
        numberPages: 1,
        numberLeft: 0,
        numberRight: 0,
        configurableLeft: false,
        configurableRight: false,
        enhancedLineModeLeft: false,
        enhancedLineModeRight: false,
        background: 'none',
        type: 'grid',
        hasLayout: false,
        largestSupportedKem: '',
      };
      this.expansionsUI = [defaultPhoneUI];
    } else {
      this.expansionsUI = this.phone.expansionModules.map((module: keyof typeof phoneModels) => phoneModels[module]);
    }
    if (this.expansionsUI.length > 0) {
      this._setExpansionModelsTab();
      // set if the existing template is standard for helpdesk mode
      if (this.userMode === DragDropMode.HELPDESK && this.phoneType !== PhoneType.EXTENSION_MOBILITY) {
        this.phoneButtonTemplateService
          .getTemplate(this.phoneButtonTemplateConfig.ref.id, Number(this.serverId))
          .subscribe((data: PhoneButtonTemplate) => {
            this.isPhoneButtonTemplateStandard = data.standardTemplate;
          });
      }
    }
  }

  private _setIndexedButtons() {
    this._indexedButtons = this._buttons.map((button: PhoneButton, index: number) => ({ button, index }));
  }

  private _createDragGroup(): Group {
    /*
      To prevent default sorting behavior WITHOUT stopping usual events from firing:
      - Use "copy" mode
      - Hide '.gu-transit' containers via styles
      - Call drake.cancel() on drop WITH revert set to true (otherwise infinite recursion)
    */
    return this.dragulaService.createGroup(DRAGULA_GROUP_NAME, {
      copy: true,
      isContainer: (el: Element) => el.classList.contains('smacs-dnd-nav-item'),
      mirrorContainer: this.hostElRef.nativeElement,
      moves: (el: HTMLElement, source: HTMLElement) => {
        const isUnmovable =
          source.classList.contains('phone-buttons-unmovable') || source.classList.contains('deletable-card');
        return !isUnmovable;
      },
    });
  }

  private _getButtonData(el: HTMLElement): DOMStringMap {
    return el.dataset && el.dataset.buttonIdx ? el.dataset : (el.children[0] as HTMLElement).dataset;
  }

  private _handleDragEvents() {
    this._dragEventEmitters.subscribe(($event: DragEvent) => {
      if ($event instanceof DragStartUpdate) {
        this._handleDragStart($event);
      } else if ($event instanceof DragOverUpdate) {
        this._handleDragOver($event);
      } else if ($event instanceof DragDropUpdate) {
        this._handleDragDrop($event);
      } else {
        throw new Error(`Unrecognized drag event type: ${JSON.stringify($event)}`);
      }
    });
  }

  private _handleDragStart({ dragIdx }: DragStartUpdate) {
    const currentButtonState = this.buttonStates[dragIdx];
    const currentButtonType = this._buttons[dragIdx].type;
    this._setCandidateAndDisabledButtons(currentButtonState, <PhoneButtonTypes>currentButtonType);
    this._setDragTarget(currentButtonState);
    this._closePopovers();
  }

  private _handleDragOver({ targetIdx }: DragOverUpdate) {
    this._setDropTarget(targetIdx);
  }

  private _resetButtonStatesExcludingInvalid() {
    const invalidStates = this.buttonStates.map((state) => state.invalid);
    this.resetButtonStates();
    this.buttonStates.forEach((state, index) => (state.invalid = invalidStates[index]));
  }

  private _handleDragDrop({ srcIdx, destIdx }: DragDropUpdate) {
    const isDragAndDropValid =
      srcIdx !== destIdx && !this.buttonStates[srcIdx].disabled && !this.buttonStates[destIdx].disabled;

    if (isDragAndDropValid) {
      this.resetButtonStates();
      this._swapButtons(srcIdx, destIdx);
      this.buttonOrderUpdated();

      const instantDragIdx = srcIdx;
      const instantDropIdx = destIdx;
      this.buttonStates[instantDragIdx].justSwapped = true;
      this.buttonStates[instantDropIdx].justSwapped = true;
      setTimeout(() => {
        this.buttonStates[instantDragIdx].justSwapped = false;
        this.buttonStates[instantDropIdx].justSwapped = false;
      }, 1000);
    } else {
      this._resetButtonStatesExcludingInvalid();
    }
  }

  private _setUnmovableButtons = () => {
    this.buttonStates.forEach((state, idx) => {
      if (this.userMode === DragDropMode.SELF_SERVE) {
        state.unmovable = !SELF_SERVE_MOVABLE_BUTTON_TYPES.includes(<PhoneButtonTypes>this._buttons[idx].type);
      } else if (this.userMode === DragDropMode.HELPDESK) {
        state.unmovable = false;
      }
    });
    this.buttonStates[0].unmovable = this.userMode !== DragDropMode.HELPDESK;
  };

  private _setUneditableButtons = () => {
    this.buttonStates.forEach((state: ButtonState, idx: number) => {
      if (idx === 0 && this.userMode === DragDropMode.SELF_SERVE) {
        this.buttonStates[idx].uneditable = true;
        this.buttonStates[idx].disabled = true;
      }
      if (this._buttons[idx]?.type === PhoneButtonTypes.line) {
        const button = this._buttons[idx] as LineButton;
        if (!button.dn && this.userMode === DragDropMode.SELF_SERVE) {
          this.buttonStates[idx].uneditable = true;
        }
      }
    });
  };

  /**
   * Iterates through the buttons and uses the phone template to determine whether the button is fixed. If so, this
   * method should prevent that button from being modified or moved.
   */
  private _setFixedButtons() {
    if (this.userMode === DragDropMode.HELPDESK) {
      this._indexedButtons.forEach((indexedButton) => {
        if (indexedButton.index < this.templateButtons.length) {
          const isButtonFixed = this.templateButtons[indexedButton.index].fixed;
          if (isButtonFixed) {
            this.buttonStates[indexedButton.index].fixed = true;
            if (indexedButton.index === 0 && this.userMode === DragDropMode.SELF_SERVE) {
              this.buttonStates[indexedButton.index].disabled = true;
              this.buttonStates[indexedButton.index].unmovable = true;
              this.buttonStates[indexedButton.index].uneditable = true;
            }
          }
        }
      });
    }
  }

  private _setCandidateAndDisabledButtons = (currentButtonState: ButtonState, currentButtonType: PhoneButtonTypes) => {
    currentButtonState.dragTarget = true;
    const isDraggableToOtherButtonTypes =
      !currentButtonState.fixed &&
      this.userMode === DragDropMode.HELPDESK &&
      this.isAutomaticPhoneButtonTemplateSelectionEnabled;
    this.buttonStates.forEach((state, idx) => {
      const isButtonMovable = !state.deleteToggled && !state.unmovable;
      const isButtonTypeCompatible =
        currentButtonType === this._buttons[idx]?.type || (!state.fixed && isDraggableToOtherButtonTypes);
      state.candidate = isButtonMovable && isButtonTypeCompatible;
      state.disabled = !state.candidate;
    });
  };

  private _setDragTarget = (currentButtonState: ButtonState) => {
    currentButtonState.dragTarget = true;
  };

  private _setDropTarget(targetIdx: number) {
    const targetButtonState = this.buttonStates[targetIdx];
    this.buttonStates.forEach((states) => {
      states.dropTarget = false;
    });
    targetButtonState.dropTarget = !targetButtonState.disabled;
  }

  public resetButtonStates() {
    this.buttonStates = this._buttons.map(() => new ButtonState());
    this._setUnmovableButtons();
    this._setUneditableButtons();
    this._setFixedButtons();
  }

  private _swapButtons(dragIdx: number, dropIdx: number) {
    this.isButtonSwapped = true;
    this.isPending = true;

    const destButton = this._buttons[dropIdx];
    this._buttons[dropIdx] = this._buttons[dragIdx];
    this._buttons[dragIdx] = destButton;
    this.phone.buttons = this._buttons;

    const destIndexedButton = this._indexedButtons[dropIdx];
    this._indexedButtons[dropIdx] = { ...this._indexedButtons[dragIdx], index: dropIdx };
    this._indexedButtons[dragIdx] = { ...destIndexedButton, index: dragIdx };
    this._setStandardButtons();
    this._setExpansionButtons();
    this._updateValidationState();
  }

  private _updateValidationState() {
    const validationState = this.buttonStates.some((state) => state.invalid)
      ? SmacsFormsValidationState.INVALID
      : SmacsFormsValidationState.VALID;
    if (this._validationState !== validationState) {
      this._validationState = validationState;
      this.validationStateSubject.next(validationState);
    }
  }

  private _createExpansionModuleOnTabClick(module: keyof typeof phoneModels) {
    if (phoneModels[module]) {
      this.expansionsUI.push(phoneModels[module]);
    } else {
      const defaultPhoneUI = {
        numberStandard: this.phoneUI.numberStandard * 2,
        numberPages: 1,
        numberLeft: 0,
        numberRight: 0,
        configurableLeft: false,
        configurableRight: false,
        enhancedLineModeLeft: false,
        enhancedLineModeRight: false,
        background: 'none',
        type: 'grid',
        hasLayout: false,
        largestSupportedKem: '',
      };
      this.expansionsUI.push(defaultPhoneUI);
    }
  }

  private _setExpansionModelsTab() {
    this.phone.expansionModules.forEach((module: keyof typeof phoneModels) => {
      this.expansionModuleTabs.push(module);
      this.selectedModule = module;
    });
  }

  private _updatePhone() {
    const phoneCopy = cloneDeep(this.phone);
    if (this.phoneType !== PhoneType.EXTENSION_MOBILITY) {
      phoneCopy.expansionModules = this.expansionModuleTabs;
    }
    if (this.isPhoneButtonTemplateStandard) {
      phoneCopy.buttons = this._buttons;
    }
    this.context.set(phoneCopy);
    this.phone = cloneDeep(phoneCopy);
  }

  private _getProposedButtons(index: number): Observable<PhoneButton[]> {
    return new Observable<PhoneButton[]>((subscriber) => {
      const templateId = this.phoneButtonTemplateConfig.ref.id;

      this.phoneButtonTemplateService
        .getTemplate(templateId, Number(this.serverId))
        .subscribe((data: PhoneButtonTemplate) => {
          const proposedPhoneCopy = cloneDeep(this.phone);
          this.isPhoneButtonTemplateStandard = data.standardTemplate;
          /**
           * Modify phone buttons if it has a standard template
           */
          if (this.isPhoneButtonTemplateStandard) {
            const countOfConfigurableButtons = this._getNumberOfConfigurableButtons(this.expansionsUI[index]);
            const newButtons = new Array(countOfConfigurableButtons).fill(
              this.phoneButtonsService.newButtonOfType(PhoneButtonTypes.speed_dial)
            );
            if (proposedPhoneCopy.buttons.length <= newButtons.length) {
              proposedPhoneCopy.buttons = proposedPhoneCopy.buttons.concat(newButtons);
            } else {
              const startingIndex = this._getCurrentlyConfiguredButtons(countOfConfigurableButtons, 0);
              for (let i = startingIndex; i < startingIndex + countOfConfigurableButtons; i++) {
                proposedPhoneCopy.buttons[i] = this.phoneButtonsService.newButtonOfType(PhoneButtonTypes.speed_dial);
              }
            }
          }
          subscriber.next(proposedPhoneCopy.buttons);
          subscriber.complete();
        });
    });
  }

  private _setNewPhoneButtons(newButtons: PhoneButton[], moduleIndex: number) {
    const countOfConfigurableButtons = this._getNumberOfConfigurableButtons(this.expansionsUI[moduleIndex]);
    const custom = this.isEnhancedLineModeOn
      ? this.phoneUI.numberStandard + countOfConfigurableButtons
      : this.phoneUI.numberLeft > 0
      ? this.phoneUI.numberLeft + countOfConfigurableButtons
      : this.phoneUI.numberStandard + countOfConfigurableButtons;
    this._buttons =
      this.expansionModuleTabs.length > 1 ? this._buttons.concat(newButtons.slice(0, custom)) : cloneDeep(newButtons);

    this._setIndexedButtons();
    const startingIndex = this._getNumberOfConfigurableButtons(this.phoneUI);
    const kemButtons: IndexedPhoneButton[] = this._indexedButtons.slice(
      startingIndex,
      startingIndex + countOfConfigurableButtons
    );

    this.expansionButtons.push({ expansionUI: this.expansionsUI[moduleIndex], buttons: kemButtons });

    this._resetButtonStatesExcludingInvalid();
  }

  private _getModule() {
    if (this.supportedKems.length === 1) {
      return this.supportedKems[0];
    }
    if (this.phoneType === PhoneType.EXTENSION_MOBILITY && this.supportedKems.length) {
      return this.supportedKems[0];
    }
    return null;
  }

  protected readonly PhoneType = PhoneType;
  protected readonly phoneModels = phoneModels;
}
