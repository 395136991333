<div
  class="mb-3"
  [hidden]="state.hidden && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted,
    'smacs-forms-feedback': showValidation,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING || validationState === ValStates.WARNING || misconfigurationFeedbackMessage,
    row: !alignLeftNoLabel,
    'd-flex': alignLeftNoLabel
  }"
>
  <label
    [hidden]="hideLabel"
    [ngClass]="'col-form-label ' + (alignLeftNoLabel ? '' : state.columnClasses?.label)"
    [attr.data-automation]="config.dataAutomation + '-label'"
  >
    <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
    <span>{{ getLabel() | translate }}</span>
  </label>

  <div
    [class]="state.columnClasses?.input"
    [ngClass]="{
      'd-flex': inline,
      'align-items-center': !isDisplayVertical,
      'flex-column': isDisplayVertical,
      'pt-2': isDisplayVertical
    }"
  >
    <div *ngFor="let button of buttons">
      <div
        [hidden]="button.hidden"
        class="form-check"
        [ngClass]="{ 'form-check-inline mb-0': inline }"
        [attr.data-automation]="'smacs-radio-option-' + button.value"
      >
        <input
          class="form-check-input"
          type="radio"
          [attr.id]="config.dataAutomation + '-' + button.value + '-' + uniqueRadioId"
          #ngModelDir="ngModel"
          (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
          [(ngModel)]="fieldData"
          [name]="config.dataAutomation + '-' + uniqueRadioId"
          [value]="button.value"
          [attr.data-automation]="'smacs-radio-option-input-' + button.value"
          [disabled]="isButtonDisabled(button)"
        />

        <label
          class="form-check-label"
          [attr.for]="config.dataAutomation + '-' + button.value + '-' + uniqueRadioId"
          [attr.data-automation]="'smacs-radio-option-label-' + button.value"
          [innerHtml]="button.label | translate : { param: button.labelParam }"
          [ngbTooltip]="
            getButtonDisabledTooltip(button)?.content | translate : getButtonDisabledTooltip(button)?.params
          "
          [disableTooltip]="!isButtonDisabled(button)"
        ></label>
      </div>
      <span
        *ngIf="button.helpText && !button.hidden"
        class="small form-text text-muted mt-0 mb-3 d-inline-block"
        [innerHtml]="button.helpText | translate"
        [attr.data-automation]="'smacs-radio-option-helptext'"
      ></span>
    </div>
    <div class="smacs-forms-feedback-area">
      <div
        class="smacs-forms-validation-message"
        data-automation="smacs-forms-validation-message"
        [hidden]="!showValidation && !isFormSubmitted"
      >
        {{ validationMessage?.content | translate : validationMessage?.params }}
      </div>

      <div
        class="smacs-forms-validation-message text-warning"
        [ngClass]="{ 'd-inline': config.autogeneration?.inline }"
        data-automation="smacs-forms-warning-message"
        [hidden]="!misconfigurationFeedbackMessage"
      >
        {{ misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params }}
      </div>
    </div>
  </div>
</div>
