import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DatatableFilterAbstractDirective } from '../datatable-filter-abstract.directive';
import { ButtonStyles } from '../../../../button/button.component';
import moment from 'moment';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'smacs-datatable-date-filter',
  templateUrl: './datatable-date-filter.component.html',
  styleUrls: ['./datatable-date-filter.component.scss', '../datatable-filter-abstract.directive.scss'],
  providers: [{ provide: DatatableFilterAbstractDirective, useExisting: DatatableDateFilterComponent }],
})
export class DatatableDateFilterComponent extends DatatableFilterAbstractDirective<Date[]> implements OnInit {
  @ViewChild('dropdownPanel') private dropdownPanel: NgbDropdown;

  labelDynamic = '';
  ButtonStyles = ButtonStyles;
  model: Date[];

  constructor(private _changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    const sessionFilterParams = sessionStorage.getItem('filterParams');

    if (!!sessionFilterParams) {
      const allFilterParams = JSON.parse(sessionFilterParams);
      if (!!allFilterParams[this.tableId]) {
        const thisFilterParam = Object.entries(allFilterParams[this.tableId]).find(
          (param) => param[0] === this.dataAutomation
        );
        if (thisFilterParam) {
          const typedParams = (thisFilterParam[1] as string[]).filter((param: string) => {
            if (param) {
              return param;
            }
          });
          if (typedParams.length) {
            this.value = [new Date(typedParams[0]), new Date(typedParams[1])];
            this.setValue();
          }
        }
      }
    }
  }

  clear = () => this._clearValues();

  setValue() {
    this.labelDynamic = `${moment(this.value[0]).format('MMM D YYYY')} - ${moment(this.value[1]).format('MMM D YYYY')}`;
    this.filterUpdateSource.next();
    this._changeDetectorRef.detectChanges();
  }

  onSelect() {
    if (this.isRangeSelected()) {
      this.setValue();
      this.closeOverlay();
    }
  }

  closeOverlay() {
    this.dropdownPanel.close();
  }

  isRangeSelected(): boolean {
    return !!(this.value?.length === 2 && this.value[0] && this.value[1]);
  }

  private _clearValues() {
    this.value = [];
    this.labelDynamic = '';
    this.filterUpdateSource.next();
  }
}
