<a
  [routerLink]="isLoading ? null : tile.reportUrl"
  [queryParams]="tile.queryParams"
  [attr.data-automation]="tile.dataAutomation"
  class="tile btn dashboard-tile"
  [ngClass]="{
    disabled: isLoading,
    'btn-outline-default': !tile.status,
    'btn-outline-success': tile.status === TileStatus.GOOD,
    'btn-outline-warning': tile.status === TileStatus.WARNING,
    'btn-outline-info': tile.status === TileStatus.NEUTRAL,
    'btn-outline-danger': tile.status === TileStatus.CRITICAL
  }"
>
  <div class="text-center">
    <div *ngIf="isLoading" class="dashboard-tile__spinner-container">
      <i class="{{ smacsIcons.SAVING }} icon-3x"></i>
    </div>
    <h1 class="dashboard-tile__title" *ngIf="!isLoading">{{ tile.value }}</h1>

    <span class="dashboard-tile__message">
      <p>
        {{ tile.message | translate : { count: tile.value } }}
      </p>
    </span>
  </div>
</a>
