import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { TranslateService } from '@ngx-translate/core';
import { CiscoTile, CiscoTileState } from '../tiles.model';

@Component({
  selector: 'smacs-user-detail-home-service-tile',
  templateUrl: './user-detail-home-service-tile.component.html',
})
export class SmacsUserDetailHomeServiceTileComponent {
  @Input() tileStates: CiscoTileState[];

  @Output() addTileClicked = new EventEmitter();
  @Output() editTileClicked = new EventEmitter();
  @Output() editTileDeleteClicked = new EventEmitter();
  @Output() deleteTileConfirmButtonClicked = new EventEmitter();
  @Output() deleteTileCancelButtonClicked = new EventEmitter();
  @Output() copyDeskphoneButtonClicked = new EventEmitter<{ tileState: CiscoTileState; index: number }>();

  smacsIcons = SmacsIcons;
  tiles = CiscoTile;

  constructor(private translateService: TranslateService) {}

  onAddTileClicked(tileState: CiscoTileState, index: number) {
    this.addTileClicked.emit({ tileState, index });
  }

  onEditTileClicked(tileState: CiscoTileState) {
    this.editTileClicked.emit({ tileState });
  }

  onEditTileDeleteClicked(tileState: CiscoTileState, index: number) {
    this.editTileDeleteClicked.emit({ tileState, index });
  }

  onDeleteTileConfirmButtonClicked(tileState: CiscoTileState, index: number) {
    this.deleteTileConfirmButtonClicked.emit({ tileState, index });
  }

  onDeleteTileCancelButtonClicked(tileState: CiscoTileState, index: number) {
    this.deleteTileCancelButtonClicked.emit({ tileState, index });
  }

  getMultiTileDescription(tileState: CiscoTileState, count: number): string {
    return `${this.translateService.instant(this.getMultiTileDescriptionKey(tileState))} (${count})`;
  }

  getMultiTileDescriptionKey(tileState: CiscoTileState): string {
    switch (tileState.serviceType) {
      case CiscoTile.TABLET: {
        return 'tkey;shared.model.tablet.text';
      }
      case CiscoTile.IM_SOFTPHONE: {
        return 'tkey;shared.model.imsoftphone.text';
      }
      case CiscoTile.CIPC: {
        return 'tkey;shared.model.cipc.text';
      }
      case CiscoTile.ANDROID: {
        return 'tkey;shared.model.android.text';
      }
      case CiscoTile.IPHONE: {
        return 'tkey;shared.model.iphone.text';
      }
      case CiscoTile.DESKPHONE: {
        return 'tkey;shared.model.deskphone.text';
      }
      case CiscoTile.CTI_PORT: {
        return 'tkey;shared.model.ctiport.text';
      }
      case CiscoTile.CTI_REMOTE_DEVICE: {
        return 'tkey;shared.model.ctiremotedevice.text';
      }
      case CiscoTile.SPARK_REMOTE_DEVICE: {
        return 'tkey;shared.model.sparkremotedevice.text';
      }
      default: {
        break;
      }
    }
  }

  onCopyDeskphoneClicked(tileState: CiscoTileState, index: number) {
    if (tileState.serviceType === CiscoTile.DESKPHONE) {
      this.copyDeskphoneButtonClicked.emit({ tileState, index });
    }
  }
}
