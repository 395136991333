import { Component, OnDestroy, OnInit } from '@angular/core';
import { BottomNavClearButtonsList, BottomNavService } from '../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-admin-zero-touch-page',
  templateUrl: './zero-touch-page.component.html',
  styleUrls: ['../../../admin/admin-page.scss', './zero-touch-page.component.scss'],
})
export class ZeroTouchPageComponent implements OnInit, OnDestroy {
  isLoading = false;

  constructor(
    private router: Router,
    private bottomNavService: BottomNavService,
    private breadcrumbsService: BreadcrumbsService
  ) {}

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.bottomNavService.dispatch(new BottomNavClearButtonsList());
      }
    });
  }

  ngOnDestroy() {
    this.bottomNavService.dispatch(new BottomNavClearButtonsList());
    this.breadcrumbsService.clearBreadcrumbs();
  }
}
