import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { forkJoin, Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../forms/fields/select/smacs-select.component';
import { SmacsFormConfig } from '../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import {
  Microsoft365UserResult,
  MicrosoftDialPlanGroup,
  MicrosoftSecurityGroupResult,
  Type,
  UserGroupPermissions,
} from '../../../shared/models/generated/smacsModels';
import { Microsoft365UserResource } from '../../../helpdesk/shared/resources/microsoft-365-user.resource';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { UserPermissionsModalComponent } from './user-permissions-modal.component';
import { MicrosoftSecurityGroupsSearchResource } from '../../resources/microsoft-security-groups-search.resource';
import { MsDialPlanGroupsContext } from '../../contexts/ms-dial-plan-groups.context';

interface UserPermissionsSearchFormEntity {
  role: {
    label: string;
    value: PermissionSearchResult;
  };
}

export interface PermissionSearchResult {
  displayName: string;
  id: string;
  url: string;
  type: Type;
}

@Component({
  selector: 'ziro-user-permissions-search-form',
  templateUrl: './user-permissions-search-form.component.html',
  styleUrls: ['./user-permissions-search-form.component.scss'],
  providers: [Microsoft365UserResource, MicrosoftSecurityGroupsSearchResource],
})
export class UserPermissionsSearchFormComponent
  extends SmacsFormAbstractDirective<UserPermissionsSearchFormEntity>
  implements OnInit, OnDestroy
{
  private _minSearchLength = 3;

  @Input() existingUserPermissions: UserGroupPermissions[];

  smacsIcons = SmacsIcons;
  formConfig = {
    fields: {
      role: {
        dataAutomation: 'user-permissions-form-search',
        componentConfig: new SmacsSelectConfig({
          isMultiSelect: false,
          asyncOptionsFn: this.searchUsers.bind(this),
          placeholder: 'tkey;admin.system.user_permissions.form.input.placeholder',
          minSearchLength: this._minSearchLength,
        }),
      },
    },
    options: {
      columnClasses: {
        label: '',
        input: 'col-12',
      },
    },
  } as SmacsFormConfig;
  buttonTooltip = 'tkey;admin.system.user_permissions.form.add_user.tooltip_search';
  tooltipEnabled = true; // hack to stop tooltip from appearing on disabled button after modal is closed

  private _dialPlanGroups: MicrosoftDialPlanGroup[] = [];
  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _microsoft365UserResource: Microsoft365UserResource,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _microsoftSecurityGroupsSearchResource: MicrosoftSecurityGroupsSearchResource,
    private _msDialPlanGroupsContext: MsDialPlanGroupsContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    const subscription = this.smacsFormsUpdate$.subscribe((data) => {
      if (!data.new.role) {
        this.buttonTooltip = 'tkey;admin.system.user_permissions.form.add_user.tooltip_search';
      } else {
        const isExisting = this.existingUserPermissions.some((up: UserGroupPermissions) => {
          if (
            (up.type === Type.USER && up.name === this.entity.role.value.id) ||
            (up.type === Type.GROUP && up.name === this.entity.role.value.displayName)
          ) {
            return true;
          }
        });

        if (isExisting) {
          this.buttonTooltip = 'tkey;admin.system.user_permissions.form.add_user.tooltip_exists';
        } else {
          this.buttonTooltip = '';
        }
      }
    });
    this._subscriptions.add(subscription);

    const dialPlanSubscription = this._msDialPlanGroupsContext.state$.subscribe((state: MicrosoftDialPlanGroup[]) => {
      this._dialPlanGroups = state;
    });
    this._subscriptions.add(dialPlanSubscription);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  searchUsers(searchTerm: string): Observable<SmacsSelectOption[]> {
    if (!searchTerm || searchTerm.length < this._minSearchLength) {
      return of(null);
    }

    return forkJoin([
      this._microsoftSecurityGroupsSearchResource.search(searchTerm, false),
      this._microsoft365UserResource.search(searchTerm),
    ]).pipe(
      switchMap(([groupResults, userResults]) => {
        const mappedGroupData: PermissionSearchResult[] = groupResults.map((group: MicrosoftSecurityGroupResult) => {
          return {
            ...group.microsoftSecurityGroupRefJson,
            type: Type.GROUP,
          };
        });
        const mappedUserData: PermissionSearchResult[] = userResults.map((user: Microsoft365UserResult) => {
          return {
            ...user.microsoft365UserRef,
            type: Type.USER,
          };
        });
        const selectOptions = mappedGroupData
          .concat(mappedUserData)
          .sort((a: PermissionSearchResult, b: PermissionSearchResult) => a.displayName.localeCompare(b.displayName))
          .map((result: PermissionSearchResult) => {
            return {
              label: `<i class="${
                result.type === Type.USER ? SmacsIcons.USER : SmacsIcons.GROUP
              } me-1"></i><strong class="text-capitalize">${result.type.toLowerCase()}</strong> - ${
                result.displayName
              }`,
              value: result,
            };
          });

        return of(selectOptions);
      })
    );
  }

  protected submit() {
    if (this.buttonTooltip) {
      return of(null);
    }

    const ref = this.entity.role.value;
    const title =
      ref.type === Type.GROUP
        ? this._translateService.instant('tkey;admin.system.user_permissions.add_permissions_group.label', {
            upn: ref.displayName,
          })
        : this._translateService.instant('tkey;admin.system.user_permissions.add_permissions_user.label', {
            upn: ref.id,
          });
    const options = {
      size: 'lg',
      bodyClass: UserPermissionsModalComponent,
      modalViewProperties: {
        title: title,
        userPermission: {
          ...ref,
          name: ref.type === Type.USER ? ref.id : ref.displayName,
          permittedDialPlanGroups: [] as string[],
        },
        dialPlanGroups: this._dialPlanGroups,
        modalBodyClass: 'overflow-visible',
      },
    };
    this._smacsModalService
      .openDetailedModal(() => options.modalViewProperties, options)
      .subscribe({
        next: () => null,
        error: (error) => {
          throwError(() => error);
        },
        complete: () => {
          this.tooltipEnabled = false;
          setTimeout(() => {
            this.tooltipEnabled = true;
          });
        },
      });

    this.entitySource.next({ role: null });
    return of(null);
  }
}
