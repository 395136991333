import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { UcMetadataCache } from '../models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UcMetadataCacheContext {
  private _stateSource = new ReplaySubject<UcMetadataCache>(1);
  state$ = this._stateSource.asObservable();

  constructor(private http: HttpClient) {
    this._init();
  }

  reloadUcMetadataCache() {
    return new Observable((subscriber: Subscriber<void>) => {
      this._post().subscribe(() => {
        this._init();
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private _init() {
    this._get().subscribe((data: UcMetadataCache) => {
      this._stateSource.next(data);
    });
  }

  private _get(): Observable<UcMetadataCache> {
    return this.http.get<UcMetadataCache>('/services/cisco/macs/servers/cache');
  }

  private _post(): Observable<void> {
    return this.http.post<void>('/services/cisco/macs/servers/cache', null);
  }
}
