import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchAbstractResource } from './search.abstract.resource';
import { Observable } from 'rxjs';
import { ExtensionMobilityResult } from '../../../shared/models/generated/smacsModels';
import { AuditHeaderService } from '../../../shared/services/audit-header.service';
import { Nvp } from '../../../shared/models/nvp';

export class SearchExtensionMobilityProfilesQueryParams {
  q?: string;
  extension?: string;
  description?: string;
  'cucm-server-id'?: number;
  limit?: number;
  name?: string;
}

@Injectable()
export class SearchExtensionMobilityProfilesResource extends SearchAbstractResource {
  constructor(protected http: HttpClient, private auditHeaderService: AuditHeaderService) {
    super(http);
  }

  getExtensionMobilityProfiles(
    searchParams: SearchExtensionMobilityProfilesQueryParams
  ): Observable<ExtensionMobilityResult[]> {
    return this.search<ExtensionMobilityResult[]>(
      '/services/cisco/macs/extension-mobility-profiles/search',
      searchParams
    );
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }

  deleteExtensionMobilityProfile(
    extensionMobilityProfileId: string,
    serverId: number,
    auditTags?: Nvp[]
  ): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.delete<void>(
      `/services/cisco/macs/cucm-servers/${serverId}/extension-mobility-profiles/${extensionMobilityProfileId}`,
      httpOptions
    );
  }
}
