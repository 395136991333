<div class="expression-editor" data-automation="call-logs-query-editor">
  <p class="expression-editor__expression">
    {{ 'tkey;reporting.call_logs.editor.calls' | translate }}
    <ziro-call-logs-query-editor-keyword
      [keyword]="callPlacedOrReceived"
      (keywordWasUpdated)="handleKeywordUpdate()"
    ></ziro-call-logs-query-editor-keyword>
    {{ 'tkey;reporting.call_logs.editor.from' | translate }}
    <ziro-call-logs-query-editor-keyword
      [keyword]="extensionUsernameOrDeviceName"
      (keywordWasUpdated)="handleKeywordUpdate()"
    ></ziro-call-logs-query-editor-keyword>
    {{ 'tkey;reporting.call_logs.editor.dates.between' | translate }}
    <ziro-call-logs-query-editor-keyword
      [keyword]="fromDate"
      (keywordWasUpdated)="handleKeywordUpdate()"
    ></ziro-call-logs-query-editor-keyword>
    {{ 'tkey;reporting.call_logs.editor.dates.to' | translate }}
    <ziro-call-logs-query-editor-keyword
      [keyword]="toDate"
      (keywordWasUpdated)="handleKeywordUpdate()"
    ></ziro-call-logs-query-editor-keyword>
  </p>
  <div class="expression-editor__feedback-message mb-2">
    <p *ngIf="!doesExtensionUsernameOrDeviceHaveAValue()" class="text-danger mb-0">
      <ng-container *ngIf="extensionUsernameOrDeviceName?.keywordValue?.selection">
        {{
          feedbackText['extensionUsernameOrDeviceName']
            | translate : { device: extensionUsernameOrDeviceName.keywordValue.selection }
        }}
      </ng-container>
      <ng-container *ngIf="!extensionUsernameOrDeviceName?.keywordValue?.selection">
        {{ feedbackText['extensionUsernameOrDeviceName'] | translate : { device: extensionUsernameOrDeviceName.type } }}
      </ng-container>
    </p>
    <p *ngIf="doesExtensionUsernameOrDeviceHaveAValue() && areDatesInvalid()" class="text-danger mb-0">
      {{ feedbackText['fromDate'] | translate }}
    </p>
  </div>
</div>
