import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CheckboxButtonComponent } from './checkbox-button.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, TranslateModule, NgbModule, FormsModule],
  declarations: [CheckboxButtonComponent],
  exports: [CheckboxButtonComponent],
})
export class CheckboxButtonModule {}
