<div *ngIf="!isLoading" class="row">
  <!-- Security Group Memberships Tile -->
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="tileStates.msSecurityGroupMembership.isDisplayed">
    <smacs-service-tile
      [attr.data-automation]="tileStates.msSecurityGroupMembership.dataAutomation"
      [dataAutomation]="tileStates.msSecurityGroupMembership.dataAutomation"
      [serviceType]="tileStates.msSecurityGroupMembership.serviceType"
      [tileType]="tileStates.msSecurityGroupMembership.tileType"
      [serviceName]="tileStates.msSecurityGroupMembership.serviceName"
      [icon]="tileStates.msSecurityGroupMembership.icon"
      [description]="tileStates.msSecurityGroupMembership.description"
      [isInProgress]="false"
      [isDeleting]="tileStates.msSecurityGroupMembership.isDeleting"
      [isEnabled]="tileStates.msSecurityGroupMembership.isEnabled"
      [tooltip]="tileStates.msSecurityGroupMembership.tooltip"
      [isDeleteShown]="tileConfig.groups === TileStatus.SHOW_WITH_DELETE"
      [isDeleteEnabled]="true"
      (addTileClicked)="onAddTileClicked(tileStates.msSecurityGroupMembership)"
      (editTileClicked)="onEditTileClicked(tileStates.msSecurityGroupMembership)"
      (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.msSecurityGroupMembership)"
      (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.msSecurityGroupMembership)"
      (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.msSecurityGroupMembership)"
    ></smacs-service-tile>
  </div>

  <!--MS Licensing Tile -->
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="tileStates.ms365.isDisplayed">
    <smacs-service-tile
      [attr.data-automation]="tileStates.ms365.dataAutomation"
      [dataAutomation]="tileStates.ms365.dataAutomation"
      [serviceType]="tileStates.ms365.serviceType"
      [tileType]="tileStates.ms365.tileType"
      [serviceName]="tileStates.ms365.serviceName"
      [icon]="tileStates.ms365.icon"
      [description]="tileStates.ms365.description"
      [isAdding]="tileStates.ms365.isAdding"
      [isDeleting]="tileStates.ms365.isDeleting"
      [isEnabled]="tileStates.ms365.isEnabled"
      [isInProgress]="is365LicensesSyncInProgress"
      [isInProgressBadgeText]="tile365LicensesSyncBadgeText"
      [deleteTooltip]="getDeleteMS365LicenseTileTooltip()"
      [isDeleteShown]="tileConfig.license === TileStatus.SHOW_WITH_DELETE"
      [isDeleteEnabled]="enableDeleteMS365LicenseTile()"
      [tooltip]="tileStates.ms365.tooltip"
      (addTileClicked)="onAddTileClicked(tileStates.ms365)"
      (editTileClicked)="onEditTileClicked(tileStates.ms365)"
      (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.ms365)"
      (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.ms365)"
      (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.ms365)"
    ></smacs-service-tile>
  </div>

  <!--MS Teams Calling Tile -->
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="tileStates.msTeamsCalling.isDisplayed">
    <smacs-service-tile
      [attr.data-automation]="tileStates.msTeamsCalling.dataAutomation"
      [dataAutomation]="tileStates.msTeamsCalling.dataAutomation"
      [serviceType]="tileStates.msTeamsCalling.serviceType"
      [tileType]="tileStates.msTeamsCalling.tileType"
      [serviceName]="tileStates.msTeamsCalling.serviceName"
      [icon]="tileStates.msTeamsCalling.icon"
      [description]="tileStates.msTeamsCalling.description"
      [isInProgress]="isTeamsLicensingInProgress"
      [isDeleting]="tileStates.msTeamsCalling.isDeleting"
      [isEnabled]="tileStates.msTeamsCalling.isEnabled"
      [isDeleteShown]="tileConfig.calling === TileStatus.SHOW_WITH_DELETE"
      [tooltip]="tileStates.msTeamsCalling.tooltip"
      (addTileClicked)="onAddTileClicked(tileStates.msTeamsCalling)"
      (editTileClicked)="onEditTileClicked(tileStates.msTeamsCalling)"
      (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.msTeamsCalling)"
      (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.msTeamsCalling)"
      (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.msTeamsCalling)"
    ></smacs-service-tile>
  </div>

  <!--MS Audio Conferencing Tile -->
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="tileStates.msTeamsAudioConferencing.isDisplayed">
    <smacs-service-tile
      [attr.data-automation]="tileStates.msTeamsAudioConferencing.dataAutomation"
      [dataAutomation]="tileStates.msTeamsAudioConferencing.dataAutomation"
      [serviceType]="tileStates.msTeamsAudioConferencing.serviceType"
      [tileType]="tileStates.msTeamsAudioConferencing.tileType"
      [serviceName]="tileStates.msTeamsAudioConferencing.serviceName"
      [icon]="tileStates.msTeamsAudioConferencing.icon"
      [description]="tileStates.msTeamsAudioConferencing.description"
      [isInProgress]="isAudioLicensingInProgress"
      [isDeleting]="tileStates.msTeamsAudioConferencing.isDeleting"
      [isEnabled]="tileStates.msTeamsAudioConferencing.isEnabled"
      [isDeleteShown]="tileConfig.meeting === TileStatus.SHOW_WITH_DELETE"
      [tooltip]="tileStates.msTeamsAudioConferencing.tooltip"
      [isDeleteEnabled]="false"
      [deleteTooltip]="'tkey;shared.model.ms_teams_audio_conferencing.tile.delete.tooltip'"
      (addTileClicked)="onAddTileClicked(tileStates.msTeamsAudioConferencing)"
      (editTileClicked)="onEditTileClicked(tileStates.msTeamsAudioConferencing)"
      (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.msTeamsAudioConferencing)"
      (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.msTeamsAudioConferencing)"
      (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.msTeamsAudioConferencing)"
    ></smacs-service-tile>
  </div>

  <!--Dubber Tile -->
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngIf="tileStates.dubber.isDisplayed">
    <smacs-service-tile
      [attr.data-automation]="tileStates.dubber.dataAutomation"
      [dataAutomation]="tileStates.dubber.dataAutomation"
      [serviceType]="tileStates.dubber.serviceType"
      [tileType]="tileStates.dubber.tileType"
      [serviceName]="tileStates.dubber.serviceName"
      [icon]="tileStates.dubber.icon"
      [description]="tileStates.dubber.description"
      [isDeleting]="tileStates.dubber.isDeleting"
      [isEnabled]="tileStates.dubber.isEnabled"
      [isDeleteShown]="tileConfig.dubber === TileStatus.SHOW_WITH_DELETE"
      [isDeleteEnabled]="true"
      (addTileClicked)="onAddTileClicked(tileStates.dubber)"
      (editTileClicked)="onEditTileClicked(tileStates.dubber)"
      (editTileDeleteClicked)="onEditTileDeleteClicked(tileStates.dubber)"
      (deleteTileConfirmButtonClicked)="onDeleteTileConfirmButtonClicked(tileStates.dubber)"
      (deleteTileCancelButtonClicked)="onDeleteTileCancelButtonClicked(tileStates.dubber)"
    ></smacs-service-tile>
  </div>

  <ng-container *ngIf="devices.length">
    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let device of devices; let i = index">
      <smacs-service-tile
        [attr.data-automation]="'device-tile-' + i"
        [tileType]="ServiceTileType.EDIT"
        [isDevice]="true"
        [serviceName]="device.manufacturer[0].toUpperCase() + device.manufacturer.slice(1)"
        [description]="device.model.toUpperCase()"
        [icon]="
          device.deviceType === 'ipPhone' || device.deviceType === 'lowCostPhone'
            ? SmacsIcons.PHONE_DIAL
            : SmacsIcons.MONITOR
        "
        [isDeleteEnabled]="false"
        [isDeleteShown]="false"
        [deviceToolip]="device.tooltip"
        [deviceIconMarkup]="device.iconMarkup"
      ></smacs-service-tile>
    </div>
  </ng-container>
</div>
