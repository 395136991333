<nav *ngIf="!short" [attr.data-automation]="dataAutomation">
  <ul class="pagination justify-content-center">
    <li>
      <b>
        (
        <span data-automation="paginaton-count-start">{{ getFirstElementOfPage() }}</span>
        -
        <span data-automation="paginaton-count-end">{{ getLastElementOfPage() }}</span>
        /
        <span data-automation="pagination-total-results">{{ totalResults }}</span>
        )
      </b>
    </li>
  </ul>

  <ul class="pagination justify-content-center">
    <li class="pagination__page-item">
      <smacs-button
        [buttonStyle]="buttonStyles.DEFAULT"
        [icon]="smacsIcons.PREVIOUS"
        (clicked)="decrementPage()"
        [isDisabled]="disabled || isOnFirstPage() || isPageLoading()"
        [dataAutomation]="'pagination-page-decrement'"
      ></smacs-button>
    </li>
    <li class="pagination__page-item">
      <smacs-button
        [buttonStyle]="buttonStyles.DEFAULT"
        [icon]="smacsIcons.NEXT"
        (clicked)="incrementPage()"
        [isDisabled]="disabled || isOnLastPage() || isPageLoading()"
        [dataAutomation]="'pagination-page-increment'"
      ></smacs-button>
    </li>
  </ul>
</nav>

<nav *ngIf="short" [attr.data-automation]="dataAutomation">
  <ul class="pagination justify-content-center align-items-center">
    <li>
      <smacs-button
        [buttonStyle]="buttonStyles.DEFAULT"
        [icon]="smacsIcons.PREVIOUS"
        (clicked)="decrementPage()"
        [isDisabled]="disabled || isOnFirstPage() || isPageLoading()"
        [dataAutomation]="'pagination-page-decrement'"
      ></smacs-button>
    </li>
    <li class="mx-2">
      <b>
        (
        <span data-automation="paginaton-count-start">{{ getFirstElementOfPage() }}</span>
        -
        <span data-automation="paginaton-count-end">{{ getLastElementOfPage() }}</span>
        /
        <span data-automation="pagination-total-results">{{ totalResults }}</span>
        )
      </b>
    </li>
    <li>
      <smacs-button
        [buttonStyle]="buttonStyles.DEFAULT"
        [icon]="smacsIcons.NEXT"
        (clicked)="incrementPage()"
        [isDisabled]="disabled || isOnLastPage() || isPageLoading()"
        [dataAutomation]="'pagination-page-increment'"
      ></smacs-button>
    </li>
  </ul>
</nav>
