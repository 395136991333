import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import {
  Microsoft360View,
  Microsoft365UserLicenses,
  MicrosoftTeamsAudioConferencing,
  SelectFieldConfig,
} from '../../../shared/models/generated/smacsModels';
import { Microsoft360ViewContext } from '../../../shared/contexts/microsoft-360-view.context';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { Microsoft365LicensesViewResource } from '../../../shared/resources/microsoft-365-licenses-view.resource';
import { MicrosoftTeamsAudioConferencingFieldConfigResource } from '../../shared/resources/microsoft-teams-audio-conferencing-field-config.resource';

@Component({
  selector: 'smacs-teams-audio-conferencing',
  templateUrl: './teams-audio-conferencing.component.html',
})
export class TeamsAudioConferencingComponent implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;
  isLoading = true;
  pageDisabled = true;
  ms365UserLicenses: Microsoft365UserLicenses;
  fieldConfig: SelectFieldConfig<string>;
  upn = '';
  msTeamsAudioConferencing: MicrosoftTeamsAudioConferencing;

  private _subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private microsoft360ViewContext: Microsoft360ViewContext,
    private microsoftTeamsAudioConferencingFieldConfigResource: MicrosoftTeamsAudioConferencingFieldConfigResource,
    private microsoft365LicensesViewResource: Microsoft365LicensesViewResource
  ) {}

  ngOnInit() {
    this._initContexts();
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  private _initContexts() {
    this.upn = this.route.snapshot.paramMap.get('upn');

    const ms360ViewSub = this.microsoft360ViewContext.state$.subscribe((ms360View: Microsoft360View) => {
      if (ms360View.microsoft365UserLicenses) {
        this.upn = ms360View.microsoft365UserLicenses.userPrincipalName;
        this.pageDisabled = false;
        this.ms365UserLicenses = ms360View.microsoft365UserLicenses;
        this.msTeamsAudioConferencing = ms360View.teamsAudioConferencing;

        if (this.msTeamsAudioConferencing) {
          forkJoin([
            this.microsoftTeamsAudioConferencingFieldConfigResource.get(),
            this.microsoft365LicensesViewResource.get(),
          ]).subscribe(([audioConferencingFieldConfig]) => {
            this.fieldConfig = audioConferencingFieldConfig.audioConferencingServiceNumbers;
            this.isLoading = false;
          });
        } else {
          window.location.href = `${window.location.origin}/app2/#/360-view/microsoft/${this.upn}`;
        }
      }
    });
    this._subscriptions.add(ms360ViewSub);
  }
}
