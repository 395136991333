<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div [hidden]="isLoading">
  <smacs-pin-reset
    #vmPin
    [hidden]="!isVmPresent"
    [fieldId]="'voicemailPinReset'"
    (resetClicked)="onSubmit($event)"
  ></smacs-pin-reset>
  <smacs-pin-reset
    #emPin
    [hidden]="!isEmPresent"
    [fieldId]="'extensionMobPinReset'"
    (resetClicked)="onSubmit($event)"
  ></smacs-pin-reset>
</div>
