import { createAction, props } from '@ngrx/store';
import { OptimizationTile, OrphanedTile, UnusedTile } from '../models/report-dashboard.model';

const LOAD_ORPHANED_TILE = '[Dashboard] Load Orphaned Tile';
const LOAD_ORPHANED_TILE_SUCCESS = '[Dashboard] Load Orphaned Tile Success';

const LOAD_UNUSED_TILE = '[Dashboard] Load Unused Tile';
const LOAD_UNUSED_TILE_SUCCESS = '[Dashboard] Load Unused Tile Success';

const LOAD_OPTIMIZATION_TILE = '[Dashboard] Load Optimization Tile';
const LOAD_OPTIMIZATION_TILE_SUCCESS = '[Dashboard] Load Optimization Tile Success';

const loadOrphanedTileAction = createAction(LOAD_ORPHANED_TILE, props<OrphanedTile>());
const loadOrphanedTileSuccessAction = createAction(LOAD_ORPHANED_TILE_SUCCESS, props<OrphanedTile>());

const loadUnusedTileAction = createAction(LOAD_UNUSED_TILE, props<UnusedTile>());
const loadUnusedTileSuccessAction = createAction(LOAD_UNUSED_TILE_SUCCESS, props<UnusedTile>());

const loadOptimizationTileAction = createAction(LOAD_OPTIMIZATION_TILE, props<OptimizationTile>());
const loadOptimizationTileSuccessAction = createAction(LOAD_OPTIMIZATION_TILE_SUCCESS, props<OptimizationTile>());

export const dashboardActions = {
  loadOrphanedTileAction: loadOrphanedTileAction,
  loadOrphanedTileSuccessAction: loadOrphanedTileSuccessAction,
  loadUnusedTileAction: loadUnusedTileAction,
  loadUnusedTileSuccessAction: loadUnusedTileSuccessAction,
  loadOptimizationTileAction: loadOptimizationTileAction,
  loadOptimizationTileSuccessAction: loadOptimizationTileSuccessAction,
};
