import { Component, OnInit } from '@angular/core';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../shared/entity-table/entity-table.models';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { EmailTemplatePreviewFieldConfig } from '../../../shared/models/generated/smacsModels';
import { EmailService } from '../../shared/services/email.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { EmailTemplatePreviewsFieldConfigResource } from '../../shared/resources/field-config/email-template-previews-field-config.resource';

@Component({
  selector: 'smacs-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent implements OnInit {
  SmacsIcons = SmacsIcons;
  ButtonStyles = ButtonStyles;
  ButtonSizes = ButtonSizes;

  entityTable: EntityTable = {
    hasActions: true,
    columns: [
      {
        columnId: 'emailTemplateName',
        label: 'tkey;helpdesk.send_email.table.email_template_name',
        cssColumnSize: 'col-6',
        filter: { type: EntityTableFilterTypes.TEXT },
      },
      {
        columnId: 'recipients',
        label: 'tkey;helpdesk.send_email.table.recipients',
        cssColumnSize: 'col-5',
        filter: {
          type: EntityTableFilterTypes.TEXT,
          filterFn: (rows, filterValue) => {
            return rows.filter((row) => {
              return row.content.recipients.some((recipient: string) =>
                recipient.toLowerCase().includes(filterValue.toLowerCase())
              );
            });
          },
        },
      },
    ],
  };

  tableRows: EntityTableContentRow[];
  enduserUsername: string;
  cucmServerId: number;
  isLoading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private emailService: EmailService,
    private emailTemplatePreviewsFieldConfigResource: EmailTemplatePreviewsFieldConfigResource,
    private currentClusterContext: CurrentClusterContext
  ) {}

  ngOnInit() {
    this.enduserUsername = this.route.snapshot.params.username;

    this.currentClusterContext.state$.subscribe((currentCluster) => {
      this.cucmServerId = currentCluster.cucmServerId;
      this.emailTemplatePreviewsFieldConfigResource.get(this.enduserUsername, this.cucmServerId).subscribe((emails) => {
        this.tableRows = emails.map((email) => this._mapEmailPreviewToTableRow(email));
        this.isLoading = false;
      });
    });
  }

  goHome() {
    this.router.navigate(['..'], { relativeTo: this.route });
  }

  private _mapEmailPreviewToTableRow(emailTemplatePreview: EmailTemplatePreviewFieldConfig): EntityTableContentRow {
    return {
      content: {
        emailTemplateName: emailTemplatePreview.emailTemplateName,
        recipients: emailTemplatePreview.recipients,
      },
      badges: {
        recipients: {
          values: emailTemplatePreview.recipients,
        },
      },
      actions: [
        {
          buttonStyle: ButtonStyles.PRIMARY,
          icon: SmacsIcons.NEXT,
          dataAutomation: 'preview-email-button',
          onClick: () => this._openPreviewModal(emailTemplatePreview),
        },
      ],
    };
  }

  private _openPreviewModal(emailTemplatePreview: EmailTemplatePreviewFieldConfig) {
    this.emailService.openEmailPreviewModal(this.enduserUsername, emailTemplatePreview);
  }
}
