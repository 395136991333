import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import {
  MicrosoftSecurityGroupOption,
  MicrosoftSecurityGroupSettings,
} from '../../../shared/models/generated/smacsModels';
import { MicrosoftSecurityGroupSettingsResource } from '../../../shared/resources/microsoft-security-group-settings.resource';
import { MsSecurityGroupManagementEntity } from './security-group-management-form.component';

@Component({
  selector: 'ziro-security-group-management',
  templateUrl: './security-group-management.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [MicrosoftSecurityGroupSettingsResource],
})
export class SecurityGroupManagementComponent implements OnInit {
  isLoading = true;
  formEntity: MsSecurityGroupManagementEntity;
  securityOptions: string[] = [];
  securityGroups: MicrosoftSecurityGroupOption[];

  constructor(
    private _breadcrumbsService: BreadcrumbsService,
    private _microsoftSecurityGroupsSettingsResource: MicrosoftSecurityGroupSettingsResource
  ) {}

  ngOnInit(): void {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.microsoft.security_group_management.title' }]);
    this._microsoftSecurityGroupsSettingsResource.get().subscribe({
      next: (securityGroupSettings: MicrosoftSecurityGroupSettings) => {
        this.formEntity = {
          readOnlySecurityGroupList: [...securityGroupSettings.readOnlySecurityGroupList],
          writeSecurityGroupList: [...securityGroupSettings.writeSecurityGroupList],
        };
        this.isLoading = false;
      },
    });
  }
}
