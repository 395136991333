import { Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject, Subscription, tap } from 'rxjs';
import { UserPhoto } from '../models/generated/smacsModels';
import { chunk } from 'lodash';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ZpmUserPhotoContext {
  private _stateSource = new ReplaySubject<number>(1);
  state$ = this._stateSource.asObservable();

  private _upns: string[] = [];
  private _userPhotos: UserPhoto[] = [];
  private _baseUrl = '/services/microsoft/macs/user-photos/search';

  constructor(private _http: HttpClient) {}

  getUpns(): string[] {
    return this._upns;
  }

  getUserPhotos(): UserPhoto[] {
    return this._userPhotos;
  }

  initUserPhotos(upns: string[]): Subscription {
    this._upns = upns;
    const loadedUpns = this.getUserPhotos().map((photo: UserPhoto) => photo.userPrincipalName);
    const filteredUpns = upns.filter((upn: string) => !loadedUpns.includes(upn));
    const batchedUpns = chunk(filteredUpns, 15);
    const requests = batchedUpns.map((upns: string[]) =>
      this._searchPhotos(upns, '48x48').pipe(
        tap((data: UserPhoto[]) => {
          this._userPhotos = this._userPhotos.concat(data);
          this._stateSource.next(Date.now());
        })
      )
    );

    if (!requests.length) this._stateSource.next(Date.now());

    return forkJoin(requests).subscribe();
  }

  private _searchPhotos(upns: string[], photoSize = '48x48'): Observable<UserPhoto[]> {
    return this._http.post<UserPhoto[]>(this._baseUrl, { userPrincipalNames: upns, photoSize: photoSize });
  }
}
