import { Injectable, NgZone } from '@angular/core';
import { JobStatus } from '../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { PollingAbstractService } from '../../shared/services/abstract/polling.abstract.service';

@Injectable()
export class CdrDumpFileImportPollingContext extends PollingAbstractService<JobStatus> {
  baseUrl = '/services/cisco/reporting/cucm-cdrs/imports/dump-files/status';

  constructor(protected httpClient: HttpClient, protected ngZone: NgZone) {
    super(httpClient, ngZone);
  }
}
