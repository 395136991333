<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item [aboutCardId]="63"></app-breadcrumb-card-item>
    </app-breadcrumbs>
    <router-outlet></router-outlet>
  </div>

  <div class="col-md-10 offset-md-1 h-100 d-flex flex-column pb-3" *ngIf="!isLoading">
    <div
      *ngIf="
        !isRunning &&
        bulkJobStatus &&
        bulkJobStatus.jobStatus.jobState === jobState.IDLE &&
        bulkJobStatus.jobStatus.lastRunTime
      "
      class="alert alert-info"
      data-automation="last-completed-job-alert"
    >
      <span
        [innerHTML]="
          'tkey;automate.zpm.bulk_provisioning.previous_job.alert.previous'
            | translate : { auditTag: bulkJobStatus.jobDetailedStatus.jobName }
        "
      ></span>
    </div>

    <div
      *ngIf="bulkJobStatus && bulkJobStatus.jobStatus.jobState === jobState.QUEUED"
      class="alert alert-info d-flex justify-content-between align-items-center"
      data-automation="queued-job-alert"
    >
      {{ 'tkey;automate.zpm.bulk_provisioning.previous_job.alert.queued' | translate }}
    </div>

    <div
      *ngIf="bulkJobStatus && bulkJobStatus.jobStatus.jobState === jobState.RUNNING"
      class="alert alert-info"
      data-automation="queued-job-alert"
    >
      <p>
        <span
          [innerHTML]="
            'tkey;automate.zpm.bulk_provisioning.drafts.inprogress.alert.content'
              | translate : { jobName: bulkJobStatus?.jobDetailedStatus?.jobName }
          "
        ></span>
        <i class="{{ smacsIcons.SAVING }} ps-1"></i>
        .
      </p>
      <a [routerLink]="'/automate/microsoft/bulk-provisioning/in-progress'">
        <strong>{{ 'tkey;automate.zpm.bulk_provisioning.drafts.inprogress.alert.link' | translate }}</strong>
      </a>
    </div>

    <div>
      <smacs-microsoft-bulk-provisioning-form
        [entity]="draftFormData"
        [tableRows]="tableRows"
        [drafts]="drafts"
        [dialPlanFieldConfigs]="microsoftDialPlanFieldConfig"
      ></smacs-microsoft-bulk-provisioning-form>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
