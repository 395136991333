import { Component, ViewChild } from '@angular/core';
import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { SmacsFieldComponentConfig } from '../../smacs-forms-models';
import { SmacsFormStateService } from '../../smacs-form-state.service';

// When adding a new mode, we need to manually import it from the package.
// Available languages are found here: https://codemirror.net/5/mode/index.html
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/handlebars/handlebars';
import 'codemirror/mode/xml/xml';
import 'codemirror/mode/powershell/powershell';
import { CodemirrorComponent } from '@ctrl/ngx-codemirror';

type SmacsCodeAreaValue = string;

export enum SmacsCodeAreaMode {
  SQL = 'sql',
  HTML = 'html',
  POWERSHELL = 'powershell',
}

export class SmacsCodeAreaConfig extends SmacsFieldComponentConfig {
  constructor(public config: { mode: SmacsCodeAreaMode }) {
    super();
  }
}

class CodeMirrorMode {
  name: string;
  [option: string]: string;
}

@Component({
  selector: 'smacs-code-area',
  templateUrl: './smacs-code-area.component.html',
  styleUrls: ['./smacs-code-area.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsCodeAreaComponent }],
})
export class SmacsCodeAreaComponent extends SmacsFieldAbstractDirective<
  SmacsCodeAreaValue,
  SmacsCodeAreaValue,
  SmacsCodeAreaConfig
> {
  @ViewChild(CodemirrorComponent) codeMirrorComponent: CodemirrorComponent;

  // Mode == Code Language & config options
  mode: CodeMirrorMode;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  applyComponentConfig = ({ config }: SmacsCodeAreaConfig) => {
    this._applyMode(config.mode);
  };

  insertText(text: string) {
    const codeMirrorEditor = this.codeMirrorComponent.codeMirror;
    if (codeMirrorEditor) {
      codeMirrorEditor.replaceRange(text, codeMirrorEditor.getCursor());
    }
  }

  private _applyMode(codeAreaMode: SmacsCodeAreaMode) {
    switch (codeAreaMode) {
      case SmacsCodeAreaMode.SQL:
        this.mode = { name: 'sql' };
        break;
      case SmacsCodeAreaMode.HTML:
        this.mode = { name: 'handlebars', base: 'text/html' };
        break;
      case SmacsCodeAreaMode.POWERSHELL:
        this.mode = { name: 'powershell' };
        break;
      default:
        throw new Error(`No matching definition for code-area mode [${codeAreaMode}] was found.`);
    }
  }
}
