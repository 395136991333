import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { sortBy, uniq } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { PhoneResource } from '../../../shared/resources/phone.resource';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { OrphanedDevicesResource } from '../resources/orphaned-devices.resource';
import { ExtensionMobilityService } from '../../../shared/services/extension-mobility.service';
import { combineLatest, from, Observable, Subscription } from 'rxjs';
import {
  ClusterResult,
  OrphanedDevice,
  OrphanedDeviceList,
  Privilege,
  SiteSummary,
} from '../../../shared/models/generated/smacsModels';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { DatatableColumn, DatatableComponent, DatatableRow } from '../../datatable/datatable.component';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../shared/services/toast.service';
import { AuthenticationContext } from '../../../shared/contexts/authentication.context';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { DatatableFilterService } from '../../datatable/datatable-filter.service';

interface OrphanedDeviceRow extends DatatableRow {
  pkid: string;
  clusterId: string;
  deviceName: string;
  deviceType: string;
  cucmCluster: string;
}

@Component({
  selector: 'app-reports-orphaned-devices',
  templateUrl: './orphaned-devices.component.html',
  styleUrls: ['../../reporting.scss'],
  providers: [ExtensionMobilityService],
})
export class OrphanedDevicesComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent) datatableChildComponent: DatatableComponent<OrphanedDeviceRow>;

  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  isLoading = true;
  isReadOnly: boolean;
  deviceTypeFilterOptions = [] as string[];
  cucmClusterFilterOptions = [] as string[];
  orphanedDevices = [] as OrphanedDeviceRow[];
  tableDataAutomation = 'orphaned-devices-datatable';
  columns = [
    {
      name: 'deviceName',
      label: 'tkey;pages.admin.orphaned_devices.deviceName',
    },
    {
      name: 'deviceType',
      label: 'tkey;pages.admin.orphaned_devices.deviceType',
    },
    {
      name: 'cucmCluster',
      label: 'tkey;pages.admin.orphaned_devices.clusterName',
    },
  ] as DatatableColumn<OrphanedDeviceRow>[];
  bottomNavButton = {
    id: 'orphanedDevicesDeleteBtn',
    dataAutomation: 'orphanedDevicesDeleteBtn',
    label: 'tkey;pages.admin.orphaned_devices.delete',
    buttonClass: this.buttonStyles.DANGER,
    icon: this.smacsIcons.DELETE,
    state: {
      pending: false,
      buttonDisableState: { disabled: true, tooltipKey: 'tkey;pages.admin.orphaned_devices.delete.tooltip' },
      tooltipVisible: true,
    },
    cb: () => {
      this._onDeleteButtonClick();
    },
  };

  private _siteSummary: SiteSummary;
  private _subs = new Subscription();

  constructor(
    private translateService: TranslateService,
    private orphanedDevicesResource: OrphanedDevicesResource,
    private smacsModalService: SmacsModalService,
    private siteSummaryContext: SiteSummaryContext,
    private phoneResource: PhoneResource,
    private extensionMobilityService: ExtensionMobilityService,
    private bottomNavService: BottomNavService,
    private breadcrumbsService: BreadcrumbsService,
    private toastService: ToastService,
    private datatableFilterService: DatatableFilterService,
    private authenticationContext: AuthenticationContext
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;pages.admin.orphaned_devices.title' }]);

    const reportObservable = this.siteSummaryContext.state$.pipe(
      switchMap((siteSummary) => {
        this._siteSummary = siteSummary;
        return this._getOrphanedDevices();
      })
    );

    const authenticationObservable = this.authenticationContext.state$.pipe(
      map((user) => {
        this.isReadOnly = !user.privileges.includes(Privilege.CONTROL_WRITE);
      })
    );

    this._subs.add(
      combineLatest([reportObservable, authenticationObservable]).subscribe(() => {
        this._initBottomNav();
        this.isLoading = false;
      })
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
    this.breadcrumbsService.clearBreadcrumbs();
  }

  getSearchResultsToDelete(): OrphanedDeviceRow[] {
    return this.orphanedDevices.filter((orphanedDeviceRow: OrphanedDeviceRow) => orphanedDeviceRow.isSelectedInTable);
  }

  isDeviceTypeMatch = (datatableFilter: string[], row: OrphanedDeviceRow): boolean =>
    this.datatableFilterService.filterMultiSelectValues(datatableFilter, row.deviceType);

  isCucmClusterMatch = (datatableFilter: string[], row: OrphanedDeviceRow): boolean =>
    this.datatableFilterService.filterMultiSelectValues(datatableFilter, row.cucmCluster);

  setDeleteButtonState() {
    const rowsSelected = this.orphanedDevices.filter((row: OrphanedDeviceRow) => row.isSelectedInTable);

    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          ...this.bottomNavButton,
          state: {
            buttonDisableState: {
              disabled: !rowsSelected.length,
              tooltipKey: !rowsSelected.length ? 'tkey;pages.admin.orphaned_devices.delete.tooltip' : '',
            },
            tooltipVisible: !rowsSelected.length,
          },
          label:
            rowsSelected.length >= 1
              ? rowsSelected.length === 1
                ? 'tkey;pages.admin.orphaned_devices.delete.count.one.label'
                : 'tkey;pages.admin.orphaned_devices.delete.count.label'
              : 'tkey;pages.admin.orphaned_devices.delete.label',
          labelParam: rowsSelected.length,
        },
      ])
    );
  }

  private _onConfirmDelete(): Observable<void> {
    return new Observable((subscriber) => {
      const searchResultToDelete = this.getSearchResultsToDelete();
      this.filterDeletedSearchResults(searchResultToDelete);
      const auditTags = [{ name: 'task', value: 'Orphaned Devices' }];
      const devicesToDelete = searchResultToDelete.map((searchResult: OrphanedDeviceRow) => {
        const cucmServerId = this.siteSummaryContext.findCucmServerIdForCluster(
          this._siteSummary,
          Number(searchResult.clusterId)
        );
        if (searchResult.deviceType === 'Extension Mobility') {
          return this.extensionMobilityService.delete(searchResult.pkid, cucmServerId, auditTags);
        } else {
          return this.phoneResource.delete(searchResult.pkid, String(cucmServerId), auditTags);
        }
      }) as Observable<void>[];

      from(devicesToDelete)
        .pipe(mergeMap((request: Observable<void>) => request, 3))
        .subscribe({
          complete: () => {
            this.filterDeletedSearchResults(searchResultToDelete);

            this.toastService.push(
              ToastTypes.INFO,
              `${this.smacsIcons.DELETE} text-danger`,
              'tkey;shared.toast.delete.success.title',
              `tkey;pages.admin.orphaned_devices.delete.success`
            );
            this.datatableChildComponent?.checkIfAllSelected();
            this.setDeleteButtonState();
            subscriber.next();
            subscriber.complete();
          },
        });
    });
  }

  private _getOrphanedDevices(): Observable<void> {
    return this.orphanedDevicesResource.find().pipe(
      map((response: OrphanedDeviceList) => {
        this.orphanedDevices = this._sortOrphanedDevices(response.results).map((searchResult: OrphanedDevice) => {
          return {
            pkid: searchResult.pkid,
            clusterId: searchResult.clusterId,
            deviceName: searchResult.name,
            deviceType: searchResult.type,
            cucmCluster: this._siteSummary.clusters.find(
              (cluster: ClusterResult) => cluster.id === Number(searchResult.clusterId)
            ).name,
            isTableRowSelectable: true,
          };
        });

        this.deviceTypeFilterOptions = uniq(this.orphanedDevices.map((d: OrphanedDeviceRow) => d.deviceType));
        this.cucmClusterFilterOptions = uniq(this.orphanedDevices.map((d: OrphanedDeviceRow) => d.cucmCluster));
      })
    );
  }

  private _sortOrphanedDevices(orphanedDevicesArray: OrphanedDevice[]): OrphanedDevice[] {
    return sortBy(orphanedDevicesArray, (data) => {
      return [data.clusterId, data.type, data.name.toLowerCase()];
    });
  }

  private _showCustomModal(searchResultsToDelete: OrphanedDeviceRow[]) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        promptBody: this.translateService.instant('tkey;pages.admin.orphaned_devices.delete.confirm', {
          devices: searchResultsToDelete.length,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onConfirmDelete(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _onDeleteButtonClick(): void {
    const searchResultsToDelete = this.getSearchResultsToDelete();

    if (searchResultsToDelete.length === 0) {
      return;
    }

    this._showCustomModal(searchResultsToDelete);
  }

  private filterDeletedSearchResults(deletedSearchResults: OrphanedDeviceRow[]): void {
    const deletedSearchResultsIds = deletedSearchResults.map((deletedSearchResult: OrphanedDeviceRow) => {
      return deletedSearchResult.pkid;
    });

    this.orphanedDevices = this.orphanedDevices.filter(
      (orphanedDeviceRow: OrphanedDeviceRow) => !deletedSearchResultsIds.includes(orphanedDeviceRow.pkid)
    );
  }

  private _initBottomNav() {
    const bottomNavButtons: BottomNavButton[] = [];

    if (!this.isReadOnly) {
      bottomNavButtons.push(this.bottomNavButton);
    }

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }
}
