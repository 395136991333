import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import {
  Microsoft365License,
  Microsoft365LicenseCount,
  Microsoft365LicenseSettings,
  Microsoft365LicensesView,
} from '../../../shared/models/generated/smacsModels';
import { forkJoin } from 'rxjs';
import { MsLicenseManagementEntity } from './license-management-form.component';
import { Microsoft365LicenseSettingsResource } from '../../../shared/resources/microsoft-365-license-settings.resource';
import { Microsoft365LicensesViewResource } from '../../../shared/resources/microsoft-365-licenses-view.resource';

@Component({
  selector: 'microsoft-license-management',
  templateUrl: './license-management.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [Microsoft365LicenseSettingsResource],
})
export class MicrosoftLicenseManagementComponent implements OnInit {
  isLoading = true;
  formEntity: MsLicenseManagementEntity;
  usageLocationOptions: string[];
  licensesOptions: string[];
  ms365Licenses: Microsoft365LicenseCount[];

  constructor(
    private _breadcrumbsService: BreadcrumbsService,
    private _microsoft365LicensesViewResource: Microsoft365LicensesViewResource,
    private _microsoft365LicenseSettingsResource: Microsoft365LicenseSettingsResource
  ) {}

  ngOnInit() {
    this._initBreadcrumbs();
    this._initFormData();
  }

  private _initBreadcrumbs() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.microsoft.license_management.title' }]);
  }

  private _initFormData() {
    forkJoin([this._microsoft365LicensesViewResource.get(), this._microsoft365LicenseSettingsResource.get()]).subscribe(
      (data: [Microsoft365LicensesView, Microsoft365LicenseSettings]) => {
        this.usageLocationOptions = data[0].usageLocationsList;
        this.ms365Licenses = data[0].microsoft365LicenseCountJsonList;
        this.licensesOptions = data[0].microsoft365LicenseCountJsonList.map(
          (count: Microsoft365LicenseCount) =>
            count.microsoft365LicenseJson.productName || count.microsoft365LicenseJson.skuPartNumber
        );
        const licenseSettings = data[1];

        this.formEntity = {
          usageLocation: {
            show: licenseSettings.usageLocation.show,
            required: licenseSettings.usageLocation.required,
            defaultValue: licenseSettings.usageLocation.defaultValue,
            possibleOptions: licenseSettings.usageLocation.possibleOptions,
          },
          licenses: {
            show: true,
            required: false,
            defaultValues: licenseSettings.writeLicenseList.map(
              (license: Microsoft365License) => license.productName || license.skuPartNumber
            ),
            possibleOptions: licenseSettings.readOnlyLicenseList.map(
              (license: Microsoft365License) => license.productName || license.skuPartNumber
            ),
          },
        };

        this.isLoading = false;
      }
    );
  }
}
