import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot } from '@angular/router';
import { Observable, of, Subscriber } from 'rxjs';
import { SmacsModalService } from '../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormStateService } from './smacs-form-state.service';
import { SmacsIcons } from '../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../button/button.component';
import { SiteComponent } from '../admin/cisco/site-management/sites/site/site.component';

@Injectable()
export class SmacsFormConfirmRouteChangeService {
  private _isDeactivating = false;

  constructor(
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private smacsFormStateService: SmacsFormStateService
  ) {}

  canDeactivate(
    component: any,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot
  ): Observable<boolean> {
    if (component instanceof SiteComponent) {
      const isDirty =
        component.serviceSettingComponents.some((settingComponent) => settingComponent.serviceSettingForm.isDirty) ||
        component.siteConfigurationFormComponent.isDirty;
      if (!isDirty) {
        return of(true);
      }
    }
    return new Observable<boolean>((subscriber: Subscriber<boolean>) => {
      if (this._isDeactivating) {
        subscriber.next(true);
      } else {
        this._isDeactivating = true;
        this.smacsModalService.dismissOpenModals();
        if (this.smacsFormStateService.getIsFormDirty()) {
          this._openModal().subscribe((response) => {
            this._isDeactivating = false;
            if (response) {
              subscriber.next(true);
            } else {
              const locationHash = window.location.hash;
              if (locationHash !== currentState.url) {
                window.location.hash = `#${currentState.url}`;
              }
            }
          });
        } else {
          this._isDeactivating = false;
          subscriber.next(true);
        }
      }
    });
  }

  private _openModal(): Observable<any> {
    const options = {
      windowClass: 'confirm-navigation-modal',
      modalViewProperties: {
        title: this.translateService.instant('tkey;global.discard_changes.title'),
        promptBody: this.translateService.instant('tkey;global.discard_changes.subtitle'),
        icon: SmacsIcons.WINDOWS_CLOSE,
        iconClass: 'text-muted',
        displayCloseButton: true,
        buttons: [
          {
            label: 'Cancel',
            buttonClass: ButtonStyles.DEFAULT,
            isSubmitButton: false,
            dataAutomation: 'confirmation-modal-cancel-button',
            cb: () => {
              return new Observable((noClickSubscriber: Subscriber<boolean>) => noClickSubscriber.next(false));
            },
          },
          {
            label: 'Discard',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              this.smacsFormStateService.setIsFormDirty(false);
              return new Observable((sub: Subscriber<boolean>) => sub.next(true));
            },
          },
        ],
      },
    };
    return this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }
}
export const smacsFormConfirmRouteChangeGuardFn: CanDeactivateFn<any> = (component, route, state) =>
  inject(SmacsFormConfirmRouteChangeService).canDeactivate(component, route, state);
