import { Component, OnDestroy, OnInit } from '@angular/core';
import { Microsoft360ViewContext } from '../../../shared/contexts/microsoft-360-view.context';
import { MicrosoftSecurityGroupsMembershipContext } from '../../../shared/contexts/microsoft-security-groups-membership.context';
import { combineLatest, Subscription } from 'rxjs';
import {
  Microsoft360View,
  MicrosoftSecurityGroupFieldConfigOption,
  MicrosoftSecurityGroupOption,
  MicrosoftSecurityGroupsFieldConfig,
} from '../../../shared/models/generated/smacsModels';
import { SecurityGroupEntity } from './security-group-memberships-form/security-group-memberships-form.component';

@Component({
  selector: 'ziro-security-group-memberships',
  templateUrl: './security-group-memberships.component.html',
})
export class SecurityGroupMembershipsComponent implements OnInit, OnDestroy {
  microsoft360View: Microsoft360View;
  securityGroupConfig: MicrosoftSecurityGroupsFieldConfig;
  inventory: MicrosoftSecurityGroupFieldConfigOption[];
  originalEntity: SecurityGroupEntity = { securityGroups: {} };
  isLoading = true;
  private _subscriptions = new Subscription();

  constructor(
    private _microsoft360ViewContext: Microsoft360ViewContext,
    private _securityGroupsMembershipContext: MicrosoftSecurityGroupsMembershipContext
  ) {}

  ngOnInit(): void {
    this._securityGroupsMembershipContext.refresh().subscribe(() => {
      this._getInitialData();
    });
  }

  private _getInitialData() {
    const contextsSub = combineLatest([
      this._microsoft360ViewContext.state$,
      this._securityGroupsMembershipContext.state$,
    ]).subscribe(([microsoft360View, securityGroupsConfig]) => {
      this.microsoft360View = microsoft360View;
      this.securityGroupConfig = securityGroupsConfig;

      this.inventory = securityGroupsConfig.writeSecurityGroupList.concat(
        securityGroupsConfig.readOnlySecurityGroupList
      );
      this._setOriginalEntity();
      this.isLoading = false;
    });

    this._subscriptions.add(contextsSub);
  }

  private _setOriginalEntity() {
    this.inventory.forEach((securityGroup: MicrosoftSecurityGroupOption) => {
      const id = securityGroup.id;
      const displayName = securityGroup.displayName;

      this.originalEntity.securityGroups[displayName] = !!this.microsoft360View.securityGroupMemberships.find(
        (securityGroup: MicrosoftSecurityGroupOption) => securityGroup.id === id
      );
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }
}
