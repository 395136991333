export const numericizePhoneNumber = (phoneNumber: string): string => {
  if (!phoneNumber) {
    return '';
  }
  if (phoneNumber.startsWith('1')) {
    phoneNumber = phoneNumber.slice(1, phoneNumber.length);
  }
  return phoneNumber
    .replaceAll('+1', '')
    .replaceAll('+', '')
    .replaceAll('(', '')
    .replaceAll(')', '')
    .replaceAll('-', '')
    .replaceAll(' ', '')
    .trim();
};
