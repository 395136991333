import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  CiscoZeroTouchProvisioningFieldConfig,
  DeprovisioningSchedule,
  Global360View,
  JobStatus,
  LdapUserId,
  LdapUserResult,
  ProvisioningSchedule,
} from '../models/generated/smacsModels';
import { ModelResourceAbstract } from './abstract/model.resource.abstract';
import { ZeroTouchJob } from '../models/zero-touch';
import { Global360ViewResource } from './global-360-view.resource';

@Injectable()
export abstract class ZpcZeroTouchResource<T extends ZeroTouchJob> extends ModelResourceAbstract<T> {
  protected _baseUrl: string;

  protected constructor(protected http: HttpClient, protected global360ViewResource: Global360ViewResource) {
    super(http);
  }

  getAll: () => Observable<T[]>;
  get: (jobId: number) => Observable<T>;
  post: (job: T) => Observable<T>;
  put: (jobId: number, job: T) => Observable<T>;
  delete: (jobId: number) => Observable<void>;

  getStatus(jobId: number): Observable<JobStatus> {
    return this.http.get<JobStatus>(`${this._baseUrl}/${jobId}/status`);
  }

  runJob = (jobId: number): Observable<JobStatus> => {
    return this.http.post<JobStatus>(`${this._baseUrl}/${jobId}/run`, null);
  };

  getCiscoZeroTouchProvisioningFieldConfigs(siteId: number): Observable<CiscoZeroTouchProvisioningFieldConfig> {
    return this.http.get<CiscoZeroTouchProvisioningFieldConfig>(
      `/services/cisco/field-configs/cisco-zero-touch-provisionings/${siteId}`
    );
  }

  getIgnoredUsers(jobId: string | number): Observable<LdapUserId[]> {
    return this.http.get<LdapUserResult[]>(`${this._baseUrl}/${jobId}/ignored-users`);
  }

  saveIgnoredUsers(jobId: string | number, ignoredUsers: LdapUserId[]): Observable<LdapUserId[]> {
    return this.http.put<LdapUserResult[]>(`${this._baseUrl}/${jobId}/ignored-users`, ignoredUsers);
  }

  getGlobal360View(username: string): Observable<Global360View> {
    return this.global360ViewResource.get(username);
  }
}

@Injectable()
export class ZeroTouchProvisioningResource extends ZpcZeroTouchResource<ProvisioningSchedule> {
  _baseUrl = '/services/cisco/automate-jobs/cisco-zero-touch-provisionings';

  constructor(protected http: HttpClient, protected global360ViewResource: Global360ViewResource) {
    super(http, global360ViewResource);
  }
}

@Injectable()
export class ZeroTouchDeprovisioningResource extends ZpcZeroTouchResource<DeprovisioningSchedule> {
  _baseUrl = '/services/cisco/automate-jobs/cisco-zero-touch-deprovisionings';

  constructor(protected http: HttpClient, protected global360ViewResource: Global360ViewResource) {
    super(http, global360ViewResource);
  }
}
