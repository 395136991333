import { Component, Input } from '@angular/core';
import { SmacsIcons } from '../models/smacs-icons.enum';

export enum ViewMembersState {
  IDLE,
  SEARCHING,
  ERROR,
}

@Component({
  selector: 'smacs-view-members',
  templateUrl: './view-members.component.html',
  styleUrls: ['./view-members.component.scss'],
})
export class SmacsViewMembersComponent {
  @Input() members: string[];
  @Input() state: ViewMembersState;
  @Input() errorMessage: string;
  @Input() disabled = false;
  @Input() buttonClass = 'btn-primary';

  filterValue = '';
  viewMembersState = ViewMembersState;
  smacsIcons = SmacsIcons;

  focusOnFilterInput() {
    document.getElementById('smacs-filter-input').focus();
  }

  getFilteredLdapSearchResults() {
    if (!this.filterValue) {
      return this.members;
    }

    return this.members.filter((item: string) => item.toLowerCase().includes(this.filterValue.toLowerCase()));
  }

  onFilterChanged(newValue: string) {
    this.filterValue = newValue;
  }

  onPopoverHidden() {
    this.filterValue = '';
  }

  getButtonClass(): string {
    switch (this.state) {
      case ViewMembersState.IDLE: {
        return this.buttonClass;
      }
      case ViewMembersState.ERROR: {
        return 'btn-danger';
      }
      case ViewMembersState.SEARCHING: {
        return 'btn-warning';
      }
      default: {
        return 'btn-primary';
      }
    }
  }
}
