import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {
  DeviceRegistrationStatus,
  DirectoryNumberResult,
  EndUserRef,
  Phone,
  PhoneStatus,
  SiteSearchResult,
} from '../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../button/button.component';
import { SmacsFormAbstractDirective } from '../../forms/smacs-form-abstract.directive';
import { HtmlInputType, InputSize, SmacsTextConfig } from '../../forms/fields/text/smacs-text.component';
import { SmacsFormsValidationState } from '../../forms/smacs-forms-models';
import { Observable, Subject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { PhoneContainer, SelfServe360View, SelfServeUiContext } from '../contexts/self-serve-ui.context';
import { DefaultDeskphoneResource } from '../resources/default-deskphone.resource';
import { SearchPhoneResource } from '../../shared/resources/search-phone.resource';
import { PhoneResource } from '../../shared/resources/phone.resource';
import { SearchDirectoryNumberResource } from '../resources/search/search-directory-number.resource';
import { DirectoryNumberResource } from '../resources/directory-number.resource';
import { ToastService } from '../../shared/services/toast.service';
import { SmacsModalService } from '../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { get } from 'lodash';
import { animate, style, transition, trigger } from '@angular/animations';
import { DragDropMode } from '../../shared/phone-buttons/drag-drop-mode.enum';
import { PhoneUiContext } from '../../shared/phone-buttons/contexts/phone-ui.context';
import { PhoneValidationService, SearchPhoneValidationMessage } from '../services/phone-validation.service';
import { tap } from 'rxjs/operators';
import phoneRegistration1 from 'ziro-static/json/phone-registration-1.json';
import phoneRegistration1Transition2 from 'ziro-static/json/phone-registration-1-transition-2.json';
import phoneRegistration2 from 'ziro-static/json/phone-registration-2.json';
import phoneRegistration2Transition3 from 'ziro-static/json/phone-registration-2-transition-3.json';
import phoneRegistration3 from 'ziro-static/json/phone-registration-3.json';
import phoneRegistration3Transition4 from 'ziro-static/json/phone-registration-3-transition-4.json';
import phoneRegistration4 from 'ziro-static/json/phone-registration-4.json';
import phoneRegistration4Transition5 from 'ziro-static/json/phone-registration-4-transition-5.json';
import { SiteContext } from '../../helpdesk/shared/contexts/site.context';
import { Nvp } from '../../shared/models/nvp';
import { DeviceRegistrationStatusPollingService } from '../../shared/services/cisco/device-registration-status-polling.service';
import { Global360ViewContext } from '../../shared/contexts/global-360-view.context';

class ReplaceDeskphoneFormData {
  extension = '';
}

@Component({
  selector: 'smacs-self-serve-replace-deskphone',
  templateUrl: './self-serve-replace-deskphone.component.html',
  styleUrls: ['./self-serve-replace-deskphone.component.scss'],
  providers: [PhoneUiContext, SiteContext, DeviceRegistrationStatusPollingService],
  animations: [
    trigger('stepAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('200ms 200ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('200ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class SelfServeReplaceDeskphoneComponent
  extends SmacsFormAbstractDirective<ReplaceDeskphoneFormData>
  implements OnInit, AfterViewInit, OnDestroy
{
  SmacsFormsValidationState = SmacsFormsValidationState;
  isLoading = true;
  isPhoneRegistering = false;
  buildFailed = false;
  canReplaceDeskphone: boolean;
  expansionModuleDetected: boolean;
  username: string;
  phoneId: string;
  currentUserRef: EndUserRef;
  currentStep = 1;
  animationCurrentStep = 1;
  smacsIcons = SmacsIcons;
  buttonSizes = ButtonSizes;
  buttonStyles = ButtonStyles;
  formConfig = {
    fields: {
      extension: {
        dataAutomation: 'extension-input',
        required: true,
        validation: [
          {
            validator: (val: string) => this.searchPhoneAndSetValidationMessage(val),
            message: () => this.phoneValidationService.getValidationMessage(),
            successMessage: SearchPhoneValidationMessage.VALID,
          },
        ],
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'Ex. 1001',
          inputSize: InputSize.LG,
        }),
      },
    },
  };
  userMode: DragDropMode;
  lottieAnimationSteps = [
    {
      animationData: phoneRegistration1,
      speed: 1,
      renderer: 'svg',
      loop: true,
    },
    {
      animationData: phoneRegistration1Transition2,
      speed: 1,
      renderer: 'svg',
      loop: false,
      autoplay: false,
    },
    {
      animationData: phoneRegistration2,
      speed: 1,
      renderer: 'svg',
      loop: true,
      autoplay: false,
    },
    {
      animationData: phoneRegistration2Transition3,
      speed: 1,
      renderer: 'svg',
      loop: false,
      autoplay: false,
    },
    {
      animationData: phoneRegistration3,
      speed: 1,
      renderer: 'svg',
      loop: true,
      autoplay: false,
    },
    {
      animationData: phoneRegistration3Transition4,
      speed: 1,
      renderer: 'svg',
      loop: false,
      autoplay: false,
    },
    {
      animationData: phoneRegistration4,
      speed: 1,
      renderer: 'svg',
      loop: true,
      autoplay: false,
    },
    {
      animationData: phoneRegistration4Transition5,
      speed: 1,
      renderer: 'svg',
      loop: false,
      autoplay: false,
    },
  ];
  phone: Phone;
  cucmServerId: number;

  private _site: SiteSearchResult;
  private _userServerId: number;
  private _isDeleted = false;
  private _phoneNotFound = false;
  private _subscriptions = new Subscription();
  private _currentAnimationTransition: number = null;
  animationReplaySource = new Subject<number>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private selfServeUiContext: SelfServeUiContext,
    private defaultDeskphoneResource: DefaultDeskphoneResource,
    private searchPhoneResource: SearchPhoneResource,
    private phoneResource: PhoneResource,
    private searchDirectoryNumberResource: SearchDirectoryNumberResource,
    private directoryNumberResource: DirectoryNumberResource,
    private toastService: ToastService,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private phoneUiContext: PhoneUiContext,
    private phoneValidationService: PhoneValidationService,
    private deviceRegistrationStatusPollingService: DeviceRegistrationStatusPollingService,
    protected smacsFormStateService: SmacsFormStateService,
    private global360ViewContext: Global360ViewContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this.userMode = DragDropMode.SELF_SERVE;
    this.username = get(this.route, 'snapshot.params.username');
    this.phoneId = get(this.route, 'snapshot.params.phoneId');

    if (!this.selfServeUiContext.selfServe360View) {
      this.selfServeUiContext.initUserDetails(this.username);
    }

    const selfServeSub = this.selfServeUiContext.selfServe360View$.subscribe((view: SelfServe360View) => {
      if (this._phoneNotFound) {
        this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(this.username)}`);
      }
      this._site = view.sites[0];
      this.currentUserRef = view.endUsers[0].ref;
      this._userServerId = view.primaryExtensions[0].serverId;
      this.phoneUiContext.phoneState$.subscribe((phone) => {
        this.phone = phone;
        this.expansionModuleDetected = phone.expansionModules.length > 0;
        this.canReplaceDeskphone = this.expansionModuleDetected ? false : view.canReplaceDeskphone;
        if (this.canReplaceDeskphone) {
          const phoneContainer = view.phoneContainers.find(
            (container: PhoneContainer) => container.phoneRef.id === this.phoneId
          );
          this.cucmServerId = phoneContainer?.phoneRef.serverId;
        }
        this.isLoading = false;
      });
    });
    this._subscriptions.add(selfServeSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.deviceRegistrationStatusPollingService.stopPolling();
    this._subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    // if the component is not initialized yet, this'll throw an error.
    if (this.isLoading) {
      setTimeout(() => this.ngAfterViewInit(), 500);
    } else if (this.canReplaceDeskphone) {
      super.ngAfterViewInit();
    }
  }

  onCancelClick() {
    const options = {
      windowClass: 'self-provisioning-cancel-modal-button',
      modalViewProperties: {
        icon: SmacsIcons.WINDOWS_CLOSE,
        iconClass: 'text-secondary',
        title: this.translateService.instant('tkey;selfserve.user.activate_deskphone.modal.cancel.title'),
        promptBody: this.translateService.instant('tkey;selfserve.user.activate_deskphone.modal.cancel.body'),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;selfserve.user.activate_deskphone.return_button.title',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onGoToSelfServeClicked(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  onNextClick() {
    this._clearAnimationTransitionTimeout();
    this.currentStep = 3;
    this.animationCurrentStep = 4;
    this.animationReplaySource.next(4);
    this._currentAnimationTransition = window.setTimeout(() => {
      this.animationCurrentStep = 5;
      this.animationReplaySource.next(5);
    }, 3400);
  }

  onBeginClick = () => {
    this._clearAnimationTransitionTimeout();
    this.currentStep = 2;
    this.animationCurrentStep = 2;
    this.animationReplaySource.next(2);
    this._currentAnimationTransition = window.setTimeout(() => {
      this.animationCurrentStep = 3;
      this.animationReplaySource.next(3);
    }, 2000);
  };

  searchPhoneAndSetValidationMessage(val: string): Observable<SmacsFormsValidationState> {
    return this.phoneValidationService.searchPhoneAndSetValidationMessage(val, this.username, this._userServerId);
  }

  getWarningTextTitle(): string {
    return this.expansionModuleDetected
      ? this.translateService.instant('tkey;selfserve.user.replace_deskphone.expansion_module.forbidden.header')
      : this.translateService.instant('tkey;selfserve.user.activate_deskphone.forbidden.header');
  }

  getWarningTextDescription(): string {
    return this.expansionModuleDetected
      ? this.translateService.instant('tkey;selfserve.user.replace_deskphone.expansion_module.forbidden.text')
      : this.translateService.instant('tkey;selfserve.user.activate_deskphone.forbidden.text');
  }

  protected submit() {
    return this._onProvisionDeskphoneClick();
  }

  private _onGoToSelfServeClicked(): Observable<void> {
    return new Observable((subscriber) => {
      this.global360ViewContext.init(this.username);
      this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(this.username)}`);
      subscriber.next();
      subscriber.complete();
    });
  }

  private _clearAnimationTransitionTimeout() {
    clearTimeout(this._currentAnimationTransition);
  }

  private _onProvisionDeskphoneClick(): Observable<Phone> {
    const phoneResult = this.phoneValidationService.getPhoneResult();
    this.isPhoneRegistering = true;
    return this.defaultDeskphoneResource
      .post({
        endUserUsername: this.username,
        siteId: `${this._site.id}`,
        phoneModel: phoneResult.ref.model,
        protocol: phoneResult.ref.protocol,
      })
      .pipe(
        tap((phone: Phone) => {
          phone.id = phoneResult.ref.id;
          phone.name = phoneResult.ref.name;

          const auditTags = [
            { name: 'self-serve replacement', value: 'self-serve replacement' },
            { name: 'owner', value: this.username },
          ] as Nvp[];

          return this.phoneResource.put(phone, phoneResult.ref.serverId.toString(), auditTags).subscribe(() => {
            this.phoneUiContext.set(phone);
            return this.searchDirectoryNumberResource.get({ extension: this.formData.extension }).subscribe({
              next: (directoryNumberResults: DirectoryNumberResult[]) => {
                const autoRegisteredDnRef = directoryNumberResults[0].ref;
                return this.directoryNumberResource
                  .delete(autoRegisteredDnRef.id, autoRegisteredDnRef.serverId, auditTags)
                  .subscribe({
                    next: () => this._checkPhoneStatus(phone),
                    error: () => {
                      this.buildFailed = true;
                    },
                  });
              },
              error: () => {
                this.buildFailed = true;
              },
            });
          });
        })
      );
  }

  private _checkPhoneStatus = (phone: Phone) => {
    this.deviceRegistrationStatusPollingService.setBaseUrl(phone.name, phone.owner.serverId);
    this.deviceRegistrationStatusPollingService.startPolling(3);
    this.deviceRegistrationStatusPollingService.state$.subscribe({
      next: (phoneStatus: PhoneStatus) => {
        if (phoneStatus.status === DeviceRegistrationStatus.REGISTERED) {
          this.isPhoneRegistering = false;
          this.deviceRegistrationStatusPollingService.stopPolling();
          const auditTags = [
            { name: 'owner', value: this.username },
            { name: 'self-serve replacement', value: 'self-serve replacement' },
          ];
          this.phoneResource.delete(this.phoneId, this.cucmServerId.toString(), auditTags).subscribe(() => {
            this._isDeleted = true;
            // replace the phone ID with the newly built and registered phone id
            this.phoneId = phone.id;
            this.phoneUiContext.set(phone);
            this.currentStep = 5;
            this._clearAnimationTransitionTimeout();
            this.animationCurrentStep = 8;
            this.animationReplaySource.next(8);
          });
        }
      },
      complete: () => {
        if (this.isPhoneRegistering) {
          this._handlePhoneNotFound();
        }
        this.global360ViewContext.init(this.username);
      },
    });
  };

  private _handlePhoneNotFound() {
    this.deviceRegistrationStatusPollingService.stopPolling();
    this.smacsFormStateService.setIsFormDirty(false);
    this._phoneNotFound = true;
    this.buildFailed = true;
    this.global360ViewContext.init(this.username);
  }
}
