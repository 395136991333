import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from '../../../shared/services/date-time.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Pipe({
  name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
  constructor(private dateTimeService: DateTimeService, private translateService: TranslateService) {}

  transform(sinceIso8601Date: string): string {
    if (!sinceIso8601Date) {
      return this.translateService.instant('tkey;time.never');
    } else if (moment(sinceIso8601Date).isSame(moment(), 'day')) {
      return this.translateService.instant('tkey;time.today');
    } else {
      return this.dateTimeService.parse(sinceIso8601Date).fromNow();
    }
  }
}
