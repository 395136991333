import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DaysAgoPipe } from './days-ago.pipe';
import { DateAgoPipe } from './date-ago.pipe';
import { StripHtmlPipe } from './strip-html.pipe';
import { DynamicPipe } from './dynamic.pipe';

@NgModule({
  declarations: [DaysAgoPipe, DateAgoPipe, StripHtmlPipe, DynamicPipe],
  exports: [DaysAgoPipe, DateAgoPipe, StripHtmlPipe, DynamicPipe],
  imports: [CommonModule],
})
export class PipesModule {}
