<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div *ngIf="!isLoading">
  <div class="smacs-page-alert alert border-warning" *ngIf="pageDisabled" data-automation="smacs-page-disabled-alert">
    <i class="{{ smacsIcons.WARNING }} text-warning fa-4x"></i>
    <div class="ms-3" [innerHTML]="'tkey;userdetail.home.services.disabled_service.label' | translate"></div>
  </div>

  <smacs-ms-teams-audio-conferencing-form
    *ngIf="!pageDisabled"
    [entity]="msTeamsAudioConferencing"
    [fieldConfig]="fieldConfig"
    [upn]="upn"
  ></smacs-ms-teams-audio-conferencing-form>
</div>
