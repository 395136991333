import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DialPlanGroupInventory, JobStatus } from '../../../shared/models/generated/smacsModels';
import { FileDownloadService } from '../../../shared/services/file-download.service';

@Injectable()
export class DialPlanInventoryResource {
  protected _baseUrl = '/services/cisco/reporting/directory-number-availability-reports';

  constructor(private http: HttpClient, private fileDownloadService: FileDownloadService) {}

  get(): Observable<DialPlanGroupInventory[]> {
    return this.http.get<DialPlanGroupInventory[]>(this._baseUrl);
  }

  getStatus(): Observable<JobStatus> {
    return this.http.get<JobStatus>(`${this._baseUrl}/status`);
  }

  refresh(): Observable<void> {
    return this.http.post<void>(`${this._baseUrl}/refresh`, null);
  }

  export(): Observable<void> {
    return this.fileDownloadService.downloadFile(`${this._baseUrl}/export`);
  }
}
