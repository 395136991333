import { Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import {
  CurrentUser,
  MicrosoftGraphRegistrationDetails,
  Privilege,
  Role,
  SearchFieldConfig,
  SiteSummary,
  State,
  StatusCategory,
} from '../models/generated/smacsModels';
import { combineLatest, of, skip, Subscription, switchMap, take, tap } from 'rxjs';
import { AuthenticationContext } from '../contexts/authentication.context';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SystemStatusContext } from '../contexts/system-status.context';
import { compare, valid } from 'semver';
import { SearchFieldConfigContext } from '../contexts/search-field-config.context';
import { PortalContext } from '../contexts/portal.context';
import { GlobalPropertiesContext } from '../contexts/global-properties.context';
import { HelpdeskOptionsContext } from '../../helpdesk/shared/contexts/helpdesk-options.context';
import { SiteSummaryContext } from '../contexts/site-summary.context';
import { ButtonStyles } from '../../button/button.component';
import { CustomUiOptionsContext } from '../contexts/custom-ui-options.context';
import { MsGraphConfigContext } from '../../admin/contexts/ms-graph-config.context';
import moment from 'moment';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['../../../scss/components/top-nav.scss', './top-nav.component.scss'],
})
export class TopNavComponent implements OnInit, OnDestroy {
  @ViewChild('toggleButton') toggleButton: ElementRef;
  @ViewChild('currentUserCard') currentUserCard: ElementRef;

  @Input() isHomePage = false;
  @Input() isMicrosoft360View = false;
  @Input() parentModel: any;

  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  query = '';
  showSearchFormAndActions = false;
  portalReleaseVersion = '';
  isReleaseAvailable = false;
  isHostedEnabled = false;
  isAddUserEnabled = false;
  ziroProvUrl = '';
  isDeskphoneEnabledOnAnyClusters: boolean;
  currentUser: CurrentUser;
  isCurrentUserCardVisible = false;
  provisioningEnabled = false;
  proxyServer = false;
  migrateToTeams = false;
  msGraphState: MicrosoftGraphRegistrationDetails;
  showReleaseMessage: boolean;
  Privilege = Privilege;

  private _isCucmSearchEnabled = false;
  private _isLicenseExpired = false;
  private _endpointDisabled = false;
  private _subscriptions = new Subscription();
  private _removeEventListener: () => void;

  constructor(
    private authenticationContext: AuthenticationContext,
    private systemStatusContext: SystemStatusContext,
    private globalPropertiesContext: GlobalPropertiesContext,
    private router: Router,
    private searchFieldConfigContext: SearchFieldConfigContext,
    private route: ActivatedRoute,
    private portalContext: PortalContext,
    private helpdeskOptionsContext: HelpdeskOptionsContext,
    private siteSummaryContext: SiteSummaryContext,
    private renderer: Renderer2,
    private uiOptionsContext: CustomUiOptionsContext,
    private msGraphConfigContext: MsGraphConfigContext
  ) {}

  ngOnInit() {
    this._getShowReleaseTimestamp();
    const path = this.router.routerState.snapshot.url;
    const queryParam = this.route.snapshot.queryParamMap.get('query');
    this.query = queryParam ? decodeURI(queryParam).trim() : '';
    this.showSearchFormAndActions = !(
      path.includes('admin') ||
      path.includes('automate') ||
      path.includes('reporting') ||
      path.includes('system-health-status')
    );

    const subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const query = this.route.snapshot.queryParamMap.get('query');
        this.query = query ? decodeURI(query).trim() : '';
      }
    });
    this._subscriptions.add(subscription);

    const authenticationContextSub = this.authenticationContext.state$.pipe(take(1)).subscribe((user) => {
      this.currentUser = user;
    });
    this._subscriptions.add(authenticationContextSub);

    const helpdeskOptionsSub = this.helpdeskOptionsContext.state$.subscribe((helpdeskOptions) => {
      this.ziroProvUrl = this.helpdeskOptionsContext.isMicrosoftHelpdeskOptions(helpdeskOptions)
        ? helpdeskOptions.zpcUrl
        : helpdeskOptions.zpmUrl;
      this.isAddUserEnabled = this.helpdeskOptionsContext.isCiscoHelpdeskOptions(helpdeskOptions)
        ? helpdeskOptions.showAddUserPage
        : false;
    });
    this._subscriptions.add(helpdeskOptionsSub);

    const globalPropertiesSub = this.globalPropertiesContext.state$.subscribe((globalProperties) => {
      this.isHostedEnabled = globalProperties.hostedEnabled;
      this._checkForMsGraph();
    });
    this._subscriptions.add(globalPropertiesSub);

    const uiOptionsSub = this.uiOptionsContext.state$.subscribe((state) => {
      this.proxyServer = state.proxyServerOnlyModeEnabled;
      this.provisioningEnabled = state.provisioningEnabled;
    });
    this._subscriptions.add(uiOptionsSub);

    const portalSub = combineLatest([
      this.globalPropertiesContext.state$,
      this.authenticationContext.state$.pipe(take(1)),
    ])
      .pipe(
        switchMap(([globalProperties, user]) => {
          if (!this.showReleaseMessage) return of(null);

          if (!globalProperties.hostedEnabled && this.authenticationContext.userIsAtLeast(user, Role.S8_ADMIN)) {
            const currentVersion = globalProperties.version.replace('-SNAPSHOT', '');

            return this.portalContext.state$.pipe(
              tap((portalVersion) => {
                this.portalReleaseVersion = portalVersion.ziroVersion;
                this.isReleaseAvailable =
                  this.portalReleaseVersion &&
                  typeof this.portalReleaseVersion === 'string' &&
                  valid(this.portalReleaseVersion) &&
                  compare(this.portalReleaseVersion, currentVersion) === 1;
              })
            );
          }
          return of(null);
        })
      )
      .subscribe();
    this._subscriptions.add(portalSub);

    const authCtxSub = this.authenticationContext.state$.pipe(skip(1)).subscribe((user) => {
      this.currentUser = user;
    });
    this._subscriptions.add(authCtxSub);

    if (this.showSearchFormAndActions && !this.isHomePage) {
      this.searchFieldConfigContext.state$.subscribe((searchFieldConfig: SearchFieldConfig) => {
        this._isCucmSearchEnabled = searchFieldConfig.cucmSearchEnabled;
      });
    }

    const systemStatusSub = this.systemStatusContext.state$.subscribe((systemStatus) => {
      this._endpointDisabled = systemStatus.endpointDisabled;

      systemStatus.healthStatuses.forEach((status) => {
        if (status.category === StatusCategory.LICENSES) {
          this._isLicenseExpired = status.state === State.ERROR && status.cause === 'License is expired';
        }
      });
    });
    this._subscriptions.add(systemStatusSub);

    const siteSumamryCtxSub = this.siteSummaryContext.state$.subscribe((siteSummary: SiteSummary) => {
      this.isDeskphoneEnabledOnAnyClusters = this.siteSummaryContext.isDeskphoneEnabledOnAnyClusters(siteSummary);
    });
    this._subscriptions.add(siteSumamryCtxSub);

    this._removeEventListener = this.renderer.listen('window', 'click', (event) => {
      if (
        this.toggleButton &&
        event.target !== this.toggleButton.nativeElement &&
        !this.toggleButton.nativeElement.contains(event.target) &&
        event.target !== this.currentUserCard.nativeElement &&
        !this.currentUserCard.nativeElement.contains(event.target) &&
        this.isCurrentUserCardVisible
      ) {
        this.isCurrentUserCardVisible = false;
      }
    });
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    if (typeof this._removeEventListener === 'function') {
      this._removeEventListener();
    }
  }

  migrateToTeamsEnabled() {
    return this.migrateToTeams && !this.provisioningEnabled;
  }

  proxyServerEnabled() {
    return this.proxyServer && !this.provisioningEnabled;
  }

  showAdminButton = (): boolean =>
    this.currentUser && this.authenticationContext.userIsAtLeast(this.currentUser, Role.S8_ADMIN);

  showReportingButton = (): boolean =>
    this.isAuthenticated() &&
    this.currentUser.privileges.includes(Privilege.CONTROL_READ) &&
    !this.proxyServerEnabled() &&
    !this.migrateToTeamsEnabled() &&
    this._isGraphEnabled();

  showAutomationButton = (): boolean =>
    this.showAdminButton() && !this.proxyServerEnabled() && !this.migrateToTeamsEnabled() && this._isGraphEnabled();

  showAddUserButton = (): boolean =>
    this.isAuthenticated() &&
    this.isAddUserEnabled &&
    this.authenticationContext.userIsAtLeast(
      this.currentUser,
      this.isHostedEnabled ? Role.S8_HELPDESK : Role.S8_GLOBAL_HELPDESK
    );

  logoutUser() {
    this.router.navigate(['/logout']);
  }

  onSubmit() {
    this.router.navigate(['/search'], { queryParams: { query: this.query ? this.query.trim() : '' } });
  }

  getReportingTooltip(): string {
    if (this._endpointDisabled) {
      return 'tkey;admin.servers.disable.tooltip';
    } else if (this._isLicenseExpired) {
      return 'tkey;admin.menu.license_expired.tooltip';
    } else {
      return 'tkey;admin.license.module.control.text';
    }
  }

  areModulesDisabled(): boolean {
    return this._endpointDisabled || this._isLicenseExpired;
  }

  getReportingLink(): string {
    if (this.areModulesDisabled()) {
      return null;
    } else if (this.isHostedEnabled) {
      return '/reporting/microsoft';
    } else {
      return '/reporting/dashboard';
    }
  }

  getAutomationTooltip(): string {
    if (this._endpointDisabled) {
      return 'tkey;admin.servers.disable.tooltip';
    } else if (this._isLicenseExpired) {
      return 'tkey;admin.menu.license_expired.tooltip';
    } else {
      return 'tkey;admin.license.module.automate.text';
    }
  }

  isAuthenticated(): boolean {
    return this.currentUser && !!this.currentUser.userId;
  }

  getAdminLink(): string[] {
    if (!this._isLicenseExpired) {
      if (this.currentUser.role === Role.ZIRO_SUPPORT && this.isHostedEnabled) {
        return ['/admin/ziro-support-settings/application-logs'];
      } else {
        return ['/admin/audits/logs'];
      }
    } else {
      return ['/admin/license'];
    }
  }

  showAddOperations(): boolean {
    return (
      this._isCucmSearchEnabled &&
      this.showSearchFormAndActions &&
      !this.isHomePage &&
      !this.isMicrosoft360View &&
      (this.showAddUserButton() || this.isDeskphoneEnabledOnAnyClusters) &&
      !this.proxyServerEnabled()
    );
  }

  toggleCurrentUserCard() {
    this.isCurrentUserCardVisible = !this.isCurrentUserCardVisible;
  }

  setShowReleaseTimestamp() {
    this.showReleaseMessage = false;
    window.localStorage.setItem('showReleaseTimestamp', Date.now().toString());
  }

  private _getShowReleaseTimestamp() {
    const nowMoment = moment(new Date(Date.now()));
    const releaseMoment = moment(new Date(Number(window.localStorage.getItem('showReleaseTimestamp'))));
    this.showReleaseMessage = moment.duration(nowMoment.diff(releaseMoment)).asHours() > 24;
  }

  private _isGraphEnabled(): boolean {
    return this.isHostedEnabled ? !!this.msGraphState?.tenant && !!this.msGraphState?.clientId : true;
  }

  private _checkForMsGraph() {
    if (this.isHostedEnabled) {
      const graphSub = this.msGraphConfigContext.state$.subscribe((state: MicrosoftGraphRegistrationDetails) => {
        this.msGraphState = state;
      });
      this._subscriptions.add(graphSub);
    }
  }
}
