<div
  class="row"
  [hidden]="(!state.hasOwnProperty('hidden') || state.hidden) && (!forceShow || state.defaultValue === null)"
  [attr.data-automation]="config.dataAutomation"
  [ngClass]="{
    'has-feedback': showValidation || isFormSubmitted || isLoading || misconfigurationFeedbackMessage,
    'smacs-forms-error': validationState === ValStates.INVALID,
    'smacs-forms-warning':
      validationState === ValStates.PENDING ||
      validationState === ValStates.WARNING ||
      misconfigurationFeedbackMessage ||
      isLoading,
  }"
>
  <label
    *ngIf="getLabel()"
    [ngClass]="'smacs-forms-label col-form-label ' + this.state.columnClasses?.label"
    [attr.for]="config.dataAutomation + '-input'"
    [attr.data-automation]="'smacs-select-label'"
  >
    <ng-template #tipContent><span [innerHtml]="config.labelToolTipText | translate"></span></ng-template>
    <div [ngbTooltip]="tipContent" [disableTooltip]="!config.labelToolTipText" class="fit-content d-inline-block">
      <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
      <span [innerHtml]="getLabel() | translate"></span>
      <i *ngIf="config.labelToolTipIconClass" class="{{ config.labelToolTipIconClass }} ms-1"></i>
    </div>
  </label>

  <div [class]="state.columnClasses?.input">
    <div
      class="input-group"
      [ngClass]="{ 'smacs-select--hidden-arrow': hideDropdownArrow }"
      [ngbTooltip]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"
      [disableTooltip]="!getDisabledState()"
      [container]="'body'"
    >
      <span
        class="{{ smacsIcons.SEARCH }} form-control-feedback form-control-feedback--left"
        data-automation="smacs-text-async-search-icon"
      ></span>
      <ng-select
        class="ng-multi-select-async ng-select-async"
        style="width: inherit"
        [labelForId]="config.dataAutomation + '-input'"
        [attr.data-automation]="'async-select-input'"
        [items]="options$ | async"
        [(ngModel)]="options"
        [ngModelOptions]="{ standalone: true }"
        #ngModelDir="ngModel"
        (ngModelChange)="onChange($event)"
        [clearable]="true"
        (clear)="onClear()"
        [multiple]="true"
        [loading]="isLoading || triggerLoading()"
        [typeahead]="optionInputSource"
        [minTermLength]="minSearchLength"
        [disabled]="getDisabledState()"
        [groupBy]="'group'"
        [placeholder]="placeholder | translate"
        [hideSelected]="hideSelected"
        (search)="handleSearchInput($event)"
        (blur)="handleBlur()"
        (close)="handleSelectClose()"
        (change)="handleChange()"
        notFoundText="{{ 'tkey;smacs_select.not_found_text' | translate }}"
        loadingText="{{ 'tkey;smacs_select.loading_text' | translate }}"
        clearAllText="{{ 'tkey;smacs_select.clear_all_text' | translate }}"
        typeToSearchText="{{ 'tkey;smacs_select.search_text' | translate }}"
      >
        <ng-template ng-typetosearch-tmp>
          <div class="d-none"></div>
        </ng-template>
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
          <span class="ng-value-label" [innerHTML]="item.label || item"></span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
          <ng-template #tipContent>
            <span>{{ item.disabledTooltip | translate }}</span>
          </ng-template>
          <span
            [ngbTooltip]="tipContent"
            [container]="'body'"
            [disableTooltip]="!item.disabled"
            [placement]="item.tooltipPlacement ? item.tooltipPlacement : 'auto'"
            class="d-flex flex-row justify-content-start align-items-center"
          >
            <span *ngIf="item.displayIcon || item.photoBase64" style="width: 2.5rem">
              <img
                *ngIf="item.photoBase64"
                [src]="'data:image/png;base64,' + item.photoBase64"
                class="round-image-navbar"
                [ngClass]="{ disabled: item.disabled }"
              />
              <i *ngIf="item.displayIcon" class="fa fa-2x icon-user-circle round-image-navbar-icon"></i>
            </span>
            <span
              class="ng-option-label"
              [ngClass]="{ 'fw-bold': item.appendValue }"
              [innerHTML]="item.label || item"
            ></span>
            <span *ngIf="item.appendValue">
              <span class="text-separator">|</span>
              {{ item.value }}
            </span>
          </span>
        </ng-template>
      </ng-select>

      <span
        class="{{ smacsIcons.SAVING }} form-control-feedback"
        [hidden]="!(validationState === ValStates.PENDING && showAsyncValidation)"
        data-automation="smacs-text-async-pending"
      ></span>
    </div>

    <div class="smacs-forms-feedback-area">
      <div class="form-text small" *ngIf="isAutogenerated" data-automation="smacs-forms-autogeneration-message">
        {{ config.autogeneration?.generatedMsg | translate }}
      </div>

      <div
        class="smacs-forms-validation-message"
        [hidden]="!showValidation && !isFormSubmitted"
        [ngClass]="{ 'd-inline': config.autogeneration?.inline && (showValidation || isFormSubmitted) }"
        data-automation="smacs-forms-validation-message"
        [innerHTML]="validationMessage?.content | translate : validationMessage?.params"
      ></div>

      <div
        class="smacs-forms-validation-message text-warning"
        [ngClass]="{ 'd-inline': config.autogeneration?.inline && !validationMessage?.content }"
        data-automation="smacs-forms-warning-message"
        [hidden]="validationMessage?.content || !misconfigurationFeedbackMessage"
        [innerHTML]="misconfigurationFeedbackMessage?.content | translate : misconfigurationFeedbackMessage?.params"
      ></div>
      <span class="ms-1" *ngIf="!isAutogenerateLinkHidden()">
        <smacs-button
          *ngIf="showAutoGenerationLink"
          [dataAutomation]="'smacs-forms-autogeneration-button'"
          [hidden]="isAutogenerateLinkHidden()"
          (clicked)="autogenerateValue()"
          [buttonStyle]="buttonStyles.LINK"
          [label]="config.autogeneration?.linkLabel"
          [labelCssClass]="'small strong'"
        ></smacs-button>
      </span>
    </div>

    <span class="small form-text text-muted" *ngIf="config.helpText" [innerHTML]="getHelpText() | translate"></span>
    <ng-content></ng-content>
  </div>
  <div class="col-lg-9 offset-lg-3 pb-2" [ngClass]="{ 'mt-2': isAutogenerateLinkHidden() }">
    <div class="d-flex align-items-center" data-automation="show-checkbox">
      <input
        type="checkbox"
        class="form-check-input mt-0 mb-1 rounded-0"
        [attr.id]="config.dataAutomation + '-show'"
        [(ngModel)]="isShowChecked"
        (ngModelChange)="onShowChange($event)"
        name="showCheck"
        [ngModelOptions]="{ standalone: true }"
      />
      <div>
        <label class="form-check-label col-form-label text-start pt-0" [attr.for]="config.dataAutomation + '-show'">
          <span class="p-0 ms-2" [innerHTML]="showLabel | translate"></span>
        </label>
      </div>
    </div>
  </div>
</div>
