import { Component, OnDestroy, OnInit } from '@angular/core';
import { zeroTouchLinks } from './zero-touch-links';
import { SystemStatusContext } from '../shared/contexts/system-status.context';
import {
  GlobalProperties,
  MicrosoftDialPlanFieldConfig,
  State,
  StatusCategory,
  SystemHealthStatus,
  UiOptions,
} from '../shared/models/generated/smacsModels';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { SmacsLink } from '../shared/models/smacs-link';
import { GlobalPropertiesContext } from '../shared/contexts/global-properties.context';
import { CustomUiOptionsContext } from '../shared/contexts/custom-ui-options.context';
import { MicrosoftDialPlansFieldConfigContext } from '../shared/contexts/microsoft-dial-plans-field-config.context';

@Component({
  selector: 'app-admin-zero-touch',
  templateUrl: './zero-touch.component.html',
  styleUrls: ['../admin/admin-page.scss'],
})
export class ZeroTouchComponent implements OnInit, OnDestroy {
  adminLinks = zeroTouchLinks;
  window = window;
  pageDisabled: boolean;
  pageDisabledMessage = 'tkey;zero_touch.page_disabled';
  trafficStatus: State;
  serverDisabled = false;
  isLoading = true;
  _isTrafficValid: boolean;
  _isZeroTouchLicenseValid: boolean;
  isLicenseExpired = false;
  linksHiddenIfHostedEnabled = [
    'ldap-sync',
    'deprovisioning',
    'provisioning',
    'bulk-provisioning',
    '/open-api/#/cisco',
  ];
  linksHiddenIfHostedDisabled = [
    '/open-api/#/microsoft',
    'microsoft/zero-touch-provisioning',
    'microsoft/bulk-provisioning',
  ];
  isHostedEnabled = false;
  isDialPlansConfigured = false;

  private _subs = new Subscription();
  private _zeroTouchEnabled = false;

  constructor(
    private _systemStatusContext: SystemStatusContext,
    private _router: Router,
    private _globalPropertiesContext: GlobalPropertiesContext,
    private _customUiOptionsContext: CustomUiOptionsContext,
    private _microsoftDialPlansFieldConfigContext: MicrosoftDialPlansFieldConfigContext
  ) {}

  ngOnInit() {
    const globalConfigurationSub = this._globalPropertiesContext.state$.subscribe(
      (globalProperties: GlobalProperties) => {
        this.isHostedEnabled = globalProperties.hostedEnabled;
        this.checkForHostedEnabled();
        this._checkForDialPlans();
      }
    );

    const systemStatusSub = this._systemStatusContext.state$.subscribe((systemStatus: SystemHealthStatus) => {
      this.trafficStatus = systemStatus.healthStatuses.find(
        (status) => status.category === StatusCategory.TRAFFIC
      )?.state;

      this.checkTrafficStatus();
    });

    const uiOptionsSub = this._customUiOptionsContext.state$.subscribe((uiOptions: UiOptions) => {
      this._zeroTouchEnabled = uiOptions.automationEnabled;
      this.checkZeroTouchLicense();
    });

    const routerSub = this._router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.checkZeroTouchLicense();
        this.checkTrafficStatus();
      }
    });

    this._subs.add(systemStatusSub);
    this._subs.add(routerSub);
    this._subs.add(uiOptionsSub);
    this._subs.add(globalConfigurationSub);
  }

  checkForHostedEnabled() {
    if (this.isHostedEnabled) {
      this.adminLinks.forEach((link: SmacsLink) => {
        link.isHidden = this.linksHiddenIfHostedEnabled.includes(link.url);
      });
    } else {
      this.adminLinks.forEach((link: SmacsLink) => {
        link.isHidden = this.linksHiddenIfHostedDisabled.includes(link.url);
      });
    }
  }

  checkTrafficStatus() {
    if (
      !this._router.url.endsWith('/about-rest') &&
      this.trafficStatus !== State.OK &&
      this.trafficStatus !== undefined
    ) {
      this.pageDisabledMessage = 'tkey;zero_touch.page_disabled';
      this._isTrafficValid = false;
    } else {
      this._isTrafficValid = true;
    }
    this._setPageDisabled();
  }

  checkZeroTouchLicense() {
    this.adminLinks
      .filter((link) => link.url.includes('open-api'))
      .forEach((apiLink) => {
        if (apiLink) {
          apiLink.isRouterLink = !this._zeroTouchEnabled;
          apiLink.url = this._zeroTouchEnabled ? apiLink.url : 'open-api';
        }
      });
    if (!this._zeroTouchEnabled && !this._router.url.endsWith('/ldap-sync')) {
      this._isZeroTouchLicenseValid = false;
      this.pageDisabledMessage = 'tkey;zero_touch.page_disabled.unlicensed';
    } else {
      this._isZeroTouchLicenseValid = true;
    }
    this._setPageDisabled();
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private _setPageDisabled() {
    this.pageDisabled = !this._isTrafficValid || !this._isZeroTouchLicenseValid;
    this.isLoading = false;
  }

  private _checkForDialPlans() {
    if (this.isHostedEnabled) {
      const subscription = this._microsoftDialPlansFieldConfigContext.state$.subscribe(
        (fieldConfig: MicrosoftDialPlanFieldConfig[]) => {
          this.isDialPlansConfigured = !!fieldConfig.length;
        }
      );
      this._subs.add(subscription);
    }
  }
}
