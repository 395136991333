import { Component, OnInit } from '@angular/core';
import { AuthenticationContext } from '../contexts/authentication.context';

@Component({
  selector: 'ziro-logout-component',
  template: '',
})
export class LogoutComponent implements OnInit {
  constructor(private authenticationContext: AuthenticationContext) {}

  ngOnInit() {
    this.authenticationContext.logout().subscribe();
  }
}
