import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  AppUserResult,
  ClusterResult,
  CucmMetadata,
  CustomCheckbox,
  CustomInputText,
  CustomMultiSelect,
  CustomSelect,
  EnabledType,
  EndUserResult,
  MultiSelect,
  Select,
  ServiceSetting,
  UnityMetadata,
} from '../../../../shared/models/generated/smacsModels';
import {
  SmacsFieldConfigs,
  SmacsFormConfig,
  SmacsFormsUpdate,
  SmacsFormsValidationConfig,
  SmacsFormsValidationConfigItem,
  SmacsFormsValidationState,
} from '../../../../forms/smacs-forms-models';
import { forkJoin, Observable, of } from 'rxjs';
import { SmacsCheckboxButtonConfig } from '../../../../forms/fields/checkbox-button/checkbox-button-field.component';
import { ButtonSizes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { sortBy } from 'lodash';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../forms/fields/select/smacs-select.component';
import { map } from 'rxjs/operators';
import { EndUserSearchResource } from '../../../../shared/resources/end-user-search.resource';
import { SmacsMultiCheckboxConfig } from '../../../../forms/fields/multi-checkbox/smacs-multi-checkbox.component';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  SmacsCheckboxConfig,
} from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { TranslateService } from '@ngx-translate/core';
import { SiteManagementServiceFormComponent } from './site-management-service-form/site-management-service-form.component';
import { getServiceFieldNameTkey, getServiceNameTkey } from './serviceNameMappings';
import { AppUserSearchResource } from '../../../../shared/resources/app-user-search.resource';
import { ZiroCustomInputTextConfig } from '../../../../shared/custom-configs/custom-input-text/ziro-custom-input-text.component';
import { ZiroCustomSelectConfig } from '../../../../shared/custom-configs/custom-select/ziro-custom-select.component';
import { customSelectOrderedFields } from './orderedFieldIds';
import { ZiroAsyncMultiSelectConfig } from '../../../../shared/custom-configs/smacs-multi-select-config/ziro-async-multi-select-config.component';
import { SiteSideNavLink } from '../site-side-nav/site-side-nav.component';
import { CommonFieldsState } from '../sites/site/common-fields-ui.context';
import { VariableEditorType } from '../../../../modals/edit-custom-input-text-modal/variable-editor.service';

enum ServiceNameDataPrefix {
  ANDROID = 'android-',
  DESK_PHONE = 'deskphone-',
  CIPC = 'cipc-',
  DIRECTORY_NUMBER = 'directorynumber-',
  END_USER = 'enduser-',
  EXTENSION_MOBILITY = 'extensionmobility-',
  IM_PRESENCE = 'impresence-',
  IM_SOFTPHONE = 'imsoftphone-',
  IPHONE = 'iphone-',
  LINE_FEATURES = 'linefeatures-',
  SINGLE_NUMBER_REACH = 'singlenumberreach-',
  TABLET = 'tablet-',
  TRANSLATION_PATTERN = 'translationpattern-',
  VOICEMAIL = 'voicemail-',
}

interface InvalidAsyncOption {
  fieldName: string;
  optionName: string;
}

export interface ServiceSettingFormData {
  [key: string]:
    | CustomCheckbox
    | Select
    | MultiSelect
    | boolean
    | string
    | EnabledType
    | string[]
    | CustomMultiSelect
    | CustomSelect
    | CustomInputText;
}

export interface ServiceSettingFieldIds {
  customInputTexts?: string[];
  customSelects?: string[];
  customMultiSelects?: string[];
  selects?: string[];
  multiSelects?: string[];
  customCheckboxes?: string[];
  checkboxes?: string[];
  jabberTypeTextInputs?: string[];
  dataLocationTextInputs?: string[];
  optionalPhoneServicesInput?: boolean;
  primeLineCustomSelects?: string[];
}
@Component({
  selector: 'ziro-site-management-service',
  templateUrl: './site-management-service.component.html',
  styleUrls: ['./site-management-service.component.scss'],
})
export class SiteManagementServiceComponent implements OnInit {
  @ViewChild(SiteManagementServiceFormComponent) serviceSettingForm: SiteManagementServiceFormComponent;

  @Output() smacsFormsUpdate$ = new EventEmitter<SmacsFormsUpdate<ServiceSetting>>();
  @Output() sideNavLinksUpdate$ = new EventEmitter<SiteSideNavLink[]>();

  @Input() cucmMetadata: CucmMetadata;
  @Input() unityMetadata: { [index: string]: UnityMetadata };
  @Input() serviceSetting: ServiceSetting;
  @Input() clusterResult: ClusterResult;
  @Input() siteName: string;
  @Input() serviceSettingToCopy: ServiceSetting;
  @Input() commonFieldsState: CommonFieldsState;
  @Input() unityServerOptions: SmacsSelectOption[];
  @Input() commonFieldScrollSpacing = false;

  dataAutomation: string;
  smacsIcons = SmacsIcons;
  protected readonly EnabledType = EnabledType;
  dynamicServiceName: string;
  impDisabledTooltip: string;
  formConfigInput: SmacsFormConfig;
  isLoading = true;
  serviceSettingFieldIds: ServiceSettingFieldIds;
  validatedAsyncSelectFields: string[] = [];
  invalidAsyncOptions: InvalidAsyncOption[] = [];
  private _alwaysRequiredFields: string[] = [
    'name',
    'location',
    'phone_model',
    'common_phone_profile',
    'built_in_bridge',
    'privacy',
    'device_mobility_mode',
    'sip_profile_name',
    'always_use_prime_line',
    'always_use_prime_line_for_vm',
    'mobility_identity_name',
    'stop_ringing_phone_delay',
    'call_recording_media_source',
    'voicemail_template',
    'profile_name',
  ];
  private _hideEditorFields: string[] = [
    'public_phone_description',
    'ext_data_location_auth_server',
    'ext_data_location_secure_auth_url',
    'Emergency Numbers',
    'stop_ringing_phone_delay',
    'call_forward_no_answer_ring_duration',
  ];

  constructor(
    private _endUserSearchResource: EndUserSearchResource,
    private _translateService: TranslateService,
    private _appUserSearchResource: AppUserSearchResource
  ) {}

  ngOnInit() {
    this.dataAutomation = this.serviceSetting.name.replace('_', '-') + '-service-setting';
    if (this.serviceSetting.name === 'IM Presence') {
      this.impDisabledTooltip = this._getImpDisabledTooltip();
    }
    this.dynamicServiceName = this._getDynamicServiceName();
    this._initFormConfig();

    let fields = [
      ...this.serviceSettingFieldIds.selects,
      ...this.serviceSettingFieldIds.multiSelects,
      ...this.serviceSettingFieldIds.customInputTexts,
      ...this.serviceSettingFieldIds.customSelects,
      ...this.serviceSettingFieldIds.customCheckboxes,
      ...this.serviceSettingFieldIds.primeLineCustomSelects,
      ...this.serviceSettingFieldIds.jabberTypeTextInputs,
      ...this.serviceSettingFieldIds.customMultiSelects,
      ...this.serviceSettingFieldIds.checkboxes,
      ...this.serviceSettingFieldIds.dataLocationTextInputs,
    ];

    if (this.dynamicServiceName === 'iphone' || this.dynamicServiceName === 'android') {
      fields = fields.concat(['mobility_identity_name', 'stop_ringing_phone_delay']);
    } else if (this.dynamicServiceName === 'singlenumberreach') {
      fields = fields.filter((field: string) => !this._isSnrHiddenField(field));
    }

    const sectionLinks = fields.map((field: string) => {
      return {
        dataAutomation: `${this.dynamicServiceName}-${field}`,
        label: getServiceFieldNameTkey(field),
        sectionName: this.serviceSetting.name,
        isCommon: this.commonFieldsState?.commonFields.includes(field),
      };
    });

    this.sideNavLinksUpdate$.emit(sectionLinks);
  }

  protected submit() {
    return of(null);
  }

  toggleService() {
    this.serviceSettingForm.toggleEnabled();
  }

  onFormUpdate(event: SmacsFormsUpdate<ServiceSetting>) {
    this.smacsFormsUpdate$.emit(event);
  }

  private _initFormConfig() {
    this.formConfigInput = {
      fields: {
        oneClickEnabled: {
          label: 'tkey;site_management.site.section.one_click_provisioning.label',
          dataAutomation: this._getDataPrefixByServiceName(this.serviceSetting.name) + 'one-click-enabled-checkbox',
          componentConfig: new SmacsCheckboxButtonConfig({
            size: ButtonSizes.NORMAL,
            icon: this.smacsIcons.ONE_CLICK,
            disabled: this.serviceSetting.name === 'IM Presence',
            disabledTooltip: 'tkey;admin.site_management.service_component.one_click_enabled.imp_disabled.tooltip',
          }),
          hidden: () =>
            this.serviceSetting.name === 'End User' ||
            this.serviceSetting.name === 'Translation Pattern' ||
            this.serviceSetting.name === 'Directory Number' ||
            this.serviceSetting.name === 'Line Features',
        },
        ...this._setAsyncMultiSelectsToFormConfig(),
        ...this._setCustomSelectsToFormConfig(),
        ...this._setTextInputsToFormConfig(),
        ...this._setSelectsToFormConfig(),
        ...this._setMultiSelectsToFormConfig(),
        ...this._setCustomCheckboxesToFormConfig(),
        ...this._setCheckboxesToFormConfig(),
      },
    };

    this.serviceSettingFieldIds = {
      customInputTexts: this.serviceSetting.customInputTexts
        .map((customInputText) => customInputText.name)
        .filter(
          (input) =>
            input !== 'mobility_identity_name' &&
            input !== 'stop_ringing_phone_delay' &&
            input !== 'cisco_support_field' &&
            input !== 'Emergency Numbers' &&
            input !== 'ext_data_location_auth_server' &&
            input !== 'ext_data_location_secure_auth_url'
        )
        .reverse(),
      customSelects: customSelectOrderedFields.filter(
        (fieldId) =>
          this.serviceSetting.customSelects.map((select) => select.name).includes(fieldId) &&
          fieldId !== 'always_use_prime_line' &&
          fieldId !== 'always_use_prime_line_for_vm'
      ),
      primeLineCustomSelects: this.serviceSetting.customSelects
        .map((customSelect) => customSelect.name)
        .filter((input) => input === 'always_use_prime_line' || input === 'always_use_prime_line_for_vm'),
      customMultiSelects: this.serviceSetting.customMultiSelects.map((multiSelect) => multiSelect.name),
      selects: this.serviceSetting.selects.map((select) => select.name),
      multiSelects: this.serviceSetting.multiSelects.map((multiSelect) => multiSelect.name),
      customCheckboxes: this.serviceSetting.customCheckboxes.map((customCheckbox) => customCheckbox.name),
      checkboxes: this.serviceSetting.checkboxes.map((checkbox) => checkbox.name),
      jabberTypeTextInputs: this.serviceSetting.customInputTexts
        .map((customInputText) => customInputText.name)
        .filter((input) => input === 'cisco_support_field' || input === 'Emergency Numbers'),
      dataLocationTextInputs: this.serviceSetting.customInputTexts
        .map((customInputText) => customInputText.name)
        .filter((input) => input === 'ext_data_location_auth_server' || input === 'ext_data_location_secure_auth_url'),
    };
    this.isLoading = false;
  }

  private _setTextInputsToFormConfig(): SmacsFieldConfigs {
    return this.serviceSetting.customInputTexts.reduce((acc: SmacsFieldConfigs, customInputText) => {
      acc[customInputText.name] = {
        label: getServiceFieldNameTkey(customInputText.name),
        labelToolTipIconClass: this._getIsLabelIconDisplayed(customInputText.name)
          ? `icon ${this.smacsIcons.INFO}`
          : undefined,
        labelToolTipText: this._getIsLabelIconDisplayed(customInputText.name)
          ? this._getLabelTooltip(customInputText.name)
          : undefined,
        dataAutomation:
          this._getDataPrefixByServiceName(this.serviceSetting.name) +
          customInputText.name.replace(/_/g, '-') +
          '-input',
        componentConfig: new ZiroCustomInputTextConfig({
          editorType: !this._hideEditorFields.some((fieldName) => fieldName === customInputText.name)
            ? VariableEditorType.CISCO
            : null,
          isAlwaysRequired: this._alwaysRequiredFields.some((field) => field === customInputText.name),
          hideCheckboxes: customInputText.name === 'directory_uri',
        }),
        validation: [
          {
            validator: (val: CustomInputText) => {
              if (
                this._isSnrHiddenField(customInputText.name) ||
                ((customInputText.name === 'mobility_identity_name' ||
                  customInputText.name === 'stop_ringing_phone_delay') &&
                  !this.serviceSettingForm.formData?.mobility_identity_management)
              ) {
                return SmacsFormsValidationState.VALID;
              }

              return val.required && !val.value.length && !val.show
                ? SmacsFormsValidationState.INVALID
                : SmacsFormsValidationState.VALID;
            },
            message: 'tkey;custom_input_text.value.error',
          },
          {
            validator: (val: CustomInputText) => {
              if (this._isSnrHiddenField(customInputText.name)) {
                return SmacsFormsValidationState.VALID;
              }

              return val.required && !val.value.length && this.serviceSettingForm.formData?.oneClickEnabled
                ? SmacsFormsValidationState.INVALID
                : SmacsFormsValidationState.VALID;
            },
            message: 'tkey;site_management.site.section.one_click_provisioning_blocked.error',
          },
          {
            validator: (val: CustomInputText) => {
              return !val.value ||
                val.value === '{{ mailId }}' ||
                /\S+@\S+\.\S+/.test(val.value) ||
                val.name !== 'directory_uri'
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID;
            },
            message: 'tkey;admin.email_configuration.modal.email.validator.message',
          },
        ],
        valExcluded: () =>
          (customInputText.name === 'mobility_identity_name' || customInputText.name === 'stop_ringing_phone_delay') &&
          !this.serviceSettingForm.formData?.mobility_identity_management,
        hidden: () => this._isSnrHiddenField(customInputText.name),
      };
      return acc;
    }, {});
  }

  private _setAsyncMultiSelectsToFormConfig(): SmacsFieldConfigs {
    return this.serviceSetting.customMultiSelects.reduce((acc: SmacsFieldConfigs, asyncMultiSelect) => {
      acc[asyncMultiSelect.name] = {
        label: getServiceFieldNameTkey(asyncMultiSelect.name),
        dataAutomation:
          this._getDataPrefixByServiceName(this.serviceSetting.name) +
          asyncMultiSelect.name.replace(/_/g, '-') +
          '-input',
        componentConfig: new ZiroAsyncMultiSelectConfig({
          options: asyncMultiSelect.defaultOptions,
          asyncOptionsFn: (searchTerm) =>
            asyncMultiSelect.name.includes('associated_end_users')
              ? this._searchAssociatedEndUsers(searchTerm)
              : this._searchAssociatedAppUsers(searchTerm),
          showAutoGenerationLink: true,
          hideSelected: true,
        }),
        validation: [
          {
            validator: (values: CustomMultiSelect) => {
              if (!values.defaultOptions.length) {
                this.validatedAsyncSelectFields.push(asyncMultiSelect.name);
              }
              if (this.validatedAsyncSelectFields.includes(asyncMultiSelect.name)) {
                return SmacsFormsValidationState.VALID;
              }
              const validations = values.defaultOptions.map((val) => {
                return asyncMultiSelect.name.includes('associated_end_users')
                  ? this._searchAssociatedEndUsers(val).pipe(
                      map((results) => {
                        if (!results.length) {
                          this.invalidAsyncOptions.push({ fieldName: asyncMultiSelect.name, optionName: val });
                          return false;
                        } else {
                          return true;
                        }
                      })
                    )
                  : this._searchAssociatedAppUsers(val).pipe(
                      map((results) => {
                        if (!results.length) {
                          this.invalidAsyncOptions.push({ fieldName: asyncMultiSelect.name, optionName: val });
                          return false;
                        } else {
                          return true;
                        }
                      })
                    );
              });
              return forkJoin(validations).pipe(
                map((results) => {
                  if (results.every((valueIsValid) => valueIsValid)) {
                    this.validatedAsyncSelectFields.push(asyncMultiSelect.name);
                    return SmacsFormsValidationState.VALID;
                  } else {
                    return SmacsFormsValidationState.INVALID;
                  }
                })
              );
            },
            message: () => {
              const invalidOptions = this.invalidAsyncOptions
                .filter((opt) => opt.fieldName === asyncMultiSelect.name)
                .map((filteredOpt) => filteredOpt.optionName);
              const translation = this._translateService.instant('tkey;select.invalid_options.label');
              return `${translation} ${this._arrayToString(invalidOptions)}`;
            },
          },
        ],
        autogeneration: {
          linkLabel: 'tkey;shared.html.customizable_fields.text_input.fix_it.label',
          generateValue: (val: CustomMultiSelect) => {
            const newValue: CustomMultiSelect = {
              ...val,
              defaultOptions: val.defaultOptions.filter(
                (user) =>
                  !this.invalidAsyncOptions.some(
                    (opt) => opt.optionName === user && opt.fieldName === asyncMultiSelect.name
                  )
              ),
            };
            return newValue;
          },
          hidden: () =>
            !!this.serviceSettingForm.formData[asyncMultiSelect.name] &&
            this.serviceSettingForm.validationFlags[asyncMultiSelect.name] !== SmacsFormsValidationState.INVALID,
          inline: true,
        },
      };
      return acc;
    }, {});
  }

  private _setCustomSelectsToFormConfig(): SmacsFieldConfigs {
    return this.serviceSetting.customSelects.reduce((acc: SmacsFieldConfigs, customSelect) => {
      acc[customSelect.name] = {
        label: getServiceFieldNameTkey(customSelect.name),
        dataAutomation:
          this._getDataPrefixByServiceName(this.serviceSetting.name) + customSelect.name.replace(/_/g, '-') + '-input',
        required: this._alwaysRequiredFields.some((field) => field === customSelect.name) || customSelect.required,
        componentConfig: new ZiroCustomSelectConfig({
          availableOptions: this._getAvailableOptionsForSelect(customSelect.name, customSelect.possibleOptions),
          isAlwaysRequired: this._alwaysRequiredFields.some((field) => field === customSelect.name),
          displayValues: this.serviceSetting.customSelects
            .filter((select) => select.name === customSelect.name)
            .map((customSelectFiltered: CustomSelect) => {
              return customSelectFiltered.defaultOption
                ? [
                    customSelectFiltered.defaultOption,
                    ...customSelectFiltered.possibleOptions.filter(
                      (option) => option !== customSelectFiltered.defaultOption
                    ),
                  ]
                : customSelectFiltered.possibleOptions;
            })
            .flat(),
          displayLabel: getServiceFieldNameTkey(customSelect.name),
        }),
        valExcluded: () => this.serviceSettingForm?.entity.enabled === EnabledType.DISABLED,
        validation: [
          {
            validator: (val: CustomSelect) => {
              if (val.required && !val.defaultOption && !val.show) {
                return SmacsFormsValidationState.INVALID;
              }
              return SmacsFormsValidationState.VALID;
            },
            message: 'tkey;custom_select.default_value.error',
          },
          {
            validator: (val: CustomSelect, oneClickEnabled: boolean) => {
              if (val.required && !val.defaultOption && oneClickEnabled) {
                return SmacsFormsValidationState.INVALID;
              } else {
                return SmacsFormsValidationState.VALID;
              }
            },
            message: 'tkey;site_management.site.section.one_click_provisioning_blocked.error',
            injectValuesFromFields: ['oneClickEnabled'],
          },
          {
            validator: (val: CustomSelect) => {
              return val.possibleOptions.every((value) =>
                this._getAvailableOptionsForSelect(customSelect.name, customSelect.possibleOptions).includes(value)
              )
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID;
            },
            message: () => {
              const possibleOptions = (this.serviceSettingForm.formData[customSelect.name] as CustomSelect)
                ?.possibleOptions;
              const invalidOptions = possibleOptions.filter(
                (value) =>
                  !this._getAvailableOptionsForSelect(customSelect.name, customSelect.possibleOptions).includes(value)
              );

              const translation = this._translateService.instant('tkey;select.invalid_options.label');

              return `${translation} ${this._arrayToString(invalidOptions)}`;
            },
          },
          {
            validator: (val: CustomSelect, oneClickEnabled: boolean) => {
              if (
                val &&
                val.required &&
                !val.defaultOption &&
                !oneClickEnabled &&
                val.possibleOptions.length === 0 &&
                val.show
              ) {
                return SmacsFormsValidationState.INVALID;
              }
              return SmacsFormsValidationState.VALID;
            },
            message: 'tkey;validators.global.required.error',
            injectValuesFromFields: ['oneClickEnabled'],
          },
          {
            validator: (val: CustomSelect) => {
              if (!val.show && val.possibleOptions.length > 1) {
                return SmacsFormsValidationState.WARNING;
              }
              return SmacsFormsValidationState.VALID;
            },
            message: 'tkey;custom_select.show.multiple_values.warning',
          },
        ],
        autogeneration: {
          linkLabel: 'tkey;shared.html.customizable_fields.text_input.fix_it.label',
          generateValue: (fieldValue) => {
            return fieldValue;
          },
          hidden: (val) => {
            const possibleOptions = (val as CustomSelect).possibleOptions;
            const availableOptions = this._getAvailableOptionsForSelect(
              customSelect.name,
              customSelect.possibleOptions
            );
            return possibleOptions.every((option) => availableOptions.includes(option));
          },
          inline: true,
        },
      };
      return acc;
    }, {});
  }

  private _setSelectsToFormConfig(): SmacsFieldConfigs {
    const requiredFields = ['devicepool', 'preferred_protocol', 'preferred_security_profile_mode', 'unity_server'];

    return this.serviceSetting.selects.reduce((acc: SmacsFieldConfigs, select) => {
      acc[select.name] = {
        label: getServiceFieldNameTkey(select.name),
        dataAutomation: select.name.replace(/_/g, '-') + '-input',
        componentConfig: new SmacsSelectConfig({
          options: this._getAvailableOptionsForSelect(select.name, []),
          hideClear: false,
          placeholder: this._getPlaceholderForSelect(select.name),
        }),
        validation: this._getSingleSelectValidation(select.name),
        helpText: this._getHelpText(select.name),
        required: () => requiredFields.includes(select.name),
      };
      return acc;
    }, {});
  }

  private _setMultiSelectsToFormConfig(): SmacsFieldConfigs {
    return this.serviceSetting.multiSelects
      .filter((multiSelect) => multiSelect.name === 'end_user_group' || multiSelect.name === 'phone_service')
      .reduce((acc: SmacsFieldConfigs, multiSelect) => {
        const options = this._getAvailableOptionsForSelect(multiSelect.name, []);

        acc[multiSelect.name] = {
          label: getServiceFieldNameTkey(multiSelect.name),
          labelToolTipIconClass: this._getIsLabelIconDisplayed(multiSelect.name)
            ? `icon ${this.smacsIcons.INFO}`
            : undefined,
          labelToolTipText: this._getIsLabelIconDisplayed(multiSelect.name)
            ? this._getLabelTooltip(multiSelect.name)
            : undefined,
          dataAutomation: multiSelect.name.replace(/_/g, '-') + '-input',
          componentConfig: new SmacsSelectConfig({ options: options, isMultiSelect: true }),
        };
        return acc;
      }, {});
  }

  private _setCustomCheckboxesToFormConfig(): SmacsFieldConfigs {
    // Right now disable speaker phone is the only custom checkbox
    return this.serviceSetting.customCheckboxes.reduce((acc: SmacsFieldConfigs, customCheckbox) => {
      acc[customCheckbox.name] = {
        label: getServiceFieldNameTkey(customCheckbox.name),
        dataAutomation: customCheckbox.name.replace(/_/g, '-') + '-input',
        componentConfig: new SmacsMultiCheckboxConfig({
          multiCheckboxOptionConfig: [
            {
              label: 'tkey;site_management.site.section.checkbox.enable',
            },
            {
              label: 'tkey;site_management.site.section.show.label',
            },
          ],
        }),
      };
      return acc;
    }, {});
  }

  private _setCheckboxesToFormConfig(): SmacsFieldConfigs {
    return this.serviceSetting.checkboxes.reduce((acc: SmacsFieldConfigs, checkbox) => {
      if (checkbox.name === 'mobility_identity_management') {
        acc[checkbox.name] = {
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
          label: 'tkey;site_management.site.section.field.mobility_identity_management.text',
          labelToolTipText: 'tkey;admin.site_management.mobility_identity.tooltip',
          labelToolTipIconClass: `${this.smacsIcons.INFO} top-0`,
          dataAutomation: 'mobility-identity-switch',
        };
      } else {
        acc[checkbox.name] = {
          label: getServiceFieldNameTkey(checkbox.name),
          dataAutomation: checkbox.name.replace(/_/g, '-') + '-input',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.CHECKBOX }),
          hidden: () => this._isSnrHiddenField(checkbox.name),
        };
      }
      return acc;
    }, {});
  }

  private _searchAssociatedEndUsers = (searchTerm: string): Observable<SmacsSelectOption[]> => {
    return this._endUserSearchResource.searchByQ(searchTerm, this.clusterResult.cucmServerId, 100).pipe(
      map((endUserResults: EndUserResult[]) =>
        endUserResults.map<SmacsSelectOption>((result: EndUserResult) => ({
          label: `${result.ref.firstName} ${result.ref.lastName} - ${result.ref.username}`,
          value: result.ref.username,
        }))
      )
    );
  };

  private _searchAssociatedAppUsers = (searchTerm: string): Observable<SmacsSelectOption[]> => {
    return this._appUserSearchResource.searchByQ(searchTerm, this.clusterResult.cucmServerId, 100).pipe(
      map((endUserResults: AppUserResult[]) =>
        endUserResults.map<SmacsSelectOption>((result: AppUserResult) => ({
          label: `${result.ref.username}`,
          value: result.ref.username,
        }))
      )
    );
  };

  private _getAvailableOptionsForSelect(fieldName: string, possibleOptions: string[]): string[] {
    let selectedUnityServer: string;
    let serverOption: number;
    if (fieldName === 'voicemail_template') {
      selectedUnityServer = this.serviceSetting.selects.find((select) => select.name === 'unity_server').value;
      if (!!selectedUnityServer) {
        serverOption = this.unityServerOptions.find((server) => server.label === selectedUnityServer)?.value;
      }
    }
    switch (fieldName) {
      // Phone custom selects
      case 'phone_model':
        return sortBy(this.cucmMetadata.phoneModels);
      case 'common_phone_profile':
        return sortBy(this.cucmMetadata.commonPhoneProfiles.map((profile) => profile.name));
      case 'media_resource_group_list':
        return sortBy(this.cucmMetadata.mediaResourceGroupLists);
      case 'sip_profile_name':
        return sortBy(this.cucmMetadata.sipProfileNames);
      case 'built_in_bridge':
        return sortBy(this.cucmMetadata.builtInBridges);
      case 'user_locale':
        return sortBy(this.cucmMetadata.userLocales);
      case 'location':
        return sortBy(this.cucmMetadata.locations);
      case 'network_moh_audio_source':
        return sortBy(this.cucmMetadata.musicOnHoldAudioSources);
      case 'user_moh_audio_source':
        return sortBy(this.cucmMetadata.musicOnHoldAudioSources);
      case 'aar_calling_search_space':
        return sortBy(this.cucmMetadata.callingSearchSpaces);
      case 'calling_search_space':
        return sortBy(this.cucmMetadata.callingSearchSpaces);
      case 'subscribe_css':
        return sortBy(this.cucmMetadata.callingSearchSpaces);
      case 'softkey_template':
        return sortBy(this.cucmMetadata.softKeyTemplates);
      case 'feature_control_policy':
        return sortBy(this.cucmMetadata.featureControlPolicies);
      case 'always_use_prime_line_for_vm':
      case 'always_use_prime_line':
      case 'device_mobility_mode':
      case 'privacy':
        return this.cucmMetadata.defaultOnOffOptions;
      // Single Selects
      case 'preferred_protocol':
        return ['SIP', 'SCCP', 'No Preference - User must select the protocol when model supports both'];
      case 'devicepool':
        return sortBy(this.cucmMetadata.devicePools);
      case 'preferred_security_profile_mode':
        return ['Encrypted', 'Authenticated', 'Non-Secure'];
      // Multiselect
      case 'phone_services':
        return sortBy(Object.keys(this.cucmMetadata.phoneServices));
      case 'end_user_group':
        return sortBy(this.cucmMetadata.endUserGroups);
      // Voicemail
      case 'voicemail_profile':
        return sortBy(this.cucmMetadata.voicemailProfiles);
      case 'no_voicemail_profile':
        return sortBy(this.cucmMetadata.voicemailProfiles);
      case 'unity_server':
        return sortBy(this.unityServerOptions.map((server) => server.label));
      case 'voicemail_template':
        return sortBy(this.unityMetadata[serverOption]?.voicemailTemplates || []);
      // Directory number
      case 'call_pickup_group':
        return sortBy(this.cucmMetadata.callPickupGroups);
      case 'call_forward_calling_search_space':
        return sortBy(this.cucmMetadata.callingSearchSpaces);
      case 'call_forward_secondary_calling_search_space_for_forward_all':
        return sortBy(this.cucmMetadata.callingSearchSpaces);
      // End User
      case 'uc_service_profile':
        return sortBy(this.cucmMetadata.ucServiceProfiles);
      case 'uc_service_profile_without_voicemail':
        return sortBy(this.cucmMetadata.ucServiceProfiles);
      // SNR
      case 'rerouting_calling_search_space':
        return sortBy(this.cucmMetadata.callingSearchSpaces);
      // Line features
      case 'idle_ring_setting':
        return sortBy(this.cucmMetadata.idleRingSettings);
      case 'active_ring_Setting':
        return sortBy(this.cucmMetadata.activeRingSettings);
      case 'call_recording_option':
        return sortBy(this.cucmMetadata.callRecordingOptions);
      case 'call_recording_profile':
        return sortBy(this.cucmMetadata.callRecordingProfiles);
      case 'call_recording_media_source':
        return ['Gateway Preferred', 'Phone Preferred'];
      default:
        return sortBy(possibleOptions);
    }
  }

  private _getPlaceholderForSelect(fieldName: string): string {
    switch (fieldName) {
      case 'devicepool': {
        return 'tkey;site_management.site.section.device_pool.placeholder';
      }
      default: {
        return null;
      }
    }
  }

  private _getInvalidOptionValidation(selectName: string): SmacsFormsValidationConfig {
    return [
      {
        validator: (val: string) =>
          !val || this._getAvailableOptionsForSelect(selectName, []).some((option) => option === val)
            ? SmacsFormsValidationState.VALID
            : SmacsFormsValidationState.INVALID,
        message: 'tkey;site_management.site.section.invalid_option.label.error',
      },
    ];
  }

  private _getDevicePoolInUseValidation(): SmacsFormsValidationConfigItem {
    let inputValue;
    return {
      validator: (val: string) => {
        inputValue = val;
        if (
          this.clusterResult.sites.some(
            (siteResult) =>
              siteResult.devicePools.some((devicePoolResult) => devicePoolResult.devicePool === val) &&
              siteResult.name !== this.siteName
          )
        ) {
          if (this.serviceSetting.name === 'Desk Phone') {
            return SmacsFormsValidationState.INVALID;
          } else {
            return SmacsFormsValidationState.WARNING;
          }
        } else {
          return SmacsFormsValidationState.VALID;
        }
      },
      message: this._getDevicePoolInUseFeedbackText(inputValue),
    };
  }

  private _getDevicePoolInUseFeedbackText(inputValue: string): string {
    const conflictingSite = this.clusterResult.sites.find((siteResult) =>
      siteResult.devicePools.some(
        (devicePoolResult) => devicePoolResult.devicePool === inputValue && siteResult.name !== this.siteName
      )
    );
    if (!!conflictingSite) {
      return this._translateService.instant('tkey;site_management.site.section.device_pool.error', {
        name: conflictingSite.name,
      });
    } else {
      return 'tkey;site_management.site.section.device_pool.warning';
    }
  }

  private _getPreferredProtocolValidation(): SmacsFormsValidationConfigItem {
    return {
      validator: (val: string) => {
        const oneClickEnabled = this.serviceSettingForm?.formData.oneClickEnabled;
        return oneClickEnabled && val?.includes('No Preference')
          ? SmacsFormsValidationState.INVALID
          : SmacsFormsValidationState.VALID;
      },
      message: 'tkey;site_management.site.section.one_click_provisioning_blocked.error',
    };
  }

  private _getSingleSelectValidation(selectName: string): SmacsFormsValidationConfig {
    switch (selectName) {
      case 'devicepool':
        return this._getInvalidOptionValidation(selectName).concat(this._getDevicePoolInUseValidation());
      case 'preferred_protocol':
        return this._getInvalidOptionValidation(selectName).concat(this._getPreferredProtocolValidation());
      default:
        return this._getInvalidOptionValidation(selectName);
    }
  }

  private _getHelpText(fieldName: string) {
    switch (fieldName) {
      case 'uc_service_profile_without_voicemail':
        return () =>
          !!this.serviceSettingForm?.formData['uc_service_profile_without_voicemail']
            ? ''
            : 'tkey;site_management.site.section.end_user.uc_service_profile.helptext';
      case 'uc_service_profile':
        return () =>
          !!this.serviceSettingForm?.formData['uc_service_profile']
            ? ''
            : 'tkey;site_management.site.section.end_user.uc_service_profile.helptext';
      default:
        return () => '';
    }
  }
  private _getDynamicServiceName(): string {
    return this.serviceSetting.name.replace(/\s/g, '').trim().toLowerCase();
  }

  private _getImpDisabledTooltip() {
    const enabled = this.serviceSetting.enabled === EnabledType.ENABLED ? 'enabled' : 'disabled';
    const state = this.serviceSetting.enabled === EnabledType.ENABLED ? 'is' : 'is not';
    return this._translateService.instant('tkey;admin.site_management.service_component.imp_disabled.tooltip', {
      enabled,
      state,
    });
  }

  private _getIsLabelIconDisplayed(inputName: string): boolean {
    return inputName === 'phone_service' || inputName === 'smtp_notification_device';
  }

  private _getLabelTooltip(inputName: string): string {
    switch (inputName) {
      case 'phone_service':
        return 'tkey;admin.site_management.phone_services.tooltip';
      case 'smtp_notification_device':
        return 'tkey;admin.site_management.smtp_notification_device.tooltip';
      default:
        return '';
    }
  }

  private _isSnrHiddenField(inputName: string) {
    return (
      inputName === 'destination_number' ||
      inputName === 'snr_destination' ||
      inputName === 'delay_before_ringing_in_seconds' ||
      inputName === 'answer_too_soon_timer_in_seconds' ||
      inputName === 'answer_too_late_timer_in_seconds'
    );
  }

  private _arrayToString(values: string[]): string {
    return values ? values.join(', ') : '';
  }

  private _getDataPrefixByServiceName(serviceName: string): string {
    switch (serviceName) {
      case 'Android':
        return ServiceNameDataPrefix.ANDROID;
      case 'CIPC':
        return ServiceNameDataPrefix.CIPC;
      case 'Desk Phone':
        return ServiceNameDataPrefix.DESK_PHONE;
      case 'Directory Number':
        return ServiceNameDataPrefix.DIRECTORY_NUMBER;
      case 'End User':
        return ServiceNameDataPrefix.END_USER;
      case 'Extension Mobility':
        return ServiceNameDataPrefix.EXTENSION_MOBILITY;
      case 'IM Presence':
        return ServiceNameDataPrefix.IM_PRESENCE;
      case 'IM Softphone':
        return ServiceNameDataPrefix.IM_SOFTPHONE;
      case 'IPhone':
        return ServiceNameDataPrefix.IPHONE;
      case 'Line Features':
        return ServiceNameDataPrefix.LINE_FEATURES;
      case 'Single Number Reach':
        return ServiceNameDataPrefix.SINGLE_NUMBER_REACH;
      case 'Tablet':
        return ServiceNameDataPrefix.TABLET;
      case 'Translation Pattern':
        return ServiceNameDataPrefix.TRANSLATION_PATTERN;
      case 'Voicemail':
        return ServiceNameDataPrefix.VOICEMAIL;
      default:
        return '';
    }
  }

  protected readonly getServiceNameTkey = getServiceNameTkey;
}
