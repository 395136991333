<smacs-text *ngFor="let fieldId of fieldGroups.textInputs" [fieldId]="fieldId"></smacs-text>

<smacs-multi-text *ngFor="let fieldId of fieldGroups.multiTextInputs" [fieldId]="fieldId"></smacs-multi-text>

<div class="text-end">
  <smacs-submit-button
    [isPending]="isSubmitting"
    [isDisabled]="isSubmitting"
    [label]="'tkey;certificate_management.generate_csr.text'"
    [dataAutomation]="'admin-generate-csr-button'"
    [iconPending]="smacsIcons.SAVING"
    [formSubmitSubject]="_validateAndSubmitSource"
  ></smacs-submit-button>
</div>
