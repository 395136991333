import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { SmacsFieldAbstractDirective } from '../../../../forms/smacs-field-abstract.directive';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsIcons } from '../../../models/smacs-icons.enum';
import { isEqual, isNil } from 'lodash';
import {
  SmacsFieldComponentConfig,
  SmacsFormConfig,
  SmacsFormsUpdate,
  SmacsFormsValidationState,
} from '../../../../forms/smacs-forms-models';
import {
  SmacsNavsetDropdownItemConfig,
  SmacsNavsetFormItemConfig,
  SmacsNavsetItemNavItem,
} from '../smacs-navset-form.component';
import { SmacsNavsetFormNavItemsComponent } from './smacs-navset-form-nav-items/smacs-navset-form-nav-items.component';
import { Subscription } from 'rxjs';
import { SmacsNavsetItemResults } from '../../smacs-navset.component';

type SmacsNavsetAnimationState = 'animating' | 'rendered';

export class SmacsNavsetItemNavConfig extends SmacsFieldComponentConfig {
  constructor(
    public config: {
      dropdownLabel: string;
      dropdownTooltip: string;
      items: SmacsNavsetFormItemConfig;
      dropdownItems: SmacsNavsetDropdownItemConfig[];
      allowDuplicates: boolean;
      emptyTemplateMessage: string;
      isOneClickAddDropdown?: boolean;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-navset-form-nav',
  templateUrl: './smacs-navset-form-nav.component.html',
  styleUrls: ['./smacs-navset-form-nav.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsNavsetFormNavComponent }],
  animations: [
    trigger('inOutState', [
      state(
        'show',
        style({
          opacity: 1,
          position: 'static',
        })
      ),
      state(
        'hide',
        style({
          opacity: 0,
          position: 'absolute',
          zIndex: -9999,
          top: '0',
          left: '15px',
          width: 'calc(100% - 30px)',
        })
      ),
      transition('show => hide', animate('0.25s ease-out')),
      transition('hide => show', animate('0.25s 0.25s ease-in')),
    ]),
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.25s 0.1s ease-in', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('0s', style({ opacity: 0 }))]),
    ]),
    trigger('messageAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('0.25s 0.1s ease-in', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('0s', style({ opacity: 0 }))]),
    ]),
    trigger('addNavItem', [
      transition(':enter', [
        animate(
          '.6s',
          keyframes([
            style({
              transform: 'perspective(400px) rotate3d(1, 0, 0, 90deg)',
              opacity: 0,
              animationTimingFunction: 'ease-in',
            }),
            style({
              transform: 'perspective(400px) rotate3d(1, 0, 0, -20deg)',
              animationTimingFunction: 'ease-in',
              opacity: 1,
            }),
            style({ transform: 'perspective(400px) rotate3d(1, 0, 0, 10deg)', opacity: 1 }),
            style({ transform: 'perspective(400px) rotate3d(1, 0, 0, -5deg)' }),
            style({ transform: 'perspective(400px)' }),
          ])
        ),
      ]),
      transition(':leave', [
        animate(
          '.2s',
          keyframes([
            style({ transform: 'perspective(400px)', offset: 0 }),
            style({
              transform: 'perspective(400px) rotate3d(1, 0, 0, -20deg)',
              opacity: 1,
              offset: 0.2,
            }),
            style({
              transform: 'perspective(400px) rotate3d(1, 0, 0, 90deg)',
              opacity: 0,
              offset: 0.7,
            }),
            style({
              transform: 'perspective(400px) rotate3d(1, 0, 0, 90deg)',
              opacity: 0,
              offset: 1,
            }),
          ])
        ),
      ]),
    ]),
  ],
})
export class SmacsNavsetFormNavComponent
  extends SmacsFieldAbstractDirective<SmacsNavsetItemNavItem[], SmacsNavsetItemNavItem[], SmacsNavsetItemNavConfig>
  implements OnChanges, OnDestroy
{
  navItemAnimationState: SmacsNavsetAnimationState = 'rendered';
  active = 0;
  icons = SmacsIcons;
  allowDuplicate = true;
  searchFilter = '';
  dropdownItems: SmacsNavsetDropdownItemConfig[] = [];
  tooltipMessage: string;
  dropdownLabel: string;
  fieldConfiguration: SmacsFormConfig[] = [];
  emptyTemplateMessage = '';
  isOneClickAddDropdown = false;

  // Needed to prevent the entire navset from animating whenever we use applyConfig in SmacsNavsetComponent
  // Animation is only enabled after we're adding/removing navsetItems or selecting an item
  @HostBinding('@.disabled')
  disableAnimation = true;

  private _items: SmacsNavsetFormItemConfig = {};
  private _subscriptions = new Subscription();

  @Input() allowedDropdownItemNames: string[];
  @Input() upstreamEntity: SmacsNavsetItemResults;
  @Input() isFormSubmitted: boolean;
  @ViewChildren(SmacsNavsetFormNavItemsComponent) formNavItems: QueryList<SmacsNavsetFormNavItemsComponent>;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  applyComponentConfig = ({ config }: SmacsNavsetItemNavConfig) => {
    this.dropdownLabel = config.dropdownLabel || this.dropdownLabel;
    this.tooltipMessage = config.dropdownTooltip || this.tooltipMessage;
    this._items = isNil(config.items) ? this._items : config.items;
    this.dropdownItems = isNil(config.dropdownItems) ? this.dropdownItems : config.dropdownItems;
    this.allowDuplicate = isNil(config.allowDuplicates) ? this.allowDuplicate : config.allowDuplicates;
    this.emptyTemplateMessage = this.emptyTemplateMessage || config.emptyTemplateMessage;
    this.isOneClickAddDropdown = config.isOneClickAddDropdown || this.isOneClickAddDropdown;

    // If we've received an entity, set up the field data
    const selfUpdate = this.selfUpdate$.subscribe((update) => {
      if (update.length === 0 && this.upstreamEntity) {
        this._setFieldDataFromUpstreamEntity(this.upstreamEntity);
      }
    });
    this._subscriptions.add(selfUpdate);
  };

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    // If we're updating what is allowed, set up the fields again in case there has been a change in the navset items
    // See implementation of PcceAgentForm
    if (changes.allowedDropdownItemNames && this.fieldData) {
      this.setFormConfiguration();
    }
    if (changes.isFormSubmitted?.currentValue) {
      this.showValidation = true;
      this.fieldData.forEach((item) => (item.showValidation = true));
    }
  }

  // This getter is making sure we exclude invalid dropdown items, if allowed dropdown items has data
  // Otherwise every dropdown item is valid
  get validDropdownItems() {
    return this.allowedDropdownItemNames.length
      ? this.dropdownItems.filter((dropdownItem: SmacsNavsetDropdownItemConfig) => {
          return this.allowedDropdownItemNames.includes(dropdownItem.id);
        })
      : this.dropdownItems;
  }

  // Check every navset form item to make sure its form is valid
  reevaluate() {
    this.formNavItems.forEach((navItem: SmacsNavsetFormNavItemsComponent) => navItem.validate());
    this.updateParent();
  }

  // Based on the given entity, set up the field data
  private _setFieldDataFromUpstreamEntity = (upstreamEntity: SmacsNavsetItemResults) => {
    const fieldData: SmacsNavsetItemNavItem[] = [];

    Object.entries(upstreamEntity).forEach((value: [string, any[]]) => {
      const id = value[0];
      const entriesForParticularId = value[1];

      if (Array.isArray(entriesForParticularId)) {
        entriesForParticularId.forEach((fields) => {
          fieldData.push({
            id,
            badgeId: this._items[id].badgeFieldId,
            labelId: this._items[id].labelFieldId,
            fields,
            hasError: false,
            showValidation: false,
            defaultLabel: this._items[id].label,
            index: this.fieldData.filter((data: SmacsNavsetItemNavItem) => data.id === id).length,
          });
        });
      } else {
        const fields = { [id]: value[1] } as any;
        fieldData.push({
          id,
          badgeId: this._items[id].badgeFieldId,
          labelId: this._items[id].labelFieldId,
          fields,
          hasError: false,
          showValidation: false,
          defaultLabel: this._items[id].label,
          index: this.fieldData.filter((data: SmacsNavsetItemNavItem) => data.id === id).length,
        });
      }
      // If duplicates aren't allowed, we should remove the dropdown item for the particular entry
      if (!this.allowDuplicate) {
        this.dropdownItems = this.dropdownItems.filter(
          (item: SmacsNavsetDropdownItemConfig) => item.label !== this._items[id].label
        );
      }
      this.updateSelf(fieldData, false);
    });
    this.setFormConfiguration();
  };

  // This is done to set up the data-automation attribute for the navset list to denote whether the list is currently
  // animating. Otherwise, without the appropriate data-automation attribute, our tests will fail as Angular Animation
  // only fully renders the HTML once the animation is done
  handleNavsetItemAnimationStateChange(animationState: SmacsNavsetAnimationState) {
    setTimeout(() => {
      this.navItemAnimationState = animationState;
    });
  }

  shouldShowBadge(navsetItemBadgeValue: any) {
    return !isNil(navsetItemBadgeValue) && navsetItemBadgeValue !== '';
  }

  // Reset the indices of the field data
  private _resetIndices() {
    this.fieldData.reduce((cumulative: SmacsNavsetItemNavItem[], currentValue: SmacsNavsetItemNavItem) => {
      currentValue.index = cumulative.filter((item: SmacsNavsetItemNavItem) => item.id === currentValue.id).length;
      cumulative.push(currentValue);
      return cumulative;
    }, []);
  }

  add(event: MouseEvent, itemNameToAdd: SmacsNavsetDropdownItemConfig) {
    this.disableAnimation = false;
    if (event) {
      event.preventDefault();
    }
    const configToAddToNavset = this._items[itemNameToAdd.id];
    const currentCount = this.fieldData.filter((item: SmacsNavsetItemNavItem) => item.id === itemNameToAdd.id).length;

    if (!this.allowDuplicate) {
      this.dropdownItems = this.dropdownItems.filter(
        (item: SmacsNavsetDropdownItemConfig) => item.id !== itemNameToAdd.id
      );
    }

    const newNavsetItem: { [p: string]: string } = {};
    configToAddToNavset.fields.forEach((field) => {
      if (typeof configToAddToNavset.defaultValue === 'function') {
        newNavsetItem[field.dataAutomation] = configToAddToNavset.defaultValue()[field.dataAutomation];
      } else {
        newNavsetItem[field.dataAutomation] = configToAddToNavset.defaultValue
          ? configToAddToNavset.defaultValue[field.dataAutomation]
          : '';
      }
    });

    const newItem: SmacsNavsetItemNavItem = {
      id: itemNameToAdd.id,
      index: currentCount,
      defaultLabel: configToAddToNavset.label,
      fields: newNavsetItem,
      hasError: false,
      showValidation: false,
      badgeId: configToAddToNavset.badgeFieldId,
      labelId: configToAddToNavset.labelFieldId,
    };

    this.updateSelf([...this.fieldData, newItem], false);
    this.setFormConfiguration();
    this.changeActiveTab(this.fieldData.length - 1);
    this.updateParent();
  }

  // Sets up an array of form configs for each element in field data
  // This is to be consumed by SmacsNavsetFormNavItemsComponent
  setFormConfiguration() {
    this.fieldConfiguration = this.fieldData.map((item) => {
      return {
        fields: this._items[item.id].fields.reduce((accumulator: { [key: string]: any }, current) => {
          accumulator[current['dataAutomation']] = current;
          return accumulator;
        }, {}),
      } as SmacsFormConfig;
    });
  }

  close(event: MouseEvent, indexToRemove: number) {
    this.disableAnimation = false;

    const removedItem: SmacsNavsetItemNavItem = this.fieldData.splice(indexToRemove, 1)[0];
    this.setFormConfiguration();

    if (this.active > indexToRemove) {
      this.active--;
    } else if (this.active > this.fieldData.length - 1) {
      this.active--;
    } else if (this.active < 0) {
      this.active = 0;
    }

    this._resetIndices();

    if (!this.allowDuplicate) {
      this.dropdownItems.push({ id: removedItem.id, label: removedItem.defaultLabel });
    }

    if (this.fieldData.length === 0) {
      this.validationState = SmacsFormsValidationState.VALID;
    }
    this.reevaluate();
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  changeActiveTab(newActiveTab: number) {
    this.disableAnimation = false;
    this.active = newActiveTab;
  }

  getFilteredList(): SmacsNavsetDropdownItemConfig[] {
    return this.validDropdownItems.filter((dropdownItem: SmacsNavsetDropdownItemConfig) => {
      return dropdownItem.label.toLowerCase().includes(this.searchFilter.toLowerCase().trim());
    });
  }

  onChanged(newValue: string) {
    this.searchFilter = newValue || '';
  }

  handleItemUpdate($event: SmacsFormsUpdate<any>, index: number) {
    if ((!isEqual($event.new, $event.old) && $event.old) || this.isFormSubmitted === true) {
      this.fieldData[index].showValidation = true;
    }
    this.fieldData[index].hasError = $event.valid === SmacsFormsValidationState.INVALID;
    this.fieldData[index].fields = $event.new;
    this.validationState = $event.valid;
    this.updateParent();
  }

  // Used primarily to hide the navset item that is fading out, in order to prevent situations where we might be
  // able to tab into the form without even seeing it
  handleAnimationEnd(index: number, animationState: string) {
    const item = this.formNavItems.filter(
      (navItem: SmacsNavsetFormNavItemsComponent, itemIndex: number) => itemIndex === index
    );
    if (item[0]) {
      setTimeout(() => {
        item[0].isHidden = animationState === 'hide';
      });
    }
  }

  // Used primarily to hide the navset item that is fading out, in order to prevent situations where we might be
  // able to tab into the form without even seeing it
  handleAnimationStart(index: number, animationState: string) {
    const item = this.formNavItems.filter(
      (navItem: SmacsNavsetFormNavItemsComponent, itemIndex: number) => itemIndex === index
    );
    if (animationState === 'show' && item[0]) {
      setTimeout(() => {
        item[0].isHidden = false;
      });
    }
  }
}
