import { Component, Input, ViewChild } from '@angular/core';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import {
  SmacsFieldComponentConfig,
  SmacsFormsUpdate,
  SmacsFormsValidationConfigItem,
  SmacsFormsValidationState,
} from '../../../forms/smacs-forms-models';
import { SmacsIcons } from '../../models/smacs-icons.enum';
import { SmacsFieldAbstractDirective } from '../../../forms/smacs-field-abstract.directive';
import { TextFieldConfig } from '../../models/generated/smacsModels';
import { TextConfigFormComponent } from './text-config-form/text-config-form.component';
import { cloneDeep, isEqual, isNil } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

export class SmacsTextFieldConfig extends SmacsFieldComponentConfig {
  constructor(public config?: { displayEditor?: boolean; extraValidation?: SmacsFormsValidationConfigItem[] }) {
    super();
  }
}

@Component({
  selector: 'smacs-text-config',
  templateUrl: './legacy-smacs-text-config.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: legacySmacsTextConfigComponent }],
})
export class legacySmacsTextConfigComponent extends SmacsFieldAbstractDirective<
  TextFieldConfig,
  TextFieldConfig,
  SmacsTextFieldConfig
> {
  @ViewChild(TextConfigFormComponent) childFormComponent: TextConfigFormComponent;

  @Input() isOneClickEnabled: boolean;
  @Input() isMicrosoftDialPlan = false;

  smacsIcons = SmacsIcons;
  displayEditor = false;
  isLoading = true;
  extraValidation: SmacsFormsValidationConfigItem[] | null;

  private _initialized = false;

  constructor(protected smacsFormStateService: SmacsFormStateService, private translateService: TranslateService) {
    super(smacsFormStateService);

    this.translateService.get(this.translateService.currentLang).subscribe(() => {
      this.isLoading = false;
    });
  }

  applyComponentConfig = ({ config }: SmacsTextFieldConfig) => {
    this.displayEditor = isNil(config) ? this.displayEditor : config.displayEditor;
    this.extraValidation = isNil(config.extraValidation) ? this.extraValidation : config.extraValidation;
  };

  onFormUpdate($event: SmacsFormsUpdate<TextFieldConfig>) {
    if (!isEqual($event.new, $event.old)) {
      this.entity = $event.new;
      this.updateSelf(this.entity);
      this.updateParent(cloneDeep($event.old));
      this._initialized = false;
    } else if (this.childFormComponent) {
      // This forces validation state to parent form
      if (
        (this.childFormComponent.isFormValid() && this.validationState !== SmacsFormsValidationState.VALID) ||
        (!this.childFormComponent.isFormValid() && this.validationState !== SmacsFormsValidationState.INVALID)
      ) {
        this.validationState = this.childFormComponent
          ? this.childFormComponent.isFormValid()
            ? SmacsFormsValidationState.VALID
            : SmacsFormsValidationState.INVALID
          : SmacsFormsValidationState.VALID;

        this.updateParent();
      }
    }
  }

  getLabel(): string {
    return typeof this.config.label === 'function' ? this.config.label() : this.config.label;
  }

  getHelpText(): string {
    return typeof this.config.helpText === 'function' ? this.config.helpText() : this.config.helpText;
  }
}
