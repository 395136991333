/* tslint:disable */
/* eslint-disable */

export interface AbbreviatedDialExtension {
  abbreviatedDialExtension: string;
}

export interface AbstractCiscoRef extends Ref {
  id: string;
  serverId: number;
  url: string;
}

export interface AbstractFrontEndObject {
  name: string;
}

export interface AbstractMsRef extends Ref {
  id: string;
  url: string;
}

export interface AdAttributes {
  adWriteDownListJson: Attribute[];
  userPrincipalName: string;
}

export interface AdAttributesQuery {
  adAttributesToQuery: string[];
  userPrincipalName: string;
}

export interface AdWriteDownAttribute {
  attributeName: string;
  attributeValue: string;
  required: boolean;
  show: boolean;
}

export interface AppUser {
  id: string;
  username: string;
}

export interface AppUserRef extends AbstractCiscoRef {
  username: string;
}

export interface AppUserResult {
  ref: AppUserRef;
}

export interface ApplicableFields {
  associatedEndUsers: boolean;
  busyTrigger: boolean;
  callRecordingMediaSource: boolean;
  callRecordingOption: boolean;
  callRecordingProfile: boolean;
  externalCallerId: boolean;
  externalCallerIdNumber: boolean;
  internalCallerId: boolean;
  label: boolean;
  maxNumberOfCalls: boolean;
  monitoringCssName: boolean;
  ringActive: boolean;
  ringIdle: boolean;
}

export interface AssignedCallingDelegate {
  id: string;
  makeCallsEnabled: boolean;
  manageSettingsEnabled: boolean;
  receiveCallsEnabled: boolean;
}

export interface AssignedMicrosoft365License extends Microsoft365License {
  directAssigned: boolean;
  inheritedFromGroups: MicrosoftLicenseInheritedFromGroup[];
}

export interface AssociatedUser extends Ref {
  displayName: string;
  id: string;
  url: string;
}

export interface Attribute {
  attributeName: string;
  attributeValue: string;
}

export interface Audit {
  afterJson: string;
  beforeJson: string;
  category: string;
  errorDetails: string;
  errorMessage: string;
  id: string;
  response: string;
  server: string;
  tags: { [index: string]: string };
  timestamp: string;
  type: string;
  userId: string;
}

export interface AuditCriteria {
  category: string;
  endTime: string;
  page: number;
  pageSize: number;
  performedBy: string;
  response: string;
  serverName: string;
  startTime: string;
  tag: string;
  type: string;
}

export interface AuditReport {
  categoryTotals: AuditReportCategory[];
}

export interface AuditReportCategory {
  category: string;
  count: number;
}

export interface AuditReportQueries {
  queries: AuditReportQuery[];
}

export interface AuditReportQuery {
  id: number;
  name: string;
  query: string;
}

export interface AuditSearchResult {
  audits: Audit[];
  totalResults: number;
}

export interface AuditSettings {
  daysToKeepAuditRecords: number;
}

export interface BandwidthCachedOptions {
  e911Geolocations: string[];
}

export interface BandwidthDlrMisconfigurationReport {
  bandwidthDlrMisconfigurations: BandwidthDlrMisconfigurations[];
}

export interface BandwidthDlrMisconfigurations {
  callbackNumber: string;
  callerName: string;
  dlrEndpointId: string;
  geolocation: string;
  misconfigurationType: MisconfigurationType;
  preferredLanguage: string;
}

export interface BandwidthE911Dlr {
  callbackNumber: string;
  callerName: string;
  geolocation: string;
  id: string;
  preferredLanguage: string;
}

export interface BandwidthE911DlrRef extends AbstractMsRef {
}

export interface BandwidthEmergencyCallingSettings {
  customSelects: CustomSelect[];
}

export interface BandwidthEmergencyCallingSettingsConfig {
  dynamicGeolocationCallbackNumber: CustomInputText;
  dynamicGeolocationCallerName: CustomInputText;
  dynamicGeolocationEndpointId: CustomInputText;
  dynamicGeolocationPreferredLanguage: CustomSelect;
  dynamicGeolocations: CustomSelect;
}

export interface BandwidthNumbers {
  accountId: string;
  clientNumbers: string[];
  siteId: string;
  siteName: string;
}

export interface BandwidthPortInDocuments {
  documentName: string;
  documentType: DocumentType;
  fileName: string;
}

export interface BandwidthPortInOrder {
  accountId: string;
  accountNumber: string;
  actualFocDate: string;
  billingTelephoneNumber: string;
  customerOrderId: string;
  earliestEstimate: string;
  errors: Errors;
  id: string;
  immediately: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  listOfPhoneNumbers: string[];
  loaAuthorizingPerson: string;
  losingCarrierIsWireless: string;
  losingCarrierName: string;
  losingCarrierSpid: string;
  newBillingTelephoneNumber: string;
  orderCreateDate: string;
  partialPort: string;
  peerId: string;
  pinNumber: string;
  pon: string;
  portType: string;
  processingStatus: string;
  requestedFocDate: string;
  siteId: string;
  subscriber: Subscriber;
  triggered: string;
  userId: string;
  vendorName: string;
}

export interface BandwidthPortInOrderNoteAndHistory {
  description: string;
  diff: string;
  lastModifiedDate: string;
  status: string;
  userId: string;
}

export interface BandwidthPortInOrderRef extends AbstractMsRef {
  actualFocDate: string;
  billingNumber: string;
  customerOrderId: string;
  lastModifiedDate: string;
  processingStatus: string;
  requestedFocDate: string;
}

export interface BandwidthSettings {
  dlrAccountId: string;
  password: string;
  tlsVoiceAccountId: string;
  tlsVoiceEnvironmentType: TlsVoiceEnvironmentType;
  username: string;
}

export interface BaseRef {
  name: string;
  refUrl: string;
}

export interface BlfButton extends Button {
  callPickup: boolean;
  destination: string;
  dn: DirectoryNumberRef;
  label: string;
}

export interface BulkFieldCopyRequest {
  destinationSites: number[];
  fieldName: string;
  serviceName: string;
  siteId: number;
}

export interface BulkProvisioningUserDetails {
  lineUri: string;
  lineUriExtension: string;
  userPrincipalName: string;
}

export interface Button {
  type: string;
}

export interface ButtonMetadata {
  defaultFeatureType: string;
  fixed: boolean;
}

export interface ButtonTemplate {
  availableFeatures: string[];
  buttons: ButtonMetadata[];
  templateId: string;
}

export interface ButtonTemplateOverride {
  cucmId: number;
  model: string;
  name: string;
  protocol: string;
  templateId: string;
}

export interface CallForward {
  classOfService: string;
  destination: string;
  forwardToVoicemail: boolean;
}

export interface CallLog {
  callingPartyNumber: string;
  callingPartyUnicodeLoginUserID: string;
  dateTimeDisconnect: string;
  dateTimeOrigination: string;
  destCause: CallLogCause;
  destDeviceName: string;
  destDeviceOwner: string;
  durationInSeconds: number;
  finalCalledPartyNumber: string;
  finalCalledPartyUnicodeLoginUserID: string;
  lastRedirectDn: string;
  origCause: CallLogCause;
  origDeviceName: string;
  origDeviceOwner: string;
  originalCalledPartyNumber: string;
}

export interface CallLogCause {
  code: string;
  description: string;
}

export interface CallParkBlfButton extends Button {
  dn: DirectoryNumberRef;
  label: string;
}

export interface CallQueue {
  callQueueName: string;
  callQueueUuid: string;
}

export interface CallingDelegate {
  assignedCallingDelegates: AssignedCallingDelegate[];
  userPrincipalName: string;
}

export interface Checkbox extends AbstractFrontEndObject {
  value: boolean;
}

export interface CheckboxFieldConfig {
  defaultValue: boolean;
}

export interface CipcProtocolFieldConfig {
  protocol: SelectFieldConfig<string>;
}

export interface CiscoDialPlanFieldConfig {
  id: number;
  name: string;
}

export interface CiscoGeneralSettings {
  automaticPhoneTemplateSelectionEnabled: boolean;
  changeSiteEnabled: boolean;
  deprovisionThreshold: number;
  display360ViewClusterDropdownEnabled: boolean;
  displayEnhancedLineMode: boolean;
  ignoreLineAssociations: boolean;
  maxOwnedDevices: number;
  minimumOverageDaysSmartLicensing: number;
  resetPinOnNextLoginEnabled: boolean;
  selfServeCallForwardEditEnabled: boolean;
  selfServeDeskphoneProvisioningEnabled: boolean;
  selfServeSnrEditEnabled: boolean;
  selfServeSpeedDialEditEnabled: boolean;
  selfServeVoicemailPinResetComplexity: string;
  showAddUserPage: boolean;
  tasksDisabled: boolean;
  unifiedFxUrl: string;
  unityLookupWithAndWithoutNaCountryCode: boolean;
  zpmUrl: string;
}

export interface CiscoHelpdeskOptions {
  automaticPhoneTemplateSelectionEnabled: boolean;
  changeSiteEnabled: boolean;
  display360ViewClusterDropdownEnabled: boolean;
  displayEnhancedLineMode: boolean;
  ignoreLineAssociations: boolean;
  maxOwnedDevices: number;
  resetPinOnNextLoginEnabled: boolean;
  sendEmailUserOperation: EmailUserOperationLevel;
  showAddUserPage: boolean;
  unifiedFxUrl: string;
  zpmUrl: string;
}

export interface CiscoZeroTouchProvisioningFieldConfig {
  agentExtension: boolean;
  android: boolean;
  cipc: boolean;
  deskphone: boolean;
  dialPlanGroups: { [index: string]: string };
  dn: boolean;
  emailerConfigured: boolean;
  imSoftPhone: boolean;
  imp: boolean;
  iphone: boolean;
  mobility: boolean;
  siteId: number;
  siteName: string;
  snr: boolean;
  snrDestinationForMicrosoftTeams: boolean;
  tablet: boolean;
  voicemail: boolean;
}

export interface Cluster {
  cucmServerDescription: string;
  cucmServerId: number;
  id: number;
  impServerDescription: string;
  impServerId: number;
  name: string;
  pcceServerDescription: string;
  pcceServerId: number;
}

export interface ClusterLdapSyncInfo {
  clusterId: number;
  clusterName: string;
  cucmServer: ServerLdapSyncInfo;
  unityServers: ServerLdapSyncInfo[];
}

export interface ClusterResult {
  cucmServerDescription: string;
  cucmServerId: number;
  id: number;
  impServerDescription: string;
  impServerId: number;
  name: string;
  pcceServerDescription: string;
  pcceServerId: number;
  sites: SiteResult[];
}

export interface CommonPhoneProfile {
  lineMode: string;
  name: string;
}

export interface ConfigSqlResult {
  count: number;
  results: { [index: string]: string }[];
}

export interface ConsumedLicenses {
  consumedCucmLicenseCountBreakdown: CucmLicenseCount[];
  consumedMicrosoftVoiceLicenses: number;
}

export interface ControlSettings {
  cdrCleanUpFrequencyInMinutes: number;
  cdrFileProcessingFrequencyInMinutes: number;
  cdrRetentionInDays: number;
  newDeviceMaxAgeInDays: number;
  utilizationProcessingFrequencyInMinutes: number;
}

export interface Csr {
  csr: string;
  timestamp: string;
}

export interface CucmCdrHistoric {
  callingPartyNumber: string;
  callingPartyUnicodeLoginUserId: string;
  dateTimeDisconnect: number;
  dateTimeOrigination: number;
  destCauseValue: string;
  destDeviceName: string;
  duration: number;
  finalCalledPartyNumber: string;
  finalCalledPartyUnicodeLoginUserId: string;
  globalCallIdCallId: number;
  globalCallIdClusterId: string;
  lastRedirectDn: string;
  origCauseValue: string;
  origDeviceName: string;
  originalCalledPartyNumber: string;
  pkid: string;
}

export interface CucmLicenseCount {
  basic: number;
  cucmDescription: string;
  cucmId: number;
  cuwlProfessional: number;
  cuwlStandard: number;
  enhanced: number;
  enhancedPlus: number;
  totalLicenses: number;
}

export interface CucmLicenseTier {
  currentTotal: number;
  optimalTotal: number;
  optimizableDevices: number;
}

export interface CucmLicenseUsage {
  publicDevices: CucmLicenseUsageDevice[];
  users: CucmLicenseUsageUser[];
}

export interface CucmLicenseUsageDevice {
  currentLicense: string;
  deviceModelName: string;
  deviceName: string;
  duration: number;
  id: string;
  isDeletionCandidate: boolean;
  lastUsed: string;
  licenseUsage: string;
  modelMoniker: string;
  recentlyCreatedDate: string;
  recentlyDeleted: boolean;
  serverDescription: string;
  serverId: number;
}

export interface CucmLicenseUsageUser {
  cucmCurrentLicense: string;
  cucmOptimalLicense: string;
  devices: CucmLicenseUsageDevice[];
  ownerId: string;
}

export interface CucmMetadata {
  activeRingSettings: string[];
  autoAnswers: string[];
  builtInBridges: string[];
  callPickupGroups: string[];
  callRecordingOptions: string[];
  callRecordingProfiles: string[];
  callingSearchSpaces: string[];
  commonDeviceConfigurations: string[];
  commonPhoneProfiles: CommonPhoneProfile[];
  cucmVersion: string;
  defaultOnOffOptions: string[];
  devicePools: string[];
  endUserGroups: string[];
  featureControlPolicies: string[];
  idleRingSettings: string[];
  lineGroups: string[];
  locations: string[];
  mediaResourceGroupLists: string[];
  musicOnHoldAudioSources: string[];
  phoneModels: string[];
  phoneServices: { [index: string]: PhoneService };
  routePartitions: string[];
  sipProfileNames: string[];
  softKeyTemplates: string[];
  ucServiceProfiles: string[];
  userLocales: string[];
  voicemailProfiles: string[];
}

export interface CucmSqlQuery extends SqlQuery {
  cucmId: number;
}

export interface CurrentUser {
  displayName: string;
  photoBase64: string;
  privileges: Privilege[];
  role: Role;
  ssoAuthenticated: boolean;
  userId: string;
}

export interface CustomCheckbox extends AbstractFrontEndObject {
  show: boolean;
  value: boolean;
}

export interface CustomCheckboxFieldConfig extends CheckboxFieldConfig {
  show: boolean;
}

export interface CustomCucmReport {
  id: number;
  lastUpdated: string;
  reportName: string;
  serverId: number;
  sqlQuery: string;
}

export interface CustomInputText extends AbstractFrontEndObject {
  required: boolean;
  show: boolean;
  value: string;
}

export interface CustomMultiSelect extends AbstractFrontEndObject {
  defaultOptions: string[];
  possibleOptions: string[];
  show: boolean;
}

export interface CustomMultiSelectAppUserRefFieldConfig {
  defaultValues: AppUserRef[];
  possibleOptions: AppUserRef[];
  required: boolean;
  show: boolean;
}

export interface CustomMultiSelectEndUserRefFieldConfig {
  defaultValues: EndUserRef[];
  possibleOptions: EndUserRef[];
  required: boolean;
  show: boolean;
}

export interface CustomMultiSelectFieldConfig {
  defaultValues: string[];
  possibleOptions: string[];
  required: boolean;
  show: boolean;
}

export interface CustomPowershellQueryRequest {
  query: string;
}

export interface CustomPowershellQueryResponse {
  count: number;
  results: { [index: string]: string }[];
}

export interface CustomPowershellReport {
  id: number;
  lastUpdated: string;
  pwshQuery: string;
  reportName: string;
}

export interface CustomSelect extends AbstractFrontEndObject {
  defaultOption: string;
  possibleOptions: string[];
  required: boolean;
  show: boolean;
}

export interface DatabaseImportRequest {
  contextUrl: string;
  password: string;
  username: string;
}

export interface DefaultCipcRequest extends DefaultPhoneRequest {
  protocol: string;
}

export interface DefaultDeskPhoneRequest {
  endUserUsername: string;
  phoneModel: string;
  protocol: string;
  siteId: string;
}

export interface DefaultDirectoryNumberRequest {
  dialPlanGroupId: string;
  endUserUsername: string;
  extension: string;
  siteId: string;
  withVoicemail: boolean;
}

export interface DefaultEndUserRequest {
  hasExtensionMobility: boolean;
  hasSnr: boolean;
  hasVoicemail: boolean;
  siteId: string;
  username: string;
}

export interface DefaultExtensionMobilityRequest extends DefaultPhoneRequest {
  phoneModel: string;
  protocol: string;
}

export interface DefaultLdapUser {
  e164Number: string;
  extension: string;
}

export interface DefaultLdapUserDialPlanAttributesRequest {
  dialPlanGroupId: string;
  primaryExtension: string;
  username: string;
}

export interface DefaultLdapUserRequest {
  dialPlanGroupId: string;
  primaryExtension: string;
}

export interface DefaultLdapUserSiteAttribute {
  site: string;
}

export interface DefaultPhoneRequest {
  endUserUsername: string;
  siteId: string;
}

export interface DefaultSnrRequest {
  endUserUsername: string;
  siteId: string;
}

export interface DefaultVoicemailRequest {
  endUserUsername: string;
  siteId: string;
}

export interface DeprovisioningSchedule {
  dailyJobTime: string;
  id: number;
  keepVoicemailEnabled: boolean;
  makeDeskphonesPublicEnabled: boolean;
  name: string;
  periodicJobMinutes: number;
}

export interface DeskphoneProvisioningOptions {
  macAddress: string;
  phoneModel: string;
}

export interface DetailedZiroCallingReport {
  accountType: AccountType;
  assignedUpn: string;
  billingLocation: string;
  billingProduct: BillingProduct;
  billingProductSku: string;
  carrier: string;
  country: string;
  number: string;
  pstnConnectivityType: PstnConnectivityType;
  zpmDialPlanGroupName: string;
}

export interface DeviceModelMetadata {
  model: string;
  nameFormat: string;
  remoteDestinationSetting: DeviceModelProtocolMetadata;
  sccpSetting: DeviceModelProtocolMetadata;
  sipSetting: DeviceModelProtocolMetadata;
  unspecifiedSetting: DeviceModelProtocolMetadata;
}

export interface DeviceModelProtocolMetadata {
  availableButtonFeatures: string[];
  builtInBridgeSupported: boolean;
  busyTriggerDefault: number;
  callRecordingSupported: boolean;
  disableSpeakerPhoneSupported: boolean;
  extensionMobilitySupported: boolean;
  featureControlPolicySupported: boolean;
  lineRingSettingsSupported: boolean;
  lineTextLabelSupported: boolean;
  localizationSupported: boolean;
  maxNumberOfCallsDefault: number;
  maxNumberOfCallsMax: number;
  networkMohSupported: boolean;
  phonePreferredMediaSourceSupported: boolean;
  phoneServiceSubscriptionsSupported: boolean;
  protocol: string;
  securityProfiles: SecurityProfile[];
  standardPhoneButtonTemplate: PhoneButtonTemplate;
}

export interface DevicePoolResult {
  devicePool: string;
  serviceName: string;
}

export interface DeviceUtilization {
  clusterId: string;
  description: string;
  deviceName: string;
  devicePool: string;
  deviceType: DeviceType;
  lastRegisteredDate: string;
  lastUsedDate: string;
  modelName: string;
  ownerId: string;
  recentlyCreatedDate: string;
  registrationStatus: string;
  totalDuration: number;
}

export interface DeviceUtilizationRefresh {
  allowRecentFiles: boolean;
  deleteFiles: boolean;
  syncDevices: boolean;
}

export interface DialPlanExceptionGroup {
  exceptionRanges: DnExceptionRange[];
  exceptionValues: string[];
  id: string;
  legalHold: boolean;
  name: string;
}

export interface DialPlanExceptionGroupResponse {
  groupName: string;
  id: string;
}

export interface DialPlanGroupEntry {
  assignmentStatus: DialPlanGroupEntryStatus;
  deletionDate: string;
  description: string;
  exceptionGroupName: string;
  pbxType: DialPlanGroupEntryType;
  usageTypes: UsageType[];
  value: string;
}

export interface DialPlanGroupInventory {
  availableAndDefined: number;
  availableAndUndefined: number;
  clusterId: number;
  groupName: string;
  siteIds: number[];
  unavailable: number;
}

export interface DialPlanInventory {
  dialPlanEntries: DialPlanGroupEntry[];
  groupId: number;
  groupName: string;
  translationPatternEntries: TranslationPatternEntry[];
}

export interface DialPlanManagementGroup {
  clusterId: number;
  directoryNumberRangesSection: DirectoryNumberRangesSection;
  e164AlternateNumberSection: IlsAlternateNumberSection;
  enterpriseAlternateNumberSection: IlsAlternateNumberSection;
  id: string;
  name: string;
  pstnFailoverSection: PstnFailoverSection;
  siteIds: number[];
  translationPatternRangesSection: TranslationPatternRangesSection;
  writeLdapSection: WriteLdapSection;
}

export interface DialPlanManagementGroupSearchResult {
  id: number;
  name: string;
  url: string;
}

export interface DirectoryNumber {
  alertingName: string;
  autoAnswer: string;
  callPickUpGroupName: string;
  classOfService: string;
  description: string;
  e164AlternateNumber: IlsAlternateNumber;
  enterpriseAlternateNumber: IlsAlternateNumber;
  extension: string;
  forwardAll: CallForward;
  forwardBusyExternal: CallForward;
  forwardBusyInternal: CallForward;
  forwardNoAnswerExternal: CallForward;
  forwardNoAnswerInternal: CallForward;
  forwardNoCoverageExternal: CallForward;
  forwardNoCoverageInternal: CallForward;
  forwardOnCtiFailure: CallForward;
  forwardUnregisteredExternal: CallForward;
  forwardUnregisteredInternal: CallForward;
  id: string;
  lineGroups: string[];
  noAnswerRingDuration: string;
  pstnFailover: string;
  routePartition: string;
  secondaryClassOfServiceForForwardAll: string;
  urgentPriority: boolean;
  voicemailProfile: string;
}

export interface DirectoryNumberFieldConfig {
  alertingName: TextFieldConfig;
  autoAnswer: SelectFieldConfig<string>;
  callPickUpGroupName: SelectFieldConfig<string>;
  classOfService: SelectFieldConfig<string>;
  description: TextFieldConfig;
  e164AlternateNumber: IlsAlternateNumberFieldConfig;
  enterpriseAlternateNumber: IlsAlternateNumberFieldConfig;
  forwardAllCss: SelectFieldConfig<string>;
  lineGroups: CustomMultiSelectFieldConfig;
  noAnswerRingDuration: TextFieldConfig;
  pstnFailover: SelectFieldConfig<string>;
  routePartition: SelectFieldConfig<string>;
  secondaryClassOfServiceForForwardAll: SelectFieldConfig<string>;
  urgentPriority: CustomCheckboxFieldConfig;
  voicemailProfile: SelectFieldConfig<string>;
}

export interface DirectoryNumberFieldConfigRequest {
  dialPlanGroupId: number;
  extension: string;
  siteId: number;
  username: string;
  withVoicemail: boolean;
}

export interface DirectoryNumberRangesSection {
  checkboxes: Checkbox[];
  customCheckboxes: CustomCheckbox[];
  customInputs: CustomInputText[];
  customMultiSelects: CustomMultiSelect[];
  customSelects: CustomSelect[];
  dnRanges: DnDidRange[];
}

export interface DirectoryNumberRef extends AbstractCiscoRef {
  description: string;
  extension: string;
}

export interface DirectoryNumberResult {
  callable: boolean;
  numberOfAssociatedDevices: number;
  ref: DirectoryNumberRef;
  routePartition: string;
}

export interface DirectoryReportUser {
  cucmExtension: string;
  ldapCurrentDid: string;
  ldapCurrentExtension: string;
  ldapExpectedDid: string;
  ldapExpectedExtension: string;
  ldapUserId: string;
}

export interface DistinguishedNameProperties {
  commonName: string;
  country: string;
  locality: string;
  organization: string;
  organizationalUnit: string;
  state: string;
  subjectAlternativeNames: string[];
}

export interface DistributionList {
  alias: string;
  displayName: string;
  id: string;
  members: DistributionListMember[];
}

export interface DistributionListMember {
  alias: string;
  displayName: string;
  id: string;
  voicemailId: string;
}

export interface DistributionListRef extends AbstractCiscoRef {
}

export interface DistributionListResult {
  alias: string;
  displayName: string;
  extension: string;
  ref: DistributionListRef;
}

export interface DnDetailSummary {
  callForwardAllDestination: string;
  dialPlanGroupId: number;
  dn: DirectoryNumberResult;
  translationPatterns: TranslationPatternRef[];
  voicemail: VoicemailRef;
}

export interface DnDidRange {
  end: string;
  start: string;
}

export interface DnExceptionRange {
  end: string;
  start: string;
}

export interface DnProvisioningOptions {
  dialPlanGroupIds: number[];
  extension: string;
}

export interface DraftSubmissionRequest {
  notificationEmails: string[];
}

export interface DubPoint {
  id: string;
  product: string;
}

export interface DubPointRef extends AbstractMsRef {
  region: string;
}

export interface DubPointsResult {
  dubPointRefJsons: DubPointRef[];
}

export interface DubberEndUser {
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  mobileNumber: string;
}

export interface DubberEndUserRef extends AbstractMsRef {
  region: string;
}

export interface DubberEndUserResult {
  dubberEndUserRefJsons: DubberEndUserRef[];
}

export interface DubberRegions {
  regions: string[];
}

export interface DubberSettings {
  password: string;
  url: string;
  username: string;
}

export interface Email {
  emailBody: string;
  emailSubject: string;
  recipients: string[];
  senderName: string;
}

export interface EmailSettings {
  from: string;
  host: string;
  password: string;
  port: number;
  transportStrategy: EmailTransportStrategy;
  username: string;
}

export interface EmailTemplate {
  emailBody: string;
  emailSubject: string;
  emailTemplateName: string;
  id: number;
  is360ViewEndUserRecipient: boolean;
  otherRecipients: string[];
  senderName: string;
}

export interface EmailTemplatePreviewFieldConfig {
  emailBody: string;
  emailSubject: string;
  emailTemplateName: string;
  recipients: string[];
  senderName: string;
}

export interface EmergencyLocation {
  description: string;
  id: string;
}

export interface EndUser {
  directoryUri: string;
  enableCti: boolean;
  enableHomeCluster: boolean;
  enableMobileVoiceAccess: boolean;
  enableMobility: boolean;
  extensionMobilityCrossCluster: boolean;
  firstName: string;
  groups: string[];
  id: string;
  ipccExtension: DirectoryNumberRef;
  lastName: string;
  mailId: string;
  primaryExtension: DirectoryNumberRef;
  selfServiceUserId: string;
  subscribeCss: string;
  ucServiceProfile: string;
  username: string;
}

export interface EndUserDeprovisioning {
  keepVoicemailEnabled: boolean;
  makeDeskphonesPublicEnabled: boolean;
  username: string;
}

export interface EndUserPinResetRequest {
  pin: string;
  resetOnNextLogin: boolean;
}

export interface EndUserProvisioningOptions {
  agentExtension: boolean;
  android: boolean;
  cipc: boolean;
  deskphone: DeskphoneProvisioningOptions;
  dn: DnProvisioningOptions;
  imSoftphone: boolean;
  imp: boolean;
  iphone: boolean;
  mobility: boolean;
  siteId: number;
  snrDestinationForMicrosoftTeams: boolean;
  snrProfile: boolean;
  tablet: boolean;
  username: string;
  voicemail: boolean;
}

export interface EndUserProvisioningSetting {
  agentExtension: boolean;
  android: boolean;
  cipc: boolean;
  deskphone: boolean;
  dialPlanGroups: { [index: string]: string };
  dn: boolean;
  imSoftPhone: boolean;
  imp: boolean;
  iphone: boolean;
  mobility: boolean;
  siteId: number;
  siteName: string;
  snr: boolean;
  snrDestinationForMicrosoftTeams: boolean;
  tablet: boolean;
  voicemail: boolean;
}

export interface EndUserRef extends AbstractCiscoRef {
  firstName: string;
  lastName: string;
  username: string;
}

export interface EndUserResult {
  email: string;
  imPresenceEnabled: boolean;
  ref: EndUserRef;
  type: string;
}

export interface EndUserSummary {
  additionalExtensions: DirectoryNumberResult[];
  associatedPublicPhones: PhoneSummary[];
  extensionMobilities: ExtensionMobilitySummary[];
  imPresenceActive: boolean;
  ipccExtension: DnDetailSummary;
  ldapUser: LdapUser;
  mailId: string;
  phones: PhoneSummary[];
  primaryExtension: DnDetailSummary;
  sites: SiteSearchResult[];
  snr: SnrSummary;
  type: string;
  uccxAgents: UccxAgentRef[];
  user: EndUserResult;
}

export interface Errors {
  code: string;
  description: string;
  telephoneNumberList: string[];
}

export interface ExpansionModuleModel {
  maxAmount: number;
  model: string;
}

export interface ExtensionMobility {
  alwaysUsePrimeLine: string;
  alwaysUsePrimeLineForVoiceMessage: string;
  associatedEndUsers: EndUserRef[];
  buttons: Button[];
  crossClusterCss: string;
  ctiAssociatedAppUsers: AppUserRef[];
  ctiAssociatedEndUsers: EndUserRef[];
  description: string;
  featureControlPolicyName: string;
  id: string;
  model: string;
  name: string;
  phoneButtonTemplate: PhoneButtonTemplateRef;
  privacy: string;
  protocol: string;
  serviceSubscriptions: PhoneServiceSubscription[];
  softkeyTemplate: string;
  userLocale: string;
  userMohSourceName: string;
}

export interface ExtensionMobilityFieldConfig {
  alwaysUsePrimeLine: SelectFieldConfig<string>;
  alwaysUsePrimeLineForVoiceMessage: SelectFieldConfig<string>;
  associatedAppUsersCti: CustomMultiSelectAppUserRefFieldConfig;
  associatedEndUsers: CustomMultiSelectEndUserRefFieldConfig;
  associatedEndUsersCti: CustomMultiSelectEndUserRefFieldConfig;
  crossClusterCss: SelectFieldConfig<string>;
  description: TextFieldConfig;
  featureControlPolicy: SelectFieldConfig<string>;
  name: TextFieldConfig;
  phoneServices: MultiSelectFieldConfig<PhoneServiceSubscription>;
  privacy: SelectFieldConfig<string>;
  softkeyTemplate: SelectFieldConfig<string>;
  userLocale: SelectFieldConfig<string>;
  userMohAudioSource: SelectFieldConfig<string>;
}

export interface ExtensionMobilityFieldConfigRequest {
  model: string;
  protocol: string;
  siteId: string;
  username: string;
}

export interface ExtensionMobilityLineReport {
  applicableFieldsJson: ApplicableFields;
  extensionMobilityResultJson: ExtensionMobilityResult;
  lineFeatureJson: LineFeature;
}

export interface ExtensionMobilityRef extends AbstractCiscoRef {
  description: string;
  model: string;
  name: string;
  protocol: string;
}

export interface ExtensionMobilityResult {
  ref: ExtensionMobilityRef;
}

export interface ExtensionMobilitySummary {
  description: string;
  id: string;
  name: string;
}

export interface FrontendError {
  buildVersion: string;
  cluster: string;
  currentUrl: string;
  currentUser: string;
  currentUserRole: string;
  errorSummary: string;
  errorType: string;
  stacktrace: string;
  userAgent: string;
}

export interface Global360View {
  associatedPublicPhones: PhoneRef[];
  endUsers: EndUserResult[];
  extensionMobilities: ExtensionMobilityRef[];
  ipccExtensions: DirectoryNumberRef[];
  ldapUser: LdapUser;
  pcceAgents: PcceAgentRef[];
  phones: PhoneRef[];
  primaryExtensionCandidates: DirectoryNumberRef[];
  primaryExtensions: DirectoryNumberRef[];
  sites: SiteSearchResult[];
  snrProfiles: SnrProfileRef[];
  translationPatterns: TranslationPatternRef[];
  uccxAgents: UccxAgentRef[];
  voicemails: VoicemailRef[];
  webexEndUser: WebexEndUser;
}

export interface GlobalProperties {
  buildTimestamp: string;
  gitDescribe: string;
  hostedEnabled: boolean;
  version: string;
}

export interface GlobalVoicemail {
  id: string;
}

export interface GlobalVoicemailResult {
  alias: string;
  displayName: string;
  extension: string;
  firstName: string;
  id: string;
  lastName: string;
  partitionObjectId: string;
  smtpAddress: string;
}

export interface GraphDetails {
  displayName: string;
  graphRoles: string[];
  teamsEntraIdRoles: TeamsEntraRole[];
  useCases: ZpmUseCase[];
}

export interface HealthStatus {
  category: StatusCategory;
  cause: string;
  description: string;
  state: State;
}

export interface HealthStatusSettings {
  automaticDisableOfEndpoints: boolean;
  emailRecipients: string[];
  errorNotifications: NotificationSettings;
  healthCheckIntervalInMinutes: number;
  warningNotifications: NotificationSettings;
}

export interface HighAvailabilitySettings {
  haEnabled: boolean;
  syncIntervalInHours: number;
  url: string;
}

export interface HighAvailabilityUrl {
  url: string;
}

export interface HoldButton extends Button {
}

export interface IlsAlternateNumber {
  addToLocalRoutePartition: boolean;
  advertiseGloballyViaIls: boolean;
  numberMask: string;
  routePartition: string;
  urgent: boolean;
}

export interface IlsAlternateNumberFieldConfig {
  addToLocalRoutePartition: CustomCheckboxFieldConfig;
  advertiseGloballyViaIls: CustomCheckboxFieldConfig;
  numberMask: TextFieldConfig;
  routePartition: SelectFieldConfig<string>;
  urgent: CustomCheckboxFieldConfig;
}

export interface IlsAlternateNumberSection {
  customCheckboxes: CustomCheckbox[];
  customInputs: CustomInputText[];
  customSelects: CustomSelect[];
}

export interface ImPresence {
  enabled: boolean;
  id: string;
  includeMeetingInformation: boolean;
}

export interface IntercomButton extends Button {
  intercom: IntercomRef;
}

export interface IntercomDirectoryNumber {
  alertingName: string;
  autoAnswer: string;
  classOfService: string;
  defaultActivatedDevice: string;
  description: string;
  extension: string;
  id: string;
  routePartition: string;
}

export interface IntercomRef extends AbstractCiscoRef {
  description: string;
  extension: string;
}

export interface JobDetailedStatus {
  completedUsers: BulkProvisioningUserDetails[];
  failedUsers: BulkProvisioningUserDetails[];
  inProgressUser: BulkProvisioningUserDetails[];
  jobName: string;
  remainingUsers: BulkProvisioningUserDetails[];
}

export interface JobSchedule {
  dailyJobTime: string;
  periodicJobMinutes: number;
}

export interface JobStatus {
  error: string;
  jobId: number;
  jobState: JobState;
  lastRunTime: string;
  nextRunTime: string;
}

export interface LdapConfiguration {
  additionalAttributeNames: string[];
  connectTimeout: number;
  e164NumberAttributeName: string;
  extensionAttributeName: string;
  groupAttributeName: string;
  groupBaseDn: string;
  groupObjectClass: string;
  password: string;
  photoAttributeName: string;
  readTimeout: number;
  searchPageSize: number;
  searchTimeLimit: number;
  siteAttributeName: string;
  urls: string[];
  userBaseDn: string;
  userIdAttributeName: string;
  userObjectClass: string;
  username: string;
  writeToSiteEnabled: boolean;
}

export interface LdapConnection {
  connectTimeout: number;
  groupBaseDn: string;
  password: string;
  readTimeout: number;
  url: string;
  userBaseDn: string;
  username: string;
}

export interface LdapGroups {
  groups: string[];
}

export interface LdapMembers {
  members: string[];
}

export interface LdapPermissionGroup {
  administratorsGroup: string;
  globalHelpdeskGroup: string;
  lookupNestedGroups: boolean;
  siteBasedHelpdeskGroup: string;
}

export interface LdapPublicConfiguration {
  configured: boolean;
  e164NumberAttributeName: string;
  extensionAttributeName: string;
}

export interface LdapSyncConfig {
  enabled: boolean;
  intervalMinutes: number;
}

export interface LdapSyncJobStatus {
  clusters: ClusterLdapSyncInfo[];
  inProgress: boolean;
}

export interface LdapUser {
  additionalAttributes: { [index: string]: string };
  e164Number: string;
  extension: string;
  groups: string[];
  mail: string;
  photoBase64: string;
  site: string;
  userPrincipalName: string;
  username: string;
}

export interface LdapUserDialPlanAttributes {
  did?: string;
  extension?: string;
  username: string;
}

export interface LdapUserDialPlanDetailsFieldConfig {
  e164Number: TextFieldConfig;
  extension: TextFieldConfig;
}

export interface LdapUserDialPlanDetailsFieldConfigRequest {
  dialPlanGroupId: number;
  extension: string;
}

export interface LdapUserId {
  userId: string;
}

export interface LdapUserResult {
  userId: string;
}

export interface LdapUserSiteAttribute {
  site: string;
  userPrincipalName: string;
}

export interface LicenseInfo {
  company: string;
  consumedCucmLicenseCountBreakdown: CucmLicenseCount[];
  expiryDate: string;
  modules: LicenseModules;
  totalLicenses: number;
  totalUsedLicenses: number;
}

export interface LicenseModules {
  automation: boolean;
  highAvailability: boolean;
  provisioning: boolean;
  proxyServerOnlyMode: boolean;
  reporting: boolean;
}

export interface LicenseOptimizerOverview {
  basic: CucmLicenseTier;
  cuwlStandard: CucmLicenseTier;
  enhanced: CucmLicenseTier;
  enhancedPlus: CucmLicenseTier;
  essential: CucmLicenseTier;
}

export interface LicensingStatus {
  groupLicenseAssignmentStatus: Microsoft365UserLicenseStatus;
  teamsAudioConferencingStatus: LicensingState;
  teamsPhoneStatus: LicensingState;
}

export interface LineAppearanceReport {
  commonSettings: LineFeature;
  extensionMobilityLineReportJsons: ExtensionMobilityLineReport[];
  phonesLineReportJsons: PhonesLineReport[];
  snrProfileLineReportJsons: SnrProfileLineReport[];
}

export interface LineButton extends Button {
  dn: DirectoryNumberRef;
  lineFeature: LineFeature;
}

export interface LineFeature {
  associatedEndUsers: EndUserRef[];
  busyTrigger: number;
  callRecordingMediaSource: string;
  callRecordingOption: string;
  callRecordingProfile: string;
  externalCallerId: string;
  externalCallerIdNumber: string;
  internalCallerId: string;
  label: string;
  maxNumberOfCalls: number;
  monitoringCssName: string;
  ringActive: string;
  ringIdle: string;
}

export interface LineFeatureFieldConfig {
  associatedEndUsers: EndUserRef[];
  busyTrigger: SelectFieldConfig<string>;
  callRecordingMediaSource: SelectFieldConfig<string>;
  callRecordingOption: SelectFieldConfig<string>;
  callRecordingProfile: SelectFieldConfig<string>;
  externalCallerId: TextFieldConfig;
  externalCallerIdNumber: TextFieldConfig;
  internalCallerId: TextFieldConfig;
  label: TextFieldConfig;
  maxNumberOfCalls: SelectFieldConfig<string>;
  monitoringCssName: SelectFieldConfig<string>;
  ringActive: SelectFieldConfig<string>;
  ringIdle: SelectFieldConfig<string>;
}

export interface LineFeatureFieldConfigRequest {
  deviceOwner: string;
  extension: string;
  model: string;
  protocol: string;
  siteId: number;
}

export interface LocalApiAccountSettings {
  password: string;
  username: string;
}

export interface LoginRequest {
  password: string;
  username: string;
}

export interface Microsoft360View {
  accountType: AccountType;
  dubberUser: DubberEndUserRef;
  licensingStatus: LicensingStatus;
  microsoft365UserLicenses: Microsoft365UserLicenses;
  onPremiseUserPrincipalName: string;
  photoBase64: string;
  securityGroupMemberships: MicrosoftSecurityGroupOption[];
  teamsAudioConferencing: MicrosoftTeamsAudioConferencing;
  teamsDevices: MicrosoftTeamsDevices[];
  teamsPhoneNumberAssignment: TeamsPhoneNumberAssignment;
  teamsVoicePolicies: TeamsVoicePolicies;
}

export interface Microsoft365DetailedLicense {
  assignedUsers: string[];
  microsoft365LicenseCountJson: Microsoft365LicenseCount;
}

export interface Microsoft365DetailedLicensesView {
  microsoft365DetailedLicenseJsonList: Microsoft365DetailedLicense[];
  tenantDefaultUsageLocation: string;
  usageLocationsList: string[];
}

export interface Microsoft365License {
  associatedServicePlans: string[];
  productName: string;
  skuId: string;
  skuPartNumber: string;
}

export interface Microsoft365LicenseCount {
  available: number;
  consumed: number;
  microsoft365LicenseJson: Microsoft365License;
  total: number;
}

export interface Microsoft365LicenseSettings {
  readOnlyLicenseList: Microsoft365License[];
  usageLocation: SelectFieldConfig<string>;
  writeLicenseList: Microsoft365License[];
}

export interface Microsoft365LicensesFieldConfig {
  readOnlyLicenseList: Microsoft365LicenseCount[];
  usageLocation: SelectFieldConfig<string>;
  writeLicenseList: Microsoft365LicenseCount[];
}

export interface Microsoft365LicensesView {
  microsoft365LicenseCountJsonList: Microsoft365LicenseCount[];
  tenantDefaultUsageLocation: string;
  usageLocationsList: string[];
}

export interface Microsoft365UserLicenses {
  accountEnabled: boolean;
  displayName: string;
  licenses: AssignedMicrosoft365License[];
  usageLocation: string;
  userPrincipalName: string;
}

export interface Microsoft365UserRef extends Ref {
  displayName: string;
  id: string;
  url: string;
}

export interface Microsoft365UserResult {
  firstName: string;
  lastName: string;
  microsoft365UserRef: Microsoft365UserRef;
}

export interface MicrosoftBulkJobDraft {
  bulkJobId: number;
  bulkJobName: string;
  dialPlanGroupId: string;
  users: MicrosoftBulkJobUserDraft[];
}

export interface MicrosoftBulkJobDraftPhoneNumberAssignment {
  emergencyLocation: string;
  lineUri: string;
  lineUriExtension: string;
}

export interface MicrosoftBulkJobDraftRef extends AbstractMsRef {
}

export interface MicrosoftBulkJobDraftVoicePolicies {
  callingLineIdentityName: string;
  callingPolicyName: string;
  emergencyCallRoutingPolicyName: string;
  emergencyCallingPolicyName: string;
  ipPhonePolicyName: string;
  onlineVoiceRoutingPolicyName: string;
  onlineVoicemailPolicyName: string;
  sharedCallRoutingPolicyName: string;
  teamsCallHoldPolicyName: string;
  teamsCallParkPolicyName: string;
  tenantDialPlanName: string;
}

export interface MicrosoftBulkJobStatus {
  jobDetailedStatus: JobDetailedStatus;
  jobStatus: JobStatus;
}

export interface MicrosoftBulkJobUserDraft {
  phoneNumberAssignment: MicrosoftBulkJobDraftPhoneNumberAssignment;
  userPrincipalName: string;
  voicePolicies: MicrosoftBulkJobDraftVoicePolicies;
}

export interface MicrosoftCachedOptions {
  audioConferencingDefaultServiceNumber: string;
  audioConferencingServiceNumbers: string[];
  callingLineIdentities: string[];
  callingPolicies: string[];
  complianceRecordingPolicies: string[];
  emergencyLocations: EmergencyLocation[];
  emergencyPolicies: string[];
  emergencyRoutingPolicies: string[];
  ipPhonePolicies: string[];
  onlineVoicemailPolicies: string[];
  sharedCallingRoutingPolicies: SharedCallingRoutingPolicy[];
  teamsCallHoldPolicies: string[];
  teamsCallParkPolicies: string[];
  tenantDialPlans: string[];
  voiceRoutingPolicies: string[];
}

export interface MicrosoftComplianceRecordingPolicyFieldConfig {
  complianceRecordingPolicy: CustomSelect;
}

export interface MicrosoftDialPlanExceptionGroup {
  exceptionRanges: MicrosoftDialPlanRange[];
  id: string;
  name: string;
  rangeType: RangeType;
}

export interface MicrosoftDialPlanFieldConfig {
  callingType: CallingType;
  emergencyCallingSettingsConfigured: boolean;
  id: number;
  mainNumber: string;
  name: string;
  onPremAdWriteDownEnabled: boolean;
  pstnConnectivityType: PstnConnectivityType;
}

export interface MicrosoftDialPlanGroup {
  adWriteDownList: AdWriteDownAttribute[];
  bandwidthEmergencyCallingSettings: BandwidthEmergencyCallingSettings;
  billingLocation: string;
  callingType: CallingType;
  carrierName: string;
  customInputs: CustomInputText[];
  customSelects: CustomSelect[];
  dialPlanRangesJson: MicrosoftDialPlanRange[];
  enforcedNumbersUsageLocation: string;
  id: string;
  mainNumber: string;
  name: string;
  pstnConnectivityType: PstnConnectivityType;
  sharedCallingRoutingPolicy: string;
}

export interface MicrosoftDialPlanGroupRef extends AbstractMsRef {
  name: string;
}

export interface MicrosoftDialPlanGroupSearchResult {
  id: number;
  name: string;
  url: string;
}

export interface MicrosoftDialPlanInventoryDetailed {
  availability: string;
  dialPlanGroup: string;
  displayName: string;
  number: string;
  upn: string;
}

export interface MicrosoftDialPlanInventorySummary {
  availableNumbers: number;
  groupName: string;
  unavailableNumbers: number;
}

export interface MicrosoftDialPlanRange {
  end: string;
  start: string;
}

export interface MicrosoftDialPlansV1 {
  id: string;
  name: string;
}

export interface MicrosoftDubberPointsFieldConfig {
  dubberPoint: CustomSelect;
}

export interface MicrosoftDubberUserFieldConfig {
  email: CustomInputText;
  firstName: CustomInputText;
  lastName: CustomInputText;
  mobileNumber: CustomInputText;
  region: CustomSelect;
}

export interface MicrosoftGeneralSettings {
  showAssociatedDevicesOn360View: boolean;
  zpcUrl: string;
}

export interface MicrosoftGraphRegistrationDetails {
  clientId: string;
  clientSecret: string;
  environmentType: EnvironmentType;
  tenant: string;
}

export interface MicrosoftGraphUserRequest {
  accountEnabled: boolean;
  displayName: string;
  forcePasswordChangeOnNextSignIn: boolean;
  mailNickname: string;
  password: string;
  userPrincipalName: string;
}

export interface MicrosoftHelpdeskOptions {
  companyId: string;
  portingEnabled: boolean;
  zpcUrl: string;
}

export interface MicrosoftLicenseInheritedFromGroup {
  displayName: string;
  id: string;
}

export interface MicrosoftOnPremAdAttributesFieldConfig {
  attributes: { [index: string]: TextFieldConfig };
}

export interface MicrosoftPolicyConfiguration {
  currentPolicy: string;
  defaultPolicy: string;
  isValid: boolean;
  possibleOptions: string[];
}

export interface MicrosoftPolicyMisconfigurationReport {
  callingLineIdentity: MicrosoftPolicyConfiguration;
  callingPolicy: MicrosoftPolicyConfiguration;
  dialPlanName: string;
  emergencyCallRoutingPolicy: MicrosoftPolicyConfiguration;
  emergencyCallingPolicy: MicrosoftPolicyConfiguration;
  ipPhonePolicy: MicrosoftPolicyConfiguration;
  lineUri: string;
  onlineVoiceRoutingPolicy: MicrosoftPolicyConfiguration;
  onlineVoicemailPolicy: MicrosoftPolicyConfiguration;
  sharedCallRoutingPolicy: MicrosoftPolicyConfiguration;
  teamsCallHoldPolicy: MicrosoftPolicyConfiguration;
  teamsCallParkPolicy: MicrosoftPolicyConfiguration;
  tenantDialPlan: MicrosoftPolicyConfiguration;
  upn: string;
}

export interface MicrosoftProvisioningJob {
  id: number;
  name: string;
  options: MicrosoftProvisioningJobOptions;
  schedule: JobSchedule;
  searchFilter: string;
}

export interface MicrosoftProvisioningJobOptions {
  audioConferenceNumber: ZeroTouchAudioConference;
  teamsCalling: ZeroTouchJobTeamsCalling;
}

/**
 * @deprecated since 10.0.0
 */
export interface MicrosoftProvisioningUserOptions {
  audioConferenceNumber: ZeroTouchAudioConference;
  teamsCalling: ZeroTouchTeamsLine;
  userPrincipalName: string;
}

export interface MicrosoftProvisioningUserOptionsV2 {
  audioConferenceNumber: string;
  callingLineIdentity: string;
  callingPolicy: string;
  dialPlanGroupId: string;
  emergencyCallRoutingPolicy: string;
  emergencyCallingPolicy: string;
  ipPhonePolicy: string;
  lineUri: string;
  lineUriExtension: string;
  memberOfGroups: string[];
  onlineVoiceRoutingPolicy: string;
  onlineVoicemailPolicy: string;
  sharedCallRoutingPolicy: string;
  teamsCallHoldPolicy: string;
  teamsCallParkPolicy: string;
  tenantDialPlan: string;
  userPrincipalName: string;
}

export interface MicrosoftPstnCallUsageReport {
  endDate: string;
  startDate: string;
  teamsPstnCallingUsageDetailsPerUser: TeamsPstnCallingUsageDetail[];
}

export interface MicrosoftSecurityGroup {
  displayName: string;
  members: string[];
}

export interface MicrosoftSecurityGroupFieldConfigOption extends MicrosoftSecurityGroupOption {
  licenses: Microsoft365License[];
}

export interface MicrosoftSecurityGroupMembership {
  securityGroupMemberships: MicrosoftSecurityGroupOption[];
  userPrincipalName: string;
}

export interface MicrosoftSecurityGroupOption {
  description: string;
  displayName: string;
  id: string;
}

export interface MicrosoftSecurityGroupRef extends Ref {
  displayName: string;
  id: string;
  url: string;
}

export interface MicrosoftSecurityGroupResult {
  microsoftSecurityGroupRefJson: MicrosoftSecurityGroupRef;
}

export interface MicrosoftSecurityGroupSettings {
  readOnlySecurityGroupList: MicrosoftSecurityGroupOption[];
  writeSecurityGroupList: MicrosoftSecurityGroupOption[];
}

export interface MicrosoftSecurityGroupsFieldConfig {
  readOnlySecurityGroupList: MicrosoftSecurityGroupFieldConfigOption[];
  writeSecurityGroupList: MicrosoftSecurityGroupFieldConfigOption[];
}

export interface MicrosoftTeamsAudioConference {
  audioConferencingServiceNumbers: SelectFieldConfig<string>;
}

export interface MicrosoftTeamsAudioConferencing {
  audioConferenceNumber: string;
  userPrincipalName: string;
}

export interface MicrosoftTeamsCallQueuesFieldConfig {
  callQueues: MultiSelectFieldConfig<CallQueue>;
}

export interface MicrosoftTeamsCallingSettings {
  forwardingTarget: string;
  forwardingTargetType: ForwardingTargetType;
  forwardingType: ForwardingType;
  unansweredDelayInSeconds: UnansweredDelayInSeconds;
  unansweredTarget: string;
  unansweredTargetType: UnansweredTargetType;
  userPrincipalName: string;
}

export interface MicrosoftTeamsComplianceRecordingPolicy {
  complianceRecordingPolicy: string;
  userPrincipalName: string;
}

export interface MicrosoftTeamsDevice {
  activityState: string;
  companyAssetTag: string;
  currentUser: MicrosoftTeamsDeviceCurrentUser;
  deviceType: string;
  healthStatus: string;
  id: string;
  manufacturer: string;
  model: string;
  notes: string;
  serialNumber: string;
}

export interface MicrosoftTeamsDeviceCurrentUser {
  displayName: string;
  id: string;
}

export interface MicrosoftTeamsDevices {
  deviceType: string;
  id: string;
  manufacturer: string;
  model: string;
}

export interface MicrosoftTeamsUserCallQueues {
  callQueueJsons: CallQueue[];
  userPrincipalName: string;
}

export interface MicrosoftTeamsUserResult {
  enterpriseVoiceEnabled: boolean;
  lineUri: string;
  lineUriExtension: string;
  ref: Microsoft365UserRef;
  userPrincipalName: string;
}

export interface MobileSoftphoneFieldConfigRequest {
  siteId: string;
  username: string;
}

export interface MobilityIdentity {
  delayBeforeRingingInSeconds: number;
  delayStopRingingInSeconds: number;
  destinationNumber: string;
  id: string;
  name: string;
}

export interface MobilityIdentityFieldConfig {
  delayBeforeRingInSeconds: TextFieldConfig;
  destination: TextFieldConfig;
  name: TextFieldConfig;
  stopRingingPhoneDelayInSeconds: TextFieldConfig;
}

export interface MobilityIdentityRef extends AbstractCiscoRef {
  destinationNumber: string;
  name: string;
}

export interface ModelProtocolFieldConfig {
  modelSpecificProtocols: { [index: string]: SelectFieldConfig<string> };
  models: SelectFieldConfig<string>;
}

export interface MultiSelect extends AbstractFrontEndObject {
  possibleOptions: string[];
}

export interface MultiSelectFieldConfig<T> {
  name: string;
  possibleOptions: T[];
  require: boolean;
  show: boolean;
}

export interface MultiTextFieldConfig {
  defaultValues: string[];
  required: boolean;
  show: boolean;
}

export interface NoneButton extends Button {
}

export interface NotificationSettings {
  failureThreshold: number;
  repeatNotificationInMinutes: number;
}

export interface NumberLocationValidation {
  isValid: boolean;
  numberUsageLocation: string;
  userUsageLocation: string;
}

export interface NumberPortability {
  csrRetrievalSupported: boolean;
  losingCarrierName: string;
  losingCarrierSpid: string;
  losingCarrierSupported: boolean;
  number: string;
  rateCenterName: string;
  rateCenterSupported: boolean;
}

export interface NumberPortabilityRequest {
  phoneNumbersToPort: string[];
}

export interface NumberPortabilityResponse {
  numberPortabilities: NumberPortability[];
  portType: PortType;
}

export interface NumberStatus {
  dialPlanGroup: MicrosoftDialPlanGroupRef;
  isConfiguredOnSbc: boolean;
  number: string;
  userPrincipalName: string;
}

export interface OAuth2ResourceServerSettings {
  audience: string;
  jwtIssuerUrl: string;
}

export interface OnPremiseCiscoDeviceRequest {
  audits: { [index: string]: string };
  id: string;
  parentResourceId: string;
  resourceId: string;
  serverId: number;
}

export interface OrderNumberInventory {
  customerOrderId: string;
  id: string;
  numbers: string[];
}

export interface OrderNumberInventoryResponse {
  portInDraftInventories: OrderNumberInventory[];
  portInOrderInventories: OrderNumberInventory[];
}

export interface OrphanedDevice {
  clusterId: string;
  name: string;
  pkid: string;
  type: string;
}

export interface OrphanedDeviceList {
  results: OrphanedDevice[];
}

export interface PcceAgent {
  agentDeskSettings: string;
  agentStateTrace: boolean;
  agentTeam: string;
  booleanAgentAttributes: { [index: string]: boolean };
  department: string;
  description: string;
  finesseId: string;
  firstName: string;
  id: string;
  lastName: string;
  loginEnabled: boolean;
  password: string;
  proficiencyAgentAttributes: { [index: string]: number };
  skillGroups: string[];
  ssoEnabled: boolean;
  supervisor: boolean;
  userName: string;
}

export interface PcceAgentRef extends AbstractCiscoRef {
  username: string;
}

export interface PcceAgentResult {
  ref: PcceAgentRef;
}

export interface PcceAgentsFieldConfig {
  booleanAttributes: string[];
  departments: SelectFieldConfig<string>;
  description: TextFieldConfig;
  deskSettings: SelectFieldConfig<string>;
  enableSso: CustomCheckboxFieldConfig;
  proficiencyAttributes: string[];
  skillGroups: SelectFieldConfig<string>;
  teams: SelectFieldConfig<string>;
  username: TextFieldConfig;
}

export interface PcceAttribute {
  name: string;
  refUrl: string;
  type: string;
}

export interface PcceCommonMetadata {
  name: string;
  refUrl: string;
}

export interface PcceDepartment extends PcceCommonMetadata {
  attributes: PcceAttribute[];
  deskSettings: PcceCommonMetadata[];
  skillGroups: PcceCommonMetadata[];
  teamRefs: PcceCommonMetadata[];
}

export interface PcceMetadata {
  attributes: PcceAttribute[];
  departments: PcceDepartment[];
  description: string;
  deskSettings: PcceCommonMetadata[];
  skillGroups: PcceCommonMetadata[];
  teams: PcceTeamMetadata[];
}

export interface PcceTeam {
  agents: PcceTeamAgent[];
  datacenter: BaseRef;
  department: BaseRef;
  peripheralSet: BaseRef;
  teamName: string;
  teamRefUrl: string;
}

export interface PcceTeamAgent {
  agentId: string;
  firstName: string;
  lastName: string;
  refUrl: string;
  userName: string;
}

export interface PcceTeamMetadata {
  datacenter: PcceCommonMetadata;
  peripheralSet: PcceCommonMetadata;
  team: PcceCommonMetadata;
}

export interface Phone {
  aarClassOfService: string;
  alwaysUsePrimeLine: string;
  alwaysUsePrimeLineForVoiceMessage: string;
  associatedAppUsers: AppUserRef[];
  associatedEndUsers: EndUserRef[];
  builtInBridge: string;
  buttons: Button[];
  ciscoSupportField: string;
  classOfService: string;
  commonDeviceConfiguration: string;
  commonPhoneProfile: string;
  description: string;
  deviceMobilityMode: string;
  devicePool: string;
  disableSpeakerPhone: boolean;
  emergencyNumbers: string;
  expansionModules: string[];
  extDataLocationAuthServer: string;
  extDataLocationSecureAuthUrl: string;
  featureControlPolicyName: string;
  id: string;
  location: string;
  mediaResourceGroupList: string;
  model: string;
  name: string;
  networkMohSourceName: string;
  owner: EndUserRef;
  phoneButtonTemplate: PhoneButtonTemplateRef;
  phoneLoadName: string;
  privacy: string;
  protocol: string;
  reroutingCallingSearchSpace: string;
  securityProfileName: string;
  serviceSubscriptions: PhoneServiceSubscription[];
  sipProfileName: string;
  softkeyTemplate: string;
  subscribeCss: string;
  userLocale: string;
  userMohSourceName: string;
}

export interface PhoneButtonTemplate {
  buttons: TemplateButton[];
  id: string;
  model: string;
  name: string;
  protocol: string;
  standardTemplate: boolean;
}

export interface PhoneButtonTemplateFieldConfig {
  availableButtonFeatures: string[];
  buttons: TemplateButton[];
  ref: PhoneButtonTemplateRef;
}

export interface PhoneButtonTemplateFieldConfigRequest {
  model: string;
  protocol: string;
  siteId: number;
}

export interface PhoneButtonTemplateInventory {
  cucmDeviceDefault: boolean;
  cucmId: number;
  id: string;
  model: string;
  name: string;
  phoneRefs: number;
  protocol: string;
  standardTemplate: boolean;
}

export interface PhoneButtonTemplateRef extends AbstractCiscoRef {
  name: string;
}

export interface PhoneButtonTemplateResult {
  model: string;
  protocol: string;
  ref: PhoneButtonTemplateRef;
  standardTemplate: boolean;
}

export interface PhoneButtonTemplateSearch {
  cucmServerId: number;
  features: string[];
  matchLimit: number;
  model: string;
  protocol: string;
}

export interface PhoneFieldConfig {
  aarCallingSearchSpace: SelectFieldConfig<string>;
  alwaysUsePrimeLine: SelectFieldConfig<string>;
  alwaysUsePrimeLineForVoiceMessage: SelectFieldConfig<string>;
  associatedAppUsers: CustomMultiSelectAppUserRefFieldConfig;
  associatedEndUsers: CustomMultiSelectEndUserRefFieldConfig;
  builtInBridge: SelectFieldConfig<string>;
  callingSearchSpace: SelectFieldConfig<string>;
  ciscoSupportField: TextFieldConfig;
  commonDeviceConfiguration: SelectFieldConfig<string>;
  commonPhoneProfile: SelectFieldConfig<string>;
  description: TextFieldConfig;
  deviceMobilityMode: SelectFieldConfig<string>;
  devicePool: string;
  disableSpeakerPhone: CustomCheckboxFieldConfig;
  emergencyNumbers: TextFieldConfig;
  expansionModules: ExpansionModuleModel[];
  extDataLocationAuthServer: TextFieldConfig;
  extDataLocationSecureAuthUrl: TextFieldConfig;
  featureControlPolicy: SelectFieldConfig<string>;
  location: SelectFieldConfig<string>;
  mediaResourceGroupList: SelectFieldConfig<string>;
  mobilityIdentityFieldConfigJson: MobilityIdentityFieldConfig;
  name: TextFieldConfig;
  networkMohAudioSource: SelectFieldConfig<string>;
  phoneServices: MultiSelectFieldConfig<PhoneServiceSubscription>;
  privacy: SelectFieldConfig<string>;
  reroutingCallingSearchSpace: SelectFieldConfig<string>;
  securityProfileName: SelectFieldConfig<string>;
  sipProfileName: SelectFieldConfig<string>;
  softkeyTemplate: SelectFieldConfig<string>;
  subscribeCss: SelectFieldConfig<string>;
  userLocale: SelectFieldConfig<string>;
  userMohAudioSource: SelectFieldConfig<string>;
}

export interface PhoneRef extends AbstractCiscoRef {
  description: string;
  devicePool: string;
  model: string;
  name: string;
  protocol: string;
}

export interface PhoneResult {
  owner: EndUserRef;
  ref: PhoneRef;
}

export interface PhoneService {
  id: string;
  name: string;
  parameters: PhoneServiceParameter[];
}

export interface PhoneServiceParameter {
  defaultValue: string;
  displayName: string;
  name: string;
  password: boolean;
  required: boolean;
}

export interface PhoneServiceSubscription {
  parameters: PhoneServiceParameter[];
  phoneServiceId: string;
  phoneServiceName: string;
  phoneServiceSubscriptionName: string;
}

export interface PhoneStatus {
  status: DeviceRegistrationStatus;
}

export interface PhoneSummary {
  description: string;
  devicePool: string;
  id: string;
  model: string;
  name: string;
}

export interface PhonesLineReport {
  applicableFieldsJson: ApplicableFields;
  lineFeatureJson: LineFeature;
  phoneResultJson: PhoneResult;
}

export interface PortInDraftDocument {
  documentId: number;
  documentName: string;
  documentType: DocumentType;
}

export interface PortInOrderDraft {
  accountNumber: string;
  addressLine2: string;
  billingTelephoneNumber: string;
  businessName: string;
  city: string;
  country: string;
  customerOrderId: string;
  desiredFocDate: string;
  draftId: number;
  houseNumber: string;
  isTollFree: boolean;
  loaAuthorizingPerson: string;
  losingCarrierName: string;
  newBillingTelephoneNumber: string;
  partialPort: boolean;
  phoneNumbers: string[];
  pinNumber: string;
  rateCenterName: string;
  stateCode: string;
  streetName: string;
  zip: string;
}

export interface PortInOrderDraftRef extends AbstractMsRef {
  billingNumber: string;
  customerOrderId: string;
  desiredFocDate: string;
  invoiceOrCsr: DraftItemStatus;
  loa: DraftItemStatus;
  numbers: string[];
  requiredInformation: DraftItemStatus;
}

export interface PostLdapUser {
  distinguishedName: string;
  email: string;
  firstName: string;
  lastName: string;
  userId: string;
  userLogonName: string;
}

export interface PostPhoneButtonTemplate {
  features: string[];
  model: string;
  protocol: string;
}

export interface PostVoicemail extends Voicemail {
  template: string;
}

export interface PrePortedNumberStatus {
  numbers: NumberStatus[];
  order: PrePortedOrderRef;
}

export interface PrePortedOrderRef {
  actualFocDate: string;
  id: string;
  name: string;
  requestedFoc: string;
  status: string;
  url: string;
}

export interface ProductInfoData extends Serializable {
  model: string;
  protocol: string;
}

export interface ProvisioningEmailRecipients {
  allOutcomeRecipients: string[];
  failureRecipients: string[];
  successRecipients: string[];
}

export interface ProvisioningSchedule {
  dailyJobTime: string;
  emailRecipients: ProvisioningEmailRecipients;
  id: number;
  ldapFilter: string;
  name: string;
  options: ZeroTouchProvisioningOptions;
  periodicJobMinutes: number;
}

export interface ProxyKeyResponse {
  proxyKey: string;
}

export interface ProxyKeysSettings {
  generatedDate: string;
  keyFirstThree: string;
}

export interface PstnFailoverSection {
  customSelects: CustomSelect[];
}

export interface Ref {
}

export interface SamlSsoConfig {
  ssoEnabled: boolean;
}

export interface SbcConfiguration {
  dialplanGroupName: string;
  number: string;
  numberConfigurationType: SbcConfigurationType;
  sbcCarrier: string;
}

export interface SbcConfigurationReport {
  sbcNumberConfigurations: SbcConfiguration[];
}

export interface SearchFieldConfig {
  cucmSearchEnabled: boolean;
  unitySearchEnabled: boolean;
  webexSearchEnabled: boolean;
}

export interface SecurityProfile extends Serializable {
  mode: DeviceSecurityProfileMode;
  name: string;
  productInfoData: ProductInfoData;
}

export interface Select extends AbstractFrontEndObject {
  value: string;
}

export interface SelectFieldConfig<T> {
  defaultValue: T;
  possibleOptions: T[];
  required: boolean;
  show: boolean;
}

export interface SelfServeOptions {
  selfServeCallForwardEditEnabled: boolean;
  selfServeDeskphoneActivationEnabled: boolean;
  selfServeDeskphoneReplacementEnabled: boolean;
  selfServeEnhancedLineModeEnabled: boolean;
  selfServeSnrEditEnabled: boolean;
  selfServeSpeedDialEditEnabled: boolean;
  selfServeVoicemailPinResetComplexity: string;
}

export interface Serializable {
}

export interface Server {
  description: string;
  disabled: boolean;
  hostAddress: string;
  id: number;
  password: string;
  port: number;
  serverType: string;
  username: string;
}

export interface ServerLdapSyncInfo {
  errorMessage: string;
  id: number;
  name: string;
  status: LdapSyncStatus;
}

export interface ServiceAddress {
  addressLine2: string;
  addressType: string;
  city: string;
  country: string;
  houseNumber: string;
  stateCode: string;
  streetName: string;
  zip: string;
}

export interface ServiceSetting {
  checkboxes: Checkbox[];
  customCheckboxes: CustomCheckbox[];
  customInputTexts: CustomInputText[];
  customMultiSelects: CustomMultiSelect[];
  customSelects: CustomSelect[];
  enabled: EnabledType;
  multiSelects: MultiSelect[];
  name: string;
  oneClickEnabled: boolean;
  selects: Select[];
}

export interface ServiceUrlButton extends Button {
  label: string;
  subscriptionName: string;
}

export interface SharedCallingExtension {
  destination: string;
  extension: string;
}

export interface SharedCallingExtensionRequest {
  sharedCallingExtension: string;
}

export interface SharedCallingRoutingPolicy {
  policyName: string;
  resourceAccountDisplayName: string;
  resourceAccountLineUri: string;
  resourceAccountUpn: string;
}

export interface SignedCertificate {
  certificate: string;
}

export interface Site {
  id: string;
  ldapSiteName: string;
  name: string;
  serviceSettings: ServiceSetting[];
}

export interface SiteBasedHelpdeskUserPermission {
  authorizedClusters: string[];
  authorizedSites: string[];
  disabled: boolean;
  readAccessToControlEnabled: boolean;
  userId: string;
}

export interface SiteBasedHelpdeskUserPermissions {
  groupNotFound: boolean;
  namingError: boolean;
  permissions: SiteBasedHelpdeskUserPermission[];
}

export interface SiteIdConfigRequest {
  siteId: number;
}

export interface SiteResult {
  devicePools: DevicePoolResult[];
  hasPermission: boolean;
  id: number;
  ldapSiteName: string;
  name: string;
  unityServerDescription: string;
  unityServerId: number;
}

export interface SiteSearchResult {
  clusterId: number;
  id: number;
  name: string;
  url: string;
}

export interface SiteSummary {
  clusters: ClusterResult[];
}

export interface SmacsError {
  description: string;
  reasonCode: string;
  stacktrace: string[];
  timestamp: string;
  version: string;
}

export interface SmtpAddress {
  email: string;
}

export interface SnrDestination {
  answerTooLateTimerInSeconds: number;
  answerTooSoonTimerInSeconds: number;
  delayBeforeRingingInSeconds: number;
  destinationName: string;
  destinationNumber: string;
  id: string;
}

export interface SnrDestinationFieldConfig {
  answerTooLateTimerInSeconds: TextFieldConfig;
  answerTooSoonTimerInSeconds: TextFieldConfig;
  delayBeforeRingingInSeconds: TextFieldConfig;
  destinationName: TextFieldConfig;
  destinationNumber: TextFieldConfig;
}

export interface SnrDestinationRef extends AbstractCiscoRef {
  destination: string;
  name: string;
}

export interface SnrDestinationResult {
  ref: SnrDestinationRef;
}

export interface SnrFieldConfig {
  aarCallingSearchSpace: SelectFieldConfig<string>;
  callingSearchSpace: SelectFieldConfig<string>;
  devicePool: string;
  networkMohAudioSource: SelectFieldConfig<string>;
  privacy: SelectFieldConfig<string>;
  profileDescription: TextFieldConfig;
  profileName: TextFieldConfig;
  reroutingCallingSearchSpace: SelectFieldConfig<string>;
  snrDestination: SnrDestinationFieldConfig;
  userMohAudioSource: SelectFieldConfig<string>;
}

export interface SnrFieldConfigRequest {
  siteId: string;
  username: string;
}

export interface SnrProfile {
  aarCss: string;
  css: string;
  description: string;
  devicePool: string;
  id: string;
  lines: LineButton[];
  model: string;
  name: string;
  networkMohSourceName: string;
  privacy: string;
  protocol: string;
  reRoutingCss: string;
  user: EndUserRef;
  userMohSourceName: string;
}

export interface SnrProfileLineReport {
  snrProfileResultJson: SnrProfileResult;
}

export interface SnrProfileRef extends AbstractCiscoRef {
  description: string;
  devicePool: string;
  name: string;
}

export interface SnrProfileResult {
  model: string;
  owner: EndUserRef;
  ref: SnrProfileRef;
}

export interface SnrSummary {
  description: string;
  devicePool: string;
  id: string;
  name: string;
  snrDestinations: SnrDestinationRef[];
}

export interface SpeedDialButton extends Button {
  label: string;
  value: string;
}

export interface SqlQuery {
  query: string;
}

export interface SqlQueryResult {
  count: number;
  results: { [index: string]: string }[];
}

export interface Status {
  cause: string;
  state: State;
}

export interface Subscriber {
  businessName: string;
  serviceAddress: ServiceAddress;
  subscriberType: string;
}

export interface SyncSettings {
  host: string;
  secret: string;
}

export interface SystemHealthStatus {
  endpointDisabled: boolean;
  generatedTime: string;
  globalState: State;
  healthStatuses: HealthStatus[];
}

export interface TeamsCallingCallPickupGroup {
  callPickupGroupMembers: string[];
  userPrincipalName: string;
}

export interface TeamsEntraRole {
  roleName: string;
  roleUuid: string;
}

export interface TeamsPhoneNumberAssignment {
  emergencyLocation: EmergencyLocation;
  lineUri: string;
  lineUriExtension: string;
  phoneNumberType: PhoneNumberType;
  userPrincipalName: string;
}

export interface TeamsPhoneNumberAssignmentFieldConfig {
  emergencyLocation: SelectFieldConfig<EmergencyLocation>;
  lineUriExtension: TextFieldConfig;
  phoneNumberType: PhoneNumberType;
}

export interface TeamsPhoneNumberAssignmentRef extends AbstractMsRef {
}

export interface TeamsPstnCallingUsageDetail {
  accountType: AccountType;
  displayName: string;
  lineUri: string;
  numberOfCalls: number;
  totalCallDurationInSeconds: number;
  userPrincipalName: string;
}

export interface TeamsVoicePolicies {
  callingLineIdentity: string;
  callingPolicy: string;
  emergencyCallRoutingPolicy: string;
  emergencyCallingPolicy: string;
  ipPhonePolicy: string;
  onlineVoiceRoutingPolicy: string;
  onlineVoicemailPolicy: string;
  sharedCallRoutingPolicy: SharedCallingRoutingPolicy;
  teamsCallHoldPolicy: string;
  teamsCallParkPolicy: string;
  tenantDialPlan: string;
  userPrincipalName: string;
}

export interface TeamsVoicePoliciesFieldConfig {
  callingLineIdentity: SelectFieldConfig<string>;
  callingPolicy: SelectFieldConfig<string>;
  emergencyCallRoutingPolicy: SelectFieldConfig<string>;
  emergencyCallingPolicy: SelectFieldConfig<string>;
  ipPhonePolicy: SelectFieldConfig<string>;
  onlineVoiceRoutingPolicy: SelectFieldConfig<string>;
  onlineVoicemailPolicy: SelectFieldConfig<string>;
  sharedCallingRoutingPolicy: SelectFieldConfig<SharedCallingRoutingPolicy>;
  teamsCallHoldPolicy: SelectFieldConfig<string>;
  teamsCallParkPolicy: SelectFieldConfig<string>;
  tenantDialPlan: SelectFieldConfig<string>;
}

export interface TeamsVoicePoliciesRef extends AbstractMsRef {
}

export interface TemplateButton {
  feature: string;
  fixed: boolean;
}

export interface TestEmailSettings extends EmailSettings {
  emailAddress: string;
}

export interface TextFieldConfig {
  defaultValue: string;
  required: boolean;
  show: boolean;
}

export interface TileConfig {
  android: ServiceProvisioningLevel;
  cipc: ServiceProvisioningLevel;
  deskphone: ServiceProvisioningLevel;
  extensionMobility: ServiceProvisioningLevel;
  imPresence: ServiceProvisioningLevel;
  imSoftphone: ServiceProvisioningLevel;
  iphone: ServiceProvisioningLevel;
  pcceAgent: ServiceProvisioningLevel;
  primaryExtension: ServiceProvisioningLevel;
  snrDestination: ServiceProvisioningLevel;
  snrProfile: ServiceProvisioningLevel;
  tablet: ServiceProvisioningLevel;
  voicemail: ServiceProvisioningLevel;
  webexCalling: ServiceProvisioningLevel;
}

export interface Timestamp {
  timestamp: string;
}

export interface TranslationPattern {
  classOfService: string;
  description: string;
  destination: string;
  id: string;
  pattern: string;
  routePartition: string;
}

export interface TranslationPatternEntry {
  assignmentStatus: DialPlanGroupEntryStatus;
  deletionDate: string;
  description: string;
  exceptionGroupName: string;
  value: string;
}

export interface TranslationPatternFieldConfig {
  classOfService: SelectFieldConfig<string>;
  description: TextFieldConfig;
  routePartition: SelectFieldConfig<string>;
}

export interface TranslationPatternFieldConfigRequest {
  dialPlanGroupId: number;
  extension: string;
  siteId: number;
  username: string;
}

export interface TranslationPatternRangesSection {
  customSelects: CustomSelect[];
  translationPatternRanges: DnDidRange[];
}

export interface TranslationPatternRef extends AbstractCiscoRef {
  description: string;
  destination: string;
  pattern: string;
}

export interface TranslationPatternResult {
  ref: TranslationPatternRef;
}

export interface UcMetadataCache {
  cucmDeviceModelMetadataCache: { [index: string]: { [index: string]: DeviceModelMetadata } };
  cucmMetadataCache: { [index: string]: CucmMetadata };
  pcceMetadataCache: { [index: string]: PcceMetadata };
  uccxMetadataCache: { [index: string]: UccxMetadata };
  unityMetadataCache: { [index: string]: UnityMetadata };
}

export interface UccxAgent {
  alias: string;
  automaticAvailable: boolean;
  id: string;
  resourceGroup: string;
  skills: UccxAgentSkill[];
  team: string;
  type: number;
}

export interface UccxAgentRef extends AbstractCiscoRef {
}

export interface UccxAgentResult {
  ref: UccxAgentRef;
}

export interface UccxAgentSkill {
  competenceLevel: number;
  name: string;
}

export interface UccxFieldConfig {
  alias: TextFieldConfig;
  automaticAvailable: CustomCheckboxFieldConfig;
  resourceGroup: TextFieldConfig;
  skills: string[];
  team: SelectFieldConfig<string>;
}

export interface UccxMetadata {
  description: string;
  resourceGroups: string[];
  skills: string[];
  teams: string[];
}

export interface UiOptions {
  automationEnabled: boolean;
  customBackground: string;
  customBackgroundColor: string;
  customLogo: string;
  highAvailabilityEnabled: boolean;
  provisioningEnabled: boolean;
  proxyServerOnlyModeEnabled: boolean;
  reportingEnabled: boolean;
  ssoEnabled: boolean;
  supportTextHtml: string;
}

export interface UiProperties {
  backgroundColor: string;
  backgroundImage: string;
  logo: string;
  supportTextHtml: string;
}

export interface UnityMetadata {
  partitionObjectIds: string[];
  voicemailTemplates: string[];
}

export interface UnusedDevicesReport {
  numCalls: number;
  numUnusedDevices: number;
  totalNumDevices: number;
}

export interface UpnFeatureTypeLineUri {
  featureTypes: string[];
  lineUri: string;
  upn: string;
}

export interface UpnValidation {
  optionalAssignedLineUri: string;
  upnValidationStatus: UpnValidationStatusEnum;
  userPrincipalName: string;
}

export interface UpnValidationRequest {
  userPrincipalNames: string[];
}

export interface User365LicensesSearchResult {
  licenses: Microsoft365License[];
  userPrincipalName: string;
}

export interface UserGroupPermissions {
  name: string;
  permittedDialPlanGroups: string[];
  role: Role;
  type: Type;
}

export interface UserPhoto {
  displayName: string;
  photoBase64: string;
  userPrincipalName: string;
}

export interface UserPhotosRequest {
  photoSize: PhotoSize;
  userPrincipalNames: string[];
}

export interface Users365LicensesSearchRequest {
  userPrincipalNames: string[];
}

export interface Uuid {
  id: string;
}

export interface UuidData extends Serializable {
}

export interface UuidDataParam {
  value: UuidData;
}

export interface ValidateQueryRequest {
  query: string;
}

export interface ValidateQueryResponse {
  result: string;
}

export interface Version {
  version: string;
}

export interface Voicemail {
  alias: string;
  displayName: string;
  extension: string;
  firstName: string;
  id: string;
  lastName: string;
}

export interface VoicemailAlternateExtension {
  extension: string;
  id: string;
}

export interface VoicemailAlternateExtensionRef extends AbstractCiscoRef {
  extension: string;
}

export interface VoicemailFieldConfig {
  alias: TextFieldConfig;
  alternateExtensions: MultiTextFieldConfig;
  displayName: TextFieldConfig;
  firstName: TextFieldConfig;
  lastName: TextFieldConfig;
  smtpNotificationDevice: TextFieldConfig;
  voicemailTemplate: SelectFieldConfig<string>;
}

export interface VoicemailFieldConfigRequest {
  extension: string;
  siteId: number;
}

export interface VoicemailPinResetRequest {
  pin: string;
  resetOnNextLogin: boolean;
}

export interface VoicemailRef extends AbstractCiscoRef {
  alias: string;
  extension: string;
}

export interface VoicemailResult {
  firstName: string;
  lastName: string;
  ref: VoicemailRef;
}

export interface WebexCalling extends Ref {
  did: string;
  displayName: string;
  email: string;
  id: string;
  url: string;
}

export interface WebexCallingSettings {
  clientId: string;
  clientSecret: string;
  redirectUri: string;
}

export interface WebexCallingStatus {
  accessTokenExpirationDate: string;
  accessTokenGenerationDate: string;
  currentUserId: string;
  status: WebexCallingStatusEnum;
}

export interface WebexDialPlanFieldConfig {
  groupId: string;
  groupName: string;
}

export interface WebexDialPlanInventory {
  dialPlanEntries: DialPlanGroupEntry[];
  groupId: string;
  groupName: string;
}

export interface WebexEndUser {
  did: string;
  displayName: string;
  email: string;
  extension: string;
  location: string;
  photoUrl: string;
  userId: string;
  webexCallingLicense: string;
}

export interface WebexEndUserResult {
  webexUserRef: WebexUserRef;
}

export interface WebexFieldConfig {
  extension: TextFieldConfig;
  webexCallingLicenseJson: SelectFieldConfig<string>;
}

export interface WebexUserRef extends Ref {
  displayName: string;
  email: string;
  url: string;
  userId: string;
}

export interface WildcardCertificate {
  certificate: string;
  fqdn: string;
  key: string;
}

export interface WipeCucmCdrTableRequest {
  endTimestamp: number;
  startTimestamp: number;
}

export interface WriteLdapSection {
  customInputs: CustomInputText[];
}

export interface ZeroTouchAudioConference {
  audioConferenceNumber: string;
}

export interface ZeroTouchJobTeamsCalling {
  dialPlanGroupIds: number[];
  teamsVoicePolicies: TeamsVoicePolicies;
}

export interface ZeroTouchProvisioningOptions {
  agentExtension: boolean;
  android: boolean;
  cipc: boolean;
  dialPlanGroupIds: number[];
  dn: boolean;
  imSoftPhone: boolean;
  imp: boolean;
  iphone: boolean;
  mobility: boolean;
  siteId: number;
  snrDestinationForMicrosoftTeams: boolean;
  snrProfile: boolean;
  tablet: boolean;
  voicemail: boolean;
}

export interface ZeroTouchTeamsLine {
  dialPlanGroupIds: number[];
  lineUri: string;
  lineUriExtension: string;
  teamsVoicePolicies: TeamsVoicePolicies;
}

export interface ZiroCallingSubscription {
  billingProduct: BillingProduct;
  quantity: number;
}

export interface ZiroDraasExtensions {
  abbreviatedDialExtension: string;
  sharedExtensions: SharedCallingExtension[];
}

export interface ZiroSupportSettings {
  pwshCmdletDelayInSeconds: number;
  pwshPoolSize: number;
  pwshUserCacheExpiryInSeconds: number;
}

export interface ZpcProxySettings {
  teamsPhoneNumberAssignmentCheckEnabled: boolean;
  teamsPhoneNumberAssignmentLastDigitsMatch: number;
}

export interface ZpmCiscoOnPremise360View {
  associatedPublicPhones: PhoneRef[];
  endUsers: EndUserResult[];
  extensionMobilities: ExtensionMobilityRef[];
  ipccExtensions: DirectoryNumberRef[];
  pcceAgents: PcceAgentRef[];
  phones: PhoneRef[];
  primaryExtensionCandidates: DirectoryNumberRef[];
  primaryExtensions: DirectoryNumberRef[];
  snrProfiles: SnrProfileRef[];
  translationPatterns: TranslationPatternRef[];
  uccxAgents: UccxAgentRef[];
  voicemails: VoicemailRef[];
}

export interface ZpmProxySettings {
  cucmPhoneNumberAssignmentCheckEnabled: boolean;
  cucmPhoneNumberAssignmentLastDigitsMatch: number;
  onPremAdWriteDownEnabled: boolean;
}

export interface ZpmTileConfig {
  calling: TileStatus;
  dubber: TileStatus;
  groups: TileStatus;
  license: TileStatus;
  meeting: TileStatus;
}

export interface ZpmUseCase {
  higherPrivileges: string[];
  isEnabled: boolean;
  isRequired: boolean;
  leastPermission: string;
  name: string;
}

export enum AccountType {
  USER = "USER",
  RESOURCE_ACCOUNT = "RESOURCE_ACCOUNT",
  SFB_ON_PREM_USER = "SFB_ON_PREM_USER",
  GUEST = "GUEST",
  UNKNOWN = "UNKNOWN",
  UPN_NOT_IN_TEAMS = "UPN_NOT_IN_TEAMS",
  INELIGIBLE_USER = "INELIGIBLE_USER",
}

export enum BillingProduct {
  ZPM_SUBSCRIPTION = "ZPM_SUBSCRIPTION",
  ZIRO_DRAAS = "ZIRO_DRAAS",
  ZIRO_DRAAS_EXTENSION = "ZIRO_DRAAS_EXTENSION",
  ZIRO_SPARE_DID = "ZIRO_SPARE_DID",
  ZIRO_PSTN_CONNECTIVITY_OTHER = "ZIRO_PSTN_CONNECTIVITY_OTHER",
  ZIRO_DRAAS_BYOC = "ZIRO_DRAAS_BYOC",
  ZIRO_DRAAS_SHARED_CALLING_DID = "ZIRO_DRAAS_SHARED_CALLING_DID",
}

export enum CallingType {
  DID = "DID",
  EXTENSION = "EXTENSION",
  SHARED_CALLING = "SHARED_CALLING",
}

export enum DeviceRegistrationStatus {
  REGISTERED = "REGISTERED",
  UNREGISTERED = "UNREGISTERED",
  NOT_FOUND = "NOT_FOUND",
}

export enum DeviceSecurityProfileMode {
  NON_SECURE = "NON_SECURE",
  AUTHENTICATED = "AUTHENTICATED",
  ENCRYPTED = "ENCRYPTED",
}

export enum DeviceType {
  DESK_PHONE = "Desk Phone",
  IM_SOFTPHONE = "IM Softphone",
  ANDROID = "Android",
  IPHONE = "iPhone",
  TABLET = "Tablet",
  EXTENSION_MOBILITY = "Extension Mobility",
  CIPC = "CIPC",
  SINGLE_NUMBER_REACH = "Single Number Reach",
  CTI_PORT = "CTI Port",
  CTI_REMOTE_DEVICE = "CTI Remote Device",
  CISCO_SPARK_REMOTE_DEVICE = "Cisco Spark Remote Device",
}

export enum DialPlanGroupEntryStatus {
  ASSIGNED = "ASSIGNED",
  UNASSIGNED = "UNASSIGNED",
  INACTIVE = "INACTIVE",
  RECENTLY_DELETED = "RECENTLY_DELETED",
  RESTRICTED = "RESTRICTED",
  TEAMS_UNASSIGNED_NUMBER_ROUTING_RULE = "TEAMS_UNASSIGNED_NUMBER_ROUTING_RULE",
}

export enum DialPlanGroupEntryType {
  CUCM = "CUCM",
  TEAMS = "TEAMS",
  WEBEX = "WEBEX",
}

export enum DocumentType {
  LOA = "LOA",
  INVOICE = "INVOICE",
  CSR = "CSR",
  OTHER = "OTHER",
}

export enum DraftItemStatus {
  SUBMITTED = "SUBMITTED",
  MISSING = "MISSING",
}

export enum EmailTransportStrategy {
  SMTP = "SMTP",
  SMTPS = "SMTPS",
  TLS = "TLS",
}

export enum EmailUserOperationLevel {
  HIDE = "HIDE",
  SHOW = "SHOW",
  SHOW_WITH_TABLE = "SHOW_WITH_TABLE",
}

export enum EnabledType {
  ENABLED = "enabled",
  DISABLED = "disabled",
  NOT_APPLICABLE = "not_applicable",
}

export enum EnvironmentType {
  COMMERCIAL = "Commercial",
  GCC_HIGH = "GCC High",
}

export enum ForwardingTargetType {
  NONE = "NONE",
  VOICEMAIL = "VOICEMAIL",
  SINGLE_TARGET = "SINGLE_TARGET",
  DELEGATES = "DELEGATES",
  CALL_PICKUP_GROUP = "CALL_PICKUP_GROUP",
}

export enum ForwardingType {
  NONE = "NONE",
  SIMULTANEOUS = "SIMULTANEOUS",
  IMMEDIATE = "IMMEDIATE",
}

export enum JobState {
  IDLE = "idle",
  QUEUED = "queued",
  RUNNING = "running",
}

export enum LdapSyncStatus {
  SYNCING = "SYNCING",
  IDLE = "IDLE",
  ERROR = "ERROR",
}

export enum LicensingState {
  UNLICENSED = "UNLICENSED",
  LICENSING_IN_PROGRESS = "LICENSING_IN_PROGRESS",
  LICENSED = "LICENSED",
  LICENSED_WITH_MICROSOFT_CALLING_PLAN = "LICENSED_WITH_MICROSOFT_CALLING_PLAN",
}

export enum Microsoft365UserLicenseStatus {
  IDLE = "IDLE",
  LICENSING_IN_PROGRESS = "LICENSING_IN_PROGRESS",
  UNLICENSING_IN_PROGRESS = "UNLICENSING_IN_PROGRESS",
}

export enum MisconfigurationType {
  MISSING_DLR = "MISSING_DLR",
  ORPHAN_DLR = "ORPHAN_DLR",
}

export enum PhoneNumberType {
  DIRECT_ROUTING = "DIRECT_ROUTING",
  MICROSOFT_CALLING_PLANS = "MICROSOFT_CALLING_PLANS",
  OPERATOR_CONNECT = "OPERATOR_CONNECT",
}

export enum PhotoSize {
  SIZE_48_X_48 = "48x48",
  SIZE_64_X_64 = "64x64",
  SIZE_96_X_96 = "96x96",
  SIZE_120_X_120 = "120x120",
  SIZE_240_X_240 = "240x240",
  SIZE_360_X_360 = "360x360",
  SIZE_432_X_432 = "432x432",
  SIZE_504_X_504 = "504x504",
  SIZE_648_X_648 = "648x648",
}

export enum PortType {
  MIXED = "MIXED",
  AUTOMATED = "AUTOMATED",
  INTERNAL = "INTERNAL",
  MANUALOFFNET = "MANUALOFFNET",
  MANUALONNET = "MANUALONNET",
  MANUALTOLLFREE = "MANUALTOLLFREE",
  AUTOMATEDOFFNET = "AUTOMATEDOFFNET",
}

export enum Privilege {
  CONTROL_READ = "CONTROL_READ",
  CONTROL_WRITE = "CONTROL_WRITE",
}

export enum PstnConnectivityType {
  DIRECT_ROUTING = "DIRECT_ROUTING",
  OPERATOR_CONNECT = "OPERATOR_CONNECT",
  ZIRO_DRAAS = "ZIRO_DRAAS",
  ZIRO_DRAAS_BYOC = "ZIRO_DRAAS_BYOC",
  MICROSOFT_CALLING_PLANS = "MICROSOFT_CALLING_PLANS",
}

export enum RangeType {
  DID = "DID",
  EXTENSION = "EXTENSION",
}

export enum Role {
  ZIRO_SUPPORT = "ZIRO_SUPPORT",
  S8_ADMIN = "S8_ADMIN",
  S8_HELPDESK = "S8_HELPDESK",
  S8_GLOBAL_HELPDESK = "S8_GLOBAL_HELPDESK",
  S8_SITE_BASED_HELPDESK = "S8_SITE_BASED_HELPDESK",
  S8_SELF_SERVE_USER = "S8_SELF_SERVE_USER",
  S8_SYNC = "S8_SYNC",
  ANONYMOUS = "ANONYMOUS",
}

export enum SbcConfigurationType {
  CORRECTLY_CONFIGURED = "CORRECTLY_CONFIGURED",
  DRAAS_MISSING_FROM_ZPM = "DRAAS_MISSING_FROM_ZPM",
  BYOC_MISSING_FROM_ZPM = "BYOC_MISSING_FROM_ZPM",
  DRAAS_MISSING_FROM_SBC = "DRAAS_MISSING_FROM_SBC",
  BYOC_MISSING_FROM_SBC = "BYOC_MISSING_FROM_SBC",
  SBC_NUMBER_MISSING_CARRIER = "SBC_NUMBER_MISSING_CARRIER",
}

export enum ServiceProvisioningLevel {
  SHOW = "SHOW",
  HIDE = "HIDE",
  ONE_CLICK = "ONE_CLICK",
}

export enum State {
  OK = "OK",
  WARNING = "Warning",
  ERROR = "Error",
}

export enum StatusCategory {
  ENDPOINTS = "Endpoints",
  LICENSES = "Licenses",
  TRAFFIC = "Traffic",
  VIRTUAL_MACHINE = "Virtual Machine",
  HIGH_AVAILABILITY_REPLICATION = "High Availability Replication",
  PROXY_SERVER = "Proxy Server",
}

export enum TileStatus {
  SHOW = "SHOW",
  SHOW_WITH_DELETE = "SHOW_WITH_DELETE",
  HIDE = "HIDE",
}

export enum TlsVoiceEnvironmentType {
  PRODUCTION = "Production",
  TEST = "Test",
}

export enum Type {
  GROUP = "GROUP",
  USER = "USER",
}

export enum UnansweredDelayInSeconds {
  NONE = 0,
  SECONDS_10 = 10,
  SECONDS_20 = 20,
  SECONDS_30 = 30,
  SECONDS_40 = 40,
  SECONDS_50 = 50,
  SECONDS_60 = 60,
}

export enum UnansweredTargetType {
  NONE = "NONE",
  DELEGATES = "DELEGATES",
  VOICEMAIL = "VOICEMAIL",
  SINGLE_TARGET = "SINGLE_TARGET",
  CALL_PICKUP_GROUP = "CALL_PICKUP_GROUP",
}

export enum UpnValidationStatusEnum {
  UPN_NOT_FOUND = "UPN_NOT_FOUND",
  VOICE_LICENSE_MISSING = "VOICE_LICENSE_MISSING",
  LINEURI_ALREADY_ASSIGNED = "LINEURI_ALREADY_ASSIGNED",
  UPN_VALID = "UPN_VALID",
}

export enum UsageType {
  RESOURCE_ACCOUNT = "RESOURCE_ACCOUNT",
  CONFERENCE_BRIDGE = "CONFERENCE_BRIDGE",
  USER = "USER",
  NOT_APPLICABLE = "NOT_APPLICABLE",
  NUMBER_NOT_FOUND_IN_TEAMS = "NUMBER_NOT_FOUND_IN_TEAMS",
}

export enum WebexCallingStatusEnum {
  OK = "OK",
  ERROR = "ERROR",
  NOT_CONFIGURED = "NOT_CONFIGURED",
}
