import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CiscoDialPlanFieldConfig } from '../models/generated/smacsModels';

@Injectable()
export class CiscoDialPlanFieldConfigResource {
  constructor(private http: HttpClient) {}

  get(siteId: number): Observable<CiscoDialPlanFieldConfig[]> {
    return this.http.get<CiscoDialPlanFieldConfig[]>(`/services/cisco/field-configs/dial-plans?siteId=${siteId}`);
  }
}
