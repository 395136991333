import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UccxAgent, UccxAgentRef, UccxAgentResult } from '../../../shared/models/generated/smacsModels';
import { Nvp } from '../../../shared/models/nvp';
import { AuditHeaderService } from '../../../shared/services/audit-header.service';

@Injectable()
export class UccxAgentResource {
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  get(serverId: string, agentId: string): Observable<UccxAgent> {
    return this.http.get<UccxAgent>(`/services/cisco/macs/uccx-servers/${serverId}/uccx-agents/${agentId}`);
  }

  put(uccxAgent: UccxAgent, serverId: number, agentId: string): Observable<UccxAgentRef> {
    const url = `/services/cisco/macs/uccx-servers/${serverId}/uccx-agents/${agentId}`;
    return this.http.put<UccxAgentRef>(url, uccxAgent);
  }

  search(username: string, serverId?: number): Observable<UccxAgentResult[]> {
    let url = `/services/cisco/macs/uccx-agents/search?username=${encodeURIComponent(username)}`;
    if (serverId) {
      url += `&uccx-server-id=${serverId}`;
    }
    return this.http.get<UccxAgentResult[]>(url);
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }
}
