<div class="card mt-3" data-automation="certificate-management-generate-csr">
  <div class="card-header">{{ 'tkey;certificate_management.generate_csr.title' | translate }}</div>

  <div class="card-body">
    <div class="container-fluid" [hidden]="displayCsr | async">
      <div class="alert alert-info" [hidden]="!existingCsr">
        <table>
          <tbody>
            <tr>
              <td>
                <i class="{{ smacsIcons.INFO }} text-info fa-2x"></i>
              </td>
              <td>
                {{ 'tkey;certificate_management.generate_csr.alert_line1' | translate }}
                <br />
                <span class="strong text-black">
                  <button
                    type="button"
                    class="btn btn-link"
                    (click)="setDisplayCsr(true)"
                    data-automation="admin-generate-csr-view-csr-button"
                  >
                    {{ 'tkey;certificate_management.generate_csr.alert_line2' | translate }}
                  </button>

                  ({{ csrTimestamp | async }})
                  {{ 'tkey;certificate_management.generate_csr.alert_line3' | translate }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <fieldset [disabled]="isGeneratingCsr">
        <app-admin-generate-csr-form
          [entity]="distinguishedNameProperties"
          (smacsFormsUpdate$)="onFormUpdate($event)"
        ></app-admin-generate-csr-form>
      </fieldset>
    </div>

    <div class="container-fluid" [hidden]="!shouldDisplayCsr">
      <h3>{{ 'tkey;certificate_management.generated_csr.title' | translate }} ({{ csrTimestamp | async }})</h3>

      <div>
        <pre data-automation="admin-generate-csr-generated-csr-text">{{ existingCsr?.csr }}</pre>
      </div>

      <div class="text-end">
        <button
          type="button"
          class="btn btn-outline-default me-1"
          (click)="setDisplayCsr(false)"
          data-automation="admin-generate-csr-generated-csr-back-button"
        >
          {{ 'tkey;certificate_management.generated_csr.back_button' | translate }}
        </button>
        <a [href]="fileUrl" [download]="fileName" class="btn btn-primary">
          {{ 'tkey;certificate_management.generated_csr.download_button' | translate }}
        </a>
      </div>
    </div>
  </div>
</div>
