import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { HelpdeskOptionsContext } from '../../../helpdesk/shared/contexts/helpdesk-options.context';
import { AuthenticationContext } from '../../../shared/contexts/authentication.context';
import { map } from 'rxjs/operators';
import { CurrentUser, Role } from '../../../shared/models/generated/smacsModels';

@Component({
  selector: 'ziro-order-view-and-edit-container',
  templateUrl: './order-view-and-edit-container.component.html',
})
export class OrderViewAndEditContainerComponent implements OnInit, OnDestroy {
  isLoading = true;
  companyId: string;
  isUserS8Support: boolean;
  currentUser: CurrentUser;

  private _subscriptions = new Subscription();

  constructor(
    private _helpdeskOptionsContext: HelpdeskOptionsContext,
    private _authenticationContext: AuthenticationContext
  ) {}

  ngOnInit() {
    const subs = combineLatest([this._getIsUserS8Support(), this._getHelpdeskOptions()]).subscribe(() => {
      this.isLoading = false;
    });
    this._subscriptions.add(subs);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  private _getHelpdeskOptions() {
    return this._helpdeskOptionsContext.state$.pipe(
      map((state) => {
        if (this._helpdeskOptionsContext.isMicrosoftHelpdeskOptions(state)) {
          this.companyId = state.companyId;
        }
      })
    );
  }

  private _getIsUserS8Support(): Observable<void> {
    return this._authenticationContext.state$.pipe(
      map((user) => {
        this.isUserS8Support = user.role === Role.ZIRO_SUPPORT;
        this.currentUser = user;
      })
    );
  }
}
