<app-top-nav></app-top-nav>
<div class="smacs-add-user-page-content">
  <div class="container-fluid animated fadeIn">
    <div class="col-md-8 offset-md-2">
      <app-breadcrumbs></app-breadcrumbs>
      <div [hidden]="isLoading">
        <smacs-select [fieldId]="'cucmServer'"></smacs-select>
        <smacs-text [fieldId]="'userName'"></smacs-text>
        <smacs-text [fieldId]="'firstName'"></smacs-text>
        <smacs-text [fieldId]="'lastName'"></smacs-text>
      </div>
    </div>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
