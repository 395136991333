export interface PhoneModelUI {
  numberStandard: number;
  numberPages: number;
  numberLeft: number;
  configurableLeft: boolean;
  enhancedLineModeLeft: boolean;
  numberRight: number;
  configurableRight: boolean;
  enhancedLineModeRight: boolean;
  background: string;
  type: string;
  hasLayout: boolean;
  largestSupportedKem?: string;
  maxAmount?: number;
}

export const undefinedPhoneModelUI = {
  numberStandard: 0,
  numberPages: 1,
  numberLeft: 0,
  configurableLeft: false,
  enhancedLineModeLeft: false,
  numberRight: 0,
  configurableRight: false,
  enhancedLineModeRight: false,
  background: 'none',
  type: 'grid',
  hasLayout: false,
  largestSupportedKem: '',
  maxAmount: 0,
};

export const phoneModels = {
  'Analog Phone': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Carrier-integrated Mobile': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 12 S': {
    numberStandard: 12,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 12 SP': {
    numberStandard: 12,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 12 SP+': {
    numberStandard: 12,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 30 SP+': {
    numberStandard: 26,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 30 VIP': {
    numberStandard: 26,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 3905': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 3911': {
    numberStandard: 2,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 3951': {
    numberStandard: 2,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 6901': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 6911': {
    numberStandard: 10,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 6921': {
    numberStandard: 8,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 6941': {
    numberStandard: 10,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 6945': {
    numberStandard: 10,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 6961': {
    numberStandard: 18,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7811': {
    numberStandard: 7,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7821': {
    numberStandard: 8,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7832': {
    numberStandard: 7,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7841': {
    numberStandard: 10,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7861': {
    numberStandard: 22,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7902': {
    numberStandard: 7,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7905': {
    numberStandard: 5,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7906': {
    numberStandard: 6,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7910': {
    numberStandard: 10,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7911': {
    numberStandard: 6,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7912': {
    numberStandard: 5,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7920': {
    numberStandard: 6,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7921': {
    numberStandard: 6,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7925': {
    numberStandard: 6,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7926': {
    numberStandard: 6,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7931': {
    numberStandard: 24,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7935': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7936': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7937': {
    numberStandard: 12,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7940': {
    numberStandard: 2,
    numberPages: 1,
    type: 'circle',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 2,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7941': {
    numberStandard: 2,
    numberPages: 1,
    type: 'circle',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 2,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7941G-GE': {
    numberStandard: 2,
    numberPages: 1,
    type: 'circle',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 2,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7942': {
    numberStandard: 2,
    numberPages: 1,
    type: 'circle',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 2,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7945': {
    numberStandard: 2,
    numberPages: 1,
    type: 'circle',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 2,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7960': {
    numberStandard: 6,
    numberPages: 1,
    type: 'circle staggered',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 6,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7961': {
    numberStandard: 6,
    numberPages: 1,
    type: 'circle staggered',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 6,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7961G-GE': {
    numberStandard: 6,
    numberPages: 1,
    type: 'circle staggered',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 6,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7962': {
    numberStandard: 6,
    numberPages: 1,
    type: 'circle staggered',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 6,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '7916 24-Button Line Expansion Module',
    maxAmount: 2,
  },
  'Cisco 7965': {
    numberStandard: 6,
    numberPages: 1,
    type: 'circle staggered',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 6,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '7916 24-Button Line Expansion Module',
    maxAmount: 2,
  },
  'Cisco 7970': {
    numberStandard: 8,
    numberPages: 1,
    type: 'circle staggered',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 8,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7971': {
    numberStandard: 8,
    numberPages: 1,
    type: 'circle staggered',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 8,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 7975': {
    numberStandard: 8,
    numberPages: 1,
    type: 'circle staggered',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 8,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '7916 24-Button Line Expansion Module',
    maxAmount: 2,
  },
  'Cisco 7985': {
    numberStandard: 8,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 8811': {
    numberStandard: 10,
    numberPages: 1,
    type: 'rectangle',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: false,
    enhancedLineModeRight: true,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 8821': {
    numberStandard: 6,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 8831': {
    numberStandard: 12,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 8832': {
    numberStandard: 7,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 8832NR': {
    numberStandard: 7,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 8841': {
    numberStandard: 10,
    numberPages: 1,
    type: 'rectangle',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: false,
    enhancedLineModeRight: true,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 8845': {
    numberStandard: 10,
    numberPages: 1,
    type: 'rectangle',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: false,
    enhancedLineModeRight: true,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 8851': {
    numberStandard: 10,
    numberPages: 1,
    type: 'rectangle',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: false,
    enhancedLineModeRight: true,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'BEKEM 36-Button Line Expansion Module',
    maxAmount: 2,
  },
  'Cisco 8851NR': {
    numberStandard: 10,
    numberPages: 1,
    type: 'rectangle',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: false,
    enhancedLineModeRight: true,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'BEKEM 36-Button Line Expansion Module',
    maxAmount: 2,
  },
  'Cisco 8861': {
    numberStandard: 10,
    numberPages: 1,
    type: 'rectangle',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: false,
    enhancedLineModeRight: true,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'BEKEM 36-Button Line Expansion Module',
    maxAmount: 3,
  },
  'Cisco 8865': {
    numberStandard: 10,
    numberPages: 1,
    type: 'rectangle',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: false,
    enhancedLineModeRight: true,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'BEKEM 36-Button Line Expansion Module',
    maxAmount: 3,
  },
  'Cisco 8865NR': {
    numberStandard: 10,
    numberPages: 1,
    type: 'rectangle',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: false,
    enhancedLineModeRight: true,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'BEKEM 36-Button Line Expansion Module',
    maxAmount: 3,
  },
  'Cisco 8941': {
    numberStandard: 4,
    numberPages: 1,
    numberLeft: 4,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'black',
    type: 'oval',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 8945': {
    numberStandard: 4,
    numberPages: 1,
    numberLeft: 4,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'black',
    type: 'oval',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco 8961': {
    numberStandard: 5,
    numberPages: 1,
    type: 'oval',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'CKEM 36-Button Line Expansion Module',
    maxAmount: 3,
  },
  'Cisco 9841': {
    numberStandard: 4,
    numberPages: 1,
    type: 'oval',
    numberLeft: 2,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 2,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 3,
  },
  'Cisco 9851': {
    numberStandard: 6,
    numberPages: 1,
    type: 'oval',
    numberLeft: 3,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 3,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'DP-9800-KEM 40-Button Key Expansion Module',
    maxAmount: 3,
  },
  'Cisco 9861': {
    numberStandard: 10,
    numberPages: 1,
    type: 'oval',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'DP-9800-KEM 40-Button Key Expansion Module',
    maxAmount: 3,
  },
  'Cisco 9861NR': {
    numberStandard: 10,
    numberPages: 1,
    type: 'oval',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'DP-9800-KEM 40-Button Key Expansion Module',
    maxAmount: 3,
  },
  'Cisco 9871': {
    numberStandard: 8,
    numberPages: 1,
    type: '',
    numberLeft: 4,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 4,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'DP-9800-KEM 40-Button Key Expansion Module',
    maxAmount: 3,
  },
  'Cisco 9871NR': {
    numberStandard: 8,
    numberPages: 1,
    type: '',
    numberLeft: 4,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 4,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'DP-9800-KEM 40-Button Key Expansion Module',
    maxAmount: 3,
  },
  'Cisco 9951': {
    numberStandard: 5,
    numberPages: 1,
    type: 'oval',
    numberLeft: 5,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 5,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'CKEM 36-Button Line Expansion Module',
    maxAmount: 2,
  },
  'Cisco 9971': {
    numberStandard: 6,
    numberPages: 1,
    type: 'oval',
    numberLeft: 6,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 6,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: 'CKEM 36-Button Line Expansion Module',
    maxAmount: 3,
  },
  'Cisco ATA 186': {
    numberStandard: 10,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco ATA 187': {
    numberStandard: 10,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco ATA 190': {
    numberStandard: 10,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco ATA 191': {
    numberStandard: 10,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Cius': {
    numberStandard: 6,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Cius SP': {
    numberStandard: 6,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco DX650': {
    numberStandard: 15,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco DX70': {
    numberStandard: 15,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco DX80': {
    numberStandard: 15,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Dual Mode for Android': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Dual Mode for iPhone': {
    numberStandard: 26,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco E20': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco IP Communicator': {
    numberStandard: 8,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Jabber for Tablet': {
    numberStandard: 26,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Spark Room 55': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Spark Room 70 Dual': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Spark Room 70 Single': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Spark Room Kit': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Spark Room Kit Plus': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence 1000': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence 1100': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence 1300-47': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence 1300-65': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence 200': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence 3000': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence 3200': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence 400': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence 500-32': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence 500-37': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Codec C40': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Codec C60': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Codec C90': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence DX70': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence DX80': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence EX60': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence EX90': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence IX5000': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence MX200': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence MX200 G2': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence MX300': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence MX300 G2': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence MX700': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence MX800': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence MX800 Dual': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Profile 42 (C20)': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Profile 42 (C40)': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Profile 42 (C60)': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Profile 52 (C40)': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Profile 52 (C60)': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Profile 52 Dual (C60)': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Profile 65 (C60)': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Profile 65 Dual (C90)': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence Quick Set C20': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence SX10': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence SX20': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence SX80': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence TX1310-65': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence TX9000': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco TelePresence TX9200': {
    numberStandard: 42,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Unified Client Services Framework': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Unified Communications for RTX': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Unified Personal Communicator': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco VGC Phone': {
    numberStandard: 10,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco VGC Virtual Phone': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco VXC 6215': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Webex Room 55 Dual': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Webex Room 70 Dual G2': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Webex Room 70 Single G2': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Webex Room Kit Mini': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Cisco Webex Room Kit Pro': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Generic Desktop Video Endpoint': {
    numberStandard: 8,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Generic Multiple Screen Room System': {
    numberStandard: 8,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Generic Single Screen Room System': {
    numberStandard: 8,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'IMS-integrated Mobile (Basic)': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'IP-STE': {
    numberStandard: 2,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'ISDN BRI Phone': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Nokia S60': {
    numberStandard: 2,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Remote Destination Profile': {
    numberStandard: 0,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Third-party AS-SIP Endpoint': {
    numberStandard: 2,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Third-party SIP Device (Advanced)': {
    numberStandard: 8,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Third-party SIP Device (Basic)': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Transnova S3': {
    numberStandard: 4,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'Universal Device Template': {
    numberStandard: 1,
    numberPages: 1,
    numberLeft: 0,
    configurableLeft: false,
    enhancedLineModeLeft: false,
    numberRight: 0,
    configurableRight: false,
    enhancedLineModeRight: false,
    background: 'none',
    type: 'grid',
    hasLayout: false,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'BEKEM 36-Button Line Expansion Module': {
    numberStandard: 18,
    numberPages: 2,
    type: 'rectangle',
    numberLeft: 9,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 9,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'CP-8800-Video 28-Button Key Expansion Module': {
    numberStandard: 14,
    numberPages: 2,
    type: 'rectangle',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 14,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'CP-8800-Audio 28-Button Key Expansion Module': {
    numberStandard: 14,
    numberPages: 2,
    type: 'rectangle',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 14,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'CKEM 36-Button Line Expansion Module': {
    numberStandard: 18,
    numberPages: 2,
    type: 'oval',
    numberLeft: 9,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 9,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  'DP-9800-KEM 40-Button Key Expansion Module': {
    numberStandard: 20,
    numberPages: 2,
    type: 'oval',
    numberLeft: 10,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 10,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  '7914 14-Button Line Expansion Module': {
    numberStandard: 14,
    numberPages: 1,
    type: 'circle staggered',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 14,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'silver',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  '7916 24-Button Line Expansion Module': {
    numberStandard: 12,
    numberPages: 2,
    type: 'circle staggered',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 12,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
  '7916 28-Button Line Expansion Module': {
    numberStandard: 14,
    numberPages: 2,
    type: 'circle staggered',
    numberLeft: 0,
    configurableLeft: true,
    enhancedLineModeLeft: false,
    numberRight: 14,
    configurableRight: true,
    enhancedLineModeRight: false,
    background: 'black',
    hasLayout: true,
    largestSupportedKem: '',
    maxAmount: 0,
  },
};
