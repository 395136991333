<div class="modal-content">
  <div class="modal-header">
    <button
      type="button"
      class="btn-close"
      (click)="onCancelClick()"
      *ngIf="displayCloseButton"
      [attr.data-automation]="'prompt-modal-close-button'"
      [disabled]="isPending"
    ></button>
  </div>

  <div class="modal-body modal-body--dynamic" data-automation="prompt-modal-body">
    <h1 *ngIf="!!modalViewProperties.icon" [ngClass]="'animated bounceIn lead text-center text-info'">
      <span
        class="{{ modalViewProperties.icon }} {{ modalViewProperties.iconClass }} fa-5x"
        [attr.data-automation]="'prompt-modal-icon'"
      ></span>
    </h1>
    <h1 *ngIf="!!modalViewProperties.iconStackedClasses" class="fa-stack fa-5x d-block mx-auto">
      <i
        [ngClass]="
          'fa fa-stack-1x ' +
          modalViewProperties.iconStackedClasses.bottomIcon +
          ' ' +
          modalViewProperties.iconStackedClasses.bottomIconCssClass
        "
      ></i>
      <i
        [ngClass]="
          'fa fa-stack-2x ' +
          modalViewProperties.iconStackedClasses.topIcon +
          ' ' +
          modalViewProperties.iconStackedClasses.topIconCssClass
        "
      ></i>
    </h1>

    <p
      data-automation="prompt-modal-title"
      *ngIf="modalViewProperties.title"
      class="text-center lead title-font"
      [innerHTML]="modalViewProperties.title | translate"
    ></p>

    <p
      data-automation="prompt-modal-message"
      *ngIf="modalViewProperties.promptBody"
      class="text-center modal-body--dynamic__scrollable"
      [innerHTML]="modalViewProperties.promptBody | translate"
    ></p>
    <ng-container #modalBody></ng-container>
  </div>

  <div class="modal-footer justify-content-center pt-0" *ngIf="modalViewProperties.buttons.length > 0">
    <div *ngFor="let button of modalButtons">
      <smacs-button
        (clicked)="onButtonClicked(button)"
        [label]="button.label"
        [icon]="button.buttonIcon"
        [dataAutomation]="button.dataAutomation"
        [buttonStyle]="button.buttonClass"
        [buttonSize]="buttonSizes.LARGE"
        [isDisabled]="isPending"
        [iconPending]="icons.SAVING"
        [isPending]="button.isPending"
      ></smacs-button>
    </div>
  </div>
</div>
