import { Component, Input, OnChanges } from '@angular/core';
import { SmacsIcons } from '../../models/smacs-icons.enum';

@Component({
  selector: 'smacs-entity-table-badge',
  templateUrl: './entity-table-badge.component.html',
})
export class EntityTableBadgeComponent implements OnChanges {
  @Input() value: string;
  @Input() maxLength: number;
  @Input() badgeIcon: SmacsIcons | string;
  @Input() badgeCssClass: string;
  @Input() tooltip: string;
  @Input() displayToolTip: boolean;

  displayValue: string;
  isTooltipDisplayed: boolean;

  ngOnChanges() {
    if (this.displayToolTip) {
      this.displayValue = this.value;
      this.isTooltipDisplayed = true;
    } else if (this.maxLength && this.value && this.value.length > this.maxLength) {
      this.displayValue = this.value.substring(0, this.maxLength - 3) + '...';
      this.tooltip = this.value;
      this.isTooltipDisplayed = true;
    } else {
      this.displayValue = this.value;
      this.isTooltipDisplayed = false;
    }
  }

  getCssClass(): string {
    return this.badgeCssClass ? this.badgeCssClass : 'bg-secondary';
  }
}
