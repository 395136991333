import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { EndUser, EndUserRef } from '../models/generated/smacsModels';
import { Nvp } from '../models/nvp';
import { switchMap } from 'rxjs/operators';
import { AuditHeaderService } from '../services/audit-header.service';

@Injectable()
export class EndUserResource {
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  get(id: string, cucmServerId: number): Observable<EndUser> {
    return this.http.get<EndUser>(
      `/services/cisco/macs/cucm-servers/${cucmServerId}/end-users/${encodeURIComponent(id)}`
    );
  }

  post(endUser: EndUser, cucmServerId: number, auditTags?: Nvp[]): Observable<EndUserRef> {
    const options = this._buildHttpOptions(auditTags);
    const url = `/services/cisco/macs/cucm-servers/${cucmServerId}/end-users`;
    return this.http.post<EndUserRef>(url, endUser, options);
  }

  put(endUser: EndUser, cucmServerId: number, auditTags?: Nvp[]): Observable<EndUserRef> {
    const options = this._buildHttpOptions(auditTags);
    const url = `/services/cisco/macs/cucm-servers/${cucmServerId}/end-users${
      endUser.id ? `/${encodeURIComponent(endUser.id)}` : ''
    }`;
    return this.http.put<EndUserRef>(url, endUser, options);
  }

  checkEnabledMobilitySettings(endUserId: string, cucmServerId: number): Observable<void | EndUserRef> {
    return this.get(endUserId, cucmServerId).pipe(
      switchMap((endUser) => {
        if (!endUser.enableMobility || !endUser.enableMobileVoiceAccess) {
          const mobilityEnabledEndUser: EndUser = {
            ...endUser,
            enableMobility: true,
            enableMobileVoiceAccess: true,
          };
          return this.put(mobilityEnabledEndUser, cucmServerId);
        }
        return of(null);
      })
    );
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }
}
