import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { of } from 'rxjs';
import {
  CucmMetadata,
  CustomSelect,
  DialPlanManagementGroup,
  DnDidRange,
  SiteSummary,
} from '../../../../../shared/models/generated/smacsModels';
import { SmacsSelectFieldConfig } from '../../../../../shared/custom-configs/legacy-smacs-select-config/legacy-smacs-select-config.component';
import { ExtensionRangesOptionalValidators } from '../../../../../forms/fields/extension-ranges/smacs-extension-range-models';
import { SmacsTextareaConfig } from '../../../../../forms/fields/textarea/smacs-textarea.component';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../../forms/smacs-forms-models';
import { cloneDeep, isEqual, sortBy } from 'lodash';
import { SelectFieldConfigUi } from '../dial-plan-management-edit.component';
import { RangeService } from '../../../../../shared/services/range.service';
import {
  ExtensionRangesDisplayFormComponent,
  RangeDisplayFormModel,
} from '../../../../../forms/fields/extension-ranges/extension-ranges-display-form/extension-ranges-display-form.component';

export interface CiscoDidRangesFormEntity {
  route_partition: CustomSelect;
  ranges: DnDidRange[];
}

export interface CiscoDidRangesFormData {
  ranges: DnDidRange[];
  bulkRanges: '';
  route_partition: SelectFieldConfigUi;
}

@Component({
  selector: 'app-cisco-dial-plan-management-did-ranges-form',
  templateUrl: './dial-plan-management-did-ranges-form.component.html',
  providers: [],
})
export class CiscoDialPlanManagementDidRangesFormComponent
  extends SmacsFormAbstractDirective<CiscoDidRangesFormEntity, CiscoDidRangesFormData>
  implements OnInit
{
  @Input() cucmMetadata: CucmMetadata;
  @Input() groupId: number;
  @Input() clusterId: number;
  @Input() dialPlanGroups: DialPlanManagementGroup[] = [];
  @Input() siteSummary: SiteSummary;

  @ViewChild(ExtensionRangesDisplayFormComponent) extensionRangesDisplayForm: ExtensionRangesDisplayFormComponent;

  validationStates = SmacsFormsValidationState;
  formConfig: SmacsFormConfig;
  displayRangeFormEntity: RangeDisplayFormModel;
  displayRangeForm = false;
  extensionRangesDisplayFormValidationState: SmacsFormsValidationState;
  displayRangeFormOptionalValidators: ExtensionRangesOptionalValidators;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.formConfig = {
      fields: {
        route_partition: {
          label: 'tkey;dialplanmanagement.admin.group.dnranges.e164_alternate_number.route_partition.label',
          dataAutomation: 'did-ranges-route-partition',
          componentConfig: new SmacsSelectFieldConfig(false, sortBy(this.cucmMetadata.routePartitions)),
        },
        bulkRanges: {
          label: 'tkey;dialplanmanagement.admin.group.generate.didranges',
          helpText: () => 'tkey;dialplanmanagement.admin.group.generate.didranges.helpblock',
          dataAutomation: 'did-ranges-bulk-ranges',
          componentConfig: new SmacsTextareaConfig(),
          validation: [
            {
              validator: (val: string) => {
                if (!val) {
                  return SmacsFormsValidationState.VALID;
                }

                const split = val.split(',');
                const hasInvalid = split.some((v: string) => {
                  return !/^\+?[0-9]+(?:-\+?[0-9]+)*$/.test(v.trim());
                });

                return hasInvalid ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
              },
              message: 'tkey;rangeDirective.range.unsupportedpattern',
            },
          ],
        },
      },
    };

    this.displayRangeFormOptionalValidators = {
      overlappingRanges: true,
      strictE164Validation: false,
      hasSameLengthValidation: true,
      maxDiffValidation: 99999,
      minLengthValidation: 1,
      maxLengthValidation: 24,
      overlappingRangesInOtherCiscoDialPlanGroups: {
        clusterId: this.clusterId,
        dialPlanGroups: !this.groupId
          ? this.dialPlanGroups
          : this.dialPlanGroups.filter((group: DialPlanManagementGroup) => group.id !== this.groupId.toString()),
        range: 'DID',
      },
    };

    this.displayRangeFormEntity = { ranges: cloneDeep(this.entity.ranges) };
    this.displayRangeForm = true;
  }

  generateBulkRangesClicked() {
    if (!this.formData['bulkRanges'].trim()) {
      return;
    }

    this.displayRangeForm = false;

    // Remove new lines, spaces
    const generatedRanges = RangeService.generateRangesFromArray(
      this.formData['bulkRanges'].replace(/\n|\r/g, '').replace(/ /g, '').split(',')
    );
    const currentRanges = cloneDeep(this.entity.ranges).filter((r: DnDidRange) => r.start && r.end);
    const updatedRanges = [...currentRanges, ...generatedRanges];
    const newEntity = {
      ...this.entity,
      ranges: updatedRanges,
    };

    this.formData['bulkRanges'] = '';
    this.entitySource.next(cloneDeep(newEntity));
    this.displayRangeFormEntity = { ranges: newEntity.ranges };

    setTimeout(() => {
      this.displayRangeForm = true;
    });
  }

  submit() {
    this.setDisplayFormValidationState();
    return of(null);
  }

  toFormData = (entity: CiscoDidRangesFormEntity): CiscoDidRangesFormData => {
    return {
      route_partition: {
        name: entity.route_partition?.name,
        defaultValue: entity.route_partition?.defaultOption,
        defaultValues: entity.route_partition?.defaultOption ? [entity.route_partition?.defaultOption] : [],
        possibleOptions: entity.route_partition?.possibleOptions,
        required: entity.route_partition?.required,
        show: entity.route_partition?.show,
      },
      ranges: entity.ranges,
      bulkRanges: '',
    } as CiscoDidRangesFormData;
  };

  toEntity = (formData: CiscoDidRangesFormData): CiscoDidRangesFormEntity => {
    return {
      route_partition: {
        name: formData.route_partition.name,
        defaultOption: formData.route_partition.defaultValue,
        possibleOptions: formData.route_partition.possibleOptions,
        required: formData.route_partition.required,
        show: formData.route_partition.show,
      },
      ranges: formData.ranges,
    } as CiscoDidRangesFormEntity;
  };

  setDisplayFormValidationState() {
    this.extensionRangesDisplayFormValidationState =
      this.isFormSubmitted || this.extensionRangesDisplayForm.extensionRanges.showValidation
        ? this.extensionRangesDisplayForm.validationState
        : this.validationStates.VALID;
  }

  onRangeFormUpdate(data: SmacsFormsUpdate<RangeDisplayFormModel>) {
    // Always update validation state, don't always update entity. Async val will fire this multiple times so entity
    // won't necessarily change onFormUpdate
    if (isEqual(this.entity.ranges, data.new.ranges)) {
      this.setDisplayFormValidationState();
    } else {
      const newEntity = {
        ...cloneDeep(this.entity),
        ranges: data.new.ranges,
      };

      this.entitySource.next(newEntity);

      this.setDisplayFormValidationState();
    }
  }
}
