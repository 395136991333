import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebexDialPlanFieldConfig, WebexFieldConfig } from '../../../../shared/models/generated/smacsModels';
import { Observable } from 'rxjs';

@Injectable()
export class WebexCallingFieldConfigResource {
  constructor(private http: HttpClient) {}

  getDialPlanFieldConfigs(): Observable<WebexDialPlanFieldConfig[]> {
    return this.http.get<WebexDialPlanFieldConfig[]>('/services/cisco/field-configs/webex-calling-dial-plan');
  }

  getFieldConfigs(email: string): Observable<WebexFieldConfig> {
    return this.http.get<WebexFieldConfig>(`/services/cisco/field-configs/webex-calling?email=${email}`);
  }
}
