import { Injectable } from '@angular/core';
import { CurrentUser, LoginRequest, Role } from '../models/generated/smacsModels';
import { EMPTY, Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { first, shareReplay } from 'rxjs/operators';
import { Router } from '@angular/router';

// When we log out, we want to set the state to an object with null strings and an empty array.
// We don't want to set the state to null because then we'd have to handle a potentially-null user everywhere.
const NULL_USER: CurrentUser = {
  userId: null,
  displayName: null,
  photoBase64: null,
  role: null,
  privileges: [],
  ssoAuthenticated: false,
};

@Injectable()
export class AuthenticationContext {
  _stateSource = new ReplaySubject<CurrentUser>(1); // public so we can access it in the workbench
  state$ = this._stateSource.asObservable();

  private _permissionHierarchy = [
    Role.S8_SELF_SERVE_USER,
    Role.S8_SITE_BASED_HELPDESK,
    Role.S8_HELPDESK,
    Role.S8_GLOBAL_HELPDESK,
    Role.S8_ADMIN,
    Role.ZIRO_SUPPORT,
  ] as Role[];
  private _currentUser: CurrentUser;

  constructor(protected http: HttpClient, private router: Router) {
    this.refreshCurrentUser().subscribe();
  }

  getCurrentUser(): CurrentUser {
    return this._currentUser;
  }

  login(credentials: LoginRequest): Observable<string> {
    return new Observable<string>((subscriber) => {
      this._login(credentials).subscribe({
        next: (data: string) => {
          this.refreshCurrentUser().subscribe(() => {
            subscriber.next(data);
          });
        },
        error: (error) => {
          error.error = JSON.parse(error.error);
          subscriber.error(error);
        },
      });
    }).pipe(first());
  }

  logout(): Observable<void> {
    if (this._currentUser.ssoAuthenticated) {
      window.location.href = window.location.origin + '/saml/logout';
      return EMPTY;
    } else {
      return new Observable<void>(() => {
        this._logout().subscribe(() => {
          this._stateSource.next(NULL_USER);
          this.router.navigateByUrl('login');
        });
      }).pipe(first());
    }
  }

  userIsAtLeast(user: CurrentUser, role: Role): boolean {
    return this._permissionHierarchy.indexOf(user?.role) >= this._permissionHierarchy.indexOf(role);
  }

  refreshCurrentUser(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this._getCurrentUser().subscribe((data) => {
        this._stateSource.next(data);
        subscriber.next();
        this._currentUser = data;
      });
    }).pipe(first());
  }

  private _login(credentials: LoginRequest): Observable<string> {
    return this.http.post('/services/system/security/authentication/login', credentials, {
      responseType: 'text',
    });
  }

  private _logout(): Observable<void> {
    return this.http.post<void>('/services/system/security/authentication/logout', {});
  }

  private _getCurrentUser(): Observable<CurrentUser> {
    return this.http.get<CurrentUser>('/services/system/security/current-user').pipe(shareReplay());
  }
}
