import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CallLogsQueryEditorKeywordConfig } from './expression-editor-keyword/call-logs-query-editor-keyword.component';

export enum CallLogQueryKeywords {
  CALLS_PLACED_OR_RECEIVED = 'tkey;reporting.call_logs.editor.placeholder.calls',
  EXTENSION_USERNAME_OR_DEVICENAME = 'tkey;reporting.call_logs.editor.placeholder.input',
  FROM_DATE = 'tkey;reporting.call_logs.editor.placeholder.dates.from',
  TO_DATE = 'tkey;reporting.call_logs.editor.placeholder.dates.to',
}

export interface CallLogQueryEditorData {
  callPlacedOrReceived: string;
  extensionUsernameOrDeviceName: { selection: string; value: string };
  from: Date;
  to: Date;
}

enum CallLogDirection {
  PLACED_AND_RECEIVED = 'BOTH',
  RECEIVED = 'RECEIVING',
  PLACED = 'CALLING',
}

@Component({
  selector: 'smacs-call-logs-query-editor',
  templateUrl: './call-logs-query-editor.component.html',
  styleUrls: ['./call-logs-query-editor.component.scss'],
})
export class CallLogsQueryEditorComponent implements OnChanges {
  @Input() config: CallLogQueryEditorData;
  @Output() callLogQueryResultUpdated = new EventEmitter<CallLogQueryEditorData>();
  keywords = CallLogQueryKeywords;
  previousValues: CallLogQueryEditorData = {
    callPlacedOrReceived: '',
    extensionUsernameOrDeviceName: { selection: '', value: '' },
    from: null,
    to: null,
  };

  callPlacedOrReceived = {
    keywordValue: null,
    choices: [
      { selection: 'placed', value: CallLogDirection.PLACED },
      { selection: 'received', value: CallLogDirection.RECEIVED },
      { selection: 'placed & received', value: CallLogDirection.PLACED_AND_RECEIVED },
    ],
    dataAutomation: 'call-placed-or-received',
    type: this.keywords.CALLS_PLACED_OR_RECEIVED,
  } as CallLogsQueryEditorKeywordConfig;
  extensionUsernameOrDeviceName = {
    keywordValue: { selection: null, value: null },
    choices: [
      { selection: 'username', value: 'username' },
      { selection: 'device', value: 'device' },
      { selection: 'extension', value: 'extension' },
    ],
    dataAutomation: 'extension-username-or-devicename',
    type: this.keywords.EXTENSION_USERNAME_OR_DEVICENAME,
  } as CallLogsQueryEditorKeywordConfig;

  fromDate = {
    keywordValue: null,
    dataAutomation: 'start-date',
    type: this.keywords.FROM_DATE,
  } as CallLogsQueryEditorKeywordConfig;

  toDate = {
    keywordValue: null,
    dataAutomation: 'end-date',
    type: this.keywords.TO_DATE,
  } as CallLogsQueryEditorKeywordConfig;

  feedbackText = {
    callPlacedOrReceived: 'tkey;reporting.call_logs.editor.feedback.calls',
    extensionUsernameOrDeviceName: 'tkey;reporting.call_logs.editor.feedback.input',
    fromDate: 'tkey;reporting.call_logs.editor.feedback.dates',
    toDate: 'tkey;reporting.call_logs.editor.feedback.dates',
  };

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.config?.currentValue) {
      const { callPlacedOrReceived, extensionUsernameOrDeviceName, from, to } = changes.config.currentValue;
      this._setPreviousValues();
      this.callPlacedOrReceived.keywordValue = { selection: 'callPlacedOrReceived', value: callPlacedOrReceived };
      this.extensionUsernameOrDeviceName.keywordValue = extensionUsernameOrDeviceName;
      this.fromDate.keywordValue = { selection: 'fromDate', value: from };
      this.toDate.keywordValue = { selection: 'toDate', value: to };
      this.fromDate.hasError = this.fromDate.keywordValue.value.getTime() > this.toDate.keywordValue.value.getTime();
      this.toDate.hasError = this.toDate.keywordValue.value.getTime() < this.fromDate.keywordValue.value.getTime();
      this.extensionUsernameOrDeviceName.hasError = !extensionUsernameOrDeviceName.value;

      if (changes?.config?.firstChange) {
        this._setPreviousValues();
        this.callLogQueryResultUpdated.emit({
          callPlacedOrReceived: this.callPlacedOrReceived.keywordValue.value,
          extensionUsernameOrDeviceName: this.extensionUsernameOrDeviceName.keywordValue,
          from: this.fromDate.keywordValue.value,
          to: this.toDate.keywordValue.value,
        } as CallLogQueryEditorData);
      }
    }
  }

  get wasAChangeMade(): boolean {
    return (
      this.previousValues.callPlacedOrReceived !== this.callPlacedOrReceived.keywordValue.value ||
      this.previousValues.extensionUsernameOrDeviceName.value !==
        this.extensionUsernameOrDeviceName.keywordValue.value ||
      this.previousValues.extensionUsernameOrDeviceName.selection !==
        this.extensionUsernameOrDeviceName.keywordValue.selection ||
      this.previousValues.from !== this.fromDate.keywordValue.value ||
      this.previousValues.to !== this.toDate.keywordValue.value
    );
  }
  get isExpressionInvalid(): boolean {
    return this.callPlacedOrReceived.hasError || this.extensionUsernameOrDeviceName.hasError || this.areDatesInvalid();
  }

  handleKeywordUpdate() {
    this.fromDate.hasError = this.areDatesInvalid();
    this.toDate.hasError = this.areDatesInvalid();
    this.extensionUsernameOrDeviceName.hasError = !this.doesExtensionUsernameOrDeviceHaveAValue();
    if (
      this.callPlacedOrReceived.keywordValue &&
      this.extensionUsernameOrDeviceName.keywordValue.value &&
      this.fromDate.keywordValue &&
      this.toDate.keywordValue &&
      !this.isExpressionInvalid &&
      this.wasAChangeMade
    ) {
      this.callLogQueryResultUpdated.emit({
        callPlacedOrReceived: this.callPlacedOrReceived.keywordValue.value,
        extensionUsernameOrDeviceName: this.extensionUsernameOrDeviceName.keywordValue,
        from: this.fromDate.keywordValue.value,
        to: this.toDate.keywordValue.value,
      } as CallLogQueryEditorData);
    }
  }

  doesExtensionUsernameOrDeviceHaveAValue(): boolean {
    return !!this.extensionUsernameOrDeviceName.keywordValue.value;
  }

  areDatesInvalid(): boolean {
    return (
      this.fromDate.keywordValue.value.getTime() > this.toDate.keywordValue.value.getTime() ||
      this.toDate.keywordValue.value.getTime() < this.fromDate.keywordValue.value.getTime()
    );
  }

  private _setPreviousValues() {
    this.previousValues = {
      callPlacedOrReceived: this.callPlacedOrReceived.keywordValue?.value,
      extensionUsernameOrDeviceName: {
        selection: this.extensionUsernameOrDeviceName.keywordValue?.selection,
        value: this.extensionUsernameOrDeviceName.keywordValue?.value,
      },
      from: this.fromDate.keywordValue?.value,
      to: this.toDate.keywordValue?.value,
    };
  }
}
