<div class="row mt-3" [attr.data-automation]="dataAutomation">
  <div class="col-md-12 position-relative">
    <smacs-read-write-select-config-form-text
      class="w-100"
      [fieldId]="'possibleOptionsFormatted'"
      [currentValue]="entity"
      (licenseSelectInputClicked)="onInputClicked($event)"
      #possibleOptions
    ></smacs-read-write-select-config-form-text>
  </div>
  <div class="col-md-12">
    <div class="row">
      <div class="offset-lg-3 col-lg-9 mt-n2">
        <span *ngIf="helpText" class="small form-text text-muted" [innerHtml]="helpText | translate"></span>
      </div>
    </div>
  </div>
</div>
