import { Component, OnInit } from '@angular/core';
import { combineLatest } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { take } from 'rxjs/operators';
import { ClusterResult, SiteResult, SiteSummary } from '../../../shared/models/generated/smacsModels';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import { SiteContext } from '../../shared/contexts/site.context';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { SmacsSelectOption } from '../../../forms/fields/select/smacs-select.component';
import { ChangeSiteFormEntity } from './form/change-site-form.component';

@Component({
  selector: 'ziro-change-site',
  templateUrl: './change-site.component.html',
})
export class ChangeSiteComponent implements OnInit {
  smacsIcons = SmacsIcons;
  isLoading = true;
  sites: SmacsSelectOption[] = [];
  siteSummary: SiteSummary;
  cluster: ClusterResult;
  initialSite: SiteResult;
  entity: ChangeSiteFormEntity = {
    site: null,
  };

  constructor(
    private _route: ActivatedRoute,
    private _breadcrumbsService: BreadcrumbsService,
    private _siteSummaryContext: SiteSummaryContext,
    private _userDetailUiContext: UserDetailUiContext,
    private _siteContext: SiteContext,
    private _currentClusterContext: CurrentClusterContext
  ) {}

  ngOnInit() {
    const username = this._route.snapshot.params.username;
    this._breadcrumbsService.updateBreadcrumbs([
      { label: username, url: `/user/${username}`, routerLink: true },
      { label: 'tkey;change.site.change_site' },
    ]);

    combineLatest([
      this._siteSummaryContext.state$,
      this._currentClusterContext.state$,
      this._siteContext.state$,
      this._userDetailUiContext.state$,
    ])
      .pipe(take(1))
      .subscribe({
        next: ([siteSummary, cluster, site]) => {
          this.siteSummary = siteSummary;
          this.cluster = cluster;
          this.initialSite = site;
          this._getSelectOptions();
          this.isLoading = false;
        },
      });
  }

  private _getSelectOptions() {
    this.siteSummary.clusters.forEach((cluster: ClusterResult) => {
      cluster.sites
        .filter((site: SiteResult) => site.id !== Number(this.initialSite.id) && site.hasPermission)
        .forEach((site: SiteResult) => {
          this.sites.push({
            label: site.name,
            value: site.id,
            group: cluster.name,
          });
        });
    });
  }
}
