import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../shared/services/toast.service';
import { ButtonStyles, ButtonTypes } from '../button/button.component';
import { SmacsIcons } from '../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../shared/breadcrumbs/breadcrumbs.service';
import { SmacsFormAbstractDirective } from '../forms/smacs-form-abstract.directive';
import { HtmlInputType, SmacsTextConfig } from '../forms/fields/text/smacs-text.component';
import { SmacsFormConfig, SmacsFormsValidationState } from '../forms/smacs-forms-models';
import { Observable, Subscription } from 'rxjs';
import { CdrDumpResource } from './cdr-dump.resource';
import { SmacsFormStateService } from '../forms/smacs-form-state.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { tap } from 'rxjs/operators';

export interface MockCdrRequest {
  startDate: string;
  endDate: string;
  callRecords: number;
  unusedPercentage: number;
}

@Component({
  selector: 'smacs-cdr-dump',
  templateUrl: './cdr-dump.component.html',
  styleUrls: ['./cdr-dump.component.scss'],
  providers: [CdrDumpResource],
})
export class SmacsCdrDumpComponent extends SmacsFormAbstractDirective<MockCdrRequest> implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;
  private _validators = {
    minValueValidator: (value: number, minValue: number): SmacsFormsValidationState => {
      return !isNaN(value) && Number(value) < minValue
        ? SmacsFormsValidationState.INVALID
        : SmacsFormsValidationState.VALID;
    },
    maxValueValidator: (value: number, maxValue: number): SmacsFormsValidationState => {
      return !isNaN(value) && Number(value) > maxValue
        ? SmacsFormsValidationState.INVALID
        : SmacsFormsValidationState.VALID;
    },
    beforeDateValidator: (value: number, endDate: string): SmacsFormsValidationState => {
      return moment(value).isBefore(endDate) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
    },
    afterDateValidator: (value: number, startDate: string): SmacsFormsValidationState => {
      return moment(value).isAfter(startDate) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
    },
  };
  formConfig = {
    fields: {
      startDate: {
        label: 'tkey;cdr_dump.start_date.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.DATE }),
        required: true,
        helpText: 'tkey;cdr_dump.start_date.helptext',
        validation: [
          {
            validator: (value: number) => {
              return this._validators.beforeDateValidator(value, this.formData.endDate);
            },
            message: () => this.translateService.instant('tkey;cdr_dump.start_date.validation.before'),
          },
        ],
      },
      endDate: {
        label: 'tkey;cdr_dump.end_date.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.DATE }),
        required: true,
        helpText: 'tkey;cdr_dump.end_date.helptext',
        validation: [
          {
            validator: (value: number) => {
              return this._validators.afterDateValidator(value, this.formData.startDate);
            },
            message: () => this.translateService.instant('tkey;cdr_dump.end_date.validation.after'),
          },
        ],
      },
      callRecords: {
        label: 'tkey;cdr_dump.call_records.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        required: true,
        helpText: 'tkey;cdr_dump.call_records.helptext',
        validation: [
          {
            validator: (value: number) => {
              return this._validators.minValueValidator(value, 0);
            },
            message: () =>
              this.translateService.instant('tkey;validators.global.error.min', {
                minValue: 0,
              }),
          },
        ],
      },
      unusedPercentage: {
        label: 'tkey;cdr_dump.unused_percentage.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        required: true,
        helpText: 'tkey;cdr_dump.unused_percentage.helptext',
        validation: [
          {
            validator: (value: number) => {
              return this._validators.minValueValidator(value, 0);
            },
            message: () =>
              this.translateService.instant('tkey;validators.global.error.min', {
                minValue: 0,
              }),
          },
          {
            validator: (value: number) => {
              return this._validators.maxValueValidator(value, 100);
            },
            message: () =>
              this.translateService.instant('tkey;validators.global.error.max', {
                maxValue: 100,
              }),
          },
        ],
      },
    },
  } as SmacsFormConfig;

  private _subscriptions = new Subscription();

  constructor(
    protected bottomNavService: BottomNavService,
    protected toastService: ToastService,
    private breadcrumbsService: BreadcrumbsService,
    private cdrDumpResource: CdrDumpResource,
    protected smacsFormStateService: SmacsFormStateService,
    private translateService: TranslateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;cdr_dump.title' }]);
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'generateCdrDump',
          label: 'tkey;cdr_dump.card.title',
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'generateCdrDump',
          state: {
            pending: false,
            buttonDisableState: { disabled: false, tooltipKey: '' },
          },
          icon: this.smacsIcons.DOWNLOAD,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() =>
      this._setBottomNavValidationError(!this.isFormValid())
    );
    this._subscriptions.add(formSubmittedSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
    this.breadcrumbsService.clearBreadcrumbs();
  }

  protected submit() {
    return this._generateCdrDumpFile();
  }

  private _generateCdrDumpFile(): Observable<void> {
    this._setBottomNavPending(true);
    this._setBottomNavValidationError(false);

    return this.cdrDumpResource.generateCdrs(this.formData).pipe(
      tap(() => {
        this._setBottomNavPending(false);
      })
    );
  }

  private _setBottomNavPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'generateCdrDump',
        state: {
          pending: setting,
          buttonDisableState: {
            disabled: setting,
          },
        },
      })
    );
  }

  private _setBottomNavValidationError(hasError: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: hasError,
      })
    );
  }
}
