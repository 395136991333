import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { intersectionWith, isNil } from 'lodash';
import {
  SmacsFieldComponentConfig,
  SmacsFieldState,
  SmacsFormsValidationState,
} from '../../../forms/smacs-forms-models';
import { FullValidationUpdate, SmacsFieldAbstractDirective } from '../../../forms/smacs-field-abstract.directive';
import { CustomSelect } from '../../models/generated/smacsModels';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { EditCustomSelectModalComponent } from '../../../modals/edit-custom-select-modal/edit-custom-select-modal.component';
import { HtmlInputType } from '../../../forms/fields/text/smacs-text.component';
import { SmacsModalService } from '../../services/smacs-modal.service';
import { Observable } from 'rxjs';

export interface SelectConfig {
  availableOptions: string[];
  isAlwaysRequired: boolean;
  displayValues: string[];
  displayLabel: string;
  postLoadedOptions?: () => Observable<string[]>;
}

export class ZiroCustomSelectConfig extends SmacsFieldComponentConfig {
  constructor(public config?: SelectConfig) {
    super();
  }
}

@Component({
  selector: 'ziro-custom-select',
  templateUrl: './ziro-custom-select.component.html',
  styleUrls: ['./ziro-custom-select.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: ZiroCustomSelectComponent }],
})
export class ZiroCustomSelectComponent
  extends SmacsFieldAbstractDirective<CustomSelect, CustomSelect, ZiroCustomSelectConfig>
  implements OnInit
{
  @Input() isServiceEnabled = false;
  htmlInputType = HtmlInputType;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;

  // Default Value plus configured options
  displayValues: string;
  displayLabel: string;

  availableOptions: string[];
  invalidOptions: string[];
  isRequiredChecked = false;
  isShowChecked = false;
  isAlwaysRequired = false;
  isInitialStateApplied = false;
  isFirstUpdate = true;

  showAutoGenerationLink = true;
  requiredLabel: string;
  showLabel = 'tkey;site_management.site.section.show.label';

  postLoadedOptions: () => Observable<string[]>;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _smacsModalService: SmacsModalService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.selfUpdate$.subscribe((update) => {
      if (!update) return;
      const possibleOptions = update.defaultOption
        ? [update.defaultOption, ...update.possibleOptions.filter((opt) => opt !== update.defaultOption)]
        : [...update.possibleOptions];
      this.showAutoGenerationLink = !possibleOptions.every((option) => this.availableOptions.includes(option));
      this.displayValues = this._arrayToString(possibleOptions);
      this.isRequiredChecked = this.isAlwaysRequired || update.required;
      this.isShowChecked = update.show;
      if (update.required !== this.isRequiredChecked && this.isFirstUpdate) {
        this.updateSelf({
          ...update,
          required: this.isRequiredChecked,
        });
        this.isFirstUpdate = false;
        this.isDirty = false;
      }
    });
  }

  /** Applies a new state. In this way, we can change hidden/disabled/required/excluded during runtime.
   * This is an override of the field abstract. */
  applyState = (newState: SmacsFieldState) => {
    if (this.isServiceEnabled || !this.isInitialStateApplied) {
      this.isInitialStateApplied = true;
      this.state = newState;
    }
  };

  /** After validation runs, this updates the field's validation state depending on the result of the validators.
   * This is an override of the field abstract. */
  _updateValidationView = (valUpdate: FullValidationUpdate) => {
    if (valUpdate) {
      this.validationState = valUpdate.status;
      this.validationMessage = this._toSmacsFormMessage(valUpdate.message);
      this.isValidationInProgress = false;
      if (valUpdate.status !== SmacsFormsValidationState.VALID) {
        this.forceShow = true;
      }
      this.updateParent();
      if (this.isDirty) {
        this.showValidation = true;
      }
      this._changeDetectorRef.detectChanges();
    }
  };

  /** This is an override of the field abstract. */
  protected _onReceiveNewEntity(newEntity: CustomSelect) {
    this.isDirty = true;
    this.isAutogenerated = false;
    this.fieldData = this.toFieldData(newEntity);
    this._selfUpdateSource.next(this.fieldData);
  }

  applyComponentConfig = ({ config }: ZiroCustomSelectConfig) => {
    this.isAlwaysRequired = config.isAlwaysRequired;
    this.availableOptions = config.availableOptions;
    this.displayValues = isNil(config.displayValues) ? '' : this._arrayToString(config.displayValues);
    this.displayLabel = config.displayLabel;
    this.requiredLabel = this.isAlwaysRequired
      ? 'tkey;site_management.site.section.required.always.label'
      : 'tkey;site_management.site.section.required.label';
    this.postLoadedOptions = config.postLoadedOptions ? config.postLoadedOptions : () => null;
  };

  onRequiredChange(newValue: boolean) {
    this.updateSelf({ ...this.entity, required: newValue });
  }

  onShowChange(newValue: boolean) {
    this.updateSelf({
      ...this.entity,
      show: newValue,
    });
  }

  openModal(): void {
    this.invalidOptions = this.entity.possibleOptions.filter((value) => {
      return !this.availableOptions.some((availableOption) => availableOption === value);
    });

    const modalInstance = this._smacsModalService.openCustomModal(
      EditCustomSelectModalComponent,
      {
        configuredOptions: this.entity.defaultOption
          ? [...this.entity.possibleOptions.filter((option) => option !== this.entity.defaultOption)]
          : this.entity.possibleOptions,
        availableOptions: this.entity.defaultOption
          ? [...this.availableOptions.filter((option) => option !== this.entity.defaultOption)]
          : [...this.availableOptions],
        defaultOptions: this.entity.defaultOption ? [this.entity.defaultOption] : [],
        modalHeaderKey: this.displayLabel,
        isMultiSelect: false,
        isPreviewVisible: true,
        translations: {
          description: 'tkey;site_management.site.section.select.modal.description',
          available: 'tkey;site_management.site.section.select.modal.available_values.label',
          show: 'tkey;site_management.site.section.select.modal.show_values.label',
          default: 'tkey;custom_select.default_value.modal.label',
        },
        postLoadOptionsFn: () => this.postLoadedOptions(),
      },
      'xl'
    );

    modalInstance.subscribe((newValues: { configuredOptions: string[]; defaultOptions: string[] }) => {
      if (newValues) {
        const possibleOptions = newValues.defaultOptions.length
          ? [...newValues.defaultOptions, ...newValues.configuredOptions]
          : newValues.configuredOptions;
        this.updateSelf({
          ...this.entity,
          possibleOptions: possibleOptions,
          defaultOption: newValues.defaultOptions.length ? newValues.defaultOptions[0] : '',
        });
      }
    });
  }

  handleAutoGeneration() {
    const validOptions = intersectionWith(this.entity.possibleOptions, this.availableOptions);
    const newEntity: CustomSelect = {
      ...this.entity,
      defaultOption: validOptions.includes(this.entity.defaultOption) ? this.entity.defaultOption : '',
      possibleOptions: validOptions,
    };
    this.updateSelf(newEntity);
    this.showAutoGenerationLink = false;
    this.autogenerateValue();
  }

  getDisabledState(): boolean {
    if (this.config.hasOwnProperty('disabled')) {
      if (typeof this.config.disabled === 'boolean') {
        return this.config.disabled;
      } else if (typeof this.config.disabled === 'function') {
        return this.config.disabled();
      }
    }

    return this.state.disabled;
  }

  private _arrayToString(values: string[]): string {
    return values ? values.join(', ') : '';
  }
}
