import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialPlanGroupContext } from '../../../../helpdesk/shared/contexts/dial-plan-group.context';
import { DialPlanManagementGroup, SiteSummary } from '../../../../shared/models/generated/smacsModels';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../shared/services/toast.service';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RangeFilterService } from '../../../services/range-filter.service';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../../shared/entity-table/entity-table.models';
import { TelephoneNumberFilter } from '../../../../shared/filters/telephone-number.filter';

@Component({
  selector: 'app-cisco-dial-plan-management-list',
  templateUrl: './dial-plan-management-list.component.html',
  styleUrls: ['../../../admin-page.scss', './dial-plan-management-list.component.scss'],
  providers: [RangeFilterService],
})
export class CiscoDialPlanManagementListComponent implements OnInit, OnDestroy {
  isLoading = true;

  table: EntityTable = {
    columns: [
      {
        columnId: 'id',
        fixedColumnSize: 'width: 8%;',
        label: 'tkey;global.table.id.text',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'name',
        fixedColumnSize: 'width: 16%;',
        label: 'tkey;dialplanmanagement.admin.group.name',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'sites',
        fixedColumnSize: 'width: 16%;',
        label: 'tkey;dialplanmanagement.admin.group.sites',
        filter: {
          type: EntityTableFilterTypes.TEXT,
          filterFn: (rows, filterValue) =>
            rows.filter((row) => {
              return row.content.sites.some((siteName: string) =>
                siteName.toLowerCase().includes(filterValue.toLowerCase())
              );
            }),
        },
      },
      {
        columnId: 'dnRanges',
        fixedColumnSize: 'width: 40%;',
        label: 'tkey;dialplanmanagement.admin.group.dnranges',
        filter: {
          type: EntityTableFilterTypes.TEXT,
          filterFn: (rows, filterValue) =>
            rows.filter((row) => {
              return this._rangeFilterService.hasMatchingRanges(row.content.dnRanges, filterValue);
            }),
        },
      },
    ],
    resultsMessage: 'tkey;dialplanmanagement.admin.group_list.no_results',
    hasActions: true,
    cssClearButtonColumnSizeFixed: 'width: 12%;',
  };
  tableRows: EntityTableContentRow[] = [];

  private _subscription = new Subscription();

  constructor(
    private _dialPlanGroupContext: DialPlanGroupContext,
    private _breadcrumbsService: BreadcrumbsService,
    private _siteSummaryContext: SiteSummaryContext,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _toastService: ToastService,
    private _bottomNavService: BottomNavService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _rangeFilterService: RangeFilterService,
    private _telephoneNumberFilter: TelephoneNumberFilter
  ) {}

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;dialplanmanagement.title' }]);
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'bottom-nav-add-group-button',
          label: 'tkey;dialplanmanagement.admin.add_group',
          icon: SmacsIcons.PLUS_CIRCLE,
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'bottom-nav-add-group-button',
          cb: () => {
            this._router.navigate(['dial-plan-group'], { relativeTo: this._route });
          },
        },
      ])
    );

    const siteSummarySub = this._siteSummaryContext.state$.subscribe((state: SiteSummary) => {
      this._initDialPlanGroups(state);
    });
    this._subscription.add(siteSummarySub);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private _onDeleteClicked(group: DialPlanManagementGroup) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        promptBody: this._translateService.instant('tkey;global.sure.delete.dialplanmanagement.group', {
          name: group.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'prompt-modal-cancel',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'prompt-modal-confirm',
            cb: () => this._deleteGroup(group),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _getE164FormattedRange(range: { start: string; end: string }): string {
    return this._rangeFilterService.getE164FormattedRange(range);
  }

  private _deleteGroup(group: DialPlanManagementGroup): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._dialPlanGroupContext.delete(group.id).subscribe({
        next: () => {
          this._dialPlanGroupContext.init().subscribe(() => {
            this._toastService.pushDeleteToast('tkey;dialplanmanagement.save.successful.toast', group.name);
          });
          subscriber.next(null);
          subscriber.complete();
        },
        error: (err) => {
          subscriber.next();
          subscriber.complete();
          throw err;
        },
      });
    });
  }

  private _initDialPlanGroups(siteSummary: SiteSummary) {
    const dialPlanContextSub = this._dialPlanGroupContext.state$.subscribe(
      (dialPlanGroups: DialPlanManagementGroup[]) => {
        if (dialPlanGroups.some((group) => group.translationPatternRangesSection.translationPatternRanges.length)) {
          this.table.columns.find((col) => col.columnId === 'dnRanges').fixedColumnSize = 'width: 24%;';
          // add the translation pattern column, if it doesn't exist.
          this.table.columns[4] = {
            columnId: 'didRanges',
            fixedColumnSize: 'width: 24%;',
            label: 'tkey;dialplanmanagement.admin.group.didranges',
            filter: {
              type: EntityTableFilterTypes.TEXT,
              filterFn: (rows, filterValue) =>
                rows.filter((row) => {
                  return this._rangeFilterService.hasMatchingRanges(row.content.didRanges, filterValue);
                }),
            },
          };
        } else {
          this.table.columns.find((col) => col.columnId === 'dnRanges').fixedColumnSize = 'width: 48%;';
          // delete the translation pattern column, if it exists.
          this.table.columns.length = 4;
        }

        this.tableRows = dialPlanGroups
          .sort((a, b) => a.name.toString().localeCompare(b.name.toString()))
          .map((dialPlan) => {
            const sitesRow = dialPlan.clusterId
              ? [siteSummary.clusters.find((cluster) => cluster.id === dialPlan.clusterId).name]
              : dialPlan.siteIds.map((siteId) => {
                  for (const cluster of siteSummary.clusters) {
                    for (const site of cluster.sites) {
                      if (site.id === siteId) {
                        return site.name;
                      }
                    }
                  }
                });

            return {
              content: {
                id: dialPlan.id,
                name: dialPlan.name,
                sites: sitesRow,
                dnRanges: dialPlan.directoryNumberRangesSection.dnRanges,
                didRanges: dialPlan.translationPatternRangesSection.translationPatternRanges,
              },
              badges: {
                sites: {
                  maxLength: 30,
                  values: sitesRow,
                },
                dnRanges: {
                  displayToolTip: true,
                  tooltips: dialPlan.directoryNumberRangesSection.dnRanges
                    .sort((a, b) => parseInt(a.start) - parseInt(b.start))
                    .map((range) => {
                      if (range.start === range.end) {
                        return this._telephoneNumberFilter.transform(range.start);
                      } else {
                        return this._getE164FormattedRange(range);
                      }
                    }),
                  values: dialPlan.directoryNumberRangesSection.dnRanges
                    .sort((a, b) => parseInt(a.start) - parseInt(b.start))
                    .map((range) => {
                      if (range.start === range.end) {
                        return this._telephoneNumberFilter.transform(range.start);
                      } else {
                        return this._getE164FormattedRange(range);
                      }
                    }),
                },
                didRanges: {
                  displayToolTip: true,
                  tooltips: dialPlan.translationPatternRangesSection.translationPatternRanges
                    .sort((a, b) => parseInt(a.start) - parseInt(b.start))
                    .map((range) => {
                      if (range.start === range.end) {
                        return this._telephoneNumberFilter.transform(range.start);
                      } else {
                        return this._getE164FormattedRange(range);
                      }
                    }),
                  values: dialPlan.translationPatternRangesSection.translationPatternRanges
                    .sort((a, b) => parseInt(a.start) - parseInt(b.start))
                    .map((range) => {
                      if (range.start === range.end) {
                        return this._telephoneNumberFilter.transform(range.start);
                      } else {
                        return this._getE164FormattedRange(range);
                      }
                    }),
                },
              },
              actions: [
                {
                  buttonStyle: ButtonStyles.PRIMARY,
                  buttonType: ButtonTypes.LINK,
                  buttonLinkHref: window.location.href + `/dial-plan-group/${dialPlan.id}`,
                  dataAutomation: 'cisco-dial-plan-group-edit',
                  icon: SmacsIcons.EDIT,
                  onClick: () => this._router.navigate(['dial-plan-group', dialPlan.id], { relativeTo: this._route }),
                },
                {
                  buttonStyle: ButtonStyles.DANGER,
                  dataAutomation: 'cisco-dial-plan-group-delete',
                  icon: SmacsIcons.DELETE,
                  onClick: () => this._onDeleteClicked(dialPlan),
                },
                {
                  buttonStyle: ButtonStyles.DEFAULT,
                  buttonType: ButtonTypes.LINK,
                  buttonLinkHref: window.location.href + `/dial-plan-group/${dialPlan.id}/copy`,
                  dataAutomation: 'cisco-dial-plan-group-copy',
                  icon: SmacsIcons.COPY,
                  onClick: () =>
                    this._router.navigate(['dial-plan-group', dialPlan.id, 'copy'], { relativeTo: this._route }),
                },
              ],
            };
          });

        this.isLoading = false;
      }
    );
    this._subscription.add(dialPlanContextSub);
  }
}
