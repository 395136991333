<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="pb-2">
    <div [hidden]="isLoading" class="col-md-10 offset-md-1 animated fadeIn">
      <app-breadcrumbs>
        <app-breadcrumb-card-item>
          <div [innerHTML]="'tkey;admin.manual_cdr_import.description' | translate"></div>
        </app-breadcrumb-card-item>
      </app-breadcrumbs>
      <div
        *ngIf="jobIsCurrentlyRunning"
        class="card border-primary text-center mb-3"
        data-automation="cdr-import-refreshing-card"
      >
        <div class="card-body strong">
          <i class="{{ smacsIcons.SAVING }} pe-1"></i>
          <span [innerHTML]="'tkey;admin.manual_cdr_import.loading.text' | translate"></span>
        </div>
      </div>

      <div [hidden]="jobIsCurrentlyRunning">
        <smacs-file-upload [fieldId]="'cdrTextFile'" class="d-block"></smacs-file-upload>
      </div>
    </div>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
