import { Component, Input, OnInit } from '@angular/core';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import {
  DialPlanGroupEntry,
  WebexDialPlanFieldConfig,
  WebexFieldConfig,
} from '../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { ExtensionDetailsEntity } from '../extension-details-form/extension-details-form.component';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { WebexCallingDialPlanInventoriesResource } from '../../../shared/resources/webex-calling-dial-plan-inventories.resource';
import {
  ExtensionSelectorSize,
  SmacsExtensionSelectorConfig,
} from '../../../shared/extension-selector/extension-selector.component';
import { UCM_LICENSE_NAME } from '../webex-calling.component';

export interface DidSelectorFormData {
  location: string;
  did: string;
  webexCallingLicense: string;
}

@Component({
  selector: 'ziro-did-selector-form',
  templateUrl: './did-selector-form.component.html',
  styleUrls: ['./did-selector-form.component.scss'],
})
export class ZiroDidSelectorFormComponent extends SmacsFormAbstractDirective<DidSelectorFormData> implements OnInit {
  UCM_LICENSE_NAME = UCM_LICENSE_NAME;

  @Input() dialPlanGroups: WebexDialPlanFieldConfig[] = [];
  @Input() webexFieldConfig: WebexFieldConfig;
  @Input()
  set extensionDetailsEntity(newVal: ExtensionDetailsEntity) {
    this._extensionDetailsEntity = newVal;
    this._setFieldStates();
  }
  get extensionDetailsEntity(): ExtensionDetailsEntity {
    return this._extensionDetailsEntity;
  }
  private _extensionDetailsEntity: ExtensionDetailsEntity;

  formConfig: SmacsFormConfig;
  selectedDialPlanGroup: WebexDialPlanFieldConfig;
  selectedDidOptions: DialPlanGroupEntry[] = [];

  didSelectorSize = ExtensionSelectorSize.SMALL;
  smacsIcons = SmacsIcons;
  showRecentlyDeleted = true;
  showUnavailable = false;
  isSubmitted = false;
  isSearching = false;

  constructor(
    private _webexCallingDialPlanInventoriesResource: WebexCallingDialPlanInventoriesResource,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    if (this.entity && this.entity.location) {
      const match = this.dialPlanGroups.find((dialPlanGroup) => dialPlanGroup.groupName === this.entity.location);
      if (match) {
        this.isSearching = true;
        this.selectedDialPlanGroup = match;
        this._getEntriesFromGroupName();
      }
    }
    this._initFormConfig();
    this._validateAndSubmitSource.subscribe(() => {
      this.isSubmitted = true;
    });
  }

  applyComponentConfig() {
    const didComponent = this.fieldComponents.find((field) => field.fieldId === 'did');
    didComponent.applyComponentConfig(
      new SmacsExtensionSelectorConfig({
        options: this.selectedDidOptions,
        showRecentlyDeleted: this.showRecentlyDeleted,
        showUnavailable: this.showUnavailable,
        size: this.didSelectorSize,
        showInactive: false,
      })
    );
  }

  onDialPlanGroupChange() {
    this.isSearching = true;
    this.selectedDidOptions = [];
    if (this.selectedDialPlanGroup && this.selectedDialPlanGroup.groupId) {
      this._getEntriesFromGroupName();
      this.entitySource.next({
        ...this.entity,
        did: this.entity.location === this.selectedDialPlanGroup.groupName ? this.entity.did : null,
        location: this.selectedDialPlanGroup.groupName,
      });
      this.smacsFormStateService.setIsFormDirty(true);
    } else {
      this.applyComponentConfig();
      this.entitySource.next({ ...this.entity, did: null, location: null });
      this.smacsFormStateService.setIsFormDirty(true);
      this.isSearching = false;
    }
  }

  toEntity = (formData: DidSelectorFormData): DidSelectorFormData => {
    return {
      webexCallingLicense: formData.webexCallingLicense,
      location: this.selectedDialPlanGroup ? this.selectedDialPlanGroup.groupName : null,
      did: formData.did,
    };
  };

  protected submit() {
    return this.parentSubmit$;
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        webexCallingLicense: {
          label: 'tkey;helpdesk.webex_calling.extension_details.calling_license.label',
          dataAutomation: 'webex-calling-license',
          componentConfig: new SmacsSelectConfig({
            options: this.webexFieldConfig.webexCallingLicenseJson.possibleOptions,
          }),
          required: this.webexFieldConfig.webexCallingLicenseJson.required,
          defaultValue: () => this.webexFieldConfig.webexCallingLicenseJson.defaultValue,
          hidden: () =>
            !this.webexFieldConfig.webexCallingLicenseJson.show &&
            // in an env with only one license, show is false. we need to override this for UCM to avoid a blank form.
            this.webexFieldConfig.webexCallingLicenseJson.defaultValue !== UCM_LICENSE_NAME,
        },
        location: {
          label: 'tkey;helpdesk.webex_calling.location_name.label.text',
          dataAutomation: 'did-extension-selector-location',
          hidden: () => true,
          required: () => true,
          componentConfig: new SmacsSelectConfig({
            options: [],
          }),
          valExcluded: () => this.formData?.webexCallingLicense === UCM_LICENSE_NAME,
        },
        did: {
          label: 'tkey;helpdesk.webex_calling.available_dids.label.text',
          dataAutomation: 'did-extension-selector',
          required: () => !this.extensionDetailsEntity.extension,
          componentConfig: new SmacsExtensionSelectorConfig({
            options: this.selectedDidOptions,
            showRecentlyDeleted: this.showRecentlyDeleted,
            showUnavailable: this.showUnavailable,
            size: this.didSelectorSize,
          }),
          valExcluded: () => this.formData?.webexCallingLicense === UCM_LICENSE_NAME,
        },
      },
      options: {
        columnClasses: {
          label: 'col-12',
          input: 'col-12',
        },
      },
    };
  }

  private _getEntriesFromGroupName() {
    this._webexCallingDialPlanInventoriesResource.get(this.selectedDialPlanGroup.groupId).subscribe((dialPlanGroup) => {
      this.selectedDidOptions = dialPlanGroup.dialPlanEntries;
      this.applyComponentConfig();
      this.isSearching = false;
    });
  }
}
