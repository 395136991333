import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml',
})
export class StripHtmlPipe implements PipeTransform {
  transform(value: any): any {
    const stringValue = String(value);
    const element = document.createElement('div');
    element.innerHTML = stringValue;
    const text = element.textContent.trim() || element.innerText.trim();
    return text;
  }
}
