import { Component, Input, ViewChild } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import {
  ExpressionEditorKeywordSearchEntity,
  ExpressionEditorKeywordSearchFormData,
} from '../call-logs-query-editor-keyword.component';
import {
  SmacsSelectComponent,
  SmacsSelectConfig,
  SmacsSelectOption,
} from '../../../../forms/fields/select/smacs-select.component';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PhoneResult } from '../../../models/generated/smacsModels';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SearchPhoneResource } from '../../../resources/search-phone.resource';

@Component({
  selector: 'smacs-expression-editor-keyword-device-search',
  templateUrl: './expression-editor-keyword-device-search.component.html',
})
export class ExpressionEditorKeywordDeviceSearchComponent extends SmacsFormAbstractDirective<
  ExpressionEditorKeywordSearchEntity,
  ExpressionEditorKeywordSearchFormData
> {
  @Input() entity: ExpressionEditorKeywordSearchEntity;
  @ViewChild(SmacsSelectComponent) selectField: SmacsSelectComponent;

  formConfig = {
    fields: {
      'keyword-search': {
        dataAutomation: 'device-select',
        componentConfig: new SmacsSelectConfig({
          asyncOptionsFn: this.searchDevice.bind(this),
          placeholder: 'tkey;reporting.call_logs.keyword.search.device',
          clearWithInput: true,
          hideDropdownArrow: true,
          minSearchLength: 3,
          hideClear: true,
          keepSearchInput: true,
        }),
      },
    },
    options: {
      columnClasses: {
        label: 'd-none',
        input: 'col-12',
      },
    },
  } as SmacsFormConfig;

  searchDevice(q: string): Observable<SmacsSelectOption[]> {
    return this.searchPhoneResource
      .get({
        q,
      })
      .pipe(
        map((results: PhoneResult[]) => {
          const phoneResults: SmacsSelectOption[] = results.map((result: PhoneResult) => {
            return {
              label: result.ref.name,
              value: result.ref.name,
            };
          });
          const uniquePhoneResults: SmacsSelectOption[] = Array.from(
            new Map(phoneResults.map((phoneResult: SmacsSelectOption) => [phoneResult.label, phoneResult])).values()
          );
          return uniquePhoneResults.sort(
            (result1: { label: string; value: string }, result2: { label: string; value: string }) => {
              return result1.label.localeCompare(result2.label);
            }
          );
        })
      );
  }

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private searchPhoneResource: SearchPhoneResource
  ) {
    super(smacsFormStateService);
  }

  protected submit(): Observable<any> {
    return null;
  }

  toFormData = (entity: ExpressionEditorKeywordSearchEntity): ExpressionEditorKeywordSearchFormData => {
    return {
      'keyword-search': {
        label: entity.extensionUsernameOrDeviceName.value,
        value: entity.extensionUsernameOrDeviceName.value,
      },
    } as ExpressionEditorKeywordSearchFormData;
  };

  toEntity = (formData: ExpressionEditorKeywordSearchFormData): ExpressionEditorKeywordSearchEntity => {
    return {
      extensionUsernameOrDeviceName: { selection: 'device', value: formData['keyword-search'].value },
    } as ExpressionEditorKeywordSearchEntity;
  };

  handleSelectClosed() {
    if (typeof this.selectField.fieldData === 'string' && this.selectField.fieldData.length >= 3) {
      // Nothing was selected but we received a search term and we'd like to use it.
      this.smacsFormsUpdateSource.next({
        valid: SmacsFormsValidationState.VALID,
        old: {
          ...this.entity,
        },
        new: {
          extensionUsernameOrDeviceName: {
            selection: 'device',
            value: this.selectField.fieldData,
          },
        },
      });
    }
  }
}
