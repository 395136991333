import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { DialPlanExceptionManagementEditComponent } from './dial-plan-exception-management-edit.component';
import { SmacsFormsModule } from '../../../../forms/smacs-forms.module';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule, NgbModule, SmacsFormsModule],
  declarations: [DialPlanExceptionManagementEditComponent],
  exports: [DialPlanExceptionManagementEditComponent],
})
export class DialPlanExceptionManagementEditModule {}
