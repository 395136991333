import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../../../forms/smacs-form-abstract.directive';
import { AdWriteDownAttribute } from '../../../../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../../../../forms/smacs-forms-models';
import { Observable, of, Subscription } from 'rxjs';
import { SmacsFormStateService } from '../../../../../../../forms/smacs-form-state.service';
import { HtmlInputType, SmacsTextConfig } from '../../../../../../../forms/fields/text/smacs-text.component';
import { ButtonSizes, ButtonStyles } from '../../../../../../../button/button.component';
import { SmacsIcons } from '../../../../../../../shared/models/smacs-icons.enum';
import { SmacsCheckboxButtonConfig } from '../../../../../../../forms/fields/checkbox-button/checkbox-button-field.component';
import { DefaultValueService } from '../../../../../../../shared/services/default-value.service';
import { VariableEditorType } from '../../../../../../../modals/edit-custom-input-text-modal/variable-editor.service';

@Component({
  selector: 'ziro-ad-attribute-child-form',
  templateUrl: './ad-attribute-child-form.component.html',
  styleUrls: ['./ad-attribute-child-form.component.scss'],
})
export class AdAttributeChildFormComponent
  extends SmacsFormAbstractDirective<AdWriteDownAttribute>
  implements OnInit, OnDestroy
{
  @Input() index: number;
  @Input() isLast: boolean;
  @Input() hideClear: boolean;
  @Input() allAttributeNames: string[];
  @Input() dynamicPreviewValue: string;
  @Output() clearItemWasClicked = new EventEmitter<number>();
  @Output() addItemWasClicked = new EventEmitter<void>();
  previewText: string;
  buttonSizes = ButtonSizes;
  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;
  formConfig: SmacsFormConfig;
  private _subscriptions = new Subscription();
  constructor(
    protected smacsFormsStateService: SmacsFormStateService,
    private _defaultValueService: DefaultValueService
  ) {
    super(smacsFormsStateService);
  }

  ngOnInit() {
    const updateSub = this.smacsFormsUpdate$.subscribe((update) => {
      this.previewText = this._defaultValueService.generatePreview(update.new.attributeValue, this.dynamicPreviewValue);
    });
    this._subscriptions.add(updateSub);
    this.formConfig = {
      fields: {
        attributeName: {
          dataAutomation: 'attribute-name-field',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT, tabIndex: this.index + 1 }),
          required: true,
          validation: [
            {
              validator: (val: string) => {
                if (
                  !!this.allAttributeNames &&
                  this.allAttributeNames.indexOf(val) !== -1 &&
                  this.allAttributeNames.indexOf(val) !== this.index
                ) {
                  return SmacsFormsValidationState.INVALID;
                } else {
                  return SmacsFormsValidationState.VALID;
                }
              },
              message: 'tkey;admin.msdialplan.management.field.write_to_ad.card.duplicate_validation.text',
            },
          ],
        },
        attributeValue: {
          dataAutomation: 'attribute-value-field',
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.TEXT,
            displayEditor: true,
            editorType: VariableEditorType.WRITE_TO_AD,
            tabIndex: this.index + 2,
          }),
          required: true,
        },
        show: {
          dataAutomation: `attribute-show-checkbox-${this.index}`,
          label: 'tkey;admin.msdialplan.management.field.write_to_ad.card.show_checkbox.label',
          componentConfig: new SmacsCheckboxButtonConfig({
            size: ButtonSizes.NORMAL,
            noTextWrap: true,
          }),
        },
      },
    };
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  clearItemClicked() {
    this.clearItemWasClicked.emit(this.index);
  }

  addItemClicked() {
    this.addItemWasClicked.emit();
  }

  toEntity = (form: AdWriteDownAttribute): AdWriteDownAttribute => {
    return {
      attributeValue: form.attributeValue,
      attributeName: form.attributeName,
      show: form.show,
      required: true,
    };
  };

  toFormData = (entity: AdWriteDownAttribute): AdWriteDownAttribute => {
    return {
      attributeValue: entity.attributeValue,
      attributeName: entity.attributeName,
      show: entity.show,
      required: true,
    };
  };

  protected submit(): Observable<any> {
    return of(null);
  }
}
