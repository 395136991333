import { Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { SiteSummaryContext } from '../../../../../shared/contexts/site-summary.context';
import {
  ClusterResult,
  CucmMetadata,
  CustomInputText,
  EnabledType,
  LdapConfiguration,
  Select,
  Server,
  ServiceSetting,
  Site,
  SiteSummary,
  UcMetadataCache,
  UnityMetadata,
} from '../../../../../shared/models/generated/smacsModels';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '../../../../../shared/breadcrumbs/breadcrumbs.service';
import { combineLatest, Observable, of, Subject, Subscription, switchMap } from 'rxjs';
import { UcMetadataCacheContext } from '../../../../../shared/contexts/uc-metadata-cache.context';
import { SitesResource } from '../../services/sites.resource';
import { SmacsFormsUpdate } from '../../../../../forms/smacs-forms-models';
import { cloneDeep, isEqual } from 'lodash';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles } from '../../../../../button/button.component';
import { SmacsIcons } from '../../../../../shared/models/smacs-icons.enum';
import { BottomNavButton } from '../../../../../shared/bottom-nav/bottom-nav.component';
import { LdapResource } from '../../../../../shared/resources/ldap.resource';
import { SiteConfigFormEntity, SiteConfigurationFormComponent } from './site-configuration-form.component';
import { ToastTypes } from '../../../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../../../shared/services/toast.service';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { map, takeUntil, tap } from 'rxjs/operators';
import { SiteManagementServiceComponent } from '../../site-management-service/site-management-service.component';
import { ZiroModalPromptModalOptions } from '../../../../../modals/prompt-modal/prompt-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../../../shared/services/smacs-modal.service';
import { CommonFieldsStateByService, CommonFieldsUiContext } from './common-fields-ui.context';
import { SiteSideNavLink, SiteSideNavSectionEntity } from '../../site-side-nav/site-side-nav.component';
import { ServersContext } from '../../../../contexts/servers.context';
import { SmacsSelectOption } from '../../../../../forms/fields/select/smacs-select.component';

export interface ServiceSettings {
  [key: string]: ServiceSetting;
  'Desk Phone': ServiceSetting;
  'Extension Mobility': ServiceSetting;
  'IM Softphone': ServiceSetting;
  IPhone: ServiceSetting;
  Android: ServiceSetting;
  Tablet: ServiceSetting;
  CIPC: ServiceSetting;
  'Directory Number': ServiceSetting;
  'Line Features': ServiceSetting;
  Voicemail: ServiceSetting;
  'IM Presence': ServiceSetting;
  'End User': ServiceSetting;
  'Single Number Reach': ServiceSetting;
  'Translation Pattern': ServiceSetting;
}

interface ServicesToCopy {
  deskphone: ServiceSetting;
  imSoftphone: ServiceSetting;
  iPhone: ServiceSetting;
}

@Component({
  selector: 'smacs-site',
  templateUrl: './site.component.html',
  styleUrls: ['./site.component.scss'],
  providers: [CommonFieldsUiContext],
})
export class SiteComponent implements OnInit, OnDestroy {
  @ViewChild(SiteConfigurationFormComponent) siteConfigurationFormComponent: SiteConfigurationFormComponent;
  @ViewChildren(SiteManagementServiceComponent) serviceSettingComponents: QueryList<SiteManagementServiceComponent>;

  isLoading = true;
  clusterResult: ClusterResult;
  site: Site;
  cucmMetadata: CucmMetadata;
  unityMetadata: { [index: string]: UnityMetadata };
  ldapConfig: LdapConfiguration;
  siteConfigEntity: SiteConfigFormEntity;
  entities: ServiceSettings;
  siteSummary: SiteSummary;
  copiableServiceSettings: ServicesToCopy = {
    deskphone: null,
    imSoftphone: null,
    iPhone: null,
  };
  isCommonFieldsBannerVisible = false;
  sideNavLinks: { [key: string]: SiteSideNavLink[] } = {};
  sectionEntities: { [key: string]: SiteSideNavSectionEntity } = {};
  activeSection = '';
  commonFieldsState: CommonFieldsStateByService;
  unityServerOptions: SmacsSelectOption[];
  tpHasCommonFields = false;

  private _clusterId: number;
  private _siteId: number;
  private _isCopy = false;
  private _isNew = false;
  private _scrollTimeout: any = null;
  private _stopPollingSource = new Subject<void>();
  private _subscriptions = new Subscription();
  private unityServers: Server[];

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _breadcrumbsService: BreadcrumbsService,
    private _siteSummaryContext: SiteSummaryContext,
    private _ucMetadataCacheContext: UcMetadataCacheContext,
    private _sitesResource: SitesResource,
    private _bottomNavService: BottomNavService,
    private _ldapResource: LdapResource,
    private _toastService: ToastService,
    private _smacsFormStateService: SmacsFormStateService,
    private _translateService: TranslateService,
    private _smacsModalService: SmacsModalService,
    private _commonFieldsUiContext: CommonFieldsUiContext,
    private serversContext: ServersContext
  ) {}

  ngOnInit() {
    this._clusterId = parseInt(this._activatedRoute.snapshot.params.clusterId) || null;
    this._siteId = parseInt(this._activatedRoute.snapshot.params.siteId) || null;
    this._isCopy = this._router.url.endsWith('/copy');
    this._isNew = this._router.url.endsWith('/new');
    const initSub = this._commonFieldsUiContext
      .init(this._clusterId)
      .pipe(
        switchMap(() =>
          combineLatest([
            this._commonFieldsUiContext.commonFieldsState$,
            this._commonFieldsUiContext.siteWithCommonFieldsState$,
          ])
        ),
        switchMap(([commonFieldsState, commonFieldsSite]) => {
          this.commonFieldsState = commonFieldsState;
          this.tpHasCommonFields = commonFieldsState['Translation Pattern'].commonFields.length > 0;
          if (this.isLoading) {
            return !this._isNew ? this._initExistingSite(commonFieldsSite) : this._initNewSite(commonFieldsSite);
          } else return of(null);
        })
      )
      .subscribe(() => {
        this.isCommonFieldsBannerVisible = (this._isCopy || this._isNew) && this._getIsCommonFieldsPopulated();
        this._initBottomNav();
        this._initScrollEvent();
        this.isLoading = false;
      });
    this._subscriptions.add(initSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this._bottomNavService.setBottomNavValidationError(false);
    this._clearScrollEvent();
  }

  onFormUpdate(data: SmacsFormsUpdate<ServiceSetting | SiteConfigFormEntity>, form?: string) {
    if (!isEqual(cloneDeep(data.new), cloneDeep(data.old))) {
      this._setCopyDisabledState(true, 'tkey;admin.site_management.site.copy.save_changes.label');

      if (form) {
        this._updateBreadcrumb(data.new.name);
        this.siteConfigEntity = data.new as SiteConfigFormEntity;
      } else {
        const newServiceSetting = data.new as ServiceSetting;
        this._updateCanCopyFromServiceSetting(newServiceSetting);

        switch (newServiceSetting.name) {
          case 'Desk Phone':
          case 'Extension Mobility':
          case 'IM Softphone':
          case 'IPhone':
          case 'Android':
          case 'Tablet':
          case 'CIPC':
          case 'Directory Number':
          case 'Line Features':
          case 'Voicemail':
          case 'IM Presence':
          case 'End User':
          case 'Single Number Reach':
          case 'Translation Pattern': {
            this.entities[newServiceSetting.name] = newServiceSetting;
            this._updateSectionsEntity();
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  closeAlert() {
    this.isCommonFieldsBannerVisible = false;
  }

  updateSideNavLinks(fields: SiteSideNavLink[], serviceName: string) {
    this.sideNavLinks = Object.assign(cloneDeep(this.sideNavLinks), {
      [serviceName]: fields,
    });
  }

  handleCommonFieldsToggled(service: string) {
    const newValue = cloneDeep(this.commonFieldsState);
    newValue[service].areCommonFieldsVisible = !newValue[service].areCommonFieldsVisible;
    this._commonFieldsUiContext.updateCommonFieldsState(newValue);
  }

  private _initScrollEvent() {
    const scrollableContainer = document.getElementById('smacs-admin-page-content');
    scrollableContainer.addEventListener('scroll', this._onScroll);
  }

  private _clearScrollEvent() {
    const scrollableContainer = document.getElementById('smacs-admin-page-content');
    scrollableContainer.removeEventListener('scroll', this._onScroll);
  }

  private _onScroll = () => {
    if (this._scrollTimeout !== null) {
      clearTimeout(this._scrollTimeout);
    }
    this._scrollTimeout = setTimeout(() => this._onScrollEnd(), 150);
  };

  private _onScrollEnd() {
    const topNavHeight = 65;
    const scrollableElement = document.getElementById('smacs-admin-page-content');
    const scrollYPosition = scrollableElement.scrollTop + topNavHeight;
    const serviceElements = document.getElementsByTagName('ziro-site-management-service');

    for (let i = 0; i < serviceElements.length; i++) {
      const element = serviceElements[i];
      const key = element.getAttribute('data-entity-key');
      const boundingRect = element.getBoundingClientRect();
      const yStart = boundingRect.y;
      const defaultYStart = parseInt((yStart + scrollYPosition - topNavHeight).toFixed());
      const yEnd = defaultYStart + boundingRect.height;

      if (scrollYPosition >= defaultYStart && scrollYPosition <= yEnd) {
        this.activeSection = key;
      }
    }
  }

  private _updateSectionsEntity() {
    Object.keys(this.entities).map((key: string) => {
      const entity = this.entities[key];
      this.sectionEntities[key] = {
        enabled: entity.enabled === EnabledType.ENABLED,
        oneClick: entity.oneClickEnabled,
      };
    });
  }

  private _updateCanCopyFromServiceSetting(serviceSetting: ServiceSetting) {
    const copiableServiceSetting =
      serviceSetting.enabled === EnabledType.ENABLED && serviceSetting.oneClickEnabled ? serviceSetting : null;
    switch (serviceSetting.name) {
      case 'Desk Phone':
        this.copiableServiceSettings.deskphone = copiableServiceSetting;
        break;
      case 'IM Softphone':
        this.copiableServiceSettings.imSoftphone = copiableServiceSetting;
        break;
      case 'IPhone':
        this.copiableServiceSettings.iPhone = copiableServiceSetting;
        break;
      default:
        break;
    }
  }

  private _isChildFormsValid(): boolean {
    return (
      this.serviceSettingComponents
        .filter((component) => component.serviceSettingForm.entity.enabled !== EnabledType.DISABLED)
        .every((component) => component.serviceSettingForm.isFormValid()) &&
      this.siteConfigurationFormComponent.isFormValid()
    );
  }

  private _validateChildForms() {
    this.siteConfigurationFormComponent._validateAndSubmitSource.next(true);
    this.siteConfigurationFormComponent.validateAllFields();

    this.serviceSettingComponents.forEach((component: SiteManagementServiceComponent) => {
      component.serviceSettingForm._validateAndSubmitSource.next(true);
      component.serviceSettingForm.validateAllFields();
    });
  }

  private _toggleSubmitState(isSubmitting: boolean) {
    this._setSavePendingState(isSubmitting);
    this._setCopyDisabledState(isSubmitting);
    this._setCancelDisabledState(isSubmitting);
    this._setDeleteButtonState(isSubmitting);
    this._setBottomNavValidationError(!isSubmitting);
  }

  private _submit() {
    this._toggleSubmitState(true);
    this._validateChildForms();

    setTimeout(() => {
      if (!this._isChildFormsValid()) {
        this._toggleSubmitState(false);
        this.siteConfigurationFormComponent._validateAndSubmitSource.next(true);
        this.serviceSettingComponents.forEach((component: SiteManagementServiceComponent) => {
          component.serviceSettingForm._validateAndSubmitSource.next(true);
        });
        return;
      }

      const payload: Site = {
        id: this._isCopy || this._isNew ? undefined : this.site.id,
        ldapSiteName: this.siteConfigEntity.ldapAttribute,
        name: this.siteConfigEntity.name,
        serviceSettings: [...Object.values(this.entities)],
      };

      const voicemailServiceSetting = payload.serviceSettings.find(
        (setting: ServiceSetting) => setting.name === 'Voicemail'
      );
      const currentUnityServerName = voicemailServiceSetting.selects.find(
        (select) => select.name === 'unity_server'
      ).value;
      if (!!currentUnityServerName) {
        const currentUnityServerId = this.unityServers.find(
          (server) => server.description === currentUnityServerName
        ).id;

        voicemailServiceSetting.selects.forEach((select) => {
          if (select.name === 'unity_server') {
            select.value = currentUnityServerId.toString();
          }
        });
      }

      if (this._isNew || this._isCopy) {
        this._sitesResource.post(this.clusterResult.id, payload).subscribe(() => this._postSave());
      } else {
        this._sitesResource.put(this.clusterResult.id, payload).subscribe(() => this._postSave());
      }
    }, 100);
  }

  private _postSave() {
    this._smacsFormStateService.setIsFormDirty(false);
    this._toastService.push(
      ToastTypes.SUCCESS,
      SmacsIcons.SITE,
      'tkey;shared.toast.save.success.title',
      'tkey;site.save.successful.toast.message',
      { site: this.siteConfigEntity.name }
    );
    this._router.navigate([`/admin/site-management/clusters/${this._clusterId}/sites`]);
  }

  private _initMetadata(): Observable<[LdapConfiguration, SiteSummary, UcMetadataCache, Server[]]> {
    return combineLatest([
      this._ldapResource.getLdapConfiguration(),
      this._siteSummaryContext.state$,
      this._ucMetadataCacheContext.state$,
      this.serversContext.state$,
    ]).pipe(
      tap(
        ([ldapConfig, siteSummaryState, metadataCacheState, servers]: [
          LdapConfiguration,
          SiteSummary,
          UcMetadataCache,
          Server[]
        ]) => {
          this.ldapConfig = ldapConfig;
          this.siteSummary = siteSummaryState;
          this.clusterResult = siteSummaryState.clusters.find(
            (clusterResult: ClusterResult) => clusterResult.id === this._clusterId
          );
          this.cucmMetadata = metadataCacheState.cucmMetadataCache[this.clusterResult.cucmServerId];
          this.unityMetadata = metadataCacheState.unityMetadataCache;
          this.unityServers = servers.filter((server) => server.serverType === 'Unity');
          this.unityServerOptions = this.unityServers.map((server) => ({
            label: server.description,
            value: server.id,
          }));
        }
      ),
      takeUntil(this._stopPollingSource)
    );
  }

  private _setImp(site: Site): Site {
    if (!this.clusterResult.impServerId) {
      return site;
    }

    return {
      ...site,
      serviceSettings: site.serviceSettings.map((serviceSetting: ServiceSetting) => {
        if (serviceSetting.name === 'IM Presence') {
          return {
            ...serviceSetting,
            enabled: EnabledType.ENABLED,
            oneClickEnabled: true,
          };
        }

        return serviceSetting;
      }),
    };
  }

  private _initNewSite(commonFieldsSite: Site): Observable<void> {
    return this._initMetadata().pipe(
      map(() => {
        this.site = this._setImp(commonFieldsSite);
        this.entities = this._mapServiceSettingsArrayToInterface(this.site);
        this._updateSectionsEntity();
        this._updateBreadcrumb('');
        this.siteConfigEntity = {
          name: '',
          ldapAttribute: '',
        };
      })
    );
  }

  private _initExistingSite(commonFieldsSite: Site): Observable<void> {
    return combineLatest([this._sitesResource.get(this._clusterId, this._siteId), this._initMetadata()]).pipe(
      map(([siteResponse]: [Site, [LdapConfiguration, SiteSummary, UcMetadataCache, Server[]]]) => {
        const site = !this._isCopy ? siteResponse : this._copySite(siteResponse, commonFieldsSite);
        this.site = this._populateCommonSettingsOrDefaultsForDisabledServiceSettings(site, commonFieldsSite);
        this.entities = this._mapServiceSettingsArrayToInterface(this.site);
        this._updateSectionsEntity();
        this._updateBreadcrumb(this._isCopy ? `Copy of ${this.site.name}` : this.site.name);
        this.siteConfigEntity = {
          name: this._isCopy ? `Copy of ${this.site.name}` : this.site.name,
          ldapAttribute: this._isCopy ? '' : this.site.ldapSiteName,
        };

        this.site.serviceSettings.forEach((serviceSetting) => {
          this._updateCanCopyFromServiceSetting(serviceSetting);
        });
      })
    );
  }

  private _populateCommonSettingsOrDefaultsForDisabledServiceSettings(apiSite: Site, commonFieldsSite: Site): Site {
    // when a service setting is disabled, the api will return blank arrays for all the field types.
    // we need to populate these with the common fields, in case the user enables them.
    const populatedSettings = apiSite.serviceSettings.map((serviceSetting: ServiceSetting) => {
      if (serviceSetting.enabled === EnabledType.DISABLED) {
        const commonServiceSetting = commonFieldsSite.serviceSettings.find(
          (blankServiceSetting: ServiceSetting) => blankServiceSetting.name === serviceSetting.name
        );
        return {
          ...cloneDeep(commonServiceSetting),
          enabled: EnabledType.DISABLED,
        };
      }
      return serviceSetting;
    });
    return {
      ...apiSite,
      serviceSettings: populatedSettings,
    };
  }

  private _copySite(site: Site, commonFieldsSite: Site): Site {
    return {
      ...site,
      id: undefined,
      ldapSiteName: '',
      serviceSettings: site.serviceSettings.map((setting: ServiceSetting) => {
        return {
          ...setting,
          selects: setting.selects.map((select: Select) => {
            return {
              ...select,
              value:
                select.name === 'devicepool'
                  ? this._getDevicePoolCommonValueIfExists(setting, commonFieldsSite)
                  : select.value,
            };
          }),
        };
      }),
    };
  }

  private _getDevicePoolCommonValueIfExists(serviceSetting: ServiceSetting, commonFieldsSite: Site): string {
    if (
      Object.keys(this.commonFieldsState).length &&
      this.commonFieldsState[serviceSetting.name].commonFields.includes('devicepool')
    ) {
      const serviceSettingWithDevicePool = commonFieldsSite.serviceSettings.find(
        (ss) => ss.enabled === EnabledType.ENABLED && ss.name === serviceSetting.name
      );
      return serviceSettingWithDevicePool.selects.find((filterSelect) => filterSelect.name === 'devicepool').value;
    } else {
      return '';
    }
  }

  private _getCorrectDefaultMobilityIdentitySettings(site: Site, service: string): ServiceSetting {
    const settings = site.serviceSettings.find((setting: ServiceSetting) => setting.name === service);
    const mobilityIdentity = settings.checkboxes.find(
      (checkboxField) => checkboxField.name === 'mobility_identity_management'
    );
    settings.customInputTexts = settings.customInputTexts.map((field: CustomInputText) => {
      if (field.name === 'mobility_identity_name') {
        field.required = mobilityIdentity.value ? true : false;
      }
      return field;
    });

    return settings;
  }

  private _mapServiceSettingsArrayToInterface(site: Site): ServiceSettings {
    const voicemailServiceSetting = site.serviceSettings.find(
      (setting: ServiceSetting) => setting.name === 'Voicemail'
    );
    const currentUnityServerId = voicemailServiceSetting.selects.find((select) => select.name === 'unity_server').value;
    if (!!currentUnityServerId) {
      const currentUnityServerName = this.unityServers.find(
        (server) => server.id === Number(currentUnityServerId)
      ).description;

      voicemailServiceSetting.selects.forEach((select) => {
        if (select.name === 'unity_server') {
          select.value = currentUnityServerName;
        }
      });
    }

    return {
      'Desk Phone': site.serviceSettings.find((setting: ServiceSetting) => setting.name === 'Desk Phone'),
      'Extension Mobility': site.serviceSettings.find(
        (setting: ServiceSetting) => setting.name === 'Extension Mobility'
      ),
      'IM Softphone': site.serviceSettings.find((setting: ServiceSetting) => setting.name === 'IM Softphone'),
      IPhone: this._getCorrectDefaultMobilityIdentitySettings(site, 'IPhone'),
      Android: this._getCorrectDefaultMobilityIdentitySettings(site, 'Android'),
      Tablet: site.serviceSettings.find((setting: ServiceSetting) => setting.name === 'Tablet'),
      CIPC: site.serviceSettings.find((setting: ServiceSetting) => setting.name === 'CIPC'),
      'Directory Number': site.serviceSettings.find((setting: ServiceSetting) => setting.name === 'Directory Number'),
      'Line Features': site.serviceSettings.find((setting: ServiceSetting) => setting.name === 'Line Features'),
      Voicemail: voicemailServiceSetting,
      'IM Presence': site.serviceSettings.find((setting: ServiceSetting) => setting.name === 'IM Presence'),
      'End User': site.serviceSettings.find((setting: ServiceSetting) => setting.name === 'End User'),
      'Single Number Reach': site.serviceSettings.find(
        (setting: ServiceSetting) => setting.name === 'Single Number Reach'
      ),
      'Translation Pattern': site.serviceSettings.find(
        (setting: ServiceSetting) => setting.name === 'Translation Pattern'
      ),
    };
  }

  private _updateBreadcrumb(siteName: string) {
    this._breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;admin.site_management.cluster_sites.header',
        url: '/admin/site-management/clusters',
        routerLink: true,
      },
      {
        label: this.clusterResult.name,
        url: `/admin/site-management/clusters/${this.clusterResult.id}/sites`,
        routerLink: true,
      },
      {
        label: siteName,
      },
    ]);
  }

  private _initBottomNav() {
    const buttons: BottomNavButton[] = [
      {
        id: 'cancel-site',
        dataAutomation: 'cancel-site',
        label: 'tkey;global.button.cancel.text',
        cb: () => {
          this._router.navigate([`/admin/site-management/clusters/${this._clusterId}/sites`]);
        },
        buttonClass: ButtonStyles.DEFAULT,
      },
      {
        id: 'save-site',
        dataAutomation: 'save-site',
        label: 'tkey;global.button.save.text',
        icon: SmacsIcons.OK,
        cb: () => this._submit(),
        buttonClass: ButtonStyles.PRIMARY,
      },
    ];

    if (!this._isCopy && !this._isNew) {
      buttons.splice(0, 0, {
        id: 'copy-site',
        dataAutomation: 'copy-site',
        label: 'tkey;admin.site_management.site.copy.label',
        cb: () => {
          this._router.navigate([`/admin/site-management/clusters/${this._clusterId}/sites/${this._siteId}/copy`]);
        },
        buttonClass: ButtonStyles.INFO,
      });
      buttons.splice(1, 0, {
        id: 'delete-site',
        dataAutomation: 'delete-site',
        label: 'tkey;global.button.delete.text',
        cb: () => this._openDeleteSiteModal(),
        buttonClass: ButtonStyles.DANGER,
      });
    }

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttons));
  }

  private _openDeleteSiteModal() {
    const siteName = this.siteConfigurationFormComponent.entity.name;

    const options: ZiroModalPromptModalOptions = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        promptBody: this._translateService.instant('tkey;admin.sites.delete.confirm.text', {
          name: siteName,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'prompt-modal-cancel',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () =>
              this._sitesResource.delete(this._clusterId, this._siteId).pipe(
                tap(() => {
                  this._stopPollingSource.next();
                  this._toastService.push(
                    ToastTypes.INFO,
                    SmacsIcons.DELETE,
                    'tkey;admin.sites.delete.toast.title',
                    'tkey;admin.sites.delete.toast.message',
                    { siteName: siteName },
                    null,
                    'text-danger'
                  );
                  this._router.navigate([`/admin/site-management/clusters/${this._clusterId}/sites`]);
                }),
                switchMap(() => this._siteSummaryContext.refreshSiteSummary())
              ),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _setSavePendingState(setting: boolean) {
    this._bottomNavService.setButtonPendingState('save-site', setting);
  }

  private _setCancelDisabledState(state: boolean) {
    this._bottomNavService.setButtonDisabledState('cancel-site', state);
  }

  private _setCopyDisabledState(state: boolean, tooltip = '') {
    this._bottomNavService.setButtonDisabledState('copy-site', state, tooltip);
  }

  private _setDeleteButtonState(state: boolean) {
    this._bottomNavService.setButtonDisabledState('delete-site', state);
  }

  private _setBottomNavValidationError(hasError: boolean) {
    this._bottomNavService.setBottomNavValidationError(hasError);
  }

  private _getIsCommonFieldsPopulated(): boolean {
    for (const key in this.commonFieldsState) {
      if (this.commonFieldsState[key].commonFields.length > 0) {
        return true;
      }
    }
    return false;
  }

  protected readonly Object = Object;
}
