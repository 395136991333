<button
  type="button"
  class="btn d-block w-100 match-input-lg"
  [attr.data-automation]="'automatic_provisioning_ldap_filter_results'"
  [ngbPopover]="
    state !== viewMembersState.ERROR && state !== viewMembersState.SEARCHING ? ldapFilterMatchesPopover : null
  "
  [placement]="'bottom-start'"
  [popoverClass]="'ldap-filter-results-popover'"
  [autoClose]="'outside'"
  (shown)="focusOnFilterInput()"
  (hidden)="onPopoverHidden()"
  [ngClass]="getButtonClass()"
  [disabled]="state === viewMembersState.SEARCHING || disabled"
>
  <span [hidden]="state !== viewMembersState.IDLE">
    <span [hidden]="state !== viewMembersState.SEARCHING" [attr.class]="smacsIcons.SAVING"></span>
    <span [hidden]="state !== viewMembersState.IDLE" [attr.class]="smacsIcons.LDAP"></span>
    <strong class="ms-1">{{ members?.length }}</strong>
    {{ 'tkey;pages.zero_touch.provisioning.edit.form.ldap_filter.view_results' | translate }}
  </span>
  <span [hidden]="state !== viewMembersState.SEARCHING">
    <i [attr.class]="smacsIcons.SAVING"></i>
    {{ 'tkey;admin.ldap.groups.members.searching' | translate }}
  </span>
  <span [hidden]="state !== viewMembersState.ERROR">{{ errorMessage | translate }}</span>
</button>

<ng-template #ldapFilterMatchesPopover>
  <smacs-filter-input
    (filterChanged)="onFilterChanged($event)"
    [dataAutomation]="'view-members-filter'"
  ></smacs-filter-input>
  <div [hidden]="!members?.length" data-automation="view-members-list" class="members-list-wrapper mt-1">
    <ul class="list-unstyled mb-0">
      <li *ngFor="let match of getFilteredLdapSearchResults()">{{ match }}</li>
    </ul>
  </div>
</ng-template>
