<div class="row" [attr.data-automation]="config.dataAutomation">
  <h5 class="col-lg-11">
    <span *ngIf="state.required" class="text-danger smacs-forms-asterisk">*</span>
    <span [innerHTML]="getLabel() | translate"></span>
  </h5>
  <div class="col-lg-1">
    <div
      class="form-check float-end"
      [hidden]="state.hidden"
      [attr.data-automation]="config.dataAutomation + '-toggle'"
      [ngClass]="{
        'form-switch': checkboxType === htmlCheckboxType.SWITCH,
        'switch-sm': checkboxType === htmlCheckboxType.SWITCH && size === htmlSwitchSize.SM,
        'switch-lg': checkboxType === htmlCheckboxType.SWITCH && size === htmlSwitchSize.LG,
        'smacs-forms-feedback': showValidation,
        'smacs-forms-error': validationState === ValStates.INVALID,
        'smacs-forms-warning':
          validationState === ValStates.PENDING ||
          validationState === ValStates.WARNING ||
          misconfigurationFeedbackMessage
      }"
    >
      <div
        [ngbTooltip]="getDisabledTooltip()?.content | translate : getDisabledTooltip()?.params"
        [disableTooltip]="!state.disabled"
        [container]="'body'"
        class="d-flex"
      >
        <input
          type="checkbox"
          class="form-check-input ms-0"
          [attr.id]="config.dataAutomation + '-input'"
          [attr.data-automation]="'smacs-checkbox-input'"
          [(ngModel)]="fieldData"
          #ngModelDir="ngModel"
          (ngModelChange)="updateStateAndSelf($event, ngModelDir)"
          [disabled]="state.disabled"
        />
      </div>
    </div>
  </div>
  <div [innerHTML]="config.options?.content | translate"></div>
</div>
