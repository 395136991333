<div>
  <div class="container-fluid">
    <smacs-navset-form-nav
      fieldId="itemNav"
      [upstreamEntity]="upstreamEntity"
      [allowedDropdownItemNames]="allowedDropdownItemNames"
      [isFormSubmitted]="isFormSubmitted"
    ></smacs-navset-form-nav>
  </div>
</div>
