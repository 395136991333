import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PhoneResult } from '../models/generated/smacsModels';
import { Observable } from 'rxjs';
import { SearchAbstractResource } from '../../self-serve/resources/search/search.abstract.resource';

export class SearchPhoneQueryParams {
  q?: string;
  name?: string;
  ownerUsername?: string;
  description?: string;
  extension?: string;
  'cucm-server-id'?: number;
  limit?: number;
}

@Injectable()
export class SearchPhoneResource extends SearchAbstractResource {
  constructor(protected http: HttpClient) {
    super(http);
  }

  get(params: SearchPhoneQueryParams): Observable<PhoneResult[]> {
    params.limit = 100;
    return this.search<PhoneResult[]>('/services/cisco/macs/phones/search', params);
  }
}
