import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmacsIcons } from '../../models/smacs-icons.enum';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'smacs-filter-input',
  templateUrl: './filter-input.component.html',
  styleUrls: ['./filter-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SmacsFilterInputComponent,
    },
  ],
})
export class SmacsFilterInputComponent implements ControlValueAccessor {
  @Input() value = '';
  @Input() placeholder = '';
  @Input() dataAutomation = '';
  @Input() appendedContent = '';
  @Input() isDisabled = false;
  @Input() asyncText = false;
  @Input() clearAllFilter = false;
  @Output() filterChanged = new EventEmitter<string>();

  smacsIcons = SmacsIcons;

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChanged: any) {}

  registerOnTouched(fn: any) {}

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  onKeyUpEvent() {
    setTimeout(() => {
      this.filterChanged.emit(this.value);
    }, 500);
  }

  onKeyDownEvent() {
    if (this.isDisabled) {
      return false;
    }
  }

  onClear() {
    this.value = null;
    this.filterChanged.emit(this.value);
  }
}
