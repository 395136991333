<smacs-text fieldId="groupName"></smacs-text>
<smacs-radio fieldId="pstnConnectivityType"></smacs-radio>
<smacs-text fieldId="carrierName"></smacs-text>
<smacs-radio fieldId="callingType"></smacs-radio>
<smacs-select
  #sharedCallingRoutingPolicy
  fieldId="sharedCallingRoutingPolicy"
  (selectWasClicked)="reloadSharedCallingPolicyOptions()"
></smacs-select>
<smacs-select fieldId="enforcedNumbersUsageLocation"></smacs-select>
<smacs-text fieldId="mainNumber"></smacs-text>
<div
  class="row mb-1"
  [ngbTooltip]="'tkey;admin.msdialplan.management.group.disabled.tooltip' | translate"
  [disableTooltip]="!isDisabledForAdmin"
>
  <div class="col-6 mb-3" [hidden]="formData.callingType === CallingType.SHARED_CALLING">
    <label
      [ngClass]="{
        'smacs-forms-error': extensionRangesDisplayFormValidationState === validationStates.INVALID,
        'smacs-forms-warning': extensionRangesDisplayFormValidationState === validationStates.WARNING
      }"
      data-automation="extension-ranges-label"
      class="smacs-forms-label col-form-label"
    >
      <span class="text-danger smacs-forms-asterisk">*</span>
      <span>
        {{ 'tkey;admin.msdialplan.management.group_list.dn_ranges' | translate }}
      </span>
    </label>
    <ziro-extension-ranges-display-form
      data-automation="extension-ranges-display-form"
      *ngIf="displayRangeForm"
      [entity]="displayRangeFormEntity"
      [optionalValidators]="displayRangeFormOptionalValidators"
      [isRequired]="true"
      [isZpm]="true"
      [isDisabled]="isDisabledForAdmin"
      [zpmCallingType]="entity.callingType"
      (smacsFormsUpdate$)="onRangeFormUpdate($event)"
    ></ziro-extension-ranges-display-form>
  </div>
  <div class="col-6" [hidden]="formData.callingType === CallingType.SHARED_CALLING">
    <smacs-textarea fieldId="bulkRanges"></smacs-textarea>
    <smacs-button
      [id]="'msDialPlanGroupGenerateBulkButton'"
      [dataAutomation]="'ms-dialplan-group-generate-ranges-button'"
      [label]="'tkey;dialplanmanagement.admin.group.generate.button'"
      (clicked)="generateBulkRangesClicked()"
      [isDisabled]="isDisabledForAdmin || validationFlags['bulkRanges'] === validationStates.INVALID"
      [tooltip]="
        isDisabledForAdmin
          ? ('tkey;admin.msdialplan.management.group.disabled.tooltip' | translate)
          : ('tkey;dialplanmanagement.admin.group.generate.button.disabled_tooltip' | translate)
      "
      [isTooltipDisabled]="!isDisabledForAdmin && this.validationFlags['bulkRanges'] === validationStates.VALID"
    ></smacs-button>
  </div>
</div>
<ziro-custom-select
  *ngFor="let fieldId of fieldIds"
  [fieldId]="fieldId"
  [id]="fieldId"
  [attr.data-automation]="fieldId"
  [isServiceEnabled]="true"
></ziro-custom-select>
<ziro-custom-input-text
  [fieldId]="'lineUri'"
  [id]="'lineUri'"
  [attr.data-automation]="'lineUri-input'"
></ziro-custom-input-text>
<smacs-text fieldId="billingLocation"></smacs-text>

<div [hidden]="!isUserS8Support || !isBandwidthConfigured">
  <smacs-collapsible-card
    (toggleSwitch)="onBandwidthRangeSelected($event)"
    [label]="'tkey;admin.msdialplan.management.field.bandwidth_range.label'"
    [icon]="SmacsIcons.BANDWIDTH"
    [cssClass]="'bg-info text-white fs-5 p-3'"
    [shouldBeOpen]="isBandwidthGroup"
    [dataAutomation]="'bandwidth-dial-plan-group-card'"
    [switchId]="'bandwidthDialplan'"
    [isDisabled]="
      entity.callingType === CallingType.EXTENSION ||
      entity.pstnConnectivityType === PstnConnectivityType.OPERATOR_CONNECT ||
      entity.pstnConnectivityType === PstnConnectivityType.MICROSOFT_CALLING_PLANS ||
      entity.callingType === CallingType.SHARED_CALLING
    "
    [disabledTooltip]="getBandwidthCallingSettingsTooltip()"
  >
    <ziro-custom-select
      [fieldId]="'e911Geolocation'"
      [id]="'e911Geolocation'"
      [attr.data-automation]="'e911Geolocation'"
      [hidden]="false"
      [isServiceEnabled]="true"
    ></ziro-custom-select>
  </smacs-collapsible-card>
</div>
<div *ngIf="isSyncStatusEstablished" [hidden]="!onPremAdWriteDownEnabled && !entity.adWriteDownList">
  <smacs-collapsible-card
    (toggleSwitch)="onAdAttributesToggled($event)"
    [label]="'tkey;admin.msdialplan.management.field.write_to_ad.card.label'"
    [shouldBeOpen]="!!entity.adWriteDownList && entity.adWriteDownList.length > 0 && isZpcSyncPresent"
    [dataAutomation]="'write-to-ad-card'"
    [switchId]="'writeToAdSwitch'"
    [isDisabled]="!isZpcSyncPresent"
    [disabledTooltip]="'tkey;admin.msdialplan.management.field.write_to_ad.card.disabled_tooltip'"
    [icon]="SmacsIcons.LDAP"
    [cssClass]="'bg-info text-white fs-5 p-3'"
  >
    <div class="alert alert-warning d-flex justify-content-between" *ngIf="isZpcSyncInWarningState">
      <span>
        <i class="{{ smacsIcons.WARNING }} me-1"></i>
        <strong
          data-automation="ad-attribute-warning-banner-text"
          [innerHTML]="'tkey;shared.model.dial_plan_management.ad_attributes.warning_banner.text' | translate"
        ></strong>
      </span>
    </div>
    <div
      class="alert alert-warning d-flex justify-content-between"
      *ngIf="!onPremAdWriteDownEnabled && entity.adWriteDownList"
    >
      <span>
        <i class="{{ smacsIcons.WARNING }} me-1"></i>
        <strong
          data-automation="ad-write-down-disabled-warning-banner-text"
          [innerHTML]="'tkey;shared.model.dial_plan_management.ad_write_down_disabled_banner.text' | translate"
        ></strong>
      </span>
    </div>
    <ziro-ad-attributes
      (adItemWasChanged)="handleAttributeUpdate()"
      [initialAdAttributes]="initialAdAttributesEntity"
      [dynamicPreviewValue]="firstNumberInRange"
    ></ziro-ad-attributes>
  </smacs-collapsible-card>
</div>
