import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityTableComponent } from './entity-table.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from '../../button/button.module';
import { EntityTableBadgeComponent } from './entity-table-badge/entity-table-badge.component';
import { PaginationModule } from '../pagination/pagination.module';
import { SmacsFormsModule } from '../../forms/smacs-forms.module';
import { FormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AutoSelectDirective } from './auto-select.directive';
import { LoadingBlockModule } from '../loading-block/loading-block.module';

@NgModule({
  declarations: [EntityTableComponent, EntityTableBadgeComponent, AutoSelectDirective],
  exports: [EntityTableComponent, EntityTableBadgeComponent],
  imports: [
    CommonModule,
    NgbTooltip,
    TranslateModule,
    ButtonModule,
    PaginationModule,
    SmacsFormsModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    LoadingBlockModule,
  ],
})
export class EntityTableModule {}
