<div class="row mb-3" [attr.data-automation]="dataAutomation">
  <div class="col-md-12 position-relative">
    <div *ngIf="!getDisabledState()" class="input-overlay-wrapper row">
      <div>
        <button
          type="button"
          data-automation="select-config-form-input-overlay-button"
          (click)="openModal()"
          class="input-overlay-button"
        ></button>
      </div>
    </div>
    <smacs-text class="w-100" [fieldId]="'possibleOptionsFormatted'" #possibleOptions></smacs-text>
  </div>
  <div class="col-md-12 select-config-extras">
    <div class="row">
      <div class="offset-lg-3 col-lg-9" [ngClass]="{ 'select-config-extras--helpless': !helpText }">
        <div>
          <span
            [ngClass]="{
              'text-danger': getTextValidationState() === ValidationState.INVALID,
              'text-warning': getTextValidationState() === ValidationState.WARNING
            }"
            class="text-80"
          >
            {{
              this.isMultiSelect
                ? ('tkey;custom_select.default_values.label' | translate)
                : ('tkey;custom_select.default_value.label' | translate)
            }}
          </span>
          <smacs-button
            [dataAutomation]="'select-config-form-default-values-button'"
            [label]="isMultiSelect ? arrayToString(entity?.defaultValues) : entity.defaultValue"
            [buttonStyle]="buttonStyles.LINK"
            [cssClass]="'text-80'"
            (clicked)="openModal()"
          ></smacs-button>
        </div>
        <div class="d-flex">
          <smacs-checkbox
            [isAlwaysRequired]="isAlwaysRequired"
            [fieldId]="'required'"
            [ngClass]="{ 'me-4': isRequiredVisible }"
            #required
          ></smacs-checkbox>
          <smacs-checkbox [fieldId]="'show'" #show></smacs-checkbox>
        </div>
        <div
          *ngIf="helpText"
          class="small form-text text-muted select-config-extras__help-text"
          [innerHtml]="helpText | translate"
        ></div>
      </div>
    </div>
  </div>
</div>
