<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;dial_plan_management.list.description' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div data-automation="cisco-dial-plan-management-group-list">
      <app-entity-table [table]="table" [tableRows]="tableRows"></app-entity-table>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
