import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';
import {
  EndUserResult,
  ExtensionMobilityRef,
  SnrDestination,
  SnrProfileRef,
  VoicemailRef,
} from '../../shared/models/generated/smacsModels';
import {
  DnContainer,
  PhoneContainer,
  SelfServe360View,
  SelfServeUiContext,
  SnrContainer,
} from '../contexts/self-serve-ui.context';
import { Subscription } from 'rxjs';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import activateDeskphoneJson from 'ziro-static/json/self_serve_activate_deskphone.json';
import callForwardJson from 'ziro-static/json/self_serve_call_forward.json';
import editDeskphoneJson from 'ziro-static/json/self_serve_edit_deskphone.json';
import extMobilityJson from 'ziro-static/json/self_serve_ext_mobility.json';
import replaceDeskphoneJson from 'ziro-static/json/self_serve_replace_deskphone.json';
import snrJson from 'ziro-static/json/self_serve_snr.json';
import vmJson from 'ziro-static/json/self_serve_vm.json';
import { Global360ViewContext } from '../../shared/contexts/global-360-view.context';

@Component({
  selector: 'app-self-serve-user-details',
  templateUrl: './self-serve-user-details.component.html',
  styleUrls: ['./self-serve-user-details.component.scss'],
})
export class SelfServeUserDetailsComponent implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;
  isLoading = true;
  user: EndUserResult;
  userFullName = '';
  selfServe360View = {} as SelfServe360View;

  voicemails: VoicemailRef[] = [];
  extensionMobilities: ExtensionMobilityRef[] = [];
  snrDestinations: SnrDestination[] = [];
  snrProfiles: SnrProfileRef[] = [];
  callForwards: DnContainer[] = [];
  editDeskphones: PhoneContainer[] = [];
  replaceDeskphones: PhoneContainer[] = [];

  activateDeskphoneJson = activateDeskphoneJson;
  callForwardJson = callForwardJson;
  editDeskphoneJson = editDeskphoneJson;
  extMobilityJson = extMobilityJson;
  replaceDeskphoneJson = replaceDeskphoneJson;
  snrJson = snrJson;
  vmJson = vmJson;
  isFetching360View$ = this.global360ViewContext.isFetching$;

  private _subscriptions = new Subscription();

  constructor(
    private selfServeUiContext: SelfServeUiContext,
    private route: ActivatedRoute,
    private global360ViewContext: Global360ViewContext
  ) {}

  ngOnInit() {
    const username = get(this.route, 'snapshot.params.username');

    if (!this.selfServeUiContext.selfServe360View) {
      this.selfServeUiContext.initUserDetails(username);
    }

    const uiContextSub = this.selfServeUiContext.selfServe360View$.subscribe((selfServe360View: SelfServe360View) => {
      this.user = selfServe360View.endUsers[0];

      if (!username || this.user.ref.username === username) {
        this.userFullName = this.user.ref.firstName + ' ' + this.user.ref.lastName;
        this.selfServe360View = selfServe360View;

        // Only populate arrays if we can show those services, cannot hide in html due to ngIfs / ngFor so hold all
        // services in array to loop and display cleanly
        if (this.showVoicemail(selfServe360View.voicemails)) {
          this.voicemails = [...selfServe360View.voicemails];
        } else {
          this.voicemails = [];
        }

        if (this.showExtensionMobility(selfServe360View.extensionMobilities)) {
          this.extensionMobilities = [...selfServe360View.extensionMobilities];
        } else {
          this.extensionMobilities = [];
        }

        if (this.showSnr(selfServe360View.snrContainers)) {
          this.snrDestinations = [];
          this.snrProfiles = [];
          this.selfServe360View.snrContainers.forEach((snrContainer: SnrContainer) => {
            this.snrDestinations = this.snrDestinations.concat(snrContainer.snrDestination);
          });
        } else if (selfServe360View.canProvisionSnr) {
          this.snrDestinations = [];
          this.snrProfiles = this.selfServe360View.snrContainers.map(
            (snrContainer: SnrContainer) => snrContainer.snrProfileRef
          );
        } else {
          this.snrDestinations = [];
          this.snrProfiles = [];
        }

        if (this.showCallForward(selfServe360View.dnContainers)) {
          this.callForwards = [...selfServe360View.dnContainers];
        } else {
          this.callForwards = [];
        }

        if (this.showEditDeskphone(selfServe360View.phoneContainers)) {
          this.editDeskphones = [...selfServe360View.phoneContainers];
        } else {
          this.editDeskphones = [];
        }

        if (selfServe360View.canReplaceDeskphone) {
          this.replaceDeskphones = [...selfServe360View.phoneContainers];
        } else {
          this.replaceDeskphones = [];
        }

        this.isLoading = false;
      }
    });
    this._subscriptions.add(uiContextSub);
  }

  ngOnDestroy = () => {
    this._subscriptions.unsubscribe();
  };

  showVoicemail = (voicemailRefs: VoicemailRef[]): boolean => this.selfServeUiContext.userHasVoicemail(voicemailRefs);

  showExtensionMobility = (extensionMobilityRefs: ExtensionMobilityRef[]): boolean =>
    this.selfServeUiContext.userHasExtensionMobility(extensionMobilityRefs);

  showSnr = (snrContainers: SnrContainer[]): boolean =>
    this.selfServeUiContext.userHasSnrDestinations(
      snrContainers.map((snrContainer: SnrContainer) => snrContainer.snrDestination)
    );

  showCallForward = (dnContainers: DnContainer[]): boolean =>
    this.selfServeUiContext.userHasCallForward(
      dnContainers.map((dnContainer: DnContainer) => dnContainer.directoryNumberRef)
    );

  showEditDeskphone = (phoneContainers: PhoneContainer[]): boolean =>
    this.selfServeUiContext.userHasDeskphone(
      phoneContainers.map((phoneContainer: PhoneContainer) => phoneContainer.phoneRef)
    );

  anyEndUserHasConfigurableServices = (): boolean => {
    if (!this.selfServeUiContext.selfServe360View) {
      return false;
    }

    return this.selfServeUiContext.hasSelfServeServices(this.selfServeUiContext.selfServe360View);
  };
}
