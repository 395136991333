import {
  CallingType,
  DialPlanManagementGroup,
  DnDidRange,
  RangeType,
  SiteSummary,
} from '../../../../shared/models/generated/smacsModels';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../smacs-form-abstract.directive';
import {
  ExtensionRangesOptionalValidators,
  SmacsExtensionRangesComponentConfig,
} from '../smacs-extension-range-models';
import { of } from 'rxjs';
import { SmacsExtensionRangesComponent } from '../smacs-extension-ranges.component';
import { SmacsFormConfig } from '../../../smacs-forms-models';

export interface RangeDisplayFormModel {
  ranges: DnDidRange[];
}

/**
 * Used by forms that have bulk generate, this will allow us to show/hide the field with an ngIf allowing us to destroy
 * and create a new one after bulk range generate. If we don't it doesn't play well with virtual scroll and our form
 * framework validation
 */
@Component({
  selector: 'ziro-extension-ranges-display-form',
  templateUrl: './extension-ranges-display-form.component.html',
  providers: [],
})
export class ExtensionRangesDisplayFormComponent
  extends SmacsFormAbstractDirective<RangeDisplayFormModel>
  implements OnInit
{
  @Input() clusterId: number;
  @Input() groupId: number;
  @Input() dialPlanGroups: DialPlanManagementGroup[];
  @Input() siteSummary: SiteSummary;
  @Input() isRequired = true;
  @Input() isZpm?: boolean;
  @Input() isDisabled = false;
  @Input() zpmCallingType: CallingType;
  @Input() exceptionRangeType: RangeType;

  @Input()
  get optionalValidators(): ExtensionRangesOptionalValidators {
    return this._optionalValidators;
  }
  set optionalValidators(optionalValidators: ExtensionRangesOptionalValidators) {
    this._optionalValidators = optionalValidators;
    if (this.optionalValidators && this.fieldComponents) {
      const field = this.fieldComponents.find((field) => field.fieldId === 'ranges');
      field.applyComponentConfig(
        new SmacsExtensionRangesComponentConfig({
          optionalValidators: this.optionalValidators,
          siteSummary: this.siteSummary ?? null,
        })
      );
    }
  }

  @ViewChild(SmacsExtensionRangesComponent) extensionRanges: SmacsExtensionRangesComponent;

  formConfig: SmacsFormConfig;

  private _optionalValidators: ExtensionRangesOptionalValidators;

  ngOnInit() {
    this.formConfig = {
      fields: {
        ranges: {
          dataAutomation: 'extension-ranges-display-form-range',
          componentConfig: new SmacsExtensionRangesComponentConfig({
            optionalValidators: this.optionalValidators,
            siteSummary: this.siteSummary ?? null,
          }),
          required: () => {
            if (!this.isRequired) {
              return false;
            }

            return !(
              this.formData &&
              this.formData.ranges &&
              this.formData.ranges.length &&
              this.formData.ranges[0] &&
              this.formData.ranges[0].start &&
              this.formData.ranges[0].end
            );
          },
          disabled: () => this.isDisabled,
        },
      },
    };
  }

  submit() {
    return of(null);
  }
}
