<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;reporting.microsoft.custom_powershell_reports.form.about' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <app-loading-block [hidden]="!isLoading"></app-loading-block>
    <div>
      <legend>{{ 'tkey;reporting.microsoft.custom_powershell_reports.header' | translate }}</legend>
      <smacs-text fieldId="reportName"></smacs-text>
      <smacs-code-area fieldId="pwshQuery"></smacs-code-area>
      <div class="row">
        <div class="col-lg-9 offset-lg-3">
          <span>{{ 'tkey;reporting.microsoft.custom_powershell_reports.form.pwsh_query.helptext_1' | translate }}</span>
          <button
            type="button"
            class="btn btn-link ps-1 pe-1 test-query-link text-decoration-none"
            data-automation="powershell-report-test-query-link-button"
            [disabled]="isQueryRunning"
            (click)="onTestQueryClick()"
          >
            {{ 'tkey;reporting.microsoft.custom_powershell_reports.form.pwsh_query.helptext_2' | translate }}
          </button>
          <span>{{ 'tkey;reporting.microsoft.custom_powershell_reports.form.pwsh_query.helptext_3' | translate }}</span>
        </div>
      </div>
    </div>

    <legend
      *ngIf="hasQueryRun || isQueryRunning || queryResults.length > 0"
      id="custom-powershell-report-results-header"
    >
      {{ 'tkey;reporting.microsoft.custom_powershell_reports.form.table.title' | translate }}
    </legend>

    <app-loading-block [hidden]="!isQueryRunning"></app-loading-block>

    <ng-container *ngIf="!isQueryRunning && hasQueryRun">
      <smacs-datatable
        [dataAutomation]="tableDataAutomation"
        [minTableHeight]="'20rem'"
        [noResultsAlert]="'tkey;reporting.microsoft.custom_powershell_reports.form.table.no_results'"
        [columns]="columns"
        [data]="tableData"
      ></smacs-datatable>
    </ng-container>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
