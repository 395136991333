<ziro-teams-call-queues-form [upn]="upn"></ziro-teams-call-queues-form>

<div class="row pb-5">
  <div class="col-md-12 order-2 order-md-1">
    <smacs-fieldset-header [legend]="'tkey;shared.model.microsoft_teams_calling.settings.text'">
      <smacs-select [fieldId]="'callingPolicy'"></smacs-select>
      <smacs-select [fieldId]="'teamsCallHoldPolicy'"></smacs-select>
      <smacs-select [fieldId]="'teamsCallParkPolicy'"></smacs-select>
      <smacs-select [fieldId]="'callingLineIdentity'"></smacs-select>
      <smacs-select [fieldId]="'tenantDialPlan'"></smacs-select>
      <smacs-select [fieldId]="'emergencyCallingPolicy'"></smacs-select>
      <smacs-select [fieldId]="'emergencyCallRoutingPolicy'"></smacs-select>
      <smacs-select [fieldId]="'onlineVoiceRoutingPolicy'"></smacs-select>
      <smacs-select [fieldId]="'onlineVoicemailPolicy'"></smacs-select>
      <smacs-select [fieldId]="'ipPhonePolicy'"></smacs-select>
      <smacs-select [fieldId]="'emergencyLocation'"></smacs-select>
      <smacs-text [fieldId]="'lineUriExtension'"></smacs-text>
    </smacs-fieldset-header>
  </div>
  <div class="col-md-12 order-2 order-md-1" *ngIf="!!bandwidthFieldConfig">
    <smacs-bandwidth-emergency-calling-form
      [bandwidthFieldConfig]="bandwidthFieldConfig"
      [bandwidthEntity]="bandwidthEntity"
      [teamsLine]="teamsLine"
    ></smacs-bandwidth-emergency-calling-form>
  </div>
  <div
    class="col-md-12 order-2 order-md-1"
    *ngIf="isAdWriteDownConfigured && !!onPremUpn"
    [attr.data-automation]="'helpdesk-ad-attributes'"
  >
    <ziro-helpdesk-ad-attributes
      [isZpcSyncWarningPresent]="isZpcSyncWarningPresent"
      [onPremUpn]="onPremUpn"
      [selectedNumber]="lineUri"
      [mainNumber]="mainNumber"
      [lineUriExtensionForDedicatedDid]="lineUriExtensionForDedicatedDid"
      [dialPlanId]="dialPlanFieldConfig.id"
      (adItemWasChanged)="handleAttributeUpdate()"
    ></ziro-helpdesk-ad-attributes>
  </div>
</div>
