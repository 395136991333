import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TopNavComponent } from './top-nav.component';
import { SystemStatusAlertComponent } from '../system-status-alert/system-status-alert.component';
import { RouterLink } from '@angular/router';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { FormsModule } from '@angular/forms';
import { LogoutComponent } from '../logout/logout.component';
import { ButtonModule } from '../../button/button.module';

@NgModule({
  declarations: [TopNavComponent, SystemStatusAlertComponent, LogoutComponent],
  exports: [TopNavComponent, SystemStatusAlertComponent, LogoutComponent],
  imports: [
    CommonModule,
    RouterLink,
    NgbTooltip,
    TranslateModule,
    AngularSvgIconModule,
    FormsModule,
    NgbDropdownToggle,
    NgbDropdown,
    NgbDropdownMenu,
    ButtonModule,
  ],
  providers: [NgbDropdown],
})
export class TopNavModule {}
