<div class="container-fluid pb-5 adjusted-height">
  <legend data-automation="teams-calling-header-legend">
    {{ 'tkey;shared.model.ms_teams_audio_conferencing.form.header' | translate }}
  </legend>
  <div class="row h-100">
    <div class="col-md-7 overflow-auto">
      <smacs-select [fieldId]="'audioConferenceNumber'"></smacs-select>
    </div>
    <div class="col-md-5 teams-calling-logo">
      <img src="/static/img/microsoft_teams_logo.png" />
    </div>
  </div>
</div>
