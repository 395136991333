<div *ngIf="directoryNumberRef?.extension">
  <a href="{{ extensionLink() }}">
    <span id="extension-info" data-automation="extension-info">
      {{ directoryNumberRef?.extension | telephoneNumberFilter }}
    </span>
  </a>
  <span *ngIf="didLink">
    <span class="text-info px-1">|</span>
    <a id="did-info" data-automation="did-info" href="{{ didLink }}">
      {{ did | telephoneNumberFilter }}
    </a>
  </span>
  <br />
</div>
