import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../../forms/smacs-form-abstract.directive';
import { Cluster, ClusterResult, Server } from '../../../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../../../forms/smacs-forms-models';
import { Observable, Subscription, switchMap } from 'rxjs';
import { HtmlInputType, SmacsTextConfig } from '../../../../../../forms/fields/text/smacs-text.component';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../../../forms/fields/select/smacs-select.component';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../../../../button/button.component';
import { SmacsIcons } from '../../../../../../shared/models/smacs-icons.enum';
import { ClustersResource } from '../../../services/clusters.resource';
import { catchError, delayWhen, map, tap } from 'rxjs/operators';
import { SmacsFormStateService } from '../../../../../../forms/smacs-form-state.service';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { ToastTypes } from '../../../../../../shared/services/abstract/toast.service.abstract';
import { SiteSummaryContext } from '../../../../../../shared/contexts/site-summary.context';
import { ActivatedRoute, Router } from '@angular/router';
import { ZiroModalPromptModalOptions } from '../../../../../../modals/prompt-modal/prompt-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../../../../shared/services/smacs-modal.service';
import { BottomNavButton } from '../../../../../../shared/bottom-nav/bottom-nav.component';

interface ClusterServerFieldData {
  value: number;
  label: string;
}

interface ClusterFormData {
  name: string;
  cucmServer: ClusterServerFieldData;
  impServer: ClusterServerFieldData;
  pcceServer: ClusterServerFieldData;
}

@Component({
  selector: 'smacs-cluster-form',
  templateUrl: 'cluster-form.component.html',
})
export class ClusterFormComponent
  extends SmacsFormAbstractDirective<Cluster, ClusterFormData>
  implements OnInit, OnDestroy
{
  @Input() serverOptions: Server[];
  @Input() allClusters: ClusterResult[];

  protected formConfig: SmacsFormConfig;

  private _duplicateClusterName: string;
  private _subscription = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private smacsModalService: SmacsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private bottomNavService: BottomNavService,
    private clustersResource: ClustersResource,
    private siteSummaryContext: SiteSummaryContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initFormConfig();
    this._initBottomNav();

    const formUpdateSub = this.smacsFormsUpdate$.subscribe((update) => {
      if (this.isFormSubmitted) {
        this.bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: update.valid === SmacsFormsValidationState.INVALID,
          })
        );
      }
    });
    this._subscription.add(formUpdateSub);

    const submitSub = this._validateAndSubmitSource.subscribe(() => {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });
    this._subscription.add(submitSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  protected submit(): Observable<any> {
    return this._saveCluster();
  }

  private _initFormConfig() {
    const cucmServerOptions = this._filterServersAndMapToOptions('CUCM');
    const impServerOptions = this._filterServersAndMapToOptions('IM & Presence');
    const pcceServerOptions = this._filterServersAndMapToOptions('PCCE');

    this.formConfig = {
      fields: {
        name: {
          label: 'tkey;admin.site_management.cluster.name.label',
          dataAutomation: 'cluster-name',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: true,
          validation: [
            {
              validator: (val: string) => {
                const duplicateCluster = this.allClusters.find(
                  (otherCluster) => otherCluster.id !== this.entity.id && otherCluster.name.trim() === val.trim()
                );
                if (duplicateCluster) {
                  this._duplicateClusterName = duplicateCluster.name;
                  return SmacsFormsValidationState.INVALID;
                }
                return SmacsFormsValidationState.VALID;
              },
              message: () => {
                return {
                  content: 'tkey;admin.site_management.cluster.already_exists.text',
                  params: { name: this._duplicateClusterName },
                };
              },
            },
          ],
        },
        cucmServer: {
          label: 'tkey;admin.site_management.cluster.cucm_server.label',
          dataAutomation: 'cluster-cucm-server',
          componentConfig: new SmacsSelectConfig({ options: cucmServerOptions }),
          required: true,
          disabled: () => !!this.entity.id,
          disabledTooltip: {
            content: 'tkey;admin.site_management.cluster.server_cannot_be_modified.tooltip',
            params: { serverType: 'CUCM' },
          },
        },
        impServer: {
          label: 'tkey;admin.site_management.cluster.imp_server.label',
          dataAutomation: 'cluster-imp-server',
          componentConfig: new SmacsSelectConfig({ options: impServerOptions }),
          hidden: () => !impServerOptions.length,
          disabled: () => !!this.entity.id,
          disabledTooltip: {
            content: 'tkey;admin.site_management.cluster.server_cannot_be_modified.tooltip',
            params: { serverType: 'IM&P' },
          },
        },
        pcceServer: {
          label: 'tkey;admin.site_management.cluster.pcce_server.label',
          dataAutomation: 'cluster-pcce-server',
          componentConfig: new SmacsSelectConfig({ options: pcceServerOptions }),
          hidden: () => !pcceServerOptions.length,
        },
      },
    };
  }

  private _filterServersAndMapToOptions(serverType: 'CUCM' | 'IM & Presence' | 'PCCE'): SmacsSelectOption[] {
    return this.serverOptions
      .filter((server) => server.serverType === serverType)
      .map((server) => {
        return {
          label: server.description,
          value: server.id,
        };
      });
  }

  private _initBottomNav() {
    const buttons: BottomNavButton[] = [];
    buttons.push({
      id: 'cancel-cluster',
      label: 'tkey;global.button.cancel.text',
      dataAutomation: 'cluster-cancel-button',
      buttonClass: ButtonStyles.DEFAULT,
      cb: () => {
        this.router.navigate(['..'], { relativeTo: this.route });
      },
    });

    if (this.entity.id) {
      buttons.push({
        id: 'delete-cluster',
        label: 'tkey;global.button.delete.text',
        dataAutomation: 'cluster-delete-button',
        buttonClass: ButtonStyles.DANGER,
        cb: () => {
          this._openDeleteClusterModal(this.entity);
        },
      });
    }

    buttons.push({
      id: 'save-cluster',
      label: 'tkey;global.button.save.text',
      icon: SmacsIcons.OK,
      submitSubject: this._validateAndSubmitSource,
      type: ButtonTypes.SUBMIT,
      dataAutomation: 'cluster-save-button',
      buttonClass: ButtonStyles.PRIMARY,
    });

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttons));
  }

  private _saveCluster(): Observable<void> {
    this._setButtonsPending(true);
    const saveObs = this.entity.id ? this._putCluster(this.entity) : this._postCluster(this.entity);
    return saveObs.pipe(
      delayWhen(() => this.siteSummaryContext.refreshSiteSummary()),
      map((clusterId) => {
        this._setButtonsPending(false);
        this.toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.CLUSTER,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.site_management.clusters.toast.message',
          { clusterName: this.entity.name }
        );
        const routerCmd = clusterId ? ['..', clusterId, 'sites'] : ['..'];
        this.router.navigate(routerCmd, { relativeTo: this.route });
      }),
      catchError((err) => {
        this._setButtonsPending(false);
        throw err;
      })
    );
  }

  private _putCluster(cluster: Cluster): Observable<number> {
    return this.clustersResource.put(cluster).pipe(map(() => null));
  }

  private _postCluster(cluster: Cluster): Observable<number> {
    return this.clustersResource.post(cluster);
  }

  private _openDeleteClusterModal(cluster: Cluster) {
    const options: ZiroModalPromptModalOptions = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        promptBody: this.translateService.instant(
          'tkey;admin.site_management.cluster_sites.cluster_confirmation_delete.text',
          {
            cluster: cluster,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'prompt-modal-cancel',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'prompt-modal-confirm',
            cb: () =>
              this.clustersResource.delete(cluster.id).pipe(
                switchMap(() => this.siteSummaryContext.refreshSiteSummary()),
                tap(() => {
                  this.toastService.push(
                    ToastTypes.INFO,
                    SmacsIcons.DELETE,
                    'tkey;admin.clusters.delete.toast.title',
                    'tkey;admin.clusters.delete.toast.message',
                    { clusterName: cluster.name },
                    null,
                    'text-danger'
                  );
                  this.router.navigate(['..'], { relativeTo: this.route });
                })
              ),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _setButtonsPending(isPending: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'cancel-cluster',
        state: {
          buttonDisableState: {
            disabled: isPending,
          },
        },
      })
    );
    if (this.entity.id) {
      this.bottomNavService.dispatch(
        new BottomNavUpdateButtonState({
          id: 'delete-cluster',
          state: {
            buttonDisableState: {
              disabled: isPending,
            },
          },
        })
      );
    }
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'save-cluster',
        state: {
          pending: isPending,
        },
      })
    );
  }

  protected toEntity = (formData: ClusterFormData): Cluster => {
    return {
      id: this.entity.id,
      name: formData.name?.trim(),
      cucmServerId: formData.cucmServer?.value,
      cucmServerDescription: formData.cucmServer?.label,
      impServerId: formData.impServer?.value,
      impServerDescription: formData.impServer?.label,
      pcceServerId: formData.pcceServer?.value,
      pcceServerDescription: formData.pcceServer?.label,
    };
  };

  protected toFormData = (entity: Cluster): ClusterFormData => {
    return {
      name: entity.name,
      cucmServer: entity.cucmServerId ? { value: entity.cucmServerId, label: entity.cucmServerDescription } : null,
      impServer: entity.impServerId ? { value: entity.impServerId, label: entity.impServerDescription } : null,
      pcceServer: entity.pcceServerId ? { value: entity.pcceServerId, label: entity.pcceServerDescription } : null,
    };
  };
}
