import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
  AccountType,
  AssignedMicrosoft365License,
  Microsoft360View,
  Microsoft365License,
  Microsoft365LicenseCount,
  Microsoft365LicensesFieldConfig,
  Microsoft365UserLicenses,
  MicrosoftLicenseInheritedFromGroup,
  MicrosoftSecurityGroupOption,
  MicrosoftSecurityGroupsFieldConfig,
} from '../../../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import {
  MultiCheckboxOptionConfig,
  SmacsMultiCheckboxComponent,
  SmacsMultiCheckboxConfig,
} from '../../../../forms/fields/multi-checkbox/smacs-multi-checkbox.component';
import { SmacsFormConfig, SmacsFormsUpdate } from '../../../../forms/smacs-forms-models';
import { EMPTY, Observable, Subscriber, Subscription, throwError } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { cloneDeep, isEqual } from 'lodash';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { Microsoft365LicensesContext } from '../../../../shared/contexts/microsoft-365-licenses.context';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../shared/services/toast.service';
import { catchError, tap } from 'rxjs/operators';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { Microsoft360ViewContext } from '../../../../shared/contexts/microsoft-360-view.context';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';

export enum ServicePlan {
  MCOEV = 'MCOEV',
}

export interface Microsoft365UserLicensesPayload {
  usageLocation: string;
  licenses: {
    [key: string]: boolean;
  };
}

@Component({
  selector: 'smacs-microsoft365-info-licenses',
  templateUrl: './microsoft365-info-licenses.component.html',
  styleUrls: ['./microsoft365-info-licenses.component.scss'],
})
export class Microsoft365InfoLicensesComponent
  extends SmacsFormAbstractDirective<Microsoft365UserLicensesPayload>
  implements OnInit, OnDestroy
{
  @Input() microsoft365UserLicenses: Microsoft365UserLicenses;
  @Input() licenseConfig: Microsoft365LicensesFieldConfig;
  @Input() securityGroupFieldConfig: MicrosoftSecurityGroupsFieldConfig;
  @Input() initialData: Microsoft365UserLicensesPayload;
  @Input() userSecurityGroups: MicrosoftSecurityGroupOption[];
  @Input() microsoft360View: Microsoft360View;

  @ViewChild('licensesCheckboxes') licensesCheckboxes: SmacsMultiCheckboxComponent<Microsoft365UserLicensesPayload>;

  formConfig: SmacsFormConfig;
  icons = SmacsIcons;
  updateUserLicenses: Microsoft365UserLicenses;
  licenses: Microsoft365LicenseCount[];

  private _subscriptions = new Subscription();
  private _availableLicenses: Microsoft365LicenseCount[];
  private _licenseLabels: MultiCheckboxOptionConfig[];

  constructor(
    private microsoft360ViewContext: Microsoft360ViewContext,
    private translateService: TranslateService,
    private bottomNavService: BottomNavService,
    private microsoft365UserContext: Microsoft365LicensesContext,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._getLicenses();
    this._setCheckboxes();
    const formUpdate = this.smacsFormsUpdate$.subscribe(this._updateInventoryOnChecked);
    this._subscriptions.add(formUpdate);

    this._initBottomNav();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  protected submit() {
    return this._onSaveClick();
  }

  private _initBottomNav = () => {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'microsoft-365-cancel',
          dataAutomation: 'microsoft-365-cancel',
          label: 'tkey;global.button.cancel.text',
          buttonClass: ButtonStyles.DEFAULT,
          cb: () => {
            this.router.navigate(['../'], { relativeTo: this.route });
          },
        },
        {
          id: 'microsoft-365-save',
          dataAutomation: 'microsoft-365-save',
          label: 'tkey;global.button.save.text',
          buttonClass: ButtonStyles.PRIMARY,
          state: {
            pending: false,
            buttonDisableState: {
              disabled: false,
              tooltipKey: '',
            },
          },
          icon: SmacsIcons.OK,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );
  };

  private _setPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'microsoft-365-save',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  private _updateUserLicenses = (): Observable<void> => {
    if (isEqual(this.initialData, this.formData)) {
      return new Observable((subscriber: Subscriber<void>) => {
        subscriber.next();
        subscriber.complete();
      });
    } else {
      const licensesInUiSkuIds = this._availableLicenses.map(
        (license: Microsoft365LicenseCount) => license.microsoft365LicenseJson.skuId
      );
      const provisionedLicensesNotInUi: AssignedMicrosoft365License[] = this.microsoft365UserLicenses.licenses.filter(
        (license: AssignedMicrosoft365License) => !licensesInUiSkuIds.includes(license.skuId)
      );
      const newLicensesForUser: AssignedMicrosoft365License[] = this.licenses
        .map((licenseInventory: Microsoft365LicenseCount) => licenseInventory.microsoft365LicenseJson)
        .filter(
          (licenseJson: Microsoft365License) =>
            this.formData.licenses[licenseJson.productName || licenseJson.skuPartNumber]
        )
        .map((license) => {
          const existingLicense = this.microsoft365UserLicenses.licenses.find(
            (existingLicense) => existingLicense.productName === license.productName
          );
          return {
            ...license,
            directAssigned: existingLicense?.directAssigned ?? true,
            inheritedFromGroups: existingLicense?.inheritedFromGroups ?? [],
          };
        });
      const newUser = { ...this.microsoft365UserLicenses };
      newUser.licenses = [...newLicensesForUser, ...provisionedLicensesNotInUi];
      newUser.usageLocation = this.formData.usageLocation;
      this.updateUserLicenses = newUser;
      return this.microsoft365UserContext.updateUserLicenses(newUser.userPrincipalName, newUser);
    }
  };

  private _onSaveClick(): Observable<void> {
    this._setPending(true);

    return this._updateUserLicenses().pipe(
      tap(() => {
        this.smacsFormStateService.setIsFormDirty(false);
        const microsoft365UserLicenses = this.updateUserLicenses
          ? this.updateUserLicenses
          : this.microsoft365UserLicenses;
        const refreshSub = this.microsoft360ViewContext
          .refresh(microsoft365UserLicenses.userPrincipalName)
          .subscribe(() => {
            this.router.navigate(['../'], { relativeTo: this.route });
            this.toastService.push(
              ToastTypes.SUCCESS,
              SmacsIcons.MICROSOFT_365,
              'tkey;shared.toast.save.success.title',
              this.translateService.instant('tkey;shared.toast.save.success.message', {
                type: this.translateService.instant('tkey;shared.model.microsoft_365.text'),
                name: this.microsoft365UserLicenses.userPrincipalName,
              })
            );
          });
        this._subscriptions.add(refreshSub);
      }),
      catchError((response) => {
        this._setPending(false);
        if (response.status === 422) {
          this.toastService.push(
            ToastTypes.ERROR,
            `${SmacsIcons.MICROSOFT_365} text-danger`,
            'tkey;selfserve.microsoft365.toast.error.title',
            response.error.description
          );

          return throwError(() => EMPTY);
        } else {
          return throwError(() => response);
        }
      })
    );
  }

  private _updateInventoryOnChecked = (update: SmacsFormsUpdate<Microsoft365UserLicensesPayload>) => {
    if (update.new && update.old) {
      const newLicenses = update.new.licenses;
      const oldLicenses = update.old.licenses;
      const differences = Object.keys(newLicenses).filter((key: string) => newLicenses[key] !== oldLicenses[key]);
      if (differences.length) {
        this._availableLicenses.forEach((license: Microsoft365LicenseCount) => {
          const licenseJson = license.microsoft365LicenseJson;
          if (differences.includes(licenseJson.skuPartNumber) || differences.includes(licenseJson.productName)) {
            if (newLicenses[licenseJson.skuPartNumber] || newLicenses[licenseJson.productName]) {
              license.consumed += 1;
            } else {
              license.consumed -= 1;
            }
          }
        });
        this._updateCheckboxLabels();
        this.licensesCheckboxes.applyComponentConfig(
          new SmacsMultiCheckboxConfig({ multiCheckboxOptionConfig: this._licenseLabels })
        );
      }
    }
  };

  private _updateCheckboxLabels = () => {
    const licenses = this._availableLicenses.map((item: Microsoft365LicenseCount) => {
      const label = item.microsoft365LicenseJson.productName || item.microsoft365LicenseJson.skuPartNumber;
      let description = '';
      if (!item.microsoft365LicenseJson.productName) {
        description += this.translateService.instant('tkey;selfserve.microsoft365.checkbox.notfound');
      }

      if (item.total >= 1000000) {
        description += this.translateService.instant('tkey;selfserve.microsoft365.checkbox.unlimited');
      } else {
        description += this.translateService.instant('tkey;selfserve.microsoft365.checkbox.consumed', {
          consumed: item.consumed,
          total: item.total,
        });
      }

      if (item.consumed === item.total) {
        description += this.translateService.instant('tkey;selfserve.microsoft365.checkbox.unavailable');
      }

      const licenseOnUser = this.microsoft365UserLicenses.licenses.find(
        (license: AssignedMicrosoft365License) => item.microsoft365LicenseJson.skuId === license.skuId
      );
      const initialDataForCheckbox = this.initialData.licenses[label];
      let isDisabled = false;
      let disabledTooltip = '';
      if (licenseOnUser && licenseOnUser.directAssigned && licenseOnUser.inheritedFromGroups.length) {
        let groupNames = '';
        licenseOnUser.inheritedFromGroups.forEach((license: MicrosoftLicenseInheritedFromGroup) => {
          groupNames += `${license.displayName}, `;
        });
        description += this.translateService.instant('tkey;selfserve.microsoft365.checkbox.assignment.both', {
          groups: groupNames.trim().substring(0, groupNames.trim().length - 1),
        });
      } else if (licenseOnUser && licenseOnUser.directAssigned) {
        description += this.translateService.instant(
          'tkey;selfserve.microsoft365.checkbox.assignment.directly_assigned'
        );
      } else if (licenseOnUser && licenseOnUser.inheritedFromGroups.length) {
        let groupNames = '';
        licenseOnUser.inheritedFromGroups.forEach((license: MicrosoftLicenseInheritedFromGroup) => {
          groupNames += `${license.displayName}, `;
        });
        description += this.translateService.instant(
          'tkey;selfserve.microsoft365.checkbox.assignment.inherited_from_group',
          { groups: groupNames.trim().substring(0, groupNames.trim().length - 1) }
        );
        isDisabled = true;
        disabledTooltip = this.translateService.instant('tkey;selfserve.microsoft365.checkbox.inherited.tooltip');
      }

      if (this.microsoft360View.accountType === AccountType.RESOURCE_ACCOUNT) {
        const isResourceAccountPhoneSystemLicense = item.microsoft365LicenseJson.associatedServicePlans.some(
          (servicePlan) => servicePlan.startsWith('MCOEV_VIRTUALUSER')
        );
        if (initialDataForCheckbox && isResourceAccountPhoneSystemLicense) {
          isDisabled = true;
          disabledTooltip = this.translateService.instant(
            'tkey;selfserve.microsoft365.resource_account_resource_license.tooltip'
          );
        } else if (!initialDataForCheckbox && isResourceAccountPhoneSystemLicense) {
          isDisabled = false;
          disabledTooltip = '';
        } else {
          isDisabled = !(
            !initialDataForCheckbox &&
            !isResourceAccountPhoneSystemLicense &&
            (item.microsoft365LicenseJson.skuPartNumber.startsWith('MCOPSTN') ||
              item.microsoft365LicenseJson.skuPartNumber.startsWith('Microsoft_Teams_Calling_Plan'))
          );
          disabledTooltip = this.translateService.instant(
            'tkey;selfserve.microsoft365.resource_account_resource_license.not_eligible.tooltip'
          );
        }
      } else {
        const entity = this.entity || this.initialData;
        const hasAssociatedLicense = this._hasAssociatedMcoevLicenses(entity);
        if (
          this.microsoft360View.teamsPhoneNumberAssignment?.lineUri &&
          (item.microsoft365LicenseJson.skuPartNumber.startsWith('MCOPSTN') ||
            item.microsoft365LicenseJson.skuPartNumber.startsWith('Microsoft_Teams_Calling_Plan'))
        ) {
          isDisabled = true;
          disabledTooltip = initialDataForCheckbox
            ? 'tkey;microsoft_360.view.license.disabled.teams_calling_number_removed'
            : 'tkey;microsoft_360.view.license.disabled.teams_calling_number_added';
        } else if (
          item.microsoft365LicenseJson.associatedServicePlans.some((servicePlan) =>
            servicePlan.startsWith('MCOPSTN')
          ) &&
          item.microsoft365LicenseJson.skuPartNumber !== 'MCOPSTNC' &&
          !hasAssociatedLicense
        ) {
          isDisabled = true;
          disabledTooltip = 'tkey;selfserve.microsoft365.checkbox.coflict.teams_phone_standard.tooltip';
        }
      }

      return {
        label,
        additionalContent: description,
        alignLeft: true,
        disabled: () => isDisabled || this._isDisabled(item),
        disabledTooltip: () => disabledTooltip || this._getDisabledCheckboxTooltip(item),
      };
    });

    this._licenseLabels = licenses;

    this.formConfig = {
      fields: {
        usageLocation: {
          label: 'tkey;selfserve.microsoft365.usage.location.label',
          dataAutomation: 'microsoft365-license-usage-locations-select',
          componentConfig: new SmacsSelectConfig({ options: this.licenseConfig.usageLocation.possibleOptions }),
          required: this.licenseConfig.usageLocation.required,
          defaultValue: () => this.licenseConfig.usageLocation.defaultValue,
          hidden: () => !this.licenseConfig.usageLocation.show,
          disabledTooltip: 'tkey;selfserve.microsoft365.usage.location.dropdown.disabled.read_only',
        },
        licenses: {
          dataAutomation: 'microsoft365-license-checkbox',
          componentConfig: new SmacsMultiCheckboxConfig({ multiCheckboxOptionConfig: licenses }),
        },
      },
      options: {
        columnClasses: {
          label: 'col-lg-3',
          input: 'col-lg-9',
        },
      },
    };
  };

  private _hasAssociatedMcoevLicenses(entity: Microsoft365UserLicensesPayload) {
    let hasAssociatedLicense = false;

    Object.keys(entity.licenses).forEach((licenseName: string) => {
      if (entity.licenses[licenseName]) {
        // get
        const licenseJson: Microsoft365License = this.licenseConfig.writeLicenseList.find(
          (writeLicense: Microsoft365LicenseCount) => {
            return writeLicense.microsoft365LicenseJson.productName === licenseName;
          }
        )?.microsoft365LicenseJson;

        if (licenseJson?.associatedServicePlans.includes('MCOEV')) {
          hasAssociatedLicense = true;
        }
      }
    });

    if (!hasAssociatedLicense) {
      const formSkuIds: string[] = this.licenseConfig.writeLicenseList.map(
        (writeLicense: Microsoft365LicenseCount) => writeLicense.microsoft365LicenseJson.skuId
      );
      const hiddenProvisionedLicenses: AssignedMicrosoft365License[] =
        this.microsoft360View.microsoft365UserLicenses.licenses.filter(
          (license: AssignedMicrosoft365License) => !formSkuIds.includes(license.skuId)
        );

      hasAssociatedLicense = hiddenProvisionedLicenses.some((license: AssignedMicrosoft365License) =>
        license.associatedServicePlans.includes('MCOEV')
      );
    }

    return hasAssociatedLicense;
  }

  private _getLicenses() {
    this.licenses = this.licenseConfig.writeLicenseList.concat(this.licenseConfig.readOnlyLicenseList);
  }

  private _setCheckboxes = () => {
    this._availableLicenses = cloneDeep(this.licenses);
    this._updateCheckboxLabels();
    const licensesSource: { [key: string]: boolean } = {};

    this._licenseLabels.forEach((license: MultiCheckboxOptionConfig) => {
      licensesSource[license.label] = this.microsoft365UserLicenses.licenses.some(
        (userLicense: Microsoft365License) => {
          return userLicense.productName === license.label || userLicense.skuPartNumber === license.label;
        }
      );
    });

    this.entitySource.next({
      usageLocation: this.initialData.usageLocation,
      licenses: { ...licensesSource },
    });
  };

  private _hasServicePlan(label: string) {
    const licenseJson = this._availableLicenses.find(
      (ms365License) => ms365License.microsoft365LicenseJson.productName === label
    );
    return licenseJson?.microsoft365LicenseJson?.associatedServicePlans.some(
      (servicePlan) => servicePlan.startsWith(ServicePlan.MCOEV) && !servicePlan.includes('MCOEV_VIRTUALUSER')
    );
  }

  private _getDisabledCheckboxTooltip(item: Microsoft365LicenseCount): string {
    if (this._isReadOnlyLicense(item)) {
      return 'tkey;microsoft_360.view.license.read.only.disabled.tooltip';
    }

    const itemJson = item.microsoft365LicenseJson;
    const label = item.microsoft365LicenseJson.productName || item.microsoft365LicenseJson.skuPartNumber;

    return this.formData &&
      this.formData.licenses &&
      this._hasServicePlan(label) &&
      this._hasLineUri() &&
      this._isLicenseSelected(itemJson)
      ? 'tkey;selfserve.microsoft365.checkbox.disabled.microsoft_teams.active'
      : '';
  }

  private _isReadOnlyLicense(license: Microsoft365LicenseCount): boolean {
    return this.licenseConfig.readOnlyLicenseList.some(
      (readOnlyLicense: Microsoft365LicenseCount) =>
        readOnlyLicense.microsoft365LicenseJson.skuId === license.microsoft365LicenseJson.skuId
    );
  }

  private _hasLineUri(): boolean {
    const microsoftTeamsLine = this.microsoft360View.teamsPhoneNumberAssignment;
    return !!microsoftTeamsLine && !!microsoftTeamsLine && microsoftTeamsLine.lineUri !== '';
  }

  /**
   * Disabled when:
   *   We're logged in as a non-admin user
   *   All licenses are consumed && it is not currently selected
   *   Associated service plans contains "MCOEV" && "Line URI" is set && license is currently selected
   */
  private _isDisabled(item: Microsoft365LicenseCount): boolean {
    const itemJson = item.microsoft365LicenseJson;
    const label = item.microsoft365LicenseJson.productName || item.microsoft365LicenseJson.skuPartNumber;

    return (
      this._isReadOnlyLicense(item) ||
      (item.consumed >= item.total &&
        this.microsoft365UserLicenses.licenses.every((license: Microsoft365License) => {
          return license.skuId !== item.microsoft365LicenseJson.skuId;
        }) &&
        !this._isLicenseSelected(itemJson)) ||
      (this._hasServicePlan(label) && this._hasLineUri() && this._isLicenseSelected(itemJson))
    );
  }

  private _isLicenseSelected(itemJson: Microsoft365License): boolean {
    return this.formData.licenses[itemJson.skuPartNumber] || this.formData.licenses[itemJson.productName];
  }
}
