<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div [hidden]="isLoading" class="col-md-10 offset-md-1">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.backup.description' | translate"></div>
        <p>
          <span
            *ngIf="!isHostedEnabled"
            class="font-light"
            [innerHtml]="'tkey;admin.backup.body.description.text' | translate"
          ></span>
          <span
            *ngIf="isHostedEnabled"
            class="font-light"
            [innerHtml]="'tkey;admin.backup.hostedEnabled.body.description.text' | translate"
          ></span>
        </p>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <div class="bottom-buffer top-buffer">
      <h4>
        <span
          *ngIf="!isHostedEnabled"
          class="font-light"
          [innerHtml]="'tkey;admin.backup.daily.description.text' | translate"
        ></span>
        <span
          *ngIf="isHostedEnabled"
          class="font-light"
          [innerHtml]="'tkey;admin.backup.hostedEnabled.daily.description.text' | translate"
        ></span>
      </h4>
      <ul>
        <li *ngFor="let fileName of dailyBackupFileNames" data-automation="admin-backup-daily-result-row">
          <a [href]="'/services/system/admin/backups/daily/' + fileName">
            <i [attr.class]="smacsIcons.DOWNLOAD"></i>
            {{ fileName }}
          </a>
        </li>
      </ul>
    </div>

    <hr />

    <div class="bottom-buffer">
      <h4>
        <span
          *ngIf="!isHostedEnabled"
          class="font-light"
          [innerHtml]="'tkey;admin.backup.weekly.description.text' | translate"
        ></span>
        <span
          *ngIf="isHostedEnabled"
          class="font-light"
          [innerHtml]="'tkey;admin.backup.hostedEnabled.weekly.description.text' | translate"
        ></span>
      </h4>
      <ul>
        <li *ngFor="let fileName of weeklyBackupFileNames" data-automation="admin-backup-weekly-result-row">
          <a [href]="'/services/system/admin/backups/weekly/' + fileName">
            <i [attr.class]="smacsIcons.DOWNLOAD"></i>
            {{ fileName }}
          </a>
        </li>
      </ul>
    </div>

    <hr />

    <legend>{{ 'tkey;admin.backup.example.header' | translate }}</legend>
    <p [innerHtml]="'tkey;admin.backup.current.description' | translate"></p>
    <pre>curl -OJs -u $user:$password <a id="current-backup" [href]="'/services/system/admin/backups/current'"
                                         data-automation="admin-backup-current-backup-link">{{currentBackupUrl}}</a></pre>
  </div>
</div>
