import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PhoneUiContext } from '../../../../shared/phone-buttons/contexts/phone-ui.context';
import { Subscription } from 'rxjs';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { HelpdeskOptionsContext } from '../../../shared/contexts/helpdesk-options.context';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'smacs-public-phone-operations-menu',
  templateUrl: './public-phone-operations-menu.component.html',
  styleUrls: ['./public-phone-operations-menu.component.scss'],
})
export class PublicPhoneOperationsMenuComponent implements OnInit, OnDestroy {
  @Input() phoneId: string;
  @Input() isCopyPhoneButtonEnabled = true;
  @Input() isUnsavedCopy = true;

  smacsIcons = SmacsIcons;
  isVoicemailPresentOnLineOne = false;
  isChangeSiteEnabled = false;
  phoneName = '';

  private _subs = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private phoneUiContext: PhoneUiContext,
    private helpdeskOptionsContext: HelpdeskOptionsContext,
    private router: Router,
    public _translateService: TranslateService
  ) {}

  ngOnInit() {
    this.phoneName = this.route.snapshot.params.phoneName;

    const helpdeskOptionsSub = this.helpdeskOptionsContext.state$.subscribe((helpdeskOptions) => {
      this.isChangeSiteEnabled =
        this.helpdeskOptionsContext.isCiscoHelpdeskOptions(helpdeskOptions) && helpdeskOptions.changeSiteEnabled;
    });
    this._subs.add(helpdeskOptionsSub);
    const phoneUiSub = this.phoneUiContext.phoneState$.subscribe((phone) => {
      this.phoneName = phone.name;
      const voicemailPresentSub = this.phoneUiContext.isVoicemailPresentOnLineOneState$.subscribe(
        (isVoicemailPresent) => {
          this.isVoicemailPresentOnLineOne = isVoicemailPresent;
        }
      );
      this._subs.add(voicemailPresentSub);
    });
    this._subs.add(phoneUiSub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  goToCopyPublicPhone() {
    if (this.isCopyPhoneButtonEnabled) {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([`/public-phone/${this.phoneName}/copy`]);
      });
    }
  }
}
