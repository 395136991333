<div id="errorDialog" class="smacs-do-not-dirty-check" data-automation="error-modal">
  <form #oopsDialogForm="ngForm">
    <div class="modal-header">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        (click)="onClose()"
        data-automation="error-modal-dismiss"
      ></button>
    </div>

    <div class="modal-body text-center" data-automation="error-modal-body">
      <div *ngIf="state === ModalState.STACKTRACE">
        <div *ngIf="modalViewProperties.title">
          <h1>
            <i class="fa fa-2x fa-bug"></i>
          </h1>

          <h4 id="oopsMessage">
            {{ modalViewProperties.title | translate }}
          </h4>

          <div ng-if="!canSendReport">
            <strong>{{ 'tkey;global.error.contact_admin' | translate }}</strong>
          </div>

          <code>{{ modalViewProperties.errorSummary }}</code>
          <br *ngIf="modalViewProperties.errorSummary" />

          <button
            type="button"
            id="expand-error-dialog"
            class="btn btn-link"
            [hidden]="!modalViewProperties.stacktrace"
            (click)="modalViewProperties.expanded = !modalViewProperties.expanded"
          >
            <span *ngIf="modalViewProperties.expanded">
              {{ 'tkey;shared.modals.error.hide_details.label' | translate }}
            </span>

            <span *ngIf="!modalViewProperties.expanded">
              {{ 'tkey;shared.modals.error.show_more.label' | translate }}
            </span>
          </button>

          <pre
            id="error-dialog-content"
            [hidden]="!(modalViewProperties.expanded && modalViewProperties.stacktrace)"
            [innerHTML]="modalViewProperties.stacktrace"
          ></pre>
        </div>
      </div>

      <div *ngIf="state === ModalState.SEND_REPORT">
        <h1>
          <i class="fa fa-2x fa-bug"></i>
        </h1>

        <p>{{ 'tkey;shared.modals.error.steps_to_reproduce.label' | translate }}</p>

        <textarea
          id="stepsToReproduce"
          name="stepsToReproduce"
          class="form-control"
          maxlength="900"
          [(ngModel)]="modalViewProperties.stepsToReproduce"
          #stepsToReproduce="ngModel"
        ></textarea>

        <div *ngIf="stepsToReproduce.invalid && (stepsToReproduce.dirty || stepsToReproduce.touched)">
          <div *ngIf="stepsToReproduce.errors.maxlength" class="text-danger">
            {{ 'tkey;modals.oops.steps_to_reproduce.too_long.text' | translate }}
          </div>
        </div>
      </div>

      <div *ngIf="state === ModalState.REPORT_SENT">
        <h1><i class="fa fa-2x fa-bug"></i></h1>

        <p>{{ 'tkey;shared.modals.error.report_sent.text' | translate }}</p>
        <pre id="error-dialog-content" data-automation="full-report">{{ modalViewProperties.stepsToReproduce }}</pre>
      </div>
    </div>

    <div class="modal-footer justify-content-center">
      <div *ngIf="state === ModalState.SEND_REPORT" class="text-end" data-automation="error-modal-footer">
        <smacs-button
          [cssClass]="'mt-4'"
          [isDisabled]="!modalViewProperties.stepsToReproduce || modalViewProperties.stepsToReproduce.length < 5"
          [icon]="smacsIcons.VOICEMAIL"
          [label]="'tkey;shared.modals.error.send_report.button'"
          (clicked)="sendSupportEmail()"
        ></smacs-button>
      </div>

      <div *ngIf="state !== ModalState.SEND_REPORT">
        <smacs-button
          *ngIf="!!currentUser"
          [buttonStyle]="buttonStyles.INFO"
          [cssClass]="'me-1'"
          [dataAutomation]="'go-home-button'"
          [label]="'tkey;shared.modals.error.go_home.button'"
          (clicked)="onHomeClicked()"
        ></smacs-button>

        <smacs-button
          *ngIf="canSendReport && state === ModalState.STACKTRACE"
          [label]="'tkey;shared.modals.error.send_report.button'"
          [id]="'reportError'"
          [icon]="smacsIcons.VOICEMAIL"
          (clicked)="state = ModalState.SEND_REPORT"
        ></smacs-button>
      </div>
    </div>
  </form>
</div>
