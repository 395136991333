import { Component, OnDestroy, OnInit } from '@angular/core';
import { SelfServePinResetAbstractComponent } from '../self-serve-pin-reset.abstract.component';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../shared/services/toast.service';
import { SelfServeUiContext } from '../../contexts/self-serve-ui.context';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { Observable } from 'rxjs';
import { PinResetService } from '../../../shared/services/pin-reset.service';

@Component({
  selector: 'app-self-serve-vm-pin-reset',
  templateUrl: '../self-serve-pin-reset.abstract.component.html',
})
export class SelfServeVmPinResetComponent extends SelfServePinResetAbstractComponent implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;

  constructor(
    private pinResetService: PinResetService,
    private toastService: ToastService,
    selfServeUiContext: SelfServeUiContext,
    route: ActivatedRoute,
    router: Router,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(selfServeUiContext, route, router, smacsFormStateService);

    this.headerTranslationKey = 'tkey;self_serve.voicemail.header';
    this.headingDataAutomation = 'self-serve-user-details-voicemail-header';
  }

  resetPin = (): Observable<void> => {
    return this.pinResetService.resetVoicemailPin(this.voicemailRef, this.entity.pin, false);
  };

  onPinResetSuccess = (...args: any) => {
    this.onPinResetSuccessBase.call(this, args);

    this.toastService.push(
      ToastTypes.SUCCESS,
      this.smacsIcons.REFRESH,
      'tkey;selfserve.user.resetpin.success.title',
      'tkey;selfserve.user.resetpin.voicemail.success.message'
    );
  };
}
