import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { EndUserSearchResource } from '../../shared/resources/end-user-search.resource';
import { SearchPhoneResource } from '../../shared/resources/search-phone.resource';
import { VoicemailSearchResource } from '../../shared/resources/voicemail-search.resource';
import { SearchResource } from '../../shared/resources/search.resource';
import { SearchDirectoryNumberResource } from '../../self-serve/resources/search/search-directory-number.resource';
import { combineLatest, forkJoin, Observable, of, Subscriber, Subscription, throwError } from 'rxjs';
import {
  ClusterResult,
  CurrentUser,
  DevicePoolResult,
  DirectoryNumberResult,
  DistributionListResult,
  EndUserResult,
  ExtensionMobilityResult,
  GlobalProperties,
  Microsoft365UserResult,
  PhoneResult,
  Role,
  SearchFieldConfig,
  SiteResult,
  SiteSummary,
  SnrProfileResult,
  VoicemailResult,
} from '../../shared/models/generated/smacsModels';
import { ButtonStyles } from '../../button/button.component';
import { groupBy, uniq, uniqBy } from 'lodash';
import { KnownPhoneModels, PhoneDescriptions } from '../shared/contexts/user-detail-ui.context';
import { SmacsModalService } from '../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { PhoneResource } from '../../shared/resources/phone.resource';
import { DirectoryNumberResource } from '../../self-serve/resources/directory-number.resource';
import { VoicemailResource } from '../../shared/resources/voicemail.resource';
import { ToastService } from '../../shared/services/toast.service';
import { SnrProfileResource } from '../../self-serve/resources/snr-profile.resource';
import { SearchFieldConfigResource } from '../shared/resources/field-config/search-field-config.resource';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthenticationContext } from '../../shared/contexts/authentication.context';
import { SearchFieldConfigContext } from '../../shared/contexts/search-field-config.context';
import { CurrentClusterContext } from '../../shared/contexts/current-cluster.context';
import { SiteSummaryContext } from '../../shared/contexts/site-summary.context';
import { SearchExtensionMobilityProfilesResource } from '../../self-serve/resources/search/search-extension-mobility-profiles.resource';
import { GlobalPropertiesContext } from '../../shared/contexts/global-properties.context';
import { SvgLoaderContext } from '../../shared/contexts/svg-loader.context';
import { MicrosoftTeamsUserSearchResource } from '../shared/resources/microsoft-teams-user-search.resource';
import { MicrosoftUserUiResult } from './microsoft-search-result/microsoft-search-result.component';
import { ZpmUserPhotoContext } from '../../shared/contexts/zpm-user-photo.context';
import { ServiceTypes } from '../../shared/contexts/global-360-view.context';
import { Nvp } from '../../shared/models/nvp';
import { Microsoft365UserResource } from '../shared/resources/microsoft-365-user.resource';

export interface DevicePoolSites {
  [devicePool: string]: SiteResult[];
}

export interface UiEndUserResult extends EndUserResult {
  isDisabled?: boolean;
  isSyncInProgress?: boolean;
  tooltip?: string;
}

interface EditLinkState {
  isDisabled: boolean;
  disabledTooltip: string;
}

@Component({
  selector: 'smacs-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    EndUserSearchResource,
    SearchPhoneResource,
    VoicemailSearchResource,
    SearchResource,
    SearchDirectoryNumberResource,
    PhoneResource,
    DirectoryNumberResource,
    VoicemailResource,
    SnrProfileResource,
    SearchFieldConfigResource,
    ZpmUserPhotoContext,
  ],
})
export class SmacsSearchComponent implements OnInit, OnDestroy {
  isLoading = true;
  isLoadingLineUri = true;
  smacsIcons = SmacsIcons;
  totalResults: number;
  query: string;
  snrResults: SnrProfileResult[] = [];
  cucmEndUserResults: UiEndUserResult[] = [];
  directoryNumberResults: DirectoryNumberResult[] = [];
  phoneResults: PhoneResult[] = [];
  distributionListResults: DistributionListResult[] = [];
  voicemailResults: VoicemailResult[] = [];
  microsoftEndUserResults: MicrosoftUserUiResult[] = [];
  extensionMobilityProfiles: ExtensionMobilityResult[] = [];
  devicePoolSites: DevicePoolSites = {};

  private _isInitialized = false;
  private _subscriptions = new Subscription();
  private _globalProperties: GlobalProperties;
  private _siteSummary: SiteSummary;

  private static _getPhoneTypeLabel(result: PhoneResult): string {
    switch (result.ref.model) {
      case KnownPhoneModels.ANDROID: {
        return 'tkey;shared.model.android.text';
      }
      case KnownPhoneModels.IPHONE: {
        return 'tkey;shared.model.iphone.text';
      }
      case KnownPhoneModels.CIPC: {
        return 'tkey;shared.model.cipc.text';
      }
      case KnownPhoneModels.TABLET: {
        return 'tkey;shared.model.tablet.text';
      }
      case KnownPhoneModels.IM_SOFTPHONE: {
        return 'tkey;shared.model.imsoftphone.text';
      }
      default: {
        return 'tkey;shared.model.deskphone.text';
      }
    }
  }

  constructor(
    private route: ActivatedRoute,
    private authenticationContext: AuthenticationContext,
    private searchResource: SearchResource,
    private endUserSearchResource: EndUserSearchResource,
    private voicemailSearchResource: VoicemailSearchResource,
    private searchDirectoryNumberResource: SearchDirectoryNumberResource,
    private searchPhoneResource: SearchPhoneResource,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private phoneResource: PhoneResource,
    private directoryNumberResource: DirectoryNumberResource,
    private toastService: ToastService,
    private snrProfileResource: SnrProfileResource,
    private router: Router,
    private searchFieldConfigContext: SearchFieldConfigContext,
    private currentClusterContext: CurrentClusterContext,
    private microsoftTeamsUserSearchResource: MicrosoftTeamsUserSearchResource,
    private microsoft365UserResource: Microsoft365UserResource,
    private siteSummaryContext: SiteSummaryContext,
    private searchExtensionMobilityProfilesResource: SearchExtensionMobilityProfilesResource,
    private globalPropertiesContext: GlobalPropertiesContext,
    private voicemailResource: VoicemailResource,
    private svgLoaderContext: SvgLoaderContext,
    private zpmUserPhotoContext: ZpmUserPhotoContext
  ) {}

  ngOnInit(): void {
    this.query = decodeURI(
      this.route.snapshot.queryParamMap.get('query') ? this.route.snapshot.queryParamMap.get('query').trim() : ''
    );

    const subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isLoadingLineUri = true;
        this.isLoading = true;
        this.query = decodeURI(
          this.route.snapshot.queryParamMap.get('query') ? this.route.snapshot.queryParamMap.get('query').trim() : ''
        );
        this._getSearchConfig();
      }
    });
    this._subscriptions.add(subscription);

    this.svgLoaderContext.init();
    const svgSub = this.svgLoaderContext.state$.subscribe((svgLoaded) => {
      if (!this._isInitialized && svgLoaded) {
        this._getSearchConfig();
        this._isInitialized = true;
      } else if (!svgLoaded) {
        this.svgLoaderContext.loadSvgs().subscribe();
      }
    });
    this._subscriptions.add(svgSub);

    const userPhotoSub = this.zpmUserPhotoContext.state$.subscribe(() => {
      const userPhotos = this.zpmUserPhotoContext.getUserPhotos();
      userPhotos.forEach((photo) => {
        const microsoftUserUiResult = this.microsoftEndUserResults.find(
          (result) => result.ref.id === photo.userPrincipalName
        );
        if (microsoftUserUiResult) {
          microsoftUserUiResult.photo = photo.photoBase64;
        }
      });
    });
    this._subscriptions.add(userPhotoSub);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  getCucmEndUserDescription(result: UiEndUserResult): string {
    return `<strong>${result.ref.username}</strong> | ${result.ref.firstName} ${result.ref.lastName}`;
  }

  usernameHasSpaces(result: UiEndUserResult): boolean {
    return /\s/.test(result.ref.username);
  }

  getCucmEndUserTooltip(result: UiEndUserResult): string {
    return result.tooltip
      ? result.tooltip
      : this.usernameHasSpaces(result)
      ? 'tkey;search.end_user_result.no_spaces_allowed_tooltip'
      : 'tkey;search.add.edit.button';
  }

  getPhoneDescription(result: PhoneResult): string {
    switch (result.ref.model) {
      case KnownPhoneModels.ANDROID:
      case KnownPhoneModels.IPHONE:
      case KnownPhoneModels.TABLET:
      case KnownPhoneModels.CIPC:
      case KnownPhoneModels.IM_SOFTPHONE: {
        return `<strong>${result.ref.name}</strong> | ${result.ref.description}`;
      }
      default: {
        return `${result.ref.model} - <strong>${result.ref.name}</strong> | ${result.ref.description}`;
      }
    }
  }

  getPhoneIcon(result: PhoneResult): SmacsIcons {
    switch (result.ref.model) {
      case KnownPhoneModels.ANDROID: {
        return this.smacsIcons.ANDROID;
      }
      case KnownPhoneModels.IPHONE: {
        return this.smacsIcons.IPHONE;
      }
      case KnownPhoneModels.CIPC: {
        return this.smacsIcons.CIPC;
      }
      case KnownPhoneModels.TABLET: {
        return this.smacsIcons.TABLET_SEARCH;
      }
      case KnownPhoneModels.IM_SOFTPHONE: {
        return this.smacsIcons.SOFTPHONE;
      }
      default: {
        return this.smacsIcons.DESKPHONE;
      }
    }
  }

  getPhoneEditLink(result: PhoneResult): string {
    switch (result.ref.model) {
      case KnownPhoneModels.ANDROID: {
        return `/user/${result.owner?.username}/android/${result.ref.id}`;
      }
      case KnownPhoneModels.IPHONE: {
        return `/user/${result.owner?.username}/iphone/${result.ref.id}`;
      }
      case KnownPhoneModels.CIPC: {
        return `/user/${result.owner?.username}/cipc/${result.ref.id}`;
      }
      case KnownPhoneModels.TABLET: {
        return `/user/${result.owner?.username}/tablet/${result.ref.id}`;
      }
      case KnownPhoneModels.IM_SOFTPHONE: {
        return `/user/${result.owner?.username}/im-softphone/${result.ref.id}`;
      }
      default: {
        if (result.owner) {
          return `/user/${result.owner?.username}/deskphone/${result.ref.id}`;
        }

        return `/public-phone/${result.ref.name}`;
      }
    }
  }

  onPhoneDeleteClicked(result: PhoneResult): void {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: encodeURIComponent('text-danger'),
        title: this.translateService.instant('tkey;userdetail.phone.delete.confirmation.modal.header'),
        promptBody: this.translateService.instant('tkey;userdetail.phone.delete.confirmation.modal.text', {
          phoneName: result.ref.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              const auditTags = [];
              if (result.owner) {
                auditTags.push({
                  name: 'owner',
                  value: result.owner?.username,
                });
              }

              return this.phoneResource
                .delete(result.ref.id, result.ref.serverId.toString(), auditTags)
                .pipe(map(() => true));
            },
          },
        ],
      },
    };

    this.smacsModalService
      .openPromptModal(() => options.modalViewProperties, options)
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.toastService.pushDeleteToast(SmacsSearchComponent._getPhoneTypeLabel(result), result.ref.name);
          this.phoneResults = this.phoneResults.filter((phoneResult: PhoneResult) => {
            return phoneResult.ref.id !== result.ref.id;
          });
          this._setTotalResults();
        }
      });
  }

  getDnDescription(result: DirectoryNumberResult): string {
    return `<strong>${result.ref.extension}</strong> | ${result.routePartition} | ${result.ref.description}`;
  }

  onDnDeleteClicked(result: DirectoryNumberResult): void {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: encodeURIComponent('text-danger'),
        promptBody: this.translateService.instant('tkey;search.directory.number.delete.voicemail.warning.text', {
          extension: result.ref.extension,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._onDnDeleteConfirmClicked(result);
            },
          },
        ],
      },
    };

    this.smacsModalService
      .openPromptModal(() => options.modalViewProperties, options)
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.toastService.pushDeleteToast(
            'tkey;site_management.site.section.directory_number.title',
            result.ref.extension
          );
          this.directoryNumberResults = this.directoryNumberResults.filter(
            (directoryNumberResult: DirectoryNumberResult) => {
              return directoryNumberResult.ref.id !== result.ref.id;
            }
          );
          this.voicemailResults = this.voicemailResults.filter((voicemailResult: VoicemailResult) => {
            return voicemailResult.ref.extension !== result.ref.extension;
          });

          this._setTotalResults();
        }
      });
  }

  getVoicemailDescription(result: VoicemailResult): string {
    return `<strong>${result.ref.alias}</strong> | ${result.ref.extension}`;
  }

  onVoicemailDeleteClicked(result: VoicemailResult): void {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: encodeURIComponent('text-danger'),
        promptBody: this.translateService.instant('tkey;search.voicemail.delete.warning.text', {
          alias: result.ref.alias,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._onVmDeleteConfirmClicked(result);
            },
          },
        ],
      },
    };

    this.smacsModalService
      .openPromptModal(() => options.modalViewProperties, options)
      .subscribe((isConfirmed) => {
        if (isConfirmed) {
          this.toastService.pushDeleteToast('tkey;shared.service.voicemail.text', result.ref.alias);
          this.voicemailResults = this.voicemailResults.filter((vmr: VoicemailResult) => {
            return vmr.ref.id !== result.ref.id;
          });

          this._setTotalResults();
        }
      });
  }

  getDistributionListDescription(result: DistributionListResult): string {
    return `<strong>${result.alias}</strong> | ${result.displayName}`;
  }

  getSnrDescription(result: SnrProfileResult): string {
    return `<strong>${result.ref.name}</strong> | ${result.ref.description}`;
  }

  onSnrDeleteClicked(result: SnrProfileResult): void {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: encodeURIComponent('text-danger'),
        title: this.translateService.instant('tkey;userdetail.snr.delete.confirmation.modal.header'),
        promptBody: this.translateService.instant('tkey;userdetail.snr.delete.confirmation.modal.text', {
          snrName: result.ref.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this.snrProfileResource.deleteSnrProfile(result.ref.id, result.ref.serverId).pipe(map(() => true));
            },
          },
        ],
      },
    };

    this.smacsModalService
      .openPromptModal(() => options.modalViewProperties, options)
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.toastService.pushDeleteToast('tkey;shared.service.snr.text', result.ref.name);
          this.snrResults = this.snrResults.filter((snr: SnrProfileResult) => {
            return snr.ref.id !== result.ref.id;
          });

          this._setTotalResults();
        }
      });
  }

  onExtensionMobilityProfileDeleteClicked(result: ExtensionMobilityResult): void {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: encodeURIComponent('text-danger'),
        title: this.translateService.instant(
          'tkey;userdetail.extension_mobility_profile.delete.confirmation.modal.header'
        ),
        promptBody: this.translateService.instant(
          'tkey;userdetail.extension_mobility_profile.delete.confirmation.modal.text',
          {
            udpName: result.ref.name,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this.searchExtensionMobilityProfilesResource
                .deleteExtensionMobilityProfile(result.ref.id, result.ref.serverId)
                .pipe(map(() => true));
            },
          },
        ],
      },
    };

    this.smacsModalService
      .openPromptModal(() => options.modalViewProperties, options)
      .subscribe((isConfirmed: boolean) => {
        if (isConfirmed) {
          this.toastService.pushDeleteToast('tkey;shared.service.extensionmobility.text', result.ref.name);
          this.extensionMobilityProfiles = this.extensionMobilityProfiles.filter(
            (extensionMobilityProfile: ExtensionMobilityResult) => {
              return extensionMobilityProfile.ref.id !== result.ref.id;
            }
          );
          this._setTotalResults();
        }
      });
  }

  getPinResetLink(result: VoicemailResult): string {
    return '/unity-servers/' + result.ref.serverId + '/voicemails/' + result.ref.id + '/pin-reset';
  }

  getExtensionMobilityProfileDescription(result: ExtensionMobilityResult): string {
    return `${result.ref.model} - <strong> ${result.ref.name} </strong> | ${result.ref.description}`;
  }

  getPhoneEditLinkState(result: PhoneResult | SnrProfileResult): EditLinkState {
    const editLinkState: EditLinkState = {
      isDisabled: false,
      disabledTooltip: '',
    };

    editLinkState.isDisabled = this._getPhoneEditLinkIsDisabled(result);
    editLinkState.disabledTooltip = this._getPhoneEditLinkTooltip(result);

    return editLinkState;
  }

  handleResultClick(isGuestAccount: boolean, id: string): void {
    if (isGuestAccount) return;
    this.router.navigate(['/360-view/microsoft/' + id]);
  }

  private _convertModelToDescription(model: PhoneDescriptions): ServiceTypes {
    switch (model) {
      case 'Cisco Jabber for Tablet':
        return ServiceTypes.TABLET;
      case 'Cisco Dual Mode for Android':
        return ServiceTypes.ANDROID;
      case 'Cisco Dual Mode for iPhone':
        return ServiceTypes.IPHONE;
      case 'Cisco Unified Client Services Framework':
        return ServiceTypes.SOFTPHONE;
      case 'Cisco IP Communicator':
        return ServiceTypes.CIPC;
      case 'Remote Destination Profile':
        return ServiceTypes.SNR;
      default:
        return ServiceTypes.DESKPHONE;
    }
  }

  private _isPhoneOrServiceInDevicePool(result: PhoneResult | SnrProfileResult): boolean {
    const matchingCluster = this._siteSummary.clusters
      .filter((cluster) => cluster.cucmServerId === result.ref.serverId)
      .find((cluster: ClusterResult) => {
        const matchingSite = cluster.sites.find((site: SiteResult) => {
          const matchingDevicePool = site.devicePools.find((devicePool: DevicePoolResult) => {
            if ('model' in result && result.model === 'Remote Destination Profile') {
              const snrResult = result as SnrProfileResult;
              return (
                devicePool.serviceName === this._convertModelToDescription(snrResult.model as PhoneDescriptions) &&
                devicePool.devicePool === result.ref.devicePool
              );
            } else {
              const phoneResult = result as PhoneResult;
              return (
                devicePool.serviceName ===
                  this._convertModelToDescription(phoneResult.ref.model as PhoneDescriptions) &&
                devicePool.devicePool === result.ref.devicePool
              );
            }
          });
          return !!matchingDevicePool;
        });
        return !!matchingSite;
      });
    return !!matchingCluster;
  }

  private _getPhoneEditLinkTooltip(result: PhoneResult | SnrProfileResult): string {
    if ('model' in result && result.model === 'Remote Destination Profile') {
      result = result as SnrProfileResult;
      return !this._isPhoneOrServiceInDevicePool(result) ? 'tkey;helpdesk.search.device.tooltip.disabled' : '';
    } else {
      result = result as PhoneResult;
      if (!result.owner) {
        return 'tkey;search.devices.no_owner_tooltip';
      } else if (!this._isPhoneOrServiceInDevicePool(result)) {
        return 'tkey;helpdesk.search.device.tooltip.disabled';
      } else {
        return '';
      }
    }
  }

  private _getPhoneEditLinkIsDisabled(result: PhoneResult | SnrProfileResult): boolean {
    if ('model' in result && result.model === 'Remote Destination Profile') {
      result = result as SnrProfileResult;
      return !this._isPhoneOrServiceInDevicePool(result);
    } else {
      result = result as PhoneResult;
      switch (result.ref.model) {
        case KnownPhoneModels.ANDROID:
        case KnownPhoneModels.IPHONE:
        case KnownPhoneModels.CIPC:
        case KnownPhoneModels.TABLET:
        case KnownPhoneModels.IM_SOFTPHONE: {
          return !result.owner || !this._isPhoneOrServiceInDevicePool(result);
        }
        default: {
          return !this._isPhoneOrServiceInDevicePool(result);
        }
      }
    }
  }

  private _clearResults(): void {
    this.snrResults = [];
    this.cucmEndUserResults = [];
    this.directoryNumberResults = [];
    this.phoneResults = [];
    this.distributionListResults = [];
    this.voicemailResults = [];
    this.extensionMobilityProfiles = [];
    this.microsoftEndUserResults = [];
  }

  private _getSearchConfig(): void {
    if (!this.query || this.query.length < 3) {
      this._clearResults();
      this.isLoading = false;
      return;
    }

    const contextSubs = combineLatest([
      this.searchFieldConfigContext.state$,
      this.authenticationContext.state$.pipe(take(1)),
      this.globalPropertiesContext.state$.pipe(take(1)),
    ])
      .pipe(
        switchMap(([searchFieldConfig, currentUser, globalProperties]) => {
          this._globalProperties = globalProperties;
          if (
            !this._globalProperties.hostedEnabled &&
            (searchFieldConfig.cucmSearchEnabled || searchFieldConfig.unitySearchEnabled)
          ) {
            return this.currentClusterContext.state$.pipe(take(1)).pipe(
              switchMap(() => {
                return this._getQueries(searchFieldConfig, currentUser);
              })
            );
          }

          return this._getQueries(searchFieldConfig, currentUser);
        })
      )
      .subscribe(() => {
        this._setTotalResults();
        this.isLoading = false;
      });
    this._subscriptions.add(contextSubs);
  }

  private _getQueries(searchFieldConfig: SearchFieldConfig, currentUser: CurrentUser): Observable<void[]> {
    const queries = [];

    if (!this._globalProperties.hostedEnabled) {
      if (searchFieldConfig.cucmSearchEnabled) {
        queries.push(this._cucmSearch(currentUser));
      }
      if (searchFieldConfig.unitySearchEnabled) {
        queries.push(this._unitySearch(currentUser));
      }
    }

    if (this._globalProperties.hostedEnabled) {
      queries.push(this._msSearch());
    }

    return queries.length ? forkJoin(queries) : of(null);
  }

  private _hasPermissionsOnAnyCluster(cucmServerIds: number[], siteSummary: SiteSummary): boolean {
    return !!cucmServerIds.find((id: number) => {
      const clusters = siteSummary.clusters.filter((clusterResult: ClusterResult) => clusterResult.cucmServerId === id);
      return clusters.some((cluster) => cluster.sites.some((site) => site.hasPermission));
    });
  }

  private _cucmSearch(currentUser: CurrentUser): Observable<void> {
    const cucmSearches: Observable<
      | SnrProfileResult[]
      | EndUserResult[]
      | PhoneResult[]
      | SiteSummary
      | DirectoryNumberResult[]
      | ExtensionMobilityResult[]
    >[] = [
      this.searchResource.searchSnrProfiles(this.query),
      this.endUserSearchResource.searchByQ(this.query),
      this.searchPhoneResource.get({ q: this.query }),
      this.siteSummaryContext.state$.pipe(take(1)),
      this.searchExtensionMobilityProfilesResource.getExtensionMobilityProfiles({ q: this.query }),
    ];

    if (
      this.authenticationContext.userIsAtLeast(
        currentUser,
        this._globalProperties.hostedEnabled ? Role.S8_HELPDESK : Role.S8_GLOBAL_HELPDESK
      )
    ) {
      cucmSearches.push(this.searchDirectoryNumberResource.get({ q: this.query }));
    }

    return new Observable((subscriber: Subscriber<void>) => {
      forkJoin(cucmSearches).subscribe(
        ([snrProfileResults, endUserResults, phoneResults, siteSummary, extensionMobilityResults, dnResults]: [
          SnrProfileResult[],
          EndUserResult[],
          PhoneResult[],
          SiteSummary,
          ExtensionMobilityResult[],
          DirectoryNumberResult[]
        ]) => {
          // Create request to lookup the sites for all the device pools on the phones & snrs
          this._siteSummary = siteSummary;
          const flattened = [].concat(snrProfileResults, phoneResults);
          const devicePools = uniq(flattened.map((result: SnrProfileResult | PhoneResult) => result.ref.devicePool));

          this.snrResults = snrProfileResults;

          const groupedEndUserResults = groupBy(endUserResults, (result: EndUserResult) => result.ref.username);
          const cucmEndUserResults = uniqBy(endUserResults, (result: EndUserResult) => {
            return result.ref.username;
          }).sort((user1: EndUserResult, user2: EndUserResult) => {
            return user1.ref.username.localeCompare(user2.ref.username);
          });

          this.cucmEndUserResults = cucmEndUserResults.map((endUser: EndUserResult) => {
            const cucmServers = groupedEndUserResults[endUser.ref.username].map(
              (groupedEndUser: EndUserResult) => groupedEndUser.ref.serverId
            );
            const hasPermissionsOnAnyCluster = this._hasPermissionsOnAnyCluster(cucmServers, this._siteSummary);
            const userTooltipWhenClustersExist: string =
              endUser.type === 'LDAP_ACTIVE' && !hasPermissionsOnAnyCluster
                ? 'tkey;search.end_user_result.ldap_sync_in_progress.tooltip'
                : !hasPermissionsOnAnyCluster
                ? 'tkey;search.devices.no_permissions_tooltip'
                : '';
            return {
              ...endUser,
              isSyncInProgress:
                siteSummary?.clusters.length > 0
                  ? endUser.type === 'LDAP_ACTIVE' && !hasPermissionsOnAnyCluster
                  : false,
              isDisabled: siteSummary?.clusters.length > 0 ? !hasPermissionsOnAnyCluster : false,
              tooltip: siteSummary?.clusters.length > 0 ? userTooltipWhenClustersExist : '',
            };
          });

          this.phoneResults = phoneResults
            .filter(
              (result: PhoneResult) =>
                result.ref.model !== KnownPhoneModels.CTI_PORT &&
                result.ref.model !== KnownPhoneModels.CTI_REMOTE_DEVICE &&
                result.ref.model !== KnownPhoneModels.SPARK_REMOTE_DEVICE
            )
            .sort((phone1: PhoneResult, phone2: PhoneResult) => {
              return phone1.ref.name.localeCompare(phone2.ref.name);
            });

          this.extensionMobilityProfiles = extensionMobilityResults || [];
          this.directoryNumberResults = dnResults || [];

          if (!!this._siteSummary.clusters.length) {
            // Group device pool sites by cucm server id as they can share the same name cross cluster
            // Individual phone results have the cucm server id to get the matching site from site search results
            this._siteSummary.clusters.forEach((clusterResult) => {
              clusterResult.sites.forEach((site) => {
                devicePools.forEach((devicePool: string) => {
                  const match = site.devicePools.find((pool) => pool.devicePool === devicePool);
                  if (match) {
                    this.devicePoolSites[clusterResult.cucmServerId] = [site];
                  }
                });
              });
            });
          }

          subscriber.next(null);
          subscriber.complete();
        }
      );
    });
  }

  private _unitySearch(currentUser: CurrentUser): Observable<void> {
    const unitySearches: Observable<VoicemailResult[] | DistributionListResult[]>[] = [
      this.voicemailSearchResource.searchByQ(this.query),
    ];

    if (
      this.authenticationContext.userIsAtLeast(
        currentUser,
        this._globalProperties.hostedEnabled ? Role.S8_HELPDESK : Role.S8_GLOBAL_HELPDESK
      )
    ) {
      unitySearches.push(this.searchResource.searchDistributionLists(this.query));
    }

    return forkJoin(unitySearches).pipe(
      map((data: [VoicemailResult[], DistributionListResult[]]) => {
        this.voicemailResults = data[0];
        this.distributionListResults = data[1] || [];
      })
    );
  }

  private _msSearch(): Observable<void> {
    const isNumberSearch = /^\+?[0-9() -]*$/.test(this.query);

    const teamsResults$ = this.microsoftTeamsUserSearchResource.search(this.query, true);

    const microsoft365Results$ = isNumberSearch
      ? of([])
      : this.microsoft365UserResource.search(this.query).pipe(
          tap((microsoft365Results: Microsoft365UserResult[]) => {
            this.microsoftEndUserResults = microsoft365Results
              .map((result) => ({
                ref: result.microsoft365UserRef,
                lineUri: null, // lineUri and extension will be populated later
                lineUriExtension: null,
                photo: null,
              }))
              .sort((a, b) => a.ref.id?.localeCompare(b.ref.id));
            this.zpmUserPhotoContext.initUserPhotos(this.microsoftEndUserResults.map((result) => result.ref.id));
            this._setTotalResults();
            this.isLoading = false;
          })
        );

    return forkJoin([teamsResults$, microsoft365Results$]).pipe(
      map(([teamsResults]) => {
        if (isNumberSearch) {
          this.microsoftEndUserResults = teamsResults
            .map((result) => ({
              ref: result.ref,
              lineUri: result.lineUri,
              lineUriExtension: result.lineUriExtension,
              photo: null,
            }))
            .sort((a, b) => a.ref.id?.localeCompare(b.ref.id));
        }
        this.microsoftEndUserResults.forEach((userResult) => {
          const matchingTeamsResult = teamsResults.find((teamResult) => teamResult.ref.id === userResult.ref.id);
          if (matchingTeamsResult) {
            userResult.lineUri = matchingTeamsResult.lineUri;
            userResult.lineUriExtension = matchingTeamsResult.lineUriExtension;
          }
        });
        this.isLoadingLineUri = false;
      })
    );
  }

  private _setTotalResults(): void {
    this.totalResults =
      this.snrResults.length +
      this.cucmEndUserResults.length +
      this.directoryNumberResults.length +
      this.phoneResults.length +
      this.distributionListResults.length +
      this.voicemailResults.length +
      this.microsoftEndUserResults.length +
      this.extensionMobilityProfiles.length;
  }

  private _onDnDeleteConfirmClicked(result: DirectoryNumberResult): Observable<boolean> {
    // search for vm first
    return new Observable((subscriber: Subscriber<boolean>) => {
      this.voicemailSearchResource
        .searchByExtension(result.ref.extension)
        .pipe(
          tap((vmResult: VoicemailResult[]) => {
            if (vmResult.length) {
              this.endUserSearchResource.searchByQ(vmResult[0].ref.extension).subscribe((endUserSearchResult) => {
                const endUserResult = endUserSearchResult[0];
                this._callVoicemailServiceDelete(vmResult[0], endUserResult)
                  .pipe(
                    tap(() => {
                      this.directoryNumberResource.delete(result.ref.id, result.ref.serverId).subscribe(() => {
                        this.toastService.pushDeleteToast('tkey;shared.service.voicemail.text', vmResult[0].ref.alias);
                        subscriber.next(true);
                        subscriber.complete();
                      });
                    }),
                    catchError((response) => {
                      subscriber.next(false);
                      subscriber.complete();
                      return throwError(() => response);
                    })
                  )
                  .subscribe();
              });
            } else {
              this.directoryNumberResource.delete(result.ref.id, result.ref.serverId).subscribe(() => {
                subscriber.next(true);
                subscriber.complete();
              });
            }
          }),
          catchError((response) => {
            subscriber.next(false);
            subscriber.complete();
            return throwError(() => response);
          })
        )
        .subscribe();
    });
  }

  private _onVmDeleteConfirmClicked(vmResult: VoicemailResult): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      this.endUserSearchResource.searchByQ(vmResult.ref.extension).subscribe((results: EndUserResult[]) => {
        this._callVoicemailServiceDelete(vmResult, results[0])
          .pipe(
            tap(() => {
              subscriber.next(true);
              subscriber.complete();
            }),
            catchError((response) => {
              subscriber.next(false);
              subscriber.complete();
              return throwError(() => response);
            })
          )
          .subscribe();
      });
    });
  }

  private _callVoicemailServiceDelete(vmResult: VoicemailResult, endUserResult: EndUserResult): Observable<void> {
    const auditTags: Nvp[] = endUserResult ? [{ name: 'username', value: endUserResult.ref.username }] : null;
    return this.voicemailResource.delete(vmResult.ref.serverId, vmResult.ref.id, auditTags);
  }
}
