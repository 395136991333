<div class="phone-buttons w-100" data-automation="phone-buttons">
  <ul
    ngbNav
    #nav="ngbNav"
    class="nav nav-tabs"
    [ngClass]="{ 'single-button': isSingleButton }"
    [destroyOnHide]="false"
    [(activeId)]="active"
    data-automation="phone-buttons-nav-tab"
  >
    <!-- Primary Buttons Tab -->
    <li
      [ngbNavItem]="expansionTabId.PRIMARY_BUTTONS"
      [attr.data-automation]="expansionTabId.PRIMARY_BUTTONS"
      *ngIf="!isSingleButton"
    >
      <button ngbNavLink class="btn" [disabled]="isPending || isSaving">
        {{ 'tkey;shared.device.phone.phone_buttons.primary.button.tab.title' | translate }}
      </button>
      <ng-template ngbNavContent>
        <smacs-button
          *ngIf="showCopyUdpButton()"
          class="mr-2"
          [dataAutomation]="'copy-button-from-existing-extension-mobility'"
          [label]="'tkey;shared.device.phone.phone_buttons.primary.button.tab.copy_button.label' | translate"
          [buttonStyle]="buttonStyles.DEFAULT"
          [icon]="smacsIcons.COPY"
          [isDisabled]="isPending || isSaving || !isCopyUdpButtonEnabled()"
          [tooltip]="disabledCopyUdpButtonTooltip()"
          [isTooltipDisabled]="isCopyUdpButtonEnabled()"
          (clicked)="onClickCopyUdpButtons()"
        ></smacs-button>
        <app-phone-buttons-standard
          class="w-100"
          [isPending]="isPending || isSaving"
          [buttons]="standardButtons"
          [phone]="phone"
          [siteId]="siteId"
          [serverId]="serverId"
          [phoneUI]="phoneUI"
          [userMode]="userMode"
          [global360View]="global360View"
          [isAutomaticPhoneTemplateSelectionEnabled]="isAutomaticPhoneButtonTemplateSelectionEnabled"
          [buttonStates]="buttonStates"
          (singleButtonWasUpdated)="updateSingleButton($event)"
          (singleButtonWasDeleted)="deleteSingleButton($event)"
          [attr.data-automation]="'standard-buttons'"
          [isButtonSwapped]="isButtonSwapped"
          [closePopover$]="closePopover$"
          (popoverOpened)="onPopoverOpened($event)"
          (secondaryLineIsUpdating)="onSecondaryLineUpdating($event)"
          (buttonStateIsInvalid)="handleInvalidButtonState()"
          [phoneType]="phoneType"
          [endUserRef]="endUserRef"
        ></app-phone-buttons-standard>
      </ng-template>
    </li>

    <li
      *ngFor="let tab of expansionModuleTabs; let i = index"
      [ngbNavItem]="expansionTabId.ADD_MODULE + i"
      class="nav-item d-flex"
      [attr.data-automation]="expansionTabId.ADD_MODULE"
    >
      <button
        ngbNavLink
        (click)="changeActiveTab(i)"
        [attr.data-automation]="expansionTabId.ADD_MODULE + i"
        class="btn expansion-module-tab"
        [disabled]="isPending || isSaving"
        [ngClass]="{
          'nav-item__last-item': canDisplayKemDropdown() && i === expansionModuleTabs.length - 1
        }"
      >
        {{ ('tkey;shared.device.phone.phone_buttons.expansion.module.tab.title' | translate) + (i + 1) }}
      </button>
      <button
        type="button"
        class="btn btn-close ms-n4 nav-item__close-tab"
        [disabled]="isPending || isSaving"
        *ngIf="canDisplayKemDropdown() && i === expansionModuleTabs.length - 1"
        [attr.data-automation]="'expansion-module-tab-close'"
        (click)="onRemoveKem($event, i)"
      ></button>
      <ng-template ngbNavContent>
        <app-loading-block [hidden]="!isAddingNewKem" data-automation="phone-buttons-loading-block"></app-loading-block>
        <ng-container *ngIf="!isAddingNewKem">
          <div class="container-fluid">
            <div class="row mt-3">
              <div class="col">
                <div
                  *ngIf="isKemTitleDisplayed"
                  class="text-center expansion-module-title"
                  [ngClass]="'expansion-module-title-' + i"
                  [attr.data-automation]="'expansion-module-title'"
                >
                  {{ tab }}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <app-phone-buttons-standard
                  class="my-2"
                  [isPending]="isPending || isSaving"
                  [userMode]="userMode"
                  [phone]="phone"
                  [siteId]="siteId"
                  [serverId]="serverId"
                  [buttons]="expansionButtons[i]?.buttons"
                  [phoneUI]="expansionButtons[i]?.expansionUI"
                  [global360View]="global360View"
                  [isAutomaticPhoneTemplateSelectionEnabled]="isAutomaticPhoneButtonTemplateSelectionEnabled"
                  [buttonStates]="buttonStates"
                  (singleButtonWasUpdated)="updateSingleButton($event)"
                  (singleButtonWasDeleted)="deleteSingleButton($event)"
                  [attr.data-automation]="'phone-expansion-module-buttons-' + (i + 1)"
                  [isButtonSwapped]="isButtonSwapped"
                  [closePopover$]="closePopover$"
                  (popoverOpened)="onPopoverOpened($event)"
                  (secondaryLineIsUpdating)="onSecondaryLineUpdating($event)"
                  (buttonStateIsInvalid)="handleInvalidButtonState()"
                  [phoneType]="phoneType"
                  [endUserRef]="endUserRef"
                ></app-phone-buttons-standard>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-template>
    </li>

    <!-- Add a new KEM -->
    <li
      [ngbNavItem]="expansionTabId.ADD_MODULE"
      *ngIf="isAutomaticPhoneButtonTemplateSelectionEnabled && !selectedModule && supportedKems.length > 1"
      class="nav-item dropdown"
      ngbDropdown
      [attr.data-automation]="'add-new-module-tab'"
    >
      <button
        [innerHtml]="'tkey;shared.device.phone.phone_buttons.add.expansion.module.tab.title' | translate"
        class="btn nav-link dropdown-toggle"
        [disabled]="isPending || isSaving"
        ngbDropdownToggle
        data-toggle="dropdown"
        role="button"
      ></button>
      <div ngbDropdownMenu class="dropdown-menu add-new-tab">
        <div *ngFor="let model of supportedKems; let i = index">
          <a class="btn dropdown-item" [attr.data-automation]="'dropdown-item-' + i" (click)="addNewTab(model)">
            {{ model }}
          </a>
        </div>
      </div>
    </li>

    <!-- Add another existing KEM -->
    <li
      [ngbNavItem]="expansionTabId.ADD_MODULE"
      *ngIf="canDisplayKemDropdown() && (!!selectedModule || supportedKems.length <= 1) && !isSingleButton"
      class="nav-item dropdown add-new-module"
      ngbDropdown
      [ngClass]="{
        'nav-item dropdown disabled nav-tabs--disabled': !canAddMoreExpansionModule() || isAddingNewKem,
        'nav-item dropdown': canAddMoreExpansionModule() && !isAddingNewKem,
        'ps-0': supportedKems.length === 1 && expansionModuleTabs.length === 0
      }"
      [tooltipClass]="'add-new-kem-tooltip'"
      [ngbTooltip]="getAddTabDisabledTooltip() | translate"
      [disableTooltip]="canAddMoreExpansionModule()"
      [attr.data-automation]="'add-new-module-tab'"
    >
      <div
        ngbNavLink
        *ngIf="!canAddMoreExpansionModule() || isAddingNewKem"
        class="disabled-tab"
        [innerHtml]="'tkey;shared.device.phone.phone_buttons.expansion.module.tab.single_module.title' | translate"
      ></div>
      <button
        ngbNavLink
        class="btn"
        [disabled]="isPending || isSaving"
        *ngIf="canAddMoreExpansionModule() && !isAddingNewKem"
        (click)="addNewTab(selectedModule)"
        [innerHtml]="'tkey;shared.device.phone.phone_buttons.expansion.module.tab.single_module.title' | translate"
      ></button>
    </li>

    <!-- Phone with one button -->
    <li *ngIf="isSingleButton" class="single-button d-flex justify-content-center">
      <app-phone-buttons-standard
        class="w-100"
        [isPending]="isPending || isSaving"
        [buttons]="standardButtons"
        [phone]="phone"
        [siteId]="siteId"
        [serverId]="serverId"
        [phoneUI]="phoneUI"
        [userMode]="userMode"
        [global360View]="global360View"
        [isAutomaticPhoneTemplateSelectionEnabled]="isAutomaticPhoneButtonTemplateSelectionEnabled"
        [buttonStates]="buttonStates"
        (singleButtonWasUpdated)="updateSingleButton($event)"
        (singleButtonWasDeleted)="deleteSingleButton($event)"
        [attr.data-automation]="'standard-buttons'"
        [isButtonSwapped]="isButtonSwapped"
        [closePopover$]="closePopover$"
        (popoverOpened)="onPopoverOpened($event)"
        (secondaryLineIsUpdating)="onSecondaryLineUpdating($event)"
        (buttonStateIsInvalid)="handleInvalidButtonState()"
        [phoneType]="phoneType"
        [endUserRef]="endUserRef"
      ></app-phone-buttons-standard>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
