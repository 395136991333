<app-breadcrumbs>
  <app-breadcrumb-card-item>
    <div [innerHTML]="'tkey;pages.zero_touch.deprovisioning.job.text' | translate"></div>
  </app-breadcrumb-card-item>
</app-breadcrumbs>

<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div [hidden]="isLoading">
  <smacs-text [fieldId]="'name'"></smacs-text>
  <smacs-checkbox [fieldId]="'makeDeskphonesPublicEnabled'"></smacs-checkbox>
  <smacs-checkbox [fieldId]="'keepVoicemailEnabled'"></smacs-checkbox>
  <smacs-checkbox [fieldId]="'runAutomatically'"></smacs-checkbox>
  <smacs-radio [fieldId]="'scheduleFrequency'"></smacs-radio>
  <smacs-text [fieldId]="'periodicJobMinutes'"></smacs-text>
  <smacs-text [fieldId]="'dailyJobTime'"></smacs-text>
</div>
