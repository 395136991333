import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Microsoft360View,
  Microsoft365License,
  Microsoft365LicenseCount,
  Microsoft365LicensesFieldConfig,
  Microsoft365UserLicenses,
  MicrosoftSecurityGroupOption,
  MicrosoftSecurityGroupsFieldConfig,
} from '../../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { Microsoft365UserLicensesPayload } from './microsoft365-info-licenses/microsoft365-info-licenses.component';
import { Microsoft360ViewContext } from '../../../shared/contexts/microsoft-360-view.context';
import { combineLatest, Subscription } from 'rxjs';
import { MicrosoftLicensesContext } from '../../../shared/contexts/microsoft-licenses.context';
import { MicrosoftSecurityGroupsMembershipContext } from '../../../shared/contexts/microsoft-security-groups-membership.context';

@Component({
  selector: 'smacs-microsoft-view-licenses',
  templateUrl: './microsoft-view-licenses.component.html',
})
export class MicrosoftViewLicensesComponent implements OnInit, OnDestroy {
  isLoading = true;
  pageDisabled = true;
  username: string;
  tenantDefaultUsageLocation: string;
  licenseConfig: Microsoft365LicensesFieldConfig;
  securityGroupFieldConfig: MicrosoftSecurityGroupsFieldConfig;
  userUsageLocation: string;
  smacsIcons = SmacsIcons;
  inventory: Microsoft365LicenseCount[] = [];
  userLicenses: Microsoft365UserLicenses;
  isReady = false;
  initialData: Microsoft365UserLicensesPayload = { licenses: {}, usageLocation: '' };
  userSecurityGroups: MicrosoftSecurityGroupOption[];
  microsoft360View: Microsoft360View;

  private _subscriptions = new Subscription();

  constructor(
    private microsoft360ViewContext: Microsoft360ViewContext,
    private microsoftSecurityGroupsMembershipContext: MicrosoftSecurityGroupsMembershipContext,
    private microsoftLicensesContext: MicrosoftLicensesContext
  ) {}

  ngOnInit(): void {
    this.microsoftLicensesContext.refresh().subscribe(() => {
      this._getInitialData();
    });
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _getInitialData() {
    const sub = combineLatest([
      this.microsoft360ViewContext.state$,
      this.microsoftLicensesContext.state$,
      this.microsoftSecurityGroupsMembershipContext.state$,
    ]).subscribe(([microsoft360View, licensesConfig, securityGroupFieldConfig]) => {
      this.microsoft360View = microsoft360View;
      this.userLicenses = microsoft360View.microsoft365UserLicenses;
      this.userSecurityGroups = microsoft360View.securityGroupMemberships;
      this.inventory = licensesConfig.writeLicenseList.concat(licensesConfig.readOnlyLicenseList);
      this.tenantDefaultUsageLocation = licensesConfig.usageLocation.defaultValue;
      this.licenseConfig = licensesConfig;
      this.securityGroupFieldConfig = securityGroupFieldConfig;
      this.userUsageLocation = microsoft360View.microsoft365UserLicenses.usageLocation;
      this._setInitialData();
      this.isLoading = false;
      this.isReady = true;
      this.pageDisabled = false;
    });

    this._subscriptions.add(sub);
  }

  private _setInitialData() {
    this.initialData.usageLocation =
      this.userUsageLocation !== '' ? this.userUsageLocation : this.tenantDefaultUsageLocation;
    this.inventory.forEach((license: Microsoft365LicenseCount) => {
      const skuId = license.microsoft365LicenseJson.skuId;
      const name = license.microsoft365LicenseJson.productName || license.microsoft365LicenseJson.skuPartNumber;
      this.initialData.licenses[name] = this.userLicenses.licenses.some((userLicense: Microsoft365License) => {
        return userLicense.skuId === skuId;
      });
    });
  }
}
