import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { SmacsIcons } from '../shared/models/smacs-icons.enum';

export enum ButtonStyles {
  DEFAULT = 'btn-default',
  SUCCESS = 'btn-success',
  DANGER = 'btn-danger',
  WARNING = 'btn-warning',
  PRIMARY = 'btn-primary',
  INFO = 'btn-info',
  OUTLINE_DEFAULT = 'btn-outline-default',
  OUTLINE_DANGER = 'btn-outline-danger',
  OUTLINE_WARNING = 'btn-outline-warning',
  OUTLINE_INFO = 'btn-outline-info',
  OUTLINE_SUCCESS = 'btn-outline-success',
  OUTLINE_PRIMARY = 'btn-outline-primary',
  OUTLINE_SECONDARY = 'btn-outline-secondary',
  LINK = 'btn-link',
  NONE = '',
}

export enum ButtonSizes {
  NORMAL = '',
  XSMALL = 'btn-xs',
  SMALL = 'btn-sm',
  LARGE = 'btn-lg',
}

export enum ButtonTypes {
  BUTTON = 'button',
  LINK = 'link',
  SUBMIT = 'submit',
  CUSTOM = 'custom',
}

@Component({
  selector: 'smacs-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class SmacsButtonComponent implements OnInit {
  // Tooltip disabled by default
  @Input() tooltip = '';
  @Input() isTooltipDisabled = true;

  @Input() dataAutomation = '';
  @Input() id: string = null;
  @Input() label = '';
  @Input() labelPending = '';
  @Input() labelCssClass = '';
  @Input() cssClass = '';
  @Input() isDragItem = false;
  @Input() isFullWidth = false;

  @Input() type = ButtonTypes.BUTTON;

  @Input() buttonStyle = ButtonStyles.PRIMARY;
  @Input() buttonSize = ButtonSizes.NORMAL;
  @Input() buttonStylePending = ButtonStyles.WARNING;

  @Input() isPending = false;
  @Input() isDisabled = false;

  @Input() icon = SmacsIcons.NONE;
  @Input() iconAppended = false;
  @Input() iconPending = SmacsIcons.NONE;

  @Input() action = new Subject<any>();
  @Input() href: string;

  @Output() clicked = new EventEmitter<any>();

  icons = SmacsIcons;
  focus = false;
  isCustomIcon = false;

  ngOnInit() {
    const icon = this.icon as string;
    this.isCustomIcon = !!this.icon && icon.includes('custom');
  }

  onClick() {
    if (!this.isDisabled) {
      this.clicked.emit(true);
    }
  }

  getButtonDisabledClass(): string {
    return this.isDisabled || this.isPending ? 'disabled' : '';
  }

  getButtonClass(): string {
    if (!this.isPending || this.buttonStyle === ButtonStyles.DANGER) {
      return `${this.buttonStyle} ${this.cssClass}`;
    } else {
      return `${this.buttonStylePending} ${this.cssClass}`;
    }
  }

  getButtonSize(): string {
    return this.buttonSize;
  }

  getIconClass(): string {
    if (!this.isPending && this.icon) {
      return this.icon;
    } else if (this.isPending && this.iconPending) {
      return this.iconPending;
    }
  }

  getLabel(): string {
    if (!this.isPending || !this.labelPending) {
      return this.label;
    } else {
      return this.labelPending;
    }
  }

  protected readonly ButtonSizes = ButtonSizes;
  protected readonly ButtonTypes = ButtonTypes;
}
