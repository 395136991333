<div class="card border-primary" [hidden]="isProxyServerEnabled">
  <div class="card-header bg-primary text-white">
    {{ 'tkey;admin.ldap.groups.title' | translate }}
  </div>

  <div class="card-body">
    <div class="container-fluid">
      <div class="card bg-light mb-3">
        <div class="card-body" [innerHTML]="'tkey;admin.ldap.groups.text' | translate"></div>
      </div>

      <div class="row mb-2" data-automation="ldap-group-admin-input">
        <div class="offset-xl-1 col-xl-6">
          <smacs-select fieldId="administratorsGroup"></smacs-select>
        </div>
        <div class="col-xl-2">
          <smacs-view-members
            *ngIf="formData.administratorsGroup"
            [members]="administratorsGroup.matches"
            [state]="administratorsGroup.viewMembersState"
            [errorMessage]="administratorsGroup.errorMsg"
          ></smacs-view-members>
        </div>
      </div>

      <div class="row mb-2" data-automation="ldap-group-global-helpdesk-input">
        <div class="offset-xl-1 col-xl-6">
          <smacs-select fieldId="globalHelpdeskGroup"></smacs-select>
        </div>
        <div class="col-xl-2">
          <smacs-view-members
            *ngIf="formData.globalHelpdeskGroup"
            [members]="globalHelpdeskGroup.matches"
            [state]="globalHelpdeskGroup.viewMembersState"
            [errorMessage]="globalHelpdeskGroup.errorMsg"
          ></smacs-view-members>
        </div>
      </div>

      <div class="row mb-2" data-automation="ldap-group-sitemac-input">
        <div class="offset-xl-1 col-xl-6">
          <smacs-select fieldId="siteBasedHelpdeskGroup"></smacs-select>
        </div>
        <div class="col-xl-2">
          <smacs-view-members
            *ngIf="formData.siteBasedHelpdeskGroup"
            [members]="siteBasedHelpdeskGroup.matches"
            [state]="siteBasedHelpdeskGroup.viewMembersState"
            [errorMessage]="siteBasedHelpdeskGroup.errorMsg"
          ></smacs-view-members>
        </div>
      </div>

      <div class="row" data-automation="ldap-group-lookup-nested-groups-input">
        <div class="offset-xl-1 col-xl-6">
          <smacs-checkbox fieldId="lookupNestedGroups"></smacs-checkbox>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-end">
      <smacs-submit-button
        [isPending]="isSubmitting"
        [isDisabled]="isSubmitting"
        [label]="'tkey;global.button.save.text'"
        [dataAutomation]="'ldap-management-permissions-save'"
        [icon]="smacsIcons.OK"
        [iconPending]="smacsIcons.SAVING"
        [formSubmitSubject]="_validateAndSubmitSource"
      ></smacs-submit-button>
    </div>
  </div>
</div>
