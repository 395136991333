<a
  class="self-serve-card border-rad-all-2"
  [routerLink]="routerLink"
  [attr.data-automation]="dataAutomation"
  (mouseover)="onMouseOver()"
  (mouseleave)="onMouseLeave()"
>
  <div class="self-serve-card--icon" data-automation="lottie-animation">
    <main lottie [options]="animationOptions" (animationCreated)="onAnimationCreated($event)"></main>
  </div>
  <div class="self-serve-card--about">
    <p data-automation="self-serve-card--header" class="self-serve-card--header strong">{{ header | translate }}</p>
    <div class="self-serve-card--body">
      <span
        *ngIf="displayBadgePrimary"
        data-automation="self-serve-card--badge-primary"
        class="badge bg-primary ms-0 me-1"
      >
        <ng-content select="[badgePrimary]"></ng-content>
      </span>
      <span
        *ngIf="displayBadgeSecondary"
        data-automation="self-serve-card--badge-secondary"
        [class]="'badge ms-0 ' + badgeSecondaryCssClass"
      >
        <ng-content select="[badgeSecondary]"></ng-content>
      </span>
      <p data-automation="self-serve-card--description" class="self-serve-card--description">
        {{ description | translate }}
      </p>
    </div>
  </div>
</a>
