import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { CiscoGeneralSettings, LicenseInfo } from '../../../shared/models/generated/smacsModels';
import {
  SmacsFieldComponentConfig,
  SmacsFieldConfig,
  SmacsFormConfig,
  SmacsFormsValidationState,
} from '../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  SmacsCheckboxConfig,
} from '../../../forms/fields/checkbox/smacs-checkbox.component';
import { HtmlInputType, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { combineLatest, Observable, Subscriber, Subscription } from 'rxjs';
import { LicenseContext } from '../../../shared/contexts/license.context';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { get } from 'lodash';
import { CiscoGeneralSettingsResource } from '../../resources/cisco-general-settings.resource';
import { HelpdeskOptionsContext } from '../../../helpdesk/shared/contexts/helpdesk-options.context';

@Component({
  selector: 'smacs-cisco-general-settings',
  templateUrl: './cisco-general-settings.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class CiscoGeneralSettingsComponent
  extends SmacsFormAbstractDirective<CiscoGeneralSettings>
  implements OnInit, OnDestroy
{
  isLoading = true;
  smacsIcons = SmacsIcons;
  bottomNavButtons = [
    {
      id: 'saveCiscoGeneralSettings',
      dataAutomation: 'save-cisco-general-settings-button',
      label: 'tkey;admin.ui.save',
      buttonClass: ButtonStyles.PRIMARY,
      state: {
        pending: false,
        buttonDisableState: {
          disabled: false,
          tooltipKey: '',
        },
      },
      icon: this.smacsIcons.OK,
      type: ButtonTypes.SUBMIT,
      submitSubject: this._validateAndSubmitSource,
    },
  ] as BottomNavButton[];

  formConfig = {
    fields: {
      resetPinOnNextLoginEnabled: {
        dataAutomation: 'reset-pin-on-next-login-enabled',
        label: 'tkey;admin.system.cisco_general_settings.voicemail_and_ext_mobility.reset_pin_on_next_login.label',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        options: {
          content:
            'tkey;admin.system.cisco_general_settings.voicemail_and_ext_mobility.reset_pin_on_next_login.content',
        },
      },
      selfServeDeskphoneProvisioningEnabled: {
        dataAutomation: 'self-serve-deskphone-provisioning-enabled',
        label: 'tkey;admin.system.cisco_general_settings.selfserve.enable.deskphone.provisioning.edit',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        disabled: () => !get(this._licenseInfo, 'modules.automation'),
        disabledTooltip: 'tkey;admin.system.cisco_general_settings.selfserve.enable.deskphone.provisioning.disabled',
        options: {
          content: 'tkey;admin.system.cisco_general_settings.selfserve.enable.deskphone.provisioning.edit.content',
        },
      },
      displayEnhancedLineMode: {
        dataAutomation: 'display-enhanced-line-mode',
        label: 'tkey;admin.system.cisco_general_settings.phone_button_display.label',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        options: {
          content: 'tkey;admin.system.cisco_general_settings.phone_button_display.content',
        },
      },
      showAddUserPage: {
        dataAutomation: 'show-add-user-page',
        label: 'tkey;admin.system.cisco_general_settings.show.add.user.page.label',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        options: {
          content: 'tkey;admin.system.cisco_general_settings.show.add.user.page.content',
        },
      },
      selfServeCallForwardEditEnabled: {
        dataAutomation: 'self-serve-call-forward-edit-enabled',
        label: 'tkey;admin.system.cisco_general_settings.selfserve.enable.call.forward.edit',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        options: {
          content: 'tkey;admin.system.cisco_general_settings.selfserve.enable.call.forward.edit.content',
        },
      },
      selfServeSnrEditEnabled: {
        dataAutomation: 'self-serve-snr-edit-enabled',
        label: 'tkey;admin.system.cisco_general_settings.selfserve.enable.snr.edit',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        options: {
          content: 'tkey;admin.system.cisco_general_settings.selfserve.enable.snr.edit.content',
        },
      },
      selfServeSpeedDialEditEnabled: {
        dataAutomation: 'self-serve-speed-dial-edit-enabled',
        label: 'tkey;admin.system.cisco_general_settings.selfserve.enable.speed_dial.edit',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        options: {
          content: 'tkey;admin.system.cisco_general_settings.selfserve.enable.speed_dial.edit.content',
        },
      },
      automaticPhoneTemplateSelectionEnabled: {
        dataAutomation: 'automatic-phone-template-selection-enabled',
        label: 'tkey;admin.system.cisco_general_settings.automatic_phone_button_template_selection.label',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        options: {
          content: 'tkey;admin.system.cisco_general_settings.automatic_phone_button_template_selection.content',
        },
      },
      changeSiteEnabled: {
        dataAutomation: 'change-site-enabled',
        label: 'tkey;admin.system.cisco_general_settings.change_site_device_pool_only.label',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        options: {
          content: 'tkey;admin.system.cisco_general_settings.change_site_device_pool_only.content',
        },
      },
      display360ViewClusterDropdownEnabled: {
        dataAutomation: 'display-360-view-cluster-dropdown-enabled',
        label: 'tkey;admin.system.cisco_general_settings.display_360_view_cluster_dropdown.label',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        options: {
          content: 'tkey;admin.system.cisco_general_settings.display_360_view_cluster_dropdown.content',
        },
      },
      minimumOverageDaysSmartLicensing: {
        dataAutomation: 'minimum-overage-days-smart-licensing',
        label: 'tkey;admin.system.cisco_general_settings.minimum_overage_days_smart_licensing.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        required: () => true,
        validation: [
          {
            validator: (val: number) => {
              return val >= 0 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
            },
            message:
              'tkey;admin.system.cisco_general_settings.minimum_overage_days_smart_licensing.validation.positive.error',
          },
        ],
        options: {
          content: 'tkey;admin.system.cisco_general_settings.minimum_overage_days_smart_licensing.content',
        },
      },
      unifiedFxUrl: {
        dataAutomation: 'unified-fx-url',
        label: 'tkey;admin.system.cisco_general_settings.unified_fx_url.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        options: {
          content: 'tkey;admin.system.cisco_general_settings.unified_fx_url.content',
        },
      },
      zpmUrl: {
        dataAutomation: 'zpm-url',
        label: 'tkey;admin.system.cisco_general_settings.zpm_url.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
        options: {
          content: 'tkey;admin.system.cisco_general_settings.zpm_url.content',
        },
      },
      maxOwnedDevices: {
        dataAutomation: 'max-owned-devices',
        label: 'tkey;admin.system.cisco_general_settings.max_owned_devices.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        required: () => true,
        validation: [
          {
            validator: (val: number) =>
              val >= 1 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: this.translateService.instant('tkey;admin.audits.logs.audit_days_to_keep.positive.error.text'),
          },
        ],
        options: {
          content: 'tkey;admin.system.cisco_general_settings.max_owned_devices.content',
        },
      },
    },
  } as SmacsFormConfig;

  private _config = {} as CiscoGeneralSettings;
  private _licenseInfo = {} as LicenseInfo;
  private _subscriptions = new Subscription();

  constructor(
    private toastService: ToastService,
    private ciscoGeneralSettingsResource: CiscoGeneralSettingsResource,
    private bottomNavService: BottomNavService,
    protected smacsFormStateService: SmacsFormStateService,
    private breadcrumbsService: BreadcrumbsService,
    private licenseContext: LicenseContext,
    private translateService: TranslateService,
    private helpdeskOptionsContext: HelpdeskOptionsContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.system.cisco_general_settings.title' }]);
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));

    combineLatest([this.ciscoGeneralSettingsResource.get(), this.licenseContext.licenseState$]).subscribe((data) => {
      this._config = data[0];
      this._licenseInfo = data[1];

      this.entitySource.next({
        ...this._config,
        selfServeDeskphoneProvisioningEnabled:
          this._config.selfServeDeskphoneProvisioningEnabled && this._licenseInfo.modules.automation,
      });

      this.isLoading = false;
    });

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() =>
      this._setBottomNavValidationError(!this.isFormValid())
    );
    this._subscriptions.add(formSubmittedSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
    this.breadcrumbsService.clearBreadcrumbs();
  }

  isCheckBox(config: SmacsFieldConfig<SmacsFieldComponentConfig>): boolean {
    return config.componentConfig instanceof SmacsCheckboxConfig;
  }

  isTextInput(config: SmacsFieldConfig<SmacsFieldComponentConfig>): boolean {
    return config.componentConfig instanceof SmacsTextConfig;
  }

  // Used to keep the order of the keys-value pairs as we iterate formConfig.fields using 'keyvalue' pipe
  returnZero = () => 0;

  protected submit() {
    return this._saveGeneralSettings();
  }

  private _saveGeneralSettings(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._setBottomNavValidationError(false);
      this._setBottomNavPending(true);

      this.ciscoGeneralSettingsResource.put(this.entity).subscribe(() => {
        this._setBottomNavPending(false);

        this.toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.CONFIG,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.system.cisco_general_settings.save.toast'
        );

        this.helpdeskOptionsContext.refresh().subscribe(() => {
          subscriber.next(null);
          subscriber.complete();
        });
      });
    });
  }

  private _setBottomNavValidationError(hasError: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: hasError,
      })
    );
  }

  private _setBottomNavPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'saveCiscoGeneralSettings',
        state: {
          pending: setting,
        },
      })
    );
  }
}
