import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { DatatableColumn, DatatableRow } from '../../../../reporting/datatable/datatable.component';
import { DetailedZiroCallingReportsContext } from '../services/detailed-ziro-calling-reports.context';
import { Subscription } from 'rxjs';
import {
  AccountType,
  BillingProduct,
  DetailedZiroCallingReport,
  JobStatus,
  PstnConnectivityType,
} from '../../../../shared/models/generated/smacsModels';
import { DatatableFilterService } from '../../../../reporting/datatable/datatable-filter.service';
import { DatatableMultiSelectFilterComponent } from '../../../../reporting/datatable/datatable-filters/datatable-multi-select-filter/datatable-multi-select-filter.component';
import { BillingProductsAbstractDirective } from '../billing-products.abstract.directive';
import { TranslateService } from '@ngx-translate/core';
import { TelephoneNumberFilter } from '../../../../shared/filters/telephone-number.filter';
import { DatatableSwitchFilterComponent } from '../../../../reporting/datatable/datatable-filters/datatable-switch-filter/datatable-switch-filter.component';
import { ActivatedRoute } from '@angular/router';
import { BillingProductsPollingService } from '../services/billing-products-polling.service';
import { BottomNavService } from '../../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { DateAgoPipe } from '../../../../shared/pipes/date-ago.pipe';
import ChangeEvent = JQuery.ChangeEvent;

interface DetailedZiroCallingReportData extends DatatableRow {
  number: string;
  country: string;
  carrier: string;
  assignedUpn: string;
  accountType: string;
  pstn: string;
  dialPlanGroupName: string;
  billingLocation: string;
  billingProduct: string;
}

@Component({
  selector: 'smacs-billing-products-detailed-view',
  templateUrl: './billing-products-detailed-view.component.html',
  styleUrls: ['../../../admin-page.scss'],
})
export class BillingProductsDetailedViewComponent
  extends BillingProductsAbstractDirective
  implements OnInit, OnDestroy
{
  @ViewChild('noDialPlanFilter') noDialPlanFilter: DatatableSwitchFilterComponent;
  @ViewChild('billingProductFilter')
  billingProductFilter: DatatableMultiSelectFilterComponent;

  BILLING_PRODUCT_NAME_MAP: Map<BillingProduct, string> = new Map([
    [
      BillingProduct.ZPM_SUBSCRIPTION,
      this.translateService.instant('tkey;admin.billing.products.product_name.zpm_subscription'),
    ],
    [BillingProduct.ZIRO_DRAAS, this.translateService.instant('tkey;admin.billing.products.product_name.ziro_draas')],
    [
      BillingProduct.ZIRO_DRAAS_EXTENSION,
      this.translateService.instant('tkey;admin.billing.products.product_name.ziro_draas_extension'),
    ],
    [
      BillingProduct.ZIRO_DRAAS_SHARED_CALLING_DID,
      this.translateService.instant('tkey;admin.billing.products.product_name.ziro_draas_shared_calling_did'),
    ],
    [
      BillingProduct.ZIRO_SPARE_DID,
      this.translateService.instant('tkey;admin.billing.products.product_name.ziro_spare_did'),
    ],
    [
      BillingProduct.ZIRO_DRAAS_BYOC,
      this.translateService.instant('tkey;admin.billing.products.product_name.ziro_draas_byoc'),
    ],
    [
      BillingProduct.ZIRO_PSTN_CONNECTIVITY_OTHER,
      this.translateService.instant('tkey;admin.billing.products.product_name.ziro_pstn_connectivity_other'),
    ],
  ]);
  ACCOUNT_TYPE_NAME_MAP: Map<AccountType, string> = new Map([
    [AccountType.USER, this.translateService.instant('tkey;admin.billing.products.account_type.user')],
    [
      AccountType.RESOURCE_ACCOUNT,
      this.translateService.instant('tkey;admin.billing.products.account_type.resource_account'),
    ],
    [AccountType.SFB_ON_PREM_USER, this.translateService.instant('tkey;admin.billing.products.account_type.on_prem')],
    [AccountType.UNKNOWN, this.translateService.instant('tkey;admin.billing.products.account_type.unknown')],
    [AccountType.GUEST, this.translateService.instant('tkey;admin.billing.products.account_type.guest')],
    [
      AccountType.UPN_NOT_IN_TEAMS,
      this.translateService.instant('tkey;admin.billing.products.account_type.upn_not_in_teams'),
    ],
  ]);
  PSTN_MAP: Map<PstnConnectivityType, string> = new Map([
    [
      PstnConnectivityType.DIRECT_ROUTING,
      this.translateService.instant('tkey;admin.billing.products.detailed_usage.datatable.pstn.direct_routing'),
    ],
    [
      PstnConnectivityType.OPERATOR_CONNECT,
      this.translateService.instant('tkey;admin.billing.products.detailed_usage.datatable.pstn.operator_connect'),
    ],
    [
      PstnConnectivityType.ZIRO_DRAAS,
      this.translateService.instant('tkey;admin.billing.products.detailed_usage.datatable.pstn.drass'),
    ],
    [
      PstnConnectivityType.ZIRO_DRAAS_BYOC,
      this.translateService.instant('tkey;admin.billing.products.detailed_usage.datatable.pstn.drass_byoc'),
    ],
  ]);

  columns: DatatableColumn<DetailedZiroCallingReportData>[] = [
    {
      name: 'number',
      label: 'tkey;admin.billing.products.detailed_usage.datatable.number.header',
      pipe: this.telephoneNumberFilter,
      columnCharacterWidth: 30,
    },
    { name: 'country', label: 'tkey;admin.billing.products.detailed_usage.datatable.country.header' },
    { name: 'carrier', label: 'tkey;admin.billing.products.detailed_usage.datatable.carrier.header' },
    { name: 'assignedUpn', label: 'tkey;admin.billing.products.detailed_usage.datatable.assigned_upn.header' },
    { name: 'accountType', label: 'tkey;admin.billing.products.detailed_usage.datatable.account_type.header' },
    { name: 'pstn', label: 'tkey;admin.billing.products.detailed_usage.datatable.pstn.header' },
    {
      name: 'dialPlanGroupName',
      label: 'tkey;admin.billing.products.detailed_usage.datatable.dial_plan_group_name.header',
    },
    { name: 'billingLocation', label: 'tkey;admin.billing.products.detailed_usage.datatable.billing_location.header' },
    { name: 'billingProduct', label: 'tkey;admin.billing.products.detailed_usage.datatable.billing_product.header' },
  ];
  data: DetailedZiroCallingReportData[] = [];

  accountTypeFilterOptions: string[] = Array.from(this.ACCOUNT_TYPE_NAME_MAP.values()).slice(0, 2);
  dialPlanGroupNameFilterOptions: string[] = [];
  billingLocationFilterOptions: string[] = [];
  billingProductFilterOptions: string[] = Array.from(this.BILLING_PRODUCT_NAME_MAP.values());
  pstnFilterOptions: string[] = Array.from(this.PSTN_MAP.values());
  countryFilterOptions: string[] = [];
  carrierFilterOptions: string[] = [];
  noResultsAlert: string;
  noResultsAlertClass: string;
  isLoadingTable = true;
  product: BillingProduct;
  tableDataAutomation = 'billing-datatable-';
  showCustomAlert = false;

  private _subscription = new Subscription();

  constructor(
    protected breadcrumbsService: BreadcrumbsService,
    protected bottomNavService: BottomNavService,
    protected detailedZiroCallingReportsContext: DetailedZiroCallingReportsContext,
    protected billingProductsPollingService: BillingProductsPollingService,
    protected toastService: ToastService,
    protected translateService: TranslateService,
    protected dateAgoPipe: DateAgoPipe,

    private telephoneNumberFilter: TelephoneNumberFilter,
    private datatableFilterService: DatatableFilterService,
    private changeDetectorRef: ChangeDetectorRef,
    private route: ActivatedRoute
  ) {
    super(
      breadcrumbsService,
      bottomNavService,
      detailedZiroCallingReportsContext,
      billingProductsPollingService,
      toastService,
      translateService,
      dateAgoPipe
    );
  }

  ngOnInit() {
    super.ngOnInit();

    const product = this.route.snapshot.params.productId as string;
    this.product = product.toUpperCase() as BillingProduct;
    this.tableDataAutomation += this.product;
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;admin.billing.products.title',
        url: '/app2/#/admin/billing/your-products',
      },
      {
        label: 'tkey;admin.billing.products.detailed_usage.title',
      },
    ]);

    const billingProductsSub = this.billingProductsPollingService.state$.subscribe((jobStatus: JobStatus) => {
      if (jobStatus.error) {
        this.showCustomAlert = true;
        this.billingProductsPollingService.stopPolling();
        this.isLoadingTable = false;
      } else {
        this.showCustomAlert = false;
      }
    });
    this._subscription.add(billingProductsSub);

    const callingReportsSub = this.detailedZiroCallingReportsContext.state$.subscribe((report) => {
      this.data = this._buildData(report);
      this.isLoadingTable = false;
    });
    this._subscription.add(callingReportsSub);
  }

  isFilterInitialized(filterName: string) {
    if (filterName === 'noDialPlanFilter') {
      setTimeout(() => {
        this.noDialPlanFilter?.filterUpdate$.subscribe(() => {
          if (this.noDialPlanFilter.value) {
            this.noResultsAlert = 'tkey;admin.billing.products.detailed_usage.datatable.no_results';
            this.noResultsAlertClass = 'table-success';
          } else {
            // use the datatable defaults
            this.noResultsAlert = null;
            this.noResultsAlertClass = null;
          }
        });
      });
    } else if (filterName === 'billingProductFilter') {
      setTimeout(() => {
        if (!this.billingProductFilter?.selectedItems.length) {
          this.billingProductFilter?.onFilterChange(this.BILLING_PRODUCT_NAME_MAP.get(this.product), {
            target: { checked: true },
          } as ChangeEvent);
          this.changeDetectorRef.detectChanges();
        }
      });
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.billingProductsPollingService.stopPolling();
    this._subscription.unsubscribe();
  }

  private _buildData(report: DetailedZiroCallingReport[]): DetailedZiroCallingReportData[] {
    return report.map((entry) => {
      if (entry.zpmDialPlanGroupName && !this.dialPlanGroupNameFilterOptions.includes(entry.zpmDialPlanGroupName)) {
        this.dialPlanGroupNameFilterOptions.push(entry.zpmDialPlanGroupName);
      }
      if (entry.billingLocation && !this.billingLocationFilterOptions.includes(entry.billingLocation)) {
        this.billingLocationFilterOptions.push(entry.billingLocation);
      }
      if (entry.country && !this.countryFilterOptions.includes(entry.country)) {
        this.countryFilterOptions.push(entry.country);
      }
      if (entry.carrier && !this.carrierFilterOptions.includes(entry.carrier)) {
        this.carrierFilterOptions.push(entry.carrier);
      }
      return {
        number: entry.number,
        country: entry.country,
        carrier: entry.carrier,
        assignedUpn: entry.assignedUpn,
        pstn: this.PSTN_MAP.get(entry.pstnConnectivityType) ?? '',
        accountType: this.ACCOUNT_TYPE_NAME_MAP.get(entry.accountType) ?? '',
        dialPlanGroupName: entry.zpmDialPlanGroupName,
        billingLocation: entry.billingLocation,
        billingProduct: this.BILLING_PRODUCT_NAME_MAP.get(entry.billingProduct) ?? '',
      };
    });
  }

  isAccountTypeMatch = (filter: string[], row: DetailedZiroCallingReportData): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.accountType);
  isPstnMatch = (filter: string[], row: DetailedZiroCallingReportData): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.pstn);
  isDialPlanGroupMatch = (filter: string[], row: DetailedZiroCallingReportData): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.dialPlanGroupName);
  isNoDialPlanGroupMatch = (filter: boolean, row: DetailedZiroCallingReportData): boolean =>
    !filter || !row.dialPlanGroupName;
  isBillingLocationMatch = (filter: string[], row: DetailedZiroCallingReportData): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.billingLocation);
  isBillingProductMatch = (filter: string[], row: DetailedZiroCallingReportData): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.billingProduct);
  isTollFreeMatch = (filter: boolean, row: DetailedZiroCallingReportData): boolean => {
    if (!filter) {
      return true;
    }

    const toolFreePrefixes = ['+1800', '+1822', '+1833', '+1844', '+1855', '+1866', '+1877', '+1888'];
    const match = toolFreePrefixes.find((tollFreeNumber: string) => row.number.startsWith(tollFreeNumber));
    return !!match;
  };
  isCountryMatch = (filter: string[], row: DetailedZiroCallingReportData): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.country);
  isCarrierMatch = (filter: string[], row: DetailedZiroCallingReportData): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.carrier);
}
