<app-loading-block [hidden]="!isLoading" class="m-auto"></app-loading-block>
<div *ngIf="!isLoading">
  <div
    class="offset-md-1 col-md-10 smacs-page-alert alert border-warning"
    *ngIf="pageDisabled"
    data-automation="smacs-page-disabled-alert"
  >
    <i class="{{ smacsIcons.WARNING }} text-warning fa-4x"></i>
    <div class="ms-3" [innerHTML]="'tkey;userdetail.home.services.disabled_service.label' | translate"></div>
  </div>
  <div *ngIf="!pageDisabled">
    <app-loading-block [hidden]="isReady"></app-loading-block>
    <smacs-microsoft365-info-licenses
      *ngIf="isReady"
      [licenseConfig]="licenseConfig"
      [microsoft365UserLicenses]="userLicenses"
      [securityGroupFieldConfig]="securityGroupFieldConfig"
      [initialData]="initialData"
      [userSecurityGroups]="userSecurityGroups"
      [microsoft360View]="microsoft360View"
    ></smacs-microsoft365-info-licenses>
  </div>
</div>
