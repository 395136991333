<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item [aboutCardId]="63"></app-breadcrumb-card-item>
    </app-breadcrumbs>
    <router-outlet></router-outlet>
  </div>

  <div class="col-md-10 offset-md-1 h-100 d-flex flex-column pb-3" *ngIf="!isLoading">
    <div
      *ngIf="bulkJobStatus && bulkJobStatus.jobStatus.jobState === jobState.QUEUED"
      class="alert alert-info d-flex justify-content-between align-items-center"
      data-automation="queued-job-alert"
    >
      {{ 'tkey;automate.zpm.bulk_provisioning.previous_job.alert.queued' | translate }}
    </div>

    <div
      *ngIf="bulkJobStatus && bulkJobStatus.jobStatus.jobState === jobState.RUNNING"
      class="alert alert-info"
      data-automation="in-progress-job-alert"
    >
      <div>
        {{ 'tkey;automate.zpm.bulk_provisioning.previous_job.alert.running' | translate }}
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="progress flex-grow-1 me-2">
          <div
            class="progress-bar"
            role="progressbar"
            [attr.style]="'width: ' + getPercentComplete() + '%'"
            [attr.aria-valuemin]="'0'"
            [attr.aria-valuemax]="getTotalRows()"
            [attr.aria-valuenow]="getTotalRows()"
          ></div>
        </div>
        <div>({{ getCompletedRows() }} / {{ getTotalRows() }})</div>
      </div>
    </div>

    <ziro-zpm-bulk-provisioning-status-entity-table
      *ngIf="
        bulkJobStatus &&
        (bulkJobStatus.jobStatus.jobState === jobState.QUEUED || bulkJobStatus.jobStatus.jobState === jobState.RUNNING)
      "
      [tableRows]="tableRows"
      [isViewLoading]="isLoadingTable"
    ></ziro-zpm-bulk-provisioning-status-entity-table>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
