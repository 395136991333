import { OptimizationTile } from '../models/report-dashboard.model';
import { dashboardActions } from '../actions/dashboard.actions';
import { createReducer, on } from '@ngrx/store';

export const initialOptimizationTile: OptimizationTile = {
  totalLicenseCount: 0,
  optimizationPercent: 0,
  numCalls: 0,
  tileStatus: {
    message: 'tkey;reporting.dashboard.optimization',
    status: null,
    value: 0,
    reportUrl: '../cucm-license-optimization',
    dataAutomation: 'license-optimizer-tile',
  },
  isLoading: true,
};

export const optimizationTileReducer = createReducer(
  initialOptimizationTile,
  on(dashboardActions.loadOptimizationTileAction, (state) => ({
    ...state,
  })),
  on(dashboardActions.loadOptimizationTileSuccessAction, (state, payload) => ({
    ...state,
    totalLicenseCount: payload.totalLicenseCount,
    optimizationPercent: payload.optimizationPercent,
    numCalls: payload.numCalls,
    tileStatus: payload.tileStatus,
    isLoading: payload.isLoading,
  }))
);
