import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { of, Subscription } from 'rxjs';
import { SmacsTextComponent } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsModalService } from '../../../services/smacs-modal.service';
import { differenceWith, intersectionWith, isEqual, omit, union } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { UniqueIdService } from '../../../services/unique-id.service';
import { EditCustomSelectModalComponent } from '../../../../modals/edit-custom-select-modal/edit-custom-select-modal.component';
import { SelectConfigEntity } from '../../legacy-smacs-select-config/legacy-smacs-select-config.component';
import { ReadWriteSelectConfigService } from '../../../../admin/services/read-write-select-config.service';
import { ReadWriteConfigType } from '../smacs-read-write-select-config.component';

export interface ReadWriteSelectConfigFormData extends SelectConfigEntity {
  possibleOptionsFormatted: string;
}

@Component({
  selector: 'smacs-read-write-select-config-form',
  templateUrl: './read-write-select-config-form.component.html',
  styleUrls: ['./read-write-select-config-form.component.scss'],
  providers: [ReadWriteSelectConfigService],
})
export class ReadWriteSelectConfigFormComponent
  extends SmacsFormAbstractDirective<SelectConfigEntity, ReadWriteSelectConfigFormData>
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('possibleOptions') possibleOptionsTextComponent: SmacsTextComponent;

  @Input() displayLabel: string;
  @Input() dataAutomation: string;
  @Input() availableOptions: string[];
  @Input() disabled = false;
  @Input() disabledTooltip = '';
  @Input() helpText: string;
  @Input() type: ReadWriteConfigType;

  formConfig: SmacsFormConfig;

  private _isMultiSelect = true;
  private _subs = new Subscription();
  private _validators = {
    invalidOptionValidator(defaultValue: string, availableOptions: string[]): SmacsFormsValidationState {
      return defaultValue && !availableOptions.includes(defaultValue)
        ? SmacsFormsValidationState.INVALID
        : SmacsFormsValidationState.VALID;
    },
  };

  private static _getValues(value: string): string[] {
    return value.split(',');
  }

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private uniqueIdService: UniqueIdService,
    private readWriteSelectConfigService: ReadWriteSelectConfigService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initFormConfig();
    const sub = this.smacsFormsUpdate$.subscribe((update: SmacsFormsUpdate<SelectConfigEntity>) => {
      if (
        !isEqual(update.new.show, update.old.show) ||
        !isEqual(update.new.required, update.old.required) ||
        update.valid === SmacsFormsValidationState.INVALID
      ) {
        const formData = this.toFormData(update.new);
        this.possibleOptionsTextComponent.updateSelf(formData.possibleOptionsFormatted, true);
      }
    });
    this._subs.add(sub);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.oneClickEnabled) {
      this._validateAllFields();
    }
    if (changes.disabled && this.possibleOptionsTextComponent) {
      this.possibleOptionsTextComponent.applyState({
        ...this.possibleOptionsTextComponent.state,
        disabled: changes.disabled.currentValue,
      });
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  openModal() {
    if (this.disabled) {
      return;
    }
    // Get the options not part of defaultValues.
    const formattedPossibleOptions = differenceWith(this.entity.possibleOptions, this.entity.defaultValues, isEqual);
    const modalInstance = this.smacsModalService.openCustomModal(
      EditCustomSelectModalComponent,
      {
        configuredOptions: [...formattedPossibleOptions],
        availableOptions: [...this.availableOptions],
        defaultOptions: [...this.entity.defaultValues],
        modalHeaderKey: this.displayLabel,
        isMultiSelect: this._isMultiSelect,
        isPreviewVisible: false,
        translations: this.readWriteSelectConfigService.getTranslations(this.type),
      },
      'xl'
    );

    modalInstance.subscribe((newValues: { configuredOptions: string[]; defaultOptions: string[] }) => {
      if (newValues) {
        this.entitySource.next({
          ...this.entity,
          defaultValue: newValues.defaultOptions[0],
          defaultValues: newValues.defaultOptions,
          possibleOptions: [...newValues.defaultOptions, ...newValues.configuredOptions],
        });

        this._validateAllFields();
      }
    });
  }

  arrayToString(values: string[]): string {
    return values ? values.join(', ') : '';
  }

  getDisabledState(): boolean {
    return this.disabled;
  }

  onInputClicked($event: boolean) {
    if ($event && !this.getDisabledState()) {
      this.openModal();
    }
  }

  protected toEntity = (formData: ReadWriteSelectConfigFormData): SelectConfigEntity => {
    return {
      ...omit(formData, ['possibleOptionsFormatted']),
    } as SelectConfigEntity;
  };

  protected toFormData = (entity: SelectConfigEntity): ReadWriteSelectConfigFormData => {
    // check if a defaultValue is not present in possibleOptions. (When misconfigured values are present, I guess)
    const valuesNotInPossibleOptions = differenceWith(entity.defaultValues, entity.possibleOptions);
    const configuredOptions =
      valuesNotInPossibleOptions.length > 0
        ? [...valuesNotInPossibleOptions, ...entity.possibleOptions]
        : [...entity.possibleOptions];
    return {
      ...entity,
      possibleOptionsFormatted: this.arrayToString(configuredOptions),
    } as ReadWriteSelectConfigFormData;
  };

  protected submit() {
    return of(null);
  }

  private _getLabel(): string {
    if (this.formData.required) {
      return `<span class="text-danger smacs-forms-asterisk">*</span> ${this.translateService.instant(
        this.displayLabel
      )}`;
    } else {
      return this.displayLabel;
    }
  }

  private _getSelectOptionsValidation(selectValues: string[], validationTarget: string): string[] {
    return selectValues.filter((val: string) => {
      const validationState = this._validators.invalidOptionValidator(val, this.availableOptions);
      return validationTarget === 'VALID'
        ? validationState === SmacsFormsValidationState.VALID
        : validationState === SmacsFormsValidationState.INVALID;
    });
  }

  private _initFormConfig() {
    const id = this.uniqueIdService.getUniqueId();

    this.formConfig = {
      fields: {
        possibleOptionsFormatted: {
          id: `select-config-form-possible-options-${id}`,
          dataAutomation: `select-config-form-possible-options`,
          label: () => this._getLabel(),
          required: () => false,
          validation: [
            {
              validator: () => {
                const formValuesMulti = union([...this.formData.defaultValues, ...this.formData.possibleOptions]);
                return this._getSelectOptionsValidation(formValuesMulti, 'INVALID').length
                  ? SmacsFormsValidationState.INVALID
                  : SmacsFormsValidationState.VALID;
              },
              message: () => {
                const formValuesMulti = union([...this.formData.defaultValues, ...this.formData.possibleOptions]);
                const invalidOptions = this._getSelectOptionsValidation(formValuesMulti, 'INVALID');
                const translation = this.translateService.instant('tkey;select.invalid_options.label');

                return `${translation} ${this.arrayToString(invalidOptions as string[])}`;
              },
            },
            {
              validator: (value) => {
                const valuesArray = ReadWriteSelectConfigFormComponent._getValues(value).filter((v: string) => !!v);

                if (valuesArray.length < 1) {
                  return SmacsFormsValidationState.WARNING;
                }

                return SmacsFormsValidationState.VALID;
              },
              message: 'tkey;admin.microsoft.license_management.licenses.validation.warning',
            },
          ],
          autogeneration: {
            linkLabel: 'tkey;shared.html.customizable_fields.text_input.fix_it.label',
            generateValue: (fieldValue) => {
              const validationStateSingleSelect =
                this._validators.invalidOptionValidator(this.formData.defaultValue, this.availableOptions) ===
                SmacsFormsValidationState.VALID;
              const defaultValues = this._isMultiSelect
                ? this._getSelectOptionsValidation(this.formData.defaultValues, 'VALID')
                : [];
              const defaultValue = this._isMultiSelect
                ? defaultValues[0]
                : validationStateSingleSelect
                ? this.formData.defaultValue
                : '';
              const possibleOptions = this._getSelectOptionsValidation(this.formData.possibleOptions, 'VALID');

              this.entitySource.next({
                ...this.formData,
                defaultValues: defaultValues,
                defaultValue: defaultValue,
                possibleOptions: intersectionWith(possibleOptions, this.availableOptions),
              });

              return fieldValue;
            },
            hidden: () => {
              const invalidPossibleOptions = this._getSelectOptionsValidation(this.formData.possibleOptions, 'INVALID');
              if (this._isMultiSelect) {
                const invalidDefaultValues = this._getSelectOptionsValidation(this.formData.defaultValues, 'INVALID');

                return invalidDefaultValues.length === 0 && invalidPossibleOptions.length === 0;
              } else {
                const validationState = this._validators.invalidOptionValidator(
                  this.formData.defaultValue,
                  this.availableOptions
                );

                return validationState !== SmacsFormsValidationState.INVALID && invalidPossibleOptions.length === 0;
              }
            },
            inline: true,
          },
          disabled: () => this.getDisabledState(),
          disabledTooltip: this.disabledTooltip,
        },
      },
    };
  }

  private _validateAllFields() {
    Object.keys(this.fieldChannels).forEach((key: string) => {
      this.fieldChannels[key].validateSource.next();
    });
  }
}
