<div
  class="tile tile-info edit-service-tile animated flipInY fast"
  data-automation="edit-service-tile"
  [ngClass]="{ muted: (!isEnabled && icon !== 'icon-outline-dubber') || isDevice }"
  [ngStyle]="{ cursor: isDevice ? 'not-allowed' : 'pointer' }"
  (mouseenter)="isHoverTile = true"
  (mouseleave)="isHoverTile = false"
  [ngbTooltip]="getTooltip() | translate"
  #tileTooltip="ngbTooltip"
>
  <div class="tile-header">
    <span *ngIf="isHoverTile">
      <span
        data-automation="add-deskphone-button"
        class="add-deskphone-button me-1"
        *ngIf="isDeskPhone && !hasMultipleDeskPhones && isEnabled"
        (mouseenter)="isHoverAddDeskphone = true"
        (mouseleave)="isHoverAddDeskphone = false"
        (click)="onDeskPhoneAddClicked()"
      >
        <i class="fa fa-plus" [ngClass]="{ 'fa-lg animated rubberBand fast text-primary': isHoverAddDeskphone }"></i>
      </span>
      <span
        class="copy-deskphone-button me-1"
        *ngIf="isDeskPhone && isEnabled"
        (mouseenter)="isHoverCopyDeskphone = true"
        (mouseleave)="isHoverCopyDeskphone = false"
        (click)="onDeskPhoneCopyClicked()"
      >
        <i class="icon-copy" [ngClass]="{ 'fa-lg animated rubberBand fast text-warning': isHoverCopyDeskphone }"></i>
      </span>
      <span
        class="smacs-tile-exchange me-1"
        *ngIf="isExchangeShown && isEnabled"
        [ngClass]="{ 'tooltip-wrapper-disabled': !isExchangeEnabled }"
        (mouseenter)="isHoverExchange = true"
        (mouseleave)="isHoverExchange = false"
        (click)="onExchangeClicked()"
      >
        <i class="fa fa-exchange" *ngIf="!isHoverExchange || (isHoverExchange && !isExchangeEnabled)"></i>
        <i
          class="fa fa-exchange fa-lg animated rubberBand fast text-primary"
          *ngIf="isHoverExchange && isExchangeEnabled"
        ></i>
      </span>
      <span
        [ngClass]="{ 'tooltip-wrapper-disabled': !isDeleteEnabled }"
        class="smacs-tile-trash"
        *ngIf="isDeleteShown"
        (mouseenter)="isHoverDelete = true; tileTooltip.close()"
        (mouseleave)="isHoverDelete = false; tileTooltip.open()"
        (click)="onDeleteClicked()"
        [ngbTooltip]="deleteTooltip | translate"
        [tooltipClass]="'tile-delete-tooltip'"
        container="body"
      >
        <i class="fa-regular fa-trash-can" *ngIf="!isHoverDelete"></i>
        <i class="fa-solid fa-trash-can fa-lg icon-danger animated rubberBand fast" *ngIf="isHoverDelete"></i>
      </span>
    </span>

    <span
      style="cursor: pointer"
      [ngClass]="{
        'status-icon': deviceIconMarkup.includes('icon-ok') || deviceIconMarkup.includes('fa-ban')
      }"
      *ngIf="isDevice"
      [ngbTooltip]="deviceToolip | translate"
      container="body"
      [innerHtml]="deviceIconMarkup"
    ></span>

    <span *ngIf="!isHoverTile && !isDeskPhone && !isDevice" class="status-icon fa-stack">
      <i class="icon-circle fa-stack-2x icon-success"></i>
      <i class="icon-ok fa-stack-1x"></i>
    </span>

    <div *ngIf="!isHoverTile && isDeskPhone && !isDevice" data-automation="phone-status-wrapper">
      <span
        *ngIf="phoneStatus === deviceRegistrationStatus.REGISTERED"
        data-automation="phone-status-success-icon"
        class="status-icon fa-stack"
      >
        <i class="icon-circle fa-stack-2x icon-success"></i>
        <i class="icon-ok fa-stack-1x"></i>
      </span>
      <span
        *ngIf="
          phoneStatusAttemptCount <= ATTEMPTS_COUNT &&
          (phoneStatus === deviceRegistrationStatus.NOT_FOUND || phoneStatus === deviceRegistrationStatus.UNREGISTERED)
        "
        data-automation="phone-status-warning-icon"
        class="status-icon fa-stack"
      >
        <i class="icon-warning-triangle icon-warning fa-stack-2x"></i>
      </span>
      <span
        *ngIf="
          phoneStatusAttemptCount > ATTEMPTS_COUNT &&
          (phoneStatus === deviceRegistrationStatus.NOT_FOUND || phoneStatus === deviceRegistrationStatus.UNREGISTERED)
        "
        data-automation="phone-status-status-text"
        class="badge bg-warning badge--phone-status"
      >
        {{ phoneStatus | phoneStatusFilter }}
      </span>
    </div>
  </div>

  <div
    [ngClass]="{ muted: (!isEnabled && icon !== 'icon-outline-dubber') || isDevice }"
    (click)="onEditTileClicked()"
    class="tile-body"
  >
    <div class="tile-description">
      <div class="text-center">
        <p [ngClass]="'svg-icon-class'">
          <svg-icon [attr.data-automation]="icon" [name]="icon" [svgStyle]="{ 'width.px': 40 }"></svg-icon>
        </p>
        <div
          data-automation="service-tile-name"
          class="user-detail-tile-service-name"
          [ngClass]="{
            strong: !isDevice
          }"
        >
          {{ serviceName | translate }}
        </div>
        <div
          data-automation="service-tile-description"
          class="user-detail-tile-description"
          [ngClass]="{
            strong: isDevice
          }"
        >
          {{ description | translate }}
        </div>
        <i class="icon-edit fa-2x text-primary" *ngIf="isEnabled && isHoverTile && !isDevice"></i>
      </div>
    </div>
  </div>
</div>
