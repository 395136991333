import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { SystemStatusContext } from '../../../shared/contexts/system-status.context';
import { combineLatest, EMPTY, Observable, Subscription, throwError } from 'rxjs';
import { LicenseContext } from '../../../shared/contexts/license.context';
import {
  CucmLicenseCount,
  HealthStatus,
  LicenseInfo,
  State,
  StatusCategory,
  SystemHealthStatus,
} from '../../../shared/models/generated/smacsModels';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { SmacsFileUploadConfig } from '../../../forms/fields/file-upload/smacs-file-upload.component';
import { SmacsFormConfig } from '../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { GlobalPropertiesContext } from '../../../shared/contexts/global-properties.context';
import { CustomUiOptionsContext } from '../../../shared/contexts/custom-ui-options.context';

interface LicenseStatus {
  title: string;
  content: string;
}

interface AdminLicensePayload {
  licenseFileUpload: File;
}

@Component({
  selector: 'app-admin-license',
  templateUrl: './admin-license.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class AdminLicenseComponent
  extends SmacsFormAbstractDirective<AdminLicensePayload>
  implements OnInit, OnDestroy
{
  isLoading = true;
  isImporting = false;
  licenseInfo: LicenseInfo;
  hasSubmitted = false;
  licenseStatusCause = '';
  licenseStatusMessage = {} as LicenseStatus;
  licenseHealthStatus: HealthStatus;
  smacsIcons = SmacsIcons;
  state = State;
  cucmLicenseUsage: CucmLicenseCount[] = [];
  systemHealthStatus: SystemHealthStatus;
  isHostedEnabled: boolean;

  private _subscriptions = new Subscription();

  formConfig = {
    fields: {
      licenseFileUpload: {
        label: 'tkey;admin.license.filename.label',
        required: true,
        dataAutomation: 'license-import-file-path-text',
        componentConfig: new SmacsFileUploadConfig({
          acceptedFileExtensions: { acceptedExtensions: '.xml' },
          maxSize: 51200,
        }),
      },
    },
    options: {
      columnClasses: {
        label: 'col-lg-2 text-lg-end',
        input: 'col-lg-10',
      },
    },
  } as SmacsFormConfig;

  constructor(
    private bottomNavService: BottomNavService,
    private toastService: ToastService,
    private systemStatusContext: SystemStatusContext,
    private licenseContext: LicenseContext,
    private customUiOptionsContext: CustomUiOptionsContext,
    protected smacsFormStateService: SmacsFormStateService,
    private breadcrumbsService: BreadcrumbsService,
    private http: HttpClient,
    private globalPropertiesContext: GlobalPropertiesContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.license.header' }]);
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'importLicenseUpdate',
          dataAutomation: 'import-license-update',
          label: 'tkey;pages.admin.import.bottom_nav.import.label',
          icon: this.smacsIcons.IMPORT,
          buttonClass: ButtonStyles.PRIMARY,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );

    const subscription = combineLatest([
      this.systemStatusContext.state$,
      this.licenseContext.licenseState$,
      this.globalPropertiesContext.state$,
    ]).subscribe(([systemStatus, licenseInfo, globalPropertyInfo]) => {
      if (systemStatus) {
        this.systemHealthStatus = systemStatus;
        this._setErrorMessage(systemStatus);
      }

      this.isHostedEnabled = globalPropertyInfo.hostedEnabled;
      this.licenseInfo = licenseInfo;
      this.licenseHealthStatus = this.systemHealthStatus.healthStatuses.find(
        (healthStatus: HealthStatus) => healthStatus.category === StatusCategory.LICENSES
      );

      this.cucmLicenseUsage = licenseInfo?.consumedCucmLicenseCountBreakdown
        ? licenseInfo?.consumedCucmLicenseCountBreakdown
        : [];
      this.isLoading = false;
      if (this.hasSubmitted) {
        this.hasSubmitted = false;
        this.isImporting = false;
        this._setPending(false);
        this._validateAndSubmitSource.next(false);
        this.smacsFormStateService.setIsFormDirty(false);
        this.toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.DOWNLOAD,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.license.toast.success.message'
        );
      }
    });
    this._subscriptions.add(subscription);

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });
    this._subscriptions.add(formSubmittedSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }

  trackByName = (i: number, ciscoUsage: CucmLicenseCount): string => {
    return ciscoUsage.cucmDescription;
  };

  protected submit() {
    return this._onImportClicked();
  }

  private _setPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'importLicenseUpdate',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );

    this.isImporting = setting;
  }

  private _onImportClicked(): Observable<void> {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: !this.formData.licenseFileUpload,
      })
    );

    this.hasSubmitted = true;

    if (this.formData.licenseFileUpload) {
      this.isImporting = true;

      this._setPending(true);

      return this._postLicenseFile(this.formData.licenseFileUpload).pipe(
        tap(),
        catchError((response) => {
          this.isImporting = false;
          this._setPending(false);
          if (response?.error?.description === 'License is not valid') {
            this.toastService.push(
              ToastTypes.ERROR,
              this.smacsIcons.DOWNLOAD,
              'tkey;shared.toast.save.fail.title',
              'tkey;admin.license.toast.invalid.message'
            );
            return throwError(() => EMPTY);
          } else {
            return throwError(() => response);
          }
        })
      );
    }
  }

  private _postLicenseFile(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('upload', file);
    return this.http.post('/services/cisco/admin/licenses/file', formData).pipe(
      mergeMap(() => this.systemStatusContext.refreshSystemStatus()),
      mergeMap(() => this.licenseContext.refreshLicenseInfo()),
      mergeMap(() => this.customUiOptionsContext.refresh())
    );
  }

  private _setErrorMessage(systemStatusAlertState: SystemHealthStatus) {
    this.licenseStatusCause = systemStatusAlertState.healthStatuses.find(
      (status) => status.category === StatusCategory.LICENSES
    )?.cause;
    switch (this.licenseStatusCause) {
      case 'No license installed': {
        this.licenseStatusMessage = {
          title: 'tkey;admin.license.missing_license.title',
          content: 'tkey;admin.license.missing_license.text',
        };
        break;
      }
      case 'License is expired': {
        this.licenseStatusMessage = {
          title: 'tkey;admin.license.expired_license.title',
          content: 'tkey;admin.license.expired_license.text',
        };
        break;
      }
      case 'Exceeded number of licenses': {
        this.licenseStatusMessage = {
          title: 'tkey;admin.license.overused_license.title',
          content: 'tkey;admin.license.overused_license.text',
        };
        break;
      }
      default: {
        this.licenseStatusMessage = {
          title: '',
          content: '',
        };
      }
    }
  }
}
