import { Component, Input } from '@angular/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[smacs-license-module-row]',
  templateUrl: './license-module-row.component.html',
})
export class LicenseModuleRowComponent {
  @Input() moduleEnabled: boolean;
  @Input() moduleName: string;
}
