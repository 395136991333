<smacs-select
  fieldId="extensionSearch"
  (selectBlur)="handleOnBlur()"
  (selectWasCleared)="handleSelectClear()"
  (searchHasChanged)="handleInputChange($event)"
>
  <div style="min-height: 1.5em" *ngIf="!isSingleButton || phoneType === phoneTypes.DESKPHONE">
    <small
      class="text-muted clickable-cursor"
      data-automation="dnd-line-extension-search-modal-button"
      *ngIf="!hideHelpText && formData.extensionSearch"
    >
      <i class="icon-edit me-1 text-primary"></i>
      <span data-automation="edit-extension-link" class="text-primary" (click)="openDetailedModal()">
        {{
          'tkey;shared.device.phone.phone_buttons.line_button.line_extension.search.helptext.existing.text' | translate
        }}
        - {{ dnRef.description }} {{ dnRef.extension }}
      </span>
    </small>
    <small class="text-muted" *ngIf="!hideHelpText && !formData.extensionSearch">
      {{ 'tkey;shared.device.phone.phone_buttons.line_button.line_extension.search.helptext.new.text' | translate }}
      <span class="text-primary clickable-cursor" (click)="openDetailedModal()" data-automation="add-extension-link">
        {{ 'tkey;shared.device.phone.phone_buttons.line_button.line_extension.search.helptext.new.link' | translate }}
      </span>
    </small>
  </div>
</smacs-select>
<smacs-text [fieldId]="'voicemail'"></smacs-text>
<div class="row mb-3" *ngIf="dnRef && unityServerId">
  <div class="col-3 d-flex align-items-center justify-content-end">
    <label class="m-0">
      {{ 'tkey;shared.device.phone.phone_buttons.line_button.line_extension.search.voicemail_alias.label' | translate }}
    </label>
  </div>
  <div class="col-9" [ngClass]="{ 'text-start': isSingleButton }">
    <button
      (click)="
        isEndUserPrimary() && isOneClickEnabled && !voicemailRef
          ? addVoicemailOneClick()
          : voicemailRef
          ? openAddOrEditVmDetailedModal(voicemailRef)
          : openAddOrEditVmDetailedModal()
      "
      [ngClass]="
        (isVoicemailAdding || isVoicemailBeingFetched) && !voicemailRef ? 'btn btn-warning' : 'btn btn-default'
      "
      data-automation="dnd-line-extension-voicemail-add-button"
      [disabled]="isVoicemailAdding || isSubmitting"
    >
      <i
        [ngClass]="{
          'icon-add': !isVoicemailAdding && !voicemailRef,
          'icon-saving': (isVoicemailAdding || isVoicemailBeingFetched) && !voicemailRef
        }"
      ></i>
      <span
        *ngIf="!isVoicemailBeingFetched && !voicemailRef"
        [ngClass]="{ 'ms-1': isVoicemailAdding }"
        data-automation="dnd-line-extension-voicemail-add-text"
      >
        {{
          isVoicemailAdding
            ? ('tkey;shared.device.phone.phone_buttons.line_button.line_extension.search.voicemail_adding.text'
              | translate)
            : ('tkey;shared.device.phone.phone_buttons.line_button.line_extension.search.voicemail_add.text'
              | translate)
        }}
      </span>
      <i *ngIf="voicemailRef" class="icon-search-voicemail"></i>
      <span class="ms-1" *ngIf="voicemailRef" data-automation="dnd-line-extension-voicemail-alias">
        {{ voicemailRef.alias }}
      </span>
    </button>
  </div>
</div>
