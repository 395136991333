import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { UiOptions } from '../models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomUiOptionsContext {
  _stateSource = new ReplaySubject<UiOptions>(1);
  state$ = this._stateSource.asObservable();

  constructor(private http: HttpClient) {
    this.refresh().subscribe();
  }

  refresh(): Observable<void> {
    return this._get().pipe(
      map((uiOptions) => {
        this._stateSource.next(uiOptions);
      })
    );
  }

  private _get(): Observable<UiOptions> {
    return this.http.get<UiOptions>('/services/system/info/ui-options');
  }
}
