import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';

@Injectable()
export class DateTimeService {
  constructor(private translateService: TranslateService) {}

  parse(value: string) {
    switch (this.translateService.currentLang) {
      case 'fr':
        return moment(value).locale('fr-ca');
      case 'es':
        return moment(value).locale('es-mx');
      default:
        return moment(value).locale('en');
    }
  }

  formatDate(value: string) {
    return this.parse(value).format('L');
  }

  formatDateTime(value: string) {
    return this.parse(value).format('L LT');
  }

  formatTime(value: string) {
    return this.parse(value).format('LT');
  }
}
