<div class="d-flex">
  <smacs-select class="flex-fill me-2" [fieldId]="'role'"></smacs-select>
  <div class="d-flex align-items-center mb-3">
    <smacs-submit-button
      [label]="'tkey;admin.system.user_permissions.form.add_user.label'"
      [dataAutomation]="'user-permissions-search-form-add-user-button'"
      [formSubmitSubject]="_validateAndSubmitSource"
      [icon]="smacsIcons.ADD"
      [isDisabled]="!!buttonTooltip"
      [isTooltipDisabled]="!buttonTooltip || !tooltipEnabled"
      [tooltip]="buttonTooltip"
    ></smacs-submit-button>
  </div>
</div>
