import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ButtonStyles } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-saml-export-btn',
  templateUrl: './saml-export-button.component.html',
})
export class SamlExportButtonComponent {
  @Input() event: () => Observable<void>;
  @Input() label = '';
  @Input() isDisabled = false;
  @Input() dataAutomation = '';
  @Input() buttonStyle = ButtonStyles.PRIMARY;
  @Input() pendingStyle = ButtonStyles.WARNING;
  @Input() icon = SmacsIcons.EXPORT;
  @Input() iconPending = SmacsIcons.SAVING;

  isPending = false;

  onButtonClick() {
    this.isPending = true;
    this.event().subscribe({
      next: () => {
        this.isPending = false;
      },
      error: (error) => {
        this.isPending = false;
        throw new HttpErrorResponse(error);
      },
    });
  }
}
