import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import {
  CallForward,
  SelectFieldConfig,
  SiteSummary,
  TextFieldConfig
} from '../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import {
  CallForwardField,
  SmacsCallForwardComponent,
  SmacsCallForwardConfig
} from '../../call-forward/call-forward.component';
import { of } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

export interface DnCallForwardForm {
  secondaryClassOfServiceForForwardAll: string;
  noAnswerRingDuration: string;
  forwardAll: CallForward;
  forwardBusyInternal: CallForward;
  forwardBusyExternal: CallForward;
  forwardNoAnswerInternal: CallForward;
  forwardNoAnswerExternal: CallForward;
  forwardNoCoverageInternal: CallForward;
  forwardNoCoverageExternal: CallForward;
  forwardUnregisteredInternal: CallForward;
  forwardUnregisteredExternal: CallForward;
  forwardOnCtiFailure: CallForward;
}

export interface DnCallForwardFormData {
  forwardAllCss: string;
  secondaryClassOfServiceForForwardAll: string;
  noAnswerRingDuration: string;
  all: CallForwardField;
  busy: CallForwardField;
  noAnswer: CallForwardField;
}

export interface DnCallForwardFieldConfig {
  forwardAllCss: SelectFieldConfig<string>;
  secondaryClassOfServiceForForwardAll: SelectFieldConfig<string>;
  noAnswerRingDuration: TextFieldConfig;
}
@Component({
  selector: 'smacs-directory-number-call-forward-form',
  templateUrl: './directory-number-call-forward-form.component.html',
})
export class DirectoryNumberCallForwardFormComponent
  extends SmacsFormAbstractDirective<DnCallForwardForm, DnCallForwardFormData>
  implements OnInit
{
  @ViewChildren(SmacsCallForwardComponent) callForwardComponents: QueryList<SmacsCallForwardComponent>;
  @Input() fieldConfig: DnCallForwardFieldConfig;
  @Input() hasVoicemail: boolean;
  @Input() siteId: string;
  @Input() siteSummary: SiteSummary;

  formConfig: SmacsFormConfig;


  private initialEntity: DnCallForwardForm;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private siteSummaryContext: SiteSummaryContext,
    private translateService: TranslateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this.initialEntity = cloneDeep(this.entity);
    const isUnityServerConfigured = !!this.siteSummaryContext.getSiteFromId(this.siteSummary, Number(this.siteId))
      .unityServerId;

    this.formConfig = {
      fields: {
        forwardAllCss: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.classOfService.label',
          dataAutomation: 'dn-call-forward-class-of-service',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig.forwardAllCss.possibleOptions }),
          defaultValue: () => this.fieldConfig.forwardAllCss.defaultValue,
          hidden: () => !this.fieldConfig.forwardAllCss.show,
          required: () => this.fieldConfig.forwardAllCss.required,
        },
        secondaryClassOfServiceForForwardAll: {
          label: 'tkey;shared.dnWizard.dnDetails.dnCallForward.secondaryClassOfService.label',
          dataAutomation: 'dn-call-forward-secondary-class-of-service',
          componentConfig: new SmacsSelectConfig({
            options: this.fieldConfig.secondaryClassOfServiceForForwardAll.possibleOptions,
          }),
          defaultValue: () => this.fieldConfig.secondaryClassOfServiceForForwardAll.defaultValue,
          hidden: () => !this.fieldConfig.secondaryClassOfServiceForForwardAll.show,
          required: () => this.fieldConfig.secondaryClassOfServiceForForwardAll.required,
        },
        noAnswerRingDuration: {
          label: 'tkey;shared.dnWizard.dnDetails.dnExtensionDetails.no_answer_ring_duration.label',
          dataAutomation: 'dn-call-forward-no-answer-ring-duration',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
          defaultValue: () => this.fieldConfig.noAnswerRingDuration.defaultValue,
          hidden: () => !this.fieldConfig.noAnswerRingDuration.show,
          required: () => this.fieldConfig.noAnswerRingDuration.required,
          validation: [
            {
              validator: (val: number) =>
                !val || val >= 1 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
              message: this.translateService.instant('tkey;validators.global.error.min', {
                minValue: 1,
              }),
            },
            {
              validator: (val: number) =>
                !val || val <= 300 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
              message: this.translateService.instant('tkey;validators.global.error.max', {
                maxValue: 300,
              }),
            },
          ],
        },
        all: {
          dataAutomation: 'call-forward-all-select',
          required: false,
          label: 'tkey;shared.dnWizard.dnDetails.dnCallForward.all.label',
          componentConfig: new SmacsCallForwardConfig({
            hasVoicemail: this.hasVoicemail,
            autoForwardToVoicemail: false,
            isUnityServerConfigured,
          }),
        },
        busy: {
          dataAutomation: 'call-forward-busy-select',
          required: false,
          label: 'tkey;shared.dnWizard.dnDetails.dnCallForward.destinationBusy.label',
          componentConfig: new SmacsCallForwardConfig({
            hasVoicemail: this.hasVoicemail,
            autoForwardToVoicemail: true,
            isUnityServerConfigured,
          }),
        },
        noAnswer: {
          dataAutomation: 'call-forward-no-answer-select',
          required: false,
          label: 'tkey;shared.dnWizard.dnDetails.dnCallForward.noAnswer.label',
          componentConfig: new SmacsCallForwardConfig({
            hasVoicemail: this.hasVoicemail,
            autoForwardToVoicemail: true,
            isUnityServerConfigured,
          }),
        },
      },
    };
  }

  toFormData = (entity: DnCallForwardForm): DnCallForwardFormData => {
    return {
      forwardAllCss: entity.forwardAll.classOfService,
      secondaryClassOfServiceForForwardAll: entity.secondaryClassOfServiceForForwardAll,
      all: { destination: entity.forwardAll.destination, forwardToVoicemail: entity.forwardAll.forwardToVoicemail },
      busy: {
        destination: entity.forwardBusyExternal.destination,
        forwardToVoicemail: entity.forwardBusyExternal.forwardToVoicemail,
      },
      noAnswer: {
        destination: entity.forwardNoAnswerExternal.destination,
        forwardToVoicemail: entity.forwardNoAnswerExternal.forwardToVoicemail,
      },
      noAnswerRingDuration: entity.noAnswerRingDuration,
    };
  };

  toEntity = (formData: DnCallForwardFormData): DnCallForwardForm => {
    const newEntity = cloneDeep(this.entity);
    newEntity.noAnswerRingDuration = formData.noAnswerRingDuration;
    newEntity.secondaryClassOfServiceForForwardAll = formData.secondaryClassOfServiceForForwardAll;
    newEntity.forwardAll = {
      classOfService: formData.forwardAllCss,
      destination: formData.all?.destination,
      forwardToVoicemail: formData.all?.forwardToVoicemail,
    };

    // Busy
    newEntity.forwardBusyExternal = {
      classOfService: formData.forwardAllCss === this.initialEntity.forwardBusyExternal.classOfService || !this.initialEntity.forwardBusyExternal.classOfService || this.initialEntity.forwardAll.classOfService === this.initialEntity.forwardBusyExternal.classOfService ? formData.forwardAllCss : this.initialEntity.forwardBusyExternal.classOfService,
      destination: formData.busy?.destination,
      forwardToVoicemail: formData.busy?.forwardToVoicemail,
    };

    newEntity.forwardBusyInternal = {
      classOfService: formData.forwardAllCss === this.initialEntity.forwardBusyInternal.classOfService || !this.initialEntity.forwardBusyInternal.classOfService || this.initialEntity.forwardAll.classOfService === this.initialEntity.forwardBusyInternal.classOfService ? formData.forwardAllCss : this.initialEntity.forwardBusyInternal.classOfService,
      destination: formData.busy?.destination === this.initialEntity.forwardBusyInternal.destination || !this.initialEntity.forwardBusyInternal.destination || this.initialEntity.forwardBusyExternal.destination === this.initialEntity.forwardBusyInternal.destination? formData.busy?.destination : this.initialEntity.forwardBusyInternal.destination,
      forwardToVoicemail: formData.busy?.forwardToVoicemail,
    };

    // No Answer
    newEntity.forwardNoAnswerExternal = {
      classOfService: formData.forwardAllCss === this.initialEntity.forwardNoAnswerExternal.classOfService || !this.initialEntity.forwardNoAnswerExternal.classOfService || this.initialEntity.forwardAll.classOfService == this.initialEntity.forwardNoAnswerExternal.classOfService ? formData.forwardAllCss : this.initialEntity.forwardNoAnswerExternal.classOfService,
      destination: formData.noAnswer?.destination,
      forwardToVoicemail: formData.noAnswer?.forwardToVoicemail,
    };

    newEntity.forwardNoAnswerInternal = {
      classOfService: formData.forwardAllCss === this.initialEntity.forwardNoAnswerInternal.classOfService || !this.initialEntity.forwardNoAnswerInternal.classOfService || this.initialEntity.forwardAll.classOfService === this.initialEntity.forwardNoAnswerInternal.classOfService ? formData.forwardAllCss : this.initialEntity.forwardNoAnswerInternal.classOfService,
      destination: formData.noAnswer?.destination === this.initialEntity.forwardNoAnswerInternal.destination || !this.initialEntity.forwardNoAnswerInternal.destination || this.initialEntity.forwardNoAnswerExternal.destination === this.initialEntity.forwardNoAnswerInternal.destination ? formData.noAnswer?.destination : this.initialEntity.forwardNoAnswerInternal.destination,
      forwardToVoicemail: formData.noAnswer?.forwardToVoicemail,
    };

    newEntity.forwardNoCoverageInternal = {
      classOfService: formData.forwardAllCss === this.initialEntity.forwardNoCoverageInternal.classOfService || !this.initialEntity.forwardNoCoverageInternal.classOfService || this.initialEntity.forwardAll.classOfService === this.initialEntity.forwardNoCoverageInternal.classOfService ? formData.forwardAllCss : this.initialEntity.forwardNoCoverageInternal.classOfService,
      destination: formData.noAnswer?.destination === this.initialEntity.forwardNoCoverageInternal.destination || !this.initialEntity.forwardNoCoverageInternal.destination || this.initialEntity.forwardNoAnswerExternal.destination === this.initialEntity.forwardNoCoverageInternal.destination ? formData.noAnswer?.destination : this.initialEntity.forwardNoCoverageInternal.destination,
      forwardToVoicemail: formData.noAnswer?.forwardToVoicemail,
    };

    newEntity.forwardNoCoverageExternal = {
      classOfService: formData.forwardAllCss === this.initialEntity.forwardNoCoverageExternal.classOfService || !this.initialEntity.forwardNoCoverageExternal.classOfService || this.initialEntity.forwardAll.classOfService === this.initialEntity.forwardNoCoverageExternal.classOfService ? formData.forwardAllCss : this.initialEntity.forwardNoCoverageExternal.classOfService,
      destination: formData.noAnswer?.destination === this.initialEntity.forwardNoCoverageExternal.destination || !this.initialEntity.forwardNoCoverageExternal.destination || this.initialEntity.forwardNoAnswerExternal.destination === this.initialEntity.forwardNoCoverageExternal.destination ? formData.noAnswer?.destination : this.initialEntity.forwardNoCoverageExternal.destination,
      forwardToVoicemail: formData.noAnswer?.forwardToVoicemail,
    };

    newEntity.forwardUnregisteredInternal = {
      classOfService: formData.forwardAllCss === this.initialEntity.forwardUnregisteredInternal.classOfService || !this.initialEntity.forwardUnregisteredInternal.classOfService || this.initialEntity.forwardAll.classOfService === this.initialEntity.forwardUnregisteredInternal.classOfService ? formData.forwardAllCss : this.initialEntity.forwardUnregisteredInternal.classOfService,
      destination: formData.noAnswer?.destination === this.initialEntity.forwardUnregisteredInternal.destination || !this.initialEntity.forwardUnregisteredInternal.destination || this.initialEntity.forwardNoAnswerExternal.destination === this.initialEntity.forwardUnregisteredInternal.destination ? formData.noAnswer?.destination : this.initialEntity.forwardUnregisteredInternal.destination,
      forwardToVoicemail: formData.noAnswer?.forwardToVoicemail,
    };

    newEntity.forwardUnregisteredExternal = {
      classOfService: formData.forwardAllCss === this.initialEntity.forwardUnregisteredExternal.classOfService || !this.initialEntity.forwardUnregisteredExternal.classOfService || this.initialEntity.forwardAll.classOfService === this.initialEntity.forwardUnregisteredExternal.classOfService ? formData.forwardAllCss : this.initialEntity.forwardUnregisteredExternal.classOfService,
      destination: formData.noAnswer?.destination === this.initialEntity.forwardUnregisteredExternal.destination || !this.initialEntity.forwardUnregisteredExternal.destination || this.initialEntity.forwardNoAnswerExternal.destination === this.initialEntity.forwardUnregisteredExternal.destination ? formData.noAnswer?.destination : this.initialEntity.forwardUnregisteredExternal.destination,
      forwardToVoicemail: formData.noAnswer?.forwardToVoicemail,
    };

    newEntity.forwardOnCtiFailure = {
      classOfService: formData.forwardAllCss === this.initialEntity.forwardOnCtiFailure.classOfService || !this.initialEntity.forwardOnCtiFailure.classOfService || this.initialEntity.forwardAll.classOfService === this.initialEntity.forwardOnCtiFailure.classOfService  ? formData.forwardAllCss : this.initialEntity.forwardOnCtiFailure.classOfService,
      destination: formData.noAnswer?.destination === this.initialEntity.forwardOnCtiFailure.destination || !this.initialEntity.forwardOnCtiFailure.destination || this.initialEntity.forwardNoAnswerExternal.destination === this.initialEntity.forwardOnCtiFailure.destination ? formData.noAnswer?.destination : this.initialEntity.forwardOnCtiFailure.destination,
      forwardToVoicemail: formData.noAnswer?.forwardToVoicemail,
    };
    return newEntity;
  };

  getAreChildrenValid(): boolean {
    return !this.callForwardComponents.find(
      (child) => child.showErrorMessage() && child.validationState === SmacsFormsValidationState.VALID
    );
  }

  protected submit() {
    return of(null);
  }
}
