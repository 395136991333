import { Directive, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscriber } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';

import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ZpcZeroTouchResource } from '../../../shared/resources/zpc-zero-touch.abstract.resource';
import { ViewProvisioningJob } from '../../cisco/provisioning/jobs-view/provisioning-jobs-view.component';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { JobState, JobStatus } from '../../../shared/models/generated/smacsModels';
import { ZeroTouchJob } from '../../../shared/models/zero-touch';
import { ButtonStyles } from '../../../button/button.component';

export interface ViewZeroTouchJob {
  id: number;
  name: string;
  periodicJobMinutes: number;
  dailyJobTime: string;
  pending: boolean;
}

@Directive({
  selector: '[smacsZpcJobsViewAbstract]',
})
export abstract class ZpcJobsViewAbstractDirective implements OnInit {
  zeroTouchJobs$: Observable<ViewZeroTouchJob[]>;
  statusUpdatedSource = new BehaviorSubject([]);
  isLoading = true;
  requestedJobIds = [] as number[];
  pageLabel: string;
  smacsIcons = SmacsIcons;

  protected _getAllJobs: () => Observable<ViewZeroTouchJob[]>;
  private _latestJobsSource = new Subject();
  private _latestJobs$ = this._latestJobsSource.asObservable();

  bottomNavButtons = [
    {
      id: 'zeroTouchAddJob',
      dataAutomation: 'admin-zero-touch-add-job-button',
      label: 'tkey;pages.zero_touch.add_job',
      buttonClass: ButtonStyles.PRIMARY,
      icon: this.smacsIcons.ADD,
      state: {
        pending: false,
        buttonDisableState: { disabled: false, tooltipKey: '' },
      },
      cb: () => this.editJob(),
    },
  ];

  protected constructor(
    protected automationResource: ZpcZeroTouchResource<ZeroTouchJob>,
    protected breadcrumbsService: BreadcrumbsService,
    protected bottomNavService: BottomNavService,
    protected toastService: ToastService,
    protected route: ActivatedRoute,
    protected router: Router,
    protected translateService: TranslateService,
    protected smacsModalService: SmacsModalService
  ) {}

  ngOnInit() {
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: this.pageLabel,
      },
    ]);

    this.zeroTouchJobs$ = combineLatest([this._latestJobs$, this.statusUpdatedSource.asObservable()]).pipe(
      map(this._updateViewJobStatuses)
    );

    this.zeroTouchJobs$.subscribe((jobs: ViewProvisioningJob[]) => {
      if (jobs) {
        this.isLoading = false;
      }
    });
  }

  deleteJob(job: ViewZeroTouchJob) {
    if (job.pending) {
      return;
    }

    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        promptBody: this.translateService.instant('tkey;pages.zero_touch.modal_delete.body'),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this.onConfirmDelete(job),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  _refreshJobs(): Observable<void> {
    return new Observable((subscriber) => {
      this._getAllJobs().subscribe((viewJobs) => {
        this._latestJobsSource.next(viewJobs);
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }

  onConfirmDelete(job: ViewZeroTouchJob): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this.automationResource.delete(job.id).subscribe(() => {
        this._refreshJobs().subscribe(() => {
          this.isLoading = false;
          this.toastService.pushDeleteToast('tkey;shared.toast.delete.success.title', job.name);
          subscriber.next(null);
          subscriber.complete();
        });
      });
    });
  }

  editJob(job?: ViewZeroTouchJob) {
    if (job && job.pending) {
      return;
    }

    const routePath = ['edit-job'];
    if (job) {
      routePath.push(job.id + '');
    }
    this.router.navigate(routePath, { relativeTo: this.route });
  }

  runJob(job: ViewZeroTouchJob) {
    if (!job.pending) {
      this.requestedJobIds.push(job.id);
      this.automationResource.runJob(job.id).subscribe();

      job.pending = true;
    }
  }

  private _updateViewJobStatuses = ([jobs, statuses]: [ViewZeroTouchJob[], JobStatus[]]) => {
    return statuses
      .map((status) => {
        const jobToUpdate = jobs.find((job) => job.id === status.jobId);

        if (!jobToUpdate) {
          return null;
        }
        const jobWasPending = jobToUpdate.pending;
        const jobIsPending = status.jobState !== JobState.IDLE;
        jobToUpdate.pending = jobIsPending;

        if (jobWasPending && !jobIsPending && this.requestedJobIds.includes(jobToUpdate.id)) {
          this.requestedJobIds.splice(this.requestedJobIds.indexOf(jobToUpdate.id), 1);
          this.toastService.push(
            ToastTypes.SUCCESS,
            this.smacsIcons.AUTOMATION,
            'tkey;pages.zero_touch.job.completed',
            jobToUpdate.name
          );
        }

        return jobToUpdate;
      })
      .filter((j) => !!j);
  };
}
