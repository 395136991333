import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { HttpErrorResponse } from '@angular/common/http';
import { EndUserResult, Voicemail, VoicemailRef } from '../models/generated/smacsModels';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { EndUserPinResetResource } from '../../self-serve/resources/end-user-pin-reset.resource';
import { VoicemailPinResetResource } from '../../self-serve/resources/voicemail-pin-reset.resource';

const extMobilityMsgMap = {
  INVALID_ENDUSER_PIN: 'tkey;servlet.ext.mobility.fail.invalid',
  INVALID_ENDUSER_PIN_TOO_SHORT: 'tkey;servlet.ext.mobility.fail.short',
  INVALID_ENDUSER_PIN_TOO_LONG: 'tkey;servlet.ext.mobility.fail.long',
  INVALID_ENDUSER_PIN_TOO_TRIVIAL: 'tkey;servlet.ext.mobility.fail.trivial',
  INVALID_ENDUSER_PIN_REPEAT: 'tkey;servlet.ext.mobility.fail.duplicate',
  INVALID_ENDUSER_PIN_CHANGED_TOO_RECENTLY: 'tkey;servlet.ext.mobility.fail.recent',
  USER_CONTROLS_TOO_MANY_DEVICES: 'tkey;servlet.ext.mobility.fail.devices',
};

const voicemailMsgMap = {
  INVALID_VOICEMAIL_PIN: 'tkey;servlet.voicemail.fail.invalid',
  INVALID_VOICEMAIL_PIN_TOO_SHORT: 'tkey;servlet.voicemail.fail.short',
  INVALID_VOICEMAIL_PIN_TOO_LONG: 'tkey;servlet.voicemail.fail.long',
  INVALID_VOICEMAIL_PIN_TOO_TRIVIAL: 'tkey;servlet.voicemail.fail.trivial',
  INVALID_VOICEMAIL_PIN_REPEAT: 'tkey;servlet.voicemail.fail.duplicate',
};

@Injectable()
export class PinResetService {
  constructor(
    private endUserPinResetResource: EndUserPinResetResource,
    private voicemailPinResetResource: VoicemailPinResetResource,
    private translateService: TranslateService
  ) {}

  private static _toMsgKey(serviceType: string, map: any, status: string): string {
    const messageValue = map[status];
    return messageValue
      ? messageValue
      : `Unknown ${serviceType} pin reset status` + (messageValue ? `: ${status}` : '') + '.';
  }

  translateVoicemailPinResetErrorResponse = (response: HttpErrorResponse) => {
    return this.translateService.instant(
      PinResetService._toMsgKey('voicemail', voicemailMsgMap, response.error.reasonCode)
    );
  };

  translateEndUserPinResetErrorStatus = (response: HttpErrorResponse) => {
    return this.translateService.instant(
      PinResetService._toMsgKey('end user', extMobilityMsgMap, response.error.reasonCode)
    );
  };

  resetEndUserPin(
    cucmServerId: string,
    endUser: EndUserResult,
    pin: string,
    resetPinOnNextLogin: boolean
  ): Observable<void> {
    return this.endUserPinResetResource
      .resetPin(cucmServerId, endUser.ref.id, pin, resetPinOnNextLogin, [
        { name: 'username', value: endUser.ref.username },
      ])
      .pipe(catchError((error) => throwError(() => error)));
  }

  resetVoicemailPin(voicemail: VoicemailRef, pin: string, resetPinOnNextLogin: boolean): Observable<void> {
    return this.voicemailPinResetResource
      .resetPin(voicemail.serverId, voicemail.id, pin, resetPinOnNextLogin, [
        {
          name: 'alias',
          value: voicemail.alias,
        },
        {
          name: 'extension',
          value: voicemail.extension,
        },
      ])
      .pipe(catchError((error) => throwError(() => error)));
  }

  resetVoicemailWithServerId(serverId: number, voicemail: Voicemail, pin: string, resetPinOnNextLogin: boolean) {
    return this.voicemailPinResetResource.resetPin(serverId, voicemail.id, pin, resetPinOnNextLogin, [
      {
        name: 'alias',
        value: voicemail.alias,
      },
      {
        name: 'extension',
        value: voicemail.extension,
      },
    ]);
  }
}
