import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BandwidthSettings, Role } from '../models/generated/smacsModels';
import { concatMap } from 'rxjs/operators';
import { AuthenticationContext } from './authentication.context';
import { GlobalPropertiesContext } from './global-properties.context';

@Injectable()
export class BandwidthSettingsContext {
  _stateSource = new ReplaySubject<BandwidthSettings>(1);
  state$ = this._stateSource.asObservable();

  private _baseUrl = '/services/microsoft/admin/bandwidth-settings';

  constructor(
    private _http: HttpClient,
    private _authenticationContext: AuthenticationContext,
    private _globalPropertiesContext: GlobalPropertiesContext
  ) {
    combineLatest([this._globalPropertiesContext.state$, this._authenticationContext.state$]).subscribe(
      ([globalProperties, currentUser]) => {
        if (globalProperties.hostedEnabled && currentUser?.role === Role.ZIRO_SUPPORT) {
          // only call the bandwidth cache if it's needed (ZPM and user is s8support).
          this._init().subscribe();
        } else {
          this._stateSource.next(null);
        }
      }
    );
  }

  get(): Observable<BandwidthSettings> {
    return this._http.get<BandwidthSettings>(this._baseUrl);
  }

  put(settings: BandwidthSettings): Observable<void> {
    return this._http.put<void>(this._baseUrl, settings).pipe(concatMap(() => this._init()));
  }

  delete(): Observable<void> {
    return this._http.delete<void>(this._baseUrl).pipe(concatMap(() => this._init()));
  }

  testConnection(bandwidthSettings: BandwidthSettings): Observable<void> {
    return this._http.post<void>(`${this._baseUrl}/connections`, bandwidthSettings);
  }

  refresh(): Observable<void> {
    return this._init();
  }

  isBandwidthConfigured(bandwidthSettings: BandwidthSettings): boolean {
    return bandwidthSettings && bandwidthSettings.dlrAccountId !== null;
  }

  private _init(): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      this._get().subscribe((data: BandwidthSettings) => {
        this._stateSource.next(data);
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private _get(): Observable<BandwidthSettings> {
    return this._http.get<BandwidthSettings>(this._baseUrl);
  }
}
