<ng-container *ngIf="bottomNavButtons.length > 0">
  <nav id="bottom-nav" class="navbar bg-light animated fadeInUp fast" [ngClass]="{ 'is-fixed': isFixed }">
    <div class="container-fluid">
      <div class="col-md-12 text-end">
        <div class="navbar-form navbar-right">
          <span [innerHTML]="navBarState?.helpText" data-automation="bottom-nav-helptext" class="small me-1"></span>
          <span *ngFor="let button of bottomNavButtons">
            <smacs-button
              *ngIf="button.type !== buttonTypes.SUBMIT && button.type !== buttonTypes.CUSTOM"
              [cssClass]="'me-1'"
              [dataAutomation]="button?.dataAutomation"
              [label]="button.label | translate : { value: button.labelParam }"
              [isDisabled]="button?.state?.buttonDisableState?.disabled"
              [tooltip]="button?.state?.buttonDisableState?.tooltipKey"
              [isTooltipDisabled]="!button?.state?.tooltipVisible"
              [icon]="button.icon"
              [iconAppended]="button?.iconAppended"
              [iconPending]="smacsIcons.SAVING"
              [isPending]="button?.state?.pending"
              [buttonStyle]="button.buttonClass"
              (clicked)="onButtonClicked(button)"
            ></smacs-button>

            <button
              *ngIf="button.type === buttonTypes.CUSTOM"
              type="button"
              class="btn me-1 custom-button"
              [ngClass]="button?.state?.pending ? buttonStyles.WARNING : button.buttonClass"
              [disabled]="button?.state?.buttonDisableState?.disabled"
              [class.disabled]="button?.state?.buttonDisableState.disabled"
              (click)="onButtonClicked(button)"
              [attr.data-automation]="button?.dataAutomation"
              [ngbTooltip]="button?.state?.buttonDisableState?.tooltipKey | translate"
              [disableTooltip]="!button?.state?.tooltipVisible"
            >
              <i [ngClass]="!button.state || !button?.state?.pending ? button.icon : smacsIcons.SAVING"></i>
              <span>{{ button.label | translate : { value: button.labelParam } }}</span>
            </button>

            <smacs-submit-button
              class="me-1"
              *ngIf="button.type === buttonTypes.SUBMIT"
              [isPending]="button?.state?.pending"
              [isDisabled]="button?.state?.buttonDisableState?.disabled"
              [tooltip]="button?.state?.buttonDisableState?.tooltipKey"
              [isTooltipDisabled]="!button?.state?.tooltipVisible"
              [label]="button.label"
              [dataAutomation]="button?.dataAutomation"
              [icon]="button.icon"
              [iconAppended]="button?.iconAppended"
              [iconPending]="smacsIcons.SAVING"
              [formSubmitSubject]="button.submitSubject"
            ></smacs-submit-button>
          </span>
        </div>
      </div>
    </div>
  </nav>

  <div
    *ngIf="navBarState?.hasValidationError"
    data-automation="smacs-error-notification"
    class="alert smacs-error-notification alert-danger animated shake text-start"
    role="alert"
  >
    <i class="fa fa-exclamation fa-lg"></i>

    <span class="strong">
      {{ 'tkey;validators.global.required.missing.error' | translate }}
    </span>
  </div>
</ng-container>
