<div class="card card-well top-buffer animated fadeIn faster" data-automation="transfer-public-component">
  <div class="card-header">
    <legend class="bottom-buffer mt-0 pb-2">
      <span class="fa-stack fa-lg text-info">
        <i class="icon-circle fa-stack-2x"></i>
        <i [attr.class]="smacsIcons.REASSIGN + ' m-1 fa-stack-1x fa-inverse'"></i>
      </span>

      <span data-automation="reassign-header">
        {{
          reassignType === ReassignTypes.PUBLIC_TO_USER
            ? ('tkey;transfer.publicToUser.title' | translate)
            : ('tkey;operations.menu.reassign.text' | translate)
        }}
      </span>
    </legend>

    <div class="pb-2">
      <app-loading-block [hidden]="!isLoading"></app-loading-block>
      <div *ngIf="!isLoading">
        <div *ngIf="cannotReassign">
          <div class="alert alert-danger d-flex mb-0">
            <i class="icon-warning-triangle me-1"></i>
            <p
              data-automation="cannot-reassign-error"
              class="m-0"
              [innerHTML]="'tkey;transfer.publicToUser.cannot_reassign' | translate : { displayName }"
            ></p>
          </div>
        </div>

        <div *ngIf="showVoicemailCard && !cannotReassign" class="pb-2">
          <h3>
            {{ 'tkey;pages.details.transfer.keep.voicemail.prompt' | translate }}
          </h3>
          <p>
            <strong>{{ 'tkey;pages.details.transfer.keep.voicemail.subheader.note.text' | translate }}</strong>
            {{ 'tkey;pages.details.transfer.keep.voicemail.subheader.text' | translate }}
          </p>
        </div>

        <div *ngIf="!showVoicemailCard && !cannotReassign" class="pb-2">
          <p
            data-automation="reassign-confirmation-text"
            [innerHTML]="
              reassignType === ReassignTypes.PUBLIC_TO_USER
                ? ('tkey;transfer.publicToUser.confirmation.text' | translate : { displayName })
                : ('tkey;transfer.userToUser.confirmation.text' | translate : { displayName })
            "
          ></p>
          <p *ngIf="isVoicemailPresentOnLineOne" [ngClass]="isKeepVoicemail ? '' : 'fw-bold text-danger'">
            {{
              isKeepVoicemail
                ? ('tkey;transfer.confirmation.voicemailKeep.text' | translate)
                : ('tkey;transfer.confirmation.voicemailDiscard.text' | translate)
            }}
          </p>
          <p>
            <strong>{{ 'tkey;transfer.confirmation.continue.text' | translate }}</strong>
          </p>
        </div>

        <div class="text-end pb-2 border-top pt-3">
          <smacs-button
            [dataAutomation]="'reassign-cancel-button'"
            [buttonStyle]="buttonStyles.DEFAULT"
            [label]="'tkey;operations.menu.reassign.back'"
            [cssClass]="'me-1'"
            [isDisabled]="isSubmitting"
            (clicked)="onGoBackClicked()"
          ></smacs-button>
          <smacs-button
            *ngIf="showVoicemailCard && !cannotReassign"
            [dataAutomation]="'delete-voicemail-button'"
            [buttonStyle]="buttonStyles.DANGER"
            [cssClass]="'me-1'"
            [label]="'tkey;pages.details.transfer.keep.voicemail.delete.button'"
            (clicked)="onVoicemailButtonClicked(false)"
          ></smacs-button>
          <smacs-button
            *ngIf="showVoicemailCard && !cannotReassign"
            [dataAutomation]="'keep-voicemail-button'"
            [buttonStyle]="buttonStyles.PRIMARY"
            [cssClass]="'me-1'"
            [label]="'tkey;pages.details.transfer.keep.voicemail.keep.button'"
            (clicked)="onVoicemailButtonClicked(true)"
          ></smacs-button>
          <smacs-button
            *ngIf="!showVoicemailCard || cannotReassign"
            [isPending]="isSubmitting"
            [dataAutomation]="'reassign-button'"
            [iconPending]="smacsIcons.SAVING"
            [buttonStyle]="buttonStyles.PRIMARY"
            [label]="'tkey;operations.menu.reassign.text'"
            [isDisabled]="isSubmitting || cannotReassign"
            [ngbTooltip]="
              cannotReassign ? ('tkey;transfer.publicToUser.cannot_reassign.tooltip' | translate : { displayName }) : ''
            "
            (clicked)="onReassignClicked()"
          ></smacs-button>
        </div>
      </div>
    </div>
  </div>
</div>
