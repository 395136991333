import { Component, OnInit } from '@angular/core';
import { SmacsIcons } from '../../models/smacs-icons.enum';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { PhoneButtonSummary } from '../../phone-buttons/shared/phone-buttons.service';
import { ModalBodyClass } from '../../../modals/modal-body';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { PromptModalComponent } from '../../../modals/prompt-modal/prompt-modal.component';
import { SmacsFormConfig } from '../../../forms/smacs-forms-models';
import { Observable } from 'rxjs';

export interface PhoneButtonLayoutConflictModalViewProperties {
  changeType: 'phone model' | 'phone button template';
  currentValue: string;
  proposedValue: string;
  currentButtonLayout: PhoneButtonSummary[];
  proposedButtonLayout: PhoneButtonSummary[];
  currentExpansionModules: string[];
  isButtonConflictPresent: boolean;
}

interface PhoneButtonConflictRow {
  current: PhoneButtonSummary;
  proposed: PhoneButtonSummary;
}

@Component({
  selector: 'smacs-phone-button-layout-conflict-modal',
  templateUrl: './phone-button-layout-conflict-modal.component.html',
  styleUrls: ['./phone-button-layout-conflict-modal.component.scss'],
})
export class PhoneButtonLayoutConflictModalComponent extends ModalBodyClass implements OnInit {
  protected formConfig: SmacsFormConfig;
  SmacsIcons = SmacsIcons;
  ButtonStyles = ButtonStyles;
  ButtonSizes = ButtonSizes;
  isCopyUdpButton = false;

  buttonRows: PhoneButtonConflictRow[];

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    public modalComponent: PromptModalComponent<any>
  ) {
    super(smacsFormStateService);
  }

  /** Sometimes the layout will have a bunch of None buttons added to the end. We need to not display those. */
  private static _findActualLength(layout: PhoneButtonSummary[]) {
    let length = 0;
    while (
      layout[length] &&
      !(layout[length].type === 'None' && layout.slice(length).every((button) => button.type === 'None'))
    ) {
      length++;
    }
    return length;
  }

  ngOnInit() {
    this.formConfig = {} as SmacsFormConfig;
    this.isCopyUdpButton = this.modalComponent.modalViewProperties?.isCopyUdpButton;
    if (this.modalComponent.modalViewProperties.isButtonConflictPresent) {
      const currentLayout = this.modalComponent.modalViewProperties.currentButtonLayout;
      const currentLayoutLength = PhoneButtonLayoutConflictModalComponent._findActualLength(currentLayout);
      const proposedLayout = this.modalComponent.modalViewProperties.proposedButtonLayout;
      const proposedLayoutLength = PhoneButtonLayoutConflictModalComponent._findActualLength(proposedLayout);
      const maxLength = Math.max(currentLayoutLength, proposedLayoutLength);

      this.buttonRows = new Array(maxLength);
      for (let i = 0; i < maxLength; i++) {
        this.buttonRows[i] = {
          current: currentLayoutLength > i && currentLayout[i],
          proposed: proposedLayoutLength > i && proposedLayout[i],
        };
      }
    }
  }

  submit(): Observable<boolean> {
    return new Observable((subscriber) => {
      subscriber.next(true);
      subscriber.complete();
    });
  }
}
