import { Injectable } from '@angular/core';
import {
  AccountType,
  AssignedMicrosoft365License,
  LicensingState,
  Microsoft360View,
  Microsoft365LicensesFieldConfig,
  MicrosoftSecurityGroupOption,
  MicrosoftSecurityGroupsFieldConfig,
  ServiceProvisioningLevel,
  TileStatus,
  ZpmTileConfig,
} from '../../../shared/models/generated/smacsModels';
import { ServiceTileType } from '../../cisco/user-detail-home/service-tile-type.model';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';
import { cloneDeep } from 'lodash';
import { MicrosoftTile, MicrosoftTileState } from './tiles.model';

@Injectable()
export class MicrosoftTileMappingService {
  smacsIcons = SmacsIcons;
  microsoft360View: Microsoft360View;

  private _defaultTileState: MicrosoftTileState = {
    description: '',
    isAdding: false,
    isDeleting: false,
    isDisplayed: false,
    isEnabled: false,
    service: null,
  } as MicrosoftTileState;
  private isResourceAccount: boolean;

  constructor(private telephoneNumberFilter: TelephoneNumberFilter) {}

  mapServiceToTile(
    tile: MicrosoftTile,
    tileConfig: ZpmTileConfig,
    ms360View: Microsoft360View,
    isConfigured?: boolean,
    is365LicenseSyncInProgress?: boolean,
    fieldConfig?: MicrosoftSecurityGroupsFieldConfig | Microsoft365LicensesFieldConfig
  ): MicrosoftTileState {
    this.microsoft360View = cloneDeep(ms360View);
    this.isResourceAccount = this.microsoft360View.accountType === AccountType.RESOURCE_ACCOUNT;

    switch (tile) {
      case MicrosoftTile.MS365: {
        return this._mapMs365ToTile(
          isConfigured,
          is365LicenseSyncInProgress,
          tileConfig.license,
          fieldConfig as Microsoft365LicensesFieldConfig
        );
      }
      case MicrosoftTile.MICROSOFT_TEAMS_AUDIO_CONFERENCING: {
        return this._mapMsTeamsAudioConferencingToTile(tileConfig.meeting);
      }
      case MicrosoftTile.MICROSOFT_TEAMS_CALLING: {
        return this._mapMsTeamsCallingToTile(tileConfig.calling);
      }
      case MicrosoftTile.DUBBER: {
        return this._mapDubberToTile(tileConfig.dubber);
      }
      case MicrosoftTile.SECURITY_GROUP_MEMBERSHIP: {
        return this._mapSecurityGroupMembershipToTile(
          isConfigured,
          tileConfig.groups,
          fieldConfig as MicrosoftSecurityGroupsFieldConfig
        );
      }
      default:
        return null;
    }
  }

  private _mapMsTeamsAudioConferencingToTile(meetingsTileStatus: TileStatus): MicrosoftTileState {
    const audioConferenceStatus = this.microsoft360View.licensingStatus.teamsAudioConferencingStatus;
    const isTileShown = meetingsTileStatus === TileStatus.SHOW || meetingsTileStatus === TileStatus.SHOW_WITH_DELETE;

    const tileType = isTileShown
      ? audioConferenceStatus === LicensingState.LICENSED
        ? ServiceTileType.EDIT
        : ServiceTileType.ADD
      : null;
    const toolTipToDisplay = this.isResourceAccount
      ? 'tkey;microsoft_360.view.teams.audio.conference.tile.resource.account.disabled'
      : audioConferenceStatus === LicensingState.UNLICENSED
      ? 'tkey;shared.model.ms_teams_audio_conferencing.disabled.tooltip'
      : audioConferenceStatus === LicensingState.LICENSING_IN_PROGRESS
      ? 'tkey;microsoft_360.view.teams.audio.conference.tile.refresh.tooltip'
      : '';

    return {
      ...this._defaultTileState,
      dataAutomation: 'microsoft-360-ms-teams-audio-conferencing-tile',
      serviceName: 'tkey;microsoft_360.view.teams.audio.conference.tile.title',
      service:
        isTileShown && this.microsoft360View.teamsAudioConferencing
          ? this.microsoft360View.teamsAudioConferencing
          : null,
      icon: this.smacsIcons.MEETINGS,
      serviceType: MicrosoftTile.MICROSOFT_TEAMS_AUDIO_CONFERENCING,
      tileType: tileType,
      description: this.microsoft360View.teamsAudioConferencing
        ? this._formatAudioConferencingNumber(this.microsoft360View.teamsAudioConferencing.audioConferenceNumber)
        : '',
      provisioningLevel: this.microsoft360View.teamsAudioConferencing
        ? ServiceProvisioningLevel.SHOW
        : ServiceProvisioningLevel.HIDE,
      isEnabled: !this.isResourceAccount && audioConferenceStatus === LicensingState.LICENSED,
      tooltip: toolTipToDisplay,
      isDisplayed: isTileShown,
    };
  }

  private _mapMs365ToTile(
    licenseConfigured: boolean,
    isSyncInProgress: boolean,
    licenseTileStatus: TileStatus,
    fieldConfig: Microsoft365LicensesFieldConfig
  ): MicrosoftTileState {
    const ms365User = this.microsoft360View?.microsoft365UserLicenses;
    const isTileShown = licenseTileStatus === TileStatus.SHOW || licenseTileStatus === TileStatus.SHOW_WITH_DELETE;
    const displayTooltip = isSyncInProgress
      ? 'tkey;userdetail.add.service_tile.microsoft.licenses_sync.tooltip'
      : !ms365User
      ? 'tkey;userdetail.add.service_tile.no_ms365_user.tooltip'
      : !licenseConfigured
      ? 'tkey;userdetail.add.service_tile.microsoft.no.licenses.tooltip'
      : null;
    const licenseIds = fieldConfig.readOnlyLicenseList
      .concat(fieldConfig.writeLicenseList)
      .map((group) => group.microsoft365LicenseJson.skuId);
    const hasLicense = !!this.microsoft360View.microsoft365UserLicenses.licenses.find(
      (license: AssignedMicrosoft365License) => licenseIds.includes(license.skuId)
    );

    return {
      ...this._defaultTileState,
      dataAutomation: 'microsoft-360-ms-365-tile',
      serviceName: 'tkey;microsoft_360.view.licenses.tile.title',
      service: isTileShown && ms365User ? ms365User : null,
      icon: this.smacsIcons.MICROSOFT_365_OUTLINE,
      serviceType: MicrosoftTile.MS365,
      tileType: isTileShown ? (hasLicense && !isSyncInProgress ? ServiceTileType.EDIT : ServiceTileType.ADD) : null,
      description: ms365User ? ms365User.userPrincipalName : '',
      provisioningLevel: ms365User ? ServiceProvisioningLevel.SHOW : ServiceProvisioningLevel.HIDE,
      isEnabled: !!ms365User && licenseConfigured && !isSyncInProgress,
      tooltip: displayTooltip,
      isDisplayed: isTileShown,
    };
  }

  private _mapMsTeamsCallingToTile(teamsCallingTileStatus: TileStatus): MicrosoftTileState {
    const containsResourceLicense = this.microsoft360View.microsoft365UserLicenses.licenses.some(
      (license: AssignedMicrosoft365License) =>
        license.associatedServicePlans.some((servicePlan) => servicePlan.startsWith('MCOEV_VIRTUALUSER'))
    );
    const isUnlicensedResourceAccount =
      this.microsoft360View.licensingStatus.teamsPhoneStatus === LicensingState.UNLICENSED &&
      this.microsoft360View.accountType === AccountType.RESOURCE_ACCOUNT &&
      !containsResourceLicense;
    const sharedCallingLineUri =
      this.microsoft360View.teamsVoicePolicies?.sharedCallRoutingPolicy.resourceAccountLineUri;
    const msTeamsLine = this.microsoft360View.teamsPhoneNumberAssignment;
    const displayToolTip = isUnlicensedResourceAccount
      ? 'tkey;shared.model.microsoft_teams.tile.unlicensed_resource_account.tooltip'
      : this.microsoft360View.licensingStatus.teamsPhoneStatus === LicensingState.UNLICENSED
      ? 'tkey;shared.model.microsoft_teams.tile.no_license.tooltip'
      : this.microsoft360View.licensingStatus.teamsPhoneStatus === LicensingState.LICENSING_IN_PROGRESS
      ? 'tkey;microsoft_360.view.teams.calling.tile.refresh.tooltip'
      : '';
    const isTileShown =
      teamsCallingTileStatus === TileStatus.SHOW || teamsCallingTileStatus === TileStatus.SHOW_WITH_DELETE;
    const tileType = isTileShown
      ? msTeamsLine?.lineUri || sharedCallingLineUri
        ? ServiceTileType.EDIT
        : ServiceTileType.ADD
      : null;
    const description = sharedCallingLineUri
      ? `${this.telephoneNumberFilter.transform(sharedCallingLineUri)}`
      : !msTeamsLine
      ? ''
      : msTeamsLine.lineUriExtension
      ? `${this.telephoneNumberFilter.transform(msTeamsLine.lineUri)} ext. ${msTeamsLine.lineUriExtension}`
      : `${this.telephoneNumberFilter.transform(msTeamsLine.lineUri)}`;

    return {
      ...this._defaultTileState,
      dataAutomation: 'microsoft-360-ms-teams-calling-tile',
      serviceName: sharedCallingLineUri
        ? 'tkey;microsoft_360.view.teams.calling.tile.shared_calling.title'
        : 'tkey;microsoft_360.view.teams.calling.tile.title',
      service: isTileShown && msTeamsLine ? msTeamsLine : null,
      icon: this.smacsIcons.DESKPHONE_OUTLINE,
      serviceType: MicrosoftTile.MICROSOFT_TEAMS_CALLING,
      tileType: tileType,
      description: description,
      provisioningLevel: msTeamsLine ? ServiceProvisioningLevel.SHOW : ServiceProvisioningLevel.HIDE,
      isEnabled:
        this.microsoft360View.licensingStatus.teamsPhoneStatus === LicensingState.LICENSED ||
        this.microsoft360View.licensingStatus.teamsPhoneStatus === LicensingState.LICENSED_WITH_MICROSOFT_CALLING_PLAN,
      tooltip: displayToolTip,
      isDisplayed: isTileShown,
    };
  }

  private _mapSecurityGroupMembershipToTile(
    isConfigured: boolean,
    tileStatus: TileStatus,
    securityGroupFieldConfig?: MicrosoftSecurityGroupsFieldConfig
  ): MicrosoftTileState {
    const readWriteGroupIds = securityGroupFieldConfig.readOnlySecurityGroupList
      .concat(securityGroupFieldConfig.writeSecurityGroupList)
      .map((group) => group.id);
    const hasMembership = !!this.microsoft360View.securityGroupMemberships.find((group: MicrosoftSecurityGroupOption) =>
      readWriteGroupIds.includes(group.id)
    );

    const securityGroupMemberships = this.microsoft360View.securityGroupMemberships;
    const ms365User = this.microsoft360View?.microsoft365UserLicenses;
    const displayToolTip = !ms365User
      ? 'tkey;userdetail.add.service_tile.no_ms365_user.tooltip'
      : !isConfigured
      ? 'tkey;microsoft_360.add.service_tile.microsoft.no.security.groups.tooltip'
      : null;
    const isTileShown = tileStatus === TileStatus.SHOW || tileStatus === TileStatus.SHOW_WITH_DELETE;
    return {
      ...this._defaultTileState,
      dataAutomation: 'microsoft-360-ms-security-groups-tile',
      serviceName: 'tkey;microsoft_360.view.security.groups.tile.title',
      service: securityGroupMemberships ? securityGroupMemberships : null,
      icon: this.smacsIcons.SECURITY_GROUPS_OUTLINE,
      serviceType: MicrosoftTile.SECURITY_GROUP_MEMBERSHIP,
      tileType: hasMembership ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: ms365User ? ms365User.userPrincipalName : '',
      provisioningLevel: ms365User ? ServiceProvisioningLevel.SHOW : ServiceProvisioningLevel.HIDE,
      isEnabled: !!ms365User && isConfigured,
      tooltip: displayToolTip,
      isDisplayed: isTileShown,
    };
  }

  private _mapDubberToTile(dubberTileStatus: TileStatus): MicrosoftTileState {
    const dubberUser = this.microsoft360View.dubberUser;
    const isTileShown = dubberTileStatus === TileStatus.SHOW || dubberTileStatus === TileStatus.SHOW_WITH_DELETE;
    return {
      ...this._defaultTileState,
      dataAutomation: 'microsoft-360-dubber-tile',
      serviceName: 'tkey;microsoft_360.view.dubber.tile.title',
      service: isTileShown ? {} : null,
      icon: this.smacsIcons.DUBBER,
      serviceType: MicrosoftTile.DUBBER,
      tileType: !!dubberUser ? ServiceTileType.EDIT : ServiceTileType.ADD,
      description: '',
      isEnabled: isTileShown,
      isDisplayed: isTileShown,
    };
  }

  private _formatAudioConferencingNumber(audioConferencingDescription: string): string {
    const audioConfNumber = audioConferencingDescription.split(' ');
    const phoneNumber = /\d/.test(audioConfNumber[1])
      ? `${audioConfNumber[0]} ${audioConfNumber[1]}`
      : audioConfNumber[0];
    return this.telephoneNumberFilter.transform(phoneNumber);
  }
}
