import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DnAvailabilityComponent } from './cisco/dn-availability/dn-availability.component';
import { OrphanedDevicesComponent } from './cisco/orphaned-devices/orphaned-devices.component';
import { DashboardComponent } from './cisco/dashboard/dashboard.component';
import { DashboardOrphanedDevicesTileComponent } from './cisco/dashboard/tiles/orphaned-devices/dashboard-orphaned-devices-tile.component';
import { DashboardUnusedDevicesTileComponent } from './cisco/dashboard/tiles/unused-devices/dashboard-unused-devices-tile.component';
import { DashboardLicenseOptimizerTileComponent } from './cisco/dashboard/tiles/license-optimizer/dashboard-license-optimizer-tile.component';
import { DeviceUtilizationComponent } from './cisco/device-utilization/device-utilization.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { SecondsToHumanReadablePipe } from './cisco/services/seconds-to-human-readable.pipe';
import { CucmLicenseOptimizationComponent } from './cisco/cucm-license-optimization/cucm-license-optimization.component';
import { CucmLicenseOptimizationCandidatesComponent } from './cisco/cucm-license-optimization/cucm-license-optimization-candidates/cucm-license-optimization-candidates.component';
import { CucmLicenseOptimizationOverviewComponent } from './cisco/cucm-license-optimization/cucm-license-optimization-overview/cucm-license-optimization-overview.component';
import { TimeAgoPipe } from './cisco/services/time-ago.pipe';
import { GlobalDirectoryComponent } from './cisco/global-directory/global-directory.component';
import { CustomReportsComponent } from './cisco/custom-reports/custom-reports.component';
import { CustomReportsListComponent } from './cisco/custom-reports/custom-reports-list/custom-reports-list.component';
import { CucmReportBuilderComponent } from './cisco/custom-reports/cucm-report-builder/cucm-report-builder.component';
import { CucmReportBuilderFormComponent } from './cisco/custom-reports/cucm-report-builder/cucm-report-builder-form/cucm-report-builder-form.component';
import { CucmQueriesResource } from './cisco/resources/cucm-queries.resource';
import { CucmCdrUnusedDevicesContext } from './cisco/contexts/cucm-cdr-unused-devices.context';
import { CucmLicenseOptimizationContext } from './cisco/contexts/cucm-license-optimization.context';
import { DeviceUtilizationResource } from './cisco/resources/device-utilization-resource';
import { OrphanedDevicesResource } from './cisco/resources/orphaned-devices.resource';
import { DialPlanInventoryResource } from './cisco/resources/dial-plan-inventory.resource';
import { SmacsFormsModule } from '../forms/smacs-forms.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import {
  OptimizationTileEffect,
  OrphanedTileEffect,
  UnusedTileEffect,
} from './cisco/state-management/effects/dashboard.effects';
import { DashboardService } from './cisco/services/dashboard.service';
import { orphanedTileReducer } from './cisco/state-management/reducers/orphaned-tile.reducer';
import { optimizationTileReducer } from './cisco/state-management/reducers/optimization-tile.reducer';
import { unusedTileReducer } from './cisco/state-management/reducers/unused-tile.reducer';
import { UnusedPhoneButtonTemplatesComponent } from './cisco/unused-phone-button-templates/unused-phone-button-templates.component';
import { CucmLicenseUsageResource } from './cisco/resources/cucm-license-usage.resource';
import { TileStatusService } from './cisco/services/tile-status.service';
import { NewDevicePipe } from './cisco/services/new-device.pipe';
import { PublicDevicePipe } from './cisco/services/public-device.pipe';
import { ButtonModule } from '../button/button.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { LineAppearanceManagementReportComponent } from './cisco/bulk-line-appearance-management/line-appearance-management-report.component';
import { LineAppearanceManagementReportResource } from './cisco/resources/line-appearance-management-report.resource';
import { CallLogsComponent } from './cisco/call-logs/call-logs.component';
import { CallLogsResource } from './cisco/resources/call-logs.resource';
import { MicrosoftDialPlanInventoryReportComponent } from './microsoft/dial-plan-inventory-report/dial-plan-inventory-report.component';
import { GarbageCanPipe } from './cisco/services/garbage-can.pipe';
import { CustomPowershellReportsComponent } from './microsoft/custom-powershell-reports/custom-powershell-reports.component';
import { CustomPowershellReportFormComponent } from './microsoft/custom-powershell-reports/custom-powershell-report-form/custom-powershell-report-form.component';
import { CallLogsQueryEditorComponent } from '../shared/expression-editor/call-logs-query-editor.component';
import { CallLogsQueryEditorKeywordComponent } from '../shared/expression-editor/expression-editor-keyword/call-logs-query-editor-keyword.component';
import { ExpressionEditorKeywordExtensionSearchComponent } from '../shared/expression-editor/expression-editor-keyword/expression-editor-keyword-extension-search/expression-editor-keyword-extension-search.component';
import { ExpressionEditorKeywordDeviceSearchComponent } from '../shared/expression-editor/expression-editor-keyword/expression-editor-keyword-device-search/expression-editor-keyword-device-search.component';
import { ExpressionEditorKeywordUsernameSearchComponent } from '../shared/expression-editor/expression-editor-keyword/expression-editor-keyword-username-search/expression-editor-keyword-username-search.component';
import { DateFormat, DateTimeFormat, TimeFormat } from '../shared/filters/date-time-format.filter';
import { DashboardTileComponent } from '../shared/dashboard-tile/dashboard-tile.component';
import { LoadingBlockModule } from '../shared/loading-block/loading-block.module';
import { BreadcrumbsModule } from '../shared/breadcrumbs/breadcrumbs.module';
import { EntityTableModule } from '../shared/entity-table/entity-table.module';
import { PaginationModule } from '../shared/pagination/pagination.module';
import { ReportingComponent } from './reporting.component';
import { BaseContainerModule } from '../shared/abstract/base-container/base-container.module';
import { BottomNavModule } from '../shared/bottom-nav/bottom-nav.module';
import { DatatableModule } from './datatable/datatable.module';
import { PipesModule } from '../shared/pipes/pipes.module';
import { DaysAgoPipe } from '../shared/pipes/days-ago.pipe';
import { DateAgoPipe } from '../shared/pipes/date-ago.pipe';
import { DynamicPipe } from '../shared/pipes/dynamic.pipe';
import { StripHtmlPipe } from '../shared/pipes/strip-html.pipe';
import { PolicyMisconfigurationReportComponent } from './microsoft/policy-misconfiguration-report/policy-misconfiguration-report.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    CalendarModule,
    SmacsFormsModule,
    ButtonModule,
    NgxSkeletonLoaderModule,
    NgxSliderModule,
    StoreModule.forRoot({
      reportDashboardOrphanedTile: orphanedTileReducer,
      reportDashboardOptimizationTile: optimizationTileReducer,
      reportDashboardUnusedTile: unusedTileReducer,
    }),
    EffectsModule.forRoot([OrphanedTileEffect, UnusedTileEffect, OptimizationTileEffect]),
    LoadingBlockModule,
    BreadcrumbsModule,
    EntityTableModule,
    PaginationModule,
    BaseContainerModule,
    BottomNavModule,
    DatatableModule,
    PipesModule,
  ],
  declarations: [
    DnAvailabilityComponent,
    OrphanedDevicesComponent,
    DashboardComponent,
    DashboardOrphanedDevicesTileComponent,
    DashboardLicenseOptimizerTileComponent,
    DeviceUtilizationComponent,
    DashboardUnusedDevicesTileComponent,
    SecondsToHumanReadablePipe,
    TimeAgoPipe,
    NewDevicePipe,
    PublicDevicePipe,
    CucmLicenseOptimizationComponent,
    CucmLicenseOptimizationCandidatesComponent,
    CucmLicenseOptimizationOverviewComponent,
    GlobalDirectoryComponent,
    CustomReportsComponent,
    CucmReportBuilderComponent,
    CucmReportBuilderFormComponent,
    CustomReportsListComponent,
    UnusedPhoneButtonTemplatesComponent,
    LineAppearanceManagementReportComponent,
    CallLogsComponent,
    MicrosoftDialPlanInventoryReportComponent,
    PolicyMisconfigurationReportComponent,
    CustomPowershellReportsComponent,
    CustomPowershellReportFormComponent,
    GarbageCanPipe,
    CallLogsQueryEditorComponent,
    CallLogsQueryEditorKeywordComponent,
    ExpressionEditorKeywordExtensionSearchComponent,
    ExpressionEditorKeywordDeviceSearchComponent,
    ExpressionEditorKeywordUsernameSearchComponent,
    DateTimeFormat,
    DateFormat,
    TimeFormat,
    DashboardTileComponent,
    ReportingComponent,
  ],
  exports: [
    DnAvailabilityComponent,
    OrphanedDevicesComponent,
    DashboardComponent,
    DashboardOrphanedDevicesTileComponent,
    DashboardLicenseOptimizerTileComponent,
    DeviceUtilizationComponent,
    DashboardUnusedDevicesTileComponent,
    CucmLicenseOptimizationComponent,
    GlobalDirectoryComponent,
    CustomReportsComponent,
    CucmReportBuilderComponent,
    CucmReportBuilderFormComponent,
    CustomReportsListComponent,
    UnusedPhoneButtonTemplatesComponent,
    LineAppearanceManagementReportComponent,
    CallLogsComponent,
    MicrosoftDialPlanInventoryReportComponent,
    PolicyMisconfigurationReportComponent,
    CustomPowershellReportsComponent,
    CustomPowershellReportFormComponent,
    CallLogsQueryEditorComponent,
    CallLogsQueryEditorKeywordComponent,
    ExpressionEditorKeywordExtensionSearchComponent,
    ExpressionEditorKeywordDeviceSearchComponent,
    ExpressionEditorKeywordUsernameSearchComponent,
    DateTimeFormat,
    DateFormat,
    TimeFormat,
    DashboardTileComponent,
    ReportingComponent,
  ],
  providers: [
    CucmCdrUnusedDevicesContext,
    CucmLicenseOptimizationContext,
    DeviceUtilizationResource,
    OrphanedDevicesResource,
    DialPlanInventoryResource,
    CucmLicenseUsageResource,
    DashboardService,
    LineAppearanceManagementReportResource,
    TileStatusService,
    CucmQueriesResource,
    TimeAgoPipe,
    SecondsToHumanReadablePipe,
    NewDevicePipe,
    PublicDevicePipe,
    DynamicPipe,
    DaysAgoPipe,
    StripHtmlPipe,
    CallLogsResource,
    DateAgoPipe,
    GarbageCanPipe,
  ],
})
export class ReportingModule {}
