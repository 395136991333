<div class="row pb-5">
  <div class="col-md-12 order-2 order-md-1">
    <smacs-fieldset-header [legend]="'tkey;microsoft_360.view.teams.call_queues.title'">
      <div *ngIf="!isDataInitialized" class="d-flex gap-2">
        <label class="smacs-forms-label col-form-label col-lg-3 text-lg-end">
          {{ 'tkey;microsoft_360.view.teams.call_queues.call_queue_membership.label' | translate }}
        </label>
        <smacs-button
          [dataAutomation]="'calling-queue-button'"
          [label]="'tkey;microsoft_360.view.teams.call_queues.call_queue_membership.button' | translate"
          [icon]="smacsIcons.GET_DATA_SOLID"
          [iconPending]="smacsIcons.SAVING"
          [isPending]="this.isFetchingQueueData"
          (clicked)="getCallQueues()"
        ></smacs-button>
      </div>
      <smacs-select [class]="'fade-in'" [hidden]="!isDataInitialized" [fieldId]="'callQueues'"></smacs-select>
    </smacs-fieldset-header>
  </div>
</div>
