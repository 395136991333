import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  JobState,
  MicrosoftBulkJobStatus,
  MicrosoftProvisioningUserOptions,
  UpnValidation,
  UpnValidationRequest,
} from '../../shared/models/generated/smacsModels';
import { AuditHeaderService } from '../../shared/services/audit-header.service';
import { map } from 'rxjs/operators';
import { Nvp } from '../../shared/models/nvp';

@Injectable()
export class ZpmBulkProvisioningResource {
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  post(body: MicrosoftProvisioningUserOptions[], auditTag?: Nvp): Observable<void> {
    if (auditTag) {
      const auditHeader = this.auditHeaderService.buildHeader([auditTag]);
      return this.http.post<void>(`/services/microsoft/automate-jobs/bulk-end-user-provisionings`, body, {
        headers: auditHeader,
      });
    }
    return this.http.post<void>(`/services/microsoft/automate-jobs/bulk-end-user-provisionings`, body);
  }

  getState(): Observable<JobState> {
    return this.http
      .get<MicrosoftBulkJobStatus>(`/services/microsoft/automate-jobs/bulk-end-user-provisionings/statuses`)
      .pipe(map((bulkJobStatus) => bulkJobStatus.jobStatus.jobState));
  }

  bulkUpnValidation(body: UpnValidationRequest, auditTag?: Nvp): Observable<UpnValidation[]> {
    if (auditTag) {
      const auditHeader = this.auditHeaderService.buildHeader([auditTag]);
      return this.http.post<UpnValidation[]>(
        `/services/microsoft/automate-jobs/bulk-end-user-provisionings/upn-validations`,
        body,
        {
          headers: auditHeader,
        }
      );
    }
    return this.http.post<UpnValidation[]>(
      `/services/microsoft/automate-jobs/bulk-end-user-provisionings/upn-validations`,
      body
    );
  }
}
