import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SearchFieldConfig } from '../../../../shared/models/generated/smacsModels';
import { Observable } from 'rxjs';

@Injectable()
export class SearchFieldConfigResource {
  constructor(private http: HttpClient) {}

  get(): Observable<SearchFieldConfig> {
    return this.http.get<SearchFieldConfig>(`/services/cisco/field-configs/search-config`);
  }
}
