import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash';
import { PhoneContainer, SelfServe360View, SelfServeUiContext } from '../contexts/self-serve-ui.context';
import { DirectoryNumber, EndUserResult, Phone, TemplateButton } from '../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { DragDropMode } from '../../shared/phone-buttons/drag-drop-mode.enum';
import { PhoneUiContext } from '../../shared/phone-buttons/contexts/phone-ui.context';
import { SiteContext } from '../../helpdesk/shared/contexts/site.context';
import { SelfServeOptionsContext } from '../contexts/self-serve-options.context';

@Component({
  selector: 'app-self-serve-deskphone-edit',
  templateUrl: './self-serve-deskphone-edit.component.html',
  styleUrls: ['./self-serve-deskphone-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [PhoneUiContext, SiteContext],
})
export class SelfServeDeskphoneEditComponent implements OnInit, OnDestroy {
  isPending = false;
  isLoading = true;
  directoryNumber: DirectoryNumber;
  phone: Phone;
  valid = true;
  user: EndUserResult;
  selfServe360View = {} as SelfServe360View;
  cucmServerId: string;
  phoneId: string;
  smacsIcons = SmacsIcons;
  userMode: DragDropMode;
  buttonLayout: TemplateButton[];
  isDisplayEnhancedLineMode: boolean;

  private _subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private selfServeUiContext: SelfServeUiContext,
    private selfServeOptionsContext: SelfServeOptionsContext,
    private phoneUiContext: PhoneUiContext
  ) {}

  ngOnInit() {
    this.userMode = DragDropMode.SELF_SERVE;
    this.phoneId = get(this.route, 'snapshot.params.phoneId');

    if (!this.selfServeUiContext.selfServe360View) {
      const username = get(this.route, 'snapshot.params.username');
      this.selfServeUiContext.initUserDetails(username);
    }

    const subscription = this.selfServeUiContext.selfServe360View$.subscribe((selfServe360View: SelfServe360View) => {
      if (selfServe360View) {
        this.user = selfServe360View.endUsers[0];
        this.selfServe360View = selfServe360View;

        const phoneContainer = selfServe360View.phoneContainers.find(
          (container: PhoneContainer) => container.phoneRef.id === this.phoneId
        );

        if (!phoneContainer) {
          const username = get(this.route, 'snapshot.params.username');
          this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(username)}`);
          return;
        }
        this.cucmServerId = String(phoneContainer.phoneRef.serverId);
      }
    });
    this._subscriptions.add(subscription);

    const optionsSub = this.selfServeOptionsContext.state$.subscribe((options) => {
      this.isDisplayEnhancedLineMode = options.selfServeEnhancedLineModeEnabled;
    });
    this._subscriptions.add(optionsSub);

    this.phoneUiContext.phoneState$.subscribe((phone) => {
      this.phone = phone;
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
