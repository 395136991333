import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CallingDelegate } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class DelegateManagementResource {
  constructor(private $http: HttpClient) {}

  getCallingDelegate(upn: string): Observable<CallingDelegate> {
    return this.$http.get<CallingDelegate>(`/services/microsoft/macs/teams-calling-delegates/${upn}`);
  }

  putCallingDelegate(callingDelegate: CallingDelegate): Observable<void> {
    return this.$http.put<void>(
      `/services/microsoft/macs/teams-calling-delegates/${callingDelegate.userPrincipalName}`,
      callingDelegate
    );
  }
}
