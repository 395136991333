import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  Csr,
  DistinguishedNameProperties,
  SignedCertificate,
  WildcardCertificate,
} from '../../shared/models/generated/smacsModels';

@Injectable()
export class CertificateResource {
  constructor(private http: HttpClient) {}

  getCsrs(): Observable<Csr[]> {
    return this.http.get<Csr[]>('/services/system/admin/certificates/csrs');
  }

  postCsr(body: DistinguishedNameProperties): Observable<Csr[]> {
    return this.http.post<Csr[]>('/services/system/admin/certificates/csrs', body);
  }

  putWildCardCertificate(wildCardCertificate: WildcardCertificate) {
    return this.http.put<void>('/services/system/admin/certificates/wildcard-certificates', wildCardCertificate);
  }

  putSignedCertificate(signedCertificate: SignedCertificate) {
    return this.http.put<void>('/services/system/admin/certificates/signed-certificates', signedCertificate);
  }
}
