import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { OrderNumberInventoryResponse } from '../../shared/models/generated/smacsModels';

@Injectable()
export class NumberInventoriesResource {
  private _url = '/services/microsoft/orders/number-inventories';

  constructor(private _http: HttpClient) {}

  get(): Observable<OrderNumberInventoryResponse> {
    return this._http.get<OrderNumberInventoryResponse>(this._url);
  }
}
