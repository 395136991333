import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TeamsVoicePoliciesFieldConfig } from '../../../shared/models/generated/smacsModels';
import { Observable } from 'rxjs';

@Injectable()
export class MicrosoftVoicePoliciesFieldConfigResource {
  private _baseUrl = '/services/microsoft/field-configs/teams-voice-policies';

  constructor(private http: HttpClient) {}

  get(dialPlanId: number, isResourceAccount: boolean): Observable<TeamsVoicePoliciesFieldConfig> {
    return this.http.get<TeamsVoicePoliciesFieldConfig>(
      `${this._baseUrl}/${dialPlanId}?isResourceAccount=${isResourceAccount}`
    );
  }
}
