<div
  *ngIf="isVisible"
  class="side-navigation-section-header"
  [attr.data-automation]="dataAutomation"
  (click)="handleSectionHeaderClick()"
  [ngClass]="{
    'side-navigation-section-header--link': isLink,
    'side-navigation-section-header--active': (active || searchFilter) && !isLink,
    'side-navigation-section-header--disabled': isDisabled,
    'side-navigation-section-header--link-active': isLink && isLinkActive
  }"
  [ngbTooltip]="disabledTooltip | translate"
  [disableTooltip]="!isDisabled"
>
  <div class="side-navigation-section-header__text">
    <i [ngClass]="[icon, 'side-navigation-section-header__text__icon']"></i>
    <span class="ms-2" *ngIf="!isMinimized">{{ sectionTitle | translate }}</span>
  </div>
  <i class="side-navigation-section-header__icon fa fa-chevron-up" *ngIf="!isMinimized && !isLink"></i>
</div>

<div class="side-navigation-section" [@inOutAnimation] *ngIf="isVisible && (active || searchFilter) && !isMinimized">
  <ul class="side-navigation-section__links">
    <li class="side-navigation-section__links__item" *ngFor="let link of childLinks">
      <side-navigation-section-link
        [link]="link.link"
        [text]="link.text"
        [dataAutomation]="link.dataAutomation"
        [isRouterLink]="link.isRouterLink === false || link.isRouterLink === true ? link.isRouterLink : true"
        [newTab]="link.newTab"
        [isDisabled]="link?.isDisabled ? link.isDisabled(navProps) : false"
        [disabledTooltip]="link?.disabledTooltip ? link.disabledTooltip(navProps) : null"
        [isVisible]="link?.isVisible ? link.isVisible(navProps) : true"
        [navProps]="navProps"
        [childLinks]="link.links"
        [queryParams]="link.queryParams"
        (clickEvent)="handleClickEvent(link)"
      ></side-navigation-section-link>
    </li>
  </ul>
</div>
