import { inject, Injectable } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationContext } from '../contexts/authentication.context';
import { map } from 'rxjs/operators';

@Injectable()
export class ActivateDeskphoneRouteGuardService {
  constructor(private router: Router, private authenticationContext: AuthenticationContext) {}

  canActivate(): Observable<boolean> {
    return this.authenticationContext.state$.pipe(
      map((user) => {
        if (user?.userId) {
          this.router.navigateByUrl(`/self-serve/user/${encodeURIComponent(user.userId)}/activate`);
        }
        return false;
      })
    );
  }
}
export const deskphoneRouteGuardFn: CanActivateFn = (route, state) =>
  inject(ActivateDeskphoneRouteGuardService).canActivate();
