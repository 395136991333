import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbNav } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription } from 'rxjs';
import { CertificateResource } from '../../resources/certificate.resource';
import { Csr } from '../../../shared/models/generated/smacsModels';
import { SamlSsoConfigContext } from '../../contexts/saml-sso-config.context';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';

enum CertificateManagementTabId {
  IMPORT_CERTIFICATE = 'import-certificate-tab',
  GENERATE_CSR = 'generate-csr-tab',
}

@Component({
  selector: 'app-admin-certificate-management',
  templateUrl: './certificate-management.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class CertificateManagementComponent implements OnInit, OnDestroy {
  @ViewChild('nav') nav: NgbNav;
  tabIds = CertificateManagementTabId;

  csrTimestamp = new Subject<string>();
  displayCsr = new Subject<boolean>();
  existingCsr: Csr;
  isSsoEnabled: boolean;
  isLoading = true;

  private _subs = new Subscription();

  constructor(
    private certificateResource: CertificateResource,
    private samlSsoConfigContext: SamlSsoConfigContext,
    private breadcrumbsService: BreadcrumbsService
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.certificate_management.header' }]);
    this.certificateResource.getCsrs().subscribe((csrs) => {
      this.existingCsr = csrs[0];
      if (this.existingCsr) {
        this.selectImportCsrTab();
      }
      this.isLoading = false;
    });

    const ssoConfigSub = this.samlSsoConfigContext.state$.subscribe((samlSSoConfig) => {
      this.isSsoEnabled = samlSSoConfig.ssoEnabled;
    });

    this._subs.add(ssoConfigSub);
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
    this._subs.unsubscribe();
  }

  selectGenerateCsrTab() {
    this.nav.select(CertificateManagementTabId.GENERATE_CSR);
  }

  selectImportCsrTab() {
    this.nav.select(CertificateManagementTabId.IMPORT_CERTIFICATE);
  }

  csrGenerated(csr: Csr) {
    this.existingCsr = csr;
  }

  csrDeleted() {
    this.existingCsr = null;
    this.csrTimestamp.next(null);
  }
}
