import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuditHeaderService } from './audit-header.service';
import { ExtensionMobility, ExtensionMobilityRef } from '../models/generated/smacsModels';
import { Nvp } from '../models/nvp';

@Injectable()
export class ExtensionMobilityService {
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  private static _buildUrl(serverId: string | number, id?: string): string {
    let url = `/services/cisco/macs/cucm-servers/${encodeURIComponent(String(serverId))}/extension-mobility-profiles`;
    if (id) {
      url += `/${encodeURIComponent(id)}`;
    }
    return url;
  }

  get(id: string, serverId: string | number): Observable<ExtensionMobility> {
    const url = ExtensionMobilityService._buildUrl(serverId, id);
    return this.http.get<ExtensionMobility>(url);
  }

  put(
    id: string,
    body: ExtensionMobility,
    serverId: string | number,
    auditTags?: Nvp[]
  ): Observable<ExtensionMobilityRef> {
    const url = ExtensionMobilityService._buildUrl(serverId, id);
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.put<ExtensionMobilityRef>(url, body, httpOptions);
  }

  post(body: ExtensionMobility, serverId: string | number, auditTags?: Nvp[]): Observable<ExtensionMobilityRef> {
    const url = ExtensionMobilityService._buildUrl(serverId);
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.post<ExtensionMobilityRef>(url, body, httpOptions);
  }

  delete(id: string, serverId: string | number, auditTags?: Nvp[]) {
    const url = ExtensionMobilityService._buildUrl(serverId, id);
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.delete(url, httpOptions);
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }
}
