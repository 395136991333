import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LineAppearanceReport } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class LineAppearanceManagementReportResource {
  constructor(private http: HttpClient) {}

  get(cucmServerId: number, uuid: string): Observable<LineAppearanceReport> {
    return this.http.get<LineAppearanceReport>(
      `/services/cisco/reporting/line-appearance-report/cucm/${cucmServerId}/directory-number/${uuid}`
    );
  }
}
