import { Component, OnDestroy, OnInit } from '@angular/core';
import { humanReadableRegistrationStatus, PhoneResource } from '../../../../shared/resources/phone.resource';
import { DeviceRegistrationStatus } from '../../../../shared/models/generated/smacsModels';
import { PhoneUiContext } from '../../../../shared/phone-buttons/contexts/phone-ui.context';
import { SiteContext } from '../../../shared/contexts/site.context';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import { combineLatest, Subscription } from 'rxjs';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';

@Component({
  selector: 'smacs-public-phone-contact-card',
  templateUrl: './public-phone-contact-card.component.html',
  styleUrls: ['./public-phone-contact-card.component.scss'],
})
export class PublicPhoneContactCardComponent implements OnInit, OnDestroy {
  SmacsIcons = SmacsIcons;
  DeviceRegistrationStatus = DeviceRegistrationStatus;
  humanReadableRegistrationStatus = humanReadableRegistrationStatus;

  siteName: string;
  phoneName: string;
  phoneDescription: string;
  phoneRegistrationStatus: DeviceRegistrationStatus;

  private _cucmServerId: number;
  private _subscription = new Subscription();

  constructor(
    private phoneResource: PhoneResource,
    private phoneUiContext: PhoneUiContext,
    private siteContext: SiteContext,
    private siteSummaryContext: SiteSummaryContext
  ) {}

  ngOnInit(): void {
    const subs = combineLatest([
      this.siteSummaryContext.state$,
      this.siteContext.state$,
      this.phoneUiContext.phoneState$,
    ]).subscribe(([siteSummary, site, phone]) => {
      this.siteName = site.name;
      this._cucmServerId = this.siteSummaryContext.findCucmServerIdForSite(siteSummary, Number(site.id));
      this.phoneDescription = phone.description;

      if (this.phoneName !== phone.name) {
        if (phone.name && /^(SEP|SIP)[a-fA-F0-9]{12}$/.test(phone.name)) {
          this.phoneName = phone.name;
          this._updatePhoneRegistrationStatus();
        } else {
          this.phoneName = '';
          this.phoneRegistrationStatus = null;
        }
      }
    });
    this._subscription.add(subs);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private _updatePhoneRegistrationStatus() {
    this.phoneRegistrationStatus = null;
    this.phoneResource.getPhoneStatus(this.phoneName, this._cucmServerId).subscribe((status) => {
      this.phoneRegistrationStatus = status.status;
    });
  }
}
