import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DialPlanInventory, DialPlanManagementGroupSearchResult } from '../models/generated/smacsModels';
import { Observable } from 'rxjs';

@Injectable()
export class DialPlanInventoriesResource {
  constructor(private http: HttpClient) {}

  get(groupId: number): Observable<DialPlanInventory> {
    return this.http.get<DialPlanInventory>(`/services/cisco/views/dial-plan-inventories/v2/groups/${groupId}`);
  }

  search(cucmServerId: number, extension: string): Observable<DialPlanManagementGroupSearchResult[]> {
    const baseUrl = `/services/cisco/views/dial-plan-inventories/v2/search`;
    return this.http.get<DialPlanManagementGroupSearchResult[]>(
      `${baseUrl}?cucmId=${cucmServerId}&extension=${encodeURIComponent(extension)}`
    );
  }
}
