import { Injectable, NgZone } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { SystemHealthStatus } from '../models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { PollingAbstractService } from '../services/abstract/polling.abstract.service';
import { shareReplay, tap } from 'rxjs/operators';

@Injectable()
export class SystemStatusContext extends PollingAbstractService<SystemHealthStatus> {
  baseUrl = '/services/system/info/system-health-status';

  protected _pollingTimeInMilliseconds = 30 * 1000;

  constructor(protected http: HttpClient, protected ngZone: NgZone) {
    super(http, ngZone);
    this.startPolling();
  }

  getSystemStatus(): Observable<SystemHealthStatus> {
    return this.http.get<SystemHealthStatus>(this.baseUrl).pipe(
      tap((healthStatus: SystemHealthStatus) => {
        this.ngZone.run(() => this._stateSource.next(healthStatus));
        return healthStatus;
      })
    );
  }

  refreshSystemStatus(): Observable<SystemHealthStatus> {
    return this._refresh().pipe(
      switchMap(() => {
        return this.getSystemStatus().pipe(
          tap((healthStatus: SystemHealthStatus) => {
            return healthStatus;
          })
        );
      })
    );
  }

  private _refresh(): Observable<void> {
    return this.http.post<void>('/services/system/info/system-health-status/refresh', {}).pipe(shareReplay());
  }
}
