<div [ngbTooltip]="disabledTooltip | translate" [disableTooltip]="!isDisabled" *ngIf="isVisible">
  <a
    *ngIf="!isRouterLink && link"
    class="side-navigation-section-item"
    [ngClass]="{
      'side-navigation-section-item--active': isActive,
      'side-navigation-section-item--disabled': isDisabled
    }"
    [href]="link"
    [target]="newTab ? '_blank' : '_self'"
    [attr.data-automation]="dataAutomation"
    [ngbTooltip]="disabledTooltip | translate"
    [disableTooltip]="!isDisabled"
  >
    {{ text | translate }}
  </a>
  <a
    *ngIf="!isRouterLink && !link"
    class="side-navigation-section-item clickable-cursor"
    [ngClass]="{
      'side-navigation-section-item--active': isActive,
      'side-navigation-section-item--disabled': isDisabled
    }"
    [attr.data-automation]="dataAutomation"
    [ngbTooltip]="disabledTooltip | translate"
    [disableTooltip]="!isDisabled"
    (click)="onClick()"
  >
    {{ text | translate }}
  </a>
  <a
    *ngIf="isRouterLink"
    class="side-navigation-section-item"
    [ngClass]="{
      'side-navigation-section-item--active': isActive,
      'side-navigation-section-item--disabled': isDisabled
    }"
    [routerLink]="link"
    [queryParams]="queryParams"
    [attr.data-automation]="dataAutomation"
    [target]="newTab ? '_blank' : '_self'"
  >
    {{ text | translate }}
  </a>
</div>
<div class="side-navigation-section-item-children mb-1" *ngIf="childLinks && childLinks.length">
  <side-navigation-section-link
    *ngFor="let link of childLinks"
    [link]="link.link"
    [text]="link.text"
    [dataAutomation]="link.dataAutomation"
    [isRouterLink]="link.isRouterLink === false || link.isRouterLink === true ? link.isRouterLink : true"
    [newTab]="link.newTab"
    [isDisabled]="
      link?.isDisabled
        ? link.isDisabled({
            isLicenseExpired: navProps.isLicenseExpired,
            isMsGraphConfigured: navProps.isMsGraphConfigured,
            hasEmailSettings: navProps.hasEmailSettings,
            isHostedEnabled: navProps.isHostedEnabled,
            isUserS8Support: navProps.isUserS8Support,
            isReportingModuleEnabled: navProps.isReportingModuleEnabled,
            isAutomationModuleEnabled: navProps.isAutomationModuleEnabled,
            isHighAvailabilityEnabled: navProps.isHighAvailabilityEnabled,
            endpointDisabled: navProps.endpointDisabled
          })
        : false
    "
    [disabledTooltip]="
      link?.disabledTooltip
        ? link.disabledTooltip({
            isLicenseExpired: navProps.isLicenseExpired,
            isMsGraphConfigured: navProps.isMsGraphConfigured,
            hasEmailSettings: navProps.hasEmailSettings,
            isHostedEnabled: navProps.isHostedEnabled,
            isUserS8Support: navProps.isUserS8Support,
            isReportingModuleEnabled: navProps.isReportingModuleEnabled,
            isAutomationModuleEnabled: navProps.isAutomationModuleEnabled,
            isHighAvailabilityEnabled: navProps.isHighAvailabilityEnabled,
            endpointDisabled: navProps.endpointDisabled
          })
        : null
    "
    [isVisible]="
      link?.isVisible
        ? link.isVisible({
            isLicenseExpired: navProps.isLicenseExpired,
            isMsGraphConfigured: navProps.isMsGraphConfigured,
            hasEmailSettings: navProps.hasEmailSettings,
            isHostedEnabled: navProps.isHostedEnabled,
            isUserS8Support: navProps.isUserS8Support,
            isReportingModuleEnabled: navProps.isReportingModuleEnabled,
            isAutomationModuleEnabled: navProps.isAutomationModuleEnabled,
            isHighAvailabilityEnabled: navProps.isHighAvailabilityEnabled,
            endpointDisabled: navProps.endpointDisabled
          })
        : true
    "
    [navProps]="navProps"
    [childLinks]="link.links"
    (clickEvent)="handleClickEvent(link)"
  ></side-navigation-section-link>
</div>
