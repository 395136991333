import { isNil } from 'lodash';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import {
  HtmlInputAddOn,
  SmacsFieldComponentConfig,
  SmacsFormsValidationState,
} from '../../../../forms/smacs-forms-models';
import { SmacsFieldAbstractDirective } from '../../../../forms/smacs-field-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { ButtonStyles } from '../../../../button/button.component';
import { SelectConfigEntity } from '../../legacy-smacs-select-config/legacy-smacs-select-config.component';
import { HtmlInputType, InputSize } from '../../../../forms/fields/text/smacs-text.component';

type SmacsTextValue = string;

export class SmacsReadWriteTextConfig extends SmacsFieldComponentConfig {
  constructor(
    public htmlInputType: HtmlInputType,
    public placeholder?: string,
    public htmlInputAddOn?: HtmlInputAddOn,
    public inputSize?: InputSize,
    public datalist$?: Observable<any>,
    public min?: number,
    public max?: number,
    public readonly?: boolean,
    public leftAligned?: boolean
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-read-write-select-config-form-text',
  templateUrl: './read-write-select-config-form-text.component.html',
  styleUrls: ['./read-write-select-config-form-text.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: ReadWriteSelectConfigFormTextComponent }],
})
export class ReadWriteSelectConfigFormTextComponent
  extends SmacsFieldAbstractDirective<SmacsTextValue, SmacsTextValue, SmacsReadWriteTextConfig>
  implements OnInit, OnDestroy
{
  @Input() inlineLabel = true;
  @Input() currentValue: SelectConfigEntity;

  @Output() licenseSelectInputClicked = new EventEmitter<boolean>();

  htmlInputType = HtmlInputType;
  placeholder = '';
  inputType = HtmlInputType.TEXT;
  inputAddOn = null as HtmlInputAddOn;
  inputSize = '';
  inputSizes = InputSize;
  datalist$: Observable<any>;
  localTime = '';
  min: number;
  max: number;
  readOnly = false;
  leftAligned = false;
  starsPlaceholder = '••••••••';
  showMaskedInput = false;
  maskedValue = '';
  disabledMasking = false;
  buttonStyles = ButtonStyles;
  isChrome: boolean;

  private _subs = new Subscription();

  constructor(protected smacsFormStateService: SmacsFormStateService, private _translateService: TranslateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.isChrome = !!(window as any)['chrome'];
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subs.unsubscribe();
  }

  applyComponentConfig = ({
    htmlInputType,
    placeholder,
    htmlInputAddOn,
    inputSize,
    datalist$,
    min,
    max,
    readonly,
    leftAligned,
  }: SmacsReadWriteTextConfig) => {
    this.inputType = isNil(htmlInputType) ? this.inputType : htmlInputType;
    this.placeholder = isNil(placeholder) ? this.placeholder : placeholder;
    this.inputAddOn = isNil(htmlInputAddOn) ? this.inputAddOn : htmlInputAddOn;
    this.inputSize = isNil(inputSize) ? this.inputSize : inputSize;
    this.datalist$ = isNil(datalist$) ? this.datalist$ : datalist$;
    this.min = isNil(min) ? this.min : min;
    this.max = isNil(max) ? this.max : max;
    this.readOnly = isNil(readonly) ? this.readOnly : readonly;
    this.leftAligned = isNil(leftAligned) ? this.leftAligned : leftAligned;

    if (this.inputType === HtmlInputType.PASSWORD) {
      const updateSub = this.smacsFormsUpdate$.subscribe((update) => {
        if (update.old && update.old !== update.new) {
          this.showMaskedInput = false;
          this.disabledMasking = true;
        } else if (this.isExisting && !this.disabledMasking) {
          this.showMaskedInput = true;
        }
      });
      this._subs.add(updateSub);
    }

    if (this.inputType === this.htmlInputType.TIME) {
      const updateSub = this.smacsFormsUpdate$.subscribe((update) => {
        this._setLocalTime(update.new);
      });
      this._subs.add(updateSub);
    }
  };

  getHelpText() {
    if (!this.config.helpText) {
      return '';
    } else if (typeof this.config.helpText === 'string') {
      return this.config.helpText;
    } else {
      return this.config.helpText();
    }
  }

  getLabelColumnClass(): string {
    if (this.inlineLabel && !this.leftAligned) {
      return this.state.columnClasses?.label;
    }
    return '';
  }

  getInputColumnClass(): string {
    if (this.config.label) {
      return this.state.columnClasses?.input;
    }
    return '';
  }

  hideDefaultValueAutogenerationFnFactory(
    defaultVal: SmacsTextValue
  ): (val: SmacsTextValue) => SmacsFormsValidationState {
    return (val) =>
      !defaultVal || defaultVal === val ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
  }

  misconfigurationWarningMessage = () => ({
    content: 'tkey;smacs_text.misconfiguration_feedback',
    params: { defaultValue: this.state.defaultValue },
  });

  isMisConfigurationFeedbackDisplayed(): boolean {
    return this.misconfigurationFeedbackMessage && !this.state.hidden ? false : !!this.misconfigurationFeedbackMessage;
  }

  getFixItLinkLabel(): string {
    if (this.misconfigurationFeedbackMessage && !this.state.hidden) {
      return this._translateService.instant('tkey;smacs_text.misconfiguration_feedback.state.show', {
        defaultValue: this.state.defaultValue,
      });
    }

    return this.config.autogeneration?.linkLabel;
  }

  getPossibleOptions(): string[] {
    return this.currentValue.possibleOptions.filter((option: string) => {
      return !this.currentValue.defaultValues.includes(option);
    });
  }

  onValueClick() {
    this.licenseSelectInputClicked.emit(true);
  }

  private _setLocalTime(utc: string): void {
    const localMoment = moment.utc(utc, 'HH:mm').local();
    this.localTime = localMoment.isValid() ? localMoment.format('LT') : '';
  }
}
