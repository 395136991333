import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ControlSettings } from '../../shared/models/generated/smacsModels';

@Injectable()
export class DeviceSettingsResource {
  private _baseUrl = `/services/cisco/reporting/settings`;
  constructor(private $http: HttpClient) {}

  saveAdminDeviceSettings(settings: ControlSettings): Observable<void> {
    return this.$http.put<void>(this._baseUrl, settings);
  }

  getAdminDeviceSettings(): Observable<ControlSettings> {
    return this.$http.get<ControlSettings>(this._baseUrl);
  }
}
