import {
  AfterViewInit,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { combineLatest, forkJoin, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../../forms/fields/select/smacs-select.component';
import { catchError, first, map } from 'rxjs/operators';
import { CurrentClusterContext } from '../../../../contexts/current-cluster.context';
import {
  SearchDirectoryNumberResource
} from '../../../../../self-serve/resources/search/search-directory-number.resource';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../../forms/smacs-forms-models';
import {
  Button,
  ClusterResult,
  DirectoryNumberRef,
  DirectoryNumberResult,
  EndUser,
  EndUserRef,
  EndUserResult,
  Global360View,
  Phone,
  ServiceProvisioningLevel,
  SmtpAddress,
  UccxAgentResult,
  Voicemail,
  VoicemailRef
} from '../../../../models/generated/smacsModels';
import { HtmlInputType, SmacsTextConfig } from '../../../../../forms/fields/text/smacs-text.component';
import { LineButtonPeripheralSummary } from '../../../layout/phone-buttons-standard.component';
import { PhoneButtonsService } from '../../../shared/phone-buttons.service';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { TelephoneNumberFilter } from '../../../../filters/telephone-number.filter';
import { SmacsModalService } from '../../../../services/smacs-modal.service';
import { ToastService } from '../../../../services/toast.service';
import { ToastTypes } from '../../../../services/abstract/toast.service.abstract';
import { SmacsIcons } from '../../../../models/smacs-icons.enum';
import {
  SmacsDirectoryNumberDetailedModalComponent
} from '../../../../../helpdesk/directory-number-detailed-modal/directory-number-detailed-modal.component';
import { UserDetailUiContext } from '../../../../../helpdesk/shared/contexts/user-detail-ui.context';
import {
  VoicemailDetailedModalComponent
} from '../../../../../helpdesk/voicemail-detailed-modal/voicemail-detailed-modal.component';
import { EndUserResource } from '../../../../resources/end-user.resource';
import { Global360ViewContext } from '../../../../contexts/global-360-view.context';
import { cloneDeep, isEqual } from 'lodash';
import { VoicemailResource } from '../../../../resources/voicemail.resource';
import { ButtonStyles } from '../../../../../button/button.component';
import { UccxAgentResource } from '../../../../../helpdesk/shared/resources/uccx-agent.resource';
import { PhoneType } from '../../../../models/service-type';
import { DeviceService } from '../../../device.service';
import { ActivatedRoute } from '@angular/router';
import { PhoneUiContext } from '../../../contexts/phone-ui.context';
import { ExtensionMobilityDnD } from '../../../phone-buttons.component';
import { VoicemailService, VoicemailServiceParams } from '../../../../services/voicemail.service';
import { TileConfigContext } from '../../../../../helpdesk/shared/contexts/tile-config.context';
import { SiteContext } from '../../../../../helpdesk/shared/contexts/site.context';

interface PhoneButtonsLineExtensionSearchFormData {
  extensionSearch: SmacsSelectOption;
  voicemail: string;
  dialPlanGroupId: number;
}

export interface PhoneButtonsLineExtensionSearchEntity {
  dn: DirectoryNumberRef;
  peripherals: LineButtonPeripheralSummary;
}

@Component({
  selector: 'smacs-phone-buttons-line-extension-search',
  templateUrl: './phone-buttons-line-extension-search.component.html',
  providers: [VoicemailService],
})
export class PhoneButtonsLineExtensionSearchComponent
  extends SmacsFormAbstractDirective<PhoneButtonsLineExtensionSearchEntity, PhoneButtonsLineExtensionSearchFormData>
  implements OnInit, OnChanges, OnDestroy, AfterViewInit
{
  @Input() isPending = false;
  @Input() phone: Phone | ExtensionMobilityDnD;
  @Input() buttonIndex: number;
  @Input() dnRef: DirectoryNumberRef;
  @Input() lineButtonSummary: LineButtonPeripheralSummary;
  @Input() global360View: Global360View;
  @Input() isSingleButton: boolean;
  @Output() directoryNumberCreated = new EventEmitter<DirectoryNumberRef>();
  @Output() voicemailUpdated = new Subject<VoicemailRef>();
  @Output() reconsiderLineFeatureSettings = new EventEmitter<boolean>();

  isOneClickEnabled = true;
  isSelectCleared = false;
  siteId: number;
  voicemailRef: VoicemailRef;
  isVoicemailAdding: boolean;
  isVoicemailBeingFetched = false;
  endUser: EndUser;
  endUserResult: EndUserResult;
  username: string;
  hideHelpText = false;
  isLoading = true;
  modalData: any = null;
  unityServerId: number;
  phoneTypes = PhoneType;
  buttonStyle = ButtonStyles;
  formConfig: SmacsFormConfig;
  updateVmExtension = false;
  initialDnRef: string;
  phoneType: PhoneType;
  smacsIcons = SmacsIcons;

  private _otherButtons: Button[];
  private _subscription = new Subscription();
  private _currentCluster: ClusterResult;

  constructor(
    private currentClusterContext: CurrentClusterContext,
    private searchDirectoryNumberResource: SearchDirectoryNumberResource,
    private telephoneNumberFilter: TelephoneNumberFilter,
    private phoneButtonsService: PhoneButtonsService,
    protected smacsFormStateService: SmacsFormStateService,
    private smacsModalService: SmacsModalService,
    private toastService: ToastService,
    private userDetailUiContext: UserDetailUiContext,
    private endUserResource: EndUserResource,
    private global360ViewContext: Global360ViewContext,
    private voicemailResource: VoicemailResource,
    private siteContext: SiteContext,
    private injector: Injector,
    private route: ActivatedRoute,
    private _uccxAgentResource: UccxAgentResource,
    private voicemailService: VoicemailService,
    private tileConfigContext: TileConfigContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.voicemailRef = this.lineButtonSummary?.voicemail;
    this.phoneType = DeviceService.getPhoneTypeFromRoute(this.route.snapshot);
    this._initForm();
    if (this.phoneType === PhoneType.EXTENSION_MOBILITY) {
      this.endUserResult = this.userDetailUiContext.getCurrentEnduser();
      this.username = this.endUserResult?.ref.username;
    } else {
      this.endUserResult = (this.phone as Phone).owner && this.userDetailUiContext.getCurrentEnduser();
      this.username = this.endUserResult?.ref.username;
    }

    const siteSubscriptions = combineLatest([
      this.tileConfigContext.state$,
      this.siteContext.state$,
      this.currentClusterContext.state$,
    ]).subscribe(([tileConfig, siteResult, currentCluster]) => {
      this.siteId = siteResult.id;
      this.unityServerId = siteResult.unityServerId;
      this._currentCluster = currentCluster;
      this.isOneClickEnabled = this._hasOwner() && tileConfig.voicemail === ServiceProvisioningLevel.ONE_CLICK;
    });
    this._subscription.add(siteSubscriptions);

    const formUpdateSub = this.smacsFormsUpdate$.subscribe((update) => {
      this.hideHelpText = false;
      if (update.new.dn?.extension !== update.old?.dn?.extension) {
        this.fieldChannels.voicemail.entitySource.next('');
      }
      if (!!this.dnRef && !this.isSelectCleared) {
        this.voicemailRef = this.lineButtonSummary?.voicemail;
      }
      this.isSelectCleared = false;
      this.updateVmExtension = false;
      this._reValidateFields();
    });
    this._subscription.add(formUpdateSub);

    // assuming we won't be able to update other buttons while this popover is open...
    this._otherButtons = this.phone.buttons.filter((btn, idx) => idx !== this.buttonIndex);
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  addVoicemailOneClick() {
    this.isVoicemailAdding = true;
    const saveParams: VoicemailServiceParams = {
      endUserResult: this.userDetailUiContext.getCurrentEnduser(),
      endUsers: this.userDetailUiContext.getEndUsers(),
      unityServerId: this.unityServerId,
      siteId: Number(this.userDetailUiContext.getCurrentSite().id),
      dnRef: this.userDetailUiContext.getCurrentPrimaryExtension(),
      hasSnr: !!this.userDetailUiContext.hasSnr(),
      hasExtensionMobility: !!this.userDetailUiContext.hasExtensionMobility(),
    };
    this.voicemailService.provisionVoicemailWithDefaults(saveParams).subscribe((ref) => {
      this.lineButtonSummary = {
        ...this.lineButtonSummary,
        voicemail: ref,
      };
      this.phoneButtonsService.lineButtonSummary.next(this.lineButtonSummary);
      this.phoneButtonsService.isVoicemailDeleted.next(false);
      this.formData.voicemail = ref.alias;
      this._showVoicemailAddSuccessToast(ref);
      this._pushVoicemailRefToGlobal360(ref);
      this.isVoicemailAdding = false;
    });
  }

  private _initForm() {
    this.formConfig = {
      fields: {
        extensionSearch: {
          label: 'tkey;shared.device.phone.phone_buttons.line_button.line_extension.search.extension.label',
          dataAutomation: 'extension-search',
          validation: [
            {
              validator: (val: SmacsSelectOption) =>
                this.phoneButtonsService.validUniqueLineExtension(val?.value?.extension, this._otherButtons)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;shared.device.phone.phone_buttons.line_button.line_extension.search.unique.error',
            },
            {
              validator: (val: SmacsSelectOption) =>
                !val || this.formData.dialPlanGroupId !== null
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.WARNING,
              message: 'tkey;shared.device.phone.phone_buttons.line_button.line_extension.search.helptext.warning.text',
            },
          ],
          componentConfig: new SmacsSelectConfig({
            asyncOptionsFn: this.searchExtension.bind(this),
            placeholder: 'tkey;shared.device.phone.phone_buttons.line_button.line_extension.search.placeholder',
            triggerLoading: () => {
              return this.isPending;
            },
            clearWithInput: true,
            hideDropdownArrow: true,
            minSearchLength: 1,
          }),
          required: () => this.buttonIndex === 0,
          disabled: () => this.isSingleButton && this.phoneType !== PhoneType.DESKPHONE,
          disabledTooltip: 'tkey;shared.device.phone.phone_buttons.extension.disabled_tooltip',
        },
        voicemail: {
          label: 'tkey;shared.device.phone.phone_buttons.line_button.line_extension.search.voicemail_alias.label',
          dataAutomation: 'voicemail',
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.TEXT,
            readonly: true,
          }),
          required: false,
          hidden: () => true,
        },
      },
      options: {
        columnClasses: {
          label: 'col-3 text-lg-end',
          input: 'col-9',
        },
      },
    };
  }

  private _updateVoicemailToNewExtension() {
    return new Observable<boolean>((subscriber) => {
      this.isVoicemailBeingFetched = true;
      this.voicemailResource.get(this.unityServerId, this.voicemailRef.id).subscribe((currentVoicemail: Voicemail) => {
        const updatedVoicemail = {
          ...currentVoicemail,
          extension: this.dnRef.extension,
        };
        this.voicemailResource
          .put(this.unityServerId, currentVoicemail.id, updatedVoicemail)
          .pipe(
            catchError((response) => {
              this.voicemailRef = null;
              this.isVoicemailBeingFetched = false;
              return throwError(() => response);
            })
          )
          .subscribe((vmRef: VoicemailRef) => {
            const requests: Observable<void | void[] | SmtpAddress | EndUserRef[]>[] = [
              this.voicemailService.setForwardToVoicemail(false, this.username, this.dnRef, this.siteId),
            ];
            if (this._hasOwner()) {
              requests.push(
                this.voicemailService.setSmtpNotificationsDeviceDefaults(
                  vmRef,
                  this.username,
                  this.unityServerId,
                  this.siteId
                )
              );
              if (this.lineButtonSummary?.isPrimaryExtension) {
                requests.push(
                  this.voicemailService.setHomeCluster(
                    this.userDetailUiContext.getEndUsers(),
                    this.endUserResult.ref.id
                  )
                );
              }
            }
            forkJoin(requests)
              .pipe(first())
              .subscribe(() => {
                this.voicemailRef = vmRef;
                if (this._hasOwner()) {
                  const global360 = {
                    ...cloneDeep(this.userDetailUiContext.getGlobal360View()),
                    voicemails: [
                      vmRef,
                      ...this.userDetailUiContext.getGlobal360View().voicemails.filter((voicemailRef: VoicemailRef) => {
                        return voicemailRef.extension !== currentVoicemail.extension;
                      }),
                    ],
                  };
                  this.global360ViewContext._stateSource.next(global360);
                }
                this.formData.voicemail = vmRef.alias;
                this.isVoicemailBeingFetched = false;
                subscriber.next(true);
                subscriber.complete();
              });
          });
      });
    });
  }

  private _showVoicemailAddSuccessToast(voicemailRef: VoicemailRef) {
    this.toastService.push(
      ToastTypes.SUCCESS,
      this.smacsIcons.VOICEMAIL,
      'tkey;shared.toast.save.success.title',
      'tkey;userdetail.voicemail.save.toast.success.text',
      { alias: voicemailRef.alias }
    );
  }

  openDetailedModal() {
    const modalUsername =
      this.phoneType === PhoneType.EXTENSION_MOBILITY
        ? this.userDetailUiContext.getCurrentEnduser().ref.username
        : !!(this.phone as Phone).owner
        ? (this.phone as Phone).owner.username
        : null;
    const options = {
      size: 'xxl',
      modalViewProperties: {
        title: 'tkey;dndidselection.modal.legend',
        phone: this.phone,
        username: modalUsername,
        dnRef: this.dnRef,
        translationPattern: this.entity.peripherals?.didTranslationPattern,
        isEndUserPrimary: this.entity.peripherals?.isPrimaryExtension,
        lineNum: !!modalUsername ? null : this.buttonIndex,
        global360View: this.global360View,
        peripherals: this.entity.peripherals,
        setIsSaveDisabled: true,
        siteId: this.siteId,
        userDetailUiContext: this.userDetailUiContext,
      },
      bodyClass: SmacsDirectoryNumberDetailedModalComponent,
    };
    this.smacsModalService
      .openDetailedModal(() => options.modalViewProperties, options)
      .subscribe((result: any) => {
        if (!result) {
          return;
        }

        // This should be emitted first after closing the modal. Or else, we do not get the extension updated.
        this.directoryNumberCreated.emit(result);

        this.initialDnRef = this.dnRef ? cloneDeep(this.dnRef.extension) : cloneDeep(result.dnRef.extension);
        if (this.voicemailRef && !!this.dnRef && this.dnRef.extension !== result.dnRef.extension) {
          this.updateVmExtension = true;
          this._updateVoicemailToNewExtension().subscribe(() => this._pushVoicemailRefToGlobal360(this.voicemailRef));
        }

        if (result.translationPatterns) {
          this.phoneButtonsService.updateTpRef.next(result.translationPatterns);
        }

        /**If existing line button, update the line button summary **/
        if (this.lineButtonSummary) {
          const tpRef = result.translationPatterns
            ? result.translationPatterns
            : this.lineButtonSummary.didTranslationPattern;
          this.lineButtonSummary = {
            ...this.lineButtonSummary,
            didTranslationPattern: tpRef,
            isUccxAgent: !!result.endUser && !!result.endUser.ipccExtension?.id,
          };
          this.phoneButtonsService.lineButtonSummary.next(this.lineButtonSummary);
        }

        /** Update 360 view when modifying IPCC extension on a line button DN**/
        const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
        if (global360) {
          const ipccIndex = global360.ipccExtensions.findIndex(
            (extension: DirectoryNumberRef) => extension.id === result.dnRef.id
          );

          if (!!result.endUser && !!result.endUser.ipccExtension && !!result.endUser.ipccExtension?.id) {
            if (ipccIndex > -1) {
              global360.ipccExtensions[ipccIndex] = result.dnRef;
            } else {
              global360.ipccExtensions.push(result.dnRef);
              const username =
                this.phoneType === PhoneType.EXTENSION_MOBILITY
                  ? result.endUser.username
                  : (this.phone as Phone).owner.username;
              if (this.entity.peripherals?.isPrimaryExtension) {
                this._uccxAgentResource.search(username).subscribe((data: UccxAgentResult[]) => {
                  global360.uccxAgents = data.map((agentResult: UccxAgentResult) => agentResult.ref);
                  this._emitLineFeatureSettings();
                });
              } else {
                setTimeout(() => {
                  this._uccxAgentResource.search(username).subscribe((data: UccxAgentResult[]) => {
                    global360.uccxAgents = data.map((agentResult: UccxAgentResult) => agentResult.ref);
                    this.global360ViewContext._stateSource.next(global360);
                    this._emitLineFeatureSettings();
                  });
                });
              }
            }
            this._emitLineFeatureSettings();
          } else if (
            !!result.endUser &&
            !!result.endUser.ipccExtension &&
            !result.endUser.ipccExtension.id &&
            ipccIndex > -1
          ) {
            global360.ipccExtensions.splice(ipccIndex, 1);
            this._emitLineFeatureSettings();
          }
          this._emitLineFeatureSettings();
          this.global360ViewContext._stateSource.next(global360);
        }
        this.modalData = result;
      });
  }

  openAddOrEditVmDetailedModal(voicemailRef?: VoicemailRef) {
    this.isVoicemailAdding = true;

    (this._hasOwner()
      ? this.endUserResource.get(this.endUserResult.ref.id, this.endUserResult.ref.serverId)
      : of(null)
    ).subscribe((endUser: EndUser) => {
      const options = {
        size: 'lg',
        modalViewProperties: {
          title: voicemailRef
            ? 'tkey;shared.device.phone.phone_buttons.line_button.line_extension.voicemail.modal.edit.header_text'
            : 'tkey;shared.device.phone.phone_buttons.line_button.line_extension.voicemail.modal.add.header_text',
          phone: this.phone,
          button: this.phone.buttons[this.buttonIndex],
          username: this.username,
          dnRef: this.dnRef,
          siteId: this.siteId,
          unityServerId: this.unityServerId,
          voicemailId: voicemailRef?.id,
          serverId: this.dnRef.serverId,
          lineButtonSummary: this.lineButtonSummary,
          setLoading: true,
          voiceMail: {
            alias: '',
            displayName: '',
            extension: '',
            firstName: '',
            lastName: '',
          } as Voicemail,
          endUser: endUser,
          showDeleteButton: !!voicemailRef,
        },
        bodyClass: VoicemailDetailedModalComponent,
      };
      this.smacsModalService
        .openDetailedModal(() => options.modalViewProperties, options)
        .subscribe((result: any) => {
          if (!!result && !result.isCancelClicked) {
            if (!this.voicemailRef || (!!this.voicemailRef && !isEqual(this.voicemailRef, result))) {
              this._pushVoicemailRefToGlobal360(result);
            }
            this.voicemailRef = result;
            this.formData.voicemail = this.voicemailRef.alias;
            this.isVoicemailAdding = false;
          } else if (!result) {
            this.phoneButtonsService.isVoicemailDeleted.subscribe((isVMDeleted) => {
              if (isVMDeleted) {
                this._removeVoicemailFromGlobal360(this.voicemailRef.id);
                if (this.isSingleButton) {
                  this.isVoicemailAdding = false;
                }
              }
            });
          }
        });
    });
  }

  searchExtension(q: string): Observable<SmacsSelectOption[]> {
    const serverId = this._currentCluster.cucmServerId;
    return this.searchDirectoryNumberResource
      .get({
        q,
        'cucm-server-id': serverId,
      })
      .pipe(
        map((results: DirectoryNumberResult[]) => {
          return results.map((result: DirectoryNumberResult) => {
            return {
              label: this._getExtensionSearchLabel(result.ref),
              value: result.ref,
            };
          });
        })
      );
  }

  submit() {
    return this.parentSubmit$;
  }

  private _getFullExtensionDisplayLabel(directoryNumberRef: DirectoryNumberRef, translationPattern: string): string {
    let label = directoryNumberRef.extension;

    if (translationPattern && !directoryNumberRef.extension.includes(translationPattern)) {
      label += ` | ${this.telephoneNumberFilter.transform(translationPattern)}`;
    }
    if (directoryNumberRef.description) {
      label += ` - ${directoryNumberRef.description}`;
    }

    return label;
  }

  private _getExtensionSearchLabel = (directoryNumberRef: DirectoryNumberRef): string => {
    return directoryNumberRef.description
      ? `${directoryNumberRef.extension} - ${directoryNumberRef.description}`
      : directoryNumberRef.extension;
  };

  handleSelectClear() {
    this.hideHelpText = false;
    this.isSelectCleared = true;
    if (this._hasOwner()) {
      const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
      global360.voicemails = global360.voicemails.filter(
        (vmRef: VoicemailRef) => vmRef.extension !== this.dnRef.extension
      );
      this.global360ViewContext._stateSource.next(global360);
    }
    this.voicemailRef = null;
  }

  handleInputChange = ($event: string) => {
    if (typeof $event === 'string') {
      this.hideHelpText = $event.length >= 2;
    }
  };

  handleOnBlur() {
    this.hideHelpText = false;
    // To prevent loss of input text if we backspace and leave the input
    if (!this.formData.extensionSearch) {
      this.entitySource.next({
        ...this.entity,
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    this._validateExtensionField();
    if (
      !!changes.lineButtonSummary &&
      !changes.lineButtonSummary.isFirstChange() &&
      changes.lineButtonSummary.previousValue
    ) {
      this.phoneButtonsService.lineButtonSummary.next(changes.lineButtonSummary.currentValue);
    }
  }

  isEndUserPrimary(): boolean {
    return this.global360View?.primaryExtensions.some((ext) => ext.id === this.dnRef?.id);
  }

  private _validateExtensionField() {
    this.fieldChannels['extensionSearch']?.validateSource.next();
  }

  toEntity = (formData: PhoneButtonsLineExtensionSearchFormData): PhoneButtonsLineExtensionSearchEntity => {
    return {
      peripherals:
        formData.extensionSearch && formData.extensionSearch.value.id === this.entity.peripherals?.id
          ? this.entity.peripherals
          : null,
      dn: formData.extensionSearch ? formData.extensionSearch.value : null,
    };
  };

  toFormData = (entity: PhoneButtonsLineExtensionSearchEntity): PhoneButtonsLineExtensionSearchFormData => {
    return {
      extensionSearch: entity.dn
        ? {
            label: this._getFullExtensionDisplayLabel(entity.dn, entity.peripherals?.didTranslationPattern?.pattern),
            value: entity.dn,
          }
        : null,
      voicemail: entity.peripherals?.voicemail ? entity.peripherals.voicemail.alias : '',
      dialPlanGroupId: entity.peripherals?.dialPlanGroupId,
    };
  };

  private _reValidateFields() {
    this.fieldComponents.forEach((fieldComponent) => {
      fieldComponent.showValidation = true;
    });
    this.fieldChannels['extensionSearch'].validateSource.next();
  }

  private _emitLineFeatureSettings() {
    this.reconsiderLineFeatureSettings.emit(true);
  }

  private _hasOwner() {
    return this.phoneType === PhoneType.EXTENSION_MOBILITY || (this.phone as Phone).owner;
  }

  private _pushVoicemailRefToGlobal360(voicemailRef: VoicemailRef): void {
    this.voicemailUpdated.next(voicemailRef);
    if (this._hasOwner()) {
      const isVmOnPrimaryExtension =
        voicemailRef.extension === this.userDetailUiContext.getCurrentPrimaryExtension().extension;
      if (isVmOnPrimaryExtension) {
        const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
        global360.voicemails.push(voicemailRef);
        this.global360ViewContext._stateSource.next(global360);
      }
    } else if (this.buttonIndex === 0) {
      // If a public phone
      if (this.phoneType !== PhoneType.EXTENSION_MOBILITY) {
        this.injector.get(PhoneUiContext).setIsVoicemailPresentOnLineOne(true);
      }
    }
  }

  private _removeVoicemailFromGlobal360(voicemailId: string): void {
    if (typeof voicemailId === 'string') {
      const global360View = cloneDeep(this.userDetailUiContext.getGlobal360View());
      if (global360View) {
        global360View.voicemails = global360View?.voicemails.filter((voicemail) => voicemail.id !== voicemailId);
        this.global360ViewContext._stateSource.next(global360View);
      }
    }
  }
}
