import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SmacsIcons } from '../../models/smacs-icons.enum';

@Component({
  selector: 'side-navigation-search',
  templateUrl: './side-navigation-search.component.html',
  styleUrls: ['./side-navigation-search.component.scss'],
})
export class SideNavigationSearchComponent {
  @Input() searchFilter: string;
  @Input() minimized = false;
  @Output() searchFilterHasUpdated = new EventEmitter<string>();

  icons = SmacsIcons;

  outputSearchFilter() {
    this.searchFilterHasUpdated.emit(this.searchFilter);
  }

  clearFilterClicked() {
    this.searchFilter = '';
    this.outputSearchFilter();
  }
}
