import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { ZiroSupportSettings } from '../../../../shared/models/generated/smacsModels';
import { Observable, Subscription } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ZiroSupportSettingsResource } from '../../../resources/ziro-support-settings.resource';
import { ToastService } from '../../../../shared/services/toast.service';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { map } from 'rxjs/operators';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ziro-support-settings',
  templateUrl: './ziro-support-settings.component.html',
  styleUrls: ['../../../admin-page.scss'],
})
export class ZiroSupportSettingsComponent
  extends SmacsFormAbstractDirective<ZiroSupportSettings>
  implements OnInit, OnDestroy
{
  formConfig: SmacsFormConfig = {
    fields: {
      pwshCmdletDelayInSeconds: {
        label: 'tkey;admin.microsoft.general_ziro_support_settings.pwsh_cmdlet_delay.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        required: true,
        dataAutomation: 'pwsh-cmdlet-delay-input',
        helpText: 'tkey;admin.microsoft.general_ziro_support_settings.pwsh_cmdlet_delay.helptext',
      },
      pwshUserCacheExpiryInSeconds: {
        label: 'tkey;admin.microsoft.general_ziro_support_settings.pwsh_cache_expiry.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER }),
        required: true,
        dataAutomation: 'pwsh-cache-expiry-input',
        helpText: () => {
          return this._translateService.instant(
            'tkey;admin.microsoft.general_ziro_support_settings.pwsh_cache_expiry.helptext',
            { amount: this.formData.pwshUserCacheExpiryInSeconds }
          );
        },
      },
      pwshPoolSize: {
        label: 'tkey;admin.microsoft.general_ziro_support_settings.pwsh_pool_size.label',
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER, max: 10, min: 1 }),
        required: true,
        dataAutomation: 'pwsh-client-pool-size-input',
        helpText: 'tkey;admin.microsoft.general_ziro_support_settings.pwsh_pool_size.helptext',
        validation: [
          {
            validator: (val: string) => {
              return Number(val) >= 1 && Number(val) <= 10
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID;
            },
            message: 'tkey;admin.microsoft.general_ziro_support_settings.pwsh_pool_size.validation',
          },
        ],
      },
    },
  };
  isLoading = true;

  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormsStateService: SmacsFormStateService,
    private _breadcrumbsService: BreadcrumbsService,
    private _bottomNavService: BottomNavService,
    private _ziroSupportSettingsResource: ZiroSupportSettingsResource,
    private _toastService: ToastService,
    private _translateService: TranslateService
  ) {
    super(smacsFormsStateService);
  }

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.microsoft.general_ziro_support_settings.title' }]);

    this._ziroSupportSettingsResource.get().subscribe((settings) => {
      this.entitySource.next(settings);
    });

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() =>
      this._bottomNavService.setBottomNavValidationError(!this.isFormValid())
    );
    this._subscriptions.add(formSubmittedSub);

    this._initBottomNav();
    this.isLoading = false;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  protected submit(): Observable<void> {
    this._setSavePendingState(true);

    return this._ziroSupportSettingsResource.put(this.entity).pipe(
      map(() => {
        this.smacsFormsStateService.setIsFormDirty(false);
        this._setSavePendingState(false);
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.ZIRO,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.microsoft.general_ziro_support_settings.save.toast.message'
        );
      })
    );
  }

  private _initBottomNav() {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'ziro-support-settings-save-button',
          dataAutomation: 'ziro-support-settings-save-button',
          label: 'tkey;admin.ui.save',
          buttonClass: ButtonStyles.PRIMARY,
          state: {
            pending: false,
            buttonDisableState: { disabled: false, tooltipKey: '' },
          },
          icon: SmacsIcons.OK,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );
  }

  private _setSavePendingState(state: boolean) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'ziro-support-settings-save-button',
        state: {
          pending: state,
        },
      })
    );
  }
}
