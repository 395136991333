import { toPairs } from 'lodash';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { AuthenticationContext } from '../../shared/contexts/authentication.context';
import { CurrentClusterContext } from '../../shared/contexts/current-cluster.context';
import { ModalViewProperties } from '../modal-view-properties';
import { StepsToReproduce } from '../steps-to-reproduce';
import { lastValueFrom, Subscription } from 'rxjs';
import {
  ClusterResult,
  CurrentUser,
  FrontendError,
  GlobalProperties,
  Role,
} from '../../shared/models/generated/smacsModels';
import { ButtonStyles } from '../../button/button.component';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { GlobalPropertiesContext } from '../../shared/contexts/global-properties.context';

enum ModalState {
  STACKTRACE,
  SEND_REPORT,
  REPORT_SENT,
}

@Component({
  selector: 'app-oops-modal',
  templateUrl: './oops-modal.component.html',
  styleUrls: ['./oops-modal.component.scss'],
})
export class OopsModalComponent implements OnInit, OnDestroy {
  ModalState = ModalState;
  state = ModalState.STACKTRACE;
  modalViewProperties: ModalViewProperties;
  stepsToReproduce: StepsToReproduce;
  oopsDialogForm: any;
  currentUser: CurrentUser;
  canSendReport = false;

  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;

  mailToInfo = {
    email: 'SoftwareSupport@stack8.com',
    subject: encodeURIComponent('Smacs Error Report'),
  };

  private _subs = new Subscription();
  private _currentCluster: ClusterResult;
  private _globalProperties: GlobalProperties;

  constructor(
    private activeModal: NgbActiveModal,
    private http: HttpClient,
    private router: Router,
    private globalPropertiesContext: GlobalPropertiesContext,
    private authenticationContext: AuthenticationContext,
    private currentClusterContext: CurrentClusterContext
  ) {}

  ngOnInit() {
    const authSub = this.authenticationContext.state$.subscribe((user: CurrentUser) => {
      this.currentUser = user;
      this.canSendReport = this.authenticationContext.userIsAtLeast(user, Role.S8_ADMIN);
    });
    this._subs.add(authSub);
    const currentContextSub = this.currentClusterContext.state$.subscribe((data: ClusterResult) => {
      this._currentCluster = data;
    });
    this._subs.add(currentContextSub);

    const globalPropertySub = this.globalPropertiesContext.state$.subscribe((state) => {
      this._globalProperties = state;
    });
    this._subs.add(globalPropertySub);

    this.initializeStacktrace();
    this.sendErrorToServer().then(
      (data) => {
        console.warn('Error data sent to server:', data);
      },
      (error) => {
        console.error('Error sending client log:', error);
      }
    );
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  // TODO: reload if on 'home' state
  onHomeClicked() {
    this.goToUserHome(this.currentUser);
  }

  onClose() {
    this.activeModal.close();
  }

  buildDeveloperProperties(): FrontendError {
    const developerProperties = {
      currentUrl: window.location.href,
      userAgent: navigator.userAgent,
    } as FrontendError;

    if (this._globalProperties) {
      developerProperties.buildVersion = this._globalProperties.version;
    }

    developerProperties.cluster = this._currentCluster ? this._currentCluster.name : '';

    if (this.currentUser?.role) {
      developerProperties.currentUser = this.currentUser.userId;
      developerProperties.currentUserRole = this.currentUser.role;
    }

    if (this.modalViewProperties['errorSummary']) {
      developerProperties.errorSummary = this.modalViewProperties['errorSummary'];
    }

    if (this.modalViewProperties['stacktrace']) {
      developerProperties.stacktrace = this.modalViewProperties['stacktrace'];
    }

    return developerProperties;
  }

  trimStacktraceIfNecessary(developerProperties: FrontendError) {
    // According to https://stackoverflow.com/a/417184/536910, 2000 is the max "safe" length of a mailto
    // string. This still applies in 2020 because even though it's 2020 Microsoft is still Microtrash.
    // ...
    // So if the user doesn't enter more than 900 characters, and our stacktrace is a max of 1000
    // characters, we should be fine...
    if (developerProperties['stacktrace'].length > 1000) {
      developerProperties['stacktrace'] = developerProperties['stacktrace'].substring(0, 1000);
    }
  }

  sendSupportEmail() {
    if (!this.modalViewProperties.stepsToReproduce || this.modalViewProperties.stepsToReproduce.length < 5) {
      return;
    }

    const developerProperties = this.buildDeveloperProperties();
    this.trimStacktraceIfNecessary(developerProperties);

    const propertiesToAppend = toPairs(developerProperties)
      .map((entry) => `${entry[0]} = ${entry[1]}`)
      .join('\n');

    const { email, subject } = this.mailToInfo;

    this.modalViewProperties.stepsToReproduce += '\n\n' + propertiesToAppend;

    window.open(
      `mailto:${email}?subject=${subject}&body=${encodeURIComponent(this.modalViewProperties.stepsToReproduce)}`
    );

    this.state = ModalState.REPORT_SENT;
  }

  initializeStacktrace() {
    const stacktrace = this.modalViewProperties['stacktrace'];
    if (stacktrace && typeof stacktrace !== 'string') {
      try {
        this.modalViewProperties['stacktrace'] = JSON.stringify(stacktrace, null, 2);
      } catch (e) {
        // Don't rethrow the exception otherwise it'll be error modal inception
        console.error('Tried to pretty-print stacktrace, but failed: ' + e);
      }
    }

    const stacktraceHasNewLines = stacktrace && typeof stacktrace === 'string' && stacktrace.split('\n').length > 1;
    if (stacktraceHasNewLines && !this.modalViewProperties['errorSummary']) {
      // Show the first line of the stack trace as the error summary if none was provided
      this.modalViewProperties['errorSummary'] = stacktrace.split('\n')[0];
    }

    this.modalViewProperties.expanded = this.modalViewProperties.stacktrace && !this.modalViewProperties.errorSummary;
  }

  sendErrorToServer(): Promise<any> {
    const obs = this.http.post('/services/logs/client-error', this.buildDeveloperProperties());
    return (window as any).environment.production ? lastValueFrom(obs) : Promise.reject('skipping (in dev mode)');
  }

  private goToUserHome(currentUser: CurrentUser) {
    this.activeModal.close();

    if (this.authenticationContext.userIsAtLeast(currentUser, Role.S8_ADMIN)) {
      this.router.navigateByUrl('/home');
    } else if (currentUser.role === Role.S8_SELF_SERVE_USER) {
      this.router.navigateByUrl('/self-serve');
    } else {
      this.router.navigateByUrl('/');
    }
  }
}
