import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UiProperties } from '../models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CustomizeUiResource {
  constructor(private http: HttpClient) {}

  getUiProperties(): Observable<UiProperties> {
    return this.http.get<UiProperties>('/services/system/admin/ui-customizations');
  }

  saveUiProperties(formData: FormData): Observable<void> {
    return this.http.put<void>('/services/system/admin/ui-customizations/file-upload', formData);
  }

  deleteImage(type: 'LOGO' | 'BACKGROUND_IMAGE'): Observable<void> {
    return this.http.delete<void>(`/services/system/admin/ui-customizations?type=${type}`);
  }
}
