import { SmacsFormAbstractDirective } from '../forms/smacs-form-abstract.directive';
import { Observable } from 'rxjs';
import { TemplateRef } from '@angular/core';

export abstract class ModalBodyClass extends SmacsFormAbstractDirective<any> {
  /** Defined by the parent. Do not override. */
  submitFn: (...args: any) => Observable<any>;
  /** Custom error templates that can be set for detailed modal components */
  errorTemplate?: TemplateRef<any>;
  /** Passed to field and stops field from marking form as dirty via SmacsFormStateService  */
  isModal = true;
  /** A method that calls submitFn with the required arguments and returns the observable given by submitFn. */
  abstract submit(): Observable<any>;

  /** Optional method in case we need to have a delete button in the detailed modal component */
  delete?(): Observable<void>;
  /** Optional method in case we need to redefine the behaviour of the cancel in the detailed modal component*/
  cancel?(): void;
}
