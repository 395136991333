import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, QueryList } from '@angular/core';
import { BreadcrumbsService } from './breadcrumbs.service';
import { MenuItem } from 'primeng/api';
import { BreadcrumbCardItemComponent } from './breadcrumb-card-item/breadcrumb-card-item.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements AfterContentInit, OnDestroy {
  @Input() tooltip = 'tkey;shared.about';
  @Input() labelParam?: string;
  @Input() beta = false;
  @Input() betaTooltip?: string;

  @ContentChildren(BreadcrumbCardItemComponent) items: QueryList<BreadcrumbCardItemComponent>;
  breadcrumbs = [] as MenuItem[];
  window = window;
  isExpansionToggled = false;

  private _subscriptions = new Subscription();

  constructor(private breadcrumbsService: BreadcrumbsService) {
    const breadcrumbItemSubscription = this.breadcrumbsService.breadcrumbs$.subscribe((breadcrumbs: MenuItem[]) => {
      this.breadcrumbs = breadcrumbs;
    });
    this._subscriptions.add(breadcrumbItemSubscription);
  }

  ngAfterContentInit() {
    if (this.items.length === 1) {
      this.items.first.shown = true;
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  handleExpansionClick() {
    this.isExpansionToggled = !this.isExpansionToggled;
  }

  scrollToAndExpand() {
    document.querySelectorAll(`[data-automation="smacs-breadcrumbs"]`)[0].scrollIntoView();
    this.isExpansionToggled = true;
  }
}
