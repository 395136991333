import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject, Subject, Subscriber } from 'rxjs';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import {
  ClusterResult,
  DirectoryNumberRef,
  EndUserResult,
  ExtensionMobilityRef,
  Global360View,
  LdapUser,
  PcceAgentRef,
  PhoneRef,
  SiteResult,
  SiteSummary,
  SnrProfileRef,
  TranslationPatternRef,
  UccxAgentRef,
  VoicemailRef,
  WebexEndUser,
} from '../../../shared/models/generated/smacsModels';
import { SiteContext } from './site.context';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { distinctUntilChanged, first, skip, take } from 'rxjs/operators';
import { isEqual } from 'lodash';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../button/button.component';
import {
  PrimaryExtensionPickerModalComponent,
  PrimaryExtensionPickerModalComponentReturnValue,
} from '../../../modals/primary-extension-picker-modal/primary-extension-picker-modal.component';
import { Router } from '@angular/router';
import { SvgLoaderContext } from '../../../shared/contexts/svg-loader.context';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';

export enum KnownPhoneModels {
  CIPC = 'Cisco IP Communicator',
  IM_SOFTPHONE = 'Cisco Unified Client Services Framework',
  IPHONE = 'Cisco Dual Mode for iPhone',
  ANDROID = 'Cisco Dual Mode for Android',
  TABLET = 'Cisco Jabber for Tablet',
  SNR = 'Remote Destination Profile',
  CTI_PORT = 'CTI Port',
  CTI_REMOTE_DEVICE = 'CTI Remote Device',
  SPARK_REMOTE_DEVICE = 'Cisco Spark Remote Device',
  DESKPHONE = '',
}

export type PhoneDescriptions = (typeof KnownPhoneModels)[keyof typeof KnownPhoneModels];

export const isNonDeskPhone = (model: any): model is KnownPhoneModels =>
  Object.values(KnownPhoneModels).includes(model as KnownPhoneModels);

/**
 * In order to get the user site, this context must be instantiated in the component, even if the component makes no
 * calls to this context directly
 */
@Injectable()
export class UserDetailUiContext {
  private _stateSource = new ReplaySubject<number>(1);
  state$ = this._stateSource.asObservable();
  shouldInitTiles = new Subject<boolean>();
  showPrimaryExtensionPickerModal = false;

  private _global360View: Global360View;
  private _primaryExtCandidates: DirectoryNumberRef[];
  private _currentSite: SiteResult;
  private _isInitialLoad = true;
  private _areClustersConfigured = true;

  private _newSite: SiteResult;
  private _currentCluster: ClusterResult;

  constructor(
    private global360ViewContext: Global360ViewContext,
    private siteContext: SiteContext,
    private currentClusterContext: CurrentClusterContext,
    private smacsModalService: SmacsModalService,
    private _router: Router,
    private _svgLoaderContext: SvgLoaderContext,
    private _siteSummaryContext: SiteSummaryContext
  ) {
    this._svgLoaderContext.init();

    combineLatest([
      this._svgLoaderContext.state$,
      this.currentClusterContext.state$,
      this._siteSummaryContext.state$,
    ]).subscribe(([svgLoaderState, currentCluster, siteSummary]) => {
      this._currentCluster = currentCluster;
      this._areClustersConfigured = siteSummary.clusters.length > 0;

      if (svgLoaderState) {
        this._init(siteSummary);
      } else {
        this._svgLoaderContext.loadSvgs().subscribe();
      }
    });
  }

  getVoicemailResults(): VoicemailRef[] {
    return this._global360View.voicemails.filter((vmRef: VoicemailRef) => {
      return vmRef.extension === this.getCurrentPrimaryExtension()?.extension;
    });
  }

  getCurrentVoicemail(): VoicemailRef {
    if (
      this._global360View.voicemails.length === 1 &&
      this._global360View.primaryExtensions.length === 1 &&
      !!this.getCurrentPrimaryExtension()
    ) {
      // this is to support clients whose voicemail extensions don't match their primary extensions, see #12781.
      return this._global360View.voicemails[0];
    }
    return this._global360View.voicemails.find((vmRef: VoicemailRef) => {
      return vmRef.extension === this.getCurrentPrimaryExtension()?.extension;
    });
  }

  getCurrentVoicemailFromExtension(extension: string): VoicemailRef {
    return this._global360View.voicemails.find((vmRef: VoicemailRef) => {
      return vmRef.extension === extension;
    });
  }

  getCurrentPrimaryExtension(): DirectoryNumberRef {
    if (this._areClustersConfigured) {
      return this._global360View.primaryExtensions.find((dnRef: DirectoryNumberRef) => {
        return dnRef.serverId === this._currentCluster.cucmServerId;
      });
    } else {
      return this._global360View.primaryExtensions[0] ?? null;
    }
  }

  getCurrentIpccExtension(): DirectoryNumberRef {
    if (this._areClustersConfigured) {
      return this._global360View.ipccExtensions.find((ref: DirectoryNumberRef) => {
        return ref.serverId === this._currentCluster.cucmServerId;
      });
    } else {
      return this._global360View.ipccExtensions[0] ?? null;
    }
  }

  getPrimaryExtensionCandidates(): DirectoryNumberRef[] {
    if (this._areClustersConfigured) {
      return this._global360View.primaryExtensionCandidates.filter(
        (ref: DirectoryNumberRef) => ref.serverId === this._currentCluster.cucmServerId
      );
    } else {
      return this._global360View.primaryExtensionCandidates;
    }
  }

  getCurrentSnrProfile(): SnrProfileRef {
    if (this._areClustersConfigured) {
      return this._global360View.snrProfiles.find((ref: SnrProfileRef) => {
        return ref.serverId === this._currentCluster.cucmServerId;
      });
    } else {
      return this._global360View.snrProfiles[0] ?? null;
    }
  }

  getCurrentExtensionMobility(): ExtensionMobilityRef {
    if (this._areClustersConfigured) {
      return this._global360View.extensionMobilities.find((ref: ExtensionMobilityRef) => {
        return ref.serverId === this._currentCluster.cucmServerId;
      });
    } else {
      return this._global360View.extensionMobilities[0] ?? null;
    }
  }

  getCurrentTranslationPattern(): TranslationPatternRef {
    if (this._areClustersConfigured) {
      return this._global360View.translationPatterns.find((translationPatternRef: TranslationPatternRef) => {
        return translationPatternRef.serverId === this._currentCluster.cucmServerId;
      });
    } else {
      return this._global360View.translationPatterns[0] ?? null;
    }
  }

  getCurrentPhoneByModel(phoneModel: KnownPhoneModels): PhoneRef {
    if (this._areClustersConfigured) {
      return this._global360View.phones.find((ref: PhoneRef) => {
        return ref.serverId === this._currentCluster.cucmServerId && ref.model === phoneModel;
      });
    } else {
      return this._global360View.phones.find((ref) => {
        return ref.model === phoneModel;
      });
    }
  }

  getCurrentPhonesByModel(phoneModel: KnownPhoneModels): PhoneRef[] {
    if (this._areClustersConfigured) {
      return this._global360View.phones.filter((ref: PhoneRef) => {
        return ref.serverId === this._currentCluster.cucmServerId && ref.model === phoneModel;
      });
    } else {
      return this._global360View.phones.filter((ref) => ref.model === phoneModel);
    }
  }

  getDeskphones(): PhoneRef[] {
    const models = [
      'Cisco IP Communicator',
      'Cisco Unified Client Services Framework',
      'Cisco Dual Mode for iPhone',
      'Cisco Dual Mode for Android',
      'Cisco Jabber for Tablet',
      'Remote Destination Profile',
      'CTI Port',
      'CTI Remote Device',
      'Cisco Spark Remote Device',
    ];
    if (this._areClustersConfigured) {
      return this._global360View.phones.filter((ref: PhoneRef) => {
        return ref.serverId === this._currentCluster.cucmServerId && !models.includes(ref.model);
      });
    } else {
      return this._global360View.phones.filter((ref) => !models.includes(ref.model));
    }
  }

  getCurrentEnduser(): EndUserResult {
    return (
      this._global360View.endUsers.find((endUser: EndUserResult) => {
        return endUser.ref.serverId === this._currentCluster.cucmServerId;
      }) || this._global360View.endUsers[0]
    );
  }

  getTranslationPatternsOfCurrentServer(): TranslationPatternRef[] {
    return this._global360View.translationPatterns.filter((translationPattern: TranslationPatternRef) => {
      return translationPattern.serverId === this._currentCluster.cucmServerId;
    });
  }

  getPrimaryExtensionsOfCurrentCluster(): DirectoryNumberRef[] {
    if (this._areClustersConfigured) {
      return this._global360View.primaryExtensions.filter((directoryNumber: DirectoryNumberRef) => {
        return directoryNumber.serverId === this._currentCluster.cucmServerId;
      });
    } else {
      return this._global360View.primaryExtensions;
    }
  }

  getCurrentSite(): SiteResult {
    return this._currentSite;
  }

  getLdapUser(): LdapUser {
    if (!this._global360View) {
      return null;
    }

    return this._global360View.ldapUser;
  }

  getUccxAgents(): UccxAgentRef[] {
    return this._global360View.uccxAgents;
  }

  getPcceAgents(): PcceAgentRef[] {
    return this._global360View.pcceAgents;
  }

  getAssociatedPublicPhones(): PhoneRef[] {
    if (this._areClustersConfigured) {
      return this._global360View.associatedPublicPhones.filter((ref: PhoneRef) => {
        return ref.serverId === this._currentCluster.cucmServerId;
      });
    } else {
      return this._global360View.associatedPublicPhones;
    }
  }

  getWebexEndUser(): WebexEndUser {
    return this._global360View.webexEndUser;
  }

  hasWebexEndUser(): boolean {
    return !!this._global360View.webexEndUser && !!this._global360View.webexEndUser.webexCallingLicense;
  }

  hasExtensionMobility(): boolean {
    if (this._areClustersConfigured) {
      return !!this._global360View.extensionMobilities.find((ref: ExtensionMobilityRef) => {
        return ref.serverId === this._currentCluster.cucmServerId;
      });
    } else {
      return this._global360View.extensionMobilities.length > 0;
    }
  }

  getExtensionMobilities(): ExtensionMobilityRef[] {
    if (this._areClustersConfigured) {
      return this._global360View.extensionMobilities.filter((ref: ExtensionMobilityRef) => {
        return ref.serverId === this._currentCluster.cucmServerId;
      });
    } else {
      return this._global360View.extensionMobilities;
    }
  }

  hasSnr(): boolean {
    if (this._areClustersConfigured) {
      return !!this._global360View.snrProfiles.find((ref: SnrProfileRef) => {
        return ref.serverId === this._currentCluster.cucmServerId;
      });
    } else {
      return this._global360View.snrProfiles.length > 0;
    }
  }

  hasPhones(): boolean {
    const deskphones = this.getDeskphones();
    const imSoftphone = this.getCurrentPhoneByModel(KnownPhoneModels.IM_SOFTPHONE);
    const cipc = this.getCurrentPhoneByModel(KnownPhoneModels.CIPC);
    const iphone = this.getCurrentPhoneByModel(KnownPhoneModels.IPHONE);
    const android = this.getCurrentPhoneByModel(KnownPhoneModels.ANDROID);
    const tablet = this.getCurrentPhoneByModel(KnownPhoneModels.TABLET);

    return !!(deskphones.length || imSoftphone || cipc || iphone || android || tablet);
  }

  getGlobal360View(): Global360View {
    return this._global360View;
  }

  getEndUsers(): EndUserResult[] {
    return this._global360View.endUsers;
  }

  initOnCluster(cluster: ClusterResult, siteSummary: SiteSummary) {
    const initialSite = this.getCurrentSite();

    this.global360ViewContext.state$.pipe(take(1)).subscribe((state: Global360View) => {
      if (state) {
        this._global360View = state;
        this.currentClusterContext.state$.pipe(take(1)).subscribe(() => {
          this.siteContext.setSiteForUserOnCluster(this._global360View, cluster, initialSite, siteSummary);
        });
      } else {
        window.location.href = '/';
      }
    });
  }

  setHasChangedSite(newSite: SiteResult) {
    this._newSite = newSite;
  }

  isPrimaryExtPickerModalDisplayed(): boolean {
    return this.showPrimaryExtensionPickerModal;
  }

  getAreClustersConfigured(): boolean {
    return this._areClustersConfigured;
  }

  private _init(siteSummary: SiteSummary) {
    this.global360ViewContext.state$
      .pipe(
        distinctUntilChanged((prev, curr) => {
          return isEqual(prev, curr);
        })
      )
      .subscribe((state: Global360View) => {
        if (state) {
          if (this._global360View && state && isEqual(this._global360View, state)) {
            return;
          }

          const hasChangedUser = this._global360View?.endUsers[0].ref.username !== state.endUsers[0].ref.username;
          this._global360View = state;

          if (this._isInitialLoad || hasChangedUser) {
            this.currentClusterContext.state$.pipe(take(1)).subscribe(() => {
              this.siteContext.setSiteForUser(this._global360View, siteSummary);
            });
            this._isInitialLoad = false;
          } else if (this._newSite) {
            this.siteContext.setSiteForUser(this._global360View, siteSummary, this._newSite);
            this.setHasChangedSite(null);
          }
          this._resolveSiteContext();
        } else {
          window.location.href = '/';
        }
      });
  }

  private _showPrimaryExtensionPickerModal = (
    user: EndUserResult,
    extensions: DirectoryNumberRef[]
  ): Observable<void> => {
    this.showPrimaryExtensionPickerModal = true;
    const options = {
      modalViewProperties: {
        icon: SmacsIcons.EXCLAMATION_CIRCLE,
        iconClass: 'text-info',
        title: 'tkey;pages.userdetail.dnpicker.header',
        promptBody: 'tkey;pages.userdetail.dnpicker.text',
        displayCloseButton: true,
        user: user,
        extensions: extensions,
        buttons: [
          {
            label: 'tkey;pages.userdetail.dnpicker.button',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'smacs-primary-extension-picker-modal-submit',
            isSubmitButton: true,
          },
        ],
      },
      bodyClass: PrimaryExtensionPickerModalComponent,
    };

    return new Observable<void>((subscriber: Subscriber<void>) => {
      this.smacsModalService
        .openPromptModal(() => options.modalViewProperties, options)
        .subscribe((returnValue: PrimaryExtensionPickerModalComponentReturnValue) => {
          if (returnValue) {
            this.global360ViewContext.init(this._global360View?.endUsers[0].ref.username);
            this.global360ViewContext.state$.pipe(skip(1), first()).subscribe(() => {
              this.showPrimaryExtensionPickerModal = false;
              this.shouldInitTiles.next(true);
              subscriber.next();
              subscriber.complete();
            });
          } else {
            this._router.navigateByUrl('/home');
          }
        });
    });
  };

  private _resolveSiteContext() {
    this.siteContext.state$.subscribe((site) => {
      this._currentSite = site;
      this._stateSource.next(Number(site.id));
      this._promptForPrimaryExtension();
    });
  }

  private _resolvePrimaryExtension() {
    if (
      this._global360View?.phones?.length > 0 ||
      this._global360View?.extensionMobilities?.length > 0 ||
      this._global360View?.snrProfiles?.length > 0
    ) {
      if (this._primaryExtCandidates?.length && this._global360View.sites.length) {
        this._showPrimaryExtensionPickerModal(this.getCurrentEnduser(), this._primaryExtCandidates).subscribe(() => {
          this.showPrimaryExtensionPickerModal = false;
        });
      }
    }
  }

  private _promptForPrimaryExtension() {
    // This setTimeout is needed only because of the way currentClusterContext works.
    // Remove the setTimeout once currentClusterContext is removed.
    window.setTimeout(() => {
      if (!this.getPrimaryExtensionsOfCurrentCluster().length) {
        this._primaryExtCandidates = this._global360View.primaryExtensionCandidates.filter(
          (ref: DirectoryNumberRef) => ref.serverId === this._currentCluster.cucmServerId
        );
        this._resolvePrimaryExtension();
      }
    }, 500);
  }
}
