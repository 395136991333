import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CiscoGeneralSettings } from '../../shared/models/generated/smacsModels';

@Injectable()
export class CiscoGeneralSettingsResource {
  private _url = '/services/cisco/admin/general-settings';
  constructor(private http: HttpClient) {}

  get(): Observable<CiscoGeneralSettings> {
    return this.http.get<CiscoGeneralSettings>(this._url);
  }

  put(ciscoGeneralSettings: CiscoGeneralSettings): Observable<void> {
    return this.http.put<void>(this._url, ciscoGeneralSettings);
  }
}
