import { Injectable, Injector } from '@angular/core';
import {
  AppUserRef,
  AppUserResult,
  ClusterResult,
  DirectoryNumberRef,
  EndUserRef,
  EndUserResult,
  ExtensionMobilityFieldConfig,
  Global360View,
  LineButton,
  LineFeatureFieldConfig,
  ModelProtocolFieldConfig,
  Phone,
  PhoneFieldConfig,
  PhoneResult,
  Role,
  SiteResult,
} from '../../models/generated/smacsModels';
import { combineLatest, Observable, of, ReplaySubject, Subscriber, switchMap } from 'rxjs';
import { PhoneResource } from '../../resources/phone.resource';
import { ActivatedRoute } from '@angular/router';
import { SelfServeUiContext } from '../../../self-serve/contexts/self-serve-ui.context';
import { CurrentClusterContext } from '../../contexts/current-cluster.context';
import { PhoneFieldConfigResource } from '../../../helpdesk/shared/resources/field-config/phone-field-config.resource';
import { SiteContext } from '../../../helpdesk/shared/contexts/site.context';
import { AppUserSearchResource } from '../../resources/app-user-search.resource';
import { EndUserSearchResource } from '../../resources/end-user-search.resource';
import { Global360ViewContext } from '../../contexts/global-360-view.context';
import { LineFeatureConfigResource } from '../../resources/line-feature-config.resource';
import { first, map, take, takeUntil, tap } from 'rxjs/operators';
import { PhoneType } from '../../models/service-type';
import { SearchPhoneResource } from '../../resources/search-phone.resource';
import { ModelProtocolFieldConfigResource } from '../../../helpdesk/shared/resources/field-config/model-protocol-field-config.resource';
import { AbstractUiContext } from './abstract-ui.context';
import { DeviceService } from '../device.service';
import { DnDetailSummaryResource } from '../../resources/dn-detail-summary.resource';
import { CipcProtocolFieldConfigResource } from '../../../helpdesk/shared/resources/field-config/cipc-protocol-field-config.resource';
import { cloneDeep } from 'lodash';
import { KnownPhoneModels } from '../../../helpdesk/shared/contexts/user-detail-ui.context';
import { AuthenticationContext } from '../../contexts/authentication.context';

interface PhoneRouteParams {
  username: string;
  phoneId: string;
  phoneName: string;
}

@Injectable()
export class PhoneUiContext extends AbstractUiContext<Phone> {
  private _phoneSource = new ReplaySubject<Phone>(1);
  phoneState$ = this._phoneSource.asObservable();

  private _isBuildPhoneCalled = new ReplaySubject<boolean>(1);
  isBuildPhoneCalled$ = this._isBuildPhoneCalled.asObservable();

  private _phoneFieldConfigSource = new ReplaySubject<PhoneFieldConfig | ExtensionMobilityFieldConfig>(1);
  phoneFieldConfigState$ = this._phoneFieldConfigSource.asObservable();

  // only used for public phones, to see whether pin reset is enabled.
  private _isVoicemailPresentOnLineOneSource = new ReplaySubject<boolean>(1);
  isVoicemailPresentOnLineOneState$ = this._isVoicemailPresentOnLineOneSource.asObservable();

  phoneUiContextModel: string;
  phoneUiContextProtocol: string;
  phoneFieldsConfig: PhoneFieldConfig | ExtensionMobilityFieldConfig;
  builtPhone: Phone;
  phoneType: PhoneType;
  phoneModelByType: string;

  private _siteId: number;

  constructor(
    protected lineFeatureConfigResource: LineFeatureConfigResource,
    protected route: ActivatedRoute,
    private injector: Injector,
    private phoneResource: PhoneResource,
    private searchPhoneResource: SearchPhoneResource,
    private phoneFieldConfigResource: PhoneFieldConfigResource,
    private currentClusterContext: CurrentClusterContext,
    private siteContext: SiteContext,
    private endUserSearchResource: EndUserSearchResource,
    private appUserSearchResource: AppUserSearchResource,
    private modelProtocolFieldConfigResource: ModelProtocolFieldConfigResource,
    private _dnDetailSummaryResource: DnDetailSummaryResource,
    private _cipcProtocolFieldConfigResource: CipcProtocolFieldConfigResource,
    private _authenticationContext: AuthenticationContext
  ) {
    super(lineFeatureConfigResource);

    const isSelfServe = this._authenticationContext.getCurrentUser()?.role === Role.S8_SELF_SERVE_USER;
    const phoneRouteParams: PhoneRouteParams = {
      username: this.route.snapshot?.params?.username as string,
      phoneId: this.route.snapshot?.params?.phoneId as string,
      phoneName: this.route.snapshot?.params?.phoneName as string,
    };

    this.phoneType = DeviceService.getPhoneTypeFromRoute(this.route.snapshot);

    if (this.phoneType === PhoneType.EXTENSION_MOBILITY) {
      return;
    }

    if (!isSelfServe) {
      this.currentClusterContext.state$.pipe(take(1)).subscribe((currentCluster: ClusterResult) => {
        this._initDevice(phoneRouteParams);
        if (phoneRouteParams.phoneId || phoneRouteParams.phoneName) {
          this._initExistingPhone(phoneRouteParams, currentCluster);
        }
      });
    } else {
      this._initSelfServePhone(phoneRouteParams);
    }
  }

  private _buildNewPhone(
    username: string,
    siteId: number,
    phoneFieldConfigState$: Observable<PhoneFieldConfig | ExtensionMobilityFieldConfig>,
    injector: Injector,
    currentClusterContext: CurrentClusterContext,
    model: string,
    protocol: string
  ): Observable<Phone> {
    return new Observable<Phone>((subscriber: Subscriber<Phone>) => {
      const contextStates = [phoneFieldConfigState$, currentClusterContext.state$] as Observable<any>[];
      if (username) {
        contextStates.push(injector.get(Global360ViewContext).state$);
      }
      combineLatest(contextStates)
        .pipe(first())
        .subscribe((contextResults) => {
          const phoneFieldConfig = contextResults[0] as PhoneFieldConfig;
          const currentCluster = contextResults[1] as ClusterResult;
          const global360View = contextResults[2] as Global360View;

          const cucmServerId = currentCluster.cucmServerId;
          const endUser = global360View
            ? global360View.endUsers.find((user: EndUserResult) => user.ref.serverId === cucmServerId)
            : null;
          const primaryExtension = global360View
            ? global360View.primaryExtensions.find(
                (extension: DirectoryNumberRef) => extension.serverId === cucmServerId
              )
            : null;

          this._getMiscPhoneFeatures(
            username,
            siteId,
            model,
            protocol,
            phoneFieldConfig,
            currentCluster.cucmServerId,
            primaryExtension.extension
          ).subscribe(({ endUserResults, appUserResults, lineFeatureConfig }) => {
            if (endUser && !endUserResults.some((result) => result.ref.id === endUser.ref.id)) {
              endUserResults.unshift(endUser);
            }

            const buttons = username
              ? [
                  {
                    type: 'Line',
                    dn: primaryExtension,
                    lineFeature: {
                      associatedEndUsers: endUser ? [endUser.ref] : [],
                      busyTrigger: Number(lineFeatureConfig.busyTrigger.defaultValue),
                      callRecordingMediaSource: lineFeatureConfig.callRecordingMediaSource.defaultValue,
                      callRecordingOption: lineFeatureConfig.callRecordingOption.defaultValue,
                      callRecordingProfile: lineFeatureConfig.callRecordingProfile.defaultValue,
                      externalCallerId: lineFeatureConfig.externalCallerId.defaultValue,
                      externalCallerIdNumber: lineFeatureConfig.externalCallerIdNumber.defaultValue,
                      internalCallerId: lineFeatureConfig.internalCallerId.defaultValue,
                      label: lineFeatureConfig.label.defaultValue,
                      maxNumberOfCalls: Number(lineFeatureConfig.maxNumberOfCalls.defaultValue),
                      monitoringCssName: lineFeatureConfig.monitoringCssName.defaultValue,
                      ringActive: lineFeatureConfig.ringActive.defaultValue,
                      ringIdle: lineFeatureConfig.ringIdle.defaultValue,
                    },
                  } as LineButton,
                ]
              : [
                  {
                    type: 'Line',
                    dn: null,
                    lineFeature: null,
                  },
                ];
            const phone = {
              id: undefined,
              aarClassOfService: phoneFieldConfig.aarCallingSearchSpace?.defaultValue ?? '',
              alwaysUsePrimeLine: phoneFieldConfig.alwaysUsePrimeLine.defaultValue,
              alwaysUsePrimeLineForVoiceMessage: phoneFieldConfig.alwaysUsePrimeLineForVoiceMessage.defaultValue,
              associatedAppUsers: appUserResults.filter((user) => !!user).map((user) => user.ref),
              associatedEndUsers: endUserResults.filter((user) => !!user).map((user) => user.ref),
              builtInBridge: phoneFieldConfig.builtInBridge?.defaultValue ?? '',
              buttons: buttons,
              ciscoSupportField: phoneFieldConfig.ciscoSupportField?.defaultValue ?? '',
              classOfService: phoneFieldConfig.callingSearchSpace?.defaultValue ?? '',
              commonDeviceConfiguration: phoneFieldConfig.commonDeviceConfiguration?.defaultValue ?? '',
              commonPhoneProfile: phoneFieldConfig.commonPhoneProfile?.defaultValue ?? '',
              description: phoneFieldConfig.description.defaultValue,
              deviceMobilityMode: phoneFieldConfig.deviceMobilityMode?.defaultValue ?? '',
              devicePool: phoneFieldConfig.devicePool,
              disableSpeakerPhone: phoneFieldConfig.disableSpeakerPhone?.defaultValue ?? false,
              emergencyNumbers: phoneFieldConfig.emergencyNumbers?.defaultValue ?? '',
              expansionModules: [],
              extDataLocationAuthServer: phoneFieldConfig.extDataLocationAuthServer?.defaultValue ?? '',
              extDataLocationSecureAuthUrl: phoneFieldConfig.extDataLocationSecureAuthUrl?.defaultValue ?? '',
              featureControlPolicyName: phoneFieldConfig.featureControlPolicy?.defaultValue ?? '',
              location: phoneFieldConfig.location?.defaultValue ?? '',
              model: model,
              name: phoneFieldConfig.name?.defaultValue ?? '',
              networkMohSourceName: phoneFieldConfig.networkMohAudioSource?.defaultValue ?? '',
              owner: endUser ? endUser.ref : null,
              phoneButtonTemplate: undefined, // phone component handles this on model/protocol change
              phoneLoadName: '', // not handled by smacs?
              privacy: phoneFieldConfig.privacy.defaultValue,
              reroutingCallingSearchSpace: phoneFieldConfig.reroutingCallingSearchSpace?.defaultValue ?? '',
              protocol: protocol,
              securityProfileName: phoneFieldConfig.securityProfileName?.defaultValue ?? '',
              serviceSubscriptions: [],
              sipProfileName: phoneFieldConfig.sipProfileName?.defaultValue ?? '',
              softkeyTemplate: phoneFieldConfig.softkeyTemplate?.defaultValue ?? '',
              subscribeCss: phoneFieldConfig.subscribeCss?.defaultValue ?? '',
              userLocale: phoneFieldConfig.userLocale?.defaultValue ?? '',
              userMohSourceName: phoneFieldConfig.userMohAudioSource?.defaultValue ?? '',
              mediaResourceGroupList: phoneFieldConfig.mediaResourceGroupList?.defaultValue ?? '',
            } as Phone;
            this.builtPhone = phone;
            subscriber.next(phone);
            subscriber.complete();
          });
        });
    });
  }

  private _getMiscPhoneFeatures(
    username: string,
    siteId: number,
    model: string,
    protocol: string,
    phoneFieldConfig: PhoneFieldConfig,
    cucmServerId: number,
    extension: string
  ): Observable<{
    endUserResults: EndUserResult[];
    appUserResults: AppUserResult[];
    lineFeatureConfig: LineFeatureFieldConfig;
  }> {
    const endUserObs = phoneFieldConfig.associatedEndUsers.defaultValues
      .filter((user: EndUserRef) => user.username !== username)
      .map((user: EndUserRef) =>
        this.endUserSearchResource
          .searchByUsername(user.username)
          .pipe(
            map((endUserResults: EndUserResult[]) =>
              endUserResults.find((endUserResult: EndUserResult) => endUserResult.ref.serverId === cucmServerId)
            )
          )
      );
    const appUserObs =
      this.phoneType !== PhoneType.EXTENSION_MOBILITY
        ? phoneFieldConfig.associatedAppUsers.defaultValues.map((user: AppUserRef) =>
            this.appUserSearchResource
              .searchByUsername(user.username, cucmServerId)
              .pipe(map((res: AppUserResult[]) => res[0]))
          )
        : [];
    const miscPhoneFeatureObservables = [
      endUserObs.length ? combineLatest(endUserObs) : of([]),
      appUserObs.length ? combineLatest(appUserObs) : of([]),
    ] as Observable<any>[];
    if (username) {
      const lineOneFeatureObs = this.lineFeatureConfigResource.post({
        model: model,
        protocol: protocol,
        deviceOwner: username,
        extension: extension,
        siteId: siteId,
      });
      miscPhoneFeatureObservables.push(lineOneFeatureObs);
    }

    return combineLatest(miscPhoneFeatureObservables)
      .pipe(first())
      .pipe(
        switchMap((miscResults) => {
          const endUserResults = miscResults[0] as EndUserResult[];
          const appUserResults = miscResults[1] as AppUserResult[];
          const lineFeatureConfig = miscResults[2] as LineFeatureFieldConfig;

          return of({
            endUserResults: endUserResults,
            appUserResults: appUserResults,
            lineFeatureConfig: lineFeatureConfig,
          });
        })
      );
  }

  setupPhone(
    username: string,
    siteId: number,
    model: string,
    protocol: string,
    phoneId: string,
    isFormUpdated: boolean
  ): Observable<Phone> {
    return new Observable((subscriber: Subscriber<Phone>) => {
      if (isFormUpdated || phoneId) {
        const contextStates = [this.phoneFieldConfigState$, this.currentClusterContext.state$] as Observable<any>[];
        if (username) {
          contextStates.push(this.injector.get(Global360ViewContext).state$);
        }
        combineLatest(contextStates)
          .pipe(take(1))
          .subscribe((states) => {
            const phoneFieldConfig = states[0] as PhoneFieldConfig;
            const currentCluster = states[1] as ClusterResult;
            const global360View = states[2] as Global360View;

            const cucmServerId = currentCluster.cucmServerId;
            const endUser = global360View
              ? global360View.endUsers.find((user: EndUserResult) => user.ref.serverId === cucmServerId)
              : null;
            const primaryExtension = global360View
              ? global360View.primaryExtensions.find(
                  (extension: DirectoryNumberRef) => extension.serverId === cucmServerId
                )
              : null;

            this.phoneState$.pipe(take(1)).subscribe((state) => {
              this._getMiscPhoneFeatures(
                username,
                siteId,
                model,
                protocol,
                phoneFieldConfig,
                currentCluster.cucmServerId,
                primaryExtension?.extension
              ).subscribe(({ endUserResults, appUserResults, lineFeatureConfig }) => {
                if (endUser && !endUserResults.some((result) => result.ref.id === endUser.ref.id)) {
                  endUserResults.unshift(endUser);
                }

                // Only wipe buttons when no dn/line-feature currently exists on first line

                // if no line-feature / config exists do this
                let buttons;
                const buttonOne = state.buttons && (state.buttons[0] as LineButton);
                if (!username) {
                  buttons = [{ type: 'Line', dn: null, lineFeature: null }];
                } else if (!buttonOne || !buttonOne.dn || !buttonOne.lineFeature) {
                  // New phone, give line some values
                  buttons = [
                    {
                      type: 'Line',
                      dn: primaryExtension,
                      lineFeature: {
                        associatedEndUsers: endUser ? [endUser.ref] : [],
                        busyTrigger: Number(lineFeatureConfig.busyTrigger.defaultValue),
                        callRecordingMediaSource: lineFeatureConfig.callRecordingMediaSource.defaultValue,
                        callRecordingOption: lineFeatureConfig.callRecordingOption.defaultValue,
                        callRecordingProfile: lineFeatureConfig.callRecordingProfile.defaultValue,
                        externalCallerId: lineFeatureConfig.externalCallerId.defaultValue,
                        externalCallerIdNumber: lineFeatureConfig.externalCallerIdNumber.defaultValue,
                        internalCallerId: lineFeatureConfig.internalCallerId.defaultValue,
                        label: lineFeatureConfig.label.defaultValue,
                        maxNumberOfCalls: Number(lineFeatureConfig.maxNumberOfCalls.defaultValue),
                        monitoringCssName: lineFeatureConfig.monitoringCssName.defaultValue,
                        ringActive: lineFeatureConfig.ringActive.defaultValue,
                        ringIdle: lineFeatureConfig.ringIdle.defaultValue,
                      },
                    },
                  ];
                } else {
                  const existingBusyTrigger = Number(buttonOne.lineFeature.busyTrigger);
                  const lineFeatureBusyTrigger = Number(lineFeatureConfig.busyTrigger.defaultValue);
                  const existingMaxCalls = Number(buttonOne.lineFeature.maxNumberOfCalls);
                  const lineFeatureMaxCalls = Number(lineFeatureConfig.maxNumberOfCalls.defaultValue);
                  buttonOne.lineFeature.label = buttonOne.lineFeature.label
                    ? buttonOne.lineFeature.label
                    : lineFeatureConfig.label.defaultValue;
                  buttonOne.lineFeature.ringActive = buttonOne.lineFeature.ringActive
                    ? buttonOne.lineFeature.ringActive
                    : lineFeatureConfig.ringActive.defaultValue;
                  buttonOne.lineFeature.ringIdle = buttonOne.lineFeature.ringIdle
                    ? buttonOne.lineFeature.ringIdle
                    : lineFeatureConfig.ringIdle.defaultValue;
                  buttonOne.lineFeature.callRecordingMediaSource = buttonOne.lineFeature.callRecordingMediaSource
                    ? buttonOne.lineFeature.callRecordingMediaSource
                    : lineFeatureConfig.callRecordingMediaSource.defaultValue;
                  buttonOne.lineFeature.callRecordingOption = buttonOne.lineFeature.callRecordingOption
                    ? buttonOne.lineFeature.callRecordingOption
                    : lineFeatureConfig.callRecordingOption.defaultValue;
                  buttonOne.lineFeature.callRecordingProfile = buttonOne.lineFeature.callRecordingProfile
                    ? buttonOne.lineFeature.callRecordingProfile
                    : lineFeatureConfig.callRecordingProfile.defaultValue;
                  buttonOne.lineFeature.busyTrigger =
                    existingBusyTrigger > lineFeatureBusyTrigger ? lineFeatureBusyTrigger : existingBusyTrigger;
                  buttonOne.lineFeature.maxNumberOfCalls =
                    existingMaxCalls > lineFeatureMaxCalls ? lineFeatureMaxCalls : existingMaxCalls;

                  // Editing existing phone, don't clear line
                  buttons = [buttonOne];
                }

                const phone = {
                  id: undefined,
                  aarClassOfService: phoneFieldConfig.aarCallingSearchSpace?.defaultValue ?? '',
                  alwaysUsePrimeLine: phoneFieldConfig.alwaysUsePrimeLine.defaultValue,
                  alwaysUsePrimeLineForVoiceMessage: phoneFieldConfig.alwaysUsePrimeLineForVoiceMessage.defaultValue,
                  associatedAppUsers: appUserResults.filter((user) => !!user).map((user) => user.ref),
                  associatedEndUsers: endUserResults.filter((user) => !!user).map((user) => user.ref),
                  builtInBridge: phoneFieldConfig.builtInBridge?.defaultValue ?? '',
                  buttons: cloneDeep(buttons),
                  ciscoSupportField: phoneFieldConfig.ciscoSupportField?.defaultValue ?? '',
                  classOfService: phoneFieldConfig.callingSearchSpace?.defaultValue ?? '',
                  commonDeviceConfiguration: phoneFieldConfig.commonDeviceConfiguration?.defaultValue ?? '',
                  commonPhoneProfile: phoneFieldConfig.commonPhoneProfile?.defaultValue ?? '',
                  description: phoneFieldConfig.description.defaultValue,
                  deviceMobilityMode: phoneFieldConfig.deviceMobilityMode?.defaultValue ?? '',
                  devicePool: phoneFieldConfig.devicePool,
                  disableSpeakerPhone: phoneFieldConfig.disableSpeakerPhone?.defaultValue ?? false,
                  emergencyNumbers: phoneFieldConfig.emergencyNumbers?.defaultValue ?? '',
                  expansionModules: [],
                  extDataLocationAuthServer: phoneFieldConfig.extDataLocationAuthServer?.defaultValue ?? '',
                  extDataLocationSecureAuthUrl: phoneFieldConfig.extDataLocationSecureAuthUrl?.defaultValue ?? '',
                  featureControlPolicyName: phoneFieldConfig.featureControlPolicy?.defaultValue ?? '',
                  location: phoneFieldConfig.location?.defaultValue ?? '',
                  model: model,
                  name: phoneFieldConfig.name?.defaultValue ?? '',
                  networkMohSourceName: phoneFieldConfig.networkMohAudioSource?.defaultValue ?? '',
                  owner: endUser ? endUser.ref : null,
                  phoneButtonTemplate: undefined, // phone component handles this on model/protocol change
                  phoneLoadName: '', // not handled by smacs?
                  privacy: phoneFieldConfig.privacy.defaultValue,
                  reroutingCallingSearchSpace: phoneFieldConfig.reroutingCallingSearchSpace?.defaultValue ?? '',
                  protocol: protocol,
                  securityProfileName: phoneFieldConfig.securityProfileName?.defaultValue ?? '',
                  serviceSubscriptions: [],
                  sipProfileName: phoneFieldConfig.sipProfileName?.defaultValue ?? '',
                  softkeyTemplate: phoneFieldConfig.softkeyTemplate?.defaultValue ?? '',
                  subscribeCss: phoneFieldConfig.subscribeCss?.defaultValue ?? '',
                  userLocale: phoneFieldConfig.userLocale?.defaultValue ?? '',
                  userMohSourceName: phoneFieldConfig.userMohAudioSource?.defaultValue ?? '',
                  mediaResourceGroupList: phoneFieldConfig.mediaResourceGroupList?.defaultValue ?? '',
                } as Phone;

                subscriber.next(phone);
                subscriber.complete();
              });
            });
          });
      } else {
        subscriber.next(this.builtPhone);
        subscriber.complete();
      }
    });
  }

  set(phone: Phone) {
    this._phoneSource.next(phone);
  }

  getFieldConfig(
    siteId: string,
    model: string,
    protocol: string,
    isInitialRun?: boolean
  ): Observable<PhoneFieldConfig | ExtensionMobilityFieldConfig> {
    return new Observable((sub) => {
      const isSelfServe =
        window.location.href.includes('self-serve') || window.location.href.includes('edit-deskphone');
      const username = this.route.snapshot?.params?.username as string;
      this.phoneType = isSelfServe ? PhoneType.DESKPHONE : this.phoneType;

      if (this.phoneUiContextProtocol !== protocol || this.phoneUiContextModel !== model || isInitialRun) {
        this.phoneFieldConfigResource
          .get(this.phoneType, {
            username: username,
            siteId: siteId,
            model: model,
            protocol: protocol,
          })
          .subscribe((fieldConfig) => {
            this._phoneFieldConfigSource.next(fieldConfig);
            sub.next(fieldConfig);
            sub.complete();
          });
      } else {
        this._phoneFieldConfigSource.next(this.phoneFieldsConfig);
        sub.next(this.phoneFieldsConfig);
        sub.complete();
      }
    });
  }

  getPhoneTypeFromRoute(): PhoneType {
    return this.phoneType;
  }

  setIsVoicemailPresentOnLineOne(state: boolean) {
    this._isVoicemailPresentOnLineOneSource.next(state);
  }

  getSiteId(): number {
    return this._siteId;
  }

  private _buildPhone(model: string, protocol: string, siteId: number, username: string): Observable<Phone> {
    const obs: Observable<any>[] = username
      ? [this.phoneFieldConfigState$, this.currentClusterContext.state$, this.injector.get(Global360ViewContext).state$]
      : [this.phoneFieldConfigState$, this.currentClusterContext.state$];
    combineLatest(obs).pipe(takeUntil(this._isBuildPhoneCalled)).subscribe();
    return this._buildNewPhone(
      username,
      siteId,
      this.phoneFieldConfigState$,
      this.injector,
      this.currentClusterContext,
      model,
      protocol
    ).pipe(
      tap((newPhone: Phone) => {
        this._isBuildPhoneCalled.next(true);
        this.set(newPhone);
      })
    );
  }

  private _getFieldConfig(
    model: string,
    protocol: string,
    siteId: number
  ): Observable<PhoneFieldConfig | ExtensionMobilityFieldConfig> {
    this.phoneUiContextProtocol = protocol;
    this.phoneUiContextModel = model;

    return this.getFieldConfig(siteId.toString(), model, protocol, true).pipe(
      tap((phoneFieldsConfig) => {
        this.phoneFieldsConfig = phoneFieldsConfig;
      })
    );
  }

  /**
   * To set voicemail present on Line one for Public Phone pin reset
   * @param phone
   * @private
   */
  private _isVoicemailPresetOnButtonOne(phone: Phone) {
    const isLineOne = phone.buttons[0].type === 'Line';
    if (isLineOne) {
      const lineOneButton = phone.buttons[0] as LineButton;
      if (lineOneButton.dn) {
        const dnId = lineOneButton.dn.id;
        const serverId = lineOneButton.dn.serverId.toString();
        this._dnDetailSummaryResource.get(dnId, serverId).subscribe((dnDetailSummary) => {
          if (dnDetailSummary.voicemail) {
            this.setIsVoicemailPresentOnLineOne(!!dnDetailSummary.voicemail.id);
          }
        });
      }
    }
  }

  private _get(id: string, cucmServerId: string): Observable<Phone> {
    return this.phoneResource.get(id, cucmServerId);
  }

  private _getModelProtocolConfigForNonDeskphone(): ModelProtocolFieldConfig {
    this.phoneModelByType =
      KnownPhoneModels[this.phoneType.toUpperCase().replace(' ', '_') as keyof typeof KnownPhoneModels];
    const modelProtocolFieldConfig: ModelProtocolFieldConfig = {
      modelSpecificProtocols: {},
      models: {
        defaultValue: '',
        possibleOptions: [],
        required: true,
        show: false,
      },
    };
    modelProtocolFieldConfig.modelSpecificProtocols[this.phoneModelByType] = {
      defaultValue: 'SIP',
      possibleOptions: ['SIP'],
      required: true,
      show: false,
    };
    modelProtocolFieldConfig.models = {
      defaultValue: this.phoneModelByType,
      possibleOptions: [this.phoneModelByType],
      required: true,
      show: false,
    };
    return modelProtocolFieldConfig;
  }

  private _initDevice(routeParams: PhoneRouteParams): void {
    this.siteContext.state$.pipe(first()).subscribe((site) => {
      this._siteId = Number(site.id);
      switch (this.phoneType) {
        case PhoneType.DESKPHONE:
          this._initDeskphone(routeParams, site);
          break;
        case PhoneType.CIPC:
          this._initCipc(routeParams, site);
          break;
        default:
          this.initNonDeskphone(routeParams, site);
          break;
      }
    });
  }

  private _initDeskphone(routeParams: PhoneRouteParams, site: SiteResult): void {
    this.modelProtocolFieldConfigResource
      .post(PhoneType.DESKPHONE, { siteId: site.id })
      .subscribe((response: ModelProtocolFieldConfig) => {
        this._modelProtocolFieldConfigSource.next(response);
        const model = response.models.defaultValue || '';
        const protocol = model ? response.modelSpecificProtocols[model].defaultValue : '';
        if (!routeParams.phoneId && !routeParams.phoneName) {
          this.set({ model: model, protocol: protocol, name: '' } as Phone);
          this._phoneFieldConfigSource.next(null);
          this._isBuildPhoneCalled.next(false);
        }
      });
  }

  private _initCipc(routeParams: PhoneRouteParams, site: SiteResult): void {
    this._cipcProtocolFieldConfigResource.getProtocolFieldConfig(site.id).subscribe((cipcProtocolFieldConfig) => {
      const modelProtocol = this._getModelProtocolConfigForNonDeskphone();
      modelProtocol.modelSpecificProtocols[this.phoneModelByType] = cipcProtocolFieldConfig.protocol;
      this._modelProtocolFieldConfigSource.next(modelProtocol);
      const model = modelProtocol.models.defaultValue || '';
      const protocol = model ? modelProtocol.modelSpecificProtocols[model].defaultValue : '';
      if (!routeParams.phoneId && !routeParams.phoneName) {
        this.set({
          model: 'Cisco IP Communicator',
          protocol: protocol,
          name: '',
        } as Phone);
        this._phoneFieldConfigSource.next(null);
        this._isBuildPhoneCalled.next(false);
      }
    });
  }

  private initNonDeskphone(routeParams: PhoneRouteParams, site: SiteResult) {
    if (!routeParams.phoneId) {
      this._modelProtocolFieldConfigSource.next(this._getModelProtocolConfigForNonDeskphone());
      this._getFieldConfig(this.phoneModelByType, 'SIP', site.id).subscribe(() =>
        this._buildPhone(this.phoneModelByType, 'SIP', site.id, routeParams.username).subscribe()
      );
    } else {
      this._modelProtocolFieldConfigSource.next(this._getModelProtocolConfigForNonDeskphone());
    }
  }

  private _initExistingPhone(routeParams: PhoneRouteParams, currentCluster: ClusterResult) {
    if (routeParams.phoneId) {
      const cucmServerId = currentCluster.cucmServerId;
      this._get(routeParams.phoneId, cucmServerId?.toString()).subscribe((phone) => {
        this._isVoicemailPresetOnButtonOne(phone);
        this.set(phone);
      });
    } else if (routeParams.phoneName) {
      let phoneResult: PhoneResult;
      this.searchPhoneResource
        .get({ name: routeParams.phoneName })
        .pipe(
          switchMap((results) => {
            if (results.length > 1) {
              const serverIds = results.map((r) => r.ref.serverId).join(', ');
              throw new Error(
                `Could not find a unique phone with name [${routeParams.phoneName}]. Results were found on servers with IDs: ${serverIds}`
              );
            } else if (results.length === 0) {
              throw new Error(`Could not find phone with name [${routeParams.phoneName}]`);
            }
            phoneResult = results[0];
            return this._get(phoneResult.ref.id, phoneResult.ref.serverId.toString());
          })
        )
        .subscribe((phone: Phone) => {
          this.currentClusterContext.setCurrentClusterFromCucmServer(phoneResult.ref.serverId);
          this._isVoicemailPresetOnButtonOne(phone);
          this.set(phone);
        });
    }
  }

  private _initSelfServePhone(routeParams: PhoneRouteParams) {
    this.injector
      .get(SelfServeUiContext)
      .selfServe360View$.pipe(
        first(),
        switchMap((selfServe360View) => {
          const cucmServerId = selfServe360View.phones.find((phone) => phone.id === routeParams.phoneId)?.serverId;
          return this._get(routeParams.phoneId, cucmServerId?.toString());
        })
      )
      .subscribe((phone) => {
        this.set(phone);
      });
  }
}
