import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import {
  CiscoHelpdeskOptions,
  ClusterResult,
  DefaultEndUserRequest,
  DirectoryNumberRef,
  EndUser,
  EndUserResult,
  ExtensionMobilityRef,
  Global360View,
  LdapUserDialPlanAttributes,
  LineButton,
  PcceAgentRef,
  Phone,
  PhoneRef,
  ServiceProvisioningLevel,
  SiteResult,
  SiteSummary,
  SnrProfileRef,
  TileConfig,
  TranslationPatternRef,
  UccxAgentRef,
  UcMetadataCache,
  VoicemailRef,
} from '../../../shared/models/generated/smacsModels';
import { combineLatest, forkJoin, from, Observable, of, skip, Subscriber, Subscription, throwError } from 'rxjs';
import { KnownPhoneModels, UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { UcMetadataCacheContext } from '../../../shared/contexts/uc-metadata-cache.context';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { catchError, distinctUntilChanged, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { EndUserResource } from '../../../shared/resources/end-user.resource';
import { ImPresenceResource } from '../../shared/resources/im-presence.resource';
import { TileMappingService } from './tile-mapping.service';
import { CiscoTile, CiscoTileState } from './tiles.model';
import { ServiceTileType } from './service-tile-type.model';
import { cloneDeep, isArray, isEqual } from 'lodash';
import { DirectoryNumberResource } from '../../../self-serve/resources/directory-number.resource';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';
import { PhoneResource } from '../../../shared/resources/phone.resource';
import { ExtensionMobilityService } from '../../../shared/services/extension-mobility.service';
import { SnrProfileResource } from '../../../self-serve/resources/snr-profile.resource';
import { AuditHeaderService } from '../../../shared/services/audit-header.service';
import { LdapUserResource } from '../../shared/resources/ldap-user.resource';
import { TranslationPatternResource } from '../../shared/resources/translation-pattern.resource';
import { DefaultsResource } from '../../shared/resources/defaults.resource';
import { DnDetailService } from '../../shared/services/dn-detail.service';
import { ChangePrimaryExtensionModalComponent } from '../../../modals/change-primary-extension-modal/change-primary-extension.modal.component';
import { Nvp } from '../../../shared/models/nvp';
import { VoicemailService, VoicemailServiceParams } from '../../../shared/services/voicemail.service';
import { HelpdeskOptionsContext } from '../../shared/contexts/helpdesk-options.context';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { SiteContext } from '../../shared/contexts/site.context';
import { WebexUserResource } from '../../../shared/resources/webex-user.resource';
import { TileConfigContext } from '../../shared/contexts/tile-config.context';

interface TileStates {
  primaryExtension: CiscoTileState;
  uccxAgent: CiscoTileState[];
  pcceAgent: CiscoTileState;
  voicemail: CiscoTileState;
  snr: CiscoTileState;
  deskphone: CiscoTileState[];
  extMobility: CiscoTileState;
  imPresence: CiscoTileState;
  imSoftphone: CiscoTileState[];
  cipc: CiscoTileState[];
  android: CiscoTileState[];
  iphone: CiscoTileState[];
  tablet: CiscoTileState[];
  ctiPort: CiscoTileState[];
  ctiRemoteDevice: CiscoTileState[];
  sparkRemoteDevice: CiscoTileState[];
  webexCalling: CiscoTileState;
}

@Component({
  selector: 'smacs-user-detail-home',
  templateUrl: './user-detail-home.component.html',
  providers: [TileMappingService, TelephoneNumberFilter, ExtensionMobilityService, DnDetailService, VoicemailService],
})
export class SmacsUserDetailHomeComponent implements OnInit, OnDestroy {
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  isLoading = true;
  multiEditAddClicked = false;
  associatedPublicPhones: PhoneRef[] = [];
  endUserResult: EndUserResult;
  tileStates: TileStates = {
    primaryExtension: null,
    uccxAgent: [],
    pcceAgent: null,
    voicemail: null,
    snr: null,
    deskphone: [],
    extMobility: null,
    imPresence: null,
    imSoftphone: [],
    cipc: [],
    android: [],
    iphone: [],
    tablet: [],
    ctiPort: [],
    ctiRemoteDevice: [],
    sparkRemoteDevice: [],
    webexCalling: null,
  };
  isClusterDropdownVisible = false;
  clusterDropdownOptions: ClusterResult[] = [];
  currentClusterName = '';
  hasSites = false;

  private _tileConfigs: TileConfig;
  private _subscriptions = new Subscription();
  private _unityServerId: number;
  private _ucMetadataCache: UcMetadataCache;
  private _siteSummary: SiteSummary;
  private _siteId: number;

  constructor(
    private telephoneNumberFilter: TelephoneNumberFilter,
    private global360ViewContext: Global360ViewContext,
    private userDetailUiContext: UserDetailUiContext,
    private translateService: TranslateService,
    private http: HttpClient,
    private siteSummaryContext: SiteSummaryContext,
    private router: Router,
    private ucMetadataCacheContext: UcMetadataCacheContext,
    private toastService: ToastService,
    private smacsModalService: SmacsModalService,
    private endUserResource: EndUserResource,
    private imPresenceResource: ImPresenceResource,
    private tileMappingService: TileMappingService,
    private directoryNumberResource: DirectoryNumberResource,
    private phoneResource: PhoneResource,
    private extensionMobilityService: ExtensionMobilityService,
    private snrProfileResource: SnrProfileResource,
    private auditHeaderService: AuditHeaderService,
    private ldapUserResource: LdapUserResource,
    private defaultsResource: DefaultsResource,
    private dnDetailService: DnDetailService,
    private translationPatternResource: TranslationPatternResource,
    private voicemailService: VoicemailService,
    private helpdeskOptionsContext: HelpdeskOptionsContext,
    private currentClusterContext: CurrentClusterContext,
    private siteContext: SiteContext,
    private _webexUserResource: WebexUserResource,
    private _tileConfigContext: TileConfigContext
  ) {}

  private static _extractIdFromServiceUrl(url: string): string {
    const formattedUrl = url.split('/');
    if (url.endsWith('/im-presence')) {
      return formattedUrl[formattedUrl.length - 2];
    }
    return formattedUrl.pop();
  }

  ngOnInit(): void {
    this.userDetailUiContext.shouldInitTiles.subscribe((shouldInit) => {
      this.isLoading = true;
      if (shouldInit) {
        this._initView();
      }
    });
    this._initView();
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  onDeleteAllClick(): void {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: encodeURIComponent('text-danger'),
        title: this.translateService.instant('tkey;userdetail.home.services.delete_all.modal.header'),
        promptBody: this.translateService.instant('tkey;userdetail.home.services.delete_all.confirmation.text', {
          cluster: this.currentClusterName,
          username: this.endUserResult.ref.username,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onDeleteAllConfirm(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _getEndUserDefault(hasVoicemail: boolean): Observable<EndUser> {
    return this._getServiceDefault('/services/cisco/defaults/end-user', {
      username: this.endUserResult.ref.username,
      siteId: this.userDetailUiContext.getCurrentSite().id.toString(),
      hasVoicemail: hasVoicemail,
      hasSnr: !!this.userDetailUiContext.getCurrentSnrProfile(),
      hasExtensionMobility: !!this.userDetailUiContext.getCurrentExtensionMobility(),
    });
  }

  /**
   * When an add tile is clicked and enabled, we do one of two things:
   * 1. If the tile is 1-click provisionable we get the default and add the service
   * 2. If the tile is not 1-click provisionable, we navigate the user to the add form for that service
   */
  onAddTileClicked(tileState: CiscoTileState, i: number = null): void {
    if (!tileState.isEnabled || tileState.isAdding) {
      return;
    }
    if (tileState.provisioningLevel === ServiceProvisioningLevel.ONE_CLICK) {
      switch (tileState.serviceType) {
        // Single
        case CiscoTile.VOICEMAIL: {
          this.tileStates = {
            ...this.tileStates,
            [tileState.serviceType]: {
              ...this.tileStates[tileState.serviceType],
              isAdding: true,
            },
          };
          const serviceParams: VoicemailServiceParams = {
            endUserResult: this.userDetailUiContext.getCurrentEnduser(),
            endUsers: this.userDetailUiContext.getEndUsers(),
            unityServerId: this._unityServerId,
            siteId: Number(this.userDetailUiContext.getCurrentSite().id),
            dnRef: this.userDetailUiContext.getCurrentPrimaryExtension(),
            hasSnr: !!this.userDetailUiContext.hasSnr(),
            hasExtensionMobility: !!this.userDetailUiContext.hasExtensionMobility(),
          };

          this.voicemailService.provisionVoicemailWithDefaults(serviceParams).subscribe({
            next: (ref: VoicemailRef) => {
              const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
              global360.voicemails.push(ref);
              this.global360ViewContext._stateSource.next(global360);
              this._initTileStates(tileState.serviceType);
              this._showAddToastForTile(tileState, ref);
            },
            error: (error) => {
              if (error.status === 422) {
                this.tileStates = {
                  ...this.tileStates,
                  [tileState.serviceType]: {
                    ...this.tileStates[tileState.serviceType],
                    isAdding: false,
                    tileType: ServiceTileType.ADD,
                  },
                };
              }
              throw new HttpErrorResponse(error);
            },
          });
          break;
        }
        case CiscoTile.SNR: {
          this.tileStates = {
            ...this.tileStates,
            [tileState.serviceType]: {
              ...this.tileStates[tileState.serviceType],
              isAdding: true,
            },
          };
          this._getServiceDefault(this._getDefaultsUrlForService(tileState), {
            endUserUsername: this.endUserResult.ref.username,
            siteId: this.userDetailUiContext.getCurrentSite().id,
          })
            .pipe(
              mergeMap((defaults) => {
                return forkJoin([
                  this._provisionServiceWithDefaults(
                    `/services/cisco/macs/cucm-servers/${this.endUserResult.ref.serverId}/snr-profiles`,
                    defaults,
                    tileState
                  ),
                  this.endUserResource.checkEnabledMobilitySettings(
                    this.endUserResult.ref.id,
                    this.endUserResult.ref.serverId
                  ),
                ]);
              })
            )
            .subscribe(([snrRef]) => {
              const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
              global360.snrProfiles.push(snrRef as SnrProfileRef);
              this.global360ViewContext._stateSource.next(global360);
              this._initTileStates(tileState.serviceType);
              this._showAddToastForTile(tileState, snrRef);
            });
          break;
        }
        case CiscoTile.EXT_MOBILITY: {
          this.tileStates = {
            ...this.tileStates,
            [tileState.serviceType]: {
              ...this.tileStates[tileState.serviceType],
              isAdding: true,
            },
          };

          this._getServiceDefault(this._getDefaultsUrlForService(tileState), {
            endUserUsername: this.endUserResult.ref.username,
            siteId: this.userDetailUiContext.getCurrentSite().id,
          }).subscribe((defaults) => {
            this._provisionServiceWithDefaults(
              `/services/cisco/macs/cucm-servers/${this.endUserResult.ref.serverId}/extension-mobility-profiles`,
              defaults,
              tileState
            ).subscribe((ref: ExtensionMobilityRef) => {
              this.endUserResource
                .get(this.endUserResult.ref.id, this.endUserResult.ref.serverId)
                .subscribe((endUser: EndUser) => {
                  if (!endUser.enableCti) {
                    this.endUserResource
                      .put({ ...endUser, enableCti: true }, this.endUserResult.ref.serverId)
                      .subscribe(() => {
                        this._onExtensionMobilityAddComplete(tileState, ref);
                      });
                  } else {
                    this._onExtensionMobilityAddComplete(tileState, ref);
                  }
                });
            });
          });
          break;
        }
        case CiscoTile.IMP: {
          this.tileStates = {
            ...this.tileStates,
            [tileState.serviceType]: {
              ...this.tileStates[tileState.serviceType],
              isAdding: true,
            },
          };

          const serverId = this.userDetailUiContext.getCurrentEnduser().ref.serverId;
          if (!this.userDetailUiContext.getCurrentPrimaryExtension()) {
            const req = {
              hasExtensionMobility: this.userDetailUiContext.hasExtensionMobility(),
              hasSnr: this.userDetailUiContext.hasSnr(),
              hasVoicemail: !!this.userDetailUiContext.getCurrentVoicemail(),
              siteId: this.userDetailUiContext.getCurrentSite().id.toString(),
              username: this.endUserResult ? this.endUserResult.ref.username : null,
            } as DefaultEndUserRequest;

            this.defaultsResource.endUserDefaults(req).subscribe((endUser: EndUser) => {
              endUser.primaryExtension = this.userDetailUiContext.getCurrentPrimaryExtension();
              endUser.enableHomeCluster = true;
              this.endUserResource.put(endUser, serverId).subscribe(() => {
                this._setImPresence(tileState);
              });
            });
          } else {
            this.endUserResource
              .get(this.endUserResult.ref.id, this.endUserResult.ref.serverId)
              .subscribe((endUser: EndUser) => {
                endUser.enableHomeCluster = true;
                this.endUserResource.put(endUser, this.endUserResult.ref.serverId).subscribe(() => {
                  this._setImPresence(tileState);
                });
              });
          }

          break;
        }
        // Multi
        case CiscoTile.ANDROID:
        case CiscoTile.IPHONE:
        case CiscoTile.TABLET:
        case CiscoTile.IM_SOFTPHONE:
        case CiscoTile.CIPC: {
          const updateTileStates = this.tileStates[tileState.serviceType].map((tile: CiscoTileState, idx: number) =>
            idx === i ? { ...tile, isAdding: true } : tile
          );

          this.tileStates = {
            ...this.tileStates,
            [tileState.serviceType]: [...updateTileStates],
          };
          this._getServiceDefault(this._getDefaultsUrlForService(tileState), {
            endUserUsername: this.endUserResult.ref.username,
            siteId: this.userDetailUiContext.getCurrentSite().id,
          }).subscribe({
            next: (defaults) => {
              this._provisionServiceWithDefaults(
                `/services/cisco/macs/cucm-servers/${this.endUserResult.ref.serverId}/phones`,
                defaults,
                tileState
              ).subscribe({
                next: (ref: PhoneRef) => {
                  const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
                  global360.phones.push(ref);
                  this.global360ViewContext._stateSource.next(global360);
                  this._initTileStates(tileState.serviceType);
                  this._showAddToastForTile(tileState, ref);
                },
                error: (error) => {
                  this._initTileStates(tileState.serviceType);
                  throw new HttpErrorResponse(error);
                },
              });
            },
            error: (error) => {
              this._initTileStates(tileState.serviceType);
              throw new HttpErrorResponse(error);
            },
          });
          break;
        }
        default: {
          break;
        }
      }
    } else if (tileState.provisioningLevel === ServiceProvisioningLevel.SHOW) {
      const currentEndUserUsername = encodeURIComponent(this.endUserResult.ref.username);
      const email = this.endUserResult.email;

      switch (tileState.serviceType) {
        case CiscoTile.PRIMARY_EXT: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/primary-extension`);
          break;
        }
        case CiscoTile.PCCE_AGENT: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/pcce-agent-settings`);
          break;
        }
        case CiscoTile.VOICEMAIL: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/voicemail`);
          break;
        }
        case CiscoTile.SNR: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/snr`);
          break;
        }
        case CiscoTile.EXT_MOBILITY: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/extension-mobility`);
          break;
        }
        case CiscoTile.DESKPHONE: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/deskphone`);
          break;
        }
        case CiscoTile.TABLET: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/tablet`);
          break;
        }
        case CiscoTile.IM_SOFTPHONE: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/im-softphone`);
          break;
        }
        case CiscoTile.WEBEX_CALLING: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/webex-calling/${email}`);
          break;
        }
        case CiscoTile.ANDROID: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/android`);
          break;
        }
        case CiscoTile.CIPC: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/cipc`);
          break;
        }
        case CiscoTile.IPHONE: {
          this.router.navigateByUrl(`/user/${currentEndUserUsername}/iphone`);
          break;
        }
        default: {
          break;
        }
      }
    } else if (tileState.serviceType === CiscoTile.PCCE_AGENT) {
      const currentEndUserUsername = encodeURIComponent(this.endUserResult.ref.username);
      this.router.navigateByUrl(`/user/${currentEndUserUsername}/pcce-agent-settings`);
    }
  }

  private _setImPresence(tileState: CiscoTileState): void {
    this.imPresenceResource
      .post(this.endUserResult.ref.id, this.endUserResult.ref.serverId, [
        { name: 'username', value: this.endUserResult.ref.username },
      ])
      .subscribe(() => {
        this.global360ViewContext.setImPresenceEnabled(
          this.userDetailUiContext.getGlobal360View(),
          this.endUserResult.ref.serverId,
          true
        );
        this.endUserResult.imPresenceEnabled = true;
        this.tileStates.imPresence = this.tileMappingService.mapImpToTile(
          this.endUserResult,
          this._tileConfigs,
          this.hasSites
        );
        this.voicemailService
          .setHomeCluster(this.userDetailUiContext.getEndUsers(), this.userDetailUiContext.getCurrentEnduser().ref.id)
          .subscribe(() => {
            this._showAddToastForTile(tileState, this.endUserResult.ref);
          });
      });
  }

  /**
   * On click of edit tile direct the user to the edit form for that service
   */
  onEditTileClicked(tileState: CiscoTileState): void {
    if (!tileState.isEnabled) {
      return;
    }

    const currentEndUserUsername = encodeURIComponent(this.endUserResult.ref.username);
    const email = this.endUserResult.email;

    switch (tileState.serviceType) {
      case CiscoTile.PRIMARY_EXT: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/primary-extension/${tileState.service.id}`);
        break;
      }
      case CiscoTile.UCCX_AGENT: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/uccx-agent/${tileState.service.serverId}`);
        break;
      }
      case CiscoTile.PCCE_AGENT: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/pcce-agent-settings`);
        break;
      }
      case CiscoTile.VOICEMAIL: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/voicemail/${tileState.service.id}`);
        break;
      }
      case CiscoTile.SNR: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/snr/${tileState.service.id}`);
        break;
      }
      case CiscoTile.EXT_MOBILITY: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/extension-mobility/${tileState.service.id}`);
        break;
      }
      case CiscoTile.DESKPHONE: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/deskphone/${tileState.service.id}`);
        break;
      }
      case CiscoTile.TABLET: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/tablet/${tileState.service.id}`);
        break;
      }
      case CiscoTile.IM_SOFTPHONE: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/im-softphone/${tileState.service.id}`);
        break;
      }
      case CiscoTile.CIPC: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/cipc/${tileState.service.id}`);
        break;
      }
      case CiscoTile.WEBEX_CALLING: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/webex-calling/${email}`);
        break;
      }
      case CiscoTile.ANDROID: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/android/${tileState.service.id}`);
        break;
      }
      case CiscoTile.IPHONE: {
        this.router.navigateByUrl(`/user/${currentEndUserUsername}/iphone/${tileState.service.id}`);
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Deleting is a two step process, on initial click of delete, update the local state to change the tile to a delete
   * tile
   */
  onEditTileDeleteClicked(tileState: CiscoTileState, i: number = null): void {
    switch (tileState.serviceType) {
      // Single tiles
      case CiscoTile.PRIMARY_EXT:
      case CiscoTile.WEBEX_CALLING:
      case CiscoTile.PCCE_AGENT:
      case CiscoTile.IMP:
      case CiscoTile.VOICEMAIL:
      case CiscoTile.SNR:
      case CiscoTile.EXT_MOBILITY: {
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: {
            ...this.tileStates[tileState.serviceType],
            tileType: ServiceTileType.DELETE,
          },
        };
        break;
      }
      // Multi tiles
      case CiscoTile.TABLET:
      case CiscoTile.IM_SOFTPHONE:
      case CiscoTile.CIPC:
      case CiscoTile.ANDROID:
      case CiscoTile.IPHONE:
      case CiscoTile.DESKPHONE:
      case CiscoTile.CTI_PORT:
      case CiscoTile.CTI_REMOTE_DEVICE:
      case CiscoTile.SPARK_REMOTE_DEVICE: {
        const updatedTileStates = this.tileStates[tileState.serviceType].map((t: CiscoTileState, idx: number) =>
          idx === i ? { ...t, tileType: ServiceTileType.DELETE } : t
        );
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: [...updatedTileStates],
        };
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * If a delete is confirmed deprovision that service, and manually update the Global 360 state to reflect the change
   */
  onDeleteTileConfirmButtonClicked(tileState: CiscoTileState, i: number = null): void {
    switch (tileState.serviceType) {
      // Single tiles
      case CiscoTile.PRIMARY_EXT: {
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: {
            ...this.tileStates[tileState.serviceType],
            isDeleting: true,
          },
        };
        if (
          tileState.serviceType === CiscoTile.PRIMARY_EXT &&
          !this.getPrimaryExtensionDeleteTooltip() &&
          !!this.userDetailUiContext.getCurrentVoicemail()
        ) {
          this.openDnDeleteConfirmationModal();
          break;
        } else {
          this._deprovisionPrimaryExtension().subscribe();
          break;
        }
      }
      case CiscoTile.PCCE_AGENT:
      case CiscoTile.IMP:
      case CiscoTile.VOICEMAIL:
      case CiscoTile.SNR:
      case CiscoTile.EXT_MOBILITY: {
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: {
            ...this.tileStates[tileState.serviceType],
            isDeleting: true,
          },
        };
        this._deprovisionService(tileState, tileState.service.url).subscribe({
          next: () => {
            this._removeServiceFromGlobal360(tileState.serviceType, tileState.service['id']);
            this._showDeleteToastForTile(tileState);
          },
          error: (error) => {
            if (error.status === 422) {
              this.tileStates = {
                ...this.tileStates,
                [tileState.serviceType]: {
                  ...this.tileStates[tileState.serviceType],
                  isDeleting: false,
                  tileType: ServiceTileType.EDIT,
                },
              };
            }
          },
        });
        break;
      }
      case CiscoTile.TABLET:
      case CiscoTile.IM_SOFTPHONE:
      case CiscoTile.CIPC:
      case CiscoTile.ANDROID:
      case CiscoTile.IPHONE:
      case CiscoTile.CTI_PORT:
      case CiscoTile.CTI_REMOTE_DEVICE:
      case CiscoTile.SPARK_REMOTE_DEVICE: {
        const preDeleteStates = this.tileStates[tileState.serviceType].map((t: CiscoTileState, idx: number) =>
          idx === i ? { ...t, isDeleting: true } : t
        );
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: [...preDeleteStates],
        };
        this._getServiceDeleteRequest(tileState).subscribe();
        break;
      }
      // When deleting a Deskphone delete any agent extensions that might be on a secondary line
      case CiscoTile.DESKPHONE: {
        const preDeleteStates = this.tileStates[tileState.serviceType].map((t: CiscoTileState, idx: number) =>
          idx === i ? { ...t, isDeleting: true } : t
        );
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: [...preDeleteStates],
        };

        const ipccExtension = this.userDetailUiContext.getCurrentIpccExtension();
        const primaryExtension = this.userDetailUiContext.getCurrentPrimaryExtension();

        if (ipccExtension) {
          this._getService(tileState.service.url).subscribe((phone: Phone) => {
            const secondaryAgentLine = phone.buttons.find((button: LineButton) => {
              if (
                button.type === 'Line' &&
                button.dn &&
                button.dn.id === ipccExtension.id &&
                button.dn.id !== primaryExtension.id
              ) {
                return button;
              }
            }) as LineButton;

            if (secondaryAgentLine) {
              this.endUserResource
                .get(this.endUserResult.ref.id, this.endUserResult.ref.serverId)
                .subscribe((endUser: EndUser) => {
                  endUser.ipccExtension = null;

                  forkJoin([
                    this.endUserResource.put(endUser, this.endUserResult.ref.serverId),
                    this._deprovisionService(tileState, secondaryAgentLine.dn.url),
                  ]).subscribe(() => {
                    this._getServiceDeleteRequest(tileState).subscribe(() =>
                      this._removeServiceFromGlobal360(CiscoTile.UCCX_AGENT, this.endUserResult.ref.username)
                    );
                  });
                });
            } else {
              this._getServiceDeleteRequest(tileState).subscribe();
            }
          });
        } else {
          this._getServiceDeleteRequest(tileState).subscribe();
        }
        break;
      }
      case CiscoTile.WEBEX_CALLING: {
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: {
            ...this.tileStates[tileState.serviceType],
            isDeleting: true,
          },
        };
        this._deprovisionService(this.tileStates.webexCalling, this.tileStates.webexCalling.service.url).subscribe(
          () => {
            const tileState = cloneDeep(this.tileStates.webexCalling);
            this._removeServiceFromGlobal360(tileState.serviceType);
            this._showDeleteToastForTile(tileState);
          }
        );
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * If a delete is cancelled, revert the tile back to an edit tile
   */
  onDeleteTileCancelButtonClicked(tileState: CiscoTileState, i: number = null): void {
    switch (tileState.serviceType) {
      // Single tiles
      case CiscoTile.PRIMARY_EXT:
      case CiscoTile.PCCE_AGENT:
      case CiscoTile.IMP:
      case CiscoTile.VOICEMAIL:
      case CiscoTile.SNR:
      case CiscoTile.WEBEX_CALLING:
      case CiscoTile.EXT_MOBILITY: {
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: {
            ...this.tileStates[tileState.serviceType],
            tileType: ServiceTileType.EDIT,
          },
        };
        break;
      }
      // Multi tiles
      case CiscoTile.TABLET:
      case CiscoTile.IM_SOFTPHONE:
      case CiscoTile.CIPC:
      case CiscoTile.ANDROID:
      case CiscoTile.IPHONE:
      case CiscoTile.DESKPHONE:
      case CiscoTile.CTI_PORT:
      case CiscoTile.CTI_REMOTE_DEVICE:
      case CiscoTile.SPARK_REMOTE_DEVICE: {
        const updatedTileStates = this.tileStates[tileState.serviceType].map((t: CiscoTileState, idx: number) =>
          idx === i ? { ...t, tileType: ServiceTileType.EDIT } : t
        );
        this.tileStates = {
          ...this.tileStates,
          [tileState.serviceType]: [...updatedTileStates],
        };
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Show change primary extension modal allowing user to select new primary extension
   * Manually updated 360 view state, and tile state once new extension has been selected
   */
  onEditTileExchangeClicked() {
    const options = {
      modalViewProperties: {
        icon: SmacsIcons.EXCHANGE,
        iconClass: encodeURIComponent('text-info'),
        title: 'tkey;pages.userdetail.changeprimaryextension.header',
        promptBody: 'tkey;pages.userdetail.changeprimaryextension.text',
        displayCloseButton: true,
        endUserResult: this.endUserResult,
        candidates: this.userDetailUiContext.getPrimaryExtensionCandidates(),
        currentExtension: this.userDetailUiContext.getCurrentPrimaryExtension(),
        buttons: [
          {
            label: 'tkey;pages.userdetail.dnpicker.button',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'smacs-change-primary-extension-modal-submit',
            isSubmitButton: true,
          },
        ],
      },
      bodyClass: ChangePrimaryExtensionModalComponent,
    };

    this.smacsModalService
      .openPromptModal(() => options.modalViewProperties, options)
      .subscribe((dnRef: DirectoryNumberRef) => {
        if (dnRef) {
          this._pushToast(this.endUserResult.ref.username);
          const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
          const currentExtension = this.userDetailUiContext.getCurrentPrimaryExtension();
          const translationPatternRef = this.userDetailUiContext.getCurrentTranslationPattern();

          global360.primaryExtensions = global360.primaryExtensions.filter((ref: DirectoryNumberRef) => {
            if (ref.id !== currentExtension.id) {
              return ref;
            }
          });
          global360.primaryExtensions = [...global360.primaryExtensions, dnRef];
          this.global360ViewContext._stateSource.next(global360);
          this._removeServiceFromTileStates(CiscoTile.PRIMARY_EXT, null);
          this.tileStates = {
            ...this.tileStates,
            [CiscoTile.PRIMARY_EXT]: this.tileMappingService.mapPrimaryExtensionToTile(
              dnRef,
              this._tileConfigs,
              this._getPrimaryExtTileDescription(dnRef, translationPatternRef),
              this.userDetailUiContext.getPrimaryExtensionCandidates(),
              this.hasSites
            ),
          };
        }
      });
  }

  getMultiTileDescription(translation: string, count: number): string {
    return `${this.translateService.instant(translation)} (${count})`;
  }

  /**
   * If a user has any services, display tooltip on primary ext. tile
   */
  getPrimaryExtensionDeleteTooltip(): string {
    if (
      this.userDetailUiContext.hasSnr() ||
      this.userDetailUiContext.hasExtensionMobility() ||
      this.userDetailUiContext.hasPhones()
    ) {
      return 'tkey;userdetail.primary_extension.delete.tooltip';
    }

    return null;
  }

  /**
   * If a user has any services enable the delete all button
   */
  isDeleteAllEnabled(): boolean {
    const primaryExt = this.userDetailUiContext.getCurrentPrimaryExtension();
    const pcceAgents = this.userDetailUiContext.getPcceAgents();

    return !!(
      primaryExt ||
      this.userDetailUiContext.hasSnr() ||
      this.userDetailUiContext.hasExtensionMobility() ||
      this.userDetailUiContext.getCurrentVoicemail() ||
      this.userDetailUiContext.hasPhones() ||
      pcceAgents.length ||
      this.endUserResult.imPresenceEnabled ||
      this.userDetailUiContext.hasWebexEndUser()
    );
  }

  /**
   * Called by associated phone component after a phone has been fixed
   * Calls method with appropriate Tile type based on phone that was fixed
   */
  onDeviceFixed(phone: Phone): void {
    switch (phone.model) {
      case KnownPhoneModels.ANDROID: {
        this._fixAssociatedPublicPhone(CiscoTile.ANDROID, phone);
        this._initTileStates(CiscoTile.ANDROID);
        break;
      }
      case KnownPhoneModels.IPHONE: {
        this._fixAssociatedPublicPhone(CiscoTile.IPHONE, phone);
        this._initTileStates(CiscoTile.IPHONE);
        break;
      }
      case KnownPhoneModels.CIPC: {
        this._fixAssociatedPublicPhone(CiscoTile.CIPC, phone);
        this._initTileStates(CiscoTile.CIPC);
        break;
      }
      case KnownPhoneModels.TABLET: {
        this._fixAssociatedPublicPhone(CiscoTile.TABLET, phone);
        this._initTileStates(CiscoTile.TABLET);
        break;
      }
      case KnownPhoneModels.IM_SOFTPHONE: {
        this._fixAssociatedPublicPhone(CiscoTile.IM_SOFTPHONE, phone);
        this._initTileStates(CiscoTile.IM_SOFTPHONE);
        break;
      }
      default: {
        break;
      }
    }
  }

  serviceTileAddClicked($event: { tileState: CiscoTileState; index: number }): void {
    this.onAddTileClicked($event.tileState, $event.index);
  }

  serviceTileEditClicked($event: { tileState: CiscoTileState }): void {
    this.onEditTileClicked($event.tileState);
  }

  serviceTileDeleteClicked($event: { tileState: CiscoTileState; index: number }): void {
    this.onEditTileDeleteClicked($event.tileState, $event.index);
  }

  serviceTileDeleteConfirmClicked($event: { tileState: CiscoTileState; index: number }): void {
    this.onDeleteTileConfirmButtonClicked($event.tileState, $event.index);
  }

  serviceTileDeleteCancelClicked($event: { tileState: CiscoTileState; index: number }): void {
    this.onDeleteTileCancelButtonClicked($event.tileState, $event.index);
  }

  /**
   * If no services are displayed for the tile, hide the entire column
   */
  displayServiceTiles(tileStates: CiscoTileState[]): boolean {
    return tileStates.some((ts: CiscoTileState) => {
      if (ts.isDisplayed) {
        return ts;
      }
    });
  }

  private _pushToast(userName: string): void {
    this.toastService.push(
      ToastTypes.SUCCESS,
      this.smacsIcons.PRIMARY_EXT,
      'tkey;shared.toast.save.success.title',
      'tkey;pages.userdetail.dnpicker.confirmation.toast',
      { name: userName }
    );
  }

  /**
   * Gets data from contexts and APIs used to determine state for 360 view
   * First gets states from contexts, then tile config based off site in userDetailUiContext, finally call to initialize
   * default tile state
   */
  private _initView(): void {
    const subs = combineLatest([
      this.userDetailUiContext.state$.pipe(take(1)),
      this.siteSummaryContext.state$,
      this.ucMetadataCacheContext.state$,
    ])
      .pipe(
        switchMap((data: [number, SiteSummary, UcMetadataCache]) => {
          this._siteId = data[0];
          this._siteSummary = data[1];
          this._ucMetadataCache = data[2];
          this.endUserResult = { ...this.userDetailUiContext.getCurrentEnduser() };
          this.hasSites = this._siteSummary.clusters.length > 0;
          if (this.userDetailUiContext.getCurrentSite()) {
            let siteResult: SiteResult;
            this._siteSummary.clusters.forEach((cluster: ClusterResult) => {
              cluster.sites.forEach((site: SiteResult) => {
                if (site.id === Number(this.userDetailUiContext.getCurrentSite()?.id)) {
                  siteResult = site;
                }
              });
            });

            if (siteResult) {
              this._unityServerId = siteResult.unityServerId;
            }
            return this._initTileConfigs();
          }
        })
      )
      .subscribe(() => {
        this._initTileStates();
        this._configureClusterDropdown();
      });
    this._subscriptions.add(subs);
  }

  changeCluster(cluster: ClusterResult) {
    if (this.currentClusterName === cluster.name) {
      return;
    }
    this.isLoading = true;
    this.currentClusterContext.setCurrentClusterFromCucmServer(cluster.cucmServerId);
    this.userDetailUiContext.initOnCluster(cluster, this._siteSummary);
    this.userDetailUiContext.state$
      .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)))
      .pipe(take(1))
      .subscribe(() => {
        const sub = this.siteContext.state$.pipe(skip(1)).subscribe((state2) => {
          if (Number(state2.id) !== this._siteId) {
            this._initView();
          } else {
            this.currentClusterContext.setCurrentClusterFromSite(this._siteId);
            this._initView();
          }
        });

        this._subscriptions.add(sub);
      });
  }

  /**
   * Configure options for cluster dropdown. Only show when:
   * - dropdown enabled in settings
   * - user is on multiple clusters
   * - the currentUser has access to those clusters
   */
  private _configureClusterDropdown() {
    const sub = combineLatest([
      this.global360ViewContext.state$,
      this.helpdeskOptionsContext.state$,
      this.currentClusterContext.state$,
    ])
      .pipe(distinctUntilChanged((prev, curr) => isEqual(prev[1], curr[1])))
      .subscribe((data: [Global360View, CiscoHelpdeskOptions, ClusterResult]) => {
        const global360View = data[0];
        const helpdeskOptions = data[1];
        const clusterResult = data[2];

        if (helpdeskOptions.display360ViewClusterDropdownEnabled && global360View.endUsers.length > 1) {
          this.currentClusterName = clusterResult.name;
          const endUserServerIds = global360View.endUsers.map(
            (endUserResult: EndUserResult) => endUserResult.ref.serverId
          );
          const clusterDropdownOptions = this._siteSummary.clusters.filter((clusterResult: ClusterResult) => {
            return endUserServerIds.includes(clusterResult.cucmServerId);
          });

          this.clusterDropdownOptions = clusterDropdownOptions
            .filter((clusterResult: ClusterResult) => {
              const cluster = this._siteSummary.clusters.find(
                (ssClusterResult: ClusterResult) => clusterResult.id === ssClusterResult.id
              );
              return cluster.sites.some((siteResult: SiteResult) => siteResult.hasPermission);
            })
            .sort((a, b) => {
              return a.name.localeCompare(b.name);
            });

          this.isClusterDropdownVisible = this.clusterDropdownOptions.length > 1;
        } else {
          this.isClusterDropdownVisible = false;
        }
      });
    this._subscriptions.add(sub);
  }

  /**
   * Manually remove service from the global 360 view context to avoid an additional GET
   */
  private _removeServiceFromGlobal360(serviceType: CiscoTile, id?: string): void {
    const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());

    switch (serviceType) {
      case CiscoTile.PRIMARY_EXT: {
        global360.primaryExtensions = global360.primaryExtensions.filter((ref: DirectoryNumberRef) => {
          if (ref.id !== id) {
            return ref;
          }
        });
        global360.primaryExtensionCandidates = global360.primaryExtensionCandidates.filter(
          (ref: DirectoryNumberRef) => {
            if (ref.id !== id) {
              return ref;
            }
          }
        );
        break;
      }
      case CiscoTile.UCCX_AGENT: {
        global360.uccxAgents = global360.uccxAgents.filter((ref: UccxAgentRef) => {
          if (ref.id !== id) {
            return ref;
          }
        });
        break;
      }
      case CiscoTile.PCCE_AGENT: {
        global360.pcceAgents = global360.pcceAgents.filter((ref: PcceAgentRef) => {
          if (ref.id !== id) {
            return ref;
          }
        });
        break;
      }
      case CiscoTile.VOICEMAIL: {
        global360.voicemails = global360.voicemails.filter((ref: VoicemailRef) => {
          if (ref.id !== id) {
            return ref;
          }
        });
        break;
      }
      case CiscoTile.SNR: {
        global360.snrProfiles = global360.snrProfiles.filter((ref: SnrProfileRef) => {
          if (ref.id !== id) {
            return ref;
          }
        });
        break;
      }
      case CiscoTile.EXT_MOBILITY: {
        global360.extensionMobilities = global360.extensionMobilities.filter((ref: ExtensionMobilityRef) => {
          if (ref.id !== id) {
            return ref;
          }
        });
        break;
      }
      case CiscoTile.IMP: {
        this.endUserResult.imPresenceEnabled = false;
        const updatedEndUsers = global360.endUsers.map((endUserResult: EndUserResult) => {
          if (endUserResult.ref.id === this.endUserResult.ref.id) {
            return {
              ...endUserResult,
              imPresenceEnabled: false,
            };
          } else {
            return endUserResult;
          }
        });
        global360.endUsers = cloneDeep(updatedEndUsers);
        break;
      }
      case CiscoTile.WEBEX_CALLING: {
        global360.webexEndUser = {
          ...global360.webexEndUser,
          webexCallingLicense: null,
          location: null,
          did: null,
          extension: null,
        };
        break;
      }
      case CiscoTile.TABLET:
      case CiscoTile.IM_SOFTPHONE:
      case CiscoTile.CIPC:
      case CiscoTile.ANDROID:
      case CiscoTile.IPHONE:
      case CiscoTile.DESKPHONE:
      case CiscoTile.CTI_PORT:
      case CiscoTile.CTI_REMOTE_DEVICE:
      case CiscoTile.SPARK_REMOTE_DEVICE: {
        global360.phones = global360.phones.filter((ref: PhoneRef) => {
          if (ref.id !== id) {
            return ref;
          }
        });
        break;
      }
      default: {
        break;
      }
    }

    this.global360ViewContext._stateSource.next(global360);
    this._removeServiceFromTileStates(serviceType, id);
    if (serviceType === CiscoTile.WEBEX_CALLING) {
      this._ensureServiceHasTile(serviceType, global360.webexEndUser);
    } else {
      this._ensureServiceHasTile(serviceType);
    }
  }

  /**
   * Remove the service from the UI without changing the state of any other tiles
   */
  private _removeServiceFromTileStates(serviceType: CiscoTile, id?: string) {
    switch (serviceType) {
      case CiscoTile.PRIMARY_EXT:
      case CiscoTile.WEBEX_CALLING:
      case CiscoTile.PCCE_AGENT:
      case CiscoTile.VOICEMAIL:
      case CiscoTile.SNR:
      case CiscoTile.EXT_MOBILITY:
      case CiscoTile.IMP: {
        this.tileStates = {
          ...this.tileStates,
          [serviceType]: null,
        };
        break;
      }
      case CiscoTile.UCCX_AGENT:
      case CiscoTile.TABLET:
      case CiscoTile.IM_SOFTPHONE:
      case CiscoTile.CIPC:
      case CiscoTile.ANDROID:
      case CiscoTile.IPHONE:
      case CiscoTile.DESKPHONE:
      case CiscoTile.CTI_PORT:
      case CiscoTile.CTI_REMOTE_DEVICE:
      case CiscoTile.SPARK_REMOTE_DEVICE: {
        const updatedStates = this.tileStates[serviceType].filter((ts: CiscoTileState) => {
          if (ts.service && ts.service['id'] !== id) {
            return ts;
          }
        });
        this.tileStates = {
          ...this.tileStates,
          [serviceType]: updatedStates,
        };
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * Create add tile for service if no edits exist
   */
  private _ensureServiceHasTile(serviceType: CiscoTile, ref?: any) {
    switch (serviceType) {
      case CiscoTile.PRIMARY_EXT: {
        this.tileStates = {
          ...this.tileStates,
          [serviceType]: {
            ...this.tileMappingService.mapPrimaryExtensionToTile(
              null,
              this._tileConfigs,
              this._getPrimaryExtTileDescription(null, null),
              this.userDetailUiContext.getPrimaryExtensionCandidates(),
              this.hasSites
            ),
            isDeleted: true,
          },
        };
        break;
      }
      case CiscoTile.PCCE_AGENT: {
        this.tileStates = {
          ...this.tileStates,
          [serviceType]: {
            ...this.tileMappingService.mapServiceToTile(
              CiscoTile.PCCE_AGENT,
              this._tileConfigs,
              null,
              this.userDetailUiContext.getCurrentPrimaryExtension(),
              this.hasSites
            ),
            isDeleted: true,
          },
        };
        break;
      }
      case CiscoTile.VOICEMAIL: {
        this.tileStates = {
          ...this.tileStates,
          [serviceType]: {
            ...this.tileMappingService.mapServiceToTile(
              serviceType,
              this._tileConfigs,
              null,
              this.userDetailUiContext.getCurrentPrimaryExtension(),
              this.hasSites
            ),
            isDeleted: true,
          },
        };
        break;
      }
      case CiscoTile.SNR: {
        this.tileStates = {
          ...this.tileStates,
          [serviceType]: {
            ...this.tileMappingService.mapServiceToTile(
              serviceType,
              this._tileConfigs,
              null,
              this.userDetailUiContext.getCurrentPrimaryExtension(),
              this.hasSites
            ),
            isDeleted: true,
          },
        };
        break;
      }
      case CiscoTile.EXT_MOBILITY: {
        this.tileStates = {
          ...this.tileStates,
          [serviceType]: {
            ...this.tileMappingService.mapServiceToTile(
              serviceType,
              this._tileConfigs,
              null,
              this.userDetailUiContext.getCurrentPrimaryExtension(),
              this.hasSites
            ),
            isDeleted: true,
          },
        };
        break;
      }
      case CiscoTile.IMP: {
        this.tileStates = {
          ...this.tileStates,
          [serviceType]: {
            ...this.tileMappingService.mapImpToTile(this.endUserResult, this._tileConfigs, this.hasSites),
            isDeleted: true,
          },
        };
        break;
      }
      case CiscoTile.WEBEX_CALLING: {
        this.tileStates = {
          ...this.tileStates,
          [serviceType]: {
            ...this.tileMappingService.mapServiceToTile(serviceType, this._tileConfigs, ref, null, this.hasSites),
            isDeleted: true,
          },
        };
        break;
      }
      case CiscoTile.TABLET:
      case CiscoTile.IM_SOFTPHONE:
      case CiscoTile.CIPC:
      case CiscoTile.ANDROID:
      case CiscoTile.IPHONE:
      case CiscoTile.DESKPHONE: {
        if (!this.tileStates[serviceType].length) {
          this.tileStates = {
            ...this.tileStates,
            [serviceType]: [
              {
                ...this.tileMappingService.mapServiceToTile(
                  serviceType,
                  this._tileConfigs,
                  null,
                  this.userDetailUiContext.getCurrentPrimaryExtension(),
                  this.hasSites
                ),
                isDeleted: true,
              },
            ],
          };
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  /**
   * When the associated device has been fixed manually update the global 360 context to include it, then refresh the
   * tile states
   */
  private _fixAssociatedPublicPhone(serviceType: CiscoTile, phone: Phone): void {
    const phoneRef = this.associatedPublicPhones.find((ref: PhoneRef) => {
      if (ref.id === phone.id) {
        return ref;
      }
    });

    const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
    global360.phones.push(phoneRef);
    global360.associatedPublicPhones = global360.associatedPublicPhones.filter((ref: PhoneRef) => {
      if (ref.id !== phone.id) {
        return ref;
      }
    });
    this.global360ViewContext._stateSource.next(global360);
    this.associatedPublicPhones = [...global360.associatedPublicPhones];

    this._showAddToastForTile({ serviceType: serviceType } as CiscoTileState, { name: phone.name });
  }

  /**
   * For multi tiles, filter out any tile without a service (ADD tiles) and call to deprovision that service
   */
  private _getServiceDeleteRequests(tileStates: CiscoTileState[]): Observable<void>[] {
    return tileStates
      .filter((tileState: CiscoTileState) => {
        if (tileState.service) {
          return tileState;
        }
      })
      .map((tileState: CiscoTileState) => this._getServiceDeleteRequest(tileState));
  }

  /**
   * Deprovision single service, once complete show success toast, and remove from UI state
   */
  private _getServiceDeleteRequest(tileState: CiscoTileState): Observable<void> {
    return this._deprovisionService(tileState, tileState.service.url).pipe(
      map(() => {
        this._removeServiceFromGlobal360(tileState.serviceType, tileState.service['id']);
        this._showDeleteToastForTile(tileState);
      })
    );
  }

  private _deleteVoicemailAndPrimaryExtension(): Observable<void> {
    const vmTile = cloneDeep(this.tileStates.voicemail);
    const primaryExtTile = cloneDeep(this.tileStates.primaryExtension);

    return this._deprovisionService(this.tileStates.voicemail, this.tileStates.voicemail.service.url).pipe(
      switchMap(() => {
        this._removeServiceFromGlobal360(vmTile.serviceType, vmTile.service['id']);
        this._showDeleteToastForTile(vmTile);
        const handleEndUserDefaultsIfSitesConfigured = this.hasSites ? this._getEndUserDefault(false) : of(null);
        return handleEndUserDefaultsIfSitesConfigured.pipe(
          switchMap((endUserDefault: EndUser) => this._deprovisionPrimaryExtension(endUserDefault))
        );
      }),
      catchError((error) => {
        if (error.status === 422) {
          this.tileStates = {
            ...this.tileStates,
            [primaryExtTile.serviceType]: {
              ...this.tileStates[primaryExtTile.serviceType],
              isDeleting: false,
              tileType: ServiceTileType.EDIT,
            },
          };
        }
        return of(error);
      })
    );
  }

  private _cancelVoicemailAndPrimaryExtensionDeleteConfirmation(): Observable<void> {
    return new Observable<void>((subscriber) => {
      this.tileStates = {
        ...this.tileStates,
        primaryExtension: {
          ...this.tileStates.primaryExtension,
          tileType: ServiceTileType.EDIT,
          isDeleting: false,
        },
      };
      subscriber.next();
      subscriber.complete();
    });
  }

  /**
   * Delete all services from user
   * Delete all the services except primary extension concurrently, when those are deprovisioned remove primary
   * extension and reset the end user object. If the user has an IPCC ext. that is different than the primary ext.
   * delete that DN with a separate request
   */
  private _onDeleteAllConfirm(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      let devicesToDelete = [];
      const ipccExtension = this.userDetailUiContext.getCurrentIpccExtension();
      const webexEndUser = this.userDetailUiContext.getWebexEndUser();
      if (ipccExtension && ipccExtension.id !== this.userDetailUiContext.getCurrentPrimaryExtension().id) {
        devicesToDelete.push(this._deprovisionService(null, ipccExtension.url));
      }

      if (this.tileStates.pcceAgent.service) {
        devicesToDelete.push(this._getServiceDeleteRequest(this.tileStates.pcceAgent));
      }

      if (this.tileStates.snr.service) {
        devicesToDelete.push(this._getServiceDeleteRequest(this.tileStates.snr));
      }

      if (this.tileStates.extMobility.service) {
        devicesToDelete.push(this._getServiceDeleteRequest(this.tileStates.extMobility));
      }

      if (this.tileStates.imPresence.service && this.endUserResult.imPresenceEnabled) {
        devicesToDelete.push(this._getServiceDeleteRequest(this.tileStates.imPresence));
      }

      if (webexEndUser && webexEndUser.webexCallingLicense) {
        devicesToDelete.push(this._getServiceDeleteRequest(this.tileStates.webexCalling));
      }

      devicesToDelete = devicesToDelete.concat(this._getServiceDeleteRequests(this.tileStates.deskphone));
      devicesToDelete = devicesToDelete.concat(this._getServiceDeleteRequests(this.tileStates.imSoftphone));
      devicesToDelete = devicesToDelete.concat(this._getServiceDeleteRequests(this.tileStates.cipc));
      devicesToDelete = devicesToDelete.concat(this._getServiceDeleteRequests(this.tileStates.android));
      devicesToDelete = devicesToDelete.concat(this._getServiceDeleteRequests(this.tileStates.iphone));
      devicesToDelete = devicesToDelete.concat(this._getServiceDeleteRequests(this.tileStates.tablet));
      devicesToDelete = devicesToDelete.concat(this._getServiceDeleteRequests(this.tileStates.ctiPort));
      devicesToDelete = devicesToDelete.concat(this._getServiceDeleteRequests(this.tileStates.ctiRemoteDevice));
      devicesToDelete = devicesToDelete.concat(this._getServiceDeleteRequests(this.tileStates.sparkRemoteDevice));

      from(devicesToDelete)
        .pipe(mergeMap((request: Observable<void>) => request, 3))
        .subscribe({
          complete: () => {
            if (this.tileStates.primaryExtension.service && this.tileStates.voicemail.service) {
              this._deleteVoicemailAndPrimaryExtension().subscribe(() => {
                subscriber.next();
                subscriber.complete();
              });
            } else if (this.tileStates.primaryExtension.service) {
              this._deprovisionPrimaryExtension().subscribe(() => {
                subscriber.next();
                subscriber.complete();
              });
            } else {
              if (this.hasSites) {
                // Make sure none of the other tiles are enabled, and that they all have the primary ext tooltip
                this._disableTileStates();
              }
              subscriber.next();
              subscriber.complete();
            }
          },
        });
    });
  }

  /**
   * When deprovisioning a primary extension the following must be done:
   * 1. Delete the primary ext.
   * 2. Get and update the end user tied to that primary ext.
   * 3. Update the UI state to remove any UCCX agents tied to that primary ext.
   * 4. Update the UI state to disable all the tiles for services that need a primary ext. to be provisioned
   */
  private _deprovisionPrimaryExtension(endUserDefault?: EndUser): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._deprovisionService(
        this.tileStates.primaryExtension,
        this.tileStates.primaryExtension.service.url
      ).subscribe(() => {
        this.endUserResource
          .get(this.endUserResult.ref.id, this.endUserResult.ref.serverId)
          .subscribe((endUser: EndUser) => {
            endUser.primaryExtension = null;
            endUser.ipccExtension = null;
            endUser.selfServiceUserId = '';

            if (endUserDefault) {
              endUser.ucServiceProfile = endUserDefault.ucServiceProfile;
            }

            this.endUserResource.put(endUser, this.endUserResult.ref.serverId).subscribe(() => {
              const primaryExtCopy = cloneDeep(this.tileStates.primaryExtension);
              this._removeServiceFromGlobal360(primaryExtCopy.serviceType, primaryExtCopy.service['id']);
              this._showDeleteToastForTile(primaryExtCopy);

              const uccxClone = cloneDeep(this.tileStates.uccxAgent);
              uccxClone.forEach((state: CiscoTileState) =>
                this._removeServiceFromGlobal360(state.serviceType, state.service['id'])
              );

              // Remove IPCC from global 360 view
              const global360Clone = cloneDeep(this.userDetailUiContext.getGlobal360View());
              global360Clone.ipccExtensions = global360Clone.ipccExtensions.filter((ipccExt: DirectoryNumberRef) => {
                if (ipccExt.id !== primaryExtCopy.service['id']) {
                  return ipccExt;
                }
              });
              this.global360ViewContext._stateSource.next(global360Clone);

              if (this.hasSites) {
                // Make sure none of the other tiles are enabled, and that they all have the primary ext tooltip
                this._disableTileStates();
              }

              subscriber.next();
              subscriber.complete();
            });
          });
      });
    });
  }

  private _getDefaultsUrlForService(tileState: CiscoTileState) {
    switch (tileState.serviceType) {
      case CiscoTile.VOICEMAIL: {
        return '/services/cisco/defaults/voicemail';
      }
      case CiscoTile.SNR: {
        return '/services/cisco/defaults/snr';
      }
      case CiscoTile.EXT_MOBILITY: {
        return '/services/cisco/defaults/extension-mobility';
      }
      case CiscoTile.TABLET: {
        return '/services/cisco/defaults/phones/tablet';
      }
      case CiscoTile.IM_SOFTPHONE: {
        return '/services/cisco/defaults/phones/im-softphone';
      }
      case CiscoTile.CIPC: {
        return '/services/cisco/defaults/phones/cipc';
      }
      case CiscoTile.ANDROID: {
        return '/services/cisco/defaults/phones/android';
      }
      case CiscoTile.IPHONE: {
        return '/services/cisco/defaults/phones/iphone';
      }
      default: {
        break;
      }
    }
  }

  private _showDeleteToastForTile(tileState: CiscoTileState) {
    switch (tileState.serviceType) {
      case CiscoTile.PRIMARY_EXT: {
        this.toastService.pushDeleteToast('tkey;shared.service.primary.extension.text', tileState.description);
        break;
      }
      case CiscoTile.PCCE_AGENT: {
        this.toastService.pushDeleteToast('tkey;shared.service.agent.text', tileState.description);
        break;
      }
      case CiscoTile.IMP: {
        this.toastService.pushDeleteToast('tkey;shared.service.imp.text', this.endUserResult.ref.username);
        break;
      }
      case CiscoTile.VOICEMAIL: {
        this.toastService.pushDeleteToast('tkey;shared.service.voicemail.text', tileState.description);
        break;
      }
      case CiscoTile.SNR: {
        this.toastService.pushDeleteToast('tkey;shared.service.snr.text', tileState.description);
        break;
      }
      case CiscoTile.EXT_MOBILITY: {
        this.toastService.pushDeleteToast('tkey;shared.service.extensionmobility.text', tileState.description);
        break;
      }
      case CiscoTile.TABLET: {
        this.toastService.pushDeleteToast('tkey;shared.model.tablet.text', tileState.description);
        break;
      }
      case CiscoTile.IM_SOFTPHONE: {
        this.toastService.pushDeleteToast('tkey;shared.model.imsoftphone.text', tileState.description);
        break;
      }
      case CiscoTile.CIPC: {
        this.toastService.pushDeleteToast('tkey;shared.model.cipc.text', tileState.description);
        break;
      }
      case CiscoTile.ANDROID: {
        this.toastService.pushDeleteToast('tkey;shared.model.android.text', tileState.description);
        break;
      }
      case CiscoTile.IPHONE: {
        this.toastService.pushDeleteToast('tkey;shared.model.iphone.text', tileState.description);
        break;
      }
      case CiscoTile.DESKPHONE: {
        this.toastService.pushDeleteToast(
          'tkey;shared.model.deskphone.text',
          tileState.description || tileState.serviceName
        );
        break;
      }
      case CiscoTile.CTI_PORT: {
        this.toastService.pushDeleteToast('tkey;shared.model.ctiport.text', tileState.description);
        break;
      }
      case CiscoTile.CTI_REMOTE_DEVICE: {
        this.toastService.pushDeleteToast('tkey;shared.model.ctiremotedevice.text', tileState.description);
        break;
      }
      case CiscoTile.SPARK_REMOTE_DEVICE: {
        this.toastService.pushDeleteToast('tkey;shared.model.sparkremotedevice.text', tileState.description);
        break;
      }
      case CiscoTile.WEBEX_CALLING: {
        this.toastService.pushDeleteToast('tkey;shared.service.webex_calling.text', tileState.description);
        break;
      }
      default: {
        break;
      }
    }
  }

  private _showAddToastForTile(tileState: CiscoTileState, ref: any) {
    switch (tileState.serviceType) {
      case CiscoTile.IMP: {
        this.toastService.pushSaveToast('tkey;shared.service.imp.text', ref.description, this.smacsIcons.IMP);
        break;
      }
      case CiscoTile.VOICEMAIL: {
        this.toastService.pushSaveToast('tkey;shared.service.voicemail.text', ref.alias, this.smacsIcons.VOICEMAIL);
        break;
      }
      case CiscoTile.SNR: {
        this.toastService.pushSaveToast('tkey;shared.service.snr.text', ref.description, this.smacsIcons.SNR);
        break;
      }
      case CiscoTile.EXT_MOBILITY: {
        this.toastService.pushSaveToast(
          'tkey;shared.service.extensionmobility.text',
          ref.name,
          this.smacsIcons.EXTENSION_MOBILITY
        );
        break;
      }
      case CiscoTile.TABLET: {
        this.toastService.pushSaveToast('tkey;shared.model.tablet.text', ref.name, this.smacsIcons.TABLET);
        break;
      }
      case CiscoTile.IM_SOFTPHONE: {
        this.toastService.pushSaveToast('tkey;shared.model.imsoftphone.text', ref.name, this.smacsIcons.SOFTPHONE);
        break;
      }
      case CiscoTile.CIPC: {
        this.toastService.pushSaveToast('tkey;shared.model.cipc.text', ref.name, this.smacsIcons.CIPC);
        break;
      }
      case CiscoTile.ANDROID: {
        this.toastService.pushSaveToast('tkey;shared.model.android.text', ref.name, this.smacsIcons.ANDROID);
        break;
      }
      case CiscoTile.IPHONE: {
        this.toastService.pushSaveToast('tkey;shared.model.iphone.text', ref.name, this.smacsIcons.IPHONE);
        break;
      }
      default: {
        break;
      }
    }
  }

  private _getServiceDefault(url: string, body: any): Observable<any> {
    return this.http.post(url, {
      ...body,
    });
  }

  private _provisionServiceWithDefaults(url: string, defaults: any, tile: CiscoTileState) {
    const auditTags: Nvp[] = [{ name: 'username', value: this.endUserResult.ref.username }];
    return this.http
      .post(
        url,
        {
          ...defaults,
        },
        auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {}
      )
      .pipe(
        catchError((response) => {
          this.tileStates = {
            ...this.tileStates,
            [tile.serviceType]: {
              ...this.tileStates[tile.serviceType],
              isAdding: false,
            },
          };
          return throwError(() => response);
        })
      );
  }

  private _getService(url: string): Observable<any> {
    return this.http.get(url);
  }

  private _deprovisionService(tileState: CiscoTileState, url: string): Observable<any> {
    const auditTags: Nvp[] = [{ name: 'username', value: this.endUserResult.ref.username }];
    if (tileState) {
      const cucmServerId = tileState.service.serverId;
      const serviceId = tileState?.service.id;
      switch (tileState.serviceType) {
        case CiscoTile.PRIMARY_EXT:
          return this.directoryNumberResource
            .delete(serviceId, cucmServerId, auditTags)
            .pipe(
              mergeMap(() => {
                if (this.userDetailUiContext.getLdapUser()) {
                  return this.ldapUserResource.get(this.endUserResult.ref.username);
                } else {
                  return of(null);
                }
              })
            )
            .pipe(
              mergeMap((ldapAttributes: LdapUserDialPlanAttributes) => {
                if (ldapAttributes && (ldapAttributes.extension || ldapAttributes.did)) {
                  return this.ldapUserResource.put({ ...ldapAttributes, extension: '', did: '' }).pipe(
                    tap(() => {
                      const global360 = this.userDetailUiContext.getGlobal360View();
                      for (const attribKey in global360.ldapUser.additionalAttributes) {
                        if (
                          global360.ldapUser.extension?.includes(global360.ldapUser.additionalAttributes[attribKey]) ||
                          global360.ldapUser.e164Number?.includes(global360.ldapUser.additionalAttributes[attribKey])
                        ) {
                          global360.ldapUser.additionalAttributes[attribKey] = '';
                        }
                      }
                      this.global360ViewContext._stateSource.next(global360);
                    })
                  );
                } else {
                  return of(null);
                }
              })
            )
            .pipe(
              mergeMap(() => {
                const global360 = this.userDetailUiContext.getGlobal360View();
                const currentTranslationPattern = this.userDetailUiContext.getCurrentTranslationPattern();
                if (currentTranslationPattern) {
                  return this.translationPatternResource
                    .delete(currentTranslationPattern.id, currentTranslationPattern.serverId)
                    .pipe(
                      tap(() => {
                        global360.translationPatterns = global360.translationPatterns.filter(
                          (pattern) => pattern.id !== currentTranslationPattern.id
                        );
                        this.global360ViewContext._stateSource.next(global360);
                      })
                    );
                } else {
                  return of(null);
                }
              })
            );
        case CiscoTile.VOICEMAIL:
          const unityServerId = this.hasSites
            ? this._unityServerId
            : this.userDetailUiContext.getCurrentVoicemail()?.serverId;
          const serviceParams: VoicemailServiceParams = {
            endUserResult: this.userDetailUiContext.getCurrentEnduser(),
            endUsers: this.userDetailUiContext.getEndUsers(),
            unityServerId: unityServerId,
            siteId: Number(this.userDetailUiContext.getCurrentSite().id),
            dnRef: this.userDetailUiContext.getCurrentPrimaryExtension(),
            hasSnr: !!this.userDetailUiContext.hasSnr(),
            hasExtensionMobility: !!this.userDetailUiContext.hasExtensionMobility(),
          };
          return this.hasSites
            ? this.voicemailService.deprovisionVoicemail(serviceId, serviceParams, true)
            : this.voicemailService.simpleDeprovision(serviceId, serviceParams.unityServerId, auditTags);
        case CiscoTile.IMP:
          const serverId = this.userDetailUiContext.getCurrentEnduser().ref.serverId.toString();
          const id = SmacsUserDetailHomeComponent._extractIdFromServiceUrl(url);
          return this.imPresenceResource.delete(id, serverId, auditTags).pipe(
            map(() => {
              this.endUserResult.imPresenceEnabled = false;
              this.global360ViewContext.setImPresenceEnabled(
                this.userDetailUiContext.getGlobal360View(),
                this.endUserResult.ref.serverId,
                false
              );
            })
          );
        case CiscoTile.EXT_MOBILITY:
          return this.extensionMobilityService.delete(serviceId, cucmServerId, auditTags);
        case CiscoTile.SNR:
          return this.snrProfileResource.deleteSnrProfile(serviceId, cucmServerId, auditTags);
        case CiscoTile.ANDROID:
        case CiscoTile.IM_SOFTPHONE:
        case CiscoTile.TABLET:
        case CiscoTile.IPHONE:
        case CiscoTile.DESKPHONE:
        case CiscoTile.CTI_PORT:
        case CiscoTile.CTI_REMOTE_DEVICE:
        case CiscoTile.SPARK_REMOTE_DEVICE:
          return this.phoneResource.delete(serviceId, cucmServerId, auditTags);
        case CiscoTile.WEBEX_CALLING:
          return this._webexUserResource.delete(this.endUserResult.email, auditTags);
        default:
          return this.http.delete(url, {
            headers: this.auditHeaderService.buildHeader(auditTags),
          });
      }
    } else {
      return this.http.delete(url, {
        headers: this.auditHeaderService.buildHeader(auditTags),
      });
    }
  }

  /**
   * Get tile configs for current site
   */
  private _initTileConfigs(): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      const tileConfigSub = this._tileConfigContext
        .getTileConfigs(this._siteId)
        .pipe(switchMap(() => this._tileConfigContext.state$))
        .subscribe((data: TileConfig) => {
          if (!isEqual(this._tileConfigs, data)) {
            this._tileConfigs = data;
            this._initTileStates();
          }
          subscriber.next();
          subscriber.complete();
        });
      this._subscriptions.add(tileConfigSub);
    });
  }

  private _getPrimaryExtTileDescription(
    directoryNumberRef: DirectoryNumberRef,
    translationPatternRef: TranslationPatternRef
  ): string {
    if (directoryNumberRef && translationPatternRef) {
      const parsedDn = this.telephoneNumberFilter.transform(directoryNumberRef.extension);
      const parsedDid = this.telephoneNumberFilter.transform(translationPatternRef.pattern);
      return `${parsedDn} | ${parsedDid}`;
    } else if (directoryNumberRef) {
      const parsedDn = this.telephoneNumberFilter.transform(directoryNumberRef.extension);
      return `${parsedDn}`;
    } else if (translationPatternRef) {
      const parsedDid = this.telephoneNumberFilter.transform(translationPatternRef.pattern);
      return `${parsedDid}`;
    }
  }

  /**
   * Create an entirely new state based off values in user detail ui context
   */
  private _initTileStates(serviceType?: CiscoTile): void {
    this.associatedPublicPhones = this.userDetailUiContext.getAssociatedPublicPhones();

    const dnRef = this.userDetailUiContext.getCurrentPrimaryExtension();
    const translationPatternRef = this.userDetailUiContext.getCurrentTranslationPattern();
    const voicemailRef = this.userDetailUiContext.getCurrentVoicemail();
    const snrProfileRef = this.userDetailUiContext.getCurrentSnrProfile();
    const extensionMobilityRef = this.userDetailUiContext.getCurrentExtensionMobility();
    const ipccExtensionRef = this.userDetailUiContext.getCurrentIpccExtension();
    const uccxAgentRefs = ipccExtensionRef ? this.userDetailUiContext.getUccxAgents() : [];
    const pcceAgentRefs = this.userDetailUiContext.getPcceAgents();
    const deskphoneRefs = this.userDetailUiContext.getDeskphones();
    const iphoneRefs = this.userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.IPHONE);
    const androidRefs = this.userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.ANDROID);
    const tabletRefs = this.userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.TABLET);
    const ctiPortRefs = this.userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.CTI_PORT);
    const ctiRemoteDeviceRefs = this.userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.CTI_REMOTE_DEVICE);
    const sparkRemoteDeviceRefs = this.userDetailUiContext.getCurrentPhonesByModel(
      KnownPhoneModels.SPARK_REMOTE_DEVICE
    );
    const cipcRefs = this.userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.CIPC);
    const imSoftphoneRefs = this.userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.IM_SOFTPHONE);
    const primaryExtensionCandidates = this.userDetailUiContext.getPrimaryExtensionCandidates();
    const translationPatternRefFormatted = translationPatternRef
      ? this.dnDetailService.getDid([translationPatternRef], false)
      : null;
    const webexEndUser = this.userDetailUiContext.getWebexEndUser();

    if (serviceType) {
      const serviceRefs =
        serviceType === CiscoTile.DESKPHONE
          ? this.userDetailUiContext.getDeskphones()
          : this.userDetailUiContext.getCurrentPhonesByModel(this.mapServiceTypetoKnownModels(serviceType));
      switch (serviceType) {
        case CiscoTile.PRIMARY_EXT: {
          this.tileStates = {
            ...this.tileStates,
            [serviceType]: this.tileMappingService.mapPrimaryExtensionToTile(
              dnRef,
              this._tileConfigs,
              this._getPrimaryExtTileDescription(dnRef, translationPatternRefFormatted),
              primaryExtensionCandidates,
              this.hasSites
            ),
          };
          break;
        }
        case CiscoTile.PCCE_AGENT: {
          this.tileStates = {
            ...this.tileStates,
            [serviceType]: this.tileMappingService.mapServiceToTile(
              CiscoTile.PCCE_AGENT,
              this._tileConfigs,
              pcceAgentRefs,
              dnRef,
              this.hasSites
            ),
          };
          break;
        }
        case CiscoTile.VOICEMAIL: {
          this.tileStates = {
            ...this.tileStates,
            [serviceType]: this.tileMappingService.mapServiceToTile(
              CiscoTile.VOICEMAIL,
              this._tileConfigs,
              voicemailRef,
              dnRef,
              this.hasSites
            ),
          };
          break;
        }
        case CiscoTile.SNR: {
          this.tileStates = {
            ...this.tileStates,
            [serviceType]: this.tileMappingService.mapServiceToTile(
              CiscoTile.SNR,
              this._tileConfigs,
              snrProfileRef,
              dnRef,
              this.hasSites
            ),
          };
          break;
        }
        case CiscoTile.EXT_MOBILITY: {
          this.tileStates = {
            ...this.tileStates,
            [serviceType]: this.tileMappingService.mapServiceToTile(
              CiscoTile.EXT_MOBILITY,
              this._tileConfigs,
              extensionMobilityRef,
              dnRef,
              this.hasSites
            ),
          };
          break;
        }
        case CiscoTile.IMP: {
          this.tileStates = {
            ...this.tileStates,
            [serviceType]: this.tileMappingService.mapImpToTile(this.endUserResult, this._tileConfigs, this.hasSites),
          };
          break;
        }
        case CiscoTile.UCCX_AGENT: {
          this.tileStates = {
            ...this.tileStates,
            [serviceType]: uccxAgentRefs.map((ref: UccxAgentRef) =>
              this.tileMappingService.mapUccxAgentToTile(
                ref,
                uccxAgentRefs,
                this._ucMetadataCache,
                this.userDetailUiContext.getCurrentIpccExtension(),
                this.hasSites
              )
            ),
          };
          break;
        }
        case CiscoTile.TABLET:
        case CiscoTile.IM_SOFTPHONE:
        case CiscoTile.CIPC:
        case CiscoTile.ANDROID:
        case CiscoTile.IPHONE:
        case CiscoTile.DESKPHONE: {
          this.tileStates[serviceType] = this.tileStates[serviceType].length
            ? serviceRefs.map((ref: PhoneRef) =>
                this.tileMappingService.mapServiceToTile(serviceType, this._tileConfigs, ref, dnRef, this.hasSites)
              )
            : [this.tileMappingService.mapServiceToTile(serviceType, this._tileConfigs, null, dnRef, this.hasSites)];
          break;
        }
        default: {
          break;
        }
      }
    } else {
      this.tileStates = {
        ...this.tileStates,
        // Single
        primaryExtension: this.tileMappingService.mapPrimaryExtensionToTile(
          dnRef,
          this._tileConfigs,
          this._getPrimaryExtTileDescription(dnRef, translationPatternRefFormatted),
          primaryExtensionCandidates,
          this.hasSites
        ),
        uccxAgent: uccxAgentRefs.map((ref: UccxAgentRef) =>
          this.tileMappingService.mapUccxAgentToTile(
            ref,
            uccxAgentRefs,
            this._ucMetadataCache,
            this.userDetailUiContext.getCurrentIpccExtension(),
            this.hasSites
          )
        ),
        pcceAgent: this.tileMappingService.mapServiceToTile(
          CiscoTile.PCCE_AGENT,
          this._tileConfigs,
          pcceAgentRefs,
          dnRef,
          this.hasSites
        ),
        voicemail: this.tileMappingService.mapServiceToTile(
          CiscoTile.VOICEMAIL,
          this._tileConfigs,
          voicemailRef,
          dnRef,
          this.hasSites
        ),
        snr: this.tileMappingService.mapServiceToTile(
          CiscoTile.SNR,
          this._tileConfigs,
          snrProfileRef,
          dnRef,
          this.hasSites
        ),
        extMobility: this.tileMappingService.mapServiceToTile(
          CiscoTile.EXT_MOBILITY,
          this._tileConfigs,
          extensionMobilityRef,
          dnRef,
          this.hasSites
        ),
        imPresence: this.tileMappingService.mapImpToTile(this.endUserResult, this._tileConfigs, this.hasSites),
        // Multi
        deskphone: deskphoneRefs.length
          ? deskphoneRefs.map((ref: PhoneRef) =>
              this.tileMappingService.mapServiceToTile(
                CiscoTile.DESKPHONE,
                this._tileConfigs,
                ref,
                dnRef,
                this.hasSites
              )
            )
          : [
              this.tileMappingService.mapServiceToTile(
                CiscoTile.DESKPHONE,
                this._tileConfigs,
                null,
                dnRef,
                this.hasSites
              ),
            ],
        imSoftphone: imSoftphoneRefs.length
          ? imSoftphoneRefs.map((ref: PhoneRef) =>
              this.tileMappingService.mapServiceToTile(
                CiscoTile.IM_SOFTPHONE,
                this._tileConfigs,
                ref,
                dnRef,
                this.hasSites
              )
            )
          : [
              this.tileMappingService.mapServiceToTile(
                CiscoTile.IM_SOFTPHONE,
                this._tileConfigs,
                null,
                dnRef,
                this.hasSites
              ),
            ],
        cipc: cipcRefs.length
          ? cipcRefs.map((ref: PhoneRef) =>
              this.tileMappingService.mapServiceToTile(CiscoTile.CIPC, this._tileConfigs, ref, dnRef, this.hasSites)
            )
          : [this.tileMappingService.mapServiceToTile(CiscoTile.CIPC, this._tileConfigs, null, dnRef, this.hasSites)],
        iphone: iphoneRefs.length
          ? iphoneRefs.map((ref: PhoneRef) =>
              this.tileMappingService.mapServiceToTile(CiscoTile.IPHONE, this._tileConfigs, ref, dnRef, this.hasSites)
            )
          : [this.tileMappingService.mapServiceToTile(CiscoTile.IPHONE, this._tileConfigs, null, dnRef, this.hasSites)],
        android: androidRefs.length
          ? androidRefs.map((ref: PhoneRef) =>
              this.tileMappingService.mapServiceToTile(CiscoTile.ANDROID, this._tileConfigs, ref, dnRef, this.hasSites)
            )
          : [
              this.tileMappingService.mapServiceToTile(
                CiscoTile.ANDROID,
                this._tileConfigs,
                null,
                dnRef,
                this.hasSites
              ),
            ],
        tablet: tabletRefs.length
          ? tabletRefs.map((ref: PhoneRef) =>
              this.tileMappingService.mapServiceToTile(CiscoTile.TABLET, this._tileConfigs, ref, dnRef, this.hasSites)
            )
          : [this.tileMappingService.mapServiceToTile(CiscoTile.TABLET, this._tileConfigs, null, dnRef, this.hasSites)],
        ctiPort: ctiPortRefs.map((ref: PhoneRef) =>
          this.tileMappingService.mapServiceToTile(CiscoTile.CTI_PORT, this._tileConfigs, ref, null, this.hasSites)
        ),
        ctiRemoteDevice: ctiRemoteDeviceRefs.map((ref: PhoneRef) =>
          this.tileMappingService.mapServiceToTile(
            CiscoTile.CTI_REMOTE_DEVICE,
            this._tileConfigs,
            ref,
            null,
            this.hasSites
          )
        ),
        sparkRemoteDevice: sparkRemoteDeviceRefs.map((ref: PhoneRef) =>
          this.tileMappingService.mapServiceToTile(
            CiscoTile.SPARK_REMOTE_DEVICE,
            this._tileConfigs,
            ref,
            null,
            this.hasSites
          )
        ),
        webexCalling: this.tileMappingService.mapServiceToTile(
          CiscoTile.WEBEX_CALLING,
          this._tileConfigs,
          webexEndUser,
          null,
          this.hasSites
        ),
      };
    }
    if (!this.userDetailUiContext.isPrimaryExtPickerModalDisplayed()) {
      this.isLoading = false;
    }
  }

  mapServiceTypetoKnownModels(serviceType: CiscoTile): KnownPhoneModels {
    switch (serviceType) {
      case CiscoTile.TABLET:
        return KnownPhoneModels.TABLET;
      case CiscoTile.IM_SOFTPHONE:
        return KnownPhoneModels.IM_SOFTPHONE;
      case CiscoTile.CIPC:
        return KnownPhoneModels.CIPC;
      case CiscoTile.ANDROID:
        return KnownPhoneModels.ANDROID;
      case CiscoTile.IPHONE:
        return KnownPhoneModels.IPHONE;
      case CiscoTile.CTI_PORT:
        return KnownPhoneModels.CTI_PORT;
      case CiscoTile.CTI_REMOTE_DEVICE:
        return KnownPhoneModels.CTI_REMOTE_DEVICE;
    }
  }

  /**
   * After a delete all disable all the tiles with a tooltip except those that can be provisioned without a primary ext
   * already provisioned
   */
  private _disableTileStates(): void {
    Object.keys(this.tileStates).forEach((key: CiscoTile) => {
      if (key === 'primaryExtension' || key === 'imPresence' || key === 'pcceAgent' || key === 'webexCalling') {
        return;
      }

      const tileState = this.tileStates[key];
      if (isArray(tileState)) {
        this.tileStates = {
          ...this.tileStates,
          [key]: tileState.map((ts: CiscoTileState) => {
            return {
              ...ts,
              isEnabled: false,
              tooltip: 'tkey;userdetail.add.service_tile.missing_primary_extension.tooltip',
            };
          }),
        };
      } else {
        this.tileStates = {
          ...this.tileStates,
          [key]: {
            ...tileState,
            isEnabled: false,
            tooltip: 'tkey;userdetail.add.service_tile.missing_primary_extension.tooltip',
          },
        };
      }
    });
  }

  private openDnDeleteConfirmationModal() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        title: this.translateService.instant('tkey;userdetail.primary_extension.delete.confirmation.modal.header'),
        promptBody: this.translateService.instant(
          'tkey;userdetail.primary_extension.voicemail.delete.confirmation.modal.text',
          {
            extension: this.userDetailUiContext.getCurrentPrimaryExtension().extension,
            voicemail: this.userDetailUiContext.getCurrentVoicemail().alias,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
            cb: () => this._cancelVoicemailAndPrimaryExtensionDeleteConfirmation(),
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteVoicemailAndPrimaryExtension(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _onExtensionMobilityAddComplete(tileState: CiscoTileState, ref: ExtensionMobilityRef) {
    const global360 = cloneDeep(this.userDetailUiContext.getGlobal360View());
    global360.extensionMobilities.push(ref);
    this.global360ViewContext._stateSource.next(global360);
    this._initTileStates(tileState.serviceType);
    this._showAddToastForTile(tileState, ref);
  }

  serviceCopyClicked({ tileState, index }: { tileState: CiscoTileState; index: number }) {
    const currentEndUserUsername = encodeURIComponent(this.endUserResult.ref.username);
    const serviceTileState = this.tileStates[tileState.serviceType];
    if (isArray(serviceTileState)) {
      const serviceId = serviceTileState[index].service.id;
      this.router.navigateByUrl(`/user/${currentEndUserUsername}/deskphone/${serviceId}/copy`);
    }
  }
}
