import { Component, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { PortInOrdersResource } from './port-in-orders.resource';
import {
  BandwidthPortInOrderRef,
  OrderNumberInventory,
  OrderNumberInventoryResponse,
  PortInOrderDraftRef,
} from '../../../shared/models/generated/smacsModels';
import { DatatableColumn, DatatableComponent, DatatableRow } from '../../../reporting/datatable/datatable.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import moment from 'moment';
import { DatatableFilterService } from '../../../reporting/datatable/datatable-filter.service';
import { Router } from '@angular/router';
import { flatMap, uniqWith } from 'lodash';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { TranslateService } from '@ngx-translate/core';
import { TelephoneNumberFilter } from '../../../shared/filters/telephone-number.filter';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PortInDraftsResource } from './port-in-drafts.resource';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { getProcessingStatusHtml, getStringFromProcessingStatus } from './processing-status-helper';
import { NumberInventoriesResource } from '../../resources/number-inventories.resource';

interface PortInOrderDatatableRow extends DatatableRow {
  customerOrderId: string;
  id: string;
  processingStatus: string;
  lastModified: string;
  focDate: string;
  billingNumber: string;
}

@Component({
  selector: 'ziro-port-in-orders',
  templateUrl: './port-in-orders.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [PortInOrdersResource, PortInDraftsResource, NumberInventoriesResource],
})
export class PortInOrdersComponent implements OnInit {
  @ViewChild(DatatableComponent) datatableComponent: DatatableComponent<PortInOrderDatatableRow>;

  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  isLoading = true;
  datatableCols: DatatableColumn<PortInOrderDatatableRow>[] = [
    {
      name: 'customerOrderId',
      label: 'tkey;admin.order_numbers.port_in_orders.datatable.customer_order_id.label',
      sortFn: (a: string, b: string) => {
        return this._getTextFromHtml(a).localeCompare(this._getTextFromHtml(b));
      },
    },
    {
      name: 'processingStatus',
      label: 'tkey;admin.order_numbers.port_in_orders.datatable.processing_status.label',
      sortFn: (a: string, b: string) => {
        return this._getTextFromHtml(a).localeCompare(this._getTextFromHtml(b));
      },
    },
    {
      name: 'lastModified',
      label: 'tkey;admin.order_numbers.port_in_orders.datatable.last_modified.label',
      sortFn: (a: string, b: string) => {
        if (a === b) {
          return 0;
        } else if (!a) {
          return 1;
        } else if (!b || b.includes('Not Applicable')) {
          return -1;
        } else {
          return moment(b).diff(a);
        }
      },
    },
    {
      name: 'focDate',
      label: 'tkey;admin.order_numbers.port_in_orders.datatable.foc_date.label',
      sortFn: (a: string, b: string) => {
        if (a === b) {
          return 0;
        } else if (!a) {
          return -1;
        } else if (!b) {
          return 1;
        } else {
          return moment(b.split('<')[0].trim()).diff(a.split('<')[0].trim());
        }
      },
    },
    {
      name: 'billingNumber',
      label: 'tkey;admin.order_numbers.port_in_orders.datatable.billing_number.label',
    },
  ];
  datatableRows: PortInOrderDatatableRow[] = [];
  processingStatusOptions: string[] = [];
  isLoadingSecondaryData = true;
  tableDataAutomation = 'port-in-order-datatable';

  private _portInOrderInventories: OrderNumberInventory[] = [];
  private _portInDraftInventories: OrderNumberInventory[] = [];
  private _orderRefs: BandwidthPortInOrderRef[] = [];

  constructor(
    private _breadcrumbsService: BreadcrumbsService,
    private _datatableFilterService: DatatableFilterService,
    private _portInOrdersResource: PortInOrdersResource,
    private _portInDraftsResource: PortInDraftsResource,
    private _router: Router,
    private _translateService: TranslateService,
    private _telephoneNumberFilter: TelephoneNumberFilter,
    private _bottomNavService: BottomNavService,
    private _numberInventoriesResource: NumberInventoriesResource
  ) {}

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.order_numbers.port_in_orders.title' }]);

    this._getOrders().subscribe((orders: BandwidthPortInOrderRef[]) => {
      this._orderRefs = orders;
      this.datatableRows = this._mapOrdersToTableRows(orders);
      this._initBottomNav();
      this.isLoading = false;
    });

    this._numberInventoriesResource.get().subscribe((data: OrderNumberInventoryResponse) => {
      this._portInOrderInventories = data.portInOrderInventories;
      this._portInDraftInventories = data.portInDraftInventories;
      if (this._orderRefs.length) {
        this.datatableComponent?.filterTableData();
      }
      this.isLoadingSecondaryData = false;
    });
  }

  matchesSecondaryDataFn = (searchValue: string, row: PortInOrderDatatableRow): boolean => {
    if ((!this._portInOrderInventories.length && !this._portInDraftInventories.length) || !searchValue) {
      return true;
    }

    const formattedSearchValue = this._numericizePhoneNumber(searchValue);
    const orderNumberInventory =
      this._portInOrderInventories.find((inventory: OrderNumberInventory) => inventory.id === row.id) ||
      this._portInDraftInventories.find((inventory: OrderNumberInventory) => inventory.id === row.id);
    let matchingSecondaryData = false;

    if (orderNumberInventory) {
      const orderNumberInventoryNumbers = orderNumberInventory.numbers.map((val) => {
        return this._numericizePhoneNumber(val);
      });

      matchingSecondaryData = orderNumberInventoryNumbers.some((val) => {
        return val.includes(formattedSearchValue.toLowerCase());
      });
    }

    return matchingSecondaryData;
  };

  isProcessingStatusMatchFn = (filter: string[], row: PortInOrderDatatableRow): boolean => {
    return this._datatableFilterService.filterMultiSelectValues(
      filter.map((f: string) => f.toLowerCase()),
      this._getTextFromHtml(row.processingStatus).toLowerCase()
    );
  };

  isDateMatchFn = (filter: Date[], row: PortInOrderDatatableRow): boolean => {
    if (!filter || !filter.length) {
      return true;
    }

    const rowDate = moment(row.focDate, 'MMMM D, YYYY');
    const startDate = moment(filter[0], 'MMMM D, YYYY');
    const endDate = moment(filter[1], 'MMMM D, YYYY');

    return (
      rowDate.toISOString() === startDate.toISOString() ||
      rowDate.toISOString() === endDate.toISOString() ||
      rowDate.isBetween(startDate, endDate)
    );
  };

  private _numericizePhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
      return '';
    }
    if (phoneNumber.startsWith('1')) {
      phoneNumber = phoneNumber.slice(1, phoneNumber.length);
    }
    return phoneNumber
      .replaceAll('+1', '')
      .replaceAll('+', '')
      .replaceAll('(', '')
      .replaceAll(')', '')
      .replaceAll('-', '')
      .replaceAll(' ', '')
      .trim();
  }

  private _getTextFromHtml(html: string) {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent.trim() || element.innerText.trim() || '';
  }

  private _getOrders(): Observable<BandwidthPortInOrderRef[]> {
    return forkJoin([
      this._portInDraftsResource.get().pipe(map((drafts) => this._filterAndMapSubmittedDrafts(drafts))),
      this._portInOrdersResource.get(),
    ]).pipe(map((orders) => flatMap(orders)));
  }

  private _filterAndMapSubmittedDrafts(drafts: PortInOrderDraftRef[]): BandwidthPortInOrderRef[] {
    return drafts.map((draft) => {
      return {
        id: draft.id,
        url: null,
        billingNumber: draft.billingNumber,
        processingStatus: 'DRAFT',
        customerOrderId: draft.customerOrderId,
        lastModifiedDate: null,
        actualFocDate: null,
        requestedFocDate: draft.desiredFocDate,
      };
    });
  }

  private _mapOrdersToTableRows(orders: BandwidthPortInOrderRef[]): PortInOrderDatatableRow[] {
    const notFoundLabel = this._translateService.instant(
      'tkey;admin.order_numbers.port_in_orders.datatable.customer_order_id.not_found.label'
    );
    const noDateLabel = this._translateService.instant(
      'tkey;admin.order_numbers.port_in_orders.datatable.last_modified.not_applicable'
    );
    this.processingStatusOptions = uniqWith(
      orders.map((ref: BandwidthPortInOrderRef) => getStringFromProcessingStatus(ref.processingStatus)),
      (a: string, b: string) => a.toLowerCase() === b.toLowerCase()
    ).sort((a, b) => a.localeCompare(b));

    return orders.map((ref: BandwidthPortInOrderRef) => {
      let location = window.location.href.split('?')[0];
      if (!isNaN(Number(ref.id))) {
        location = location.slice(0, -6) + 'drafts';
      }
      const lastModifiedDateFormatted = ref.lastModifiedDate
        ? moment(ref.lastModifiedDate).format('MMMM D, YYYY')
        : `<i>${noDateLabel}</i>`;
      const focDateFormatted = ref.actualFocDate
        ? moment(ref.actualFocDate).format('MMMM D, YYYY')
        : `${moment(ref.requestedFocDate).format('MMMM D, YYYY')} <strong>(Requested)</strong>`;

      return {
        customerOrderId: ref.customerOrderId ? `<strong>${ref.customerOrderId}</strong>` : `<i>${notFoundLabel}</i>`,
        id: ref.id,
        processingStatus: getProcessingStatusHtml(ref.processingStatus),
        lastModified: lastModifiedDateFormatted,
        focDate: focDateFormatted,
        billingNumber: this._telephoneNumberFilter.transform(ref.billingNumber),
        editButtonHref: `${location}/${ref.id}`,
      };
    });
  }

  private _initBottomNav() {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'create-order-button',
          dataAutomation: 'create-order-button',
          label: 'tkey;admin.order_numbers.port_in_drafts.new_draft_button.text',
          buttonClass: ButtonStyles.PRIMARY,
          state: {
            pending: false,
          },
          icon: SmacsIcons.ADD,
          cb: () => this._router.navigate(['/admin/order-numbers/port-in-drafts/check-number-portability']),
        },
      ])
    );
  }
}
