<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div [hidden]="isLoading">
  <div
    class="alert alert-info"
    [innerHTML]="'tkey;microsoft_360.view.teams.delegate_management.about' | translate"
  ></div>
  <div class="d-flex justify-content-start">
    <div class="w-100">
      <smacs-select [fieldId]="'searchTerm'"></smacs-select>
    </div>
  </div>
  <div class="row m-3">
    <div *ngFor="let item of selectedDelegates" class="col-md-6">
      <ziro-calling-delegate-card-form
        [photoBase64]="item.userPhoto.photoBase64"
        [displayName]="item.userPhoto.displayName"
        [entity]="{
          id: item.userPhoto.userPrincipalName,
          makeCallsEnabled: item.assignedCallingDelegate.makeCallsEnabled,
          manageSettingsEnabled: item.assignedCallingDelegate.manageSettingsEnabled,
          receiveCallsEnabled: item.assignedCallingDelegate.receiveCallsEnabled
        }"
        [currentUser]="userDisplayName"
        (delegateRemoved)="delegateRemoveClicked($event)"
      ></ziro-calling-delegate-card-form>
    </div>
  </div>
</div>
