<div [ngStyle]="cssClass" class="loading-spinner" data-automation="smacs-loading-block" [hidden]="hidden">
  <div>
    <svg class="ziro_rotate" viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M190.262 740.663C195.813 744.108 201.364 747.362 207.01 750.425C265.773 782.869 332.576 800 400.048 800C454.026 800 506.376 789.377 555.76 768.513C603.421 748.319 646.202 719.512 682.857 682.857C719.608 646.106 748.415 603.326 768.513 555.76C789.377 506.376 800 454.026 800 400.048C800 324.058 778.562 250.078 738.079 186.147C734.633 180.787 731.092 175.428 727.36 170.164L621.031 281.278C640.555 317.55 650.796 358.32 650.796 399.952C650.796 466.946 624.668 529.92 577.39 577.294C530.016 624.668 467.041 650.7 400.048 650.7C364.254 650.7 328.843 643.043 296.495 628.401L190.262 740.663Z"
        fill="#2DCCD3"
      />
      <path
        d="M504.558 171.982C471.827 156.861 436.703 149.204 400.048 149.204C333.054 149.204 270.08 175.332 222.706 222.61C175.332 269.984 149.3 332.958 149.3 399.952C149.3 441.01 159.254 481.11 178.299 517.095L72.5446 629.645C68.2378 623.615 64.1225 617.299 60.1986 611.078C20.8637 547.817 0 474.794 0 399.952C0 345.974 10.6233 293.624 31.487 244.24C51.6808 196.578 80.4881 153.798 117.143 117.143C153.894 80.3924 196.674 51.5851 244.24 31.487C293.624 10.6233 345.974 0 399.952 0C465.032 0 529.633 15.9828 586.864 46.2256C595.095 50.5324 603.134 55.2219 611.078 60.1029L504.558 171.982Z"
        fill="black"
      />
    </svg>
  </div>
</div>
