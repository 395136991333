import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'counter',
})
export class CounterPipe implements PipeTransform {
  transform(numberOfElements: number, args?: any): number[] {
    return [...Array(numberOfElements).keys()];
  }
}
