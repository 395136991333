<div class="note" [attr.data-automation]="'note-' + index">
  <div class="d-flex">
    <h6 class="strong" data-automation="order-note-author">{{ note.userId }}</h6>
    <span class="ms-2" data-automation="order-note-date">{{ note.lastModifiedDate | date : 'MMMM d, h:mm a' }}</span>
  </div>
  <div class="d-flex mb-3">
    <div class="note__description-icon">
      <i class="icon-user-circle icon-2x align-self-center" [style]="'color:' + iconColor"></i>
    </div>
    <p class="note__description-text" data-automation="order-note-description">{{ note.description }}</p>
  </div>
  <hr *ngIf="!isLastNote" />
</div>
