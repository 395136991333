<li
  class="nav-item smacs-dnd-nav-item animated"
  [attr.data-automation]="'phone-button-' + buttonIdx"
  [ngClass]="{
    'smacs-dnd-nav-item--selected': buttonState.selected,
    'phone-buttons-disabled': buttonState.disabled,
    'phone-buttons-drag-target-candidate': buttonState.candidate,
    'phone-buttons-drop-target': buttonState.dropTarget,
    'phone-buttons-unmovable': buttonState.unmovable,
    'draggable-card': !buttonState.unmovable && !buttonState.deleteToggled,
    'vibrate infinite': buttonState.deleteToggled,
    'deletable-card': !buttonState.unmovable && buttonState.deleteToggled,
    'deletable-card--danger':
      !buttonState.unmovable && buttonState.deleteToggled && userMode === DragDropMode.SELF_SERVE,
    'deletable-card--info': !buttonState.unmovable && buttonState.deleteToggled && userMode === DragDropMode.HELPDESK,
    'smacs-forms-error': buttonState.invalid,
    flipInX: buttonState.justSwapped
  }"
  [ngStyle]="{ opacity: isPending ? 0.3 : 1 }"
>
  <a
    class="btn nav-link phone-buttons-tab"
    [attr.data-button-idx]="buttonIdx"
    [ngClass]="{ 'phone-buttons-tab--inverse': inverse }"
  >
    <div class="nav-pill" [ngbTooltip]="tooltipMessage | translate" [disableTooltip]="!tooltipMessage">
      <div
        *ngIf="!buttonState.deleteToggled"
        [ngClass]="{ 'nav-pill-index-sd': !isButtonLabelLong(button), 'nav-pill-index-blf': isButtonLabelLong(button) }"
        class="text-large"
        [ngbTooltip]="button.type"
        data-automation="button-tab-button-type"
      >
        {{ getButtonTypeLabel(button) }}
      </div>

      <div class="nav-pill-info" [ngClass]="{ 'd-flex deletable-card-section': buttonState.deleteToggled }">
        <div class="overflow-hidden">
          <div
            class="nav-pill-type text-truncate"
            data-automation="button-tab-button-label"
            [ngClass]="{
              small: button && (getButtonLabel(button) || getButtonValue(button) || getButtonDestination(button))
            }"
            *ngIf="!!displayButtonLabel(button)"
          >
            {{ displayButtonLabel(button) }}
          </div>

          <div class="nav-pill-value" data-automation="button-tab-button-value">
            <strong>
              {{ getNonLineButtonValue(button) }}
              <span *ngIf="getButtonExtension(button)" class="me-1">{{ getButtonExtension(button) }}</span>
              <span *ngIf="translationPattern(button)">| {{ translationPattern(button) }}</span>
            </strong>
            <i
              *ngIf="isPrimaryExtension(button)"
              [ngClass]="smacsIcons.PRIMARY_EXT"
              class="ms-1 custom-material-icon"
            ></i>
            <i *ngIf="showUccxIcon" [ngClass]="smacsIcons.UCCX_AGENT" class="ms-1 custom-material-icon"></i>
            <i *ngIf="showVoicemailIcon || isVoicemailExists(button)" [ngClass]="smacsIcons.VOICEMAIL" class="ms-1"></i>
          </div>
        </div>
      </div>
      <div
        *ngIf="buttonState.deleteToggled"
        [ngClass]="{ 'me-2': inverse, 'ms-2': !inverse }"
        class="d-flex deletable-card-section"
        data-automation="phone-buttons-delete-section"
      >
        <button
          type="button"
          class="btn btn-default phone-buttons-delete-confirmation-buttons"
          (click)="closeDeleteConfirmation()"
          data-automation="phone-buttons-delete-cancel"
        >
          {{ 'tkey;shared.device.phone.phone_buttons.delete.cancel' | translate }}
        </button>
        <button
          type="button"
          class="btn phone-buttons-delete-confirmation-buttons ms-2"
          [ngClass]="{
            'btn-danger': userMode === DragDropMode.SELF_SERVE,
            'btn-info': userMode === DragDropMode.HELPDESK
          }"
          data-automation="phone-buttons-delete-confirm"
          (click)="deleteButton()"
        >
          {{
            (userMode === DragDropMode.SELF_SERVE
              ? 'tkey;shared.device.phone.phone_buttons.delete.confirm'
              : 'tkey;shared.device.phone.phone_buttons.delete.clear'
            ) | translate
          }}
        </button>
      </div>
      <div
        *ngIf="!buttonState.deleteToggled && !buttonState.selected && !isEmpty(button) && !isSelfServeLine(button)"
        class="btn btn-sm btn-outline-info phone-buttons-delete-toggle-button rounded-circle"
        [ngClass]="{
          'phone-buttons-delete-toggle-button--danger': userMode === DragDropMode.SELF_SERVE,
          'phone-buttons-delete-toggle-button--info': userMode === DragDropMode.HELPDESK,
          'phone-buttons-delete-toggle-button--disabled': userMode === DragDropMode.HELPDESK && !!clearTooltipMessage
        }"
        data-automation="phone-buttons-delete-toggle"
        (click)="openDeleteConfirmation()"
        [ngbTooltip]="clearTooltipMessage | translate"
        [disableTooltip]="!clearTooltipMessage"
      >
        <i
          [ngClass]="{
            'icon-delete': userMode === DragDropMode.SELF_SERVE,
            'icon-clear': userMode === DragDropMode.HELPDESK
          }"
        ></i>
      </div>
      <div
        *ngIf="buttonState.invalid && !buttonState.deleteToggled"
        class="animated tada infinite ms-2 me-1"
        data-automation="phone-buttons-validation-error-icon"
      >
        <i class="icon-exclamation fa-lg text-danger"></i>
      </div>
    </div>
  </a>
</li>
