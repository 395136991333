<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 pb-2 smacs-datatable-content">
    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <div [hidden]="isLoading">
      <app-breadcrumbs>
        <app-breadcrumb-card-item>
          <div
            [innerHTML]="
              isDraftOrder
                ? ('tkey;admin.order_numbers.port_in_drafts_edit.about' | translate)
                : ('tkey;admin.order_numbers.view_orders.about' | translate)
            "
          ></div>
        </app-breadcrumb-card-item>
      </app-breadcrumbs>

      <div *ngIf="!isLoading">
        <!--   PHONE NUMBERS ERROR ALERT     -->
        <div
          [attr.data-automation]="'error-banner'"
          class="alert alert-danger"
          role="alert"
          *ngIf="!isDraftOrder && !!errors"
        >
          <i [attr.class]="smacsIcons.WARNING"></i>
          <strong [attr.data-automation]="'error-code'">
            {{ ('tkey;admin.order_numbers.port_in_drafts_edit.errors.label' | translate) + ' ' + errors.code }}
          </strong>
          -
          <span [attr.data-automation]="'error-description'">{{ errors.description }}</span>
          <div [attr.data-automation]="'error-number-list'" *ngIf="errors.telephoneNumberList.length">
            {{ 'tkey;admin.order_numbers.port_in_drafts_edit.errors.numbers.label' | translate }}:
            <!-- prettier-ignore-->
            <span *ngFor="let number of errors.telephoneNumberList; let last = last">
              {{ number | telephoneNumberFilter }}<span *ngIf="!last">, </span>
            </span>
          </div>
        </div>

        <!--   PHONE NUMBERS CARD     -->
        <smacs-collapsible-card
          [label]="'tkey;admin.order_numbers.order_view_and_edit.phone_numbers.label'"
          [icon]="smacsIcons.PHONE_ALT"
          [shouldBeOpen]="true"
          [dataAutomation]="'phone-numbers'"
          [switchId]="'phone-numbers'"
          [showChevron]="true"
          [cssClass]="'bg-info text-white fs-5 p-3'"
        >
          <smacs-datatable
            [columns]="phoneNumbersTableCols"
            [data]="phoneNumbersTableRows"
            [mainFilterPlaceholder]="
              'tkey;admin.order_numbers.order_view_and_edit.phone_numbers.datatable.search.placeholder' | translate
            "
            [setColumnWidths]="false"
            [containerCard]="true"
            [isStriped]="false"
            [tableSize]="'table-md'"
            [tableFontClass]="'table-font-sans-serif'"
            [isFirstColumnSticky]="true"
            [dataAutomation]="tableDataAutomation"
            [matchesSecondaryDataFn]="matchesSecondaryDataFn"
          >
            <smacs-datatable-multi-select-filter
              [label]="'tkey;admin.order_numbers.order_view_and_edit.phone_numbers.table.user.filter.label'"
              [items]="userFilterOptions"
              [matchFn]="onUpnFilterChange"
              [labelCssClass]="'text-capitalize'"
              [dataAutomation]="'user-filter'"
              [tableId]="tableDataAutomation"
            ></smacs-datatable-multi-select-filter>
            <smacs-datatable-multi-select-filter
              [label]="'tkey;admin.order_numbers.order_view_and_edit.phone_numbers.table.dial_plan.label'"
              [items]="dialPlanGroupFilterOptions"
              [matchFn]="onDialPlanFilterChange"
              [labelCssClass]="'text-capitalize'"
              [dataAutomation]="'dial-plan-filter'"
              [tableId]="tableDataAutomation"
            ></smacs-datatable-multi-select-filter>
            <smacs-datatable-multi-select-filter
              [label]="'tkey;admin.order_numbers.order_view_and_edit.phone_numbers.table.sbc_config.label'"
              [items]="sbcConfiguredFilterOptions"
              [matchFn]="onSbcConfiguredFilterChange"
              [labelCssClass]="'text-capitalize'"
              [dataAutomation]="'sbc-configured-filter'"
              [tableId]="tableDataAutomation"
            ></smacs-datatable-multi-select-filter>
          </smacs-datatable>
        </smacs-collapsible-card>

        <!--   DOCUMENTS CARD     -->
        <smacs-collapsible-card
          [label]="'tkey;admin.order_numbers.port_in_drafts_edit.documents.label'"
          [icon]="smacsIcons.DOCUMENTS"
          [shouldBeOpen]="true"
          [dataAutomation]="'documents'"
          [switchId]="'documents'"
          [showChevron]="true"
          [cssClass]="'bg-info text-white fs-5 p-3'"
        >
          <div data-automation="documents-table-view">
            <app-entity-table
              [table]="documentsTable"
              [tableRows]="documentsTableRows"
              [theadRowCssClass]="''"
              (fieldChange)="onFieldChange($event)"
            ></app-entity-table>
          </div>
          <div class="text-end mt-2 mb-4" *ngIf="isDraftOrder">
            <smacs-button
              *ngIf="!loaIsPresent"
              [icon]="smacsIcons.PDF"
              [buttonStyle]="buttonStyles.DEFAULT"
              class="me-1"
              [dataAutomation]="'generate-loa-button'"
              [label]="'tkey;admin.order_numbers.order_view_and_edit.documents.generate_loa.button.label'"
              [isDisabled]="!!generateLoaButtonDisabledTooltip"
              [tooltip]="generateLoaButtonDisabledTooltip"
              [isTooltipDisabled]="!generateLoaButtonDisabledTooltip"
              (clicked)="onGenerateLoaClick()"
            ></smacs-button>
            <smacs-button
              [icon]="smacsIcons.ADD"
              [dataAutomation]="'new-draft-document-button'"
              [label]="'tkey;admin.order_numbers.port_in_drafts_edit.documents.add_button.label'"
              (clicked)="onNewDocumentClick()"
            ></smacs-button>
          </div>
        </smacs-collapsible-card>
      </div>
      <div class="row">
        <div class="mb-4 col-md-6">
          <!--   ORDER/DRAFT INFORMATION CARD     -->
          <div class="card" data-automation="draft-info">
            <div class="card-header d-flex justify-content-between align-items-center bg-info text-white fs-5 p-3">
              <span class="p-0 m-0">
                <i [attr.class]="smacsIcons.DRAFT"></i>
                {{
                  isDraftOrder
                    ? ('tkey;admin.order_numbers.port_in_drafts_edit.draft_information.label' | translate)
                    : ('tkey;admin.order_numbers.view_orders.title' | translate)
                }}
              </span>
            </div>
            <div class="card-body pb-0">
              <smacs-text [fieldId]="'customerOrderId'"></smacs-text>
              <div *ngIf="!isDraftOrder">
                <smacs-text [fieldId]="'processingStatus'"></smacs-text>
                <smacs-text [fieldId]="'actualFocDate'"></smacs-text>
                <smacs-text [fieldId]="'orderCreatedDate'"></smacs-text>
                <smacs-text [fieldId]="'lastModifiedDate'"></smacs-text>
                <smacs-text [fieldId]="'lastModifiedBy'"></smacs-text>
                <smacs-text [fieldId]="'requestedFocDate'"></smacs-text>
              </div>
              <div *ngIf="isDraftOrder">
                <ziro-date [fieldId]="'desiredFocDate'"></ziro-date>
              </div>
              <smacs-text [fieldId]="'losingCarrierName'"></smacs-text>
              <smacs-text [fieldId]="'rateCenterName'"></smacs-text>
              <smacs-checkbox [fieldId]="'partialPort'"></smacs-checkbox>
              <smacs-text [fieldId]="'newBillingTelephoneNumber'"></smacs-text>
              <div *ngIf="!isDraftOrder">
                <smacs-checkbox [fieldId]="'losingCarrierIsWireless'"></smacs-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <!--   ACCOUNT INFORMATION CARD     -->
          <div class="card mb-4" data-automation="account-info">
            <div class="card-header d-flex justify-content-between align-items-center bg-info text-white fs-5 p-3">
              <span class="p-0 m-0">
                <i [attr.class]="smacsIcons.ADDRESS_CARD" style="height: 20px; width: 20px; margin-right: 2px"></i>
                {{ 'tkey;admin.order_numbers.port_in_drafts_edit.account_information.label' | translate }}
              </span>
            </div>
            <div class="card-body pb-0">
              <smacs-text [fieldId]="'loaAuthorizingPerson'"></smacs-text>
              <smacs-text [fieldId]="'billingTelephoneNumber'"></smacs-text>
              <smacs-text [fieldId]="'accountNumber'"></smacs-text>
              <smacs-text [fieldId]="'pinNumber'"></smacs-text>
              <smacs-text [fieldId]="'businessName'"></smacs-text>
            </div>
          </div>

          <!--   ADDRESS CARD    -->
          <div class="card mb-4" data-automation="address-info">
            <div class="card-header d-flex justify-content-between align-items-center bg-info text-white fs-5 p-3">
              <span class="p-0 m-0">
                <i [attr.class]="smacsIcons.BUILDING" style="height: 20px; width: 20px; margin-right: 1px"></i>
                {{ 'tkey;admin.order_numbers.port_in_drafts_edit.address.label' | translate }}
              </span>
            </div>
            <div class="card-body pb-0">
              <smacs-text [fieldId]="'houseNumber'"></smacs-text>
              <smacs-text [fieldId]="'streetName'"></smacs-text>
              <smacs-text [fieldId]="'addressLine2'"></smacs-text>
              <smacs-text [fieldId]="'city'"></smacs-text>
              <div *ngIf="isDraftOrder">
                <smacs-select [fieldId]="'stateCode'"></smacs-select>
              </div>
              <div *ngIf="!isDraftOrder">
                <smacs-text [fieldId]="'stateCode'"></smacs-text>
              </div>
              <smacs-text [fieldId]="'country'"></smacs-text>
              <smacs-text [fieldId]="'zip'"></smacs-text>
            </div>
          </div>
        </div>
      </div>
      <!--   NOTES CARD-->
      <div [attr.data-automation]="'notes-card'" class="card mb-4" *ngIf="!isDraftOrder">
        <div class="card-header d-flex justify-content-between align-items-center bg-info text-white fs-5 p-3">
          <span class="p-0 m-0">
            <i [attr.class]="smacsIcons.NOTES"></i>
            {{ 'tkey;admin.order_numbers.port_in_orders.datatable.note.header' | translate }}
          </span>
        </div>
        <div class="card-body pb-0">
          <ziro-order-note
            *ngFor="let note of notes; let i = index"
            [note]="note"
            [isLastNote]="i === notes.length - 1 && notes.length > 1"
            [index]="i"
          ></ziro-order-note>
        </div>
      </div>
    </div>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
