import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { of, Subject, Subscription } from 'rxjs';
import {
  CallingType,
  DnDidRange,
  MicrosoftDialPlanExceptionGroup,
  PstnConnectivityType,
  RangeType,
} from '../../../../../shared/models/generated/smacsModels';
import {
  ExtensionRangesOptionalValidators,
  SmacsRangeGroup,
} from '../../../../../forms/fields/extension-ranges/smacs-extension-range-models';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../../forms/smacs-forms-models';
import { TranslateService } from '@ngx-translate/core';
import { RangeService } from '../../../../../shared/services/range.service';
import { SmacsExtensionRangesComponent } from '../../../../../forms/fields/extension-ranges/smacs-extension-ranges.component';
import { HtmlInputType, SmacsTextConfig } from '../../../../../forms/fields/text/smacs-text.component';
import {
  SmacsTextareaComponent,
  SmacsTextareaConfig,
} from '../../../../../forms/fields/textarea/smacs-textarea.component';
import { cloneDeep, isEqual } from 'lodash';
import {
  ExtensionRangesDisplayFormComponent,
  RangeDisplayFormModel,
} from '../../../../../forms/fields/extension-ranges/extension-ranges-display-form/extension-ranges-display-form.component';
import { e164BulkNoPlusRegex, e164BulkWithPlusRegex } from '../../../../../shared/validators-regex';
import { SmacsRadioConfig } from '../../../../../forms/fields/radio/smacs-radio.component';
import { ValidatorsService } from '../../../../../shared/validators.service';

export interface MicrosoftExceptionGroupFormData extends MicrosoftDialPlanExceptionGroup {
  bulkRanges: string;
}

@Component({
  selector: 'app-microsoft-dial-plan-management-exception-edit-form',
  templateUrl: './dial-plan-exception-management-edit-form.component.html',
})
export class MicrosoftDialPlanExceptionManagementEditFormComponent
  extends SmacsFormAbstractDirective<MicrosoftDialPlanExceptionGroup, MicrosoftExceptionGroupFormData>
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() groupId: number;
  @Input() exceptionGroups = [] as MicrosoftDialPlanExceptionGroup[];
  @Input() isNewGroup: boolean;
  @Output() isValidFromDynamicValidator = new Subject<SmacsFormsValidationState>();

  @ViewChild(SmacsExtensionRangesComponent) extensionRanges: SmacsExtensionRangesComponent;
  @ViewChild(ExtensionRangesDisplayFormComponent) extensionRangesDisplayForm: ExtensionRangesDisplayFormComponent;
  @ViewChild(SmacsTextareaComponent) bulkRanges: SmacsTextareaComponent;

  formConfig: SmacsFormConfig;
  displayRangeFormEntity: RangeDisplayFormModel;
  displayRangeForm = false;
  extensionRangesDisplayFormValidationState: SmacsFormsValidationState;
  displayRangeFormOptionalValidators: ExtensionRangesOptionalValidators;
  validationStates = SmacsFormsValidationState;

  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _translateService: TranslateService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.formConfig = {
      fields: {
        name: {
          label: 'tkey;admin.ms_dial_plan_exception.group_name.label',
          dataAutomation: 'exception-group-name',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: true,
          validation: [
            {
              validator: (val: string) => {
                const filteredGroups = this.groupId
                  ? this.exceptionGroups.filter(
                      (group: MicrosoftDialPlanExceptionGroup) => Number(group.id) !== this.groupId
                    )
                  : this.exceptionGroups;
                const hasMatch = filteredGroups.find(
                  (group: MicrosoftDialPlanExceptionGroup) => group.name.toLowerCase() === val.trim().toLowerCase()
                );
                return hasMatch ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
              },
              message: 'tkey;dialplanmanagement.admin.group.name.alreadyInUse',
            },
          ],
        },
        rangeType: {
          label: 'tkey;admin.ms_dial_plan_exception.edit.range_type.label',
          dataAutomation: 'exception-group-range-type',
          required: true,
          componentConfig: new SmacsRadioConfig({
            buttons: [
              {
                value: CallingType.DID,
                label: 'tkey;admin.ms_dial_plan_exception.edit.range_type.did.label',
              },
              {
                value: CallingType.EXTENSION,
                label: 'tkey;admin.ms_dial_plan_exception.edit.range_type.extension.label',
              },
            ],
            inline: true,
          }),
        },
        bulkRanges: {
          label: 'tkey;admin.ms_dial_plan_exception.edit.bulk_generate.label',
          helpText: () => 'tkey;admin.ms_dial_plan_exception.edit.bulk_generate.helptext',
          dataAutomation: 'exception-group-bulk-ranges',
          componentConfig: new SmacsTextareaConfig({
            placeholder: this._translateService.instant(
              'tkey;admin.ms_dial_plan_exception.edit.bulk_generate.placeholder'
            ),
          }),
          validation: [
            {
              validator: (val: string, rangeType: RangeType) => {
                if (!val) {
                  return SmacsFormsValidationState.VALID;
                }
                const bulkFieldRegex = rangeType === RangeType.DID ? e164BulkWithPlusRegex : e164BulkNoPlusRegex;
                const cleanVal = ValidatorsService.removeFormattingFromBulkRanges(val, rangeType);

                return bulkFieldRegex.test(cleanVal)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: () =>
                this.formData.rangeType === RangeType.DID
                  ? 'tkey;validators.global.e164.invalid.error'
                  : 'tkey;validators.global.extension.invalid.error',
              injectValuesFromFields: ['rangeType'],
            },
          ],
        },
      },
    };

    this.displayRangeFormOptionalValidators = {
      overlappingRanges: true,
      strictE164Validation: this.entity.rangeType === RangeType.DID,
      isExtensionRangeType: this.entity.rangeType === RangeType.EXTENSION,
      hasSameLengthValidation: true,
      overlappingRangesInOtherGroups: {
        groups: this.isNewGroup
          ? this._mapMsGroupsToGeneric(this.exceptionGroups)
          : this._mapMsGroupsToGeneric(
              this.exceptionGroups.filter((group: MicrosoftDialPlanExceptionGroup) => group.id !== this.entity.id)
            ),
      },
    };
    this.displayRangeFormEntity = { ranges: cloneDeep(this.entity.exceptionRanges) };
    this.displayRangeForm = true;

    const formUpdateSub = this.smacsFormsUpdate$.subscribe(
      (update: SmacsFormsUpdate<MicrosoftDialPlanExceptionGroup>) => {
        this.displayRangeFormOptionalValidators = {
          ...this.displayRangeFormOptionalValidators,
          strictE164Validation: update.new.rangeType === RangeType.DID,
          isExtensionRangeType: update.new.rangeType === RangeType.EXTENSION,
        };
        this._changeDetectorRef.detectChanges();
      }
    );
    this._subscriptions.add(formUpdateSub);
  }

  generateBulkRangesClicked() {
    if (!this.formData['bulkRanges'].trim()) {
      return;
    }

    this.displayRangeForm = false;

    // Remove new lines, spaces
    const cleanVal = ValidatorsService.removeFormattingFromBulkRanges(
      this.formData.bulkRanges,
      this.formData.rangeType
    );
    const generatedRanges = RangeService.generateRangesFromArray(
      cleanVal.replace(/\n|\r/g, '').replace(/ /g, '').split(',')
    );
    const currentRanges = cloneDeep(this.entity.exceptionRanges).filter((r: DnDidRange) => r.start && r.end);
    const updatedRanges = [...currentRanges, ...generatedRanges];
    const newEntity = {
      ...this.entity,
      dialPlanRangesJson: updatedRanges,
    };

    this.formData['bulkRanges'] = '';
    this.entitySource.next(cloneDeep(newEntity));
    this.displayRangeFormEntity = { ranges: newEntity.dialPlanRangesJson };

    setTimeout(() => {
      this.displayRangeForm = true;
    });
  }

  submit() {
    this.setDisplayFormValidationState();
    return of(null);
  }

  toFormData = (entity: MicrosoftDialPlanExceptionGroup): MicrosoftExceptionGroupFormData => {
    return {
      id: entity.id,
      name: entity.name,
      rangeType: entity.rangeType,
      exceptionRanges: entity.exceptionRanges,
      bulkRanges: this.formData['bulkRanges'] || '',
    };
  };

  toEntity = (formData: MicrosoftExceptionGroupFormData): MicrosoftDialPlanExceptionGroup => {
    return {
      id: formData.id,
      name: formData.name,
      rangeType: formData.rangeType,
      exceptionRanges: formData.exceptionRanges,
    } as MicrosoftDialPlanExceptionGroup;
  };

  onRangeFormUpdate(data: SmacsFormsUpdate<RangeDisplayFormModel>) {
    // Always update validation state, don't always update entity. Async val will fire this multiple times so entity
    // won't necessarily change onFormUpdate
    if (isEqual(this.entity.exceptionRanges, data.new.ranges)) {
      this.setDisplayFormValidationState();
    } else {
      const newEntity = {
        ...cloneDeep(this.entity),
        exceptionRanges: data.new.ranges,
      };

      this.entitySource.next(newEntity);

      this.setDisplayFormValidationState();
    }
  }

  setDisplayFormValidationState() {
    this.extensionRangesDisplayFormValidationState =
      this.isFormSubmitted || this.extensionRangesDisplayForm.extensionRanges.showValidation
        ? this.extensionRangesDisplayForm.validationState
        : this.validationStates.VALID;
  }

  private _mapMsGroupsToGeneric(exceptionGroups: MicrosoftDialPlanExceptionGroup[]): SmacsRangeGroup[] {
    return exceptionGroups.map((g: MicrosoftDialPlanExceptionGroup) => {
      return {
        id: Number(g.id),
        name: g.name,
        ranges: g.exceptionRanges,
        rangeType: CallingType.DID,
        pstnConnectivityType: PstnConnectivityType.DIRECT_ROUTING,
        mainNumber: '',
      };
    });
  }
}
