<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 smacs-report-content">
    <app-breadcrumbs *ngIf="isReadOnly !== null">
      <app-breadcrumb-card-item>
        <div
          [innerHTML]="
            (isReadOnly
              ? 'tkey;pages.admin.orphaned_devices.description.read_only'
              : 'tkey;pages.admin.orphaned_devices.description'
            ) | translate
          "
        ></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <div
      *ngIf="!isLoading && orphanedDevices.length === 0"
      data-automation="orphaned-devices-no-results"
      class="alert alert-info"
    >
      <span [innerHTML]="'tkey;pages.admin.orphaned_devices.subtitle' | translate : { total: 0 }"></span>
    </div>

    <ng-container *ngIf="!isLoading && orphanedDevices.length > 0">
      <smacs-datatable
        [columns]="columns"
        [data]="orphanedDevices"
        [showSelectColumn]="!isReadOnly"
        [mainFilterPlaceholder]="
          ('tkey;reporting.datatable.search_placeholder' | translate) +
          ('tkey;pages.admin.orphaned_devices.deviceName' | translate) +
          '...'
        "
        (rowSelected)="setDeleteButtonState()"
        [dataAutomation]="tableDataAutomation"
      >
        <smacs-datatable-multi-select-filter
          [label]="'tkey;pages.admin.orphaned_devices.deviceType'"
          [items]="deviceTypeFilterOptions"
          [matchFn]="isDeviceTypeMatch"
          [dataAutomation]="'orphaned-devices-device-type-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;pages.admin.orphaned_devices.clusterName'"
          [items]="cucmClusterFilterOptions"
          [matchFn]="isCucmClusterMatch"
          [dataAutomation]="'orphaned-devices-cucm-cluster-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
      </smacs-datatable>
    </ng-container>
  </div>
</div>

<app-bottom-nav *ngIf="!isReadOnly"></app-bottom-nav>
