import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { SmacsError } from '../models/generated/smacsModels';
import { ToastTypes } from './abstract/toast.service.abstract';
import { ToastService } from './toast.service';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { OopsModalComponent } from '../../modals/oops-modal/oops-modal.component';
import { Observable, of } from 'rxjs';
import { ZiroPromptModalViewProperties } from '../../modals/prompt-modal/prompt-modal.component';
import { SmacsModalService } from './smacs-modal.service';
import { ButtonStyles } from '../../button/button.component';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  smacsIcons = SmacsIcons;
  isUnloading = false;

  private static _isBlobError(error: Error): boolean {
    return error instanceof HttpErrorResponse && error.error instanceof Blob && error.error.type === 'application/json';
  }

  private static _parseErrorBlob(err: HttpErrorResponse): Observable<HttpErrorResponse> {
    return new Observable((subscriber: any) => {
      const blob = new Blob([err.error], { type: 'application/json' });
      blob.text().then((data: string) => {
        subscriber.error(
          new HttpErrorResponse({
            ...err,
            error: JSON.parse(data),
          })
        );
        subscriber.complete();
      });
    });
  }

  constructor(private injector: Injector, private translateService: TranslateService) {
    window.onbeforeunload = () => {
      this.isUnloading = true;
    };
  }

  handleError(error: any) {
    // log the error, this is helpful to have
    if (!!error) {
      console.error(error);
    }

    if (this.isUnloading) {
      // do not try to handle errors if we're about to unload the page!
      return;
    }
    // for whatever reason, promise rejections aren't handled well in angular.
    if (error?.rejection) {
      error = error.rejection;
    }

    if (error instanceof HttpErrorResponse) {
      if (error.error && error.error.description) {
        const statusCode = error.status;

        if (statusCode === 403) {
          window.location.href = '/';
        } else if (statusCode === 422) {
          this._showErrorToast(error.error.description);
        } else {
          const errorJson = error.error as SmacsError;
          this._showOopsDialog(errorJson.description, errorJson.stacktrace.join('\n'));
        }
      } else if (GlobalErrorHandler._isBlobError(error)) {
        // If response was returned as a blob parse response to get the error message
        GlobalErrorHandler._parseErrorBlob(error).subscribe({
          next: () => null,
          error: (parsedResponse) => {
            this._showErrorToast(parsedResponse.error.description);
          },
        });
      } else if (error.status && error.status === 422) {
        this._showErrorToast(error.message);
      } else if (error.error && error.error[0] && error.error[0].message) {
        const errorJson = error.error[0];
        this._showOopsDialog(errorJson.message, errorJson.path);
      } else {
        const translateService = this.injector.get(TranslateService);

        if (error.status === 0) {
          this._showLocalNetworkErrorModal();
          return;
        }

        let errorTitle = translateService.instant('tkey;shared.modals.error.unexpected_status.label');
        if (errorTitle.startsWith('tkey')) {
          // If the translation didn't work (because en.json failed to load from the server), default to English.
          errorTitle = 'Unexpected Status';
        }
        this._showOopsDialog(errorTitle, error.error || error.message);
      }
    } else if (error instanceof Error) {
      this.translateService.use(localStorage.getItem('smacs8_language_key') || 'en');
      this._showOopsDialog(error.message, error.stack);
    } else if (!!error) {
      this._showOopsDialog('Unhandled Error', error);
      console.warn('WARNING: UNHANDLED ERROR');
    }
  }

  private _showErrorToast(message: string) {
    const ngZone = this.injector.get(NgZone);
    const toastService = this.injector.get(ToastService);

    // Use ngZone to force change detection. This solves an issue where the toast doesn't appear immediately on an error
    ngZone.run(() => {
      toastService.push(
        ToastTypes.ERROR,
        this.smacsIcons.EXCLAMATION,
        'tkey;shared.toast.operation_failed.title',
        message
      );
    });
  }

  private _showOopsDialog(message: string, stacktrace: string) {
    const ngZone = this.injector.get(NgZone);
    const ngbModalService = this.injector.get(NgbModal);

    const options = {
      animation: false,
      size: 'md',
      backdrop: 'static',
      keyboard: false,
      windowClass: 'errorModal',
    } as NgbModalOptions;
    const translateService = this.injector.get(TranslateService);
    const modalViewProperties = {
      title: translateService.instant('tkey;pages.error.dialog.header'),
      errorSummary: message,
      stacktrace: stacktrace,
      stepsToReproduce: '',
    };
    const errorModals = document.getElementsByClassName('errorModal');

    // Use ngZone to force change detection. This solves an issue where the modal doesn't appear immediately on an error.
    ngZone.run(() => {
      if (errorModals.length === 0) {
        const modalInstance = ngbModalService.open(OopsModalComponent, options);
        modalInstance.componentInstance.modalViewProperties = modalViewProperties;
      }
    });
  }

  private _showLocalNetworkErrorModal() {
    const ngZone = this.injector.get(NgZone);
    const smacsModalService = this.injector.get(SmacsModalService);
    const translateService = this.injector.get(TranslateService);

    const buttonLabel =
      translateService?.instant('tkey;local_network_error_modal.refresh_button.label') || 'Refresh Page';
    const modalTitle = translateService?.instant('tkey;local_network_error_modal.title') || 'Network Error';
    const modalBody =
      translateService?.instant('tkey;local_network_error_modal.body') ||
      'Your browser is unable to connect to the network. Please refresh the page to try again.';

    const modalProperties: ZiroPromptModalViewProperties = {
      buttons: [
        {
          label: buttonLabel,
          buttonClass: ButtonStyles.PRIMARY,
          buttonIcon: SmacsIcons.REFRESH,
          cb: () => {
            window.location.reload();
            return of(null);
          },
        },
      ],
      displayCloseButton: false,
      icon: SmacsIcons.WIFI,
      promptBody: modalBody,
      title: modalTitle,
    };

    ngZone.run(() => {
      smacsModalService.openPromptModal(() => modalProperties, { modalViewProperties: modalProperties });
    });
  }
}
export const globalErrorProvider = {
  provide: ErrorHandler,
  useClass: GlobalErrorHandler,
};
