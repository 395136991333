import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { Component, EventEmitter, Output } from '@angular/core';
import { SmacsFieldComponentConfig, SmacsFormsValidationState } from '../../smacs-forms-models';
import { concat, Observable, of, Subject, Subscriber, timer } from 'rxjs';
import { debounce, first, switchMap } from 'rxjs/operators';
import { cloneDeep, intersectionWith, isEqual, isNil, uniq } from 'lodash';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import { ButtonStyles } from '../../../button/button.component';
import { NgModel } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgSelectComponent } from '@ng-select/ng-select';

export interface SmacsSelectOption {
  label: string;
  value: any;
  group?: string;
  disabled?: boolean;
  photoBase64?: string;
  displayIcon?: boolean;
  appendValue?: boolean;
  disabledTooltip?: string;
  tooltipPlacement?: string;
}

export type SmacsSelectAsyncOptionsFn = (string: string) => Observable<string[] | SmacsSelectOption[]>;

export type SmacsSelectPostLoadedOptionsFn = () => Observable<void>;

export class SmacsSelectConfig extends SmacsFieldComponentConfig {
  constructor(
    public config?: {
      options?: string[] | SmacsSelectOption[];
      isMultiSelect?: boolean;
      asyncOptionsFn?: SmacsSelectAsyncOptionsFn;
      bindValue?: string;
      placeholder?: string;
      triggerLoading?: () => boolean;
      triggerLoadingIcon?: () => boolean;
      clearWithInput?: boolean;
      hideDropdownArrow?: boolean;
      minSearchLength?: number;
      showAutoGenerationLink?: boolean;
      hideClear?: boolean;
      keepSearchInput?: boolean;
      postLoadedOptionsFn?: SmacsSelectPostLoadedOptionsFn;
    }
  ) {
    super();
  }
}

export type SmacsSelectValue = string | string[] | SmacsSelectOption | SmacsSelectOption[];

@Component({
  selector: 'smacs-select',
  templateUrl: './smacs-select.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsSelectComponent }],
})
export class SmacsSelectComponent extends SmacsFieldAbstractDirective<
  SmacsSelectValue,
  SmacsSelectValue,
  SmacsSelectConfig
> {
  @Output() searchHasChanged = new EventEmitter<string>();
  @Output() selectWasCleared = new EventEmitter<void>();
  @Output() selectBlur = new EventEmitter<void>();
  @Output() selectWasClosed = new EventEmitter<void>();
  @Output() selectWasClicked = new EventEmitter<void>();
  isMultiSelect: boolean;
  isLoading: boolean;
  initialValues: SmacsSelectValue[];
  options: string[] | SmacsSelectOption[];
  asyncOptionsFn: SmacsSelectAsyncOptionsFn;
  options$: Observable<string[] | SmacsSelectOption[]>;
  optionInputSource = new Subject<string>();
  bindValue: string;
  placeholder = '';
  clearWithInput = false;
  searchedTerm = '';
  hideDropdownArrow = false;
  hideClear = false;
  minSearchLength = 2;
  buttonStyles = ButtonStyles;
  isAsync = false;
  keepSearchInput = false;
  // smacs-selects by default shouldn't have 'fix it' links with a few exceptions (Associated End Users in phone fields)
  showAutoGenerationLink = false;
  selected: boolean;
  postLoadedOptionsFn: SmacsSelectPostLoadedOptionsFn;

  private static _arrayToString(values: string[]): string {
    return values ? values.join(', ') : '';
  }

  constructor(protected smacsFormStateService: SmacsFormStateService, private translateService: TranslateService) {
    super(smacsFormStateService);
  }

  triggerLoading = () => false;
  triggerLoadingIcon: () => boolean;

  applyComponentConfig = ({ config }: SmacsSelectConfig) => {
    this.options = config?.options ?? [];
    this.isMultiSelect = config?.isMultiSelect;
    this.placeholder = config?.placeholder;
    this.asyncOptionsFn = config?.asyncOptionsFn;
    this.minSearchLength = isNil(config?.minSearchLength) ? this.minSearchLength : config.minSearchLength;
    this.isAsync = !!config?.asyncOptionsFn && !!this.minSearchLength;
    this.isLoading = false;
    this.bindValue = config?.bindValue ? config.bindValue : null;
    this.options$ = !!config?.asyncOptionsFn ? this.getOptionsAsync() : this.getOptions(config?.options || []);
    this.triggerLoading = config?.triggerLoading || this.triggerLoading;
    this.triggerLoadingIcon = config?.triggerLoadingIcon || null;
    this.clearWithInput = config?.clearWithInput || this.clearWithInput;
    this.hideDropdownArrow = this.isAsync ? true : config?.hideDropdownArrow || this.hideDropdownArrow;
    this.showAutoGenerationLink = Boolean(config?.showAutoGenerationLink);
    this.hideClear = Boolean(config?.hideClear);
    this.keepSearchInput = Boolean(config?.keepSearchInput);
    this.postLoadedOptionsFn = config?.postLoadedOptionsFn;
  };

  shouldSearchedTermBeClearable = () => {
    return (
      (this.clearWithInput ? this.searchedTerm.length >= this.minSearchLength : false) &&
      !this.isSelectCurrentlyLoading()
    );
  };

  isSelectCurrentlyLoading = () => {
    return this.isLoading || this.triggerLoading();
  };

  isClearable = () => {
    const isRequired = typeof this.config.required === 'function' ? this.config.required() : this.config.required;
    return (
      this.shouldSearchedTermBeClearable() ||
      (!this.isSelectCurrentlyLoading() &&
        !isRequired &&
        !!this.entity &&
        (!this.isMultiSelect ||
          !(this.entity as SmacsSelectOption[]).every((val: SmacsSelectOption) => {
            if (!this.options) {
              return false;
            }

            const opt = (this.options as any[]).find((option) => {
              if (option.value === val) {
                return option;
              }
            });

            return opt?.disabled;
          }))) ||
      this.clearWithInput
    );
  };

  onClear = () => {
    if (this.isMultiSelect) {
      this.updateSelf(
        (this.entity as SmacsSelectOption[]).filter((val: SmacsSelectOption) => {
          if (!this.options || !this.options.length) {
            return false;
          }

          const opt = (this.options as any[]).find((option) => {
            if (option.value === val) {
              return option;
            }
          });

          return opt?.disabled;
        })
      );
    } else if (typeof this.entity === 'string') {
      this.updateSelf(null);
    } else {
      this.updateSelf(null);
    }
    this.selectWasCleared.emit();
  };

  getOptionsAsync = (): Observable<string[] | SmacsSelectOption[]> => {
    return concat(
      of(this.options ?? []),
      this.optionInputSource.pipe(
        debounce(() => timer(this.isAsync ? 500 : 0)),
        switchMap((searchTerm: string) => {
          return new Observable((subscriber: Subscriber<string[] | SmacsSelectOption[]>) => {
            if (this.isAsync && !searchTerm) {
              subscriber.next([]);
              subscriber.complete();
              this.isLoading = false;
            } else {
              if (this.isAsync) {
                this.isLoading = true;
                this.hideDropdownArrow = false;
              }
              this.asyncOptionsFn(searchTerm)
                .pipe(first())
                .subscribe((results: string[] | SmacsSelectOption[]) => {
                  subscriber.next(results);
                  subscriber.complete();
                  this.isLoading = false;
                });
            }
          });
        })
      )
    );
  };

  getOptions = (options: string[] | SmacsSelectOption[]): Observable<string[] | SmacsSelectOption[]> => {
    return new Observable<string[] | SmacsSelectOption[]>((subscriber) => {
      this.addInitialEntityToOptions(options, this.entity);
      let selectOptions: any[] = [];

      if (!!this.config && !!this.config.defaultValue) {
        const defaultValues = this.config.defaultValue();
        if (typeof this.config.defaultValue === 'function' && defaultValues !== '' && defaultValues !== null) {
          /**
           * If it is a multi-select
           */
          if (this.isMultiSelect) {
            if (typeof options !== 'string') {
              if (typeof defaultValues !== 'string') {
                const defaultOption = intersectionWith(
                  options as SmacsSelectOption[],
                  defaultValues as SmacsSelectOption[]
                );
                selectOptions.concat(defaultOption ? defaultOption : defaultValues);
              } else {
                const smacsSelectOpt = (options as SmacsSelectOption[]).find((opt) => opt.value === defaultValues);
                selectOptions.concat(smacsSelectOpt ? smacsSelectOpt : defaultValues);
              }
            } else {
              selectOptions = selectOptions.concat(defaultValues);
            }
          } else {
            /**
             * If it is a single-select
             */
            if (typeof options !== 'string') {
              if (typeof defaultValues !== 'string') {
                const defaultOption = (options as SmacsSelectOption[]).find((opt) =>
                  isEqual(opt.value, defaultValues.value)
                );
                selectOptions.push(defaultOption ? defaultOption : defaultValues);
              } else {
                const smacsSelectOpt = (options as SmacsSelectOption[]).find((opt) => opt.value === defaultValues);
                selectOptions.push(smacsSelectOpt ? smacsSelectOpt : defaultValues);
              }
            } else {
              selectOptions.push(defaultValues);
            }
          }
        }
      }
      const sendValues = uniq(selectOptions.concat(options));
      subscriber.next(sendValues);
      subscriber.complete();
    });
  };

  addInitialEntityToOptions<T extends SmacsSelectValue | string>(
    options: string[] | SmacsSelectValue[],
    entity: T | T[]
  ) {
    // we have to use a generic type because typescript doesn't like it when you push elements to a union-typed array.
    let addToInitialValues = false;
    if (!this.initialValues) {
      this.initialValues = [];
      addToInitialValues = true;
    }

    if (!entity) {
      return;
    }

    // Multi Select?
    if (Array.isArray(entity)) {
      (entity as T[]).forEach((value) => {
        if (
          (this.bindValue && !options.some((o) => isEqual(this.lookupNestedValue(o, this.bindValue), value))) ||
          (!this.bindValue && !options.some((o) => isEqual(o, value)))
        ) {
          (options as T[]).unshift(value);
          if (addToInitialValues) {
            this.initialValues.push(value);
          }
        }
      });
    } else {
      if (
        (this.bindValue && !options.some((o) => isEqual(this.lookupNestedValue(o, this.bindValue), entity))) ||
        (!this.bindValue && !options.some((o) => isEqual(o, entity)))
      ) {
        (options as T[]).unshift(entity as T);
        if (addToInitialValues) {
          this.initialValues.push(entity);
        }
      }
    }
  }

  lookupNestedValue(o: any, s: string) {
    // convert indexes to properties & strip a leading dot
    const keysArray = s
      .replace(/\[(\w+)\]/g, '.$1')
      .replace(/^\./, '')
      .split('.');
    for (let i = 0, n = keysArray.length; i < n; ++i) {
      const k = keysArray[i];
      if (o.hasOwnProperty(k)) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }

  hideDefaultValueAutogenerationFnFactory(
    defaultVal: SmacsSelectValue
  ): (val: SmacsSelectValue) => SmacsFormsValidationState {
    if (!this.isMultiSelect) {
      return (val: SmacsSelectValue) => {
        return (!this.initialValues || !this.initialValues.includes(val)) &&
          (!val ||
            this.options.length === 0 ||
            this.options.some((option: any) => {
              if (this.bindValue) {
                return isEqual(val, option[this.bindValue]);
              } else {
                return isEqual(val, option);
              }
            }))
          ? SmacsFormsValidationState.VALID
          : SmacsFormsValidationState.INVALID;
      };
    } else {
      return (val) =>
        (defaultVal as string[] | SmacsSelectValue[]).every((d) =>
          ((val as string[] | SmacsSelectValue[]) || []).some((v) => isEqual(v, d))
        )
          ? SmacsFormsValidationState.VALID
          : SmacsFormsValidationState.INVALID;
    }
  }

  misconfigurationWarningMessage = (val?: any) => {
    if (!!this.asyncOptionsFn || this.isMultiSelect) {
      if (this.config?.defaultValue) {
        const currentValues = cloneDeep(val);
        const defaultValues = this.config?.defaultValue();

        // Default values can be either a string or a SmacsSelectOption
        const missingDefaults = defaultValues
          .filter((defaultValue: any) => {
            return currentValues.length > 0
              ? typeof defaultValue === 'string'
                ? !currentValues.includes(defaultValue)
                : currentValues.some((currentValue: any) => currentValue.label !== defaultValue.label)
              : defaultValue;
          })
          .map((value: any) => (typeof value === 'string' ? value : value.label));

        const validationMessage =
          missingDefaults.length > 1
            ? 'tkey;custom.multi_select.missing_default_values.text'
            : 'tkey;custom.multi_select.missing_default_value.text';
        return `${this.translateService.instant(validationMessage)} <strong>${SmacsSelectComponent._arrayToString(
          missingDefaults
        )}</strong>. `;
      }

      return 'tkey;custom.multi_select.missing_default_values.text';
    } else {
      return 'tkey;smacs_select.misconfiguration_feedback';
    }
  };

  handleSearchInput($event: { term: string; items: any[] }) {
    this.searchedTerm = $event.term;
    if (this.isAsync) {
      this.isLoading = this.searchedTerm.length >= this.minSearchLength;
      if (!this.searchedTerm) {
        // need this to clear the options because when this.searchedTerm.length >= this.minSearchLength,
        // typeahead doesn't get triggered so the stale options remain.
        this.optionInputSource.next('');
      }
    }
    this.searchHasChanged.emit(this.searchedTerm);
  }

  handleBlur() {
    if (!!this.asyncOptionsFn && !this.searchedTerm.length) {
      this.optionInputSource.next('');
    }
    if (this.isAsync) {
      this.hideDropdownArrow = true;
    }
    this.selectBlur.emit();
  }

  onChange($event: SmacsSelectValue, ngModelDir: NgModel) {
    if (this.isAsync) {
      this.hideDropdownArrow = true;
    }
    this.updateStateAndSelf($event, ngModelDir);
  }

  toFieldData = (entity: SmacsSelectValue): SmacsSelectValue => {
    // We want the ng-select to have "null" as its empty value, since that's what it prefers.
    if (entity === '') {
      return null;
    } else {
      return cloneDeep(entity);
    }
  };

  toEntity = (fieldData: SmacsSelectValue): SmacsSelectValue => {
    // We want "null" to be transformed into an empty string for the forms, since our API prefers empty string over null.
    if (fieldData === null && !this.isMultiSelect) {
      return '';
    } else {
      return cloneDeep(fieldData);
    }
  };

  protected _onReceiveNewEntity(newEntity: SmacsSelectValue) {
    super._onReceiveNewEntity(newEntity);
    if (this.options) {
      this.addInitialEntityToOptions(this.options, newEntity);
    }
  }

  /**
   * Only add the required validator when not a multi-select. A multi-select has default value validation to fallback on
   */
  protected _addRequiredValidator() {
    if (!this.isMultiSelect || (this.isMultiSelect && !this.config.defaultValue)) {
      this.config.validation = [
        {
          validator: this.exists,
          message: 'tkey;validators.global.required.error',
        },
        ...(this.config.validation || []),
      ];
    }
  }

  handleSelectClose(select: NgSelectComponent, ngModelDir: NgModel) {
    if (this.keepSearchInput) {
      if (!this.selected && this.searchedTerm) {
        this._onReceiveNewEntity(this.searchedTerm);
        this.updateStateAndSelf(this.searchedTerm, ngModelDir);
      }
    }
    this.selected = false;
    this.selectWasClosed.emit();
  }

  handleChange() {
    this.selected = true;
  }

  handleClicked() {
    if (this.asyncOptionsFn && !this.minSearchLength) {
      this.optionInputSource.next('');
    }
    this.selectWasClicked.emit();
  }

  getDisabledState(): boolean {
    if (this.config.hasOwnProperty('disabled')) {
      if (typeof this.config.disabled === 'boolean') {
        return this.config.disabled;
      } else if (typeof this.config.disabled === 'function') {
        return this.config.disabled();
      }
    }

    return this.state.disabled;
  }

  onClick() {
    if (this.options.length || !this.postLoadedOptionsFn) {
      return;
    }

    this.triggerLoading = () => true;
    this.postLoadedOptionsFn().subscribe(() => {
      this.config.labelToolTipText = null;
      this.config.inputToolTipText = null;
      this.triggerLoading = () => false;
    });
  }
}
