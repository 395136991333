import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { PhoneResource } from '../../shared/resources/phone.resource';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  DeviceRegistrationStatus,
  DirectoryNumberResult,
  EndUserRef,
  Phone,
  PhoneResult,
  PhoneStatus,
  SiteSearchResult,
} from '../../shared/models/generated/smacsModels';
import { DefaultDeskphoneResource } from '../resources/default-deskphone.resource';
import { SearchPhoneResource } from '../../shared/resources/search-phone.resource';
import { DirectoryNumberResource } from '../resources/directory-number.resource';
import { SearchDirectoryNumberResource } from '../resources/search/search-directory-number.resource';
import { SmacsFormAbstractDirective } from '../../forms/smacs-form-abstract.directive';
import { SmacsFormsMessage, SmacsFormsValidationState } from '../../forms/smacs-forms-models';
import { ActivatedRoute, Router } from '@angular/router';
import { get } from 'lodash';
import { SelfServe360View, SelfServeUiContext } from '../contexts/self-serve-ui.context';
import { ToastService } from '../../shared/services/toast.service';
import { SmacsModalService } from '../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { HtmlInputType, InputSize, SmacsTextConfig } from '../../forms/fields/text/smacs-text.component';
import { animate, style, transition, trigger } from '@angular/animations';
import { ButtonSizes, ButtonStyles } from '../../button/button.component';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import phoneRegistration1 from 'ziro-static/json/phone-registration-1.json';
import phoneRegistration1Transition2 from 'ziro-static/json/phone-registration-1-transition-2.json';
import phoneRegistration2 from 'ziro-static/json/phone-registration-2.json';
import phoneRegistration2Transition3 from 'ziro-static/json/phone-registration-2-transition-3.json';
import phoneRegistration3 from 'ziro-static/json/phone-registration-3.json';
import phoneRegistration3Transition4 from 'ziro-static/json/phone-registration-3-transition-4.json';
import phoneRegistration4 from 'ziro-static/json/phone-registration-4.json';
import phoneRegistration4Transition5 from 'ziro-static/json/phone-registration-4-transition-5.json';
import { Nvp } from '../../shared/models/nvp';
import { DeviceRegistrationStatusPollingService } from '../../shared/services/cisco/device-registration-status-polling.service';
import { Global360ViewContext } from '../../shared/contexts/global-360-view.context';

enum SearchPhoneValidationMessage {
  NOT_FOUND = 'tkey;selfserve.user.activate_deskphone.extension.not_found',
  MULTIPLE_FOUND = 'tkey;selfserve.user.activate_deskphone.extension.multiple_found',
  OWNED_BY_SELF = 'tkey;selfserve.user.activate_deskphone.extension.owned_by_self',
  OWNED_BY_OTHER = 'tkey;selfserve.user.activate_deskphone.extension.owned_by_other',
  WRONG_CLUSTER = 'tkey;selfserve.user.activate_deskphone.extension.wrong_cluster',
  VALID = 'tkey;selfserve.user.activate_deskphone.extension.valid',
}

class ActivateDeskphoneFormData {
  extension = '';
}

@Component({
  selector: 'app-activate-deskphone',
  templateUrl: './activate-deskphone.component.html',
  styleUrls: ['./activate-deskphone.component.scss'],
  providers: [DeviceRegistrationStatusPollingService],
  animations: [
    trigger('stepAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('500ms 500ms', style({ opacity: 1 }))]),
      transition(':leave', [animate('500ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class ActivateDeskphoneComponent
  extends SmacsFormAbstractDirective<ActivateDeskphoneFormData>
  implements OnInit, AfterViewInit, OnDestroy
{
  SmacsFormsValidationState = SmacsFormsValidationState;
  isLoading = true;
  isActivationInProgress = false;
  canActivateDeskphone: boolean;
  username: string;
  currentUserRef: EndUserRef;
  currentStep = 1;
  animationCurrentStep = 1;
  smacsIcons = SmacsIcons;
  buttonSizes = ButtonSizes;
  buttonStyles = ButtonStyles;

  private _site: SiteSearchResult;
  private _userServerId: number;
  private _phoneResult: PhoneResult;
  private _validationMessage: string | SmacsFormsMessage;
  private _isDeleted = false;
  private _phoneNotFound = false;
  private _subscriptions = new Subscription();
  private _currentAnimationTransition: number = null;
  animationReplaySource = new Subject<number>();

  lottieAnimationSteps = [
    {
      animationData: phoneRegistration1,
      speed: 1,
      renderer: 'svg',
      loop: false,
      autoplay: false,
    },
    {
      animationData: phoneRegistration1Transition2,
      speed: 1,
      renderer: 'svg',
      loop: false,
      autoplay: false,
    },
    {
      animationData: phoneRegistration2,
      speed: 1,
      renderer: 'svg',
      loop: true,
      autoplay: false,
    },
    {
      animationData: phoneRegistration2Transition3,
      speed: 1,
      renderer: 'svg',
      loop: false,
      autoplay: false,
    },
    {
      animationData: phoneRegistration3,
      speed: 1,
      renderer: 'svg',
      loop: true,
      autoplay: false,
    },
    {
      animationData: phoneRegistration3Transition4,
      speed: 1,
      renderer: 'svg',
      loop: false,
      autoplay: false,
    },
    {
      animationData: phoneRegistration4,
      speed: 1,
      renderer: 'svg',
      loop: true,
      autoplay: false,
    },
    {
      animationData: phoneRegistration4Transition5,
      speed: 1,
      renderer: 'svg',
      loop: false,
      autoplay: false,
    },
  ];

  formConfig = {
    fields: {
      extension: {
        dataAutomation: 'extension-input',
        required: true,
        validation: [
          {
            validator: (val: string) => this._searchPhoneAndSetValidationMessage(val),
            message: () => this._validationMessage,
            successMessage: SearchPhoneValidationMessage.VALID,
          },
        ],
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'Ex. 1001',
          inputSize: InputSize.LG,
        }),
      },
    },
  };

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private selfServeUiContext: SelfServeUiContext,
    private defaultDeskphoneResource: DefaultDeskphoneResource,
    private searchPhoneResource: SearchPhoneResource,
    private phoneResource: PhoneResource,
    private searchDirectoryNumberResource: SearchDirectoryNumberResource,
    private directoryNumberResource: DirectoryNumberResource,
    private toastService: ToastService,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private deviceRegistrationStatusPollingService: DeviceRegistrationStatusPollingService,
    protected smacsFormStateService: SmacsFormStateService,
    private global360ViewContext: Global360ViewContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.username = get(this.route, 'snapshot.params.username');

    const selfServeSub = this.selfServeUiContext.selfServe360View$.subscribe((view: SelfServe360View) => {
      if (this._phoneNotFound) {
        this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(this.username)}`);
      }

      if (this._isDeleted) {
        this._isDeleted = false;
        this._validateAndSubmitSource.next(false);
        return;
      }

      this.canActivateDeskphone = view.canActivateDeskphone;
      this.currentUserRef = view.endUsers[0].ref;

      if (view.canActivateDeskphone) {
        this._site = view.sites[0];
        this._userServerId = view.primaryExtensions[0].serverId;
        this.isLoading = false;
      } else {
        this.isLoading = false;
      }
    });
    this._subscriptions.add(selfServeSub);

    if (!this.selfServeUiContext.selfServe360View) {
      this.selfServeUiContext.initUserDetails(this.username);
    }
  }

  ngAfterViewInit() {
    // if the component is not initialized yet, this'll throw an error.
    if (this.isLoading) {
      setTimeout(() => this.ngAfterViewInit(), 500);
    } else if (this.canActivateDeskphone) {
      super.ngAfterViewInit();
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.deviceRegistrationStatusPollingService.stopPolling();
    this._subscriptions.unsubscribe();
  }

  protected submit() {
    this.isActivationInProgress = true;
    return this._onProvisionDeskphoneClick();
  }

  private _onProvisionDeskphoneClick(): Observable<Phone> {
    return this.defaultDeskphoneResource
      .post({
        endUserUsername: this.username,
        siteId: `${this._site.id}`,
        phoneModel: this._phoneResult.ref.model,
        protocol: this._phoneResult.ref.protocol,
      })
      .pipe(
        tap((phone: Phone) => {
          phone.id = this._phoneResult.ref.id;
          phone.name = this._phoneResult.ref.name;

          const auditTags = [{ name: 'self-serve activation', value: 'self-serve activation' }] as Nvp[];

          return this.phoneResource.put(phone, this._phoneResult.ref.serverId.toString(), auditTags).subscribe(() => {
            return this.searchDirectoryNumberResource
              .get({ extension: this.formData.extension })
              .subscribe((directoryNumberResults: DirectoryNumberResult[]) => {
                const autoregisteredDnRef = directoryNumberResults[0].ref;
                return this.directoryNumberResource
                  .delete(autoregisteredDnRef.id, autoregisteredDnRef.serverId, auditTags)
                  .subscribe(() => this._checkPhoneStatus(phone));
              });
          });
        })
      );
  }

  onCancelClick = () => {
    const options = {
      windowClass: 'self-provisioning-cancel-modal-button',
      modalViewProperties: {
        icon: SmacsIcons.WINDOWS_CLOSE,
        title: this.translateService.instant('tkey;selfserve.user.activate_deskphone.modal.cancel.title'),
        promptBody: this.translateService.instant('tkey;selfserve.user.activate_deskphone.modal.cancel.body'),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;selfserve.user.activate_deskphone.return_button.title',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onGoToSelfServeClicked(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  };

  onNextClick = () => {
    this._clearAnimationTransitionTimeout();
    this.currentStep = 3;
    this.animationCurrentStep = 4;
    this.animationReplaySource.next(4);
    this._currentAnimationTransition = window.setTimeout(() => {
      this.animationCurrentStep = 5;
      this.animationReplaySource.next(5);
    }, 3400);
  };

  onBeginClick = () => {
    this._clearAnimationTransitionTimeout();
    this.currentStep = 2;
    this.animationCurrentStep = 2;
    this.animationReplaySource.next(2);
    this._currentAnimationTransition = window.setTimeout(() => {
      this.animationCurrentStep = 3;
      this.animationReplaySource.next(3);
    }, 2000);
  };

  private _onGoToSelfServeClicked(): Observable<void> {
    return new Observable((subscriber) => {
      this.router.navigateByUrl(`self-serve/user/${encodeURIComponent(this.username)}`).then(() => {
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private _clearAnimationTransitionTimeout() {
    clearTimeout(this._currentAnimationTransition);
  }

  private _searchPhoneAndSetValidationMessage(val: string): Observable<SmacsFormsValidationState> {
    this._phoneResult = null;
    return this.searchPhoneResource.get({ extension: val }).pipe(
      map<PhoneResult[], SmacsFormsValidationState>((results: PhoneResult[]) => {
        if (results.length < 1) {
          this._validationMessage = SearchPhoneValidationMessage.NOT_FOUND;
          return SmacsFormsValidationState.INVALID;
        }
        if (results.length > 1) {
          this._validationMessage = SearchPhoneValidationMessage.MULTIPLE_FOUND;
          return SmacsFormsValidationState.INVALID;
        }
        const result = results[0];
        if (result.owner) {
          if (result.owner.username === this.username) {
            this._validationMessage = SearchPhoneValidationMessage.OWNED_BY_SELF;
            return SmacsFormsValidationState.INVALID;
          }
          this._validationMessage = {
            content: SearchPhoneValidationMessage.OWNED_BY_OTHER,
            params: { userId: result.owner.username },
          };
          return SmacsFormsValidationState.INVALID;
        }
        if (result.ref.serverId !== this._userServerId) {
          this._validationMessage = SearchPhoneValidationMessage.WRONG_CLUSTER;
          return SmacsFormsValidationState.INVALID;
        }
        this._phoneResult = result;
        this._validationMessage = SearchPhoneValidationMessage.VALID;
        return SmacsFormsValidationState.VALID;
      })
    );
  }

  private _checkPhoneStatus = (phone: Phone) => {
    this.deviceRegistrationStatusPollingService.setBaseUrl(phone.name, phone.owner.serverId);
    this.deviceRegistrationStatusPollingService.startPolling(3);
    this.deviceRegistrationStatusPollingService.state$.subscribe({
      next: (phoneStatus: PhoneStatus) => {
        if (phoneStatus.status === DeviceRegistrationStatus.REGISTERED) {
          this.isActivationInProgress = false;
          this.deviceRegistrationStatusPollingService.stopPolling();
          this._isDeleted = true;
          this.currentStep = 5;
          this._clearAnimationTransitionTimeout();
          this.animationCurrentStep = 8;
          this.animationReplaySource.next(8);
        }
      },
      complete: () => {
        if (this.isActivationInProgress) {
          this._handlePhoneNotFound();
        }
        this.global360ViewContext.init(this.username);
      },
    });
  };

  private _handlePhoneNotFound() {
    this.isActivationInProgress = false;
    this.smacsFormStateService.setIsFormDirty(false);
    this._phoneNotFound = true;
    this.global360ViewContext.init(this.username);
  }
}
