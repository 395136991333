import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Microsoft360ViewContext } from '../../../shared/contexts/microsoft-360-view.context';
import {
  DubberEndUser,
  DubberEndUserRef,
  Microsoft360View,
  MicrosoftComplianceRecordingPolicyFieldConfig,
  MicrosoftDubberPointsFieldConfig,
  MicrosoftDubberUserFieldConfig,
  MicrosoftTeamsComplianceRecordingPolicy,
} from '../../../shared/models/generated/smacsModels';
import { forkJoin, Observable, of, Subject, switchMap } from 'rxjs';
import { DubberFieldConfigResource } from '../../../shared/resources/dubber-field-config.resource';
import {
  DubberComplianceRecordingFieldConfig,
  DubberComplianceRecordingFormData,
} from './dubber-compliance-recording-form/dubber-compliance-recording-form.component';
import { DubberService, DubPointAndResult } from '../../shared/services/dubber.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'smacs-dubber-compliance-recording',
  templateUrl: './dubber-compliance-recording.component.html',
  providers: [DubberFieldConfigResource, DubberService],
})
export class DubberComplianceRecordingComponent implements OnInit, OnDestroy {
  ms360View: Microsoft360View;
  dubberUser: DubberEndUserRef;
  isLoading = true;
  fieldConfig: DubberComplianceRecordingFieldConfig;
  initialEntity: DubberComplianceRecordingFormData;

  private _upn: string;
  private _unsubscribeSubject = new Subject<void>();

  constructor(
    private _route: ActivatedRoute,
    private _microsoft360ViewContext: Microsoft360ViewContext,
    private _dubberFieldConfigResource: DubberFieldConfigResource,
    private _dubberService: DubberService
  ) {}

  ngOnInit() {
    this._upn = this._route.snapshot.paramMap.get('upn');
    this._microsoft360ViewContext.state$
      .pipe(takeUntil(this._unsubscribeSubject))
      .pipe(
        switchMap((ms360View) => {
          this.dubberUser = ms360View.dubberUser;
          this.ms360View = ms360View;

          return forkJoin([
            this._getFieldConfigData(),
            !!this.dubberUser ? this._getExistingDubberUserData() : of(null),
          ]);
        })
      )
      .subscribe(
        ([
          [complianceRecordingPolicyFieldConfig, dubberUserFieldConfig, dubberPointsFieldConfig],
          dubberExistingUserData,
        ]) => {
          const [teamsComplianceRecordingPolicy, dubberEndUser, dubPointAndResult] = dubberExistingUserData ?? [];
          // If we load an existing user that has more than 1 dub point, throw an error
          if (dubPointAndResult && dubPointAndResult.dubPointsResult.dubPointRefJsons.length > 1) {
            throw new Error('Users with multiple dubpoints configured are not supported');
          } else {
            this.fieldConfig = {
              complianceRecordingPolicy: complianceRecordingPolicyFieldConfig.complianceRecordingPolicy,
              user: dubberUserFieldConfig,
              product: dubberPointsFieldConfig.dubberPoint,
            };
            this.initialEntity = {
              complianceRecordingPolicy: !!this.dubberUser
                ? teamsComplianceRecordingPolicy.complianceRecordingPolicy
                : this.fieldConfig.complianceRecordingPolicy.defaultOption,
              email: !!this.dubberUser ? dubberEndUser.email : this.fieldConfig.user.email.value,
              firstName: !!this.dubberUser ? dubberEndUser.firstName : this.fieldConfig.user.firstName.value,
              lastName: !!this.dubberUser ? dubberEndUser.lastName : this.fieldConfig.user.lastName.value,
              mobileNumber: !!this.dubberUser ? dubberEndUser.mobileNumber : this.fieldConfig.user.mobileNumber.value,
              region: !!this.dubberUser ? this.dubberUser.region : this.fieldConfig.user.region.defaultOption,
              product: !!this.dubberUser
                ? dubPointAndResult
                  ? dubPointAndResult.dubPoint.product
                  : ''
                : this.fieldConfig.product.defaultOption,
            };
            this.isLoading = false;
          }

          this._unsubscribeSubject.next();
          this._unsubscribeSubject.complete();
        }
      );
  }

  ngOnDestroy() {
    this._unsubscribeSubject.next();
    this._unsubscribeSubject.complete();
  }

  private _getFieldConfigData(): Observable<
    [MicrosoftComplianceRecordingPolicyFieldConfig, MicrosoftDubberUserFieldConfig, MicrosoftDubberPointsFieldConfig]
  > {
    return forkJoin([
      this._dubberFieldConfigResource.getComplianceRecordingPolicy(true),
      this._dubberFieldConfigResource.getDubberUserFieldConfig(this._upn),
      this._dubberFieldConfigResource.getDubberPointsFieldConfig(),
    ]);
  }

  private _getExistingDubberUserData(): Observable<
    [MicrosoftTeamsComplianceRecordingPolicy, DubberEndUser, DubPointAndResult | null]
  > {
    return forkJoin([
      this._dubberService.getUserComplianceRecordingPolicy(this._upn),
      this._dubberService.getDubberEndUser(this.dubberUser.region, this.dubberUser.id),
      this._dubberService.getDubPointsResultAndDubPointByRegionAndUserId(this.dubberUser.region, this.dubberUser.id),
    ]);
  }
}
