import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CucmLicenseUsage } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class CucmLicenseUsageResource {
  constructor(private $http: HttpClient) {}

  getCucmLicenseUsage(): Observable<CucmLicenseUsage> {
    return this.$http.get<CucmLicenseUsage>(
      `/services/cisco/reporting/license-optimizations/cucm-licenses?daysRecentlyCreated=30`
    );
  }
}
