<smacs-read-write-select-config-form
  *ngIf="!!entity && !isLoading"
  [entity]="entity"
  [displayLabel]="config.label"
  [dataAutomation]="config.dataAutomation"
  [availableOptions]="availableOptions"
  [disabled]="config.disabled ? config.disabled() : null"
  [disabledTooltip]="config.disabledTooltip ? config.disabledTooltip : null"
  [helpText]="getHelpText()"
  [type]="type"
  (smacsFormsUpdate$)="onFormUpdate($event)"
></smacs-read-write-select-config-form>
