import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[smacsFileUploader]',
})
export class FileUploaderDirective {
  @Output() fileDropped = new EventEmitter<FileList>();
  @Output() fileOver = new EventEmitter<boolean>();

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver.emit(true);
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver.emit(false);
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver.emit(false);
    const files = event.dataTransfer.files;
    if (files?.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
