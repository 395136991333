import { Component, ViewChild } from '@angular/core';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { SmacsFieldComponentConfig, SmacsFormsUpdate } from '../../../forms/smacs-forms-models';
import { SmacsIcons } from '../../models/smacs-icons.enum';
import { SmacsFieldAbstractDirective } from '../../../forms/smacs-field-abstract.directive';
import { CustomCheckboxFieldConfig } from '../../models/generated/smacsModels';
import { TranslateService } from '@ngx-translate/core';
import { CheckboxConfigFormComponent } from './checkbox-config-form/checkbox-config-form.component';
import { cloneDeep, isEqual } from 'lodash';

export class SmacsCheckboxFieldConfig extends SmacsFieldComponentConfig {
  constructor() {
    super();
  }
}

@Component({
  selector: 'smacs-checkbox-config',
  templateUrl: './smacs-checkbox-config.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsCheckboxConfigComponent }],
})
export class SmacsCheckboxConfigComponent extends SmacsFieldAbstractDirective<
  CustomCheckboxFieldConfig,
  CustomCheckboxFieldConfig,
  SmacsCheckboxFieldConfig
> {
  smacsIcons = SmacsIcons;

  @ViewChild(CheckboxConfigFormComponent) childFormComponent: CheckboxConfigFormComponent;

  constructor(protected smacsFormStateService: SmacsFormStateService, private translateService: TranslateService) {
    super(smacsFormStateService);
  }

  applyComponentConfig = () => {};

  onFormUpdate($event: SmacsFormsUpdate<CustomCheckboxFieldConfig>) {
    if (!isEqual($event.new, $event.old)) {
      this.entity = $event.new;
      this.updateSelf(this.entity);
      this.updateParent(cloneDeep($event.old));
    }
  }
}
