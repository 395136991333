import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PollingAbstractService } from '../abstract/polling.abstract.service';
import { JobStatus } from '../../models/generated/smacsModels';

@Injectable()
export class CiscoProvisioningStatusesPollingService extends PollingAbstractService<JobStatus[]> {
  protected _pollingTimeInMilliseconds = 3000;

  baseUrl = '/services/cisco/automate-jobs/cisco-zero-touch-provisionings/statuses';

  constructor(protected httpClient: HttpClient, protected ngZone: NgZone) {
    super(httpClient, ngZone);
  }
}
