import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import {
  ExtensionMobility,
  LineFeatureFieldConfig,
  ModelProtocolFieldConfig,
  Phone,
  PhoneButtonTemplate,
} from '../../models/generated/smacsModels';
import { LineFeatureConfigResource } from '../../resources/line-feature-config.resource';

type AbstractDevice = Phone | ExtensionMobility;

@Injectable()
export abstract class AbstractUiContext<DEVICE extends AbstractDevice = AbstractDevice> {
  abstract phoneState$: Observable<DEVICE>;

  private _isSavingSource = new ReplaySubject<boolean>(1);
  isSaving$ = this._isSavingSource.asObservable();

  protected _modelProtocolFieldConfigSource = new ReplaySubject<ModelProtocolFieldConfig>(1);
  modelProtocolFieldConfigState$ = this._modelProtocolFieldConfigSource.asObservable();

  protected constructor(protected lineFeatureConfigResource: LineFeatureConfigResource) {}

  abstract set(param: DEVICE): void;

  phoneButtonTemplate: PhoneButtonTemplate;

  setSubmitting(isSubmitting: boolean) {
    this._isSavingSource.next(isSubmitting);
  }

  getLineFeatureFieldConfig(
    model: string,
    protocol: string,
    username: string,
    extension: string,
    siteId: number
  ): Observable<LineFeatureFieldConfig> {
    return new Observable<LineFeatureFieldConfig>((subscriber) => {
      this.lineFeatureConfigResource
        .post({
          model: model,
          protocol: protocol,
          deviceOwner: username,
          extension: extension,
          siteId: siteId,
        })
        .subscribe((lineFeatureFieldConfig) => {
          subscriber.next(lineFeatureFieldConfig);
          subscriber.complete();
        });
    });
  }

  // Set the phone button template
  setPhoneTemplate(template: PhoneButtonTemplate) {
    this.phoneButtonTemplate = template;
  }

  getPhoneTemplate() {
    return this.phoneButtonTemplate;
  }
}
