import { SmacsTextComponent } from '../../../../forms/fields/text/smacs-text.component';
import { Component } from '@angular/core';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsFieldAbstractDirective } from '../../../../forms/smacs-field-abstract.directive';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';

@Component({
  selector: 'smacs-general-settings-text',
  templateUrl: './general-settings-text.component.html',
  styleUrls: ['./general-settings-text.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: GeneralSettingsTextComponent }],
})
export class GeneralSettingsTextComponent extends SmacsTextComponent {
  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    protected translateService: TranslateService,
    protected smacsModalService: SmacsModalService
  ) {
    super(smacsFormStateService, translateService, smacsModalService);
  }
}
