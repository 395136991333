import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  DirectoryNumberFieldConfig,
  DirectoryNumberFieldConfigRequest,
  LdapUserDialPlanDetailsFieldConfig,
  LdapUserDialPlanDetailsFieldConfigRequest,
  TranslationPatternFieldConfig,
  TranslationPatternFieldConfigRequest,
} from '../../../../shared/models/generated/smacsModels';

@Injectable()
export class PrimaryExtensionFieldConfigResource {
  constructor(private http: HttpClient) {}

  getDnFieldConfig(body: DirectoryNumberFieldConfigRequest): Observable<DirectoryNumberFieldConfig> {
    return this.http.post<DirectoryNumberFieldConfig>(`/services/cisco/field-configs/directory-numbers`, {
      ...body,
    });
  }

  getTranslationPatternFieldConfig(
    body: TranslationPatternFieldConfigRequest
  ): Observable<TranslationPatternFieldConfig> {
    return this.http.post<TranslationPatternFieldConfig>(`/services/cisco/field-configs/translation-patterns`, {
      ...body,
    });
  }

  getLdapUserDialPlanFieldConfig(
    body: LdapUserDialPlanDetailsFieldConfigRequest
  ): Observable<LdapUserDialPlanDetailsFieldConfig> {
    return this.http.post<LdapUserDialPlanDetailsFieldConfig>(
      `/services/cisco/field-configs/ldap-user-dial-plan-attributes`,
      {
        ...body,
      }
    );
  }
}
