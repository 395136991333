import { Component, OnInit } from '@angular/core';
import { ClusterResult, SiteBasedHelpdeskUserPermission } from '../../../../shared/models/generated/smacsModels';
import { FilterOptionSite } from '../site-based-helpdesk-user-permissions-management.component';
import { SmacsFormConfig, SmacsFormsUpdate } from '../../../../forms/smacs-forms-models';
import { SmacsRadioConfig } from '../../../../forms/fields/radio/smacs-radio.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { uniq } from 'lodash';
import jQuery from 'jquery';
import { ButtonSizes, ButtonStyles } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SiteClusterSelection } from '../site-based-helpdesk.enum';
import { SiteBasedHelpdeskManagementResource } from '../../../resources/site-based-helpdesk-management-resource.service';
import { combineLatest, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { ModalBodyClass } from '../../../../modals/modal-body';
import { DetailedModalComponent } from '../../../../modals/detailed-modal/detailed-modal.component';
import ChangeEvent = JQuery.ChangeEvent;

interface ModalViewProperties {
  permissionList: SiteBasedHelpdeskUserPermission[];
  authorizedSitesFilterOpts: FilterOptionSite[];
  clusters: ClusterResult[];
}

interface SelectedSiteClusterDisplay {
  name: string;
  count: number;
}

interface AuthorizationLevel {
  accessLevel: string;
  isAccessDisabled: boolean;
  readAccessToControlEnabled: boolean;
}

@Component({
  selector: 'app-bulk-edit-permissions-modal',
  templateUrl: './bulk-edit-permissions-modal.component.html',
  styleUrls: ['./bulk-edit-permissions-modal.component.scss'],
})
export class BulkEditPermissionsModalComponent extends ModalBodyClass implements OnInit {
  formConfig: SmacsFormConfig;
  resultFilter = '';
  smacsIcons = SmacsIcons;
  authorization: string;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  siteClusterSelection = SiteClusterSelection;
  permissionResult = [] as SiteBasedHelpdeskUserPermission[];
  modalViewProperties: ModalViewProperties;
  selectedSites = [] as string[];
  filteredAuthorizedSitesOpts = [] as FilterOptionSite[];
  filteredAuthorizedClusterOpts = [] as ClusterResult[];

  private _numberOfDisabledPermissions = 0;
  private _isBulkAccessDisabled: boolean;
  private _siteList = [] as string[];
  private _clusterList = [] as string[];
  private _selectedClusters = [] as string[];
  private _removedSites = [] as string[];
  private _removedClusters = [] as string[];
  private _authorizedSites = [] as SelectedSiteClusterDisplay[];
  private _authorizedClusters = [] as SelectedSiteClusterDisplay[];
  private _bulkUpdatePermissionList = [] as SiteBasedHelpdeskUserPermission[];
  private _isReadAccessToReportingDisabled: boolean;
  private _readAccessToReportingEnabledCount = 0;

  constructor(
    private activeModal: NgbActiveModal,
    private siteBasedHelpdeskManagementResource: SiteBasedHelpdeskManagementResource,
    protected smacsFormStateService: SmacsFormStateService,
    private modalComponent: DetailedModalComponent<any>
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this.permissionResult = [...this.modalComponent.modalViewProperties.permissionList];
    this.filteredAuthorizedSitesOpts = this.modalComponent.modalViewProperties.authorizedSitesFilterOpts;
    this.filteredAuthorizedClusterOpts = this.modalComponent.modalViewProperties.clusters;
    this._numberOfDisabledPermissions = 0;
    this.permissionResult.forEach((permission: SiteBasedHelpdeskUserPermission) => {
      if (permission.disabled) {
        this._numberOfDisabledPermissions++;
      }
    });
    this._readAccessToReportingEnabledCount = this.permissionResult.filter(
      (permission: SiteBasedHelpdeskUserPermission) => permission.readAccessToControlEnabled
    ).length;
    this.formConfig = {
      fields: {
        accessLevel: {
          label: '',
          dataAutomation: 'site-mac-man-edit-access-level',
          componentConfig: new SmacsRadioConfig<any>({
            buttons: [
              {
                value: SiteClusterSelection.SITES,
                label: 'tkey;admin.sitemacman.modal.sites.label',
              },
              {
                value: SiteClusterSelection.CLUSTERS,
                label: 'tkey;admin.sitemacman.modal.clusters.label',
              },
            ],
            inline: true,
            hideLabel: true,
          }),
        },
        isAccessDisabled: {
          label: 'tkey;admin.sitemacman.modal.disable.access',
          dataAutomation: 'site-mac-man-edit-disable-access',
          helpText: 'tkey;admin.sitemacman.modal.disable.access.help.text',
          componentConfig: new SmacsCheckboxConfig({
            checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX,
            indeterminate:
              this._numberOfDisabledPermissions > 0 && this._numberOfDisabledPermissions < this.permissionResult.length,
          }),
        },
        readAccessToControlEnabled: {
          label: 'tkey;admin.sitemacman.modal.read_only.label',
          dataAutomation: 'site-mac-man-read-access-control-enabled',
          helpText: 'tkey;admin.sitemacman.modal.read_only.helptext',
          componentConfig: new SmacsCheckboxConfig({
            checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX,
            indeterminate:
              this._readAccessToReportingEnabledCount > 0 &&
              this._readAccessToReportingEnabledCount < this.permissionResult.length,
          }),
        },
      },
    };
    this.indeterminateCheck();
    this.authorization = this._authorizedSitesVal()
      ? SiteClusterSelection.SITES
      : this._authorizedClusterVal()
      ? SiteClusterSelection.CLUSTERS
      : SiteClusterSelection.SITES;
    this.entitySource.next({
      accessLevel: this.authorization,
      isAccessDisabled: this._numberOfDisabledPermissions === this.permissionResult.length,
      readAccessToControlEnabled: this._readAccessToReportingEnabledCount === this.permissionResult.length,
    });

    this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<AuthorizationLevel>) => {
      this.resultFilter = this.authorization !== this.formData.accessLevel ? '' : this.resultFilter;
      this.authorization =
        this.authorization !== this.formData.accessLevel ? this.formData.accessLevel : this.authorization;

      this._filterResults();

      if (data.new?.isAccessDisabled) {
        const isAccessDisabledComponent = this.fieldComponents.find((field) => field.fieldId === 'isAccessDisabled');
        isAccessDisabledComponent.applyComponentConfig(
          new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX })
        );
        this._isBulkAccessDisabled = data.new.isAccessDisabled;
      }
      if (data.old?.isAccessDisabled) {
        this._isBulkAccessDisabled = data.new?.isAccessDisabled;
      }

      if (data.new?.readAccessToControlEnabled) {
        const readAccessToControlEnabledComponent = this.fieldComponents.find(
          (field) => field.fieldId === 'readAccessToControlEnabled'
        );
        readAccessToControlEnabledComponent.applyComponentConfig(
          new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX })
        );
        this._isReadAccessToReportingDisabled = data.new.readAccessToControlEnabled;
      }
      if (data.old?.readAccessToControlEnabled) {
        this._isReadAccessToReportingDisabled = data.new?.readAccessToControlEnabled;
      }
    });

    this._findCommonSitesClusters();
  }

  onClose() {
    this.activeModal.close(null);
  }

  isSiteSelectedForAllUsers(site: string): boolean {
    return (
      this._authorizedSites.filter(
        (authorizedSite: SelectedSiteClusterDisplay) =>
          site === authorizedSite.name && authorizedSite.count === this.permissionResult.length
      ).length > 0 || this.selectedSites.includes(site)
    );
  }

  isClusterSelectedForAllUsers(cluster: string): boolean {
    return (
      this._authorizedClusters.filter(
        (authorizedCluster: SelectedSiteClusterDisplay) =>
          cluster === authorizedCluster.name && authorizedCluster.count === this.permissionResult.length
      ).length > 0 || this._selectedClusters.includes(cluster)
    );
  }

  isClusterForAuthorizedSite(index: number, clusterName: string): boolean {
    if (index === 0) {
      return true;
    } else {
      if (index < this.filteredAuthorizedSitesOpts.length) {
        if (this.filteredAuthorizedSitesOpts[index - 1].cluster !== clusterName) {
          return true;
        }
      } else {
        return false;
      }
    }
  }

  isIndeterminateSite(site: string): boolean {
    return (
      this._authorizedSites.filter(
        (authorizedSite: SelectedSiteClusterDisplay) =>
          site === authorizedSite.name &&
          authorizedSite.count < this.permissionResult.length &&
          authorizedSite.count > 0
      ).length > 0 &&
      !this.selectedSites.includes(site) &&
      !this._removedSites.includes(site)
    );
  }

  isIndeterminateCluster(cluster: string): boolean {
    return (
      this._authorizedClusters.filter(
        (authorizedCluster: SelectedSiteClusterDisplay) =>
          cluster === authorizedCluster.name &&
          authorizedCluster.count < this.permissionResult.length &&
          authorizedCluster.count > 0
      ).length > 0 &&
      !this._selectedClusters.includes(cluster) &&
      !this._removedClusters.includes(cluster)
    );
  }

  updateSiteSelection(site: string, $event: ChangeEvent): void {
    if ($event.target.checked) {
      this.selectedSites.push(site);
    } else if (!$event.target.checked) {
      this._removedSites.push(site);
      this.selectedSites = this.selectedSites.filter((selectedSite: string) => selectedSite !== site);
    }
  }

  updateClusterSelection(cluster: string, $event: ChangeEvent): void {
    if ($event.target.checked) {
      this._selectedClusters.push(cluster);
    } else if (!$event.target.checked) {
      this._selectedClusters = this._selectedClusters.filter((selectedCluster: string) => selectedCluster !== cluster);
      this._removedClusters.push(cluster);
    }
  }

  onChanged(newValue: string) {
    this.resultFilter = newValue;
    this._filterResults();
  }

  submit() {
    return this._onSavePermissions();
  }

  private _authorizedSitesVal(): boolean {
    return this.permissionResult.some((userPermission: SiteBasedHelpdeskUserPermission) => {
      return userPermission.authorizedSites.length > 0;
    });
  }

  private _authorizedClusterVal(): boolean {
    return this.permissionResult.some((userPermission: SiteBasedHelpdeskUserPermission) => {
      return userPermission.authorizedClusters.length > 0;
    });
  }

  private _findCommonSitesClusters(): void {
    this.permissionResult.forEach((userPermission: SiteBasedHelpdeskUserPermission) => {
      userPermission.authorizedClusters.forEach((cluster: string) => {
        this._clusterList.push(cluster);
      });
      userPermission.authorizedSites.forEach((site: string) => {
        this._siteList.push(site);
      });
    });
    if (this._siteList.length > 0 && this._clusterList.length > 0) {
      this._clusterList.forEach((cluster) => {
        this.filteredAuthorizedSitesOpts.forEach((siteObj) => {
          if (siteObj.cluster === cluster) {
            this._siteList.push(siteObj.site);
          }
        });
      });
    }
    this._authorizedClusters = uniq(this._clusterList).map((clusterOption) => ({
      name: clusterOption,
      count: this._clusterList.filter((cluster) => clusterOption === cluster).length,
    }));
    this._authorizedSites = uniq(this._siteList).map((siteOption) => ({
      name: siteOption,
      count: this._siteList.filter((site) => siteOption === site).length,
    }));
  }

  /**
   * check if input element is indeterminate and update property
   */
  private indeterminateCheck(): void {
    jQuery(function () {
      jQuery('input.indeterminate-input').prop('indeterminate', true);
    });
  }

  /**
   * Update authorised sites when new site is selected, remove unchecked sites.
   */
  private _onSavePermissions(): Observable<void[]> {
    this._bulkUpdatePermissionList = [...this.permissionResult];
    this._bulkUpdatePermissionList.forEach((newPermission) => {
      if (this.authorization === SiteClusterSelection.SITES) {
        if (this._authorizedSites.length > 0 && this._authorizedClusters.length > 0) {
          if (newPermission.authorizedClusters && newPermission.authorizedClusters.length > 0) {
            newPermission.authorizedSites = uniq(
              newPermission.authorizedSites.concat(
                this.filteredAuthorizedSitesOpts
                  .filter(
                    (filteredSiteOption: FilterOptionSite) =>
                      newPermission.authorizedClusters.includes(filteredSiteOption.cluster) &&
                      !this._removedSites.includes(filteredSiteOption.site)
                  )
                  .map((site: FilterOptionSite) => site.site)
                  .concat(this.selectedSites)
              )
            );
          } else {
            newPermission.authorizedSites = uniq(
              newPermission.authorizedSites
                .filter((authorizedSite: string) => !this._removedSites.includes(authorizedSite))
                .concat(this.selectedSites)
            );
          }
          newPermission.authorizedClusters = [];
        } else {
          if ((this.selectedSites && this.selectedSites.length > 0) || this._removedSites.length > 0) {
            if (newPermission.authorizedClusters) {
              newPermission.authorizedSites = uniq(
                newPermission.authorizedSites
                  .filter((authorizedSite: string) => !this._removedSites.includes(authorizedSite))
                  .concat(this.selectedSites)
              );
            }
          } else {
            newPermission.authorizedSites = newPermission.authorizedSites.filter(
              (authorizedSite: string) => !this._removedSites.includes(authorizedSite)
            );
          }
          newPermission.authorizedClusters = [];
        }
      } else if (this.authorization === SiteClusterSelection.CLUSTERS) {
        if (this._selectedClusters && this._selectedClusters.length > 0) {
          if (newPermission.authorizedSites) {
            newPermission.authorizedClusters = uniq(
              newPermission.authorizedClusters
                .filter((authorizedCluster: string) => !this._removedClusters.includes(authorizedCluster))
                .concat(this._selectedClusters)
            );
            newPermission.authorizedSites = [];
          }
        } else {
          newPermission.authorizedClusters = newPermission.authorizedClusters.filter(
            (authorizedCluster: string) => !this._removedClusters.includes(authorizedCluster)
          );
          newPermission.authorizedSites = [];
        }
      }
    });
    const permissionUpdateList = this._bulkUpdatePermissionList.map((sitePermission) => ({
      userId: sitePermission.userId,
      disabled:
        this._isBulkAccessDisabled || this._isBulkAccessDisabled === false
          ? this._isBulkAccessDisabled
          : sitePermission.disabled,
      authorizedSites: sitePermission.authorizedSites,
      authorizedClusters: sitePermission.authorizedClusters,
      readAccessToControlEnabled:
        this._isReadAccessToReportingDisabled || this._isReadAccessToReportingDisabled === false
          ? this._isReadAccessToReportingDisabled
          : sitePermission.readAccessToControlEnabled,
    }));

    const requests = permissionUpdateList.map((permissionRequest: SiteBasedHelpdeskUserPermission) => {
      return this.siteBasedHelpdeskManagementResource.put(permissionRequest);
    }) as Observable<void>[];

    return combineLatest(requests).pipe(
      tap(() => {
        this.activeModal.close(permissionUpdateList);
      })
    );
  }

  private _filterResults(): void {
    if (this.authorization === 'CLUSTERS') {
      this.filteredAuthorizedClusterOpts = this.modalComponent.modalViewProperties.clusters.filter(
        (clusterResult: ClusterResult) => {
          return clusterResult.name.toLowerCase().includes(this.resultFilter.toLowerCase());
        }
      );
    } else {
      this.filteredAuthorizedSitesOpts = this.modalComponent.modalViewProperties.authorizedSitesFilterOpts.filter(
        (optionSite: FilterOptionSite) => {
          return (
            optionSite.site.toLowerCase().includes(this.resultFilter.toLowerCase()) ||
            optionSite.cluster.toLowerCase().includes(this.resultFilter.toLowerCase())
          );
        }
      );
    }
    this.indeterminateCheck();
  }
}
