<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div class="smacs-content" *ngIf="!isLoading">
  <div class="mx-4 flex-grow-1">
    <ziro-uccx-agent-form
      [entity]="uccxAgent"
      [uccxAgentRef]="uccxAgentRef"
      [fieldConfig]="fieldConfig"
      [resourceGroups]="resourceGroups"
    ></ziro-uccx-agent-form>
  </div>
</div>
