import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DnDetailSummary } from '../models/generated/smacsModels';

@Injectable()
export class DnDetailSummaryResource {
  constructor(private http: HttpClient) {}

  get = (dnId: string, cucmServerId: string): Observable<DnDetailSummary> => {
    return this.http.get<DnDetailSummary>(
      `/services/cisco/views/cucm-servers/${cucmServerId}/dn-detail-summaries/${encodeURIComponent(dnId)}`
    );
  };
}
