<div class="d-flex flex-row justify-content-center mb-3" [attr.data-automation]="'ad-write-down-child' + '-' + index">
  <div class="d-flex flex-column me-4 attribute-width">
    <div *ngIf="index === 0" class="text-start mb-4">
      <strong class="fs-6 ms-1">
        <span class="text-danger smacs-forms-asterisk">*</span>
        {{ 'tkey;admin.msdialplan.management.field.write_to_ad.card.keys.label' | translate }}
      </strong>
    </div>
    <smacs-text [fieldId]="'attributeName'"></smacs-text>
    <smacs-button
      *ngIf="isLast"
      [dataAutomation]="'add-item-button'"
      (clicked)="addItemClicked()"
      [buttonStyle]="buttonStyles.INFO"
      [buttonSize]="buttonSizes.NORMAL"
      [icon]="smacsIcons.ADD"
      [label]="'tkey;admin.msdialplan.management.field.write_to_ad.card.add_button.label'"
      class="mb-3 mt-4"
    ></smacs-button>
  </div>
  <div class="d-flex flex-column me-4 attribute-width">
    <div *ngIf="index === 0" class="text-start mb-4">
      <strong class="fs-6 ms-1">
        <span class="text-danger smacs-forms-asterisk">*</span>
        {{ 'tkey;admin.msdialplan.management.field.write_to_ad.card.values.label' | translate }}
      </strong>
    </div>
    <smacs-text [fieldId]="'attributeValue'" [dynamicPreviewValue]="dynamicPreviewValue"></smacs-text>
    <div class="pb-2 pt-1">
      <span class="me-1">
        <i [attr.class]="smacsIcons.PREVIEW + ' d-inline-block vertical-align-middle'"></i>
        <span>{{ 'tkey;site_management.site.section.preview.label' | translate }}</span>
      </span>
      <span *ngIf="!!previewText" data-automation="form-preview">{{ previewText }}</span>
    </div>
  </div>

  <div class="d-flex flex-column w-auto">
    <div *ngIf="index === 0" class="text-start mb-4" style="visibility: hidden">
      <!-- Placeholder to match the height of the headers in the other columns -->
      <strong class="fs-6 ms-1">&nbsp;</strong>
    </div>
    <div class="d-flex flex-row justify-content-start">
      <smacs-checkbox-button-field [fieldId]="'show'" class="me-4"></smacs-checkbox-button-field>
      <smacs-button
        *ngIf="!hideClear"
        [dataAutomation]="'clear-item-button'"
        (clicked)="clearItemClicked()"
        [buttonStyle]="buttonStyles.DANGER"
        [buttonSize]="buttonSizes.NORMAL"
        [icon]="smacsIcons.DELETE"
        class="mb-3"
      ></smacs-button>
    </div>
  </div>
</div>
