import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { HtmlInputAddOn, SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { LdapSyncConfig } from '../../../../shared/models/generated/smacsModels';
import { LdapSyncConfigResource } from '../../../resources/ldap-sync-config.resource';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../../button/button.component';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

interface AutomaticSyncPayload {
  enabled: boolean;
  intervalMinutes: number;
}

const validators = {
  min: (val: number) => (val >= 60 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID),
};

@Component({
  selector: 'smacs-automatic-sync-form',
  templateUrl: './automatic-sync-form.component.html',
})
export class AutomaticSyncFormComponent
  extends SmacsFormAbstractDirective<AutomaticSyncPayload>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() ldapSyncConfig: LdapSyncConfig;

  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;

  fieldGroups = {
    textInputs: ['intervalMinutes'],
    checkboxInputs: ['enabled'],
  };

  formConfig = {
    fields: {
      enabled: {
        label: 'tkey;admin.ldap_sync.automatic_sync.enable.label',
        dataAutomation: 'ldap-auto-sync-enable',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.CHECKBOX }),
      },
      intervalMinutes: {
        label: 'tkey;admin.ldap_sync.automatic_sync.period.label',
        dataAutomation: 'ldap-auto-sync-period-time-unit',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.NUMBER,
          htmlInputAddOn: {
            appendedContent: 'tkey;admin.ldap_sync.automatic_sync.period_time_unit.label',
          } as HtmlInputAddOn,
        }),
        required: () => this.formData.enabled,
        disabled: () => !this.formData.enabled,
        validation: [
          {
            validator: validators.min,
            message: () =>
              this.translateService.instant('tkey;validators.global.error.min', {
                minValue: 60,
              }),
          },
        ],
      },
    },
  } as SmacsFormConfig;

  constructor(
    private ldapSyncConfigResource: LdapSyncConfigResource,
    private toastService: ToastService,
    private translateService: TranslateService,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.entitySource.next({
      enabled: false,
      intervalMinutes: 0,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (!changes.ldapSyncConfig.previousValue && changes.ldapSyncConfig.currentValue) {
      this.entitySource.next({
        enabled: this.ldapSyncConfig.enabled,
        intervalMinutes: this.ldapSyncConfig.intervalMinutes,
      });
    }
  }

  protected submit() {
    return this._onSaveClick();
  }

  private _onSaveClick(): Observable<void> {
    return this.ldapSyncConfigResource
      .put({
        ...this.ldapSyncConfig,
        enabled: this.formData.enabled,
        intervalMinutes: this.formData.intervalMinutes,
      })
      .pipe(
        tap(() => {
          this.toastService.push(
            ToastTypes.SUCCESS,
            'icon-wrench',
            'tkey;admin.ldap_sync.automatic_sync.save.toast.success.title',
            'tkey;admin.ldap_sync.automatic_sync.save.toast.success.text'
          );
        })
      );
  }
}
