import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ApplicationLogsResource } from '../../resources/application-logs-resource.resource';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-admin-application-logs',
  templateUrl: './application-logs.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class ApplicationLogsComponent implements OnInit, OnDestroy {
  todaysLogsIsDownloading = false;
  allLogsIsDownloading = false;
  buttonSizes = ButtonSizes;
  buttonStyle = ButtonStyles;
  icons = SmacsIcons;

  constructor(
    private applicationLogsResource: ApplicationLogsResource,
    private breadcrumbsService: BreadcrumbsService
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;pages.admin.application_logs.title' }]);
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
  }

  getAllLogs() {
    this.allLogsIsDownloading = true;
    this.applicationLogsResource.getAllLogs().subscribe(() => {
      this.allLogsIsDownloading = false;
    });
  }

  getTodaysLogs() {
    this.todaysLogsIsDownloading = true;
    this.applicationLogsResource.getTodaysExportLogs().subscribe(() => {
      this.todaysLogsIsDownloading = false;
    });
  }
}
