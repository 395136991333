import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, Observable, of, ReplaySubject, Subscription, tap } from 'rxjs';
import {
  GlobalProperties,
  MicrosoftDialPlanFieldConfig,
  MicrosoftGraphRegistrationDetails,
} from '../models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { GlobalPropertiesContext } from './global-properties.context';
import { map } from 'rxjs/operators';
import { MsGraphConfigContext } from '../../admin/contexts/ms-graph-config.context';

@Injectable()
export class MicrosoftDialPlansFieldConfigContext implements OnDestroy {
  _stateSource = new ReplaySubject<MicrosoftDialPlanFieldConfig[]>(1);
  state$ = this._stateSource.asObservable();

  private _subscriptions = new Subscription();
  private _graphConfigured = false;

  constructor(
    private _http: HttpClient,
    private _globalPropertiesContext: GlobalPropertiesContext,
    private _msGraphConfigContext: MsGraphConfigContext
  ) {
    const sub = combineLatest([this._globalPropertiesContext.state$, this._msGraphConfigContext.state$]).subscribe(
      ([globalProperties, msGraphState]: [GlobalProperties, MicrosoftGraphRegistrationDetails]) => {
        this._graphConfigured = !!msGraphState?.tenant && !!msGraphState?.clientId;
        if (globalProperties.hostedEnabled) {
          this.init().subscribe();
        }
      }
    );

    this._subscriptions.add(sub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  init(): Observable<null> {
    return this._graphConfigured ? this._getDialPlansFieldConfigs().pipe(map(() => null)) : of(null);
  }

  private _getDialPlansFieldConfigs(): Observable<MicrosoftDialPlanFieldConfig[]> {
    return this._http.get<MicrosoftDialPlanFieldConfig[]>(`/services/microsoft/field-configs/dial-plans`).pipe(
      tap((response: MicrosoftDialPlanFieldConfig[]) => {
        this._stateSource.next(response);
      })
    );
  }
}
