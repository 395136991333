import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import {
  Microsoft360View,
  MicrosoftTeamsAudioConferencing,
  SelectFieldConfig,
} from '../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../forms/fields/select/smacs-select.component';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { Observable, Subscription, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MicrosoftTeamsAudioConferencingResource } from '../../../shared/resources/microsoft-teams-audio-conferencing.resource';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { TranslateService } from '@ngx-translate/core';
import { TelephoneNumberFilter } from '../../../../shared/filters/telephone-number.filter';
import { catchError, tap } from 'rxjs/operators';
import { Microsoft360ViewContext } from '../../../../shared/contexts/microsoft-360-view.context';
import { cloneDeep } from 'lodash';

interface MicrosoftTeamsAudioConferencingFormData {
  audioConferenceNumber: SmacsSelectOption;
}

@Component({
  selector: 'smacs-ms-teams-audio-conferencing-form',
  templateUrl: './teams-audio-conferencing-form.component.html',
  styleUrls: ['./teams-audio-conferencing-form.component.scss'],
})
export class MsTeamsAudioConferencingFormComponent
  extends SmacsFormAbstractDirective<MicrosoftTeamsAudioConferencing, MicrosoftTeamsAudioConferencingFormData>
  implements OnInit, OnDestroy
{
  @Input() fieldConfig: SelectFieldConfig<string>;
  @Input() upn: string;

  smacsIcons = SmacsIcons;
  formConfig: SmacsFormConfig;

  private _microsoft360View: Microsoft360View;
  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private toastService: ToastService,
    private smacsModalService: SmacsModalService,
    private route: ActivatedRoute,
    private bottomNavService: BottomNavService,
    private msTeamsAudioConferencingResource: MicrosoftTeamsAudioConferencingResource,
    private microsoft360ViewContext: Microsoft360ViewContext,
    private translateService: TranslateService,
    private telephoneNumberFilter: TelephoneNumberFilter,
    private router: Router
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.microsoft360ViewContext.state$.subscribe((state: Microsoft360View) => {
      this._microsoft360View = state;
    });

    this._initForm();
    this._initBottomNav();

    const formSubmittedSub = this._validateAndSubmitSource.subscribe(() => {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: !this.isFormValid(),
        })
      );
    });
    this._subscriptions.add(formSubmittedSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  private _initForm() {
    const formattedPossibleOptions: SmacsSelectOption[] = this.fieldConfig.possibleOptions.map((option) => {
      return {
        value: option,
        label: option,
      };
    });

    this.formConfig = {
      fields: {
        audioConferenceNumber: {
          label: 'tkey;shared.model.ms_teams_audio_conferencing.form.audio_conference_number.label',
          componentConfig: new SmacsSelectConfig({ options: formattedPossibleOptions }),
          dataAutomation: 'ms-teams-audio-conferencing-number',
          required: () => this.fieldConfig.required,
          defaultValue: () => this.fieldConfig.defaultValue,
        },
      },
    } as SmacsFormConfig;
  }

  private _initBottomNav() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'ms-teams-audio-conferencing-cancel',
          dataAutomation: 'ms-teams-audio-conferencing-cancel',
          label: 'tkey;global.button.cancel.text',
          buttonClass: ButtonStyles.DEFAULT,
          cb: () => {
            this.router.navigate(['../'], { relativeTo: this.route });
          },
        },
        {
          id: 'ms-teams-audio-conferencing-save',
          dataAutomation: 'ms-teams-audio-conferencing-save',
          label: 'tkey;global.button.save.text',
          icon: this.smacsIcons.OK,
          buttonClass: ButtonStyles.PRIMARY,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );
  }

  protected submit() {
    return this._onSaveClicked();
  }

  private _onSaveClicked(): Observable<void> {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
      })
    );
    this._setPending(true);

    return this.msTeamsAudioConferencingResource.put(this.upn, this.entity).pipe(
      tap(() => {
        this.smacsFormStateService.setIsFormDirty(false);
        const ms360 = cloneDeep(this._microsoft360View);
        ms360.teamsAudioConferencing = this.entity;
        this.microsoft360ViewContext._stateSource.next(ms360);
        this.microsoft360ViewContext.refresh(this.upn).subscribe();

        this.toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.MICROSOFT_TEAMS_CALLING,
          'tkey;shared.toast.save.success.title',
          this.translateService.instant('tkey;shared.toast.save.success.message', {
            type: this.translateService.instant('tkey;shared.model.ms_teams_audio_conferencing.text'),
            name: this.upn,
          })
        );
        this.router.navigate(['../'], { relativeTo: this.route });
      }),
      catchError((response) => {
        this._setPending(false);
        if (response.status === 422) {
          this.toastService.push(
            ToastTypes.ERROR,
            `${this.smacsIcons.MICROSOFT_TEAMS_CALLING} text-danger`,
            'tkey;shared.toast.save.fail.title',
            response.error.description
          );
        } else {
          return throwError(() => response);
        }
      })
    );
  }

  private _setPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'ms-teams-audio-conferencing-save',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  toEntity = (formData: MicrosoftTeamsAudioConferencingFormData): MicrosoftTeamsAudioConferencing => {
    return {
      ...this.entity,
      audioConferenceNumber: formData.audioConferenceNumber?.value,
    };
  };

  toFormData = (entity: MicrosoftTeamsAudioConferencing): MicrosoftTeamsAudioConferencingFormData => {
    return {
      audioConferenceNumber: {
        value: entity.audioConferenceNumber,
        label: entity.audioConferenceNumber,
      },
    };
  };
}
