import { Component, Input } from '@angular/core';
import { SmacsLink } from '../../models/smacs-link';
import { SmacsIcons } from '../../models/smacs-icons.enum';

@Component({
  selector: 'app-base-container',
  templateUrl: './base-container.component.html',
  styleUrls: ['./base-container.component.scss'],
})
export class BaseContainerComponent {
  window = window;
  @Input() pageDisabled: boolean;
  @Input() serverDisabled: boolean;
  @Input() pageDisabledMessage: string;
  @Input() links: SmacsLink[];
  @Input() licenseExpired: boolean;
  @Input() isDialPlansConfigured: boolean;

  smacsIcons = SmacsIcons;
  disableLink = ['admin/site-management', 'admin/cisco/dial-plan-management', 'phone-button-templates'];
  dialPlanLinks = ['microsoft/zero-touch-provisioning', 'microsoft/bulk-provisioning', 'microsoft/dial-plan-inventory'];

  disableLinks(url: string): boolean {
    if (this.licenseExpired) {
      return true;
    } else if (!this.isDialPlansConfigured && this.dialPlanLinks.includes(url)) {
      return true;
    } else if (this.disableLink.includes(url)) {
      return this.serverDisabled;
    }
    return false;
  }

  getDisableTooltip(url?: string): string {
    if (this.licenseExpired) {
      return 'tkey;admin.menu.license_expired.tooltip';
    } else if (!this.isDialPlansConfigured && this.dialPlanLinks.includes(url)) {
      return 'tkey;global.zpm.no_dial_plans.text';
    } else {
      return 'tkey;admin.servers.disable.tooltip';
    }
  }

  // Temporary hack, method to be removed once Site Management page is migrated to Angular.
  activeWindow(href: string): boolean {
    return window.location.href.includes('bulk-copy') && href.includes('site-management');
  }
}
