import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuditCriteria, AuditSearchResult } from '../../shared/models/generated/smacsModels';
import { combineLatest, Observable, ReplaySubject, Subscriber } from 'rxjs';
import { FileDownloadService } from '../../shared/services/file-download.service';

export interface SystemAudits {
  categories: string[];
  types: string[];
  response: string[];
}

@Injectable()
export class AuditService {
  private _stateSource = new ReplaySubject<SystemAudits>(1);
  state$ = this._stateSource.asObservable();
  responses = ['OK', 'Error'];

  constructor(private http: HttpClient, private fileDownloadService: FileDownloadService) {
    this.init().subscribe((data) => {
      this._stateSource.next(data);
    });
  }

  init() {
    const categoriesReq = this.http.get<string[]>('/services/system/admin/audits/categories');
    const typesReq = this.http.get<string[]>('/services/system/admin/audits/ui-types');
    return new Observable((subscriber: Subscriber<SystemAudits>) => {
      combineLatest([categoriesReq, typesReq]).subscribe(([categories, types]) => {
        subscriber.next({
          categories: categories,
          types: types,
          response: this.responses,
        });
        subscriber.complete();
      });
    });
  }

  searchAudits(criteria: AuditCriteria): Observable<AuditSearchResult> {
    return this.http.post<AuditSearchResult>('/services/system/admin/audits/search', criteria);
  }

  exportAudits(): Observable<void> {
    return this.fileDownloadService.downloadFile('/services/system/admin/audits/export');
  }
}
