import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LdapSyncConfig } from '../../shared/models/generated/smacsModels';

@Injectable()
export class LdapSyncConfigResource {
  constructor(private http: HttpClient) {}

  get(): Observable<LdapSyncConfig> {
    return this.http.get<LdapSyncConfig>('/services/cisco/automate-jobs/cisco-ldap-syncs');
  }

  put(ldapSyncConfig: LdapSyncConfig): Observable<void> {
    return this.http.put<void>('/services/cisco/automate-jobs/cisco-ldap-syncs', ldapSyncConfig);
  }
}
