import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { of, Subscription } from 'rxjs';
import { SmacsTextComponent } from '../../../../forms/fields/text/smacs-text.component';
import {
  HtmlCheckboxType,
  SmacsCheckboxComponent,
  SmacsCheckboxConfig,
} from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { SmacsIcons } from '../../../models/smacs-icons.enum';
import { SmacsModalService } from '../../../services/smacs-modal.service';
import { SelectConfigEntity, SelectConfigTranslations } from '../legacy-smacs-select-config.component';
import { ButtonStyles } from '../../../../button/button.component';
import { differenceWith, intersectionWith, isEqual, omit, union } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { UniqueIdService } from '../../../services/unique-id.service';
import { EditCustomSelectModalComponent } from '../../../../modals/edit-custom-select-modal/edit-custom-select-modal.component';

export interface SelectConfigFormData extends SelectConfigEntity {
  possibleOptionsFormatted: string;
}

@Component({
  selector: 'smacs-select-config-form',
  templateUrl: './select-config-form.component.html',
  styleUrls: ['./select-config-form.component.scss'],
})
export class SelectConfigFormComponent
  extends SmacsFormAbstractDirective<SelectConfigEntity, SelectConfigFormData>
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('possibleOptions') possibleOptionsTextComponent: SmacsTextComponent;
  @ViewChild('required') requiredCheckboxComponent: SmacsCheckboxComponent;
  @ViewChild('show') showCheckboxComponent: SmacsCheckboxComponent;

  @Input() displayLabel: string;
  @Input() dataAutomation: string;
  @Input() isMultiSelect: boolean;
  @Input() availableOptions: string[];
  @Input() isRequiredVisible: boolean;
  @Input() isAlwaysRequired: boolean;
  @Input() disabled = false;
  @Input() disabledTooltip = '';
  @Input() helpText: string;
  @Input() translations: SelectConfigTranslations;
  @Input() labelToolTipIconClass: string;
  @Input() labelToolTipText: string;

  formConfig: SmacsFormConfig;
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;

  private _subs = new Subscription();
  private _validators = {
    defaultValueValidator(defaultValue: string, required: boolean, show: boolean): SmacsFormsValidationState {
      return !show && required && !defaultValue ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
    },
    requiredValidator(defaultValue: string, required: boolean): SmacsFormsValidationState {
      return required && !defaultValue ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
    },
    invalidOptionValidator(defaultValue: string, availableOptions: string[]): SmacsFormsValidationState {
      return defaultValue && !availableOptions.includes(defaultValue)
        ? SmacsFormsValidationState.INVALID
        : SmacsFormsValidationState.VALID;
    },
  };

  private static _getValues(value: string): string[] {
    return value.split(',');
  }

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private uniqueIdService: UniqueIdService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initFormConfig();
    const sub = this.smacsFormsUpdate$.subscribe((update: SmacsFormsUpdate<SelectConfigEntity>) => {
      if (
        !isEqual(update.new.show, update.old.show) ||
        !isEqual(update.new.required, update.old.required) ||
        update.valid === SmacsFormsValidationState.INVALID
      ) {
        const formData = this.toFormData(update.new);
        this.possibleOptionsTextComponent.updateSelf(formData.possibleOptionsFormatted, true);
      }
    });
    this._subs.add(sub);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.oneClickEnabled) {
      this.validateAllFields();
    }
    if (changes.disabled && this.possibleOptionsTextComponent) {
      this.possibleOptionsTextComponent.applyState({
        ...this.possibleOptionsTextComponent.state,
        disabled: changes.disabled.currentValue,
      });
      this.requiredCheckboxComponent.applyState({
        ...this.requiredCheckboxComponent.state,
        disabled: changes.disabled.currentValue,
      });
      this.showCheckboxComponent.applyState({
        ...this.showCheckboxComponent.state,
        disabled: changes.disabled.currentValue,
      });
    }
    if (changes.isAlwaysRequired && this.requiredCheckboxComponent) {
      this.requiredCheckboxComponent.applyState({
        ...this.requiredCheckboxComponent.state,
        disabled: this.getRequiredDisabledState(),
      });
    }
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  openModal() {
    if (this.disabled) {
      return;
    }
    // Get the options not part of defaultValues.
    const formattedPossibleOptions = differenceWith(this.entity.possibleOptions, this.entity.defaultValues, isEqual);
    const modalInstance = this.smacsModalService.openCustomModal(
      EditCustomSelectModalComponent,
      {
        configuredOptions: [...formattedPossibleOptions],
        availableOptions: [...this.availableOptions],
        defaultOptions: [...this.entity.defaultValues],
        modalHeaderKey: this.displayLabel,
        isMultiSelect: this.isMultiSelect,
        isPreviewVisible: true,
        translations: {
          ...this.translations,
          available: 'tkey;site_management.site.section.select.modal.available_values.label',
          show: 'tkey;site_management.site.section.select.modal.show_values.label',
          default: 'tkey;custom_select.default_value.modal.label',
        },
      },
      'xl'
    );

    modalInstance.subscribe((newValues: { configuredOptions: string[]; defaultOptions: string[] }) => {
      if (newValues) {
        this.entitySource.next({
          ...this.entity,
          defaultValue: newValues.defaultOptions[0],
          defaultValues: newValues.defaultOptions,
          possibleOptions: [...newValues.defaultOptions, ...newValues.configuredOptions],
        });

        this.validateAllFields();
      }
    });
  }

  onInputClicked() {
    this.openModal();
  }

  arrayToString(values: string[]): string {
    return values ? values.join(', ') : '';
  }

  getTextValidationState(): SmacsFormsValidationState {
    return this.possibleOptionsTextComponent?.validationState;
  }

  getDisabledState(): boolean {
    return this.disabled;
  }

  getRequiredDisabledState(): boolean {
    return this.isAlwaysRequired ? true : this.disabled;
  }

  protected toEntity = (formData: SelectConfigFormData): SelectConfigEntity => {
    return {
      ...omit(formData, ['possibleOptionsFormatted']),
    } as SelectConfigEntity;
  };

  protected toFormData = (entity: SelectConfigEntity): SelectConfigFormData => {
    // check if a defaultValue is not present in possibleOptions. (When misconfigured values are present, I guess)
    const valuesNotInPossibleOptions = differenceWith(entity.defaultValues, entity.possibleOptions);
    const configuredOptions =
      valuesNotInPossibleOptions.length > 0
        ? [...valuesNotInPossibleOptions, ...entity.possibleOptions]
        : [...entity.possibleOptions];
    return {
      ...entity,
      possibleOptionsFormatted: this.arrayToString(configuredOptions),
    } as SelectConfigFormData;
  };

  protected submit() {
    return of(null);
  }

  private _getLabel(): string {
    if (this.formData.required) {
      return `<span class="text-danger smacs-forms-asterisk">*</span> ${this.translateService.instant(
        this.displayLabel
      )}`;
    } else {
      return this.displayLabel;
    }
  }

  private _getSelectOptionsValidation(selectValues: string[], validationTarget: string): string[] {
    return selectValues.filter((val: string) => {
      const validationState = this._validators.invalidOptionValidator(val, this.availableOptions);
      return validationTarget === 'VALID'
        ? validationState === SmacsFormsValidationState.VALID
        : validationState === SmacsFormsValidationState.INVALID;
    });
  }

  private _initFormConfig() {
    const id = this.uniqueIdService.getUniqueId();

    this.formConfig = {
      fields: {
        possibleOptionsFormatted: {
          id: `select-config-form-possible-options-${id}`,
          dataAutomation: `select-config-form-possible-options`,
          label: () => this._getLabel(),
          labelToolTipText: this.labelToolTipText,
          labelToolTipIconClass: this.labelToolTipIconClass,
          required: () => false,
          validation: [
            {
              validator: (value, required) => {
                return this._validators.requiredValidator(this.formData?.defaultValue, required);
              },
              message: () => 'tkey;custom_select.required.all_defaults_required.error',
              injectValuesFromFields: ['required'],
            },
            {
              validator: () => {
                if (this.isMultiSelect) {
                  const formValuesMulti = union([...this.formData.defaultValues, ...this.formData.possibleOptions]);
                  return this._getSelectOptionsValidation(formValuesMulti, 'INVALID').length
                    ? SmacsFormsValidationState.INVALID
                    : SmacsFormsValidationState.VALID;
                }
                const formValuesSingle = union([...this.formData.possibleOptions, this.formData.defaultValue]);
                return this._getSelectOptionsValidation(formValuesSingle, 'INVALID').length
                  ? SmacsFormsValidationState.INVALID
                  : SmacsFormsValidationState.VALID;
              },
              message: () => {
                const formValuesMulti = union([...this.formData.defaultValues, ...this.formData.possibleOptions]);
                const formValuesSingle = union([...this.formData.possibleOptions, this.formData.defaultValue]);
                const invalidOptions = this.isMultiSelect
                  ? this._getSelectOptionsValidation(formValuesMulti, 'INVALID')
                  : this._getSelectOptionsValidation(formValuesSingle, 'INVALID');
                const translation = this.translateService.instant('tkey;select.invalid_options.label');

                return `${translation} ${this.arrayToString(invalidOptions as string[])}`;
              },
            },
            {
              validator: (value, show) => {
                if (!show && this.formData?.defaultValue && SelectConfigFormComponent._getValues(value).length > 1) {
                  return SmacsFormsValidationState.WARNING;
                }

                return SmacsFormsValidationState.VALID;
              },
              message: 'tkey;custom_select.show.multiple_values.warning',
              injectValuesFromFields: ['show'],
            },
          ],
          autogeneration: {
            linkLabel: 'tkey;shared.html.customizable_fields.text_input.fix_it.label',
            generateValue: (fieldValue) => {
              const validationStateSingleSelect =
                this._validators.invalidOptionValidator(this.formData.defaultValue, this.availableOptions) ===
                SmacsFormsValidationState.VALID;
              const defaultValues = this.isMultiSelect
                ? this._getSelectOptionsValidation(this.formData.defaultValues, 'VALID')
                : [];
              const defaultValue = this.isMultiSelect
                ? defaultValues[0]
                : validationStateSingleSelect
                ? this.formData.defaultValue
                : '';
              const possibleOptions = this._getSelectOptionsValidation(this.formData.possibleOptions, 'VALID');

              this.entitySource.next({
                ...this.formData,
                defaultValues: defaultValues,
                defaultValue: defaultValue,
                possibleOptions: intersectionWith(possibleOptions, this.availableOptions),
              });

              return fieldValue;
            },
            hidden: () => {
              const invalidPossibleOptions = this._getSelectOptionsValidation(this.formData.possibleOptions, 'INVALID');
              if (this.isMultiSelect) {
                const invalidDefaultValues = this._getSelectOptionsValidation(this.formData.defaultValues, 'INVALID');

                return invalidDefaultValues.length === 0 && invalidPossibleOptions.length === 0;
              } else {
                const validationState = this._validators.invalidOptionValidator(
                  this.formData.defaultValue,
                  this.availableOptions
                );

                return validationState !== SmacsFormsValidationState.INVALID && invalidPossibleOptions.length === 0;
              }
            },
            inline: true,
          },
          disabled: () => this.getDisabledState(),
          disabledTooltip: this.disabledTooltip,
        },
        required: {
          id: `select-config-form-required-${id}`,
          dataAutomation: `select-config-form-required`,
          label: () =>
            this.isAlwaysRequired
              ? 'tkey;site_management.site.section.required.always.label'
              : 'tkey;site_management.site.section.required.label',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX }),
          hidden: () => !this.isRequiredVisible,
          disabled: () => this.getRequiredDisabledState(),
          disabledTooltip: this.disabledTooltip,
        },
        show: {
          id: `select-config-form-show-${id}`,
          dataAutomation: `select-config-form-show`,
          label: 'tkey;site_management.site.section.show.label',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.LEFT_ALIGNED_CHECKBOX }),
          disabled: () => this.getDisabledState(),
          disabledTooltip: this.disabledTooltip,
        },
      },
    };
  }
}
