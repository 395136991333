import { Component, OnDestroy, OnInit } from '@angular/core';
import { SamlSpMetadataContext } from '../../contexts/saml-sp-metadata.context';
import { SamlIdpMetadataContext } from '../../contexts/saml-idp-metadata.context';
import { SamlSsoConfig, Timestamp } from '../../../shared/models/generated/smacsModels';
import { Observable, Subscription } from 'rxjs';
import { ToastService } from '../../../shared/services/toast.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../button/button.component';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { SamlSsoConfigContext } from '../../contexts/saml-sso-config.context';
import { GlobalPropertiesContext } from '../../../shared/contexts/global-properties.context';

@Component({
  selector: 'app-admin-saml',
  templateUrl: './saml.component.html',
  styleUrls: ['../../admin-page.scss'],
  providers: [SamlIdpMetadataContext, SamlSpMetadataContext],
})
export class SamlComponent implements OnInit, OnDestroy {
  private _loadedSpMetaData = false;
  private _loadedIdpMetadata = false;
  private _loadedSamlConfigData = false;
  isHostedEnabled = false;
  private _subscriptions = new Subscription();
  isLoading = true;
  samlConfig: SamlSsoConfig = { ssoEnabled: false };
  samlSpMetaDataTimestamp: Timestamp = { timestamp: '' };
  samlIdpMetaDataTimestamp: Timestamp = { timestamp: '' };
  samlSpDate: Date = null;
  samlIdpDate: Date = null;
  samlSpGetMetadataXML: () => Observable<void>;
  samlIdpGetMetadataXML: () => Observable<void>;
  samlIdpUpload: (formData: FormData) => Observable<void>;
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;

  constructor(
    private globalPropertiesContext: GlobalPropertiesContext,
    private samlSsoConfigContext: SamlSsoConfigContext,
    private samlSpMetadataContext: SamlSpMetadataContext,
    private samlIdpMetadataContext: SamlIdpMetadataContext,
    private toastService: ToastService,
    private breadcrumbsService: BreadcrumbsService
  ) {}

  ngOnInit(): void {
    const globalPropertiesSub = this.globalPropertiesContext.state$.subscribe((globalProperties) => {
      this.isHostedEnabled = globalProperties.hostedEnabled;
    });

    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.saml.title' }]);
    this.samlSpGetMetadataXML = this.samlSpMetadataContext.getMetadataXML;
    this.samlIdpGetMetadataXML = this.samlIdpMetadataContext.getMetadataXML;
    this.samlIdpUpload = this.samlIdpMetadataContext.uploadMetadata;

    const samlSPMetadataSubscription = this.samlSpMetadataContext.state$.subscribe((timestamp: Timestamp) => {
      this.samlSpMetaDataTimestamp = timestamp;
      this.samlSpDate = timestamp.timestamp ? this._parseDate(timestamp) : null;
      this._loadedSpMetaData = true;
      this._setIsLoading();
    });

    const samlIdpMetadataSubscription = this.samlIdpMetadataContext.state$.subscribe((timestamp: Timestamp) => {
      this.samlIdpMetaDataTimestamp = timestamp;
      this.samlIdpDate = timestamp.timestamp ? this._parseDate(timestamp) : null;
      this._loadedIdpMetadata = true;
      this._setIsLoading();
    });

    const ssoEnabledContextSubscription = this.samlSsoConfigContext.state$.subscribe((samlConfig) => {
      this.samlConfig = samlConfig;
      this._loadedSamlConfigData = true;
      this._setIsLoading();
    });

    this._subscriptions.add(globalPropertiesSub);
    this._subscriptions.add(samlSPMetadataSubscription);
    this._subscriptions.add(samlIdpMetadataSubscription);
    this._subscriptions.add(ssoEnabledContextSubscription);
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }

  onEnabledToggled() {
    this.samlSsoConfigContext.set(this.samlConfig).subscribe(() => {
      this.toastService.push(
        ToastTypes.SUCCESS,
        this.smacsIcons.OK,
        this.samlConfig.ssoEnabled ? 'tkey;admin.saml.toast.enabled.title' : 'tkey;admin.saml.toast.disabled.title',
        this.samlConfig.ssoEnabled
          ? 'tkey;admin.saml.toast.enabled.description'
          : 'tkey;admin.saml.toast.disabled.description'
      );
    });
  }

  private _setIsLoading() {
    this.isLoading = !(this._loadedIdpMetadata && this._loadedSamlConfigData && this._loadedSamlConfigData);
  }

  private _parseDate(timestamp: Timestamp) {
    const formattedDateArray = timestamp.timestamp.replace(/[-.]/g, ' ').split(' ');
    const formattedUtcDate = Date.UTC.apply(this, formattedDateArray);
    const formattedDate = new Date(formattedUtcDate);
    formattedDate.setMonth(formattedDate.getMonth() - 1);
    return formattedDate;
  }

  handleInvalidImportTypeError() {
    this.toastService.push(
      ToastTypes.ERROR,
      this.smacsIcons.DOWNLOAD,
      'tkey;admin.saml.toast.invalid.title',
      'tkey;admin.saml.toast.invalid.description'
    );
  }

  handleSuccessfulFileImport() {
    this.toastService.push(
      ToastTypes.SUCCESS,
      this.smacsIcons.DOWNLOAD,
      'tkey;admin.saml.toast.success.title',
      'tkey;admin.saml.toast.success.description'
    );
  }
}
