import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdAttributes, MicrosoftOnPremAdAttributesFieldConfig } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class MicrosoftOnPremAdResource {
  constructor(private http: HttpClient) {}

  getOnPremAttributesFieldConfig(
    upn: string,
    did: string,
    extension?: string
  ): Observable<MicrosoftOnPremAdAttributesFieldConfig> {
    const params = new URLSearchParams(
      !!extension
        ? {
            upn,
            did,
            ext: extension,
          }
        : { upn, did }
    );
    const path = `/services/microsoft/field-configs/on-prem-ad-attributes?${params.toString()}`;

    return this.http.get<MicrosoftOnPremAdAttributesFieldConfig>(path);
  }

  getAdAttributes(upn: string, dialPlanId: number): Observable<AdAttributes> {
    return this.http.get<AdAttributes>(
      `/services/microsoft/macs/on-prem-ad-attributes/${upn}/dial-plans/${dialPlanId}`
    );
  }

  putAdAttributes(upn: string, dialPlanId: number, body: AdAttributes): Observable<void> {
    return this.http.put<void>(`/services/microsoft/macs/on-prem-ad-attributes/${upn}/dial-plans/${dialPlanId}`, body);
  }
}
