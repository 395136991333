<div class="container-fluid">
  <div class="modal-header">
    <legend class="modal-title">
      <strong>{{ audit.category }}</strong>
      | {{ audit.type }}
    </legend>
    <button type="button" class="btn-close" aria-label="Close" (click)="onOkClick()"></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <span class="float-end">
          {{ audit.timestamp | date : 'medium' }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h5>
          {{ 'tkey;admin.audits.logs.json.view.modified_by.label' | translate }}
          <strong>{{ audit.userId }}</strong>
        </h5>
      </div>
    </div>

    <div class="row" *ngIf="singleJsonView">
      <div class="col-md-12">
        <div class="audit-details-body single-json-view">
          <div [innerHTML]="singleJsonView"></div>
        </div>
      </div>
    </div>

    <div *ngIf="unifiedDiff || splitLeft || splitRight" class="json-diff">
      <div class="row">
        <div class="col-md-12">
          <form class="d-flex justify-content-end">
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                class="form-check-input"
                id="toggleViewCheckbox"
                [(ngModel)]="isUnifiedDiff"
                (ngModelChange)="toggleViewType()"
                [ngModelOptions]="{ standalone: true }"
              />
              <label class="form-check-label" for="toggleViewCheckbox" data-automation="is-unified-diff">
                {{ 'tkey;admin.audits.logs.json.view.unified_diff.label' | translate }}
              </label>
            </div>
            <div class="form-check form-check-inline">
              <input
                type="checkbox"
                class="form-check-input"
                id="showAllCheckbox"
                [(ngModel)]="showAllFields"
                (ngModelChange)="toggleUnchanged()"
                [ngModelOptions]="{ standalone: true }"
              />
              <label class="form-check-label" for="showAllCheckbox" data-automation="show-all-fields">
                {{ 'tkey;admin.audits.logs.json.view.show_all_fields.label' | translate }}
              </label>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12" *ngIf="unifiedDiff">
          <div [innerHTML]="unifiedDiff" class="audit-details-body-split-view"></div>
        </div>

        <div class="left-diff col-md-6" *ngIf="splitLeft">
          <div [innerHTML]="splitLeft" class="audit-details-body-split-view"></div>
        </div>
        <div class="right-diff col-md-6" *ngIf="splitRight">
          <div [innerHTML]="splitRight" class="audit-details-body-split-view"></div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="text-center">
      <button type="button" class="btn btn-lg btn-primary ok-button ok-modal-click" (click)="onOkClick()">
        <span translate="tkey;dialogs.button.ok"></span>
      </button>
    </div>
  </div>
</div>
