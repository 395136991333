<smacs-fieldset-header [legend]="'tkey;snr.snrprofile.label'">
  <smacs-text fieldId="profileName"></smacs-text>
  <smacs-text fieldId="profileDescription"></smacs-text>
  <smacs-select fieldId="aarCallingSearchSpace"></smacs-select>
  <smacs-select fieldId="callingSearchSpace"></smacs-select>
  <smacs-select fieldId="reroutingCallingSearchSpace"></smacs-select>
  <smacs-select fieldId="userMohAudioSource"></smacs-select>
  <smacs-select fieldId="networkMohAudioSource"></smacs-select>
  <smacs-select fieldId="privacy"></smacs-select>
</smacs-fieldset-header>
