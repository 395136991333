<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1 pb-2 smacs-report-content" *ngIf="!isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <p>{{ 'tkey;reporting.microsoft.dial_plan_inventory.about_1' | translate }}</p>
        <p>
          <smacs-button
            [label]="'tkey;reporting.microsoft.dial_plan_inventory.export_to_excel'"
            (clicked)="onExportClick()"
            [buttonStyle]="buttonStyles.LINK"
          ></smacs-button>
          {{ 'tkey;reporting.microsoft.dial_plan_inventory.about_2' | translate }}
        </p>
        <div [innerHTML]="'tkey;reporting.microsoft.dial_plan_inventory.about_3' | translate"></div>
        <p class="mb-0">
          <strong>{{ 'tkey;reporting.microsoft.dial_plan_inventory.about_4' | translate }}</strong>
          {{ 'tkey;reporting.microsoft.dial_plan_inventory.about_5' | translate }}
          <smacs-button
            [label]="'tkey;reporting.microsoft.dial_plan_inventory.refresh_dial_plan_data'"
            (clicked)="onRefreshClick()"
            [buttonStyle]="buttonStyles.LINK"
          ></smacs-button>
          {{ 'tkey;reporting.microsoft.dial_plan_inventory.about_6' | translate }}
        </p>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <smacs-datatable
      [columns]="datatableCols"
      [data]="datatableRows"
      [mainFilterPlaceholder]="'tkey;reporting.microsoft.dial_plan_inventory.filter.text.placeholder' | translate"
      [maxLength]="150"
      [dataAutomation]="tableDataAutomation"
    >
      <smacs-datatable-multi-select-filter
        [label]="'tkey;admin.dn_report.available.filter.label'"
        [items]="availabilityFilterOptionsTexts"
        [matchFn]="isAvailableNumbersMatch"
        [dataAutomation]="'dn-inventory-filter'"
        [tableId]="tableDataAutomation"
      ></smacs-datatable-multi-select-filter>
    </smacs-datatable>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
