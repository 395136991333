import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ClustersResource } from '../../services/clusters.resource';
import { ActivatedRoute } from '@angular/router';
import { Cluster, ClusterResult, Server } from '../../../../../shared/models/generated/smacsModels';
import { ServersContext } from '../../../../contexts/servers.context';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { SiteSummaryContext } from '../../../../../shared/contexts/site-summary.context';
import { BreadcrumbsService } from '../../../../../shared/breadcrumbs/breadcrumbs.service';
import { SmacsFormsUpdate } from '../../../../../forms/smacs-forms-models';
import { ClusterFormComponent } from './cluster-form/cluster-form.component';

@Component({
  selector: 'smacs-cluster',
  templateUrl: './cluster.component.html',
})
export class ClusterComponent implements OnInit, OnDestroy {
  @ViewChild(ClusterFormComponent) clusterFormComponent: ClusterFormComponent;

  isLoading = true;
  initialCluster: Cluster;
  cluster: Cluster;
  servers: Server[];
  allClusters: ClusterResult[];

  private _subscription = new Subscription();

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private clustersResource: ClustersResource,
    private route: ActivatedRoute,
    private serversContext: ServersContext,
    private siteSummaryContext: SiteSummaryContext
  ) {}

  ngOnInit() {
    const clusterId = this.route.snapshot.params.clusterId;
    let cluster$: Observable<Cluster>;

    if (clusterId) {
      cluster$ = this.clustersResource.get(clusterId);
    } else {
      cluster$ = of({
        id: undefined,
        name: '',
        cucmServerId: null,
        cucmServerDescription: '',
        impServerId: null,
        impServerDescription: '',
        pcceServerId: null,
        pcceServerDescription: '',
      });
    }

    const sub = combineLatest([cluster$, this.serversContext.state$, this.siteSummaryContext.state$]).subscribe(
      ([cluster, servers, siteSummary]) => {
        this._updateCrumbs(cluster);
        this.initialCluster = cluster;
        this.cluster = cluster;
        this.servers = servers;
        this.allClusters = siteSummary.clusters;
        this.isLoading = false;
      }
    );
    this._subscription.add(sub);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  onFormUpdate(update: SmacsFormsUpdate<Cluster>) {
    this.cluster = update.new;
    if (update.new.id) {
      this._updateCrumbs(update.new);
    }
  }

  private _updateCrumbs(cluster: Cluster) {
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;admin.site_management.cluster_sites.header',
        url: '/admin/site-management/clusters',
        routerLink: true,
      },
      {
        label: cluster?.id ? cluster.name : 'tkey;admin.site_management.clusters.new_cluster',
      },
    ]);
  }
}
