import { Component, OnDestroy, OnInit } from '@angular/core';
import { EmailTemplatesContext } from '../../../contexts/email-templates.context';
import { EmailTemplate } from '../../../../shared/models/generated/smacsModels';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../../shared/entity-table/entity-table.models';
import { TranslateService } from '@ngx-translate/core';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'smacs-email-templates-entity-table',
  templateUrl: 'email-templates-entity-table.component.html',
  styleUrls: ['../../../admin-page.scss'],
})
export class EmailTemplatesEntityTableComponent implements OnInit, OnDestroy {
  isLoading = true;

  table: EntityTable = {
    hasActions: true,
    cssClearButtonColumnSize: 'col-sm-2',
    columns: [
      {
        columnId: 'name',
        cssColumnSize: 'col-sm-5',
        label: 'tkey;admin.email_templates.table_view.table.headers.name',
        filter: { type: EntityTableFilterTypes.TEXT },
      },
      {
        columnId: 'recipients',
        cssColumnSize: 'col-sm-5',
        label: 'tkey;admin.email_templates.table_view.table.headers.recipients',
        filter: {
          type: EntityTableFilterTypes.TEXT,
          filterFn: (rows, filterValue) => {
            return rows.filter((row) => {
              return row.content.recipients.some((recipient: string) =>
                recipient.toLowerCase().includes(filterValue.toLowerCase())
              );
            });
          },
        },
      },
    ],
  };
  tableRows: EntityTableContentRow[];

  private _subscription = new Subscription();

  constructor(
    private emailTemplatesContext: EmailTemplatesContext,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private smacsModalService: SmacsModalService,
    private breadcrumbsService: BreadcrumbsService,
    private bottomNavService: BottomNavService
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.email_templates.title' }]);
    this._initBottomNav();

    const templateStateSub = this.emailTemplatesContext.state$.subscribe((templates) => {
      this._buildTableRows(templates);
      this.isLoading = false;
    });
    this._subscription.add(templateStateSub);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private _buildTableRows(emailTemplates: EmailTemplate[]) {
    const endUserRecipientLabel = this.translateService.instant(
      'tkey;admin.email_templates.table_view.table.360_view_recipient_badge'
    );

    this.tableRows = emailTemplates.map((emailTemplate) => {
      const emailRecipients = emailTemplate.is360ViewEndUserRecipient
        ? emailTemplate.otherRecipients.concat([endUserRecipientLabel])
        : emailTemplate.otherRecipients;
      return {
        content: {
          name: emailTemplate.emailTemplateName,
          recipients: emailRecipients,
        },
        badges: {
          recipients: {
            maxLength: 30,
            values: emailRecipients,
          },
        },
        actions: [
          {
            buttonStyle: ButtonStyles.DEFAULT,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/template/${emailTemplate.id}/copy`,
            icon: SmacsIcons.COPY,
            dataAutomation: 'email-templates-entity-table-copy-button',
            onClick: () => this.router.navigate(['template', emailTemplate.id, 'copy'], { relativeTo: this.route }),
          },
          {
            buttonStyle: ButtonStyles.PRIMARY,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/template/${emailTemplate.id}`,
            icon: SmacsIcons.EDIT,
            dataAutomation: 'email-templates-entity-table-edit-button',
            onClick: () => this.router.navigate(['template', emailTemplate.id], { relativeTo: this.route }),
          },
          {
            buttonStyle: ButtonStyles.DANGER,
            icon: SmacsIcons.DELETE,
            dataAutomation: 'email-templates-entity-table-delete-button',
            onClick: () => this.emailTemplatesContext.deleteTemplate(emailTemplate),
          },
        ],
      };
    });
  }

  private _initBottomNav() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'email-templates-add-button',
          label: 'tkey;admin.email_templates.table_view.add_button.label',
          icon: SmacsIcons.PLUS_CIRCLE,
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'email-templates-add-button',
          cb: () => {
            this._addTemplate();
          },
        },
      ])
    );
  }

  private _addTemplate() {
    this.router.navigate(['template'], { relativeTo: this.route });
  }
}
