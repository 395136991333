<fieldset [disabled]="state?.disabled">
  <smacs-navset-form
    *ngIf="isReady"
    [hidden]="state.hidden && (!forceShow || !state.defaultValue)"
    [attr.data-automation]="config.dataAutomation"
    [allowDuplicate]="allowDuplicate"
    [dropdownItems]="dropdownItems"
    [dropdownLabel]="dropdownLabel"
    [emptyTemplateMessage]="emptyTemplateMessage"
    [navsetItemsConfig]="navsetItemsConfig"
    [tooltipMessage]="tooltipMessage"
    [upstreamEntity]="entity"
    [isFormSubmitted]="isFormSubmitted"
    [allowedDropdownItemNames]="allowedDropdownItems"
    [isOneClickAddDropdown]="isOneClickAddDropdown"
    (smacsFormsUpdate$)="handleUpdate($event)"
  ></smacs-navset-form>
</fieldset>
