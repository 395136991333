import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-self-serve-user-details-header',
  templateUrl: './self-serve-user-details-header.component.html',
})
export class SelfServeUserDetailsHeaderComponent {
  @Input() heading: string;
  @Input() headingDataAutomation: string;
}
