import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, ReplaySubject, Subscription } from 'rxjs';
import { MicrosoftDialPlanGroup } from '../../shared/models/generated/smacsModels';
import { ModelContextAbstract } from '../../shared/contexts/abstract/model.context.abstract';
import { delayWhen, map } from 'rxjs/operators';
import { GlobalPropertiesContext } from '../../shared/contexts/global-properties.context';
import { MicrosoftDialPlansFieldConfigContext } from '../../shared/contexts/microsoft-dial-plans-field-config.context';

@Injectable()
export class MsDialPlanGroupsContext
  extends ModelContextAbstract<MicrosoftDialPlanGroup | number>
  implements OnDestroy
{
  _baseUrl = '/services/microsoft/admin/dial-plans';
  _stateSource = new ReplaySubject<MicrosoftDialPlanGroup[]>(1);
  state$ = this._stateSource.asObservable();

  private _subscriptions = new Subscription();

  _getAll: () => Observable<MicrosoftDialPlanGroup[]>;
  _put: (id: string, body: MicrosoftDialPlanGroup) => Observable<MicrosoftDialPlanGroup>;
  _post: (body: MicrosoftDialPlanGroup) => Observable<number>;
  _delete: (id: string) => Observable<void>;

  constructor(
    protected httpClient: HttpClient,
    private globalPropertiesContext: GlobalPropertiesContext,
    private microsoftDialPlansFieldConfigContext: MicrosoftDialPlansFieldConfigContext
  ) {
    super(httpClient);
    const sub = this.globalPropertiesContext.state$.subscribe((globalProperties) => {
      if (globalProperties.hostedEnabled) {
        this.getAll().subscribe();
      }
    });
    this._subscriptions.add(sub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  getAll(): Observable<void> {
    return this._getAll().pipe(
      map((groups) => {
        this._stateSource.next(groups);
      })
    );
  }

  post(group: MicrosoftDialPlanGroup): Observable<number> {
    return this._post(group).pipe(
      delayWhen(() => forkJoin([this.getAll(), this.microsoftDialPlansFieldConfigContext.init()]))
    );
  }

  put(group: MicrosoftDialPlanGroup): Observable<MicrosoftDialPlanGroup> {
    return this._put(group.id, group).pipe(
      delayWhen(() => forkJoin([this.getAll(), this.microsoftDialPlansFieldConfigContext.init()]))
    );
  }

  delete(groupId: string): Observable<void> {
    return this._delete(groupId.toString()).pipe(
      delayWhen(() => forkJoin([this.getAll(), this.microsoftDialPlansFieldConfigContext.init()]))
    );
  }

  save(body: MicrosoftDialPlanGroup): Observable<void> {
    if (body.id == null) {
      return this.post(body).pipe(map(() => null));
    } else {
      return this.put(body).pipe(map(() => null));
    }
  }
}
