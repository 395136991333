import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Global360View } from '../models/generated/smacsModels';

@Injectable()
export class Global360ViewResource {
  constructor(private http: HttpClient) {}

  get(username: string): Observable<Global360View> {
    return this.http.get<Global360View>(`/services/cisco/views/global-360-view/${encodeURIComponent(username)}`);
  }
}
