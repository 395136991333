import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MockCdrRequest } from './cdr-dump.component';
import { FileDownloadService } from '../shared/services/file-download.service';

@Injectable()
export class CdrDumpResource {
  constructor(private http: HttpClient, private fileDownloadService: FileDownloadService) {}

  generateCdrs(request: MockCdrRequest): Observable<void> {
    return this.fileDownloadService.downloadFile(
      `/services/cisco/reporting/cucm-cdrs/imports/dump-files/dummy-device-utilization-cdrs`,
      'post',
      request
    );
  }
}
