import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { combineLatest, forkJoin, Observable, of, Subscriber, Subscription } from 'rxjs';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../../shared/services/toast.service';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../forms/fields/select/smacs-select.component';
import { SmacsFormConfig, SmacsFormsMessage, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { switchMap } from 'rxjs/operators';
import {
  ClusterResult,
  DefaultLdapUserSiteAttribute,
  DevicePoolResult,
  ExtensionMobilityRef,
  PhoneRef,
  ServiceProvisioningLevel,
  SiteResult,
  SiteSummary,
  SnrProfileRef,
  TileConfig,
  VoicemailRef,
} from '../../../../shared/models/generated/smacsModels';
import { KnownPhoneModels, UserDetailUiContext } from '../../../shared/contexts/user-detail-ui.context';
import { SiteContext } from '../../../shared/contexts/site.context';
import { CurrentClusterContext } from '../../../../shared/contexts/current-cluster.context';
import { isArray } from 'lodash';
import { HttpClient } from '@angular/common/http';
import { LdapUserResource } from '../../../shared/resources/ldap-user.resource';
import { Global360ViewContext } from '../../../../shared/contexts/global-360-view.context';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { Nvp } from '../../../../shared/models/nvp';
import { AuditHeaderService } from '../../../../shared/services/audit-header.service';
import { ButtonStyles } from '../../../../button/button.component';
import { TileConfigContext } from '../../../shared/contexts/tile-config.context';

export interface ChangeSiteFormEntity {
  site: number;
}

interface ZiroService {
  devicePool?: string;
  icon: string;
  name: string;
  type: string;
  url: string;
  uuid: string;
}

@Component({
  selector: 'ziro-change-site-form',
  templateUrl: './change-site-form.component.html',
  styleUrls: ['./change-site-form.component.scss'],
  providers: [LdapUserResource],
})
export class ChangeSiteFormComponent
  extends SmacsFormAbstractDirective<ChangeSiteFormEntity>
  implements OnInit, OnDestroy
{
  @Input() sites: SmacsSelectOption[] = [];
  @Input() siteSummary: SiteSummary;
  @Input() cluster: ClusterResult;
  @Input() initialSite: SiteResult;

  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;
  isGettingDevicePools = false;
  isValidCluster = false;
  servicesToUpdate: ZiroService[] = [];
  servicesToDelete: ZiroService[] = [];
  selectedSite: SiteResult;
  ldapAttribute: DefaultLdapUserSiteAttribute;
  formConfig: SmacsFormConfig;

  private _subscriptions = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _toastService: ToastService,
    private _userDetailUiContext: UserDetailUiContext,
    private _siteContext: SiteContext,
    private _currentClusterContext: CurrentClusterContext,
    private _tileConfigContext: TileConfigContext,
    private _http: HttpClient,
    private _ldapUserResource: LdapUserResource,
    private _global360ViewContext: Global360ViewContext,
    private _auditHeaderService: AuditHeaderService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.formConfig = {
      fields: {
        site: {
          label: 'tkey;change.site.site.search.header',
          dataAutomation: 'change-site-form-site',
          componentConfig: new SmacsSelectConfig({ options: this.sites, bindValue: 'value' }),
          validation: [
            {
              validator: (val: number) => {
                const cluster = this._getClusterForSite(val);
                this.isValidCluster = !!cluster && cluster.id === this.cluster.id;
                if (!val || cluster.id !== this.cluster.id) {
                  return SmacsFormsValidationState.INVALID;
                }

                this._getFieldConfig(val);
                return SmacsFormsValidationState.VALID;
              },
              message: () => this._getValidationMessage(),
            },
          ],
        },
      },
      options: {
        columnClasses: {
          label: 'col-12 font-light',
          input: 'col-12',
        },
      },
    };
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  onCancelClicked() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  protected submit() {
    if (this.entity && this.entity.site) {
      return new Observable((subscriber: Subscriber<void>) => {
        const auditTags = [{ name: 'changeSite', value: `${this.initialSite.name} > ${this.selectedSite.name}` }];
        const audits = this._buildHttpOptions(auditTags);
        const updateRequests = this.servicesToUpdate.map((service: ZiroService) => {
          return this._http.get(service.url).pipe(
            switchMap((getResponse) => {
              return this._http.put(service.url, { ...getResponse, devicePool: service.devicePool }, audits);
            })
          );
        });
        const deleteRequests = this.servicesToDelete.map((service: ZiroService) => {
          return this._http.delete(service.url, audits);
        });
        const allRequests: Observable<any>[] = updateRequests.concat(deleteRequests);

        if (this._userDetailUiContext.getLdapUser() && this.ldapAttribute.site) {
          allRequests.push(
            this._ldapUserResource.putLdapUserSiteAttributesDefaults(
              {
                userPrincipalName: this._userDetailUiContext.getLdapUser().userPrincipalName,
                site: this.ldapAttribute.site,
              },
              auditTags
            )
          );
        }

        combineLatest(allRequests).subscribe({
          next: () => {
            this._userDetailUiContext.setHasChangedSite(this.selectedSite);
            this._global360ViewContext.init(this._userDetailUiContext.getCurrentEnduser().ref.username);
            const siteCtxSub = this._siteContext.state$.subscribe((siteCtxState) => {
              if (siteCtxState.name === this.selectedSite.name) {
                this._toastService.push(
                  ToastTypes.SUCCESS,
                  SmacsIcons.SITE,
                  'tkey;change.site.toast.title',
                  'tkey;change.site.toast.message',
                  { site: this.selectedSite.name }
                );
                this._router.navigate(['../'], { relativeTo: this._route });
                subscriber.next(null);
                subscriber.complete();
              }
            });
            this._subscriptions.add(siteCtxSub);
          },
        });
      });
    } else {
      return of(null);
    }
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this._auditHeaderService.buildHeader(auditTags) } : {};
  }

  private _getClusterForSite(siteId: number): ClusterResult {
    return this.siteSummary.clusters.find((clusterResult: ClusterResult) => {
      const site = clusterResult.sites.find((siteResult: SiteResult) => siteResult.id === siteId);

      if (site) {
        return clusterResult;
      }
    });
  }

  private _getValidationMessage(): SmacsFormsMessage {
    return {
      content: 'tkey;change_site.form.site.error.text',
      params: { clusterName: this.cluster.name },
    };
  }

  private _getFieldConfig(selectedSiteId: number) {
    this.isGettingDevicePools = true;
    this.servicesToUpdate = [];
    this.servicesToDelete = [];
    const reqs: Observable<any>[] = [this._tileConfigContext.getTileConfigs(selectedSiteId)];
    if (this._userDetailUiContext.getLdapUser()) {
      reqs.push(this._ldapUserResource.getLdapUserSiteAttributesDefaults(selectedSiteId));
    }

    forkJoin(reqs).subscribe({
      next: (data: [void, DefaultLdapUserSiteAttribute]) => {
        this._tileConfigContext.state$.subscribe((tileConfig: TileConfig) => {
          this.ldapAttribute = this._userDetailUiContext.getLdapUser() ? data[1] : null;
          const servicesDisabledOnSite = Object.entries(tileConfig)
            .filter(([k, v]) => {
              return v === ServiceProvisioningLevel.HIDE;
            })
            .map(([k]) => {
              return k;
            });
          const servicesEnabledOnSite = Object.entries(tileConfig)
            .filter(([k, v]) => {
              return v !== ServiceProvisioningLevel.HIDE;
            })
            .map(([k]) => {
              return k;
            });

          this.selectedSite = this.cluster.sites.find((sr: SiteResult) => sr.id === selectedSiteId);

          this._getUpdatedServices(servicesEnabledOnSite, this.selectedSite.devicePools);
          this._getDeletedServices(servicesDisabledOnSite);

          this.isGettingDevicePools = false;
        });
      },
    });
  }

  /**
   * Any services which are enabled on the new site, provisioned on the user, and device pool DOESN'T match
   */
  private _getUpdatedServices(servicesEnabledOnSite: string[], devicePools: DevicePoolResult[]) {
    servicesEnabledOnSite.forEach((serviceName: keyof TileConfig) => {
      const servicesWithoutDevicePool = ['extensionMobility', 'voicemail'];
      if (servicesWithoutDevicePool.includes(serviceName)) {
        return;
      }

      const provisionedDevices = this._getProvisionedDevicesByType(serviceName);

      if (
        (!isArray(provisionedDevices) && provisionedDevices !== undefined) ||
        (isArray(provisionedDevices) && provisionedDevices.length > 0)
      ) {
        if (serviceName === 'snrProfile') {
          const dp = this._getDevicePoolForService(serviceName, devicePools);
          const snrRef = provisionedDevices as SnrProfileRef;
          if (snrRef.devicePool !== dp.devicePool) {
            this.servicesToUpdate.push({
              uuid: snrRef.id,
              name: snrRef.name,
              type: this._getNameForService(serviceName),
              icon: this._getIconForService(serviceName),
              url: snrRef.url,
              devicePool: dp.devicePool,
            });
          }
        } else {
          const refs = provisionedDevices as PhoneRef[];
          refs.forEach((phoneRef: PhoneRef) => {
            const dp = this._getDevicePoolForService(serviceName, devicePools);
            if (phoneRef.devicePool !== dp.devicePool) {
              this.servicesToUpdate.push({
                uuid: phoneRef.id,
                name: phoneRef.name,
                type: this._getNameForService(serviceName),
                icon: this._getIconForService(serviceName),
                url: phoneRef.url,
                devicePool: dp.devicePool,
              });
            }
          });
        }
      }
    });
  }

  /**
   * Any services which are disabled on the new site, and provisioned on the user
   */
  private _getDeletedServices(servicesDisabledOnSite: string[]) {
    servicesDisabledOnSite.forEach((serviceName: keyof TileConfig) => {
      const provisionedDevices = this._getProvisionedDevicesByType(serviceName);
      if (
        (!isArray(provisionedDevices) && provisionedDevices !== undefined) ||
        (isArray(provisionedDevices) && provisionedDevices.length > 0)
      ) {
        if (!isArray(provisionedDevices)) {
          if (serviceName === 'snrProfile') {
            const typedProvisionedDevice = provisionedDevices as SnrProfileRef;
            this.servicesToDelete.push({
              uuid: typedProvisionedDevice.id,
              name: typedProvisionedDevice.name,
              type: this._getNameForService(serviceName),
              icon: this._getIconForService(serviceName),
              url: typedProvisionedDevice.url,
            });
          } else {
            const typedProvisionedDevice = provisionedDevices as VoicemailRef;
            this.servicesToDelete.push({
              uuid: typedProvisionedDevice.id,
              name: typedProvisionedDevice.alias,
              type: this._getNameForService(serviceName),
              icon: this._getIconForService(serviceName),
              url: typedProvisionedDevice.url,
            });
          }
        } else {
          if (serviceName === 'extensionMobility') {
            provisionedDevices.forEach((ref: ExtensionMobilityRef) => {
              this.servicesToDelete.push({
                uuid: ref.id,
                name: ref.name,
                type: this._getNameForService(serviceName),
                icon: this._getIconForService(serviceName),
                url: ref.url,
              });
            });
          } else {
            provisionedDevices.forEach((ref: PhoneRef | ExtensionMobilityRef) => {
              this.servicesToDelete.push({
                uuid: ref.id,
                name: ref.name,
                type: this._getNameForService(serviceName),
                icon: this._getIconForService(serviceName),
                url: ref.url,
              });
            });
          }
        }
      }
    });
  }

  private _getProvisionedDevicesByType(
    serviceName: keyof TileConfig
  ): PhoneRef[] | ExtensionMobilityRef[] | SnrProfileRef | VoicemailRef {
    switch (serviceName) {
      case 'android': {
        return this._userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.ANDROID);
      }
      case 'cipc': {
        return this._userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.CIPC);
      }
      case 'deskphone': {
        return this._userDetailUiContext.getDeskphones();
      }
      case 'imSoftphone': {
        return this._userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.IM_SOFTPHONE);
      }
      case 'iphone': {
        return this._userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.IPHONE);
      }
      case 'snrProfile': {
        return this._userDetailUiContext.getCurrentSnrProfile();
      }
      case 'tablet': {
        return this._userDetailUiContext.getCurrentPhonesByModel(KnownPhoneModels.TABLET);
      }
      case 'voicemail': {
        return this._userDetailUiContext.getCurrentVoicemail();
      }
      case 'extensionMobility': {
        return this._userDetailUiContext.getExtensionMobilities();
      }
      default: {
      }
    }
  }

  private _getDevicePoolForService(serviceName: keyof TileConfig, devicePools: DevicePoolResult[]): DevicePoolResult {
    switch (serviceName) {
      case 'android': {
        return devicePools.find((devicePoolResult: DevicePoolResult) => devicePoolResult.serviceName === 'Android');
      }
      case 'cipc': {
        return devicePools.find((devicePoolResult: DevicePoolResult) => devicePoolResult.serviceName === 'CIPC');
      }
      case 'deskphone': {
        return devicePools.find((devicePoolResult: DevicePoolResult) => devicePoolResult.serviceName === 'Desk Phone');
      }
      case 'imSoftphone': {
        return devicePools.find(
          (devicePoolResult: DevicePoolResult) => devicePoolResult.serviceName === 'IM Softphone'
        );
      }
      case 'iphone': {
        return devicePools.find((devicePoolResult: DevicePoolResult) => devicePoolResult.serviceName === 'IPhone');
      }
      case 'snrProfile': {
        return devicePools.find(
          (devicePoolResult: DevicePoolResult) => devicePoolResult.serviceName === 'Single Number Reach'
        );
      }
      case 'tablet': {
        return devicePools.find((devicePoolResult: DevicePoolResult) => devicePoolResult.serviceName === 'Tablet');
      }
      default: {
      }
    }
  }

  private _getIconForService(serviceName: keyof TileConfig): SmacsIcons {
    switch (serviceName) {
      case 'android': {
        return SmacsIcons.ANDROID;
      }
      case 'cipc': {
        return SmacsIcons.CIPC;
      }
      case 'deskphone': {
        return SmacsIcons.DESKPHONE;
      }
      case 'imSoftphone': {
        return SmacsIcons.SOFTPHONE;
      }
      case 'iphone': {
        return SmacsIcons.IPHONE;
      }
      case 'snrProfile': {
        return SmacsIcons.SNR;
      }
      case 'tablet': {
        return SmacsIcons.TABLET;
      }
      case 'voicemail': {
        return SmacsIcons.VOICEMAIL;
      }
      case 'extensionMobility': {
        return SmacsIcons.EXTENSION_MOBILITY;
      }
      default: {
      }
    }
  }

  private _getNameForService(serviceName: keyof TileConfig): string {
    switch (serviceName) {
      case 'android': {
        return 'tkey;shared.model.android.text';
      }
      case 'cipc': {
        return 'tkey;shared.model.cipc.text';
      }
      case 'deskphone': {
        return 'tkey;shared.model.deskphone.text';
      }
      case 'imSoftphone': {
        return 'tkey;shared.model.imsoftphone.text';
      }
      case 'iphone': {
        return 'tkey;shared.model.iphone.text';
      }
      case 'snrProfile': {
        return 'tkey;shared.service.snr.text';
      }
      case 'tablet': {
        return 'tkey;shared.model.tablet.text';
      }
      case 'voicemail': {
        return 'tkey;shared.service.voicemail.text';
      }
      case 'extensionMobility': {
        return 'tkey;shared.model.extension.mobility.text';
      }
      default: {
      }
    }
  }
}
