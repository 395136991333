<div class="container-fluid pb-5 adjusted-height">
  <legend data-automation="microsoft-365-licenses-header-legend">
    {{ 'tkey;selfserve.microsoft365.title' | translate }}
  </legend>
  <div class="row h-100">
    <div class="col-md-7 order-2 order-md-1 h-100 overflow-auto">
      <br *ngIf="licenseConfig.usageLocation.show" />
      <smacs-select [fieldId]="'usageLocation'"></smacs-select>
      <br />
      <smacs-multi-checkbox #licensesCheckboxes [fieldId]="'licenses'"></smacs-multi-checkbox>
    </div>
    <div class="col-md-5 order-1 order-md-2 microsoft-365-logo">
      <em [ngClass]="icons.MICROSOFT_365"></em>
    </div>
  </div>
</div>
