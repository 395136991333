<div
  class="row pb-5"
  [hidden]="
    !didSelectorFormData ||
    !didSelectorFormData.webexCallingLicense ||
    didSelectorFormData.webexCallingLicense === UCM_LICENSE_NAME
  "
>
  <div class="col-md-12 order-2 order-md-1">
    <smacs-fieldset-header [legend]="'tkey;helpdesk.webex_calling.extension_details.header'">
      <smacs-text [fieldId]="'extension'"></smacs-text>
    </smacs-fieldset-header>
  </div>
</div>
