import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PhoneButtonTemplateFieldConfigRequest, PhoneButtonTemplateFieldConfig } from '../models/generated/smacsModels';

@Injectable()
export class PhoneButtonTemplateFieldConfigResource {
  protected _baseUrl = '/services/cisco/field-configs/phone-button-templates';

  constructor(protected http: HttpClient) {}

  post(body: PhoneButtonTemplateFieldConfigRequest): Observable<PhoneButtonTemplateFieldConfig> {
    return this.http.post<PhoneButtonTemplateFieldConfig>(this._baseUrl, body);
  }
}
