<div data-automation="edit-server-modal">
  <div>
    <div class="row">
      <div class="col-md-12">
        <smacs-text [fieldId]="'description'"></smacs-text>

        <smacs-text [fieldId]="'hostAddress'"></smacs-text>

        <smacs-text [fieldId]="'port'"></smacs-text>

        <smacs-select [fieldId]="'serverType'"></smacs-select>

        <smacs-text [fieldId]="'username'"></smacs-text>

        <smacs-text [fieldId]="'password'"></smacs-text>

        <smacs-checkbox [fieldId]="'disabled'"></smacs-checkbox>

        <div
          class="alert alert-danger text-center animated shake"
          data-automation="server-modal-form-error-message"
          [hidden]="!isSubmitted || (isSubmitted && isFormValid() && !connectionError?.cause)"
        >
          <i class="fa fa-exclamation fa-lg"></i>
          <span
            id="server-connection-error"
            data-automation="server-connection-error"
            [hidden]="connectionError?.cause !== 'Connection Issue'"
          >
            {{
              'tkey;admin.servers.error.connection.text'
                | translate
                  : {
                      host: cachedFormData?.hostAddress,
                      port: cachedFormData?.port,
                      serverType: cachedFormData?.serverType
                    }
            }}
          </span>
          <span
            id="server-credential-error"
            data-automation="server-credential-error"
            [hidden]="connectionError?.cause !== 'Incorrect Credentials'"
          >
            {{ 'tkey;admin.servers.error.credentials.text' | translate }}
          </span>
          <span
            id="appuser-roles-error"
            data-automation="appuser-roles-error"
            [hidden]="connectionError?.cause !== 'Unauthorized (Missing Permissions)'"
          >
            {{ 'tkey;admin.servers.error.roles.text' | translate : { username: cachedFormData?.username } }}
          </span>
          <span
            id="other-errors"
            data-automation="other-errors"
            [hidden]="
              connectionError?.cause === 'Connection Issue' ||
              connectionError?.cause === 'Incorrect Credentials' ||
              connectionError?.cause === 'Unauthorized (Missing Permissions)' ||
              isFormValid()
            "
          >
            {{ 'tkey;validators.global.required.missing.error' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
