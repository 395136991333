import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { StatusCategory, SystemHealthStatus, WebexCallingSettings } from '../../../shared/models/generated/smacsModels';
import { of, Subscription, switchMap } from 'rxjs';
import { ToastService } from '../../../shared/services/toast.service';
import { WebexCallingSettingsContext } from '../../contexts/webex-calling-settings.context';
import { WebexCallingStatusContext } from '../../contexts/webex-calling-status.context';
import { WebexAuthenticationFormComponent } from './webex-authentication-form/webex-authentication-form.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { SystemStatusContext } from '../../../shared/contexts/system-status.context';

@Component({
  selector: 'ziro-webex-authentication',
  templateUrl: './webex-authentication.component.html',
  styleUrls: ['../../../admin/admin-page.scss'],
})
export class WebexAuthenticationComponent implements OnInit, OnDestroy {
  @ViewChild('authForm', { static: false }) authForm: WebexAuthenticationFormComponent;
  isLoading = true;
  callingSettings: WebexCallingSettings;
  isRefreshing = false;
  clientIdExists = false;
  pageDisabled = false;
  smacsIcons = SmacsIcons;
  pageDisabledMessage = '';
  private readonly inactiveSideError = 'This side is inactive';
  private _subscriptions = new Subscription();

  constructor(
    private _breadcrumbsService: BreadcrumbsService,
    private _webexCallingSettingsContext: WebexCallingSettingsContext,
    private _webexCallingStatusContext: WebexCallingStatusContext,
    private _toastService: ToastService,
    private _systemStatusCtx: SystemStatusContext
  ) {}

  ngOnInit(): void {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.webex_authentication.breadcrumb' }]);

    const sub = this._systemStatusCtx.state$
      .pipe(
        switchMap((systemHealthStatus) => {
          if (this.isDarkSide(systemHealthStatus)) {
            this.pageDisabledMessage = 'tkey;admin.webex_authentication.page_disabled';
            this.isLoading = false;
            this.pageDisabled = true;
            return of(null);
          } else {
            return this._webexCallingSettingsContext.state$;
          }
        })
      )
      .subscribe((callingSettings) => {
        if (callingSettings) {
          this.callingSettings = callingSettings;
          this.clientIdExists = !!this.callingSettings.clientId;
          this.isLoading = false;
        }
      });

    this._subscriptions.add(sub);
  }

  ngOnDestroy() {
    this._breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }

  isDarkSide(systemHealthStatus: SystemHealthStatus): boolean {
    const trafficStatusState = systemHealthStatus?.healthStatuses.find(
      (status) => status.category === StatusCategory.TRAFFIC
    )?.cause;
    return trafficStatusState === this.inactiveSideError;
  }
}
