import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../shared/services/toast.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { UiProperties } from '../../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig } from '../../../forms/smacs-forms-models';
import { SmacsFileUploadConfig } from '../../../forms/fields/file-upload/smacs-file-upload.component';
import { Observable, throwError } from 'rxjs';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ButtonSizes, ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CustomizeUiPreviewModalComponent } from './customize-ui-preview-modal.component';
import { CustomizeUiResource } from '../../../shared/resources/customize-ui.resource';
import { CustomUiOptionsContext } from '../../../shared/contexts/custom-ui-options.context';

export enum CustomizeUiPhotoTypes {
  LOGO = 'LOGO',
  BACKGROUND_IMAGE = 'BACKGROUND_IMAGE',
}

@Component({
  selector: 'app-admin-customize-ui',
  templateUrl: './customize-ui.component.html',
  styleUrls: ['./customize-ui.component.scss', '../../admin-page.scss'],
})
export class CustomizeUiComponent extends SmacsFormAbstractDirective<UiProperties> implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  customizeUiPhotoTypes = CustomizeUiPhotoTypes;
  isLoading = true;
  uiProperties: UiProperties;
  formConfig = {
    fields: {
      supportTextHtml: {
        dataAutomation: 'customize-ui-support-text',
      },
      logo: {
        dataAutomation: 'customize-ui-logo',
        label: 'tkey;admin.ui.form.logo.label',
        componentConfig: new SmacsFileUploadConfig({
          acceptedFileExtensions: { acceptedExtensions: '.jpg,.jpeg,.png' },
          maxSize: 20000,
        }),
      },
      backgroundImage: {
        dataAutomation: 'customize-ui-background-image',
        label: 'tkey;admin.ui.form.background_image.label',
        componentConfig: new SmacsFileUploadConfig({
          acceptedFileExtensions: { acceptedExtensions: '.jpg,.jpeg,.png' },
          maxSize: 20000,
        }),
      },
      backgroundColor: {
        dataAutomation: 'customize-ui-background-color',
        label: 'tkey;admin.ui.form.background_color.label',
        required: true,
      },
    },
  } as SmacsFormConfig;
  private defaultBackgroundColor = '#202020';

  constructor(
    private toastService: ToastService,
    private bottomNavService: BottomNavService,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    protected smacsFormStateService: SmacsFormStateService,
    private breadcrumbsService: BreadcrumbsService,
    private customizeUiResource: CustomizeUiResource,
    private _uiOptionsContext: CustomUiOptionsContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.ui.header' }]);
    this._initBottomNav();
    this.customizeUiResource.getUiProperties().subscribe((uiProperties: UiProperties) => {
      this.uiProperties = uiProperties;
      this.entitySource.next({
        supportTextHtml: uiProperties.supportTextHtml,
        backgroundColor: uiProperties.backgroundColor || this.defaultBackgroundColor,
      } as UiProperties);
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
  }

  onDeleteClick(type: CustomizeUiPhotoTypes) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        promptBody: this.translateService.instant('tkey;admin.ui.modal.delete.message'),
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => (type === 'LOGO' ? this._deleteLogo() : this._deleteBackgroundImage()),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  onPreviewClick(type: CustomizeUiPhotoTypes) {
    this.smacsModalService.openCustomModal(
      CustomizeUiPreviewModalComponent,
      {
        type: type,
        image: type === CustomizeUiPhotoTypes.LOGO ? this.uiProperties.logo : this.uiProperties.backgroundImage,
      },
      type === CustomizeUiPhotoTypes.LOGO ? 'lg' : 'xxl'
    );
  }

  private _deleteLogo(): Observable<void> {
    return new Observable((subscriber) => {
      this.customizeUiResource.deleteImage(CustomizeUiPhotoTypes.LOGO).subscribe(() => {
        this._getUiProperties().subscribe(() => this._showSuccessToast());
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private _deleteBackgroundImage(): Observable<void> {
    return new Observable((subscriber) => {
      this.customizeUiResource.deleteImage(CustomizeUiPhotoTypes.BACKGROUND_IMAGE).subscribe(() => {
        this._getUiProperties().subscribe(() => this._showSuccessToast());
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  protected submit() {
    return this._onFormSubmission();
  }

  private _onFormSubmission(): Observable<void> {
    this._setPending(true);

    const formData = new FormData();
    if (this.formData.logo) {
      formData.append('logo', this.formData.logo || null);
    }
    if (this.formData.backgroundImage) {
      formData.append('backgroundImage', this.formData.backgroundImage || null);
    }
    formData.append('backgroundColor', this.formData.backgroundColor);
    formData.append('supportTextHtml', this.formData.supportTextHtml);

    return this.customizeUiResource.saveUiProperties(formData).pipe(
      tap(() => {
        this.bottomNavService.dispatch(
          new BottomNavUpdateButtonState({
            id: 'customize-ui-save',
            state: {
              pending: false,
            },
          })
        );

        this._getUiProperties().subscribe(() => {
          this._showSuccessToast();
          this._setPending(false);
        });
      }),
      catchError((error) => {
        this._setPending(false);
        return throwError(() => error);
      })
    );
  }

  private _getUiProperties(): Observable<void> {
    return this.customizeUiResource.getUiProperties().pipe(
      switchMap((response: UiProperties) => {
        this.uiProperties = response;
        this.entitySource.next({
          supportTextHtml: response.supportTextHtml,
          backgroundColor: response.backgroundColor || this.defaultBackgroundColor,
        } as UiProperties);
        return this._uiOptionsContext.refresh();
      })
    );
  }

  private _initBottomNav() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'customize-ui-save',
          label: 'tkey;admin.ui.save',
          buttonClass: this.buttonStyles.PRIMARY,
          dataAutomation: 'customize-ui-save-button',
          state: {
            pending: false,
            buttonDisableState: { disabled: false, tooltipKey: '' },
          },
          icon: this.smacsIcons.OK,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );
  }

  private _setPending(setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'customize-ui-save',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  private _showSuccessToast() {
    this.toastService.push(
      ToastTypes.SUCCESS,
      this.smacsIcons.PREVIEW,
      'tkey;shared.toast.save.success.title',
      'tkey;admin.ui.toast.success.message'
    );
  }
}
