import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { Subscription } from 'rxjs';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  SmacsCheckboxConfig,
} from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { MobilityIdentity, MobilityIdentityFieldConfig } from '../../../../shared/models/generated/smacsModels';
import { PhoneUiContext } from '../../../../shared/phone-buttons/contexts/phone-ui.context';
import { TranslateService } from '@ngx-translate/core';
import { filter, first } from 'rxjs/operators';

export interface MobilityIdentityFormdata {
  id: string | null;
  formSwitch: boolean;
  name: string;
  destinationNumber: string;
  delayBeforeRingingInSeconds: number;
  delayStopRingingInSeconds: number;
}

@Component({
  selector: 'smacs-phone-mobility-identity',
  templateUrl: './phone-mobility-identity.component.html',
})
export class PhoneMobilityIdentityComponent
  extends SmacsFormAbstractDirective<MobilityIdentityFormdata>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() fieldConfig: MobilityIdentityFieldConfig;
  @Input() isExistingMobilityIdentity: boolean;
  formConfig: SmacsFormConfig;
  isFormSwitchChecked: boolean;

  private _validators = {
    nameFieldValidator: (input: string) =>
      /^[^\/%&<>"]+$/.test(input) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
    destinationValidator: (input: string) =>
      /^[0-9*+#]+$/.test(input) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
    beforeRingValidator: (val: string) =>
      Number(val) >= 0 && Number(val) <= 30 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
    stopRingValidator: (val: string) =>
      (Number(val) >= 10 && Number(val) <= 300) || val === '0'
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID,
  };
  private _subscriptions = new Subscription();
  constructor(
    smacsFormStateService: SmacsFormStateService,
    private phoneUiContext: PhoneUiContext,
    private translateService: TranslateService
  ) {
    super(smacsFormStateService);
    this.isFormSwitchChecked = this.isExistingMobilityIdentity;
    this.formConfig = {
      fields: {
        formSwitch: {
          label: 'tkey;pages.details.phoneSettings.mobility_identity',
          dataAutomation: 'mobility-identity-switch',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.LG }),
        },
        name: {
          label: 'tkey;pages.details.phoneSettings.mobility_identity.name',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          dataAutomation: 'mobility-identity-name',
          required: () =>
            (this.isExistingMobilityIdentity && this.fieldConfig?.name.required) ||
            (this.isExistingMobilityIdentity && !this.fieldConfig) ||
            this.fieldConfig?.name.required,
          hidden: () => !this.isFormSwitchChecked || (this.isFormSwitchChecked && !this.fieldConfig?.name.show),
          validation: [
            {
              validator: this._validators.nameFieldValidator,
              message: 'tkey;validators.global.error.pattern',
            },
            {
              validator: (val: string) => {
                return val.length > 50 ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
              },
              message: this.translateService.instant('tkey;validators.global.error.maxlength', { maxlength: 50 }),
            },
          ],
          defaultValue: () => this.fieldConfig.name.defaultValue,
        },
        destinationNumber: {
          label: 'tkey;pages.details.phoneSettings.mobility_identity.destination',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          dataAutomation: 'mobility-identity-destination',
          required: () =>
            (this.isExistingMobilityIdentity && this.fieldConfig?.destination.required) ||
            (this.isExistingMobilityIdentity && !this.fieldConfig) ||
            this.fieldConfig?.destination.required,
          hidden: () => !this.isFormSwitchChecked || (this.isFormSwitchChecked && !this.fieldConfig?.destination.show),
          validation: [
            {
              validator: (val: string) => {
                return val.length > 24 ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID;
              },
              message: this.translateService.instant('tkey;validators.global.error.maxlength', { maxlength: 24 }),
            },
            {
              validator: this._validators.destinationValidator,
              message: 'tkey;pages.details.phoneSettings.mobility_identity.destination.pattern_error',
            },
          ],
          defaultValue: () => this.fieldConfig.destination.defaultValue,
        },
        delayBeforeRingingInSeconds: {
          label: 'tkey;pages.details.phoneSettings.mobility_identity.delay_before_ring',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER, step: 0.1 }),
          dataAutomation: 'mobility-identity-delay-before-ringing',
          required: () =>
            (this.isExistingMobilityIdentity && this.fieldConfig?.delayBeforeRingInSeconds.required) ||
            (this.isExistingMobilityIdentity && !this.fieldConfig) ||
            this.fieldConfig?.delayBeforeRingInSeconds.required,
          hidden: () =>
            !this.isFormSwitchChecked || (this.isFormSwitchChecked && !this.fieldConfig?.delayBeforeRingInSeconds.show),
          validation: [
            {
              validator: this._validators.beforeRingValidator,
              message: 'tkey;pages.details.phoneSettings.mobility_identity.delay_before_ring.range_error',
            },
          ],
          defaultValue: () => Number(this.fieldConfig.delayBeforeRingInSeconds.defaultValue),
        },
        delayStopRingingInSeconds: {
          label: 'tkey;pages.details.phoneSettings.mobility_identity.stop_ringing_phone_delay',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER, step: 0.1 }),
          dataAutomation: 'mobility-identity-delay-stop-ringing',
          required: () =>
            (this.isExistingMobilityIdentity && this.fieldConfig?.stopRingingPhoneDelayInSeconds.required) ||
            (this.isExistingMobilityIdentity && !this.fieldConfig) ||
            this.fieldConfig?.stopRingingPhoneDelayInSeconds.required,
          hidden: () =>
            !this.isFormSwitchChecked ||
            (this.isFormSwitchChecked && !this.fieldConfig?.stopRingingPhoneDelayInSeconds.show),
          validation: [
            {
              validator: this._validators.stopRingValidator,
              message: 'tkey;pages.details.phoneSettings.mobility_identity.stop_ringing_phone_delay.range_error',
            },
          ],
          defaultValue: () => Number(this.fieldConfig.stopRingingPhoneDelayInSeconds.defaultValue),
        },
      },
    };
  }

  ngOnInit(): void {
    const formUpdateSub = this.smacsFormsUpdate$.subscribe((update) => {
      this.isFormSwitchChecked = update.new.formSwitch;
      this.fieldComponents.forEach((fieldComponent) => {
        if (fieldComponent.config.componentConfig instanceof SmacsTextConfig) {
          this.fieldChannels[fieldComponent.fieldId].stateSource.next({
            ...fieldComponent.state,
            hidden: fieldComponent.config.hidden() ?? !this.isFormSwitchChecked,
            required: this.isFormSwitchChecked
              ? typeof fieldComponent.config.required === 'function' && fieldComponent.config.required()
              : false,
            defaultValue: this.isFormSwitchChecked ? this._getFieldDefault(fieldComponent.fieldId) : null,
          });
        }
      });
    });
    this._subscriptions.add(formUpdateSub);

    const phoneSubmitSub = this.phoneUiContext.isSaving$.subscribe((isSubmitting) => {
      this.fieldComponents.forEach((fieldComponent) => {
        fieldComponent.applyState({
          ...fieldComponent.state,
          disabled: isSubmitting,
        });
      });
    });
    this._subscriptions.add(phoneSubmitSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    super.ngOnDestroy();
  }

  getMobilityIdentity(): MobilityIdentity {
    return {
      id: this.formData.id,
      name: this.formData.name,
      destinationNumber: this.formData.destinationNumber,
      delayBeforeRingingInSeconds: this.formData.delayBeforeRingingInSeconds,
      delayStopRingingInSeconds: this.formData.delayStopRingingInSeconds,
    };
  }

  getIsFormDirty(): boolean {
    return this.smacsFormStateService.getIsFormDirty();
  }

  protected submit() {
    return this.phoneUiContext.isSaving$.pipe(
      filter((isSaving) => !isSaving),
      first()
    );
  }

  private _getFieldDefault(fieldId: string): string | number {
    switch (fieldId) {
      case 'name':
        return this.fieldConfig.name.defaultValue;
      case 'destinationNumber':
        return this.fieldConfig.destination.defaultValue;
      case 'delayBeforeRingingInSeconds':
        return Number(this.fieldConfig.delayBeforeRingInSeconds.defaultValue);
      case 'delayStopRingingInSeconds':
        return Number(this.fieldConfig.stopRingingPhoneDelayInSeconds.defaultValue);
    }
  }
}
