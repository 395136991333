<app-self-serve-top-bar></app-self-serve-top-bar>

<app-loading-block [hidden]="!isLoading && (isFetching360View$ | async) === false"></app-loading-block>

<div *ngIf="!isLoading && (isFetching360View$ | async) === false" data-automation="self-serve-user-details">
  <div *ngIf="!anyEndUserHasConfigurableServices()" id="self-serve-no-services" class="alert alert-warning text-center">
    {{ 'tkey;selfserve.user.nophoneservices.helpblock' | translate }}
  </div>

  <div class="row self-serve-user-details-services" data-automation="self-serve-user-details-services">
    <!-- VM -->
    <ziro-self-serve-card
      *ngFor="let voicemail of voicemails; let i = index"
      [routerLink]="'/self-serve/user/' + user.ref.username + '/voicemail-pin-reset/' + voicemail.id"
      [dataAutomation]="'self-serve-voicemail-pin-reset-' + i"
      [header]="'tkey;self_serve.voicemail.header'"
      [description]="'tkey;self_serve.voicemail.description'"
      [displayBadgePrimary]="true"
      [lottieFile]="vmJson"
      class="col-lg-6"
    >
      <span badgePrimary>
        {{ 'tkey;self_serve.voicemail.badge' | translate }} {{ voicemail.extension | telephoneNumberFilter }}
      </span>
    </ziro-self-serve-card>

    <!-- Extension Mobility -->
    <ziro-self-serve-card
      *ngFor="let extensionMobility of extensionMobilities; let i = index"
      [routerLink]="'/self-serve/user/' + user.ref.username + '/extension-mobility-pin-reset/' + extensionMobility.id"
      [dataAutomation]="'self-serve-extension-mobility-pin-reset-' + i"
      [header]="'tkey;self_serve.ext_mobility.header'"
      [description]="'tkey;self_serve.ext_mobility.description'"
      [lottieFile]="extMobilityJson"
      class="col-lg-6"
    ></ziro-self-serve-card>

    <!-- SNR - Edit -->
    <ziro-self-serve-card
      *ngFor="let snrDestination of snrDestinations; let i = index"
      [routerLink]="'/self-serve/user/' + user.ref.username + '/snr-destination/' + snrDestination.id"
      [dataAutomation]="'self-serve-snr-pin-reset-' + i"
      [header]="'tkey;self_serve.snr.header'"
      [description]="'tkey;self_serve.snr.description'"
      [displayBadgePrimary]="!!snrDestination.destinationNumber"
      [displayBadgeSecondary]="!snrDestination.destinationNumber"
      [lottieFile]="snrJson"
      class="col-lg-6"
    >
      <span badgePrimary>
        {{ 'tkey;self_serve.snr.badge.active' | translate }}
        {{ snrDestination.destinationNumber | telephoneNumberFilter }}
      </span>
      <span badgeSecondary>
        {{ 'tkey;self_serve.snr.badge.no_destination' | translate }}
      </span>
    </ziro-self-serve-card>

    <!-- SNR - Create -->
    <ziro-self-serve-card
      *ngFor="let snrProfiles of snrProfiles; let i = index"
      [routerLink]="'/self-serve/user/' + user.ref.username + '/snr-profile/' + snrProfiles.id + '/snr-destination'"
      [dataAutomation]="'self-serve-snr-pin-reset-' + i"
      [header]="'tkey;self_serve.snr.header'"
      [description]="'tkey;self_serve.snr.description'"
      [displayBadgeSecondary]="true"
      [lottieFile]="snrJson"
      class="col-lg-6"
    >
      <span badgeSecondary>
        {{ 'tkey;self_serve.snr.badge.no_destination' | translate }}
      </span>
    </ziro-self-serve-card>

    <!-- Call Forward -->
    <ziro-self-serve-card
      *ngFor="let dnContainer of callForwards; let i = index"
      [routerLink]="'/self-serve/user/' + user.ref.username + '/call-forward/' + dnContainer.directoryNumberRef.id"
      [dataAutomation]="'self-serve-call-forward-pin-reset-' + i"
      [header]="'tkey;self_serve.call_forward.header'"
      [description]="'tkey;self_serve.call_forward.description'"
      [displayBadgePrimary]="!!dnContainer.directoryNumber.forwardAll.destination"
      [displayBadgeSecondary]="!dnContainer.directoryNumber.forwardAll.destination"
      [lottieFile]="callForwardJson"
      class="col-lg-6"
    >
      <span badgePrimary>
        {{ 'tkey;self_serve.call_forward.badge.destination' | translate }}
        {{ dnContainer.directoryNumber.forwardAll.destination | telephoneNumberFilter }}
      </span>
      <span badgeSecondary>
        {{ 'tkey;self_serve.call_forward.badge.no_destination' | translate }}
      </span>
    </ziro-self-serve-card>

    <!-- Edit Deskphone Buttons -->
    <ziro-self-serve-card
      *ngFor="let phoneContainer of editDeskphones; let i = index"
      [routerLink]="'/self-serve/user/' + user.ref.username + '/phone/' + phoneContainer.phoneRef.id"
      [dataAutomation]="'self-serve-deskphone-edit-' + i"
      [header]="'tkey;self_serve.phone.buttons.header'"
      [description]="'tkey;self_serve.phone.buttons.description'"
      [displayBadgePrimary]="!!phoneContainer.phoneRef.description"
      [displayBadgeSecondary]="
        (phoneContainer.statusAsync | async)?.status === 'NOT_FOUND' ||
        (phoneContainer.statusAsync | async)?.status === 'UNREGISTERED'
      "
      [badgeSecondaryCssClass]="'bg-warning animated fadeIn'"
      [lottieFile]="editDeskphoneJson"
      class="col-lg-6"
    >
      <span badgePrimary>
        {{ phoneContainer.phoneRef.description }}
      </span>
      <span badgeSecondary>
        <div class="d-inline-flex align-items-center">
          <strong class="p-0 m-0">
            <i class="fa fa-warning me-1"></i>
            <span>{{ 'tkey;selfserve.user.deskphone.out_of_service.title' | translate }}</span>
          </strong>
        </div>
      </span>
    </ziro-self-serve-card>

    <!-- Replace Existing Deskphone -->
    <ziro-self-serve-card
      *ngFor="let phoneContainer of replaceDeskphones; let i = index"
      [routerLink]="'/self-serve/user/' + user.ref.username + '/replace-deskphone/' + phoneContainer.phoneRef.id"
      [dataAutomation]="'self-serve-replace-deskphone-' + i"
      [header]="'tkey;self_serve.phone.replace.header'"
      [description]="'tkey;self_serve.phone.replace.description'"
      [displayBadgePrimary]="!!phoneContainer.phoneRef.description"
      [lottieFile]="replaceDeskphoneJson"
      class="col-lg-6"
    >
      <span badgePrimary>
        {{ phoneContainer.phoneRef.description }}
      </span>
    </ziro-self-serve-card>

    <!-- Activate Deskphone -->
    <ziro-self-serve-card
      *ngIf="selfServe360View.canActivateDeskphone"
      [routerLink]="'/self-serve/user/' + user.ref.username + '/activate'"
      [dataAutomation]="'activate-deskphone-button'"
      [header]="'tkey;self_serve.phone.activate.header'"
      [description]="'tkey;self_serve.phone.activate.description'"
      [lottieFile]="activateDeskphoneJson"
      class="col-lg-6"
    ></ziro-self-serve-card>

    <app-footer class="col-12 mt-4 mb-3" [cssClasses]="'text-center'"></app-footer>
  </div>
</div>
