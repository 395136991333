import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CurrentDateContext, CurrentDateContextState } from '../contexts/current-date.context';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  @Input() cssClasses = 'bg-light footer py-2 text-center flex-column';

  year: number;
  window = window;
  isLoading = true;

  private dateSubscription: Subscription;

  constructor(private currentDateContext: CurrentDateContext) {}

  ngOnInit() {
    this._getYear();
  }

  ngOnDestroy() {
    this.dateSubscription.unsubscribe();
  }

  private _getYear() {
    this.dateSubscription = this.currentDateContext.state$.subscribe((state: CurrentDateContextState) => {
      this.year = state.year;
      this.isLoading = false;
    });
  }
}
