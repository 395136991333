import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ButtonState, IndexedPhoneButton, PhoneButtonTypes } from '../../models/phone-button';
import { PhoneModelUI, undefinedPhoneModelUI } from '../phone-buttons-mapping';
import { PhoneButtonEditModalComponent } from '../edit-modal/phone-button-edit-modal.component';
import {
  Button,
  DirectoryNumberRef,
  EndUserRef,
  Global360View,
  LineButton,
  LineFeatureFieldConfig,
  Phone,
  TranslationPatternRef,
  VoicemailRef,
} from '../../models/generated/smacsModels';
import { SmacsFormsUpdate, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { combineLatest, forkJoin, Observable, of, Subscriber, Subscription } from 'rxjs';
import { LineFeatureConfigResource } from '../../resources/line-feature-config.resource';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { DragDropMode } from '../drag-drop-mode.enum';
import { cloneDeep, isEqual } from 'lodash';
import { DnDetailSummaryResource } from '../../resources/dn-detail-summary.resource';
import { SmacsModalService } from '../../services/smacs-modal.service';
import { map, take, tap } from 'rxjs/operators';
import { PhoneButtonsService } from '../shared/phone-buttons.service';
import { DragdropUiContext, DragNDropUICache } from '../contexts/dragdrop-ui.context';
import { SmacsSelectOption } from '../../../forms/fields/select/smacs-select.component';
import { TranslateService } from '@ngx-translate/core';
import { PhoneUiContext } from '../contexts/phone-ui.context';
import { PhoneResource } from '../../resources/phone.resource';
import { ToastService } from '../../services/toast.service';
import { SmacsIcons } from '../../models/smacs-icons.enum';
import { Global360ViewContext } from '../../contexts/global-360-view.context';
import { PhoneType } from '../../models/service-type';
import { ExtMobilityUiContext } from '../contexts/ext-mobility-ui.context';
import { AbstractUiContext } from '../contexts/abstract-ui.context';
import { ExtensionMobilityDnD } from '../phone-buttons.component';
import MouseDownEvent = JQuery.MouseDownEvent;

export interface IndexedPhoneButtonLayout {
  left: IndexedPhoneButton[];
  right: IndexedPhoneButton[];
}

export interface LineButtonPeripheralSummary {
  id: string;
  voicemail: VoicemailRef;
  didTranslationPattern: TranslationPatternRef;
  dialPlanGroupId: number;
  isUccxAgent: boolean;
  isPrimaryExtension: boolean;
}

interface PopoverPosition {
  page: number;
  side: 'left' | 'right';
}

@Component({
  selector: 'app-phone-buttons-standard',
  templateUrl: './phone-buttons-standard.component.html',
  styleUrls: ['./phone-buttons-standard.component.scss'],
})
export class PhoneButtonsStandardComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @ViewChildren('editFormPopover') editForm: QueryList<any>;

  @Input() isPending: boolean;
  @Input() userMode: DragDropMode;
  @Input() isAutomaticPhoneTemplateSelectionEnabled: boolean;
  @Input() buttons: IndexedPhoneButton[] = [];
  @Input() phone: Phone | ExtensionMobilityDnD;
  @Input() siteId: number;
  @Input() serverId: string;
  @Input() phoneUI: PhoneModelUI = undefinedPhoneModelUI;
  @Input() buttonStates: ButtonState[] = [];
  @Input() isButtonSwapped = false;
  @Input() closePopover$: Observable<NgbPopover>;
  @Input() global360View: Global360View;
  @Input() phoneType: PhoneType;
  @Input() endUserRef: EndUserRef;

  @Output() singleButtonWasUpdated = new EventEmitter<IndexedPhoneButton>();
  @Output() singleButtonWasDeleted = new EventEmitter<IndexedPhoneButton>();
  @Output() popoverOpened = new EventEmitter<NgbPopover>();
  @Output() buttonStateIsInvalid = new EventEmitter();
  @Output() secondaryLineIsUpdating = new EventEmitter<boolean>();

  paginatedButtons: IndexedPhoneButtonLayout[];
  phoneFrameButtonClasses = {};
  buttonIdx: number;
  updatedButton: any;
  isLoadingLineInfo = false;
  availableButtonTypeOptions: SmacsSelectOption[];
  // this boolean is to check for secondary line info and display loading block until payload is received.
  isSecondaryLineInfoLoading = false;
  isSelfServeMode = false;
  updateLineButton: any;
  updateFieldValidation = false;
  context: AbstractUiContext;
  isSingleButtonSoftphone = false;

  currentlyOpenPopoverPosition: PopoverPosition;
  private _currentlyOpenButtonState: ButtonState;
  private _currentlyOpenPopover: NgbPopover;

  private _subscriptions = new Subscription();

  constructor(
    private lineFeatureConfigResource: LineFeatureConfigResource,
    private dnDetailSummaryResource: DnDetailSummaryResource,
    private phoneResource: PhoneResource,
    private phoneButtonsService: PhoneButtonsService,
    private dragDropUiContext: DragdropUiContext,
    private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    private toastService: ToastService,
    private smacsModalService: SmacsModalService,
    private global360ViewContext: Global360ViewContext,
    private injector: Injector
  ) {}

  isVoicemailDeleted(): Observable<boolean> {
    return new Observable((subscriber: Subscriber<boolean>) => {
      this.phoneButtonsService.isVoicemailDeleted.subscribe((isVoiceMailDeleted) => {
        subscriber.next(isVoiceMailDeleted);
      });
    });
  }

  deletedLineSummary(): Observable<LineButtonPeripheralSummary> {
    return new Observable((subscriber: Subscriber<LineButtonPeripheralSummary>) => {
      this.phoneButtonsService.lineButtonSummary.subscribe((lineButtonSummary) => {
        subscriber.next(lineButtonSummary);
      });
    });
  }

  ngOnInit() {
    this.isSelfServeMode = this.userMode === DragDropMode.SELF_SERVE;
    /** This will be called every time the phone's model or protocol are updated. */
    const dragDropSub = this.dragDropUiContext.dragnDropState$.pipe(take(1)).subscribe((metadata) => {
      this._setAvailableButtonTypeOptions(metadata);
    });
    this._subscriptions.add(dragDropSub);
    this.context =
      this.phoneType === PhoneType.EXTENSION_MOBILITY
        ? this.injector.get(ExtMobilityUiContext)
        : this.injector.get(PhoneUiContext);

    /** This subscriber is called when the parent starts a drag event, or when a click is made outside the popover.
     * 'popover' will be defined only in the latter case. */
    const closePopoverSub = this.closePopover$.subscribe((popover) => {
      this._closeCurrentlyOpenPopover(popover);
    });
    const sub = combineLatest([this.isVoicemailDeleted(), this.deletedLineSummary()]).subscribe(
      ([isVmDeleted, lineSummary]) => {
        const deletedButtonIdx = this.buttons.findIndex(
          (button) => button?.linePeripheralSummary?.id === lineSummary?.id
        );
        if (isVmDeleted && this.buttons[deletedButtonIdx]?.linePeripheralSummary) {
          this.buttons[deletedButtonIdx].linePeripheralSummary = {
            ...this.buttons[deletedButtonIdx]?.linePeripheralSummary,
            voicemail: null,
          };
        } else if (this.buttons[deletedButtonIdx]?.linePeripheralSummary) {
          this.buttons[deletedButtonIdx].linePeripheralSummary = {
            ...this.buttons[deletedButtonIdx]?.linePeripheralSummary,
            voicemail: lineSummary?.voicemail,
          };
        }
      }
    );
    this.isSingleButtonSoftphone = this.buttons?.length === 1 && this.phoneType !== PhoneType.DESKPHONE;

    this._subscriptions.add(sub);
    this._subscriptions.add(closePopoverSub);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.buttons?.isFirstChange()) {
      this.isSecondaryLineInfoLoading = this.userMode !== DragDropMode.SELF_SERVE;
    }
    if (changes.buttons?.currentValue) {
      this.isLoadingLineInfo = true;
      if (this.isButtonSwapped === false && this.buttonIdx != null) {
        this.buttons[this.buttonIdx] = { button: this.updatedButton, index: this.buttonIdx } as IndexedPhoneButton;
        this.isButtonSwapped = null;
      }
      const lineInfo$ =
        this.userMode === DragDropMode.HELPDESK
          ? this._setSecondaryLineInformationAndValidateButtons(this.buttons)
          : of<void>(null);
      lineInfo$.subscribe(() => {
        this._setPaginatedButtons();
        this._setCSSClassMapping();
        this._detectInvalidButtonState();
        this.isLoadingLineInfo = false;
      });
    }
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  /***
   * Checks the newly set buttonStates and determines if any of them are invalid. If so, relay this information to
   * the smacs-phone-buttons component.
   */
  private _detectInvalidButtonState() {
    const areAnyButtonStatesInvalid = this.buttonStates.some((buttonState: ButtonState) => buttonState.invalid);

    if (areAnyButtonStatesInvalid) {
      this.buttonStateIsInvalid.emit();
    }
  }

  /***
   * Splits the UI based on the number of buttons and the number of pages, both given by the phoneUI property.
   * For example, the BEKEM 36-Button Line has 18 standard buttons in two pages, therefore this method will split
   * those 18 buttons into their own separate UI, to account for the fact that on the physical model we can change
   * the current page.
   */
  private _setPaginatedButtons() {
    this.paginatedButtons = [];
    for (let currentPageIndex = 0; currentPageIndex < this.phoneUI.numberPages; currentPageIndex++) {
      this.paginatedButtons.push({
        left: this._getLeftButtons(currentPageIndex),
        right: this._getRightButtons(currentPageIndex),
      });
    }
  }

  /**
   * Get all relevant info for the line buttons, including the voicemail, translation pattern and line feature field config.
   * lineIconsMap is used to display the icons on drag-n-drop button.
   *
   * @param indexedPhoneButtonList
   */
  private _setSecondaryLineInformationAndValidateButtons(
    indexedPhoneButtonList: IndexedPhoneButton[]
  ): Observable<void> {
    return new Observable<void>((subscriber) => {
      const requests = indexedPhoneButtonList
        .map((indexedPhoneButton) => {
          if (this.phoneButtonsService.isLineButton(indexedPhoneButton.button) && indexedPhoneButton.button.dn) {
            return this._getSecondaryLineInformation(indexedPhoneButton, indexedPhoneButton.button.dn, false).pipe(
              tap(() => {
                this.buttonStates[indexedPhoneButton.index].invalid = !this._isValidLineButton(
                  indexedPhoneButton.button as LineButton,
                  indexedPhoneButton
                );
              })
            );
          } else if (indexedPhoneButton.index === 0) {
            const otherButtons = this.buttons
              .filter((btn) => btn.index !== indexedPhoneButton.index)
              .map((btn) => btn.button);
            const lineButton = indexedPhoneButton.button as LineButton;

            /**
             * If line one button is not configured, and we try to load in DnD view, set the invalid state for the
             * button to true.
             */
            if (!lineButton.dn) {
              this.buttonStates[indexedPhoneButton.index].invalid = true;
            } else {
              this.buttonStates[indexedPhoneButton.index].invalid = !this.phoneButtonsService.isValidLineOneButton(
                lineButton,
                this._isUccxAgentLine(lineButton),
                indexedPhoneButton.lineFeatureConfig,
                otherButtons
              );
            }
            this.setIsVoicemailPresentOnLineOne(false);
          } else {
            this.buttonStates[indexedPhoneButton.index].invalid = !this.phoneButtonsService.isValidNonLineButton(
              indexedPhoneButton.button
            );
          }
        })
        .filter((obs) => !!obs);

      if (requests.length) {
        forkJoin(requests).subscribe(() => {
          this.isSecondaryLineInfoLoading = false;
          subscriber.next();
          subscriber.complete();
        });
      } else {
        this.isSecondaryLineInfoLoading = false;
        subscriber.next();
        subscriber.complete();
      }
    });
  }

  /** Secondary line information includes the DN detail summary (voicemail, DID) and the line feature field config. */
  private _getSecondaryLineInformation(
    indexedPhoneButton: IndexedPhoneButton,
    lineDn: DirectoryNumberRef,
    forceUpdate: boolean
  ): Observable<[void, void]> {
    if (indexedPhoneButton.linePeripheralSummary && indexedPhoneButton.lineFeatureConfig && !forceUpdate) {
      // this happens after swapping a button. we already have this info, so we don't have to make these calls!
      if (indexedPhoneButton.index === 0) {
        this.setIsVoicemailPresentOnLineOne(!!indexedPhoneButton.linePeripheralSummary.voicemail);
      }
      return of<[void, void]>(null);
    }

    this.secondaryLineIsUpdating.emit(true);
    const dnSummaryRequest = this.dnDetailSummaryResource.get(lineDn.id, lineDn.serverId.toString()).pipe(
      map((dnDetailSummary) => {
        indexedPhoneButton.linePeripheralSummary = {
          id: dnDetailSummary.dn?.ref.id,
          voicemail: dnDetailSummary.voicemail,
          didTranslationPattern: dnDetailSummary.translationPatterns[0],
          dialPlanGroupId: dnDetailSummary.dialPlanGroupId,
          isUccxAgent: this.global360View?.ipccExtensions.some(
            (ipccExtension) => ipccExtension.extension === dnDetailSummary.dn.ref.extension
          ),
          isPrimaryExtension: this.global360View?.primaryExtensions.some(
            (primaryExt) => primaryExt.id === dnDetailSummary.dn.ref.id
          ),
        };
        if (indexedPhoneButton.index === 0) {
          this.setIsVoicemailPresentOnLineOne(!!indexedPhoneButton.linePeripheralSummary.voicemail);
        }
      })
    );
    const fieldConfigRequest = this.lineFeatureConfigResource
      .post({
        deviceOwner:
          this.phoneType === PhoneType.EXTENSION_MOBILITY
            ? this.endUserRef.username
            : !!this.global360View &&
              this.global360View.primaryExtensions.some((primaryExt) => primaryExt.extension === lineDn.extension)
            ? (this.phone as Phone).owner
              ? (this.phone as Phone).owner.username
              : null
            : (this.phone as Phone).owner
            ? (this.phone as Phone).owner.username
            : null,
        extension: lineDn.extension,
        model: this.phone.model,
        protocol: this.phone.protocol,
        siteId: this.siteId,
      })
      .pipe(
        map((fieldConfig: LineFeatureFieldConfig) => {
          indexedPhoneButton.lineFeatureConfig = fieldConfig;
          if (!(indexedPhoneButton.button as LineButton).lineFeature && Object.keys(fieldConfig).length && forceUpdate) {
            (indexedPhoneButton.button as LineButton).lineFeature = {
              associatedEndUsers: indexedPhoneButton?.lineFeatureConfig.associatedEndUsers,
              busyTrigger: Number(indexedPhoneButton.lineFeatureConfig.busyTrigger?.defaultValue),
              callRecordingMediaSource: indexedPhoneButton.lineFeatureConfig.callRecordingMediaSource?.defaultValue,
              callRecordingOption: indexedPhoneButton.lineFeatureConfig.callRecordingOption?.defaultValue,
              callRecordingProfile: indexedPhoneButton.lineFeatureConfig.callRecordingProfile?.defaultValue,
              externalCallerId: indexedPhoneButton.lineFeatureConfig.externalCallerId?.defaultValue,
              externalCallerIdNumber: indexedPhoneButton.lineFeatureConfig.externalCallerIdNumber?.defaultValue,
              internalCallerId: indexedPhoneButton.lineFeatureConfig.internalCallerId?.defaultValue,
              label: indexedPhoneButton.lineFeatureConfig.label?.defaultValue,
              maxNumberOfCalls: Number(indexedPhoneButton.lineFeatureConfig.maxNumberOfCalls?.defaultValue),
              monitoringCssName: indexedPhoneButton.lineFeatureConfig?.monitoringCssName?.defaultValue,
              ringActive: indexedPhoneButton.lineFeatureConfig.ringActive?.defaultValue,
              ringIdle: indexedPhoneButton.lineFeatureConfig.ringIdle?.defaultValue,
            };
          }
        })
      );
    return new Observable((subscriber: Subscriber<[void, void]>) => {
      forkJoin([dnSummaryRequest, fieldConfigRequest]).subscribe((data) => {
        this.secondaryLineIsUpdating.emit(false);
        subscriber.next(data);
        subscriber.complete();
      });
    });
  }

  private _closeCurrentlyOpenPopover = (popover?: NgbPopover) => {
    if (popover && popover === this._currentlyOpenPopover) {
      if (!this.isPopoverOpen()) {
        this.currentlyOpenPopoverPosition = null;
      }
      // this will happen when we have just opened a new popover and have told the parent to close all other popovers
      return;
    }

    if (this._currentlyOpenPopover?.isOpen() && !this.isModalOpen()) {
      this._currentlyOpenPopover.close();
      this._currentlyOpenPopover = null;
      this.currentlyOpenPopoverPosition = null;
    }
    if (this._currentlyOpenButtonState) {
      this._currentlyOpenButtonState.selected = false;
      this._currentlyOpenButtonState = null;
    }
    if (!this.isPopoverOpen()) {
      this.currentlyOpenPopoverPosition = null;
    }
    this.changeDetectorRef.detectChanges();
  };

  isModalOpen(): boolean {
    return document.getElementsByTagName('smacs-detailed-modal').length !== 0;
  }

  isPopoverOpen(): boolean {
    return document.getElementsByTagName('ngb-popover-window').length !== 0;
  }

  ngAfterViewInit() {
    this.changeDetectorRef.detectChanges();
  }

  /** Build the available button types for the current phone's model and protocol, to be used by the 'type' smacs-select. */
  private _setAvailableButtonTypeOptions(metadata: DragNDropUICache) {
    this.availableButtonTypeOptions = metadata.buttonTypes
      .map((buttonType) => {
        return {
          label: buttonType,
          value: buttonType,
          group: this._groupNameForButtonType(buttonType),
        };
      })
      .sort((a, b) => {
        const isACommon = this._isCommonButtonType(a.value);
        const isBCommon = this._isCommonButtonType(b.value);
        if (isACommon !== isBCommon) {
          return Number(isBCommon) - Number(isACommon);
        } else {
          return a.value.localeCompare(b.value);
        }
      });
  }

  private _groupNameForButtonType(buttonType: string): string {
    if (this._isCommonButtonType(buttonType)) {
      return this.translateService.instant('tkey;pages.details.phoneSettings.button.group.common');
    } else {
      return this.translateService.instant('tkey;pages.details.phoneSettings.button.group.specialized');
    }
  }

  private _isCommonButtonType(buttonType: string): boolean {
    return (
      buttonType === 'Line' || buttonType === 'Speed Dial' || buttonType === 'Speed Dial BLF' || buttonType === 'None'
    );
  }

  private _pageStartingPoint(pageIndex: number) {
    return pageIndex * this.phoneUI.numberLeft + pageIndex * this.phoneUI.numberRight;
  }

  private _pageLeftButtonsEndingPoint(pageIndex: number) {
    return this._pageStartingPoint(pageIndex) + this.phoneUI.numberLeft;
  }

  private _pageRightButtonsEndingPoint(pageIndex: number) {
    return this._pageLeftButtonsEndingPoint(pageIndex) + this.phoneUI.numberRight;
  }

  /***
   * Determines how many buttons we should present on the left side, based on the layout given by phoneUI. Utilizes
   * pageIndex to determine how we should split the current buttons array to account for this.
   *
   * @param pageIndex The index of the page we're currently on
   */
  private _getLeftButtons(pageIndex: number) {
    return this.buttons.slice(this._pageStartingPoint(pageIndex), this._pageLeftButtonsEndingPoint(pageIndex));
  }

  /***
   * Determines how many buttons we should present on the right side, based on the layout given by phoneUI. Utilizes
   * pageIndex to determine how we should split the current buttons array to account for this.
   *
   * @param pageIndex The index of the page we're currently on
   */
  private _getRightButtons(pageIndex: number) {
    return this.buttons.slice(
      this._pageLeftButtonsEndingPoint(pageIndex),
      this._pageRightButtonsEndingPoint(pageIndex)
    );
  }

  /***
   * Determines the button shape for the UI. The phoneUI determines whether buttons will be rectangular, oval, circle
   * or staggered (with different margins depending on whether the button index is odd or even), like in the
   * Cisco 7962 model.
   */
  private _setPhoneFrameButtonClasses() {
    const phoneFrameButtonCSS = { 'phone-frame__content__buttons': true };
    this.phoneUI.type.split(' ').forEach((buttonType: string) => {
      (phoneFrameButtonCSS as any)[`phone-frame__content__buttons--${buttonType}`] = true;
    });

    this.phoneFrameButtonClasses = phoneFrameButtonCSS;
  }

  /** At this point, the request to update the phone should already be complete. Now we're just updating the ui. */
  private _updateSingleButtonFromModal = (indexedButton: IndexedPhoneButton) => {
    this.buttonIdx = indexedButton.index;
    this.updatedButton = indexedButton.button;
    this.buttons.find((btn) => btn.index === indexedButton.index).button = indexedButton.button;
    this.singleButtonWasUpdated.emit(indexedButton);
  };

  /** Update the validation state of the button. Also, if we're updating a line's DN, we need to re-fetch the
   * secondary line information. */
  updateSingleButtonFromPopover(buttonUpdate: SmacsFormsUpdate<Button>, buttonIndex: number) {
    const isCurrentButtonStateInvalid = this.buttonStates[buttonIndex].invalid;
    this.buttonStates[buttonIndex].invalid = buttonUpdate.valid === SmacsFormsValidationState.INVALID;

    // we need to emit singleButtonWasUpdated if button state has changed as well
    if (
      (buttonUpdate.new && buttonUpdate.old && !isEqual(buttonUpdate.new, buttonUpdate.old)) ||
      isCurrentButtonStateInvalid !== this.buttonStates[buttonIndex].invalid
    ) {
      const updatedButton = cloneDeep(buttonUpdate.new);
      this.updateLineButton = cloneDeep(buttonUpdate.old);
      const oldButton = buttonUpdate.old;
      // we have to use 'find' because expansion modules have indexedButtons where the index starts at >0
      const updatedIndexedButton = this.buttons.find((button) => button.index === buttonIndex);

      const updatedButtonClone = cloneDeep(updatedButton) as LineButton;
      const oldButtonClone = cloneDeep(oldButton) as LineButton;

      // Don't diff with description
      delete updatedButtonClone?.dn?.description;
      delete oldButtonClone?.dn?.description;

      if (
        this.phoneButtonsService.isLineButton(updatedButton) &&
        this.phoneButtonsService.isLineButton(oldButton) &&
        !isEqual(updatedButtonClone.dn, oldButtonClone.dn)
      ) {
        updatedButton.lineFeature = null;
        updatedIndexedButton.lineFeatureConfig = null;
        updatedIndexedButton.linePeripheralSummary = null;
        if (updatedButton.dn) {
          this._getSecondaryLineInformation(updatedIndexedButton, updatedButton.dn, false).subscribe(() => {
            updatedButton.lineFeature = {
              associatedEndUsers: updatedIndexedButton.lineFeatureConfig.associatedEndUsers,
              busyTrigger: Number(updatedIndexedButton.lineFeatureConfig.busyTrigger.defaultValue),
              callRecordingMediaSource: updatedIndexedButton.lineFeatureConfig.callRecordingMediaSource.defaultValue,
              callRecordingOption: updatedIndexedButton.lineFeatureConfig.callRecordingOption.defaultValue,
              callRecordingProfile: updatedIndexedButton.lineFeatureConfig.callRecordingProfile.defaultValue,
              externalCallerId: updatedIndexedButton.lineFeatureConfig.externalCallerId.defaultValue,
              externalCallerIdNumber: updatedIndexedButton.lineFeatureConfig.externalCallerIdNumber.defaultValue,
              internalCallerId: updatedIndexedButton.lineFeatureConfig.internalCallerId.defaultValue,
              label: updatedIndexedButton.lineFeatureConfig.label.defaultValue,
              maxNumberOfCalls: Number(updatedIndexedButton.lineFeatureConfig.maxNumberOfCalls.defaultValue),
              monitoringCssName: updatedIndexedButton.lineFeatureConfig.monitoringCssName.defaultValue,
              ringActive: updatedIndexedButton.lineFeatureConfig.ringActive.defaultValue,
              ringIdle: updatedIndexedButton.lineFeatureConfig.ringIdle.defaultValue,
            };
            // Force the views to update by changing the entity reference
            updatedIndexedButton.button = cloneDeep(updatedButton);
            this.singleButtonWasUpdated.emit({ ...updatedIndexedButton, index: buttonIndex });
          });
        } else if (updatedIndexedButton.index === 0) {
          this.setIsVoicemailPresentOnLineOne(false);
        }
      }

      updatedIndexedButton.button = updatedButton;

      if (
        this.phoneButtonsService.isLineButton(oldButton) &&
        (!this.phoneButtonsService.isLineButton(updatedButton) || !isEqual(oldButton.dn, updatedButton.dn))
      ) {
        // if this was a line button, and the type or dn has changed, check for duplicates on all other line extensions
        this._validateAllLines(updatedIndexedButton, oldButton);
      }

      this.singleButtonWasUpdated.emit({ ...updatedIndexedButton, index: buttonIndex });
    }
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Set voicemail present on line one. This flag is to be used for public phone.
   * @param isPresent
   */
  setIsVoicemailPresentOnLineOne(isPresent: boolean) {
    if (this.phoneType === PhoneType.DESKPHONE) {
      this.injector.get(PhoneUiContext).setIsVoicemailPresentOnLineOne(isPresent);
    }
  }

  handlePeripheralUpdateFromForm(update: LineButtonPeripheralSummary, buttonIndex: number) {
    const indexedPhoneButton = this.buttons.find((button) => button.index === buttonIndex);
    if (!isEqual(update, indexedPhoneButton.linePeripheralSummary)) {
      indexedPhoneButton.linePeripheralSummary = update;
    }
  }

  handleLineFieldConfigUpdate($event: any, buttonIndex: number) {
    if ($event.update && !!$event.dnRef) {
      const updatedIndexedButton = this.buttons.find((button) => button.index === buttonIndex);
      if (updatedIndexedButton) {
        this._getSecondaryLineInformation(updatedIndexedButton, $event.dnRef, true).subscribe(() => {
          this.singleButtonWasUpdated.emit({ ...updatedIndexedButton, index: buttonIndex });
          this.updateFieldValidation = true;
          this.buttonStates[buttonIndex].invalid = !this._isValidLineButton(
            updatedIndexedButton.button as LineButton,
            updatedIndexedButton
          );
          const isInvalidButton = this.buttonStates[updatedIndexedButton.index].invalid;
          if (isInvalidButton) {
            this.buttonStateIsInvalid.emit();
          }
          setTimeout(() => {
            this.updateFieldValidation = false;
          }, 100);
        });
      }
    }
  }

  private _validateAllLines(updatedButton: IndexedPhoneButton, oldButton: LineButton) {
    this.buttons.forEach((button) => {
      if (
        button.index !== updatedButton.index &&
        this.phoneButtonsService.isLineButton(button.button) &&
        button.button.dn
      ) {
        const buttonExtension = button.button.dn.extension;
        if (
          oldButton.dn?.extension === buttonExtension ||
          (this.phoneButtonsService.isLineButton(updatedButton.button) &&
            updatedButton.button.dn?.extension === buttonExtension)
        ) {
          // this button either is or was a duplicate. run full validation.
          this.buttonStates[button.index].invalid = !this._isValidLineButton(button.button, button);
        }
      }
    });
  }

  private _isValidLineButton(lineButton: LineButton, indexedPhoneButton: IndexedPhoneButton): boolean {
    const otherButtons = this.buttons.filter((btn) => btn.index !== indexedPhoneButton.index).map((btn) => btn.button);
    const isLineOneButton = indexedPhoneButton.index === 0;
    const isValidLineOneButton = isLineOneButton
      ? this.phoneButtonsService.isValidLineOneButton(
          lineButton,
          this._isUccxAgentLine(lineButton),
          indexedPhoneButton.lineFeatureConfig,
          otherButtons
        )
      : indexedPhoneButton.index === 0;
    return (
      isValidLineOneButton ||
      this.phoneButtonsService.isValidLineButton(
        lineButton,
        this._isUccxAgentLine(lineButton),
        indexedPhoneButton.lineFeatureConfig,
        otherButtons
      )
    );
  }

  /***
   * Goes through every single button and determines which to replace in the UI, before relaying that information
   * to PhoneButtonsComponent.
   *
   * @param indexedButton The phone button that was deleted in PhoneButtonsDragNDropButtonComponent
   */
  deleteSingleButton = (indexedButton: IndexedPhoneButton) => {
    const oldButton = { ...this.buttons.find((btn) => btn.index === indexedButton.index) };

    this.paginatedButtons.forEach((button) => {
      if (button.left) {
        button.left.forEach((leftButton) => {
          if (leftButton.index === indexedButton.index) {
            leftButton.button = indexedButton.button;
            leftButton.lineFeatureConfig = null;
            leftButton.linePeripheralSummary = null;
          }
        });
      } else if (button.right) {
        button.right.forEach((rightButton) => {
          if (rightButton.index === indexedButton.index) {
            rightButton.button = indexedButton.button;
            rightButton.lineFeatureConfig = null;
            rightButton.linePeripheralSummary = null;
          }
        });
      }
    });

    if (this.userMode === DragDropMode.HELPDESK) {
      this.buttonStates[indexedButton.index].deleteToggled = false;
      this.buttonStates[indexedButton.index].invalid = false;
      if (this.phoneButtonsService.isLineButton(indexedButton.button)) {
        this._validateAllLines(indexedButton, oldButton.button as LineButton);
        if (indexedButton.index === 0) {
          this.setIsVoicemailPresentOnLineOne(false);
        }
      }
      this.singleButtonWasDeleted.emit(indexedButton);
    } else if (this.userMode === DragDropMode.SELF_SERVE) {
      this.isPending = true;
      this.phoneResource.put(this.phone as Phone, this.serverId).subscribe(() => {
        this.isPending = false;
        this.toastService.pushSaveToast('Deskphone', this.phone.description, SmacsIcons.DESKPHONE);
        this.buttonStates[indexedButton.index].deleteToggled = false;
        this.buttonStates[indexedButton.index].invalid = false;
        this.singleButtonWasDeleted.emit(indexedButton);
      });
    }
  };

  /***
   * If we're in self-serve mode, open a modal to update the button. If we're in helpdesk mode, open a popover instead.
   * @param $event The button HTML element that was clicked
   * @param popover A reference to the ngbPopover template
   * @param indexedPhoneButton The properties and index of the button
   * @param buttonState The button state, such as whether it's disabled or not
   * @param position Whether the clicked button is on the left or the right of the phone
   */
  openButtonEditForm(
    $event: MouseDownEvent,
    popover: NgbPopover,
    indexedPhoneButton: IndexedPhoneButton,
    buttonState: ButtonState,
    position: PopoverPosition
  ) {
    if (
      buttonState.uneditable ||
      buttonState.unmovable ||
      buttonState.disabled ||
      buttonState.deleteToggled ||
      $event.target.classList.contains('phone-buttons-delete-toggle-button') ||
      $event.target.parentNode.classList.contains('phone-buttons-delete-toggle-button') ||
      $event.target.classList.contains('phone-buttons-delete-confirmation-buttons') ||
      $event.target.parentNode.classList.contains('phone-buttons-delete-confirmation-buttons')
    ) {
      return false;
    }
    if (this.userMode === DragDropMode.HELPDESK) {
      $event.stopPropagation();
      const isClosingCurrentPopover = this._currentlyOpenPopover === popover;
      this._closeCurrentlyOpenPopover();
      if (!isClosingCurrentPopover) {
        // Only open the popover for this button if it wasn't already open
        popover.open({
          indexedButton: indexedPhoneButton,
          isFixedButton: buttonState.fixed,
        });
        this._currentlyOpenPopover = popover;
        this._currentlyOpenButtonState = buttonState;
        this._currentlyOpenButtonState.selected = true;
        this.currentlyOpenPopoverPosition = position;
        this.popoverOpened.emit(popover);
      }
    } else {
      this.buttonStates[indexedPhoneButton.index].selected = true;
      const options = {
        modalViewProperties: {
          title: this.getModalTitleByButtonType(indexedPhoneButton),
          buttonToEdit: cloneDeep(indexedPhoneButton),
          phone: this.phone,
          serverId: this.serverId,
          cb: this._updateSingleButtonFromModal,
          phoneUiContext: this.context,
          submitButtonLabel: 'tkey;shared.device.phone.phone_buttons.config_pane.update.button',
        },
        bodyClass: PhoneButtonEditModalComponent,
      };
      this.smacsModalService
        .openDetailedModal(() => options.modalViewProperties, options)
        .subscribe(() => {
          this.buttonStates[indexedPhoneButton.index].selected = false;
        });
    }
  }

  private getModalTitleByButtonType(indexedPhoneButton: IndexedPhoneButton): string {
    const buttonType = indexedPhoneButton.button.type;
    let title = '';
    if (buttonType === PhoneButtonTypes.blf) {
      title = 'tkey;shared.device.phone.phone_buttons.config_pane.speed_dial_blf_header';
    } else if (buttonType === PhoneButtonTypes.speed_dial) {
      title = 'tkey;shared.device.phone.phone_buttons.config_pane.speed_dial_header';
    } else if (buttonType === PhoneButtonTypes.line) {
      title = 'tkey;shared.device.phone.phone_buttons.config_pane.line_header';
    }

    return this.translateService.instant(title, {
      btnNumber: indexedPhoneButton.index + 1,
      btnType: indexedPhoneButton.button.type,
    });
  }

  private _setCSSClassMapping() {
    this._setPhoneFrameButtonClasses();
  }

  private _isUccxAgentLine(line: LineButton): boolean {
    return (
      !!this.global360View &&
      this.global360View?.ipccExtensions.some((ipccExtension) => ipccExtension.extension === line.dn?.extension)
    );
  }
}
