import { Injectable, OnDestroy } from '@angular/core';
import { SnrProfileResource } from '../resources/snr-profile.resource';
import { DirectoryNumberResource } from '../resources/directory-number.resource';
import {
  DeviceRegistrationStatus,
  DirectoryNumber,
  DirectoryNumberRef,
  ExtensionMobilityRef,
  Global360View,
  PhoneRef,
  PhoneStatus,
  SelfServeOptions,
  SnrDestination,
  SnrProfileRef,
  VoicemailRef,
} from '../../shared/models/generated/smacsModels';
import { combineLatest, forkJoin, Observable, ReplaySubject, Subscription, switchMap } from 'rxjs';
import { PhoneResource } from '../../shared/resources/phone.resource';
import { defaultIfEmpty, map, tap } from 'rxjs/operators';
import { Global360ViewContext } from '../../shared/contexts/global-360-view.context';
import { SelfServeOptionsContext } from './self-serve-options.context';
import { get } from 'lodash';
import { ActivatedRoute } from '@angular/router';

interface UserDetailPhoneStatus {
  status: string;
  label: string;
  cssClass: string;
}

const statusToDisplayInfoMap = {
  [DeviceRegistrationStatus.REGISTERED]: {
    label: 'tkey;userdetail.desk_phone.status.registered.label',
    cssClass: 'bg-success',
  },
  [DeviceRegistrationStatus.UNREGISTERED]: {
    label: 'tkey;userdetail.desk_phone.status.unregistered.label',
    cssClass: 'bg-warning',
  },
  [DeviceRegistrationStatus.NOT_FOUND]: {
    label: 'tkey;userdetail.desk_phone.status.not_found.label',
    cssClass: 'bg-danger',
  },
};

export interface DnContainer {
  directoryNumber: DirectoryNumber;
  directoryNumberRef: DirectoryNumberRef;
}

export interface PhoneContainer {
  phoneRef?: PhoneRef;
  statusAsync?: Observable<UserDetailPhoneStatus>;
}

export interface SnrContainer {
  snrDestination: SnrDestination[];
  snrProfileRef: SnrProfileRef;
}

export interface SelfServe360View extends Global360View {
  dnContainers?: DnContainer[];
  phoneContainers?: PhoneContainer[];
  snrContainers?: SnrContainer[];
  canActivateDeskphone: boolean;
  canReplaceDeskphone: boolean;
  canProvisionSnr: boolean;
}

@Injectable()
export class SelfServeUiContext implements OnDestroy {
  selfServe360View: SelfServe360View = null;
  private _selfServeOptions = {} as SelfServeOptions;

  /** Use this subject to update the self serve user's 360 view. */
  _selfServe360ViewSource = new ReplaySubject<SelfServe360View>(1);
  selfServe360View$ = this._selfServe360ViewSource.asObservable();

  private _subscription = new Subscription();
  private _username: string;
  private _isFirstRun = true;

  private _global360ViewState: Observable<Global360View>;
  private _selfServeOptionsState: Observable<SelfServeOptions>;

  constructor(
    private directoryNumberResource: DirectoryNumberResource,
    private snrProfileResource: SnrProfileResource,
    private global360ViewContext: Global360ViewContext,
    private phoneResource: PhoneResource,
    private selfServeOptionsContext: SelfServeOptionsContext,
    private route: ActivatedRoute
  ) {
    this._username = get(this.route, 'snapshot.params.username');
    this.initUserDetails(this._username).subscribe();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  initUserDetails = (username: string) => {
    this._global360ViewState = this.global360ViewContext.state$;
    this._selfServeOptionsState = this.selfServeOptionsContext.state$;

    if (this._isFirstRun) {
      this.global360ViewContext.init(username);
      this._isFirstRun = false;
    }

    return combineLatest([this._global360ViewState, this._selfServeOptionsState]).pipe(
      switchMap(([global360View, selfServeOptions]) => {
        this._selfServeOptions = selfServeOptions;

        return this._getSecondaryData(global360View);
      })
    );
  };

  userHasVoicemail = (voicemails: VoicemailRef[]): boolean => {
    return !!(voicemails && voicemails.length);
  };

  userHasExtensionMobility = (extensionMobilities: ExtensionMobilityRef[]): boolean => {
    return !!(extensionMobilities && extensionMobilities.length);
  };

  userHasSnr = (snrProfiles: SnrProfileRef[]): boolean => {
    return !!(snrProfiles && snrProfiles.length) && this._selfServeOptions.selfServeSnrEditEnabled;
  };

  userHasSnrDestinations = (snrDestinations: SnrDestination[][]): boolean => {
    return (
      snrDestinations &&
      snrDestinations.some((destinations) => !!destinations.length) &&
      this._selfServeOptions.selfServeSnrEditEnabled
    );
  };

  userHasCallForward = (primaryExtensions: DirectoryNumberRef[]): boolean => {
    return !!(primaryExtensions && primaryExtensions.length) && this._selfServeOptions.selfServeCallForwardEditEnabled;
  };

  userHasDeskphone = (phones: PhoneRef[]): boolean => {
    return !!(phones && phones.length) && this._selfServeOptions.selfServeSpeedDialEditEnabled;
  };

  hasSelfServeServices = (services: Global360View): boolean => {
    return (
      this.userHasVoicemail(services.voicemails) ||
      this.userHasExtensionMobility(services.extensionMobilities) ||
      this.userHasSnr(services.snrProfiles) ||
      this.userHasCallForward(services.primaryExtensions) ||
      this.userHasDeskphone(services.phones)
    );
  };

  /** Get phone status to communicate the phone's registration status on UI */
  private _getUserDetailPhoneStatus(name: string, cucmServerId: string | number): Observable<UserDetailPhoneStatus> {
    return this.phoneResource.getPhoneStatus(name, cucmServerId).pipe(
      map((phoneStatus: PhoneStatus) => {
        const status: DeviceRegistrationStatus = phoneStatus.status;
        return {
          status: String(status),
          label: statusToDisplayInfoMap[status].label,
          cssClass: statusToDisplayInfoMap[status].cssClass,
        };
      })
    );
  }

  /** Get SNR, DNs, and phones data based on Global Configuration settings. If permission is set in global config,
   *  these data can be accessed and edited */
  private _getSecondaryData = (global360View: Global360View) => {
    return forkJoin([this._getSnrDestinations(global360View), this._getDns(global360View)]).pipe(
      tap((data) => {
        this._forkJoinCb(data, global360View);
      })
    );
  };

  /** Set the Self Serve user's SNR profile, Primary Ext., and Phones from 360 View Resource on {@link _selfServe360ViewSource} */
  private _forkJoinCb = (reqData: any, global360View: Global360View) => {
    const snrContainers = [] as SnrContainer[];
    const dnContainers = [] as DnContainer[];
    let phoneContainers = [];

    /** Set the SNR destinations */
    if (this._selfServeOptions.selfServeSnrEditEnabled) {
      global360View.snrProfiles.forEach((snrProfileRef: SnrProfileRef, i: number) => {
        const dests = reqData[0][i];

        snrContainers.push({
          snrProfileRef,
          snrDestination: dests,
        });
      });
    }

    /** Set the DN if Call Forward is enabled in Global Configuration */
    if (this._selfServeOptions.selfServeCallForwardEditEnabled) {
      global360View.primaryExtensions.forEach((primaryExtension: DirectoryNumberRef, i: number) => {
        const dn = reqData[1][i];

        dnContainers.push({
          directoryNumberRef: primaryExtension,
          directoryNumber: dn,
        });
      });
    }

    /** Get the phone containers for Drag n Drop view from 360 view */
    phoneContainers = global360View.phones
      .filter((phoneRef: PhoneRef) => /^(SEP|SIP)/.test(phoneRef.name))
      .map((phoneRef: PhoneRef) => {
        return {
          phoneRef: phoneRef,
          statusAsync: this._getUserDetailPhoneStatus(phoneRef.name, phoneRef.serverId),
        };
      });
    const canActivateDeskphone = this._selfServeOptions.selfServeDeskphoneActivationEnabled;
    const canReplaceDeskphone = this._selfServeOptions.selfServeDeskphoneReplacementEnabled;
    const canProvisionSnr = this._selfServeOptions.selfServeSnrEditEnabled;

    this.selfServe360View = {
      ...global360View,
      phoneContainers,
      dnContainers,
      snrContainers,
      canActivateDeskphone,
      canReplaceDeskphone,
      canProvisionSnr,
    };

    this._selfServe360ViewSource.next(this.selfServe360View);
  };

  private _getSnrDestinations = (global360View: Global360View) => {
    let subs: Observable<SnrDestination[]>[] = [];

    if (this._selfServeOptions.selfServeSnrEditEnabled) {
      subs = global360View.snrProfiles.map((snrProfileRef: SnrProfileRef) => {
        return this.snrProfileResource.getSnrDestinations(snrProfileRef.id, snrProfileRef.serverId);
      });
    }

    return forkJoin(subs).pipe(defaultIfEmpty([null]));
  };

  private _getDns = (global360View: Global360View) => {
    let subs: Observable<DirectoryNumber>[] = [];

    if (this._selfServeOptions.selfServeCallForwardEditEnabled) {
      subs = global360View.primaryExtensions.map((primaryExtension: DirectoryNumberRef) => {
        return this.directoryNumberResource.get(primaryExtension.id, primaryExtension.serverId.toString());
      });
    }

    return forkJoin(subs).pipe(defaultIfEmpty([null]));
  };
}
