import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import {
  CustomMultiSelectFieldConfig,
  Microsoft365License,
  Microsoft365LicenseCount,
  Microsoft365LicenseSettings,
  SelectFieldConfig,
} from '../../../shared/models/generated/smacsModels';
import { SmacsFormConfig } from '../../../forms/smacs-forms-models';
import { ToastService } from '../../../shared/services/toast.service';
import { combineLatest, Subscription, throwError } from 'rxjs';
import { SmacsSelectFieldConfig } from '../../../shared/custom-configs/legacy-smacs-select-config/legacy-smacs-select-config.component';
import { SelectFieldConfigUi } from '../../cisco/dial-plan-mangement/dial-plan-mangement-edit/dial-plan-management-edit.component';
import { difference, sortBy } from 'lodash';
import { Microsoft365LicenseSettingsResource } from '../../../shared/resources/microsoft-365-license-settings.resource';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { catchError, tap } from 'rxjs/operators';
import {
  ReadWriteConfigType,
  SmacsReadWriteSelectFieldConfig,
} from '../../../shared/custom-configs/smacs-read-write-select-config/smacs-read-write-select-config.component';

// Custom entity to map Microsoft365LicenseCount to something the UI components can use
export interface MsLicenseManagementEntity {
  usageLocation: SelectFieldConfig<string>;
  licenses: CustomMultiSelectFieldConfig;
}

export interface MsLicenseManagementFormData {
  usage_location: SelectFieldConfigUi;
  licenses: SelectFieldConfigUi;
}

@Component({
  selector: 'app-microsoft-license-management-form',
  templateUrl: './license-management-form.component.html',
})
export class MicrosoftLicenseManagementFormComponent
  extends SmacsFormAbstractDirective<MsLicenseManagementEntity, MsLicenseManagementFormData>
  implements OnInit, OnDestroy
{
  @Input() usageLocationOptions: string[];
  @Input() licensesOptions: string[];
  @Input() ms365Licenses: Microsoft365LicenseCount[];

  type = ReadWriteConfigType.LICENSE;
  formConfig: SmacsFormConfig;

  private _subs = new Subscription();

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _bottomNavService: BottomNavService,
    private _toastService: ToastService,
    private _microsoft365LicenseSettingsResource: Microsoft365LicenseSettingsResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initBottomNav();
    this._initFormConfig();

    const bottomNavErrorStateSub = combineLatest([this._validateAndSubmitSource, this.smacsFormsUpdate$]).subscribe(
      () => {
        this._updateBottomNavErrorState();
      }
    );
    this._subs.add(bottomNavErrorStateSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
      })
    );
    this._subs.unsubscribe();
  }

  private _updateBottomNavErrorState() {
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: !this.isFormValid(),
      })
    );
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        usage_location: {
          label: 'tkey;admin.microsoft.license_management.usage_location.label',
          dataAutomation: 'ms-license-management-usage-location',
          componentConfig: new SmacsSelectFieldConfig(false, sortBy(this.usageLocationOptions), true, true, {
            description: 'tkey;admin.microsoft.license_management.usage_location.modal.about',
          }),
          helpText: 'tkey;admin.microsoft.license_management.usage_location.helptext',
        },
        licenses: {
          label: 'tkey;admin.microsoft.license_management.licenses.label',
          dataAutomation: 'ms-license-management-licenses',
          required: false,
          componentConfig: new SmacsReadWriteSelectFieldConfig({ availableOptions: sortBy(this.licensesOptions) }),
          helpText: 'tkey;admin.microsoft.license_management.licenses.helptext',
        },
      },
    };
  }

  private _initBottomNav = () => {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'ms-license-management-save',
          dataAutomation: 'ms-license-management-save',
          label: 'tkey;global.button.save.text',
          icon: SmacsIcons.OK,
          buttonClass: ButtonStyles.PRIMARY,
          cb: () => {
            this._validateAndSubmitSource.next(true);
          },
        },
      ])
    );
  };

  protected submit() {
    this._setPending('ms-license-management-save', true);
    const apiData = this._toApiData(this.entity);

    return this._microsoft365LicenseSettingsResource.put(apiData).pipe(
      tap(() => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.MICROSOFT_365,
          'tkey;admin.microsoft.license_management.title',
          'tkey;shared.toast.save.success.title'
        );
        this._setPending('ms-license-management-save', false);
      }),
      catchError((error) => {
        this._setPending('ms-license-management-save', false);
        return throwError(() => error);
      })
    );
  }

  toFormData = (entity: MsLicenseManagementEntity): MsLicenseManagementFormData => {
    return {
      usage_location: {
        name: 'usage_location',
        defaultValue: entity.usageLocation.defaultValue,
        defaultValues: [entity.usageLocation.defaultValue],
        possibleOptions: entity.usageLocation.possibleOptions,
        required: entity.usageLocation.required,
        show: entity.usageLocation.show,
      },
      licenses: {
        name: 'licenses',
        defaultValues: [...entity.licenses.defaultValues],
        possibleOptions: entity.licenses.possibleOptions,
        required: entity.licenses.required,
        show: entity.licenses.show,
      },
    } as MsLicenseManagementFormData;
  };

  toEntity = (form: MsLicenseManagementFormData): MsLicenseManagementEntity => {
    return {
      usageLocation: {
        show: form.usage_location.show,
        required: form.usage_location.required,
        defaultValue: form.usage_location.defaultValue,
        possibleOptions: form.usage_location.possibleOptions,
      },
      licenses: {
        show: form.licenses.show,
        required: form.licenses.required,
        defaultValues: form.licenses.defaultValues,
        possibleOptions: form.licenses.possibleOptions,
      },
    };
  };

  private _toApiData(entity: MsLicenseManagementEntity): Microsoft365LicenseSettings {
    const readOnlyValues = difference(entity.licenses.possibleOptions, entity.licenses.defaultValues);
    const writeValues = entity.licenses.defaultValues;

    const readOnlyLicenses = readOnlyValues.map(
      (value: string) => this._findLicenseByProductName(value) || this._findLicenseBySkuPartNumber(value)
    );
    const writeLicenses = writeValues.map(
      (value: string) => this._findLicenseByProductName(value) || this._findLicenseBySkuPartNumber(value)
    );

    return {
      usageLocation: {
        defaultValue: entity.usageLocation.defaultValue,
        possibleOptions: entity.usageLocation.possibleOptions,
        required: entity.usageLocation.required,
        show: entity.usageLocation.show,
      },
      readOnlyLicenseList: readOnlyLicenses,
      writeLicenseList: writeLicenses,
    };
  }

  private _findLicenseByProductName(name: string): Microsoft365License {
    return this.ms365Licenses.find(
      (license: Microsoft365LicenseCount) => license.microsoft365LicenseJson.productName === name
    )?.microsoft365LicenseJson;
  }

  private _findLicenseBySkuPartNumber(partNumber: string): Microsoft365License {
    return this.ms365Licenses.find(
      (license: Microsoft365LicenseCount) => license.microsoft365LicenseJson.skuPartNumber === partNumber
    )?.microsoft365LicenseJson;
  }

  private _setPending(buttonId: string, setting: boolean) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }
}
