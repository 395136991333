import { Component, Input, OnInit } from '@angular/core';
import { BandwidthPortInOrderNoteAndHistory } from '../../../../shared/models/generated/smacsModels';

@Component({
  selector: 'ziro-order-note',
  templateUrl: './order-note.component.html',
  styleUrls: ['./order-note.component.scss'],
})
export class OrderNoteComponent implements OnInit {
  @Input() note: BandwidthPortInOrderNoteAndHistory;
  @Input() isLastNote: boolean;
  @Input() index: number;

  iconColor: string;

  ngOnInit() {
    this.iconColor = this._determineIconColor(this.note.userId);
  }

  private _determineIconColor(username: string): string {
    if (!username || username.length < 6) {
      return '#000';
    }
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += (username.charCodeAt(i) % 16).toString(16);
    }
    return color;
  }
}
