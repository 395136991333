import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { MicrosoftSecurityGroupsFieldConfig } from '../models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';

@Injectable()
export class MicrosoftSecurityGroupsMembershipContext {
  private _stateSource = new ReplaySubject<MicrosoftSecurityGroupsFieldConfig>(1);
  state$ = this._stateSource.asObservable();

  constructor(private http: HttpClient) {
    this.refresh().subscribe();
  }

  refresh(): Observable<MicrosoftSecurityGroupsFieldConfig> {
    return this._getLicensesConfig().pipe(
      tap((securityGroupSettings: MicrosoftSecurityGroupsFieldConfig) => {
        this._stateSource.next(securityGroupSettings);
      })
    );
  }

  private _getLicensesConfig(): Observable<MicrosoftSecurityGroupsFieldConfig> {
    return this.http.get<MicrosoftSecurityGroupsFieldConfig>(
      '/services/microsoft/field-configs/security-group-permissions'
    );
  }
}
