import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { BreadcrumbCardItemComponent } from './breadcrumb-card-item/breadcrumb-card-item.component';
import { BreadcrumbsComponent } from './breadcrumbs.component';
import { AboutCardComponent } from '../about-card/about-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ButtonModule } from '../../button/button.module';

@NgModule({
  declarations: [BreadcrumbCardItemComponent, BreadcrumbsComponent, AboutCardComponent],
  exports: [BreadcrumbCardItemComponent, BreadcrumbsComponent, AboutCardComponent],
  imports: [BreadcrumbModule, CommonModule, TranslateModule, NgbModule, BrowserAnimationsModule, ButtonModule],
})
export class BreadcrumbsModule {}
