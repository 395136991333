import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';
import { isEqual } from 'lodash';

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  shouldReuseRoute(future: ActivatedRouteSnapshot, current: ActivatedRouteSnapshot): boolean {
    const isSameRoute = future.routeConfig === current.routeConfig;
    const isSameComponentConfig =
      !!future.routeConfig?.component && future.routeConfig?.component === current.routeConfig?.component;
    const isSameRouteParams = isEqual(future.params, current.params);

    return (isSameRoute || isSameComponentConfig) && isSameRouteParams;
  }
}
