import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HtmlInputType, SmacsTextConfig } from '../../../../../../forms/fields/text/smacs-text.component';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../../../forms/smacs-forms-models';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../../../forms/fields/select/smacs-select.component';
import {
  AdAttributes,
  BandwidthE911Dlr,
  BandwidthEmergencyCallingSettingsConfig,
  CallingType,
  EmergencyLocation,
  MicrosoftDialPlanFieldConfig,
  PstnConnectivityType,
  SharedCallingExtension,
  SharedCallingRoutingPolicy,
  TeamsPhoneNumberAssignment,
  TeamsPhoneNumberAssignmentFieldConfig,
  TeamsVoicePoliciesFieldConfig,
  TeamsVoicePolicies,
} from '../../../../../../shared/models/generated/smacsModels';
import { ActivatedRoute, Router } from '@angular/router';
import { Microsoft360ViewContext } from '../../../../../../shared/contexts/microsoft-360-view.context';
import { SmacsFormAbstractDirective } from '../../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../../../forms/smacs-form-state.service';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../../../../button/button.component';
import { SmacsIcons } from '../../../../../../shared/models/smacs-icons.enum';
import { catchError, EMPTY, forkJoin, Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import { ToastTypes } from '../../../../../../shared/services/abstract/toast.service.abstract';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { BottomNavButton } from '../../../../../../shared/bottom-nav/bottom-nav.component';
import { SmacsModalService } from '../../../../../../shared/services/smacs-modal.service';
import { TelephoneNumberFilter } from '../../../../../../shared/filters/telephone-number.filter';
import {
  BandwidthDynamicFormData,
  BandwidthEmergencyCallingFormComponent,
} from './bandwidth-e911-dlrs-form/bandwidth-emergency-calling-form.component';
import { BandwidthE911DlrsResource } from '../../../../../shared/resources/bandwidth-e911-dlrs.resource';
import { cloneDeep, isEqual } from 'lodash';
import { e164ValidatorRegex } from '../../../../../../shared/validators-regex';
import { HelpdeskAdAttributesComponent } from './helpdesk-ad-attributes/helpdesk-ad-attributes.component';
import { map } from 'rxjs/operators';
import { DraasSharedExtensionsResource } from '../../../../../shared/resources/draas-shared-extensions.resource';
import { MicrosoftOnPremAdResource } from '../../../../../shared/resources/microsoft-on-prem-ad.resource';
import { TeamsCallQueuesFormComponent } from './teams-call-queues/teams-call-queues-form.component';
import { MicrosoftPhoneNumberAssignmentsResource } from '../../../../../shared/resources/microsoft-phone-number-assignments.resource';
import { MicrosoftVoicePoliciesResource } from '../../../../../shared/resources/microsoft-voice-policies.resource';

export interface MsTeamsLine {
  lineUri: string;
  callingPolicy: string;
  teamsCallHoldPolicy: string;
  teamsCallParkPolicy: string;
  callingLineIdentity: string;
  tenantDialPlan: string;
  emergencyCallingPolicy: string;
  emergencyCallRoutingPolicy: string;
  onlineVoiceRoutingPolicy: string;
  onlineVoicemailPolicy: string;
  ipPhonePolicy: string;
  lineUriExtension: string;
  emergencyLocation: SmacsSelectOption;
}

@Component({
  selector: 'smacs-ms-teams-calling-form',
  templateUrl: './ms-teams-calling-form.component.html',
})
export class MsTeamsCallingFormComponent extends SmacsFormAbstractDirective<MsTeamsLine> implements OnInit, OnDestroy {
  @Input() phoneNumberAssignmentFieldConfig: TeamsPhoneNumberAssignmentFieldConfig;
  @Input() voicePoliciesFieldConfig: TeamsVoicePoliciesFieldConfig;
  @Input() teamsLine: TeamsPhoneNumberAssignment;
  @Input() voicePolicies: TeamsVoicePolicies;
  @Input() upn: string;
  @Input() onPremUpn: string;
  @Input() lineUri: string;
  @Input() bandwidthFieldConfig: BandwidthEmergencyCallingSettingsConfig;
  @Input() bandwidthEntity: BandwidthDynamicFormData;
  @Input() dialPlanFieldConfig: MicrosoftDialPlanFieldConfig;
  @Input() initialBandwidthEndpointId: string;
  @Input() isExtensionChanged: boolean;
  @Input() isBandwidthConfiguredWithoutE911Dlr: boolean;
  @Input() isResourceAccount: boolean;
  @Input() isAdWriteDownConfigured: boolean;
  @Input() isZpcSyncWarningPresent: boolean;
  @Input() mainNumber: string;
  @Input() initialZiroDraasSharedCallingExtension: SharedCallingExtension;
  @Input() initialLineUri: string;
  @Input() initialDialPlanGroup: MicrosoftDialPlanFieldConfig;
  @Input() sharedCallingPolicy: SharedCallingRoutingPolicy;
  @Input() isExistingSharedCalling: boolean;

  @ViewChild(BandwidthEmergencyCallingFormComponent)
  bandwidthEmergencyCallingFormComponent: BandwidthEmergencyCallingFormComponent;
  @ViewChild(HelpdeskAdAttributesComponent) helpdeskAdAttributesComponent: HelpdeskAdAttributesComponent;
  @ViewChild(TeamsCallQueuesFormComponent) callQueuesFormComponent: TeamsCallQueuesFormComponent;

  isDeleting = false;
  formConfig: SmacsFormConfig;
  smacsIcons = SmacsIcons;
  isExistingTeamsCalling = false;
  isExistingLineUri = false;
  isBandwidthFormValid: boolean;
  lineUriExtensionForDedicatedDid: string;
  private _subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private microsoft360ViewContext: Microsoft360ViewContext,
    private bottomNavService: BottomNavService,
    private translateService: TranslateService,
    private toastService: ToastService,
    private microsoftPhoneNumberAssignmentsResource: MicrosoftPhoneNumberAssignmentsResource,
    private microsoftVoicePoliciesResource: MicrosoftVoicePoliciesResource,
    private router: Router,
    private smacsModalService: SmacsModalService,
    private telephoneNumberFilter: TelephoneNumberFilter,
    private bandwidthE911DlrsResource: BandwidthE911DlrsResource,
    private draasSharedExtensionsResource: DraasSharedExtensionsResource,
    private _microsoftOnPremAdResource: MicrosoftOnPremAdResource,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._initFormConfig();
    this.isExistingTeamsCalling = !!this.teamsLine || this.isExistingSharedCalling;
    this.isExistingLineUri = !!this.teamsLine;
    this.lineUriExtensionForDedicatedDid = !!this.teamsLine?.lineUriExtension
      ? this.teamsLine.lineUriExtension
      : this.phoneNumberAssignmentFieldConfig?.lineUriExtension.defaultValue;
    this._setUpdateEntity();
    this._initBottomNav();

    const formSubmitSub = this._validateAndSubmitSource.subscribe(() => {
      if (this.bandwidthFieldConfig) {
        this.bandwidthEmergencyCallingFormComponent._validateAndSubmitSource.next(true);
      }
      this.isBandwidthFormValid = this.bandwidthFieldConfig
        ? this.bandwidthEmergencyCallingFormComponent.isFormValid()
        : true;
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError:
            !this.isFormValid() ||
            !this.isBandwidthFormValid ||
            (!!this.helpdeskAdAttributesComponent &&
              !this.helpdeskAdAttributesComponent.isFormValid() &&
              this.isAdWriteDownConfigured),
        })
      );
    });
    this._subscription.add(formSubmitSub);
    const updateSub = this.smacsFormsUpdate$.subscribe((update) => {
      if (cloneDeep(update.new?.lineUriExtension) !== cloneDeep(update.old?.lineUriExtension)) {
        this.handleExtensionUpdate(update.new.lineUriExtension);
      }
    });
    this._subscription.add(updateSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscription.unsubscribe();
  }

  isValidExtension(viewValue: string): boolean {
    return /^[0-9]{1,8}$/i.test(viewValue);
  }

  isPhoneNumber(viewValue: string): boolean {
    return e164ValidatorRegex.test(viewValue);
  }

  lineUriFormatValidation(viewValue: string, type: string): boolean {
    if (!viewValue) {
      return true;
    }
    return type === 'extension' ? this.isValidExtension(viewValue) : this.isPhoneNumber(viewValue);
  }

  handleAttributeUpdate() {
    const isBandwidthFormValid = this.bandwidthFieldConfig
      ? this.bandwidthEmergencyCallingFormComponent.isFormValid()
      : true;

    if (isBandwidthFormValid && this.helpdeskAdAttributesComponent.isFormValid() && this.isFormValid()) {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: false,
        })
      );
    }
  }

  handleExtensionUpdate(newValue: string) {
    const isExtensionValid =
      this.fieldComponents.find((comp) => comp.fieldId === 'lineUriExtension').validationState ===
      SmacsFormsValidationState.VALID;
    if (newValue.length && this.isAdWriteDownConfigured && !this.isZpcSyncWarningPresent && isExtensionValid) {
      this.helpdeskAdAttributesComponent.init(newValue);
    }
  }

  protected submit() {
    const isFormsValid =
      this.isAdWriteDownConfigured && !this.isZpcSyncWarningPresent && !!this.helpdeskAdAttributesComponent
        ? this.helpdeskAdAttributesComponent.isFormValid() && this.isBandwidthFormValid
        : this.isBandwidthFormValid;
    if (isFormsValid) {
      return this._onSaveClick();
    } else {
      return of(null);
    }
  }

  private _setUpdateEntity() {
    if (this.isExistingTeamsCalling) {
      const teamsVoicePolicies = this.voicePolicies;
      const updateEntity: MsTeamsLine = {
        lineUriExtension: this.teamsLine?.lineUriExtension,
        lineUri: this._isDialPlanExtensionType() ? this.teamsLine?.lineUriExtension : this.teamsLine?.lineUri,
        onlineVoiceRoutingPolicy: teamsVoicePolicies.onlineVoiceRoutingPolicy,
        callingPolicy: teamsVoicePolicies.callingPolicy,
        teamsCallHoldPolicy: teamsVoicePolicies.teamsCallHoldPolicy,
        teamsCallParkPolicy: teamsVoicePolicies.teamsCallParkPolicy,
        callingLineIdentity: teamsVoicePolicies.callingLineIdentity,
        tenantDialPlan: teamsVoicePolicies.tenantDialPlan,
        emergencyCallingPolicy: teamsVoicePolicies.emergencyCallingPolicy,
        emergencyCallRoutingPolicy: teamsVoicePolicies.emergencyCallRoutingPolicy,
        onlineVoicemailPolicy: teamsVoicePolicies.onlineVoicemailPolicy,
        ipPhonePolicy: teamsVoicePolicies.ipPhonePolicy,
        emergencyLocation: this.teamsLine
          ? this._emergencyLocationToSelectOption(this.teamsLine.emergencyLocation)
          : null,
      };
      this.entitySource.next({ ...updateEntity });
    } else {
      const newEntity: MsTeamsLine = {
        lineUri: this.lineUri,
        callingPolicy: this.voicePoliciesFieldConfig?.callingPolicy?.defaultValue,
        teamsCallHoldPolicy: this.voicePoliciesFieldConfig?.teamsCallHoldPolicy?.defaultValue,
        teamsCallParkPolicy: this.voicePoliciesFieldConfig?.teamsCallParkPolicy?.defaultValue,
        callingLineIdentity: this.voicePoliciesFieldConfig?.callingLineIdentity?.defaultValue,
        tenantDialPlan: this.voicePoliciesFieldConfig?.tenantDialPlan?.defaultValue,
        emergencyCallingPolicy: this.voicePoliciesFieldConfig?.emergencyCallingPolicy?.defaultValue,
        emergencyCallRoutingPolicy: this.voicePoliciesFieldConfig?.emergencyCallRoutingPolicy?.defaultValue,
        onlineVoiceRoutingPolicy: this.voicePoliciesFieldConfig?.onlineVoiceRoutingPolicy?.defaultValue,
        onlineVoicemailPolicy: this.voicePoliciesFieldConfig?.onlineVoicemailPolicy?.defaultValue,
        ipPhonePolicy: this.voicePoliciesFieldConfig?.ipPhonePolicy?.defaultValue,
        lineUriExtension: this.phoneNumberAssignmentFieldConfig?.lineUriExtension?.defaultValue,
        emergencyLocation: this._emergencyLocationToSelectOption(
          this.phoneNumberAssignmentFieldConfig?.emergencyLocation?.defaultValue
        ),
      };
      this.entitySource.next({ ...newEntity });
    }
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        callingPolicy: {
          label: 'tkey;shared.model.microsoft_teams.calling_policy.label',
          componentConfig: new SmacsSelectConfig({
            options: this.voicePoliciesFieldConfig?.callingPolicy?.possibleOptions,
          }),
          dataAutomation: 'microsoft-teams-calling-policy',
          required: () => this.voicePoliciesFieldConfig?.callingPolicy?.required,
          hidden: () => !this.voicePoliciesFieldConfig?.callingPolicy?.show,
          defaultValue: () => this.voicePoliciesFieldConfig?.callingPolicy?.defaultValue,
        },
        teamsCallHoldPolicy: {
          label: 'tkey;shared.model.microsoft_teams.teams_call_hold_policy.label',
          componentConfig: new SmacsSelectConfig({
            options: this.voicePoliciesFieldConfig?.teamsCallHoldPolicy?.possibleOptions,
          }),
          dataAutomation: 'microsoft-teams-call-hold-policy',
          required: () => this.voicePoliciesFieldConfig?.teamsCallHoldPolicy?.required,
          hidden: () => !this.voicePoliciesFieldConfig?.teamsCallHoldPolicy?.show,
          defaultValue: () => this.voicePoliciesFieldConfig?.teamsCallHoldPolicy?.defaultValue,
        },
        teamsCallParkPolicy: {
          label: 'tkey;shared.model.microsoft_teams.teams_call_park_policy.label',
          componentConfig: new SmacsSelectConfig({
            options: this.voicePoliciesFieldConfig?.teamsCallParkPolicy?.possibleOptions,
          }),
          dataAutomation: 'microsoft-teams-call-park-policy',
          required: () => this.voicePoliciesFieldConfig?.teamsCallParkPolicy?.required,
          hidden: () => !this.voicePoliciesFieldConfig?.teamsCallParkPolicy?.show,
          defaultValue: () => this.voicePoliciesFieldConfig?.teamsCallParkPolicy?.defaultValue,
        },
        callingLineIdentity: {
          label: 'tkey;shared.model.microsoft_teams.calling_line_identity.label',
          componentConfig: new SmacsSelectConfig({
            options: this.voicePoliciesFieldConfig?.callingLineIdentity?.possibleOptions,
          }),
          dataAutomation: 'microsoft-teams-calling-line-identity-policy',
          required: () => this.voicePoliciesFieldConfig?.callingLineIdentity?.required,
          hidden: () => !this.voicePoliciesFieldConfig?.callingLineIdentity?.show,
          defaultValue: () => this.voicePoliciesFieldConfig?.callingLineIdentity?.defaultValue,
        },
        tenantDialPlan: {
          label: 'tkey;shared.model.microsoft_teams.dial_plan.label',
          componentConfig: new SmacsSelectConfig({
            options: this.voicePoliciesFieldConfig?.tenantDialPlan?.possibleOptions,
          }),
          dataAutomation: 'microsoft-teams-tenant-dial-plan',
          required: () => this.voicePoliciesFieldConfig?.tenantDialPlan?.required,
          hidden: () => !this.voicePoliciesFieldConfig?.tenantDialPlan?.show,
          defaultValue: () => this.voicePoliciesFieldConfig?.tenantDialPlan?.defaultValue,
        },
        emergencyCallingPolicy: {
          label: 'tkey;shared.model.microsoft_teams.emergency_calling_policy.label',
          componentConfig: new SmacsSelectConfig({
            options: this.voicePoliciesFieldConfig?.emergencyCallingPolicy?.possibleOptions,
          }),
          dataAutomation: 'microsoft-teams-emergency-calling-policy',
          required: () => this.voicePoliciesFieldConfig?.emergencyCallingPolicy?.required,
          hidden: () => !this.voicePoliciesFieldConfig?.emergencyCallingPolicy?.show,
          defaultValue: () => this.voicePoliciesFieldConfig?.emergencyCallingPolicy?.defaultValue,
        },
        emergencyCallRoutingPolicy: {
          label: 'tkey;shared.model.microsoft_teams.emergency_call_routing_policy.label',
          componentConfig: new SmacsSelectConfig({
            options: this.voicePoliciesFieldConfig?.emergencyCallRoutingPolicy?.possibleOptions,
          }),
          dataAutomation: 'microsoft-teams-emergency-call-routing-policy',
          required: () => this.voicePoliciesFieldConfig?.emergencyCallRoutingPolicy?.required,
          hidden: () => !this.voicePoliciesFieldConfig?.emergencyCallRoutingPolicy?.show,
          defaultValue: () => this.voicePoliciesFieldConfig?.emergencyCallRoutingPolicy?.defaultValue,
        },
        onlineVoiceRoutingPolicy: {
          label: 'tkey;shared.model.microsoft_teams.voice_routing_policy.label',
          componentConfig: new SmacsSelectConfig({
            options: this.voicePoliciesFieldConfig?.onlineVoiceRoutingPolicy?.possibleOptions,
          }),
          dataAutomation: 'microsoft-teams-voice-routing-policy',
          required: () => this.voicePoliciesFieldConfig?.onlineVoiceRoutingPolicy?.required,
          hidden: () => !this.voicePoliciesFieldConfig?.onlineVoiceRoutingPolicy?.show,
          defaultValue: () => this.voicePoliciesFieldConfig?.onlineVoiceRoutingPolicy?.defaultValue,
          validation: [
            {
              validator: (val) => {
                if (this.sharedCallingPolicy && val !== 'Global') {
                  return SmacsFormsValidationState.INVALID;
                }
                return SmacsFormsValidationState.VALID;
              },
              message: 'tkey;microsoft_360.view.teams.calling.voice_policies.voice_routing.error.shared_calling',
            },
          ],
        },
        onlineVoicemailPolicy: {
          label: 'tkey;shared.model.microsoft_teams.online_voicemail_policy.label',
          componentConfig: new SmacsSelectConfig({
            options: this.voicePoliciesFieldConfig?.onlineVoicemailPolicy?.possibleOptions,
          }),
          dataAutomation: 'microsoft-teams-online-voicemail-policy',
          required: () => this.voicePoliciesFieldConfig?.onlineVoicemailPolicy?.required,
          hidden: () => !this.voicePoliciesFieldConfig?.onlineVoicemailPolicy?.show,
          defaultValue: () => this.voicePoliciesFieldConfig?.onlineVoicemailPolicy?.defaultValue,
        },
        ipPhonePolicy: {
          label: 'tkey;shared.model.microsoft_teams.ip_phone_policy.label',
          componentConfig: new SmacsSelectConfig({
            options: this.voicePoliciesFieldConfig?.ipPhonePolicy?.possibleOptions,
          }),
          dataAutomation: 'microsoft-teams-ip-phone-policy',
          required: () => this.voicePoliciesFieldConfig?.ipPhonePolicy?.required,
          hidden: () => !this.voicePoliciesFieldConfig?.ipPhonePolicy?.show,
          defaultValue: () => this.voicePoliciesFieldConfig?.ipPhonePolicy?.defaultValue,
        },
        emergencyLocation: {
          label: 'tkey;shared.model.microsoft_teams.emergency_location.label',
          componentConfig: new SmacsSelectConfig({
            options: this.phoneNumberAssignmentFieldConfig?.emergencyLocation.possibleOptions.map((option) =>
              this._emergencyLocationToSelectOption(option)
            ),
          }),
          dataAutomation: 'microsoft-teams-emergency-location',
          required: () => this.phoneNumberAssignmentFieldConfig?.emergencyLocation?.required,
          hidden: () => !this.phoneNumberAssignmentFieldConfig?.emergencyLocation?.show,
          defaultValue: () =>
            this._emergencyLocationToSelectOption(
              this.phoneNumberAssignmentFieldConfig?.emergencyLocation?.defaultValue
            ),
        },
        lineUriExtension: {
          label: 'tkey;shared.model.microsoft_teams.on_prem.line.uri.extension',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          validation: [
            {
              validator: (value: string) => {
                return this.lineUriFormatValidation(value, 'extension')
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID;
              },
              message: this.translateService.instant('tkey;shared.model.microsoft_teams.validation.extension.message'),
            },
          ],
          dataAutomation: 'microsoft-teams-line-extension',
          required: () => this.phoneNumberAssignmentFieldConfig?.lineUriExtension?.required,
          hidden: () => !this.phoneNumberAssignmentFieldConfig?.lineUriExtension?.show,
          defaultValue: () => this.phoneNumberAssignmentFieldConfig?.lineUriExtension?.defaultValue,
        },
      },
    } as SmacsFormConfig;
  }

  private _initBottomNav() {
    const buttonsList: BottomNavButton[] = [];
    buttonsList.push({
      id: 'microsoft-teams-calling-cancel',
      dataAutomation: 'microsoft-teams-calling-cancel',
      label: 'tkey;global.button.cancel.text',
      buttonClass: ButtonStyles.DEFAULT,
      cb: () => {
        this.router.navigate(['../'], { relativeTo: this.route });
      },
    });
    if (this.isExistingTeamsCalling) {
      buttonsList.push({
        id: 'microsoft-teams-calling-delete',
        label: 'tkey;dialogs.button.delete',
        buttonClass: ButtonStyles.DANGER,
        dataAutomation: 'microsoft-teams-calling-delete',
        state: {
          pending: false,
          buttonDisableState: {
            disabled: false,
            tooltipKey: '',
          },
        },
        icon: this.smacsIcons.DELETE,
        cb: () => this._onDeleteClick(),
      });
    }
    buttonsList.push({
      id: 'microsoft-teams-calling-save',
      dataAutomation: 'microsoft-teams-calling-save',
      label: 'tkey;microsoft_360.view.teams.bottom_nav.save_button.text',
      icon: this.smacsIcons.OK,
      buttonClass: ButtonStyles.PRIMARY,
      type: ButtonTypes.SUBMIT,
      submitSubject: this._validateAndSubmitSource,
    });
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttonsList));
  }

  private _onSaveClick(): Observable<void> {
    this._setPending(true);
    const submitObs: Observable<any>[] = [];

    if (!this.sharedCallingPolicy) {
      const teamsLine: TeamsPhoneNumberAssignment = {
        userPrincipalName: this.upn,
        lineUri: this._getLineUri(),
        lineUriExtension: this._getLineUriExtension(),
        phoneNumberType: this.phoneNumberAssignmentFieldConfig.phoneNumberType,
        emergencyLocation: this.formData.emergencyLocation ? this.formData.emergencyLocation.value : null,
      };

      if (this.isExistingLineUri) {
        submitObs.push(this.microsoftPhoneNumberAssignmentsResource.put(this.upn, teamsLine));
      } else {
        submitObs.push(this.microsoftPhoneNumberAssignmentsResource.post(this.upn, teamsLine));
      }
    }

    const voicePolicies: TeamsVoicePolicies = {
      userPrincipalName: this.upn,
      onlineVoiceRoutingPolicy: this.formData.onlineVoiceRoutingPolicy ? this.formData.onlineVoiceRoutingPolicy : '',
      callingPolicy: this.formData.callingPolicy ? this.formData.callingPolicy : '',
      teamsCallHoldPolicy: this.formData.teamsCallHoldPolicy ? this.formData.teamsCallHoldPolicy : '',
      teamsCallParkPolicy: this.formData.teamsCallParkPolicy ? this.formData.teamsCallParkPolicy : '',
      callingLineIdentity: this.formData.callingLineIdentity ? this.formData.callingLineIdentity : '',
      tenantDialPlan: this.formData.tenantDialPlan ? this.formData.tenantDialPlan : '',
      emergencyCallingPolicy: this.formData.emergencyCallingPolicy ? this.formData.emergencyCallingPolicy : '',
      emergencyCallRoutingPolicy: this.formData.emergencyCallRoutingPolicy
        ? this.formData.emergencyCallRoutingPolicy
        : '',
      onlineVoicemailPolicy: this.formData.onlineVoicemailPolicy ? this.formData.onlineVoicemailPolicy : '',
      ipPhonePolicy: this.formData.ipPhonePolicy ? this.formData.ipPhonePolicy : '',
      sharedCallRoutingPolicy: this.sharedCallingPolicy || {
        policyName: 'Global',
        resourceAccountUpn: '',
        resourceAccountDisplayName: '',
        resourceAccountLineUri: '',
      },
    };
    submitObs.push(
      // these need to be done sequentially!! otherwise enterpriseVoiceEnabled will be false and their calling won't work
      this._deleteExistingTeamsLineIfDowngradingToSharedCalling().pipe(
        switchMap(() => this.microsoftVoicePoliciesResource.put(this.upn, voicePolicies, this.isResourceAccount))
      )
    );

    if (
      !!this.helpdeskAdAttributesComponent &&
      this.helpdeskAdAttributesComponent.isFormValid() &&
      this.isAdWriteDownConfigured
    ) {
      submitObs.push(this.helpdeskAdAttributesComponent.submitAdAttributeForm());
    }

    const lineUri = this._getLineUri();
    const lineUriExtension = this._getLineUriExtension();
    if (
      this.initialZiroDraasSharedCallingExtension &&
      (this.initialZiroDraasSharedCallingExtension.extension !== lineUriExtension || this.initialLineUri !== lineUri)
    ) {
      submitObs.push(
        this.draasSharedExtensionsResource.deleteExtension(
          this.initialLineUri,
          this.initialZiroDraasSharedCallingExtension.extension
        )
      );
    }

    if (this._isDraasSharedMainNumberDialPlan()) {
      const draasExtensionObs = this.draasSharedExtensionsResource.getAllExtensions(lineUri).pipe(
        switchMap((e164Extensions) => {
          if (!e164Extensions.sharedExtensions.some((ext) => ext.extension === lineUriExtension)) {
            return this.draasSharedExtensionsResource.postExtension(lineUri, {
              sharedCallingExtension: lineUriExtension,
            });
          } else {
            return of(null);
          }
        })
      );
      submitObs.push(draasExtensionObs);
    }

    if (!!this.callQueuesFormComponent && this.callQueuesFormComponent.isDataInitialized) {
      submitObs.push(this.callQueuesFormComponent.saveQueues());
    }

    if (
      !this.dialPlanFieldConfig.onPremAdWriteDownEnabled &&
      this.initialDialPlanGroup?.onPremAdWriteDownEnabled &&
      !this.isZpcSyncWarningPresent &&
      !!this.onPremUpn
    ) {
      const emptyAttributesSub = this._microsoftOnPremAdResource
        .getAdAttributes(this.onPremUpn, this.initialDialPlanGroup.id)
        .pipe(
          switchMap((existingAttribs: AdAttributes) => {
            if (existingAttribs.adWriteDownListJson.length) {
              return this._microsoftOnPremAdResource.putAdAttributes(this.onPremUpn, this.initialDialPlanGroup.id, {
                ...existingAttribs,
                adWriteDownListJson: existingAttribs.adWriteDownListJson.map((attr) => {
                  return {
                    attributeName: attr.attributeName,
                    attributeValue: '',
                  };
                }),
              });
            } else {
              return of(null);
            }
          })
        );
      submitObs.push(emptyAttributesSub);
    }

    return forkJoin(submitObs).pipe(
      map(() => {
        this.microsoft360ViewContext.refresh(this.upn).subscribe(() => {
          const onSuccess = () => {
            this.smacsFormStateService.setIsFormDirty(false);
            this.toastService.push(
              ToastTypes.SUCCESS,
              SmacsIcons.MICROSOFT_TEAMS_CALLING,
              'tkey;shared.toast.save.success.title',
              this.translateService.instant('tkey;shared.toast.save.success.message', {
                type: this.translateService.instant('tkey;shared.model.microsoft_teams_calling.text'),
                name: this.upn,
              })
            );
            this.router.navigate(['../'], { relativeTo: this.route });
          };

          if (this.bandwidthFieldConfig) {
            const body = {
              id: this.bandwidthFieldConfig.dynamicGeolocationEndpointId.value,
              geolocation: this.bandwidthEmergencyCallingFormComponent.formData.dynamicGeolocations,
              callerName: this.bandwidthFieldConfig.dynamicGeolocationCallerName.value,
              callbackNumber: this.bandwidthFieldConfig.dynamicGeolocationCallbackNumber.value,
              preferredLanguage: this.bandwidthFieldConfig.dynamicGeolocationPreferredLanguage.defaultOption,
            } as BandwidthE911Dlr;
            if (!this.isExistingTeamsCalling || this.isBandwidthConfiguredWithoutE911Dlr) {
              // If this is a new line, simply perform a POST to create our Bandwidth Data
              this.bandwidthE911DlrsResource.post(body).subscribe(() => {
                onSuccess();
              });
            } else if (
              !isEqual(this.bandwidthEntity, this.bandwidthEmergencyCallingFormComponent.formData) &&
              !this.isExtensionChanged
            ) {
              // If this is an existing line and the geolocation input value has changed but the
              // extension has not changed, perform a simple PUT to the endpoint ID because no DELETE is required
              this.bandwidthE911DlrsResource.put(body).subscribe(() => {
                onSuccess();
              });
            } else if (
              this.isExtensionChanged &&
              !!this.initialBandwidthEndpointId &&
              !this.isBandwidthConfiguredWithoutE911Dlr
            ) {
              // If the extension has changed then we must DELETE the existing Bandwidth data and POST an updated set
              this.bandwidthE911DlrsResource.delete(this.initialBandwidthEndpointId).subscribe(() => {
                this.bandwidthE911DlrsResource.post(body).subscribe(() => {
                  onSuccess();
                });
              });
            } else {
              onSuccess();
            }
          } else {
            onSuccess();
          }
        });
      }),
      catchError((response) => {
        this._setPending(false);
        this.isSubmitting = false;
        if (response.status === 422) {
          this.toastService.push(
            ToastTypes.ERROR,
            `${this.smacsIcons.MICROSOFT_TEAMS_CALLING} text-danger`,
            'tkey;shared.toast.save.fail.title',
            response.error.description
          );
          return throwError(() => EMPTY);
        } else {
          return throwError(() => response);
        }
      })
    );
  }

  private _onDeleteClick() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: encodeURIComponent('text-danger'),
        title: this.translateService.instant('tkey;microsoft_360.view.teams.calling.delete.modal.title'),
        promptBody: this.translateService.instant('tkey;microsoft_360.view.teams.calling.delete.modal.text', {
          number: this.telephoneNumberFilter.transform(this.formData.lineUri),
          userPrincipalName: this.upn,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._onConfirmDelete(),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _onConfirmDelete(): Observable<void> {
    this.isDeleting = true;

    const tasks$: Observable<void>[] = [];

    tasks$.push(this.microsoftVoicePoliciesResource.delete(this.upn, this.isResourceAccount));
    if (this.isExistingLineUri) {
      tasks$.push(this.microsoftPhoneNumberAssignmentsResource.delete(this.upn));
    }
    if (!!this.helpdeskAdAttributesComponent && this.isAdWriteDownConfigured && !this.isZpcSyncWarningPresent) {
      tasks$.push(this.helpdeskAdAttributesComponent.emptyAdAttributes());
    }
    if (!!this.bandwidthFieldConfig && this.initialBandwidthEndpointId && !this.isBandwidthConfiguredWithoutE911Dlr) {
      tasks$.push(this.bandwidthE911DlrsResource.delete(this.initialBandwidthEndpointId));
    }
    const lineUri = this._getLineUri();
    const lineUriExtension = this._getLineUriExtension();
    if (this._isDraasSharedMainNumberDialPlan()) {
      const sharedExtensionObs = this.draasSharedExtensionsResource.getAllExtensions(lineUri).pipe(
        switchMap((e164Extensions) => {
          if (e164Extensions.sharedExtensions.some((ext) => ext.extension === lineUriExtension)) {
            return this.draasSharedExtensionsResource.deleteExtension(lineUri, lineUriExtension);
          } else {
            return of(null);
          }
        })
      );
      tasks$.push(sharedExtensionObs);
    }
    return forkJoin(tasks$).pipe(
      switchMap(() => this.microsoft360ViewContext.refresh(this.upn)),
      map(() => {
        this.toastService.push(
          ToastTypes.INFO,
          `${this.smacsIcons.DELETE} text-danger`,
          'tkey;shared.toast.delete.success.title',
          `${this.translateService.instant('tkey;microsoft_360.view.teams.calling.toast.text')} - <strong>${
            this.upn
          } | ${this.teamsLine?.lineUri || this.sharedCallingPolicy?.resourceAccountLineUri}
            </strong>`
        );
        this.smacsFormStateService.setIsFormDirty(false);
        this.router.navigate(['../'], { relativeTo: this.route });
      })
    );
  }

  private _setPending(setting: boolean) {
    if (this.bandwidthFieldConfig) {
      this.bandwidthEmergencyCallingFormComponent.isSubmitting = setting;
    }
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'microsoft-teams-calling-cancel',
        state: {
          buttonDisableState: { disabled: setting || this.isDeleting, tooltipKey: '' },
        },
      })
    );
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'microsoft-teams-calling-delete',
        state: {
          buttonDisableState: { disabled: setting || this.isDeleting, tooltipKey: '' },
        },
      })
    );
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'microsoft-teams-calling-save',
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting || this.isDeleting, tooltipKey: '' },
        },
      })
    );
  }

  private _getLineUri(): string {
    if (this._isDialPlanExtensionType()) {
      return this.dialPlanFieldConfig.mainNumber || '';
    }
    return this.lineUri || '';
  }

  private _getLineUriExtension(): string {
    if (this._isDialPlanExtensionType()) {
      return this.formData.lineUri;
    }
    return this.formData.lineUriExtension ? this.formData.lineUriExtension : '';
  }

  private _isDialPlanExtensionType(): boolean {
    return this.dialPlanFieldConfig && this.dialPlanFieldConfig.callingType === CallingType.EXTENSION;
  }

  private _isDraasSharedMainNumberDialPlan(): boolean {
    const ziroDraasPstnTypes = [PstnConnectivityType.ZIRO_DRAAS, PstnConnectivityType.ZIRO_DRAAS_BYOC];
    return (
      this.dialPlanFieldConfig?.callingType === CallingType.EXTENSION &&
      ziroDraasPstnTypes.includes(this.dialPlanFieldConfig?.pstnConnectivityType)
    );
  }

  private _emergencyLocationToSelectOption(emergencyLocation: EmergencyLocation): SmacsSelectOption {
    if (!emergencyLocation) {
      return null;
    }
    return {
      label: emergencyLocation.description,
      value: emergencyLocation,
    };
  }

  private _deleteExistingTeamsLineIfDowngradingToSharedCalling(): Observable<void> {
    if (!this.sharedCallingPolicy || !this.isExistingLineUri) {
      return of(null);
    }
    return this.microsoftPhoneNumberAssignmentsResource.delete(this.upn);
  }
}
