import { Injectable } from '@angular/core';
import { forkJoin, Observable, of, switchMap } from 'rxjs';
import {
  DubberEndUser,
  DubberEndUserRef,
  DubPoint,
  DubPointRef,
  DubPointsResult,
  MicrosoftTeamsComplianceRecordingPolicy,
} from '../../../shared/models/generated/smacsModels';
import { DubberFieldConfigResource } from '../../../shared/resources/dubber-field-config.resource';
import { MicrosoftTeamsComplianceRecordingPolicyRequest } from '../../microsoft/dubber-compliance-recording/dubber-compliance-recording-form/dubber-compliance-recording-form.component';
import { Nvp } from '../../../shared/models/nvp';
import { HttpClient } from '@angular/common/http';
import { AuditHeaderService } from '../../../shared/services/audit-header.service';

export interface DubPointAndResult {
  dubPointsResult: DubPointsResult;
  dubPoint: DubPoint;
}
@Injectable()
export class DubberService {
  constructor(
    private _dubberFieldConfigResource: DubberFieldConfigResource,
    protected http: HttpClient,
    private _auditHeaderService: AuditHeaderService
  ) {}

  // Dubber end user
  createDubberUser(endUser: DubberEndUser, region: string, auditTags?: Nvp[]): Observable<DubberEndUserRef> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.post<DubberEndUserRef>(
      `/services/microsoft/macs/dubber-regions/${region}/dubber-users`,
      endUser,
      httpOptions
    );
  }

  getDubberEndUser(dubberRegionId: string, dubberUserId: string): Observable<DubberEndUser> {
    return this.http.get<DubberEndUser>(
      `/services/microsoft/macs/dubber-regions/${dubberRegionId}/dubber-users/${encodeURIComponent(dubberUserId)}`
    );
  }

  updateDubberEndUser(
    dubberRegionId: string,
    dubberUserId: string,
    body: DubberEndUser,
    auditTags: Nvp[]
  ): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.put<void>(
      `/services/microsoft/macs/dubber-regions/${dubberRegionId}/dubber-users/${encodeURIComponent(dubberUserId)}`,
      body,
      httpOptions
    );
  }

  deleteDubberUser(region: string, dubberUserId: string, upn: string, auditTags: Nvp[]): Observable<[void, void]> {
    const deleteDubberEndUser$ = this._deleteDubberEndUser(region, dubberUserId);
    return forkJoin([
      this.deleteDubPointIfExists(region, dubberUserId, auditTags).pipe(switchMap(() => deleteDubberEndUser$)),
      this._getAndUpdateCompliancePolicy(upn),
    ]);
  }

  // Dub Points

  createDubPoint(dubPoint: DubPoint, region: string, dubberUserId: string, auditTags?: Nvp[]): Observable<DubPointRef> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.post<DubPointRef>(
      `/services/microsoft/macs/dubber-regions/${region}/dubber-users/${encodeURIComponent(dubberUserId)}/dub-points`,
      dubPoint,
      httpOptions
    );
  }

  deleteDubPointIfExists(region: string, dubberUserId: string, auditTags: Nvp[]): Observable<void> {
    return this._getUserDubPointsAndThrowErrorIfMultiple(region, dubberUserId).pipe(
      switchMap((dubPointsResult) => {
        const dubPointId = Boolean(dubPointsResult.dubPointRefJsons.length)
          ? dubPointsResult.dubPointRefJsons[0].id
          : null;
        return Boolean(dubPointsResult.dubPointRefJsons.length)
          ? this._deleteDubPoint(region, dubberUserId, dubPointId, auditTags)
          : of(null);
      })
    );
  }

  getDubPointsResultAndDubPointByRegionAndUserId(
    dubberRegionId: string,
    dubberUserId: string
  ): Observable<DubPointAndResult> {
    return this._getUserDubPointsAndThrowErrorIfMultiple(dubberRegionId, dubberUserId).pipe(
      switchMap((dubPointsResult: DubPointsResult) => {
        return Boolean(dubPointsResult.dubPointRefJsons.length)
          ? this._getDubPoint(dubberRegionId, dubberUserId, dubPointsResult.dubPointRefJsons[0].id).pipe(
              switchMap((dubPoint) => of({ dubPointsResult, dubPoint }))
            )
          : of(null);
      })
    );
  }

  // Compliance Recording Policy

  getUserComplianceRecordingPolicy(upn: string): Observable<MicrosoftTeamsComplianceRecordingPolicy> {
    return this.http.get<MicrosoftTeamsComplianceRecordingPolicy>(
      `/services/microsoft/macs/teams-compliance-recording-policies/${encodeURIComponent(upn)}`
    );
  }

  updateUserComplianceRecordingPolicy(
    body: MicrosoftTeamsComplianceRecordingPolicyRequest,
    auditTags?: Nvp[]
  ): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.put<void>(
      `/services/microsoft/macs/teams-compliance-recording-policies/${encodeURIComponent(body.userPrincipalName)}`,
      body,
      httpOptions
    );
  }

  private _getAndUpdateCompliancePolicy(upn: string): Observable<void> {
    return this._dubberFieldConfigResource.getComplianceRecordingPolicy(false).pipe(
      switchMap((complianceFieldConfig) => {
        const recordingPolicy: MicrosoftTeamsComplianceRecordingPolicyRequest = {
          complianceRecordingPolicy: complianceFieldConfig.complianceRecordingPolicy.defaultOption,
          userPrincipalName: upn,
        };
        return this.updateUserComplianceRecordingPolicy(recordingPolicy);
      })
    );
  }

  private _deleteDubberEndUser(dubberRegionId: string, dubberUserId: string, auditTags?: Nvp[]): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.delete<void>(
      `/services/microsoft/macs/dubber-regions/${dubberRegionId}/dubber-users/${encodeURIComponent(dubberUserId)}`,
      httpOptions
    );
  }
  private _getDubPoint(dubberRegionId: string, dubberUserId: string, dubPointId: string): Observable<DubPoint> {
    return this.http.get<DubPoint>(
      `/services/microsoft/macs/dubber-regions/${dubberRegionId}/dubber-users/${encodeURIComponent(
        dubberUserId
      )}/dub-points/${dubPointId}`
    );
  }

  private _getUserDubPoints(dubberRegionId: string, dubberUserId: string): Observable<DubPointsResult> {
    return this.http.get<DubPointsResult>(
      `/services/microsoft/macs/dubber-regions/${dubberRegionId}/dubber-users/${encodeURIComponent(
        dubberUserId
      )}/dub-points`
    );
  }

  private _getUserDubPointsAndThrowErrorIfMultiple(region: string, dubberUserId: string): Observable<DubPointsResult> {
    return new Observable((subscriber) => {
      this._getUserDubPoints(region, dubberUserId).subscribe((dubPointsResult) => {
        if (dubPointsResult.dubPointRefJsons.length > 1) {
          throw new Error('Users with multiple dubpoints configured are not supported');
        } else {
          subscriber.next(dubPointsResult);
          subscriber.complete();
        }
      });
    });
  }

  private _deleteDubPoint(
    dubberRegionId: string,
    dubberUserId: string,
    dubPointId: string,
    auditTags?: Nvp[]
  ): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.delete<void>(
      `/services/microsoft/macs/dubber-regions/${dubberRegionId}/dubber-users/${encodeURIComponent(
        dubberUserId
      )}/dub-points/${dubPointId}`,
      httpOptions
    );
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this._auditHeaderService.buildHeader(auditTags) } : {};
  }
}
