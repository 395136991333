<div class="row mb-3 self-serve-back">
  <div class="col-12">
    <a [routerLink]="link" class="btn btn-link" id="self-serve-back" data-automation="self-serve-user-details-back">
      <i class="fa fa-chevron-circle-left"></i>

      <span>
        {{ 'tkey;selfserve.back.button' | translate }}
      </span>
    </a>
  </div>
</div>
