import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnInit,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ModalButton } from '../modal-button';
import { Observable, of } from 'rxjs';
import { ModalBodyClass } from '../modal-body';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { ButtonSizes } from '../../button/button.component';

export interface ZiroModalPromptModalOptions extends NgbModalOptions {
  modalViewProperties?: ZiroPromptModalViewProperties;
  bodyClass?: any;
}

export interface ZiroPromptModalViewProperties {
  buttons?: ModalButton[];
  displayCloseButton?: boolean;
  icon?: SmacsIcons | string;
  iconClass?: string;
  iconStackedClasses?: IconsStacked;
  promptBody?: string;
  title?: string;
  modalBodyClass?: string;
}

export interface IconsStacked {
  bottomIcon: SmacsIcons;
  bottomIconCssClass?: string;
  topIcon: SmacsIcons;
  topIconCssClass?: string;
}

@Component({
  selector: 'smacs-prompt-modal',
  templateUrl: './prompt-modal.component.html',
  styleUrls: ['./prompt-modal.component.scss'],
})
export class PromptModalComponent<T extends ModalBodyClass> implements OnInit, AfterViewInit {
  @ViewChild('modalBody', { read: ViewContainerRef }) modalBody: ViewContainerRef;

  modalViewProperties: any;
  modalButtons: ModalButton[];
  displayCloseButton: false;
  buttonSizes = ButtonSizes;
  icons = SmacsIcons;
  iconClass: IconsStacked;
  isPending = false;
  clickedButton: ModalButton;
  iconStackedClasses: any;

  // These properties are set from smacs-modal service when we open the prompt modal.
  bodyClass: Type<T>;
  submitFn: (...args: any) => Observable<any>;
  bodyComponent: ComponentRef<T>;
  constructor(private activeModal: NgbActiveModal, private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.modalViewProperties.buttons && this.modalViewProperties.buttons.length > 0) {
      this.modalButtons = this.modalViewProperties.buttons;
    }
    this.displayCloseButton = this.modalViewProperties.displayCloseButton;
    this.iconClass = this.modalViewProperties.iconClass;
    this.iconStackedClasses = this.modalViewProperties.iconStackedClasses;
  }

  ngAfterViewInit() {
    // Get the class constructor from the given type.
    if (this.bodyClass) {
      // Create component and add it to the DOM as a child element of the #modalBody element.
      this.bodyComponent = this.modalBody.createComponent(this.bodyClass);
      this.bodyComponent.instance.submitFn = this.submitFn;
      this.changeDetectorRef.detectChanges();
    }
  }

  onSubmit() {
    // Call submit on the body so that we can use the data therein.
    if (!!this.bodyClass && this.bodyComponent.instance.isFormValid()) {
      return this.bodyComponent.instance.submit().subscribe((result) => {
        if (this.clickedButton.cb) {
          this.clickedButton.cb().subscribe(() => {
            this.isPending = false;
            this.activeModal.close(result);
          });
        } else {
          this.isPending = false;
          this.activeModal.close(result);
        }
      });
    } else {
      return of(null);
    }
  }

  onCancelClick(): void {
    if (!this.isPending) {
      this.activeModal.close(false);
    }
  }

  onButtonClicked(button: ModalButton) {
    if (button.isSubmitButton) {
      if (!!this.bodyComponent && this.bodyComponent.instance.isFormValid()) {
        const isPending = (!!this.bodyComponent && this.bodyComponent.instance.isFormValid()) || !this.bodyComponent;
        this.clickedButton = button;
        button.isPending = isPending;
        this.isPending = isPending;
        this.onSubmit();
      } else {
        this.bodyComponent?.instance._validateAndSubmitSource.next(true);
      }
    } else if (button.cb) {
      this.bodyComponent?.instance._validateAndSubmitSource.next(true);
      this.isPending = true;
      button.isPending = true;
      button.cb().subscribe({
        next: (response) => {
          this.isPending = false;
          this.activeModal.close(response || null);
        },
        error: (err) => {
          this.isPending = false;
          this.activeModal.dismiss(err || null);
          throw err;
        },
      });
    } else {
      this.activeModal.close(false);
    }
  }
}
