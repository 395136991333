import { Pipe, PipeTransform } from '@angular/core';
import { ServerLdapSyncInfo } from '../../../shared/models/generated/smacsModels';

@Pipe({ name: 'ldapSyncStatusFilter' })
export class LdapSyncStatusFilter implements PipeTransform {
  transform(serverLdapSyncInfo: ServerLdapSyncInfo): string {
    const statusIconCssMap = {
      SYNCING: 'text-warning fa-refresh fa-spin',
      IDLE: 'text-success fa-check',
      ERROR: 'text-danger fa-exclamation animated tada infinite',
    };

    return statusIconCssMap[serverLdapSyncInfo.status];
  }
}
