import { Component, Input, OnInit, Output } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig, SmacsFormsUpdate } from '../../../../forms/smacs-forms-models';
import { Subject } from 'rxjs';
import { ModelProtocolFieldConfig } from '../../../../shared/models/generated/smacsModels';
import { PhoneType } from '../../../../shared/models/service-type';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { KnownPhoneModels } from '../../../shared/contexts/user-detail-ui.context';
import { ActivatedRoute, Router } from '@angular/router';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { filter, first } from 'rxjs/operators';
import { PhoneUiContext } from '../../../../shared/phone-buttons/contexts/phone-ui.context';

export interface ModelProtocolFormData {
  model: string;
  protocol: string;
}

@Component({
  selector: 'smacs-phone-model-protocol-form',
  templateUrl: './phone-model-protocol-form.component.html',
  providers: [{ provide: SmacsFormAbstractDirective, useExisting: PhoneModelProtocolFormComponent }],
})
export class PhoneModelProtocolFormComponent
  extends SmacsFormAbstractDirective<ModelProtocolFormData>
  implements OnInit
{
  @Input() phoneType: PhoneType;
  @Input() fieldConfig: ModelProtocolFieldConfig;
  @Input() isGeneratingPhone: boolean;

  private _modelProtocolUpdateSource = new Subject<SmacsFormsUpdate<ModelProtocolFormData>>();
  @Output() modelProtocolUpdate$ = this._modelProtocolUpdateSource.asObservable();

  formConfig: SmacsFormConfig;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private route: ActivatedRoute,
    private router: Router,
    private phoneUiContext: PhoneUiContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    const isExisting = this.route.snapshot.params.phoneId && !this.router.url.endsWith('copy');
    this.setIsExisting(isExisting);
    this._initFormConfig();

    this.smacsFormsUpdate$.subscribe((update) => {
      if (update.new.model !== update.old?.model || (update.new.model && !update.new.protocol)) {
        this._setProtocolConfig(update.new);
      }
      if (update.new.model && update.new.protocol) {
        this._modelProtocolUpdateSource.next(update);
      }
    });
  }

  submit() {
    return this.phoneUiContext.isSaving$.pipe(
      filter((isSaving) => !isSaving),
      first()
    );
  }

  private _initFormConfig() {
    const protocolConfig = this.fieldConfig.modelSpecificProtocols[this.entity.model];
    const protocolPossibleOptions = protocolConfig?.possibleOptions || [];

    this.formConfig = {
      fields: {
        model: {
          label: 'tkey;pages.details.phoneSettings.model',
          dataAutomation: 'phone-model-select',
          componentConfig: new SmacsSelectConfig({
            options: this.fieldConfig.models.possibleOptions,
          }),
          defaultValue: () =>
            this.phoneType === PhoneType.DESKPHONE || this.phoneType === PhoneType.EXTENSION_MOBILITY
              ? this.fieldConfig.models.defaultValue
              : KnownPhoneModels[this.phoneType?.toUpperCase().replace(' ', '_') as keyof typeof KnownPhoneModels],
          required: this.fieldConfig.models.required,
          hidden: () => !this.fieldConfig.models.show,
          disabled: () => this.isGeneratingPhone || this.isSubmitting,
        },
        protocol: {
          label: 'tkey;pages.details.phoneSettings.protocol.label',
          dataAutomation: 'phone-protocol-select',
          componentConfig: new SmacsSelectConfig({ options: protocolPossibleOptions }),
          required: true,
          hidden: () => {
            const protocolConfig = this.fieldConfig.modelSpecificProtocols[this.formData?.model];
            return !protocolConfig || !protocolConfig.show;
          },
          disabled: () => this.isGeneratingPhone || this.isSubmitting,
        },
      },
    };
  }

  private _setProtocolConfig(entity: ModelProtocolFormData) {
    const protocolConfig = this.fieldConfig.modelSpecificProtocols[entity.model];
    const protocolPossibleOptions = protocolConfig?.possibleOptions || [];
    const oldProtocol = entity.protocol;

    if (protocolPossibleOptions.length === 1) {
      entity.protocol = protocolPossibleOptions[0];
    } else if (protocolPossibleOptions.length === 2 && (!this.entity.protocol || protocolConfig.defaultValue === '')) {
      entity.protocol = protocolConfig.defaultValue;
    }

    const protocolField = this.fieldComponents.find((field) => field.fieldId === 'protocol');
    protocolField.applyComponentConfig(new SmacsSelectConfig({ options: protocolPossibleOptions }));
    if (entity.protocol !== oldProtocol) {
      const protocolChannel = this.fieldChannels['protocol'];
      protocolChannel.entitySource.next(entity.protocol);
      protocolChannel.validateSource.next();
    }
  }
}
