<div class="m-auto w-auto px-3 pt-3" data-automation="'ad-write-down-component'">
  <div *ngFor="let item of adAttributes; let i = index">
    <ziro-ad-attribute-child-form
      [entity]="adAttributes[i]"
      (smacsFormsUpdate$)="onChildFormUpdate($event)"
      [index]="i"
      [isLast]="adAttributes.length - 1 === i"
      [hideClear]="adAttributes.length === 1 && i === 0"
      [dynamicPreviewValue]="dynamicPreviewValue"
      (clearItemWasClicked)="handleClearClicked($event)"
      (addItemWasClicked)="handleAddItemClicked()"
      [allAttributeNames]="allAttributeNames"
    ></ziro-ad-attribute-child-form>
  </div>
</div>
