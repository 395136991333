import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JobState, MicrosoftBulkJobStatus } from '../models/generated/smacsModels';
import { PollingAbstractService } from '../services/abstract/polling.abstract.service';

@Injectable()
export class MicrosoftBulkProvisioningPollingContext extends PollingAbstractService<MicrosoftBulkJobStatus> {
  baseUrl = '/services/microsoft/automate-jobs/bulk-end-user-provisionings/statuses';

  constructor(protected httpClient: HttpClient, protected ngZone: NgZone) {
    super(httpClient, ngZone);
  }

  setIsQueued(status: MicrosoftBulkJobStatus) {
    this._stateSource.next({
      ...status,
      jobStatus: {
        ...status.jobStatus,
        jobState: JobState.QUEUED,
      },
    });
  }
}
