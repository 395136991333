import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  MicrosoftComplianceRecordingPolicyFieldConfig,
  MicrosoftDubberPointsFieldConfig,
  MicrosoftDubberUserFieldConfig,
} from '../models/generated/smacsModels';

@Injectable()
export class DubberFieldConfigResource {
  constructor(protected http: HttpClient) {}

  getComplianceRecordingPolicy(withRecording: boolean): Observable<MicrosoftComplianceRecordingPolicyFieldConfig> {
    return this.http.get<MicrosoftComplianceRecordingPolicyFieldConfig>(
      `/services/microsoft/field-config/compliance-recording-policies?withRecording=${withRecording}`
    );
  }

  getDubberUserFieldConfig(upn: string): Observable<MicrosoftDubberUserFieldConfig> {
    return this.http.get<MicrosoftDubberUserFieldConfig>(
      `/services/microsoft/field-config/dubber-users?upn=${encodeURIComponent(upn)}`
    );
  }

  getDubberPointsFieldConfig(): Observable<MicrosoftDubberPointsFieldConfig> {
    return this.http.get<MicrosoftDubberPointsFieldConfig>(`/services/microsoft/field-config/dub-points`);
  }
}
