import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DialPlanGroupEntry } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class MicrosoftDialPlanInventoriesResource {
  constructor(private http: HttpClient) {}

  get(dialPlanId: number): Observable<DialPlanGroupEntry[]> {
    return this.http.get<DialPlanGroupEntry[]>(`/services/microsoft/views/dial-plan-inventories/v2/${dialPlanId}`);
  }
}
