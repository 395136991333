import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MicrosoftSecurityGroupMembership } from '../models/generated/smacsModels';

@Injectable()
export class Microsoft365SecurityGroupsContext {
  private _stateSource = new ReplaySubject<MicrosoftSecurityGroupMembership>(1);
  state$ = this._stateSource.asObservable();

  private _baseUrl = '/services/microsoft/macs/security-group-memberships/';

  constructor(protected http: HttpClient) {}

  get(userPrincipalName: string): Observable<MicrosoftSecurityGroupMembership> {
    return this.http.get<MicrosoftSecurityGroupMembership>(this._baseUrl + `${userPrincipalName}`).pipe(
      tap((userGroups: MicrosoftSecurityGroupMembership) => {
        this._stateSource.next(userGroups);
      })
    );
  }

  updateUserGroups(
    userPrincipalName: string,
    microsoft365GroupMembership: MicrosoftSecurityGroupMembership
  ): Observable<void> {
    return this.http.put<void>(this._baseUrl + `${userPrincipalName}`, microsoft365GroupMembership).pipe(
      tap(() => {
        this._stateSource.next(microsoft365GroupMembership);
      })
    );
  }
}
