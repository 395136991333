import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../../shared/entity-table/entity-table.models';
import { Subscription, switchMap } from 'rxjs';
import { SiteSummaryContext } from '../../../../shared/contexts/site-summary.context';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import {
  BottomNavClearButtonsList,
  BottomNavService,
  BottomNavUpdateButtonsList,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ClusterResult, SiteResult } from '../../../../shared/models/generated/smacsModels';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ZiroModalPromptModalOptions } from '../../../../modals/prompt-modal/prompt-modal.component';
import { SitesResource } from '../services/sites.resource';
import { tap } from 'rxjs/operators';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';

@Component({
  selector: 'smacs-sites-list',
  templateUrl: './sites-list.component.html',
})
export class SitesListComponent implements OnInit, OnDestroy {
  table: EntityTable;
  tableRows: EntityTableContentRow[];

  clusterId: number;
  isLoading = true;

  private _subscription = new Subscription();

  constructor(
    private siteSummaryContext: SiteSummaryContext,
    private sitesResource: SitesResource,
    private breadcrumbsService: BreadcrumbsService,
    private bottomNavService: BottomNavService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private smacsModalService: SmacsModalService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this._initBottomNav();

    this.clusterId = Number(this.route.snapshot.params.clusterId);

    const siteSummarySub = this.siteSummaryContext
      .refreshSiteSummary()
      .pipe(switchMap(() => this.siteSummaryContext.state$))
      .subscribe((siteSummary) => {
        const cluster = siteSummary.clusters.find((cluster) => cluster.id === this.clusterId);

        this.breadcrumbsService.updateBreadcrumbs([
          {
            label: 'tkey;admin.site_management.cluster_sites.header',
            url: '/admin/site-management/clusters',
            routerLink: true,
          },
          {
            label: this.translateService.instant('tkey;admin.site_management.site.header', {
              labelParam: cluster.name,
            }),
          },
        ]);

        this._buildTable(cluster);
        this._buildTableRows(cluster);
        this.isLoading = false;
      });
    this._subscription.add(siteSummarySub);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private _buildTable(cluster: ClusterResult) {
    this.table = {
      columns: [
        {
          columnId: 'id',
          cssColumnSize: 'col-sm-1',
          label: 'tkey;admin.site_management.site.id',
          filter: {
            type: EntityTableFilterTypes.TEXT,
          },
        },
        {
          columnId: 'name',
          cssColumnSize: 'col-sm-4',
          label: 'tkey;admin.site_management.site.site',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: cluster.sites.map((site) => site.name),
          },
        },
        {
          columnId: 'devicePool',
          cssColumnSize: 'col-sm-5',
          label: 'tkey;admin.site_management.site.device_pool',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: cluster.sites.flatMap((site) =>
              site.devicePools
                .filter((dpResult) => dpResult.serviceName === 'Desk Phone')
                .map((dpResult) => dpResult.devicePool)
            ),
          },
        },
      ],
      resultsMessage: 'tkey;admin.site_management.site.no_results',
      cssClearButtonColumnSize: 'col-sm-2',
      hasActions: true,
    };
  }

  private _buildTableRows(cluster: ClusterResult) {
    this.tableRows = cluster.sites.map((site) => {
      return {
        content: {
          id: site.id,
          name: site.name,
          devicePool: site.devicePools.find((dpResult) => dpResult.serviceName === 'Desk Phone')?.devicePool || '',
        },
        actionsColumnSize: 'col-sm-2',
        actions: [
          {
            buttonStyle: ButtonStyles.PRIMARY,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/${site.id}`,
            icon: SmacsIcons.NEXT,
            dataAutomation: 'sites-list-edit-button',
            onClick: () => {
              this.bottomNavService.dispatch(new BottomNavClearButtonsList());
              this.router.navigate([site.id], { relativeTo: this.route });
            },
          },
          {
            buttonStyle: ButtonStyles.DANGER,
            icon: SmacsIcons.DELETE,
            dataAutomation: 'sites-list-delete-button',
            onClick: () => {
              this._openDeleteSiteModal(site);
            },
          },
          {
            buttonStyle: ButtonStyles.DEFAULT,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/${site.id}/copy`,
            icon: SmacsIcons.COPY,
            dataAutomation: 'sites-list-copy-button',
            onClick: () => {
              this.router.navigate([site.id, 'copy'], { relativeTo: this.route });
            },
          },
        ],
      };
    });
  }

  private _initBottomNav() {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'bulk-field-copy',
          label: 'tkey;admin.site_management.bulk_change.button',
          cb: () => {
            this.router.navigate(['bulk-copy'], { relativeTo: this.route });
          },
          dataAutomation: 'sites-list-bulk-copy-button',
          buttonClass: ButtonStyles.DEFAULT,
        },
        {
          id: 'add-site',
          label: 'tkey;admin.site_management.add_site.button',
          icon: SmacsIcons.ADD,
          cb: () => {
            this.bottomNavService.dispatch(new BottomNavClearButtonsList());
            this.router.navigate(['new'], { relativeTo: this.route });
          },
          dataAutomation: 'sites-list-add-button',
          buttonClass: ButtonStyles.PRIMARY,
        },
      ])
    );
  }

  private _openDeleteSiteModal(site: SiteResult) {
    const options: ZiroModalPromptModalOptions = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        promptBody: this.translateService.instant('tkey;admin.sites.delete.confirm.text', {
          name: site.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'prompt-modal-cancel',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'prompt-modal-confirm',
            cb: () =>
              this.sitesResource.delete(this.clusterId, site.id).pipe(
                tap(() => {
                  this.toastService.push(
                    ToastTypes.INFO,
                    SmacsIcons.DELETE,
                    'tkey;admin.sites.delete.toast.title',
                    'tkey;admin.sites.delete.toast.message',
                    { siteName: site.name },
                    null,
                    'text-danger'
                  );
                }),
                switchMap(() => this.siteSummaryContext.refreshSiteSummary())
              ),
          },
        ],
      },
    };

    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }
}
