import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subscription, switchMap } from 'rxjs';
import {
  BandwidthCachedOptions,
  BandwidthSettings,
  CurrentUser,
  Role,
} from '../../shared/models/generated/smacsModels';
import { map } from 'rxjs/operators';
import { AuthenticationContext } from '../../shared/contexts/authentication.context';
import { BandwidthSettingsContext } from '../../shared/contexts/bandwidth-settings.context';

@Injectable()
export class BandwidthCachedOptionsContext {
  private _stateSource = new ReplaySubject<BandwidthCachedOptions>(1);
  state$ = this._stateSource.asObservable();

  private _baseurl = '/services/microsoft/admin/bandwidth-cached-options';
  private _bandwidthCtxSub: Subscription;

  constructor(
    private http: HttpClient,
    private authenticationContext: AuthenticationContext,
    private bandwidthSettingsContext: BandwidthSettingsContext
  ) {
    this.authenticationContext.state$.subscribe((currentUser: CurrentUser) => {
      if (this._bandwidthCtxSub) {
        this._bandwidthCtxSub.unsubscribe();
      }
      if (currentUser?.role === Role.ZIRO_SUPPORT) {
        this._bandwidthCtxSub = this.bandwidthSettingsContext.state$.subscribe(
          (bandwidthSettings: BandwidthSettings) => {
            if (this.bandwidthSettingsContext.isBandwidthConfigured(bandwidthSettings)) {
              this._init().subscribe();
            } else {
              this._stateSource.next(null);
            }
          }
        );
      } else {
        this._stateSource.next(null);
      }
    });
  }

  refresh(): Observable<void> {
    return this._refresh().pipe(switchMap(() => this._init()));
  }

  private _init(): Observable<void> {
    return this._get().pipe(
      map((cache) => {
        this._stateSource.next(cache);
      })
    );
  }

  private _get(): Observable<BandwidthCachedOptions> {
    return this.http.get<BandwidthCachedOptions>(this._baseurl);
  }

  private _refresh(): Observable<void> {
    return this.http.post<void>(this._baseurl, null);
  }
}
