<nav data-automation="teams-calling-container-nav" class="navbar pb-3">
  <div class="w-100">
    <ul class="nav nav-tabs justify-content-start">
      <li class="nav-item w-25" role="presentation">
        <a
          data-automation="teams-calling-tab"
          class="nav-link text-center text-nowrap fs-5 {{ !isDelegateManagementSelected ? 'active' : '' }}"
          id="home-tab"
          routerLink="."
          role="tab"
        >
          {{ 'tkey;microsoft_360.view.teams.number_and_policies.title' | translate }}
        </a>
      </li>
      <li
        class="nav-item w-25"
        role="presentation"
        [ngbTooltip]="delegateManagementDisabledTooltip | translate"
        [disableTooltip]="isDelegateManagementEnabled"
        [container]="'body'"
      >
        <a
          [ngClass]="{ disabled: !isDelegateManagementEnabled }"
          data-automation="delegate-management-tab"
          class="nav-link text-center text-nowrap fs-5 {{ isDelegateManagementSelected ? 'active' : '' }}"
          id="profile-tab"
          routerLink="delegate-management"
          role="tab"
        >
          {{ 'tkey;microsoft_360.view.teams.delegate_management.title' | translate }}
        </a>
      </li>
    </ul>
  </div>
</nav>

<router-outlet></router-outlet>
