import { Injectable } from '@angular/core';
import { ServiceType } from '../models/service-type';
import { forOwn } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

const serviceTranslationKeys = {
  [ServiceType.PRIMARY_EXTENSION]: 'tkey;shared.service.primary.extension.text',
  [ServiceType.UCCX_AGENT]: 'tkey;shared.service.agent.text',
  [ServiceType.VOICEMAIL]: 'tkey;shared.service.voicemail.text',
  [ServiceType.EXTENSION_MOBILITY]: 'tkey;shared.service.extensionmobility.text',
  [ServiceType.SNR]: 'tkey;shared.service.snr.text',
  [ServiceType.IM_PRESENCE]: 'tkey;shared.service.imp.text',
  [ServiceType.DESKPHONE]: 'tkey;shared.model.deskphone.text',
  [ServiceType.CTI_PORT]: 'tkey;shared.model.ctiport.text',
  [ServiceType.CTI_REMOTE_DEVICE]: 'tkey;shared.model.ctiremotedevice.text',
  [ServiceType.SPARK_REMOTE_DEVICE]: 'tkey;shared.model.sparkremotedevice.text',
  [ServiceType.ANDROID]: 'tkey;shared.model.android.text',
  [ServiceType.IPHONE]: 'tkey;shared.model.iphone.text',
  [ServiceType.SOFTPHONE]: 'tkey;shared.model.imsoftphone.text',
  [ServiceType.TABLET]: 'tkey;shared.model.tablet.text',
  [ServiceType.CIPC]: 'tkey;shared.model.cipc.text',
  [ServiceType.MICROSOFT_365]: 'tkey;shared.model.microsoft_365.text',
  [ServiceType.MICROSOFT_TEAMS_CALLING]: 'tkey;shared.model.microsoft_teams_calling.text',
};

interface Translations {
  [key: string]: string;
}

@Injectable()
export class ServiceTypeNameFilter {
  translations: Translations;

  constructor(private translateService: TranslateService) {}

  generateNewTranslations(languageKey: string) {
    const translations = {} as typeof serviceTranslationKeys;

    forOwn(serviceTranslationKeys, (val: string, key: keyof typeof serviceTranslationKeys) => {
      translations[key] = this.translateService.instant(val, {});
    });

    return translations;
  }

  getServiceTranslation(serviceType: string) {
    const name = this.translations[serviceType];
    if (!name) {
      throw Error(`No name found for ${serviceType}`);
    }
    return name;
  }

  refreshTranslations(languageKey: string) {
    return (this.translations = this.generateNewTranslations(languageKey));
  }
}
