<button
  class="phone-fx-button btn float-end"
  [disabled]="disabled"
  (click)="openPhoneFx()"
  data-automation="phone-fx-button"
  [ngbTooltip]="'tkey;shared.device.phone.unified_fx.disabled_tooltip' | translate"
  [disableTooltip]="!disabled"
>
  <img class="phone-fx-button__img" src="/static/img/phonefx_logo.png" />
</button>
