<div class="container-fluid">
  <div class="modal-header">
    <legend class="modal-title">
      <strong>{{ audit.category }}</strong>
      | {{ audit.type }}
    </legend>
    <button type="button" class="btn-close" aria-label="Close" (click)="onOkClick()"></button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <span class="float-end">
          {{ audit.timestamp | date : 'medium' }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <h5>
          <strong data-automation="error-message">{{ audit.errorMessage }}</strong>
        </h5>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <pre class="audit-details-body" data-automation="error-details">{{ audit.errorDetails }}</pre>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <div class="text-center">
      <button type="button" class="btn btn-lg btn-primary ok-button ok-modal-click" (click)="onOkClick()">
        <span translate="tkey;dialogs.button.ok"></span>
      </button>
    </div>
  </div>
</div>
