import { Component, Input, OnInit } from '@angular/core';
import { DatatableFilterAbstractDirective } from '../datatable-filter-abstract.directive';

@Component({
  selector: 'smacs-datatable-switch-filter',
  templateUrl: './datatable-switch-filter.component.html',
  styleUrls: ['./datatable-switch-filter.component.scss', '../datatable-filter-abstract.directive.scss'],
  providers: [{ provide: DatatableFilterAbstractDirective, useExisting: DatatableSwitchFilterComponent }],
})
export class DatatableSwitchFilterComponent extends DatatableFilterAbstractDirective<boolean> implements OnInit {
  @Input() tooltip: string;

  clear = () => (this.value = false);

  ngOnInit() {
    const sessionFilterParams = sessionStorage.getItem('filterParams');

    if (!!sessionFilterParams) {
      const allFilterParams = JSON.parse(sessionFilterParams);
      if (!!allFilterParams[this.tableId]) {
        const thisFilterParam = Object.entries(allFilterParams[this.tableId]).find(
          (param) => param[0] === this.dataAutomation
        );
        if (thisFilterParam) {
          this.value = Boolean(thisFilterParam[1]);
        }
      }
    }

    this.isInitialized.emit();
  }
}
