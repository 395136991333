import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { DetailedZiroCallingReportsContext } from '../services/detailed-ziro-calling-reports.context';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFieldTypes,
  EntityTableTextField,
} from '../../../../shared/entity-table/entity-table.models';
import {
  BillingProduct,
  DetailedZiroCallingReport,
  JobStatus,
  ZiroCallingSubscription,
} from '../../../../shared/models/generated/smacsModels';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { BillingProductsAbstractDirective, ProductType } from '../billing-products.abstract.directive';
import { PurchasedZiroCallingSubscriptionsContext } from '../services/purchased-ziro-calling-subscriptions.context';
import { ToastService } from '../../../../shared/services/toast.service';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { EntityTableComponent } from '../../../../shared/entity-table/entity-table.component';
import { BottomNavService } from '../../../../shared/bottom-nav/bottom-nav.service';
import { BillingProductsPollingService } from '../services/billing-products-polling.service';
import { DateAgoPipe } from '../../../../shared/pipes/date-ago.pipe';

@Component({
  selector: 'smacs-billing-products-summary-view',
  templateUrl: './billing-products-summary-view.component.html',
  styleUrls: ['../../../admin-page.scss'],
  providers: [PurchasedZiroCallingSubscriptionsContext],
})
export class BillingProductsSummaryViewComponent extends BillingProductsAbstractDirective implements OnInit, OnDestroy {
  @ViewChild(EntityTableComponent) entityTableComponent: EntityTableComponent;

  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];
  showCustomAlert = true;

  private _subscriptions = new Subscription();
  private _initialTextInputValue: string;

  constructor(
    protected breadcrumbsService: BreadcrumbsService,
    protected bottomNavService: BottomNavService,
    protected detailedZiroCallingReportsContext: DetailedZiroCallingReportsContext,
    protected billingProductsPollingService: BillingProductsPollingService,
    protected toastService: ToastService,
    protected translateService: TranslateService,
    protected dateAgoPipe: DateAgoPipe,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private purchasedZiroCallingSubscriptionsContext: PurchasedZiroCallingSubscriptionsContext,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    super(
      breadcrumbsService,
      bottomNavService,
      detailedZiroCallingReportsContext,
      billingProductsPollingService,
      toastService,
      translateService,
      dateAgoPipe
    );
  }

  ngOnInit() {
    super.ngOnInit();
    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: 'tkey;admin.billing.products.title',
      },
    ]);

    this._initTable();

    const ziroCallingSubs = combineLatest([
      this.detailedZiroCallingReportsContext.state$,
      this.purchasedZiroCallingSubscriptionsContext.state$,
    ]).subscribe(([report, subscriptions]) => {
      this._initTableRows(report, subscriptions);
    });
    this._subscriptions.add(ziroCallingSubs);

    const billingProductsSub = this.billingProductsPollingService.state$.subscribe((jobStatus: JobStatus) => {
      if (jobStatus.error) {
        this.showCustomAlert = true;
        this.billingProductsPollingService.stopPolling();
      } else {
        this.showCustomAlert = true;
      }
    });
    this._subscriptions.add(billingProductsSub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  onInitialTextInputValue(value: string) {
    this._initialTextInputValue = value;
  }

  private _initTable() {
    this.table = {
      hasActions: true,
      summaryTable: true,
      columns: [
        {
          columnId: 'productName',
          cssColumnSize: 'col-sm-7',
          label: 'tkey;admin.billing.products.summary.table.product_name.header',
        },
        {
          columnId: 'quantity',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;admin.billing.products.summary.table.quantity.header',
        },
        {
          columnId: 'quantityPurchased',
          label: 'tkey;admin.billing.products.summary.table.quantity_purchased.header',
          cssColumnSize: 'col-sm-2',
        },
      ],
    };
  }

  private _initTableRows(report: DetailedZiroCallingReport[], subscriptions: ZiroCallingSubscription[]) {
    let subscriptionCount = 0;
    let didCount = 0;
    let nonDidCount = 0;
    let unassignedCount = 0;
    let byocCount = 0;
    let pstnCount = 0;
    let draasSharedCount = 0;

    let subscriptionsPurchased = 0;
    let didsPurchased = 0;
    let nonDidsPurchased = 0;
    let unassignedsPurchased = 0;
    let byocsPurchased = 0;
    let pstnsPurchased = 0;
    let draasSharedPurchased = 0;

    report.forEach((entry) => {
      switch (entry.billingProduct) {
        case BillingProduct.ZPM_SUBSCRIPTION:
          subscriptionCount++;
          break;
        case BillingProduct.ZIRO_DRAAS:
          didCount++;
          break;
        case BillingProduct.ZIRO_DRAAS_EXTENSION:
          nonDidCount++;
          break;
        case BillingProduct.ZIRO_SPARE_DID:
          unassignedCount++;
          break;
        case BillingProduct.ZIRO_DRAAS_BYOC:
          byocCount++;
          break;
        case BillingProduct.ZIRO_PSTN_CONNECTIVITY_OTHER:
          pstnCount++;
          break;
        case BillingProduct.ZIRO_DRAAS_SHARED_CALLING_DID:
          draasSharedCount++;
          break;
      }
    });

    subscriptions.forEach((entry) => {
      switch (entry.billingProduct) {
        case BillingProduct.ZPM_SUBSCRIPTION:
          subscriptionsPurchased = entry.quantity;
          break;
        case BillingProduct.ZIRO_DRAAS:
          didsPurchased = entry.quantity;
          break;
        case BillingProduct.ZIRO_DRAAS_EXTENSION:
          nonDidsPurchased = entry.quantity;
          break;
        case BillingProduct.ZIRO_SPARE_DID:
          unassignedsPurchased = entry.quantity;
          break;
        case BillingProduct.ZIRO_DRAAS_BYOC:
          byocsPurchased = entry.quantity;
          break;
        case BillingProduct.ZIRO_PSTN_CONNECTIVITY_OTHER:
          pstnsPurchased = entry.quantity;
          break;
        case BillingProduct.ZIRO_DRAAS_SHARED_CALLING_DID:
          draasSharedPurchased = entry.quantity;
          break;
      }
    });

    this.tableRows = [
      this._buildTableRow('zpm_subscription', subscriptionCount, subscriptionsPurchased),
      this._buildTableRow('ziro_draas', didCount, didsPurchased),
      this._buildTableRow('ziro_draas_extension', nonDidCount, nonDidsPurchased),
      this._buildTableRow('ziro_draas_shared_calling_did', draasSharedCount, draasSharedPurchased),
      this._buildTableRow('ziro_spare_did', unassignedCount, unassignedsPurchased),
      this._buildTableRow('ziro_draas_byoc', byocCount, byocsPurchased),
      this._buildTableRow('ziro_pstn_connectivity_other', pstnCount, pstnsPurchased),
    ];
  }

  private handleUpdateSubscriptions(row: EntityTableTextField) {
    if (isNaN(Number(row.value))) {
      this.entityTableComponent.focussedTextInput.nativeElement.dispatchEvent(
        new InputEvent(this._initialTextInputValue)
      );
      this.changeDetectorRef.detectChanges();
      this.entityTableComponent.toggleTextPlaceholderDisplay(row);
      // Requires a tick to pass so [(ngModel)] picks up the dispatched value
      return setTimeout(() => {
        this.toastService.push(
          ToastTypes.ERROR,
          SmacsIcons.EXCLAMATION,
          'tkey;admin.billing.purchase_quantity.toast.error_not_a_number.title',
          'tkey;admin.billing.purchase_quantity.toast.error_not_a_number.body'
        );
      });
    }

    const updatedSub: ZiroCallingSubscription = {
      billingProduct: row.rowId.toUpperCase() as BillingProduct,
      quantity: Number(row.value),
    };

    this.purchasedZiroCallingSubscriptionsContext.updateSubscriptions([updatedSub]).subscribe(() => {
      this.toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.BILLING,
        'tkey;admin.billing.purchase_quantity.toast.title',
        'tkey;admin.billing.purchase_quantity.toast.body'
      );
    });
  }

  private _buildTableRow(product: ProductType, count: number, countPurchased: number): EntityTableContentRow {
    return {
      rowId: product,
      cssClass: !count ? 'muted' : '',
      content: {
        quantity: count,
      },
      html: {
        productName: this._getNameAndDescriptionHtml(product),
        quantity: this._getCountHtml(count),
      },
      fields: {
        quantityPurchased: {
          type: EntityTableFieldTypes.TEXT,
          value: countPurchased.toString(),
          showPlaceholderMarkup: true,
          placeholderMarkup: this.domSanitizer.bypassSecurityTrustHtml(
            `<span style="font-size:1.5em"><strong>${countPurchased}</strong></span>`
          ) as string,
          rowId: product,
          onEnter: (row: EntityTableTextField) => this.handleUpdateSubscriptions(row),
          onBlur: (row: EntityTableTextField) => this.handleUpdateSubscriptions(row),
        },
      },
      actions: [
        {
          buttonStyle: ButtonStyles.PRIMARY,
          buttonType: ButtonTypes.LINK,
          buttonLinkHref: window.location.href + `/detailed-usage/${product}`,
          icon: SmacsIcons.NEXT,
          dataAutomation: `billing-summary-detailed-usage-button`,
          isDisabled: count === 0,
          tooltipDisabled: count > 0,
          tooltip: 'tkey;admin.billing.products.summary.table.detailed_usage_button.disabled_tooltip',
          onClick: () => {
            this.router.navigate([`detailed-usage/${product}`], { relativeTo: this.route });
          },
        },
      ],
    };
  }

  private _getCountHtml(count: number): string {
    return this.domSanitizer.bypassSecurityTrustHtml(
      `<span style="font-size:1.5em"><strong>${count}</strong></span>`
    ) as string;
  }

  private _getNameAndDescriptionHtml(productType: ProductType): string {
    return this.domSanitizer.bypassSecurityTrustHtml(
      `<div style="padding: 0.5rem 0"><div style="font-size:1.125em"><strong>${this.translateService.instant(
        `tkey;admin.billing.products.product_name.${productType}`
      )}</strong></div><div style="font-size:0.875em;">${this.translateService.instant(
        `tkey;admin.billing.products.product_name.${productType}.description`
      )}</div></div>`
    ) as string;
  }
}
