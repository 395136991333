import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmailTemplatePreviewFieldConfig } from '../../../../shared/models/generated/smacsModels';

@Injectable()
export class EmailTemplatePreviewsFieldConfigResource {
  constructor(private http: HttpClient) {}

  get(username: string, cucmServerId: number): Observable<EmailTemplatePreviewFieldConfig[]> {
    const usernameParam = `endUserUsername=${encodeURIComponent(username)}`;
    const serverIdParam = `cucmServerId=${cucmServerId}`;
    return this.http.get<EmailTemplatePreviewFieldConfig[]>(
      `/services/cisco/field-configs/email-template-previews?${usernameParam}&${serverIdParam}`
    );
  }
}
