import { Component, Input, OnInit } from '@angular/core';
import {
  CustomSelect,
  DubberEndUser,
  DubberEndUserRef,
  DubPointRef,
  Microsoft360View,
  MicrosoftDubberUserFieldConfig,
} from '../../../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { combineLatest, forkJoin, Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ActivatedRoute, Router } from '@angular/router';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../../shared/services/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { DubberFieldConfigResource } from '../../../../shared/resources/dubber-field-config.resource';
import { catchError, tap } from 'rxjs/operators';
import { Microsoft360ViewContext } from '../../../../shared/contexts/microsoft-360-view.context';
import { Nvp } from '../../../../shared/models/nvp';
import { BottomNavButton } from '../../../../shared/bottom-nav/bottom-nav.component';
import { DubberService } from '../../../shared/services/dubber.service';

export interface DubberComplianceRecordingFormData {
  complianceRecordingPolicy: string;
  email: string;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  region: string;
  product: string;
}

export interface DubberComplianceRecordingFieldConfig {
  complianceRecordingPolicy: CustomSelect;
  user: MicrosoftDubberUserFieldConfig;
  product: CustomSelect;
}

export interface MicrosoftTeamsComplianceRecordingPolicyRequest {
  userPrincipalName: string;
  complianceRecordingPolicy: string;
}

@Component({
  selector: 'smacs-dubber-compliance-recording-form',
  templateUrl: './dubber-compliance-recording-form.component.html',
})
export class DubberComplianceRecordingFormComponent
  extends SmacsFormAbstractDirective<DubberComplianceRecordingFormData>
  implements OnInit
{
  @Input() fieldConfig: DubberComplianceRecordingFieldConfig;
  @Input() dubberUser: DubberEndUserRef;
  @Input() ms360View: Microsoft360View;

  formConfig: SmacsFormConfig;
  isLoading = true;
  private _upn: string;
  private _initialEntity: DubberComplianceRecordingFormData;
  private _auditTags: Nvp[];
  private _subscriptions = new Subscription();
  private _validators = {
    emailValidator: (value: string): SmacsFormsValidationState => {
      return !value ||
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        )
        ? SmacsFormsValidationState.VALID
        : SmacsFormsValidationState.INVALID;
    },
    mobileNumberValidator: (value: string) => {
      return !value || /^1\d{10}$/.test(value) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
    },
  };
  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private _bottomNavService: BottomNavService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _dubberFieldConfigResource: DubberFieldConfigResource,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    private _smacsModalService: SmacsModalService,
    private _microsoft360ViewContext: Microsoft360ViewContext,
    private _dubberService: DubberService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._upn = this._route.snapshot.paramMap.get('upn');
    this._auditTags = [
      {
        name: 'upn',
        value: this._upn,
      },
    ];
    const validationAlertSub = combineLatest([this.smacsFormsUpdate$, this._validateAndSubmitSource]).subscribe(
      (data) => {
        this._bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: !this.isFormValid(),
          })
        );
      }
    );
    this._subscriptions.add(validationAlertSub);
    this._initialEntity = this.entity;
    this._initBottomNav();
    this._initForm();
  }

  protected submit() {
    return this._onSaveClicked();
  }

  private _initForm() {
    this.formConfig = {
      fields: {
        complianceRecordingPolicy: {
          label: 'tkey;microsoft_360.view.dubber.complianceRecording.label',
          componentConfig: new SmacsSelectConfig({
            options: this.fieldConfig.complianceRecordingPolicy.possibleOptions,
          }),
          required: this.fieldConfig.complianceRecordingPolicy.required,
          defaultValue: () => this.entity.complianceRecordingPolicy,
          dataAutomation: 'dubber-compliance-recording-policy',
        },
        email: {
          label: 'tkey;microsoft_360.view.dubber.email.label',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: this.fieldConfig.user.email.required,
          defaultValue: () => this.fieldConfig.user.email.value,
          dataAutomation: 'dubber-email',
          validation: [
            {
              validator: this._validators.emailValidator,
              message: 'tkey;validators.global.email.invalid.error',
            },
          ],
        },
        firstName: {
          label: 'tkey;microsoft_360.view.dubber.firstName.label',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: this.fieldConfig.user.firstName.required,
          defaultValue: () => this.fieldConfig.user.firstName.value,
          dataAutomation: 'dubber-first-name',
        },
        lastName: {
          label: 'tkey;microsoft_360.view.dubber.lastName.label',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: this.fieldConfig.user.lastName.required,
          defaultValue: () => this.fieldConfig.user.lastName.value,
          dataAutomation: 'dubber-last-name',
        },
        mobileNumber: {
          label: 'tkey;microsoft_360.view.dubber.mobileNumber.label',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: this.fieldConfig.user.mobileNumber.required,
          defaultValue: () => this.fieldConfig.user.mobileNumber.value,
          dataAutomation: 'dubber-mobile-number',
          validation: [
            {
              validator: this._validators.mobileNumberValidator,
              message: 'tkey;microsoft_360.view.dubber.mobile_number.validator.message',
            },
          ],
        },
        region: {
          label: 'tkey;microsoft_360.view.dubber.region.label',
          componentConfig: new SmacsSelectConfig({
            options: this.fieldConfig.user.region.possibleOptions,
          }),
          required: this.fieldConfig.user.region.required,
          defaultValue: () => this._initialEntity.region,
          dataAutomation: 'dubber-region',
          disabled: () => !!this.dubberUser,
          disabledTooltip: 'tkey;microsoft_360.view.dubber.region_disabled.tooltip',
        },
        product: {
          label: 'tkey;microsoft_360.view.dubber.product.label',
          componentConfig: new SmacsSelectConfig({
            options: this.fieldConfig.product.possibleOptions,
          }),
          required: this.fieldConfig.product.required,
          defaultValue: () => this.fieldConfig.product.defaultOption,
          dataAutomation: 'dubber-product',
        },
      },
    };
    this.isLoading = false;
  }

  private _initBottomNav() {
    const buttons: BottomNavButton[] = [
      {
        id: 'dubber-cancel',
        dataAutomation: 'dubber-cancel',
        label: 'tkey;global.button.cancel.text',
        buttonClass: ButtonStyles.DEFAULT,
        cb: () => {
          this._router.navigate(['../'], { relativeTo: this._route });
        },
        state: {
          pending: false,
          buttonDisableState: {
            disabled: this.isSubmitting,
            tooltipKey: '',
          },
        },
      },

      {
        id: 'dubber-save',
        dataAutomation: 'dubber-save',
        label: 'tkey;global.button.save.text',
        buttonClass: ButtonStyles.PRIMARY,
        state: {
          pending: this.isSubmitting,
          buttonDisableState: {
            disabled: false,
            tooltipKey: '',
          },
        },
        icon: SmacsIcons.OK,
        type: ButtonTypes.SUBMIT,
        submitSubject: this._validateAndSubmitSource,
      },
    ];
    if (!!this.dubberUser) {
      buttons.splice(1, 0, {
        id: 'dubber-delete',
        dataAutomation: 'dubber-delete',
        label: 'tkey;global.button.delete.text',
        buttonClass: ButtonStyles.DANGER,
        cb: () => this._onDeleteClicked(),
      });
    }
    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttons));
  }

  private _onSaveClicked(): Observable<any> {
    this.isSubmitting = true;
    this._bottomNavService.setButtonPendingState('dubber-save', true);
    return (!!this.dubberUser ? this._updateUser() : this._createUser()).pipe(
      tap(() => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.DUBBER_ICON,
          'tkey;shared.toast.save.success.title',
          'tkey;microsoft_360.view.dubber.toast.message',
          { upn: this._upn }
        );
        this._router.navigate(['../'], { relativeTo: this._route });
      }),
      catchError((err) => {
        this._bottomNavService.setButtonPendingState('dubber-save', false);
        return throwError(() => err);
      })
    );
  }

  private _createUser(): Observable<[DubPointRef, void]> {
    const dubberEndUser: DubberEndUser = {
      email: this.formData.email,
      firstName: this.formData.firstName,
      lastName: this.formData.lastName,
      mobileNumber: this.formData.mobileNumber,
      id: null,
    };
    return this._dubberService.createDubberUser(dubberEndUser, this.formData.region).pipe(
      switchMap((endUserRef: DubberEndUserRef) => {
        this.dubberUser = endUserRef;

        const createDubPoint$ = this._dubberService.createDubPoint(
          { id: null, product: this.formData.product },
          endUserRef.region,
          endUserRef.id,
          this._auditTags
        );

        const updatedUserComplianceRecordingPolicy: MicrosoftTeamsComplianceRecordingPolicyRequest = {
          userPrincipalName: this._upn,
          complianceRecordingPolicy: this.formData.complianceRecordingPolicy,
        };
        const updateComplianceRecordingPolicy$ = this._dubberService.updateUserComplianceRecordingPolicy(
          updatedUserComplianceRecordingPolicy
        );

        return forkJoin([createDubPoint$, updateComplianceRecordingPolicy$]);
      }),
      tap(() => {
        const updated360View: Microsoft360View = {
          ...this.ms360View,
          dubberUser: this.dubberUser,
        };
        this._microsoft360ViewContext._stateSource.next(updated360View);
      })
    );
  }

  private _onDeleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        title: this._translateService.instant('tkey;microsoft_360.view.dubber.delete.modal.title'),
        promptBody: this._translateService.instant('tkey;microsoft_360.view.dubber.delete.modal.message', {
          upn: this._upn,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteUser(),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteUser(): Observable<[void, void]> {
    return this._dubberService
      .deleteDubberUser(this.dubberUser.region, this.dubberUser.id, this._upn, this._auditTags)
      .pipe(
        tap(() => {
          this._toastService.pushDeleteToast('tkey;microsoft_360.view.dubber.delete.toast.message', this._upn);
          const updated360View: Microsoft360View = {
            ...this.ms360View,
            dubberUser: null,
          };
          this._microsoft360ViewContext._stateSource.next(updated360View);
          this._router.navigate(['../'], { relativeTo: this._route });
        })
      );
  }

  private _updateUser(): Observable<any[]> | Observable<null> {
    // Check if Compliance Recording, Dubber End User, or Product(dubPoint) were updated and update them individually
    const updatesToProcess$: Observable<any>[] = [of(null)];
    let isUserUpdated = false;

    // Compliance Policy
    if (this.formData.complianceRecordingPolicy !== this._initialEntity.complianceRecordingPolicy) {
      const updatedUserComplianceRecordingPolicy: MicrosoftTeamsComplianceRecordingPolicyRequest = {
        userPrincipalName: this._upn,
        complianceRecordingPolicy: this.formData.complianceRecordingPolicy,
      };
      updatesToProcess$.push(
        this._dubberService.updateUserComplianceRecordingPolicy(updatedUserComplianceRecordingPolicy, this._auditTags)
      );
    }

    // Dubber User
    if (
      this.formData.email !== this._initialEntity.email ||
      this.formData.firstName !== this._initialEntity.firstName ||
      this.formData.lastName !== this._initialEntity.lastName ||
      this.formData.mobileNumber !== this._initialEntity.mobileNumber
    ) {
      const updatedDubberEndUser: DubberEndUser = {
        email: this.formData.email,
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        mobileNumber: this.formData.mobileNumber,
        id: this.dubberUser.id,
      };
      updatesToProcess$.push(
        this._dubberService.updateDubberEndUser(
          this.dubberUser.region,
          this.dubberUser.id,
          updatedDubberEndUser,
          this._auditTags
        )
      );
      isUserUpdated = true;
    }

    // Product
    if (this.formData.product !== this._initialEntity.product) {
      // update product(dubPoint) - first delete previous dubPoint
      const deletePreviousDubPoint = this._dubberService.deleteDubPointIfExists(
        this.dubberUser.region,
        this.dubberUser.id,
        this._auditTags
      );
      const createNewDubPoint = this._dubberService.createDubPoint(
        { id: null, product: this.formData.product },
        this.dubberUser.region,
        this.dubberUser.id
      );
      updatesToProcess$.push(deletePreviousDubPoint.pipe(switchMap(() => createNewDubPoint)));
    }

    return forkJoin(updatesToProcess$).pipe(
      tap(() => {
        if (isUserUpdated) {
          const updated360View: Microsoft360View = {
            ...this.ms360View,
            dubberUser: this.dubberUser,
          };
          this._microsoft360ViewContext._stateSource.next(updated360View);
        }
      })
    );
  }
}
