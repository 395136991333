<app-breadcrumbs>
  <app-breadcrumb-card-item>
    <div
      [innerHTML]="
        !isReadOnly
          ? ('tkey;reporting.custom_reports.custom_reports_list.description' | translate)
          : ('tkey;reporting.custom_reports.custom_reports_list.description.read_only' | translate)
      "
    ></div>
  </app-breadcrumb-card-item>
</app-breadcrumbs>
<app-loading-block [hidden]="!isLoading"></app-loading-block>
<app-entity-table
  *ngIf="!isLoading"
  [table]="table"
  [tableRows]="tableRows"
  data-automation="custom-report-entity-table"
></app-entity-table>
