import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import {
  DirectoryNumberRef,
  Global360View,
  LineButton,
  LineFeature,
  LineFeatureFieldConfig,
  Phone,
  TranslationPattern,
  VoicemailRef,
} from '../../../models/generated/smacsModels';
import { LineButtonPeripheralSummary } from '../../layout/phone-buttons-standard.component';
import {
  PhoneButtonsLineExtensionSearchComponent,
  PhoneButtonsLineExtensionSearchEntity,
} from './phone-buttons-line-extension-search/phone-buttons-line-extension-search.component';
import { Observable, Subject, Subscription } from 'rxjs';
import { isEqual } from 'lodash';
import { PhoneButtonsLineHelpdeskFormComponent } from './phone-buttons-line-helpdesk-form/phone-buttons-line-helpdesk-form.component';
import { ExtensionMobilityDnD } from '../../phone-buttons.component';
import { PhoneUiContext } from '../../contexts/phone-ui.context';

@Component({
  selector: 'smacs-phone-buttons-line-helpdesk',
  templateUrl: './phone-buttons-line-helpdesk.component.html',
})
export class PhoneButtonsLineHelpdeskComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(PhoneButtonsLineExtensionSearchComponent) lineExtensionSearch: PhoneButtonsLineExtensionSearchComponent;
  @ViewChild(PhoneButtonsLineHelpdeskFormComponent) lineHelpdeskFormComponent: PhoneButtonsLineHelpdeskFormComponent;

  @Input() lineFeature: LineFeature;
  @Input() dnRef: DirectoryNumberRef;
  @Input() fieldConfig: LineFeatureFieldConfig;
  @Input() phone: Phone | ExtensionMobilityDnD;
  @Input() siteId: number;
  @Input() lineButtonSummary: LineButtonPeripheralSummary;
  @Input() buttonIndex: number;
  @Input() parentSubmit$: Observable<any>;
  @Input() global360View: Global360View;

  @Output() lineButtonWasUpdated = new EventEmitter<SmacsFormsUpdate<LineButton>>();
  @Output() dnUpdated = new EventEmitter<boolean>();
  @Output() lineButtonSummaryWasUpdated = new Subject<LineButtonPeripheralSummary>();

  extensionSearchEntity = {} as PhoneButtonsLineExtensionSearchEntity;
  isLineFeaturePending = false;
  modalTranslationPattern: TranslationPattern;
  isSingleButton: boolean;

  _validateAndSubmitSource = new Subject<boolean>();

  private _formValidationStates = {
    extensionSearch: null as SmacsFormsValidationState,
    lineFeature: null as SmacsFormsValidationState,
  };
  private _subscriptions = new Subscription();

  constructor(private phoneUiContext: PhoneUiContext) {}

  ngOnInit() {
    this.isSingleButton = this.phone.buttons.length === 1;
    if (this.isSingleButton) {
      const sub = this.phoneUiContext.isSaving$.pipe().subscribe((isSubmitting) => {
        // These properties are sometimes unavailable when switching models on a public phone
        if (!!this.lineExtensionSearch) {
          this.lineExtensionSearch.isSubmitting = isSubmitting;
        }
        if (!!this.lineHelpdeskFormComponent) {
          this.lineHelpdeskFormComponent.fieldComponents.forEach((key) => {
            key.applyState({
              ...key.state,
              disabled: isSubmitting,
            });
          });
        }
      });
      this._subscriptions.add(sub);
    }
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dnRef && changes.dnRef.currentValue !== changes.dnRef.previousValue) {
      this.extensionSearchEntity = { ...this.extensionSearchEntity, dn: changes.dnRef.currentValue };
    }
    if (
      changes.lineButtonSummary &&
      changes.lineButtonSummary.currentValue !== changes.lineButtonSummary.previousValue
    ) {
      this.extensionSearchEntity = {
        ...this.extensionSearchEntity,
        peripherals: changes.lineButtonSummary.currentValue,
      };
    }
    if (changes.lineFeature && changes.lineFeature.currentValue) {
      this.isLineFeaturePending = false;
    }
  }

  onLineFeatureUpdate($event: SmacsFormsUpdate<LineFeature>) {
    if (!isEqual($event.new, $event.old) || !isEqual(this._formValidationStates.lineFeature, $event.valid)) {
      this._formValidationStates.lineFeature = $event.valid;

      const updateNew: LineButton = {
        type: 'Line',
        lineFeature: $event.new,
        dn: this.dnRef ? { ...this.dnRef } : null,
      };
      const old = { ...updateNew, lineFeature: $event.old };
      this.lineButtonWasUpdated.emit({ new: updateNew, old: old, valid: this._validationState() });
    }
  }

  onExtensionChanged($event: SmacsFormsUpdate<PhoneButtonsLineExtensionSearchEntity>) {
    if (!isEqual($event.new.dn, $event.old.dn) || !isEqual(this._formValidationStates.extensionSearch, $event.valid)) {
      this._formValidationStates.extensionSearch = $event.valid;

      if (!$event.new.dn?.extension) {
        // if the extension is absent, the line feature form is hidden so it'll always be valid.
        this._formValidationStates.lineFeature = SmacsFormsValidationState.VALID;
      } else if (!$event.old.dn?.extension) {
        // if the extension was just added, show the spinner on the extension search field.
        this.isLineFeaturePending = true;
      }

      const updateNew: LineButton = {
        type: 'Line',
        dn: $event.new.dn,
        lineFeature: this.lineFeature ? { ...this.lineFeature } : null,
      };
      const old = { ...updateNew, dn: $event.old.dn };
      this.lineButtonWasUpdated.emit({ new: updateNew, old: old, valid: this._validationState() });
    }
  }

  onVoicemailUpdate($event: VoicemailRef) {
    this.lineButtonSummaryWasUpdated.next({ ...this.lineButtonSummary, voicemail: $event });
  }

  fetchLineFeature($event: boolean) {
    this.dnUpdated.next($event);
  }

  private _validationState(): SmacsFormsValidationState {
    const validationStates = Object.values(this._formValidationStates);

    if (validationStates.includes(SmacsFormsValidationState.INVALID)) {
      return SmacsFormsValidationState.INVALID;
    } else if (validationStates.includes(SmacsFormsValidationState.WARNING)) {
      return SmacsFormsValidationState.WARNING;
    } else if (validationStates.includes(SmacsFormsValidationState.PENDING)) {
      return SmacsFormsValidationState.PENDING;
    } else {
      return SmacsFormsValidationState.VALID;
    }
  }

  onDnCreated($event: any) {
    if ($event) {
      this.modalTranslationPattern = $event.translationPatterns;
      const updateNew: LineButton = {
        type: 'Line',
        dn: $event.dnRef,
        lineFeature: this.lineFeature ? { ...this.lineFeature } : null,
      };
      const old = { ...updateNew, dn: $event.dnRef };
      this.lineButtonWasUpdated.emit({ new: updateNew, old: old, valid: this._validationState() });
    }
  }
}
