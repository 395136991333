import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeService } from '../services/date-time.service';

@Pipe({ name: 'dateTimeFormat' })
export class DateTimeFormat implements PipeTransform {
  constructor(private dateTimeService: DateTimeService) {}

  transform(value: string): string {
    return this.dateTimeService.formatDateTime(value);
  }
}

@Pipe({ name: 'timeFormat' })
export class TimeFormat implements PipeTransform {
  constructor(private dateTimeService: DateTimeService) {}

  transform(value: string): string {
    return this.dateTimeService.formatTime(value);
  }
}

@Pipe({ name: 'dateFormat' })
export class DateFormat implements PipeTransform {
  constructor(private dateTimeService: DateTimeService) {}

  transform(value: string): string {
    return this.dateTimeService.formatDate(value);
  }
}
