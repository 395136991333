import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LdapSyncJobStatus } from '../../models/generated/smacsModels';
import { PollingAbstractService } from '../abstract/polling.abstract.service';

@Injectable()
export class LdapSyncJobStatusPollingService extends PollingAbstractService<LdapSyncJobStatus> {
  baseUrl = '/services/cisco/automate-jobs/cisco-ldap-syncs/status';
  constructor(protected http: HttpClient, protected ngZone: NgZone) {
    super(http, ngZone);
  }
}
