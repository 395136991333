import { Injectable } from '@angular/core';
import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationContext } from './authentication.context';
import { Role } from '../models/generated/smacsModels';
import { GlobalPropertiesContext } from './global-properties.context';

export interface Stack8PortalVersions {
  ziroConsoleVersion: string;
  ziroVersion: string;
}

@Injectable()
export class PortalContext {
  private _stateSource = new ReplaySubject<Stack8PortalVersions>(1);
  state$ = this._stateSource.asObservable();

  constructor(
    private http: HttpClient,
    private authenticationContext: AuthenticationContext,
    private globalProperties: GlobalPropertiesContext
  ) {
    this._init();
  }

  // Public so stages can refresh state via MirageJS requests
  _init() {
    combineLatest([this.authenticationContext.state$, this.globalProperties.state$]).subscribe(
      ([user, globalProperties]) => {
        if (this.authenticationContext.userIsAtLeast(user, Role.S8_ADMIN) && !globalProperties.hostedEnabled) {
          this._get().subscribe({
            next: (data: Stack8PortalVersions) => {
              this._stateSource.next(data);
            },
            error: () => {
              this._stateSource.next({
                ziroVersion: null,
                ziroConsoleVersion: null,
              });
            },
          });
        }
      }
    );
  }

  private _get(): Observable<Stack8PortalVersions> {
    return this.http.get<Stack8PortalVersions>('https://portal.goziro.com/api/configuration/current-zpc-version');
  }
}
