<div class="d-flex flex-column h-100">
  <app-top-nav [isMicrosoft360View]="true"></app-top-nav>
  <app-loading-block [hidden]="!isPageLoading"></app-loading-block>
  <div class="container-fluid h-100" [hidden]="isPageLoading">
    <div class="row h-100">
      <smacs-microsoft360-detail-card
        class="col-lg-2 col-md-3 p-0"
        (viewLoaded)="setLoading($event)"
      ></smacs-microsoft360-detail-card>
      <div class="col-lg-10 col-md-9 user-detail-pane">
        <div class="user-detail-pane__outlet">
          <app-breadcrumbs></app-breadcrumbs>
          <router-outlet></router-outlet>
        </div>
        <app-bottom-nav class="user-detail-pane__bottom-nav"></app-bottom-nav>
      </div>
    </div>
  </div>
</div>
