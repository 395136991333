<app-top-nav></app-top-nav>
<app-loading-block [hidden]="!isLoading"></app-loading-block>
<div class="smacs-distribution-list-page-content">
  <div class="col-md-10 offset-md-1 top-buffer" *ngIf="!isLoading">
    <app-breadcrumbs></app-breadcrumbs>
    <fieldset *ngIf="table" data-automation="distribution-list-entity-table">
      <app-entity-table [table]="table" [tableRows]="tableRows"></app-entity-table>
    </fieldset>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
