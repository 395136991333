import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { DlrMisconfigurationsReportContext } from './services/dlr-misconfigurations-report.context';
import { DatatableColumn, DatatableRow } from '../../../../reporting/datatable/datatable.component';
import { BandwidthDlrMisconfigurations, MisconfigurationType } from '../../../../shared/models/generated/smacsModels';
import { TranslateService } from '@ngx-translate/core';
import { TelephoneNumberFilter } from '../../../../shared/filters/telephone-number.filter';
import { Subscription } from 'rxjs';
import { DatatableFilterService } from '../../../../reporting/datatable/datatable-filter.service';

interface DlrMisconfigurationsData extends DatatableRow {
  misconfigurationType: string;
  dlrEndpointId: string;
  geolocation: string;
  callerName: string;
  callbackNumber: string;
  preferredLanguage: string;
}

@Component({
  selector: 'smacs-dlr-misconfigurations-datatable',
  templateUrl: './dlr-misconfigurations-datatable.component.html',
})
export class DlrMisconfigurationsDatatableComponent implements OnInit, OnDestroy {
  @Output() rowWasSelected = new EventEmitter<void>();
  MISCONFIGURATION_TYPE_MAP: Map<MisconfigurationType, string> = new Map([
    [
      MisconfigurationType.MISSING_DLR,
      this.translateService.instant('tkey;admin.billing.dlr_misconfigurations.missing_dlr'),
    ],
    [
      MisconfigurationType.ORPHAN_DLR,
      this.translateService.instant('tkey;admin.billing.dlr_misconfigurations.orphan_dlr'),
    ],
  ]);
  misconfigurationTypeOptions: string[] = Array.from(this.MISCONFIGURATION_TYPE_MAP.values());

  tableColumns: DatatableColumn<DlrMisconfigurationsData>[] = [
    {
      name: 'misconfigurationType',
      label: 'tkey;admin.billing.dlr_misconfigurations.datatable.misconfiguration_type',
    },
    {
      name: 'dlrEndpointId',
      label: 'tkey;admin.billing.dlr_misconfigurations.datatable.dlr_endpoint_id',
    },
    {
      name: 'geolocation',
      label: 'tkey;admin.billing.dlr_misconfigurations.datatable.geolocation',
    },
    {
      name: 'callerName',
      label: 'tkey;admin.billing.dlr_misconfigurations.datatable.caller_name',
    },
    {
      name: 'callbackNumber',
      label: 'tkey;admin.billing.dlr_misconfigurations.datatable.callback_number',
      pipe: this.telephoneNumberFilter,
      columnCharacterWidth: 20,
    },
    {
      name: 'preferredLanguage',
      label: 'tkey;admin.billing.dlr_misconfigurations.datatable.preferred_language',
    },
  ];
  tableRows: DlrMisconfigurationsData[];

  noResultsAlert: string;
  tableDataAutomation = 'dlr-misconfigurations-datatable';

  private _subs = new Subscription();

  constructor(
    private translateService: TranslateService,
    private telephoneNumberFilter: TelephoneNumberFilter,
    private datatableFilterService: DatatableFilterService,
    private dlrMisconfigurationsReportContext: DlrMisconfigurationsReportContext
  ) {}

  ngOnInit() {
    const reportSub = this.dlrMisconfigurationsReportContext.state$.subscribe((report) => {
      this.tableRows = report.bandwidthDlrMisconfigurations.map((data) => this._buildRow(data));
      if (!this.tableRows.length) {
        this.noResultsAlert = 'tkey;admin.billing.dlr_misconfigurations.datatable.no_results';
      }
    });
    this._subs.add(reportSub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  onRowSelected(): void {
    this.rowWasSelected.emit();
  }

  private _buildRow(dataEntry: BandwidthDlrMisconfigurations): DlrMisconfigurationsData {
    return {
      misconfigurationType:
        this.MISCONFIGURATION_TYPE_MAP.get(dataEntry.misconfigurationType) ?? dataEntry.misconfigurationType,
      dlrEndpointId: dataEntry.dlrEndpointId,
      geolocation: dataEntry.geolocation,
      callerName: dataEntry.callerName,
      callbackNumber: dataEntry.callbackNumber,
      preferredLanguage: dataEntry.preferredLanguage,
      isTableRowSelectable: true,
      isTableRowSelectDisabled: dataEntry.misconfigurationType === MisconfigurationType.ORPHAN_DLR,
      tableRowSelectDisabledTooltip: 'tkey;admin.billing.dlr_misconfigurations.select_disabled.tooltip',
    };
  }

  isMisconfigurationTypeMatch = (filter: string[], row: DlrMisconfigurationsData): boolean =>
    this.datatableFilterService.filterMultiSelectValues(filter, row.misconfigurationType);
}
