import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslationPattern, TranslationPatternRef } from '../../../shared/models/generated/smacsModels';
import { Nvp } from '../../../shared/models/nvp';
import { AuditHeaderService } from '../../../shared/services/audit-header.service';

@Injectable()
export class TranslationPatternResource {
  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  get(id: string, serverId: number): Observable<TranslationPattern> {
    return this.http.get<TranslationPattern>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(serverId)}/translation-patterns/${encodeURIComponent(id)}`
    );
  }

  post(serverId: number, body: TranslationPattern, auditTags?: Nvp[]): Observable<TranslationPatternRef> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.post<TranslationPatternRef>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(serverId)}/translation-patterns`,
      body,
      httpOptions
    );
  }

  put(id: string, serverId: number, body: TranslationPattern, auditTags?: Nvp[]): Observable<TranslationPatternRef> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.put<TranslationPatternRef>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(serverId)}/translation-patterns/${encodeURIComponent(
        id
      )}`,
      body,
      httpOptions
    );
  }

  delete(id: string, serverId: number, auditTags?: Nvp[]): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.delete<void>(
      `/services/cisco/macs/cucm-servers/${encodeURIComponent(serverId)}/translation-patterns/${encodeURIComponent(
        id
      )}`,
      httpOptions
    );
  }

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }
}
