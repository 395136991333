import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'smacs-phone-fx',
  templateUrl: './phone-fx.component.html',
  styleUrls: ['./phone-fx.component.scss'],
})
export class PhoneFxComponent implements OnChanges {
  @Input() disabled: boolean;
  @Input() phoneName: string;
  @Input() unifiedFxUrl: string;

  ngOnChanges(changes: SimpleChanges) {
    if (this.unifiedFxUrl && !this.unifiedFxUrl.startsWith('http')) {
      this.unifiedFxUrl = `http://${this.unifiedFxUrl}`;
    }
  }

  openPhoneFx() {
    const url = `${this.unifiedFxUrl}/#/phonefx/${this.phoneName}`;
    const opts = 'height=745,width=493,menubar=no,resizable=no,scrollbars=no,toolbar=no';
    window.open(url, '', opts);
  }
}
