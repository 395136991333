import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { OptimizationTile } from '../../../state-management/models/report-dashboard.model';
import { Store } from '@ngrx/store';
import { DashboardState } from '../../dashboard.state';
import { dashboardActions } from '../../../state-management/actions/dashboard.actions';
import { initialOptimizationTile } from '../../../state-management/reducers/optimization-tile.reducer';

@Component({
  selector: 'app-dashboard-license-optimizer-tile',
  templateUrl: './dashboard-license-optimizer-tile.component.html',
})
export class DashboardLicenseOptimizerTileComponent implements OnInit {
  optimizationTileData$: Observable<OptimizationTile>;
  constructor(private store: Store<DashboardState>) {
    this.optimizationTileData$ = this.store.select('reportDashboardOptimizationTile');
  }

  ngOnInit() {
    /**
     * When the component is first initialized, an action is dispatched to load the tile data.
     * An effect will be listening to this action and will fetch the tile data.
     * The reducer will update the state of the tile and send it back to the component.
     */
    this.store.dispatch(dashboardActions.loadOptimizationTileAction(initialOptimizationTile));
  }
}
