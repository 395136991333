import { Component, EventEmitter, Output, QueryList, ViewChildren } from '@angular/core';
import { SmacsFieldConfig, SmacsFormsUpdate } from '../../../../../forms/smacs-forms-models';
import { SmacsFieldAbstractDirective } from '../../../../../forms/smacs-field-abstract.directive';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { AdminLdapManagementSettingsLdapServerUrlFormComponent } from './admin-ldap-management-settings-ldap-server-url-form.component';
import { ButtonStyles } from '../../../../../button/button.component';

type LdapServerUrlList = string[];

@Component({
  selector: 'smacs-admin-ldap-management-settings-ldap-server-url',
  templateUrl: './admin-ldap-management-settings-ldap-server-url.component.html',
  styleUrls: ['./admin-ldap-management-settings-ldap-server-url.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: AdminLdapManagementSettingsLdapServerUrlComponent }],
})
export class AdminLdapManagementSettingsLdapServerUrlComponent extends SmacsFieldAbstractDirective<
  LdapServerUrlList,
  LdapServerUrlList,
  SmacsFieldConfig
> {
  trackingList = [] as null[];
  @Output() removeServerInParent = new EventEmitter<number>();

  @ViewChildren(AdminLdapManagementSettingsLdapServerUrlFormComponent)
  childForms: QueryList<AdminLdapManagementSettingsLdapServerUrlFormComponent>;

  buttonStyles = ButtonStyles;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  init() {
    super.init();

    this.selfUpdate$.subscribe(() => {
      // In order to prevent constant re-renders of the inputs, they will be tracked and bound to a separate array
      this.trackingList = [];
      this.trackingList.length = this.fieldData.length || 1;
    });
  }

  addServer() {
    this.trackingList.push(null);
    this.fieldData.push('ldaps://:636');
  }

  onRemoveServer(index: number) {
    const updatedFieldData = this.fieldData.filter((item, i) => {
      if (index !== i) {
        return item;
      }
    });

    this.trackingList = new Array(updatedFieldData.length).fill(null);

    // Updating fieldData in parent, would not work here
    this.removeServerInParent.emit(index);
  }

  onFormUpdate($event: SmacsFormsUpdate<string>, index: number) {
    this.fieldData[index] = $event.new;
    this._selfUpdateSource.next(this.fieldData);
  }
}
