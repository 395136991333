import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileUploaderComponent } from './file-uploader.component';
import { FileUploaderDirective } from './file-uploader.directive';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { ButtonModule } from '../../button/button.module';
import { EntityTableModule } from '../entity-table/entity-table.module';

@NgModule({
  declarations: [FileUploaderComponent, FileUploaderDirective],
  imports: [CommonModule, FormsModule, TranslateModule, NgbTooltip, ButtonModule, EntityTableModule],
  exports: [FileUploaderComponent, FileUploaderDirective],
})
export class FileUploaderModule {}
