import { Injectable } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Nvp } from '../models/nvp';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const smacsRestConfig: any = {
      status: [],
    };
    const config: any = {};
    const transformedReqData = {
      body: request.body,
      headers: request.headers,
    };

    if (request.url.includes('services')) {
      config.url = `/services${request.url}`;

      if (smacsRestConfig.auditTags) {
        const auditHeader = smacsRestConfig.auditTags.map((tag: Nvp) => tag.name + '=' + tag.value).join(';');
        transformedReqData.headers = this.setHeaders(transformedReqData.headers, 'set-audits', String(auditHeader));
      }

      const testIdentifiers = {
        testRunUuid: localStorage.getItem('testRunUuid'),
        testUUID: localStorage.getItem('testUUID'),
      };
      if (testIdentifiers.testRunUuid) {
        transformedReqData.headers = this.setHeaders(
          transformedReqData.headers,
          'x-test-run-id',
          testIdentifiers.testRunUuid
        );
      }
      if (testIdentifiers.testUUID) {
        transformedReqData.headers = this.setHeaders(transformedReqData.headers, 'x-test-id', testIdentifiers.testUUID);
        let setAudit = transformedReqData.headers.get('set-audits');
        if (setAudit) {
          setAudit += `;testUUID=${testIdentifiers.testUUID}`;
          transformedReqData.headers = this.setHeaders(transformedReqData.headers, 'set-audits', setAudit);
        } else {
          transformedReqData.headers = this.setHeaders(
            transformedReqData.headers,
            'set-audits',
            `testUUID=${testIdentifiers.testUUID}`
          );
        }
      }

      smacsRestConfig.requestStartTime = Date.now();
      transformedReqData.headers = this.setHeaders(
        transformedReqData.headers,
        'x-smacs-config',
        JSON.stringify(smacsRestConfig)
      );
    }

    const clonedRequest = request.clone(transformedReqData);

    return next.handle(clonedRequest);
  }

  setHeaders(transformedReqDataHeaders: HttpHeaders, key: string, value: string): HttpHeaders {
    return transformedReqDataHeaders.set(key, value);
  }
}

export const httpInterceptorProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpInterceptorService,
  multi: true,
};
