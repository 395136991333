import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VoicemailFieldConfig, VoicemailFieldConfigRequest } from '../models/generated/smacsModels';

@Injectable()
export class VoicemailFieldsConfigResource {
  protected _baseUrl = '/services/cisco/field-configs/voicemails';

  constructor(protected http: HttpClient) {}

  post(body: VoicemailFieldConfigRequest): Observable<VoicemailFieldConfig> {
    return this.http.post<VoicemailFieldConfig>(this._baseUrl, body);
  }
}
