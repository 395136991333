import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MicrosoftCachedOptions } from '../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MsTeamsCachedOptionsResource {
  _baseUrl = '/services/microsoft/admin/msteams-cached-options';

  constructor(private http: HttpClient) {}

  get(): Observable<MicrosoftCachedOptions> {
    return this.http.get<MicrosoftCachedOptions>(this._baseUrl);
  }

  refreshTeamsCachedOptions(attribute?: keyof MicrosoftCachedOptions): Observable<void> {
    let url = this._baseUrl;
    if (attribute) {
      url += `?attribute=${attribute}`;
    }
    return this.http.post<void>(url, null);
  }
}
