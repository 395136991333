import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { EmailTemplate } from '../../shared/models/generated/smacsModels';
import { delayWhen, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../shared/services/smacs-modal.service';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../button/button.component';
import { ToastService } from '../../shared/services/toast.service';
import { HelpdeskOptionsContext } from '../../helpdesk/shared/contexts/helpdesk-options.context';

@Injectable()
export class EmailTemplatesContext {
  private _stateSource = new ReplaySubject<EmailTemplate[]>(1);
  state$ = this._stateSource.asObservable();

  private _baseUrl = '/services/cisco/admin/email-templates';

  constructor(
    private http: HttpClient,
    private translateService: TranslateService,
    private smacsModalService: SmacsModalService,
    private helpdeskOptionsContext: HelpdeskOptionsContext,
    private toastService: ToastService
  ) {
    this._refresh().subscribe();
  }

  getTemplate(id: number): Observable<EmailTemplate> {
    return this._get(id);
  }

  createTemplate(body: EmailTemplate): Observable<number> {
    return this._post(body).pipe(
      delayWhen(() => this._refresh()),
      delayWhen(() => this.helpdeskOptionsContext.refresh())
    );
  }

  updateTemplate(id: number, body: EmailTemplate): Observable<void> {
    return this._put(id, body).pipe(
      delayWhen(() => this._refresh()),
      delayWhen(() => this.helpdeskOptionsContext.refresh())
    );
  }

  deleteTemplate(emailTemplate: EmailTemplate): Observable<boolean> {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        title: this.translateService.instant('tkey;admin.email_templates.delete_modal.title'),
        promptBody: this.translateService.instant('tkey;admin.email_templates.delete_modal.message', {
          templateName: emailTemplate.emailTemplateName,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._delete(emailTemplate.id).pipe(
                delayWhen(() => this._refresh()),
                delayWhen(() => this.helpdeskOptionsContext.refresh()),
                map(() => {
                  this.toastService.pushDeleteToast(
                    'tkey;admin.email_templates.edit_view.toast.message.type',
                    emailTemplate.emailTemplateName
                  );
                  // Indicate to the calling method that the template was deleted
                  return true;
                })
              );
            },
          },
        ],
      },
    };
    return this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _refresh(): Observable<void> {
    return this._getAll().pipe(
      map((emailTemplates) => {
        this._stateSource.next(emailTemplates);
      })
    );
  }

  private _getAll(): Observable<EmailTemplate[]> {
    return this.http.get<EmailTemplate[]>(this._baseUrl);
  }

  private _get(id: number): Observable<EmailTemplate> {
    return this.http.get<EmailTemplate>(`${this._baseUrl}/${id}`);
  }

  private _post(body: EmailTemplate): Observable<number> {
    return this.http.post<number>(this._baseUrl, body);
  }

  private _put(id: number, body: EmailTemplate): Observable<void> {
    return this.http.put<void>(`${this._baseUrl}/${id}`, body);
  }

  private _delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this._baseUrl}/${id}`);
  }
}
