<div class="card border-primary mb-4">
  <div class="card-header bg-primary text-white">{{ 'tkey;admin.ldap.settings.title' | translate }}</div>

  <div class="card-body">
    <div class="container-fluid">
      <legend class="mt-0">{{ 'tkey;admin.ldap.settings.servers.title' | translate }}</legend>

      <div class="card bg-light mb-3">
        <div class="card-body" [innerHtml]="'tkey;admin.ldap.settings.servers.description' | translate"></div>
      </div>

      <smacs-admin-ldap-management-settings-ldap-server-url
        fieldId="urls"
        (removeServerInParent)="onRemoveServer($event)"
      ></smacs-admin-ldap-management-settings-ldap-server-url>

      <legend>{{ 'tkey;admin.ldap.settings.account.title' | translate }}</legend>

      <div class="card bg-light mb-3">
        <div class="card-body" [innerHtml]="'tkey;admin.ldap.settings.account.description' | translate"></div>
      </div>

      <smacs-text fieldId="username"></smacs-text>
      <smacs-text fieldId="password"></smacs-text>

      <legend>{{ 'tkey;admin.ldap.settings.general.title' | translate }}</legend>

      <div class="card bg-light mb-3">
        <div class="card-body" [innerHTML]="'tkey;admin.ldap.settings.general.description' | translate"></div>
      </div>

      <smacs-text fieldId="userBaseDn"></smacs-text>
      <smacs-text fieldId="userIdAttributeName"></smacs-text>
      <smacs-text fieldId="userObjectClass"></smacs-text>
      <smacs-text fieldId="groupBaseDn"></smacs-text>
      <smacs-text fieldId="groupAttributeName"></smacs-text>
      <smacs-text fieldId="groupObjectClass"></smacs-text>
      <smacs-text fieldId="connectTimeout"></smacs-text>
      <smacs-text fieldId="readTimeout"></smacs-text>
      <div [hidden]="isProxyServerEnabled">
        <legend>{{ 'tkey;admin.ldap.settings.site.title' | translate }}</legend>

        <div class="card bg-light mb-3">
          <div class="card-body">
            <span [innerHTML]="'tkey;admin.ldap.settings.site.description.1' | translate"></span>
            <a class="strong" [attr.href]="window.location.origin + '/app2/#/admin/site-management'" target="_blank">
              {{ 'tkey;admin.site_management.title' | translate }}
            </a>
            <span [innerHTML]="'tkey;admin.ldap.settings.site.description.2' | translate"></span>
          </div>
        </div>

        <smacs-text fieldId="siteAttributeName"></smacs-text>
        <smacs-checkbox fieldId="writeToSiteEnabled"></smacs-checkbox>

        <legend>{{ 'tkey;admin.ldap.settings.dial_plan.title' | translate }}</legend>

        <div class="card bg-light mb-3">
          <div class="card-body">
            <span [innerHTML]="'tkey;admin.ldap.settings.dial_plan.description.1' | translate"></span>
            <strong>
              <a [routerLink]="'/admin/cisco/dial-plan-management'" target="_blank">
                {{ 'tkey;dialplanmanagement.title' | translate }}
              </a>
            </strong>
            <span [innerHTML]="'tkey;admin.ldap.settings.dial_plan.description.2' | translate"></span>
          </div>
        </div>

        <smacs-text fieldId="extensionAttributeName"></smacs-text>
        <smacs-text fieldId="e164NumberAttributeName"></smacs-text>

        <legend>{{ 'tkey;admin.ldap.settings.additional.title' | translate }}</legend>

        <div class="card bg-light mb-3">
          <div class="card-body" [innerHTML]="'tkey;admin.ldap.settings.additional.description' | translate"></div>
        </div>

        <smacs-text fieldId="photoAttributeName"></smacs-text>
        <smacs-multi-text fieldId="additionalAttributeNames"></smacs-multi-text>

        <div class="d-flex justify-content-end">
          <smacs-submit-button
            [isPending]="isSubmitting"
            [isDisabled]="isSubmitting"
            [label]="'tkey;global.button.save.text'"
            [dataAutomation]="'save-ldap-settings'"
            [icon]="smacsIcons.OK"
            [iconPending]="smacsIcons.SAVING"
            [formSubmitSubject]="_validateAndSubmitSource"
          ></smacs-submit-button>
        </div>
      </div>
    </div>
  </div>
</div>
