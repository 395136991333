import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EmailTemplate } from '../../../../shared/models/generated/smacsModels';
import { EmailTemplatesContext } from '../../../contexts/email-templates.context';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { MenuItem } from 'primeng/api';
import { EmailTemplateEditFormComponent } from './email-template-edit-form/email-template-edit-form.component';

@Component({
  selector: 'smacs-email-template-edit',
  templateUrl: 'email-template-edit.component.html',
  styleUrls: ['../../../admin-page.scss'],
})
export class EmailTemplateEditComponent implements OnInit, OnDestroy {
  @ViewChild(EmailTemplateEditFormComponent) emailTemplateEditFormComponent: EmailTemplateEditFormComponent;

  emailTemplates: EmailTemplate[];
  emailTemplate: EmailTemplate;

  isLoading = true;
  isSubmitted = false;

  private _subscription = new Subscription();

  constructor(
    private emailTemplatesContext: EmailTemplatesContext,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private _router: Router
  ) {}

  ngOnInit() {
    const loadingObs: Observable<void>[] = [];
    loadingObs.push(
      this.emailTemplatesContext.state$.pipe(
        map((emailTemplates) => {
          this.emailTemplates = emailTemplates;
        })
      )
    );

    const templateId: string = this.route.snapshot.params.id;
    if (templateId) {
      const isCopy = this._router.url.includes('copy');
      loadingObs.push(
        this.emailTemplatesContext.getTemplate(Number(templateId)).pipe(
          map((template) => {
            this.emailTemplate = {
              ...template,
              emailTemplateName: !isCopy ? template.emailTemplateName : `Copy of ${template.emailTemplateName}`,
            };
            this._initBreadcrumbs(this.emailTemplate);
          })
        )
      );
    } else {
      this.emailTemplate = {
        id: undefined,
        emailTemplateName: '',
        is360ViewEndUserRecipient: true,
        otherRecipients: [],
        emailSubject: '',
        emailBody: `<p>Hello <strong>{{firstName}}</strong></p>
<p>Your voice services are now ready:</p>

<ul>
    <li>Your extension is <strong style="color:red"> {{primaryExtension}} </strong></li>
    <li>Please change your default voicemail pin <strong style="color:red">12345</strong> and record your greeting at your earliest convenience</li>
</ul>

<p>For any additional questions you can ask <a href="https://www.google.com" target="_blank">Google</a></p>
        `,
        senderName: '',
      };
      this._initBreadcrumbs(this.emailTemplate);
    }

    const loadingSub = combineLatest(loadingObs).subscribe(() => {
      this.isLoading = false;
    });
    this._subscription.add(loadingSub);
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
    this._subscription.unsubscribe();
  }

  private _initBreadcrumbs(emailTemplate: EmailTemplate) {
    const rootCrumb: MenuItem = {
      label: 'tkey;admin.email_templates.title',
      url: '/admin/cisco/email-templates',
      routerLink: true,
    };
    const thisCrumb: MenuItem = {
      label: emailTemplate.emailTemplateName || 'tkey;admin.email_templates.edit_view.breadcrumbs.new_template.label',
    };
    this.breadcrumbsService.updateBreadcrumbs([rootCrumb, thisCrumb]);
  }
}
