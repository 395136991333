import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { combineLatest, Subscription } from 'rxjs';
import { PcceAgentResource } from '../../shared/resources/pcce-agent.resource';
import {
  Global360View,
  PcceAgent,
  PcceAgentRef,
  PcceAgentsFieldConfig,
  UcMetadataCache,
} from '../../../shared/models/generated/smacsModels';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import { ToastService } from '../../../shared/services/toast.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import { UcMetadataCacheContext } from '../../../shared/contexts/uc-metadata-cache.context';
import { PcceAgentFieldConfigResource } from '../../shared/resources/field-config/pcce-agent-field-config.resource';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { take } from 'rxjs/operators';

export interface PcceAgentFieldConfigRequest {
  pcceServerId: number;
  department: string;
  username: string;
}

@Component({
  selector: 'smacs-pcce-agent-settings',
  templateUrl: './pcce-agent-settings.component.html',
  styleUrls: ['./pcce-agent-settings.component.scss'],
})
export class PcceAgentSettingsComponent implements OnInit, OnDestroy {
  isLoading = true;
  pageDisabled = true;
  username: string;
  smacsIcons = SmacsIcons;
  pcceAgentRef: PcceAgentRef;
  pcceAgent: Partial<PcceAgent>;
  ucMetadataCache: UcMetadataCache;
  global360View: Global360View;
  isNewAgent = false;
  isSsoAgent = false;
  pcceServer: string;
  fieldConfig: PcceAgentsFieldConfig;
  private _subscriptions = new Subscription();

  constructor(
    private global360ViewContext: Global360ViewContext,
    private toastService: ToastService,
    private pcceAgentResource: PcceAgentResource,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService,
    private userDetailUiContext: UserDetailUiContext,
    private ucMetadataCacheContext: UcMetadataCacheContext,
    private pcceAgentFieldConfigResource: PcceAgentFieldConfigResource,
    private currentClusterContext: CurrentClusterContext
  ) {}

  ngOnInit() {
    this.username = this.route.snapshot.paramMap.get('username');
    this.global360ViewContext.init(this.username);

    const sub = combineLatest([
      this.global360ViewContext.state$.pipe(take(1)),
      this.ucMetadataCacheContext.state$,
      this.currentClusterContext.state$,
      this.userDetailUiContext.state$.pipe(take(1)),
    ]).subscribe(([global360View, ucMetadataCache, currentCluster]) => {
      this._updateBreadCrumb();

      this.global360View = global360View;
      this.ucMetadataCache = ucMetadataCache;
      if (global360View.pcceAgents.length > 0) {
        this.isNewAgent = false;
        this.pcceAgentRef = global360View.pcceAgents[0];
        this.pcceAgentResource
          .get(this.pcceAgentRef.id, this.pcceAgentRef.serverId.toString())
          .subscribe((pcceAgent) => {
            this.pcceAgent = pcceAgent;
            this.isSsoAgent = pcceAgent.ssoEnabled;
            const requestBody = {
              pcceServerId: this.pcceAgentRef.serverId,
              department: this.pcceAgent.department === '' ? 'Global' : this.pcceAgent.department,
              username: this.pcceAgent.userName,
            };
            this.pcceAgentFieldConfigResource.getFieldConfigs(requestBody).subscribe((data: PcceAgentsFieldConfig) => {
              this.fieldConfig = data;
              this.pageDisabled = false;
              this.isLoading = false;
            });
          });
      } else {
        this.isNewAgent = true;
        this.isSsoAgent = false;
        if (Object.keys(this.ucMetadataCache.pcceMetadataCache).length > 0) {
          this.pcceAgent = this._getPcceAgentTemplate();
          this.pcceServer = currentCluster.pcceServerId.toString();
          this.pageDisabled = false;

          // To fetch field configs for new agent, department defaults to Global
          const requestBody = {
            pcceServerId: Number(this.pcceServer),
            department: this.pcceAgent.department === '' ? 'Global' : this.pcceAgent.department,
            username: this.pcceAgent.userName,
          };
          this.pcceAgentFieldConfigResource.getFieldConfigs(requestBody).subscribe((data: PcceAgentsFieldConfig) => {
            this.fieldConfig = data;
            this.isLoading = false;
          });
        } else {
          this.pageDisabled = true;
          this.isLoading = false;
        }
      }
    });
    this._subscriptions.add(sub);
  }

  private _getPcceAgentTemplate(): Partial<PcceAgent> {
    return {
      description: '',
      agentStateTrace: false,
      userName: this.username,
      firstName: this.global360View.endUsers[0].ref.firstName,
      lastName: this.global360View.endUsers[0].ref.lastName,
      password: '',
      loginEnabled: true,
      ssoEnabled: false,
      supervisor: false,
      department: '',
      booleanAgentAttributes: {},
      proficiencyAgentAttributes: {},
      skillGroups: [],
      agentDeskSettings: '',
      agentTeam: '',
      finesseId: '',
    };
  }

  private _updateBreadCrumb() {
    const userCrumb = `/app2/#/user/${encodeURIComponent(this.username)}`;

    this.breadcrumbsService.updateBreadcrumbs([
      {
        label: this.username,
        url: userCrumb,
      },
      {
        label: 'tkey;pcce.agent_settings.title',
      },
    ]);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
