import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DefaultEndUserRequest, EndUser } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class DefaultsResource {
  constructor(private http: HttpClient) {}

  endUserDefaults(body: DefaultEndUserRequest): Observable<EndUser> {
    return this.http.post<EndUser>(`/services/cisco/defaults/end-user`, {
      ...body,
    });
  }
}
