<p-calendar
  [attr.data-automation]="dataAutomation"
  [(ngModel)]="model"
  [selectionMode]="'range'"
  [showIcon]="true"
  [icon]="'icon-calendar'"
  [selectOtherMonths]="true"
  [numberOfMonths]="2"
  [inputStyleClass]="'smacs-primeng-datepicker-input'"
  [styleClass]="'smacs-primeng-datepicker'"
  [hourFormat]="12"
  (onClose)="handleCalendarClose()"
  (onShow)="handleCalendarOpen()"
></p-calendar>
