import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CallLog } from '../../../shared/models/generated/smacsModels';
import { FileDownloadService } from '../../../shared/services/file-download.service';

export interface CallLogsQueryParams {
  direction: string;
  startDate: string;
  endDate: string;
  username?: string;
  number?: string;
  deviceName?: string;
}

@Injectable()
export class CallLogsResource {
  constructor(private http: HttpClient, private fileDownloadService: FileDownloadService) {}

  get(params: CallLogsQueryParams): Observable<CallLog[]> {
    return this.http.get<CallLog[]>(`/services/cisco/reporting/call-logs`, { params: { ...params } });
  }

  getFile(params: CallLogsQueryParams): Observable<void> {
    return this.fileDownloadService.downloadFile(`/services/cisco/reporting/call-logs/export`, 'get', params);
  }
}
