import { Component } from '@angular/core';
import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import {
  HtmlInputAddOn,
  SmacsFieldComponentConfig,
  SmacsFormsMessage,
  SmacsFormsValidationState,
} from '../../smacs-forms-models';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import { isNil } from 'lodash';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../button/button.component';
import { NgModel } from '@angular/forms';

type SmacsFileValue = File;

export interface AcceptedFileExtensions {
  acceptedExtensions: string; // in the pattern usually accepted in file inputs e.g. '.jpg,.gif,.bmp'
  allowOthers?: boolean; // if true, allows file extensions other than those specified above
}

export class SmacsFileUploadConfig extends SmacsFieldComponentConfig {
  constructor(
    public config: {
      acceptedFileExtensions?: AcceptedFileExtensions;
      maxSize?: number; // in kB
      htmlInputAddOn?: HtmlInputAddOn;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-file-upload',
  templateUrl: './smacs-file-upload.component.html',
  styleUrls: ['./smacs-file-upload.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsFileUploadComponent }],
})
export class SmacsFileUploadComponent extends SmacsFieldAbstractDirective<
  SmacsFileValue,
  SmacsFileValue,
  SmacsFileUploadConfig
> {
  acceptedFileExtensions: AcceptedFileExtensions;
  maxSize: number;
  htmlInputAddOn: HtmlInputAddOn;
  smacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  fileSize: number;

  _filePath = '';

  private _validators = {
    isWithinSizeConstraint: {
      validator: (val: File): SmacsFormsValidationState => {
        this.fileSize = val?.size;
        return !val || this.fileSize <= this.maxSize * 1024
          ? SmacsFormsValidationState.VALID
          : SmacsFormsValidationState.INVALID;
      },
      message: (): SmacsFormsMessage => {
        return {
          content: 'tkey;shared.fileselect.validators.max_size',
          params: {
            size:
              this.maxSize <= 1024
                ? `${this.maxSize} KB`
                : this.maxSize > 1024 && this.maxSize < 1048576
                ? `${Math.floor((this.maxSize * 10) / 1024) / 10} MB`
                : `${Math.floor((this.maxSize * 10) / 1048576) / 10} GB`,
          },
        };
      },
    },
    isAcceptedFileType: {
      validator: (val: File): SmacsFormsValidationState => {
        if (!val) {
          return SmacsFormsValidationState.VALID;
        }
        const fileType = val.name.substring(val.name.lastIndexOf('.'));
        return this.acceptedFileExtensions.acceptedExtensions.split(',').some((type) => type.trim() === fileType)
          ? SmacsFormsValidationState.VALID
          : SmacsFormsValidationState.INVALID;
      },
      message: (): SmacsFormsMessage => {
        return {
          content: 'tkey;shared.fileselect.validators.strict_file_types',
          params: { types: this.acceptedFileExtensions.acceptedExtensions.replace(/\s*,\s*/g, '/') },
        };
      },
    },
  };

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  applyComponentConfig = ({ config }: SmacsFileUploadConfig) => {
    // remove component-specific validators if they are already present, to preserve the proper order and prevent dupes
    this.config.validation = (this.config.validation || []).filter((validator) => {
      return validator !== this._validators.isWithinSizeConstraint && validator !== this._validators.isAcceptedFileType;
    });

    this.htmlInputAddOn = isNil(config.htmlInputAddOn) ? this.htmlInputAddOn : config.htmlInputAddOn;

    this.acceptedFileExtensions = isNil(config.acceptedFileExtensions)
      ? this.acceptedFileExtensions
      : config.acceptedFileExtensions;
    this.maxSize = isNil(config.maxSize) ? this.maxSize : config.maxSize;

    if (this.maxSize) {
      this.config.validation = [this._validators.isWithinSizeConstraint, ...this.config.validation];
    }
    if (this.acceptedFileExtensions && !this.acceptedFileExtensions.allowOthers) {
      this.config.validation = [this._validators.isAcceptedFileType, ...this.config.validation];
    }
  };

  onFileChange(files: FileList, ngModel: NgModel) {
    if (files && files.item(0)) {
      this._filePath = '';
      const file = files.item(0);
      this.updateStateAndSelf(file, ngModel);
    }
  }

  onRemoveClicked(ngModel: NgModel) {
    this._filePath = '';
    this.updateStateAndSelf(null, ngModel);
  }

  toEntity = (fieldData: SmacsFileValue) => fieldData;

  toFieldData = (entity: SmacsFileValue) => entity;
}
