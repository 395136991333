import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { HtmlInputType, SmacsTextComponent, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsSelectConfig } from '../../../forms/fields/select/smacs-select.component';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber, Subscription } from 'rxjs';
import { EndUserSearchResource } from '../../../shared/resources/end-user-search.resource';
import { ClusterResult, EndUser, EndUserResult } from '../../../shared/models/generated/smacsModels';
import { find } from 'lodash';
import { EndUserResource } from '../../../shared/resources/end-user.resource';
import { tap } from 'rxjs/operators';
import { DefaultsResource } from '../../shared/resources/defaults.resource';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { Router } from '@angular/router';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';

export interface EndUserFormData {
  cucmServer: string;
  userName: string;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'smacs-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
})
export class AddUserComponent
  extends SmacsFormAbstractDirective<EndUserFormData>
  implements OnInit, AfterViewInit, OnDestroy
{
  smacsIcons = SmacsIcons;
  isLoading = true;
  private _clusters = [] as ClusterResult[];
  private _subscriptions = new Subscription();

  validators = {
    isUserNameUnique: (val: string): Observable<SmacsFormsValidationState> =>
      new Observable<SmacsFormsValidationState>((subscriber: Subscriber<SmacsFormsValidationState>) => {
        this.endUserSearchResource
          .searchByQ(val, this._getServerId(this.formData.cucmServer), 100)
          .subscribe((users: EndUserResult[]) => {
            const matchEmail = find(
              users,
              (usersResult: EndUserResult) => usersResult.email.toLowerCase() === val.toLowerCase()
            );
            const matchUserName = find(
              users,
              (userResult: EndUserResult) => userResult.ref.username.toLowerCase() === val.toLowerCase()
            );
            const match = matchEmail || matchUserName;

            subscriber.next(match ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID);
            subscriber.complete();
          });
      }),
  };
  formConfig = {
    fields: {
      cucmServer: {
        fieldId: 'cucmServer',
        label: 'tkey;pages.useradd.summary.cucm_server.text',
        dataAutomation: 'add-user-cucm-server',
        componentConfig: new SmacsSelectConfig({ options: [] }),
        required: true,
      },
      userName: {
        fieldId: 'userName',
        label: 'tkey;pages.useradd.summary.userid',
        dataAutomation: 'add-user-user-name',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'tkey;pages.useradd.summary.userid.placeholder',
        }),
        required: true,
        validation: [
          {
            validator: (val) => (val.length >= 2 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID),
            message: this.translateService.instant('tkey;validators.global.error.min', {
              minValue: 2,
            }),
          },
          {
            validator: (val) =>
              /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val) || /^(([A-Z0-9]+)([.-]))*([A-Z0-9]+)$/i.test(val)
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: 'tkey;pages.useradd.email.validator.message',
          },
          {
            validator: this.validators.isUserNameUnique,
            message: 'tkey;pages.useradd.alreadyExists',
          },
        ],
        hidden: () => !this.formData.cucmServer,
      },
      firstName: {
        fieldId: 'firstName',
        label: 'tkey;pages.useradd.summary.firstname',
        dataAutomation: 'add-user-first-name',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'tkey;pages.useradd.summary.firstname.placeholder',
        }),
        required: true,
        hidden: () => !this.formData.cucmServer,
      },
      lastName: {
        fieldId: 'lastName',
        label: 'tkey;pages.useradd.summary.lastname',
        dataAutomation: 'add-user-last-name',
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'tkey;pages.useradd.summary.lastname.placeholder',
        }),
        required: true,
        hidden: () => !this.formData.cucmServer,
      },
    },
  } as SmacsFormConfig;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private breadcrumbsService: BreadcrumbsService,
    private translateService: TranslateService,
    private defaultsResource: DefaultsResource,
    private smacsModalService: SmacsModalService,
    private router: Router,
    private siteSummaryContext: SiteSummaryContext,
    private bottomNavService: BottomNavService,
    private endUserSearchResource: EndUserSearchResource,
    private endUserResource: EndUserResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;pages.useradd.title' }]);
    const validateAndSubmitSub = this._validateAndSubmitSource.subscribe(() => {
      this._setPendingState(true);
      this.isErrorPresent.subscribe((hasError) => {
        if (hasError) {
          this._setPendingState(false);
        }
        this.bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: hasError,
          })
        );
      });
    });
    this._subscriptions.add(validateAndSubmitSub);
    this._initBottomNav();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();

    setTimeout(() => {
      const siteSummarySub = this.siteSummaryContext.state$.subscribe((siteSummary) => {
        this._clusters = siteSummary.clusters;
        this._setAllServers();
        this.isLoading = false;
      });
      this._subscriptions.add(siteSummarySub);
    }, 0);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  protected submit() {
    return this._onValidateClicked();
  }

  private _initBottomNav(): void {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'add-user-save-button',
          label: 'tkey;global.button.save.text',
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'add-user-save-button',
          state: {
            pending: false,
            buttonDisableState: {
              disabled: false,
              tooltipKey: '',
            },
          },
          icon: this.smacsIcons.OK,
          type: ButtonTypes.SUBMIT,
          submitSubject: this._validateAndSubmitSource,
        },
      ])
    );
  }

  private _onValidateClicked() {
    const cucmServer = this._getServerId(this.formData.cucmServer);
    const newEndUser = {
      directoryUri: '',
      enableCti: false,
      enableHomeCluster: true,
      enableMobileVoiceAccess: false,
      enableMobility: false,
      extensionMobilityCrossCluster: true,
      firstName: this.formData.firstName,
      groups: [],
      ipccExtension: null,
      lastName: this.formData.lastName,
      mailId: '',
      primaryExtension: null,
      selfServiceUserId: '',
      subscribeCss: '',
      ucServiceProfile: '',
      username: this.formData.userName,
    } as EndUser;
    const username = this.formData.userName;
    const url = `/user/${username}`;
    return this.endUserResource.post(newEndUser, cucmServer).pipe(
      tap(() => {
        this._setPendingState(false);
        const options = {
          windowClass: 'save-button-modal',
          modalViewProperties: {
            icon: SmacsIcons.CHECK_CIRCLE,
            iconClass: 'text-success',
            title: this.translateService.instant('tkey;pages.useradd.saved.title'),
            promptBody: this.translateService.instant('tkey;pages.useradd.saved.subtitle'),
            displayCloseButton: true,
            buttons: [
              {
                label: 'tkey;pages.useradd.title',
                buttonClass: ButtonStyles.DEFAULT,
                dataAutomation: 'confirmation-modal-cancel-button',
              },
              {
                label: `${this.translateService.instant('tkey;pages.useradd.success.editUser')} ${username}`,
                buttonClass: ButtonStyles.PRIMARY,
                dataAutomation: 'confirmation-modal-confirm-button',
                cb: () => this._clickEditUserButton(url),
              },
            ],
          },
        };

        this.smacsModalService
          .openPromptModal(() => options.modalViewProperties, options)
          .subscribe(() => {
            this._resetFormState();
          });
      })
    );
  }

  private _clickEditUserButton(url: string): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this.router.navigateByUrl(url);
      subscriber.next();
    });
  }

  private _resetFormState() {
    this.setIsExisting(false);
    this.entitySource.next({
      cucmServer: '',
      userName: '',
      firstName: '',
      lastName: '',
    });
    this.smacsFormStateService.setIsFormDirty(false);
    this.isFormSubmitted = false;
    this.fieldComponents.forEach((item: SmacsTextComponent) => {
      item.isDirty = false;
      item.showAsyncValidation = false;
      item.showValidation = false;
      item.isFormSubmitted = this.isFormSubmitted;
    });
  }

  private _getServerId(server: string): number {
    const clusterResult = this._clusters.find((cluster) => cluster.name.toLowerCase() === server.toLowerCase());
    return clusterResult.cucmServerId;
  }

  private _setAllServers() {
    const clusterNameOptions = this._clusters.map((clusterResult) => clusterResult.name);
    const cucmServerSelector = this.fieldComponents.find((field) => field.fieldId === 'cucmServer');
    cucmServerSelector.applyComponentConfig(new SmacsSelectConfig({ options: clusterNameOptions }));
  }

  private _setPendingState(state: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'add-user-save-button',
        state: {
          pending: state,
          buttonDisableState: { disabled: state, tooltipKey: '' },
          tooltipVisible: false,
        },
      })
    );
  }
}
