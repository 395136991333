<smacs-text fieldId="bulkJobName"></smacs-text>

<smacs-collapsible-card
  [label]="'tkey;automate.zpm.bulk_provisioning.teams_calling_settings.label'"
  [svgIcon]="smacsIcons.DESKPHONE_OUTLINE"
  [shouldBeOpen]="true"
  [dataAutomation]="'teams-calling-settings-toggle'"
  [switchId]="'teams-calling-settings-toggle'"
  [cssClass]="'bg-info text-white fs-5 p-3'"
  [isCollapsible]="false"
>
  <smacs-select fieldId="dialPlanGroup"></smacs-select>
</smacs-collapsible-card>

<div class="card mb-4" data-automation="draft-info">
  <div class="card-header d-flex justify-content-between align-items-center bg-info text-white fs-5 p-3">
    <span class="p-0 m-0">
      <i [attr.class]="smacsIcons.USER_CIRCLE"></i>
      {{ 'tkey;automate.zpm.bulk_provisioning.user_list.label' | translate }}
    </span>
  </div>
  <div class="card-body pb-0">
    <div>
      <div class="mb-4">
        <div [innerHTML]="'tkey;automate.zpm.bulk_provisioning.user_list.instructions' | translate"></div>
        <a
          class="btn btn-primary"
          [href]="csvTemplateUrl"
          [download]="'zpm_bulk_provisioning_job_template.csv'"
          data-automation="microsoft-bulk-provisioning-upload-csv-download-template-link"
        >
          <i [attr.class]="smacsIcons.DOWNLOAD + ' me-1'"></i>
          <strong>{{ 'tkey;automate.zpm.bulk_provisioning.user_list.download_csv.label' | translate }}</strong>
        </a>
      </div>

      <smacs-file-uploader [fieldId]="'file'" [showTable]="false"></smacs-file-uploader>

      <smacs-select fieldId="addUpn"></smacs-select>

      <app-loading-block [hidden]="!isLoadingFieldData"></app-loading-block>

      <div
        *ngIf="selectedDialPlan && selectedDialPlan.callingType === CallingType.EXTENSION && !isLoadingFieldData"
        data-automation="zpm-bulk-provisioning-main-number-alert"
        class="alert alert-info mb-3"
      >
        <h4 data-automation="selected-group-main-number-alert-number" class="mb-0">
          <strong>
            {{ 'tkey;automate.zpm.bulk_provisioning.main_number.label' | translate }}
            {{ selectedDialPlan.mainNumber | telephoneNumberFilter }}
          </strong>
        </h4>
        <p class="mb-0">{{ 'tkey;microsoft_360.view.teams.extensionSelection.main_number.message' | translate }}</p>
      </div>

      <div *ngIf="table && !isLoadingFieldData" data-automation="zpm-bulk-provisioning-preview-entity-table">
        <app-entity-table
          [table]="table"
          [tableRows]="tableRows"
          [tableResponsive]="true"
          [scrollableTable]="true"
          [selectAppendTo]="'body'"
          (fieldChange)="onFieldChange($event)"
        ></app-entity-table>
      </div>
    </div>
  </div>
</div>
