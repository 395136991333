import { SiteContext } from '../../shared/contexts/site.context';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PhoneUiContext } from '../../../shared/phone-buttons/contexts/phone-ui.context';
import { ActivatedRoute, ActivationEnd, ActivationStart, Router, Scroll } from '@angular/router';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { combineLatest, Subscription, switchMap } from 'rxjs';
import { first } from 'rxjs/operators';
import { cloneDeep, get, isEqual } from 'lodash';
import { MenuItem } from 'primeng/api';
import { Phone } from '../../../shared/models/generated/smacsModels';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { TileConfigContext } from '../../shared/contexts/tile-config.context';

@Component({
  selector: 'smacs-public-phone',
  templateUrl: './public-phone.component.html',
  styleUrls: ['./public-phone.component.scss'],
  providers: [SiteContext, PhoneUiContext],
})
export class PublicPhoneComponent implements OnInit, OnDestroy {
  phone: Phone;
  initialPhone: Phone;
  phoneId: string;
  isLoading = true;
  isCopyPhoneButtonEnabled = true;
  isUnsavedCopy = false;

  private _subscription = new Subscription();

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _breadcrumbsService: BreadcrumbsService,
    private _siteContext: SiteContext,
    private _phoneUiContext: PhoneUiContext,
    private _siteSummaryContext: SiteSummaryContext,
    private _tileConfigContext: TileConfigContext
  ) {}

  ngOnInit() {
    const baseCrumb: MenuItem = {
      label: 'tkey;pages.phone.header',
    };
    const phoneName = this._route.snapshot.params.phoneName;
    if (phoneName) {
      baseCrumb.url = `/public-phone/${phoneName}`;
      baseCrumb.routerLink = true;

      const subscription = combineLatest([
        this._phoneUiContext.phoneState$.pipe(first()),
        this._siteSummaryContext.state$,
      ]).subscribe(([phone, siteSummary]) => {
        this._siteContext.setSiteForPublicPhone(siteSummary, phone);
      });
      this._subscription.add(subscription);
    } else {
      const siteSummarySubscription = this._siteSummaryContext.state$.subscribe((state) => {
        this._siteContext.setSiteForPublicPhone(state);
      });
      this._subscription.add(siteSummarySubscription);
    }

    const initialRouteCrumb = this._route.firstChild?.snapshot?.data?.breadcrumb;
    if (initialRouteCrumb) {
      this._breadcrumbsService.updateBreadcrumbs([baseCrumb, { label: initialRouteCrumb }]);
    } else {
      this._breadcrumbsService.updateBreadcrumbs([baseCrumb]);
    }

    this._siteContext.state$
      .pipe(switchMap((site) => this._tileConfigContext.getTileConfigs(site.id)))
      .subscribe(() => {
        this.isLoading = false;
      });

    const phoneUiSub = this._phoneUiContext.phoneState$.subscribe((phone) => {
      this.phone = phone;
      this.phoneId = phone.id;

      if (!this.initialPhone) {
        this.initialPhone = cloneDeep(phone);
      }

      this.isCopyPhoneButtonEnabled = isEqual(this.initialPhone, this.phone);
    });
    this._subscription.add(phoneUiSub);

    const routerSub = this._router.events.subscribe((event) => {
      if (event instanceof Scroll && event.routerEvent.url.includes('/copy')) {
        this.isUnsavedCopy = true;
      }
      if (event instanceof Scroll && event.routerEvent.url.includes('reassign')) {
        const reassignCrumb: MenuItem = {
          label: 'tkey;userdetail.breadcrumb.transfer.text',
          routerLink: true,
          url: baseCrumb.url + '/reassign',
        };
        const crumbs = [baseCrumb, reassignCrumb];

        if (event.routerEvent.url.includes('/reassign/')) {
          const username = event.routerEvent.url.split('/').reverse()[0];
          const userCrumb: MenuItem = {
            label: username,
          };
          this._breadcrumbsService.updateBreadcrumbs(crumbs.concat(userCrumb));
        } else {
          this._breadcrumbsService.updateBreadcrumbs(crumbs);
        }
        return;
      }
      if (event instanceof ActivationStart) {
        const routeCrumb = get(event, ['snapshot', 'data', 'breadcrumb']);
        if (routeCrumb) {
          this._breadcrumbsService.updateBreadcrumbs([baseCrumb, { label: routeCrumb }]);
        } else {
          this._breadcrumbsService.updateBreadcrumbs([baseCrumb]);
        }
      }
      if (event instanceof ActivationEnd) {
        // happens after saving if we are staying on the same route
        this.initialPhone = cloneDeep(this.phone);
        this.isCopyPhoneButtonEnabled = true;
      }
    });
    this._subscription.add(routerSub);
  }

  ngOnDestroy() {
    this._breadcrumbsService.clearBreadcrumbs();
    this._subscription.unsubscribe();
  }
}
