<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div
          [innerHTML]="
            isReadOnly
              ? ('tkey;reporting.microsoft.custom_powershell_reports.about.read' | translate)
              : ('tkey;reporting.microsoft.custom_powershell_reports.about.read_write' | translate)
          "
        ></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <app-loading-block [hidden]="!isLoading"></app-loading-block>
    <app-entity-table
      *ngIf="!isLoading"
      [table]="table"
      [tableRows]="tableRows"
      data-automation="custom-powershell-reports-entity-table"
    ></app-entity-table>
  </div>
</div>

<app-bottom-nav [hidden]="isReadOnly"></app-bottom-nav>
