<div data-automation="edit-custom-select-modal" class="edit-custom-select-modal">
  <div class="modal-header">
    <h3 data-automation="edit-custom-select-modal-title" class="mb-0">
      {{ modalProperties.modalHeaderKey | translate }}
    </h3>
  </div>

  <div class="modal-body">
    <div class="card card-well mb-3">
      <div class="card-header pb-0" [innerHTML]="modalProperties.translations.description | translate"></div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <app-connected-list
          [availableOptions]="availableOptions"
          [configuredOptions]="configuredOptions"
          [defaultOptions]="defaultOptions"
          [isMultiSelect]="modalProperties.isMultiSelect"
          [availableTranslation]="modalProperties.translations.available"
          [showTranslation]="modalProperties.translations.show"
          [defaultTranslation]="modalProperties.translations.default"
          [isLoadingOptions]="isLoadingOptions"
          (listChange)="onChange($event)"
        ></app-connected-list>
      </div>
    </div>
    <br />
    <div [hidden]="!modalProperties.isPreviewVisible" class="card card-well deselect-protect">
      <div class="card-header">
        <div>
          <h4>{{ 'tkey;custom_select.preview.modal.label' | translate }}</h4>
          <ng-select
            class="w-100"
            [attr.data-automation]="'edit-custom-select-modal-preview'"
            [items]="previewOptions"
            [(ngModel)]="selectedDefault"
            [ngModelOptions]="{ standalone: true }"
            [clearable]="false"
            (change)="onDefaultSelectChange()"
            [multiple]="modalProperties.isMultiSelect"
          ></ng-select>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer justify-content-end">
    <smacs-button
      [label]="'tkey;dialogs.button.cancel'"
      [dataAutomation]="'edit-custom-select-modal-cancel-button'"
      [buttonStyle]="buttonStyles.DEFAULT"
      [buttonSize]="buttonSizes.LARGE"
      (clicked)="onCancelClick()"
    ></smacs-button>
    <smacs-button
      [label]="'tkey;dialogs.button.ok'"
      [dataAutomation]="'edit-custom-select-modal-ok-button'"
      [buttonSize]="buttonSizes.LARGE"
      (clicked)="onConfirmClick()"
    ></smacs-button>
  </div>
</div>
