import { Component, OnInit } from '@angular/core';
import { SmacsFormConfig } from '../../../../forms/smacs-forms-models';
import { ModalBodyClass } from '../../../../modals/modal-body';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { DetailedModalComponent } from '../../../../modals/detailed-modal/detailed-modal.component';
import { Observable } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PortInDraftSignedLoasResource } from '../../../resources/port-in-draft-signed-loas.resource';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ziro-generate-loa-modal',
  templateUrl: './generate-loa-modal.component.html',
  styleUrls: ['./generate-loa-modal.component.scss'],
})
export class GenerateLoaModalComponent extends ModalBodyClass implements OnInit {
  isLoading = true;
  formConfig: SmacsFormConfig = { fields: {} };
  pdfSrc: SafeResourceUrl;
  draftId: number;
  authorizingPersonDisplayName: string;
  currentUserDisplayName: string;

  constructor(
    protected smacsFormsStateService: SmacsFormStateService,
    private portInDraftSignedLoasResource: PortInDraftSignedLoasResource,
    private domSanitizer: DomSanitizer,
    private modalComponent: DetailedModalComponent<GenerateLoaModalComponent>
  ) {
    super(smacsFormsStateService);
  }

  ngOnInit() {
    this.draftId = this.modalComponent.modalViewProperties.draftId;
    this.authorizingPersonDisplayName = this.modalComponent.modalViewProperties.authorizingPersonDisplayName;
    this.currentUserDisplayName = this.modalComponent.modalViewProperties.currentUserDisplayName;

    this.pdfSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(
      `/services/microsoft/orders/port-in-drafts/${this.draftId}/generated-signed-loas`
    );
    this.isLoading = false;
  }

  submit(): Observable<boolean> {
    return this.portInDraftSignedLoasResource.generateSignedDocument(this.draftId).pipe(map(() => true));
  }
}
