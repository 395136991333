import { Component, OnInit } from '@angular/core';
import { ModalBodyClass } from '../../../../modals/modal-body';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { DetailedModalComponent } from '../../../../modals/detailed-modal/detailed-modal.component';
import { Observable } from 'rxjs';
import { Email, EmailTemplatePreviewFieldConfig } from '../../../../shared/models/generated/smacsModels';
import { Nvp } from '../../../../shared/models/nvp';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { EmailService } from '../../../shared/services/email.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EmailTemplatePreviewsFieldConfigResource } from '../../../shared/resources/field-config/email-template-previews-field-config.resource';

@Component({
  selector: 'smacs-email-preview-detailed-modal',
  templateUrl: './email-preview-detailed-modal.component.html',
})
export class EmailPreviewDetailedModalComponent extends ModalBodyClass implements OnInit {
  formConfig = { fields: {} };

  emailSenderName: string;
  emailRecipients: string;
  emailSubject: SafeHtml;
  emailBody: SafeHtml;

  isLoading = true;

  private _emailTemplatePreview: EmailTemplatePreviewFieldConfig;
  private _enduserUsername: string;

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private detailedModalComponent: DetailedModalComponent<any>,
    private activeModal: NgbActiveModal,
    private domSanitizer: DomSanitizer,
    private emailService: EmailService,
    private emailTemplatePreviewsFieldConfigResource: EmailTemplatePreviewsFieldConfigResource
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._enduserUsername = this.detailedModalComponent.modalViewProperties.enduserUsername;
    this._emailTemplatePreview = this.detailedModalComponent.modalViewProperties.emailTemplatePreview;

    if (this._emailTemplatePreview) {
      this._initView(this._emailTemplatePreview);
    } else {
      this.emailTemplatePreviewsFieldConfigResource
        .get(this._enduserUsername, this.detailedModalComponent.modalViewProperties.cucmServerId)
        .subscribe((emailTemplates) => {
          this._emailTemplatePreview = emailTemplates[0];
          this._initView(this._emailTemplatePreview);
        });
    }
  }

  private _initView(emailTemplatePreview: EmailTemplatePreviewFieldConfig) {
    this.emailSenderName = emailTemplatePreview.senderName;
    this.emailRecipients = emailTemplatePreview.recipients.join('; ');
    this.emailSubject = this.domSanitizer.bypassSecurityTrustHtml(emailTemplatePreview.emailSubject);
    this.emailBody = this.domSanitizer.bypassSecurityTrustHtml(emailTemplatePreview.emailBody);
    this.isLoading = false;
  }

  submit(): Observable<void> {
    const email: Email = {
      senderName: this._emailTemplatePreview.senderName,
      recipients: this._emailTemplatePreview.recipients,
      emailSubject: this._emailTemplatePreview.emailSubject,
      emailBody: this._emailTemplatePreview.emailBody,
    };
    const audits: Nvp[] = [
      {
        name: 'templateName',
        value: this._emailTemplatePreview.emailTemplateName,
      },
      {
        name: '360ViewEnduser',
        value: this._enduserUsername,
      },
    ];
    return this.emailService.sendEmail(email, audits);
  }

  cancel() {
    if (!this.isSubmitting) {
      this.activeModal.dismiss();
    }
  }
}
