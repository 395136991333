import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../../../forms/fields/select/smacs-select.component';
import { SmacsFormConfig, SmacsFormsUpdate } from '../../../../../../forms/smacs-forms-models';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../../../../button/button.component';
import { SmacsFormAbstractDirective } from '../../../../../../forms/smacs-form-abstract.directive';
import {
  BulkFieldCopyRequest,
  ServiceSetting,
  Site,
  SiteResult,
} from '../../../../../../shared/models/generated/smacsModels';
import * as _ from 'lodash';
import { SmacsIcons } from '../../../../../../shared/models/smacs-icons.enum';
import { Observable, of, Subscription } from 'rxjs';
import { KeyValue } from '@angular/common';
import { SmacsRadioConfig } from '../../../../../../forms/fields/radio/smacs-radio.component';
import { SitesResource } from '../../../services/sites.resource';
import { SmacsModalService } from '../../../../../../shared/services/smacs-modal.service';
import { SmacsFormStateService } from '../../../../../../forms/smacs-form-state.service';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { ToastTypes } from '../../../../../../shared/services/abstract/toast.service.abstract';

export const DisplaySort = ['name', 'value', 'possibleOptions', 'defaultOption', 'required', 'show'];

export enum CopyDestination {
  ALL_SITES = 'ALL_SITES',
  SUBSET_SITES = 'SUBSET_SITES',
}
export interface BulkFieldCopyForm {
  fieldName: string;
  serviceName: string;
  siteName: string;
  copyDestination: CopyDestination;
  selectedSites: SmacsSelectOption[];
}
@Component({
  selector: 'app-site-bulk-field-copy-form',
  templateUrl: './site-bulk-field-copy-form.component.html',
})
export class SiteBulkFieldCopyFormComponent
  extends SmacsFormAbstractDirective<BulkFieldCopyForm>
  implements OnInit, OnDestroy
{
  @Input() clusterId: number;
  @Input() sites: SiteResult[];
  @Input() currentClusterName: string;
  formConfig: SmacsFormConfig;
  selectedSite: Site;
  selectedFieldData: any = null;
  selectedField: string;
  selectedSection: string;
  smacsIcons = SmacsIcons;
  isLoadingSections = false;
  private _subscriptions = new Subscription();

  constructor(
    private bottomNavService: BottomNavService,
    private modalService: SmacsModalService,
    private siteResource: SitesResource,
    protected smacsFormStateService: SmacsFormStateService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private toastService: ToastService
  ) {
    super(smacsFormStateService);
  }
  bottomNavButtons = [
    {
      id: 'site-bulk-field-copy-cancel',
      dataAutomation: 'bulk-field-copy-cancel',
      label: 'tkey;global.button.cancel.text',
      buttonClass: ButtonStyles.DEFAULT,
      cb: () => {
        this.router.navigate(['..'], { relativeTo: this.route });
      },
    },
    {
      id: 'site-apply-bulk-field-copy',
      dataAutomation: 'bulk-field-copy-save',
      label: 'tkey;admin.site_management.bulk_change.apply_button',
      icon: SmacsIcons.COPY,
      buttonClass: ButtonStyles.PRIMARY,
      type: ButtonTypes.SUBMIT,
      state: {
        pending: false,
        buttonDisableState: { disabled: false, tooltipKey: '' },
        tooltipVisible: false,
      },
      submitSubject: this._validateAndSubmitSource,
    },
  ];

  private static _retrieveFields(serviceSettings: ServiceSetting): any[] {
    return _.flatMap(
      _.pick(serviceSettings, [
        'customCheckboxes',
        'customInputTexts',
        'customMultiSelects',
        'customSelects',
        'multiSelects',
        'selects',
        'checkboxes',
      ])
    );
  }

  ngOnInit(): void {
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));
    this._initForm();
    this.entitySource.next({
      siteName: '',
      fieldName: '',
      serviceName: '',
      copyDestination: CopyDestination.ALL_SITES,
      selectedSites: [],
    } as BulkFieldCopyForm);

    const smacsFormUpdateSub = this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<BulkFieldCopyForm>) => {
      if (data.new.siteName && data.new.siteName !== data.old?.siteName) {
        this.isLoadingSections = true;
        this.entitySource.next({
          siteName: data.new.siteName,
          fieldName: '',
          serviceName: '',
          copyDestination: CopyDestination.ALL_SITES,
          selectedSites: [],
        } as BulkFieldCopyForm);
        const siteResult = this.sites.find((s) => s.name === this.formData.siteName);
        this.siteResource.get(this.clusterId, siteResult.id).subscribe((site) => {
          this.isLoadingSections = false;
          this.selectedSite = site;
          const serviceNameComponent = this.fieldComponents.find((field) => field.fieldId === 'serviceName');
          serviceNameComponent.applyComponentConfig(new SmacsSelectConfig({ options: this._getSiteSections() }));
          Object.keys(this.fieldChannels).forEach((key) => {
            this.fieldChannels[key].validateSource.next();
          });
        });
      } else if (data.new.serviceName && data.new.serviceName !== data.old?.serviceName) {
        this.entitySource.next({
          ...data.new,
          siteName: data.new.siteName,
          fieldName: '',
          serviceName: data.new.serviceName,
        } as BulkFieldCopyForm);
        this.selectedSection = this.formData.serviceName;
        const fieldNameComponent = this.fieldComponents.find((field) => field.fieldId === 'fieldName');
        fieldNameComponent.applyComponentConfig(
          new SmacsSelectConfig({ options: this._getFieldsFromSection(this.formData.serviceName).map((s) => s.name) })
        );
      } else if (
        data.new.copyDestination &&
        data.new.copyDestination !== data.old?.copyDestination &&
        data.new.copyDestination === CopyDestination.ALL_SITES
      ) {
        const selectedSitesComponent = this.fieldComponents.find((field) => field.fieldId === 'selectedSites');
        selectedSitesComponent.updateSelf([]);
      } else {
        this._setSectionValues();
      }
    });
    this._subscriptions.add(smacsFormUpdateSub);
  }

  protected submit() {
    return this._openPromptModal();
  }

  private _getModalMessage(): string {
    if (this.isAllSitesSelected()) {
      const warning = this.translateService.instant('tkey;admin.site_management.bulk_change.copy.warning', {
        cluster: this.currentClusterName,
      });
      return warning + ' ' + `</br>` + this.translateService.instant('tkey;change.site.confirm_site_change.footer');
    } else if (!this.isAllSitesSelected()) {
      const warning = this.translateService.instant(
        'tkey;admin.site_management.bulk_change.copy.warning.subset.sites',
        { sites: this.entity.selectedSites.map((value) => value.label).join(', ') }
      );
      return warning + ' ' + `</br>` + this.translateService.instant('tkey;change.site.confirm_site_change.footer');
    }
  }

  private _initForm(): void {
    this.formConfig = {
      fields: {
        siteName: {
          label: 'tkey;admin.site_management.site.site',
          dataAutomation: 'bulk-field-copy-site',
          componentConfig: new SmacsSelectConfig({ options: this.sites.map((site) => site.name) }),
          required: true,
        },
        serviceName: {
          label: 'tkey;admin.site_management.cluster.bulk_change.section',
          dataAutomation: 'bulk-field-copy-section',
          componentConfig: new SmacsSelectConfig(),
          hidden: () => !this.formData.siteName || this.isLoadingSections,
          required: true,
        },
        fieldName: {
          label: 'tkey;admin.site_management.cluster.bulk_change.field',
          dataAutomation: 'bulk-field-copy-field',
          componentConfig: new SmacsSelectConfig(),
          hidden: () => !this.formData.serviceName,
          required: true,
        },
        copyDestination: {
          label: 'tkey;admin.site_management.bulk_change.copy.field.settings.destination',
          dataAutomation: 'bulk-field-copy-field-settings-destination',
          hidden: () => !this.formData.fieldName,
          required: true,
          componentConfig: new SmacsRadioConfig<CopyDestination>({
            buttons: [
              {
                value: CopyDestination.ALL_SITES,
                label: 'tkey;admin.site_management.bulk_change.copy.field.settings.cluster.option',
                labelParam: this.currentClusterName,
              },
              {
                value: CopyDestination.SUBSET_SITES,
                label: 'tkey;admin.site_management.bulk_change.copy.field.settings.subset_sites.option',
              },
            ],
            inline: true,
          }),
        },
        selectedSites: {
          label: 'tkey;admin.site_management.bulk_change.copy.field.settings.subset_sites.label',
          dataAutomation: 'bulk-field-copy-field-settings-selected-sites',
          componentConfig: new SmacsSelectConfig({ isMultiSelect: true }),
          required: () => this.formData.copyDestination !== CopyDestination.ALL_SITES,
          hidden: () => this.formData.copyDestination === CopyDestination.ALL_SITES,
        },
      },
    } as SmacsFormConfig;
  }

  private _setSectionValues(): void {
    if (this.formData.fieldName) {
      this.selectedField = this.formData.fieldName;
      const fieldValues = this._getFieldsFromSection(this.formData.serviceName).find(
        (f) => f.name === this.formData.fieldName
      );
      this.selectedFieldData = new Map(Object.entries(_.pick(fieldValues, DisplaySort)));
      const selectedSitesComponent = this.fieldComponents.find((field) => field.fieldId === 'selectedSites');
      if (this.formData.copyDestination === CopyDestination.SUBSET_SITES) {
        selectedSitesComponent.applyComponentConfig(
          new SmacsSelectConfig({
            options: this.sites
              .map<SmacsSelectOption>((site) => ({ label: site.name, value: site.id }))
              .filter((site) => site.label !== this.selectedSite.name),
            isMultiSelect: true,
          })
        );
      } else {
        const copyDestinationComponent = this.fieldComponents.find((field) => field.fieldId === 'copyDestination');
        copyDestinationComponent.applyComponentConfig(
          new SmacsRadioConfig<CopyDestination>({
            buttons: [
              {
                value: CopyDestination.ALL_SITES,
                label: 'tkey;admin.site_management.bulk_change.copy.field.settings.cluster.option',
                labelParam: this.currentClusterName,
              },
              {
                value: CopyDestination.SUBSET_SITES,
                label: 'tkey;admin.site_management.bulk_change.copy.field.settings.subset_sites.option',
              },
            ],
            inline: true,
          })
        );
        this.entity.selectedSites = this.formData.selectedSites = [];
      }
    }
  }

  private _getSiteSections(): string[] {
    const filteredSites = this.selectedSite.serviceSettings.filter(function (sections) {
      if (SiteBulkFieldCopyFormComponent._retrieveFields(sections).length > 0) {
        return sections.name;
      }
    });
    return filteredSites.map((sections) => sections.name).sort();
  }

  private _getFieldsFromSection(section: string): ServiceSetting[] {
    const serviceSettings = this.selectedSite.serviceSettings.find((s) => s.name === section);
    return SiteBulkFieldCopyFormComponent._retrieveFields(serviceSettings);
  }

  private _openPromptModal(): Observable<void> {
    const options = {
      modalViewProperties: {
        title: 'tkey;admin.site_management.bulk_change.button',
        icon: SmacsIcons.COPY,
        buttonClass: ButtonStyles.INFO,
        promptBody: this._getModalMessage(),
        selectedSite: this.selectedSite,
        selectedSection: this.selectedSection,
        selectedField: this.selectedField,
        clusterId: this.clusterId,
        selectedSites: this.entity.selectedSites.map((value) => value.label),
        currentClusterName: this.currentClusterName,
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'prompt-modal-cancel',
          },
          {
            label: 'tkey;admin.site_management.bulk_change.apply_button',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'prompt-modal-confirm',
            cb: () => this.onApplyChangeClicked(),
          },
        ],
      },
    };

    this.modalService.openPromptModal(() => options.modalViewProperties, options);

    return of(null);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };

  isAllSitesSelected(): boolean {
    return this.entity.copyDestination === CopyDestination.ALL_SITES;
  }

  onApplyChangeClicked(): Observable<void> {
    const requestBody: BulkFieldCopyRequest = {
      siteId: Number(this.selectedSite.id),
      serviceName: this.selectedSection,
      fieldName: this.selectedField,
      destinationSites: this.entity.selectedSites.map((option) => option.value),
    };
    return this.siteResource.bulkFieldCopy(this.clusterId, requestBody).pipe(
      map(() => {
        this.smacsFormStateService.setIsFormDirty(false);
        this.toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.SITE,
          'tkey;admin.site_management.bulk_change.toast.success',
          `${this.selectedSite.name} / ${this.selectedSection} / ${this.selectedField}`
        );
        this.router.navigate(['..'], { relativeTo: this.route });
      })
    );
  }
}
