<div *ngIf="!isLoading">
  <smacs-fieldset-header [legend]="'Teams Compliance Recording'">
    <smacs-select [fieldId]="'complianceRecordingPolicy'"></smacs-select>
  </smacs-fieldset-header>
  <smacs-fieldset-header [legend]="'Dubber User Settings'">
    <smacs-text [fieldId]="'email'"></smacs-text>
    <smacs-text [fieldId]="'firstName'"></smacs-text>
    <smacs-text [fieldId]="'lastName'"></smacs-text>
    <smacs-text [fieldId]="'mobileNumber'"></smacs-text>
    <smacs-select [fieldId]="'region'"></smacs-select>
    <smacs-select [fieldId]="'product'"></smacs-select>
  </smacs-fieldset-header>
</div>
