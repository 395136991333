<div class="modal-header modal-header--bordered d-flex align-items-center justify-content-between">
  <h5 class="mb-0" data-automation="detailed-modal-title">
    {{ 'tkey;site_management.site.edit_custom_input_value.title' | translate }}
  </h5>
  <button
    type="button"
    class="btn-close"
    aria-label="Close"
    data-automation="detailed-modal-close-button"
    (click)="onCancelClick()"
  ></button>
</div>

<div class="modal-body">
  <form [formGroup]="variableEditorInputForm">
    <div class="text-info">
      <i class="{{ smacsIcons.PREVIEW }}"></i>
      <strong>{{ 'tkey;site_management.site.edit_custom_input_value.preview.text' | translate }}</strong>
      -
      <span [attr.data-automation]="'preview-value'">
        <i>
          {{ variableEditorInputForm.get('value').value | customInputPreviewFilter }}
        </i>
      </span>
    </div>

    <div class="mb-3 row">
      <div class="col-md-12">
        <input [attr.data-automation]="'custom-input-text'" class="form-control" type="text" formControlName="value" />
      </div>
    </div>
  </form>

  <div class="mt-1">
    <div class="row">
      <div class="col-md-12">
        <div class="form-horizontal">
          <div class="mb-3 row">
            <label class="col-md-3 col-form-label">
              {{ 'tkey;site_management.site.edit_custom_input_value.variable.text' | translate }}
            </label>
            <div class="col-md-9">
              <ng-select
                [attr.data-automation]="'variable-select'"
                [items]="variableOptions"
                [(ngModel)]="selectedVariable"
                [ngModelOptions]="{ standalone: true }"
                [bindLabel]="'label'"
                (ngModelChange)="onChange()"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="ng-option-label" [innerHTML]="addPreviewVariableOptions(item)"></div>
                </ng-template>
              </ng-select>
            </div>
          </div>

          <div class="mb-3 row" *ngIf="!!selectedVariable && selectedVariable.value">
            <label class="col-md-3 col-form-label">
              {{ 'tkey;site_management.site.edit_custom_input_value.expression_one.text' | translate }}
            </label>
            <div class="col-md-9">
              <ng-select
                [attr.data-automation]="'expression-one'"
                [items]="functionOneOptions"
                [(ngModel)]="functionOne"
                [ngModelOptions]="{ standalone: true }"
                [bindLabel]="'label'"
                (ngModelChange)="onFunctionOneChange($event)"
              ></ng-select>
            </div>
          </div>

          <div class="mb-3 row" *ngIf="!!functionOne && functionOne.value">
            <label class="col-md-3 col-form-label">
              {{ 'tkey;site_management.site.edit_custom_input_value.expression_two.text' | translate }}
            </label>
            <div class="col-md-9">
              <ng-template #tooltipTemplate>
                <span
                  [innerHTML]="
                    'tkey;shared.custom_input_text.expression_editor.apply_international.disabled.tooltip' | translate
                  "
                ></span>
              </ng-template>
              <ng-select
                [attr.data-automation]="'expression-two'"
                [items]="functionTwoOptions"
                [(ngModel)]="functionTwo"
                [ngModelOptions]="{ standalone: true }"
                [bindLabel]="'label'"
                [placeholder]="
                  'tkey;site_management.site.edit_custom_input_value.expression_two_apply.text' | translate
                "
                (ngModelChange)="onFunctionTwoChange($event)"
                [disabled]="functionOne.value === 'toInternationalNumberFormat'"
                [ngbTooltip]="tooltipTemplate"
                [disableTooltip]="functionOne.value !== 'toInternationalNumberFormat'"
              ></ng-select>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-end">
              <span
                class="expression-preview"
                [hidden]="!state.isExpressionPreviewVisible"
                [attr.data-automation]="'custom-input-expression-preview'"
              >
                {{ 'tkey;site_management.site.edit_custom_input_value.preview.text' | translate }}:
                {{ state.expressionPreview }}
              </span>
              <smacs-button
                [label]="'tkey;site_management.site.edit_custom_input_value.add.text'"
                [isDisabled]="state.isAddDisabled"
                [buttonStyle]="buttonStyles.INFO"
                [dataAutomation]="'custom_input_add'"
                (clicked)="onAddClick()"
              ></smacs-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer modal-footer--bordered justify-content-end">
  <smacs-button
    [dataAutomation]="'custom-input-text-modal-cancel'"
    [label]="'tkey;global.button.cancel.text'"
    [buttonStyle]="buttonStyles.DEFAULT"
    [buttonSize]="buttonSizes.LARGE"
    (clicked)="onCancelClick()"
  ></smacs-button>

  <smacs-button
    [dataAutomation]="'custom-input-text-modal-save'"
    [label]="'tkey;site_management.site.edit_custom_input_value.title'"
    [buttonSize]="ButtonSizes.LARGE"
    (clicked)="onConfirmClick()"
  ></smacs-button>
</div>
