<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 smacs-datatable-content">
    <app-breadcrumbs>
      <app-breadcrumb-card-item [aboutCardId]="35"></app-breadcrumb-card-item>
    </app-breadcrumbs>

    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <ng-container *ngIf="!isLoading">
      <smacs-datatable
        [data]="rows"
        [columns]="columns"
        [mainFilterPlaceholder]="'tkey;admin.billing.sbc_numbers_config.datatable.filter.placeholder' | translate"
        [dataAutomation]="tableDataAutomation"
        [noResultsAlert]="!rows.length ? noResultsAlert : null"
        [noResultsAlertClass]="noResultsAlert ? 'table-success' : null"
      >
        <smacs-datatable-multi-select-filter
          [label]="'tkey;admin.billing.sbc_numbers_config.datatable.number_misconfig_type.label'"
          [dataAutomation]="'number-misconfig-type-filter'"
          [items]="numberMisconfgTypeFilterOptions"
          [matchFn]="isNumberMisconfigTypeMatch"
          [tableId]="tableDataAutomation"
          [initialSelectedItems]="initialSelectedFilters"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;admin.billing.sbc_numbers_config.datatable.dial_plan_group_name.label'"
          [dataAutomation]="'dial-plan-filter'"
          [items]="dialPlanFilterOptions"
          [matchFn]="isDialPlanMatch"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;admin.billing.sbc_numbers_config.datatable.carrier.label'"
          [dataAutomation]="'carrier-filter'"
          [items]="carrierFilterOptions"
          [matchFn]="isCarrierMatch"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
      </smacs-datatable>
    </ng-container>
  </div>
</div>
