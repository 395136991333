import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PrePortedNumberStatus } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class OrderNumberStatusResource {
  private _url = '/services/microsoft/orders/number-statuses';

  constructor(private _http: HttpClient) {}

  get(): Observable<PrePortedNumberStatus[]> {
    return this._http.get<PrePortedNumberStatus[]>(this._url);
  }
}
