import { Injectable } from '@angular/core';

export const NEVER_USED_VALUE = -1;

@Injectable({
  providedIn: 'root',
})
export class DatatableFilterService {
  public filterTimePeriod(filter: number, columnValue: any) {
    const lastUsed = columnValue.match(/([\d]+)/);
    if (!columnValue.includes('month')) {
      if (columnValue === 'Never') {
        return filter == null || filter === NEVER_USED_VALUE;
      } else if (columnValue.includes('days ago') || columnValue.includes('day ago')) {
        return filter == null || Number(lastUsed[0]) <= filter;
      } else if (columnValue.includes('Today') || columnValue.includes('hours')) {
        return filter == null || filter >= 0;
      } else if (columnValue.includes('year')) {
        return filter == null || filter >= Number(lastUsed[0]) * 365;
      }
    } else if (columnValue.includes('month')) {
      if (lastUsed) {
        if (lastUsed[0]) {
          return filter == null || filter >= Number(lastUsed[0]) * 30;
        }
      } else if (columnValue.startsWith('a month')) {
        return filter == null || filter >= 30;
      }
    }
    return !filter;
  }

  filterMultiSelectValues(filterByValues: string[], columnValue: any): boolean {
    return !filterByValues.length || filterByValues.includes(String(columnValue));
  }

  getTextFromHtml(html: string): string {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent.trim() || element.innerText.trim() || '';
  }
}
