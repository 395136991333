import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PhoneRef } from '../../../../shared/models/generated/smacsModels';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ToastService } from '../../../../shared/services/toast.service';

@Component({
  selector: 'smacs-public-phone-container',
  templateUrl: './public-phone-container.component.html',
})
export class PublicPhoneContainerComponent {
  constructor(private route: ActivatedRoute, private router: Router, private toastService: ToastService) {}

  onSave(ref: PhoneRef) {
    this.toastService.pushSaveToast('tkey;shared.model.deskphone.text', ref.name, SmacsIcons.DESKPHONE);
    this.router.navigateByUrl(`public-phone/${ref.name}`);
  }

  goHome() {
    this.router.navigateByUrl('home');
  }
}
