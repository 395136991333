import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { Component } from '@angular/core';
import { SmacsFieldComponentConfig } from '../../smacs-forms-models';
import { isNil } from 'lodash';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';

export class SmacsCheckboxButtonConfig extends SmacsFieldComponentConfig {
  constructor(
    public config?: {
      size?: ButtonSizes;
      icon?: SmacsIcons;
      disabled?: boolean;
      disabledTooltip?: string;
      noTextWrap?: boolean;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-checkbox-button-field',
  templateUrl: './checkbox-button-field.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsCheckboxButtonFieldComponent }],
})
export class SmacsCheckboxButtonFieldComponent extends SmacsFieldAbstractDirective<
  boolean,
  boolean,
  SmacsCheckboxButtonConfig
> {
  ButtonStyles = ButtonStyles;
  size = ButtonSizes.NORMAL;
  icon = SmacsIcons.NONE;
  disabled: boolean;
  disabledTooltip = '';
  noTextWrap = false;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  applyComponentConfig = ({ config }: SmacsCheckboxButtonConfig) => {
    this.size = isNil(config?.size) ? this.size : config.size;
    this.icon = isNil(config?.icon) ? this.icon : config.icon;
    this.disabled = isNil(config?.disabled) ? this.disabled : config.disabled;
    this.disabledTooltip = isNil(config.disabledTooltip) ? this.disabledTooltip : config.disabledTooltip;
    this.noTextWrap = isNil(config.noTextWrap) ? this.noTextWrap : config.noTextWrap;
  };

  onChange($event: boolean) {
    this.updateSelf($event, true);
  }

  // As stupid as this seems, it address an issue with the checkbox being initialized with a null value.
  toFieldData = (entity: boolean): boolean => !!entity;
}
