import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { Observable, Subscriber, Subscription } from 'rxjs';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { EmailSettings, EmailTransportStrategy } from '../../../shared/models/generated/smacsModels';
import { SmacsSelectConfig } from '../../../forms/fields/select/smacs-select.component';
import { HtmlInputType, SmacsTextComponent, SmacsTextConfig } from '../../../forms/fields/text/smacs-text.component';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { ToastService } from '../../../shared/services/toast.service';
import { AdminEmailConfigurationModalComponent } from './admin-email-configuration-modal.component';
import { SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { EmailSettingsContext } from '../../../shared/contexts/email-settings.context';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';

@Component({
  selector: 'app-admin-email-configuration',
  templateUrl: './admin-email-configuration.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class AdminEmailConfigurationComponent
  extends SmacsFormAbstractDirective<EmailSettings>
  implements OnInit, OnDestroy
{
  smacsIcons = SmacsIcons;
  isLoading = false;

  private _subscriptions = new Subscription();

  formConfig = {
    fields: {
      host: {
        label: 'tkey;admin.email_configuration.smtp_host.label',
        dataAutomation: 'email-config-host',
        required: true,
      },
      port: {
        label: 'tkey;admin.email_configuration.smtp_port.label',
        dataAutomation: 'email-config-port',
        required: true,
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.NUMBER, placeholder: 'Ex: 25, 465, 587' }),
        validation: [
          {
            validator: (val: number) =>
              val >= 1 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: () =>
              this.translateService.instant('tkey;validators.global.error.min', {
                minValue: 1,
              }),
          },
          {
            validator: (val: number) =>
              val <= 65352 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: () =>
              this.translateService.instant('tkey;validators.global.error.max', {
                maxValue: 65352,
              }),
          },
        ],
      },
      transportStrategy: {
        label: 'tkey;admin.email_configuration.smtp_security.label',
        dataAutomation: 'email-config-transport-strategy',
        required: true,
        componentConfig: new SmacsSelectConfig({
          options: ['SMTP', 'SMTPS', 'TLS'],
        }),
      },
      username: {
        label: 'tkey;admin.email_configuration.smtp_username.label',
        dataAutomation: 'email-config-username',
        required: () => {
          return !!this.formData.password;
        },
      },
      password: {
        label: 'tkey;admin.email_configuration.smtp_password.label',
        dataAutomation: 'email-config-password',
        required: () => !this.isExisting && !!this.formData.username,
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
      },
      from: {
        label: 'tkey;admin.email_configuration.smtp_from.label',
        dataAutomation: 'email-config-from',
        required: true,
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'it@yourcompany.com',
        }),
        validation: [
          {
            validator: (val: string) =>
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                val
              )
                ? SmacsFormsValidationState.VALID
                : SmacsFormsValidationState.INVALID,
            message: 'tkey;admin.email_configuration.modal.email.validator.message',
          },
        ],
      },
    },
  };

  constructor(
    private bottomNavService: BottomNavService,
    private smacsModalService: SmacsModalService,
    private toastService: ToastService,
    private emailSettingsContext: EmailSettingsContext,
    protected smacsFormStateService: SmacsFormStateService,
    private translateService: TranslateService,
    private breadcrumbsService: BreadcrumbsService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.email_configuration.title' }]);
    this._initBottomNav();

    const formValidateSub = this._validateAndSubmitSource.subscribe((submit: boolean) => {
      if (submit && !this.isFormValid()) {
        this._setBottomNavValidation(true);
      }
    });
    this._subscriptions.add(formValidateSub);

    const contextSub = this.emailSettingsContext.state$.subscribe((data: EmailSettings) => {
      this.entitySource.next(data);
      this.setIsExisting(!!data.host);
      this._setDeleteButtonState(!this.isExisting);
    });

    this._subscriptions.add(contextSub);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }

  protected submit() {
    return this._onFormSubmit();
  }

  private _initBottomNav(): void {
    const bottomNavButtons = [
      {
        id: 'email-config-delete-button',
        label: 'tkey;dialogs.button.delete',
        buttonClass: ButtonStyles.DANGER,
        dataAutomation: 'email-config-delete-button',
        state: {
          pending: false,
          buttonDisableState: {
            disabled: false,
            tooltipKey: '',
          },
        },
        icon: this.smacsIcons.DELETE,
        cb: () => this._onDeleteClick(),
      },
      {
        id: 'email-config-save-button',
        dataAutomation: 'email-config-save-button',
        label: 'tkey;admin.email_configuration.save.label',
        icon: this.smacsIcons.OK,
        buttonClass: ButtonStyles.PRIMARY,
        state: {
          pending: false,
          buttonDisableState: { disabled: false, tooltipKey: '' },
          tooltipVisible: false,
        },
        type: ButtonTypes.SUBMIT,
        submitSubject: this._validateAndSubmitSource,
      },
    ] as BottomNavButton[];
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _onFormSubmit(): Observable<void> {
    if (this.entity.username === '') {
      this.entity.username = null;
    }
    if (this.entity.password === '') {
      this.entity.password = null;
    }
    const options = {
      animation: false,
      modalViewProperties: {
        title: 'tkey;admin.email_configuration.modal.title',
        promptBody: 'tkey;admin.email_configuration.modal.message',
        icon: this.smacsIcons.INBOX,
        iconClass: 'text-muted',
        displayCloseButton: true,
        emailSettings: this.entity,
        buttons: [
          {
            label: 'tkey;global.button.cancel.text',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'email-config-modal-cancel-btn',
          },
          {
            label: 'tkey;admin.email_configuration.save.label',
            buttonClass: ButtonStyles.PRIMARY,
            dataAutomation: 'email-config-modal-test-save-btn',
            buttonIcon: this.smacsIcons.OK,
            isSubmitButton: true,
          },
        ],
      },
      bodyClass: AdminEmailConfigurationModalComponent,
    };

    return new Observable((subscriber: Subscriber<void>) => {
      this._setBottomNavValidation(false);
      this.smacsModalService
        .openPromptModal(() => options.modalViewProperties, options)
        .subscribe((testPass: boolean) => {
          this._validateAndSubmitSource.next(false);
          this._setBottomNavValidation(!testPass);
          if (testPass) {
            this.entitySource.next({
              ...this.entity,
            });
            this.setIsExisting(true);
            const field = this.fieldComponents.find((f) => f.fieldId === 'password');
            field.applyComponentConfig(new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }));
            this._setDeleteButtonState(false);
          }
        });
    });
  }

  private _setBottomNavValidation(showError: boolean): void {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: showError,
      })
    );
  }

  private _onDeleteClick() {
    const options = {
      animation: false,
      modalViewProperties: {
        promptBody: this.translateService.instant('tkey;admin.email_configuration.delete.modal.message'),
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteEmailSettings();
            },
          },
        ],
      },
    };
    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteEmailSettings(): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.emailSettingsContext.delete().subscribe(() => {
        this._resetFormState();
        this.toastService.push(
          ToastTypes.INFO,
          `${this.smacsIcons.DELETE} text-danger`,
          'tkey;shared.toast.delete.success.title',
          `tkey;admin.email_configuration.toast.delete.message`
        );
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private _resetFormState() {
    this.setIsExisting(false);
    this.entitySource.next({
      from: '',
      host: '',
      password: '',
      port: 25,
      transportStrategy: EmailTransportStrategy.SMTP,
      username: '',
    });
    this.smacsFormStateService.setIsFormDirty(false);
    this.isFormSubmitted = false;
    this.fieldComponents.forEach((item: SmacsTextComponent) => {
      item.isDirty = false;
      item.showValidation = false;
      item.isFormSubmitted = this.isFormSubmitted;

      if (item.fieldId === 'password') {
        item.showMaskedInput = false;
        item.applyComponentConfig(new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }));
      }
    });
    this._setDeleteButtonState(true);
  }

  private _setDeleteButtonState(isDisabled: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'email-config-delete-button',
        state: {
          pending: false,
          buttonDisableState: {
            disabled: isDisabled,
          },
        },
      })
    );
  }
}
