import { NgModule } from '@angular/core';
import { BreadcrumbsModule } from '../../shared/breadcrumbs/breadcrumbs.module';
import { BottomNavModule } from '../../shared/bottom-nav/bottom-nav.module';
import { LoadingBlockModule } from '../../shared/loading-block/loading-block.module';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { BillingProductsSummaryViewComponent } from './billing-products/summary-view/billing-products-summary-view.component';
import { BillingProductsDetailedViewComponent } from './billing-products/detailed-view/billing-products-detailed-view.component';
import { DetailedZiroCallingReportsContext } from './billing-products/services/detailed-ziro-calling-reports.context';
import { EntityTableModule } from '../../shared/entity-table/entity-table.module';
import { ReportingModule } from '../../reporting/reporting.module';
import { BillingProductsPollingService } from './billing-products/services/billing-products-polling.service';
import { DatatableModule } from '../../reporting/datatable/datatable.module';
import { DlrMisconfigurationsComponent } from '../microsoft/ziro-support-settings/dlr-misconfigurations/dlr-misconfigurations.component';
import { DlrMisconfigurationsReportContext } from '../microsoft/ziro-support-settings/dlr-misconfigurations/services/dlr-misconfigurations-report.context';
import { DlrMisconfigurationsPollingService } from '../microsoft/ziro-support-settings/dlr-misconfigurations/services/dlr-misconfigurations-polling.service';
import { DlrMisconfigurationsDatatableComponent } from '../microsoft/ziro-support-settings/dlr-misconfigurations/dlr-misconfigurations-datatable.component';
import { SbcNumberConfigurationsComponent } from '../microsoft/ziro-support-settings/sbc-number-configurations/sbc-number-configurations.component';
import { SbcNumberConfigurationsContext } from '../microsoft/ziro-support-settings/sbc-number-configurations/sbc-number-configurations-context.service';

@NgModule({
  imports: [
    BreadcrumbsModule,
    BottomNavModule,
    LoadingBlockModule,
    TranslateModule,
    CommonModule,
    RouterModule,
    EntityTableModule,
    ReportingModule,
    DatatableModule,
  ],
  declarations: [
    BillingProductsSummaryViewComponent,
    BillingProductsDetailedViewComponent,
    DlrMisconfigurationsComponent,
    DlrMisconfigurationsDatatableComponent,
    SbcNumberConfigurationsComponent,
  ],
  exports: [
    BillingProductsSummaryViewComponent,
    BillingProductsDetailedViewComponent,
    DlrMisconfigurationsComponent,
    SbcNumberConfigurationsComponent,
  ],
  providers: [
    DetailedZiroCallingReportsContext,
    BillingProductsPollingService,
    DlrMisconfigurationsReportContext,
    DlrMisconfigurationsPollingService,
    SbcNumberConfigurationsContext,
  ],
})
export class BillingModule {}
