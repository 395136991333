import { Component, Input, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { of } from 'rxjs';
import { CustomInputText, LdapPublicConfiguration } from '../../../../../shared/models/generated/smacsModels';
import { SmacsTextFieldConfig } from '../../../../../shared/custom-configs/legacy-smacs-text-config/legacy-smacs-text-config.component';
import { TextFieldConfigUi } from '../dial-plan-management-edit.component';
import { SmacsFormConfig } from '../../../../../forms/smacs-forms-models';

export interface CiscoWriteToLdapFormEntity {
  e164_ldap: CustomInputText;
  extension_ldap: CustomInputText;
}

export interface CiscoWriteToLdapFormData {
  e164_ldap: TextFieldConfigUi;
  extension_ldap: TextFieldConfigUi;
}

@Component({
  selector: 'app-cisco-dial-plan-management-write-to-ldap-form',
  templateUrl: './dial-plan-management-write-to-ldap-form.component.html',
})
export class CiscoDialPlanManagementWriteToLdapFormComponent
  extends SmacsFormAbstractDirective<CiscoWriteToLdapFormEntity | CiscoWriteToLdapFormData>
  implements OnInit
{
  @Input() ldapConfiguration: LdapPublicConfiguration;

  formConfig: SmacsFormConfig;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.formConfig = {
      fields: {
        e164_ldap: {
          label: 'tkey;dialplanmanagement.admin.group.writeldap.e164',
          dataAutomation: 'write-to-ldap-e164',
          componentConfig: new SmacsTextFieldConfig({ displayEditor: true }),
          disabled: () => !this.ldapConfiguration.e164NumberAttributeName,
        },
        extension_ldap: {
          label: 'tkey;dialplanmanagement.admin.group.writeldap.extension',
          dataAutomation: 'write-to-ldap-extension',
          componentConfig: new SmacsTextFieldConfig({ displayEditor: true }),
          disabled: () => !this.ldapConfiguration.extensionAttributeName,
        },
      },
    };
  }

  submit() {
    return of(null);
  }

  toFormData = (entity: CiscoWriteToLdapFormEntity): CiscoWriteToLdapFormData => {
    return {
      e164_ldap: {
        name: entity.e164_ldap?.name,
        defaultValue: entity.e164_ldap?.value,
        required: entity.e164_ldap?.required,
        show: entity.e164_ldap?.show,
      },
      extension_ldap: {
        name: entity.extension_ldap?.name,
        defaultValue: entity.extension_ldap?.value,
        required: entity.extension_ldap?.required,
        show: entity.extension_ldap?.show,
      },
    } as CiscoWriteToLdapFormData;
  };

  toEntity = (formData: CiscoWriteToLdapFormData): CiscoWriteToLdapFormEntity => {
    return {
      e164_ldap: {
        name: formData.e164_ldap.name,
        required: formData.e164_ldap.required,
        show: formData.e164_ldap.show,
        value: formData.e164_ldap.defaultValue,
      },
      extension_ldap: {
        name: formData.extension_ldap.name,
        required: formData.extension_ldap.required,
        show: formData.extension_ldap.show,
        value: formData.extension_ldap.defaultValue,
      },
    } as CiscoWriteToLdapFormEntity;
  };
}
