import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SmacsFormsModule } from '../forms/smacs-forms.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { LoginComponent } from './login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SamlSsoConfigContext } from '../admin/contexts/saml-sso-config.context';
import { ButtonModule } from '../button/button.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LoadingBlockModule } from '../shared/loading-block/loading-block.module';
import { FooterModule } from '../shared/footer/footer.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    SharedModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    SmacsFormsModule,
    NgSelectModule,
    ButtonModule,
    AngularSvgIconModule,
    LoadingBlockModule,
    FooterModule,
  ],
  declarations: [LoginComponent],
  exports: [LoginComponent],
  providers: [SamlSsoConfigContext],
})
export class LoginModule {}
