import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';

import { ZeroTouchComponent } from './zero-touch.component';
import { DeprovisioningJobsViewComponent } from './cisco/deprovisioning/jobs-view/deprovisioning-jobs-view.component';
import { DeprovisioningEditJobComponent } from './cisco/deprovisioning/edit-job/deprovisioning-edit-job.component';
import { ProvisioningJobsViewComponent } from './cisco/provisioning/jobs-view/provisioning-jobs-view.component';
import { ProvisioningEditJobComponent } from './cisco/provisioning/edit-job/provisioning-edit-job.component';
import { DropdownModule } from 'primeng/dropdown';
import { ZeroTouchPageComponent } from './shared/page/zero-touch-page.component';
import { DialPlanGroupIdToNameFilter } from '../shared/filters/dial-plan-group-id-to-name.filter';
import { ProvisioningEditJobIgnoredUsersComponent } from './cisco/provisioning/edit-job/tabs/ignored-users/provisioning-edit-job-ignored-users.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { BulkProvisioningComponent } from './cisco/bulk-provisioning/bulk-provisioning.component';
import { BulkProvisioningJobGeneralComponent } from './cisco/bulk-provisioning/job-general/bulk-provisioning-job-general.component';
import { BulkProvisioningUploadCsvComponent } from './cisco/bulk-provisioning/upload-csv/bulk-provisioning-upload-csv.component';
import { AboutRestComponent } from './about-rest/about-rest.component';
import { LdapSyncComponent } from './cisco/ldap-sync/ldap-sync.component';
import { AutomaticSyncFormComponent } from './cisco/ldap-sync/automatic-sync-form/automatic-sync-form.component';
import { LdapSyncStatusFilter } from './cisco/ldap-sync/ldap-sync-status.filter';
import { SmacsFormsModule } from '../forms/smacs-forms.module';
import {
  ZeroTouchDeprovisioningResource,
  ZeroTouchProvisioningResource,
} from '../shared/resources/zpc-zero-touch.abstract.resource';
import { ZeroTouchSchedulingService } from './services/zero-touch-scheduling.service';
import { BulkEndUserProvisioningResource } from './resources/bulk-end-user-provisioning.resource';
import { LdapSyncResource } from './resources/ldap-sync.resource';
import { LdapSyncConfigResource } from './resources/ldap-sync-config.resource';
import { MsGraphConfigContext } from '../admin/contexts/ms-graph-config.context';
import { ButtonModule } from '../button/button.module';
import { ReportingModule } from '../reporting/reporting.module';
import { MicrosoftBulkProvisioningComponent } from './microsoft/bulk-provisioning/microsoft-bulk-provisioning.component';
import { MicrosoftBulkProvisioningFormComponent } from './microsoft/bulk-provisioning/form/microsoft-bulk-provisioning-form.component';
import { ZpmBulkProvisioningResource } from './resources/zpm-bulk-provisioning.resource';
import { ZiroMsProvisioningEditJobComponent } from './microsoft/provisioning/edit-job/ziro-ms-provisioning-edit-job.component';
import { ZiroMsProvisioningJobsListComponent } from './microsoft/provisioning/jobs-list/ziro-ms-provisioning-jobs-list.component';
import { LoadingBlockModule } from '../shared/loading-block/loading-block.module';
import { BreadcrumbsModule } from '../shared/breadcrumbs/breadcrumbs.module';
import { EntityTableModule } from '../shared/entity-table/entity-table.module';
import { BaseContainerModule } from '../shared/abstract/base-container/base-container.module';
import { BottomNavModule } from '../shared/bottom-nav/bottom-nav.module';
import { DatatableModule } from '../reporting/datatable/datatable.module';
import { FileUploaderModule } from '../shared/file-uploader/file-uploader.module';
import { ZpmBulkProvisioningPreviousJobComponent } from './microsoft/bulk-provisioning/previous-job/zpm-bulk-provisioning-previous-job.component';
import { ZpmBulkProvisioningStatusEntityTableComponent } from './microsoft/bulk-provisioning/status-table/zpm-bulk-provisioning-status-entity-table.component';
import { MicrosoftBulkProvisioningContainerComponent } from './microsoft/bulk-provisioning/microsoft-bulk-provisioning-container.component';
import { MicrosoftBulkProvisioningDraftsListComponent } from './microsoft/bulk-provisioning/drafts/bulk-provisioning-drafts-list.component';
import { MicrosoftBulkProvisioningInProgressComponent } from './microsoft/bulk-provisioning/in-progress/bulk-provisioning-in-progress.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    SharedModule,
    FormsModule,
    DropdownModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
    SmacsFormsModule,
    ButtonModule,
    ReportingModule,
    LoadingBlockModule,
    BreadcrumbsModule,
    EntityTableModule,
    BaseContainerModule,
    BottomNavModule,
    DatatableModule,
    FileUploaderModule,
  ],
  declarations: [
    ZeroTouchComponent,
    ZeroTouchPageComponent,

    LdapSyncComponent,
    AutomaticSyncFormComponent,
    LdapSyncStatusFilter,

    DeprovisioningJobsViewComponent,
    DeprovisioningEditJobComponent,

    ProvisioningJobsViewComponent,
    ProvisioningEditJobComponent,

    BulkProvisioningComponent,
    BulkProvisioningJobGeneralComponent,
    BulkProvisioningUploadCsvComponent,

    MicrosoftBulkProvisioningContainerComponent,
    MicrosoftBulkProvisioningComponent,
    MicrosoftBulkProvisioningFormComponent,
    ZpmBulkProvisioningPreviousJobComponent,
    ZpmBulkProvisioningStatusEntityTableComponent,
    MicrosoftBulkProvisioningDraftsListComponent,
    MicrosoftBulkProvisioningInProgressComponent,

    ProvisioningEditJobIgnoredUsersComponent,
    AboutRestComponent,
    DialPlanGroupIdToNameFilter,
    ZiroMsProvisioningEditJobComponent,
    ZiroMsProvisioningJobsListComponent,
  ],
  exports: [
    ZeroTouchComponent,
    ZeroTouchPageComponent,

    LdapSyncComponent,
    DeprovisioningJobsViewComponent,
    DeprovisioningEditJobComponent,

    ProvisioningJobsViewComponent,
    ProvisioningEditJobComponent,

    BulkProvisioningComponent,
    BulkProvisioningJobGeneralComponent,
    BulkProvisioningUploadCsvComponent,

    MicrosoftBulkProvisioningContainerComponent,
    MicrosoftBulkProvisioningComponent,
    MicrosoftBulkProvisioningFormComponent,
    ZpmBulkProvisioningPreviousJobComponent,
    ZpmBulkProvisioningStatusEntityTableComponent,
    MicrosoftBulkProvisioningDraftsListComponent,
    MicrosoftBulkProvisioningInProgressComponent,

    ProvisioningEditJobIgnoredUsersComponent,
    AboutRestComponent,

    ZiroMsProvisioningEditJobComponent,
    ZiroMsProvisioningJobsListComponent,
  ],
  providers: [
    ZeroTouchSchedulingService,
    BulkEndUserProvisioningResource,
    ZpmBulkProvisioningResource,
    LdapSyncResource,
    LdapSyncConfigResource,
    MsGraphConfigContext,
    ZeroTouchDeprovisioningResource,
    ZeroTouchProvisioningResource,
  ],
})
export class ZeroTouchModule {}
