import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DefaultVoicemailRequest, SmtpAddress } from '../models/generated/smacsModels';
import { Observable } from 'rxjs';
import { AuditHeaderService } from '../services/audit-header.service';
import { Nvp } from '../models/nvp';

@Injectable()
export class VoicemailSmtpNotificationsDeviceResource {
  constructor(protected http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  getDefaultValue(body: DefaultVoicemailRequest): Observable<SmtpAddress> {
    return this.http.post<SmtpAddress>('/services/cisco/defaults/voicemail/smtp-notification-devices', body);
  }

  put(serverId: number, voicemailId: string, body: { email: string }, auditTags: Nvp[]): Observable<SmtpAddress> {
    const auditTagHeader = this.auditHeaderService.buildHeader(auditTags);
    return this.http.put<SmtpAddress>(
      `/services/cisco/macs/unity-servers/${serverId}/voicemails/${voicemailId}/smtp-notification-devices`,
      body,
      {
        headers: auditTagHeader,
      }
    );
  }

  get(serverId: number, voicemailId: string): Observable<SmtpAddress> {
    return this.http.get<SmtpAddress>(
      `/services/cisco/macs/unity-servers/${serverId}/voicemails/${voicemailId}/smtp-notification-devices`
    );
  }
}
