import { Component, Input, OnInit } from '@angular/core';
import { WebexFieldConfig } from '../../../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { Observable } from 'rxjs';
import { DidSelectorFormData } from '../did-selector-form/did-selector-form.component';
import { UCM_LICENSE_NAME } from '../webex-calling.component';

export interface ExtensionDetailsEntity {
  extension: string;
}

@Component({
  selector: 'ziro-extension-details-form',
  templateUrl: './extension-details-form.component.html',
})
export class ZiroExtensionDetailsFormComponent
  extends SmacsFormAbstractDirective<ExtensionDetailsEntity>
  implements OnInit
{
  UCM_LICENSE_NAME = UCM_LICENSE_NAME;

  @Input() webexFieldConfig: WebexFieldConfig;
  @Input()
  set didSelectorFormData(newVal: DidSelectorFormData) {
    this._didSelectorFormData = newVal;
    this._setFieldStates();
  }
  get didSelectorFormData(): DidSelectorFormData {
    return this._didSelectorFormData;
  }
  private _didSelectorFormData: DidSelectorFormData;

  formConfig: SmacsFormConfig;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this._initFormConfig();
  }

  protected submit(): Observable<any> {
    return this.parentSubmit$;
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        extension: {
          label: 'tkey;helpdesk.webex_calling.extension_details.extension.label',
          dataAutomation: 'webex-calling-extension',
          required: () => this.webexFieldConfig.extension.required || !this.didSelectorFormData.did,
          defaultValue: () => this.webexFieldConfig.extension.defaultValue,
          hidden: () => !this.webexFieldConfig.extension.show,
          validation: [
            {
              validator: (val) =>
                !val || /^[0-9]{2,10}$/.test(val) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
              message: 'tkey;helpdesk.webex_calling.extension_details.validation.message',
            },
          ],
          valExcluded: () => this.didSelectorFormData?.webexCallingLicense === UCM_LICENSE_NAME,
        },
      },
    };
  }
}
