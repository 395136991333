<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.order_numbers.check_number_portability.about.description' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <ziro-check-number-portability-form
      *ngIf="!isLoading"
      [entity]="formEntity"
      (smacsFormsUpdate$)="onFormUpdate($event)"
      [existingPortInDraftNumbers]="existingPortInDraftNumbers"
    ></ziro-check-number-portability-form>
  </div>
</div>
<app-bottom-nav></app-bottom-nav>
