import { Injectable } from '@angular/core';
import { ServiceType } from '../models/service-type';
import { ServiceTypeIconFilter } from '../filters/service-type-icon.filter';
import { ServiceTypeNameFilter } from '../filters/service-type-name.filter';

@Injectable()
export class ServiceTypeMetadataService {
  serviceTypeMd: {
    [serviceType: string]: {
      icon: string;
      name: string;
      canHaveMultiple: boolean;
      canAddMultiple: boolean;
      isPhone: boolean;
      model: string;
    };
  };

  constructor(
    private serviceTypeIconFilter: ServiceTypeIconFilter,
    private serviceTypeNameFilter: ServiceTypeNameFilter
  ) {
    this.refreshReferenceMetadata();
  }

  getMetadataFor(serviceType: (typeof ServiceType)[keyof typeof ServiceType]) {
    const serviceMetadata = this.serviceTypeMd[serviceType];
    if (!serviceMetadata) {
      throw new Error(`No metadata found for service type: ${serviceType}`);
    }

    return serviceMetadata;
  }

  refreshReferenceMetadata(languageKey = 'EN') {
    this.serviceTypeNameFilter.refreshTranslations(languageKey);

    this.serviceTypeMd = {};

    this.addServiceTypeMd(ServiceType.PRIMARY_EXTENSION, false, false, false);
    this.addServiceTypeMd(ServiceType.UCCX_AGENT, false, false, false);
    this.addServiceTypeMd(ServiceType.VOICEMAIL, false, false, false);
    this.addServiceTypeMd(ServiceType.SNR, false, false, false);
    this.addServiceTypeMd(ServiceType.EXTENSION_MOBILITY, true, false, false);
    this.addServiceTypeMd(ServiceType.IM_PRESENCE, false, false, false);
    this.addServiceTypeMd(ServiceType.DESKPHONE, true, true, true);
    this.addServiceTypeMd(ServiceType.SOFTPHONE, true, false, true, 'Cisco Unified Client Services Framework');
    this.addServiceTypeMd(ServiceType.CIPC, true, false, true, 'Cisco IP Communicator');
    this.addServiceTypeMd(ServiceType.ANDROID, true, false, true, 'Cisco Dual Mode for Android');
    this.addServiceTypeMd(ServiceType.IPHONE, true, false, true, 'Cisco Dual Mode for iPhone');
    this.addServiceTypeMd(ServiceType.TABLET, true, false, true, 'Cisco Jabber for Tablet');
    this.addServiceTypeMd(ServiceType.CTI_PORT, true, false, true, 'CTI Port');
    this.addServiceTypeMd(ServiceType.CTI_REMOTE_DEVICE, true, false, true, 'CTI Remote Device');
    this.addServiceTypeMd(ServiceType.SPARK_REMOTE_DEVICE, true, false, true, 'Cisco Spark Remote Device');
    this.addServiceTypeMd(ServiceType.MICROSOFT_365, false, false, false);
    this.addServiceTypeMd(ServiceType.MICROSOFT_TEAMS_CALLING, false, false, false);

    Object.freeze(this.serviceTypeMd);
  }

  /**
   * @param {ServiceType} serviceType
   * @param {boolean} canHaveMultiple
   * @param {boolean} canAddMultiple
   * @param {boolean} isPhone
   * @param {string} [model]
   */
  addServiceTypeMd(
    serviceType: (typeof ServiceType)[keyof typeof ServiceType],
    canHaveMultiple: boolean,
    canAddMultiple: boolean,
    isPhone: boolean,
    model = ''
  ) {
    if (canAddMultiple && !canHaveMultiple) {
      throw new Error('Tried to add multiple when having multiple not enabled');
    }

    this.serviceTypeMd[serviceType] = {
      icon: this.serviceTypeIconFilter.transform(serviceType),
      name: this.serviceTypeNameFilter.getServiceTranslation(serviceType),

      canHaveMultiple,
      canAddMultiple,
      isPhone,
      model,
    };
  }
}
