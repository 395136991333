<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <h4>{{ 'tkey;zero_touch.bulk_provisioning.description.header' | translate }}</h4>
        <hr />
        <div [innerHTML]="'tkey;zero_touch.bulk_provisioning.description.text' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <router-outlet></router-outlet>
  </div>

  <div class="col-md-10 offset-md-1 h-100 d-flex flex-column pb-3" *ngIf="!isLoading">
    <legend data-automation="bulk-provisioning-header"></legend>
    <div
      class="card border-primary text-center mb-3"
      *ngIf="isRunning || isComplete"
      [attr.data-automation]="isRunning ? 'bulk-provisioning-in-progress-alert' : 'bulk-provisioning-complete-alert'"
    >
      <div class="card-body strong">
        <i *ngIf="isRunning" class="{{ smacsIcons.SAVING }} pe-1"></i>
        <i *ngIf="isComplete" class="{{ smacsIcons.OK }} pe-1"></i>
        <span>
          {{
            (isRunning
              ? 'tkey;zero_touch.bulk_provisioning.in_progress.1'
              : 'tkey;zero_touch.bulk_provisioning.complete'
            ) | translate
          }}
        </span>
        <a [href]="auditLink" target="_blank">
          {{ 'tkey;zero_touch.bulk_provisioning.in_progress.2' | translate }}
        </a>
        <span>{{ 'tkey;zero_touch.bulk_provisioning.in_progress.3' | translate }}</span>
      </div>
    </div>

    <ul class="nav nav-tabs nav-fill" *ngIf="!isRunning">
      <li class="nav-item">
        <div class="nav-link" [ngClass]="{ active: currentTab === BulkProvisioningTab.GENERAL }">
          <div class="btn btn-sm">
            <strong>1</strong>
          </div>

          <span>{{ 'tkey;zero_touch.bulk_provisioning.general_settings.card_header' | translate }}</span>
        </div>
      </li>

      <li class="nav-item">
        <div class="nav-link" [ngClass]="{ active: currentTab === BulkProvisioningTab.TABLE }">
          <div class="btn btn-sm">
            <strong>2</strong>
          </div>

          <span>{{ 'tkey;zero_touch.bulk_provisioning.upload_csv.card_header' | translate }}</span>
        </div>
      </li>
    </ul>

    <div class="card-border" *ngIf="!isRunning">
      <div class="card-body pb-0 h-100">
        <p *ngIf="!this.isLoading" class="card-text h-100">
          <smacs-bulk-provisioning-job-general
            [hidden]="currentTab === BulkProvisioningTab.TABLE"
            [csvTemplateUrl]="csvTemplateUrl"
            [tabSelectionEmitter]="tabSelectionEmitter"
            (smacsFormsUpdate$)="receiveJobSettingsUpdate($event)"
          ></smacs-bulk-provisioning-job-general>

          <smacs-bulk-provisioning-upload-csv
            [hidden]="currentTab === BulkProvisioningTab.GENERAL"
            [tabSelectionEmitter]="tabSelectionEmitter"
            [bulkProvisioningConfig]="bulkProvisioningConfig"
            [csvTemplateUrl]="csvTemplateUrl"
            (jobSubmitted)="onJobRun()"
          ></smacs-bulk-provisioning-upload-csv>
        </p>
      </div>
    </div>
  </div>
</div>
