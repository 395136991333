import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  PipeTransform,
  QueryList,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SmacsIcons } from '../../shared/models/smacs-icons.enum';
import { DatatableFilterAbstractDirective } from './datatable-filters/datatable-filter-abstract.directive';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { difference } from 'lodash';
import { StripHtmlPipe } from '../../shared/pipes/strip-html.pipe';
import { ButtonSizes, ButtonStyles, ButtonTypes } from '../../button/button.component';
import { DatatableMultiSelectFilterComponent } from './datatable-filters/datatable-multi-select-filter/datatable-multi-select-filter.component';
import ChangeEvent = JQuery.ChangeEvent;

enum SortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface DatatableColumn<T extends DatatableRow> {
  name: keyof T & string;
  label: string;
  cssClass?: string;
  sortFn?: (a: any, b: any) => number;
  pipe?: PipeTransform;
  pipeArgs?: (keyof T)[];
  columnCharacterWidth?: number;
  ignoreFilter?: boolean;
  disableTooltip?: boolean;
}

/**
 * This allows parent components to specify different row attributes, i.e. whether it's selectable, the colour, etc.
 * The names of these properties should be strange / obscure to ensure no conflicts between this interface and the datasets.
 */
export interface DatatableRow {
  datatableRowIndex?: number;
  isTableRowSelectable?: boolean;
  isTableRowSelectDisabled?: boolean;
  tableRowSelectDisabledTooltip?: string;
  isSelectedInTable?: boolean;
  cssClass?: string;
  // hardcoded tooltip overrides the default (i.e. the full text for cells that exceed the max length)
  hardcodedTooltips?: {
    [colName: string]: string;
  };
  hardcodedDisableTooltip?: {
    [colName: string]: boolean;
  };
  isEditDisabled?: boolean;
  isEditButtonHidden?: boolean;
  editButtonHref?: string;
  disabledEditMsg?: string;
}

export type DatatableRowSelectedEvent<T> = 'all' | 'none' | T[];

/** Don't forget to update this function when you update the above interface. {@see matchesSearchBar} */
function isKeyOfDatatableRow(key: string): key is keyof DatatableRow {
  return (
    key === 'datatableRowIndex' ||
    key === 'isTableRowSelectable' ||
    key === 'isTableRowSelectDisabled' ||
    key === 'tableRowSelectDisabledTooltip' ||
    key === 'isSelectedInTable' ||
    key === 'cssClass' ||
    key === 'hardcodedTooltips' ||
    key === 'hardcodedDisableTooltip' ||
    key === 'isEditDisabled' ||
    key === 'isEditButtonHidden' ||
    key === 'editButtonHref' ||
    key === 'disabledEditMsg'
  );
}

export interface TableParams {
  [param: string]: any;
}

export interface FilterParams {
  [tableId: string]: TableParams;
}

@Component({
  selector: 'smacs-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss'],
})
export class DatatableComponent<T extends DatatableRow>
  implements OnInit, OnChanges, AfterViewInit, AfterContentInit, AfterContentChecked, OnDestroy
{
  readonly PAGE_SIZE = 50;
  @ViewChild('scrollingTable') scrollingTable: ElementRef<Element>;
  @ContentChildren(DatatableFilterAbstractDirective) filters: QueryList<DatatableFilterAbstractDirective<any>>;

  @Input({ required: true }) dataAutomation: string;
  @Input() columns: DatatableColumn<T>[];
  @Input() data: T[];
  @Input() isFirstColumnSticky = false;
  @Input() showEditColumn = false;
  @Input() showSelectColumn = false;
  @Input() editTooltip = '';
  @Input() editIcon = SmacsIcons.EDIT;
  @Input() editButtonType = ButtonTypes.LINK;
  @Input() editButtonStyle = ButtonStyles.LINK;
  @Input() editButtonSize = ButtonSizes.NORMAL;
  @Input() editButtonLabel = '';
  @Input() editButtonCssClasses = '';
  @Input() mainFilterPlaceholder: string;
  @Input() noResultsAlert: string;
  @Input() noResultsAlertClass: string;
  @Input() maxLength = 30;
  @Input() tableFontClass: 'table-font-mono' | 'table-font-sans-serif' = 'table-font-mono';
  @Input() isStriped = true;
  @Input() tableSize: 'table-sm' | 'table-md' | '' = 'table-sm';
  @Input() setColumnWidths = true;
  @Input() containerCard = false;
  @Input() hasFilter = true;
  @Input() isLoadingSecondaryData = false;
  @Input() truncateCell = true;
  @Input() persistFilters = false;
  @Input() showCustomAlert = false;
  @Input() minTableHeight: string;
  @Input() matchesSecondaryDataFn: (searchFilter: string, args: T) => boolean;

  @Output() rowSelected = new EventEmitter<DatatableRowSelectedEvent<T>>();
  @Output() isViewStillLoading = new EventEmitter<boolean>();
  @Output() editClicked = new EventEmitter<T>();

  isViewLoading = false;
  SmacsIcons = SmacsIcons;
  buttonStyles = ButtonStyles;
  DatatableSortDirection = SortOrder;
  allSelected = false;
  someSelected = false;
  filteredData: T[];
  paginatedData: T[];
  page = 1;
  columnSortDirections: { [colName: string]: SortOrder };
  searchValue = '';
  sortActive = '';
  sortState: SortOrder;
  isTableScrolledRight: boolean;
  isTableScrolledLeft: boolean;
  searchBarTextChanged = new Subject<string>();
  showNoResultWarningAboveSelected = false;
  allFilterParams: FilterParams = {};
  private subscriptions = new Subscription();

  constructor(private changeDetectorRef: ChangeDetectorRef, private stripHtmlPipe: StripHtmlPipe) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data) {
      this._determineColumnWidths();
      this.data.forEach((row, index) => {
        row.datatableRowIndex = index;
      });
      this.filteredData = [...changes.data.currentValue];
      this.filterTableData();
    }
  }

  ngOnInit() {
    this.columns.forEach((col) => {
      if (!col.sortFn && this.data.length > 0) {
        const typeOfData = typeof this.data[0][col.name];
        if (typeOfData === 'string') {
          col.sortFn = (a: string, b: string) => a?.localeCompare(b);
        } else if (typeOfData === 'number') {
          col.sortFn = (a: number, b: number) => a - b;
        } else if (typeOfData === 'boolean') {
          col.sortFn = (a: boolean, b: boolean) => (a === b ? 0 : a ? 1 : -1);
        } else {
          throw new Error(
            `sortFn was undefined for [${col.name}], but must be defined for data types other than string, number or boolean.`
          );
        }
      }
    });

    this.columnSortDirections = this.columns.reduce<{ [col: string]: SortOrder }>((obj, col) => {
      obj[col.name] = SortOrder.DESC;
      return obj;
    }, {});

    this._setMainFilterPlaceholder(this.columns);

    const searchSub = this.searchBarTextChanged
      .pipe(
        distinctUntilChanged(),
        tap((newValue) => (this.searchValue = newValue)),
        debounceTime(500),
        tap(() => {
          this._forceDatatableLoading();
          if (!this.isLoadingSecondaryData) {
            this.updateFilterParams({ q: this.searchValue });
            this.filterTableData();
          }
        })
      )
      .subscribe();
    this.subscriptions.add(searchSub);

    if (this.persistFilters) {
      this.initFilterParams();
    }
  }

  ngAfterViewInit() {
    this._checkScrollProps();
    this.scrollingTable.nativeElement.addEventListener('scroll', () => this._checkScrollProps());

    this.filters.forEach((filter) => {
      filter.filterUpdate$.subscribe(() => this.filterTableData());

      if (this.persistFilters && this.allFilterParams[this.dataAutomation]) {
        const param = Object.entries(this.allFilterParams[this.dataAutomation]).find(
          (param) => param[0] === filter.dataAutomation
        );

        if (param && filter instanceof DatatableMultiSelectFilterComponent) {
          const multiSelectFilter = filter as DatatableMultiSelectFilterComponent;
          multiSelectFilter.onFilterChange(param[1], {
            target: { checked: true },
          } as ChangeEvent);
        }
        filter.filterUpdateSource.next();
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  ngAfterContentInit() {
    this.filterTableData();
    this.changeDetectorRef.detectChanges();
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  initFilterParams() {
    const sessionFilterParams = sessionStorage.getItem('filterParams');
    let allFilterParams;

    if (!!sessionFilterParams) {
      allFilterParams = JSON.parse(sessionFilterParams);
      this.allFilterParams = allFilterParams;
      const existingFilterParams = allFilterParams[this.dataAutomation];

      if (!!existingFilterParams && Object.keys(existingFilterParams).length) {
        const filterParams = { ...this.allFilterParams[this.dataAutomation], ...existingFilterParams };

        if ('q' in filterParams) {
          this.searchBarTextChanged.next(filterParams['q']);
        }

        if ('sortCol' in filterParams) {
          Object.entries(filterParams['sortCol']).forEach((colOrder) => {
            const colName = colOrder[0] as keyof DatatableRow;
            const colSortOrder = colOrder[1] as string;
            this.sortByFilterParams(colName, colSortOrder);
          });
        }

        this.updateFilterParams(filterParams);
      }
    } else {
      this.allFilterParams = { [this.dataAutomation]: {} };
      sessionStorage.setItem('filterParams', JSON.stringify(this.allFilterParams));
    }
  }

  updateFilterParams(updatedParams: { [param: string]: string }): void {
    if (this.persistFilters) {
      const sessionFilterParams = sessionStorage.getItem('filterParams');
      let allFilterParams;

      if (!!sessionFilterParams) {
        allFilterParams = JSON.parse(sessionFilterParams);
        this.allFilterParams = allFilterParams;
      }

      let filterParams: TableParams = {
        ...this.allFilterParams[this.dataAutomation],
      };

      if (Object.values(updatedParams).includes('ASC') || Object.values(updatedParams).includes('DESC')) {
        filterParams = {
          ...filterParams,
          sortCol: updatedParams,
        };
      } else {
        filterParams = {
          ...filterParams,
          ...updatedParams,
        };
      }

      if (filterParams['q'] === '' || filterParams['q'] === null) {
        delete filterParams['q'];
      }

      this.allFilterParams[this.dataAutomation] = filterParams;

      sessionStorage.setItem('filterParams', JSON.stringify(this.allFilterParams));
    }
  }

  getCellMaxStringLength(column: DatatableColumn<T>): number {
    return column.columnCharacterWidth || this.maxLength;
  }

  changePage() {
    const newPaginatedData = this.filteredData.slice(this.PAGE_SIZE * (this.page - 1), this.PAGE_SIZE * this.page);
    const diff = difference(this.paginatedData, newPaginatedData);
    if (diff.length > 0 || this.paginatedData?.length !== newPaginatedData.length) {
      this.isViewLoading = true;
      this.filters?.forEach((filter) => {
        filter.isViewLoading = this.isViewLoading;
        this.changeDetectorRef.detectChanges();
      });
      this.isViewLoading = true;
      this.paginatedData = this.filteredData.slice(0, 3);
      setTimeout(() => {
        this.paginatedData = this.filteredData.slice(this.PAGE_SIZE * (this.page - 1), this.PAGE_SIZE * this.page);
        this.isViewLoading = false;
        this.isViewStillLoading.emit(this.isViewLoading);
        this.filters?.forEach((filter) => (filter.isViewLoading = this.isViewLoading));
        this.changeDetectorRef.detectChanges();
      });
    } else {
      this.paginatedData = this.filteredData.slice(this.PAGE_SIZE * (this.page - 1), this.PAGE_SIZE * this.page);
      this.isViewLoading = false;
    }
    this.isViewStillLoading.emit(this.isViewLoading);
  }

  selectAll() {
    this.someSelected = false;
    this.filteredData.forEach(
      (row) => (row.isSelectedInTable = row.isTableRowSelectable && !row.isTableRowSelectDisabled && this.allSelected)
    );
    this.filterTableData();
    if (this.allSelected) {
      this.rowSelected.emit('all');
    } else {
      this.rowSelected.emit('none');
    }
  }

  checkIfAllSelected() {
    if (this.showSelectColumn) {
      let isAtLeastOneRowSelectable = false;
      this.allSelected =
        this.filteredData.every((row) => {
          if (row.isTableRowSelectable && !row.isTableRowSelectDisabled) {
            isAtLeastOneRowSelectable = true;
            return row.isSelectedInTable && !row.isTableRowSelectDisabled;
          }
          return true;
        }) && isAtLeastOneRowSelectable;
      const selectedRows = this.filteredData.filter((row) => row.isSelectedInTable && !row.isTableRowSelectDisabled);
      this.someSelected = !this.allSelected && selectedRows.length > 0;
      this.rowSelected.emit(selectedRows);
    }
  }

  filterTableData() {
    this.showNoResultWarningAboveSelected = false;
    this.filteredData = [];
    const currentlySelectedNotMatchingFilters: T[] = [];
    this.data.forEach((row) => {
      if (this.matchesFilters(row)) {
        this.filteredData.push(row);
      } else if (row.isSelectedInTable) {
        currentlySelectedNotMatchingFilters.push(row);
      }
    });
    if (currentlySelectedNotMatchingFilters.length > 0 && this.filteredData.length === 0) {
      this.showNoResultWarningAboveSelected = true;
    }
    this.filteredData = this.filteredData.concat(currentlySelectedNotMatchingFilters);
    this._resetPage();
    this.checkIfAllSelected();
  }

  onRowSelected() {
    this.filterTableData();
    this.rowSelected.emit();
    if (this.isViewLoading) {
      this.isViewLoading = false;
      this.isViewStillLoading.emit(this.isViewLoading);
    }
  }

  clearFilters() {
    this.searchBarTextChanged.next(null);
    this.filters.forEach((filter) => {
      filter.clear();
      filter.isViewLoading = this.isViewLoading;
      this.changeDetectorRef.detectChanges();
    });
    this.filterTableData();
  }

  sortByFilterParams(colName: keyof T & string, currentSortDir: string) {
    const column = this.columns.find((col) => col.name === colName);
    if (!column) return;
    const sortFn = column.sortFn;
    this.sortActive = colName;
    if (currentSortDir === SortOrder.DESC) {
      this.data.sort((a, b) => sortFn(a[colName], b[colName]) * -1);
    } else {
      this.data.sort((a, b) => sortFn(a[colName], b[colName]));
    }
    this.columnSortDirections[colName] = currentSortDir as SortOrder;
    this.sortState = currentSortDir as SortOrder;
    this.filterTableData();
  }

  sort(colName: keyof T & string, currentSortDir: string) {
    const sessionFilterParams = sessionStorage.getItem('filterParams');
    if (!!sessionFilterParams) {
      const allFilterParams = JSON.parse(sessionFilterParams);
      if (!!allFilterParams[this.dataAutomation] && 'sortCol' in allFilterParams[this.dataAutomation]) {
        delete allFilterParams[this.dataAutomation]['sortCol'];
      }
      sessionStorage.setItem('filterParams', JSON.stringify(allFilterParams));
    }
    if (!!this.allFilterParams[this.dataAutomation] && 'sortCol' in this.allFilterParams[this.dataAutomation]) {
      delete this.allFilterParams[this.dataAutomation]['sortCol'];
    }
    const sortFn = this.columns.find((col) => col.name === colName).sortFn;
    if (currentSortDir === SortOrder.DESC) {
      this.sortActive = colName;
      this.data.sort((a, b) => sortFn(a[colName], b[colName]));
      this.columnSortDirections[colName] = SortOrder.ASC;
      this.sortState = SortOrder.ASC;
    } else {
      this.sortActive = colName;
      this.data.sort((a, b) => sortFn(a[colName], b[colName]) * -1);
      this.columnSortDirections[colName] = SortOrder.DESC;
      this.sortState = SortOrder.DESC;
    }
    this.updateFilterParams({ [colName]: this.sortState });
    this.filterTableData();
  }

  isActiveColumn(columnName: string): boolean {
    if (this.sortActive === columnName) {
      return true;
    }
  }

  getPipeAgs(row: T, args: (keyof T)[]): any[] {
    return args ? args.map((arg) => row[arg]) : null;
  }

  onFilterChanged(newValue: string) {
    if (newValue !== this.searchValue) {
      this.searchBarTextChanged.next(newValue);
    }
  }

  private _resetPage() {
    this.page = 1;
    this.changePage();
  }

  matchesSearchBar(row: T): boolean {
    return (
      !this.searchValue ||
      Object.entries(row)
        .filter((entry) => this.isColumnSearchable(entry[0]))
        .some(([key, value]) => {
          return (
            !isKeyOfDatatableRow(key) &&
            value != null &&
            String(value).toLowerCase().includes(this.searchValue.toLowerCase().trim())
          );
        })
    );
  }

  isColumnSearchable(key: string): boolean {
    return !this.columns.find((column) => column.name === key)?.ignoreFilter;
  }

  matchesFilters(row: T): boolean {
    return !this.matchesSecondaryDataFn
      ? this.matchesSearchBar(row) && !this.filters?.some((filter) => !filter.matches(row))
      : (this.matchesSearchBar(row) || this.matchesSecondaryDataFn(this.searchValue, row)) &&
          !this.filters?.some((filter) => !filter.matches(row));
  }

  getSubtextValue(propertyName: string): string {
    return document.getElementById(`${propertyName}Subtext`)?.innerText;
  }

  trackColumnByName(i: number, column: DatatableColumn<any>): string {
    return column.name;
  }

  trackRowByDatatableIndex(i: number, row: T): number {
    return row.datatableRowIndex;
  }

  private _setMainFilterPlaceholder(columns: DatatableColumn<T>[]) {
    if (!!this.mainFilterPlaceholder) return;
    if (!this.mainFilterPlaceholder && !columns[0]?.label) {
      return (this.mainFilterPlaceholder = 'No results to filter by');
    }
    if (!!columns[0]?.label && !columns[1]?.label) {
      return (this.mainFilterPlaceholder = `Filter by ${columns[0].label}`);
    }
    this.mainFilterPlaceholder = `${this.columns[0].label}, ${this.columns[1].label}, etc`;
  }

  private _forceDatatableLoading() {
    this.isViewLoading = true;
    this.isViewStillLoading.emit(this.isViewLoading);
  }

  private _checkScrollProps() {
    const element = this.scrollingTable.nativeElement;

    const isTableScrolledRight = element.scrollLeft > 0;
    const isTableScrolledLeft = element.scrollWidth - element.scrollLeft - element.clientWidth > 0;

    if (this.isTableScrolledRight !== isTableScrolledRight) {
      this.isTableScrolledRight = isTableScrolledRight;
      this.changeDetectorRef.detectChanges();
    }

    if (this.isTableScrolledLeft !== isTableScrolledLeft) {
      this.isTableScrolledLeft = isTableScrolledLeft;
      this.changeDetectorRef.detectChanges();
    }
  }

  private _determineColumnWidths() {
    this.columns.forEach((column) => {
      if (column.columnCharacterWidth == null) {
        let columnCharacterWidth = 0;
        for (const row of this.data) {
          const cell = this.stripHtmlPipe.transform(row[column.name]);
          if (cell && typeof cell === 'string' && cell.length > columnCharacterWidth) {
            columnCharacterWidth = cell.length;
          }

          const cellSubtext = this.stripHtmlPipe.transform((row as any)[column.name + 'Subtext']);
          if (cellSubtext && typeof cellSubtext === 'string' && cellSubtext.length > columnCharacterWidth) {
            columnCharacterWidth = cellSubtext.length;
          }

          if (columnCharacterWidth >= this.maxLength) {
            columnCharacterWidth = this.maxLength;
            break;
          }
        }
        column.columnCharacterWidth = columnCharacterWidth;
      }
    });
  }

  _handleEdit(row: T) {
    if (!row.isEditDisabled) {
      this.editClicked.emit(row);
    }
  }
}
