import { Component } from '@angular/core';
import { ModalViewProperties } from '../modal-view-properties';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonSizes, ButtonStyles } from '../../button/button.component';
import { CustomModal } from '../../shared/services/smacs-modal.service';
import { ActivatedRoute, Router } from '@angular/router';

interface DeletedAgentErrorModalViewProperties {
  username: string;
}

@Component({
  selector: 'app-deleted-agent-error-modal',
  templateUrl: './deleted-agent-error-modal.component.html',
  styleUrls: ['./deleted-agent-error-modal.component.scss'],
})
export class DeletedAgentErrorModalComponent implements CustomModal<DeletedAgentErrorModalViewProperties> {
  modalProperties: DeletedAgentErrorModalViewProperties;
  username: string;
  modalViewProperties: ModalViewProperties;

  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;

  constructor(private activeModal: NgbActiveModal, private router: Router, private route: ActivatedRoute) {}

  onReturnTo360ViewClicked() {
    this.router.navigate(['../'], { relativeTo: this.route });
  }

  onCancelClicked() {
    this.activeModal.close(false);
  }
}
