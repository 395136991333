import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastService } from '../../../shared/services/toast.service';
import { combineLatest, forkJoin, Observable, Subscriber, Subscription } from 'rxjs';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { GlobalDirectoryResource } from '../../../shared/resources/global-directory.resource';
import { LdapUserDialPlanAttributesResource } from '../../../shared/resources/ldap-user-dial-plan-attributes.resource';
import {
  CurrentUser,
  DirectoryReportUser,
  JobState,
  JobStatus,
  Privilege,
} from '../../../shared/models/generated/smacsModels';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { DatatableColumn, DatatableComponent, DatatableRow } from '../../datatable/datatable.component';
import { AuthenticationContext } from '../../../shared/contexts/authentication.context';
import { first, map } from 'rxjs/operators';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { GlobalDirectoryPollingService } from '../../../shared/resources/global-directory-polling.service';
import { DateAgoPipe } from '../../../shared/pipes/date-ago.pipe';

interface GlobalDirectoryRow extends DatatableRow {
  ldapUserId: string;
  cucmExtension: string;
  ldapExtension: string;
  expectedLdapExtension: string;
  ldapDid: string;
  expectedLdapDid: string;
  // Subtext properties
  ldapExtensionSubtext: string;
  ldapExtensionSubtextLength: number;
  ldapDidSubtext: string;
  ldapDidSubtextLength: number;
}

@Component({
  selector: 'app-global-directory',
  templateUrl: './global-directory.component.html',
  styleUrls: ['../../reporting.scss'],
  providers: [GlobalDirectoryPollingService],
})
export class GlobalDirectoryComponent implements OnInit, OnDestroy {
  @ViewChild(DatatableComponent) datatableChildComponent: DatatableComponent<GlobalDirectoryRow>;

  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  isLoading = true;
  isDeleting = false;
  hasResultsToFix = false;
  isReadOnly: boolean;
  isDisplayAlert = false;
  timeStamp: string;
  results = [] as GlobalDirectoryRow[];
  tableDataAutomation = 'global-directory-datatable';
  columns = [
    {
      name: 'ldapUserId',
      label: 'tkey;reporting.global_directory.ldap_user_id.label',
    },
    {
      name: 'cucmExtension',
      label: 'tkey;reporting.global_directory.cucm_extension.label',
      cssClass: '',
    },
    {
      name: 'ldapExtension',
      label: 'tkey;reporting.global_directory.ldap_extension.label',
      cssClass: '',
    },
    {
      name: 'ldapDid',
      label: 'tkey;reporting.global_directory.ldap_did.label',
      cssClass: '',
    },
  ] as DatatableColumn<GlobalDirectoryRow>[];
  isOnlyShowMisConfigEnabled = false;
  fixSelectedButton = {
    id: 'global-directory-fix-selected-button',
    dataAutomation: 'global-directory-fix-selected-button',
    label: 'tkey;reporting.global_directory.fix',
    icon: this.smacsIcons.FIX_IT,
    buttonClass: this.buttonStyles.PRIMARY,
    cb: () => {
      this._onFixSelectedClick();
    },
    state: {
      buttonDisableState: { disabled: true, tooltipKey: 'tkey;reporting.global_directory.fix.disabled' },
      tooltipVisible: true,
    },
  };

  exportButton = {
    id: 'global-directory-export-excel-button',
    dataAutomation: 'global-directory-export-excel-button',
    label: 'tkey;reporting.xlsx_export.button',
    icon: this.smacsIcons.EXPORT,
    buttonClass: this.buttonStyles.PRIMARY,
    cb: () => {
      this._onGetFileClicked();
    },
    state: {
      buttonDisableState: {
        disabled: false,
        tooltipKey: '',
      },
      tooltipVisible: true,
    },
  };

  refreshDataButton = {
    id: 'global-directory-refresh-data-button',
    dataAutomation: 'global-directory-refresh-data-button',
    label: 'tkey;reporting.refresh_data.button',
    icon: this.smacsIcons.REFRESH,
    buttonClass: this.buttonStyles.DEFAULT,
    cb: () => {
      this.refreshGlobalDirectoryData();
    },
  };

  noResultsAlert: string;
  noResultsAlertClass: string;

  private _subs = new Subscription();

  private static _isInvalidDn(user: GlobalDirectoryRow): boolean {
    return user.expectedLdapExtension && user.ldapExtension !== user.expectedLdapExtension;
  }

  private static _isInvalidDid(user: GlobalDirectoryRow): boolean {
    return user.expectedLdapDid && user.ldapDid !== user.expectedLdapDid;
  }

  private static _isInvalidRow(user: GlobalDirectoryRow): boolean {
    return GlobalDirectoryComponent._isInvalidDn(user) || GlobalDirectoryComponent._isInvalidDid(user);
  }

  constructor(
    private toastService: ToastService,
    private bottomNavService: BottomNavService,
    private globalDirectoryResource: GlobalDirectoryResource,
    private globalDirectoryPollingService: GlobalDirectoryPollingService,
    private smacsModalService: SmacsModalService,
    private translateService: TranslateService,
    private ldapUserDialPlanAttributesResource: LdapUserDialPlanAttributesResource,
    private breadcrumbsService: BreadcrumbsService,
    private authenticationContext: AuthenticationContext,
    private dateAgoPipe: DateAgoPipe
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;reporting.global_directory.title' }]);

    const reportObservable = this._getGlobalDirectoryReport();
    const authenticationObservable = this.authenticationContext.state$.pipe(
      first(),
      map((user: CurrentUser) => {
        this.isReadOnly = !user.privileges.includes(Privilege.CONTROL_WRITE);
      })
    );

    const refreshStatusObservable = this.globalDirectoryResource.getStatus();

    this._subs.add(
      combineLatest([authenticationObservable, refreshStatusObservable]).subscribe(([authentication, status]) => {
        this.timeStamp = this.dateAgoPipe.transform(status.lastRunTime);
        if (status.jobState === JobState.IDLE) {
          if (status.lastRunTime === null) {
            this.globalDirectoryResource.refreshData().subscribe(() => {
              this._pollStatusUntilIdle(reportObservable);
            });
          } else {
            reportObservable.subscribe(() => {
              this._initBottomNav();
              this._showTimestamp(status);
              this.isDisplayAlert = false;
              this.isLoading = false;
            });
          }
        } else if (status.jobState === JobState.RUNNING || status.jobState === JobState.QUEUED) {
          this._initBottomNav();
          this.isLoading = true;
          this._setPending(true);
          this._setDisabled(true);
          this._pollStatusUntilIdle(reportObservable);
        }
      })
    );
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
    this.globalDirectoryPollingService.stopPolling();
    this._subs.unsubscribe();
  }

  private _setPending(isPending: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'global-directory-refresh-data-button',
        state: {
          pending: isPending,
          buttonDisableState: { disabled: isPending, tooltipKey: '' },
        },
      })
    );
  }

  private _setDisabled(isDisabled: boolean) {
    const hasRowsSelected = this.results.some((r: GlobalDirectoryRow) => r.isSelectedInTable);
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'global-directory-export-excel-button',
        state: {
          pending: false,
          buttonDisableState: {
            disabled: this.isLoading,
            tooltipKey: this.isLoading ? 'tkey;reporting.global_directory.disabled.tooltip' : '',
          },
        },
      })
    );
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'global-directory-fix-selected-button',
        state: {
          pending: false,
          buttonDisableState: {
            disabled: isDisabled && this.isLoading,
            tooltipKey:
              !hasRowsSelected && !this.isLoading
                ? 'tkey;reporting.global_directory.fix.disabled'
                : this.isLoading
                ? 'tkey;reporting.global_directory.disabled.tooltip'
                : '',
          },
        },
      })
    );
  }

  onRowSelected() {
    const rowsSelected = this.results.filter((result: GlobalDirectoryRow) => result.isSelectedInTable);
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        this.refreshDataButton,
        {
          ...this.fixSelectedButton,
          state: {
            buttonDisableState: {
              disabled: !rowsSelected.length,
              tooltipKey: !rowsSelected.length ? 'tkey;reporting.global_directory.fix.disabled' : '',
            },
            tooltipVisible: !rowsSelected.length,
          },
          label:
            rowsSelected.length >= 1
              ? rowsSelected.length === 1
                ? 'tkey;reporting.global_directory.delete.count.one.label'
                : 'tkey;reporting.global_directory.delete.count.label'
              : 'tkey;reporting.global_directory.delete.label',
          labelParam: rowsSelected.length,
        },
        this.exportButton,
      ])
    );
  }

  showOnlyMisconfiguredFilter = (filter: boolean, row: GlobalDirectoryRow): boolean => {
    this.isOnlyShowMisConfigEnabled = filter;
    if (!filter) {
      return true;
    }

    return GlobalDirectoryComponent._isInvalidRow(row);
  };

  private handlePollingStatusUntilIdle(jobStatus: JobStatus, reportObservable: Observable<void>) {
    if (jobStatus.jobState === JobState.RUNNING && jobStatus.lastRunTime === null) {
      this.isDisplayAlert = true;
    }
    if (jobStatus.jobState === JobState.IDLE && jobStatus.lastRunTime !== null) {
      this.globalDirectoryPollingService.stopPolling();
      reportObservable.subscribe(() => {
        this._initBottomNav();
        this._showTimestamp(jobStatus);
        this.isLoading = false;
        this.isDisplayAlert = false;
        this._setDisabled(false);
      });
    }
  }

  private _pollStatusUntilIdle(reportObservable: Observable<void>) {
    this.globalDirectoryPollingService.state$.subscribe({
      next: (jobStatus) => {
        this.handlePollingStatusUntilIdle(jobStatus, reportObservable);
      },
    });
    this.globalDirectoryPollingService.startPolling();
  }

  refreshGlobalDirectoryData() {
    this.isLoading = true;
    this._setPending(true);
    this._setDisabled(true);
    this.globalDirectoryResource.refreshData().subscribe(() => {
      this.globalDirectoryPollingService.state$.subscribe(this.handlePollingStatusOnRefresh.bind(this));
      this.globalDirectoryPollingService.startPolling();
    });
  }

  handlePollingStatusOnRefresh(jobStatus: JobStatus) {
    if (jobStatus.jobState === JobState.IDLE && jobStatus.lastRunTime !== null) {
      this.globalDirectoryPollingService.stopPolling();
      this._getGlobalDirectoryReport().subscribe(() => {
        this.isLoading = false;
        this.isDisplayAlert = false;
        this._setDisabled(false);
        this.toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.REFRESH,
          'tkey;global_directory.toast.title',
          'tkey;global_directory.toast.message'
        );
        this._showTimestamp(jobStatus);
      });
    }
  }

  private _showTimestamp(status: JobStatus) {
    this.timeStamp = this.dateAgoPipe.transform(status.lastRunTime);
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
        helpText: `<span class="me-2" data-automation="global-directory-last-updated">
        <i class="fa fa-clock-o"></i>
          ${this.translateService.instant('tkey;global_directory.refresh.timestamp.text')}
          <strong>${this.dateAgoPipe.transform(status.lastRunTime)}</strong>
    </span>`,
      })
    );
  }

  getNoResultsAlert(): string {
    if (!this.hasResultsToFix && this.isOnlyShowMisConfigEnabled) {
      return 'tkey;reporting.global_directory.alert.empty';
    }
  }

  getNoResultsAlertClass(): string {
    if (!this.hasResultsToFix && this.isOnlyShowMisConfigEnabled) {
      return 'table-success';
    }
  }

  private _setExportPending(isPending: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'global-directory-export-excel-button',
        state: {
          pending: isPending,
          buttonDisableState: { disabled: isPending, tooltipKey: '' },
        },
      })
    );
  }

  private _getGlobalDirectoryReport(): Observable<void> {
    return this.globalDirectoryResource.get().pipe(
      map((data: DirectoryReportUser[]) => {
        this.results = data.map((d: DirectoryReportUser) => {
          const row = {
            ldapUserId: d.ldapUserId,
            cucmExtension: d.cucmExtension,
            ldapExtension: d.ldapCurrentExtension,
            expectedLdapExtension: d.ldapExpectedExtension,
            ldapDid: d.ldapCurrentDid,
            expectedLdapDid: d.ldapExpectedDid,
            ldapExtensionSubtext:
              d.ldapExpectedExtension && d.ldapExpectedExtension !== d.ldapCurrentExtension
                ? `<span class="text-warning strong"><i class="icon-warning-triangle me-1"></i> ${this.translateService.instant(
                    'tkey;reporting.global_directory.expected.text'
                  )} ${d.ldapExpectedExtension}</span>`
                : '',
            ldapExtensionSubtextLength: `${this.translateService.instant(
              'tkey;reporting.global_directory.expected.text'
            )} ${d.ldapExpectedExtension}`.length,
            ldapDidSubtext:
              d.ldapExpectedDid && d.ldapExpectedDid !== d.ldapCurrentDid
                ? `<span class="text-warning strong"><i class="icon-warning-triangle me-1"></i> ${this.translateService.instant(
                    'tkey;reporting.global_directory.expected.text'
                  )} ${d.ldapExpectedDid}</span>`
                : '',
            ldapDidSubtextLength: `${this.translateService.instant('tkey;reporting.global_directory.expected.text')} ${
              d.ldapExpectedDid
            }`.length,
          } as GlobalDirectoryRow;

          return {
            ...row,
            isTableRowSelectable: GlobalDirectoryComponent._isInvalidRow(row),
            cssClass: GlobalDirectoryComponent._isInvalidRow(row) ? 'table-warning' : '',
          };
        });

        this.hasResultsToFix = this.results.some((row: GlobalDirectoryRow) => row.isTableRowSelectable);

        setTimeout(() => {
          this.datatableChildComponent?.filterTableData();
        });
      })
    );
  }

  isViewLoading($event: boolean) {
    if (!$event) {
      this._setPending(false);
    }
  }

  private _onFixSelectedClick() {
    const resultsToFix = this.results.filter((r: GlobalDirectoryRow) => r.isSelectedInTable);
    if (resultsToFix.length > 0) {
      const options = {
        windowClass: 'reports-global-directory-confirmation-modal',
        modalViewProperties: {
          title: this.translateService.instant('tkey;reporting.global_directory.fix.title'),
          promptBody: this.translateService.instant('tkey;reporting.global_directory.fix.confirmation', {
            count: resultsToFix.length,
          }),
          icon: SmacsIcons.FIX_IT,
          displayCloseButton: true,
          buttons: [
            {
              label: 'tkey;dialogs.button.cancel',
              buttonClass: ButtonStyles.DEFAULT,
              dataAutomation: 'confirmation-modal-cancel-button',
            },
            {
              label: 'tkey;reporting.global_directory.fix',
              buttonClass: ButtonStyles.PRIMARY,
              dataAutomation: 'confirmation-modal-confirm-button',
              cb: () => this._confirmFix(resultsToFix),
            },
          ],
        },
      };
      this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
    }
  }

  private handlePollingStatusOnConfirmFix(
    jobStatus: JobStatus,
    resultsToFix: GlobalDirectoryRow[],
    subscriber: Subscriber<void>
  ) {
    if (jobStatus.lastRunTime !== null && jobStatus.jobState !== JobState.RUNNING) {
      this.globalDirectoryPollingService.stopPolling();
      this._getGlobalDirectoryReport().subscribe(() => {
        if (resultsToFix.length > 3) {
          this.toastService.push(
            ToastTypes.SUCCESS,
            SmacsIcons.OK,
            'tkey;reporting.global_directory.title',
            this.translateService.instant('tkey;reporting.global_directory.toast.multiple.message', {
              number: resultsToFix.length,
            })
          );
        } else {
          resultsToFix.forEach((r: GlobalDirectoryRow) => {
            this.toastService.push(
              ToastTypes.SUCCESS,
              SmacsIcons.OK,
              'tkey;reporting.global_directory.title',
              this.translateService.instant('tkey;reporting.global_directory.toast.single.message', {
                username: r.ldapUserId,
              })
            );
          });
        }
        this.onRowSelected();
        subscriber.next();
        subscriber.complete();
      });
      this._showTimestamp(jobStatus);
    }
  }

  private _confirmFix(resultsToFix: GlobalDirectoryRow[]): Observable<void> {
    return new Observable<void>((subscriber) => {
      const requests = resultsToFix.map((r: GlobalDirectoryRow) => {
        return this.ldapUserDialPlanAttributesResource.put({
          username: r.ldapUserId,
          extension: r.expectedLdapExtension,
          did: r.expectedLdapDid,
        });
      });

      forkJoin(requests).subscribe(() => {
        this.globalDirectoryResource.refreshData().subscribe(() => {
          this.globalDirectoryPollingService.state$.subscribe({
            next: (jobStatus) => {
              this.handlePollingStatusOnConfirmFix(jobStatus, resultsToFix, subscriber);
            },
          });
          this.globalDirectoryPollingService.startPolling();
        });
      });
    });
  }

  private _initBottomNav() {
    const bottomNavButtons: BottomNavButton[] = [];
    bottomNavButtons.push(this.refreshDataButton);
    if (!this.isReadOnly) {
      bottomNavButtons.push(this.fixSelectedButton);
    }
    bottomNavButtons.push(this.exportButton);

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _onGetFileClicked() {
    this._setExportPending(true);

    this.globalDirectoryResource.getFile().subscribe(() => {
      this._setExportPending(false);
      this.toastService.push(
        ToastTypes.SUCCESS,
        this.smacsIcons.DOWNLOAD,
        'tkey;reporting.xlsx_export.downloaded_toast.title',
        'tkey;reporting.xlsx_export.downloaded_toast.message'
      );
    });
  }
}
