import { OrphanedTile } from '../models/report-dashboard.model';
import { dashboardActions } from '../actions/dashboard.actions';
import { createReducer, on } from '@ngrx/store';

export const initialOrphanedtile: OrphanedTile = {
  isLoading: true,
  orphanedDeviceCount: 0,
  tileStatus: {
    message: 'tkey;reporting.dashboard.orphaned_devices',
    status: null,
    value: 0,
    reportUrl: '../orphaned-devices',
    dataAutomation: 'orphaned-devices-tile',
  },
};

export const orphanedTileReducer = createReducer(
  initialOrphanedtile,
  on(dashboardActions.loadOrphanedTileAction, (state) => ({
    ...state,
  })),
  on(dashboardActions.loadOrphanedTileSuccessAction, (state, payload) => ({
    ...state,
    orphanedDeviceCount: payload.orphanedDeviceCount,
    tileStatus: payload.tileStatus,
    isLoading: payload.isLoading,
  }))
);
