import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AppUserRef,
  EndUserRef,
  ExtensionMobilityFieldConfig,
  PhoneFieldConfig,
  PhoneRef,
  SelectFieldConfig,
  TextFieldConfig,
} from '../../../../shared/models/generated/smacsModels';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { SmacsSelectConfig, SmacsSelectOption } from '../../../../forms/fields/select/smacs-select.component';
import { HtmlInputType, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import {
  SmacsFieldConfig,
  SmacsFormConfig,
  SmacsFormsValidationConfig,
  SmacsFormsValidationState,
} from '../../../../forms/smacs-forms-models';
import { Observable, Subject } from 'rxjs';
import { cloneDeep, union } from 'lodash';
import {
  HtmlCheckboxType,
  HtmlSwitchSize,
  SmacsCheckboxConfig,
} from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { EndUserSearchResource } from '../../../../shared/resources/end-user-search.resource';
import { AppUserSearchResource } from '../../../../shared/resources/app-user-search.resource';
import { filter, first, map } from 'rxjs/operators';
import { SearchPhoneResource } from '../../../../shared/resources/search-phone.resource';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { PhoneType } from '../../../../shared/models/service-type';
import { PhoneUiContext } from '../../../../shared/phone-buttons/contexts/phone-ui.context';
import { SearchExtensionMobilityProfilesResource } from '../../../../self-serve/resources/search/search-extension-mobility-profiles.resource';

/** The base interface from which the entity and form data extend. These are the fields they have in common. */
interface PhoneFieldsFormAbstractData {
  aarClassOfService: string;
  alwaysUsePrimeLine: string;
  alwaysUsePrimeLineForVoiceMessage: string;
  builtInBridge: string;
  ciscoSupportField: string;
  classOfService: string;
  commonDeviceConfiguration: string;
  commonPhoneProfile: string;
  description: string;
  deviceMobilityMode: string;
  devicePool: string;
  disableSpeakerPhone: boolean;
  emergencyNumbers: string;
  extDataLocationAuthServer: string;
  extDataLocationSecureAuthUrl: string;
  featureControlPolicyName: string;
  id: string;
  location: string;
  mediaResourceGroupList: string;
  name: string;
  networkMohSourceName: string;
  privacy: string;
  reroutingCallingSearchSpace: string;
  securityProfileName: string;
  sipProfileName: string;
  softkeyTemplate: string;
  subscribeCss: string;
  crossClusterCss: string;
  userLocale: string;
  userMohSourceName: string;
}

/** The form data of the phone fields form. Service subscriptions are represented by serviceSubscriptionsNavset
 * which is then used by the SmacsNavsetComponent.  */
interface PhoneFieldsFormData extends PhoneFieldsFormAbstractData {
  associatedAppUsers: SmacsSelectOption[];
  associatedEndUsers: SmacsSelectOption[];
  ctiAssociatedAppUsers: SmacsSelectOption[];
  ctiAssociatedEndUsers: SmacsSelectOption[];
}

/** The entity of the phone fields form. */
export interface PhoneFieldsEntity extends PhoneFieldsFormAbstractData {
  associatedAppUsers: AppUserRef[];
  associatedEndUsers: EndUserRef[];
  ctiAssociatedAppUsers: AppUserRef[];
  ctiAssociatedEndUsers: EndUserRef[];
}

/** The model-protocol-specific fields and whether they are supported by the current model-protocol.
 *  `supported` is deliberately undefined on init so that we can bypass the code block in which we replace
 *  the existing value with the default. {@see _setModelProtocolSpecificConfig} */
interface ModelProtocolSpecificField {
  fieldId:
    | 'disableSpeakerPhone'
    | 'userLocale'
    | 'networkMohSourceName'
    | 'sipProfileName'
    | 'builtInBridge'
    | 'featureControlPolicyName';
  supported?: boolean;
  lastSupportedValue?: string | boolean;
}

type DeviceFieldConfig = PhoneFieldConfig & ExtensionMobilityFieldConfig;

@Component({
  selector: 'smacs-phone-fields-form',
  templateUrl: './phone-fields-form.component.html',
  providers: [{ provide: SmacsFormAbstractDirective, useExisting: PhoneFieldsFormComponent }],
})
export class PhoneFieldsFormComponent
  extends SmacsFormAbstractDirective<PhoneFieldsEntity, PhoneFieldsFormData>
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() phoneType: PhoneType;

  @Input()
  get fieldConfig(): PhoneFieldConfig & ExtensionMobilityFieldConfig {
    return this._fieldConfig;
  }
  set fieldConfig(fieldConfig: DeviceFieldConfig) {
    const oldFieldConfig = cloneDeep(this._fieldConfig);
    this._fieldConfig = fieldConfig;
    if (fieldConfig && this.fieldComponents) {
      this.setSelectOptions(fieldConfig);
      this._redetermineModelProtocolSpecificSettings(fieldConfig, oldFieldConfig);
      this.changeDetectorRef.detectChanges();
    }
  }
  private _fieldConfig: DeviceFieldConfig;

  @Input() endUserRef: EndUserRef;
  @Input() cucmServerId: number;
  @Input() isFormStateInvalid: boolean;
  @Input() isCopy: boolean;
  @Input() isGeneratingPhone: boolean;
  @Output() conflictingPhoneFound = new Subject<PhoneRef>();
  DESCRIPTION_MAX_LENGTH = 128;

  value = false;
  formConfig: SmacsFormConfig;
  isDeskphoneType: boolean;
  genericSelectFieldIds = [
    'securityProfileName',
    'classOfService',
    'aarClassOfService',
    'mediaResourceGroupList',
    'reroutingCallingSearchSpace',
    'subscribeCss',
    'commonPhoneProfile',
    'commonDeviceConfiguration',
    'userMohSourceName',
    'softkeyTemplate',
    'location',
    'privacy',
    'deviceMobilityMode',
    'alwaysUsePrimeLine',
    'alwaysUsePrimeLineForVoiceMessage',
    'crossClusterCss',
  ];
  genericTextFieldIds = [
    'extDataLocationAuthServer',
    'extDataLocationSecureAuthUrl',
    'emergencyNumbers',
    'ciscoSupportField',
  ];
  associatedUserFieldIds = [
    'associatedEndUsers',
    'associatedAppUsers',
    'ctiAssociatedEndUsers',
    'ctiAssociatedAppUsers',
  ];

  modelProtocolSpecificSelectFields: ModelProtocolSpecificField[] = [
    { fieldId: 'userLocale' },
    { fieldId: 'networkMohSourceName' },
    { fieldId: 'sipProfileName' },
    { fieldId: 'builtInBridge' },
    { fieldId: 'featureControlPolicyName' },
  ];
  modelProtocolSpecificCheckboxFields: ModelProtocolSpecificField[] = [{ fieldId: 'disableSpeakerPhone' }];

  nameAsyncValidatorMessage = '';
  showAsyncValidatorSuccessMessage = false;

  validators = {
    nameAsyncValidator: (val: string): Observable<SmacsFormsValidationState> => {
      this.showAsyncValidatorSuccessMessage = false;
      return this.searchPhoneResource
        .get({ name: this.isDeskphoneType ? 'SEP' + val : val, 'cucm-server-id': this.cucmServerId })
        .pipe(
          map((phoneResults) => {
            const conflictingPhoneResult = phoneResults.find((result) => result.ref.id !== this.entity.id);
            this.conflictingPhoneFound.next(conflictingPhoneResult?.ref);
            if (!conflictingPhoneResult) {
              return SmacsFormsValidationState.VALID;
            } else {
              if (!conflictingPhoneResult.owner) {
                this.nameAsyncValidatorMessage = this.translateService.instant(
                  this.isDeskphoneType
                    ? 'tkey;shared.device.phone.mac_address.warning.overwrite.pub.helpblock'
                    : 'tkey;shared.device.phone.name.warning.overwrite.pub.helpblock',
                  { description: conflictingPhoneResult.ref.description }
                );
                if (conflictingPhoneResult.ref.description.startsWith('Auto') && this.isDeskphoneType) {
                  this.showAsyncValidatorSuccessMessage = true;
                  return SmacsFormsValidationState.VALID;
                } else {
                  return SmacsFormsValidationState.WARNING;
                }
              }
              this.nameAsyncValidatorMessage = this.translateService.instant(
                this.isDeskphoneType
                  ? 'tkey;shared.device.phone.mac_address.error.unique.helpblock'
                  : 'tkey;shared.device.phone.name.error.unique.helpblock',
                { description: conflictingPhoneResult.ref.description }
              );
              return SmacsFormsValidationState.INVALID;
            }
          })
        );
    },
  };

  private _initialPhoneName: string;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private endUserSearchResource: EndUserSearchResource,
    private appUserSearchResource: AppUserSearchResource,
    private searchPhoneResource: SearchPhoneResource,
    private translateService: TranslateService,
    private phoneUiContext: PhoneUiContext,
    private searchExtensionMobilityProfilesResource: SearchExtensionMobilityProfilesResource,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  private _searchAssociatedEndUsers = (searchTerm: string): Observable<SmacsSelectOption[]> => {
    return this.endUserSearchResource.searchByQ(searchTerm, this.cucmServerId).pipe(
      map((endUserResults) =>
        endUserResults.map<SmacsSelectOption>((result) => ({
          label: `${result.ref.firstName} ${result.ref.lastName} - ${result.ref.username}`,
          value: result.ref,
          disabled: this.endUserRef?.id === result.ref.id,
        }))
      )
    );
  };

  private _searchAssociatedAppUsers = (searchTerm: string): Observable<SmacsSelectOption[]> => {
    return this.appUserSearchResource.searchByQ(searchTerm, this.cucmServerId).pipe(
      map((appUserResults) =>
        appUserResults.map<SmacsSelectOption>((result) => ({
          label: result.ref.username,
          value: result.ref,
        }))
      )
    );
  };

  private _buildEndUserOption = (value: EndUserRef): SmacsSelectOption => {
    return {
      label: `${value.firstName} ${value.lastName} - ${value.username}`,
      value: value,
      disabled: this.endUserRef && this.endUserRef.id === value.id,
    } as SmacsSelectOption;
  };

  private _buildAppUserOption = (value: AppUserRef): SmacsSelectOption => {
    return {
      label: value.username,
      value: value,
    };
  };

  private _generateMacAddress = (): string => {
    let mac = 'DD';
    while (mac.length < 12) {
      mac += Math.floor(Math.random() * 10);
    }
    return mac;
  };

  ngOnInit() {
    // This will determine whether the 'Name' field is MAC
    this.isDeskphoneType = this.phoneType === PhoneType.DESKPHONE;
    this._initialPhoneName = this.entity.name;
    this._initFormConfig();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    window.setTimeout(() => {
      this._redetermineModelProtocolSpecificSettings(this.fieldConfig);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        name: {
          label: this.isDeskphoneType
            ? 'tkey;pages.details.phoneSettings.mac'
            : 'tkey;pages.details.phoneSettings.nondeskphone.name',
          dataAutomation: 'phone-name-input',
          componentConfig: new SmacsTextConfig({
            htmlInputType: HtmlInputType.TEXT,
            placeholder: this.isDeskphoneType ? 'MAC (ex. DD5148675309)' : '',
          }),
          required: true,
          autogeneration: {
            linkLabel: 'tkey;pages.details.phoneSettings.mac.autogeneratelink',
            generatedMsg: 'tkey;pages.details.phoneSettings.mac.autogenerateinfo',
            generateValue: this._generateMacAddress,
            hidden: () =>
              (!!this.formData.name && this.validationFlags['name'] !== SmacsFormsValidationState.INVALID) ||
              !this.isDeskphoneType,
          },
          defaultValue: () => this.fieldConfig?.name?.defaultValue,
          validation: this._getNameValidationConfig(),
          hidden: () => !this.fieldConfig?.name?.show && !this.isDeskphoneType,
        },
        description: {
          label: 'tkey;pages.details.phoneSettings.description',
          dataAutomation: 'phone-description-input',
          ...this._extractTextFieldConfig('description'),
          validation: [
            {
              validator: (val) =>
                /^[^\x00-\x1F\x22%&<>]*$/.test(val)
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: 'tkey;validators.global.error.pattern',
            },
            {
              validator: (val) =>
                val?.length <= this.DESCRIPTION_MAX_LENGTH
                  ? SmacsFormsValidationState.VALID
                  : SmacsFormsValidationState.INVALID,
              message: {
                content: 'tkey;validators.global.error.maxlength',
                params: {
                  maxlength: this.DESCRIPTION_MAX_LENGTH,
                },
              },
            },
          ],
        },
        securityProfileName: {
          label: 'tkey;pages.details.phoneSettings.security_profile.label',
          dataAutomation: 'phone-security-profile-name-select',
          componentConfig: new SmacsSelectConfig({ options: this.fieldConfig?.securityProfileName?.possibleOptions }),
          required: () => !!this.fieldConfig?.securityProfileName,
          hidden: () => !this.fieldConfig?.securityProfileName?.show,
        },
        classOfService: {
          label: 'tkey;pages.details.phoneSettings.css',
          dataAutomation: 'phone-class-of-service-select',
          ...this._extractSelectFieldConfig('callingSearchSpace'),
        },
        aarClassOfService: {
          label: 'tkey;pages.details.phoneSettings.aarCss',
          dataAutomation: 'phone-aar-class-of-service-select',
          ...this._extractSelectFieldConfig('aarCallingSearchSpace'),
        },
        mediaResourceGroupList: {
          label: 'tkey;pages.details.phoneSettings.mediaResourceGroupList',
          dataAutomation: 'media-resource-group-list-select',
          ...this._extractSelectFieldConfig('mediaResourceGroupList'),
        },
        reroutingCallingSearchSpace: {
          label: 'tkey;site_management.site.section.field.rerouting_calling_search_space.text',
          dataAutomation: 'phone-rerouting-css-select',
          ...this._extractSelectFieldConfig('reroutingCallingSearchSpace'),
        },
        subscribeCss: {
          label: 'tkey;pages.details.phoneSettings.subscribe_css.label',
          dataAutomation: 'phone-subscribe-class-of-service-select',
          ...this._extractSelectFieldConfig('subscribeCss'),
        },
        commonPhoneProfile: {
          label: 'tkey;pages.details.phoneSettings.cpp',
          dataAutomation: 'phone-common-phone-profile-select',
          ...this._extractSelectFieldConfig('commonPhoneProfile'),
        },
        builtInBridge: {
          label: 'tkey;pages.details.phoneSettings.builtinbridge',
          dataAutomation: 'phone-built-in-bridge-select',
          ...this._extractSelectFieldConfig('builtInBridge'),
        },
        featureControlPolicyName: {
          label: 'tkey;pages.details.phoneSettings.featurecontrolpolicy',
          dataAutomation: 'phone-feature-control-policy-name-select',
          ...this._extractSelectFieldConfig('featureControlPolicy'),
        },
        commonDeviceConfiguration: {
          label: 'tkey;pages.details.phoneSettings.commondeviceconfiguration',
          dataAutomation: 'phone-common-device-configuration-select',
          ...this._extractSelectFieldConfig('commonDeviceConfiguration'),
        },
        userLocale: {
          label: 'tkey;pages.details.phoneSettings.language',
          dataAutomation: 'phone-language-select',
          ...this._extractSelectFieldConfig('userLocale'),
        },
        userMohSourceName: {
          label: 'tkey;site_management.site.section.field.user_moh_audio_source.text',
          dataAutomation: 'phone-user-moh-audio-source-select',
          ...this._extractSelectFieldConfig('userMohAudioSource'),
        },
        networkMohSourceName: {
          label: 'tkey;shared.device.phone.network_music_on_hold.label',
          dataAutomation: 'phone-network-moh-audio-source-select',
          ...this._extractSelectFieldConfig('networkMohAudioSource'),
        },
        softkeyTemplate: {
          label: 'tkey;site_management.site.section.field.softkey_template.text',
          dataAutomation: 'phone-softkey-template-select',
          ...this._extractSelectFieldConfig('softkeyTemplate'),
        },
        location: {
          label: 'tkey;site_management.site.section.field.location.text',
          dataAutomation: 'phone-location-select',
          ...this._extractSelectFieldConfig('location'),
        },
        privacy: {
          label: 'tkey;site_management.site.section.field.privacy.text',
          dataAutomation: 'phone-privacy-select',
          ...this._extractSelectFieldConfig('privacy'),
        },
        deviceMobilityMode: {
          label: 'tkey;shared.device.phone.device_mobility_mode.label',
          dataAutomation: 'phone-device-mobility-mode-select',
          ...this._extractSelectFieldConfig('deviceMobilityMode'),
        },
        sipProfileName: {
          label: 'tkey;site_management.site.section.field.sip_profile_name.text',
          dataAutomation: 'phone-sip-profile-name-select',
          ...this._extractSelectFieldConfig('sipProfileName'),
        },
        alwaysUsePrimeLine: {
          label: 'tkey;shared.device.phone.always_use_prime_line.label',
          dataAutomation: 'phone-always-use-prime-line-select',
          ...this._extractSelectFieldConfig('alwaysUsePrimeLine'),
        },
        alwaysUsePrimeLineForVoiceMessage: {
          label: 'tkey;shared.device.phone.always_use_prime_line_voice_message.label',
          dataAutomation: 'phone-always-use-prime-line-for-voice-message-select',
          ...this._extractSelectFieldConfig('alwaysUsePrimeLineForVoiceMessage'),
        },
        crossClusterCss: {
          label: 'tkey;userdetail.extension_mobility.cross_cluster_css.label',
          dataAutomation: 'phone-cross-cluster-css-select',
          ...this._extractSelectFieldConfig('crossClusterCss'),
        },
        emergencyNumbers: {
          label: 'tkey;site_management.site.section.field.emergency_numbers',
          dataAutomation: 'phone-emergency-numbers-input',
          ...this._extractTextFieldConfig('emergencyNumbers'),
        },
        ciscoSupportField: {
          label: 'tkey;site_management.site.section.field.cisco_support_field.text',
          dataAutomation: 'phone-cisco-support-field-input',
          ...this._extractTextFieldConfig('ciscoSupportField'),
        },
        extDataLocationAuthServer: {
          label: 'tkey;shared.device.phone.ext_data_location_auth_server.label',
          dataAutomation: 'phone-external-data-location-auth-server-input',
          ...this._extractTextFieldConfig('extDataLocationAuthServer'),
        },
        extDataLocationSecureAuthUrl: {
          label: 'tkey;shared.device.phone.ext_data_location_secure_auth_url.label',
          dataAutomation: 'phone-external-data-location-secure-auth-url',
          ...this._extractTextFieldConfig('extDataLocationSecureAuthUrl'),
        },
        disableSpeakerPhone: {
          label: 'tkey;site_management.site.section.field.disable_speaker_phone.text',
          dataAutomation: 'phone-disable-speakerphone-checkbox',
          componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.SWITCH, size: HtmlSwitchSize.SM }),
          required: false,
          defaultValue: () => this.fieldConfig?.disableSpeakerPhone?.defaultValue,
          hidden: () => !this.fieldConfig?.disableSpeakerPhone?.show,
        },
        associatedEndUsers: {
          label: 'tkey;userdetail.phone.associated_end_users.label',
          dataAutomation: 'phone-associated-end-users-select',
          componentConfig: new SmacsSelectConfig({
            isMultiSelect: true,
            asyncOptionsFn: (searchTerm) => this._searchAssociatedEndUsers(searchTerm),
            placeholder: 'tkey;userdetail.phone.associated_end_users.placeholder',
            showAutoGenerationLink: true,
          }),
          hidden: () => !this.fieldConfig?.associatedEndUsers?.show,
          defaultValue: () => {
            return this.fieldConfig?.associatedEndUsers?.defaultValues.map((option) => {
              return {
                label: `${option.firstName} ${option.lastName} - ${option.username}`,
                value: option,
                disabled: this.endUserRef && this.endUserRef.id === option.id,
              } as SmacsSelectOption;
            });
          },
        },
        associatedAppUsers: {
          label: 'tkey;userdetail.phone.associated_app_users.label',
          dataAutomation: 'phone-associated-app-users-select',
          componentConfig: new SmacsSelectConfig({
            isMultiSelect: true,
            asyncOptionsFn: (searchTerm) => this._searchAssociatedAppUsers(searchTerm),
            placeholder: 'tkey;userdetail.phone.associated_app_users.placeholder',
          }),
          hidden: () => !this.fieldConfig?.associatedAppUsers?.show,
        },
        ctiAssociatedEndUsers: {
          label: 'tkey;userdetail.phone.associated_end_users_cti.label',
          dataAutomation: 'phone-cti-associated-end-users-select',
          componentConfig: new SmacsSelectConfig({
            isMultiSelect: true,
            asyncOptionsFn: (searchTerm) => this._searchAssociatedEndUsers(searchTerm),
            placeholder: 'tkey;userdetail.phone.associated_end_users.placeholder',
            showAutoGenerationLink: true,
          }),
          hidden: () => !this.fieldConfig?.associatedEndUsersCti?.show,
          defaultValue: () => {
            return this.fieldConfig?.associatedEndUsersCti?.defaultValues.map((option) => {
              return {
                label: `${option.firstName} ${option.lastName} - ${option.username}`,
                value: option,
                disabled: this.endUserRef && this.endUserRef.id === option.id,
              } as SmacsSelectOption;
            });
          },
        },
        ctiAssociatedAppUsers: {
          label: 'tkey;userdetail.phone.associated_app_users_cti.label',
          dataAutomation: 'phone-cti-associated-app-users-select',
          componentConfig: new SmacsSelectConfig({
            isMultiSelect: true,
            asyncOptionsFn: (searchTerm) => this._searchAssociatedAppUsers(searchTerm),
            placeholder: 'tkey;userdetail.phone.associated_app_users.placeholder',
          }),
          hidden: () => !this.fieldConfig?.associatedAppUsersCti?.show,
        },
      },
    };
    this.entitySource.next({
      ...this.entity,
      name: this.isCopy ? '' : this.entity.name,
      description: this.isCopy ? this.entity.description.concat(' (Copy)') : this.entity.description,
    });
  }

  private _extractSelectFieldConfig(fieldName: keyof DeviceFieldConfig): SmacsFieldConfig<SmacsSelectConfig> {
    return {
      componentConfig: new SmacsSelectConfig({ options: this._getSelectFieldConfig(fieldName)?.possibleOptions }),
      defaultValue: () => this._getSelectFieldConfig(fieldName)?.defaultValue || '',
      required: () => !!this._getSelectFieldConfig(fieldName)?.required,
      hidden: () => this.fieldConfig && !this._getSelectFieldConfig(fieldName)?.show,
    };
  }

  private _getSelectFieldConfig(fieldName: keyof DeviceFieldConfig): SelectFieldConfig<string> {
    return (
      this.fieldConfig && this.fieldConfig[fieldName] && (this.fieldConfig[fieldName] as SelectFieldConfig<string>)
    );
  }

  private _extractTextFieldConfig(fieldName: keyof DeviceFieldConfig): SmacsFieldConfig<SmacsTextConfig> {
    return {
      componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
      defaultValue: () => this._getTextFieldConfig(fieldName)?.defaultValue || '',
      required: () => !!this._getTextFieldConfig(fieldName)?.required,
      hidden: () => this.fieldConfig && !this._getTextFieldConfig(fieldName)?.show,
    };
  }

  private _getTextFieldConfig(fieldName: keyof DeviceFieldConfig): TextFieldConfig {
    return this.fieldConfig && this.fieldConfig[fieldName] && (this.fieldConfig[fieldName] as TextFieldConfig);
  }

  private _redetermineModelProtocolSpecificSettings(
    newFieldConfig: DeviceFieldConfig,
    oldFieldConfig?: DeviceFieldConfig
  ) {
    const newEntity = cloneDeep(this.entity);
    union(this.modelProtocolSpecificSelectFields, this.modelProtocolSpecificCheckboxFields).forEach((field) => {
      this._setModelProtocolSpecificConfig(field, newEntity);
    });

    if (
      oldFieldConfig?.securityProfileName &&
      newFieldConfig.securityProfileName.defaultValue !== oldFieldConfig.securityProfileName.defaultValue
    ) {
      // Security Profile is special, it is different depending on the model and protocol.
      newEntity.securityProfileName = newFieldConfig.securityProfileName.defaultValue;
      this._applyFieldData(newEntity, 'securityProfileName');
      this.fieldChannels.securityProfileName.validateSource.next();
    }

    this._setFieldStates();
    this.entitySource.next(newEntity);
  }

  private _setModelProtocolSpecificConfig(field: ModelProtocolSpecificField, entity: PhoneFieldsEntity) {
    const isSupportedByNewModel = this.isFieldSupportedByNewModel(field.fieldId);
    const defaultValue = this._getDefaultValueForField(field.fieldId);
    const oldValue = entity[field.fieldId];

    if (!isSupportedByNewModel) {
      if (field.supported === true) {
        // Save the current value if we're switching away from a supported model.
        field.lastSupportedValue = entity[field.fieldId];
        (entity[field.fieldId] as string | boolean) = typeof field.lastSupportedValue === 'string' ? '' : false;
      }
    } else if (field.supported === false) {
      // note: if supported is undefined, this is our first pass, DON'T replace the existing value!
      // This is to save us from overwriting the existing value on an existing phone where the feature is supported.
      (entity[field.fieldId] as string | boolean) =
        field.lastSupportedValue !== undefined ? field.lastSupportedValue : defaultValue;
    }

    field.supported = isSupportedByNewModel;

    if (entity[field.fieldId] !== oldValue) {
      this._applyFieldData(entity, field.fieldId);
    }
  }

  private _applyFieldData(entity: PhoneFieldsEntity, fieldId: keyof typeof entity) {
    const fieldComponent = this.fieldComponents.find((f) => f.fieldId === fieldId);
    fieldComponent.entity = entity[fieldId];
    fieldComponent.fieldData = entity[fieldId];
  }

  isFieldSupportedByNewModel(fieldId: string): boolean {
    switch (fieldId) {
      case 'userLocale':
        return !!this.fieldConfig?.userLocale;
      case 'networkMohSourceName':
        return !!this.fieldConfig?.networkMohAudioSource;
      case 'builtInBridge':
        return !!this.fieldConfig?.builtInBridge;
      case 'featureControlPolicyName':
        return !!this.fieldConfig?.featureControlPolicy;
      case 'sipProfileName':
        return !!this.fieldConfig?.sipProfileName;
      case 'disableSpeakerPhone':
        return !!this.fieldConfig?.disableSpeakerPhone;
    }
  }

  private _getDefaultValueForField(fieldId: string): string | boolean {
    switch (fieldId) {
      case 'userLocale':
        return this.fieldConfig?.userLocale?.defaultValue;
      case 'networkMohSourceName':
        return this.fieldConfig?.networkMohAudioSource?.defaultValue;
      case 'builtInBridge':
        return this.fieldConfig?.builtInBridge?.defaultValue;
      case 'featureControlPolicyName':
        return this.isDeskphoneType ? this.fieldConfig?.featureControlPolicy?.defaultValue : '';
      case 'sipProfileName':
        return this.fieldConfig?.sipProfileName?.defaultValue;
      case 'disableSpeakerPhone':
        return this.isDeskphoneType ? this.fieldConfig?.disableSpeakerPhone?.defaultValue : false;
    }
  }

  private _getNameValidationConfig(): SmacsFormsValidationConfig {
    const asyncValidators: SmacsFormsValidationConfig = [
      {
        validator: this.validators.nameAsyncValidator,
        message: () => this.nameAsyncValidatorMessage,
        successMessage: () => (this.showAsyncValidatorSuccessMessage ? this.nameAsyncValidatorMessage : ''),
      },
    ];
    const commonLengthValidator: SmacsFormsValidationConfig = [
      {
        validator: (val) => (val.length <= 15 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID),
        message: 'tkey;shared.device.phone.name.error.validation.length.helpblock',
      },
    ];
    const regexValidator = (regex: RegExp, message: string): SmacsFormsValidationConfig => [
      {
        validator: (val) => (regex.test(val) ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID),
        message,
      },
    ];
    switch (this.phoneType) {
      case PhoneType.ANDROID:
        return [
          ...regexValidator(/^BOT[A-Z0-9._-]*$/, 'tkey;shared.device.phone.name.error.validation.android.helpblock'),
          ...asyncValidators,
          ...commonLengthValidator,
        ];
      case PhoneType.IPHONE:
        return [
          ...regexValidator(/^TCT[A-Z0-9._-]*$/, 'tkey;shared.device.phone.name.error.validation.iphone.helpblock'),
          ...asyncValidators,
          ...commonLengthValidator,
        ];
      case PhoneType.TABLET:
        return [
          ...regexValidator(/^TAB[A-Z0-9._-]*$/, 'tkey;shared.device.phone.name.error.validation.tablet.helpblock'),
          ...asyncValidators,
          ...commonLengthValidator,
        ];
      case PhoneType.CIPC:
        return [
          ...regexValidator(/^[a-zA-Z0-9_-]*$/, 'tkey;validators.global.error.pattern'),
          ...asyncValidators,
          ...commonLengthValidator,
        ];

      case PhoneType.IM_SOFTPHONE:
        return [
          ...regexValidator(/^[a-zA-Z0-9]*$/, 'tkey;validators.global.error.pattern'),
          ...asyncValidators,
          ...commonLengthValidator,
        ];
      case PhoneType.EXTENSION_MOBILITY:
        return [
          ...regexValidator(/^[a-zA-Z0-9@#$^*()_\x20-]*$/, 'tkey;validators.global.error.pattern'),
          {
            validator: (val: string) =>
              val.length > 50 ? SmacsFormsValidationState.INVALID : SmacsFormsValidationState.VALID,
            message: {
              content: 'tkey;validators.global.error.maxlength',
              params: {
                maxlength: 50,
              },
            },
          },
          {
            validator: (val: string) => {
              return this.searchExtensionMobilityProfilesResource
                .getExtensionMobilityProfiles({
                  name: val,
                  'cucm-server-id': this.cucmServerId,
                })
                .pipe(
                  map((results) => {
                    const conflictingResult = results.find((result) => result.ref.id !== this.entity.id);
                    if (!conflictingResult) {
                      return SmacsFormsValidationState.VALID;
                    } else {
                      this.nameAsyncValidatorMessage = this.translateService.instant(
                        'tkey;shared.device.phone.name.error.unique.helpblock',
                        { description: conflictingResult.ref.description }
                      );
                      return SmacsFormsValidationState.INVALID;
                    }
                  })
                );
            },
            message: () => this.nameAsyncValidatorMessage,
          },
        ];
      default:
        // deskphone
        return [
          ...regexValidator(/^[a-fA-F0-9]{12}$/, 'tkey;pages.details.phoneSettings.mac.invalid'),
          ...asyncValidators,
        ];
    }
  }

  toFormData = (entity: PhoneFieldsEntity): PhoneFieldsFormData => {
    const entityClone = cloneDeep(entity);

    return {
      ...(entityClone as PhoneFieldsFormAbstractData),
      name: entityClone.name?.startsWith('SEP') ? entityClone.name.substring(3) : entityClone.name,
      associatedEndUsers: entityClone.associatedEndUsers?.map((value) => this._buildEndUserOption(value)),
      associatedAppUsers: entityClone.associatedAppUsers?.map((value) => this._buildAppUserOption(value)),
      ctiAssociatedEndUsers: entityClone.ctiAssociatedEndUsers?.map((value) => this._buildEndUserOption(value)),
      ctiAssociatedAppUsers: entityClone.ctiAssociatedAppUsers?.map((value) => this._buildAppUserOption(value)),
    };
  };

  toEntity = (formData: PhoneFieldsFormData): PhoneFieldsEntity => {
    const formDataClone = cloneDeep(formData);

    const newData = {
      ...(formDataClone as PhoneFieldsFormAbstractData),
      name: this.isDeskphoneType ? `SEP${formDataClone.name}` : formDataClone.name,
      associatedEndUsers: formDataClone.associatedEndUsers?.map<EndUserRef>((value) => value.value),
      associatedAppUsers: formDataClone.associatedAppUsers?.map<AppUserRef>((value) => value.value),
      ctiAssociatedEndUsers: formDataClone.ctiAssociatedEndUsers?.map<EndUserRef>((value) => value.value),
      ctiAssociatedAppUsers: formDataClone.ctiAssociatedAppUsers?.map<AppUserRef>((value) => value.value),
    };

    if (this.phoneType !== PhoneType.EXTENSION_MOBILITY) {
      for (const key in newData) {
        const phoneFieldsUpdateKey = key as keyof typeof newData;
        if (newData[phoneFieldsUpdateKey] === undefined) {
          delete newData[phoneFieldsUpdateKey];
        }
      }
    }

    return newData;
  };

  protected submit() {
    return this.phoneUiContext.isSaving$.pipe(
      filter((isSaving) => !isSaving),
      first()
    );
  }

  /**
   * Loop through all the fields, and set the options for all the selects
   */
  setSelectOptions(fieldConfig: PhoneFieldConfig | ExtensionMobilityFieldConfig) {
    const selects: { [key: string]: keyof DeviceFieldConfig } = {
      securityProfileName: 'securityProfileName',
      classOfService: 'callingSearchSpace',
      aarClassOfService: 'aarCallingSearchSpace',
      mediaResourceGroupList: 'mediaResourceGroupList',
      reroutingCallingSearchSpace: 'reroutingCallingSearchSpace',
      subscribeCss: 'subscribeCss',
      commonPhoneProfile: 'commonPhoneProfile',
      builtInBridge: 'builtInBridge',
      featureControlPolicyName: 'featureControlPolicy',
      commonDeviceConfiguration: 'commonDeviceConfiguration',
      userLocale: 'userLocale',
      userMohSourceName: 'userMohAudioSource',
      networkMohSourceName: 'networkMohAudioSource',
      softkeyTemplate: 'softkeyTemplate',
      location: 'location',
      privacy: 'privacy',
      deviceMobilityMode: 'deviceMobilityMode',
      sipProfileName: 'sipProfileName',
      alwaysUsePrimeLine: 'alwaysUsePrimeLine',
      alwaysUsePrimeLineForVoiceMessage: 'alwaysUsePrimeLineForVoiceMessage',
      crossClusterCss: 'crossClusterCss',
    };

    this.fieldComponents.forEach((field) => {
      const select = selects[field.fieldId];
      const selectFieldConfig = fieldConfig[
        select as keyof (PhoneFieldConfig | ExtensionMobilityFieldConfig)
      ] as SelectFieldConfig<string>;

      if (select && !!selectFieldConfig) {
        field.applyComponentConfig(new SmacsSelectConfig({ options: selectFieldConfig.possibleOptions }));
      }
    });
  }
}
