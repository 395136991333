import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MicrosoftGeneralSettings } from '../../shared/models/generated/smacsModels';

@Injectable()
export class MicrosoftGeneralSettingsResource {
  private _url = '/services/microsoft/admin/general-settings';

  constructor(private http: HttpClient) {}

  get(): Observable<MicrosoftGeneralSettings> {
    return this.http.get<MicrosoftGeneralSettings>(this._url);
  }

  put(settings: MicrosoftGeneralSettings): Observable<void> {
    return this.http.put<void>(this._url, settings);
  }
}
