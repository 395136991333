<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <p class="font-regular" [innerHTML]="'tkey;admin.dial_plan_exceptions.description.header' | translate"></p>
        <span [innerHTML]="'tkey;admin.dial_plan_exceptions.description.text' | translate"></span>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <app-entity-table [table]="entityTable" [tableRows]="tableRows"></app-entity-table>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
