import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatatableComponent } from './datatable.component';
import { DatatableSwitchFilterComponent } from './datatable-filters/datatable-switch-filter/datatable-switch-filter.component';
import { DatatableSliderFilterComponent } from './datatable-filters/datatable-slider-filter/datatable-slider-filter.component';
import { DatatableColumnWidthPipe } from './datatable-column-width.pipe';
import { DatatableMultiSelectFilterComponent } from './datatable-filters/datatable-multi-select-filter/datatable-multi-select-filter.component';
import { PipesModule } from '../../shared/pipes/pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { RouterModule } from '@angular/router';
import { CalendarModule } from 'primeng/calendar';
import { SmacsFormsModule } from '../../forms/smacs-forms.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { StoreModule } from '@ngrx/store';
import { orphanedTileReducer } from '../cisco/state-management/reducers/orphaned-tile.reducer';
import { optimizationTileReducer } from '../cisco/state-management/reducers/optimization-tile.reducer';
import { unusedTileReducer } from '../cisco/state-management/reducers/unused-tile.reducer';
import { EffectsModule } from '@ngrx/effects';
import {
  OptimizationTileEffect,
  OrphanedTileEffect,
  UnusedTileEffect,
} from '../cisco/state-management/effects/dashboard.effects';
import { LoadingBlockModule } from '../../shared/loading-block/loading-block.module';
import { BreadcrumbsModule } from '../../shared/breadcrumbs/breadcrumbs.module';
import { EntityTableModule } from '../../shared/entity-table/entity-table.module';
import { PaginationModule } from '../../shared/pagination/pagination.module';
import { BaseContainerModule } from '../../shared/abstract/base-container/base-container.module';
import { BottomNavModule } from '../../shared/bottom-nav/bottom-nav.module';
import { StripHtmlPipe } from '../../shared/pipes/strip-html.pipe';
import { DynamicPipe } from '../../shared/pipes/dynamic.pipe';
import { DatatableDateFilterComponent } from './datatable-filters/datatable-date-filter/datatable-date-filter.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgSelectModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    CalendarModule,
    OverlayPanelModule,
    SmacsFormsModule,
    ButtonModule,
    NgxSkeletonLoaderModule,
    NgxSliderModule,
    StoreModule.forRoot({
      reportDashboardOrphanedTile: orphanedTileReducer,
      reportDashboardOptimizationTile: optimizationTileReducer,
      reportDashboardUnusedTile: unusedTileReducer,
    }),
    EffectsModule.forRoot([OrphanedTileEffect, UnusedTileEffect, OptimizationTileEffect]),
    LoadingBlockModule,
    BreadcrumbsModule,
    EntityTableModule,
    PaginationModule,
    BaseContainerModule,
    BottomNavModule,
    PipesModule,
  ],
  declarations: [
    DatatableComponent,
    DatatableSwitchFilterComponent,
    DatatableSliderFilterComponent,
    DatatableMultiSelectFilterComponent,
    DatatableColumnWidthPipe,
    DatatableDateFilterComponent,
  ],
  exports: [
    DatatableComponent,
    DatatableSwitchFilterComponent,
    DatatableSliderFilterComponent,
    DatatableMultiSelectFilterComponent,
    DatatableDateFilterComponent,
  ],
  providers: [StripHtmlPipe, DynamicPipe],
})
export class DatatableModule {}
