import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'smacs-filter-checkbox',
  templateUrl: './filter-checkbox.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SmacsFilterCheckboxComponent,
    },
  ],
})
export class SmacsFilterCheckboxComponent implements ControlValueAccessor {
  @Input() value = false;
  @Input() dataAutomation = '';
  @Input() isCentered = false;
  @Input() isIndeterminate = false;
  @Input() selectAllValue = true;
  @Input() isSelectAll = false;
  @Output() filterCheckboxChanged = new EventEmitter<boolean>();

  filterOnChange() {
    this.filterCheckboxChanged.emit(this.value);
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChanged: any) {}

  registerOnTouched(fn: any) {}

  onClear() {
    this.value = false;
    this.filterCheckboxChanged.emit(this.value);
  }
}
