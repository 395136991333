import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { BandwidthDlrMisconfigurationReport } from '../../../../../shared/models/generated/smacsModels';
import { map } from 'rxjs/operators';

@Injectable()
export class DlrMisconfigurationsReportContext {
  private _stateSource = new ReplaySubject<BandwidthDlrMisconfigurationReport>(1);
  state$ = this._stateSource.asObservable();

  constructor(private http: HttpClient) {}

  initReport(): Observable<void> {
    return this._get().pipe(
      map((report) => {
        this._stateSource.next(report);
      })
    );
  }

  refreshReport(): Observable<void> {
    return this.http.post<void>('/services/microsoft/billing/dlr-misconfigurations-reports/refresh', null);
  }

  private _get(): Observable<BandwidthDlrMisconfigurationReport> {
    return this.http.get<BandwidthDlrMisconfigurationReport>(
      '/services/microsoft/billing/dlr-misconfigurations-reports'
    );
  }
}
