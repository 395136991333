import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subscriber } from 'rxjs';
import { GlobalProperties } from '../models/generated/smacsModels';
import { Injectable } from '@angular/core';

@Injectable()
export class GlobalPropertiesContext {
  _stateSource = new ReplaySubject<GlobalProperties>(1);
  state$ = this._stateSource.asObservable();

  constructor(private http: HttpClient) {}

  refreshGlobalProperties(): Observable<void> {
    return new Observable<void>((subscriber: Subscriber<void>) => {
      this._getGlobalProperties().subscribe((globalProperties: GlobalProperties) => {
        this._stateSource.next(globalProperties);
        subscriber.next();
        subscriber.complete();
      });
    });
  }

  private _getGlobalProperties(): Observable<GlobalProperties> {
    return this.http.get<GlobalProperties>('/services/system/info/global-properties');
  }
}
