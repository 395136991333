import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { LdapMembers, LdapPermissionGroup } from '../../../../shared/models/generated/smacsModels';
import { SmacsFormConfig, SmacsFormsUpdate } from '../../../../forms/smacs-forms-models';
import { HtmlCheckboxType, SmacsCheckboxConfig } from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { LdapResource } from '../../../../shared/resources/ldap.resource';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../../shared/services/toast.service';
import { Observable, Subject, Subscription } from 'rxjs';
import { ViewMembersState } from '../../../../shared/view-members/view-members.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';
import { map, tap } from 'rxjs/operators';

enum ViewMembersErrorMessages {
  READ_TIMEOUT = 'tkey;admin.ldap.view_members.read_timeout_error.text',
  CONNECTION_TIMEOUT = 'tkey;admin.ldap.view_members.connection_timeout_error.text',
  GENERIC_ERROR = 'tkey;admin.ldap.view_members.generic_error.text',
}

interface LdapPermissionGroupUiState {
  matches: string[];
  viewMembersState: ViewMembersState;
  errorMsg: string;
}

@Component({
  selector: 'smacs-admin-ldap-management-permissions',
  templateUrl: './admin-ldap-management-permissions.component.html',
})
export class AdminLdapManagementPermissionsComponent
  extends SmacsFormAbstractDirective<LdapPermissionGroup>
  implements OnInit, OnDestroy
{
  @Input() ldapPermissionGroup: LdapPermissionGroup;
  @Input() isSaved: Subject<void>;
  @Input() isProxyServerEnabled: boolean;

  administratorsGroup: LdapPermissionGroupUiState = {
    matches: [],
    viewMembersState: ViewMembersState.IDLE,
    errorMsg: '',
  };

  globalHelpdeskGroup: LdapPermissionGroupUiState = {
    matches: [],
    viewMembersState: ViewMembersState.IDLE,
    errorMsg: '',
  };

  siteBasedHelpdeskGroup: LdapPermissionGroupUiState = {
    matches: [],
    viewMembersState: ViewMembersState.IDLE,
    errorMsg: '',
  };

  smacsIcons = SmacsIcons;

  formConfig = {
    fields: {
      administratorsGroup: {
        label: 'tkey;admin.ldap.groups.admin.label',
        labelToolTipText: 'tkey;admin.ldap.groups.admin.tooltip',
        labelToolTipIconClass: `icon ${this.smacsIcons.INFO}`,
        dataAutomation: 'ldap-permission-admin-group',
        componentConfig: new SmacsSelectConfig({
          isMultiSelect: false,
          asyncOptionsFn: (query) => this._searchLdapGroups(query),
          placeholder: 'tkey;admin.ldap.groups.permissions.placeholder',
        }),
      },
      globalHelpdeskGroup: {
        label: 'tkey;admin.ldap.groups.global_helpdesk.label',
        labelToolTipText: 'tkey;admin.ldap.groups.global_helpdesk.tooltip',
        labelToolTipIconClass: `icon ${this.smacsIcons.INFO}`,
        dataAutomation: 'ldap-permission-global-helpdesk-group',
        componentConfig: new SmacsSelectConfig({
          isMultiSelect: false,
          asyncOptionsFn: (query) => this._searchLdapGroups(query),
          placeholder: 'tkey;admin.ldap.groups.permissions.placeholder',
        }),
      },
      siteBasedHelpdeskGroup: {
        label: 'tkey;admin.ldap.groups.sitemac.label',
        labelToolTipText: 'tkey;admin.ldap.groups.sitemac.tooltip',
        labelToolTipIconClass: `icon ${this.smacsIcons.INFO}`,
        dataAutomation: 'ldap-permission-mac-site-group',
        componentConfig: new SmacsSelectConfig({
          isMultiSelect: false,
          asyncOptionsFn: (query) => this._searchLdapGroups(query),
          placeholder: 'tkey;admin.ldap.groups.permissions.placeholder',
        }),
      },
      lookupNestedGroups: {
        label: 'tkey;admin.ldap.groups.lookup_nested_groups.label',
        dataAutomation: 'ldap-group-lookup-nested-groups',
        componentConfig: new SmacsCheckboxConfig({ checkboxType: HtmlCheckboxType.CHECKBOX }),
      },
    },
  } as SmacsFormConfig;

  private _subs = new Subscription();

  constructor(
    private ldapResource: LdapResource,
    private toastService: ToastService,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._setDefaultValues();
    this._watchForUpdates();

    const sub = this.isSaved.asObservable().subscribe(() => {
      this._searchAllLdapGroupMembers();
    });
    this._subs.add(sub);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subs.unsubscribe();
  }

  protected submit() {
    return this._onSaveClick();
  }

  private _onSaveClick(): Observable<void> {
    return this.ldapResource.putLdapPermissionGroup(this.formData).pipe(
      tap(() => {
        this.toastService.push(
          ToastTypes.SUCCESS,
          this.smacsIcons.LDAP,
          'tkey;shared.toast.save.success.title',
          'tkey;admin.ldap.groups.saved'
        );

        this._clearViewMemberLists();
        this._searchAllLdapGroupMembers();
      })
    );
  }

  private _setDefaultValues() {
    this.entitySource.next({
      ...this.ldapPermissionGroup,
    });

    if (this.ldapPermissionGroup.administratorsGroup) {
      this.administratorsGroup.viewMembersState = ViewMembersState.SEARCHING;
      this._searchLdapGroupMembers(this.ldapPermissionGroup.administratorsGroup).subscribe({
        next: (data: LdapMembers) => this._setMatches(data, this.administratorsGroup),
        error: (error) => this._setMatchesError(error, this.administratorsGroup),
      });
    }

    if (this.ldapPermissionGroup.globalHelpdeskGroup) {
      this.globalHelpdeskGroup.viewMembersState = ViewMembersState.SEARCHING;
      this._searchLdapGroupMembers(this.ldapPermissionGroup.globalHelpdeskGroup).subscribe({
        next: (data: LdapMembers) => this._setMatches(data, this.globalHelpdeskGroup),
        error: (error) => this._setMatchesError(error, this.globalHelpdeskGroup),
      });
    }

    if (this.ldapPermissionGroup.siteBasedHelpdeskGroup) {
      this.siteBasedHelpdeskGroup.viewMembersState = ViewMembersState.SEARCHING;
      this._searchLdapGroupMembers(this.ldapPermissionGroup.siteBasedHelpdeskGroup).subscribe({
        next: (data: LdapMembers) => this._setMatches(data, this.siteBasedHelpdeskGroup),
        error: (error) => this._setMatchesError(error, this.siteBasedHelpdeskGroup),
      });
    }
  }

  private _searchAllLdapGroupMembers() {
    this.administratorsGroup.viewMembersState = ViewMembersState.SEARCHING;
    this.globalHelpdeskGroup.viewMembersState = ViewMembersState.SEARCHING;
    this.siteBasedHelpdeskGroup.viewMembersState = ViewMembersState.SEARCHING;

    this._searchLdapGroupMembers(this.formData.administratorsGroup).subscribe({
      next: (data: LdapMembers) => this._setMatches(data, this.administratorsGroup),
      error: (error) => this._setMatchesError(error, this.administratorsGroup),
    });
    this._searchLdapGroupMembers(this.formData.globalHelpdeskGroup).subscribe({
      next: (data: LdapMembers) => this._setMatches(data, this.globalHelpdeskGroup),
      error: (error) => this._setMatchesError(error, this.globalHelpdeskGroup),
    });
    this._searchLdapGroupMembers(this.formData.siteBasedHelpdeskGroup).subscribe({
      next: (data: LdapMembers) => this._setMatches(data, this.siteBasedHelpdeskGroup),
      error: (error) => this._setMatchesError(error, this.siteBasedHelpdeskGroup),
    });
  }

  private _watchForUpdates() {
    this.smacsFormsUpdate$.subscribe((changes: SmacsFormsUpdate<LdapPermissionGroup>) => {
      // Admin Changes
      if (changes.new.administratorsGroup !== changes.old?.administratorsGroup) {
        this._getGroupMatches(
          changes.new.administratorsGroup,
          this.administratorsGroup,
          this.formData.administratorsGroup
        );
      }

      // Global Helpdesk Changes
      if (changes.new.globalHelpdeskGroup !== changes.old?.globalHelpdeskGroup) {
        this._getGroupMatches(
          changes.new.globalHelpdeskGroup,
          this.globalHelpdeskGroup,
          this.formData.globalHelpdeskGroup
        );
      }

      // Site Based Helpdesk Changes
      if (changes.new.siteBasedHelpdeskGroup !== changes.old?.siteBasedHelpdeskGroup) {
        this._getGroupMatches(
          changes.new.siteBasedHelpdeskGroup,
          this.siteBasedHelpdeskGroup,
          this.formData.siteBasedHelpdeskGroup
        );
      }
    });
  }

  private _getGroupMatches(newValue: string, group: LdapPermissionGroupUiState, formData: string) {
    group.matches = [];

    if (newValue) {
      group.viewMembersState = ViewMembersState.SEARCHING;

      this._searchLdapGroupMembers(newValue).subscribe({
        next: (ldapMembers: LdapMembers) => {
          if (newValue === formData) {
            group.matches = ldapMembers.members || [];
            group.viewMembersState = ViewMembersState.IDLE;
          }
        },
        error: (error) => this._setMatchesError(error, group),
      });
    }
  }

  private _searchLdapGroups(query: string): Observable<string[]> {
    return this.ldapResource.searchLdapGroups(query, 100).pipe(map((ldapGroups) => ldapGroups.groups));
  }

  private _searchLdapGroupMembers(query: string): Observable<LdapMembers> {
    return this.ldapResource.searchLdapGroupMembers(query, 1000);
  }

  private _clearViewMemberLists() {
    this.administratorsGroup.matches = [];
    this.globalHelpdeskGroup.matches = [];
    this.siteBasedHelpdeskGroup.matches = [];
  }

  private _setMatches(data: LdapMembers, group: LdapPermissionGroupUiState) {
    group.matches = data.members || [];
    group.viewMembersState = ViewMembersState.IDLE;
  }

  private _setMatchesError(error: any, group: LdapPermissionGroupUiState) {
    group.matches = [];
    group.viewMembersState = ViewMembersState.ERROR;
    group.errorMsg = this._getViewMembersErrorMessage(error.error.description);
  }

  private _getViewMembersErrorMessage(error: string) {
    if (error.includes('LDAP response read timed out')) {
      return ViewMembersErrorMessages.READ_TIMEOUT;
    } else if (error.toLowerCase().includes('connection')) {
      return ViewMembersErrorMessages.CONNECTION_TIMEOUT;
    } else {
      return ViewMembersErrorMessages.GENERIC_ERROR;
    }
  }
}
