import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MicrosoftTeamsAudioConferencing } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class MicrosoftTeamsAudioConferencingResource {
  constructor(private http: HttpClient) {}

  put(upn: string, request: MicrosoftTeamsAudioConferencing): Observable<void> {
    return this.http.put<void>(`/services/microsoft/macs/teams-audio-conferences/${upn}`, request);
  }
}
