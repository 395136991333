import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MicrosoftSecurityGroup, MicrosoftSecurityGroupResult } from '../../shared/models/generated/smacsModels';

@Injectable()
export class MicrosoftSecurityGroupsSearchResource {
  constructor(private _http: HttpClient) {}

  get(name: string): Observable<MicrosoftSecurityGroup> {
    return this._http.get<MicrosoftSecurityGroup>(`/services/microsoft/macs/security-groups/${name}`);
  }

  search(query: string, assignedOnly: boolean): Observable<MicrosoftSecurityGroupResult[]> {
    return this._http.get<MicrosoftSecurityGroupResult[]>(
      `/services/microsoft/macs/security-groups/search?q=${encodeURIComponent(query)}&assignedOnly=${assignedOnly}`
    );
  }
}
