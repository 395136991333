import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AboutCardService {
  private BASE_URL = 'https://learn.goziro.com';

  constructor(private http: HttpClient) {}

  getAboutCard(topicId: number): Observable<string | null> {
    return this.http.get(`${this.BASE_URL}/t/${topicId}.json`).pipe(
      catchError(() => of(null)),
      map((response: any) => {
        if (!response) return null;
        return response.post_stream.posts[0].cooked as string;
      })
    );
  }
}
