<div class="d-flex justify-content-end">
  <smacs-checkbox-button-field
    [id]="dynamicServiceName + '-' + 'oneClickEnabled'"
    [fieldId]="'oneClickEnabled'"
  ></smacs-checkbox-button-field>
</div>
<div *ngIf="!!serviceSettingToCopy" class="d-flex justify-content-end copy-from-service-button">
  <smacs-button
    [id]="dynamicServiceName + '-' + 'copyFromService'"
    data-automation="copy-from-service-button"
    [label]="
      'tkey;admin.site_management.copy_from_service.label' | translate : { serviceName: serviceSettingToCopy.name }
    "
    [buttonStyle]="ButtonStyles.DEFAULT"
    [icon]="SmacsIcons.COPY"
    (clicked)="copyFromServiceSetting()"
  ></smacs-button>
</div>
<smacs-select
  *ngFor="let field of serviceSettingFieldIds.selects; trackBy: trackByName"
  [fieldId]="field"
  [id]="dynamicServiceName + '-' + field"
  [attr.data-automation]="dynamicServiceName + '-' + field"
  [hidden]="getCommonFieldVisibility(field)"
></smacs-select>
<smacs-select
  *ngFor="let field of serviceSettingFieldIds.multiSelects; trackBy: trackByName"
  [fieldId]="field"
  [id]="dynamicServiceName + '-' + field"
  [attr.data-automation]="dynamicServiceName + '-' + field"
  [hidden]="getCommonFieldVisibility(field)"
></smacs-select>
<ziro-custom-input-text
  *ngFor="let field of serviceSettingFieldIds.customInputTexts; trackBy: trackByName"
  [fieldId]="field"
  [id]="dynamicServiceName + '-' + field"
  [attr.data-automation]="dynamicServiceName + '-' + field"
  [hidden]="getCommonFieldVisibility(field)"
></ziro-custom-input-text>
<ziro-custom-select
  *ngFor="let field of serviceSettingFieldIds.customSelects; trackBy: trackByName"
  [fieldId]="field"
  [id]="dynamicServiceName + '-' + field"
  [attr.data-automation]="dynamicServiceName + '-' + field"
  [hidden]="getCommonFieldVisibility(field)"
  [isServiceEnabled]="entity?.enabled === EnabledType.ENABLED || entity?.enabled === EnabledType.NOT_APPLICABLE"
></ziro-custom-select>
<smacs-multi-checkbox
  *ngFor="let field of serviceSettingFieldIds.customCheckboxes; trackBy: trackByName"
  [fieldId]="field"
  [id]="dynamicServiceName + '-' + field"
  [attr.data-automation]="dynamicServiceName + '-' + field"
  [hidden]="getCommonFieldVisibility(field)"
></smacs-multi-checkbox>
<ziro-custom-select
  *ngFor="let field of serviceSettingFieldIds.primeLineCustomSelects; trackBy: trackByName"
  [fieldId]="field"
  [id]="dynamicServiceName + '-' + field"
  [attr.data-automation]="dynamicServiceName + '-' + field"
  [hidden]="getCommonFieldVisibility(field)"
  [isServiceEnabled]="entity?.enabled === EnabledType.ENABLED || entity?.enabled === EnabledType.NOT_APPLICABLE"
></ziro-custom-select>
<ziro-custom-input-text
  *ngFor="let field of serviceSettingFieldIds.jabberTypeTextInputs; trackBy: trackByName"
  [fieldId]="field"
  [id]="dynamicServiceName + '-' + field"
  [attr.data-automation]="dynamicServiceName + '-' + field"
  [hidden]="getCommonFieldVisibility(field)"
></ziro-custom-input-text>
<ziro-async-multi-select-config
  *ngFor="let field of serviceSettingFieldIds.customMultiSelects; trackBy: trackByName"
  [fieldId]="field"
  [id]="dynamicServiceName + '-' + field"
  [attr.data-automation]="dynamicServiceName + '-' + field"
  [hidden]="getCommonFieldVisibility(field)"
></ziro-async-multi-select-config>
<smacs-checkbox
  *ngFor="let field of serviceSettingFieldIds.checkboxes; trackBy: trackByName"
  [fieldId]="field"
  [id]="dynamicServiceName + '-' + field"
  [attr.data-automation]="dynamicServiceName + '-' + field"
  [hidden]="getCommonFieldVisibility(field)"
></smacs-checkbox>
<ziro-custom-input-text
  *ngFor="let field of serviceSettingFieldIds.dataLocationTextInputs; trackBy: trackByName"
  [fieldId]="field"
  [id]="dynamicServiceName + '-' + field"
  [attr.data-automation]="dynamicServiceName + '-' + field"
  [hidden]="getCommonFieldVisibility(field)"
></ziro-custom-input-text>
<div *ngIf="isMobilityIdentity">
  <div [hidden]="!formData['mobility_identity_management']">
    <ziro-custom-input-text
      *ngFor="let field of mobilityIdentityFieldIds; trackBy: trackByName"
      [fieldId]="field"
      [id]="dynamicServiceName + '-' + field"
      [attr.data-automation]="dynamicServiceName + '-' + field"
      [hidden]="getCommonFieldVisibility(field)"
    ></ziro-custom-input-text>
  </div>
</div>
