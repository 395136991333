<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1 smacs-report-content">
    <app-breadcrumbs *ngIf="isReadOnly !== null">
      <app-breadcrumb-card-item>
        <div
          [innerHTML]="
            (isReadOnly
              ? 'tkey;menu.report.unused_phone_button_template.description.read_only'
              : 'tkey;menu.report.unused_phone_button_template.description'
            ) | translate
          "
        ></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <ng-container *ngIf="!isLoading && unusedPbts.length > 0">
      <smacs-datatable
        [columns]="columns"
        [data]="unusedPbts"
        [showSelectColumn]="!isReadOnly"
        [mainFilterPlaceholder]="
          ('tkey;menu.report.unused_phone_button_template.datatable.search.placeholder' | translate) + '...'
        "
        [isFirstColumnSticky]="true"
        (rowSelected)="setDeleteButtonState()"
        [dataAutomation]="tableDataAutomation"
      >
        <smacs-datatable-switch-filter
          [label]="'tkey;menu.report.unused_phone_button_template.toggle.label' | translate"
          [matchFn]="showAllFilter"
          [dataAutomation]="'show-all-switch-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-switch-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;menu.report.unused_phone_button_template.protocol.label'"
          [items]="protocolFilterOptions"
          [matchFn]="isProtocolMatch"
          [dataAutomation]="'unused-pbt-protocol-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;menu.report.unused_phone_button_template.cucm_server.label'"
          [items]="cucmServerFilterOptions"
          [matchFn]="isCucmServerMatch"
          [dataAutomation]="'unused-pbt-cucm-server-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
        <smacs-datatable-multi-select-filter
          [label]="'tkey;menu.report.unused_phone_button_template.type.label'"
          [items]="typeFilterOptions"
          [matchFn]="isTypeMatch"
          [dataAutomation]="'unused-pbt-type-filter'"
          [tableId]="tableDataAutomation"
        ></smacs-datatable-multi-select-filter>
      </smacs-datatable>
    </ng-container>
  </div>
</div>

<app-bottom-nav *ngIf="!isReadOnly"></app-bottom-nav>
