import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Microsoft365UserResult, UserPhoto } from '../../../shared/models/generated/smacsModels';

export interface UserPhotoSearchParams {
  photoSize: string;
  userPrincipalNames: string[];
}
@Injectable()
export class Microsoft365UserResource {
  constructor(private http: HttpClient) {}

  search(query: string): Observable<Microsoft365UserResult[]> {
    return this.http.get<Microsoft365UserResult[]>(`/services/microsoft/macs/365-users/search?q=${query}`);
  }

  advancedSearch(query: string): Observable<Microsoft365UserResult[]> {
    return this.http.get<Microsoft365UserResult[]>(
      `/services/microsoft/macs/365-users/search?advancedUserSearchQuery=${encodeURIComponent(query)}`
    );
  }

  userPhotoSearch(params: UserPhotoSearchParams): Observable<UserPhoto[]> {
    return this.http.post<UserPhoto[]>(`/services/microsoft/macs/user-photos/search`, params);
  }
}
