<div class="smacs-admin-page-content">
  <div class="col-md-10 offset-md-1">
    <app-breadcrumbs>
      <app-breadcrumb-card-item [aboutCardId]="66"></app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div
      *ngIf="
        bulkJobStatus &&
        (bulkJobStatus.jobStatus.jobState === jobState.RUNNING || bulkJobStatus.jobStatus.jobState === jobState.QUEUED)
      "
      class="alert alert-info"
      data-automation="in-progress-job-alert"
    >
      <p>
        <span
          [innerHTML]="
            'tkey;automate.zpm.bulk_provisioning.drafts.inprogress.alert.content'
              | translate : { jobName: bulkJobStatus?.jobDetailedStatus?.jobName }
          "
        ></span>
        <i class="{{ SmacsIcons.SAVING }} ps-1"></i>
        .
      </p>
      <a [routerLink]="'/automate/microsoft/bulk-provisioning/in-progress'" data-automation="ms-bulk-in-progress-link">
        <strong>{{ 'tkey;automate.zpm.bulk_provisioning.drafts.inprogress.alert.link' | translate }}</strong>
      </a>
    </div>

    <div
      *ngIf="
        !isRunning &&
        bulkJobStatus &&
        bulkJobStatus.jobStatus.jobState === jobState.IDLE &&
        bulkJobStatus.jobStatus.lastRunTime
      "
      class="alert alert-info"
      data-automation="last-completed-job-alert"
    >
      <span
        [innerHTML]="
          'tkey;automate.zpm.bulk_provisioning.previous_job.alert.previous'
            | translate : { auditTag: bulkJobStatus.jobDetailedStatus.jobName }
        "
      ></span>
    </div>

    <app-loading-block [hidden]="!isLoading"></app-loading-block>

    <div [hidden]="isLoading" data-automation="microsoft-bulk-drafts-table">
      <app-entity-table [table]="table" [tableRows]="tableRows"></app-entity-table>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
