import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, switchMap } from 'rxjs';
import { Global360View, SelfServeOptions } from '../../shared/models/generated/smacsModels';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Global360ViewContext } from '../../shared/contexts/global-360-view.context';

export interface SelfServeOptionsQueryParameters {
  extension: string;
  siteId: number;
}

@Injectable()
export class SelfServeOptionsContext {
  private _stateSource = new ReplaySubject<SelfServeOptions>(1);
  state$ = this._stateSource.asObservable();

  private _global360ViewState: Observable<Global360View>;

  constructor(private http: HttpClient, private global360ViewContext: Global360ViewContext) {
    this.refresh().subscribe();
  }

  refresh(): Observable<void> {
    // wipe the local global properties state, to kill off any lingering subscriptions from the last refresh.
    this._global360ViewState = this.global360ViewContext.state$;

    return this._global360ViewState.pipe(
      switchMap((global360View) => {
        let requestParams: SelfServeOptionsQueryParameters;
        if (
          global360View.phones.filter((phoneRef) => /^(SEP|SIP)/.test(phoneRef.name)).length === 0 &&
          global360View.primaryExtensions.length === 1 &&
          global360View.sites.length === 1
        ) {
          requestParams = {
            extension: global360View.primaryExtensions[0].extension,
            siteId: global360View.sites[0].id,
          };
        }
        return this._get(requestParams);
      }),
      map((selfServeOptions) => {
        this._stateSource.next(selfServeOptions);
      })
    );
  }

  private _get(params?: SelfServeOptionsQueryParameters): Observable<SelfServeOptions> {
    let url = '/services/cisco/field-configs/self-serve-options';
    if (params) {
      url += `?extension=${encodeURIComponent(params.extension)}&siteId=${params.siteId}`;
    }
    return this.http.get<SelfServeOptions>(url);
  }
}
