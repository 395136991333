import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DistributionList } from '../models/generated/smacsModels';

@Injectable()
export class DistributionListsResource {
  private _baseUrl = `/services/cisco/macs/unity-servers`;
  constructor(private $http: HttpClient) {}

  getDistributionList(serverId: string, id: number): Observable<DistributionList> {
    return this.$http.get<DistributionList>(`${this._baseUrl}/${serverId}/distribution-lists/${id}`);
  }

  addMemberToDistributionList(serverId: string, id: number, request: { id: string }): Observable<DistributionList> {
    return this.$http.post<DistributionList>(`${this._baseUrl}/${serverId}/distribution-lists/${id}/members`, {
      ...request,
    });
  }

  removeMemberFromDistributionList(serverId: string, id: number, memberId: string): Observable<void> {
    return this.$http.delete<void>(`${this._baseUrl}/${serverId}/distribution-lists/${id}/members/${memberId}`);
  }
}
