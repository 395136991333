import { Component, OnDestroy } from '@angular/core';

import { SystemStatusContext } from '../contexts/system-status.context';
import { CurrentUser, Role, State, SystemHealthStatus } from '../models/generated/smacsModels';
import { AuthenticationContext } from '../contexts/authentication.context';
import { Subscription } from 'rxjs';
import { SmacsIcons } from '../models/smacs-icons.enum';

@Component({
  selector: 'app-system-status-alert',
  templateUrl: './system-status-alert.component.html',
  styleUrls: ['./system-status-alert.component.scss'],
})
export class SystemStatusAlertComponent implements OnDestroy {
  State = State;
  state = State.OK;

  currentUser: CurrentUser;
  smacsIcons = SmacsIcons;
  private _subs = new Subscription();

  constructor(private systemStatusContext: SystemStatusContext, private authenticationContext: AuthenticationContext) {
    const systemStatusSub = this.systemStatusContext.state$.subscribe((systemHealthStatus: SystemHealthStatus) => {
      if (systemHealthStatus) {
        this.state = systemHealthStatus.globalState;
      }
    });
    this._subs.add(systemStatusSub);

    const authenticationContextSub = this.authenticationContext.state$.subscribe((user) => {
      this.currentUser = user;
    });
    this._subs.add(authenticationContextSub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  showSystemStatusAlert = (): boolean => {
    return (
      this.state !== State.OK &&
      this.currentUser &&
      this.authenticationContext.userIsAtLeast(this.currentUser, Role.S8_SITE_BASED_HELPDESK)
    );
  };

  redirectRoute(): string {
    return this.isAdminRoute() ? '/app2/#/admin/system-health-status' : '/app2/#/system-health-status';
  }

  isAdminRoute(): boolean {
    return this.currentUser && this.authenticationContext.userIsAtLeast(this.currentUser, Role.S8_ADMIN);
  }
}
