import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationContext } from '../shared/contexts/authentication.context';
import { CurrentUser, Role, SiteSummary } from '../shared/models/generated/smacsModels';
import { SmacsIcons } from '../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../button/button.component';
import { CurrentDateContext } from '../shared/contexts/current-date.context';
import { combineLatest, Subscription } from 'rxjs';
import { Moment } from 'moment';
import { Router } from '@angular/router';
import { SearchFieldConfigContext } from '../shared/contexts/search-field-config.context';
import { HelpdeskOptionsContext } from '../helpdesk/shared/contexts/helpdesk-options.context';
import { SiteSummaryContext } from '../shared/contexts/site-summary.context';
import { SvgLoaderContext } from '../shared/contexts/svg-loader.context';
import { GlobalPropertiesContext } from '../shared/contexts/global-properties.context';
import { SmacsModalService } from '../shared/services/smacs-modal.service';
import {
  ReleaseNotesModalComponent,
  ReleaseNotesModalViewProperties,
} from '../shared/release-notes/release-notes-modal.component';
import { ReleaseNotesService } from '../shared/release-notes/release-notes.service';
import { CustomUiOptionsContext } from '../shared/contexts/custom-ui-options.context';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  buttonStyle = ButtonStyles;
  smacsIcons = SmacsIcons;
  buildVersion: string;
  isAddUserEnabled: boolean;
  isAdminOrGlobalHelpdesk: boolean;
  isAdminOrHelpdesk: boolean;
  year: number;
  isLoading = true;
  isCucmEnabled: boolean;
  isHosted: boolean;
  searchText: string;
  isDeskphoneEnabledOnAnyClusters: boolean;
  searchPlaceholder: string;
  zpmSearchErrorMessage: string;
  hideSearchErrorMessage: boolean;
  provisioningEnabled = false;
  proxyServer = false;
  releaseNotesUrl = 'https://learn.goziro.com/c/release-notes/5';

  private _isReleaseModalOpen = false;
  private _currentUser: CurrentUser;
  private _subscriptions = new Subscription();

  constructor(
    private authenticationContext: AuthenticationContext,
    private globalPropertiesContext: GlobalPropertiesContext,
    private helpdeskOptionsContext: HelpdeskOptionsContext,
    private currentDateContext: CurrentDateContext,
    private searchFieldConfigContext: SearchFieldConfigContext,
    private router: Router,
    private siteSummaryContext: SiteSummaryContext,
    private svgLoaderContext: SvgLoaderContext,
    private smacsModalService: SmacsModalService,
    private releaseNotesService: ReleaseNotesService,
    private uiOptionsContext: CustomUiOptionsContext
  ) {}

  ngOnInit() {
    const contextSub = combineLatest([
      this.authenticationContext.state$,
      this.helpdeskOptionsContext.state$,
      this.currentDateContext.state$,
      this.searchFieldConfigContext.state$,
      this.globalPropertiesContext.state$,
      this.uiOptionsContext.state$,
    ]).subscribe(([user, helpdeskOptions, currentDateContextState, searchFieldConfig, globalProperties, uiOptions]) => {
      this.isCucmEnabled = searchFieldConfig.cucmSearchEnabled;
      this.isAddUserEnabled =
        this.helpdeskOptionsContext.isCiscoHelpdeskOptions(helpdeskOptions) && helpdeskOptions.showAddUserPage;
      this._currentUser = user;
      this.buildVersion = globalProperties.version;
      this.isHosted = globalProperties.hostedEnabled;
      this.isAdminOrGlobalHelpdesk = this.authenticationContext.userIsAtLeast(
        this._currentUser,
        this.isHosted ? Role.S8_HELPDESK : Role.S8_GLOBAL_HELPDESK
      );
      this.isAdminOrHelpdesk = this.authenticationContext.userIsAtLeast(this._currentUser, Role.S8_SITE_BASED_HELPDESK);
      this.year = (currentDateContextState.utcTimestamp as Moment).year();
      this.searchPlaceholder = this.isHosted ? 'tkey;search.placeholder.zpm.text' : 'tkey;search.placeholder.zpc.text';
      this.provisioningEnabled = uiOptions.provisioningEnabled;
      this.proxyServer = uiOptions.proxyServerOnlyModeEnabled;

      this._checkForReleaseNotes(this.releaseNotesService.getLocalStorageVersion(), this.buildVersion);

      if (this.isCucmEnabled) {
        this._getSiteSummaryContextState();
      } else {
        this.isLoading = false;
      }

      this.svgLoaderContext.init();
      this.svgLoaderContext.loadSvgs().subscribe();
    });
    this._subscriptions.add(contextSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  searchByString() {
    const query = this.searchText?.trim() ?? '';
    if (!this.isHosted || query.length > 2) {
      this.router.navigate(['/search'], { queryParams: { query: query } });
    } else {
      this.zpmSearchErrorMessage = 'tkey;home.search.error.text';
    }
  }

  updateSearchError() {
    this.hideSearchErrorMessage = this.searchText && this.searchText.trim().length > 2;
  }

  private _getSiteSummaryContextState() {
    const siteSummaryContextSubscription = this.siteSummaryContext.state$.subscribe((state: SiteSummary) => {
      this.isDeskphoneEnabledOnAnyClusters = this.siteSummaryContext.isDeskphoneEnabledOnAnyClusters(state);
      this.isLoading = false;
    });
    this._subscriptions.add(siteSummaryContextSubscription);
  }

  private _checkForReleaseNotes(localStorageVersion: string, buildVersion: string) {
    if (
      this.isHosted &&
      this.releaseNotesService.isNewVersion(localStorageVersion, buildVersion) &&
      !this._isReleaseModalOpen
    ) {
      this._isReleaseModalOpen = true;
      this.releaseNotesService
        .getVersionReleaseNotes(buildVersion)
        .subscribe((releaseNote: ReleaseNotesModalViewProperties) => {
          if (releaseNote) {
            this.releaseNotesUrl = `https://learn.goziro.com/t/${releaseNote.slug}`;
            this.smacsModalService
              .openCustomModal(
                ReleaseNotesModalComponent,
                { html: releaseNote.html, title: releaseNote.title, slug: releaseNote.slug },
                'lg'
              )
              .subscribe(() => {
                this.releaseNotesService.setVersion(buildVersion);
                this._isReleaseModalOpen = false;
              });
          } else {
            this._isReleaseModalOpen = false;
          }
        });
    }
  }
}
