import { Component, Input, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-multi-step-animation',
  templateUrl: './multi-step-animation.component.html',
})
export class MultiStepAnimationComponent implements OnInit {
  @Input() currentStep = 1;
  @Input() steps: AnimationOptions[];
  @Input() animationStart$ = new Observable<number>();
  replaySubjects: Subject<void>[];

  ngOnInit() {
    this.replaySubjects = this.steps.map(() => new Subject<void>());
    this.animationStart$.subscribe((step: number) => {
      if (this.replaySubjects[step - 1]) {
        this.currentStep = step;
        this.replaySubjects[step - 1].next();
      }
    });
  }
}
