import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {
  MicrosoftPolicyMisconfigurationReport,
  SharedCallingRoutingPolicy,
  TeamsVoicePolicies,
} from '../../../shared/models/generated/smacsModels';

export interface TeamsPoliciesUntyped extends TeamsVoicePolicies {
  [key: string]: string | SharedCallingRoutingPolicy;
}

@Injectable()
export class PolicyMisconfigurationResource {
  private _baseUrl = `/services/microsoft/reporting/policy-misconfiguration`;

  constructor(private _http: HttpClient) {}

  get(): Observable<MicrosoftPolicyMisconfigurationReport[]> {
    return this._http.get<MicrosoftPolicyMisconfigurationReport[]>(`${this._baseUrl}`);
  }

  refresh(): Observable<void> {
    return this._http.post<void>(`${this._baseUrl}/refresh`, null);
  }

  updatePolicy(upn: string, requestBody: TeamsPoliciesUntyped): Observable<void> {
    return this._http.put<void>(`/services/microsoft/macs/teams-voice-policies/${upn}`, requestBody);
  }
}
