import { Component, OnInit } from '@angular/core';
import { DirectoryNumberRef, EndUser } from '../../shared/models/generated/smacsModels';
import { EndUserResource } from '../../shared/resources/end-user.resource';
import { ModalBodyClass } from '../modal-body';
import { SmacsFormConfig } from '../../forms/smacs-forms-models';
import { PromptModalComponent } from '../prompt-modal/prompt-modal.component';
import { SmacsFormStateService } from '../../forms/smacs-form-state.service';
import { SmacsSelectConfig } from '../../forms/fields/select/smacs-select.component';
import { Observable } from 'rxjs';
import { ToastService } from '../../shared/services/toast.service';

@Component({
  selector: 'smacs-change-primary-extension-modal',
  templateUrl: './change-primary-extension.modal.component.html',
})
export class ChangePrimaryExtensionModalComponent extends ModalBodyClass implements OnInit {
  protected formConfig: SmacsFormConfig;
  selectedExtension: string;
  passedCandidates: DirectoryNumberRef[];

  constructor(
    private endUserResource: EndUserResource,
    private modalComponent: PromptModalComponent<any>,
    protected smacsFormStateService: SmacsFormStateService,
    private _toastService: ToastService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.passedCandidates = this.modalComponent.modalViewProperties.candidates;
    const candidateExtensions = this.passedCandidates.map((candidate) => {
      return candidate.extension;
    });
    this.formConfig = {
      fields: {
        changePrimaryExtension: {
          dataAutomation: 'smacs-select-input',
          componentConfig: new SmacsSelectConfig({
            options: candidateExtensions,
            bindValue: 'extension',
            clearWithInput: true,
            hideClear: true,
          }),
          required: true,
        },
      },
      options: {
        columnClasses: {
          label: 'd-none',
          input: 'col-12',
        },
      },
    } as SmacsFormConfig;
  }

  submit(): Observable<DirectoryNumberRef> {
    this.selectedExtension = this.formData.changePrimaryExtension;
    const selectedDirectoryNumberRef = this.passedCandidates.find((extension) => {
      return extension.extension === this.selectedExtension;
    });
    return new Observable((subscriber) => {
      this.endUserResource
        .get(
          this.modalComponent.modalViewProperties.endUserResult.ref.id,
          this.modalComponent.modalViewProperties.endUserResult.ref.serverId
        )
        .subscribe((endUser: EndUser) => {
          endUser.primaryExtension = selectedDirectoryNumberRef;
          this.endUserResource
            .put(endUser, this.modalComponent.modalViewProperties.endUserResult.ref.serverId)
            .subscribe((endUserRef) => {
              subscriber.next(selectedDirectoryNumberRef);
              subscriber.complete();
            });
        });
    });
  }
}
