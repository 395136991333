<div
  class="service-setting-container"
  [attr.data-automation]="dynamicServiceName + '-service-setting'"
  [ngClass]="{
    'common-fields-scroll-spacing': commonFieldScrollSpacing
  }"
>
  <smacs-collapsible-card
    (toggleSwitch)="toggleService()"
    [label]="getServiceNameTkey(serviceSetting.name)"
    [shouldBeOpen]="serviceSetting.enabled !== EnabledType.DISABLED"
    [dataAutomation]="'site-service-card'"
    [switchId]="serviceSetting.name"
    [isSwitchHidden]="serviceSetting.enabled === EnabledType.NOT_APPLICABLE && serviceSetting.name !== 'IM Presence'"
    [isDisabled]="serviceSetting.name === 'IM Presence'"
    [disabledTooltip]="impDisabledTooltip"
    [isDisabledAndChecked]="serviceSetting.name === 'IM Presence' && serviceSetting.enabled === EnabledType.ENABLED"
    [autoScrollOnOpen]="false"
  >
    <ziro-site-management-service-form
      *ngIf="!isLoading"
      [entity]="serviceSetting"
      [formConfig]="formConfigInput"
      [serviceSettingFieldIds]="serviceSettingFieldIds"
      [dynamicServiceName]="dynamicServiceName"
      [unityMetadata]="serviceSetting.name === 'Voicemail' ? unityMetadata : null"
      [unityServerOptions]="unityServerOptions"
      [serviceSettingToCopy]="serviceSettingToCopy"
      [commonFieldsState]="commonFieldsState"
      (smacsFormsUpdate$)="onFormUpdate($event)"
    ></ziro-site-management-service-form>
  </smacs-collapsible-card>
</div>
