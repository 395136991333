import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import {
  isSmacsFieldValidationResult,
  SmacsFieldComponentConfig,
  SmacsFieldConfig,
  SmacsFieldState,
  SmacsFieldValidationResult,
  SmacsFormsMessage,
  SmacsFormsUpdate,
  SmacsFormsValidationConfigItem,
  SmacsFormsValidationResult,
  SmacsFormsValidationState,
  SmacsMessageOrFunction,
} from './smacs-forms-models';
import { asyncScheduler, isObservable, Observable, of, scheduled, Subject, timer } from 'rxjs';
import { concatAll, debounce, map, switchMap, tap } from 'rxjs/operators';
import { cloneDeep, isEqual, uniqWith } from 'lodash';
import { NgModel } from '@angular/forms';
import { SmacsFormStateService } from './smacs-form-state.service';
import { SmacsIcons } from '../shared/models/smacs-icons.enum';

interface AsyncValidationObservable {
  obs: Observable<SmacsFormsValidationResult>;
  config: SmacsFormsValidationConfigItem;
}

interface SyncValidationUpdate {
  syncValidationState: SmacsFormsValidationState;
  lastRunValidationConfig: SmacsFormsValidationConfigItem;
  asyncValidatorObs: AsyncValidationObservable[];
}

export interface FullValidationUpdate {
  status: SmacsFormsValidationState;
  message: SmacsMessageOrFunction;
}

/**
 * A SMACS Field is a component that is meant to be - and can only be - used inside of a SMACS Form. At its most basic,
 * a field takes data from its parent form and notifies its parent whenever its value is changed.
 *
 * E -- The type of the entity, i.e. the data sent to and from the parent form.
 *
 * F -- The type of the fieldData, i.e. the model used internally by the field.
 *
 * C -- The type of the component config, i.e. arguments that are passed to the field to specialize it.
 *
 * @see SmacsFormAbstractDirective
 */
@Directive({
  selector: '[smacsFieldAbstract]',
})
export abstract class SmacsFieldAbstractDirective<E, F = E, C extends SmacsFieldComponentConfig = null>
  implements OnDestroy
{
  /** This is how the parent form knows which {@link SmacsFormAbstractDirective.fieldComponents} item this field
   * represents. This must match one of the keys in the parent form's *formConfig*. */
  @Input() fieldId: string;

  /** If you programmatically modify the *fieldData*, you may have to call this via {@link updateSelf}. */
  protected _selfUpdateSource = new Subject<F>();
  selfUpdate$ = this._selfUpdateSource.asObservable();
  /** If you programatically modify the *entity*, you may have to call this via {@link updateParent}. */
  private _smacsFormsUpdateSource = new Subject<SmacsFormsUpdate<E>>();
  /** The async validation debounce time, specified in the *formConfig*. */
  private _actualDebounceTime = 0;
  /** The default debounce time for async validation if no debounce time is specified in the config */
  private _defaultDebounceTime = 500;
  forceValidation$: Observable<void>;

  /** This is meant to control the visibility of the auto-generate link present on certain fields,
   * via {@link isAutogenerateLinkHidden}. */
  isAutogenerated = false;
  /** While this is false, {@link showValidation} and {@link showAsyncValidation} will remain false as well. */
  isDirty = false;
  /** If a validator or warning validator ever fails for the field, we want to ensure it is shown forever. */
  forceShow = false;

  /** This is the observable to which the parent form subscribes, in order to receive changes from the field. */
  smacsFormsUpdate$ = this._smacsFormsUpdateSource.asObservable();

  // These members are defined by the parent form when this field is initialized.
  /** The field uses this observable to receive {@link entity} updates from its parent form.
   * @see _onReceiveNewEntity */
  entity$: Observable<E>;
  /** When the parent form updates the component config (for example on initialization), it pushes an update here.
   * @see applyComponentConfig */
  componentConfig$: Observable<C>;
  /** The parent form uses this to control this field's state, i.e. hidden/disabled/required/excluded.
   * @see applyState */
  state$: Observable<SmacsFieldState>;
  /** The parent form can emit to this observable to force this field to validate itself. */
  validate$: Observable<void>;
  /** The static field config laid out in the parent form's *formConfig*. This is different from *componentConfig*
   * in that this represents the config common to all fields. */
  config = {} as SmacsFieldConfig<C>;
  /** When wanting to perform cross-field operations, such as validation or auto-generation based on the value of
   * another field, you must use this method.
   * @function evaluateWithForm
   * @param cb The callback which performs the operation. The first arg is the value of this field, and other args will
   * be present if *injectValuesFromFields* is defined.
   * @param fieldVal The value of this field, i.e. {@link fieldData}.
   * @param injectValuesFromFields For every *fieldId* in this array, the parent form will inject an arg into the
   * callback. The value injected will be the *entity* of the field identified by the given fieldId. */
  evaluateWithForm: (cb: (...any: any) => any, fieldVal: any, injectValuesFromFields: string[]) => any;
  /** Used by parent form to emit new value for isExisting. */
  isExisting$: Observable<boolean>;

  /** The *entity* is what is sent to and from the parent form. */
  entity: E;
  /** The *fieldData* is what is used internally by the field for validation, etc. */
  fieldData: F;
  ValStates = SmacsFormsValidationState;

  // These members are to be used in the HTML template of implementing components.
  /** The state tells us whether the field should be hidden/disabled/required/excluded. */
  state = {} as SmacsFieldState;
  /** If this is false, validation feedback should be hidden. This is used most often when loading a required
   * field in a blank form. */
  showValidation = false;
  /** If this is false, async validation feedback (for example a spinner) should be hidden. */
  showAsyncValidation = false;
  /** Whether the field is considered VALID, INVALID, or PENDING. */
  validationState: SmacsFormsValidationState;
  /** If a validator has specified a message (or success message), it will be applied here after the validator runs. */
  validationMessage: SmacsFormsMessage;
  /** If a misconfiguration validator has specified a message, it will be applied here if the validator does not pass. */
  misconfigurationFeedbackMessage: SmacsFormsMessage;
  /** If the form was populated using existing values. */
  isExisting = false;

  /** Add this to the disableTooltip property of a custom field template to show it when field is disabled. */
  shouldDisableTooltip = true;

  /** If this is true, forces validation to be shown, regardless of {@link showValidation}. */
  isFormSubmitted: boolean;

  /** Used to emit if field is in pending state to parent form. */
  isAsyncValidationRunning = new Subject<boolean>();

  isCustomSelect = false;

  smacsIcons = SmacsIcons;
  updateShadowValue$: Observable<any>;
  private _init = new Subject<void>();
  init$ = this._init.asObservable();

  isValidationInProgress = false;
  protected validationInProgressMessage: string = null;

  protected requiredFeedbackMessage = 'tkey;validators.global.required.error';

  protected constructor(protected smacsFormStateService: SmacsFormStateService) {}

  /** This is called by the parent form to initialize the field component. */
  init() {
    this._actualDebounceTime = this.config.debounceTime;

    this.entity$.subscribe((entity) => this._onReceiveNewEntity(entity));
    this.componentConfig$.subscribe(this.applyComponentConfig);
    this.state$.subscribe(this.applyState);
    this.validate$.subscribe(() => {
      // run full validation
      this._selfUpdateSource.next(this.fieldData);
    });
    this.isExisting$.subscribe((isExisting: boolean) => (this.isExisting = isExisting));

    // Perform full validation whenever the field is updated by itself.
    this.selfUpdate$
      .pipe(
        tap(this._validateMisconfigFeedback),
        map((newFieldData: F) => this._runSyncValidation(newFieldData)),
        tap(() => (this._actualDebounceTime ? this._setPending() : void 0)),
        debounce(() => timer(this._actualDebounceTime)),
        switchMap((syncValidationUpdate: SyncValidationUpdate) => this._runAsyncValidation(syncValidationUpdate)),
        tap(this._updateValidationView)
      )
      .subscribe();

    if (this.config.disabled && this.config.disabledTooltip) {
      this.shouldDisableTooltip = !this.config.disabled();
    }

    this._init.next();
    this._init.complete();
  }

  /** This must be implemented if the class's generic params (E & F) are not the same. Transforms the field's internal
   * state into the type that the parent form component expects. Typically this is called after every update.
   * @see toFieldData */
  protected toEntity = (fieldData: F): E => cloneDeep<any>(fieldData) as E;
  /** This must be implemented if the class's generic params (E & F) are not the same. Transforms the entity passed down
   * from the parent form into a type that is usable by the field. Typically this is called whenever the entity is updated.
   * @see toEntity */
  protected toFieldData = (entity: E): F => cloneDeep<any>(entity) as F;
  /** This must be implemented if your component config type (C) is not null. This method receives the new component
   * config and saves it so that the field may use it. */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  applyComponentConfig: (config: C) => void = () => {};

  /** The warning validation message for autogeneration / misconfiguration. */
  misconfigurationWarningMessage: (val?: any) => string | SmacsFormsMessage = () =>
    'tkey;smacs_forms.misconfiguration_feedback';

  /** Call this when the user wishes to fire an auto-generation event. */
  autogenerateValue() {
    if (this.config && this.config.autogeneration && this.config.autogeneration.generateValue) {
      const newVal = this.evaluateWithForm(
        this.config.autogeneration.generateValue,
        this.fieldData,
        this.config.autogeneration.injectValuesFromFields
      );

      this.updateSelf(newVal);
      this.isAutogenerated = true;
    }
  }

  /** If true, the user should not be able to fire auto-generation events. */
  isAutogenerateLinkHidden() {
    return (
      !this.config.autogeneration ||
      this.isAutogenerated ||
      (this.config.autogeneration.inline &&
        !this.misconfigurationFeedbackMessage &&
        !this.showValidation &&
        !this.isFormSubmitted) ||
      this.evaluateWithForm(
        this.config.autogeneration.hidden,
        this.fieldData,
        this.config.autogeneration.injectValuesFromFields
      )
    );
  }

  /** Returns the function used for hiding the defaultValue autogeneration link and warning. Override as needed. */
  hideDefaultValueAutogenerationFnFactory(defaultVal: F): (val: F) => SmacsFormsValidationState {
    return (val: F) => (val === defaultVal ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID);
  }

  /* Gets help text value or returns value of help text function */
  getHelpText(): string {
    return typeof this.config.helpText === 'function' ? this.config.helpText() : this.config.helpText;
  }

  getLabel(): string {
    return typeof this.config.label === 'function' ? this.config.label() : this.config.label;
  }

  getDisabledTooltip(): SmacsFormsMessage {
    if (this.config.inputToolTipText) {
      return this._toSmacsFormMessage(this.config.inputToolTipText);
    } else if (typeof this.config.disabledTooltip === 'function') {
      return this._toSmacsFormMessage(this.config.disabledTooltip());
    } else {
      return this._toSmacsFormMessage(this.config.disabledTooltip);
    }
  }

  /** After validation runs, this updates the field's validation state depending on the result of the validators. */
  protected _updateValidationView = (valUpdate: FullValidationUpdate) => {
    if (valUpdate) {
      this.validationState = valUpdate.status;
      this.validationMessage = this._toSmacsFormMessage(valUpdate.message);

      this.isAsyncValidationRunning.next(false);
      this.isValidationInProgress = false;
      if (valUpdate.status !== SmacsFormsValidationState.VALID) {
        this.forceShow = true;
      }

      this.updateParent();
      if (this.isDirty) {
        this.showValidation = true;
      }
    }
  };

  /** PENDING validation state is used to indicate that we are awaiting a response to an async validator. */
  private _setPending = () => {
    this.validationState = SmacsFormsValidationState.PENDING;
    this.validationMessage =
      this.validationInProgressMessage !== null
        ? this._toSmacsFormMessage(this.validationInProgressMessage)
        : this._toSmacsFormMessage('tkey;validators.global.pending');
    if (this.isDirty) {
      this.showValidation = true;
    }
    this.isValidationInProgress = this.validationMessage !== null;
    this.isAsyncValidationRunning.next(this.validationMessage !== null);
  };

  /** Applies a new state. In this way, we can change hidden/disabled/required/excluded during runtime. */
  applyState = (newState: SmacsFieldState) => {
    const oldState = this.state;
    this.state = newState;

    if (!oldState.required && newState.required) {
      this._addRequiredValidator();
      // run full validation
      this._selfUpdateSource.next(this.fieldData);
    } else if (oldState.required && !newState.required) {
      this.config.validation = (this.config.validation || []).filter((v) => v.validator !== this.exists);
      // run full validation
      this._selfUpdateSource.next(this.fieldData);
    }

    // check whether there is a new default
    if (newState.defaultValue != null && newState.defaultValue !== oldState.defaultValue) {
      const defaultValue = newState.defaultValue;

      this.config.autogeneration = {
        linkLabel: 'tkey;shared.html.customizable_fields.text_input.fix_it.label',
        generateValue: (val) => (Array.isArray(val) ? uniqWith([...val, ...defaultValue], isEqual) : defaultValue),
        hidden: (val) => {
          return this.hideDefaultValueAutogenerationFnFactory(defaultValue)(val) === SmacsFormsValidationState.VALID;
        },
        inline: true,
      };

      // remove existing warning
      if (oldState.defaultValue != null) {
        this.config.misconfigurationFeedback.filter((wv) => wv.message !== this.misconfigurationWarningMessage);
      }
      this.config.misconfigurationFeedback = [
        {
          validator: this.hideDefaultValueAutogenerationFnFactory(defaultValue),
          message: () => this.misconfigurationWarningMessage(this.fieldData),
        },
        ...(this.config.misconfigurationFeedback || []),
      ];
    }
  };

  /** Adds the "required" validator. */
  protected _addRequiredValidator() {
    this.config.validation = [
      {
        validator: this.exists,
        message: this.requiredFeedbackMessage,
      },
      ...(this.config.validation || []),
    ];
  }

  /** The base required validator. Can be overridden. */
  protected exists(value: E): SmacsFormsValidationState {
    if (value === null || value === undefined) {
      return SmacsFormsValidationState.INVALID;
    }

    if (typeof value === 'string') {
      return value.trim().length > 0 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
    }

    if (Array.isArray(value)) {
      return value.length > 0 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
    }

    return SmacsFormsValidationState.VALID;
  }

  /** Called whenever the parent form pushes a new entity to the field. */
  protected _onReceiveNewEntity(newEntity: E) {
    if (this.exists(newEntity) === SmacsFormsValidationState.VALID) {
      this.isDirty = true;
    }
    this.isAutogenerated = false;
    this.fieldData = this.toFieldData(newEntity);
    this._selfUpdateSource.next(this.fieldData);
  }

  /** Runs warning validators. Note that this is entirely separate from normal validators; it is entirely possible
   * to have a warning message and error message appear at once on one field. */
  private _validateMisconfigFeedback = (newFieldData: F) => {
    const warningSyncIsValid =
      !this.config.misconfigurationFeedback ||
      this.config.misconfigurationFeedback.every((config) => {
        const result = this.evaluateWithForm(config.validator, newFieldData, config.injectValuesFromFields);
        if (result !== SmacsFormsValidationState.VALID) {
          this.misconfigurationFeedbackMessage = this._toSmacsFormMessage(config.message);
        }
        return result === SmacsFormsValidationState.VALID;
      });

    if (warningSyncIsValid) {
      this.misconfigurationFeedbackMessage = null;
    } else {
      this.forceShow = true;
    }
  };

  /** Despite the name, this method runs all validation. However, it does not wait for async validation to complete.
   * Short-circuits on the first failed synchronous validator, so do not count on all validators running every time.
   * If async validators are present, this method will NOT short-circuit on them and does not guarantee the order
   * in which they return, so beware using multiple async validators on one field. */
  private _runSyncValidation = (newFieldData: F): SyncValidationUpdate => {
    const asyncValidatorObs: AsyncValidationObservable[] = [];

    let lastRunValidationConfig = null;

    let syncValidationState = SmacsFormsValidationState.VALID;
    if (this.config.validation) {
      this.config.validation.every((config) => {
        const result = this.evaluateWithForm(config.validator, newFieldData, config.injectValuesFromFields);
        if (!isObservable(result)) {
          lastRunValidationConfig = config;
          syncValidationState = result;
          return result !== SmacsFormsValidationState.INVALID;
        } else {
          asyncValidatorObs.push({
            obs: result as any,
            config,
          });
          return true;
        }
      });
    }

    if (syncValidationState === SmacsFormsValidationState.VALID) {
      if (this.config.debounceTime != null) {
        this._actualDebounceTime = this.config.debounceTime;
      } else {
        this._actualDebounceTime = asyncValidatorObs.length > 0 ? this._defaultDebounceTime : 0;
      }
      if (asyncValidatorObs.length && this.isDirty) {
        this.showAsyncValidation = true;
      }
    } else {
      this._actualDebounceTime = 0;
    }

    return {
      asyncValidatorObs,
      syncValidationState,
      lastRunValidationConfig,
    };
  };

  /** This method does not actually run async validators. It simply waits for the observables returned by those
   * validators to emit a value. If no async validators were run, this method simply returns an observable of the
   * sync validation state. */
  private _runAsyncValidation = ({
    syncValidationState,
    asyncValidatorObs,
    lastRunValidationConfig,
  }: SyncValidationUpdate): Observable<FullValidationUpdate> => {
    let validationObs: Observable<FullValidationUpdate>;
    this.isValidationInProgress = true;
    this.isAsyncValidationRunning.next(true);
    this._setPending();

    if (
      syncValidationState !== SmacsFormsValidationState.INVALID &&
      syncValidationState !== SmacsFormsValidationState.WARNING
    ) {
      if (asyncValidatorObs.length) {
        validationObs = this._combine(asyncValidatorObs);
      } else {
        validationObs = of({
          status: SmacsFormsValidationState.VALID,
          message: lastRunValidationConfig && lastRunValidationConfig.successMessage,
        });
      }
    } else {
      validationObs = of({
        status: syncValidationState,
        message: lastRunValidationConfig.message,
      });
    }

    return validationObs;
  };

  /** Maps validation messages to instances of {@link SmacsFormsMessage}. Note that if you want the message to be
   * dynamic (i.e. set at runtime), the validator message must be a function. */
  protected _toSmacsFormMessage = (msg: SmacsMessageOrFunction): SmacsFormsMessage => {
    return typeof msg === 'string'
      ? { content: msg, params: {} }
      : typeof msg === 'function'
      ? typeof msg() === 'string'
        ? { content: msg() as string, params: {} }
        : (msg() as SmacsFormsMessage)
      : msg;
  };

  /** Combines async validator observables into a single observable. IMPORTANT: If there are multiple async validators,
   * this method will short-circuit if any return false. There are no guarantees as to order, so if you choose to
   * include multiple async validators in your field component, beware! */
  private _combine = (obsConfig: AsyncValidationObservable[]): Observable<FullValidationUpdate> => {
    return new Observable<FullValidationUpdate>((sub) => {
      let concatCount = 0;
      let validatorsCompleteCount = 0;

      const innerSub = scheduled([obsConfig.map((o) => o.obs)], asyncScheduler)
        .pipe(concatAll())
        .subscribe((result) => {
          const obsIndex = concatCount++;
          let validationState;
          let message;

          result.subscribe((data) => {
            if (isSmacsFieldValidationResult(data)) {
              validationState = data.isValid ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID;
              message = (data as SmacsFieldValidationResult).message;
            } else {
              validationState = data;
              message = this._toSmacsFormMessage(
                validationState === SmacsFormsValidationState.VALID
                  ? obsConfig[obsIndex].config.successMessage
                  : obsConfig[obsIndex].config.message
              );
            }
            const isLast = obsConfig.length - 1 === validatorsCompleteCount;

            sub.next(
              validationState === SmacsFormsValidationState.INVALID || isLast
                ? {
                    status: validationState,
                    message,
                  }
                : null
            );
            validatorsCompleteCount++;

            if (validationState === SmacsFormsValidationState.INVALID || isLast) {
              sub.complete();
              innerSub.unsubscribe();
            }
          });
        });
    });
  };

  /** This should be called whenever you programmatically update the *fieldData* from the field. */
  updateSelf(newFieldData: F, markAsDirty = true, oldFieldData?: F) {
    // Can't use fieldData as that is directly changed by ngModel in some fields, it will always equal newFieldData
    const oldEntity = oldFieldData || cloneDeep(this.entity);

    if (markAsDirty) {
      this._markFieldAsDirty();
      if (this.state.canModifyFormState && !isEqual(oldEntity, newFieldData)) {
        this._markFormAsDirty();
      }
    }

    this.fieldData = newFieldData;
    this._selfUpdateSource.next(newFieldData);
    this.isAutogenerated = false;
  }

  /** This is a better version of {@link updateSelf}, but can only be used if you are using `ngModel` in your field. */
  updateStateAndSelf(newFieldData: F, ngModelDir: NgModel) {
    ngModelDir.control.markAsTouched();
    this.updateSelf(newFieldData);
  }

  /** Sets *isDirty* to true, so that the field component knows to display validation feedback. */
  private _markFieldAsDirty() {
    this.isDirty = true;
  }

  /** Sets smacsFormStateService dirty state to true so confirm navigation modal displays */
  private _markFormAsDirty() {
    this.smacsFormStateService.setIsFormDirty(true);
  }

  /** Whenever the field is updated via {@link updateSelf}, this method is called to update the parent form's *formData*. */
  protected updateParent(old?: F) {
    const oldEntity = old || this.entity;
    this.entity = this.toEntity(this.fieldData);

    this._smacsFormsUpdateSource.next({
      new: this.entity,
      old: oldEntity,
      valid: this.validationState,
    } as SmacsFormsUpdate<E>);
  }

  ngOnDestroy() {
    this._smacsFormsUpdateSource.complete();
    this._selfUpdateSource.complete();
  }

  @HostListener('window:beforeunload', ['$event'])
  discardChanges(event: BeforeUnloadEvent) {
    if (this.smacsFormStateService.getIsFormDirty()) {
      event.preventDefault();
      event.returnValue = 'Unsaved changes';
      return event.returnValue;
    }
  }
}
