import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Audit } from '../../../shared/models/generated/smacsModels';
import { CustomModal } from '../../../shared/services/smacs-modal.service';

interface AuditErrorViewModalViewProperties {
  audit: Audit;
}

@Component({
  selector: 'app-audit-error-view-modal',
  templateUrl: './audit-error-view-modal.component.html',
  styleUrls: ['./audit-error-view-modal.component.scss'],
})
export class AuditErrorViewModalComponent implements CustomModal<AuditErrorViewModalViewProperties>, OnInit {
  modalProperties: AuditErrorViewModalViewProperties;
  audit: Audit;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.audit = this.modalProperties.audit;

    this.audit.errorDetails = this.toStackTrace(this.audit.errorDetails);
  }

  onOkClick(): void {
    this.activeModal.close(true);
  }

  toStackTrace(errorDetail: string): string {
    let stacktrace = '';
    let errorDetailsList = [];

    if (errorDetail.charAt(0) === '[' && errorDetail.charAt(errorDetail.length - 1) === ']') {
      errorDetailsList = errorDetail.slice(0, errorDetail.length - 1).split(', ');
    } else {
      errorDetailsList = errorDetail.split(', ');
    }

    errorDetailsList.forEach((error) => (stacktrace += error + '\n'));
    return stacktrace;
  }
}
