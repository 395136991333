import { Component, Input } from '@angular/core';
import { SmacsIcons } from '../models/smacs-icons.enum';

export enum DashboardTileStatus {
  CRITICAL = 'Critical',
  WARNING = 'Warning',
  NEUTRAL = 'Neutral',
  GOOD = 'Good',
}

export interface DashboardTile {
  message: string;
  status: DashboardTileStatus;
  value: number | string;
  reportUrl: string;
  queryParams?: any;
  dataAutomation?: string;
}

@Component({
  selector: 'ziro-dashboard-tile',
  templateUrl: './dashboard-tile.component.html',
  styleUrls: ['./dashboard-tile.component.scss'],
})
export class DashboardTileComponent {
  @Input() isLoading = true;
  @Input() tile: DashboardTile;
  TileStatus = DashboardTileStatus;
  smacsIcons = SmacsIcons;
}
