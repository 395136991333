import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { get, kebabCase } from 'lodash';
import {
  BottomNavAction,
  BottomNavClearButtonsList,
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from './bottom-nav.service';
import { Subject } from 'rxjs';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { ButtonStyles, ButtonTypes } from '../../button/button.component';

/**
 * Set the bottom nav validation state on form submit. Set a help text if form state is invalid.
 */
export interface BottomNavState {
  /** Set to true if form has error */
  hasValidationError: boolean;
  helpText?: string;
}

export interface BottomNavButtonState {
  pending?: boolean;
  buttonDisableState?: ButtonDisableState;
  tooltipVisible?: boolean;
}

/**
 * State of a bottom nav button disabled with a tooltip key
 */
export interface ButtonDisableState {
  disabled: boolean;
  tooltipKey?: string;
}

/**
 * Set bottom nav button(s) for components with id and label required.
 */
export interface BottomNavButton {
  id: string;
  label: string;
  icon?: SmacsIcons;
  iconAppended?: boolean;
  buttonClass: ButtonStyles;
  dataAutomation?: string;
  cb?: () => any;
  state?: BottomNavButtonState;
  type?: ButtonTypes;
  /** Use this subject if the button action is responsible for form submit,
   * see {@link SmacsFormAbstractDirective._validateAndSubmitSource} **/
  submitSubject?: Subject<boolean>;
  labelParam?: any;
}

@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.scss'],
})
export class BottomNavComponent implements OnChanges {
  bottomNavButtons = [] as BottomNavButton[];
  navBarState = {
    hasValidationError: false,
  } as BottomNavState;
  smacsIcons = SmacsIcons;
  buttonTypes = ButtonTypes;
  buttonStyles = ButtonStyles;

  @Input() isFixed = false;
  /** Emit on button clicked to perform actions in parent form component */
  @Output() buttonClicked = new EventEmitter<any>();

  constructor(private bottomNavService: BottomNavService) {
    /** Based on the actions sent by individual form components, update the state of buttons.
     * Some actions are:
     * {@link BottomNavUpdateButtonState}: Can update the state (pending, disabled) of button by providing
     * id {@link BottomNavButton.id}
     * {@link BottomNavUpdateButtonsList}: Initialize button list or update button label
     * {@link BottomNavUpdateState}: Provide validation feedback
     * {@link BottomNavClearButtonsList}: Clear the buttons list
     * */
    this.bottomNavService.bottomNavChannel$.subscribe((action: BottomNavAction) => {
      if (action instanceof BottomNavUpdateButtonsList) {
        const { payload } = action as BottomNavUpdateButtonsList;
        this.bottomNavButtons = payload;
      } else if (action instanceof BottomNavUpdateButtonState) {
        const { payload } = action as BottomNavUpdateButtonState;
        const button = this._getButtonById(payload.id);
        if (!button) {
          return; // we probably navigated away from the page or something...
        }
        button.state = { ...button.state, ...payload.state };
      } else if (action instanceof BottomNavUpdateState) {
        const { payload } = action as BottomNavUpdateState;
        this.navBarState = { ...this.navBarState, ...payload };
      } else if (action instanceof BottomNavClearButtonsList) {
        this.bottomNavButtons = [];
        this.navBarState.hasValidationError = false;
      }
    });
  }

  /** Emit event to execute the call back action in form component when button is not disabled or pending*/
  onButtonClicked(button: BottomNavButton) {
    if (!button.state || (!button.state.buttonDisableState?.disabled && !button.state.pending)) {
      this.buttonClicked.emit(button.id);
      button.cb && button.cb();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (get(changes, ['bottomNavButtons', 'currentValue'])) {
      this._addDataAutomation();
    }
  }

  private _getButtonById(id: string): BottomNavButton {
    return this.bottomNavButtons.find((b) => b.id === id);
  }

  /**
   * Set data automation attribute for each button in bottom nav list if not provided in form components
   * @private
   */
  private _addDataAutomation() {
    this.bottomNavButtons.forEach((button) => {
      button.dataAutomation = (button.dataAutomation || kebabCase(button.id)) + '-button';
    });
  }
}
