import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CucmLicenseUsage, CucmLicenseUsageDevice } from '../../../shared/models/generated/smacsModels';
import { FileDownloadService } from '../../../shared/services/file-download.service';

export interface LicenseOptimizationReport {
  [licenseType: string]: LicenseTierReport;
}

export interface LicenseTierReport {
  currentTotal: number;
  optimalTotal: number;
  devices: LicenseTierReportDevice[];
}

export interface LicenseTierReportDevice extends CucmLicenseUsageDevice {
  ownerId: string;
}

@Injectable()
export class CucmLicenseOptimizationContext {
  private _stateSource = new ReplaySubject<CucmLicenseUsage>(1); // use _setCucmLicenseUsage to set state
  state$ = this._stateSource.asObservable();

  private _reportSource = new ReplaySubject<LicenseOptimizationReport>(1); // use _setCucmLicenseUsage to set state
  report$ = this._reportSource.asObservable();

  constructor(private $http: HttpClient, private fileDownloadService: FileDownloadService) {
    this.refresh();
  }

  refresh() {
    this.getCucmLicenseUsage().subscribe((response) => {
      this._setCucmLicenseUsage(response);
    });
  }

  // public so stages can update state
  _setCucmLicenseUsage(cucmLicenseUsage: CucmLicenseUsage) {
    this._stateSource.next(cucmLicenseUsage);
    this._reportSource.next(this._buildLicenseOptimizationReport(cucmLicenseUsage));
  }

  downloadFile(): Observable<void> {
    return this.fileDownloadService.downloadFile('/services/cisco/reporting/license-optimizations/export');
  }

  private getCucmLicenseUsage(): Observable<CucmLicenseUsage> {
    return this.$http.get<CucmLicenseUsage>(
      `/services/cisco/reporting/license-optimizations/cucm-licenses?daysRecentlyCreated=30`
    );
  }

  private _buildLicenseOptimizationReport(licenseUsage: CucmLicenseUsage): LicenseOptimizationReport {
    const licenseOptimizationReport: LicenseOptimizationReport = {};
    ['CUWL Standard', 'Enhanced Plus', 'Enhanced', 'Basic', 'Essential'].forEach(
      (tier) =>
        (licenseOptimizationReport[tier] = {
          currentTotal: 0,
          optimalTotal: 0,
          devices: [],
        })
    );

    licenseUsage.users.forEach((user) => {
      const tierReport = licenseOptimizationReport[user.cucmCurrentLicense];
      tierReport.currentTotal++;
      if (user.cucmOptimalLicense !== 'None') {
        licenseOptimizationReport[user.cucmOptimalLicense].optimalTotal++;
      }

      user.devices.forEach((device) => {
        if (device.isDeletionCandidate) {
          tierReport.devices.push({
            ownerId: user.ownerId,
            ...device,
          });
        }
      });
    });

    // Do public devices second so they are sorted to the bottom of the report
    licenseUsage.publicDevices.forEach((device) => {
      const tierReport = licenseOptimizationReport[device.currentLicense];
      tierReport.currentTotal++;
      if (!device.isDeletionCandidate) {
        tierReport.optimalTotal++;
      } else {
        tierReport.devices.push({
          ownerId: '',
          ...device,
        });
      }
    });

    return licenseOptimizationReport;
  }
}
