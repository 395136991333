<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div data-automation="user-detail-phone">
  <smacs-phone
    *ngIf="phoneType !== PhoneType.EXTENSION_MOBILITY && !isLoading"
    (save$)="onSave($event)"
    (delete$)="goHome()"
    (cancel$)="goHome()"
  ></smacs-phone>
  <smacs-extension-mobility
    *ngIf="phoneType === PhoneType.EXTENSION_MOBILITY && !isLoading"
    [endUserRef]="endUserRef"
    [global360View]="global360View"
    (save$)="onSave($event)"
    (delete$)="goHome()"
    (cancel$)="goHome()"
  ></smacs-extension-mobility>
</div>
