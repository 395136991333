<div class="list-group list-group-striped">
  <div class="list-group-item">
    <div class="row">
      <h5 class="col-lg-11">
        {{ 'tkey;admin.proxy_server_management.proxy_feature_settings.number_assignment_check.title.zpc' | translate }}
      </h5>
      <div class="d-flex justify-content-end col-lg-1">
        <smacs-checkbox [fieldId]="'teamsPhoneNumberAssignmentCheckEnabled'"></smacs-checkbox>
      </div>
    </div>
    <p [ngClass]="{ 'mb-0': !dialPlanSyncToggleEnabled }">
      {{
        'tkey;admin.proxy_server_management.proxy_feature_settings.number_assignment_check.description.zpc' | translate
      }}
    </p>
    <div [hidden]="!dialPlanSyncToggleEnabled">
      <smacs-radio [fieldId]="'teamsPhoneNumberAssignmentMatchType'"></smacs-radio>
      <div [hidden]="!lastDigitsMatchEnabled">
        <div class="alert alert-warning d-flex justify-content-between">
          <span>
            <i class="{{ smacsIcons.WARNING }} me-1"></i>
            <strong
              data-automation="proxy-feature-settings-end-match-warning"
              [innerHTML]="
                'tkey;admin.proxy_server_management.proxy_feature_settings.number_assignment_check.end_match_warning_banner.text'
                  | translate
              "
            ></strong>
          </span>
        </div>
        <smacs-text [fieldId]="'teamsPhoneNumberAssignmentLastDigitsMatch'"></smacs-text>
      </div>
    </div>
  </div>
</div>
