import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { IndexedPhoneButton, PhoneButton } from '../../models/phone-button';
import { SmacsIcons } from '../../models/smacs-icons.enum';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { Phone } from '../../models/generated/smacsModels';
import { DragDropMode } from '../drag-drop-mode.enum';
import { Observable } from 'rxjs';
import { SmacsFormAbstractDirective } from '../../../forms/smacs-form-abstract.directive';
import { PhoneResource } from '../../resources/phone.resource';
import { ToastService } from '../../services/toast.service';
import { map, shareReplay } from 'rxjs/operators';
import { PhoneUiContext } from '../contexts/phone-ui.context';
import { ModalBodyClass } from '../../../modals/modal-body';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { Global360ViewContext } from '../../contexts/global-360-view.context';
import { DetailedModalComponent } from '../../../modals/detailed-modal/detailed-modal.component';
import { AbstractUiContext } from '../contexts/abstract-ui.context';

export interface PhoneButtonsExpansionButtonEditModalViewProperties {
  buttonToEdit: IndexedPhoneButton;
  phone: Phone;
  serverId: string;
  cb: (updatedButton: IndexedPhoneButton) => void;
  phoneUiContext: PhoneUiContext;
}

@Component({
  selector: 'app-phone-button-edit-modal',
  templateUrl: './phone-button-edit-modal.component.html',
  styleUrls: ['./phone-button-edit-modal.component.scss'],
  providers: [Global360ViewContext],
})
export class PhoneButtonEditModalComponent extends ModalBodyClass implements OnInit {
  @ViewChild('form') childForm: SmacsFormAbstractDirective<PhoneButton>;
  @ViewChild('errorTemplate') errorTemplate: TemplateRef<any>;

  DragDropMode = DragDropMode;
  SmacsIcons = SmacsIcons;
  SmacsFormsValidationState = SmacsFormsValidationState;

  // modal properties passed in from phone-buttons-standard
  buttonToEdit: IndexedPhoneButton;
  phone: Phone;
  serverId: string;
  cb: (updatedButton: IndexedPhoneButton) => void;
  context: AbstractUiContext; // since this is a session-scoped context we cannot inject it into the modal!

  updatedButton: PhoneButton;
  submit$: Observable<void>;
  isValid: SmacsFormsValidationState;
  modalViewProperties: PhoneButtonsExpansionButtonEditModalViewProperties;
  isSubmitting = false;
  isSubmitted = false;
  formConfig = {
    fields: {},
  } as SmacsFormConfig;

  constructor(
    private phoneResource: PhoneResource,
    private toastService: ToastService,
    protected smacsFormStateService: SmacsFormStateService,
    private modalComponent: DetailedModalComponent<any>
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this.setValuesFromViewProperties();
  }

  isFormValid() {
    return this.childForm.isFormValid();
  }

  setValuesFromViewProperties = () => {
    this.buttonToEdit = this.modalComponent.modalViewProperties.buttonToEdit;
    this.phone = this.modalComponent.modalViewProperties.phone;
    this.serverId = this.modalComponent.modalViewProperties.serverId;
    this.cb = this.modalComponent.modalViewProperties.cb;
    this.context = this.modalComponent.modalViewProperties.phoneUiContext;
  };

  onFormUpdate(update: SmacsFormsUpdate<PhoneButton>) {
    this.isValid = update.valid;
    this.updatedButton = update.new;
  }

  submit(): Observable<void> {
    this.isSubmitting = true;
    this.phone.buttons[this.buttonToEdit.index] = this.updatedButton;
    return this.phoneResource.put(this.phone, this.serverId).pipe(
      map(() => {
        this.context.set(this.phone);
        this.toastService.pushSaveToast('Deskphone', this.phone.description, SmacsIcons.DESKPHONE);
        this.isSubmitting = false;
        this.cb({ ...this.buttonToEdit, button: this.updatedButton });
      }),
      shareReplay()
    );
  }
}
