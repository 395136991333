import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NumberLocationValidation } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class NumberLocationValidationResource {
  constructor(private http: HttpClient) {}

  get(lineUri: string, upn: string): Observable<NumberLocationValidation> {
    return this.http.get<NumberLocationValidation>(
      `/services/microsoft/views/number-location-validation?lineUri=${encodeURIComponent(
        lineUri
      )}&upn=${encodeURIComponent(upn)}`
    );
  }
}
