import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MicrosoftTeamsAudioConference } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class MicrosoftTeamsAudioConferencingFieldConfigResource {
  private _baseUrl = '/services/microsoft/field-configs/teams-audio-conferences';

  constructor(private http: HttpClient) {}

  get(): Observable<MicrosoftTeamsAudioConference> {
    return this.http.get<MicrosoftTeamsAudioConference>(this._baseUrl);
  }
}
