import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppUserResult } from '../models/generated/smacsModels';
import { SearchAbstractResource } from '../../self-serve/resources/search/search.abstract.resource';

// In shared module so that we can use it with site management one day
@Injectable()
export class AppUserSearchResource extends SearchAbstractResource {
  private _baseUrl = `/services/cisco/macs/app-users/search`;
  limit = 100;

  constructor(protected http: HttpClient) {
    super(http);
  }

  searchByQ(query: string, cucmServerId?: number, limit?: number): Observable<AppUserResult[]> {
    const params = { q: query } as any;
    if (cucmServerId) {
      params.cucmServerId = cucmServerId;
    }
    if (limit) {
      params.limit = limit;
    }
    return this.search(this._baseUrl, params);
  }

  searchByUsername(username: string, cucmServerId: number): Observable<AppUserResult[]> {
    return this.search(this._baseUrl, { username: username, cucmServerId: cucmServerId, limit: this.limit });
  }
}
