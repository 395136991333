<div
  *ngIf="isReleaseAvailable && !isHostedEnabled && showReleaseMessage"
  data-automation="release-alert"
  class="alert alert-primary animated slideInDown fast d-flex justify-content-center mb-0 w-100"
  role="alert"
>
  <span class="me-1 ms-auto">
    <span [class]="smacsIcons.BULLHORN + ' me-1'"></span>
    <span
      data-automation="home-new-release-alert-message"
      [innerHTML]="'tkey;version.message.out_of_date' | translate : { version: portalReleaseVersion }"
    ></span>
  </span>
  <span class="me-auto" [innerHTML]="'tkey;version.message' | translate"></span>
  <i data-automation="hide-release-notes" [class]="smacsIcons.REMOVE" (click)="setShowReleaseTimestamp()"></i>
</div>
<nav
  class="navbar navbar-expand-lg smacs-top-nav slideInDown fast"
  [ngClass]="{ 'bg-info': !isHomePage }"
  data-automation="top-nav"
>
  <div class="container-fluid">
    <div [ngClass]="{ 'd-none': isHomePage }">
      <a class="navbar-brand" [attr.href]="'/app2/#/home/'">
        <svg-icon [name]="'navbar-ziro-logo'"></svg-icon>
      </a>
    </div>

    <div id="topnavbar" class="collapse navbar-collapse">
      <!-- HIDE -->
      <div *ngIf="showSearchFormAndActions && !isHomePage" class="smacs-top-nav-form__container">
        <form (ngSubmit)="onSubmit()" class="smacs-top-nav-form" novalidate>
          <div class="input-group">
            <input
              type="text"
              name="searchTerm"
              data-automation="smacs-top-nav-search-input"
              class="form-control smacs-top-nav-form__input"
              [(ngModel)]="query"
              #searchTerm="ngModel"
              [placeholder]="
                (isHostedEnabled ? 'tkey;search.placeholder.zpm.text' : 'tkey;search.placeholder.zpc.text') | translate
              "
            />
            <button
              class="btn btn-primary border-rad-tr-2 border-rad-br-2"
              data-automation="smacs-top-nav-search-button"
              type="submit"
            >
              <i [attr.class]="smacsIcons.SEARCH"></i>
            </button>
          </div>
        </form>
      </div>

      <ul *ngIf="showAddOperations()" data-automation="smacs-top-nav-actions" class="navbar-nav ms-4" id="add-nav-bar">
        <div
          ngbDropdown
          class="nav-item d-inline-block smacs-top-nav-edit-dropdown"
          data-automation="smacs-top-nav-edit-dropdown"
        >
          <button class="btn dropdown-toggle" ngbDropdownToggle>
            <i [attr.class]="smacsIcons.PLUS"></i>
            {{ 'tkey;menu.edit' | translate }}
          </button>

          <div ngbDropdownMenu aria-labelledby="topNavEditDropdown">
            <a
              *ngIf="showAddUserButton()"
              [routerLink]="'/user-add'"
              class="btn dropdown-item"
              data-automation="smacs-top-nav-actions-add-end-user"
            >
              <i [attr.class]="smacsIcons.USER"></i>
              {{ 'tkey;menu.useradd' | translate }}
            </a>
            <a
              [routerLink]="'/public-phone'"
              [hidden]="!isDeskphoneEnabledOnAnyClusters"
              class="btn dropdown-item"
              data-automation="smacs-top-nav-actions-add-public-phone"
            >
              <i [attr.class]="smacsIcons.DESKPHONE"></i>
              {{ 'tkey;menu.edit.phone' | translate }}
            </a>
          </div>
        </div>
      </ul>

      <ul class="navbar-nav ms-auto">
        <app-system-status-alert></app-system-status-alert>

        <li
          *ngIf="ziroProvUrl"
          ngbDropdown
          class="nav-item dropdown ziro-prov-container environment px-2"
          data-automation="ziro-prov-container"
        >
          <a
            class="btn nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            ngbDropdownToggle
            data-automation="current-provisioning"
          >
            <i [attr.class]="smacsIcons.ENVIRONMENT" aria-hidden="true"></i>
            <span
              [innerHTML]="
                'tkey;home.top.nav.current.environment.title'
                  | translate
                    : {
                        env: isHostedEnabled
                          ? ('tkey;home.top.nav.microsoft' | translate)
                          : ('tkey;home.top.nav.cisco' | translate)
                      }
              "
            ></span>
          </a>
          <div
            ngbDropdownMenu
            class="dropdown-menu ziro-prov-container--dropdown-menu"
            aria-labelledby="navbarDropdown"
          >
            <div *ngIf="isHostedEnabled">
              <a class="btn dropdown-item" [attr.href]="null">{{ 'tkey;home.top.nav.microsoft' | translate }}</a>
              <a class="btn dropdown-item" [attr.href]="ziroProvUrl">{{ 'tkey;home.top.nav.cisco' | translate }}</a>
            </div>
            <div *ngIf="!isHostedEnabled">
              <a class="btn dropdown-item" [attr.href]="null">{{ 'tkey;home.top.nav.cisco' | translate }}</a>
              <a class="btn dropdown-item" [attr.href]="ziroProvUrl">{{ 'tkey;home.top.nav.microsoft' | translate }}</a>
            </div>
          </div>
        </li>

        <li
          *ngIf="showReportingButton()"
          class="dropdown"
          data-automation="top-nav-link-reports"
          [ngbTooltip]="getReportingTooltip() | translate"
          [placement]="'bottom'"
          [class.default-cursor]="areModulesDisabled()"
        >
          <a
            [routerLink]="getReportingLink()"
            [ngClass]="{ disabled: areModulesDisabled() }"
            data-automation="top-nav-link-reports-link"
          >
            <i [attr.class]="smacsIcons.REPORTS + ' smacs-top-nav-navbar__icon'"></i>
          </a>
        </li>

        <li
          *ngIf="showAutomationButton()"
          class="dropdown"
          data-automation="top-nav-link-zero-touch"
          [ngbTooltip]="getAutomationTooltip() | translate"
          [placement]="'bottom'"
          [class.default-cursor]="areModulesDisabled()"
        >
          <a
            [routerLink]="
              areModulesDisabled() ? null : isHostedEnabled ? ['/automate/about-rest'] : ['/automate/ldap-sync']
            "
            [ngClass]="{ disabled: areModulesDisabled() }"
          >
            <i [attr.class]="smacsIcons.AUTOMATION_ALT + ' smacs-top-nav-navbar__icon'"></i>
          </a>
        </li>

        <li
          *ngIf="showAdminButton()"
          class="dropdown"
          data-automation="top-nav-link-admin"
          [ngbTooltip]="'tkey;top_nav.settings.tooltip' | translate"
          [placement]="'bottom'"
        >
          <a id="gear-link" [routerLink]="getAdminLink()">
            <i [attr.class]="smacsIcons.CONFIG + ' smacs-top-nav-navbar__icon'"></i>
          </a>
        </li>
        <li *ngIf="isAuthenticated()">
          <a
            #toggleButton
            id="top-logout-button"
            class="smacs-top-nav-logout dropdown-toggle current-user--dropdown-toggle"
            data-automation="top-nav-current-user-card-toggle"
            (click)="toggleCurrentUserCard()"
          >
            <img
              [hidden]="!currentUser.photoBase64"
              [src]="'data:image/png;base64,' + currentUser.photoBase64"
              [alt]="currentUser.displayName"
              class="user-profile-picture"
            />
            <i
              [hidden]="currentUser.photoBase64"
              [attr.class]="smacsIcons.USER_CIRCLE + ' smacs-top-nav-navbar__icon'"
            ></i>
          </a>
          <div
            #currentUserCard
            data-automation="current-user-card"
            class="dropdown-menu current-user--dropdown-menu"
            [ngClass]="{ 'is-visible': isCurrentUserCardVisible }"
          >
            <div class="current-user--dropdown-menu__inner">
              <img
                data-automation="current-user-card-user-photo"
                [hidden]="!currentUser.photoBase64"
                [src]="'data:image/png;base64,' + currentUser.photoBase64"
                [alt]="currentUser.displayName"
                class="user-profile-picture"
              />
              <i
                data-automation="current-user-card-default-photo"
                [hidden]="currentUser.photoBase64"
                [attr.class]="smacsIcons.USER_CIRCLE + ' user-profile-picture-default'"
              ></i>
              <h3 [hidden]="!currentUser.displayName" data-automation="current-user-card-display-name" class="mb-0">
                {{ currentUser.displayName }}
              </h3>
              <p [hidden]="!currentUser.userId" data-automation="current-user-card-id">{{ currentUser.userId }}</p>
              <hr />
              <smacs-button
                [dataAutomation]="'current-user-card-logout'"
                [label]="'Logout'"
                [icon]="smacsIcons.SIGN_OUT"
                [buttonStyle]="buttonStyles.DEFAULT"
                (clicked)="logoutUser()"
              ></smacs-button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
