import { Component, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { SbcNumberConfigurationsContext } from './sbc-number-configurations-context.service';
import {
  SbcConfiguration,
  SbcConfigurationReport,
  SbcConfigurationType,
} from '../../../../shared/models/generated/smacsModels';
import { DatatableColumn, DatatableRow } from '../../../../reporting/datatable/datatable.component';
import { TelephoneNumberFilter } from '../../../../shared/filters/telephone-number.filter';
import { DatatableFilterService } from '../../../../reporting/datatable/datatable-filter.service';
import { TranslateService } from '@ngx-translate/core';

interface SbcNumberConfigurationRow extends DatatableRow {
  numberMisconfigType: string;
  dialPlanGroupName: string;
  carrier: string;
  number: string;
}

@Component({
  selector: 'ziro-sbc-number-configurations',
  templateUrl: './sbc-number-configurations.component.html',
  styleUrls: ['../../../admin-page.scss'],
  providers: [SbcNumberConfigurationsContext],
})
export class SbcNumberConfigurationsComponent implements OnInit {
  isLoading = true;
  columns: DatatableColumn<SbcNumberConfigurationRow>[] = [
    {
      name: 'numberMisconfigType',
      label: 'tkey;admin.billing.sbc_numbers_config.datatable.number_misconfig_type.label',
      columnCharacterWidth: 30,
    },
    {
      name: 'dialPlanGroupName',
      label: 'tkey;admin.billing.sbc_numbers_config.datatable.dial_plan_group_name.label',
      columnCharacterWidth: 30,
    },
    {
      name: 'carrier',
      label: 'tkey;admin.billing.sbc_numbers_config.datatable.carrier.label',
      columnCharacterWidth: 30,
    },
    {
      name: 'number',
      label: 'tkey;admin.billing.sbc_numbers_config.datatable.number.label',
      columnCharacterWidth: 30,
      pipe: this._telephoneNumberFilter,
    },
  ];
  rows: SbcNumberConfigurationRow[] = [];
  noResultsAlert = 'tkey;admin.billing.sbc_numbers_config.datatable.empty';
  NUMBER_MISCONFIG_TYPE_MAP: Map<SbcConfigurationType, string> = new Map([
    [SbcConfigurationType.DRAAS_MISSING_FROM_ZPM, 'tkey;admin.billing.sbc_numbers_config.datatable.misconfig_type_1'],
    [SbcConfigurationType.BYOC_MISSING_FROM_ZPM, 'tkey;admin.billing.sbc_numbers_config.datatable.misconfig_type_2'],
    [SbcConfigurationType.DRAAS_MISSING_FROM_SBC, 'tkey;admin.billing.sbc_numbers_config.datatable.misconfig_type_3'],
    [SbcConfigurationType.BYOC_MISSING_FROM_SBC, 'tkey;admin.billing.sbc_numbers_config.datatable.misconfig_type_4'],
    [
      SbcConfigurationType.SBC_NUMBER_MISSING_CARRIER,
      'tkey;admin.billing.sbc_numbers_config.datatable.misconfig_type_5',
    ],
    [SbcConfigurationType.CORRECTLY_CONFIGURED, 'tkey;admin.billing.sbc_numbers_config.datatable.correct_type'],
  ]);
  numberMisconfgTypeFilterOptions: string[] = Array.from(this.NUMBER_MISCONFIG_TYPE_MAP.values()).map((filter) =>
    this._translateService.instant(filter)
  );
  carrierFilterOptions: string[] = [];
  dialPlanFilterOptions: string[] = [];
  tableDataAutomation = 'sbc-numbers-misconfiguration-table';
  initialSelectedFilters: string[];

  constructor(
    private _telephoneNumberFilter: TelephoneNumberFilter,
    private _breadcrumbsService: BreadcrumbsService,
    private _sbcNumbersMisconfigurationContext: SbcNumberConfigurationsContext,
    private _datatableFilterService: DatatableFilterService,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this.initialSelectedFilters = this.numberMisconfgTypeFilterOptions.slice(0, -1);
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.billing.sbc_numbers_config.title' }]);
    this._sbcNumbersMisconfigurationContext.get().subscribe((state: SbcConfigurationReport) => {
      this.rows = this._buildRows(state.sbcNumberConfigurations);
      this.isLoading = false;
    });
  }

  isCarrierMatch = (filter: string[], row: SbcNumberConfigurationRow): boolean =>
    this._datatableFilterService.filterMultiSelectValues(filter, row.carrier);
  isNumberMisconfigTypeMatch = (filter: string[], row: SbcNumberConfigurationRow): boolean =>
    this._datatableFilterService.filterMultiSelectValues(
      filter.map((f) => this._translateService.instant(f)),
      this._translateService.instant(row.numberMisconfigType)
    );
  isDialPlanMatch = (filter: string[], row: SbcNumberConfigurationRow): boolean =>
    this._datatableFilterService.filterMultiSelectValues(filter, row.dialPlanGroupName);

  private _buildRows(sbcMisconfigs: SbcConfiguration[]): SbcNumberConfigurationRow[] {
    return sbcMisconfigs.map((misconfig: SbcConfiguration) => {
      if (misconfig.sbcCarrier && !this.carrierFilterOptions.includes(misconfig.sbcCarrier)) {
        this.carrierFilterOptions.push(misconfig.sbcCarrier);
      }
      if (misconfig.dialplanGroupName && !this.dialPlanFilterOptions.includes(misconfig.dialplanGroupName)) {
        this.dialPlanFilterOptions.push(misconfig.dialplanGroupName);
      }
      return {
        numberMisconfigType: this._translateService.instant(
          this.NUMBER_MISCONFIG_TYPE_MAP.get(misconfig.numberConfigurationType)
        ),
        dialPlanGroupName: misconfig.dialplanGroupName,
        carrier: misconfig.sbcCarrier,
        number: misconfig.number,
      };
    });
  }
}
