<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div [innerHTML]="'tkey;admin.email_configuration.card.message' | translate"></div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <div class="card mt-3">
      <div class="card-header">{{ 'tkey;admin.email_configuration.card.label' | translate }}</div>
      <div class="card-body">
        <smacs-text [fieldId]="'host'"></smacs-text>
        <smacs-text [fieldId]="'port'"></smacs-text>
        <smacs-select [fieldId]="'transportStrategy'"></smacs-select>
        <smacs-text [fieldId]="'username'"></smacs-text>
        <smacs-text [fieldId]="'password'"></smacs-text>
        <smacs-text [fieldId]="'from'"></smacs-text>
      </div>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
