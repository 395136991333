<div class="card mb-4" [attr.data-automation]="dataAutomation" [ngClass]="{ expanded: isCardSwitchChecked }">
  <div class="card-header d-flex justify-content-between align-items-center" [ngClass]="cssClass">
    <span class="p-0 m-0 d-flex align-items-center">
      <svg-icon
        *ngIf="svgIcon"
        src="/static/svg/{{ svgIcon }}.svg"
        [svgStyle]="{ 'width.px': '20', stroke: '#ffffff' }"
        class="me-2"
      ></svg-icon>
      <i *ngIf="icon" [attr.class]="icon + ' me-1'"></i>
      {{ label | translate }}
      <span
        *ngIf="hasBetaTag"
        class="badge bg-warning rounded-pill align-self-center ms-2"
        [ngbTooltip]="betaTagTooltip | translate"
      >
        {{ 'tkey;shared.collapsible_card.beta_tag.text' | translate }}
      </span>
    </span>
    <span [hidden]="!showChevron || !isCollapsible">
      <label
        [attr.for]="switchId"
        [hidden]="!isCardSwitchChecked"
        class="cursor-pointer"
        data-automation="toggle-card-up"
      >
        <i [attr.class]="smacsIcons.UP"></i>
      </label>
      <label
        [attr.for]="switchId"
        [hidden]="isCardSwitchChecked"
        class="cursor-pointer"
        data-automation="toggle-card-down"
      >
        <i [attr.class]="smacsIcons.DOWN"></i>
      </label>
    </span>
    <span [hidden]="showChevron || !isCollapsible">
      <span
        [ngClass]="{ disabled: isDisabledAndChecked }"
        class="switch switch-md d-flex align-items-center"
        [ngbTooltip]="disabledTooltip | translate"
        [disableTooltip]="!disabledTooltip || !isDisabled"
        container="body"
        data-automation="collapsible-card-toggle-switch"
        *ngIf="!isSwitchHidden"
      >
        <input
          [attr.id]="switchId"
          type="checkbox"
          class="switch-input"
          [checked]="isCardSwitchChecked"
          (change)="emitSwitchValue()"
          [disabled]="isDisabled"
        />
        <label [attr.for]="switchId" class="switch-label"></label>
      </span>
    </span>
  </div>
  <div
    id="collapsibleCardBody"
    class="collapsible-card card-body {{ switchId }} pb-0"
    [ngClass]="{ expanded: isCardSwitchChecked }"
  >
    <ng-content></ng-content>
  </div>
</div>
