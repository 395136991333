<app-loading-block [hidden]="!isLoading"></app-loading-block>
<app-base-container
  *ngIf="!isLoading"
  [pageDisabled]="pageDisabled"
  [serverDisabled]="serverDisabled"
  [links]="adminLinks"
  [pageDisabledMessage]="pageDisabledMessage"
  [licenseExpired]="isLicenseExpired"
  [isDialPlansConfigured]="isDialPlansConfigured"
></app-base-container>
