import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VoicemailAlternateExtension } from '../models/generated/smacsModels';

@Injectable()
export class VoicemailAlternateExtensionsResource {
  constructor(private http: HttpClient) {}

  getAll(unityServerId: number, voicemailId: string): Observable<VoicemailAlternateExtension[]> {
    return this.http.get<VoicemailAlternateExtension[]>(
      `/services/cisco/macs/unity-servers/${unityServerId}/voicemails/${voicemailId}/alternate-extensions`
    );
  }

  get(
    unityServerId: number,
    voicemailId: string,
    alternateExtensionId: string
  ): Observable<VoicemailAlternateExtension> {
    return this.http.get<VoicemailAlternateExtension>(
      `/services/cisco/macs/unity-servers/${unityServerId}/voicemails/${voicemailId}/alternate-extensions/${alternateExtensionId}`
    );
  }

  put(
    unityServerId: number,
    voicemailId: string,
    extension: string,
    alternateExtensionId: string
  ): Observable<VoicemailAlternateExtension> {
    return this.http.put<VoicemailAlternateExtension>(
      `/services/cisco/macs/unity-servers/${unityServerId}/voicemails/${voicemailId}/alternate-extensions/${alternateExtensionId}`,
      {
        id: alternateExtensionId,
        extension,
      }
    );
  }

  delete(unityServerId: number, voicemailId: string, alternateExtensionId: string): Observable<void> {
    return this.http.delete<void>(
      `/services/cisco/macs/unity-servers/${unityServerId}/voicemails/${voicemailId}/alternate-extensions/${alternateExtensionId}`
    );
  }

  post(unityServerId: number, voicemailId: string, extension: string): Observable<VoicemailAlternateExtension> {
    return this.http.post<VoicemailAlternateExtension>(
      `/services/cisco/macs/unity-servers/${unityServerId}/voicemails/${voicemailId}/alternate-extensions`,
      {
        extension,
      }
    );
  }
}
