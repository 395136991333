import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { AuthenticationContext } from '../../../shared/contexts/authentication.context';
import { CustomPowershellReport, Privilege } from '../../../shared/models/generated/smacsModels';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../shared/entity-table/entity-table.models';
import { CustomPowershellReportContext } from '../../../shared/contexts/custom-powershell-report.context';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { DateTimeFormat } from '../../../shared/filters/date-time-format.filter';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { ToastService } from '../../../shared/services/toast.service';
import { tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';

@Component({
  selector: 'app-custom-powershell-reports',
  templateUrl: './custom-powershell-reports.component.html',
  styleUrls: ['../../reporting.scss'],
  providers: [DateTimeFormat],
})
export class CustomPowershellReportsComponent implements OnInit, OnDestroy {
  smacsIcons = SmacsIcons;
  isLoading = true;
  isReadOnly = false;
  table: EntityTable = {
    columns: [
      {
        columnId: 'reportName',
        cssColumnSize: 'col-sm-6',
        label: 'tkey;reporting.custom_reports.custom_report_list.report_name',
        filter: {
          type: EntityTableFilterTypes.TEXT,
        },
      },
      {
        columnId: 'lastUpdated',
        cssColumnSize: 'col-sm-3',
        label: 'tkey;reporting.custom_reports.custom_report_list.last_updated',
      },
    ],
    cssClearButtonColumnSize: 'col-sm-3',
    hasActions: true,
  };
  tableRows: EntityTableContentRow[] = [];

  private _reportsExporting: number[] = [];
  private _reports: CustomPowershellReport[];
  private _subscriptions = new Subscription();

  constructor(
    private _breadcrumbsService: BreadcrumbsService,
    private _authenticationContext: AuthenticationContext,
    private _bottomNavService: BottomNavService,
    private _customPowershellReportContext: CustomPowershellReportContext,
    private _dateTimeFormat: DateTimeFormat,
    private _translateService: TranslateService,
    private _smacsModalService: SmacsModalService,
    private _toastService: ToastService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    const sub = combineLatest([
      this._authenticationContext.state$,
      this._customPowershellReportContext.state$,
    ]).subscribe(([currentUser, customPowershellReports]) => {
      this._reports = customPowershellReports;

      this.isReadOnly = !currentUser.privileges.includes(Privilege.CONTROL_WRITE);
      if (!this.isReadOnly) {
        this._initBottomNav();
      }

      this._createTableRows();
      this.isLoading = false;
    });
    this._subscriptions.add(sub);

    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;reporting.microsoft.custom_powershell_reports.title' }]);
  }

  ngOnDestroy() {
    this._breadcrumbsService.clearBreadcrumbs();
  }

  private _initBottomNav() {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'custom-powershell-reports-add',
          dataAutomation: 'custom-powershell-reports-add',
          label: 'tkey;reporting.microsoft.custom_powershell_reports.bottom_nav.add_report',
          icon: SmacsIcons.ADD,
          buttonClass: ButtonStyles.PRIMARY,
          cb: () => {
            this._newReportClick();
          },
        },
      ])
    );
  }

  private _createTableRows() {
    this.tableRows = this._reports.map((report: CustomPowershellReport) => {
      return {
        content: {
          reportName: report.reportName,
          lastUpdated: this._dateTimeFormat.transform(report.lastUpdated),
        },
        actions: [
          {
            buttonStyle: ButtonStyles.DANGER,
            dataAutomation: 'report-delete',
            icon: SmacsIcons.DELETE,
            isHidden: this.isReadOnly,
            isDisabled: this._reportsExporting.includes(report.id),
            tooltip: this._reportsExporting.includes(report.id)
              ? 'tkey;reporting.xlsx_edit_delete.button.disabled.tooltip'
              : '',
            tooltipDisabled: !this._reportsExporting.includes(report.id),
            onClick: () => this._promptDeleteReport(report),
          },
          {
            buttonStyle: ButtonStyles.INFO,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/${report.id}`,
            dataAutomation: 'report-edit',
            icon: SmacsIcons.EDIT,
            isHidden: this.isReadOnly,
            isDisabled: this._reportsExporting.includes(report.id),
            tooltip: this._reportsExporting.includes(report.id)
              ? 'tkey;reporting.xlsx_edit_delete.button.disabled.tooltip'
              : '',
            tooltipDisabled: !this._reportsExporting.includes(report.id),
            onClick: () => this._editReport(report),
          },
          {
            buttonStyle: ButtonStyles.PRIMARY,
            dataAutomation: 'report-export',
            icon: SmacsIcons.EXPORT,
            isDisabled: this._reportsExporting.includes(report.id),
            label: 'tkey;reporting.xlsx_export.button',
            isPending: this._reportsExporting.includes(report.id),
            iconPending: SmacsIcons.SAVING,
            onClick: () => this._exportReport(report),
          },
        ],
        actionsColumnSize: 'col-sm-3',
      };
    });
  }

  private _newReportClick() {
    this._router.navigate(['new-report'], { relativeTo: this._route });
  }

  private _exportReport(report: CustomPowershellReport) {
    this._reportsExporting.push(report.id);
    this._createTableRows();
    this._customPowershellReportContext.export(report.id).subscribe(() => {
      this._reportsExporting = this._reportsExporting.filter((id: number) => id !== report.id);
      this._toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.EXPORT,
        'tkey;reporting.microsoft.custom_powershell_reports.toast.export.title',
        'tkey;reporting.microsoft.custom_powershell_reports.toast.export.message'
      );
      this._createTableRows();
    });
  }

  private _editReport(report: CustomPowershellReport) {
    this._router.navigate([report.id], { relativeTo: this._route });
  }

  private _promptDeleteReport(report: CustomPowershellReport) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        modalBodyIconHeaderClass: 'animated bounceIn lead text-center text-danger',
        title: this._translateService.instant('tkey;reporting.microsoft.custom_powershell_reports.modal.delete.title'),
        promptBody: this._translateService.instant(
          'tkey;reporting.microsoft.custom_powershell_reports.modal.delete.message',
          {
            reportName: report.reportName,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteReport(report),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteReport(report: CustomPowershellReport): Observable<void> {
    this.isLoading = true;
    return this._customPowershellReportContext.delete(report.id).pipe(
      tap(() => {
        this._toastService.pushDeleteToast(
          'tkey;reporting.microsoft.custom_powershell_reports.title',
          report.reportName
        );
      })
    );
  }
}
