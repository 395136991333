import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import {
  Checkbox,
  CustomCheckbox,
  CustomInputText,
  CustomMultiSelect,
  CustomSelect,
  EnabledType,
  MultiSelect,
  Select,
  ServiceSetting,
  UnityMetadata,
} from '../../../../../shared/models/generated/smacsModels';
import { ServiceSettingFieldIds, ServiceSettingFormData } from '../site-management-service.component';
import { of, Subscription } from 'rxjs';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../../forms/smacs-forms-models';
import { cloneDeep, isEqual } from 'lodash';
import { ButtonStyles } from '../../../../../button/button.component';
import { ZiroCustomSelectConfig } from '../../../../../shared/custom-configs/custom-select/ziro-custom-select.component';
import { SmacsIcons } from '../../../../../shared/models/smacs-icons.enum';
import { SmacsSelectOption } from '../../../../../forms/fields/select/smacs-select.component';
import { CommonFieldsState } from '../../sites/site/common-fields-ui.context';

@Component({
  selector: 'ziro-site-management-service-form',
  templateUrl: './site-management-service-form.component.html',
  styleUrls: ['./site-management-service-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteManagementServiceFormComponent
  extends SmacsFormAbstractDirective<ServiceSetting, ServiceSettingFormData>
  implements OnInit, OnDestroy
{
  ButtonStyles = ButtonStyles;
  SmacsIcons = SmacsIcons;

  @Input() formConfig: SmacsFormConfig;
  @Input() serviceSettingFieldIds: ServiceSettingFieldIds;
  @Input() dynamicServiceName: string;
  @Input() unityMetadata: { [index: string]: UnityMetadata };
  @Input() serviceSettingToCopy: ServiceSetting;
  @Input() commonFieldsState: CommonFieldsState;
  @Input() unityServerOptions: SmacsSelectOption[];

  mobilityIdentityFieldIds: string[];
  isMobilityIdentity = false;
  isDirty = false;

  private _initialEntity: ServiceSetting;
  private _isFirstVmTemplateInit = true;
  private _subscriptions = new Subscription();

  ngOnInit() {
    this._initialEntity = this.entity;
    this.isMobilityIdentity = this._initialEntity.checkboxes.some(
      (checkbox) => checkbox.name === 'mobility_identity_management'
    );
    const formUpdateSubscription = this.smacsFormsUpdate$.subscribe((update) => {
      if (this.entity?.enabled !== EnabledType.DISABLED) {
        if (update.new.oneClickEnabled !== update.old.oneClickEnabled && !!this.fieldChannels) {
          Object.values(this.fieldChannels).forEach((field) => field.validateSource.next());
        }
        if (this.entity?.name === 'Voicemail') {
          this._updateVmTemplateOptionsIfUnityServerChange(update);
        }
      }
    });
    this._subscriptions.add(formUpdateSubscription);
    if (this.isMobilityIdentity) {
      this.mobilityIdentityFieldIds = ['mobility_identity_name', 'stop_ringing_phone_delay'];
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  copyFromServiceSetting() {
    const newEntity = cloneDeep(this.entity);
    newEntity.customInputTexts = newEntity.customInputTexts.map((customInputText) => {
      const copiableField = this.serviceSettingToCopy.customInputTexts.find((cit) => cit.name === customInputText.name);
      return copiableField ?? customInputText;
    });
    newEntity.customSelects = newEntity.customSelects.map((customSelect) => {
      const copiableField = this.serviceSettingToCopy.customSelects.find((cs) => cs.name === customSelect.name);
      return copiableField ?? customSelect;
    });
    newEntity.selects = newEntity.selects.map((select) => {
      const copiableField = this.serviceSettingToCopy.selects.find((s) => s.name === select.name && s.name);
      return copiableField ?? select;
    });
    newEntity.customCheckboxes = newEntity.customCheckboxes.map((customCheckbox) => {
      const copiableField = this.serviceSettingToCopy.customCheckboxes.find((cc) => cc.name === customCheckbox.name);
      return copiableField ?? customCheckbox;
    });
    newEntity.checkboxes = newEntity.checkboxes.map((checkbox) => {
      const copiableField = this.serviceSettingToCopy.checkboxes.find((c) => c.name === checkbox.name);
      return copiableField ?? checkbox;
    });
    newEntity.multiSelects = newEntity.multiSelects.map((multiSelect) => {
      const copiableField = this.serviceSettingToCopy.multiSelects.find((ms) => ms.name === multiSelect.name);
      return copiableField ?? multiSelect;
    });
    newEntity.customMultiSelects = newEntity.customMultiSelects.map((customMultiSelect) => {
      const copiableField = this.serviceSettingToCopy.customMultiSelects.find(
        (cms) => cms.name === customMultiSelect.name
      );
      return copiableField ?? customMultiSelect;
    });
    this.entitySource.next(newEntity);
  }

  trackByName(index: number, name: string) {
    return `${this.dynamicServiceName}_${name}`;
  }

  toFormData = (entity: ServiceSetting): ServiceSettingFormData => {
    const formData: ServiceSettingFormData = {
      oneClickEnabled: entity.oneClickEnabled,
      enabled: entity.enabled,
    };

    for (let i = 0; i < entity.customInputTexts.length; i++) {
      const customInputText = entity.customInputTexts[i];
      formData[customInputText.name] = customInputText;
    }

    for (let i = 0; i < entity.customSelects.length; i++) {
      const customSelect = entity.customSelects[i];
      formData[customSelect.name] = customSelect;
    }

    for (let i = 0; i < entity.selects.length; i++) {
      const select = entity.selects[i];
      formData[select.name] = select.value;
    }

    for (let i = 0; i < entity.customCheckboxes.length; i++) {
      const customCheckbox = entity.customCheckboxes[i];
      formData[customCheckbox.name] = {
        value: customCheckbox.value,
        show: customCheckbox.show,
      } as CustomCheckbox;
    }

    for (let i = 0; i < entity.checkboxes.length; i++) {
      const checkbox = entity.checkboxes[i];
      formData[checkbox.name] = checkbox.value;
    }

    for (let i = 0; i < entity.multiSelects.length; i++) {
      const multiSelect = entity.multiSelects[i];
      formData[multiSelect.name] = multiSelect.possibleOptions;
    }

    for (let i = 0; i < entity.customMultiSelects.length; i++) {
      const multiSelect = entity.customMultiSelects[i];
      formData[multiSelect.name] = {
        defaultOptions: multiSelect.defaultOptions,
        possibleOptions: [],
        show: multiSelect.show,
        name: multiSelect.name,
      };
    }
    return formData;
  };

  toEntity = (formData: ServiceSettingFormData): ServiceSetting => {
    const initialEntity = this._initialEntity;
    const isMobilityIdentityEnabled = formData['mobility_identity_management'] as boolean;

    const customSelects: CustomSelect[] = [];
    const customCheckboxes: CustomCheckbox[] = [];
    const customInputTexts: CustomInputText[] = [];
    const selects: Select[] = [];
    const checkboxes: Checkbox[] = [];
    const multiSelects: MultiSelect[] = [];
    const customMultiSelects: CustomMultiSelect[] = [];

    // custom selects
    for (let i = 0; i < initialEntity.customSelects.length; i++) {
      const customSelect = initialEntity.customSelects[i];
      const fieldFormData = formData[customSelect.name] as CustomSelect;
      customSelects.push({
        name: fieldFormData.name,
        defaultOption: fieldFormData.defaultOption,
        possibleOptions: fieldFormData.possibleOptions,
        required: fieldFormData.required,
        show: fieldFormData.show,
      });
    }

    // custom checkboxes
    for (let i = 0; i < initialEntity.customCheckboxes.length; i++) {
      const customCheckbox = initialEntity.customCheckboxes[i];
      const { value, show } = formData[customCheckbox.name] as CustomCheckbox;
      customCheckboxes.push({
        name: customCheckbox.name,
        value,
        show,
      });
    }

    // custom input texts
    for (let i = 0; i < initialEntity.customInputTexts.length; i++) {
      const customInputText = initialEntity.customInputTexts[i];
      const formDataCustomInputText = formData[customInputText.name] as CustomInputText;
      if (formDataCustomInputText) {
        if (formDataCustomInputText.name === 'mobility_identity_name') {
          customInputTexts.push({
            ...formDataCustomInputText,
            required: !isMobilityIdentityEnabled ? false : formDataCustomInputText.required,
          });
        } else {
          customInputTexts.push(formDataCustomInputText);
        }
      }
    }

    // selects
    for (let i = 0; i < initialEntity.selects.length; i++) {
      const select = initialEntity.selects[i];
      selects.push({
        name: select.name,
        value: formData[select.name] as string,
      });
    }

    // checkboxes
    for (let i = 0; i < initialEntity.checkboxes.length; i++) {
      const checkbox = initialEntity.checkboxes[i];
      checkboxes.push({
        name: checkbox.name,
        value: formData[checkbox.name] as boolean,
      });
    }

    // multi-selects
    for (let i = 0; i < initialEntity.multiSelects.length; i++) {
      const multiSelect = initialEntity.multiSelects[i];
      multiSelects.push({
        name: multiSelect.name,
        possibleOptions: formData[multiSelect.name] as string[],
      });
    }

    // custom multi-selects
    for (let i = 0; i < initialEntity.customMultiSelects.length; i++) {
      const customMultiSelect = initialEntity.customMultiSelects[i];
      const formDataCustomInputText = formData[customMultiSelect.name] as CustomMultiSelect;
      customMultiSelects.push({
        name: customMultiSelect.name,
        defaultOptions: formDataCustomInputText.defaultOptions,
        possibleOptions: [],
        show: formDataCustomInputText.show,
      });
    }

    const updatedEntity = {
      oneClickEnabled: formData['oneClickEnabled'] as boolean,
      customSelects,
      customCheckboxes,
      customInputTexts,
      selects,
      checkboxes,
      multiSelects,
      customMultiSelects,
      enabled: formData['enabled'] as EnabledType,
      name: this.entity.name,
    };
    this.isDirty = !isEqual(initialEntity, updatedEntity);
    return updatedEntity;
  };

  toggleEnabled() {
    this.entitySource.next({
      ...this.entity,
      enabled: this.entity.enabled === EnabledType.ENABLED ? EnabledType.DISABLED : EnabledType.ENABLED,
    });
  }

  getCommonFieldVisibility(field: string): boolean {
    return (
      !!this.commonFieldsState &&
      !this.commonFieldsState.areCommonFieldsVisible &&
      this.commonFieldsState.commonFields.includes(field) &&
      this.validationFlags[field] !== SmacsFormsValidationState.INVALID
    );
  }

  protected submit() {
    return of(null);
  }

  private _updateVmTemplateOptionsIfUnityServerChange(update: SmacsFormsUpdate<ServiceSetting>) {
    if (
      !!this.fieldChannels['voicemail_template'] &&
      update.new.customSelects.find((cs) => cs.name === 'voicemail_template') &&
      this._isFirstVmTemplateInit
    ) {
      this._reInitVmTemplate(update);
      this._isFirstVmTemplateInit = false;
      setTimeout(() => {
        Object.values(this.fieldChannels).forEach((field) => field.validateSource.next());
      });
    }
    if (
      !!this.fieldChannels['voicemail_template'] &&
      update.new.selects.find((select) => select.name === 'unity_server')?.value !==
        update.old.selects.find((select) => select.name === 'unity_server')?.value
    ) {
      this._reInitVmTemplate(update);
      setTimeout(() => {
        Object.values(this.fieldChannels).forEach((field) => field.validateSource.next());
      });
    }
  }

  private _reInitVmTemplate(update: SmacsFormsUpdate<ServiceSetting>) {
    const selectedUnityServer = update.new.selects.find((select) => select.name === 'unity_server').value;
    let serverOption: number;
    if (selectedUnityServer) {
      serverOption = this.unityServerOptions.find((server) => server.label === selectedUnityServer).value;
    }
    const vmTemplate = this.fieldComponents.find((field) => field.fieldId == 'voicemail_template');
    const vmTemplateConfig = this.fieldComponents.find((field) => field.fieldId == 'voicemail_template').config
      .componentConfig as ZiroCustomSelectConfig;
    const newConfig = new ZiroCustomSelectConfig({
      isAlwaysRequired: vmTemplateConfig.config.isAlwaysRequired,
      displayValues: vmTemplateConfig.config.displayValues,
      availableOptions: this.unityMetadata[serverOption]?.voicemailTemplates || [],
      displayLabel: vmTemplateConfig.config.displayLabel,
    });
    vmTemplate.applyComponentConfig(newConfig);
    if (!this._isFirstVmTemplateInit) {
      this.fieldChannels['voicemail_template'].validateSource.next();
    }
  }

  protected readonly SmacsFormsValidationState = SmacsFormsValidationState;
  protected readonly EnabledType = EnabledType;
}
