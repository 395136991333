import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BottomNavComponent } from './bottom-nav.component';
import { ButtonModule } from '../../button/button.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { SmacsFormsModule } from '../../forms/smacs-forms.module';

@NgModule({
  declarations: [BottomNavComponent],
  exports: [BottomNavComponent],
  imports: [CommonModule, ButtonModule, TranslateModule, NgbTooltip, SmacsFormsModule],
})
export class BottomNavModule {}
