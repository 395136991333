import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../../../../forms/smacs-forms-models';
import { SmacsSelectConfig } from '../../../../../forms/fields/select/smacs-select.component';
import { CustomCucmReport, Server } from '../../../../../shared/models/generated/smacsModels';
import { EMPTY, Observable, Subscriber, Subscription, throwError } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { CucmQueriesResource } from '../../../resources/cucm-queries.resource';
import { ToastService } from '../../../../../shared/services/toast.service';
import { ToastTypes } from '../../../../../shared/services/abstract/toast.service.abstract';
import { SmacsIcons } from '../../../../../shared/models/smacs-icons.enum';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../../shared/bottom-nav/bottom-nav.service';
import { ButtonSizes, ButtonStyles } from '../../../../../button/button.component';
import { isEqual } from 'lodash';
import { HtmlInputType, SmacsTextConfig } from '../../../../../forms/fields/text/smacs-text.component';
import { ActivatedRoute, Router } from '@angular/router';
import { SmacsModalService } from '../../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import {
  SmacsCodeAreaConfig,
  SmacsCodeAreaMode,
} from '../../../../../forms/fields/code-area/smacs-code-area.component';
import { SmacsFormStateService } from '../../../../../forms/smacs-form-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DatatableColumn, DatatableRow } from '../../../../datatable/datatable.component';

interface CucmCustomReportDatatableRow extends DatatableRow {
  [key: string]: unknown;
}

export interface CucmReportForm {
  cucmServer: string;
  sqlQuery: string;
  reportName: string;
}

@Component({
  selector: 'app-cucm-report-builder-form',
  templateUrl: './cucm-report-builder-form.component.html',
  styleUrls: ['../../../../reporting.scss'],
})
export class CucmReportBuilderFormComponent
  extends SmacsFormAbstractDirective<CustomCucmReport, CucmReportForm>
  implements OnInit, OnDestroy
{
  @Input() servers: Server[] = [];
  @Input() isNewReport: boolean;
  @Input() report: CustomCucmReport;
  @Input() allReports: CustomCucmReport[];
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  tableDataAutomation = 'cucm-report-builder-datatable';
  isTableLoading = true;
  hasQueryRun = false;
  formConfig: SmacsFormConfig;
  allReportNames: string[];
  serverIdLabelMap: { [id: number]: string } = {};
  queryResults: any[] = [];
  isTestQueryClicked = false;
  isSaveReportClicked = false;
  columns: DatatableColumn<CucmCustomReportDatatableRow>[];
  tableData: CucmCustomReportDatatableRow[];

  private _isInvalidQuery = false;
  private _invalidQueryMessage: string;
  private _subs = new Subscription();

  constructor(
    private _cucmQueriesResource: CucmQueriesResource,
    private _toastService: ToastService,
    private _bottomNavService: BottomNavService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    protected smacsFormStateService: SmacsFormStateService
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    this._initFormConfig();
    this._initBottomNav();
    this.allReportNames = this.allReports.map((r) => r.reportName.trim().toLowerCase());
    this.servers.forEach((server) => {
      this.serverIdLabelMap[server.id] = server.description;
    });

    if (!this.isNewReport) {
      this.entitySource.next(this.report);
    }

    this.smacsFormsUpdate$.subscribe((data: SmacsFormsUpdate<CustomCucmReport>) => {
      if (data.valid === SmacsFormsValidationState.VALID && this.isFormSubmitted) {
        this._bottomNavService.dispatch(
          new BottomNavUpdateState({
            hasValidationError: !this.isFormValid(),
          })
        );
      }
      if (data?.old?.sqlQuery !== data?.new?.sqlQuery) {
        this.hasQueryRun = false;
      }
      if (data.old && !isEqual(data.old, data.new)) {
        this._isInvalidQuery = false;
        this.fieldChannels['sqlQuery'].validateSource.next();
      }
    });
  }

  private _initFormConfig() {
    this.formConfig = {
      fields: {
        reportName: {
          label: 'tkey;reporting.custom_reports.custom_report_list.report_name',
          dataAutomation: 'cucm-report-builder-name-input',
          componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.TEXT }),
          required: true,
          validation: [
            {
              validator: (val: string) => {
                if (!this.report) {
                  return this.allReportNames.includes(val.trim().toLowerCase())
                    ? SmacsFormsValidationState.INVALID
                    : SmacsFormsValidationState.VALID;
                } else {
                  return this.report.reportName === val || !this.allReportNames.includes(val.trim().toLowerCase())
                    ? SmacsFormsValidationState.VALID
                    : SmacsFormsValidationState.INVALID;
                }
              },
              message: 'tkey;reporting.custom_reports.edit_report.duplicate_name_error',
            },
          ],
        },
        cucmServer: {
          label: 'tkey;reporting.custom_reports.custom_report_list.cucm_server',
          dataAutomation: 'cucm-report-builder-server-input',
          componentConfig: new SmacsSelectConfig({ options: this.servers.map((s) => s.description) }),
          required: true,
        },
        sqlQuery: {
          label: 'tkey;reportmanagement.edit.query.label',
          dataAutomation: 'cucm-report-builder-query-input',
          componentConfig: new SmacsCodeAreaConfig({ mode: SmacsCodeAreaMode.SQL }),
          required: true,
          validation: [
            {
              validator: () =>
                !this._isInvalidQuery ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
              message: () => this._invalidQueryMessage,
            },
          ],
        },
      },
    } as SmacsFormConfig;
  }

  private _initBottomNav = () => {
    const buttons = [
      {
        id: 'cucmReportBuilderTestQueryButton',
        dataAutomation: 'cucm-report-builder-test-query-button',
        label: 'tkey;menu.report.cucm_report_builder.button.test',
        icon: SmacsIcons.RUN,
        buttonClass: ButtonStyles.INFO,
        cb: () => {
          this.testQueryClicked();
        },
      },
      {
        id: 'cucmReportBuilderExportButton',
        dataAutomation: 'cucm-report-builder-export-button',
        label: 'tkey;reporting.xlsx_export.button',
        icon: SmacsIcons.EXPORT,
        buttonClass: ButtonStyles.INFO,
        cb: () => {
          this._onExportClicked();
        },
      },
      {
        id: 'cucmReportBuilderCancelButton',
        dataAutomation: 'cucm-report-builder-cancel-button',
        label: 'tkey;reportmanagement.edit.query.cancel.button',
        buttonClass: ButtonStyles.DEFAULT,
        cb: () => {
          this._returnToListView();
        },
      },
      {
        id: 'cucmReportBuilderSaveReportButton',
        dataAutomation: 'cucm-report-builder-save-report-button',
        label: 'tkey;global.button.save.text',
        icon: SmacsIcons.OK,
        buttonClass: ButtonStyles.PRIMARY,
        cb: () => {
          this.isSaveReportClicked = true;
          this._bottomNavService.dispatch(
            new BottomNavUpdateState({
              hasValidationError: !this.isFormValid(),
            })
          );
          this._validateAndSubmitSource.next(true);
        },
      },
    ];

    if (!this.isNewReport) {
      buttons.splice(3, 0, {
        id: 'bottom-nav-delete-button',
        dataAutomation: 'bottom-nav-delete-button',
        label: 'tkey;global.button.delete.text',
        icon: SmacsIcons.DELETE,
        buttonClass: ButtonStyles.DANGER,
        cb: () => {
          this._onDeleteClicked();
        },
      });
    }

    this._bottomNavService.dispatch(new BottomNavUpdateButtonsList(buttons));
  };

  testQueryClicked() {
    if (this.isFormValid()) {
      this.isTestQueryClicked = true;
    }
    this._validateAndSubmitSource.next(true);
  }

  private _hasQueryChanged(): boolean {
    return (
      this.report && (this.report.sqlQuery !== this.entity.sqlQuery || this.report.serverId !== this.entity.serverId)
    );
  }

  private _testQuery(isModalCall = false): Observable<any> {
    if (!isModalCall) {
      this._setPendingButton('cucmReportBuilderTestQueryButton', true);
      this._setDisabledButtons(
        ['cucmReportBuilderCancelButton', 'cucmReportBuilderSaveReportButton', 'cucmReportBuilderExportButton'],
        true
      );
    }
    this.queryResults = [];
    this.isTableLoading = true;
    this.hasQueryRun = false;
    const serverID = this.servers.find((s) => s.description === this.formData.cucmServer).id;
    return new Observable((subscriber: Subscriber<any>) => {
      this._cucmQueriesResource.postLiveQuery({ query: this.entity.sqlQuery, cucmId: serverID }).subscribe({
        next: (response) => {
          this.queryResults = response.results;
          this.hasQueryRun = true;
          this._initTable();
          this._toastService.push(
            ToastTypes.SUCCESS,
            SmacsIcons.TABLE,
            'tkey;menu.report.cucm_report_builder.modal.success.title',
            'tkey;menu.report.cucm_report_builder.modal.success.message'
          );

          subscriber.next(response);
          subscriber.complete();
        },
        error: (response) => {
          this.isTableLoading = false;
          subscriber.error(response);
          subscriber.complete();
        },
      });
    });
  }

  private _saveReport(isModalCall = false): Observable<void> {
    if (!isModalCall) {
      this._setPendingButton('cucmReportBuilderSaveReportButton', true);
      this._setDisabledButtons(
        ['cucmReportBuilderCancelButton', 'cucmReportBuilderTestQueryButton', 'cucmReportBuilderExportButton'],
        true
      );
    }

    const request = this.isNewReport
      ? this._cucmQueriesResource.postNewReport(this.entity).pipe(
          map((newId) => {
            this.entity.id = Number(newId);
          })
        )
      : this._cucmQueriesResource.updateReport(this.entity.id, this.entity).pipe(
          map(() => {
            this.report = this.entity;
          })
        );

    return request.pipe(
      tap(() => {
        this._toastService.pushSaveToast(
          'tkey;reporting.custom_reports.custom_report_list.toast.title',
          this.entity.reportName,
          SmacsIcons.TABLE
        );
      })
    );
  }

  private _initTable() {
    if (this.queryResults.length > 0) {
      this.columns = Object.keys(this.queryResults[0]).map(
        (queryKey) =>
          ({
            name: queryKey,
            label: queryKey,
          } as DatatableColumn<CucmCustomReportDatatableRow>)
      );
      this.tableData = this.queryResults;
    }
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
      })
    );
    this._subs.unsubscribe();
  }

  toFormData = (entity: CustomCucmReport): CucmReportForm => {
    return {
      cucmServer: this.serverIdLabelMap[entity.serverId],
      sqlQuery: entity.sqlQuery,
      reportName: entity.reportName,
    };
  };

  toEntity = (formData: CucmReportForm): CustomCucmReport => {
    const server = this.servers.find((s) => s.description === formData.cucmServer);
    return {
      id: this.report ? this.report.id : null,
      lastUpdated: this.report ? this.report.lastUpdated : null,
      reportName: formData.reportName,
      serverId: server ? server.id : null,
      sqlQuery: formData.sqlQuery,
    };
  };

  private _onExportClicked() {
    Object.keys(this.fieldChannels).forEach((key) => {
      this.fieldChannels[key].validateSource.next();
    });
    if (!this.isFormValid()) {
      return;
    }
    if (this.isNewReport || this._hasQueryChanged()) {
      const options = {
        windowClass: 'custom-report-run-and-save-report-modal',
        modalViewProperties: {
          title: this._translateService.instant('tkey;reporting.custom_reports.modal.title'),
          promptBody: this._translateService.instant('tkey;reporting.custom_reports.modal_2.text'),
          icon: SmacsIcons.DOWNLOAD,
          displayCloseButton: true,
          buttons: [
            {
              label: 'tkey;dialogs.button.cancel',
              buttonClass: ButtonStyles.DEFAULT,
              dataAutomation: 'confirmation-modal-cancel-button',
            },
            {
              label: 'tkey;reporting.custom_reports.modal_2.button',
              buttonClass: ButtonStyles.PRIMARY,
              dataAutomation: 'confirmation-modal-confirm-button',
              cb: () => {
                return this._testQuerySaveAndExport().pipe(
                  tap(() => {
                    this.smacsFormStateService.setIsFormDirty(false);
                    this._returnToListView();
                  }),
                  catchError((response) => {
                    this._handleErrorResponse(response);
                    return throwError(() => response);
                  })
                );
              },
            },
          ],
        },
      };
      this._smacsModalService.openPromptModal(() => options.modalViewProperties, options).subscribe();
    } else {
      this._exportFile().subscribe(() => this._resetButtonStates());
    }
  }

  private _testQuerySaveAndExport() {
    return new Observable((subscriber: Subscriber<HttpErrorResponse> | null) => {
      this._testQuery(true)
        .pipe(mergeMap(() => this._saveReport(true)))
        .pipe(mergeMap(() => this._exportFile(true)))
        .subscribe({
          next: () => {
            subscriber.next();
            subscriber.complete();
          },
          error: (response) => {
            subscriber.error(response);
            subscriber.complete();
          },
        });
    });
  }

  private _handleErrorResponse(error: any) {
    this._isInvalidQuery = true;
    this._invalidQueryMessage = error.error.description;
    this.fieldChannels['sqlQuery'].validateSource.next();
    this._toastService.push(
      ToastTypes.ERROR,
      SmacsIcons.TABLE,
      'tkey;menu.report.cucm_report_builder.modal.error.title',
      'tkey;menu.report.cucm_report_builder.modal.error.message'
    );
    this._resetButtonStates();
  }

  private _resetButtonStates() {
    this._setDisabledButtons(
      [
        'cucmReportBuilderCancelButton',
        'cucmReportBuilderTestQueryButton',
        'cucmReportBuilderSaveReportButton',
        'cucmReportBuilderExportButton',
      ],
      false
    );
    this.isTableLoading = false;
    this._validateAndSubmitSource.next(false);
  }

  private _exportFile(isModalCall = false) {
    if (!isModalCall) {
      this._setPendingButton('cucmReportBuilderExportButton', true);
      this._setDisabledButtons(
        ['cucmReportBuilderCancelButton', 'cucmReportBuilderTestQueryButton', 'cucmReportBuilderSaveReportButton'],
        true
      );
    }
    return this._cucmQueriesResource.getExportToExcel(this.entity.id).pipe(
      tap(() => {
        this._toastService.push(
          ToastTypes.SUCCESS,
          SmacsIcons.EXPORT,
          'tkey;reporting.xlsx_export.downloaded_toast.title',
          'tkey;reporting.xlsx_export.downloaded_toast.message'
        );
      })
    );
  }

  private _setPendingButton(buttonId: string, setting: boolean) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  private _returnToListView() {
    this._router.navigate(['../'], { relativeTo: this._route });
  }

  private _setDisabledButtons(buttonIds: string[], setting: boolean) {
    buttonIds.forEach((id) => this._setDisabledButton(id, setting));
  }

  private _setDisabledButton(buttonId: string, setting: boolean) {
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: buttonId,
        state: {
          pending: false,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  private _showConfirmationModal() {
    return new Observable((subscriber) => {
      const options = {
        windowClass: 'custom-report-run-and-save-report-modal',
        modalViewProperties: {
          title: this._translateService.instant('tkey;reporting.custom_reports.modal.title'),
          promptBody: this._translateService.instant('tkey;reporting.custom_reports.modal_1.text'),
          icon: SmacsIcons.TABLE,
          displayCloseButton: true,
          buttons: [
            {
              label: 'tkey;dialogs.button.cancel',
              buttonClass: ButtonStyles.DEFAULT,
              dataAutomation: 'confirmation-modal-cancel-button',
            },
            {
              label: 'tkey;reporting.custom_reports.modal_1.button',
              buttonClass: ButtonStyles.PRIMARY,
              dataAutomation: 'confirmation-modal-confirm-button',
              cb: () => {
                return this._testQueryAndSave().pipe(
                  tap(() => {
                    subscriber.next(true);
                    subscriber.complete();
                  }),
                  catchError((response) => {
                    this._handleErrorResponse(response);
                    subscriber.error(response);
                    subscriber.complete();
                    return throwError(() => response);
                  })
                );
              },
            },
          ],
        },
      };
      this._smacsModalService
        .openPromptModal(() => options.modalViewProperties, options)
        .subscribe((response) => {
          if (response === false) {
            subscriber.next(false);
            subscriber.complete();
          }
        });
    });
  }

  private _testQueryAndSave() {
    return new Observable((subscriber: Subscriber<HttpErrorResponse> | null) => {
      this._testQuery(true)
        .pipe(mergeMap(() => this._saveReport(true)))
        .subscribe({
          next: () => {
            subscriber.next();
            subscriber.complete();
          },
          error: (response) => {
            subscriber.error(response);
            subscriber.complete();
          },
        });
    });
  }

  protected submit() {
    if (this.isTestQueryClicked) {
      this.isTestQueryClicked = false;
      return this._testQuery().pipe(
        tap(() => {
          setTimeout(() => {
            if (document.getElementById('query-results-header')) {
              document.getElementById('query-results-header').scrollIntoView({ behavior: 'smooth' });
            }
          });
          this._resetButtonStates();
        }),
        catchError((response) => {
          this._handleErrorResponse(response);
          return throwError(() => EMPTY);
        })
      );
    }
    if (this.isSaveReportClicked) {
      this.isSaveReportClicked = false;
      if (!this.hasQueryRun && (this.isNewReport || this._hasQueryChanged())) {
        return new Observable((subscriber) => {
          this._showConfirmationModal().subscribe({
            next: (isSuccess) => {
              if (isSuccess) {
                this._returnToListView();
                subscriber.next(true);
                subscriber.complete();
              } else {
                subscriber.error(EMPTY);
                subscriber.complete();
              }
            },
            error: (response) => {
              subscriber.error(response);
              subscriber.complete();
            },
          });
        });
      } else {
        return this._saveReport().pipe(
          tap(() => {
            this._returnToListView();
          }),
          catchError((response) => {
            this._handleErrorResponse(response);
            return throwError(() => EMPTY);
          })
        );
      }
    }
  }

  private _onDeleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        title: this._translateService.instant('tkey;reporting.custom_reports.custom_report_list.delete.modal.title'),
        promptBody: this._translateService.instant(
          'tkey;reporting.custom_reports.custom_report_list.delete.modal.message',
          {
            reportName: this.entity.reportName,
          }
        ),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteReport();
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteReport(): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._cucmQueriesResource.deleteReport(this.entity.id).subscribe(() => {
        this._toastService.pushDeleteToast(
          'tkey;reporting.custom_reports.custom_report_list.toast.title',
          this.entity.reportName
        );
        this._returnToListView();
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }
}
