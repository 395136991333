<app-self-serve-top-bar></app-self-serve-top-bar>

<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div [hidden]="isLoading">
  <app-self-serve-user-details-back
    [link]="'/self-serve/user/' + user?.ref.username"
  ></app-self-serve-user-details-back>

  <app-self-serve-user-details-header
    [heading]="'tkey;self_serve.snr.header' | translate"
    [headingDataAutomation]="'self-serve-user-details-snr-header'"
  ></app-self-serve-user-details-header>

  <smacs-text [fieldId]="'destinationNumber'"></smacs-text>
  <smacs-text [fieldId]="'delay'"></smacs-text>

  <div class="col-sm-12 text-end">
    <smacs-button
      *ngIf="snrDestination.id"
      [cssClass]="'me-1'"
      [dataAutomation]="'snr-edit-delete'"
      [label]="'tkey;snr.snrdestination.delete.label' | translate"
      [icon]="smacsIcons.DELETE"
      [iconPending]="smacsIcons.SAVING"
      [isPending]="isDeleting"
      [isDisabled]="isSubmitting"
      [buttonStyle]="buttonStyles.DANGER"
      (clicked)="onDeleteClicked()"
    ></smacs-button>
    <smacs-submit-button
      [isPending]="isSubmitting"
      [isDisabled]="isSubmitting"
      [cssClass]="'me-1'"
      [label]="'tkey;selfserve.user.single_number_reach.button.label'"
      [dataAutomation]="'self-serve-snr-edit-submit-button'"
      [iconPending]="smacsIcons.SAVING"
      [formSubmitSubject]="_validateAndSubmitSource"
    ></smacs-submit-button>
  </div>
</div>
