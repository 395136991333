<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1 pb-2 smacs-report-content" *ngIf="!isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <div>
          <p [innerHtml]="'tkey;reporting.dn_availability.description.header' | translate"></p>
          <p>
            <a [routerLink]="[]" (click)="onGetFileClicked()">
              {{ 'tkey;reporting.xlsx_export.button' | translate }}
            </a>
            <span>
              {{ 'tkey;reporting.dn_availability.description.text' | translate }}
            </span>
          </p>
          <div [innerHTML]="'tkey;reporting.dn_availability.description.text_2' | translate"></div>
          <p class="mb-0">
            <span [innerHtml]="'tkey;reporting.dn_availability.description.refresh.text_1' | translate"></span>
            <smacs-button
              [label]="'tkey;reporting.dn_availability.description.refresh.text_2'"
              (clicked)="refreshDialPlanGroups()"
              [buttonStyle]="buttonStyles.LINK"
            ></smacs-button>
            <span>{{ 'tkey;reporting.dn_availability.description.refresh.text_3' | translate }}</span>
          </p>
        </div>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>

    <smacs-datatable
      [columns]="dataTableCols"
      [data]="dnTableRows"
      [mainFilterPlaceholder]="
        ('tkey;reporting.datatable.search_placeholder' | translate) +
        ('tkey;admin.dn_report.group_name.label' | translate) +
        ', ' +
        ('tkey;admin.site_management.site.site' | translate) +
        ', ' +
        ('tkey;menu.cluster' | translate) +
        '...'
      "
      [dataAutomation]="tableDataAutomation"
    >
      <smacs-datatable-multi-select-filter
        [label]="'tkey;admin.dn_report.available.filter.label'"
        [items]="availabilityFilterOptionsTexts"
        [matchFn]="isAvailableNumbersMatch"
        [dataAutomation]="'dn-inventory-filter'"
        [tableId]="tableDataAutomation"
      ></smacs-datatable-multi-select-filter>
    </smacs-datatable>
  </div>
</div>

<app-bottom-nav data-automation="export-dn-report"></app-bottom-nav>
