import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import { ActivatedRoute, ActivationStart, Router, Scroll } from '@angular/router';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { MenuItem } from 'primeng/api';
import { get } from 'lodash';
import { combineLatest, of, Subscription, switchMap } from 'rxjs';
import { SiteContext } from '../../shared/contexts/site.context';
import { TileConfigContext } from '../../shared/contexts/tile-config.context';

@Component({
  selector: 'smacs-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  providers: [UserDetailUiContext, Global360ViewContext, SiteContext],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  isLoading = true;
  username: string;
  baseCrumb: MenuItem;
  hideBreadcrumb = false;
  siteId: number;

  private _subscriptions = new Subscription();

  constructor(
    private breadcrumbsService: BreadcrumbsService,
    private userDetailUiContext: UserDetailUiContext,
    private global360ViewContext: Global360ViewContext,
    private route: ActivatedRoute,
    private router: Router,
    private tileConfigContext: TileConfigContext,
    private siteContext: SiteContext
  ) {}

  ngOnInit() {
    this.username = this.route.snapshot.paramMap.get('username');
    this.baseCrumb = {
      label: this.username,
      url: `/app2/#/user/${encodeURIComponent(this.username)}`,
    };
    this._updateBreadcrumbs(this.route.firstChild.snapshot.data.breadcrumb);

    const contextSubs = combineLatest([this.global360ViewContext.state$, this.siteContext.state$])
      .pipe(
        switchMap(([global360View, site]) => {
          const siteIdFromContext = Number(site.id);
          if (this.siteId !== siteIdFromContext) {
            this.siteId = siteIdFromContext;
            return this.tileConfigContext.getTileConfigs(siteIdFromContext);
          }
          return of(null);
        })
      )
      .subscribe(() => {
        this.isLoading = false;
      });

    this._subscriptions.add(contextSubs);

    this.global360ViewContext.init(this.username);

    const routerSub = this.router.events.subscribe((event) => {
      if (event instanceof Scroll && event.routerEvent.url.includes('reassign')) {
        const reassignCrumb: MenuItem = {
          label: 'tkey;userdetail.breadcrumb.transfer.text',
          url: this.baseCrumb.url + '/reassign',
        };
        const crumbs = [this.baseCrumb, reassignCrumb];

        if (event.routerEvent.url.includes('/reassign/')) {
          const username = event.routerEvent.url.split('/').reverse()[0];
          const userCrumb: MenuItem = {
            label: username,
          };
          this.breadcrumbsService.updateBreadcrumbs(crumbs.concat(userCrumb));
        } else {
          this.breadcrumbsService.updateBreadcrumbs(crumbs);
        }
        return;
      }
      if (event instanceof ActivationStart) {
        const routeCrumb = get(event, ['snapshot', 'data', 'breadcrumb']);
        this._updateBreadcrumbs(routeCrumb);
      }
    });
    this._subscriptions.add(routerSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  private _updateBreadcrumbs(crumb: string) {
    this.hideBreadcrumb = crumb === 'tkey;userdetail.breadcrumb.home.text';
    this.breadcrumbsService.updateBreadcrumbs([this.baseCrumb, { label: crumb }]);
  }
}
