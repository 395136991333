import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { EndUserSearchResource } from '../../../resources/end-user-search.resource';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import {
  ExpressionEditorKeywordSearchEntity,
  ExpressionEditorKeywordSearchFormData,
} from '../call-logs-query-editor-keyword.component';
import {
  SmacsSelectComponent,
  SmacsSelectConfig,
  SmacsSelectOption,
} from '../../../../forms/fields/select/smacs-select.component';
import { SmacsFormConfig, SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EndUserResult } from '../../../models/generated/smacsModels';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';

@Component({
  selector: 'smacs-expression-editor-keyword-username-search',
  templateUrl: './expression-editor-keyword-username-search.component.html',
  styles: [],
})
export class ExpressionEditorKeywordUsernameSearchComponent extends SmacsFormAbstractDirective<
  ExpressionEditorKeywordSearchEntity,
  ExpressionEditorKeywordSearchFormData
> {
  @Input() entity: ExpressionEditorKeywordSearchEntity;
  @Output() searchTermWasUsed = new EventEmitter();
  @ViewChild(SmacsSelectComponent) selectField: SmacsSelectComponent;

  formConfig = {
    fields: {
      'keyword-search': {
        dataAutomation: 'username-select',
        componentConfig: new SmacsSelectConfig({
          asyncOptionsFn: this.searchUsername.bind(this),
          placeholder: 'tkey;reporting.call_logs.keyword.search.username',
          clearWithInput: true,
          hideDropdownArrow: true,
          minSearchLength: 3,
          hideClear: true,
          keepSearchInput: true,
        }),
      },
    },
    options: {
      columnClasses: {
        label: 'd-none',
        input: 'col-12',
      },
    },
  } as SmacsFormConfig;

  searchUsername(username: string): Observable<SmacsSelectOption[]> {
    return this.endUserSearchResource.searchByQ(username).pipe(
      map((results: EndUserResult[]) => {
        const users: SmacsSelectOption[] = results.map((result: EndUserResult) => {
          return {
            label: result.ref.username,
            value: result.ref.username,
          };
        });
        const uniqueUsers: SmacsSelectOption[] = Array.from(
          new Map(users.map((user: SmacsSelectOption) => [user.label, user])).values()
        );
        return uniqueUsers.sort(
          (result1: { label: string; value: string }, result2: { label: string; value: string }) => {
            return result1.label.localeCompare(result2.label);
          }
        );
      })
    );
  }

  constructor(
    protected smacsFormStateService: SmacsFormStateService,
    private endUserSearchResource: EndUserSearchResource
  ) {
    super(smacsFormStateService);
  }

  protected submit(): Observable<any> {
    return null;
  }

  toFormData = (entity: ExpressionEditorKeywordSearchEntity): ExpressionEditorKeywordSearchFormData => {
    return {
      'keyword-search': {
        label: entity.extensionUsernameOrDeviceName.value,
        value: entity.extensionUsernameOrDeviceName.value,
      },
    } as ExpressionEditorKeywordSearchFormData;
  };

  toEntity = (formData: ExpressionEditorKeywordSearchFormData): ExpressionEditorKeywordSearchEntity => {
    return {
      extensionUsernameOrDeviceName: { selection: 'username', value: formData['keyword-search'].value },
    } as ExpressionEditorKeywordSearchEntity;
  };

  handleSelectClosed() {
    if (typeof this.selectField.fieldData === 'string' && this.selectField.fieldData.length >= 3) {
      // Nothing was selected but we received a search term and we'd like to use it.
      this.smacsFormsUpdateSource.next({
        valid: SmacsFormsValidationState.VALID,
        old: {
          ...this.entity,
        },
        new: {
          extensionUsernameOrDeviceName: {
            selection: 'username',
            value: this.selectField.fieldData,
          },
        },
      });
    }
  }
}
