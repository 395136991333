import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../../../../../forms/smacs-form-abstract.directive';
import { SmacsFormConfig } from '../../../../../../../../forms/smacs-forms-models';
import { Observable, of, Subscription } from 'rxjs';
import { SmacsFormStateService } from '../../../../../../../../forms/smacs-form-state.service';
import { SmacsIcons } from '../../../../../../../../shared/models/smacs-icons.enum';

export interface HelpdeskAdAttributesEntity {
  [key: string]: string;
}

@Component({
  selector: 'ziro-helpdesk-ad-attributes-form',
  templateUrl: './helpdesk-ad-attributes-form.component.html',
})
export class HelpdeskAdAttributesFormComponent
  extends SmacsFormAbstractDirective<HelpdeskAdAttributesEntity>
  implements OnInit, OnDestroy
{
  @Input() formConfig: SmacsFormConfig;
  @Input() attributeFields: string[];
  @Input() initialEntity: HelpdeskAdAttributesEntity;
  hiddenFields: string[] = [];
  smacsIcons = SmacsIcons;
  private _isFirstCompleteLoad = true;
  private _subscriptions: Subscription = new Subscription();

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    const updateSub = this.smacsFormsUpdate$.subscribe((update) => {
      if (Object.values(update.new).length === this.attributeFields.length && this._isFirstCompleteLoad) {
        this._isFirstCompleteLoad = false;
        this.fieldComponents.forEach((fieldComponent) => {
          this.fieldChannels[fieldComponent.fieldId].entitySource.next(this.initialEntity[fieldComponent.fieldId]);
          if (
            this.formConfig.fields[fieldComponent.fieldId].hidden() &&
            this.initialEntity[fieldComponent.fieldId] === this.formConfig.fields[fieldComponent.fieldId].defaultValue()
          ) {
            this.hiddenFields.push(fieldComponent.fieldId);
          }
        });
      }
    });
    this._subscriptions.add(updateSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }

  protected submit(): Observable<any> {
    return of(null);
  }
}
