import { SmacsIcons } from '../shared/models/smacs-icons.enum';
import { NavSection } from '../shared/side-navigation/side-navigation.model';

export const zpmAdminLinks: NavSection[] = [
  {
    icon: SmacsIcons.ZIRO,
    title: 'tkey;admin.ziro_support_settings.title',
    dataAutomation: 'ziro-support-section-header',
    isVisible: (flags) => {
      return flags.isUserS8Support;
    },
    links: [
      {
        link: '/admin/ziro-support-settings/application-logs',
        text: 'tkey;menu.admin.application_logs',
        dataAutomation: 'application-logs-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/ziro-support-settings/general-ziro-support-settings',
        text: 'tkey;admin.microsoft.general_ziro_support_settings.title',
        dataAutomation: 'menu-general-ziro-support-settings-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/ziro-support-settings/bandwidth-management',
        text: 'tkey;admin.bandwidth_account_settings.title',
        dataAutomation: 'menu-microsoft-bandwidth-account-settings-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          if (flags.isLicenseExpired) {
            return 'tkey;admin.menu.license_expired.tooltip';
          } else {
            return null;
          }
        },
      },
      {
        link: '/admin/ziro-support-settings/bandwidth-dlr-misconfigurations',
        text: 'tkey;admin.billing.dlr_misconfigurations.title',
        dataAutomation: 'menu-billing-dlr-misconfigurations',
        isDisabled: (flags) => {
          return !flags.isMsDialPlanConfigured || !flags.isBandwidthConfigured;
        },
        disabledTooltip: () => {
          return 'tkey;admin.billing.dlr_misconfigurations.link.disabled.tooltip';
        },
      },
      {
        link: '/admin/ziro-support-settings/sbc-number-configurations',
        text: 'tkey;admin.billing.sbc_numbers_config.title',
        dataAutomation: 'menu-sbc-numbers-misconfig-link',
        isDisabled: (flags) => {
          return !flags.isZiroDraasDialPlanConfigured;
        },
        disabledTooltip: () => {
          return 'tkey;admin.billing.sbc_numbers_config.disabled.tooltip';
        },
      },
      {
        link: '/admin/ziro-support-settings/dubber-account-management',
        text: 'tkey;admin.microsoft.dubber_management.title',
        dataAutomation: 'menu-microsoft-dubber-account-management-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          if (flags.isLicenseExpired) {
            return 'tkey;admin.menu.license_expired.tooltip';
          } else {
            return null;
          }
        },
      },
      {
        link: '/admin/ziro-support-settings/backup',
        text: 'tkey;admin.backup.title',
        dataAutomation: 'menu-backups-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/ziro-support-settings/db-import',
        text: 'tkey;pages.admin.import.title',
        dataAutomation: 'menu-config-import-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/ziro-support-settings/audit-import',
        text: 'tkey;pages.admin.audit.import.title',
        dataAutomation: 'menu-audit-import-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
    ],
  },
  {
    icon: SmacsIcons.HEALTH,
    dataAutomation: 'system-health-section-header-link',
    title: 'tkey;system_status.title',
    isLink: true,
    link: '/admin/system-health-status',
  },
  {
    icon: SmacsIcons.SLIDERS,
    title: 'tkey;admin.menu.section.system',
    dataAutomation: 'system-section-header',
    links: [
      {
        link: '/admin/system/microsoft-general-settings',
        text: 'tkey;admin.microsoft.general_settings.title',
        dataAutomation: 'menu-microsoft-general-settings-link',
        isDisabled: (flags) => flags.isLicenseExpired || !flags.isMsGraphConfigured,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired
            ? 'tkey;admin.menu.license_expired.tooltip'
            : !flags.isMsGraphConfigured
            ? 'tkey;admin.menu.security_group.graph_disabled.tooltip'
            : null;
        },
      },
      {
        link: '/admin/ui',
        text: 'tkey;admin.ui.title',
        dataAutomation: 'menu-ui-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
    ],
  },
  {
    icon: SmacsIcons.SHIELD,
    dataAutomation: 'security-and-permissions',
    title: 'tkey;admin.menu.section.security_and_permissions',
    links: [
      {
        link: '/admin/security-and-permissions/saml-sso',
        text: 'tkey;admin.saml.title',
        dataAutomation: 'menu-saml-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/security-and-permissions/user-permissions',
        text: 'tkey;admin.system.user_permissions.title',
        dataAutomation: 'menu-user-permissions-link',
        isDisabled: (flags) => !flags.isMsGraphConfigured,
        disabledTooltip: (flags) => {
          return !flags.isMsGraphConfigured ? 'tkey;admin.menu.graph_unconfigured.tooltip' : null;
        },
      },
      {
        link: '/admin/security-and-permissions/oauth2-resource-server-management',
        text: 'tkey;admin.system.oauth2_resource_server_management.title',
        dataAutomation: 'menu-oauth2-resource-server-management-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
        isVisible: (flags) => {
          return flags.isAutomationModuleEnabled;
        },
      },
    ],
  },
  {
    icon: SmacsIcons.MICROSOFT_365,
    title: 'tkey;admin.menu.section.ms365',
    dataAutomation: 'microsoft-management-section-header',
    links: [
      {
        link: '/admin/microsoft/graph-management',
        text: 'tkey;admin.msgraph.management.config.title',
        dataAutomation: 'menu-msgraph-management-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/microsoft/security-group-management',
        text: 'tkey;admin.microsoft.security_group_management.title',
        dataAutomation: 'menu-microsoft-security-group-management-link',
        isDisabled: (flags) => flags.isLicenseExpired || !flags.isMsGraphConfigured,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired
            ? 'tkey;admin.menu.license_expired.tooltip'
            : !flags.isMsGraphConfigured
            ? 'tkey;admin.menu.security_group.graph_disabled.tooltip'
            : null;
        },
      },
      {
        link: '/admin/microsoft/license-management',
        text: 'tkey;admin.license_management.title',
        dataAutomation: 'menu-microsoft-license-management-link',
        isDisabled: (flags) => flags.isLicenseExpired || !flags.isMsGraphConfigured,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired
            ? 'tkey;admin.menu.license_expired.tooltip'
            : !flags.isMsGraphConfigured
            ? 'tkey;admin.menu.graph_disabled.tooltip'
            : null;
        },
      },
      {
        link: '/admin/microsoft/dial-plan-management',
        text: 'tkey;admin.msdialplan.management.title',
        dataAutomation: 'menu-microsoft-dial-plan-management-link',
        isDisabled: (flags) => !flags.isMsGraphConfigured || flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired
            ? 'tkey;admin.menu.license_expired.tooltip'
            : 'tkey;admin.menu.graph_disabled.dial_plans.tooltip';
        },
        isVisible: (flags) => {
          return flags.isHostedEnabled;
        },
      },
      {
        link: '/admin/microsoft/dial-plan-exception-management',
        text: 'tkey;admin.ms_dial_plan_exception.title',
        dataAutomation: 'menu-microsoft-dial-plan-exception-management-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
    ],
  },
  {
    icon: SmacsIcons.SERVER,
    title: 'tkey;admin.proxy_server_management.title',
    dataAutomation: 'proxy-server-management-section-header',
    links: [
      {
        link: '/admin/proxy-server-management/generate-key',
        text: 'tkey;admin.proxy_server_management.generate_key.title',
        dataAutomation: 'menu-proxy-server-generate-key-link',
      },
      {
        link: '/admin/proxy-server-management/proxy-feature-settings',
        text: 'tkey;admin.proxy_server_management.proxy_feature_settings.title',
        dataAutomation: 'menu-proxy-feature-settings-link',
        isDisabled: (flags) => !flags.isZpmProxyKeyConfigured,
        disabledTooltip: (flags) => {
          return flags.isZpmProxyKeyConfigured ? '' : 'tkey;admin.menu.proxy_key_unconfigured.tooltip.zpm';
        },
      },
    ],
  },
  {
    icon: SmacsIcons.BILLING,
    title: 'tkey;admin.billing.title',
    dataAutomation: 'microsoft-billing-section-header',
    isVisible: (flags) => {
      return flags.isUserS8Support;
    },
    links: [
      {
        link: '/admin/billing/subscriptions',
        text: 'tkey;admin.billing.products.title',
        dataAutomation: 'menu-subscriptions-link',
        isDisabled: (flags) => {
          return !flags.isMsDialPlanConfigured;
        },
        disabledTooltip: () => {
          return 'tkey;admin.billing.products.disabled.tooltip';
        },
      },
    ],
  },
  {
    icon: SmacsIcons.AUDITS,
    title: 'tkey;admin.menu.section.audits',
    dataAutomation: 'audits-section-header',
    links: [
      {
        link: '/admin/audits/logs',
        text: 'tkey;admin.audits.logs.title',
        dataAutomation: 'menu-audit-logs-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/audits/settings',
        text: 'tkey;admin.audits.settings.title',
        dataAutomation: 'menu-audit-settings-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
    ],
  },
  {
    icon: SmacsIcons.HASH,
    title: 'tkey;admin.order_numbers.title',
    dataAutomation: 'order-numbers-section-header',
    isVisible: (flags) => {
      return flags.portingEnabled;
    },
    links: [
      {
        link: '/admin/order-numbers/port-in-orders',
        text: 'tkey;admin.order_numbers.port_in_orders.title',
        dataAutomation: 'menu-port-in-orders-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
      {
        link: '/admin/order-numbers/port-in-number-status',
        text: 'tkey;admin.order_numbers.port_in_number_status.title',
        dataAutomation: 'menu-port-in-number-status-link',
        isDisabled: (flags) => flags.isLicenseExpired,
        disabledTooltip: (flags) => {
          return flags.isLicenseExpired ? 'tkey;admin.menu.license_expired.tooltip' : null;
        },
      },
    ],
  },
];
