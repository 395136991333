import { Pipe, PipeTransform } from '@angular/core';
import { find } from 'lodash';
import { DialPlanManagementGroup } from '../models/generated/smacsModels';

@Pipe({ name: 'dialPlanGroupIdToNameFilter' })
export class DialPlanGroupIdToNameFilter implements PipeTransform {
  transform(value: number[], dialPlanGroups: DialPlanManagementGroup[]): string {
    return dialPlanGroups.length
      ? value.map((dpgId) => find(dialPlanGroups, ['id', dpgId.toString()]).name).join(', ')
      : '';
  }
}
