import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReassignTypes } from './reassign-user-search/reassign-user-search.component';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, forkJoin, Observable, of, Subscription, switchMap } from 'rxjs';
import {
  DirectoryNumberRef,
  DnDetailSummary,
  EndUser,
  EndUserRef,
  EndUserResult,
  ExtensionMobility,
  ExtensionMobilityFieldConfigRequest,
  Global360View,
  LdapUserDialPlanAttributes,
  LdapUserDialPlanDetailsFieldConfigRequest,
  LineButton,
  LineFeatureFieldConfig,
  LineFeatureFieldConfigRequest,
  Phone,
  SnrProfile,
  UccxAgentResult,
  Voicemail,
  VoicemailRef,
} from '../../../shared/models/generated/smacsModels';
import { Nvp } from '../../../shared/models/nvp';
import { ReassignUserExecuteComponent } from './reassign-user-execute/reassign-user-execute.component';
import { EndUserResource } from '../../../shared/resources/end-user.resource';
import { DnDetailSummaryResource } from '../../../shared/resources/dn-detail-summary.resource';
import { PrimaryExtensionFieldConfigResource } from '../../shared/resources/field-config/primary-extension-field-config.resource';
import { DirectoryNumberResource } from '../../../self-serve/resources/directory-number.resource';
import { VoicemailService } from '../../../shared/services/voicemail.service';
import { TranslationPatternResource } from '../../shared/resources/translation-pattern.resource';
import { VoicemailResource } from '../../../shared/resources/voicemail.resource';
import { LineFeatureConfigResource } from '../../../shared/resources/line-feature-config.resource';
import { ToastService } from '../../../shared/services/toast.service';
import { DefaultEndUserResource } from '../../../shared/resources/default-end-user.resource';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import { ImPresenceResource } from '../../shared/resources/im-presence.resource';
import { UccxAgentResource } from '../../shared/resources/uccx-agent.resource';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { finalize } from 'rxjs/operators';
import { SiteContext } from '../../shared/contexts/site.context';
import { VoicemailSmtpNotificationsDeviceResource } from '../../../shared/resources/voicemail-smtp-notifications-device.resource';
import { SnrProfileResource } from '../../../self-serve/resources/snr-profile.resource';
import { SnrModelProtocol } from '../snr/snr.component';
import { SnrFieldConfigResource } from '../../shared/resources/field-config/snr-field-config.resource';
import { isArray } from 'lodash';
import { PhoneResource } from '../../../shared/resources/phone.resource';
import { isNonDeskPhone, KnownPhoneModels, PhoneDescriptions } from '../../shared/contexts/user-detail-ui.context';
import {
  PhoneFieldConfigRequestParams,
  PhoneFieldConfigResource,
} from '../../shared/resources/field-config/phone-field-config.resource';
import { ExtensionMobilityProfilesResource } from '../../../shared/resources/extension-mobility-profiles.resource';
import { LdapUserResource } from '../../shared/resources/ldap-user.resource';
import { DefaultValueService } from '../../../shared/services/default-value.service';
import { MobilityIdentityResource } from '../../../shared/resources/mobility-identity.resource';

@Component({
  selector: 'ziro-reassign-user',
  templateUrl: './reassign-user.component.html',
})
export class ReassignUserComponent implements OnInit, OnDestroy {
  @ViewChild(ReassignUserExecuteComponent) reassignUserExecuteComponent: ReassignUserExecuteComponent;
  isLoading = true;
  serverId: number;
  siteId: number;
  isTargetUserSelected = false;
  isVoicemailPresentOnLineOne = false;

  protected readonly ReassignTypes = ReassignTypes;

  private isKeepVoicemail: boolean;
  private originUsername: string;
  private originGlobal360: Global360View;
  private originEndUserResult: EndUserResult;
  private originEndUser: EndUser;
  private targetUsername: string;
  private targetUserGlobal360: Global360View;
  private targetEndUserResult: EndUserResult;
  private auditTags: Nvp[];
  private subscriptions = new Subscription();
  private primaryExtension: DirectoryNumberRef;
  private dnAssociatedVm: VoicemailRef;
  private dnDetailSummary: DnDetailSummary;

  constructor(
    private currentClusterContext: CurrentClusterContext,
    private route: ActivatedRoute,
    private router: Router,
    private endUserResource: EndUserResource,
    private dnDetailSummaryResource: DnDetailSummaryResource,
    private primaryExtensionFieldConfigResource: PrimaryExtensionFieldConfigResource,
    private directoryNumberResource: DirectoryNumberResource,
    private voicemailService: VoicemailService,
    private translationPatternResource: TranslationPatternResource,
    private voicemailResource: VoicemailResource,
    private lineFeatureConfigResource: LineFeatureConfigResource,
    private toastService: ToastService,
    private defaultEndUserResource: DefaultEndUserResource,
    private global360ViewContext: Global360ViewContext,
    private siteContext: SiteContext,
    private imPresenceResource: ImPresenceResource,
    private uccxResource: UccxAgentResource,
    private vmSmtpNotificationDeviceResource: VoicemailSmtpNotificationsDeviceResource,
    private snrProfileResource: SnrProfileResource,
    private snrFieldConfigResource: SnrFieldConfigResource,
    private phoneResource: PhoneResource,
    private phoneFieldConfigResource: PhoneFieldConfigResource,
    private extensionMobilityResource: ExtensionMobilityProfilesResource,
    private ldapUserResource: LdapUserResource,
    private defaultValueService: DefaultValueService,
    private mobilityIdentityResource: MobilityIdentityResource
  ) {}

  ngOnInit() {
    const initSub = combineLatest([
      this.global360ViewContext.state$,
      this.siteContext.state$,
      this.currentClusterContext.state$,
      this.route.params,
    ]).subscribe(([global360View, site, currentCluster, params]) => {
      this.serverId = currentCluster.cucmServerId;
      this.originGlobal360 = global360View;
      this.originEndUserResult = global360View.endUsers.find((endUser) => endUser.ref.serverId === this.serverId);
      this.siteId = site.id;
      const { username, targetUsername } = params;
      this.originUsername = username;
      this.targetUsername = targetUsername;
      this.isTargetUserSelected = !!this.targetUsername;
      this.primaryExtension = global360View.primaryExtensions.find((extension) => extension.serverId === this.serverId);
      this.dnAssociatedVm = global360View.voicemails.find(
        (voicemail) => voicemail.extension === this.primaryExtension?.extension
      );
      this.isVoicemailPresentOnLineOne = !!this.dnAssociatedVm;
      this.isLoading = false;
    });
    this.subscriptions.add(initSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onReassignClicked(isKeepVoicemail: boolean) {
    this.isKeepVoicemail = isKeepVoicemail;
    this.targetUserGlobal360 = this.reassignUserExecuteComponent.global360View;
    this.targetEndUserResult = this.targetUserGlobal360.endUsers.find(
      (endUserResult) => endUserResult.ref.serverId === this.serverId
    );
    this.auditTags = [{ name: 'transfer', value: `${this.originUsername} > ${this.targetEndUserResult.ref.username}` }];

    const reassignSub = this.updateTargetEndUser()
      .pipe(
        switchMap(() => this.handleUccx()),
        switchMap(() => this.updateOriginEndUser()),
        switchMap(() => this.transferDevices()),
        finalize(() => {
          this.toastService.push(
            ToastTypes.SUCCESS,
            SmacsIcons.DESKPHONE,
            'tkey;transfer.toast.title',
            'tkey;transfer.toast.message',
            { origin: this.originUsername, destination: this.targetEndUserResult.ref.username }
          );
          this.router.navigate([`/user/${this.targetEndUserResult.ref.username}`]);
        })
      )
      .subscribe();
    this.subscriptions.add(reassignSub);
  }

  private updateTargetEndUser() {
    return forkJoin([
      this.endUserResource.get(this.originEndUserResult.ref.id, this.serverId),
      this.defaultEndUserResource.post({
        hasExtensionMobility: !!this.originGlobal360.extensionMobilities?.length,
        hasSnr: !!this.originGlobal360.snrProfiles?.length,
        hasVoicemail: this.isKeepVoicemail,
        siteId: this.siteId.toString(),
        username: this.targetUsername,
      }),
    ]).pipe(
      switchMap(([originEndUser, targetEndUser]) => {
        this.originEndUser = originEndUser;

        const uniqueGroups = [...originEndUser.groups, ...targetEndUser.groups].reduce((acc, cur) => {
          if (acc.includes(cur)) {
            return acc;
          }
          return [...acc, cur];
        }, []);

        targetEndUser.primaryExtension = this.originGlobal360.primaryExtensions.find(
          (primaryExtension) => primaryExtension.serverId === this.serverId
        );
        targetEndUser.ipccExtension = this.originGlobal360.ipccExtensions.find(
          (ipccExtension) => ipccExtension.serverId === this.serverId
        );
        targetEndUser.groups = uniqueGroups;
        targetEndUser.extensionMobilityCrossCluster = true;
        targetEndUser.enableCti = !!this.originGlobal360.extensionMobilities?.length;
        targetEndUser.enableHomeCluster = true;

        return this.endUserResource.put(targetEndUser, this.serverId, this.auditTags);
      })
    );
  }

  private updateOriginEndUser() {
    this.originEndUser.primaryExtension = null;
    this.originEndUser.ipccExtension = null;
    return this.handleDn().pipe(
      switchMap(() =>
        forkJoin([
          this.setTargetEndUserHomeCluster(),
          this.endUserResource.put(this.originEndUser, this.serverId, this.auditTags),
        ])
      )
    );
  }

  private handleImPresence(): Observable<any> {
    const currentUserHasImPresence = this.originEndUserResult.imPresenceEnabled;
    const targetUserHasImPresence = this.targetEndUserResult.imPresenceEnabled;

    if (currentUserHasImPresence && !targetUserHasImPresence) {
      return this.imPresenceResource
        .delete(this.originEndUserResult.ref.id, this.serverId.toString(), this.auditTags)
        .pipe(
          switchMap(() => this.imPresenceResource.post(this.targetEndUserResult.ref.id, this.serverId, this.auditTags))
        );
    } else if (currentUserHasImPresence && targetUserHasImPresence) {
      return this.imPresenceResource.delete(this.originEndUserResult.ref.id, this.serverId.toString(), this.auditTags);
    } else {
      return of(null);
    }
  }

  private handleUccx() {
    return this.uccxResource.search(this.originUsername).pipe(
      switchMap((originUccxAgentResults: UccxAgentResult[]) => {
        let uccxAgentResults: UccxAgentResult[];

        if (originUccxAgentResults?.length > 1) {
          return this.uccxResource.search(this.targetUsername).pipe(
            switchMap((targetUccxAgentResults) => {
              uccxAgentResults = targetUccxAgentResults;
              const uccxAgentsRequests = uccxAgentResults.map((targetAgentResult) =>
                this.uccxResource.get(targetAgentResult.ref.serverId.toString(), this.originUsername).pipe(
                  switchMap((uccxAgent) => {
                    uccxAgent.id = this.targetUsername;
                    return this.uccxResource.put(uccxAgent, targetAgentResult.ref.serverId, uccxAgent.id);
                  })
                )
              );
              return forkJoin(uccxAgentsRequests);
            })
          );
        } else if (originUccxAgentResults?.length === 1) {
          uccxAgentResults = originUccxAgentResults;
          const uccxAgentsRequests = uccxAgentResults.map((originAgentResult) =>
            this.uccxResource.get(originAgentResult.ref.serverId.toString(), originAgentResult.ref.id).pipe(
              switchMap((uccxAgent) => {
                uccxAgent.id = this.targetUsername;
                return this.uccxResource.put(uccxAgent, originAgentResult.ref.serverId, uccxAgent.id);
              })
            )
          );
          return forkJoin(uccxAgentsRequests);
        } else {
          return of(null);
        }
      })
    );
  }

  private setTargetEndUserHomeCluster() {
    const nonCurrentEndUsers = this.targetUserGlobal360.endUsers
      .filter((endUserResult) => endUserResult.ref.id !== this.targetEndUserResult.ref.id)
      .map((endUserResult) => {
        return this.endUserResource.get(endUserResult.ref.id, endUserResult.ref.serverId).pipe(
          switchMap((endUser) => {
            if (endUser.enableHomeCluster) {
              endUser.enableHomeCluster = false;
              return this.endUserResource.put(endUser, endUserResult.ref.serverId, this.auditTags);
            } else {
              return of(null);
            }
          })
        );
      });
    if (nonCurrentEndUsers?.length) {
      return forkJoin(nonCurrentEndUsers);
    } else {
      return of(null);
    }
  }

  private handleDn() {
    const originDnRef = this.originGlobal360.primaryExtensions.find(
      (extension) => extension.serverId === this.serverId
    );
    return this.dnDetailSummaryResource.get(originDnRef.id, originDnRef.serverId.toString()).pipe(
      switchMap((dnDetailSummary) => {
        this.dnDetailSummary = dnDetailSummary;

        return forkJoin([
          this.primaryExtensionFieldConfigResource.getDnFieldConfig({
            dialPlanGroupId: dnDetailSummary.dialPlanGroupId,
            username: this.targetUsername,
            siteId: this.siteId,
            extension: this.primaryExtension.extension,
            withVoicemail: this.isKeepVoicemail,
          }),
          this.directoryNumberResource.get(originDnRef.id, originDnRef.serverId.toString()),
        ]).pipe(
          switchMap(([defaultDn, originDn]) => {
            originDn.alertingName = defaultDn.alertingName.defaultValue;
            originDn.description = defaultDn.description.defaultValue;
            if (!this.isKeepVoicemail) {
              originDn.voicemailProfile = defaultDn.voicemailProfile.defaultValue;

              originDn.forwardBusyInternal.forwardToVoicemail =
                originDn.forwardBusyExternal.forwardToVoicemail =
                originDn.forwardNoAnswerExternal.forwardToVoicemail =
                originDn.forwardNoAnswerInternal.forwardToVoicemail =
                originDn.forwardNoCoverageExternal.forwardToVoicemail =
                originDn.forwardNoCoverageInternal.forwardToVoicemail =
                originDn.forwardUnregisteredExternal.forwardToVoicemail =
                originDn.forwardUnregisteredInternal.forwardToVoicemail =
                originDn.forwardOnCtiFailure.forwardToVoicemail =
                  this.isKeepVoicemail;
            }

            return this.directoryNumberResource.put(originDn.id, originDn, this.serverId.toString(), this.auditTags);
          })
        );
      })
    );
  }

  private handleAssociatedDids() {
    if (this.originGlobal360.translationPatterns?.length) {
      const tpObservables = this.originGlobal360.translationPatterns.map((tpRef) =>
        forkJoin([
          this.primaryExtensionFieldConfigResource.getTranslationPatternFieldConfig({
            siteId: this.siteId,
            extension: this.primaryExtension.extension,
            username: this.targetEndUserResult.ref.username,
            dialPlanGroupId: this.dnDetailSummary.dialPlanGroupId,
          }),
          this.translationPatternResource.get(tpRef.id, tpRef.serverId),
        ]).pipe(
          switchMap(([tpFieldConfig, translationPattern]) => {
            translationPattern.description = tpFieldConfig.description.defaultValue;
            return this.translationPatternResource.put(tpRef.id, tpRef.serverId, translationPattern, this.auditTags);
          })
        )
      );
      return forkJoin(tpObservables);
    }
    return of(null);
  }

  private handleVoicemail() {
    if (this.isKeepVoicemail && this.isVoicemailPresentOnLineOne) {
      return this.voicemailService.getVoicemailDefault(this.targetEndUserResult.ref.username, this.siteId).pipe(
        switchMap((vmDefaults) => {
          const newVm: Voicemail = {
            id: this.dnAssociatedVm.id,
            extension: this.dnAssociatedVm.extension,
            alias: vmDefaults.alias,
            firstName: vmDefaults.firstName,
            lastName: vmDefaults.lastName,
            displayName: vmDefaults.displayName,
          };
          return this.voicemailResource.put(
            this.dnAssociatedVm.serverId,
            this.dnAssociatedVm.id,
            newVm,
            this.auditTags
          );
        }),
        switchMap(() => {
          return this.vmSmtpNotificationDeviceResource.getDefaultValue({
            endUserUsername: this.targetUsername,
            siteId: this.siteId.toString(),
          });
        }),
        switchMap((smtpAddress) => {
          return this.vmSmtpNotificationDeviceResource.put(
            this.dnAssociatedVm.serverId,
            this.dnAssociatedVm.id,
            { email: smtpAddress.email },
            [
              { name: 'email', value: smtpAddress.email },
              { name: 'Alias', value: this.dnAssociatedVm.alias },
              ...this.auditTags,
            ]
          );
        })
      );
    } else if (this.isVoicemailPresentOnLineOne) {
      return this.voicemailResource.delete(this.dnAssociatedVm.serverId, this.dnAssociatedVm.id, this.auditTags);
    } else {
      return of(null);
    }
  }

  private handleSnr() {
    const snrProfileRef = this.originGlobal360.snrProfiles.find((profileRef) => profileRef.serverId === this.serverId);
    if (!snrProfileRef) return of(null);

    return forkJoin([
      this.lineFeatureConfigResource.post({
        deviceOwner: this.targetUsername,
        extension: this.primaryExtension.extension,
        model: SnrModelProtocol.SNR_MODEL,
        protocol: SnrModelProtocol.SNR_PROTOCOL,
        siteId: this.siteId,
      }),
      this.snrProfileResource.getSnrProfile(snrProfileRef.id, snrProfileRef.serverId),
      this.snrFieldConfigResource.getFieldConfigs({ username: this.targetUsername, siteId: this.siteId.toString() }),
    ]).pipe(
      switchMap(([lineFeatureFieldConfig, snrProfile, snrFieldConfig]) => {
        snrProfile.user = this.targetEndUserResult.ref;
        snrProfile.name = snrFieldConfig.profileName.defaultValue;
        snrProfile.description = snrFieldConfig.profileDescription.defaultValue;

        if (snrProfile.aarCss === null) {
          snrProfile.aarCss = '';
        }

        return this.transferLines(snrProfile, lineFeatureFieldConfig);
      }),
      switchMap((snrProfile) => {
        return this.snrProfileResource.updateSnrProfile(
          snrProfile.id,
          this.serverId,
          snrProfile as SnrProfile,
          this.auditTags
        );
      })
    );
  }

  private handlePhones() {
    if (!this.originGlobal360.phones?.length) return of(null);

    const phoneRequests = this.originGlobal360.phones.map((phoneRef) => {
      return this.phoneResource.get(phoneRef.id, phoneRef.serverId.toString()).pipe(
        switchMap((phone) => {
          const model = phone.model as PhoneDescriptions;
          if (model === KnownPhoneModels.ANDROID || model === KnownPhoneModels.IPHONE) {
            return this.mobilityIdentityResource.getAll(this.serverId.toString(), phone.id).pipe(
              switchMap((mobilityIdentities) => {
                if (mobilityIdentities?.length > 0) {
                  return combineLatest([
                    this.mobilityIdentityResource.delete(this.serverId.toString(), phone.id, mobilityIdentities[0].id, [
                      ...this.auditTags,
                      { name: 'deviceName', value: phone.name },
                    ]),
                    of(phone),
                  ]);
                } else {
                  return of(phone);
                }
              })
            );
          } else {
            return of(phone);
          }
        }),
        switchMap((phone) => {
          if (isArray(phone)) {
            return this.applyNewPhoneOwner(phone[1]);
          } else {
            return this.applyNewPhoneOwner(phone);
          }
        })
      );
    });
    return forkJoin(phoneRequests);
  }

  private updateDefaultsOnPrimaryExtension(lineButton: LineButton, lineFeatureFieldConfig: LineFeatureFieldConfig) {
    if (lineButton.dn.id === this.primaryExtension.id) {
      lineButton.lineFeature.internalCallerId = lineFeatureFieldConfig.internalCallerId.defaultValue;
      lineButton.lineFeature.externalCallerId = lineFeatureFieldConfig.externalCallerId.defaultValue;

      // SNR lines don't have labels
      if (lineButton.lineFeature.label !== undefined) {
        lineButton.lineFeature.label = lineFeatureFieldConfig.label.defaultValue;
      }
    }
  }

  private applyNewPhoneOwner(phone: Phone) {
    const index = phone.associatedEndUsers.findIndex((endUser) => endUser.id === this.originEndUserResult.ref.id);
    phone.associatedEndUsers.splice(index, 1);
    phone.owner = this.targetEndUserResult.ref;
    phone.associatedEndUsers.push(this.targetEndUserResult.ref);

    const phoneFieldConfigRequest: PhoneFieldConfigRequestParams = {
      model: phone.model,
      protocol: phone.protocol,
      siteId: this.siteId.toString(),
      username: this.targetUsername,
    };

    const lineFeatureFieldConfigRequest: LineFeatureFieldConfigRequest = {
      deviceOwner: this.targetUsername,
      extension: this.primaryExtension.extension,
      model: phone.model,
      protocol: phone.protocol,
      siteId: this.siteId,
    };
    return forkJoin([
      this.phoneFieldConfigResource.getPhoneFieldConfig(phoneFieldConfigRequest),
      this.lineFeatureConfigResource.post(lineFeatureFieldConfigRequest),
    ]).pipe(
      switchMap(([phoneFieldConfig, lineFeatureFieldConfig]) => {
        if (isNonDeskPhone(phone.model)) {
          phone.name = phoneFieldConfig.name.defaultValue;
        }
        phone.description = phoneFieldConfig.description.defaultValue;

        return this.transferLines(phone, lineFeatureFieldConfig).pipe(
          switchMap((phone) => this.phoneResource.put(phone as Phone, this.serverId.toString(), this.auditTags))
        );
      })
    );
  }

  private transferLines(
    device: Phone | ExtensionMobility | SnrProfile,
    lineFeatureFieldConfig: LineFeatureFieldConfig
  ) {
    if ('buttons' in device) {
      device.buttons.forEach((button) => {
        if (button.type === 'Line') {
          const newButton = button as LineButton;
          if (newButton.dn && newButton.lineFeature) {
            this.disassociateOriginWithLine(newButton);
            this.associateTargetWithLine(newButton);
            this.updateDefaultsOnPrimaryExtension(newButton, lineFeatureFieldConfig);
          }
        }
      });
    }

    if ('lines' in device) {
      device.lines.forEach((line) => {
        if (line.dn && line.lineFeature) {
          this.disassociateOriginWithLine(line);
          this.associateTargetWithLine(line);
          this.updateDefaultsOnPrimaryExtension(line, lineFeatureFieldConfig);
        }
      });
    }

    return of(device);
  }

  private associateTargetWithLine(lineButton: LineButton) {
    lineButton.lineFeature.associatedEndUsers = lineButton.lineFeature.associatedEndUsers || [];

    const index = lineButton.lineFeature.associatedEndUsers.findIndex((associatedEndUser) => associatedEndUser.id);

    if (index === -1) {
      lineButton.lineFeature.associatedEndUsers.push({
        id: this.targetEndUserResult.ref.id,
        url: `/services/cisco/macs/cucm-servers/${this.serverId}/end-users/${encodeURIComponent(
          this.targetEndUserResult.ref.id
        )}`,
        serverId: this.serverId,
        username: this.targetEndUserResult.ref.username,
        lastName: this.targetEndUserResult.ref.lastName,
        firstName: this.targetEndUserResult.ref.firstName,
      });
    }
  }

  private disassociateOriginWithLine(lineButton: LineButton) {
    lineButton.lineFeature.associatedEndUsers = lineButton.lineFeature.associatedEndUsers || [];

    const endUserIndex = lineButton.lineFeature.associatedEndUsers.findIndex(
      (associatedEndUser: EndUserRef) => associatedEndUser.id
    );

    if (endUserIndex !== -1) {
      lineButton.lineFeature.associatedEndUsers.splice(endUserIndex, 1);
    }
  }

  private handleExtensionMobility() {
    if (!this.originGlobal360.extensionMobilities?.length) return of(null);

    const extensionMobilityRequests = this.originGlobal360.extensionMobilities.map((extensionMobilityRef) =>
      this.extensionMobilityResource.get(extensionMobilityRef.id, this.serverId.toString()).pipe(
        switchMap((extensionMobility) => {
          const associatedEndUserIndex = extensionMobility.associatedEndUsers.findIndex(
            (associatedEndUserRef) => associatedEndUserRef.id
          );
          if (associatedEndUserIndex !== -1) extensionMobility.associatedEndUsers.splice(associatedEndUserIndex, 1);
          extensionMobility.associatedEndUsers.push(this.targetEndUserResult.ref);

          const ctiIndex = extensionMobility.ctiAssociatedEndUsers.findIndex((ctiEndUserRef) => ctiEndUserRef.id);
          if (ctiIndex !== -1) extensionMobility.ctiAssociatedEndUsers.splice(ctiIndex, 1);
          extensionMobility.ctiAssociatedEndUsers.push(this.targetEndUserResult.ref);

          const extensionMobilityFieldConfigRequest: ExtensionMobilityFieldConfigRequest = {
            model: extensionMobility.model,
            protocol: extensionMobility.protocol,
            siteId: this.siteId.toString(),
            username: this.targetUsername,
          };

          const lineFeatureFieldConfigRequest: LineFeatureFieldConfigRequest = {
            deviceOwner: this.targetUsername,
            extension: this.primaryExtension.extension,
            model: extensionMobility.model,
            protocol: extensionMobility.protocol,
            siteId: this.siteId,
          };
          return forkJoin([
            of(extensionMobility),
            this.lineFeatureConfigResource.post(lineFeatureFieldConfigRequest),
            this.phoneFieldConfigResource.getExtMobilityFieldConfig(extensionMobilityFieldConfigRequest),
          ]);
        }),
        switchMap(([extensionMobility, lineFeatureFieldConfig, extensionMobilityFieldConfig]) => {
          extensionMobility.name = extensionMobilityFieldConfig.name.defaultValue;
          extensionMobility.description = extensionMobilityFieldConfig.description.defaultValue;

          return this.transferLines(extensionMobility, lineFeatureFieldConfig);
        }),
        switchMap((extensionMobility) =>
          this.extensionMobilityResource.put(
            extensionMobility as ExtensionMobility,
            this.serverId.toString(),
            this.auditTags
          )
        )
      )
    );
    return forkJoin(extensionMobilityRequests);
  }

  private handleLdap() {
    const isOriginLdapUser = this.originEndUserResult.type === 'LDAP_ACTIVE';
    const isTargetLdapUser = this.targetEndUserResult.type === 'LDAP_ACTIVE';
    const hasExistingDialPlanGroup = !!this.dnDetailSummary?.dialPlanGroupId;
    const ldapUserDialPlanDetailsFieldConfigRequest: LdapUserDialPlanDetailsFieldConfigRequest = {
      dialPlanGroupId: this.dnDetailSummary.dialPlanGroupId,
      extension: this.primaryExtension.extension,
    };
    const originLdapUserDialPlanAttributes: LdapUserDialPlanAttributes = {
      extension: '',
      did: '',
      username: this.originUsername,
    } as LdapUserDialPlanAttributes;

    if (isOriginLdapUser && isTargetLdapUser && hasExistingDialPlanGroup) {
      return this.ldapUserResource.get(this.originUsername).pipe(
        switchMap((ldapDialPlanAttributes) => {
          const { extension, did, username } = ldapDialPlanAttributes;
          const targetLdapUserDialPlanAttributes: LdapUserDialPlanAttributes = {
            extension,
            did,
            username: this.targetUsername,
          };

          return forkJoin([
            this.ldapUserResource.put(originLdapUserDialPlanAttributes, this.auditTags),
            this.ldapUserResource.put(targetLdapUserDialPlanAttributes, this.auditTags),
          ]);
        })
      );
    } else if (isTargetLdapUser && hasExistingDialPlanGroup) {
      return this.primaryExtensionFieldConfigResource
        .getLdapUserDialPlanFieldConfig(ldapUserDialPlanDetailsFieldConfigRequest)
        .pipe(
          switchMap((ldapUserDialPlanDetailsFieldConfig) => {
            const targetLdapUserDialPlanAttributes: LdapUserDialPlanAttributes = {
              extension: ldapUserDialPlanDetailsFieldConfig.extension.defaultValue,
              did: ldapUserDialPlanDetailsFieldConfig.e164Number.defaultValue,
              username: this.targetUsername,
            } as LdapUserDialPlanAttributes;

            return this.ldapUserResource.put(targetLdapUserDialPlanAttributes, this.auditTags);
          })
        );
    }
    if (isOriginLdapUser && hasExistingDialPlanGroup) {
      return this.ldapUserResource.put(originLdapUserDialPlanAttributes, this.auditTags);
    }

    return of(null);
  }

  private transferDevices() {
    return forkJoin([
      this.handleImPresence(),
      this.handleAssociatedDids(),
      this.handlePhones(),
      this.handleVoicemail(),
      this.handleSnr(),
      this.handleExtensionMobility(),
      this.handleLdap(),
    ]);
  }
}
