import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BulkFieldCopyRequest, Site } from '../../../../shared/models/generated/smacsModels';

@Injectable()
export class SitesResource {
  constructor(private http: HttpClient) {}

  getAll = (clusterId: number): Observable<Site[]> => {
    return this.http.get<Site[]>(`/services/cisco/admin/clusters/${clusterId}/sites`);
  };

  get = (clusterId: number, siteId: number): Observable<Site> => {
    return this.http.get<Site>(`/services/cisco/admin/clusters/${clusterId}/sites/${encodeURIComponent(siteId)}`);
  };

  post = (clusterId: number, site: Site): Observable<number> => {
    return this.http.post<number>(`/services/cisco/admin/clusters/${clusterId}/sites/`, site);
  };

  put = (clusterId: number, site: Site): Observable<Site> => {
    return this.http.put<Site>(`/services/cisco/admin/clusters/${clusterId}/sites/${site.id}`, site);
  };

  delete = (clusterId: number, siteId: number): Observable<void> => {
    return this.http.delete<void>(`/services/cisco/admin/clusters/${clusterId}/sites/${siteId}`);
  };

  bulkFieldCopy = (clusterId: number, requestBody: BulkFieldCopyRequest): Observable<void> => {
    return this.http.post<void>(
      `/services/cisco/admin/clusters/${clusterId}/sites/${encodeURIComponent(requestBody.siteId)}/bulk-field-copy`,
      requestBody
    );
  };
}
