import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  SnrDestination,
  SnrDestinationRef,
  SnrDestinationResult,
  SnrProfile,
  SnrProfileRef,
} from '../../shared/models/generated/smacsModels';
import { Observable } from 'rxjs';
import { AuditHeaderService } from '../../shared/services/audit-header.service';
import { Nvp } from '../../shared/models/nvp';

@Injectable()
export class SnrProfileResource {
  limit = 100;

  constructor(private http: HttpClient, private auditHeaderService: AuditHeaderService) {}

  private _buildHttpOptions(auditTags: Nvp[]) {
    return auditTags ? { headers: this.auditHeaderService.buildHeader(auditTags) } : {};
  }

  getSnrDestinations(snrProfileId: string, serverId: number): Observable<SnrDestination[]> {
    return this.http.get<SnrDestination[]>(
      `/services/cisco/macs/cucm-servers/${serverId}/snr-profiles/${snrProfileId}/snr-destinations`
    );
  }

  updateDestination(
    serverId: number,
    snrProfileId: string,
    snrDestination: SnrDestination,
    auditTags: Nvp[]
  ): Observable<SnrDestinationRef> {
    const httpOptions = this._buildHttpOptions(auditTags);

    return this.http.put<SnrDestinationRef>(
      `/services/cisco/macs/cucm-servers/${serverId.toString()}/snr-profiles/${snrProfileId}/snr-destinations/${
        snrDestination.id
      }`,
      snrDestination,
      httpOptions
    );
  }

  deleteDestination(
    serverId: number,
    snrProfileId: string,
    snrDestination: SnrDestination,
    auditTags: Nvp[]
  ): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);

    return this.http.delete<void>(
      `/services/cisco/macs/cucm-servers/${serverId.toString()}/snr-profiles/${snrProfileId}/snr-destinations/${
        snrDestination.id
      }`,
      httpOptions
    );
  }

  createDestination(
    snrDestination: SnrDestination,
    serverId: number,
    snrProfileId: string,
    auditTags: Nvp[]
  ): Observable<SnrDestinationRef> {
    const httpOptions = this._buildHttpOptions(auditTags);

    delete snrDestination.id;
    return this.http.post<SnrDestinationRef>(
      `/services/cisco/macs/cucm-servers/${serverId.toString()}/snr-profiles/${snrProfileId}/snr-destinations`,
      snrDestination,
      httpOptions
    );
  }

  makeDestinationRequest(
    snrProfileId: string,
    snrDestination: SnrDestination,
    serverId: number,
    auditTags: Nvp[]
  ): Observable<SnrDestinationRef | void> {
    if (snrDestination.id.includes('-')) {
      if (snrDestination.destinationNumber) {
        return this.updateDestination(serverId, snrProfileId, snrDestination, auditTags);
      } else {
        return this.deleteDestination(serverId, snrProfileId, snrDestination, auditTags);
      }
    } else {
      return this.createDestination(snrDestination, serverId, snrProfileId, auditTags);
    }
  }

  createSnrProfile(cucmServerId: number, snrProfile: SnrProfile, auditTags?: Nvp[]): Observable<SnrProfileRef> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.post<SnrProfileRef>(
      `/services/cisco/macs/cucm-servers/${cucmServerId}/snr-profiles`,
      snrProfile,
      httpOptions
    );
  }

  getSnrProfile(snrProfileId: string, serverId: number): Observable<SnrProfile> {
    return this.http.get<SnrProfile>(
      `/services/cisco/macs/cucm-servers/${serverId}/snr-profiles/${encodeURIComponent(snrProfileId)}`
    );
  }

  updateSnrProfile(
    snrProfileId: string,
    serverId: number,
    snrProfile: SnrProfile,
    auditTags?: Nvp[]
  ): Observable<SnrProfileRef> {
    const httpOptions = this._buildHttpOptions(auditTags);
    const url = `/services/cisco/macs/cucm-servers/${serverId}/snr-profiles/${snrProfileId}`;
    return this.http.put<SnrProfileRef>(url, snrProfile, httpOptions);
  }

  deleteSnrProfile(snrProfileId: string, serverId: number, auditTags?: Nvp[]): Observable<void> {
    const httpOptions = this._buildHttpOptions(auditTags);
    return this.http.delete<void>(
      `/services/cisco/macs/cucm-servers/${serverId}/snr-profiles/${snrProfileId}`,
      httpOptions
    );
  }

  searchSnrDestinations(destination: string, cucmServerId: number): Observable<SnrDestinationResult[]> {
    let param = `destination=${encodeURIComponent(destination)}`;
    if (cucmServerId) {
      param += `&cucmServerId=${cucmServerId.toString()}`;
    }
    return this.http.get<SnrDestinationResult[]>(
      `/services/cisco/macs/snr-destinations/search?${param}&limit=${this.limit}`
    );
  }

  searchDestination(destination: string, cucmServerId: number): Observable<SnrDestinationResult[]> {
    return this.searchSnrDestinations(destination, cucmServerId);
  }
}
