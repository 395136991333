import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { SmacsFormAbstractDirective } from '../../smacs-form-abstract.directive';
import { SmacsFormConfig, SmacsFormsUpdate, SmacsFormsValidationState } from '../../smacs-forms-models';
import { cloneDeep, isEqual } from 'lodash';
import {
  ExtensionRange,
  ExtensionRanges,
  ExtensionRangesOptionalValidators,
  SmacsRangeGroup,
} from './smacs-extension-range-models';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { of, Subscription } from 'rxjs';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import { CallingType, DialPlanManagementGroup, SiteSummary } from '../../../shared/models/generated/smacsModels';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ActivatedRoute } from '@angular/router';
import {
  ExtensionRangeValidationResult,
  SmacsExtensionRangesValidationUiContext,
} from './smacs-extension-ranges-validation.ui-context';

@Component({
  selector: 'smacs-extension-ranges-form',
  templateUrl: './smacs-extension-ranges-form.component.html',
  styleUrls: ['./smacs-extension-ranges-form.component.scss'],
  providers: [SmacsExtensionRangesValidationUiContext],
})
export class SmacsExtensionRangesFormComponent
  extends SmacsFormAbstractDirective<ExtensionRange[], ExtensionRanges>
  implements OnInit, OnChanges, OnDestroy
{
  @Input() optionalValidators: ExtensionRangesOptionalValidators;
  @Input() upstreamEntity: ExtensionRange[];
  @Input() isFormSubmitted: boolean;
  @Input() siteSummary: SiteSummary;
  @Input() isZpm?: boolean;
  @Input() isDisabled = false;
  @Input() zpmCallingType: CallingType;

  @Output() rangeDelete = new EventEmitter();
  @ViewChild(CdkVirtualScrollViewport) virtualScrollViewport: CdkVirtualScrollViewport;
  buttonStyles = ButtonStyles;
  smacsIcons = SmacsIcons;
  buttonSize = ButtonSizes;
  clusterId: number;
  ciscoDialPlanGroups: DialPlanManagementGroup[];
  rangeType: 'DN' | 'DID';
  maxLengthValidation: number;
  minLengthValidation: number;
  isModifiedExtensionRangeForm: boolean;
  existingPortInDraftNumbers: string[];
  groups: SmacsRangeGroup[];
  safeEntity: ExtensionRange[];
  fieldIds = [] as { fieldId: `range${number}`; externalValidationState: ExtensionRangeValidationResult }[];

  formConfig = {
    fields: {},
  } as SmacsFormConfig;

  private subscriptions = new Subscription();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    protected smacsFormStateService: SmacsFormStateService,
    private route: ActivatedRoute,
    private smacsExtensionRangesValidationUiContext: SmacsExtensionRangesValidationUiContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit() {
    const routeSub = this.route.data.subscribe((data) => {
      this.isModifiedExtensionRangeForm = data.isModifiedExtensionRangeForm;
    });
    this.subscriptions.add(routeSub);
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    const validators = changes.optionalValidators as {
      currentValue: ExtensionRangesOptionalValidators;
      previousValue: ExtensionRangesOptionalValidators;
    };

    if (validators) {
      this.smacsExtensionRangesValidationUiContext.reset();

      this.smacsExtensionRangesValidationUiContext.addValidator('requiredValidator');

      if (validators?.currentValue?.minLengthValidation) {
        this.smacsExtensionRangesValidationUiContext.addValidator('minLengthValidator');
        this.minLengthValidation = validators.currentValue.minLengthValidation;
      }
      if (validators?.currentValue?.maxLengthValidation) {
        this.smacsExtensionRangesValidationUiContext.addValidator('maxLengthValidator');
        this.maxLengthValidation = validators.currentValue.maxLengthValidation;
      }
      if (validators?.currentValue?.strictE164Validation) {
        this.smacsExtensionRangesValidationUiContext.addValidator('e164Validator');
      }
      if (validators?.currentValue?.isExtensionRangeType) {
        this.smacsExtensionRangesValidationUiContext.addValidator('extensionTypePlusSignValidator');
      }
      if (validators?.currentValue?.noLeadingZeroes) {
        this.smacsExtensionRangesValidationUiContext.addValidator('noLeadingZeroes');
      }

      if (this.zpmCallingType === CallingType.EXTENSION || !this.zpmCallingType) {
        this.smacsExtensionRangesValidationUiContext.addValidator('patternValidator');
      }

      this.smacsExtensionRangesValidationUiContext.addValidator('isStartAndEndSamePlusStatus');
      this.smacsExtensionRangesValidationUiContext.addValidator('isStartBeforeEnd');

      if (validators?.currentValue?.hasSameLengthValidation) {
        this.smacsExtensionRangesValidationUiContext.addValidator('sameLengthValidator');
      }
      if (validators?.currentValue?.maxDiffValidation) {
        this.smacsExtensionRangesValidationUiContext.addValidator('maxDiffValidator');
      }

      if (validators?.currentValue?.overlappingRanges) {
        this.smacsExtensionRangesValidationUiContext.addValidator('overlappingRanges');
      }

      if (validators?.currentValue?.overlappingRangesInOtherCiscoDialPlanGroups) {
        this.smacsExtensionRangesValidationUiContext.addValidator('overlappingRangesInOtherCiscoDialPlanGroups');
        this.ciscoDialPlanGroups = validators.currentValue.overlappingRangesInOtherCiscoDialPlanGroups.dialPlanGroups;
        this.clusterId = validators.currentValue.overlappingRangesInOtherCiscoDialPlanGroups.clusterId;
        this.rangeType = validators.currentValue.overlappingRangesInOtherCiscoDialPlanGroups.range;
      }

      if (validators?.currentValue?.overlappingRangesInOtherGroups) {
        this.smacsExtensionRangesValidationUiContext.addValidator('overlappingRangesInOtherGroups');
        this.groups = validators.currentValue.overlappingRangesInOtherGroups.groups;
      }

      if (validators?.currentValue?.overlappingRangesInExistingPortInDraft) {
        this.smacsExtensionRangesValidationUiContext.addValidator('overlappingRangesInExistingPortInDraft');
        this.smacsExtensionRangesValidationUiContext.addValidator('canadianNumbersRestricted');
        this.existingPortInDraftNumbers =
          validators.currentValue.overlappingRangesInExistingPortInDraft.existingPortInDraftNumbers;
      }
    }

    const entity = changes.upstreamEntity as {
      currentValue: ExtensionRange[];
      previousValue: ExtensionRange[];
    };

    this._validateFields(entity?.currentValue || this.entity);

    if (entity && entity.currentValue) {
      this._updateEntityFromUpstreamSource(entity.currentValue);
    }

    const isFormSubmitted = changes.isFormSubmitted as {
      currentValue: boolean;
      previousValue: boolean;
    };
    if (isFormSubmitted && isFormSubmitted.currentValue) {
      this._setIsFormSubmitted(true);
    }
  }

  getFormValidationState(): SmacsFormsValidationState {
    let hasWarning = false;
    for (const field of this.fieldIds) {
      if (
        field.externalValidationState.start.state === SmacsFormsValidationState.INVALID ||
        field.externalValidationState.end.state === SmacsFormsValidationState.INVALID
      ) {
        return SmacsFormsValidationState.INVALID;
      }
      if (
        field.externalValidationState.start.state === SmacsFormsValidationState.WARNING ||
        field.externalValidationState.end.state === SmacsFormsValidationState.WARNING
      ) {
        hasWarning = true;
      }
    }
    return hasWarning ? SmacsFormsValidationState.WARNING : SmacsFormsValidationState.VALID;
  }

  addItem(manualAdd = false) {
    const fieldKeys = Object.keys(this.formConfig.fields);
    const newFieldIndex = fieldKeys.length ? Number(fieldKeys[fieldKeys.length - 1].slice(5)) + 1 : 0;
    const newFieldName: `range${number}` = `range${newFieldIndex}`;
    this.formConfig.fields[newFieldName] = {};

    this.fieldIds = [
      ...this.fieldIds,
      {
        fieldId: newFieldName,
        externalValidationState: {
          start: { state: SmacsFormsValidationState.VALID },
          end: { state: SmacsFormsValidationState.VALID },
        },
      },
    ];

    if (manualAdd) {
      const newEntity = [...this.entity, { start: '', end: '' }];
      this._validateFields(newEntity);
      this.safeEntity = newEntity;
      this.entitySource.next([...newEntity]);
      this.scrollToBottom();
    }
  }

  scrollToBottom() {
    setTimeout(() => {
      this.virtualScrollViewport.scrollTo({
        bottom: 0,
        behavior: 'auto',
      });
      setTimeout(() => {
        this.virtualScrollViewport.scrollTo({
          bottom: 0,
          behavior: 'auto',
        });
      }, 10);
    }, 0);
  }

  deleteItem(index: number) {
    const removedField = this.fieldIds[index].fieldId;
    this.fieldIds = [...this.fieldIds.slice(0, index), ...this.fieldIds.slice(index + 1)];
    const newEntity = [...this.entity.slice(0, index), ...this.entity.slice(index + 1)];
    this._validateFields(newEntity);
    this.entity = [...newEntity];
    this.safeEntity = [...newEntity];
    this.entitySource.next([...newEntity]);
    delete this.formData[removedField];
    delete this.formConfig.fields[removedField];
    this.changeDetectorRef.detectChanges();
    this.rangeDelete.emit();
  }

  toEntity = (formData: ExtensionRanges): ExtensionRange[] => {
    return Object.values(formData);
  };

  toFormData = (entity: ExtensionRange[]): ExtensionRanges => {
    const numberRanges = {} as ExtensionRanges;
    if (entity) {
      entity.forEach((value, index) => {
        numberRanges[this.fieldIds[index].fieldId] = value;
      });
    }
    this.changeDetectorRef.detectChanges();
    return numberRanges;
  };

  /**
   * When range sub component changes update the state of this component excluding safeEntity. Safe entity is the UI
   * representation, changing it will cause a refresh of the list, and the user to lose their cursor placement
   */
  onSingleRangeUpdate(update: { $event: SmacsFormsUpdate<ExtensionRange>; index: number }) {
    const newEntity = this.entity.map((range: ExtensionRange, i) => {
      if (i === update.index) {
        return {
          ...update.$event.new,
        };
      }
      return range;
    });
    if (isEqual(this.entity, newEntity)) {
      return;
    }

    this._validateFields(newEntity);
    this.entity = [...newEntity];
    this.entitySource.next([...newEntity]);
    this.changeDetectorRef.detectChanges();
  }

  protected submit() {
    return of(null);
  }

  private _updateEntityFromUpstreamSource = (entity: ExtensionRange[]) => {
    if (!this.entity) {
      entity.forEach(() => this.addItem());
    } else if (this.entity.every((existingRange: ExtensionRange, i: number) => isEqual(entity[i], existingRange))) {
      // This is just adding a few more ranges to the existing entity
      const addedRanges = entity.slice(this.entity.length);
      addedRanges.forEach(() => this.addItem());
    }
    this._validateFields(entity);
    this.safeEntity = cloneDeep(entity);
    this.entitySource.next(entity);
  };

  private _validateFields(entity: ExtensionRange[]) {
    this.fieldIds.forEach((field, i) => {
      field.externalValidationState = this.smacsExtensionRangesValidationUiContext.validate({
        value: entity[i],
        ciscoDialPlanGroups: this.ciscoDialPlanGroups,
        rangeType: this.rangeType,
        clusterId: this.clusterId,
        groups: this.groups,
        existingPortInDraftNumbers: this.existingPortInDraftNumbers,
        minLength: this.minLengthValidation,
        maxLength: this.maxLengthValidation,
        zpmCallingType: !!this.zpmCallingType,
        siteSummary: this.siteSummary,
        ranges: entity,
      });
    });
  }
}
