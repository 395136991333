import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { Component } from '@angular/core';
import { SmacsFieldComponentConfig } from '../../smacs-forms-models';
import { SmacsFormStateService } from '../../smacs-form-state.service';

export class SmacsColorPickerComponentConfig extends SmacsFieldComponentConfig {
  constructor() {
    super();
  }
}

@Component({
  selector: 'smacs-color-picker',
  templateUrl: './smacs-color-picker.component.html',
  styleUrls: ['./smacs-color-picker.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsColorPickerComponent }],
})
export class SmacsColorPickerComponent<T> extends SmacsFieldAbstractDirective<
  string,
  string,
  SmacsColorPickerComponentConfig
> {
  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }
}
