import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { ZpmTileConfig } from '../../../shared/models/generated/smacsModels';
import { map } from 'rxjs/operators';

@Injectable()
export class MicrosoftTileConfigContext {
  private _stateSource = new ReplaySubject<ZpmTileConfig>(1);
  state$ = this._stateSource.asObservable();

  constructor(private http: HttpClient) {
    this.refresh().subscribe();
  }

  refresh(): Observable<void> {
    return this._get().pipe(map((tileConfig) => this._stateSource.next(tileConfig)));
  }

  private _get(): Observable<ZpmTileConfig> {
    return this.http.get<ZpmTileConfig>('/services/microsoft/field-configs/tile-configs');
  }
}
