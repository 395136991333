import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BottomNavButton, BottomNavButtonState, BottomNavState } from './bottom-nav.component';

/**
 * This constructor initializes the initial buttons list with all properties of {@link BottomNavButton}
 */
export class BottomNavUpdateButtonsList {
  constructor(public payload: BottomNavButton[]) {}
}

/** Update the state of a button to pending and/or disabled*/
export class BottomNavUpdateButtonState {
  constructor(public payload: { id: string; state: BottomNavButtonState }) {}
}

/** Update the validation state of bottom nav buttons and display help text messages when form is invalid on submit.
 * See {@link BottomNavState.hasValidationError} */
export class BottomNavUpdateState {
  constructor(public payload: BottomNavState) {}
}

/**
 * Clear the bottom nav buttons list when required (especially on ngOnDestroy)
 */
export class BottomNavClearButtonsList {}

export type BottomNavAction =
  | BottomNavUpdateButtonsList
  | BottomNavUpdateButtonState
  | BottomNavUpdateState
  | BottomNavClearButtonsList;

@Injectable()
export class BottomNavService {
  constructor() {}

  private bottomNavChannelSource = new Subject<any>();

  bottomNavChannel$ = this.bottomNavChannelSource.asObservable();

  /**
   * Add button list to bottom Nav or update states of buttons. Update a single button or a list of buttons.
   * @param action The object containing the desired action (i.e. {@link BottomNavAction}) for bottom nav buttons.
   * For more, see constructor in {@link BottomNavComponent}
   */
  dispatch = (action: BottomNavAction) => {
    this.bottomNavChannelSource.next(action);
  };

  setBottomNavValidationError(hasValidationError: boolean) {
    this.dispatch(
      new BottomNavUpdateState({
        hasValidationError: hasValidationError,
      })
    );
  }

  setButtonPendingState(id: string, state: boolean) {
    this.dispatch(
      new BottomNavUpdateButtonState({
        id: id,
        state: {
          pending: state,
          buttonDisableState: {
            disabled: state,
          },
        },
      })
    );
  }

  setButtonDisabledState(id: string, setting: boolean, tooltipKey = '') {
    this.dispatch(
      new BottomNavUpdateButtonState({
        id: id,
        state: {
          pending: false,
          buttonDisableState: {
            disabled: setting,
            tooltipKey: tooltipKey,
          },
          tooltipVisible: !!tooltipKey,
        },
      })
    );
  }
}
