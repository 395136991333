<div
  class="row mb-3"
  [attr.data-automation]="dataAutomation"
  [ngbTooltip]="'tkey;dialplanmanagement.admin.group.writeldap.disabledtooltip' | translate"
  [disableTooltip]="!getDisabledState()"
>
  <div class="col-md-12 position-relative">
    <smacs-text class="w-100" [fieldId]="'defaultValue'" #defaultValue>
      <button
        *ngIf="displayEditor"
        type="button"
        class="btn btn-default btn-expression-editor"
        data-automation="edit-expression-editor"
        (click)="openModal()"
        [disabled]="getDisabledState()"
      >
        <i [attr.class]="smacsIcons.EDIT"></i>
        {{ 'tkey;admin.site_management.custom_input_text_modal.mandatory_functions.button' | translate }}
      </button>
    </smacs-text>
  </div>
  <div class="col-md-12 text-config-extras">
    <div class="row">
      <div class="offset-lg-3 col-lg-9" [ngClass]="{ 'text-config-extras--helpless': !helpText }">
        <div *ngIf="entity?.defaultValue">
          <span class="me-1">
            <i [attr.class]="smacsIcons.PREVIEW + ' d-inline-block vertical-align-middle'"></i>
            <span>{{ 'tkey;site_management.site.section.preview.label' | translate }}</span>
          </span>
          <span data-automation="text-config-form-preview">{{ formData.preview }}</span>
        </div>
        <div class="d-flex">
          <smacs-checkbox [fieldId]="'required'"></smacs-checkbox>
          <smacs-checkbox [fieldId]="'show'" class="ms-4"></smacs-checkbox>
        </div>
        <div
          *ngIf="helpText"
          class="small form-text text-muted text-config-extras__help-text"
          [innerHtml]="helpText | translate"
        ></div>
      </div>
    </div>
  </div>
</div>
