import { AfterViewInit, Component, ElementRef, Input, Optional, Renderer2, ViewChild } from '@angular/core';
import { AboutCardService } from '../../services/microsoft/about-card.service';
import { BreadcrumbsComponent } from '../breadcrumbs.component';

@Component({
  selector: 'app-breadcrumb-card-item',
  templateUrl: './breadcrumb-card-item.component.html',
})
export class BreadcrumbCardItemComponent implements AfterViewInit {
  @Input() aboutCardId: number;
  @ViewChild('aboutCard') aboutCard: ElementRef;
  shown = false;
  aboutCardMarkup: string;

  constructor(
    @Optional() public ancestorBreadcrumbsComponent: BreadcrumbsComponent,
    private aboutCardService: AboutCardService,
    private renderer: Renderer2
  ) {}

  ngAfterViewInit() {
    if (!!this.aboutCardId) {
      this.aboutCardService.getAboutCard(this.aboutCardId).subscribe((html) => {
        if (!!html) {
          if (!this.ancestorBreadcrumbsComponent) {
            this.shown = true;
          }
          this.aboutCardMarkup = html;
          setTimeout(() => {
            if (!!this.aboutCard) {
              const aboutCard = this.aboutCard.nativeElement as HTMLElement;
              this.renderer.setStyle(aboutCard.lastChild, 'margin-bottom', 0);
            }
          });
        }
      });
    }
  }
}
