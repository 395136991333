<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item [aboutCardId]="34"></app-breadcrumb-card-item>
    </app-breadcrumbs>
    <div class="card mt-3">
      <div class="card-header">{{ 'tkey;admin.msgraph.management.config.details.title' | translate }}</div>
      <div class="card-body">
        <smacs-text [fieldId]="'tenant'"></smacs-text>
        <smacs-text [fieldId]="'clientId'"></smacs-text>
        <smacs-text [fieldId]="'clientSecret'"></smacs-text>
        <smacs-select [fieldId]="'environmentType'"></smacs-select>
      </div>
    </div>
    <div class="alert alert-info refresh-alert">
      {{ 'tkey;admin.powershell_management.refresh.alert.text' | translate }}
      <br />
      <a *ngIf="!isRefreshing" class="strong cache-refresh-link" (click)="refreshCache()" target="_blank">
        <i [attr.class]="smacsIcons.REFRESH"></i>
        {{ 'tkey;admin.powershell_management.refresh.link.text' | translate }}
      </a>

      <a *ngIf="isRefreshing" class="strong text-warning disabled disabled-refresh-link" target="_blank">
        <i [attr.class]="smacsIcons.SAVING"></i>
        {{ 'tkey;admin.powershell_management.refreshing.link.text' | translate }}
      </a>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
