<div data-automation="detailed-modal" id="detailed-modal">
  <div class="modal-header modal-header--bordered d-flex align-items-center justify-content-between">
    <i *ngIf="modalViewProperties.icon" [ngClass]="modalViewProperties.icon" class="fs-4 me-2"></i>
    <h5 class="mb-0" data-automation="detailed-modal-title" [innerHTML]="modalViewProperties.title | translate"></h5>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      data-automation="detailed-modal-close-button"
      [disabled]="bodyComponent?.instance?.isSubmitting"
      (click)="onCancelClick()"
    ></button>
  </div>

  <div class="modal-body modal-body--dynamic py-0">
    <div class="row modal-body--dynamic__scrollable py-3" [ngClass]="modalViewProperties.modalBodyClass">
      <div class="col-md-12" [style.pointer-events]="isPending ? 'none' : 'auto'" [ngClass]="{ disabled: isPending }">
        <ng-container #modalBody></ng-container>
      </div>
    </div>
  </div>

  <div class="modal-footer modal-footer--bordered flex-column align-items-end">
    <div
      class="alert alert-danger text-center animated shake d-flex align-items-center justify-content-center"
      [ngClass]="{
        'w-100': size === 'md',
        'w-200': size === 'lg',
        'min-width-25': size === 'xl',
        'modal-xxl ps-4': size === 'xxl'
      }"
      *ngIf="(isErrorTemplatePresent && isSubmitted && !bodyComponent?.instance?.isFormValid()) || errorMessage"
    >
      <i class="fa fa-exclamation fa-lg me-1"></i>
      <div data-automation="detailed-modal-form-error-message">
        <div *ngIf="errorMessage" [innerHTML]="errorMessage"></div>
        <ng-container *ngIf="!errorMessage" [ngTemplateOutlet]="bodyComponent?.instance?.errorTemplate"></ng-container>
      </div>
    </div>

    <div class="text-end">
      <smacs-button
        [dataAutomation]="'detailed-modal-cancel-button'"
        [label]="'tkey;global.button.cancel.text'"
        [isDisabled]="isPending || isLoading"
        [buttonStyle]="buttonStyles.DEFAULT"
        [buttonSize]="buttonSizes.LARGE"
        (clicked)="onCancelClick()"
        [cssClass]="'me-1'"
      ></smacs-button>

      <smacs-button
        *ngIf="modalViewProperties.showDeleteButton"
        [dataAutomation]="'detailed-modal-delete-button'"
        [label]="'tkey;global.button.delete.text'"
        [isDisabled]="isPending || isLoading"
        [icon]="smacsIcons.DELETE"
        [buttonSize]="buttonSizes.LARGE"
        [buttonStyle]="buttonStyles.DANGER"
        (clicked)="onDeleteClick()"
        [cssClass]="'me-1'"
      ></smacs-button>

      <smacs-button
        [isPending]="isPending"
        [isDisabled]="isPending || isLoading || isSaveDisabled"
        [label]="getSaveButtonLabel()"
        [dataAutomation]="'detailed-modal-save-button'"
        [icon]="getSaveButtonIcon()"
        [iconPending]="smacsIcons.SAVING"
        [buttonSize]="buttonSizes.LARGE"
        (click)="onSubmit()"
      ></smacs-button>
    </div>
  </div>
</div>
