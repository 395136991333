import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CustomCucmReport } from '../../../shared/models/generated/smacsModels';
import { FileDownloadService } from '../../../shared/services/file-download.service';

@Injectable()
export class CucmQueriesResource {
  constructor(private http: HttpClient, private fileDownloadService: FileDownloadService) {}

  postLiveQuery(body: { query: string; cucmId: number }): Observable<any> {
    return this.http.post(`/services/cisco/reporting/custom-cucm-reports/live-queries`, body);
  }

  postNewReport(body: CustomCucmReport): Observable<number> {
    return this.http.post<number>(`/services/cisco/reporting/custom-cucm-reports`, body);
  }

  updateReport(reportId: number, body: CustomCucmReport): Observable<void> {
    return this.http.put<void>(`/services/cisco/reporting/custom-cucm-reports/${reportId}`, body);
  }

  getAllReports(): Observable<CustomCucmReport[]> {
    return this.http.get<CustomCucmReport[]>(`/services/cisco/reporting/custom-cucm-reports`);
  }

  getReport(reportId: number): Observable<CustomCucmReport> {
    return this.http.get<CustomCucmReport>(`/services/cisco/reporting/custom-cucm-reports/${reportId}`);
  }

  deleteReport(reportId: number): Observable<void> {
    return this.http.delete<void>(`/services/cisco/reporting/custom-cucm-reports/${reportId}`);
  }

  getExportToExcel(reportId: number): Observable<void> {
    return this.fileDownloadService.downloadFile(`/services/cisco/reporting/custom-cucm-reports/${reportId}/exports`);
  }
}
