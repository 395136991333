import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SbcConfigurationReport } from '../../../../shared/models/generated/smacsModels';
import { Observable } from 'rxjs';

@Injectable()
export class SbcNumberConfigurationsContext {
  private _baseUrl = '/services/microsoft/billing/sbc-number-configuration-reports';

  constructor(private http: HttpClient) {}

  get(): Observable<SbcConfigurationReport> {
    return this.http.get<SbcConfigurationReport>(this._baseUrl);
  }
}
