<div class="datatable-filter">
  <div
    class="dropdown"
    ngbDropdown
    #dropdownPanel="ngbDropdown"
    [autoClose]="'outside'"
    [attr.data-automation]="dataAutomation"
  >
    <div>
      <button
        [id]="dataAutomation"
        type="button"
        class="btn dropdown-toggle"
        [class]="isRangeSelected() ? 'btn-primary' : 'btn-default'"
        [disabled]="isViewLoading"
        data-automation="datatable-date-filter-dropdown-toggle-button"
        ngbDropdownToggle
      >
        <span class="font-regular">{{ label | translate }}{{ isRangeSelected() ? ': ' : '' }}</span>
        <span *ngIf="isRangeSelected()" class="font-black" data-automation="datatable-multi-select-filter-value">
          {{ (label | translate) + ' ' + labelDynamic }}
        </span>
      </button>
      <button
        type="button"
        *ngIf="isRangeSelected()"
        class="btn btn-primary clear-margin"
        data-automation="datatable-clear-filter-button"
        (click)="clear()"
      >
        <i class="icon-clear"></i>
      </button>
    </div>
    <div ngbDropdownMenu class="dropdown-menu">
      <div class="dropdown-menu__sticky-container">
        <div class="dropdown-item">
          <p-calendar
            [attr.data-automation]="'filter-calendar'"
            [(ngModel)]="value"
            [selectionMode]="'range'"
            [showIcon]="false"
            [icon]="'icon-calendar'"
            [selectOtherMonths]="true"
            [numberOfMonths]="2"
            [inputStyleClass]="'smacs-primeng-datepicker-input'"
            [styleClass]="'smacs-primeng-datepicker'"
            [hourFormat]="12"
            [inline]="true"
            (onSelect)="onSelect()"
          ></p-calendar>
        </div>
      </div>
    </div>
  </div>
</div>
