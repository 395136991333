<div data-automation="pcce-deleted-agent-error-modal">
  <div class="modal-header modal-header--bordered d-flex align-items-center justify-content-between">
    <h5 class="mb-0">
      {{ 'tkey;pcce.deleted_agent_error_modal.title' | translate }}
    </h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="onCancelClicked()"></button>
  </div>

  <div class="modal-body modal-body--dynamic">
    <div
      class="mx-3"
      [innerHTML]="'tkey;pcce.deleted_agent_error_modal.body' | translate : { username: modalProperties.username }"
    ></div>
    <div class="text-center">
      <img
        class="deleted-object-instructions-image"
        alt="deleted objects"
        [src]="'/static/img/deleted_objects_instructions.png'"
      />
    </div>
  </div>

  <div class="modal-footer modal-footer--bordered">
    <div class="text-end">
      <smacs-button
        [label]="'tkey;global.button.cancel.text'"
        [buttonStyle]="buttonStyles.DEFAULT"
        [buttonSize]="buttonSizes.LARGE"
        (clicked)="onCancelClicked()"
        [cssClass]="'me-1'"
      ></smacs-button>

      <smacs-button
        [label]="'tkey;pcce.deleted_agent_error_modal.button'"
        [buttonSize]="buttonSizes.LARGE"
        (clicked)="onReturnTo360ViewClicked()"
      ></smacs-button>
    </div>
  </div>
</div>
