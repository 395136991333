<app-loading-block [hidden]="!isGeneratingPhone"></app-loading-block>

<div [hidden]="!isInitialized || isGeneratingPhone || !fieldConfig" data-automation="phone-fields">
  <smacs-text [fieldId]="'name'"></smacs-text>
  <smacs-text [fieldId]="'description'"></smacs-text>
  <smacs-select *ngFor="let fieldId of genericSelectFieldIds" [fieldId]="fieldId"></smacs-select>
  <smacs-text *ngFor="let fieldId of genericTextFieldIds" [fieldId]="fieldId"></smacs-text>

  <smacs-select
    *ngFor="let field of modelProtocolSpecificSelectFields"
    [fieldId]="field.fieldId"
    [hidden]="!field.supported"
  ></smacs-select>
  <smacs-checkbox
    *ngFor="let field of modelProtocolSpecificCheckboxFields"
    [fieldId]="field.fieldId"
    [hidden]="!field.supported"
  ></smacs-checkbox>
  <smacs-select *ngFor="let fieldId of associatedUserFieldIds" [fieldId]="fieldId"></smacs-select>

  <ng-content></ng-content>
</div>
