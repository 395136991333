<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>
  <div class="col-md-10 offset-md-1" [hidden]="isLoading">
    <app-breadcrumbs>
      <app-breadcrumb-card-item>
        <span [innerHTML]="'tkey;admin.microsoft.general_ziro_support_settings.about' | translate"></span>
      </app-breadcrumb-card-item>
    </app-breadcrumbs>
    <div class="card mt-3">
      <div class="card-header">{{ 'tkey;admin.microsoft.general_ziro_support_settings.title' | translate }}</div>
      <div class="card-body">
        <smacs-text [fieldId]="'pwshCmdletDelayInSeconds'"></smacs-text>
        <smacs-text [fieldId]="'pwshUserCacheExpiryInSeconds'"></smacs-text>
        <smacs-text [fieldId]="'pwshPoolSize'"></smacs-text>
      </div>
    </div>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
