import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { Microsoft360ViewContext } from '../../../shared/contexts/microsoft-360-view.context';
import { Subscription } from 'rxjs';
import { AccountType, Microsoft360View } from '../../../shared/models/generated/smacsModels';

@Component({
  selector: 'ziro-teams-calling-container',
  templateUrl: './teams-calling-container.component.html',
  styleUrls: ['./teams-calling-container.component.scss'],
})
export class TeamsCallingContainerComponent implements OnInit, OnDestroy {
  isDelegateManagementSelected: boolean;
  isDelegateManagementEnabled = false;
  delegateManagementDisabledTooltip = '';
  private _subscriptions = new Subscription();
  constructor(private _router: Router, private _microsoft360ViewContext: Microsoft360ViewContext) {}

  ngOnInit() {
    const routerSub = this._router.events.subscribe((event) => {
      if (event instanceof Scroll && event.routerEvent instanceof NavigationEnd) {
        this.isDelegateManagementSelected = event.routerEvent.url.includes('delegate-management');
      }
    });

    const ms360ViewSub = this._microsoft360ViewContext.state$.subscribe((ms360View: Microsoft360View) => {
      this.isDelegateManagementEnabled =
        (!!ms360View.teamsPhoneNumberAssignment ||
          ms360View.teamsVoicePolicies.sharedCallRoutingPolicy.policyName !== 'Global') &&
        ms360View.accountType !== AccountType.RESOURCE_ACCOUNT;
      if (ms360View.accountType === AccountType.RESOURCE_ACCOUNT) {
        this.delegateManagementDisabledTooltip =
          'tkey;microsoft_360.view.teams.delegate_management.tab.disabled_for_resource_account.tooltip';
      } else if (!ms360View.teamsPhoneNumberAssignment) {
        this.delegateManagementDisabledTooltip =
          'tkey;microsoft_360.view.teams.delegate_management.tab.disabled_no_teamsline.tooltip';
      }
    });
    this._subscriptions.add(routerSub);
    this._subscriptions.add(ms360ViewSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
