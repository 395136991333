<div class="h-100 d-flex flex-column container" data-automation="bulk-provisioning-general">
  <div class="row mb-3">
    <div class="col-12 d-flex justify-content-end">
      <a
        class="btn btn-primary"
        [href]="csvTemplateUrl"
        [download]="'zpc_bulk_provisioning_job_template.csv'"
        data-automation="bulk-provisioning-upload-csv-download-template-link"
      >
        <i [attr.class]="smacsIcons.DOWNLOAD + ' me-1'"></i>
        <strong>{{ 'tkey;zero_touch.bulk_provisioning.upload_csv.download_template' | translate }}</strong>
      </a>
    </div>
  </div>
  <smacs-select fieldId="site"></smacs-select>

  <div>
    <app-loading-block
      class="bulk-services__loading-block"
      [hidden]="provisioningFieldConfigs !== null"
    ></app-loading-block>
    <div [hidden]="!provisioningFieldConfigs" class="bulk-services__multi-checkbox">
      <smacs-multi-checkbox fieldId="services"></smacs-multi-checkbox>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <smacs-radio [fieldId]="'assignExtension'"></smacs-radio>
    </div>
  </div>
  <smacs-select fieldId="dnGroups"></smacs-select>

  <smacs-text fieldId="auditTag"></smacs-text>

  <div class="row mt-auto pb-3">
    <div class="col-12 text-end">
      <div
        *ngIf="isSubmitted && validationState === ValidationState.INVALID"
        class="alert smacs-error-notification alert-danger animated shake text-start"
        role="alert"
      >
        <i class="fa fa-exclamation fa-lg"></i>

        <span class="strong">
          {{ 'tkey;validators.global.required.missing.error' | translate }}
        </span>
      </div>

      <button
        type="button"
        class="btn btn-primary align-bottom"
        (click)="goToTable()"
        data-automation="bulk-provisioning-general-next-button"
      >
        <span>{{ 'tkey;global.button.next.text' | translate }}</span>
      </button>
    </div>
  </div>
</div>
