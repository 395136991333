import { Injectable } from '@angular/core';

@Injectable()
export class SmacsSelectService {
  static lookupNestedValue(o: any, s: string) {
    // convert indexes to properties & strip a leading dot
    const keysArray = s
      .replace(/\[(\w+)\]/g, '.$1')
      .replace(/^\./, '')
      .split('.');
    for (let i = 0, n = keysArray.length; i < n; ++i) {
      const k = keysArray[i];
      if (k in o) {
        o = o[k];
      } else {
        return;
      }
    }
    return o;
  }
}
