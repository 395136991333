import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouteReuseStrategy } from '@angular/router';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import {
  BandwidthCachedOptions,
  CallingType,
  EnvironmentType,
  MicrosoftCachedOptions,
  MicrosoftDialPlanGroup,
  PstnConnectivityType,
  Role,
} from '../../../../shared/models/generated/smacsModels';
import { MsDialPlanGroupsContext } from '../../../contexts/ms-dial-plan-groups.context';
import { MsTeamsCachedOptionsResource } from '../../../resources/ms-teams-cached-options.resource';
import { combineLatest, Subscription } from 'rxjs';
import { isEqual } from 'lodash';
import { SmacsFormsUpdate } from '../../../../forms/smacs-forms-models';
import { AuthenticationContext } from '../../../../shared/contexts/authentication.context';
import { BandwidthCachedOptionsContext } from '../../../contexts/bandwidth-cached-options.context';
import { BandwidthSettingsContext } from '../../../../shared/contexts/bandwidth-settings.context';
import { Microsoft365LicensesViewResource } from '../../../../shared/resources/microsoft-365-licenses-view.resource';
import { MsGraphConfigContext } from '../../../contexts/ms-graph-config.context';
import { MicrosoftProxyFeatureSettingsContext } from '../../../contexts/microsoft-proxy-feature-settings.context';

@Component({
  selector: 'microsoft-dial-plan-group-management',
  templateUrl: './ms-dial-plan-group-management.component.html',
  providers: [MsTeamsCachedOptionsResource],
})
export class MsDialPlanGroupManagementComponent implements OnInit, OnDestroy {
  isLoading = true;
  allDialPlanGroups: MicrosoftDialPlanGroup[];
  dialPlanGroup: MicrosoftDialPlanGroup;
  microsoftCachedOptions: MicrosoftCachedOptions;
  bandwidthCachedOptions: BandwidthCachedOptions;
  usageLocations: string[];
  isUserS8Support: boolean;
  isBandwidthConfigured: boolean;
  isGccHigh: boolean;
  onPremAdWriteDownEnabled: boolean;

  private _groupId: string;
  private _isCopy: boolean;
  private _subs = new Subscription();

  constructor(
    private _authenticationContext: AuthenticationContext,
    private _msTeamCachedOptionsResource: MsTeamsCachedOptionsResource,
    private _bandwidthCachedOptionsContext: BandwidthCachedOptionsContext,
    private _router: Router,
    private _route: ActivatedRoute,
    private _breadcrumbsService: BreadcrumbsService,
    private _msDialPlanContext: MsDialPlanGroupsContext,
    private _bandwidthSettingsContext: BandwidthSettingsContext,
    private _routeReuseStrategy: RouteReuseStrategy,
    private _microsoft365LicensesViewResource: Microsoft365LicensesViewResource,
    private _msGraphConfigContext: MsGraphConfigContext,
    private _proxyFeatureSettingsContext: MicrosoftProxyFeatureSettingsContext
  ) {}

  ngOnInit() {
    this._routeReuseStrategy.shouldReuseRoute = () => false;
    this._groupId = this._route.snapshot.params['groupId'];
    this._isCopy = this._router.url.endsWith('copy');

    const sub = combineLatest([
      this._msDialPlanContext.state$,
      this._msTeamCachedOptionsResource.get(),
      this._bandwidthCachedOptionsContext.state$,
      this._authenticationContext.state$,
      this._bandwidthSettingsContext.state$,
      this._msGraphConfigContext.state$,
      this._microsoft365LicensesViewResource.get(),
    ]).subscribe(
      ([
        dialPlanGroups,
        microsoftCachedOptions,
        bandwidthCachedOptions,
        currentUser,
        bandwidthSettings,
        graphSettings,
        ms365LicensesView,
      ]) => {
        this.microsoftCachedOptions = microsoftCachedOptions;
        this.bandwidthCachedOptions = bandwidthCachedOptions;
        this.usageLocations = ms365LicensesView.usageLocationsList;
        this.isUserS8Support = currentUser.role === Role.ZIRO_SUPPORT;
        this.allDialPlanGroups = dialPlanGroups;
        this.isBandwidthConfigured =
          this.isUserS8Support && this._bandwidthSettingsContext.isBandwidthConfigured(bandwidthSettings);
        this.isGccHigh = graphSettings.environmentType === EnvironmentType.GCC_HIGH;

        if (this._groupId != null) {
          const dialPlanGroup = this.allDialPlanGroups.find((group) => group.id === this._groupId);
          if (!dialPlanGroup) {
            this._router.navigate(['../new-group'], { relativeTo: this._route });
            return;
          }
          if (this._isCopy) {
            this.dialPlanGroup = {
              ...dialPlanGroup,
              id: undefined,
              name: `${dialPlanGroup.name} (Copy)`,
              dialPlanRangesJson: [{ start: '', end: '' }],
              mainNumber: '',
              billingLocation: '',
              bandwidthEmergencyCallingSettings: null,
              customInputs: [
                {
                  name: 'on_prem_line_uri_extension',
                  required: false,
                  show: false,
                  value: '',
                },
              ],
            };
          } else {
            this.dialPlanGroup = dialPlanGroup;
          }
        } else {
          this.dialPlanGroup = {
            customInputs: [
              {
                name: 'on_prem_line_uri_extension',
                required: false,
                show: false,
                value: '',
              },
            ],
            customSelects: [
              {
                name: 'calling_policy',
                defaultOption: 'Global',
                possibleOptions: ['Global'],
                show: false,
                required: true,
              },
              {
                name: 'teams_call_hold_policy',
                defaultOption: 'Global',
                possibleOptions: ['Global'],
                show: false,
                required: true,
              },
              {
                name: 'teams_call_park_policy',
                defaultOption: 'Global',
                possibleOptions: ['Global'],
                show: false,
                required: true,
              },
              {
                name: 'calling_line_identity',
                defaultOption: 'Global',
                possibleOptions: ['Global'],
                show: false,
                required: true,
              },
              {
                name: 'tenant_dial_plan',
                defaultOption: 'Global',
                possibleOptions: ['Global'],
                show: false,
                required: true,
              },
              {
                name: 'emergency_calling_policy',
                defaultOption: 'Global',
                possibleOptions: ['Global'],
                show: false,
                required: true,
              },
              {
                name: 'emergency_call_routing_policy',
                defaultOption: 'Global',
                possibleOptions: ['Global'],
                show: false,
                required: true,
              },
              {
                name: 'online_voice_routing_policy',
                defaultOption: 'Global',
                possibleOptions: ['Global'],
                show: false,
                required: true,
              },
              {
                name: 'online_voicemail_policy',
                defaultOption: 'Global',
                possibleOptions: ['Global'],
                show: false,
                required: true,
              },
              {
                name: 'ip_phone_policy',
                defaultOption: 'Global',
                possibleOptions: ['Global'],
                show: false,
                required: true,
              },
              {
                name: 'emergency_location',
                defaultOption: '',
                possibleOptions: [],
                show: false,
                required: false,
              },
            ],
            billingLocation: '',
            bandwidthEmergencyCallingSettings: null,
            dialPlanRangesJson: [{ start: '', end: '' }],
            id: null,
            name: '',
            callingType: CallingType.DID,
            pstnConnectivityType: PstnConnectivityType.DIRECT_ROUTING,
            carrierName: '',
            mainNumber: null,
            adWriteDownList: null,
            sharedCallingRoutingPolicy: '',
            enforcedNumbersUsageLocation: '',
          };
        }
        this._setBreadcrumbs(this.dialPlanGroup.name);
        this.isLoading = false;
      }
    );

    const proxyFeatureSettingsSub = this._proxyFeatureSettingsContext.state$.subscribe((proxySettings) => {
      this.onPremAdWriteDownEnabled = proxySettings.onPremAdWriteDownEnabled;
    });

    this._subs.add(proxyFeatureSettingsSub);
    this._subs.add(sub);
  }

  private _setBreadcrumbs(label: string) {
    this._breadcrumbsService.updateBreadcrumbs([
      { label: 'tkey;dialplanmanagement.title', url: '/admin/microsoft/dial-plan-management', routerLink: true },
      { label: label },
    ]);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  onFormUpdate(data: SmacsFormsUpdate<MicrosoftDialPlanGroup>) {
    if (!isEqual(data.new.name, data.old?.name)) {
      this._setBreadcrumbs(data.new.name);
    }
  }
}
