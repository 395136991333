import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { concat, Observable, of, Subject, Subscriber, timer } from 'rxjs';
import { SmacsSelectAsyncOptionsFn, SmacsSelectOption } from '../../../forms/fields/select/smacs-select.component';
import { debounce, first, switchMap } from 'rxjs/operators';

export interface FilterSelectOption {
  label: string;
  value: any;
  groupBy?: string;
  iconHtml?: string;
  iconHtmlTooltip?: string;
}

@Component({
  selector: 'smacs-filter-select',
  templateUrl: './filter-select.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SmacsFilterSelectComponent,
    },
  ],
})
export class SmacsFilterSelectComponent implements OnInit, ControlValueAccessor {
  @Input() bindLabel = 'label';
  @Input() bindValue: string = null;
  @Input() dataAutomation = 'smacs-filter-select';
  @Input() filterOptions = [] as FilterSelectOption[] | string[] | any[];
  @Input() groupBy = 'groupBy';
  @Input() hideSelected = false;
  @Input() isClearable = true;
  @Input() isDisabled = false;
  @Input() isGroupSelectable = false;
  @Input() isMultiSelect = false;
  @Input() placeholder = '';
  @Input() filterValue: FilterSelectOption | FilterSelectOption[] | string[] | string = null;
  @Input() asyncText = false;
  @Input() clearAllFilter = false;
  @Input() appendTo: string;
  @Input() showOptionsIcons = false;

  @Output() filterChanged = new EventEmitter<FilterSelectOption | FilterSelectOption[] | string[] | string>();

  @Input() asyncOptionsFn: SmacsSelectAsyncOptionsFn;
  options$: Observable<string[] | SmacsSelectOption[] | FilterSelectOption[]>;
  optionInputSource = new Subject<string>();
  isLoading: boolean;
  hideDropdownArrow = false;

  ngOnInit() {
    if (this.filterOptions?.length) {
      this.options$ = of(this.filterOptions);
    } else {
      this.options$ = this.getOptionsAsync();
    }
  }

  getOptionsAsync = (): Observable<string[] | SmacsSelectOption[]> => {
    return concat(
      this.optionInputSource.pipe(
        debounce(() => timer(500)),
        switchMap((searchTerm: string) => {
          return new Observable((subscriber: Subscriber<string[] | SmacsSelectOption[]>) => {
            if (!searchTerm) {
              subscriber.next([]);
              subscriber.complete();
              this.isLoading = false;
            } else {
              this.isLoading = true;
              if (!this.filterOptions?.length) {
                this.hideDropdownArrow = false;
              }
              this.asyncOptionsFn(searchTerm)
                .pipe(first())
                .subscribe((results: string[] | SmacsSelectOption[]) => {
                  subscriber.next(results);
                  subscriber.complete();
                  this.isLoading = false;
                });
            }
          });
        })
      )
    );
  };

  filterOnChange() {
    this.filterChanged.emit(this.filterValue);
  }

  writeValue(value: any) {
    this.filterValue = value;
  }

  registerOnChange(onChanged: any) {}

  registerOnTouched(fn: any) {}

  onClear() {
    this.filterValue = null;
    this.filterChanged.emit(this.filterValue);
  }
}
