import { SmacsCheckboxComponent } from '../../../../forms/fields/checkbox/smacs-checkbox.component';
import { Component } from '@angular/core';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { SmacsFieldAbstractDirective } from '../../../../forms/smacs-field-abstract.directive';

@Component({
  selector: 'smacs-general-settings-checkbox',
  templateUrl: './general-settings-checkbox.component.html',
  styleUrls: ['./general-settings-checkbox.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: GeneralSettingsCheckboxComponent }],
})
export class GeneralSettingsCheckboxComponent extends SmacsCheckboxComponent {
  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }
}
