import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { SitesResource } from '../../services/sites.resource';
import {
  Checkbox,
  CustomCheckbox,
  CustomInputText,
  CustomMultiSelect,
  CustomSelect,
  EnabledType,
  MultiSelect,
  Select,
  ServiceSetting,
  Site,
} from '../../../../../shared/models/generated/smacsModels';
import blankSite from './blank-site';
import { serviceSettingNameMapping } from '../../site-management-service/serviceNameMappings';
import { cloneDeep, isEqual } from 'lodash';
import { map } from 'rxjs/operators';

export interface CommonFieldsStateByService {
  [key: string]: CommonFieldsState;
}

export interface CommonFieldsState {
  commonFields: string[];
  areCommonFieldsVisible: boolean;
}

@Injectable()
export class CommonFieldsUiContext {
  private _commonFieldsStateSource = new ReplaySubject<CommonFieldsStateByService>(1);
  commonFieldsState$ = this._commonFieldsStateSource.asObservable();
  private _siteWithCommonFieldsStateSource = new ReplaySubject<Site>(1);
  siteWithCommonFieldsState$ = this._siteWithCommonFieldsStateSource.asObservable();
  constructor(private _sitesResource: SitesResource) {}

  init(clusterId: number): Observable<void> {
    return this._sitesResource.getAll(clusterId).pipe(
      map((allSites) => {
        const eligibleServices = this._getEligibleServices(allSites);
        const initialState: CommonFieldsStateByService = {
          'Desk Phone': { commonFields: [], areCommonFieldsVisible: false },
          'Extension Mobility': { commonFields: [], areCommonFieldsVisible: false },
          'IM Softphone': { commonFields: [], areCommonFieldsVisible: false },
          IPhone: { commonFields: [], areCommonFieldsVisible: false },
          Android: { commonFields: [], areCommonFieldsVisible: false },
          Tablet: { commonFields: [], areCommonFieldsVisible: false },
          CIPC: { commonFields: [], areCommonFieldsVisible: false },
          'Directory Number': { commonFields: [], areCommonFieldsVisible: false },
          'Line Features': { commonFields: [], areCommonFieldsVisible: false },
          Voicemail: { commonFields: [], areCommonFieldsVisible: false },
          'IM Presence': { commonFields: [], areCommonFieldsVisible: false },
          'End User': { commonFields: [], areCommonFieldsVisible: false },
          'Single Number Reach': { commonFields: [], areCommonFieldsVisible: false },
          'Translation Pattern': { commonFields: [], areCommonFieldsVisible: false },
        };
        if (allSites.length < 2 || !eligibleServices.length) {
          this.updateCommonFieldsState(initialState);
          this._siteWithCommonFieldsStateSource.next(blankSite);
        } else if (allSites.length > 1 && eligibleServices.length) {
          this._processEligibleServices(initialState, allSites, eligibleServices);
        }
      })
    );
  }

  updateCommonFieldsState(newValue: CommonFieldsStateByService) {
    this._commonFieldsStateSource.next(newValue);
  }

  private _getEligibleServices(allSites: Site[]): string[] {
    return Object.keys(serviceSettingNameMapping).filter(
      (serviceName) =>
        allSites.filter((site) =>
          site.serviceSettings.some(
            (serviceSetting) => serviceSetting.name === serviceName && serviceSetting.enabled !== EnabledType.DISABLED
          )
        ).length > 1
    );
  }

  private _processEligibleServices(
    initialState: CommonFieldsStateByService,
    allSites: Site[],
    eligibleServices: string[]
  ) {
    let siteWithCommonFields: Site = { ...cloneDeep(blankSite) };
    eligibleServices.forEach((eligibleServiceSetting) => {
      const eligibleSites = allSites.filter((site) =>
        site.serviceSettings.some(
          (serviceSetting) =>
            serviceSetting.name === eligibleServiceSetting && serviceSetting.enabled !== EnabledType.DISABLED
        )
      );
      siteWithCommonFields = this._setServiceEnabledAndOneClickStates(
        eligibleSites,
        eligibleServiceSetting,
        siteWithCommonFields
      );
      eligibleSites.forEach((filteredSite, _, eligibleSites) => {
        const targetSiteServiceSettings = filteredSite.serviceSettings.find(
          (serviceSettingFiltered) => serviceSettingFiltered.name === eligibleServiceSetting
        );
        [
          'customSelects',
          'selects',
          'checkboxes',
          'multiSelects',
          'customCheckboxes',
          'customMultiSelects',
          'customInputTexts',
        ].forEach((fieldType) => {
          siteWithCommonFields = this._getAndSetCommonFields(
            filteredSite,
            eligibleSites,
            targetSiteServiceSettings,
            fieldType,
            initialState,
            siteWithCommonFields
          );
        });
      });
    });
    this.updateCommonFieldsState(initialState);
    this._siteWithCommonFieldsStateSource.next(siteWithCommonFields);
  }

  private _getAndSetCommonFields(
    currentSite: Site,
    eligibleSites: Site[],
    targetSiteServiceSetting: ServiceSetting,
    currentFieldType: string,
    commonFieldsStateByService: CommonFieldsStateByService,
    siteWithCommonFields: Site
  ): Site {
    const serviceSettingIndexOnCommonFieldSite = siteWithCommonFields.serviceSettings.findIndex(
      (commonSs) => commonSs.name === targetSiteServiceSetting.name
    );
    const currentFields = this._getArrayOfFieldsByType(currentFieldType, targetSiteServiceSetting);

    if (!currentFields.length) {
      return siteWithCommonFields;
    }

    for (const field of this._getArrayOfFieldsByType(currentFieldType, targetSiteServiceSetting)) {
      if (!commonFieldsStateByService[targetSiteServiceSetting.name].commonFields.includes(field.name)) {
        const indexOnCommonFieldsSite = this._getArrayOfFieldsByType(
          currentFieldType,
          siteWithCommonFields.serviceSettings.find(
            (serviceSettingCommon) => serviceSettingCommon.name === targetSiteServiceSetting.name
          )
        ).findIndex((blankSiteField) => blankSiteField.name === field.name);

        const fieldMatchesEveryOtherSite = eligibleSites
          .filter((s) => s.id !== currentSite.id)
          .every((elSite) => {
            const serviceSettingToCheck = elSite.serviceSettings.find(
              (ss) => ss.name === targetSiteServiceSetting.name
            );
            const fieldIndexToCheck = this._getArrayOfFieldsByType(currentFieldType, serviceSettingToCheck).findIndex(
              (fieldTarget) => fieldTarget.name === field.name
            );
            return (
              this._getArrayOfFieldsByType(currentFieldType, serviceSettingToCheck).length &&
              isEqual(field, this._getArrayOfFieldsByType(currentFieldType, serviceSettingToCheck)[fieldIndexToCheck])
            );
          });

        if (fieldMatchesEveryOtherSite) {
          this._setCommonFieldToBlankSite(
            currentFieldType,
            serviceSettingIndexOnCommonFieldSite,
            indexOnCommonFieldsSite,
            field,
            siteWithCommonFields
          );
          if (commonFieldsStateByService[targetSiteServiceSetting.name].commonFields) {
            commonFieldsStateByService[targetSiteServiceSetting.name].commonFields.push(field.name);
          } else {
            commonFieldsStateByService[targetSiteServiceSetting.name].commonFields = [field.name];
          }
        }
      }
    }
    return siteWithCommonFields;
  }

  private _setCommonFieldToBlankSite(
    fieldType: string,
    serviceSettingIdx: number,
    commonFieldIdx: number,
    field: Checkbox | CustomCheckbox | CustomSelect | CustomMultiSelect | Select | CustomInputText | MultiSelect,
    siteWithCommonFields: Site
  ) {
    switch (fieldType) {
      case 'checkboxes':
        siteWithCommonFields.serviceSettings[serviceSettingIdx].checkboxes[commonFieldIdx] = field as Checkbox;
        break;
      case 'customSelects':
        siteWithCommonFields.serviceSettings[serviceSettingIdx].customSelects[commonFieldIdx] = field as CustomSelect;
        break;
      case 'selects':
        siteWithCommonFields.serviceSettings[serviceSettingIdx].selects[commonFieldIdx] = field as Select;
        break;
      case 'customMultiSelects':
        siteWithCommonFields.serviceSettings[serviceSettingIdx].customMultiSelects[commonFieldIdx] =
          field as CustomMultiSelect;
        break;
      case 'multiSelects':
        siteWithCommonFields.serviceSettings[serviceSettingIdx].multiSelects[commonFieldIdx] = field as MultiSelect;
        break;
      case 'customInputTexts':
        siteWithCommonFields.serviceSettings[serviceSettingIdx].customInputTexts[commonFieldIdx] =
          field as CustomInputText;
        break;
      case 'customCheckboxes':
        siteWithCommonFields.serviceSettings[serviceSettingIdx].customCheckboxes[commonFieldIdx] =
          field as CustomCheckbox;
        break;
      default:
        break;
    }
  }

  private _getArrayOfFieldsByType(
    fieldType: string,
    serviceSetting: ServiceSetting
  ):
    | Checkbox[]
    | CustomSelect[]
    | Select[]
    | CustomMultiSelect[]
    | MultiSelect[]
    | CustomInputText[]
    | CustomCheckbox[] {
    switch (fieldType) {
      case 'checkboxes':
        return serviceSetting.checkboxes;
      case 'customSelects':
        return serviceSetting.customSelects;
      case 'selects':
        return serviceSetting.selects;
      case 'customMultiSelects':
        return serviceSetting.customMultiSelects;
      case 'multiSelects':
        return serviceSetting.multiSelects;
      case 'customInputTexts':
        return serviceSetting.customInputTexts;
      case 'customCheckboxes':
        return serviceSetting.customCheckboxes;
      default:
        return [];
    }
  }

  private _setServiceEnabledAndOneClickStates(
    eligibleSites: Site[],
    eligibleServiceSetting: string,
    siteWithCommonFields: Site
  ): Site {
    siteWithCommonFields.serviceSettings.forEach((serviceSetting) => {
      if (serviceSetting.name === eligibleServiceSetting && serviceSetting.enabled !== EnabledType.NOT_APPLICABLE) {
        serviceSetting.enabled = EnabledType.ENABLED;

        serviceSetting.oneClickEnabled = eligibleSites.every(
          (site) => site.serviceSettings.find((ss) => ss.name === serviceSetting.name).oneClickEnabled
        );
      }
    });
    return siteWithCommonFields;
  }
}
