import { Component, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { ActivatedRoute, ActivationStart, Router } from '@angular/router';
import { get } from 'lodash';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Microsoft360ViewContext } from '../../../../shared/contexts/microsoft-360-view.context';
import { MicrosoftTileConfigContext } from '../../../shared/contexts/microsoft-tile-config.context';

@Component({
  selector: 'smacs-microsoft360-detail',
  templateUrl: './microsoft360-detail.component.html',
  styleUrls: ['./microsoft360-detail.component.scss'],
  providers: [Microsoft360ViewContext, MicrosoftTileConfigContext],
})
export class Microsoft360DetailComponent implements OnInit, OnDestroy {
  baseCrumb: MenuItem;
  isPageLoading = true;

  private _subs = new Subscription();

  constructor(private breadcrumbsService: BreadcrumbsService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.baseCrumb = {
      label: 'tkey;microsoft_360.view.breadcrumb.home.text',
    };

    this._updateBreadcrumb(this.route.firstChild.snapshot.data.breadcrumb);
    const sub = this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        const activeRouteCrumb = get(event, ['snapshot', 'data', 'breadcrumb']);
        this._updateBreadcrumb(activeRouteCrumb);
      }
    });
    this._subs.add(sub);
  }

  ngOnDestroy() {
    this._subs.unsubscribe();
  }

  private _updateBreadcrumb(crumb: string) {
    if (crumb !== this.baseCrumb.label) {
      this.breadcrumbsService.updateBreadcrumbs([this.baseCrumb, { label: crumb }]);
    } else {
      this.breadcrumbsService.updateBreadcrumbs([this.baseCrumb]);
    }
  }

  setLoading(event: boolean) {
    this.isPageLoading = !event;
  }
}
