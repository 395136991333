import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { ZpmProxySettings } from '../../shared/models/generated/smacsModels';
import { GlobalPropertiesContext } from '../../shared/contexts/global-properties.context';
import { tap } from 'rxjs/operators';

@Injectable()
export class MicrosoftProxyFeatureSettingsContext implements OnDestroy {
  private _stateSource = new ReplaySubject<ZpmProxySettings>(1);
  state$ = this._stateSource.asObservable();

  private _baseUrl = '/services/microsoft/admin/proxy-feature-settings';
  private _subscriptions = new Subscription();

  constructor(private http: HttpClient, private globalPropertiesContext: GlobalPropertiesContext) {
    const sub = this.globalPropertiesContext.state$.subscribe((globalProperties) => {
      if (globalProperties.hostedEnabled) {
        this._init();
      }
    });

    this._subscriptions.add(sub);
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private _init() {
    this._get().subscribe((proxyFeatureSettings) => this._stateSource.next(proxyFeatureSettings));
  }

  private _get(): Observable<ZpmProxySettings> {
    return this.http.get<ZpmProxySettings>(this._baseUrl);
  }

  put(body: ZpmProxySettings): Observable<ZpmProxySettings> {
    return this.http.put<ZpmProxySettings>(this._baseUrl, body).pipe(tap(() => this.refresh()));
  }

  refresh(): void {
    this._init();
  }
}
