import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { Component } from '@angular/core';
import { SmacsFieldComponentConfig, SmacsFormsMessage } from '../../smacs-forms-models';
import { UniqueIdService } from '../../../shared/services/unique-id.service';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import { isNil } from 'lodash';

export interface SmacsRadioButton<T> {
  value: T;
  label: string;
  labelParam?: any;
  helpText?: string;
  disabled?: boolean | (() => boolean);
  disabledTooltip?: string | (() => string);
  hidden?: boolean;
}

export class SmacsRadioConfig<T> extends SmacsFieldComponentConfig {
  constructor(
    public config: {
      buttons: SmacsRadioButton<T>[];
      inline?: boolean;
      hideLabel?: boolean;
      isDisplayVertical?: boolean;
      alignLeftNoLabel?: boolean;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-radio',
  templateUrl: './smacs-radio.component.html',
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsRadioComponent }],
})
export class SmacsRadioComponent<T> extends SmacsFieldAbstractDirective<T, T, SmacsRadioConfig<T>> {
  buttons = [] as SmacsRadioButton<T>[];
  inline = false;
  uniqueRadioId: string;
  hideLabel = false;
  isDisplayVertical = false;
  alignLeftNoLabel = false;

  constructor(protected smacsFormStateService: SmacsFormStateService, private uniqueIdService: UniqueIdService) {
    super(smacsFormStateService);
    this.uniqueRadioId = uniqueIdService.getUniqueId();
  }

  applyComponentConfig = ({ config }: SmacsRadioConfig<T>) => {
    this.buttons = config.buttons;
    this.inline = config.inline;
    this.hideLabel = isNil(config.hideLabel) ? this.hideLabel : config.hideLabel;
    this.isDisplayVertical = isNil(config.isDisplayVertical) ? this.isDisplayVertical : config.isDisplayVertical;
    this.alignLeftNoLabel = !!config?.alignLeftNoLabel;
  };

  isButtonDisabled(button: SmacsRadioButton<T>): boolean {
    if (this.getDisabledState()) {
      return this.getDisabledState();
    } else if (button.hasOwnProperty('disabled')) {
      if (typeof button.disabled === 'boolean') {
        return button.disabled;
      } else if (typeof button.disabled === 'function') {
        return button.disabled();
      }
    }

    return false;
  }

  getButtonDisabledTooltip(button: SmacsRadioButton<T>): SmacsFormsMessage {
    if (this.getDisabledTooltip()) {
      return this.getDisabledTooltip();
    } else if (typeof button.disabledTooltip === 'function') {
      return this._toSmacsFormMessage(button.disabledTooltip());
    } else {
      return this._toSmacsFormMessage(button.disabledTooltip);
    }
  }

  getDisabledState(): boolean {
    if (this.config.hasOwnProperty('disabled')) {
      if (typeof this.config.disabled === 'boolean') {
        return this.config.disabled;
      } else if (typeof this.config.disabled === 'function') {
        return this.config.disabled();
      }
    }

    return this.state.disabled;
  }
}
