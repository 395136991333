import { Component, OnDestroy, OnInit } from '@angular/core';
import { ButtonTemplateOverride, PhoneButtonTemplateInventory } from '../../../shared/models/generated/smacsModels';
import { combineLatest, Subscription } from 'rxjs';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { DefaultPhoneButtonTemplateResource } from '../../resources/default-phone-button-template.resource';
import { ToastService } from '../../../shared/services/toast.service';
import { ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import {
  EntityTable,
  EntityTableContentRow,
  EntityTableFieldTypes,
  EntityTableFilterTypes,
  EntityTableOnFieldChange,
} from '../../../shared/entity-table/entity-table.models';
import { ServersContext } from '../../contexts/servers.context';
import { FilterSelectOption } from '../../../shared/filter/filter-select/filter-select.component';

@Component({
  selector: 'app-admin-phone-button-templates',
  templateUrl: './default-phone-button-templates.component.html',
  styleUrls: ['../../admin-page.scss'],
})
export class DefaultPhoneButtonTemplatesComponent implements OnInit, OnDestroy {
  isLoading = true;
  isSaving = false;

  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];

  private _overrides: ButtonTemplateOverride[];
  private _serverIdToNameMap: Map<number, string>;
  private _isSubmitted = false;
  private _templateInventory: PhoneButtonTemplateInventory[] = [];

  private _subscription = new Subscription();

  bottomNavButtons = [
    {
      id: 'saveDefaultTemplateConfig',
      label: 'tkey;admin.phone_button_templates.bottom_nav.save.label',
      dataAutomation: 'admin-phone-button-templates-save-button',
      buttonClass: ButtonStyles.PRIMARY,
      state: {
        pending: false,
        buttonDisableState: { disabled: false, tooltipKey: '' },
      },
      icon: SmacsIcons.OK,
      cb: () => {
        this._saveDefaultTemplateConfig();
      },
    },
  ] as BottomNavButton[];

  constructor(
    private defaultPhoneButtonTemplateResource: DefaultPhoneButtonTemplateResource,
    private bottomNavService: BottomNavService,
    private toastService: ToastService,
    private serversContext: ServersContext,
    private breadcrumbsService: BreadcrumbsService
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.phone_button_templates.title' }]);

    const sub = combineLatest([
      this.serversContext.state$,
      this.defaultPhoneButtonTemplateResource.getPhoneButtonTemplateInventory(),
      this.defaultPhoneButtonTemplateResource.getDefaultPhoneButtonTemplateOverrides(),
    ]).subscribe(([servers, templateInventory, templateOverrides]) => {
      this._templateInventory = templateInventory;
      this._serverIdToNameMap = servers.reduce<Map<number, string>>(
        (map, server) => map.set(server.id, server.description),
        new Map<number, string>()
      );
      this._buildTableColumns(templateInventory);
      this._buildTableRows(templateInventory, templateOverrides);
      this._overrides = templateOverrides;
      this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(this.bottomNavButtons));
      this.isLoading = false;
    });
    this._subscription.add(sub);
  }

  ngOnDestroy() {
    this.breadcrumbsService.clearBreadcrumbs();
    this._subscription.unsubscribe();
  }

  private _buildTableColumns(templateInventory: PhoneButtonTemplateInventory[]) {
    const uniqueModels = Array.from(new Set(templateInventory.map((template) => template.model)));
    const uniqueProtocols = Array.from(new Set(templateInventory.map((template) => template.protocol)));
    const uniqueServers = Array.from(new Set(templateInventory.map((template) => template.cucmId))).map((serverId) =>
      this._serverIdToNameMap.get(serverId)
    );

    this.table = {
      columns: [
        {
          columnId: 'model',
          cssColumnSize: 'col-sm-4',
          label: 'tkey;admin.phone_button_templates.header.model_name',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: uniqueModels,
          },
        },
        {
          columnId: 'protocol',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;admin.phone_button_templates.header.protocol',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: uniqueProtocols,
          },
        },
        {
          columnId: 'cucmServer',
          cssColumnSize: 'col-sm-2',
          label: 'tkey;admin.phone_button_templates.header.cucm_server',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: uniqueServers,
          },
        },
        {
          columnId: 'defaultTemplate',
          cssColumnSize: 'col-sm-4',
          label: 'tkey;admin.phone_button_templates.header.default_template',
        },
      ],
      hasActions: false,
    };
  }

  private _buildTableRows(
    templateInventory: PhoneButtonTemplateInventory[],
    templateOverrides: ButtonTemplateOverride[]
  ) {
    // The template inventory is a list of templates. It isn't very UI-friendly.
    // What we have to do here is group up the templates by model, protocol and cucmId.
    // We should display one row per unique model-protocol-server.
    // The options for the select on that row will be all the templates with that particular model-protocol-server.
    // There might also be an override set which no longer exists on cucm. So be sure to account for that.
    this.tableRows = templateInventory.reduce<EntityTableContentRow[]>((array, template) => {
      const modelProtocolServer = {
        model: template.model,
        protocol: template.protocol,
        cucmServer: this._serverIdToNameMap.get(template.cucmId),
      };
      if (
        !array.some(
          (row) =>
            row.content.model === modelProtocolServer.model &&
            row.content.protocol === modelProtocolServer.protocol &&
            row.content.cucmServer === modelProtocolServer.cucmServer
        )
      ) {
        const existingOverride = templateOverrides.find(
          (override) =>
            override.model === template.model &&
            override.protocol === template.protocol &&
            override.cucmId === template.cucmId
        );
        const possibleTemplates = templateInventory.filter((templateInv) => {
          return (
            templateInv.model === template.model &&
            templateInv.protocol === template.protocol &&
            templateInv.cucmId === template.cucmId
          );
        });
        const content = {
          ...modelProtocolServer,
          defaultTemplate: existingOverride
            ? existingOverride.name
            : possibleTemplates.find((possibleTemplate) => possibleTemplate.standardTemplate).name,
        };
        const options: FilterSelectOption[] = possibleTemplates.map((templateInv) => {
          return {
            label: templateInv.name,
            value: templateInv,
          };
        });

        let hasInitialError = false;
        if (existingOverride && options.every((option) => existingOverride.templateId !== option.value.id)) {
          // if the template has somehow been deleted from cucm, we need to show it in the options
          // and display an error on the row.
          options.push({
            label: existingOverride.name,
            value: {
              id: existingOverride.templateId,
              name: existingOverride.name,
              model: existingOverride.model,
              protocol: existingOverride.protocol,
              cucmId: existingOverride.cucmId,
              phoneRefs: 0,
              cucmDeviceDefault: false,
              standardTemplate: false,
            },
          });
          hasInitialError = true;
        }

        array.push({
          content: content,
          fields: {
            defaultTemplate: {
              type: EntityTableFieldTypes.SELECT,
              options: options,
              validation: {
                validator: (value: FilterSelectOption) => {
                  return !(hasInitialError && value.value.id === existingOverride.templateId);
                },
                message: 'tkey;admin.phone_button_templates.feedback.template_invalid.text',
                hasError: hasInitialError,
              },
            },
          },
          cssClass: hasInitialError ? 'table-danger' : '',
        });
      }
      return array;
    }, []);
  }

  private _setSaveButtonPendingState(newState: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'saveDefaultTemplateConfig',
        state: {
          pending: newState,
          buttonDisableState: { disabled: newState, tooltipKey: '' },
        },
      })
    );
  }

  private _scrollToFirstError() {
    const firstErrorRow = document.querySelector('.table-danger');
    firstErrorRow && firstErrorRow.scrollIntoView();
  }

  private _saveDefaultTemplateConfig() {
    this._isSubmitted = true;
    if (this.tableRows.some((row) => Object.values(row.fields).some((cell) => cell.validation?.hasError))) {
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: true,
        })
      );

      this._scrollToFirstError();
      return;
    }

    this.isSaving = true;
    this._setSaveButtonPendingState(true);

    this.defaultPhoneButtonTemplateResource.saveDefaultPhoneButtonTemplateOverrides(this._overrides).subscribe(() => {
      this.toastService.push(
        ToastTypes.SUCCESS,
        SmacsIcons.TABLE,
        'tkey;shared.toast.save.success.title',
        'tkey;admin.phone_button_templates.toast.saved'
      );

      this.isSaving = false;
      this._setSaveButtonPendingState(false);
    });
  }

  onTemplateChange(event: EntityTableOnFieldChange) {
    if (this._isSubmitted) {
      const hasError = this.tableRows.some((row) =>
        Object.values(row.fields).some((cell) => cell.validation?.hasError)
      );
      this.bottomNavService.dispatch(
        new BottomNavUpdateState({
          hasValidationError: hasError,
        })
      );
    }

    const newValue = (event.newValue as FilterSelectOption).value as PhoneButtonTemplateInventory;
    const newOverride: ButtonTemplateOverride = {
      templateId: newValue.id,
      name: newValue.name,
      model: newValue.model,
      protocol: newValue.protocol,
      cucmId: newValue.cucmId,
    };
    const existingOverride = this._overrides.findIndex((override) => {
      return (
        newValue.model === override.model &&
        newValue.protocol === override.protocol &&
        newValue.cucmId === override.cucmId
      );
    });
    if (existingOverride > -1) {
      this._overrides.splice(existingOverride, 1, newOverride);
    } else {
      this._overrides.push(newOverride);
    }

    this._buildTableRows(this._templateInventory, this._overrides);
  }
}
