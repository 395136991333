import { Component } from '@angular/core';
import { SmacsFieldAbstractDirective } from '../../smacs-field-abstract.directive';
import { SmacsFieldComponentConfig } from '../../smacs-forms-models';
import { SmacsFormStateService } from '../../smacs-form-state.service';
import { isNil } from 'lodash';

type SmacsTextareaValue = string;

export class SmacsTextareaConfig extends SmacsFieldComponentConfig {
  constructor(
    public config?: {
      placeholder?: string;
      nonresizable?: boolean;
      inlineLabel?: boolean;
      rightAlignedLabel?: boolean;
    }
  ) {
    super();
  }
}

@Component({
  selector: 'smacs-textarea',
  templateUrl: './smacs-textarea.component.html',
  styleUrls: ['./smacs-textarea.component.scss'],
  providers: [{ provide: SmacsFieldAbstractDirective, useExisting: SmacsTextareaComponent }],
})
export class SmacsTextareaComponent extends SmacsFieldAbstractDirective<
  SmacsTextareaValue,
  SmacsTextareaValue,
  SmacsTextareaConfig
> {
  placeholder = '';
  nonresizable = false;
  inlineLabel = false;
  rightAlignedLabel = false;

  constructor(protected smacsFormStateService: SmacsFormStateService) {
    super(smacsFormStateService);
  }

  applyComponentConfig = ({ config }: SmacsTextareaConfig) => {
    this.placeholder = isNil(config?.placeholder) ? this.placeholder : config.placeholder;
    this.nonresizable = isNil(config?.nonresizable) ? this.nonresizable : config.nonresizable;
    this.inlineLabel = isNil(config?.inlineLabel) ? this.inlineLabel : config.inlineLabel;
    this.rightAlignedLabel = isNil(config?.rightAlignedLabel) ? this.rightAlignedLabel : config.rightAlignedLabel;
  };
}
