<div [attr.data-automation]="dataAutomation" [class.disabled]="isDisabled" class="input-group">
  <div>
    <span class="input-group-text input-group-prepend form-control">
      <i [attr.class]="smacsIcons.FILTER"></i>
    </span>
  </div>
  <input
    autocomplete="off"
    id="smacs-filter-input"
    type="text"
    class="form-control"
    [placeholder]="placeholder | translate"
    [(ngModel)]="value"
    [attr.data-automation]="dataAutomation + '-input'"
    (keyup)="onKeyUpEvent()"
    (keydown)="onKeyDownEvent()"
  />
  <div
    *ngIf="value"
    (click)="onClear()"
    [ngClass]="{ 'has-appended-content': appendedContent }"
    class="smacs-filter-clear"
  >
    x
  </div>
  <span *ngIf="appendedContent" class="input-group-text form-control">
    {{ appendedContent }}
  </span>
</div>
