<div class="d-flex flex-column h-100" data-automation="generate-loa-modal-content">
  <p>{{ 'tkey;admin.order_numbers.order_view_and_edit.documents.generate_loa.modal.body' | translate }}</p>
  <p *ngIf="authorizingPersonDisplayName !== currentUserDisplayName" class="strong">
    {{
      'tkey;admin.order_numbers.order_view_and_edit.documents.generate_loa.modal.body.confirm_forgery'
        | translate : { authorizingPerson: authorizingPersonDisplayName }
    }}
  </p>
  <embed *ngIf="pdfSrc" [src]="pdfSrc" class="pdf-embed" />
</div>
