import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PcceAgent, PcceAgentRef } from '../../../shared/models/generated/smacsModels';

@Injectable()
export class PcceAgentResource {
  constructor(private http: HttpClient) {}

  get(id: string, serverId: string): Observable<PcceAgent> {
    return this.http.get<PcceAgent>(
      `/services/cisco/macs/pcce-servers/${encodeURIComponent(serverId)}/pcce-agents/${encodeURIComponent(id)}`
    );
  }

  put(id: string, serverId: string, pcceAgent: PcceAgent): Observable<PcceAgentRef> {
    return this.http.put<PcceAgentRef>(
      `/services/cisco/macs/pcce-servers/${encodeURIComponent(serverId)}/pcce-agents/${encodeURIComponent(id)}`,
      pcceAgent
    );
  }

  post(serverId: string, pcceAgent: PcceAgent): Observable<PcceAgentRef> {
    return this.http.post<PcceAgentRef>(
      `/services/cisco/macs/pcce-servers/${encodeURIComponent(serverId)}/pcce-agents`,
      pcceAgent
    );
  }
}
