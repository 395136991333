import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../../forms/smacs-form-abstract.directive';
import { Observable, of, Subscription, switchMap, throwError } from 'rxjs';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../../shared/bottom-nav/bottom-nav.service';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { BottomNavButton } from '../../../../shared/bottom-nav/bottom-nav.component';
import { BandwidthSettings, TlsVoiceEnvironmentType } from '../../../../shared/models/generated/smacsModels';
import { HtmlInputType, SmacsTextComponent, SmacsTextConfig } from '../../../../forms/fields/text/smacs-text.component';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { ToastService } from '../../../../shared/services/toast.service';
import { SmacsFormsValidationState } from '../../../../forms/smacs-forms-models';
import { catchError, tap } from 'rxjs/operators';
import { ToastTypes } from '../../../../shared/services/abstract/toast.service.abstract';
import { TranslateService } from '@ngx-translate/core';
import { SmacsFormStateService } from '../../../../forms/smacs-form-state.service';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { BandwidthCachedOptionsContext } from '../../../contexts/bandwidth-cached-options.context';
import { BandwidthSettingsContext } from '../../../../shared/contexts/bandwidth-settings.context';
import { HttpErrorResponse } from '@angular/common/http';
import { SmacsSelectConfig } from '../../../../forms/fields/select/smacs-select.component';

@Component({
  selector: 'app-bandwidth-account-settings',
  templateUrl: './bandwidth-management.component.html',
  styleUrls: ['../../../admin-page.scss', './bandwidth-management.component.scss'],
})
export class BandwidthManagementComponent
  extends SmacsFormAbstractDirective<BandwidthSettings>
  implements OnInit, OnDestroy
{
  smacsIcons = SmacsIcons;
  isLoading = false;
  isRefreshing: boolean;
  formConfig = {
    fields: {
      username: {
        label: 'tkey;admin.bandwidth_account_settings.username',
        dataAutomation: 'bandwidth-account-settings-username',
        required: true,
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: 'ucteam@yourcompany.com',
        }),
      },
      password: {
        label: 'tkey;admin.bandwidth_account_settings.password',
        dataAutomation: 'bandwidth-account-settings-password',
        required: () => !this.isExisting,
        componentConfig: new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }),
      },
      dlrAccountId: {
        label: 'tkey;admin.bandwidth_account_settings.dlr_account_id',
        dataAutomation: 'bandwidth-account-settings-dlr-account-id',
        required: true,
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: '7007148520',
        }),
        validation: [
          {
            validator: (val: number) =>
              val >= 1 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: () =>
              this.translateService.instant('tkey;validators.global.error.min', {
                minValue: 1,
              }),
          },
        ],
      },
      tlsVoiceAccountId: {
        label: 'tkey;admin.bandwidth_account_settings.tls_account_id',
        dataAutomation: 'bandwidth-account-settings-tls-account-id',
        required: true,
        componentConfig: new SmacsTextConfig({
          htmlInputType: HtmlInputType.TEXT,
          placeholder: '7007148520',
        }),
        validation: [
          {
            validator: (val: number) =>
              val >= 1 ? SmacsFormsValidationState.VALID : SmacsFormsValidationState.INVALID,
            message: () =>
              this.translateService.instant('tkey;validators.global.error.min', {
                minValue: 1,
              }),
          },
        ],
      },
      tlsVoiceEnvironmentType: {
        label: 'tkey;admin.bandwidth_account_settings.tls_environment_type',
        dataAutomation: 'bandwidth-account-settings-tls-environment-type',
        required: true,
        componentConfig: new SmacsSelectConfig({
          options: [TlsVoiceEnvironmentType.TEST, TlsVoiceEnvironmentType.PRODUCTION],
        }),
      },
    },
  };

  private _subscriptions = new Subscription();
  private _isSaveClicked = false;

  constructor(
    private bottomNavService: BottomNavService,
    private smacsModalService: SmacsModalService,
    private toastService: ToastService,
    protected smacsFormStateService: SmacsFormStateService,
    private translateService: TranslateService,
    private breadcrumbsService: BreadcrumbsService,
    private bandWidthCachedOptionsContext: BandwidthCachedOptionsContext,
    private bandwidthSettingsContext: BandwidthSettingsContext
  ) {
    super(smacsFormStateService);
  }

  ngOnInit(): void {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.bandwidth_account_settings.title' }]);
    this._initBottomNav();

    const formValidateSub = this._validateAndSubmitSource.subscribe((submit: boolean) => {
      if (submit && !this.isFormValid()) {
        this._setBottomNavValidation(true);
      }
    });
    this._subscriptions.add(formValidateSub);

    const bandwidthSettingsContextSub = this.bandwidthSettingsContext.state$.subscribe((bwSettings) => {
      if (bwSettings !== null && !this._isSaveClicked) {
        this.setIsExisting(true);
        this.entitySource.next({
          ...bwSettings,
        });
        this.isLoading = false;
        this._setDeleteDisabledState(!this.isExisting);
      }
    });
    this._subscriptions.add(bandwidthSettingsContextSub);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this._subscriptions.unsubscribe();
  }

  refreshCache() {
    this.isRefreshing = true;
    this._setDeleteDisabledState(true);
    this._setSaveDisabledState(true);
    this.bandWidthCachedOptionsContext
      .refresh()
      .pipe(
        tap(() => {
          this.toastService.push(
            ToastTypes.SUCCESS,
            this.smacsIcons.BANDWIDTH,
            'tkey;admin.bandwidth_account_settings.toast.refresh_cache.title',
            'tkey;admin.bandwidth_account_settings.toast.refresh_cache.message'
          );
          this.isRefreshing = false;
          this._setDeleteDisabledState(false);
          this._setSaveDisabledState(false);
        }),
        catchError((response) => {
          this.isRefreshing = false;
          this._setDeleteDisabledState(false);
          this._setSaveDisabledState(false);
          return throwError(() => response);
        })
      )
      .subscribe(() => {
        this.isRefreshing = false;
      });
  }

  protected submit() {
    return this._onFormSubmit();
  }

  private _initBottomNav(): void {
    const bottomNavButtons = [
      {
        id: 'bandwidth-account-settings-delete-button',
        label: 'tkey;admin.bandwidth_account_settings.delete.label',
        buttonClass: ButtonStyles.DANGER,
        dataAutomation: 'bandwidth-account-settings-delete-button',
        state: {
          pending: false,
          buttonDisableState: {
            disabled: false,
            tooltipKey: '',
          },
        },
        icon: this.smacsIcons.DELETE,
        cb: () => this._onDeleteClick(),
      },
      {
        id: 'bandwidth-account-settings-save-button',
        dataAutomation: 'bandwidth-account-settings-save-button',
        label: 'tkey;admin.bandwidth_account_settings.save.label',
        icon: this.smacsIcons.OK,
        buttonClass: ButtonStyles.PRIMARY,
        state: {
          pending: false,
          buttonDisableState: { disabled: false, tooltipKey: '' },
          tooltipVisible: false,
        },
        type: ButtonTypes.SUBMIT,
        submitSubject: this._validateAndSubmitSource,
      },
    ] as BottomNavButton[];
    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _onFormSubmit(): Observable<boolean> {
    this._isSaveClicked = true;
    this._setSavePendingState(true);
    this._setDeleteDisabledState(true);
    this._setBottomNavValidation(false);
    const bwSettings: BandwidthSettings = {
      dlrAccountId: this.formData.dlrAccountId,
      password: this.formData.password,
      tlsVoiceAccountId: this.formData.tlsVoiceAccountId,
      tlsVoiceEnvironmentType: this.formData.tlsVoiceEnvironmentType,
      username: this.formData.username,
    };
    return this.bandwidthSettingsContext.testConnection(bwSettings).pipe(
      switchMap(() => {
        return this.bandwidthSettingsContext.put(bwSettings).pipe(
          switchMap(() => {
            this.toastService.push(
              ToastTypes.SUCCESS,
              this.smacsIcons.BANDWIDTH,
              'tkey;shared.toast.save.success.title',
              'tkey;admin.bandwidth_account_settings.toast.saved.message'
            );
            this._setSavePendingState(false);
            this._setDeleteDisabledState(false);
            return of(true);
          }),
          catchError((errorResponse) => {
            this._setSavePendingState(false);
            this._setDeleteDisabledState(false);
            this._setBottomNavValidationError(true);
            this.toastService.push(
              ToastTypes.ERROR,
              this.smacsIcons.BANDWIDTH,
              'tkey;admin.bandwidth_account_settings.toast.failed.message',
              errorResponse.error.description
            );
            return of(false);
          })
        );
      })
    );
  }

  private _setBottomNavValidation(showError: boolean): void {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: showError,
      })
    );
  }

  private _onDeleteClick() {
    const options = {
      animation: false,
      modalViewProperties: {
        promptBody: this.translateService.instant('tkey;admin.bandwidth_account_settings.toast.delete.modal.message'),
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteBandwidthSettings();
            },
          },
        ],
      },
    };
    this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteBandwidthSettings(): Observable<any> {
    return new Observable<any>((subscriber) => {
      this.bandwidthSettingsContext.delete().subscribe({
        next: () => {
          this._resetFormState();
          this.toastService.push(
            ToastTypes.INFO,
            `${this.smacsIcons.BANDWIDTH} text-danger`,
            'tkey;shared.toast.delete.success.title',
            `tkey;admin.bandwidth_account_settings.toast.deleted.message`
          );
          subscriber.next();
          subscriber.complete();
        },
        error: (error) => {
          subscriber.error(error);
          throw new HttpErrorResponse(error);
        },
      });
    });
  }

  private _resetFormState() {
    this.setIsExisting(false);
    this.entitySource.next({
      username: '',
      password: '',
      dlrAccountId: '',
      tlsVoiceAccountId: '',
      tlsVoiceEnvironmentType: TlsVoiceEnvironmentType.PRODUCTION,
    });
    this.smacsFormStateService.setIsFormDirty(false);
    this.isFormSubmitted = false;
    this.fieldComponents.forEach((item: SmacsTextComponent) => {
      item.isDirty = false;
      item.showValidation = false;
      item.isFormSubmitted = this.isFormSubmitted;

      if (item.fieldId === 'password') {
        item.showMaskedInput = false;
        item.applyComponentConfig(new SmacsTextConfig({ htmlInputType: HtmlInputType.PASSWORD }));
      }
    });
    this._setDeleteButtonState(true);
  }

  private _setDeleteButtonState(isDisabled: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: 'bandwidth-account-settings-delete-button',
        state: {
          pending: false,
          buttonDisableState: {
            disabled: isDisabled,
          },
        },
      })
    );
  }

  private _setSaveDisabledState(state: boolean) {
    this.bottomNavService.setButtonDisabledState('bandwidth-account-settings-save-button', state);
  }

  private _setSavePendingState(setting: boolean) {
    this.bottomNavService.setButtonPendingState('bandwidth-account-settings-save-button', setting);
  }

  private _setDeleteDisabledState(state: boolean) {
    this.bottomNavService.setButtonDisabledState('bandwidth-account-settings-delete-button', state);
  }

  private _setBottomNavValidationError(hasError: boolean) {
    this.bottomNavService.setBottomNavValidationError(hasError);
  }
}
