<div class="smacs-admin-page-content">
  <app-loading-block [hidden]="!isLoading"></app-loading-block>

  <div class="col-md-10 offset-md-1 pb-2" [hidden]="isLoading">
    <app-breadcrumbs></app-breadcrumbs>

    <smacs-admin-ldap-management-settings
      *ngIf="!isLoading"
      [ldapConfiguration]="ldapConfiguration"
      [isSaved]="isSaved"
      [isProxyServerEnabled]="isProxyServerEnabled"
    ></smacs-admin-ldap-management-settings>
    <smacs-admin-ldap-management-permissions
      *ngIf="!isLoading"
      [ldapPermissionGroup]="ldapPermissionGroup"
      [isSaved]="isSaved"
      [isProxyServerEnabled]="isProxyServerEnabled"
    ></smacs-admin-ldap-management-permissions>
  </div>
</div>

<app-bottom-nav></app-bottom-nav>
