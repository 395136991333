import { Directive, Output } from '@angular/core';
import {
  Button,
  EndUserRef,
  ExtensionMobility,
  ExtensionMobilityFieldConfig,
  ExtensionMobilityRef,
  LineButton,
  LineFeature,
  LineFeatureFieldConfig,
  ModelProtocolFieldConfig,
  Phone,
  PhoneButtonTemplateFieldConfig,
  PhoneButtonTemplateRef,
  PhoneFieldConfig,
  PhoneRef,
  PhoneServiceSubscription,
  PostPhoneButtonTemplate,
  TemplateButton,
} from '../../../shared/models/generated/smacsModels';
import { PhoneType } from '../../../shared/models/service-type';
import { DragDropMode } from '../../../shared/phone-buttons/drag-drop-mode.enum';
import { Observable, of, Subject } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { cloneDeep, isEqual, tail } from 'lodash';
import { PhoneButtonTemplateService } from '../../../shared/services/phone-button-template.service';
import { PhoneFieldsEntity } from '../phone/phone-fields-form/phone-fields-form.component';
import { PhoneButtonsService, PhoneButtonSummary } from '../../../shared/phone-buttons/shared/phone-buttons.service';
import {
  PhoneButtonLayoutConflictModalComponent,
  PhoneButtonLayoutConflictModalViewProperties,
} from '../../../shared/modals/phone-button-layout-conflict-modal/phone-button-layout-conflict-modal.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { ButtonStyles } from '../../../button/button.component';
import { TranslateService } from '@ngx-translate/core';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { AbstractUiContext } from '../../../shared/phone-buttons/contexts/abstract-ui.context';
import { ModelProtocolFormData } from '../phone/phone-model-protocol-form/phone-model-protocol-form.component';

@Directive({
  selector: '[smacsDeviceAbstract]',
})
export abstract class DeviceAbstractDirective<
  DEVICE extends Phone | ExtensionMobility,
  FIELD_CONFIG extends PhoneFieldConfig | ExtensionMobilityFieldConfig
> {
  DragDropMode = DragDropMode;
  PhoneType = PhoneType;

  @Output() save$ = new Subject<PhoneRef | ExtensionMobilityRef>();
  @Output() delete$ = new Subject<void>();
  @Output() cancel$ = new Subject<void>();

  phone: DEVICE;
  phoneFieldsEntity: PhoneFieldsEntity;
  fieldConfig: FIELD_CONFIG;
  phoneType: PhoneType;

  modelProtocolFormData: ModelProtocolFormData;
  phoneButtonTemplateEntity: PhoneButtonTemplateRef;
  possiblePhoneButtonTemplates: PhoneButtonTemplateRef[];
  phoneButtonTemplateFieldConfig: PhoneButtonTemplateFieldConfig;

  modelProtocolFieldConfig: ModelProtocolFieldConfig;

  isPhoneButtonTemplateChangePending = false;
  cucmServerId: number;
  siteId: number;
  isAutomaticPhoneButtonTemplateMode: boolean;
  isDisplayEnhancedLineMode: boolean;

  endUserRef: EndUserRef;

  isLoading = true;

  protected _phoneId: string;
  protected _originalPhoneButtonTemplateFeatures: PostPhoneButtonTemplate;

  protected _validationStates = {
    modelProtocolValidationState: SmacsFormsValidationState.VALID,
    phoneFieldsValidationState: SmacsFormsValidationState.VALID,
    phoneServiceSubscriptionsValidationState: SmacsFormsValidationState.VALID,
    phoneButtonValidationState: SmacsFormsValidationState.VALID,
    mobilityIdentityValidationState: SmacsFormsValidationState.VALID,
  };

  protected constructor(
    protected translateService: TranslateService,
    protected smacsModalService: SmacsModalService,
    protected phoneButtonTemplateService: PhoneButtonTemplateService,
    protected phoneButtonsService: PhoneButtonsService,
    protected uiContext: AbstractUiContext<DEVICE>
  ) {}

  protected _mapToPhoneFieldsEntity(device: DEVICE): PhoneFieldsEntity {
    // this cast is fine because we don't care about undefined values
    const deviceEntity = cloneDeep(device) as Phone & ExtensionMobility;
    return {
      aarClassOfService: deviceEntity.aarClassOfService,
      alwaysUsePrimeLine: deviceEntity.alwaysUsePrimeLine,
      alwaysUsePrimeLineForVoiceMessage: deviceEntity.alwaysUsePrimeLineForVoiceMessage,
      associatedAppUsers: deviceEntity.associatedAppUsers,
      associatedEndUsers: deviceEntity.associatedEndUsers,
      builtInBridge: deviceEntity.builtInBridge,
      ciscoSupportField: deviceEntity.ciscoSupportField,
      classOfService: deviceEntity.classOfService,
      crossClusterCss: deviceEntity.crossClusterCss,
      commonDeviceConfiguration: deviceEntity.commonDeviceConfiguration,
      commonPhoneProfile: deviceEntity.commonPhoneProfile,
      description: deviceEntity.description,
      deviceMobilityMode: deviceEntity.deviceMobilityMode,
      devicePool: deviceEntity.devicePool,
      disableSpeakerPhone: deviceEntity.disableSpeakerPhone,
      emergencyNumbers: deviceEntity.emergencyNumbers,
      extDataLocationAuthServer: deviceEntity.extDataLocationAuthServer,
      extDataLocationSecureAuthUrl: deviceEntity.extDataLocationSecureAuthUrl,
      featureControlPolicyName: deviceEntity.featureControlPolicyName,
      id: deviceEntity.id,
      location: deviceEntity.location,
      mediaResourceGroupList: deviceEntity.mediaResourceGroupList,
      name: deviceEntity.name,
      networkMohSourceName: deviceEntity.networkMohSourceName,
      privacy: deviceEntity.privacy,
      reroutingCallingSearchSpace: deviceEntity.reroutingCallingSearchSpace,
      securityProfileName: deviceEntity.securityProfileName,
      sipProfileName: deviceEntity.sipProfileName,
      softkeyTemplate: deviceEntity.softkeyTemplate,
      subscribeCss: deviceEntity.subscribeCss,
      userLocale: deviceEntity.userLocale,
      userMohSourceName: deviceEntity.userMohSourceName,
      ctiAssociatedEndUsers: deviceEntity.ctiAssociatedEndUsers,
      ctiAssociatedAppUsers: deviceEntity.ctiAssociatedAppUsers,
    };
  }

  protected _assignPhoneButtonTemplate(): Observable<void> {
    if (this.isAutomaticPhoneButtonTemplateMode && this._phoneButtonTemplateFeaturesHaveChanged()) {
      return this.phoneButtonTemplateService.findOrCreateTemplate(this.phone, this.cucmServerId).pipe(
        map((phoneButtonTemplateRef) => {
          this.phone.phoneButtonTemplate = phoneButtonTemplateRef;
        })
      );
    } else {
      return of(null);
    }
  }

  protected _associateCurrentUserToLines() {
    if (this.endUserRef) {
      this.phone.buttons.forEach((button) => {
        if (
          this.phoneButtonsService.isLineButton(button) &&
          button.lineFeature &&
          !button.lineFeature.associatedEndUsers.some((endUser) => endUser.id === this.endUserRef.id)
        ) {
          button.lineFeature.associatedEndUsers.push(this.endUserRef);
        }
      });
    }
  }

  protected _isButtonInConflictWithTemplate(
    button: PhoneButtonSummary,
    template: TemplateButton,
    availableTypes: string[]
  ): boolean {
    if (!button) {
      // There is no button, no conflict
      return false;
    } else if (!template) {
      // When there is a configured button that doesn't fit on the proposed phone, there's a conflict!
      return !!button.label || !!button.value;
    } else if (button.type === template.feature) {
      // Button type hasn't changed, no conflict
      return false;
    } else if (!button.label && !button.value) {
      // Our current button isn't configured so we don't care if it's overwritten, no conflict
      return false;
    } else if (!this.isAutomaticPhoneButtonTemplateMode || template.fixed) {
      // If the type of the new button can't be changed, there's a conflict!
      return true;
    } else {
      // If the old button type isn't available on the new phone, there's a conflict!
      return !availableTypes.includes(button.type);
    }
  }

  protected _overlayButtons(
    currentButtons: Button[],
    proposedTemplateConfig: TemplateButton[],
    proposedTemplateAvailableTypes: string[]
  ): Button[] {
    return proposedTemplateConfig.map((buttonTemplate, i) => {
      const currentButton = currentButtons && currentButtons[i];

      // On an existing phone, the button type is overwritten only if one of the following conditions are true:
      // The existing button type is not supported on the new model.
      // The button is fixed on the new model.
      // On a new phone, the button type is always overwritten by the default type of the new model.
      if (currentButton) {
        if (currentButton.type === buttonTemplate.feature) {
          return currentButton;
        } else if (
          !proposedTemplateAvailableTypes.includes(currentButton.type) ||
          buttonTemplate.fixed ||
          !this.isAutomaticPhoneButtonTemplateMode ||
          !this._phoneId
        ) {
          return this.phoneButtonsService.newButtonOfType(buttonTemplate.feature);
        } else {
          return currentButton;
        }
      } else {
        return this.phoneButtonsService.newButtonOfType(buttonTemplate.feature);
      }
    });
  }

  protected _redefineLineFeatureFieldValues(
    lineFeature: LineFeature,
    lineFeatureConfig: LineFeatureFieldConfig
  ): LineFeature {
    lineFeature.label = lineFeatureConfig.label.defaultValue;
    lineFeature.ringActive = lineFeatureConfig.ringActive.defaultValue;
    lineFeature.ringIdle = lineFeatureConfig.ringIdle.defaultValue;
    lineFeature.callRecordingMediaSource = lineFeatureConfig.callRecordingMediaSource.defaultValue;
    lineFeature.callRecordingOption = lineFeatureConfig.callRecordingOption.defaultValue;
    lineFeature.callRecordingProfile = lineFeatureConfig.callRecordingProfile.defaultValue;
    lineFeature.busyTrigger = Number(lineFeatureConfig.busyTrigger.defaultValue);
    lineFeature.maxNumberOfCalls = Number(lineFeatureConfig.maxNumberOfCalls.defaultValue);
    lineFeature.monitoringCssName = lineFeatureConfig.monitoringCssName.defaultValue;
    return lineFeature;
  }

  protected _isButtonConflictPresent(
    currentButtons: PhoneButtonSummary[],
    proposedTemplateConfig: TemplateButton[],
    proposedTemplateAvailableTypes: string[]
  ): boolean {
    return currentButtons.some((button, index) =>
      this._isButtonInConflictWithTemplate(button, proposedTemplateConfig[index], proposedTemplateAvailableTypes)
    );
  }

  protected _openPhoneButtonLayoutConflictModal(
    modalOptions: PhoneButtonLayoutConflictModalViewProperties,
    isCopyUdpButton = false
  ): Observable<boolean> {
    const options = {
      size: 'lg',
      modalViewProperties: {
        title: this.translateService.instant('tkey;phone.phone_button_layout_conflict_modal.header'),
        icon: SmacsIcons.WARNING,
        iconClass: encodeURIComponent('text-warning'),
        isButtonConflictPresent: modalOptions.isButtonConflictPresent,
        currentButtonLayout: modalOptions.currentButtonLayout,
        proposedButtonLayout: modalOptions.proposedButtonLayout,
        changeType: modalOptions.changeType,
        currentValue: modalOptions.currentValue,
        proposedValue: modalOptions.proposedValue,
        currentExpansionModules: modalOptions.currentExpansionModules,
        displayCloseButton: true,
        isCopyUdpButton: isCopyUdpButton,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;phone.phone_button_layout_conflict_modal.confirm',
            buttonClass: ButtonStyles.WARNING,
            dataAutomation: 'confirmation-modal-confirm-button',
            isSubmitButton: true,
          },
        ],
      },
      bodyClass: PhoneButtonLayoutConflictModalComponent,
    };
    return this.smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  protected _clearServiceUrlButton(subscriptionToClear: PhoneServiceSubscription[]) {
    this.phone.buttons.forEach((button) => {
      if (
        this.phoneButtonsService.isServiceUrlButton(button) &&
        subscriptionToClear.some(
          (subscription) => subscription.phoneServiceSubscriptionName === button.subscriptionName
        )
      ) {
        button.label = '';
        button.subscriptionName = '';
      }
    });
  }

  protected _getPhoneLines(newProtocol: string, newModel: string, phone: DEVICE): DEVICE {
    const firstButton = cloneDeep(phone.buttons[0]) as LineButton;
    const newPhoneState = {
      ...this.phone,
      ...phone,
      name: this.phone.name || phone.name,
      buttons: [firstButton, ...tail(this.phone.buttons)],
      phoneButtonTemplate: this.phone.phoneButtonTemplate,
    };

    newPhoneState.buttons
      .filter((button) => button.type === 'Line')
      .forEach((lineButton: LineButton) => {
        if (!!lineButton.dn && !!lineButton.lineFeature) {
          const secondaryLineFeature = lineButton.lineFeature;
          this.uiContext
            .getLineFeatureFieldConfig(
              newModel,
              newProtocol,
              this.endUserRef?.username,
              lineButton.dn.extension,
              this.siteId
            )
            .subscribe((lineFeatureFieldConfig) => {
              lineButton.lineFeature = this._redefineLineFeatureFieldValues(
                secondaryLineFeature,
                lineFeatureFieldConfig
              );
            });
        }
      });
    return cloneDeep(newPhoneState);
  }

  protected _checkForConflictAndGetTemplate(
    newPhoneButtonTemplate: PhoneButtonTemplateRef,
    oldPhoneButtonTemplate: PhoneButtonTemplateRef
  ): Observable<void> {
    return this.phoneButtonTemplateService.getTemplate(newPhoneButtonTemplate.id, newPhoneButtonTemplate.serverId).pipe(
      switchMap((template) => {
        const currentButtonSummary = this.phone.buttons.map((button) =>
          this.phoneButtonsService.mapButtonToButtonSummary(button)
        );
        const availableFeatures = this.phoneButtonTemplateFieldConfig.availableButtonFeatures;
        const proposedButtons = this._overlayButtons(this.phone.buttons, template.buttons, availableFeatures);
        const isButtonConflictPresent = this._isButtonConflictPresent(
          currentButtonSummary,
          template.buttons,
          availableFeatures
        );

        if (isButtonConflictPresent) {
          const proposedButtonSummary = proposedButtons.map((button) =>
            this.phoneButtonsService.mapButtonToButtonSummary(button)
          );
          return this._openPhoneButtonLayoutConflictModal({
            changeType: 'phone button template',
            currentValue: oldPhoneButtonTemplate.name,
            proposedValue: newPhoneButtonTemplate.name,
            currentButtonLayout: currentButtonSummary,
            proposedButtonLayout: proposedButtonSummary,
            currentExpansionModules: [],
            isButtonConflictPresent: isButtonConflictPresent,
          }).pipe(
            map((confirmed) => {
              if (confirmed) {
                const newPhone = cloneDeep(this.phone);
                newPhone.phoneButtonTemplate = newPhoneButtonTemplate;
                newPhone.buttons = proposedButtons;
                this.uiContext.setPhoneTemplate(template);
                this.uiContext.set(newPhone);
              } else {
                this.phoneButtonTemplateEntity = { ...this.phone.phoneButtonTemplate };
              }
            })
          );
        } else {
          const newPhone = cloneDeep(this.phone);
          newPhone.phoneButtonTemplate = newPhoneButtonTemplate;
          newPhone.buttons = proposedButtons;
          this.uiContext.setPhoneTemplate(template);
          this.uiContext.set(newPhone);
          return of(null);
        }
      })
    );
  }

  protected _handleModelProtocolFieldConfig(): Observable<ModelProtocolFieldConfig> {
    return this.uiContext.modelProtocolFieldConfigState$.pipe(
      first(),
      tap((fieldConfig: ModelProtocolFieldConfig) => {
        this.modelProtocolFieldConfig = fieldConfig;
      })
    );
  }

  private _phoneButtonTemplateFeaturesHaveChanged(): boolean {
    return (
      this.phone.model !== this._originalPhoneButtonTemplateFeatures.model ||
      this.phone.protocol !== this._originalPhoneButtonTemplateFeatures.protocol ||
      !isEqual(
        this._originalPhoneButtonTemplateFeatures.features,
        this.phone.buttons.map((button) => button.type)
      )
    );
  }
}
