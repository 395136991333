import { Injectable } from '@angular/core';
import { UcMetadataCacheContext } from '../../contexts/uc-metadata-cache.context';
import { ExtensionMobility, Phone } from '../../models/generated/smacsModels';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

export interface DragNDropUICache {
  buttonTypes: string[];
}

@Injectable({ providedIn: 'root' })
export class DragdropUiContext {
  private _dragnDropSource = new ReplaySubject<DragNDropUICache>(1);
  dragnDropState$ = this._dragnDropSource.asObservable();

  constructor(private ucMetadataCacheContext: UcMetadataCacheContext) {}

  setDeviceMetaDataCache(serverId: number, phone: Phone | ExtensionMobility) {
    this.ucMetadataCacheContext.state$.pipe(first()).subscribe((ucMetadataCache) => {
      const metaDataCache = ucMetadataCache.cucmDeviceModelMetadataCache[serverId][phone.model];
      let availableFeatures: string[] = [];
      if (!metaDataCache) {
        availableFeatures = [];
      } else if (phone.protocol === 'SIP') {
        availableFeatures = metaDataCache.sipSetting.availableButtonFeatures;
      } else if (phone.protocol === 'SCCP') {
        availableFeatures = metaDataCache.sccpSetting.availableButtonFeatures;
      }
      const deviceObj = {
        buttonTypes: availableFeatures,
      } as DragNDropUICache;
      this._dragnDropSource.next(deviceObj);
    });
  }
}
