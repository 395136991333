import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import {
  DirectoryNumberRef,
  EndUserRef,
  EndUserResult,
  Global360View,
  LineButton,
  LineFeature,
  LineFeatureFieldConfig,
  SiteSummary,
  SnrDestination,
  SnrDestinationRef,
  SnrFieldConfig,
  SnrProfile,
  SnrProfileRef,
} from '../../../shared/models/generated/smacsModels';
import { ActivatedRoute, Router } from '@angular/router';
import { SnrProfileResource } from '../../../self-serve/resources/snr-profile.resource';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { SnrFieldConfigResource } from '../../shared/resources/field-config/snr-field-config.resource';
import { LineFeatureConfigResource } from '../../../shared/resources/line-feature-config.resource';
import {
  BottomNavClearButtonsList,
  BottomNavService,
  BottomNavUpdateButtonsList,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ButtonStyles, ButtonTypes } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { SmacsFormStateService } from '../../../forms/smacs-form-state.service';
import { SiteContext } from '../../shared/contexts/site.context';
import { Global360ViewContext } from '../../../shared/contexts/global-360-view.context';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ToastService } from '../../../shared/services/toast.service';
import { EndUserResource } from '../../../shared/resources/end-user.resource';
import { cloneDeep, isEqual } from 'lodash';
import { SnrDestinationsFormComponent } from './snr-destinations-form/snr-destinations-form.component';
import { SnrProfileFormComponent } from './snr-profile-form/snr-profile-form.component';
import { PhoneButtonsLineHelpdeskFormComponent } from '../../../shared/phone-buttons/edit-form/helpdesk/phone-buttons-line-helpdesk-form/phone-buttons-line-helpdesk-form.component';
import { catchError, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { SmacsModalService } from '../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { SmacsFormsUpdate, SmacsFormsValidationState } from '../../../forms/smacs-forms-models';
import { Nvp } from '../../../shared/models/nvp';

export enum SnrModelProtocol {
  SNR_MODEL = 'Remote Destination Profile',
  SNR_PROTOCOL = 'Remote Destination',
}

@Component({
  selector: 'smacs-snr',
  templateUrl: './snr.component.html',
  styleUrls: ['./snr.component.scss'],
})
export class SnrComponent implements OnInit, OnDestroy {
  @ViewChild(SnrDestinationsFormComponent) snrDestinationsViewChild: SnrDestinationsFormComponent;
  @ViewChild(SnrProfileFormComponent) snrProfileViewChild: SnrProfileFormComponent;
  @ViewChild(PhoneButtonsLineHelpdeskFormComponent) snrProfileLineViewChild: PhoneButtonsLineHelpdeskFormComponent;

  isLoading = true;
  cucmServerId: number;
  snrDestinations: SnrDestination[] = [];
  snrProfile: SnrProfile;
  snrProfileLine: LineFeature;
  snrFieldConfig: SnrFieldConfig;
  lineFeatureFieldConfig: LineFeatureFieldConfig;
  smacsIcons = SmacsIcons;
  primaryExtension: DirectoryNumberRef;
  parentSubmit$: Observable<(void | SnrDestinationRef)[] | SnrProfileRef | void | EndUserRef>;
  isErrorThrown = false;

  private _snrProfileId: string;
  private _global360View: Global360View;
  private _username: string;
  private _siteId: number;
  private _siteSummary: SiteSummary;
  private _endUser: EndUserResult;
  private _snrProfileRef: SnrProfileRef;
  private _isSubmitted = false;
  private _initialSnrDestinations: SnrDestination[];
  private _initialSnrProfile: SnrProfile;
  private _initialSnrProfileLine: LineFeature;
  private _validationStates = {
    destinationsValidationState: SmacsFormsValidationState.VALID,
    snrProfileValidationState: SmacsFormsValidationState.VALID,
    profileLineValidationState: SmacsFormsValidationState.VALID,
  };
  private _snrDestinationsEntity: SnrDestination[] = [];
  private _snrProfileEntity: SnrProfile;
  private _snrProfileLineEntity: LineFeature;
  private _snrDestinationRequests: Observable<SnrDestinationRef | void>[] = [];
  _isSnrDestinationsUpdated: boolean;
  _isSnrProfileUpdated: boolean;

  constructor(
    private _smacsFormStateService: SmacsFormStateService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _siteContext: SiteContext,
    private _siteSummaryContext: SiteSummaryContext,
    private _bottomNavService: BottomNavService,
    private _snrProfileResource: SnrProfileResource,
    private _global360ViewContext: Global360ViewContext,
    private _endUserResource: EndUserResource,
    private _breadcrumbsService: BreadcrumbsService,
    private _snrFieldConfigResource: SnrFieldConfigResource,
    private _lineFeatureConfigResource: LineFeatureConfigResource,
    private _toastService: ToastService,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _userDetailUiContext: UserDetailUiContext,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this._snrProfileId = this._route.snapshot.paramMap.get('profileId');
    this._username = this._route.snapshot.paramMap.get('username');
    this._updateBreadCrumb();

    combineLatest([
      this._global360ViewContext.state$.pipe(take(1)),
      this._siteContext.state$.pipe(take(1)),
      this._siteSummaryContext.state$,
    ])
      .pipe(
        tap(([global360View, site, siteSummary]) => {
          this._global360View = global360View;
          this._siteId = Number(site.id);
          this._siteSummary = siteSummary;
          this.cucmServerId = this._siteSummaryContext.findCucmServerIdForSite(this._siteSummary, this._siteId);
          this._endUser = this._userDetailUiContext.getCurrentEnduser();
          this.primaryExtension = this._global360View.primaryExtensions.find(
            (extension) => extension.serverId === this.cucmServerId
          );
          if (this._global360View.snrProfiles.length) {
            const profileMatch = this._global360View.snrProfiles.find(
              (profileRef) => profileRef.id === this._snrProfileId && profileRef.serverId === this.cucmServerId
            );
            if (profileMatch) {
              this._snrProfileRef = profileMatch;
            }
          }
        }),
        switchMap(() =>
          this._snrFieldConfigResource.getFieldConfigs({ username: this._username, siteId: this._siteId.toString() })
        ),
        tap((snrFieldConfig) => (this.snrFieldConfig = snrFieldConfig)),
        switchMap(() => {
          if (this._snrProfileId) {
            return combineLatest([
              this._snrProfileResource.getSnrDestinations(this._snrProfileId, this.cucmServerId),
              this._snrProfileResource.getSnrProfile(this._snrProfileId, this.cucmServerId),
            ]);
          } else {
            return of(null);
          }
        }),
        switchMap((result) => {
          if (result) {
            const snrDestinations = result[0];
            const snrProfile = result[1];
            this._initialSnrDestinations = cloneDeep(snrDestinations);
            this._initialSnrProfile = cloneDeep(snrProfile);
            this._initialSnrProfileLine = cloneDeep(snrProfile.lines[0].lineFeature);

            this.snrDestinations = cloneDeep(snrDestinations);
            this.snrProfile = cloneDeep(snrProfile);
            this.snrProfileLine = cloneDeep(snrProfile.lines[0].lineFeature);

            const lineFeatureRequestBody = {
              extension: snrProfile.lines[0].dn.extension,
              siteId: this._siteId,
              deviceOwner: snrProfile.user.username,
              model: snrProfile.model,
              protocol: snrProfile.protocol,
            };
            return this._lineFeatureConfigResource.post(lineFeatureRequestBody);
          } else {
            const snrFieldConfig = this.snrFieldConfig;
            const lines: LineButton[] = [];
            const id: any = null;

            const defaultSnrProfile = {
              id,
              aarCss: snrFieldConfig.aarCallingSearchSpace.defaultValue,
              css: snrFieldConfig.callingSearchSpace.defaultValue,
              description: snrFieldConfig.profileDescription.defaultValue,
              devicePool: snrFieldConfig.devicePool,
              lines,
              model: SnrModelProtocol.SNR_MODEL,
              name: snrFieldConfig.profileName.defaultValue,
              networkMohSourceName: snrFieldConfig.networkMohAudioSource.defaultValue,
              privacy: snrFieldConfig.privacy.defaultValue,
              protocol: SnrModelProtocol.SNR_PROTOCOL,
              reRoutingCss: snrFieldConfig.reroutingCallingSearchSpace.defaultValue,
              user: this._endUser.ref,
              userMohSourceName: snrFieldConfig.userMohAudioSource.defaultValue,
            };

            this._initialSnrDestinations = [];
            this.snrDestinations = [];

            this._initialSnrProfile = cloneDeep(defaultSnrProfile);
            this.snrProfile = cloneDeep(defaultSnrProfile);

            const lineFeatureRequestBody = {
              extension: this.primaryExtension.extension,
              siteId: this._siteId,
              deviceOwner: this._endUser.ref.username,
              model: SnrModelProtocol.SNR_MODEL,
              protocol: SnrModelProtocol.SNR_PROTOCOL,
            };

            return this._lineFeatureConfigResource.post(lineFeatureRequestBody);
          }
        })
      )
      .subscribe((lineFeatureConfig) => {
        this.lineFeatureFieldConfig = lineFeatureConfig;

        if (!this._snrProfileId) {
          const defaultLineFeature = {
            associatedEndUsers: lineFeatureConfig.associatedEndUsers,
            busyTrigger: Number(lineFeatureConfig.busyTrigger.defaultValue),
            callRecordingMediaSource: lineFeatureConfig.callRecordingMediaSource.defaultValue,
            callRecordingOption: lineFeatureConfig.callRecordingOption.defaultValue,
            callRecordingProfile: lineFeatureConfig.callRecordingProfile.defaultValue,
            externalCallerId: lineFeatureConfig.externalCallerId.defaultValue,
            externalCallerIdNumber: lineFeatureConfig.externalCallerIdNumber.defaultValue,
            internalCallerId: lineFeatureConfig.internalCallerId.defaultValue,
            label: lineFeatureConfig.label.defaultValue,
            maxNumberOfCalls: Number(lineFeatureConfig.maxNumberOfCalls.defaultValue),
            monitoringCssName: lineFeatureConfig.monitoringCssName.defaultValue,
            ringActive: lineFeatureConfig.ringActive.defaultValue,
            ringIdle: lineFeatureConfig.ringIdle.defaultValue,
          };

          this._initialSnrProfileLine = cloneDeep(defaultLineFeature);
          this.snrProfileLine = cloneDeep(defaultLineFeature);

          const defaultLineButton: LineButton = {
            type: 'Line',
            dn: this.primaryExtension,
            lineFeature: defaultLineFeature,
          };

          this.snrProfile.lines[0] = defaultLineButton;
          this._initialSnrProfile.lines[0] = defaultLineButton;
        }

        this.isLoading = false;
        this._initBottomNav();
      });
  }

  ngOnDestroy() {
    this._bottomNavService.dispatch(new BottomNavClearButtonsList());
  }

  onDestinationsValidationStateChange(update: SmacsFormsUpdate<SnrDestination[]>) {
    this._validationStates.destinationsValidationState = update.valid;
    this._snrDestinationsEntity = update.new || update.old;
    this._updateBottomNavErrorState();
  }

  onProfileValidationStateChange(update: SmacsFormsUpdate<SnrProfile>) {
    this._validationStates.snrProfileValidationState = update.valid;
    this._snrProfileEntity = update.new || update.old;
    this._updateBottomNavErrorState();
  }

  onProfileLineValidationStateChange(update: SmacsFormsUpdate<LineFeature>) {
    this._validationStates.profileLineValidationState = update.valid;
    this._snrProfileLineEntity = update.new || update.old;
    this._updateBottomNavErrorState();
  }

  private _updateBreadCrumb() {
    this._breadcrumbsService.updateBreadcrumbs([
      {
        label: this._username,
        url: `/app2/#/user/${encodeURIComponent(this._username)}`,
      },
      {
        label: 'tkey;shared.service.snr.text',
      },
    ]);
  }

  private _goToUserHome() {
    if (this._snrProfileId) {
      this._router.navigate(['../../'], {
        relativeTo: this._route,
      });
    } else {
      this._router.navigate(['../'], {
        relativeTo: this._route,
      });
    }
  }

  private _initBottomNav = () => {
    if (this._snrProfileId) {
      this._bottomNavService.dispatch(
        new BottomNavUpdateButtonsList([
          {
            id: 'bottom-nav-cancel-button',
            dataAutomation: 'bottom-nav-cancel-button',
            label: 'tkey;global.button.cancel.text',
            buttonClass: ButtonStyles.DEFAULT,
            cb: () => this._goToUserHome(),
          },
          {
            id: 'bottom-nav-delete-button',
            dataAutomation: 'bottom-nav-delete-button',
            label: 'tkey;global.button.delete.text',
            icon: this.smacsIcons.DELETE,
            buttonClass: ButtonStyles.DANGER,
            cb: () => this._onDeleteClicked(),
          },
          {
            id: 'bottom-nav-save-button',
            dataAutomation: 'bottom-nav-save-button',
            label: 'tkey;global.button.save.text',
            icon: this.smacsIcons.OK,
            buttonClass: ButtonStyles.PRIMARY,
            type: ButtonTypes.BUTTON,
            cb: () => this._submit(),
          },
        ])
      );
    } else {
      this._bottomNavService.dispatch(
        new BottomNavUpdateButtonsList([
          {
            id: 'bottom-nav-cancel-button',
            dataAutomation: 'bottom-nav-cancel-button',
            label: 'tkey;global.button.cancel.text',
            buttonClass: ButtonStyles.DEFAULT,
            cb: () => this._goToUserHome(),
          },
          {
            id: 'bottom-nav-save-button',
            dataAutomation: 'bottom-nav-save-button',
            label: 'tkey;global.button.save.text',
            icon: this.smacsIcons.OK,
            buttonClass: ButtonStyles.PRIMARY,
            type: ButtonTypes.BUTTON,
            cb: () => this._submit(),
          },
        ])
      );
    }
  };

  private _saveToastAndReroute() {
    this._smacsFormStateService.setIsFormDirty(false);
    const global360View = cloneDeep(this._userDetailUiContext.getGlobal360View());

    if (!global360View.snrProfiles.length) {
      global360View.snrProfiles.push(this._snrProfileRef);
    } else {
      const snrProfileIndex = global360View.snrProfiles.findIndex(
        (profileRef) => profileRef.id === this._snrProfileRef.id && profileRef.serverId === this._snrProfileRef.serverId
      );
      global360View.snrProfiles[snrProfileIndex].name = this._snrProfileEntity.name;
      global360View.snrProfiles[snrProfileIndex].description = this._snrProfileEntity.description;
    }

    this._global360ViewContext._stateSource.next(global360View);

    this._toastService.push(
      ToastTypes.SUCCESS,
      SmacsIcons.OK,
      'tkey;shared.toast.save.success.title',
      'tkey;snr.toast.success',
      {
        profileName: this._snrProfileEntity.name,
      }
    );
    this._router.navigateByUrl(`/user/${this._route.snapshot.params.username}`);
  }

  private _createDestinationRequests() {
    this._snrDestinationRequests = this._snrDestinationsEntity.map((destination) => {
      const auditTags: Nvp[] = [
        {
          name: 'username',
          value: this._endUser.ref.username,
        },
      ];
      return this._snrProfileResource.makeDestinationRequest(
        this._snrProfileId,
        destination,
        this.cucmServerId,
        auditTags
      );
    });
  }

  private _saveDestinations(): Observable<(void | SnrDestinationRef)[]> {
    return this._endUserResource
      .checkEnabledMobilitySettings(this._endUser.ref.id, this.cucmServerId)
      .pipe(switchMap(() => combineLatest(this._snrDestinationRequests)));
  }

  private _formsAreValid(): boolean {
    return Object.values(this._validationStates).every((val) => val === SmacsFormsValidationState.VALID);
  }

  private _updateBottomNavErrorState() {
    if (this._isSubmitted) {
      this._bottomNavService.setBottomNavValidationError(!this._formsAreValid());
    }
  }

  private _trackChildFormUpdates() {
    this._isSnrDestinationsUpdated = !isEqual(this._initialSnrDestinations, this._snrDestinationsEntity);
    this._isSnrProfileUpdated =
      !isEqual(this._initialSnrProfile, this._snrProfileEntity) ||
      !isEqual(this._initialSnrProfileLine, this._snrProfileLineEntity);

    if (!isEqual(this._snrProfileEntity.lines[0].lineFeature, this._snrProfileLineEntity)) {
      this._snrProfileEntity.lines[0].lineFeature = this._snrProfileLineEntity;
    }
  }

  private _removeUntouchedDestinations() {
    if (this._snrDestinationsEntity?.length) {
      this._snrDestinationsEntity.forEach((destination, i) => {
        const matchingDestination = this._initialSnrDestinations.find(
          (initialDestination) => initialDestination.id === destination.id
        );
        if (isEqual(destination, matchingDestination)) {
          this._snrDestinationsEntity.splice(i, 1);
        }
      });
    }
  }

  private _saveExistingSnr(stopSubmitFn: () => void) {
    this._removeUntouchedDestinations();

    if (this._isSnrProfileUpdated && this._isSnrDestinationsUpdated) {
      this.parentSubmit$ = this._snrProfileResource
        .updateSnrProfile(this.snrProfile.id, this.cucmServerId, this._snrProfileEntity)
        .pipe(
          switchMap(() => {
            this._createDestinationRequests();
            return this._saveDestinations();
          }),
          tap(() => this._saveToastAndReroute()),
          catchError((err) => {
            stopSubmitFn();
            this._setErrorThrown();
            throw err;
          }),
          shareReplay()
        );
    } else if (this._isSnrProfileUpdated) {
      this.parentSubmit$ = this._snrProfileResource
        .updateSnrProfile(this._snrProfileId, this.cucmServerId, this._snrProfileEntity)
        .pipe(
          tap(() => this._saveToastAndReroute()),
          catchError((err) => {
            stopSubmitFn();
            this._setErrorThrown();
            throw err;
          }),
          shareReplay()
        );
    } else if (this._isSnrDestinationsUpdated) {
      this._createDestinationRequests();
      this.parentSubmit$ = this._saveDestinations().pipe(
        tap(() => this._saveToastAndReroute()),
        catchError((err) => {
          stopSubmitFn();
          this._setErrorThrown();
          throw err;
        }),
        shareReplay()
      );
    } else {
      this.parentSubmit$ = of(null).pipe(
        tap(() => this._saveToastAndReroute()),
        shareReplay()
      );
    }
  }

  private _setErrorThrown() {
    this.isErrorThrown = true;
    this._changeDetectorRef.detectChanges();
  }

  private _saveNewSnr(stopSubmitFn: () => void) {
    if (this._isSnrDestinationsUpdated) {
      this.parentSubmit$ = this._snrProfileResource.createSnrProfile(this.cucmServerId, this._snrProfileEntity).pipe(
        switchMap((snrProfileRef) => {
          if (snrProfileRef) {
            this._snrProfileRef = snrProfileRef;
            this._snrProfileId = snrProfileRef.id;
            this._snrProfileEntity.id = this._snrProfileRef.id;
            this._createDestinationRequests();
          }
          return this._saveDestinations();
        }),
        tap(() => this._saveToastAndReroute()),
        catchError((err) => {
          stopSubmitFn();
          throw err;
        }),
        shareReplay()
      );
    } else {
      this.parentSubmit$ = this._snrProfileResource.createSnrProfile(this.cucmServerId, this._snrProfileEntity).pipe(
        switchMap((snrProfileRef) => {
          if (snrProfileRef) {
            this._snrProfileRef = snrProfileRef;
            this._snrProfileId = snrProfileRef.id;
            this._snrProfileEntity.id = this._snrProfileRef.id;
          }
          return this._endUserResource.checkEnabledMobilitySettings(this._endUser.ref.id, this.cucmServerId);
        }),
        tap(() => this._saveToastAndReroute()),
        catchError((err) => {
          stopSubmitFn();
          throw err;
        }),
        shareReplay()
      );
    }
  }

  private _submit() {
    this._isSubmitted = true;
    this._bottomNavService.setButtonPendingState('bottom-nav-save-button', true);
    this._bottomNavService.setButtonDisabledState('bottom-nav-delete-button', true);
    this._bottomNavService.setButtonDisabledState('bottom-nav-cancel-button', true);

    const viewChildren = [this.snrDestinationsViewChild, this.snrProfileViewChild, this.snrProfileLineViewChild];

    const stopSubmission = () => {
      this._bottomNavService.setButtonPendingState('bottom-nav-save-button', false);
      this._bottomNavService.setButtonDisabledState('bottom-nav-delete-button', false);
      this._bottomNavService.setButtonDisabledState('bottom-nav-cancel-button', false);
      this.parentSubmit$ = of(null).pipe(shareReplay());
    };

    const submitChildren = () => {
      viewChildren.forEach((child) => {
        child._validateAndSubmitSource.next(true);
      });
    };

    this._smacsFormStateService
      .checkForPendingFields({
        snrDestinationFields: this.snrDestinationsViewChild.snrNavset.form.formNav,
        fieldComponents: this.snrProfileViewChild.fieldComponents,
        validationStates: this._validationStates,
      })
      .subscribe((valid) => {
        if (!valid) {
          this._updateBottomNavErrorState();
          stopSubmission();
          viewChildren.forEach((child) => {
            child._validateAndSubmitSource.next(false);
          });
        } else {
          this._trackChildFormUpdates();

          if (this._snrProfileId) {
            this._saveExistingSnr(stopSubmission);
          } else {
            this._saveNewSnr(stopSubmission);
          }
          window.setTimeout(() => submitChildren());
        }
      });
  }

  private _onDeleteClicked() {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE_OUTLINE,
        iconClass: 'text-danger',
        title: this._translateService.instant('tkey;snr.snrprofile.delete.modal.header'),
        promptBody: this._translateService.instant('tkey;snr.snrprofile.delete.modal.text', {
          name: this._snrProfileEntity.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => {
              return this._deleteSnrProfile();
            },
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _deleteSnrProfile() {
    const auditTags: Nvp[] = [
      {
        name: 'username',
        value: this._endUser.ref.username,
      },
    ];
    return this._snrProfileResource.deleteSnrProfile(this._snrProfileId, this.cucmServerId, auditTags).pipe(
      tap(() => {
        this._smacsFormStateService.setIsFormDirty(false);
        const global360View = cloneDeep(this._userDetailUiContext.getGlobal360View());
        global360View.snrProfiles = [];
        this._global360ViewContext._stateSource.next(global360View);
        this._toastService.pushDeleteToast('tkey;shared.toast.delete.success.title', this._snrProfileEntity.name);
        this._router.navigateByUrl(`/user/${this._route.snapshot.params.username}`);
      }),
      catchError((error) => {
        const viewChildren = [this.snrDestinationsViewChild, this.snrProfileViewChild, this.snrProfileLineViewChild];
        viewChildren.forEach((child) => {
          child.isSubmitting = false;
          child._validateAndSubmitSource.next(false);
          this._bottomNavService.setButtonPendingState('bottom-nav-save-button', false);
          this._bottomNavService.setButtonDisabledState('bottom-nav-delete-button', false);
          this._bottomNavService.setButtonDisabledState('bottom-nav-cancel-button', false);
        });
        return throwError(() => error);
      })
    );
  }
}
