import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SmacsIcons } from '../../models/smacs-icons.enum';

@Component({
  selector: 'smacs-filter-date',
  templateUrl: './smacs-filter-date.component.html',
})
export class SmacsFilterDateComponent implements OnInit {
  @Input() dataAutomation = 'smacs-filter-date';
  @Input() model: Date[];
  @Output() selectedDate = new EventEmitter<Date[]>();

  defaultDate: Date[];
  smacsIcons = SmacsIcons;

  ngOnInit() {
    this.defaultDate = this.model;
  }

  handleCalendarOpen() {
    if (!!this.model && this.model.some((dateRange) => dateRange === null)) {
      this.model = this.defaultDate;
    }
  }

  handleCalendarClose() {
    if (!!this.model && this.model.every((dateRange) => dateRange !== null)) {
      this.selectedDate.emit(this.model);
    }
  }

  onClear() {
    this.model = null;
    this.selectedDate.emit(this.model);
  }
}
