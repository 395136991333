import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  MicrosoftDialPlanExceptionGroup,
  MicrosoftDialPlanRange,
  SmacsError,
} from '../../../../shared/models/generated/smacsModels';
import { BreadcrumbsService } from '../../../../shared/breadcrumbs/breadcrumbs.service';
import { ButtonStyles, ButtonTypes } from '../../../../button/button.component';
import { SmacsIcons } from '../../../../shared/models/smacs-icons.enum';
import { SmacsModalService } from '../../../../shared/services/smacs-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of, Subscriber, Subscription, throwError } from 'rxjs';
import { BottomNavService, BottomNavUpdateButtonsList } from '../../../../shared/bottom-nav/bottom-nav.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MsDialPlanExceptionGroupsContext } from '../../../contexts/ms-dial-plan-exception-groups.context';
import { RangeFilterService } from '../../../services/range-filter.service';
import {
  EntityTable,
  EntityTableColumn,
  EntityTableContentRow,
  EntityTableFilterTypes,
} from '../../../../shared/entity-table/entity-table.models';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-microsoft-dial-plan-exception-management-list',
  templateUrl: './dial-plan-exception-management-list.component.html',
  styleUrls: ['../../../admin-page.scss'],
  providers: [RangeFilterService],
})
export class MicrosoftDialPlanExceptionManagementListComponent implements OnInit, OnDestroy {
  isLoading = true;
  aboutCardText = 'tkey;admin.ms_dial_plan_exception.list.about_card';
  dynamicNonTeamsGroupNames = new Set<string>();

  table: EntityTable;
  tableRows: EntityTableContentRow[] = [];

  private _subscriptions = new Subscription();

  constructor(
    private _msDialPlanExceptionGroupsContext: MsDialPlanExceptionGroupsContext,
    private _breadcrumbsService: BreadcrumbsService,
    private _smacsModalService: SmacsModalService,
    private _translateService: TranslateService,
    private _bottomNavService: BottomNavService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _rangeFilterService: RangeFilterService
  ) {}

  ngOnInit() {
    this._breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;admin.ms_dial_plan_exception.title' }]);
    this._bottomNavService.dispatch(
      new BottomNavUpdateButtonsList([
        {
          id: 'ms-dial-plan-exception-list-add-group-button',
          label: 'tkey;admin.ms_dial_plan_exception.list.add_button.label',
          icon: SmacsIcons.PLUS_CIRCLE,
          buttonClass: ButtonStyles.PRIMARY,
          dataAutomation: 'ms-dial-plan-exception-list-add-group-button',
          cb: () => {
            this._router.navigate(['dial-plan-exception-group'], { relativeTo: this._route });
          },
        },
      ])
    );

    const sub = combineLatest([
      this._msDialPlanExceptionGroupsContext.state$,
      this._msDialPlanExceptionGroupsContext.getDynamicNonTeamsDialPlaneExceptionGroups().pipe(
        catchError((err: HttpErrorResponse) => {
          return (err.error as SmacsError).reasonCode === 'MISSING_NUMBERS_API_SETTING'
            ? of(null)
            : throwError(() => err);
        })
      ),
    ]).subscribe(([msDialPlanExceptionGroups, dynamicNonTeamsDialPlanExceptionGroupsMaybe]) => {
      const dynamicNonTeamsDialPlanExceptionGroups = dynamicNonTeamsDialPlanExceptionGroupsMaybe || [];
      this.table = {
        columns: [
          {
            columnId: 'name',
            cssColumnSize: dynamicNonTeamsDialPlanExceptionGroups.length ? 'col-sm-3' : 'col-sm-4',
            label: 'tkey;admin.ms_dial_plan_exception.group_name.label',
            filter: {
              type: EntityTableFilterTypes.TEXT,
            },
          },
          {
            columnId: 'exceptions',
            cssColumnSize: dynamicNonTeamsDialPlanExceptionGroups.length ? 'col-sm-6' : 'col-sm-7',
            label: 'tkey;admin.ms_dial_plan_exception.exceptions.label',
            filter: {
              type: EntityTableFilterTypes.TEXT,
              filterFn: (rows, filterValue) =>
                rows.filter((row) => {
                  return this._rangeFilterService.hasMatchingRanges(row.content.exceptions, filterValue);
                }),
            },
          },
        ],
        resultsMessage: 'tkey;admin.ms_dial_plan_exception.list.no_results',
        hasActions: true,
      };
      if (dynamicNonTeamsDialPlanExceptionGroups.length) {
        this.aboutCardText = 'tkey;admin.ms_dial_plan_exception.list.about_card.dynamic_non_teams_groups_exist';
        const typeColumn: EntityTableColumn = {
          columnId: 'type',
          cssColumnSize: 'col-sm-2',
          cssClass: 'text-center',
          label: 'tkey;admin.ms_dial_plan_exception.group_type.label',
          filter: {
            type: EntityTableFilterTypes.SELECT,
            options: ['Dynamic', 'Manual'],
          },
        };
        this.table.columns = [...this.table.columns.slice(0, 1), typeColumn, ...this.table.columns.slice(1, 2)];

        dynamicNonTeamsDialPlanExceptionGroups.forEach((group) => this.dynamicNonTeamsGroupNames.add(group.name));
      }
      this._buildTableRows([...msDialPlanExceptionGroups, ...dynamicNonTeamsDialPlanExceptionGroups]);
      this.isLoading = false;
    });
    this._subscriptions.add(sub);
  }

  ngOnDestroy() {
    this._breadcrumbsService.clearBreadcrumbs();
    this._subscriptions.unsubscribe();
  }

  private _onDeleteClicked(group: MicrosoftDialPlanExceptionGroup) {
    const options = {
      windowClass: 'delete-button-modal',
      modalViewProperties: {
        icon: SmacsIcons.DELETE,
        iconClass: 'text-danger',
        title: this._translateService.instant('tkey;admin.msdialplan.management.delete_modal.title'),
        promptBody: this._translateService.instant('tkey;admin.ms_dial_plan_exception.delete.message', {
          name: group.name,
        }),
        displayCloseButton: true,
        buttons: [
          {
            label: 'tkey;dialogs.button.cancel',
            buttonClass: ButtonStyles.DEFAULT,
            dataAutomation: 'confirmation-modal-cancel-button',
          },
          {
            label: 'tkey;dialogs.button.delete',
            buttonClass: ButtonStyles.DANGER,
            dataAutomation: 'confirmation-modal-confirm-button',
            cb: () => this._deleteGroup(group),
          },
        ],
      },
    };

    this._smacsModalService.openPromptModal(() => options.modalViewProperties, options);
  }

  private _getE164FormattedRange(range: MicrosoftDialPlanRange): string {
    return this._rangeFilterService.getE164FormattedRange(range);
  }

  private _buildTableRows(exceptionGroups: MicrosoftDialPlanExceptionGroup[]) {
    this.tableRows = exceptionGroups.map((group) => {
      const isDynamicGroup = this.dynamicNonTeamsGroupNames.has(group.name);

      return {
        content: {
          name: group.name,
          type: isDynamicGroup ? 'Dynamic' : 'Manual',
          exceptions: group.exceptionRanges,
        },
        tooltip: {
          name: {
            content: isDynamicGroup ? 'tkey;admin.ms_dial_plan_exception.dynamic.group_name.tooltip' : '',
          },
        },
        html: {
          name: group.name,
          type: `<span class="alert alert-inline alert-info text-capitalize"><i class="${
            isDynamicGroup ? 'icon-fix-it' : 'icon-hand'
          } me-1"></i>${isDynamicGroup ? 'Dynamic' : 'Manual'}</span>`,
          exceptions: group.exceptionRanges
            .sort((a, b) => parseInt(a.start) - parseInt(b.start))
            .map((range) => `<span class="badge bg-secondary me-1">${this._getE164FormattedRange(range)}</span>`)
            .join(''),
        },
        actions: [
          {
            buttonStyle: ButtonStyles.PRIMARY,
            buttonType: ButtonTypes.LINK,
            buttonLinkHref: window.location.href + `/dial-plan-exception-group/${group.id}`,
            dataAutomation: 'ms-dial-plan-exception-group-edit',
            icon: SmacsIcons.EDIT,
            isDisabled: isDynamicGroup,
            tooltip: isDynamicGroup ? 'tkey;admin.ms_dial_plan_exception.dynamic.button.tooltip' : '',
            onClick: () => this._router.navigate(['dial-plan-exception-group', group.id], { relativeTo: this._route }),
          },
          {
            buttonStyle: ButtonStyles.DANGER,
            dataAutomation: 'ms-dial-plan-exception-group-delete',
            icon: SmacsIcons.DELETE,
            isDisabled: isDynamicGroup,
            tooltip: isDynamicGroup ? 'tkey;admin.ms_dial_plan_exception.dynamic.button.tooltip' : '',
            onClick: () => this._onDeleteClicked(group),
          },
        ],
      };
    });
  }

  private _deleteGroup(group: MicrosoftDialPlanExceptionGroup): Observable<void> {
    return new Observable((subscriber: Subscriber<void>) => {
      this._msDialPlanExceptionGroupsContext.delete(Number(group.id), group.name).subscribe(() => {
        subscriber.next(null);
        subscriber.complete();
      });
    });
  }
}
