export const customSelectOrderedFields = [
  'call_forward_no_answer_ring_duration',
  'call_pickup_group',
  'call_forward_calling_search_space',
  'voicemail_template',
  'calling_search_space',
  'aar_calling_search_space',
  'media_resource_group_list',
  'rerouting_calling_search_space',
  'cross_cluster_css',
  'call_forward_secondary_calling_search_space_for_forward_all',
  'location',
  'softkey_template',
  'subscribe_css',
  'common_device_configuration',
  'phone_model',
  'common_phone_profile',
  'user_locale',
  'idle_ring_setting',
  'active_ring_setting',
  'call_recording_option',
  'call_recording_profile',
  'call_recording_media_source',
  'built_in_bridge',
  'feature_control_policy',
  'user_moh_audio_source',
  'network_moh_audio_source',
  'privacy',
  'device_mobility_mode',
  'sip_profile_name',
  'always_use_prime_line',
  'always_use_prime_line_for_vm',
];
