<div class="card bg-light mb-3">
  <span
    [ngClass]="'card-body bulk-edit-description'"
    [innerHtml]="'tkey;admin.sitemacman.modal.bulk.edit.description' | translate"
  ></span>
</div>
<smacs-radio [fieldId]="'accessLevel'"></smacs-radio>
<smacs-filter-input
  (filterChanged)="onChanged($event)"
  [value]="resultFilter"
  [dataAutomation]="'site-based-helpdesk-filter'"
></smacs-filter-input>
<div class="custom-table" *ngIf="authorization === siteClusterSelection.SITES">
  <div *ngFor="let item of filteredAuthorizedSitesOpts; let i = index">
    <div class="col-12 text-muted custom-table__header" *ngIf="isClusterForAuthorizedSite(i, item.cluster)">
      {{ item.cluster }}
    </div>
    <div class="col-12 form-check custom-table__row">
      <input
        type="checkbox"
        name="'{{ item.cluster }}'"
        id="select-site-{{ item.site }}"
        class="form-check-input"
        [checked]="isSiteSelectedForAllUsers(item.site) || selectedSites.includes(item.site)"
        [ngClass]="{ 'indeterminate-input': isIndeterminateSite(item.site) }"
        (change)="updateSiteSelection(item.site, $event)"
      />
      <label for="select-site-{{ item.site }}" class="form-check-label">{{ item.site }}</label>
    </div>
  </div>
</div>
<div class="custom-table" *ngIf="authorization === siteClusterSelection.CLUSTERS">
  <div class="d-flex" *ngFor="let item of filteredAuthorizedClusterOpts">
    <div class="col-12 form-check custom-table__row">
      <input
        type="checkbox"
        class="form-check-input"
        id="select-cluster-{{ item.name }}"
        [checked]="isClusterSelectedForAllUsers(item.name)"
        [ngClass]="{ 'indeterminate-input': isIndeterminateCluster(item.name) }"
        (change)="updateClusterSelection(item.name, $event)"
      />
      <label for="select-cluster-{{ item.name }}" class="form-check-label">{{ item.name }}</label>
    </div>
  </div>
</div>
<div class="checkbox-container">
  <smacs-checkbox [fieldId]="'readAccessToControlEnabled'"></smacs-checkbox>
  <smacs-checkbox [fieldId]="'isAccessDisabled'"></smacs-checkbox>
</div>
