import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { SmacsFormAbstractDirective } from '../../../smacs-form-abstract.directive';
import { SmacsFormConfig } from '../../../smacs-forms-models';
import { ExtensionRange } from '../smacs-extension-range-models';
import { of } from 'rxjs';
import { SmacsFormStateService } from '../../../smacs-form-state.service';
import { ExtensionRangeValidationResult } from '../smacs-extension-ranges-validation.ui-context';
import { isEqual } from 'lodash';

@Component({
  selector: 'smacs-single-extension-range-form',
  templateUrl: './smacs-single-extension-range-form.component.html',
  styleUrls: ['./smacs-single-extension-range-form.component.scss'],
})
export class SmacsSingleExtensionRangeFormComponent
  extends SmacsFormAbstractDirective<ExtensionRange>
  implements OnChanges
{
  @Input() externalValidation: ExtensionRangeValidationResult;
  @Input() isFormSubmitted = false;
  @Input() isDisabled = false;

  formConfig: SmacsFormConfig = {
    fields: {
      start: {
        dataAutomation: 'extension-range-start-input',
        validation: [
          {
            validator: () => this.externalValidation.start.state,
            message: () => this.externalValidation.start.message,
          },
        ],
        disabled: () => this.isDisabled,
      },
      end: {
        dataAutomation: 'extension-range-end-input',
        validation: [
          {
            validator: () => this.externalValidation.end.state,
            message: () => this.externalValidation.end.message,
          },
        ],
        disabled: () => this.isDisabled,
      },
    },
  };

  constructor(protected smacsFormStateService: SmacsFormStateService, private changeDetectorRef: ChangeDetectorRef) {
    super(smacsFormStateService);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    const externalChange = changes.externalValidation;
    if (
      externalChange &&
      !externalChange.isFirstChange() &&
      !isEqual(externalChange.currentValue, externalChange.previousValue)
    ) {
      Object.values(this.fieldChannels).forEach((c) => c.validateSource.next());
    }

    const isFormSubmittedChange = changes.isFormSubmitted;
    if (isFormSubmittedChange && isFormSubmittedChange.currentValue) {
      this.fieldComponents?.forEach((field) => {
        field.showValidation = true;
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  protected submit() {
    return of(null);
  }
}
