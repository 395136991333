import { Component, HostBinding, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { CustomUiOptionsContext } from '../shared/contexts/custom-ui-options.context';
import { Global360ViewContext } from '../shared/contexts/global-360-view.context';
import { SelfServeUiContext } from './contexts/self-serve-ui.context';
import { SelfServeOptionsContext } from './contexts/self-serve-options.context';

@Component({
  selector: 'app-self-serve',
  templateUrl: './self-serve.component.html',
  styleUrls: ['./self-serve.component.scss'],
  providers: [Global360ViewContext, SelfServeUiContext, SelfServeOptionsContext],
})
export class SelfServeComponent implements OnInit {
  @HostBinding('attr.class') class = 'login-and-ss';
  @HostBinding('attr.style')
  get getSanitizedStyle(): SafeStyle {
    return this.domSanitizer.bypassSecurityTrustStyle(this._backgroundStyles);
  }
  isLoading = true;

  private _backgroundStyles: string;
  private defaultBackgroundColor = '#202020';

  constructor(private customUiOptionsContext: CustomUiOptionsContext, private domSanitizer: DomSanitizer) {}

  ngOnInit() {
    this._getCustomizeUi();
  }

  private _getCustomizeUi() {
    this.customUiOptionsContext.state$.subscribe((uiOptions) => {
      let styles = `background-color: ${uiOptions.customBackgroundColor || this.defaultBackgroundColor};`;
      if (uiOptions.customBackground) {
        styles += `background-image: url("data:image/png;base64,${uiOptions.customBackground}");`;
      }

      this._backgroundStyles = styles;
      this.isLoading = false;
    });
  }
}
