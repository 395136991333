import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AccountType,
  LicensingState,
  Microsoft360View,
  MicrosoftDialPlanFieldConfig,
  SharedCallingRoutingPolicy,
  State,
  StatusCategory,
  TeamsPhoneNumberAssignment,
  TeamsVoicePolicies,
} from '../../../../shared/models/generated/smacsModels';
import { Microsoft360ViewContext } from '../../../../shared/contexts/microsoft-360-view.context';
import { ActivatedRoute } from '@angular/router';
import { combineLatest, of, Subscription, switchMap } from 'rxjs';
import { MicrosoftDialPlansResource } from '../../../shared/resources/ms-dial-plans.resource';
import { SystemStatusContext } from '../../../../shared/contexts/system-status.context';
import { SharedCallingPoliciesContext } from '../../../shared/contexts/shared-calling-policies.context';
import { map } from 'rxjs/operators';

@Component({
  selector: 'smacs-ms-teams-calling',
  templateUrl: './microsoft-teams-calling.component.html',
  providers: [SharedCallingPoliciesContext],
})
export class MicrosoftTeamsCallingComponent implements OnInit, OnDestroy {
  upn = '';
  isLoading = true;
  teamsLine: TeamsPhoneNumberAssignment;
  voicePolicies: TeamsVoicePolicies;
  microsoftDialPlanGroups: MicrosoftDialPlanFieldConfig[];
  isZpcSyncWarningPresent = false;
  ms360View: Microsoft360View;
  isResourceAccount: boolean;
  sharedCallingPolicies: SharedCallingRoutingPolicy[];

  private _subscriptions = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private microsoft360ViewContext: Microsoft360ViewContext,
    private microsoftDialPlansResource: MicrosoftDialPlansResource,
    private systemStatusContext: SystemStatusContext,
    private _sharedCallingPoliciesContext: SharedCallingPoliciesContext
  ) {}

  ngOnInit(): void {
    this.upn = this.route.snapshot.paramMap.get('upn');

    const sharedCallingPolicies$ = this._sharedCallingPoliciesContext.state$.pipe(
      map((policies) => {
        this.sharedCallingPolicies = policies;
      })
    );

    const ms360View$ = this.microsoft360ViewContext.state$.pipe(
      switchMap((ms360View: Microsoft360View) => {
        this.ms360View = ms360View;
        this.isResourceAccount = this.ms360View.accountType === AccountType.RESOURCE_ACCOUNT;
        if (ms360View.microsoft365UserLicenses) {
          if (ms360View.licensingStatus.teamsPhoneStatus === LicensingState.LICENSED) {
            return !!this.microsoftDialPlanGroups ? of(null) : this.microsoftDialPlansResource.getAllDialPlanGroups();
          } else if (
            ms360View.licensingStatus.teamsPhoneStatus === LicensingState.LICENSED_WITH_MICROSOFT_CALLING_PLAN
          ) {
            return !!this.microsoftDialPlanGroups ? of(null) : this.microsoftDialPlansResource.getAllDialPlanGroups();
          } else {
            window.location.href = `${window.location.origin}/app2/#/360-view/microsoft/${this.upn}`;
          }
        } else {
          window.location.href = `${window.location.origin}/app2/#/home`;
        }
      }),
      map((dialPlanGroups: MicrosoftDialPlanFieldConfig[]) => {
        if (!!dialPlanGroups) {
          this.teamsLine = this.ms360View.teamsPhoneNumberAssignment
            ? { ...this.ms360View.teamsPhoneNumberAssignment }
            : null;
          this.voicePolicies = this.ms360View.teamsVoicePolicies ? { ...this.ms360View.teamsVoicePolicies } : null;
          this.microsoftDialPlanGroups = dialPlanGroups;
        }
      })
    );

    const contextSubs = combineLatest([ms360View$, sharedCallingPolicies$]).subscribe(() => {
      this.isLoading = false;
    });
    this._subscriptions.add(contextSubs);

    const systemStatusSub = this.systemStatusContext.state$.subscribe((systemStatus) => {
      const syncStatus = systemStatus.healthStatuses.find((status) => status.category === StatusCategory.PROXY_SERVER);
      this.isZpcSyncWarningPresent = syncStatus && syncStatus.state !== State.OK;
    });
    this._subscriptions.add(systemStatusSub);
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
  }
}
