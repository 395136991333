<label [ngClass]="{ hidden: minimized }" class="side-navigation-search">
  <i class="fa fa-search side-navigation-search__icon"></i>
  <input
    class="side-navigation-search__input"
    placeholder="Search"
    data-automation="side-navigation-search-input"
    [(ngModel)]="searchFilter"
    (input)="outputSearchFilter()"
  />
  <span (click)="clearFilterClicked()" class="side-navigation-search__clear-button" *ngIf="searchFilter">&times;</span>
</label>
