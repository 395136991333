import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
  SecurityContext,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { ButtonSizes, ButtonStyles } from '../../button/button.component';
import { SmacsIcons } from '../models/smacs-icons.enum';
import { FilterSelectOption, SmacsFilterSelectComponent } from '../filter/filter-select/filter-select.component';
import { SmacsFilterInputComponent } from '../filter/filter-input/filter-input.component';
import { SmacsFilterDateComponent } from '../filter/filter-date/smacs-filter-date.component';
import { isArray, isEmpty, isEqual, isNil } from 'lodash';
import moment from 'moment';
import {
  EntityTable,
  EntityTableAction,
  EntityTableButtonField,
  EntityTableCheckboxField,
  EntityTableColumn,
  EntityTableContentRow,
  EntityTableFieldTypes,
  EntityTableFiltersValue,
  EntityTableFilterTypes,
  EntityTableOnFieldChange,
  EntityTableSelectField,
  EntityTableTextField,
} from './entity-table.models';
import { DomSanitizer } from '@angular/platform-browser';
import { SmacsFilterCheckboxComponent } from '../filter/filter-checkbox/filter-checkbox.component';

@Component({
  selector: 'app-entity-table',
  templateUrl: './entity-table.component.html',
  styleUrls: ['./entity-table.component.scss'],
})
export class EntityTableComponent implements AfterViewInit, OnChanges {
  @Input() table: EntityTable;
  @Input() totalResult: number;
  @Input() isViewLoading = false;
  @Input() theadRowCssClass = 'table-secondary';
  @Input() verticalAlignContent = false;
  @Input() tableResponsive = false;
  @Input() scrollableTable = false;
  @Input() selectAppendTo: string;
  @Input() showCustomAlert = false;

  @Input()
  get tableRows(): any {
    return this._tableRows;
  }

  set tableRows(rows: any) {
    const actionOrder = [ButtonStyles.NONE, ButtonStyles.DANGER, ButtonStyles.INFO, ButtonStyles.PRIMARY];
    rows.forEach((row: EntityTableContentRow) => {
      if (row.actions) {
        row.actions.sort(
          (a: EntityTableAction, b: EntityTableAction) =>
            actionOrder.indexOf(a.buttonStyle) - actionOrder.indexOf(b.buttonStyle)
        );
      }
      if (row.html) {
        Object.entries(row.html).forEach(
          ([key, value]) =>
            (row.html[key] = this.domSanitizer.sanitize(
              SecurityContext.HTML,
              this.domSanitizer.bypassSecurityTrustHtml(value) as string
            ))
        );
      }
    });
    this._tableRows = rows;
    this.totalResults = this._tableRows.length;
  }

  @Output() fieldChange = new EventEmitter<EntityTableOnFieldChange>();
  @Output() filterChange = new EventEmitter<EntityTableFiltersValue>();
  @Output() filterValuesUpdate = new EventEmitter<EntityTableContentRow[]>();
  @Output() clearFiltersChange = new EventEmitter<EntityTableFiltersValue>();
  @Output() initialTextInputValue = new EventEmitter<string>();
  @Output() headerSelectAllChange = new EventEmitter<boolean>();

  @ViewChildren('textInput') textInput: QueryList<ElementRef>;
  @ViewChildren(SmacsFilterInputComponent) filterInputs: QueryList<SmacsFilterInputComponent>;
  @ViewChildren(SmacsFilterSelectComponent) filterSelects: QueryList<SmacsFilterSelectComponent>;
  @ViewChildren(SmacsFilterDateComponent) filterDates: QueryList<SmacsFilterDateComponent>;
  @ViewChildren(SmacsFilterCheckboxComponent) filterCheckboxes: QueryList<SmacsFilterCheckboxComponent>;

  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  entityTableFilterTypes = EntityTableFilterTypes;
  entityTableFieldTypes = EntityTableFieldTypes;
  smacsIcons = SmacsIcons;
  filteredTableRows: EntityTableContentRow[];
  totalResults: number;
  isClearAllFilter: boolean;
  page = 1;
  pageSize = 10;
  focussedTextInput: ElementRef;
  isIndeterminate = false;
  headerSelectAllValue = true;
  isRowsLoading = true;

  private _tableRows: EntityTableContentRow[];
  private _filterValues: EntityTableFiltersValue = {};

  constructor(private domSanitizer: DomSanitizer, private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.textInput.changes.subscribe((elements: QueryList<ElementRef>) => {
      if (this.focussedTextInput) {
        this.focussedTextInput.nativeElement.blur();
      }
      this.focussedTextInput = elements.get(0);

      if (this.focussedTextInput) {
        this.focussedTextInput.nativeElement.focus();
        setTimeout(() => {
          this.initialTextInputValue.emit(this.focussedTextInput.nativeElement.value);
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.table?.firstChange) {
      this.table.columns.forEach((col: EntityTableColumn) => {
        if (col?.filter?.type === EntityTableFilterTypes.DATE) {
          this._filterValues[col.columnId] = col.filter.defaultDate;
        }
      });
    }
    if (!isEqual(changes?.tableRows?.previousValue, changes?.tableRows?.currentValue)) {
      this._filterRows('', null);
    }
    if (!isNil(changes?.totalResult?.currentValue)) {
      this.totalResults = changes.totalResult.currentValue;
    }
    this.changeDetectorRef.detectChanges();
  }

  onFilterChanged(
    $event: FilterSelectOption[] | string[] | string | Date[] | boolean,
    key: string,
    preventTableUpdate = false,
    isLast = true
  ) {
    const column = this._getColumn(key);
    if (this._filterValues[key] !== $event) {
      this._filterValues[key] = $event;
      this.filterChange.emit(this._filterValues);
      if (!preventTableUpdate) {
        if (!!column.asyncSearch) {
          if (!this.isClearAllFilter || isLast) {
            this._filterAsyncRows(key);
            this.isClearAllFilter = false;
          }
        } else {
          this._filterRows(key, $event);
          this.filterValuesUpdate.emit(this.filteredTableRows);
        }
      }
    } else if (!!column.asyncSearch) {
      if (!this.isClearAllFilter || isLast) {
        this._filterAsyncRows(key);
        this.isClearAllFilter = false;
      }
    }
  }

  onHeaderSelectAllChanged($event: boolean) {
    this.isIndeterminate = false;
    this.headerSelectAllChange.emit($event);
  }

  clearFilters() {
    this.isClearAllFilter = true;
    this.changeDetectorRef.detectChanges();
    this.filterInputs.forEach((filter: SmacsFilterInputComponent) => filter.onClear());
    this.filterSelects.forEach((filter: SmacsFilterSelectComponent) => {
      if (filter.dataAutomation.startsWith('entity-table-filter-')) {
        filter.onClear();
      }
    });
    this.table.columns.forEach((col: EntityTableColumn) => {
      if (col?.filter?.type === EntityTableFilterTypes.DATE) {
        if (!col.filter?.preventDateClear) {
          this.filterDates.forEach((filter: SmacsFilterDateComponent) => filter.onClear());
        }
      }
    });
    this.filterCheckboxes.forEach((filter: SmacsFilterCheckboxComponent) => filter.onClear());
    this.clearFiltersChange.emit(this._filterValues);
  }

  /**
   * Determines if any filters exist on any of the columns in the table. Used to show filters, and create extra column
   * for filter clear button
   */
  hasFilters(): boolean {
    return !!this.table.columns.find((col: EntityTableColumn) => col.filter);
  }

  /**
   * If any rows have actions we'll need to give the table the additional column at the end
   */
  getHasActions(): boolean {
    return this.table.hasActions;
  }

  trackByColumns(index: number, column: EntityTableColumn): string {
    return column.columnId;
  }

  trackByContent(index: number, row: EntityTableContentRow): string {
    return JSON.stringify(row.content);
  }

  getSelectField(field: EntityTableSelectField | EntityTableButtonField): EntityTableSelectField {
    return field as EntityTableSelectField;
  }

  getButtonField(field: EntityTableSelectField | EntityTableButtonField): EntityTableButtonField {
    return field as EntityTableButtonField;
  }

  getCheckboxField(
    field: EntityTableSelectField | EntityTableButtonField | EntityTableCheckboxField
  ): EntityTableCheckboxField {
    return field as EntityTableCheckboxField;
  }

  getTextField(field: EntityTableTextField): EntityTableTextField {
    return field;
  }

  onFieldChanged(
    $event: FilterSelectOption[] | FilterSelectOption | string[] | string | boolean,
    row: EntityTableContentRow,
    columnId: string,
    value: object = {},
    index?: number
  ) {
    if (this.table.columns.some((column) => column.headerSelectAll && column.columnId === columnId)) {
      this._setIndeterminate($event as boolean, columnId);
      this._setSelectAll(columnId);
    }
    this.fieldChange.emit({
      newValue: $event,
      columnId: columnId,
      rowId: row?.rowId,
      content: row.content,
      contentValue: value,
      index: index,
    });
  }

  hasValidationError(row: EntityTableContentRow): boolean {
    if (!row || !row.fields) {
      return false;
    }
    const columnIds = this.table.columns.map((col) => col.columnId);
    return columnIds.some((columnId: string) => {
      return row.fields[columnId]?.validation?.hasError && row.fields[columnId]?.validation?.message;
    });
  }

  toggleTextPlaceholderDisplay(
    textField: EntityTableTextField | EntityTableSelectField | EntityTableButtonField | EntityTableCheckboxField
  ) {
    if ('showPlaceholderMarkup' in textField) {
      textField.showPlaceholderMarkup = !textField.showPlaceholderMarkup;
    }
  }

  onFieldClicked(row: EntityTableContentRow, columnId: string, value: object) {
    this.onFieldChanged('BUTTON_CLICK', row, columnId, value);
  }

  onPageChange() {
    if (this.table.pagination.onChanged) {
      this.isRowsLoading = true;
      this.table.pagination.onChanged(this.page, this._filterValues).subscribe((contentRows) => {
        this.filteredTableRows = contentRows;
        this.isRowsLoading = false;
      });
    }
  }

  private _setSelectAll(columnId: string) {
    const filteredRows = this._tableRows.filter((row) => !(row.fields[columnId] as EntityTableCheckboxField).disabled);
    const allChecked = filteredRows.every((row) => (row.fields[columnId] as EntityTableCheckboxField).value === true);
    const allUnchecked = filteredRows.every(
      (row) => (row.fields[columnId] as EntityTableCheckboxField).value === false
    );
    if (allChecked) {
      this.headerSelectAllValue = true;
    } else if (allUnchecked) {
      this.headerSelectAllValue = false;
    }
  }

  private _setIndeterminate(newCheckValue: boolean, columnId: string) {
    const filteredRows = this._tableRows.filter((row) => !(row.fields[columnId] as EntityTableCheckboxField).disabled);
    this.isIndeterminate =
      filteredRows.some(
        (nonDisabledRow) => (nonDisabledRow.fields[columnId] as EntityTableCheckboxField).value === newCheckValue
      ) &&
      !filteredRows.every(
        (nonDisabledRow) => (nonDisabledRow.fields[columnId] as EntityTableCheckboxField).value === newCheckValue
      );
  }

  private _getColumn(columnId: string) {
    return this.table.columns.find((column) => column.columnId === columnId);
  }

  private _filterAsyncRows(columnId = '') {
    let validRows = this.tableRows;
    const column = this._getColumn(columnId);
    if (column.filter.onChanged) {
      this.isRowsLoading = true;
      column.filter.onChanged(this._filterValues).subscribe((response) => {
        validRows = response;
        this.filteredTableRows = validRows;
        this.isRowsLoading = false;
      });
    }
  }

  private _filterRows(columnId = '', event: FilterSelectOption[] | string[] | string | Date[] | boolean) {
    let validRows = this.tableRows;
    this.table.columns.forEach((column: EntityTableColumn) => {
      if (
        column.filter &&
        this._filterValues[column.columnId] !== undefined &&
        this._filterValues[column.columnId] !== null
      ) {
        this.isRowsLoading = true;
        switch (column.filter.type) {
          case EntityTableFilterTypes.TEXT: {
            if (column.filter.filterFn) {
              validRows = column.filter.filterFn(validRows, this._filterValues[column.columnId], column.columnId);
              this.filteredTableRows = validRows;
            } else if (!!column.filter.onChanged) {
              column.filter.onChanged(this._filterValues).subscribe((response) => {
                validRows = response;
                this.filteredTableRows = validRows;
              });
            } else {
              validRows = this._filterRowsOnTextFilter(validRows, this._filterValues[column.columnId], column.columnId);
              this.filteredTableRows = validRows;
            }
            break;
          }
          case EntityTableFilterTypes.SELECT: {
            if (column.filter.filterFn) {
              validRows = column.filter.filterFn(validRows, this._filterValues[column.columnId], column.columnId);
            } else if (!!column.filter.onChanged) {
              column.filter.onChanged(this._filterValues).subscribe((response) => {
                validRows = response;
                this.filteredTableRows = validRows;
              });
            } else {
              validRows = this._filterRowsOnSelectFilter(
                validRows,
                this._filterValues[column.columnId],
                column.columnId
              );
            }
            this.filteredTableRows = validRows;
            break;
          }
          case EntityTableFilterTypes.DATE: {
            const filterValues = this._filterValues[column.columnId];
            if (filterValues[0]) {
              if (column.filter.filterFn) {
                validRows = column.filter.filterFn(validRows, this._filterValues[column.columnId], column.columnId);
              } else {
                validRows = this._filterRowsOnDateFilter(
                  validRows,
                  this._filterValues[column.columnId],
                  column.columnId
                );
              }
            }
            this.filteredTableRows = validRows;
            break;
          }
          case EntityTableFilterTypes.CHECKBOX: {
            validRows = validRows.map((row: any) => {
              if (row.fields[columnId] !== undefined) {
                row.fields[columnId].value = event;
              }
              return row;
            });
            this.filteredTableRows = validRows;
            break;
          }
          default: {
            break;
          }
        }
      }
    });
    this.filteredTableRows = validRows;
    this.isRowsLoading = false;
  }

  private _filterRowsOnTextFilter(
    rowsToFilter: EntityTableContentRow[],
    filterValue: string,
    columnKey: string
  ): EntityTableContentRow[] {
    return rowsToFilter.filter((row: EntityTableContentRow) => {
      return row.content[columnKey].toString().toLowerCase().includes(filterValue.trim().toLowerCase());
    });
  }

  private _filterRowsOnSelectFilter(
    rowsToFilter: EntityTableContentRow[],
    filterValue: FilterSelectOption | FilterSelectOption[] | string[] | string,
    columnKey: string
  ): EntityTableContentRow[] {
    if (isArray(filterValue)) {
      const firstFilterValue = filterValue[0];

      if (isEmpty(filterValue)) {
        return rowsToFilter;
      } else if (typeof firstFilterValue === 'string') {
        return this._filterRowsOnStringArray(rowsToFilter, filterValue as string[], columnKey);
      } else {
        const formattedFilterValues = (filterValue as FilterSelectOption[]).map(
          (filterOption: FilterSelectOption) => filterOption.value
        );
        return this._filterRowsOnStringArray(rowsToFilter, formattedFilterValues, columnKey);
      }
    } else {
      if (typeof filterValue === 'string') {
        return rowsToFilter.filter((row: EntityTableContentRow) => {
          return row.content[columnKey].toString().toLowerCase() === filterValue.trim().toLowerCase();
        });
      } else {
        return rowsToFilter.filter((row: EntityTableContentRow) => {
          return row.content[columnKey].toString().toLowerCase() === filterValue.value.trim().toLowerCase();
        });
      }
    }
  }

  private _filterRowsOnStringArray(
    rowsToFilter: EntityTableContentRow[],
    filterValues: string[],
    columnKey: string
  ): EntityTableContentRow[] {
    const filterValuesFormatted = filterValues.map((value: string) => value.toLowerCase());

    return rowsToFilter.filter((row: EntityTableContentRow) => {
      return filterValuesFormatted.includes(row.content[columnKey].toString().toLowerCase());
    });
  }

  private _filterRowsOnDateFilter(
    rowsToFilter: EntityTableContentRow[],
    filterValue: Date[],
    columnKey: string
  ): EntityTableContentRow[] {
    return rowsToFilter.filter((row: EntityTableContentRow) => {
      const rowDate = row.content[columnKey];
      const date = moment(rowDate);

      if (filterValue[0] && filterValue[1]) {
        if (date.diff(filterValue[0]) >= 0 && date.diff(filterValue[1]) <= 0) {
          return row;
        }
      } else if (filterValue[0]) {
        if (date.isSame(filterValue[0], 'day')) {
          return row;
        }
      }
    });
  }
}
