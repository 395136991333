<div class="row">
  <div class="col-12">
    <div
      [innerHtml]="
        'tkey;extension_selector.dial_plan_group_not_found.message'
          | translate : { extension: initialExtension | telephoneNumberFilter }
      "
      *ngIf="!isSearching && !!initialExtension && !selectedDialPlanGroup"
      class="alert alert-warning"
      data-automation="dn-did-dial-plan-not-found-alert"
    ></div>
  </div>
  <div class="col-6">
    <smacs-text [fieldId]="'dialPlanGroupId'"></smacs-text>
    <div
      [ngClass]="{ 'has-feedback smacs-forms-error': isSubmitted && !selectedDialPlanGroup }"
      data-automation="dn-did-selector-dial-plan-group-wrapper"
    >
      <label for="dialPlanGroupSelect" class="mb-2">
        <span class="text-danger">*</span>
        {{ 'tkey;dndidselection.dndid.group.label' | translate }}
      </label>
      <ng-select
        id="dialPlanGroupSelect"
        [labelForId]="'dialPlanGroupSelect'"
        data-automation="dn-did-dial-plan-group-select"
        [items]="dialPlanGroups"
        bindLabel="name"
        [(ngModel)]="selectedDialPlanGroup"
        (ngModelChange)="onDialPlanGroupChange()"
        [disabled]="isSubmitting"
        [clearable]="false"
      ></ng-select>
    </div>
  </div>
  <div [hidden]="!selectedDialPlanGroup || isSearching" data-automation="dn-did-filter-toggle-wrapper" class="col-6">
    <div class="card">
      <div class="card-header">
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input border-rad-all-2"
            id="dn-did-show-inactive"
            data-automation="dn-did-show-inactive-input"
            [(ngModel)]="showInactive"
            (ngModelChange)="applyConfigs()"
            [disabled]="isSubmitting"
          />
          <label
            class="form-check-label"
            [attr.data-automation]="'dn-did-show-inactive-label'"
            for="dn-did-show-inactive"
          >
            <i [attr.class]="smacsIcons.INACTIVE"></i>
            {{ 'tkey;dndidselection.dndid.show_inactive.label' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="dn-did-show-recently-deleted"
            data-automation="dn-did-show-recently-deleted-input"
            [(ngModel)]="showRecentlyDeleted"
            (ngModelChange)="applyConfigs()"
            [disabled]="isSubmitting"
          />
          <label
            class="form-check-label"
            [attr.data-automation]="'dn-did-show-recently-deleted-label'"
            for="dn-did-show-recently-deleted"
          >
            <i [attr.class]="smacsIcons.HISTORY"></i>
            {{ 'tkey;dndidselection.dndid.show_recently_deleted.label' | translate }}
          </label>
        </div>
        <div class="form-check">
          <input
            type="checkbox"
            class="form-check-input"
            id="dn-did-show-unavailable"
            data-automation="dn-did-show-unavailable-input"
            [(ngModel)]="showUnavailable"
            (ngModelChange)="applyConfigs()"
            [disabled]="isSubmitting"
          />
          <label
            class="form-check-label"
            [attr.data-automation]="'dn-did-show-unavailable-label'"
            for="dn-did-show-unavailable"
          >
            <i [attr.class]="smacsIcons.UNAVAILABLE + ' text-danger'"></i>
            {{ 'tkey;dndidselection.dndid.show_unavailable.label' | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
<app-loading-block [hidden]="!isSearching"></app-loading-block>
<div [hidden]="!selectedDialPlanGroup || isSearching">
  <div class="row mt-2" *ngIf="isZpmSyncWarningPresent" data-automation="sync-warning-alert">
    <div class="col-12">
      <div class="alert alert-warning mb-0">
        <strong>{{ 'tkey;extension_selector.sync.warning.zpc' | translate }}</strong>
      </div>
    </div>
  </div>
  <div class="row">
    <div [ngClass]="{ 'col-6': selectedGroupDidOptions.length, 'col-12': !selectedGroupDidOptions.length }">
      <smacs-extension-selector [fieldId]="'extension'" [isSubmitting]="isSubmitting"></smacs-extension-selector>
    </div>
    <div [hidden]="!selectedGroupDidOptions.length" class="col-6">
      <smacs-extension-selector [fieldId]="'pattern'" [isSubmitting]="isSubmitting"></smacs-extension-selector>
    </div>
  </div>
</div>
