import { Component, OnDestroy, OnInit } from '@angular/core';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { Global360ViewContext, ServiceTypes } from '../../../shared/contexts/global-360-view.context';
import {
  DirectoryNumberRef,
  EmailUserOperationLevel,
  Global360View,
  SiteResult,
  TranslationPatternRef,
} from '../../../shared/models/generated/smacsModels';
import { UserDetailUiContext } from '../../shared/contexts/user-detail-ui.context';
import { Router } from '@angular/router';
import { CurrentClusterContext } from '../../../shared/contexts/current-cluster.context';
import { Subscription } from 'rxjs';
import { EmailService } from '../../shared/services/email.service';

@Component({
  selector: 'smacs-user-detail-card',
  templateUrl: './user-detail-card.component.html',
  styleUrls: ['./user-detail-card.component.scss'],
})
export class SmacsUserDetailCardComponent implements OnInit, OnDestroy {
  areClustersConfigured = true;
  smacsIcons = SmacsIcons;
  global360View: Global360View;
  translationPatterns: TranslationPatternRef[];
  directoryNumber: DirectoryNumberRef;
  site: SiteResult;
  private _allCurrentServerIds: number[];
  private _cucmServerId: number;

  private _subscription = new Subscription();

  constructor(
    private global360ViewContext: Global360ViewContext,
    private userDetailUiContext: UserDetailUiContext,
    private currentClusterContext: CurrentClusterContext,
    private emailService: EmailService,
    private _router: Router
  ) {}

  ngOnInit(): void {
    const global360ViewSub = this.global360ViewContext.state$.subscribe((state: Global360View) => {
      this.global360View = state;
    });
    this._subscription.add(global360ViewSub);

    const userDetailSub = this.userDetailUiContext.state$.subscribe(() => {
      this.translationPatterns = this.userDetailUiContext.getTranslationPatternsOfCurrentServer();
      this.directoryNumber = this.userDetailUiContext.getPrimaryExtensionsOfCurrentCluster()[0];
      this.site = this.userDetailUiContext.getCurrentSite();
      this.areClustersConfigured = this.userDetailUiContext.getAreClustersConfigured();
    });
    this._subscription.add(userDetailSub);

    const currentClusterSub = this.currentClusterContext.state$.subscribe((currentCluster) => {
      this._cucmServerId = currentCluster.cucmServerId;
      this._allCurrentServerIds = this.currentClusterContext.getCurrentClusterServerIds(currentCluster);
    });
    this._subscription.add(currentClusterSub);
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getBase64Photo(): string {
    return this.global360View?.ldapUser && this.global360View?.ldapUser?.photoBase64;
  }

  getImageSrc(): string {
    return `data:image/jpeg;base64,${this.getBase64Photo()}`;
  }

  getUser360ViewRoute(): string {
    return `/app2/#/user/${encodeURIComponent(this.getUsername())}`;
  }

  getFullName(): string {
    return `${this.userDetailUiContext.getCurrentEnduser()?.ref.firstName} ${
      this.userDetailUiContext.getCurrentEnduser()?.ref.lastName
    }`;
  }

  getEmail(): string {
    return `${this.userDetailUiContext.getCurrentEnduser()?.email}`;
  }

  getLdapType(): string {
    return `${this.userDetailUiContext.getCurrentEnduser()?.type}`;
  }

  getUsername(): string {
    return this.userDetailUiContext.getCurrentEnduser()?.ref?.username;
  }

  onReassignClick() {
    if (this.global360ViewContext.hasAnyServiceInstances(this.global360View, this._allCurrentServerIds)) {
      const username = this.userDetailUiContext.getCurrentEnduser().ref.username;
      this._router.navigate([`/user/${username}/reassign`]);
    }
  }

  onMakePublicClick() {
    if (
      this.global360ViewContext.hasInstanceOf(ServiceTypes.DESKPHONE, this.global360View, this._allCurrentServerIds)
    ) {
      const username = this.userDetailUiContext.getCurrentEnduser().ref.username;
      this._router.navigate([`/user/${username}/make-public`]);
    }
  }

  onPinResetClick() {
    const voicemail = this.global360View?.voicemails[0];
    const extensionMobilities = this.global360View?.extensionMobilities;

    if (voicemail || (extensionMobilities && extensionMobilities.length)) {
      const username = this.userDetailUiContext.getCurrentEnduser().ref.username;
      this._router.navigate([`/user/${username}/pin-reset`]);
    }
  }

  onChangeSiteClick() {
    if (this.global360ViewContext.hasAnyDeviceInstances(this.global360View, this._allCurrentServerIds)) {
      const username = this.userDetailUiContext.getCurrentEnduser().ref.username;
      this._router.navigate([`/user/${username}/change-site`]);
    }
  }

  onSendEmailClick($event: EmailUserOperationLevel) {
    const username = this.getUsername();
    switch ($event) {
      case EmailUserOperationLevel.SHOW:
        this.emailService.openEmailPreviewModal(username, null, this._cucmServerId);
        break;
      case EmailUserOperationLevel.SHOW_WITH_TABLE:
        this._router.navigate([`/user/${username}/send-email`]);
        break;
    }
  }
}
