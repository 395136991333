<app-loading-block [hidden]="!isLoading"></app-loading-block>

<div [hidden]="isLoading">
  <app-breadcrumbs>
    <app-breadcrumb-card-item>
      <div [innerHTML]="'tkey;pages.zero_touch.deprovisioning.description.text' | translate"></div>
    </app-breadcrumb-card-item>
  </app-breadcrumbs>

  <app-entity-table
    *ngIf="!isLoading"
    [table]="table"
    [tableRows]="tableRows"
    data-automation="deprovisioning-job-entity-table"
  ></app-entity-table>
</div>
