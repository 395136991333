import { Component, OnDestroy, OnInit } from '@angular/core';
import { DialPlanInventoryResource } from '../resources/dial-plan-inventory.resource';
import { SiteSummaryContext } from '../../../shared/contexts/site-summary.context';
import { ToastService } from '../../../shared/services/toast.service';
import { DialPlanGroupInventory, JobState, JobStatus, SiteSummary } from '../../../shared/models/generated/smacsModels';
import { combineLatest, Observable, Subscription } from 'rxjs';
import {
  BottomNavService,
  BottomNavUpdateButtonsList,
  BottomNavUpdateButtonState,
  BottomNavUpdateState,
} from '../../../shared/bottom-nav/bottom-nav.service';
import { ToastTypes } from '../../../shared/services/abstract/toast.service.abstract';
import { ButtonSizes, ButtonStyles } from '../../../button/button.component';
import { SmacsIcons } from '../../../shared/models/smacs-icons.enum';
import { BreadcrumbsService } from '../../../shared/breadcrumbs/breadcrumbs.service';
import { TranslateService } from '@ngx-translate/core';
import { DatatableColumn, DatatableRow } from '../../datatable/datatable.component';
import { filter, map, switchMap, tap } from 'rxjs/operators';
import { BottomNavButton } from '../../../shared/bottom-nav/bottom-nav.component';
import { DialPlanInventoriesPollingService } from '../../../shared/resources/dial-plan-inventories-polling.service';
import { DateAgoPipe } from '../../../shared/pipes/date-ago.pipe';

interface DnInventoryModel {
  high: string;
  low: string;
  empty: string;
}

interface DnAvailabilityRow extends DatatableRow {
  groupName: string;
  // sitesText can be either a list of sites or a cluster name, it's the text shown in the cell:
  sitesText: string;
  available: string;
  // For filtering we need the actual list of sites and cluster name, independently from sitesText:
  sites: string;
  cluster: string;
  percentAvailable: number;
}

enum InventoryStatus {
  HIGH = 'tkey;reporting.dn_availability.inventory_status.high',
  LOW = 'tkey;reporting.dn_availability.inventory_status.low',
  EMPTY = 'tkey;reporting.dn_availability.inventory_status.empty',
}

enum InventoryThresholds {
  high = 10,
  low = 0,
}

@Component({
  selector: 'app-dn-availability',
  templateUrl: './dn-availability.component.html',
  styleUrls: ['../../reporting.scss'],
  providers: [DialPlanInventoriesPollingService],
})
export class DnAvailabilityComponent implements OnInit, OnDestroy {
  buttonStyles = ButtonStyles;
  buttonSizes = ButtonSizes;
  smacsIcons = SmacsIcons;
  tableDataAutomation = 'dn-datatable';
  dnTableRows = [] as DnAvailabilityRow[];
  isLoading = true;

  private _siteSummary: SiteSummary;
  private _subscriptions = new Subscription();
  inventoryFilterOptions = {
    high: null,
    low: null,
    empty: null,
  } as DnInventoryModel;
  availabilityFilterOptionsTexts: string[] = [];

  dataTableCols: DatatableColumn<DnAvailabilityRow>[] = [
    {
      name: 'groupName',
      label: 'tkey;admin.dn_report.group_name.label',
    },
    {
      name: 'sitesText',
      label: 'tkey;admin.dn_report.sites.label',
    },
    {
      name: 'available',
      label: 'tkey;admin.dn_report.available.label',
      sortFn: (a: string, b: string) => {
        // a and b are html <span>'s so need to extract the numbers:
        const numberA = Number(a.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '').split('%')[0]);
        const numberB = Number(b.replace(/<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g, '').split('%')[0]);
        if (numberA < numberB) {
          return -1;
        } else if (numberA > numberB) {
          return 1;
        }
        return 0;
      },
    },
  ];

  constructor(
    private dialPlanInventoryResource: DialPlanInventoryResource,
    private dialPlanInventoriesPollingService: DialPlanInventoriesPollingService,
    private toastService: ToastService,
    private siteSummaryContext: SiteSummaryContext,
    private bottomNavService: BottomNavService,
    private breadcrumbsService: BreadcrumbsService,
    private translateService: TranslateService,
    private dateAgoPipe: DateAgoPipe
  ) {}

  ngOnInit() {
    this.breadcrumbsService.updateBreadcrumbs([{ label: 'tkey;menu.report.dn_availability' }]);
    this.inventoryFilterOptions = {
      high: this.translateService.instant(InventoryStatus.HIGH),
      low: this.translateService.instant(InventoryStatus.LOW),
      empty: this.translateService.instant(InventoryStatus.EMPTY),
    } as DnInventoryModel;
    this.availabilityFilterOptionsTexts = Object.values(this.inventoryFilterOptions);

    const siteSummaryObservable = this.siteSummaryContext.state$.pipe(
      map((siteSummary: SiteSummary) => {
        this._siteSummary = siteSummary;
      })
    );

    const reportObservable = this.dialPlanInventoryResource.getStatus().pipe(
      switchMap((status: JobStatus) => {
        if (status.jobState === JobState.IDLE) {
          if (status.lastRunTime) {
            this._setBottomNavTimestamp(status.lastRunTime);
            return this.dialPlanInventoryResource.get();
          } else {
            return this.dialPlanInventoryResource.refresh().pipe(
              switchMap(() => {
                return this._pollAndGetDialPlanInventories().pipe(
                  tap((data) => {
                    this.dialPlanInventoriesPollingService.stopPolling();
                    return data;
                  })
                );
              })
            );
          }
        } else {
          return this._pollAndGetDialPlanInventories().pipe(
            tap((data) => {
              this.dialPlanInventoriesPollingService.stopPolling();
              return data;
            })
          );
        }
      })
    );

    this._subscriptions.add(
      combineLatest([reportObservable, siteSummaryObservable]).subscribe(([report]) => {
        this._initBottomNav();
        this._setDialPlanInventories(report);
        this.isLoading = false;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this.breadcrumbsService.clearBreadcrumbs();
    this.dialPlanInventoriesPollingService.stopPolling();
  }

  private _resolveDialPlanGroupInventory(
    dialPlanGroupInventory: DialPlanGroupInventory,
    siteSummary: SiteSummary
  ): DnAvailabilityRow {
    const {
      groupName,
      siteIds = [],
      clusterId,
      availableAndUndefined,
      availableAndDefined,
      unavailable,
    } = dialPlanGroupInventory;

    const available = availableAndUndefined + availableAndDefined;
    const total = availableAndUndefined + availableAndDefined + unavailable;
    const percentAvailable = total === 0 ? 0 : Math.round((available / total) * 100);
    const siteNames = siteIds
      .map((siteId) => this.siteSummaryContext.getSiteFromId(siteSummary, siteId)?.name)
      .filter((siteName) => !!siteName);
    let siteNamesJoined = siteNames.join(', ');
    let isDisplayingClusterName = false;
    const cluster = clusterId
      ? siteSummary.clusters.find((c) => c.id === clusterId)?.name
      : siteNames.length > 0
      ? this.siteSummaryContext.getClusterNameForSiteName(siteSummary, siteNames[0])
      : '';
    let sitesText = siteNamesJoined;
    if (!sitesText && cluster) {
      // sitesText will display the cluster name
      isDisplayingClusterName = true;
      sitesText = this.translateService.instant('tkey;reporting.dn_availability.sites.text', {
        cluster: cluster,
      });
      // but we still get the list of sites to filter by site and to display the list in the tooltip
      siteNamesJoined = siteSummary.clusters
        .find((c) => c.id === clusterId)
        .sites.map((s) => s?.name)
        .join(', ');
    }

    let rowCssClass = 'table-warning';
    if (percentAvailable <= InventoryThresholds.low) {
      rowCssClass = 'table-danger';
    } else if (percentAvailable >= InventoryThresholds.high) {
      rowCssClass = '';
    }

    return {
      groupName,
      sitesText: sitesText,
      available: this.translateService.instant('tkey;reporting.dn_availability.available.text', {
        available: available,
        total: total,
        percent: percentAvailable,
      }),
      sites: siteNamesJoined,
      cluster: cluster,
      hardcodedTooltips: {
        sitesText: isDisplayingClusterName ? siteNamesJoined : null,
      },
      percentAvailable: percentAvailable,
      cssClass: rowCssClass,
    } as DnAvailabilityRow;
  }

  onGetFileClicked() {
    this._setPending('exportDNReport', true);
    this._setDisabled('refreshDNReport', true);
    this.dialPlanInventoryResource.export().subscribe(() => {
      this._setPending('exportDNReport', false);
      this._setDisabled('refreshDNReport', false);
      this.toastService.push(
        ToastTypes.SUCCESS,
        this.smacsIcons.DOWNLOAD,
        'tkey;reporting.xlsx_export.downloaded_toast.title',
        'tkey;reporting.xlsx_export.downloaded_toast.message'
      );
    });
  }

  isAvailableNumbersMatch(filters: string[] | string, row: DnAvailabilityRow): boolean {
    let isMatch = false;
    if (!filters.length || filters.length === 3) {
      isMatch = true;
    } else if (typeof filters === 'string') {
      if (filters.includes(this.translateService.instant(InventoryStatus.EMPTY))) {
        isMatch = row.percentAvailable <= InventoryThresholds.low;
      } else if (filters.includes(this.translateService.instant(InventoryStatus.LOW))) {
        isMatch = row.percentAvailable > InventoryThresholds.low && row.percentAvailable < InventoryThresholds.high;
      } else if (filters.includes(this.translateService.instant(InventoryStatus.HIGH))) {
        isMatch = row.percentAvailable >= InventoryThresholds.high;
      }
    } else {
      if (row.percentAvailable <= InventoryThresholds.low) {
        isMatch = filters.some((val) =>
          this.translateService.instant(val).includes(this.translateService.instant(InventoryStatus.EMPTY))
        );
      } else if (row.percentAvailable > InventoryThresholds.low && row.percentAvailable < InventoryThresholds.high) {
        isMatch = filters.some((val) =>
          this.translateService.instant(val).includes(this.translateService.instant(InventoryStatus.LOW))
        );
      } else if (row.percentAvailable >= InventoryThresholds.high) {
        isMatch = filters.some((val) =>
          this.translateService.instant(val).includes(this.translateService.instant(InventoryStatus.HIGH))
        );
      }
    }
    return isMatch;
  }

  private _setPending(id: string, setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: id,
        state: {
          pending: setting,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  private _setDisabled(id: string, setting: boolean) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateButtonState({
        id: id,
        state: {
          pending: false,
          buttonDisableState: { disabled: setting, tooltipKey: '' },
        },
      })
    );
  }

  refreshDialPlanGroups() {
    this.isLoading = true;
    this._setPending('refreshDNReport', true);
    this._setDisabled('exportDNReport', true);
    this._refreshDialPlanInventories();
  }

  private _refreshDialPlanInventories() {
    this.dialPlanInventoryResource
      .refresh()
      .pipe(
        switchMap(() => this._pollAndGetDialPlanInventories()),
        map((report) => {
          this.dialPlanInventoriesPollingService.stopPolling();
          this._setDialPlanInventories(report);
          this.toastService.push(
            ToastTypes.SUCCESS,
            this.smacsIcons.REFRESH,
            'tkey;reporting.device_utilization.toast.refresh.title',
            'tkey;reporting.device_utilization.toast.refresh.message'
          );
          this.isLoading = false;
        })
      )
      .subscribe();
  }

  private _setDialPlanInventories(dialPlanGroupInventories: DialPlanGroupInventory[]) {
    this.dnTableRows = dialPlanGroupInventories
      // we decided not to show the clusters that are not part of any group
      .filter((d: DialPlanGroupInventory) => !d.groupName.includes('cluster not part of any group'))
      .map((d: DialPlanGroupInventory) => this._resolveDialPlanGroupInventory(d, this._siteSummary));

    this._setPending('refreshDNReport', false);
    this._setDisabled('exportDNReport', false);
  }

  private _pollAndGetDialPlanInventories(): Observable<DialPlanGroupInventory[]> {
    this.dialPlanInventoriesPollingService.startPolling();
    return this.dialPlanInventoriesPollingService.state$.pipe(
      filter((polledStatus: JobStatus) => {
        if (polledStatus.jobState === JobState.IDLE) {
          this._setBottomNavTimestamp(polledStatus.lastRunTime);
          return true;
        }
        return false;
      }),
      switchMap(() => {
        return this.dialPlanInventoryResource.get();
      })
    );
  }

  private _initBottomNav() {
    const bottomNavButtons: BottomNavButton[] = [
      {
        id: 'refreshDNReport',
        dataAutomation: 'refresh-dn-report',
        label: 'tkey;reporting.dn_availability.refresh.label',
        icon: this.smacsIcons.REFRESH,
        buttonClass: this.buttonStyles.DEFAULT,
        cb: () => {
          this.refreshDialPlanGroups();
        },
      },
      {
        id: 'exportDNReport',
        dataAutomation: 'export-dn-report',
        label: 'tkey;reporting.xlsx_export.button',
        icon: this.smacsIcons.EXPORT,
        buttonClass: this.buttonStyles.PRIMARY,
        cb: () => {
          this.onGetFileClicked();
        },
      },
    ];

    this.bottomNavService.dispatch(new BottomNavUpdateButtonsList(bottomNavButtons));
  }

  private _setBottomNavTimestamp(timestamp: string) {
    this.bottomNavService.dispatch(
      new BottomNavUpdateState({
        hasValidationError: false,
        helpText: `
              <i class="${this.smacsIcons.CLOCK}"></i>
              ${this.translateService.instant(
                'tkey;reporting.dn_availability.last_updated'
              )} <strong>${this.dateAgoPipe.transform(timestamp)}</strong>
            `,
      })
    );
  }
}
